import { EGateway } from '@domain/enums/common/EGateway';
import { EBusinessType } from '@domain/enums/stores/Etype';
import { IAdsProvider } from '@domain/interfaces/common/integration/IAdsProvider';
import { IDropshippingProviders } from '@domain/interfaces/common/integration/IDropshippingProvider';
import { IEcommerceProviders } from '@domain/interfaces/common/integration/IEcommerceProvider';
import { IErp } from '@domain/interfaces/common/integration/IErp';
import { IGateway } from '@domain/interfaces/common/integration/IGateway';
import { IInfoProductProvider } from '@domain/interfaces/common/integration/IInfoProductProvider';
import { IIntegration } from '@domain/interfaces/common/integration/IIntegration';
import {
  IIntegration as IStoreIntegration,
  IStoreIntegrations,
} from '@domain/interfaces/stores/IStores';

import {
  ADS_PROVIDERS,
  DROPSHIPPING_PROVIDERS,
  ECOMMERCE_PROVIDERS,
  ERPS,
  GATEWAYS,
  INFO_PRODUCT_PROVIDERS,
} from '@constants/common/integrations';

const mapIntegrations = (
  integrations: Array<
    | IDropshippingProviders
    | IInfoProductProvider
    | IEcommerceProviders
    | IGateway
    | IAdsProvider
    | IErp
  >,
): Array<IIntegration> => {
  const mappedIntegrations: Array<IIntegration> = integrations.map(integration => {
    return {
      icon: integration.icon,
      id: integration.id,
      identifier: integration.identifier,
      idToVerifyStatus: integration.idToVerifyStatus,
      lowerIdentifier: integration.lowerIdentifier,
      name: integration.name,
      sideModal: integration.sideModal,
    };
  });

  return mappedIntegrations;
};

const getDropshippingIntegrations = (
  activeStoreFrontIntegrations: Array<IStoreIntegration>,
): Array<IIntegration> => {
  const filteredDropshippingIntegrations = DROPSHIPPING_PROVIDERS.filter(integration => {
    const foundIntegration = activeStoreFrontIntegrations.find(
      activeIntegration => activeIntegration.name === integration.identifier,
    );

    return Boolean(foundIntegration);
  });

  const dropshippingIntegrations = mapIntegrations(filteredDropshippingIntegrations);

  return dropshippingIntegrations;
};

const getInfoProductIntegrations = (
  activeStoreFrontIntegrations: Array<IStoreIntegration>,
): Array<IIntegration> => {
  const filteredInfoProductIntegrations = INFO_PRODUCT_PROVIDERS.filter(integration => {
    const foundIntegration = activeStoreFrontIntegrations.find(
      activeIntegration => activeIntegration.name === integration.identifier,
    );

    return Boolean(foundIntegration);
  });

  const infoProductIntegrations = mapIntegrations(filteredInfoProductIntegrations);

  return infoProductIntegrations;
};

const getEcommerceIntegrations = (
  activeStoreFrontIntegrations: Array<IStoreIntegration>,
): Array<IIntegration> => {
  const filteredEcommerceIntegrations = ECOMMERCE_PROVIDERS.filter(integration => {
    const foundIntegration = activeStoreFrontIntegrations.find(
      activeIntegration => activeIntegration.name === integration.identifier,
    );

    return Boolean(foundIntegration);
  });

  const ecommerceIntegrations = mapIntegrations(filteredEcommerceIntegrations);

  return ecommerceIntegrations;
};

const getGatewayIntegrations = (
  activeGatewayIntegrations: Array<IStoreIntegration>,
): Array<IIntegration> => {
  const filteredGatewayIntegrations = GATEWAYS.filter(integration => {
    const foundIntegration = activeGatewayIntegrations.find(
      activeIntegration => activeIntegration.name === integration.identifier,
    );

    return Boolean(foundIntegration);
  });

  const gatewayIntegrations = mapIntegrations(filteredGatewayIntegrations);

  return gatewayIntegrations;
};

const getPredefinedGatewayIntegrations = (
  activeGatewayIntegrations: IStoreIntegration | undefined,
): Array<IIntegration> => {
  if (!activeGatewayIntegrations) return [];

  const gatewayNames = activeGatewayIntegrations.other_gateway?.gateway_names as Array<string>;

  const hasOtherGateway = gatewayNames.includes('OTHER');

  if (hasOtherGateway) {
    const otherGateway = GATEWAYS.find(
      gateway => gateway.identifier === EGateway.OTHER_GATEWAY,
    ) as typeof GATEWAYS[number];

    const gatewayIntegrations = mapIntegrations([otherGateway]);

    return gatewayIntegrations;
  }

  const predefinedGateways = GATEWAYS.filter(gateway => gateway.isPredefinedOtherGateway);

  const foundPredefinedGateway = predefinedGateways.find(gateway =>
    gatewayNames.includes(gateway.identifier),
  ) as typeof GATEWAYS[number];

  const gatewayIntegrations = mapIntegrations([foundPredefinedGateway]);

  return gatewayIntegrations;
};

const getProductCostIntegrations = (
  activeProductCostIntegrations: Array<IStoreIntegration>,
): Array<IIntegration> => {
  const filteredProductCostIntegrations = ERPS.filter(integration => {
    const foundIntegration = activeProductCostIntegrations.find(
      activeIntegration => activeIntegration.name === integration.identifier,
    );

    return Boolean(foundIntegration);
  });

  const gatewayIntegrations = mapIntegrations(filteredProductCostIntegrations);

  return gatewayIntegrations;
};

const getAdsIntegrations = (
  activeAdsIntegrations: Array<IStoreIntegration>,
): Array<IIntegration> => {
  const filteredAdsIntegrations = ADS_PROVIDERS.filter(integration => {
    const foundIntegration = activeAdsIntegrations.find(
      activeIntegration => activeIntegration.name === integration.identifier,
    );

    return Boolean(foundIntegration);
  });

  const adsIntegrations = mapIntegrations(filteredAdsIntegrations);

  return adsIntegrations;
};

export const getStoreIntegrations = (
  storeIntegrations: IStoreIntegrations,
  storeType: EBusinessType,
): Array<IIntegration> => {
  const integrationsToReturn: Array<IIntegration> = [];

  const { marketing, gateways, product_cost, store_front } = storeIntegrations;

  const activeStoreFrontIntegrations = store_front.filter(integration => integration.is_active);
  const activeGatewayIntegrations = gateways.filter(integration => integration.is_active);
  const activeProductCostIntegrations = product_cost.filter(integration => integration.is_active);
  const activeAdsIntegrations = marketing.filter(integration => integration.is_active);
  const activeOtherGatewayIntegrations = gateways.find(
    integration => integration.other_gateway?.has_other_gateway,
  );

  // Integrações da Loja
  if (storeType === EBusinessType.DROPSHIPPING) {
    const integrations = getDropshippingIntegrations(activeStoreFrontIntegrations);

    integrationsToReturn.push(...integrations);
  }

  if (storeType === EBusinessType.INFO_PRODUCT) {
    const integrations = getInfoProductIntegrations(activeStoreFrontIntegrations);

    integrationsToReturn.push(...integrations);
  }

  if (storeType === EBusinessType.TRADITIONAL_ECOMMERCE) {
    const integrations = getEcommerceIntegrations(activeStoreFrontIntegrations);

    integrationsToReturn.push(...integrations);
  }

  // Integrações de Gateways
  const gatewayIntegrations = getGatewayIntegrations(activeGatewayIntegrations);

  if (gatewayIntegrations.length) integrationsToReturn.push(...gatewayIntegrations);

  const otherGatewayIntegrations = getPredefinedGatewayIntegrations(activeOtherGatewayIntegrations);

  if (otherGatewayIntegrations.length) integrationsToReturn.push(...otherGatewayIntegrations);

  // Integrações de ERPs
  const productCostIntegrations = getProductCostIntegrations(activeProductCostIntegrations);

  if (productCostIntegrations.length) integrationsToReturn.push(...productCostIntegrations);

  // Integrações de Marketing
  const adsIntegrations = getAdsIntegrations(activeAdsIntegrations);

  if (adsIntegrations.length) integrationsToReturn.push(...adsIntegrations);

  return integrationsToReturn;
};
