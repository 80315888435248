import React from 'react';
import { ThumbsUp } from 'phosphor-react';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';
import { friendlyNumberFormatter } from '@helpers/masks';
import { IVoteProps } from '@domain/interfaces/dashboard/Automations/Canvas/SideModal/Integration/ISuggestionIntegrationCard';

import * as S from './styles';

const Vote: React.FC<IVoteProps> = ({ suggestion }) => {
  const { voteUp, voteDown } = useAutomation();

  const votesNumber = suggestion.votes_up;
  const ideaAliasId = suggestion.id;
  const isVoted = suggestion.is_voted_up_by_user;

  const [isUpvoted, setIsUpvoted] = React.useState<boolean>(false);
  const [votes, setVotes] = React.useState<number>(0);

  const formattedVotesNumber = friendlyNumberFormatter(votes, 1, true);

  const handleClick = React.useCallback(() => {
    if (isUpvoted) {
      voteDown({ ideaAliasId });
      setVotes(state => state - 1);
    }

    if (!isUpvoted) {
      voteUp({ ideaAliasId });
      setVotes(state => state + 1);
    }

    setIsUpvoted(!isUpvoted);
  }, [isUpvoted, voteDown, voteUp, ideaAliasId]);

  React.useEffect(() => {
    setIsUpvoted(isVoted);
  }, [isVoted]);

  React.useEffect(() => {
    setVotes(votesNumber);
  }, [votesNumber]);

  return (
    <S.VoteWrapper onClick={handleClick} isVoted={isUpvoted}>
      <ThumbsUp size={18} />
      <S.VotesCount>{formattedVotesNumber}</S.VotesCount>
    </S.VoteWrapper>
  );
};

export default Vote;
