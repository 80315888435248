import styled from 'styled-components/macro';

import SidemodalBase from '@components/common/core/DataDisplay/SideModal';

export const ErrorWrapper = styled.div`
  margin: 128px 0;
`;

export const Sidemodal = styled(SidemodalBase)`
  max-width: 588px;
  box-sizing: border-box;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  text-align: left;
`;
