import { v4 as idRandom } from 'uuid';

const initialPositionX = 574;
const initialPositionY = 28;

export const ABANDONED_CART_FREE_NODES = [
  {
    id: `node1`,
    position: { x: initialPositionX, y: initialPositionY },
    data: { label: `Node 1`, name: idRandom() },
    type: 'ABANDONED_CART',
  },
  {
    id: `node2`,
    position: { x: initialPositionX + 200, y: initialPositionY + 200 },
    data: {
      label: `Node 2`,
      name: idRandom(),
      message: {
        id: 'abandoned-cart-free-first-condition',
      },
    },
    type: 'WHATSAPP',
  },
  {
    id: `node3`,
    position: { x: initialPositionX + 400, y: initialPositionY + 800 },
    data: { label: `Node 3`, name: idRandom() },
    type: 'END',
  },
];
