import React from 'react';

import { SortProvider } from '@helpers/hooks/components/useSort';
import { ISortProps } from '@domain/interfaces/components/ISort';
import Wrapper from './Wrapper';

const Sort: React.FC<ISortProps> = ({ handleSortData, config, ...rest }) => {
  return (
    <SortProvider handleSortData={handleSortData} config={config}>
      <Wrapper {...rest} />
    </SortProvider>
  );
};

export default Sort;
