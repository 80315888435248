import logoImg from '@assets/img/brand/profitfy-logo.svg';
import { ECreateStoreStep, EEcommerceOptions } from '@domain/enums/onboarding/ECreateStore';
import { EBusinessType } from '@domain/enums/stores/Etype';
import { useSubscription } from '@helpers/hooks/common/store/subscription/useSubscription';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';
import { useConfig } from '@helpers/hooks/useConfig';
import { useToast } from '@helpers/hooks/useToast';
import { getCreateStoreFormSchema } from '@helpers/validators/stores/newStore';
import { yupResolver } from '@hookform/resolvers/yup';
import { SignOut, Storefront } from 'phosphor-react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import FinishedStep from './FinishedStep';
import FirstStep from './FirstStep';
import InfoStepModal from './InfoStepModal';
import SecondStep from './SecondStep';
import StoreNameStep from './StoreNameStep';
import * as S from './styles';

const CreateStore: React.FC = () => {
  const theme = useTheme();
  const { toast } = useToast();
  const history = useHistory();
  const { handleLogout, stores, analytics, user } = useConfig();
  const { createStore, isCreatingStore } = useOnboarding();
  const { createTrial } = useSubscription();

  const [step, setStep] = React.useState<ECreateStoreStep>(ECreateStoreStep.FIRST_STEP);

  const formSchema = getCreateStoreFormSchema(step === ECreateStoreStep.STORE_NAME_STEP);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  });

  const [storeSegment, setStoreSegment] = React.useState<EBusinessType>(EBusinessType.DROPSHIPPING);
  const [storeAliasIdOnboarding, setStoreAliasIdOnboarding] = React.useState<string>('');
  const [selectedSecondAnswer, setSelectedSecondAnswer] = React.useState<EEcommerceOptions>(
    EEcommerceOptions.VIRTUAL_STORE_ONLY,
  );
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const toggle = React.useCallback(() => setIsModalOpen(state => !state), []);
  const handleStoreSegment = React.useCallback(answer => setStoreSegment(answer), []);
  const handleSecondAnswer = React.useCallback(answer => setSelectedSecondAnswer(answer), []);
  const handleShowMyStores = React.useCallback(() => history.push('/stores'), [history]);

  const handleStep = React.useCallback(backStep => {
    setStep(backStep);
  }, []);

  const parseDomain = React.useCallback(
    formData => {
      const { store_name } = formData;

      const normalizedDomainValue = store_name.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
      const normalizedSegment = storeSegment.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');

      return `https://${normalizedSegment}.${normalizedDomainValue}-${Date.now()}.com`;
    },
    [storeSegment],
  );

  const onSubmit = React.useCallback(
    async formData => {
      if (step === ECreateStoreStep.FIRST_STEP) {
        analytics?.track('Store Segment Selected', {
          button_source: storeSegment,
          email: user?.email,
        });

        if (storeSegment === EBusinessType.TRADITIONAL_ECOMMERCE) {
          setStep(ECreateStoreStep.SECOND_STEP);
          return;
        }
        setStep(ECreateStoreStep.STORE_NAME_STEP);
        return;
      }

      if (step === ECreateStoreStep.SECOND_STEP) {
        analytics?.track('Ecommerce Operation Type', {
          answer: selectedSecondAnswer,
          email: user?.email,
        });

        if (selectedSecondAnswer === EEcommerceOptions.VIRTUAL_STORE_ONLY) {
          setStep(ECreateStoreStep.STORE_NAME_STEP);
          return;
        }

        setIsModalOpen(true);
        return;
      }

      const storeName = formData?.store_name;
      const storeTimezone = formData?.timezone;

      const foundStore = stores?.find(store => store.name === storeName);

      if (foundStore) {
        toast.error('Nome de loja já existente, escolha outro nome.');

        return;
      }

      const data = {
        name: storeName,
        iana_timezone: storeTimezone,
        domain: parseDomain(formData),
        type: storeSegment,
      };

      const storeAliasId = await createStore(data);

      if (!storeAliasId) return;

      const dataLayer = (window as Record<string, any>)?.dataLayer;

      if (dataLayer) {
        dataLayer.push({
          event: 'Store Creation Form Submitted',
          store_name: storeName,
          store_timezone: storeTimezone,
          store_type: storeSegment,
        });
      }

      analytics?.track(
        'Account Created',
        {
          account_name: storeName,
          timezone: storeTimezone,
          domain: parseDomain(formData),
          email: user?.email,
          type: storeSegment,
        },
        { context: { groupId: storeAliasId } },
      );

      analytics?.trackHubSpot(storeAliasId, { is_onboarding_started: true });

      history.push(`/${storeAliasId}/dashboard/overview/financial`);

      // setStoreAliasIdOnboarding(storeAliasId);

      // analytics?.trackHubSpot(storeAliasId, { is_onboarding_finished: false });

      // analytics?.trackHubSpot(storeAliasId, { account_created: true });

      // setStep(ECreateStoreStep.FINISHED_STEP);

      // await createTrial(storeAliasId);
    },
    [
      analytics,
      createStore,
      parseDomain,
      storeSegment,
      selectedSecondAnswer,
      step,
      stores,
      toast,
      user.email,
      history,
      // createTrial,
    ],
  );

  return (
    <S.Container>
      <S.ProgressBar step={step} />
      <S.HeaderWrapper>
        <S.LogoWrapper>
          <S.Logo src={logoImg} alt="profitfy-logo" />
        </S.LogoWrapper>

        <S.LogoutWrapper>
          <S.Button onClick={handleShowMyStores}>
            <S.IconWrapper>
              <Storefront size={20} color={theme.colors.gray[2]} />
            </S.IconWrapper>

            <S.MyStoresText>Ver minhas lojas</S.MyStoresText>
          </S.Button>

          <S.Button onClick={handleLogout}>
            <S.IconWrapper>
              <SignOut size={20} color={theme.colors.green.default} />
            </S.IconWrapper>

            <S.Text>Sair</S.Text>
          </S.Button>
        </S.LogoutWrapper>
      </S.HeaderWrapper>

      <S.Wrapper>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          {step === ECreateStoreStep.FIRST_STEP && (
            <FirstStep handleStoreSegment={handleStoreSegment} storeSegment={storeSegment} />
          )}

          {step === ECreateStoreStep.SECOND_STEP && (
            <SecondStep
              handleSecondAnswer={handleSecondAnswer}
              selectedAnswer={selectedSecondAnswer}
              goBack={handleStep}
            />
          )}

          <InfoStepModal
            isOpen={isModalOpen}
            toggle={toggle}
            selectedAnswer={selectedSecondAnswer}
            handleStep={handleStep}
          />

          {step === ECreateStoreStep.STORE_NAME_STEP && (
            <StoreNameStep errors={errors} register={register} goBack={handleStep} />
          )}

          {step === ECreateStoreStep.FINISHED_STEP && (
            <FinishedStep storeAliasId={storeAliasIdOnboarding} />
          )}

          {step !== ECreateStoreStep.INFO_STEP && step !== ECreateStoreStep.FINISHED_STEP && (
            <S.SubmitButton type="submit" isLoading={isCreatingStore} disabled={isCreatingStore}>
              {step === ECreateStoreStep.STORE_NAME_STEP ? 'Criar loja' : 'Continuar'}
            </S.SubmitButton>
          )}
        </S.Form>
      </S.Wrapper>
    </S.Container>
  );
};

export default CreateStore;
