import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import SelectBase from '@components/common/core/Inputs/Select';
import ButtonBase from '@components/common/core/Inputs/Button';
import OptionBase from '@components/common/core/Inputs/Select/Option';

export const ProfileNameText = styled(HeadingBase)``;

export const Title = styled(HeadingBase)``;

export const AvatarTextWrapper = styled.div`
  margin-left: 16px;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  @media only screen and (max-width: 820px) {
    max-width: 322px;
    margin: 0 auto;
    margin-bottom: 32px;
  }
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background[8]};
  width: 100%;
  height: fit-content;
  padding: 32px;
  border-radius: 12px;
  box-sizing: border-box;
`;

export const Option = styled(OptionBase)``;

export const Button = styled(ButtonBase)`
  width: fit-content;
  margin-left: auto;
`;

export const YearBirthSelect = styled(SelectBase)``;

export const MonthBirthSelect = styled(SelectBase)``;

export const DayBirthSelect = styled(SelectBase)``;

export const UserRoleSelect = styled(SelectBase)`
  width: 100%;

  & > label > select {
    width: 100%;
  }
`;

export const DateBirthSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 374px) {
    flex-direction: column;

    & > div:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

export const Input = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  width: 100%;
  margin-bottom: 24px;
`;

export const InputsGroupWrapper = styled.div`
  display: flex;
  gap: 24px;
  max-width: 677px;
  width: 100%;

  @media only screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const Heading = styled(HeadingBase)``;

export const HeaderWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export const PersonInfoWrapper = styled.div`
  @media only screen and (max-width: 820px) {
    max-width: 322px;
    margin: 0 auto;
  }
`;
