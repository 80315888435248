import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import RadioBase from '@components/common/core/Inputs/Radio';
import BadgeBase from '@components/common/core/DataDisplay/Badge';

export const Badge = styled(BadgeBase)``;

export const OptionText = styled(TextBase)`
  display: inline-block;
  margin-left: 8px;
  margin-right: 16px;
  margin-top: 1px;
  font-size: 0.875rem;
`;

export const RadioInput = styled(RadioBase)``;

export const RadioLabel = styled(LabelBase)`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
`;

export const Label = styled(LabelBase)`
  font-size: 1rem;
  margin-bottom: 16px;
`;

export const InputGroup = styled(InputGroupBase)``;

export const Description = styled(TextBase)`
  display: inline-block;
  margin-bottom: 24px;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[2]};
  line-height: 120%;
`;

export const Title = styled(HeadingBase)`
  display: inline-block;
  margin-bottom: 12px;
`;

export const Wrapper = styled.div``;
