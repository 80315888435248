import React from 'react';

import { Calendar } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import Datepicker from 'react-datepicker';

import { ICustomDateRangePickerProps } from '@domain/interfaces/dashboard/TaxesAndFees/CheckoutFee/ICheckoutFee';

import { useDate } from '@helpers/hooks/useDate';
import { useConfig } from '@helpers/hooks/useConfig';

import Text from '@components/common/core/DataDisplay/Text';
import Header from './Header';

import * as S from './styles';

const CustomDate: React.FC<ICustomDateRangePickerProps> = ({
  startDate,
  endDate,
  onChange,
  isOpen,
  handleIsOpen,
  minDate,
  maxDate,
  calendarOnTop,
}) => {
  const { format } = useDate();
  const { isInternalTeam } = useConfig();
  const theme: any = useTheme();

  const getNameOfDay = React.useCallback(
    nameOfDay =>
      ({
        Sunday: 'Dom',
        Monday: 'Seg',
        Tuesday: 'Ter',
        Wednesday: 'Qua',
        Thursday: 'Qui',
        Friday: 'Sex',
        Saturday: 'Sab',
      }[nameOfDay]),
    [],
  );

  const handleOnChange = React.useCallback(
    dates => {
      const [start, end] = dates;

      onChange([start, end]);
    },
    [onChange],
  );

  return (
    <S.DateInputsWrapper>
      <S.InputWrapper className="input-wrapper">
        <S.DateButtonLabel>Começa em:</S.DateButtonLabel>
        <S.DateButton type="button" onClick={handleIsOpen}>
          <Text>{startDate && format(startDate, 'dd/MM/yyyy')}</Text>
          <Calendar size={20} outline color={theme.colors.font} />
        </S.DateButton>
      </S.InputWrapper>

      <S.InputWrapper className="input-wrapper">
        <S.DateButtonLabel>Termina em:</S.DateButtonLabel>
        <S.DateButton type="button" onClick={handleIsOpen}>
          <Text>{endDate && format(endDate, 'dd/MM/yyyy')}</Text>
          <Calendar size={20} outline color={theme.colors.font} />
        </S.DateButton>
      </S.InputWrapper>

      <S.CalendarWrapper calendarOnTop={calendarOnTop} isOpen={isOpen}>
        <Datepicker
          minDate={isInternalTeam ? undefined : minDate}
          maxDate={maxDate}
          selected={startDate}
          onChange={handleOnChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          inline
          formatWeekDay={nameOfDay => getNameOfDay(nameOfDay)}
          peekNextMonth={false}
          renderCustomHeader={({ date, changeYear, decreaseMonth, increaseMonth }) => (
            <Header
              date={date}
              changeYear={changeYear}
              decreaseMonth={decreaseMonth}
              increaseMonth={increaseMonth}
            />
          )}
        />
      </S.CalendarWrapper>
    </S.DateInputsWrapper>
  );
};

export default CustomDate;
