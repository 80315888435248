import styled from 'styled-components/macro';

export const Container = styled.div`
  position: relative;
  max-width: 716px;
  width: 100%;

  .pagination {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;
