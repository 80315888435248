import React from 'react';

import { IProfileProvider } from '@domain/interfaces/dashboard/Profile/IProfile';

import { useToast } from '@helpers/hooks/useToast';
import { useDate } from '@helpers/hooks/useDate';
import { useConfig } from '@helpers/hooks/useConfig';

import profileService from '@services/pages/dashboard/profile/profile';
import personalInfoService from '@services/pages/auth/personalInfo';
import userService from '@services/user';

const ProfileContext = React.createContext<IProfileProvider | null>(null);

export const ProfileProvider: React.FC = ({ children }) => {
  const {
    profile,
    isLoading: isLoadingProfile,
    error: profileError,
    mutate: mutateProfile,
    isValidating: isValidatingProfile,
  } = profileService.getProfile();
  const {
    personalInfo,
    isLoading: isLoadingPersonalInfo,
    isValidating: isValidatingPersonalInfo,
    mutate: mutatePersonalInfo,
    error: personalInfoError,
  } = personalInfoService.getPersonalInfo();
  const { toast } = useToast();
  const { format } = useDate();
  const { handleUser, handlePersonalInfo, authorization } = useConfig();

  const mutate = React.useCallback(async () => {
    const promises = [];

    if (profileError) promises.push(mutateProfile());

    if (personalInfoError) promises.push(mutatePersonalInfo());

    await Promise.all(promises);
  }, [profileError, mutateProfile, personalInfoError, mutatePersonalInfo]);

  const updatePersonalInfo = React.useCallback(
    async (data, phoneValue) => {
      const birthDate = format(
        new Date(Number(data?.birth_year), Number(data?.birth_month - 1), Number(data?.birth_day)),
        'yyyy-MM-dd',
      );

      const profileData = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
      };

      const personalInfoData = {
        birth_date: birthDate,
        phone_number: phoneValue,
        role: data.role,
      };

      try {
        const { data: userData } = await userService.getUserPromise(authorization);

        const { user } = userData;

        const { data: updatedProfileData } = await profileService.updateProfile({
          profileData: {
            ...profileData,
            has_answered_pql: user.has_answered_pql,
          },
        });

        const { data: updatedPersonalInfoData } = await personalInfoService.updatePersonalInfo({
          data: personalInfoData,
        });

        toast.success('Perfil salvo com sucesso!');
        handleUser({
          ...updatedProfileData?.user,
          user_intercom_hash: updatedProfileData?.user_intercom_hash,
        });
        handlePersonalInfo(updatedPersonalInfoData.personal_info);

        await Promise.all([mutateProfile(), mutatePersonalInfo()]);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
    [
      format,
      handlePersonalInfo,
      handleUser,
      mutateProfile,
      mutatePersonalInfo,
      toast,
      authorization,
    ],
  );

  const isLoading = isLoadingProfile || isLoadingPersonalInfo;
  const isValidating = isValidatingProfile || isValidatingPersonalInfo;
  const isError = Boolean(profileError) || Boolean(personalInfoError);

  return (
    <ProfileContext.Provider
      value={{
        isError,
        isLoading,
        isValidating,
        mutate,
        personalInfo,
        profile,
        mutatePersonalInfo,
        mutateProfile,
        updatePersonalInfo,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = (): IProfileProvider => {
  const context = React.useContext(ProfileContext);

  if (!context) {
    throw new Error('useProfile must be used within ProfileProvider');
  }

  return context;
};
