import React from 'react';
import { useForm } from 'react-hook-form';
import { Hubsale as Icon, SimpleCheck } from '@profitfy/pakkun-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { ETextSize } from '@domain/enums/components/EText';
import { IParams } from '@domain/interfaces/IParams';

import { useEcommerce } from '@helpers/hooks/pages/onboarding/useEcommerce';
import { useToast } from '@helpers/hooks/useToast';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { hubsaleSchema } from '@helpers/validators/dashboard/integrationCenter/hubsale';

import hubsaleCredentialService from '@services/pages/dashboard/integrationCenter/gateway/hubsale';

import NextStep from '@components/Onboarding/NextStep';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const HubSale: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(hubsaleSchema) });
  const { handleNextGatewayToIntegrate, selectedMarketings } = useEcommerce();
  const { storeAliasId } = useParams<IParams>();
  const history = useHistory();
  const { toast } = useToast();

  const [isLoadingSubmittedForm, setIsLoadingSubmittedForm] = React.useState<boolean>(false);
  const [clientIdValue, setClientIdValue] = React.useState<string>('');
  const [clientSecretValue, setClientSecretValue] = React.useState<string>('');

  const clientIdRegister = register('client_id');
  const clientSecretRegister = register('client_secret');

  const onClientIdValueChange = React.useCallback(
    event => {
      clientIdRegister.onChange(event);

      setClientIdValue(event.target.value);
    },
    [clientIdRegister],
  );

  const onClientSecretValueChange = React.useCallback(
    event => {
      clientSecretRegister.onChange(event);

      setClientSecretValue(event.target.value);
    },
    [clientSecretRegister],
  );

  const onSubmit = React.useCallback(
    async data => {
      setIsLoadingSubmittedForm(true);

      try {
        await hubsaleCredentialService.createCredential({
          storeAliasId,
          data,
        });

        toast.success('Credencial salva com sucesso!');

        setIsLoadingSubmittedForm(false);

        const hasNextGateway = handleNextGatewayToIntegrate();

        if (!hasNextGateway && selectedMarketings.length) {
          history.push(`/onboarding/${storeAliasId}/ecommerce/integrations/marketing`);
        }

        if (!hasNextGateway && !selectedMarketings.length) {
          history.push(`/onboarding/${storeAliasId}/ecommerce/integrations/integration-finished`);
        }
      } catch (error: any) {
        setIsLoadingSubmittedForm(false);
        toast.error(getTranslatedIntegrationsError(error));
      }
    },
    [history, handleNextGatewayToIntegrate, storeAliasId, selectedMarketings, toast],
  );

  const hasAllInputsValue = clientIdValue && clientSecretValue;

  return (
    <S.Wrapper>
      <S.FormWrapper>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Header>
            <S.Title>Integre com Gateway de pagamento</S.Title>
          </S.Header>

          <S.IntegrationTitle>
            <Icon size={36} />

            <S.IntegrationTitleText fontWeight={EHeadingWeight.REGULAR}>
              Integração com Doppus
            </S.IntegrationTitleText>
          </S.IntegrationTitle>

          <S.Body>
            <S.BenefitsWrapper>
              <S.Benefit>
                <SimpleCheck size={12} />
                <S.DescriptionText size={ETextSize.B5}>Exatidão dos dados</S.DescriptionText>
              </S.Benefit>
              <S.Benefit>
                <SimpleCheck size={12} />
                <S.DescriptionText size={ETextSize.B5}>Confiabilidade dos dados</S.DescriptionText>
              </S.Benefit>
            </S.BenefitsWrapper>

            <S.InputGroup>
              <S.Label>Client Id</S.Label>
              <S.Input
                {...clientIdRegister}
                placeholder="Client Id Doppus"
                type="text"
                id="hubsale-client-id"
                onChange={onClientIdValueChange}
                value={clientIdValue}
                isError={errors.client_id}
              />
              {errors.client_id && <Text isErrorFeedback>{errors.client_id.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Client Secret</S.Label>
              <S.Input
                {...clientSecretRegister}
                placeholder="Client Secret Doppus"
                type="text"
                id="hubsale-client-secret"
                onChange={onClientSecretValueChange}
                value={clientSecretValue}
                isError={errors.client_secret}
              />
              {errors.client_secret && <Text isErrorFeedback>{errors.client_secret.message}</Text>}
            </S.InputGroup>
          </S.Body>

          <NextStep
            type="submit"
            isLoading={isLoadingSubmittedForm}
            disabled={isLoadingSubmittedForm || !hasAllInputsValue}
          >
            Próximo passo
          </NextStep>
        </S.Form>
      </S.FormWrapper>

      <S.TutorialWrapper>
        <S.TutorialImage
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/onboarding/images/content/onboarding/onboarding-integration-tutorial.svg"
          alt="Profitfy.me Integration Tutorial"
        />
        <S.TutorialTitle>Tutorial de integração</S.TutorialTitle>
        <S.TutorialContent>
          Para realizar a integração você precisará do <strong>Client ID</strong> e do{' '}
          <strong>Client Secret</strong> que são encontrados neste link:
        </S.TutorialContent>
        <S.TutorialLink
          href="https://ajuda.profitfy.me/pt-BR/articles/5901842-como-integrar-com-a-doppus"
          target="_blank"
        >
          Encontre suas credenciais clicando aqui!
        </S.TutorialLink>
      </S.TutorialWrapper>
    </S.Wrapper>
  );
};

export default HubSale;
