import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Description = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.gray[2]};
  max-width: 494px;
  width: 100%;
`;

export const Title = styled(HeadingBase)`
  max-width: 620px;
  width: 100%;
  font-size: 1.75rem;
  color: ${({ theme }) => theme.colors.white.default};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 28px;
`;
