import React from 'react';

import { EBoletoOrderStatusPicker } from '@domain/enums/dashboard/automations/node/conditionalNode/EBoleto';

import * as S from './styles';

interface IPickerOrderStatusProps {
  defaultValue?: string;
  onChange: (data: string) => void;
}

const PickerOrderStatus: React.FC<IPickerOrderStatusProps> = ({
  defaultValue = EBoletoOrderStatusPicker.PAID,
  onChange,
}) => {
  return (
    <S.Select
      name="picker"
      defaultValue={defaultValue}
      onChange={e => {
        onChange(e.target.value);
      }}
    >
      <S.Option value={EBoletoOrderStatusPicker.CANCELED}>Cancelado</S.Option>
      <S.Option value={EBoletoOrderStatusPicker.CHARGED_BACK}>Chargedback</S.Option>
      <S.Option value={EBoletoOrderStatusPicker.IN_ANALYSIS}>Em análise</S.Option>
      <S.Option value={EBoletoOrderStatusPicker.PAID}>Pago</S.Option>
      <S.Option value={EBoletoOrderStatusPicker.PENDING}>Pendente</S.Option>
      <S.Option value={EBoletoOrderStatusPicker.REFUNDED}>Reembolsado</S.Option>
      <S.Option value={EBoletoOrderStatusPicker.REJECTED}>Rejeitado</S.Option>
    </S.Select>
  );
};

export default PickerOrderStatus;
