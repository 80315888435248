import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';

import {
  ICreateProductTagProps,
  IDeleteProductTagProps,
  IGetProductTagProps,
  IGetProductTagResponse,
  IListProductTagsProps,
  IListProductTagsResponse,
  IUpdateProductTagProps,
} from '@domain/interfaces/dashboard/ProductTag/IProductTag';

export class ProductTagService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listProductTags({
    storeAliasId,
    productAliasId,
    page,
  }: IListProductTagsProps): IListProductTagsResponse {
    const { data, isValidating, error, mutate } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/product-tags?limit=10&page=${page}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      productTags: data?.product_tags,
      totalPages: data?.total_pages,
      isLoading,
      isValidating,
      error,
      mutate,
    };
  }

  public getProductTag({
    storeAliasId,
    productAliasId,
    productTagAliasId,
  }: IGetProductTagProps): IGetProductTagResponse {
    const { data, isValidating, error, mutate } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/product-tags/${productTagAliasId}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      productTag: data?.product_tag,
      isLoading,
      isValidating,
      error,
      mutate,
    };
  }

  public createProductTag({
    storeAliasId,
    productAliasId,
    data,
  }: ICreateProductTagProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/product-tags`,
      { ...data },
    );
  }

  public updateProductTag({
    storeAliasId,
    productAliasId,
    productTagAliasId,
    data,
  }: IUpdateProductTagProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/product-tags/${productTagAliasId}`,
      { ...data },
    );
  }

  public deleteProductTag({
    storeAliasId,
    productAliasId,
    productTagAliasId,
  }: IDeleteProductTagProps): Promise<any> {
    return this.apiService.delete(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/product-tags/${productTagAliasId}`,
    );
  }
}

const productTagService = new ProductTagService(API_DOMAIN);

export default productTagService;
