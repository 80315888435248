import React from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { MetroSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';

import { useSocket } from '@helpers/hooks/pages/dashboard/whatsappInstance/useSocket';
import { useQuery } from '@helpers/hooks/useQuery';
import { useWhatsappInstance } from '@helpers/hooks/pages/dashboard/whatsappInstance/useWhatsappInstance';
import { useToast } from '@helpers/hooks/useToast';

import AndroidWhatsappConfigImg from '@assets/dashboard/whatsapp/android-whatsapp-config.svg';
import IOSWhatsappConfigImg from '@assets/dashboard/whatsapp/ios-whatsapp-config.svg';

import GoBack from '@components/common/core/Utils/GoBack';
import NewInstanceModal from './NewInstanceModal';
import ConnectedInstanceModal from './ConnectedInstanceModal';

import * as S from './styles';

const NewInstance: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const query = useQuery();

  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { socket } = useSocket();

  const {
    isNewInstanceModalOpen,
    handleNewInstanceModalOpen,
    mutateWhatsappCredential,
    handleShowConnectedInstance,
    showConnectedInstance,
  } = useWhatsappInstance();

  const [imageURL, setImageURL] = React.useState<string>('');
  const [credentialAliasId, setCredentialAliasId] = React.useState<string>('');
  const [isLoadingImageURL, setIsLoadingImageURL] = React.useState<boolean>(true);

  const shouldReconnect = query.get('reconnect');

  const initConection = React.useCallback(() => {
    socket.emit('qrCode:request', { store_alias_id: storeAliasId });
  }, [socket, storeAliasId]);

  const getQRCodeData = React.useCallback(() => {
    socket.on('qrCode:read', data => {
      if (data.image) {
        setIsLoadingImageURL(false);
        setImageURL(data.image);
      }
    });
  }, [socket]);

  const handleSuccess = React.useCallback(() => {
    socket.on('qrCode:success', data => {
      if (data && shouldReconnect === 'true') {
        toast.success('Instância reconectada com sucesso.');

        mutateWhatsappCredential();

        history.push(`/${storeAliasId}/dashboard/marketing/messages-instances`);
      } else if (data) {
        setCredentialAliasId(data.whatsapp_credential.alias_id);
        handleNewInstanceModalOpen();
      }
    });
  }, [
    socket,
    handleNewInstanceModalOpen,
    shouldReconnect,
    mutateWhatsappCredential,
    toast,
    history,
    storeAliasId,
  ]);

  const handleFailure = React.useCallback(() => {
    socket.on('qrCode:error', data => {
      if (data) {
        toast.error(data.message);
      }
    });
  }, [socket, toast]);

  const handleGoBack = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/marketing/messages-instances`);
  }, [history, storeAliasId]);

  React.useEffect(() => {
    if (socket) {
      initConection();
      getQRCodeData();
      handleSuccess();
      handleFailure();
    }
  }, [socket, initConection, getQRCodeData, handleSuccess, handleFailure]);

  return (
    <S.Content>
      {shouldReconnect === 'true' && <GoBack text="Voltar às instâncias" onClick={handleGoBack} />}

      <S.HeadingAndQrCodeWrapper>
        <S.HeadingAndStepsWrapper>
          <S.Heading type={EHeadingSize.H3}>
            Para criar uma nova <mark>instância do Whatsapp</mark>:
          </S.Heading>

          <S.StepsWrapper>
            <S.StepText>1. Abra o Whatsapp no seu celular.</S.StepText>

            <S.StepText>
              2. Toque em <strong>Mais opções</strong>
              <S.WhatsappConfigImg
                src={AndroidWhatsappConfigImg}
                alt="profitfy-android-whatsapp-config"
              />
              ou <strong>Configurações</strong>
              <S.WhatsappConfigImg src={IOSWhatsappConfigImg} alt="profitfy-ios-whatsapp-config" />e
              selecione <br /> <strong>Aparelhos conectados.</strong>
            </S.StepText>

            <S.StepText>
              3. Toque em <strong>Conectar um aparelho.</strong>
            </S.StepText>

            <S.StepText>4. Aponte seu celular para esta tela para capturar o código.</S.StepText>

            <S.StepText>
              5. Assim que validarmos a configuração, direcionaremos você <br />
              para a próxima etapa.
            </S.StepText>
          </S.StepsWrapper>
        </S.HeadingAndStepsWrapper>

        <S.QRCodeWrapper>
          {isLoadingImageURL && (
            <S.LoadingQRCodeWrapper>
              <MetroSpinner
                size={40}
                color={theme.colors.green.default}
                loading={isLoadingImageURL}
              />

              <S.LoadingQRCodeWarn>
                Carregando QR Code. <br />
                Essa ação pode demorar alguns minutos.
              </S.LoadingQRCodeWarn>
            </S.LoadingQRCodeWrapper>
          )}

          {!isLoadingImageURL && (
            <S.QRCodeImage src={imageURL} alt="profitfy-whatsapp-qr-code-image" />
          )}
        </S.QRCodeWrapper>
      </S.HeadingAndQrCodeWrapper>

      <NewInstanceModal
        isOpen={isNewInstanceModalOpen}
        toggle={handleNewInstanceModalOpen}
        credentialAliasId={credentialAliasId}
      />

      <ConnectedInstanceModal isOpen={showConnectedInstance} toggle={handleShowConnectedInstance} />
    </S.Content>
  );
};

export default NewInstance;
