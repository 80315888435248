import styled from 'styled-components/macro';

export const ProviderAndMarketingWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 48px;

  @media only screen and (max-width: 702px) {
    gap: 24px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 48px;

  @media only screen and (max-width: 702px) {
    display: block;
    margin: 0 auto;
  }
`;

export const ComponentErrorWrapper = styled.div`
  max-width: 842px;
  width: 100%;
  margin: auto;
  padding: 142px 0;
  box-sizing: border-box;
`;

export const Wrapper = styled.div`
  padding: 142px 32px;
  max-width: calc(842px + 64px);
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;

  @media only screen and (max-width: 1028px) {
    max-width: calc(620px + 64px);
  }
`;
