import React from 'react';
import { MetroSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components/macro';

import { ILoadingScreenProps } from '@domain/interfaces/components/ILoadingScreen';

import * as S from './styles';

const LoadingScreen: React.FC<ILoadingScreenProps> = ({ showChargingSubscriptionText }) => {
  const theme: any = useTheme();

  return (
    <S.Wrapper>
      <MetroSpinner size={64} color={theme.colors.green.default} />

      {showChargingSubscriptionText && (
        <S.Text>Realizando a cobrança no cartão. Por favor, não atualize a página.</S.Text>
      )}
    </S.Wrapper>
  );
};

export default LoadingScreen;
