import React from 'react';

import { Hand, MarketingCircle, Sync } from '@profitfy/pakkun-icons';
import { useHistory, useParams } from 'react-router-dom';
import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { useTheme } from 'styled-components/macro';
import { IParams } from '@domain/interfaces/IParams';

import * as S from './styles';

const NoAdSenseCampaignsCard: React.FC = () => {
  const history = useHistory();
  const theme = useTheme();
  const { storeAliasId } = useParams<IParams>();

  const { handleNewCustomAdsSideModalOpen } = useFinancialDashboard();

  const [isSyncButtonHovering, setIsSyncButtonHovering] = React.useState(false);
  const [isAddManualAdsButtonHovering, setIsAddManualAdsButtonHovering] = React.useState(false);

  const onSyncButtonMouseEnter = React.useCallback(() => {
    setIsSyncButtonHovering(true);
  }, []);

  const onSyncButtonMouseLeave = React.useCallback(() => {
    setIsSyncButtonHovering(false);
  }, []);

  const onAddManualAdsButtonMouseEnter = React.useCallback(() => {
    setIsAddManualAdsButtonHovering(true);
  }, []);

  const onAddManualAdsButtonMouseLeave = React.useCallback(() => {
    setIsAddManualAdsButtonHovering(false);
  }, []);

  const handleSyncButtonClick = React.useCallback(() => {
    const referralQueryString = 'marketing=true';

    history.push(`/${storeAliasId}/dashboard/data/integrations?${referralQueryString}`);
  }, [history, storeAliasId]);

  return (
    <S.CardWrapper>
      <S.Content>
        <MarketingCircle size={40} />

        <S.Text>
          Nenhum custo de Marketing. Para adicionar{' '}
          <S.Popover>
            <S.PopoverTrigger>clique aqui</S.PopoverTrigger>

            <S.PopoverContent>
              <S.ActionButton
                onClick={handleSyncButtonClick}
                onMouseEnter={onSyncButtonMouseEnter}
                onMouseLeave={onSyncButtonMouseLeave}
              >
                <Sync
                  size={16}
                  color={isSyncButtonHovering ? theme.colors.green.default : theme.colors.font}
                />
                Sincronizar
              </S.ActionButton>
              <S.ActionButton
                onClick={handleNewCustomAdsSideModalOpen}
                onMouseEnter={onAddManualAdsButtonMouseEnter}
                onMouseLeave={onAddManualAdsButtonMouseLeave}
              >
                <Hand
                  size={16}
                  color={
                    isAddManualAdsButtonHovering ? theme.colors.green.default : theme.colors.font
                  }
                />
                Lançamento Manual
              </S.ActionButton>

              <S.Popover.Arrow />
            </S.PopoverContent>
          </S.Popover>
        </S.Text>
      </S.Content>
    </S.CardWrapper>
  );
};

export default NoAdSenseCampaignsCard;
