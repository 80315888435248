import React from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SimpleCheck } from '@profitfy/pakkun-icons';

import { EProgressBarStep } from '@domain/enums/onboarding/progressBar/ESteps';
import { EButtonTextColor } from '@domain/enums/components/EButton';
import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { ETextSize } from '@domain/enums/components/EText';
import { EAlertVariant } from '@domain/enums/components/EAlert';
import { EStep } from '@domain/enums/onboarding/EStep';
import { EType } from '@domain/enums/onboarding/EType';
import { IParams } from '@domain/interfaces/IParams';
import { IOnboardingStep } from '@domain/interfaces/onboarding/IOnboarding';

import { useToast } from '@helpers/hooks/useToast';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';
import { useDropshipping } from '@helpers/hooks/pages/onboarding/useDropshipping';
import { useConfig } from '@helpers/hooks/useConfig';

import { currencyFormatter, currencyToNumber, numberFormatter } from '@helpers/masks';
import {
  checkoutFeeFixedSchema,
  checkoutFeePercentageSchema,
} from '@helpers/validators/dashboard/integrationCenter/checkoutFee';

import SideBar from '@components/Onboarding/SideBar';
import ComponentError from '@components/common/core/Utils/ComponentError';
import Text from '@components/common/core/DataDisplay/Text';
import Form from '@components/common/core/Inputs/Form';
import NextStep from '@components/Onboarding/NextStep';

import checkoutFeeService from '@services/pages/dashboard/checkoutFee/checkoutFee';
import onboardingStepService from '@services/pages/onboarding/onboardingStep';

import * as S from './styles';

const CheckoutFee: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { addStep, completeStep } = useOnboarding();
  const { analytics, user } = useConfig();
  const { handleStoreBonus, isLoadingTrial } = useDropshipping();

  const {
    onboardingSteps,
    mutate: mutateOnboardingSteps,
  } = onboardingStepService.getOnboardingSteps({ storeAliasId });

  const { checkoutFee, error, isValidating, mutate } = checkoutFeeService.getDefaultCheckoutFee({
    storeAliasId,
  });

  const percentageInitialValue = numberFormatter(checkoutFee?.percentage_amount || 2.5, 2);
  const fixedInitialValue = numberFormatter(checkoutFee?.amount || 2.5, 2);

  const [type, setType] = React.useState<string>('percentage');
  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [showAlertCheckoutFee, setShowAlertCheckoutFee] = React.useState<boolean>(false);
  const [isBenefitsTimeOutFinished, setIsBenefitsTimeOutFinished] = React.useState<boolean>(false);
  const [percentageInputValue, setPercentageInputValue] = React.useState<string>(
    percentageInitialValue,
  );
  const [fixedInputValue, setFixedInputValue] = React.useState<string>(fixedInitialValue);
  const [isRadioGroupTimeOutFinished, setIsRadioGroupTimeOutFinished] = React.useState<boolean>(
    false,
  );
  const [isInputGroupTimeOutFinished, setIsInputGroupTimeOutFinished] = React.useState<boolean>(
    false,
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(
      type === 'percentage' ? checkoutFeePercentageSchema : checkoutFeeFixedSchema,
    ),
  });

  const fixedInputRegister = register('fixed');
  const percentageInputRegister = register('percentage');

  const onRadioChange = React.useCallback(event => {
    setType(event.target.value);
  }, []);

  const onFixedChange = React.useCallback(
    event => {
      fixedInputRegister.onChange(event);

      const fixedValue = event.target.value;

      const formattedValue = currencyFormatter(fixedValue);

      setFixedInputValue(formattedValue);
    },
    [fixedInputRegister],
  );

  const onPercentageChange = React.useCallback(
    event => {
      percentageInputRegister.onChange(event);

      const percentageValue = event.target.value;

      const formattedValue = currencyFormatter(percentageValue);

      const higherFeeChargedByCheckouts = 2.5;

      if (currencyToNumber(formattedValue) > higherFeeChargedByCheckouts) {
        setShowAlertCheckoutFee(true);
      }

      setPercentageInputValue(formattedValue);
    },
    [percentageInputRegister],
  );

  const onSubmit = React.useCallback(
    async data => {
      setIsSubmittingForm(true);

      try {
        if (!checkoutFee) {
          await checkoutFeeService.createDefaultCheckoutFee({
            storeAliasId,
            data: {
              amount: type === 'fixed' ? currencyToNumber(data.fixed) : 0,
              name: 'any_checkout_fee_name',
              percentage_amount: type === 'percentage' ? currencyToNumber(data.percentage) : 0,
            },
          });
        } else {
          await checkoutFeeService.updateDefaultCheckoutFee({
            storeAliasId,
            checkoutFeeAliasId: checkoutFee.alias_id,
            data: {
              amount: type === 'fixed' ? currencyToNumber(data.fixed) : 0,
              name: 'any_checkout_fee_name',
              percentage_amount: type === 'percentage' ? currencyToNumber(data.percentage) : 0,
            },
          });
        }

        const { data: onboardingStepsData } = await mutateOnboardingSteps();

        const foundOnboardingStep:
          | IOnboardingStep
          | undefined = onboardingStepsData.onboarding_steps.find(
          (onboardingStep: IOnboardingStep) =>
            onboardingStep.configuration_step === EStep.STORE_CHECKOUT_FEE_CONFIG,
        );

        if (foundOnboardingStep) {
          completeStep(foundOnboardingStep.id);
        }

        analytics?.track(
          'Onboarding Checkout Fee Added',
          {
            amount: type === 'fixed' ? currencyToNumber(data.fixed) : 0,
            percentage_amount: type === 'percentage' ? currencyToNumber(data.percentage) : 0,
            email: user?.email,
          },
          { context: { groupId: storeAliasId } },
        );

        toast.success('Taxa de checkout/tarifa criado com sucesso.');

        await handleStoreBonus(storeAliasId);

        setIsSubmittingForm(false);
      } catch {
        setIsSubmittingForm(false);
        toast.error(
          'Ocorreu um problema ao cadastrar a taxa de checkout! Por favor, tente novamente.',
        );
      }
    },
    [
      storeAliasId,
      toast,
      checkoutFee,
      type,
      completeStep,
      mutateOnboardingSteps,
      analytics,
      user,
      handleStoreBonus,
    ],
  );

  React.useEffect(() => {
    const benefitsTimeout = setTimeout(() => {
      setIsBenefitsTimeOutFinished(true);
    }, 700);

    return () => clearTimeout(benefitsTimeout);
  }, []);

  React.useEffect(() => {
    const radioGroupTimeout = setTimeout(() => {
      setIsRadioGroupTimeOutFinished(true);
    }, 1500);

    return () => clearTimeout(radioGroupTimeout);
  }, []);

  React.useEffect(() => {
    const inputGroupTimeout = setTimeout(() => {
      setIsInputGroupTimeOutFinished(true);
    }, 2000);

    return () => clearTimeout(inputGroupTimeout);
  }, []);

  React.useEffect(() => {
    if (checkoutFee) {
      if (checkoutFee.amount > 0) {
        setType('fixed');
      } else {
        setType('percentage');
      }
    }
  }, [checkoutFee]);

  React.useEffect(() => {
    if (checkoutFee) {
      setPercentageInputValue(numberFormatter(checkoutFee.percentage_amount, 2));
      setFixedInputValue(numberFormatter(checkoutFee.amount, 2));
    }
  }, [checkoutFee]);

  React.useEffect(() => {
    if (onboardingSteps) {
      const foundStep = onboardingSteps.find(
        step => step.configuration_step === EStep.STORE_CHECKOUT_FEE_CONFIG,
      );

      if (!foundStep) {
        addStep([
          {
            configuration_step: EStep.STORE_CHECKOUT_FEE_CONFIG,
            type: EType.DROPSHIPPING_INTERNATIONAL,
          },
        ]);
      }
    }
  }, [onboardingSteps, addStep]);

  if (error && !isValidating) {
    return <ComponentError mutate={mutate} />;
  }

  return (
    <S.Container>
      <SideBar
        onboardingStep={EProgressBarStep.GENERAL_CONFIG}
        linkToGoBack={`/onboarding/${storeAliasId}/dropshipping/integrations`}
      />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.Wrapper>
          <S.Heading fontWeight={EHeadingWeight.MEDIUM}>
            Cadastrar taxa de checkout / Tarifa de vendas
          </S.Heading>

          {isBenefitsTimeOutFinished && (
            <S.BenefitsWrapper>
              <S.Benefit>
                <SimpleCheck size={12} />
                <S.BenefitText size={ETextSize.B5}>Exatidão dos dados</S.BenefitText>
              </S.Benefit>
              <S.Benefit>
                <SimpleCheck size={12} />
                <S.BenefitText size={ETextSize.B5}>Confiabilidade dos dados</S.BenefitText>
              </S.Benefit>
            </S.BenefitsWrapper>
          )}

          {isRadioGroupTimeOutFinished && (
            <S.RadioGroup>
              <S.RadioGroupLabel>Escolha como será cobrado:</S.RadioGroupLabel>

              <S.RadioLabel>
                <S.Radio
                  name="checkout-fee-type"
                  onChange={onRadioChange}
                  value="percentage"
                  checked={type === 'percentage'}
                />
                <S.RadioText>Porcentagem</S.RadioText>
              </S.RadioLabel>

              <S.RadioLabel>
                <S.Radio
                  name="checkout-fee-type"
                  onChange={onRadioChange}
                  value="fixed"
                  checked={type === 'fixed'}
                />
                <S.RadioText>Fixo</S.RadioText>
              </S.RadioLabel>
            </S.RadioGroup>
          )}

          {showAlertCheckoutFee && (
            <S.Alert
              variant={EAlertVariant.WARNING}
              description="Por exemplo: Yampi 2,5%, Nuvemshop R$1,99 etc."
            >
              Taxa / Tarifa de checkout, é diferente de taxas do gateway!
            </S.Alert>
          )}

          {isInputGroupTimeOutFinished && (
            <S.InputGroup>
              <S.Label>Insíra o valor de tarifa por venda concluída</S.Label>
              {type === 'percentage' ? (
                <>
                  <S.Input
                    {...percentageInputRegister}
                    isError={Boolean(errors?.percentage)}
                    value={percentageInputValue}
                    onChange={onPercentageChange}
                    type="text"
                    placeholder="2,50"
                    prependText="%"
                  />
                  {errors.percentage && <Text isErrorFeedback>{errors.percentage.message}</Text>}
                </>
              ) : (
                <>
                  <S.Input
                    {...fixedInputRegister}
                    isError={Boolean(errors?.fixed)}
                    value={fixedInputValue}
                    onChange={onFixedChange}
                    type="text"
                    placeholder="2,50"
                    prependText="R$"
                  />
                  {errors.fixed && <Text isErrorFeedback>{errors.fixed.message}</Text>}
                </>
              )}
            </S.InputGroup>
          )}
        </S.Wrapper>

        <NextStep
          isLoading={isSubmittingForm || isLoadingTrial}
          disabled={isSubmittingForm || isLoadingTrial}
          textColor={EButtonTextColor.DARK}
          type="submit"
        >
          Próximo passo
        </NextStep>
      </Form>
    </S.Container>
  );
};

export default CheckoutFee;
