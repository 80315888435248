import styled from 'styled-components/macro';

import SideModalBase from '@components/common/core/DataDisplay/SideModal';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';

export const Checkbox = styled(CheckboxBase)``;

export const CheckboxLabel = styled(LabelBase)`
  margin: unset;
  line-height: 100%;
  cursor: pointer;
`;

export const CheckboxGroup = styled(InputGroupBase)`
  margin: unset;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CampaignsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[7]};
  padding: 24px;
  border-radius: 5px;

  & > div {
    padding: 8px 0;
  }

  & > div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[4]};
  }

  & > div:first-child {
    padding-top: unset;
  }

  & > div:last-child {
    padding-bottom: unset;
    padding-right: 4px;

    ::-webkit-scrollbar {
      width: 4px;
      position: absolute;
    }

    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.gray[4]};
      position: absolute;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.gray[1]};
      border-radius: 25px;
      transition: all 0.3s;
    }

    ::-webkit-scrollbar-thumb:hover {
      opacity: brightness(0.8);
    }

    & > div > div:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray[4]};
    }
  }
`;

export const SearchInput = styled(TextFieldBase)`
  margin-bottom: 16px;
`;

export const Title = styled(HeadingBase)``;

export const Body = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
  margin-bottom: 16px;
`;

export const SideModal = styled(SideModalBase)``;
