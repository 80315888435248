export enum EStep {
  INTEGRATIONS = 'INTEGRATIONS',
  SHOPIFY_CREDENTIAL = 'SHOPIFY_CREDENTIAL',
  NUVEM_SHOP_CREDENTIAL = 'NUVEM_SHOP_CREDENTIAL',
  CART_PANDA_CREDENTIAL = 'CART_PANDA_CREDENTIAL',
  MERCADO_LIVRE_CREDENTIAL = 'MERCADO_LIVRE_CREDENTIAL',
  MERCADO_PAGO_CREDENTIAL = 'MERCADO_PAGO_CREDENTIAL',
  PAG_SEGURO_CREDENTIAL = 'PAG_SEGURO_CREDENTIAL',
  PAGARME_CREDENTIAL = 'PAGARME_CREDENTIAL',
  HUB_SALE_CREDENTIAL = 'HUB_SALE_CREDENTIAL',
  UPNID_CREDENTIAL = 'UPNID_CREDENTIAL',
  APPMAX_CREDENTIAL = 'APPMAX_CREDENTIAL',
  CART_PANDA_PAY = 'CART_PANDA_PAY',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  CHECKOUT_FEE = 'CHECKOUT_FEE',
  TAX = 'TAX',
  DSERS = 'DSERS',
  AVERAGE_COST = 'AVERAGE_COST',
  PRODUCT_COSTS = 'PRODUCT_COSTS',
  PROCESSING = 'PROCESSING',
  FINISHED = 'FINISHED',
  PROCESSING_PRODUCTS = 'PROCESSING_PRODUCTS',
  STORE_INTEGRATIONS = 'STORE_INTEGRATIONS',
  STORE_CHECKOUT_FEE_CONFIG = 'STORE_CHECKOUT_FEE_CONFIG',
  STORE_DSERS_CONFIG = 'STORE_DSERS_CONFIG',
  STORE_ONBOARDING_FINISHED = 'STORE_ONBOARDING_FINISHED',
}
