import React from 'react';
import { PerfectPay as PerfectPayIcon, Copy } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { EInfoProductProvider } from '@domain/enums/common/integrations/EInfoProductProvider';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';
// TODO - replace to perfectpayservice
import perfectPayService from '@services/pages/dashboard/integrationCenter/infoProduct/platforms/perfectPayAuth';

import ActiveIntegrationFooter from '@components/Dashboard/IntegrationCenter/Sidemodal/ActiveIntegrationFooter';
import Text from '@components/common/core/DataDisplay/Text';
import { EKiwifyType } from '@domain/enums/common/integrations/providers/EKiwify';
import { perfectPaySchema } from '@helpers/validators/dashboard/integrationCenter/infoProduct/perfectPay';
import PerfectPayConfig from './PerfectPayConfig';
import SkeletonLoading from './SkeletonLoading';
import ActiveIntegration from './ActiveIntegration';

import * as S from './styles';

const PerfectPay: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const theme = useTheme();

  const { storeIntegrations, mutateStore } = useStoreConfig();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(perfectPaySchema),
  });

  const [webhookURL, setWebhookURL] = React.useState<string>('');
  const [accessTokenValue, setAccessTokenValue] = React.useState<string>('');
  const [providerId, setProviderId] = React.useState<string>('');
  const [credentialAliasId, setCredentialAliasId] = React.useState<string>('');
  const [isURLCopied, setIsURLCopied] = React.useState<boolean>(false);
  const [hasPerfectPayCredential, setHasPerfectPayCredential] = React.useState<boolean>(false);
  const [isGettingWebhookURL, setIsGettingWebhookURL] = React.useState<boolean>(false);
  const [isGettingCredential, setIsGettingCredential] = React.useState<boolean>(false);
  const [isCreatingCredential, setIsCreatingCredential] = React.useState<boolean>(false);

  const hasPerfectPayIntegration = Boolean(
    storeIntegrations?.store_front.find(
      integration => integration.name === EInfoProductProvider.PERFECT_PAY && integration.is_active,
    ),
  );

  const accessTokenInputRegister = register('access_token');
  const providerIdInputRegister = register('provider_id');

  const onAccessTokenChange = React.useCallback(
    event => {
      accessTokenInputRegister.onChange(event);

      setAccessTokenValue(event.target.value);
    },
    [accessTokenInputRegister],
  );

  const onProviderIdChange = React.useCallback(
    event => {
      providerIdInputRegister.onChange(event);

      setProviderId(event.target.value);
    },
    [providerIdInputRegister],
  );

  const handleCopyURL = React.useCallback(() => {
    navigator.clipboard.writeText(webhookURL);
    setIsURLCopied(true);
    toast.success('URL copiada com sucesso.');
  }, [toast, webhookURL]);

  const onSubmit = React.useCallback(
    async formData => {
      setIsCreatingCredential(true);

      const data = {
        public_token: formData.access_token,
        provider_id: formData.provider_id,
      };

      try {
        await perfectPayService.createCredential({ storeAliasId, data });

        toast.success('Credencial criada com sucesso.');

        mutateStore();

        setIsCreatingCredential(false);
      } catch (error: any) {
        toast.error(getTranslatedIntegrationsError(error));
        setIsCreatingCredential(false);
      }
    },
    [mutateStore, storeAliasId, toast],
  );

  const getPerfectPayCredential = React.useCallback(async () => {
    setIsGettingCredential(true);

    try {
      const { data } = await perfectPayService.getCredential({ storeAliasId });

      if (data?.perfect_pay_credential.is_active) {
        setHasPerfectPayCredential(true);
      }

      setCredentialAliasId(data?.perfect_pay_credential.alias_id);

      setIsGettingCredential(false);
    } catch (error: any) {
      console.log('yeeey,', error);
      toast.error(error?.response?.data?.message);
      setIsGettingCredential(false);
    } finally {
      setIsGettingCredential(false);
    }
  }, [toast, storeAliasId]);

  const generateWebhookURL = React.useCallback(async () => {
    setIsGettingWebhookURL(true);

    try {
      const { data } = await perfectPayService.generateWebhookURL({ storeAliasId });

      setWebhookURL(data?.webhook_url);
      setIsGettingWebhookURL(false);
    } catch (error: any) {
      setIsGettingWebhookURL(false);
      toast.error(error?.response?.data?.message);
    }
  }, [toast, storeAliasId]);

  React.useEffect(() => {
    if (hasPerfectPayIntegration) {
      getPerfectPayCredential();
    } else {
      generateWebhookURL();
    }
  }, [getPerfectPayCredential, generateWebhookURL, hasPerfectPayIntegration]);

  if (isGettingWebhookURL || isGettingCredential) {
    return <SkeletonLoading />;
  }

  if (hasPerfectPayIntegration && !hasPerfectPayCredential) {
    return <ActiveIntegration credentialAliasId={credentialAliasId} />;
  }

  if (hasPerfectPayCredential) {
    return <PerfectPayConfig credentialAliasId={credentialAliasId} />;
  }

  return (
    <S.ContentWrapper>
      <S.HeadingWrapper>
        <PerfectPayIcon size={52} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com Perfect Pay
        </S.Heading>
      </S.HeadingWrapper>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>
        <S.FunctionalityDescriptionText>
          A integração com sua plataforma de info-produto serve para puxarmos seus dados de
          plataforma aumentando a exatidão dos seus dados.
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <S.TutorialWrapper>
        <S.TutotialTitle weight={ETextWeight.BOLD}>Tutorial de integração</S.TutotialTitle>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          1 - Copie a URL no campo abaixo.
        </S.TutotialStep>

        <S.WebhookURLWrapper onClick={handleCopyURL} type="button">
          <S.WebhookURL weight={ETextWeight.MEDIUM}>{webhookURL}</S.WebhookURL>
          <S.IconWrapper>
            <Copy size={18} color={theme.colors.gray[1]} outline={!isURLCopied} />
          </S.IconWrapper>
        </S.WebhookURLWrapper>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          2 -{' '}
          <S.Link href="https://app.perfectpay.com.br/ferramentas/webhook" target="_blank">
            Clique aqui e vá para Perfect Pay.
          </S.Link>
        </S.TutotialStep>

        <S.StepsWrapper>
          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>Clique em &quot;Adicionar&quot;</S.StepText>
          </S.StepWrapper>
          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>Cole no campo &quot;URL&quot;</S.StepText>
          </S.StepWrapper>
        </S.StepsWrapper>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          3 - Após colar, copie o token do campo “Public token” na Perfect Pay e cole no campo
          “Token” da Profitfy.
        </S.TutotialStep>

        <S.LastStepWrapper>
          <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
            4 -{' '}
            <S.Link href="https://app.perfectpay.com.br/ferramentas/apis" target="_blank">
              Clique aqui e vá para Perfect Pay.
            </S.Link>
          </S.TutotialStep>
          <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
            Adicione um Token de integração, copie o token e cole no campo “Token de Integração” da
            Profitfy
          </S.TutotialStep>
        </S.LastStepWrapper>
      </S.TutorialWrapper>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label>Public Token</S.Label>

          <S.Input
            {...providerIdInputRegister}
            type="text"
            value={providerId}
            placeholder="Public Token"
            onChange={onProviderIdChange}
            isError={errors?.access_token}
          />
          {errors.provider_id && <Text isErrorFeedback>{errors.provider_id.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Token de Integração</S.Label>

          <S.Input
            {...accessTokenInputRegister}
            type="text"
            value={accessTokenValue}
            placeholder="Token de integração"
            onChange={onAccessTokenChange}
            isError={errors?.access_token}
          />
          {errors.access_token && <Text isErrorFeedback>{errors.access_token.message}</Text>}
        </S.InputGroup>

        <ActiveIntegrationFooter
          isIntegrating={isCreatingCredential}
          disabled={!accessTokenValue || isCreatingCredential}
        />
      </S.Form>
    </S.ContentWrapper>
  );
};

export default PerfectPay;
