import styled from 'styled-components';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import ButtonBase from '@components/common/core/Inputs/Button';
import FormBase from '@components/common/core/Inputs/Form';

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 48px;
  align-self: flex-start;

  @media only screen and (max-width: 1345px) {
    padding-top: 60px;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 491px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
`;

export const Form = styled(FormBase)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 8px;
  padding: 24px 32px;
  box-sizing: border-box;
`;

export const Heading = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.white.default};
  line-height: 100%;
`;

export const InputGroup = styled(InputGroupBase)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[4]};
  padding-bottom: 24px;
`;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)``;

export const FeaturesTitle = styled(TextBase)`
  font-size: 1.125rem;
`;

export const FeaturesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
`;

export const CreateGroupButton = styled(ButtonBase)`
  margin-top: 24px;
  margin-left: auto;
`;
