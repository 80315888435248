import React from 'react';
import { Check, Warning } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { CircleSpinner } from 'react-spinners-kit';

import { IParams } from '@domain/interfaces/IParams';
import { IFilterQueryStringData } from '@domain/interfaces/components/IFilter';
import { EAdSenseProvider } from '@domain/enums/common/EAdSense';

import { useScriptAndUtms } from '@helpers/hooks/pages/dashboard/adsManager/onboarding/useScriptAndUtms';
import { useToast } from '@helpers/hooks/useToast';

import adSenseAdsService from '@services/pages/dashboard/adsManager/adSenseAds';

import ComponentError from '@components/common/core/Utils/ComponentError';

import * as S from './styles';

const AddUtmsInExistingCampaigns: React.FC = () => {
  const theme = useTheme();
  const {
    handleCampaignsSideModalOpen,
    handleNextStep,
    handleAdSenseAds,
    storeSelectedAdSenseAds,
  } = useScriptAndUtms();
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();

  const [isInputChecked, setIsInputChecked] = React.useState<boolean>(false);
  const [adSenseCampaignsWithoutUtmFilter] = React.useState<IFilterQueryStringData>({
    statuses: ['ENABLED'],
    providers: [EAdSenseProvider.FACEBOOK],
  });

  const {
    adSenseAds,
    isLoading: isLoadingAdSenseAds,
    isValidating: isValidatingAdSenseAds,
    mutate: mutateAdSenseAds,
    error: adSenseAdsError,
  } = adSenseAdsService.listAdSenseAdsWithoutUtm({
    storeAliasId,
    filter: adSenseCampaignsWithoutUtmFilter,
  });

  const onCheckChange = React.useCallback(event => {
    if (event && !event.target) {
      setIsInputChecked(event.checked);
    }
  }, []);

  const onAddUtmTagsClick = React.useCallback(() => {
    if (storeSelectedAdSenseAds.length) {
      handleNextStep();
    } else {
      toast.error('Selecione pelo menos um criativo para poder continuar.');
    }
  }, [handleNextStep, toast, storeSelectedAdSenseAds]);

  React.useEffect(() => {
    if (adSenseAds) {
      handleAdSenseAds(adSenseAds);
    }
  }, [adSenseAds, handleAdSenseAds]);

  if (isLoadingAdSenseAds || isValidatingAdSenseAds) {
    return (
      <S.LoadingWrapper>
        <CircleSpinner size={32} color={theme.colors.green.default} />
      </S.LoadingWrapper>
    );
  }

  if (adSenseAdsError && !isValidatingAdSenseAds) {
    return <ComponentError mutate={mutateAdSenseAds} />;
  }

  return (
    <S.Wrapper>
      <S.Description>
        Isso adicionará UTM tags para seus criativos existentes, para cruzamento de dados
        financeiros com os de campanhas.
      </S.Description>

      <S.InsightsWrapper>
        <S.Insight>
          <S.InsightIconWrapper style={{ backgroundColor: '#223C30' }}>
            <Check size={14} color={theme.colors.success.light} />
          </S.InsightIconWrapper>

          <S.InsightText>
            Foram{' '}
            <S.DetectedCampaigns onClick={handleCampaignsSideModalOpen}>
              {`selecionados (${storeSelectedAdSenseAds?.length}) criativos`}
            </S.DetectedCampaigns>{' '}
            para serem adicionadas <strong>UTM’s</strong>
          </S.InsightText>
        </S.Insight>

        <S.Insight>
          <S.InsightIconWrapper style={{ backgroundColor: '#223C30' }}>
            <Check size={14} color={theme.colors.success.light} />
          </S.InsightIconWrapper>

          <S.InsightText>
            Os trackings de lucro só irão funcionar a partir da adição das UTM’s
          </S.InsightText>
        </S.Insight>

        <S.Insight>
          <S.InsightIconWrapper style={{ backgroundColor: '#4D3F2C' }}>
            <Warning size={14} color={theme.colors.warning.light} />
          </S.InsightIconWrapper>

          <S.InsightText>
            As <strong>performances</strong> de suas campanhas podem ser afetadas por essa
            atualização.
          </S.InsightText>
        </S.Insight>

        <S.Insight>
          <S.InsightIconWrapper style={{ backgroundColor: '#4D3F2C' }}>
            <Warning size={14} color={theme.colors.warning.light} />
          </S.InsightIconWrapper>

          <S.InsightText>
            Isso adicionará tags em seus criativos então <strong>poderá</strong> entrar em revisão.
          </S.InsightText>
        </S.Insight>
      </S.InsightsWrapper>

      <S.CheckboxAndAddButtonWrapper>
        <S.CheckboxGroup>
          <S.Checkbox id="input-checkbox" checked={isInputChecked} onChange={onCheckChange} />
          <S.CheckboxLabel htmlFor="input-checkbox">
            Eu concordo e estou ciente dos tópicos anteriores
          </S.CheckboxLabel>
        </S.CheckboxGroup>

        <S.AddButton onClick={onAddUtmTagsClick} disabled={!isInputChecked}>
          Adicionar UTM Tags
        </S.AddButton>
      </S.CheckboxAndAddButtonWrapper>
    </S.Wrapper>
  );
};

export default AddUtmsInExistingCampaigns;
