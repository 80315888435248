import React from 'react';

import { useForm } from 'react-hook-form';
import { useToast } from '@helpers/hooks/useToast';

import migrationStoreOwnerService from '@services/pages/dashboard/adminPanel/storeOwner';

import Form from '@components/common/core/Inputs/Form';

import * as S from './styles';

const StoreMigration: React.FC = () => {
  const { toast } = useToast();

  const { handleSubmit, register } = useForm();

  const [isMigratingStoreOwnerPlan, setIsMigratingStoreOwner] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async formData => {
      setIsMigratingStoreOwner(true);

      const storeAliasId = formData.store_alias_id;

      try {
        await migrationStoreOwnerService.migrationStoreOwner({
          storeAliasId,
          data: {
            new_owner_email: formData.new_owner_email,
          },
        });

        toast.success('Proprietário transferido com sucesso.');

        setIsMigratingStoreOwner(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsMigratingStoreOwner(false);
      }
    },
    [toast],
  );

  return (
    <S.ContentWrapper>
      <S.Heading>Migrar proprietário de loja</S.Heading>

      <S.Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label htmlFor="store-alias-id">Loja</S.Label>
            <S.Input
              {...register('store_alias_id')}
              type="text"
              placeholder="Insira o Alias Id da loja"
              id="store-alias-id"
            />
          </S.InputGroup>

          <S.InputGroup>
            <S.Label htmlFor="new-owner-email">E-mail</S.Label>
            <S.Input
              {...register('new_owner_email')}
              type="text"
              placeholder="Insira o e-mail do novo proprietário"
              id="new-owner-email"
            />
          </S.InputGroup>

          <S.Button
            type="submit"
            isLoading={isMigratingStoreOwnerPlan}
            disabled={isMigratingStoreOwnerPlan}
          >
            Migrar
          </S.Button>
        </Form>
      </S.Content>
    </S.ContentWrapper>
  );
};

export default StoreMigration;
