import React from 'react';
import { useParams } from 'react-router-dom';

import { EProgressBarStep } from '@domain/enums/downgrade/EDowngradeStep';
import { IParams } from '@domain/interfaces/IParams';

import SideBar from '@components/Downgrade/SideBar';
import NavBar from '@components/Downgrade/NavBar';
import FacebookProfilesContent from '@components/Downgrade/FacebookProfiles';

import * as S from './styles';

const FacebookProfiles: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();

  const previousRouteLink = `/${storeAliasId}/downgrade/invited-members`;

  return (
    <S.Container>
      <SideBar
        onboardingStep={EProgressBarStep.INVITED_MEMBERS}
        previousRouteLink={previousRouteLink}
      />

      <S.Content>
        <NavBar />
        <FacebookProfilesContent />
      </S.Content>
    </S.Container>
  );
};

export default FacebookProfiles;
