import styled, { css } from 'styled-components/macro';

import { ETextWeight, ETextSize } from '@domain/enums/components/EText';
import { IText } from '@domain/interfaces/components/IText';

export const Text = styled.span<IText>`
  color: ${({ theme }) => theme.colors.font};
  margin: 0;

  ${({ size }) =>
    size === ETextSize.B1 &&
    css`
      font-size: 2rem;
    `}

  ${({ size }) =>
    size === ETextSize.B2 &&
    css`
      font-size: 1.5rem;
    `}

    ${({ size }) =>
    size === ETextSize.B3 &&
    css`
      font-size: 1.25rem;
    `}

    ${({ size }) =>
    size === ETextSize.B4 &&
    css`
      font-size: 1rem;
    `}

    ${({ size }) =>
    size === ETextSize.B5 &&
    css`
      font-size: 0.875rem;
    `}

  ${({ weight }) =>
    weight === ETextWeight.LIGHT &&
    css`
      font-weight: 300;
    `}

  ${({ weight }) =>
    weight === ETextWeight.REGULAR &&
    css`
      font-weight: 400;
    `}

    ${({ weight }) =>
    weight === ETextWeight.MEDIUM &&
    css`
      font-weight: 500;
    `}

    ${({ weight }) =>
    weight === ETextWeight.SEMIBOLD &&
    css`
      font-weight: 600;
    `}

    ${({ weight }) =>
    weight === ETextWeight.BOLD &&
    css`
      font-weight: 700;
    `}

    ${({ isErrorFeedback }) =>
    isErrorFeedback &&
    css`
      font-size: 12px;
      color: ${({ theme }) => theme.colors.danger.default};
    `}

    ${({ isHiddenContent }) =>
    isHiddenContent &&
    css`
      background-color: ${({ theme }) => theme.colors.background[2]};
      margin: 1px 0;

      span {
        opacity: 0;
      }
    `}
`;
