import React from 'react';

import { EUserStoreRole } from '@domain/enums/common/userStore/EUserStoreRole';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import { AccessManagerProvider } from '@helpers/hooks/pages/dashboard/accessManagement/useAccessManager';

import Wrapper from '@components/Dashboard/AccessManagement/Wrapper';
import NoAccess from '@components/common/core/Utils/NoAccess';
import DeleteMemberModal from '@components/Dashboard/AccessManagement/DeleteMemberModal';
import DeleteGroupModal from '@components/Dashboard/AccessManagement/DeleteGroupModal';
import DeletePendingInviteModal from '@components/Dashboard/AccessManagement/DeletePendingInviteModal';
import EditGroupSideModal from '@components/Dashboard/AccessManagement/EditGroupSideModal';

const AccessManagement: React.FC = () => {
  const { accessManagementData } = useStoreConfig();
  const { isInternalTeam } = useConfig();

  const isStoreOwner = accessManagementData?.role === EUserStoreRole.OWNER;

  if (!isStoreOwner && !isInternalTeam) {
    return <NoAccess />;
  }

  return (
    <AccessManagerProvider>
      <Wrapper />
      <DeleteMemberModal />
      <DeleteGroupModal />
      <DeletePendingInviteModal />
      <EditGroupSideModal />
    </AccessManagerProvider>
  );
};

export default AccessManagement;
