import { yupResolver } from '@hookform/resolvers/yup';
import { Apple, Google } from '@profitfy/pakkun-icons';
import React from 'react';
import AppleSignin from 'react-apple-signin-auth';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import sha256 from 'crypto-js/sha256';

import { EButtonTextColor } from '@domain/enums/components/EButton';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';

import { ETextSize } from '@domain/enums/components/EText';
import { useConfig } from '@helpers/hooks/useConfig';
import { useToast } from '@helpers/hooks/useToast';

import { useGoogleLogin } from '@react-oauth/google';
import { useQuery } from '@helpers/hooks/useQuery';

import { encrypt } from '@helpers/utils/common/encryptor';
import { getEnvironment } from '@helpers/utils/environment';
import { getFormSchema } from '@helpers/utils/signUp';
import { normalizeEmail, normalizeName } from '@helpers/utils/common/normalizer';

import { AUTH_API_DOMAIN } from '@constants/api';

import Text from '@components/common/core/DataDisplay/Text';

import googleSignInService from '@services/pages/auth/google/signIn';
import signUpService from '@services/pages/auth/signUp';
import userService from '@services/user';

import logoImg from '@assets/img/brand/profitfy-logo.svg';

import { EMambaTermsStatus } from '@domain/enums/common/mamba/EMambaTermStatus';
import { EBequestTermsStatus } from '@domain/enums/common/bequest/EBequestTermStatus';

import * as S from './styles';

const FirstForm: React.FC = () => {
  const history = useHistory();
  const query = useQuery();
  const { toast } = useToast();
  const {
    handleAuthorization,
    handleUser,
    analytics,
    affiliate,
    handleAppAffiliate,
    handleTerm,
    appAffiliate,
    term,
  } = useConfig();

  const utmSourceQuery = query.get('utm_source');
  const utmCampaignQuery = query.get('utm_campaign');
  const utmContentQuery = query.get('utm_content');
  const utmMediumQuery = query.get('utm_medium');
  const utmTermQuery = query.get('utm_term');

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [emailInputValue, setEmailInputValue] = React.useState<string>('');
  const [firstNameInputValue, setFirstNameInputValue] = React.useState<string>('');
  const [lastNameInputValue, setLastNameInputValue] = React.useState<string>('');
  const [passwordInputValue, setPasswordInputValue] = React.useState<string>('');
  const [affiliateInputValue, setAffiliateInputValue] = React.useState<string>('');
  const [confirmPasswordInputValue, setConfirmPasswordInputValue] = React.useState<string>('');
  const [step, setStep] = React.useState<number>(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(getFormSchema(step)),
  });

  const emailInputRegister = register('email');
  const firstNameInputRegister = register('firstName');
  const lastNameInputRegister = register('lastName');
  const passwordInputRegister = register('password');
  const confirmPasswordInputRegister = register('confirmPassword');
  const affiliateInputRegister = register('affiliate');

  const onEmailChange = React.useCallback(
    event => {
      emailInputRegister.onChange(event);

      setEmailInputValue(event.target.value);
    },
    [emailInputRegister],
  );

  const onFirstNameChange = React.useCallback(
    event => {
      firstNameInputRegister.onChange(event);

      setFirstNameInputValue(event.target.value);
    },
    [firstNameInputRegister],
  );

  const onLastNameChange = React.useCallback(
    event => {
      lastNameInputRegister.onChange(event);

      setLastNameInputValue(event.target.value);
    },
    [lastNameInputRegister],
  );

  const onAffiliateChange = React.useCallback(
    event => {
      affiliateInputRegister.onChange(event);

      setAffiliateInputValue(event.target.value);
    },
    [affiliateInputRegister],
  );

  const onPasswordChange = React.useCallback(
    event => {
      passwordInputRegister.onChange(event);

      setPasswordInputValue(event.target.value);
    },
    [passwordInputRegister],
  );

  const onConfirmPasswordChange = React.useCallback(
    event => {
      confirmPasswordInputRegister.onChange(event);

      setConfirmPasswordInputValue(event.target.value);
    },
    [confirmPasswordInputRegister],
  );

  const handleGoogleLogin = React.useCallback(
    async code => {
      setIsSubmittingForm(true);
      try {
        const encryptionKey = process.env.REACT_APP_GOOGLE_ENCRYPTION_KEY || '';

        const { data: signUpData } = await googleSignInService.signIn({
          access_token: encrypt(code.access_token, encryptionKey),
          referral_code: affiliate || undefined,
          utm_source: utmSourceQuery,
          utm_campaign: utmCampaignQuery,
          utm_content: utmContentQuery,
          utm_medium: utmMediumQuery,
          utm_term: utmTermQuery,
        });

        handleAuthorization(signUpData?.access_token);

        toast.success('Cadastro realizado com sucesso!');

        const { data: userData } = await userService.getUserPromise(signUpData?.access_token);

        if (signUpData?.is_sign_up) {
          await analytics?.track('Signed Up', {
            created_at: userData.user.created_at,
            email: userData.user.email,
            utm_source: utmSourceQuery,
            utm_campaign: utmCampaignQuery,
            utm_content: utmContentQuery,
            utm_medium: utmMediumQuery,
            utm_term: utmTermQuery,
          });

          analytics?.identifyHubSpot({
            has_signed_up: true,
            created_at: userData?.user.created_at,
          });

          const dataLayer = (window as Record<string, any>)?.dataLayer;

          if (dataLayer) {
            dataLayer.push({
              full_name: `${userData.user.first_name} ${userData.user.last_name}`,
              email: userData.user.email,
              event: 'User Creation Form Submitted',
              hashed_full_name: sha256(
                normalizeName(`${userData.user.first_name} ${userData.user.last_name}`),
              ).toString(),
              hashed_first_name: sha256(normalizeName(userData.user.first_name)).toString(),
              hashed_last_name: sha256(normalizeName(userData.user.last_name)).toString(),
              hashed_email: sha256(normalizeEmail(userData.user.email)).toString(),
            });
          }
        }

        handleUser({ ...userData?.user, user_intercom_hash: userData?.user_intercom_hash });
        handleTerm(userData?.term);

        if (userData?.affiliate) {
          handleAppAffiliate(userData.affiliate);
        }

        setIsSubmittingForm(false);

        if (signUpData?.is_sign_up) {
          if (
            appAffiliate &&
            appAffiliate.referral_code === 'njlimzb' &&
            term.term_to_provide_data_to_mamba_culture_accepted_status === EMambaTermsStatus.NONE
          ) {
            history.push('/consent/mamba-digital');
            return;
          }

          if (
            appAffiliate &&
            appAffiliate.referral_code === 'nwjkody' &&
            term.term_to_provide_data_to_bequest_culture_accepted_status ===
              EBequestTermsStatus.NONE
          ) {
            history.push('/consent/bequest-digital');
            return;
          }

          history.push('/onboarding/segmentation');
        } else {
          history.push('/stores');
        }
      } catch (error: any) {
        setIsSubmittingForm(false);
        toast.error(error.response.data.message);
      }
    },
    [
      affiliate,
      analytics,
      handleAuthorization,
      handleUser,
      history,
      toast,
      handleAppAffiliate,
      handleTerm,
      appAffiliate,
      term,
      utmCampaignQuery,
      utmContentQuery,
      utmMediumQuery,
      utmSourceQuery,
      utmTermQuery,
    ],
  );

  const onGoogleLogin = useGoogleLogin({
    onSuccess: codeResponse => handleGoogleLogin(codeResponse),
    onError: response => console.log(response),
    scope: 'openid https://www.googleapis.com/auth/userinfo.profile',
  });

  const handleSteps = React.useCallback(() => {
    setStep(state => {
      return state < 2 ? state + 1 : state;
    });
  }, []);

  const onSubmit = React.useCallback(
    async formData => {
      handleSteps();

      if (step === 2) {
        setIsSubmittingForm(true);

        try {
          const { data: signUpData } = await signUpService.signUp({
            first_name: formData.firstName,
            last_name: formData.lastName,
            email: formData.email,
            password: formData.password,
            password_confirmation: formData.confirmPassword,
            referral_code: formData.affiliate || undefined,
            utm_source: utmSourceQuery,
            utm_campaign: utmCampaignQuery,
            utm_content: utmContentQuery,
            utm_medium: utmMediumQuery,
            utm_term: utmTermQuery,
          });

          const dataLayer = (window as Record<string, any>)?.dataLayer;

          if (dataLayer) {
            dataLayer.push({
              full_name: `${formData.firstName} ${formData.lastName}`,
              email: formData.email,
              event: 'User Creation Form Submitted',
              hashed_full_name: sha256(
                normalizeName(`${formData.firstName} ${formData.lastName}`),
              ).toString(),
              hashed_first_name: sha256(normalizeName(formData.firstName)).toString(),
              hashed_last_name: sha256(normalizeName(formData.lastName)).toString(),
              hashed_email: sha256(normalizeEmail(formData.email)).toString(),
            });
          }

          handleAuthorization(signUpData?.access_token);

          toast.success('Cadastro realizado com sucesso!');

          const { data: userData } = await userService.getUserPromise(signUpData?.access_token);

          await analytics?.track('Signed Up', {
            created_at: userData.user.created_at,
            email: userData.user.email,
            utm_source: utmSourceQuery,
            utm_campaign: utmCampaignQuery,
            utm_content: utmContentQuery,
            utm_medium: utmMediumQuery,
            utm_term: utmTermQuery,
          });

          analytics?.identifyHubSpot({
            has_signed_up: true,
            created_at: userData?.user.created_at,
          });

          handleUser({ ...userData?.user, user_intercom_hash: userData?.user_intercom_hash });
          handleTerm(userData?.term);

          if (userData?.affiliate) {
            handleAppAffiliate(userData.affiliate);
          }

          setIsSubmittingForm(false);

          if (
            appAffiliate &&
            appAffiliate.referral_code === 'njlimzb' &&
            term.term_to_provide_data_to_mamba_culture_accepted_status === EMambaTermsStatus.NONE
          ) {
            history.push('/consent/mamba-digital');
            return;
          }

          if (
            appAffiliate &&
            appAffiliate.referral_code === 'nwjkody' &&
            term.term_to_provide_data_to_bequest_culture_accepted_status ===
              EBequestTermsStatus.NONE
          ) {
            history.push('/consent/bequest-digital');
            return;
          }

          if (userData?.user?.has_answered_form_segmentation) {
            history.push('/stores');
            return;
          }

          history.push('/onboarding/segmentation');
        } catch (error: any) {
          setIsSubmittingForm(false);
          toast.error(error?.response?.data?.message);
        }
      }
    },
    [
      toast,
      history,
      handleAuthorization,
      handleUser,
      handleSteps,
      analytics,
      step,
      handleAppAffiliate,
      handleTerm,
      appAffiliate,
      term,
      utmCampaignQuery,
      utmContentQuery,
      utmMediumQuery,
      utmSourceQuery,
      utmTermQuery,
    ],
  );

  // React.useEffect(() => {
  //   if (window?.location?.host.includes('demo')) {
  //     history.push('/');
  //   }
  // }, [history]);

  React.useEffect(() => {
    if (affiliate) {
      setAffiliateInputValue(affiliate);
    }
  }, [affiliate]);

  const isAbleToShowFirstAndLastNameInputs = step >= 1 && !errors.email && emailInputValue.length;

  const isAbleToShowPasswordAndConfirmPasswordInputs =
    step >= 2 &&
    !errors.firstName &&
    !errors.lastName &&
    firstNameInputValue.length &&
    lastNameInputValue.length;

  const isEmailStepDisabled = step === 0 ? !emailInputValue.length || errors.email : false;

  const isFirstAndLastNameStepDisabled =
    step === 1
      ? !firstNameInputValue.length ||
        !lastNameInputValue.length ||
        errors.firstName ||
        errors.lastName
      : false;

  const isPasswordAndConfirmPasswordDisabled =
    step === 2
      ? !passwordInputValue.length ||
        !confirmPasswordInputValue.length ||
        errors.password ||
        errors.confirmPassword
      : false;

  const isCreateAccountButtonDisabled =
    isEmailStepDisabled || isFirstAndLastNameStepDisabled || isPasswordAndConfirmPasswordDisabled;

  return (
    <S.Wrapper>
      <S.LogoLink to="/">
        <S.Logo src={logoImg} alt="profitfy-logo" />
      </S.LogoLink>

      <S.TitleAndSubTitleWrapper>
        <S.Title type={EHeadingSize.H3} fontWeight={EHeadingWeight.REGULAR}>
          Comece a usar Gratuitamente!
        </S.Title>
        <S.SubTitle size={ETextSize.B5}>Não precisa cadastrar cartão de crédito.</S.SubTitle>
      </S.TitleAndSubTitleWrapper>

      <S.ExternalLoginWrapper>
        <S.ExternalLoginButton onClick={() => onGoogleLogin()} disabled={isSubmittingForm}>
          <Google size={22} />
          Continuar com Google
        </S.ExternalLoginButton>

        <AppleSignin
          authOptions={{
            clientId: 'com.profitfy.web',
            scope: 'email name',
            redirectURI: `${AUTH_API_DOMAIN}/${getEnvironment()}/auth/apple/sign-in`,
            state: JSON.stringify({ referral_code: affiliate || undefined }),
            nonce: '',
            usePopup: false,
          }}
          uiType="dark"
          render={(props: any) => (
            <S.ExternalLoginButton disabled={isSubmittingForm} {...props}>
              <Apple size={22} />
              Continuar com Apple
            </S.ExternalLoginButton>
          )}
        />
      </S.ExternalLoginWrapper>

      <S.HorizontalRulesWrapper>
        <S.HorizontalRule />
        <S.HorizontalRuleText>Ou</S.HorizontalRuleText>
        <S.HorizontalRule />
      </S.HorizontalRulesWrapper>

      <S.Form id="sign-up-form" onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label>E-mail</S.Label>
          <S.Input
            {...emailInputRegister}
            onChange={onEmailChange}
            type="text"
            name="email"
            placeholder="Digite seu e-mail"
            isError={errors.email}
            value={emailInputValue}
          />
          {errors.email && <Text isErrorFeedback>{errors.email.message}</Text>}
        </S.InputGroup>

        {isAbleToShowFirstAndLastNameInputs && (
          <>
            <S.InputGroup>
              <S.Label>Nome</S.Label>
              <S.Input
                {...firstNameInputRegister}
                onChange={onFirstNameChange}
                type="text"
                name="firstName"
                placeholder="Digite seu primeiro nome"
                isError={errors.firstName}
                value={firstNameInputValue}
              />
              {errors.firstName && <Text isErrorFeedback>{errors.firstName.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Sobrenome</S.Label>
              <S.Input
                {...lastNameInputRegister}
                onChange={onLastNameChange}
                type="text"
                name="lastName"
                placeholder="Digite seu sobrenome"
                isError={errors.lastName}
                value={lastNameInputValue}
              />
              {errors.lastName && <Text isErrorFeedback>{errors.lastName.message}</Text>}
            </S.InputGroup>
          </>
        )}

        {isAbleToShowPasswordAndConfirmPasswordInputs && (
          <>
            <S.InputGroup>
              <S.Label>Afiliado (opcional)</S.Label>
              <S.Input
                {...affiliateInputRegister}
                onChange={onAffiliateChange}
                type="affiliate"
                name="affiliate"
                placeholder="Digite o código do seu afiliado"
                isError={errors.affiliate}
                value={affiliateInputValue}
                data-openreplay-obscured
              />
              {errors.affiliate && <Text isErrorFeedback>{errors.affiliate.message}</Text>}
            </S.InputGroup>
            <S.InputGroup>
              <S.Label>Senha</S.Label>
              <S.Input
                {...passwordInputRegister}
                onChange={onPasswordChange}
                type="password"
                name="password"
                placeholder="Digite sua senha"
                isError={errors.password}
                value={passwordInputValue}
                data-openreplay-obscured
              />
              {errors.password && <Text isErrorFeedback>{errors.password.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Confirmar senha</S.Label>
              <S.Input
                {...confirmPasswordInputRegister}
                onChange={onConfirmPasswordChange}
                type="password"
                name="confirmPassword"
                placeholder="Digite sua senha"
                isError={errors.confirmPassword}
                value={confirmPasswordInputValue}
                data-openreplay-obscured
              />
              {errors.confirmPassword && (
                <Text isErrorFeedback>{errors.confirmPassword.message}</Text>
              )}
            </S.InputGroup>
          </>
        )}

        {step === 2 && !errors.password && !errors.confirmPassword && (
          <>
            <S.TermsOfUsageText>
              Ao prosseguir, você aceita os
              <S.TermsOfUsageLink
                href="https://app.profitfy.me/terms-of-usage?_ga=2.9148149.1575988857.1657205605-180586444.1654478752"
                target="_blank"
              >
                Termos de Uso e de Privacidade
              </S.TermsOfUsageLink>{' '}
              da Profitfy
            </S.TermsOfUsageText>
          </>
        )}

        <S.CreateAccountButton
          type="submit"
          textColor={EButtonTextColor.DARK}
          isLoading={isSubmittingForm}
          disabled={isCreateAccountButtonDisabled || isSubmittingForm}
        >
          {step === 2 ? 'Cadastrar minha conta' : 'Continuar'}
        </S.CreateAccountButton>

        {step !== 2 && (
          <>
            <S.AccessAccountText>
              Já tem uma conta? <S.Link to={`/${window.location.search}`}>Entrar</S.Link>
            </S.AccessAccountText>
          </>
        )}
      </S.Form>
    </S.Wrapper>
  );
};

export default FirstForm;
