import React from 'react';

import Popover from '@components/common/core/Utils/Popover';
import { EHeadAlign } from '@domain/enums/components/ETable';
import { IRowProps } from '@domain/interfaces/dashboard/ProductCostDetails/IHistoricSidemodal';
import { useVariantHistoric } from '@helpers/hooks/pages/dashboard/productCostDetails/variantHistoric';
import { useDate } from '@helpers/hooks/useDate';
import { numberFormatter } from '@helpers/masks';
import DeleteModal from '@components/common/core/Utils/DeleteModal';

import * as S from './styles';

const Row: React.FC<IRowProps> = ({ variantCost }) => {
  const {
    handleNextStep,
    handleVariantCostToEdit,
    deleteVariantCost,
    isDeletingVariantCost,
  } = useVariantHistoric();
  const { format } = useDate();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);

  const handleDeleteModalOpen = React.useCallback(() => setIsDeleteModalOpen(!isDeleteModalOpen), [
    isDeleteModalOpen,
  ]);

  const onEdit = React.useCallback(() => {
    handleVariantCostToEdit(variantCost);

    handleNextStep();
  }, [handleNextStep, handleVariantCostToEdit, variantCost]);

  const onDelete = React.useCallback(async () => {
    await deleteVariantCost(variantCost.alias_id);
  }, [variantCost, deleteVariantCost]);

  return (
    <S.TableRow>
      <S.TableData>
        {variantCost.start_date ? format(new Date(variantCost.start_date), 'dd/MM/yyyy') : '-'}
      </S.TableData>
      <S.TableData>
        {variantCost.end_date ? format(new Date(variantCost.end_date), 'dd/MM/yyyy') : '-'}
      </S.TableData>
      <S.TableData align={EHeadAlign.RIGHT}>{numberFormatter(variantCost.cost, 2)}</S.TableData>
      <S.TableData align={EHeadAlign.CENTER}>
        <>
          {!variantCost.is_default && (
            <S.ActionsButton
              data-tip
              data-for={`historic-actions-${variantCost.alias_id}`}
              data-event="click"
            >
              <S.Dot />
              <S.Dot />
              <S.Dot />

              <S.AccessibleActionsButtonName>Ações</S.AccessibleActionsButtonName>
            </S.ActionsButton>
          )}

          <Popover width={128} id={`historic-actions-${variantCost.alias_id}`} place="bottom">
            <S.ActionsWrapper>
              <S.ActionButton name="edit" onClick={onEdit}>
                Editar
              </S.ActionButton>
              <S.ActionButton name="delete" onClick={handleDeleteModalOpen}>
                Deletar
              </S.ActionButton>
            </S.ActionsWrapper>
          </Popover>

          <DeleteModal
            isLoading={isDeletingVariantCost}
            content="Deseja excluir o histórico? Essa ação é irreversível."
            isOpen={isDeleteModalOpen}
            onDelete={onDelete}
            title="Excluir histórico"
            toggle={handleDeleteModalOpen}
          />
        </>
      </S.TableData>
    </S.TableRow>
  );
};

export default Row;
