import React from 'react';

import { ISelectedFilter, IFilterProps } from '@domain/interfaces/components/IFilter';
import { EFilterOperator } from '@domain/enums/components/EFilter';

import * as S from './styles';

const Text: React.FC<IFilterProps> = ({
  config,
  handleOnClick,
  defaultValue,
  addNewData,
  updateData,
}) => {
  const { config: filterConfig } = config;

  const [valueToFilter, setValueToFilter] = React.useState<string>('');

  const onValueToFilterChange = React.useCallback(event => {
    setValueToFilter(event.target.value);
  }, []);

  const handleConfirm = React.useCallback(() => {
    if (valueToFilter) {
      const newData: ISelectedFilter = {
        field: config.field,
        label: valueToFilter,
        value: valueToFilter,
        operator: EFilterOperator.EQUAL,
      };

      if (defaultValue) {
        updateData(newData);
      } else {
        addNewData(newData);
      }
    }

    handleOnClick();
  }, [handleOnClick, config, valueToFilter, addNewData, defaultValue, updateData]);

  return (
    <S.Wrapper>
      <S.ValueToFilterWrapper>
        <S.ValueToFilterInput
          type="text"
          placeholder={filterConfig.placeholder}
          onChange={onValueToFilterChange}
          defaultValue={defaultValue}
          autoFocus
        />
      </S.ValueToFilterWrapper>

      <S.ButtonsWrapper>
        <S.CancelButton onClick={handleOnClick}>Cancelar</S.CancelButton>
        <S.ConfirmButton onClick={handleConfirm}>Confirmar</S.ConfirmButton>
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export default Text;
