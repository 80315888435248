import React from 'react';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { useProductDetails } from '@helpers/hooks/pages/dashboard/productAnalytics/useProductDetails';
import { numberFormatter } from '@helpers/masks';
import PieChart from './PieChart';

import * as S from './styles';

const CreditCard: React.FC = () => {
  const { productFinancialDetailsReport } = useProductDetails();

  const { card } = productFinancialDetailsReport;

  return (
    <S.Card>
      <S.Content>
        <S.Title fontWeight={EHeadingWeight.LIGHT}>Cartão</S.Title>

        <S.DataWrapper>
          <S.ChartWrapper>
            <PieChart />
            <S.ChartPercentage>{`${numberFormatter(card.conversion, 0, true)}%`}</S.ChartPercentage>
          </S.ChartWrapper>

          <S.OrdersDataWrapper>
            <S.OrdersStatusWrapper>
              <S.OrdersAmountAndQuantity>
                <S.OrdersAmount>{`R$ ${numberFormatter(card.paid.amount, 2)}`}</S.OrdersAmount>
                <S.OrdersQuantity>{`(${card.paid.quantity})`}</S.OrdersQuantity>
              </S.OrdersAmountAndQuantity>

              <S.StatusWrapper>
                <S.ApprovedBullet />
                <S.StatusText>Aprovados</S.StatusText>
              </S.StatusWrapper>
            </S.OrdersStatusWrapper>

            <S.OrdersStatusWrapper>
              <S.OrdersAmountAndQuantity>
                <S.OrdersAmount>{`R$ ${numberFormatter(
                  card.in_analysis.amount,
                  2,
                )}`}</S.OrdersAmount>
                <S.OrdersQuantity>{`(${card.in_analysis.quantity})`}</S.OrdersQuantity>
              </S.OrdersAmountAndQuantity>

              <S.StatusWrapper>
                <S.InAnalysisBullet />
                <S.StatusText>Em Análise</S.StatusText>
              </S.StatusWrapper>
            </S.OrdersStatusWrapper>

            <S.OrdersStatusWrapper>
              <S.OrdersAmountAndQuantity>
                <S.OrdersAmount>{`R$ ${numberFormatter(card.canceled.amount, 2)}`}</S.OrdersAmount>
                <S.OrdersQuantity>{`(${card.canceled.quantity})`}</S.OrdersQuantity>
              </S.OrdersAmountAndQuantity>

              <S.StatusWrapper>
                <S.RefusedBullet />
                <S.StatusText>Recusados</S.StatusText>
              </S.StatusWrapper>
            </S.OrdersStatusWrapper>
          </S.OrdersDataWrapper>
        </S.DataWrapper>
      </S.Content>
    </S.Card>
  );
};

export default CreditCard;
