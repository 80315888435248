import styled, { css } from 'styled-components/macro';

import { IPlanPriceProps } from '@domain/interfaces/pricing/plans/IPlan';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';

export const TooltipText = styled(TextBase)`
  max-width: 320px;
  width: 100%;
  display: block;
`;

export const TooltipContent = styled(CustomTooltipBase.Content)``;

export const TooltipTrigger = styled(CustomTooltipBase.Trigger)`
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[2]};
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-underline-offset: 8px;
`;

export const Tooltip = styled(CustomTooltipBase)``;

export const BenefitText = styled(TextBase)`
  line-height: 17px;
  font-size: 0.875rem;

  strong {
    font-weight: 700;
  }
`;

export const Benefit = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
`;

export const Benefits = styled.div``;

export const SignUpButton = styled(ButtonBase)`
  width: 100%;
  background-color: #004eff;
  height: 42px;
  border-radius: 65px;
  border: none;
  margin-bottom: 32px;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: unset;

  & > span {
    color: #fff;
    font-family: 'Poppins', 'Open Sans';
    font-size: 0.875rem;
    font-weight: 700;
  }

  &:hover {
    background-color: #004eff;
    box-shadow: unset;
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
    background-color: #004eff;
    box-shadow: unset;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray[2]};
    box-shadow: none;
    cursor: not-allowed;
  }
`;

export const PlanDescription = styled(TextBase)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[2]};
  margin-bottom: 32px;
  text-align: center;
  display: inherit;
`;

export const ChargeRecurrenceText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
  display: flex;
  justify-content: center;
  font-size: 0.875rem;
  margin-bottom: 24px;
  height: 24px;
`;

export const PlanPriceLabel = styled(TextBase)<IPlanPriceProps>`
  text-align: center;
  display: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.gray[2]};
  font-family: 'Poppins', 'Open Sans';
  line-height: 21px;

  ${({ isRecurrence }) =>
    isRecurrence &&
    css`
      margin-bottom: 2px;
      height: 32px;
    `}

  span {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.gray[2]};
  }
`;

export const Price = styled(TextBase)`
  text-align: center;
  display: inherit;
  font-size: 2.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white.light};
  font-family: 'Poppins', 'Open Sans';
  line-height: 100%;
  height: 40px;

  span {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.gray[2]};
  }

  small {
    font-size: 1rem;
  }
`;

export const Currency = styled(TextBase)`
  text-align: center;
  display: inherit;
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white.light};
  font-family: 'Poppins', 'Open Sans';
  line-height: 120%;
  height: 40px;

  span {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.gray[2]};
  }
`;

export const PlanPrice = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  justify-content: center;
`;

export const PlanPriceWrapper = styled.div``;

export const PlanTitle = styled(HeadingBase)`
  margin-bottom: 4px;
  font-family: 'Poppins', 'Open Sans';
  margin-bottom: 24px;
  font-size: 1.25rem;
  color: #fff;
  text-align: center;
  font-weight: 600;
`;

export const PlanCard = styled.div<any>`
  min-width: 236px;
  height: 674px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  padding: 16px 24px;
  box-sizing: border-box;
  border-radius: 15px;
  position: relative;
  margin-right: 12px;

  ${({ isMostPopularPlan }) =>
    isMostPopularPlan &&
    css`
      border: 2px solid #33d69f;
      background-color: #33d69f1a;
    `}
`;

export const Wrapper = styled.div`
  border-radius: 7px;
  flex: 1 0 270px;
`;
