import { LONG_API_DOMAIN } from '@constants/api';
import { ICreateFacebookCredentialProps } from '@domain/interfaces/pages/facebookLogin/IFacebookCredential';
import { getEnvironment } from '@helpers/utils/environment';
import axios, { AxiosInstance } from 'axios';

export class FacebookCredentialService {
  private readonly api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = axios.create({ baseURL });
  }

  public createFacebookCredential({
    storeAliasId,
    data,
    config,
  }: ICreateFacebookCredentialProps): Promise<any> {
    const { authorization } = config;

    if (authorization) {
      this.api.defaults.headers.authorization = authorization;
    }

    return this.api.post(
      `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/facebook/ad-sense-credentials`,
      {
        ...data,
      },
    );
  }
}

const facebookCredentialService = new FacebookCredentialService(LONG_API_DOMAIN);

export default facebookCredentialService;
