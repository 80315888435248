import React from 'react';

import { EStatus } from '@domain/enums/subscription/ESubscription';
import { IPlan } from '@domain/interfaces/common/plan/IPlan';
import { IStorePermissionProvider } from '@domain/interfaces/hooks/dashboard/IStorePermission';

import { ALL_PLANS } from '@constants/plans';

import { useStoreSubscription } from '../useStoreSubscription';
import { useConfig } from '../useConfig';
import { useDate } from '../useDate';

const StorePermissionContext = React.createContext<IStorePermissionProvider | null>(null);

export const StorePermissionProvider: React.FC = ({ children }) => {
  const { subscription } = useStoreSubscription();
  const { user } = useConfig();
  const { differenceInDays, utcToZonedTime } = useDate();

  const getHasAllAccess = React.useCallback(() => {
    if (
      user?.role === 'ADMIN' ||
      user?.role === 'BETA' ||
      user?.role === 'VIP' ||
      user?.role === 'CUSTOMER_SUCCESS'
    )
      return true;

    return false;
  }, [user]);

  const getSubscriptionPlan = React.useCallback((subscriptionPlan: IPlan | undefined) => {
    const foundSubscriptionPlan = ALL_PLANS.find(
      plan =>
        plan.identifier === subscriptionPlan?.identifier ||
        plan.quarterIdentifier === subscriptionPlan?.identifier ||
        plan.semiAnualIdentifier === subscriptionPlan?.identifier,
    );

    return foundSubscriptionPlan;
  }, []);

  const getMaxMarketingIntegrations = React.useCallback((): number => {
    const hasAllAccess = getHasAllAccess();

    if (hasAllAccess) return -1;

    if (!subscription) return 0;

    const isSubscriptionPeriodEndedAtAfterNow = subscription?.period_ended_at
      ? differenceInDays(utcToZonedTime(new Date()), new Date(subscription.period_ended_at)) >= 0
      : true;

    if (subscription?.status === EStatus.CANCELED && isSubscriptionPeriodEndedAtAfterNow) {
      const subscriptionPlan = getSubscriptionPlan(subscription.plan);

      return subscriptionPlan?.benefits.maxAdsIntegrations ?? -1;
    }

    if (subscription.status === EStatus.CANCELED || subscription.status === EStatus.TRIAL_ENDED)
      return 0;

    if (subscription.status === EStatus.TRIAL_STARTED) return -1;

    const subscriptionPlan = getSubscriptionPlan(subscription.plan);

    if (subscriptionPlan) return subscriptionPlan.benefits.maxAdsIntegrations ?? -1;

    return -1;
  }, [subscription, getHasAllAccess, getSubscriptionPlan, differenceInDays, utcToZonedTime]);

  const getMaxFacebookProfiles = React.useCallback((): number => {
    const hasAllAccess = getHasAllAccess();

    if (hasAllAccess) return -1;

    if (!subscription) return 0;

    const isSubscriptionPeriodEndedAtAfterNow = subscription?.period_ended_at
      ? differenceInDays(utcToZonedTime(new Date()), new Date(subscription.period_ended_at)) >= 0
      : true;

    if (subscription?.status === EStatus.CANCELED && isSubscriptionPeriodEndedAtAfterNow) {
      const subscriptionPlan = getSubscriptionPlan(subscription.plan);

      return subscriptionPlan?.benefits.maxFacebookProfiles ?? -1;
    }

    if (subscription.status === EStatus.CANCELED || subscription.status === EStatus.TRIAL_ENDED)
      return 0;

    if (subscription.status === EStatus.TRIAL_STARTED) return -1;

    const subscriptionPlan = getSubscriptionPlan(subscription.plan);

    if (subscriptionPlan) return subscriptionPlan.benefits.maxFacebookProfiles ?? -1;

    return -1;
  }, [subscription, getHasAllAccess, getSubscriptionPlan, differenceInDays, utcToZonedTime]);

  const getMaxWorkflows = React.useCallback(() => {
    const hasAllAccess = getHasAllAccess();

    if (hasAllAccess) return -1;

    if (!subscription) return 0;

    const isSubscriptionPeriodEndedAtAfterNow = subscription?.period_ended_at
      ? differenceInDays(utcToZonedTime(new Date()), new Date(subscription.period_ended_at)) >= 0
      : true;

    if (subscription?.status === EStatus.CANCELED && isSubscriptionPeriodEndedAtAfterNow) {
      const subscriptionPlan = getSubscriptionPlan(subscription.plan);

      return subscriptionPlan?.benefits.maxWorkflows ?? -1;
    }

    if (subscription.status === EStatus.CANCELED || subscription.status === EStatus.TRIAL_ENDED)
      return 0;

    if (subscription.status === EStatus.TRIAL_STARTED) return -1;

    const subscriptionPlan = getSubscriptionPlan(subscription.plan);

    if (subscriptionPlan) return subscriptionPlan.benefits.maxWorkflows ?? -1;

    return -1;
  }, [subscription, getHasAllAccess, getSubscriptionPlan, differenceInDays, utcToZonedTime]);

  const getMaxMessagePerWorkflow = React.useCallback(() => {
    const hasAllAccess = getHasAllAccess();

    if (hasAllAccess) return -1;

    if (!subscription) return 0;

    const isSubscriptionPeriodEndedAtAfterNow = subscription?.period_ended_at
      ? differenceInDays(utcToZonedTime(new Date()), new Date(subscription.period_ended_at)) >= 0
      : true;

    if (subscription?.status === EStatus.CANCELED && isSubscriptionPeriodEndedAtAfterNow) {
      const subscriptionPlan = getSubscriptionPlan(subscription.plan);

      return subscriptionPlan?.benefits.maxMessagePerWorkflow ?? -1;
    }

    if (subscription.status === EStatus.CANCELED || subscription.status === EStatus.TRIAL_ENDED)
      return 0;

    if (subscription.status === EStatus.TRIAL_STARTED) return -1;

    const subscriptionPlan = getSubscriptionPlan(subscription.plan);

    if (subscriptionPlan) return subscriptionPlan.benefits.maxMessagePerWorkflow ?? -1;

    return -1;
  }, [subscription, getHasAllAccess, getSubscriptionPlan, differenceInDays, utcToZonedTime]);

  const getMaxWhatsappInstances = React.useCallback(() => {
    const hasAllAccess = getHasAllAccess();

    if (hasAllAccess) return -1;

    if (!subscription) return 0;

    const isSubscriptionPeriodEndedAtAfterNow = subscription?.period_ended_at
      ? differenceInDays(utcToZonedTime(new Date()), new Date(subscription.period_ended_at)) >= 0
      : true;

    if (subscription?.status === EStatus.CANCELED && isSubscriptionPeriodEndedAtAfterNow) {
      const subscriptionPlan = getSubscriptionPlan(subscription.plan);

      return subscriptionPlan?.benefits.maxWhatsappInstances ?? -1;
    }

    if (subscription.status === EStatus.CANCELED || subscription.status === EStatus.TRIAL_ENDED)
      return 0;

    if (subscription.status === EStatus.TRIAL_STARTED) return -1;

    const subscriptionPlan = getSubscriptionPlan(subscription.plan);

    if (subscriptionPlan) return subscriptionPlan.benefits.maxWhatsappInstances ?? -1;

    return -1;
  }, [subscription, getHasAllAccess, getSubscriptionPlan, differenceInDays, utcToZonedTime]);

  const getMaxWhatsappMessagesSent = React.useCallback(() => {
    const hasAllAccess = getHasAllAccess();

    if (hasAllAccess) return -1;

    if (!subscription) return 0;

    const isSubscriptionPeriodEndedAtAfterNow = subscription?.period_ended_at
      ? differenceInDays(utcToZonedTime(new Date()), new Date(subscription.period_ended_at)) >= 0
      : true;

    if (subscription?.status === EStatus.CANCELED && isSubscriptionPeriodEndedAtAfterNow) {
      const subscriptionPlan = getSubscriptionPlan(subscription.plan);

      return subscriptionPlan?.benefits.maxWhatsappMessagesSent ?? -1;
    }

    if (subscription.status === EStatus.CANCELED || subscription.status === EStatus.TRIAL_ENDED)
      return 0;

    if (subscription.status === EStatus.TRIAL_STARTED) return -1;

    const subscriptionPlan = getSubscriptionPlan(subscription.plan);

    if (subscriptionPlan) return subscriptionPlan.benefits.maxWhatsappMessagesSent ?? -1;

    return -1;
  }, [subscription, getHasAllAccess, getSubscriptionPlan, differenceInDays, utcToZonedTime]);

  const getMaxUsers = React.useCallback((): number => {
    const hasAllAccess = getHasAllAccess();

    if (hasAllAccess) return -1;

    if (!subscription) return 0;

    const isSubscriptionPeriodEndedAtAfterNow = subscription?.period_ended_at
      ? differenceInDays(utcToZonedTime(new Date()), new Date(subscription.period_ended_at)) >= 0
      : true;

    if (subscription?.status === EStatus.CANCELED && isSubscriptionPeriodEndedAtAfterNow) {
      const subscriptionPlan = getSubscriptionPlan(subscription.plan);

      return subscriptionPlan?.benefits?.maxMembers ?? -1;
    }

    if (subscription.status === EStatus.CANCELED || subscription.status === EStatus.TRIAL_ENDED)
      return 0;

    if (subscription.status === EStatus.TRIAL_STARTED) return -1;

    const subscriptionPlan = getSubscriptionPlan(subscription.plan);

    if (subscriptionPlan) return subscriptionPlan?.benefits?.maxMembers ?? -1;

    return -1;
  }, [getHasAllAccess, subscription, getSubscriptionPlan, differenceInDays, utcToZonedTime]);

  const getHasExportSpreadsheetFeature = React.useCallback((): boolean => {
    const hasAllAccess = getHasAllAccess();

    if (hasAllAccess) return true;

    if (!subscription) return false;

    const isSubscriptionPeriodEndedAtAfterNow = subscription?.period_ended_at
      ? differenceInDays(utcToZonedTime(new Date()), new Date(subscription.period_ended_at)) >= 0
      : true;

    if (subscription?.status === EStatus.CANCELED && isSubscriptionPeriodEndedAtAfterNow) {
      const subscriptionPlan = getSubscriptionPlan(subscription.plan);

      return Boolean(subscriptionPlan?.benefits.exportFinancialDashboardSpreadSheet);
    }

    if (subscription.status === EStatus.CANCELED || subscription.status === EStatus.TRIAL_ENDED)
      return false;

    if (subscription.status === EStatus.TRIAL_STARTED) return true;

    const subscriptionPlan = getSubscriptionPlan(subscription.plan);

    if (subscriptionPlan)
      return Boolean(subscriptionPlan.benefits.exportFinancialDashboardSpreadSheet);

    return false;
  }, [getHasAllAccess, subscription, getSubscriptionPlan, differenceInDays, utcToZonedTime]);

  const getHasProductAnalyticsFeature = React.useCallback(() => {
    const hasAllAccess = getHasAllAccess();

    if (hasAllAccess) return true;

    if (!subscription) return false;

    const isSubscriptionPeriodEndedAtAfterNow = subscription?.period_ended_at
      ? differenceInDays(utcToZonedTime(new Date()), new Date(subscription.period_ended_at)) >= 0
      : true;

    if (subscription?.status === EStatus.CANCELED && isSubscriptionPeriodEndedAtAfterNow) {
      const subscriptionPlan = getSubscriptionPlan(subscription.plan);

      return Boolean(subscriptionPlan?.benefits.productAnalytics);
    }

    if (subscription.status === EStatus.CANCELED || subscription.status === EStatus.TRIAL_ENDED)
      return false;

    if (subscription.status === EStatus.TRIAL_STARTED) return true;

    const subscriptionPlan = getSubscriptionPlan(subscription.plan);

    if (subscriptionPlan) return Boolean(subscriptionPlan.benefits.productAnalytics);

    return false;
  }, [getHasAllAccess, subscription, getSubscriptionPlan, differenceInDays, utcToZonedTime]);

  const getHasAdsManagerFeature = React.useCallback(() => {
    const hasAllAccess = getHasAllAccess();

    if (hasAllAccess) return true;

    if (!subscription) return false;

    const isSubscriptionPeriodEndedAtAfterNow = subscription?.period_ended_at
      ? differenceInDays(utcToZonedTime(new Date()), new Date(subscription.period_ended_at)) >= 0
      : true;

    if (subscription?.status === EStatus.CANCELED && isSubscriptionPeriodEndedAtAfterNow) {
      const subscriptionPlan = getSubscriptionPlan(subscription.plan);

      return Boolean(subscriptionPlan?.benefits.adsManager);
    }

    if (subscription.status === EStatus.CANCELED || subscription.status === EStatus.TRIAL_ENDED)
      return false;

    if (subscription.status === EStatus.TRIAL_STARTED) return true;

    const subscriptionPlan = getSubscriptionPlan(subscription.plan);

    if (subscriptionPlan) return Boolean(subscriptionPlan.benefits.adsManager);

    return false;
  }, [getHasAllAccess, subscription, getSubscriptionPlan, differenceInDays, utcToZonedTime]);

  const maxMarketingIntegrations = getMaxMarketingIntegrations();
  const maxFacebookProfiles = getMaxFacebookProfiles();
  const maxUsers = getMaxUsers();
  const hasExportSpreadsheetFeature = getHasExportSpreadsheetFeature();
  const hasProductAnalyticsFeature = getHasProductAnalyticsFeature();
  const hasAdsManagerFeature = getHasAdsManagerFeature();
  const maxWorkflows = getMaxWorkflows();
  const maxMessagePerWorkflow = getMaxMessagePerWorkflow();
  const maxWhatsappInstances = getMaxWhatsappInstances();
  const maxWhatsappMessagesSent = getMaxWhatsappMessagesSent();

  return (
    <StorePermissionContext.Provider
      value={{
        hasAdsManagerFeature,
        hasExportSpreadsheetFeature,
        hasProductAnalyticsFeature,
        maxFacebookProfiles,
        maxMarketingIntegrations,
        maxUsers,
        maxWorkflows,
        maxMessagePerWorkflow,
        maxWhatsappInstances,
        maxWhatsappMessagesSent,
      }}
    >
      {children}
    </StorePermissionContext.Provider>
  );
};

export const useStorePermission = (): IStorePermissionProvider => {
  const context = React.useContext(StorePermissionContext);

  if (!context) {
    throw new Error('useStorePermission must be used within StorePermissionProvider');
  }

  return context;
};
