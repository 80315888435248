import styled from 'styled-components/macro';

import CustomHoverCardBase from '@components/common/core/Utils/CustomHoverCard';

export const CustomHoverCardContent = styled(CustomHoverCardBase.Content)``;

export const CustomHoverCard = styled(CustomHoverCardBase)``;

export const InsightWrapper = styled.div`
  width: 24px;
  min-width: 24px;
  height: 26px;
  min-height: 26px;
  background-color: #24363f;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: blur(5px);
`;
