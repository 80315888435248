import React from 'react';

import { IActiveIntegrationFooterProps } from '@domain/interfaces/dashboard/IntegrationCenter/IActiveCredentialFooter';

import * as S from './styles';

const ActiveIntegrationFooter: React.FC<IActiveIntegrationFooterProps> = ({
  onIntegrateClick,
  isIntegrating,
  disabled,
  submitText,
}) => {
  return (
    <S.ActiveIntegrationButtonBackground>
      <S.ActiveIntegrationButtonWrapper>
        <S.ActiveIntegrationButton
          type="submit"
          onClick={onIntegrateClick}
          isLoading={isIntegrating}
          disabled={disabled || isIntegrating}
        >
          {submitText || 'Ativar integração'}
        </S.ActiveIntegrationButton>
      </S.ActiveIntegrationButtonWrapper>
    </S.ActiveIntegrationButtonBackground>
  );
};

export default ActiveIntegrationFooter;
