import React from 'react';
import * as S from './styles';

const FourthStep: React.FC = () => {
  return (
    <>
      <S.ImageWrapper>
        <S.Image
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/onboarding/images/content/onboarding-account-setting.svg"
          alt="Profitfy.me finished user config"
        />
      </S.ImageWrapper>
      <S.Title>Você terminou de configurar a sua conta!</S.Title>
      <S.Desciption>
        Antes de criar sua loja, explore todas as funcionalidades da profitfy com a nossa
        demonstração!
      </S.Desciption>
    </>
  );
};

export default FourthStep;
