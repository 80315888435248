import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Divider = styled.div`
  background: linear-gradient(90deg, #25cfbb -6.34%, rgba(37, 207, 187, 0) 95.77%);
  height: 3px;
  width: 198px;
`;

export const Description = styled(TextBase)`
  font-size: 1rem;
  line-height: 130%;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[1]};
  display: inline-block;
`;

export const PricingValue = styled(TextBase)`
  font-size: 1.5rem;
  line-height: 130%;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray[1]};
  display: inline-block;
`;

export const PlanName = styled(TextBase)`
  font-size: 1.125rem;
  line-height: 130%;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray[1]};
  display: inline-block;
`;

export const PlanNameAndPricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
`;

export const CardWrapper = styled.div`
  min-width: 392px;
  height: 232px;
  background-color: ${({ theme }) => `${theme.colors.danger.default}33`};
  border: 2px solid ${({ theme }) => theme.colors.danger.default};
  border-radius: 10px;
  box-sizing: border-box;
`;
