import styled from 'styled-components/macro';

import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';

export const Option = styled(OptionBase)``;

export const Select = styled(SelectBase)`
  width: 100%;
`;

export const NeighborhoodAndCityAndStateWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const ZipCodeAndStreetAndNumberWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
  }

  & > div:first-child {
    max-width: 120px;
  }
`;

export const Input = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin: unset;
`;
