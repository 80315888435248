import React from 'react';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { IOrders } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/ISummaryReport';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import BlurredContent from './BlurredContent';

import * as S from './styles';

const Orders: React.FC<ICampaignProps> = ({ data }) => {
  const ordersData: IOrders = data;

  const { store } = useStoreConfig();
  const { isDemoEnvironment } = useConfig();

  const isContentBlurred = !store.is_utm_onboarding_finished && !isDemoEnvironment;

  return (
    <S.Wrapper>
      {isContentBlurred ? (
        <BlurredContent />
      ) : (
        <>
          <S.ApprovedOrders>{`(${ordersData.total_paid_quantity}) Aprovados`}</S.ApprovedOrders>
          <S.PendingOrders>{`(${ordersData.total_pending_quantity}) Pendentes`}</S.PendingOrders>
          <S.OrdersLabel>Total</S.OrdersLabel>
        </>
      )}
    </S.Wrapper>
  );
};

export default Orders;
