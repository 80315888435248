import fonts from '../common/fonts';
import gradient from '../common/gradient';
import zIndex from '../common/zIndex';
import colors from './colors';
import shadows from './shadows';

export default {
  fonts,
  colors,
  gradient,
  shadows,
  zIndex,
};
