import React from 'react';

import { useToast } from '@helpers/hooks/useToast';
import { useParams } from 'react-router-dom';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import trayAuthService from '@services/pages/dashboard/integrationCenter/ecommerce/tray/trayAuth';

import DisableIntegrationFooter from '@components/Dashboard/IntegrationCenter/Sidemodal/DisableIntegrationFooter';

import DeleteCredentialModal from '@components/Dashboard/IntegrationCenter/SideModalContent/DeleteCredentialModal';
import * as S from './styles';

export interface ITrayCredentialOptionProps {
  trayCredential: any;
  mutate: any;
}

const CredentialOption: React.FC<ITrayCredentialOptionProps> = ({ trayCredential }) => {
  const { mutateStore } = useStoreConfig();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();

  const [isDeletingCredential, setIsDeletingCredential] = React.useState<boolean>(false);
  const [isDeleteCredentialModalOpen, setIsDeleteCredentialModalOpen] = React.useState<boolean>(
    false,
  );

  const handleDeleteCredentialModalOpen = React.useCallback(
    () => setIsDeleteCredentialModalOpen(state => !state),
    [],
  );

  const onDeleteCredential = React.useCallback(async () => {
    setIsDeletingCredential(true);

    try {
      await trayAuthService.disableTrayCredential({
        storeAliasId,
        trayCredentialAliasId: trayCredential.alias_id,
      });

      toast.success('Credencial desativada com sucesso!');
      handleDeleteCredentialModalOpen();

      await mutateStore();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsDeletingCredential(false);
    }
  }, [mutateStore, storeAliasId, toast, trayCredential, handleDeleteCredentialModalOpen]);

  return (
    <S.Content>
      <S.CredentialDescriptionWrapper>
        <S.CredentialDescriptionTitle type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
          O que acontece se eu desativar minha integração?
        </S.CredentialDescriptionTitle>
        <S.CredentialDescriptionText>
          Ao desativar a integração,
          <strong> não será mais </strong>
          possível processar os pedidos da Tray.
        </S.CredentialDescriptionText>
      </S.CredentialDescriptionWrapper>

      <DisableIntegrationFooter
        onDeleteCredential={handleDeleteCredentialModalOpen}
        isDeletingCredential={isDeletingCredential}
      />

      <DeleteCredentialModal
        isOpen={isDeleteCredentialModalOpen}
        toggle={handleDeleteCredentialModalOpen}
        onDeleteCredential={onDeleteCredential}
        isLoading={isDeletingCredential}
      />
    </S.Content>
  );
};

export default CredentialOption;
