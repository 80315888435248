import React from 'react';
import { useParams } from 'react-router-dom';

import billingService from '@services/pages/dashboard/customValue/billing';
import { IParams } from '@domain/interfaces/IParams';
import { useToast } from '@helpers/hooks/useToast';
import {
  IBilling,
  IBillingProviderProps,
} from '@domain/interfaces/dashboard/CustomValues/IBilling';
import { IBillingContext } from '@domain/interfaces/dashboard/CustomValues/IBillingContext';

const BillingContext = React.createContext<IBillingContext | null>(null);

export const BillingProvider: React.FC<IBillingProviderProps> = ({
  selectedCustomValueChecked,
  children,
}) => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();

  const [page, setPage] = React.useState<number>(0);
  const [pageCount, setPageCount] = React.useState<number>(1);
  const [selectedBillingToUpdate, setSelectedBillingToUpdate] = React.useState<
    IBilling | undefined
  >(undefined);
  const [
    isUpdateCustomValueBillingSidemodalOpen,
    setIsUpdateCustomValueBillingSidemodalOpen,
  ] = React.useState<boolean>(false);
  const [isUpdatingCustomValueBilling, setIsUpdatingCustomValueBilling] = React.useState<boolean>(
    false,
  );
  const [isDeletingCustomValueBilling, setIsDeletingCustomValueBilling] = React.useState<boolean>(
    false,
  );

  const {
    customValueBillings,
    error: customValueBillingsError,
    isLoading: isLoadingCustomValueBillings,
    isValidating: isValidatingCustomValueBillings,
    mutate: mutateCustomValueBillings,
    totalPages,
  } = billingService.getBillings({
    storeAliasId,
    customValueAliasId: selectedCustomValueChecked?.alias_id,
    page,
  });

  const handleSelectedBillingToUpdate = React.useCallback((billing: IBilling | undefined) => {
    setSelectedBillingToUpdate(billing);
  }, []);

  const handlePage = React.useCallback(({ selected }) => setPage(selected), []);

  const handleUpdateCustomValueBillingSidemodalOpen = React.useCallback(
    () => setIsUpdateCustomValueBillingSidemodalOpen(!isUpdateCustomValueBillingSidemodalOpen),
    [isUpdateCustomValueBillingSidemodalOpen],
  );

  const updateCustomValueBilling = React.useCallback(
    async data => {
      setIsUpdatingCustomValueBilling(true);

      try {
        await billingService.updateBilling({
          storeAliasId,
          customValueAliasId: selectedCustomValueChecked?.alias_id,
          billingAliasId: selectedBillingToUpdate?.alias_id,
          data: { ...data },
        });

        toast.success('Cobrança atualizada com sucesso!');

        handleUpdateCustomValueBillingSidemodalOpen();

        await mutateCustomValueBillings();
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsUpdatingCustomValueBilling(false);
      }
    },
    [
      mutateCustomValueBillings,
      storeAliasId,
      toast,
      handleUpdateCustomValueBillingSidemodalOpen,
      selectedCustomValueChecked,
      selectedBillingToUpdate,
    ],
  );

  const deleteCustomValueBilling = React.useCallback(
    async billingAliasId => {
      setIsDeletingCustomValueBilling(true);

      try {
        await billingService.deleteBilling({
          storeAliasId,
          billingAliasId,
          customValueAliasId: selectedCustomValueChecked?.alias_id,
        });

        toast.success('Cobrança excluída com sucesso!');

        await mutateCustomValueBillings();
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsDeletingCustomValueBilling(false);
      }
    },
    [mutateCustomValueBillings, toast, storeAliasId, selectedCustomValueChecked],
  );

  React.useEffect(() => {
    if (totalPages) {
      setPageCount(totalPages);
    }
  }, [totalPages]);

  return (
    <BillingContext.Provider
      value={{
        customValueBillings,
        customValueBillingsError,
        deleteCustomValueBilling,
        handleUpdateCustomValueBillingSidemodalOpen,
        isDeletingCustomValueBilling,
        isLoadingCustomValueBillings,
        isUpdateCustomValueBillingSidemodalOpen,
        isUpdatingCustomValueBilling,
        isValidatingCustomValueBillings,
        mutateCustomValueBillings,
        page,
        pageCount,
        selectedBillingToUpdate,
        updateCustomValueBilling,
        handlePage,
        handleSelectedBillingToUpdate,
      }}
    >
      {children}
    </BillingContext.Provider>
  );
};

export const useBilling = (): IBillingContext => {
  const context = React.useContext(BillingContext);

  if (!context) {
    throw new Error('useBilling must be used within BillingProvider');
  }

  return context;
};
