import styled, { keyframes } from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

const StartAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Text = styled(TextBase)`
  font-size: 1.25rem;
  text-align: center;
`;

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.fifthLayer};
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${StartAnimation} 0.2s linear;
  backdrop-filter: blur(2px);
  flex-direction: column;
  gap: 32px;
  padding: 16px;
`;
