import React from 'react';

import { WarningCircle } from 'phosphor-react';

import { ETextSize } from '@domain/enums/components/EText';
import { EButtonSize, EButtonVariant } from '@domain/enums/components/EButton';
import { IDeleteInstanceModalProps } from '@domain/interfaces/dashboard/WhatsappInstance/IDeleteInstanceModal';

import { useWhatsappInstance } from '@helpers/hooks/pages/dashboard/whatsappInstance/useWhatsappInstance';

import * as S from './styles';

const DeleteInstanceModal: React.FC<IDeleteInstanceModalProps> = ({
  isOpen,
  toggle,
  credentialAliasId,
  metrics,
}) => {
  const {
    deleteCredential,
    isDeletingCredential,
    handleNoDeletedInstanceModalOpen,
  } = useWhatsappInstance();

  const onDelete = React.useCallback(async () => {
    if (metrics.workflow_quantity > 0) {
      handleNoDeletedInstanceModalOpen();
      toggle();
    } else {
      await deleteCredential(credentialAliasId);

      toggle();
    }
  }, [deleteCredential, credentialAliasId, metrics, handleNoDeletedInstanceModalOpen, toggle]);

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Header>
        <WarningCircle size={24} color="#D03333" />
        <S.Heading>Deseja excluir essa instância?</S.Heading>
      </S.Header>

      <S.Description size={ETextSize.B5}>
        Ao excluir uma instância, o <strong>número associado</strong> à essa <br /> instância{' '}
        <strong>
          será removida do nosso sistema. Não será <br /> possível reverter essa ação
        </strong>
        , sendo necessário criar uma nova <br /> instância com o número excluído.
      </S.Description>

      <S.ButtonsWrapper>
        <S.CancelButton
          onClick={toggle}
          variant={EButtonVariant.SECONDARY_BUTTON}
          buttonSize={EButtonSize.MEDIUM}
        >
          Cancelar
        </S.CancelButton>

        <S.DeleteInstanceButton
          onClick={onDelete}
          isLoading={isDeletingCredential}
          disabled={isDeletingCredential}
        >
          Excluir instância
        </S.DeleteInstanceButton>
      </S.ButtonsWrapper>
    </S.Modal>
  );
};

export default DeleteInstanceModal;
