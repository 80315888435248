import React from 'react';

import { useSocket } from '@novu/notification-center';

import { INotificationData } from '@domain/interfaces/dashboard/Notifications/INotification';

import Notification from '../Notification';

import * as S from './styles';

const NotificationsWrapper: React.FC = () => {
  const SECONDS_TO_REMOVE_FIRST_ITEM = 10;

  const { socket } = useSocket();

  const [notifications, setNotifications] = React.useState<Array<INotificationData>>([]);

  const removeFirstItem = React.useCallback(() => {
    const firstItem = notifications.shift();

    setNotifications(currentNotifications =>
      currentNotifications.filter(notification => notification?.id !== firstItem?.id),
    );
  }, [notifications]);

  const onCloseManually = React.useCallback((event, notificationId) => {
    event.stopPropagation();

    setNotifications(currentNotifications =>
      currentNotifications.filter(notification => notification?.id !== notificationId),
    );
  }, []);

  const closeNotification = React.useCallback(notificationId => {
    setNotifications(currentNotifications =>
      currentNotifications.filter(notification => notification?.id !== notificationId),
    );
  }, []);

  React.useEffect(() => {
    if (socket) {
      socket.on('notification_received', data => {
        if (data.message.payload) {
          const newData = {
            id: data.message.id,
            ...data.message.payload,
          };

          setNotifications(currentNotifications => [...currentNotifications, newData]);
        }
      });
    }
  }, [socket]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      removeFirstItem();
    }, SECONDS_TO_REMOVE_FIRST_ITEM * 1000);

    return () => clearInterval(interval);
  }, [removeFirstItem]);

  return (
    <S.Wrapper>
      {notifications.map(notification => {
        return (
          <Notification
            data={notification}
            onCloseManually={(event: any) => onCloseManually(event, notification.id)}
            closeNotification={() => closeNotification(notification.id)}
          />
        );
      })}
    </S.Wrapper>
  );
};

export default NotificationsWrapper;
