// import ProgressBar from '@components/common/core/DataDisplay/ProgressBar';
// import { useHome } from '@helpers/hooks/pages/dashboard/home/useHome';
// import { useConfig } from '@helpers/hooks/useConfig';
// import { IParams } from '@domain/interfaces/IParams';
// import { CaretRight, GearSix, Check } from 'phosphor-react';
import React from 'react';
// import { useHistory, useParams } from 'react-router-dom';
// import { useTheme } from 'styled-components';
// import HelpCenter from '../HelpCenter';
import OnboardingFinished from '../OnboardingFinished';
// import * as S from './styles';

const Wrapper: React.FC = () => {
  // const { storeAliasId } = useParams<IParams>();
  // const { colors } = useTheme();
  // const { user } = useConfig();
  // const history = useHistory();
  // const {
  //   isOnboardingFinished,
  //   onboardingSteps,
  //   homeSetup,
  //   isLoadingHomeSetup,
  //   quantityCrucialSteps,
  //   currentQuantityCrucialSteps,
  // } = useHome();

  // const handleClick = React.useCallback(
  //   url => {
  //     history.push(`/${storeAliasId}${url}`);
  //   },
  //   [history, storeAliasId],
  // );

  // const getGreetingsText = React.useCallback(() => {
  //   const date = new Date();
  //   const hours = date.getHours();

  //   if (hours >= 0 && hours < 12) {
  //     return 'Bom dia';
  //   }

  //   if (hours >= 12 && hours < 18) {
  //     return 'Boa tarde';
  //   }

  //   return 'Boa noite';
  // }, []);

  return <OnboardingFinished />;

  // return (
  //   <S.Container>
  //     <S.Title>
  //       {getGreetingsText()}, {user.first_name}!
  //     </S.Title>
  //     <S.SubTitle>Configure as etapas essenciais para poder utilizar a Profitfy!</S.SubTitle>

  //     <S.Wrapper>
  //       <S.Header>
  //         <S.HeaderTitleWrapper>
  //           <S.IconWrapper>
  //             <GearSix size={20} weight="fill" fill={colors.gray[1]} color={colors.gray[1]} />
  //           </S.IconWrapper>
  //           <S.CardTitleWrapper>
  //             <S.CardTitle>Primeiras etapas</S.CardTitle>
  //             <S.CardDesciption>Comece a utilizar a Profitfy.</S.CardDesciption>
  //           </S.CardTitleWrapper>
  //         </S.HeaderTitleWrapper>

  //         <S.ProgressBarWrapper>
  //           <S.StepsText>
  //             {currentQuantityCrucialSteps} de {quantityCrucialSteps} tarefas concluídas
  //           </S.StepsText>
  //           <ProgressBar
  //             total={quantityCrucialSteps}
  //             value={currentQuantityCrucialSteps}
  //             colorBar={colors.gray[5]}
  //             height={7}
  //           />
  //         </S.ProgressBarWrapper>
  //       </S.Header>

  //       <S.Divider />

  //       <S.Body>
  //         {!isLoadingHomeSetup &&
  //           onboardingSteps.map(step => {
  //             const isStepCompleted = homeSetup[step.id];
  //             return (
  //               <S.StepWrapper onClick={() => handleClick(step.linkTo)}>
  //                 <S.StepMark completed={isStepCompleted}>
  //                   {isStepCompleted && <Check size={24} color={colors.green.dark} />}
  //                 </S.StepMark>

  //                 <S.StepTitleWrapper>
  //                   <S.StepTitle>{step.title}</S.StepTitle>

  //                   <S.StepDescription>{step.description}</S.StepDescription>
  //                 </S.StepTitleWrapper>

  //                 <S.ArrowWrapper>
  //                   <CaretRight size={24} color={colors.gray[3]} />
  //                 </S.ArrowWrapper>
  //               </S.StepWrapper>
  //             );
  //           })}
  //       </S.Body>
  //     </S.Wrapper>

  //     <HelpCenter />
  //   </S.Container>
  // );
};

export default Wrapper;
