import styled from 'styled-components/macro';

export const NameWrapper = styled.div`
  max-width: 158px;
  width: 100%;

  & > span > span {
    display: block;
  }
`;

export const AvatarWrapper = styled.div`
  & > span > span {
    display: block;
    border-radius: 50%;
  }
`;

export const AdSenseCredentialWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
`;
