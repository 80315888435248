import styled from 'styled-components/macro';

import BackgroundImg from '@assets/pages/SignIn/background-img.svg';
import Image from '@components/common/core/DataDisplay/Image';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Button = styled(ButtonBase)`
  max-width: 374px !important;
  width: 100%;
`;

export const Logo = styled(Image)`
  max-width: 162px;
  width: 100%;
  position: absolute;
  top: 52px;
  left: 40px;

  @media only screen and (max-width: 1368px) {
    top: 24px;
    left: 24px;
  }

  @media only screen and (max-width: 1024px) {
    position: relative;
    top: unset;
    left: unset;
    max-width: 224px;
    margin-top: 64px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: center;
  align-items: center;
  flex-direction: column;
  margin-left: 290px;
  margin-top: 132px;
  max-width: 374px;
  width: 100%;
  margin-bottom: 90px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  padding: 32px;
  border-radius: 7px;
  margin-bottom: 32px;

  @media only screen and (max-width: 1368px) {
    margin-top: 92px;
  }

  @media only screen and (max-width: 1024px) {
    margin: 0 auto;
    margin-top: unset;
    background-color: transparent;
    padding: unset;
  }

  @media only screen and (max-width: 425px) {
    box-sizing: border-box;
    padding: 0 16px;
  }
`;

export const BackgroundGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: ${({ theme }) => theme.gradient.blackWhite};
  background-color: ${({ theme }) => theme.colors.black.default};
  z-index: ${({ theme }) => theme.zIndex.behindLayer};
  top: 0;
`;

export const Wrapper = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
`;

export const Container = styled.div`
  min-height: 100vh;
  background-image: url(${BackgroundImg});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
  overflow: hidden;
  position: relative;
  background-color: #000;

  @media only screen and (max-width: 1024px) {
    background-image: none;
  }
`;
