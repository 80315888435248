/* eslint-disable no-restricted-syntax */
import React from 'react';

import { IAnnouncementProvider } from '@domain/interfaces/common/announcement/IAnnouncement';
import { IAnnouncement } from '@domain/interfaces/dashboard/AdminPanel/IAnnouncement';

import { useToast } from '@helpers/hooks/useToast';
import { useDate } from '@helpers/hooks/useDate';

import announcementService from '@services/pages/dashboard/adminPanel/announcement';
import userAnnouncementService from '@services/pages/dashboard/adminPanel/userAnnouncement';

const AnnouncementContext = React.createContext<IAnnouncementProvider | null>(null);

export const AnnouncementProvider: React.FC = ({ children }) => {
  const { toast } = useToast();

  const { isAfter, isBefore } = useDate();

  const [isCreatingAnnouncement, setIsCreatingAnnouncement] = React.useState<boolean>(false);
  const [announcement, setAnnouncement] = React.useState<IAnnouncement>({} as IAnnouncement);
  const [isAnnouncementOpen, setIsAnnouncementOpen] = React.useState<boolean>(false);
  const [isPreviewAnnouncementOpen, setIsPreviewAnnouncementOpen] = React.useState<boolean>(false);
  const [previewData, setPreviewData] = React.useState<Record<string, any>>({});

  const {
    announcements,
    isLoading: isLoadingAnnouncements,
    isValidating: isValidatingAnnouncements,
  } = announcementService.listAnnouncements();

  const {
    userAnnouncements,
    isLoading: isLoadingUserAnnouncements,
    isValidating: isValidatingUserAnnouncements,
  } = userAnnouncementService.listUserAnnouncements();

  const isLoadingAnnoucementsData = isLoadingAnnouncements || isValidatingAnnouncements;

  const hasAnnouncements = Boolean(announcements?.length) && !isLoadingAnnoucementsData;

  const isLoadingUserAnnouncementsData =
    isLoadingUserAnnouncements || isValidatingUserAnnouncements;

  const hasUserAnnouncements = Boolean(userAnnouncements) && !isLoadingUserAnnouncementsData;

  const handlePreviewData = React.useCallback(newData => {
    setPreviewData(newData);
  }, []);

  const handlePreviewAnnouncementOpen = React.useCallback(() => {
    setIsPreviewAnnouncementOpen(currentState => !currentState);
  }, []);

  const openPreviewAnnouncement = React.useCallback(() => {
    setIsPreviewAnnouncementOpen(true);
  }, []);

  const addView = React.useCallback(
    async (announcement_alias_id: string) => {
      try {
        await userAnnouncementService.upsertAnnouncement({
          announcement_alias_id,
          data: { add_view: true, is_closed_manually: false },
        });
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
    [toast],
  );

  const handleCloseAnnouncement = React.useCallback(async () => {
    try {
      await userAnnouncementService.upsertAnnouncement({
        announcement_alias_id: announcement.alias_id,
        data: { add_view: false, is_closed_manually: true },
      });

      setIsAnnouncementOpen(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [toast, announcement]);

  const createAnnouncement = React.useCallback(
    async data => {
      setIsCreatingAnnouncement(true);

      try {
        await announcementService.createAnnouncement({ data });

        toast.success('Anúncio criado com sucesso.');

        setIsCreatingAnnouncement(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsCreatingAnnouncement(false);
      }
    },
    [toast],
  );

  const getMoreRecentAnnouncement = React.useCallback((): IAnnouncement => {
    if (announcements) {
      let firstPositionOfArray = announcements[0];

      for (const announcementItem of announcements) {
        const isAfterDate = isAfter(
          new Date(announcementItem.created_at),
          new Date(firstPositionOfArray.created_at),
        );

        if (isAfterDate) {
          firstPositionOfArray = announcementItem;
        }
      }

      return firstPositionOfArray;
    }

    return {} as IAnnouncement;
  }, [announcements, isAfter]);

  const checkModalConfig = React.useCallback(() => {
    const moreRecentAnnouncement = getMoreRecentAnnouncement();

    setAnnouncement(moreRecentAnnouncement);

    const foundAnnouncement = userAnnouncements?.find(
      userAnnouncement => userAnnouncement.announcement_id === moreRecentAnnouncement.id,
    );

    if (!foundAnnouncement) {
      setIsAnnouncementOpen(true);
      addView(moreRecentAnnouncement.alias_id);
    }

    if (foundAnnouncement) {
      const { total_views, is_closed_manually } = foundAnnouncement;

      const hasExceededQuantityViews = total_views >= moreRecentAnnouncement.maximum_view_quantity;

      if (hasExceededQuantityViews) setIsAnnouncementOpen(false);

      if (is_closed_manually) setIsAnnouncementOpen(false);

      const isAfterDate = isAfter(new Date(), new Date(moreRecentAnnouncement.start_date));
      const isBeforeDate = isBefore(new Date(), new Date(moreRecentAnnouncement.end_date));

      const isCorrectDate = isAfterDate && isBeforeDate;

      if (isCorrectDate && !hasExceededQuantityViews && !is_closed_manually) {
        setIsAnnouncementOpen(true);
        addView(moreRecentAnnouncement.alias_id);
      }
    }
  }, [userAnnouncements, addView, getMoreRecentAnnouncement, isAfter, isBefore]);

  React.useEffect(() => {
    if (hasAnnouncements && hasUserAnnouncements) {
      checkModalConfig();
    }
  }, [checkModalConfig, hasAnnouncements, hasUserAnnouncements]);

  return (
    <AnnouncementContext.Provider
      value={{
        createAnnouncement,
        isCreatingAnnouncement,
        handleCloseAnnouncement,
        isAnnouncementOpen,
        announcement,
        handlePreviewAnnouncementOpen,
        isPreviewAnnouncementOpen,
        handlePreviewData,
        previewData,
        openPreviewAnnouncement,
      }}
    >
      {children}
    </AnnouncementContext.Provider>
  );
};

export const useAnnouncement = (): IAnnouncementProvider => {
  const context = React.useContext(AnnouncementContext);

  if (!context) {
    throw new Error('useAnnouncement must be used within AnnouncementContext');
  }

  return context;
};
