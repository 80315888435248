import React from 'react';

import { useKit } from '@helpers/hooks/pages/dashboard/kit/useKit';
import PageError from '@components/common/core/Utils/PageError';
import { useConfig } from '@helpers/hooks/useConfig';
import EmptyKit from '../EmptyKit';
import Header from '../Header';
import Table from '../Table';

import SkeletonLoading from '../SkeletonLoading';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const {
    variantKits,
    isLoadingKitProvider,
    isValidatingKitProvider,
    isKitProviderError,
    mutateKitProvider,
  } = useKit();
  const { analytics, selectedStore, user } = useConfig();

  React.useEffect(() => {
    analytics?.track(
      'Kit Cost Viewed',
      {
        email: user?.email,
      },
      { context: { groupId: selectedStore?.alias_id } },
    );
  }, [analytics, selectedStore, user]);

  if (isLoadingKitProvider || isValidatingKitProvider) {
    return <SkeletonLoading />;
  }

  if (isKitProviderError && !isValidatingKitProvider) {
    return <PageError mutate={mutateKitProvider} />;
  }

  if (variantKits && !variantKits.length) {
    return <EmptyKit />;
  }

  return (
    <S.Container>
      <Header />

      <Table />
    </S.Container>
  );
};

export default Wrapper;
