import { EBusinessType } from '@domain/enums/stores/Etype';
import React from 'react';
import * as S from './styles';

interface IFirstStepProps {
  handleStoreSegment: (answer: string) => void;
  storeSegment: EBusinessType;
}

const FirstStep: React.FC<IFirstStepProps> = ({ handleStoreSegment, storeSegment }) => {
  const onRadioChange = React.useCallback(
    event => {
      handleStoreSegment(event.target.value);
    },
    [handleStoreSegment],
  );

  return (
    <>
      <S.Question>Qual seu tipo de negócio?</S.Question>
      <S.RadioGroup>
        <S.RadioLabel checked={storeSegment === EBusinessType.DROPSHIPPING}>
          <S.Radio
            name="business_type"
            value={EBusinessType.DROPSHIPPING}
            onChange={onRadioChange}
            id={EBusinessType.DROPSHIPPING}
            defaultChecked={storeSegment === EBusinessType.DROPSHIPPING}
          />
          <S.RadioText>Dropshipping</S.RadioText>
        </S.RadioLabel>

        <S.RadioLabel checked={storeSegment === EBusinessType.TRADITIONAL_ECOMMERCE}>
          <S.Radio
            name="business_type"
            value={EBusinessType.TRADITIONAL_ECOMMERCE}
            onChange={onRadioChange}
            id={EBusinessType.TRADITIONAL_ECOMMERCE}
            defaultChecked={storeSegment === EBusinessType.TRADITIONAL_ECOMMERCE}
          />
          <S.RadioText>E-commerce</S.RadioText>
        </S.RadioLabel>

        <S.RadioLabel checked={storeSegment === EBusinessType.INFO_PRODUCT}>
          <S.Radio
            name="business_type"
            value={EBusinessType.INFO_PRODUCT}
            onChange={onRadioChange}
            id={EBusinessType.INFO_PRODUCT}
            defaultChecked={storeSegment === EBusinessType.INFO_PRODUCT}
          />
          <S.RadioText>Infoproduto</S.RadioText>
        </S.RadioLabel>
      </S.RadioGroup>
    </>
  );
};

export default FirstStep;
