import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Wrapper = styled.div`
  width: 100%;
  padding: 16px 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray[5]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`;

export const CheckboxAndSelectAllExpensesTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const CheckboxWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.black.light};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SelectAllExpensesText = styled(TextBase)``;

export const SelectAllExpensesCheckbox = styled(CheckboxBase)``;

export const ChangeStatusButton = styled(ButtonBase)``;
