import styled, { keyframes } from 'styled-components/macro';

const iconAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  animation: ${iconAnimation} 0.15s ease-in-out;
`;

export const FieldButton = styled.button`
  background-color: unset;
  border: unset;
  padding: unset;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.font};
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.green.default};
  }

  &:active {
    color: ${({ theme }) => theme.colors.green.dark};
  }
`;
