import * as yup from 'yup';

export const hubsaleSchema = yup.object().shape({
  client_id: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'Informe uma credencial válida')
    .max(200, 'Informe uma credencial válida'),
  client_secret: yup
    .string()
    .required('Este campo é obrigatório')
    .min(2, 'Informe uma credencial válida')
    .max(200, 'Informe uma credencial válida'),
});
