import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';

export const StatusWrapper = styled.div`
  max-width: 100px;
  width: 100%;
`;

export const FrequencyWrapper = styled.div`
  max-width: 80px;
  width: 100%;
`;

export const ValueWrapper = styled.div`
  max-width: 80px;
  width: 100%;
`;

export const EndDateWrapper = styled.div`
  max-width: 72px;
  width: 100%;
`;

export const StartDateWrapper = styled.div`
  max-width: 72px;
  width: 100%;
`;

export const DescriptionCategoryWrapper = styled.div`
  max-width: 72px;
  width: 100%;
`;

export const DescriptionNameWrapper = styled.div`
  max-width: 120px;
  width: 100%;
`;

export const DescriptionWrapper = styled.div`
  max-width: 174px;
  width: 100%;
`;

export const CheckboxWrapper = styled.div`
  max-width: 16px;
  width: 100%;
`;

export const Table = styled(TableBase)`
  margin-bottom: 224px;

  & > table {
    & > thead {
      & > tr {
        & > th {
          padding: 10px 28px;
        }

        & > th:first-child {
          padding-left: 32px;
        }

        & > th:last-child {
          padding-right: 32px;
        }
      }
    }

    & > tbody {
      & > tr {
        & > td {
          padding: 10px 28px;

          & > td:first-child {
            padding-left: 32px;
          }

          & > td:last-child {
            padding-right: 32px;
          }
        }
      }
    }
  }
`;
