import React from 'react';
import { useParams } from 'react-router-dom';

import { IAccessManagerProvider } from '@domain/interfaces/dashboard/AccessManagement/IAccessManager';
import { IParams } from '@domain/interfaces/IParams';
import { IGroup } from '@domain/interfaces/common/group/IGroup';

import { useToast } from '@helpers/hooks/useToast';

import { getSelectedGroupPermissions } from '@helpers/utils/dashboard/adsManagement.ts/getSelectedGroupPermissions';

import groupService from '@services/pages/dashboard/accessManagement/group';
import userInviteService from '@services/pages/dashboard/accessManagement/userInvite';
import userStoreService from '@services/pages/dashboard/accessManagement/userStore';

const AccessManagerContext = React.createContext<IAccessManagerProvider | null>(null);

export const AccessManagerProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();

  const [selectedPermissions, setSelectedPermissions] = React.useState<string[]>([]);
  const [isDeleteMemberModalOpen, setIsDeleteMemberModalOpen] = React.useState<boolean>(false);
  const [isDeleteGroupModalOpen, setIsDeleteGroupModalOpen] = React.useState<boolean>(false);
  const [isDeletePendingInviteModalOpen, setIsDeletePendingInviteOpen] = React.useState<boolean>(
    false,
  );
  const [isEditGroupSideModalOpen, setIsEditGroupSideModalOpen] = React.useState<boolean>(false);
  const [selectedGroupsId, setSelectedGroupsId] = React.useState<Array<string>>([]);
  const [selectedUsersStoreId, setSelectedUsersStoreId] = React.useState<Array<string>>([]);
  const [selectedPendingInvitationsId, setSelectedPendingInvitationsId] = React.useState<
    Array<string>
  >([]);
  const [selectedGroupToEdit, setSelectedGroupToEdit] = React.useState<IGroup | undefined>(
    undefined,
  );
  const [isDeletingGroups, setIsDeletingGroups] = React.useState<boolean>(false);
  const [isDeletingMembers, setIsDeletingMembers] = React.useState<boolean>(false);
  const [isDeletingPendingInvites, setIsDeletingPendingInvites] = React.useState<boolean>(false);

  const {
    groups,
    isLoading: isLoadingGroups,
    isValidating: isValidatingGroups,
    error: groupsError,
    mutate: mutateGroups,
  } = groupService.listGroups({ storeAliasId });
  const {
    userInvites,
    isLoading: isLoadingUserInvites,
    isValidating: isValidatingUserInvites,
    error: userInvitesError,
    mutate: mutateUserInvites,
  } = userInviteService.listUserInvites({ storeAliasId });
  const {
    users,
    isLoading: isLoadingUsersStore,
    isValidating: isValidatingUsersStore,
    mutate: mutateUsersStore,
    error: usersStoreError,
  } = userStoreService.listUsersStore({ storeAliasId });

  const isLoadingAccessManagement = isLoadingGroups || isLoadingUserInvites || isLoadingUsersStore;
  const isValidatingAccessManagement =
    isValidatingGroups || isValidatingUserInvites || isValidatingUsersStore;
  const isAccessManagementError =
    Boolean(groupsError) || Boolean(userInvitesError) || Boolean(usersStoreError);

  const mutate = React.useCallback(async () => {
    const promises = [];

    if (groupsError) promises.push(mutateGroups());

    if (userInvitesError) promises.push(mutateUserInvites());

    if (usersStoreError) promises.push(mutateUsersStore());

    await Promise.all(promises);
  }, [
    groupsError,
    mutateGroups,
    userInvitesError,
    mutateUserInvites,
    usersStoreError,
    mutateUsersStore,
  ]);

  const handleSelectedGroupsId = React.useCallback(newSelectedGroupsId => {
    setSelectedGroupsId(newSelectedGroupsId);
  }, []);

  const handleSelectedUsersStoreId = React.useCallback(
    newSelectedUsersStoreId => setSelectedUsersStoreId(newSelectedUsersStoreId),
    [],
  );

  const handleSelectedPendingInvitationsId = React.useCallback(
    newSelectedPendingInvitationsId =>
      setSelectedPendingInvitationsId(newSelectedPendingInvitationsId),
    [],
  );

  const handleSelectedGroupToEdit = React.useCallback(
    newSelectedGroupToEdit => setSelectedGroupToEdit(newSelectedGroupToEdit),
    [],
  );

  const handleEditGroupSideModalOpen = React.useCallback(() => {
    setIsEditGroupSideModalOpen(!isEditGroupSideModalOpen);
  }, [isEditGroupSideModalOpen]);

  const handleDeleteMemberModalOpen = React.useCallback(() => {
    setIsDeleteMemberModalOpen(!isDeleteMemberModalOpen);
  }, [isDeleteMemberModalOpen]);

  const handleDeleteGroupModalOpen = React.useCallback(() => {
    setIsDeleteGroupModalOpen(!isDeleteGroupModalOpen);
  }, [isDeleteGroupModalOpen]);

  const handleDeletePendingInviteModalOpen = React.useCallback(
    () => setIsDeletePendingInviteOpen(state => !state),
    [],
  );

  const handleDeleteGroups = React.useCallback(async () => {
    setIsDeletingGroups(true);

    const data = {
      group_ids: selectedGroupsId,
    };

    try {
      await groupService.deleteGroupPromise({ storeAliasId, data });

      toast.success('Grupo(s) removido(s) com sucesso!');

      handleDeleteGroupModalOpen();
      handleSelectedGroupsId([]);

      await mutateGroups();

      setIsDeletingGroups(false);
    } catch (error: any) {
      setIsDeletingGroups(false);
      toast.error(error?.response?.data?.message);
    }
  }, [
    mutateGroups,
    storeAliasId,
    toast,
    selectedGroupsId,
    handleDeleteGroupModalOpen,
    handleSelectedGroupsId,
  ]);

  const handleDeleteMembers = React.useCallback(async () => {
    setIsDeletingMembers(true);

    const data = {
      user_ids: selectedUsersStoreId,
    };

    try {
      await userStoreService.deleteUsersStorePromise({ storeAliasId, data });

      toast.success('Membro(s) removido(s) com sucesso!');

      handleDeleteMemberModalOpen();
      handleSelectedUsersStoreId([]);

      await mutateUsersStore();

      setIsDeletingMembers(false);
    } catch (error: any) {
      setIsDeletingMembers(false);
    }
  }, [
    mutateUsersStore,
    storeAliasId,
    toast,
    selectedUsersStoreId,
    handleDeleteMemberModalOpen,
    handleSelectedUsersStoreId,
  ]);

  const handleDeletePendingInvites = React.useCallback(async () => {
    setIsDeletingPendingInvites(true);

    const data = {
      user_group_invite_ids: selectedPendingInvitationsId,
    };

    try {
      await userInviteService.deleteUserInvitesPromise({ storeAliasId, data });

      toast.success('Convite(s) removido(s) com sucesso!');

      handleDeletePendingInviteModalOpen();
      handleSelectedPendingInvitationsId([]);

      await mutateUserInvites();

      setIsDeletingPendingInvites(false);
    } catch (error: any) {
      setIsDeletingPendingInvites(false);
      toast.error(error?.response?.data?.message);
    }
  }, [
    mutateUserInvites,
    storeAliasId,
    toast,
    selectedPendingInvitationsId,
    handleDeletePendingInviteModalOpen,
    handleSelectedPendingInvitationsId,
  ]);

  const handleSelectedPermissions = React.useCallback(permissions => {
    setSelectedPermissions(permissions);
  }, []);

  React.useEffect(() => {
    if (selectedGroupToEdit) {
      const permissions = getSelectedGroupPermissions(selectedGroupToEdit);

      setSelectedPermissions(permissions);
    }
  }, [selectedGroupToEdit]);

  return (
    <AccessManagerContext.Provider
      value={{
        selectedPermissions,
        handleSelectedPermissions,
        handleDeleteMemberModalOpen,
        isDeleteMemberModalOpen,
        handleDeleteGroupModalOpen,
        isDeleteGroupModalOpen,
        handleEditGroupSideModalOpen,
        isEditGroupSideModalOpen,
        groups,
        isAccessManagementError,
        isLoadingAccessManagement,
        isValidatingAccessManagement,
        mutate,
        userInvites,
        handleSelectedUsersStoreId,
        handleSelectedGroupsId,
        selectedUsersStoreId,
        selectedGroupsId,
        handleDeleteGroups,
        isDeletingGroups,
        handleDeleteMembers,
        isDeletingMembers,
        handleSelectedPendingInvitationsId,
        selectedPendingInvitationsId,
        handleDeletePendingInviteModalOpen,
        isDeletePendingInviteModalOpen,
        handleDeletePendingInvites,
        isDeletingPendingInvites,
        users,
        handleSelectedGroupToEdit,
        selectedGroupToEdit,
        mutateGroups,
        mutateUserInvites,
        mutateUsersStore,
      }}
    >
      {children}
    </AccessManagerContext.Provider>
  );
};

export const useAccessManager = (): IAccessManagerProvider => {
  const context = React.useContext(AccessManagerContext);

  if (!context) {
    throw new Error('useAccessManager must be used within AccessManagerContext');
  }

  return context;
};
