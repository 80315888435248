import { AxiosResponse } from 'axios';

import { ICreateScheduleProps } from '@domain/interfaces/common/schedule/ISchedule';

import { ApiService } from '@services/api';
import { API_DOMAIN } from '@constants/api';

export class ScheduleService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public createSchedule({
    storeAliasId,
    data,
  }: ICreateScheduleProps): Promise<AxiosResponse<void>> {
    return this.apiService.post<void>(`/api/v1/users/stores/${storeAliasId}/schedules`, {
      ...data,
    });
  }
}

const scheduleService = new ScheduleService(API_DOMAIN);

export default scheduleService;
