import React from 'react';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';

import { useAdsManager } from '@helpers/hooks/pages/dashboard/adsManager/useAdsManager';
import { useCampaigns } from '@helpers/hooks/pages/dashboard/adsManager/useCampaigns';

import * as S from './styles';

const Checkbox: React.FC<ICampaignProps> = ({ adSenseCampaignAliasId }) => {
  const { hasSelectedAll } = useAdsManager();
  const { setSelectedCampaigns, selectedCampaigns } = useCampaigns();

  const [hasChecked, setHasChecked] = React.useState<boolean>(false);

  const onCheckboxChange = React.useCallback(
    event => {
      if (event && !event.target) {
        if (event.checked) {
          setSelectedCampaigns(previousState => [...previousState, adSenseCampaignAliasId]);
        } else {
          setSelectedCampaigns(previousState => {
            const filteredCampaigns = previousState.filter(
              campaign => campaign !== adSenseCampaignAliasId,
            );

            return filteredCampaigns;
          });
        }
      }
    },
    [adSenseCampaignAliasId, setSelectedCampaigns],
  );

  React.useEffect(() => {
    setHasChecked(hasSelectedAll);
  }, [hasSelectedAll]);

  React.useEffect(() => {
    const foundCampaign = selectedCampaigns.find(campaign => campaign === adSenseCampaignAliasId);

    if (foundCampaign) {
      setHasChecked(true);
    } else {
      setHasChecked(false);
    }
  }, [selectedCampaigns, adSenseCampaignAliasId]);

  return (
    <S.Wrapper>
      <S.Checkbox checked={hasChecked} onChange={onCheckboxChange} isBigVariant />
    </S.Wrapper>
  );
};

export default Checkbox;
