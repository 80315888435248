import React from 'react';

import { useAnnouncement } from '@helpers/hooks/pages/dashboard/admin/useAnnouncement';

import Content from '@components/Dashboard/AdminPanel/Announcement';
import PreviewAnnouncement from '@components/Dashboard/AdminPanel/Announcement/PreviewAnnouncement';

const Announcement: React.FC = () => {
  const { isPreviewAnnouncementOpen } = useAnnouncement();

  return (
    <>
      <Content />

      {isPreviewAnnouncementOpen && <PreviewAnnouncement />}
    </>
  );
};

export default Announcement;
