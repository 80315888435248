/* eslint-disable no-console */
import React from 'react';

import { GoogleAds as GoogleAdsIcon } from '@profitfy/pakkun-icons';
import { EHeadingSize } from '@domain/enums/components/EHeading';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { useGoogleLogin } from '@react-oauth/google';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useGoogleCredentials } from '@helpers/hooks/common/integrations/marketing/google/useGoogleCredentials';
import { GoogleAdAccountsProvider } from '@helpers/hooks/common/integrations/marketing/google/useGoogleAdAccounts';

import GoogleAdsImg from '@assets/pages/Onboarding/dropshipping/marketing/google-ads.svg';

import PageError from '@components/common/core/Utils/PageError';
import Header from '../Header';
import AdAccountsTable from '../Table';

import { PageSkeletonLoading, TableSkeletonLoading } from '../SkeletonLoading';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const { currentSynchronizations } = useSynchronization();
  const {
    adSenseCredentials,
    isGoogleCredentialsDataError,
    isLoadingGoogleCredentialsData,
    isValidatingGoogleCredentialsData,
    isLoggingIn,
    isLoggingOut,
    mutate,
    handleLogin,
  } = useGoogleCredentials();

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: response => {
      handleLogin(response);
    },
    onError: response => console.log(response),
    flow: 'auth-code',
    scope:
      'openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/adwords',
  });

  const isSyncing = Boolean(
    currentSynchronizations?.synchronizations.find(synchronization => {
      if (
        synchronization.type === ESynchronizationType.AD_SENSE_ACCOUNTS ||
        synchronization.type === ESynchronizationType.AD_SENSE_CAMPAIGNS
      ) {
        return true;
      }

      return false;
    }),
  );

  const isLoading =
    isLoadingGoogleCredentialsData ||
    isValidatingGoogleCredentialsData ||
    isLoggingIn ||
    isLoggingOut;

  if (isLoading) {
    return (
      <>
        <PageSkeletonLoading />
      </>
    );
  }

  if (isGoogleCredentialsDataError && !isValidatingGoogleCredentialsData) {
    return (
      <>
        <S.ErrorWrapper>
          <PageError mutate={mutate} />
        </S.ErrorWrapper>
      </>
    );
  }

  return (
    <>
      {Boolean(adSenseCredentials.length) && (
        <S.HeaderAndTableContainer>
          <Header />

          {isSyncing && <TableSkeletonLoading />}

          {!isSyncing && (
            <>
              {adSenseCredentials.map((adSenseCredential, index) => (
                <GoogleAdAccountsProvider
                  adSenseCredentialAliasId={adSenseCredential.alias_id}
                  key={adSenseCredential.alias_id}
                >
                  <AdAccountsTable
                    openFirstProfile={Boolean(index === 0)}
                    adSenseCredential={adSenseCredential}
                  />
                </GoogleAdAccountsProvider>
              ))}
            </>
          )}
        </S.HeaderAndTableContainer>
      )}

      {Boolean(!adSenseCredentials.length) && (
        <S.ContentWrapper>
          <S.Image src={GoogleAdsImg} alt="profitfy-goat-with-google-ads-logo" />

          <S.Heading type={EHeadingSize.H2}>Conectar perfil do Google Ads</S.Heading>

          <S.AddNewProfileButton onClick={handleGoogleLogin}>
            <GoogleAdsIcon size={22} />
            Adicionar perfil do Google Ads
          </S.AddNewProfileButton>
        </S.ContentWrapper>
      )}
    </>
  );
};

export default Wrapper;
