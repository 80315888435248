import React from 'react';
import { ResponsiveContainer, PieChart as Chart, Pie, Cell } from 'recharts';

import { ICustomValueChartProps } from '@domain/interfaces/dashboard/FinancialDashboard/ICustomValue';

import * as S from './styles';

const PieChart: React.FC<ICustomValueChartProps> = ({ customValue }) => {
  const data = [
    {
      name: customValue.name,
      value: customValue.percentage,
    },
    {
      name: 'Total',
      value: 100 - customValue.percentage,
    },
  ];

  const colors = [customValue.hex_color, '#515562'];

  return (
    <S.ChartWrapper>
      <ResponsiveContainer width="100%">
        <Chart>
          <Pie
            data={data}
            innerRadius="80%"
            outerRadius="100%"
            fill="#000"
            paddingAngle={0}
            dataKey="value"
            strokeOpacity={0}
          >
            {data.map((pie, index) => (
              <Cell key={`cell-${pie.name}`} fill={colors[index]} />
            ))}
          </Pie>
        </Chart>
      </ResponsiveContainer>
    </S.ChartWrapper>
  );
};

export default PieChart;
