import React from 'react';

import { useCanvasMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessageSettings';

import { ECreateMessageStep, EMessageType } from '@domain/enums/dashboard/automations/EMessage';

import * as S from './styles';

const MessageTemplate: React.FC<any> = ({ template }) => {
  const {
    handleContents,
    contents,
    handleCreateNewMessageStep,
    handleTemplateMessage,
    handleSelectedMessageTrigger,
  } = useCanvasMessageSettings();

  const onTemplateClick = React.useCallback(() => {
    if (template.id === 'no-content') {
      handleTemplateMessage(false);
      handleSelectedMessageTrigger(EMessageType.ABANDONED_CART);
      handleCreateNewMessageStep(ECreateMessageStep.CONFIG_MESSAGE);
      return;
    }

    const { message_contents } = template;

    handleContents([...contents, ...message_contents]);

    handleTemplateMessage(true);
    handleSelectedMessageTrigger(template.type);
    handleCreateNewMessageStep(ECreateMessageStep.CONFIG_MESSAGE);
  }, [
    handleCreateNewMessageStep,
    template,
    contents,
    handleContents,
    handleTemplateMessage,
    handleSelectedMessageTrigger,
  ]);

  const Icon = template.icon;

  return (
    <S.Wrapper onClick={onTemplateClick}>
      <S.IconWrapper>
        <Icon size={48} />
      </S.IconWrapper>

      <S.TemplateName>{template.title}</S.TemplateName>
    </S.Wrapper>
  );
};

export default MessageTemplate;
