import React from 'react';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';

import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const Quota: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const { subscription } = useStoreSubscription();

  const isUnlimitedPlan = subscription.plan?.identifier?.includes('unlimited');
  const currentOrders = subscription.current_orders_quantity;
  const maxOrders = isUnlimitedPlan ? '∞' : subscription.allowed_orders_quantity;

  const getProgressPercentage = React.useCallback(() => {
    if (isUnlimitedPlan) return 100;

    if (currentOrders >= (maxOrders as number)) return 100;

    return (currentOrders * 100) / (maxOrders as number);
  }, [currentOrders, maxOrders, isUnlimitedPlan]);

  const formattedMaxOrders = isUnlimitedPlan ? '∞' : numberFormatter(maxOrders as number, 0);

  return (
    <S.Wrapper>
      <S.QuotaWrapper>
        <S.QuantityTextWrapper>
          <S.OrdersText>Pedidos</S.OrdersText>
          <S.QuantityText>
            <strong>{numberFormatter(currentOrders, 0)}</strong>/{formattedMaxOrders}
          </S.QuantityText>
        </S.QuantityTextWrapper>

        <S.ProgressWrapper>
          <S.CurrentProgressWrapper style={{ width: `${getProgressPercentage()}%` }} />
        </S.ProgressWrapper>
      </S.QuotaWrapper>

      <S.ManageSubscriptionLink to={`/${storeAliasId}/dashboard/subscription`}>
        Gerenciar assinatura
      </S.ManageSubscriptionLink>
    </S.Wrapper>
  );
};

export default Quota;
