import { ApiService } from '@services/api';
import { ISignUpProps } from '@domain/interfaces/auth/ISignUp';
import { AUTH_API_DOMAIN } from '@constants/api';

export class SignUpService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public signUp(data: ISignUpProps): Promise<any> {
    return this.apiService.post('/auth/sign-up', { ...data });
  }
}

export const signUpService = new SignUpService(AUTH_API_DOMAIN);

export default signUpService;
