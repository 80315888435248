import React from 'react';

import { MambaDigitalProvider } from '@helpers/hooks/pages/consent/useMambaDigital';

import Wrapper from '@components/Consent/MambaDigital/Wrapper';

import * as S from './styles';

const MambaDigital: React.FC = () => {
  return (
    <MambaDigitalProvider>
      <S.Wrapper>
        <Wrapper />
      </S.Wrapper>
    </MambaDigitalProvider>
  );
};

export default MambaDigital;
