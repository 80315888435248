import React from 'react';

import { ENodes } from '@domain/enums/dashboard/automations/node/ENodes';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';
import { MESSAGES_TEMPLATES } from '@constants/pages/dashboard/automations/messages/templates';

import DefaultMessage from './DefaultMessage';

import * as S from './styles';

const DefaultMessages: React.FC<any> = ({ toggleModal }) => {
  const { nodes } = useAutomation();

  const triggerNodes = [
    ENodes.ABANDONED_CART,
    ENodes.BOLETO,
    ENodes.PIX,
    ENodes.REJECTED_PAYMENT,
    ENodes.BOLETO_RECOVER,
    ENodes.PIX_RECOVER,
    ENodes.SIMPLE_SHIPMENT,
    ENodes.UPDATED_FULFILLMENT,
  ];

  const filteredTriggerNodes = nodes.filter(node => triggerNodes.includes(node.type));

  const triggerNode = filteredTriggerNodes[0];

  const filteredDefaultMessageTemplates = MESSAGES_TEMPLATES.filter(
    template => template.type === triggerNode.type,
  );

  return (
    <S.Wrapper>
      {filteredDefaultMessageTemplates.map(messageTemplate => {
        return <DefaultMessage message={messageTemplate} toggleModal={toggleModal} />;
      })}
    </S.Wrapper>
  );
};

export default DefaultMessages;
