import React from 'react';

import { IColumnOptionProps } from '@domain/interfaces/dashboard/FinancialDetails/IFinancialDetails';
import { useFinancialDetails } from '@helpers/hooks/pages/dashboard/financialDetails/useFinancialDetails';

import * as S from './styles';

const ColumnOption: React.FC<IColumnOptionProps> = ({ column }) => {
  const { columnsConfig, handleColumnsConfig } = useFinancialDetails();

  const onChange = React.useCallback(
    event => {
      if (event && !event.target) {
        const mappedColumnsConfig = columnsConfig.map(columnConfig => {
          if (columnConfig.value === column.value) {
            return {
              ...columnConfig,
              is_active: event.checked,
            };
          }

          return columnConfig;
        });

        handleColumnsConfig(mappedColumnsConfig);
      }
    },
    [column, columnsConfig, handleColumnsConfig],
  );

  const filteredActiveColumns = columnsConfig.filter(columnConfig => columnConfig.is_active);

  return (
    <S.InputGroup>
      <S.Checkbox
        checked={column.is_active}
        onChange={onChange}
        id={`column-${column.value}`}
        disabled={filteredActiveColumns.length <= 1}
      />
      <S.Label htmlFor={`column-${column.value}`}>{column.label}</S.Label>
    </S.InputGroup>
  );
};

export default ColumnOption;
