import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import CustomHoverCardBase from '@components/common/core/Utils/CustomHoverCard';

export const CustomHoverCardContent = styled(CustomHoverCardBase.Content)``;

export const CustomHoverCard = styled(CustomHoverCardBase)``;

export const AmountText = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[1]};
  filter: blur(5px);
  user-select: none;
`;

export const LabelText = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[2]};
  filter: blur(5px);
  user-select: none;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;
