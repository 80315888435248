import React from 'react';

import { OnboardingTagProvider } from '@helpers/hooks/pages/dashboard/productAnalytics/useOnboardingTag';
import { CampaignsProvider } from '@helpers/hooks/pages/dashboard/campaigns/useCampaigns';

import Wrapper from './Wrapper';

const ProductTagOnboarding: React.FC = () => {
  return (
    <CampaignsProvider>
      <OnboardingTagProvider>
        <Wrapper />
      </OnboardingTagProvider>
    </CampaignsProvider>
  );
};

export default ProductTagOnboarding;
