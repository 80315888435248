import React from 'react';

import Tab from './Tab';

import ActionsTab from './ActionsTab';
import LogicTab from './LogicTab';
import TimeTab from './TimeTab';

import * as S from './styles';

const TaskMenu: React.FC = () => {
  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const onChangeTab = React.useCallback(index => setSelectedTab(index), []);

  return (
    <S.Wrapper>
      <S.Title>Módulos</S.Title>

      <Tab defaultTab={selectedTab} onChangeTab={onChangeTab}>
        <div>Ações</div>
        <div>Lógico</div>
        <div>Tempo</div>
      </Tab>

      {selectedTab === 0 && <ActionsTab />}
      {selectedTab === 1 && <LogicTab />}
      {selectedTab === 2 && <TimeTab />}
    </S.Wrapper>
  );
};

export default TaskMenu;
