import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';

export const Table = styled(TableBase)`
  border-top-left-radius: unset;
  border-top-right-radius: unset;
`;

export const SwitchWrapper = styled.div`
  width: 44px;
`;

export const AccountIdWrapper = styled.div`
  width: 75px;
  display: flex;
  flex-direction: column;
`;

export const AccountNameWrapper = styled.div`
  width: 75px;
  display: flex;
  flex-direction: column;
`;

export const AccountProfileWrapper = styled.div`
  width: 100px;
  display: flex;
  flex-direction: column;
`;

export const ClicksWrapper = styled.div`
  width: 50px;
  display: flex;
  flex-direction: column;
`;

export const ImpressionsWrapper = styled.div`
  width: 50px;
  display: flex;
  flex-direction: column;
`;

export const ConversionsWrapper = styled.div`
  width: 50px;
  display: flex;
  flex-direction: column;
`;

export const AllConversionsWrapper = styled.div`
  width: 50px;
  display: flex;
  flex-direction: column;
`;

export const SpendAmountWrapper = styled.div`
  width: 80px;
  display: flex;
  flex-direction: column;
`;
