import styled, { css } from 'styled-components/macro';

import { ICalendarWrapperProps } from '@domain/interfaces/components/IDatePicker';

export const CalendarWrapper = styled.div<ICalendarWrapperProps>`
  width: fit-content;
  display: none;
  position: absolute;
  background-color: white;
  top: 100px;
  left: 24px;
  border-radius: 5px;
  padding: 16px 8px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.background[2]};
  box-shadow: ${({ theme }) => theme.shadows.calendar};
  border: 1px solid ${({ theme }) => theme.colors.background[3]};

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `};

  .react-datepicker__navigation {
    position: absolute;
  }

  .react-datepicker__navigation--next {
    right: 12px;
  }

  .react-datepicker__month-container {
    .react-datepicker__header {
      .react-datepicker__current-month {
        text-align: center;
        font-size: 1rem;
        font-family: ${({ theme }) => theme.fonts.body};
        color: ${({ theme }) => theme.colors.green.dark};
        font-weight: 500;
      }

      .react-datepicker__day-names {
        display: flex;
        margin-bottom: 10px;

        .react-datepicker__day-name {
          width: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: ${({ theme }) => theme.fonts.body};
          font-size: 1rem;
          color: ${({ theme }) => theme.colors.font};
          font-weight: 500;

          @media only screen and (max-width: 388px) {
            width: 36px;
          }
        }
      }
    }

    .react-datepicker__month {
      .react-datepicker__week {
        display: flex;

        .react-datepicker__day {
          width: 45px;
          height: 32px;
          /* border: 1px solid ${({ theme }) => theme.colors.gray[3]}; */
          border-right: none;
          font-family: ${({ theme }) => theme.fonts.body};
          font-size: 0.75rem;
          color: ${({ theme }) => theme.colors.font};
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.3s;

          @media only screen and (max-width: 388px) {
            width: 35px;
          }

          &:hover {
            background-color: ${({ theme }) => theme.colors.green.dark};
            color: ${({ theme }) => theme.colors.gray[1]};
            font-weight: 700;
          }

          &:last-child {
            /* border-right: 1px solid ${({ theme }) => theme.colors.gray[3]}; */
          }
        }

        .react-datepicker__day--outside-month {
          /* width: 46px; */
          border: none;
          z-index: -1;
          opacity: 0;

          @media only screen and (max-width: 388px) {
            width: 36px;
          }

          &:hover {
            background-color: ${({ theme }) => theme.colors.green.dark};
            color: ${({ theme }) => theme.colors.gray[1]};
            font-weight: 700;
          }
        }

        .react-datepicker__day--in-range {
          background-color: ${({ theme }) => `${theme.colors.green.dark}B2`};
          color: ${({ theme }) => theme.colors.gray[1]};
        }

        .react-datepicker__day--in-selecting-range {
          background-color: ${({ theme }) => `${theme.colors.green.dark}B2`};
          color: ${({ theme }) => theme.colors.gray[1]};
        }

        .react-datepicker__day--range-start {
          background-color: ${({ theme }) => theme.colors.green.dark};
          color: ${({ theme }) => theme.colors.gray[1]};
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        .react-datepicker__day--range-end {
          background-color: ${({ theme }) => theme.colors.green.dark};
          color: ${({ theme }) => theme.colors.gray[1]};
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        .react-datepicker__day--selected {
          background-color: ${({ theme }) => theme.colors.green.dark};
          color: ${({ theme }) => theme.colors.gray[1]};

          &.react-datepicker__day--selecting-range-start {
            border-radius: unset;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
        }

        .react-datepicker__day--disabled {
          cursor: not-allowed;
          filter: brightness(0.6);

          &:hover {
            background-color: unset;
          }
        }
      }

      .react-datepicker__week:first-child {
        .react-datepicker__day--outside-month {
          /* border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]}; */
          height: 32px;
        }
      }

      .react-datepicker__week:not(:first-child) {
        .react-datepicker__day {
          border-top: none;
        }
      }

      .react-datepicker__week:last-child {
        .react-datepicker__day--028
          ~ .react-datepicker__day--001.react-datepicker__day--outside-month {
          /* border-left: 1px solid ${({ theme }) => theme.colors.gray[3]}; */
        }

        .react-datepicker__day--029
          ~ .react-datepicker__day--001.react-datepicker__day--outside-month {
          /* border-left: 1px solid ${({ theme }) => theme.colors.gray[3]}; */
        }

        .react-datepicker__day--030
          ~ .react-datepicker__day--001.react-datepicker__day--outside-month {
          /* border-left: 1px solid ${({ theme }) => theme.colors.gray[3]}; */
        }

        .react-datepicker__day--031
          ~ .react-datepicker__day--001.react-datepicker__day--outside-month {
          /* border-left: 1px solid ${({ theme }) => theme.colors.gray[3]}; */
        }

        .react-datepicker__day--outside-month {
          /* height: 33px; */

          &:last-child {
            border: none;
          }
        }
      }
    }
  }
`;

export const DateButtonLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.font};
  margin-bottom: 4px;
`;

export const DateButton = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.colors.background[2]};
  height: 40px;
  width: 158px;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.font};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.inputBorderColor};

  @media only screen and (max-width: 388px) {
    padding: 8px 12px;
    width: 128px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-right: 24px;

    @media only screen and (max-width: 388px) {
      margin-right: 12px;
    }
  }
`;

export const DateInputsWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;

  @media only screen and (max-width: 388px) {
    padding: 12px;
  }
`;
