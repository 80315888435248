import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import { numberFormatter } from '@helpers/masks';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { EStatus } from '@domain/enums/subscription/ESubscription';
import { EPreDefinedDates } from '@domain/enums/components/EDatePicker';
import { IParams } from '@domain/interfaces/IParams';

import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { useOrders } from '@helpers/hooks/pages/dashboard/orders/useOrders';
import { useConfig } from '@helpers/hooks/useConfig';

import { ORDERS_FILTER_CONFIG } from '@helpers/utils/filters/ordersFilter';
import { ORDERS_SORTER } from '@helpers/utils/sorters/ordersSorter';

import Sort from '@components/common/core/Utils/Sort';
import FilterButton from '@components/common/core/Utils/Filter/FilterButton';

import * as S from './styles';

const Header: React.FC = () => {
  const theme: any = useTheme();
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { analytics, user } = useConfig();

  const {
    period,
    setPeriod,
    metric,
    filterData,
    handleFilterData,
    handleSortData,
    toggleCreateOrderModal,
  } = useOrders();
  const { subscription, hasAllPermissions } = useStoreSubscription();

  const handleNewData = React.useCallback(
    newData => {
      handleFilterData([newData]);
    },
    [handleFilterData],
  );

  const handleCreateOrder = React.useCallback(() => {
    analytics?.track('Register Order Page Viewed', { email: user?.email });

    history.push(`/${storeAliasId}/dashboard/reports/orders/create`);
  }, [history, storeAliasId, analytics, user]);

  const getDefaultPreDefinedDate = React.useCallback(() => {
    const isFreePlan = subscription && subscription.plan?.identifier === 'free_monthly_v1';

    if (hasAllPermissions) {
      return EPreDefinedDates.TODAY;
    }

    if (isFreePlan && subscription.exceeded_order_limit_at) {
      return EPreDefinedDates.CUSTOM;
    }

    if (subscription?.status === EStatus.PAID) {
      return EPreDefinedDates.TODAY;
    }

    return EPreDefinedDates.CUSTOM;
  }, [subscription, hasAllPermissions]);

  const formattedTotalNetRevenueAmount = `R$ ${numberFormatter(
    metric?.total_net_revenue_amount || 0,
    2,
  )}`;

  return (
    <S.HeaderWrapper>
      <S.HeadingText fontWeight={EHeadingWeight.MEDIUM} type={EHeadingSize.H4}>
        Pedidos
      </S.HeadingText>

      <S.FiltersSortWrapper>
        <S.AddOrderButton onClick={handleCreateOrder}>Cadastrar pedido</S.AddOrderButton>

        {Boolean(!filterData.length) && (
          <FilterButton
            data={filterData}
            config={ORDERS_FILTER_CONFIG}
            addNewData={handleNewData}
          />
        )}

        <Sort handleSortData={handleSortData} config={ORDERS_SORTER} />
      </S.FiltersSortWrapper>

      <S.DetailsHeaderWrapper>
        <S.DetailsTextWrapper>
          <S.DetailsTextColumn>
            <S.TaxText>{formattedTotalNetRevenueAmount}</S.TaxText>
            <S.TaxSubtitle>Receita Líquida</S.TaxSubtitle>
          </S.DetailsTextColumn>

          <S.DetailsTextColumn>
            <S.TaxText>{metric?.total_orders_quantity || 0}</S.TaxText>
            <S.TaxSubtitle>Total de Pedidos</S.TaxSubtitle>
          </S.DetailsTextColumn>
        </S.DetailsTextWrapper>

        <S.DatePicker
          period={period}
          setPeriod={setPeriod}
          defaultPreDefinedDate={getDefaultPreDefinedDate()}
          color={theme.colors.background[6]}
        />
      </S.DetailsHeaderWrapper>
    </S.HeaderWrapper>
  );
};

export default Header;
