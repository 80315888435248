import React from 'react';

import { Sync } from '@profitfy/pakkun-icons';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { EHeadAlign } from '@domain/enums/components/ETable';
import { useVariantHistoric } from '@helpers/hooks/pages/dashboard/productCostDetails/variantHistoric';
import Paginate from '@components/common/core/Utils/Paginate';
import Row from './Row';
import NewHistoric from './NewHistoric';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const FirstStep: React.FC = () => {
  const {
    isLoadingVariantCosts,
    isLoadingDefaultVariantCost,
    defaultVariantCostError,
    variantCostsError,
    variantCosts,
    selectedVariant,
    isDeletingVariantCost,
    page,
    pageCount,
    handlePageChange,
  } = useVariantHistoric();

  if (isLoadingDefaultVariantCost || isLoadingVariantCosts || isDeletingVariantCost) {
    return <SkeletonLoading />;
  }

  if (defaultVariantCostError || variantCostsError) {
    return <div>Error...</div>;
  }

  return (
    <>
      <S.Header>
        <Sync size={52} />
        <S.Title>Histórico de Variante</S.Title>
      </S.Header>

      <S.Body>
        <S.ProductName type={EHeadingSize.H5} fontWeight={EHeadingWeight.SEMIBOLD}>
          {selectedVariant?.name}
        </S.ProductName>

        <NewHistoric />

        <S.TableWrapper>
          <S.Table>
            <S.TableHeader>
              <S.TableRow>
                <S.TableHead>Data Inicial</S.TableHead>
                <S.TableHead>Data Final</S.TableHead>
                <S.TableHead align={EHeadAlign.RIGHT}>Custo</S.TableHead>
                <S.TableHead align={EHeadAlign.CENTER}>Ações</S.TableHead>
              </S.TableRow>
            </S.TableHeader>

            <S.TableBody>
              {variantCosts?.map(variantCost => (
                <Row variantCost={variantCost} key={variantCost.alias_id} />
              ))}
            </S.TableBody>

            <S.TableFooter>
              <S.TableRow>
                <S.TableData colSpan={4} align={EHeadAlign.RIGHT}>
                  <Paginate
                    forcePage={page}
                    pageCount={pageCount}
                    onPageChange={handlePageChange}
                  />
                </S.TableData>
              </S.TableRow>
            </S.TableFooter>
          </S.Table>
        </S.TableWrapper>
      </S.Body>
    </>
  );
};

export default FirstStep;
