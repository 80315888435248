import React from 'react';
import { useParams } from 'react-router-dom';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { ILpqvCredentialOptionProps } from '@domain/interfaces/dashboard/IntegrationCenter/SideModalContent/StoreProvider/ILPQVCredentialOption';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';

import lpqvAuthService from '@services/pages/dashboard/integrationCenter/storeProvider/lpqv/lpqvAuth';

import Save from '@components/Dashboard/IntegrationCenter/SideModalContent/Save';

import DeleteCredentialModal from '@components/Dashboard/IntegrationCenter/SideModalContent/DeleteCredentialModal';
import * as S from './styles';

const CredentialOption: React.FC<ILpqvCredentialOptionProps> = ({ lpqvCredential, mutate }) => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();

  const [isDeletingCredential, setIsDeletingCredential] = React.useState<boolean>(false);
  const [isDeleteCredentialModalOpen, setIsDeleteCredentialModalOpen] = React.useState<boolean>(
    false,
  );

  const handleDeleteCredentialModalOpen = React.useCallback(
    () => setIsDeleteCredentialModalOpen(state => !state),
    [],
  );

  const onDeleteCredential = React.useCallback(async () => {
    setIsDeletingCredential(true);

    try {
      await lpqvAuthService.updateLpqvCredential({
        storeAliasId,
        lpqvCredentialAliasId: lpqvCredential.alias_id,
        data: {
          provider_id: lpqvCredential.provider_id,
          // auth_token: lpqvCredential.auth_token,
          is_active: false,
        },
      });

      toast.success('Credencial desativada com sucesso!');

      setIsDeletingCredential(false);
      handleDeleteCredentialModalOpen();

      mutate();
    } catch (error: any) {
      setIsDeletingCredential(false);
      toast.error(error?.response?.data?.message);
    }
  }, [mutate, storeAliasId, toast, lpqvCredential, handleDeleteCredentialModalOpen]);

  return (
    <S.Content>
      <S.CredentialDescriptionWrapper>
        <S.CredentialDescriptionTitle type={EHeadingSize.H5}>
          O que acontece se eu desativar minha integração?
        </S.CredentialDescriptionTitle>
        <S.CredentialDescriptionText>
          Ao desativar a integração,
          <strong> não será mais possível </strong>
          processar os pedidos da Shopify.
        </S.CredentialDescriptionText>
      </S.CredentialDescriptionWrapper>

      <Save
        hasCredentials
        deleteText="Desativar credencial"
        onDelete={handleDeleteCredentialModalOpen}
        isDeleteButtonDisabled={isDeletingCredential}
        isDeleting={isDeletingCredential}
      />

      <DeleteCredentialModal
        isOpen={isDeleteCredentialModalOpen}
        toggle={handleDeleteCredentialModalOpen}
        onDeleteCredential={onDeleteCredential}
        isLoading={isDeletingCredential}
      />
    </S.Content>
  );
};

export default CredentialOption;
