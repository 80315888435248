import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';

export const Wrapper = styled.div`
  padding: 42px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  width: 100%;
  max-width: 459px;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: sticky;
  top: 0;
  box-sizing: border-box;

  @media only screen and (max-width: 1768px) {
    max-width: 320px;
    padding-right: 30px;
  }

  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Logo = styled(ImageBase)`
  max-width: 160px;
  width: 100%;
  margin-bottom: 12px;
  display: block;
`;
