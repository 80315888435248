import React from 'react';
import * as HoverCardPrimitive from '@radix-ui/react-hover-card';

import * as S from './styles';

const Arrow: React.FC<
  HoverCardPrimitive.HoverCardArrowProps & React.RefAttributes<SVGSVGElement>
> = ({ children, ...rest }) => {
  return <S.HoverCardArrow {...rest}>{children}</S.HoverCardArrow>;
};

export default Arrow;
