import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  max-width: 420px;
  height: 60px;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;

  span {
    display: block;
  }

  @media only screen and (max-width: 1388px) {
    max-width: 380px;
  }

  @media only screen and (max-width: 1360px) {
    max-width: unset;
  }
`;
