import styled, { css } from 'styled-components/macro';

import { IOptionProps } from './index';

export const Wrapper = styled.button<IOptionProps>`
  padding: 3px 18px;
  height: 28px;
  border: none;
  border-radius: 5px;
  background-color: transparent;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[3]};
  letter-spacing: 0.7px;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-size: 0.875rem;
  cursor: pointer;

  ${({ isSelected }) =>
    !isSelected &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[5]};
        color: ${({ theme }) => theme.colors.gray[1]};
      }
    `};

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background-color: ${theme.colors.gray[5]};
      color: ${theme.colors.gray[1]};
    `}
`;
