import React from 'react';

import { Layout } from 'phosphor-react';
import { useTheme } from 'styled-components';

import { ETextWeight } from '@domain/enums/components/EText';

import { DEFAULT_MESSAGE_TEMPLATES } from '@constants/pages/dashboard/automations/messages/messageTemplates';

import MessageTemplate from './MessageTemplate';

import * as S from './styles';

const SelectTemplateContent: React.FC = () => {
  const theme = useTheme();

  return (
    <>
      <S.Header>
        <S.HeadingWrapper>
          <Layout size={28} color={theme.colors.gray[1]} />
          <S.Heading>Escolha um template</S.Heading>
        </S.HeadingWrapper>

        <S.Description weight={ETextWeight.MEDIUM}>
          Crie a sua mensagem do zero ou selecione um de nossos templates prontos
        </S.Description>
      </S.Header>

      <S.TemplatesWrapper>
        {DEFAULT_MESSAGE_TEMPLATES.map(template => {
          return <MessageTemplate key={template.id} template={template} />;
        })}
      </S.TemplatesWrapper>
    </>
  );
};

export default SelectTemplateContent;
