import { DEFAULT_ABANDONED_CART_CONTENT } from '@components/Dashboard/Automations/Canvas/Nodes/ConditionalNode/ConditionalModal/AbandonCartModal/Content';
import { DEFAULT_APPROVED_ORDER_CONTENT } from '@components/Dashboard/Automations/Canvas/Nodes/ConditionalNode/ConditionalModal/ApprovedOrderModal/Content';
import { DEFAULT_BOLETO_CONTENT } from '@components/Dashboard/Automations/Canvas/Nodes/ConditionalNode/ConditionalModal/BoletoModal/Content';
import { DEFAULT_CANCELED_ORDER_CONTENT } from '@components/Dashboard/Automations/Canvas/Nodes/ConditionalNode/ConditionalModal/CanceledOrderModal/Content';
import { DEFAULT_COUPON_CONTENT } from '@components/Dashboard/Automations/Canvas/Nodes/ConditionalNode/ConditionalModal/CouponModal/Content';
import { DEFAULT_REJECTED_PAYMENT_CONTENT } from '@components/Dashboard/Automations/Canvas/Nodes/ConditionalNode/ConditionalModal/DeniedCardModal/Content';
import { DEFAULT_PIX_CONTENT } from '@components/Dashboard/Automations/Canvas/Nodes/ConditionalNode/ConditionalModal/PixModal/Content';
import { DEFAULT_SIMPLE_SHIPMENT_CONTENT } from '@components/Dashboard/Automations/Canvas/Nodes/ConditionalNode/ConditionalModal/SimpleShipmentModal/Content';
import { DEFAULT_UPDATE_FULFILLMENT_CONTENT } from '@components/Dashboard/Automations/Canvas/Nodes/ConditionalNode/ConditionalModal/UpdateFulfillmentModal/Content';
import { ETriggers } from '@domain/enums/dashboard/automations/ETriggers';
import { IConditionalNodeContent } from '@domain/interfaces/dashboard/Automations/Canvas/Node/ConditionalNode/IConditionalNodeContent';

export const selectDefaultConditionalNodeContent = (
  triggerType: ETriggers,
): IConditionalNodeContent => {
  const triggers = {
    ABANDONED_CART: DEFAULT_ABANDONED_CART_CONTENT,
    PIX: DEFAULT_PIX_CONTENT,
    BOLETO: DEFAULT_BOLETO_CONTENT,
    REJECTED_PAYMENT: DEFAULT_REJECTED_PAYMENT_CONTENT,
    UPDATED_FULFILLMENT: DEFAULT_UPDATE_FULFILLMENT_CONTENT,
    SIMPLE_SHIPMENT: DEFAULT_SIMPLE_SHIPMENT_CONTENT,
    APPROVED_ORDER: DEFAULT_APPROVED_ORDER_CONTENT,
    CANCELED_ORDER: DEFAULT_CANCELED_ORDER_CONTENT,
    COUPON: DEFAULT_COUPON_CONTENT,
  };

  const defaultContent = {
    id: '',
    input: '',
    options: 'contains',
    type: 'product',
  };

  return triggers[triggerType] || defaultContent;
};
