import styled from 'styled-components/macro';
import CheckBoxBase from '@components/common/core/Inputs/Checkbox';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.div`
  width: 100%;
  height: 56px;
  min-height: 56px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.background[2]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.background[3]};
  box-sizing: border-box;
`;

export const Group = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckBox = styled(CheckBoxBase)``;

export const WhatAppIconContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #25d366;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
`;

export const Name = styled(TextBase)`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white.default};
  margin-left: 10px;
`;

export const Phone = styled(TextBase)`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[2]};
`;
