import styled, { keyframes, css } from 'styled-components/macro';

import { ISyncDataButtonProps } from '@domain/interfaces/pages/onboarding/adsManager/ISyncingData';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import InputBase from '@components/common/core/Inputs/TextField';
import TextBase from '@components/common/core/DataDisplay/Text';
import DatePickerRangeBase from '@components/common/core/Utils/DatePickerRange';
import CustomDropDownBase from '@components/common/core/Utils/CustomDropDown';
import FilterButtonBase from '@components/common/core/Utils/Filter/FilterButton';

const syncingAnimation = keyframes`
  0% {
    transform: rotateZ(0deg) rotateY(180deg);
  }
  100% {
    transform: rotateZ(364deg) rotateY(180deg);
  }
`;

export const FilterButton = styled(FilterButtonBase)`
  height: 32px;
  background: ${({ theme }) => theme.colors.gray[6]};
  padding: 8px 7px;
  border-radius: 5px;
  font-size: 0.875rem;
  border: unset;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const DropDownTriggerContent = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.font};
  font-family: ${({ theme }) => theme.fonts.body};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DropDownTrigger = styled(CustomDropDownBase.Trigger)`
  border: unset;
  padding: unset;
  background: ${({ theme }) => theme.colors.gray[6]};
  height: 32px;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  border-radius: 5px;
  transition: all 0.3s;
  padding: 8px 7px;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const DropDown = styled(CustomDropDownBase)``;

export const DuplicateButton = styled.button`
  border: unset;
  padding: unset;
  background: ${({ theme }) => theme.colors.gray[6]};
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: all 0.3s;
  gap: 4px;
  padding: 8px 7px;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.font};
  font-family: ${({ theme }) => theme.fonts.body};
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const DatePicker = styled(DatePickerRangeBase)``;

export const UpdateDataButton = styled.button<ISyncDataButtonProps>`
  border: unset;
  padding: unset;
  background: ${({ theme }) => theme.colors.gray[6]};
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  &:disabled {
    cursor: not-allowed;
  }

  & > svg {
    transform: rotateY(180deg);
    animation: unset;

    ${({ isSyncing }) =>
      isSyncing &&
      css`
        animation: ${syncingAnimation} 1s infinite linear;
      `}
  }
`;

export const LastTimeUpdatedText = styled(TextBase)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const LastTimeUpdatedWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const LastTimeUpdatedAndDatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media only screen and (max-width: 848px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SearchInput = styled(InputBase)`
  & > div {
    height: 32px;
    background-color: ${({ theme }) => theme.colors.gray[6]};

    & > input {
      padding-top: 4px;
      padding-bottom: 4px;
      font-size: 0.875rem;
    }
  }
`;

export const SearchInputGroup = styled(InputGroupBase)`
  max-width: 602px;
  margin: unset;

  @media only screen and (max-width: 1424px) {
    max-width: 428px;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  @media only screen and (max-width: 1256px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
  }
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 28px;
`;

export const Wrapper = styled.div`
  margin-bottom: 8px;
`;
