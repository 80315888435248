import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import TextBase from '@components/common/core/DataDisplay/Text';
import ImageBase from '@components/common/core/DataDisplay/Image';
import NoImageBase from '@components/common/core/DataDisplay/NoImage';
import PopoverBase from '@components/common/core/Utils/Popover';
import TooltipBase from '@components/common/core/Utils/Tooltip';
import BadgeBase from '@components/common/core/DataDisplay/Badge';

export const OrderNumber = styled.a`
  color: ${({ theme }) => theme.colors.green.dark};
  display: block;
  cursor: pointer;
  text-decoration: unset;

  &:hover {
    color: ${({ theme }) => theme.colors.green.default};
    text-decoration: underline;
  }
`;

export const ProductImage = styled(ImageBase)`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: block;
`;

export const BoletoTable = styled(TableBase)``;

export const OrderDetails = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const IconWrapper = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  background-color: ${({ theme }) => theme.colors.green.dark};
`;

export const OrderDetailsTextWrapper = styled.div``;

export const OrderDetailsNumber = styled(TextBase)`
  color: ${({ theme }) => theme.colors.green.dark};
  display: block;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.green.default};
  }
`;

export const OrderDetailsDate = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
  font-size: 14px;
  margin-top: 2px;
`;

export const ImageIconWrapper = styled.div`
  height: 38px;
  width: 38px;
  border-radius: 19px;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageIcon = styled(ImageBase)`
  width: 20px;
`;

export const ApprovedAtWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ApprovedAtDaysText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
  font-size: 0.875rem;
`;

export const ApprovedAtDate = styled(TextBase)`
  font-size: 0.875rem;
`;

export const ProductWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const ProductDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Image = styled(ImageBase)`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

export const NoImage = styled(NoImageBase)`
  background-color: ${({ theme }) => theme.colors.background[3]};
`;

export const ProductDetailsText = styled(TextBase)`
  max-width: 164px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
`;

export const SeeMoreProducts = styled(TextBase)`
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.background[6]};
  cursor: pointer;
`;

export const Popover = styled(PopoverBase)`
  background-color: ${({ theme }) => theme.colors.background[1]} !important;
  width: fit-content !important;
  padding: 12px !important;

  &.place-right::after {
    border-right-color: ${({ theme }) => theme.colors.background[1]} !important;
  }

  &.place-left::after {
    border-left-color: ${({ theme }) => theme.colors.background[1]} !important;
  }

  &.place-top::after {
    border-top-color: ${({ theme }) => theme.colors.background[1]} !important;
  }

  &.place-bottom::after {
    border-bottom-color: ${({ theme }) => theme.colors.background[1]} !important;
  }
`;

export const TooltipProductText = styled(TextBase)`
  max-width: 300px;
  width: 100%;
  overflow: hidden;
  display: block;
`;

export const RevenueValue = styled(TextBase)`
  font-weight: 700;
  font-size: 0.875rem;
  display: flex;
  justify-content: flex-end;
`;

export const ProductCostText = styled(TextBase)`
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const Tooltip = styled(TooltipBase)``;

export const SynchronizationIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StatusBadge = styled(BadgeBase)`
  margin: 0 auto;
`;
