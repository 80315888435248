import React from 'react';

import { useTheme } from 'styled-components';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { IOrderTableDataProps } from '@domain/interfaces/dashboard/Orders/IOrderTableData';
import { IIntegration } from '@domain/interfaces/stores/IStores';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import {
  getOrderGatewayIcon,
  getOrderSourceIcon,
  parseOrderSourceName,
  parseOrderGatewayName,
} from '@helpers/dashboard/orders/ordersUtils';

import Table from '@components/common/core/Utils/Table';

import * as S from './styles';

const OrderSource: React.FC<IOrderTableDataProps> = ({ order }) => {
  const theme = useTheme();
  const { storeIntegrations } = useStoreConfig();

  const getOrderGateway = React.useCallback(() => {
    const orderSource = order.order_transactions[0].gateway_name;

    if (orderSource !== 'OTHER') return getOrderGatewayIcon(orderSource);

    const otherGateway = storeIntegrations.gateways.find(
      gateway => gateway.other_gateway?.has_other_gateway,
    ) as IIntegration;

    const otherGatewayName = otherGateway?.other_gateway?.gateway_names[0] || '';

    return getOrderGatewayIcon(otherGatewayName);
  }, [order, storeIntegrations]);

  const getOrderGatewayName = React.useCallback(() => {
    const orderSource = order.order_transactions[0].gateway_name;

    if (orderSource !== 'OTHER') return parseOrderGatewayName(orderSource);

    const otherGateway = storeIntegrations.gateways.find(
      gateway => gateway.other_gateway?.has_other_gateway,
    ) as IIntegration;

    const otherGatewayName = otherGateway?.other_gateway?.gateway_names[0] || '';

    return parseOrderGatewayName(otherGatewayName);
  }, [order, storeIntegrations]);

  const {
    icon: gatewayIcon,
    backgroundColor: gatewayIconBackgroundColor,
    hasMarginLeft,
  } = getOrderGateway();

  const {
    icon: orderSourceIcon,
    backgroundColor: orderSourceIconBackgroundColor,
  } = getOrderSourceIcon(order.utm_source, theme);

  const orderTransactionId = order.order_transactions[0].payment_token;
  const orderGatewayName = getOrderGatewayName();

  return (
    <Table.Data align={EHeadAlign.CENTER}>
      <S.GatewayAndMarketingDataContent>
        {order.utm_source ? (
          <S.Tooltip>
            <S.Tooltip.Trigger>
              <S.OrderSourceIconWrapper color={orderSourceIconBackgroundColor}>
                {orderSourceIcon}
              </S.OrderSourceIconWrapper>
            </S.Tooltip.Trigger>

            <S.Tooltip.Content side="top" sideOffset={5}>
              <S.OrderSourceTooltipContentText>
                {parseOrderSourceName(order.utm_source)}
              </S.OrderSourceTooltipContentText>
              <S.Tooltip.Arrow />
            </S.Tooltip.Content>
          </S.Tooltip>
        ) : (
          <div />
        )}

        {orderGatewayName ? (
          <S.HoverCard>
            <S.HoverCard.Trigger>
              <S.GatewayIconWrapper
                color={gatewayIconBackgroundColor}
                hasMarginLeft={hasMarginLeft}
              >
                {gatewayIcon}
              </S.GatewayIconWrapper>
            </S.HoverCard.Trigger>

            <S.HoverCard.Content side="top" sideOffset={5}>
              <S.GatewayTooltipContentWrapper>
                <S.GatewayTooltipContentText>
                  Gateway: <span>{getOrderGatewayName()}</span>
                </S.GatewayTooltipContentText>

                <S.GatewayTooltipContentText>
                  ID da transação: <span>{orderTransactionId}</span>
                </S.GatewayTooltipContentText>
              </S.GatewayTooltipContentWrapper>

              <S.HoverCard.Arrow />
            </S.HoverCard.Content>
          </S.HoverCard>
        ) : (
          <div />
        )}
      </S.GatewayAndMarketingDataContent>
    </Table.Data>
  );
};

export default OrderSource;
