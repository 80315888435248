import styled from 'styled-components/macro';

import CustomPopover from '@components/common/core/Utils/CustomPopover';

export const ActionButton = styled.button`
  background-color: unset;
  border: unset;
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.font};
  text-align: left;
  line-height: 120%;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.green.default};
  }

  &:active {
    color: ${({ theme }) => theme.colors.green.dark};
  }
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const PopoverArrow = styled(CustomPopover.Arrow)`
  fill: ${({ theme }) => theme.colors.black.light};
`;

export const PopoverContent = styled(CustomPopover.Content)`
  background-color: ${({ theme }) => theme.colors.black.light};
  border-radius: 10px;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 258px;
`;

export const ActionsSelectButton = styled.div`
  background-color: unset;
  border: 1px solid ${({ theme }) => theme.colors.gray[2]};
  border-radius: 5px;
  height: 32px;
  padding: 7px 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.font};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Popover = styled(CustomPopover)``;
