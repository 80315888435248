import styled, { css } from 'styled-components/macro';

import { IRecurrenceTypeOptionProps } from '@domain/interfaces/pricing/plans/IRecurrenceType';

import TextBase from '@components/common/core/DataDisplay/Text';
import BadgeBase from '@components/common/core/DataDisplay/Badge';

export const DiscountBadge = styled(BadgeBase)``;

export const OptionText = styled(TextBase)`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  color: ${({ theme }) => theme.colors.gray[2]};
  transition: all 0.3s;
`;

export const Option = styled.button<IRecurrenceTypeOptionProps>`
  height: 36px;
  background-color: transparent;
  border: unset;
  padding: 4px 16px;
  min-width: 132px;
  box-sizing: border-box;
  transition: all 0.3s;
  border-radius: 7px;
  cursor: pointer;

  @media only screen and (max-width: 739px) {
    height: 28px;
    padding: 2px 8px;
  }

  @media only screen and (max-width: 492px) {
    height: 32px;
    padding: 4px 8px;
    width: 100%;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[5]};

    & > span {
      color: ${({ theme }) => theme.colors.white.light};
    }
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background-color: ${theme.colors.gray[4]};

      & > span {
        color: ${theme.colors.white.light};
      }

      &:hover {
        background-color: ${theme.colors.gray[4]};
      }
    `};
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[6]};
  padding: 4px 8px;
  box-sizing: border-box;
  border-radius: 7px;
  margin: 0 auto;
  width: fit-content;
  margin-bottom: 48px;
  display: flex;
  align-items: center;
  gap: 4px;

  @media only screen and (max-width: 739px) {
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 492px) {
    flex-direction: column;
    width: 100%;
  }
`;
