import React from 'react';

import { Warning } from '@profitfy/pakkun-icons';
import { ETextWeight } from '@domain/enums/components/EText';
import { useAccessManager } from '@helpers/hooks/pages/dashboard/accessManagement/useAccessManager';

import * as S from './styles';

const DeleteMemberModal: React.FC = () => {
  const {
    isDeleteMemberModalOpen,
    handleDeleteMemberModalOpen,
    handleDeleteMembers,
    isDeletingMembers,
  } = useAccessManager();

  return (
    <S.Modal isOpen={isDeleteMemberModalOpen} toggle={handleDeleteMemberModalOpen}>
      <S.Header>
        <Warning size={24} outline />
        <S.Heading weight={ETextWeight.MEDIUM}>Certeza que deseja remover o usuário?</S.Heading>
      </S.Header>

      <S.Description>
        Ele perderá todos acessos de visualização e configurações na sua plataforma.
      </S.Description>

      <S.ButtonsWrapper>
        <S.CancelButton onClick={handleDeleteMemberModalOpen}>Voltar</S.CancelButton>
        <S.DeleteMemberButton
          onClick={handleDeleteMembers}
          isLoading={isDeletingMembers}
          disabled={isDeletingMembers}
        >
          Remover usuário
        </S.DeleteMemberButton>
      </S.ButtonsWrapper>
    </S.Modal>
  );
};

export default DeleteMemberModal;
