import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const Button = styled.button`
  padding: unset;
  border: unset;
  background: unset;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.green.default};
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Title = styled(HeadingBase)``;

export const Wrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
