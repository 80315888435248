import styled, { css } from 'styled-components/macro';

import SidemodalBase from '@components/common/core/DataDisplay/SideModal';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';
import TextBase from '@components/common/core/DataDisplay/Text';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import { IBulletProps } from '@domain/interfaces/dashboard/CustomValuesCategories/IBullet';

export const InputValueText = styled(TextBase)``;

export const CompletedInput = styled.button`
  height: 42px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.gray[2]};
  padding: 12px 18px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: unset;
  cursor: pointer;
  width: 100%;
`;

export const Bullet = styled.span<IBulletProps>`
  min-width: 12px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  background-color: green;
  position: relative;
  margin-right: 12px;
  cursor: pointer;

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}
`;

export const CancelButton = styled.button`
  cursor: pointer;
  background-color: unset;
  border: none;
  color: ${({ theme }) => theme.colors.green.default};
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const ConfirmButton = styled.button`
  cursor: pointer;
  background-color: unset;
  border: none;
  color: ${({ theme }) => theme.colors.green.default};
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Input = styled.input`
  width: 100%;
  border: none;
  background-color: unset;
  color: ${({ theme }) => theme.colors.font};

  &:focus {
    outline: none;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background[2]};
  display: flex;
  padding: 12px 18px;
  box-sizing: border-box;
  border-radius: 5px;
  height: 42px;
  align-items: center;
`;

export const Option = styled(OptionBase)``;

export const Select = styled(SelectBase)`
  width: 100%;
  margin-bottom: 24px;

  & > label {
    & > select {
      width: 100%;
    }
  }
`;

export const AddCategoryLabel = styled(TextBase)`
  margin-bottom: 16px;
  display: inline-block;
`;

export const AddCategoryButton = styled(ButtonBase)`
  width: 100%;
  margin-bottom: 24px;
`;

export const Body = styled.div``;

export const Title = styled(HeadingBase)``;

export const Header = styled.div`
  display: flex;
  gap: 16px;
  padding-bottom: 16px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
  margin-bottom: 32px;
`;

export const Sidemodal = styled(SidemodalBase)`
  max-width: 588px;
  width: 100%;
`;
