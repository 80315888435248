import React from 'react';

import { IProduct } from '@domain/interfaces/common/product/IProduct';
import { useCreateOrder } from '@helpers/hooks/pages/dashboard/orders/useCreateOrder';

import * as S from './styles';

interface IProductProps {
  product: IProduct;
}

const Product: React.FC<IProductProps> = ({ product }) => {
  const { handleSelectProduct } = useCreateOrder();

  const [isVariantImageError, setIsVariantImageError] = React.useState<boolean>(false);

  return (
    <S.Wrapper onClick={() => handleSelectProduct(product)}>
      {isVariantImageError || !product.image_url ? (
        <S.NoImage />
      ) : (
        <S.Image
          src={product.image_url}
          alt="Profitfy.me Produto"
          onError={() => setIsVariantImageError(true)}
        />
      )}

      <S.VariantDetails>
        <S.VariantName>{product.name}</S.VariantName>
      </S.VariantDetails>
    </S.Wrapper>
  );
};

export default Product;
