import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

export const InfiniteScrollLoading: React.FC = () => {
  return (
    <>
      <S.Wrapper>
        <S.ImageAndOrder>
          <S.ImageWrapper>
            <Skeleton width="100%" height={48} />
          </S.ImageWrapper>

          <S.OrderNumberAndStatus>
            <S.OrderNumberWrapper>
              <Skeleton width="100%" height={27} />
            </S.OrderNumberWrapper>
            <S.OrderStatusWrapper>
              <Skeleton width="100%" height={20} />
            </S.OrderStatusWrapper>
          </S.OrderNumberAndStatus>
        </S.ImageAndOrder>

        <S.OrderDetails>
          <S.FirstOrderItemWrapper>
            <Skeleton width="100%" height={20} />
          </S.FirstOrderItemWrapper>
          <S.OrderDateWrapper>
            <Skeleton width="100%" height={20} />
          </S.OrderDateWrapper>
        </S.OrderDetails>

        <S.OrderRevenueWrapper>
          <S.OrderRevenueValueWrapper>
            <Skeleton width="100%" height={20} />
          </S.OrderRevenueValueWrapper>
          <S.OrderRevenueDescription>
            <Skeleton width="100%" height={20} />
          </S.OrderRevenueDescription>
        </S.OrderRevenueWrapper>

        <S.SolveButtonWrapper>
          <Skeleton width="100%" height={32} />
        </S.SolveButtonWrapper>
      </S.Wrapper>

      <S.Wrapper>
        <S.ImageAndOrder>
          <S.ImageWrapper>
            <Skeleton width="100%" height={48} />
          </S.ImageWrapper>

          <S.OrderNumberAndStatus>
            <S.OrderNumberWrapper>
              <Skeleton width="100%" height={27} />
            </S.OrderNumberWrapper>
            <S.OrderStatusWrapper>
              <Skeleton width="100%" height={20} />
            </S.OrderStatusWrapper>
          </S.OrderNumberAndStatus>
        </S.ImageAndOrder>

        <S.OrderDetails>
          <S.FirstOrderItemWrapper>
            <Skeleton width="100%" height={20} />
          </S.FirstOrderItemWrapper>
          <S.OrderDateWrapper>
            <Skeleton width="100%" height={20} />
          </S.OrderDateWrapper>
        </S.OrderDetails>

        <S.OrderRevenueWrapper>
          <S.OrderRevenueValueWrapper>
            <Skeleton width="100%" height={20} />
          </S.OrderRevenueValueWrapper>
          <S.OrderRevenueDescription>
            <Skeleton width="100%" height={20} />
          </S.OrderRevenueDescription>
        </S.OrderRevenueWrapper>

        <S.SolveButtonWrapper>
          <Skeleton width="100%" height={32} />
        </S.SolveButtonWrapper>
      </S.Wrapper>

      <S.Wrapper>
        <S.ImageAndOrder>
          <S.ImageWrapper>
            <Skeleton width="100%" height={48} />
          </S.ImageWrapper>

          <S.OrderNumberAndStatus>
            <S.OrderNumberWrapper>
              <Skeleton width="100%" height={27} />
            </S.OrderNumberWrapper>
            <S.OrderStatusWrapper>
              <Skeleton width="100%" height={20} />
            </S.OrderStatusWrapper>
          </S.OrderNumberAndStatus>
        </S.ImageAndOrder>

        <S.OrderDetails>
          <S.FirstOrderItemWrapper>
            <Skeleton width="100%" height={20} />
          </S.FirstOrderItemWrapper>
          <S.OrderDateWrapper>
            <Skeleton width="100%" height={20} />
          </S.OrderDateWrapper>
        </S.OrderDetails>

        <S.OrderRevenueWrapper>
          <S.OrderRevenueValueWrapper>
            <Skeleton width="100%" height={20} />
          </S.OrderRevenueValueWrapper>
          <S.OrderRevenueDescription>
            <Skeleton width="100%" height={20} />
          </S.OrderRevenueDescription>
        </S.OrderRevenueWrapper>

        <S.SolveButtonWrapper>
          <Skeleton width="100%" height={32} />
        </S.SolveButtonWrapper>
      </S.Wrapper>
    </>
  );
};

export default InfiniteScrollLoading;
