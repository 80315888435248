import { ApiService } from '@services/api';
import { SUGGESTION_API_DOMAIN } from '@constants/api';
import { ITrackRetrospectiveProps } from '@domain/interfaces/hooks/IRetrospectiveData';

export class TrackRetrospectiveDataService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public trackFinancialDashboardView({
    storeAliasId,
    data,
  }: ITrackRetrospectiveProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/retrospectives`, { ...data });
  }

  public trackProductAnalyticsView({ storeAliasId, data }: ITrackRetrospectiveProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/retrospectives`, { ...data });
  }

  public trackProductCostEditedQuantity({
    storeAliasId,
    data,
  }: ITrackRetrospectiveProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/retrospectives`, { ...data });
  }

  public trackEditedProductCostQuantity({
    storeAliasId,
    data,
  }: ITrackRetrospectiveProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/retrospectives`, { ...data });
  }

  public trackActiveCampaignsQuantity({
    storeAliasId,
    data,
  }: ITrackRetrospectiveProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/retrospectives`, { ...data });
  }

  public trackActiveInvitedUsersQuantity({
    storeAliasId,
    data,
  }: ITrackRetrospectiveProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/retrospectives`, { ...data });
  }
}

const trackRetrospectiveDataService = new TrackRetrospectiveDataService(SUGGESTION_API_DOMAIN);

export default trackRetrospectiveDataService;
