import { Shopify, IIconProps } from '@profitfy/pakkun-icons';

import {
  DROPSHIPPING_PROVIDERS,
  INFO_PRODUCT_PROVIDERS,
  ECOMMERCE_PROVIDERS,
} from '@constants/common/integrations';

export const getProviderIcon = (provider: string): React.FC<IIconProps> => {
  const integrations = [
    ...DROPSHIPPING_PROVIDERS,
    ...INFO_PRODUCT_PROVIDERS,
    ...ECOMMERCE_PROVIDERS,
  ];

  const foundIntegration = integrations.find(integration => integration.identifier === provider);

  if (foundIntegration) return foundIntegration.icon;

  return Shopify;
};
