import { AxiosResponse } from 'axios';

import {
  ICreateCouponProps,
  ICreateCouponResponse,
  IGetCouponByIdentifierPromiseResponse,
  IGetCouponByIdentifierProps,
  IGetCouponByIdentifierResponse,
  IGetCouponByIdPromiseResponse,
  IGetCouponByIdProps,
  IGetCouponByIdResponse,
  IListCouponsPromiseResponse,
  IListCouponsResponse,
} from '@domain/interfaces/common/coupon/ICoupon';

import { useFetch } from '@providers/Fetch/useFetch';

import { ApiService } from '@services/api';

import { USER_API_DOMAIN } from '@constants/api';

export class CouponService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listCoupons(): IListCouponsResponse {
    const { data, isValidating, mutate, error } = useFetch('/api/v1/coupons', this.apiService);

    const isLoading = !data && !error;

    return {
      coupons: data?.coupons,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public listCouponsPromise(): Promise<AxiosResponse<IListCouponsPromiseResponse>> {
    return this.apiService.get<IListCouponsPromiseResponse>('/api/v1/coupons');
  }

  public getCouponByIdentifier({
    couponIdentifier,
    planAliasId,
    subscriptionAliasId,
  }: IGetCouponByIdentifierProps): IGetCouponByIdentifierResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/plans/${planAliasId}/subscriptions/${subscriptionAliasId}/coupons/identifier/${couponIdentifier}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      coupon: data?.coupon,
      isValid: data?.is_valid,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public getCouponByIdentifierPromise({
    couponIdentifier,
    planAliasId,
    subscriptionAliasId,
  }: IGetCouponByIdentifierProps): Promise<AxiosResponse<IGetCouponByIdentifierPromiseResponse>> {
    return this.apiService.get<IGetCouponByIdentifierPromiseResponse>(
      `/api/v1/plans/${planAliasId}/subscriptions/${subscriptionAliasId}/coupons/identifier/${couponIdentifier}`,
    );
  }

  public getCouponById({
    couponId,
    planAliasId,
    subscriptionAliasId,
  }: IGetCouponByIdProps): IGetCouponByIdResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/plans/${planAliasId}/subscriptions/${subscriptionAliasId}/coupons/id/${couponId}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      coupon: data?.coupon,
      isValid: data?.is_valid,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public getCouponByIdPromise({
    couponId,
    planAliasId,
    subscriptionAliasId,
  }: IGetCouponByIdProps): Promise<AxiosResponse<IGetCouponByIdPromiseResponse>> {
    return this.apiService.get<IGetCouponByIdPromiseResponse>(
      `/api/v1/plans/${planAliasId}/subscriptions/${subscriptionAliasId}/coupons/id/${couponId}`,
    );
  }

  public createCoupon({ data }: ICreateCouponProps): Promise<AxiosResponse<ICreateCouponResponse>> {
    return this.apiService.post<ICreateCouponResponse>('/api/v1/coupons', { ...data });
  }
}

const couponService = new CouponService(USER_API_DOMAIN);

export default couponService;
