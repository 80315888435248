import { ENodeType } from '@domain/enums/mappers/automations/profitfy/ENodeType';

import { MESSAGES_TEMPLATES } from '@constants/pages/dashboard/automations/messages/templates';
import { REFUSED_CREDIT_CARD_NODES } from '@constants/pages/dashboard/automations/templates/nodes/refusedCreditCard';

export const getRefusedCreditCardNodesArray = (): any[] => {
  const newWhatsappNodes: any[] = [];

  const nodesWithoutWhatsappNodes = REFUSED_CREDIT_CARD_NODES.filter(
    node => node.type !== ENodeType.WHATSAPP,
  );

  REFUSED_CREDIT_CARD_NODES.forEach(node => {
    if (node.type === ENodeType.WHATSAPP) {
      const foundTemplate = MESSAGES_TEMPLATES.find(
        template => template.id === node.data.message?.id,
      );

      newWhatsappNodes.push({
        ...node,
        data: {
          ...node.data,
          category: foundTemplate?.category,
          type: foundTemplate?.type,
          message: {
            title: foundTemplate?.title,
            message_contents: foundTemplate?.message_contents,
            id: foundTemplate?.id,
          },
        },
      });
    }
  });

  return [...nodesWithoutWhatsappNodes, ...newWhatsappNodes];
};
