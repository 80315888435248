import React from 'react';

import { useMarketingIntegration } from '@helpers/hooks/pages/dashboard/adsManager/onboarding/useMarketingIntegration';

import Header from '../Header';
import AddIntegration from '../AddIntegration';
import Sidemodal from '../Sidemodal';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const { selectedIntegrations, currentIntegration, handleNextStep } = useMarketingIntegration();

  if (currentIntegration) {
    const Component = currentIntegration.marketing_content;

    return (
      <S.MarketingIntegrationWrapper>
        <Component callback={handleNextStep} />
      </S.MarketingIntegrationWrapper>
    );
  }

  return (
    <S.Wrapper>
      <Header />
      <AddIntegration />
      <Sidemodal />

      <S.NextStepWrapper>
        <S.NextStepButton disabled={!selectedIntegrations.length} onClick={handleNextStep}>
          Próximo passo
        </S.NextStepButton>
      </S.NextStepWrapper>
    </S.Wrapper>
  );
};

export default Wrapper;
