import React from 'react';

import { TikTok } from '@profitfy/pakkun-icons';
import { useParams } from 'react-router-dom';
import { IParams } from '@domain/interfaces/IParams';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { IAuthenticateModalProps } from '@domain/interfaces/onboarding/components/tiktok/modal';
import { useToast } from '@helpers/hooks/useToast';
import { useTikTokCrendentials } from '@helpers/hooks/common/integrations/marketing/tiktok/useTikTokCrendentials';
import { newWindow } from '@helpers/hooks/usePopup';

import tikTokCredentialService from '@services/pages/dashboard/adSense/tiktok/tiktokCredential';

import * as S from './styles';

const AddNewProfileModal: React.FC<IAuthenticateModalProps> = ({ isOpen, toggle }) => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();

  const { mutateAdSenseCredentials } = useTikTokCrendentials();

  const [isIntegrating, setIsIntegrating] = React.useState<boolean>(false);
  const [userWindow, setUserWindow] = React.useState<Window | undefined>(undefined);

  const onAddNewProfileButtonClick = React.useCallback(async () => {
    setIsIntegrating(true);

    try {
      const { data: tiktokData } = await tikTokCredentialService.generateUrl({ storeAliasId });

      const window = newWindow(tiktokData?.auth_url, 'TikTok', 1280, 720);

      setUserWindow(window);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsIntegrating(false);
    }
  }, [storeAliasId, toast]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (userWindow?.closed) {
        clearInterval(interval);
        mutateAdSenseCredentials();
      }
    }, 1000);
  }, [userWindow, mutateAdSenseCredentials]);

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.HeadingWrapper>
        <TikTok size={24} />
        <S.Title weight={ETextWeight.MEDIUM}>Adicionar perfil do TikTok</S.Title>
      </S.HeadingWrapper>

      <S.StepsWrapper>
        <S.StepTitle>Conecte seu perfil do TikTok seguindo os passos abaixo:</S.StepTitle>
        <S.Step>
          <S.StepBullet />
          <S.StepDescription size={ETextSize.B5}>Clique no botão abaixo.</S.StepDescription>
        </S.Step>

        <S.Step>
          <S.StepBullet />
          <S.StepDescription size={ETextSize.B5}>
            Na janela aberta, clique no botão <strong>&quot;SWITCH ACCOUNT&quot;.</strong>
          </S.StepDescription>
        </S.Step>

        <S.Step>
          <S.StepBullet />
          <S.StepDescription size={ETextSize.B5}>
            Logue outra conta que deseja conectar.
          </S.StepDescription>
        </S.Step>
      </S.StepsWrapper>

      <S.AddNewProfileButton
        onClick={onAddNewProfileButtonClick}
        isLoading={isIntegrating}
        disabled={isIntegrating}
      >
        Adicionar perfil
      </S.AddNewProfileButton>
    </S.Modal>
  );
};

export default AddNewProfileModal;
