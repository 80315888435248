import React from 'react';
import { CartPanda as Icon } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';
import { EEcommerceProvider } from '@domain/enums/common/integrations/EEcommerceProvider';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';

import { useToast } from '@helpers/hooks/useToast';
import { useEcommerce } from '@helpers/hooks/pages/onboarding/useEcommerce';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useDate } from '@helpers/hooks/useDate';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { ECartPandaType } from '@domain/enums/common/integrations/providers/ECartPanda';

import { cartPandaSchema } from '@helpers/validators/dashboard/integrationCenter/cartPanda';

import { ECOMMERCE_PROVIDERS } from '@constants/common/integrations';

import cartPandaService from '@services/pages/dashboard/integrationCenter/storeProvider/cartPanda/cartPandaAuth';
import integrationCenterService from '@services/pages/dashboard/integrationCenter/integrationCenter';

import NextStep from '@components/Onboarding/NextStep';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const CartPanda: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(cartPandaSchema) });
  const history = useHistory();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { selectedGateways, selectedMarketings } = useEcommerce();
  const { addSynchronization } = useSynchronization();
  const { format, utcToZonedTime } = useDate();
  const { sendFinancialData, handleStoreBonus } = useOnboarding();

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [domainValue, setDomainValue] = React.useState<string>('');
  const [accessTokenValue, setAccessTokenValue] = React.useState<string>('');

  const domainRegister = register('domain');
  const accessTokenRegister = register('access_token');

  const onDomainValueChange = React.useCallback(
    event => {
      domainRegister.onChange(event);

      setDomainValue(event.target.value);
    },
    [domainRegister],
  );

  const onAccessTokenValueChange = React.useCallback(
    event => {
      accessTokenRegister.onChange(event);

      setAccessTokenValue(event.target.value);
    },
    [accessTokenRegister],
  );

  const sanitizeUrl = React.useCallback(url => {
    const replacedUrl = url.replace('https://', '').replace('wwww.', '');

    const parsedUrl = replacedUrl.split('.mycartpanda.com')[0];

    return parsedUrl;
  }, []);

  const handleAfterSubmit = React.useCallback(async () => {
    const { data } = await integrationCenterService.getStoreIntegrations({ storeAliasId });

    const hasCartPandaCredential = data?.store_integrations?.store_front?.has_cart_panda_credential;

    if (hasCartPandaCredential) {
      const foundCartPandaIntegration = ECOMMERCE_PROVIDERS.find(
        integration => integration.identifier === EEcommerceProvider.CART_PANDA,
      ) as typeof ECOMMERCE_PROVIDERS[number];

      const { generateSyncRequest } = foundCartPandaIntegration;

      const params = `?start_date=${format(new Date(2000, 1, 1), 'yyyy-MM-dd')}&end_date=${format(
        utcToZonedTime(new Date()),
        'yyyy-MM-dd',
      )}`;

      addSynchronization({
        name: 'CartPanda',
        type: ESynchronizationType.STORE_PRODUCT,
        label: 'Produtos',
        dependencies: [],
        storeAliasId,
        config: {
          isOnboardingSynchronization: true,
          showNotification: false,
        },
        request: generateSyncRequest(ESynchronizationType.STORE_PRODUCT, storeAliasId, params),
      });

      sendFinancialData(storeAliasId);

      setIsSubmittingForm(false);

      if (!selectedGateways.length && !selectedMarketings.length) {
        handleStoreBonus();
        return;
      }

      if (selectedGateways.length) {
        history.push(`/onboarding/${storeAliasId}/ecommerce/integrations/gateways`);
      }

      if (!selectedGateways.length && selectedMarketings.length) {
        history.push(`/onboarding/${storeAliasId}/ecommerce/integrations/marketing`);
      }
    }
  }, [
    addSynchronization,
    format,
    history,
    selectedMarketings,
    storeAliasId,
    utcToZonedTime,
    sendFinancialData,
    handleStoreBonus,
    selectedGateways,
  ]);

  const onSubmit = React.useCallback(
    async data => {
      setIsSubmittingForm(true);

      const sanitizedUrl = sanitizeUrl(data.domain);

      try {
        await cartPandaService.createCredential({
          storeAliasId,
          data: {
            access_token: data.access_token,
            domain: sanitizedUrl,
            types: [ECartPandaType.STORE],
          },
        });

        handleAfterSubmit();
      } catch (error: any) {
        toast.error(getTranslatedIntegrationsError(error));
      } finally {
        setIsSubmittingForm(false);
      }
    },
    [storeAliasId, toast, sanitizeUrl, handleAfterSubmit],
  );

  const hasAllInputsValue = domainValue && accessTokenValue;

  return (
    <S.Wrapper>
      <S.FormWrapper>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Header>
            <S.Title>Integre com sua plataforma de E-commerce</S.Title>
          </S.Header>

          <S.IntegrationTitle>
            <Icon size={36} />

            <S.IntegrationTitleText fontWeight={EHeadingWeight.REGULAR}>
              Integração com CartPanda
            </S.IntegrationTitleText>
          </S.IntegrationTitle>

          <S.Body>
            <S.InputGroup>
              <S.Label htmlFor="cart-panda-store-url">URL da Loja</S.Label>

              <S.InputWrapper isError={errors.domain} value={domainValue}>
                <S.TextFieldWithAppendContent
                  {...domainRegister}
                  type="text"
                  id="cart-panda-store-url"
                  onChange={onDomainValueChange}
                />
                <S.AppendContent>.mycartpanda.com</S.AppendContent>
              </S.InputWrapper>
              {errors.domain && <Text isErrorFeedback>{errors.domain.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label htmlFor="access-token">Access Token</S.Label>
              <S.TextField
                {...accessTokenRegister}
                type="text"
                id="access-token"
                onChange={onAccessTokenValueChange}
                value={accessTokenValue}
                isError={Boolean(errors.access_token)}
              />
              {errors.access_token && <Text isErrorFeedback>{errors.access_token.message}</Text>}
            </S.InputGroup>
          </S.Body>

          <NextStep
            type="submit"
            isLoading={isSubmittingForm}
            disabled={isSubmittingForm || !hasAllInputsValue}
          >
            Próximo passo
          </NextStep>
        </S.Form>
      </S.FormWrapper>

      <S.TutorialWrapper>
        <S.TutorialImage
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/onboarding/images/content/onboarding/onboarding-integration-tutorial.svg"
          alt="Profitfy.me Integration Tutorial"
        />
        <S.TutorialTitle>Tutorial de integração</S.TutorialTitle>
        <S.TutorialContent>
          Para realizar a integração você precisará da <strong>URL da loja</strong> e do{' '}
          <strong>Access Token</strong> que são encontrados neste link:
        </S.TutorialContent>
        <S.TutorialLink
          href="https://ajuda.profitfy.me/pt-BR/articles/6060422-como-integrar-com-a-cartpanda-pay"
          target="_blank"
        >
          Encontre suas credenciais clicando aqui!
        </S.TutorialLink>
      </S.TutorialWrapper>
    </S.Wrapper>
  );
};

export default CartPanda;
