import React from 'react';
import { useLocation } from 'react-router-dom';

import { ISideBarProps } from '@domain/interfaces/onboarding/components/sideBar/ISideBar';

import logoImg from '@assets/img/brand/profitfy-logo.svg';

import Return from './Return';
import ProgressBar from './ProgressBar';

import * as S from './styles';

const SideBar: React.FC<ISideBarProps> = ({ onboardingStep, linkToGoBack }) => {
  const { pathname } = useLocation();

  const hideReturnButton =
    pathname.includes('integrations') &&
    !pathname.includes('gateways') &&
    !pathname.includes('platforms') &&
    !pathname.includes('marketing');

  return (
    <S.Wrapper>
      <S.Content>
        <S.Logo src={logoImg} alt="profitfy-logo" />

        {!hideReturnButton && <Return linkToGoBack={linkToGoBack} />}

        <ProgressBar step={onboardingStep} />
      </S.Content>
    </S.Wrapper>
  );
};

export default SideBar;
