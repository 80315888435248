import React from 'react';

import { ETextWeight } from '@domain/enums/components/EText';
import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const ChartTooltip: React.FC<any> = ({ active, payload, label, currency }) => {
  const isNetAmountPositive = React.useCallback((name: string, value: number) => {
    return name === 'Lucro Líquido' && value > 0;
  }, []);

  const isNetAmountNegative = React.useCallback((name: string, value: number) => {
    return name === 'Lucro Líquido' && value < 0;
  }, []);

  if (active && payload && payload.length) {
    return (
      <S.TooltipCard>
        <S.TooltipTitle weight={ETextWeight.SEMIBOLD}>{label}</S.TooltipTitle>

        {payload.map((objectData: any) =>
          objectData.value !== 0 ? (
            <S.LabelWrapper key={objectData.name}>
              <S.DotColor color={objectData.color} />
              {currency ? (
                <S.TextWrapper>
                  <S.LabelText>{`${objectData.name}:`}</S.LabelText>
                  <S.LabelText
                    isNetAmountPositive={isNetAmountPositive(objectData.name, objectData.value)}
                    isNetAmountNegative={isNetAmountNegative(objectData.name, objectData.value)}
                  >
                    {objectData.name === 'Quantidade de Pedidos'
                      ? `R$ ${numberFormatter(objectData.value, 0, true)}`
                      : `R$ ${numberFormatter(objectData.value, 2)}`}
                  </S.LabelText>
                </S.TextWrapper>
              ) : (
                <S.LabelText>
                  {objectData.name === 'Quantidade de Pedidos'
                    ? `${numberFormatter(objectData.value, 0, true)}`
                    : `${numberFormatter(objectData.value, 2)}`}
                </S.LabelText>
              )}
            </S.LabelWrapper>
          ) : (
            <React.Fragment key={objectData.name} />
          ),
        )}
      </S.TooltipCard>
    );
  }

  return null;
};

export default ChartTooltip;
