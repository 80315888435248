import * as yup from 'yup';

export const cartPandaSchema = yup.object().shape({
  access_token: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(10, 'Informe uma credencial válida')
    .max(200, 'Informe uma credencial válida'),
  domain: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'Informe um domínio válido.')
    .max(400, 'Informe um domínio válido.'),
});
