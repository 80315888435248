import styled from 'styled-components';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';
import TextBase from '@components/common/core/DataDisplay/Text';
import ImageBase from '@components/common/core/DataDisplay/Image';

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 851px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-start;
  padding-top: 95px;

  @media only screen and (max-width: 880px) {
    flex-direction: column;
    gap: 32px;
    padding-top: 42px;
  }
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Heading = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.white.default};
  line-height: 100%;
`;

export const BenefitsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 52px;
  margin-top: 32px;
`;

export const Benefit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const BenefitDescription = styled(TextBase)``;

export const InviteTeamButton = styled(ButtonBase)``;

export const Image = styled(ImageBase)`
  width: 100%;
  max-width: 374px;
`;
