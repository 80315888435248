import React from 'react';

import { EAbandonCartOrderStatusPicker } from '@domain/enums/dashboard/automations/node/conditionalNode/EAbandonCart';

import * as S from './styles';

interface IPickerOrderStatusProps {
  defaultValue?: string;
  onChange: (data: string) => void;
}

const PickerProduct: React.FC<IPickerOrderStatusProps> = ({
  defaultValue = EAbandonCartOrderStatusPicker.PAID,
  onChange,
}) => {
  return (
    <S.Select
      name="picker"
      defaultValue={defaultValue}
      onChange={e => {
        onChange(e.target.value);
      }}
    >
      <S.Option value={EAbandonCartOrderStatusPicker.CANCELED}>Cancelado</S.Option>
      <S.Option value={EAbandonCartOrderStatusPicker.CHARGED_BACK}>Chargedback</S.Option>
      <S.Option value={EAbandonCartOrderStatusPicker.IN_ANALYSIS}>Em análise</S.Option>
      <S.Option value={EAbandonCartOrderStatusPicker.PAID}>Pago</S.Option>
      <S.Option value={EAbandonCartOrderStatusPicker.PENDING}>Pendente</S.Option>
      <S.Option value={EAbandonCartOrderStatusPicker.REFUNDED}>Reembolsado</S.Option>
      <S.Option value={EAbandonCartOrderStatusPicker.REJECTED}>Rejeitado</S.Option>
    </S.Select>
  );
};

export default PickerProduct;
