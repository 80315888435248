import React from 'react';
import Datepicker from 'react-datepicker';
import { Calendar } from '@profitfy/pakkun-icons';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import { EPreDefinedDates } from '@domain/enums/components/EDatePicker';
import { EStatus } from '@domain/enums/subscription/ESubscription';
import { IParams } from '@domain/interfaces/IParams';
import { ICustomDateProps } from '@domain/interfaces/components/IDatePicker';

import { useConfig } from '@helpers/hooks/useConfig';
import { useDate } from '@helpers/hooks/useDate';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';

import Text from '@components/common/core/DataDisplay/Text';
import Header from './Header';

import * as S from './styles';

const CustomDate: React.FC<ICustomDateProps> = ({
  startDate,
  endDate,
  onChange,
  isOpen,
  handleIsOpen,
  handleSelectDateType,
}) => {
  const { getUserStore, isInternalTeam, selectedStore } = useConfig();
  const { utcToZonedTime, format, differenceInDays, addDays } = useDate();
  const { storeAliasId } = useParams<IParams>();
  const theme: any = useTheme();
  const { subscription, hasAllPermissions } = useStoreSubscription();

  const [dateToSync, setDateToSync] = React.useState<Date>(
    utcToZonedTime(getUserStore(storeAliasId)?.initial_date_to_sync || new Date().toISOString()),
  );

  const getNameOfDay = React.useCallback(
    nameOfDay =>
      ({
        Sunday: 'Dom',
        Monday: 'Seg',
        Tuesday: 'Ter',
        Wednesday: 'Qua',
        Thursday: 'Qui',
        Friday: 'Sex',
        Saturday: 'Sab',
      }[nameOfDay]),
    [],
  );

  const handleOnChange = React.useCallback(
    dates => {
      const [start, end] = dates;

      onChange([start, end]);

      if (end) {
        handleSelectDateType(EPreDefinedDates.CUSTOM);
      }
    },
    [handleSelectDateType, onChange],
  );

  React.useEffect(() => {
    if (selectedStore) {
      setDateToSync(new Date(selectedStore.initial_date_to_sync));
    }
  }, [selectedStore]);

  const getMaxDate = React.useCallback(() => {
    const isFreePlan = subscription && subscription.plan?.identifier.includes('free');

    if (isFreePlan && subscription.exceeded_order_limit_at && !hasAllPermissions) {
      return utcToZonedTime(new Date(subscription.exceeded_order_limit_at));
    }

    const isSubscriptionPeriodEndedAtAfterNow = subscription?.period_ended_at
      ? differenceInDays(utcToZonedTime(new Date()), new Date(subscription.period_ended_at)) >= 0
      : true;

    if (subscription?.status === EStatus.CANCELED && isSubscriptionPeriodEndedAtAfterNow) {
      return utcToZonedTime(new Date());
    }

    if (
      subscription?.status === EStatus.CANCELED &&
      subscription?.period_ended_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(addDays(new Date(subscription.period_ended_at), 1));
    }

    if (
      subscription?.status === EStatus.CANCELED &&
      subscription?.canceled_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(subscription.canceled_at);
    }

    if (subscription && subscription.status === EStatus.TRIAL_ENDED && !hasAllPermissions) {
      return utcToZonedTime(new Date(subscription.period_ended_at));
    }

    return utcToZonedTime(new Date());
  }, [subscription, utcToZonedTime, hasAllPermissions, differenceInDays, addDays]);

  const maxDate = getMaxDate();

  return (
    <S.DateInputsWrapper>
      <S.InputWrapper>
        <S.DateButtonLabel>Começa em:</S.DateButtonLabel>
        <S.DateButton onClick={handleIsOpen}>
          <Text>{startDate && format(startDate, 'dd/MM/yyyy')}</Text>
          <Calendar size={20} outline color={theme.colors.font} />
        </S.DateButton>
      </S.InputWrapper>
      <S.InputWrapper>
        <S.DateButtonLabel>Termina em:</S.DateButtonLabel>
        <S.DateButton onClick={handleIsOpen}>
          <Text>{endDate && format(endDate, 'dd/MM/yyyy')}</Text>
          <Calendar size={20} outline color={theme.colors.font} />
        </S.DateButton>
      </S.InputWrapper>
      <S.CalendarWrapper isOpen={isOpen}>
        <Datepicker
          selected={startDate}
          minDate={isInternalTeam ? undefined : dateToSync}
          maxDate={maxDate}
          onChange={handleOnChange}
          startDate={startDate}
          showPreviousMonths={false}
          endDate={endDate}
          selectsRange
          inline
          formatWeekDay={nameOfDay => getNameOfDay(nameOfDay)}
          peekNextMonth={false}
          renderCustomHeader={({ date, changeYear, decreaseMonth, increaseMonth }) => (
            <Header
              date={date}
              changeYear={changeYear}
              decreaseMonth={decreaseMonth}
              increaseMonth={increaseMonth}
            />
          )}
        />
      </S.CalendarWrapper>
    </S.DateInputsWrapper>
  );
};

export default CustomDate;
