import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import ImageBase from '@components/common/core/DataDisplay/Image';
import BadgeBase from '@components/common/core/DataDisplay/Badge';
import TooltipBase from '@components/common/core/Utils/Tooltip';
import TextBase from '@components/common/core/DataDisplay/Text';
import NoImageBase from '@components/common/core/DataDisplay/NoImage';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';
import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';
import LinkBase from '@components/common/core/Navigation/Link';

export const NetProfitText = styled(TextBase)`
  font-size: 0.875rem;
`;

export const NetProfitContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
`;

export const NetProfitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const NetProfitTooltip = styled(CustomTooltipBase)``;

export const RankingText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.font};
  text-decoration: underline;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Link = styled(LinkBase)`
  &:hover {
    filter: unset;
  }

  &:active {
    filter: unset;
  }
`;

export const ProductNameText = styled(TextBase)``;

export const AddCostText = styled(TextBase)``;

export const CustomPopover = styled(CustomPopoverBase)``;

export const CustomTooltipTrigger = styled(CustomTooltipBase.Trigger)`
  height: 55px;
`;

export const CustomTooltip = styled(CustomTooltipBase)``;

export const ChevronIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 auto;
`;

export const NoImage = styled(NoImageBase)`
  background-color: ${({ theme }) => theme.colors.background[3]};
`;

export const MarketingTooltipText = styled(TextBase)`
  font-size: 0.875rem;
`;

export const MarketingTooltipLineWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const MarketingValueTooltipWrapper = styled.div``;

export const MarketingText = styled(TextBase)`
  font-size: 0.875rem;
`;

export const Tooltip = styled(TooltipBase)``;

export const MoreOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`;

export const ActionButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.font};
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[6]};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.gray[6]};
    filter: brightness(0.9);
  }
`;

export const ConversionValue = styled.span`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: 0.75rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.green.default};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ConversionWrapper = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  margin: 0 auto;
`;

export const MarketingWrapper = styled.div`
  width: 20px;
  height: 20px;
  border: unset;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  margin-left: 6px;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const MarketingData = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`;

export const ProfitMarginData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProfitMarginBadge = styled(BadgeBase)``;

export const Badge = styled(BadgeBase)`
  margin-left: auto;
  white-space: nowrap;
`;

export const ProductImage = styled(ImageBase)`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
`;

export const Table = styled(TableBase)``;

export const FavoriteIconWrapper = styled.button`
  background-color: unset;
  padding: unset;
  border: unset;
  cursor: pointer;
`;
