import styled, { css } from 'styled-components/macro';

import {
  ITextFieldContainerProps,
  ITextFieldVerticalRuleProps,
} from '@domain/interfaces/components/ITextField';

export const PrependAndAppendText = styled.span`
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.font};
  font-weight: 600;
  font-size: 1rem;
  line-height: 100%;
  margin-top: 4px;
`;

export const InputButtonWrapper = styled.div`
  display: flex;
  border-radius: 5px;
`;

export const Button = styled.button<{ isLoading?: boolean }>`
  min-width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.green.dark};
  color: #fff;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  font-size: 1rem;
  border: none;
  padding: 0 28px;
  height: 42px;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
  transition: all 0.3s;
  white-space: nowrap;

  ${({ isLoading }) =>
    isLoading &&
    css`
      min-width: 165px;
    `}

  &:hover {
    filter: brightness(0.9);
  }

  &:active {
    filter: brightness(0.8);
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray[3]};
    cursor: not-allowed;
  }
`;

export const VerticalRule = styled.div<ITextFieldVerticalRuleProps>`
  width: 1px;
  height: 25px;
  margin: 0 12px;
  background-color: ${({ theme }) => theme.colors.gray[3]};

  ${({ prepend }) =>
    prepend &&
    css`
      width: 0;
      margin: 0;
      margin-left: 16px;
    `}
`;

export const IconWrapper = styled.div``;

export const Input = styled.input`
  border: none;
  height: calc(100% - 2px);
  background-color: transparent;
  color: ${({ theme }) => theme.colors.font};
  font-size: 1rem;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.font};
    opacity: 0.6;
  }

  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.font};
    opacity: 0.6;
  }

  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.font};
    opacity: 0.6;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;

    &::placeholder {
      color: ${({ theme }) => theme.colors.gray[3]};
    }
  }

  &:read-only {
    cursor: not-allowed;

    &::placeholder {
      color: ${({ theme }) => theme.colors.gray[3]};
    }
  }
`;

export const Container = styled.div<ITextFieldContainerProps>`
  height: 42px;
  border: 1px solid ${({ theme }) => theme.colors.inputBorderColor};
  background-color: ${({ theme }) => theme.colors.background[2]};
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  padding: 10px 12px;
  transition: all 0.3s;
  width: 100%;
  box-sizing: border-box;

  & > svg {
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
  }

  ${({ isError, theme, value, disabled, noFeedbackError, readOnly, noFeedbackSuccess }) =>
    Boolean(value) &&
    Boolean(!isError) &&
    !disabled &&
    !readOnly &&
    !noFeedbackError &&
    !noFeedbackSuccess &&
    css`
      border-color: ${theme.colors.success.default};
      background-color: ${theme.colors.black.default};
    `}

  ${({ isError, theme }) =>
    Boolean(isError) &&
    css`
      border-color: ${theme.colors.danger.default};
      background-color: ${theme.colors.black.default};
    `}

  ${({ button }) =>
    button &&
    css`
      border-radius: 5px 0 0 5px;
    `}

  ${({ theme, hasAnyError, isFocused }) =>
    hasAnyError &&
    css`
      border-color: ${theme.colors.danger.default};
      background-color: ${theme.colors.danger.input.defaultBackground};

      ${isFocused &&
      css`
        background-color: ${theme.colors.danger.input.activeBackground};
        box-shadow: ${theme.shadows.dangerFocusedTyping};
      `}
    `}

  ${({ theme, hasAnyWarning, isFocused }) =>
    hasAnyWarning &&
    css`
      border-color: ${theme.colors.warning.default};
      background-color: ${theme.colors.warning.input.defaultBackground};

      ${isFocused &&
      css`
        background-color: ${theme.colors.warning.input.activeBackground};
        box-shadow: ${theme.shadows.warningFocusedTyping};
      `}
    `}

    ${({ theme, isSuccess, isFocused, noFeedbackSuccess }) =>
    isSuccess &&
    !noFeedbackSuccess &&
    css`
      border-color: ${theme.colors.success.default};
      background-color: ${theme.colors.success.input.defaultBackground};

      ${isFocused &&
      css`
        background-color: ${theme.colors.success.input.activeBackground};
        box-shadow: ${theme.shadows.successFocusedTyping};
      `}
    `}

    ${({ theme, disabled, readOnly }) =>
    (disabled || readOnly) &&
    css`
      background-color: ${theme.colors.background[3]};
      cursor: not-allowed;
    `}
`;

export const FeedBackIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;
