import { Info } from 'phosphor-react';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { EHeadingSize } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useToast } from '@helpers/hooks/useToast';
import { dsersCredentialSchema } from '@helpers/validators/dashboard/integrationCenter/erp/dsers';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import Text from '@components/common/core/DataDisplay/Text';
import Form from '@components/common/core/Inputs/Form';
import ComponentError from '@components/common/core/Utils/ComponentError';
import dsersAuthService from '@services/pages/dashboard/integrationCenter/erp/dsers/dsersAuth';

import * as S from './styles';

const CredentialOption: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(dsersCredentialSchema) });
  const { mutateStore, handleDeleteSyncsModalOpen } = useStoreConfig();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const theme = useTheme();

  const {
    dsersCredential,
    error: dsersCredentialError,
    isLoading: isLoadingDsersCredential,
    isValidating: isValidatingDsersCredential,
    mutate: mutateDsersCredential,
  } = dsersAuthService.getDsersCredential({ storeAliasId });

  const [isDeletingCredential, setIsDeletingCredential] = React.useState<boolean>(false);

  const [isUpdatingCredential, setIsUpdatingCredential] = React.useState<boolean>(false);

  const syncOrderCogsRef = React.useRef<HTMLInputElement>(null);
  const syncProductsRef = React.useRef<HTMLInputElement>(null);
  const syncProductsInSyncOrderCogs = React.useRef<HTMLInputElement>(null);
  const syncRemessaConforme = React.useRef<HTMLInputElement>(null);

  const onUpdateCredential = React.useCallback(
    async data => {
      setIsUpdatingCredential(true);

      try {
        if (
          syncOrderCogsRef.current &&
          syncProductsRef.current &&
          syncProductsInSyncOrderCogs.current &&
          syncRemessaConforme.current
        ) {
          await dsersAuthService.updateDsersCredential({
            storeAliasId,
            data: {
              sync_order_cogs: syncOrderCogsRef.current.checked,
              sync_order_shipping_cost_owner: true,
              sync_products: syncProductsRef.current.checked,
              sync_remessa_conforme: syncRemessaConforme.current.checked,
              email: data.email,
              password: data.password,
              sync_products_in_sync_order_cogs: syncProductsInSyncOrderCogs.current.checked,
            },
          });

          toast.success('Credencial atualizada com sucesso.');

          setIsUpdatingCredential(false);

          await mutateStore();
        }
      } catch (error: any) {
        setIsUpdatingCredential(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [storeAliasId, toast, mutateStore],
  );

  const onDeleteCredential = React.useCallback(async () => {
    setIsDeletingCredential(true);

    try {
      await dsersAuthService.deleteDsersCredential({ storeAliasId });

      toast.success('Credencial desativada com sucesso!');

      setIsDeletingCredential(false);

      handleDeleteSyncsModalOpen();

      await mutateStore();
    } catch (error: any) {
      setIsDeletingCredential(false);
      toast.error(error?.response?.data?.message);
    }
  }, [toast, mutateStore, storeAliasId, handleDeleteSyncsModalOpen]);

  if (isLoadingDsersCredential || isValidatingDsersCredential) {
    return <div>Loading...</div>;
  }

  if (dsersCredentialError && !isValidatingDsersCredential) {
    return <ComponentError mutate={mutateDsersCredential} />;
  }

  return (
    <S.Content>
      <S.CredentialDescriptionWrapper>
        <S.CredentialDescriptionTitle type={EHeadingSize.H5}>
          Descrição da Credencial
        </S.CredentialDescriptionTitle>
        <S.CredentialDescriptionText>
          A integração com Dsers sincroniza seus
          <strong> custos de produto e frete </strong>
          diretamente com o Aliexpress
          <strong> de forma automática.</strong>
        </S.CredentialDescriptionText>
      </S.CredentialDescriptionWrapper>

      <Form onSubmit={handleSubmit(onUpdateCredential)}>
        <S.InputGroup>
          <S.Label htmlFor="email">E-mail</S.Label>

          <S.Input
            {...register('email')}
            type="text"
            id="email"
            placeholder="Digite o e-mail do Dsers"
            defaultValue={dsersCredential.email}
          />
          {errors.email && <Text isErrorFeedback>{errors.email.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label htmlFor="password">Senha</S.Label>

          <S.Input
            {...register('password')}
            type="password"
            id="password"
            placeholder="Digite a senha do Dsers"
          />
          {errors.password && <Text isErrorFeedback>{errors.password.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>O que você deseja sincronizar?</S.Label>

          <S.SwitchGroup>
            <S.Switch
              id="sync_order_cogs"
              name="sync-order-cogs"
              isLoading={false}
              defaultChecked={dsersCredential.sync_order_cogs}
              innerRef={syncOrderCogsRef}
            />

            <S.SwitchLabel>Pedidos</S.SwitchLabel>
          </S.SwitchGroup>

          <S.SwitchGroup>
            <S.Switch
              id="sync_products"
              name="sync-products"
              isLoading={false}
              defaultChecked={dsersCredential.sync_products}
              innerRef={syncProductsRef}
            />

            <S.SwitchLabel>Produtos</S.SwitchLabel>
          </S.SwitchGroup>

          <S.SwitchGroup>
            <S.Switch
              id="sync_products_in_sync_order_cogs"
              name="sync-products-in-sync-order-cogs"
              isLoading={false}
              defaultChecked={dsersCredential.sync_products_in_sync_order_cogs}
              innerRef={syncProductsInSyncOrderCogs}
            />

            <S.SwitchLabel>
              <S.SyncProductsWrapper>
                Sincronizar produtos ao sincronizar pedidos
                <S.Tooltip>
                  <S.Tooltip.Trigger>
                    <Info size={16} color={theme.colors.font} />
                  </S.Tooltip.Trigger>

                  <S.Tooltip.Content sideOffset={5} side="top">
                    <S.TooltipTextWrapper>
                      <S.TooltipText>
                        Ao ativar essa opção, a sincronização do produto sempre irá ocorrer ao
                        sincronizar os pedidos. Os custos de produtos podem ser alterados, deixando
                        mais assertivos.
                      </S.TooltipText>
                    </S.TooltipTextWrapper>
                    <S.Tooltip.Arrow />
                  </S.Tooltip.Content>
                </S.Tooltip>
              </S.SyncProductsWrapper>
            </S.SwitchLabel>
          </S.SwitchGroup>

          <S.SwitchGroup>
            <S.Switch
              id="sync_remessa_conforme"
              name="sync-remessa-conforme"
              isLoading={false}
              defaultChecked={dsersCredential?.sync_remessa_conforme}
              innerRef={syncRemessaConforme}
            />

            <S.SwitchLabel>Remessa Conforme</S.SwitchLabel>
          </S.SwitchGroup>
        </S.InputGroup>

        <S.UpdateCredentialButton
          type="submit"
          isLoading={isUpdatingCredential}
          disabled={isDeletingCredential || isUpdatingCredential}
        >
          Atualizar credencial
        </S.UpdateCredentialButton>
      </Form>

      <S.CredentialDescriptionWrapper>
        <S.CredentialDescriptionTitle type={EHeadingSize.H5}>
          O que acontece se eu desativar minha integração?
        </S.CredentialDescriptionTitle>
        <S.CredentialDescriptionText>
          Ao desativar a integração, não será mais possível processar os dados do Bling
        </S.CredentialDescriptionText>
      </S.CredentialDescriptionWrapper>

      <S.DisableCredentialButton
        onClick={onDeleteCredential}
        variant={EButtonVariant.SECONDARY}
        isLoading={isDeletingCredential}
        disabled={isDeletingCredential || isUpdatingCredential}
      >
        Desativar credencial
      </S.DisableCredentialButton>
    </S.Content>
  );
};

export default CredentialOption;
