import React from 'react';

import { ETextWeight } from '@domain/enums/components/EText';
import { ITemplateProps } from '@domain/interfaces/dashboard/Automations/ITemplateSummaryModal';

import { useTemplate } from '@helpers/hooks/pages/dashboard/automations/useTemplate';
import { useConfig } from '@helpers/hooks/useConfig';

import BadgeTags from './BadgeTags';
import SupportedChannels from './SupportedChannels';

import * as S from './styles';

const Template: React.FC<ITemplateProps> = ({ template }) => {
  const { analytics, selectedStore } = useConfig();

  const { handleSelectedTemplate, handleTemplateSummaryModalOpen } = useTemplate();

  const onTemplateClick = React.useCallback(() => {
    analytics?.track(
      'Automation Tool Chose a Template',
      {
        click_source: template.name,
      },
      { context: { groupId: selectedStore?.alias_id } },
    );

    handleSelectedTemplate(template);

    handleTemplateSummaryModalOpen();
  }, [handleSelectedTemplate, template, handleTemplateSummaryModalOpen, analytics, selectedStore]);

  return (
    <S.Wrapper onClick={onTemplateClick}>
      <S.TemplateContent>
        <SupportedChannels channels={template.supported_channels} />

        <S.TemplateContentImage
          src={template.image_url}
          alt={`profitfy-automations-${template.alias}-image`}
        />
      </S.TemplateContent>

      <S.TemplateInfos>
        <S.TemplateName weight={ETextWeight.BOLD}>{template.name}</S.TemplateName>

        <BadgeTags tags={template.tags} />
      </S.TemplateInfos>
    </S.Wrapper>
  );
};

export default Template;
