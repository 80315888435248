import { Shopify, Yampi, CartPanda, NuvemShop, Woocommerce, Lpqv } from '@profitfy/pakkun-icons';

import { EDropshippingProvider } from '@domain/enums/common/integrations/EDropshippingProvider';
import { IDropshippingProviders } from '@domain/interfaces/common/integration/IDropshippingProvider';

import YampiSideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/StoreProvider/Yampi';
import CartPandaSideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/StoreProvider/CartPanda';
import NuvemShopSidemodal from '@components/Dashboard/IntegrationCenter/Sidemodal/Marketplace/NuvemShop';
import WoocommerceSidemodal from '@components/Dashboard/IntegrationCenter/Sidemodal/Marketplace/Woocommerce';
import ShopifySidemodalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/StoreProvider/Shopify';
import LPQVSideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/StoreProvider/LPQV';

import OnboardingShopifyContent from '@components/Onboarding/Dropshipping/Platforms/Shopify';
import OnboardingNuvemShopContent from '@components/Onboarding/Dropshipping/Platforms/NuvemShop';
import OnboardingWoocommerceContent from '@components/Onboarding/Dropshipping/Platforms/Woocommerce';
import OnboardingCartPandaContent from '@components/Onboarding/Dropshipping/Platforms/CartPanda';
import OnboardingYampiContent from '@components/Onboarding/Dropshipping/Platforms/Yampi';
import OnboardingLPQVContent from '@components/Onboarding/Dropshipping/Platforms/LPQV';

import {
  getShopifySynchronizationRequestConfig,
  getShopifyGatewaySynchronizationRequestConfig,
} from '@helpers/utils/integrations/dropshippingProviders/shopify';
import {
  getNuvemShopSynchronizationRequestConfig,
  getNuvemShopGatewaySynchronizationRequestConfig,
} from '@helpers/utils/integrations/dropshippingProviders/nuvemShop';
import {
  getCartPandaSynchronizationRequestConfig,
  getCartPandaGatewaySynchronizationRequestConfig,
} from '@helpers/utils/integrations/dropshippingProviders/cartPanda';
import {
  getYampiSynchronizationRequestConfig,
  getYampiGatewaySynchronizationRequestConfig,
} from '@helpers/utils/integrations/dropshippingProviders/yampi';
import {
  getWoocommerceSynchronizationRequestConfig,
  getWoocommerceGatewaySynchronizationRequestConfig,
} from '@helpers/utils/integrations/dropshippingProviders/woocommerce';
import {
  getLPQVSynchronizationRequestConfig,
  getLPQVGatewaySynchronizationRequestConfig,
} from '@helpers/utils/integrations/dropshippingProviders/lpqv';

import { SHOPIFY_GATEWAYS } from '@constants/integrations/providers/shopifyGateways';
import { CART_PANDA_GATEWAYS } from '@constants/integrations/providers/cartPandaGateway';
import { NUVEM_SHOP_GATEWAYS } from '@constants/integrations/providers/nuvemShopGateway';
import { YAMPI_GATEWAYS } from '@constants/integrations/providers/yampiGateway';
import { WOO_COMMERCE_GATEWAYS } from '@constants/integrations/providers/wooCommerceGateway';
import { LPQV_GATEWAYS } from '@constants/integrations/providers/lpqvGateways';

export const DROPSHIPPING_PROVIDERS: Array<IDropshippingProviders> = [
  {
    id: 'shopify',
    name: 'Shopify',
    icon: Shopify,
    idToVerifyStatus: 'has_shopify_credential',
    sideModal: ShopifySidemodalContent,
    identifier: EDropshippingProvider.SHOPIFY,
    lowerIdentifier: 'shopify',
    onboardingContent: OnboardingShopifyContent,
    gateways: SHOPIFY_GATEWAYS,
    generateSyncRequest: getShopifySynchronizationRequestConfig,
    generateGatewaySyncRequest: getShopifyGatewaySynchronizationRequestConfig,
    canSyncRetroactive: true,
  },
  {
    id: 'yampi',
    name: 'Yampi Loja',
    icon: Yampi,
    idToVerifyStatus: 'has_yampi_credential',
    sideModal: YampiSideModalContent,
    identifier: EDropshippingProvider.YAMPI,
    lowerIdentifier: 'yampi',
    onboardingContent: OnboardingYampiContent,
    gateways: YAMPI_GATEWAYS,
    generateSyncRequest: getYampiSynchronizationRequestConfig,
    generateGatewaySyncRequest: getYampiGatewaySynchronizationRequestConfig,
    canSyncRetroactive: true,
  },
  {
    id: 'cart_panda',
    name: 'CartPanda',
    icon: CartPanda,
    idToVerifyStatus: 'has_cart_panda_credential',
    sideModal: CartPandaSideModalContent,
    identifier: EDropshippingProvider.CART_PANDA,
    lowerIdentifier: 'cart_panda',
    onboardingContent: OnboardingCartPandaContent,
    gateways: CART_PANDA_GATEWAYS,
    generateSyncRequest: getCartPandaSynchronizationRequestConfig,
    generateGatewaySyncRequest: getCartPandaGatewaySynchronizationRequestConfig,
    canSyncRetroactive: true,
  },
  {
    id: 'nuvem_shop',
    name: 'NuvemShop',
    icon: NuvemShop,
    idToVerifyStatus: 'has_nuvem_shop_credential',
    sideModal: NuvemShopSidemodal,
    identifier: EDropshippingProvider.NUVEM_SHOP,
    lowerIdentifier: 'nuvem_shop',
    onboardingContent: OnboardingNuvemShopContent,
    gateways: NUVEM_SHOP_GATEWAYS,
    generateSyncRequest: getNuvemShopSynchronizationRequestConfig,
    generateGatewaySyncRequest: getNuvemShopGatewaySynchronizationRequestConfig,
    canSyncRetroactive: true,
  },
  {
    id: 'woocommerce',
    name: 'WooCommerce',
    icon: Woocommerce,
    idToVerifyStatus: 'has_woocommerce_credential',
    sideModal: WoocommerceSidemodal,
    identifier: EDropshippingProvider.WOO_COMMERCE,
    lowerIdentifier: 'woo_commerce',
    onboardingContent: OnboardingWoocommerceContent,
    gateways: WOO_COMMERCE_GATEWAYS,
    generateSyncRequest: getWoocommerceSynchronizationRequestConfig,
    generateGatewaySyncRequest: getWoocommerceGatewaySynchronizationRequestConfig,
    canSyncRetroactive: true,
  },
  {
    id: 'lpqv',
    name: 'LPQV',
    icon: Lpqv,
    idToVerifyStatus: 'has_lpqv_credential',
    sideModal: LPQVSideModalContent,
    identifier: EDropshippingProvider.LPQV,
    lowerIdentifier: 'lpqv',
    onboardingContent: OnboardingLPQVContent,
    gateways: LPQV_GATEWAYS,
    generateSyncRequest: getLPQVSynchronizationRequestConfig,
    generateGatewaySyncRequest: getLPQVGatewaySynchronizationRequestConfig,
    canSyncRetroactive: true,
  },
];
