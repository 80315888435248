import styled from 'styled-components/macro';

import LabelBase from '@components/common/core/DataDisplay/Label';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import ButtonBase from '@components/common/core/Inputs/Button';

export const SubmitButton = styled(ButtonBase)``;

export const Checkbox = styled(CheckboxBase)``;

export const LabelAndCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const Label = styled(LabelBase)`
  font-size: 16px;
  line-height: 100%;
  margin: unset;
  margin-top: 4px;
  cursor: pointer;
`;

export const CheckboxAndTermsReaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;
