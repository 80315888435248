import styled from 'styled-components/macro';

export const NavBarWrapper = styled.div`
  max-width: 1384px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 36px;

  & > div:first-child {
    padding-left: 40px;

    @media only screen and (max-width: 768px) {
      padding-left: 20px;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
`;

export const Container = styled.div`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray[7]};
  min-height: 100vh;

  @media only screen and (max-width: 728px) {
    padding: 0 16px 16px 16px;
  }
`;
