import React from 'react';

const ScriptContext = React.createContext<any>(null);

export const ScriptProvider: React.FC = ({ children }) => {
  return <ScriptContext.Provider value={{ teste: 1 }}>{children}</ScriptContext.Provider>;
};

export const useScript = (): any => {
  const context = React.useContext(ScriptContext);

  if (!context) {
    throw new Error('useScript must be used within ScriptProvider');
  }

  return context;
};
