import styled from 'styled-components/macro';

import SidemodalBase from '@components/common/core/DataDisplay/SideModal';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import LabelBase from '@components/common/core/DataDisplay/Label';

export const Input = styled(TextFieldBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 32px;
`;

export const Label = styled(LabelBase)``;

export const Title = styled(HeadingBase)``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
  margin-bottom: 68px;
`;

export const Sidemodal = styled(SidemodalBase)``;

export const SidemodalWrapper = styled.div`
  /* & > div:first-child {
    display: none;
  } */
`;
