import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';

import Form from '@components/common/core/Inputs/Form';
import { editKitSchema } from '@helpers/validators/dashboard/kit/editKit';
import GoBack from '@components/common/core/Utils/GoBack';
import { useEditKit } from '@helpers/hooks/pages/dashboard/kit/useEditKit';
import PageError from '@components/common/core/Utils/PageError';
import KitNameAndStatus from '../KitNameAndStatus';
import Variants from '../Variants';
import KitCost from '../KitCost';
import Submit from '../Submit';
import SyncModal from '../SyncModal';
import SyncSideModal from '../SyncSideModal';

import SkeletonLoading from '../SkeletonLoading';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const history = useHistory();
  const {
    isLoadingEditKit,
    isValidatingEditKit,
    isEditKitError,
    mutate,
    variantKit,
    updateKit,
  } = useEditKit();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(editKitSchema),
  });

  const onSubmit = React.useCallback(data => updateKit(data), [updateKit]);

  const handleGoBack = React.useCallback(() => {
    history.goBack();
  }, [history]);

  if (isLoadingEditKit || isValidatingEditKit) {
    return <SkeletonLoading />;
  }

  if (isEditKitError && !isValidatingEditKit) {
    return <PageError mutate={mutate} />;
  }

  return (
    <S.Container>
      <S.Header>
        <GoBack text={variantKit.name} onClick={handleGoBack} />
      </S.Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <KitNameAndStatus register={register} errors={errors} />
        <Variants />
        <KitCost register={register} errors={errors} />
        <Submit />
        <SyncModal />
        <SyncSideModal />
      </Form>
    </S.Container>
  );
};

export default Wrapper;
