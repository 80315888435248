import styled, { css } from 'styled-components/macro';

import { IContainerProps, IMenuContainer } from '@domain/interfaces/components/ISidebar';
import ImageBase from '@components/common/core/DataDisplay/Image';
import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const Container = styled.div<IContainerProps>`
  position: fixed;
  display: flex;
  z-index: ${({ theme }) => theme.zIndex.secondLayer};
  width: fit-content;
  background-color: black;
  top: ${({ top }) => `${top}px`};
  bottom: 0;
  left: 0;
`;

export const MainMenu = styled.div<IMenuContainer>`
  width: 49px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.sideBarColor};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;

  ${({ isSidebarExpanded }) =>
    isSidebarExpanded &&
    css`
      width: 236px;
    `}
`;

export const MainMenuItemsWrapper = styled.div<IMenuContainer>`
  margin-bottom: 36px;

  span {
    display: block;
    white-space: nowrap;
  }

  & > img {
    width: 152px;
    height: 36px;
    display: block;
    margin: 24px auto 60px auto;
    margin-left: 8px;
    transition: all 0.2s;
  }

  ${({ isSidebarExpanded }) =>
    isSidebarExpanded &&
    css`
      & > img {
        width: 152px;
        height: 36px;
        margin-bottom: 48px;
        margin-left: auto;

        @media only screen and (max-height: 700px) {
          width: 118px;
          height: 28px;
          margin-bottom: 32px;
        }
      }
    `}
`;

export const LogoProfitfy = styled(ImageBase)`
  width: 154px;
  height: 44px;
  cursor: pointer;
`;

export const SecondaryMenu = styled.div<IMenuContainer>`
  width: 49px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.sideBarSubMenuColor};
  overflow: hidden;
  padding-left: 8px;
  padding-top: 120px;
  transition: all 0.2s;
  margin-top: -42px;

  & > h4 {
    opacity: 0;
    transition: all 0.2s;
  }

  @media only screen and (max-height: 700px) {
    padding-top: 92px;
  }

  ${({ isSidebarExpanded }) =>
    isSidebarExpanded &&
    css`
      width: 228px;
      padding-left: 30px;
      padding-right: 30px;
      margin-top: 0;

      & > h4 {
        display: block;
        opacity: 1;
        margin-bottom: 16px;
      }
    `}
`;

export const SecondaryTitle = styled(HeadingBase)`
  width: 206px;
  margin-bottom: 24px;
`;
