import * as yup from 'yup';

export const announcementSchema = yup.object().shape({
  identifier: yup.string().required('Este campo é obrigatório'),
  title: yup.string().required('Este campo é obrigatório'),
  image_url: yup.string().required('Este campo é obrigatório'),
  internal_url: yup.string(),
  external_url: yup.string(),
  maximum_view_quantity: yup.string().required('Este campo é obrigatório'),
  description: yup.string(),
});
