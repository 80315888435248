import { AxiosResponse } from 'axios';

import {
  ICreateGatewayCustomerProps,
  ICreateGatewayCustomerResponse,
  IGetGatewayCustomerPromiseResponse,
  IGetGatewayCustomerProps,
  IGetGatewayCustomerResponse,
} from '@domain/interfaces/common/gatewayCustomer/IGatewayCustomer';

import { useFetch } from '@providers/Fetch/useFetch';

import { ApiService } from '@services/api';

import { USER_API_DOMAIN } from '@constants/api';

export class GatewayCustomerService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getGatewayCustomer({
    storeAliasId,
  }: IGetGatewayCustomerProps): IGetGatewayCustomerResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/gateway-customers`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      gatewayCustomer: data?.gateway_customer,
      isValidating,
      isLoading,
      error,
      mutate,
    };
  }

  public getGatewayCustomerPromise({
    storeAliasId,
  }: IGetGatewayCustomerProps): Promise<AxiosResponse<IGetGatewayCustomerPromiseResponse>> {
    return this.apiService.get<IGetGatewayCustomerPromiseResponse>(
      `/api/v1/users/stores/${storeAliasId}/gateway-customers`,
    );
  }

  public createGatewayCustomer({
    storeAliasId,
  }: ICreateGatewayCustomerProps): Promise<AxiosResponse<ICreateGatewayCustomerResponse>> {
    return this.apiService.post<ICreateGatewayCustomerResponse>(
      `/api/v1/users/stores/${storeAliasId}/gateway-customers`,
    );
  }
}

const gatewayCustomerService = new GatewayCustomerService(USER_API_DOMAIN);

export default gatewayCustomerService;
