import React from 'react';

import { IAlert } from '@domain/interfaces/components/IAlert';
import { EAlertVariant } from '@domain/enums/components/EAlert';
import { ETextWeight } from '@domain/enums/components/EText';
import { Warning } from '@profitfy/pakkun-icons';

import * as S from './styles';

const Alert: React.FC<IAlert> = ({
  variant = EAlertVariant.INFO,
  children,
  description,
  outlineIcon = false,
  ...rest
}) => {
  const getIconColor = (): string => {
    if (variant === EAlertVariant.WARNING) return '#FFAB00';

    if (variant === EAlertVariant.ERROR) return '#EA384D';

    return '#1F9AEA';
  };

  return (
    <S.Container variant={variant} {...rest}>
      <Warning outline={outlineIcon} size={24} color={getIconColor()} />

      <S.TitleAndDescriptionWrapper>
        <S.Title weight={ETextWeight.MEDIUM}>{children}</S.Title>
        {description && <S.Description>{description}</S.Description>}
      </S.TitleAndDescriptionWrapper>
    </S.Container>
  );
};

export default Alert;
