import React from 'react';

import { useToast } from '@helpers/hooks/useToast';
import { useParams } from 'react-router-dom';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';
import { IBlingCredentialOptionProps } from '@domain/interfaces/dashboard/IntegrationCenter/SideModalContent/StoreProvider/IBlingCredentialOption';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import blingAuthService from '@services/pages/dashboard/integrationCenter/ecommerce/bling/blingAuth';

import DisableIntegrationFooter from '@components/Dashboard/IntegrationCenter/Sidemodal/DisableIntegrationFooter';

import DeleteCredentialModal from '@components/Dashboard/IntegrationCenter/SideModalContent/DeleteCredentialModal';
import * as S from './styles';

const CredentialOption: React.FC<IBlingCredentialOptionProps> = ({ blingCredential }) => {
  const { mutateStore } = useStoreConfig();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();

  const [isDeletingCredential, setIsDeletingCredential] = React.useState<boolean>(false);
  const [isDeleteCredentialModalOpen, setIsDeleteCredentialModalOpen] = React.useState<boolean>(
    false,
  );

  const handleDeleteCredentialModalOpen = React.useCallback(
    () => setIsDeleteCredentialModalOpen(state => !state),
    [],
  );

  const onDeleteCredential = React.useCallback(async () => {
    setIsDeletingCredential(true);

    try {
      await blingAuthService.disableBlingCredential({
        storeAliasId,
        blingCredentialAliasId: blingCredential.alias_id,
      });

      toast.success('Credencial desativada com sucesso!');
      handleDeleteCredentialModalOpen();

      await mutateStore();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsDeletingCredential(false);
    }
  }, [mutateStore, storeAliasId, toast, blingCredential, handleDeleteCredentialModalOpen]);

  return (
    <S.Content>
      <S.CredentialDescriptionWrapper>
        <S.CredentialDescriptionTitle type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
          O que acontece se eu desativar minha integração?
        </S.CredentialDescriptionTitle>
        <S.CredentialDescriptionText>
          Ao desativar a integração,
          <strong> não será mais </strong>
          possível processar os pedidos do Bling.
        </S.CredentialDescriptionText>
      </S.CredentialDescriptionWrapper>

      <DisableIntegrationFooter
        onDeleteCredential={handleDeleteCredentialModalOpen}
        isDeletingCredential={isDeletingCredential}
      />

      <DeleteCredentialModal
        isOpen={isDeleteCredentialModalOpen}
        toggle={handleDeleteCredentialModalOpen}
        onDeleteCredential={onDeleteCredential}
        isLoading={isDeletingCredential}
      />
    </S.Content>
  );
};

export default CredentialOption;
