import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Heading = styled(HeadingBase)`
  color: #fff;
  margin-bottom: 12px;
`;

export const TagsWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray[4]};
  border-radius: 8px;
  margin-bottom: 24px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-left: auto;

  @media only screen and (max-width: 402px) {
    button > span {
      font-size: 0.75rem;
    }
  }
`;

export const AddTagButton = styled(ButtonBase)``;

export const SyncCampaignsButton = styled(ButtonBase)``;
