import React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';

import * as S from './styles';

const Trigger: React.FC<
  PopoverPrimitive.PopoverTriggerProps & React.RefAttributes<HTMLButtonElement>
> = ({ children, ...rest }) => {
  return <S.PopoverTrigger {...rest}>{children}</S.PopoverTrigger>;
};

export default Trigger;
