import React from 'react';

import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';

import { useContent } from '@helpers/hooks/pages/dashboard/automations/useContent';

import TextTypeConfig from './TextTypeConfig';
import ImageTypeConfig from './ImageTypeConfig';
import AudioTypeConfig from './AudioTypeConfig';
import VideoTypeConfig from './VideoTypeConfig';
import PDFTypeConfig from './PDFTypeConfig';
import ProductImageTypeConfig from './ProductImageTypeConfig';

const Content: React.FC = () => {
  const { selectedContentType } = useContent();

  return (
    <>
      {selectedContentType === EConfigOption.TEXT && <TextTypeConfig />}

      {selectedContentType === EConfigOption.IMAGE && <ImageTypeConfig />}

      {selectedContentType === EConfigOption.AUDIO && <AudioTypeConfig />}

      {selectedContentType === EConfigOption.VIDEO && <VideoTypeConfig />}

      {selectedContentType === EConfigOption.PDF && <PDFTypeConfig />}

      {selectedContentType === EConfigOption.PRODUCT_IMAGE && <ProductImageTypeConfig />}
    </>
  );
};

export default Content;
