import React from 'react';

import {
  IFilterProvider,
  IFilterComponentProps,
  ISelectedFilter,
} from '@domain/interfaces/components/IFilter';

const FilterContext = React.createContext<IFilterProvider | null>(null);

export const FilterProvider: React.FC<IFilterComponentProps> = ({
  filterData,
  config,
  handleFilterData,
  children,
}) => {
  const [data, setData] = React.useState<Array<ISelectedFilter>>(filterData);
  const [isAddNewFilterOpen, setIsAddNewFilterOpen] = React.useState<boolean>(false);

  const handleAddNewFilterOpen = React.useCallback(
    () => setIsAddNewFilterOpen(!isAddNewFilterOpen),
    [isAddNewFilterOpen],
  );

  const handleData = React.useCallback(newData => {
    setData(newData);
  }, []);

  const addNewData = React.useCallback((newData: ISelectedFilter) => {
    setData(previousValue => [...previousValue, newData]);
  }, []);

  const updateData = React.useCallback(
    (newData: ISelectedFilter) => {
      const mappedData = data.map(dataField => {
        if (dataField.field === newData.field) return newData;

        return dataField;
      });

      setData(mappedData);
    },
    [data],
  );

  React.useEffect(() => {
    if (data) {
      handleFilterData(data);
    }
  }, [data, handleFilterData]);

  return (
    <FilterContext.Provider
      value={{
        config,
        data,
        handleData,
        handleAddNewFilterOpen,
        isAddNewFilterOpen,
        addNewData,
        updateData,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = (): IFilterProvider => {
  const context = React.useContext(FilterContext);

  if (!context) {
    throw new Error('useFilter must be used within FilterProvider');
  }

  return context;
};
