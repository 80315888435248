import React from 'react';
import { useTheme } from 'styled-components';
import { WhatsappLogo } from 'phosphor-react';

import { IMessagePreviewProps } from '@domain/interfaces/dashboard/Automations/IMessage';

import { numberFormatter } from '@helpers/masks';

import Preview from './Preview';

import * as S from './styles';

interface IFullNodeProps {
  title: string;
  delivery_quantity: number;
  click_percentage: number;
}

const FullNode: React.FC<IFullNodeProps & IMessagePreviewProps> = ({
  title,
  click_percentage,
  delivery_quantity,
  message,
  messageContents,
}) => {
  const { colors } = useTheme();

  return (
    <>
      <S.HeaderContainer>
        <S.IconContainer>
          <WhatsappLogo size={20} color={colors.white.default} />
        </S.IconContainer>

        <S.TitleContainer>
          <S.IntegrationName>Whatsapp</S.IntegrationName>
          <S.Name>{title}</S.Name>
        </S.TitleContainer>
      </S.HeaderContainer>

      <S.SummaryContainer>
        <S.StatsContainer>
          <S.StatsNumber>{numberFormatter(delivery_quantity, 0)}</S.StatsNumber>
          <S.StatsDescription>Envios</S.StatsDescription>
        </S.StatsContainer>

        <S.StatsContainer>
          <S.StatsNumber>{numberFormatter(click_percentage, 2)}%</S.StatsNumber>
          <S.StatsDescription>Cliques</S.StatsDescription>
        </S.StatsContainer>
      </S.SummaryContainer>

      <Preview message={message} messageContents={messageContents} />
    </>
  );
};

export default FullNode;
