import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';
import ImageBase from '@components/common/core/DataDisplay/Image';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 120px 42px 0 42px;
  background-color: ${({ theme }) => theme.colors.gray[7]};
`;

export const ProfitfyLogo = styled(ImageBase)`
  width: 100%;
  max-width: 198px;
  margin-bottom: 100px;
`;

export const HeadingAndDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Heading = styled(HeadingBase)`
  font-size: 18px;
`;

export const Description = styled(TextBase)`
  margin-bottom: 40px;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BackToStoresButton = styled(ButtonBase)``;

export const SkipButton = styled.button`
  background: unset;
  padding: unset;
  border: unset;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-weight: 400;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.green.default};
`;
