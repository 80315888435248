export enum EDeniedCardType {
  PRODUCT_NAME = 'PRODUCT_NAME',
  REJECTED_PAYMENT_TOTAL_VALUE = 'REJECTED_PAYMENT_TOTAL_VALUE',
  ORDER_STATUS = 'ORDER_STATUS',
  SELL_RECOVERED = 'SELL_RECOVERED',
}

export enum EDeniedCardProductOptions {
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  NOT_EQUAL = 'NOT_EQUAL',
}

export enum EDeniedCardTotalValueOptions {
  SMALLER = 'SMALLER',
  SMALLER_EQUAL = 'SMALLER_EQUAL',
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  LARGER = 'LARGER',
  LARGER_EQUAL = 'LARGER_EQUAL',
}

export enum EDeniedCardOrderStatusOptions {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
}

export enum EDeniedCardOrderStatusPicker {
  PAID = 'PAID',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
  CHARGED_BACK = 'CHARGED_BACK',
  IN_ANALYSIS = 'IN_ANALYSIS',
  REJECTED = 'REJECTED',
}

export enum EDeniedCardSellRecoveredPicker {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

export type DeniedCardOptions<Type> = Type extends EDeniedCardType.PRODUCT_NAME
  ? EDeniedCardProductOptions
  : Type extends EDeniedCardType.REJECTED_PAYMENT_TOTAL_VALUE
  ? EDeniedCardTotalValueOptions
  : EDeniedCardOrderStatusOptions;
