import React from 'react';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { ISaveProps } from '@domain/interfaces/components/ISideModal';

import * as S from './styles';

const Save: React.FC<ISaveProps> = ({
  onDelete,
  isSubmitting,
  isDeleteButtonDisabled,
  isSaveButtonDisabled,
  saveContent,
  cancelContent,
  isDeleteLoading,
  type,
  onSave,
}) => {
  return (
    <S.Background>
      <S.SaveWrapper>
        <S.DeleteButton
          onClick={onDelete}
          variant={EButtonVariant.SECONDARY}
          isLoading={isDeleteLoading}
          disabled={isDeleteButtonDisabled}
          type="button"
        >
          {cancelContent}
        </S.DeleteButton>

        <S.ConfirmButton
          type={type}
          isLoading={isSubmitting}
          disabled={isSaveButtonDisabled}
          onClick={onSave}
        >
          {saveContent}
        </S.ConfirmButton>
      </S.SaveWrapper>
    </S.Background>
  );
};

export default Save;
