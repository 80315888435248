import React from 'react';
import { SimplePlus } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';

import { useOrders } from '@helpers/hooks/pages/dashboard/orders/useOrders';

import * as S from './styles';

const NewProduct: React.FC = () => {
  const theme = useTheme();
  const { addProductForOrder } = useOrders();

  const addNewProduct = React.useCallback(() => {
    addProductForOrder({
      quantity: 1,
      product: undefined,
      id: uuidv4(),
    });
  }, [addProductForOrder]);

  return (
    <S.Wrapper type="button" onClick={addNewProduct} id="new_product">
      <S.Text>Adicionar produto</S.Text>

      <S.PlusIconWrapper>
        <SimplePlus size={20} color={theme.colors.gray[1]} />
      </S.PlusIconWrapper>
    </S.Wrapper>
  );
};

export default NewProduct;
