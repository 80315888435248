import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import ButtonBase from '@components/common/core/Inputs/Button';

export const SubmitButton = styled(ButtonBase)`
  width: 100%;
`;

export const ExpirationDateAndSecurityCodeWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

export const Input = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin: unset;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  & > form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export const Title = styled(HeadingBase)``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Modal = styled(ModalBase)`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 524px;
  width: 100%;
  box-sizing: border-box;
`;
