import styled, { css } from 'styled-components/macro';

import { EFilterVariant, EFilterType, EFilterMode } from '@domain/enums/components/EFilter';
import { IButtonWrapperProps, IPopoverContentProps } from '@domain/interfaces/components/IFilter';

import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';
import TextBase from '@components/common/core/DataDisplay/Text';

export const NoFilterFound = styled(TextBase)`
  font-size: 0.875rem;
`;

export const SearchFilterInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  background-color: unset;
  border: none;
  color: ${({ theme }) => theme.colors.font};
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;

  &::placeholder {
    color: ${({ theme }) => theme.colors.font};
    opacity: 0.6;
  }

  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.font};
    opacity: 0.6;
  }

  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.font};
    opacity: 0.6;
  }

  &:focus {
    outline: none;
  }
`;

export const SearchFilterWrapper = styled.div`
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray[4]};
  padding: 12px;
  box-sizing: border-box;
`;

export const FilterButton = styled.button`
  background-color: unset;
  border: unset;
  padding: unset;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray[1]};
  font-weight: 500;
  line-height: 120%;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.green.default};
  }

  &:active {
    color: ${({ theme }) => theme.colors.green.dark};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.gray[3]};
  }
`;

export const Filters = styled.div`
  padding: 16px 24px 20px 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const FiltersWrapper = styled.div``;

export const PopoverContent = styled(CustomPopoverBase.Content)<IPopoverContentProps>`
  background-color: ${({ theme }) => theme.colors.gray[6]};
  width: 236px;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

  ${({ filterType }) =>
    filterType === EFilterType.DATE_RANGE_PICKER &&
    css`
      width: fit-content;
      overflow: unset;
    `}
`;

export const ButtonWrapper = styled.div<IButtonWrapperProps & { mode: EFilterMode }>`
  height: 42px;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  line-height: 120%;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.font};
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 12px;
  border-radius: 5px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  ${({ variant }) =>
    variant === EFilterVariant.MEDIUM &&
    css`
      height: 32px;
      padding: 10px 20px;
      font-size: 0.875rem;
    `}

  ${({ mode, theme }) =>
    mode === EFilterMode.AUTOMATION &&
    css`
      background-color: ${theme.colors.background[2]};
      border: unset;
    `}
`;

export const Popover = styled(CustomPopoverBase)``;
