import React from 'react';
import * as HoverCardPrimitive from '@radix-ui/react-hover-card';

import * as S from './styles';

const Content: React.FC<
  HoverCardPrimitive.HoverCardContentProps & React.RefAttributes<HTMLDivElement>
> = ({ children, ...rest }) => {
  return <S.HoverCardContent {...rest}>{children}</S.HoverCardContent>;
};

export default Content;
