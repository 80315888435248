import { EGateway } from '@domain/enums/common/EGateway';

export const SHOPIFY_GATEWAYS = [
  EGateway.ZOUTI,
  EGateway.MERCADO_PAGO,
  EGateway.UPNID,
  EGateway.PAG_SEGURO,
  EGateway.PAGARME,
  EGateway.HUB_SALE,
  EGateway.CART_PANDA_PAY,
];
