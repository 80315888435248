import React from 'react';

import { currencyToNumber } from '@helpers/masks';

import { useForm } from 'react-hook-form';
import { Box } from '@profitfy/pakkun-icons';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { EOrderStatus } from '@domain/enums/common/EOrder';
import { ISidemodalProps } from '@domain/interfaces/dashboard/Feed/IFeed';

import { useOtherGateway } from '@helpers/hooks/pages/dashboard/feed/useOtherGateway';
import { useDate } from '@helpers/hooks/useDate';

import Text from '@components/common/core/DataDisplay/Text';
import Form from '@components/common/core/Inputs/Form';
import SideModalSave from '@components/common/core/Utils/SideModalSave';
import DefaultEditForm from '@components/Dashboard/Orders/Body/Row/EditOrdersModal/DefaultEditForm';
import ChargebackEditForm from '@components/Dashboard/Orders/Body/Row/EditOrdersModal/ChargebackEditForm';
import RefundedEditForm from '@components/Dashboard/Orders/Body/Row/EditOrdersModal/RefundedEditForm';
import CanceledEditForm from '@components/Dashboard/Orders/Body/Row/EditOrdersModal/CanceledEditForm';

import * as S from './styles';

const Sidemodal: React.FC<ISidemodalProps> = ({ isOpen, toggle, order }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });
  const { solveOtherGatewayOrder, isSolvingOrder } = useOtherGateway();
  const { utcToZonedTime } = useDate();

  const [selectedOrderStatus, setSelectedOrderStatus] = React.useState<EOrderStatus>(
    EOrderStatus.PAID,
  );
  const [date, setDate] = React.useState<Date>(utcToZonedTime(order?.provider_created_at));

  const onSubmit = React.useCallback(
    data => {
      const isChargedback = data.status === EOrderStatus.CHARGED_BACK;
      const isRefunded = data.status === EOrderStatus.REFUNDED;
      const isPaid = data.status === EOrderStatus.PAID;

      const hasRefundedAt = isChargedback || isRefunded;

      const gatewayAmount = currencyToNumber(data?.gateway_amount || '0');
      const gatewayFeeAmount = currencyToNumber(data?.gateway_fee_amount || '0');
      const cogsAmount = currencyToNumber(data?.cogs_amount || '0');
      const refundedAmount = currencyToNumber(data?.refunded_amount || '0');
      const shippingCostOwnerAmount = currencyToNumber(data?.shipping_cost_owner_amount || '0');
      const refundedAt = hasRefundedAt ? utcToZonedTime(date).toISOString() : undefined;
      const approvedAt = isPaid ? utcToZonedTime(date).toISOString() : undefined;

      const parsedOrder = {
        ...order,
        status: data.status,
        type: data.type,
        gateway_amount: gatewayAmount,
        gateway_fee_amount: gatewayFeeAmount,
        cogs_amount: cogsAmount,
        refunded_amount: refundedAmount,
        shipping_cost_owner_amount: shippingCostOwnerAmount,
        refunded_at: refundedAt,
        approved_at: approvedAt,
        is_resolved_by_feed: isChargedback,
      };

      solveOtherGatewayOrder(parsedOrder);
    },
    [date, order, solveOtherGatewayOrder, utcToZonedTime],
  );

  const handleOrderStatus = React.useCallback(event => {
    const orderStatus = event.target.value;

    setSelectedOrderStatus(orderStatus);
  }, []);

  const isPaidForm =
    selectedOrderStatus === EOrderStatus.PAID ||
    selectedOrderStatus === EOrderStatus.NO_INFORMATION;

  const isChargedBackForm = selectedOrderStatus === EOrderStatus.CHARGED_BACK;
  const isRefundedForm = selectedOrderStatus === EOrderStatus.REFUNDED;
  const isCanceledForm = selectedOrderStatus === EOrderStatus.CANCELED;

  return (
    <S.SideModal isOpen={isOpen} toggle={toggle}>
      <S.Header>
        <Box size={24} />
        <S.Title
          type={EHeadingSize.H5}
          fontWeight={EHeadingWeight.REGULAR}
        >{`Editar ${order?.name}`}</S.Title>
      </S.Header>

      <S.ProductsWrapper>
        <S.Text>Produtos</S.Text>
        <S.ProductsList>
          {order?.line_items.map((lineItem: any) => (
            <S.Product key={lineItem.alias_id}>{lineItem.product?.name}</S.Product>
          ))}
        </S.ProductsList>
      </S.ProductsWrapper>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label>Status do pedido</S.Label>
          <S.Select
            {...register('status')}
            name="status"
            defaultValue={selectedOrderStatus}
            onChange={handleOrderStatus}
          >
            <S.Option disabled value="" />
            <S.Option value={EOrderStatus.PAID}>Aprovado</S.Option>
            <S.Option value={EOrderStatus.CHARGED_BACK}>Chargeback</S.Option>
            <S.Option value={EOrderStatus.REFUNDED}>Devolvido</S.Option>
            <S.Option value={EOrderStatus.CANCELED}>Cancelado</S.Option>
          </S.Select>
          {errors.status && <Text isErrorFeedback>{errors.status.message}</Text>}
        </S.InputGroup>

        {isPaidForm ? (
          <DefaultEditForm
            order={order}
            errors={errors}
            register={register}
            setDate={setDate}
            date={date}
          />
        ) : (
          <></>
        )}
        {isChargedBackForm ? (
          <ChargebackEditForm
            order={order}
            register={register}
            date={date}
            setDate={setDate}
            errors={errors}
          />
        ) : (
          <></>
        )}
        {isRefundedForm ? (
          <RefundedEditForm
            order={order}
            errors={errors}
            register={register}
            setDate={setDate}
            date={date}
          />
        ) : (
          <></>
        )}
        {isCanceledForm ? (
          <CanceledEditForm order={order} errors={errors} register={register} />
        ) : (
          <></>
        )}

        <SideModalSave
          onCancel={toggle}
          type="submit"
          successButtonText="Salvar alterações"
          cancelButtonText="Cancelar"
          isLoading={isSolvingOrder}
          isDisabled={isSolvingOrder}
        />
      </Form>
    </S.SideModal>
  );
};

export default Sidemodal;
