import React from 'react';

import { FacebookCredentialsProvider } from '@helpers/hooks/common/integrations/marketing/facebook/useFacebookCredentials';

import { IMarketingContentProps } from '@domain/interfaces/dashboard/IntegrationCenter/Marketing/IMarketingContent';

import Wrapper from './Wrapper';

const Facebook: React.FC<IMarketingContentProps> = ({ callback }) => {
  return (
    <FacebookCredentialsProvider>
      <Wrapper callback={callback} />
    </FacebookCredentialsProvider>
  );
};

export default Facebook;
