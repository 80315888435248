import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';
import { CreditCard } from 'phosphor-react';

export const REFUSED_CREDIT_CARD = [
  {
    id: 'refused-credit-card-first-condition',
    title: 'Cartão Recusado (1ª condição)',
    type: 'REJECTED_PAYMENT',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: CreditCard,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá, {{first_name}}!\n\nSeu pagamento não foi finalizado, pois seu cartão foi recusado. 😥\n\nPara garantir sua compra, clique no link abaixo e escolha outra forma de pagamento: 🛒\n\n{{checkout_link}}\n\nE, se preferir cartão de crédito, recomendamos que revise todas as informações. 🤩\n\n',
      },
    ],
  },
  {
    id: 'refused-credit-card-second-condition',
    title: 'Cartão Recusado (2ª condição)',
    type: 'REJECTED_PAYMENT',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: CreditCard,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá, {{first_name}}! 😃\n\nTemos uma boa notícia: aproveite a promoção que está rolando agora!\n\nCorra no seu carrinho para conferir, porque é por tempo limitado! 🤩\n\nClique no link abaixo e aproveite essa oferta especial: 🛒\n\n{{checkout_link}}',
      },
    ],
  },
  {
    id: 'refused-credit-card-third-condition',
    title: 'Cartão Recusado (3ª condição)',
    type: 'REJECTED_PAYMENT',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: CreditCard,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá {{first_name}}!\n\nO produto que você gostou está quase esgotando! 😪\n\nClique no link abaixo para finalizar a compra: 🛒\n\n{{checkout_link}}',
      },
    ],
  },
];
