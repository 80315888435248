import styled, { css } from 'styled-components/macro';

import { IAdProvidersSelectedQuantityProps } from '@domain/interfaces/downgrade/IDowngrade';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';

export const Tooltip = styled(CustomTooltipBase)``;

export const TriggerTooltipContentText = styled(TextBase)``;

export const TriggerIconWrapper = styled.div<{ color: string }>`
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background-color: ${({ color }) =>
    css`
      ${color}
    `};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectedQuantity = styled(TextBase)<IAdProvidersSelectedQuantityProps>`
  display: block;
  text-align: right;
  color: ${({ theme }) => theme.colors.gray[2]};

  & > mark {
    background: unset;
    color: ${({ theme, hasExceededQuantity }) =>
      hasExceededQuantity ? theme.colors.danger.default : theme.colors.success.default};
  }
`;

export const ProviderName = styled(TextBase)`
  font-size: 0.875rem;
`;

export const Checkbox = styled(CheckboxBase)``;

export const Option = styled(LabelBase)`
  margin: unset;
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
`;

export const ProviderOptionGroup = styled(InputGroupBase)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: unset;
`;

export const AdProvidersOptionsWrapper = styled.div`
  padding: 24px 32px;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border: 1px solid ${({ theme }) => theme.colors.background[2]};

  & > div {
    padding: 16px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.background[2]};
  }

  & > div:first-child {
    padding-top: unset;
  }

  & > div:last-child {
    padding-bottom: unset;
    border-bottom: unset;
  }
`;

export const Description = styled(TextBase)`
  margin-bottom: 12px;
  display: inline-block;
`;

export const Title = styled(HeadingBase)`
  display: block;
  margin-bottom: 32px;
`;

export const Wrapper = styled.div`
  max-width: 508px;
  width: 100%;
  margin: 0 auto;
  margin-top: 72px;
`;
