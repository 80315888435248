import React from 'react';
import Table from './Table';
import * as S from './styles';

const FacebookBreakEven: React.FC = () => {
  return (
    <S.Container>
      <S.Title>Facebook Ads - Break even de uma venda</S.Title>
      <Table />
    </S.Container>
  );
};

export default FacebookBreakEven;
