import { ApiService } from '@services/api';
import { LONG_API_DOMAIN } from '@constants/api';
import {
  ISyncOrdersProps,
  ISyncProductsProps,
  IDeleteDsersCredentialProps,
} from '@domain/interfaces/dashboard/IntegrationCenter/Erp/Bling/IBlingSync';

export class DsersSyncService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public syncProducts({ storeAliasId, startDate, endDate }: ISyncProductsProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/dsers/products/sync?start_date=${startDate}&end_date=${endDate}`,
    );
  }

  public syncOrders({ storeAliasId, startDate, endDate }: ISyncOrdersProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/dsers/orders/sync?start_date=${startDate}&end_date=${endDate}`,
    );
  }

  public deleteDsersSyncs({ storeAliasId }: IDeleteDsersCredentialProps): Promise<any> {
    return this.apiService.delete(`/api/v1/users/stores/${storeAliasId}/dsers/synchronization`);
  }
}

const dsersSyncService = new DsersSyncService(LONG_API_DOMAIN);

export default dsersSyncService;
