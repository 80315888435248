import React from 'react';

import { ECreateMessageStep } from '@domain/enums/dashboard/automations/EMessage';

import { useCanvasMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessageSettings';

import ConfigMessageContent from './ConfigMessageContent';
import SelectTemplateContent from './SelectTemplateContent';

import * as S from './styles';

interface INewMessageContentProps {
  closeModal: () => void;
}

const NewMessageContent: React.FC<INewMessageContentProps> = ({ closeModal }) => {
  const { createNewMessageStep } = useCanvasMessageSettings();

  const getContent = {
    [ECreateMessageStep.SELECT_MESSAGE_TEMPLATE]: <SelectTemplateContent />,
    [ECreateMessageStep.CONFIG_MESSAGE]: <ConfigMessageContent />,
  };

  return <S.Wrapper>{getContent[createNewMessageStep]}</S.Wrapper>;
};

export default NewMessageContent;
