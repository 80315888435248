import styled from 'styled-components/macro';

import SideModalBase from '@components/common/core/DataDisplay/SideModal';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import ButtonBase from '@components/common/core/Inputs/Button';
import AlertBase from '@components/common/core/DataDisplay/Alert';

export const Alert = styled(AlertBase)`
  margin-bottom: 24px;

  & > div > span {
    margin-top: -2px;
  }
`;

export const PaginateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
`;

export const TagsTitle = styled(HeadingBase)`
  margin-bottom: 12px;
  font-size: 1rem;
`;

export const TagsWrapper = styled.div``;

export const HorizontalRule = styled.hr`
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.gray[4]};
  margin: 24px 0;
`;

export const CreateTagButton = styled(ButtonBase)`
  width: 100%;

  & > span {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const Input = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const ExampleText = styled(TextBase)`
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[3]};
  display: block;
  margin-bottom: 24px;
`;

export const DescriptionText = styled(TextBase)`
  line-height: 120%;
  display: block;
  margin-bottom: 12px;
`;

export const DescriptionTitle = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const Body = styled.div``;

export const Title = styled(HeadingBase)``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
  padding-bottom: 24px;
  margin-bottom: 40px;
`;

export const SideModal = styled(SideModalBase)``;
