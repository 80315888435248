import React from 'react';

import { EButtonVariant } from '@domain/enums/components/EButton';

import { yupResolver } from '@hookform/resolvers/yup';

import { useForm } from 'react-hook-form';
import { useDate } from '@helpers/hooks/useDate';
import { useAnnouncement } from '@helpers/hooks/pages/dashboard/admin/useAnnouncement';

import { announcementSchema } from '@helpers/validators/dashboard/adminPanel/announcement';

import Form from '@components/common/core/Inputs/Form';
import Draft from '@components/common/core/Inputs/Draft';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const Announcement: React.FC = () => {
  const { utcToZonedTime, format } = useDate();

  const {
    createAnnouncement,
    isCreatingAnnouncement,
    handlePreviewData,
    openPreviewAnnouncement,
  } = useAnnouncement();

  const [draftContent, setDraftContent] = React.useState<string>('');
  const [period, setPeriod] = React.useState({
    startDate: utcToZonedTime(new Date()),
    endDate: utcToZonedTime(new Date()),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    watch,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(announcementSchema),
  });

  const identifierInputRegister = register('identifier');
  const titleInputRegister = register('title');
  const imageURLInputRegister = register('image_url');
  const urlInputRegister = register('internal_url');
  const externalUrlInputRegister = register('external_url');
  const maxQuantityOfViewsInputRegister = register('maximum_view_quantity');
  const descriptionInputRegister = register('description', { value: draftContent });

  watch();

  const onPreviewAnnouncementButtonClick = React.useCallback(() => {
    const inputsValues = getValues();

    handlePreviewData(inputsValues);
    openPreviewAnnouncement();
  }, [getValues, handlePreviewData, openPreviewAnnouncement]);

  const onSubmit = React.useCallback(
    formData => {
      const start_date = format(period.startDate, 'yyyy-MM-dd');
      const end_date = format(period.endDate, 'yyyy-MM-dd');

      const data = {
        identifier: formData.identifier,
        title: formData.title,
        image_url: formData.image_url,
        maximum_view_quantity: Number(formData.maximum_view_quantity),
        start_date,
        end_date,
      };

      if (formData.description) {
        Object.assign(data, { description: formData.description });
      }

      if (formData.internal_url) {
        Object.assign(data, { internal_url: formData.internal_url });
      }

      if (formData.external_url) {
        Object.assign(data, { external_url: formData.external_url });
      }

      createAnnouncement(data);
    },
    [period, format, createAnnouncement],
  );

  const handleDraft = React.useCallback(content => {
    setDraftContent(content);
  }, []);

  const isIdentifierError = Boolean(errors.identifier);
  const isTitleError = Boolean(errors.title);
  const isImageURLError = Boolean(errors.image_url);
  const isMaximumViewQuantityError = Boolean(errors.maximum_view_quantity);

  return (
    <S.Wrapper>
      <S.Heading>Novo anúncio</S.Heading>

      <S.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputsGroupWrapper>
            <S.InputGroup>
              <S.Label htmlFor="identifier">Identificador</S.Label>
              <S.Input
                {...identifierInputRegister}
                id="identifier"
                type="text"
                placeholder="Ex: [ANÚNCIO-X]"
                isError={isIdentifierError}
              />
              {isIdentifierError && <Text isErrorFeedback>{errors.identifier.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label htmlFor="title">Titulo</S.Label>
              <S.Input
                {...titleInputRegister}
                id="title"
                type="text"
                placeholder="Insira o titulo"
                isError={isTitleError}
              />
              {isTitleError && <Text isErrorFeedback>{errors.title.message}</Text>}
            </S.InputGroup>
          </S.InputsGroupWrapper>

          <S.InputsGroupWrapper>
            <S.InputGroup>
              <S.Label htmlFor="image_url">URL da Imagem</S.Label>
              <S.Input
                {...imageURLInputRegister}
                id="image_url"
                type="text"
                placeholder="Insira a url da imagem"
                isError={isImageURLError}
              />
              {isImageURLError && <Text isErrorFeedback>{errors.image_url.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label htmlFor="internal_url">URL interna (opcional)</S.Label>

              <S.Input
                {...urlInputRegister}
                id="internal_url"
                type="text"
                placeholder="Insira a URL interna"
              />
            </S.InputGroup>
          </S.InputsGroupWrapper>

          <S.InputsGroupWrapper>
            <S.InputGroup>
              <S.Label htmlFor="external_url">URL externa (opcional)</S.Label>
              <S.Input
                {...externalUrlInputRegister}
                id="external_url"
                type="text"
                placeholder="Insira a URL Externa"
              />
            </S.InputGroup>

            <S.InputGroup>
              <S.Label htmlFor="maximum_view_quantity">Quantidade máxima de visualizações</S.Label>
              <S.Input
                {...maxQuantityOfViewsInputRegister}
                id="maximum_view_quantity"
                type="text"
                placeholder="Insira a quantidade máxima"
                isError={isMaximumViewQuantityError}
              />
              {isMaximumViewQuantityError && (
                <Text isErrorFeedback>{errors.maximum_view_quantity.message}</Text>
              )}
            </S.InputGroup>
          </S.InputsGroupWrapper>

          <S.CustomDatePicker period={period} setPeriod={setPeriod} />

          <S.InputGroup>
            <S.Label>Descrição (opcional)</S.Label>
            <Draft
              {...descriptionInputRegister}
              placeholder="Insira a descrição"
              handleDraft={handleDraft}
            />
          </S.InputGroup>

          <S.ButtonsWrapper>
            <S.PreviewAnnouncementButton
              onClick={onPreviewAnnouncementButtonClick}
              type="button"
              variant={EButtonVariant.SECONDARY_BUTTON}
            >
              Pré-visualizar anúncio
            </S.PreviewAnnouncementButton>

            <S.CreateAnnouncementButton
              type="submit"
              isLoading={isCreatingAnnouncement}
              disabled={isCreatingAnnouncement}
            >
              Criar novo anúncio
            </S.CreateAnnouncementButton>
          </S.ButtonsWrapper>
        </Form>
      </S.Body>
    </S.Wrapper>
  );
};

export default Announcement;
