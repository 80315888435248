import React from 'react';

import funnelChart from '@assets/components/FunnelChart/funnel-chart.svg';
import Image from '@components/common/core/DataDisplay/Image';

import * as S from './styles';

const FunnelChart: React.FC = () => {
  return (
    <S.ChartWrapper>
      <Image src={funnelChart} alt="Profitfy.me Funnel Chart" />
    </S.ChartWrapper>
  );
};

export default React.memo(FunnelChart);
