import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';
import { Barcode } from 'phosphor-react';

export const BOLETO_FREE = [
  {
    id: 'boleto-free-first-condition',
    title: 'Recuperação de Boleto',
    type: 'BOLETO',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Barcode,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá {{first_name}}! 😀\n\nSeu boleto está pronto e aguardando seu pagamento. Não perca a chance de garantir esses itens exclusivos! 💰🛒\n\n⚠️ Se ainda não tiver efetuado o pagamento, gere um novo boleto pelo link abaixo:',
      },
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content: '{{boleto_url}}',
      },
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content: 'Observação: Em caso de pagamento realizado, por favor, ignore esta mensagem.',
      },
    ],
  },
];
