import React from 'react';
import { useHistory } from 'react-router-dom';

import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { EStatus } from '@domain/enums/subscription/ESubscription';
import { IDowngradeContext } from '@domain/interfaces/downgrade/IDowngrade';
import { IAdSenseCredential } from '@domain/interfaces/dashboard/AdSense/IAdSenseCredential';
import { IUser } from '@domain/interfaces/services/group/IUserStore';

import { useSubscription } from '@helpers/hooks/common/store/subscription/useSubscription';
import { useToast } from '@helpers/hooks/useToast';
import { useDate } from '@helpers/hooks/useDate';

import { ALL_PLANS } from '@constants/plans';

import userStoreService from '@services/pages/dashboard/accessManagement/userStore';
import adSenseCredentialService from '@services/pages/dashboard/adSense/adSenseCredential';
import storesService from '@services/pages/stores/stores';
import subscriptionService from '@services/common/subscription/subscription';
import automationsService from '@services/pages/dashboard/automations/automations';
import { IWorkflows } from '@domain/interfaces/common/automations/IWorkflows';

const DowngradeContext = React.createContext<IDowngradeContext | null>(null);

export const DowngradeProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const { toast } = useToast();
  const { subDays } = useDate();
  const {
    selectedPlanIdentifier,
    createFreeSubscription,
    updateFreeSubscription,
  } = useSubscription();

  const foundPlan = ALL_PLANS.find(
    plan =>
      plan.identifier === selectedPlanIdentifier ||
      plan.quarterIdentifier === selectedPlanIdentifier ||
      plan.semiAnualIdentifier === selectedPlanIdentifier,
  );

  const maxAdProviders = foundPlan?.marketingProvider || 0;
  const maxInvitedMembers = foundPlan?.members || 0;
  const maxFacebookProfiles = foundPlan?.facebookProfile || 0;
  const maxWorkflows = foundPlan?.benefits.maxWorkflows || 0;

  const [isDowngradeModalOpen, setIsDowngradeModalOpen] = React.useState<boolean>(false);
  const [adProviders, setAdProviders] = React.useState<Array<EAdSenseProvider>>([]);
  const [selectedAdProviders, setSelectedAdProviders] = React.useState<Array<EAdSenseProvider>>([]);
  const [invitedMembers, setInvitedMembers] = React.useState<Array<IUser>>([]);
  const [selectedInvitedMembers, setSelectedInvitedMembers] = React.useState<Array<IUser>>([]);
  const [facebookProfiles, setFacebookProfiles] = React.useState<Array<IAdSenseCredential>>([]);
  const [selectedFacebookProfiles, setSelectedFacebookProfiles] = React.useState<
    Array<IAdSenseCredential>
  >([]);
  const [storeWorkflows, setStoreWorkflows] = React.useState<Array<IWorkflows>>([]);
  const [selectedStoreWorkflows, setSelectedStoreWorkflows] = React.useState<Array<IWorkflows>>([]);
  const [isVerifyingDowngrade, setIsVerifyingDowngrade] = React.useState<boolean>(false);
  const [isDowngradingSubscription, setIsDowngradingSubscription] = React.useState<boolean>(false);
  const [isLoadingSubscription, setIsLoadingSubscription] = React.useState<boolean>(false);

  const selectedPlan =
    (ALL_PLANS.find(
      plan =>
        plan.identifier === selectedPlanIdentifier ||
        plan.quarterIdentifier === selectedPlanIdentifier ||
        plan.semiAnualIdentifier === selectedPlanIdentifier,
    ) as typeof ALL_PLANS[number]) || undefined;

  const selectedPlanMarketingProviders =
    selectedPlan?.marketingProvider === 0 ? Infinity : selectedPlan?.marketingProvider;
  const selectedPlanInvitedMembers = selectedPlan?.members === 0 ? Infinity : selectedPlan?.members;
  const selectedPlanFacebookProfiles =
    selectedPlan?.facebookProfile === 0 ? Infinity : selectedPlan?.facebookProfile;
  const selectedPlanStoreWorkflows =
    selectedPlan?.benefits.maxWorkflows === undefined
      ? Infinity
      : selectedPlan?.benefits.maxWorkflows;

  const hasToDowngradeAdsProviders = adProviders.length > (selectedPlanMarketingProviders || 0);
  const hasToDowngradeInvitedMembers = invitedMembers.length > (selectedPlanInvitedMembers || 0);
  const hasToDowngradeFacebookProfiles =
    facebookProfiles.length > (selectedPlanFacebookProfiles || 0);
  const hasToDowngradeStoreWorkflows = storeWorkflows.length > (selectedPlanStoreWorkflows || 0);

  const handleDowngradingSubscription = React.useCallback(
    state => setIsDowngradingSubscription(state),
    [],
  );

  const handleDowngradeModalOpen = React.useCallback(
    () => setIsDowngradeModalOpen(state => !state),
    [],
  );

  const handleSelectedAdProviders = React.useCallback(
    providers => setSelectedAdProviders(providers),
    [],
  );

  const handleSelectedInvitedMembers = React.useCallback(
    members => setSelectedInvitedMembers(members),
    [],
  );

  const handleSelectedFacebookProfiles = React.useCallback(
    profiles => setSelectedFacebookProfiles(profiles),
    [],
  );

  const handleSelectedStoreWorkflows = React.useCallback(
    workflows => setSelectedStoreWorkflows(workflows),
    [],
  );

  const handleVerifyingDowngrade = React.useCallback(state => setIsVerifyingDowngrade(state), []);

  const loadStoreIntegrations = React.useCallback(async (storeAliasId: string | undefined) => {
    const { data } = await storesService.getStorePromise({ storeAliasId });

    return data.integrations;
  }, []);

  const loadInvitedMembers = React.useCallback(async (storeAliasId: string | undefined) => {
    const { data } = await userStoreService.listUsersStorePromise({ storeAliasId });

    return data.users;
  }, []);

  const loadFacebookProfiles = React.useCallback(async (storeAliasId: string | undefined) => {
    const { data } = await adSenseCredentialService.listAdSenseCredentialsPromise({
      storeAliasId,
      page: 0,
      filter: {
        provider: EAdSenseProvider.FACEBOOK,
      },
    });

    return data.ad_sense_credentials;
  }, []);

  const loadWorkflows = React.useCallback(
    async (storeAliasId: string | undefined) => {
      const { data } = await automationsService.listWorkflowsPromise({
        storeAliasId,
        endDate: new Date(),
        startDate: subDays(new Date(), 7),
      });

      return data.workflows_with_metrics;
    },
    [subDays],
  );

  const verifyDowngrade = React.useCallback(
    async (storeAliasId: string | undefined) => {
      setAdProviders([]);
      setInvitedMembers([]);
      setFacebookProfiles([]);
      setStoreWorkflows([]);
      setSelectedAdProviders([]);
      setSelectedInvitedMembers([]);
      setSelectedFacebookProfiles([]);
      setSelectedStoreWorkflows([]);

      const [storeIntegrations, users, adSenseCredentials, workflows] = await Promise.all([
        loadStoreIntegrations(storeAliasId),
        loadInvitedMembers(storeAliasId),
        loadFacebookProfiles(storeAliasId),
        loadWorkflows(storeAliasId),
      ]);

      const storeMarketingProviders = storeIntegrations.marketing.filter(
        integration => integration.is_active,
      );
      const mappedStoreMarketingProvider = storeMarketingProviders.map(
        provider => provider.name,
      ) as Array<EAdSenseProvider>;

      if (mappedStoreMarketingProvider.length) setAdProviders(mappedStoreMarketingProvider);

      if (users.length) setInvitedMembers(users);

      if (adSenseCredentials.length) setFacebookProfiles(adSenseCredentials);

      if (workflows.workflows.length) setStoreWorkflows(workflows.workflows);

      return [mappedStoreMarketingProvider, users, adSenseCredentials, workflows.workflows];
    },
    [loadFacebookProfiles, loadStoreIntegrations, loadInvitedMembers, loadWorkflows],
  );

  const downgradeAdProviders = React.useCallback(
    async (storeAliasId: string | undefined) => {
      const filteredAdProviders = adProviders.filter(
        provider => !selectedAdProviders.includes(provider),
      );

      const adSenseCredentialsPromises = filteredAdProviders.map(provider => {
        return adSenseCredentialService.listAdSenseCredentialsPromise({
          storeAliasId,
          page: 1,
          filter: {
            provider,
          },
        });
      });

      const adSenseCredentialsResponses = await Promise.all(adSenseCredentialsPromises);

      const adSenseCredentialsIds: Array<string> = [];

      adSenseCredentialsResponses.forEach(response => {
        response.data.ad_sense_credentials.forEach(adSenseCredential =>
          adSenseCredentialsIds.push(adSenseCredential.id),
        );
      });

      await adSenseCredentialService.blockAdSenseCredentials({
        storeAliasId,
        data: {
          ad_sense_credential_ids: adSenseCredentialsIds,
        },
      });

      setSelectedAdProviders([]);
      setAdProviders([]);
    },
    [adProviders, selectedAdProviders],
  );

  const downgradeInvitedMembers = React.useCallback(
    async (storeAliasId: string | undefined) => {
      const filteredInvitedMembers = invitedMembers.filter(member => {
        const foundInvitedMember = selectedInvitedMembers.find(
          selectedMember => selectedMember.user.id === member.user.id,
        );

        if (foundInvitedMember) return false;

        return true;
      });

      const userIds = filteredInvitedMembers.map(invitedMember => invitedMember.user.id);

      await userStoreService.deleteUsersStorePromise({
        storeAliasId,
        data: {
          user_ids: userIds,
        },
      });

      setSelectedInvitedMembers([]);
      setInvitedMembers([]);
    },
    [selectedInvitedMembers, invitedMembers],
  );

  const downgradeFacebookCredentials = React.useCallback(
    async (storeAliasId: string | undefined) => {
      const filteredFacebookProfiles = facebookProfiles.filter(profile => {
        const foundInvitedMember = selectedFacebookProfiles.find(
          selectedProfile => selectedProfile.alias_id === profile.alias_id,
        );

        if (foundInvitedMember) return false;

        return true;
      });

      const adSenseCredentialsIds = filteredFacebookProfiles.map(credential => credential.id);

      await adSenseCredentialService.blockAdSenseCredentials({
        storeAliasId,
        data: {
          ad_sense_credential_ids: adSenseCredentialsIds,
        },
      });

      setSelectedFacebookProfiles([]);
      setFacebookProfiles([]);
    },
    [selectedFacebookProfiles, facebookProfiles],
  );

  const downgradeStoreWorkflows = React.useCallback(
    async (storeAliasId: string | undefined) => {
      const filteredStoreWorkflows = storeWorkflows.filter(workflow => {
        const foundWorkflow = selectedStoreWorkflows.find(
          selectedWorkflow => selectedWorkflow.workflow.alias_id === workflow.workflow.alias_id,
        );

        if (foundWorkflow) return false;

        return true;
      });

      const workflowsPromises = filteredStoreWorkflows.map(workflow => {
        return automationsService.deleteWorkflow({
          storeAliasId,
          workflowAliasId: workflow.workflow.alias_id,
        });
      });

      await Promise.all(workflowsPromises);
    },
    [selectedStoreWorkflows, storeWorkflows],
  );

  const downgradeSubscription = React.useCallback(
    async (storeAliasId: string | undefined) => {
      try {
        if (hasToDowngradeAdsProviders) {
          await downgradeAdProviders(storeAliasId);
        }

        if (hasToDowngradeInvitedMembers) {
          await downgradeInvitedMembers(storeAliasId);
        }

        if (hasToDowngradeFacebookProfiles) {
          await downgradeFacebookCredentials(storeAliasId);
        }

        if (hasToDowngradeStoreWorkflows) {
          await downgradeStoreWorkflows(storeAliasId);
        }
      } catch (error: any) {
        console.log(error);
      }
    },
    [
      hasToDowngradeAdsProviders,
      hasToDowngradeInvitedMembers,
      hasToDowngradeFacebookProfiles,
      downgradeAdProviders,
      downgradeInvitedMembers,
      downgradeFacebookCredentials,
      downgradeStoreWorkflows,
      hasToDowngradeStoreWorkflows,
    ],
  );

  const handleSubscribe = React.useCallback(
    async (storeAliasId: string | undefined) => {
      const planIdentifier = selectedPlan?.identifier;

      if (!planIdentifier?.includes('free')) {
        history.push(`/${storeAliasId}/subscription/address`);

        return;
      }

      setIsLoadingSubscription(true);

      try {
        const { data } = await subscriptionService.getSubscriptionPromise({ storeAliasId });

        const { subscription } = data;

        if (
          subscription?.status === EStatus.TRIAL_ENDED ||
          subscription?.status === EStatus.CANCELED
        ) {
          await createFreeSubscription(storeAliasId, planIdentifier);
        } else {
          await updateFreeSubscription(storeAliasId, planIdentifier, subscription?.alias_id);
        }

        await downgradeSubscription(storeAliasId);

        setIsLoadingSubscription(false);

        window.location.href = `${window.location.origin}/${storeAliasId}/dashboard/overview/financial`;
      } catch (error: any) {
        setIsLoadingSubscription(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [
      createFreeSubscription,
      updateFreeSubscription,
      history,
      toast,
      selectedPlan,
      downgradeSubscription,
    ],
  );

  return (
    <DowngradeContext.Provider
      value={{
        adProviders,
        handleSelectedAdProviders,
        selectedAdProviders,
        maxAdProviders,
        handleSelectedInvitedMembers,
        invitedMembers,
        maxInvitedMembers,
        selectedInvitedMembers,
        facebookProfiles,
        handleSelectedFacebookProfiles,
        maxFacebookProfiles,
        selectedFacebookProfiles,
        storeWorkflows,
        selectedStoreWorkflows,
        handleSelectedStoreWorkflows,
        maxWorkflows,
        verifyDowngrade,
        handleVerifyingDowngrade,
        isVerifyingDowngrade,
        downgradeSubscription,
        handleDowngradeModalOpen,
        isDowngradeModalOpen,
        handleDowngradingSubscription,
        isDowngradingSubscription,
        handleSubscribe,
        isLoadingSubscription,
        hasToDowngradeAdsProviders,
        hasToDowngradeFacebookProfiles,
        hasToDowngradeInvitedMembers,
        hasToDowngradeStoreWorkflows,
      }}
    >
      {children}
    </DowngradeContext.Provider>
  );
};

export const useDowngrade = (): IDowngradeContext => {
  const context = React.useContext(DowngradeContext);

  if (!context) {
    throw new Error('useDowngrade must be used within DowngradeContext');
  }

  return context;
};
