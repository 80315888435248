import styled, { css } from 'styled-components/macro';

import { ISubItemProps } from '@domain/interfaces/components/ISidebar';

import TextBase from '@components/common/core/DataDisplay/Text';

export const SubItem = styled.div<ISubItemProps>`
  display: flex;
  width: fit-content;
  height: 24px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 24px;

  ${({ isSidebarExpanded }) =>
    !isSidebarExpanded &&
    css`
      margin-left: 12px;
    `}

  span {
    white-space: nowrap;
    margin-left: 12px;

    @media only screen and (max-height: 700px) {
      margin-left: 8px;
    }
  }

  svg {
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;

    @media only screen and (max-height: 700px) {
      min-width: 18px;
      min-height: 18px;
      width: 18px;
      height: 18px;
    }
  }

  ${({ isHoveringItem }) =>
    isHoveringItem &&
    css`
      & > span {
        color: ${({ theme }) => theme.colors.gray[1]};
      }
    `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      & > span {
        color: ${({ theme }) => theme.colors.green.default};
      }
    `}
`;

export const Text = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
`;
