import styled from 'styled-components/macro';

import SideModalBase from '@components/common/core/DataDisplay/SideModal';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import CustomDatePickerBase from '@components/common/core/Utils/CustomDatePicker';

export const CustomDatePicker = styled(CustomDatePickerBase)``;

export const ProductName = styled(HeadingBase)`
  margin-bottom: 24px;
`;

export const Body = styled.div`
  padding-top: 40px;
  padding-bottom: 96px;
`;

export const Title = styled(HeadingBase)``;

export const Header = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Sidemodal = styled(SideModalBase)``;
