import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import { EStep } from '@domain/enums/onboarding/EStep';

import { useDropshipping } from '@helpers/hooks/pages/onboarding/useDropshipping';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';
import { useConfig } from '@helpers/hooks/useConfig';

import { ADS_PROVIDERS } from '@constants/common/integrations';

import onboardingStepService from '@services/pages/onboarding/onboardingStep';

const Marketing: React.FC = () => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const {
    currentMarketingToIntegrate,
    handleNextMarketingToIntegrate,
    selectedMarketings,
    hasSelectedStoreIntegrations,
  } = useDropshipping();
  const { completeStep } = useOnboarding();
  const { onboardingSteps } = onboardingStepService.getOnboardingSteps({ storeAliasId });
  const { analytics, user } = useConfig();

  const getMarketingContent = React.useCallback(
    (integration: typeof ADS_PROVIDERS[number] | undefined) => {
      if (integration) {
        const Content = integration.onboardingContent;

        return <Content callback={handleNextMarketingToIntegrate} />;
      }

      return <></>;
    },
    [handleNextMarketingToIntegrate],
  );

  React.useEffect(() => {
    if (
      !selectedMarketings.length &&
      !currentMarketingToIntegrate &&
      hasSelectedStoreIntegrations
    ) {
      if (onboardingSteps) {
        const foundOnboardingStep = onboardingSteps.find(
          step => step.configuration_step === EStep.STORE_INTEGRATIONS,
        );

        if (foundOnboardingStep) {
          completeStep(foundOnboardingStep.id);
        }
      }

      analytics?.track(
        'Onboarding Integrations Done',
        {
          email: user?.email,
        },
        { context: { groupId: storeAliasId } },
      );
      analytics?.trackHubSpot(storeAliasId, { is_onboarding_integrations_done: true });

      history.push(`/onboarding/${storeAliasId}/dropshipping/integrations/integration-finished`);
    }

    if (
      !selectedMarketings.length &&
      !currentMarketingToIntegrate &&
      !hasSelectedStoreIntegrations
    ) {
      history.push(`/onboarding/${storeAliasId}/dropshipping/integrations`);
    }

    if (!currentMarketingToIntegrate) {
      handleNextMarketingToIntegrate();
    }
  }, [
    selectedMarketings,
    currentMarketingToIntegrate,
    history,
    storeAliasId,
    handleNextMarketingToIntegrate,
    hasSelectedStoreIntegrations,
    completeStep,
    onboardingSteps,
    analytics,
    user,
  ]);

  const foundMarketing = ADS_PROVIDERS.find(
    integration => integration.identifier === currentMarketingToIntegrate,
  );

  return <>{getMarketingContent(foundMarketing)}</>;
};

export default Marketing;
