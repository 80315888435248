import styled, { css } from 'styled-components/macro';

import { IBulletProps } from '@domain/interfaces/dashboard/CustomValuesCategories/IBullet';

export const Test = styled.span``;

export const Input = styled.input`
  background-color: unset;
  border: none;
  color: ${({ theme }) => theme.colors.font};
  font-weight: 500;
  font-size: 1rem;
  padding: 5px 0;

  &:focus {
    outline: none;
  }
`;

export const Bullet = styled.span<IBulletProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: block;
  background-color: green;
  position: relative;

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}
`;

export const BulletAndInputWrapper = styled.div`
  margin-left: 44px;
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Trail = styled.div`
  position: absolute;
  width: 32px;
  height: 50px;
  border-radius: 0 0 0 6px;
  border-left: 2px solid ${({ theme }) => theme.colors.gray[3]};
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray[3]};
  transform: translateY(-50%);
  top: -2px;
`;

export const InputWrapper = styled.div`
  position: relative;
  /* margin-top: 20px; */

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[4]};
  }
`;
