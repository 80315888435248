import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  padding-top: 95px;

  @media only screen and (max-width: 1086px) {
    flex-direction: column;
    gap: 36px;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1036px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const TemplatesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
`;
