import styled from 'styled-components/macro';

import ButtonBase from '@components/common/core/Inputs/Button';

export const Background = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.background[11]};
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  left: 0;
`;

export const SaveWrapper = styled.div`
  display: flex;
`;

export const DisableIntegrationButton = styled(ButtonBase)`
  margin-right: 16px;

  border: 2px solid ${({ theme }) => theme.colors.danger.default};
  box-shadow: none;

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.danger.default};
    box-shadow: none;
  }

  & > span {
    color: ${({ theme }) => theme.colors.danger.default};

    &:hover {
      color: ${({ theme }) => theme.colors.danger.default};
    }
  }
`;

export const ConfirmButton = styled(ButtonBase)``;
