import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const IntegrationName = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const IntegrationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HorizontalRule = styled.hr`
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.gray[4]};
  margin: 12px 0;
`;

export const Text = styled(TextBase)`
  font-size: 0.875rem;
`;

export const DescriptionText = styled(TextBase)`
  font-size: 0.875rem;

  & > strong {
    color: ${({ theme }) => theme.colors.green.default};
  }
`;

export const IconWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[4]};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CardWrapper = styled.button`
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 7px;
  margin-bottom: 8px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.1s;

  &:hover {
    border-color: ${({ theme }) => theme.colors.green.default};
  }
`;

export const ExampleText = styled(TextBase)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const Wrapper = styled.div`
  max-width: 300px;
  width: 100%;

  @media only screen and (max-width: 480px) {
    max-width: unset;
  }
`;
