import { ApiService } from '@services/api';
import {
  IGenerateURLProps,
  IGetWoocommerceCredentialProps,
} from '@domain/interfaces/dashboard/IntegrationCenter/Marketplace/Woocommerce/IWoocommerceAuth';
import { API_DOMAIN } from '@constants/api';

export class WoocommerceService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getWoocommerceCredentialPromise({
    storeAliasId,
  }: IGetWoocommerceCredentialProps): Promise<any> {
    return this.apiService.get(`/api/v1/users/stores/${storeAliasId}/woo-commerce-credentials`);
  }

  public generateURL({ storeAliasId, data }: IGenerateURLProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/woo-commerce/auth/generate-url`,
      { ...data },
    );
  }

  public disableCredential(storeAliasId?: string): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/woo-commerce-credentials/disable`,
    );
  }
}

const woocommerceService = new WoocommerceService(API_DOMAIN);

export default woocommerceService;
