import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { ISkeletonLoadingProps } from '@domain/interfaces/dashboard/FinancialDashboard/IStoreGoal';

import * as S from './styles';

const SkeletonLoading: React.FC<ISkeletonLoadingProps> = ({ height = 138 }) => {
  return (
    <S.Wrapper height={height}>
      <Skeleton width="100%" height={height} />
    </S.Wrapper>
  );
};

export default SkeletonLoading;
