import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';

import { ISelectedFilter, IFilterProps } from '@domain/interfaces/components/IFilter';
import { IAdSenseAccount } from '@domain/interfaces/dashboard/AdSense/IAdSenseAccount';
import { IParams } from '@domain/interfaces/IParams';
import { EFilterOperator } from '@domain/enums/components/EFilter';

import { useToast } from '@helpers/hooks/useToast';
import { useDebounce } from '@helpers/hooks/useDebounce';

import adSenseAccountService from '@services/pages/dashboard/adSense/adSenseAccount';

import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const AdsManagerAdSenseAccount: React.FC<IFilterProps> = ({
  config,
  handleOnClick,
  defaultValue,
  updateData,
  addNewData,
  data,
}) => {
  const { config: filterConfig } = config;
  const foundAdSenseCredential = data.find(
    selectedData => selectedData.field === 'ad_sense_credential_id',
  );

  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();

  const [adSenseAccounts, setAdSenseAccounts] = React.useState<Array<IAdSenseAccount>>([]);
  const [page, setPage] = React.useState<number>(1);
  const [totalPages, setTotalPages] = React.useState<number>(1);
  const [isLoadingAdSenseAccounts, setIsLoadingAdSenseAccounts] = React.useState<boolean>(false);
  const [optionToSearch, setOptionToSearch] = React.useState<string>('');
  const [optionToFilter, setOptionToFilter] = React.useState<string>('');

  const debouncedValue = useDebounce(optionToSearch, 700);

  const onSearchValueChange = React.useCallback(event => {
    setOptionToSearch(event.target.value);
  }, []);

  const loadAdSenseAccount = React.useCallback(async () => {
    if (foundAdSenseCredential) {
      setIsLoadingAdSenseAccounts(true);

      try {
        const { data: adSenseAccountData } = await adSenseAccountService.listAdSenseAccountsPromise(
          {
            storeAliasId,
            page,
            adSenseCredentialAliasId: foundAdSenseCredential.aliasIdHelper,
            filter: {
              name: optionToFilter,
            },
          },
        );

        setTotalPages(adSenseAccountData.total_pages);
        setAdSenseAccounts(previousState => [
          ...previousState,
          ...adSenseAccountData.ad_sense_accounts,
        ]);

        setIsLoadingAdSenseAccounts(false);
      } catch (error: any) {
        setIsLoadingAdSenseAccounts(false);
        toast.error(error?.response?.data?.message);
      }
    } else {
      toast.error('AdSenseCredential não encontrado.');
    }
  }, [foundAdSenseCredential, page, storeAliasId, toast, optionToFilter]);

  const next = React.useCallback(() => {
    setPage(oldPage => oldPage + 1);
  }, []);

  const handleOptionClick = React.useCallback(
    (adSenseAccount: IAdSenseAccount) => {
      const newData: ISelectedFilter = {
        field: config.field,
        label: adSenseAccount.provider_id,
        value: adSenseAccount.id,
        operator: EFilterOperator.EQUAL,
      };

      if (defaultValue) {
        updateData(newData);
      } else {
        addNewData(newData);
      }

      handleOnClick();
    },
    [handleOnClick, config, addNewData, defaultValue, updateData],
  );

  React.useEffect(() => {
    setPage(1);
    setTotalPages(1);
    setAdSenseAccounts([]);
    setOptionToFilter(debouncedValue);
  }, [debouncedValue]);

  React.useEffect(() => {
    loadAdSenseAccount();
  }, [loadAdSenseAccount]);

  const hasMore = page < totalPages;

  return (
    <S.Wrapper id="ads-manager-ad-sense-account-filter">
      <S.ValueToFilterWrapper>
        <S.ValueToFilterInput
          type="text"
          placeholder={filterConfig.placeholder}
          onChange={onSearchValueChange}
          autoFocus
        />
      </S.ValueToFilterWrapper>

      <InfiniteScroll
        dataLength={adSenseAccounts.length}
        next={next}
        hasMore={hasMore}
        loader={<SkeletonLoading />}
        scrollableTarget="ads-manager-ad-sense-account-filter"
        scrollThreshold={0.95}
      >
        {Boolean(!adSenseAccounts.length) && isLoadingAdSenseAccounts && <SkeletonLoading />}

        {adSenseAccounts.map(adSenseAccount => (
          <S.OptionButton
            key={adSenseAccount.alias_id}
            onClick={() => handleOptionClick(adSenseAccount)}
          >
            <S.IdText>{adSenseAccount.provider_id}</S.IdText>
            <S.NameText>{adSenseAccount.name}</S.NameText>
          </S.OptionButton>
        ))}
      </InfiniteScroll>
    </S.Wrapper>
  );
};

export default AdsManagerAdSenseAccount;
