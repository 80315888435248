import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import TextBase from '@components/common/core/DataDisplay/Text';
import NoImageBase from '@components/common/core/DataDisplay/NoImage';

export const NoImage = styled(NoImageBase)`
  background-color: ${({ theme }) => theme.colors.gray[5]};
`;

export const VariantSku = styled(TextBase)`
  max-width: 432px;
  width: 100%;
  -webkit-line-clamp: 2;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 150%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.colors.gray[3]};
  text-align: left;
`;

export const VariantName = styled(TextBase)`
  line-height: 150%;
  text-align: left;
`;

export const VariantDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const Image = styled(ImageBase)`
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

export const Wrapper = styled.button`
  align-items: center;
  background-color: unset;
  border: unset;
  padding: 20px 0;
  display: flex;
  width: 100%;
  gap: 20px;
  transition: all 0.3s;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[4]};

  &:last-child {
    border-bottom: unset;
  }

  &:hover {
    filter: brightness(1.2);
  }

  &:active {
    filter: brightness(0.9);
  }
`;
