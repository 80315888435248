import React from 'react';
import { BarChart } from '@profitfy/pakkun-icons';

import { useFinancialData } from '@helpers/hooks/pages/dashboard/financialData/useFinancialData';
import { zipCodeFormatter } from '@helpers/masks';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Address: React.FC = () => {
  const { register, isLoadingAddress, isValidatingAddress, address } = useFinancialData();

  const zipCodeInput = register('zip_code');

  if (isLoadingAddress || isValidatingAddress) {
    return <SkeletonLoading />;
  }

  return (
    <S.AddressWrapper>
      <S.Header>
        <BarChart size={24} outline />
        <S.Title>Endereço</S.Title>
      </S.Header>

      <S.FormWrapper>
        <S.ZipcodeAndCityAndStateWrapper>
          <S.InputGroup>
            <S.Label>CEP</S.Label>
            <S.TextField
              {...zipCodeInput}
              type="text"
              defaultValue={zipCodeFormatter(address?.zip_code)}
              onChange={event => {
                zipCodeInput.onChange(event);

                const { value } = event.target;

                event.target.value = zipCodeFormatter(value);
              }}
            />
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Cidade</S.Label>
            <S.TextField {...register('city')} type="text" defaultValue={address?.city} />
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Estado</S.Label>
            <S.TextField {...register('state')} type="text" defaultValue={address?.state} />
          </S.InputGroup>
        </S.ZipcodeAndCityAndStateWrapper>

        <S.StreetAndNumberWrapper>
          <S.InputGroup>
            <S.Label>Endereço</S.Label>
            <S.TextField {...register('street')} type="text" defaultValue={address?.street} />
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Número</S.Label>
            <S.TextField
              {...register('number')}
              type="number"
              defaultValue={address?.street_number}
            />
          </S.InputGroup>
        </S.StreetAndNumberWrapper>

        <S.NeighborhoodAndComplementWrapper>
          <S.InputGroup>
            <S.Label>Bairro</S.Label>
            <S.TextField
              {...register('neighborhood')}
              type="text"
              defaultValue={address?.neighborhood}
            />
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Complemento</S.Label>
            <S.TextField
              {...register('complement')}
              type="text"
              defaultValue={address?.complementary || undefined}
            />
          </S.InputGroup>
        </S.NeighborhoodAndComplementWrapper>
      </S.FormWrapper>
    </S.AddressWrapper>
  );
};

export default Address;
