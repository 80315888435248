import React from 'react';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { ICard } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IAdSenseCampaign';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import { numberFormatter } from '@helpers/masks';

import BlurredContent from './BlurredContent';

import * as S from './styles';

const CreditCard: React.FC<ICampaignProps> = ({ data, rowData }) => {
  const cardData: ICard = data;

  const { isDemoEnvironment } = useConfig();
  const { store } = useStoreConfig();

  const isContentBlurred =
    (!store.is_utm_onboarding_finished || !rowData.has_at_least_one_ads_with_utm_synchronized) &&
    !isDemoEnvironment &&
    !rowData.has_all_ads_with_utm_synchronized;

  return (
    <S.Wrapper>
      {isContentBlurred ? (
        <BlurredContent />
      ) : (
        <>
          <S.ContentWrapper>
            <S.LabelText>Receita</S.LabelText>
            <S.AmountText>{`R$ ${numberFormatter(cardData.amount, 2)}`}</S.AmountText>
          </S.ContentWrapper>

          <S.ContentWrapper>
            <S.LabelText>CPA</S.LabelText>
            <S.AmountText>{`R$ ${numberFormatter(cardData.cpa_amount, 2)}`}</S.AmountText>
          </S.ContentWrapper>
        </>
      )}
    </S.Wrapper>
  );
};

export default CreditCard;
