import React from 'react';

import { EHeadingWeight } from '@domain/enums/components/EHeading';

import { useCheckout } from '@helpers/hooks/pages/subscription/useCheckout';
import { useAddress } from '@helpers/hooks/common/store/address/useAddress';
import { useToast } from '@helpers/hooks/useToast';

import { zipCodeFormatter } from '@helpers/masks';

import { brazilianStates } from '@constants/brazilianStates';

import zipCodeService from '@services/external/zipCode';

import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const Address: React.FC = () => {
  const { toast } = useToast();
  const { address } = useAddress();
  const { register, setValue, clearErrors, errors, isSubmittingAddressForm } = useCheckout();

  const zipCodeRegister = register('zip_code');
  const cityRegister = register('city');
  const stateRegister = register('state');
  const streetRegister = register('street');
  const complementaryRegister = register('complementary');
  const numberRegister = register('street_number');
  const neighborhoodRegister = register('neighborhood');

  const onZipCodeChange = React.useCallback(
    async event => {
      event.target.value = zipCodeFormatter(event.target.value);

      const zipCode = event.target.value;

      zipCodeRegister.onChange(event);

      if (zipCode.length === 9) {
        try {
          const { data } = await zipCodeService.getAddressByZipCode({ zipCode });

          if (data.erro) throw new Error();

          setValue('neighborhood', data.bairro);
          setValue('city', data.localidade);
          setValue('street', data.logradouro);
          setValue('state', data.uf);

          clearErrors(['neighborhood', 'city', 'street', 'state']);
        } catch (error: any) {
          toast.error(
            'Não foi possível obter os dados do seu CEP. Por favor, preencha manualmente.',
          );
        }
      }
    },
    [toast, setValue, clearErrors, zipCodeRegister],
  );

  React.useEffect(() => {
    if (address) {
      setValue('zip_code', zipCodeFormatter(address.zip_code));
      setValue('city', address.city);
      setValue('state', address.state_code);
      setValue('street', address.street);
      setValue('street_number', address.street_number);
      setValue('neighborhood', address.neighborhood);

      if (address.complementary) {
        setValue('complementary', address.complementary);
      }
    }
  }, [address, setValue]);

  const isZipCodeError = Boolean(errors.zip_code);
  const isCityError = Boolean(errors.city);
  const isStreetError = Boolean(errors.street);
  const isNeighborhoodError = Boolean(errors.neighborhood);
  const isNumberError = Boolean(errors.street_number);
  const isComplementaryError = Boolean(errors.complementary);

  return (
    <S.Wrapper>
      <S.Header>
        <S.StepNumberWrapper>
          <S.StepNumber>1</S.StepNumber>
        </S.StepNumberWrapper>

        <S.Title fontWeight={EHeadingWeight.REGULAR}>Endereço</S.Title>
      </S.Header>

      <S.FormContent>
        <S.InputGroup>
          <S.Label>CEP</S.Label>
          <S.Input
            {...zipCodeRegister}
            type="text"
            placeholder="Digite o CEP"
            onChange={onZipCodeChange}
            isError={isZipCodeError}
          />
          {errors.zip_code && <Text isErrorFeedback>{errors.zip_code.message}</Text>}
        </S.InputGroup>

        <S.CityAndStateWrapper>
          <S.InputGroup>
            <S.Label>Cidade</S.Label>
            <S.Input
              {...cityRegister}
              type="text"
              placeholder="Digite a cidade"
              isError={isCityError}
            />
            {errors.city && <Text isErrorFeedback>{errors.city.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Estado</S.Label>
            <S.Select {...stateRegister}>
              {brazilianStates.map(state => (
                <S.Option key={state.abbreviation} value={state.abbreviation}>
                  {state.abbreviation}
                </S.Option>
              ))}
            </S.Select>
          </S.InputGroup>
        </S.CityAndStateWrapper>

        <S.InputGroup>
          <S.Label>Rua</S.Label>
          <S.Input
            {...streetRegister}
            type="text"
            placeholder="Digite a rua"
            isError={isStreetError}
          />
          {errors.street && <Text isErrorFeedback>{errors.street.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Complemento (Opcional)</S.Label>
          <S.Input
            {...complementaryRegister}
            type="text"
            placeholder="Digite o complemento"
            isError={isComplementaryError}
          />
        </S.InputGroup>

        <S.NumberAndComplementaryWrapper>
          <S.InputGroup>
            <S.Label>Número</S.Label>
            <S.Input
              {...numberRegister}
              type="text"
              placeholder="Digite o número"
              isError={isNumberError}
            />
            {errors.street_number && <Text isErrorFeedback>{errors.street_number.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Bairro</S.Label>
            <S.Input
              {...neighborhoodRegister}
              type="text"
              placeholder="Digite o bairro"
              isError={isNeighborhoodError}
            />
            {errors.neighborhood && <Text isErrorFeedback>{errors.neighborhood.message}</Text>}
          </S.InputGroup>
        </S.NumberAndComplementaryWrapper>

        <S.NextStepButton isLoading={isSubmittingAddressForm}>Continuar</S.NextStepButton>
      </S.FormContent>
    </S.Wrapper>
  );
};

export default Address;
