import React from 'react';

import { EUserStoreRole } from '@domain/enums/common/userStore/EUserStoreRole';

import { useConfig } from '@helpers/hooks/useConfig';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useStorePermission } from '@helpers/hooks/common/useStorePermission';

import { RankingTableProvider } from '@helpers/hooks/pages/dashboard/productAnalytics/useRankingTable';

import Wrapper from '@components/Dashboard/ProductAnalytics/RankingTable/Wrapper';
import NoAccess from '@components/common/core/Utils/NoAccess';
import LimitationPage from '@components/common/core/Utils/LimitationPage';

const RankingTable: React.FC = () => {
  const { analytics, selectedStore, user, isInternalTeam } = useConfig();
  const { accessManagementData } = useStoreConfig();
  const { hasProductAnalyticsFeature } = useStorePermission();

  const isStoreOwner = accessManagementData?.role === EUserStoreRole.OWNER;
  const hasAccessToPage =
    accessManagementData?.group?.has_financial_product_dashboard_full_write_access ||
    accessManagementData?.group?.has_financial_product_dashboard_full_read_access;

  React.useEffect(() => {
    analytics?.track(
      'Product Ranking Viewed',
      {
        email: user?.email,
      },
      { context: { groupId: selectedStore?.alias_id } },
    );
  }, [analytics, selectedStore, user]);

  if (!isStoreOwner && !hasAccessToPage && !isInternalTeam) {
    return <NoAccess />;
  }

  if (!hasProductAnalyticsFeature) {
    return (
      <LimitationPage
        imageSrc="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/temp/limitation-temp.svg"
        title="Análise de produtos não disponível"
        description="Escolha um plano que te permita exportar planilhas para que consiga usufruir o melhor da ferramenta."
        trackingPropertyName="NO_PRODUCT_ANALYTICS_ACCESS"
      />
    );
  }

  return (
    <RankingTableProvider>
      <Wrapper />
    </RankingTableProvider>
  );
};

export default RankingTable;
