import React from 'react';

import { EHeadingWeight } from '@domain/enums/components/EHeading';

import * as S from './styles';

const Header: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Title fontWeight={EHeadingWeight.REGULAR}>Adicione o script da Profitfy no Gtag</S.Title>
    </S.Wrapper>
  );
};

export default Header;
