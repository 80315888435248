import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import ModalBase from '@components/common/core/DataDisplay/CustomModal';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Wrapper = styled.div`
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.gray[4]};
  border-radius: 12px;
  padding: 16px;
  box-sizing: border-box;
`;

export const SavedContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IconAndTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const IconWrapper = styled.div`
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SavedContentText = styled(TextBase)`
  line-height: 120%;
`;

export const EditAndDeleteMessageButtonsWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  gap: 4px;
`;

export const EditMessageButton = styled.button`
  background: unset;
  border: unset;
  padding: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const DeleteMessageButton = styled.button`
  background: unset;
  border: unset;
  padding: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const DeleteModal = styled(ModalBase)`
  & > div:first-child {
    & > span {
      display: flex;
      align-items: center;
      gap: 8px;

      & > svg {
        margin-top: 3px;
      }
    }
  }
`;

export const Text = styled(TextBase)`
  display: block;
  margin-bottom: 32px;
`;

export const Button = styled(ButtonBase)`
  margin-left: auto;
`;
