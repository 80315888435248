/* eslint-disable no-restricted-syntax */
import React from 'react';

import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { CircleSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components';
import { Play, DotsThreeVertical, Trash, PencilSimple } from 'phosphor-react';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { EMessageContentType, EMessageType } from '@domain/enums/dashboard/automations/EMessage';
import { IMessagePreviewProps } from '@domain/interfaces/dashboard/Automations/IMessage';

import { useMessage } from '@helpers/hooks/pages/dashboard/automations/useMessage';
import { useMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useMessageSettings';

import { processText } from '@helpers/utils/dashboard/automations/preview';

import {
  ABANDONED_CART_TRIGGER_VARIABLES,
  BOLETO_TRIGGER_VARIABLES,
  PIX_TRIGGER_VARIABLES,
  REJECTED_PAYMENT_TRIGGER_VARIABLES,
  SIMPLE_SHIPMENT_TRIGGER_VARIABLES,
  UPDATED_FULFILLMENT_TRIGGER_VARIABLES,
} from '@constants/pages/dashboard/automations/messages/triggers';

import * as S from './styles';

const Preview: React.FC<IMessagePreviewProps> = ({ messageContents, message }) => {
  const { deleteMessage, isDeletingMessage } = useMessage();
  const {
    handleUpdateMessageModalOpen,
    handleSelectedMessageToUpdate,
    selectedMessageTrigger,
  } = useMessageSettings();

  const theme = useTheme();

  const [isHoveringPreview, setIsHoveringPreview] = React.useState<boolean>(false);

  const onMouseEnter = React.useCallback(() => {
    setIsHoveringPreview(true);
  }, []);

  const onMouseLeave = React.useCallback(() => {
    setIsHoveringPreview(false);
  }, []);

  const onDelete = React.useCallback(
    event => {
      event.stopPropagation();

      deleteMessage(message.alias_id);
    },
    [deleteMessage, message],
  );

  const sortedArrayByPositionNumber = messageContents.sort((currentValue, nextValue) => {
    return currentValue.position - nextValue.position;
  });

  const getRandomWaveHeight = (): number => {
    const min = 3;
    const max = 14;

    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const AUDIO_WAVES = Array.from(Array(50).keys()).map(item => {
    return {
      value: item,
      height: getRandomWaveHeight(),
    };
  });

  const onEdit = React.useCallback(() => {
    handleUpdateMessageModalOpen(true);
    handleSelectedMessageToUpdate(message);
  }, [handleUpdateMessageModalOpen, handleSelectedMessageToUpdate, message]);

  const getTriggerVariables = React.useCallback(() => {
    if (selectedMessageTrigger === EMessageType.ABANDONED_CART)
      return ABANDONED_CART_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.BOLETO) return BOLETO_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.PIX) return PIX_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.SIMPLE_SHIPMENT)
      return SIMPLE_SHIPMENT_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.REJECTED_PAYMENT)
      return REJECTED_PAYMENT_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.UPDATED_FULFILLMENT)
      return UPDATED_FULFILLMENT_TRIGGER_VARIABLES;

    return [];
  }, [selectedMessageTrigger]);

  React.useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  return (
    <S.Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {sortedArrayByPositionNumber?.map((messageContent, index) => {
        const isFirstItemOfArray = index === 0;

        if (messageContent.type === EMessageContentType.IMAGE) {
          return (
            <S.ImageContent key={messageContent.id}>
              {messageContent.content_public_url && (
                <S.Image src={messageContent.content_public_url} alt="" />
              )}

              <S.Time>11:47</S.Time>
            </S.ImageContent>
          );
        }

        if (messageContent.type === EMessageContentType.VIDEO) {
          if (!messageContent.content_public_url) return <></>;
          return (
            <S.VideoContent key={messageContent.id}>
              {messageContent.content_public_url && (
                <S.Video src={messageContent.content_public_url} />
              )}

              <S.Time>11:47</S.Time>
            </S.VideoContent>
          );
        }

        if (messageContent.type === EMessageContentType.PDF) {
          if (!messageContent.content_public_url) return <></>;

          return (
            <S.PDFContent key={messageContent.id}>
              {messageContent.content_public_url && (
                <Document file={messageContent.content_public_url}>
                  <Page renderTextLayer={false} scale={0.5} pageNumber={1} />
                </Document>
              )}

              <S.Time>11:47</S.Time>
            </S.PDFContent>
          );
        }

        if (messageContent.type === EMessageContentType.AUDIO) {
          return (
            <S.AudioContent>
              <S.IconWrapper>
                <Play size={20} color="#9C8D8D" weight="fill" />
              </S.IconWrapper>

              <S.BulletAndWavesWrapper>
                <S.AudioBullet />

                <S.WavesWrapper>
                  {AUDIO_WAVES.map(wave => {
                    return <S.Wave key={wave.value} height={wave.height} />;
                  })}
                </S.WavesWrapper>

                <S.CurrentTimeText>0:00</S.CurrentTimeText>

                <S.TotalTimeText>1:20</S.TotalTimeText>
              </S.BulletAndWavesWrapper>

              <S.Avatar />
            </S.AudioContent>
          );
        }

        if (messageContent.type === EMessageContentType.TEXT) {
          let modifiedText = messageContent.content;

          const triggerVariables = getTriggerVariables();

          triggerVariables.forEach(variable => {
            modifiedText = modifiedText.replace(variable.data, variable.nameToDisplay);
          });

          return (
            <S.Content isFirstItemOfArray={isFirstItemOfArray} key={messageContent.id}>
              <S.MessageText
                dangerouslySetInnerHTML={{ __html: processText(modifiedText as string) }}
              />

              <S.Time>11:47</S.Time>

              {isFirstItemOfArray && <S.ContentArrow />}
            </S.Content>
          );
        }

        return <></>;
      })}

      {isHoveringPreview && (
        <S.BluredOverlayer>
          <S.Popover>
            <S.PopoverTrigger>
              <S.OptionsWrapper>
                <DotsThreeVertical size={24} color={theme.colors.gray[1]} weight="bold" />
              </S.OptionsWrapper>
            </S.PopoverTrigger>

            <S.PopoverContent>
              <S.PopoverOption onClick={onDelete}>
                {!isDeletingMessage && (
                  <>
                    <Trash size={16} color={theme.colors.gray[1]} weight="bold" />
                    Excluir
                  </>
                )}

                {isDeletingMessage && (
                  <CircleSpinner
                    size={12}
                    color={theme.colors.gray[1]}
                    loading={isDeletingMessage}
                  />
                )}
              </S.PopoverOption>

              <S.PopoverArrow />
            </S.PopoverContent>
          </S.Popover>

          <S.UpdateMessageButton
            variant={EButtonVariant.SECONDARY_BUTTON}
            onClick={onEdit}
            iconPrepend={() => (
              <PencilSimple size={16} color={theme.colors.gray[1]} weight="bold" />
            )}
          >
            Editar mensagem
          </S.UpdateMessageButton>
        </S.BluredOverlayer>
      )}
    </S.Wrapper>
  );
};

export default Preview;
