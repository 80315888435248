import { ECategory, EMessageType } from '@domain/enums/dashboard/automations/EMessage';
import { IMessage } from '@domain/interfaces/common/automations/IMessage';

interface IMessageTemplates {
  id: string;
  title: string;
  type: string;
  category: string;
  description: string;
  icon: any;
  message_contents?: any[];
}

export const messageTemplateToMessage = (
  messageTemplate: IMessageTemplates | undefined,
): IMessage | undefined => {
  if (!messageTemplate) return undefined;

  const mappedMessage: IMessage = {
    title: messageTemplate.title,
    id: messageTemplate.id,
    alias_id: 'message-to-be-created',
    message_contents: messageTemplate.message_contents || [],
    category: messageTemplate.category as ECategory,
    store_id: 'any-store',
    type: messageTemplate.type as EMessageType,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  };

  return mappedMessage;
};
