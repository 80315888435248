import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import ButtonBase from '@components/common/core/Inputs/Button';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import CustomDatePickerBase from '@components/common/core/Utils/CustomDatePicker';
import TextFieldBase from '@components/common/core/Inputs/TextField';

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 570px;
  display: flex;
  flex-direction: column;
  padding-top: 48px;
`;

export const WalletReportContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 5px;
  padding: 24px;
`;

export const Heading = styled(HeadingBase)`
  margin-bottom: 12px;
`;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Label = styled(LabelBase)``;

export const CustomDatePicker = styled(CustomDatePickerBase)`
  margin-bottom: 24px;

  & > div {
    gap: 24px;

    @media only screen and (max-width: 590px) {
      flex-direction: column;
    }
  }

  label {
    font-size: 0.875rem;
  }

  @media only screen and (max-width: 590px) {
    .input-wrapper {
      width: 100%;
    }

    & > div > div > button {
      width: 100%;
    }
  }
`;

export const ReportDataSelect = styled(SelectBase)`
  width: 100%;
`;

export const Input = styled(TextFieldBase)`
  width: 100%;
`;

export const Option = styled(OptionBase)``;

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 24px;
  background-color: ${({ theme }) => theme.colors.black.light};
  border-radius: 5px;
  margin-bottom: 24px;
`;

export const Data = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[5]};
  padding-bottom: 8px;

  &:last-child {
    border-bottom: unset;
    padding-bottom: unset;
  }
`;

export const DataText = styled(TextBase)``;

export const NetRevenueDataValue = styled(TextBase)`
  color: ${({ theme }) => theme.colors.success.light};
  font-size: 1.125rem;
`;

export const SubscriptionsDataValue = styled(TextBase)`
  color: #ffffff;
  font-size: 1.125rem;
`;

export const ReportButton = styled(ButtonBase)`
  width: fit-content;
  margin-left: auto;
`;

export const LoadingText = styled(TextBase)``;
