import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import ButtonBase from '@components/common/core/Inputs/Button';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import TextFieldBase from '@components/common/core/Inputs/TextField';

export const Heading = styled(HeadingBase)`
  margin-bottom: 12px;
`;

export const AssignUserContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 5px;
  padding: 24px;
  margin-bottom: 32px;
`;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Label = styled(LabelBase)``;

export const AssingUserSelect = styled(SelectBase)`
  width: 100%;
`;

export const Option = styled(OptionBase)``;

export const Input = styled(TextFieldBase)`
  width: 100%;
`;

export const AssignUserButton = styled(ButtonBase)`
  width: fit-content;
  margin-left: auto;
`;
