import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Modal = styled(ModalBase)`
  width: 100%;
  max-width: 428px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
`;

export const Heading = styled(TextBase)``;

export const Description = styled(TextBase)`
  color: #ffffff;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-left: auto;
  width: fit-content;
  margin-top: 32px;
`;

export const CancelButton = styled(ButtonBase)``;

export const DeleteInstanceButton = styled(ButtonBase)`
  background-color: #d03333;
  border: unset;
  padding: unset;
  box-shadow: none;
  width: 141px;
  height: 36px;
  transition: all 0.3s;
  cursor: pointer;

  span {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.white.dark};
  }

  &:hover {
    background-color: #d03333;
    box-shadow: none;
  }

  &:active {
    filter: brightness(0.9);
    box-shadow: none;
  }
`;
