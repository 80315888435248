/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { IHelpMessenger } from '@domain/interfaces/hooks/IHelpMessenger';

const Intercom = {
  getIntercom(event: string, settings?: any) {
    if (!window['Intercom']) {
      throw new Error('Intercom script has not been loaded yet.');
    }

    return window['Intercom'](event, settings);
  },
  openChat() {
    this.getIntercom('show');
  },
  shutdown() {
    this.getIntercom('shutdown');
  },
  showArticle(articleId: number) {
    window?.Intercom('showArticle', articleId);
  },
};

export const useHelpMessenger = (): IHelpMessenger => {
  return Intercom;
};
