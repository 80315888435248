import styled from 'styled-components/macro';

export const Button = styled.button`
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background-color: unset;
  right: 0;
  padding-top: 4px !important;
  border: 2px solid ${({ theme }) => theme.colors.green.dark};
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.green.dark} !important;
  }
`;

export const Wrapper = styled.div`
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(15, 17, 22, 1) 50%);
  height: 100%;
  width: 56px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  @media only screen and (max-width: 768px) {
    right: -32px;
  }
`;
