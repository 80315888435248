import styled, { keyframes, css } from 'styled-components/macro';

import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';
import TextBase from '@components/common/core/DataDisplay/Text';
import Image from '@components/common/core/DataDisplay/Image';
import FormBase from '@components/common/core/Inputs/Form';
import LinkBase from '@components/common/core/Navigation/Link';

const appearFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Form = styled(FormBase)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const InputGroup = styled(InputGroupBase)`
  animation: ${appearFromBottom} 400ms ease-in-out;
  max-width: 374px;
  width: 100%;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 32px;
  }
`;

export const Label = styled(LabelBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const Input = styled(TextFieldBase)`
  background-color: ${({ theme }) => theme.colors.gray[5]};
  border-color: ${({ theme }) => theme.colors.gray[5]};

  & > div {
    background-color: ${({ theme }) => theme.colors.gray[6]};
    border-color: ${({ theme }) => theme.colors.gray[6]};

    & > input {
      color: ${({ theme }) => theme.colors.gray[1]};

      &::placeholder {
        color: ${({ theme }) => theme.colors.gray[3]};
        font-weight: 500;
      }

      &::-ms-input-placeholder {
        color: ${({ theme }) => theme.colors.gray[3]};
      }

      &::-ms-input-placeholder {
        color: ${({ theme }) => theme.colors.gray[3]};
      }
    }

    ${({ isError, theme, value }) =>
      Boolean(value) &&
      Boolean(!isError) &&
      css`
        border-color: ${theme.colors.success.default};
        background-color: ${theme.colors.black.default};
      `}

    ${({ isError, theme }) =>
      Boolean(isError) &&
      css`
        border-color: ${theme.colors.danger.default};
        background-color: ${theme.colors.black.default};
      `}
  }
`;

export const Logo = styled(Image)``;

export const TitleAndSubTitleWrapper = styled.div`
  margin-top: 70px;
  margin-bottom: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const SubTitle = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const CreateAccountButton = styled(ButtonBase)`
  max-width: 374px !important;
  width: 100%;
`;

export const TermsOfUsageText = styled(TextBase)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[2]};
  margin-bottom: 12px;
`;

export const ExternalLoginButton = styled.button`
  width: 100%;
  height: 42px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-weight: 400;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray[1]};
  border: 0;
  padding: 12px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const ExternalLoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export const HorizontalRulesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
  justify-content: center;
  width: 100%;
`;

export const HorizontalRule = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.gray[5]};
  width: 100%;
`;

export const HorizontalRuleText = styled(TextBase)`
  margin: 0 18px;
`;

export const Link = styled(LinkBase)`
  margin-left: auto;
  font-size: 0.875rem;
`;

export const LogoLink = styled(LinkBase)`
  width: 100%;
  max-width: 200px;
  align-self: unset;

  &:hover {
    filter: unset;
  }
`;

export const TermsOfUsageLink = styled.a`
  margin: 0 4px;
  color: ${({ theme }) => theme.colors.green.default};
  font-family: ${({ theme }) => theme.fonts.body};
  text-decoration: none;
  transition: all 0.3s;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const AccessAccountText = styled(TextBase)`
  display: flex;
  margin-left: auto;
  gap: 4px;
  margin-top: 16px;
  font-size: 0.875rem;
`;
