import React from 'react';

import { useOrders } from '@helpers/hooks/pages/dashboard/orders/useOrders';
import { CreateOrderProvider } from '@helpers/hooks/pages/dashboard/orders/useCreateOrder';

import Product from './Product';
import NewProduct from './NewProduct';

import * as S from './styles';

const Products: React.FC = () => {
  const { newProductsForOrder } = useOrders();

  return (
    <S.Wrapper>
      <CreateOrderProvider>
        {newProductsForOrder.map((product, index) => (
          <Product product={product} index={index} key={product.id} />
        ))}
      </CreateOrderProvider>
      <NewProduct />
    </S.Wrapper>
  );
};

export default Products;
