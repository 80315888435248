import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import CustomHoverCard from '@components/common/core/Utils/CustomHoverCard';

export const HoverCard = styled(CustomHoverCard)``;

export const HoverCardContent = styled(CustomHoverCard.Content)`
  padding: 10px;
`;

export const OrdersAmount = styled(TextBase)`
  color: ${({ theme }) => theme.colors.white.default};
  line-height: 120%;
  font-size: 0.875rem;
  font-weight: 400;
`;
