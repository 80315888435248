import Text from '@components/common/core/DataDisplay/Text';
import { STORE_PROVIDERS } from '@constants/common/integrations';
import { useToast } from '@helpers/hooks/useToast';
import { changeCredentialFormSchema } from '@helpers/validators/dashboard/adminPanel/changeCredential';
import { yupResolver } from '@hookform/resolvers/yup';
import changeCredentialService from '@services/pages/dashboard/adminPanel/changeCredential';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as S from './styles';

const ChangeCredential: React.FC = () => {
  const { toast } = useToast();
  const integrations = STORE_PROVIDERS;

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(changeCredentialFormSchema),
  });

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async formData => {
      setIsSubmitting(true);

      try {
        await changeCredentialService.alterCredential({
          store_alias_id: formData.origin,
          integration_type: formData.integrations,
          data: {
            new_store_alias_id: formData.destiny,
          },
        });

        toast.success('Credencial trocada com sucesso.');

        setIsSubmitting(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsSubmitting(false);
      }
    },
    [toast],
  );

  return (
    <S.ContentWrapper>
      <S.Heading>Alterar credencial</S.Heading>

      <S.Content>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label htmlFor="origin">Store alias Id (origem)</S.Label>
            <S.Input
              {...register('origin')}
              type="text"
              placeholder="Insira o store alias da loja de origem"
              id="origin"
            />
            {errors.origin && <Text isErrorFeedback>{errors.origin.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Integrações</S.Label>
            <S.Select {...register('integrations')}>
              {integrations.map(integration => {
                return (
                  <S.Option value={integration.id} key={integration.id}>
                    {integration.name}
                  </S.Option>
                );
              })}
            </S.Select>
          </S.InputGroup>

          <S.InputGroup>
            <S.Label htmlFor="destiny">Store alias Id (destino)</S.Label>
            <S.Input
              {...register('destiny')}
              type="text"
              placeholder="Insira o store alias da loja de destino"
              id="destiny"
            />
            {errors.destiny && <Text isErrorFeedback>{errors.destiny.message}</Text>}
          </S.InputGroup>

          <S.Button type="submit" isLoading={isSubmitting} disabled={isSubmitting}>
            Enviar
          </S.Button>
        </S.Form>
      </S.Content>
    </S.ContentWrapper>
  );
};

export default ChangeCredential;
