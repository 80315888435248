export enum EPixType {
  PRODUCT_NAME = 'PRODUCT_NAME',
  PIX_TOTAL_VALUE = 'PIX_TOTAL_VALUE',
  ORDER_STATUS = 'ORDER_STATUS',
}

export enum EPixProductOptions {
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  NOT_EQUAL = 'NOT_EQUAL',
}

export enum EPixTotalValueOptions {
  SMALLER = 'SMALLER',
  SMALLER_EQUAL = 'SMALLER_EQUAL',
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  LARGER = 'LARGER',
  LARGER_EQUAL = 'LARGER_EQUAL',
}

export enum EPixOrderStatusOptions {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
}

export enum EPixOrderStatusPicker {
  PAID = 'PAID',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
  CHARGED_BACK = 'CHARGED_BACK',
  IN_ANALYSIS = 'IN_ANALYSIS',
  REJECTED = 'REJECTED',
}

export type PixOptions<Type> = Type extends EPixType.PRODUCT_NAME
  ? EPixProductOptions
  : Type extends EPixType.PIX_TOTAL_VALUE
  ? EPixTotalValueOptions
  : EPixOrderStatusOptions;
