import React from 'react';
import { Tag } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useHistory } from 'react-router-dom';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { useCustomValuesIn } from '@helpers/hooks/pages/dashboard/customValues/useCustomValuesIn';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Header: React.FC = () => {
  const theme: any = useTheme();
  const history = useHistory();
  const {
    handleNewCustomValueSidemodalOpen,
    isLoadingCustomValues,
    isLoadingCustomValuesCategories,
    isValidatingCustomValues,
  } = useCustomValuesIn();

  const onCategoriesClick = React.useCallback(() => {
    history.push('categories');
  }, [history]);

  const onNewCustomValueClick = React.useCallback(() => {
    handleNewCustomValueSidemodalOpen();
  }, [handleNewCustomValueSidemodalOpen]);

  if (isLoadingCustomValues || isLoadingCustomValuesCategories || isValidatingCustomValues) {
    return <SkeletonLoading />;
  }

  return (
    <S.HeaderWrapper>
      <S.Title type={EHeadingSize.H3}>Valores Adicionais de Entrada</S.Title>

      <S.ButtonsWrapper>
        <S.CategoriesButton onClick={onCategoriesClick}>
          <S.CategoryIcon>
            <Tag size={16} color={theme.colors.white.default} />
          </S.CategoryIcon>
          Categorias
        </S.CategoriesButton>

        <S.NewCustomSpendButton onClick={onNewCustomValueClick}>
          Adicionar valor
        </S.NewCustomSpendButton>
      </S.ButtonsWrapper>
    </S.HeaderWrapper>
  );
};

export default Header;
