import React from 'react';

import { IBadge } from '@domain/interfaces/components/IBadge';
import Text from '@components/common/core/DataDisplay/Text';

import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import * as S from './styles';

const Badge: React.FC<IBadge> = ({ children, color, secondary, ...rest }) => {
  return (
    <>
      {secondary ? (
        <S.SecondaryContainer color={color} {...rest}>
          <S.BadgeCircle />
          <Text size={ETextSize.B5} weight={ETextWeight.MEDIUM}>
            {children}
          </Text>
        </S.SecondaryContainer>
      ) : (
        <S.Container color={color} {...rest}>
          {children}
        </S.Container>
      )}
    </>
  );
};

export default Badge;
