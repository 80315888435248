import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';
import ImageBase from '@components/common/core/DataDisplay/Image';

export const SignUpButton = styled(ButtonBase)``;

export const ScheduleCalendlyButton = styled(ButtonBase)``;

export const ButtonsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 28px 32px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
`;

export const VideoFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: 16px;
  margin-top: 24px;
`;

export const Text = styled(TextBase)`
  line-height: 120%;
  text-align: center;
  display: block;
  width: 100%;
`;

export const Title = styled(HeadingBase)`
  text-align: center;
  margin-bottom: 16px;
`;

export const TextAndVideoWrapper = styled.div`
  max-width: 576px;
  margin: 0 auto;
`;

export const Logo = styled(ImageBase)`
  max-width: 152px;
  width: auto;
  margin-bottom: 28px;
`;

export const Content = styled.div`
  max-width: 1262px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 32px 96px 32px;
  box-sizing: border-box;
`;

export const Wrapper = styled.div`
  background: linear-gradient(180deg, #0f1116 14.36%, rgba(15, 17, 22, 0) 100%);
  width: 100%;
  min-height: 100vh;
  background: url('https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/onboarding/images/backgrounds/bottom-background.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100vw auto;
`;

export const Container = styled.div`
  background-color: #000;
  min-height: 100vh;
  width: 100%;
`;
