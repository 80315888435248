import { differenceInDays, subDays } from 'date-fns';

import { ESelectedChart } from '@domain/enums/dashboard/main/ESelectedChart';
import {
  ILineDataConfig,
  IBarDataConfig,
  IGetChartApiProps,
  IParseChartPeriodProps,
  IParseChartPeriodResponse,
} from '@domain/interfaces/dashboard/Chart/IChart';

import { ADS_PROVIDERS } from '@constants/common/integrations';

import chartService from '@services/pages/dashboard/chart/chart';

export { parseData } from '@helpers/dashboard/chart/parseData';

export const getChartName = (selectedParse: string): string =>
  ({
    revenue: 'Resumo Financeiro',
    cogs: 'Resumo de custos dos produtos',
    marketing: 'Resumo de gastos com Ads',
    taxes: 'Resumo de taxas e impostos',
  }[selectedParse] || '');

export const parseLineDataConfig = (selectedChart: string): Array<ILineDataConfig> => {
  if (selectedChart === ESelectedChart.TAXES) {
    return [
      {
        dataKey: 'gateway_fee',
        strokeColor: '#3398FF',
        name: 'Taxa de Gateway',
      },
      {
        dataKey: 'checkout_fee',
        strokeColor: '#0058B3',
        name: 'Taxa de Checkout',
      },
      {
        dataKey: 'marketplace_fee',
        strokeColor: '#75B9FF',
        name: 'Taxa de Marketplace',
      },
      {
        dataKey: 'tax',
        strokeColor: '#117EEE',
        name: 'Taxa de Impostos',
      },
    ];
  }

  if (selectedChart === ESelectedChart.MARKETING) {
    const mappedMarketings = ADS_PROVIDERS.map(marketing => {
      const dataKey = marketing.identifier.toLocaleLowerCase();

      return { dataKey, strokeColor: marketing.chart_hex_color, name: marketing.name };
    });

    return [
      ...mappedMarketings,
      {
        dataKey: 'manual',
        strokeColor: '#f1f1',
        name: 'Manual',
      },
    ];
  }

  if (selectedChart === ESelectedChart.COGS) {
    return [
      {
        dataKey: 'delivered',
        strokeColor: '#FE8F24',
        name: 'Produtos enviados',
      },
      {
        dataKey: 'pending',
        strokeColor: '#8C4704',
        name: 'Produtos não enviados',
      },
    ];
  }

  return [
    {
      dataKey: 'gateway_revenue',
      strokeColor: '#FFD600',
      name: 'Receita Líquida',
    },
    {
      dataKey: 'cogs',
      strokeColor: '#FD6B17',
      name: 'Custo dos Produtos',
    },
    {
      dataKey: 'marketing',
      strokeColor: '#7A51DC',
      name: 'Marketing',
    },
    {
      dataKey: 'fee',
      strokeColor: '#3398FF',
      name: 'Taxas e Impostos',
    },
    {
      dataKey: 'net_profit',
      strokeColor: '#28CF87',
      name: 'Lucro Líquido',
    },
  ];
};

export const parseBarDataConfig = (selectedChart: string): Array<IBarDataConfig> => {
  if (selectedChart === ESelectedChart.TAXES) {
    return [
      {
        dataKey: 'gateway_fee',
        fill: '#3398FF',
        name: 'Taxa de Gateway',
      },
      {
        dataKey: 'checkout_fee',
        fill: '#0058B3',
        name: 'Taxa de Checkout',
      },
      {
        dataKey: 'marketplace_fee',
        fill: '#75B9FF',
        name: 'Taxa de Marketplace',
      },
      {
        dataKey: 'tax',
        fill: '#117EEE',
        name: 'Taxa de Impostos',
      },
    ];
  }

  if (selectedChart === ESelectedChart.MARKETING) {
    const mappedMarketings = ADS_PROVIDERS.map(marketing => {
      const dataKey = marketing.identifier.toLocaleLowerCase();

      return { dataKey, fill: marketing.chart_hex_color, name: marketing.name };
    });

    return [
      ...mappedMarketings,
      {
        dataKey: 'manual',
        fill: '#f1f1',
        name: 'Manual',
      },
    ];
  }

  if (selectedChart === ESelectedChart.COGS) {
    return [
      {
        dataKey: 'delivered',
        fill: '#FE8F24',
        name: 'Produtos enviados',
      },
      {
        dataKey: 'pending',
        fill: '#8C4704',
        name: 'Produtos não enviados',
      },
    ];
  }

  return [
    {
      dataKey: 'gateway_revenue',
      fill: '#FFD600',
      name: 'Receita Líquida',
    },
    {
      dataKey: 'cogs',
      fill: '#FD6B17',
      name: 'Custo dos Produtos',
    },
    {
      dataKey: 'marketing',
      fill: '#7A51DC',
      name: 'Marketing',
    },
    {
      dataKey: 'fee',
      fill: '#3398FF',
      name: 'Taxas e Impostos',
    },
    {
      dataKey: 'net_profit',
      fill: '#28CF87',
      name: 'Lucro Líquido',
    },
  ];
};

export const parseChartPeriod = ({
  startDate,
  endDate,
  format,
}: IParseChartPeriodProps): IParseChartPeriodResponse => {
  let newStartDate = startDate;

  if (differenceInDays(endDate, startDate) < 6) {
    newStartDate = subDays(endDate, 6);
  }

  const endDateDay = format(endDate, 'dd');

  if (differenceInDays(endDate, startDate) > 29 && endDateDay === '31') {
    newStartDate = subDays(endDate, 30);
  }

  if (differenceInDays(endDate, startDate) > 29 && endDateDay !== '31') {
    newStartDate = subDays(endDate, 29);
  }

  const parsedStartDate = newStartDate;
  const parsedEndDate = endDate;

  return {
    startDate: parsedStartDate,
    endDate: parsedEndDate,
  };
};

export const getChartApi = {
  net_revenue: ({ storeAliasId, startDate, endDate, filter }: IGetChartApiProps): any => {
    return chartService.getGatewayRevenue({ storeAliasId, startDate, endDate, filter });
  },
  cogs: ({ storeAliasId, startDate, endDate, filter }: IGetChartApiProps): any => {
    return chartService.getCogs({ storeAliasId, startDate, endDate, filter });
  },
  marketing: ({ storeAliasId, startDate, endDate, filter }: IGetChartApiProps): any => {
    return chartService.getMarketing({ storeAliasId, startDate, endDate, filter });
  },
  taxes: ({ storeAliasId, startDate, endDate, filter }: IGetChartApiProps): any => {
    return chartService.getFee({ storeAliasId, startDate, endDate, filter });
  },
  profit: ({ storeAliasId, startDate, endDate, filter }: IGetChartApiProps): any => {
    return chartService.getNetProfit({ storeAliasId, startDate, endDate, filter });
  },
  revenue: ({ storeAliasId, startDate, endDate, filter }: IGetChartApiProps): any => {
    return chartService.getFinancialSummary({ storeAliasId, startDate, endDate, filter });
  },
};
