import queryString from 'query-string';

import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DASHBOARD_DOMAIN } from '@constants/api';
import {
  IGetProductsMetricProps,
  IGetProductsMetricResponse,
  IGetProductsProps,
  IGetProductsResponse,
} from '@domain/interfaces/dashboard/ProductAnalytics/IProducts';

export class ProductsService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getProducts({
    storeAliasId,
    startDate,
    endDate,
    filter,
  }: IGetProductsMetricProps): IGetProductsMetricResponse {
    const stringfiedFilter = JSON.stringify(filter);

    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/products/summary/financial?start_date=${startDate}&end_date=${endDate}&page=1&limit=10&${queryString.stringify(
        { filter: stringfiedFilter },
      )}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      productsMetrics: data?.products_metrics,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public listTopThreeProducts({
    storeAliasId,
    startDate,
    endDate,
    page,
    filter,
  }: IGetProductsProps): IGetProductsResponse {
    const stringfiedFilter = JSON.stringify(filter);

    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/products/summary/financial?start_date=${startDate}&end_date=${endDate}&page=${
        page + 1
      }&limit=3&${queryString.stringify({ filter: stringfiedFilter })}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      productsMetrics: data?.products_metrics,
      totalPages: data?.total_pages,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public listTopThreeProductsPromise({
    storeAliasId,
    startDate,
    endDate,
    page,
    filter,
  }: IGetProductsProps): Promise<any> {
    const stringfiedFilter = JSON.stringify(filter);

    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/products/summary/financial?start_date=${startDate}&end_date=${endDate}&page=${
        page + 1
      }&limit=3&${queryString.stringify({ filter: stringfiedFilter })}`,
    );
  }
}

const productsService = new ProductsService(API_DASHBOARD_DOMAIN);

export default productsService;
