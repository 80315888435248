import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { USER_API_DOMAIN } from '@constants/api';
import {
  ICreateAddressProps,
  IDeleteAddressProps,
  IGetAddressProps,
  IGetAddressResponse,
  IUpdateAddressProps,
} from '@domain/interfaces/subscription/IAddress';

export class AddressService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getAddress({ storeAliasId }: IGetAddressProps): IGetAddressResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/addresses`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      address: data?.address,
      error,
      mutate,
      isValidating,
      isLoading,
    };
  }

  public createAddress({ data, storeAliasId }: ICreateAddressProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/addresses`, { ...data });
  }

  public updateAddress({ data, storeAliasId }: IUpdateAddressProps): Promise<any> {
    return this.apiService.put(`/api/v1/users/stores/${storeAliasId}/addresses`, { ...data });
  }

  public deleteAddress({ storeAliasId }: IDeleteAddressProps): Promise<any> {
    return this.apiService.delete(`/api/v1/users/stores/${storeAliasId}/addresses`);
  }
}

const addressService = new AddressService(USER_API_DOMAIN);

export default addressService;
