import React from 'react';

import { SimpleClose } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { ISelectedFilterProps } from '@domain/interfaces/components/IFilter';
import { useFilter } from '@helpers/hooks/useFilter';
import { getFilterComponent } from '@helpers/utils/components/filter';
import { useDate } from '@helpers/hooks/useDate';
import { ThemeProvider } from '@helpers/hooks/useTheme';

import { parseISO } from 'date-fns';
import * as S from './styles';

const SelectedFilter: React.FC<ISelectedFilterProps> = ({ selectedFilter }) => {
  const theme = useTheme();

  const { format } = useDate();

  const { handleData, data, config, addNewData, updateData } = useFilter();

  const [isFilterOpen, setIsFilterOpen] = React.useState<boolean>(false);

  const handleFilterOpen = React.useCallback(() => setIsFilterOpen(!isFilterOpen), [isFilterOpen]);

  const removeSelectedFilter = React.useCallback(
    event => {
      event.stopPropagation();

      if (selectedFilter.field === 'ad_sense_credential_id') {
        const filteredData = data.filter(filter => filter.field !== selectedFilter.field);
        const filteredDataWithoutAdSenseAccount = filteredData.filter(
          filter => filter.field !== 'ad_sense_account_id',
        );

        handleData(filteredDataWithoutAdSenseAccount);
      } else {
        const filteredData = data.filter(filter => filter.field !== selectedFilter.field);

        handleData(filteredData);
      }
    },
    [data, handleData, selectedFilter],
  );

  const foundFilterConfig =
    config.find(filter => filter.field === selectedFilter.field) || config[0];

  const Filter = getFilterComponent(foundFilterConfig?.type || '');

  const getButtonLabel = (): string => {
    if (selectedFilter.field === 'date') {
      const parsedStartDate = parseISO(selectedFilter.value.startDate);
      const parsedEndDate = parseISO(selectedFilter.value.endDate);

      const formattedStartDate = format(parsedStartDate, 'dd/MM');
      const formattedEndDate = format(parsedEndDate, 'dd/MM');

      return `${formattedStartDate} - ${formattedEndDate}`;
    }

    return selectedFilter.label;
  };

  return (
    <S.DropDown open={isFilterOpen} onOpenChange={handleFilterOpen}>
      <S.DropDown.Trigger>
        <S.ButtonWrapper>
          {getButtonLabel()}
          <S.IconWrapper onClick={removeSelectedFilter}>
            <SimpleClose size={12} color={theme.colors.green.default} />
          </S.IconWrapper>
        </S.ButtonWrapper>
      </S.DropDown.Trigger>

      <S.DropDownContent sideOffset={5} filterType={foundFilterConfig?.type}>
        <ThemeProvider>
          <Filter
            config={foundFilterConfig}
            handleOnClick={handleFilterOpen}
            defaultValue={selectedFilter.value}
            addNewData={addNewData}
            updateData={updateData}
            data={data}
          />
        </ThemeProvider>
      </S.DropDownContent>
    </S.DropDown>
  );
};

export default SelectedFilter;
