import styled from 'styled-components/macro';

export const SecondSection = styled.div`
  max-width: 398px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;

  @media only screen and (max-width: 1124px) {
    max-width: unset;
    flex-direction: row;
  }

  @media only screen and (max-width: 632px) {
    flex-direction: column;
  }
`;

export const FirstSection = styled.div`
  max-width: 848px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media only screen and (max-width: 1342px) {
    max-width: 728px;
  }

  @media only screen and (max-width: 1216px) {
    max-width: 624px;
  }

  @media only screen and (max-width: 1124px) {
    max-width: unset;
  }
`;

export const SectionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;

  @media only screen and (max-width: 1124px) {
    flex-direction: column;
  }
`;

export const Container = styled.div`
  max-width: 1262px;
  width: 100%;
`;
