import PejotaPlusImg from '@assets/dashboard/benefitArea/pj-plus.svg';
import ZendropImg from '@assets/dashboard/benefitArea/zendrop.svg';

import { EBenefit } from '@domain/enums/dashboard/benefitArea/EBenefit';
import { getDescription } from '@helpers/dashboard/benefitArea/benefitAreaUtils';

export const BENEFITS = [
  {
    id: 'pejota-plus',
    title: 'Pejota.Plus',
    image: {
      src: PejotaPlusImg,
      alt: 'profitfy-pejota-plus-image',
    },
    badgeText: 'Abertura gratuita de empresa',
    benefitDescription: getDescription[EBenefit.PEJOTAPLUS](),
  },
  {
    id: 'zendrop',
    title: 'Zendrop',
    image: {
      src: ZendropImg,
      alt: 'profitfy-zendrop-image',
    },
    badgeText: 'Zendrop Select',
    benefitDescription: getDescription[EBenefit.ZENDROP](),
  },
];
