import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';
import {
  ICreateVariantCostProps,
  IDeleteVariantCostProps,
  IDeleteVariantProps,
  IGetDefaultVariantCostProps,
  IGetProductProps,
  IGetProductResponse,
  IGetVariantCostsProps,
  IGetVariantsProps,
  IGetVariantsResponse,
  IUpdateALlVariantCostProps,
  IUpdateProductProps,
  IUpdateVariantCostPeriodProps,
  IUpdateVariantCostProps,
} from '@domain/interfaces/dashboard/ProductCostDetails/IProductCostDetails';

export class ProductCostDetailsService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getVariants({
    storeAliasId,
    productAliasId,
    page,
    limit = 10,
  }: IGetVariantsProps): IGetVariantsResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/variants?limit=${limit}&page=${
        page + 1
      }`,
      this.apiService,
    );

    const isLoading = !error && !data;

    return {
      variants: data?.variants,
      error,
      mutate,
      isValidating,
      isLoading,
      totalPages: data?.total_pages,
    };
  }

  public getVariantCosts({
    page,
    productAliasId,
    storeAliasId,
    variantId,
  }: IGetVariantCostsProps): Promise<any> {
    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/variants/${variantId}/variant-costs?limit=10&page=${
        page + 1
      }`,
    );
  }

  public getDefaultVariantCost({
    variantId,
    productAliasId,
    storeAliasId,
  }: IGetDefaultVariantCostProps): Promise<any> {
    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/variants/${variantId}/variant-costs/default`,
    );
  }

  public getProduct({ storeAliasId, productAliasId }: IGetProductProps): IGetProductResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      product: data?.product,
      error,
      isLoading,
      isValidating,
      mutate,
    };
  }

  public updateProduct({ storeAliasId, productAliasId, data }: IUpdateProductProps): Promise<any> {
    return this.apiService.put(`/api/v1/users/stores/${storeAliasId}/products/${productAliasId}`, {
      ...data,
    });
  }

  public createVariantCost({
    storeAliasId,
    productAliasId,
    variantAliasId,
    data,
  }: ICreateVariantCostProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/variants/${variantAliasId}/variant-costs/period`,
      { ...data },
    );
  }

  public updateAllVariantCost({
    storeAliasId,
    productAliasId,
    data,
  }: IUpdateALlVariantCostProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/variants/variant-costs/all`,
      { ...data },
    );
  }

  public updateVariantCost({
    storeAliasId,
    productAliasId,
    data,
  }: IUpdateVariantCostProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/variants/variant-costs`,
      { ...data },
    );
  }

  public updateVariantCostPeriod({
    productAliasId,
    storeAliasId,
    variantAliasId,
    variantCostAliasId,
    data,
  }: IUpdateVariantCostPeriodProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/variants/${variantAliasId}/variant-costs/${variantCostAliasId}/period`,
      { ...data },
    );
  }

  public deleteVariantCost({
    variantAliasId,
    variantCostAliasId,
    productAliasId,
    storeAliasId,
  }: IDeleteVariantCostProps): Promise<any> {
    return this.apiService.delete(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/variants/${variantAliasId}/variant-costs/${variantCostAliasId}`,
    );
  }

  public deleteVariant({
    storeAliasId,
    productAliasId,
    variantAliasId,
  }: IDeleteVariantProps): Promise<any> {
    return this.apiService.delete(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/variants/${variantAliasId}`,
    );
  }
}

const productCostDetailsService = new ProductCostDetailsService(API_DOMAIN);

export default productCostDetailsService;
