import React from 'react';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { Appmax as AppmaxIcon } from '@profitfy/pakkun-icons';
import { useOtherGatewayIntegration } from '@helpers/hooks/pages/dashboard/integrationCenter/gateway/useOtherGatewayIntegration';
import { useTheme } from 'styled-components';
import { ISideModalGatewayContent } from '@domain/interfaces/dashboard/IntegrationCenter/SideModalContent/ISideModalContent';

import IntegrateForm from '@components/Dashboard/IntegrationCenter/SideModalContent/Gateway/OtherGateway/IntegrateForm';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Appmax: React.FC<ISideModalGatewayContent> = ({ syncOnCreate, isFeedSideModal, toggle }) => {
  const theme = useTheme();

  const { isLoadingOtherGateways, otherGateways } = useOtherGatewayIntegration();

  if (isLoadingOtherGateways || Boolean(!otherGateways)) {
    return <SkeletonLoading />;
  }

  return (
    <S.Wrapper>
      <S.HeadingWrapper>
        <AppmaxIcon size={52} color={theme.colors.green.default} />
        <S.Heading type={EHeadingSize.H4}>Integração com Appmax</S.Heading>
      </S.HeadingWrapper>

      <IntegrateForm
        gatewayName="Appmax"
        gatewayId="appmax"
        isFeedSideModal={isFeedSideModal}
        syncOnCreate={syncOnCreate}
        toggle={toggle}
      />
    </S.Wrapper>
  );
};

export default Appmax;
