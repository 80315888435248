import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresPendingOrderValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresPendingOrdersValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresPendingOrderValuesResponse => {
  const currentSelectedStorePendingOrdersAmountValue =
    currentSelectedStoreFinancialDetailsReportData?.pending_orders?.amount;

  const currentSelectedStorePendingOrdersQuantityValue =
    currentSelectedStoreFinancialDetailsReportData?.pending_orders?.quantity;

  const storesPendingOrdersAmountValues = selectedStoresData?.map(
    value => value.financial_details_report?.pending_orders?.amount,
  );

  const storesPendingOrdersQuantityValues = selectedStoresData?.map(
    value => value.financial_details_report?.pending_orders?.quantity,
  );

  const pendingOrdersAmountValues = [
    ...storesPendingOrdersAmountValues,
    currentSelectedStorePendingOrdersAmountValue,
  ];

  const pendingOrdersQuantityValues = [
    ...storesPendingOrdersQuantityValues,
    currentSelectedStorePendingOrdersQuantityValue,
  ];

  const reducedPendingOrdersAmountValues = pendingOrdersAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedPendingOrdersQuantityValues = pendingOrdersQuantityValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  return {
    pending_orders_amount: reducedPendingOrdersAmountValues,
    pending_orders_quantity: reducedPendingOrdersQuantityValues,
  };
};
