import queryString from 'query-string';

import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';
import {
  IListAdSenseAdsWithoutUtmProps,
  IListAdSenseAdsWithoutUtmResponse,
} from '@domain/interfaces/dashboard/AdsManager/Onboarding/IAdSenseCampaignsWithoutUtm';

export class AdSenseAds {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listAdSenseAdsWithoutUtm({
    storeAliasId,
    filter,
  }: IListAdSenseAdsWithoutUtmProps): IListAdSenseAdsWithoutUtmResponse {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/ad-sense-ads/without-utm?${queryStringFilter}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      adSenseAds: data?.ad_sense_ads,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }
}

const adSenseAdsService = new AdSenseAds(API_DOMAIN);

export default adSenseAdsService;
