import React from 'react';

import { EUpdatedFulfillmentStatusPicker } from '@domain/enums/dashboard/automations/node/switchNode/EUpdateFulfillment';

import * as S from './styles';

interface IPickerOrderStatusProps {
  defaultValue?: string;
  onChange: (data: string) => void;
}

const PickerOrderStatus: React.FC<IPickerOrderStatusProps> = ({
  defaultValue = EUpdatedFulfillmentStatusPicker.POSTED,
  onChange,
}) => {
  return (
    <S.Select
      name="picker"
      defaultValue={defaultValue}
      onChange={e => {
        onChange(e.target.value);
      }}
    >
      <S.Option value={EUpdatedFulfillmentStatusPicker.POSTED}>Postado</S.Option>
      <S.Option value={EUpdatedFulfillmentStatusPicker.IN_TRANSIT}>Em trânsito</S.Option>
      <S.Option value={EUpdatedFulfillmentStatusPicker.OUT_FOR_DELIVERY}>
        Saiu para entrega
      </S.Option>
      <S.Option value={EUpdatedFulfillmentStatusPicker.DELIVERY_PROBLEMS}>
        Problemas na entrega
      </S.Option>
      <S.Option value={EUpdatedFulfillmentStatusPicker.AWAITING_PICKUP}>
        Aguardando retirada
      </S.Option>
      <S.Option value={EUpdatedFulfillmentStatusPicker.RETURNED_TO_SENDER}>
        Devolvido ao remetente
      </S.Option>
      <S.Option value={EUpdatedFulfillmentStatusPicker.DELIVERED}>Entregue</S.Option>
    </S.Select>
  );
};

export default PickerOrderStatus;
