import { ICustomValuesCategory } from '@domain/interfaces/dashboard/CustomValuesCategories/ICustomValuesCategories';
import { IParseCustomValuesResponse } from '@domain/interfaces/utils/customValues/customValues';

export const parseCustomValues = (
  data: Array<ICustomValuesCategory>,
): IParseCustomValuesResponse[] => {
  return data.map(customValue => ({
    label: customValue.name,
    value: customValue.id,
  }));
};
