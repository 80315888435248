import styled from 'styled-components/macro';

import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';

export const PhoneAndEmailWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;

  & > div:first-child {
    max-width: 204px;
    width: 100%;
  }

  & > div:last-child {
    max-width: 445px;
    width: 100%;
  }
`;

export const CpfAndNameWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 24px;

  & > div:first-child {
    max-width: 324px;
    width: 100%;
  }

  & > div:last-child {
    max-width: 324px;
    width: 100%;
  }
`;

export const TextField = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)``;

export const FormWrapper = styled.div``;
