import { EAutomationsTemplate } from '@domain/enums/common/automation/ETemplate';

import { getAbandonedCartNodesArray } from './templates/abandonedCart';
import { getAbandonedCart2NodesArray } from './templates/abandonedCart2';
import { getAbandonedCartFreeNodesArray } from './templates/abandonedCartFree';
import { getAbandonedCartStarterNodesArray } from './templates/abandonedCartStarter';
import { getBoletoNodesArray } from './templates/boleto';
import { getBoletoFreeNodesArray } from './templates/boletoFree';
import { getBoletoStarterNodesArray } from './templates/boletoStarter';
import { getPixNodesArray } from './templates/pix';
import { getPixFreeNodesArray } from './templates/pixFree';
import { getPixStarterNodesArray } from './templates/pixStarter';
import { getRefusedCreditCardNodesArray } from './templates/refusedCreditCard';
import { getRefusedCreditCardFreeNodesArray } from './templates/refusedCreditCardFree';
import { getRefusedCreditCardStarterNodesArray } from './templates/refusedCreditCardStarter';
import { getSimpleShipmentStarterNodesArray } from './templates/simpleShipment';

export const generateInitialNodesArray = (template: EAutomationsTemplate): Array<any> => {
  if (template === EAutomationsTemplate.ABANDONED_CART) return getAbandonedCartNodesArray();

  if (template === EAutomationsTemplate.ABANDONED_CART_2) return getAbandonedCart2NodesArray();

  if (template === EAutomationsTemplate.ABANDONED_CART_FREE)
    return getAbandonedCartFreeNodesArray();

  if (template === EAutomationsTemplate.ABANDONED_CART_STARTER)
    return getAbandonedCartStarterNodesArray();

  if (template === EAutomationsTemplate.REFUSED_CREDIT_CARD)
    return getRefusedCreditCardNodesArray();

  if (template === EAutomationsTemplate.REFUSED_CREDIT_CARD_FREE)
    return getRefusedCreditCardFreeNodesArray();

  if (template === EAutomationsTemplate.REFUSED_CREDIT_CARD_STARTER)
    return getRefusedCreditCardStarterNodesArray();

  if (template === EAutomationsTemplate.BOLETO) return getBoletoNodesArray();

  if (template === EAutomationsTemplate.BOLETO_FREE) return getBoletoFreeNodesArray();

  if (template === EAutomationsTemplate.BOLETO_STARTER) return getBoletoStarterNodesArray();

  if (template === EAutomationsTemplate.PIX) return getPixNodesArray();

  if (template === EAutomationsTemplate.PIX_FREE) return getPixFreeNodesArray();

  if (template === EAutomationsTemplate.PIX_STARTER) return getPixStarterNodesArray();

  if (template === EAutomationsTemplate.SIMPLE_SHIPMENT)
    return getSimpleShipmentStarterNodesArray();

  return [];
};
