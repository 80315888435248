import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Description = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 28px;
  font-weight: 400;
  text-align: center;
  display: inherit;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: 'Poppins', 'Open Sans';

  & > svg {
    margin-top: -2px;
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: center;

  @media only screen and (max-width: 948px) {
    gap: 12px;
  }
`;

export const Title = styled(HeadingBase)`
  text-align: center;
  line-height: 60px;
  margin-bottom: 24px;
  font-size: 3.75rem;
  color: #fff;
  font-family: 'Poppins', 'Open Sans';
  font-weight: 600;
  max-width: 800px;

  @media only screen and (max-width: 948px) {
    font-size: 3rem;
    line-height: 40px;
    text-align: center;
    margin-bottom: 40px;
  }
`;

export const PlansWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
`;

export const Header = styled.div`
  margin-bottom: 32px;
`;

export const Wrapper = styled.section`
  margin-bottom: 32px;
`;
