import Text from '@components/common/core/DataDisplay/Text';
import { brazilianStates } from '@constants/brazilianStates';
import { useAutomationsManager } from '@helpers/hooks/pages/dashboard/automations/useAutomationsManager';
import { thirdStepSchema } from '@helpers/validators/dashboard/automations/testSideModal/steps';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import Save from '../Save';
import * as S from './styles';

const ThirdStep: React.FC = () => {
  const {
    handleTestSideModalOpen,
    saveTestAutomation,
    formDataTestAutomation,
    isSavingTestAutomation,
  } = useAutomationsManager();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(thirdStepSchema),
  });

  const onSubmit = React.useCallback(
    async formData => {
      saveTestAutomation({ ...formDataTestAutomation, ...formData });
    },
    [formDataTestAutomation, saveTestAutomation],
  );

  return (
    <>
      <S.TitleForm>Dados de entrega</S.TitleForm>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label>CEP</S.Label>
          <S.Input
            {...register('zip_code')}
            type="text"
            placeholder="Digite o CEP"
            isError={errors.zip_code}
          />
          {errors.zip_code && <Text isErrorFeedback>{errors.zip_code.message}</Text>}
        </S.InputGroup>

        <S.InputsGroupWrapper>
          <S.InputGroup>
            <S.Label>Endereço</S.Label>
            <S.Input
              {...register('street')}
              type="text"
              placeholder="Digite a rua"
              isError={errors.street}
            />
            {errors.street && <Text isErrorFeedback>{errors.street.message}</Text>}
          </S.InputGroup>

          <S.InputNumberGroup>
            <S.Label>Número</S.Label>
            <S.Input
              {...register('street_number')}
              type="text"
              placeholder="Número"
              isError={errors.street_number}
            />
            {errors.street_number && <Text isErrorFeedback>{errors.street_number.message}</Text>}
          </S.InputNumberGroup>
        </S.InputsGroupWrapper>

        <S.InputGroup>
          <S.Label>Complemento (opcional)</S.Label>
          <S.Input
            {...register('complement')}
            type="text"
            placeholder="Digite o complemento"
            isError={errors.complement}
          />
          {errors.complement && <Text isErrorFeedback>{errors.complement.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Bairro</S.Label>
          <S.Input
            {...register('neighborhood')}
            type="text"
            placeholder="Digite o bairro"
            isError={errors.neighborhood}
          />
          {errors.neighborhood && <Text isErrorFeedback>{errors.neighborhood.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Cidade</S.Label>
          <S.Input
            {...register('city')}
            type="text"
            placeholder="Digite a cidade"
            isError={errors.city}
          />
          {errors.city && <Text isErrorFeedback>{errors.city.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Estado</S.Label>
          <S.Select {...register('state')}>
            {brazilianStates.map(state => (
              <S.Option key={state.abbreviation} value={state.abbreviation}>
                {state.abbreviation}
              </S.Option>
            ))}
          </S.Select>
        </S.InputGroup>

        <Save
          onCancel={handleTestSideModalOpen}
          isLastStep
          isSavingTestAutomation={isSavingTestAutomation}
        />
      </S.Form>
    </>
  );
};

export default ThirdStep;
