import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import LabelBase from '@components/common/core/DataDisplay/Label';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import AlertBase from '@components/common/core/DataDisplay/Alert';

export const Alert = styled(AlertBase)`
  margin-bottom: 24px;
`;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 36px;
`;

export const TutorialHeading = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const TutorialWrapper = styled.div`
  margin-bottom: 40px;
`;

export const FunctionalityDescriptionText = styled(TextBase)`
  font-size: 0.875rem;
`;

export const FunctionalityDescriptionHeading = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const FunctionalityDescriptionWrapper = styled.div`
  margin-bottom: 24px;
`;

export const Heading = styled(HeadingBase)`
  margin-left: 16px;
`;

export const Header = styled.div`
  margin-bottom: 40px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
`;

export const ContentWrapper = styled.div`
  max-width: 487px;
  width: 100%;
`;

export const TutorialText = styled(TextBase)`
  display: block;
  margin-top: 12px;
  font-size: 0.875rem;
`;

export const ArticleLink = styled.a`
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.green.default};
  text-decoration: none;
  margin-left: 4px;
`;
