import styled, { keyframes } from 'styled-components/macro';

const backgroundAnimation = keyframes`
  0% {
    opacity: 0;
    backdrop-filter: blur(0px);
  }
  100% {
    opacity: 1;
    backdrop-filter: blur(2px);
  }
`;

const modalAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.1);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${({ theme }) => theme.zIndex.sixthLayer};
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  animation: ${backgroundAnimation} 0.3s ease-in-out;
`;

export const ModalContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 32px;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  box-shadow: 0px 6px 18px 1px rgba(0, 0, 0, 0.06);
  z-index: ${({ theme }) => theme.zIndex.sixthLayer};
  animation: ${modalAnimation} 0.2s ease-in-out;
`;
