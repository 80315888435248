import React from 'react';
import { Task, SimpleCheck, SimpleClose } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { useCustomValuesCategories } from '@helpers/hooks/pages/dashboard/customValues/useCustomValuesCategories';
import { EGroup } from '@domain/enums/dashboard/customValuesCategories/EGroup';
import Text from '@components/common/core/DataDisplay/Text';
import Save from './SaveButton';

import ColorPicker from './ColorPicker';

import * as S from './styles';

const CreateCategorySidemodal: React.FC = () => {
  const theme: any = useTheme();
  const {
    isCreateCategorySidemodalOpen,
    handleCreateCategorySidemodalOpen,
    createCustomValuesCategory,
  } = useCustomValuesCategories();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [selectedValue, setSelectedValue] = React.useState<EGroup>(EGroup.NONE);
  const [categoryValue, setCategoryValue] = React.useState<string>('');
  const [userCategoryValue, setUserCategoryValue] = React.useState<string>('');
  const [isColorPickerOpen, setIsColorPickerOpen] = React.useState<boolean>(false);
  const [isInputButtonsOpen, setIsInputButtonsOpen] = React.useState<boolean>(false);
  const [hasCompleted, setHasCompleted] = React.useState<boolean>(false);
  const [showError, setShowError] = React.useState<boolean>(false);
  const [addNewCategoryClick, setAddNewCategoryClick] = React.useState<boolean>(false);
  const [userColor, setUserColor] = React.useState<string>('');

  const handleAddNewCategoryClick = React.useCallback(() => setAddNewCategoryClick(true), []);

  const handleUserColor = React.useCallback(color => {
    setUserColor(color);
    setHasCompleted(true);
  }, []);

  const handleHasCompleted = React.useCallback(() => {
    setHasCompleted(!hasCompleted);
  }, [hasCompleted]);

  const handleIsChoosingColor = React.useCallback(() => setIsColorPickerOpen(!isColorPickerOpen), [
    isColorPickerOpen,
  ]);

  const handleInputButtonsOpen = React.useCallback(state => {
    setIsInputButtonsOpen(state);
  }, []);

  const onSelectChange = React.useCallback(event => {
    setSelectedValue(event.target.value);
  }, []);

  const onInputChange = React.useCallback(event => {
    setCategoryValue(event.target.value);

    if (event.target.value.length >= 2) {
      setShowError(false);
    }
  }, []);

  const onInputSave = React.useCallback(() => {
    setUserCategoryValue(categoryValue);

    if (categoryValue.length < 2) {
      setShowError(true);
    } else {
      setIsInputButtonsOpen(false);
      handleIsChoosingColor();
      setShowError(false);
    }
  }, [categoryValue, handleIsChoosingColor]);

  const onInputCancel = React.useCallback(() => {
    if (inputRef?.current) {
      inputRef.current.value = userCategoryValue;

      handleInputButtonsOpen(false);
    }
  }, [userCategoryValue, handleInputButtonsOpen]);

  const onSaveClick = React.useCallback(async () => {
    await createCustomValuesCategory({
      group: selectedValue,
      hex_color: userColor,
      name: categoryValue,
    });

    setAddNewCategoryClick(false);
    handleHasCompleted();
    setSelectedValue(EGroup.NONE);
  }, [selectedValue, categoryValue, userColor, createCustomValuesCategory, handleHasCompleted]);

  return (
    <S.Sidemodal isOpen={isCreateCategorySidemodalOpen} toggle={handleCreateCategorySidemodalOpen}>
      <S.Header>
        <Task size={36} color={theme.colors.green.default} />

        <S.Title>Incluir nova categoria</S.Title>
      </S.Header>

      <S.Body>
        <S.AddCategoryLabel>Adicionar nova categoria</S.AddCategoryLabel>
        <S.AddCategoryButton onClick={handleAddNewCategoryClick}>
          + Adicionar Categoria
        </S.AddCategoryButton>

        {addNewCategoryClick && (
          <S.Select name="category_group" defaultValue={EGroup.NONE} onChange={onSelectChange}>
            <S.Option value={EGroup.NONE} disabled hidden>
              Selecione o grupo da categoria
            </S.Option>
            <S.Option value={EGroup.GENERAL_EXPENSES}>Despesas gerais</S.Option>
            <S.Option value={EGroup.ADMINISTRATIVE_EXPENSES}>Despesas administrativas</S.Option>
            <S.Option value={EGroup.MARKETING_EXPENSES}>Despesas de marketing</S.Option>
            <S.Option value={EGroup.TAX}>Taxas | Tarifas sobre vendas</S.Option>
            <S.Option value={EGroup.SHIPPING_EXPENSES}>Despesas de envio</S.Option>
            <S.Option value={EGroup.COMMISSIONS}>Comissões</S.Option>
            <S.Option value={EGroup.FINANCIAL_INCOMES}>Receitas financeiras</S.Option>
            <S.Option value={EGroup.FINANCIAL_EXPENSES}>Despesas financeiras</S.Option>
            <S.Option value={EGroup.OTHER}>Outros</S.Option>
          </S.Select>
        )}

        {!hasCompleted && selectedValue !== 'none' && (
          <>
            <S.InputWrapper>
              <S.Bullet color={userColor} onClick={handleIsChoosingColor}>
                {isColorPickerOpen && (
                  <ColorPicker
                    handleIsChoosingColor={handleIsChoosingColor}
                    onSelectColor={handleUserColor}
                  />
                )}
              </S.Bullet>

              <S.Input
                onFocus={() => handleInputButtonsOpen(true)}
                onChange={onInputChange}
                ref={inputRef}
                autoFocus
                defaultValue={categoryValue}
              />

              {isInputButtonsOpen && (
                <>
                  <S.ConfirmButton onClick={onInputSave}>
                    <SimpleCheck size={12} />
                  </S.ConfirmButton>
                  <S.CancelButton onClick={onInputCancel}>
                    <SimpleClose size={12} />
                  </S.CancelButton>
                </>
              )}
            </S.InputWrapper>
            {showError && <Text isErrorFeedback>O nome precisa ter pelo menos 2 caractéres.</Text>}
          </>
        )}

        {hasCompleted && (
          <>
            <S.CompletedInput onClick={handleHasCompleted}>
              <S.Bullet color={userColor} />
              <S.InputValueText>{userCategoryValue}</S.InputValueText>
            </S.CompletedInput>

            <Save onSave={onSaveClick} onCancel={handleCreateCategorySidemodalOpen} />
          </>
        )}
      </S.Body>
    </S.Sidemodal>
  );
};

export default CreateCategorySidemodal;
