import { ApiService } from '@services/api';
import { USER_API_DOMAIN } from '@constants/api';

import { IAddTrialDaysProps } from '@domain/interfaces/common/trialDays/addTrialDays';

export class TrialDaysService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public addTrialDays({ store_alias_id, data }: IAddTrialDaysProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${store_alias_id}/subscriptions/add-trial-days`,
      {
        ...data,
      },
    );
  }
}

const trialDaysService = new TrialDaysService(USER_API_DOMAIN);

export default trialDaysService;
