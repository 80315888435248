import React from 'react';

import {
  ABANDONED_CART_TRIGGER_VARIABLES,
  BOLETO_TRIGGER_VARIABLES,
  PIX_TRIGGER_VARIABLES,
  REJECTED_PAYMENT_TRIGGER_VARIABLES,
  SIMPLE_SHIPMENT_TRIGGER_VARIABLES,
  UPDATED_FULFILLMENT_TRIGGER_VARIABLES,
} from '@constants/pages/dashboard/automations/messages/triggers';
import {
  EConfigOption,
  ECreateMessageStep,
  EMessageContentType,
  EMessageType,
  ESelectedCategoryOption,
} from '@domain/enums/dashboard/automations/EMessage';
import { ICanvasMessageSettingsProvider } from '@domain/interfaces/common/automations/ICanvasMessage';
import {
  // ICreateMessageData,
  IMessage,
  IMessageContent,
} from '@domain/interfaces/common/automations/IMessage';
import { ISavedContent } from '@domain/interfaces/common/automations/ISavedContent';

const CanvasMessageSettingsContext = React.createContext<ICanvasMessageSettingsProvider | null>(
  null,
);

export const CanvasMessageSettingsProvider: React.FC = ({ children }) => {
  const [messageToBeSearched, setMessageToBeSearched] = React.useState<string>('');
  const [
    selectedCategoryOption,
    setSelectedCategoryOption,
  ] = React.useState<ESelectedCategoryOption>(ESelectedCategoryOption.ALL_MESSAGES);
  const [contents, setContents] = React.useState<Array<ISavedContent>>([]);
  const [contentsToUpdate, setContentsToUpdate] = React.useState<Array<ISavedContent>>([]);
  const [selectedMessageTrigger, setSelectedMessageTrigger] = React.useState<
    EMessageType | undefined
  >(undefined);
  // const [isNewMessageModalOpen, setIsNewMessageModalOpen] = React.useState<boolean>(false);
  const [selectedMessageToUpdate, setSelectedMessageToUpdate] = React.useState<
    IMessage | undefined
  >(undefined);
  // const [isUpdateMessageModalOpen, setIsUpdateMessageModalOpen] = React.useState<boolean>(false);
  const [isTemplateMessage, setIsTemplateMessage] = React.useState<boolean>(false);
  const [messageName, setMessageName] = React.useState<string>('');
  const [arrayToChangePosition, setArrayToChangePosition] = React.useState<Array<ISavedContent>>(
    [],
  );
  const [
    showUpdateMessageContentOnWhatsappNode,
    setShowUpdateMessageContentOnWhatsappNode,
  ] = React.useState<boolean>(false);
  const [
    showUpdateDefaultMessageContentOnWhatsappNode,
    setShowUpdateDefaultMessageContentOnWhatsappNode,
  ] = React.useState<boolean>(false);
  const [
    showNewMessageContentOnWhatsappNode,
    setShowNewMessageContentOnWhatsappNode,
  ] = React.useState<boolean>(false);
  const [createNewMessageStep, setCreateNewMessageStep] = React.useState<ECreateMessageStep>(
    ECreateMessageStep.SELECT_MESSAGE_TEMPLATE,
  );
  const [shouldCloseModal, setShouldCloseModal] = React.useState<boolean>(false);
  const [selectedDefaultMessageToUpdate, setSelectedDefaultMessageToUpdate] = React.useState<
    IMessage | undefined
  >(undefined);

  const resetOnMessageModalClose = React.useCallback(() => {
    setMessageToBeSearched('');
    setContents([]);
    setContentsToUpdate([]);
    setSelectedMessageTrigger(undefined);
    setShowNewMessageContentOnWhatsappNode(false);
    setShowUpdateMessageContentOnWhatsappNode(false);
    setShowUpdateDefaultMessageContentOnWhatsappNode(false);
    setSelectedMessageToUpdate(undefined);
    setCreateNewMessageStep(ECreateMessageStep.SELECT_MESSAGE_TEMPLATE);
  }, []);

  const handleShouldCloseModal = React.useCallback(state => setShouldCloseModal(state), []);

  const handleMessageToBeSearched = React.useCallback(
    newValue => setMessageToBeSearched(newValue),
    [],
  );

  const handleSelectedCategoryOption = React.useCallback(
    newCategoryOption => setSelectedCategoryOption(newCategoryOption),
    [],
  );

  const handleContents = React.useCallback(newContents => setContents(newContents), []);

  const handleContentsToUpdate = React.useCallback(
    newContents => setContentsToUpdate(newContents),
    [],
  );

  const handleSelectedMessageTrigger = React.useCallback(
    newMessageTrigger => setSelectedMessageTrigger(newMessageTrigger),
    [],
  );

  const handleSelectedMessageToUpdate = React.useCallback(
    messageToUpdate => setSelectedMessageToUpdate(messageToUpdate),
    [],
  );

  const handleSelectedDefaultMessageToUpdate = React.useCallback(
    messageToUpdate => setSelectedDefaultMessageToUpdate(messageToUpdate),
    [],
  );

  const handleMessageName = React.useCallback(newName => setMessageName(newName), []);

  const handleArrayToChangePosition = React.useCallback(
    newArray => setArrayToChangePosition(newArray),
    [],
  );

  const handleShowUpdateDefaultMessageContentOnWhatsappNode = React.useCallback(
    () => setShowUpdateDefaultMessageContentOnWhatsappNode(state => !state),
    [],
  );

  const handleShowUpdateMessageContentOnWhatsappNode = React.useCallback(
    () => setShowUpdateMessageContentOnWhatsappNode(state => !state),
    [],
  );

  const handleShowNewMessageContentOnWhatsappNode = React.useCallback(
    () => setShowNewMessageContentOnWhatsappNode(state => !state),
    [],
  );

  const handleTemplateMessage = React.useCallback(state => setIsTemplateMessage(state), []);

  const handleCreateNewMessageStep = React.useCallback(step => setCreateNewMessageStep(step), []);

  const getTriggerVariables = React.useCallback(() => {
    if (selectedMessageTrigger === EMessageType.ABANDONED_CART)
      return ABANDONED_CART_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.BOLETO) return BOLETO_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.PIX) return PIX_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.SIMPLE_SHIPMENT)
      return SIMPLE_SHIPMENT_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.REJECTED_PAYMENT)
      return REJECTED_PAYMENT_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.UPDATED_FULFILLMENT)
      return UPDATED_FULFILLMENT_TRIGGER_VARIABLES;

    return [];
  }, [selectedMessageTrigger]);

  React.useEffect(() => {
    if (!selectedMessageToUpdate && !selectedDefaultMessageToUpdate) return;

    let messageContents: Array<IMessageContent> = [];

    if (selectedMessageToUpdate) {
      messageContents = selectedMessageToUpdate.message_contents;
      setSelectedMessageTrigger(selectedMessageToUpdate.type);
    }

    if (selectedDefaultMessageToUpdate) {
      messageContents = selectedDefaultMessageToUpdate.message_contents;
      setSelectedMessageTrigger(selectedDefaultMessageToUpdate.type);
    }

    const newData: Array<ISavedContent> = [];

    messageContents.forEach(content => {
      if (content.type === EMessageContentType.TEXT) {
        const newValue = content.content;

        let modifiedText = newValue;

        const triggerVariables = getTriggerVariables();

        triggerVariables.forEach(variable => {
          modifiedText = modifiedText.replace(variable.data, variable.nameToDisplay);
        });

        newData.push({
          id: content.alias_id || content.id,
          type: EConfigOption.TEXT,
          content: modifiedText,
          position: content.position,
        });
      }

      if (content.type === EMessageContentType.IMAGE) {
        newData.push({
          id: content.alias_id || content.id,
          type: EConfigOption.IMAGE,
          content: content.content_public_url,
          position: content.position,
        });
      }

      if (content.type === EMessageContentType.AUDIO) {
        newData.push({
          id: content.alias_id || content.id,
          type: EConfigOption.AUDIO,
          content: {},
          position: content.position,
        });
      }

      if (content.type === EMessageContentType.VIDEO) {
        newData.push({
          id: content.alias_id || content.id,
          type: EConfigOption.VIDEO,
          content: content.content_public_url,
          position: content.position,
        });
      }

      if (content.type === EMessageContentType.PRODUCT_IMAGE) {
        newData.push({
          id: content.alias_id || content.id,
          type: EConfigOption.PRODUCT_IMAGE,
          content: content.content_public_url,
          position: content.position,
        });
      }

      if (content.type === EMessageContentType.PDF) {
        newData.push({
          id: content.alias_id || content.id,
          type: EConfigOption.PDF,
          content: content.content_public_url,
          position: content.position,
        });
      }
    });

    setContentsToUpdate(newData);
  }, [getTriggerVariables, selectedMessageToUpdate, selectedDefaultMessageToUpdate]);

  return (
    <CanvasMessageSettingsContext.Provider
      value={{
        messageToBeSearched,
        selectedCategoryOption,
        contents,
        contentsToUpdate,
        selectedMessageTrigger,
        selectedMessageToUpdate,
        selectedDefaultMessageToUpdate,
        messageName,
        arrayToChangePosition,
        showUpdateMessageContentOnWhatsappNode,
        showUpdateDefaultMessageContentOnWhatsappNode,
        createNewMessageStep,
        isTemplateMessage,
        showNewMessageContentOnWhatsappNode,
        shouldCloseModal,
        resetOnMessageModalClose,
        handleContents,
        handleContentsToUpdate,
        handleMessageToBeSearched,
        handleSelectedCategoryOption,
        handleSelectedMessageTrigger,
        handleSelectedMessageToUpdate,
        handleSelectedDefaultMessageToUpdate,
        handleMessageName,
        handleArrayToChangePosition,
        handleShowUpdateMessageContentOnWhatsappNode,
        handleShowUpdateDefaultMessageContentOnWhatsappNode,
        handleCreateNewMessageStep,
        handleTemplateMessage,
        handleShowNewMessageContentOnWhatsappNode,
        handleShouldCloseModal,
      }}
    >
      {children}
    </CanvasMessageSettingsContext.Provider>
  );
};

export const useCanvasMessageSettings = (): ICanvasMessageSettingsProvider => {
  const context = React.useContext(CanvasMessageSettingsContext);

  if (!context)
    throw new Error('useCanvasMessageSettings must be used within CanvasMessageSettingsProvider');

  return context;
};
