import React from 'react';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { ICpa } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/ISummaryReport';

import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const Cpa: React.FC<ICampaignProps> = ({ data }) => {
  const cpaData: ICpa = data;

  return (
    <S.Wrapper>
      <S.CpaAmount>{`${numberFormatter(cpaData.average_amount, 1)}x`}</S.CpaAmount>
      <S.CpaLabel>Média</S.CpaLabel>
    </S.Wrapper>
  );
};

export default Cpa;
