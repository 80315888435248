import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 120px;
  align-self: flex-start;
  padding-top: 134px;

  @media only screen and (max-width: 1072px) {
    gap: 40px;
  }

  @media only screen and (max-width: 992px) {
    flex-direction: column;
    padding-top: 48px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 122px;
    left: 178px;
    width: 107px;
    height: 87px;
    background-image: url('https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/productAnalytics/topLeftDetail.svg');
    background-repeat: no-repeat;

    @media only screen and (max-width: 770px) {
      display: none;
    }
  }

  &::after {
    content: '';
    position: fixed;
    bottom: 0;
    left: 147px;
    width: 250px;
    height: 174px;
    background-image: url('https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/productAnalytics/bottomLeftDetail.svg');
    background-repeat: no-repeat;

    @media only screen and (max-width: 992px) {
      display: none;
    }

    @media only screen and (max-height: 755px) {
      display: none;
    }
  }
`;

export const Image = styled(ImageBase)`
  width: 100%;
  max-width: 433px;

  @media only screen and (max-width: 992px) {
    max-width: 355px;
    margin-right: 60px;
  }

  @media only screen and (max-width: 420px) {
    margin-right: unset;
  }
`;

export const Content = styled.div`
  max-width: 410px;
  display: flex;
  flex-direction: column;

  &::before {
    content: '';
    position: absolute;
    bottom: 76px;
    left: 50%;
    width: 12px;
    height: 15px;
    background-image: url('https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/productAnalytics/hamburguerDetail.svg');
    background-repeat: no-repeat;

    @media only screen and (max-width: 992px) {
      display: none;
    }

    @media only screen and (max-height: 750px) {
      display: none;
    }
  }

  &::after {
    content: '';
    position: fixed;
    bottom: 0;
    right: 0;
    width: 148px;
    height: 285px;
    background-image: url('https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/productAnalytics/bottomRightDetail.svg');
    background-repeat: no-repeat;

    @media only screen and (max-width: 770px) {
      display: none;
    }

    @media only screen and (max-height: 880px) {
      display: none;
    }
  }
`;

export const Heading = styled(HeadingBase)`
  margin-bottom: 24px;

  span {
    font-size: inherit;
    color: ${({ theme }) => theme.colors.green.default};
  }
`;

export const BenefitsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 24px;
`;

export const Benefit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const BenefitIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 24px;
  height: 24px;
  background-color: #24363f;
`;

export const BenefitText = styled(TextBase)``;

export const ButtonsWrapper = styled.div`
  width: 100%;
  max-width: 337px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media only screen and (max-width: 420px) {
    max-width: unset;
  }
`;

export const CreateTagButton = styled(ButtonBase)`
  span {
    font-size: 0.875rem;
  }
`;

export const HorizontalRuleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
`;

export const HorizontalRuleText = styled(TextBase)``;

export const HorizontalRule = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
`;

export const SkipOnboardingButton = styled(ButtonBase)`
  span {
    font-size: 0.875rem;
  }
`;
