import styled from 'styled-components/macro';

import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import DayPickerBase from '@components/common/core/Utils/DayPicker';

export const CheckboxGroup = styled(InputGroupBase)`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: row;
  margin-bottom: 16px;

  & > label {
    margin: unset;
  }
`;

export const DayPicker = styled(DayPickerBase)`
  margin-bottom: 92px;
`;

export const Checkbox = styled(CheckboxBase)``;

export const Option = styled(OptionBase)``;

export const Select = styled(SelectBase)``;

export const Input = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 32px;
`;

export const Wrapper = styled.div``;
