import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { SimpleMinus, SimplePlus, Cancel, Check, SimpleClose } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { CircleSpinner } from 'react-spinners-kit';

import { ISynchronization } from '@domain/interfaces/hooks/IStoreSynchronization';
import { ESynchronizationStatus } from '@domain/enums/hooks/ESynchronization';

import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import {
  getSynchronizationNameIcon,
  groupAndSortSynchronizations,
} from '@helpers/utils/components/synchronization';

import * as S from './styles';

const DraggableItem: React.FC<any> = ({ top, left }) => {
  const theme = useTheme();
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: 'draggable',
  });
  const {
    currentSynchronizations,
    synchronizationsQueue,
    finishedSynchronizations,
    failedSynchronizations,
    handleFailedSynchronizations,
    handleFinishedSynchronizations,
  } = useSynchronization();

  const parsedCurrentSynchronizations = currentSynchronizations
    ? currentSynchronizations.synchronizations
    : [];

  const mergedSynchronizations: Array<ISynchronization> = [
    ...parsedCurrentSynchronizations,
    ...synchronizationsQueue,
    ...finishedSynchronizations,
    ...failedSynchronizations,
  ];

  const filteredMergedSynchronizations = mergedSynchronizations.filter(
    synchronization => synchronization.config.showNotification,
  );

  const [isMaximized, setIsMaximized] = React.useState<boolean>(true);

  const wrapperRef = React.useRef<HTMLDivElement | HTMLButtonElement | null>(null);

  const handleIsMaximized = React.useCallback(() => setIsMaximized(!isMaximized), [isMaximized]);

  const handleCloseSynchronizations = React.useCallback(() => {
    handleFailedSynchronizations([]);
    handleFinishedSynchronizations([]);
  }, [handleFailedSynchronizations, handleFinishedSynchronizations]);

  const getStatusIcon = React.useCallback(
    (status: ESynchronizationStatus) =>
      ({
        COMPLETED: <Check size={14} outline color={theme.colors.green.default} />,
        PROCESSING: <CircleSpinner size={10} color={theme.colors.green.default} />,
        ERROR: <Cancel size={14} outline color={theme.colors.danger.default} />,
        IN_QUEUE: <S.WaitingIcon />,
      }[status]),
    [theme],
  );

  const getStatusName = React.useCallback(
    (status: ESynchronizationStatus) =>
      ({
        COMPLETED: 'Completo',
        PROCESSING: 'Em Progresso',
        ERROR: 'Com Erro',
        IN_QUEUE: 'Na Fila',
      }[status]),
    [],
  );

  React.useEffect(() => {
    if (wrapperRef.current) {
      const topPosition = top;
      const leftPosition =
        wrapperRef.current.offsetWidth + left <= window.innerWidth
          ? left
          : window.innerWidth - wrapperRef.current.offsetWidth;

      wrapperRef.current.style.top = `${topPosition}px`;
      wrapperRef.current.style.right = `${leftPosition * -1}px`;
    }
  }, [left, top]);

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  const groupedAndSortedSynchronizations = groupAndSortSynchronizations(
    filteredMergedSynchronizations,
  );

  return (
    <S.Wrapper
      ref={ref => {
        setNodeRef(ref);
        wrapperRef.current = ref;
      }}
      style={{ ...style }}
      {...attributes}
    >
      <S.Header>
        <S.DragHandler {...listeners}>Sincronizando dados</S.DragHandler>
        {parsedCurrentSynchronizations.length ? (
          <S.HeaderIconWrapper onClick={handleIsMaximized}>
            {isMaximized ? (
              <SimpleMinus size={12} color={theme.colors.font} />
            ) : (
              <SimplePlus size={12} color={theme.colors.font} />
            )}
          </S.HeaderIconWrapper>
        ) : (
          <S.HeaderIconWrapper onClick={handleCloseSynchronizations}>
            <SimpleClose size={12} color={theme.colors.font} />
          </S.HeaderIconWrapper>
        )}
      </S.Header>

      {isMaximized && (
        <S.ContentWrapper>
          {groupedAndSortedSynchronizations.map(group => {
            const GroupIcon = getSynchronizationNameIcon(group.name);

            return (
              <S.SynchronizationGroup key={group.name}>
                <S.GroupHeader>
                  <GroupIcon size={16} />
                  <S.GroupTitle>{group.name}</S.GroupTitle>
                </S.GroupHeader>

                <S.SynchronizationsWrapper>
                  {group.synchronizations.map(synchronization => {
                    const synchronizationStatusIcon = getStatusIcon(synchronization.status);
                    const synchronizationStatusLabel = getStatusName(synchronization.status);

                    return (
                      <S.SynchronizationWrapper
                        key={`${synchronization.name}-${synchronization.label}-${synchronization.createdAt}-${synchronization.status}`}
                      >
                        <S.SynchronizationLabel>
                          {synchronizationStatusIcon}
                          {synchronization.label}
                        </S.SynchronizationLabel>
                        <S.SynchronizationProgress>
                          {synchronizationStatusLabel}
                        </S.SynchronizationProgress>
                      </S.SynchronizationWrapper>
                    );
                  })}
                </S.SynchronizationsWrapper>
              </S.SynchronizationGroup>
            );
          })}
        </S.ContentWrapper>
      )}
    </S.Wrapper>
  );
};

export default DraggableItem;
