import React from 'react';
import { useParams } from 'react-router-dom';

import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { IProductDetailsProvider } from '@domain/interfaces/dashboard/ProductAnalytics/IProductDetails';
import productNetProfitService from '@services/pages/dashboard/productAnalytics/productNetProfit';
import productMarketingService from '@services/pages/dashboard/productAnalytics/productMarketing';
import productFinancialService from '@services/pages/dashboard/productAnalytics/productFInancial';
import { IParams } from '@domain/interfaces/IParams';
import { IPeriod } from '@domain/interfaces/components/IDatePicker';
import { useDate } from '@helpers/hooks/useDate';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { EStatus } from '@domain/enums/subscription/ESubscription';
import adSenseCampaignsService from '@services/pages/dashboard/adSense/adSenseCampaign';
import customValueCategoriesService from '@services/pages/dashboard/customValuesCategories/customValueCategories';

const ProductDetailsContext = React.createContext<IProductDetailsProvider | null>(null);

export const ProductDetailsProvider: React.FC = ({ children }) => {
  const { storeAliasId, productAliasId } = useParams<IParams>();
  const { utcToZonedTime, format, subDays, differenceInDays, addDays } = useDate();
  const { subscription, hasAllPermissions } = useStoreSubscription();

  const getMaxDate = React.useCallback(() => {
    if (
      subscription?.plan?.identifier.includes('free') &&
      subscription.exceeded_order_limit_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(subscription?.exceeded_order_limit_at);
    }

    const isSubscriptionPeriodEndedAtAfterNow = subscription?.period_ended_at
      ? differenceInDays(utcToZonedTime(new Date()), new Date(subscription.period_ended_at)) >= 0
      : true;

    if (subscription?.status === EStatus.CANCELED && isSubscriptionPeriodEndedAtAfterNow) {
      return utcToZonedTime(new Date());
    }

    if (
      subscription?.status === EStatus.CANCELED &&
      subscription?.period_ended_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(addDays(new Date(subscription.period_ended_at), 1));
    }

    if (
      subscription?.status === EStatus.CANCELED &&
      subscription?.canceled_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(subscription.canceled_at);
    }

    if (subscription && subscription.status === EStatus.TRIAL_ENDED && !hasAllPermissions) {
      return utcToZonedTime(addDays(new Date(subscription.period_ended_at), 1));
    }

    return utcToZonedTime(new Date());
  }, [subscription, utcToZonedTime, hasAllPermissions, differenceInDays, addDays]);

  const initialDate = getMaxDate();

  const [period, setPeriod] = React.useState<IPeriod>({
    startDate: initialDate,
    endDate: initialDate,
  });
  const [funnelChartProvider, setFunnelChartProvider] = React.useState<EAdSenseProvider>(
    EAdSenseProvider.FACEBOOK,
  );

  const {
    productNetProfitChart,
    isLoading: isLoadingProductNetProfitChart,
    isValidating: isValidatingProductNetProfitChart,
    mutate: mutateProductNetProfitChart,
    error: productNetProfitChartError,
  } = productNetProfitService.listProductNetProfitChart({
    storeAliasId,
    productAliasId,
    startDate: format(subDays(initialDate, 6), 'yyyy-MM-dd'),
    endDate: format(initialDate, 'yyyy-MM-dd'),
  });
  const {
    marketingReport,
    isLoading: isLoadingMarketingReport,
    isValidating: isValidatingMarketingReport,
    mutate: mutateMarketingReport,
    error: marketingReportError,
  } = productMarketingService.getProductMarketing({
    storeAliasId,
    productAliasId,
    startDate: format(period.startDate, 'yyyy-MM-dd'),
    endDate: format(period.endDate, 'yyyy-MM-dd'),
  });
  const {
    product,
    productFinancialDetailsReport,
    productFinancialReport,
    isLoading: isLoadingProductFinancial,
    isValidating: isValidatingProductFinancial,
    mutate: mutateProductFinancial,
    error: productFinancialError,
  } = productFinancialService.getProductFinancial({
    storeAliasId,
    productAliasId,
    startDate: format(period.startDate, 'yyyy-MM-dd'),
    endDate: format(period.endDate, 'yyyy-MM-dd'),
  });
  const {
    adSenseCampaigns,
    isLoading: isLoadingAdSenseCampaigns,
    isValidating: isValidatingAdSenseCampaigns,
    mutate: mutateAdSenseCampaigns,
    error: adSenseCampaignsError,
  } = adSenseCampaignsService.listAdSenseCampaigns({
    storeAliasId,
    productAliasId,
    page: 1,
    filter: { name: '' },
  });
  const {
    customValuesCategories,
    mutate: mutateCustomValuesCategories,
    error: customValuesCategoriesError,
    isLoading: isLoadingCustomValuesCategories,
    isValidating: isValidatingCustomValuesCategories,
  } = customValueCategoriesService.getCustomValuesCategories({ storeAliasId });

  const mutateProductDetails = React.useCallback(async () => {
    const promises = [];

    if (productNetProfitChartError) promises.push(mutateProductNetProfitChart());

    if (productFinancialError) promises.push(mutateProductFinancial());

    if (adSenseCampaignsError) promises.push(mutateAdSenseCampaigns());

    if (customValuesCategoriesError) promises.push(mutateCustomValuesCategories());

    await Promise.all(promises);
  }, [
    productNetProfitChartError,
    mutateProductNetProfitChart,
    productFinancialError,
    mutateProductFinancial,
    adSenseCampaignsError,
    mutateAdSenseCampaigns,
    customValuesCategoriesError,
    mutateCustomValuesCategories,
  ]);

  const handleFunnelChartProvider = React.useCallback(
    provider => setFunnelChartProvider(provider),
    [],
  );

  const isLoadingProductDetails =
    isLoadingProductNetProfitChart ||
    isLoadingProductFinancial ||
    isLoadingAdSenseCampaigns ||
    isLoadingCustomValuesCategories;
  const isValidatingProductDetails =
    isValidatingProductNetProfitChart ||
    isValidatingProductFinancial ||
    isValidatingAdSenseCampaigns ||
    isValidatingCustomValuesCategories;
  const isProductDetailsError =
    Boolean(productNetProfitChartError) ||
    Boolean(productFinancialError) ||
    Boolean(adSenseCampaignsError) ||
    Boolean(customValuesCategoriesError);

  return (
    <ProductDetailsContext.Provider
      value={{
        funnelChartProvider,
        handleFunnelChartProvider,
        mutateProductDetails,
        adSenseCampaigns,
        productFinancialReport,
        productFinancialDetailsReport,
        product,
        marketingReport,
        productNetProfitChart,
        isLoadingMarketingReport,
        isValidatingMarketingReport,
        marketingReportError,
        mutateMarketingReport,
        period,
        setPeriod,
        isLoadingProductDetails,
        isProductDetailsError,
        isValidatingProductDetails,
        customValuesCategories,
        mutateCustomValuesCategories,
      }}
    >
      {children}
    </ProductDetailsContext.Provider>
  );
};

export const useProductDetails = (): IProductDetailsProvider => {
  const context = React.useContext(ProductDetailsContext);

  if (!context) {
    throw new Error('useProductDetails must be used within ProductDetailsContext');
  }

  return context;
};
