import styled from 'styled-components/macro';

import SideModalBase from '@components/common/core/DataDisplay/SideModal';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';

export const SideModal = styled(SideModalBase)``;

export const ContentWrapper = styled.div``;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 22px;
  gap: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
  margin-bottom: 24px;
`;

export const Heading = styled(HeadingBase)`
  line-height: 100%;
`;

export const Description = styled(TextBase)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray[2]};
  margin-bottom: 12px;
`;

export const Example = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const InputGroup = styled(InputGroupBase)`
  margin: 24px 0;
`;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)``;
