import { MarkerType } from 'react-flow-renderer';

const defaultProps = {
  animated: true,
  type: 'buttonedge',
  style: {
    strokeWidth: 4,
  },
  markerEnd: {
    type: MarkerType.ArrowClosed,
    orient: 'auto',
  },
};

export const REFUSED_CREDIT_CARD_EDGES = [
  {
    ...defaultProps,
    id: `reactflow__edge-node1-node2`,
    source: `node1`,
    target: `node2`,
  },
  {
    ...defaultProps,
    id: `reactflow__edge-node2-node3`,
    source: `node2`,
    target: `node3`,
  },
  {
    ...defaultProps,
    id: `reactflow__edge-node3-node4`,
    source: `node3`,
    target: `node4`,
  },
  {
    ...defaultProps,
    id: `reactflow__edge-node4-node5`,
    source: `node4`,
    target: `node5`,
  },
  {
    ...defaultProps,
    id: `reactflow__edge-node5-node6`,
    sourceHandle: 'positive',
    source: `node5`,
    target: `node6`,
  },
  {
    ...defaultProps,
    id: `reactflow__edge-node5-nodeEnd1`,
    sourceHandle: 'negative',
    source: `node5`,
    target: `nodeEnd1`,
  },
  {
    ...defaultProps,
    id: `reactflow__edge-node6-node7`,
    source: `node6`,
    target: `node7`,
  },
  {
    ...defaultProps,
    id: `reactflow__edge-node7-node8`,
    source: `node7`,
    target: `node8`,
  },
  {
    ...defaultProps,
    id: `reactflow__edge-node8-node9`,
    sourceHandle: 'positive',
    source: `node8`,
    target: `node9`,
  },
  {
    ...defaultProps,
    id: `reactflow__edge-node8-nodeEnd2`,
    sourceHandle: 'negative',
    source: `node8`,
    target: `nodeEnd2`,
  },
  {
    ...defaultProps,
    id: `reactflow__edge-node9-node10`,
    source: `node9`,
    target: `node10`,
  },
];
