import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import SideModalBase from '@components/common/core/DataDisplay/SideModal';

export const SideModal = styled(SideModalBase)`
  width: fit-content;
  max-width: unset;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 18px;
  padding-bottom: 22px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
  margin-bottom: 32px;
`;

export const Heading = styled(HeadingBase)``;
