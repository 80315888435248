import styled from 'styled-components/macro';

export const DateButton = styled.button`
  background-color: transparent;
  border: none;
  width: fit-content;
  padding: 4px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    span {
      color: ${({ theme }) => theme.colors.green.default};
    }
  }

  span {
    color: ${({ theme }) => theme.colors.font};
    transition: all 0.3s;
  }
`;

export const PreDefinedDatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
