import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { EEcommerceProvider } from '@domain/enums/common/integrations/EEcommerceProvider';
import { EAlertVariant } from '@domain/enums/components/EAlert';
import { EGateway } from '@domain/enums/common/EGateway';
import { IParams } from '@domain/interfaces/IParams';

import { useDate } from '@helpers/hooks/useDate';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { getSynchronizationStatus } from '@helpers/utils/common/synchronization';

import { gatewaySyncSchema } from '@helpers/validators/dashboard/integrationCenter/gatewaySync';

import { GATEWAYS, ECOMMERCE_PROVIDERS } from '@constants/common/integrations';

import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';
import Label from '@components/common/core/DataDisplay/Label';

import * as S from './styles';

const GatewayOption: React.FC = () => {
  const foundIntegration = ECOMMERCE_PROVIDERS.find(
    integration => integration.identifier === EEcommerceProvider.SHOPIFY,
  ) as typeof ECOMMERCE_PROVIDERS[number];

  const { storeIntegrations } = useStoreConfig();
  const { utcToZonedTime, format } = useDate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(gatewaySyncSchema) });
  const { storeAliasId } = useParams<IParams>();
  const {
    addSynchronization,
    currentSynchronizations,
    synchronizationsQueue,
  } = useSynchronization();

  const [storeGateways, setStoreGateways] = React.useState<Array<typeof GATEWAYS[number]>>([]);
  const [period, setPeriod] = React.useState({
    startDate: utcToZonedTime(new Date()),
    endDate: utcToZonedTime(new Date()),
  });

  const onSubmit = React.useCallback(
    async data => {
      const { gateway: selectedGateway } = data;

      const foundSelectedGateway = storeGateways.find(
        integration => integration.identifier === selectedGateway,
      ) as typeof GATEWAYS[number];

      if (foundSelectedGateway) {
        const params = `?start_date=${format(period.startDate, 'yyyy-MM-dd')}&end_date=${format(
          period.endDate,
          'yyyy-MM-dd',
        )}`;

        addSynchronization({
          name: foundIntegration.name,
          type: ESynchronizationType.STORE_GATEWAY,
          label: foundSelectedGateway.name,
          dependencies: [ESynchronizationType.STORE_PRODUCT, ESynchronizationType.STORE_ORDER],
          storeAliasId,
          config: {
            isOnboardingSynchronization: false,
            showNotification: true,
          },
          request: foundIntegration.generateGatewaySyncRequest(
            foundSelectedGateway.synchronizationName,
            storeAliasId,
            params,
          ),
        });
      }
    },
    [format, period, storeAliasId, addSynchronization, foundIntegration, storeGateways],
  );

  React.useEffect(() => {
    if (storeIntegrations) {
      const filteredActiveGateways = storeIntegrations.gateways.filter(
        integration => integration.is_active,
      );

      const foundActiveOtherGateway = storeIntegrations.gateways.find(
        integration => integration.other_gateway?.has_other_gateway,
      );

      const otherGateways = foundActiveOtherGateway?.other_gateway?.gateway_names || [];

      const storeActiveGateways = GATEWAYS.filter(integration =>
        filteredActiveGateways.find(
          activeIntegration => activeIntegration.name === integration.identifier,
        ),
      );

      const activeGateways = storeActiveGateways.filter(integration =>
        foundIntegration.gateways.includes(integration.identifier),
      );

      const predefinedGateways = GATEWAYS.filter(gateway => gateway.isPredefinedOtherGateway);

      const activePredefinedGateways = predefinedGateways.filter(gateway =>
        otherGateways.includes(gateway.identifier),
      );

      activeGateways.push(...activePredefinedGateways);

      if (otherGateways.includes('OTHER')) {
        const otherGateway = GATEWAYS.find(
          gateway => gateway.identifier === EGateway.OTHER_GATEWAY,
        ) as typeof GATEWAYS[number];

        activeGateways.push(otherGateway);
      }

      setStoreGateways(activeGateways);
    }
  }, [storeIntegrations, foundIntegration]);

  const maxDate = utcToZonedTime(new Date());

  const isSyncingGateway = getSynchronizationStatus(
    currentSynchronizations,
    synchronizationsQueue,
    foundIntegration.name,
    ESynchronizationType.STORE_GATEWAY,
    storeAliasId,
  );

  return (
    <S.GatewayOptionWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <Label>Selecione o Gateway para conciliar:</Label>

          <S.Select {...register('gateway')} defaultValue="">
            <S.Option value="" disabled hidden>
              Selecione um gateway
            </S.Option>
            {storeGateways.map(integration => (
              <S.Option value={integration.identifier} key={integration.identifier}>
                {integration.name}
              </S.Option>
            ))}
          </S.Select>
          {errors.gateway && <Text isErrorFeedback>{errors.gateway.message}</Text>}
        </S.InputGroup>

        <S.Alert variant={EAlertVariant.WARNING} outlineIcon>
          O período para selecionar poderá ser no máximo 30 dias.
        </S.Alert>

        <S.InputGroup>
          <S.CustomDateRangePicker
            period={period}
            setPeriod={setPeriod}
            maxRange={30}
            maxDate={maxDate}
          />
        </S.InputGroup>

        <S.Button type="submit" isLoading={isSyncingGateway} disabled={isSyncingGateway}>
          Sincronizar
        </S.Button>
      </Form>
    </S.GatewayOptionWrapper>
  );
};

export default GatewayOption;
