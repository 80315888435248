import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const AmountText = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const LabelText = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export const TextContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
`;

export const ConversionPercentage = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% - 4px), calc(-50% - 4px));
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: 0.625rem;
  font-weight: 500;
  color: #b78aff;
`;

export const ChartWrapper = styled.div`
  min-width: 46px;
  width: 46px;
  min-height: 46px;
  height: 46px;
  position: relative;

  & > div {
    position: absolute;
    top: -4px;
    left: -4px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 38px;
  display: flex;
  justify-content: center;
  gap: 8px;
  overflow: hidden;
`;

export const TotalLabel = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
  line-height: 120%;
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 4px;
  overflow: hidden;
`;
