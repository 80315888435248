import React from 'react';
import { useTheme } from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';
import { ChevronDown, Cancel } from '@profitfy/pakkun-icons';

import { EBadgeColors } from '@domain/enums/components/EBadge';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { IParams } from '@domain/interfaces/IParams';

import { useAccessManager } from '@helpers/hooks/pages/dashboard/accessManagement/useAccessManager';
import { useToast } from '@helpers/hooks/useToast';

import GroupRow from './GroupRow';

import * as S from './styles';

const GroupTable: React.FC = () => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const theme = useTheme();
  const { toast } = useToast();
  const {
    handleDeleteGroupModalOpen,
    groups,
    selectedGroupsId,
    handleSelectedGroupsId,
  } = useAccessManager();

  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);

  const handlePopoverOpen = React.useCallback(() => {
    setIsPopoverOpen(!isPopoverOpen);
  }, [isPopoverOpen]);

  const onDeleteGroupButtonClick = React.useCallback(() => {
    if (selectedGroupsId.length) {
      handleDeleteGroupModalOpen();
      handlePopoverOpen();
    } else {
      toast.error('Selecione pelo menos um grupo.');
    }
  }, [handleDeleteGroupModalOpen, handlePopoverOpen, toast, selectedGroupsId]);

  const handleCheckAllGroupsChange = React.useCallback(
    event => {
      if (!event || event.target) return;

      const filteredGroupsWithNoDefaults = groups.filter(group => !group.is_default);

      if (event.checked) {
        const mappedGroupsId = filteredGroupsWithNoDefaults.map(filteredGroup => filteredGroup.id);

        handleSelectedGroupsId(mappedGroupsId);
      } else {
        handleSelectedGroupsId([]);
      }
    },
    [handleSelectedGroupsId, groups],
  );

  const onCreateNewGroupButtonClick = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/access-manager/new-group`);
  }, [history, storeAliasId]);

  const filteredGroupsWithNoDefaults = groups.filter(group => !group.is_default);

  const hasSelectedAllGroups =
    filteredGroupsWithNoDefaults.length > 0 &&
    filteredGroupsWithNoDefaults.length === selectedGroupsId.length;

  return (
    <S.Wrapper>
      <S.Header>
        <S.Heading>Grupos</S.Heading>

        <S.ButtonsWrapper>
          <S.CustomPopover open={isPopoverOpen} onOpenChange={handlePopoverOpen}>
            <S.ButtonTrigger>
              Ações
              <ChevronDown size={16} color={theme.colors.font} />
            </S.ButtonTrigger>

            <S.CustomPopoverContent side="bottom" sideOffset={4}>
              <S.DeleteSelectedGroupsButton onClick={onDeleteGroupButtonClick}>
                <Cancel outline size={16} color={theme.colors.danger.default} />
                Excluir selecionados
              </S.DeleteSelectedGroupsButton>
              <S.CustomPopoverArrow />
            </S.CustomPopoverContent>
          </S.CustomPopover>

          <S.CreateNewGroupButton onClick={onCreateNewGroupButtonClick}>
            Criar novo grupo
          </S.CreateNewGroupButton>
        </S.ButtonsWrapper>
      </S.Header>

      <S.Table>
        <S.Table.Header>
          <S.Table.Row>
            <S.Table.Head>
              <S.CheckboxWrapper>
                <S.Checkbox checked={hasSelectedAllGroups} onChange={handleCheckAllGroupsChange} />
              </S.CheckboxWrapper>
            </S.Table.Head>
            <S.Table.Head>Nome</S.Table.Head>
          </S.Table.Row>
        </S.Table.Header>

        <S.Table.Body>
          <S.Table.Row borderBottom>
            <S.Table.Data>
              <S.CheckboxWrapper>
                <S.Checkbox disabled />
              </S.CheckboxWrapper>
            </S.Table.Data>

            <S.Table.Data>
              <S.TableDataContent>
                <S.GroupName weight={ETextWeight.MEDIUM} size={ETextSize.B5}>
                  Administradores
                </S.GroupName>
                <S.Badge color={EBadgeColors.PRIMARY}>Grupo padrão</S.Badge>
              </S.TableDataContent>
            </S.Table.Data>
          </S.Table.Row>

          {filteredGroupsWithNoDefaults.map(group => (
            <GroupRow key={group.alias_id} group={group} />
          ))}
        </S.Table.Body>
      </S.Table>
    </S.Wrapper>
  );
};

export default GroupTable;
