import styled from 'styled-components/macro';

export const NoProductWrapper = styled.div``;

export const Wrapper = styled.div<any>`
  width: ${({ width }) => `${width}px`};
  padding: 0 32px;
  box-sizing: border-box;
  max-height: 328px;
  height: fit-content;
  overflow-y: scroll;
  overscroll-behavior-block: contain;

  ::-webkit-scrollbar {
    width: 4px;
    position: absolute;
  }

  ::-webkit-scrollbar:horizontal {
    height: 1px;
    width: 4px;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.gray[4]};
    position: absolute;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[1]};
    border-radius: 25px;
    transition: all 0.3s;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray[1]};
    border-radius: 25px;
    transition: all 0.3s;
  }

  ::-webkit-scrollbar-thumb:hover {
    opacity: brightness(0.8);
  }
`;
