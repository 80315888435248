import React from 'react';

import { DownloadSimple } from 'phosphor-react';
import { Background, Controls, updateEdge, useReactFlow } from 'react-flow-renderer';
import { ClapSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components';
import { v4 } from 'uuid';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';

import { ENodes } from '@domain/enums/dashboard/automations/node/ENodes';

import { selectDefaultWaitNodeContent } from '@helpers/utils/dashboard/automations/Nodes/selectDefailtWaitNodeContent';
import { selectDefaultConditionalNodeContent } from '@helpers/utils/dashboard/automations/Nodes/selectDefaultConditionalNodeContent';
import { selectDefaultSwitchNodeContent } from '@helpers/utils/dashboard/automations/Nodes/selectDefaultSwithNodeContent';

import LimitationModal from '@components/common/core/Utils/LimitationModal';
import { EButtonVariant } from '@domain/enums/components/EButton';
import AddWhatsappInstanceModal from '../AddWhatsappInstanceModal';
import ConnectedInstanceModal from '../AddWhatsappInstanceModal/ConnectedInstanceModal';
import NewInstanceModal from '../AddWhatsappInstanceModal/NewInstanceModal';
import ButtonEdge from '../Edges';
import AbandonCartNode from '../Nodes/AbandonCartNode';
import AutomationEndNode from '../Nodes/AutomationEndNode';
import BoletoNode from '../Nodes/BoletoNode';
import ConditionalNode from '../Nodes/ConditionalNode';
import DeniedCardNode from '../Nodes/DeniedCardNode';
import PixNode from '../Nodes/PixNode';
import SendWhatsAppNode from '../Nodes/SendWhatsAppNode';
import SwitchNode from '../Nodes/SwitchNode';
import UpdateFulfillmentNode from '../Nodes/UpdateFulfillmentNode';
import WaitNode from '../Nodes/WaitNode';
import SaveAutomationModal from '../SaveAutomationModal';
import SideModal from '../SideModal';
import TaskMenu from '../TaskMenu';

import ApprovedOrderNode from '../Nodes/ApprovedOrderNode';
import CanceledOrderNode from '../Nodes/CanceledOrderNode';
import CouponNode from '../Nodes/CouponNode';
import SimpleShipmentNode from '../Nodes/SimpleShipmentNode';
import TestSideModal from './TestSideModal';
import * as S from './styles';

const CanvasWrapper: React.FC = () => {
  const { colors } = useTheme();

  const {
    edges,
    nodes,
    onConnect,
    onEdgesChange,
    onNodesChange,
    setEdges,
    showSideModal,
    selectedWorkflowTriggerType,
    showSaveAutomationModal,
    showAddWhatsappInstanceModal,
    showRenameWhatsappInstanceModal,
    whatsappInstanceAliasId,
    toggleShowSaveAutomationModal,
    isLoadingWorkflow,
    handleConnectionInProgress,
    handleShowSaveAutomationModal,
    handleWorkflowLimitationModalOpen,
    isWorkflowLimitationModalOpen,
    isMessagePerWorkflowLimitationModalOpen,
    handleMessagePerWorkflowLimitationModalOpen,
    isWhatsappInstancesLimitationModalOpen,
    handleWhatsappInstancesLimitationModalOpen,
    workflowAliasId,
    handleTestSideModalOpen,
    isTestSideModalOpen,
    showConnectedInstanceModal,
    handleShowConnectedInstanceModal,
  } = useAutomation();

  // const { handleStatus, tutorialStatus } = useStepModal();

  // const [showTutorial, setShowTutorial] = React.useState<boolean>(false);

  const reactFlowWrapper = React.useRef(null);
  const edgeUpdateSuccessful = React.useRef(true);

  const reactFlowInstance = useReactFlow();

  const edgesTypes = React.useMemo(
    () => ({
      buttonedge: ButtonEdge,
    }),
    [],
  );

  const nodeTypes = React.useMemo(
    () => ({
      [ENodes.ABANDONED_CART]: AbandonCartNode,
      [ENodes.END]: AutomationEndNode,
      [ENodes.IF]: ConditionalNode,
      [ENodes.SWITCH]: SwitchNode,
      [ENodes.WAIT]: WaitNode,
      [ENodes.WHATSAPP]: SendWhatsAppNode,
      [ENodes.PIX]: PixNode,
      [ENodes.BOLETO]: BoletoNode,
      [ENodes.REJECTED_PAYMENT]: DeniedCardNode,
      [ENodes.UPDATED_FULFILLMENT]: UpdateFulfillmentNode,
      [ENodes.SIMPLE_SHIPMENT]: SimpleShipmentNode,
      [ENodes.APPROVED_ORDER]: ApprovedOrderNode,
      [ENodes.CANCELED_ORDER]: CanceledOrderNode,
      [ENodes.COUPON]: CouponNode,
    }),
    [],
  );

  const onDragOver = React.useCallback(event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = React.useCallback(
    event => {
      event.preventDefault();

      const flow = reactFlowWrapper.current as any;
      const reactFlowBounds = flow.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');
      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance?.project({
        x: event.clientX - reactFlowBounds?.left,
        y: event.clientY - reactFlowBounds?.top,
      });

      const rules = [];

      if (!selectedWorkflowTriggerType) return;

      if (type === ENodes.SWITCH) {
        rules.push(
          selectDefaultSwitchNodeContent(selectedWorkflowTriggerType, 0),
          selectDefaultSwitchNodeContent(selectedWorkflowTriggerType, 1),
          selectDefaultSwitchNodeContent(selectedWorkflowTriggerType, 2),
        );
      }

      if (type === ENodes.IF) {
        rules.push(selectDefaultConditionalNodeContent(selectedWorkflowTriggerType));
      }

      if (type === ENodes.WAIT) {
        rules.push(selectDefaultWaitNodeContent());
      }

      const newNode = {
        id: v4(),
        type,
        position,
        data: { label: `${type}`, rules, name: v4() },
      };

      reactFlowInstance.addNodes(newNode);
    },
    [reactFlowInstance, selectedWorkflowTriggerType],
  );

  const onEdgeUpdateStart = React.useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = React.useCallback(
    (oldEdge, newConnection) => {
      edgeUpdateSuccessful.current = true;
      const alreadyHasEdge = edges.find(edge => {
        return edge.source === newConnection.source && edge.target === newConnection.target;
      });

      if (alreadyHasEdge) {
        return;
      }

      setEdges((els: any) => updateEdge(oldEdge, newConnection, els));
    },
    [setEdges, edges],
  );

  const onEdgeUpdateEnd = React.useCallback(
    (_, edge) => {
      if (!edgeUpdateSuccessful.current) {
        setEdges((eds: any) => eds.filter((e: any) => e.id !== edge.id));
      }

      edgeUpdateSuccessful.current = true;
    },
    [setEdges],
  );

  const onConnectStart = React.useCallback(() => {
    handleConnectionInProgress(true);
  }, [handleConnectionInProgress]);

  const onConnectEnd = React.useCallback(() => {
    handleConnectionInProgress(false);
  }, [handleConnectionInProgress]);

  React.useEffect(() => {
    const element = document.getElementsByClassName('react-flow__attribution');

    if (element.length > 0) {
      element[0].remove();
    }
  }, []);

  // const handleCloseTutorial = React.useCallback(() => {
  //   handleStatus({ canvas_tutorial: false });
  //   setShowTutorial(false);
  // }, [handleStatus, setShowTutorial]);

  // React.useEffect(() => {
  //   if (tutorialStatus?.canvas_tutorial === undefined) {
  //     setShowTutorial(true);
  //     handleStatus({ canvas_tutorial: true });
  //   }

  //   if (tutorialStatus?.canvas_tutorial) {
  //     setShowTutorial(true);
  //   }
  // }, [tutorialStatus, handleStatus]);

  if (isLoadingWorkflow) {
    return (
      <S.LoadingScreen>
        <ClapSpinner size={48} frontColor={colors.green.default} />
      </S.LoadingScreen>
    );
  }

  const showInstanceRenameModal =
    !showAddWhatsappInstanceModal &&
    !showSaveAutomationModal &&
    showRenameWhatsappInstanceModal &&
    whatsappInstanceAliasId;

  return (
    <S.Wrapper ref={reactFlowWrapper}>
      <S.StyledReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        edgeTypes={edgesTypes}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onEdgeUpdate={onEdgeUpdate}
        onEdgeUpdateStart={onEdgeUpdateStart}
        onEdgeUpdateEnd={onEdgeUpdateEnd}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        minZoom={0.1}
      >
        <Background size={2} color={colors.gray[5]} />
        <Controls />
        {showSideModal && <SideModal />}
        {!showSideModal && <TaskMenu />}

        {!showAddWhatsappInstanceModal &&
          showSaveAutomationModal &&
          !isWorkflowLimitationModalOpen &&
          !isWhatsappInstancesLimitationModalOpen && (
            <SaveAutomationModal
              isOpen={showSaveAutomationModal}
              toggle={toggleShowSaveAutomationModal}
            />
          )}

        {isWorkflowLimitationModalOpen && (
          <LimitationModal
            isOpen={isWorkflowLimitationModalOpen}
            toggle={handleWorkflowLimitationModalOpen}
            imageSrc="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/temp/limitation-temp.svg"
            title="Limite de automações atingido"
            description="Escolha um plano que te permita adicionar mais automações para que consiga usufruir o melhor da ferramenta."
            trackingPropertyName="MAX_WORKFLOWS_REACHED"
          />
        )}

        {isMessagePerWorkflowLimitationModalOpen && (
          <LimitationModal
            isOpen={isMessagePerWorkflowLimitationModalOpen}
            toggle={handleMessagePerWorkflowLimitationModalOpen}
            imageSrc="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/temp/limitation-temp.svg"
            title="Limite de mensagens por automação atingido"
            description="Escolha um plano que te permita adicionar mais mensagens em uma automação para que consiga usufruir o melhor da ferramenta."
            trackingPropertyName="MAX_MESSAGES_PER_WORKFLOW_REACHED"
          />
        )}

        {isWhatsappInstancesLimitationModalOpen && (
          <LimitationModal
            isOpen={isWhatsappInstancesLimitationModalOpen}
            toggle={handleWhatsappInstancesLimitationModalOpen}
            imageSrc="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/temp/limitation-temp.svg"
            title="Limite de enviadores de WhatsApp atingido"
            description="Escolha um plano que te permita adicionar mais enviadores para que consiga usufruir o melhor da ferramenta."
            trackingPropertyName="MAX_WHATSAPP_INSTANCES_REACHED"
          />
        )}

        {showAddWhatsappInstanceModal &&
          !showSaveAutomationModal &&
          !isWhatsappInstancesLimitationModalOpen && (
            <AddWhatsappInstanceModal
              isOpen={showAddWhatsappInstanceModal}
              toggle={handleShowSaveAutomationModal}
            />
          )}

        {showInstanceRenameModal && (
          <NewInstanceModal
            isOpen={showRenameWhatsappInstanceModal}
            toggle={handleShowSaveAutomationModal}
            credentialAliasId={whatsappInstanceAliasId}
          />
        )}
        <S.ButtonWrapper>
          {workflowAliasId && <TestAutomationButton onClick={handleTestSideModalOpen} />}
          <SaveAutomationButton onClick={toggleShowSaveAutomationModal} />
        </S.ButtonWrapper>

        {showConnectedInstanceModal && (
          <ConnectedInstanceModal
            isOpen={showConnectedInstanceModal}
            toggle={handleShowConnectedInstanceModal}
          />
        )}
      </S.StyledReactFlow>
      {/* {showTutorial && (
        <StepsModal
          isOpen={showTutorial}
          tutorial={tutorial}
          page="Automations Canvas"
          side="left"
          toggle={handleCloseTutorial}
        />
      )} */}

      <TestSideModal isOpen={isTestSideModalOpen} toggle={handleTestSideModalOpen} />
    </S.Wrapper>
  );
};

const TestAutomationButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <S.TestButton onClick={onClick} variant={EButtonVariant.SECONDARY_BUTTON} isLoading={false}>
      Testar automação
    </S.TestButton>
  );
};

const SaveAutomationButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { colors } = useTheme();

  return (
    <S.SaveButton
      onClick={onClick}
      iconColor={colors.black.default}
      iconPrepend={DownloadSimple}
      isLoading={false}
    >
      Salvar automação
    </S.SaveButton>
  );
};

export default CanvasWrapper;
