import { Search } from '@profitfy/pakkun-icons';
import React from 'react';

import { useHistory } from 'react-router-dom';

import {
  ESynchronizationRequestType,
  ESynchronizationType,
} from '@domain/enums/hooks/ESynchronization';

import { EMambaTermsStatus } from '@domain/enums/common/mamba/EMambaTermStatus';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { IUser } from '@domain/interfaces/IUser';
import { IPersonalInfo } from '@domain/interfaces/auth/IPersonalInfo';
import { IStore } from '@domain/interfaces/stores/IStore';

import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useConfig } from '@helpers/hooks/useConfig';
import { useDebounce } from '@helpers/hooks/useDebounce';
import { useRetrospectiveData } from '@helpers/hooks/useRetrospectiveData';
import { useToast } from '@helpers/hooks/useToast';
import { useQuery } from '@helpers/hooks/useQuery';

import { getEnvironment } from '@helpers/utils/environment';

import { LONG_API_DOMAIN } from '@constants/api';

import personalInfoService from '@services/pages/auth/personalInfo';
import userInviteService from '@services/pages/dashboard/accessManagement/userInvite';
import storesService from '@services/pages/stores/stores';
import userService from '@services/user';

import GenerateStore from '@components/Stores/GenerateStore';
import NewStore from '@components/Stores/NewStore';
import PageError from '@components/Stores/PageError';
import SkeletonLoading from '@components/Stores/SkeletonLoading';
import Store from '@components/Stores/Store';
import Paginate from '@components/common/core/Utils/Paginate';

import Logo from '@assets/img/brand/profitfy-logo.svg';

import { EBequestTermsStatus } from '@domain/enums/common/bequest/EBequestTermStatus';
import * as S from './styles';

const Stores: React.FC = () => {
  const history = useHistory();
  const query = useQuery();
  const { toast } = useToast();
  const { addSynchronization } = useSynchronization();
  const { trackActiveInvitedUsersQuantity } = useRetrospectiveData();

  const {
    handleLogout,
    handleStores,
    inviteToken,
    handleInviteToken,
    analytics,
    user: configUser,
    appAffiliate,
    term,
    userOnboarding,
  } = useConfig();

  const updateQuery = query.get('update');

  const [storeSearchAfterLoaded, setStoreSearchAfterLoaded] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(1);
  const [stores, setStores] = React.useState<Array<IStore>>([]);
  const [isLoadingStores, setIsLoadingStores] = React.useState<boolean>(true);
  const [isStoresError, setIsStoresError] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<IUser | undefined>(undefined);
  const [isLoadingUser, setIsLoadingUser] = React.useState<boolean>(true);
  const [isUserError, setIsUserError] = React.useState<boolean>(false);
  const [isLoadingPersonalInfo, setIsLoadingPersonalInfo] = React.useState<boolean>(false);
  const [isPersonalInfoError, setIsPersonalInfoError] = React.useState<boolean>(false);
  const [storeToSearch, setStoreToSearch] = React.useState<string>('');
  const [personalInfo, setPersonalInfo] = React.useState<IPersonalInfo | undefined | null>(
    undefined,
  );

  const debouncedValue = useDebounce(storeToSearch, 700);

  const onSearchValueChange = React.useCallback(event => {
    setStoreToSearch(event.target.value);
  }, []);

  const onPageChange = React.useCallback(({ selected }) => setPage(selected), []);

  const loadStores = React.useCallback(async () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    setIsLoadingStores(true);
    setIsStoresError(false);

    try {
      const { data } = await storesService.getStoresPromise({
        page,
        filter: { store_name: debouncedValue },
      });

      setStores(data.stores);
      setTotalPages(data.total_pages);
      setIsLoadingStores(false);
      setStoreSearchAfterLoaded(debouncedValue);
    } catch {
      setIsStoresError(true);
      setIsLoadingStores(false);
    }
  }, [page, debouncedValue]);

  const loadUser = React.useCallback(async () => {
    setIsLoadingUser(false);
    setIsUserError(false);

    try {
      const { data } = await userService.getUserPromise(undefined);

      setUser(data.user);

      setIsLoadingUser(false);
    } catch {
      setIsUserError(true);
      setIsLoadingUser(false);
    }
  }, []);

  const loadPersonalInfo = React.useCallback(async () => {
    setIsLoadingPersonalInfo(true);
    setIsPersonalInfoError(false);

    try {
      const { data } = await personalInfoService.getPersonalInfoPromise();

      if (!data.personal_info) {
        setPersonalInfo(null);
      } else {
        setPersonalInfo(data.personal_info);
      }

      setIsLoadingPersonalInfo(false);
    } catch {
      setIsPersonalInfoError(true);
      setIsLoadingPersonalInfo(false);
    }
  }, []);

  const handleNewStoreModalOpen = React.useCallback(() => {
    if (stores.length) {
      history.push('/onboarding/store');
    } else {
      history.push('/onboarding/segmentation');
    }
  }, [history, stores]);

  const handleGenerateDemoStore = React.useCallback(() => {
    addSynchronization({
      name: 'Demo',
      type: ESynchronizationType.DEMO_STORE,
      label: 'Demo',
      dependencies: [],
      config: {
        isOnboardingSynchronization: false,
        showNotification: true,
      },
      request: {
        type: ESynchronizationRequestType.POST,
        domain: LONG_API_DOMAIN,
        api: `/${getEnvironment()}/api/v1/users/demo/generate/store`,
      },
    });
  }, [addSynchronization]);

  const mutate = React.useCallback(async () => {
    const promises = [];

    if (isStoresError) promises.push(loadStores());

    if (isUserError) promises.push(loadUser());

    if (isPersonalInfoError) promises.push(loadPersonalInfo());

    await Promise.all(promises);
  }, [isStoresError, loadStores, isUserError, loadUser, isPersonalInfoError, loadPersonalInfo]);

  const acceptStoreInvite = React.useCallback(
    async (token: string) => {
      try {
        await userInviteService.acceptUserInvitePromise({ data: { token } });

        trackActiveInvitedUsersQuantity();

        await loadStores();

        if (configUser) {
          analytics?.identify(
            configUser.id,
            { has_accepted_invite: true },
            {
              Intercom: {
                user_hash: configUser?.user_intercom_hash,
              },
            },
          );
        }

        handleInviteToken(undefined);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
    [handleInviteToken, loadStores, toast, analytics, configUser, trackActiveInvitedUsersQuantity],
  );

  React.useEffect(() => {
    if (
      appAffiliate &&
      appAffiliate.referral_code === 'njlimzb' &&
      term.term_to_provide_data_to_mamba_culture_accepted_status === EMambaTermsStatus.NONE
    ) {
      history.push('/consent/mamba-digital');
    }
  }, [appAffiliate, history, term]);

  React.useEffect(() => {
    if (
      appAffiliate &&
      appAffiliate.referral_code === 'nwjkody' &&
      term.term_to_provide_data_to_bequest_culture_accepted_status === EBequestTermsStatus.NONE
    ) {
      history.push('/consent/bequest-digital');
    }
  }, [appAffiliate, history, term]);

  React.useEffect(() => {
    if (window.location.pathname.includes('/stores')) {
      loadStores();
      loadUser();
      loadPersonalInfo();
    }
  }, [loadStores, loadUser, loadPersonalInfo]);

  React.useEffect(() => {
    if (updateQuery) {
      loadStores();
      loadUser();
      loadPersonalInfo();

      history.push('/stores');
    }
  }, [loadStores, loadUser, loadPersonalInfo, updateQuery, history]);

  React.useEffect(() => {
    if (inviteToken && window.location.pathname.includes('/stores')) {
      acceptStoreInvite(inviteToken);
    }
  }, [inviteToken, acceptStoreInvite]);

  React.useEffect(() => {
    if (stores?.length) {
      handleStores(stores);
    }
  }, [stores, handleStores]);

  React.useEffect(() => {
    const isLoadingData = isLoadingPersonalInfo || isLoadingStores;
    const hasDataError = isStoresError || isPersonalInfoError;

    if (
      stores &&
      !stores.length &&
      personalInfo &&
      !inviteToken &&
      !isLoadingData &&
      !hasDataError &&
      !storeSearchAfterLoaded &&
      !userOnboarding?.has_answered_form_segmentation
    ) {
      history.push('/onboarding/segmentation');
    }

    if (
      stores &&
      !stores.length &&
      personalInfo &&
      !inviteToken &&
      !isLoadingData &&
      !hasDataError &&
      !storeSearchAfterLoaded &&
      user?.has_answered_form_segmentation
    ) {
      history.push('/onboarding/store');
    }
  }, [
    history,
    stores,
    inviteToken,
    isLoadingStores,
    isStoresError,
    isLoadingPersonalInfo,
    isPersonalInfoError,
    personalInfo,
    storeSearchAfterLoaded,
    user,
    userOnboarding,
  ]);

  React.useEffect(() => {
    if (!isLoadingPersonalInfo && !isPersonalInfoError && personalInfo === null) {
      history.push('/onboarding/segmentation');
    }
  }, [isLoadingPersonalInfo, isPersonalInfoError, personalInfo, history]);

  if (isLoadingStores || isLoadingUser || Boolean(inviteToken) || isLoadingPersonalInfo) {
    return <SkeletonLoading />;
  }

  if (isStoresError || isUserError || isPersonalInfoError) {
    return <PageError mutate={mutate} />;
  }

  return (
    <S.Container>
      <S.ContentWrapper>
        <S.Image src={Logo} alt="Profitfy.me Logo" />

        <S.Heading type={EHeadingSize.H3} fontWeight={EHeadingWeight.REGULAR}>
          {`Olá ${user?.first_name}!`}
        </S.Heading>

        <S.TextAndProfileWrapper>
          <S.Text>Qual loja deseja acessar?</S.Text>
          <S.Link to="/profile">Meu perfil</S.Link>
        </S.TextAndProfileWrapper>

        {Boolean(totalPages > 1 || storeToSearch) && (
          <S.SearchInput
            prepend={Search}
            type="text"
            placeholder="Procurar loja..."
            onChange={onSearchValueChange}
            defaultValue={storeToSearch}
          />
        )}

        {stores?.map(store => (
          <Store
            key={store.alias_id}
            name={store.name}
            storeAliasId={store.alias_id}
            store={store}
          />
        ))}

        <NewStore toggle={handleNewStoreModalOpen} />

        {getEnvironment() === 'dev' ? <GenerateStore toggle={handleGenerateDemoStore} /> : null}

        <S.PaginateAndExitWrapper>
          {totalPages > 1 && (
            <Paginate forcePage={page} pageCount={totalPages} onPageChange={onPageChange} />
          )}
          <S.ExitWrapper>
            <S.LogoutButton onClick={handleLogout}>
              <S.LogoutText>Sair</S.LogoutText>
            </S.LogoutButton>
          </S.ExitWrapper>
        </S.PaginateAndExitWrapper>
      </S.ContentWrapper>
    </S.Container>
  );
};

export default Stores;
