import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div<{ isHorizontal?: boolean }>`
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${({ isHorizontal }) =>
    isHorizontal &&
    css`
      position: unset;
      flex-direction: row;
    `}
`;

export const Background = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${({ color }) => color};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
