import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import TextBase from '@components/common/core/DataDisplay/Text';
import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 416px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 28px 38px 32px;
  border: 1px solid ${({ theme }) => theme.colors.gray[4]};
  border-radius: 7px;

  @media only screen and (max-width: 620px) {
    width: unset;
    max-width: unset;
  }
`;

export const Image = styled(ImageBase)`
  width: 100%;
  max-width: 140px;
  margin-bottom: 26px;
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.white.dark};
`;

export const Description = styled(TextBase)`
  line-height: 150%;
  margin-bottom: 8px;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.green.default};
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 150%;
  text-decoration: none;
`;
