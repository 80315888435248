import React from 'react';

import { DatePickerRangeProvider } from '@helpers/hooks/components/useDatePickerRange';
import { IDatePickerRangeProps } from '@domain/interfaces/components/IDatePickerRange';
import Wrapper from './Wrapper';

const DatePickerRange: React.FC<IDatePickerRangeProps> = ({
  period,
  setPeriod,
  maxDate,
  minDate,
  excludeTodayInLastSevenDays,
  ...rest
}) => {
  return (
    <DatePickerRangeProvider
      period={period}
      setPeriod={setPeriod}
      maxDate={maxDate}
      minDate={minDate}
      excludeTodayInLastSevenDays={excludeTodayInLastSevenDays}
    >
      <Wrapper {...rest} />
    </DatePickerRangeProvider>
  );
};

export default DatePickerRange;
