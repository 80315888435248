import React from 'react';

import { IMetricCardProps } from '@domain/interfaces/dashboard/ProductAnalytics/IProductDetails';
import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const MetricCard: React.FC<IMetricCardProps> = ({ type, title, value, icon: Icon }) => {
  return (
    <S.Card>
      <S.Content>
        <S.IconWrapper>
          <Icon outline size={24} />
        </S.IconWrapper>

        <S.TextWrapper>
          <S.Title>{title}</S.Title>

          {type === 'CURRENCY' && <S.Value>{`R$ ${numberFormatter(value, 2)}`}</S.Value>}

          {type === 'PERCENTAGE' && <S.Value>{`${numberFormatter(value, 2)}%`}</S.Value>}

          {type === 'MULTIPLIER' && <S.Value>{`${numberFormatter(value, 2)}x`}</S.Value>}
        </S.TextWrapper>
      </S.Content>
    </S.Card>
  );
};

export default MetricCard;
