import React from 'react';
import {
  Shopify,
  IIconProps,
  Appmax,
  Hubsale,
  CartPanda,
  Pagarme,
  PagSeguro,
  Linepay,
  Cielo,
  MercadoPago,
  Upnid,
  Zouti,
  Cloudfox,
  Istpay,
  IntegrationsCircle,
  Facebook,
  Taboola,
  GoogleAds,
  TikTok,
  Kiwify,
  Evermart,
  Hotmart,
  Woocommerce,
  DsersWaiting,
  DsersSynced,
  Profitfy,
  Bling,
  Barcode as BarcodeIcon,
  Pix as PixIcon,
  Card as CreditCardIcon,
} from '@profitfy/pakkun-icons';

import {
  ChatText,
  CheckCircle,
  Envelope,
  ShoppingCart,
  UsersThree,
  XSquare,
  XCircle,
  IconProps,
  ClockClockwise,
  CaretCircleLeft,
  CaretCircleDoubleLeft,
  DotsThreeCircle,
  Prohibit,
  CircleDashed,
} from 'phosphor-react';

import { EBadgeColors } from '@domain/enums/components/EBadge';

import { IProduct } from '@domain/interfaces/dashboard/Orders/IOrdersLineItem';

import {
  EUtmSource,
  ILineItem,
  IOrder,
  IOrderSynchronization,
} from '@domain/interfaces/common/orders/IOrders';

import {
  IGetOrderGatewayIconResponse,
  IGetOrderMarketingIconResponse,
} from '@domain/interfaces/utils/orders/IOrder';

import {
  DROPSHIPPING_PROVIDERS,
  ERPS,
  INFO_PRODUCT_PROVIDERS,
  ECOMMERCE_PROVIDERS,
} from '@constants/common/integrations';

import { DefaultTheme } from 'styled-components';

export const getMostRecentOrderSync = (
  orderSynchronizations: Array<IOrderSynchronization>,
): IOrderSynchronization => {
  const mostRecentSynchronization = orderSynchronizations.reduce((previous, current) => {
    return new Date(previous.updated_at) > new Date(current.updated_at) ? previous : current;
  });

  return mostRecentSynchronization;
};

export const getOrderSynchronizationIcon = (
  orderSynchronizations: Array<IOrderSynchronization>,
): React.ReactElement | undefined => {
  const integrations = [...DROPSHIPPING_PROVIDERS, ...ERPS];

  const mostRecentSynchronization = getMostRecentOrderSync(orderSynchronizations);

  const foundIntegration = integrations.find(
    integration => integration.identifier === mostRecentSynchronization.provider,
  );

  if (foundIntegration) {
    const Icon = foundIntegration.icon;

    return <Icon size={12} color="white" />;
  }

  return undefined;
};

export const getParsedStatus = (status: string): string =>
  ({
    PAID: 'Aprovado',
    PENDING: 'Pendente',
    CANCELED: 'Cancelado',
    REFUNDED: 'Estornado',
    CHARGED_BACK: 'Chargeback',
    IN_ANALYSIS: 'Em Análise',
    REJECTED: 'Recusado',
    NO_INFORMATION: 'Sem Informação',
  }[status] || 'Sem Informação');

export const getBadgeColors = (status: string): EBadgeColors =>
  ({
    PAID: EBadgeColors.GREEN,
    PENDING: EBadgeColors.YELLOW,
    CANCELED: EBadgeColors.RED,
    REFUNDED: EBadgeColors.RED,
    CHARGED_BACK: EBadgeColors.RED,
    IN_ANALYSIS: EBadgeColors.SKY_BLUE,
    REJECTED: EBadgeColors.RED,
    NO_INFORMATION: EBadgeColors.GRAY,
  }[status] || EBadgeColors.GRAY);

export const getBadgeIconColors = (status: string): string =>
  ({
    PAID: '#50E170',
    PENDING: '#EFC980',
    CANCELED: '#D0425C',
    REFUNDED: '#D06C42',
    CHARGED_BACK: '#9542D0',
    IN_ANALYSIS: '#5B8DEF',
    REJECTED: '#D0425C',
    NO_INFORMATION: '#A7AFC6',
  }[status] || '#00f2a1');

export const getBadgeIcons = (
  status: string,
): React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>> =>
  ({
    PAID: CheckCircle,
    PENDING: ClockClockwise,
    CANCELED: XCircle,
    REFUNDED: CaretCircleLeft,
    CHARGED_BACK: CaretCircleDoubleLeft,
    IN_ANALYSIS: DotsThreeCircle,
    REJECTED: Prohibit,
    NO_INFORMATION: CircleDashed,
  }[status] || CheckCircle);

export const getProviderIcon = (provider: string): React.FC<IIconProps> => {
  const integrations = [
    ...DROPSHIPPING_PROVIDERS,
    ...INFO_PRODUCT_PROVIDERS,
    ...ECOMMERCE_PROVIDERS,
  ];

  const foundIntegration = integrations.find(integration => integration.identifier === provider);

  if (foundIntegration) return foundIntegration.icon;

  return Shopify;
};

export const getParsedPaymentType = (paymentType: string): string =>
  ({
    BOLETO: 'Boleto',
    CREDIT_CARD: 'Cartão',
    PIX: 'Pix',
  }[paymentType] || 'Sem Informação');

export const getPaymentType = (type: string, theme: DefaultTheme): any =>
  ({
    BOLETO: <BarcodeIcon size={20} outline color={theme.colors.gray[1]} />,
    PIX: <PixIcon size={20} outline color={theme.colors.gray[1]} />,
    CREDIT_CARD: <CreditCardIcon size={20} outline color={theme.colors.gray[1]} />,
  }[type]);

export const sumFeeOrderAmount = (order: IOrder): number => {
  return (
    order.checkout_fee_amount +
    order.gateway_fee_amount +
    order.marketplace_fee_amount +
    order.info_product_fee_amount
  );
};

export const getProductProducts = (
  products: IProduct[],
): { first_product?: IProduct; rest_products?: IProduct[] } => {
  const [first_product, ...rest_products] = products;
  return {
    first_product,
    rest_products,
  };
};

export const getOrderLineItems = (
  line_items: Array<ILineItem>,
): { firstLineItem: ILineItem; restLineItems?: Array<ILineItem> } => {
  const [first_line_item, ...rest_line_items] = line_items;
  return {
    firstLineItem: first_line_item,
    restLineItems: rest_line_items,
  };
};

const empytGatewayObject = {
  icon: <></>,
  backgroundColor: '',
  hasMarginLeft: false,
};

const empytMarketingObject = {
  icon: <></>,
  backgroundColor: '',
};

export const getOrderSourceIcon = (
  utmSource: EUtmSource,
  theme: DefaultTheme,
): IGetOrderMarketingIconResponse =>
  ({
    FACEBOOK: {
      icon: <Facebook size={16} />,
      backgroundColor: '#355F79',
    },
    TABOOLA: {
      icon: <Taboola size={16} />,
      backgroundColor: '#1C5A87',
    },
    GOOGLE: {
      icon: <GoogleAds size={16} />,
      backgroundColor: '#79621E',
    },
    TIKTOK: {
      icon: <TikTok size={20} />,
      backgroundColor: '#0F1116',
    },
    SMS: {
      icon: <ChatText size={18} color={theme.colors.green.default} weight="bold" />,
      backgroundColor: '#0F534B',
    },
    EMAIL: {
      icon: <Envelope size={18} color={theme.colors.green.default} weight="bold" />,
      backgroundColor: 'rgba(15, 83, 75, 1)',
    },
    ABANDONED_CART: {
      icon: <ShoppingCart size={19} color={theme.colors.green.default} weight="bold" />,
      backgroundColor: 'rgba(15, 83, 75, 1)',
    },
    PROFITFY: {
      icon: <Profitfy size={16} />,
      backgroundColor: 'rgba(15, 83, 75, 1)',
    },
    ORGANIC: {
      icon: <UsersThree size={20} color={theme.colors.green.default} weight="bold" />,
      backgroundColor: 'rgba(15, 83, 75, 1)',
    },
    OTHER: {
      icon: <XSquare size={20} color={theme.colors.green.default} weight="bold" />,
      backgroundColor: 'rgba(15, 83, 75, 1)',
    },
  }[utmSource] || empytMarketingObject);

export const parseOrderSourceName = (utmSource: EUtmSource): string =>
  ({
    FACEBOOK: 'Facebook',
    TABOOLA: 'Taboola',
    GOOGLE: 'Google',
    TIKTOK: 'TikTok',
    OTHER: 'Outro',
    SMS: 'SMS',
    EMAIL: 'E-mail',
    ORGANIC: 'Tráfego Orgânico',
    ABANDONED_CART: 'Carrinho Abandonado',
    PROFITFY: 'Profitfy Automações',
  }[utmSource] || '');

export const parseOrderGatewayName = (gatewayName: string): string =>
  ({
    APPMAX: 'Appmax',
    MERCADO_PAGO: 'Mercado Pago',
    HUB_SALE: 'Doppus',
    CART_PANDA_PAY: 'CartPanda Pay',
    PAGARME: 'Pagar.me',
    PAG_SEGURO: 'PagSeguro',
    UPNID: 'Upnid',
    ZOUTI: 'Zouti',
    CLOUD_FOX: 'Cloudfox',
    LINEPAY: 'Linepay',
    CIELO: 'Cielo',
    ISTPAY: 'Istpay',
    KIWIFY: 'Kiwify',
    EVERMART: 'Evermart',
    HOTMART: 'Hotmart',
    WOO_COMMERCE: 'WooCommerce',
    PAG_SEGURO_V4: 'PagSeguro',
    HUB_SALE_V2: 'Doppus',
    OTHER: 'Outro',
  }[gatewayName] || '');

export const getOrderGatewayIcon = (gatewayName: string): IGetOrderGatewayIconResponse =>
  ({
    PAG_SEGURO_V4: {
      icon: <PagSeguro size={18} />,
      backgroundColor: '#0F1116',
      hasMarginLeft: true,
    },
    HUB_SALE_V2: {
      icon: <Hubsale size={16} />,
      backgroundColor: '#7B4A1C',
      hasMarginLeft: false,
    },
    APPMAX: {
      icon: <Appmax size={16} />,
      backgroundColor: '#53457D',
      hasMarginLeft: false,
    },
    MERCADO_PAGO: {
      icon: <MercadoPago size={16} />,
      backgroundColor: '#355F79',
      hasMarginLeft: true,
    },
    HUB_SALE: {
      icon: <Hubsale size={16} />,
      backgroundColor: '#7B4A1C',
      hasMarginLeft: false,
    },
    CART_PANDA_PAY: {
      icon: <CartPanda size={16} />,
      backgroundColor: '#2d5ff563',
      hasMarginLeft: false,
    },
    PAGARME: {
      icon: <Pagarme size={18} />,
      backgroundColor: '#58632F',
      hasMarginLeft: true,
    },
    PAG_SEGURO: {
      icon: <PagSeguro size={18} />,
      backgroundColor: '#0F1116',
      hasMarginLeft: true,
    },
    UPNID: {
      icon: <Upnid size={16} />,
      backgroundColor: '#0F1116',
      hasMarginLeft: true,
    },
    ZOUTI: {
      icon: <Zouti size={16} />,
      backgroundColor: '#7B4A1C',
      hasMarginLeft: false,
    },
    CLOUD_FOX: {
      icon: <Cloudfox size={16} />,
      backgroundColor: '#0F1116',
      hasMarginLeft: false,
    },
    LINEPAY: {
      icon: <Linepay size={16} />,
      backgroundColor: '#676f1cb3',
      hasMarginLeft: true,
    },
    CIELO: {
      icon: <Cielo size={24} />,
      backgroundColor: '#355F79',
      hasMarginLeft: true,
    },
    ISTPAY: {
      icon: <Istpay size={16} />,
      backgroundColor: '#0F1116',
      hasMarginLeft: false,
    },
    OTHER: {
      icon: <IntegrationsCircle size={32} />,
      backgroundColor: '',
      hasMarginLeft: false,
    },
    KIWIFY: {
      icon: <Kiwify size={16} />,
      backgroundColor: '#0F1116',
      hasMarginLeft: false,
    },
    EVERMART: {
      icon: <Evermart size={18} />,
      backgroundColor: '#0F1116',
      hasMarginLeft: false,
    },
    HOTMART: {
      icon: <Hotmart size={18} />,
      backgroundColor: '#7B4A1C',
      hasMarginLeft: false,
    },
    WOO_COMMERCE: {
      icon: <Woocommerce size={22} />,
      backgroundColor: '#53457D',
      hasMarginLeft: false,
    },
    BLING: {
      icon: <Bling size={16} />,
      backgroundColor: '#033524',
      hasMarginLeft: false,
    },
  }[gatewayName] || empytGatewayObject);

export const getProductCostDsersIcon = (
  orderSynchronizations: Array<IOrderSynchronization>,
): JSX.Element => {
  const hasOrderSynchronizations = Boolean(orderSynchronizations?.length);

  if (!hasOrderSynchronizations) return <DsersWaiting size={32} />;

  if (hasOrderSynchronizations) return <DsersSynced size={32} />;

  return <></>;
};
