import React from 'react';

import { IParams } from '@domain/interfaces/IParams';
import { useParams, useHistory } from 'react-router-dom';
import { EHeadingSize } from '@domain/enums/components/EHeading';
import { EButtonSize } from '@domain/enums/components/EButton';

import { ADMIN_PANEL_FUNCTIONALITIES } from '@constants/pages/dashboard/admin/functionalities';

import * as S from './styles';

const AdminPanel: React.FC = () => {
  const history = useHistory();

  const { storeAliasId } = useParams<IParams>();

  const handleButtonClick = React.useCallback(
    route => {
      history.push(`/${storeAliasId}/dashboard/admin/${route}`);
    },
    [history, storeAliasId],
  );

  const sortedFunctionalities = ADMIN_PANEL_FUNCTIONALITIES.sort((currentItem, nextItem) => {
    if (currentItem.name > nextItem.name) return 1;

    if (currentItem.name < nextItem.name) return -1;

    return 0;
  });

  return (
    <S.ContentWrapper>
      <S.Heading type={EHeadingSize.H3}>Painel de Admin</S.Heading>

      <S.Body>
        {sortedFunctionalities.map(functionality => {
          return (
            <S.Functionality key={functionality.route}>
              <S.Text>{functionality.name}</S.Text>
              <S.Button
                buttonSize={EButtonSize.SMALL}
                onClick={() => handleButtonClick(functionality.route)}
              >
                Acessar
              </S.Button>
            </S.Functionality>
          );
        })}
      </S.Body>
    </S.ContentWrapper>
  );
};

export default AdminPanel;
