import styled, { css } from 'styled-components/macro';

import { IRowProps } from '@domain/interfaces/components/ITable';

export const Row = styled.tr<IRowProps>`
  & > td {
    padding: 10px 28px;

    ${({ bottomSpacing }) =>
      bottomSpacing &&
      css`
        padding-bottom: ${bottomSpacing}px;
      `}

    ${({ borderBottom }) =>
      borderBottom &&
      css`
        border-bottom: 1px solid ${({ theme }) => theme.colors.background[3]};
      `}
  }

  & > td:first-child {
    padding-left: 32px;
    padding-top: 10px;
  }

  & > td:last-child {
    padding-right: 32px;
    padding-bottom: 10px;
  }
`;
