import mockAdsManagerThumbImg from '@assets/components/InstantHelp/adsManager/thumb.jpg';
import empytImg from '@assets/components/InstantHelp/empyt-image.svg';

import { IGetImagePropsResponse } from '@domain/interfaces/utils/instantHelp/instantHelp';

export const getImageProps = (title: string, name: string): IGetImagePropsResponse => {
  if (title === 'Gerenciador de anúncios') {
    return {
      src: mockAdsManagerThumbImg,
      alt: `profitfy-${name}-thumb-image`,
    };
  }

  return {
    src: empytImg,
    alt: 'empyt-image',
  };
};
