import React from 'react';

import * as S from './styles';

export interface IOptionProps {
  isSelected?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Option: React.FC<IOptionProps> = ({ children, isSelected, onClick }) => {
  return (
    <S.Wrapper isSelected={isSelected} onClick={onClick}>
      {children}
    </S.Wrapper>
  );
};

export default Option;
