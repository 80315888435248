import React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';

import { ICustomPopover } from '@domain/interfaces/components/ICustomPopover';
import Content from './Content';
import Trigger from './Trigger';
import Arrow from './Arrow';
import Close from './Close';

import * as S from './styles';

const CustomPopover: ICustomPopover & React.FC<PopoverPrimitive.PopoverProps> = ({
  children,
  ...rest
}) => {
  return <S.Popover {...rest}>{children}</S.Popover>;
};

CustomPopover.Content = Content;
CustomPopover.Trigger = Trigger;
CustomPopover.Arrow = Arrow;
CustomPopover.Close = Close;

export default CustomPopover;
