import React from 'react';
import { Warning } from '@profitfy/pakkun-icons';

import { ETextWeight } from '@domain/enums/components/EText';

import { useAccessManager } from '@helpers/hooks/pages/dashboard/accessManagement/useAccessManager';

import * as S from './styles';

const DeletePendingInviteModal: React.FC = () => {
  const {
    isDeletePendingInviteModalOpen,
    handleDeletePendingInviteModalOpen,
    handleDeletePendingInvites,
    isDeletingPendingInvites,
  } = useAccessManager();

  return (
    <S.Modal isOpen={isDeletePendingInviteModalOpen} toggle={handleDeletePendingInviteModalOpen}>
      <S.Header>
        <Warning size={24} outline />
        <S.Heading weight={ETextWeight.MEDIUM}>
          Certeza que deseja remover o(s) convite(s)?
        </S.Heading>
      </S.Header>

      <S.Description>Essa ação é irreversível.</S.Description>

      <S.ButtonsWrapper>
        <S.CancelButton
          onClick={handleDeletePendingInviteModalOpen}
          disabled={isDeletingPendingInvites}
        >
          Voltar
        </S.CancelButton>
        <S.DeleteMemberButton
          onClick={handleDeletePendingInvites}
          disabled={isDeletingPendingInvites}
          isLoading={isDeletingPendingInvites}
        >
          Remover convite(s)
        </S.DeleteMemberButton>
      </S.ButtonsWrapper>
    </S.Modal>
  );
};

export default DeletePendingInviteModal;
