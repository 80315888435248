import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const WaitingIcon = styled.div`
  width: 14px;
  height: 14px;
`;

export const SynchronizationProgress = styled(TextBase)`
  line-height: 120%;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const SynchronizationLabel = styled(TextBase)`
  display: flex;
  align-items: center;
  gap: 12px;
  line-height: 120%;
  font-size: 0.75rem;
`;

export const SynchronizationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SynchronizationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

export const GroupTitle = styled(TextBase)`
  line-height: 120%;
  font-size: 0.875rem;
`;

export const GroupHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
`;

export const SynchronizationGroup = styled.div`
  &:not(:last-child) {
    padding-bottom: 12px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[5]};
  }
`;

export const HeaderIconWrapper = styled.button`
  padding: unset;
  border: unset;
  background-color: unset;
  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  max-height: 244px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  margin-top: 24px;
  padding: 0 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ::-webkit-scrollbar {
    width: 4px;
    right: -20px;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.gray[5]};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray[3]};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.gray[4]};
  }
`;

export const DragHandler = styled.button`
  background-color: unset;
  border: unset;
  font-family: ${({ theme }) => theme.colors.font};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.font};
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  box-sizing: border-box;
`;

export const Wrapper = styled.div`
  max-width: 320px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 15px;
  position: absolute;
  padding: 24px 0;
  box-sizing: border-box;
  pointer-events: all;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
`;
