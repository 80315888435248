import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import Form from '@components/common/core/Inputs/Form';
import { currencyFormatter, currencyToNumber } from '@helpers/masks';
import { useDate } from '@helpers/hooks/useDate';
import { IBodyProps } from '@domain/interfaces/dashboard/ProductAnalytics/IRankingTable';
import customValueService from '@services/pages/dashboard/customValue/customValue';
import { useToast } from '@helpers/hooks/useToast';
import { IParams } from '@domain/interfaces/IParams';
import { EGroup } from '@domain/enums/dashboard/customValuesCategories/EGroup';
import Text from '@components/common/core/DataDisplay/Text';
import { productCustomValueSchema } from '@helpers/validators/dashboard/customValues/customValues';
import customValueCategoriesService from '@services/pages/dashboard/customValuesCategories/customValueCategories';
import { useConfig } from '@helpers/hooks/useConfig';
import { useProductDetails } from '@helpers/hooks/pages/dashboard/productAnalytics/useProductDetails';
import SaveButton from './Save';

import * as S from './styles';

const Body: React.FC<IBodyProps> = ({ toggle, product }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(productCustomValueSchema),
  });
  const { utcToZonedTime, format } = useDate();
  const { analytics, user } = useConfig();
  const { toast } = useToast();
  const {
    mutateProductDetails,
    customValuesCategories,
    mutateCustomValuesCategories,
  } = useProductDetails();
  const { storeAliasId } = useParams<IParams>();

  const amountInput = register('amount');

  const [includeEndDate, setIncludeEndDate] = React.useState<boolean>(false);
  const [selectedEndDate, setSelectedEndDate] = React.useState<Date>(utcToZonedTime(new Date()));
  const [selectedStartDate, setSelectedStartDate] = React.useState<Date>(
    utcToZonedTime(new Date()),
  );
  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);

  const handleIncludeEndDate = React.useCallback(status => {
    setIncludeEndDate(status.checked);
  }, []);

  const onSubmit = React.useCallback(
    async data => {
      setIsSubmittingForm(true);

      try {
        let foundAdsCostCategory = customValuesCategories.find(
          category =>
            category.name === 'Custom com ADS' && category.group === EGroup.MARKETING_EXPENSES,
        );

        if (!foundAdsCostCategory) {
          const {
            data: customValueCategoriesData,
          } = await customValueCategoriesService.createCustomValueCategory({
            storeAliasId,
            data: {
              name: 'Custom com ADS',
              group: EGroup.MARKETING_EXPENSES,
              hex_color: '#9400D3',
            },
          });

          foundAdsCostCategory = customValueCategoriesData.custom_value_category;
        }

        await customValueService.createCustomValue({
          storeAliasId,
          customValuesCategoryAliasId: foundAdsCostCategory?.alias_id || '',
          data: {
            amount: currencyToNumber(data.amount),
            currency: data.currency,
            description: data.description,
            is_active: true,
            is_spend: true,
            start_date: format(utcToZonedTime(selectedStartDate), 'yyyy-MM-dd'),
            period: data.frequency,
            product_id: product.id,
            end_date: includeEndDate ? format(selectedEndDate, 'yyyy-MM-dd') : undefined,
            send_notification: false,
          },
        });

        analytics?.track(
          'Product Marketing Cost Added',
          {
            manual_fill: true,
            email: user?.email,
          },
          { context: { groupId: storeAliasId } },
        );
        analytics?.trackHubSpot(storeAliasId, { manual_fill: true });

        toast.success('Valor adicional criado com sucesso!');

        await Promise.all([mutateProductDetails(), mutateCustomValuesCategories()]);

        setIsSubmittingForm(false);
        toggle();
      } catch (error: any) {
        setIsSubmittingForm(false);

        toast.error(error?.response?.data?.message);
      }
    },
    [
      customValuesCategories,
      mutateCustomValuesCategories,
      mutateProductDetails,
      toast,
      storeAliasId,
      format,
      product,
      selectedEndDate,
      toggle,
      utcToZonedTime,
      analytics,
      selectedStartDate,
      includeEndDate,
      user,
    ],
  );

  const onValueChange = React.useCallback(event => {
    event.target.value = currencyFormatter(event.target.value);
  }, []);

  return (
    <S.Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label>Moeda</S.Label>
          <S.Select {...register('currency')}>
            <S.Option value="BRL">Real</S.Option>
            <S.Option value="USD">Dólar</S.Option>
          </S.Select>
          {errors.currency && <Text isErrorFeedback>{errors.currency.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Valor</S.Label>
          <S.Input {...amountInput} type="text" onChange={onValueChange} placeholder="0,00" />
          {errors.amount && <Text isErrorFeedback>{errors.amount.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Descrição</S.Label>
          <S.Input {...register('description')} type="text" placeholder="Ex. Produto X" />
          {errors.description && <Text isErrorFeedback>{errors.description.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Frequência</S.Label>
          <S.Select {...register('frequency')}>
            <S.Option value="ONCE">Única</S.Option>
            <S.Option value="DAILY">Diária</S.Option>
            <S.Option value="WEEKLY">Semanal</S.Option>
            <S.Option value="QUARTERLY">Trimestral</S.Option>
            <S.Option value="SEMI_ANNUALY">Semestral</S.Option>
            <S.Option value="MONTHLY">Mensal</S.Option>
          </S.Select>
        </S.InputGroup>

        <S.InputStartDateGroup>
          <S.Label>Começa em:</S.Label>

          <S.StartDayPicker
            selectedDate={selectedStartDate}
            setSelectedDate={setSelectedStartDate}
          />
        </S.InputStartDateGroup>

        <S.CheckboxGroup>
          <S.Checkbox checked={includeEndDate} onChange={handleIncludeEndDate} />
          <S.Label>Incluir data de término</S.Label>
        </S.CheckboxGroup>

        {includeEndDate && (
          <S.InputGroup>
            <S.Label>Termina em:</S.Label>

            <S.DayPicker selectedDate={selectedEndDate} setSelectedDate={setSelectedEndDate} />
          </S.InputGroup>
        )}

        <SaveButton toggle={toggle} isLoading={isSubmittingForm} disabled={isSubmittingForm} />
      </Form>
    </S.Wrapper>
  );
};

export default Body;
