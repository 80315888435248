import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';

export const Table = styled(TableBase)`
  & > table {
    & > thead {
      & > tr {
        & > th {
          padding: 10px 8px;
        }

        & > th:first-child {
          padding-left: 16px;
        }

        & > th:last-child {
          padding-right: 24px;
        }
      }
    }

    & > tbody {
      & > tr {
        & > td {
          padding-left: 8px;
          padding-right: 8px;
        }

        & > td:first-child {
          padding-left: 16px;
          padding-right: 8px;
        }

        & > td:last-child {
          padding-left: 16px;
        }

        & > :nth-child(2) {
          padding-left: 5px !important;
          padding-right: 5px !important;
        }
      }
    }
  }
`;

export const TextWrapper = styled.div``;

export const RevenueWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8;
`;

export const OrderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8;
`;

export const IconWrapper = styled.div`
  width: 38px;
`;

export const OrderDetails = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
`;
