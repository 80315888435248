import React from 'react';
import { useParams } from 'react-router-dom';

import { EDropshippingProvider } from '@domain/enums/common/integrations/EDropshippingProvider';
import { IParams } from '@domain/interfaces/IParams';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useToast } from '@helpers/hooks/useToast';

import woocommerceAuthService from '@services/pages/dashboard/integrationCenter/marketplace/woocommerce/woocommerceAuth';

import IntegrateForm from './IntegrateForm';
import SkeletonLoading from './SkeletonLoading';
import WoocommerceConfig from './WoocommerceConfig';

const Woocommerce: React.FC = () => {
  const { toast } = useToast();

  const { storeAliasId } = useParams<IParams>();

  const { storeIntegrations } = useStoreConfig();

  const [hasWoocommerceCredential, setHasWoocommerceCredential] = React.useState<boolean>(false);
  const [
    isGettingWoocommerceCredential,
    setIsGettingWoocommerceCredential,
  ] = React.useState<boolean>(false);

  const hasWoocommerceIntegration = Boolean(
    storeIntegrations?.store_front.find(
      integration =>
        integration.name === EDropshippingProvider.WOO_COMMERCE && integration.is_active,
    ),
  );

  const getWoocommerceCredential = React.useCallback(async (): Promise<any> => {
    setIsGettingWoocommerceCredential(true);

    try {
      const { data } = await woocommerceAuthService.getWoocommerceCredentialPromise({
        storeAliasId,
      });

      if (data.woo_commerce_credential.is_active) {
        setHasWoocommerceCredential(true);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsGettingWoocommerceCredential(false);
    }
  }, [storeAliasId, toast]);

  React.useEffect(() => {
    if (hasWoocommerceIntegration) {
      getWoocommerceCredential();
    }
  }, [getWoocommerceCredential, hasWoocommerceIntegration]);

  if (isGettingWoocommerceCredential) return <SkeletonLoading />;

  if (hasWoocommerceCredential) return <WoocommerceConfig />;

  return <IntegrateForm />;
};

export default Woocommerce;
