import React from 'react';
import { IIconProps } from '@profitfy/pakkun-icons';

import { EGateway } from '@domain/enums/common/EGateway';

import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { parseGateways } from '@helpers/utils/dashboard/financialDashboard/financialDashboard';

import { GATEWAYS } from '@constants/common/integrations';

import * as S from './styles';

const NetRevenueInfo: React.FC = () => {
  const { financialReport } = useFinancialDashboard();
  const { storeIntegrations } = useStoreConfig();

  const getGateway = React.useCallback(
    (gateway: EGateway) => {
      let gatewayToFilter = gateway;

      const foundOtherGateway = storeIntegrations.gateways.find(
        storeGateway => storeGateway.other_gateway?.has_other_gateway,
      );

      const isPredefinedGateway = !foundOtherGateway?.other_gateway?.gateway_names.includes(
        'OTHER',
      );

      if (gateway === EGateway.OTHER_GATEWAY && foundOtherGateway && isPredefinedGateway) {
        gatewayToFilter = foundOtherGateway.other_gateway?.gateway_names[0] as EGateway;
      }

      const foundIntegration = GATEWAYS.find(
        gatewayIntegration => gatewayIntegration.identifier === gatewayToFilter,
      );

      return foundIntegration;
    },
    [storeIntegrations],
  );

  const { gateway } = financialReport;

  const parsedGateways = parseGateways(gateway);

  const filteredGateways = parsedGateways.filter(integration => integration.amount > 0);

  const reversedFilteredFinancialReport = filteredGateways.reverse();

  const maxIntegrationsOfDisplay = reversedFilteredFinancialReport.slice(0, 3);

  const moreThanTwoGatewaysIntegrationAmount = maxIntegrationsOfDisplay.map(
    (filteredGateway, index) => {
      if (index === 0)
        return (
          <S.ExtraGatewaysIcon key={filteredGateway.provider}>{`+${
            filteredGateways.length - 2
          }`}</S.ExtraGatewaysIcon>
        );

      const foundIntegration = GATEWAYS.find(
        integration => integration.identifier === filteredGateway.provider,
      );

      const Icon = foundIntegration?.icon as React.FC<IIconProps>;

      return (
        <S.GatewaysIcon key={filteredGateway.provider}>
          <Icon size={16} />
        </S.GatewaysIcon>
      );
    },
  );

  const integrationsAmount = reversedFilteredFinancialReport.map(integration => {
    const foundIntegration = getGateway(integration.provider);

    const Icon = foundIntegration?.icon as React.FC<IIconProps>;

    return (
      <S.GatewaysIcon key={integration.provider}>
        <Icon size={16} />
      </S.GatewaysIcon>
    );
  });

  return (
    <S.Wrapper>
      {reversedFilteredFinancialReport.length > 2
        ? moreThanTwoGatewaysIntegrationAmount
        : integrationsAmount}
    </S.Wrapper>
  );
};

export default NetRevenueInfo;
