import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const Heading = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.popoverTitleColor};
  line-height: 120%;
  font-weight: 500;
  margin: 0;
  margin-bottom: 6px;
`;
