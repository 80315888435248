import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Info } from '@profitfy/pakkun-icons';
import { useParams } from 'react-router-dom';

import { IDomainModalProps } from '@domain/interfaces/components/IDomainModal';
import { domainSchema } from '@helpers/validators/components/domainModal';
import { useToast } from '@helpers/hooks/useToast';
import { IParams } from '@domain/interfaces/IParams';

import storesService from '@services/pages/stores/stores';

import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const DomainModal: React.FC<IDomainModalProps> = ({ isOpen, toggle, callback, store }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(domainSchema) });
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();

  const [isUpdatingStore, setIsUpdatingStore] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async data => {
      setIsUpdatingStore(true);

      try {
        let parsedDomain = data?.domain;

        const [domain] = parsedDomain.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/');

        parsedDomain = `https://www.${domain}`;

        await storesService.updateStore(storeAliasId, {
          ...store,
          domain: parsedDomain,
        });

        setIsUpdatingStore(false);

        callback();

        toggle();
      } catch (error: any) {
        setIsUpdatingStore(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [callback, toast, toggle, storeAliasId, store],
  );

  return (
    <S.Modal isOpen={isOpen} toggle={toggle} blockBackgroundClick>
      <S.Modal.Header>
        <Info size={24} />
        Adicionar URL do site de sua loja
      </S.Modal.Header>

      <S.Modal.Body>
        <S.Text>
          A Profitfy é uma plataforma que centraliza dados e cruza as informações para te{' '}
          <strong>gerar inteligência.</strong>
          <br />
          Por conta disso, solicitamos que você <strong>adicione a URL de Loja</strong> para que
          possamos criar <strong>novas soluções e experiências.</strong>
        </S.Text>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>URL da Loja</S.Label>
            <S.Input {...register('domain')} type="text" placeholder="minhaloja.com.br" />
            {errors.domain && <Text isErrorFeedback>{errors.domain.message}</Text>}
          </S.InputGroup>

          <S.SubmitButton type="submit" isLoading={isUpdatingStore} disabled={isUpdatingStore}>
            Confirmar
          </S.SubmitButton>
        </Form>
      </S.Modal.Body>
    </S.Modal>
  );
};

export default DomainModal;
