import styled from 'styled-components/macro';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';

export const InstallScriptAutomaticallyButton = styled(ButtonBase)``;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media only screen and (max-width: 512px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const VerifyButton = styled(ButtonBase)``;

export const CopyButton = styled(ButtonBase)`
  position: absolute;
  top: 10px;
  right: 16px;
  background-color: unset;
  border: 1px solid ${({ theme }) => theme.colors.green.default};

  span {
    color: ${({ theme }) => theme.colors.green.default};
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &:hover {
    filter: brightness(1.2);
    background-color: unset;
  }

  &:active {
    filter: brightness(0.9);
    background-color: unset;
  }
`;

export const CodeEditor = styled(SyntaxHighlighter)``;

export const CodeWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 16px;
`;

export const FinishedStepWrapper = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #223c30;
  border-radius: 6px;
`;

export const Title = styled(HeadingBase)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

export const Wrapper = styled.div`
  max-width: 720px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  padding: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  transition: all 0.3s;
  margin-bottom: 24px;

  @media only screen and (max-width: 1428px) {
    max-width: 648px;
  }

  @media only screen and (max-width: 1348px) {
    max-width: 720px;
  }

  pre {
    padding: 0 !important;
    margin: 0 !important;
    background: ${({ theme }) => theme.colors.black.light} !important;
    font-family: ${({ theme }) => theme.fonts.body}!important;
    font-size: 0.875rem !important;
    border-radius: 7px !important;
    padding: 32px !important;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
      position: absolute;
    }

    code {
      span {
        font-size: 0.875rem !important;
      }

      .linenumber {
        min-width: unset !important;
      }
    }
  }
`;
