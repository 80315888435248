import React from 'react';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { SimpleCheck } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components';
import { ETextSize } from '@domain/enums/components/EText';

import IntegrateForm from './IntegrateForm';

import * as S from './styles';

const OtherGateway: React.FC = () => {
  const theme = useTheme();

  return (
    <S.ContentWrapper>
      <S.HeadingAndIntegrateFormWrapper>
        <S.FormHeading>Integração com Gateway Outro</S.FormHeading>

        <IntegrateForm />
      </S.HeadingAndIntegrateFormWrapper>

      <S.BenefitsWrapper>
        <S.BenefitsHeading type={EHeadingSize.H6}>
          Por que nos fornecer seus dados de gateway?
        </S.BenefitsHeading>

        <S.BenefitsBody>
          <S.Benefit>
            <SimpleCheck size={12} color={theme.colors.green.dark} />
            <S.BenefitDescription size={ETextSize.B5}>Exatidão dos dados</S.BenefitDescription>
          </S.Benefit>

          <S.Benefit>
            <SimpleCheck size={12} color={theme.colors.green.dark} />
            <S.BenefitDescription size={ETextSize.B5}>
              Confiabilidade dos dados
            </S.BenefitDescription>
          </S.Benefit>
        </S.BenefitsBody>
      </S.BenefitsWrapper>
    </S.ContentWrapper>
  );
};

export default OtherGateway;
