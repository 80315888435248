import React from 'react';
import { ChevronLeft } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import * as S from './styles';

const PreviousArrow: React.FC<any> = ({ className, style, onClick }) => {
  const theme: any = useTheme();

  const [isHovering, setIsHovering] = React.useState<boolean>(false);

  const onMouseEnter = React.useCallback(() => setIsHovering(true), []);

  const onMouseLeave = React.useCallback(() => setIsHovering(false), []);

  React.useEffect(() => {
    if (!onClick) {
      setIsHovering(false);
    }
  }, [onClick]);

  return (
    <>
      {onClick ? (
        <S.Wrapper>
          <S.Button
            className={className}
            style={style}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <ChevronLeft size={28} color={isHovering ? '#fff' : theme.colors.green.dark} />
          </S.Button>
        </S.Wrapper>
      ) : (
        <></>
      )}
    </>
  );
};

export default PreviousArrow;
