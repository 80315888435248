import React from 'react';
import { useParams } from 'react-router-dom';

import {
  IAddressProvider,
  ICreateAddressData,
  IUpdateAddressData,
} from '@domain/interfaces/common/address/IAddress';
import { IParams } from '@domain/interfaces/IParams';

import addressService from '@services/common/address/address';

const AddressContext = React.createContext<IAddressProvider | null>(null);

export const AddressProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();

  const {
    address,
    isLoading: isLoadingAddress,
    isValidating: isValidatingAddress,
    mutate: mutateAddress,
    error: addressError,
  } = addressService.getAddress({ storeAliasId });

  const createAddress = React.useCallback(
    async (addressData: ICreateAddressData) => {
      await addressService.createAddress({ storeAliasId, data: addressData });

      await mutateAddress();
    },
    [mutateAddress, storeAliasId],
  );

  const updateAddress = React.useCallback(
    async (addressData: IUpdateAddressData) => {
      await addressService.updateAddress({ storeAliasId, data: addressData });

      await mutateAddress();
    },
    [mutateAddress, storeAliasId],
  );

  const deleteAddress = React.useCallback(async () => {
    await addressService.deleteAddress({ storeAliasId });

    await mutateAddress();
  }, [mutateAddress, storeAliasId]);

  return (
    <AddressContext.Provider
      value={{
        address,
        addressError,
        createAddress,
        deleteAddress,
        isLoadingAddress,
        isValidatingAddress,
        mutateAddress,
        updateAddress,
      }}
    >
      {children}
    </AddressContext.Provider>
  );
};

export const useAddress = (): IAddressProvider => {
  const context = React.useContext(AddressContext);

  if (!context) {
    throw new Error('useAddress must be used within AddressProvider');
  }

  return context;
};
