import React from 'react';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { ETextWeight } from '@domain/enums/components/EText';
import { IRowProps } from '@domain/interfaces/dashboard/ProductCostDetails/ITable';
import { useProductCostDetails } from '@helpers/hooks/pages/dashboard/productCostDetails/productCostDetails';
import { currencyFormatter, currencyToNumber, numberFormatter } from '@helpers/masks';
import { Pen } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useConfig } from '@helpers/hooks/useConfig';

import HistoricSidemodalWrapper from '@components/Dashboard/ProductCostDetails/HistoricSidemodalWrapper';
import Popover from '@components/common/core/Utils/Popover';
import Table from '@components/common/core/Utils/Table';

import { TrashSimple } from 'phosphor-react';
import DeleteModal from './DeleteModal';
import * as S from './styles';

const Row: React.FC<IRowProps> = ({ variant }) => {
  const {
    editedVariants,
    updateEditedVariants,
    costToApplyAll,
    currencyToApplyAll,
    onDeleteVariant,
    isDeletingVariant,
  } = useProductCostDetails();
  const theme: any = useTheme();
  const { analytics, user } = useConfig();

  const [isEditOpen, setIsEditOpen] = React.useState<boolean>(false);
  const [variantCost, setVariantCost] = React.useState<string>(
    numberFormatter(variant.default_cost, 2),
  );
  const [currency, setCurrency] = React.useState<string>(variant.currency);
  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const [isImageError, setIsImageError] = React.useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);

  const handleDeleteModalOpen = React.useCallback(() => setIsDeleteModalOpen(!isDeleteModalOpen), [
    isDeleteModalOpen,
  ]);

  const handleIsHovering = React.useCallback(status => setIsHovering(status), []);

  const handleEdit = React.useCallback(() => setIsEditOpen(!isEditOpen), [isEditOpen]);

  const getVariantsWithoutCurrentOne = React.useCallback(() => {
    const parsedVariants = editedVariants.filter(editedVariant => editedVariant.id !== variant.id);

    return parsedVariants;
  }, [editedVariants, variant]);

  const handleCostEdit = React.useCallback(
    event => {
      const newValue = currencyFormatter(event.target.value);

      setVariantCost(newValue);

      const parsedVariants = getVariantsWithoutCurrentOne();

      const currentVariantEdited = editedVariants.find(
        editedVariant => editedVariant.id === variant.id,
      );

      if (newValue && !currentVariantEdited) {
        const newEditedVariant = { ...variant, default_cost: currencyToNumber(newValue), currency };

        parsedVariants.push(newEditedVariant);
      }

      if (newValue && currentVariantEdited) {
        const newEditedVariant = {
          ...currentVariantEdited,
          default_cost: currencyToNumber(newValue),
          currency,
        };

        parsedVariants.push(newEditedVariant);
      }

      updateEditedVariants(parsedVariants);
    },
    [getVariantsWithoutCurrentOne, updateEditedVariants, variant, editedVariants, currency],
  );

  const handleCurrencyEdit = React.useCallback(
    event => {
      const parsedVariants = getVariantsWithoutCurrentOne();

      const currentVariantEdited = editedVariants.find(
        editedVariant => editedVariant.id === variant.id,
      );

      if (currentVariantEdited) {
        parsedVariants.push({ ...currentVariantEdited, currency: event.target.value });
      } else {
        parsedVariants.push({ ...variant, currency: event.target.value });
      }

      setCurrency(event.target.value);

      updateEditedVariants(parsedVariants);
    },
    [getVariantsWithoutCurrentOne, updateEditedVariants, variant, editedVariants],
  );

  React.useEffect(() => {
    const foundEditedVariant = editedVariants.find(
      editedVariant => editedVariant.alias_id === variant.alias_id,
    );

    if (!foundEditedVariant && costToApplyAll) {
      setVariantCost(numberFormatter(costToApplyAll, 2));
    }

    if (!foundEditedVariant && currencyToApplyAll) {
      setCurrency(currencyToApplyAll);
    }

    if (foundEditedVariant) {
      setVariantCost(numberFormatter(foundEditedVariant.default_cost, 2));
      setCurrency(foundEditedVariant.currency);
    }
  }, [editedVariants, variant, costToApplyAll, currencyToApplyAll]);

  const hasProductImage = variant?.image_url && !isImageError;
  const hasEdited = variant.is_cost_edited;

  const onDelete = React.useCallback(() => {
    analytics?.track('Delete Variant Product Button Clicked', { email: user?.email });

    onDeleteVariant(variant.alias_id);
  }, [onDeleteVariant, variant.alias_id, analytics, user]);

  return (
    <Table.Row
      onMouseEnter={() => handleIsHovering(true)}
      onMouseLeave={() => handleIsHovering(false)}
      borderBottom
    >
      <Table.Data>
        <S.ProductWrapper>
          {!hasProductImage && <S.NoImage />}

          {hasProductImage && (
            <S.Image
              src={variant.image_url}
              alt="Profitfy.me Variante"
              onError={() => setIsImageError(true)}
            />
          )}

          <S.Text
            weight={ETextWeight.MEDIUM}
            data-tip
            data-for={`variant-name-${variant.id}`}
            data-event="mouseenter mouseleave"
          >
            {variant?.name}
          </S.Text>

          <Popover width={128} place="top" id={`variant-name-${variant.id}`}>
            <S.VariantTooltipName>{variant?.name}</S.VariantTooltipName>
          </Popover>
        </S.ProductWrapper>
      </Table.Data>
      <Table.Data align={EHeadAlign.RIGHT}>
        <S.Text weight={ETextWeight.MEDIUM}>{`R$ ${numberFormatter(variant?.price, 2)}`}</S.Text>
      </Table.Data>
      <Table.Data>
        <S.CurrencySelect name="currency" onChange={handleCurrencyEdit} value={currency}>
          <S.Option value="USD">USD</S.Option>
          <S.Option value="BRL">BRL</S.Option>
        </S.CurrencySelect>
      </Table.Data>
      <Table.Data>
        <S.Input
          prependText={currency === 'USD' ? '$' : 'R$'}
          type="text"
          onChange={handleCostEdit}
          value={variantCost}
          noFeedbackError
        />
      </Table.Data>
      <Table.Data align={EHeadAlign.RIGHT}>
        <>
          <S.OptionsWrapper>
            <S.ActionsButton
              isHovering={isHovering}
              data-tip
              data-for={`variant-actions-${variant.id}`}
              data-event="click"
            >
              <S.Dot />
              <S.Dot />
              <S.Dot />
            </S.ActionsButton>

            {hasEdited && (
              <S.HasEditedTooltip>
                <S.HasEditedTooltip.Trigger>
                  <S.EditedCostWrapper>
                    <Pen outline size={16} color={theme.colors.green.default} />
                  </S.EditedCostWrapper>
                </S.HasEditedTooltip.Trigger>

                <S.HasEditedTooltip.Content side="top" sideOffset={5}>
                  <S.TooltipContent>
                    O custo dessa variante foi alterada manualmente
                  </S.TooltipContent>
                  <S.HasEditedTooltip.Arrow />
                </S.HasEditedTooltip.Content>
              </S.HasEditedTooltip>
            )}
          </S.OptionsWrapper>

          <Popover width={128} id={`variant-actions-${variant.id}`} place="bottom">
            <S.ActionsWrapper>
              <S.ActionButton onClick={handleEdit}>
                <Pen outline size={16} color={theme.colors.font} />
                Editar
              </S.ActionButton>
              <S.ActionButton onClick={handleDeleteModalOpen}>
                <TrashSimple size={17} color={theme.colors.font} />
                Excluir
              </S.ActionButton>
            </S.ActionsWrapper>
          </Popover>

          {isEditOpen && (
            <HistoricSidemodalWrapper isOpen={isEditOpen} toggle={handleEdit} variant={variant} />
          )}
        </>
      </Table.Data>

      <DeleteModal
        isLoading={isDeletingVariant}
        isOpen={isDeleteModalOpen}
        onDelete={onDelete}
        toggle={handleDeleteModalOpen}
        variantName={variant.name}
      />
    </Table.Row>
  );
};

export default Row;
