import React from 'react';
import { Percent } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import {
  ESynchronizationRequestType,
  ESynchronizationType,
} from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';

import { useCheckoutFee } from '@helpers/hooks/pages/dashboard/checkoutFee/useCheckoutFee';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useDate } from '@helpers/hooks/useDate';

import { currencyToNumber, numberFormatter } from '@helpers/masks';
import { getEnvironment } from '@helpers/utils/environment';

import {
  checkoutFeePercentageSchema,
  checkoutFeeFixedSchema,
} from '@helpers/validators/dashboard/checkoutFee/checkoutFee';

import { LONG_API_DOMAIN } from '@constants/api';

import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';
import SideModalSave from '@components/common/core/Utils/SideModalSave';

import * as S from './styles';

const HistoricSidebar: React.FC = () => {
  const {
    handleEditCheckoutFeeSideModalOpen,
    isEditCheckoutFeeSideModalOpen,
    userPeriodCheckoutFee,
    isUpdatingHistoricCheckoutFee,
    updateCheckoutFeePeriod,
    updateDefaultCheckoutFee,
  } = useCheckoutFee();

  const { addSynchronization } = useSynchronization();
  const { storeAliasId } = useParams<IParams>();

  const { utcToZonedTime, format } = useDate();
  const theme: any = useTheme();

  const [checkoutFeeChargeType, setCheckoutFeeChargeType] = React.useState<string>('percentage');
  const [period, setPeriod] = React.useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(
      checkoutFeeChargeType === 'percentage' ? checkoutFeePercentageSchema : checkoutFeeFixedSchema,
    ),
  });

  const amountInput = register('amount');
  const percentageInput = register('percentage');

  const onSubmit = React.useCallback(
    async data => {
      if (userPeriodCheckoutFee?.is_default) {
        await updateDefaultCheckoutFee({
          amount: checkoutFeeChargeType === 'fixed' ? currencyToNumber(data.amount) : 0,
          percentage_amount:
            checkoutFeeChargeType === 'percentage' ? currencyToNumber(data.percentage) : 0,
          name: userPeriodCheckoutFee?.name || 'any_checkout_fee_name',
        });
      } else {
        await updateCheckoutFeePeriod({
          amount: checkoutFeeChargeType === 'fixed' ? currencyToNumber(data.amount) : 0,
          name: userPeriodCheckoutFee?.name || 'any_checkout_fee_name',
          percentage_amount:
            checkoutFeeChargeType === 'percentage' ? currencyToNumber(data.percentage) : 0,
          start_date: format(utcToZonedTime(period.startDate), 'yyyy-MM-dd'),
          end_date: format(utcToZonedTime(period.endDate), 'yyyy-MM-dd'),
        });

        const params = `?start_date=${format(
          utcToZonedTime(period.startDate),
          'yyyy-MM-dd',
        )}&end_date=${format(utcToZonedTime(period.endDate), 'yyyy-MM-dd')}`;

        addSynchronization({
          name: 'Taxas de Checkout',
          type: ESynchronizationType.STORE_ORDER,
          label: 'Pedidos',
          dependencies: [],
          storeAliasId,
          config: {
            isOnboardingSynchronization: false,
            showNotification: true,
          },
          request: {
            type: ESynchronizationRequestType.POST,
            domain: LONG_API_DOMAIN,
            api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/orders/checkout-fee/sync`,
            requestParams: params,
          },
        });
      }

      handleEditCheckoutFeeSideModalOpen();
    },
    [
      addSynchronization,
      checkoutFeeChargeType,
      format,
      period,
      updateCheckoutFeePeriod,
      updateDefaultCheckoutFee,
      userPeriodCheckoutFee,
      utcToZonedTime,
      storeAliasId,
      handleEditCheckoutFeeSideModalOpen,
    ],
  );

  const onCheckoutFeeChargeTypeChange = React.useCallback(event => {
    setCheckoutFeeChargeType(event.target.value);
  }, []);

  const onAmountChange = React.useCallback(
    event => {
      amountInput.onChange(event);

      const { value } = event.target;

      event.target.value = numberFormatter(value, 2);
    },
    [amountInput],
  );

  const onPercentageChange = React.useCallback(
    event => {
      percentageInput.onChange(event);

      const { value } = event.target;

      event.target.value = numberFormatter(value, 2);
    },
    [percentageInput],
  );

  React.useEffect(() => {
    if (
      userPeriodCheckoutFee &&
      userPeriodCheckoutFee.start_date &&
      userPeriodCheckoutFee.end_date
    ) {
      setPeriod({
        startDate: utcToZonedTime(userPeriodCheckoutFee.start_date),
        endDate: utcToZonedTime(userPeriodCheckoutFee.end_date),
      });
    }
  }, [userPeriodCheckoutFee, utcToZonedTime]);

  React.useEffect(() => {
    if (userPeriodCheckoutFee && userPeriodCheckoutFee.amount) {
      setCheckoutFeeChargeType('fixed');
    } else {
      setCheckoutFeeChargeType('percentage');
    }
  }, [userPeriodCheckoutFee]);

  const isPercentage = Boolean(!userPeriodCheckoutFee?.amount);

  const maxDate = utcToZonedTime(new Date());

  return (
    <>
      {userPeriodCheckoutFee && (
        <S.Sidemodal
          isOpen={isEditCheckoutFeeSideModalOpen}
          toggle={handleEditCheckoutFeeSideModalOpen}
        >
          <S.Header>
            <Percent size={36} color={theme.colors.green.default} outline />
            <S.Heading>Editar Taxa</S.Heading>
          </S.Header>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <S.RadioGroup>
              <S.Label>Escolha como será cobrado:</S.Label>

              <S.RadioLabel>
                <S.Radio
                  value="percentage"
                  defaultChecked={isPercentage}
                  name="charge-type"
                  onChange={onCheckoutFeeChargeTypeChange}
                  id="percentage-option"
                />

                <S.RadioText>Porcentagem</S.RadioText>
              </S.RadioLabel>

              <S.RadioLabel>
                <S.Radio
                  value="fixed"
                  defaultChecked={!isPercentage}
                  name="charge-type"
                  onChange={onCheckoutFeeChargeTypeChange}
                  id="fixed-option"
                />

                <S.RadioText>Fixo</S.RadioText>
              </S.RadioLabel>
            </S.RadioGroup>

            <S.InputGroup>
              <S.Label>Valor da taxa por venda concluída</S.Label>

              {checkoutFeeChargeType === 'fixed' && (
                <>
                  <S.Input
                    {...amountInput}
                    type="text"
                    placeholder="Inserir valor"
                    prependText="R$"
                    defaultValue={numberFormatter(userPeriodCheckoutFee.amount, 2)}
                    onChange={onAmountChange}
                  />
                  {errors.amount && <Text isErrorFeedback>{errors.amount.message}</Text>}
                </>
              )}

              {checkoutFeeChargeType === 'percentage' && (
                <>
                  <S.Input
                    {...percentageInput}
                    type="text"
                    placeholder="Inserir valor"
                    prependText="%"
                    defaultValue={numberFormatter(userPeriodCheckoutFee.percentage_amount, 2)}
                    onChange={onPercentageChange}
                  />
                  {errors.percentage && <Text isErrorFeedback>{errors.percentage.message}</Text>}
                </>
              )}
            </S.InputGroup>

            {userPeriodCheckoutFee.start_date && userPeriodCheckoutFee.end_date && (
              <S.CustomDatePicker period={period} setPeriod={setPeriod} maxDate={maxDate} />
            )}

            <SideModalSave
              successButtonText="Salvar"
              cancelButtonText="Cancelar"
              type="submit"
              onCancel={handleEditCheckoutFeeSideModalOpen}
              isLoading={isUpdatingHistoricCheckoutFee}
              isDisabled={isUpdatingHistoricCheckoutFee}
            />
          </Form>
        </S.Sidemodal>
      )}
    </>
  );
};

export default HistoricSidebar;
