import React from 'react';
import { useTheme } from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { X as CloseIcon } from 'phosphor-react';

import { IParams } from '@domain/interfaces/IParams';

import { useLocalStorage } from '@helpers/hooks/useLocalStorage';
import { useConfig } from '@helpers/hooks/useConfig';
import { useMediaQuery } from '@helpers/hooks/useMediaQuery';
import { useDate } from '@helpers/hooks/useDate';

import { getEnvironment } from '@helpers/utils/environment';

import * as S from './styles';

const WorkflowAnnouncement: React.FC = () => {
  const theme = useTheme();
  const isMobileWidth = useMediaQuery('628px');
  const { analytics, user } = useConfig();
  const { isAfter } = useDate();
  const history = useHistory();

  const [showWorkflowAnnouncement, setShowWorkflowAnnouncement] = useLocalStorage(
    `@profitfy:${getEnvironment()}/workflow-announcement`,
    true,
  );
  const [countWorkflowAnnouncement, setCountWorkflowAnnouncement] = useLocalStorage(
    `@profitfy:${getEnvironment()}/workflow-announcement-count`,
    0,
  );

  const { storeAliasId } = useParams<IParams>();

  const onClickStart = React.useCallback(() => {
    setShowWorkflowAnnouncement(false);

    analytics?.track(
      'Adoption New Feature Modal Button Clicked',
      { email: user?.email, feature_name: 'Automations' },
      { context: { groupId: storeAliasId } },
    );

    history.push(`/${storeAliasId}/dashboard/marketing/automations`);
  }, [setShowWorkflowAnnouncement, history, analytics, user, storeAliasId]);

  const onCloseWorkflowAnnouncement = React.useCallback(() => {
    setShowWorkflowAnnouncement(false);
  }, [setShowWorkflowAnnouncement]);

  React.useEffect(() => {
    if (showWorkflowAnnouncement && !isMobileWidth) {
      analytics?.track(
        'Adoption New Feature Modal Viewed',
        { email: user?.email, feature_name: 'Automations' },
        { context: { groupId: storeAliasId } },
      );
    }
  }, [analytics, showWorkflowAnnouncement, user, storeAliasId, isMobileWidth]);

  React.useEffect(() => {
    if (showWorkflowAnnouncement) {
      const isAfterDate = isAfter(new Date(), new Date('2023-08-16T00:00:00.000Z'));

      if (isAfterDate) {
        setShowWorkflowAnnouncement(false);
      }
    }
  }, [isAfter, setShowWorkflowAnnouncement, showWorkflowAnnouncement]);

  React.useEffect(() => {
    setCountWorkflowAnnouncement((prev: number) => prev + 1);
  }, [setCountWorkflowAnnouncement]);

  React.useEffect(() => {
    if (countWorkflowAnnouncement > 3) {
      setShowWorkflowAnnouncement(false);
    }
  }, [countWorkflowAnnouncement, setShowWorkflowAnnouncement]);

  return (
    <>
      {showWorkflowAnnouncement && !isMobileWidth && (
        <S.Wrapper>
          <S.VideoWrapper>
            <S.Video src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/automations/gif_automa%C3%A7%C3%A3o.gif" />
          </S.VideoWrapper>

          <S.SubTitle>Novidade</S.SubTitle>
          <S.Title>Automação de mensagens</S.Title>
          <S.Description>
            Comece a criar fluxos de mensagens personalizadas para melhorar a sua operação!
          </S.Description>

          <S.Item bullets>Conecte vários números de WhatsApp;</S.Item>
          <S.Item bullets>Rotacione os números para evitar bloqueios;</S.Item>
          <S.Item bullets>Disparo de mensagens ilimitadas;</S.Item>
          <S.Item bullets>Galeria com modelos prontos;</S.Item>

          <S.LastItem>Comece a recuperar suas vendas hoje mesmo.</S.LastItem>

          <S.ButtonsWrapper>
            <S.SeeNewsButtons onClick={onClickStart}>Comece agora!</S.SeeNewsButtons>
          </S.ButtonsWrapper>

          <S.CloseButton onClick={onCloseWorkflowAnnouncement}>
            <CloseIcon size={18} color={theme.colors.gray[2]} weight="bold" />
          </S.CloseButton>
        </S.Wrapper>
      )}
    </>
  );
};

export default WorkflowAnnouncement;
