import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import ButtonBase from '@components/common/core/Inputs/Button';
import FilterButtonBase from '@components/common/core/Utils/Filter/FilterButton';

export const FilterButton = styled(FilterButtonBase)`
  background-color: ${({ theme }) => theme.colors.background[2]};
  border: unset;
  flex-direction: row-reverse;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 220px;

  @media only screen and (max-width: 945px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Heading = styled(HeadingBase)`
  color: #ffffff;
`;

export const Description = styled(TextBase)`
  color: ${({ theme }) => theme.colors.white.dark};
`;

export const FilterAndSearchInputWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 34px;

  @media only screen and (max-width: 945px) {
    align-items: flex-start;
    gap: 16px;
  }
`;

export const Input = styled(TextFieldBase)`
  min-width: 323px;

  @media only screen and (max-width: 496px) {
    min-width: unset;
  }
`;

export const NewMessageButton = styled(ButtonBase)`
  max-width: 195px;
  box-shadow: none;

  &:hover {
    box-shadow: none !important;
  }

  span {
    font-size: 0.875rem;
  }
`;
