import React from 'react';
import { Plus, ProfileConfig, OperationalExpends, Exit } from '@profitfy/pakkun-icons';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfig } from '@helpers/hooks/useConfig';
import { IStore } from '@domain/interfaces/stores/IStore';
import { EAvatarSize } from '@domain/enums/components/EAvatar';
import { ETextWeight } from '@domain/enums/components/EText';

import * as S from './styles';

const Dropdown: React.FC = ({ children }) => {
  const { stores, handleLogout } = useConfig();
  const history = useHistory();
  const { pathname } = useLocation();

  const [storeAliasId, setStoreAliasId] = React.useState<string>('');

  const handleProfile = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/profile`);
  }, [history, storeAliasId]);

  const handleStoreSubscription = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/subscription`);
  }, [history, storeAliasId]);

  const handleAddStore = React.useCallback(() => {
    history.push(`/stores`);
  }, [history]);

  const handleChangeStore = React.useCallback(
    newStoreAliasId => {
      history.push(`/${newStoreAliasId}/dashboard/overview/financial`);
    },
    [history],
  );

  React.useEffect(() => {
    const foundStoreAliasId = pathname.match(/store-([a-zA-Z0-9]+)/);

    if (foundStoreAliasId?.length) {
      setStoreAliasId(foundStoreAliasId[0]);
    }
  }, [pathname]);

  return (
    <S.CustomDropdown>
      <S.CustomDropdown.Trigger>{children}</S.CustomDropdown.Trigger>

      <S.CustomDropdown.Content sideOffset={12} align="end" style={{ borderRadius: 15 }}>
        <S.StoresWrapper>
          {stores?.map((store: IStore) => (
            <S.CustomDropdown.Item
              onClick={() => handleChangeStore(store.alias_id)}
              key={store.alias_id}
            >
              <S.StoreWrapper>
                <S.StoreAvatar
                  isCurrentStore={storeAliasId === store.alias_id}
                  word={store?.name.charAt(0) || 'N'}
                  size={EAvatarSize.SMALL}
                  alt="Store avatar"
                />
                <S.StoreName
                  isCurrentStore={storeAliasId === store.alias_id}
                  weight={ETextWeight.MEDIUM}
                >
                  {store?.name}
                </S.StoreName>
              </S.StoreWrapper>
            </S.CustomDropdown.Item>
          ))}
        </S.StoresWrapper>

        <S.CustomDropdown.Separator />

        <S.CustomDropdown.Item onClick={handleAddStore}>
          <S.NewStoreWrapper>
            <Plus size={16} color="gray" />
            <S.OptionName weight={ETextWeight.MEDIUM}>Adicionar nova loja</S.OptionName>
          </S.NewStoreWrapper>
        </S.CustomDropdown.Item>

        <S.CustomDropdown.Separator />

        <S.OptionsWrapper>
          <S.CustomDropdown.Item onClick={handleProfile}>
            <S.OptionWrapper>
              <ProfileConfig size={24} color="#D9DDE9" />
              <S.OptionName weight={ETextWeight.MEDIUM}>Meu perfil</S.OptionName>
            </S.OptionWrapper>
          </S.CustomDropdown.Item>

          <S.CustomDropdown.Item onClick={handleStoreSubscription}>
            <S.OptionWrapper>
              <OperationalExpends size={24} color="#D9DDE9" />
              <S.OptionName weight={ETextWeight.MEDIUM}>Minha assinatura</S.OptionName>
            </S.OptionWrapper>
          </S.CustomDropdown.Item>

          <S.CustomDropdown.Item onClick={handleLogout}>
            <S.OptionWrapper>
              <Exit size={20} color="#D9DDE9" />
              <S.OptionName weight={ETextWeight.MEDIUM}>Sair</S.OptionName>
            </S.OptionWrapper>
          </S.CustomDropdown.Item>
        </S.OptionsWrapper>

        <S.CustomDropdown.Arrow />
      </S.CustomDropdown.Content>
    </S.CustomDropdown>
  );
};

export default Dropdown;
