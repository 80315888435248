import queryString from 'query-string';
import { AxiosResponse } from 'axios';

import {
  IGetFinancialTableByPeriodPromiseResponse,
  IGetFinancialTableByPeriodProps,
} from '@domain/interfaces/common/product/IProductFinancialTableByPeriod';

import { ApiService } from '@services/api';

import { API_DASHBOARD_DOMAIN } from '@constants/api';

export class ProductFinancialTableByPeriodService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getFinancialTableByPeriodPromise({
    storeAliasId,
    productAliasId,
    startDate,
    endDate,
    filter,
  }: IGetFinancialTableByPeriodProps): Promise<
    AxiosResponse<IGetFinancialTableByPeriodPromiseResponse>
  > {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    return this.apiService.get<IGetFinancialTableByPeriodPromiseResponse>(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/dashboards/financial-table-by-period?start_date=${startDate}&end_date=${endDate}&${queryStringFilter}`,
    );
  }
}

const productFinancialTableByPeriodService = new ProductFinancialTableByPeriodService(
  API_DASHBOARD_DOMAIN,
);

export default productFinancialTableByPeriodService;
