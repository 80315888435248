import React from 'react';

import { ITemplate, ITemplateProvider } from '@domain/interfaces/common/automations/ITemplate';
import { ESelectedOption } from '@domain/enums/dashboard/automations/ETemplate';

const TemplateContext = React.createContext<ITemplateProvider | null>(null);

export const TemplateProvider: React.FC = ({ children }) => {
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [selectedTemplate, setSelectedTemplate] = React.useState<ITemplate>({} as ITemplate);
  const [isTemplateSummaryModalOpen, setIsTemplateSummaryModalOpen] = React.useState<boolean>(
    false,
  );
  const [selectedOption, setSelectedOption] = React.useState<ESelectedOption>(
    ESelectedOption.ALL_TEMPLATES,
  );

  const handleSearchValue = React.useCallback(value => {
    setSearchValue(value);
  }, []);

  const handleSelectedOption = React.useCallback(value => {
    setSelectedOption(value);
  }, []);

  const handleSelectedTemplate = React.useCallback(template => {
    setSelectedTemplate(template);
  }, []);

  const handleTemplateSummaryModalOpen = React.useCallback(() => {
    setIsTemplateSummaryModalOpen(currentState => !currentState);
  }, []);

  return (
    <TemplateContext.Provider
      value={{
        handleSearchValue,
        searchValue,
        handleSelectedOption,
        selectedOption,
        handleSelectedTemplate,
        selectedTemplate,
        handleTemplateSummaryModalOpen,
        isTemplateSummaryModalOpen,
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
};

export const useTemplate = (): ITemplateProvider => {
  const context = React.useContext(TemplateContext);

  if (!context) {
    throw new Error('useTemplate must be used within TemplateContext');
  }

  return context;
};
