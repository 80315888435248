import React from 'react';

import { useParams } from 'react-router-dom';

import { IWhatsappInstanceProvider } from '@domain/interfaces/dashboard/WhatsappInstance/IWhatsappInstance';

import { IParams } from '@domain/interfaces/IParams';
import { useDate } from '@helpers/hooks/useDate';
import { useToast } from '@helpers/hooks/useToast';

import whatsappCredentialService from '@services/pages/dashboard/whatsappInstance/whatsappCredential';

const WhatsappInstanceContext = React.createContext<IWhatsappInstanceProvider | null>(null);

export const WhatsappInstanceProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { utcToZonedTime, subDays, format } = useDate();

  const [isUpdateInstanceModalOpen, setIsUpdateInstanceModalOpen] = React.useState<boolean>(false);
  const [isDeleteInstanceModalOpen, setIsDeleteInstanceModalOpen] = React.useState<boolean>(false);
  const [isNewInstanceModalOpen, setIsNewInstanceModalOpen] = React.useState<boolean>(false);
  const [isDeletingCredential, setIsDeletingCredential] = React.useState<boolean>(false);
  const [isNoDeletedInstanceModalOpen, setIsNoDeletedInstanceModalOpen] = React.useState<boolean>(
    false,
  );
  const [showConnectedInstance, setShowConnectedInstance] = React.useState<boolean>(false);
  const [hasUnconnectedWhatsapp, setHasUnconnectedWhatsapp] = React.useState<boolean>(false);

  const lastSevenDays = utcToZonedTime(subDays(new Date(), 6));

  const {
    whatsappCredentials,
    mutate: mutateWhatsappCredential,
    error: whatsappCredentialError,
    isLoading: isLoadingWhatsappCredential,
    isValidating: isValidatingWhatsappCredential,
  } = whatsappCredentialService.listAllCredentials({
    storeAliasId,
    start_date: format(lastSevenDays, 'yyyy-MM-dd'),
    end_date: format(utcToZonedTime(new Date()), 'yyyy-MM-dd'),
  });

  const handleUpdateInstanceModalOpen = React.useCallback(() => {
    setIsUpdateInstanceModalOpen(currentState => !currentState);
  }, []);

  const handleDeleteInstanceModalOpen = React.useCallback(() => {
    setIsDeleteInstanceModalOpen(currentState => !currentState);
  }, []);

  const handleShowConnectedInstance = React.useCallback(() => {
    setShowConnectedInstance(currentState => !currentState);
  }, []);

  const handleNoDeletedInstanceModalOpen = React.useCallback(() => {
    setIsNoDeletedInstanceModalOpen(currentState => !currentState);
  }, []);

  const handleNewInstanceModalOpen = React.useCallback(() => {
    setIsNewInstanceModalOpen(currentState => !currentState);
  }, []);

  const deleteCredential = React.useCallback(
    async credentialAliasId => {
      setIsDeletingCredential(true);

      try {
        await whatsappCredentialService.deleteCredential({ storeAliasId, credentialAliasId });

        toast.success('Instância deletada com sucesso.');

        mutateWhatsappCredential();

        setIsDeletingCredential(false);
      } catch (error: any) {
        setIsDeletingCredential(false);

        toast.error(error?.response?.data?.message);
      }
    },
    [toast, storeAliasId, mutateWhatsappCredential],
  );

  React.useEffect(() => {
    if (whatsappCredentials) {
      const hasUconnected = whatsappCredentials.some(
        item => item.whatsapp_credential.is_connected === false,
      );

      setHasUnconnectedWhatsapp(hasUconnected);
    }
  }, [whatsappCredentials]);

  return (
    <WhatsappInstanceContext.Provider
      value={{
        handleUpdateInstanceModalOpen,
        isUpdateInstanceModalOpen,
        handleDeleteInstanceModalOpen,
        isDeleteInstanceModalOpen,
        handleNoDeletedInstanceModalOpen,
        isNoDeletedInstanceModalOpen,
        handleNewInstanceModalOpen,
        isNewInstanceModalOpen,
        isLoadingWhatsappCredential,
        isValidatingWhatsappCredential,
        mutateWhatsappCredential,
        whatsappCredentialError,
        whatsappCredentials,
        deleteCredential,
        isDeletingCredential,
        hasUnconnectedWhatsapp,
        showConnectedInstance,
        handleShowConnectedInstance,
      }}
    >
      {children}
    </WhatsappInstanceContext.Provider>
  );
};

export const useWhatsappInstance = (): IWhatsappInstanceProvider => {
  const context = React.useContext(WhatsappInstanceContext);

  if (!context) {
    throw new Error('useWhatsappInstance must be used within WhatsappInstanceProvider');
  }

  return context;
};
