import styled, { css } from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';
import { IPageErrorWrapperProps } from '@domain/interfaces/components/IPageError';

export const PageErrorWrapper = styled.div<IPageErrorWrapperProps>`
  margin: 52px 0 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ isNotDashboardError }) =>
    isNotDashboardError &&
    css`
      margin: unset;
      padding: 52px 0 32px 0;
    `}
`;

export const Image = styled(ImageBase)`
  max-width: 264px;
  margin-bottom: 32px;

  @media only screen and (max-width: 545px) {
    margin-bottom: 22px;
  }
`;

export const Text = styled(TextBase)`
  margin-bottom: 16px;
  display: inline-block;
  text-align: center;
`;

export const Button = styled(ButtonBase)`
  margin-top: 20px;
`;
