import React from 'react';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { useProductDetails } from '@helpers/hooks/pages/dashboard/productAnalytics/useProductDetails';
import { numberFormatter } from '@helpers/masks';
import ProfitChart from './ProfitChart';

import * as S from './styles';

const ProfitCard: React.FC = () => {
  const { productFinancialReport } = useProductDetails();

  const { net_profit } = productFinancialReport;

  const isNetProfitAmountPositive = net_profit.amount > 0;
  const isNetProfitAmountNegative = net_profit.amount < 0;

  const isPercentagePositive = net_profit.growth >= 0;
  const parsedPercentageValue = numberFormatter(
    isPercentagePositive ? net_profit.growth : Math.abs(net_profit.growth),
    2,
  );

  return (
    <S.Card>
      <S.Title fontWeight={EHeadingWeight.LIGHT}>Lucro Líquido</S.Title>

      <S.ProfitValueAndPercentageWrapper>
        <S.ProfitValue
          isNegative={isNetProfitAmountNegative}
          isPositive={isNetProfitAmountPositive}
        >{`R$ ${numberFormatter(net_profit.amount, 2)}`}</S.ProfitValue>
        <S.PercentageWrapper>
          <S.PercentageArrow isPositive={isPercentagePositive} />
          <S.PercentageValue
            isPositive={isPercentagePositive}
          >{`${parsedPercentageValue}%`}</S.PercentageValue>
        </S.PercentageWrapper>
      </S.ProfitValueAndPercentageWrapper>

      <S.ComparedPeriodText>
        {isPercentagePositive ? 'a mais neste período' : 'a menos neste período'}
      </S.ComparedPeriodText>

      <S.ChartWrapper>
        <ProfitChart />
      </S.ChartWrapper>
    </S.Card>
  );
};

export default ProfitCard;
