import React from 'react';
import { Search, Sync, Tag } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';

import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';

import { useCampaigns } from '@helpers/hooks/pages/dashboard/campaigns/useCampaigns';
import { useDebounce } from '@helpers/hooks/useDebounce';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { PRODUCT_ANALYTICS_CONFIG } from '@helpers/utils/filters/productAnalyticsFilter';

import { getSynchronizationStatus } from '@helpers/utils/common/synchronization';

import { ADS_PROVIDERS } from '@constants/common/integrations';

import Popover from '@components/common/core/Utils/Popover';
import GoBack from '@components/common/core/Utils/GoBack';
import FilterButton from '@components/common/core/Utils/Filter/FilterButton';

import * as S from './styles';

const Header: React.FC = () => {
  const theme: any = useTheme();
  const history = useHistory();
  const { storeIntegrations } = useStoreConfig();
  const { storeAliasId } = useParams<IParams>();
  const {
    addSynchronization,
    currentSynchronizations,
    synchronizationsQueue,
  } = useSynchronization();
  const {
    checkedAdSenseCampaigns,
    isSyncingAdSenseCampaigns,
    updateCheckedAdSenseCampaigns,
    handleSearchName,
    handleTagsSideModalOpen,
    filterData,
    handleFilterData,
  } = useCampaigns();

  const [campaignName, setCampaignName] = React.useState<string>('');

  const debouncedCampaignName = useDebounce(campaignName, 700);

  const handleOnChange = React.useCallback(event => setCampaignName(event.target.value), []);

  const handleGoBack = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const onSync = React.useCallback(() => {
    if (storeIntegrations) {
      const activeAdSenseIntegrations = storeIntegrations.marketing.filter(
        integration => integration.is_active,
      );
      const marketingIntegrations = ADS_PROVIDERS.filter(integration =>
        activeAdSenseIntegrations.find(
          activeIntegration => activeIntegration.name === integration.identifier,
        ),
      );

      marketingIntegrations.forEach(integration => {
        addSynchronization({
          name: integration.name,
          type: ESynchronizationType.AD_SENSE_CAMPAIGNS,
          label: 'Campanhas',
          dependencies: [],
          storeAliasId,
          config: {
            isOnboardingSynchronization: false,
            showNotification: true,
          },
          request: integration.generateSyncRequest(
            ESynchronizationType.AD_SENSE_CAMPAIGNS,
            storeAliasId,
            undefined,
            undefined,
          ),
        });
      });
    }
  }, [addSynchronization, storeAliasId, storeIntegrations]);

  const onUpdateCheckedCampaigns = React.useCallback(() => {
    updateCheckedAdSenseCampaigns();
  }, [updateCheckedAdSenseCampaigns]);

  const handleNewData = React.useCallback(
    newData => {
      handleFilterData([newData]);
    },
    [handleFilterData],
  );

  React.useEffect(() => {
    handleSearchName(debouncedCampaignName);
  }, [handleSearchName, debouncedCampaignName]);

  const isSyncing = getSynchronizationStatus(
    currentSynchronizations,
    synchronizationsQueue,
    undefined,
    ESynchronizationType.AD_SENSE_CAMPAIGNS,
    storeAliasId,
  );

  return (
    <S.Wrapper>
      <GoBack text="Voltar a página anterior" onClick={handleGoBack} />

      <S.TitleAndButtonsWrapper>
        <S.Title>Campanhas</S.Title>

        <S.ButtonsWrapper>
          <S.NewTagButton onClick={handleTagsSideModalOpen}>
            <S.ButtonIconWrapper>
              <Tag size={14} color={theme.colors.font} />
            </S.ButtonIconWrapper>
            Criar tag de sincronização
          </S.NewTagButton>
          <S.Button
            onClick={onSync}
            isLoading={isSyncingAdSenseCampaigns || isSyncing}
            disabled={isSyncingAdSenseCampaigns || isSyncing}
          >
            <Sync outline size={16} color={theme.colors.gray[7]} />
            Atualizar campanhas
          </S.Button>
        </S.ButtonsWrapper>
      </S.TitleAndButtonsWrapper>

      <S.FilterWrapper>
        <S.MoreOptionsButton enabled={Boolean(checkedAdSenseCampaigns.length)}>
          <S.OptionsIcon>
            <S.Bullet />
            <S.Bullet />
            <S.Bullet />
          </S.OptionsIcon>

          <S.MoreOptionText data-tip data-for="more-options" data-event="click">
            Mais opções
          </S.MoreOptionText>
        </S.MoreOptionsButton>
        <Popover id="more-options" place="bottom">
          <S.OptionsWrapper>
            <S.ActionButton onClick={onUpdateCheckedCampaigns}>
              <Sync outline size={16} color={theme.colors.font} />
              Ativar campanhas
            </S.ActionButton>
          </S.OptionsWrapper>
        </Popover>

        <S.FilterAndInputWrapper>
          {Boolean(!filterData.length) && (
            <FilterButton
              data={filterData}
              config={PRODUCT_ANALYTICS_CONFIG}
              addNewData={handleNewData}
            />
          )}
          <S.Input
            type="text"
            prepend={Search}
            placeholder="Procurar..."
            onChange={handleOnChange}
          />
        </S.FilterAndInputWrapper>
      </S.FilterWrapper>
    </S.Wrapper>
  );
};

export default Header;
