import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const CancelButton = styled.button`
  background: unset;
  border: unset;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.font};
  font-size: 0.875rem;
  font-weight: 500;
  padding: 8px 20px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const UnderstoodButton = styled(ButtonBase)`
  width: 136px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

export const Description = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 150%;
  display: inline-block;
  margin-bottom: 16px;
`;

export const Modal = styled(ModalBase)`
  & > div:first-child {
    & > span {
      font-size: 1.25rem;
      gap: 8px;
    }
  }

  & > div:last-child {
    max-width: 464px;
    margin-top: 12px;
  }
`;
