import React from 'react';

import { ICouponProvider, ICreateCouponData } from '@domain/interfaces/common/coupon/ICoupon';

import { useToast } from '@helpers/hooks/useToast';

import couponService from '@services/common/coupon/coupon';

const CouponContext = React.createContext<ICouponProvider | null>(null);

export const CouponProvider: React.FC = ({ children }) => {
  const { toast } = useToast();

  const [isCreatingCoupon, setIsCreatingCoupon] = React.useState<boolean>(false);

  const {
    coupons,
    isLoading: isLoadingCoupons,
    isValidating: isValidatingCoupons,
    mutate: mutateCoupons,
    error: couponsError,
  } = couponService.listCoupons();

  const mutate = React.useCallback(() => {
    const promises = [];

    if (couponsError) promises.push(mutateCoupons());

    Promise.all(promises);
  }, [couponsError, mutateCoupons]);

  const createCoupon = React.useCallback(
    async (createCouponData: ICreateCouponData) => {
      setIsCreatingCoupon(true);

      try {
        await couponService.createCoupon({ data: createCouponData });

        toast.success('Cupom criado com sucesso!');

        await mutateCoupons();

        setIsCreatingCoupon(false);
      } catch (error: any) {
        setIsCreatingCoupon(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [mutateCoupons, toast],
  );

  const isLoading = isLoadingCoupons;
  const isValidating = isValidatingCoupons;
  const isError = Boolean(couponsError);

  return (
    <CouponContext.Provider
      value={{
        coupons,
        isError,
        isLoading,
        isValidating,
        mutate,
        createCoupon,
        isCreatingCoupon,
      }}
    >
      {children}
    </CouponContext.Provider>
  );
};

export const useCoupon = (): ICouponProvider => {
  const context = React.useContext(CouponContext);

  if (!context) {
    throw new Error('useCoupon must be used within CouponProvider');
  }

  return context;
};
