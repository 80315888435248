import React from 'react';

import { EButtonTextColor } from '@domain/enums/components/EButton';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';

import { useConfig } from '@helpers/hooks/useConfig';
import { useHistory } from 'react-router-dom';

import NextStep from '@components/Onboarding/NextStep';

import logo from '@assets/img/brand/profitfy-logo.svg';

import * as S from './styles';

const Welcome: React.FC = () => {
  const history = useHistory();

  const { analytics } = useConfig();

  const nextStep = React.useCallback(() => {
    history.push('/onboarding/segmentation');

    return analytics?.pageview('Onboarding Welcome');
  }, [history, analytics]);

  return (
    <S.Container>
      <S.Wrapper>
        <S.LogoWrapper src={logo} alt="Profitfy.me" />

        <S.ContentWrapper>
          <S.ImageWrapper
            src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/onboarding/images/content/onboarding/welcome.svg"
            alt="Profitfy.me Onboarding Welcome"
          />

          <S.TextWrapper>
            <S.Heading type={EHeadingSize.H2} fontWeight={EHeadingWeight.MEDIUM}>
              Bem-vindo a Profitfy.me.
              <br />
              Vamos começar a configuração?
            </S.Heading>

            <S.Text>
              O onboarding é importante para que tudo funcione como esperado. Isso deve levar de
              <strong>5-10 minutos.</strong>
            </S.Text>
          </S.TextWrapper>
        </S.ContentWrapper>
      </S.Wrapper>

      <NextStep textColor={EButtonTextColor.DARK} onClick={nextStep}>
        Começar onboarding
      </NextStep>
    </S.Container>
  );
};

export default Welcome;
