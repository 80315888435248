import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const UnderstoodButton = styled(ButtonBase)`
  margin-left: auto;
`;

export const Description = styled(TextBase)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray[1]};
  font-size: 1rem;
  margin-bottom: 16px;
`;

export const Title = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
  font-size: 1.125rem;
`;

export const Body = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
`;

export const Modal = styled(ModalBase)`
  max-width: 464px;
  width: 100%;
  box-sizing: border-box;
`;
