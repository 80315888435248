import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const TotalCampaignsText = styled(TextBase)`
  white-space: nowrap;
  font-weight: 700;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-left: 64px;
`;
