import React from 'react';

import { StoreSubscriptionProvider } from '@helpers/hooks/pages/dashboard/storeSubscription/useStoreSubscription';
import { SubscriptionDataProvider } from '@helpers/hooks/pages/dashboard/subscription/useSubscriptionData';

import Wrapper from '@components/Dashboard/StoreSubscription/Wrapper';
import NoAccess from '@components/common/core/Utils/NoAccess';
import { DEMO_STORE_PROD_ALIAS_ID } from '@constants/demoStore';
import { IParams } from '@domain/interfaces/IParams';
import { useParams } from 'react-router-dom';

const StoreSubscription: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const isDemoStore = storeAliasId && storeAliasId === DEMO_STORE_PROD_ALIAS_ID;

  if (isDemoStore) {
    return <NoAccess />;
  }

  return (
    <StoreSubscriptionProvider>
      <SubscriptionDataProvider>
        <Wrapper />
      </SubscriptionDataProvider>
    </StoreSubscriptionProvider>
  );
};

export default StoreSubscription;
