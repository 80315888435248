import React from 'react';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import { useDate } from '@helpers/hooks/useDate';
import { useConfig } from '@helpers/hooks/useConfig';
import { useMediaQuery } from '@helpers/hooks/useMediaQuery';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { useSubscriptionPlans } from '@helpers/hooks/pages/dashboard/subscription/useSubscriptionPlans';

import subscriptionService from '@services/pages/subscription/subscription';

import PageLoading from '@components/Dashboard/PageLoading';
import PageError from '@components/common/core/Utils/PageError';
import StoreOrdersQuantity from '@components/Pricing/StoreOrdersQuantity';
import PricingNavbar from '@components/common/core/Utils/PricingNavbar';

import Plans from '@components/Subscription/Pricing/Plans';
import MobilePlans from '@components/Subscription/Pricing/MobilePlans';
import Footer from '@components/Subscription/Pricing/Footer';
import DowngradeModal from '@components/Subscription/Pricing/DowngradingModal';
import ComparePlans from '@components/Subscription/Pricing/ComparePlans';
import Faq from '@components/Subscription/Pricing/Faq';
import Phone from '@components/Subscription/Pricing/Phone';
import Commentary from '@components/Subscription/Pricing/Commentary';

import * as S from './styles';

const Pricing: React.FC = () => {
  const isMobileResolution = useMediaQuery('728px');
  const hideTableComparison = useMediaQuery('999px');
  const { storeAliasId } = useParams<IParams>();
  const { utcToZonedTime, subDays, format } = useDate();
  const { analytics, selectedStore, user } = useConfig();
  const {
    isLoadingSubscriptionProviderData,
    isValidatingSubscriptionProviderData,
    isSubscriptionProviderDataError,
    mutateSubscriptionProviderData,
  } = useStoreSubscription();
  const {
    isLoadingDowngradeRequests,
    isValidatingDowngradeRequests,
    isDowngradeRequestsError,
    mutate: mutateDowngradeRequest,
  } = useSubscriptionPlans();

  const {
    ordersQuantity,
    mutate: mutateOrdersQuantity,
    isValidating: isValidatingOrdersQuantity,
    isLoading: isLoadingOrdersQuantity,
    error: ordersQuantityError,
  } = subscriptionService.getStoreOrdersQuantity({
    storeAliasId,
    startDate: format(utcToZonedTime(subDays(new Date(), 30)), 'yyyy-MM-dd'),
    endDate: format(utcToZonedTime(new Date()), 'yyyy-MM-dd'),
  });

  const mutate = React.useCallback(async () => {
    const promises = [];

    if (ordersQuantityError) promises.push(mutateOrdersQuantity());

    if (isSubscriptionProviderDataError) promises.push(mutateSubscriptionProviderData());

    if (isDowngradeRequestsError) promises.push(mutateDowngradeRequest());

    await Promise.all(promises);
  }, [
    ordersQuantityError,
    mutateOrdersQuantity,
    isSubscriptionProviderDataError,
    mutateSubscriptionProviderData,
    isDowngradeRequestsError,
    mutateDowngradeRequest,
  ]);

  React.useEffect(() => {
    if (ordersQuantity) {
      analytics?.track(
        'Pricing Page Viewed',
        {
          email: user?.email,
        },
        { context: { groupId: selectedStore?.alias_id } },
      );
      analytics?.trackHubSpot(selectedStore?.alias_id, { plan_page_viewed: true });
    }
  }, [analytics, ordersQuantity, selectedStore, user]);

  React.useEffect(() => {
    analytics?.track(
      'Subscription Pricing Page Viewed',
      {
        email: user?.email,
      },
      {
        context: { groupId: selectedStore?.alias_id },
      },
    );
  }, [analytics, selectedStore, user]);

  if (
    isLoadingOrdersQuantity ||
    isLoadingSubscriptionProviderData ||
    isLoadingDowngradeRequests ||
    isValidatingOrdersQuantity ||
    isValidatingSubscriptionProviderData ||
    isValidatingDowngradeRequests
  ) {
    return <PageLoading />;
  }

  if (
    (ordersQuantityError || isSubscriptionProviderDataError || isDowngradeRequestsError) &&
    !isValidatingOrdersQuantity &&
    !isValidatingOrdersQuantity &&
    !isDowngradeRequestsError
  ) {
    return <PageError mutate={mutate} isNotDashboardError />;
  }

  return (
    <S.Container>
      <S.Content>
        <StoreOrdersQuantity quantity={ordersQuantity || 0} />

        <S.NavBarWrapper>
          <PricingNavbar />
        </S.NavBarWrapper>

        {isMobileResolution ? <MobilePlans /> : <Plans />}

        {!hideTableComparison && <ComparePlans />}

        <Phone />

        <Commentary />

        <Faq />

        <Footer />

        <DowngradeModal />
      </S.Content>
    </S.Container>
  );
};

export default Pricing;
