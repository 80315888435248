export enum EButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  DANGER = 'danger',
  SECONDARY_BUTTON = 'SECONDARY_BUTTON',
}

export enum EButtonSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum EButtonTextColor {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}
