import React from 'react';
import Skeleton from 'react-loading-skeleton';

import Logo from '@assets/img/brand/profitfy-logo.svg';

import * as S from './styles';

export const SkeletonLoading: React.FC = () => {
  return (
    <S.Container>
      <S.ContentWrapper>
        <S.Image src={Logo} alt="Profitfy.me Logo" />

        <S.HeadingWrapper>
          <Skeleton width={216} height={28} />
        </S.HeadingWrapper>

        <S.TextAndProfileWrapper>
          <Skeleton width={164} height={24} />
          <Skeleton width={68} height={24} />
        </S.TextAndProfileWrapper>

        <S.StoresWrapper>
          <S.StoreCardWrapper>
            <Skeleton width="100%" height={76} style={{ borderRadius: 8 }} />
          </S.StoreCardWrapper>

          <S.StoreCardWrapper>
            <Skeleton width="100%" height={76} style={{ borderRadius: 8 }} />
          </S.StoreCardWrapper>

          <S.StoreCardWrapper>
            <Skeleton width="100%" height={76} style={{ borderRadius: 8 }} />
          </S.StoreCardWrapper>

          <S.StoreCardWrapper>
            <Skeleton width="100%" height={76} style={{ borderRadius: 8 }} />
          </S.StoreCardWrapper>

          <S.StoreCardWrapper>
            <Skeleton width="100%" height={76} style={{ borderRadius: 8 }} />
          </S.StoreCardWrapper>
        </S.StoresWrapper>

        <S.ExitWrapper>
          <Skeleton width={26} height={24} />
        </S.ExitWrapper>
      </S.ContentWrapper>
    </S.Container>
  );
};

export default SkeletonLoading;
