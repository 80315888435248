export const WEBHOOK_STEP_TUTORIAL = {
  title: 'Clique aqui e vá para a página de webhook da hotmart',
  link: 'https://app-vlc.hotmart.com/tools/webhook/',
  steps: [
    {
      id: '1',
      text: 'Clique no botão "Cadastrar webhook.',
    },
    {
      id: '2',
      text: 'Coloque o nome do Webhook que desejar.',
    },
    {
      id: '3',
      text: 'Selecione todos os produtos.',
    },
    {
      id: '5',
      text: 'Copie a URL disponibilizada no campo "URL do webhook".',
    },
    {
      id: '5',
      text: 'Cole a URL disponibilizada no campo de "URL para envio de dados".',
    },
    {
      id: '6',
      text: 'Selecione a versão 2.',
    },
    {
      id: '7',
      text: 'Selecione todos os eventos para enviar.',
    },
  ],
};

export const FIRST_STEP_TUTORIAL = {
  title: 'Clique aqui e vá para a página de credenciais da hotmart.',
  link: 'https://app-vlc.hotmart.com/tools/credentials',
  steps: [
    {
      id: '1',
      text: 'Clique no botão "Criar credencial".',
    },
    {
      id: '2',
      text: 'Selecione a opção "API Hotmart".',
    },
    {
      id: '3',
      text: 'Preencha o nome da credencial como desejar.',
    },
    {
      id: '4',
      text: 'Após preencher, clique no botão "Criar credencial".',
    },
    {
      id: '5',
      text: 'Recarregue a página pressioando a tecla "F5" do teclado.',
    },
    {
      id: '6',
      text: 'Abra a credencial criada no passo anterior.',
    },
    {
      id: '7',
      text: 'Copie os campos Client ID, Client Secret e Basic da Hotmart.',
    },
    {
      id: '8',
      text: 'Cole nos respectivos campos da Profitfy.',
    },
  ],
};

export const SECOND_STEP_TUTORIAL = {
  title: 'Clique aqui e vá para a página de webhooks da hotmart.',
  link: 'https://app-vlc.hotmart.com/tools/webhook/auth',
  steps: [
    {
      id: '1',
      text: 'Copie o campo "Hottok de verificação".',
    },
    {
      id: '2',
      text: 'Cole no respectivo campo da Profitfy.',
    },
  ],
};
