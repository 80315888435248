import React from 'react';
import { useParams } from 'react-router-dom';

import {
  ESynchronizationRequestType,
  ESynchronizationType,
} from '@domain/enums/hooks/ESynchronization';
import { ESyncType } from '@domain/enums/dashboard/adsManager/EScriptAndUtms';
import { EScheduleEntity } from '@domain/enums/common/schedule/EScheduleEntity';
import { EScheduleAction } from '@domain/enums/common/schedule/EScheduleAction';
import { ESchedulePeriod } from '@domain/enums/common/schedule/ESchedulePeriod';
import { EScheduleItemEntity } from '@domain/enums/common/schedule/EScheduleItemEntity';
import { ICreateScheduleData } from '@domain/interfaces/common/schedule/ISchedule';
import { IUtmsProvider } from '@domain/interfaces/dashboard/AdsManager/IUtms';
import { IParams } from '@domain/interfaces/IParams';
import { IAdSenseAds } from '@domain/interfaces/dashboard/AdsManager/Onboarding/IAdSenseCampaignsWithoutUtm';

import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useToast } from '@helpers/hooks/useToast';
import { useAdsManager } from '@helpers/hooks/pages/dashboard/adsManager/useAdsManager';

import { LONG_API_DOMAIN } from '@constants/api';

import { getEnvironment } from '@helpers/utils/environment';

import scheduleService from '@services/common/schedule/schedule';

const UtmsContext = React.createContext<IUtmsProvider | null>(null);

export const UtmsProvider: React.FC = ({ children }) => {
  const { addSynchronization } = useSynchronization();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { handleUtmSidemodalOpen } = useAdsManager();

  const [syncType, setSyncType] = React.useState<ESyncType>(ESyncType.SCHEDULE);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState<boolean>(false);
  const [isLoadingSync, setIsLoadingSync] = React.useState<boolean>(false);

  const handleConfirmModalOpen = React.useCallback(
    () => setIsConfirmModalOpen(state => !state),
    [],
  );

  const handleSyncType = React.useCallback(event => {
    setSyncType(event.target.value);
  }, []);

  const syncUtms = React.useCallback(
    selectedAdSenseAds => {
      if (selectedAdSenseAds.length) {
        const mappedSelectedAdSenseAds = selectedAdSenseAds.map((adSenseAd: IAdSenseAds) => ({
          id: adSenseAd.id,
          alias_id: adSenseAd.alias_id,
          name: adSenseAd.name,
          provider: adSenseAd.provider,
          status: adSenseAd.status,
          provider_id: adSenseAd.provider_id,
        }));

        addSynchronization({
          name: 'UTMs Onboarding',
          type: ESynchronizationType.ADS_WITHOUT_UTMS,
          label: 'UTMs',
          dependencies: [],
          storeAliasId,
          config: {
            isOnboardingSynchronization: false,
            showNotification: true,
          },
          request: {
            type: ESynchronizationRequestType.POST,
            domain: LONG_API_DOMAIN,
            api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/ads-manager/utm/sync`,
            requestBody: {
              ad_sense_ads: mappedSelectedAdSenseAds,
            },
          },
        });

        handleConfirmModalOpen();
        handleUtmSidemodalOpen();
      } else {
        toast.error('Selecione pelo menos um criativo para poder continuar.');
      }
    },
    [addSynchronization, storeAliasId, toast, handleConfirmModalOpen, handleUtmSidemodalOpen],
  );

  const createSchedule = React.useCallback(
    async selectedAdSenseAds => {
      setIsLoadingSync(true);

      try {
        const mappedStoreSelectedAdSenseAds = selectedAdSenseAds.map(
          (adSenseAd: IAdSenseAds) => adSenseAd.id,
        );

        const nextMidnightDate = new Date().setHours(24, 0, 0, 0);

        const data: ICreateScheduleData = {
          schedule: {
            entity: EScheduleEntity.STORE,
            action: EScheduleAction.SYNC_UTM,
            date: new Date(nextMidnightDate).toISOString(),
            period: ESchedulePeriod.ONCE,
          },
          schedule_items: [
            {
              values: mappedStoreSelectedAdSenseAds,
              entity: EScheduleItemEntity.AD_SENSE_AD,
              metadata: {},
            },
          ],
        };

        await scheduleService.createSchedule({ storeAliasId, data });

        setIsLoadingSync(false);

        toast.success('Sincronização agendada com sucesso!');

        handleConfirmModalOpen();
        handleUtmSidemodalOpen();
      } catch (error: any) {
        setIsLoadingSync(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [storeAliasId, toast, handleConfirmModalOpen, handleUtmSidemodalOpen],
  );

  return (
    <UtmsContext.Provider
      value={{
        syncType,
        handleSyncType,
        syncUtms,
        handleConfirmModalOpen,
        isConfirmModalOpen,
        createSchedule,
        isLoadingSync,
      }}
    >
      {children}
    </UtmsContext.Provider>
  );
};

export const useUtms = (): IUtmsProvider => {
  const context = React.useContext(UtmsContext);

  if (!context) {
    throw new Error('useUtms must be used within UtmsProvider');
  }

  return context;
};
