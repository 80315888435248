import {
  IFinancialReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresNetRevenueValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresNetRevenueValues = (
  currentSelectedStoreFinancialReportData: IFinancialReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresNetRevenueValuesResponse => {
  const currentSelectedStoreNetRevenueAmountValue =
    currentSelectedStoreFinancialReportData?.net_revenue?.amount;

  const currentSelectedStoreNetRevenueGrowthValue =
    currentSelectedStoreFinancialReportData?.net_revenue?.growth;

  const currentSelectedStoreNetRevenueAmountByApprovedAtValue =
    currentSelectedStoreFinancialReportData?.net_revenue.amount_by_approved_at;

  const storesNetRevenueAmountValues = selectedStoresData.map(
    value => value.financial_report?.net_revenue.amount,
  );

  const storesNetRevenueGrowthValues = selectedStoresData.map(
    value => value.financial_report?.net_revenue?.growth,
  );

  const storesNetRevenueAmountByApprovedAtValues = selectedStoresData.map(
    value => value.financial_report?.net_revenue?.amount_by_approved_at,
  );

  const netRevenueAmountValues = [
    ...storesNetRevenueAmountValues,
    currentSelectedStoreNetRevenueAmountValue,
  ];

  const netRevenueGrowthValues = [
    ...storesNetRevenueGrowthValues,
    currentSelectedStoreNetRevenueGrowthValue,
  ];

  const netRevenueAmountByApprovedAtValues = [
    ...storesNetRevenueAmountByApprovedAtValues,
    currentSelectedStoreNetRevenueAmountByApprovedAtValue,
  ];

  const reducedNetRevenueAmountValues = netRevenueAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedNetRevenueGrowthValues = netRevenueGrowthValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedNetRevenueAmountByApprovedAtValues = netRevenueAmountByApprovedAtValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  return {
    net_revenue_amount: reducedNetRevenueAmountValues,
    net_revenue_growth: reducedNetRevenueGrowthValues,
    net_revenue_amount_by_approved_at: reducedNetRevenueAmountByApprovedAtValues,
  };
};
