import React from 'react';

import { EHeadAlign } from '@domain/enums/components/ETable';
import Paginate from '@components/common/core/Utils/Paginate';
import { ITableProps } from '@domain/interfaces/dashboard/ProductCost/IProductCost';
import EmptyData from '@components/common/core/DataDisplay/EmptyData';
import { EHeadingSize } from '@domain/enums/components/EHeading';
import SkeletonLoading from './SkeletonLoading';
import TableRow from '../TableRow';

import * as S from './styles';

const Table: React.FC<ITableProps> = ({
  products,
  page,
  pageCount,
  onPageChange,
  isLoading,
  mutate,
}) => {
  return (
    <S.ProductCostTable>
      <S.ProductCostTable.Header>
        <S.ProductCostTable.Row>
          <S.ProductCostTable.Head>Produto</S.ProductCostTable.Head>
          <S.ProductCostTable.Head>Plataforma</S.ProductCostTable.Head>
          <S.ProductCostTable.Head />
          <S.ProductCostTable.Head />
        </S.ProductCostTable.Row>
      </S.ProductCostTable.Header>

      <S.ProductCostTable.Body>
        {isLoading ? (
          <SkeletonLoading />
        ) : (
          <>
            {products.length ? (
              <>
                {products.map(product => (
                  <TableRow product={product} key={product.alias_id} mutate={mutate} />
                ))}
              </>
            ) : (
              <S.ProductCostTable.Row>
                <S.ProductCostTable.Data colSpan={4} align={EHeadAlign.CENTER}>
                  <EmptyData
                    content="Não encontramos nenhum produto."
                    contentSize={EHeadingSize.H3}
                  />
                </S.ProductCostTable.Data>
              </S.ProductCostTable.Row>
            )}
          </>
        )}
      </S.ProductCostTable.Body>

      {!isLoading && products?.length ? (
        <S.ProductCostTable.Footer>
          <S.ProductCostTable.Row>
            <S.ProductCostTable.Data colSpan={4} align={EHeadAlign.RIGHT}>
              <Paginate forcePage={page} pageCount={pageCount} onPageChange={onPageChange} />
            </S.ProductCostTable.Data>
          </S.ProductCostTable.Row>
        </S.ProductCostTable.Footer>
      ) : (
        <></>
      )}
    </S.ProductCostTable>
  );
};

export default Table;
