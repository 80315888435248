import Text from '@components/common/core/DataDisplay/Text';
import GoBack from '@components/common/core/Utils/GoBack';
import { timezones } from '@constants/timezones';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ECreateStoreStep } from '@domain/enums/onboarding/ECreateStore';
import React from 'react';
import { DeepMap, FieldError, FieldValues, UseFormRegister } from 'react-hook-form';
import * as S from './styles';

interface IStoreNameStepProps {
  goBack: (backStep: ECreateStoreStep) => void;
  register: UseFormRegister<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
}

const StoreNameStep: React.FC<IStoreNameStepProps> = ({ goBack, register, errors }) => {
  const handleGoBack = React.useCallback(() => goBack(ECreateStoreStep.SECOND_STEP), [goBack]);

  return (
    <>
      <GoBack
        text="Voltar"
        onClick={handleGoBack}
        size={16}
        type={EHeadingSize.H6}
        fontWeight={EHeadingWeight.REGULAR}
      />

      <S.Question>Informe o nome do seu negócio</S.Question>

      <S.Description>
        Digite um nome para a sua loja. Essa informação irá te ajudar a gerenciar todas as suas
        lojas.
      </S.Description>

      <S.InputsWrapper>
        <S.InputGroup>
          <S.Label>Nome do negócio</S.Label>

          <S.Input
            {...register('store_name')}
            isError={errors?.store_name}
            placeholder="Digite o nome do negócio"
            type="text"
          />
          {errors.store_name && <Text isErrorFeedback>{errors.store_name.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Timezone</S.Label>
          <S.Select {...register('timezone')}>
            {timezones.map(timezone => (
              <S.Option value={timezone} key={timezone}>
                {timezone}
              </S.Option>
            ))}
          </S.Select>
        </S.InputGroup>
      </S.InputsWrapper>
    </>
  );
};

export default StoreNameStep;
