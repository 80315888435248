import React from 'react';

import { Evermart as Icon } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { IEvermartConfigProps } from '@domain/interfaces/dashboard/IntegrationCenter/InfoProduct/IEvermart';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';

import CredentialOption from './CredentialOption';

import * as S from './styles';

const EvermartConfig: React.FC<IEvermartConfigProps> = ({ credentialAliasId }) => {
  const theme = useTheme();

  return (
    <S.ContentWrapper>
      <S.Header>
        <Icon size={52} color={theme.colors.gray[2]} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.REGULAR}>
          Integração com Evermart
        </S.Heading>
      </S.Header>

      <CredentialOption credentialAliasId={credentialAliasId} />
    </S.ContentWrapper>
  );
};

export default EvermartConfig;
