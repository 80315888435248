import styled, { keyframes } from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import Link from '@components/common/core/Navigation/Link';

const initialAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const OptionsAndQuotaWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NewText = styled.span`
  border: 1px solid ${({ theme }) => theme.colors.green.default};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  padding: 2px 8px;
  font-size: 0.75rem;
  border-radius: 15px;
  color: ${({ theme }) => theme.colors.green.default} !important;
  line-height: 120%;
`;

export const IconsWrapper = styled.div`
  animation: ${initialAnimation} 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-bottom: 56px;
`;

export const BenefitAreaLink = styled(Link)`
  animation: ${initialAnimation} 0.3s ease-in-out;
  align-self: unset;
  display: flex;
  align-items: center;
  width: 204px;
  gap: 16px;
  cursor: pointer;
  transition: all 0.2s;
  line-height: 120%;

  @media only screen and (max-height: 700px) {
    gap: 12px;
  }

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  & > svg {
    margin-top: -3px !important;
  }
`;

export const IdeaChannelLink = styled(Link)`
  animation: ${initialAnimation} 0.3s ease-in-out;
  align-self: unset;
  display: flex;
  align-items: center;
  width: 204px;
  gap: 16px;
  cursor: pointer;
  transition: all 0.2s;
  line-height: 120%;

  @media only screen and (max-height: 700px) {
    gap: 12px;
  }

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  & > svg {
    margin-top: -2px !important;
  }
`;

export const AppMobileButton = styled.button`
  background-color: unset;
  padding: unset;
  border: unset;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 204px;
  transition: all 0.2s;
  gap: 16px;
  animation: ${initialAnimation} 0.3s ease-in-out;
  line-height: 120%;

  @media only screen and (max-height: 700px) {
    gap: 12px;
  }

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const HelpCenterLink = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 204px;
  transition: all 0.2s;
  gap: 16px;
  animation: ${initialAnimation} 0.3s ease-in-out;
  line-height: 120%;

  @media only screen and (max-height: 700px) {
    gap: 12px;
  }

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  & > svg {
    margin-top: -2px !important;
  }
`;

export const Text = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const ExpandSidebarWrapper = styled.div`
  animation: ${initialAnimation} 0.3s ease-in-out;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const MoreOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 28px;
  margin-bottom: 56px;
  gap: 12px;

  @media only screen and (max-height: 700px) {
    margin-bottom: 32px;
  }
`;
