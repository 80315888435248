import styled from 'styled-components/macro';

export const Button = styled.button`
  display: block;
  background-color: unset;
  border: unset;
  display: flex;
  gap: 16px;
  align-items: center;
  color: ${({ theme }) => theme.colors.font};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1.15rem;
  cursor: pointer;
  transition: all 0.1s;
  font-weight: 300;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;
