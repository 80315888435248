import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Modal = styled(ModalBase)`
  width: 100%;
  max-width: 520px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const Title = styled(TextBase)`
  color: #ffffff;
  font-size: 2rem;
  line-height: 130%;
  margin-bottom: 16px;
`;

export const Description = styled(TextBase)`
  color: #ffffff;
  font-size: 1.125rem;
  line-height: 130%;
  margin-bottom: 32px;
  text-align: center;
`;

export const ConfirmButton = styled(ButtonBase)`
  width: 100%;
  max-width: 268px;
`;

export const CloseIcon = styled.button`
  position: absolute;
  top: 16px;
  right: 24px;
  background: unset;
  padding: unset;
  border: unset;
  cursor: pointer;
`;
