import queryString from 'query-string';
import { AxiosResponse } from 'axios';

import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';
import {
  IGetStoreProps,
  IGetStoreResponse,
  IGetStoresPromiseResponse,
  IGetStoresResponse,
  ICreateStoreProps,
  IGetStorePromiseResponse,
  IGetStoresProps,
  IGetStoresPromiseProps,
} from '@domain/interfaces/stores/IStores';

export class StoresService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getStorePromise({
    storeAliasId,
  }: IGetStoreProps): Promise<AxiosResponse<IGetStorePromiseResponse>> {
    return this.apiService.get<IGetStorePromiseResponse>(`/api/v1/users/stores/${storeAliasId}`);
  }

  public getStore({ storeAliasId }: IGetStoreProps): IGetStoreResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      store: data?.store,
      integrations: data?.integrations,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public getStores({ page, filter }: IGetStoresProps): IGetStoresResponse {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores?limit=10&page=${page + 1}&${queryStringFilter}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      stores: data?.stores,
      totalPages: data?.total_pages,
      error,
      mutate,
      isValidating,
      isLoading,
    };
  }

  public getStoresPromise({
    page,
    filter,
  }: IGetStoresPromiseProps): Promise<AxiosResponse<IGetStoresPromiseResponse>> {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    return this.apiService.get<IGetStoresPromiseResponse>(
      `/api/v1/users/stores?limit=10&page=${page + 1}&${queryStringFilter}`,
    );
  }

  public completeStoreOnboarding(storeAliasId: string | undefined, data: any): Promise<any> {
    return this.apiService.put(`/api/v1/users/stores/${storeAliasId}`, {
      ...data,
      is_onboarding_finished: true,
    });
  }

  public createStore(data: ICreateStoreProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores`, {
      ...data,
    });
  }

  public updateStore(storeAliasId: string | undefined, data: any): Promise<any> {
    return this.apiService.put(`/api/v1/users/stores/${storeAliasId}`, {
      ...data,
    });
  }

  public reactivateStore(storeAliasId: string | undefined): Promise<any> {
    return this.apiService.put(`/api/v1/users/stores/${storeAliasId}/reactivate`);
  }

  public deleteStore(storeAliasId: string | undefined): Promise<AxiosResponse<void>> {
    return this.apiService.delete(`/api/v1/users/stores/${storeAliasId}`);
  }
}

const storesService = new StoresService(API_DOMAIN);

export default storesService;
