export enum ECancellationReason {
  CLOSING_STORE = 'CLOSING_STORE',
  MISSING_INTEGRATIONS = 'MISSING_INTEGRATIONS',
  FINANCIAL_ISSUES = 'FINANCIAL_ISSUES',
  TECHNICAL_ISSUE = 'TECHNICAL_ISSUE',
  CREDIT_CARD_ISSUE = 'CREDIT_CARD_ISSUE',
  PAYMENT_PROCESSING_ERRORS = 'PAYMENT_PROCESSING_ERRORS',
  UNSATISFACTORY_SOLUTION = 'UNSATISFACTORY_SOLUTION',
  PLAN_PRICE_TOO_EXPENSIVE = 'PLAN_PRICE_TOO_EXPENSIVE',
  INSUFFICIENT_SUPPORT = 'INSUFFICIENT_SUPPORT',
  MIGRATE_TO_OTHER_PLATFORM = 'MIGRATE_TO_OTHER_PLATFORM',
  TOO_COMPLEX = 'TOO_COMPLEX',
  REPENT = 'REPENT',
  WITHOUT_ANY_REASON = 'WITHOUT_ANY_REASON',
  OTHER = 'OTHER',
}
