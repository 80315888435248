import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import LabelBase from '@components/common/core/DataDisplay/Label';
import FormBase from '@components/common/core/Inputs/Form';
import ImageBase from '@components/common/core/DataDisplay/Image';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.div`
  width: 100%;
  max-width: calc(1008px + 64px);
  display: flex;
  padding: 142px 32px;
  margin: 0 auto;
  box-sizing: border-box;
  gap: 96px;

  @media only screen and (max-width: 1292px) {
    flex-direction: column;
    max-width: calc(620px + 64px);
    gap: 28px;
  }
`;

export const Header = styled.div``;

export const Form = styled(FormBase)`
  width: auto;

  @media only screen and (max-width: 920px) {
    margin: 0 auto;
  }
`;

export const IntegrationTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  gap: 16px;
`;

export const Title = styled(HeadingBase)`
  font-size: 1.75rem;
  line-height: 120%;
  margin-bottom: 36px;
`;

export const FormWrapper = styled.div`
  width: 100%;
`;

export const IntegrationTitleText = styled(HeadingBase)``;

export const Body = styled.div``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Input = styled(TextFieldBase)`
  width: 100%;
  max-width: 408px;

  &:first-child {
    & > div {
      border-radius: 5px 0 0 5px;
    }
  }
`;

export const Label = styled(LabelBase)``;

export const TutorialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 416px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray[4]};
  border-radius: 7px;
  padding: 28px 32px 12px 32px;
  box-sizing: border-box;
  height: fit-content;
`;

export const TutorialImage = styled(ImageBase)`
  max-width: 140px;
  margin-bottom: 24px;
  display: block;
`;

export const TutotialTitle = styled(TextBase)`
  margin-bottom: 12px;
`;

export const TutotialStep = styled(TextBase)`
  margin-left: 8px;
  margin-bottom: 16px;
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.green.default};
  cursor: pointer;
`;

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: -8px 0 16px 32px;
  gap: 8px;
`;

export const StepWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const StepBullet = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[1]};
`;

export const StepText = styled(TextBase)``;

export const WebhookURLWrapper = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background[2]};
  border-radius: 5px;
  height: 42px;
  width: 100%;
  max-width: 408px;
  padding: 10px 12px;
  gap: 10px;
  border: unset;
  cursor: pointer;
`;

export const WebhookURL = styled(TextBase)`
  max-width: 434px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const IconWrapper = styled.div`
  min-height: 18px;
  min-width: 18px;
`;
