import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { LONG_API_DOMAIN } from '@constants/api';
import {
  ICreateOnboardingStepProps,
  IDeleteOnboardingStepProps,
  IGetOnboardingStepProps,
  IGetOnboardingStepResponse,
  IUpdateOnboardingStepProps,
} from '@domain/interfaces/onboarding/IOnboarding';

export class OnboardingStepService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getOnboardingSteps({ storeAliasId }: IGetOnboardingStepProps): IGetOnboardingStepResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/onboarding-steps`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      onboardingSteps: data?.onboarding_steps,
      error,
      mutate,
      isValidating,
      isLoading,
    };
  }

  public listOnboardingStepsPromise({ storeAliasId }: IGetOnboardingStepProps): Promise<any> {
    return this.apiService.get(`/api/v1/users/stores/${storeAliasId}/onboarding-steps`);
  }

  public createStep({ storeAliasId, data }: ICreateOnboardingStepProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/onboarding-steps`, {
      onboarding_steps: [...data],
    });
  }

  public updateStep({
    storeAliasId,
    onboardingStepId,
    data,
  }: IUpdateOnboardingStepProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/onboarding-steps/${onboardingStepId}`,
      { ...data },
    );
  }

  public deleteStep({ storeAliasId, onboardingStepId }: IDeleteOnboardingStepProps): Promise<any> {
    return this.apiService.delete(
      `/api/v1/users/stores/${storeAliasId}/onboarding-steps/${onboardingStepId}`,
    );
  }
}

const onboardingStepService = new OnboardingStepService(LONG_API_DOMAIN);

export default onboardingStepService;
