import React from 'react';
import { useParams } from 'react-router-dom';

import { ETableType } from '@domain/enums/dashboard/financialDetails/EFinancialDetails';
import { EStatus } from '@domain/enums/subscription/ESubscription';
import {
  IColumnProps,
  IComparePeriod,
  IFinancialDetailsProvider,
  IFinancialPeriod,
} from '@domain/interfaces/dashboard/FinancialDetails/IFinancialDetails';
import { ISelectedFilter } from '@domain/interfaces/components/IFilter';
import { IPeriod } from '@domain/interfaces/components/IDatePicker';
import { IParams } from '@domain/interfaces/IParams';

import { useDate } from '@helpers/hooks/useDate';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { useToast } from '@helpers/hooks/useToast';

import {
  getPeriodTableDatesNames,
  getPeriodTableDates,
} from '@helpers/utils/dashboard/financialDetails/financialDetails';
import { parseDataFilter } from '@helpers/utils/components/filter';
import {
  parseCompareProductFinancialData,
  parseProductFinancialData,
} from '@helpers/utils/dashboard/financialDetails/parseProductFinancial';

import financialDetailsService from '@services/pages/dashboard/financialDetails/financialDetails';
import financialDashboardService from '@services/pages/dashboard/financialDashboard/financialDashboard';
import productFinancialTableByPeriodService from '@services/common/product/financialTableByPeriod';

const FinancialDetailsContext = React.createContext<IFinancialDetailsProvider | null>(null);

export const FinancialDetailsProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();
  const { utcToZonedTime, format, addDays, differenceInDays } = useDate();
  const { subscription, hasAllPermissions } = useStoreSubscription();
  const { toast } = useToast();

  const [
    isNoExportSpreadSheetPermissionModalOpen,
    setIsNoExportSpreadSheetPermissionModalOpen,
  ] = React.useState<boolean>(false);
  const [isExportSpreadSheetOpen, setIsExportSpreadSheetOpen] = React.useState<boolean>(false);
  const [tableType, setTableType] = React.useState<ETableType>(ETableType.PERIOD);
  const [filterData, setFilterData] = React.useState<Array<ISelectedFilter>>([]);
  const [
    isLoadingFinancialTableByProduct,
    setIsLoadingFinancialTableByProduct,
  ] = React.useState<boolean>(false);
  const [
    isFinancialTableByProductError,
    setIsFinancialTableByProductError,
  ] = React.useState<boolean>(false);
  const [financialTablePeriodData, setFinancialTablePeriodData] = React.useState<
    IFinancialPeriod | undefined
  >(undefined);
  const [financialTablePeriodComparedData, setFinancialTablePeriodComparedData] = React.useState<
    IComparePeriod | undefined
  >(undefined);
  const [columnsConfig, setColumnsConfig] = React.useState<Array<IColumnProps>>([
    {
      label: 'Receita Bruta',
      value: 'gross_revenue',
      is_active: true,
    },
    {
      label: 'Pedidos Pendentes',
      value: 'pending_orders',
      is_active: true,
    },
    {
      label: 'Pedidos em Análise',
      value: 'in_analysis_orders',
      is_active: true,
    },
    {
      label: 'Pedidos não Conciliados',
      value: 'no_information_orders',
      is_active: true,
    },
    {
      label: 'Descontos',
      value: 'discount_revenue',
      is_active: true,
    },
    {
      label: 'Chargebacks',
      value: 'charged_back_revenue',
      is_active: true,
    },
    {
      label: 'Devoluções',
      value: 'refund_revenue',
      is_active: true,
    },
    {
      label: 'Receita Líquida',
      value: 'net_revenue',
      is_active: true,
    },
    {
      label: 'Custo dos Produtos',
      value: 'cogs',
      is_active: true,
    },
    {
      label: 'Marketing',
      value: 'marketing',
      is_active: true,
    },
    {
      label: 'Taxas de Checkout',
      value: 'sales_fee',
      is_active: true,
    },
    {
      label: 'Taxas de Gateway',
      value: 'gateway_fee',
      is_active: true,
    },
    {
      label: 'Despesas de envio',
      value: 'shipping_cost_owner',
      is_active: true,
    },
    {
      label: 'Impostos',
      value: 'tax',
      is_active: true,
    },
    {
      label: 'Outras Despesas',
      value: 'other_spends',
      is_active: true,
    },
    {
      label: 'Receitas Financeiras',
      value: 'financial_income',
      is_active: true,
    },
    {
      label: 'Lucro Líquido',
      value: 'net_profit',
      is_active: true,
    },
  ]);

  const maxDateLimiter = React.useCallback(() => {
    const isFreePlan = subscription && subscription.plan?.identifier.includes('free');

    if (isFreePlan && subscription.exceeded_order_limit_at && !hasAllPermissions) {
      return utcToZonedTime(new Date(subscription.exceeded_order_limit_at));
    }

    const isSubscriptionPeriodEndedAtAfterNow = subscription?.period_ended_at
      ? differenceInDays(utcToZonedTime(new Date()), new Date(subscription.period_ended_at)) >= 0
      : true;

    if (subscription?.status === EStatus.CANCELED && isSubscriptionPeriodEndedAtAfterNow) {
      return utcToZonedTime(new Date());
    }

    if (
      subscription?.status === EStatus.CANCELED &&
      subscription?.period_ended_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(addDays(new Date(subscription.period_ended_at), 1));
    }

    if (
      subscription?.status === EStatus.CANCELED &&
      subscription?.canceled_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(subscription.canceled_at);
    }

    if (subscription && subscription.status === EStatus.TRIAL_ENDED && !hasAllPermissions) {
      return utcToZonedTime(new Date(subscription.period_ended_at));
    }

    if (
      subscription &&
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_CREATE &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(new Date(subscription.period_ended_at));
    }

    if (
      subscription &&
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_CREATE_FAILED &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(new Date(subscription.period_ended_at));
    }

    if (
      subscription &&
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_UPDATE &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(new Date(subscription.period_ended_at));
    }

    return utcToZonedTime(new Date());
  }, [hasAllPermissions, subscription, utcToZonedTime, addDays, differenceInDays]);

  const minDateLimiter = React.useCallback(() => {
    if (subscription && subscription.status === EStatus.TRIAL_ENDED && !hasAllPermissions) {
      return utcToZonedTime(new Date(subscription.period_ended_at));
    }

    if (
      subscription &&
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_CREATE &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(new Date(subscription.period_started_at));
    }

    if (
      subscription &&
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_CREATE_FAILED &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(new Date(subscription.period_started_at));
    }

    if (
      subscription &&
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_UPDATE &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(new Date(subscription.period_started_at));
    }

    return undefined;
  }, [hasAllPermissions, subscription, utcToZonedTime]);

  const initialDate = maxDateLimiter();

  const [period, setPeriod] = React.useState<IPeriod>({
    startDate: utcToZonedTime(initialDate),
    endDate: utcToZonedTime(initialDate),
  });

  const [comparedStartDate, comparedEndDate] = getPeriodTableDates(
    period.startDate,
    period.endDate,
  );

  const {
    period: financialTablePeriod,
    comparePeriod: financialTableComparePeriod,
    isLoading: isLoadingFinancialTableByPeriod,
    isValidating: isValidatingFinancialTableByPeriod,
    mutate: mutateFinancialTableByPeriod,
    error: financialTableByPeriodError,
  } = financialDetailsService.getFinancialTableByPeriod({
    storeAliasId,
    startDate: format(period.startDate, 'yyyy-MM-dd'),
    endDate: format(period.endDate, 'yyyy-MM-dd'),
    filter: parseDataFilter(filterData),
  });
  const {
    financialTableByDay,
    isLoading: isLoadingFinancialTableByDay,
    isValidating: isValidatingFinancialTableByDay,
    mutate: mutateFinancialTableByDay,
    error: financialTableByDayError,
  } = financialDetailsService.getFinancialTableByDay({
    storeAliasId,
    startDate: format(period.startDate, 'yyyy-MM-dd'),
    endDate: format(period.endDate, 'yyyy-MM-dd'),
    filter: parseDataFilter(filterData),
  });
  const {
    customValues,
    isLoading: isLoadingCustomValues,
    isValidating: isValidatingCustomValues,
    mutate: mutateCustomValues,
    error: customValuesError,
  } = financialDashboardService.getFinancialCustomValues({
    storeAliasId,
    startDate: format(period.startDate, 'yyyy-MM-dd'),
    endDate: format(period.endDate, 'yyyy-MM-dd'),
  });
  const {
    customValues: comparedCustomValues,
    isLoading: isLoadingComparedCustomValues,
    isValidating: isValidatingComparedCustomValues,
    mutate: mutateComparedCustomValues,
    error: comparedCustomCustomValuesError,
  } = financialDashboardService.getFinancialCustomValues({
    storeAliasId,
    startDate: format(comparedStartDate, 'yyyy-MM-dd'),
    endDate: format(comparedEndDate, 'yyyy-MM-dd'),
  });

  const mutate = React.useCallback(() => {
    const promises = [];

    if (financialTableByPeriodError) promises.push(mutateFinancialTableByPeriod());

    if (financialTableByDayError) promises.push(mutateFinancialTableByDay());

    if (customValuesError) promises.push(mutateCustomValues());

    if (comparedCustomCustomValuesError) promises.push(mutateComparedCustomValues());

    Promise.all(promises);
  }, [
    financialTableByPeriodError,
    mutateFinancialTableByPeriod,
    financialTableByDayError,
    mutateFinancialTableByDay,
    customValuesError,
    mutateCustomValues,
    comparedCustomCustomValuesError,
    mutateComparedCustomValues,
  ]);

  const handleNoExportSpreadSheetPermissionModalOpen = React.useCallback(() => {
    setIsNoExportSpreadSheetPermissionModalOpen(!isNoExportSpreadSheetPermissionModalOpen);
  }, [isNoExportSpreadSheetPermissionModalOpen]);

  const handleExportSpreadSheetOpen = React.useCallback(() => {
    setIsExportSpreadSheetOpen(!isExportSpreadSheetOpen);
  }, [isExportSpreadSheetOpen]);

  const handleColumnsConfig = React.useCallback(newColumns => setColumnsConfig(newColumns), []);

  const handleFilterData = React.useCallback(newFilterData => setFilterData(newFilterData), []);

  const handleTableType = React.useCallback(type => setTableType(type), []);

  const loadFinancialTablePeriodByProduct = React.useCallback(
    async (productAliasId: string | undefined) => {
      setIsLoadingFinancialTableByProduct(true);
      setIsFinancialTableByProductError(false);

      try {
        const {
          data,
        } = await productFinancialTableByPeriodService.getFinancialTableByPeriodPromise({
          storeAliasId,
          productAliasId,
          startDate: format(period.startDate, 'yyyy-MM-dd'),
          endDate: format(period.endDate, 'yyyy-MM-dd'),
          filter: parseDataFilter(filterData),
        });

        const newFinancialTablePeriodData = parseProductFinancialData(data.period);
        const newCompareFinancialTablePeriodData = parseCompareProductFinancialData(
          data.compare_period,
        );

        setFinancialTablePeriodData(newFinancialTablePeriodData);
        setFinancialTablePeriodComparedData(newCompareFinancialTablePeriodData);

        setIsLoadingFinancialTableByProduct(false);
      } catch (error: any) {
        setIsFinancialTableByProductError(true);
        setIsLoadingFinancialTableByProduct(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [toast, format, period.startDate, period.endDate, storeAliasId, filterData],
  );

  React.useEffect(() => {
    const foundFilterData = filterData.find(filter => filter.field === 'product_id');

    if (foundFilterData) {
      loadFinancialTablePeriodByProduct(foundFilterData.aliasIdHelper);
    }
  }, [filterData, loadFinancialTablePeriodByProduct]);

  React.useEffect(() => {
    const foundFilterData = filterData.find(filter => filter.field === 'product_id');

    if (!foundFilterData) {
      setFinancialTablePeriodData(financialTablePeriod);
      setFinancialTablePeriodComparedData(financialTableComparePeriod);
    }
  }, [filterData, financialTablePeriod, financialTableComparePeriod]);

  React.useEffect(() => {
    const foundFilterData = filterData.find(filter => filter.field === 'product_id');

    if (foundFilterData && tableType === ETableType.DAILY) {
      setFilterData([]);
    }
  }, [filterData, tableType]);

  const periodTableDatesNames = getPeriodTableDatesNames(
    period.startDate,
    period.endDate,
    utcToZonedTime(new Date()),
  );

  const isLoadingFinancialDetails =
    isLoadingFinancialTableByDay ||
    isLoadingFinancialTableByPeriod ||
    isLoadingCustomValues ||
    isLoadingComparedCustomValues ||
    isLoadingFinancialTableByProduct;
  const isValidatingFinancialDetails =
    isValidatingFinancialTableByDay ||
    isValidatingFinancialTableByPeriod ||
    isValidatingCustomValues ||
    isValidatingComparedCustomValues ||
    isLoadingFinancialTableByProduct;
  const isFinancialDetailsError =
    Boolean(financialTableByDayError) ||
    Boolean(financialTableByPeriodError) ||
    Boolean(customValuesError) ||
    Boolean(comparedCustomCustomValuesError) ||
    isFinancialTableByProductError;

  return (
    <FinancialDetailsContext.Provider
      value={{
        tableType,
        handleTableType,
        filterData,
        handleFilterData,
        period,
        setPeriod,
        columnsConfig,
        handleColumnsConfig,
        periodTableDatesNames,
        financialTableByDay,
        financialTableComparePeriod: financialTablePeriodComparedData,
        financialTablePeriod: financialTablePeriodData,
        isFinancialDetailsError,
        isLoadingFinancialDetails,
        isValidatingFinancialDetails,
        mutate,
        comparedCustomValues,
        customValues,
        mutateComparedCustomValues,
        mutateCustomValues,
        mutateFinancialTableByDay,
        mutateFinancialTableByPeriod,
        handleExportSpreadSheetOpen,
        isExportSpreadSheetOpen,
        handleNoExportSpreadSheetPermissionModalOpen,
        isNoExportSpreadSheetPermissionModalOpen,
        maxDateLimiter,
        minDateLimiter,
      }}
    >
      {children}
    </FinancialDetailsContext.Provider>
  );
};

export const useFinancialDetails = (): IFinancialDetailsProvider => {
  const context = React.useContext(FinancialDetailsContext);

  if (!context) {
    throw new Error('useFinancialDetails must be used within FinancialDetailsProvider');
  }

  return context;
};
