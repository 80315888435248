import React from 'react';
import { Route as ReactDOMRoute, Redirect } from 'react-router-dom';

import { IRouteProps } from '@domain/interfaces/routes/IRoute';
import PrivateRoute from '@components/routes/PrivateRoute';
import { useConfig } from '@helpers/hooks/useConfig';

const Route: React.FC<IRouteProps> = ({
  isPrivate = false,
  component: Component,
  isPublic = false,
  ...rest
}) => {
  const { authorization, user, handleLogout } = useConfig();

  if (isPrivate && (!authorization || !user)) {
    handleLogout();
  }

  if (isPublic) {
    return <ReactDOMRoute {...rest} render={() => <Component {...rest} />} />;
  }

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === Boolean(authorization && user) ? (
          <PrivateRoute component={Component} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : '/stores',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default React.memo(Route);
