import ImageBase from '@components/common/core/DataDisplay/Image';
import TextBase from '@components/common/core/DataDisplay/Text';
import styled, { css } from 'styled-components/macro';

import ButtonBase from '@components/common/core/Inputs/Button';
import { EUserSegmentationStep } from '@domain/enums/onboarding/EUsersSegmentation';

interface IProgressBarProps {
  step?: EUserSegmentationStep;
}

export const SubmitButton = styled(ButtonBase)`
  width: 100%;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[7]};
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;

  &::after {
    content: '';
    position: absolute;
    bottom: 144px;
    right: 124px;
    width: 161px;
    height: 22px;
    background-repeat: no-repeat;

    @media only screen and (max-width: 1225px) {
      display: none;
    }

    @media only screen and (max-height: 700px) {
      display: none;
    }
  }
`;

export const Wrapper = styled.div`
  max-width: 380px;
  width: 100%;
  margin: 0 auto;
  padding: 64px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media only screen and (max-width: 970px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media only screen and (max-height: 560px) {
    padding-top: 48px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const LogoWrapper = styled.div`
  justify-content: flex-start;
  padding: 24px 32px;
  max-width: 140px;
  width: 100%;
`;

export const Logo = styled(ImageBase)``;

export const LogoutWrapper = styled.div`
  justify-content: flex-end;
  padding: 24px 32px;
  max-width: 140px;
  width: 100%;
`;

export const Button = styled.button`
  background-color: unset;
  border: 1px solid transparent;
  padding: 4px 12px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  gap: 8px;
  transition: all 0.3s;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.colors.green.default};
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const IconWrapper = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled(TextBase)`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.green.default};
`;

export const ProgressBar = styled.div<IProgressBarProps>`
  width: 100%;
  height: 8px;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.green.default};

  ${({ step }) =>
    step === EUserSegmentationStep.FIRST_STEP &&
    css`
      width: 35%;
    `}

  ${({ step }) =>
    step === EUserSegmentationStep.SECOND_STEP &&
    css`
      width: 65%;
    `}
`;
