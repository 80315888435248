import React from 'react';
import { useLocation } from 'react-router-dom';

import Route from '@components/routes/Route';
import OnboardingWrapper from '@pages/Onboarding';
import CreateStore from '@pages/Onboarding/CreateStore';
import Finished from '@pages/Onboarding/Finished';
import NotFinished from '@pages/Onboarding/NotFinished';
import UserSegmentation from '@pages/Onboarding/UserSegmentation';
import Welcome from '@pages/Onboarding/Welcome';
import Dropshipping from './Dropshipping';
import InfoProduct from './InfoProduct';
import Ecommerce from './TraditionalEcommerce';

import * as S from './styles';

const Onboarding: React.FC = () => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location]);

  return (
    <S.Wrapper>
      <OnboardingWrapper>
        <Route
          isPrivate
          exact
          path="/onboarding/:storeAliasId/not-finished"
          component={NotFinished}
        />
        <Route isPrivate exact path="/onboarding/welcome" component={Welcome} />
        <Route isPrivate exact path="/onboarding/segmentation" component={UserSegmentation} />
        <Route isPrivate exact path="/onboarding/store" component={CreateStore} />

        <Route isPrivate exact path="/onboarding/:storeAliasId/finished" component={Finished} />

        <Dropshipping />

        <InfoProduct />

        <Ecommerce />
      </OnboardingWrapper>
    </S.Wrapper>
  );
};

export default React.memo(Onboarding);
