import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <Skeleton width="100%" height={24} />
      </S.HeaderWrapper>

      <S.ZipCodeAndCityAndStateWrapper>
        <S.CepInputWrapper>
          <S.LabelWrapper>
            <Skeleton width={42} height={19} />
          </S.LabelWrapper>
          <Skeleton width="100%" height={42} />
        </S.CepInputWrapper>

        <S.CityInputWrapper>
          <S.LabelWrapper>
            <Skeleton width={52} height={19} />
          </S.LabelWrapper>
          <Skeleton width="100%" height={42} />
        </S.CityInputWrapper>

        <S.StateInputWrapper>
          <S.LabelWrapper>
            <Skeleton width={60} height={19} />
          </S.LabelWrapper>
          <Skeleton width="100%" height={42} />
        </S.StateInputWrapper>
      </S.ZipCodeAndCityAndStateWrapper>

      <S.StreetAndNumber>
        <S.StreetInputWrapper>
          <S.LabelWrapper>
            <Skeleton width={92} height={19} />
          </S.LabelWrapper>
          <Skeleton width="100%" height={42} />
        </S.StreetInputWrapper>

        <S.NumberInputWrapper>
          <S.LabelWrapper>
            <Skeleton width={64} height={19} />
          </S.LabelWrapper>
          <Skeleton width="100%" height={42} />
        </S.NumberInputWrapper>
      </S.StreetAndNumber>

      <S.NeighborhoodAndComplement>
        <S.NeighborhoodInputWrapper>
          <S.LabelWrapper>
            <Skeleton width={48} height={19} />
          </S.LabelWrapper>
          <Skeleton width="100%" height={42} />
        </S.NeighborhoodInputWrapper>

        <S.ComplementInputWrapper>
          <S.LabelWrapper>
            <Skeleton width={96} height={19} />
          </S.LabelWrapper>
          <Skeleton width="100%" height={42} />
        </S.ComplementInputWrapper>
      </S.NeighborhoodAndComplement>
    </S.Wrapper>
  );
};

export default SkeletonLoading;
