import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  max-width: 742px;
  width: 100%;

  & > div {
    margin-bottom: 16px;
  }
`;
