import styled from 'styled-components/macro';

import TextBase from '../../DataDisplay/Text';
import ButtonBase from '../../Inputs/Button';

export const Modal = styled.div`
  display: flex;
  max-width: 1336px;
  margin: 0 22px;
  width: calc(100% - 32px);
  padding: 22px 24px;

  position: fixed;
  bottom: 32px;
  left: 50%;
  transform: translateX(calc(-50% - 22px));
  z-index: 9999;

  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  gap: 12px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
  }
`;

export const Text = styled(TextBase)`
  max-width: 1072px;
`;

export const AcepptCookiesButton = styled(ButtonBase)`
  padding: 10px 12px;
`;

export const AcepptCookiesButtonText = styled(TextBase)``;
