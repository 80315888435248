import styled from 'styled-components/macro';

import CustomHoverCard from '@components/common/core/Utils/CustomHoverCard';
import TextBase from '@components/common/core/DataDisplay/Text';

export const SyncButton = styled.button`
  padding: unset;
  border: unset;
  background: unset;
  color: ${({ theme }) => theme.colors.green.default};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 0.875rem;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const Text = styled(TextBase)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.font};
`;

export const ContentWrapper = styled.div`
  max-width: 308px;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
`;

export const CustomHoverContent = styled(CustomHoverCard.Content)`
  background-color: ${({ theme }) => theme.colors.black.light};
`;

export const CustomHover = styled(CustomHoverCard)``;

export const InsightWrapper = styled.div`
  width: 24px;
  min-width: 24px;
  height: 26px;
  min-height: 26px;
  background-color: #24363f;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
