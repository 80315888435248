import { ECurrency } from '@domain/enums/common/ECurrency';
import { EDropshippingProvider } from '@domain/enums/common/integrations/EDropshippingProvider';
import { EOrderStatus, EOrderType } from '@domain/enums/common/EOrder';
import { IPeriod } from '@domain/interfaces/components/IDatePicker';
import { IFilterQueryStringData, ISelectedFilter } from '@domain/interfaces/components/IFilter';
import { ISelectedField } from '@domain/interfaces/components/ISort';
import { IProduct } from '@domain/interfaces/common/product/IProduct';
import { EModalStep } from '@domain/enums/common/EStepsModal';

export enum EUtmSource {
  FACEBOOK = 'FACEBOOK',
  TABOOLA = 'TABOOLA',
  GOOGLE = 'GOOGLE',
  TIKTOK = 'TIKTOK',
  OTHER = 'OTHER',
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  ORGANIC = 'ORGANIC',
  ABANDONED_CART = 'ABANDONED_CART',
}

interface ICreateManualOrderProps {
  order: {
    order_name: string;
    status: EOrderStatus;
    type: EOrderType;
    gateway_amount: number;
    cogs_amount: number;
    gateway_fee_amount: number;
    shipping_cost_owner_amount: number;
    info_product_fee_amount: number;
    approved_at: Date;
  };
  customer: {
    name: string;
    email: string;
    phone_number: string;
  };
  products: {
    id: string;
    product?: IProduct;
    quantity: number;
  }[];
}

export interface ICreateManualOrder {
  storeAliasId?: string;
  data: ICreateManualOrderProps;
}

export interface ILineItem {
  alias_id: string;
  approved_at: string | null;
  checkout_fee_amount: number;
  cogs_amount: number;
  created_at: string;
  currency: ECurrency;
  discount_amount: number;
  gateway_amount: number;
  gateway_fee_amount: number;
  id: string;
  info_product_fee_amount: number;
  installments: number;
  interest_amount: number;
  iof_amount: number;
  marketplace: string;
  marketplace_fee_amount: number;
  order_id: string;
  percentage_of_total_price: number;
  product?: IProduct;
  product_id: string;
  provider: EDropshippingProvider;
  provider_amount: number;
  provider_created_at: string;
  provider_id: string;
  quantity: number;
  refunded_amount: number;
  refunded_at: string | null;
  shipping_cost_customer_amount: number;
  shipping_cost_owner_amount: number;
  status: EOrderStatus;
  tax_amount: number;
  total_amount: number;
  type: EOrderType;
  updated_at: string;
  variant_id: string;
}

export interface IOrderSynchronization {
  id: string;
  alias_id: string;
  order_id: string;
  provider: string;
  amount: number;
  currency: string;
  currency_date: string;
  currency_amount: number;
  order_field: string;
  created_at: string;
  updated_at: string;
}

export interface IOrderTransaction {
  alias_id: string;
  created_at: string;
  external_reference?: string;
  gateway_name: string;
  id: string;
  order_id: string;
  payment_token: string;
  provider: EDropshippingProvider;
  provider_created_at: string;
  updated_at: string;
}

export interface IOrder {
  alias_id: string;
  approved_at?: string;
  checkout_fee_amount: number;
  cogs_amount: number;
  created_at: string;
  currency: ECurrency;
  customer_id: string;
  discount_amount: number;
  info_product_fee_amount: number;
  gateway_amount: number;
  gateway_fee_amount: number;
  id: string;
  installments: number;
  interest_amount: number;
  iof_amount: number;
  is_resolved_by_feed: boolean;
  is_updated_manually: boolean;
  marketplace: string;
  marketplace_fee_amount: number;
  name: string;
  net_profit: number;
  order_synchronizations: Array<IOrderSynchronization>;
  order_transactions: Array<IOrderTransaction>;
  line_items: Array<ILineItem>;
  provider: EDropshippingProvider;
  provider_amount: number;
  provider_created_at: string;
  provider_detail_url: string;
  provider_id: string;
  refunded_amount: number;
  refunded_at?: string;
  shipping_cost_customer_amount: number;
  shipping_cost_owner_amount: number;
  status: EOrderStatus;
  store_id: string;
  tax_amount: number;
  type: EOrderType;
  updated_at: string;
  time_to_approve_in_days?: number;
  utm_source: EUtmSource;
}

export interface IOrdersMetric {
  total_net_revenue_amount: number;
  total_orders_quantity: number;
}

export interface IListOrdersWithLineItemProps {
  page: number;
  startDate: Date;
  endDate: Date;
  storeAliasId?: string;
  filter?: IFilterQueryStringData;
  sort?: ISelectedField;
}

export interface IListOrdersWithLineItemPromiseProps {
  storeAliasId?: string;
  page: number;
  startDate: string;
  endDate: string;
}

export interface IListOrdersWithLineItemResponse {
  orders: Array<IOrder>;
  metric: IOrdersMetric;
  mutate: any;
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  totalPages: number;
}

export interface IUpdateOrder {
  orderAliasId: string;
  storeAliasId?: string;
  order: IOrder;
}

export interface INewProductForOrder {
  product?: IProduct;
  quantity: number;
  id: string;
}

export interface IProductForOrder {
  id: string;
  product?: IProduct;
}

export interface IOrdersProvider {
  period: IPeriod;
  setPeriod: React.Dispatch<React.SetStateAction<IPeriod>>;
  ordersPage: number;
  ordersPageCount: number;
  filterData: Array<ISelectedFilter>;
  sortData?: ISelectedField;
  orders: Array<IOrder>;
  metric: IOrdersMetric;
  mutate: any;
  handleFilterData: (newFilterData: Array<ISelectedFilter>) => void;
  handleSortData: (newSortData?: ISelectedField) => void;
  handlePageChange: (pageSelected: { selected: number }) => void;
  isLoadingOrdersProvider: boolean;
  isValidatingOrdersProvider: boolean;
  isOrdersProviderError: boolean;
  mutateOrders: any;
  createOrderStep: EModalStep;
  handleCreateOrderStep: (step: EModalStep) => void;
  isCreateOrderModalOpen: boolean;
  toggleCreateOrderModal: () => void;
  newProductsForOrder: Array<INewProductForOrder>;
  handleNewProductsForOrder: (products: Array<INewProductForOrder>) => void;
  addProductForOrder: (product: INewProductForOrder) => void;
  index: number;
  handleIndex: (index: number) => void;
  isCreatingOrder: boolean;
}
