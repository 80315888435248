import React from 'react';

import { EHeadAlign } from '@domain/enums/components/ETable';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

export const TableSkeletonLoading: React.FC = () => {
  const randomArray = Array.from(Array(10).keys());

  return (
    <S.Table>
      <S.Table.Header>
        <S.Table.Row>
          <S.Table.Head>
            <S.Checkbox checked={false} />
          </S.Table.Head>
          <S.Table.Head>Nome da Campanha</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Clicks</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Impressões</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Conversões</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Todas conversões</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Custos</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Status no Provedor</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Status</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER} />
        </S.Table.Row>
      </S.Table.Header>

      <S.Table.Body>
        {randomArray.map(value => (
          <S.Table.Row borderBottom key={value}>
            <S.Table.Data align={EHeadAlign.CENTER}>
              <Skeleton width={20} height={20} style={{ borderRadius: 5 }} />
            </S.Table.Data>

            <S.Table.Data align={EHeadAlign.CENTER}>
              <Skeleton width={100} height={25} />
            </S.Table.Data>

            <S.Table.Data align={EHeadAlign.CENTER}>
              <Skeleton width={100} height={25} />
            </S.Table.Data>

            <S.Table.Data align={EHeadAlign.CENTER}>
              <Skeleton width={100} height={25} />
            </S.Table.Data>

            <S.Table.Data align={EHeadAlign.CENTER}>
              <Skeleton width={100} height={25} />
            </S.Table.Data>

            <S.Table.Data align={EHeadAlign.CENTER}>
              <Skeleton width={100} height={25} />
            </S.Table.Data>

            <S.Table.Data align={EHeadAlign.CENTER}>
              <Skeleton width={100} height={25} />
            </S.Table.Data>

            <S.Table.Data align={EHeadAlign.CENTER}>
              <Skeleton width={100} height={25} />
            </S.Table.Data>

            <S.Table.Data align={EHeadAlign.CENTER}>
              <Skeleton width={50} height={25} />
            </S.Table.Data>

            <S.Table.Data align={EHeadAlign.CENTER} />
          </S.Table.Row>
        ))}
      </S.Table.Body>
    </S.Table>
  );
};

export const PageSkeletonLoading: React.FC = () => {
  return (
    <S.Container>
      <S.Header>
        <Skeleton width="100%" height={176} style={{ lineHeight: 'unset' }} />
      </S.Header>

      <TableSkeletonLoading />
    </S.Container>
  );
};
