import React from 'react';
import { useParams } from 'react-router-dom';

import { ECampaignStatus } from '@domain/enums/dashboard/adsManager/ECampaigns';
import {
  ESynchronizationRequestType,
  ESynchronizationType,
} from '@domain/enums/hooks/ESynchronization';
import { IPeriod } from '@domain/interfaces/components/IDatePicker';
import { IParams } from '@domain/interfaces/IParams';
import { ISelectedFilter } from '@domain/interfaces/components/IFilter';
import {
  IAdsManagerProps,
  ITableColumn,
} from '@domain/interfaces/dashboard/AdsManager/IAdsManager';

import { useLocalStorage } from '@helpers/hooks/useLocalStorage';
import { useStorePermissions } from '@helpers/hooks/common/useStorePermissions';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useDate } from '@helpers/hooks/useDate';

import { getSynchronizationStatus } from '@helpers/utils/common/synchronization';

import { mapCampaignsData } from '@helpers/utils/dashboard/adsManager/campaigns';
import {
  useDefaultTableColumns,
  DEFAULT_COLUMNS_SIZE,
  columnsWithRef,
  getInitialColumns,
} from '@constants/pages/dashboard/adsManager';

import { getEnvironment } from '@helpers/utils/environment';

import { LONG_API_DOMAIN } from '@constants/api';

const AdsManagerContext = React.createContext<IAdsManagerProps | null>(null);

const ADS_MANAGER_SYNC_NAME = 'Gerenciador de Anúncios';

export const AdsManagerProvider: React.FC = ({ children }) => {
  const DEFAULT_TABLE_COLUMNS = useDefaultTableColumns();
  const { maxDateLimiter } = useStorePermissions();
  const { storeAliasId } = useParams<IParams>();
  const { utcToZonedTime, format } = useDate();
  const {
    addSynchronization,
    currentSynchronizations,
    synchronizationsQueue,
  } = useSynchronization();

  const [adsManagerColumns, setAdsManagerColumns] = useLocalStorage(
    `@profitfy:${getEnvironment()}/ads-manager-columns`,
    DEFAULT_TABLE_COLUMNS,
  );
  const [columnsSize, setColumnsSize] = useLocalStorage(
    `@profitfy:${getEnvironment()}/ads-manager-columns-size`,
    DEFAULT_COLUMNS_SIZE,
  );
  const [isFirstTooltipOpen, setIsFirstTooltipOpen] = useLocalStorage(
    `@profitfy:${getEnvironment()}/ads-manager-first-time`,
    true,
  );

  const initialDate = maxDateLimiter();
  const initialColumns = getInitialColumns(adsManagerColumns, DEFAULT_TABLE_COLUMNS);

  const adsManagerColumnsWithRef = columnsWithRef(initialColumns);

  const [tableColumns, setTableColumns] = React.useState<Array<ITableColumn>>(
    adsManagerColumnsWithRef,
  );
  const [campaignsStatusFilter, setCampaignsStatusFilter] = React.useState<Array<ECampaignStatus>>([
    ECampaignStatus.ENABLED,
    ECampaignStatus.PAUSED,
  ]);
  const [isUtmSidemodalOpen, setIsUtmSidemodalOpen] = React.useState<boolean>(false);
  const [
    isAddUTMTagsManualySidemodalOpen,
    setIsAddUTMTagsManuallySidemodalOpen,
  ] = React.useState<boolean>(false);
  const [lastSyncDate, setLastSyncDate] = React.useState<Date>(utcToZonedTime(new Date()));
  const [data] = React.useState<Array<any>>(mapCampaignsData(tableColumns, []));
  const [hasSelectedAll, setHasSelectedAll] = React.useState<boolean>(false);
  const [searchFilter, setSearchFilter] = React.useState<string>('');
  const [filterData, setFilterData] = React.useState<Array<ISelectedFilter>>([]);
  const [period, setPeriod] = React.useState<IPeriod>({
    startDate: initialDate,
    endDate: initialDate,
  });

  const closeFirstTooltip = React.useCallback(() => setIsFirstTooltipOpen(false), [
    setIsFirstTooltipOpen,
  ]);

  const handleFilterData = React.useCallback(newData => {
    setFilterData(newData);
  }, []);

  const handleAddUTMTagsManuallySidemodalOpen = React.useCallback(
    () => setIsAddUTMTagsManuallySidemodalOpen(state => !state),
    [],
  );

  const handleUtmSidemodalOpen = React.useCallback(
    () => setIsUtmSidemodalOpen(state => !state),
    [],
  );

  const handleLastSyncDate = React.useCallback(newDate => setLastSyncDate(newDate), []);

  const syncAdsManagerData = React.useCallback(() => {
    addSynchronization({
      name: ADS_MANAGER_SYNC_NAME,
      type: ESynchronizationType.ADS_MANAGER_DATA,
      label: 'Dados',
      dependencies: [],
      storeAliasId,
      config: {
        isOnboardingSynchronization: false,
        showNotification: true,
      },
      request: {
        type: ESynchronizationRequestType.POST,
        domain: LONG_API_DOMAIN,
        api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/ads-manager/data/sync?start_date=${format(
          utcToZonedTime(new Date()),
          'yyyy-MM-dd',
        )}&end_date=${format(utcToZonedTime(new Date()), 'yyyy-MM-dd')}`,
      },
    });
  }, [addSynchronization, storeAliasId, utcToZonedTime, format]);

  const handleCampaignsStatusFilter = React.useCallback(newCampaignsStatus => {
    setCampaignsStatusFilter(newCampaignsStatus);
  }, []);

  const handleTableColumns = React.useCallback(
    newTableColumns => {
      const columnsWithoutRef = newTableColumns.map((column: ITableColumn) => {
        const duplicatedColumn = { ...column };

        delete duplicatedColumn.ref;

        return duplicatedColumn;
      });

      setTableColumns(newTableColumns);
      setAdsManagerColumns(columnsWithoutRef);
    },
    [setTableColumns, setAdsManagerColumns],
  );

  const handleSelectedAll = React.useCallback(status => setHasSelectedAll(status), []);

  const handleSearchFilter = React.useCallback(newSearchFilter => {
    setSearchFilter(newSearchFilter);
  }, []);

  React.useEffect(() => {
    if (adsManagerColumnsWithRef.length !== adsManagerColumns.length) {
      const columnsWithoutRef = adsManagerColumnsWithRef.map((column: ITableColumn) => {
        const duplicatedColumn = { ...column };

        delete duplicatedColumn.ref;

        return duplicatedColumn;
      });

      setTableColumns(adsManagerColumnsWithRef);
      setAdsManagerColumns(columnsWithoutRef);
    }
  }, [adsManagerColumns.length, adsManagerColumnsWithRef, setAdsManagerColumns]);

  React.useEffect(() => {
    if (Object.keys(columnsSize).length !== Object.keys(DEFAULT_COLUMNS_SIZE).length) {
      setColumnsSize({ ...DEFAULT_COLUMNS_SIZE, ...columnsSize });
    }
  }, [columnsSize, setColumnsSize]);

  const hasAdsManagerSynchronization = getSynchronizationStatus(
    currentSynchronizations,
    synchronizationsQueue,
    ADS_MANAGER_SYNC_NAME,
    ESynchronizationType.ADS_MANAGER_DATA,
    storeAliasId,
  );

  return (
    <AdsManagerContext.Provider
      value={{
        tableColumns,
        period,
        setPeriod,
        handleSelectedAll,
        hasSelectedAll,
        data,
        columnsSize,
        setColumnsSize,
        handleSearchFilter,
        searchFilter,
        handleTableColumns,
        campaignsStatusFilter,
        handleCampaignsStatusFilter,
        syncAdsManagerData,
        handleLastSyncDate,
        lastSyncDate,
        hasAdsManagerSynchronization,
        handleUtmSidemodalOpen,
        isUtmSidemodalOpen,
        filterData,
        handleFilterData,
        handleAddUTMTagsManuallySidemodalOpen,
        isAddUTMTagsManualySidemodalOpen,
        isFirstTooltipOpen,
        closeFirstTooltip,
      }}
    >
      {children}
    </AdsManagerContext.Provider>
  );
};

export const useAdsManager = (): IAdsManagerProps => {
  const context = React.useContext(AdsManagerContext);

  if (!context) {
    throw new Error('useAdsManager must be used within AdsManagerProvider');
  }

  return context;
};
