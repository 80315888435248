import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import BadgeBase from '@components/common/core/DataDisplay/Badge';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';
import TextBase from '@components/common/core/DataDisplay/Text';

export const TooltipText = styled(TextBase)``;

export const FailedTransactionDescriptionWrapper = styled.div`
  max-width: 256px;
  width: 100%;
`;

export const LabelAndValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ConsumeOrdersDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 256px;
`;

export const Text = styled(TextBase)`
  display: block;
  max-width: 256px;
  width: 100%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
`;

export const Tooltip = styled(CustomTooltipBase)``;

export const Badge = styled(BadgeBase)`
  margin: 0 auto;
`;

export const Table = styled(TableBase)``;
