import styled from 'styled-components/macro';

import CardBase from '@components/common/core/Surfaces/Card';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';

export const TooltipValue = styled(TextBase)`
  font-size: 0.875rem;
`;

export const TooltipLabel = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
  font-size: 0.875rem;
`;

export const TooltipValueWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const TooltipContent = styled.div`
  width: 100%;
`;

export const CustomTooltipContent = styled(CustomTooltipBase.Content)`
  width: 100%;
`;

export const CustomTooltip = styled(CustomTooltipBase)``;

export const StatusText = styled(TextBase)`
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const OrdersAmount = styled(TextBase)`
  color: ${({ theme }) => theme.colors.white.default};
  line-height: 120%;
  font-size: 0.875rem;
  font-weight: 400;
  max-width: 98px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    cursor: pointer;
  }
`;

export const OrdersStatusWrapper = styled.div``;

export const OrdersDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
`;

export const TextAndPercentageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.875rem;
  font-weight: 300;
`;

export const ChartText = styled(TextBase)`
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const ChartPercentage = styled(TextBase)`
  line-height: 120%;
`;

export const ChartWrapper = styled.div`
  width: 106px;
  height: 120px;
  position: relative;
`;

export const DataWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  width: 100%;
`;

export const Title = styled(HeadingBase)``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const Card = styled(CardBase)`
  padding: 20px 24px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 12px;
  border: unset;
  min-width: 269px;
  width: 269px;

  @media only screen and (max-width: 1360px) {
    max-width: 348px;
    width: 100%;
  }
`;
