/* eslint-disable no-plusplus */
import React from 'react';

import { useTheme } from 'styled-components';
import { Upload, FileVideo } from 'phosphor-react';

import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { EButtonSize, EButtonVariant } from '@domain/enums/components/EButton';
import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';

import { useCanvasContent } from '@helpers/hooks/pages/dashboard/automations/useCanvasContent';
import { useCanvasMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessageSettings';
import { useToast } from '@helpers/hooks/useToast';

import * as S from './styles';

const VideoTypeConfig: React.FC = () => {
  const theme = useTheme();
  const { toast } = useToast();
  const {
    handleVideoPreview,
    handleEditingContent,
    handleSelectedContentType,
    isEditingContent,
    contentToEdit,
    videoPreview,
  } = useCanvasContent();
  const { contents, handleContents } = useCanvasMessageSettings();

  const [configMessageVideoFile, setConfigMessageVideoFile] = React.useState<File>({} as File);
  const [isUploadError, setIsUploadError] = React.useState<boolean>(false);

  const resetConfig = React.useCallback(() => {
    handleVideoPreview(undefined);
    handleEditingContent(false);
    handleSelectedContentType(EConfigOption.NONE);
  }, [handleVideoPreview, handleEditingContent, handleSelectedContentType]);

  const onCancel = React.useCallback(() => {
    resetConfig();
  }, [resetConfig]);

  const onSave = React.useCallback(() => {
    if (isEditingContent) {
      resetConfig();
      return;
    }

    const newContent = { id: Math.random(), type: EConfigOption.VIDEO, content: videoPreview };

    handleContents([...contents, newContent]);

    resetConfig();
  }, [isEditingContent, resetConfig, videoPreview, handleContents, contents]);

  const handleUploadFile = React.useCallback(
    file => {
      const data = [...contents];
      const MAX_FILE_SIZE = 1024 * 1024 * 10;

      if (file.size > MAX_FILE_SIZE) {
        toast.error('O arquivo deve ter no máximo 10MB');
        return;
      }

      if (!isEditingContent) {
        handleVideoPreview(file);
        return;
      }

      if (!contentToEdit) return;

      const foundContent = data.find(content => content.id === contentToEdit.id);

      if (!foundContent) return;

      const index = data.indexOf(foundContent);

      data[index].content = file;

      handleContents(data);
    },
    [contentToEdit, contents, handleContents, handleVideoPreview, isEditingContent, toast],
  );

  const onUploadFile = React.useCallback(
    event => {
      const allowedFormats = ['video/mp4'];

      const newFileUploaded = event.target.files[0] as File;

      if (allowedFormats.includes(newFileUploaded?.type)) {
        setConfigMessageVideoFile(newFileUploaded);
        handleUploadFile(newFileUploaded);
        setIsUploadError(false);
      } else {
        setIsUploadError(true);
      }
    },
    [handleUploadFile],
  );

  const formatFileSize = (sizeInBytes: number): string => {
    if (!sizeInBytes) return 'No file';

    const units = ['bytes', 'kb', 'mb', 'gb', 'tb'];

    let i = 0;

    while (sizeInBytes >= 1024 && i < units.length - 1) {
      sizeInBytes /= 1024;
      i++;
    }

    return `${Math.round(sizeInBytes)}${units[i]}`;
  };

  return (
    <S.Wrapper>
      <S.NoSavedContent>
        <S.NoSavedContentBody>
          <Upload size={48} color={theme.colors.gray[1]} />

          <S.Title weight={ETextWeight.MEDIUM}>Selecione o vídeo para carregar</S.Title>

          <S.Description size={ETextSize.B5}>ou arraste o arquivo até aqui</S.Description>

          <S.SupportedExtensionsText size={ETextSize.B5}>MP4</S.SupportedExtensionsText>

          {isUploadError && (
            <S.NoSupportedFormatErrorText isErrorFeedback size={ETextSize.B5}>
              Formato de arquivo não permitido, tente novamente.
            </S.NoSupportedFormatErrorText>
          )}

          <S.Input type="file" name="files" onChange={onUploadFile} />
        </S.NoSavedContentBody>

        <S.NoSavedContentFooter>
          <S.FileInfosWrapper>
            <S.IconBackground>
              <FileVideo size={28} color={theme.colors.gray[8]} />
            </S.IconBackground>

            <S.FileNameAndSizeWrapper>
              <S.FileName>{configMessageVideoFile?.name || 'Nenhum arquivo escolhido'}</S.FileName>

              <S.FileSize size={ETextSize.B5}>
                {formatFileSize(configMessageVideoFile?.size) || 0}
              </S.FileSize>
            </S.FileNameAndSizeWrapper>
          </S.FileInfosWrapper>

          <S.ButtonsWrapper>
            <S.CancelButton
              onClick={onCancel}
              variant={EButtonVariant.SECONDARY_BUTTON}
              buttonSize={EButtonSize.MEDIUM}
              type="button"
            >
              Cancelar
            </S.CancelButton>

            <S.SaveButton onClick={onSave} buttonSize={EButtonSize.MEDIUM} type="button">
              Salvar
            </S.SaveButton>
          </S.ButtonsWrapper>
        </S.NoSavedContentFooter>
      </S.NoSavedContent>
    </S.Wrapper>
  );
};

export default VideoTypeConfig;
