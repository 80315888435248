import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.Wrapper>
      <S.CardWrapper>
        <Skeleton width="100%" height={96} />
      </S.CardWrapper>

      <S.CardWrapper>
        <Skeleton width="100%" height={96} />
      </S.CardWrapper>

      <S.CardWrapper>
        <Skeleton width="100%" height={96} />
      </S.CardWrapper>

      <S.CardWrapper>
        <Skeleton width="100%" height={96} />
      </S.CardWrapper>
    </S.Wrapper>
  );
};

export default SkeletonLoading;
