import { IIntegration, IStoreIntegrations } from '@domain/interfaces/stores/IStores';

import { EGateway } from '@domain/enums/common/EGateway';

import { GATEWAYS, STORE_PROVIDERS } from '@constants/common/integrations';
import { IGateway } from '@domain/interfaces/common/integration/IGateway';
import { EDropshippingProvider } from '@domain/enums/common/integrations/EDropshippingProvider';

const getGatewayIntegrationsBySearchFilter = (
  integrationToBeSearched: string,
  gateways: Array<IGateway>,
): Array<IGateway> => {
  const filteredIntegrations = gateways.filter(integration => {
    const integrationName = integration.name;

    const integrationToSearch = integrationToBeSearched.toLowerCase();

    const isIntegrationIncludedOnSearch = integrationName.includes(integrationToSearch);

    return isIntegrationIncludedOnSearch;
  });

  return filteredIntegrations;
};

export const findGatewaysIntegration = (
  storeIntegrations: IStoreIntegrations,
  identifier: EGateway,
): boolean => {
  const foundGatewayIntegration = storeIntegrations.gateways.find(
    integration => integration.name === identifier,
  );

  if (foundGatewayIntegration && identifier !== EGateway.OTHER_GATEWAY)
    return foundGatewayIntegration.is_active;

  const otherGatewayIntegration = storeIntegrations.gateways.find(
    integration => integration.name === EGateway.OTHER_GATEWAY,
  ) as IIntegration;

  if (otherGatewayIntegration.other_gateway?.gateway_names.includes('OTHER')) return true;

  const isIdentifierIncludedInOtherGateway = otherGatewayIntegration.other_gateway?.gateway_names.includes(
    identifier,
  );

  if (isIdentifierIncludedInOtherGateway) return true;

  return false;
};

export const getStoreIntegrations = (
  storeIntegrations: IStoreIntegrations,
  integrationToBeSearched: string,
  gateways: Array<IGateway>,
): Array<IGateway> => {
  const { store_front } = storeIntegrations;

  const foundActiveIntegration = store_front.find(
    integration =>
      integration.is_active && integration.name !== EDropshippingProvider.MERCADO_LIVRE,
  );

  if (!foundActiveIntegration) return [];

  const storeProvider = STORE_PROVIDERS.find(
    provider => provider.identifier === foundActiveIntegration.name,
  );

  if (!storeProvider) return [];

  const gatewaysToReturn = [];

  gateways.forEach(gateway => {
    const hasGateway = storeProvider.gateways.includes(gateway.identifier);

    if (hasGateway) gatewaysToReturn.push(gateway);
  });

  const foundOtherGateway = storeIntegrations.gateways.find(
    gateway => gateway?.other_gateway?.has_other_gateway,
  );

  const hasOtherGateway =
    foundOtherGateway && foundOtherGateway.other_gateway?.gateway_names.includes('OTHER');

  const hasPredefinedGateway =
    foundOtherGateway &&
    foundOtherGateway.other_gateway?.gateway_names &&
    !foundOtherGateway.other_gateway?.gateway_names.includes('OTHER');

  if (hasOtherGateway) {
    const foundGateway = GATEWAYS.find(
      gateway => gateway.identifier === EGateway.OTHER_GATEWAY,
    ) as typeof GATEWAYS[number];

    gatewaysToReturn.push(foundGateway);
  }

  if (foundOtherGateway && hasPredefinedGateway) {
    const predefinedGateways = foundOtherGateway.other_gateway?.gateway_names;

    gateways.forEach(gateway => {
      if (predefinedGateways?.includes(gateway.identifier)) {
        gatewaysToReturn.push(gateway);
      }
    });
  }

  if (!foundOtherGateway) {
    gateways.forEach(gateway => {
      if (gateway.isPredefinedOtherGateway) gatewaysToReturn.push(gateway);
    });

    const foundGateway = GATEWAYS.find(
      gateway => gateway.identifier === EGateway.OTHER_GATEWAY,
    ) as typeof GATEWAYS[number];

    gatewaysToReturn.push(foundGateway);
  }

  const filteredIntegrationsBySearch = getGatewayIntegrationsBySearchFilter(
    integrationToBeSearched,
    gatewaysToReturn,
  );

  return filteredIntegrationsBySearch;
};

export const teste = (
  storeIntegrations: IStoreIntegrations,
  integrationToBeSearched: string,
): Array<typeof GATEWAYS[number]> => {
  const { store_front } = storeIntegrations;

  const filteredIntegrationsBySearch = getGatewayIntegrationsBySearchFilter(
    integrationToBeSearched,
    GATEWAYS,
  );

  const foundActiveIntegration = store_front.find(
    integration =>
      integration.is_active && integration.name !== EDropshippingProvider.MERCADO_LIVRE,
  );

  if (!foundActiveIntegration) return [];

  const foundStoreIntegration = STORE_PROVIDERS.find(
    provider => provider.identifier === foundActiveIntegration.name,
  ) as typeof STORE_PROVIDERS[number];

  const storeIntegrationGateways = foundStoreIntegration?.gateways || [];

  const filteredGatewaysByStoreIntegration = filteredIntegrationsBySearch.filter(integration =>
    storeIntegrationGateways.includes(integration.identifier),
  );

  const gatewaysToReturn = [...filteredGatewaysByStoreIntegration];

  const foundOtherGateway = storeIntegrations.gateways.find(
    gateway => gateway?.other_gateway?.has_other_gateway,
  );

  if (foundOtherGateway && foundOtherGateway.other_gateway?.gateway_names.includes('OTHER')) {
    const foundGateway = GATEWAYS.find(
      gateway => gateway.identifier === EGateway.OTHER_GATEWAY,
    ) as typeof GATEWAYS[number];

    gatewaysToReturn.push(foundGateway);

    return gatewaysToReturn;
  }

  const predefinedGateways = GATEWAYS.filter(gateway => gateway.isPredefinedOtherGateway);

  const foundPredefinedGateway = predefinedGateways.find(gateway =>
    foundOtherGateway?.other_gateway?.gateway_names.includes(gateway.identifier),
  );

  if (foundOtherGateway && foundPredefinedGateway) {
    gatewaysToReturn.push(foundPredefinedGateway);

    return gatewaysToReturn;
  }

  return filteredIntegrationsBySearch;
};
