import React from 'react';
import { DotsSixVertical } from 'phosphor-react';

import { IOptionProps } from '@domain/interfaces/dashboard/Automations/Canvas/TaskMenu/ITaskMenu';

import * as S from './styles';

const Option: React.FC<IOptionProps> = ({
  icon: Icon,
  children,
  onDragStart,
  variant = 'default',
  onClick,
}) => {
  return (
    <S.Wrapper variant={variant} onDragStart={onDragStart} draggable onClick={onClick}>
      <S.Group>
        <Icon size={18} color="white" />
        {children}
      </S.Group>
      <DotsSixVertical size={16} color="white" />
    </S.Wrapper>
  );
};

export default Option;
