import React from 'react';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { IAdSenseCampaign } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IAdSenseCampaigns';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import NoUtmInsight from './NoUtmInsight';
import BlurredContent from './BlurredContent';

import * as S from './styles';

const Insights: React.FC<ICampaignProps> = ({ data, index }) => {
  const insights = [];

  const insightsData: IAdSenseCampaign = data;

  const { store } = useStoreConfig();
  const { isDemoEnvironment } = useConfig();

  if (!insightsData.has_all_ads_with_utm_synchronized && !isDemoEnvironment) {
    insights.push({
      id: 'no-utm-insight',
      component: NoUtmInsight,
    });
  }

  const isContentBlurred =
    (!store.is_utm_onboarding_finished ||
      !insightsData.has_at_least_one_ads_with_utm_synchronized) &&
    !isDemoEnvironment &&
    !insightsData.has_all_ads_with_utm_synchronized;

  return (
    <S.Wrapper>
      {isContentBlurred ? (
        <BlurredContent index={index} />
      ) : (
        <>
          {insights.map(insight => {
            const Component = insight.component;

            return <Component key={insight.id} />;
          })}
        </>
      )}
    </S.Wrapper>
  );
};

export default Insights;
