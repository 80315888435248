import React from 'react';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { useParams } from 'react-router-dom';
import { IDescriptionProps } from '@domain/interfaces/dashboard/IdeasChannel/suggestions';
import { IParams } from '@domain/interfaces/IParams';

import * as S from './styles';

const Description: React.FC<IDescriptionProps> = ({ suggestions }) => {
  const { storeAliasId } = useParams<IParams>();

  const ideaDetailsPageURL = `/${storeAliasId}/dashboard/admin/ideas/details/${suggestions.id}`;

  return (
    <S.DescriptionWrapper to={ideaDetailsPageURL}>
      <S.Title type={EHeadingSize.H5}>{suggestions.title}</S.Title>
      <S.DescriptionText>{suggestions.description}</S.DescriptionText>
    </S.DescriptionWrapper>
  );
};

export default Description;
