import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ImageBase from '@components/common/core/DataDisplay/Image';
import BadgeBase from '@components/common/core/DataDisplay/Badge';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 7px;
  width: 100%;
  max-width: 290px;
  min-height: 438px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 16px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`;

export const Heading = styled(HeadingBase)`
  margin-bottom: 12px;
  color: #ffffff;
`;

export const Image = styled(ImageBase)``;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Badge = styled(BadgeBase)`
  margin-bottom: 12px;
  text-transform: uppercase;
`;

export const BenefitTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BenefitTitle = styled(TextBase)`
  margin-bottom: 4px;
`;

export const BenefitDescription = styled(TextBase)`
  margin-bottom: 18px;
`;

export const SeeMoreButton = styled(ButtonBase)`
  width: 100%;
  text-transform: uppercase;

  span {
    font-size: 0.875rem;
  }
`;
