import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 24px;
  height: 90%;
  position: fixed;
  left: 120px;
  bottom: 24px;
  z-index: 1;
`;
