import { ENodeType } from '@domain/enums/mappers/automations/profitfy/ENodeType';
import IWorkflowEdge from '@domain/interfaces/mappers/automations/profitfy/IWorkflowEdge';
import IWorkflowNode from '@domain/interfaces/mappers/automations/profitfy/IWorkflowNode';

interface ICheckForMissingConfiguredNodesProps {
  nodes: IWorkflowNode[];
  edges: IWorkflowEdge[];
  toast: any;
}

export const checkForMissingConfiguredNodes = ({
  nodes,
  edges,
  toast,
}: ICheckForMissingConfiguredNodesProps): boolean => {
  for (const currentNode of nodes) {
    if (currentNode.type === ENodeType.IF) {
      const hasMissingConfig = currentNode.metadata.data.rules.some(
        (rule: any) => rule.input === '',
      );

      if (hasMissingConfig) {
        toast.error_long(
          'Faltou configurar ao menos um node condicional. Por favor, verifique e tente novamente.',
        );
        return false;
      }

      const foundPositiveEdge = edges.find(
        edge => edge.source === currentNode.provider_id && edge.source_handle === 'positive',
      );

      const foundNegativeEdge = edges.find(
        edge => edge.source === currentNode.provider_id && edge.source_handle === 'negative',
      );

      if (!foundPositiveEdge || !foundNegativeEdge) {
        toast.error_long(
          'Faltou configurar as conexões do node condicional. Por favor, verifique e tente novamente.',
        );
        return false;
      }
    }

    if (currentNode.type === ENodeType.WAIT) {
      const hasConfig = currentNode.metadata.data.rules[0].amount;

      if (!hasConfig) {
        toast.error_long(
          'Faltou configurar ao menos um node de aguardar. Por favor, verifique e tente novamente.',
        );
        return false;
      }
    }

    if (currentNode.type === ENodeType.SWITCH) {
      const missingConfig = currentNode.metadata.data.rules.every((rule: any) => rule.input === '');

      if (missingConfig) {
        toast.error_long(
          'Faltou configurar ao menos um node de condição multipla. Por favor, verifique e tente novamente.',
        );
        return false;
      }

      let ruleWithProblem = false;
      const rules = currentNode?.metadata?.data?.rules;

      if (rules.length) {
        const isFirstRuleEmpty = rules[0].input === '';
        const isSecondRuleEmpty = rules[1].input === '';
        const isThirdRuleEmpty = rules[2].input === '';

        if (isFirstRuleEmpty && !isSecondRuleEmpty && !isThirdRuleEmpty) {
          ruleWithProblem = true;
        }

        if (!isFirstRuleEmpty && isSecondRuleEmpty && !isThirdRuleEmpty) {
          ruleWithProblem = true;
        }

        if (isFirstRuleEmpty && isSecondRuleEmpty && !isThirdRuleEmpty) {
          ruleWithProblem = true;
        }
      }

      if (ruleWithProblem) {
        toast.error_long(
          'Faltou configurar ao menos um node de condição multipla. Por favor, verifique e tente novamente.',
        );
        return false;
      }
    }

    if (currentNode.type === ENodeType.WHATSAPP) {
      const hasMessage = currentNode.metadata.data.message;

      if (!hasMessage) {
        toast.error_long(
          'Faltou configurar ao menos um node de whatsapp. Por favor, verifique e tente novamente.',
        );
        return false;
      }
    }
  }

  return true;
};

interface ICheckForMissingConnectionsProps {
  nodes: IWorkflowNode[];
  edges: IWorkflowEdge[];
  toast: any;
}

export const checkForMissingConnections = ({
  nodes,
  edges,
  toast,
}: ICheckForMissingConnectionsProps): boolean => {
  const nodesThatDontNeedConnections = nodes.filter(
    node =>
      node.type !== ENodeType.ABANDONED_CART &&
      node.type !== ENodeType.BOLETO &&
      node.type !== ENodeType.END &&
      node.type !== ENodeType.INTERNAL_ORDER_STATUS &&
      node.type !== ENodeType.INTERNAL_SELL_RECOVERED_STATUS &&
      node.type !== ENodeType.INTERNAL_WORKFLOW_STATUS &&
      node.type !== ENodeType.PIX &&
      node.type !== ENodeType.REJECTED_PAYMENT &&
      node.type !== ENodeType.WEBHOOK &&
      node.type !== ENodeType.UPDATED_FULFILLMENT &&
      node.type !== ENodeType.SIMPLE_SHIPMENT,
  );

  const hasNodesWithoutEdges = nodesThatDontNeedConnections.filter(node => {
    const sourceHandle = edges.find(edge => edge.source === node.provider_id);
    const targetHandle = edges.find(edge => edge.target === node.provider_id);

    if (!sourceHandle) return node;
    if (!targetHandle) return node;

    return false;
  });

  if (hasNodesWithoutEdges.length) {
    toast.error_long('Todos os nodes precisam de conexões.');
    return false;
  }

  const foundEndsNodes = nodes.filter(node => node.type === ENodeType.END);

  if (edges.length === 0) {
    toast.error_long('Você precisa adicionar ao menos uma conexão.');
    return false;
  }

  if (!foundEndsNodes.length) {
    toast.error_long('Você precisa adicionar ao menos um node de encerramento.');
    return false;
  }

  const filteredNodes = nodes.filter(node =>
    foundEndsNodes.some((endNode: any) => endNode.id !== node.provider_id),
  );

  const nodesWithoutEdges = filteredNodes.filter(
    node =>
      !edges.some((edge: any) => edge.source === node.provider_id) && node.type !== ENodeType.END,
  );

  if (nodesWithoutEdges.length) {
    toast.error_long('Você precisa adicionar ao menos uma conexão para cada node.');
    return false;
  }

  const endNodeConnections = foundEndsNodes.filter(endNode => {
    const hasConnection = edges.some(
      edge => edge.target === endNode.provider_id || edge.target_handle === endNode.provider_id,
    );
    return hasConnection;
  });

  if (!endNodeConnections.length) {
    toast.error_long('Você precisa adicionar ao menos uma conexão para cada node de encerramento.');
    return false;
  }

  return true;
};
