import { v4 } from 'uuid';

import {
  ECanceledOrderProductOptions,
  ECanceledOrderType,
} from '@domain/enums/dashboard/automations/node/conditionalNode/ECanceledOrder';

export const DEFAULT_CANCELED_ORDER_CONTENT = {
  id: v4(),
  type: ECanceledOrderType.PRODUCT_NAME,
  options: ECanceledOrderProductOptions.CONTAINS,
  input: '',
};
