import React from 'react';

import * as S from './styles';

const Header: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Title>Instância de Whatsapp desconectada</S.Title>
      <S.Description>
        Reconecte a sua instância para garantir o funcionamento das automações
      </S.Description>
    </S.Wrapper>
  );
};

export default Header;
