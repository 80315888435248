import React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

import { ICustomAccordion } from '@domain/interfaces/components/ICustomAccordion';

import Content from './Content';
import Item from './Item';
import Trigger from './Trigger';

import * as S from './styles';

const CustomAccordion: ICustomAccordion &
  React.FC<AccordionPrimitive.AccordionSingleProps & React.RefAttributes<HTMLDivElement>> = ({
  children,
  ...rest
}) => {
  return (
    <S.Wrapper collapsible {...rest}>
      {children}
    </S.Wrapper>
  );
};

CustomAccordion.Content = Content;
CustomAccordion.Item = Item;
CustomAccordion.Trigger = Trigger;

export default CustomAccordion;
