import React from 'react';

import { ITooltipContentProps } from '@domain/interfaces/dashboard/Orders/IOrdersLineItem';
import { numberFormatter } from '@helpers/masks';
import { useDate } from '@helpers/hooks/useDate';

import * as S from './styles';

const TooltipContent: React.FC<ITooltipContentProps> = ({ synchronization }) => {
  const { format } = useDate();

  const getCurrency = {
    BRL: 'R$',
    USD: '$',
  };

  return (
    <S.Wrapper>
      <S.TextWrapper>
        <S.DescriptionText>Custo do produto:</S.DescriptionText>
        <S.ValueText>{`
          ${getCurrency[synchronization.currency]}${numberFormatter(synchronization.amount, 2)}
        `}</S.ValueText>
      </S.TextWrapper>

      <S.TextWrapper>
        <S.DescriptionText>Data de processamento:</S.DescriptionText>
        <S.ValueText>{format(new Date(synchronization.updated_at), 'dd/MM/yyyy')}</S.ValueText>
      </S.TextWrapper>

      {synchronization.currency_amount && synchronization.currency_amount > 0 && (
        <S.TextWrapper>
          <S.DescriptionText>Cotação dólar com spread:</S.DescriptionText>
          <S.ValueText>{`$${numberFormatter(synchronization.currency_amount, 2)}`}</S.ValueText>
        </S.TextWrapper>
      )}

      <S.TextWrapper>
        <S.DescriptionText>Total:</S.DescriptionText>
        <S.ValueText>
          {synchronization.currency === 'USD'
            ? `R$${numberFormatter(synchronization.amount * synchronization.currency_amount, 2)}`
            : `R$${numberFormatter(synchronization.amount, 2)}`}
        </S.ValueText>
      </S.TextWrapper>
    </S.Wrapper>
  );
};

export default TooltipContent;
