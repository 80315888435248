import LabelBase from '@components/common/core/DataDisplay/Label';
import TextBase from '@components/common/core/DataDisplay/Text';
import FormBase from '@components/common/core/Inputs/Form';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import styled from 'styled-components/macro';

export const TitleForm = styled(TextBase)`
  line-height: 120%;
  color: ${({ theme }) => theme.colors.font};
  display: inline-block;
  font-size: 1.25rem;
  margin-bottom: 16px;
  margin: 24px 0px;
`;

export const Form = styled(FormBase)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InputGroup = styled(InputGroupBase)``;
export const InputQuantityGroup = styled(InputGroupBase)`
  width: 35%;

  @media only screen and (max-width: 820px) {
    width: 100%;
  }
`;

export const InputsGroupWrapper = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;

  @media only screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)`
  width: 100%;
`;

export const Select = styled(SelectBase)`
  width: 100%;
`;

export const Option = styled(OptionBase)``;
