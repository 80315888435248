import React from 'react';

import { IFilterProps, ISelectedFilter } from '@domain/interfaces/components/IFilter';
import { useDate } from '@helpers/hooks/useDate';
import { EFilterOperator } from '@domain/enums/components/EFilter';
import { IPeriod } from '@domain/interfaces/components/IDatePicker';

import * as S from './styles';

const DateRangePicker: React.FC<IFilterProps> = ({
  config,
  handleOnClick,
  defaultValue,
  updateData,
  addNewData,
}) => {
  const { utcToZonedTime, format } = useDate();

  const [period] = React.useState({
    startDate: utcToZonedTime(new Date()),
    endDate: utcToZonedTime(new Date()),
  });

  const handlePeriodChange = React.useCallback(
    (newPeriod: IPeriod) => {
      const formattedStartDate = format(newPeriod.startDate, 'yyyy-MM-dd');
      const formattedEndDate = format(newPeriod.endDate, 'yyyy-MM-dd');

      const newData: ISelectedFilter = {
        field: config.field,
        label: config.label,
        value: { startDate: formattedStartDate, endDate: formattedEndDate },
        operator: EFilterOperator.EQUAL,
      };

      if (defaultValue) {
        updateData(newData);
      } else {
        addNewData(newData);
      }

      handleOnClick();
    },
    [addNewData, config, format, defaultValue, updateData, handleOnClick],
  );

  return (
    <S.Wrapper>
      <S.CustomDatePicker
        period={period}
        setPeriod={handlePeriodChange}
        maxDate={utcToZonedTime(new Date())}
      />
    </S.Wrapper>
  );
};

export default DateRangePicker;
