import React from 'react';
import { Target } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import Form from '@components/common/core/Inputs/Form';
import SideModalSave from '@components/common/core/Utils/SideModalSave';
import { storeGoalSchema } from '@helpers/validators/dashboard/financialDashboard/storeGoal';
import { currencyFormatter, currencyToNumber, numberFormatter } from '@helpers/masks';
import { EHeadingWeight } from '@domain/enums/components/EHeading';
import Text from '@components/common/core/DataDisplay/Text';
import { IPeriod } from '@domain/interfaces/components/IDatePicker';
import { useDate } from '@helpers/hooks/useDate';
import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import goalService from '@services/pages/dashboard/goal/goal';
import { IParams } from '@domain/interfaces/IParams';
import { useToast } from '@helpers/hooks/useToast';
import { EGoalMetric } from '@domain/enums/dashboard/goal/EGoal';
import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const EditStoreGoalSideModal: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(storeGoalSchema) });
  const { utcToZonedTime, format } = useDate();
  const {
    isEditStoreGoalSideModalOpen,
    handleEditStoreGoalSideModal,
    mutateFinancialDashboard,
    financialDetailsReport,
    isLoadingFinancialDashboardProvider,
    isValidatingFinancialDashboardProvider,
  } = useFinancialDashboard();
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { analytics, selectedStore, user } = useConfig();

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [period, setPeriod] = React.useState<IPeriod>({
    startDate: utcToZonedTime(new Date()),
    endDate: utcToZonedTime(new Date()),
  });

  const onSubmit = React.useCallback(
    async data => {
      setIsSubmittingForm(true);

      try {
        await goalService.updateGoal({
          storeAliasId,
          goalAliasId: financialDetailsReport.goal?.alias_id,
          data: {
            name: 'Store Goal',
            metric: data.goal_type,
            goal_amount: currencyToNumber(data.amount),
            start_date: format(period.startDate, 'yyyy-MM-dd'),
            end_date: format(period.endDate, 'yyyy-MM-dd'),
            is_active: true,
          },
        });

        toast.success('Meta editada com sucesso!');

        analytics?.track(
          'Store Goal Edited',
          {
            email: user?.email,
          },
          { context: { groupId: selectedStore?.alias_id } },
        );

        setIsSubmittingForm(false);

        mutateFinancialDashboard();

        handleEditStoreGoalSideModal();
      } catch (error: any) {
        setIsSubmittingForm(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [
      format,
      handleEditStoreGoalSideModal,
      period,
      storeAliasId,
      toast,
      mutateFinancialDashboard,
      financialDetailsReport,
      analytics,
      selectedStore,
      user,
    ],
  );

  const onAmountChange = React.useCallback(event => {
    event.target.value = currencyFormatter(event.target.value);
  }, []);

  React.useEffect(() => {
    if (financialDetailsReport) {
      setPeriod({
        startDate: utcToZonedTime(financialDetailsReport.goal?.start_date || new Date()),
        endDate: utcToZonedTime(financialDetailsReport.goal?.end_date || new Date()),
      });
    }
  }, [financialDetailsReport, utcToZonedTime]);

  if (isLoadingFinancialDashboardProvider || isValidatingFinancialDashboardProvider) {
    return <></>;
  }

  return (
    <S.SideModal isOpen={isEditStoreGoalSideModalOpen} toggle={handleEditStoreGoalSideModal}>
      <S.Header>
        <Target size={36} />
        <S.Title fontWeight={EHeadingWeight.REGULAR}>Editar meta</S.Title>
      </S.Header>

      <S.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>Meta</S.Label>
            <S.Input
              {...register('amount')}
              placeholder="Insira o valor da meta"
              prependText="R$"
              type="text"
              defaultValue={numberFormatter(financialDetailsReport.goal?.goal_amount || 0, 2)}
              onChange={onAmountChange}
            />
            {errors.amount && <Text isErrorFeedback>{errors.amount.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Tipo de métrica</S.Label>
            <S.Select
              {...register('goal_type')}
              defaultValue={financialDetailsReport.goal?.metric || ''}
            >
              <S.Option value="" disabled hidden>
                Selecione a métrica que deseja como meta
              </S.Option>
              <S.Option value={EGoalMetric.NET_REVENUE}>Receita Líquida</S.Option>
              <S.Option value={EGoalMetric.NET_PROFIT}>Lucro Líquido</S.Option>
            </S.Select>
            {errors.goal_type && <Text isErrorFeedback>{errors.goal_type.message}</Text>}
          </S.InputGroup>

          <S.CustomDatePicker period={period} setPeriod={setPeriod} />

          <SideModalSave
            successButtonText="Salvar meta"
            cancelButtonText="Cancelar"
            onCancel={handleEditStoreGoalSideModal}
            isLoading={isSubmittingForm}
            isDisabled={isSubmittingForm}
          />
        </Form>
      </S.Body>
    </S.SideModal>
  );
};

export default EditStoreGoalSideModal;
