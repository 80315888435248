import React from 'react';

import { CircleSpinner } from 'react-spinners-kit';

import { IButton } from '@domain/interfaces/components/IButton';
import { ETextWeight } from '@domain/enums/components/EText';
import { EButtonSize, EButtonTextColor, EButtonVariant } from '@domain/enums/components/EButton';
import theme from '@styles/themes/dark/theme';

import * as S from './styles';

const Button: React.FC<IButton> = ({
  children,
  disabled,
  variant,
  iconPrepend: IconPrepend,
  iconAppend: IconAppend,
  isLoading,
  type,
  iconAppendOutline = false,
  iconPrependOutline = false,
  buttonSize = EButtonSize.LARGE,
  textColor = EButtonTextColor.LIGHT,
  iconColor,
  ...rest
}) => {
  const getIconColor = React.useCallback(() => {
    if (variant === EButtonVariant.SECONDARY && disabled) {
      return theme.colors.gray[2];
    }

    if (variant === EButtonVariant.SECONDARY) {
      return theme.colors.green.default;
    }

    return '#ffffff';
  }, [disabled, variant]);

  return (
    <S.Container
      type={type}
      disabled={disabled || isLoading}
      variant={variant}
      buttonSize={buttonSize}
      {...rest}
    >
      {IconPrepend && !isLoading && (
        <IconPrepend size={18} color={iconColor || getIconColor()} outline={iconPrependOutline} />
      )}
      <S.ButtonText
        textColor={textColor}
        isLoading={isLoading}
        disabled={disabled || isLoading}
        iconAppend={IconAppend}
        iconPrepend={IconPrepend}
        variant={variant}
        buttonSize={buttonSize}
        weight={ETextWeight.BOLD}
      >
        {children}
      </S.ButtonText>
      {IconAppend && !isLoading && (
        <IconAppend size={18} color={getIconColor()} outline={iconAppendOutline} />
      )}
      {isLoading && (
        <S.SpinnerWrapper>
          <CircleSpinner size={15} color={getIconColor()} loading={isLoading} />
        </S.SpinnerWrapper>
      )}
    </S.Container>
  );
};

export default React.memo(Button);
