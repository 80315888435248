export const PREDEFINED_ADM_GROUP_PERMISSIONS = [
  'Dashboards',
  'Campanhas',
  'Avisos',
  'Relatórios',
  'Valores Adicionais',
  'Dados',
];

export const DEFAULT_FEATURES = [
  {
    name: 'Dashboard',
    permissions: [
      {
        name: 'Financeiro',
        value: 'has_financial_store_dashboard_full_write_access',
        readValue: 'has_financial_store_dashboard_full_read_access',
      },
      {
        name: 'Análise de Produtos',
        value: 'has_financial_product_dashboard_full_write_access',
        readValue: 'has_financial_product_dashboard_full_read_access',
      },
    ],
  },
  {
    name: 'Avisos',
    permissions: [
      {
        name: 'Feed de Avisos',
        value: 'has_feed_full_write_access',
        readValue: 'has_feed_full_read_access',
      },
    ],
  },
  {
    name: 'Campanhas',
    permissions: [
      {
        name: 'Gerenciador de Ads',
        value: 'has_ads_manager_full_write_access',
        readValue: 'has_ads_manager_full_read_access',
      },
    ],
  },
  {
    name: 'Automações',
    permissions: [
      {
        name: 'Automações e Enviadores de Mensagens',
        value: 'has_workflow_full_write_access',
        readValue: 'has_workflow_full_read_access',
      },
    ],
  },
  {
    name: 'Relatórios',
    permissions: [
      {
        name: 'Pedidos',
        value: 'has_orders_table_full_write_access',
        readValue: 'has_orders_table_full_read_access',
      },
      {
        name: 'Boletos',
        value: 'has_boleto_table_full_write_access',
        readValue: 'has_boleto_table_full_read_access',
      },
      {
        name: 'Detalhes Financeiros',
        value: 'has_financial_details_table_full_write_access',
        readValue: 'has_financial_details_table_full_read_access',
      },
    ],
  },
  {
    name: 'Valores Adicionais',
    permissions: [
      {
        name: 'Entrada e Saída',
        value: 'has_custom_value_full_write_access',
        readValue: 'has_custom_value_full_read_access',
      },
    ],
  },
  {
    name: 'Dados',
    permissions: [
      {
        name: 'Integrações',
        value: 'has_integrations_full_write_access',
        readValue: 'has_integrations_full_read_access',
      },
      {
        name: 'Custo dos Produtos',
        value: 'has_product_cost_full_write_access',
        readValue: 'has_product_cost_full_read_access',
      },
      {
        name: 'Custo de Kits',
        value: 'has_kits_full_write_access',
        readValue: 'has_kits_full_read_access',
      },
      {
        name: 'Impostos',
        value: 'has_tax_full_write_access',
        readValue: 'has_tax_full_read_access',
      },
      {
        name: 'Taxas e Tarifas',
        value: 'has_checkout_fee_full_write_access',
        readValue: 'has_checkout_fee_full_read_access',
      },
      {
        name: 'Frete',
        value: 'has_shipping_full_write_access',
        readValue: 'has_shipping_full_read_access',
      },
    ],
  },
];

export const FEATURES = [
  {
    name: 'Dashboard',
    permissions: ['Financeiro', 'Análise de Produtos'],
  },
  {
    name: 'Avisos',
    permissions: ['Feed de Avisos'],
  },
  {
    name: 'Campanhas',
    permissions: ['Gerenciador de Ads'],
  },
  {
    name: 'Relatórios',
    permissions: ['Pedidos', 'Boletos', 'Detalhes Financeiros'],
  },
  {
    name: 'Valores Adicionais',
    permissions: ['Saída', 'Entrada'],
  },
  {
    name: 'Dados',
    permissions: [
      'Integrações',
      'Custos dos Produtos',
      'Custo de Kits',
      'Impostos',
      'Taxas e tarifas',
    ],
  },
];
