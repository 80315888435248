import React from 'react';

import { useCustomValuesIn } from '@helpers/hooks/pages/dashboard/customValues/useCustomValuesIn';
import Header from './Header';
import Body from './Body';

import * as S from './styles';

const NewCustomValueSidemodal: React.FC = () => {
  const { isNewCustomValueSidemodalOpen, handleNewCustomValueSidemodalOpen } = useCustomValuesIn();

  return (
    <S.SideModal isOpen={isNewCustomValueSidemodalOpen} toggle={handleNewCustomValueSidemodalOpen}>
      <Header />
      <Body />
    </S.SideModal>
  );
};

export default NewCustomValueSidemodal;
