import React from 'react';

import { EUserStoreRole } from '@domain/enums/common/userStore/EUserStoreRole';

import { AutomationsManagerProvider } from '@helpers/hooks/pages/dashboard/automations/useAutomationsManager';
import { StepModalProvider } from '@helpers/hooks/components/useStepModal';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import AutomationsManager from '@components/Dashboard/Automations/AutomationsManager/Wrapper';
import Welcome from '@components/Dashboard/Automations/Welcome';
import NoAccess from '@components/common/core/Utils/NoAccess';

const Wrapper: React.FC = () => {
  const { store, accessManagementData } = useStoreConfig();
  const { isInternalTeam } = useConfig();

  const isStoreOwner = accessManagementData?.role === EUserStoreRole.OWNER;
  const hasAccessToPage =
    accessManagementData?.group?.has_workflow_full_write_access ||
    accessManagementData?.group?.has_workflow_full_read_access;

  if (!isStoreOwner && !hasAccessToPage && !isInternalTeam) {
    return <NoAccess />;
  }

  if (!store?.is_automation_onboarding_finished) {
    return <Welcome />;
  }

  return (
    <AutomationsManagerProvider>
      <StepModalProvider>
        <AutomationsManager />
      </StepModalProvider>
    </AutomationsManagerProvider>
  );
};

export default Wrapper;
