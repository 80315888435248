import React from 'react';
import { useHistory } from 'react-router-dom';

import logo from '@assets/img/brand/profitfy-logo.svg';
import { EHeadingSize } from '@domain/enums/components/EHeading';
// import { EButtonVariant } from '@domain/enums/components/EButton';
import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const Migration: React.FC = () => {
  const history = useHistory();
  const { analytics, user, selectedStore } = useConfig();

  const onSignUpClick = React.useCallback(() => {
    history.push('/sign-up/1');
  }, [history]);

  React.useEffect(() => {
    analytics?.track(
      'Migration Page Viewed',
      {
        email: user?.email,
      },
      { context: { groupId: selectedStore?.alias_id } },
    );
  }, [analytics, user, selectedStore]);

  return (
    <S.Container>
      <S.Wrapper>
        <S.Content>
          <S.Logo src={logo} alt="Profitfy.me" />

          <S.TextAndVideoWrapper>
            <S.Title type={EHeadingSize.H2}>Pronto para iniciar sua migração?</S.Title>

            <S.Text>
              No vídeo abaixo vamos te apresentar a<strong> nova versão da Profitfy, </strong>na
              qual estávamos desenvolvendo nos últimos 12 meses.
            </S.Text>
            <S.Text>
              <strong>Desejamos a você uma ótima experiência!</strong>
            </S.Text>
            <S.VideoWrapper>
              <S.VideoFrame
                width="1920"
                height="1080"
                src="https://www.youtube.com/embed/BItlO_idBJc"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </S.VideoWrapper>

            {/* <S.Text>
              No primeiro botão abaixo você poderá agendar uma call para esclarecimento de dúvidas
              sobre a migração, caso seja do seu interesse. Caso contrário, basta clicar no botão
              “realizar cadastro” para dar continuidade ao processo.
            </S.Text> */}
          </S.TextAndVideoWrapper>

          <S.ButtonsWrapper>
            {/* <S.ScheduleCalendlyButton variant={EButtonVariant.SECONDARY} onClick={onCalendlyClick}>
              Marcar reunião
            </S.ScheduleCalendlyButton> */}
            <S.SignUpButton onClick={onSignUpClick}>Realizar cadastro</S.SignUpButton>
          </S.ButtonsWrapper>
        </S.Content>
      </S.Wrapper>
    </S.Container>
  );
};

export default Migration;
