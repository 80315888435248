import React from 'react';
import { BellSimple, DeviceMobile } from 'phosphor-react';
import { useTheme } from 'styled-components';
import { useParams } from 'react-router-dom';
import { IParams } from '@domain/interfaces/IParams';
import { useToast } from '@helpers/hooks/useToast';

import { useMobileNotification } from '@helpers/hooks/pages/dashboard/profile/useMobileNotification';
import mobileNotificationService from '@services/pages/dashboard/profile/mobileNotification';

import * as S from './styles';

const Notifications: React.FC = () => {
  const { colors } = useTheme();
  const { mobileNotification } = useMobileNotification();
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();

  const [isUpdatingSwitch, setIsUpdatingSwitch] = React.useState<boolean>(false);

  const sendCompensatedBoletoNotificationRef = React.useRef<HTMLInputElement>(null);
  const sendAdSenseAccountInsightNotificationRef = React.useRef<HTMLInputElement>(null);
  const sendNetProfitNotification = React.useRef<HTMLInputElement>(null);
  const sendFiveHourNetProfitNotificationRef = React.useRef<HTMLInputElement>(null);
  const sendLastDailyNetProfitNotificationRef = React.useRef<HTMLInputElement>(null);
  const sendGoalNotificationRef = React.useRef<HTMLInputElement>(null);
  const sendBlockedFacebookAdSenseAccountsNotificationRef = React.useRef<HTMLInputElement>(null);
  const sendsendAutomationFirstTimeRecoverNotificationRef = React.useRef<HTMLInputElement>(null);
  const sendsendAutomationRecoverNotificationRef = React.useRef<HTMLInputElement>(null);

  const handleSwitchChange = React.useCallback(
    async e => {
      e.persist();
      setIsUpdatingSwitch(true);
      const field = e.target.name;
      const { checked } = e.target;
      try {
        await mobileNotificationService.updateMobileNotification({
          storeAliasId,
          data: {
            [field]: checked,
          },
        });
      } catch (error) {
        toast.error('Ocorreu um erro ao atualizar a notificação. Tente novamente mais tarde!');
        e.target.checked = !checked;
      }

      setIsUpdatingSwitch(false);
    },
    [storeAliasId, toast],
  );

  return (
    <S.Wrapper>
      <S.TitleContainer>
        <BellSimple size={28} color={colors.gray[1]} />
        <S.Title>Notificações</S.Title>
      </S.TitleContainer>
      <S.SubTitle>Selecione as notificações que deseja receber da sua loja</S.SubTitle>

      <S.TargetNotificationChannelWrapper>
        <DeviceMobile size={20} color={colors.gray[1]} />
        <S.TargetNotificationChannelTitle>App Mobile</S.TargetNotificationChannelTitle>
      </S.TargetNotificationChannelWrapper>

      <S.NotificationWrapper>
        <S.NotificationTitleWrapper>
          <S.NotificationTitle>Boletos compensados</S.NotificationTitle>
          <S.NotificationSubTitle>
            Recebe uma notificação diariamente sobre os boletos compensados de sua loja
          </S.NotificationSubTitle>
        </S.NotificationTitleWrapper>
        <S.Switch
          name="send_compensated_boleto_notification"
          id="send_compensated_boleto_notification"
          onChange={handleSwitchChange}
          // isLoading={isUpdatingSwitch}
          defaultChecked={mobileNotification?.send_compensated_boleto_notification || false}
          disabled={isUpdatingSwitch}
          innerRef={sendCompensatedBoletoNotificationRef}
        />
      </S.NotificationWrapper>

      <S.NotificationWrapper>
        <S.NotificationTitleWrapper>
          <S.NotificationTitle>Gasto em marketing</S.NotificationTitle>
          <S.NotificationSubTitle>
            Recebe uma notificação diariamente sobre o gasto de marketing de sua loja
          </S.NotificationSubTitle>
        </S.NotificationTitleWrapper>
        <S.Switch
          name="send_ad_sense_account_insight_notification"
          id="send_ad_sense_account_insight_notification"
          onChange={handleSwitchChange}
          // isLoading={isUpdatingSwitch}
          defaultChecked={mobileNotification?.send_ad_sense_account_insight_notification || false}
          disabled={isUpdatingSwitch}
          innerRef={sendAdSenseAccountInsightNotificationRef}
        />
      </S.NotificationWrapper>

      <S.NotificationWrapper>
        <S.NotificationTitleWrapper>
          <S.NotificationTitle>Lucro semanal</S.NotificationTitle>
          <S.NotificationSubTitle>
            Receba uma notificação a cada sexta-feira a respeito do lucro de sua loja durante a
            semana
          </S.NotificationSubTitle>
        </S.NotificationTitleWrapper>
        <S.Switch
          name="send_net_profit_notification"
          id="send_net_profit_notification"
          onChange={handleSwitchChange}
          // isLoading={isUpdatingSwitch}
          defaultChecked={mobileNotification?.send_net_profit_notification || false}
          disabled={isUpdatingSwitch}
          innerRef={sendNetProfitNotification}
        />
      </S.NotificationWrapper>

      <S.NotificationWrapper>
        <S.NotificationTitleWrapper>
          <S.NotificationTitle>Lucro nas últimas horas</S.NotificationTitle>
          <S.NotificationSubTitle>
            Receba uma notificação a respeito do lucro do dia da sua loja. Essa notificação será
            enviada três vezes por dia, às 7h, 12h e 17h
          </S.NotificationSubTitle>
        </S.NotificationTitleWrapper>
        <S.Switch
          name="send_five_hour_net_profit_notification"
          id="send_five_hour_net_profit_notification"
          onChange={handleSwitchChange}
          // isLoading={isUpdatingSwitch}
          defaultChecked={mobileNotification?.send_five_hour_net_profit_notification || false}
          disabled={isUpdatingSwitch}
          innerRef={sendFiveHourNetProfitNotificationRef}
        />
      </S.NotificationWrapper>

      <S.NotificationWrapper>
        <S.NotificationTitleWrapper>
          <S.NotificationTitle>Lucro do dia</S.NotificationTitle>
          <S.NotificationSubTitle>
            Receba uma notificação do lucro diário da sua loja
          </S.NotificationSubTitle>
        </S.NotificationTitleWrapper>
        <S.Switch
          name="send_last_daily_net_profit_notification"
          id="send_last_daily_net_profit_notification"
          onChange={handleSwitchChange}
          // isLoading={isUpdatingSwitch}
          defaultChecked={mobileNotification?.send_last_daily_net_profit_notification || false}
          disabled={isUpdatingSwitch}
          innerRef={sendLastDailyNetProfitNotificationRef}
        />
      </S.NotificationWrapper>

      <S.NotificationWrapper>
        <S.NotificationTitleWrapper>
          <S.NotificationTitle>Meta diária</S.NotificationTitle>
          <S.NotificationSubTitle>
            Receba uma notificação para saber quando a meta da sua loja for atingida
          </S.NotificationSubTitle>
        </S.NotificationTitleWrapper>
        <S.Switch
          name="send_goal_notification"
          id="send_goal_notification"
          onChange={handleSwitchChange}
          // isLoading={isUpdatingSwitch}
          defaultChecked={mobileNotification?.send_goal_notification || false}
          disabled={isUpdatingSwitch}
          innerRef={sendGoalNotificationRef}
        />
      </S.NotificationWrapper>

      <S.NotificationWrapper>
        <S.NotificationTitleWrapper>
          <S.NotificationTitle>Conta bloqueada</S.NotificationTitle>
          <S.NotificationSubTitle>
            Receba uma notificação quando a sua conta de anúncio for bloqueada
          </S.NotificationSubTitle>
        </S.NotificationTitleWrapper>
        <S.Switch
          name="send_blocked_facebook_ad_sense_accounts_notification"
          id="send_blocked_facebook_ad_sense_accounts_notification"
          onChange={handleSwitchChange}
          // isLoading={isUpdatingSwitch}
          defaultChecked={
            mobileNotification?.send_blocked_facebook_ad_sense_accounts_notification || false
          }
          disabled={isUpdatingSwitch}
          innerRef={sendBlockedFacebookAdSenseAccountsNotificationRef}
        />
      </S.NotificationWrapper>

      <S.NotificationWrapper>
        <S.NotificationTitleWrapper>
          <S.NotificationTitle>Automações</S.NotificationTitle>
          <S.NotificationSubTitle>
            Receba uma notificação da sua primeira recuperação
          </S.NotificationSubTitle>
        </S.NotificationTitleWrapper>
        <S.Switch
          name="send_automation_first_time_recover_notification"
          id="send_automation_first_time_recover_notification"
          onChange={handleSwitchChange}
          // isLoading={isUpdatingSwitch}
          defaultChecked={
            mobileNotification?.send_automation_first_time_recover_notification || false
          }
          disabled={isUpdatingSwitch}
          innerRef={sendsendAutomationFirstTimeRecoverNotificationRef}
        />
      </S.NotificationWrapper>

      <S.NotificationWrapper>
        <S.NotificationTitleWrapper>
          <S.NotificationTitle>Automações</S.NotificationTitle>
          <S.NotificationSubTitle>
            Receba uma notificação quando tiver uma recuperação
          </S.NotificationSubTitle>
        </S.NotificationTitleWrapper>
        <S.Switch
          name="send_automation_recover_notification"
          id="send_automation_recover_notification"
          onChange={handleSwitchChange}
          // isLoading={isUpdatingSwitch}
          defaultChecked={mobileNotification?.send_automation_recover_notification || false}
          disabled={isUpdatingSwitch}
          innerRef={sendsendAutomationRecoverNotificationRef}
        />
      </S.NotificationWrapper>
    </S.Wrapper>
  );
};

export default Notifications;
