import React from 'react';
// import { HexColorPicker } from 'react-colorful';

import { IColorPickerProps } from '@domain/interfaces/dashboard/CustomValuesCategories/IColorPicker';

import * as S from './styles';

const ColorPicker: React.FC<IColorPickerProps> = ({ handleIsChoosingColor, onSelectColor }) => {
  const colorPickerRef = React.useRef<HTMLDivElement>(null);

  const onBlur = React.useCallback(() => {
    handleIsChoosingColor(false);
  }, [handleIsChoosingColor]);

  React.useEffect(() => {
    if (colorPickerRef?.current) {
      colorPickerRef.current.focus();
    }
  });

  return (
    <S.Wrapper ref={colorPickerRef} onBlur={onBlur}>
      <S.Arrow />
      <S.ColorPickerWrapper tabIndex={0}>
        <S.Color onClick={() => onSelectColor('#800000')} color="#800000" />
        <S.Color onClick={() => onSelectColor('#E50000')} color="#E50000" />
        <S.Color onClick={() => onSelectColor('#FF4081')} color="#FF4081" />
        <S.Color onClick={() => onSelectColor('#FF7FAB')} color="#FF7FAB" />
        <S.Color onClick={() => onSelectColor('#F900EA')} color="#F900EA" />
        <S.Color onClick={() => onSelectColor('#EA80FC')} color="#EA80FC" />
        <S.Color onClick={() => onSelectColor('#BF55EC')} color="#BF55EC" />
        <S.Color onClick={() => onSelectColor('#9B59B6')} color="#9B59B6" />
        <S.Color onClick={() => onSelectColor('#7C4DFF')} color="#7C4DFF" />
        <S.Color onClick={() => onSelectColor('#0231E8')} color="#0231E8" />
        <S.Color onClick={() => onSelectColor('#81B1FF')} color="#81B1FF" />
        <S.Color onClick={() => onSelectColor('#3397DD')} color="#3397DD" />
        <S.Color onClick={() => onSelectColor('#3082B7')} color="#3082B7" />
        <S.Color onClick={() => onSelectColor('#04A9F4')} color="#04A9F4" />
        <S.Color onClick={() => onSelectColor('#02BCD4')} color="#02BCD4" />
        <S.Color onClick={() => onSelectColor('#1BBC9C')} color="#1BBC9C" />
        <S.Color onClick={() => onSelectColor('#2ECD6F')} color="#2ECD6F" />
        <S.Color onClick={() => onSelectColor('#F9D900')} color="#F9D900" />
        <S.Color onClick={() => onSelectColor('#AF7E2E')} color="#AF7E2E" />
        <S.Color onClick={() => onSelectColor('#FF7800')} color="#FF7800" />
        <S.Color onClick={() => onSelectColor('#E65100')} color="#E65100" />
        <S.Color onClick={() => onSelectColor('#B5BCC2')} color="#B5BCC2" />
        <S.Color onClick={() => onSelectColor('#667684')} color="#667684" />
        {/* <S.CustomColor /> */}

        {/* <HexColorPicker color="#000" onChange={color => console.log(color)} /> */}
      </S.ColorPickerWrapper>
    </S.Wrapper>
  );
};

export default ColorPicker;
