import React from 'react';

import { IOrderTableRowProps } from '@domain/interfaces/dashboard/Orders/IOrderTableRow';

import { useOrders } from '@helpers/hooks/pages/dashboard/orders/useOrders';

import Table from '@components/common/core/Utils/Table';

import EditOrderModal from './EditOrdersModal';
import Order from './Data/Order';
import Product from './Data/Product';
import NetRevenue from './Data/NetRevenue';
import Cogs from './Data/Cogs';
import Shipping from './Data/Shipping';
import Fee from './Data/Fee';
import Tax from './Data/Tax';
import NetProfit from './Data/NetProfit';
import Status from './Data/Status';
import OrderSource from './Data/OrderSource';
import EditOrder from './Data/EditOrder';

const Row: React.FC<IOrderTableRowProps> = ({ order }) => {
  const { mutateOrders } = useOrders();

  const [isEditOrderModalOpen, setIsEditOrderModalOpen] = React.useState<boolean>(false);
  const [isHovering, setIsHovering] = React.useState<boolean>(false);

  const handleEditOrderModalOpen = React.useCallback(() => {
    setIsEditOrderModalOpen(currentState => !currentState);
  }, []);

  const onMouseEnter = React.useCallback(() => {
    setIsHovering(true);
  }, []);

  const onMouseLeave = React.useCallback(() => {
    setIsHovering(false);
  }, []);

  return (
    <>
      <Table.Row borderBottom onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Order order={order} />
        <Product order={order} />
        <NetRevenue order={order} />
        <Cogs order={order} />
        <Shipping order={order} />
        <Fee order={order} />
        <Tax order={order} />
        <NetProfit order={order} />
        <Status order={order} />
        <OrderSource order={order} />
        <EditOrder
          order={order}
          isHovering={isHovering}
          handleEditOrderModalOpen={handleEditOrderModalOpen}
        />
      </Table.Row>

      <EditOrderModal
        isOpen={isEditOrderModalOpen}
        toggle={handleEditOrderModalOpen}
        order={order}
        mutateOrders={mutateOrders}
      />
    </>
  );
};

export default Row;
