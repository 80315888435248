import styled from 'styled-components/macro';

import CustomTooltip from '@components/common/core/Utils/CustomTooltip';

export const Tooltip = styled(CustomTooltip)``;

export const TooltipTrigger = styled(CustomTooltip.Trigger)``;

export const TooltipContent = styled(CustomTooltip.Content)`
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray[1]};
  max-width: 260px;
  text-align: left;
  padding: 16px;
`;

export const TooltipArrow = styled(Tooltip.Arrow)`
  fill: ${({ theme }) => theme.colors.black.light};
`;
