import React from 'react';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import { ISubscriptionDataProvider } from '@domain/interfaces/dashboard/Subscription/ISubscriptionData';
import { ISubscriptionPlan } from '@domain/interfaces/subscription/ISubscription';

import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';

import addressService from '@services/pages/subscription/address';
import legalPersonService from '@services/pages/subscription/legalPerson';
import naturalPersonService from '@services/pages/subscription/naturalPerson';
import paymentMethodService from '@services/common/paymentMethod/paymentMethod';
import subscriptionService from '@services/pages/subscription/subscription';
import plansService from '@services/common/plans/plans';

const SubscriptionDataContext = React.createContext<ISubscriptionDataProvider | null>(null);

export const SubscriptionDataProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();
  const { subscription } = useStoreSubscription();

  const [
    isLoadingStoreSubscriptionPlan,
    setIsLoadingStoreSubscriptionPlan,
  ] = React.useState<boolean>(false);
  const [isStoreSubscriptionPlanError, setIsStoreSubscriptionPlanError] = React.useState<boolean>(
    false,
  );
  const [subscriptionPlan, setSubscriptionPlan] = React.useState<ISubscriptionPlan | undefined>(
    undefined,
  );

  const {
    address,
    error: addressError,
    isLoading: isLoadingAddress,
    isValidating: isValidatingAddress,
    mutate: mutateAddress,
  } = addressService.getAddress({ storeAliasId });
  const {
    legalPerson,
    error: legalPersonError,
    isLoading: isLoadingLegalPerson,
    isValidating: isValidatingLegalPerson,
    mutate: mutateLegalPerson,
  } = legalPersonService.getLegalPerson({ storeAliasId });
  const {
    naturalPerson,
    error: naturalPersonError,
    isLoading: isLoadingNaturalPerson,
    isValidating: isValidatingNaturalPerson,
    mutate: mutateNaturalPerson,
  } = naturalPersonService.getNaturalPerson({ storeAliasId });
  const {
    paymentMethod,
    error: paymentMethodError,
    isLoading: isLoadingPaymentMethod,
    isValidating: isValidatingPaymentMethod,
    mutate: mutatePaymentMethod,
  } = paymentMethodService.getPaymentMethod({ storeAliasId });
  const {
    plans,
    error: plansError,
    isLoading: isLoadingPlans,
    isValidating: isValidatingPlans,
    mutate: mutatePlans,
  } = plansService.listAllPlans({ storeAliasId });

  const loadSubscriptionPlan = React.useCallback(
    async (subscriptionAliasId: string) => {
      setIsLoadingStoreSubscriptionPlan(true);

      try {
        const { data: subscriptionPlanData } = await subscriptionService.getSubscriptionPlan({
          storeAliasId,
          subscriptionAliasId,
        });

        if (subscriptionPlanData) {
          setSubscriptionPlan(subscriptionPlanData.plan);
        }

        setIsLoadingStoreSubscriptionPlan(false);
      } catch {
        setIsLoadingStoreSubscriptionPlan(false);
        setIsStoreSubscriptionPlanError(true);
      }
    },
    [storeAliasId],
  );

  const mutateData = React.useCallback(async () => {
    const promises = [];

    if (addressError) promises.push(mutateAddress());

    if (legalPersonError) promises.push(mutateLegalPerson());

    if (naturalPersonError) promises.push(mutateNaturalPerson());

    if (paymentMethodError) promises.push(mutatePaymentMethod());

    if (isStoreSubscriptionPlanError) promises.push(loadSubscriptionPlan(subscription.alias_id));

    if (plansError) promises.push(mutatePlans());

    await Promise.all(promises);
  }, [
    addressError,
    legalPersonError,
    naturalPersonError,
    paymentMethodError,
    mutateAddress,
    mutateLegalPerson,
    mutateNaturalPerson,
    mutatePaymentMethod,
    isStoreSubscriptionPlanError,
    loadSubscriptionPlan,
    subscription,
    plansError,
    mutatePlans,
  ]);

  React.useEffect(() => {
    if (subscription) {
      loadSubscriptionPlan(subscription.alias_id);
    }
  }, [subscription, loadSubscriptionPlan]);

  const isLoadingData =
    isLoadingAddress ||
    isLoadingLegalPerson ||
    isLoadingNaturalPerson ||
    isLoadingPaymentMethod ||
    isLoadingStoreSubscriptionPlan ||
    isLoadingPlans;
  const isValidatingData =
    isValidatingAddress ||
    isValidatingLegalPerson ||
    isValidatingNaturalPerson ||
    isValidatingPaymentMethod ||
    isValidatingPlans;
  const isDataError =
    Boolean(addressError) ||
    Boolean(legalPersonError) ||
    Boolean(naturalPersonError) ||
    Boolean(paymentMethodError) ||
    Boolean(plansError) ||
    isStoreSubscriptionPlanError;

  return (
    <SubscriptionDataContext.Provider
      value={{
        address,
        paymentMethod,
        naturalPerson,
        legalPerson,
        isDataError,
        isLoadingData,
        isValidatingData,
        mutateData,
        subscriptionPlan,
        plans,
        mutatePaymentMethod,
      }}
    >
      {children}
    </SubscriptionDataContext.Provider>
  );
};

export const useSubscriptionData = (): ISubscriptionDataProvider => {
  const context = React.useContext(SubscriptionDataContext);

  if (!context) {
    throw new Error('useSubscriptionData must be used within SubscriptionDataProvider');
  }

  return context;
};
