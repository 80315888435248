import React from 'react';

import { IIntegrationCardProps } from '@domain/interfaces/dashboard/IntegrationCenter/IIntegrationCard';
import { ETextWeight } from '@domain/enums/components/EText';

import Tooltip from './Tooltip';

import * as S from './styles';

const IntegrationCard: React.FC<IIntegrationCardProps> = ({
  isActivated,
  title,
  iconColor,
  isDisabled,
  type,
  icon: Icon,
  onClick,
}) => {
  const isShopifyPlusYampiIntegration = title === 'Checkout Yampi + Loja Shopify';
  const isShopifyPlusCartpandaIntegration = title === 'Checkout Cartpanda + Loja Shopify';

  const getHeadingContent = (): JSX.Element => {
    if (isShopifyPlusYampiIntegration)
      return (
        <S.Heading weight={ETextWeight.REGULAR} isShopifyAlternative>
          Yampi
          <br />+ Shopify
        </S.Heading>
      );

    if (isShopifyPlusCartpandaIntegration)
      return (
        <S.Heading weight={ETextWeight.REGULAR} isShopifyAlternative>
          Cartpanda
          <br />+ Shopify
        </S.Heading>
      );

    return <S.Heading weight={ETextWeight.REGULAR}>{title}</S.Heading>;
  };

  const getTooltipContent = (): string => {
    if (isDisabled) {
      return `Só é possível integrar com uma loja virtual por loja. Para integrar com um novo canal de venda, crie uma nova loja.`;
    }

    return '';
  };

  return (
    <Tooltip content={getTooltipContent()} side="top">
      <S.Card onClick={onClick} isDisabled={isDisabled} disabled={isDisabled}>
        <S.IconAndNameWrapper>
          <S.IconWrapper isDisabled={isDisabled}>
            <Icon size={48} color={iconColor} />
          </S.IconWrapper>

          {getHeadingContent()}
        </S.IconAndNameWrapper>

        {isActivated && <S.ActivatedText onClick={onClick}>Detalhes</S.ActivatedText>}

        {!isActivated && (
          <S.NotActivatedText onClick={isDisabled ? undefined : onClick}>
            Instalar
          </S.NotActivatedText>
        )}
      </S.Card>
    </Tooltip>
  );
};

export default IntegrationCard;
