import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TabBase from '@components/common/core/Navigation/Tab';
import OptionBase from '@components/common/core/Navigation/Tab/Option';

export const Heading = styled(HeadingBase)`
  margin-left: 16px;
`;

export const Header = styled.div`
  margin-bottom: 40px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
`;

export const ContentWrapper = styled.div`
  max-width: 487px;
  width: 100%;
`;

export const Option = styled(OptionBase)``;

export const Tab = styled(TabBase)`
  margin-bottom: 48px;
`;
