import { EFilterType } from '@domain/enums/components/EFilter';
import { ETriggers } from '@domain/enums/dashboard/automations/ETriggers';
import { IFilterConfig } from '@domain/interfaces/components/IFilter';

export const AUTOMATIONS_MANAGER_CONFIG: Array<IFilterConfig> = [
  {
    field: 'type',
    label: 'Tipo de gatilho',
    type: EFilterType.SELECT,
    config: {
      placeholder: 'Encontrar um gatilho...',
      data: [
        {
          value: ETriggers.ABANDONED_CART,
          label: 'Carrinho Abandonado',
        },
        {
          value: ETriggers.BOLETO,
          label: 'Boleto Gerado',
        },
        {
          value: ETriggers.REJECTED_PAYMENT,
          label: 'Cartão Recusado',
        },
        {
          value: ETriggers.PIX,
          label: 'PIX Gerado',
        },
      ],
    },
  },
];
