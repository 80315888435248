import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import TextFieldBase from '@components/common/core/Inputs/TextField';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 320px;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 945px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Heading = styled(HeadingBase)`
  color: #ffffff;
`;

export const Description = styled(TextBase)`
  color: ${({ theme }) => theme.colors.white.dark};
`;

export const Input = styled(TextFieldBase)``;
