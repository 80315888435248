import React from 'react';

import { VariantHistoricProvider } from '@helpers/hooks/pages/dashboard/productCostDetails/variantHistoric';
import { IHistoricSidemodalWrapperProps } from '@domain/interfaces/dashboard/ProductCostDetails/IHistoricSidemodal';
import HistoricSidemodal from '../HistoricSidemodal';

const HistoricSidemodalWrapper: React.FC<IHistoricSidemodalWrapperProps> = ({
  isOpen,
  toggle,
  variant,
}) => {
  return (
    <VariantHistoricProvider>
      <HistoricSidemodal isOpen={isOpen} toggle={toggle} variant={variant} />
    </VariantHistoricProvider>
  );
};

export default HistoricSidemodalWrapper;
