import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Text = styled(TextBase)``;

export const IconWrapper = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddAdsCostButton = styled.div`
  background-color: unset;
  border: 1px solid transparent;
  padding: 4px 12px;
  height: 42px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.colors.font};
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const MoreOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`;

export const ActionButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.font};
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[6]};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.gray[6]};
    filter: brightness(0.9);
  }
`;

export const SyncAdsAndDatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  max-width: 100%;
  flex-wrap: wrap;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  flex-wrap: wrap;
  gap: 16px;
`;
