import React from 'react';

import * as yup from 'yup';

import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ECheckoutStep } from '@domain/enums/subscription/ECheckoutStep';
import { EPlanRecurrence, EStatus } from '@domain/enums/subscription/ESubscription';
import { EPersonType } from '@domain/enums/common/personType/EPersonType';
import { ICardTokenProps } from '@domain/interfaces/common/payment/IPayment';
import { ICheckoutProvider } from '@domain/interfaces/subscription/checkout/ICheckout';
import { IParams } from '@domain/interfaces/IParams';

import { useQuery } from '@helpers/hooks/useQuery';
import { useSubscription } from '@helpers/hooks/common/store/subscription/useSubscription';
import { useToast } from '@helpers/hooks/useToast';
import { useAddress } from '@helpers/hooks/common/store/address/useAddress';
import { usePersonalInfo } from '@helpers/hooks/common/store/personalInfo/usePersonalInfo';
import { useIugu } from '@helpers/hooks/useIugu';
import { usePayment } from '@helpers/hooks/common/store/payment/usePayment';
import { useConfig } from '@helpers/hooks/useConfig';
import { useDowngrade } from '@helpers/hooks/common/store/subscription/useDowngrade';
import { useCoupon } from '@helpers/hooks/pages/subscription/useCoupon';
import { useDate } from '@helpers/hooks/useDate';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import { ALL_PLANS } from '@constants/plans';

import { checkoutErrorMapper } from '@helpers/errors/subscription/checkoutMapper';
import { getCheckoutAddressTranslatedErrorMessage } from '@helpers/utils/errors/subscription/checkout/address';
import { getCheckoutPersonalInfoTranslatedErrorMessage } from '@helpers/utils/errors/subscription/checkout/personalInfo';

import { getAddressSchema } from '@helpers/validators/subscription/checkout/address';
import { getPersonalInfoSchema } from '@helpers/validators/subscription/checkout/personalInfo';
import { paymentSchema } from '@helpers/validators/subscription/checkout/payment';

import addressService from '@services/common/address/address';
import legalPersonService from '@services/common/legalPerson/legalPerson';
import naturalPersonService from '@services/common/naturalPerson/naturalPerson';
import { getCheckoutSubscribeTranslatedErrorMessage } from '@helpers/utils/errors/subscription/checkout/subscribe';

const CheckoutContext = React.createContext<ICheckoutProvider | null>(null);

export const CheckoutProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const query = useQuery();
  const iugu = useIugu('32100AB23A5B464C964437C238C1844D');

  const { checkoutStep: checkoutStepParam, storeAliasId } = useParams<IParams>();
  const { address, mutateAddress } = useAddress();
  const { legalPerson, naturalPerson, mutateLegalPerson, mutateNaturalPerson } = usePersonalInfo();
  const { subscription, plans } = usePayment();
  const { toast } = useToast();
  const { analytics, user } = useConfig();
  const { downgradeSubscription } = useDowngrade();
  const { selectedCoupon, handleRecurrenceType: handleCouponRecurrenceType } = useCoupon();
  const { differenceInDays, utcToZonedTime } = useDate();
  const { handleResyncData } = useSynchronization();

  const {
    selectedPlanIdentifier,
    createSubscription,
    updateSubscription,
    createSubscriptionByCheckoutLink,
    updateSubscriptionByCheckoutLink,
    selectedRecurrenceType,
  } = useSubscription();

  const hashQuery = query.get('hash');
  const planAliasIdQuery = query.get('plan_alias_id');
  // const allowedOrdersQuantity = query.get('allowed_orders_quantity');
  const identifierQuery = query.get('identifier');

  const [checkoutStep, setCheckoutStep] = React.useState<ECheckoutStep>(ECheckoutStep.ADDRESS);
  const [isSubmittingAddressForm, setIsSubmittingAddressForm] = React.useState<boolean>(false);
  const [isSubmittingPersonalInfoForm, setIsSubmittingPersonalInfoForm] = React.useState<boolean>(
    false,
  );
  const [isSubmittingPaymentForm, setIsSubmittingPaymentForm] = React.useState<boolean>(false);
  const [isSubscribing, setIsSubscribing] = React.useState<boolean>(false);
  const [cardToken, setCardToken] = React.useState<ICardTokenProps | undefined>(undefined);
  const [selectedInstallment, setSelectedInstallment] = React.useState<number>(1);
  const [hasAddressAutoFill, setHasAddressAutoFill] = React.useState<boolean>(false);
  const [personType, setPersonType] = React.useState<EPersonType>(EPersonType.LEGAL_PERSON);
  const [selectedPlan, setSelectedPlan] = React.useState<typeof ALL_PLANS[number] | undefined>(
    undefined,
  );
  const [recurrenceType, setRecurrenceType] = React.useState<EPlanRecurrence>(
    EPlanRecurrence.MONTHLY,
  );
  const [isTermsOfAdhesionModalOpen, setIsTermsOfAdhesionModalOpen] = React.useState<boolean>(
    false,
  );

  const getCurrentSchema = React.useCallback(() => {
    if (checkoutStepParam === ECheckoutStep.ADDRESS) return getAddressSchema(hasAddressAutoFill);

    if (checkoutStepParam === ECheckoutStep.PERSONAL_INFO) return getPersonalInfoSchema(personType);

    if (checkoutStepParam === ECheckoutStep.PAYMENT) return paymentSchema;

    return yup.object().shape({});
  }, [checkoutStepParam, hasAddressAutoFill, personType]);

  const currentSchema = getCurrentSchema();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
    getValues,
    reset,
    watch,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(currentSchema),
  });

  const handleTermsOfAdhesionModalOpen = React.useCallback(
    () => setIsTermsOfAdhesionModalOpen(state => !state),
    [],
  );

  const handleSelectedInstallment = React.useCallback(
    installment => setSelectedInstallment(installment),
    [],
  );

  const handlePersonType = React.useCallback(type => setPersonType(type), []);

  const handleAddressAutoFill = React.useCallback(state => setHasAddressAutoFill(state), []);

  const handleRecurrenceType = React.useCallback(
    newRecurrenceType => setRecurrenceType(newRecurrenceType),
    [],
  );

  const handleAddressStep = React.useCallback(
    async addressData => {
      setIsSubmittingAddressForm(true);

      try {
        if (address) {
          await addressService.updateAddress({ storeAliasId, data: addressData });
        } else {
          await addressService.createAddress({ storeAliasId, data: addressData });
        }

        await mutateAddress();

        setIsSubmittingAddressForm(false);

        analytics?.track(
          'Subscription Address Added',
          {
            email: user?.email,
          },
          { context: { groupId: storeAliasId } },
        );

        if (checkoutStep === ECheckoutStep.OVERVIEW) {
          history.push(`/${storeAliasId}/subscription/overview${window.location.search}`);
        } else {
          history.push(`/${storeAliasId}/subscription/personal-info${window.location.search}`);
        }
      } catch (error: any) {
        setIsSubmittingAddressForm(false);
        toast.error(getCheckoutAddressTranslatedErrorMessage(error));
      }
    },
    [address, history, toast, storeAliasId, mutateAddress, checkoutStep, analytics, user],
  );

  const handlePersonalInfoStep = React.useCallback(
    async personalInfoData => {
      setIsSubmittingPersonalInfoForm(true);

      try {
        if (personType === EPersonType.LEGAL_PERSON && !legalPerson) {
          await legalPersonService.createLegalPerson({ storeAliasId, data: personalInfoData });

          await mutateLegalPerson();
        }

        if (personType === EPersonType.LEGAL_PERSON && legalPerson) {
          await legalPersonService.updateLegalPerson({ storeAliasId, data: personalInfoData });

          await mutateLegalPerson();
        }

        if (personType === EPersonType.NATURAL_PERSON && !naturalPerson) {
          await naturalPersonService.createNaturalPerson({ storeAliasId, data: personalInfoData });

          await mutateNaturalPerson();
        }

        if (personType === EPersonType.NATURAL_PERSON && naturalPerson) {
          await naturalPersonService.updateNaturalPerson({ storeAliasId, data: personalInfoData });

          await mutateNaturalPerson();
        }

        setIsSubmittingPersonalInfoForm(false);

        analytics?.track(
          'Subscription Personal Data Added',
          {
            email: user?.email,
          },
          { context: { groupId: storeAliasId } },
        );

        if (checkoutStep === ECheckoutStep.OVERVIEW) {
          history.push(`/${storeAliasId}/subscription/overview${window.location.search}`);
        } else {
          history.push(`/${storeAliasId}/subscription/payment${window.location.search}`);
        }
      } catch (error: any) {
        setIsSubmittingPersonalInfoForm(false);
        toast.error(getCheckoutPersonalInfoTranslatedErrorMessage(error, personType));
      }
    },
    [
      history,
      legalPerson,
      mutateLegalPerson,
      mutateNaturalPerson,
      naturalPerson,
      personType,
      storeAliasId,
      toast,
      checkoutStep,
      analytics,
      user,
    ],
  );

  const handlePaymentStep = React.useCallback(
    async (formData, currentFormRef) => {
      setIsSubmittingPaymentForm(true);

      try {
        await iugu.setTestMode(true);

        const createdCardToken = await iugu.createPaymentToken(currentFormRef);

        if (formData.installment) {
          setSelectedInstallment(Number(formData.installment));
        }

        setCardToken(createdCardToken);
        setIsSubmittingPaymentForm(false);

        analytics?.track(
          'Subscription Payment Method Added',
          {
            email: user?.email,
          },
          { context: { groupId: storeAliasId } },
        );

        history.push(`/${storeAliasId}/subscription/overview${window.location.search}`);
      } catch (error: any) {
        if (error?.errors?.adblock) {
          toast.error(
            'Desabilite o AdBlock e depois recarregue a página para poder continuar essa etapa.',
          );
        } else if (error?.errors?.record_invalid === 'Validation failed: Number is invalid') {
          toast.error('Número do cartão inválido.');
        } else if (error?.errors?.verification_value === 'is_invalid') {
          toast.error('CVV / CID inválidos');
        } else if (error?.errors?.last_name === 'is_invalid') {
          toast.error('Sobrenome do cartão inválido.');
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          setIsSubmittingPaymentForm(false);
          throw error;
        }
      }
    },
    [iugu, toast, history, storeAliasId, analytics, user],
  );

  const trackTransactionError = React.useCallback(
    (providerError: string) => {
      analytics?.track(
        'Transaction Not Approved',
        {
          declined_reason: providerError,
          plan_name: selectedPlan?.name,
          plan_price: (selectedPlan as typeof ALL_PLANS[number])?.monthlyPrice,
          email: user?.email,
        },
        { context: { groupId: storeAliasId } },
      );
    },
    [storeAliasId, analytics, selectedPlan, user],
  );

  const getPlanIdentifier = React.useCallback((): string => {
    if (recurrenceType === EPlanRecurrence.MONTHLY) return selectedPlan?.identifier || '';

    if (recurrenceType === EPlanRecurrence.QUARTER) return selectedPlan?.quarterIdentifier || '';

    return selectedPlan?.semiAnualIdentifier || '';
  }, [selectedPlan, recurrenceType]);

  const subscribe = React.useCallback(async () => {
    if (!cardToken) {
      toast.error('Finalize a etapa de pagamento!');
      return;
    }

    if (!selectedPlan) {
      toast.error('Selecione um plano!');
      return;
    }

    setIsSubscribing(true);

    try {
      const previousSubscription = subscription;

      const isPreviousSubscriptionFree =
        subscription.plan?.identifier === 'free_monthly_v1' ||
        subscription.plan?.identifier === 'free_monthly_v2';

      const isPreviousSubscriptionPaid =
        subscription.status === EStatus.PAID && !subscription.plan?.identifier.includes('free');

      const isPreviousSubscriptionFreePlanActive =
        subscription.status !== EStatus.CANCELED && isPreviousSubscriptionFree;

      const isPreviousSubscriptionTrialEnded = subscription.status === EStatus.TRIAL_ENDED;

      const isPreviousSubscriptionCanceled = subscription.status === EStatus.CANCELED;

      const isPreviousSubscriptionPaymentFailed =
        subscription.status === EStatus.PROCESSING_PAYMENT_IN_CREATE_FAILED;

      const couponId = selectedCoupon ? selectedCoupon.id : undefined;

      const planIdentifier = getPlanIdentifier();

      if (
        hashQuery &&
        planAliasIdQuery &&
        isPreviousSubscriptionPaid &&
        !isPreviousSubscriptionFreePlanActive
      ) {
        const parsedHashQuery = hashQuery
          .replace(/p1L2u3S/g, '+')
          .replace(/s1L2a3S4h/g, '/')
          .replace(/e1Q2u3A4l/g, '=');

        await updateSubscriptionByCheckoutLink(
          storeAliasId,
          cardToken,
          planIdentifier,
          planAliasIdQuery,
          parsedHashQuery,
          selectedInstallment,
        );
      }

      if (
        hashQuery &&
        planAliasIdQuery &&
        !isPreviousSubscriptionPaid &&
        (isPreviousSubscriptionFreePlanActive ||
          isPreviousSubscriptionTrialEnded ||
          isPreviousSubscriptionCanceled ||
          isPreviousSubscriptionPaymentFailed)
      ) {
        const parsedHashQuery = hashQuery
          .replace(/p1L2u3S/g, '+')
          .replace(/s1L2a3S4h/g, '/')
          .replace(/e1Q2u3A4l/g, '=');

        await createSubscriptionByCheckoutLink(
          storeAliasId,
          cardToken,
          planAliasIdQuery,
          parsedHashQuery,
          selectedInstallment,
        );
      }

      if (!hashQuery && !isPreviousSubscriptionFreePlanActive && isPreviousSubscriptionPaid) {
        await updateSubscription(
          storeAliasId,
          subscription.alias_id,
          cardToken,
          subscription.plan?.identifier,
          planIdentifier,
          selectedInstallment,
          couponId,
        );
      }

      if (
        !hashQuery &&
        (!isPreviousSubscriptionPaid ||
          isPreviousSubscriptionFreePlanActive ||
          isPreviousSubscriptionPaymentFailed)
      ) {
        await createSubscription(
          storeAliasId,
          cardToken,
          planIdentifier,
          selectedInstallment,
          couponId,
        );
      }

      if (recurrenceType === EPlanRecurrence.MONTHLY) {
        const dataLayer = (window as Record<string, any>)?.dataLayer;

        if (dataLayer) {
          dataLayer.push({
            event: 'Subscription Started',
            subscription_recurrence: recurrenceType,
            subscription_price: selectedPlan?.monthlyPrice,
            subscription_plan: selectedPlan?.identifier,
          });
        }

        const lastSubscriptionDay =
          previousSubscription.canceled_at || previousSubscription.period_ended_at;

        const lastSubscriptionDayToDate = utcToZonedTime(lastSubscriptionDay);

        const difference = differenceInDays(lastSubscriptionDayToDate, new Date()) + 1;

        const daysToSync = difference < 30 ? difference : 30;

        handleResyncData({
          storeAliasId,
          daysToSync,
        });
      }

      await downgradeSubscription(storeAliasId);

      history.push(`/${storeAliasId}/checkout-finished?recurrence_type=${recurrenceType}`);

      setIsSubscribing(false);
    } catch (error: any) {
      setIsSubscribing(false);

      if (error?.response?.data?.integration_message) {
        toast.error(checkoutErrorMapper(error, trackTransactionError));
      }

      if (error?.response?.data?.message) {
        toast.error(getCheckoutSubscribeTranslatedErrorMessage(error));
      }

      history.push(`/${storeAliasId}/subscription/payment${window.location.search}`);

      throw error;
    }
  }, [
    cardToken,
    createSubscription,
    updateSubscription,
    getPlanIdentifier,
    selectedInstallment,
    selectedPlan,
    storeAliasId,
    subscription,
    toast,
    trackTransactionError,
    hashQuery,
    planAliasIdQuery,
    createSubscriptionByCheckoutLink,
    updateSubscriptionByCheckoutLink,
    downgradeSubscription,
    history,
    recurrenceType,
    selectedCoupon,
    differenceInDays,
    handleResyncData,
    utcToZonedTime,
  ]);

  React.useEffect(() => {
    if (
      checkoutStepParam !== ECheckoutStep.ADDRESS &&
      checkoutStepParam !== ECheckoutStep.PERSONAL_INFO &&
      checkoutStepParam !== ECheckoutStep.PAYMENT &&
      checkoutStepParam !== ECheckoutStep.OVERVIEW
    ) {
      history.push(`/${storeAliasId}/checkout/address`);
    }
  }, [checkoutStepParam, history, storeAliasId]);

  React.useEffect(() => {
    if (selectedRecurrenceType) {
      setRecurrenceType(selectedRecurrenceType);
    }
  }, [selectedRecurrenceType]);

  React.useEffect(() => {
    if (address && (legalPerson || naturalPerson) && cardToken) {
      setCheckoutStep(ECheckoutStep.OVERVIEW);
    }

    if (address && (legalPerson || naturalPerson) && !cardToken) {
      setCheckoutStep(ECheckoutStep.PAYMENT);
    }

    if (!legalPerson && !naturalPerson) {
      setCheckoutStep(ECheckoutStep.PERSONAL_INFO);
    }

    if (!address) {
      setCheckoutStep(ECheckoutStep.ADDRESS);
    }
  }, [address, cardToken, legalPerson, naturalPerson]);

  React.useEffect(() => {
    if (!plans) return;

    if (
      !hashQuery &&
      !identifierQuery &&
      !selectedPlanIdentifier &&
      !selectedPlan &&
      !planAliasIdQuery
    ) {
      history.push(`/${storeAliasId}/pricing`);
    }

    const foundPlan = plans.find(plan => plan.alias_id === planAliasIdQuery);
    const foundPlanFromQuery = ALL_PLANS.find(plan => plan.identifier === identifierQuery);
    const foundPlanFromSelectedPlan = ALL_PLANS.find(
      plan =>
        plan.identifier === selectedPlanIdentifier ||
        plan.quarterIdentifier === selectedPlanIdentifier ||
        plan.semiAnualIdentifier === selectedPlanIdentifier,
    );
    const foundPlanFromHashQuery = ALL_PLANS.find(
      plan =>
        plan.identifier === foundPlan?.identifier ||
        plan.quarterIdentifier === foundPlan?.identifier ||
        plan.semiAnualIdentifier === foundPlan?.identifier,
    );

    if (foundPlanFromHashQuery) {
      setSelectedPlan(foundPlanFromHashQuery);
    }

    if (foundPlanFromQuery) setSelectedPlan(foundPlanFromQuery);

    if (foundPlanFromSelectedPlan) setSelectedPlan(foundPlanFromSelectedPlan);

    if (identifierQuery && !foundPlanFromQuery) {
      history.push(`/${storeAliasId}/pricing`);
    }

    if (selectedPlanIdentifier && !foundPlanFromSelectedPlan) {
      history.push(`/${storeAliasId}/pricing`);
    }

    if (
      ((hashQuery && !planAliasIdQuery) || (!hashQuery && planAliasIdQuery)) &&
      !foundPlanFromHashQuery
    ) {
      history.push(`/${storeAliasId}/pricing`);
    }
  }, [
    hashQuery,
    identifierQuery,
    selectedPlanIdentifier,
    storeAliasId,
    history,
    selectedPlan,
    plans,
    planAliasIdQuery,
  ]);

  React.useEffect(() => {
    if (!plans) return;

    if (planAliasIdQuery) {
      const foundPlan = plans.find(plan => plan.alias_id === planAliasIdQuery);

      if (foundPlan?.interval === 3) setRecurrenceType(EPlanRecurrence.QUARTER);

      if (foundPlan?.interval === 6) setRecurrenceType(EPlanRecurrence.SEMIANUAL);
    }
  }, [planAliasIdQuery, plans]);

  React.useEffect(() => {
    handleCouponRecurrenceType(recurrenceType);
  }, [handleCouponRecurrenceType, recurrenceType]);

  const isAddressStep = checkoutStep === ECheckoutStep.ADDRESS;
  const isPersonalInfoStep = checkoutStep === ECheckoutStep.PERSONAL_INFO;
  const isPaymentStep = checkoutStep === ECheckoutStep.PAYMENT;
  const isOverviewStep = checkoutStep === ECheckoutStep.OVERVIEW;

  return (
    <CheckoutContext.Provider
      value={{
        checkoutStep,
        isAddressStep,
        isPaymentStep,
        isPersonalInfoStep,
        isOverviewStep,
        handleRecurrenceType,
        recurrenceType,
        selectedPlan,
        handleSubmit,
        register,
        setValue,
        handleAddressAutoFill,
        hasAddressAutoFill,
        clearErrors,
        getValues,
        errors,
        handlePersonType,
        personType,
        reset,
        watch,
        handleAddressStep,
        isSubmittingAddressForm,
        isSubmittingPersonalInfoForm,
        handlePersonalInfoStep,
        handlePaymentStep,
        isSubmittingPaymentForm,
        selectedInstallment,
        cardToken,
        handleSelectedInstallment,
        isSubscribing,
        subscribe,
        handleTermsOfAdhesionModalOpen,
        isTermsOfAdhesionModalOpen,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckout = (): ICheckoutProvider => {
  const context = React.useContext(CheckoutContext);

  if (!context) {
    throw new Error('useCheckout must be used within CheckoutProvider');
  }

  return context;
};
