import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ImageBase from '@components/common/core/DataDisplay/Image';

export const Image = styled(ImageBase)`
  /* max-width: 296px; */
  width: 100%;
`;

export const SeePlansButton = styled.button`
  height: 42px;
  background: linear-gradient(229deg, #538bf0 0%, #2dc7ed 100%);
  width: 100%;
  border: unset;
  border-radius: 5px;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.gray[8]};
  font-weight: 700;
  font-size: 1rem;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const DescriptionText = styled(TextBase)`
  display: inline-block;
  width: 100%;
  line-height: 130%;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`;

export const Title = styled(HeadingBase)`
  display: inline-block;
  width: 100%;
  margin-bottom: 12px;
`;

export const DescriptionWrapper = styled.div`
  padding: 0px 12px 12px 12px;
`;

export const Content = styled.div`
  max-width: 504px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 12px;
  box-sizing: border-box;
`;

export const Modal = styled(ModalBase)`
  padding: 0;
`;
