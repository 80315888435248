import React from 'react';

import { useParams } from 'react-router-dom';
import { IParams } from '@domain/interfaces/IParams';
import { IMobileNotificationProvider } from '@domain/interfaces/dashboard/Profile/IMobileNotification';

import mobileNotificationService from '@services/pages/dashboard/profile/mobileNotification';

const MobileNotificationContext = React.createContext<IMobileNotificationProvider | null>(null);

export const MobileNotificationProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();

  const {
    mobileNotification,
    mutate: mutateMobileNotifications,
    isLoading: isLoadingMobileNotifications,
    isValidating: isValidatingMobileNotifications,
  } = mobileNotificationService.getMobileNotifications({ storeAliasId });

  return (
    <MobileNotificationContext.Provider
      value={{
        mobileNotification,
        mutateMobileNotifications,
        isLoadingMobileNotifications,
        isValidatingMobileNotifications,
      }}
    >
      {children}
    </MobileNotificationContext.Provider>
  );
};

export const useMobileNotification = (): IMobileNotificationProvider => {
  const context = React.useContext(MobileNotificationContext);

  if (!context) {
    throw new Error('useMobileNotification must be used within MobileNotificationContext');
  }

  return context;
};
