import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import LabelBase from '@components/common/core/DataDisplay/Label';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';

export const AlertText = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
  max-width: 438px;
`;

export const WarningIconWrapper = styled.div`
  min-width: 18px;
`;

export const AlertWarning = styled.div`
  background-color: #322e2a;
  max-width: 487px;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 5px;
  margin-bottom: 24px;
  box-sizing: border-box;
`;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Input = styled(TextFieldBase)`
  width: 100%;
`;

export const Label = styled(LabelBase)`
  font-weight: 700;
`;

export const TutorialText = styled(TextBase)``;

export const TutorialHeading = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const TutorialWrapper = styled.div`
  margin-bottom: 40px;
`;

export const FunctionalityDescriptionText = styled(TextBase)``;

export const FunctionalityDescriptionHeading = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const FunctionalityDescriptionWrapper = styled.div`
  margin-bottom: 48px;
`;

export const Heading = styled(HeadingBase)`
  margin-left: 10px;
`;

export const Header = styled.div`
  margin-bottom: 40px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
`;

export const ContentWrapper = styled.div`
  max-width: 487px;
  width: 100%;
`;
