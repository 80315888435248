import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Link = styled.a`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.green.default};
  font-weight: 700;
  text-decoration: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
    text-decoration: underline;
  }

  &:active {
    filter: brightness(0.9);
    text-decoration: underline;
  }
`;

export const StartOnboardingButton = styled(ButtonBase)`
  white-space: nowrap;

  @media only screen and (max-width: 1048px) {
    width: 100%;
  }
`;

export const Description = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
  line-height: 120%;
  font-size: 0.875rem;
`;

export const Title = styled(HeadingBase)`
  line-height: 100%;
`;

export const IconWrapper = styled.div``;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const IconAndDescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #122427;
  gap: 48px;
  justify-content: space-between;
  margin-bottom: 20px;

  @media only screen and (max-width: 1048px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;
