import { ApiService } from '@services/api';
import { LONG_API_DOMAIN } from '@constants/api';
import {
  ISyncTaboolaCampaigns,
  IUpdateTaboolaCampaign,
} from '@domain/interfaces/dashboard/AdSense/Taboola/ITaboolaCampaign';

export class TaboolaCampaignService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public syncTaboolaCampaigns({ storeAliasId }: ISyncTaboolaCampaigns): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/taboola/ad-sense-campaigns/sync`,
    );
  }

  public updateTaboolaCampaign({
    adSenseCampaignAliasId,
    data,
    productAliasId,
    storeAliasId,
  }: IUpdateTaboolaCampaign): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/taboola/ad-sense-campaigns/${adSenseCampaignAliasId}`,
      { ...data },
    );
  }
}

const taboolaCampaignService = new TaboolaCampaignService(LONG_API_DOMAIN);

export default taboolaCampaignService;
