import styled from 'styled-components/macro';

import ButtonBase from '@components/common/core/Inputs/Button';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Container = styled.div`
  max-width: 1262px;
  width: 100%;
  position: relative;
`;

export const Header = styled.div`
  height: 393px;
  background-image: url('https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/products-kit/kits.svg');
  background-position: center;
  background-repeat: no-repeat;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(TextBase)`
  font-size: 2.25rem;
  text-align: center;
`;

export const CreateKitButton = styled(ButtonBase)`
  margin-top: 30px;

  & > span {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;
