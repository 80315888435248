import React from 'react';

import { useFinancialDetails } from '@helpers/hooks/pages/dashboard/financialDetails/useFinancialDetails';
import {
  IColumnProps,
  IReducedColumns,
} from '@domain/interfaces/dashboard/FinancialDetails/IFinancialDetails';
import { numberFormatter } from '@helpers/masks';
import { useDate } from '@helpers/hooks/useDate';

import * as S from './styles';

const TableBody: React.FC = () => {
  const randomArray = Array.from(Array(30).keys());
  const { format } = useDate();
  const { columnsConfig, financialTableByDay } = useFinancialDetails();

  const reducedColumnsConfig: IReducedColumns = columnsConfig.reduce(
    (obj, item: IColumnProps) => ({
      ...obj,
      [item.value]: { label: item.label, is_active: item.is_active },
    }),
    {},
  );

  const parsePercentageText = React.useCallback(percentage => {
    if (percentage < 0) {
      const positivePercentage = percentage * -1;

      return `-${numberFormatter(positivePercentage, 1)}`;
    }

    return numberFormatter(percentage, 1);
  }, []);

  return (
    <S.Table.Body>
      {financialTableByDay.map((day, index) => {
        const [yearValue, monthValue, dayValue] = day.date.split('-');

        return (
          <S.Table.Row key={day.date} borderBottom={index + 1 !== randomArray.length}>
            <S.Table.Data>
              <S.DateText>
                {format(
                  new Date(Number(yearValue), Number(monthValue) - 1, Number(dayValue)),
                  'dd/MM',
                )}
              </S.DateText>
            </S.Table.Data>
            {reducedColumnsConfig?.gross_revenue.is_active && (
              <S.Table.Data>
                <S.DataText highlight right>
                  {`R$ ${numberFormatter(day.gross_revenue.amount, 2)}`}
                </S.DataText>
              </S.Table.Data>
            )}
            {reducedColumnsConfig?.pending_orders.is_active && (
              <S.Table.Data>
                <S.DataText right>{`R$ ${numberFormatter(
                  day.pending_orders.amount,
                  2,
                )}`}</S.DataText>
              </S.Table.Data>
            )}
            {reducedColumnsConfig?.in_analysis_orders.is_active && (
              <S.Table.Data>
                <S.DataText right>
                  {`R$ ${numberFormatter(day.in_analysis_orders.amount, 2)}`}
                </S.DataText>
              </S.Table.Data>
            )}
            {reducedColumnsConfig?.no_information_orders.is_active && (
              <S.Table.Data>
                <S.DataText right>
                  {`R$ ${numberFormatter(day.no_information_orders.amount, 2)}`}
                </S.DataText>
              </S.Table.Data>
            )}
            {reducedColumnsConfig?.discount_revenue.is_active && (
              <S.Table.Data>
                <S.DataText right>
                  {`R$ ${numberFormatter(day.discount_revenue.amount, 2)}`}
                </S.DataText>
              </S.Table.Data>
            )}
            {reducedColumnsConfig?.charged_back_revenue.is_active && (
              <S.Table.Data>
                <S.DataText right>
                  {`R$ ${numberFormatter(day.charged_back_revenue.amount, 2)}`}
                </S.DataText>
              </S.Table.Data>
            )}
            {reducedColumnsConfig?.refund_revenue.is_active && (
              <S.Table.Data>
                <S.DataText right>{`R$ ${numberFormatter(
                  day.refund_revenue.amount,
                  2,
                )}`}</S.DataText>
              </S.Table.Data>
            )}
            {reducedColumnsConfig?.net_revenue.is_active && (
              <S.Table.Data>
                <S.DataText highlight right>
                  {`R$ ${numberFormatter(day.net_revenue.amount, 2)}`}
                </S.DataText>
              </S.Table.Data>
            )}
            {reducedColumnsConfig?.cogs.is_active && (
              <S.Table.Data>
                <S.DataText right>{`R$ ${numberFormatter(day.cogs.amount, 2)}`}</S.DataText>
              </S.Table.Data>
            )}
            {reducedColumnsConfig?.marketing.is_active && (
              <S.Table.Data>
                <S.DataText right>{`R$ ${numberFormatter(day.marketing.amount, 2)}`}</S.DataText>
              </S.Table.Data>
            )}
            {reducedColumnsConfig?.sales_fee.is_active && (
              <S.Table.Data>
                <S.DataText right>{`R$ ${numberFormatter(day.sales_fee.amount, 2)}`}</S.DataText>
              </S.Table.Data>
            )}
            {reducedColumnsConfig?.gateway_fee.is_active && (
              <S.Table.Data>
                <S.DataText right>{`R$ ${numberFormatter(day.gateway_fee.amount, 2)}`}</S.DataText>
              </S.Table.Data>
            )}
            {reducedColumnsConfig?.shipping_cost_owner.is_active && (
              <S.Table.Data>
                <S.DataText right>{`R$ ${numberFormatter(
                  day.shipping_cost_owner.amount,
                  2,
                )}`}</S.DataText>
              </S.Table.Data>
            )}
            {reducedColumnsConfig?.tax.is_active && (
              <S.Table.Data>
                <S.DataText right>{`R$ ${numberFormatter(day.tax.amount, 2)}`}</S.DataText>
              </S.Table.Data>
            )}
            {reducedColumnsConfig?.other_spends.is_active && (
              <S.Table.Data>
                <S.DataText right>{`R$ ${numberFormatter(day.other_spends.amount, 2)}`}</S.DataText>
              </S.Table.Data>
            )}
            {reducedColumnsConfig?.financial_income.is_active && (
              <S.Table.Data>
                <S.DataText right>{`R$ ${numberFormatter(
                  day.financial_income.amount,
                  2,
                )}`}</S.DataText>
              </S.Table.Data>
            )}
            {reducedColumnsConfig?.net_profit.is_active && (
              <S.Table.Data>
                <S.NetProfitText isPositive={day.net_profit.amount >= 0}>{`R$ ${numberFormatter(
                  day.net_profit.amount,
                  2,
                )}`}</S.NetProfitText>
              </S.Table.Data>
            )}
            <S.Table.Data>
              <S.DataText right>
                {`${parsePercentageText(day.net_profit.growth)}%`}
                <S.PercentageArrow isPositive={day.net_profit.growth >= 0} />
              </S.DataText>
            </S.Table.Data>
          </S.Table.Row>
        );
      })}
    </S.Table.Body>
  );
};

export default TableBody;
