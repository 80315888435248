import { AxiosResponse } from 'axios';

import { IAddUtmScriptProps } from '@domain/interfaces/dashboard/IntegrationCenter/Marketplace/Shopify/IShopifyScript';

import { ApiService } from '@services/api';

import { API_DOMAIN } from '@constants/api';

export class ShopifyScriptService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public addUtmScript({ storeAliasId }: IAddUtmScriptProps): Promise<AxiosResponse<void>> {
    return this.apiService.post<void>(
      `/api/v1/users/stores/${storeAliasId}/shopify-credentials/utm-script`,
    );
  }
}

const shopifyScriptService = new ShopifyScriptService(API_DOMAIN);

export default shopifyScriptService;
