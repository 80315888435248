import styled from 'styled-components/macro';

export const VariantSkuWrapper = styled.div`
  max-width: 456px;
  width: 100%;
`;

export const VariantNameWrapper = styled.div`
  max-width: 224px;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  width: 100%;
`;

export const ImageWrapper = styled.div`
  min-width: 48px;
  min-height: 48px;
  border-radius: 50%;
  overflow: hidden;

  & > span > span {
    line-height: unset;
  }
`;

export const VariantWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Wrapper = styled.div`
  & > div {
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[4]};
  }

  & > div:last-child {
    border-bottom: unset;
  }
`;
