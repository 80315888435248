import React from 'react';
import { useTheme } from 'styled-components/macro';
import { ChevronUp, ChevronDown, SimpleClose } from '@profitfy/pakkun-icons';

import { IHeaderCellProps } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IHeaderCell';

import { useAdsManager } from '@helpers/hooks/pages/dashboard/adsManager/useAdsManager';
import { useCampaigns } from '@helpers/hooks/pages/dashboard/adsManager/useCampaigns';

import UtmBadge from './UtmBadge';
import CheckboxContent from '../CheckboxContent';

import * as S from './styles';

const HeaderCell: React.FC<IHeaderCellProps> = ({ column, index }) => {
  const theme = useTheme();
  const { tableColumns, columnsSize, handleTableColumns } = useAdsManager();
  const {
    onHeaderMouseDown,
    tableElementRef,
    activeTableIndex,
    campaignsTableSort,
    handleCampaignsTableSort,
  } = useCampaigns();

  const [isHovering, setIsHovering] = React.useState<boolean>(false);

  const onMouseEnter = React.useCallback(() => setIsHovering(true), []);

  const onMouseLeave = React.useCallback(() => setIsHovering(false), []);

  const onSortClick = React.useCallback(() => {
    if (column.isSortable) {
      if (campaignsTableSort?.id === column.id) {
        const newSort = campaignsTableSort.sort === 'DESC' ? 'ASC' : 'DESC';

        handleCampaignsTableSort({
          id: column.id,
          sort: newSort,
        });
      } else {
        handleCampaignsTableSort({
          id: column.id,
          sort: 'ASC',
        });
      }
    }
  }, [campaignsTableSort, column, handleCampaignsTableSort]);

  const onRemoveColumnClick = React.useCallback(
    event => {
      event.stopPropagation();

      const newTableColumns = tableColumns.map(tableColumn => {
        if (tableColumn.id === column.id) {
          return {
            ...tableColumn,
            isActive: !tableColumn.isActive,
          };
        }

        return tableColumn;
      });

      handleTableColumns(newTableColumns);
    },
    [column, tableColumns, handleTableColumns],
  );

  const { id, content, ref, contentAlign } = column;
  const width = columnsSize[column.id];

  const tableHeight = tableElementRef.current?.offsetHeight || 0;

  const isSortSelected = campaignsTableSort?.id === column.id;

  return (
    <S.HeaderCell
      ref={ref}
      key={id}
      contentAlign={contentAlign}
      style={{ zIndex: theme.zIndex.thirdLayer + (tableColumns.length - index) }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <S.HeaderCellContent style={{ width }} onClick={onSortClick} isSortable={column.isSortable}>
        {((column.isSortable && isHovering) || isSortSelected) && (
          <S.SortableWrapper>
            {isSortSelected && campaignsTableSort?.sort === 'DESC' ? (
              <ChevronUp size={16} />
            ) : (
              <ChevronDown size={16} />
            )}
          </S.SortableWrapper>
        )}

        <S.ContentWrapper>
          {column.id === 'checkbox' ? (
            <CheckboxContent />
          ) : (
            <>
              <S.Text isSortSelected={isSortSelected}>{content}</S.Text>
              {column.isUtmColumn && <UtmBadge />}
            </>
          )}
        </S.ContentWrapper>

        {column.isOptional && isHovering && (
          <S.RemoveColumnButton onClick={onRemoveColumnClick}>
            <SimpleClose size={12} color={theme.colors.gray[1]} />
          </S.RemoveColumnButton>
        )}
      </S.HeaderCellContent>

      {column.isResizable && (
        <S.ResizableElement
          tableHeight={tableHeight}
          aria-hidden
          onMouseDown={() => onHeaderMouseDown(index)}
          isPressing={activeTableIndex === index}
        />
      )}
    </S.HeaderCell>
  );
};

export default HeaderCell;
