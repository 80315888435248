import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

import * as Slider from '@radix-ui/react-slider';

export const SliderRoot = styled(Slider.Root)`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
`;

export const SliderTrack = styled(Slider.Track)`
  background-color: ${({ theme }) => theme.colors.gray[4]};
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;
  height: 3px;
`;

export const SliderRange = styled(Slider.Range)`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.gray[1]};
  border-radius: 9999px;
  height: 100%;
`;

export const SliderThumb = styled(Slider.Thumb)`
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[1]};
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 431px;
  height: 48px;
  background: ${({ theme }) => theme.colors.background[2]};
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  gap: 12px;
  box-sizing: border-box;

  @media only screen and (max-width: 955px) {
    margin-bottom: 14px;
  }
`;

export const Audio = styled.audio``;

export const PlayButton = styled.button`
  background: unset;
  padding: unset;
  border: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const MicrophoneButton = styled.button`
  background: unset;
  padding: unset;
  border: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const CurrentTime = styled(TextBase)`
  color: #ffffff;
  line-height: 150%;
  font-weight: 500;
`;

export const DisplayDuration = styled.span``;
