import React from 'react';

import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';

import * as S from './styles';

const Unlimited: React.FC = () => {
  const { subscription } = useStoreSubscription();

  const currentConsumption = subscription.current_orders_quantity;

  return (
    <S.CardWrapper>
      <S.Content>
        <S.PlanNameAndPricingWrapper>
          <S.TitleHeader>
            <S.PlanName>Unlimited</S.PlanName>
          </S.TitleHeader>
        </S.PlanNameAndPricingWrapper>

        <S.Divider />

        <S.OrderConsumptionWrapper>
          <S.Description>Consumo de pedidos</S.Description>

          <S.Consumption>
            <S.TotalConsumption>
              <S.CurrentConsumption style={{ width: '100%' }} />
            </S.TotalConsumption>

            <S.ConsumptionValuesWrapper>
              <S.CurrentConsumptionText>{currentConsumption}</S.CurrentConsumptionText>
              <S.MaxConsumptionText>∞</S.MaxConsumptionText>
            </S.ConsumptionValuesWrapper>
          </S.Consumption>
        </S.OrderConsumptionWrapper>
      </S.Content>
    </S.CardWrapper>
  );
};

export default Unlimited;
