import React from 'react';
import { Warning } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';

import { ETextSize } from '@domain/enums/components/EText';
import { INoScopesModalProps } from '@domain/interfaces/integrations/storeProvider/shopify/IShopify';
import { IParams } from '@domain/interfaces/IParams';

import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const NoScopesModal: React.FC<INoScopesModalProps> = ({ isOpen, toggle, missingScopes }) => {
  const theme = useTheme();
  const { storeAliasId } = useParams<IParams>();
  const { analytics } = useConfig();

  const trackArticleClick = React.useCallback(() => {
    analytics?.track(
      'Shopify Integration Article Clicked',
      {
        click_source: 'no_scope_modal',
      },
      { context: { groupId: storeAliasId } },
    );
  }, [analytics, storeAliasId]);

  const onShopifyTutorialClick = React.useCallback(() => {
    trackArticleClick();
    toggle();
  }, [toggle, trackArticleClick]);

  React.useEffect(() => {
    if (isOpen) {
      analytics?.track(
        'Shopify Integration Scope Error Viewed',
        {},
        { context: { groupId: storeAliasId } },
      );
    }
  }, [isOpen, analytics, storeAliasId]);

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.HeadingWrapper>
        <Warning size={24} color={theme.colors.danger.default} outline />
        <S.Heading>Existem alguns escopos não cadastrados</S.Heading>
      </S.HeadingWrapper>

      <S.ScopesWrapper>
        <S.ScopesTitle>escopos não cadastrados:</S.ScopesTitle>

        <S.MissingScopesWrapper>
          {missingScopes.map(scope => {
            return <S.MissingScope size={ETextSize.B5}>{scope}</S.MissingScope>;
          })}
        </S.MissingScopesWrapper>
      </S.ScopesWrapper>

      <S.Description size={ETextSize.B5}>
        Caso tenha alguma dúvida sobre como realizar a <br /> integração com Shopify, você pode
        assistir o video <br /> tutorial abaixo ou{' '}
        <S.Link
          href="https://ajuda.profitfy.me/pt-BR/articles/5901324-como-integrar-com-a-loja-shopify"
          target="_blank"
          onClick={trackArticleClick}
        >
          clicar aqui para acessar o artigo.
        </S.Link>
      </S.Description>

      <S.ShopifyTutorialIntegrationWrapper
        href="https://ajuda.profitfy.me/pt-BR/articles/5901324-como-integrar-com-a-loja-shopify"
        target="_blank"
        onClick={onShopifyTutorialClick}
      >
        <S.ShopifyTutorialIntegrationImg
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/integrations/shopify/shopify-how-to-integrate.jpg"
          alt="Profitfy Shopify Integration"
        />
      </S.ShopifyTutorialIntegrationWrapper>

      <S.UnderstoodButton onClick={toggle}>Entendi</S.UnderstoodButton>
    </S.Modal>
  );
};

export default NoScopesModal;
