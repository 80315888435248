import React from 'react';
import { useTheme } from 'styled-components/macro';
import { Warning } from '@profitfy/pakkun-icons';

import { useCheckoutFee } from '@helpers/hooks/pages/dashboard/checkoutFee/useCheckoutFee';
import { EModalType } from '@domain/enums/components/EModal';

import * as S from './styles';

const SyncPastCheckoutFeeModal: React.FC = () => {
  const theme = useTheme();

  const {
    isSyncPastCheckoutFeeModalOpen,
    handleSyncPastCheckoutFeeModalOpen,
    handleSyncPastCheckoutFeeSideModalOpen,
  } = useCheckoutFee();

  const onSyncClick = React.useCallback(() => {
    handleSyncPastCheckoutFeeModalOpen();
    handleSyncPastCheckoutFeeSideModalOpen();
  }, [handleSyncPastCheckoutFeeModalOpen, handleSyncPastCheckoutFeeSideModalOpen]);

  return (
    <S.Modal isOpen={isSyncPastCheckoutFeeModalOpen} toggle={handleSyncPastCheckoutFeeModalOpen}>
      <S.Modal.Header type={EModalType.WARNING}>
        <Warning size={24} color={theme.colors.warning.default} outline />
        Alterar taxa para pedidos anteriores?
      </S.Modal.Header>
      <S.Modal.Body>
        <S.Description>
          Caso você <strong>não altere</strong>, o valor só será aplicados para os{' '}
          <strong>próximos períodos.</strong>
        </S.Description>
        <S.ButtonsWrapper>
          <S.CancelButton onClick={handleSyncPastCheckoutFeeModalOpen}>Não Alterar</S.CancelButton>
          <S.SyncButton onClick={onSyncClick}>Alterar</S.SyncButton>
        </S.ButtonsWrapper>
      </S.Modal.Body>
    </S.Modal>
  );
};

export default SyncPastCheckoutFeeModal;
