import React from 'react';

import { EProductCosts } from '@domain/enums/common/EProductCosts';
import { EDropshippingProvider } from '@domain/enums/common/integrations/EDropshippingProvider';

import { useStoreIntegrations } from '@helpers/hooks/pages/dashboard/integrationCenter/useStoreIntegrations';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { getProductCostIntegrationStatus } from '@helpers/utils/dashboard/integrationsCenter/erps';
import { getStoreIntegrations } from '@helpers/utils/dashboard/integrationsCenter/storeIntegrations';

import { ERPS } from '@constants/common/integrations';

import IntegrationCard from '@components/Dashboard/IntegrationCenter/IntegrationCard';
import Text from '@components/common/core/DataDisplay/Text';
import SkeletonLoadingCard from '../../SkeletonLoadingCard';

import * as S from './styles';

const Erp: React.FC = () => {
  const emptyArray = Array.from(Array(5).keys());

  const {
    searchIntegrationsValue,
    isSideModalOpen,
    handleSideModalOpen,
    handleSelectedIntegration,
    selectedIntegration,
    selectedTab,
  } = useStoreIntegrations();
  const { storeIntegrations, isLoadingStore, isValidatingStore, store } = useStoreConfig();

  const activeIntegrations = getStoreIntegrations(storeIntegrations, store.type);

  const SideModalContent = ERPS.find(integration => {
    const foundActiveIntegration = activeIntegrations.find(
      activeIntegration => activeIntegration?.identifier === integration.identifier,
    );
    if (foundActiveIntegration && selectedTab !== 2) return false;
    return integration.identifier === selectedIntegration;
  })?.sideModal;

  const onVirtualStoreIntegrationCardClick = React.useCallback(
    (identifier: EProductCosts) => {
      handleSideModalOpen();
      handleSelectedIntegration(identifier);
    },
    [handleSideModalOpen, handleSelectedIntegration],
  );

  if (isLoadingStore || isValidatingStore) {
    return (
      <S.ErpWrapper>
        <S.Heading>ERP</S.Heading>

        <S.CardsWrapper>
          {emptyArray.map(element => (
            <SkeletonLoadingCard key={element} />
          ))}
        </S.CardsWrapper>
      </S.ErpWrapper>
    );
  }

  const isShopifyIntegrationActive = Boolean(
    storeIntegrations?.store_front.find(
      integration => integration.name === EDropshippingProvider.SHOPIFY && integration.is_active,
    ),
  );

  const filteredERPIntegrations = ERPS.filter(integration => {
    if (!isShopifyIntegrationActive && integration.identifier === EProductCosts.DSERS) return false;

    const integrationName = integration.name.toLowerCase();
    const searchIntegration = searchIntegrationsValue.toLowerCase();
    const isIntegrationIncludedOnSearch = integrationName.includes(searchIntegration);

    if (!isIntegrationIncludedOnSearch) return false;

    return true;
  });

  return (
    <S.ErpWrapper>
      <S.Heading>ERP</S.Heading>

      <S.CardsWrapper>
        {filteredERPIntegrations.map(filteredIntegration => {
          const isActivated = getProductCostIntegrationStatus(
            storeIntegrations,
            filteredIntegration.identifier,
          );

          return (
            <IntegrationCard
              icon={filteredIntegration.icon}
              isActivated={isActivated}
              title={filteredIntegration.name}
              key={filteredIntegration.id}
              onClick={() => onVirtualStoreIntegrationCardClick(filteredIntegration.identifier)}
            />
          );
        })}

        {!filteredERPIntegrations.length && (
          <Text>Não encontramos nenhuma integração que contenha esse nome.</Text>
        )}
      </S.CardsWrapper>

      {SideModalContent && (
        <S.SideModal isOpen={isSideModalOpen} toggle={handleSideModalOpen}>
          <SideModalContent />
        </S.SideModal>
      )}
    </S.ErpWrapper>
  );
};

export default Erp;
