import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Title = styled(TextBase)`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.green[3]};
`;

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin-top: 24px;
`;

export const Card = styled.div`
  width: 190px;
  height: 213px;
  border-radius: 15px;
  padding: 24px 16px 24px 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: border-color 0.3s;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  border: 2px solid ${({ theme }) => theme.colors.background[3]};

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.green.default};
  }
`;

export const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background-color: rgb(37, 207, 187, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardTitle = styled(TextBase)`
  font-weight: 700;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.green[3]};
  line-height: 1;
  margin-top: 24px;
  text-align: center;
`;

export const CardDescription = styled(TextBase)`
  font-weight: 400;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[3]};
  line-height: 16.8px;
  text-align: center;
`;
