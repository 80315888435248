import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Description = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const Wrapper = styled.div`
  margin-bottom: 32px;
`;
