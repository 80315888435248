import React from 'react';

import { EEcommerceProvider } from '@domain/enums/common/integrations/EEcommerceProvider';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import IntegrateForm from './IntegrateForm';
import TinyConfig from './TinyConfig';

const Tiny: React.FC = () => {
  const { storeIntegrations } = useStoreConfig();

  const { store_front } = storeIntegrations;

  const foundIntegration = store_front.find(
    integration => integration.name === EEcommerceProvider.TINY,
  );

  if (foundIntegration?.is_active) {
    return <TinyConfig />;
  }

  return <IntegrateForm />;
};

export default Tiny;
