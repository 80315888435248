import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import FormBase from '@components/common/core/Inputs/Form';
import ImageBase from '@components/common/core/DataDisplay/Image';
import LinkBase from '@components/common/core/Navigation/Link';

export const Input = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  max-width: 408px;
  margin-bottom: 24px;
`;

export const TutorialText = styled(TextBase)`
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const Link = styled(LinkBase)`
  display: inline;
`;

export const Benefit = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const BenefitsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;

export const DescriptionText = styled(TextBase)`
  line-height: 120%;
`;

export const Form = styled(FormBase)`
  width: auto;

  @media only screen and (max-width: 920px) {
    margin: 0 auto;
  }
`;

export const IntegrationTitleText = styled(HeadingBase)``;

export const Body = styled.div``;

export const IntegrationTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  gap: 16px;
`;

export const Title = styled(HeadingBase)`
  font-size: 1.75rem;
  line-height: 120%;
  margin-bottom: 36px;
`;

export const Header = styled.div``;

export const TutorialLink = styled.a`
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.green.default};
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    text-decoration: underline;
    filter: brightness(0.9);
  }
`;

export const TutorialContent = styled(TextBase)`
  display: inline-block;
  font-size: 0.875rem;
  line-height: 150%;
  margin-bottom: 8px;
`;

export const TutorialTitle = styled(HeadingBase)`
  font-size: 1rem;
  line-height: 120%;
  display: inline-block;
  margin-bottom: 8px;
`;

export const TutorialImage = styled(ImageBase)`
  max-width: 140px;
  margin-bottom: 24px;
  display: block;
`;

export const TutorialWrapper = styled.div`
  max-width: 416px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray[4]};
  border-radius: 7px;
  padding: 28px 32px;
  box-sizing: border-box;
  height: fit-content;
`;

export const FormWrapper = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  padding: 142px 32px;
  max-width: calc(1008px + 64px);
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  gap: 28px;

  @media only screen and (max-width: 1292px) {
    flex-direction: column;
    max-width: calc(620px + 64px);
  }
`;
