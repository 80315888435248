import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { IFinancialDataProvider } from '@domain/interfaces/dashboard/FinancialData/IFinancialData';
import { IParams } from '@domain/interfaces/IParams';

import addressService from '@services/common/address/address';
import legalPersonService from '@services/common/legalPerson/legalPerson';
import naturalPersonService from '@services/common/naturalPerson/naturalPerson';

const FinancialDataContext = React.createContext<IFinancialDataProvider | null>(null);

export const FinancialDataProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    // resolver: yupResolver(test ? firstFormSchema : secondFormSchema),
  });

  const {
    naturalPerson,
    isLoading: isLoadingNaturalPerson,
    error: naturalPersonError,
    isValidating: isValidatingNaturalPerson,
    mutate: mutateNaturalPerson,
  } = naturalPersonService.getNaturalPerson({ storeAliasId });

  const {
    legalPerson,
    isLoading: isLoadingLegalPerson,
    error: legalPersonError,
    isValidating: isValidatingLegalPerson,
    mutate: mutateLegalPerson,
  } = legalPersonService.getLegalPerson({ storeAliasId });

  const {
    address,
    isLoading: isLoadingAddress,
    error: addressError,
    isValidating: isValidatingAddress,
    mutate: mutateAddress,
  } = addressService.getAddress({ storeAliasId });

  return (
    <FinancialDataContext.Provider
      value={{
        register,
        handleSubmit,
        errors,
        legalPerson,
        naturalPerson,
        address,
        addressError,
        isLoadingAddress,
        isLoadingLegalPerson,
        isLoadingNaturalPerson,
        isValidatingAddress,
        isValidatingLegalPerson,
        isValidatingNaturalPerson,
        legalPersonError,
        mutateAddress,
        mutateLegalPerson,
        mutateNaturalPerson,
        naturalPersonError,
      }}
    >
      {children}
    </FinancialDataContext.Provider>
  );
};

export const useFinancialData = (): IFinancialDataProvider => {
  const context = React.useContext(FinancialDataContext);

  if (!context) {
    throw new Error('useFinancialData must be used within FinancialDataProvider');
  }

  return context;
};
