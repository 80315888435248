import IWorkflowEdge from '@domain/interfaces/mappers/automations/profitfy/IWorkflowEdge';
import IReactFlowWorkflowEdge from '@domain/interfaces/mappers/automations/reactFlow/IWorkflowEdge';

const map = ({
  id,
  source,
  target,
  sourceHandle,
  targetHandle,
}: IReactFlowWorkflowEdge): IWorkflowEdge => {
  const mappedEdge: IWorkflowEdge = {
    provider_id: id,
    source,
    source_handle: sourceHandle,
    target,
    target_handle: targetHandle,
  };

  return mappedEdge;
};

export default { map };
