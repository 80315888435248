import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import ImageBase from '@components/common/core/DataDisplay/Image';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';
import NoImageBase from '@components/common/core/DataDisplay/NoImage';

export const NoImage = styled(NoImageBase)`
  background-color: ${({ theme }) => theme.colors.background[3]};
  min-width: 42px;
  min-height: 42px;
  width: 42px;
  height: 42px;
`;

export const TooltipText = styled(TextBase)``;

export const TooltipTrigger = styled(CustomTooltipBase.Trigger)`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.font};
  cursor: pointer;
`;

export const Tooltip = styled(CustomTooltipBase)``;

export const ProductImage = styled(ImageBase)`
  min-width: 42px;
  min-height: 42px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;
