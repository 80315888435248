import React from 'react';

import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';

import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useToast } from '@helpers/hooks/useToast';

import storesService from '@services/pages/stores/stores';

import Welcome from './Welcome';
import Syncing from './Syncing';

const Onboarding: React.FC = () => {
  const { mutateStore, store } = useStoreConfig();
  const { toast } = useToast();
  const {
    currentSynchronizations,
    synchronizationsQueue,
    finishedSynchronizations,
    handleFinishedSynchronizations,
  } = useSynchronization();

  const [isUpdatingStore, setIsUpdatingStore] = React.useState<boolean>(false);

  const finishOnboarding = React.useCallback(async () => {
    setIsUpdatingStore(true);

    try {
      await storesService.updateStore(store.alias_id, {
        ...store,
        is_ads_manager_onboarding_finished: true,
      });

      await mutateStore();

      setIsUpdatingStore(false);
      handleFinishedSynchronizations([]);
    } catch (error: any) {
      setIsUpdatingStore(false);
      toast.error(error?.response?.data?.message);
    }
  }, [mutateStore, store, toast, handleFinishedSynchronizations]);

  React.useEffect(() => {
    const foundSynchronizationFinished = finishedSynchronizations.find(
      synchronization => synchronization.type === ESynchronizationType.ADS_MANAGER_DATA,
    );
    if (finishedSynchronizations.length && foundSynchronizationFinished) {
      finishOnboarding();
    }
  }, [finishOnboarding, finishedSynchronizations]);

  const parsedCurrentSynchronizations = currentSynchronizations
    ? currentSynchronizations.synchronizations
    : [];
  const mergedSynchronizations = [...parsedCurrentSynchronizations, ...synchronizationsQueue];

  const isSyncing = Boolean(
    mergedSynchronizations.find(
      synchronization => synchronization.type === ESynchronizationType.ADS_MANAGER_DATA,
    ),
  );

  if (isSyncing || isUpdatingStore) {
    return <Syncing />;
  }

  return <Welcome />;
};

export default Onboarding;
