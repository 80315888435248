import React from 'react';
import { useParams } from 'react-router-dom';

import { EScriptAndUtmsStep, ESyncType } from '@domain/enums/dashboard/adsManager/EScriptAndUtms';
import { EDropshippingProvider } from '@domain/enums/common/integrations/EDropshippingProvider';
import {
  ESynchronizationRequestType,
  ESynchronizationType,
} from '@domain/enums/hooks/ESynchronization';
import { IScriptAndUtmsProvider } from '@domain/interfaces/dashboard/AdsManager/Onboarding/IScriptAndUtms';
import { IAdSenseAds } from '@domain/interfaces/dashboard/AdsManager/Onboarding/IAdSenseCampaignsWithoutUtm';
import { IParams } from '@domain/interfaces/IParams';

import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useDate } from '@helpers/hooks/useDate';
import { newWindow } from '@helpers/hooks/usePopup';

import { getEnvironment } from '@helpers/utils/environment';

import { LONG_API_DOMAIN } from '@constants/api';

import scheduleService from '@services/common/schedule/schedule';
import shopifyService from '@services/pages/dashboard/integrationCenter/marketplace/shopify/shopifyAuth';
import shopifyScriptService from '@services/pages/dashboard/integrationCenter/marketplace/shopify/shopifyScript';

const sanitizeUrl = (url: string): string => {
  const replacedUrl = url.replace('https://', '').replace('wwww.', '');

  const parsedUrl = replacedUrl.split('.myshopify.com')[0];

  return parsedUrl;
};

const ScriptAndUtmsContext = React.createContext<IScriptAndUtmsProvider | null>(null);

export const ScriptAndUtmsProvider: React.FC = ({ children }) => {
  const {
    currentSynchronizations,
    synchronizationsQueue,
    finishedSynchronizations,
    addSynchronization,
  } = useSynchronization();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { analytics } = useConfig();
  const { storeIntegrations } = useStoreConfig();
  const { isBefore } = useDate();

  const parsedCurrentSynchronizations = currentSynchronizations
    ? currentSynchronizations.synchronizations
    : [];
  const mergedSynchronzations = [...parsedCurrentSynchronizations, ...synchronizationsQueue];

  const [isCampaignsSideModalOpen, setIsCampaignsSideModalOpen] = React.useState<boolean>(false);
  const [userStep, setUserStep] = React.useState<EScriptAndUtmsStep>(EScriptAndUtmsStep.SCRIPT);
  const [adSenseAds, setAdSenseAds] = React.useState<Array<IAdSenseAds>>([]);
  const [searchName, setSearchName] = React.useState<string>('');
  const [filteredAds, setFilteredAds] = React.useState<Array<IAdSenseAds>>([]);
  const [selectedAdSenseAds, setSelectedAdSenseAds] = React.useState<Array<IAdSenseAds>>([]);
  const [syncType, setSyncType] = React.useState<ESyncType>(ESyncType.SCHEDULE);
  const [userWindow, setUserWindow] = React.useState<Window | undefined>(undefined);
  const [storeSelectedAdSenseAds, setStoreSelectedAdSenseAds] = React.useState<Array<IAdSenseAds>>(
    [],
  );

  const shouldReAuthenticateShopify = React.useCallback(async () => {
    try {
      const { data: shopifyCredentialData } = await shopifyService.getShopifyCredentialPromise({
        storeAliasId,
      });

      if (
        isBefore(
          new Date(shopifyCredentialData?.shopify_credential.updated_at),
          new Date(2022, 8, 20),
        )
      )
        return shopifyCredentialData?.shopify_credential;
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }

    return false;
  }, [isBefore, storeAliasId, toast]);

  const authenticateShopify = React.useCallback(
    async url => {
      try {
        const sanitizedUrl = sanitizeUrl(url);

        const { data: shopifyData } = await shopifyService.generateURL({
          storeAliasId,
          data: { shop: sanitizedUrl },
        });

        const window = newWindow(shopifyData?.auth_url, 'Shopify', 1280, 720);

        setUserWindow(window);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
    [storeAliasId, toast],
  );

  const addScriptToShopify = React.useCallback(async () => {
    try {
      await shopifyScriptService.addUtmScript({ storeAliasId });
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [storeAliasId, toast]);

  const handleSearchName = React.useCallback(newSearchname => setSearchName(newSearchname), []);

  const handleSyncType = React.useCallback(event => {
    setSyncType(event.target.value);
  }, []);

  const handleCampaignsSideModalOpen = React.useCallback(() => {
    setIsCampaignsSideModalOpen(!isCampaignsSideModalOpen);
  }, [isCampaignsSideModalOpen]);

  const handleNextStep = React.useCallback(() => {
    if (userStep === EScriptAndUtmsStep.SCRIPT) {
      setUserStep(EScriptAndUtmsStep.UTM_TAGS);
    }

    if (userStep === EScriptAndUtmsStep.UTM_TAGS) {
      setUserStep(EScriptAndUtmsStep.SCHEDULE);
    }

    if (userStep === EScriptAndUtmsStep.SCHEDULE && syncType === ESyncType.SCHEDULE) {
      setUserStep(EScriptAndUtmsStep.UTM_TAGS_PROCESSED);
    }

    if (userStep === EScriptAndUtmsStep.SCHEDULE && syncType === ESyncType.SYNC_NOW) {
      setUserStep(EScriptAndUtmsStep.PROCESSING_UTM_TAGS);
    }

    if (userStep === EScriptAndUtmsStep.PROCESSING_UTM_TAGS) {
      setUserStep(EScriptAndUtmsStep.UTM_TAGS_PROCESSED);
    }
  }, [userStep, syncType]);

  const scheduleUtmSync = React.useCallback(
    async data => {
      try {
        await scheduleService.createSchedule({ storeAliasId, data });

        toast.success('Sincronização agendada com sucesso!');

        handleNextStep();
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
    [toast, storeAliasId, handleNextStep],
  );

  const syncUtms = React.useCallback(() => {
    try {
      const mappedStoreSelectedAdSenseAds = storeSelectedAdSenseAds.map(adSenseAd => ({
        id: adSenseAd.id,
        alias_id: adSenseAd.alias_id,
        name: adSenseAd.name,
        provider: adSenseAd.provider,
        status: adSenseAd.status,
        provider_id: adSenseAd.provider_id,
      }));

      analytics?.track(
        'Ads Manager UTM Onboarding Add UTM Button Clicked',
        {},
        { context: { groupId: storeAliasId } },
      );

      handleNextStep();

      addSynchronization({
        name: 'UTMs Onboarding',
        type: ESynchronizationType.ADS_WITHOUT_UTMS,
        label: 'UTMs',
        dependencies: [],
        storeAliasId,
        config: {
          isOnboardingSynchronization: false,
          showNotification: false,
        },
        request: {
          type: ESynchronizationRequestType.POST,
          domain: LONG_API_DOMAIN,
          api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/ads-manager/utm/sync`,
          requestBody: {
            ad_sense_ads: mappedStoreSelectedAdSenseAds,
          },
        },
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [addSynchronization, toast, storeAliasId, analytics, handleNextStep, storeSelectedAdSenseAds]);

  const handleStoreSelectedAdSenseAds = React.useCallback(newAdSenseAds => {
    setStoreSelectedAdSenseAds(newAdSenseAds);
  }, []);

  const handleAdSenseAds = React.useCallback(newAdSenseAds => {
    setAdSenseAds(newAdSenseAds);
  }, []);

  const handleSelectedAdSenseAds = React.useCallback((newAdSenseAds: Array<IAdSenseAds>) => {
    setSelectedAdSenseAds(newAdSenseAds);
  }, []);

  const handleSelectedAdSenseAd = React.useCallback(
    (adSenseAd: IAdSenseAds) => {
      const foundadSenseAd = selectedAdSenseAds.find(ad => ad.alias_id === adSenseAd.alias_id);

      if (foundadSenseAd) {
        const filteredAdSenseAd = selectedAdSenseAds.filter(
          ad => ad.alias_id !== adSenseAd.alias_id,
        );

        setSelectedAdSenseAds(filteredAdSenseAd);
      } else {
        setSelectedAdSenseAds(previousValue => [...previousValue, adSenseAd]);
      }
    },
    [selectedAdSenseAds],
  );

  const filterAdSenseAds = React.useCallback(
    (ads: Array<IAdSenseAds>, nameToFilter: string): Array<IAdSenseAds> => {
      const filteredAdSenseAds = ads.filter(ad =>
        ad.name.toLowerCase().includes(nameToFilter.toLowerCase()),
      );

      return filteredAdSenseAds;
    },
    [],
  );

  React.useEffect(() => {
    if (mergedSynchronzations.length) {
      const foundSynchronization = mergedSynchronzations.find(
        synchronization => synchronization.type === ESynchronizationType.ADS_WITHOUT_UTMS,
      );

      const foundFinishedSynchronziation = finishedSynchronizations.find(
        synchronization => synchronization.type === ESynchronizationType.ADS_WITHOUT_UTMS,
      );

      if (foundSynchronization) {
        setSyncType(ESyncType.SYNC_NOW);
        setUserStep(EScriptAndUtmsStep.PROCESSING_UTM_TAGS);
      }

      if (foundFinishedSynchronziation) {
        setSyncType(ESyncType.SYNC_NOW);
        setUserStep(EScriptAndUtmsStep.UTM_TAGS_PROCESSED);
      }
    }
  }, [mergedSynchronzations, finishedSynchronizations]);

  React.useEffect(() => {
    if (adSenseAds.length) {
      setSelectedAdSenseAds(adSenseAds);
      setStoreSelectedAdSenseAds(adSenseAds);
    }
  }, [adSenseAds]);

  React.useEffect(() => {
    if (adSenseAds.length) {
      const ads = filterAdSenseAds(adSenseAds, searchName);

      setFilteredAds(ads);
    }
  }, [adSenseAds, searchName, filterAdSenseAds]);

  const hasShopifyIntegration = Boolean(
    storeIntegrations?.store_front?.find(
      integration => integration.name === EDropshippingProvider.SHOPIFY && integration.is_active,
    ),
  );

  return (
    <ScriptAndUtmsContext.Provider
      value={{
        handleCampaignsSideModalOpen,
        isCampaignsSideModalOpen,
        handleNextStep,
        userStep,
        adSenseAds,
        handleSearchName,
        filteredAds,
        handleAdSenseAds,
        handleSelectedAdSenseAd,
        handleSelectedAdSenseAds,
        handleStoreSelectedAdSenseAds,
        selectedAdSenseAds,
        storeSelectedAdSenseAds,
        handleSyncType,
        syncType,
        scheduleUtmSync,
        syncUtms,
        hasShopifyIntegration,
        addScriptToShopify,
        authenticateShopify,
        shouldReAuthenticateShopify,
        userWindow,
      }}
    >
      {children}
    </ScriptAndUtmsContext.Provider>
  );
};

export const useScriptAndUtms = (): IScriptAndUtmsProvider => {
  const context = React.useContext(ScriptAndUtmsContext);

  if (!context) {
    throw new Error('useScriptAndUtms must be used within CampaignsProvider');
  }

  return context;
};
