import React from 'react';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import { IProductsProvider } from '@domain/interfaces/dashboard/Feed/IProduct';

import feedService from '@services/pages/dashboard/feed/feed';

const ProductsContext = React.createContext<IProductsProvider | null>(null);

export const ProductsProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();

  const { products, isLoading, isValidating, mutate, error } = feedService.listProductsFeed({
    storeAliasId,
  });

  const isLoadingProducts = isLoading;
  const isValidatingProducts = isValidating;
  const mutateProducts = mutate;
  const productsError = error;

  return (
    <ProductsContext.Provider
      value={{ products, isLoadingProducts, isValidatingProducts, mutateProducts, productsError }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export const useProducts = (): IProductsProvider => {
  const context = React.useContext(ProductsContext);

  if (!context) {
    throw new Error('useProductContext must be used within ProductsContext');
  }

  return context;
};
