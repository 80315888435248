import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';
import { ShoppingCart } from 'phosphor-react';

export const ABANDONED_CART_STARTER = [
  {
    id: 'abandoned-cart-starter-first-condition',
    title: 'Carrinho abandonado (1ª condição)',
    type: 'ABANDONED_CART',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: ShoppingCart,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá, {{first_name}}! 😀\n\nNotamos que você deixou alguns itens no seu carrinho.\n\nClique no link abaixo e garanta seu produto {{products_with_variant}} com mais 10% de desconto utilizando o cupom: BLABLA10.\n\n{{checkout_link}}',
      },
    ],
  },
  {
    id: 'abandoned-cart-starter-second-condition',
    title: 'Carrinho abandonado (2ª condição)',
    type: 'ABANDONED_CART',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: ShoppingCart,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          '{{first_name}}, você não utilizou o seu cupom de desconto? 😥\n\nNão perca essa chance, porque é por tempo limitado!\n\nClique no link abaixo e utilize o cupom: BLABLA10\n\n{{checkout_link}}',
      },
    ],
  },
];
