import styled from 'styled-components/macro';

import AlertBase from '@components/common/core/DataDisplay/Alert';

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 692px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;

export const Alert = styled(AlertBase)`
  margin-bottom: 16px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 8px;
  padding: 24px 28px;
  box-sizing: border-box;
`;
