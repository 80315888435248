import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { ILink } from '@domain/interfaces/components/ILink';

export const Container = styled(Link)<ILink>`
  align-self: flex-end;
  text-decoration: none;
  transition: all 0.3s;
  display: block;
  width: fit-content;
  color: ${({ theme }) => theme.colors.green.default};
  font-family: ${({ theme }) => theme.fonts.body};

  &:hover {
    filter: brightness(0.8);
  }
`;
