import React from 'react';

import { FilterProvider } from '@helpers/hooks/useFilter';
import { IFilterComponentProps } from '@domain/interfaces/components/IFilter';
import Wrapper from './Wrapper';

const Filter: React.FC<IFilterComponentProps> = ({ config, filterData, handleFilterData }) => {
  return (
    <FilterProvider config={config} filterData={filterData} handleFilterData={handleFilterData}>
      <Wrapper />
    </FilterProvider>
  );
};

export default Filter;
