import React from 'react';
import { NuvemShop as Icon } from '@profitfy/pakkun-icons';
import { useHistory, useParams } from 'react-router-dom';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';
import { EDropshippingProvider } from '@domain/enums/common/integrations/EDropshippingProvider';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';

import { newWindow } from '@helpers/hooks/usePopup';
import { useToast } from '@helpers/hooks/useToast';
import { useDropshipping } from '@helpers/hooks/pages/onboarding/useDropshipping';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useDate } from '@helpers/hooks/useDate';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';

import { DROPSHIPPING_PROVIDERS } from '@constants/common/integrations';

import integrationCenterService from '@services/pages/dashboard/integrationCenter/integrationCenter';
import nuvemShopAuthService from '@services/pages/dashboard/integrationCenter/marketplace/nuvemShop/nuvemShopAuth';

import NextStep from '@components/Onboarding/NextStep';

import * as S from './styles';

const NuvemShop: React.FC = () => {
  const history = useHistory();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { selectedGateways, selectedMarketings } = useDropshipping();
  const { addSynchronization } = useSynchronization();
  const { format, utcToZonedTime } = useDate();
  const { sendFinancialData } = useOnboarding();

  const [isIntegrating, setIsIntegrating] = React.useState<boolean>(false);
  const [userWindow, setUserWindow] = React.useState<Window | undefined>(undefined);

  const nextStep = React.useCallback(async () => {
    setIsIntegrating(true);

    try {
      const { data: nuvemShopData } = await nuvemShopAuthService.generateURL({ storeAliasId });

      const window = newWindow(nuvemShopData?.auth_url, 'NuvemShop', 1280, 720);

      setUserWindow(window);
    } catch (error: any) {
      setIsIntegrating(false);
      toast.error(error?.response?.data?.message);
    }
  }, [toast, storeAliasId]);

  const handleWindowClose = React.useCallback(async () => {
    const { data } = await integrationCenterService.getStoreIntegrations({ storeAliasId });

    const hasNuvemShopCredential = data?.store_integrations?.store_front?.has_nuvem_shop_credential;

    if (hasNuvemShopCredential) {
      const foundShopifyIntegration = DROPSHIPPING_PROVIDERS.find(
        integration => integration.identifier === EDropshippingProvider.NUVEM_SHOP,
      ) as typeof DROPSHIPPING_PROVIDERS[number];

      const { generateSyncRequest } = foundShopifyIntegration;

      const params = `?start_date=${format(new Date(2000, 1, 1), 'yyyy-MM-dd')}&end_date=${format(
        utcToZonedTime(new Date()),
        'yyyy-MM-dd',
      )}`;

      addSynchronization({
        name: 'NuvemShop',
        type: ESynchronizationType.STORE_PRODUCT,
        label: 'Produtos',
        dependencies: [],
        storeAliasId,
        config: {
          isOnboardingSynchronization: true,
          showNotification: false,
        },
        request: generateSyncRequest(ESynchronizationType.STORE_PRODUCT, storeAliasId, params),
      });

      sendFinancialData(storeAliasId);

      setIsIntegrating(false);

      if (selectedGateways.length) {
        history.push(`/onboarding/${storeAliasId}/dropshipping/integrations/gateways`);
      }

      if (!selectedGateways.length && selectedMarketings.length) {
        history.push(`/onboarding/${storeAliasId}/dropshipping/integrations/marketing`);
      }

      if (!selectedGateways.length && !selectedMarketings.length) {
        history.push(`/onboarding/${storeAliasId}/dropshipping/integrations/integration-finished`);
      }
    } else {
      setIsIntegrating(false);
    }
  }, [
    addSynchronization,
    format,
    history,
    selectedGateways,
    selectedMarketings,
    storeAliasId,
    utcToZonedTime,
    sendFinancialData,
  ]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (userWindow?.closed) {
        clearInterval(interval);
        handleWindowClose();
      }
    }, 1000);
  }, [userWindow, history, selectedGateways, selectedMarketings, storeAliasId, handleWindowClose]);

  return (
    <S.Wrapper>
      <S.IntegrationContent>
        <S.Header>
          <S.Title>Integre com sua plataforma de E-commerce</S.Title>
        </S.Header>

        <S.IntegrationTitle>
          <Icon size={36} />

          <S.IntegrationTitleText fontWeight={EHeadingWeight.REGULAR}>
            Integração com NuvemShop
          </S.IntegrationTitleText>
        </S.IntegrationTitle>

        <S.Body>
          <S.Text>
            Para continuar com a integração, vá para o próximo passo e realize a integração com a
            NuvemShop
          </S.Text>
        </S.Body>
      </S.IntegrationContent>

      <S.TutorialWrapper>
        <S.TutorialImage
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/onboarding/images/content/onboarding/onboarding-integration-tutorial.svg"
          alt="Profitfy.me Integration Tutorial"
        />
        <S.TutorialTitle>Tutorial de integração</S.TutorialTitle>
        <S.TutorialContent>
          Para realizar a integração você precisará seguir o próximo passo e então integrar com a
          NuvemShop.
        </S.TutorialContent>
      </S.TutorialWrapper>

      <NextStep onClick={nextStep} isLoading={isIntegrating} disabled={isIntegrating}>
        Próximo passo
      </NextStep>
    </S.Wrapper>
  );
};

export default NuvemShop;
