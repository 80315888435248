import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Text = styled(TextBase)``;

export const Wrapper = styled.div`
  background-color: #122427;
  width: 100%;
  padding: 12px 24px;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  & > svg {
    min-width: 32px;
  }
`;
