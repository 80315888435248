import styled from 'styled-components/macro';

import CardBase from '@components/common/core/Surfaces/Card';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

export const CompensatedBullet = styled.span`
  width: 10px;
  height: 10px;
  background-color: #324161;
  border-radius: 2px;
  position: relative;
  display: block;

  &::after {
    position: absolute;
    content: '';
    width: 7px;
    height: 7px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.colors.info.light};
    border-radius: 50%;
  }
`;

export const InAnalysisBullet = styled.span`
  width: 10px;
  height: 10px;
  background-color: #4f432e;
  border-radius: 2px;
  position: relative;
  display: block;

  &::after {
    position: absolute;
    content: '';
    width: 7px;
    height: 7px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.colors.warning.default};
    border-radius: 50%;
  }
`;

export const ApprovedBullet = styled.span`
  width: 10px;
  height: 10px;
  background-color: #314b42;
  border-radius: 2px;
  position: relative;
  display: block;

  &::after {
    position: absolute;
    content: '';
    width: 7px;
    height: 7px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.colors.success.default};
    border-radius: 50%;
  }
`;

export const StatusText = styled(TextBase)`
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const OrdersQuantity = styled(TextBase)`
  line-height: 120%;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const OrdersAmount = styled(TextBase)`
  line-height: 120%;
  font-size: 0.875rem;
`;

export const OrdersAmountAndQuantity = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const OrdersStatusWrapper = styled.div``;

export const OrdersDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
`;

export const ChartPercentage = styled(HeadingBase)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.125rem;
  font-weight: 400;
`;

export const ChartWrapper = styled.div`
  width: 92px;
  height: 92px;
  position: relative;
`;

export const DataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const Title = styled(HeadingBase)``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const Card = styled(CardBase)`
  padding: 12px 24px 24px 24px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 12px;
  border: unset;
  min-width: 270px;
`;
