import React from 'react';

import Wrapper from '@components/Dashboard/Orders/CreateOrder/Wrapper';
import { OrdersProvider } from '@helpers/hooks/pages/dashboard/orders/useOrders';

const CreateOrder: React.FC = () => {
  return (
    <OrdersProvider>
      <Wrapper />
    </OrdersProvider>
  );
};

export default CreateOrder;
