import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  max-width: 202px;
  width: 100%;
  margin-bottom: 32px;
`;

export const CnpjAndCompanyNameWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 24px;
`;

export const PhoneAndEmailWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 24px;
`;

export const LabelWrapper = styled.div``;

export const CnpjInputWrapper = styled.div`
  max-width: 324px;
  width: 100%;
`;

export const CompanyNameWrapper = styled.div`
  max-width: 324px;
  width: 100%;
`;

export const PhoneWrapper = styled.div`
  max-width: 204px;
  width: 100%;
`;

export const EmailWrapper = styled.div`
  max-width: 445px;
  width: 100%;
`;
