import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

import { IIntegrationsCardProps } from './index';

export const Wrapper = styled.button<IIntegrationsCardProps>`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 18px 16px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 300ms;
  border: none;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.background[8] : theme.colors.background[2]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.background[8]};
  }
`;

export const Name = styled(TextBase)`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[1]};
  margin-left: 10px;
`;

export const Icon = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
