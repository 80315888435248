import styled from 'styled-components/macro';
import * as Accordion from '@radix-ui/react-accordion';

export const Wrapper = styled(Accordion.Item)`
  overflow: hidden;
  margin-top: 1px;

  &:first-child {
    margin-top: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:focus-within {
    position: relative;
    z-index: 1;
  }
`;
