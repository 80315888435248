import { ENodes } from '@domain/enums/dashboard/automations/node/ENodes';
import { ENodeType } from '@domain/enums/mappers/automations/profitfy/ENodeType';

interface ITypeMapperDTO<T> {
  [key: string]: T;
}

const map = (nodeType: ENodeType): ENodes => {
  const type: ITypeMapperDTO<ENodes> = {
    [ENodeType.ABANDONED_CART]: ENodes.ABANDONED_CART,
    [ENodeType.REJECTED_PAYMENT]: ENodes.REJECTED_PAYMENT,
    [ENodeType.PIX]: ENodes.PIX,
    [ENodeType.BOLETO]: ENodes.BOLETO,
    [ENodeType.IF]: ENodes.IF,
    [ENodeType.SWITCH]: ENodes.SWITCH,
    [ENodeType.WAIT]: ENodes.WAIT,
    [ENodeType.WHATSAPP]: ENodes.WHATSAPP,
    [ENodeType.END]: ENodes.END,
    [ENodeType.SIMPLE_SHIPMENT]: ENodes.SIMPLE_SHIPMENT,
    [ENodeType.UPDATED_FULFILLMENT]: ENodes.UPDATED_FULFILLMENT,
  };

  const mappedType = type[nodeType];

  return mappedType;
};

export default { map };
