export enum EStoreType {
  DROPSHIPPING = 'DROPSHIPPING',
  INFO_PRODUCT = 'INFO_PRODUCT',
  TRADITIONAL_ECOMMERCE = 'TRADITIONAL_ECOMMERCE',
  DNVB = 'DNVB',
  ENCAPSULATED = 'ENCAPSULATED',
}

export enum EBusinessType {
  DROPSHIPPING = 'DROPSHIPPING',
  TRADITIONAL_ECOMMERCE = 'TRADITIONAL_ECOMMERCE',
  INFO_PRODUCT = 'INFO_PRODUCT',
}
