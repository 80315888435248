import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { numberFormatter } from '@helpers/masks';
import { EButtonVariant } from '@domain/enums/components/EButton';
import { ChevronRight } from '@profitfy/pakkun-icons';

import PieChart from '@components/Dashboard/FinancialDashboard/CustomValuesSideModal/PieChart';
import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { useConfig } from '@helpers/hooks/useConfig';
import NoCustomValues from './NoCustomValues';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const CustomValues: React.FC = ({ ...props }) => {
  const {
    customValues,
    isLoadingFinancialDashboardProvider,
    isValidatingFinancialDashboardProvider,
    customValuesTotalAmount,
    handleCustomValuesSideModalOpen,
    isMultiStoreSelectMode,
  } = useFinancialDashboard();

  const { analytics, selectedStore, user } = useConfig();

  const handleMoreDetails = React.useCallback(() => {
    analytics?.track(
      'Financial Dashboard Custom Values SideModal Viewed',
      {
        email: user?.email,
      },
      { context: { groupId: selectedStore?.alias_id } },
    );

    handleCustomValuesSideModalOpen();
  }, [handleCustomValuesSideModalOpen, analytics, selectedStore, user]);

  if (isLoadingFinancialDashboardProvider || isValidatingFinancialDashboardProvider) {
    return <SkeletonLoading />;
  }

  const hasCustomValues = Boolean(customValues.length);
  const slicedCustomValues = customValues.slice(0, 3);

  if (isMultiStoreSelectMode) {
    return (
      <S.Card>
        <S.Content>
          <S.Title
            isMultiStoreSelectMode
            fontWeight={EHeadingWeight.REGULAR}
            type={EHeadingSize.H6}
          >
            Não é possível visualizar valores adicionais no modo de multiseleção de lojas
          </S.Title>
        </S.Content>

        <S.DataWrapper />
      </S.Card>
    );
  }

  return (
    <S.Card {...props}>
      {hasCustomValues ? (
        <>
          <S.Content>
            <S.Title fontWeight={EHeadingWeight.REGULAR} type={EHeadingSize.H6}>
              Valores adicionais do mês
            </S.Title>

            <S.DataWrapper>
              <S.ChartWrapper>
                <PieChart />

                <S.TextAndValueWrapper>
                  <S.ChartText>Total</S.ChartText>

                  <S.ChartValue>{`R$ ${numberFormatter(customValuesTotalAmount, 2)}`}</S.ChartValue>
                </S.TextAndValueWrapper>
              </S.ChartWrapper>

              <S.OrdersDataWrapper>
                {slicedCustomValues.map(customValue => (
                  <S.OrdersStatusWrapper key={customValue.name}>
                    <S.StatusWrapper>
                      <S.Bullet color={customValue.hex_color} />
                      <S.StatusText>{customValue.name}</S.StatusText>
                    </S.StatusWrapper>

                    <S.OrdersAmount>{`R$ ${numberFormatter(
                      customValue.amount,
                      2,
                    )}`}</S.OrdersAmount>
                  </S.OrdersStatusWrapper>
                ))}
              </S.OrdersDataWrapper>
            </S.DataWrapper>
          </S.Content>

          {!isMultiStoreSelectMode && (
            <S.SeeDetailsButton
              iconAppend={() => <ChevronRight size={12} />}
              variant={EButtonVariant.SECONDARY}
              onClick={handleMoreDetails}
            >
              Ver detalhes
            </S.SeeDetailsButton>
          )}
        </>
      ) : (
        <NoCustomValues />
      )}
    </S.Card>
  );
};

export default CustomValues;
