import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { EPlanRecurrence } from '@domain/enums/subscription/ESubscription';
import { EAlertVariant } from '@domain/enums/components/EAlert';
import { ECouponType } from '@domain/enums/common/coupon/ECouponType';
import { IParams } from '@domain/interfaces/IParams';

import { useCheckout } from '@helpers/hooks/pages/subscription/useCheckout';
import { usePersonalInfo } from '@helpers/hooks/common/store/personalInfo/usePersonalInfo';
import { useAddress } from '@helpers/hooks/common/store/address/useAddress';
import { useCoupon } from '@helpers/hooks/pages/subscription/useCoupon';

import {
  cardNumberFormatter,
  cvvFormatter,
  expirationDateFormatter,
  numberFormatter,
} from '@helpers/masks';
import { getCardIssuer } from '@helpers/subscription/payment';

import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const Payment: React.FC = () => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { legalPerson, naturalPerson } = usePersonalInfo();
  const { selectedCoupon } = useCoupon();
  const { address } = useAddress();
  const {
    register,
    errors,
    getValues,
    recurrenceType,
    isSubmittingPaymentForm,
    selectedPlan,
    watch,
  } = useCheckout();

  watch(['installment']);

  const numberRegister = register('number');
  const fullNameRegister = register('full_name');
  const expirationRegister = register('expiration');
  const verificationValueRegister = register('verification_value');
  const installmentRegister = register('installment');

  const onNumberChange = React.useCallback(
    event => {
      const formattedNumber = cardNumberFormatter(event.target.value);
      event.target.value = formattedNumber;
      numberRegister.onChange(event);
    },
    [numberRegister],
  );

  const onExpirationChange = React.useCallback(
    event => {
      const formattedExpiration = expirationDateFormatter(event.target.value);
      event.target.value = formattedExpiration;
      expirationRegister.onChange(event);
    },
    [expirationRegister],
  );

  const onVerificationValueChange = React.useCallback(
    event => {
      const number = getValues('number');
      const cardIssuer = getCardIssuer(number);
      let formattedVerificationValue = '';

      if (cardIssuer === 'American Express') {
        formattedVerificationValue = cvvFormatter(event.target.value, 4);
      } else {
        formattedVerificationValue = cvvFormatter(event.target.value);
      }

      event.target.value = formattedVerificationValue;
      verificationValueRegister.onChange(event);
    },
    [verificationValueRegister, getValues],
  );

  const getTotalPrice = React.useCallback(() => {
    if (recurrenceType === EPlanRecurrence.MONTHLY) return selectedPlan?.monthlyPrice || 0;

    if (recurrenceType === EPlanRecurrence.QUARTER) return selectedPlan?.quarterPrice || 0;

    return selectedPlan?.semesterPrice || 0;
  }, [recurrenceType, selectedPlan]);

  const getCouponDiscount = React.useCallback(() => {
    if (!selectedCoupon) return 0;

    const totalPrice = getTotalPrice();

    if (selectedCoupon.type === ECouponType.FIXED && selectedCoupon.amount <= totalPrice)
      return selectedCoupon.amount;

    if (selectedCoupon.type === ECouponType.FIXED && selectedCoupon.amount > totalPrice)
      return totalPrice;

    return (totalPrice * selectedCoupon.amount) / 100;
  }, [selectedCoupon, getTotalPrice]);

  const getTotalPriceValue = React.useCallback(() => {
    const couponDiscount = selectedCoupon ? getCouponDiscount() : 0;

    if (recurrenceType === EPlanRecurrence.MONTHLY)
      return (selectedPlan?.monthlyPrice || 0) - couponDiscount;

    if (recurrenceType === EPlanRecurrence.QUARTER)
      return (selectedPlan?.quarterPrice || 0) - couponDiscount;

    return (selectedPlan?.semesterPrice || 0) - couponDiscount;
  }, [recurrenceType, selectedPlan, selectedCoupon, getCouponDiscount]);

  const getInstallmentValue = React.useCallback(
    (installment: number) => {
      return getTotalPriceValue() / installment;
    },
    [getTotalPriceValue],
  );

  React.useEffect(() => {
    if (!address) {
      history.push(`/${storeAliasId}/subscription/personal-info${window.location.search}`);
    }

    if (address && !legalPerson && !naturalPerson) {
      history.push(`/${storeAliasId}/subscription/personal-info${window.location.search}`);
    }
  }, [address, legalPerson, naturalPerson, history, storeAliasId]);

  const isNumberError = Boolean(errors.number);
  const isFullNameError = Boolean(errors.full_name);
  const isExpirationError = Boolean(errors.expiration);
  const isVerificationValue = Boolean(errors.verification_value);

  const selectedInstallments = getValues('installment');
  const isSelectedInstallmentOne =
    selectedInstallments === undefined || selectedInstallments === '1';

  const isQuarterOrSemiAnualRecurrenceType =
    recurrenceType === EPlanRecurrence.QUARTER || recurrenceType === EPlanRecurrence.SEMIANUAL;

  const maxInstallments = recurrenceType === EPlanRecurrence.QUARTER ? 3 : 6;

  return (
    <S.Wrapper>
      <S.Header>
        <S.StepNumberWrapper>
          <S.StepNumber>3</S.StepNumber>
        </S.StepNumberWrapper>

        <S.Title fontWeight={EHeadingWeight.REGULAR}>Pagamento</S.Title>
      </S.Header>

      <S.FormContent>
        <S.InputGroup>
          <S.Label>Número do Cartão</S.Label>
          <S.Input
            {...numberRegister}
            type="text"
            placeholder="4111 1111 1111 1111"
            onChange={onNumberChange}
            data-iugu="number"
            data-openreplay-obscured
            isError={isNumberError}
          />
          {isNumberError && <Text isErrorFeedback>{errors.number.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Nome (Impresso no Cartão)</S.Label>
          <S.Input
            {...fullNameRegister}
            type="text"
            placeholder="YUNG BUDA"
            data-iugu="full_name"
            data-openreplay-obscured
            isError={isFullNameError}
          />
          {isFullNameError && <Text isErrorFeedback>{errors.full_name.message}</Text>}
        </S.InputGroup>

        <S.ExpirationDateAndSecurityCodeWrapper>
          <S.InputGroup>
            <S.Label>Validade</S.Label>
            <S.Input
              {...expirationRegister}
              type="text"
              placeholder="MM/AA"
              onChange={onExpirationChange}
              data-iugu="expiration"
              isError={isExpirationError}
            />
            {isExpirationError && <Text isErrorFeedback>{errors.expiration.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Cód. Segurança (CVV)</S.Label>
            <S.Input
              {...verificationValueRegister}
              type="text"
              placeholder="123"
              onChange={onVerificationValueChange}
              data-iugu="verification_value"
              data-openreplay-obscured
              isError={isVerificationValue}
            />
            {isVerificationValue && (
              <Text isErrorFeedback>{errors.verification_value.message}</Text>
            )}
          </S.InputGroup>
        </S.ExpirationDateAndSecurityCodeWrapper>

        {isQuarterOrSemiAnualRecurrenceType && (
          <>
            <S.InputGroup>
              <S.Label>Defina quantas parcelas</S.Label>
              <S.Select {...installmentRegister} defaultValue="1">
                {Array.from(Array(maxInstallments).keys()).map(installment => (
                  <S.Option value={installment + 1} key={installment}>{`Em ${
                    installment + 1
                  }x de R$${numberFormatter(
                    getInstallmentValue(installment + 1),
                    2,
                  )} sem juros`}</S.Option>
                ))}
              </S.Select>
            </S.InputGroup>

            {!isSelectedInstallmentOne && (
              <S.InstallmentAlert
                variant={EAlertVariant.WARNING}
                description="Caso você utilize Conta Simples ou um cartão pré-pago não será possível realizar o
                  parcelamento."
              />
            )}
          </>
        )}

        <S.NextStepButton isLoading={isSubmittingPaymentForm}>Continuar</S.NextStepButton>
      </S.FormContent>
    </S.Wrapper>
  );
};

export default Payment;
