import styled from 'styled-components/macro';
import * as DropDownMenu from '@radix-ui/react-dropdown-menu';

export const Wrapper = styled(DropDownMenu.Trigger)`
  padding: unset;
  background-color: unset;
  border: unset;
  box-shadow: unset;

  &:focus {
    outline: unset;
  }
`;
