import React from 'react';

import { HourglassHigh } from 'phosphor-react';
import { useReactFlow } from 'react-flow-renderer';
import { v4 } from 'uuid';

import { ENodes } from '@domain/enums/dashboard/automations/node/ENodes';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';
import { selectDefaultWaitNodeContent } from '@helpers/utils/dashboard/automations/Nodes/selectDefailtWaitNodeContent';

import Option from '../Option';

import * as S from './styles';

const TimeTab: React.FC = () => {
  const { trackNode } = useAutomation();

  const reactFlowInstance = useReactFlow();

  const onDragStartWaitNode = (event: React.DragEvent<HTMLDivElement>): void => {
    event.dataTransfer.setData('application/reactflow', ENodes.WAIT);
    event.dataTransfer.effectAllowed = 'move';
  };

  const handleClickWaitNode = React.useCallback(() => {
    const rules = [];

    rules.push(selectDefaultWaitNodeContent());

    const type = ENodes.WAIT;

    const newNode = {
      id: v4(),
      type,
      position: {
        x: Math.random() * 500,
        y: Math.random() * 500,
      },
      data: { label: `${type}`, rules, name: v4() },
    };

    reactFlowInstance.addNodes(newNode);

    trackNode(type);
  }, [reactFlowInstance, trackNode]);

  return (
    <S.Wrapper>
      <Option onClick={handleClickWaitNode} onDragStart={onDragStartWaitNode} icon={HourglassHigh}>
        Aguardar
      </Option>
    </S.Wrapper>
  );
};

export default TimeTab;
