import { IHomeOnboardingSteps } from '@domain/interfaces/dashboard/Home/IHome';

export const ONBOARDING_DROPSHIPPING_STEPS: Array<IHomeOnboardingSteps> = [
  {
    title: 'Integração de Loja',
    description: 'Conecte a sua loja à plataforma que você utiliza.',
    linkTo: '/dashboard/data/integrations',
    id: 'has_store_integration',
  },
  {
    title: 'Gateway de Pagamento',
    description: 'Vincule a sua loja a um provedor de pagamento.',
    linkTo: '/dashboard/data/integrations',
    id: 'has_payment_gateway',
  },

  {
    title: 'Taxa de Checkout',
    description: 'Cadastre sua taxa de checkout.',
    linkTo: '/dashboard/data/checkout-fee',
    id: 'has_checkout_fee',
  },
];

export const SIDE_MODAL_ONBOARDING_DROPSHIPPING_STEPS: Array<IHomeOnboardingSteps> = [
  {
    title: 'Integração de Loja',
    description: 'Conecte a sua loja à plataforma que você utiliza.',
    linkTo: '/dashboard/data/integrations',
    id: 'has_store_integration',
  },
  {
    title: 'Gateway de Pagamento',
    description: 'Vincule a sua loja a um provedor de pagamento.',
    linkTo: '/dashboard/data/integrations',
    id: 'has_payment_gateway',
  },

  {
    title: 'Taxa de Checkout',
    description: 'Cadastre sua taxa de checkout.',
    linkTo: '/dashboard/data/checkout-fee',
    id: 'has_checkout_fee',
  },
];

export const ONBOARDING_ECOMMERCE_STEPS: Array<IHomeOnboardingSteps> = [
  {
    title: 'Integração de Loja',
    description: 'Conecte a sua loja à plataforma que você utiliza.',
    linkTo: '/dashboard/data/integrations',
    id: 'has_store_integration',
  },
  {
    title: 'Gateway de Pagamento',
    description: 'Vincule a sua loja a um provedor de pagamento.',
    linkTo: '/dashboard/data/integrations',
    id: 'has_payment_gateway',
  },
];

export const SIDE_MODAL_ONBOARDING_ECOMMERCE_STEPS: Array<IHomeOnboardingSteps> = [
  {
    title: 'Integração de Loja',
    description: 'Conecte a sua loja à plataforma que você utiliza.',
    linkTo: '/dashboard/data/integrations',
    id: 'has_store_integration',
  },
  {
    title: 'Gateway de Pagamento',
    description: 'Vincule a sua loja a um provedor de pagamento.',
    linkTo: '/dashboard/data/integrations',
    id: 'has_payment_gateway',
  },
];

export const ONBOARDING_INFO_PRODUCT_STEPS: Array<IHomeOnboardingSteps> = [
  {
    title: 'Integração de Loja',
    description: 'Conecte a sua loja à plataforma que você utiliza.',
    linkTo: '/dashboard/data/integrations',
    id: 'has_store_integration',
  },
];

export const SIDE_MODAL_ONBOARDING_INFO_PRODUCT_STEPS: Array<IHomeOnboardingSteps> = [
  {
    title: 'Integração de Loja',
    description: 'Conecte a sua loja à plataforma que você utiliza.',
    linkTo: '/dashboard/data/integrations',
    id: 'has_store_integration',
  },
  {
    title: 'Marketing',
    description: 'Conecte a sua loja às plataformas de tráfego.',
    linkTo: '/dashboard/data/integrations',
    id: 'has_marketing_integration',
  },
];

export const FINISHED_ONBOARDING_DROPSHIPPING_STEPS: Array<IHomeOnboardingSteps> = [
  {
    title: 'Configurar Dsers',
    description: 'Sincronize seus custos de produto e frete diretamente com o Aliexpress',
    linkTo: '/dashboard/data/integrations',
    id: 'has_dsers_credential',
  },
  {
    title: 'Configurar Frete',
    description: 'Configure como seu frete será contabilizado',
    linkTo: '/dashboard/data/shipping',
    id: 'has_default_shipping',
  },
  {
    title: 'Automações',
    description: 'Multiplique as suas vendas com a automação de mensagens',
    linkTo: '/dashboard/marketing/automations',
    id: 'has_message_automations',
  },
  {
    title: 'Marketing',
    description: 'Conecte a sua loja às plataformas de tráfego.',
    linkTo: '/dashboard/data/integrations',
    id: 'has_marketing_integration',
  },
  {
    title: 'Gerenciador de Ads',
    description: 'Gerencia suas campanhas de marketing para escalar',
    linkTo: '/dashboard/marketing/ads-manager',
    id: 'has_ads_manager_configured',
  },
  {
    title: 'Custo de Produtos',
    description: 'Veja se os custos de seus produtos estão todos corretos',
    linkTo: '/dashboard/data/products-costs',
    id: 'has_product_cost_configured',
  },
  {
    title: 'Valores Adicionais',
    description: 'Adicione custos fixos da sua operação',
    linkTo: '/dashboard/custom-values/out',
    id: 'has_custom_values_configured',
  },
  {
    title: 'Impostos',
    description: 'Configure o regime tributário da sua empresa',
    linkTo: '/dashboard/data/tax',
    id: 'has_tax_configured',
  },
];

export const FINISHED_ONBOARDING_ECOMMERCE_STEPS: Array<IHomeOnboardingSteps> = [
  {
    title: 'Configurar Frete',
    description: 'Configure como seu frete será contabilizado',
    linkTo: '/dashboard/data/shipping',
    id: 'has_default_shipping',
  },
  {
    title: 'Automações',
    description: 'Multiplique as suas vendas com a automação de mensagens',
    linkTo: '/dashboard/marketing/automations',
    id: 'has_message_automations',
  },
  {
    title: 'Marketing',
    description: 'Conecte a sua loja às plataformas de tráfego.',
    linkTo: '/dashboard/data/integrations',
    id: 'has_marketing_integration',
  },
  {
    title: 'Gerenciador de Ads',
    description: 'Gerencia suas campanhas de marketing para escalar',
    linkTo: '/dashboard/marketing/ads-manager',
    id: 'has_ads_manager_configured',
  },
  {
    title: 'Custo de Produtos',
    description: 'Veja se os custos de seus produtos estão todos corretos',
    linkTo: '/dashboard/data/products-costs',
    id: 'has_product_cost_configured',
  },
  {
    title: 'Valores Adicionais',
    description: 'Adicione custos fixos da sua operação',
    linkTo: '/dashboard/custom-values/out',
    id: 'has_custom_values_configured',
  },
  {
    title: 'Impostos',
    description: 'Configure o regime tributário da sua empresa',
    linkTo: '/dashboard/data/tax',
    id: 'has_tax_configured',
  },
];

export const FINISHED_ONBOARDING_INFO_PRODUCT_STEPS: Array<IHomeOnboardingSteps> = [
  {
    title: 'Automações',
    description: 'Multiplique as suas vendas com a automação de mensagens',
    linkTo: '/dashboard/marketing/automations',
    id: 'has_message_automations',
  },
  {
    title: 'Marketing',
    description: 'Conecte a sua loja às plataformas de tráfego.',
    linkTo: '/dashboard/data/integrations',
    id: 'has_marketing_integration',
  },
  {
    title: 'Gerenciador de Ads',
    description: 'Gerencia suas campanhas de marketing para escalar',
    linkTo: '/dashboard/marketing/ads-manager',
    id: 'has_ads_manager_configured',
  },
  {
    title: 'Custo de Produtos',
    description: 'Veja se os custos de seus produtos estão todos corretos',
    linkTo: '/dashboard/data/products-costs',
    id: 'has_product_cost_configured',
  },
  {
    title: 'Valores Adicionais',
    description: 'Adicione custos fixos da sua operação',
    linkTo: '/dashboard/custom-values/out',
    id: 'has_custom_values_configured',
  },
  {
    title: 'Impostos',
    description: 'Configure o regime tributário da sua empresa',
    linkTo: '/dashboard/data/tax',
    id: 'has_tax_configured',
  },
];
