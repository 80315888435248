import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const TaxSubtitle = styled(TextBase)`
  font-size: 0.875rem;
`;

export const TaxText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.green.default};
`;

export const DefaultTaxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;
