import TextBase from '@components/common/core/DataDisplay/Text';
import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  width: 100%;
  border-radius: 12px;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  box-sizing: border-box;
  align-items: center;
`;

export const TitleCard = styled(TextBase)`
  font-size: 1rem;
  font-weight: 500;
  line-height: 120%;
`;

export const Text = styled(TextBase)`
  color: #00f2a1;
  line-height: 100%;
`;

export const PriceWrapper = styled.div`
  background-color: #183231;
  border-color: #00f2a1;
  border: 1px solid #00f2a1;
  border-radius: 4px;
  padding: 4px 16px 4px 16px;
  margin-left: auto;
`;
