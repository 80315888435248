import React from 'react';

import { useFinancialData } from '@helpers/hooks/pages/dashboard/financialData/useFinancialData';
import { cpfFormatter, phone } from '@helpers/masks';

import * as S from './styles';

const LegalPerson: React.FC = () => {
  const { register, naturalPerson } = useFinancialData();

  const cpfInput = register('cpf');
  const phoneInput = register('phone');

  return (
    <>
      <S.CpfAndNameWrapper>
        <S.InputGroup>
          <S.Label>CPF</S.Label>
          <S.TextField
            {...cpfInput}
            type="text"
            defaultValue={cpfFormatter(naturalPerson?.cpf)}
            onChange={event => {
              cpfInput.onChange(event);

              const { value } = event.target;

              event.target.value = cpfFormatter(value);
            }}
          />
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Nome</S.Label>
          <S.TextField {...register('Name')} type="text" />
        </S.InputGroup>
      </S.CpfAndNameWrapper>

      <S.PhoneAndEmailWrapper>
        <S.InputGroup>
          <S.Label>Telefone de contato</S.Label>
          <S.TextField
            {...phoneInput}
            type="text"
            onChange={event => {
              phoneInput.onChange(event);

              const { value } = event.target;

              event.target.value = phone(value);
            }}
          />
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>E-mail</S.Label>
          <S.TextField {...register('email')} type="text" />
        </S.InputGroup>
      </S.PhoneAndEmailWrapper>
    </>
  );
};

export default LegalPerson;
