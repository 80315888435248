import { EDropshippingProvider } from '@domain/enums/common/integrations/EDropshippingProvider';
import { IStoreIntegrations } from '@domain/interfaces/stores/IStores';

import { DROPSHIPPING_PROVIDERS } from '@constants/common/integrations';

export const getStoreProviderToSync = (storeIntegrations: IStoreIntegrations): string => {
  const { store_front } = storeIntegrations;

  const filteredStoreProvider = store_front.filter(storeProvider =>
    DROPSHIPPING_PROVIDERS.find(provider => provider.identifier === storeProvider.name),
  );

  const activeStoreProvider = filteredStoreProvider.find(storeProvider => storeProvider.is_active);

  return activeStoreProvider?.name || EDropshippingProvider.SHOPIFY;
};

export const getStoreProvider = (
  storeIntegrations: IStoreIntegrations,
): typeof DROPSHIPPING_PROVIDERS[number] | undefined => {
  const foundActiveProvider = storeIntegrations.store_front.find(
    integration => integration.is_active,
  );

  if (foundActiveProvider) {
    const foundProvider = DROPSHIPPING_PROVIDERS.find(
      integration => integration.identifier === foundActiveProvider.name,
    ) as typeof DROPSHIPPING_PROVIDERS[number];

    return foundProvider;
  }

  return undefined;
};
