import React from 'react';

import { useTheme } from 'styled-components';
import { X as CloseIcon, LightbulbFilament } from 'phosphor-react';

import { ISuggestIntegrationModalProps } from '@domain/interfaces/dashboard/Automations/Canvas/SideModal/Integration/ISuggestIntegrationModal';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';
import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';

import SuggestionCard from './SuggestionCard';

import * as S from './styles';

const SuggestIntegrationModal: React.FC<ISuggestIntegrationModalProps> = ({ isOpen, toggle }) => {
  const theme = useTheme();

  const { toast } = useToast();
  const { suggestions } = useAutomation();
  const { analytics, selectedStore } = useConfig();

  const [showSuggestionInput, setShowSuggestionInput] = React.useState<boolean>(false);
  const [isSendingSuggestion, setIsSendingSuggestion] = React.useState<boolean>(false);
  const [newSuggestion, setNewSuggestion] = React.useState<string>('');

  const toggleSuggestionInput = React.useCallback(
    () => setShowSuggestionInput(currentState => !currentState),
    [],
  );

  const closeModal = React.useCallback(() => {
    toggle();

    setShowSuggestionInput(false);
  }, [toggle]);

  const onInputChange = React.useCallback(event => {
    setNewSuggestion(event.target.value);
  }, []);

  const onSendSuggestion = React.useCallback(async () => {
    setIsSendingSuggestion(true);

    try {
      toast.success('Sugestão enviada com sucesso.');

      analytics?.track(
        'Automation Tool Suggestion Created',
        { suggestion: newSuggestion },
        { context: { groupId: selectedStore?.alias_id } },
      );

      setIsSendingSuggestion(false);

      toggle();
    } catch (error: any) {
      setIsSendingSuggestion(false);
      toast.error(error?.response?.data?.message);
    }
  }, [toast, newSuggestion, toggle, analytics, selectedStore]);

  const hasSuggestions = Boolean(suggestions?.length);

  return (
    <S.Wrapper isOpen={isOpen} toggle={closeModal}>
      <S.Container>
        <S.Row>
          <S.GroupTitle>
            <LightbulbFilament size={28} color={theme.colors.gray[1]} />
            <S.Title>Sugerir nova integração</S.Title>
          </S.GroupTitle>
          <S.CloseButton onClick={closeModal}>
            <CloseIcon size={20} color={theme.colors.gray[3]} />
          </S.CloseButton>
        </S.Row>

        <S.Description>
          Vote ou sugira na integração que você espera dentro da Profitfy.
        </S.Description>

        <S.CardList>
          {hasSuggestions &&
            suggestions.map(suggestion => (
              <SuggestionCard key={suggestion.id} suggestion={suggestion} />
            ))}
        </S.CardList>
      </S.Container>

      <S.SuggestionContainer>
        {!showSuggestionInput && (
          <S.SuggestionButton onClick={toggleSuggestionInput}>
            <S.SuggestionText>Sugerir outra integração</S.SuggestionText>
          </S.SuggestionButton>
        )}

        {showSuggestionInput && (
          <S.Input
            placeholder="Nome da integração"
            type="text"
            onChange={onInputChange}
            button="Enviar Sugestão"
            buttonLoading={isSendingSuggestion}
            onClick={onSendSuggestion}
          />
        )}
      </S.SuggestionContainer>
    </S.Wrapper>
  );
};

export default SuggestIntegrationModal;
