import styled, { css } from 'styled-components/macro';

import { IProfitTextProps } from '@domain/interfaces/dashboard/AdsManager/Table/Content/IProfit';

import TextBase from '@components/common/core/DataDisplay/Text';

export const TotalLabel = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
  line-height: 120%;
  white-space: nowrap;
`;

export const ForeCastAmount = styled(TextBase)<IProfitTextProps>`
  color: ${({ theme }) => theme.colors.gray[2]};
  line-height: 120%;
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;

  ${({ amount, theme }) =>
    amount === 0 &&
    css`
      color: ${theme.colors.gray[1]};
    `};

  ${({ amount, theme }) =>
    amount < 0 &&
    css`
      color: ${theme.colors.danger.default};
    `};
`;

export const TotalAmountText = styled(TextBase)<IProfitTextProps>`
  line-height: 120%;
  white-space: nowrap;

  ${({ amount, theme }) =>
    amount === 0 &&
    css`
      color: ${theme.colors.gray[1]};
    `};

  ${({ amount, theme }) =>
    amount < 0 &&
    css`
      color: ${theme.colors.danger.default};
    `};

  ${({ amount, theme }) =>
    amount > 0 &&
    css`
      color: ${theme.colors.success.default};
    `};
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 4px;
  overflow: hidden;
`;
