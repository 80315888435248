import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ETableType } from '@domain/enums/dashboard/financialDetails/EFinancialDetails';
import { IParams } from '@domain/interfaces/IParams';

import {
  FINANCIAL_DETAILS_FILTER_CONFIG,
  FINANCIAL_DETAILS_FILTER_BY_DAY_CONFIG,
} from '@helpers/utils/filters/financialDetailsFilter';

import { useFinancialDetails } from '@helpers/hooks/pages/dashboard/financialDetails/useFinancialDetails';
import { useConfig } from '@helpers/hooks/useConfig';

import LimitationModal from '@components/common/core/Utils/LimitationModal';
import PageError from '@components/common/core/Utils/PageError';
import SideModal from '@components/common/core/DataDisplay/SideModal';
import ExportSpreadSheetSideModalContent from '@components/Dashboard/FinancialDashboard/SpreadSheetSideModal';
import Filter from '@components/common/core/Utils/Filter';
import GoBack from '@components/common/core/Utils/GoBack';
import InstantHelp from '@components/common/core/Utils/InstantHelp';
import Header from '../Header';
import PeriodTable from '../PeriodTable';
import DailyTable from '../DailyTable';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const history = useHistory();
  const { analytics, user } = useConfig();
  const {
    tableType,
    isFinancialDetailsError,
    isValidatingFinancialDetails,
    mutate,
    isExportSpreadSheetOpen,
    handleExportSpreadSheetOpen,
    filterData,
    handleFilterData,
    isNoExportSpreadSheetPermissionModalOpen,
    handleNoExportSpreadSheetPermissionModalOpen,
  } = useFinancialDetails();
  const { storeAliasId } = useParams<IParams>();

  const goBack = React.useCallback(() => {
    history.goBack();
  }, [history]);

  React.useEffect(() => {
    analytics?.track(
      'Financial Details Viewed',
      {
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );
    analytics?.trackHubSpot(storeAliasId, { financial_details_viewed: true });
  }, [storeAliasId, analytics, user]);

  if (isFinancialDetailsError && !isValidatingFinancialDetails) {
    return <PageError mutate={mutate} />;
  }

  const filterConfig =
    tableType === ETableType.PERIOD
      ? FINANCIAL_DETAILS_FILTER_CONFIG
      : FINANCIAL_DETAILS_FILTER_BY_DAY_CONFIG;

  return (
    <S.Wrapper tableType={tableType}>
      <GoBack text="Voltar" onClick={goBack} />

      <Header />

      {Boolean(filterData.length) && (
        <Filter config={filterConfig} handleFilterData={handleFilterData} filterData={filterData} />
      )}

      {tableType === ETableType.PERIOD ? <PeriodTable /> : <DailyTable />}

      <InstantHelp
        name="financial_details"
        title="Detalhes Financeiros"
        articleLink="https://ajuda.profitfy.me/pt-BR/articles/6483940-aba-de-detalhes-financeiros"
      />

      <SideModal isOpen={isExportSpreadSheetOpen} toggle={handleExportSpreadSheetOpen}>
        <ExportSpreadSheetSideModalContent toggle={handleExportSpreadSheetOpen} />
      </SideModal>

      <LimitationModal
        isOpen={isNoExportSpreadSheetPermissionModalOpen}
        toggle={handleNoExportSpreadSheetPermissionModalOpen}
        imageSrc="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/temp/limitation-temp.svg"
        title="Exportação de planilhas não disponível"
        description="Escolha um plano que te permita exportar planilhas para que consiga usufruir o melhor da ferramenta."
        trackingPropertyName="NO_EXPORT_SPREADSHEET_FEATURE"
      />
    </S.Wrapper>
  );
};

export default Wrapper;
