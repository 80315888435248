import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { EBadgeColors } from '@domain/enums/components/EBadge';
import { EButtonSize } from '@domain/enums/components/EButton';
import { IProductProps } from '@domain/interfaces/dashboard/Feed/IProduct';
import { IParams } from '@domain/interfaces/IParams';

import { useConfig } from '@helpers/hooks/useConfig';

import { getProviderIcon } from '@helpers/dashboard/productCost/productCostUtils';

import * as S from './styles';

const Product: React.FC<IProductProps> = ({ product }) => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { analytics, user } = useConfig();

  const [isImageError, setIsImageError] = React.useState<boolean>(false);

  const onEditCostClick = React.useCallback(() => {
    analytics?.track(
      'Task Feed Ad Account Blocked Button Clicked',
      {
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );

    history.push(`/${storeAliasId}/dashboard/data/products-costs/${product.alias_id}`);
  }, [history, storeAliasId, product, analytics, user]);

  const ProviderIcon = getProviderIcon(product?.provider);

  return (
    <S.Wrapper>
      <S.ProductDetails>
        <S.ProductImageWrapper>
          {product.image_url && !isImageError ? (
            <S.ProductImage
              src={product.image_url}
              alt="Profitfy Store Product"
              onError={() => setIsImageError(true)}
            />
          ) : (
            <S.NoImage />
          )}

          <S.ProviderIconWrapper>
            <ProviderIcon size={20} color="white" />
          </S.ProviderIconWrapper>
        </S.ProductImageWrapper>
        <S.ProductName>{product.name}</S.ProductName>
      </S.ProductDetails>

      <S.ProductStatus color={EBadgeColors.RED}>Sem edição de custos</S.ProductStatus>

      <S.ButtonsWrapper>
        <S.ActionButton buttonSize={EButtonSize.SMALL} onClick={onEditCostClick}>
          Editar custo
        </S.ActionButton>
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export default Product;
