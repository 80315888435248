import React from 'react';

import { ISwitchDeniedCardNodeModalSelect } from '@domain/interfaces/dashboard/Automations/Canvas/Node/SwitchNode/ISwitchDeniedCardNodeModal';
import { EDeniedCardType } from '@domain/enums/dashboard/automations/node/switchNode/EDeniedCard';

import { currencyFormatter } from '@helpers/masks';
import { getHandleColorSwitchNode } from '@helpers/utils/dashboard/automations/Nodes/getHandleColorSwitchNode';

import OptionProduct from './OptionProduct';
import OptionTotalValue from './OptionTotalValue';

import * as S from './styles';

const RuleSelect: React.FC<ISwitchDeniedCardNodeModalSelect> = ({
  value,
  index,
  onUpdate,
  ruleWithProblem,
}) => {
  const handleChangeOptionsProduct = React.useCallback(
    name => {
      onUpdate({ ...value, options: name }, index);
    },
    [value, index, onUpdate],
  );

  const handleChangeOptionsTotalValue = React.useCallback(
    name => {
      onUpdate({ ...value, options: name }, index);
    },
    [value, index, onUpdate],
  );

  const handleChangeInput = React.useCallback(
    text => {
      onUpdate({ ...value, input: text }, index);
    },
    [value, index, onUpdate],
  );

  const handleChangeProductInput = React.useCallback(
    e => {
      handleChangeInput(e.target.value);
    },
    [handleChangeInput],
  );

  const handleChangeTotalValueInput = React.useCallback(
    e => {
      handleChangeInput(currencyFormatter(e.target.value));

      e.target.value = currencyFormatter(e.target.value);
    },
    [handleChangeInput],
  );

  return (
    <S.Wrapper>
      <S.RuleRouteTitle>
        <S.RuleRouteWrapper>
          <S.RuleColorIndicator style={{ backgroundColor: getHandleColorSwitchNode(index + 1) }} />
          <S.RuleRouteNumber>Caso #{index + 1}</S.RuleRouteNumber>
        </S.RuleRouteWrapper>
      </S.RuleRouteTitle>

      {value?.type === EDeniedCardType.PRODUCT_NAME && (
        <S.RulesWrapper>
          <S.Row>
            <OptionProduct onChange={handleChangeOptionsProduct} defaultValue={value.options} />

            <S.TextField
              type="text"
              defaultValue={value.input}
              onChange={handleChangeProductInput}
            />
          </S.Row>

          {index === ruleWithProblem && (
            <S.RuleError>
              Preencha esse campo. Apenas o último campo pode ser deixado vazio.
            </S.RuleError>
          )}
        </S.RulesWrapper>
      )}

      {value?.type === EDeniedCardType.REJECTED_PAYMENT_TOTAL_VALUE && (
        <S.RulesWrapper>
          <S.Row>
            <OptionTotalValue onChange={handleChangeOptionsTotalValue} />

            <S.TextField
              type="text"
              defaultValue={value.input}
              onChange={handleChangeTotalValueInput}
            />
          </S.Row>

          {index === ruleWithProblem && (
            <S.RuleError>
              Preencha esse campo. Apenas o último campo pode ser deixado vazio.
            </S.RuleError>
          )}
        </S.RulesWrapper>
      )}
    </S.Wrapper>
  );
};

export default RuleSelect;
