import React from 'react';

import { useTheme } from 'styled-components';
import { WhatsappLogo } from 'phosphor-react';

import { IInstanceCardProps } from '@domain/interfaces/dashboard/Automations/IInstanceCard';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';

import * as S from './styles';

const InstanceCard: React.FC<IInstanceCardProps> = ({ instance, addInstance, removeInstance }) => {
  const theme = useTheme();

  const { whatsappCredentialsWorkflow } = useAutomation();

  const [checked, setChecked] = React.useState<boolean>(false);

  const credentialAliasId = instance.whatsapp_credential.alias_id;

  React.useEffect(() => {
    if (!whatsappCredentialsWorkflow) return;

    const foundWhatsAppCredentialWorkflow = whatsappCredentialsWorkflow.find(
      whatsapp_credential_workflow =>
        whatsapp_credential_workflow.whatsapp_credential_id === instance.whatsapp_credential.id,
    );

    if (foundWhatsAppCredentialWorkflow) {
      setChecked(true);
      addInstance(credentialAliasId);
    } else {
      setChecked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whatsappCredentialsWorkflow]);

  const handleCheckBoxChange = React.useCallback(
    e => {
      const nextState = !checked;

      if (nextState) {
        addInstance(e.value);
      } else {
        removeInstance(e.value);
      }
      setChecked(nextState);
    },
    [checked, addInstance, removeInstance],
  );

  const phone = `+55 (${instance.whatsapp_credential.phone_prefix}) ${instance.whatsapp_credential.phone_number}`;

  return (
    <S.Wrapper>
      <S.Group>
        <S.CheckBox
          name={`switch-${credentialAliasId}`}
          id={`switch-${credentialAliasId}`}
          defaultChecked={checked}
          value={credentialAliasId}
          onChange={handleCheckBoxChange}
        />

        <S.WhatAppIconContainer>
          <WhatsappLogo size={20} color={theme.colors.white.default} />
        </S.WhatAppIconContainer>

        <S.Name>{instance.whatsapp_credential.name}</S.Name>
      </S.Group>

      <S.Phone>{phone}</S.Phone>
    </S.Wrapper>
  );
};

export default InstanceCard;
