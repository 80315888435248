import styled from 'styled-components/macro';

import BadgeBase from '@components/common/core/DataDisplay/Badge';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`;

export const BadgeTag = styled(BadgeBase)`
  background-color: ${({ theme }) => theme.colors.gray[4]};
  color: ${({ theme }) => theme.colors.gray[1]};
`;
