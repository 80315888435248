import styled from 'styled-components/macro';

export const TableWrapper = styled.div`
  border-radius: 5px;
  overflow: hidden;

  & > div {
    padding-top: 42px;

    ::-webkit-scrollbar {
      width: 8px;
      position: absolute;
    }

    ::-webkit-scrollbar:horizontal {
      height: 12px;
      width: 4px;
      position: absolute;
    }

    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.gray[4]};
      position: absolute;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.gray[1]};
      border-radius: 25px;
      transition: all 0.3s;
    }

    ::-webkit-scrollbar-thumb:horizontal {
      height: 1px;
      background-color: ${({ theme }) => theme.colors.gray[1]};
      border-radius: 25px;
      transition: all 0.3s;
    }

    ::-webkit-scrollbar-thumb:hover {
      opacity: brightness(0.8);
    }

    & > table {
      position: relative;
      border-collapse: collapse;
    }

    & > table > thead {
      display: block;
      margin-top: -42px;
      position: sticky;
      height: 42px;
      top: -42px;
      z-index: ${({ theme }) => theme.zIndex.firstLayer};
    }

    & > table > thead > tr {
      & > th {
        border: unset;
        padding: 10px 12px !important;
        box-sizing: border-box;
      }

      & > th:nth-child(1) {
        min-width: 58px;
        width: 58px;
        padding-left: 24px !important;
        padding-right: 0 !important;

        @media only screen and (max-width: 1415px) {
          min-width: 44px;
          width: 44px;
        }
      }

      & > th:nth-child(2) {
        min-width: 88px;
        width: 88px;
      }

      & > th:nth-child(3) {
        min-width: 136px;
        width: 136px;

        @media only screen and (max-width: 1415px) {
          min-width: 132px;
          width: 132px;
        }
      }

      & > th:nth-child(4) {
        min-width: 132px;
        width: 132px;
      }

      & > th:nth-child(5) {
        min-width: 116px;
        width: 116px;
      }

      & > th:nth-child(6) {
        min-width: 152px;
        width: 152px;

        @media only screen and (max-width: 1415px) {
          min-width: 132px;
          width: 132px;
        }
      }

      & > th:nth-child(7) {
        min-width: 156px;
        width: 156px;

        @media only screen and (max-width: 1415px) {
          min-width: 144px;
          width: 144px;
        }
      }

      & > th:nth-child(8) {
        min-width: 88px;
        width: 88px;
      }

      & > th:nth-child(9) {
        min-width: 124px;
        width: 124px;
      }

      & > th:nth-child(10) {
        min-width: 116px;
        width: 116px;
      }

      & > th:nth-child(12) {
        min-width: 56px;
        width: 56px;
        padding-left: 0 !important;
        padding-right: 24px !important;

        @media only screen and (max-width: 1415px) {
          min-width: 48px;
          width: 48px;
        }
      }
    }

    & > table > tbody > tr {
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray[4]};

      & > td {
        border: unset;
        height: 52px !important;
        padding: 8px 12px !important;
        box-sizing: border-box;
      }

      & > td:nth-child(1) {
        min-width: 58px;
        width: 58px;
        padding-left: 24px !important;
        padding-right: 0 !important;

        @media only screen and (max-width: 1415px) {
          min-width: 44px;
          width: 44px;
        }
      }

      & > td:nth-child(2) {
        min-width: 88px;
        width: 88px;
      }

      & > td:nth-child(3) {
        min-width: 136px;
        width: 136px;

        @media only screen and (max-width: 1415px) {
          min-width: 132px;
          width: 132px;
        }
      }

      & > td:nth-child(4) {
        min-width: 132px;
        width: 132px;
      }

      & > td:nth-child(5) {
        min-width: 116px;
        width: 116px;
      }

      & > td:nth-child(6) {
        min-width: 152px;
        width: 152px;

        @media only screen and (max-width: 1415px) {
          min-width: 132px;
          width: 132px;
        }
      }

      & > td:nth-child(7) {
        min-width: 156px;
        width: 156px;

        @media only screen and (max-width: 1415px) {
          min-width: 144px;
          width: 144px;
        }
      }

      & > td:nth-child(8) {
        min-width: 88px;
        width: 88px;
      }

      & > td:nth-child(9) {
        min-width: 124px;
        width: 124px;
      }

      & > td:nth-child(10) {
        min-width: 116px;
        width: 116px;
      }

      & > td:nth-child(12) {
        min-width: 56px;
        width: 56px;
        padding-left: 0 !important;
        padding-right: 24px !important;

        @media only screen and (max-width: 1415px) {
          min-width: 48px;
          width: 48px;
        }
      }
    }
  }
`;

export const Container = styled.div`
  max-width: 1280px;
  width: 100%;
`;
