import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { EHeadAlign } from '@domain/enums/components/ETable';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.FirstStepWrapper>
      <S.HeaderWrapper>
        <Skeleton width="100%" height={52} />
      </S.HeaderWrapper>

      <S.NewVariantCostWrapper>
        <S.ProductNameWrapper>
          <Skeleton width="100%" height={19} />
        </S.ProductNameWrapper>

        <S.InputGroupWrapper>
          <S.LabelWrapper>
            <Skeleton width="100%" height={19} />
          </S.LabelWrapper>

          <S.InputWrapper>
            <Skeleton width="100%" height={42} />
          </S.InputWrapper>
        </S.InputGroupWrapper>

        <S.InputGroupWrapper>
          <S.LabelWrapper>
            <Skeleton width="100%" height={19} />
          </S.LabelWrapper>

          <S.InputWrapper>
            <Skeleton width="100%" height={42} />
          </S.InputWrapper>
        </S.InputGroupWrapper>

        <S.InputGroupWrapper>
          <S.LabelWrapper>
            <Skeleton width="100%" height={19} />
          </S.LabelWrapper>

          <S.DateRangePickerWrapper>
            <S.DateWrapper>
              <S.LabelWrapper>
                <Skeleton width="100%" height={19} />
              </S.LabelWrapper>

              <S.DateInputWrapper>
                <Skeleton width="100%" height={42} />
              </S.DateInputWrapper>
            </S.DateWrapper>

            <S.DateWrapper>
              <S.LabelWrapper>
                <Skeleton width="100%" height={19} />
              </S.LabelWrapper>

              <S.DateInputWrapper>
                <Skeleton width="100%" height={42} />
              </S.DateInputWrapper>
            </S.DateWrapper>
          </S.DateRangePickerWrapper>
        </S.InputGroupWrapper>

        <S.ButtonWrapper>
          <Skeleton width="100%" height={42} />
        </S.ButtonWrapper>
      </S.NewVariantCostWrapper>

      <S.HorizontalRule />

      <S.TableWrapper>
        <S.Table>
          <S.TableHeader>
            <S.TableRow>
              <S.TableHead>Data Inicial</S.TableHead>
              <S.TableHead>Data Final</S.TableHead>
              <S.TableHead align={EHeadAlign.RIGHT}>Custo</S.TableHead>
              <S.TableHead align={EHeadAlign.CENTER}>Ações</S.TableHead>
            </S.TableRow>
          </S.TableHeader>

          <S.TableBody>
            <S.TableRow>
              <S.TableData>
                <Skeleton width={64} height={19} />
              </S.TableData>
              <S.TableData>
                <Skeleton width={64} height={19} />
              </S.TableData>
              <S.TableData align={EHeadAlign.RIGHT}>
                <Skeleton width={64} height={19} />
              </S.TableData>
              <S.TableData align={EHeadAlign.CENTER}>
                <Skeleton width={64} height={19} />
              </S.TableData>
            </S.TableRow>

            <S.TableRow>
              <S.TableData>
                <Skeleton width={64} height={19} />
              </S.TableData>
              <S.TableData>
                <Skeleton width={64} height={19} />
              </S.TableData>
              <S.TableData align={EHeadAlign.RIGHT}>
                <Skeleton width={64} height={19} />
              </S.TableData>
              <S.TableData align={EHeadAlign.CENTER}>
                <Skeleton width={64} height={19} />
              </S.TableData>
            </S.TableRow>

            <S.TableRow>
              <S.TableData>
                <Skeleton width={64} height={19} />
              </S.TableData>
              <S.TableData>
                <Skeleton width={64} height={19} />
              </S.TableData>
              <S.TableData align={EHeadAlign.RIGHT}>
                <Skeleton width={64} height={19} />
              </S.TableData>
              <S.TableData align={EHeadAlign.CENTER}>
                <Skeleton width={64} height={19} />
              </S.TableData>
            </S.TableRow>

            <S.TableRow>
              <S.TableData>
                <Skeleton width={64} height={19} />
              </S.TableData>
              <S.TableData>
                <Skeleton width={64} height={19} />
              </S.TableData>
              <S.TableData align={EHeadAlign.RIGHT}>
                <Skeleton width={64} height={19} />
              </S.TableData>
              <S.TableData align={EHeadAlign.CENTER}>
                <Skeleton width={64} height={19} />
              </S.TableData>
            </S.TableRow>

            <S.TableRow>
              <S.TableData>
                <Skeleton width={64} height={19} />
              </S.TableData>
              <S.TableData>
                <Skeleton width={64} height={19} />
              </S.TableData>
              <S.TableData align={EHeadAlign.RIGHT}>
                <Skeleton width={64} height={19} />
              </S.TableData>
              <S.TableData align={EHeadAlign.CENTER}>
                <Skeleton width={64} height={19} />
              </S.TableData>
            </S.TableRow>
          </S.TableBody>
        </S.Table>
      </S.TableWrapper>
    </S.FirstStepWrapper>
  );
};

export default SkeletonLoading;
