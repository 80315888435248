import styled from 'styled-components/macro';

import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Description = styled(TextBase)`
  display: inline-block;
  margin-bottom: 32px;
`;

export const NewPasswordButton = styled(ButtonBase)``;

export const SubmitButton = styled(ButtonBase)`
  margin-top: 32px;
  margin-left: auto;
`;

export const Title = styled(HeadingBase)`
  display: inline-block;
  margin-bottom: 32px;
`;

export const Input = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Wrapper = styled.div`
  padding: 32px;
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background[8]};
  border-radius: 12px;
`;
