import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresAverageTicketValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresAverageTicketValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresAverageTicketValuesResponse => {
  const currentSelectedStoreAverageTicketAmountValue =
    currentSelectedStoreFinancialDetailsReportData?.average_ticket?.amount;

  const storesAverageTicketAmountValues = selectedStoresData?.map(
    value => value.financial_details_report?.average_ticket?.amount,
  );

  const averageTicketAmountValues = [
    ...storesAverageTicketAmountValues,
    currentSelectedStoreAverageTicketAmountValue,
  ];

  const reducedAverageTicketAmountValues = averageTicketAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const currentSelectedStore = 1;
  const selectedStoresQuantity = selectedStoresData?.length;

  const storesQuantity = selectedStoresQuantity + currentSelectedStore;

  const finalResult = reducedAverageTicketAmountValues / storesQuantity;

  return {
    average_ticket_amount: finalResult,
  };
};
