import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const ContentText = styled(TextBase)`
  font-weight: 700;
  color: #fff;
`;

export const Content = styled.div`
  border-radius: 7px;
  max-width: 428px;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray[7]};
  display: flex;
  align-items: center;
  gap: 16px;

  & > svg {
    min-width: 32px;
  }
`;

export const Wrapper = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #363535;
`;
