import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';

import * as S from './styles';

const Header: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Title type={EHeadingSize.H3} fontWeight={EHeadingWeight.REGULAR}>
        Conecte-se com pelo menos uma fonte de tráfego
      </S.Title>
      <S.Description>
        Para utilizar nosso gerenciador de campanhas, primeiro é necessário que você conecte-se a
        uma plataforma de anúncio
      </S.Description>
    </S.Wrapper>
  );
};

export default Header;
