import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import CustomHoverCardBase from '@components/common/core/Utils/CustomHoverCard';

export const CustomHoverCardContent = styled(CustomHoverCardBase.Content)``;

export const CustomHoverCardTrigger = styled(CustomHoverCardBase.Trigger)`
  display: flex;
  justify-content: center;
  gap: 8px;
  overflow: hidden;
  padding: 10px 12px;
  height: 58px;
  box-sizing: border-box;
  width: calc(100% + 24px);
  margin-left: -12px;
  margin-top: -10px;
`;

export const CustomHoverCard = styled(CustomHoverCardBase)``;

export const AmountText = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const LabelText = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  user-select: none;
  filter: blur(5px);
`;

export const TextContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
`;

export const ConversionPercentage = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% - 4px), calc(-50% - 4px));
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: 0.625rem;
  font-weight: 500;
  color: #b78aff;
`;

export const ChartWrapper = styled.div`
  min-width: 46px;
  width: 46px;
  min-height: 46px;
  height: 46px;
  position: relative;
  user-select: none;
  filter: blur(5px);

  & > div {
    position: absolute;
    top: -4px;
    left: -4px;
  }
`;
