import React from 'react';

import { useDate } from '@helpers/hooks/useDate';
import { useQuery } from '@helpers/hooks/useQuery';
import { useParams } from 'react-router-dom';
import { IParams } from '@domain/interfaces/IParams';
// import { Facebook as FacebookIcon } from '@profitfy/pakkun-icons';
import { useToast } from '@helpers/hooks/useToast';
import FacebookImg from '@assets/pages/Onboarding/dropshipping/marketing/facebook.svg';

import facebookCredentialService from '@services/pages/facebookLogin/facebookCredential';

// import NewFacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import ExternalFacebookButton from '@components/common/core/Utils/ExternalFacebookButton';
import SuccessLoginContent from './SuccessLoginContent';
import FailureLoginContent from './FailureLoginContent';

import * as S from './styles';

const FacebookLogin: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();

  const query = useQuery();

  const { utcToZonedTime } = useDate();

  const [facebookResponse, setFacebookResponse] = React.useState<string>('');
  const [authorization, setAuthorization] = React.useState<string>('');
  const [facebookData, setFacebookData] = React.useState<Record<string, any> | undefined>(
    undefined,
  );
  const [facebookProfileData, setFacebookProfileData] = React.useState<
    Record<string, any> | undefined
  >(undefined);

  const handleFail = React.useCallback(() => {
    setFacebookData(undefined);
    setFacebookProfileData(undefined);
  }, []);

  const handleFacebookData = React.useCallback(data => {
    setFacebookData(data);
  }, []);

  const handleFacebookProfileData = React.useCallback(data => {
    setFacebookProfileData(data);
  }, []);

  const handleLogin = React.useCallback(
    async (sessionData: Record<string, any>, profileData: Record<string, any>) => {
      try {
        const data = {
          ...sessionData,
          access_token: sessionData?.accessToken,
          name: profileData?.name || 'Sem nome',
          provider_id: profileData?.id,
          provider_created_at: utcToZonedTime(new Date()).toISOString(),
        };

        await facebookCredentialService.createFacebookCredential({
          storeAliasId,
          data,
          config: {
            authorization,
          },
        });

        setFacebookResponse('success');
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
    [toast, storeAliasId, utcToZonedTime, authorization],
  );

  React.useEffect(() => {
    if (facebookData && facebookProfileData && !facebookProfileData.error) {
      handleLogin(facebookData, facebookProfileData);
    }
  }, [facebookData, facebookProfileData, handleLogin]);

  React.useEffect(() => {
    const authorizationRef = query.get('auth');

    if (authorizationRef) {
      setAuthorization(authorizationRef);
    }
  }, [query]);

  if (!authorization) {
    return (
      <S.Container>
        <S.ContentWrapper>
          <FailureLoginContent />
        </S.ContentWrapper>
      </S.Container>
    );
  }

  return (
    <S.Container>
      <S.ContentWrapper>
        {facebookResponse === 'success' && <SuccessLoginContent />}
        {facebookResponse === 'error' && <FailureLoginContent />}

        {!facebookResponse && (
          <>
            <S.Image src={FacebookImg} alt="profitfy-goat-with-facebook-logo" />

            <S.Heading type={EHeadingSize.H2}>Conectar perfil do facebook</S.Heading>

            <ExternalFacebookButton
              width={379}
              onSuccess={handleFacebookData}
              onProfileSuccess={handleFacebookProfileData}
              onFail={handleFail}
            />
          </>
        )}
      </S.ContentWrapper>
    </S.Container>
  );
};

export default FacebookLogin;
