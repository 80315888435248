import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background[1]};
  min-height: 100vh;
  position: relative;
  padding: 0 32px 64px 32px;
`;

export const PageErrorWrapper = styled.div`
  padding: 96px 0 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Image = styled(ImageBase)`
  max-width: 346px;
  margin-bottom: 72px;

  @media only screen and (max-width: 1440px) {
    max-width: 268px;
    margin-bottom: 32px;
  }
`;

export const Text = styled(TextBase)`
  margin-bottom: 16px;
  display: inline-block;
  text-align: center;

  @media only screen and (max-width: 1440px) {
    margin-bottom: 8px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Button = styled(ButtonBase)`
  margin-top: 20px;

  @media only screen and (max-width: 1440px) {
    margin-top: 16px;
  }
`;
