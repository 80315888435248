import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';

export const TooltipText = styled(TextBase)``;

export const Tooltip = styled(CustomTooltipBase)``;

export const OrderDate = styled(TextBase)`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.gray[3]};
`;

export const FirstOrderItem = styled(TextBase)`
  width: 472px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`;

export const OrderNumberLink = styled.a`
  width: 148px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.font};
  font-size: 1rem;
  font-weight: 400;
  text-decoration: unset;
  text-decoration: underline;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.green.default};
  }

  &:active {
    color: ${({ theme }) => theme.colors.green.dark};
  }
`;

export const OrderNumber = styled(TextBase)`
  width: 128px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

export const Wrapper = styled.div`
  min-width: 768px;
  padding: 12px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 48px;

  & > button:nth-child(2) {
    width: 100%;
  }
`;
