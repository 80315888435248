import styled from 'styled-components/macro';

export const GoBackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Image = styled.div`
  width: 48px;
  height: 48px;
`;

export const Text = styled.div``;
