import React from 'react';
import { LineChart } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { ICogs } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/ISummaryReport';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import { numberFormatter } from '@helpers/masks';

import BlurredContent from './BlurredContent';

import * as S from './styles';

const Cogs: React.FC<ICampaignProps> = ({ data, forecastData }) => {
  const cogsData: ICogs = data;

  const { store } = useStoreConfig();
  const { isDemoEnvironment } = useConfig();
  const theme = useTheme();

  const isContentBlurred = !store.is_utm_onboarding_finished && !isDemoEnvironment;

  return (
    <S.Wrapper>
      {isContentBlurred ? (
        <BlurredContent />
      ) : (
        <>
          <S.TotalAmountText>{`R$ ${numberFormatter(cogsData.total_amount, 2)}`}</S.TotalAmountText>

          {Boolean(forecastData) && (
            <S.ForeCastAmount>
              <LineChart outline size={12} color={theme.colors.gray[2]} />
              {`R$ ${numberFormatter(forecastData.total_amount, 2)}`}
            </S.ForeCastAmount>
          )}

          <S.TotalLabel>Total</S.TotalLabel>
        </>
      )}
    </S.Wrapper>
  );
};

export default Cogs;
