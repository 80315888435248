import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const EditPaymentMethod = styled(ButtonBase)`
  margin-right: 12px;
`;

export const DescriptionText = styled(TextBase)`
  line-height: 120%;
  color: #ffffff;
`;

export const TitleText = styled(TextBase)`
  line-height: 120%;
  display: block;
  margin-bottom: 4px;
  color: #ffffff;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconAndTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & > svg {
    min-width: 32px;
    width: 32px;
    height: 32px;
  }
`;

export const Content = styled.div`
  max-width: 1262px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;

  @media only screen and (max-width: 1168px) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
`;

export const Container = styled.div`
  width: 100%;
  min-height: 69px;
  padding: 12px 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #b87905;
  border-radius: 7px;
`;

export const CloseIcon = styled.button`
  background: unset;
  padding: unset;
  border: unset;
  position: absolute;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  top: 8px;
  right: 8px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;
