import styled from 'styled-components/macro';

export const ChartWrapper = styled.div`
  width: 100%;
  height: 100%;

  tspan {
    font-family: ${({ theme }) => theme.fonts.body};
    font-size: 0.875rem;
    fill: ${({ theme }) => theme.colors.chartTextAxis};
  }
`;
