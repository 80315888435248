import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const ProvidersButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Title = styled(HeadingBase)``;

export const Header = styled.div`
  margin-bottom: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Funnel = styled.div`
  .svg-funnel-js__labels {
    .svg-funnel-js__label {
      .label__value {
        font-size: 1.25rem;
        margin: 0;
        font-weight: 700;
        font-family: ${({ theme }) => theme.fonts.body};
        color: ${({ theme }) => theme.colors.font};
      }

      .label__title {
        color: ${({ theme }) => theme.colors.green.default};
        font-family: ${({ theme }) => theme.fonts.body};
        font-weight: 700;
        font-size: 0.75rem;
        margin: 0;
      }

      .label__percentage {
        color: ${({ theme }) => theme.colors.gray[2]};
        font-family: ${({ theme }) => theme.fonts.body};
        font-weight: 700;
        font-size: 0.75rem;
        margin: 0;
      }
    }
  }
`;

export const Wrapper = styled.div`
  max-width: 800px;
  height: 274px;
  overflow-y: hidden;
  overflow-x: scroll;
  width: 100%;

  @media only screen and (max-width: 1124px) {
    max-width: unset;
  }

  &:active {
    cursor: grabbing;
  }

  &::-webkit-scrollbar {
    height: 2px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.background[3]};
    border-radius: 25px;
  }
`;

export const Container = styled.div`
  max-width: 848px;
  width: 100%;
  padding: 20px 24px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.gray[4]};
  border-radius: 7px;
  height: 378px;

  @media only screen and (max-width: 1124px) {
    max-width: unset;
  }
`;
