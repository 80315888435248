import { GoBack as GoBackIcon } from '@profitfy/pakkun-icons';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { IGoBackProps } from '@domain/interfaces/components/IGoBack';

import * as S from './styles';

const GoBack: React.FC<IGoBackProps> = ({ src, alt, text, onClick, size, fontWeight, type }) => {
  const theme = useTheme();

  const [isImageError, setIsImageError] = React.useState<boolean>(false);

  return (
    <S.GoBackButton onClick={onClick}>
      <GoBackIcon size={size ?? 28} color={theme.colors.font} />

      {src && alt ? (
        <>
          {!isImageError ? (
            <S.Image src={src} alt={alt} onError={() => setIsImageError(true)} />
          ) : (
            <S.NoImageFill />
          )}
        </>
      ) : (
        <></>
      )}

      <S.Text fontWeight={fontWeight ?? EHeadingWeight.LIGHT} type={type ?? EHeadingSize.H4}>
        {text}
      </S.Text>
    </S.GoBackButton>
  );
};

export default GoBack;
