import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';

import { duplicateCampaignsModalSchema } from '@helpers/validators/dashboard/adsManager/adSenseCampaigns/actions/duplicateCampaignsModal';

import { useCampaigns } from '@helpers/hooks/pages/dashboard/adsManager/useCampaigns';
import { useToast } from '@helpers/hooks/useToast';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const DuplicateCampaignsModal: React.FC = () => {
  const {
    onDuplicate,
    selectedCampaigns,
    selectedCampaignToAction,
    isDuplicateModalOpen,
    handleDuplicateModalOpen,
  } = useCampaigns();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(duplicateCampaignsModalSchema) });
  const { toast } = useToast();
  const { currentSynchronizations, synchronizationsQueue } = useSynchronization();

  const parsedCurrentSynchronizations = currentSynchronizations
    ? currentSynchronizations.synchronizations
    : [];
  const mergedSynchronizations = [...parsedCurrentSynchronizations, ...synchronizationsQueue];

  const quantityInput = register('quantity');

  const onQuantityChange = React.useCallback(event => {
    // quantityInput.onChange(event);
    // const { value } = event.target;

    // const newValue = Number(value.replace('.', '').replace(',', ''));
    event.target.value = '1';
  }, []);

  const onSubmit = React.useCallback(
    async data => {
      const filteredSynchronizations = mergedSynchronizations.filter(
        synchronization =>
          synchronization.type === ESynchronizationType.ADS_MANAGER_DUPLICATE_CAMPAIGNS,
      );

      const hasActiveSynchronization = selectedCampaigns.find(campaignAliasId => {
        const foundSynchronization = filteredSynchronizations.find(synchronization =>
          synchronization.externalId?.includes(campaignAliasId),
        );

        return Boolean(foundSynchronization);
      });

      if (!selectedCampaignToAction && hasActiveSynchronization) {
        toast.error('Já existe um anúncio sendo atualizado no momento.');
        handleDuplicateModalOpen();
      } else {
        const campaignToDuplicate = selectedCampaigns.length
          ? selectedCampaigns
          : [selectedCampaignToAction || ''];

        onDuplicate(data.quantity, campaignToDuplicate);
        handleDuplicateModalOpen();
      }
    },
    [
      onDuplicate,
      selectedCampaigns,
      selectedCampaignToAction,
      handleDuplicateModalOpen,
      mergedSynchronizations,
      toast,
    ],
  );

  return (
    <S.Modal isOpen={isDuplicateModalOpen} toggle={handleDuplicateModalOpen}>
      <S.Modal.Header>
        <S.Title>Duplicar suas campanhas</S.Title>
      </S.Modal.Header>
      <S.Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>Quantidade de cópias</S.Label>
            <S.Input {...quantityInput} onChange={onQuantityChange} type="text" defaultValue="1" />
            {errors.quantity && <Text isErrorFeedback>{errors.quantity.message}</Text>}
          </S.InputGroup>

          <S.ButtonsWrapper>
            <S.CancelButton type="button" onClick={handleDuplicateModalOpen}>
              Cancelar
            </S.CancelButton>
            <S.DuplicateButton type="submit">Duplicar</S.DuplicateButton>
          </S.ButtonsWrapper>
        </Form>
      </S.Modal.Body>
    </S.Modal>
  );
};

export default DuplicateCampaignsModal;
