import Text from '@components/common/core/DataDisplay/Text';
import { EOrderStatus } from '@domain/enums/common/EOrder';
import { EModalStep } from '@domain/enums/common/EStepsModal';
import { ETriggers } from '@domain/enums/dashboard/automations/ETriggers';
import { useAutomationsManager } from '@helpers/hooks/pages/dashboard/automations/useAutomationsManager';
import { currencyFormatter } from '@helpers/masks';
import {
  firstStepAbadonRejectedSchema,
  firstStepPixBoletoSchema,
} from '@helpers/validators/dashboard/automations/testSideModal/steps';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import Save from '../Save';
import * as S from './styles';

interface IFirstStepProps {
  handleTestAutomationStep: (step: EModalStep) => void;
}

const FirstStep: React.FC<IFirstStepProps> = ({ handleTestAutomationStep }) => {
  const {
    handleTestSideModalOpen,
    handleSetFormValues,
    selectedWorkflowTriggerType,
  } = useAutomationsManager();

  const isPixOrBoleto = React.useCallback(() => {
    if (
      selectedWorkflowTriggerType === ETriggers.PIX ||
      selectedWorkflowTriggerType === ETriggers.BOLETO
    )
      return true;

    return false;
  }, [selectedWorkflowTriggerType])();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(isPixOrBoleto ? firstStepPixBoletoSchema : firstStepAbadonRejectedSchema),
  });

  const onSubmit = React.useCallback(
    formData => {
      handleTestAutomationStep(EModalStep.SECOND_STEP);
      handleSetFormValues(formData);
    },
    [handleSetFormValues, handleTestAutomationStep],
  );

  const handleOrderValue = React.useCallback(event => {
    event.target.value = currencyFormatter(event.target.value);
  }, []);

  return (
    <>
      <S.TitleForm>Dados do pedido</S.TitleForm>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label htmlFor="order_value">Valor total do pedido</S.Label>

          <S.Input
            {...register('order_value')}
            type="text"
            placeholder="Insira o valor total do pedido"
            id="order_value"
            isError={errors.order_value}
            onChange={handleOrderValue}
          />
          {errors.order_value && <Text isErrorFeedback>{errors.order_value.message}</Text>}
        </S.InputGroup>

        {isPixOrBoleto && (
          <S.InputGroup>
            <S.Label htmlFor="order_status">Status do pedido</S.Label>

            <S.Select {...register('order_status')}>
              <S.Option disabled value="" />
              <S.Option value={EOrderStatus.PAID}>Pago</S.Option>
              <S.Option value={EOrderStatus.PENDING}>Pendente</S.Option>
              <S.Option value={EOrderStatus.CANCELED}>Cancelado</S.Option>
              <S.Option value={EOrderStatus.REFUNDED}>Devolvido</S.Option>
              <S.Option value={EOrderStatus.CHARGED_BACK}>Chargeback</S.Option>
              <S.Option value={EOrderStatus.IN_ANALYSIS}>Em análise</S.Option>
              <S.Option value={EOrderStatus.REJECTED}>Rejeitado</S.Option>
              <S.Option value={EOrderStatus.NO_INFORMATION}>Sem informação</S.Option>
            </S.Select>

            {errors.order_status && <Text isErrorFeedback>{errors.order_status.message}</Text>}
          </S.InputGroup>
        )}
        <S.InputsGroupWrapper>
          <S.InputGroup>
            <S.Label htmlFor="product">Produto</S.Label>

            <S.Input
              {...register('product')}
              type="text"
              placeholder="Selecione o produto"
              id="product"
              isError={errors.product}
            />
            {errors.product && <Text isErrorFeedback>{errors.product.message}</Text>}
          </S.InputGroup>
          <S.InputQuantityGroup>
            <S.Label>Quantidade</S.Label>
            <S.Input
              {...register('quantity')}
              type="text"
              placeholder="Quantidade"
              isError={errors.quantity}
            />
            {errors.quantity && <Text isErrorFeedback>{errors.quantity.message}</Text>}
          </S.InputQuantityGroup>
        </S.InputsGroupWrapper>

        <Save onCancel={handleTestSideModalOpen} />
      </S.Form>
    </>
  );
};

export default FirstStep;
