import styled from 'styled-components/macro';

import ButtonBase from '@components/common/core/Inputs/Button';

export const MarketingIntegrationWrapper = styled.div`
  max-width: 1292px;
  width: 100%;
`;

export const NextStepButton = styled(ButtonBase)``;

export const NextStepWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 90px;
  right: 0;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    left: 0;
    bottom: 70px;
  }
`;

export const Wrapper = styled.div`
  max-width: 560px;
  width: 100%;
`;
