import React from 'react';

import { EHeadAlign } from '@domain/enums/components/ETable';

import * as S from './styles';

const Table: React.ForwardRefRenderFunction<HTMLTableElement> = ({ children, ...rest }, ref) => {
  return (
    <S.AutomationsTable ref={ref} {...rest}>
      <S.Table.Header>
        <S.TableRow>
          <S.TableHead align={EHeadAlign.CENTER}>Status</S.TableHead>
          <S.TableHead align={EHeadAlign.LEFT}>Nome</S.TableHead>
          <S.TableHead align={EHeadAlign.RIGHT}>Envios (7d)</S.TableHead>
          <S.TableHead align={EHeadAlign.RIGHT}>Execuções (7d)</S.TableHead>
          <S.TableHead align={EHeadAlign.RIGHT}>Recuperados (7d)</S.TableHead>
          <S.TableHead align={EHeadAlign.RIGHT}>Valor Recuperado (7d)</S.TableHead>
          <S.TableHead align={EHeadAlign.CENTER}>Gatilho</S.TableHead>
          <S.TableHead align={EHeadAlign.CENTER}>Ações</S.TableHead>
        </S.TableRow>
      </S.Table.Header>

      <S.Table.Body>{children}</S.Table.Body>
    </S.AutomationsTable>
  );
};

export default React.forwardRef(Table);
