import { ISaveButtonProps } from '@domain/interfaces/components/ISaveButton';
import React from 'react';

import * as S from './styles';

const SaveButton: React.FC<ISaveButtonProps> = ({ children, ...rest }) => {
  return (
    <S.SaveButtonWrapper>
      <S.Button {...rest}>{children}</S.Button>
    </S.SaveButtonWrapper>
  );
};

export default SaveButton;
