import React from 'react';

import { ICustomDatePicker } from '@domain/interfaces/components/ICustomDatePicker';
import { useDate } from '@helpers/hooks/useDate';
import CustomDate from './CustomDate';

import * as S from './styles';

const CustomDatePicker: React.FC<ICustomDatePicker> = ({
  period,
  setPeriod,
  maxDate,
  minDate,
  calendarOnTop = false,
  maxRange,
  ...rest
}) => {
  const { differenceInDays, addDays, utcToZonedTime } = useDate();

  const [startDate, setStartDate] = React.useState<Date>(period.startDate);
  const [endDate, setEndDate] = React.useState<Date>(period.endDate);
  const [isCustomDatePickerOpen, setIsCustomDatePickerOpen] = React.useState<boolean>(false);

  const handleIsCustomDatePickerOpen = React.useCallback(
    () => setIsCustomDatePickerOpen(!isCustomDatePickerOpen),
    [isCustomDatePickerOpen],
  );

  const onChange = React.useCallback(
    dates => {
      const [start, end] = dates;

      setStartDate(start);
      setEndDate(end);

      if (end) {
        handleIsCustomDatePickerOpen();
        setPeriod({ startDate: start, endDate: end });
      }
    },
    [handleIsCustomDatePickerOpen, setPeriod],
  );

  const getMaxDate = React.useCallback((): Date | undefined => {
    if (!maxRange) return maxDate;

    const differenceInDaysFromStartToNow = differenceInDays(startDate, utcToZonedTime(new Date()));

    if (differenceInDaysFromStartToNow >= 29) {
      const endRangeDate = addDays(startDate, 29);

      return endRangeDate;
    }

    return utcToZonedTime(new Date());
  }, [differenceInDays, maxRange, maxDate, startDate, utcToZonedTime, addDays]);

  React.useEffect(() => {
    setStartDate(period.startDate);
    setEndDate(period.endDate);
  }, [period]);

  const maxCalendarDate = getMaxDate();

  return (
    <S.Container {...rest}>
      <CustomDate
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        isOpen={isCustomDatePickerOpen}
        handleIsOpen={handleIsCustomDatePickerOpen}
        maxDate={maxCalendarDate}
        minDate={minDate}
        calendarOnTop={calendarOnTop}
      />
    </S.Container>
  );
};

export default CustomDatePicker;
