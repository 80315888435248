import React from 'react';

import { ITagWrapperProps } from '@domain/interfaces/dashboard/ProductTag/ISideModalTags';
import DeleteModal from '@components/Dashboard/ProductAnalytics/Tags/DeleteTagModal';
import { useCampaigns } from '@helpers/hooks/pages/dashboard/campaigns/useCampaigns';
import Label from './Label';
import Input from './Input';

import * as S from './styles';

const TagWrapper: React.FC<ITagWrapperProps> = ({ productTag }) => {
  const { deleteProductTag } = useCampaigns();

  const [tagName, setTagName] = React.useState<string>(productTag.name);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);

  const handleDeleteModalOpen = React.useCallback(() => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  }, [isDeleteModalOpen]);

  const handleDeleteProductTag = React.useCallback(async () => {
    await deleteProductTag(productTag.alias_id);
  }, [productTag, deleteProductTag]);

  const onInputBlur = React.useCallback(async event => {
    const isBlurringInput = !event.currentTarget.contains(event.relatedTarget);

    if (isBlurringInput) {
      setIsHovering(false);
      setIsEditing(false);
    }
  }, []);

  const handleTagName = React.useCallback(newTagName => setTagName(newTagName), []);

  const handleIsEditing = React.useCallback(() => setIsEditing(!isEditing), [isEditing]);

  const onMouseEnter = React.useCallback(() => {
    setIsHovering(true);
  }, []);

  const onMouseLeave = React.useCallback(() => {
    setIsHovering(false);
  }, []);

  return (
    <S.Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {isEditing ? (
        <Input
          handleIsEditing={handleIsEditing}
          handleTagName={handleTagName}
          tagName={tagName}
          onBlur={onInputBlur}
          productTag={productTag}
        />
      ) : (
        <Label
          isHovering={isHovering}
          handleIsEditing={handleIsEditing}
          tagName={tagName}
          handleDeleteModalOpen={handleDeleteModalOpen}
        />
      )}

      <DeleteModal
        isOpen={isDeleteModalOpen}
        toggle={handleDeleteModalOpen}
        handleDeleteProductTag={handleDeleteProductTag}
      />
    </S.Wrapper>
  );
};

export default TagWrapper;
