import React from 'react';
import { Percent } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  ESynchronizationRequestType,
  ESynchronizationType,
} from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';
import { IPeriod } from '@domain/interfaces/components/IDatePicker';

import { useCheckoutFee } from '@helpers/hooks/pages/dashboard/checkoutFee/useCheckoutFee';
import { useDate } from '@helpers/hooks/useDate';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import { currencyFormatter, numberFormatter, currencyToNumber } from '@helpers/masks';
import {
  checkoutFeePercentageSchema,
  checkoutFeeFixedSchema,
} from '@helpers/validators/dashboard/checkoutFee/checkoutFee';
import { getEnvironment } from '@helpers/utils/environment';

import { LONG_API_DOMAIN } from '@constants/api';

import Form from '@components/common/core/Inputs/Form';
import SideModalSave from '@components/common/core/Utils/SideModalSave';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const NewCheckoutFeeSidemodal: React.FC = () => {
  const theme = useTheme();
  const { storeAliasId } = useParams<IParams>();
  const { utcToZonedTime, format } = useDate();
  const { addSynchronization } = useSynchronization();
  const {
    isNewCheckoutFeeSideModalOpen,
    handleNewCheckoutFeeSideModalOpen,
    defaultCheckoutFee,
    configDefaultCheckoutFee,
    createCheckoutFeePeriod,
    isCreatingPeriodCheckoutFee,
    isConfiguringDefaultCheckoutFee,
    mutateHistoricCheckoutFees,
  } = useCheckoutFee();

  const [checkoutFeeType, setCheckoutFeeType] = React.useState<string>('current');
  const [checkoutFeeChargeType, setCheckoutFeeChargeType] = React.useState<string>('percentage');
  const [period, setPeriod] = React.useState<IPeriod>({
    startDate: utcToZonedTime(new Date()),
    endDate: utcToZonedTime(new Date()),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(
      checkoutFeeChargeType === 'percentage' ? checkoutFeePercentageSchema : checkoutFeeFixedSchema,
    ),
  });

  const amountInput = register('amount');
  const percentageInput = register('percentage');

  const onSubmit = React.useCallback(
    async data => {
      if (checkoutFeeType === 'current') {
        configDefaultCheckoutFee({
          amount: checkoutFeeChargeType === 'fixed' ? currencyToNumber(data.amount) : 0,
          name: 'any_checkout_fee_name',
          percentage_amount:
            checkoutFeeChargeType === 'percentage' ? currencyToNumber(data.percentage) : 0,
        });
      } else {
        const response = await createCheckoutFeePeriod({
          amount: checkoutFeeChargeType === 'fixed' ? currencyToNumber(data.amount) : 0,
          name: 'any_checkout_fee_name',
          percentage_amount:
            checkoutFeeChargeType === 'percentage' ? currencyToNumber(data.percentage) : 0,
          start_date: format(utcToZonedTime(period.startDate), 'yyyy-MM-dd'),
          end_date: format(utcToZonedTime(period.endDate), 'yyyy-MM-dd'),
        });

        if (response) {
          const params = `?start_date=${format(
            utcToZonedTime(period.startDate),
            'yyyy-MM-dd',
          )}&end_date=${format(utcToZonedTime(period.endDate), 'yyyy-MM-dd')}`;

          addSynchronization({
            name: 'Taxas de Checkout',
            type: ESynchronizationType.STORE_ORDER,
            label: 'Pedidos',
            dependencies: [],
            storeAliasId,
            config: {
              isOnboardingSynchronization: false,
              showNotification: true,
            },
            request: {
              type: ESynchronizationRequestType.POST,
              domain: LONG_API_DOMAIN,
              api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/orders/checkout-fee/sync`,
              requestParams: params,
            },
          });

          handleNewCheckoutFeeSideModalOpen();

          mutateHistoricCheckoutFees();
        }
      }
    },
    [
      addSynchronization,
      checkoutFeeChargeType,
      checkoutFeeType,
      configDefaultCheckoutFee,
      createCheckoutFeePeriod,
      format,
      period,
      storeAliasId,
      utcToZonedTime,
      handleNewCheckoutFeeSideModalOpen,
      mutateHistoricCheckoutFees,
    ],
  );

  const onTypeChange = React.useCallback(event => {
    setCheckoutFeeType(event.target.value);
  }, []);

  const onChargeTypeChange = React.useCallback(event => {
    setCheckoutFeeChargeType(event.target.value);
  }, []);

  const onAmountChange = React.useCallback(
    event => {
      amountInput.onChange(event);

      const { value } = event.target;

      event.target.value = currencyFormatter(value);
    },
    [amountInput],
  );

  const onPercentageChange = React.useCallback(
    event => {
      percentageInput.onChange(event);

      const { value } = event.target;

      event.target.value = currencyFormatter(value);
    },
    [percentageInput],
  );

  const defaultCheckoutFeeAmount = defaultCheckoutFee?.amount;
  const defaultCheckoutFeePercentage = defaultCheckoutFee?.percentage_amount;
  const maxDate = utcToZonedTime(new Date());

  return (
    <S.Sidemodal isOpen={isNewCheckoutFeeSideModalOpen} toggle={handleNewCheckoutFeeSideModalOpen}>
      <S.Header>
        <Percent size={36} color={theme.colors.green.default} outline />
        <S.Heading>Configurar Taxa</S.Heading>
      </S.Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.RadioGroup>
          <S.Label>Escolha como deseja cadastrar sua taxa:</S.Label>

          <S.RadioLabel>
            <S.Radio
              value="current"
              defaultChecked={checkoutFeeType === 'current'}
              name="type"
              onChange={onTypeChange}
              id="current-option"
            />

            <S.RadioText>Taxa atual</S.RadioText>
          </S.RadioLabel>

          <S.RadioLabel>
            <S.Radio
              value="period"
              defaultChecked={checkoutFeeType === 'period'}
              name="type"
              onChange={onTypeChange}
              id="period-option"
            />

            <S.RadioText>Taxa por período</S.RadioText>
          </S.RadioLabel>
        </S.RadioGroup>

        <S.RadioGroup>
          <S.Label>Escolha como será cobrado:</S.Label>

          <S.RadioLabel>
            <S.Radio
              value="percentage"
              defaultChecked={checkoutFeeChargeType === 'percentage'}
              name="charge-type"
              onChange={onChargeTypeChange}
              id="percentage-option"
            />

            <S.RadioText>Porcentagem</S.RadioText>
          </S.RadioLabel>

          <S.RadioLabel>
            <S.Radio
              value="fixed"
              defaultChecked={checkoutFeeType === 'fixed'}
              name="charge-type"
              onChange={onChargeTypeChange}
              id="fixed-option"
            />

            <S.RadioText>Fixo</S.RadioText>
          </S.RadioLabel>
        </S.RadioGroup>

        <S.InputGroup>
          <S.Label>Valor da taxa por venda concluída</S.Label>

          {checkoutFeeChargeType === 'fixed' ? (
            <>
              <S.Input
                {...amountInput}
                type="text"
                placeholder="Inserir valor"
                prependText="R$"
                defaultValue={
                  defaultCheckoutFeeAmount
                    ? numberFormatter(defaultCheckoutFeeAmount, 2)
                    : undefined
                }
                onChange={onAmountChange}
              />
              {errors.amount && <Text isErrorFeedback>{errors.amount.message}</Text>}
            </>
          ) : (
            <>
              <S.Input
                {...percentageInput}
                type="text"
                placeholder="Inserir valor"
                prependText="%"
                defaultValue={
                  defaultCheckoutFeePercentage
                    ? numberFormatter(defaultCheckoutFeePercentage, 2)
                    : undefined
                }
                onChange={onPercentageChange}
              />
              {errors.percentage && <Text isErrorFeedback>{errors.percentage.message}</Text>}
            </>
          )}
        </S.InputGroup>

        {checkoutFeeType === 'period' && (
          <S.CustomDatePicker period={period} setPeriod={setPeriod} maxDate={maxDate} />
        )}

        <SideModalSave
          successButtonText="Salvar"
          cancelButtonText="Cancelar"
          type="submit"
          onCancel={handleNewCheckoutFeeSideModalOpen}
          isLoading={isCreatingPeriodCheckoutFee || isConfiguringDefaultCheckoutFee}
          isDisabled={isCreatingPeriodCheckoutFee || isConfiguringDefaultCheckoutFee}
        />
      </Form>
    </S.Sidemodal>
  );
};

export default NewCheckoutFeeSidemodal;
