import styled, { css } from 'styled-components/macro';

import { IAlert } from '@domain/interfaces/components/IAlert';
import { EAlertVariant } from '@domain/enums/components/EAlert';

import Text from '@components/common/core/DataDisplay/Text';

export const Container = styled.div<IAlert>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 14px 24px;
  border-radius: 5px;
  background: rgba(46, 179, 180, 0.1);
  box-sizing: border-box;

  & > svg {
    min-width: 24px;
  }

  ${({ flexStart }) =>
    flexStart &&
    css`
      align-items: flex-start;

      & > svg {
        margin-top: 4px;
      }
    `}

  ${({ variant }) =>
    variant === EAlertVariant.WARNING &&
    css`
      background-color: #221e18;
    `}

  ${({ variant }) =>
    variant === EAlertVariant.ERROR &&
    css`
      background-color: rgba(235, 61, 61, 0.1);
    `}
`;

export const TitleAndDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 12px;
`;

export const Title = styled(Text)``;

export const Description = styled(Text)``;
