import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresRoiValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresRoiValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresRoiValuesResponse => {
  const currentSelectedStoreRoiAmountValue =
    currentSelectedStoreFinancialDetailsReportData?.roi?.amount;

  const storesRoiAmountValues = selectedStoresData?.map(
    value => value.financial_details_report?.roi?.amount,
  );

  const roiAmountValues = [...storesRoiAmountValues, currentSelectedStoreRoiAmountValue];

  const reducedRoiAmountValues = roiAmountValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  return {
    roi_amount: reducedRoiAmountValues,
  };
};
