import * as yup from 'yup';

export const couponWithoutMaxQuantitySchema = yup.object().shape({
  name: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'É necessário ter dois ou mais caracteres.'),
  identifier: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'É necessário ter dois ou mais caracteres.'),
  amount: yup.string().required('Este campo é obrigatório.'),
});

export const couponWithMaxQuantitySchema = yup.object().shape({
  name: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'É necessário ter dois ou mais caracteres.'),
  identifier: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'É necessário ter dois ou mais caracteres.'),
  amount: yup.string().required('Este campo é obrigatório.'),
  quantity: yup
    .number()
    .required('Este campo é obrigatório.')
    .moreThan(0, 'A quantidade precisa ser maior que 0.'),
});

export const getCouponSchema = (hasSelectedMaxQuantity: boolean): yup.ObjectSchema => {
  if (hasSelectedMaxQuantity) return couponWithMaxQuantitySchema;

  return couponWithoutMaxQuantitySchema;
};
