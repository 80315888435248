import React from 'react';

import { EScriptAndUtmsStep, ESyncType } from '@domain/enums/dashboard/adsManager/EScriptAndUtms';
import { EBadgeColors } from '@domain/enums/components/EBadge';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { EScheduleEntity } from '@domain/enums/common/schedule/EScheduleEntity';
import { EScheduleItemEntity } from '@domain/enums/common/schedule/EScheduleItemEntity';
import { EScheduleAction } from '@domain/enums/common/schedule/EScheduleAction';
import { ESchedulePeriod } from '@domain/enums/common/schedule/ESchedulePeriod';
import { ICreateScheduleData } from '@domain/interfaces/common/schedule/ISchedule';

import { useScriptAndUtms } from '@helpers/hooks/pages/dashboard/adsManager/onboarding/useScriptAndUtms';

import Processing from './Processing';
import Finished from './Finished';

import * as S from './styles';

const ScheduleSync: React.FC = () => {
  const {
    userStep,
    syncType,
    handleSyncType,
    scheduleUtmSync,
    storeSelectedAdSenseAds,
    syncUtms,
  } = useScriptAndUtms();

  const [isCreatingSyncType, setIsCreatingSyncType] = React.useState<boolean>(false);

  const onConfirmButtonClick = React.useCallback(async () => {
    setIsCreatingSyncType(true);

    if (syncType === ESyncType.SCHEDULE) {
      const mappedStoreSelectedAdSenseAds = storeSelectedAdSenseAds.map(adSenseAd => adSenseAd.id);

      const nextMidnightDate = new Date().setHours(24, 0, 0, 0);

      const data: ICreateScheduleData = {
        schedule: {
          entity: EScheduleEntity.STORE,
          action: EScheduleAction.SYNC_UTM,
          date: new Date(nextMidnightDate).toISOString(),
          period: ESchedulePeriod.ONCE,
        },
        schedule_items: [
          {
            values: mappedStoreSelectedAdSenseAds,
            entity: EScheduleItemEntity.AD_SENSE_AD,
            metadata: {},
          },
        ],
      };

      await scheduleUtmSync(data);
    } else {
      await syncUtms();
    }

    setIsCreatingSyncType(false);
  }, [scheduleUtmSync, syncType, syncUtms, storeSelectedAdSenseAds]);

  const isScheduleStep = userStep === EScriptAndUtmsStep.SCHEDULE;
  const isNotProcessingStep =
    userStep !== EScriptAndUtmsStep.PROCESSING_UTM_TAGS &&
    userStep !== EScriptAndUtmsStep.UTM_TAGS_PROCESSED;

  return (
    <S.Wrapper>
      {isNotProcessingStep && (
        <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
          Adicionar horário para sincronizar UTMs
        </S.Title>
      )}

      {isScheduleStep && (
        <>
          <S.Description>
            Recomendamos que faça a sincronização dos anúncios <strong>durante a noite</strong>,
            quando tem menos visitas em sua loja. Os anúncios podem ficar em análise por{' '}
            <strong>algumas horas/minutos.</strong>
          </S.Description>

          <S.InputGroup>
            <S.Label>Escolher opção para sincronizar</S.Label>

            <S.RadioOption>
              <S.Radio
                value={ESyncType.SCHEDULE}
                defaultChecked
                name="sync-type"
                onChange={handleSyncType}
                id="schedule-option"
              />
              <S.RadioText>Programar para sincronizar às 00:00</S.RadioText>
              <S.Badge color={EBadgeColors.PRIMARY}>Recomendado</S.Badge>
            </S.RadioOption>

            <S.RadioOption>
              <S.Radio
                value={ESyncType.SYNC_NOW}
                name="sync-type"
                onChange={handleSyncType}
                id="sync-now-option"
              />
              <S.RadioText>Sincronizar agora</S.RadioText>
            </S.RadioOption>
          </S.InputGroup>

          <S.HorizontalRule />

          <S.ConfirmButton
            onClick={onConfirmButtonClick}
            isLoading={isCreatingSyncType}
            disabled={isCreatingSyncType}
          >
            Escolher opção e continuar
          </S.ConfirmButton>
        </>
      )}

      {userStep === EScriptAndUtmsStep.PROCESSING_UTM_TAGS && <Processing />}
      {userStep === EScriptAndUtmsStep.UTM_TAGS_PROCESSED && <Finished />}
    </S.Wrapper>
  );
};

export default ScheduleSync;
