import React from 'react';
import { useParams } from 'react-router-dom';

import { INewKitProvider, INewKitVariant } from '@domain/interfaces/dashboard/Kit/newKit';
import { ECurrency } from '@domain/enums/common/ECurrency';
import { useToast } from '@helpers/hooks/useToast';
import kitService from '@services/pages/dashboard/kit/kit';
import { currencyToNumber } from '@helpers/masks';
import { IParams } from '@domain/interfaces/IParams';
import { useConfig } from '@helpers/hooks/useConfig';

const NewKitContext = React.createContext<INewKitProvider | null>(null);

export const NewKitProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { analytics, selectedStore, user } = useConfig();

  const [newKitVariants, setNewKitVariants] = React.useState<Array<INewKitVariant>>([]);
  const [hasKitName, setHasKitName] = React.useState<boolean>(false);
  const [kitCurrency, setKitCurrency] = React.useState<ECurrency>(ECurrency.USD);
  const [isCreatingKit, setIsCreatingKit] = React.useState<boolean>(false);
  const [isSyncModalOpen, setIsSyncModalOpen] = React.useState<boolean>(false);
  const [isSyncSideModalOpen, setIsSyncSideModalOpen] = React.useState<boolean>(false);

  const handleSyncModalOpen = React.useCallback(() => setIsSyncModalOpen(!isSyncModalOpen), [
    isSyncModalOpen,
  ]);

  const handleSyncSideModalOpen = React.useCallback(
    () => setIsSyncSideModalOpen(!isSyncSideModalOpen),
    [isSyncSideModalOpen],
  );

  const handleNewKitVariants = React.useCallback(variants => {
    setNewKitVariants(variants);
  }, []);

  const handleKitName = React.useCallback(status => setHasKitName(status), []);

  const isVariantsValid = React.useCallback(() => {
    if (newKitVariants.length > 1) return true;

    if (newKitVariants[0]?.quantity > 1 && newKitVariants[0].variant) return true;

    return false;
  }, [newKitVariants]);

  const addVariant = React.useCallback(
    (newKitVariant: INewKitVariant) => {
      handleNewKitVariants([...newKitVariants, newKitVariant]);
    },
    [newKitVariants, handleNewKitVariants],
  );

  const handleKitCurrency = React.useCallback(currency => {
    setKitCurrency(currency);
  }, []);

  const mapVariantKitItems = React.useCallback(kitVariants => {
    const mappedVariantKitItems = kitVariants.map((kitVariant: INewKitVariant) => {
      return {
        quantity: kitVariant.quantity,
        variant_id: kitVariant.variant?.id,
      };
    });

    return mappedVariantKitItems;
  }, []);

  const createNewKit = React.useCallback(
    async data => {
      setIsCreatingKit(true);

      try {
        const mappedVariantKitItems = mapVariantKitItems(newKitVariants);

        await kitService.createKit({
          storeAliasId,
          data: {
            cost: currencyToNumber(data.kit_cost),
            currency: kitCurrency,
            name: data.kit_name,
            variant_kit_items: mappedVariantKitItems,
          },
        });
        setIsCreatingKit(false);

        toast.success('Kit criado com sucesso!');

        analytics?.track(
          'Kit Cost Created',
          {
            name: data.kit_name,
            total_variant_kit_items: mappedVariantKitItems.length,
            currency: kitCurrency,
            amount: currencyToNumber(data.kit_cost),
            email: user?.email,
          },
          { groupId: selectedStore?.alias_id },
        );

        handleSyncModalOpen();
      } catch (error: any) {
        setIsCreatingKit(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [
      toast,
      kitCurrency,
      mapVariantKitItems,
      newKitVariants,
      storeAliasId,
      analytics,
      selectedStore,
      handleSyncModalOpen,
      user,
    ],
  );

  return (
    <NewKitContext.Provider
      value={{
        newKitVariants,
        handleNewKitVariants,
        addVariant,
        handleKitName,
        hasKitName,
        handleKitCurrency,
        kitCurrency,
        isVariantsValid,
        createNewKit,
        isCreatingKit,
        isSyncModalOpen,
        isSyncSideModalOpen,
        handleSyncModalOpen,
        handleSyncSideModalOpen,
      }}
    >
      {children}
    </NewKitContext.Provider>
  );
};

export const useNewKit = (): INewKitProvider => {
  const context = React.useContext(NewKitContext);

  if (!context) {
    throw new Error('useNewKit must be used within KitContext');
  }

  return context;
};
