import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Modal = styled(ModalBase)`
  width: 100%;
  max-width: 428px;
  padding: 32px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-bottom: 22px;
`;

export const Heading = styled(TextBase)`
  font-size: 1.125rem;
`;

export const Description = styled(TextBase)``;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 34px;
  margin-top: 24px;
  margin-left: auto;
`;

export const CancelButton = styled.button`
  border: unset;
  background-color: unset;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray[1]};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const DeleteGroupButton = styled(ButtonBase)``;
