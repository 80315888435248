import React from 'react';

import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { EButtonVariant } from '@domain/enums/components/EButton';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { INoStoreGoalContentProps } from '@domain/interfaces/dashboard/FinancialDashboard/IStoreGoal';

import * as S from './styles';

const NoStoreGoalContent: React.FC<INoStoreGoalContentProps> = ({ isNoRetroactiveDashboard }) => {
  const { handleNewStoreGoalSideModal } = useFinancialDashboard();

  if (isNoRetroactiveDashboard) {
    return (
      <S.NoRetroactiveWrapper>
        {isNoRetroactiveDashboard && (
          <S.Heading fontWeight={EHeadingWeight.REGULAR} type={EHeadingSize.H6}>
            Acompanhe suas metas
          </S.Heading>
        )}

        <S.Content>
          <S.CreateNewGoalText>
            Cadastre uma meta de Receita Líquida ou Lucro Líquido
          </S.CreateNewGoalText>

          <S.CreateNewGoalButton
            onClick={handleNewStoreGoalSideModal}
            variant={EButtonVariant.SECONDARY_BUTTON}
          >
            Cadastrar meta
          </S.CreateNewGoalButton>
        </S.Content>
      </S.NoRetroactiveWrapper>
    );
  }

  return (
    <S.Wrapper>
      <S.CreateNewGoalText>
        Cadastre uma meta de Receita Líquida ou Lucro Líquido
      </S.CreateNewGoalText>
      <S.CreateNewGoalButton
        onClick={handleNewStoreGoalSideModal}
        variant={EButtonVariant.SECONDARY_BUTTON}
      >
        Cadastrar meta
      </S.CreateNewGoalButton>
    </S.Wrapper>
  );
};

export default NoStoreGoalContent;
