import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import SwitchBase from '@components/common/core/Inputs/Switch';
import TextBase from '@components/common/core/DataDisplay/Text';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';

export const TooltipText = styled(TextBase)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const TooltipContent = styled.div`
  max-width: 242px;
  width: 100%;
`;

export const Tooltip = styled(CustomTooltipBase)``;

export const Table = styled(TableBase)``;

export const Switch = styled(SwitchBase)`
  width: 30px;
`;

export const InfosWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AccountInfo = styled(TextBase)``;

export const Description = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
`;
