import React from 'react';

import Boleto from './Boleto';
import CreditCard from './CreditCard';
import Pix from './Pix';

import * as S from './styles';

const PaymentMethodsCards: React.FC = () => {
  const sliderPaymentMethodsCardsRef = React.useRef<HTMLDivElement>(null);

  const [isMouseMoveOn, setIsMouseMoveOn] = React.useState<boolean>(false);
  const [onMouseMoveDownEvent, setOnMouseMoveDownEvent] = React.useState<any>({});
  const [scrollLeft, setScrollLeft] = React.useState<number | undefined>(0);

  const handleOnMouseDown = React.useCallback(event => {
    setIsMouseMoveOn(true);
    setOnMouseMoveDownEvent(event);
  }, []);

  const handleOnMouseUp = React.useCallback(() => {
    setIsMouseMoveOn(false);
    setScrollLeft(sliderPaymentMethodsCardsRef?.current?.scrollLeft);
    setOnMouseMoveDownEvent({});
  }, []);

  const handleOnMouseMove = React.useCallback(
    event => {
      event.persist();

      if (isMouseMoveOn && sliderPaymentMethodsCardsRef.current) {
        const position = onMouseMoveDownEvent.pageX - event.clientX;

        sliderPaymentMethodsCardsRef.current.scrollLeft = (scrollLeft || 0) + position;
      }
    },
    [isMouseMoveOn, scrollLeft, sliderPaymentMethodsCardsRef, onMouseMoveDownEvent],
  );

  return (
    <S.Wrapper
      onMouseDown={handleOnMouseDown}
      onMouseUp={handleOnMouseUp}
      onMouseMove={handleOnMouseMove}
      ref={sliderPaymentMethodsCardsRef}
      tabIndex={0}
    >
      <Boleto />
      <CreditCard />
      <Pix />
    </S.Wrapper>
  );
};

export default PaymentMethodsCards;
