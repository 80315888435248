import React from 'react';
import { useForm } from 'react-hook-form';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ECancellationReason } from '@domain/enums/subscription/ECancellationReason';

import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { useConfig } from '@helpers/hooks/useConfig';

import Form from '@components/common/core/Inputs/Form';

import * as S from './styles';

interface ICancelSubscriptionProps {
  isOpen: boolean;
  toggle: () => void;
}

const CancelSubscription: React.FC<ICancelSubscriptionProps> = ({ isOpen, toggle }) => {
  const { register, handleSubmit } = useForm({ mode: 'onBlur' });
  const { cancelSubscription } = useStoreSubscription();
  const { user } = useConfig();

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [reason, setReason] = React.useState<ECancellationReason>(
    ECancellationReason.WITHOUT_ANY_REASON,
  );

  const onSubmit = React.useCallback(
    async data => {
      if (!toggle) return;

      setIsSubmittingForm(true);

      await cancelSubscription({
        cancellation_reason: reason,
        cancellation_reason_description: data.description || 'none',
      });

      setIsSubmittingForm(false);
      toggle();
    },
    [cancelSubscription, reason, toggle],
  );

  const onRadioChange = React.useCallback(event => setReason(event.target.value), []);

  const handleToggle = React.useCallback(() => {
    if (!isSubmittingForm && toggle) {
      toggle();
    }
  }, [isSubmittingForm, toggle]);

  return (
    <S.Modal isOpen={isOpen} toggle={handleToggle}>
      <S.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
            {`Caro(a) ${user.first_name},`}{' '}
          </S.Title>
          <S.Description>
            Estamos tristes por sua saída. Mas se esta é sua decisão, pedimos encarecidamente que
            nos dê a chance de saber o motivo do cancelamento. Pode ser absurdamente sincero(a)
            conosco. Assim podemos melhorar, se for algo que depende de nós.
            <br />
            <br />O real motivo de cancelar minha assinatura foi:
          </S.Description>

          <S.RadioGroup>
            <S.RadioLabel>
              <S.Radio
                name="reason"
                value={ECancellationReason.CLOSING_STORE}
                onChange={onRadioChange}
                id="1"
              />
              <S.RadioText>Estou fechando minha loja ou empresa</S.RadioText>
            </S.RadioLabel>

            <S.RadioLabel>
              <S.Radio
                name="reason"
                value={ECancellationReason.MISSING_INTEGRATIONS}
                onChange={onRadioChange}
                id="2"
              />
              <S.RadioText>Falta de alguma integração</S.RadioText>
            </S.RadioLabel>

            <S.RadioLabel>
              <S.Radio
                name="reason"
                value={ECancellationReason.FINANCIAL_ISSUES}
                onChange={onRadioChange}
                id="3"
              />
              <S.RadioText>Problemas financeiros</S.RadioText>
            </S.RadioLabel>

            <S.RadioLabel>
              <S.Radio
                name="reason"
                value={ECancellationReason.TECHNICAL_ISSUE}
                onChange={onRadioChange}
                id="4"
              />
              <S.RadioText>Problemas técnicos na plataforma</S.RadioText>
            </S.RadioLabel>

            <S.RadioLabel>
              <S.Radio
                name="reason"
                value={ECancellationReason.CREDID_CARD_ISSUE}
                onChange={onRadioChange}
                id="5"
              />
              <S.RadioText>Problemas com cartões de crédito e limites</S.RadioText>
            </S.RadioLabel>

            <S.RadioLabel>
              <S.Radio
                name="reason"
                value={ECancellationReason.PAYMENT_PROCESSING_ERRORS}
                onChange={onRadioChange}
                id="6"
              />
              <S.RadioText>Erros de processamento do pagamento</S.RadioText>
            </S.RadioLabel>

            <S.RadioLabel>
              <S.Radio
                name="reason"
                value={ECancellationReason.UNSATISFACTORY_SOLUTION}
                onChange={onRadioChange}
                id="7"
              />
              <S.RadioText>Estou insatisfeito com a solução</S.RadioText>
            </S.RadioLabel>

            <S.RadioLabel>
              <S.Radio
                name="reason"
                value={ECancellationReason.PLAN_PRICE_TOO_EXPENSIVE}
                onChange={onRadioChange}
                id="8"
              />
              <S.RadioText>O preço do meu plano está caro</S.RadioText>
            </S.RadioLabel>

            <S.RadioLabel>
              <S.Radio
                name="reason"
                value={ECancellationReason.INSUFFICIENT_SUPPORT}
                onChange={onRadioChange}
                id="9"
              />
              <S.RadioText>Tive problemas com atendimento / suporte</S.RadioText>
            </S.RadioLabel>

            <S.RadioLabel>
              <S.Radio
                name="reason"
                value={ECancellationReason.MIGRATE_TO_OTHER_PLATFORM}
                onChange={onRadioChange}
                id="10"
              />
              <S.RadioText>Recebi melhor proposta de um concorrente</S.RadioText>
            </S.RadioLabel>

            <S.RadioLabel>
              <S.Radio
                name="reason"
                value={ECancellationReason.TOO_COMPLEX}
                onChange={onRadioChange}
                id="11"
              />
              <S.RadioText>Achei a plataforma complexa</S.RadioText>
            </S.RadioLabel>

            <S.RadioLabel>
              <S.Radio
                name="reason"
                value={ECancellationReason.REPENT}
                onChange={onRadioChange}
                id="12"
              />
              <S.RadioText>Arrependimento</S.RadioText>
            </S.RadioLabel>

            <S.RadioLabel>
              <S.Radio
                name="reason"
                value={ECancellationReason.OTHER}
                onChange={onRadioChange}
                id="13"
              />
              <S.RadioText>Outros</S.RadioText>
            </S.RadioLabel>
          </S.RadioGroup>

          <S.TextArea {...register('description')} placeholder="Comentário opcional" />

          <S.ButtonWrapper>
            <S.ButtonBack onClick={toggle} variant={EButtonVariant.TERTIARY}>
              Voltar
            </S.ButtonBack>
            <S.ButtonCancel type="submit" isLoading={isSubmittingForm} disabled={isSubmittingForm}>
              Cancelar Assinatura
            </S.ButtonCancel>
          </S.ButtonWrapper>
        </Form>
      </S.Body>
    </S.Modal>
  );
};

export default CancelSubscription;
