import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import SwitchBase from '@components/common/core/Inputs/Switch';

export const Wrapper = styled.div`
  padding: 32px;
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background[8]};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

export const Title = styled(HeadingBase)`
  font-weight: 400;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const SubTitle = styled(TextBase)`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[2]};
  margin-bottom: 32px;
`;

export const TargetNotificationChannelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
`;

export const TargetNotificationChannelTitle = styled(TextBase)`
  font-size: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const NotificationWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const NotificationTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 501px;
`;

export const NotificationTitle = styled(TextBase)`
  font-weight: 500;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const NotificationSubTitle = styled(TextBase)`
  font-weight: 400;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const Switch = styled(SwitchBase)`
  width: 44px;
`;
