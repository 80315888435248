import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { createProductTagSchema } from '@helpers/validators/dashboard/productTag/productTag';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { EButtonSize } from '@domain/enums/components/EButton';
import { useOnboardingTag } from '@helpers/hooks/pages/dashboard/productAnalytics/useOnboardingTag';

import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const FirstTag: React.FC = () => {
  const { createProductTag } = useOnboardingTag();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(createProductTagSchema) });

  const [isCreatingProductTag, setIsCreatingProductTag] = React.useState<boolean>(false);
  const [tagValue, setTagValue] = React.useState<string>('');

  const tagInputRegister = register('new_tag');

  const onTagChange = React.useCallback(
    event => {
      tagInputRegister.onChange(event);

      setTagValue(event.target.value);
    },
    [tagInputRegister],
  );

  const onSubmit = React.useCallback(
    async formData => {
      setIsCreatingProductTag(true);

      await createProductTag(formData.new_tag, 'add_tag');

      setIsCreatingProductTag(false);
    },
    [createProductTag],
  );

  return (
    <>
      <S.Heading type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
        Adicionar identificador de campanhas
      </S.Heading>

      <S.Description>
        Para ativar suas campanhas mais facilmente, <strong>crie uma tag (identificador)</strong>{' '}
        com um nome em <strong>comum das suas campanhas</strong> deste produto.
      </S.Description>

      <S.Example>
        <strong>Exemplo:</strong> [CONV][LAL]<strong>[PRODUTO X] Campanha A</strong> sua tag será:{' '}
        <strong>[PRODUTO X]</strong>
      </S.Example>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label>Digite uma palavra chave que identifique suas campanhas deste produto</S.Label>
          <S.Input
            {...tagInputRegister}
            type="text"
            value={tagValue}
            placeholder="Ex. [produto x]"
            onChange={onTagChange}
          />
          {errors.new_tag && <Text isErrorFeedback>{errors.new_tag.message}</Text>}
        </S.InputGroup>

        <S.AddTagButton
          type="submit"
          buttonSize={EButtonSize.MEDIUM}
          isLoading={isCreatingProductTag}
          disabled={isCreatingProductTag || !tagValue}
        >
          Adicionar tag
        </S.AddTagButton>
      </Form>
    </>
  );
};

export default FirstTag;
