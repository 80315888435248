import React from 'react';
import { CircleSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components/macro';

import { ESwitchWidth, ESwitchSize } from '@domain/enums/components/ESwitch';
import { ISwitch } from '@domain/interfaces/components/ISwitch';

import * as S from './styles';

const Switch: React.FC<ISwitch> = ({
  onChange,
  width = ESwitchWidth.SHORT,
  size = ESwitchSize.MEDIUM,
  id,
  name,
  defaultChecked,
  innerRef,
  isLoading = false,
  disabled,
  checked,
  ...rest
}) => {
  const theme: any = useTheme();

  return (
    <S.Wrapper customWidth={width} customSize={size} {...rest}>
      <S.SwitchWrapper customWidth={width} customSize={size}>
        <S.SwitchInput
          id={id}
          name={name}
          type="checkbox"
          customWidth={width}
          customSize={size}
          defaultChecked={defaultChecked}
          checked={checked}
          ref={innerRef}
          onChange={onChange}
          disabled={isLoading || disabled}
        />
        <S.SwitchLabel htmlFor={id} customWidth={width} customSize={size} />
      </S.SwitchWrapper>

      <CircleSpinner color={theme.colors.green.default} loading={isLoading} size={16} />
    </S.Wrapper>
  );
};

export default React.memo(Switch);
