import React from 'react';
import { ChevronRight, ChevronDown } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { ICategoryProps } from '@domain/interfaces/dashboard/CustomValuesCategories/ICategory';
import { ETextWeight } from '@domain/enums/components/EText';
import { useCustomValuesCategories } from '@helpers/hooks/pages/dashboard/customValues/useCustomValuesCategories';
import Input from './InputGroup';

import * as S from './styles';

const Category: React.FC<ICategoryProps> = ({ name, groupType }) => {
  const { customValuesCategories } = useCustomValuesCategories();
  const theme: any = useTheme();

  const filteredCustomValuesCategories = customValuesCategories.filter(
    category => groupType === category.group,
  );

  const [isGroupOpen, setIsGroupOpen] = React.useState<boolean>(false);

  const handleIsGroupOpen = React.useCallback(() => setIsGroupOpen(!isGroupOpen), [isGroupOpen]);

  return (
    <S.CategoryWrapper>
      <S.GroupWrapperButton onClick={handleIsGroupOpen}>
        <S.GroupIcon>
          {isGroupOpen ? (
            <ChevronDown size={16} color={theme.colors.gray[2]} />
          ) : (
            <ChevronRight size={16} color={theme.colors.gray[2]} />
          )}
        </S.GroupIcon>
        <S.GroupName weight={ETextWeight.MEDIUM}>{name}</S.GroupName>
      </S.GroupWrapperButton>

      {isGroupOpen && (
        <>
          {filteredCustomValuesCategories.length ? (
            <>
              {filteredCustomValuesCategories.map(category => (
                <Input key={category.alias_id} category={category} />
              ))}
            </>
          ) : (
            <S.EmptyData content="Não encontramos nenhuma categoria nesse grupo." />
          )}
        </>
      )}
    </S.CategoryWrapper>
  );
};

export default Category;
