import * as yup from 'yup';

export const shopifySchema = yup.object().shape({
  shopify_url: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'Informe um alias válido.')
    .max(400, 'Informe um alias válido.'),
  access_token: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(10, 'Informe um Token de Acesso da API Admin válido.')
    .max(200, 'Informe um Token de Acesso da API Admin válido.'),
  secret_key: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(10, 'Informe uma Chave secreta da API válida.')
    .max(200, 'Informe uma Chave secreta da API válida.'),
});

export const updateShopifySchema = yup.object().shape({
  access_token: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(10, 'Informe um Token de Acesso da API Admin válido.')
    .max(200, 'Informe um Token de Acesso da API Admin válido.')
    .test('valid access token', 'Insira um Token de Acesso da API Admin válido.', value => {
      if (value?.includes('*')) return false;

      return true;
    }),
  secret_key: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(10, 'Informe uma Chave secreta da API válida.')
    .max(200, 'Informe uma Chave secreta da API válida.')
    .test('valid access token', 'Insira uma Chave secreta da API válida.', value => {
      if (value?.includes('*')) return false;

      return true;
    }),
});
