export enum ECreateStoreStep {
  FIRST_STEP = 'FIRST_STEP',
  SECOND_STEP = 'SECOND_STEP',
  INFO_STEP = 'INFO_STEP',
  STORE_NAME_STEP = 'STORE_NAME_STEP',
  FINISHED_STEP = 'FINISHED_STEP',
}

export enum EEcommerceOptions {
  VIRTUAL_STORE_ONLY = 'VIRTUAL_STORE_ONLY',
  VIRTUAL_STORE_AND_MARKETPLACES = 'VIRTUAL_STORE_AND_MARKETPLACES',
  MARKETPLACES_ONLY = 'MARKETPLACES_ONLY',
}
