import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/CustomModal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Wrapper = styled(ModalBase)`
  width: 100%;
  max-width: 588px;
  height: auto;
  padding: 32px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 32px;
  box-sizing: border-box;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  box-sizing: border-box;
`;

export const GroupTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled(TextBase)`
  font-size: 1.25rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
  margin-left: 8px;
`;

export const CloseButton = styled.button`
  background: unset;
  padding: unset;
  border: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const AddConditionButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  box-sizing: border-box;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray[5]};
    position: absolute;
  }
`;

export const AddConditionButton = styled(ButtonBase)`
  border-radius: 100px;
`;

export const AddedOptionsWrapper = styled.div`
  width: 100%;
  height: auto;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 32px;
  box-sizing: border-box;
  margin-top: 25px;

  scrollbar-color: ${({ theme }) => theme.colors.gray[4]} ${({ theme }) => theme.colors.gray[6]};
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[4]};
  }
`;

export const SaveButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 47px 32px 0 32px;
  width: 100%;
  box-sizing: border-box;
`;

export const SaveButton = styled(ButtonBase)`
  width: 100%;
`;
