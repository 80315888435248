import styled from 'styled-components/macro';

import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';

export const Arrow = styled(CustomPopoverBase.Arrow)`
  fill: ${({ theme }) => theme.colors.gray[6]};
`;

export const Content = styled(CustomPopoverBase.Content)`
  background-color: ${({ theme }) => theme.colors.gray[6]};
  padding: 24px;
  box-sizing: border-box;
  width: 388px;
  border-radius: 10px;

  @media only screen and (max-width: 512px) {
    width: 340px;
  }

  @media only screen and (max-width: 400px) {
    width: 320px;
  }
`;
