import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Container = styled.section`
  width: calc(100% + 72px);
  background: linear-gradient(253.21deg, #0b0d11 4.75%, #000000 71.62%);
  padding-top: 96px;
  padding-bottom: 96px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 420px) {
    padding-bottom: 60px;
  }

  @media only screen and (max-height: 768px) {
    padding-top: 20px;
    padding-bottom: 30px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1002px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;

  @media only screen and (max-width: 1180px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media only screen and (max-height: 768px) {
    gap: 32px;
  }
`;

export const Heading = styled(HeadingBase)`
  font-size: 2rem;
  color: #ffffff;
`;

export const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 48px;
  column-gap: 54px;

  @media only screen and (max-height: 768px) {
    row-gap: 24px;
    column-gap: 32px;
  }
`;

export const Card = styled.div`
  width: 298px;
  height: 242px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 660px) {
    width: 100%;
  }
`;

export const CardTitle = styled(TextBase)`
  color: #ffffff;
  font-size: 1.25rem;
  line-height: 130%;
  margin-bottom: 8px;
`;

export const CardDescription = styled(TextBase)`
  line-height: 150%;
  text-align: center;
  color: #d2d2d2;
  max-width: 250px;
`;
