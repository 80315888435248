import styled, { css } from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import CardBase from '@components/common/core/Surfaces/Card';
import TextBase from '@components/common/core/DataDisplay/Text';
import {
  ISelectedOverlayerCardProps,
  ICardPercentageProps,
  ICardWrapperProps,
  IContentProps,
} from '@domain/interfaces/dashboard/FinancialDashboard/ICard';

export const LastSyncText = styled(TextBase)`
  line-height: 120%;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[3]};
`;

export const CardWrapper = styled(CardBase)<ICardWrapperProps>`
  padding: 22px 16px;
  box-sizing: border-box;
  min-width: 264px;
  height: 96px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border: unset;
  border-radius: 12px;
  cursor: default;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;

  & > a {
    z-index: 2;
    position: absolute;
    top: 12px;
    right: 40px;
    display: flex;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Title = styled(HeadingBase)`
  color: #fff;
  line-height: 120%;
`;

export const Value = styled(TextBase)`
  color: #fff;
  line-height: 120%;
`;

export const Percentage = styled(TextBase)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.success.default};
`;

export const ValueAndPercentageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

export const Content = styled.div<IContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;

  ${({ isMarketing }) =>
    isMarketing &&
    css`
      gap: 2px;
    `}
`;
export const PercentageArrow = styled.div<ICardPercentageProps>`
  margin-right: 4px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid ${({ theme }) => theme.colors.danger.default};

  ${({ isPositive, theme }) =>
    isPositive &&
    css`
      border-top: 0;
      border-bottom: 6px solid ${theme.colors.success.default};
    `}
`;

export const PercentageNumber = styled(TextBase)<ICardPercentageProps>`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.danger.default};
  display: flex;
  gap: 4px;
  align-items: center;
  line-height: 120%;

  ${({ isPositive, theme }) =>
    isPositive &&
    css`
      color: ${theme.colors.success.default};
    `}
`;

export const PercentageWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SelectedOverlayer = styled.div<ISelectedOverlayerCardProps>`
  position: absolute;
  width: 100%;
  height: 96px;
  left: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 12px;
  box-sizing: border-box;
  transition: all 0.3s;

  &:hover {
    background-color: rgba(37, 207, 187, 0.05);
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: rgba(37, 207, 187, 0.05);
      border-color: ${({ theme }) => theme.colors.green.default};
    `}
`;
