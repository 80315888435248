import React from 'react';
import { Eduzz as Icon } from '@profitfy/pakkun-icons';
import { useParams } from 'react-router-dom';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';
import { IActiveIntegrationProps } from '@domain/interfaces/dashboard/IntegrationCenter/InfoProduct/IEduzz';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import eduzzService from '@services/pages/dashboard/integrationCenter/infoProduct/platforms/eduzz';

import ActiveIntegrationFooter from '@components/Dashboard/IntegrationCenter/Sidemodal/ActiveIntegrationFooter';

import * as S from './styles';

const ActiveIntegration: React.FC<IActiveIntegrationProps> = ({ credential }) => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { mutateStore } = useStoreConfig();

  const [isActivatingIntegration, setIsActivatingIntegration] = React.useState<boolean>(false);

  const handleButtonClick = React.useCallback(async () => {
    setIsActivatingIntegration(true);

    try {
      await eduzzService.updateCredential({
        storeAliasId,
        credentialAliasId: credential.alias_id,
        data: {
          api_key: credential.api_key,
          origin_key: credential.origin_key,
          public_key: credential.public_key,
          is_active: true,
        },
      });

      toast.success('Credencial Ativada com sucesso!');

      await mutateStore();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsActivatingIntegration(false);
    }
  }, [mutateStore, storeAliasId, toast, credential]);

  return (
    <S.ContentWrapper>
      <S.Header>
        <Icon size={52} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.REGULAR}>
          Integração com Eduzz
        </S.Heading>
      </S.Header>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>

        <S.FunctionalityDescriptionText>
          A integração com sua plataforma de info-produto serve para puxarmos seus dados de
          plataforma aumentando a exatidão dos seus dados.
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <ActiveIntegrationFooter
        onIntegrateClick={handleButtonClick}
        isIntegrating={isActivatingIntegration}
        disabled={isActivatingIntegration}
      />
    </S.ContentWrapper>
  );
};

export default ActiveIntegration;
