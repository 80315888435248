import React from 'react';

export const useMediaQuery = (mediaQuery: string): boolean => {
  const [matchMedia, setMatchMedia] = React.useState<boolean>(
    window.matchMedia(`(max-width: ${mediaQuery})`).matches,
  );

  const handleMatchMedia = React.useCallback(() => {
    setMatchMedia(window.matchMedia(`(max-width: ${mediaQuery})`).matches);
  }, [mediaQuery]);

  React.useEffect(() => {
    window.addEventListener('resize', handleMatchMedia);

    handleMatchMedia();

    return () => window.addEventListener('resize', handleMatchMedia);
  }, [handleMatchMedia]);

  return matchMedia;
};

export const useMediaQueryHeight = (mediaQuery: string): boolean => {
  const [matchMedia, setMatchMedia] = React.useState<boolean>(
    window.matchMedia(`(max-height: ${mediaQuery})`).matches,
  );

  const handleMatchMedia = React.useCallback(() => {
    setMatchMedia(window.matchMedia(`(max-height: ${mediaQuery})`).matches);
  }, [mediaQuery]);

  React.useEffect(() => {
    window.addEventListener('resize', handleMatchMedia);

    handleMatchMedia();

    return () => window.addEventListener('resize', handleMatchMedia);
  }, [handleMatchMedia]);

  return matchMedia;
};
