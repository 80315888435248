import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  display: flex;
  align-items: center;
  padding: 8px 16px;
  box-sizing: border-box;
`;

export const Name = styled(TextBase)`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[1]};
  margin-left: 8px;
`;

export const IconGroup = styled.button`
  background: unset;
  padding: unset;
  border: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 5px;
`;

export const Likes = styled(TextBase)`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[2]};
`;
