import React from 'react';

import { ISaveButton } from '@domain/interfaces/dashboard/ProductAnalytics/IRankingTable';

import * as S from './styles';

const SaveButton: React.FC<ISaveButton> = ({ toggle, disabled, isLoading }) => {
  return (
    <S.SaveButtonWrapper>
      <S.CancelButton onClick={toggle}>Cancelar</S.CancelButton>
      <S.Button type="submit" isLoading={isLoading} disabled={disabled}>
        Salvar
      </S.Button>
    </S.SaveButtonWrapper>
  );
};

export default SaveButton;
