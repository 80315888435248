import legalPersonErrors from '@locales/subscription/checkout/legalPerson.json';
import naturalPersonErrors from '@locales/subscription/checkout/naturalPerson.json';
import commonErrors from '@locales/common/commonErrors.json';

import { EPersonType } from '@domain/enums/common/personType/EPersonType';

export const getCheckoutPersonalInfoTranslatedErrorMessage = (
  error: any,
  personalType: EPersonType,
): string => {
  const errorMessage = error?.response?.data?.message;

  if (!errorMessage) return error;

  const arrayToBeFounded =
    personalType === EPersonType.LEGAL_PERSON ? legalPersonErrors : naturalPersonErrors;

  const mergedArray = [...arrayToBeFounded, ...commonErrors];

  const foundErrorMessage = mergedArray.find(
    personalInfoError => personalInfoError.apiError === errorMessage,
  );

  if (foundErrorMessage) return foundErrorMessage.translatedError;

  return errorMessage;
};
