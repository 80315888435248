import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 32px;
  box-sizing: border-box;
  margin-top: 30px;
`;

export const Image = styled(ImageBase)`
  width: 100%;
  max-width: 250px;
  margin-bottom: 10px;
`;

export const Message = styled(TextBase)`
  font-size: 1.25rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.background[15]};
  text-align: center;
`;

export const NewInstance = styled(ButtonBase)`
  padding: 2px 16px 2px 16px;
  margin-top: 16px;
`;
