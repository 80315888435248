import styled, { css } from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import DropDownBase from '@components/common/core/Utils/DropDown';
import { IMoreOptions } from '@domain/interfaces/dashboard/CustomValuesCategories/ILabel';

export const ActionButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.font};
  cursor: pointer;
  transition: color 0.3s;
  position: relative;

  &:hover {
    color: ${({ theme }) => theme.colors.green.default};
  }

  &:active {
    color: ${({ theme }) => theme.colors.green.dark};
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const ActionsWrapper = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const MenuDropDown = styled(DropDownBase)``;

export const Bullet = styled.span`
  display: block;
  background-color: ${({ theme }) => theme.colors.font};
  width: 4px;
  height: 4px;
  border-radius: 50%;
`;

export const MoreOptions = styled.button<IMoreOptions>`
  background-color: unset;
  border: none;
  padding: 4px;
  gap: 3px;
  align-items: center;
  cursor: pointer;
  display: none;
  transition: all 0.1s;

  ${({ isHovering }) =>
    isHovering &&
    css`
      display: flex;
    `}
`;

export const CategoryName = styled(TextBase)`
  cursor: default;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
