import styled from 'styled-components/macro';

import CustomDropDownBase from '@components/common/core/Utils/CustomDropDown';

export const UtmBadge = styled.span`
  font-size: 0.625rem;
  font-weight: 700;
`;

export const ActionButton = styled.button`
  padding: 12px 20px;
  text-align: left;
  background: unset;
  border: unset;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.font};
  display: flex;
  align-items: center;
  gap: 12px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[6]};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.gray[6]};
    filter: brightness(0.9);
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.black.light};
`;

export const Arrow = styled(CustomDropDownBase.Arrow)`
  fill: ${({ theme }) => theme.colors.black.light};
`;

export const Content = styled(CustomDropDownBase.Content)`
  padding: unset;
`;
