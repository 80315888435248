import React from 'react';

import errorImage from '@assets/components/PageError/error.svg';
import { ETextSize } from '@domain/enums/components/EText';
import { IPageErrorProps } from '@domain/interfaces/components/IPageError';
import { EButtonVariant } from '@domain/enums/components/EButton';
import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const PageError: React.FC<IPageErrorProps> = ({ mutate }) => {
  const { handleLogout } = useConfig();

  const handleSignOutButton = React.useCallback(() => {
    handleLogout();
  }, [handleLogout]);

  return (
    <S.Container>
      <S.PageErrorWrapper>
        <S.Image src={errorImage} alt="Profitfy.me Algo Inesperado Aconteceu" />
        <S.Text size={ETextSize.B2}>
          Aconteceu um imprevisto e não foi possível carregar seus dados no momento.
        </S.Text>
        <S.Text>
          Você pode tentar carregar seus dados novamente, e se o problema persistir, contate o
          suporte.
        </S.Text>

        <S.ButtonWrapper>
          <S.Button onClick={mutate}>Tentar novamente</S.Button>
          <S.Button onClick={handleSignOutButton} variant={EButtonVariant.SECONDARY}>
            Sair
          </S.Button>
        </S.ButtonWrapper>
      </S.PageErrorWrapper>
    </S.Container>
  );
};

export default PageError;
