const getRandomWaveHeight = (): number => {
  const min = 3;
  const max = 14;

  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const getWavesNumber = (screenWidthSize: number): number => {
  let wavesNumber = 0;

  if (screenWidthSize > 955) {
    wavesNumber = 65;
  }

  if (screenWidthSize > 432 && screenWidthSize <= 955) {
    wavesNumber = 60;
  }

  if (screenWidthSize > 415 && screenWidthSize <= 432) {
    wavesNumber = 55;
  }

  if (screenWidthSize > 393 && screenWidthSize <= 415) {
    wavesNumber = 50;
  }

  if (screenWidthSize <= 393) {
    wavesNumber = 45;
  }

  return wavesNumber;
};

export const getAudioWaves = (
  screenWidthSize: number,
): Array<{ value: number; height: number }> => {
  const audioWaves = Array.from(Array(getWavesNumber(screenWidthSize)).keys()).map(item => {
    return {
      value: item,
      height: getRandomWaveHeight(),
    };
  });

  return audioWaves;
};

export const processText = (text: string): string => {
  const regexToIdentifyStrong = /\*(.*?)\*/g;
  const regexToIdentifyBrakeRow = /\n/g;

  const textWithStrong = text.replace(regexToIdentifyStrong, '<strong>$1</strong>');

  return textWithStrong.replace(regexToIdentifyBrakeRow, '<br />');
};
