import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import { EProgressBarStep } from '@domain/enums/onboarding/progressBar/ESteps';

import { useDropshipping } from '@helpers/hooks/pages/onboarding/useDropshipping';

import { GATEWAYS } from '@constants/common/integrations';

import SideBar from '@components/Onboarding/SideBar';

import * as S from './styles';

const Gateways: React.FC = () => {
  const {
    selectedGateways,
    hasSelectedStoreIntegrations,
    currentGatewayToIntegrate,
    handleNextGatewayToIntegrate,
  } = useDropshipping();

  const { storeAliasId } = useParams<IParams>();
  const history = useHistory();

  const getGatewayContent = React.useCallback(
    (integration: typeof GATEWAYS[number] | undefined) => {
      if (integration) {
        const Content = integration.onboardingContent;

        return <Content />;
      }

      return <></>;
    },
    [],
  );

  React.useEffect(() => {
    if (!selectedGateways.length && !currentGatewayToIntegrate && hasSelectedStoreIntegrations) {
      history.push(`/onboarding/${storeAliasId}/dropshipping/integrations/marketing`);
    }

    if (!selectedGateways.length && !currentGatewayToIntegrate && !hasSelectedStoreIntegrations) {
      history.push(`/onboarding/${storeAliasId}/dropshipping/integrations`);
    }

    if (!currentGatewayToIntegrate) {
      handleNextGatewayToIntegrate();
    }
  }, [
    selectedGateways,
    currentGatewayToIntegrate,
    history,
    storeAliasId,
    handleNextGatewayToIntegrate,
    hasSelectedStoreIntegrations,
  ]);

  const foundGateway = GATEWAYS.find(
    integration => integration.identifier === currentGatewayToIntegrate,
  );

  return (
    <S.Container>
      <SideBar
        onboardingStep={EProgressBarStep.INTEGRATIONS}
        linkToGoBack={`/onboarding/${storeAliasId}/dropshipping/integrations`}
      />
      {getGatewayContent(foundGateway)}
    </S.Container>
  );
};

export default Gateways;
