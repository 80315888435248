import React from 'react';
import { useParams } from 'react-router-dom';
import { CartPanda as CartPandaIcon } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ECartPandaType } from '@domain/enums/common/integrations/providers/ECartPanda';

import Save from '@components/Dashboard/IntegrationCenter/SideModalContent/Save';
import { useToast } from '@helpers/hooks/useToast';
import { IParams } from '@domain/interfaces/IParams';
import { ICartPandaCredentialOptionProps } from '@domain/interfaces/dashboard/IntegrationCenter/SideModalContent/StoreProvider/ICartPandaCredentialOption';
import cartPandaAuthService from '@services/pages/dashboard/integrationCenter/storeProvider/cartPanda/cartPandaAuth';

import * as S from './styles';

const EnableIntegration: React.FC<ICartPandaCredentialOptionProps> = ({
  cartPandaCredential,
  mutate,
}) => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const theme = useTheme();

  const [isEnablingCredential, setIsEnablingCredential] = React.useState<boolean>(false);

  const onEnableCredential = React.useCallback(async () => {
    setIsEnablingCredential(true);

    try {
      await cartPandaAuthService.updateCartPandaCredential({
        storeAliasId,
        cartPandaCredentialAliasId: cartPandaCredential.alias_id,
        data: { types: [...cartPandaCredential.types, ECartPandaType.STORE] },
      });

      toast.success('Integração ativada com sucesso!');

      setIsEnablingCredential(false);

      mutate();
    } catch (error: any) {
      setIsEnablingCredential(true);
      toast.error(error?.response?.data?.message);
    }
  }, [storeAliasId, toast, cartPandaCredential, mutate]);

  return (
    <S.Content>
      <S.Header>
        <CartPandaIcon size={52} color={theme.colors.gray[2]} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.REGULAR}>
          Integração com CartPanda
        </S.Heading>
      </S.Header>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>
        <S.FunctionalityDescriptionText>
          A integração com sua plataforma de e-commerce serve para puxar seus dados de loja.
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <S.TutorialWrapper>
        <S.TutorialHeading type={EHeadingSize.H5}>Tutorial de integração</S.TutorialHeading>
        <S.TutorialText>Basta ativar a integração abaixo.</S.TutorialText>
      </S.TutorialWrapper>

      <Save
        submitText="Ativar integração"
        isSaveButtonDisabled={isEnablingCredential}
        isSubmitting={isEnablingCredential}
        submitButtonOnClick={onEnableCredential}
      />
    </S.Content>
  );
};

export default EnableIntegration;
