import React from 'react';
import toastBase from 'react-hot-toast';
import { useTheme } from 'styled-components/macro';

import WarningIcon from '@components/common/core/DataDisplay/WarningIcon';

const ToastContext = React.createContext<any>(null);

export const ToastProvider: React.FC = ({ children }): any => {
  const theme: any = useTheme();

  const defaultConfig = {
    duration: 6000,
    style: {
      background: theme.colors.background[2],
      padding: '12px 16px',
      borderRadius: '5px',
      color: theme.colors.font,
      fontFamily: theme.fonts.body,
      display: 'flex',
      alignItems: 'center',
      minWidth: '288px',
      height: '19px',
      maxWidth: '420px',
      fontWeight: 500,
      fontSize: '1rem',
    },
  };

  const successStyle = {
    border: `1px solid ${theme.colors.success.default}`,
  };

  const dangerStyle = {
    border: `1px solid ${theme.colors.danger.default}`,
  };

  const warningStyle = {
    border: `1px solid ${theme.colors.warning.default}`,
  };

  const toast = {
    info: (message: string, error: any | undefined): string =>
      toastBase(message, {
        ...defaultConfig,
        icon: <WarningIcon />,
        style: {
          ...defaultConfig.style,
          ...warningStyle,
        },
      }),
    success: (message: string, error: any | undefined): string =>
      toastBase.success(message, {
        ...defaultConfig,
        style: {
          ...defaultConfig.style,
          ...successStyle,
        },
      }),
    error: (message: string, error: any | undefined): string => {
      let toastMessage = message;
      if (message === 'Store does not exists' && error) {
        throw new Error(error);
      }

      if (message === 'Store does not exists') return '';

      if (message === 'This store is not from this logged user')
        toastMessage = 'Esta loja não é deste usuário logado';

      if (message === 'Invalid update data') toastMessage = 'Dados de atualização inválidos';

      if (message === 'This synchronization is not from this store')
        toastMessage = 'Esta sincronização não é desta loja';

      return toastBase.error(toastMessage, {
        ...defaultConfig,
        style: {
          ...defaultConfig.style,
          ...dangerStyle,
        },
      });
    },
    error_long: (message: string, duration = 10000): string => {
      return toastBase.error(message, {
        ...defaultConfig,
        duration,
        style: {
          ...defaultConfig.style,
          ...dangerStyle,
        },
      });
    },
    loading: (message: string, error: any | undefined): string =>
      toastBase.loading(message, {
        ...defaultConfig,
        duration: undefined,
        style: { ...defaultConfig.style },
      }),
    dismiss: (toastId: string) => toastBase.dismiss(toastId),
    promise: (
      promise: any,
      loadingMessage: string,
      successMessage: string,
      errorMessage: string,
    ): any =>
      toastBase.promise(
        promise,
        {
          loading: loadingMessage,
          success: successMessage,
          error: errorMessage,
        },
        {
          style: {
            ...defaultConfig.style,
          },
        },
      ),
  };

  return <ToastContext.Provider value={{ toast }}>{children}</ToastContext.Provider>;
};

export const useToast = (): any => {
  const context = React.useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
};
