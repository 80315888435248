import styled, { css } from 'styled-components/macro';

import { IStepDecoratorProps, IStepProps } from '@domain/interfaces/subscription/checkout/IStepper';

import TextBase from '@components/common/core/DataDisplay/Text';

export const StepDecorator = styled.span<IStepDecoratorProps>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ theme }) => theme.colors.gray[3]};
  background-color: ${({ theme }) => theme.colors.background[8]};
  transition: all 0.3s;

  ${({ hasPassedStep, theme }) =>
    hasPassedStep &&
    css`
      border-color: ${theme.colors.green.default};
      background-color: ${theme.colors.green.default};
    `}

  ${({ isCurrentStep, theme }) =>
    isCurrentStep &&
    css`
      border-color: ${theme.colors.green.default};
    `}
`;

export const StepLabel = styled(TextBase)`
  font-size: 0.875rem;
  margin-bottom: 16px;
  display: inline-block;
`;

export const Step = styled.button<IStepProps>`
  background: unset;
  padding: unset;
  border: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${({ hasPassedStep }) =>
    hasPassedStep &&
    css`
      cursor: pointer;
    `}

  &:nth-child(1) {
    &:before {
      content: '';
      border: 1px solid ${({ theme }) => theme.colors.gray[3]};
      width: 172px;
      position: absolute;
      bottom: 12px;
      left: 40px;

      ${({ hasPassedStep, theme }) =>
        hasPassedStep &&
        css`
          border-color: ${theme.colors.green.default};
        `}

      @media only screen and (max-width: 1366px) {
        left: 38px;
      }

      @media only screen and (max-width: 524px) {
        width: 138px;
      }

      @media only screen and (max-width: 436px) {
        width: 88px;
      }
    }
  }

  &:nth-child(2) {
    &:before {
      content: '';
      border: 1px solid ${({ theme }) => theme.colors.gray[3]};
      width: 172px;
      position: absolute;
      bottom: 12px;
      left: 58px;

      ${({ hasPassedStep, theme }) =>
        hasPassedStep &&
        css`
          border-color: ${theme.colors.green.default};
        `}

      @media only screen and (max-width: 1366px) {
        left: 52px;
      }

      @media only screen and (max-width: 524px) {
        width: 146px;
      }

      @media only screen and (max-width: 436px) {
        width: 96px;
      }
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  max-width: 452px;
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  position: relative;

  @media only screen and (max-width: 885px) {
    margin: 0 auto;
  }

  @media only screen and (max-width: 524px) {
    max-width: 396px;
  }

  @media only screen and (max-width: 436px) {
    max-width: 296px;
  }
`;
