import React from 'react';
import { useTheme } from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';
import { ChevronDown, Cancel } from '@profitfy/pakkun-icons';

import { ETextWeight } from '@domain/enums/components/EText';
import { EBadgeColors } from '@domain/enums/components/EBadge';
import { IParams } from '@domain/interfaces/IParams';

import { useAccessManager } from '@helpers/hooks/pages/dashboard/accessManagement/useAccessManager';
import { useConfig } from '@helpers/hooks/useConfig';
import { useToast } from '@helpers/hooks/useToast';
import { useStorePermission } from '@helpers/hooks/common/useStorePermission';

import LimitationModal from '@components/common/core/Utils/LimitationModal';
import MemberRow from './MemberRow';

import * as S from './styles';

const MembersTable: React.FC = () => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const theme = useTheme();
  const { user: storeOwner } = useConfig();
  const { toast } = useToast();
  const { maxUsers } = useStorePermission();
  const {
    handleDeleteMemberModalOpen,
    users,
    selectedUsersStoreId,
    handleSelectedUsersStoreId,
  } = useAccessManager();

  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);
  const [isLimitationModalOpen, setIsLimitationModalOpen] = React.useState<boolean>(false);

  const handleLimitationModalOpen = React.useCallback(
    () => setIsLimitationModalOpen(state => !state),
    [],
  );

  const handlePopoverOpen = React.useCallback(() => {
    setIsPopoverOpen(!isPopoverOpen);
  }, [isPopoverOpen]);

  const onDeleteMemberButtonClick = React.useCallback(() => {
    if (selectedUsersStoreId.length) {
      handleDeleteMemberModalOpen();
      handlePopoverOpen();
    } else {
      toast.error('Selecione pelo menos um membro.');
    }
  }, [handleDeleteMemberModalOpen, handlePopoverOpen, toast, selectedUsersStoreId]);

  const handleSelectedAllMembersChange = React.useCallback(
    event => {
      if (!event || event.target) return;

      if (event.checked) {
        const mappedUsersId = users.map(user => user.user.id);

        handleSelectedUsersStoreId(mappedUsersId);
      } else {
        handleSelectedUsersStoreId([]);
      }
    },
    [handleSelectedUsersStoreId, users],
  );

  const onInviteNewMemberButtonClick = React.useCallback(() => {
    if (users.length + 1 >= maxUsers && maxUsers >= 0) {
      handleLimitationModalOpen();
      return;
    }

    history.push(`/${storeAliasId}/dashboard/access-manager/invite`);
  }, [history, storeAliasId, users, maxUsers, handleLimitationModalOpen]);

  const hasSelectedAllMembers = users.length > 0 && users.length === selectedUsersStoreId.length;

  return (
    <S.Wrapper>
      <S.Header>
        <S.Heading>Membros</S.Heading>

        <S.ButtonsWrapper>
          <S.CustomPopover open={isPopoverOpen} onOpenChange={handlePopoverOpen}>
            <S.ButtonTrigger>
              Ações
              <ChevronDown size={16} color={theme.colors.font} />
            </S.ButtonTrigger>

            <S.CustomPopoverContent side="bottom" sideOffset={4}>
              <S.DeleteSelectedMembersButton onClick={onDeleteMemberButtonClick}>
                <Cancel outline size={16} color={theme.colors.danger.default} />
                Remover selecionados
              </S.DeleteSelectedMembersButton>
              <S.CustomPopoverArrow />
            </S.CustomPopoverContent>
          </S.CustomPopover>
          <S.InviteNewMemberButton onClick={onInviteNewMemberButtonClick}>
            Convidar novo Membro
          </S.InviteNewMemberButton>
        </S.ButtonsWrapper>
      </S.Header>

      <S.Table>
        <S.Table.Header>
          <S.Table.Row>
            <S.Table.Head>
              <S.CheckboxWrapper>
                <S.Checkbox
                  checked={hasSelectedAllMembers}
                  onChange={handleSelectedAllMembersChange}
                />
              </S.CheckboxWrapper>
            </S.Table.Head>
            <S.Table.Head>Nome</S.Table.Head>
            <S.Table.Head>E-mail</S.Table.Head>
          </S.Table.Row>
        </S.Table.Header>

        <S.Table.Body>
          <S.Table.Row borderBottom>
            <S.Table.Data>
              <S.CheckboxWrapper>
                <S.Checkbox disabled />
              </S.CheckboxWrapper>
            </S.Table.Data>

            <S.Table.Data>
              <S.MemberNameTableDataContent>
                <S.MemberName
                  weight={ETextWeight.MEDIUM}
                >{`${storeOwner.first_name} ${storeOwner.last_name}`}</S.MemberName>
              </S.MemberNameTableDataContent>
            </S.Table.Data>

            <S.Table.Data>
              <S.EmailTableDataContent>
                {storeOwner.email}
                <S.Badge color={EBadgeColors.PRIMARY}>Proprietário</S.Badge>
              </S.EmailTableDataContent>
            </S.Table.Data>
          </S.Table.Row>

          {users.map(user => (
            <MemberRow key={user.user.alias_id} user={user} />
          ))}
        </S.Table.Body>
      </S.Table>

      <LimitationModal
        isOpen={isLimitationModalOpen}
        toggle={handleLimitationModalOpen}
        imageSrc="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/temp/limitation-temp.svg"
        title="Limite de usuários atingido"
        description="Escolha um plano que te permita convidar mais para que consiga usufruir o melhor da ferramenta."
        trackingPropertyName="MAX_USERS_REACHED"
      />
    </S.Wrapper>
  );
};

export default MembersTable;
