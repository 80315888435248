import { MelhorEnvio, Intelipost } from '@profitfy/pakkun-icons';

export const SHIPPING_INTEGRATIONS = [
  {
    name: 'Melhor envio',
    icon: MelhorEnvio,
  },
  {
    name: 'Intellipost',
    icon: Intelipost,
  },
];
