import React from 'react';
import { useTheme } from 'styled-components/macro';
import { Plus } from '@profitfy/pakkun-icons';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';

import * as S from './styles';

type GenerateStoreProps = {
  toggle: () => void;
};

const GenerateStore: React.FC<GenerateStoreProps> = ({ toggle }) => {
  const theme: any = useTheme();

  return (
    <S.Button onClick={toggle}>
      <S.Card>
        <S.Text size={ETextSize.B3} weight={ETextWeight.MEDIUM}>
          Gerar loja demo
        </S.Text>

        <Plus size={24} color={theme.colors.font} />
      </S.Card>
    </S.Button>
  );
};

export default GenerateStore;
