import React from 'react';

import { Payt as PaytIcon, Copy } from '@profitfy/pakkun-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import { IParams } from '@domain/interfaces/IParams';
import { useTheme } from 'styled-components/macro';
import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { useToast } from '@helpers/hooks/useToast';
import { useInfoProduct } from '@helpers/hooks/pages/onboarding/useInfoProduct';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';
import { paytSchema } from '@helpers/validators/dashboard/integrationCenter/infoProduct/payt';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import paytService from '@services/pages/dashboard/integrationCenter/infoProduct/platforms/paytAuth';

import NextStep from '@components/Onboarding/NextStep';
import Text from '@components/common/core/DataDisplay/Text';

import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Payt: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();

  const { selectedMarketings } = useInfoProduct();
  const { isLoadingTrial, handleStoreBonus } = useOnboarding();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(paytSchema) });

  const [webhookURL, setWebhookURL] = React.useState<string>('');
  const [isGettingWebhookURL, setIsGettingWebhookURL] = React.useState<boolean>(false);
  const [secretKeyValue, setSecretKeyValue] = React.useState<string>('');
  const [isURLCopied, setIsURLCopied] = React.useState<boolean>(false);
  const [isCreatingCredential, setIsCreatingCredential] = React.useState<boolean>(false);

  const secretKeyInputRegister = register('secret_key');

  const onSecretKeyChange = React.useCallback(
    event => {
      secretKeyInputRegister.onChange(event);

      setSecretKeyValue(event.target.value);
    },
    [secretKeyInputRegister],
  );

  const handleCopyURL = React.useCallback(() => {
    navigator.clipboard.writeText(webhookURL);
    setIsURLCopied(true);
    toast.success('URL copiada com sucesso.');
  }, [toast, webhookURL]);

  const onSubmit = React.useCallback(
    async formData => {
      setIsCreatingCredential(true);

      try {
        await paytService.createCredential({
          storeAliasId,
          data: { ...formData },
        });

        toast.success('Credencial criada com sucesso.');

        setIsCreatingCredential(false);

        if (selectedMarketings.length) {
          history.push(`/onboarding/${storeAliasId}/info-product/integrations/marketing`);
        } else {
          await handleStoreBonus();
        }
      } catch (error: any) {
        toast.error(getTranslatedIntegrationsError(error));
        setIsCreatingCredential(false);
      }
    },
    [storeAliasId, toast, handleStoreBonus, history, selectedMarketings],
  );

  const generateWebhookURL = React.useCallback(async () => {
    setIsGettingWebhookURL(true);

    try {
      const { data } = await paytService.generateWebhookURL({ storeAliasId });

      setWebhookURL(data?.webhook_url);
      setIsGettingWebhookURL(false);
    } catch (error: any) {
      setIsGettingWebhookURL(false);
      toast.error(error?.response?.data?.message);
    }
  }, [toast, storeAliasId]);

  React.useEffect(() => {
    generateWebhookURL();
  }, [generateWebhookURL]);

  if (isGettingWebhookURL) {
    return (
      <>
        <SkeletonLoading />
        <NextStep>Próximo passo</NextStep>
      </>
    );
  }

  return (
    <S.Wrapper>
      <S.FormWrapper>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Header>
            <S.Title>Integre sua plataforma de infoproduto</S.Title>
          </S.Header>

          <S.IntegrationTitle>
            <PaytIcon size={36} />

            <S.IntegrationTitleText fontWeight={EHeadingWeight.REGULAR}>
              Integração com Payt
            </S.IntegrationTitleText>
          </S.IntegrationTitle>

          <S.Body>
            <S.InputGroup>
              <S.Label>URL do webhook</S.Label>

              <S.WebhookURLWrapper onClick={handleCopyURL} type="button">
                <S.WebhookURL weight={ETextWeight.MEDIUM}>{webhookURL}</S.WebhookURL>

                <S.IconWrapper>
                  <Copy size={18} color={theme.colors.gray[1]} outline={!isURLCopied} />
                </S.IconWrapper>
              </S.WebhookURLWrapper>
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Chave Única</S.Label>

              <S.Input
                {...secretKeyInputRegister}
                type="text"
                value={secretKeyValue}
                placeholder="Chave Única da Payt"
                onChange={onSecretKeyChange}
                isError={errors.secret_key}
              />
              {errors.secret_key && <Text isErrorFeedback>{errors.secret_key.message}</Text>}
            </S.InputGroup>
          </S.Body>

          <NextStep
            type="submit"
            isLoading={isCreatingCredential || isLoadingTrial}
            disabled={!secretKeyValue || isCreatingCredential || isLoadingTrial}
          >
            Próximo passo
          </NextStep>
        </S.Form>
      </S.FormWrapper>

      <S.TutorialWrapper>
        <S.TutorialImage
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/onboarding/images/content/onboarding/onboarding-integration-tutorial.svg"
          alt="Profitfy.me Integration Tutorial"
        />
        <S.TutotialTitle weight={ETextWeight.BOLD}>Tutorial de integração</S.TutotialTitle>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          1 - Copie a URL no campo ao lado.
        </S.TutotialStep>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          2 -{' '}
          <S.Link href="https://app.payt.com.br/admin/postbacks/create" target="_blank">
            Clique aqui e vá para a página de Postback da Payt.
          </S.Link>
        </S.TutotialStep>

        <S.StepsWrapper>
          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              Preencha o campo &quot;Nome&quot; como desejar.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              No campo &quot;Produto&quot;, selecione &quot;Todos os Produtos.&quot;.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              No campo &quot;Tipo&quot;, selecione &quot;PayT V1&quot;.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              No campo &quot;Url&quot; da PayT, cole a Url da Profitfy gerada ao lado.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              No campo &quot;Eventos&quot; selecione todas as opções.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              Para finalizar, no canto superior direito, copie a &quot;Chave Única&quot; da PayT.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              Na Profitfy, cole no campo &quot;Chave Única&quot; ao lado.
            </S.StepText>
          </S.StepWrapper>
        </S.StepsWrapper>
      </S.TutorialWrapper>
    </S.Wrapper>
  );
};

export default Payt;
