import React from 'react';

import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import { IFeedProvider } from '@domain/interfaces/dashboard/Feed/IFeed';
import { IOrder } from '@domain/interfaces/common/orders/IOrders';
import { EGroup } from '@domain/enums/dashboard/customValuesCategories/EGroup';
import { ECurrency } from '@domain/enums/common/ECurrency';

import { useDate } from '@helpers/hooks/useDate';

import customValueCategoriesService from '@services/pages/dashboard/customValuesCategories/customValueCategories';
import customValueService from '@services/pages/dashboard/customValue/customValue';

const FeedContext = React.createContext<IFeedProvider | null>(null);

export const FeedProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();
  const { format, utcToZonedTime } = useDate();

  const {
    customValuesCategories,
    isLoading: isLoadingCustomValueCategories,
    error: customValueCategoriesError,
    mutate: mutateCustomValueCategories,
  } = customValueCategoriesService.getCustomValuesCategories({ storeAliasId });

  const createChargebackCategory = React.useCallback(async () => {
    const { data } = await customValueCategoriesService.createCustomValueCategory({
      storeAliasId,
      data: {
        group: EGroup.FINANCIAL_EXPENSES,
        hex_color: '#E40707',
        name: 'Chargeback',
      },
    });

    mutateCustomValueCategories();

    return data.custom_value_category;
  }, [storeAliasId, mutateCustomValueCategories]);

  const createCustomValue = React.useCallback(
    async (
      customValuesCategoryAliasId: string,
      order: IOrder,
      refundedAmount: number,
      date: Date,
    ) => {
      await customValueService.createCustomValue({
        storeAliasId,
        customValuesCategoryAliasId,
        data: {
          amount: refundedAmount,
          currency: ECurrency.BRL,
          is_spend: true,
          is_active: true,
          period: 'ONCE',
          start_date: format(utcToZonedTime(date), 'yyyy-MM-dd'),
          description: `Pedido ${order.name} - Chargeback`,
          send_notification: false,
        },
      });
    },
    [format, storeAliasId, utcToZonedTime],
  );

  return (
    <FeedContext.Provider
      value={{
        customValueCategoriesError,
        customValuesCategories,
        isLoadingCustomValueCategories,
        mutateCustomValueCategories,
        createChargebackCategory,
        createCustomValue,
      }}
    >
      {children}
    </FeedContext.Provider>
  );
};

export const useFeed = (): IFeedProvider => {
  const context = React.useContext(FeedContext);

  if (!context) {
    throw new Error('useFeed must be used within FeedContext');
  }

  return context;
};
