import styled, { css } from 'styled-components/macro';

import { IShopifyInputWrapperProps } from '@domain/interfaces/onboarding/dropshipping/platforms/IShopify';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import LabelBase from '@components/common/core/DataDisplay/Label';
import FormBase from '@components/common/core/Inputs/Form';
import ImageBase from '@components/common/core/DataDisplay/Image';
import TextBase from '@components/common/core/DataDisplay/Text';

export const IntegrationArticleImage = styled(ImageBase)``;

export const TutorialLink = styled.a`
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.green.default};
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    text-decoration: underline;
    filter: brightness(0.9);
  }
`;

export const ArticleLink = styled.a`
  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Form = styled(FormBase)`
  width: auto;

  @media only screen and (max-width: 920px) {
    margin: 0 auto;
  }
`;

export const AppendContent = styled.div`
  display: block;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray[3]};
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0px 16px;
  background-color: ${({ theme }) => theme.colors.background.input};
  border-radius: 0 5px 5px 0;
  transition: all 0.3s;
`;

export const InputWrapper = styled.div<IShopifyInputWrapperProps>`
  display: flex;
  max-width: 408px;
  border: 1px solid transparent;
  border-radius: 5px;

  ${({ isError, theme }) =>
    Boolean(isError) &&
    css`
      border-color: ${theme.colors.danger.default};

      & > div {
        background-color: ${theme.colors.black.default};

        & > div {
          background-color: ${theme.colors.black.default};
        }
      }
    `}

  ${({ isError, value, theme }) =>
    Boolean(!isError) &&
    value &&
    css`
      border-color: ${theme.colors.success.default};

      & > div {
        background-color: ${theme.colors.black.default};

        & > div {
          background-color: ${theme.colors.black.default};
        }
      }
    `}
`;

export const TextField = styled(TextFieldBase)`
  width: 100%;
  max-width: 408px;

  &:first-child {
    & > div {
      border-radius: 5px 0 0 5px;
    }
  }
`;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const IntegrationTitleText = styled(HeadingBase)``;

export const Body = styled.div``;

export const IntegrationTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  gap: 16px;
`;

export const Title = styled(HeadingBase)`
  font-size: 1.75rem;
  line-height: 120%;
  margin-bottom: 36px;
`;

export const Header = styled.div``;

export const TutorialContent = styled(TextBase)`
  /* display: inline-block; */
  font-size: 0.875rem;
  line-height: 150%;
  margin-bottom: 16px;

  & > strong {
    color: ${({ theme }) => theme.colors.green.default};
    font-weight: 400;
  }
`;

export const TutorialTitle = styled(HeadingBase)`
  font-size: 1rem;
  line-height: 120%;
  display: inline-block;
  margin-bottom: 8px;
`;

export const TutorialImage = styled(ImageBase)`
  max-width: 140px;
  margin-bottom: 24px;
  display: block;
`;

export const TutorialWrapper = styled.div`
  max-width: 416px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray[4]};
  border-radius: 7px;
  padding: 28px 32px;
  box-sizing: border-box;
  height: fit-content;
`;

export const FormWrapper = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  padding: 142px 32px;
  max-width: calc(1008px + 64px);
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  gap: 28px;

  @media only screen and (max-width: 1292px) {
    flex-direction: column;
    max-width: calc(620px + 64px);
  }
`;

export const WebhookURLWrapper = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background[2]};
  border-radius: 5px;
  height: 42px;
  width: 408px;
  padding: 10px 12px;
  gap: 10px;
  border: unset;
  cursor: pointer;
  margin-bottom: 16px;
`;

export const WebhookURL = styled(TextBase)`
  max-width: 434px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const IconWrapper = styled.div`
  min-height: 18px;
  min-width: 18px;
`;

export const TutorialWebhookText = styled(TextBase)`
  margin-top: 10px;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 150%;
  margin-bottom: 16px;

  & > strong {
    color: ${({ theme }) => theme.colors.green.default};
    font-weight: 400;
  }
`;
