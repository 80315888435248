import React from 'react';
import { Facebook } from '@profitfy/pakkun-icons';
import { useHistory } from 'react-router-dom';

import errorImage from '@assets/components/PageError/error.svg';
import { useProductDetails } from '@helpers/hooks/pages/dashboard/productAnalytics/useProductDetails';
import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const NoProviderIntegration: React.FC = () => {
  const { funnelChartProvider, handleFunnelChartProvider } = useProductDetails();
  const history = useHistory();
  const { analytics, selectedStore, user } = useConfig();

  const redirectToIntegration = React.useCallback(() => {
    analytics?.track(
      'Product Analytic Details No Campaigns Clicked',
      {
        source: 'funnel',
        email: user?.email,
      },
      { context: { groupId: selectedStore?.alias_id } },
    );

    history.push('campaigns');
  }, [history, analytics, selectedStore, user]);

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title fontWeight={EHeadingWeight.REGULAR}>Performance de Funil</S.Title>

        <S.ProvidersButtonWrapper>
          <S.ProviderButton
            onClick={() => handleFunnelChartProvider(EAdSenseProvider.FACEBOOK)}
            isSelected={funnelChartProvider === EAdSenseProvider.FACEBOOK}
          >
            <Facebook size={20} />
          </S.ProviderButton>
          {/* <S.ProviderButton
            onClick={() => handleFunnelChartProvider(EAdSenseProvider.GOOGLE)}
            isSelected={funnelChartProvider === EAdSenseProvider.GOOGLE}
          >
            <Google size={20} />
          </S.ProviderButton> */}
        </S.ProvidersButtonWrapper>
      </S.Header>

      <S.Content>
        <S.ErrorImage src={errorImage} alt="Profitfy.me No Integration" />
        <S.Description>
          Você precisa ter contas de anúncios ativas para visualizar o gráfico.
        </S.Description>
        <S.ActivateButton onClick={redirectToIntegration}>Clique aqui para ativar</S.ActivateButton>
      </S.Content>
    </S.Wrapper>
  );
};

export default NoProviderIntegration;
