import React from 'react';

import { SuggestionsProvider } from '@helpers/hooks/pages/dashboard/ideasChannel/useSuggestions';

import AdminPanel from '@pages/Dashboard/AdminPanel';
import Announcement from '@pages/Dashboard/AdminPanel/Announcement';
import AdminCheckoutGenerator from '@pages/Dashboard/AdminPanel/Checkout';
import Coupon from '@pages/Dashboard/AdminPanel/Coupon';
import AdminIdeaDetails from '@pages/Dashboard/AdminPanel/IdeasChannel/IdeaDetails';
import AdminOverviewIdeas from '@pages/Dashboard/AdminPanel/IdeasChannel/OverviewIdeas';
import StoreMigration from '@pages/Dashboard/AdminPanel/StoreMigration';
import StorePlan from '@pages/Dashboard/AdminPanel/StorePlan';
import Trial from '@pages/Dashboard/AdminPanel/Trial';
import Wallet from '@pages/Dashboard/AdminPanel/Wallet';

import Route from '@components/routes/Route';
import PushNotification from '@pages/Dashboard/AdminPanel/PushNotification';
import DeleteCredential from '@pages/Dashboard/AdminPanel/DeleteCredential';
import ChangeCredential from '@pages/Dashboard/AdminPanel/ChangeCredential';

const Admin: React.FC = () => {
  return (
    <>
      <Route isPrivate path="/:storeAliasId/dashboard/admin-panel" component={AdminPanel} exact />
      <Route isPrivate path="/:storeAliasId/dashboard/admin/coupons" component={Coupon} exact />

      <SuggestionsProvider>
        <Route
          isPrivate
          path="/:storeAliasId/dashboard/admin/ideas"
          component={AdminOverviewIdeas}
          exact
        />
        <Route
          isPrivate
          path="/:storeAliasId/dashboard/admin/ideas/details/:ideaAliasId"
          component={AdminIdeaDetails}
          exact
        />
      </SuggestionsProvider>

      <Route
        isPrivate
        path="/:storeAliasId/dashboard/admin/checkout-generator"
        component={AdminCheckoutGenerator}
        exact
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/admin/store-plan"
        component={StorePlan}
        exact
      />

      <Route
        isPrivate
        path="/:storeAliasId/dashboard/admin/push-notification"
        component={PushNotification}
        exact
      />

      <Route
        isPrivate
        path="/:storeAliasId/dashboard/admin/delete-credential"
        component={DeleteCredential}
      />

      <Route
        isPrivate
        path="/:storeAliasId/dashboard/admin/change-credential"
        component={ChangeCredential}
        exact
      />

      <Route
        isPrivate
        path="/:storeAliasId/dashboard/admin/announcement"
        component={Announcement}
        exact
      />

      <Route
        isPrivate
        path="/:storeAliasId/dashboard/admin/store-migration"
        component={StoreMigration}
        exact
      />

      <Route isPrivate path="/:storeAliasId/dashboard/admin/wallet" component={Wallet} exact />

      <Route isPrivate path="/:storeAliasId/dashboard/admin/trial" component={Trial} exact />
    </>
  );
};

export default React.memo(Admin);
