import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';
import TextBase from '@components/common/core/DataDisplay/Text';

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 625px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 42px;
`;

export const Heading = styled(HeadingBase)`
  margin-bottom: 24px;
`;

export const Text = styled(TextBase)``;

export const Button = styled(ButtonBase)``;

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 12px;
`;

export const Functionality = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[4]};

  &:last-child {
    border-bottom: unset;
  }
`;
