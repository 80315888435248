import React from 'react';

import { useWhatsappInstance } from '@helpers/hooks/pages/dashboard/whatsappInstance/useWhatsappInstance';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import PageError from '@components/common/core/Utils/PageError';

import Instances from '../Instances';
import Welcome from '../Welcome';
import NewInstance from '../NewInstance';
import SkeletonLoading from '../SkeletonLoading';

const Wrapper: React.FC = () => {
  const {
    whatsappCredentials,
    isLoadingWhatsappCredential,
    isValidatingWhatsappCredential,
    whatsappCredentialError,
    mutateWhatsappCredential,
  } = useWhatsappInstance();

  const { store } = useStoreConfig();

  const isOnboardingFinished = store?.is_whatsapp_onboarding_finished;

  if (!isOnboardingFinished) return <Welcome />;

  const isLoading = isLoadingWhatsappCredential || isValidatingWhatsappCredential;

  if (isLoading) {
    return <SkeletonLoading />;
  }

  if (whatsappCredentialError) {
    return <PageError mutate={mutateWhatsappCredential} />;
  }

  return whatsappCredentials?.length ? <Instances /> : <NewInstance />;
};

export default Wrapper;
