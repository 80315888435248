import React from 'react';

import { Pen } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { ETextWeight } from '@domain/enums/components/EText';
import { EHeadAlign } from '@domain/enums/components/ETable';
import { IRowProps } from '@domain/interfaces/dashboard/ProductCostDetails/ITable';
import { numberFormatter, currencyFormatter, currencyToNumber } from '@helpers/masks';
import { useProductCostDetails } from '@helpers/hooks/pages/dashboard/productCostDetails/productCostDetails';

import Table from '@components/common/core/Utils/Table';
import Popover from '@components/common/core/Utils/Popover';

import * as S from './styles';

const Row: React.FC<IRowProps> = ({ variant }) => {
  const {
    editedVariants,
    updateEditedVariants,
    costToApplyAll,
    currencyToApplyAll,
    handleVariant,
  } = useProductCostDetails();

  const theme: any = useTheme();

  const [variantCost, setVariantCost] = React.useState<string>(
    numberFormatter(variant.default_cost, 2),
  );
  const [currency, setCurrency] = React.useState<string>(variant.currency);
  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const [isImageError, setIsImageError] = React.useState<boolean>(false);

  const handleIsHovering = React.useCallback(status => setIsHovering(status), []);

  const getVariantsWithoutCurrentOne = React.useCallback(() => {
    const parsedVariants = editedVariants.filter(editedVariant => editedVariant.id !== variant.id);

    return parsedVariants;
  }, [editedVariants, variant]);

  const handleCostEdit = React.useCallback(
    event => {
      const newValue = currencyFormatter(event.target.value);

      setVariantCost(newValue);

      const parsedVariants = getVariantsWithoutCurrentOne();

      const currentVariantEdited = editedVariants.find(
        editedVariant => editedVariant.id === variant.id,
      );

      if (newValue && !currentVariantEdited) {
        const newEditedVariant = { ...variant, default_cost: currencyToNumber(newValue), currency };

        parsedVariants.push(newEditedVariant);
      }

      if (newValue && currentVariantEdited) {
        const newEditedVariant = {
          ...currentVariantEdited,
          default_cost: currencyToNumber(newValue),
          currency,
        };

        parsedVariants.push(newEditedVariant);
      }

      updateEditedVariants(parsedVariants);
    },
    [getVariantsWithoutCurrentOne, updateEditedVariants, variant, editedVariants, currency],
  );

  const handleCurrencyEdit = React.useCallback(
    event => {
      const parsedVariants = getVariantsWithoutCurrentOne();

      const currentVariantEdited = editedVariants.find(
        editedVariant => editedVariant.id === variant.id,
      );

      if (currentVariantEdited) {
        parsedVariants.push({ ...currentVariantEdited, currency: event.target.value });
      } else {
        parsedVariants.push({ ...variant, currency: event.target.value });
      }

      setCurrency(event.target.value);

      updateEditedVariants(parsedVariants);
    },
    [getVariantsWithoutCurrentOne, updateEditedVariants, variant, editedVariants],
  );

  React.useEffect(() => {
    const foundEditedVariant = editedVariants.find(
      editedVariant => editedVariant.alias_id === variant.alias_id,
    );

    if (!foundEditedVariant && costToApplyAll) {
      setVariantCost(numberFormatter(costToApplyAll, 2));
    }

    if (!foundEditedVariant && currencyToApplyAll) {
      setCurrency(currencyToApplyAll);
    }

    if (foundEditedVariant) {
      setVariantCost(numberFormatter(foundEditedVariant.default_cost, 2));
      setCurrency(foundEditedVariant.currency);
    }
  }, [editedVariants, variant, costToApplyAll, currencyToApplyAll]);

  const hasProductImage = variant?.image_url && !isImageError;

  return (
    <Table.Row
      onMouseEnter={() => handleIsHovering(true)}
      onMouseLeave={() => handleIsHovering(false)}
      borderBottom
    >
      <Table.Data>
        <S.ProductWrapper>
          {!hasProductImage && <S.NoImage />}

          {hasProductImage && (
            <S.Image
              src={variant.image_url}
              alt="Profitfy.me Variante"
              onError={() => setIsImageError(true)}
            />
          )}

          <S.Text
            weight={ETextWeight.MEDIUM}
            data-tip
            data-for={`variant-name-${variant.id}`}
            data-event="mouseenter mouseleave"
          >
            {variant?.name}
          </S.Text>

          <Popover width={128} place="top" id={`variant-name-${variant.id}`}>
            <S.VariantTooltipName>{variant?.name}</S.VariantTooltipName>
          </Popover>
        </S.ProductWrapper>
      </Table.Data>

      <Table.Data align={EHeadAlign.RIGHT}>
        <S.Text weight={ETextWeight.MEDIUM}>{`R$ ${numberFormatter(variant?.price, 2)}`}</S.Text>
      </Table.Data>

      <Table.Data>
        <S.CurrencySelect name="currency" onChange={handleCurrencyEdit} value={currency}>
          <S.Option value="USD">USD</S.Option>
          <S.Option value="BRL">BRL</S.Option>
        </S.CurrencySelect>
      </Table.Data>

      <Table.Data>
        <S.Input
          prependText={currency === 'USD' ? '$' : 'R$'}
          type="text"
          onChange={handleCostEdit}
          value={variantCost}
          noFeedbackError
        />
      </Table.Data>

      <Table.Data align={EHeadAlign.RIGHT}>
        <>
          <S.ActionsButton
            isHovering={isHovering}
            data-tip
            data-for={`variant-actions-${variant.id}`}
            data-event="click"
          >
            <S.Dot />
            <S.Dot />
            <S.Dot />

            <S.AccessibleActionsButtonName>Ações</S.AccessibleActionsButtonName>
          </S.ActionsButton>

          <Popover width={128} id={`variant-actions-${variant.id}`} place="bottom">
            <S.ActionsWrapper>
              <S.ActionButton onClick={() => handleVariant(variant)}>
                <Pen outline size={16} color={theme.colors.font} />
                Editar
              </S.ActionButton>
            </S.ActionsWrapper>
          </Popover>
        </>
      </Table.Data>
    </Table.Row>
  );
};

export default Row;
