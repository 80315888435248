import React from 'react';

import { useHistory } from 'react-router-dom';
import { Lamp } from '@profitfy/pakkun-icons';
import { EButtonVariant } from '@domain/enums/components/EButton';

import * as S from './styles';

const NewIdeaAndSearch: React.FC = () => {
  const history = useHistory();

  const handleNewIdea = React.useCallback(() => {
    history.push('ideas/new-idea');
  }, [history]);

  return (
    <S.NewIdeaAndSearchWrapper>
      <S.NewIdeaButton variant={EButtonVariant.SECONDARY} onClick={handleNewIdea}>
        <Lamp size={16} outline />
        Nova ideia
      </S.NewIdeaButton>
    </S.NewIdeaAndSearchWrapper>
  );
};

export default NewIdeaAndSearch;
