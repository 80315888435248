import React from 'react';

import {
  ABANDONED_CART_TRIGGER_VARIABLES,
  BOLETO_TRIGGER_VARIABLES,
  PIX_TRIGGER_VARIABLES,
  REJECTED_PAYMENT_TRIGGER_VARIABLES,
  SIMPLE_SHIPMENT_TRIGGER_VARIABLES,
} from '@constants/pages/dashboard/automations/messages/triggers';
import {
  EConfigOption,
  ECreateMessageStep,
  EMessageContentType,
  EMessageType,
  ESelectedCategoryOption,
} from '@domain/enums/dashboard/automations/EMessage';
import { IMessage, IMessageSettingsProvider } from '@domain/interfaces/common/automations/IMessage';
import { ISavedContent } from '@domain/interfaces/common/automations/ISavedContent';

const MessageSettingsContext = React.createContext<IMessageSettingsProvider | null>(null);

export const MessageSettingsProvider: React.FC = ({ children }) => {
  const [messageToBeSearched, setMessageToBeSearched] = React.useState<string>('');
  const [
    selectedCategoryOption,
    setSelectedCategoryOption,
  ] = React.useState<ESelectedCategoryOption>(ESelectedCategoryOption.ALL_MESSAGES);
  const [contents, setContents] = React.useState<Array<ISavedContent>>([]);
  const [contentsToUpdate, setContentsToUpdate] = React.useState<Array<ISavedContent>>([]);
  const [selectedMessageTrigger, setSelectedMessageTrigger] = React.useState<
    EMessageType | undefined
  >(undefined);
  const [isNewMessageModalOpen, setIsNewMessageModalOpen] = React.useState<boolean>(false);
  const [selectedMessageToUpdate, setSelectedMessageToUpdate] = React.useState<
    IMessage | undefined
  >(undefined);
  const [isUpdateMessageModalOpen, setIsUpdateMessageModalOpen] = React.useState<boolean>(false);
  const [isTemplateMessage, setIsTemplateMessage] = React.useState<boolean>(false);
  const [messageName, setMessageName] = React.useState<string>('');
  const [arrayToChangePosition, setArrayToChangePosition] = React.useState<Array<ISavedContent>>(
    [],
  );
  const [createNewMessageStep, setCreateNewMessageStep] = React.useState<ECreateMessageStep>(
    ECreateMessageStep.SELECT_MESSAGE_TEMPLATE,
  );
  const [shouldCloseModal, setShouldCloseModal] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState<string>('');

  const resetMessageSettings = React.useCallback(() => {
    setMessageToBeSearched('');
    setContents([]);
    setContentsToUpdate([]);
    setSelectedMessageTrigger(undefined);
    setIsNewMessageModalOpen(false);
    setIsUpdateMessageModalOpen(false);
    setSelectedMessageToUpdate(undefined);
    setCreateNewMessageStep(ECreateMessageStep.SELECT_MESSAGE_TEMPLATE);
  }, []);

  const handleSearchValue = React.useCallback(value => setSearchValue(value), []);

  const handleNewMessageModalOpen = React.useCallback(state => setIsNewMessageModalOpen(state), []);

  const handleUpdateMessageModalOpen = React.useCallback(
    state => setIsUpdateMessageModalOpen(state),
    [],
  );

  const handleShouldCloseModal = React.useCallback(state => setShouldCloseModal(state), []);

  const handleMessageToBeSearched = React.useCallback(
    newValue => setMessageToBeSearched(newValue),
    [],
  );

  const handleSelectedCategoryOption = React.useCallback(
    newCategoryOption => setSelectedCategoryOption(newCategoryOption),
    [],
  );

  const handleContents = React.useCallback(newContents => setContents(newContents), []);

  const handleContentsToUpdate = React.useCallback(
    newContents => setContentsToUpdate(newContents),
    [],
  );

  const handleSelectedMessageTrigger = React.useCallback(
    newMessageTrigger => setSelectedMessageTrigger(newMessageTrigger),
    [],
  );

  const handleSelectedMessageToUpdate = React.useCallback(
    messageToUpdate => setSelectedMessageToUpdate(messageToUpdate),
    [],
  );

  const handleMessageName = React.useCallback(newName => setMessageName(newName), []);

  const handleArrayToChangePosition = React.useCallback(
    newArray => setArrayToChangePosition(newArray),
    [],
  );

  const handleTemplateMessage = React.useCallback(state => setIsTemplateMessage(state), []);

  const handleCreateNewMessageStep = React.useCallback(step => setCreateNewMessageStep(step), []);

  const getTriggerVariables = React.useCallback(() => {
    if (selectedMessageTrigger === EMessageType.ABANDONED_CART)
      return ABANDONED_CART_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.BOLETO) return BOLETO_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.PIX) return PIX_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.SIMPLE_SHIPMENT)
      return SIMPLE_SHIPMENT_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.REJECTED_PAYMENT)
      return REJECTED_PAYMENT_TRIGGER_VARIABLES;

    return [];
  }, [selectedMessageTrigger]);

  React.useEffect(() => {
    if (!selectedMessageToUpdate) return;

    const { message_contents } = selectedMessageToUpdate;

    const newData: Array<ISavedContent> = [];

    message_contents.forEach(content => {
      if (content.type === EMessageContentType.TEXT) {
        const newValue = content.content;

        let modifiedText = newValue;

        const triggerVariables = getTriggerVariables();

        triggerVariables.forEach(variable => {
          modifiedText = modifiedText.replace(variable.data, variable.nameToDisplay);
        });

        newData.push({
          id: content.alias_id,
          type: EConfigOption.TEXT,
          content: modifiedText,
          position: content.position,
        });
      }

      if (content.type === EMessageContentType.IMAGE) {
        newData.push({
          id: content.alias_id,
          type: EConfigOption.IMAGE,
          content: content.content_public_url,
          position: content.position,
        });
      }

      if (content.type === EMessageContentType.VIDEO) {
        newData.push({
          id: content.alias_id || content.id,
          type: EConfigOption.VIDEO,
          content: content.content_public_url,
          position: content.position,
        });
      }

      if (content.type === EMessageContentType.PRODUCT_IMAGE) {
        newData.push({
          id: content.alias_id || content.id,
          type: EConfigOption.PRODUCT_IMAGE,
          content: content.content_public_url,
          position: content.position,
        });
      }

      if (content.type === EMessageContentType.PDF) {
        newData.push({
          id: content.alias_id || content.id,
          type: EConfigOption.PDF,
          content: content.content_public_url,
          position: content.position,
        });
      }

      if (content.type === EMessageContentType.AUDIO) {
        newData.push({
          id: content.alias_id,
          type: EConfigOption.AUDIO,
          content: {},
          position: content.position,
        });
      }
    });

    setContentsToUpdate(newData);
    setSelectedMessageTrigger(selectedMessageToUpdate.type);
  }, [getTriggerVariables, selectedMessageToUpdate]);

  return (
    <MessageSettingsContext.Provider
      value={{
        messageToBeSearched,
        selectedCategoryOption,
        contents,
        contentsToUpdate,
        selectedMessageTrigger,
        selectedMessageToUpdate,
        messageName,
        arrayToChangePosition,
        createNewMessageStep,
        isTemplateMessage,
        shouldCloseModal,
        isNewMessageModalOpen,
        isUpdateMessageModalOpen,
        searchValue,
        resetMessageSettings,
        handleContents,
        handleContentsToUpdate,
        handleMessageToBeSearched,
        handleSelectedCategoryOption,
        handleSelectedMessageTrigger,
        handleSelectedMessageToUpdate,
        handleMessageName,
        handleArrayToChangePosition,
        handleCreateNewMessageStep,
        handleTemplateMessage,
        handleShouldCloseModal,
        handleNewMessageModalOpen,
        handleUpdateMessageModalOpen,
        handleSearchValue,
      }}
    >
      {children}
    </MessageSettingsContext.Provider>
  );
};

export const useMessageSettings = (): IMessageSettingsProvider => {
  const context = React.useContext(MessageSettingsContext);

  if (!context) throw new Error('useMessageSettings must be used within MessageSettingsProvider');

  return context;
};
