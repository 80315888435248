import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 566px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background[2]};
  margin: 0;
  padding: 0;
  display: flex;
  z-index: 10;
`;
