import React from 'react';

import Skeleton from 'react-loading-skeleton';

import { EHeadAlign } from '@domain/enums/components/ETable';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  const rows = Array.from(Array(10).keys());

  return (
    <S.AutomationsTable>
      <S.Table.Header>
        <S.Table.Row>
          <S.Table.Head align={EHeadAlign.CENTER}>Status</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Nome</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Envios (7d)</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Execuções (7d)</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Recuperados (7d)</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Valor Recuperado (7d)</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Gatilho</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Ações</S.Table.Head>
        </S.Table.Row>
      </S.Table.Header>

      <S.Table.Body>
        {rows.map(row => {
          return (
            <S.Table.Row key={row}>
              <S.Table.Data align={EHeadAlign.CENTER}>
                <Skeleton width={50} height={20} />
              </S.Table.Data>

              <S.Table.Data align={EHeadAlign.CENTER}>
                <Skeleton width={50} height={20} />
              </S.Table.Data>

              <S.Table.Data align={EHeadAlign.RIGHT}>
                <Skeleton width={50} height={20} />
              </S.Table.Data>

              <S.Table.Data align={EHeadAlign.RIGHT}>
                <Skeleton width={50} height={20} />
              </S.Table.Data>

              <S.Table.Data align={EHeadAlign.RIGHT}>
                <Skeleton width={50} height={20} />
              </S.Table.Data>

              <S.Table.Data align={EHeadAlign.CENTER}>
                <Skeleton width={50} height={20} />
              </S.Table.Data>

              <S.Table.Data align={EHeadAlign.CENTER}>
                <Skeleton width={50} height={20} />
              </S.Table.Data>
            </S.Table.Row>
          );
        })}
      </S.Table.Body>
    </S.AutomationsTable>
  );
};

export default SkeletonLoading;
