import React from 'react';

import { useProductCostDetails } from '@helpers/hooks/pages/dashboard/productCostDetails/productCostDetails';
import { currencyFormatter, currencyToNumber } from '@helpers/masks';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';

import * as S from './styles';

const Details: React.FC = () => {
  const { product, handleCostToApplyAll, handleCurrencyToApplyAll } = useProductCostDetails();

  const [applyToAllValue, setApplyToAllValue] = React.useState<string>('');
  const [currency, setCurrency] = React.useState<string>('BRL');
  const [isImageError, setIsImageError] = React.useState<boolean>(false);

  const onSelectCurrencyChange = React.useCallback(event => {
    setCurrency(event.target.value);
  }, []);

  const onApplyToAllValueChange = React.useCallback(event => {
    event.target.value = currencyFormatter(event.target.value);

    setApplyToAllValue(event.target.value);
  }, []);

  const handleOnApplyToAllClick = React.useCallback(() => {
    const parsedValue = currencyToNumber(applyToAllValue);

    handleCostToApplyAll(parsedValue);
  }, [applyToAllValue, handleCostToApplyAll]);

  const handleOnApplyCurrencyToAllClick = React.useCallback(() => {
    handleCurrencyToApplyAll(currency);
  }, [currency, handleCurrencyToApplyAll]);

  const hasProductImage = product?.image_url && !isImageError;

  return (
    <S.DetailsWrapper>
      <S.HeadingWrapper>
        {!hasProductImage && <S.NoImage />}

        {hasProductImage && (
          <S.Image
            src={product?.image_url}
            alt="Profitfy.me Custo dos Produtos"
            onError={() => setIsImageError(true)}
          />
        )}

        <S.ProductName fontWeight={EHeadingWeight.REGULAR} type={EHeadingSize.H5}>
          {product?.name}
        </S.ProductName>
      </S.HeadingWrapper>

      <S.InputsWrapper>
        <S.InputGroup>
          <S.Label>Insira o custo</S.Label>

          <S.Input
            button="Aplicar a todas"
            type="text"
            onChange={onApplyToAllValueChange}
            onClick={handleOnApplyToAllClick}
          />
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Insira a moeda</S.Label>

          <S.ApplyToAllCurrencyWrapper>
            <S.Select
              name="apply-to-all-currency"
              defaultValue={currency}
              onChange={onSelectCurrencyChange}
            >
              <S.Option value="BRL">BRL</S.Option>
              <S.Option value="USD">USD</S.Option>
            </S.Select>

            <S.ApplyToAllButton onClick={handleOnApplyCurrencyToAllClick}>
              Aplicar a todas
            </S.ApplyToAllButton>
          </S.ApplyToAllCurrencyWrapper>
        </S.InputGroup>
      </S.InputsWrapper>
    </S.DetailsWrapper>
  );
};

export default Details;
