const mockedData = [
  {
    id: '8aab3967-ca2a-44ca-9694-62765340888e',
    alias_id: 'ad-sense-campaign-s97hpb920gqyef9bso7lcm11ys9t',
    name: 'Adesivo - [ABO] - [1-10-1] - [ABERTO] - [C02A]',
    provider: 'FACEBOOK',
    provider_id: '23850915012490098',
    status: 'ENABLED',
    daily_budget: 0,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 1.3214154200230153,
      },
      cpm: {
        amount: 24.047369743675663,
      },
      ctr: {
        percentage: 1.8198190651700454,
      },
      roas: {
        amount: 3.7211724000000004,
      },
      spend: {
        amount: 1148.3100000000002,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 47752,
      },
    },
    financial_report: {
      boleto: {
        amount: 127.9,
        cpa_amount: 1148.3100000000002,
      },
      card: {
        amount: 1427.11,
        cpa_amount: 104.3918181818182,
      },
      cogs: {
        amount: 1169.96331901064,
      },
      net_profit: {
        amount: 439.0166809893599,
      },
      net_revenue: {
        amount: 2757.29,
      },
      orders: {
        paid_quantity: 20,
        pending_quantity: 2,
      },
      pix: {
        amount: 1202.28,
        cpa_amount: 143.53875000000002,
      },
      pix_conversion: {
        conversion: 80,
        paid: {
          amount: 1202.28,
          quantity: 8,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 33.333333333333336,
        paid: {
          amount: 127.9,
          quantity: 1,
        },
        pending: {
          amount: 255.8,
          quantity: 2,
        },
      },
      roi: {
        amount: 0.18937227003790788,
      },
      cpa: {
        amount: 57.41550000000001,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 2747.138808058583,
      },
      cogs: {
        amount: 1072.2472351096499,
      },
      cpa: {
        amount: 57.63683718546487,
      },
      net_profit: {
        amount: 184.99333918539946,
      },
      roi: {
        amount: 0.23713938313458102,
      },
    },
  },
  {
    id: '0ff9d9ab-c215-412d-862f-92720aed51e7',
    alias_id: 'ad-sense-campaign-jsliuv9q79x52n8j5gk359y168bg',
    name: 'Adesivo - [ABO] - [1-10-1] - [ABERTO] - [C02A] — Cópia',
    provider: 'FACEBOOK',
    provider_id: '23851026477140098',
    status: 'ENABLED',
    daily_budget: 0,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 1.4394428969359332,
      },
      cpm: {
        amount: 26.237465410880656,
      },
      ctr: {
        percentage: 1.822751389911401,
      },
      roas: {
        amount: 3.6914768000000002,
      },
      spend: {
        amount: 1033.52,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 39391,
      },
    },
    financial_report: {
      boleto: {
        amount: 127.9,
        cpa_amount: 1033.52,
      },
      card: {
        amount: 1383.18,
        cpa_amount: 86.12666666666667,
      },
      cogs: {
        amount: 1219.04248828456,
      },
      net_profit: {
        amount: 455.69751171543965,
      },
      net_revenue: {
        amount: 2708.2599999999998,
      },
      orders: {
        paid_quantity: 23,
        pending_quantity: 2,
      },
      pix: {
        amount: 1197.18,
        cpa_amount: 103.352,
      },
      pix_conversion: {
        conversion: 76.92307692307692,
        paid: {
          amount: 1197.18,
          quantity: 10,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 33.333333333333336,
        paid: {
          amount: 127.9,
          quantity: 1,
        },
        pending: {
          amount: 255.8,
          quantity: 2,
        },
      },
      roi: {
        amount: 0.20230182917699052,
      },
      cpa: {
        amount: 44.93565217391304,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 2698.821668099151,
      },
      cogs: {
        amount: 1232.8310173102334,
      },
      cpa: {
        amount: 45.11814772735801,
      },
      net_profit: {
        amount: 499.4638662283669,
      },
      roi: {
        amount: 0.19082244872296333,
      },
    },
  },
  {
    id: '027fc8cb-5294-4dce-b0e1-e459b762ec4d',
    alias_id: 'ad-sense-campaign-5g9ratzjh8hxbe8y6zr2edjzp24n',
    name: 'Adesivo - [ABO] - [1-10-1] - [DIREC] - [C02A]',
    provider: 'FACEBOOK',
    provider_id: '23850958361050098',
    status: 'ENABLED',
    daily_budget: 0,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 1.4127664768104151,
      },
      cpm: {
        amount: 25.24044192469836,
      },
      ctr: {
        percentage: 1.7865968890827155,
      },
      roas: {
        amount: 3.4390507999999995,
      },
      spend: {
        amount: 1736.2900000000002,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 68790,
      },
    },
    financial_report: {
      boleto: {
        amount: 255.8,
        cpa_amount: 868.1450000000001,
      },
      card: {
        amount: 2479.6600000000003,
        cpa_amount: 82.6804761904762,
      },
      cogs: {
        amount: 2237.3014512507993,
      },
      net_profit: {
        amount: 1173.9885487491997,
      },
      net_revenue: {
        amount: 5147.579999999999,
      },
      orders: {
        paid_quantity: 43,
        pending_quantity: 8,
      },
      pix: {
        amount: 2412.12,
        cpa_amount: 86.81450000000001,
      },
      pix_conversion: {
        conversion: 83.33333333333333,
        paid: {
          amount: 2412.12,
          quantity: 20,
        },
        pending: {
          amount: 240.45,
          quantity: 1,
        },
      },
      boleto_conversion: {
        conversion: 22.22222222222222,
        paid: {
          amount: 255.8,
          quantity: 2,
        },
        pending: {
          amount: 895.3,
          quantity: 7,
        },
      },
      roi: {
        amount: 0.2954477235901173,
      },
      cpa: {
        amount: 40.37883720930233,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 5274.26244851652,
      },
      cogs: {
        amount: 2366.1170752086587,
      },
      cpa: {
        amount: 39.49316715801657,
      },
      net_profit: {
        amount: 1205.8969266528686,
      },
      roi: {
        amount: 0.285650680642963,
      },
    },
  },
  {
    id: '1c08989f-e3c9-4ed9-8334-d4630f53a7ad',
    alias_id: 'ad-sense-campaign-mm94okl3giwcnn1fffcbxptimwfu',
    name: 'Adesivo - ABO [1-22-1] - MISTO — 18 C01 add C11',
    provider: 'FACEBOOK',
    provider_id: '23852015614280195',
    status: 'ENABLED',
    daily_budget: 0,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 1.4986138613861384,
      },
      cpm: {
        amount: 22.20983125458547,
      },
      ctr: {
        percentage: 1.4820249449743215,
      },
      roas: {
        amount: 2.3376286,
      },
      spend: {
        amount: 151.35999999999999,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 6815,
      },
    },
    financial_report: {
      boleto: {
        amount: 0,
        cpa_amount: 0,
      },
      card: {
        amount: 110.15,
        cpa_amount: 151.35999999999999,
      },
      cogs: {
        amount: 101.00559360176001,
      },
      net_profit: {
        amount: -21.985593601759994,
      },
      net_revenue: {
        amount: 230.38,
      },
      orders: {
        paid_quantity: 2,
        pending_quantity: 1,
      },
      pix: {
        amount: 120.23,
        cpa_amount: 151.35999999999999,
      },
      pix_conversion: {
        conversion: 50,
        paid: {
          amount: 120.23,
          quantity: 1,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 0,
        paid: {
          amount: 0,
          quantity: 0,
        },
        pending: {
          amount: 127.9,
          quantity: 1,
        },
      },
      roi: {
        amount: -0.0871180309803003,
      },
      cpa: {
        amount: 75.67999999999999,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 275.5615026185811,
      },
      cogs: {
        amount: 126.52879782089099,
      },
      cpa: {
        amount: 64.38099234222597,
      },
      net_profit: {
        amount: 5.974300442804719,
      },
      roi: {
        amount: -0.008374915507785057,
      },
    },
  },
  {
    id: 'c3c2485d-fcfc-4e08-b043-d02f03f61042',
    alias_id: 'ad-sense-campaign-tkoay9jiv8b2373yyeetq1s919ij',
    name: 'Adesivo - ABO [1-22-1] - MISTO — 18 CM11',
    provider: 'FACEBOOK',
    provider_id: '23852015614320195',
    status: 'ENABLED',
    daily_budget: 0,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 1.1597291773122127,
      },
      cpm: {
        amount: 21.062893840542724,
      },
      ctr: {
        percentage: 1.8161907325086077,
      },
      roas: {
        amount: 3.308045,
      },
      spend: {
        amount: 2269.59,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 107753,
      },
    },
    financial_report: {
      boleto: {
        amount: 0,
        cpa_amount: 0,
      },
      card: {
        amount: 0,
        cpa_amount: 0,
      },
      cogs: {
        amount: 0,
      },
      net_profit: {
        amount: -2269.59,
      },
      net_revenue: {
        amount: 0,
      },
      orders: {
        paid_quantity: 0,
        pending_quantity: 0,
      },
      pix: {
        amount: 0,
        cpa_amount: 0,
      },
      pix_conversion: {
        conversion: 0,
        paid: {
          amount: 0,
          quantity: 0,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 0,
        paid: {
          amount: 0,
          quantity: 0,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      roi: {
        amount: -1,
      },
      cpa: {
        amount: 0,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 0,
      },
      cogs: {
        amount: 0,
      },
      cpa: {
        amount: 0,
      },
      net_profit: {
        amount: -2269.59,
      },
      roi: {
        amount: -1,
      },
    },
  },
  {
    id: '0d58ed27-d0bd-40cf-8698-effc1e7fd963',
    alias_id: 'ad-sense-campaign-caz2vhh864xupwyadqrkxgn47twj',
    name: 'Adesivo - ABO [1-22-1] - MISTO C01 - Add c11',
    provider: 'FACEBOOK',
    provider_id: '23851954470980195',
    status: 'ENABLED',
    daily_budget: 0,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 1.294755600814664,
      },
      cpm: {
        amount: 25.669264313978843,
      },
      ctr: {
        percentage: 1.9825567310021806,
      },
      roas: {
        amount: 3.9250728,
      },
      spend: {
        amount: 1271.45,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 49532,
      },
    },
    financial_report: {
      boleto: {
        amount: 255.8,
        cpa_amount: 635.725,
      },
      card: {
        amount: 1521.2900000000002,
        cpa_amount: 97.80384615384615,
      },
      cogs: {
        amount: 1079.2936162068802,
      },
      net_profit: {
        amount: 147.72638379312014,
      },
      net_revenue: {
        amount: 2498.4700000000003,
      },
      orders: {
        paid_quantity: 21,
        pending_quantity: 4,
      },
      pix: {
        amount: 721.38,
        cpa_amount: 211.90833333333333,
      },
      pix_conversion: {
        conversion: 33.333333333333336,
        paid: {
          amount: 721.38,
          quantity: 6,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 33.333333333333336,
        paid: {
          amount: 255.8,
          quantity: 2,
        },
        pending: {
          amount: 639.5,
          quantity: 4,
        },
      },
      roi: {
        amount: 0.06284240560078125,
      },
      cpa: {
        amount: 60.5452380952381,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 2546.766238411203,
      },
      cogs: {
        amount: 1129.7927329287322,
      },
      cpa: {
        amount: 60.56705237575069,
      },
      net_profit: {
        amount: 133.40922842900568,
      },
      roi: {
        amount: 0.060603413177217604,
      },
    },
  },
  {
    id: 'c1435e33-5655-47b3-92ae-11bdd9e8d5ab',
    alias_id: 'ad-sense-campaign-15fp379ah9or4gf48ys6dvifjrv5',
    name: 'Adesivo - [ABO] - [1-4-1] - [ABERTO] - [TESTES] - coloquei c11',
    provider: 'FACEBOOK',
    provider_id: '23851036400840098',
    status: 'ENABLED',
    daily_budget: 0,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 0.940700964630225,
      },
      cpm: {
        amount: 17.037317431107176,
      },
      ctr: {
        percentage: 1.8111300053576838,
      },
      roas: {
        amount: 2.576603,
      },
      spend: {
        amount: 1462.79,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 85858,
      },
    },
    financial_report: {
      boleto: {
        amount: 127.9,
        cpa_amount: 1462.79,
      },
      card: {
        amount: 1345.9099999999999,
        cpa_amount: 121.89916666666666,
      },
      cogs: {
        amount: 1067.265971348,
      },
      net_profit: {
        amount: -94.40597134799938,
      },
      net_revenue: {
        amount: 2435.6500000000005,
      },
      orders: {
        paid_quantity: 21,
        pending_quantity: 1,
      },
      pix: {
        amount: 961.84,
        cpa_amount: 182.84875,
      },
      pix_conversion: {
        conversion: 61.53846153846154,
        paid: {
          amount: 961.84,
          quantity: 8,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 50,
        paid: {
          amount: 127.9,
          quantity: 1,
        },
        pending: {
          amount: 127.9,
          quantity: 1,
        },
      },
      roi: {
        amount: -0.03731378768577211,
      },
      cpa: {
        amount: 69.65666666666667,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 2379.847528563734,
      },
      cogs: {
        amount: 1105.428888246919,
      },
      cpa: {
        amount: 71.2175619449227,
      },
      net_profit: {
        amount: -88.22639221331201,
      },
      roi: {
        amount: -0.07334708133533285,
      },
    },
  },
  {
    id: 'abc5b688-6a5e-43cc-a75b-16a1dba2fd0a',
    alias_id: 'ad-sense-campaign-ct9z90gaq96dhhwm8x7vxftd1cui',
    name: 'Adesivo - ABO [1-4-1] MISTO — 17',
    provider: 'FACEBOOK',
    provider_id: '23852006668890195',
    status: 'ENABLED',
    daily_budget: 0,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 1.2010813397129187,
      },
      cpm: {
        amount: 22.445902929289318,
      },
      ctr: {
        percentage: 1.8688078972781574,
      },
      roas: {
        amount: 1.6256909999999998,
      },
      spend: {
        amount: 1255.13,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 55918,
      },
    },
    financial_report: {
      boleto: {
        amount: 383.70000000000005,
        cpa_amount: 418.3766666666667,
      },
      card: {
        amount: 821.0799999999999,
        cpa_amount: 179.30428571428573,
      },
      cogs: {
        amount: 862.6169669281601,
      },
      net_profit: {
        amount: -71.35696692815966,
      },
      net_revenue: {
        amount: 2046.3900000000006,
      },
      orders: {
        paid_quantity: 17,
        pending_quantity: 2,
      },
      pix: {
        amount: 841.61,
        cpa_amount: 179.30428571428573,
      },
      pix_conversion: {
        conversion: 70,
        paid: {
          amount: 841.61,
          quantity: 7,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 60,
        paid: {
          amount: 383.70000000000005,
          quantity: 3,
        },
        pending: {
          amount: 255.8,
          quantity: 2,
        },
      },
      roi: {
        amount: -0.03369475581479137,
      },
      cpa: {
        amount: 73.83117647058825,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 1884.2108193809377,
      },
      cogs: {
        amount: 846.1734778769509,
      },
      cpa: {
        amount: 79.8298347773671,
      },
      net_profit: {
        amount: -202.94176706126996,
      },
      roi: {
        amount: -0.10331332945555902,
      },
    },
  },
  {
    id: 'f3f3d430-de90-4e42-9749-6dcfa600d1ab',
    alias_id: 'ad-sense-campaign-iqs2xxv49ys9cn4b5at6c03l7qt1',
    name: 'Adesivo - ABO [1-4-1] MISTO — 18 C01 add c11',
    provider: 'FACEBOOK',
    provider_id: '23852015614330195',
    status: 'ENABLED',
    daily_budget: 0,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 1.2789115646258504,
      },
      cpm: {
        amount: 22.399618729893962,
      },
      ctr: {
        percentage: 1.7514595496246872,
      },
      roas: {
        amount: 2.2779902,
      },
      spend: {
        amount: 564,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 25179,
      },
    },
    financial_report: {
      boleto: {
        amount: 127.9,
        cpa_amount: 564,
      },
      card: {
        amount: 456.21000000000004,
        cpa_amount: 141,
      },
      cogs: {
        amount: 406.58934313168015,
      },
      net_profit: {
        amount: -25.789343131680255,
      },
      net_revenue: {
        amount: 944.8,
      },
      orders: {
        paid_quantity: 8,
        pending_quantity: 4,
      },
      pix: {
        amount: 360.69,
        cpa_amount: 188,
      },
      pix_conversion: {
        conversion: 50,
        paid: {
          amount: 360.69,
          quantity: 3,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 20,
        paid: {
          amount: 127.9,
          quantity: 1,
        },
        pending: {
          amount: 511.6,
          quantity: 4,
        },
      },
      roi: {
        amount: -0.02657080804995131,
      },
      cpa: {
        amount: 70.5,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 1044.341630739964,
      },
      cogs: {
        amount: 472.0602069038714,
      },
      cpa: {
        amount: 64.30098721171484,
      },
      net_profit: {
        amount: 22.990975171056448,
      },
      roi: {
        amount: 0.007993187829151972,
      },
    },
  },
  {
    id: '9e437e23-d31c-4651-9931-03e44134227e',
    alias_id: 'ad-sense-campaign-29tquompxc06c5e89s4jm6zzrnza',
    name: 'Adesivo - ABO [1-4-1] MISTO — 18 C01 add c11',
    provider: 'FACEBOOK',
    provider_id: '23852015614310195',
    status: 'ENABLED',
    daily_budget: 0,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 1.384073756432247,
      },
      cpm: {
        amount: 25.919150713092634,
      },
      ctr: {
        percentage: 1.8726712064756519,
      },
      roas: {
        amount: 2.0409126000000004,
      },
      spend: {
        amount: 1613.83,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 62264,
      },
    },
    financial_report: {
      boleto: {
        amount: 255.8,
        cpa_amount: 806.915,
      },
      card: {
        amount: 1516.9600000000003,
        cpa_amount: 124.14076923076922,
      },
      cogs: {
        amount: 985.2601958067199,
      },
      net_profit: {
        amount: -345.41019580671946,
      },
      net_revenue: {
        amount: 2253.6800000000003,
      },
      orders: {
        paid_quantity: 19,
        pending_quantity: 2,
      },
      pix: {
        amount: 480.92,
        cpa_amount: 403.4575,
      },
      pix_conversion: {
        conversion: 66.66666666666667,
        paid: {
          amount: 480.92,
          quantity: 4,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 50,
        paid: {
          amount: 255.8,
          quantity: 2,
        },
        pending: {
          amount: 255.8,
          quantity: 2,
        },
      },
      roi: {
        amount: -0.13289657910448513,
      },
      cpa: {
        amount: 84.93842105263157,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 2161.13972244187,
      },
      cogs: {
        amount: 982.626550430775,
      },
      cpa: {
        amount: 88.39042437621178,
      },
      net_profit: {
        amount: -391.9669650931244,
      },
      roi: {
        amount: -0.1676580445440852,
      },
    },
  },
  {
    id: '2ee671a9-d033-41ea-aeb9-6c3d2b8cb44a',
    alias_id: 'ad-sense-campaign-z6zx6w1ou3e3inkna9dh0ik6whrz',
    name: 'Adesivo - ABO - 1-5-1 M - ABERTO 22-62+ C02',
    provider: 'FACEBOOK',
    provider_id: '23851455962070736',
    status: 'ENABLED',
    daily_budget: 0,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 1.2414779186707479,
      },
      cpm: {
        amount: 20.412086529544133,
      },
      ctr: {
        percentage: 1.6441763661329865,
      },
      roas: {
        amount: 2.4229860000000003,
      },
      spend: {
        amount: 2839.26,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 139097,
      },
    },
    financial_report: {
      boleto: {
        amount: 370.91,
        cpa_amount: 946.4200000000001,
      },
      card: {
        amount: 2819.1200000000003,
        cpa_amount: 123.44608695652175,
      },
      cogs: {
        amount: 1857.0723053372794,
      },
      net_profit: {
        amount: -544.4623053372788,
      },
      net_revenue: {
        amount: 4151.870000000001,
      },
      orders: {
        paid_quantity: 34,
        pending_quantity: 8,
      },
      pix: {
        amount: 961.84,
        cpa_amount: 354.9075,
      },
      pix_conversion: {
        conversion: 61.53846153846154,
        paid: {
          amount: 961.84,
          quantity: 8,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 27.272727272727273,
        paid: {
          amount: 370.91,
          quantity: 3,
        },
        pending: {
          amount: 1023.1999999999999,
          quantity: 8,
        },
      },
      roi: {
        amount: -0.11593351363116049,
      },
      cpa: {
        amount: 83.50764705882354,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 4247.174930266671,
      },
      cogs: {
        amount: 1865.708141449742,
      },
      cpa: {
        amount: 81.9025430129672,
      },
      net_profit: {
        amount: -519.3147359901136,
      },
      roi: {
        amount: -0.09729995983394939,
      },
    },
  },
  {
    id: '6d72f34c-09bb-43f6-a710-f43f348a8edd',
    alias_id: 'ad-sense-campaign-wieoxne6qnjbz6i378alo26lvik6',
    name: 'Adesivo - [ABO] - [1-7-1] - [DIREC] - [C02A] NOVA',
    provider: 'FACEBOOK',
    provider_id: '23851078678920098',
    status: 'ENABLED',
    daily_budget: 0,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 1.3898855421686747,
      },
      cpm: {
        amount: 22.422519606985627,
      },
      ctr: {
        percentage: 1.6132637491860793,
      },
      roas: {
        amount: 4.0996125999999995,
      },
      spend: {
        amount: 2307.21,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 102897,
      },
    },
    financial_report: {
      boleto: {
        amount: 543.6,
        cpa_amount: 576.8025,
      },
      card: {
        amount: 4752.879999999999,
        cpa_amount: 60.71605263157895,
      },
      cogs: {
        amount: 3596.53229854512,
      },
      net_profit: {
        amount: 2330.977701454876,
      },
      net_revenue: {
        amount: 8234.719999999996,
      },
      orders: {
        paid_quantity: 64,
        pending_quantity: 4,
      },
      pix: {
        amount: 2938.24,
        cpa_amount: 104.87318181818182,
      },
      pix_conversion: {
        conversion: 84.61538461538461,
        paid: {
          amount: 2938.24,
          quantity: 22,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 50,
        paid: {
          amount: 543.6,
          quantity: 4,
        },
        pending: {
          amount: 535.6,
          quantity: 4,
        },
      },
      roi: {
        amount: 0.3948305301248172,
      },
      cpa: {
        amount: 36.05015625,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 8010.3105423958,
      },
      cogs: {
        amount: 3354.0681091251963,
      },
      cpa: {
        amount: 37.02125842280514,
      },
      net_profit: {
        amount: 1863.460777523219,
      },
      roi: {
        amount: 0.41492970103063637,
      },
    },
  },
  {
    id: 'e5b8ee19-4243-40c4-b445-f9a7a2ac111a',
    alias_id: 'ad-sense-campaign-gf1xod5bnpuztylz2bnloajo8n0r',
    name: 'Adesivo - [ABO] - [1-7-1] - [MISTO] - [C11]',
    provider: 'FACEBOOK',
    provider_id: '23850968124080098',
    status: 'ENABLED',
    daily_budget: 0,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 1.1394412681912685,
      },
      cpm: {
        amount: 18.414284334353884,
      },
      ctr: {
        percentage: 1.6160801656398174,
      },
      roas: {
        amount: 3.394623,
      },
      spend: {
        amount: 4384.570000000001,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 238107,
      },
    },
    financial_report: {
      boleto: {
        amount: 1023.1999999999999,
        cpa_amount: 548.0712500000001,
      },
      card: {
        amount: 6050.67,
        cpa_amount: 89.48102040816327,
      },
      cogs: {
        amount: 4513.181624622963,
      },
      net_profit: {
        amount: 1423.8283753770293,
      },
      net_revenue: {
        amount: 10321.579999999993,
      },
      orders: {
        paid_quantity: 84,
        pending_quantity: 7,
      },
      pix: {
        amount: 3247.71,
        cpa_amount: 162.3914814814815,
      },
      pix_conversion: {
        conversion: 75,
        paid: {
          amount: 3247.71,
          quantity: 27,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 53.333333333333336,
        paid: {
          amount: 1023.1999999999999,
          quantity: 8,
        },
        pending: {
          amount: 895.3,
          quantity: 7,
        },
      },
      roi: {
        amount: 0.16002114190699968,
      },
      cpa: {
        amount: 52.19726190476191,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 9904.728583903463,
      },
      cogs: {
        amount: 4343.914444530412,
      },
      cpa: {
        amount: 54.32274084650186,
      },
      net_profit: {
        amount: 1016.9413719885824,
      },
      roi: {
        amount: 0.13475926397624838,
      },
    },
  },
  {
    id: 'bad69121-3f93-42fd-b864-baaf3816b98f',
    alias_id: 'ad-sense-campaign-vo7m5bwm9g49pkzxt95d2s8q7wb8',
    name: 'Adesivo - [ABO] - [1-7-1] - [MISTO] - [C11] — Cópia',
    provider: 'FACEBOOK',
    provider_id: '23851140295240098',
    status: 'ENABLED',
    daily_budget: 0,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 1.0850185185185184,
      },
      cpm: {
        amount: 21.157332177806666,
      },
      ctr: {
        percentage: 1.9499512512187196,
      },
      roas: {
        amount: 4.746992,
      },
      spend: {
        amount: 585.91,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 27693,
      },
    },
    financial_report: {
      boleto: {
        amount: 0,
        cpa_amount: 0,
      },
      card: {
        amount: 788,
        cpa_amount: 83.70142857142856,
      },
      cogs: {
        amount: 606.71500380888,
      },
      net_profit: {
        amount: 196.52499619112007,
      },
      net_revenue: {
        amount: 1389.15,
      },
      orders: {
        paid_quantity: 12,
        pending_quantity: 1,
      },
      pix: {
        amount: 601.15,
        cpa_amount: 117.18199999999999,
      },
      pix_conversion: {
        conversion: 35.714285714285715,
        paid: {
          amount: 601.15,
          quantity: 5,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 0,
        paid: {
          amount: 0,
          quantity: 0,
        },
        pending: {
          amount: 127.9,
          quantity: 1,
        },
      },
      roi: {
        amount: 0.1647835619440135,
      },
      cpa: {
        amount: 48.82583333333333,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 1423.3319082980943,
      },
      cogs: {
        amount: 659.4791885097075,
      },
      cpa: {
        amount: 47.81517445160928,
      },
      net_profit: {
        amount: 234.1301692556973,
      },
      roi: {
        amount: 0.14288121450718694,
      },
    },
  },
  {
    id: '8eb6c280-929c-4c7f-888f-653fc3846dd3',
    alias_id: 'ad-sense-campaign-6rb7uznlax2kqdqiy6xiqeap11ig',
    name: 'Adesivo - CBO - 1-1-1 M - ABERTO 22-62+ C02',
    provider: 'FACEBOOK',
    provider_id: '23851455962040736',
    status: 'ENABLED',
    daily_budget: 65,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 1.0192211838006229,
      },
      cpm: {
        amount: 15.016063888378921,
      },
      ctr: {
        percentage: 1.473288048467046,
      },
      roas: {
        amount: 4.4257104,
      },
      spend: {
        amount: 327.16999999999996,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 21788,
      },
    },
    financial_report: {
      boleto: {
        amount: 0,
        cpa_amount: 0,
      },
      card: {
        amount: 871.1899999999999,
        cpa_amount: 40.896249999999995,
      },
      cogs: {
        amount: 512.9078569424001,
      },
      net_profit: {
        amount: 151.34214305759983,
      },
      net_revenue: {
        amount: 991.42,
      },
      orders: {
        paid_quantity: 9,
        pending_quantity: 1,
      },
      pix: {
        amount: 120.23,
        cpa_amount: 327.16999999999996,
      },
      pix_conversion: {
        conversion: 100,
        paid: {
          amount: 120.23,
          quantity: 1,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 0,
        paid: {
          amount: 0,
          quantity: 0,
        },
        pending: {
          amount: 127.9,
          quantity: 1,
        },
      },
      roi: {
        amount: 0.18015252015858885,
      },
      cpa: {
        amount: 36.35222222222222,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 1024.251969150021,
      },
      cogs: {
        amount: 497.1487438242773,
      },
      cpa: {
        amount: 35.41792033158575,
      },
      net_profit: {
        amount: 291.0177262149823,
      },
      roi: {
        amount: 0.24254358744554308,
      },
    },
  },
  {
    id: '6d6dbbf3-a850-46eb-8520-87d5766babe1',
    alias_id: 'ad-sense-campaign-zyrsbt8vi7tyhlot2kxitxewhxi1',
    name: 'Adesivo - CBO - 1-1-1 M - ABERTO 22-62+ C02  23/08',
    provider: 'FACEBOOK',
    provider_id: '23851475993830736',
    status: 'ENABLED',
    daily_budget: 65,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 1.307938596491228,
      },
      cpm: {
        amount: 16.523160460992905,
      },
      ctr: {
        percentage: 1.2632978723404253,
      },
      roas: {
        amount: 2.1421858,
      },
      spend: {
        amount: 298.21,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 18048,
      },
    },
    financial_report: {
      boleto: {
        amount: 0,
        cpa_amount: 0,
      },
      card: {
        amount: 117.18,
        cpa_amount: 298.21,
      },
      cogs: {
        amount: 101.46104360176003,
      },
      net_profit: {
        amount: -162.26104360175998,
      },
      net_revenue: {
        amount: 237.41000000000003,
      },
      orders: {
        paid_quantity: 2,
        pending_quantity: 0,
      },
      pix: {
        amount: 120.23,
        cpa_amount: 298.21,
      },
      pix_conversion: {
        conversion: 100,
        paid: {
          amount: 120.23,
          quantity: 1,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 0,
        paid: {
          amount: 0,
          quantity: 0,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      roi: {
        amount: -0.4059864886369903,
      },
      cpa: {
        amount: 149.105,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 235.50849898580122,
      },
      cogs: {
        amount: 106.76474438624797,
      },
      cpa: {
        amount: 150.32467280163598,
      },
      net_profit: {
        amount: -165.45163463764663,
      },
      roi: {
        amount: -0.4184612688805525,
      },
    },
  },
  {
    id: '281779d1-f10e-46e1-a571-166cd38304cd',
    alias_id: 'ad-sense-campaign-dqlchxpas3dcnm4pzgjganhztgi6',
    name: 'Adesivo - CBO - 1-1-1 M - ABERTO 22-62+ C02  23/08',
    provider: 'FACEBOOK',
    provider_id: '23851475993850736',
    status: 'ENABLED',
    daily_budget: 65,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 1.2325283018867925,
      },
      cpm: {
        amount: 17.19776748104465,
      },
      ctr: {
        percentage: 1.395324347093513,
      },
      roas: {
        amount: 1.8619856,
      },
      spend: {
        amount: 326.62,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 18992,
      },
    },
    financial_report: {
      boleto: {
        amount: 127.9,
        cpa_amount: 326.62,
      },
      card: {
        amount: 127.9,
        cpa_amount: 326.62,
      },
      cogs: {
        amount: 200.56573719152001,
      },
      net_profit: {
        amount: -30.92573719151997,
      },
      net_revenue: {
        amount: 496.26000000000005,
      },
      orders: {
        paid_quantity: 4,
        pending_quantity: 1,
      },
      pix: {
        amount: 240.46,
        cpa_amount: 163.31,
      },
      pix_conversion: {
        conversion: 100,
        paid: {
          amount: 240.46,
          quantity: 2,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 50,
        paid: {
          amount: 127.9,
          quantity: 1,
        },
        pending: {
          amount: 127.9,
          quantity: 1,
        },
      },
      roi: {
        amount: -0.058661938307115205,
      },
      cpa: {
        amount: 81.655,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 460.22239671789237,
      },
      cogs: {
        amount: 200.11188906986965,
      },
      cpa: {
        amount: 87.84272744924597,
      },
      net_profit: {
        amount: -77.78760279613024,
      },
      roi: {
        amount: -0.12626820918213857,
      },
    },
  },
  {
    id: '27f64054-a100-4876-9dea-eb790bccc20a',
    alias_id: 'ad-sense-campaign-5rsggapmn9yifnhxpty0ajdvxidj',
    name: 'Adesivo - CBO - 1-1-1 M - ABERTO 22-62+ C02  23/08',
    provider: 'FACEBOOK',
    provider_id: '23851475993870736',
    status: 'ENABLED',
    daily_budget: 85,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 1.2653550295857987,
      },
      cpm: {
        amount: 17.662192855667975,
      },
      ctr: {
        percentage: 1.3958290315919883,
      },
      roas: {
        amount: 4.8675388,
      },
      spend: {
        amount: 427.69,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 24215,
      },
    },
    financial_report: {
      boleto: {
        amount: 0,
        cpa_amount: 0,
      },
      card: {
        amount: 600.68,
        cpa_amount: 85.538,
      },
      cogs: {
        amount: 459.5689623620002,
      },
      net_profit: {
        amount: 194.34103763799996,
      },
      net_revenue: {
        amount: 1081.6000000000001,
      },
      orders: {
        paid_quantity: 9,
        pending_quantity: 0,
      },
      pix: {
        amount: 480.92,
        cpa_amount: 106.9225,
      },
      pix_conversion: {
        conversion: 80,
        paid: {
          amount: 480.92,
          quantity: 4,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 0,
        paid: {
          amount: 0,
          quantity: 0,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      roi: {
        amount: 0.2190353052288574,
      },
      cpa: {
        amount: 47.52111111111111,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 1071.8526572008113,
      },
      cogs: {
        amount: 480.0046841169042,
      },
      cpa: {
        amount: 47.95341596543097,
      },
      net_profit: {
        amount: 169.17966513115294,
      },
      roi: {
        amount: 0.18085153075851307,
      },
    },
  },
  {
    id: '3a721fdb-a261-4196-bcfd-bb9a19b85b7a',
    alias_id: 'ad-sense-campaign-aan0e1l8d9lqxc98gzuhqsgojuya',
    name: 'Adesivo - CBO - 1-1-1 M - ABERTO 22-62+ C02  23/08',
    provider: 'FACEBOOK',
    provider_id: '23851475993760736',
    status: 'ENABLED',
    daily_budget: 65,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 1.4386784140969164,
      },
      cpm: {
        amount: 18.103104212860313,
      },
      ctr: {
        percentage: 1.2583148558758315,
      },
      roas: {
        amount: 2.2805232,
      },
      spend: {
        amount: 326.58000000000004,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 18040,
      },
    },
    financial_report: {
      boleto: {
        amount: 0,
        cpa_amount: 0,
      },
      card: {
        amount: 484.64000000000004,
        cpa_amount: 81.64500000000001,
      },
      cogs: {
        amount: 261.1938089984001,
      },
      net_profit: {
        amount: 17.09619100159994,
      },
      net_revenue: {
        amount: 604.87,
      },
      orders: {
        paid_quantity: 5,
        pending_quantity: 0,
      },
      pix: {
        amount: 120.23,
        cpa_amount: 326.58000000000004,
      },
      pix_conversion: {
        conversion: 100,
        paid: {
          amount: 120.23,
          quantity: 1,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 0,
        paid: {
          amount: 0,
          quantity: 0,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      roi: {
        amount: 0.029086343657831183,
      },
      cpa: {
        amount: 65.316,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 597.005659229209,
      },
      cogs: {
        amount: 265.601864101985,
      },
      cpa: {
        amount: 66.17506781750926,
      },
      net_profit: {
        amount: 3.686976407572274,
      },
      roi: {
        amount: 0.008145800166540144,
      },
    },
  },
  {
    id: 'a6dbaefa-4c77-4532-a66a-7df1ae862286',
    alias_id: 'ad-sense-campaign-gosv7idlsxbzfkjcg47oamvkkwab',
    name: 'Adesivo - [CBO] - [1-2-1] - [ABERTO] - [C11]',
    provider: 'FACEBOOK',
    provider_id: '23851044761680098',
    status: 'ENABLED',
    daily_budget: 70,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 0.84381861575179,
      },
      cpm: {
        amount: 13.139586740002974,
      },
      ctr: {
        percentage: 1.557157722610376,
      },
      roas: {
        amount: 3.8286293999999996,
      },
      spend: {
        amount: 353.56,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 26908,
      },
    },
    financial_report: {
      boleto: {
        amount: 0,
        cpa_amount: 0,
      },
      card: {
        amount: 786.3599999999999,
        cpa_amount: 50.50857142857143,
      },
      cogs: {
        amount: 416.8048905551201,
      },
      net_profit: {
        amount: 136.22510944487982,
      },
      net_revenue: {
        amount: 906.5899999999999,
      },
      orders: {
        paid_quantity: 8,
        pending_quantity: 2,
      },
      pix: {
        amount: 120.23,
        cpa_amount: 353.56,
      },
      pix_conversion: {
        conversion: 50,
        paid: {
          amount: 120.23,
          quantity: 1,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 0,
        paid: {
          amount: 0,
          quantity: 0,
        },
        pending: {
          amount: 255.8,
          quantity: 2,
        },
      },
      roi: {
        amount: 0.17683192875874287,
      },
      cpa: {
        amount: 44.195,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 987.7674474278314,
      },
      cogs: {
        amount: 463.96709068700795,
      },
      cpa: {
        amount: 41.0120876923854,
      },
      net_profit: {
        amount: 223.36745761369383,
      },
      roi: {
        amount: 0.2082381840065533,
      },
    },
  },
  {
    id: 'd7191540-6d9a-4560-aff4-0619b9b0f1d6',
    alias_id: 'ad-sense-campaign-j7urds9neyjclsp0uciy4zguzz8m',
    name: 'Adesivo - [CBO] - [1-2-1] - [ABERTO] - [C11] — 24/08',
    provider: 'FACEBOOK',
    provider_id: '23851077311130098',
    status: 'ENABLED',
    daily_budget: 70,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 0.9349602122015915,
      },
      cpm: {
        amount: 14.287219812735602,
      },
      ctr: {
        percentage: 1.5281099266345102,
      },
      roas: {
        amount: 5.504864400000001,
      },
      spend: {
        amount: 352.48,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 24671,
      },
    },
    financial_report: {
      boleto: {
        amount: 0,
        cpa_amount: 0,
      },
      card: {
        amount: 675.3400000000001,
        cpa_amount: 58.74666666666667,
      },
      cogs: {
        amount: 461.58001015352016,
      },
      net_profit: {
        amount: 221.96998984648,
      },
      net_revenue: {
        amount: 1036.0300000000002,
      },
      orders: {
        paid_quantity: 9,
        pending_quantity: 1,
      },
      pix: {
        amount: 360.69,
        cpa_amount: 117.49333333333334,
      },
      pix_conversion: {
        conversion: 42.857142857142854,
        paid: {
          amount: 360.69,
          quantity: 3,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 0,
        paid: {
          amount: 0,
          quantity: 0,
        },
        pending: {
          amount: 127.9,
          quantity: 1,
        },
      },
      roi: {
        amount: 0.27267030326747005,
      },
      cpa: {
        amount: 39.16444444444445,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 1072.0400624563094,
      },
      cogs: {
        amount: 498.8954063091239,
      },
      cpa: {
        amount: 38.02427163869857,
      },
      net_profit: {
        amount: 267.87964221273035,
      },
      roi: {
        amount: 0.2591860823227314,
      },
    },
  },
  {
    id: '4c087768-c260-4040-bc4b-d9c05bf80be5',
    alias_id: 'ad-sense-campaign-w3445n5iqfzgqpyrn1uznw4w4h0f',
    name: 'Adesivo - CBO - 1-3-1 M - ABERTO 22-62+ C02',
    provider: 'FACEBOOK',
    provider_id: '23851095275120736',
    status: 'ENABLED',
    daily_budget: 65,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 0.9081994459833795,
      },
      cpm: {
        amount: 17.63162140360312,
      },
      ctr: {
        percentage: 1.9413820919602043,
      },
      roas: {
        amount: 1.4004096000000001,
      },
      spend: {
        amount: 327.86,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 18595,
      },
    },
    financial_report: {
      boleto: {
        amount: 0,
        cpa_amount: 0,
      },
      card: {
        amount: 0,
        cpa_amount: 0,
      },
      cogs: {
        amount: 96.83679360176002,
      },
      net_profit: {
        amount: -184.23679360176004,
      },
      net_revenue: {
        amount: 240.46,
      },
      orders: {
        paid_quantity: 2,
        pending_quantity: 2,
      },
      pix: {
        amount: 240.46,
        cpa_amount: 163.93,
      },
      pix_conversion: {
        conversion: 50,
        paid: {
          amount: 240.46,
          quantity: 2,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 0,
        paid: {
          amount: 0,
          quantity: 0,
        },
        pending: {
          amount: 255.8,
          quantity: 2,
        },
      },
      roi: {
        amount: -0.4338078280254681,
      },
      cpa: {
        amount: 163.93,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 334.39785310734464,
      },
      cogs: {
        amount: 147.16618249795732,
      },
      cpa: {
        amount: 119.89921487603306,
      },
      net_profit: {
        amount: -144.8638064778699,
      },
      roi: {
        amount: -0.2960433226040491,
      },
    },
  },
  {
    id: '782e5d20-a2a2-4f96-ab62-351754ca6eb8',
    alias_id: 'ad-sense-campaign-z5vr3ec5hofjrbzw7kwv1vie90yw',
    name: 'Adesivo - CBO - 1-3-1 M - ABERTO 22-62+ C02 — 13/08 — Cópia — Cópia',
    provider: 'FACEBOOK',
    provider_id: '23851429928580736',
    status: 'ENABLED',
    daily_budget: 65,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 1.1804000000000001,
      },
      cpm: {
        amount: 24.46932006633499,
      },
      ctr: {
        percentage: 2.0729684908789388,
      },
      roas: {
        amount: 0,
      },
      spend: {
        amount: 29.51,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 1206,
      },
    },
    financial_report: {
      boleto: {
        amount: 0,
        cpa_amount: 0,
      },
      card: {
        amount: 122.38,
        cpa_amount: 29.51,
      },
      cogs: {
        amount: 53.62164312008,
      },
      net_profit: {
        amount: 39.24835687991998,
      },
      net_revenue: {
        amount: 122.38,
      },
      orders: {
        paid_quantity: 1,
        pending_quantity: 0,
      },
      pix: {
        amount: 0,
        cpa_amount: 0,
      },
      pix_conversion: {
        conversion: 0,
        paid: {
          amount: 0,
          quantity: 0,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 0,
        paid: {
          amount: 0,
          quantity: 0,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      roi: {
        amount: 0.4721229535091406,
      },
      cpa: {
        amount: 29.51,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 120.39411764705882,
      },
      cogs: {
        amount: 52.94570657191233,
      },
      cpa: {
        amount: 29.99676288659794,
      },
      net_profit: {
        amount: 36.32620368173964,
      },
      roi: {
        amount: 0.46010655480902535,
      },
    },
  },
  {
    id: '6151bce8-4518-4008-a4e1-1761e12b1171',
    alias_id: 'ad-sense-campaign-ggbaqg2vx57uh2xs9mjd8zek43rs',
    name: 'Adesivo - CBO - 1-3-1 M - ABERTO 22-62+ C02 — Cópia',
    provider: 'FACEBOOK',
    provider_id: '23851429928590736',
    status: 'ENABLED',
    daily_budget: 65,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 1.044775641025641,
      },
      cpm: {
        amount: 16.852962465101854,
      },
      ctr: {
        percentage: 1.6130700031020577,
      },
      roas: {
        amount: 2.648564,
      },
      spend: {
        amount: 325.97,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 19342,
      },
    },
    financial_report: {
      boleto: {
        amount: 0,
        cpa_amount: 0,
      },
      card: {
        amount: 244.76,
        cpa_amount: 162.985,
      },
      cogs: {
        amount: 203.65610483592,
      },
      net_profit: {
        amount: -44.40610483591999,
      },
      net_revenue: {
        amount: 485.22,
      },
      orders: {
        paid_quantity: 4,
        pending_quantity: 2,
      },
      pix: {
        amount: 240.46,
        cpa_amount: 162.985,
      },
      pix_conversion: {
        conversion: 66.66666666666667,
        paid: {
          amount: 240.46,
          quantity: 2,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 0,
        paid: {
          amount: 0,
          quantity: 0,
        },
        pending: {
          amount: 255.8,
          quantity: 2,
        },
      },
      roi: {
        amount: -0.08384425244612363,
      },
      cpa: {
        amount: 81.4925,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 575.1860884014623,
      },
      cogs: {
        amount: 253.05759564178197,
      },
      cpa: {
        amount: 69.32568734736853,
      },
      net_profit: {
        amount: -11.30139911439062,
      },
      roi: {
        amount: -0.006634411328983202,
      },
    },
  },
  {
    id: '47d0b493-8d6e-49d6-a6c1-5ebf7ec2c5e0',
    alias_id: 'ad-sense-campaign-oox0n8q2sqz0wy2ptxyqajr4j0ki',
    name: 'BATOM - TESTES',
    provider: 'FACEBOOK',
    provider_id: '23851098320370242',
    status: 'ENABLED',
    daily_budget: 0,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 0.6350387025433101,
      },
      cpm: {
        amount: 15.46039466245502,
      },
      ctr: {
        percentage: 2.434559437170778,
      },
      roas: {
        amount: 3.7868266,
      },
      spend: {
        amount: 1722.8600000000001,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 111437,
      },
    },
    financial_report: {
      boleto: {
        amount: 589.5,
        cpa_amount: 344.572,
      },
      card: {
        amount: 2887.35,
        cpa_amount: 71.78583333333334,
      },
      cogs: {
        amount: 2839.3274973873586,
      },
      net_profit: {
        amount: 1225.262502612639,
      },
      net_revenue: {
        amount: 5787.449999999998,
      },
      orders: {
        paid_quantity: 49,
        pending_quantity: 8,
      },
      pix: {
        amount: 2310.5999999999995,
        cpa_amount: 86.143,
      },
      pix_conversion: {
        conversion: 76.92307692307692,
        paid: {
          amount: 2310.5999999999995,
          quantity: 20,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 38.46153846153846,
        paid: {
          amount: 589.5,
          quantity: 5,
        },
        pending: {
          amount: 943.1999999999999,
          quantity: 8,
        },
      },
      roi: {
        amount: 0.26856908080045255,
      },
      cpa: {
        amount: 35.16040816326531,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 5713.9523825076485,
      },
      cogs: {
        amount: 2604.010408662968,
      },
      cpa: {
        amount: 35.60764088359188,
      },
      net_profit: {
        amount: 1515.1392780198935,
      },
      roi: {
        amount: 0.3205739582742203,
      },
    },
  },
  {
    id: 'bca5e7d0-5463-48e5-8a1f-bd937f4c39ad',
    alias_id: 'ad-sense-campaign-wuo4ktit49wau7ttbnufs1bdfnvh',
    name: 'MEIA - TESTES',
    provider: 'FACEBOOK',
    provider_id: '23851098401810242',
    status: 'ENABLED',
    daily_budget: 0,
    has_all_ads_with_utm_synchronized: true,
    marketing_report: {
      cpc: {
        amount: 1.0182073434125272,
      },
      cpm: {
        amount: 23.549716512226194,
      },
      ctr: {
        percentage: 2.312860603941354,
      },
      roas: {
        amount: 2.0803792000000003,
      },
      spend: {
        amount: 942.8600000000001,
      },
      cpa: {
        amount: 0,
      },
      impressions: {
        quantity: 40037,
      },
    },
    financial_report: {
      boleto: {
        amount: 508,
        cpa_amount: 235.71500000000003,
      },
      card: {
        amount: 1071.88,
        cpa_amount: 117.85750000000002,
      },
      cogs: {
        amount: 1017.4991980344157,
      },
      net_profit: {
        amount: -22.339198034415816,
      },
      net_revenue: {
        amount: 1938.02,
      },
      orders: {
        paid_quantity: 15,
        pending_quantity: 2,
      },
      pix: {
        amount: 358.14,
        cpa_amount: 314.2866666666667,
      },
      pix_conversion: {
        conversion: 100,
        paid: {
          amount: 358.14,
          quantity: 3,
        },
        pending: {
          amount: 0,
          quantity: 0,
        },
      },
      boleto_conversion: {
        conversion: 66.66666666666667,
        paid: {
          amount: 508,
          quantity: 4,
        },
        pending: {
          amount: 254,
          quantity: 2,
        },
      },
      roi: {
        amount: -0.011395461636221871,
      },
      cpa: {
        amount: 62.857333333333344,
      },
    },
    forecast_report: {
      net_revenue: {
        amount: 1692.4569182108844,
      },
      cogs: {
        amount: 703.6070866261637,
      },
      cpa: {
        amount: 72.11953796108567,
      },
      net_profit: {
        amount: -67.94827502153373,
      },
      roi: {
        amount: 0.027932433000504162,
      },
    },
  },
];

export const generateDataMock = (): any => {
  const mappedMockedData = mockedData.map((data, index) => {
    return {
      ...data,
      name: `campanha teste - ${index}`,
    };
  });

  return {
    status: 'success',
    ad_sense_campaigns_report: mappedMockedData,
    summary_report: {
      ad_sense_campaign: {
        total_quantity: 26,
      },
      marketing_report: {
        cpa: {
          average_amount: 0,
        },
        cpc: {
          average_amount: 1.1394857486953025,
        },
        cpm: {
          average_amount: 20.270001606769846,
        },
        ctr: {
          average_percentage: 1.7788727616803242,
        },
        daily_budget: {
          total_amount: 680,
        },
        impressions: {
          total_quantity: 1400325,
        },
        roas: {
          average_amount: 3.0589295163934422,
        },
        spend: {
          total_amount: 28384.589999999986,
        },
      },
      financial_report: {
        boleto: {
          total_amount: 4825.810000000001,
          total_cpa_amount: 746.9628947368417,
        },
        card: {
          total_amount: 33461.88000000002,
          total_cpa_amount: 102.10284172661865,
        },
        cogs: {
          total_amount: 26326.86372466782,
        },
        net_profit: {
          total_amount: 4336.076275332434,
        },
        net_revenue: {
          total_amount: 59047.53000000024,
        },
        orders: {
          total_paid_quantity: 485,
          total_pending_quantity: 65,
        },
        pix: {
          total_amount: 20759.84,
          total_cpa_amount: 167.95615384615377,
        },
        pix_conversion: {
          conversion: 68.69918699186992,
          paid: {
            total_amount: 20759.84,
            total_quantity: 169,
          },
          pending: {
            total_amount: 240.45,
            total_quantity: 1,
          },
        },
        boleto_conversion: {
          conversion: 37.254901960784316,
          paid: {
            total_amount: 4825.810000000001,
            total_quantity: 38,
          },
          pending: {
            total_amount: 8255.69999999999,
            total_quantity: 64,
          },
        },
        roi: {
          average_amount: 0.07925353797311768,
        },
        cpa: {
          average_amount: 1091.7149999999995,
        },
      },
      forecast_report: {
        net_revenue: {
          total_amount: 58482.672382736855,
        },
        cogs: {
          total_amount: 25830.257267947934,
        },
        cpa: {
          total_amount: 59.14115785637212,
        },
        net_profit: {
          total_amount: 3734.465325753383,
        },
        roi: {
          total_amount: 0.0787205964760155,
        },
      },
    },
    last_sync: '2022-08-31T14:08:14.587Z',
  };
};
