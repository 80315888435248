import React from 'react';
import {
  LineChart as LineChartIcon,
  HorizontalBarChartCircle as BarChartIcon,
} from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { hasChartCurrency } from '@helpers/dashboard/financeDashboard/chartCurrency';
import { getChartName } from '@helpers/dashboard/chart/chartUtils';
import { getEnvironment } from '@helpers/utils/environment';
import { useLocalStorage } from '@helpers/hooks/useLocalStorage';
import { numberFormatter } from '@helpers/masks';
import { ESelectedChart } from '@domain/enums/dashboard/main/ESelectedChart';
import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { IChartProps } from '@domain/interfaces/dashboard/FinancialDashboard/IChart';
import { useMediaQuery } from '@helpers/hooks/useMediaQuery';

import ComponentError from '@components/common/core/Utils/ComponentError';
import GroupedBarChart from '@components/common/core/DataDisplay/Charts/GroupedBarChart';
import LineChart from '@components/common/core/DataDisplay/Charts/LineChart';

import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Chart: React.FC<IChartProps> = ({ isNoRetroactiveDashboard, ...rest }) => {
  const {
    isLoadingChart,
    chartData,
    lineChartConfig,
    barChartConfig,
    isChartError,
    loadChart,
    selectedChart,
    chartResponseData,
  } = useFinancialDashboard();
  const theme: any = useTheme();

  const [chartType, setChartType] = useLocalStorage(`@profitfy:${getEnvironment()}/chart`, 'bar');

  const handleChartType = React.useCallback(type => setChartType(type), [setChartType]);

  const isMobileWidth = useMediaQuery('1435px');

  const getSkeletonLoadingHeight = (): number => {
    if (isNoRetroactiveDashboard && isMobileWidth) {
      return 300;
    }

    if (isNoRetroactiveDashboard && !isMobileWidth) {
      return 277;
    }

    return 300;
  };

  if (isLoadingChart) {
    return <SkeletonLoading height={getSkeletonLoadingHeight()} />;
  }

  if (isChartError) {
    return (
      <S.ErrorWrapper>
        <ComponentError mutate={loadChart} />
      </S.ErrorWrapper>
    );
  }

  return (
    <S.ChartContainer {...rest}>
      <S.ChartHeader>
        <S.Title fontWeight={EHeadingWeight.REGULAR}>{getChartName(selectedChart)}</S.Title>

        <S.ChartButtonsWrapper>
          <S.ChartButton isSelected={chartType === 'line'} onClick={() => handleChartType('line')}>
            <LineChartIcon size={20} outline color={chartType !== 'line' && theme.colors.font} />
          </S.ChartButton>
          <S.ChartButton isSelected={chartType === 'bar'} onClick={() => handleChartType('bar')}>
            <BarChartIcon size={20} outline color={chartType !== 'bar' && theme.colors.font} />
          </S.ChartButton>
        </S.ChartButtonsWrapper>
      </S.ChartHeader>

      <S.ChartAndInfosWrapper>
        <S.Chart id="chart-wrapper">
          {chartType === 'line' ? (
            <LineChart
              data={chartData}
              initialConfig={lineChartConfig}
              currency={hasChartCurrency(selectedChart)}
            />
          ) : (
            <GroupedBarChart
              data={chartData}
              initialConfig={barChartConfig}
              currency={hasChartCurrency(selectedChart)}
            />
          )}
        </S.Chart>

        {selectedChart === ESelectedChart.REVENUE && (
          <S.GrossRevenueAndNetRevenueWrapper>
            <S.GrossRevenueWrapper>
              <S.GrossRevenueValue>{`R$ ${numberFormatter(
                chartResponseData?.gross_revenue_amount || 0,
                2,
              )}`}</S.GrossRevenueValue>
              <S.Description>Receita Bruta</S.Description>
            </S.GrossRevenueWrapper>

            <S.NetRevenueWrapper>
              <S.NetRevenueValue>{`R$ ${numberFormatter(
                chartResponseData?.net_revenue_amount_by_approved_at || 0,
                2,
              )}`}</S.NetRevenueValue>
              <S.Description>
                Receita total pela data <br /> de aprovação do pedido.
              </S.Description>
            </S.NetRevenueWrapper>
          </S.GrossRevenueAndNetRevenueWrapper>
        )}

        {selectedChart === ESelectedChart.COGS && (
          <S.PendingAndPercentageShippingsWrapper>
            <S.PendingShippingsWrapper>
              <S.PendingShippingsValue>{`R$ ${numberFormatter(
                chartResponseData?.shipping_pending?.amount || 0,
                2,
              )}(${chartResponseData?.shipping_pending?.quantity || 0})`}</S.PendingShippingsValue>
              <S.Description>Pendente de envio</S.Description>
            </S.PendingShippingsWrapper>

            <S.PercentageWrapper>
              <S.PercentageShippings>{`${numberFormatter(
                chartResponseData?.shipping_delivered?.percentage || 0,
                0,
              )}%`}</S.PercentageShippings>
              <S.Description>
                Foram enviados <br /> nesse periodo.
              </S.Description>
            </S.PercentageWrapper>
          </S.PendingAndPercentageShippingsWrapper>
        )}
      </S.ChartAndInfosWrapper>
    </S.ChartContainer>
  );
};

export default Chart;
