export default {
  background: {
    1: '#F8F9FE',
    2: '#FFFFFF',
    3: '#D9DDE9',
    4: '#FFFFFF',
    5: '#D9DDE9',
    6: '#515562',
    7: '#838BA1',
    8: '#FFFFFF',
    9: '#FFFFFF',
    10: '#D9DDE9',
    11: '#D9DDE9',
    12: '#FBFAFC',
    13: '#D9DDE9',
    14: 'transparent',
    15: '#838BA1',
    input: '#FBFAFC',
  },
  sideBarColor: '#FBFAFC',
  sideBarSubMenuColor: '#EDEBF0',
  inputBorderColor: '#D9DDE9',
  inputBorderColorHover: '#2EB3B4',
  checkboxStrokeColor: '#fff',
  popoverTitleColor: '#2EB3B4',
  chartTextAxis: '#838BA1',
  sideModal: '#FFFFFF',
  sideModalExitIcon: '#FFFFFF',
  card: '#D9DDE9',
  font: '#2E323E',
  filterFont: '#838BA1',
  green: {
    default: '#25CFBB',
    light: '#6FD5C3',
    dark: '#2EB3B4',
  },
  white: {
    default: '#F8F9FE',
    light: '#FBFAFC',
    dark: '#EDEBF0',
  },
  black: {
    default: '#17151A',
    light: '#232126',
    dark: '#0B0B0D',
  },
  gray: {
    1: '#D9DDE9',
    2: '#A7AFC6',
    3: '#838BA1',
    4: '#515562',
    5: '#2E323E',
    6: '#0F1116',
    7: '#0F1116',
    8: '#0F1116',
  },
  danger: {
    default: '#D0425C',
    light: '#D0425C',
    dark: '#D0425C',
    input: {
      defaultBackground: '#fff',
      activeBackground: '#F3D7DB',
    },
  },
  success: {
    default: '#59B578',
    light: '#50E170',
    dark: '#28BB49',
    input: {
      defaultBackground: '#fff',
      activeBackground: '#D0F2DC',
    },
  },
  warning: {
    default: '#F6A609',
    light: '#FFBC1F',
    dark: '#E89806',
    input: {
      defaultBackground: '#fff',
      activeBackground: '#F6E4C6',
    },
  },
  info: {
    default: '#0063F7',
    light: '#5B8DEF',
    dark: '#004FC4',
  },
  purple: {
    default: '#804DFA',
    light: '#A37CFF',
    dark: '#6727FE',
  },
};
