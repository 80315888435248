import React from 'react';
import { CreditCard } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToast } from '@helpers/hooks/useToast';
import { useParams } from 'react-router-dom';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { EStatus } from '@domain/enums/subscription/ESubscription';
import { EAlertVariant } from '@domain/enums/components/EAlert';
import { ESubscriptionPlanChangeStatus } from '@domain/enums/common/subscription/ESubscriptionPlanChangeStatus';
import { ESubscriptionRecurrence } from '@domain/enums/common/subscription/ESubscriptionRecurrence';
import { ICardTokenProps } from '@domain/interfaces/common/payment/IPayment';
import { IParams } from '@domain/interfaces/IParams';

import { useIugu } from '@helpers/hooks/useIugu';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { useSubscriptionStatus } from '@helpers/hooks/common/store/subscription/useSubscriptionStatus';
import { useSubscription } from '@helpers/hooks/common/store/subscription/useSubscription';
import { useConfig } from '@helpers/hooks/useConfig';
import { useTransaction } from '@helpers/hooks/common/store/subscription/useTransaction';

import { getCardIssuer } from '@helpers/subscription/payment';
import { checkoutErrorMapper } from '@helpers/errors/subscription/checkoutMapper';
import {
  cardNumberFormatter,
  cvvFormatter,
  expirationDateFormatter,
  numberFormatter,
} from '@helpers/masks';

import { paymentSchema } from '@helpers/validators/subscription/checkout/payment';

import { ALL_PLANS } from '@constants/plans';

import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const PaymentInfoModal: React.FC<any> = ({ isOpen, toggle }) => {
  const theme = useTheme();
  const iugu = useIugu('32100AB23A5B464C964437C238C1844D');
  const { toast } = useToast();
  const { subscription, mutateSubscription } = useStoreSubscription();
  const { createSubscription, updateSubscription } = useSubscription();
  const { storeAliasId } = useParams<IParams>();
  const { analytics, user } = useConfig();
  const { mutateTransactions } = useTransaction();
  const {
    subscriptionPlanChange,
    subscriptionPlanChangePlan,
    reset,
    paymentMethod,
  } = useSubscriptionStatus();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ mode: 'onBlur', reValidateMode: 'onChange', resolver: yupResolver(paymentSchema) });

  const isProcessingInCreateFailed =
    subscription?.status === EStatus.PROCESSING_PAYMENT_IN_CREATE_FAILED;
  const isProcessingInUpdateFailed =
    subscriptionPlanChange?.status === ESubscriptionPlanChangeStatus.FAILED;
  const recurrenceType = isProcessingInUpdateFailed
    ? subscriptionPlanChange.recurrence
    : subscription.recurrence;

  const storePlan = isProcessingInCreateFailed ? subscription?.plan : subscriptionPlanChangePlan;

  const selectedPlan = ALL_PLANS.find(
    plan =>
      plan.identifier === storePlan?.identifier ||
      plan.quarterIdentifier === storePlan?.identifier ||
      plan.semiAnualIdentifier === storePlan?.identifier,
  );

  const formRef = React.useRef<HTMLFormElement>(null);

  const numberRegister = register('number');
  const fullNameRegister = register('full_name');
  const expirationRegister = register('expiration');
  const verificationValueRegister = register('verification_value');
  const installmentRegister = register('installment');

  const [isUpdatingPaymentInfo, setIsUpdatingPaymentInfo] = React.useState<boolean>(false);

  const getPlanIdentifier = React.useCallback((): string => {
    if (recurrenceType === ESubscriptionRecurrence.MONTHLY) return selectedPlan?.identifier || '';

    if (recurrenceType === ESubscriptionRecurrence.QUARTERLY)
      return selectedPlan?.quarterIdentifier || '';

    return selectedPlan?.semiAnualIdentifier || '';
  }, [selectedPlan, recurrenceType]);

  const trackTransactionError = React.useCallback(
    (providerError: string) => {
      analytics?.track(
        'Transaction Not Approved',
        {
          declined_reason: providerError,
          plan_name: selectedPlan?.name,
          plan_price: (selectedPlan as typeof ALL_PLANS[number])?.monthlyPrice,
          email: user?.email,
        },
        { context: { groupId: storeAliasId } },
      );
    },
    [storeAliasId, analytics, selectedPlan, user],
  );

  const subscribe = React.useCallback(
    async (cardToken: ICardTokenProps, installments: number) => {
      if (!isProcessingInCreateFailed && !isProcessingInUpdateFailed) return;

      try {
        if (isProcessingInCreateFailed) {
          const planIdentifier = getPlanIdentifier();

          await createSubscription(
            storeAliasId,
            cardToken,
            planIdentifier,
            installments,
            undefined,
          );
        } else {
          const planIdentifier = getPlanIdentifier();

          await updateSubscription(
            storeAliasId,
            subscription.alias_id,
            cardToken,
            subscription.plan?.identifier,
            planIdentifier,
            installments,
            undefined,
          );
        }

        toggle();
        reset();

        mutateSubscription();
        mutateTransactions();
      } catch (error: any) {
        const message = checkoutErrorMapper(error, trackTransactionError);
        toast.error(message);
        throw new Error();
      }
    },
    [
      createSubscription,
      storeAliasId,
      subscription,
      updateSubscription,
      isProcessingInCreateFailed,
      isProcessingInUpdateFailed,
      toast,
      trackTransactionError,
      toggle,
      reset,
      getPlanIdentifier,
      mutateTransactions,
      mutateSubscription,
    ],
  );

  const onSubmit = React.useCallback(
    async formData => {
      analytics?.track(
        'Checkout Update Card Details Button Clicked',
        {
          email: user?.email,
        },
        {
          context: { groupId: storeAliasId },
        },
      );

      setIsUpdatingPaymentInfo(true);

      try {
        await iugu.setTestMode(true);
        const createdCardToken = await iugu.createPaymentToken(formRef.current);
        await subscribe(createdCardToken, formData.installments);

        setIsUpdatingPaymentInfo(false);
      } catch (error: any) {
        if (error?.errors?.adblock) {
          toast.error(
            'Desabilite o AdBlock e depois recarregue a página para poder continuar essa etapa.',
          );
        } else if (error?.errors?.record_invalid === 'Validation failed: Number is invalid') {
          toast.error('Número do cartão inválido.');
        } else if (error?.errors?.verification_value === 'is_invalid') {
          toast.error('CVV / CID inválidos');
        } else if (error?.errors?.last_name === 'is_invalid') {
          toast.error('Sobrenome do cartão inválido.');
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          setIsUpdatingPaymentInfo(false);
          throw error;
        }
      }
    },
    [iugu, toast, subscribe, analytics, storeAliasId, user],
  );

  const onNumberChange = React.useCallback(
    event => {
      const formattedNumber = cardNumberFormatter(event.target.value);
      event.target.value = formattedNumber;
      numberRegister.onChange(event);
    },
    [numberRegister],
  );

  const onExpirationChange = React.useCallback(
    event => {
      const formattedExpiration = expirationDateFormatter(event.target.value);
      event.target.value = formattedExpiration;
      expirationRegister.onChange(event);
    },
    [expirationRegister],
  );

  const onVerificationValueChange = React.useCallback(
    event => {
      const number = getValues('number');
      const cardIssuer = getCardIssuer(number);
      let formattedVerificationValue = '';

      if (cardIssuer === 'American Express') {
        formattedVerificationValue = cvvFormatter(event.target.value, 4);
      } else {
        formattedVerificationValue = cvvFormatter(event.target.value);
      }

      event.target.value = formattedVerificationValue;
      verificationValueRegister.onChange(event);
    },
    [verificationValueRegister, getValues],
  );

  const getInstallmentValue = React.useCallback(
    (installment: number) => {
      if (recurrenceType === ESubscriptionRecurrence.QUARTERLY)
        return (selectedPlan?.quarterPrice || 0) / installment;

      return (selectedPlan?.semesterPrice || 0) / installment;
    },
    [recurrenceType, selectedPlan],
  );

  const getCardLastFourDigits = React.useCallback(() => {
    if (!paymentMethod) return 'XXXX';

    const splittedDigits = paymentMethod.display_number.split('-');

    return splittedDigits.pop();
  }, [paymentMethod]);

  const isNumberError = Boolean(errors.number);
  const isFullNameError = Boolean(errors.full_name);
  const isExpirationError = Boolean(errors.expiration);
  const isVerificationValue = Boolean(errors.verification_value);

  const maxInstallments = recurrenceType === ESubscriptionRecurrence.QUARTERLY ? 3 : 6;

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Header>
        <CreditCard size={36} color={theme.colors.green.default} weight="fill" />
        <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
          Atualize seu cartão de crédito
        </S.Title>
      </S.Header>

      <S.Body>
        <S.Description>
          {`O cartão com final **** **** **** ${getCardLastFourDigits()} foi recusado, por favor atualize os dados de seu
          cartão ou adicione um novo.`}
        </S.Description>

        <Form innerRef={formRef} onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>Número do Cartão</S.Label>
            <S.Input
              {...numberRegister}
              type="text"
              placeholder="4111 1111 1111 1111"
              onChange={onNumberChange}
              data-iugu="number"
              data-openreplay-obscured
              isError={isNumberError}
            />
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Nome (Impresso no Cartão)</S.Label>
            <S.Input
              {...fullNameRegister}
              type="text"
              placeholder="YUNG BUDA"
              data-iugu="full_name"
              data-openreplay-obscured
              isError={isFullNameError}
            />
            {isFullNameError && <Text isErrorFeedback>{errors.full_name.message}</Text>}
          </S.InputGroup>

          <S.ExpirationDateAndSecurityCodeWrapper>
            <S.InputGroup>
              <S.Label>Validade</S.Label>
              <S.Input
                {...expirationRegister}
                type="text"
                placeholder="MM/AA"
                onChange={onExpirationChange}
                data-iugu="expiration"
                isError={isExpirationError}
              />
              {isExpirationError && <Text isErrorFeedback>{errors.expiration.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Cód. Segurança (CVV)</S.Label>
              <S.Input
                {...verificationValueRegister}
                type="text"
                placeholder="123"
                onChange={onVerificationValueChange}
                data-iugu="verification_value"
                data-openreplay-obscured
                isError={isVerificationValue}
              />
              {isVerificationValue && (
                <Text isErrorFeedback>{errors.verification_value.message}</Text>
              )}
            </S.InputGroup>
          </S.ExpirationDateAndSecurityCodeWrapper>

          <S.InputGroup>
            <S.Label>Defina quantas parcelas</S.Label>
            <S.Select {...installmentRegister} defaultValue="1">
              {Array.from(Array(maxInstallments).keys()).map(installment => (
                <S.Option value={installment + 1} key={installment}>{`Em ${
                  installment + 1
                }x de R$${numberFormatter(
                  getInstallmentValue(installment + 1),
                  2,
                )} sem juros`}</S.Option>
              ))}
            </S.Select>
          </S.InputGroup>

          <S.InstallmentAlert
            variant={EAlertVariant.WARNING}
            description="Caso você utilize Conta Simples ou um cartão pré-pago não será possível realizar o
              parcelamento."
          />

          <S.UpdateInfoButton isLoading={isUpdatingPaymentInfo}>
            Atualizar dados de cartão
          </S.UpdateInfoButton>
        </Form>
      </S.Body>
    </S.Modal>
  );
};

export default PaymentInfoModal;
