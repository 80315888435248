import React from 'react';

import { EBadgeColors } from '@domain/enums/components/EBadge';
import { EPlanRecurrence } from '@domain/enums/subscription/ESubscription';
import { IRecurrenceTypeProps } from '@domain/interfaces/pricing/plans/IRecurrenceType';

import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const RecurrenceType: React.FC<IRecurrenceTypeProps> = ({
  selectedRecurrenceType,
  handleSelectedRecurrenceType,
}) => {
  const isMonthlyType = selectedRecurrenceType === EPlanRecurrence.MONTHLY;
  const isQuarterType = selectedRecurrenceType === EPlanRecurrence.QUARTER;
  const isSemiAnualType = selectedRecurrenceType === EPlanRecurrence.SEMIANUAL;

  const { analytics, user, selectedStore } = useConfig();

  const handleOptionClick = React.useCallback(
    (planRecurrence: EPlanRecurrence) => {
      handleSelectedRecurrenceType(planRecurrence);

      if (planRecurrence === EPlanRecurrence.MONTHLY) {
        analytics?.track(
          'Subscription Plans Page Monthly Button Clicked',
          {
            email: user?.email,
          },
          { context: { groupId: selectedStore?.alias_id } },
        );
      }

      if (planRecurrence === EPlanRecurrence.QUARTER) {
        analytics?.track(
          'Subscription Plans Page Quarterly Button Clicked',
          {
            email: user?.email,
          },
          { context: { groupId: selectedStore?.alias_id } },
        );
      }

      if (planRecurrence === EPlanRecurrence.SEMIANUAL) {
        analytics?.track(
          'Subscription Plans Page Semiannual Button Clicked',
          {
            email: user?.email,
          },
          { context: { groupId: selectedStore?.alias_id } },
        );
      }
    },
    [handleSelectedRecurrenceType, analytics, user, selectedStore],
  );

  return (
    <S.Wrapper>
      <S.Option
        isSelected={isMonthlyType}
        onClick={() => handleOptionClick(EPlanRecurrence.MONTHLY)}
      >
        <S.OptionText>Mensal</S.OptionText>
      </S.Option>

      <S.Option
        isSelected={isQuarterType}
        onClick={() => handleOptionClick(EPlanRecurrence.QUARTER)}
      >
        <S.OptionText>
          Trimestral <S.DiscountBadge color={EBadgeColors.SUCCESS}>15% OFF</S.DiscountBadge>
        </S.OptionText>
      </S.Option>

      <S.Option
        isSelected={isSemiAnualType}
        onClick={() => handleOptionClick(EPlanRecurrence.SEMIANUAL)}
      >
        <S.OptionText>
          Semestral <S.DiscountBadge color={EBadgeColors.SUCCESS}>25% OFF</S.DiscountBadge>
        </S.OptionText>
      </S.Option>
    </S.Wrapper>
  );
};

export default RecurrenceType;
