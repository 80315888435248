import styled, { css } from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import TextBase from '@components/common/core/DataDisplay/Text';
import {
  ICustomSpendsRowProps,
  IDataTextProps,
  IPercentageProps,
} from '@domain/interfaces/dashboard/FinancialDetails/IPeriodTable';
import CustomHoverCard from '@components/common/core/Utils/CustomHoverCard';
import LinkBase from '@components/common/core/Navigation/Link';

export const Link = styled(LinkBase)``;

export const ColorBullet = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 12px;
  margin-right: 8px;

  ${({ color }) => css`
    background-color: ${color};
  `}
`;

export const CustomSpendRow = styled(TableBase.Row)<ICustomSpendsRowProps>`
  cursor: pointer;

  ${({ isExpanded, theme }) =>
    isExpanded &&
    css`
      background-color: ${theme.colors.black.default};
    `}
`;

export const CustomSpendsRow = styled(TableBase.Row)<ICustomSpendsRowProps>`
  transition: all 0.3s;
  cursor: pointer;

  ${({ isExpanded, theme }) =>
    isExpanded &&
    css`
      background-color: ${theme.colors.black.default};
    `}
`;

export const CustomSpendsIconWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HoverCardText = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
`;

export const HoverCardContentWrapper = styled.div`
  max-width: 248px;
  width: 100%;
`;

export const HoverCardContent = styled(CustomHoverCard.Content)`
  background-color: ${({ theme }) => theme.colors.black.light};
  padding: 16px;
`;

export const HoverCardArrow = styled(CustomHoverCard.Arrow)`
  fill: ${({ theme }) => theme.colors.black.light};
`;

export const HoverCard = styled(CustomHoverCard)``;

export const PercentageArrow = styled.div<IPercentageProps>`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid ${({ theme }) => theme.colors.danger.default};
  margin-left: 8px;

  ${({ isPositive, theme }) =>
    isPositive &&
    css`
      border-top: 0;
      border-bottom: 6px solid ${theme.colors.success.default};
    `};
`;

export const DataText = styled(TextBase)<IDataTextProps>`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[1]};
  line-height: 120%;
  display: flex;
  align-items: center;

  & > a {
    margin-left: 8px;
    margin-top: -2px;
    display: flex;
    align-items: center;
  }

  ${({ highlight, theme }) =>
    highlight &&
    css`
      font-size: 1rem;
      color: ${theme.colors.white.default};
      font-weight: 700;
    `}

  ${({ right }) =>
    right &&
    css`
      justify-content: flex-end;
    `}

    ${({ isPositive, theme }) =>
    isPositive &&
    css`
      font-size: 1rem;
      color: ${theme.colors.success.default};
      font-weight: 700;
    `}

    ${({ isNegative, theme }) =>
    isNegative &&
    css`
      font-size: 1rem;
      color: ${theme.colors.danger.default};
      font-weight: 700;
    `}
`;

export const Table = styled(TableBase)`
  transition: all 0.3s;

  & > table > thead > tr {
    & > th {
      padding: 8px;
      font-size: 1rem;
    }

    & > th:first-child {
      padding-left: 16px;
    }

    & > th:last-child {
      padding-right: 72px;
    }
  }

  & > table > tbody > tr {
    & > td {
      padding: 8px;
      padding-top: 8px !important;
    }

    & > td:first-child {
      padding-left: 16px;
      width: 24px;
    }

    & > td:last-child {
      padding-right: 72px;
      width: 124px;
    }

    & > td:nth-child(3) {
      width: 200px;
    }

    & > td:nth-child(4) {
      width: 200px;
    }

    & > td:nth-child(5) {
      width: 124px;
    }
  }

  & > table > tbody > tr:last-child {
    & > td {
      padding: 16px 8px;
      padding-top: 16px !important;
    }

    & > td:first-child {
      padding-left: 16px;
    }

    & > td:last-child {
      padding-right: 72px;
    }
  }
`;
