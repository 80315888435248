import React from 'react';

import { useTheme } from 'styled-components/macro';
import { IAvatar } from '@domain/interfaces/components/IAvatar';
import { EHeadingSize } from '@domain/enums/components/EHeading';
import { EAvatarSize } from '@domain/enums/components/EAvatar';
import { BarChart } from '@profitfy/pakkun-icons';

import * as S from './styles';

const Avatar: React.FC<IAvatar> = ({
  word,
  size = EAvatarSize.DEFAULT,
  image,
  alt,
  icon,
  isHiddenContent,
  ...rest
}) => {
  const theme: any = useTheme();

  const getAvatarWordSize = React.useCallback(() => {
    if (size === EAvatarSize.DEFAULT) {
      return EHeadingSize.H3;
    }

    return EHeadingSize.H5;
  }, [size]);

  const getIconSize = React.useCallback(() => {
    if (size === EAvatarSize.MEDIUM) {
      return 21;
    }

    if (size === EAvatarSize.SMALL) {
      return 16;
    }

    return 24;
  }, [size]);

  if (image) {
    return (
      <S.AvatarImg
        size={size}
        word={word}
        src={image}
        alt={alt || ''}
        isHiddenContent={isHiddenContent}
        {...rest}
      />
    );
  }

  if (icon) {
    return (
      <S.Avatar word={word} size={size} {...rest} isHiddenContent={isHiddenContent}>
        <BarChart size={getIconSize()} color={theme.colors.font} />
      </S.Avatar>
    );
  }

  return (
    <S.Avatar word={word} size={size} {...rest} isHiddenContent={isHiddenContent}>
      <S.AvatarWord as={getAvatarWordSize()}>{word}</S.AvatarWord>
    </S.Avatar>
  );
};

export default React.memo(Avatar);
