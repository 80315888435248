import React from 'react';
import { Cancel } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useHistory } from 'react-router-dom';

import { IFeatureModalProps } from '@domain/interfaces/IFeatureModal';

import * as S from './styles';

const FeatureModal: React.FC<IFeatureModalProps> = ({
  isOpen,
  toggle,
  src,
  url,
  externalUrl,
  handleManualClose,
}) => {
  const history = useHistory();
  const theme = useTheme();

  const [isImageLoaded, setIsImageLoaded] = React.useState<boolean>(false);

  // const onModalClick = React.useCallback(() => {
  //   history.push(url);

  //   toggle();
  // }, [history, url, toggle]);

  const onLinkClick = React.useCallback(() => {
    if (url) {
      history.push(url);
    }

    if (externalUrl) {
      window.open(externalUrl, '_blank');
    }

    toggle();
  }, [toggle, url, externalUrl, history]);

  return (
    <S.Modal isOpen={isOpen} toggle={handleManualClose}>
      <S.ModalContent onClick={onLinkClick}>
        <S.Image
          src={src}
          alt="Profitfy ProfitClass"
          onLoad={() => setIsImageLoaded(true)}
          onError={toggle}
        />
      </S.ModalContent>

      {isImageLoaded && (
        <S.CloseButton onClick={handleManualClose}>
          <Cancel size={24} color={theme.colors.gray[3]} />
        </S.CloseButton>
      )}
    </S.Modal>
  );
};

export default FeatureModal;
