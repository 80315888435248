import { yupResolver } from '@hookform/resolvers/yup';
import { Info } from 'phosphor-react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ECurrency } from '@domain/enums/common/ECurrency';
import {
  ESynchronizationRequestType,
  ESynchronizationType,
} from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';

import { currencyFormatter, currencyToNumber } from '@helpers/masks';
import { getEnvironment } from '@helpers/utils/environment';
import {
  productCostFixedSchema,
  productCostPercentageSchema,
} from '@helpers/validators/dashboard/productCost/productCostCogsEdit';

import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useToast } from '@helpers/hooks/useToast';

import { LONG_API_DOMAIN } from '@constants/api';

import Text from '@components/common/core/DataDisplay/Text';
import Form from '@components/common/core/Inputs/Form';

import * as S from './styles';

export interface INameModeProps {
  toggle: () => void;
}

const NameMode: React.FC<INameModeProps> = ({ toggle }) => {
  const { colors } = useTheme();
  const { toast } = useToast();
  const { addSynchronization } = useSynchronization();
  const { storeAliasId } = useParams<IParams>();

  const [name, setName] = React.useState<string>('');
  const [currency, setCurrency] = React.useState<ECurrency>(ECurrency.BRL);
  const [chargeType, setChargeType] = React.useState<'percentage' | 'fixed'>('fixed');
  const [isInputChecked, setIsInputChecked] = React.useState<boolean>(false);

  const onCheckChange = React.useCallback(event => {
    if (event && !event.target) {
      setIsInputChecked(event.checked);
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(
      chargeType === 'percentage' ? productCostPercentageSchema : productCostFixedSchema,
    ),
  });

  const costInput = register('cost');

  const onSubmit = React.useCallback(
    async dataForm => {
      if (!name) {
        toast.error('Digite o nome de um produto');
        return;
      }

      const data = {
        product_ids: undefined,
        product_name: name,
        is_percentage: chargeType === 'percentage',
        is_all_products: false,
        cost:
          chargeType === 'percentage'
            ? currencyToNumber(dataForm.cost) / 100
            : currencyToNumber(dataForm.cost),
        currency,
        is_using_remessa_conforme: isInputChecked,
      };

      addSynchronization({
        name: 'Custo dos Produtos',
        type: ESynchronizationType.STORE_PRODUCT,
        label: 'Custo',
        dependencies: [],
        storeAliasId,
        config: {
          isOnboardingSynchronization: false,
          showNotification: true,
        },
        request: {
          type: ESynchronizationRequestType.POST,
          domain: LONG_API_DOMAIN,
          api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/products/costs`,
          requestBody: {
            ...data,
          },
        },
      });

      toggle();
    },
    [name, chargeType, currency, isInputChecked, addSynchronization, storeAliasId, toggle, toast],
  );

  const handleCurrency = React.useCallback(e => {
    setCurrency(e.target.value);
  }, []);

  const handleChargeType = React.useCallback(e => {
    setChargeType(e.target.value);
  }, []);

  const onCostChange = React.useCallback(
    event => {
      costInput.onChange(event);

      const { value } = event.target;

      event.target.value = currencyFormatter(value);
    },
    [costInput],
  );

  const handleName = React.useCallback(e => {
    setName(e.target.value);
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <S.Title>Edite o custo de produtos específicos</S.Title>

        <S.Row>
          <S.Text>Informe qual nome em comum dos produtos</S.Text>
        </S.Row>

        <S.InputNameWrapper>
          <S.Input type="text" onChange={handleName} placeholder="Informe um nome" />
          <S.WarningMessage>
            Todos os produtos que contenham esse nome sofrerão alterações
          </S.WarningMessage>
        </S.InputNameWrapper>

        <S.CheckboxGroup>
          <S.Checkbox id="input-checkbox" checked={isInputChecked} onChange={onCheckChange} />
          <S.CheckboxLabel htmlFor="input-checkbox">Remessa conforme</S.CheckboxLabel>
        </S.CheckboxGroup>

        <S.Text>Selecione a moeda</S.Text>

        <S.CurrencySelect name="currency" onChange={handleCurrency} defaultValue={currency}>
          <S.Option value={ECurrency.BRL}>BRL</S.Option>
          <S.Option value={ECurrency.USD}>USD</S.Option>
        </S.CurrencySelect>

        <S.Text>Selecione o tipo de custo a ser aplicado</S.Text>

        <S.RadioWrapper>
          <S.RadioLabel>
            <S.Radio
              name="charge-type"
              value="fixed"
              defaultChecked
              onChange={handleChargeType}
              id="fixed-option"
            />

            <S.RadioText>Valor fixo</S.RadioText>
          </S.RadioLabel>
          <S.RadioLabel>
            <S.Radio
              name="charge-type"
              value="percentage"
              onChange={handleChargeType}
              id="percentage-option"
            />
            <S.RadioText>Valor em porcentual</S.RadioText>
            <S.HoverCard>
              <S.HoverCardTrigger>
                <Info size={16} color={colors.gray[2]} />
                <S.HoverCardContent side="top" sideOffset={5}>
                  <S.HoverCardContentWrapper>
                    <S.HoverCardText>
                      O valor percentual será definido em cima do valor de venda. Se o percentual
                      for definido como 50% e o valor da compra foi R$100, o custo será de R$50.
                    </S.HoverCardText>
                  </S.HoverCardContentWrapper>
                  <S.HoverCardArrow />
                </S.HoverCardContent>
              </S.HoverCardTrigger>
            </S.HoverCard>
          </S.RadioLabel>
        </S.RadioWrapper>

        <S.Text>Defina o custo a ser aplicado</S.Text>

        <S.Row>
          <S.Input
            {...costInput}
            type="text"
            onChange={onCostChange}
            placeholder="Define o custo"
            prependText={chargeType === 'fixed' ? 'R$' : '%'}
            prependIconColor={colors.gray[3]}
          />
        </S.Row>
        {errors.cost && <Text isErrorFeedback>{errors.cost.message}</Text>}

        <S.ConfirmButton type="submit">Editar custo dos produtos</S.ConfirmButton>
      </S.Wrapper>
    </Form>
  );
};

export default NameMode;
