import React from 'react';
import { getBezierPath, getEdgeCenter } from 'react-flow-renderer';
import { useTheme } from 'styled-components';
import { X as Close } from 'phosphor-react';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';

import * as S from './styles';

const foreignObjectSize = 40;

const ButtonEdge: React.FC<any> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}) => {
  const { colors } = useTheme();
  const { handleDeleteEdges } = useAutomation();
  const onEdgeClick = React.useCallback(
    (evt, edgeId) => {
      evt.stopPropagation();
      handleDeleteEdges(edgeId);
    },
    [handleDeleteEdges],
  );

  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <S.Wrapper>
          <S.Button type="button" onClick={event => onEdgeClick(event, id)}>
            <Close size={14} color={colors.white.default} weight="bold" />
          </S.Button>
        </S.Wrapper>
      </foreignObject>
    </>
  );
};

export default ButtonEdge;
