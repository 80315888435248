import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';
import { Wallet } from 'phosphor-react';

export const PIX_FREE = [
  {
    id: 'pix-free-first-condition',
    title: 'Recuperação de Pix',
    type: 'PIX',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Wallet,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá {{first_name}}!\n\nParabéns pelo seu pedido 🎉\n\nEle já foi separado e está pronto para ser enviado! 🚚\n\n📌 Detalhes do Pedido: #{{order_id}}\n{{products_with_variant}}\n💵 Valor total: {{total_amount}}\n\n🕗 Realize o pagamento do Pix dentro de 30 minutos para que o pedido não seja cancelado.📱',
      },
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content: '{{pix_code}}',
      },
    ],
  },
];
