import { EInfoProductProvider } from '@domain/enums/common/integrations/EInfoProductProvider';
import { IStoreIntegrations } from '@domain/interfaces/stores/IStores';

export const getInfoProductProviderIntegrationStatus = (
  storeIntegrations: IStoreIntegrations,
  identifier: EInfoProductProvider,
): boolean => {
  const foundStoreFrontIntegration = storeIntegrations.store_front?.find(
    integration => integration.name === identifier,
  );

  return Boolean(foundStoreFrontIntegration?.is_active);
};
