import React from 'react';

import TermsOfUsageContent from '@components/common/core/Utils/TermsOfUsageModal/TermsOfUsageContent';
import logo from '@assets/img/brand/profitfy-logo.svg';

import * as S from './styles';

const TermsOfUsage: React.FC = () => {
  return (
    <S.Container>
      <S.Content>
        <S.Logo src={logo} alt="Profitfy.me" />
        <TermsOfUsageContent />
      </S.Content>
    </S.Container>
  );
};

export default TermsOfUsage;
