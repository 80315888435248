import styled, { css } from 'styled-components/macro';

import { IMoreOptionsWrapperProps } from '@domain/interfaces/dashboard/Tax/IRow';
import { IActionButtonProps } from '@domain/interfaces/components/IActionButton';

import TableBase from '@components/common/core/Utils/Table';
import CustomPopover from '@components/common/core/Utils/CustomPopover';
import BadgeBase from '@components/common/core/DataDisplay/Badge';

export const DefaultCheckoutFeeBadge = styled(BadgeBase)`
  margin: 0 auto;
`;

export const Popover = styled(CustomPopover)``;

export const MoreOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`;

export const ActionButton = styled.button<IActionButtonProps>`
  border: none;
  background-color: transparent;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.font};
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;

  ${({ isDeleteButton, theme }) =>
    isDeleteButton &&
    css`
      color: ${theme.colors.danger.default};
    `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[6]};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.gray[6]};
    filter: brightness(0.9);
  }
`;

export const Bullet = styled.span`
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.background[15]};
`;

export const MoreActionsButton = styled.div<IMoreOptionsWrapperProps>`
  width: 26px;
  height: 26px;
  align-items: center;
  justify-content: center;
  gap: 2px;
  opacity: 0;
  display: flex;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  border-radius: 50%;
  transition: filter 0.3s;
  border: unset;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  ${({ isHovering }) =>
    isHovering &&
    css`
      opacity: 1;
    `}
`;

export const Table = styled(TableBase)``;
