import React from 'react';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { ITransactionsTableRowProps } from '@domain/interfaces/dashboard/Subscription/ITransactionsTable';

import { useDate } from '@helpers/hooks/useDate';
import { useSubscriptionData } from '@helpers/hooks/pages/dashboard/subscription/useSubscriptionData';

import { numberFormatter } from '@helpers/masks';
import {
  getTransactionDescription,
  getTransactionsStatusColor,
  getTransactionsStatusName,
  getFailedTransactionDescription,
} from '@helpers/utils/dashboard/transactions/transactionsUtils';

import * as S from './styles';

const Row: React.FC<ITransactionsTableRowProps> = ({ transaction }) => {
  const { utcToZonedTime, format } = useDate();
  const { plans } = useSubscriptionData();

  const transactionDescription = getTransactionDescription(transaction, plans);
  const transactionAmount = numberFormatter(transaction.total_amount, 2);
  const formattedTransactionDate = format(
    utcToZonedTime(new Date(transaction.provider_created_at)),
    'dd/MM/yyyy',
  );
  const statusBadgeColor = getTransactionsStatusColor(transaction.status);
  const statusName = getTransactionsStatusName(transaction.status);

  return (
    <S.Table.Row borderBottom>
      <S.Table.Data>
        <S.Tooltip>
          <S.Tooltip.Trigger>
            <S.Text>{transactionDescription}</S.Text>
          </S.Tooltip.Trigger>

          <S.Tooltip.Content side="top" sideOffset={5}>
            <S.ConsumeOrdersDetailsWrapper>
              <S.LabelAndValue>
                <S.TooltipText>Qnt. Pedidos Extras:</S.TooltipText>
                <S.TooltipText>
                  {numberFormatter(transaction.total_orders_exceed_quantity, 0)}
                </S.TooltipText>
              </S.LabelAndValue>

              <S.LabelAndValue>
                <S.TooltipText>Valor Pedidos Extras:</S.TooltipText>
                <S.TooltipText>{`R$${numberFormatter(
                  transaction.consume_amount,
                  2,
                )}`}</S.TooltipText>
              </S.LabelAndValue>

              <S.LabelAndValue>
                <S.TooltipText>Total</S.TooltipText>
                <S.TooltipText>{`R$${numberFormatter(transaction.total_amount, 2)}`}</S.TooltipText>
              </S.LabelAndValue>
            </S.ConsumeOrdersDetailsWrapper>
            <S.Tooltip.Arrow />
          </S.Tooltip.Content>
        </S.Tooltip>
      </S.Table.Data>

      <S.Table.Data>{formattedTransactionDate}</S.Table.Data>

      <S.Table.Data>
        <S.Badge secondary color={statusBadgeColor}>
          {statusName}
        </S.Badge>
      </S.Table.Data>

      <S.Table.Data align={EHeadAlign.CENTER}>
        {Boolean(transaction.lr) && (
          <S.Tooltip>
            <S.Tooltip.Trigger>
              <S.Text>{getFailedTransactionDescription(transaction.lr)}</S.Text>
            </S.Tooltip.Trigger>

            <S.Tooltip.Content side="top" sideOffset={5}>
              <S.FailedTransactionDescriptionWrapper>
                <S.TooltipText>{getFailedTransactionDescription(transaction.lr)}</S.TooltipText>
              </S.FailedTransactionDescriptionWrapper>

              <S.Tooltip.Arrow />
            </S.Tooltip.Content>
          </S.Tooltip>
        )}
      </S.Table.Data>

      <S.Table.Data align={EHeadAlign.RIGHT}>{`R$ ${transactionAmount}`}</S.Table.Data>
    </S.Table.Row>
  );
};

export default Row;
