import styled from 'styled-components/macro';

import SideModalBase from '@components/common/core/DataDisplay/SideModal';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import LabelBase from '@components/common/core/DataDisplay/Label';
import CustomDatePickerBase from '@components/common/core/Utils/CustomDatePicker';

export const CustomDatePicker = styled(CustomDatePickerBase)`
  & > div > div:first-child > label {
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.body};
  }

  & > div > div:nth-child(2) > label {
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.body};
  }
`;

export const Label = styled(LabelBase)``;

export const Option = styled(OptionBase)``;

export const Select = styled(SelectBase)`
  width: 100%;
`;

export const Input = styled(TextFieldBase)``;

export const InputGroup = styled(InputGroupBase)``;

export const Title = styled(HeadingBase)``;

export const Body = styled.div`
  padding-top: 24px;

  & > form > div:first-child {
    margin-bottom: 24px;
  }

  & > form > div:nth-child(2) {
    margin-bottom: 32px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
`;

export const SideModal = styled(SideModalBase)``;
