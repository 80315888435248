import React from 'react';
import { SimpleCheck } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useHistory } from 'react-router-dom';

import { EPlanRecurrence } from '@domain/enums/subscription/ESubscription';
import { IPlanProps } from '@domain/interfaces/pricing/plans/IPlan';

import { useConfig } from '@helpers/hooks/useConfig';

import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const Plan: React.FC<IPlanProps> = ({ plan, selectedRecurrenceType }) => {
  const isMonthlyRecurrence = selectedRecurrenceType === EPlanRecurrence.MONTHLY;
  const isQuarterRecurrence = selectedRecurrenceType === EPlanRecurrence.QUARTER;
  const isSemiAnualRecurrence = selectedRecurrenceType === EPlanRecurrence.SEMIANUAL;

  const theme = useTheme();
  const { analytics } = useConfig();
  const history = useHistory();

  const isMostPopularPlan = plan.name === 'Essentials';
  const isUnlimitedPlan = plan.name === 'Unlimited';
  const isLitePlan = plan.name === 'Lite';

  const onButtonClick = React.useCallback(() => {
    analytics?.track('Website Test For Free Button Clicked', {
      plan_name: plan.name,
      recurrence: selectedRecurrenceType,
    });

    history.push('/sign-up/1');
  }, [analytics, plan, selectedRecurrenceType, history]);

  const getPlanPrice = React.useCallback(() => {
    if (isMonthlyRecurrence) return numberFormatter(plan.monthlyPrice, 0);

    if (isQuarterRecurrence) return numberFormatter(plan.quarterMonthlyPrice || 0, 0);

    return numberFormatter(plan.semesterMonthlyPrice || 0, 0);
  }, [isMonthlyRecurrence, isQuarterRecurrence, plan]);

  const getRecurrenceText = React.useCallback(() => {
    if (isQuarterRecurrence && !isLitePlan) return 'Cobrado a cada 3 meses';

    if (isSemiAnualRecurrence && !isLitePlan) return 'Cobrado a cada 6 meses';

    return '';
  }, [isQuarterRecurrence, isSemiAnualRecurrence, isLitePlan]);

  const getExtraOrderPrice = React.useCallback(() => {
    if (isLitePlan) return 'Sem pedidos extras';

    if (selectedRecurrenceType === EPlanRecurrence.MONTHLY)
      return `${numberFormatter(plan.extraOrderPrice, 2)} por pedido extra`;

    if (selectedRecurrenceType === EPlanRecurrence.QUARTER)
      return `${numberFormatter(plan.extraOrderPriceQuarter, 2)} por pedido extra`;

    return `${numberFormatter(plan.extraOrderPriceSemiannual, 2)} por pedido extra`;
  }, [isLitePlan, selectedRecurrenceType, plan]);

  const isRecurrence = isQuarterRecurrence || isSemiAnualRecurrence;

  return (
    <S.Wrapper>
      <S.PlanCard isMostPopularPlan={isMostPopularPlan}>
        <S.PlanTitle>{plan.name}</S.PlanTitle>

        <S.PlanPriceWrapper>
          <S.PlanPrice>
            <S.Currency>R$</S.Currency>
            <S.Price>{getPlanPrice()}</S.Price>
          </S.PlanPrice>

          <S.PlanPriceLabel isRecurrence={isRecurrence}>por mês</S.PlanPriceLabel>
        </S.PlanPriceWrapper>

        {isRecurrence && !isLitePlan && (
          <S.ChargeRecurrenceText>{getRecurrenceText()}</S.ChargeRecurrenceText>
        )}

        <S.SignUpButton onClick={onButtonClick}>Testar gratuitamente</S.SignUpButton>

        <S.HorizontalRule />

        <S.MoreBenefitsAccordion type="single" collapsible defaultValue="benefit">
          <S.MoreBenefitsItem value="benefit">
            <S.MoreBenefitsTrigger>Benefícios do plano</S.MoreBenefitsTrigger>
            <S.MoreBenefitsContent>
              <S.BenefitsWrapper>
                <S.Benefits>
                  <S.Benefit>
                    <SimpleCheck size={14} color={theme.colors.green.default} />
                    <S.BenefitText>
                      <strong>
                        {plan.ordersPerMonth === 0 ? 'Ilimitados' : plan.ordersPerMonth}
                      </strong>{' '}
                      Pedidos pagos/mês
                    </S.BenefitText>
                  </S.Benefit>

                  <S.Benefit>
                    <SimpleCheck size={14} color={theme.colors.green.default} />
                    <S.BenefitText>
                      <strong>
                        {plan.marketingProvider === 0 ? 'Ilimitadas' : plan.marketingProvider}
                      </strong>{' '}
                      {plan.marketingProvider === 1 ? 'Fonte de tráfego' : 'Fontes de tráfego'}
                    </S.BenefitText>
                  </S.Benefit>

                  <S.Benefit>
                    <SimpleCheck size={14} color={theme.colors.green.default} />
                    <S.BenefitText>
                      <strong>{plan.members === 0 ? 'Ilimitados' : plan.members}</strong>{' '}
                      {plan.members === 1 ? 'Usuário' : 'Usuários'}
                    </S.BenefitText>
                  </S.Benefit>

                  <S.Benefit>
                    <SimpleCheck size={14} color={theme.colors.green.default} />
                    <S.BenefitText>
                      <strong>
                        {plan.facebookProfile === 0 ? 'Ilimitados' : plan.facebookProfile}
                      </strong>{' '}
                      {plan.facebookProfile === 1 ? 'Perfil do Facebook' : 'Perfis do Facebook'}
                    </S.BenefitText>
                  </S.Benefit>

                  <S.Benefit>
                    <SimpleCheck size={14} color={theme.colors.green.default} />
                    <S.BenefitText>Automação de mensagens</S.BenefitText>
                  </S.Benefit>

                  {plan.features.map((feature: string) => (
                    <S.Benefit>
                      <SimpleCheck size={14} color={theme.colors.green.default} />
                      <S.BenefitText>{feature}</S.BenefitText>
                    </S.Benefit>
                  ))}
                </S.Benefits>

                {!isUnlimitedPlan && (
                  <S.Tooltip>
                    <S.TooltipTrigger>{getExtraOrderPrice()}</S.TooltipTrigger>

                    <S.TooltipContent side="top" sideOffset={5}>
                      <S.Tooltip.Arrow />

                      <S.TooltipText>
                        {isLitePlan ? (
                          <>
                            Caso você <strong>exceda a quantidade de pedidos</strong> contratado
                            pelo plano, você terá acesso aos pedidos até o momento em que foi
                            excedido.
                          </>
                        ) : (
                          <>
                            Caso você <strong>exceda a quantidade de pedidos</strong> contratado
                            pelo plano, será cobrado um valor{' '}
                            <strong>adicional a cada pedido aprovado.</strong>
                          </>
                        )}
                      </S.TooltipText>
                    </S.TooltipContent>
                  </S.Tooltip>
                )}
              </S.BenefitsWrapper>
            </S.MoreBenefitsContent>
          </S.MoreBenefitsItem>
        </S.MoreBenefitsAccordion>
      </S.PlanCard>
    </S.Wrapper>
  );
};

export default Plan;
