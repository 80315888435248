import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresCardOtherRefundedValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresCardOtherRefundedValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresCardOtherRefundedValuesResponse => {
  const currentSelectedStoreCardOtherRefundedAmountValue =
    currentSelectedStoreFinancialDetailsReportData?.card?.other?.refunded.amount;

  const currentSelectedStoreCardOtherRefundedQuantityValue =
    currentSelectedStoreFinancialDetailsReportData?.card?.other?.refunded.quantity;

  const storesCardOtherRefundedAmountValues = selectedStoresData?.map(
    value => value.financial_details_report?.card?.other?.refunded.amount,
  );

  const storesCardOtherRefundedQuantityValues = selectedStoresData?.map(
    value => value.financial_details_report?.card?.other?.refunded.quantity,
  );

  const cardOtherRefundedAmountValues = [
    ...storesCardOtherRefundedAmountValues,
    currentSelectedStoreCardOtherRefundedAmountValue,
  ];

  const cardOtherRefundedQuantityValues = [
    ...storesCardOtherRefundedQuantityValues,
    currentSelectedStoreCardOtherRefundedQuantityValue,
  ];

  const reducedCardOtherRefundedAmountValues = cardOtherRefundedAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedCardOtherRefundedQuantityValues = cardOtherRefundedQuantityValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  return {
    other_refunded_amount: reducedCardOtherRefundedAmountValues,
    other_refunded_quantity: reducedCardOtherRefundedQuantityValues,
  };
};
