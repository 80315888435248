import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import LabelBase from '@components/common/core/DataDisplay/Label';
import FormBase from '@components/common/core/Inputs/Form';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  box-sizing: border-box;
`;

export const GroupTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled(TextBase)`
  font-size: 1.25rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
  margin-left: 8px;
`;

export const CloseButton = styled.button`
  background: unset;
  padding: unset;
  border: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Tutorial = styled(TextBase)`
  margin-top: 8px;
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};
`;

export const Link = styled.a`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.green.default};
  text-decoration: underline;
`;

export const FormWrapper = styled.div`
  width: 100%;
  padding: 0 32px;
  box-sizing: border-box;
`;

export const Form = styled(FormBase)`
  width: auto;

  @media only screen and (max-width: 920px) {
    margin: 0 auto;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
`;

export const Input = styled(TextFieldBase)`
  width: 100%;
`;

export const TextField = styled(TextFieldBase)`
  width: 100%;
`;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  max-width: 408px;
  margin-top: 16px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
`;

export const ButtonCancel = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.colors.background[2]};
  padding: 2px 16px 2px 16px;
  box-shadow: unset;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background[2]};
    box-shadow: unset;
  }

  & > span {
    color: ${({ theme }) => theme.colors.white.default};
  }
`;

export const ButtonSave = styled(ButtonBase)`
  padding: 2px 16px 2px 16px;
`;
