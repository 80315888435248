import React from 'react';

import { EBadgeColors } from '@domain/enums/components/EBadge';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { IPendingInvitationRowProps } from '@domain/interfaces/dashboard/AccessManagement/IPendingInvitationRow';

import { useAccessManager } from '@helpers/hooks/pages/dashboard/accessManagement/useAccessManager';

import * as S from './styles';

const PendingInvitationRow: React.FC<IPendingInvitationRowProps> = ({ userInvite }) => {
  const {
    groups,
    selectedPendingInvitationsId,
    handleSelectedPendingInvitationsId,
  } = useAccessManager();

  const foundSelectedInvite = selectedPendingInvitationsId.find(
    invitationId => invitationId === userInvite.id,
  );

  const onCheckboxChange = React.useCallback(
    event => {
      if (!event || event.target) return;

      if (foundSelectedInvite) {
        const filteredSelectedInvites = selectedPendingInvitationsId.filter(
          selectedGroupId => selectedGroupId !== userInvite.id,
        );

        handleSelectedPendingInvitationsId(filteredSelectedInvites);
      } else {
        handleSelectedPendingInvitationsId([...selectedPendingInvitationsId, userInvite.id]);
      }
    },
    [
      foundSelectedInvite,
      userInvite.id,
      handleSelectedPendingInvitationsId,
      selectedPendingInvitationsId,
    ],
  );

  const foundGroup = groups.find(group => group.id === userInvite.group_id);

  return (
    <S.Table.Row borderBottom>
      <S.Table.Data>
        <S.CheckboxWrapper>
          <S.Checkbox checked={Boolean(foundSelectedInvite)} onChange={onCheckboxChange} />
        </S.CheckboxWrapper>
      </S.Table.Data>

      <S.Table.Data>
        <S.EmailTableDataContent>
          <S.EmailAndGroupNameWrapper>
            <S.EmailText>{userInvite.email}</S.EmailText>
            <S.GroupName weight={ETextWeight.MEDIUM} size={ETextSize.B5}>
              {foundGroup?.name}
            </S.GroupName>
          </S.EmailAndGroupNameWrapper>

          <S.Badge color={EBadgeColors.YELLOW}>Convidado</S.Badge>
        </S.EmailTableDataContent>
      </S.Table.Data>
    </S.Table.Row>
  );
};

export default PendingInvitationRow;
