import React from 'react';

import { EHeadAlign } from '@domain/enums/components/ETable';

import { usePricingTool } from '@helpers/hooks/pages/dashboard/pricingTool/usePricingTool';

import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

interface IRowProps {
  markup: number;
  index: number;
}

const Row: React.FC<IRowProps> = ({ markup, index }) => {
  const { finalPrice, maxCpa } = usePricingTool();

  return (
    <S.Table.Row>
      <S.Table.Data align={EHeadAlign.RIGHT}>
        <S.DataText>{numberFormatter(markup, 2)}x</S.DataText>
      </S.Table.Data>
      <S.Table.Data align={EHeadAlign.RIGHT}>
        <S.DataText>R$ {numberFormatter(finalPrice[index], 2)}</S.DataText>
      </S.Table.Data>
      <S.Table.Data align={EHeadAlign.RIGHT}>
        <S.DataText>R$ {numberFormatter(maxCpa[index], 2)}</S.DataText>
      </S.Table.Data>
      <S.Table.Data align={EHeadAlign.RIGHT}>
        <S.DataText>R$ {numberFormatter(maxCpa[index] / 10, 2)}</S.DataText>
      </S.Table.Data>
    </S.Table.Row>
  );
};

export default Row;
