import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';
import {
  ILojaIntegradaCredential,
  IUpdateLojaIntegradaCredentialData,
} from '@domain/interfaces/dashboard/IntegrationCenter/Ecommerce/LojaIntegrada/ILojaIntegradaAuth';

import { useToast } from '@helpers/hooks/useToast';

import lojaIntegradaAuthService from '@services/pages/dashboard/integrationCenter/ecommerce/lojaIntegrada/lojaIntegradaAuth';

import DeleteCredentialModal from '@components/Dashboard/IntegrationCenter/SideModalContent/DeleteCredentialModal';
import * as S from './styles';

interface ICredentialOptionProps {
  lojaIntegradaCredential: ILojaIntegradaCredential;
  mutate: any;
}

const CredentialOption: React.FC<ICredentialOptionProps> = ({
  lojaIntegradaCredential,
  mutate,
}) => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();

  const [isDisablingCredential, setIsDisablingCredential] = React.useState<boolean>(false);
  const [isDeleteCredentialModalOpen, setIsDeleteCredentialModalOpen] = React.useState<boolean>(
    false,
  );

  const handleDeleteCredentialModalOpen = React.useCallback(
    () => setIsDeleteCredentialModalOpen(state => !state),
    [],
  );

  const onDeleteCredential = React.useCallback(async () => {
    setIsDisablingCredential(true);

    try {
      const payload: IUpdateLojaIntegradaCredentialData = {
        store_key: lojaIntegradaCredential.store_key,
        type: lojaIntegradaCredential.types,
        is_active: false,
      };

      await lojaIntegradaAuthService.updateLojaIntegradaCredential({
        storeAliasId,
        payload,
      });

      toast.success('Integração desativada com sucesso!');

      setIsDisablingCredential(false);
      handleDeleteCredentialModalOpen();

      mutate();
    } catch (error: any) {
      setIsDisablingCredential(false);
      toast.error(error?.response?.data?.message);
    }
  }, [toast, handleDeleteCredentialModalOpen, mutate, lojaIntegradaCredential, storeAliasId]);

  return (
    <S.Content>
      <S.CredentialDescriptionWrapper>
        <S.CredentialDescriptionTitle type={EHeadingSize.H5}>
          O que acontece se eu desativar minha integração?
        </S.CredentialDescriptionTitle>

        <S.CredentialDescriptionText>
          Ao desativar a integração,
          <strong> não será mais possível </strong>
          processar os pedidos da Loja Integrada.
        </S.CredentialDescriptionText>
      </S.CredentialDescriptionWrapper>

      <DeleteCredentialModal
        isOpen={isDeleteCredentialModalOpen}
        toggle={handleDeleteCredentialModalOpen}
        onDeleteCredential={onDeleteCredential}
        isLoading={isDisablingCredential}
      />
    </S.Content>
  );
};

export default CredentialOption;
