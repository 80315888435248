import styled from 'styled-components';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TabOptionBase from '@components/common/core/Navigation/Tab/Option';
import TabBase from '@components/common/core/Navigation/Tab';
import ImageBase from '@components/common/core/DataDisplay/Image';

export const Wrapper = styled.div``;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding-bottom: 22px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
  margin-bottom: 40px;
`;

export const Image = styled(ImageBase)`
  max-width: 104px;
`;

export const Heading = styled(HeadingBase)``;

export const Tab = styled(TabBase)`
  margin-bottom: 48px;
`;

export const TabOption = styled(TabOptionBase)``;
