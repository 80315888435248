import styled from 'styled-components/macro';

import BadgeBase from '@components/common/core/DataDisplay/Badge';
import ButtonBase from '@components/common/core/Inputs/Button';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.div`
  min-width: 768px;
  padding: 12px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 48px;

  & > button:nth-child(2) {
    width: 100%;
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: #25d366;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ColumnContainer = styled.div`
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  gap: 4px;
`;

export const Title = styled(TextBase)`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[1]};
  line-height: 1;
`;

export const SubTitle = styled(TextBase)`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[2]};
  line-height: 1;
`;

export const InstanceStatus = styled(BadgeBase)``;

export const ActionButton = styled(ButtonBase)`
  font-weight: 500;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
