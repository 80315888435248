import React from 'react';

import { ITooltipProps } from '@domain/interfaces/integrations/marketing/tiktok/ITable';

import * as S from './styles';

const Tooltip: React.FC<ITooltipProps> = ({
  children,
  content,
  textMaxWidth = 75,
  side,
  sideOffset,
}) => {
  return (
    <S.Tooltip>
      <S.TooltipTrigger textMaxWidth={textMaxWidth}>{children}</S.TooltipTrigger>
      <S.TooltipContent side={side} sideOffset={sideOffset}>
        {content}
        <S.Tooltip.Arrow />
      </S.TooltipContent>
    </S.Tooltip>
  );
};

export default Tooltip;
