export enum ERankingProductSortOption {
  PRODUCT_NAME = 'PRODUCT_NAME',
  FAVORITE_PRODUCT = 'FAVORITE_PRODUCT',
  NET_REVENUE_AMOUNT = 'NET_REVENUE_AMOUNT',
  PENDING_LINE_ITEMS_QUANTITY = 'PENDING_LINE_ITEMS_QUANTITY',
  COGS_AMOUNT = 'COGS_AMOUNT',
  MARKETING_COST = 'MARKETING_COST',
  TAXES_AND_FESS_AMOUNT = 'TAXES_AND_FEES_AMOUNT',
  CPA_AMOUNT = 'CPA_AMOUNT',
  BOLETO_CONVERSION = 'BOLETO_CONVERSION',
  NET_PROFIT_AMOUNT = 'NET_PROFIT_AMOUNT',
  PROFIT_MARGIN = 'PROFIT_MARGIN',
}
