import React from 'react';

import { useSubscriptionData } from '@helpers/hooks/pages/dashboard/subscription/useSubscriptionData';

import visaIcon from '@assets/subscription/card/issuers/visa.svg';
import mastercardIcon from '@assets/subscription/card/issuers/mastercard.svg';
import dinersIcon from '@assets/subscription/card/issuers/diners.svg';
import eloIcon from '@assets/subscription/card/issuers/elo.svg';
import amexIcon from '@assets/subscription/card/issuers/amex.svg';

import UpdatePaymentMethod from '@components/Dashboard/StoreSubscription/Subscription/Modals/UpdatePaymentMethod';

import * as S from './styles';

const PaymentMethod: React.FC = () => {
  const { paymentMethod } = useSubscriptionData();

  const [isUpdateModalOpen, setIsUpdateModalOpen] = React.useState<boolean>(false);

  const handleUpdateModalOpen = React.useCallback(() => {
    setIsUpdateModalOpen(state => !state);
  }, []);

  const getIssuerIcon = React.useCallback((brand: string): string => {
    if (brand.toLowerCase().includes('visa')) return visaIcon;

    if (brand.toLowerCase().includes('master')) return mastercardIcon;

    if (brand.toLowerCase().includes('elo')) return eloIcon;

    if (brand.toLowerCase().includes('diners')) return dinersIcon;

    if (brand.toLowerCase().includes('american')) return amexIcon;

    return '';
  }, []);

  const getIssuerName = React.useCallback((brand: string): string | undefined => {
    if (brand.toLowerCase().includes('visa')) return 'Visa';

    if (brand.toLowerCase().includes('master')) return 'Mastercard';

    if (brand.toLowerCase().includes('elo')) return 'Elo';

    if (brand.toLowerCase().includes('diners')) return 'Diners Club';

    if (brand.toLowerCase().includes('american')) return 'American Express';

    return undefined;
  }, []);

  const getLastDigits = React.useCallback((cardText: string): string => {
    const digits = cardText.split('-');

    return digits[3];
  }, []);

  const cardText = `${getIssuerName(paymentMethod.brand)} com final ${getLastDigits(
    paymentMethod.display_number,
  )}`;
  const cardName = paymentMethod.holder_name
    .toLowerCase()
    .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

  return (
    <>
      <S.CardWrapper>
        <S.Content>
          <S.DescriptionWrapper>
            <S.Title>Método de pagamento</S.Title>
            <S.Description>
              Edite o seu método de pagamento para as cobranças futuras.
            </S.Description>
          </S.DescriptionWrapper>

          <S.DataWrapper>
            <S.IssuerLogo src={getIssuerIcon(paymentMethod.brand)} alt="Profitfy Card Issuer" />
            <S.CardDetailsWrapper>
              <S.OwnerNameText>{cardName}</S.OwnerNameText>
              <S.CardText>{cardText}</S.CardText>
            </S.CardDetailsWrapper>
          </S.DataWrapper>
        </S.Content>

        <S.UpdateDateButton onClick={handleUpdateModalOpen}>Alterar dados</S.UpdateDateButton>
      </S.CardWrapper>

      {isUpdateModalOpen && (
        <UpdatePaymentMethod isOpen={isUpdateModalOpen} toggle={handleUpdateModalOpen} />
      )}
    </>
  );
};

export default PaymentMethod;
