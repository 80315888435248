import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.button`
  width: 100%;
  height: 84px;
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background[8]};
  }
`;

export const Icon = styled.div`
  margin-left: 15px;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
`;

export const Title = styled(TextBase)`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const Description = styled(TextBase)`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[2]};
  text-align: left;
`;
