import React from 'react';

import { EFilterStep } from '@domain/enums/components/EFilter';
import { IFilterConfig } from '@domain/interfaces/components/IFilter';

import { useFilter } from '@helpers/hooks/useFilter';

import { getFilterComponent } from '@helpers/utils/components/filter';

import { ThemeProvider } from '@helpers/hooks/useTheme';

import * as S from './styles';

const FilterPopoverContent: React.FC = () => {
  const { data, config, handleAddNewFilterOpen, addNewData, updateData } = useFilter();

  const [filterStep, setFilterStep] = React.useState<EFilterStep>(EFilterStep.FILTERS);
  const [selectedFilter, setSelectedFilter] = React.useState<IFilterConfig | undefined>(undefined);
  const [filterSearch, setFilterSearch] = React.useState<string>('');

  const handleFilterClick = React.useCallback((filter: IFilterConfig) => {
    setSelectedFilter(filter);
    setFilterStep(EFilterStep.SELECTED_FILTER);
  }, []);

  const onSearchFilterChange = React.useCallback(event => {
    setFilterSearch(event.target.value);
  }, []);

  const handleOnOptionClick = React.useCallback(() => {
    handleAddNewFilterOpen();
  }, [handleAddNewFilterOpen]);

  const isFilterDisabled = React.useCallback(
    (filter: IFilterConfig) => {
      const foundFilter = data.find(filterData => filterData.field === filter.field);

      return Boolean(foundFilter);
    },
    [data],
  );

  const isFirstStep = filterStep === EFilterStep.FILTERS;
  const filteredFilters = config.filter(filter => {
    const loweredCaseLabel = filter.label.toLowerCase();
    const loweredCaseFilterSearch = filterSearch.toLowerCase();

    return loweredCaseLabel.includes(loweredCaseFilterSearch);
  });
  const Filter = getFilterComponent(selectedFilter?.type || '');

  return (
    <S.DropDownContent sideOffset={5} filterType={selectedFilter?.type}>
      <ThemeProvider>
        {isFirstStep ? (
          <S.FiltersWrapper>
            <S.SearchFilterWrapper>
              <S.SearchFilterInput
                type="text"
                placeholder="Encontrar filtro..."
                onChange={onSearchFilterChange}
              />
            </S.SearchFilterWrapper>

            <S.Filters>
              {filteredFilters.map(filter => (
                <S.FilterButton
                  onClick={() => handleFilterClick(filter)}
                  key={filter.field}
                  disabled={isFilterDisabled(filter)}
                >
                  {filter.label}
                </S.FilterButton>
              ))}

              {!filteredFilters.length && (
                <S.NoFilterFound>Não encontramos nenhum filtro.</S.NoFilterFound>
              )}
            </S.Filters>
          </S.FiltersWrapper>
        ) : (
          <>
            {selectedFilter && (
              <Filter
                config={selectedFilter}
                handleOnClick={handleOnOptionClick}
                addNewData={addNewData}
                updateData={updateData}
                data={data}
              />
            )}
          </>
        )}
      </ThemeProvider>
    </S.DropDownContent>
  );
};

export default FilterPopoverContent;
