import { ApiService } from '@services/api';
import { USER_API_DOMAIN } from '@constants/api';
import { ISendCogsInformationEventProps } from '@domain/interfaces/dashboard/BenefitArea/ISendEvent';

export class ZendropEventService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public sendCogsInformationEvent({ storeAliasId }: ISendCogsInformationEventProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/zendrop/send-cogs-information`,
    );
  }
}

const zendropEventService = new ZendropEventService(USER_API_DOMAIN);

export default zendropEventService;
