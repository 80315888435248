import styled, { css, keyframes } from 'styled-components/macro';

import { ISideModalContentProps } from '@domain/interfaces/components/ISideModal';

import Text from '@components/common/core/DataDisplay/Text';

const backgroundAnimation = keyframes`
  0% {
    opacity: 0;
    backdrop-filter: blur(0px);
  }
  100% {
    opacity: 1;
    backdrop-filter: blur(2px);
  }
`;

const sideModalAnimation = keyframes`
  0% {
    transform: translateX(600px);
  }
  100% {
    transform: translateX(0);
  }
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.6);
  z-index: ${({ theme }) => theme.zIndex.secondLayer};
  animation: ${backgroundAnimation} 0.3s ease-in-out;
`;

export const ModalContentWrapper = styled.div`
  padding: 24px 40px 108px 40px;
  box-sizing: border-box;
  min-height: 100vh;
  height: fit-content;
  position: relative;
`;

export const ModalContent = styled.div<ISideModalContentProps>`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  box-shadow: 0px 6px 18px 1px ${({ theme }) => theme.shadows.mainShadow};
  z-index: ${({ theme }) => theme.zIndex.fifthLayer};
  overflow-y: scroll;
  max-width: 585px;
  width: 100%;
  text-align: left;
  animation: ${sideModalAnimation} 0.3s ease-in;

  ${({ blockOverflowYScroll }) =>
    blockOverflowYScroll &&
    css`
      overflow-y: unset;
    `}

  ::-webkit-scrollbar {
    width: 4px;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    position: absolute;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[6]};
    border-radius: 25px;
    transition: all 0.3s;
  }

  ::-webkit-scrollbar-thumb:hover {
    opacity: brightness(0.8);
  }
`;

export const CloseButtonWrapper = styled.div`
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
`;

export const CloseButtonWrapperLabel = styled(Text)`
  margin-right: 9px;
`;
