import React from 'react';

import { EAvatarSize } from '@domain/enums/components/EAvatar';
import { useParams } from 'react-router-dom';
import { IParams, ISuggestion, IUser } from '@domain/interfaces/dashboard/IdeasChannel/suggestions';
import { friendlyNumberFormatter } from '@helpers/masks';
import { useToast } from '@helpers/hooks/useToast';
import { MONTHS } from '@constants/months';
import { useSuggestions } from '@helpers/hooks/pages/dashboard/ideasChannel/useSuggestions';

import suggestionsService from '@services/pages/dashboard/ideasChannel/suggestions';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Idea: React.FC = () => {
  const { ideaAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { voteUp, voteDown } = useSuggestions();

  const [suggestion, setSuggestion] = React.useState<ISuggestion>({} as ISuggestion);
  const [user, setUser] = React.useState<IUser>({} as IUser);
  const [createdAtDate, setIsCreatedAtDate] = React.useState<string>('');
  const [isGettingSuggestion, setIsGettingSuggestion] = React.useState<boolean>(false);
  const [isUpvoted, setIsUpvoted] = React.useState<boolean>(false);
  const [votes, setVotes] = React.useState<number>(0);

  React.useEffect(() => {
    async function loadingSuggestion(): Promise<any> {
      try {
        setIsGettingSuggestion(true);

        const { data } = await suggestionsService.getSuggestion({ ideaAliasId });

        setSuggestion(data?.suggestion);
        setUser(data?.user);
        setVotes(data?.suggestion.votes_up);
        setIsUpvoted(data?.suggestion.is_voted_up_by_user);

        setIsGettingSuggestion(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    }

    loadingSuggestion();
  }, [ideaAliasId, toast]);

  const handleUpvote = React.useCallback(() => {
    if (isUpvoted) {
      voteDown({ ideaAliasId });
      setVotes(previousState => previousState - 1);
    } else {
      voteUp({ ideaAliasId });
      setVotes(previousState => previousState + 1);
    }

    setIsUpvoted(!isUpvoted);
  }, [isUpvoted, voteDown, voteUp, ideaAliasId]);

  React.useEffect(() => {
    const createdSuggestionDate = suggestion.created_at;

    if (createdSuggestionDate) {
      let date: string;

      if (typeof createdSuggestionDate === 'number') {
        date = new Date(createdSuggestionDate * 1000).toISOString();
      } else {
        date = createdSuggestionDate;
      }

      const createAtYear = date.substring(0, 4);
      const createAtMonth = date.substring(5, 7);
      const createdAtDay = date.substring(8, 10);

      const foundMonth = MONTHS.find(month => month.id === createAtMonth);

      if (foundMonth) {
        setIsCreatedAtDate(`${createdAtDay} de ${foundMonth.name} de ${createAtYear}.`);
      }
    }
  }, [suggestion.created_at]);

  if (isGettingSuggestion) {
    return <SkeletonLoading />;
  }

  return (
    <S.IdeaWrapper>
      <S.Header>
        <S.VoteWrapper isVoted={isUpvoted} onClick={handleUpvote}>
          <S.ArrowUp />
          <S.VotesCount>{friendlyNumberFormatter(votes)}</S.VotesCount>
        </S.VoteWrapper>
        <S.Title>{suggestion.title}</S.Title>
      </S.Header>

      <S.Body>
        <S.Avatar word={user.first_name?.charAt(0)} size={EAvatarSize.SMALL} />
        <S.DescriptionWrapper>
          <S.UserName>Anônimo</S.UserName>
          <S.DescriptionText>{suggestion.description}</S.DescriptionText>
          <S.DateText>{createdAtDate}</S.DateText>
        </S.DescriptionWrapper>
      </S.Body>
    </S.IdeaWrapper>
  );
};

export default Idea;
