import React from 'react';
import { ResponsiveContainer, PieChart as Chart, Pie, Cell } from 'recharts';

import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';

import * as S from './styles';

const Boleto: React.FC = () => {
  const { financialDetailsReport } = useFinancialDashboard();

  const { boleto } = financialDetailsReport;

  const data = [
    {
      name: 'Conversão',
      value: boleto.conversion,
    },
    {
      name: 'Total',
      value: 100 - boleto.conversion,
    },
  ];

  const colors = ['#50E170', '#515562'];

  return (
    <S.ChartWrapper>
      <ResponsiveContainer width="100%">
        <Chart>
          <Pie
            data={data}
            innerRadius="90%"
            outerRadius="100%"
            fill="#000"
            paddingAngle={0}
            dataKey="value"
            strokeOpacity={0}
          >
            {data.map((pie, index) => (
              <Cell key={`cell-${pie.name}`} fill={colors[index]} />
            ))}
          </Pie>
        </Chart>
      </ResponsiveContainer>
    </S.ChartWrapper>
  );
};

export default Boleto;
