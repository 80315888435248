import React from 'react';

import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { EGateway } from '@domain/enums/common/EGateway';
import { IIconProps } from '@profitfy/pakkun-icons';

import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { numberFormatter } from '@helpers/masks';
import {
  parseGateways,
  parseProviders,
} from '@helpers/utils/dashboard/financialDashboard/financialDashboard';

import { GATEWAYS } from '@constants/common/integrations';

import * as S from './styles';

const HoverCard: React.FC = ({ children }) => {
  const { financialReport } = useFinancialDashboard();
  const { storeIntegrations } = useStoreConfig();

  const getGateway = React.useCallback(
    (gateway: EGateway) => {
      let gatewayToFilter = gateway;

      const foundOtherGateway = storeIntegrations.gateways.find(
        storeGateway => storeGateway.other_gateway?.has_other_gateway,
      );

      const isPredefinedGateway = !foundOtherGateway?.other_gateway?.gateway_names.includes(
        'OTHER',
      );

      if (gateway === EGateway.OTHER_GATEWAY && foundOtherGateway && isPredefinedGateway) {
        gatewayToFilter = foundOtherGateway.other_gateway?.gateway_names[0] as EGateway;
      }

      const foundIntegration = GATEWAYS.find(
        gatewayIntegration => gatewayIntegration.identifier === gatewayToFilter,
      );

      return foundIntegration;
    },
    [storeIntegrations],
  );

  const { gateway, provider, net_revenue } = financialReport;

  const parsedProviders = parseProviders(provider);
  const parsedGateways = parseGateways(gateway);

  const filteredFinancialReport = parsedGateways.filter(integration => integration.amount);

  const onHoverCardClick = React.useCallback(event => {
    event.stopPropagation();
  }, []);

  const foundProvider = parsedProviders.find(integration => integration.amount > 0);

  return (
    <S.HoverCard>
      <S.HoverCard.Trigger>{children}</S.HoverCard.Trigger>

      <S.HoverCardContent onClick={onHoverCardClick}>
        <S.GrossValueWrapper>
          <S.GrossRevenue size={ETextSize.B5} weight={ETextWeight.MEDIUM}>
            Receita Bruta: <strong>{numberFormatter(foundProvider?.amount || 0, 2)}</strong>
          </S.GrossRevenue>

          <S.Badge color="primary">Beta</S.Badge>
        </S.GrossValueWrapper>

        <S.GatewaysWrapper>
          {filteredFinancialReport.map(integration => {
            const foundIntegration = getGateway(integration.provider);

            const Icon = foundIntegration?.icon as React.FC<IIconProps>;

            return (
              <S.Row key={foundIntegration?.identifier}>
                <S.IconAndNameWrapper>
                  <Icon size={24} />
                  <S.GatewayName>{foundIntegration?.name}</S.GatewayName>
                </S.IconAndNameWrapper>

                <S.GatewayValue size={ETextSize.B5} weight={ETextWeight.MEDIUM}>
                  {`R$ ${numberFormatter(integration.amount, 2)}`}
                </S.GatewayValue>
              </S.Row>
            );
          })}
        </S.GatewaysWrapper>

        <S.ValueAndDescriptionWrapper>
          <S.ApprovedRevenueByOrderDateValue>{`R$ ${numberFormatter(
            net_revenue.amount_by_approved_at,
            2,
          )}`}</S.ApprovedRevenueByOrderDateValue>
          <S.Description>Total de receita pela data de aprovação do pedido.</S.Description>
        </S.ValueAndDescriptionWrapper>

        <S.HoverCard.Arrow />
      </S.HoverCardContent>
    </S.HoverCard>
  );
};

export default HoverCard;
