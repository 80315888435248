import { v4 } from 'uuid';

import {
  EBoletoType,
  EBoletoProductOptions,
} from '@domain/enums/dashboard/automations/node/conditionalNode/EBoleto';

export const DEFAULT_BOLETO_CONTENT = {
  id: v4(),
  type: EBoletoType.PRODUCT_NAME,
  options: EBoletoProductOptions.CONTAINS,
  input: '',
};
