import React from 'react';

import { useLocation } from 'react-router-dom';

import Route from '@components/routes/Route';
import Teste from '@pages/Dashboard/Teste';

import IntegrationCenter from '@pages/Dashboard/IntegrationCenter';
import Profile from '@pages/Dashboard/Profile';

import FacebookIntegration from '@pages/Dashboard/IntegrationCenter/Marketing/Facebook';
import GoogleIntegration from '@pages/Dashboard/IntegrationCenter/Marketing/Google';
import TaboolaIntegration from '@pages/Dashboard/IntegrationCenter/Marketing/Taboola';
import TikTokIntegration from '@pages/Dashboard/IntegrationCenter/Marketing/TikTok';

import DashboardWrapper from '@pages/Dashboard';
import AccessManagement from '@pages/Dashboard/AccessManagement';
import AccessManagementNewGroup from '@pages/Dashboard/AccessManagement/NewGroup';
import AccessManagementNewMember from '@pages/Dashboard/AccessManagement/NewMember';
import OnboardingAccessManagementNewGroup from '@pages/Dashboard/AccessManagement/Onboarding/NewGroup';
import OnboardingAccessManagementNewMember from '@pages/Dashboard/AccessManagement/Onboarding/NewMember';
import OnboardingAccessManagementSelectGroup from '@pages/Dashboard/AccessManagement/Onboarding/SelectGroup';
import AdsManager from '@pages/Dashboard/AdsManager/AdsManager';
import MarketingIntegration from '@pages/Dashboard/AdsManager/Onboarding/MarketingIntegration';
import ScriptAndUtms from '@pages/Dashboard/AdsManager/Onboarding/ScriptAndUtms';
import AutomationCanvas from '@pages/Dashboard/AutomationCanvas';
import AutomationMessages from '@pages/Dashboard/AutomationMessages';
import AutomationTemplates from '@pages/Dashboard/AutomationTemplates';
import Automations from '@pages/Dashboard/Automations';
import AutomationsWelcome from '@pages/Dashboard/AutomationsWelcome';
import BenefitArea from '@pages/Dashboard/BenefitArea';
import PejotaPlus from '@pages/Dashboard/BenefitArea/PejotaPlus';
import Zendrop from '@pages/Dashboard/BenefitArea/Zendrop';
import Boleto from '@pages/Dashboard/Boleto';
import CheckoutFee from '@pages/Dashboard/CheckoutFee';
import CustomValuesIn from '@pages/Dashboard/CustomValues/In';
import CustomValuesOut from '@pages/Dashboard/CustomValues/Out';
import CustomValuesCategories from '@pages/Dashboard/CustomValuesCategories';
import Feed from '@pages/Dashboard/Feed';
import FinancialDashboard from '@pages/Dashboard/FinancialDashboard';
import FinancialData from '@pages/Dashboard/FinancialData';
import FinancialDetails from '@pages/Dashboard/FinancialDetails';
import IdeaDetails from '@pages/Dashboard/IdeasChannel/IdeaDetails';
import NewIdea from '@pages/Dashboard/IdeasChannel/NewIdea';
import OverviewIdeas from '@pages/Dashboard/IdeasChannel/OverviewIdeas';
import Kit from '@pages/Dashboard/Kit';
import EditKit from '@pages/Dashboard/Kit/EditKit';
import NewKit from '@pages/Dashboard/Kit/NewKit';
import Orders from '@pages/Dashboard/Orders';
import ProductDetails from '@pages/Dashboard/ProductAnalytics/ProductDetails';
import ProductTagOnboardingNewTag from '@pages/Dashboard/ProductAnalytics/ProductTagOnboarding/NewTag';
import ProductTagOnboarding from '@pages/Dashboard/ProductAnalytics/ProductTagOnboarding/ProductTagOnboarding';
import RankingTable from '@pages/Dashboard/ProductAnalytics/RankingTable';
import ProductCost from '@pages/Dashboard/ProductCost';
import ProductCostDetails from '@pages/Dashboard/ProductCostDetails';
import Shipping from '@pages/Dashboard/Shipping';
import StoreSubscription from '@pages/Dashboard/StoreSubscription';
import Tax from '@pages/Dashboard/Tax';
import WhatsappInstances from '@pages/Dashboard/WhatsappInstance/Instances';
import WhatsappNewInstance from '@pages/Dashboard/WhatsappInstance/NewInstance';
import CreateOrder from '@pages/Dashboard/Orders/CreateOrder';

import { OnboardingProvider } from '@helpers/hooks/pages/onboarding/useOnboarding';

import Home from '@pages/Dashboard/Home';
import PricingTool from '@pages/Dashboard/PricingTool';
import Admin from './Admin';

const Dashboard: React.FC = () => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location]);

  return (
    <DashboardWrapper>
      <Admin />

      <Route isPrivate path="/:storeAliasId/dashboard/teste" component={Teste} />

      <OnboardingProvider>
        <Route
          isPrivate
          path="/:storeAliasId/dashboard/overview/financial"
          exact
          component={FinancialDashboard}
        />
      </OnboardingProvider>
      <Route isPrivate path="/:storeAliasId/dashboard/home/home" exact component={Home} />
      <Route isPrivate path="/:storeAliasId/dashboard/pricing" exact component={PricingTool} />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/reports/financial/details"
        exact
        component={FinancialDetails}
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/overview/products-analytic"
        exact
        component={RankingTable}
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/overview/products-analytic/:productAliasId/campaigns"
        exact
        component={ProductTagOnboarding}
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/overview/products-analytic/:productAliasId/campaigns/onboarding/new-tag"
        exact
        component={ProductTagOnboardingNewTag}
      />
      <Route isPrivate path="/:storeAliasId/dashboard/data/kits" exact component={Kit} />
      <Route isPrivate path="/:storeAliasId/dashboard/data/kits/new-kit" exact component={NewKit} />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/data/kits/:variantKitAliasId/edit"
        exact
        component={EditKit}
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/overview/products-analytic/:productAliasId/details"
        exact
        component={ProductDetails}
      />
      <Route isPrivate path="/:storeAliasId/dashboard/profile" component={Profile} />
      <Route
        isPrivate
        exact
        path="/:storeAliasId/dashboard/subscription"
        component={StoreSubscription}
      />
      <Route isPrivate path="/:storeAliasId/dashboard/reports/orders" exact component={Orders} />
      <Route
        exact
        isPrivate
        path="/:storeAliasId/dashboard/reports/orders/create"
        component={CreateOrder}
      />
      <Route isPrivate path="/:storeAliasId/dashboard/reports/boletos" component={Boleto} />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/data/integrations"
        exact
        component={IntegrationCenter}
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/data/products-costs"
        component={ProductCost}
        exact
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/data/products-costs/:productAliasId"
        exact
        component={ProductCostDetails}
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/data/integrations/facebook"
        exact
        component={FacebookIntegration}
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/data/integrations/google"
        exact
        component={GoogleIntegration}
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/data/integrations/taboola"
        exact
        component={TaboolaIntegration}
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/data/integrations/tiktok"
        exact
        component={TikTokIntegration}
      />
      <Route isPrivate path="/:storeAliasId/dashboard/ideas" component={OverviewIdeas} exact />
      <Route isPrivate path="/:storeAliasId/dashboard/ideas/new-idea" component={NewIdea} exact />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/ideas/details/:ideaAliasId"
        component={IdeaDetails}
        exact
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/financial-data"
        component={FinancialData}
        exact
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/custom-values/out"
        component={CustomValuesOut}
        exact
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/custom-values/in"
        component={CustomValuesIn}
        exact
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/custom-values/categories"
        component={CustomValuesCategories}
        exact
      />
      <Route isPrivate path="/:storeAliasId/dashboard/data/tax" component={Tax} exact />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/data/checkout-fee"
        component={CheckoutFee}
        exact
      />
      <Route isPrivate path="/:storeAliasId/dashboard/feed" component={Feed} exact />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/marketing/ads-manager/onboarding/script-utms"
        component={ScriptAndUtms}
        exact
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/marketing/ads-manager/onboarding/marketing-integrations"
        component={MarketingIntegration}
        exact
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/marketing/ads-manager"
        component={AdsManager}
        exact
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/access-manager"
        component={AccessManagement}
        exact
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/access-manager/onboarding/select-group"
        component={OnboardingAccessManagementSelectGroup}
        exact
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/access-manager/onboarding/new-group"
        component={OnboardingAccessManagementNewGroup}
        exact
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/access-manager/onboarding/invite"
        component={OnboardingAccessManagementNewMember}
        exact
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/access-manager/new-group"
        component={AccessManagementNewGroup}
        exact
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/access-manager/invite"
        component={AccessManagementNewMember}
        exact
      />
      <Route isPrivate path="/:storeAliasId/dashboard/benefit-area" component={BenefitArea} exact />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/benefit-area/pejota-plus"
        component={PejotaPlus}
        exact
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/benefit-area/zendrop"
        component={Zendrop}
        exact
      />
      <Route isPrivate path="/:storeAliasId/dashboard/data/shipping" component={Shipping} exact />

      <Route
        isPrivate
        path="/:storeAliasId/dashboard/data/pricing-tool"
        component={PricingTool}
        exact
      />

      <Route
        isPrivate
        path="/:storeAliasId/dashboard/marketing/messages-instances"
        component={WhatsappInstances}
        exact
      />

      <Route
        isPrivate
        path="/:storeAliasId/dashboard/marketing/messages-instances/new-instance"
        component={WhatsappNewInstance}
        exact
      />
      <Route
        isPrivate
        path="/:storeAliasId/dashboard/marketing/automations/templates"
        component={AutomationTemplates}
        exact
      />

      <Route
        isPrivate
        path="/:storeAliasId/dashboard/marketing/automations/canvas/new"
        component={AutomationCanvas}
        exact
      />

      <Route
        isPrivate
        path="/:storeAliasId/dashboard/marketing/automations/canvas/workflow/:workflowAliasId"
        component={AutomationCanvas}
        exact
      />

      <Route
        isPrivate
        path="/:storeAliasId/dashboard/marketing/automations/messages"
        component={AutomationMessages}
        exact
      />

      <Route
        isPrivate
        path="/:storeAliasId/dashboard/marketing/automations"
        component={Automations}
        exact
      />

      <Route
        isPrivate
        path="/:storeAliasId/dashboard/marketing/automations/welcome"
        component={AutomationsWelcome}
        exact
      />
    </DashboardWrapper>
  );
};

export default React.memo(Dashboard);
