import React from 'react';
import { useParams } from 'react-router-dom';
import { Tiny, Copy } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from 'styled-components';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { tinySchema } from '@helpers/validators/dashboard/integrationCenter/ecommerce/tiny';

import tinyAuthService from '@services/pages/dashboard/integrationCenter/ecommerce/tiny/tinyAuth';

import Text from '@components/common/core/DataDisplay/Text';
import Form from '@components/common/core/Inputs/Form';
import ActiveIntegrationFooter from '@components/Dashboard/IntegrationCenter/Sidemodal/ActiveIntegrationFooter';

import { ETextWeight } from '@domain/enums/components/EText';
import * as S from './styles';

const IntegrateForm: React.FC = () => {
  const { mutateStore } = useStoreConfig();
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const theme = useTheme();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(tinySchema),
  });

  const [webhookURL, setWebhookURL] = React.useState<string>('');
  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [authTokenValue, setAuthTokenValue] = React.useState<string>('');
  const [isURLCopied, setIsURLCopied] = React.useState<boolean>(false);

  const authTokenRegister = register('auth_token');

  const onAuthTokenChange = React.useCallback(
    event => {
      authTokenRegister.onChange(event);

      setAuthTokenValue(event.target.value);
    },
    [authTokenRegister],
  );

  const onSubmit = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      const data = {
        auth_token: formData.auth_token,
      };

      try {
        await tinyAuthService.createTinyCredential({ storeAliasId, data });

        toast.success('Credencial criada com sucesso.');

        mutateStore();

        setIsSubmittingForm(false);
      } catch (error: any) {
        setIsSubmittingForm(false);
        toast.error(getTranslatedIntegrationsError(error));
      }
    },
    [storeAliasId, toast, mutateStore],
  );

  const handleCopyURL = React.useCallback(() => {
    navigator.clipboard.writeText(webhookURL);
    setIsURLCopied(true);
    toast.success('URL copiada com sucesso.');
  }, [toast, webhookURL]);

  const generateWebhookURL = React.useCallback(async () => {
    try {
      const { data } = await tinyAuthService.generateWebhookURL({ storeAliasId });

      setWebhookURL(data.webhook_url);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [toast, storeAliasId]);

  React.useEffect(() => {
    generateWebhookURL();
  }, [generateWebhookURL]);

  return (
    <S.ContentWrapper>
      <S.Header>
        <Tiny size={52} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com Tiny
        </S.Heading>
      </S.Header>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>
        <S.FunctionalityDescriptionText>
          A integração com sua plataforma de e-commerce serve para puxar seus dados de loja.
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <S.TutorialWrapper>
        <S.TutorialHeading type={EHeadingSize.H5}>Tutorial de integração</S.TutorialHeading>
        <S.TutorialWebhookText>
          Copie o link e configure o webhook na Tiny.{' '}
          <S.ArticleLink
            href="https://ajuda.profitfy.me/pt-BR/articles/8319258-como-integrar-com-a-tiny"
            target="_blank"
          >
            Veja nosso artigo para saber como configurar.
          </S.ArticleLink>
        </S.TutorialWebhookText>

        <S.WebhookURLWrapper onClick={handleCopyURL} type="button">
          <S.WebhookURL weight={ETextWeight.MEDIUM}>{webhookURL}</S.WebhookURL>
          <S.IconWrapper>
            <Copy size={18} color={theme.colors.gray[1]} outline={!isURLCopied} />
          </S.IconWrapper>
        </S.WebhookURLWrapper>
      </S.TutorialWrapper>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label htmlFor="auth-token">Token</S.Label>
          <S.Input
            {...authTokenRegister}
            type="text"
            id="auth-token"
            placeholder="Insira o token"
            onChange={onAuthTokenChange}
            value={authTokenValue}
            isError={Boolean(errors.auth_token)}
          />
          {errors.auth_token && <Text isErrorFeedback>{errors.auth_token.message}</Text>}
        </S.InputGroup>

        <ActiveIntegrationFooter isIntegrating={isSubmittingForm} />
      </Form>
    </S.ContentWrapper>
  );
};

export default IntegrateForm;
