import styled from 'styled-components/macro';
import TextBase from '@components/common/core/DataDisplay/Text';
import HeadingBase from '@components/common/core/DataDisplay/Heading';

import Detail from '@assets/pages/Onboarding/almostDone/detail.svg';
import LeftDown from '@assets/pages/Onboarding/almostDone/left-down.svg';
import LeftTop from '@assets/pages/Onboarding/almostDone/left-top.svg';
import RightTop from '@assets/pages/Onboarding/almostDone/right-top.svg';
import RightMiddle from '@assets/pages/Onboarding/almostDone/right-middle.svg';

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: linear-gradient(3.76deg, #000000 -18.27%, #050609 35.18%, #14161f 69.13%);

  &::after {
    content: '';
    position: absolute;
    bottom: 70px;
    right: 5%;
    width: 161px;
    height: 22px;
    background-image: url(${Detail});
    background-repeat: no-repeat;

    @media only screen and (max-width: 690px) {
      & {
        display: none;
      }
    }

    @media only screen and (max-height: 700px) {
      display: none;
    }
  }
`;

export const DetailLeftDown = styled.div`
  &::after {
    content: '';
    position: absolute;
    bottom: 104px;
    left: 94px;
    width: 20px;
    height: 142px;
    background-image: url(${LeftDown});
    background-repeat: no-repeat;

    @media only screen and (max-width: 690px) {
      & {
        display: none;
      }
    }
  }
`;

export const DetailLeftTop = styled.div`
  &::after {
    content: '';
    position: absolute;
    top: 204px;
    left: 94px;
    width: 60px;
    height: 62px;
    background-image: url(${LeftTop});
    background-repeat: no-repeat;

    @media only screen and (max-width: 690px) {
      & {
        top: 144px;
        left: 44px;
        display: none;
      }
    }
  }
`;

export const DetailRightTop = styled.div`
  &::after {
    content: '';
    position: absolute;
    top: 204px;
    right: 5%;
    width: 60px;
    height: 62px;
    background-image: url(${RightTop});
    background-repeat: no-repeat;

    @media only screen and (max-width: 690px) {
      & {
        top: 144px;
        right: 5%;
        display: none;
      }
    }
  }
`;
export const DetailRightMiddle = styled.div`
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 5%;
    width: 60px;
    height: 62px;
    background-image: url(${RightMiddle});
    background-repeat: no-repeat;

    @media only screen and (max-width: 690px) {
      & {
        top: 50%;
        right: 5%;
        display: none;
      }
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 40px;
  min-height: 100vh;
`;

export const Header = styled.div``;

export const IconWrapper = styled.div``;

export const LogoProfitfy = styled.img`
  max-width: 152px;
`;

export const Content = styled.div`
  /* background-color: red; */
  width: 100%;
  text-align: center;
  margin-top: 200px;

  @media only screen and (max-width: 690px) {
    margin-top: 100px;
  }

  @media only screen and (max-height: 768px) {
    margin-top: 48px;
  }
`;

export const Title = styled(TextBase)`
  font-size: 36px;

  @media only screen and (max-width: 690px) {
    font-size: 26px;
  }
`;

export const SubTitle = styled(TextBase)`
  font-size: 24px;

  @media only screen and (max-width: 690px) {
    font-size: 18px;
  }
`;

export const ProgressContainer = styled.div`
  margin-top: 40px;
  text-align: center;
`;

export const ProgressTitle = styled(HeadingBase)`
  margin-top: 20px;
  font-size: 25px;

  & > span {
    /* font-family: 'Poppins'; */
    font-family: 'Saira';
    font-weight: 500;
    font-size: 25px;
    color: ${({ theme }) => theme.colors.green.default};
  }

  @media only screen and (max-width: 690px) {
    font-size: 15px;

    & > span {
      font-size: 15px;
    }
  }
`;
