import React from 'react';
import { Plus } from '@profitfy/pakkun-icons';

import { useFilter } from '@helpers/hooks/useFilter';
import FilterPopoverContent from '../FilterPopoverContent';

import * as S from './styles';

const AddFilter: React.FC = () => {
  const { isAddNewFilterOpen, handleAddNewFilterOpen } = useFilter();

  return (
    <S.Popover open={isAddNewFilterOpen} onOpenChange={handleAddNewFilterOpen}>
      <S.Popover.Trigger>
        <S.Wrapper>
          <Plus size={12} />
        </S.Wrapper>
      </S.Popover.Trigger>

      {isAddNewFilterOpen && <FilterPopoverContent />}
    </S.Popover>
  );
};

export default AddFilter;
