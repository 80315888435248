import React from 'react';
import { FixedSizeList as List } from 'react-window';

import { useCampaigns } from '@helpers/hooks/pages/dashboard/adsManager/useCampaigns';

import Table from './Table';
import CampaingRow from './Table/Body';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const CampaignsTable: React.FC = () => {
  const {
    tableParentElementRef,
    mappedAdSenseCampaigns,
    isLoadingCampaigns,
    isValidatingCampaigns,
    isCampaignsError,
  } = useCampaigns();

  if (isLoadingCampaigns) {
    return <SkeletonLoading />;
  }

  if (isCampaignsError && !isValidatingCampaigns) {
    return <div>Error...</div>;
  }

  const offsetComparedToTop =
    window.pageYOffset + tableParentElementRef.current?.getBoundingClientRect().top || 0;
  const tableHeaderAndFooterHeight = 53 + 97;
  const maxHeight =
    window.innerHeight > 720
      ? window.innerHeight - offsetComparedToTop - tableHeaderAndFooterHeight
      : window.innerHeight - tableHeaderAndFooterHeight - 32;
  const mappedItemsLength = mappedAdSenseCampaigns?.length || 0;

  const listHeight = mappedItemsLength * 60 >= maxHeight ? maxHeight : mappedItemsLength * 60 + 18;

  return (
    <S.Wrapper ref={tableParentElementRef}>
      <List
        height={listHeight}
        innerElementType={Table}
        itemCount={mappedAdSenseCampaigns.length}
        itemSize={60}
        width="100%"
      >
        {CampaingRow}
      </List>
    </S.Wrapper>
  );
};

export default CampaignsTable;
