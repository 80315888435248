import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.div`
  flex: 1;
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${({ theme }) => theme.colors.gray[5]};
  position: relative;
`;

export const SelectedIntegration = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 16px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[5]};
`;

export const SelectedIntegrationName = styled(TextBase)`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[1]};
  margin-left: 10px;
`;

export const EmptyWrapper = styled.div`
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${({ theme }) => theme.colors.gray[5]};
`;

export const EmptyMessage = styled(TextBase)`
  font-size: 1.125rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[1]};
  text-align: center;
`;
