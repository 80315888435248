import ImageBase from '@components/common/core/DataDisplay/Image';
import TextBase from '@components/common/core/DataDisplay/Text';
import styled from 'styled-components/macro';

export const Title = styled(TextBase)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray[1]};
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.16px;
  width: ;
`;

export const Desciption = styled(TextBase)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray[2]};
  font-size: 1rem;
  font-weight: 500;
  line-height: 120%;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled(ImageBase)`
  max-width: 264px;
`;
