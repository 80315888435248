import React from 'react';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import { ETextSize } from '@domain/enums/components/EText';
import { EButtonTextColor } from '@domain/enums/components/EButton';
import { ICongratulationsModalProps } from '@domain/interfaces/onboarding/ICongratulationsModal';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';
import { useQuery } from '@helpers/hooks/useQuery';

import storesService from '@services/pages/stores/stores';

import CongratulationsImg from '@assets/pages/Onboarding/congratulations/goat-with-cake.svg';

import * as S from './styles';

const CongratulationsModal: React.FC<ICongratulationsModalProps> = ({ isOpen, toggle }) => {
  const { width, height } = useWindowSize();
  const { toast } = useToast();
  const { mutateStore, store } = useStoreConfig();
  const { storeAliasId } = useParams<IParams>();
  const theme = useTheme();
  const { analytics, user } = useConfig();
  const query = useQuery();

  const noRetroactiveDataQuery = query.get('no_retroactive_data');

  const hasNoRetroactiveData = noRetroactiveDataQuery === 'true';

  const [isFinishingOnboarding, setIsFinishingOnboarding] = React.useState<boolean>(false);

  const onAnalyzeDataButtonClick = React.useCallback(async () => {
    setIsFinishingOnboarding(true);

    try {
      await storesService.completeStoreOnboarding(storeAliasId, store);

      analytics?.track(
        'Onboarding Analyze My Data Button Clicked',
        {
          email: user?.email,
        },
        { context: { groupId: storeAliasId } },
      );
      analytics?.trackHubSpot(storeAliasId, { onboarding_analyze_my_data_button_clicked: true });

      await mutateStore();

      setIsFinishingOnboarding(false);
    } catch (error: any) {
      setIsFinishingOnboarding(false);
      toast.error(error?.response?.data?.message);
    }
  }, [mutateStore, store, storeAliasId, toast, analytics, user]);

  React.useEffect(() => {
    if (isOpen) {
      analytics?.track(
        'Onboarding Finished',
        {
          email: user?.email,
        },
        { context: { groupId: storeAliasId } },
      );
      analytics?.trackHubSpot(storeAliasId, { is_onboarding_finished: true });
    }
  }, [isOpen, analytics, user, storeAliasId]);

  const confettiColors = ['#25CFBB', '#6FD5C3', '#2EB3B4'];

  return (
    <>
      <Confetti
        style={{ zIndex: theme.zIndex.sixthLayer + 1 }}
        width={isOpen ? width - 24 : 0}
        height={isOpen ? height : 0}
        colors={confettiColors}
        initialVelocityX={10}
      />

      <S.Modal blockBackgroundClick isOpen={isOpen} toggle={toggle}>
        <S.Image src={CongratulationsImg} alt="profitfy-goat-with-cake" />

        <S.Title size={ETextSize.B2}>
          {hasNoRetroactiveData
            ? 'Parabéns! Você concluiu todas as configurações do onboarding.'
            : 'Parabéns! Seus dados de 30 dias foram processados.'}
        </S.Title>

        <S.Description size={ETextSize.B5}>
          {hasNoRetroactiveData
            ? 'Agora você poderá fazer uma análise mais ampla do desempenho de sua loja nesse período.'
            : 'Os dados retroativos de 30 dias foram processados, e agora você poderá fazer uma análise mais ampla do desempenho de sua loja nesse período.'}
        </S.Description>

        <S.AnalyzeDataButton
          onClick={onAnalyzeDataButtonClick}
          textColor={EButtonTextColor.DARK}
          isLoading={isFinishingOnboarding}
          disabled={isFinishingOnboarding}
        >
          Analisar meus dados
        </S.AnalyzeDataButton>
      </S.Modal>
    </>
  );
};

export default CongratulationsModal;
