import Text from '@components/common/core/DataDisplay/Text';
import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled(Text)`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 150%;
`;
