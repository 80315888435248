import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresCardCancelledValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresCardCancelledValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresCardCancelledValuesResponse => {
  const currentSelectedStoreCardCancelledAmountValue =
    currentSelectedStoreFinancialDetailsReportData?.card?.canceled?.amount;

  const currentSelectedStoreCardCancelledQuantityValue =
    currentSelectedStoreFinancialDetailsReportData?.card?.canceled?.quantity;

  const storesCardCancelledAmountValues = selectedStoresData?.map(
    value => value.financial_details_report?.card?.canceled?.amount,
  );

  const storesCardCancelledQuantityValues = selectedStoresData?.map(
    value => value.financial_details_report?.card?.canceled?.quantity,
  );

  const cardCancelledAmountValues = [
    ...storesCardCancelledAmountValues,
    currentSelectedStoreCardCancelledAmountValue,
  ];

  const cardCancelledQuantityValues = [
    ...storesCardCancelledQuantityValues,
    currentSelectedStoreCardCancelledQuantityValue,
  ];

  const reducedCardCancelledAmountValues = cardCancelledAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedCardCancelledQuantityValues = cardCancelledQuantityValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  return {
    card_cancelled_amount: reducedCardCancelledAmountValues,
    card_cancelled_quantity: reducedCardCancelledQuantityValues,
  };
};
