import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import LinkBase from '@components/common/core/Navigation/Link';

export const ReturnWrapper = styled.div``;

export const Text = styled(TextBase)`
  color: ${({ theme }) => theme.colors.background[6]};
  transition: all 0.3s;
`;

export const Link = styled(LinkBase)`
  display: flex;
  align-items: center;
  gap: 16px;

  &:hover {
    filter: none;

    & > span {
      color: ${({ theme }) => theme.colors.green.default};
    }
  }
`;
