import React from 'react';

import { EEcommerceProvider } from '@domain/enums/common/integrations/EEcommerceProvider';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import IntegrateForm from './IntegrateForm';
import YampiConfig from './YampiConfig';

const Yampi: React.FC = () => {
  const { storeIntegrations } = useStoreConfig();

  const { store_front } = storeIntegrations;

  const foundIntegration = store_front.find(
    integration => integration.name === EEcommerceProvider.YAMPI,
  );

  if (foundIntegration?.is_active) {
    return <YampiConfig />;
  }

  return <IntegrateForm />;
};

export default Yampi;
