import { firstFormSchema } from '@helpers/validators/signUp';

export const getFormSchema = (step: number): any => {
  const { firstStepFormSchema, secondStepFormSchema, thirdStepFormSchema } = firstFormSchema;

  if (step === 1) return secondStepFormSchema;

  if (step === 2) return thirdStepFormSchema;

  return firstStepFormSchema;
};
