import { EGroup } from '@domain/enums/dashboard/customValuesCategories/EGroup';

export const PRE_DEFINED_CUSTOM_VALUES_CATEGORIES = [
  {
    name: 'Aluguel',
    group: EGroup.ADMINISTRATIVE_EXPENSES,
    hex_color: '#33FF71',
  },
  {
    name: 'Folha de Pagamento',
    group: EGroup.ADMINISTRATIVE_EXPENSES,
    hex_color: '#FF960F',
  },
  {
    name: 'Contabilidade',
    group: EGroup.ADMINISTRATIVE_EXPENSES,
    hex_color: '#03D2E7',
  },
  {
    name: 'Softwares e Aplicativos',
    group: EGroup.GENERAL_EXPENSES,
    hex_color: '#4634E7',
  },
  {
    name: 'Despesas com Alimentação',
    group: EGroup.GENERAL_EXPENSES,
    hex_color: '#F2FF25',
  },
  {
    name: 'Despesas com tráfego pago',
    group: EGroup.MARKETING_EXPENSES,
    hex_color: '#2553FF',
  },
  {
    name: 'Taxa do Gateway Sobre Transações',
    group: EGroup.TAX,
    hex_color: '#869FFE',
  },
  {
    name: 'Custo de Frete',
    group: EGroup.SHIPPING_EXPENSES,
    hex_color: '#DC1FB6',
  },
  {
    name: 'Cashback',
    group: EGroup.FINANCIAL_INCOMES,
    hex_color: '#C70039',
  },
  {
    name: 'Dividendos',
    group: EGroup.FINANCIAL_EXPENSES,
    hex_color: '#FFC300',
  },
];
