import React from 'react';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { IImpressions } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/ISummaryReport';

import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const Prints: React.FC<ICampaignProps> = ({ data }) => {
  const printsData: IImpressions = data;

  return (
    <S.Wrapper>
      <S.PrintsAmount>{`${numberFormatter(printsData.total_quantity, 0, true)}`}</S.PrintsAmount>
      <S.PrintsLabel>Total</S.PrintsLabel>
    </S.Wrapper>
  );
};

export default Prints;
