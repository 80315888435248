import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';

export const Logo = styled(ImageBase)`
  max-width: 192px;
  display: block;
  margin: 0 auto;
  margin-bottom: 48px;
`;

export const Content = styled.div`
  max-width: 1262px;
  width: 100%;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.black.default};
  display: flex;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
`;
