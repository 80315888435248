import React from 'react';

import { ShippingProvider } from '@helpers/hooks/pages/dashboard/shipping/useShipping';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import Content from '@components/Dashboard/Shipping';
import NoAccess from '@components/common/core/Utils/NoAccess';

import { EUserStoreRole } from '@domain/enums/common/userStore/EUserStoreRole';

const Shipping: React.FC = () => {
  const { accessManagementData } = useStoreConfig();
  const { isInternalTeam } = useConfig();

  const isStoreOwner = accessManagementData?.role === EUserStoreRole.OWNER;
  const hasAccessToPage =
    accessManagementData?.group?.has_checkout_fee_full_write_access ||
    accessManagementData?.group?.has_checkout_fee_full_read_access;

  if (!isStoreOwner && !hasAccessToPage && !isInternalTeam) {
    return <NoAccess />;
  }

  return (
    <ShippingProvider>
      <Content />
    </ShippingProvider>
  );
};

export default Shipping;
