import React from 'react';

import Header from '@components/Dashboard/AdminPanel/IdeasChannel/OverviewIdeas/Header';
import Idea from '@components/Dashboard/AdminPanel/IdeasChannel/OverviewIdeas/Idea';
import Paginate from '@components/common/core/Utils/Paginate';

import { useSuggestions } from '@helpers/hooks/pages/dashboard/ideasChannel/useSuggestions';

import * as S from './styles';

const AdminOverviewIdeas: React.FC = () => {
  const { onPageChange, page, pageCount, suggestions } = useSuggestions();

  return (
    <S.Container>
      <Header />

      <Idea />

      {Boolean(suggestions?.length) && (
        <Paginate forcePage={page} pageCount={pageCount} onPageChange={onPageChange} />
      )}
    </S.Container>
  );
};

export default AdminOverviewIdeas;
