import React from 'react';

import { IDayPickerProps } from '@domain/interfaces/components/IDayPicker';
import CustomDate from './CustomDate';

import * as S from './styles';

const DayPicker: React.FC<IDayPickerProps> = ({
  selectedDate,
  maxDate,
  minDate,
  setSelectedDate,
  ...rest
}) => {
  const [isCustomDatePickerOpen, setIsCustomDatePickerOpen] = React.useState<boolean>(false);

  const handleIsCustomDatePickerOpen = React.useCallback(
    () => setIsCustomDatePickerOpen(!isCustomDatePickerOpen),
    [isCustomDatePickerOpen],
  );

  return (
    <S.Container {...rest}>
      <CustomDate
        isOpen={isCustomDatePickerOpen}
        handleIsOpen={handleIsCustomDatePickerOpen}
        maxDate={maxDate}
        minDate={minDate}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
    </S.Container>
  );
};

export default DayPicker;
