import React from 'react';

import { FacebookCredentialsProvider } from '@helpers/hooks/common/integrations/marketing/facebook/useFacebookCredentials';

import Wrapper from './Wrapper';

const Facebook: React.FC = () => {
  return (
    <FacebookCredentialsProvider>
      <Wrapper />
    </FacebookCredentialsProvider>
  );
};

export default Facebook;
