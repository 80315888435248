import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresBoletoCompensatedValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresBoletoCompensatedValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresBoletoCompensatedValuesResponse => {
  const currentSelectedStoreBoletoCompensatedAmountValue =
    currentSelectedStoreFinancialDetailsReportData?.boleto?.compensated?.amount;

  const currentSelectedStoreBoletoCompensatedQuantityValue =
    currentSelectedStoreFinancialDetailsReportData?.boleto?.compensated?.quantity;

  const storesBoletoCompensatedAmountValues = selectedStoresData?.map(
    value => value.financial_details_report?.boleto?.compensated?.amount,
  );

  const storesBoletoCompensatedQuantityValues = selectedStoresData?.map(
    value => value.financial_details_report?.boleto?.compensated?.quantity,
  );

  const boletoCompensatedAmountValues = [
    ...storesBoletoCompensatedAmountValues,
    currentSelectedStoreBoletoCompensatedAmountValue,
  ];

  const boletoCompensatedQuantityValues = [
    ...storesBoletoCompensatedQuantityValues,
    currentSelectedStoreBoletoCompensatedQuantityValue,
  ];

  const reducedBoletoCompensatedAmountValues = boletoCompensatedAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedBoletoCompensatedQuantityValues = boletoCompensatedQuantityValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  return {
    boleto_compensated_amount: reducedBoletoCompensatedAmountValues,
    boleto_compensated_quantity: reducedBoletoCompensatedQuantityValues,
  };
};
