import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import TextBase from '@components/common/core/DataDisplay/Text';
import BadgeBase from '@components/common/core/DataDisplay/Badge';

export const Table = styled(TableBase)``;

export const MemberName = styled(TextBase)``;

export const EmailText = styled(TextBase)``;

export const EmailAndGroupNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MemberNameTableDataContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 6px 0;
`;

export const Badge = styled(BadgeBase)`
  @media only screen and (max-width: 620px) {
    margin-left: 32px;
  }
`;

export const EmailTableDataContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
`;

export const CheckboxWrapper = styled.div`
  width: fit-content;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.gray[7]};
`;

export const Checkbox = styled(CheckboxBase)``;

export const GroupName = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const TableDataContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
`;
