import React from 'react';

import { MarketingIntegrationProvider } from '@helpers/hooks/pages/dashboard/adsManager/onboarding/useMarketingIntegration';
import Wrapper from '@components/Dashboard/AdsManager/UtmOnboarding/MarketingIntegration/Wrapper';

const MarketingIntegration: React.FC = () => {
  return (
    <MarketingIntegrationProvider>
      <Wrapper />
    </MarketingIntegrationProvider>
  );
};

export default MarketingIntegration;
