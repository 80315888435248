import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const IdText = styled(TextBase)`
  font-size: 0.875rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const NameText = styled(TextBase)`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.gray[2]};
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const OptionButton = styled.button`
  background-color: unset;
  border: unset;
  padding: unset;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray[1]};
  font-weight: 500;
  line-height: 120%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    & > span {
      color: ${({ theme }) => theme.colors.green.default};
    }
  }

  &:active {
    & > span {
      color: ${({ theme }) => theme.colors.green.dark};
    }
  }
`;

export const ValueToFilterInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  background-color: unset;
  border: none;
  color: ${({ theme }) => theme.colors.font};
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;

  &::placeholder {
    color: ${({ theme }) => theme.colors.font};
    opacity: 0.6;
  }

  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.font};
    opacity: 0.6;
  }

  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.font};
    opacity: 0.6;
  }

  &:focus {
    outline: none;
  }
`;

export const ValueToFilterWrapper = styled.div`
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[5]};
`;

export const Wrapper = styled.div`
  max-height: 364px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 4px;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.gray[4]};
    position: absolute;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[1]};
    border-radius: 25px;
    transition: all 0.3s;
  }

  ::-webkit-scrollbar-thumb:hover {
    opacity: brightness(0.8);
  }

  & > div > div {
    display: flex;
    flex-direction: column;
    padding: 16px 24px 20px 24px;
    box-sizing: border-box;
    align-items: flex-start;
    gap: 20px;
  }
`;
