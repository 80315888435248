import styled from 'styled-components/macro';

import SideModalBase from '@components/common/core/DataDisplay/SideModal';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import LabelBase from '@components/common/core/DataDisplay/Label';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';

export const SideModal = styled(SideModalBase)`
  max-width: 520px;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  gap: 26px;
  align-items: center;
  margin-bottom: 40px;
`;

export const Title = styled(HeadingBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 32px;
`;

export const TextField = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const Button = styled(ButtonBase)`
  margin-left: auto;
`;
