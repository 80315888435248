import { v4 } from 'uuid';

import IWorkflowNode from '@domain/interfaces/mappers/automations/profitfy/IWorkflowNode';
import IWorkflowEdge from '@domain/interfaces/mappers/automations/profitfy/IWorkflowEdge';
import { ENodeType } from '@domain/enums/mappers/automations/profitfy/ENodeType';

interface ICreateNode {
  id: string;
  type: ENodeType;
  name: string;
}

const createNode = ({ id, name, type }: ICreateNode): IWorkflowNode => {
  const newNode: IWorkflowNode = {
    provider_id: id,
    type,
    position_x: Math.random() * 500,
    position_y: Math.random() * 500,
    height: 30,
    width: 30,
    name,
    metadata: {
      label: type,
      name,
    },
  };
  return newNode;
};

interface ICreateInternalFinishNode {
  rawNodes: IWorkflowNode[];
  rawEdges: IWorkflowEdge[];
}

export interface ICreateInternalFinishNodeResponse {
  nodes: IWorkflowNode[];
  edges: IWorkflowEdge[];
}

interface IInternalFinishNodeSwithProps {
  currentNode: IWorkflowNode;
  nodes: IWorkflowNode[];
  edges: IWorkflowEdge[];
}

interface IInternalFinishNodeSwithResponse {
  nodes: IWorkflowNode[];
  edges: IWorkflowEdge[];
  edgesToExclude: IWorkflowEdge[];
}

export const createInternalFinishNode = ({
  rawEdges,
  rawNodes,
}: ICreateInternalFinishNode): ICreateInternalFinishNodeResponse => {
  const newNodes = [];
  const newEdges = [];
  const edgesToRemove: IWorkflowEdge[] = [];

  for (const rawNode of rawNodes) {
    if (rawNode.type !== ENodeType.IF && rawNode.type !== ENodeType.SWITCH) {
      newNodes.push(rawNode);
      continue;
    }

    if (rawNode.type === ENodeType.IF) {
      const foundNegativeHandle = rawEdges.find(
        edge => edge.source === rawNode.provider_id && edge.source_handle === 'negative',
      );

      const foundNegativeHandleNode = rawNodes.find(
        node => node.provider_id === foundNegativeHandle?.target,
      );

      if (!foundNegativeHandleNode) {
        const newWorkflowStatusNodeId = v4();
        const newWorkflowStatusNode = createNode({
          id: newWorkflowStatusNodeId,
          name: `connectionFrom${rawNode.name}`,
          type: ENodeType.INTERNAL_WORKFLOW_STATUS,
        });

        newNodes.push(newWorkflowStatusNode);
        newNodes.push(rawNode);

        const newEdgeFromNodeIf: IWorkflowEdge = {
          provider_id: `internalEdge${v4()}`,
          source: rawNode.provider_id as string,
          target: newWorkflowStatusNodeId,
          source_handle: 'negative',
          target_handle: undefined,
        };

        newEdges.push(newEdgeFromNodeIf);
      }

      if (foundNegativeHandleNode?.type === ENodeType.END) {
        const newWorkflowStatusNodeId = v4();
        const newWorkflowStatusNode = createNode({
          id: newWorkflowStatusNodeId,
          name: `connectionTo${foundNegativeHandleNode.name}`,
          type: ENodeType.INTERNAL_WORKFLOW_STATUS,
        });

        newNodes.push(newWorkflowStatusNode);
        newNodes.push(rawNode);

        edgesToRemove.push(foundNegativeHandle as IWorkflowEdge);

        const newEdgeForWorkflowStatusNode: IWorkflowEdge = {
          provider_id: `internalEdge${v4()}`,
          source: rawNode.provider_id as string,
          target: newWorkflowStatusNodeId,
          source_handle: 'negative',
          target_handle: foundNegativeHandle?.target_handle,
        };

        newEdges.push(newEdgeForWorkflowStatusNode);

        const newEdgeForEndNode: IWorkflowEdge = {
          provider_id: `internalEdge${v4()}`,
          source: newWorkflowStatusNodeId,
          target: foundNegativeHandleNode?.provider_id as string,
          source_handle: undefined,
          target_handle: undefined,
        };

        newEdges.push(newEdgeForEndNode);
      }
    }

    if (rawNode.type === ENodeType.SWITCH) {
      const newWorkflowStatusNodeId = v4();
      const internalNode = createNode({
        id: newWorkflowStatusNodeId,
        name: `connectionFrom${rawNode.name}`,
        type: ENodeType.INTERNAL_WORKFLOW_STATUS,
      });

      newNodes.push(internalNode);
      newNodes.push(rawNode);

      const newEdgeForWorkflowStatusNode: IWorkflowEdge = {
        provider_id: `internalEdge${v4()}`,
        source: rawNode?.provider_id as string,
        target: newWorkflowStatusNodeId,
        source_handle: 'fourth',
        target_handle: undefined,
      };

      newEdges.push(newEdgeForWorkflowStatusNode);
    }
  }

  const filteredEdges = rawEdges.filter(
    rawEdge => !edgesToRemove.some(edge => edge.provider_id === rawEdge.provider_id),
  );

  filteredEdges.push(...newEdges);

  return {
    edges: filteredEdges,
    nodes: newNodes,
  };
};

interface ICreateInternalOrderStatusNode {
  rawNodes: IWorkflowNode[];
  rawEdges: IWorkflowEdge[];
}

interface ICreateInternalOrderStatusNodeResponse {
  nodes: IWorkflowNode[];
  edges: IWorkflowEdge[];
}

export const createInternalOrderStatusNode = ({
  rawEdges,
  rawNodes,
}: ICreateInternalOrderStatusNode): ICreateInternalOrderStatusNodeResponse => {
  const newEdges = [];
  const newNodes = [];

  const edgesToRemove: IWorkflowEdge[] = [];

  for (const rawNode of rawNodes) {
    if (rawNode.type !== ENodeType.IF && rawNode.type !== ENodeType.SWITCH) {
      newNodes.push(rawNode);
      continue;
    }

    const isOrderStatusRule = rawNode.metadata.data.rules.some(
      (rule: any) => rule.type === 'ORDER_STATUS',
    );

    if (!isOrderStatusRule) {
      newNodes.push(rawNode);
      continue;
    }

    const newOrderStatusNodeId = v4();
    const newOrderStatusNode = createNode({
      id: newOrderStatusNodeId,
      name: `connectionTo${rawNode.name}`,
      type: ENodeType.INTERNAL_ORDER_STATUS,
    });

    newNodes.push(newOrderStatusNode);
    newNodes.push(rawNode);

    const foundEdge = rawEdges.find(rawEdge => {
      return rawEdge.target === rawNode.provider_id;
    });

    if (!foundEdge) continue;

    edgesToRemove.push(foundEdge);

    const newEdgeForOrderStatusNode: IWorkflowEdge = {
      provider_id: `internalEdge${v4()}`,
      source: foundEdge?.source,
      target: newOrderStatusNodeId,
      source_handle: foundEdge?.source_handle,
      target_handle: undefined,
    };

    newEdges.push(newEdgeForOrderStatusNode);

    const newEdgeForRawNode: IWorkflowEdge = {
      provider_id: `internalEdge${v4()}`,
      source: newOrderStatusNodeId,
      target: foundEdge.target,
      source_handle: undefined,
      target_handle: foundEdge.target_handle,
    };

    newEdges.push(newEdgeForRawNode);
  }

  const filteredEdges = rawEdges.filter(
    rawEdge =>
      !edgesToRemove.some(edgeToRemove => edgeToRemove.provider_id === rawEdge.provider_id),
  );

  filteredEdges.push(...newEdges);

  return {
    edges: filteredEdges,
    nodes: newNodes,
  };
};

interface ICreateInternalSellRecoveredStatusNode {
  rawNodes: IWorkflowNode[];
  rawEdges: IWorkflowEdge[];
}

interface ICreateInternalSellRecoveredNodeResponse {
  nodes: IWorkflowNode[];
  edges: IWorkflowEdge[];
}

export const createInternalSellRecoveredStatusNode = ({
  rawEdges,
  rawNodes,
}: ICreateInternalSellRecoveredStatusNode): ICreateInternalSellRecoveredNodeResponse => {
  const newEdges = [];
  const newNodes = [];

  const edgesToRemove: IWorkflowEdge[] = [];

  for (const currentNode of rawNodes) {
    if (currentNode.type !== ENodeType.IF) {
      newNodes.push(currentNode);
      continue;
    }

    const isSellRecoveredRule = currentNode.metadata.data.rules.some(
      (rule: any) => rule.type === 'SELL_RECOVERED',
    );

    if (!isSellRecoveredRule) {
      newNodes.push(currentNode);
      continue;
    }

    const newId = v4();
    const newSellRecoveredStatusNode = createNode({
      id: newId,
      name: `connectionTo${currentNode.name}`,
      type: ENodeType.INTERNAL_SELL_RECOVERED_STATUS,
    });

    newNodes.push(newSellRecoveredStatusNode);
    newNodes.push(currentNode);

    const foundEdge = rawEdges.find(rawEdge => {
      return rawEdge.target === currentNode.provider_id;
    });

    if (!foundEdge) continue;

    edgesToRemove.push(foundEdge);

    const newEdgeForOrderStatusNode: IWorkflowEdge = {
      provider_id: `internalEdge${v4()}`,
      source: foundEdge?.source,
      target: newId,
      source_handle: foundEdge?.source_handle,
      target_handle: undefined,
    };

    newEdges.push(newEdgeForOrderStatusNode);

    const newEdgeForRawNode: IWorkflowEdge = {
      provider_id: `internalEdge${v4()}`,
      source: newId,
      target: foundEdge.target,
      source_handle: undefined,
      target_handle: foundEdge.target_handle,
    };

    newEdges.push(newEdgeForRawNode);
  }

  const filteredEdges = rawEdges.filter(
    rawEdge =>
      !edgesToRemove.some(edgeToRemove => edgeToRemove.provider_id === rawEdge.provider_id),
  );

  filteredEdges.push(...newEdges);

  return {
    edges: filteredEdges,
    nodes: newNodes,
  };
};
