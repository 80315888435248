import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import NoImage from '@components/common/core/DataDisplay/NoImage';

export const Product = styled.div`
  padding: 8px 16px;
  display: grid;
  grid-template-columns: 40px 2fr 1fr;
  align-items: center;
  height: 48px;
  box-sizing: border-box;

  background-color: ${({ theme }) => theme.colors.background[2]};

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[4]};
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
`;

export const NoImageFill = styled(NoImage)`
  min-width: 55px;
  width: 55px;
  min-height: 55px;
  height: 55px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.gray[4]};
`;

export const ProductName = styled(TextBase)`
  font-weight: 400;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray[1]};
  width: 200px;
  padding: 0;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0 5px 0 5px;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CheckBox = styled(CheckboxBase)`
  justify-self: end;
`;
