import React from 'react';

import { ISelectMessageProps } from '@domain/interfaces/dashboard/Automations/Canvas/Node/WhatsAppNode/IWhatsAppNodeModalProps';

import { useCanvasMessage } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessage';

import MessageSkeletonLoading from '@components/Dashboard/Automations/Messages/SkeletonLoading';

import WebSearchDark from '@assets/dashboard/automations/canvas/empty-instances.svg';
import Message from './Message';

import * as S from './styles';

const SelectMessage: React.FC<ISelectMessageProps> = ({ toggleModal }) => {
  const { messages, isLoadingMessages, isValidatingMessages } = useCanvasMessage();

  const isLoading = isLoadingMessages || isValidatingMessages;

  const hasMessages = Boolean(messages?.length) && !isLoading;

  return (
    <S.MessagesWrapper hasMessages={hasMessages}>
      {isLoading && <MessageSkeletonLoading />}

      {hasMessages &&
        messages?.map(message => {
          return <Message key={message.id} message={message} toggleModal={toggleModal} />;
        })}

      {!hasMessages && !isLoading && (
        <S.EmptyDataWrapper>
          <S.Image src={WebSearchDark} alt="Messages not found" />
          <S.NoMessagesText>Parece que você não possui nenhuma mensagem criada</S.NoMessagesText>
        </S.EmptyDataWrapper>
      )}
    </S.MessagesWrapper>
  );
};

export default SelectMessage;
