import React from 'react';

import { WarningCircle, X } from 'phosphor-react';
import { useLocation, useHistory } from 'react-router-dom';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { ETextWeight, ETextSize } from '@domain/enums/components/EText';
import { IPendingPaymentProps } from '@domain/interfaces/dashboard/SubscriptionAlerts/IPendingPayment';

import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

export const PendingPayment: React.FC<IPendingPaymentProps> = ({ onClose }) => {
  const history = useHistory();

  const { user } = useConfig();
  const { pathname } = useLocation();

  const [storeAliasId, setStoreAliasId] = React.useState<string>('');

  const onChangePaymentMethod = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/subscription?updateCard=true`);
  }, [history, storeAliasId]);

  React.useEffect(() => {
    const foundStoreAliasId = pathname.match(/store-([a-zA-Z0-9]+)/);

    if (foundStoreAliasId?.length) {
      setStoreAliasId(foundStoreAliasId[0]);
    }
  }, [pathname]);

  const titleText = `Desculpe, ${user.first_name}. Infelizmente, seu pagamento foi negado.`;

  return (
    <S.Container>
      <S.Content>
        <S.IconAndTextWrapper>
          <WarningCircle size={26} color="#553700" weight="fill" />

          <S.TextWrapper>
            <S.TitleText weight={ETextWeight.BOLD}>{titleText}</S.TitleText>

            <S.DescriptionText size={ETextSize.B5}>
              Por favor, verifique se o limite do seu cartão de crédito cadastrado ainda está
              disponível ou altere a forma de pagamento.
            </S.DescriptionText>
          </S.TextWrapper>
        </S.IconAndTextWrapper>

        <S.EditPaymentMethod
          variant={EButtonVariant.SECONDARY_BUTTON}
          onClick={onChangePaymentMethod}
        >
          Atualizar cartão de crédito
        </S.EditPaymentMethod>

        <S.CloseIcon onClick={onClose}>
          <X size={20} color="black" weight="bold" />
        </S.CloseIcon>
      </S.Content>
    </S.Container>
  );
};

export default PendingPayment;
