import styled from 'styled-components/macro';

import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';

export const Wrapper = styled.div`
  width: 33px;
  height: 33px;
  border: 1px solid ${({ theme }) => theme.colors.gray[1]};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.colors.green.default};
    filter: brightness(1.1);
  }

  &:active {
    border-color: ${({ theme }) => theme.colors.green.default};
    filter: brightness(0.9);
  }
`;

export const Popover = styled(CustomPopoverBase)``;
