import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ImageBase from '@components/common/core/DataDisplay/Image';
import { IHowToAddTheScriptProps } from '@domain/interfaces/dashboard/AdsManager/Onboarding/ScriptsAndUtms';

export const StepImage = styled(ImageBase)``;

export const StepDescription = styled(TextBase)`
  line-height: 120%;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const StepNumber = styled.span`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #24363f;
  color: ${({ theme }) => theme.colors.green.default};
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: 1.125rem;
  font-weight: 700;
`;

export const StepHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Step = styled.div`
  padding: 0 24px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 40px;
  padding: 0 24px;
`;

export const StepsWrapper = styled.div<IHowToAddTheScriptProps>`
  overflow-y: scroll;
  max-height: ${({ scrollOffset }) => `calc(100vh - ${scrollOffset}px)`};

  @media only screen and (max-width: 1348px) {
    max-height: 424px;
  }

  ::-webkit-scrollbar {
    width: 5px;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.gray[4]};
    position: absolute;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[2]};
    border-radius: 25px;
    transition: all 0.3s;
  }

  ::-webkit-scrollbar-thumb:hover {
    opacity: brightness(0.8);
  }
`;

export const Wrapper = styled.div<IHowToAddTheScriptProps>`
  background-color: ${({ theme }) => theme.colors.gray[6]};
  padding: 44px 0;
  max-width: 540px;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  right: 0;
  top: ${({ scrollOffset }) => `${scrollOffset}px`};
  max-height: ${({ scrollOffset }) => `calc(100vh - ${scrollOffset}px)`};
  transition: top 0.2s, max-height 0.3s;

  @media only screen and (max-width: 1348px) {
    max-width: 720px;
    position: relative;
    top: unset;
    max-height: unset;
    margin-bottom: 24px;
    border-radius: 8px;
    padding: 24px 0;
  }
`;
