import { EBadgeColors } from '@domain/enums/components/EBadge';

export const getFrequency = {
  ONCE: EBadgeColors.SKY_BLUE,
  DAILY: EBadgeColors.GREEN,
  WEEKLY: EBadgeColors.ORANGE,
  MONTHLY: EBadgeColors.YELLOW,
  QUARTERLY: EBadgeColors.GRAY,
  SEMI_ANNUALY: EBadgeColors.PURPLE,
  ANNUALLY: EBadgeColors.MAROON,
};

export const getFrequencyName = {
  ONCE: 'Única',
  DAILY: 'Diária',
  WEEKLY: 'Semanal',
  MONTHLY: 'Mensal',
  QUARTERLY: 'Trimestral',
  SEMI_ANNUALY: 'Semestral',
  ANNUALLY: 'Anual',
};
