import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import ButtonBase from '@components/common/core/Inputs/Button';

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  gap: 16px;
`;

export const Heading = styled(HeadingBase)``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 12px;
  padding: 24px;
`;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)``;

export const Button = styled(ButtonBase)`
  width: fit-content;
  margin-left: auto;
`;
