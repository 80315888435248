import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import CustomHoverCardBase from '@components/common/core/Utils/CustomHoverCard';

export const CustomHoverCardContent = styled(CustomHoverCardBase.Content)``;

export const CustomHoverCardTrigger = styled(CustomHoverCardBase.Trigger)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 4px;
  overflow: hidden;
  padding: 10px 12px;
  height: 58px;
  min-height: 58px;
  box-sizing: border-box;
  width: calc(100% + 24px);
  margin-right: -12px;
`;

export const CustomHoverCard = styled(CustomHoverCardBase)``;

export const ForecastText = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[2]};
  display: flex;
  align-items: center;
  gap: 4px;
  filter: blur(5px);
  user-select: none;
`;

export const NetRevenueValue = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[1]};
  filter: blur(5px);
  user-select: none;
`;
