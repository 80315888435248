import { AxiosResponse } from 'axios';

import {
  ICreateLegalPersonProps,
  ICreateLegalPersonResponse,
  IGetLegalPersonPromiseResponse,
  IGetLegalPersonProps,
  IGetLegalPersonResponse,
  IUpdateLegalPersonProps,
  IUpdateLegalPersonResponse,
  IDeleteLegalPersonProps,
} from '@domain/interfaces/common/legalPerson/ILegalPerson';

import { useFetch } from '@providers/Fetch/useFetch';

import { ApiService } from '@services/api';

import { USER_API_DOMAIN } from '@constants/api';

export class LegalPersonService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getLegalPerson({ storeAliasId }: IGetLegalPersonProps): IGetLegalPersonResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/legal-people`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      legalPerson: data?.legal_person,
      error,
      mutate,
      isLoading,
      isValidating,
    };
  }

  public getLegalPersonPromise({
    storeAliasId,
  }: IGetLegalPersonProps): Promise<AxiosResponse<IGetLegalPersonPromiseResponse>> {
    return this.apiService.get<IGetLegalPersonPromiseResponse>(
      `/api/v1/users/stores/${storeAliasId}/legal-people`,
    );
  }

  public createLegalPerson({
    storeAliasId,
    data,
  }: ICreateLegalPersonProps): Promise<AxiosResponse<ICreateLegalPersonResponse>> {
    return this.apiService.post<ICreateLegalPersonResponse>(
      `/api/v1/users/stores/${storeAliasId}/legal-people`,
      { ...data },
    );
  }

  public updateLegalPerson({
    storeAliasId,
    data,
  }: IUpdateLegalPersonProps): Promise<AxiosResponse<IUpdateLegalPersonResponse>> {
    return this.apiService.put<IUpdateLegalPersonResponse>(
      `/api/v1/users/stores/${storeAliasId}/legal-people`,
      { ...data },
    );
  }

  public deleteLegalPerson({
    storeAliasId,
  }: IDeleteLegalPersonProps): Promise<AxiosResponse<void>> {
    return this.apiService.delete<void>(`/api/v1/users/stores/${storeAliasId}/legal-people`);
  }
}

const legalPersonService = new LegalPersonService(USER_API_DOMAIN);

export default legalPersonService;
