import React from 'react';
import { CircleSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components/macro';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { ECampaignStatus } from '@domain/enums/dashboard/adsManager/ECampaigns';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';

import { getCampaignsStatus } from '@helpers/utils/dashboard/adsManager/campaignsParser';

import { useCampaigns } from '@helpers/hooks/pages/dashboard/adsManager/useCampaigns';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import * as S from './styles';

const Switch: React.FC<ICampaignProps> = ({ rowAliasId, data, adSenseCampaignAliasId }) => {
  const switchData: ECampaignStatus = data;

  const { onUpdateStatus } = useCampaigns();
  const { currentSynchronizations, synchronizationsQueue } = useSynchronization();
  const theme = useTheme();

  const switchRef = React.useRef<HTMLInputElement>(null);

  const onSwitchChange = React.useCallback(
    event => {
      const status = getCampaignsStatus(event.target.checked);

      onUpdateStatus(status, [adSenseCampaignAliasId]);
    },
    [adSenseCampaignAliasId, onUpdateStatus],
  );

  const isActive = switchData === ECampaignStatus.ENABLED;

  const parsedCurrentSynchronizations = currentSynchronizations
    ? currentSynchronizations.synchronizations
    : [];
  const mergedSynchronizations = [...parsedCurrentSynchronizations, ...synchronizationsQueue];
  const filteredStatusSynchronizations = mergedSynchronizations.filter(synchronization => {
    return (
      synchronization.type === ESynchronizationType.ADS_MANAGER_CAMPAIGNS_STATUS ||
      synchronization.type === ESynchronizationType.ADS_MANAGER_DUPLICATE_CAMPAIGNS
    );
  });

  const isSyncing = Boolean(
    filteredStatusSynchronizations.find(synchronization =>
      synchronization.externalId?.includes(adSenseCampaignAliasId),
    ),
  );

  return (
    <S.Wrapper>
      {isSyncing && (
        <S.SpinnerWrapper>
          <CircleSpinner size={16} color={theme.colors.green.default} />
        </S.SpinnerWrapper>
      )}

      <S.Switch
        name={`switch-${rowAliasId}`}
        id={`switch-${rowAliasId}`}
        checked={isActive}
        onChange={onSwitchChange}
        disabled={isSyncing}
        innerRef={switchRef}
      />
    </S.Wrapper>
  );
};

export default Switch;
