import styled from 'styled-components/macro';

export const ChartContainer = styled.div`
  max-width: 860px;
  width: 100%;
  position: relative;
  border-radius: 5px;
  margin-bottom: 20px;

  span {
    display: block;
  }

  @media only screen and (max-width: 1360px) {
    max-width: unset;
  }
`;
