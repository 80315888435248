import React from 'react';

import { ClapSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components/macro';
import { useConfig } from '@helpers/hooks/useConfig';
import { useCampaigns } from '@helpers/hooks/pages/dashboard/campaigns/useCampaigns';
import { PRODUCT_ANALYTICS_CONFIG } from '@helpers/utils/filters/productAnalyticsFilter';

import PageError from '@components/common/core/Utils/PageError';
import SideModalTags from '@components/Dashboard/ProductAnalytics/Tags/SideModalTags';
import Filter from '@components/common/core/Utils/Filter';
import Header from '../Header';
import Table from '../Table';

import { TableSkeletonLoading } from '../SkeletonLoading';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const theme: any = useTheme();

  const { analytics, selectedStore } = useConfig();

  const {
    adSenseCampaigns,
    isLoadingCampaignsData,
    isValidatingCampaignsData,
    mutateCampaignsData,
    isUpdatingCheckedAdSenseCampaigns,
    isCampaignsDataError,
    filterData,
    handleFilterData,
  } = useCampaigns();

  React.useEffect(() => {
    analytics?.track(
      'Product Tags Campaign Table Viewed',
      {},
      {
        context: { groupId: selectedStore?.alias_id },
      },
    );
  }, [analytics, selectedStore]);

  if (isCampaignsDataError && !isValidatingCampaignsData) {
    return <PageError mutate={mutateCampaignsData} />;
  }

  const isLoading = isLoadingCampaignsData || isValidatingCampaignsData;

  const hasAdSenseCampaigns = adSenseCampaigns && !isLoading;

  return (
    <S.Container>
      <Header />

      {Boolean(filterData.length) && (
        <Filter
          config={PRODUCT_ANALYTICS_CONFIG}
          handleFilterData={handleFilterData}
          filterData={filterData}
        />
      )}

      {!hasAdSenseCampaigns ? <TableSkeletonLoading /> : <Table />}

      {isUpdatingCheckedAdSenseCampaigns && (
        <S.LoadingScreen>
          <ClapSpinner size={48} frontColor={theme.colors.green.default} />
        </S.LoadingScreen>
      )}

      <SideModalTags />
    </S.Container>
  );
};

export default Wrapper;
