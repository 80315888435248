import React from 'react';
import { Search } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { useVariant } from '@helpers/hooks/pages/dashboard/kit/useVariant';
import { useDebounce } from '@helpers/hooks/useDebounce';

import VariantsDropdown from './VariantsDropdown';

import * as S from './styles';

const VariantInput: React.FC = () => {
  const theme = useTheme();
  const {
    isVariantsComponentOpen,
    onInputFocus,
    inputComponentRef,
    handleIsHoveringInput,
    isHoveringInput,
    closeVariantsComponent,
    handleSearchName,
    handleIsHoveringVariantsComponent,
    isHoveringVariantsComponent,
  } = useVariant();

  const [searchVariantName, setSearchVariantName] = React.useState<string>('');

  const debouncedVariantName = useDebounce(searchVariantName, 700);

  const onVariantNameInputChange = React.useCallback(event => {
    setSearchVariantName(event.target.value);
  }, []);

  const onVariantsComponentBlur = React.useCallback(
    event => {
      const isBlurringOutside = !event.currentTarget.contains(event.relatedTarget);

      if (isBlurringOutside && !isHoveringInput) {
        closeVariantsComponent();
      }
    },
    [closeVariantsComponent, isHoveringInput],
  );

  const onInputBlur = React.useCallback(() => {
    if (!isHoveringInput && isVariantsComponentOpen && !isHoveringVariantsComponent) {
      closeVariantsComponent();
    }
  }, [
    isVariantsComponentOpen,
    closeVariantsComponent,
    isHoveringInput,
    isHoveringVariantsComponent,
  ]);

  React.useEffect(() => {
    handleSearchName(debouncedVariantName);
  }, [debouncedVariantName, handleSearchName]);

  return (
    <S.Wrapper>
      <S.Popover open={isVariantsComponentOpen}>
        <S.Popover.Trigger onBlur={onInputBlur}>
          <S.Input
            prepend={Search}
            placeholder="Pesquisar variante"
            type="text"
            onFocus={onInputFocus}
            parentRef={inputComponentRef}
            onMouseEnter={() => handleIsHoveringInput(true)}
            onMouseLeave={() => handleIsHoveringInput(false)}
            onChange={onVariantNameInputChange}
          />
        </S.Popover.Trigger>

        <S.Popover.Content
          asChild
          side="bottom"
          style={{ backgroundColor: theme.colors.black.dark }}
          onBlur={onVariantsComponentBlur}
          onMouseEnter={() => handleIsHoveringVariantsComponent(true)}
          onMouseLeave={() => handleIsHoveringVariantsComponent(false)}
        >
          <S.VariantsWrapper>
            <VariantsDropdown />
          </S.VariantsWrapper>
        </S.Popover.Content>
      </S.Popover>
    </S.Wrapper>
  );
};

export default VariantInput;
