import React from 'react';

import { IOptionProps } from '@domain/interfaces/dashboard/Automations/Canvas/Node/WhatsAppNode/ITab';

import * as S from './styles';

const Option: React.FC<IOptionProps> = ({ children, isSelected, onClick }) => {
  return (
    <S.Wrapper isSelected={isSelected} onClick={onClick} type="button">
      {children}
    </S.Wrapper>
  );
};

export default Option;
