import React from 'react';

import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import { useProductCostDetails } from '@helpers/hooks/pages/dashboard/productCostDetails/productCostDetails';
import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';
import { useRetrospectiveData } from '@helpers/hooks/useRetrospectiveData';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { parseVariantCosts } from '@helpers/dashboard/productCostDetails/productCostDetailsUtils';

import productCostDetailsService from '@services/pages/dashboard/productCostDetails/productCostDetails';

import * as S from './styles';

const Save: React.FC = () => {
  const { toast } = useToast();
  const { analytics, user } = useConfig();
  const { storeAliasId, productAliasId } = useParams<IParams>();
  const { mutateFeedQuantity } = useStoreConfig();

  const { trackProductCostEditedQuantity } = useRetrospectiveData();

  const {
    isPageEdited,
    editedVariants,
    updateEditedVariants,
    updateSavedVariants,
    handleSyncPastCogsModalOpen,
    costToApplyAll,
    currencyToApplyAll,
  } = useProductCostDetails();

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(async () => {
    setIsSubmittingForm(true);

    try {
      if (editedVariants) {
        const mappedVariants = parseVariantCosts(editedVariants);

        if (costToApplyAll || currencyToApplyAll) {
          await productCostDetailsService.updateAllVariantCost({
            productAliasId,
            storeAliasId,
            data: {
              cost: costToApplyAll,
              currency: currencyToApplyAll,
            },
          });
        }

        if (mappedVariants.length) {
          await productCostDetailsService.updateVariantCost({
            storeAliasId,
            productAliasId,
            data: { variants: mappedVariants },
          });
        }

        trackProductCostEditedQuantity();

        analytics?.track(
          'Product Cost Edited',
          {
            email: user?.email,
          },
          { context: { groupId: storeAliasId } },
        );
        analytics?.trackHubSpot(storeAliasId, { product_cost_added: true });

        toast.success('Custo dos Produtos atualizado com sucesso!');

        updateSavedVariants(editedVariants);

        updateEditedVariants([]);

        mutateFeedQuantity();

        handleSyncPastCogsModalOpen();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsSubmittingForm(false);
    }
  }, [
    toast,
    editedVariants,
    storeAliasId,
    productAliasId,
    updateEditedVariants,
    updateSavedVariants,
    handleSyncPastCogsModalOpen,
    analytics,
    costToApplyAll,
    currencyToApplyAll,
    user,
    trackProductCostEditedQuantity,
    mutateFeedQuantity,
  ]);

  return (
    <>
      {isPageEdited && (
        <S.SaveButtonWrapper>
          <S.Button onClick={onSubmit} isLoading={isSubmittingForm} disabled={isSubmittingForm}>
            Salvar alterações
          </S.Button>
        </S.SaveButtonWrapper>
      )}
    </>
  );
};

export default Save;
