import { AxiosResponse } from 'axios';
import queryString from 'query-string';

import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';
import {
  IBlockAdSenseCredentialsProps,
  IDeleteAdSenseCredentialProps,
  IListAdSenseCredentialsPromiseResponse,
  IListAdSenseCredentialsProps,
  IListAdSenseCredentialsResponse,
} from '@domain/interfaces/dashboard/AdSense/IAdSenseCredential';

export class AdSenseCredentialService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listAdSenseCredentials({
    storeAliasId,
    page,
    filter,
  }: IListAdSenseCredentialsProps): IListAdSenseCredentialsResponse {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    const { data, isValidating, error, mutate } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/ad-sense-credentials?limit=50&page=${page}&${queryStringFilter}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      adSenseCredentials: data?.ad_sense_credentials,
      totalPages: data?.total_pages,
      isLoading,
      isValidating,
      error,
      mutate,
    };
  }

  public listAdSenseCredentialsPromise({
    storeAliasId,
    page,
    filter,
  }: IListAdSenseCredentialsProps): Promise<AxiosResponse<IListAdSenseCredentialsPromiseResponse>> {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    return this.apiService.get<IListAdSenseCredentialsPromiseResponse>(
      `/api/v1/users/stores/${storeAliasId}/ad-sense-credentials?limit=50&page=${page}&${queryStringFilter}`,
    );
  }

  public blockAdSenseCredentials({
    storeAliasId,
    data,
  }: IBlockAdSenseCredentialsProps): Promise<AxiosResponse<void>> {
    return this.apiService.post<void>(
      `/api/v1/users/stores/${storeAliasId}/ad-sense-credentials/block`,
      { ...data },
    );
  }

  public deleteAdSenseCredential({
    adSenseCredentialAliasId,
    storeAliasId,
  }: IDeleteAdSenseCredentialProps): Promise<AxiosResponse<void>> {
    return this.apiService.delete<void>(
      `/api/v1/users/stores/${storeAliasId}/ad-sense-credentials/${adSenseCredentialAliasId}`,
    );
  }
}

const adSenseCredentialService = new AdSenseCredentialService(API_DOMAIN);

export default adSenseCredentialService;
