import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { useMediaQuery } from '@helpers/hooks/useMediaQuery';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  const mobileWidth = useMediaQuery('1360px');

  const sliderPaymentMethodsCardsRef = React.useRef<HTMLDivElement>(null);

  const [isMouseMoveOn, setIsMouseMoveOn] = React.useState<boolean>(false);
  const [onMouseMoveDownEvent, setOnMouseMoveDownEvent] = React.useState<any>({});
  const [scrollLeft, setScrollLeft] = React.useState<number | undefined>(0);

  const handleOnMouseDown = React.useCallback(event => {
    setIsMouseMoveOn(true);
    setOnMouseMoveDownEvent(event);
  }, []);

  const handleOnMouseUp = React.useCallback(() => {
    setIsMouseMoveOn(false);
    setScrollLeft(sliderPaymentMethodsCardsRef?.current?.scrollLeft);
    setOnMouseMoveDownEvent({});
  }, []);

  const handleOnMouseMove = React.useCallback(
    event => {
      event.persist();

      if (isMouseMoveOn && sliderPaymentMethodsCardsRef.current) {
        const position = onMouseMoveDownEvent.pageX - event.clientX;

        sliderPaymentMethodsCardsRef.current.scrollLeft = (scrollLeft || 0) + position;
      }
    },
    [isMouseMoveOn, scrollLeft, sliderPaymentMethodsCardsRef, onMouseMoveDownEvent],
  );
  return (
    <>
      {mobileWidth ? (
        <S.MobileWrapper
          onMouseDown={handleOnMouseDown}
          onMouseUp={handleOnMouseUp}
          onMouseMove={handleOnMouseMove}
          ref={sliderPaymentMethodsCardsRef}
          tabIndex={0}
        >
          <S.CardWrapper>
            <Skeleton width="100%" height={60} />
          </S.CardWrapper>
          <S.CardWrapper>
            <Skeleton width="100%" height={60} />
          </S.CardWrapper>
          <S.CardWrapper>
            <Skeleton width="100%" height={60} />
          </S.CardWrapper>
          <S.CardWrapper>
            <Skeleton width="100%" height={60} />
          </S.CardWrapper>
          <S.CardWrapper>
            <Skeleton width="100%" height={60} />
          </S.CardWrapper>
          <S.CardWrapper>
            <Skeleton width="100%" height={60} />
          </S.CardWrapper>
        </S.MobileWrapper>
      ) : (
        <S.Wrapper>
          <S.CardWrapper>
            <Skeleton width="100%" height={60} />
          </S.CardWrapper>
          <S.CardWrapper>
            <Skeleton width="100%" height={60} />
          </S.CardWrapper>
          <S.CardWrapper>
            <Skeleton width="100%" height={60} />
          </S.CardWrapper>
          <S.CardWrapper>
            <Skeleton width="100%" height={60} />
          </S.CardWrapper>
          <S.CardWrapper>
            <Skeleton width="100%" height={60} />
          </S.CardWrapper>
          <S.CardWrapper>
            <Skeleton width="100%" height={60} />
          </S.CardWrapper>
        </S.Wrapper>
      )}
    </>
  );
};

export default SkeletonLoading;
