import React from 'react';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import { CampaignsProvider } from '@helpers/hooks/pages/dashboard/adsManager/useCampaigns';
import { UtmsProvider } from '@helpers/hooks/pages/dashboard/adsManager/useUtms';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';
import { useAdsManager } from '@helpers/hooks/pages/dashboard/adsManager/useAdsManager';

import { ADS_MANAGER_FILTER_CONFIG } from '@helpers/utils/filters/adsManagerFilter';

import Filter from '@components/common/core/Utils/Filter';
// import InstantHelp from '@components/common/core/Utils/InstantHelp';
import Header from '../Campaigns/Header';
import CampaignsTable from '../Campaigns/CampaignsTable';
import CampaignsActions from '../Campaigns/CampaignsActions';
import Onboarding from '../Onboarding';
import UtmOnboardingNotFinishedAlert from '../UtmOnboardingNotFinishedAlert';
import UtmSidemodal from '../UtmSidemodal';
import AddUTMTagsManuallySidemodal from '../AddUTMTagsManuallySidemodal';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const { store } = useStoreConfig();
  const { analytics } = useConfig();
  const { storeAliasId } = useParams<IParams>();
  const {
    isUtmSidemodalOpen,
    filterData,
    handleFilterData,
    isAddUTMTagsManualySidemodalOpen,
  } = useAdsManager();

  React.useEffect(() => {
    if (store?.is_ads_manager_onboarding_finished) {
      analytics?.track('Ads Manager Table Viewed', {}, { context: { groupId: storeAliasId } });
    }
  }, [analytics, storeAliasId, store]);

  if (!store?.is_ads_manager_onboarding_finished) {
    return <Onboarding />;
  }

  return (
    <S.Wrapper>
      <CampaignsProvider>
        <UtmsProvider>
          {!store?.is_utm_onboarding_finished && <UtmOnboardingNotFinishedAlert />}

          <Header />

          {Boolean(filterData.length) && (
            <Filter
              config={ADS_MANAGER_FILTER_CONFIG}
              handleFilterData={handleFilterData}
              filterData={filterData}
            />
          )}

          <CampaignsTable />

          <CampaignsActions />

          {isUtmSidemodalOpen && <UtmSidemodal />}

          {isAddUTMTagsManualySidemodalOpen && <AddUTMTagsManuallySidemodal />}
        </UtmsProvider>
      </CampaignsProvider>

      {/* <InstantHelp
        name="ads-manager"
        title="Gerenciador de anúncios"
        videoURL="https://www.youtube.com/embed/vWAC8Wkt9ok?autoplay=1"
      /> */}
    </S.Wrapper>
  );
};

export default Wrapper;
