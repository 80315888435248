import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { numberFormatter } from '@helpers/masks';
import { ETextSize } from '@domain/enums/components/EText';
import { ChevronRight } from '@profitfy/pakkun-icons';
import { EButtonVariant } from '@domain/enums/components/EButton';
import { useHistory, useParams } from 'react-router-dom';
import { IParams } from '@domain/interfaces/IParams';
import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';

import ProfitChart from './ProfitChart';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const ProfitCard: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const history = useHistory();
  const {
    financialReport,
    isLoadingFinancialDashboardProvider,
    isValidatingFinancialDashboardProvider,
    updateDashboardConfiguration,
    financialDashboardConfiguration,
    isUpdatingDashboardConfiguration,
    isLoadingFinancialDashboardPromise,
  } = useFinancialDashboard();

  const handleSeeDetailsButtonClick = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/reports/financial/details`);
  }, [history, storeAliasId]);

  const onSwitchChange = React.useCallback(
    event => {
      updateDashboardConfiguration(event.target.checked);
    },
    [updateDashboardConfiguration],
  );

  if (
    isLoadingFinancialDashboardProvider ||
    isValidatingFinancialDashboardProvider ||
    isLoadingFinancialDashboardPromise
  ) {
    return <SkeletonLoading />;
  }

  const { net_profit } = financialReport;

  const MAX_CHARACTERS_ALLOWED = 17;

  const parsedProfitValue = String(net_profit.amount);
  const isNetProfitValueSmall = parsedProfitValue.length > MAX_CHARACTERS_ALLOWED;

  const isNetProfitAmountPositive = net_profit.amount > 0;
  const isNetProfitAmountNegative = net_profit.amount < 0;

  const isPercentagePositive = net_profit.growth >= 0;

  const parsedPercentageValue = numberFormatter(
    isPercentagePositive ? net_profit.growth : Math.abs(net_profit.growth),
    2,
  );

  return (
    <S.Card>
      <S.ContentAndChartContainer>
        <S.Content>
          <S.Title fontWeight={EHeadingWeight.REGULAR} type={EHeadingSize.H6}>
            Lucro Líquido
          </S.Title>

          <S.ProfitValueAndPercentageWrapper>
            <S.ProfitValue
              isSmall={isNetProfitValueSmall}
              isNegative={isNetProfitAmountNegative}
              isPositive={isNetProfitAmountPositive}
            >{`R$${numberFormatter(net_profit.amount, 2)}`}</S.ProfitValue>
            <S.PercentageWrapper>
              <S.PercentageArrow isPositive={isPercentagePositive} />
              <S.PercentageValue
                isPositive={isPercentagePositive}
              >{`${parsedPercentageValue}%`}</S.PercentageValue>
            </S.PercentageWrapper>
          </S.ProfitValueAndPercentageWrapper>

          <S.ComparedPeriodText size={ETextSize.B5}>
            {isPercentagePositive ? 'a mais neste período' : 'a menos neste período'}
          </S.ComparedPeriodText>
        </S.Content>

        <S.ChartWrapper>
          <ProfitChart />
        </S.ChartWrapper>
      </S.ContentAndChartContainer>

      <S.CardFooter>
        <S.SwitchWrapper>
          <S.Switch
            id="include-custom-values"
            name="include-custom-values"
            checked={
              financialDashboardConfiguration?.include_custom_value_in_net_profit || undefined
            }
            disabled={isUpdatingDashboardConfiguration}
            onChange={onSwitchChange}
          />

          <S.SwitchText size={ETextSize.B5}>Incluir valores adicionais</S.SwitchText>
        </S.SwitchWrapper>

        <S.SeeDetailsButton
          iconAppend={() => <ChevronRight size={12} />}
          variant={EButtonVariant.SECONDARY}
          onClick={handleSeeDetailsButtonClick}
        >
          Ver detalhes
        </S.SeeDetailsButton>
      </S.CardFooter>
    </S.Card>
  );
};

export default ProfitCard;
