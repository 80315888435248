import React from 'react';

import { ICard } from '@domain/interfaces/components/ICard';

import * as S from './styles';

const Card: ICard &
  React.ForwardRefRenderFunction<HTMLDivElement, React.PropsWithChildren<ICard>> = (
  { children, spotlight, ...rest },
  ref,
) => {
  return (
    <S.Container ref={ref} spotlight={spotlight} {...rest}>
      {children}
    </S.Container>
  );
};

export default React.forwardRef(Card);
