import React from 'react';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  const commonProp = { lineHeight: 'unset' };

  const instanceStyle = { ...commonProp, borderRadius: 12 };

  return (
    <S.Wrapper>
      <S.Header>
        <S.Heading>
          <Skeleton height={36} width="100%" style={{ ...commonProp }} />
        </S.Heading>

        <S.NewInstanceButton>
          <Skeleton height={36} width="100%" style={{ ...commonProp }} />
        </S.NewInstanceButton>
      </S.Header>

      <S.InstancesWrapper>
        <S.Instance>
          <Skeleton height={182} width="100%" style={instanceStyle} />
        </S.Instance>

        <S.Instance>
          <Skeleton height={182} width="100%" style={instanceStyle} />
        </S.Instance>

        <S.Instance>
          <Skeleton height={182} width="100%" style={instanceStyle} />
        </S.Instance>

        <S.Instance>
          <Skeleton height={182} width="100%" style={instanceStyle} />
        </S.Instance>
      </S.InstancesWrapper>
    </S.Wrapper>
  );
};

export default SkeletonLoading;
