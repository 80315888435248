import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import SideModalBase from '@components/common/core/DataDisplay/SideModal';

export const SideModal = styled(SideModalBase)``;

export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

export const Heading = styled(HeadingBase)`
  margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;
