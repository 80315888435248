import styled from 'styled-components/macro';

import { IBulletProps } from '@domain/interfaces/dashboard/AdsManager/Table/Content/IStatus';

import TextBase from '@components/common/core/DataDisplay/Text';

export const StatusName = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const Bullet = styled.span<IBulletProps>`
  min-width: 6px;
  width: 6px;
  min-height: 6px;
  height: 6px;
  background-color: ${({ color }) => `${color}`};
  border-radius: 50%;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 8px;
  padding: 10px 12px;
  box-sizing: border-box;
`;
