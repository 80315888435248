import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ETextSize } from '@domain/enums/components/EText';
import { useOtherGateway } from '@helpers/hooks/pages/dashboard/feed/useOtherGateway';
import Order from './Order';
import InfiniteScrollLoading from './InfiniteScrollLoading';

import * as S from './styles';

const OtherGatewayOrders: React.FC = () => {
  const {
    isLoadingOtherGatewayOrders,
    loadOtherGatewayOrders,
    otherGatewayOrders,
    hasMore,
    handleLoadMore,
  } = useOtherGateway();

  React.useEffect(() => {
    loadOtherGatewayOrders();
  }, [loadOtherGatewayOrders]);

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>Pedido(s) de outro(s) Gateway(s)</S.Title>
        <S.Description size={ETextSize.B5}>
          O gateway deste pedido não possui integração com a Profitfy.me. Informe os dados para
          contabilizá-lo
        </S.Description>
      </S.Header>

      <S.OrdersWrapper id="other-gateway-orders-list">
        <InfiniteScroll
          dataLength={otherGatewayOrders.length}
          next={handleLoadMore}
          hasMore={hasMore}
          loader={<InfiniteScrollLoading />}
          scrollThreshold={0.95}
          scrollableTarget="other-gateway-orders-list"
        >
          {!otherGatewayOrders.length && isLoadingOtherGatewayOrders ? (
            <InfiniteScrollLoading />
          ) : (
            <></>
          )}

          {otherGatewayOrders.map(order => (
            <Order key={order.alias_id} order={order} />
          ))}
        </InfiniteScroll>
      </S.OrdersWrapper>
    </S.Wrapper>
  );
};

export default OtherGatewayOrders;
