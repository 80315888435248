import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

export const StatusText = styled(TextBase)`
  line-height: 120%;
  font-family: ${({ theme }) => theme.fonts.heading};

  strong {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.green.default};
  }
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 36px;
  text-align: center;
`;

export const Wrapper = styled.div`
  padding: 32px;
  margin: 0 auto;
`;
