import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const ComponentErrorWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;

export const Text = styled(TextBase)`
  display: inline-block;
  margin-bottom: 8px;
  text-align: center;
`;

export const Button = styled(ButtonBase)`
  margin-top: 16px;
`;
