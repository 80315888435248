export enum ETriggers {
  ABANDONED_CART = 'ABANDONED_CART',
  BOLETO = 'BOLETO',
  PIX = 'PIX',
  REJECTED_PAYMENT = 'REJECTED_PAYMENT',
  BOLETO_RECOVER = 'BOLETO_RECOVER',
  PIX_RECOVER = 'PIX_RECOVER',
  REJECT_PAYMENT = 'REJECT_PAYMENT',
  UPDATED_FULFILLMENT = 'UPDATED_FULFILLMENT',
  SIMPLE_SHIPMENT = 'SIMPLE_SHIPMENT',
  APPROVED_ORDER = 'APPROVED_ORDER',
  CANCELED_ORDER = 'CANCELED_ORDER',
  COUPON = 'COUPON',
}
