import React from 'react';
import { Pen, Cancel } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { IRowProps } from '@domain/interfaces/dashboard/CustomValues/IBilling';
import { numberFormatter } from '@helpers/masks';
import { useDate } from '@helpers/hooks/useDate';
import { useCustomValuesIn } from '@helpers/hooks/pages/dashboard/customValues/useCustomValuesIn';
import { useBilling } from '@helpers/hooks/pages/dashboard/customValues/useBilling';
import { ICustomValuesCategory } from '@domain/interfaces/dashboard/CustomValuesCategories/ICustomValuesCategories';
import DeleteModal from '@components/common/core/Utils/DeleteModal';
import EditBilling from '../EditBilling';

import * as S from './styles';

const Row: React.FC<IRowProps> = ({ billing }) => {
  const { format, utcToZonedTime } = useDate();
  const { selectedCustomValueChecked, findCustomValueCategoryData } = useCustomValuesIn();
  const theme = useTheme();
  const {
    isUpdateCustomValueBillingSidemodalOpen,
    handleSelectedBillingToUpdate,
    selectedBillingToUpdate,
    handleUpdateCustomValueBillingSidemodalOpen,
    deleteCustomValueBilling,
    isDeletingCustomValueBilling,
  } = useBilling();

  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const [category, setCategory] = React.useState<ICustomValuesCategory | undefined>(undefined);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);
  const [isEditBillingModalOpen, setIsEditBillingModalOpen] = React.useState<boolean>(false);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);

  const handlePopoverOpen = React.useCallback(() => {
    setIsPopoverOpen(!isPopoverOpen);
  }, [isPopoverOpen]);

  const handleDeleteModalOpen = React.useCallback(() => setIsDeleteModalOpen(!isDeleteModalOpen), [
    isDeleteModalOpen,
  ]);

  const onDeleteClick = React.useCallback(() => {
    setIsPopoverOpen(false);
    handleDeleteModalOpen();
  }, [handleDeleteModalOpen]);

  const onDelete = React.useCallback(async () => {
    await deleteCustomValueBilling(billing.alias_id);

    handleDeleteModalOpen();
  }, [billing, deleteCustomValueBilling, handleDeleteModalOpen]);

  const handleEditBillingOpen = React.useCallback(() => {
    setIsEditBillingModalOpen(!isEditBillingModalOpen);
    handleSelectedBillingToUpdate(billing);
    setIsPopoverOpen(false);
    handleUpdateCustomValueBillingSidemodalOpen();
  }, [
    handleUpdateCustomValueBillingSidemodalOpen,
    billing,
    handleSelectedBillingToUpdate,
    isEditBillingModalOpen,
  ]);

  const handleIsHovering = React.useCallback(status => setIsHovering(status), []);

  React.useEffect(() => {
    if (selectedCustomValueChecked) {
      const foundCategory = findCustomValueCategoryData(selectedCustomValueChecked);

      setCategory(foundCategory);
    }
  }, [selectedCustomValueChecked, findCustomValueCategoryData]);

  return (
    <S.TableRow
      onMouseEnter={() => handleIsHovering(true)}
      onMouseLeave={() => handleIsHovering(false)}
    >
      <S.TableData>{format(utcToZonedTime(billing.billing_date), 'dd/MM/yyyy')}</S.TableData>
      <S.TableData textAlign={EHeadAlign.RIGHT}>
        {`R$ ${numberFormatter(billing.amount, 2)}`}
      </S.TableData>
      <S.TableData textAlign={EHeadAlign.CENTER}>
        <S.Badge color={category?.hex_color || '#fff'} secondary>
          {category?.name}
        </S.Badge>
      </S.TableData>
      <S.TableData textAlign={EHeadAlign.RIGHT}>
        <S.Popover open={isPopoverOpen} onOpenChange={handlePopoverOpen}>
          <S.Popover.Trigger>
            <S.MoreOptionsWrapper isHovering={isHovering}>
              <S.OptionDot />
              <S.OptionDot />
              <S.OptionDot />
            </S.MoreOptionsWrapper>
          </S.Popover.Trigger>

          <S.Popover.Content sideOffset={5}>
            <S.OptionsWrapper>
              <S.ActionButton onClick={handleEditBillingOpen}>
                <Pen outline size={16} color={theme.colors.font} />
                Editar
              </S.ActionButton>
              <S.ActionButton onClick={onDeleteClick} isDeleteButton>
                <Cancel outline size={16} color={theme.colors.danger.default} />
                Excluir
              </S.ActionButton>
            </S.OptionsWrapper>
            <S.Popover.Arrow />
          </S.Popover.Content>
        </S.Popover>

        <DeleteModal
          content="Deseja realmente excluir essa cobrança? Essa ação é irreversível."
          title="Excluir cobrança"
          onDelete={onDelete}
          toggle={handleDeleteModalOpen}
          isOpen={isDeleteModalOpen}
          isLoading={isDeletingCustomValueBilling}
        />

        {isEditBillingModalOpen &&
          isUpdateCustomValueBillingSidemodalOpen &&
          selectedBillingToUpdate && (
            <EditBilling
              isOpen={isUpdateCustomValueBillingSidemodalOpen}
              toggle={handleEditBillingOpen}
            />
          )}
      </S.TableData>
    </S.TableRow>
  );
};

export default Row;
