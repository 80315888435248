import Text from '@components/common/core/DataDisplay/Text';
import PageError from '@components/common/core/Utils/PageError';
import { useConfig } from '@helpers/hooks/useConfig';
import { useToast } from '@helpers/hooks/useToast';
import { yupResolver } from '@hookform/resolvers/yup';
import personalInfoService from '@services/pages/auth/personalInfo';
import { Check } from 'phosphor-react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as yup from 'yup';
import * as S from './styles';

export const firstStepSchema = yup.object().shape({
  phone: yup
    .string()
    .required('É necessário informar um telefone.')
    .min(6, 'Este campo deve conter no mínimo 6 caracteres'),
});

const PHONE_INFOS = [
  'Mantenha um atendimento personalizado',
  'Receba notificações importantes sobre seu negócio',
  'Receba notificações de atualizações importantes para você',
];

interface IFirstStepProps {
  nextStep: () => void;
}

const FirstStep: React.FC<IFirstStepProps> = ({ nextStep }) => {
  const theme = useTheme();
  const history = useHistory();
  const { toast } = useToast();

  const { handlePersonalInfo } = useConfig();
  const {
    error: errorPersonalInfo,
    isValidating,
    mutate,
    personalInfo,
  } = personalInfoService.getPersonalInfo();

  const formattedPhoneValue = (prefix: string, number: string): string => {
    return `${prefix}${number}`;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(firstStepSchema),
  });

  const [phoneValue, setPhoneValue] = React.useState<string>('');
  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);

  const onPhoneChange = React.useCallback(phoneNumber => {
    setPhoneValue(phoneNumber);
  }, []);

  const onSubmit = React.useCallback(async () => {
    setIsSubmittingForm(true);

    const data = {
      phone_number: phoneValue,
    };

    if (personalInfo && personalInfo?.phone_number) {
      try {
        const { data: personalInfoData } = await personalInfoService.updatePersonalInfo({ data });

        handlePersonalInfo(personalInfoData?.personal_info);

        toast.success('Dados atualizados com sucesso!');

        setIsSubmittingForm(false);

        nextStep();
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsSubmittingForm(false);
      }

      return;
    }

    try {
      const { data: personalInfoData } = await personalInfoService.createPersonalInfo({ data });

      handlePersonalInfo(personalInfoData?.personal_info);

      toast.success('Dados salvos com sucesso!');

      setIsSubmittingForm(false);

      nextStep();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsSubmittingForm(false);
    }
  }, [handlePersonalInfo, nextStep, personalInfo, phoneValue, toast]);

  React.useEffect(() => {
    if (personalInfo && personalInfo?.phone_number) {
      setPhoneValue(formattedPhoneValue(personalInfo?.phone_prefix, personalInfo?.phone_number));
    }
  }, [personalInfo]);

  React.useEffect(() => {
    if (personalInfo && personalInfo?.phone_number) {
      nextStep();
    }
  }, [nextStep, personalInfo]);

  if (errorPersonalInfo?.message === 'Request failed with status code 401') {
    history.push('/');
  }

  if (errorPersonalInfo && !isValidating) {
    return <PageError mutate={mutate} />;
  }

  return (
    <>
      <S.Title>Informe o seu número de contato</S.Title>
      <S.InfoWrapper>
        {PHONE_INFOS.map(info => {
          return (
            <S.InfoGroup>
              <Check color={theme.colors.green.default} size={18} />
              <S.InfoText>{info}</S.InfoText>
            </S.InfoGroup>
          );
        })}
      </S.InfoWrapper>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label htmlFor="phone">Celular</S.Label>
          <S.PhoneInput
            {...register('phone')}
            country="br"
            value={phoneValue}
            onChange={onPhoneChange}
            inputProps={{ name: 'phone', autoFocus: true }}
            isError={errors.phone}
            disabled={isValidating}
          />
          {errors.phone && <Text isErrorFeedback>{errors.phone.message}</Text>}
        </S.InputGroup>

        <S.SubmitButton
          type="submit"
          isLoading={isSubmittingForm}
          disabled={isSubmittingForm || isValidating}
        >
          Continuar
        </S.SubmitButton>
      </S.Form>
    </>
  );
};

export default FirstStep;
