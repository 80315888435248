import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import RadioBase from '@components/common/core/Inputs/Radio';
import ButtonBase from '@components/common/core/Inputs/Button';

export const NextStepButton = styled(ButtonBase)`
  width: 100%;
`;

export const RadioText = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 110%;
  cursor: pointer;
`;

export const RadioInput = styled(RadioBase)``;

export const TypeLabel = styled(LabelBase)`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
`;

export const PersonalInfoTypeGroup = styled(InputGroupBase)``;

export const FormContent = styled.div`
  & > div {
    margin-bottom: 24px;
  }

  & > div:last-of-type {
    margin-bottom: 32px;
  }
`;

export const StepNumber = styled(TextBase)`
  font-size: 1.25rem;
  font-weight: 700;
`;

export const StepNumberWrapper = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray[6]};
`;

export const Title = styled(HeadingBase)``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 28px;
`;

export const Wrapper = styled.div`
  @media only screen and (max-width: 450px) {
    padding: 0 32px;
  }
`;
