import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';

export const StatusWrapper = styled.div`
  width: 110px;
  margin: 0 auto;
`;

export const TaxesWrapper = styled.div`
  width: 58px;
  margin-left: auto;
`;

export const FeesWrapper = styled.div`
  width: 58px;
  margin-left: auto;
`;

export const CogsWrapper = styled.div`
  width: 58px;
  margin-left: auto;
`;

export const NetRevenueWrapper = styled.div`
  width: 58px;
  margin-left: auto;
`;

export const ProductNameWrapper = styled.div`
  width: 174px;
`;

export const ProductImageWrapper = styled.div`
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;

  & > span > span {
    display: block;
  }
`;

export const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const ApprovedAtTextWrapper = styled.div`
  width: 118px;
`;

export const DaysApprovedWrapper = styled.div`
  width: 40px;
`;

export const ApprovedAtWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const OrderApprovedTextWrapper = styled.div`
  width: 118px;
`;

export const OrderProviderWrapper = styled.div`
  width: 42px;
`;

export const OrderDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const OrderProviderIconWrapper = styled.div`
  min-width: 38px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;

  & > span > span {
    display: block;
  }
`;

export const OrderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Table = styled(TableBase)`
  & > table {
    & > thead > tr {
      & > th {
        padding-left: 16px;
        padding-right: 16px;
      }
      & > th:first-child {
        padding-left: 32px;
        padding-right: 16px;
      }

      & > th:last-child {
        padding-right: 32px;
      }
    }

    & > tbody > tr {
      & > td {
        padding-left: 16px;
        padding-right: 16px;
      }
      & > td:first-child {
        padding-left: 32px;
        padding-right: 16px;
      }

      & > td:last-child {
        padding-right: 32px;
      }
    }
  }
`;
