import React from 'react';

import * as S from './styles';

const TrialEnded: React.FC = () => {
  return (
    <S.CardWrapper>
      <S.Content>
        <S.PlanNameAndPricingWrapper>
          <S.PlanName>Trial Finalizado</S.PlanName>
          <S.PricingValue>O seu trial está finalizado</S.PricingValue>
        </S.PlanNameAndPricingWrapper>

        <S.Divider />

        <S.Description>Obrigado por testar a nossa plataforma!</S.Description>
      </S.Content>
    </S.CardWrapper>
  );
};

export default TrialEnded;
