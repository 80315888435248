import styled from 'styled-components/macro';

import ButtonBase from '@components/common/core/Inputs/Button';

export const Button = styled(ButtonBase)``;

export const SaveButtonWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 24px;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.background[2]};
  width: 100%;
`;
