import React from 'react';

import { useOnboardingTag } from '@helpers/hooks/pages/dashboard/productAnalytics/useOnboardingTag';

import Welcome from '@components/Dashboard/ProductAnalytics/ProductTagOnboarding/Welcome';
import CampaignsTable from '@components/Dashboard/ProductAnalytics/CampaignsTable/Wrapper';

import { PageSkeletonLoading } from '@components/Dashboard/ProductAnalytics/CampaignsTable/SkeletonLoading';

const Wrapper: React.FC = () => {
  const { product, isLoadingProduct, isValidatingProduct } = useOnboardingTag();

  const isOnboardingFinished = product?.is_onboarding_finished;

  const isLoading = isLoadingProduct || isValidatingProduct;

  if (isLoading) {
    return <PageSkeletonLoading />;
  }

  return isOnboardingFinished ? <CampaignsTable /> : <Welcome />;
};

export default Wrapper;
