import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';
import ImageBase from '@components/common/core/DataDisplay/Image';

export const Modal = styled(ModalBase)`
  width: 100%;
  max-width: 391px;
  box-sizing: border-box;
  padding: 24px;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

export const Heading = styled(TextBase)`
  color: ${({ theme }) => theme.colors.white.default};
  font-size: 1.125rem;
`;

export const ScopesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[5]};
`;

export const ScopesTitle = styled(TextBase)`
  margin-bottom: 8px;
`;

export const MissingScopesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
  max-height: 125px;
  overflow-y: scroll;
  padding: 10px 18px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.black.light};
  box-sizing: border-box;

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[1]};
    border-radius: 25px;
    margin-bottom: 4px;
  }
`;

export const MissingScope = styled(TextBase)``;

export const Description = styled(TextBase)`
  margin-bottom: 24px;
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.green.default};
`;

export const ShopifyTutorialIntegrationWrapper = styled.a`
  cursor: pointer;
`;

export const ShopifyTutorialIntegrationImg = styled(ImageBase)`
  border-radius: 4px;
`;

export const UnderstoodButton = styled(ButtonBase)`
  margin-top: 16px;
  margin-left: auto;
`;
