import styled, { css } from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import RadioBase from '@components/common/core/Inputs/Radio';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import CustomHoverCard from '@components/common/core/Utils/CustomHoverCard';

import LabelBase from '@components/common/core/DataDisplay/Label';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const Title = styled(TextBase)`
  font-weight: 700;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray[1]};
  margin-bottom: 24px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
`;

export const Text = styled(TextBase)<{ button?: boolean }>`
  font-weight: 400;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[1]};
  user-select: none;

  ${props =>
    props.button &&
    css`
      color: ${({ theme }) => theme.colors.green.default};
      cursor: pointer;
    `}
`;

export const Input = styled(TextFieldBase)`
  width: 100%;
  margin-top: 8px;
`;

export const Checkbox = styled(CheckboxBase)``;

export const CheckboxLabel = styled(LabelBase)`
  margin: unset;
  margin-top: 1px;
  cursor: pointer;
`;

export const CheckboxGroup = styled(InputGroupBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
`;

export const CheckBox = styled(CheckboxBase)``;

export const ProductsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 8px;
  margin-bottom: 24px;
  max-height: 168px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[4]};
  }
`;

export const CurrencySelect = styled(SelectBase)`
  /* max-width: 126px; */
  width: 100%;
  border-radius: 5px;
  margin-bottom: 24px;

  & > label > select {
    background-color: ${({ theme }) => theme.colors.background[2]};
    width: 100%;
  }
`;

export const Option = styled(OptionBase)``;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 24px;
`;

export const RadioItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const RadioLabel = styled(LabelBase)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Radio = styled(RadioBase)``;

export const RadioText = styled(TextBase)`
  cursor: pointer;
  font-weight: 400;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const HoverCardText = styled(TextBase)`
  font-size: 0.875rem;
  display: flex;
  align-items: center;
`;

export const HoverCardContentWrapper = styled.div`
  max-width: 248px;
  width: 100%;
`;

export const HoverCardContent = styled(CustomHoverCard.Content)`
  background-color: ${({ theme }) => theme.colors.black.light};
  padding: 16px;
`;

export const HoverCardArrow = styled(CustomHoverCard.Arrow)`
  fill: ${({ theme }) => theme.colors.black.light};
`;

export const HoverCard = styled(CustomHoverCard)``;

export const HoverCardTrigger = styled(CustomHoverCard.Trigger)`
  display: flex;
  align-items: center;
`;

export const ConfirmButton = styled(ButtonBase)`
  margin-top: 24px;
`;

export const AllProducts = styled.div`
  padding: 8px 16px;
  display: flex;
  align-items: center;
  height: 48px;
  box-sizing: border-box;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.background[2]};
`;

export const AllProductsMessage = styled(TextBase)`
  font-weight: 400;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray[1]};
`;
