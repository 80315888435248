import { ApiService } from '@services/api';
import { USER_API_DOMAIN } from '@constants/api';
import { ITransferPlanProps } from '@domain/interfaces/dashboard/AdminPanel/ITransferPlan';

export class TransferPlansService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public transferPlan({ storeAliasId, data }: ITransferPlanProps): Promise<any> {
    return this.apiService.put(`/api/v1/users/stores/${storeAliasId}/subscriptions/transfer`, {
      ...data,
    });
  }
}

const transferPlansService = new TransferPlansService(USER_API_DOMAIN);

export default transferPlansService;
