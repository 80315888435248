import React from 'react';

import notFoundDark from '@assets/components/EmptyData/not-found-dark.svg';
import notFoundLight from '@assets/components/EmptyData/not-found-light.svg';
import { EHeadingSize } from '@domain/enums/components/EHeading';
import { IEmptyDataProps } from '@domain/interfaces/components/IEmptyData';

import * as S from './styles';

const EmptyData: React.FC<IEmptyDataProps> = ({
  content,
  contentSize = EHeadingSize.H4,
  ...rest
}) => {
  const theme = 'dark';

  if (theme === 'dark') {
    return (
      <S.NoDataWrapper {...rest}>
        <S.Image src={notFoundDark} alt="Profitfy.me não encontrado" />
        <S.Heading type={contentSize}>{content}</S.Heading>
      </S.NoDataWrapper>
    );
  }

  return <S.Image src={notFoundLight} alt="Profitfy.me não encontrado" {...rest} />;
};

export default EmptyData;
