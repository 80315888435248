import React from 'react';
import { Pen } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';

import { useCampaigns } from '@helpers/hooks/pages/dashboard/adsManager/useCampaigns';

import { numberFormatter } from '@helpers/masks';

import EditCampaignDailyBudgetContent from '@components/Dashboard/AdsManager/Campaigns/CampaignsActions/EditCampaignDailyBudgetContent';

import * as S from './styles';

const Budget: React.FC<ICampaignProps> = ({ data, adSenseCampaignAliasId }) => {
  const budgetData: number = data;
  const theme = useTheme();
  const { isEditBudgetModalOpen, handleEditBudgetModalOpen } = useCampaigns();

  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const [isHoveringEditBudget, setIsHoveringEditBudget] = React.useState<boolean>(false);
  const [isEditBudgetPopoverOpen, setIsEditBudgetPopoverOpen] = React.useState<boolean>(false);

  const handleBudgetPopoverOpen = React.useCallback(
    status => {
      if (status === true) {
        handleEditBudgetModalOpen(adSenseCampaignAliasId);
      } else {
        handleEditBudgetModalOpen(undefined);
      }

      setIsEditBudgetPopoverOpen(status);
    },
    [adSenseCampaignAliasId, handleEditBudgetModalOpen],
  );

  const onMouseEnter = React.useCallback(() => {
    setIsHovering(true);
  }, []);

  const onMouseLeave = React.useCallback(() => {
    setIsHovering(false);
  }, []);

  const onEditMouseEnter = React.useCallback(() => {
    setIsHoveringEditBudget(true);
  }, []);

  const onEditMouseLeave = React.useCallback(() => {
    setIsHoveringEditBudget(false);
  }, []);

  const isOpen = isEditBudgetModalOpen === adSenseCampaignAliasId;

  return (
    <S.Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <S.BudgetValue>
        {`R$ ${numberFormatter(budgetData, 2)}`}
        <S.EditBudgetWrapper onMouseEnter={onEditMouseEnter} onMouseLeave={onEditMouseLeave}>
          <S.CustomPopover open={isEditBudgetPopoverOpen} onOpenChange={handleBudgetPopoverOpen}>
            <S.CustomPopover.Trigger>
              {(isHovering || isOpen) && (
                <Pen
                  outline
                  size={14}
                  color={isHoveringEditBudget ? theme.colors.green.default : theme.colors.font}
                />
              )}
            </S.CustomPopover.Trigger>

            <EditCampaignDailyBudgetContent
              setIsEditBudgetPopoverOpen={setIsEditBudgetPopoverOpen}
            />
          </S.CustomPopover>
        </S.EditBudgetWrapper>
      </S.BudgetValue>
      <S.BudgetFrequency>Diariamente</S.BudgetFrequency>
    </S.Wrapper>
  );
};

export default Budget;
