import React from 'react';

import { DotsThreeVertical, PencilSimple, Play } from 'phosphor-react';
import { useTheme } from 'styled-components';

import { EButtonVariant } from '@domain/enums/components/EButton';

import { EMessageContentType, EMessageType } from '@domain/enums/dashboard/automations/EMessage';

import { IMessagePreviewProps } from '@domain/interfaces/dashboard/Automations/IMessage';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';
import { useCanvasMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessageSettings';

import { processText } from '@helpers/utils/dashboard/automations/preview';

import {
  ABANDONED_CART_TRIGGER_VARIABLES,
  BOLETO_TRIGGER_VARIABLES,
  INFO_PRODUCT_ABANDONED_CART_TRIGGER_VARIABLES,
  INFO_PRODUCT_BOLETO_TRIGGER_VARIABLES,
  INFO_PRODUCT_PIX_TRIGGER_VARIABLES,
  INFO_PRODUCT_REJECTED_PAYMENT_TRIGGER_VARIABLES,
  PIX_TRIGGER_VARIABLES,
  REJECTED_PAYMENT_TRIGGER_VARIABLES,
  SIMPLE_SHIPMENT_TRIGGER_VARIABLES,
  UPDATED_FULFILLMENT_TRIGGER_VARIABLES,
} from '@constants/pages/dashboard/automations/messages/triggers';

import * as S from './styles';

const Preview: React.FC<IMessagePreviewProps> = ({ messageContents, message, toggleModal }) => {
  const { handleWhatsappNodeSelectMessage, selectedIntegration } = useAutomation();

  const {
    selectedMessageTrigger,
    handleShowUpdateDefaultMessageContentOnWhatsappNode,
    handleSelectedDefaultMessageToUpdate,
  } = useCanvasMessageSettings();

  const theme = useTheme();

  const [isHoveringPreview, setIsHoveringPreview] = React.useState<boolean>(false);

  const onMouseEnter = React.useCallback(() => {
    setIsHoveringPreview(true);
  }, []);

  const onMouseLeave = React.useCallback(() => {
    setIsHoveringPreview(false);
  }, []);

  const getRandomWaveHeight = (): number => {
    const min = 3;
    const max = 14;

    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const AUDIO_WAVES = Array.from(Array(50).keys()).map(item => {
    return {
      value: item,
      height: getRandomWaveHeight(),
    };
  });

  const onEdit = React.useCallback(() => {
    handleShowUpdateDefaultMessageContentOnWhatsappNode();
    handleSelectedDefaultMessageToUpdate(message);
  }, [
    handleShowUpdateDefaultMessageContentOnWhatsappNode,
    handleSelectedDefaultMessageToUpdate,
    message,
  ]);

  const onChooseMessage = React.useCallback(() => {
    handleWhatsappNodeSelectMessage(message);

    if (toggleModal) {
      toggleModal();
    }
  }, [message, toggleModal, handleWhatsappNodeSelectMessage]);

  const getTriggerVariables = React.useCallback(() => {
    const isInfo = selectedIntegration?.id === 'HOTMART';

    if (isInfo) {
      if (selectedMessageTrigger === EMessageType.ABANDONED_CART)
        return INFO_PRODUCT_ABANDONED_CART_TRIGGER_VARIABLES;

      if (selectedMessageTrigger === EMessageType.BOLETO)
        return INFO_PRODUCT_BOLETO_TRIGGER_VARIABLES;

      if (selectedMessageTrigger === EMessageType.REJECTED_PAYMENT)
        return INFO_PRODUCT_REJECTED_PAYMENT_TRIGGER_VARIABLES;

      if (selectedMessageTrigger === EMessageType.PIX) return INFO_PRODUCT_PIX_TRIGGER_VARIABLES;
    }

    if (selectedMessageTrigger === EMessageType.ABANDONED_CART)
      return ABANDONED_CART_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.BOLETO) return BOLETO_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.REJECTED_PAYMENT)
      return REJECTED_PAYMENT_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.PIX) return PIX_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.SIMPLE_SHIPMENT)
      return SIMPLE_SHIPMENT_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.UPDATED_FULFILLMENT)
      return UPDATED_FULFILLMENT_TRIGGER_VARIABLES;

    return [];
  }, [selectedMessageTrigger, selectedIntegration]);

  return (
    <S.Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {messageContents?.map((messageContent, index) => {
        const isFirstItemOfArray = index === 0;

        if (messageContent.type === EMessageContentType.IMAGE) {
          return (
            <S.ImageContent key={messageContent.id}>
              {messageContent.content_public_url && (
                <S.Image src={messageContent.content_public_url} alt="" />
              )}

              <S.Time>11:47</S.Time>
            </S.ImageContent>
          );
        }

        if (messageContent.type === EMessageContentType.PRODUCT_IMAGE) {
          return (
            <S.ProductImageContent key={messageContent.id}>
              <S.ProductImageWrapper>
                <S.ProductImageText>IMAGEM DO PRODUTO</S.ProductImageText>
              </S.ProductImageWrapper>
              <S.Time>11:47</S.Time>
            </S.ProductImageContent>
          );
        }

        if (messageContent.type === EMessageContentType.VIDEO) {
          return (
            <S.VideoContent key={messageContent.id}>
              {messageContent.content_public_url && (
                <S.Video src={messageContent.content_public_url} />
              )}

              <S.Time>11:47</S.Time>
            </S.VideoContent>
          );
        }

        if (messageContent.type === EMessageContentType.AUDIO) {
          return (
            <S.AudioContent>
              <S.IconWrapper>
                <Play size={20} color="#9C8D8D" weight="fill" />
              </S.IconWrapper>

              <S.BulletAndWavesWrapper>
                <S.AudioBullet />

                <S.WavesWrapper>
                  {AUDIO_WAVES.map(wave => {
                    return <S.Wave key={wave.value} height={wave.height} />;
                  })}
                </S.WavesWrapper>

                <S.CurrentTimeText>0:00</S.CurrentTimeText>

                <S.TotalTimeText>1:20</S.TotalTimeText>
              </S.BulletAndWavesWrapper>

              <S.Avatar />
            </S.AudioContent>
          );
        }

        let modifiedText = messageContent.content;

        const triggerVariables = getTriggerVariables();

        triggerVariables.forEach(variable => {
          modifiedText = modifiedText.replace(variable.data, variable.nameToDisplay);
        });

        return (
          <S.Content isFirstItemOfArray={isFirstItemOfArray} key={messageContent.id}>
            <S.MessageText
              dangerouslySetInnerHTML={{ __html: processText(modifiedText as string) }}
            />

            <S.Time>11:47</S.Time>

            {isFirstItemOfArray && <S.ContentArrow />}
          </S.Content>
        );
      })}

      {isHoveringPreview && (
        <S.BluredOverlayer>
          <S.Popover>
            <S.PopoverTrigger>
              <S.OptionsWrapper>
                <DotsThreeVertical size={24} color={theme.colors.gray[1]} weight="bold" />
              </S.OptionsWrapper>
            </S.PopoverTrigger>

            <S.PopoverContent>
              <S.PopoverOption onClick={onEdit}>
                <PencilSimple size={16} color={theme.colors.gray[1]} weight="bold" />
                Editar
              </S.PopoverOption>

              <S.PopoverArrow />
            </S.PopoverContent>
          </S.Popover>

          <S.ChooseMessageButton
            variant={EButtonVariant.SECONDARY_BUTTON}
            onClick={onChooseMessage}
          >
            Escolher mensagem
          </S.ChooseMessageButton>
        </S.BluredOverlayer>
      )}
    </S.Wrapper>
  );
};

export default Preview;
