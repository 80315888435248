import React from 'react';

import { useParams } from 'react-router-dom';
import { EBadgeColors } from '@domain/enums/components/EBadge';
import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { ITableRowProps } from '@domain/interfaces/integrations/marketing/tiktok/ITable';
import { IParams } from '@domain/interfaces/IParams';
import { useConfig } from '@helpers/hooks/useConfig';
import { useTikTokAdAccounts } from '@helpers/hooks/common/integrations/marketing/tiktok/useTikTokAdAccounts';
import { useToast } from '@helpers/hooks/useToast';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { EHeadAlign } from '@domain/enums/components/ETable';

import Badge from '@components/common/core/DataDisplay/Badge';
import Tooltip from './Tooltip';

import * as S from './styles';

const Row: React.FC<ITableRowProps> = ({ adSenseAccount }) => {
  const { analytics } = useConfig();
  const { storeAliasId } = useParams<IParams>();
  const { updateAdSenseAccount } = useTikTokAdAccounts();
  const { toast } = useToast();

  const {
    currentSynchronizations,
    synchronizationsQueue,
    finishedSynchronizations,
    failedSynchronizations,
    handleFailedSynchronizations,
    handleFinishedSynchronizations,
  } = useSynchronization();

  const [isUpdatingAdAccount, setIsUpdatingAdAccount] = React.useState<boolean>(false);
  const [isSyncing, setIsSyncing] = React.useState<boolean>(false);

  const currentSynchronizationsArray = currentSynchronizations
    ? [...currentSynchronizations.synchronizations]
    : [];

  const mergedSynchronizations = [...synchronizationsQueue, ...currentSynchronizationsArray];

  const switchRef = React.useRef<HTMLInputElement>(null);

  const onSwitchChange = React.useCallback(
    async event => {
      try {
        if (!event.target.checked) {
          setIsUpdatingAdAccount(true);
        }

        const data = {
          ...adSenseAccount,
          is_active: event.target.checked,
        };

        await updateAdSenseAccount(adSenseAccount.alias_id, data);

        setIsUpdatingAdAccount(false);

        if (switchRef.current) {
          if (switchRef.current.checked) {
            analytics?.track(
              'Onboarding Ads Account Activated',
              {
                provider: EAdSenseProvider.TIKTOK,
              },
              { context: { groupId: storeAliasId } },
            );
          }
        }
      } catch (error: any) {
        if (switchRef.current) {
          switchRef.current.checked = !switchRef.current.checked;
        }

        setIsUpdatingAdAccount(false);
        toast.error(error?.response?.message?.data);
      }
    },
    [adSenseAccount, toast, updateAdSenseAccount, analytics, storeAliasId],
  );

  const parseIsOperationalName = React.useCallback(isOperational => {
    if (isOperational) return 'Operacional';

    return 'Não operacional';
  }, []);

  const parseStatusAccount = React.useCallback(status => {
    return status === 'ENABLED' ? 'Ativo' : 'Bloqueado';
  }, []);

  React.useEffect(() => {
    if (mergedSynchronizations.length) {
      const foundSynchronization = mergedSynchronizations.find(
        synchronization => synchronization.externalId === adSenseAccount.alias_id,
      );

      setIsSyncing(Boolean(foundSynchronization));
    } else {
      setIsSyncing(false);
    }
  }, [adSenseAccount, mergedSynchronizations]);

  React.useEffect(() => {
    if (finishedSynchronizations.length) {
      const filteredFinishedSynchronizations = finishedSynchronizations.filter(
        synchronization => synchronization.externalId === adSenseAccount.alias_id,
      );

      if (filteredFinishedSynchronizations.length) {
        const newFinishedSynchronzation = finishedSynchronizations.filter(
          synchronization => synchronization.externalId !== adSenseAccount.alias_id,
        );

        handleFinishedSynchronizations(newFinishedSynchronzation);
      }
    }
  }, [finishedSynchronizations, handleFinishedSynchronizations, adSenseAccount]);

  React.useEffect(() => {
    if (failedSynchronizations.length) {
      const filteredFailedSynchronizations = failedSynchronizations.filter(
        synchronization => synchronization.externalId === adSenseAccount.alias_id,
      );

      if (filteredFailedSynchronizations.length) {
        if (switchRef?.current) {
          switchRef.current.checked = false;
        }

        const newFailedSynchronization = failedSynchronizations.filter(
          synchronization => synchronization.externalId !== adSenseAccount.alias_id,
        );

        handleFailedSynchronizations(newFailedSynchronization);
      }
    }
  }, [toast, switchRef, failedSynchronizations, adSenseAccount, handleFailedSynchronizations]);

  const adSenseAccountProviderStatus = parseStatusAccount(adSenseAccount.status);

  return (
    <S.Table.Row borderBottom>
      <S.Table.Data>
        <S.Switch
          name={`switch-${adSenseAccount.id}`}
          id={`switch-${adSenseAccount.id}`}
          onChange={!isSyncing ? onSwitchChange : () => null}
          isLoading={isUpdatingAdAccount}
          defaultChecked={adSenseAccount.is_active}
          disabled={isSyncing || isUpdatingAdAccount}
          innerRef={switchRef}
        />
      </S.Table.Data>

      <S.Table.Data>
        <S.InfosWrapper>
          <S.AccountInfo>{adSenseAccount.provider_id}</S.AccountInfo>
          <S.Description>ID da conta</S.Description>
        </S.InfosWrapper>
      </S.Table.Data>

      <S.Table.Data>
        <S.InfosWrapper>
          <S.AccountInfo>{adSenseAccount.name}</S.AccountInfo>
          <S.Description>Nome</S.Description>
        </S.InfosWrapper>
      </S.Table.Data>

      {/* <S.Table.Data align={EHeadAlign.RIGHT}>
        <S.InfosWrapper>
          <S.AccountInfo>{`R$ ${numberFormatter(
            adSenseAccount.ad_sense_account_insight.spend_amount,
            0,
          )}`}</S.AccountInfo>
          <S.Description>Gasto dos últimos 7 dias</S.Description>
        </S.InfosWrapper>
      </S.Table.Data> */}

      <S.Table.Data align={EHeadAlign.RIGHT}>
        <Tooltip content="Status da conta no TikTok" side="bottom" sideOffset={8}>
          <Badge
            color={
              adSenseAccountProviderStatus === 'Ativo' ? EBadgeColors.PRIMARY : EBadgeColors.RED
            }
          >
            {adSenseAccountProviderStatus}
          </Badge>
        </Tooltip>
      </S.Table.Data>

      <S.Table.Data>
        <Badge color={adSenseAccount.is_operational ? EBadgeColors.PRIMARY : EBadgeColors.RED}>
          {parseIsOperationalName(adSenseAccount.is_operational)}
        </Badge>
      </S.Table.Data>
    </S.Table.Row>
  );
};

export default Row;
