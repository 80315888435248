import React from 'react';

import { GearSix } from 'phosphor-react';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { IParams } from '@domain/interfaces/IParams';
import { useConfig } from '@helpers/hooks/useConfig';
import * as S from './styles';

const UnconnectedInstance: React.FC = () => {
  const theme = useTheme();
  const { user } = useConfig();
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();

  const handleClick = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/marketing/messages-instances`);
  }, [history, storeAliasId]);

  return (
    <S.Container>
      <S.Content>
        <S.IconAndTextWrapper>
          <S.IconWrapper>
            <GearSix size={16} weight="fill" color={theme.colors.black.default} />
          </S.IconWrapper>

          <S.TextWrapper>
            <S.DescriptionText>
              Olá, {user?.first_name || 'GOAT'}! Você possui automações desconectadas.
              <S.LinkWrapper onClick={handleClick}>
                <S.Link>Clique aqui para reconectar</S.Link>
              </S.LinkWrapper>
            </S.DescriptionText>
          </S.TextWrapper>
        </S.IconAndTextWrapper>
      </S.Content>
    </S.Container>
  );
};

export default UnconnectedInstance;
