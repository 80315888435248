import { AxiosResponse } from 'axios';

import {
  ICreateCredentialProps,
  IDisableLpqvCredentialProps,
  IEnableLpqvCredentialProps,
  IGetLpqvCredentialProps,
  IGetLpqvCredentialResponse,
  IUpdateLpqvCredentialProps,
  IUpdateLpqvCredentialResponse,
} from '@domain/interfaces/dashboard/IntegrationCenter/StoreProvider/Lpqv/ILpqvAuth';

import { ApiService } from '@services/api';

import { useFetch } from '@providers/Fetch/useFetch';

import { API_DOMAIN } from '@constants/api';

export class LpqvAuthService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public createCredential({
    storeAliasId,
    data,
  }: ICreateCredentialProps): Promise<AxiosResponse<any>> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/lpqv-credentials`, {
      ...data,
    });
  }

  public getLpqvCredential({ storeAliasId }: IGetLpqvCredentialProps): IGetLpqvCredentialResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/lpqv-credentials`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      lpqvCredential: data?.lpqv_credential,
      error,
      isLoading,
      isValidating,
      mutate,
    };
  }

  public getLpqvCredentialPromise({ storeAliasId }: IGetLpqvCredentialProps): Promise<any> {
    return this.apiService.get(`/api/v1/users/stores/${storeAliasId}/lpqv-credentials`);
  }

  public disableCredential({
    storeAliasId,
    lpqvCredentialAliasId,
  }: IDisableLpqvCredentialProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/lpqv-credentials/${lpqvCredentialAliasId}`,
      { is_active: false },
    );
  }

  public enableCredential({
    storeAliasId,
    lpqvCredentialAliasId,
  }: IEnableLpqvCredentialProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/lpqv-credentials/${lpqvCredentialAliasId}`,
      { is_active: true },
    );
  }

  public updateLpqvCredential({
    storeAliasId,
    data,
    lpqvCredentialAliasId,
  }: IUpdateLpqvCredentialProps): Promise<AxiosResponse<IUpdateLpqvCredentialResponse>> {
    return this.apiService.put<IUpdateLpqvCredentialResponse>(
      `/api/v1/users/stores/${storeAliasId}/lpqv-credentials/${lpqvCredentialAliasId}`,
      {
        ...data,
      },
    );
  }
}

const lpqvAuthService = new LpqvAuthService(API_DOMAIN);

export default lpqvAuthService;
