import React from 'react';
import { Pen, Cancel } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { EBadgeColors } from '@domain/enums/components/EBadge';
import { IRowProps } from '@domain/interfaces/dashboard/CheckoutFee/IRow';

import { useDate } from '@helpers/hooks/useDate';
import { useCheckoutFee } from '@helpers/hooks/pages/dashboard/checkoutFee/useCheckoutFee';

import { numberFormatter } from '@helpers/masks';
import { getFormattedCurrency } from '@helpers/utils/common/currency';

import DeleteModal from '@components/common/core/Utils/DeleteModal';

import * as S from './styles';

const Row: React.FC<IRowProps> = ({ checkoutFee }) => {
  const { format, utcToZonedTime } = useDate();

  const {
    handleUserPeriodCheckoutFee,
    handleEditingCheckoutFee,
    deleteCheckoutFeePeriod,
    isDeletingHistoricCheckoutFee,
    handleEditCheckoutFeeSideModalOpen,
  } = useCheckoutFee();
  const theme: any = useTheme();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);
  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);

  const handlePopoverOpen = React.useCallback(() => setIsPopoverOpen(!isPopoverOpen), [
    isPopoverOpen,
  ]);

  const handleIsHovering = React.useCallback(status => setIsHovering(status), []);

  const handleDeleteModalOpen = React.useCallback(() => setIsDeleteModalOpen(!isDeleteModalOpen), [
    isDeleteModalOpen,
  ]);

  const onDelete = React.useCallback(async () => {
    await deleteCheckoutFeePeriod(checkoutFee);

    handleDeleteModalOpen();
  }, [deleteCheckoutFeePeriod, checkoutFee, handleDeleteModalOpen]);

  const onEditClick = React.useCallback(() => {
    setIsPopoverOpen(false);

    handleUserPeriodCheckoutFee(checkoutFee);
    handleEditCheckoutFeeSideModalOpen();

    handleEditingCheckoutFee();
  }, [
    checkoutFee,
    handleUserPeriodCheckoutFee,
    handleEditingCheckoutFee,
    handleEditCheckoutFeeSideModalOpen,
  ]);

  const onDeleteClick = React.useCallback(() => {
    setIsPopoverOpen(false);
    handleDeleteModalOpen();
  }, [handleDeleteModalOpen]);

  return (
    <S.Table.Row
      onMouseEnter={() => handleIsHovering(true)}
      onMouseLeave={() => handleIsHovering(false)}
      borderBottom
    >
      <S.Table.Data>
        {checkoutFee.start_date
          ? format(utcToZonedTime(checkoutFee.start_date), 'dd/MM/yyyy')
          : '-'}
      </S.Table.Data>

      <S.Table.Data>
        {checkoutFee.end_date ? format(utcToZonedTime(checkoutFee.end_date), 'dd/MM/yyyy') : '-'}
      </S.Table.Data>

      <S.Table.Data align={EHeadAlign.RIGHT}>
        {checkoutFee.percentage_amount
          ? `${numberFormatter(checkoutFee.percentage_amount, 2)}%`
          : `${getFormattedCurrency(checkoutFee.currency)}${numberFormatter(
              checkoutFee.amount,
              2,
            )}`}
      </S.Table.Data>

      <S.Table.Data>
        {checkoutFee.is_default && (
          <S.DefaultCheckoutFeeBadge color={EBadgeColors.PRIMARY}>Padrão</S.DefaultCheckoutFeeBadge>
        )}
      </S.Table.Data>

      <S.Table.Data align={EHeadAlign.CENTER}>
        <S.Popover open={isPopoverOpen} onOpenChange={handlePopoverOpen}>
          <S.Popover.Trigger>
            <S.MoreActionsButton isHovering={isHovering}>
              <S.Bullet />
              <S.Bullet />
              <S.Bullet />
            </S.MoreActionsButton>
          </S.Popover.Trigger>

          <S.Popover.Content>
            <S.MoreOptionWrapper>
              <S.ActionButton onClick={onEditClick}>
                <Pen outline size={16} color={theme.colors.font} />
                Editar
              </S.ActionButton>
              <S.ActionButton onClick={onDeleteClick} isDeleteButton>
                <Cancel outline size={16} color={theme.colors.danger.default} />
                Excluir
              </S.ActionButton>
            </S.MoreOptionWrapper>

            <S.Popover.Arrow />
          </S.Popover.Content>
        </S.Popover>

        <DeleteModal
          isOpen={isDeleteModalOpen}
          toggle={handleDeleteModalOpen}
          title="Deseja realmente excluir este imposto?"
          content="Essa ação é irreversível, deseja realmente excluir este imposto?"
          isLoading={isDeletingHistoricCheckoutFee}
          onDelete={onDelete}
        />
      </S.Table.Data>
    </S.Table.Row>
  );
};

export default Row;
