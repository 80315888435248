export const cnpjFormatter = (value = ''): string => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const getCnpjNumber = (value = ''): string => {
  return value.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '');
};
