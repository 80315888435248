/* eslint-disable no-console */
import React from 'react';

import { MagnifyingGlass, Plus } from 'phosphor-react';
import { useTheme } from 'styled-components';

import { ISelectedFilter } from '@domain/interfaces/components/IFilter';
import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { EButtonSize } from '@domain/enums/components/EButton';

import { useMessage } from '@helpers/hooks/pages/dashboard/automations/useMessage';
import { useMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useMessageSettings';
import { useDebounce } from '@helpers/hooks/useDebounce';
import { useConfig } from '@helpers/hooks/useConfig';

import { AUTOMATIONS_MESSAGES_FILTER_CONFIG } from '@helpers/utils/filters/messagesFilter';

import * as S from './styles';

const MAX_MESSAGES = 50;

const Header: React.FC = () => {
  const theme = useTheme();
  const { analytics, selectedStore } = useConfig();
  const { messages } = useMessage();
  const { handleNewMessageModalOpen, handleSearchValue } = useMessageSettings();

  const [searchValue, setSearchValue] = React.useState<string>('');
  const [filterData] = React.useState<Array<ISelectedFilter>>([]);

  const debouncedNameValue = useDebounce(searchValue, 700);

  const onSearchInputChange = React.useCallback(event => {
    setSearchValue(event.target.value);
  }, []);

  const onNewMessageClick = React.useCallback(() => {
    analytics?.track(
      'Automation Tool New Message Created',
      {},
      { context: { groupId: selectedStore?.alias_id } },
    );

    handleNewMessageModalOpen(true);
  }, [handleNewMessageModalOpen, analytics, selectedStore]);

  const handleAddNewData = React.useCallback(newData => {
    console.log(newData);
  }, []);

  React.useEffect(() => {
    handleSearchValue(debouncedNameValue);
  }, [handleSearchValue, debouncedNameValue]);

  const isWithinMaxMessagesLimit = messages?.length < MAX_MESSAGES;

  return (
    <S.Wrapper>
      <S.HeadingWrapper>
        <S.Heading fontWeight={EHeadingWeight.SEMIBOLD}>Todas as mensagens</S.Heading>
        <S.Description>Visualize todas as mensagens já criadas</S.Description>
      </S.HeadingWrapper>

      <S.RightSide>
        {isWithinMaxMessagesLimit && (
          <S.NewMessageButton
            buttonSize={EButtonSize.MEDIUM}
            onClick={onNewMessageClick}
            iconPrepend={() => <Plus size={14} color={theme.colors.gray[8]} weight="bold" />}
          >
            Nova mensagem
          </S.NewMessageButton>
        )}

        <S.FilterAndSearchInputWrapper>
          <S.FilterButton
            filterAlias="automation_messages"
            data={filterData}
            config={AUTOMATIONS_MESSAGES_FILTER_CONFIG}
            addNewData={handleAddNewData}
          />

          <S.Input
            type="text"
            onChange={onSearchInputChange}
            prepend={() => <MagnifyingGlass size={20} color={theme.colors.gray[3]} weight="bold" />}
            placeholder="Procurar mensagem"
            noFeedbackError
          />
        </S.FilterAndSearchInputWrapper>
      </S.RightSide>
    </S.Wrapper>
  );
};

export default Header;
