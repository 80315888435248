/* eslint-disable dot-notation */
import React from 'react';
import { Menu } from '@profitfy/pakkun-icons';
import { useLocation } from 'react-router-dom';

import Text from '@components/common/core/DataDisplay/Text';
import { EAvatarSize } from '@domain/enums/components/EAvatar';
import { useConfig } from '@helpers/hooks/useConfig';
import { IStore } from '@domain/interfaces/stores/IStore';
import Dropdown from './Dropdown';

import * as S from './styles';

const PricingNavbar: React.FC = () => {
  const { stores, user, analytics, selectedStore } = useConfig();
  const { pathname } = useLocation();

  const [currentStore, setCurrentStore] = React.useState<IStore>();

  React.useEffect(() => {
    const foundStoreAliasId = pathname.match(/store-([a-zA-Z0-9]+)/)?.pop();

    if (foundStoreAliasId) {
      const foundStore = stores.find(store => {
        return store.alias_id.includes(foundStoreAliasId);
      });
      setCurrentStore(foundStore);
    }
  }, [pathname, stores]);

  React.useEffect(() => {
    let widget: any;

    const config = {
      key: '9346df30-3ffd-4e1d-9a5b-86d17ca4a4f2',
      callbacks: {
        onReady: (frillWidget: any) => {
          widget = frillWidget;
        },
      },
    };

    if ('Frill' in window) {
      widget = window['Frill'].widget(config);
    } else {
      window['Frill_Config'] = window['Frill_Config'] || [];
      window['Frill_Config'].push(config);
    }

    return () => {
      if (widget) {
        widget.destroy();
      }
      if (window['Frill_Config']) {
        window['Frill_Config'] = window['Frill_Config'].filter((c: any) => c !== config);
      }
    };
  }, []);

  const trackNewsButton = React.useCallback(() => {
    return analytics?.track(
      'New Features Post Viewed',
      { email: user?.email },
      { context: { groupId: selectedStore?.alias_id } },
    );
  }, [analytics, user, selectedStore]);

  return (
    <S.NavBar>
      <S.TitleWrapper>
        <Menu size={24} />
        <Text>PROFITFY</Text>
      </S.TitleWrapper>

      <S.MenuWrapper>
        <S.NewsButton id="my-frill-widget" className="my-frill-widget" onClick={trackNewsButton}>
          Novidades
        </S.NewsButton>

        <Dropdown>
          <S.CurrentStoreWrapper>
            <S.StoreAvatar
              word={currentStore?.name.charAt(0) || 'N'}
              size={EAvatarSize.MEDIUM}
              alt="Avatar"
            />
            <S.UserDetails>
              <S.CurrentStoreName>{currentStore?.name}</S.CurrentStoreName>
              <S.ProfileName>{`${user.first_name} ${user.last_name}`}</S.ProfileName>
            </S.UserDetails>
          </S.CurrentStoreWrapper>
        </Dropdown>
      </S.MenuWrapper>
    </S.NavBar>
  );
};

export default React.memo(PricingNavbar);
