import React from 'react';
import { useHistory } from 'react-router-dom';
import { useConfig } from '@helpers/hooks/useConfig';
import userService from '@services/user';
import * as S from './styles';

interface IFinishedStepProps {
  storeAliasId: string;
}

const FinishedStep: React.FC<IFinishedStepProps> = ({ storeAliasId }) => {
  const history = useHistory();
  const { analytics, user } = useConfig();

  const handleSkip = React.useCallback(() => {
    analytics?.track(
      'Store Onboarding Clicked',
      {
        button_click: 'Skip',
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );

    history.push(`/${storeAliasId}/dashboard/home/home`);
  }, [history, storeAliasId, analytics, user]);

  const handleDemoStore = React.useCallback(async () => {
    const { data } = await userService.assignDemoStore();

    if (data?.store) {
      analytics?.track(
        'Store Onboarding Clicked',
        {
          button_click: 'Demo Store',
          email: user?.email,
        },
        { context: { groupId: storeAliasId } },
      );

      history.push(`/${data.store.alias_id}/dashboard/overview/financial`);
      return;
    }

    history.push('/stores');
  }, [history, analytics, storeAliasId, user]);

  return (
    <>
      <S.ImageWrapper>
        <S.Image
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/onboarding/images/content/onboarding-account-setting.svg"
          alt="Profitfy.me finished user config"
        />
      </S.ImageWrapper>
      <S.Title>Parabéns! Sua loja foi criada com sucesso.</S.Title>
      <S.Desciption>
        Agora, você pode pular diretamente para sua nova loja ou explorar nossa loja demo para
        descobrir todas as possibilidades.
      </S.Desciption>

      <S.ButtonsWrapper>
        <S.SkipButton onClick={handleSkip}>Acessar Loja</S.SkipButton>
        <S.ConfirmButton type="button" onClick={handleDemoStore}>
          Ir para loja demo
        </S.ConfirmButton>
      </S.ButtonsWrapper>
    </>
  );
};

export default FinishedStep;
