import React from 'react';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { useCustomValuesIn } from '@helpers/hooks/pages/dashboard/customValues/useCustomValuesIn';
import Paginate from '@components/common/core/Utils/Paginate';
import Row from './Row';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Table: React.FC = () => {
  const {
    onPageChange,
    pageCount,
    page,
    customValues,
    isLoadingCustomValues,
    isLoadingCustomValuesCategories,
    isValidatingCustomValues,
    selectedCustomValueChecked,
  } = useCustomValuesIn();

  if (isLoadingCustomValues || isLoadingCustomValuesCategories || isValidatingCustomValues) {
    return <SkeletonLoading />;
  }

  return (
    <S.Table isSelectedCustomValueToChecked={Boolean(selectedCustomValueChecked)}>
      <S.Table.Header>
        <S.Table.Row>
          <S.Table.Head />
          <S.Table.Head>Descrição</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Começa em</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Termina em</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Valor</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Frequência</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Status</S.Table.Head>
          <S.Table.Head />
        </S.Table.Row>
      </S.Table.Header>

      <S.Table.Body>
        {customValues.length ? (
          <>
            {customValues.map(customValue => (
              <Row customValue={customValue} key={customValue.alias_id} />
            ))}
          </>
        ) : (
          <S.Table.Row>
            <S.Table.Data colSpan={8} align={EHeadAlign.CENTER}>
              <S.EmptyData content="Não encontramos nenhum valor adicional." />
            </S.Table.Data>
          </S.Table.Row>
        )}
      </S.Table.Body>

      {customValues?.length ? (
        <S.Table.Footer>
          <S.Table.Row>
            <S.Table.Data colSpan={9} align={EHeadAlign.RIGHT}>
              <Paginate forcePage={page} pageCount={pageCount} onPageChange={onPageChange} />
            </S.Table.Data>
          </S.Table.Row>
        </S.Table.Footer>
      ) : (
        <></>
      )}
    </S.Table>
  );
};

export default Table;
