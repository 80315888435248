/* eslint-disable import/no-duplicates */
import React from 'react';

import { CaretRight } from '@profitfy/pakkun-icons';
import { formatDistance, differenceInDays } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import { IStoreGoalProps } from '@domain/interfaces/dashboard/FinancialDashboard/IStoreGoal';
import { useDate } from '@helpers/hooks/useDate';
import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { useMediaQuery } from '@helpers/hooks/useMediaQuery';
import { numberFormatter } from '@helpers/masks';

import PieChart from './PieChart';
import NoStoreGoalContent from './NoStoreGoalContent';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const StoreGoal: React.FC<IStoreGoalProps> = ({ isNoRetroactiveDashboard, ...rest }) => {
  const { utcToZonedTime } = useDate();
  const {
    handleEditStoreGoalSideModal,
    isLoadingFinancialDashboardProvider,
    isValidatingFinancialDashboardProvider,
    financialDetailsReport,
    isMultiStoreSelectMode,
  } = useFinancialDashboard();

  const isMobileWidth = useMediaQuery('1435px');

  const getSkeletonLoadingHeight = (): number => {
    if (isNoRetroactiveDashboard && isMobileWidth) {
      return 224;
    }

    if (isNoRetroactiveDashboard && !isMobileWidth) {
      return 138;
    }

    return 138;
  };

  if (isLoadingFinancialDashboardProvider || isValidatingFinancialDashboardProvider) {
    return <SkeletonLoading height={getSkeletonLoadingHeight()} />;
  }

  const hasGoal = Boolean(financialDetailsReport.goal);

  const hasReachedGoal =
    financialDetailsReport.goal &&
    financialDetailsReport.goal.current_amount >= financialDetailsReport.goal.goal_amount;

  const targetDate = financialDetailsReport?.goal?.end_date || new Date();

  const today = new Date();

  const percentage = financialDetailsReport.goal
    ? (financialDetailsReport.goal.current_amount * 100) / financialDetailsReport.goal?.goal_amount
    : 0;

  const parsedPercentageValue = String(percentage);

  const MAX_CHARACTERS_ALLOWED = 6;

  const isPercentageValueSmall = parsedPercentageValue.length >= MAX_CHARACTERS_ALLOWED;

  const remainingGoalDays = differenceInDays(utcToZonedTime(targetDate), utcToZonedTime(today));
  const remainingText = formatDistance(utcToZonedTime(today), utcToZonedTime(targetDate), {
    locale: pt,
    includeSeconds: false,
  });

  if (isMultiStoreSelectMode) {
    return (
      <S.StoreGoalCard>
        <S.Header>
          <S.TitleAndRemainingPeriod>
            <S.Title isMultiStoreSelectMode>
              Não é possível visualizar metas no modo de multiseleção de lojas
            </S.Title>
          </S.TitleAndRemainingPeriod>
        </S.Header>

        <S.Body />
      </S.StoreGoalCard>
    );
  }

  return (
    <S.StoreGoalCard {...rest}>
      {hasGoal ? (
        <>
          <S.Header>
            <S.TitleAndRemainingPeriod>
              <S.Title>Minha meta</S.Title>
              <S.RemainingPeriodText>
                {remainingGoalDays < 0 ? 'Prazo da meta vencida' : `${remainingText} restantes`}
              </S.RemainingPeriodText>
            </S.TitleAndRemainingPeriod>
            <S.EditGoal onClick={handleEditStoreGoalSideModal}>
              Editar meta
              <CaretRight size={12} />
            </S.EditGoal>
          </S.Header>

          <S.Body>
            <S.ChartAndPercentage>
              <S.HalfPieChart>
                <S.ChartWrapper>
                  <PieChart />
                </S.ChartWrapper>
              </S.HalfPieChart>

              <S.ChartPercentageWrapper>
                <S.Percentage isSmall={isPercentageValueSmall}>{`${numberFormatter(
                  percentage,
                  0,
                )}%`}</S.Percentage>
                <S.PercentageLabel>Completa</S.PercentageLabel>
              </S.ChartPercentageWrapper>
            </S.ChartAndPercentage>
            {hasReachedGoal ? (
              <S.ReachedGoal>
                <S.ProgressLabel>Meta de faturamento alcançada</S.ProgressLabel>
                <S.ReachedGoalValue>{`R$${numberFormatter(
                  financialDetailsReport.goal?.current_amount || 0,
                  2,
                )}`}</S.ReachedGoalValue>
              </S.ReachedGoal>
            ) : (
              <>
                <S.CurrentProgress>
                  <S.ProgressLabel>Atual</S.ProgressLabel>
                  <S.CurrentProgressText>{`R$${numberFormatter(
                    financialDetailsReport.goal?.current_amount || 0,
                    2,
                  )}`}</S.CurrentProgressText>
                </S.CurrentProgress>
                <S.GoalTarget>
                  <S.ProgressLabel>Meta</S.ProgressLabel>
                  <S.GoalTargetText>{`R$${numberFormatter(
                    financialDetailsReport.goal?.goal_amount || 0,
                    2,
                  )}`}</S.GoalTargetText>
                </S.GoalTarget>
              </>
            )}
          </S.Body>
        </>
      ) : (
        <NoStoreGoalContent isNoRetroactiveDashboard />
      )}
    </S.StoreGoalCard>
  );
};

export default StoreGoal;
