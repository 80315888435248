import styled from 'styled-components/macro';

import { IColorProps } from '@domain/interfaces/dashboard/CustomValuesCategories/IColorPicker';

export const CustomColor = styled.button`
  background: linear-gradient(136.59deg, #e23939 15.33%, #201daf 84.62%);
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.background[8]};
  cursor: pointer;
  transition: all 0.1s;

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.font};
  }
`;

export const Color = styled.button<IColorProps>`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.background[8]};
  background-color: ${({ color }) => color};
  cursor: pointer;
  transition: all 0.1s;

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.font};
  }
`;

export const Arrow = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid ${({ theme }) => theme.colors.background[8]};
  position: absolute;
  top: 20px;
  left: -7px;
`;

export const ColorPickerWrapper = styled.div`
  position: absolute;
  top: 30px;
  left: -64px;
  background-color: ${({ theme }) => theme.colors.background[8]};
  border-radius: 15px;
  width: 346px;
  z-index: ${({ theme }) => theme.zIndex.thirdLayer};
  padding: 24px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  box-sizing: border-box;
`;

export const Wrapper = styled.div``;
