import styled from 'styled-components/macro';

export const OrderDateWrapper = styled.div`
  max-width: 120px;
  width: 100%;
`;

export const FirstOrderItemWrapper = styled.div`
  width: 100%;
`;

export const OrderNumberWrapper = styled.div`
  max-width: 128px;
  width: 100%;
`;

export const Wrapper = styled.div`
  min-width: 768px;
  padding: 12px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 48px;
`;
