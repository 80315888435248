import React from 'react';

import { EUserStoreRole } from '@domain/enums/common/userStore/EUserStoreRole';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import { AccessManagerProvider } from '@helpers/hooks/pages/dashboard/accessManagement/useAccessManager';

import Content from '@components/Dashboard/AccessManagement/NewGroup';
import NoAccess from '@components/common/core/Utils/NoAccess';

const NewGroup: React.FC = () => {
  const { accessManagementData } = useStoreConfig();
  const { isInternalTeam } = useConfig();

  const isStoreOwner = accessManagementData?.role === EUserStoreRole.OWNER;

  if (!isStoreOwner && !isInternalTeam) {
    return <NoAccess />;
  }

  return (
    <AccessManagerProvider>
      <Content />
    </AccessManagerProvider>
  );
};

export default NewGroup;
