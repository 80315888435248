import styled from 'styled-components/macro';

import ButtonBase from '@components/common/core/Inputs/Button';

export const CancelButton = styled.button`
  border: unset;
  background: unset;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.font};
  font-size: 0.875rem;
  font-weight: 500;
  padding: 12px 16px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.green.default};
  }

  &:active {
    color: ${({ theme }) => theme.colors.green.default};
  }
`;

export const SaveButton = styled(ButtonBase)``;

export const Wrapper = styled.div`
  position: fixed;
  width: 787px;
  bottom: 0;
  right: 0;
  height: 92px;
  padding: 24px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.background[2]};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`;
