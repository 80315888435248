import React from 'react';
import { Woocommerce as Icon } from '@profitfy/pakkun-icons';
import { useHistory, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { EEcommerceProvider } from '@domain/enums/common/integrations/EEcommerceProvider';
import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useEcommerce } from '@helpers/hooks/pages/onboarding/useEcommerce';
import { useDate } from '@helpers/hooks/useDate';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';

import { wooCommerceSchema } from '@helpers/validators/dashboard/integrationCenter/woocommerce';

import { newWindow } from '@helpers/hooks/usePopup';

import { ECOMMERCE_PROVIDERS } from '@constants/common/integrations';

import integrationCenterService from '@services/pages/dashboard/integrationCenter/integrationCenter';
import woocommerceService from '@services/pages/dashboard/integrationCenter/marketplace/woocommerce/woocommerceAuth';

import NextStep from '@components/Onboarding/NextStep';
import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const Woocommerce: React.FC = () => {
  const history = useHistory();

  const { toast } = useToast();
  const { addSynchronization } = useSynchronization();
  const { sendFinancialData, handleStoreBonus } = useOnboarding();
  const { format, utcToZonedTime } = useDate();
  const { storeAliasId } = useParams<IParams>();
  const { selectedGateways, selectedMarketings } = useEcommerce();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(wooCommerceSchema) });

  const domainRegister = register('domain');

  const [isIntegrating, setIsIntegrating] = React.useState<boolean>(false);
  const [userWindow, setUserWindow] = React.useState<Window | undefined>(undefined);

  const onSubmit = React.useCallback(
    async formData => {
      const includeProtocol =
        formData.domain.includes('https://') || formData.domain.includes('http://');
      const lastDomainCharacter = formData.domain.slice(-1);

      let { domain } = formData;

      if (!includeProtocol) {
        domain = `https://${domain}`;
      }

      if (lastDomainCharacter === '/') {
        domain = domain.slice(0, -1);
      }

      domain = domain.replace('www.', '');

      setIsIntegrating(true);

      try {
        const { data: woocommerceData } = await woocommerceService.generateURL({
          storeAliasId,
          data: { domain },
        });

        const window = newWindow(woocommerceData?.auth_url, 'WooCommerce', 1280, 720);

        setUserWindow(window);
        setIsIntegrating(false);
      } catch (error: any) {
        setIsIntegrating(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [storeAliasId, toast],
  );

  const handleWindowClose = React.useCallback(async () => {
    const { data } = await integrationCenterService.getStoreIntegrations({ storeAliasId });

    const hasWoocommerceCredential =
      data?.store_integrations?.store_front?.has_woo_commerce_credential;

    if (hasWoocommerceCredential) {
      const foundIntegration = ECOMMERCE_PROVIDERS.find(
        integration => integration.identifier === EEcommerceProvider.WOO_COMMERCE,
      ) as typeof ECOMMERCE_PROVIDERS[number];

      const { name, generateSyncRequest } = foundIntegration;

      const params = `?start_date=${format(new Date(2000, 1, 1), 'yyyy-MM-dd')}&end_date=${format(
        utcToZonedTime(new Date()),
        'yyyy-MM-dd',
      )}`;

      addSynchronization({
        name,
        type: ESynchronizationType.STORE_PRODUCT,
        label: 'Produtos',
        dependencies: [],
        storeAliasId,
        config: {
          isOnboardingSynchronization: true,
          showNotification: false,
        },
        request: generateSyncRequest(ESynchronizationType.STORE_PRODUCT, storeAliasId, params),
      });

      sendFinancialData(storeAliasId);

      setIsIntegrating(false);

      if (!selectedGateways.length && !selectedMarketings.length) {
        handleStoreBonus();
        return;
      }

      if (selectedGateways.length) {
        history.push(`/onboarding/${storeAliasId}/ecommerce/integrations/gateways`);
      }

      if (!selectedGateways.length && selectedMarketings.length) {
        history.push(`/onboarding/${storeAliasId}/ecommerce/integrations/marketing`);
      }
    } else {
      setIsIntegrating(false);
    }
  }, [
    addSynchronization,
    format,
    history,
    selectedGateways,
    selectedMarketings,
    storeAliasId,
    utcToZonedTime,
    sendFinancialData,
    handleStoreBonus,
  ]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (userWindow?.closed) {
        clearInterval(interval);
        handleWindowClose();
      }
    }, 1000);
  }, [userWindow, history, selectedGateways, selectedMarketings, storeAliasId, handleWindowClose]);

  return (
    <S.Wrapper>
      <S.IntegrationContent>
        <S.Header>
          <S.Title>Integre com sua plataforma de E-commerce</S.Title>
        </S.Header>

        <S.IntegrationTitle>
          <Icon size={36} />

          <S.IntegrationTitleText fontWeight={EHeadingWeight.REGULAR}>
            Integração com Woocommerce
          </S.IntegrationTitleText>
        </S.IntegrationTitle>

        <S.Body>
          <S.Text>
            Para continuar com a integração, vá para o próximo passo e realize a integração com a
            Woocommerce
          </S.Text>
        </S.Body>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>Domínio</S.Label>
            <S.TextField
              {...domainRegister}
              type="text"
              id="woocommerce-store-url"
              placeholder="https://exemplo.com.br"
            />
            {errors.domain && <Text isErrorFeedback>{errors?.domain?.message}</Text>}
          </S.InputGroup>

          <NextStep type="submit" isLoading={isIntegrating} disabled={isIntegrating}>
            Próximo passo
          </NextStep>
        </Form>
      </S.IntegrationContent>

      <S.TutorialWrapper>
        <S.TutorialImage
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/onboarding/images/content/onboarding/onboarding-integration-tutorial.svg"
          alt="Profitfy.me Integration Tutorial"
        />
        <S.TutorialTitle>Tutorial de integração</S.TutorialTitle>
        <S.TutorialContent>
          Para realizar a integração você precisará seguir o próximo passo e então integrar com a
          Woocommerce.
        </S.TutorialContent>
      </S.TutorialWrapper>
    </S.Wrapper>
  );
};

export default Woocommerce;
