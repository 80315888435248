import styled, { css } from 'styled-components/macro';

import { ISelectStyleProps } from '@domain/interfaces/components/ISelect';

import selectArrow from '@assets/img/select/select-arrow.svg';

export const Select = styled.select<ISelectStyleProps>`
  height: 42px;
  padding: 10px 32px 10px 12px;
  line-height: 100%;
  border-radius: 5px;
  border: none;
  background-color: ${({ theme }) => theme.colors.background[2]};
  color: ${({ theme }) => theme.colors.font};
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  font-size: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.3s;
  border: 1px solid ${({ theme }) => theme.colors.inputBorderColor};
  width: 100%;

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.green.dark};
    border-color: ${({ theme }) => theme.colors.inputBorderColorHover};
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${({ theme, readOnly }) =>
    readOnly &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      touch-action: none;
      background-color: ${theme.colors.background[3]};
    `}
`;

export const Label = styled.label``;

export const Container = styled.div`
  position: relative;
  width: fit-content;

  &:after {
    content: url(${selectArrow});
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
  }
`;

export const PrependAndAppendText = styled.span`
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.font};
  font-weight: 600;
  font-size: 1rem;
  line-height: 100%;
  margin-top: 4px;
`;

export const VerticalRule = styled.div`
  width: 1px;
  height: 25px;
  margin: 0 2px 0 12px;
  background-color: ${({ theme }) => theme.colors.gray[3]};
`;
