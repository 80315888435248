import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ESelectedCategoryOption } from '@domain/enums/dashboard/automations/EMessage';

import { useMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useMessageSettings';

import * as S from './styles';

const MessageOptions: React.FC = () => {
  const { selectedCategoryOption, handleSelectedCategoryOption } = useMessageSettings();

  const onOptionClick = React.useCallback(
    event => {
      handleSelectedCategoryOption(event.target.value);
    },
    [handleSelectedCategoryOption],
  );

  return (
    <S.Wrapper>
      <S.Heading type={EHeadingSize.H3} fontWeight={EHeadingWeight.REGULAR}>
        Mensagens
      </S.Heading>

      <S.OptionsWrapper>
        <S.Option
          isSelected={selectedCategoryOption === ESelectedCategoryOption.ALL_MESSAGES}
          value={ESelectedCategoryOption.ALL_MESSAGES}
          onClick={onOptionClick}
        >
          Todas as mensagens
        </S.Option>

        <S.Option
          isSelected={selectedCategoryOption === ESelectedCategoryOption.WHATSAPP}
          value={ESelectedCategoryOption.WHATSAPP}
          onClick={onOptionClick}
        >
          Whatsapp
        </S.Option>

        <S.Option
          isSelected={selectedCategoryOption === ESelectedCategoryOption.EMAIL}
          value={ESelectedCategoryOption.EMAIL}
          onClick={onOptionClick}
        >
          E-mail
        </S.Option>

        <S.Option
          isSelected={selectedCategoryOption === ESelectedCategoryOption.SMS}
          value={ESelectedCategoryOption.SMS}
          onClick={onOptionClick}
        >
          SMS
        </S.Option>
      </S.OptionsWrapper>
    </S.Wrapper>
  );
};

export default MessageOptions;
