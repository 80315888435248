import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';
import {
  IGetProps,
  IGetResponse,
  ICreateCredentialProps,
  IUpdateCredentialProps,
  IDeleteCredentialProps,
} from '@domain/interfaces/dashboard/IntegrationCenter/Gateway/ICartPandaPay';

export class CartPandaPayService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getCredential({ storeAliasId }: IGetProps): IGetResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/cart-panda-pay-credentials`,
      this.apiService,
    );

    const isLoading = !error && !data;

    return {
      cartPandaPayCredential: data?.cart_panda_pay_credential,
      error,
      mutate,
      isLoading,
      isValidating,
    };
  }

  public createCredential({ storeAliasId, data }: ICreateCredentialProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/cart-panda-pay-credentials`, {
      ...data,
    });
  }

  public updateCredential({ storeAliasId, data }: IUpdateCredentialProps): Promise<any> {
    return this.apiService.put(`/api/v1/users/stores/${storeAliasId}/cart-panda-pay-credentials`, {
      ...data,
    });
  }

  public deleteCredential({ storeAliasId }: IDeleteCredentialProps): Promise<any> {
    return this.apiService.delete(
      `/api/v1/users/stores/${storeAliasId}/cart-panda-pay-credentials`,
    );
  }
}

const cartPandaPayService = new CartPandaPayService(API_DOMAIN);

export default cartPandaPayService;
