import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';

export const VariantsTable = styled(TableBase)`
  thead {
    background-color: unset;
  }

  td:first-child {
    padding-left: unset;
  }

  tr > th:first-child {
    padding-left: unset;
  }

  tr > td:last-child {
    padding: unset;
  }
`;
