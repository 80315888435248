/* eslint-disable import/no-duplicates */
import React from 'react';
import { Search, Sync, ChevronDown, Copy, Column } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { formatDistanceToNow } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import { ECampaignStatus } from '@domain/enums/dashboard/adsManager/ECampaigns';

import { useAdsManager } from '@helpers/hooks/pages/dashboard/adsManager/useAdsManager';
import { useStorePermissions } from '@helpers/hooks/common/useStorePermissions';
import { useDate } from '@helpers/hooks/useDate';
import { useDebounce } from '@helpers/hooks/useDebounce';
import { useCampaigns } from '@helpers/hooks/pages/dashboard/adsManager/useCampaigns';
import { useToast } from '@helpers/hooks/useToast';

import { ADS_MANAGER_FILTER_CONFIG } from '@helpers/utils/filters/adsManagerFilter';

import CampaignsSelectContent from './CampaignsSelectContent';
import EditSelectContent from './EditSelectContent';
// import MarketingSelectContent from './MarketingSelectContent';
import ColumnSelectContent from './ColumnsSelectContent';

import * as S from './styles';

const Header: React.FC = () => {
  const { utcToZonedTime } = useDate();
  const {
    period,
    setPeriod,
    handleSearchFilter,
    campaignsStatusFilter,
    syncAdsManagerData,
    lastSyncDate,
    hasAdsManagerSynchronization,
    filterData,
    handleFilterData,
  } = useAdsManager();
  const { maxDateLimiter } = useStorePermissions();
  const theme = useTheme();
  const { selectedCampaigns, handleDuplicateModalOpen, isDuplicateModalOpen } = useCampaigns();
  const { toast } = useToast();

  const [, setUpdatedDate] = React.useState<Date>(new Date());
  const [searchFilter, setSearchFilter] = React.useState<string>('');
  const [isCampaignsStatusDropdownOpen, setIsCampaignsStatusDropdownOpen] = React.useState<boolean>(
    false,
  );
  const [isEditSelectOpen, setIsEditSelectOpen] = React.useState<boolean>(false);

  const handleDuplicateCampaignsModalOpen = React.useCallback(() => {
    if (!isDuplicateModalOpen && selectedCampaigns.length === 0) {
      toast.error('Selecione pelo menos uma campanha.');
    } else {
      handleDuplicateModalOpen();
    }
  }, [isDuplicateModalOpen, toast, handleDuplicateModalOpen, selectedCampaigns]);

  const handleCampaignsStatusDropdownOpen = React.useCallback(status => {
    setIsCampaignsStatusDropdownOpen(status);
  }, []);

  const onSearchFilterChange = React.useCallback(event => {
    setSearchFilter(event.target.value);
  }, []);

  const getCampaignsStatusName = React.useCallback(() => {
    if (campaignsStatusFilter.length > 1) return 'Todas Campanhas';

    if (campaignsStatusFilter[0] === ECampaignStatus.ENABLED) return 'Ativas';

    return 'Inativas';
  }, [campaignsStatusFilter]);

  const handleNewData = React.useCallback(
    newData => {
      handleFilterData([newData]);
    },
    [handleFilterData],
  );

  const debouncedSearchFilter = useDebounce(searchFilter, 700);

  React.useEffect(() => {
    handleSearchFilter(debouncedSearchFilter);
  }, [debouncedSearchFilter, handleSearchFilter]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setUpdatedDate(new Date());
    }, 60000);

    return () => clearInterval(interval);
  }, [lastSyncDate]);

  const syncText = hasAdsManagerSynchronization
    ? 'Sincronizando...'
    : `Atualizado há ${formatDistanceToNow(utcToZonedTime(lastSyncDate), {
        locale: pt,
      })}`;

  return (
    <S.Wrapper>
      <S.Title>Gerenciador de Anúncios</S.Title>
      <S.FilterWrapper>
        <S.SearchInputGroup>
          <S.SearchInput
            type="text"
            placeholder="Pesquisar e filtrar"
            prepend={Search}
            onChange={onSearchFilterChange}
          />
        </S.SearchInputGroup>

        <S.LastTimeUpdatedAndDatePickerWrapper>
          <S.LastTimeUpdatedWrapper>
            <S.LastTimeUpdatedText>{syncText}</S.LastTimeUpdatedText>

            <S.UpdateDataButton
              onClick={syncAdsManagerData}
              disabled={hasAdsManagerSynchronization}
              isSyncing={hasAdsManagerSynchronization}
            >
              <Sync size={16} color={theme.colors.gray[1]} />
            </S.UpdateDataButton>
          </S.LastTimeUpdatedWrapper>

          <S.DatePicker
            period={period}
            setPeriod={setPeriod}
            maxDate={maxDateLimiter()}
            excludeTodayInLastSevenDays
          />
        </S.LastTimeUpdatedAndDatePickerWrapper>
      </S.FilterWrapper>

      <S.ActionsWrapper>
        <S.DuplicateButton onClick={handleDuplicateCampaignsModalOpen}>
          Duplicar
          <Copy size={16} color={theme.colors.font} />
        </S.DuplicateButton>

        <S.DropDown open={isEditSelectOpen} onOpenChange={isOpen => setIsEditSelectOpen(isOpen)}>
          <S.DropDownTrigger>
            <S.DropDownTriggerContent>Editar</S.DropDownTriggerContent>
            <ChevronDown size={14} color={theme.colors.font} />
          </S.DropDownTrigger>

          <EditSelectContent setIsEditSelectOpen={setIsEditSelectOpen} />
        </S.DropDown>

        <S.DropDown
          open={isCampaignsStatusDropdownOpen}
          onOpenChange={handleCampaignsStatusDropdownOpen}
        >
          <S.DropDownTrigger>
            <S.DropDownTriggerContent>{getCampaignsStatusName()}</S.DropDownTriggerContent>
            <ChevronDown size={14} color={theme.colors.font} />
          </S.DropDownTrigger>

          <CampaignsSelectContent handleDropdownOpen={handleCampaignsStatusDropdownOpen} />
        </S.DropDown>

        {/* <S.DropDown>
          <S.DropDownTrigger>
            <S.DropDownTriggerContent>Todas Fontes</S.DropDownTriggerContent>
            <ChevronDown size={14} color={theme.colors.font} />
          </S.DropDownTrigger>

          <MarketingSelectContent />
        </S.DropDown> */}

        <S.DropDown>
          <S.DropDownTrigger>
            <S.DropDownTriggerContent>
              Colunas
              <Column size={14} color={theme.colors.font} />
            </S.DropDownTriggerContent>
            <ChevronDown size={14} color={theme.colors.font} />
          </S.DropDownTrigger>

          <ColumnSelectContent />
        </S.DropDown>

        {Boolean(!filterData.length) && (
          <S.FilterButton
            data={filterData}
            config={ADS_MANAGER_FILTER_CONFIG}
            addNewData={handleNewData}
          />
        )}
      </S.ActionsWrapper>
    </S.Wrapper>
  );
};

export default Header;
