import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import FormBase from '@components/common/core/Inputs/Form';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';

export const ContentWrapper = styled.div``;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 22px;
  gap: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
  margin-bottom: 40px;
`;

export const Heading = styled(HeadingBase)``;

export const Form = styled(FormBase)``;

export const FunctionalityDescriptionWrapper = styled.div`
  margin-bottom: 32px;
`;

export const FunctionalityDescriptionText = styled(TextBase)``;

export const FunctionalityDescriptionHeading = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)``;

export const TutorialWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TutotialTitle = styled(TextBase)`
  margin-bottom: 12px;
`;

export const TutotialStep = styled(TextBase)`
  margin-left: 8px;
`;

export const WebhookURLWrapper = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background[2]};
  border-radius: 5px;
  height: 42px;
  width: 100%;
  padding: 10px 12px;
  gap: 10px;
  border: unset;
  cursor: pointer;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const WebhookURL = styled(TextBase)`
  max-width: 434px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const IconWrapper = styled.div`
  min-height: 18px;
  min-width: 18px;
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.green.default};
  cursor: pointer;
`;

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 8px 0 18px 32px;
  gap: 8px;
`;

export const StepWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const StepBullet = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[1]};
`;

export const StepText = styled(TextBase)``;
