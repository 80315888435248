import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <Skeleton width="100%" height={24} />
      </S.HeaderWrapper>

      <S.CnpjAndCompanyNameWrapper>
        <S.CnpjInputWrapper>
          <S.LabelWrapper>
            <Skeleton width={42} height={19} />
          </S.LabelWrapper>
          <Skeleton width="100%" height={42} />
        </S.CnpjInputWrapper>

        <S.CompanyNameWrapper>
          <S.LabelWrapper>
            <Skeleton width={52} height={19} />
          </S.LabelWrapper>
          <Skeleton width="100%" height={42} />
        </S.CompanyNameWrapper>
      </S.CnpjAndCompanyNameWrapper>

      <S.PhoneAndEmailWrapper>
        <S.PhoneWrapper>
          <S.LabelWrapper>
            <Skeleton width={128} height={19} />
          </S.LabelWrapper>
          <Skeleton width="100%" height={42} />
        </S.PhoneWrapper>

        <S.EmailWrapper>
          <S.LabelWrapper>
            <Skeleton width={42} height={19} />
          </S.LabelWrapper>
          <Skeleton width="100%" height={42} />
        </S.EmailWrapper>
      </S.PhoneAndEmailWrapper>
    </S.Wrapper>
  );
};

export default SkeletonLoading;
