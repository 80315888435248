import styled from 'styled-components/macro';

import TextBase from '../../DataDisplay/Text';

interface IFacebookButtonProps {
  width?: number;
}

export const Text = styled(TextBase)`
  font-weight: 500;
  color: #fff;
  margin-top: 4px;
`;

export const FacebookLoginButton = styled.button<IFacebookButtonProps>`
  width: 100%;
  max-width: ${({ width }) => width}px;
  height: 40px;
  border-radius: 5px;
  background-color: #1877f2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
  border: unset;
  padding: unset;
  cursor: pointer;
`;

export const FacebookIcon = styled.img`
  width: 100%;
  max-width: 22px;
`;
