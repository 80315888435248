import { Check, Plus } from '@profitfy/pakkun-icons';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { useEcommerce } from '@helpers/hooks/pages/onboarding/useEcommerce';

import { ECOMMERCE_PROVIDERS } from '@constants/common/integrations';

import SideModal from './SideModal';

import * as S from './styles';

const Provider: React.FC = () => {
  const theme = useTheme();
  const { selectedProvider } = useEcommerce();

  const [isSideModalOpen, setIsSideModalOpen] = React.useState<boolean>(false);

  const handleSideModalOpen = React.useCallback(() => setIsSideModalOpen(!isSideModalOpen), [
    isSideModalOpen,
  ]);

  const getProviderIcon = React.useCallback(provider => {
    const foundProvider = ECOMMERCE_PROVIDERS.find(
      integration => integration.identifier === provider,
    );

    if (foundProvider) {
      const Icon = foundProvider.icon;

      return <Icon size={20} />;
    }

    return <></>;
  }, []);

  const getProviderName = React.useCallback(provider => {
    const foundProvider = ECOMMERCE_PROVIDERS.find(
      integration => integration.identifier === provider,
    );

    if (foundProvider) return foundProvider.name;

    return '';
  }, []);

  return (
    <>
      <S.Wrapper>
        <S.CardWrapper onClick={handleSideModalOpen}>
          <S.Header>
            <S.IconWrapper>
              <Plus color={theme.colors.gray[1]} size={9} />
            </S.IconWrapper>

            <S.DescriptionText>
              Selecionar plataforma de <strong>E-commerce</strong>
            </S.DescriptionText>
          </S.Header>

          {selectedProvider && (
            <>
              <S.HorizontalRule />

              <S.IntegrationWrapper>
                <S.IntegrationName>
                  {getProviderIcon(selectedProvider)}

                  <S.Text>{getProviderName(selectedProvider)}</S.Text>
                </S.IntegrationName>

                <Check size={20} outline />
              </S.IntegrationWrapper>
            </>
          )}
        </S.CardWrapper>

        <S.ExampleText>Ex: Shopify, Yampi, etc.</S.ExampleText>
      </S.Wrapper>

      <SideModal isOpen={isSideModalOpen} toggle={handleSideModalOpen} />
    </>
  );
};

export default Provider;
