import React from 'react';

import { Taboola } from '@profitfy/pakkun-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { EButtonTextColor } from '@domain/enums/components/EButton';
import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { IParams } from '@domain/interfaces/IParams';
import { IMarketingContentProps } from '@domain/interfaces/dashboard/IntegrationCenter/Marketing/IMarketingContent';
import { useTaboolaCredentials } from '@helpers/hooks/common/integrations/marketing/taboola/useTaboolaCredentials';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useConfig } from '@helpers/hooks/useConfig';
import { TaboolaAdAccountsProvider } from '@helpers/hooks/common/integrations/marketing/taboola/useTaboolaAdAccounts';
import { taboolaSchema } from '@helpers/validators/onboarding/dropshipping/taboola';

import NextStep from '@components/Onboarding/NextStep';
import Text from '@components/common/core/DataDisplay/Text';
import PageError from '@components/common/core/Utils/PageError';
import TutorialIntegration from './TutorialIntegration';
import Header from '../Header';
import AdAccountsTable from '../Table';

import { TableSkeletonLoading } from '../SkeletonLoading';

import * as S from './styles';

const IntegrateForm: React.FC<IMarketingContentProps> = ({ callback }) => {
  const { storeAliasId } = useParams<IParams>();

  const { analytics } = useConfig();

  const {
    adSenseCredentials,
    isValidatingTaboolaCredentialsData,
    isTaboolaCredentialsDataError,
    mutate,
    handleLogin,
  } = useTaboolaCredentials();

  const { currentSynchronizations } = useSynchronization();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(taboolaSchema) });

  const [clientIdValue, setClientIdValue] = React.useState<string>('');
  const [clientSecretValue, setClientSecretValue] = React.useState<string>('');

  const clientIdInputRegister = register('client_id');
  const clientSecretInputRegister = register('client_secret');

  const onClientIdChange = React.useCallback(
    event => {
      clientIdInputRegister.onChange(event);

      setClientIdValue(event.target.value);
    },
    [clientIdInputRegister],
  );

  const onClientSecretChange = React.useCallback(
    event => {
      clientSecretInputRegister.onChange(event);

      setClientSecretValue(event.target.value);
    },
    [clientSecretInputRegister],
  );

  const onSubmit = React.useCallback(
    async formData => {
      await handleLogin(formData);
    },
    [handleLogin],
  );

  const handleNextStepButtonClick = React.useCallback(() => {
    callback();

    analytics?.track(
      'Onboarding Marketing Integration Done',
      {
        provider: EAdSenseProvider.TABOOLA,
      },
      { context: { groupId: storeAliasId } },
    );
  }, [analytics, storeAliasId, callback]);

  const isSyncing = Boolean(
    currentSynchronizations?.synchronizations.find(synchronization => {
      if (
        synchronization.type === ESynchronizationType.AD_SENSE_ACCOUNTS ||
        synchronization.type === ESynchronizationType.AD_SENSE_CAMPAIGNS
      ) {
        return true;
      }

      return false;
    }),
  );

  const isNextStepButtonDisabled =
    (!clientIdValue && errors.client_id) || (!clientSecretValue && errors.client_secret);

  if (isTaboolaCredentialsDataError && !isValidatingTaboolaCredentialsData) {
    return (
      <S.ErrorWrapper>
        <PageError mutate={mutate} />
      </S.ErrorWrapper>
    );
  }

  return (
    <>
      {Boolean(adSenseCredentials.length) && (
        <S.HeaderAndTableContainer>
          <Header />

          {isSyncing && <TableSkeletonLoading />}

          {!isSyncing && (
            <>
              {adSenseCredentials.map((adSenseCredential, index) => (
                <TaboolaAdAccountsProvider
                  adSenseCredentialAliasId={adSenseCredential.alias_id}
                  key={adSenseCredential.alias_id}
                >
                  <AdAccountsTable
                    openFirstProfile={Boolean(index === 0)}
                    adSenseCredential={adSenseCredential}
                  />
                </TaboolaAdAccountsProvider>
              ))}
            </>
          )}
        </S.HeaderAndTableContainer>
      )}

      {Boolean(!adSenseCredentials.length) && (
        <S.ContentWrapper>
          <S.Form onSubmit={handleSubmit(onSubmit)}>
            <S.Heading>Integração com Fonte de trafego</S.Heading>

            <S.IconAndTitleWrapper>
              <Taboola size={40} />
              <S.FormTitle fontWeight={EHeadingWeight.REGULAR}>Integração com Taboola</S.FormTitle>
            </S.IconAndTitleWrapper>

            <S.InputGroup>
              <S.Label htmlFor="client_id">Client ID</S.Label>
              <S.Input
                {...clientIdInputRegister}
                isError={errors.client_id}
                value={clientIdValue}
                onChange={onClientIdChange}
                type="text"
                placeholder="Client ID Taboola"
              />
              {errors.client_id && <Text isErrorFeedback>{errors.client_id.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label htmlFor="client_secret">Client Secret</S.Label>
              <S.Input
                {...clientSecretInputRegister}
                isError={errors.client_secret}
                value={clientSecretValue}
                onChange={onClientSecretChange}
                type="text"
                placeholder="Client Secret Taboola"
              />
              {errors.client_secret && <Text isErrorFeedback>{errors.client_secret.message}</Text>}
            </S.InputGroup>

            <NextStep type="submit" disabled={isNextStepButtonDisabled}>
              Próximo passo
            </NextStep>
          </S.Form>

          <TutorialIntegration />
        </S.ContentWrapper>
      )}

      {Boolean(adSenseCredentials.length) && (
        <NextStep
          onClick={handleNextStepButtonClick}
          textColor={EButtonTextColor.DARK}
          disabled={isSyncing}
          isLoading={isSyncing}
        >
          Próximo Passo
        </NextStep>
      )}
    </>
  );
};

export default IntegrateForm;
