import React from 'react';
import { LineChart } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { ICogs } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IAdSenseCampaign';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import { numberFormatter } from '@helpers/masks';

import BlurredContent from './BlurredContent';

import * as S from './styles';

const Cogs: React.FC<ICampaignProps> = ({ data, forecastData, rowData }) => {
  const cogsData: ICogs = data;

  const { store } = useStoreConfig();
  const { isDemoEnvironment } = useConfig();
  const theme = useTheme();

  const isContentBlurred =
    (!store.is_utm_onboarding_finished || !rowData.has_at_least_one_ads_with_utm_synchronized) &&
    !isDemoEnvironment &&
    !rowData.has_all_ads_with_utm_synchronized;

  return (
    <S.Wrapper>
      {isContentBlurred ? (
        <BlurredContent />
      ) : (
        <>
          <S.NetRevenueValue>{`(-) R$ ${numberFormatter(cogsData.amount, 2)}`}</S.NetRevenueValue>

          {Boolean(forecastData) && (
            <S.ForecastText>
              <LineChart outline size={12} color={theme.colors.gray[2]} />
              {`(-) R$ ${numberFormatter(forecastData.amount, 2)}`}
            </S.ForecastText>
          )}
        </>
      )}
    </S.Wrapper>
  );
};

export default Cogs;
