import React from 'react';
import { Store } from '@profitfy/pakkun-icons';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ETextSize } from '@domain/enums/components/EText';

import { useMarketingIntegration } from '@helpers/hooks/pages/dashboard/adsManager/onboarding/useMarketingIntegration';

import { ADS_PROVIDERS } from '@constants/common/integrations';

import Save from './Save';

import * as S from './styles';

const Sidemodal: React.FC = () => {
  const {
    isSideModalOpen,
    handleSideModalOpen,
    handleSelectedIntegrations,
    selectedIntegrations,
  } = useMarketingIntegration();

  const [integrations, setIntegrations] = React.useState<typeof ADS_PROVIDERS>([]);

  const onSave = React.useCallback(() => {
    handleSelectedIntegrations(integrations);

    handleSideModalOpen();
  }, [handleSelectedIntegrations, handleSideModalOpen, integrations]);

  const isDefaultChecked = React.useCallback(
    integration => {
      const foundIntegration = selectedIntegrations.find(
        selectedIntegration => selectedIntegration.id === integration.id,
      );

      if (foundIntegration) return true;

      return false;
    },
    [selectedIntegrations],
  );

  const onChange = React.useCallback(
    event => {
      if (event && !event.target) {
        if (event.checked) {
          const foundMarketing = ADS_PROVIDERS.find(integration => integration.id === event.value);

          if (foundMarketing) setIntegrations(previousState => [...previousState, foundMarketing]);
        } else {
          const filteredMarketing = integrations.filter(
            integration => integration.id !== event.value,
          );

          setIntegrations(filteredMarketing);
        }
      }
    },
    [integrations],
  );

  React.useEffect(() => {
    if (selectedIntegrations.length) {
      setIntegrations(selectedIntegrations);
    }
  }, [selectedIntegrations]);

  return (
    <S.Sidemodal isOpen={isSideModalOpen} toggle={handleSideModalOpen}>
      <S.Header>
        <Store size={24} />
        <S.Title fontWeight={EHeadingWeight.REGULAR} type={EHeadingSize.H4}>
          Selecionar plataformas de marketing
        </S.Title>
      </S.Header>

      <S.Body>
        <S.InstructionTitle fontWeight={EHeadingWeight.REGULAR} type={EHeadingSize.H5}>
          Instruções
        </S.InstructionTitle>

        <S.DescriptionText size={ETextSize.B5}>
          Para integrar com sua plataforma de marketing,
          <strong> primeiro você irá selecioná-la. </strong>
          Logo
          <strong> depois de ter escolhido todas as plataformas </strong>
          que você irá utilizar, você irá
          <strong> configurá-las com seus dados.</strong>
        </S.DescriptionText>

        <S.IntegrationsWrapper>
          {ADS_PROVIDERS.map(integration => {
            const Icon = integration.icon;

            return (
              <S.InputGroup key={integration.id}>
                <S.CheckboxWrapper htmlFor={integration.id}>
                  <S.CheckboxTextWrapper>
                    <Icon size={24} />
                    <S.CheckboxText>{integration.name}</S.CheckboxText>
                  </S.CheckboxTextWrapper>
                </S.CheckboxWrapper>

                <S.Checkbox
                  value={integration.id}
                  defaultChecked={isDefaultChecked(integration)}
                  id={integration.id}
                  onChange={onChange}
                />
              </S.InputGroup>
            );
          })}
        </S.IntegrationsWrapper>
      </S.Body>

      <Save onCancel={handleSideModalOpen} onSave={onSave} />
    </S.Sidemodal>
  );
};

export default Sidemodal;
