import React from 'react';

import { EUserStoreRole } from '@domain/enums/common/userStore/EUserStoreRole';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import { ScriptProvider } from '@helpers/hooks/pages/dashboard/adsManager/onboarding/useScript';
import { ScriptAndUtmsProvider } from '@helpers/hooks/pages/dashboard/adsManager/onboarding/useScriptAndUtms';

import NoAccess from '@components/common/core/Utils/NoAccess';
import Wrapper from '@components/Dashboard/AdsManager/UtmOnboarding/ScriptAndUtms/Wrapper';

import * as S from './styles';

const ScriptAndUtms: React.FC = () => {
  const { accessManagementData } = useStoreConfig();
  const { isInternalTeam } = useConfig();

  const isStoreOwner = accessManagementData?.role === EUserStoreRole.OWNER;
  const hasAccessToPage =
    accessManagementData?.group?.has_ads_manager_full_write_access ||
    accessManagementData?.group?.has_ads_manager_full_read_access;

  if (!isStoreOwner && !hasAccessToPage && !isInternalTeam) {
    return <NoAccess />;
  }

  return (
    <S.Wrapper>
      <ScriptAndUtmsProvider>
        <ScriptProvider>
          <Wrapper />
        </ScriptProvider>
      </ScriptAndUtmsProvider>
    </S.Wrapper>
  );
};

export default ScriptAndUtms;
