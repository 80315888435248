import React from 'react';

import { Zouti as ZoutiIcon } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { EGateway } from '@domain/enums/common/EGateway';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { EZoutiType } from '@domain/enums/common/integrations/gateways/EZouti';
import { ISideModalGatewayContent } from '@domain/interfaces/dashboard/IntegrationCenter/SideModalContent/ISideModalContent';
import { IParams } from '@domain/interfaces/IParams';
import { IDropshippingProviders } from '@domain/interfaces/common/integration/IDropshippingProvider';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useDate } from '@helpers/hooks/useDate';

import { zoutiSchema } from '@helpers/validators/dashboard/integrationCenter/zouti';

import { getStoreProvider } from '@helpers/utils/common/integration';
import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { GATEWAYS } from '@constants/common/integrations';

import zoutiCredentialService from '@services/pages/dashboard/integrationCenter/gateway/zouti';

import Form from '@components/common/core/Inputs/Form';
import InputGroup from '@components/common/core/Inputs/InputGroup';
import Input from '@components/common/core/Inputs/TextField';
import Text from '@components/common/core/DataDisplay/Text';
import Save from '@components/Dashboard/IntegrationCenter/SideModalContent/Save';

import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const ZoutiPay: React.FC<ISideModalGatewayContent> = ({
  syncOnCreate,
  isFeedSideModal,
  toggle,
}) => {
  const foundIntegration = GATEWAYS.find(
    integration => integration.identifier === EGateway.ZOUTI,
  ) as typeof GATEWAYS[number];

  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { format, subDays, utcToZonedTime } = useDate();
  const { addSynchronization } = useSynchronization();
  const { mutateStore: mutateIntegrations, storeIntegrations } = useStoreConfig();

  const [isLoadingSubmittedForm, setIsLoadingSubmittedForm] = React.useState<boolean>(false);
  const [isLoadingDeleteCredential, setIsLoadingDeleteCredential] = React.useState<boolean>(false);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(zoutiSchema),
  });

  const [secretKey, publicKey] = getValues(['secret_key', 'public_key']);

  watch();

  const {
    zoutiCredential,
    mutate: mutateZoutiCredential,
    isLoading: isLoadingZoutiCredential,
    isValidating: isValidatingZoutiCredential,
  } = zoutiCredentialService.getCredential({
    storeAliasId,
  });

  const syncGateway = React.useCallback(
    (storeProvider: IDropshippingProviders, params: string) => {
      addSynchronization({
        name: storeProvider.name,
        type: ESynchronizationType.STORE_GATEWAY,
        label: foundIntegration.name,
        dependencies: [ESynchronizationType.STORE_PRODUCT, ESynchronizationType.STORE_ORDER],
        storeAliasId,
        config: {
          isOnboardingSynchronization: false,
          showNotification: true,
        },
        request: storeProvider.generateGatewaySyncRequest(
          foundIntegration.synchronizationName,
          storeAliasId,
          params,
        ),
      });
    },
    [addSynchronization, foundIntegration, storeAliasId],
  );

  const onSubmit = React.useCallback(
    async formData => {
      setIsLoadingSubmittedForm(true);

      const lastThirtyDays = utcToZonedTime(subDays(new Date(), 29));

      const storeProvider = getStoreProvider(storeIntegrations);

      const hasGatewaySynchronization = storeProvider?.gateways.includes(EGateway.ZOUTI);

      try {
        const params = `?start_date=${format(lastThirtyDays, 'yyyy-MM-dd')}&end_date=${format(
          utcToZonedTime(new Date()),
          'yyyy-MM-dd',
        )}`;

        if (zoutiCredential?.id) {
          await zoutiCredentialService.updateCredential({
            storeAliasId,
            data: { ...formData, types: [EZoutiType.GATEWAY] },
          });

          if (storeProvider && hasGatewaySynchronization) {
            syncGateway(storeProvider, params);
          }
        } else {
          await zoutiCredentialService.createCredential({
            storeAliasId,
            data: { ...formData, types: [EZoutiType.GATEWAY] },
          });

          if (syncOnCreate && storeProvider && hasGatewaySynchronization) {
            syncGateway(storeProvider, params);
          }
        }

        await Promise.all([mutateZoutiCredential(), mutateIntegrations()]);

        toast.success('Credencial salva com sucesso.');

        setIsLoadingSubmittedForm(false);

        if (toggle) {
          toggle();
        }
      } catch (error: any) {
        toast.error(getTranslatedIntegrationsError(error));
        setIsLoadingSubmittedForm(false);
      }
    },
    [
      mutateZoutiCredential,
      mutateIntegrations,
      storeAliasId,
      zoutiCredential,
      toast,
      format,
      storeIntegrations,
      subDays,
      syncGateway,
      utcToZonedTime,
      syncOnCreate,
      toggle,
    ],
  );

  const handleDeleteCredential = React.useCallback(async () => {
    setIsLoadingDeleteCredential(true);

    try {
      await zoutiCredentialService.deleteCredential({
        storeAliasId,
      });

      await Promise.all([mutateZoutiCredential(), mutateIntegrations()]);

      toast.success('Credencial desativada com sucesso!');
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoadingDeleteCredential(false);
    }
  }, [storeAliasId, mutateZoutiCredential, toast, mutateIntegrations]);

  const hasInputsValue = secretKey && publicKey;

  if (isLoadingZoutiCredential || isValidatingZoutiCredential) {
    return <SkeletonLoading />;
  }

  return (
    <S.ContentWrapper>
      <S.Header>
        <ZoutiIcon size={52} />

        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com Zouti
        </S.Heading>
      </S.Header>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>

        <S.FunctionalityDescriptionText>
          A integração com seu Gateway de pagamento serve para
          <strong> puxarmos seu faturamento </strong>
          aumentando a<strong> exatidão na análise de seus dados.</strong>
        </S.FunctionalityDescriptionText>

        <br />
        <br />

        <S.FunctionalityDescriptionText>
          Saiba mais sobre a Zouti{' '}
          <S.Link href="https://zouti.com.br" target="_blank">
            clicando aqui.
          </S.Link>
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <S.TutorialWrapper>
        <S.TutorialHeading type={EHeadingSize.H5}>Tutorial de integração</S.TutorialHeading>
        <S.TutorialList>
          <S.TutorialStep>Acesse sua conta Zouti;</S.TutorialStep>

          <S.TutorialStep>
            Na barra lateral esquerda, clique em <strong>&quot;configurações&quot;</strong>;
          </S.TutorialStep>

          <S.TutorialStep>
            Clique em <strong>&quot;Credenciais de API&quot;</strong>;
          </S.TutorialStep>

          <S.TutorialStep>
            Copie os campos <strong>&quot;Chave Secreta&quot;</strong> e
            <strong>&quot;Chave Pública&quot;</strong>;
          </S.TutorialStep>

          <S.TutorialStep>Cole nos respectivos campos abaixo.</S.TutorialStep>
        </S.TutorialList>
      </S.TutorialWrapper>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup>
          <S.Label htmlFor="zouti-secret-key">Chave secreta</S.Label>

          <Input
            {...register('secret_key')}
            type="text"
            value={zoutiCredential?.secret_key}
            placeholder="Chave secreta da Zouti"
            id="zouti-secret-key"
            isError={errors.secret_key}
          />
          {errors.secret_key && <Text isErrorFeedback>{errors.secret_key.message}</Text>}
        </InputGroup>

        <InputGroup>
          <S.Label htmlFor="zouti-public-key">Chave pública</S.Label>

          <Input
            {...register('public_key')}
            type="text"
            value={zoutiCredential?.public_key}
            placeholder="Chave pública da Zouti"
            id="zouti-public-key"
            isError={errors.public_key}
          />
          {errors.public_key && <Text isErrorFeedback>{errors.public_key.message}</Text>}
        </InputGroup>

        <Save
          submitText={isFeedSideModal ? 'Reprocessar pedidos' : 'Salvar alterações'}
          cancelText="Cancelar"
          onDelete={isFeedSideModal ? undefined : handleDeleteCredential}
          hasCredentials={Boolean(zoutiCredential?.id)}
          isSubmitting={isLoadingSubmittedForm}
          isDeleting={isLoadingDeleteCredential}
          isDeleteButtonDisabled={isLoadingSubmittedForm || isLoadingDeleteCredential}
          isSaveButtonDisabled={
            isLoadingSubmittedForm ||
            isLoadingDeleteCredential ||
            (!zoutiCredential?.id && !hasInputsValue)
          }
        />
      </Form>
    </S.ContentWrapper>
  );
};

export default ZoutiPay;
