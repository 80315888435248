import React from 'react';
import ReactTooltip from 'react-tooltip';

import Popover from '@components/common/core/Utils/Popover';
import { ILabelProps } from '@domain/interfaces/dashboard/CustomValuesCategories/ILabel';
import DeleteModal from '@components/common/core/Utils/DeleteModal';
import { useCustomValuesCategories } from '@helpers/hooks/pages/dashboard/customValues/useCustomValuesCategories';

import * as S from './styles';

const Label: React.FC<ILabelProps> = ({
  isHovering,
  handleIsEditing,
  name,
  aliasId,
  handleIsChoosingColor,
  category,
}) => {
  const {
    isDeletingCustomValuesCategory,
    deleteCustomValuesCategory,
  } = useCustomValuesCategories();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);

  const handleDeleteModalOpen = React.useCallback(() => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  }, [isDeleteModalOpen]);

  const onDelete = React.useCallback(async () => {
    await deleteCustomValuesCategory(category.alias_id);
  }, [category, deleteCustomValuesCategory]);

  const handleEditColor = React.useCallback(() => {
    ReactTooltip.hide();
    handleIsChoosingColor(true);
  }, [handleIsChoosingColor]);

  return (
    <S.LabelWrapper>
      <S.CategoryName>{name}</S.CategoryName>

      <S.MoreOptions
        data-tip
        data-for={`category-${aliasId}`}
        data-event="click"
        isHovering={isHovering}
      >
        <S.Bullet />
        <S.Bullet />
        <S.Bullet />
      </S.MoreOptions>

      <Popover width={156} id={`category-${aliasId}`} place="bottom">
        <S.ActionsWrapper>
          <S.ActionButton name="edit" onClick={handleIsEditing}>
            Editar
          </S.ActionButton>
          <S.ActionButton name="edit-color" onClick={handleEditColor}>
            Editar Cor
          </S.ActionButton>
          <S.ActionButton name="delete" onClick={handleDeleteModalOpen}>
            Deletar
          </S.ActionButton>
        </S.ActionsWrapper>
      </Popover>

      <DeleteModal
        isOpen={isDeleteModalOpen}
        content="Deseja excluir a categoria? Essa ação é irreversível."
        isLoading={isDeletingCustomValuesCategory}
        onDelete={onDelete}
        title="Excluir categoria"
        toggle={handleDeleteModalOpen}
      />
    </S.LabelWrapper>
  );
};

export default Label;
