import React from 'react';

import { ORDERS_FILTER_CONFIG } from '@helpers/utils/filters/ordersFilter';
import { useOrders } from '@helpers/hooks/pages/dashboard/orders/useOrders';
import { useConfig } from '@helpers/hooks/useConfig';

import Header from '@components/Dashboard/Orders/Header';
import Body from '@components/Dashboard/Orders/Body';
import PageError from '@components/common/core/Utils/PageError';
import Filter from '@components/common/core/Utils/Filter';
import InstantHelp from '@components/common/core/Utils/InstantHelp';

import * as S from './styles';

const Orders: React.FC = () => {
  const {
    mutate,
    isOrdersProviderError,
    filterData,
    handleFilterData,
    isValidatingOrdersProvider,
  } = useOrders();

  const { analytics, selectedStore, user } = useConfig();

  React.useEffect(() => {
    analytics?.track(
      'Orders Page Viewed',
      {
        email: user?.email,
      },
      { context: { groupId: selectedStore?.alias_id } },
    );
  }, [selectedStore, analytics, user]);

  const isError = isOrdersProviderError && !isValidatingOrdersProvider;

  if (isError) return <PageError mutate={mutate} />;

  return (
    <S.Container>
      <Header />

      {Boolean(filterData.length) && (
        <Filter
          config={ORDERS_FILTER_CONFIG}
          handleFilterData={handleFilterData}
          filterData={filterData}
        />
      )}

      <Body />

      <InstantHelp
        name="orders"
        title="Pedidos"
        articleLink="https://ajuda.profitfy.me/pt-BR/articles/6461452-como-funciona-a-aba-de-pedidos"
      />
    </S.Container>
  );
};

export default Orders;
