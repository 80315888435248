import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const RoasLabel = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
  line-height: 120%;
  white-space: nowrap;
`;

export const RoasAmount = styled(TextBase)`
  line-height: 120%;
  white-space: nowrap;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 4px;
  overflow: hidden;
`;
