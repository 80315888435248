import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  background: linear-gradient(180deg, #0f1116 14.36%, rgba(15, 17, 22, 0) 100%);
  min-height: 100vh;
  width: 100%;
`;

export const Container = styled.div`
  position: relative;
  background-color: #000;
  min-height: 100vh;
  width: 100%;
`;
