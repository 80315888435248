import React from 'react';
import { Search } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { useCreateOrder } from '@helpers/hooks/pages/dashboard/orders/useCreateOrder';
import { useDebounce } from '@helpers/hooks/useDebounce';

import VariantsDropdown from './ProductsDropdown';

import * as S from './styles';

const VariantInput: React.FC = () => {
  const theme = useTheme();

  const {
    isProductsComponentOpen,
    onInputFocus,
    inputComponentRef,
    handleIsHoveringInput,
    isHoveringInput,
    closeProductsComponent,
    handleSearchName,
    handleIsHoveringProductsComponent,
    isHoveringProductsComponent,
  } = useCreateOrder();

  const [searchVariantName, setSearchVariantName] = React.useState<string>('');

  const debouncedVariantName = useDebounce(searchVariantName, 700);

  const onVariantNameInputChange = React.useCallback(event => {
    setSearchVariantName(event.target.value);
  }, []);

  const onVariantsComponentBlur = React.useCallback(
    event => {
      const isBlurringOutside = !event.currentTarget.contains(event.relatedTarget);

      if (isBlurringOutside && !isHoveringInput) {
        closeProductsComponent();
      }
    },
    [closeProductsComponent, isHoveringInput],
  );

  const onInputBlur = React.useCallback(() => {
    if (!isHoveringInput && isProductsComponentOpen && !isHoveringProductsComponent) {
      closeProductsComponent();
    }
  }, [
    isProductsComponentOpen,
    closeProductsComponent,
    isHoveringInput,
    isHoveringProductsComponent,
  ]);

  React.useEffect(() => {
    handleSearchName(debouncedVariantName);
  }, [debouncedVariantName, handleSearchName]);

  return (
    <S.Wrapper>
      <S.Popover open={isProductsComponentOpen}>
        <S.Popover.Trigger onBlur={onInputBlur}>
          <S.Input
            prepend={Search}
            placeholder="Pesquisar produtos"
            type="text"
            onFocus={onInputFocus}
            parentRef={inputComponentRef}
            onMouseEnter={() => handleIsHoveringInput(true)}
            onMouseLeave={() => handleIsHoveringInput(false)}
            onChange={onVariantNameInputChange}
          />
        </S.Popover.Trigger>

        <S.Popover.Content
          asChild
          side="bottom"
          style={{ backgroundColor: theme.colors.black.dark }}
          onBlur={onVariantsComponentBlur}
          onMouseEnter={() => handleIsHoveringProductsComponent(true)}
          onMouseLeave={() => handleIsHoveringProductsComponent(false)}
        >
          <S.VariantsWrapper>
            <VariantsDropdown />
          </S.VariantsWrapper>
        </S.Popover.Content>
      </S.Popover>
    </S.Wrapper>
  );
};

export default VariantInput;
