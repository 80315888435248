import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'react-flow-renderer';

import { EUserStoreRole } from '@domain/enums/common/userStore/EUserStoreRole';
import { EStatus } from '@domain/enums/subscription/ESubscription';
import { IParams } from '@domain/interfaces/IParams';

import { AutomationProvider } from '@helpers/hooks/pages/dashboard/automation/useAutomation';
import { WhatsappInstanceProvider } from '@helpers/hooks/pages/dashboard/whatsappInstance/useWhatsappInstance';
import { StepModalProvider } from '@helpers/hooks/components/useStepModal';
import { AutomationsManagerProvider } from '@helpers/hooks/pages/dashboard/automations/useAutomationsManager';

import { CanvasMessageSettingsProvider } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessageSettings';
import { CanvasMessageContentProvider } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessageContent';
import { CanvasMessageProvider } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessage';
import { CanvasContentProvider } from '@helpers/hooks/pages/dashboard/automations/useCanvasContent';
import { AutomationsTemplateProvider } from '@helpers/hooks/pages/dashboard/automations/useAutomationsTemplate';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';

import Wrapper from '@components/Dashboard/Automations/Canvas';

import * as S from './styles';

const Canvas: React.FC = () => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { accessManagementData } = useStoreConfig();
  const { hasAllPermissions, subscription } = useStoreSubscription();

  const isStoreOwner = accessManagementData?.role === EUserStoreRole.OWNER;
  const hasAccessToPage = accessManagementData?.group?.has_workflow_full_write_access;
  const hasPermission =
    hasAllPermissions || subscription?.status === EStatus.TRIAL_STARTED || !subscription;

  if (!isStoreOwner && !hasAccessToPage && !hasPermission) {
    history.push(`/${storeAliasId}/dashboard/marketing/automations`);

    return <></>;
  }

  return (
    <ReactFlowProvider>
      <AutomationsTemplateProvider>
        <WhatsappInstanceProvider>
          <CanvasContentProvider>
            <CanvasMessageSettingsProvider>
              <CanvasMessageContentProvider>
                <CanvasMessageProvider>
                  <AutomationProvider>
                    <AutomationsManagerProvider>
                      <StepModalProvider>
                        <S.Wrapper>
                          <Wrapper />
                        </S.Wrapper>
                      </StepModalProvider>
                    </AutomationsManagerProvider>
                  </AutomationProvider>
                </CanvasMessageProvider>
              </CanvasMessageContentProvider>
            </CanvasMessageSettingsProvider>
          </CanvasContentProvider>
        </WhatsappInstanceProvider>
      </AutomationsTemplateProvider>
    </ReactFlowProvider>
  );
};

export default Canvas;
