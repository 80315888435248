import Text from '@components/common/core/DataDisplay/Text';
import { STORE_PROVIDERS } from '@constants/common/integrations';
import { useToast } from '@helpers/hooks/useToast';
import { deleteCredentialFormSchema } from '@helpers/validators/dashboard/adminPanel/deleteCredential';
import { yupResolver } from '@hookform/resolvers/yup';
import deleteCredentialService from '@services/pages/dashboard/adminPanel/deleteCredential';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as S from './styles';

const DeleteCredential: React.FC = () => {
  const { toast } = useToast();

  const integrations = STORE_PROVIDERS;

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(deleteCredentialFormSchema),
  });

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async formData => {
      setIsSubmitting(true);

      try {
        await deleteCredentialService.deleteCredential({
          store_alias_id: formData.store_alias_id,
          integration_type: formData.integrations,
        });

        toast.success('Credencial deletada com sucesso.');

        setIsSubmitting(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);

        setIsSubmitting(false);
      }
    },

    [toast],
  );

  return (
    <S.ContentWrapper>
      <S.Heading>Deletar credencial</S.Heading>

      <S.Content>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label htmlFor="store_alias_id">Store alias Id</S.Label>

            <S.Input
              {...register('store_alias_id')}
              type="text"
              placeholder="Insira o store alias id"
              id="store_alias_id"
            />

            {errors.store_alias_id && <Text isErrorFeedback>{errors.store_alias_id.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Integrações</S.Label>

            <S.Select {...register('integrations')}>
              {integrations.map(integration => {
                return (
                  <S.Option value={integration.id} key={integration.id}>
                    {integration.name}
                  </S.Option>
                );
              })}
            </S.Select>
          </S.InputGroup>

          <S.Button type="submit" isLoading={isSubmitting} disabled={isSubmitting}>
            Enviar
          </S.Button>
        </S.Form>
      </S.Content>
    </S.ContentWrapper>
  );
};

export default DeleteCredential;
