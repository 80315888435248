import styled from 'styled-components/macro';

import ButtonBase from '@components/common/core/Inputs/Button';

export const CancelButton = styled.button`
  height: 36px;
  width: 96px;
  background: unset;
  border: unset;
  padding: unset;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.gray[1]};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const SubmitButton = styled(ButtonBase)`
  height: 36px;
`;

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background-color: ${({ theme }) => theme.colors.background[2]};
`;
