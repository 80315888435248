import styled from 'styled-components/macro';

import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import TextBase from '@components/common/core/DataDisplay/Text';
import LabelBase from '@components/common/core/DataDisplay/Label';
import BadgeBase from '@components/common/core/DataDisplay/Badge';

export const StatusBadge = styled(BadgeBase)`
  white-space: nowrap;
`;

export const ProfileName = styled(TextBase)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Checkbox = styled(CheckboxBase)`
  pointer-events: none;
`;

export const ProfileLabel = styled(LabelBase)`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: unset;
  padding: 12px 16px;
  gap: 16px;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[4]};
  }
`;
