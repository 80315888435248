import styled, { css } from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.div`
  width: 100%;
  height: 132px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
`;

export const Timer = styled(TextBase)`
  line-height: 150%;
  color: #ffffff;
  font-size: 2.25rem;
  margin-bottom: 16px;
`;

export const Controls = styled.div<{ isRecordingFinished?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 36px;

  @media only screen and (max-width: 955px) {
    ${({ isRecordingFinished }) =>
      !isRecordingFinished &&
      css`
        gap: unset;
        justify-content: space-between;
      `}
  }
`;

export const StartRecordingButton = styled.button`
  min-width: 192px;
  height: 48px;
  border-radius: 25px;
  background-color: #59b578;
  padding: 6px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  color: ${({ theme }) => theme.colors.gray[8]};
  font-weight: 700;
  font-size: 1rem;
  line-height: 150%;
  border: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const FinishRecordingButton = styled.button`
  min-width: 192px;
  height: 48px;
  border-radius: 25px;
  background-color: #f05454;
  padding: 6px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  color: ${({ theme }) => theme.colors.gray[1]};
  font-weight: 700;
  font-size: 1rem;
  line-height: 150%;
  border: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const PauseButton = styled.button<{ isPaused?: boolean }>`
  width: 48px;
  height: 48px;
  min-width: 48px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.background[2]};
  border: unset;
  padding: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  ${({ isPaused, theme }) =>
    isPaused &&
    css`
      background: ${theme.colors.gray[3]};
    `}
`;

export const DeleteAudioButton = styled.button`
  width: 48px;
  height: 48px;
  min-width: 48px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.background[2]};
  border: unset;
  padding: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const SaveButton = styled.button`
  width: 48px;
  height: 48px;
  min-width: 48px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.green.default};
  border: unset;
  padding: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Audio = styled.audio``;
