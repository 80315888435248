import React from 'react';

import { EOrderType } from '@domain/enums/common/EOrder';
import { ICanceledOrderFormProps } from '@domain/interfaces/dashboard/Orders/IEditOrder';

import Text from '@components/common/core/DataDisplay/Text';
import Option from '@components/common/core/Inputs/Select/Option';

import * as S from './styles';

const CanceledEditForm: React.FC<ICanceledOrderFormProps> = ({ order, errors, register }) => {
  return (
    <>
      <S.InputGroup>
        <S.Label>Tipo do pedido</S.Label>

        <S.Select {...register('type')} name="type" defaultValue={order.type}>
          <Option disabled value="" />
          <Option value={EOrderType.CREDIT_CARD}>Cartão de Crédito</Option>
          <Option value={EOrderType.BOLETO}>Boleto</Option>
          <Option value={EOrderType.PIX}>Pix</Option>
        </S.Select>

        {errors.type && <Text isErrorFeedback>{errors.type.message}</Text>}
      </S.InputGroup>
    </>
  );
};

export default CanceledEditForm;
