import React from 'react';
import { useTheme } from 'styled-components';
import {
  ChartLineUp,
  CurrencyCircleDollar,
  CurrencyDollarSimple,
  Megaphone,
  Percent,
} from 'phosphor-react';

import { usePricingTool } from '@helpers/hooks/pages/dashboard/pricingTool/usePricingTool';

import { currencyFormatter, currencyToNumber } from '@helpers/masks';

import FixedCost from './FixedCost';
import RecommendedPrice from './RecommendedPrice';

import * as S from './styles';

const Calculator: React.FC = () => {
  const theme = useTheme();
  const { handleProductCost, handleMarketing, handleCustomMarkup } = usePricingTool();

  const onCogsChange = React.useCallback(
    event => {
      const formattedAmount = currencyFormatter(event.target.value);

      handleProductCost(currencyToNumber(formattedAmount));

      event.target.value = formattedAmount;
    },
    [handleProductCost],
  );

  const onMarketingAmountChange = React.useCallback(
    event => {
      const formattedAmount = currencyFormatter(event.target.value);

      handleMarketing(
        currencyToNumber(formattedAmount) ? currencyToNumber(formattedAmount) / 100 : 0,
      );

      event.target.value = formattedAmount;
    },
    [handleMarketing],
  );

  const onCustomMarkupChange = React.useCallback(
    event => {
      const formattedAmount = currencyFormatter(event.target.value);

      handleCustomMarkup(currencyToNumber(formattedAmount));

      event.target.value = formattedAmount;
    },
    [handleCustomMarkup],
  );

  return (
    <S.Container>
      <S.Group>
        <S.ProductCostCard>
          <S.InputGroup>
            <S.LabelWrapper>
              <CurrencyCircleDollar size={16} color={theme.colors.font} />
              <S.Label>Custo do produto</S.Label>
            </S.LabelWrapper>
            <S.Input
              key="productCost"
              name="productCost"
              prepend={() => <CurrencyDollarSimple size={20} color="#00F2A1" />}
              type="text"
              placeholder="0,00"
              onChange={onCogsChange}
            />
          </S.InputGroup>
        </S.ProductCostCard>

        <S.CostMarkupWrapper>
          <S.MktCostCard>
            <S.InputGroup>
              <S.LabelWrapper>
                <Megaphone size={16} color={theme.colors.font} />
                <S.Label>Custo do marketing</S.Label>
              </S.LabelWrapper>
              <S.InputMktCost
                key="mktCost"
                name="mktCost"
                prepend={() => <Percent size={20} color="#00F2A1" />}
                type="text"
                placeholder="20"
                onChange={onMarketingAmountChange}
              />
            </S.InputGroup>
          </S.MktCostCard>

          <S.CustomMarkupCard>
            <S.InputGroup>
              <S.LabelWrapper>
                <ChartLineUp size={16} color={theme.colors.font} />
                <S.Label>Markup customizado</S.Label>
              </S.LabelWrapper>
              <S.Input
                key="customMarkup"
                name="customMarkup"
                prepend={() => <Percent size={20} color="#00F2A1" />}
                type="text"
                placeholder="1"
                onChange={onCustomMarkupChange}
              />
            </S.InputGroup>
          </S.CustomMarkupCard>
        </S.CostMarkupWrapper>
      </S.Group>

      <S.Group>
        <FixedCost />

        <RecommendedPrice />
      </S.Group>
    </S.Container>
  );
};

export default Calculator;
