import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { useHistory, useParams } from 'react-router-dom';
import { IParams } from '@domain/interfaces/IParams';
import { EButtonVariant } from '@domain/enums/components/EButton';

import * as S from './styles';

const NoCustomValues: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const history = useHistory();

  const handleNewCustomValueButtonClick = React.useCallback(() => {
    const fromRef = 'from=no-custom-value-card-clicked';

    history.push(`/${storeAliasId}/dashboard/custom-values/out?${fromRef}`);
  }, [history, storeAliasId]);

  return (
    <S.Content>
      <S.Title fontWeight={EHeadingWeight.REGULAR} type={EHeadingSize.H6}>
        Valores adicionais do mês
      </S.Title>

      <S.ChartAndButtonWrapper>
        <S.ChartWrapper>
          <S.PieChart />

          <S.TextAndValueWrapper>
            <S.ChartText>Total</S.ChartText>

            <S.ChartValue>R$0,00</S.ChartValue>
          </S.TextAndValueWrapper>
        </S.ChartWrapper>

        <S.NewCustomValueButton
          onClick={handleNewCustomValueButtonClick}
          variant={EButtonVariant.SECONDARY_BUTTON}
        >
          Cadastrar valores adicionais
        </S.NewCustomValueButton>
      </S.ChartAndButtonWrapper>
    </S.Content>
  );
};

export default NoCustomValues;
