import React from 'react';

import { CircleSpinner } from 'react-spinners-kit';
import { TikTok as TikTokIcon } from '@profitfy/pakkun-icons';

import { useParams } from 'react-router-dom';
import { EHeadingSize } from '@domain/enums/components/EHeading';
import { EProgressBarStep } from '@domain/enums/onboarding/progressBar/ESteps';
import { EButtonTextColor, EButtonVariant } from '@domain/enums/components/EButton';
import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';
import { IMarketingContentProps } from '@domain/interfaces/dashboard/IntegrationCenter/Marketing/IMarketingContent';
import { useTikTokCrendentials } from '@helpers/hooks/common/integrations/marketing/tiktok/useTikTokCrendentials';
import { newWindow } from '@helpers/hooks/usePopup';
import { useTheme } from 'styled-components';
import { useToast } from '@helpers/hooks/useToast';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useConfig } from '@helpers/hooks/useConfig';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';
import { TikTokAdAccountsProvider } from '@helpers/hooks/common/integrations/marketing/tiktok/useTikTokAdAccounts';

import TikTokImg from '@assets/pages/Onboarding/dropshipping/marketing/tiktok.svg';
import tikTokCredentialService from '@services/pages/dashboard/adSense/tiktok/tiktokCredential';

import SideBar from '@components/Onboarding/SideBar';
import NextStep from '@components/Onboarding/NextStep';
import PageError from '@components/common/core/Utils/PageError';
import Header from '../Header';
import AdAccountsTable from '../Table';

import { PageSkeletonLoading, TableSkeletonLoading } from '../SkeletonLoading';

import * as S from './styles';

const Wrapper: React.FC<IMarketingContentProps> = ({ callback }) => {
  const theme = useTheme();

  const { toast } = useToast();
  const { analytics } = useConfig();
  const { isLoadingTrial } = useOnboarding();
  const { storeAliasId } = useParams<IParams>();
  const { currentSynchronizations } = useSynchronization();

  const {
    adSenseCredentials,
    isLoadingTikTokCredentialsData,
    isValidatingTikTokCredentialsData,
    isTikTokCredentialsDataError,
    isLoggingOut,
    isLoggingIn,
    mutate: mutateOnPageError,
    mutateAdSenseCredentials,
    syncAdSenseAccounts,
  } = useTikTokCrendentials();

  const [userWindow, setUserWindow] = React.useState<Window | undefined>(undefined);
  const [isIntegrating, setIsIntegrating] = React.useState<boolean>(false);

  const onAddNewProfileButtonClick = React.useCallback(async () => {
    setIsIntegrating(true);

    try {
      const { data: tiktokData } = await tikTokCredentialService.generateUrl({ storeAliasId });

      const window = newWindow(tiktokData?.auth_url, 'TikTok', 1280, 720);

      setUserWindow(window);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsIntegrating(false);
    }

    analytics?.track(
      'Onboarding Profile Button Clicked',
      {
        provider: EAdSenseProvider.TIKTOK,
      },
      { context: { groupId: storeAliasId } },
    );
  }, [analytics, storeAliasId, toast]);

  const handleNextStepButtonClick = React.useCallback(() => {
    callback();

    analytics?.track(
      'Onboarding Marketing Integration Done',
      {
        provider: EAdSenseProvider.TIKTOK,
      },
      { context: { groupId: storeAliasId } },
    );
  }, [analytics, storeAliasId, callback]);

  const isSyncing = Boolean(
    currentSynchronizations?.synchronizations.find(synchronization => {
      if (
        synchronization.type === ESynchronizationType.AD_SENSE_ACCOUNTS ||
        synchronization.type === ESynchronizationType.AD_SENSE_CAMPAIGNS
      ) {
        return true;
      }

      return false;
    }),
  );

  const mutate = React.useCallback(async () => {
    const { data: adSenseCredentialsResponse } = await mutateAdSenseCredentials();

    const lastCredentialAddedIndex = 0;

    const lastCredentialAddedAliasId =
      adSenseCredentialsResponse?.ad_sense_credentials[lastCredentialAddedIndex]?.alias_id;

    if (lastCredentialAddedAliasId) {
      syncAdSenseAccounts(lastCredentialAddedAliasId);
    }
  }, [mutateAdSenseCredentials, syncAdSenseAccounts]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (userWindow?.closed) {
        clearInterval(interval);
        mutate();
      }
    }, 1000);
  }, [userWindow, mutate]);

  const isLoading =
    isLoadingTikTokCredentialsData ||
    isValidatingTikTokCredentialsData ||
    isLoggingIn ||
    isLoggingOut;

  if (isLoading) {
    return (
      <S.Container>
        <SideBar
          onboardingStep={EProgressBarStep.INTEGRATIONS}
          linkToGoBack={`/onboarding/${storeAliasId}/dropshipping/integrations`}
        />

        <PageSkeletonLoading />
      </S.Container>
    );
  }

  if (isTikTokCredentialsDataError && !isValidatingTikTokCredentialsData) {
    return (
      <S.Container>
        <SideBar
          onboardingStep={EProgressBarStep.INTEGRATIONS}
          linkToGoBack={`/onboarding/${storeAliasId}/dropshipping/integrations`}
        />

        <S.ErrorWrapper>
          <PageError mutate={mutateOnPageError} />
        </S.ErrorWrapper>
      </S.Container>
    );
  }

  return (
    <S.Container>
      <SideBar
        onboardingStep={EProgressBarStep.INTEGRATIONS}
        linkToGoBack={`/onboarding/${storeAliasId}/dropshipping/integrations`}
      />

      {Boolean(adSenseCredentials.length) && (
        <S.HeaderAndTableContainer>
          <Header />

          {isSyncing && <TableSkeletonLoading />}

          {!isSyncing && (
            <>
              {adSenseCredentials.map((adSenseCredential, index) => (
                <TikTokAdAccountsProvider
                  adSenseCredentialAliasId={adSenseCredential.alias_id}
                  key={adSenseCredential.alias_id}
                >
                  <AdAccountsTable
                    openFirstProfile={Boolean(index === 0)}
                    adSenseCredential={adSenseCredential}
                  />
                </TikTokAdAccountsProvider>
              ))}
            </>
          )}
        </S.HeaderAndTableContainer>
      )}

      {Boolean(!adSenseCredentials.length) && (
        <S.ContentWrapper>
          <S.Image src={TikTokImg} alt="profitfy-goat-with-tiktok-logo" />

          <S.Heading type={EHeadingSize.H2}>Conectar perfil do TikTok</S.Heading>

          <S.ButtonsWrapper>
            <S.AddNewProfileButton onClick={onAddNewProfileButtonClick}>
              {!isIntegrating ? (
                <>
                  <TikTokIcon size={22} />
                  Adicionar perfil do TikTok
                </>
              ) : (
                <CircleSpinner size={15} color={theme.colors.gray[1]} loading={isIntegrating} />
              )}
            </S.AddNewProfileButton>

            <S.HorizontalRuleWrapper>
              <S.HorizontalRule />
              <S.HorizontalRuleText>Ou</S.HorizontalRuleText>
              <S.HorizontalRule />
            </S.HorizontalRuleWrapper>

            <S.SkipStepButton
              onClick={handleNextStepButtonClick}
              variant={EButtonVariant.SECONDARY_BUTTON}
            >
              Conectar depois
            </S.SkipStepButton>
          </S.ButtonsWrapper>
        </S.ContentWrapper>
      )}

      {Boolean(adSenseCredentials.length) && (
        <NextStep
          onClick={handleNextStepButtonClick}
          textColor={EButtonTextColor.DARK}
          disabled={isSyncing || isLoadingTrial}
          isLoading={isSyncing || isLoadingTrial}
        >
          Próximo Passo
        </NextStep>
      )}
    </S.Container>
  );
};

export default Wrapper;
