import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Name = styled(TextBase)`
  max-width: calc(100% - 48px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 0.875rem;
  transition: all 0.3s;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  background-color: unset;
  padding: unset;
  border: unset;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  padding: 10px 12px;
  box-sizing: border-box;
`;
