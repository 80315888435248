import React from 'react';

import { Warning } from '@profitfy/pakkun-icons';
import { useParams } from 'react-router-dom';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { IUpdateVariantCostModalProps } from '@domain/interfaces/dashboard/ProductCostDetails/IUpdateVariantCostModal';
import { IParams } from '@domain/interfaces/IParams';

import { useProductCostDetails } from '@helpers/hooks/pages/dashboard/productCostDetails/productCostDetails';
import { useConfig } from '@helpers/hooks/useConfig';
import { useToast } from '@helpers/hooks/useToast';
import { useRetrospectiveData } from '@helpers/hooks/useRetrospectiveData';

import { parseVariantCosts } from '@helpers/dashboard/productCostDetails/productCostDetailsUtils';

import productCostDetailsService from '@services/pages/dashboard/productCostDetails/productCostDetails';

import * as S from './styles';

const UpdateVariantCostModal: React.FC<IUpdateVariantCostModalProps> = ({ isOpen, toggle }) => {
  const { analytics, user } = useConfig();
  const { toast } = useToast();
  const { storeAliasId, productAliasId } = useParams<IParams>();
  const { trackProductCostEditedQuantity } = useRetrospectiveData();

  const {
    editedVariants,
    updateEditedVariants,
    updateSavedVariants,
    costToApplyAll,
    currencyToApplyAll,
    handleSyncPastCogsSidemodalOpen,
    mutateVariants,
  } = useProductCostDetails();

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);

  const onConfirm = React.useCallback(
    async openSyncOrdersSideModal => {
      setIsSubmittingForm(true);

      try {
        if (editedVariants) {
          const mappedVariants = parseVariantCosts(editedVariants);

          if (costToApplyAll || currencyToApplyAll) {
            await productCostDetailsService.updateAllVariantCost({
              productAliasId,
              storeAliasId,
              data: {
                cost: costToApplyAll,
                currency: currencyToApplyAll,
              },
            });
          }

          if (mappedVariants.length) {
            await productCostDetailsService.updateVariantCost({
              storeAliasId,
              productAliasId,
              data: { variants: mappedVariants },
            });
          }

          trackProductCostEditedQuantity();

          analytics?.track(
            'Product Cost Edited',
            {
              email: user?.email,
            },
            { context: { groupId: storeAliasId } },
          );

          analytics?.trackHubSpot(storeAliasId, { product_cost_added: true });

          toast.success('Custo dos Produtos atualizado com sucesso!');

          mutateVariants();

          updateSavedVariants(editedVariants);

          updateEditedVariants([]);

          toggle();

          if (openSyncOrdersSideModal) {
            handleSyncPastCogsSidemodalOpen();
          }
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsSubmittingForm(false);
      }
    },
    [
      toast,
      editedVariants,
      storeAliasId,
      productAliasId,
      updateEditedVariants,
      updateSavedVariants,
      toggle,
      handleSyncPastCogsSidemodalOpen,
      analytics,
      costToApplyAll,
      currencyToApplyAll,
      user,
      mutateVariants,
      trackProductCostEditedQuantity,
    ],
  );

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.HeadingWrapper>
        <Warning size={24} outline />
        <S.Heading>Sincronizar custo com pedidos do passado?</S.Heading>
      </S.HeadingWrapper>

      <S.Description>
        Caso você <strong>não sincronize</strong>, os custos só serão aplicados <br /> para os{' '}
        <strong>próximos pedidos.</strong>
      </S.Description>

      <S.ButtonsWrapper>
        <S.ConfirmButton
          onClick={() => onConfirm(false)}
          isLoading={isSubmittingForm}
          disabled={isSubmittingForm}
          variant={EButtonVariant.SECONDARY_BUTTON}
        >
          Não alterar
        </S.ConfirmButton>
        <S.ConfirmButton
          onClick={() => onConfirm(true)}
          isLoading={isSubmittingForm}
          disabled={isSubmittingForm}
        >
          Alterar
        </S.ConfirmButton>
      </S.ButtonsWrapper>
    </S.Modal>
  );
};

export default UpdateVariantCostModal;
