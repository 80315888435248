import styled from 'styled-components/macro';

export const StoreGoalAndPaymentMethodsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  justify-content: space-between;
  width: 100%;
`;

export const MetricsAndStoreGoalAndPaymentMethodsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;

  @media only screen and (max-width: 1408px) {
    gap: 0;
  }

  @media only screen and (max-width: 1360px) {
    flex-direction: column;
    gap: 16px;
    margin-bottom: unset;
  }
`;

export const SecondSection = styled.div`
  min-width: 398px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media only screen and (max-width: 1360px) {
    max-width: unset;
    width: 100%;
    gap: 16px;
    min-width: unset;
  }
`;

export const FirstSection = styled.div`
  max-width: 860px;
  width: 100%;

  @media only screen and (max-width: 1456px) {
    width: 828px;
  }

  @media only screen and (max-width: 1424px) {
    width: 816px;
  }

  @media only screen and (max-width: 1408px) {
    width: 798px;
  }

  @media only screen and (max-width: 1388px) {
    width: 770px;
  }

  @media only screen and (max-width: 1360px) {
    max-width: unset;
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  justify-content: space-between;

  @media only screen and (max-width: 1424px) {
    gap: 16px;
  }

  @media only screen and (max-width: 1360px) {
    flex-direction: column;
  }
`;

export const Container = styled.div`
  max-width: 1282px;
  width: 100%;
  padding-top: 32px;
`;
