import React from 'react';
import { useParams } from 'react-router-dom';

import { ECheckoutStep } from '@domain/enums/subscription/ECheckoutStep';
import { IParams } from '@domain/interfaces/IParams';

import { useCheckout } from '@helpers/hooks/pages/subscription/useCheckout';

import {
  parseAddressForm,
  parsePersonalInfoForm,
} from '@helpers/utils/subscription/checkout/formData';

import TermsOfAdhesionModal from '@components/common/core/Utils/TermsOfAdhesionModal';
import Address from './Address';
import PersonalInfo from './PersonalInfo';
import Payment from './Payment';
import Overview from './Overview';

import * as S from './styles';

const Forms: React.FC = () => {
  const { checkoutStep } = useParams<IParams>();
  const {
    handleSubmit,
    handleAddressStep,
    personType,
    handlePersonalInfoStep,
    handlePaymentStep,
    subscribe,
    isTermsOfAdhesionModalOpen,
    handleTermsOfAdhesionModalOpen,
  } = useCheckout();

  const formRef = React.useRef<HTMLFormElement>(null);

  const isAddressStep = checkoutStep === ECheckoutStep.ADDRESS;
  const isPersonalInfoStep = checkoutStep === ECheckoutStep.PERSONAL_INFO;
  const isPaymentStep = checkoutStep === ECheckoutStep.PAYMENT;
  const isOverviewStep = checkoutStep === ECheckoutStep.OVERVIEW;

  const handleAcceptTermsOfAdhesion = React.useCallback(() => {
    handleTermsOfAdhesionModalOpen();
    subscribe();
  }, [handleTermsOfAdhesionModalOpen, subscribe]);

  const onSubmit = React.useCallback(
    async formData => {
      if (isAddressStep) {
        const parsedFormData = parseAddressForm(formData);
        handleAddressStep(parsedFormData);
      }

      if (isPersonalInfoStep) {
        const parsedPersonalInfoData = parsePersonalInfoForm(formData, personType);
        handlePersonalInfoStep(parsedPersonalInfoData);
      }

      if (isPaymentStep) {
        handlePaymentStep(formData, formRef.current);
      }

      if (isOverviewStep) {
        handleTermsOfAdhesionModalOpen();
      }
    },
    [
      isPaymentStep,
      handleAddressStep,
      isAddressStep,
      handlePersonalInfoStep,
      isPersonalInfoStep,
      personType,
      handlePaymentStep,
      isOverviewStep,
      handleTermsOfAdhesionModalOpen,
    ],
  );

  return (
    <S.Wrapper innerRef={formRef} onSubmit={handleSubmit(onSubmit)}>
      {isAddressStep && <Address />}
      {isPersonalInfoStep && <PersonalInfo />}
      {isPaymentStep && <Payment />}
      {isOverviewStep && <Overview />}

      <TermsOfAdhesionModal
        isOpen={isTermsOfAdhesionModalOpen}
        toggle={handleTermsOfAdhesionModalOpen}
        handleAccept={handleAcceptTermsOfAdhesion}
      />
    </S.Wrapper>
  );
};

export default Forms;
