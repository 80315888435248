import React from 'react';

import CustomDate from './CustomDate';

import * as S from './styles';

const CustomDatePickerPopover: React.FC = () => {
  return (
    <S.Content>
      <CustomDate />

      {/* <PreDefinedDates /> */}

      <S.Arrow />
    </S.Content>
  );
};

export default CustomDatePickerPopover;
