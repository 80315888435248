import React from 'react';
import { useParams } from 'react-router-dom';

import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { ICampaignsProvider } from '@domain/interfaces/dashboard/Campaigns/ICampaigns';
import { IParams } from '@domain/interfaces/IParams';
import { IAdSenseCampaign } from '@domain/interfaces/dashboard/AdSense/IAdSenseCampaign';
import { ISelectedFilter } from '@domain/interfaces/components/IFilter';

import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { parseDataFilter } from '@helpers/utils/components/filter';

import { ADS_PROVIDERS } from '@constants/common/integrations';

import productTagService from '@services/pages/dashboard/productTag/productTag';
import facebookCampaignService from '@services/pages/dashboard/adSense/facebook/facebookCampaign';
import googleCampaignService from '@services/pages/dashboard/adSense/google/googleCampaign';
import taboolaCampaignService from '@services/pages/dashboard/adSense/taboola/taboolaCampaign';
import adSenseCampaignsService from '@services/pages/dashboard/adSense/adSenseCampaign';

const CampaignsContext = React.createContext<ICampaignsProvider | null>(null);

export const CampaignsProvider: React.FC = ({ children }) => {
  const { storeAliasId, productAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { analytics, selectedStore, user } = useConfig();

  const { addSynchronization } = useSynchronization();

  const [isLoadingCampaignsData, setIsLoadingCampaignsData] = React.useState<boolean>(true);
  const [isValidatingCampaignsData, setIsValidatingCampaignsData] = React.useState<boolean>(false);
  const [isCampaignsDataError, setIsCampaignsDataError] = React.useState<boolean>(false);
  const [isAllAdSenseCampaignsChecked, setIsAllAdSenseCampaignsChecked] = React.useState<boolean>(
    false,
  );
  const [adSenseCampaignsPage, setAdSenseCampaignsPage] = React.useState<number>(0);
  const [adSenseCampaignsPageCount, setAdSenseCampaignsPageCount] = React.useState<number>(1);
  const [checkedAdSenseCampaigns, setCheckedAdSenseCampaigns] = React.useState<
    Array<IAdSenseCampaign>
  >([]);
  const [searchName, setSearchName] = React.useState<string>('');
  const [isSyncingAdSenseCampaigns, setIsSyncingAdSenseCampaigns] = React.useState<boolean>(false);
  const [
    isUpdatingCheckedAdSenseCampaigns,
    setIsUpdatingCheckedAdSenseCampaigns,
  ] = React.useState<boolean>(false);
  const [isTagsSideModalOpen, setIsTagsSideModalOpen] = React.useState<boolean>(false);
  const [productTagsPage, setProductTagsPage] = React.useState<number>(0);
  const [productTagsPageCount, setProductTagsPageCount] = React.useState<number>(1);
  const [hasCreatedOrUpdatedProductTag, setHasCreatedOrUpdatedProductTag] = React.useState<boolean>(
    false,
  );
  const [filterData, setFilterData] = React.useState<Array<ISelectedFilter>>([]);

  const handleTagsSideModalOpen = React.useCallback(
    () => setIsTagsSideModalOpen(!isTagsSideModalOpen),
    [isTagsSideModalOpen],
  );

  const handleSearchName = React.useCallback(name => setSearchName(name), []);

  const handleCheckedAdSenseCampaigns = React.useCallback(newCheckedAdSenseCampaigns => {
    setCheckedAdSenseCampaigns(newCheckedAdSenseCampaigns);
  }, []);

  const handleFilterData = React.useCallback(newData => {
    setFilterData(newData);
  }, []);

  const {
    adSenseCampaigns,
    error: adSenseCampaignsError,
    mutate: mutateAdSenseCampaigns,
    isLoading: isLoadingAdSenseCampaigns,
    isValidating: isValidatingAdSenseCampaigns,
    totalPages: adSenseCampaignsTotalPages,
  } = adSenseCampaignsService.listAdSenseCampaignWithInsights({
    storeAliasId,
    productAliasId,
    page: adSenseCampaignsPage + 1,
    filter: { name: searchName, ...parseDataFilter(filterData) },
  });

  const {
    productTags,
    totalPages: productTagsTotalPages,
    isLoading: isLoadingProductTags,
    mutate: mutateProductTags,
    error: productTagsError,
  } = productTagService.listProductTags({
    storeAliasId,
    productAliasId,
    page: productTagsPage + 1,
  });

  const mutateCampaignsData = React.useCallback(async () => {
    const promises = [];

    if (adSenseCampaignsError) promises.push(mutateAdSenseCampaigns());

    if (productTagsError) promises.push(mutateProductTags());

    await Promise.all(promises);
  }, [adSenseCampaignsError, mutateAdSenseCampaigns, productTagsError, mutateProductTags]);

  const syncAdSenseCampaigns = React.useCallback(async () => {
    setIsSyncingAdSenseCampaigns(true);

    try {
      const syncCampaignsPromise = [
        facebookCampaignService.syncFacebookCampaigns({ storeAliasId }),
        googleCampaignService.syncGoogleCampaigns({ storeAliasId }),
        taboolaCampaignService.syncTaboolaCampaigns({ storeAliasId }),
      ];

      await toast.promise(
        Promise.all(syncCampaignsPromise),
        'Sincronizando campanhas...',
        'Campanhas sincronizadas com sucesso!',
        'Ocorreu um problema ao sincronizar as campanhas',
      );

      setIsSyncingAdSenseCampaigns(false);

      await mutateAdSenseCampaigns();
    } catch {
      setIsSyncingAdSenseCampaigns(false);
    }
  }, [storeAliasId, toast, mutateAdSenseCampaigns]);

  const updateAdSenseCampaign = React.useCallback(
    async ({ adSenseCampaignAliasId, data }) => {
      try {
        const foundIntegration = ADS_PROVIDERS.find(
          integration => integration.identifier === data.provider,
        ) as typeof ADS_PROVIDERS[number];

        if (data?.is_active) {
          const aliases = {
            adSenseCampaignAliasId,
            productAliasId,
          };

          addSynchronization({
            name: foundIntegration.name,
            type: ESynchronizationType.UPDATE_AD_SENSE_CAMPAIGNS,
            label: 'Campanhas',
            dependencies: [],
            storeAliasId,
            config: {
              isOnboardingSynchronization: false,
              showNotification: true,
            },
            request: foundIntegration.generateSyncRequest(
              ESynchronizationType.UPDATE_AD_SENSE_CAMPAIGNS,
              storeAliasId,
              aliases,
              data,
            ),
          });
        } else {
          await foundIntegration.updateCampaign({
            storeAliasId,
            productAliasId,
            adSenseCampaignAliasId,
            data,
          });
        }

        analytics?.track(
          'Product Marketing Cost Added',
          {
            automatic_fill: true,
            email: user?.email,
          },
          { context: { groupId: selectedStore?.alias_id } },
        );
      } catch (err: any) {
        toast.error(err?.response?.data?.message);
      }
    },
    [productAliasId, storeAliasId, toast, analytics, selectedStore, addSynchronization, user],
  );

  const updateCheckedAdSenseCampaigns = React.useCallback(async () => {
    setIsUpdatingCheckedAdSenseCampaigns(true);

    try {
      const promises: Array<Promise<any>> = [];

      checkedAdSenseCampaigns.forEach(adSenseCampaign => {
        promises.push(
          facebookCampaignService.updateFacebookCampaign({
            storeAliasId,
            productAliasId,
            adSenseCampaignAliasId: adSenseCampaign.alias_id,
            data: {
              is_active: true,
            },
          }),
        );
      });

      await Promise.all(promises);

      analytics?.track(
        'Product Marketing Cost Added',
        {
          automatic_fill: true,
          email: user?.email,
        },
        { context: { groupId: selectedStore?.alias_id } },
      );

      toast.success('Campanhas atualizadas com sucesso!');

      setIsUpdatingCheckedAdSenseCampaigns(false);

      await mutateAdSenseCampaigns();

      setCheckedAdSenseCampaigns([]);
    } catch (err: any) {
      setIsUpdatingCheckedAdSenseCampaigns(false);
      toast.error(err?.response?.data?.message);
    }
  }, [
    checkedAdSenseCampaigns,
    productAliasId,
    storeAliasId,
    toast,
    mutateAdSenseCampaigns,
    analytics,
    selectedStore,
    user,
  ]);

  const onAdSenseCampaignsPageChange = React.useCallback(
    ({ selected }) => setAdSenseCampaignsPage(selected),
    [],
  );

  const onProductTagsPageChange = React.useCallback(
    ({ selected }) => setProductTagsPage(selected),
    [],
  );

  const handleAllAdSenseCampaignsChecked = React.useCallback(() => {
    if (!isAllAdSenseCampaignsChecked) {
      setCheckedAdSenseCampaigns(adSenseCampaigns);
    } else {
      setCheckedAdSenseCampaigns([]);
    }

    setIsAllAdSenseCampaignsChecked(!isAllAdSenseCampaignsChecked);
  }, [isAllAdSenseCampaignsChecked, adSenseCampaigns]);

  const createProductTag = React.useCallback(
    async name => {
      try {
        await productTagService.createProductTag({
          storeAliasId,
          productAliasId,
          data: {
            name,
          },
        });

        analytics?.track(
          'Campaign Tag Added',
          { name, email: user?.email },
          { context: { groupId: selectedStore?.alias_id } },
        );

        toast.success('Custo dos Produtos atualizado com sucesso!');

        toast.success('Tag criada com sucesso!');

        await mutateProductTags();

        setHasCreatedOrUpdatedProductTag(true);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
    [mutateProductTags, productAliasId, storeAliasId, toast, analytics, selectedStore, user],
  );

  const updateProductTag = React.useCallback(
    async (productTagAliasId, name) => {
      try {
        await productTagService.updateProductTag({
          storeAliasId,
          productAliasId,
          productTagAliasId,
          data: {
            name,
          },
        });

        toast.success('Tag atualizada com sucesso!');

        await mutateProductTags();

        setHasCreatedOrUpdatedProductTag(true);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
    [mutateProductTags, toast, storeAliasId, productAliasId],
  );

  const deleteProductTag = React.useCallback(
    async productTagAliasId => {
      try {
        await productTagService.deleteProductTag({
          storeAliasId,
          productAliasId,
          productTagAliasId,
        });

        toast.success('Tag excluída com sucesso!');

        await mutateProductTags();

        setHasCreatedOrUpdatedProductTag(true);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
    [mutateProductTags, storeAliasId, productAliasId, toast],
  );

  React.useEffect(() => {
    if (adSenseCampaignsTotalPages) setAdSenseCampaignsPageCount(adSenseCampaignsTotalPages);
  }, [adSenseCampaignsTotalPages]);

  React.useEffect(() => {
    if (productTagsTotalPages) setProductTagsPageCount(productTagsTotalPages);
  }, [productTagsTotalPages]);

  React.useEffect(() => {
    setIsLoadingCampaignsData(isLoadingAdSenseCampaigns || isLoadingProductTags);
  }, [isLoadingAdSenseCampaigns, isLoadingProductTags]);

  React.useEffect(() => {
    setIsValidatingCampaignsData(isValidatingAdSenseCampaigns);
  }, [isValidatingAdSenseCampaigns]);

  React.useEffect(() => {
    if (adSenseCampaignsError || productTagsError) {
      setIsCampaignsDataError(true);
    } else {
      setIsCampaignsDataError(false);
    }
  }, [adSenseCampaignsError, productTagsError]);

  return (
    <CampaignsContext.Provider
      value={{
        adSenseCampaigns,
        adSenseCampaignsPageCount,
        handleAllAdSenseCampaignsChecked,
        handleCheckedAdSenseCampaigns,
        handleSearchName,
        isAllAdSenseCampaignsChecked,
        isCampaignsDataError,
        isLoadingCampaignsData,
        isValidatingCampaignsData,
        mutateCampaignsData,
        onAdSenseCampaignsPageChange,
        syncAdSenseCampaigns,
        updateAdSenseCampaign,
        updateCheckedAdSenseCampaigns,
        isSyncingAdSenseCampaigns,
        isUpdatingCheckedAdSenseCampaigns,
        checkedAdSenseCampaigns,
        adSenseCampaignsPage,
        handleTagsSideModalOpen,
        isTagsSideModalOpen,
        onProductTagsPageChange,
        productTags,
        productTagsPage,
        productTagsPageCount,
        createProductTag,
        hasCreatedOrUpdatedProductTag,
        updateProductTag,
        deleteProductTag,
        filterData,
        handleFilterData,
      }}
    >
      {children}
    </CampaignsContext.Provider>
  );
};

export const useCampaigns = (): ICampaignsProvider => {
  const context = React.useContext(CampaignsContext);

  if (!context) {
    throw new Error('useCampaigns must be used within CampaignsContext');
  }

  return context;
};
