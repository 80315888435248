export const normalizeEmail = (content: string): string => {
  return content.replaceAll(' ', '').toLowerCase();
};

export const normalizeName = (content: string): string => {
  return content.replaceAll(' ', '').toLowerCase();
};

export const normalizePhone = (content: string): string => {
  return content.replaceAll(' ', '').toLowerCase();
};
