import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const IconWrapper = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled(TextBase)``;

export const Button = styled.button`
  background-color: unset;
  border: 1px solid transparent;
  padding: 4px 12px;
  height: 42px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.colors.font};
  }

  &:active {
    filter: brightness(0.9);
  }
`;
