export const data = [
  {
    name: 'Facebook',
    value: 409,
  },
  {
    name: 'Google Ads',
    value: 898,
  },
  {
    name: 'Taboola',
    value: 203,
  },
];

export const COLORS = ['#1C6D6E', '#2EB3B4', '#A1E6E7'];
