import React from 'react';
import { useTheme } from 'styled-components';
import { MagnifyingGlass } from 'phosphor-react';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';
import { useToast } from '@helpers/hooks/useToast';
import { useStorePermission } from '@helpers/hooks/common/useStorePermission';
import { useAutomationsManager } from '@helpers/hooks/pages/dashboard/automations/useAutomationsManager';

import { ISelectInstanceProps } from '@domain/interfaces/dashboard/Automations/Canvas/SaveAutomationModal/ISaveAutomationModal';

import InstanceCard from '../InstancesCard';

import * as S from './styles';

const SelectInstances: React.FC<ISelectInstanceProps> = ({ toggle }) => {
  const { colors } = useTheme();
  const {
    saveAutomation,
    workflow,
    whatsappCredentials,
    whatsAppCredentialName,
    handleWhatsAppCredentialSearch,
    handleWorkflowLimitationModalOpen,
    toggleShowAddWhatsappInstanceModal,
    toggleShowSaveAutomationModal,
    isSavingAutomation,
    handleWhatsappInstancesLimitationModalOpen,
    workflowAliasId,
  } = useAutomation();
  const { toast } = useToast();
  const { maxWorkflows, maxWhatsappInstances } = useStorePermission();
  const { workflows } = useAutomationsManager();

  const [name, setName] = React.useState<string>('');
  const [selectedInstances, setSelectedInstances] = React.useState<string[]>([]);

  const handleChangeName = React.useCallback(e => {
    setName(e.target.value);
  }, []);

  const handleSaveAutomation = React.useCallback(async () => {
    if (!name) {
      toast.error('O nome da automação é obrigatório');
      return;
    }

    if (!workflowAliasId && (workflows?.length ?? 0) >= maxWorkflows && maxWorkflows !== -1) {
      handleWorkflowLimitationModalOpen();
      return;
    }

    await saveAutomation(name, selectedInstances);

    toggle();
  }, [
    saveAutomation,
    name,
    toggle,
    selectedInstances,
    toast,
    handleWorkflowLimitationModalOpen,
    maxWorkflows,
    workflows,
    workflowAliasId,
  ]);

  const handleSearchWhatsAppInstance = React.useCallback(
    e => {
      handleWhatsAppCredentialSearch(e.target.value);
    },
    [handleWhatsAppCredentialSearch],
  );

  React.useEffect(() => {
    setName(workflow ? workflow.name : '');
  }, [workflow]);

  const handleAddInstances = React.useCallback(
    (newInstance: string) => {
      const foundInstance = selectedInstances.find(
        selectedInstance => selectedInstance === newInstance,
      );

      if (foundInstance) return;

      setSelectedInstances([...selectedInstances, newInstance]);
    },
    [selectedInstances],
  );

  const handleRemoveInstances = React.useCallback(
    (instance: string) => {
      const filteredInstances = selectedInstances.filter(selectedInstance => {
        return selectedInstance !== instance;
      });

      setSelectedInstances(filteredInstances);
    },
    [selectedInstances],
  );

  const handleAddInstance = React.useCallback(() => {
    if ((whatsappCredentials?.length ?? 0) >= maxWhatsappInstances && maxWhatsappInstances !== -1) {
      handleWhatsappInstancesLimitationModalOpen();
      return;
    }

    toggleShowAddWhatsappInstanceModal();
    toggleShowSaveAutomationModal();
  }, [
    toggleShowAddWhatsappInstanceModal,
    toggleShowSaveAutomationModal,
    handleWhatsappInstancesLimitationModalOpen,
    whatsappCredentials,
    maxWhatsappInstances,
  ]);

  return (
    <>
      <S.InputGroup>
        <S.Label htmlFor="name">Nome da automação</S.Label>
        <S.Input
          type="text"
          noFeedbackError
          placeholder="Digite o nome da automação"
          id="name"
          defaultValue={workflow ? workflow.name : ''}
          onChange={handleChangeName}
        />
      </S.InputGroup>

      <S.InstanceWrapper>
        <S.TextInstanceRotation>
          Selecione as instâncias que você quer utilizar com essa automação. <br /> Caso selecione
          duas ou mais instâncias, as automações irão executar na ordem dessas instâncias,
          distribuíndo de forma uniforme.
        </S.TextInstanceRotation>

        <S.AddInstanceWrapper onClick={handleAddInstance}>
          <S.AddInstanceText>Conectar nova instância</S.AddInstanceText>
        </S.AddInstanceWrapper>
      </S.InstanceWrapper>

      <S.Row>
        <S.Input
          type="text"
          placeholder="Procurar instância"
          prepend={MagnifyingGlass}
          prependIconColor={colors.gray[3]}
          prependIconSize={30}
          defaultValue={whatsAppCredentialName}
          onChange={handleSearchWhatsAppInstance}
        />
      </S.Row>

      <S.InstancesWrapper>
        {whatsappCredentials &&
          whatsappCredentials.map(instance => {
            return (
              <InstanceCard
                key={instance.whatsapp_credential.id}
                instance={instance}
                addInstance={handleAddInstances}
                removeInstance={handleRemoveInstances}
              />
            );
          })}
      </S.InstancesWrapper>

      <S.ButtonWrapper>
        <S.SecondaryButton onClick={toggle}>Continuar editando</S.SecondaryButton>

        <S.PrimaryButton
          onClick={handleSaveAutomation}
          isLoading={isSavingAutomation}
          disabled={isSavingAutomation}
        >
          Salvar automação
        </S.PrimaryButton>
      </S.ButtonWrapper>
    </>
  );
};

export default SelectInstances;
