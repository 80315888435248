import React from 'react';

import { useAdsManager } from '@helpers/hooks/pages/dashboard/adsManager/useAdsManager';

import HeaderCell from './HeaderCell';

import * as S from './styles';

const Header: React.FC = () => {
  const { tableColumns } = useAdsManager();

  const filteredTableColumns = tableColumns.filter(column => column.isActive);

  return (
    <S.Wrapper>
      {filteredTableColumns.map((column, index) => (
        <HeaderCell column={column} index={index} key={column.id} />
      ))}
    </S.Wrapper>
  );
};

export default Header;
