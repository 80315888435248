import React from 'react';
import { Rocket } from '@profitfy/pakkun-icons';

import * as S from './styles';

const PremiumFeature: React.FC = () => {
  return (
    <S.Container>
      <Rocket size={28} color="#A37CFF" />
      <S.Text>
        Esse é um recurso <strong>premium.</strong> Você terá total acesso durante seu trial.
      </S.Text>
    </S.Container>
  );
};

export default PremiumFeature;
