import styled, { keyframes } from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import RadioBase from '@components/common/core/Inputs/Radio';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import TextBase from '@components/common/core/DataDisplay/Text';

const slideUpAndFade = keyframes`
  0% {
    opacity: 0;
    transform: translateY(4px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Input = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const RadioText = styled(TextBase)`
  line-height: 120%;
`;

export const Radio = styled(RadioBase)``;

export const RadioLabel = styled(LabelBase)`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const InputGroup = styled(InputGroupBase)``;

export const Title = styled(HeadingBase)`
  margin-bottom: 16px;
`;

export const Wrapper = styled.div`
  padding: 24px 32px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 6px;
  animation-duration: 700ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-fill-mode: forwards;
  animation-name: ${slideUpAndFade};
  will-change: 'transform, opacity';
`;
