import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import ButtonBase from '@components/common/core/Inputs/Button';

export const CancelButton = styled.button`
  border: unset;
  background: unset;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.font};
  font-size: 0.875rem;
  font-weight: 500;
  padding: 12px 16px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.green.default};
  }

  &:active {
    color: ${({ theme }) => theme.colors.green.default};
  }
`;

export const SaveButton = styled(ButtonBase)``;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Checkbox = styled(CheckboxBase)``;

export const CheckboxLabel = styled(LabelBase)`
  margin: unset;
  margin-top: 1px;
  cursor: pointer;
`;

export const CheckboxGroup = styled(InputGroupBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin: unset;
  margin-bottom: 42px;
`;

export const Modal = styled(ModalBase)`
  & > div:first-child {
    & > span {
      display: flex;
      align-items: center;
      gap: 8px;

      & > svg {
        margin-top: 2px;
      }
    }
  }
`;
