import React from 'react';
import { SquaredArrowDown, SquaredArrowUp } from '@profitfy/pakkun-icons';

import { IFieldOptionProps } from '@domain/interfaces/components/ISort';
import { useSort } from '@helpers/hooks/components/useSort';

import * as S from './styles';

const FieldOption: React.FC<IFieldOptionProps> = ({ field }) => {
  const { onFieldClick, selectedField } = useSort();

  const isFieldSelected = selectedField && selectedField.field === field.value;

  return (
    <S.FieldButton onClick={() => onFieldClick(field)}>
      <>
        {field.label}
        {isFieldSelected && (
          <>
            {selectedField?.value === 'ASC' ? (
              <S.IconWrapper>
                <SquaredArrowUp outline size={18} />
              </S.IconWrapper>
            ) : (
              <S.IconWrapper>
                <SquaredArrowDown outline size={18} />
              </S.IconWrapper>
            )}
          </>
        )}
      </>
    </S.FieldButton>
  );
};

export default FieldOption;
