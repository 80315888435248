import React from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { EButtonSize } from '@domain/enums/components/EButton';

import { useToast } from '@helpers/hooks/useToast';
import { addTrialDaysSchema } from '@helpers/validators/dashboard/adminPanel/trialDays';

import trialDaysService from '@services/pages/dashboard/adminPanel/trial';

import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const Trial: React.FC = () => {
  const { toast } = useToast();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(addTrialDaysSchema) });

  const [isAddingDays, setIsAddingDays] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async formData => {
      setIsAddingDays(true);

      try {
        await trialDaysService.addTrialDays({
          store_alias_id: formData.store_alias_id,
          data: { days_to_add: Number(formData.days_to_add) },
        });

        toast.success('Quantidade adicionada com sucesso.');

        setIsAddingDays(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsAddingDays(false);
      }
    },
    [toast],
  );

  return (
    <S.ContentWrapper>
      <S.Heading>Adicionar dias de trial</S.Heading>

      <S.Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label htmlFor="store-alias-id">Loja</S.Label>

            <S.Input
              {...register('store_alias_id')}
              type="text"
              placeholder="Insira o Alias Id da loja"
              id="store-alias-id"
            />
            {errors.store_alias_id && <Text isErrorFeedback>{errors.store_alias_id.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label htmlFor="days-to-add">Quantidade de dias</S.Label>

            <S.Input
              {...register('days_to_add')}
              type="text"
              placeholder="Insira a quantidade de dias"
              id="days-to-add"
            />
            {errors.days_to_add && <Text isErrorFeedback>{errors.days_to_add.message}</Text>}
          </S.InputGroup>

          <S.AddDaysButton
            type="submit"
            isLoading={isAddingDays}
            disabled={isAddingDays}
            buttonSize={EButtonSize.MEDIUM}
          >
            Adicionar
          </S.AddDaysButton>
        </Form>
      </S.Content>
    </S.ContentWrapper>
  );
};

export default Trial;
