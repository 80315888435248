import React from 'react';
import { Dsers as BlingIcon } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import CredentialOption from './CredentialOption';
import SyncOption from './SyncOption';

import * as S from './styles';

const DsersConfig: React.FC = () => {
  const theme: any = useTheme();

  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const onChangeTab = React.useCallback(index => setSelectedTab(index), []);

  return (
    <S.ContentWrapper>
      <S.Header>
        <BlingIcon size={52} color={theme.colors.gray[2]} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com Dsers
        </S.Heading>
      </S.Header>

      <S.Tab defaultTab={selectedTab} onChangeTab={onChangeTab}>
        <S.Option>Geral</S.Option>
        <S.Option>Sincronização</S.Option>
      </S.Tab>

      {selectedTab === 0 && <CredentialOption />}

      {selectedTab === 1 && <SyncOption />}
    </S.ContentWrapper>
  );
};

export default DsersConfig;
