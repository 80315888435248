import React from 'react';
import { useParams } from 'react-router-dom';

import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { ESyncType } from '@domain/enums/dashboard/adsManager/EScriptAndUtms';
import { IParams } from '@domain/interfaces/IParams';
import { IFilterQueryStringData } from '@domain/interfaces/components/IFilter';
import { IAdSenseAds } from '@domain/interfaces/dashboard/AdsManager/Onboarding/IAdSenseCampaignsWithoutUtm';

import { useAdsManager } from '@helpers/hooks/pages/dashboard/adsManager/useAdsManager';
import { useUtms } from '@helpers/hooks/pages/dashboard/adsManager/useUtms';

import adSenseAdsService from '@services/pages/dashboard/adsManager/adSenseAds';

import ComponentError from '@components/common/core/Utils/ComponentError';
import OverviewContent from './OverviewContent';
import UtmsContent from './UtmsContent';
import Save from './Save';
import ConfirmModal from './ConfirmModal';
import ScheduleContent from './ScheduleContent';
import NoUtmLinks from './NoUtmLinks';

import * as S from './styles';

const UtmSidemodal: React.FC = () => {
  const { isUtmSidemodalOpen, handleUtmSidemodalOpen } = useAdsManager();
  const { storeAliasId } = useParams<IParams>();
  const {
    syncType,
    isConfirmModalOpen,
    handleConfirmModalOpen,
    syncUtms,
    createSchedule,
  } = useUtms();

  const [step, setStep] = React.useState<string>('FIRST');
  const [selectedAdSenseAds, setSelectedAdSenseAds] = React.useState<Array<IAdSenseAds>>([]);
  const [adSenseCampaignsWithoutUtmFilter] = React.useState<IFilterQueryStringData>({
    statuses: ['ENABLED'],
    providers: [EAdSenseProvider.FACEBOOK],
  });

  const {
    adSenseAds,
    isLoading: isLoadingAdSenseAds,
    isValidating: isValidatingAdSenseAds,
    mutate: mutateAdSenseAds,
    error: adSenseAdsError,
  } = adSenseAdsService.listAdSenseAdsWithoutUtm({
    storeAliasId,
    filter: adSenseCampaignsWithoutUtmFilter,
  });

  const handleCancel = React.useCallback(() => {
    if (step === 'FIRST') {
      handleUtmSidemodalOpen();
    } else {
      setStep('FIRST');
    }
  }, [step, handleUtmSidemodalOpen]);

  const handleSave = React.useCallback(async () => {
    if (step === 'FIRST' && syncType === ESyncType.SCHEDULE) {
      await createSchedule(selectedAdSenseAds);
    }

    if (step === 'FIRST' && syncType === ESyncType.SYNC_NOW) {
      handleConfirmModalOpen();
    }

    if (step === 'SECOND') {
      setStep('FIRST');
    }
  }, [step, handleConfirmModalOpen, syncType, createSchedule, selectedAdSenseAds]);

  const handleSelectedAdSenseAds = React.useCallback(
    newSelectedAdSenseAds => setSelectedAdSenseAds(newSelectedAdSenseAds),
    [],
  );

  const handleStep = React.useCallback(newStep => setStep(newStep), []);

  React.useEffect(() => {
    if (adSenseAds?.length) {
      setSelectedAdSenseAds(adSenseAds);
    }
  }, [adSenseAds]);

  if (isLoadingAdSenseAds || isValidatingAdSenseAds) {
    return (
      <S.Sidemodal isOpen={isUtmSidemodalOpen} toggle={handleUtmSidemodalOpen}>
        <div>Loading...</div>;
      </S.Sidemodal>
    );
  }

  if (adSenseAdsError && !isValidatingAdSenseAds) {
    return <ComponentError mutate={mutateAdSenseAds()} />;
  }

  return (
    <S.Sidemodal isOpen={isUtmSidemodalOpen} toggle={handleUtmSidemodalOpen}>
      {step === 'FIRST' && (
        <>
          <OverviewContent
            handleStep={handleStep}
            adSenseAds={adSenseAds}
            selectedAdSenseAds={selectedAdSenseAds}
          />

          <S.HorizontalRule />

          <ScheduleContent />

          {Boolean(adSenseAds?.length) && <S.HorizontalRule />}

          {Boolean(adSenseAds?.length) && <NoUtmLinks adSenseAds={adSenseAds} />}
        </>
      )}

      {step === 'SECOND' && (
        <UtmsContent
          adSenseAds={adSenseAds}
          selectedAdSenseAds={selectedAdSenseAds}
          handleSelectedAdSenseAds={handleSelectedAdSenseAds}
        />
      )}

      <Save step={step} handleCancel={handleCancel} handleSave={handleSave} />

      <ConfirmModal
        isOpen={isConfirmModalOpen}
        toggle={handleConfirmModalOpen}
        syncUtms={() => syncUtms(selectedAdSenseAds)}
      />
    </S.Sidemodal>
  );
};

export default UtmSidemodal;
