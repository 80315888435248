import { usePricingTool } from '@helpers/hooks/pages/dashboard/pricingTool/usePricingTool';
import { numberFormatter } from '@helpers/masks';
import React from 'react';
import * as S from './styles';

const RecommendedPrice: React.FC = () => {
  const { finalPrice } = usePricingTool();
  const suggestedPrice = finalPrice[2];

  return (
    <S.Container>
      <S.TitleCard>Preço recomendado</S.TitleCard>
      <S.PriceWrapper>
        <S.Text>R$ {numberFormatter(suggestedPrice, 2)}</S.Text>
      </S.PriceWrapper>
    </S.Container>
  );
};

export default RecommendedPrice;
