import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import EmptyDataBase from '@components/common/core/DataDisplay/EmptyData';

export const EmptyData = styled(EmptyDataBase)`
  padding: 32px;
  max-width: unset;
  box-sizing: border-box;

  & > img {
    max-width: 364px;
  }

  & > h2 {
    font-size: 1.5rem;
  }
`;

export const GroupName = styled(TextBase)``;

export const GroupIcon = styled.div`
  & > svg {
    margin-top: 2px;
  }
`;

export const GroupWrapperButton = styled.button`
  display: flex;
  padding-left: 44px;
  background-color: unset;
  align-items: center;
  border: none;
  padding: 0;
  cursor: pointer;
  gap: 8px;
  padding: 16px 20px 16px 20px;
`;

export const Title = styled(TextBase)``;

export const CategoryWrapper = styled.div`
  & > div {
    padding: 8px 24px;
  }

  & > div:first-of-type {
    & > div:first-child {
      height: 28px;
      top: 8px;
    }
  }

  & > div:last-child {
    margin-bottom: 8px;
  }
`;
