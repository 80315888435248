import React from 'react';
import { OrderTruck, Clock, Check } from '@profitfy/pakkun-icons';

import { useProductDetails } from '@helpers/hooks/pages/dashboard/productAnalytics/useProductDetails';
import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const OrdersDataCard: React.FC = () => {
  const { productFinancialDetailsReport } = useProductDetails();

  const { total_orders, paid_orders, pending_orders } = productFinancialDetailsReport;

  return (
    <S.Card>
      <S.Content>
        <S.MetricWrapper>
          <S.IconWrapper>
            <OrderTruck size={24} outline />
          </S.IconWrapper>

          <S.MetricTextWrapper>
            <S.MetricValueAndQuantity>
              <S.MetricValue>{`R$ ${numberFormatter(total_orders.amount, 2)}`}</S.MetricValue>
              <S.MetricQuantity>{`(${numberFormatter(
                total_orders.quantity,
                0,
                true,
              )})`}</S.MetricQuantity>
            </S.MetricValueAndQuantity>
            <S.MetricName>Total de Pedidos</S.MetricName>
          </S.MetricTextWrapper>
        </S.MetricWrapper>

        <S.MetricWrapper>
          <S.IconWrapper>
            <Check size={24} outline />
          </S.IconWrapper>

          <S.MetricTextWrapper>
            <S.MetricValueAndQuantity>
              <S.MetricValue>{`R$ ${numberFormatter(paid_orders.amount, 2)}`}</S.MetricValue>
              <S.MetricQuantity>{`(${numberFormatter(
                paid_orders.quantity,
                0,
                true,
              )})`}</S.MetricQuantity>
            </S.MetricValueAndQuantity>
            <S.MetricName>Pedidos Pagos</S.MetricName>
          </S.MetricTextWrapper>
        </S.MetricWrapper>

        <S.MetricWrapper>
          <S.IconWrapper>
            <Clock size={24} outline />
          </S.IconWrapper>

          <S.MetricTextWrapper>
            <S.MetricValueAndQuantity>
              <S.MetricValue>{`R$ ${numberFormatter(pending_orders.amount, 2)}`}</S.MetricValue>
              <S.MetricQuantity>{`(${numberFormatter(
                pending_orders.quantity,
                0,
                true,
              )})`}</S.MetricQuantity>
            </S.MetricValueAndQuantity>
            <S.MetricName>Pedidos Pendentes</S.MetricName>
          </S.MetricTextWrapper>
        </S.MetricWrapper>
      </S.Content>
    </S.Card>
  );
};

export default OrdersDataCard;
