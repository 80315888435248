import styled from 'styled-components/macro';

import CustomHoverCard from '@components/common/core/Utils/CustomHoverCard';

import TextBase from '@components/common/core/DataDisplay/Text';

export const HoverCard = styled(CustomHoverCard)``;

export const HoverCardContent = styled(CustomHoverCard.Content)`
  min-width: 288px;
  padding: 16px;
  box-sizing: border-box;
`;

export const HoverCardTrigger = styled(CustomHoverCard.Trigger)`
  margin-right: -22px;
`;

export const IconAndNameWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const TaxName = styled(TextBase)`
  font-size: 0.875rem;
`;

export const TaxValue = styled(TextBase)``;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
`;
