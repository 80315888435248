import styled from 'styled-components/macro';

import LabelBase from '@components/common/core/DataDisplay/Label';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';
import TextBase from '@components/common/core/DataDisplay/Text';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';

export const Option = styled(OptionBase)``;

export const UserMarketSegment = styled(SelectBase)`
  width: 100%;

  & > label > select {
    background-color: ${({ theme }) => theme.colors.gray[5]};
    border-color: transparent;
    color: ${({ theme }) => theme.colors.gray[1]};
  }
`;

export const UserCompanySize = styled(SelectBase)`
  width: 100%;

  & > label > select {
    background-color: ${({ theme }) => theme.colors.gray[5]};
    border-color: transparent;
    color: ${({ theme }) => theme.colors.gray[1]};
  }
`;

export const UserRoleSelect = styled(SelectBase)`
  width: 100%;

  & > label > select {
    background-color: ${({ theme }) => theme.colors.gray[5]};
    border-color: transparent;
    color: ${({ theme }) => theme.colors.gray[1]};
  }
`;

export const Button = styled(ButtonBase)`
  max-width: 374px !important;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 32px;
`;

export const Label = styled(LabelBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const InputGroup = styled(InputGroupBase)`
  max-width: 374px;
  width: 100%;
  margin-bottom: 24px;

  &:first-of-type {
    margin-top: 40px;
  }

  &:last-child {
    margin-bottom: 32px;
  }
`;

export const HeadingDescription = styled(TextBase)`
  text-align: center;
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const Heading = styled(HeadingBase)`
  margin-bottom: 42px;
  color: ${({ theme }) => theme.colors.gray[1]};
  text-align: center;

  @media only screen and (max-width: 1024px) {
    margin-top: 42px;
    margin-bottom: 28px;
  }
`;
