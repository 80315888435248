import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Divider = styled.hr`
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.gray[3]};
  margin: 0 0 24px 0;
`;

export const WebhookURLWrapper = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background[2]};
  border-radius: 5px;
  height: 42px;
  width: 100%;
  padding: 10px 12px;
  gap: 10px;
  border: unset;
  cursor: pointer;
  margin-top: 8px;
`;

export const WebhookURL = styled(TextBase)`
  max-width: 434px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const IconWrapper = styled.div`
  min-height: 18px;
  min-width: 18px;
`;

export const StepText = styled(TextBase)``;

export const TutorialTitle = styled(TextBase)`
  margin-bottom: 12px;
`;

export const TutorialStep = styled(TextBase)`
  margin-left: 8px;
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.green.default};
  cursor: pointer;
`;

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 8px 0 18px 32px;
  gap: 8px;
`;

export const StepWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const StepBullet = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[1]};
`;

export const DisableCredentialButton = styled(ButtonBase)``;

export const CredentialDescriptionText = styled(TextBase)``;

export const CredentialDescriptionTitle = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const CredentialDescriptionWrapper = styled.div`
  margin-bottom: 24px;
`;

export const Content = styled.div``;
