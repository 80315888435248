import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresPixPaidValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresPixPaidValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresPixPaidValuesResponse => {
  const currentSelectedStorePixPaidAmountValue =
    currentSelectedStoreFinancialDetailsReportData?.pix?.paid?.amount;

  const currentSelectedStorePixQuantityValue =
    currentSelectedStoreFinancialDetailsReportData?.pix?.paid?.quantity;

  const storesPixPaidAmountValues = selectedStoresData?.map(
    value => value.financial_details_report?.pix?.paid?.amount,
  );

  const storesPixQuantityValues = selectedStoresData?.map(
    value => value.financial_details_report?.pix?.paid?.quantity,
  );

  const pixAmountValues = [...storesPixPaidAmountValues, currentSelectedStorePixPaidAmountValue];

  const pixQuantityValues = [...storesPixQuantityValues, currentSelectedStorePixQuantityValue];

  const reducedPixPaidAmountValues = pixAmountValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const reducedPixQuantityValues = pixQuantityValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  return {
    pix_paid_amount: reducedPixPaidAmountValues,
    pix_paid_quantity: reducedPixQuantityValues,
  };
};
