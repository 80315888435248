import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  display: flex;
`;

export const LeftSide = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 20px;
  background: #14161a;

  @media only screen and (max-width: 1080px) {
    width: 100vw;
  }
`;

export const ContentWrapper = styled.div`
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 374px;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
`;
