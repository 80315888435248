import styled, { css } from 'styled-components/macro';

import { IInputWrapperProps } from '@domain/interfaces/dashboard/IntegrationCenter/SideModalContent/IIntegrateForm';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import LabelBase from '@components/common/core/DataDisplay/Label';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import ImageBase from '@components/common/core/DataDisplay/Image';

export const IntegrationArticleImage = styled(ImageBase)``;

export const ArticleLink = styled.a`
  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const InputWrapper = styled.div<IInputWrapperProps>`
  display: flex;

  ${({ readOnly, theme }) =>
    readOnly &&
    css`
      & > div:last-child {
        background-color: ${theme.colors.background[3]};
      }
    `}
`;

export const Input = styled(TextFieldBase)`
  width: 100%;

  & > div {
    border-radius: 5px 0 0 5px;
  }
`;

export const AppendContent = styled.div`
  display: block;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray[3]};
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0px 16px;
  background-color: ${({ theme }) => theme.colors.background.input};
  border-radius: 0 5px 5px 0;
`;

export const Label = styled(LabelBase)`
  font-weight: 700;
`;

export const TutorialText = styled(TextBase)`
  margin-bottom: 16px;

  & > strong {
    color: ${({ theme }) => theme.colors.green.default};
    font-weight: 400;
  }
`;

export const TutorialHeading = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const TutorialWrapper = styled.div`
  margin-bottom: 40px;
`;

export const FunctionalityDescriptionText = styled(TextBase)``;

export const FunctionalityDescriptionHeading = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const FunctionalityDescriptionWrapper = styled.div`
  margin-bottom: 48px;
`;

export const Heading = styled(HeadingBase)`
  margin-left: 10px;
`;

export const Header = styled.div`
  margin-bottom: 40px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
`;

export const ContentWrapper = styled.div`
  max-width: 487px;
  width: 100%;
`;
