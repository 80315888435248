import React from 'react';
import { useParams } from 'react-router-dom';

import { ECategory, EMessageType } from '@domain/enums/dashboard/automations/EMessage';
import { IParams } from '@domain/interfaces/IParams';
import { ICreateMessageData } from '@domain/interfaces/common/automations/IMessage';
import { ISavedContent } from '@domain/interfaces/common/automations/ISavedContent';
import { ICanvasMessageProvider } from '@domain/interfaces/common/automations/ICanvasMessage';

import { useToast } from '@helpers/hooks/useToast';
import { useCanvasMessageContent } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessageContent';
import { useCanvasMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessageSettings';

import messagesService from '@services/pages/dashboard/automations/messages';

const CanvasMessageContext = React.createContext<ICanvasMessageProvider | null>(null);

export const CanvasMessageProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { createMessageContents, updateMessageContents } = useCanvasMessageContent();
  const {
    messageToBeSearched,
    selectedCategoryOption,
    handleShouldCloseModal,
  } = useCanvasMessageSettings();

  const [isDeletingMessage, setIsDeletingMessage] = React.useState<boolean>(false);
  const [isCreatingMessage, setIsCreatingMessage] = React.useState<boolean>(false);
  const [isUpdatingMessage, setIsUpdatingMessage] = React.useState<boolean>(false);

  const {
    messages,
    isLoading: isLoadingMessages,
    isValidating: isValidatingMessages,
    error: messagesError,
    mutate: mutateMessages,
  } = messagesService.listMessages({
    storeAliasId,
    page: 0,
    filter: { title: messageToBeSearched, category: selectedCategoryOption },
  });

  const createMessage = React.useCallback(
    async (
      title: string,
      contents: Array<ISavedContent>,
      category: ECategory,
      type: EMessageType,
      isFromTemplate: boolean,
    ) => {
      setIsCreatingMessage(true);

      const data: ICreateMessageData = {
        title,
        category,
        type,
        is_from_template: isFromTemplate,
      };

      try {
        const { data: messageData } = await messagesService.createMessage({ storeAliasId, data });

        if (messageData) {
          await createMessageContents(messageData.message.alias_id, contents, type);
        }

        await mutateMessages();

        setIsCreatingMessage(false);

        return messageData.message;
      } catch (error: any) {
        setIsCreatingMessage(false);
        toast.error(error?.response?.data?.message);
      }

      return undefined;
    },
    [storeAliasId, toast, createMessageContents, mutateMessages],
  );

  const updateMessage = React.useCallback(
    async (
      messageAliasId: string,
      title: string,
      contents: Array<ISavedContent>,
      category: ECategory,
      type: EMessageType,
      isFromTemplate: boolean,
    ) => {
      setIsUpdatingMessage(true);

      const data = {
        title,
        category,
        type,
        is_from_template: isFromTemplate,
      };

      try {
        const { data: messageData } = await messagesService.updateMessage({
          storeAliasId,
          message_alias_id: messageAliasId,
          data,
        });

        if (messageData) {
          await updateMessageContents(messageAliasId, contents, type);
        }

        await mutateMessages();

        setIsUpdatingMessage(false);

        handleShouldCloseModal(true);

        return messageData.message;
      } catch (error: any) {
        setIsUpdatingMessage(false);
        toast.error(error?.response?.data?.message);
      }

      return undefined;
    },
    [storeAliasId, toast, updateMessageContents, handleShouldCloseModal, mutateMessages],
  );

  const deleteMessage = React.useCallback(
    async (messageAliasId: string) => {
      setIsDeletingMessage(true);

      try {
        await messagesService.deleteMessage({ storeAliasId, message_alias_id: messageAliasId });

        toast.success('Mensagem excluída com sucesso!');

        await mutateMessages();

        setIsDeletingMessage(false);
      } catch (error: any) {
        setIsDeletingMessage(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [mutateMessages, storeAliasId, toast],
  );

  return (
    <CanvasMessageContext.Provider
      value={{
        createMessage,
        deleteMessage,
        isCreatingMessage,
        isDeletingMessage,
        isLoadingMessages,
        isUpdatingMessage,
        isValidatingMessages,
        messages,
        messagesError,
        mutateMessages,
        updateMessage,
      }}
    >
      {children}
    </CanvasMessageContext.Provider>
  );
};

export const useCanvasMessage = (): ICanvasMessageProvider => {
  const context = React.useContext(CanvasMessageContext);

  if (!context) throw new Error('useCanvasMessage must be used within CanvasMessageProvider');

  return context;
};
