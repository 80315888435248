import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 100%;
  max-width: calc(1008px + 64px);
  display: flex;
  padding: 142px 32px;
  margin: 0 auto;
  box-sizing: border-box;
  gap: 96px;

  @media only screen and (max-width: 1292px) {
    flex-direction: column;
    max-width: calc(620px + 64px);
    gap: 28px;
  }

  @media only screen and (max-width: 472px) {
    width: 100%;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
`;

export const Heading = styled.div`
  width: 100%;
  max-width: 496px;
  margin-bottom: 36px;
`;

export const IntegrationTitle = styled.div`
  width: 100%;
  max-width: 215px;
  margin-bottom: 28px;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 24px;
`;

export const Label = styled.div``;

export const Input = styled.div`
  width: 100%;
  max-width: 408px;
`;

export const TutorialWrapper = styled.div`
  width: 100%;
  max-width: 416px;
  box-sizing: border-box;
`;
