import { ApiService } from '@services/api';
import { LONG_API_DOMAIN } from '@constants/api';
import { ICreateTaboolaCredentialProps } from '@domain/interfaces/dashboard/AdSense/Taboola/ITaboolaCredential';

export class TaboolaCredentialService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public createTaboolaCredential({
    data,
    storeAliasId,
  }: ICreateTaboolaCredentialProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/taboola/ad-sense-credentials`,
      { ...data },
    );
  }
}

const taboolaCredentialService = new TaboolaCredentialService(LONG_API_DOMAIN);

export default taboolaCredentialService;
