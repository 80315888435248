import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.background[1]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableHead = styled(TableBase.Head)`
  height: 49px;
`;
