import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresBoletoPendingValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresBoletoPendingValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresBoletoPendingValuesResponse => {
  const currentSelectedStoreBoletoPendingAmountValue =
    currentSelectedStoreFinancialDetailsReportData?.boleto?.pending?.amount;

  const currentSelectedStoreBoletoPendingQuantityValue =
    currentSelectedStoreFinancialDetailsReportData?.boleto?.pending?.quantity;

  const storesBoletoPendingAmountValues = selectedStoresData?.map(
    value => value.financial_details_report?.boleto?.pending?.amount,
  );

  const storesBoletoPendingQuantityValues = selectedStoresData?.map(
    value => value.financial_details_report?.boleto?.pending?.quantity,
  );

  const boletoPendingAmountValues = [
    ...storesBoletoPendingAmountValues,
    currentSelectedStoreBoletoPendingAmountValue,
  ];

  const boletoPendingQuantityValues = [
    ...storesBoletoPendingQuantityValues,
    currentSelectedStoreBoletoPendingQuantityValue,
  ];

  const reducedBoletoPendingAmountValues = boletoPendingAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedBoletoPendingQuantityValues = boletoPendingQuantityValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  return {
    boleto_pending_amount: reducedBoletoPendingAmountValues,
    boleto_pending_quantity: reducedBoletoPendingQuantityValues,
  };
};
