import styled, { css } from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import TextBase from '@components/common/core/DataDisplay/Text';
import DropDownBase from '@components/common/core/Utils/DropDown';
import { IActionsButtonProps } from '@domain/interfaces/dashboard/ProductCost/IActionsButton';
import { IActionButtonProps } from '@domain/interfaces/components/IActionButton';
import BadgeBase from '@components/common/core/DataDisplay/Badge';
import CustomTooltip from '@components/common/core/Utils/CustomTooltip';
import CustomPopover from '@components/common/core/Utils/CustomPopover';

export const SeeMoreVariantsWrapper = styled.div`
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Popover = styled(CustomPopover)``;

export const Tooltip = styled(CustomTooltip)``;

export const Badge = styled(BadgeBase)`
  margin: 0 auto;
`;

export const KitTable = styled(TableBase)``;

export const QuantityText = styled(TextBase)`
  display: inline-block;
  text-align: right;
  width: 100%;
`;

export const KitName = styled(TextBase)`
  max-width: 148px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
`;

export const FirstVariantName = styled(TextBase)`
  max-width: 302px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
`;

export const Text = styled(TextBase)``;

export const ItensWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SeeMoreItens = styled(TextBase)`
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.background[6]};
  cursor: pointer;
`;

export const TooltipItensText = styled(TextBase)`
  max-width: 300px;
  width: 100%;
  overflow: hidden;
  display: block;
`;

export const ActionsButton = styled.span<IActionsButtonProps>`
  width: 26px;
  height: 26px;
  align-items: center;
  justify-content: center;
  gap: 2px;
  opacity: 0;
  display: flex;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  border-radius: 50%;
  transition: filter 0.3s;
  border: unset;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  ${({ isHovering }) =>
    isHovering &&
    css`
      opacity: 1;
    `}
`;

export const Dot = styled.span`
  display: block;
  width: 4px;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.gray[2]};
  border-radius: 50%;
`;

export const AccessibleActionsButtonName = styled.span`
  position: absolute;
  opacity: 0;
  width: 0;
`;

export const ActionsWrapper = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
`;

export const ActionButton = styled.button<IActionButtonProps>`
  border: none;
  background-color: transparent;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.font};
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;

  ${({ isDeleteButton, theme }) =>
    isDeleteButton &&
    css`
      color: ${theme.colors.danger.default};
    `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[6]};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.gray[6]};
    filter: brightness(0.9);
  }
`;

export const MenuDropDown = styled(DropDownBase)``;

export const RevenueValue = styled(TextBase)`
  font-weight: 700;
  font-size: 0.875rem;
  display: flex;
  justify-content: flex-end;
`;
