import React from 'react';
import { HandManual, IIconProps } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { EAdSenseProvider } from '@domain/enums/common/EAdSense';

import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';

import { parseMarketings } from '@helpers/utils/dashboard/financialDashboard/financialDashboard';

import { ADS_PROVIDERS } from '@constants/common/integrations';

import * as S from './styles';

const MarketingInfo: React.FC = () => {
  const theme = useTheme();
  const { financialReport } = useFinancialDashboard();

  const { marketing } = financialReport;

  const parsedGateways = parseMarketings(marketing);

  const filteredMarketings = parsedGateways.filter(integration => integration.amount > 0);

  const reversedFilteredFinancialReport = filteredMarketings.reverse();

  const maxIntegrationsOfDisplay = reversedFilteredFinancialReport.slice(0, 3);

  const moreThanTwoMarketingIntegrationAmount = maxIntegrationsOfDisplay.map(
    (filteredMarketing, index) => {
      if (index === 0)
        return (
          <S.ExtraMarketingIcon key={filteredMarketing.provider}>{`+${
            filteredMarketings.length - 2
          }`}</S.ExtraMarketingIcon>
        );

      const provider = filteredMarketing.provider as EAdSenseProvider | 'MANUAL';

      if (provider !== 'MANUAL') {
        const foundMarketing = ADS_PROVIDERS.find(
          integration => integration.identifier === filteredMarketing.provider,
        );

        const Icon = foundMarketing?.icon as React.FC<IIconProps>;

        return (
          <S.MarketingIcon key={filteredMarketing.provider}>
            <Icon size={24} />
          </S.MarketingIcon>
        );
      }

      return (
        <S.ManualMarketingIcon key="MANUAL">
          <HandManual size={14} color={theme.colors.font} />
        </S.ManualMarketingIcon>
      );
    },
  );

  const integrationsAmount = reversedFilteredFinancialReport.map(integration => {
    const provider = integration.provider as EAdSenseProvider | 'MANUAL';

    if (provider !== 'MANUAL') {
      const foundMarketing = ADS_PROVIDERS.find(
        marketingIntegration => marketingIntegration.identifier === integration.provider,
      );

      const Icon = foundMarketing?.icon as React.FC<IIconProps>;

      return (
        <S.MarketingIcon key={integration.provider}>
          <Icon size={24} />
        </S.MarketingIcon>
      );
    }

    return (
      <S.ManualMarketingIcon key="MANUAL">
        <HandManual size={14} color={theme.colors.font} />
      </S.ManualMarketingIcon>
    );
  });

  return (
    <S.Wrapper>
      {reversedFilteredFinancialReport.length > 2
        ? moreThanTwoMarketingIntegrationAmount
        : integrationsAmount}
    </S.Wrapper>
  );
};

export default MarketingInfo;
