import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { EHeadAlign } from '@domain/enums/components/ETable';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <>
      <S.ProductCostTable.Row borderBottom>
        <S.ProductCostTable.Data>
          <S.ProductDetailsWrapper>
            <Skeleton width={48} height={48} style={{ borderRadius: '50%' }} />
            <Skeleton width={120} height={27} />
          </S.ProductDetailsWrapper>
        </S.ProductCostTable.Data>

        <S.ProductCostTable.Data>
          <S.ProviderWrapper>
            <Skeleton width={36} height={36} style={{ borderRadius: 36 }} />
          </S.ProviderWrapper>
        </S.ProductCostTable.Data>

        <S.ProductCostTable.Data>
          <Skeleton width={36} height={18} />
        </S.ProductCostTable.Data>

        <S.ProductCostTable.Data align={EHeadAlign.CENTER}>
          <Skeleton width={36} height={18} />
        </S.ProductCostTable.Data>
      </S.ProductCostTable.Row>

      <S.ProductCostTable.Row borderBottom>
        <S.ProductCostTable.Data>
          <S.ProductDetailsWrapper>
            <Skeleton width={48} height={48} style={{ borderRadius: '50%' }} />
            <Skeleton width={120} height={27} />
          </S.ProductDetailsWrapper>
        </S.ProductCostTable.Data>

        <S.ProductCostTable.Data>
          <S.ProviderWrapper>
            <Skeleton width={36} height={36} style={{ borderRadius: 36 }} />
          </S.ProviderWrapper>
        </S.ProductCostTable.Data>

        <S.ProductCostTable.Data>
          <Skeleton width={36} height={18} />
        </S.ProductCostTable.Data>

        <S.ProductCostTable.Data align={EHeadAlign.CENTER}>
          <Skeleton width={36} height={18} />
        </S.ProductCostTable.Data>
      </S.ProductCostTable.Row>

      <S.ProductCostTable.Row borderBottom>
        <S.ProductCostTable.Data>
          <S.ProductDetailsWrapper>
            <Skeleton width={48} height={48} style={{ borderRadius: '50%' }} />
            <Skeleton width={120} height={27} />
          </S.ProductDetailsWrapper>
        </S.ProductCostTable.Data>

        <S.ProductCostTable.Data>
          <S.ProviderWrapper>
            <Skeleton width={36} height={36} style={{ borderRadius: 36 }} />
          </S.ProviderWrapper>
        </S.ProductCostTable.Data>

        <S.ProductCostTable.Data>
          <Skeleton width={36} height={18} />
        </S.ProductCostTable.Data>

        <S.ProductCostTable.Data align={EHeadAlign.CENTER}>
          <Skeleton width={36} height={18} />
        </S.ProductCostTable.Data>
      </S.ProductCostTable.Row>

      <S.ProductCostTable.Row borderBottom>
        <S.ProductCostTable.Data>
          <S.ProductDetailsWrapper>
            <Skeleton width={48} height={48} style={{ borderRadius: '50%' }} />
            <Skeleton width={120} height={27} />
          </S.ProductDetailsWrapper>
        </S.ProductCostTable.Data>

        <S.ProductCostTable.Data>
          <S.ProviderWrapper>
            <Skeleton width={36} height={36} style={{ borderRadius: 36 }} />
          </S.ProviderWrapper>
        </S.ProductCostTable.Data>

        <S.ProductCostTable.Data>
          <Skeleton width={36} height={18} />
        </S.ProductCostTable.Data>

        <S.ProductCostTable.Data align={EHeadAlign.CENTER}>
          <Skeleton width={36} height={18} />
        </S.ProductCostTable.Data>
      </S.ProductCostTable.Row>

      <S.ProductCostTable.Row>
        <S.ProductCostTable.Data>
          <S.ProductDetailsWrapper>
            <Skeleton width={48} height={48} style={{ borderRadius: '50%' }} />
            <Skeleton width={120} height={27} />
          </S.ProductDetailsWrapper>
        </S.ProductCostTable.Data>

        <S.ProductCostTable.Data>
          <S.ProviderWrapper>
            <Skeleton width={36} height={36} style={{ borderRadius: 36 }} />
          </S.ProviderWrapper>
        </S.ProductCostTable.Data>

        <S.ProductCostTable.Data>
          <Skeleton width={36} height={18} />
        </S.ProductCostTable.Data>

        <S.ProductCostTable.Data align={EHeadAlign.CENTER}>
          <Skeleton width={36} height={18} />
        </S.ProductCostTable.Data>
      </S.ProductCostTable.Row>
    </>
  );
};

export default SkeletonLoading;
