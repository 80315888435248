import React from 'react';
import { Check } from '@profitfy/pakkun-icons';

import * as S from './styles';

const VerifyScript: React.FC = () => {
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      window.open('about:blank', '_self');
      window.close();
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <S.Wrapper>
      <S.Content>
        <Check size={32} />
        <S.ContentText>Verificação de instalação da profitfy realizada com sucesso!</S.ContentText>
      </S.Content>
    </S.Wrapper>
  );
};

export default VerifyScript;
