import React from 'react';

import { useLocation } from 'react-router-dom';
import { InfoProductProvider } from '@helpers/hooks/pages/onboarding/useInfoProduct';

import Route from '@components/routes/Route';
import Integrations from '@pages/Onboarding/InfoProduct/Integrations';
import Platforms from '@pages/Onboarding/InfoProduct/Platforms';
import Marketing from '@pages/Onboarding/InfoProduct/Marketing';

const InfoProduct: React.FC = () => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location]);

  return (
    <InfoProductProvider>
      <Route
        isPrivate
        exact
        path="/onboarding/:storeAliasId/info-product/integrations"
        component={Integrations}
      />
      <Route
        isPrivate
        exact
        path="/onboarding/:storeAliasId/info-product/integrations/platforms"
        component={Platforms}
      />
      <Route
        isPrivate
        exact
        path="/onboarding/:storeAliasId/info-product/integrations/marketing"
        component={Marketing}
      />
    </InfoProductProvider>
  );
};

export default React.memo(InfoProduct);
