import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/CustomModal';
import TextBase from '@components/common/core/DataDisplay/Text';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import ButtonBase from '@components/common/core/Inputs/Button';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';

export const Wrapper = styled(ModalBase)`
  width: 100%;
  max-width: 528px;
  height: auto;
  padding: 32px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 32px;
  box-sizing: border-box;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  box-sizing: border-box;
`;

export const GroupTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled(TextBase)`
  font-size: 1.25rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
  margin-left: 8px;
`;

export const SubTitle = styled(TextBase)`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
  margin-top: 15px;
`;

export const CloseButton = styled.button`
  background: unset;
  padding: unset;
  border: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Description = styled(TextBase)`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[3]};
  margin-bottom: 27px;
`;

export const TextField = styled(TextFieldBase)`
  width: 100%;
  max-width: 227px;
`;

export const Select = styled(SelectBase)`
  width: 100%;
  max-width: 227px;
`;

export const Option = styled(OptionBase)``;

export const SaveButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 27px 32px 0 32px;
  width: 100%;
  box-sizing: border-box;
`;

export const SaveButton = styled(ButtonBase)`
  width: 100%;
`;
