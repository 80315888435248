import React from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { Feature } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { IParams } from '@domain/interfaces/IParams';

import { useConfig } from '@helpers/hooks/useConfig';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useToast } from '@helpers/hooks/useToast';

import storesService from '@services/pages/stores/stores';

import * as S from './styles';

const Welcome: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { toast } = useToast();
  const { analytics } = useConfig();
  const { storeAliasId } = useParams<IParams>();
  const { mutateStore, store } = useStoreConfig();

  const [isFinishingOnboarding, setIsFinishingOnboarding] = React.useState<boolean>(false);

  const finishOnboarding = React.useCallback(async () => {
    analytics?.track(
      'First Automation Page Button Clicked',
      {},
      {
        context: { groupId: storeAliasId },
      },
    );

    setIsFinishingOnboarding(true);

    const data = {
      ...store,
      is_automation_onboarding_finished: true,
    };

    try {
      await storesService.updateStore(store.alias_id, data);

      await mutateStore();

      setIsFinishingOnboarding(false);
    } catch (error: any) {
      setIsFinishingOnboarding(false);
      toast.error(error?.response?.data?.message);
    }

    history.push(`/${storeAliasId}/dashboard/marketing/automations/templates`);
  }, [history, storeAliasId, analytics, mutateStore, store, toast]);

  return (
    <S.ContentWrapper>
      <S.OnboardingImage
        src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/automations/automations_onboarding.mp4"
        autoPlay
        muted
        loop
      />

      <S.Content>
        <S.Heading>
          Multiplique as suas vendas com a <mark>automação de mensagens</mark> da profitfy!
        </S.Heading>

        <S.BenefitsWrapper>
          <S.Benefit>
            <S.IconWrapper>
              <Feature size={12} color={theme.colors.green.default} />
            </S.IconWrapper>
            <S.BenefitText>Conecte vários números de Whatsapp.</S.BenefitText>
          </S.Benefit>

          <S.Benefit>
            <S.IconWrapper>
              <Feature size={12} color={theme.colors.green.default} />
            </S.IconWrapper>
            <S.BenefitText>Rotacione os números para evitar bloqueios.</S.BenefitText>
          </S.Benefit>

          <S.Benefit>
            <S.IconWrapper>
              <Feature size={12} color={theme.colors.green.default} />
            </S.IconWrapper>
            <S.BenefitText>Disparo de mensagens ilimitadas.</S.BenefitText>
          </S.Benefit>

          <S.Benefit>
            <S.IconWrapper>
              <Feature size={12} color={theme.colors.green.default} />
            </S.IconWrapper>
            <S.BenefitText>Templates de automações prontos.</S.BenefitText>
          </S.Benefit>
        </S.BenefitsWrapper>

        <S.CreateNewInstanceButton isLoading={isFinishingOnboarding} onClick={finishOnboarding}>
          Quero experimentar
        </S.CreateNewInstanceButton>
      </S.Content>
    </S.ContentWrapper>
  );
};

export default Welcome;
