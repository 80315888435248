import React from 'react';

import { EHeadingSize } from '@domain/enums/components/EHeading';

import { useMediaQuery } from '@helpers/hooks/useMediaQuery';
import { useConfig } from '@helpers/hooks/useConfig';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import * as S from './styles';

const SecondarySection: React.FC = () => {
  const { analytics } = useConfig();
  const { store } = useStoreConfig();
  const mobileWidth = useMediaQuery('1048px');

  const onActivateBenefitClick = React.useCallback(() => {
    analytics?.track(
      'Activate Benefit Button Clicked',
      { partner: 'Pejota.Plus' },
      { context: { groupId: store?.alias_id } },
    );
  }, [analytics, store]);

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title type={EHeadingSize.H3}>
          a pjrocks! <br /> <span>é uma empresa de contabilidade 100% focada no digital.</span>
        </S.Title>

        <S.Description>
          Integre sua contabilidade com profissionais que realmente dominam o ambiente digital,{' '}
          exclusivamente, no âmbito do Dropshipping, que até então, é um cenário nebuloso.
        </S.Description>

        {mobileWidth && (
          <S.Link
            href="https://api.whatsapp.com/send?phone=5511987155948&text=Quero%20descomplicar%20minha%20contabilidade"
            target="_blank"
            onClick={onActivateBenefitClick}
          >
            <S.ActivateBenefitButton>Ativar meu benefício</S.ActivateBenefitButton>
          </S.Link>
        )}

        <S.FeaturesWrapper>
          <S.Feature>
            <S.FeatureTitle type={EHeadingSize.H6}>
              abertura de cnpj <br /> 100% gratuita
            </S.FeatureTitle>

            <S.FeatureDescription>
              Em parceria com a Profitfy, estamos <br /> te{' '}
              <strong>
                oferendo a abertura gratuita do <br /> seu CNPJ
              </strong>
              , para que você possa <br /> trabalhar com segurança <br /> e baixo custo.
            </S.FeatureDescription>
          </S.Feature>

          <S.Feature>
            <S.FeatureTitle type={EHeadingSize.H6}>
              Consultoria inicial <br /> gratuita!
            </S.FeatureTitle>

            <S.FeatureDescription>
              <strong>Consultoria proativa</strong> para te <br /> ajudar a manter a regularidade{' '}
              <br /> fiscal e contábil, desenvolvendo um <br /> forte entendimento de suas metas{' '}
              <br /> e aspirações comerciais.
            </S.FeatureDescription>
          </S.Feature>

          <S.Feature>
            <S.FeatureTitle type={EHeadingSize.H6}>
              Tecnologia + <br /> Humanização
            </S.FeatureTitle>

            <S.FeatureDescription>
              Quebramos o paradigma do <br /> contador (chato e tradicional) e <br />
              <strong>
                humanizamos a contabilidade <br /> on-line
              </strong>
              , que para nós, é sobre <br /> relacionamento e menos sobre <br />
              auto-atendimento.
            </S.FeatureDescription>
          </S.Feature>
        </S.FeaturesWrapper>

        {!mobileWidth && (
          <S.Link
            href="https://api.whatsapp.com/send?phone=5511987155948&text=Quero%20descomplicar%20minha%20contabilidade"
            target="_blank"
            onClick={onActivateBenefitClick}
          >
            <S.ActivateBenefitButton>Ativar meu benefício</S.ActivateBenefitButton>
          </S.Link>
        )}
      </S.Content>
    </S.Wrapper>
  );
};

export default SecondarySection;
