import * as yup from 'yup';

export const firstStepPixBoletoSchema = yup.object().shape({
  order_value: yup.string().required('É necessário informar o valor do pedido.'),
  order_status: yup.string().required('É necessário informar o status do pedido.'),
  product: yup.string().required('É necessário informar o produto.'),
  quantity: yup.string().required('É necessário a quantidade.'),
});

export const firstStepAbadonRejectedSchema = yup.object().shape({
  order_value: yup.string().required('É necessário informar o valor do pedido.'),
  product: yup.string().required('É necessário informar o produto.'),
  quantity: yup.string().required('É necessário a quantidade.'),
});

export const secondStepSchema = yup.object().shape({
  first_name: yup
    .string()
    .matches(/^[a-zA-Z]*$/, 'Este campo deve conter apenas o primeiro nome.')
    .min(2, 'O nome precisa conter no mínimo 2 letras.')
    .max(30, 'O nome pode conter no máximo 30 letras.'),
  last_name: yup
    .string()
    .matches(/^[a-zA-Z ]*$/, 'Este campo deve conter apenas o sobrenome')
    .min(2, 'O sobrenome precisa conter no mínimo 2 letras.')
    .max(50, 'O sobrenome pode conter no máximo 50 letras'),
  email: yup
    .string()
    .email('Você precisa inserir um e-mail válido.')
    .required('Este campo é obrigatório.'),
  phone: yup
    .string()
    .required('É necessário informar um telefone.')
    .min(6, 'Este campo deve conter no mínimo 6 caracteres'),
});

export const thirdStepSchema = yup.object().shape({
  zip_code: yup
    .string()
    .required('Este campo é obrigatório.')
    .test('zip code', 'Informe um CEP válido.', value => {
      if (value?.length === 9) return true;

      return false;
    }),
  street: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'Informe um endereço válido.')
    .max(80, 'O número máximo de carácteres foi atingido.'),
  street_number: yup.string().required('Este campo é obrigatório.'),
  complement: yup.string().max(200, 'O número máximo de carácteres foi atingido.'),
  neighborhood: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'Informe um bairro válido.')
    .max(60, 'O número máximo de carácteres foi atingido.'),
  city: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'Informe uma cidade válida.')
    .max(60, 'O número máximo de carácteres foi atingido.'),
});
