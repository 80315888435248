import styled, { css } from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import TextBase from '@components/common/core/DataDisplay/Text';
import {
  IDataTextProps,
  IPercentageProps,
  INetProfitProps,
} from '@domain/interfaces/dashboard/FinancialDetails/IDailyTable';

export const PercentageArrow = styled.div<IPercentageProps>`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid ${({ theme }) => theme.colors.danger.default};
  margin-left: 8px;

  ${({ isPositive, theme }) =>
    isPositive &&
    css`
      border-top: 0;
      border-bottom: 6px solid ${theme.colors.success.default};
    `};
`;

export const NetProfitText = styled(TextBase)<INetProfitProps>`
  color: ${({ theme }) => theme.colors.danger.default};
  line-height: 120%;
  display: flex;
  align-items: center;
  height: 20px;
  font-size: 1rem;
  font-weight: 700;
  justify-content: flex-end;

  ${({ isPositive, theme }) =>
    isPositive &&
    css`
      color: ${theme.colors.success.default};
    `}
`;

export const DateText = styled(TextBase)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[2]};
  line-height: 120%;
  display: flex;
  align-items: center;
`;

export const DataText = styled(TextBase)<IDataTextProps>`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[1]};
  line-height: 120%;
  display: flex;
  align-items: center;
  height: 20px;

  ${({ highlight, theme }) =>
    highlight &&
    css`
      font-size: 1rem;
      color: ${theme.colors.white.default};
      font-weight: 700;
    `}

  ${({ right }) =>
    right &&
    css`
      justify-content: flex-end;
    `}
`;

export const Table = styled(TableBase)``;
