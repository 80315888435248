import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 100%;
  height: 36px;
  background-color: ${({ theme }) => theme.colors.background[8]};
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 5px;
`;
