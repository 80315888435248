import React from 'react';
import { Feature } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import {
  ESynchronizationRequestType,
  ESynchronizationType,
} from '@domain/enums/hooks/ESynchronization';

import { LONG_API_DOMAIN } from '@constants/api';

import { getEnvironment } from '@helpers/utils/environment';
import { getSynchronizationStatus } from '@helpers/utils/common/synchronization';

import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useConfig } from '@helpers/hooks/useConfig';
import { useHome } from '@helpers/hooks/pages/dashboard/home/useHome';

import * as S from './styles';

const ADS_MANAGER_SYNC_NAME = 'Gerenciador de Anúncios';

const Onboarding: React.FC = () => {
  const theme = useTheme();
  const {
    addSynchronization,
    currentSynchronizations,
    synchronizationsQueue,
  } = useSynchronization();
  const { storeAliasId } = useParams<IParams>();
  const { analytics } = useConfig();
  const { mutateHomeSetup } = useHome();

  const completeOnboarding = React.useCallback(async () => {
    analytics?.track(
      'Ads Manager Onboarding Button Clicked',
      {},
      { context: { groupId: storeAliasId } },
    );

    addSynchronization({
      name: ADS_MANAGER_SYNC_NAME,
      type: ESynchronizationType.ADS_MANAGER_DATA,
      label: 'Dados',
      dependencies: [],
      storeAliasId,
      config: {
        isOnboardingSynchronization: false,
        showNotification: true,
      },
      request: {
        type: ESynchronizationRequestType.POST,
        domain: LONG_API_DOMAIN,
        api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/ads-manager/data/sync`,
      },
    });

    mutateHomeSetup();
  }, [addSynchronization, storeAliasId, analytics, mutateHomeSetup]);

  React.useEffect(() => {
    analytics?.track('Ads Manager Onboarding Viewed', {}, { context: { groupId: storeAliasId } });
  }, [analytics, storeAliasId]);

  const isSyncing = getSynchronizationStatus(
    currentSynchronizations,
    synchronizationsQueue,
    ADS_MANAGER_SYNC_NAME,
    ESynchronizationType.ADS_MANAGER_DATA,
    storeAliasId,
  );

  return (
    <S.Wrapper>
      <S.Content>
        <S.OnboardingImage
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/ads-manager/onboarding/onboarding/GIF+ADS+MANAGER.mp4"
          autoPlay
          muted
          loop
        />

        <S.DescriptionWrapper>
          <S.Title>
            Todas campanhas <strong>centralizadas.</strong>
            <br />
            Ganhe tempo, <strong>reduza trabalho.</strong>
          </S.Title>

          <S.FeaturesWrapper>
            <S.Feature>
              <S.IconWrapper>
                <Feature size={12} color={theme.colors.green.default} />
              </S.IconWrapper>
              <S.FeatureText>Veja seu lucro líquido por camapanha</S.FeatureText>
            </S.Feature>
            <S.Feature>
              <S.IconWrapper>
                <Feature size={12} color={theme.colors.green.default} />
              </S.IconWrapper>
              <S.FeatureText>Evite check-in points</S.FeatureText>
            </S.Feature>
            <S.Feature>
              <S.IconWrapper>
                <Feature size={12} color={theme.colors.green.default} />
              </S.IconWrapper>
              <S.FeatureText>Dados financeiros cruzados com de Ads</S.FeatureText>
            </S.Feature>
            <S.Feature>
              <S.IconWrapper>
                <Feature size={12} color={theme.colors.green.default} />
              </S.IconWrapper>
              <S.FeatureText>Projeção de lucro líquido.</S.FeatureText>
            </S.Feature>
          </S.FeaturesWrapper>

          <S.StartNowButton onClick={completeOnboarding} isLoading={isSyncing}>
            Testar agora!
          </S.StartNowButton>
        </S.DescriptionWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

export default Onboarding;
