import React from 'react';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { ITableColumn } from '@domain/interfaces/dashboard/AdsManager/IAdsManager';

export const getInitialColumns = (
  savedColumns: Array<ITableColumn>,
  defaultColumns: Array<ITableColumn>,
): Array<ITableColumn> => {
  const mappedColumns = defaultColumns.map(defaultColumn => {
    const foundSavedColumn = savedColumns.find(savedColumn => savedColumn.id === defaultColumn.id);

    if (foundSavedColumn) return foundSavedColumn;

    return defaultColumn;
  });

  return mappedColumns;
};

export const columnsWithRef = (columns: Array<ITableColumn>): Array<ITableColumn> => {
  const newColumns = columns.map(column => ({
    ...column,
    ref: React.useRef<HTMLTableCellElement>(null),
  }));

  return newColumns;
};

export const useDefaultTableColumns = (): Array<ITableColumn> => {
  return [
    {
      id: 'checkbox',
      content: '',
      isActive: true,
      contentAlign: EHeadAlign.CENTER,
      isOptional: false,
      isResizable: false,
      minWidth: 66,
      isSortable: false,
      isUtmColumn: false,
    },
    {
      id: 'is_active',
      content: '',
      isActive: true,
      contentAlign: EHeadAlign.CENTER,
      isOptional: false,
      isResizable: false,
      minWidth: 70,
      isSortable: false,
      isUtmColumn: false,
    },
    {
      id: 'ad_name',
      content: 'Nome dos Anúncios',
      isActive: true,
      contentAlign: EHeadAlign.LEFT,
      isOptional: false,
      isResizable: true,
      minWidth: 364,
      isSortable: true,
      isUtmColumn: false,
    },
    {
      id: 'insights',
      content: 'Insights',
      isActive: true,
      contentAlign: EHeadAlign.LEFT,
      isOptional: true,
      isResizable: true,
      minWidth: 116,
      isSortable: true,
      isUtmColumn: false,
    },
    {
      id: 'status',
      content: 'Veiculação',
      isActive: true,
      contentAlign: EHeadAlign.LEFT,
      isOptional: true,
      isResizable: true,
      minWidth: 132,
      isSortable: true,
      isUtmColumn: false,
    },
    {
      id: 'budget',
      content: 'Orçamento',
      isActive: true,
      contentAlign: EHeadAlign.RIGHT,
      isOptional: true,
      isResizable: true,
      minWidth: 136,
      isSortable: true,
      isUtmColumn: false,
    },
    {
      id: 'net_revenue',
      content: 'Receita Líquida',
      isActive: true,
      contentAlign: EHeadAlign.RIGHT,
      isOptional: true,
      isResizable: true,
      minWidth: 204,
      isSortable: true,
      isUtmColumn: true,
    },
    {
      id: 'spend_amount',
      content: 'Valor Gasto',
      isActive: true,
      contentAlign: EHeadAlign.RIGHT,
      isOptional: true,
      isResizable: true,
      minWidth: 142,
      isSortable: true,
      isUtmColumn: false,
    },
    {
      id: 'cogs',
      content: 'COGS',
      isActive: true,
      contentAlign: EHeadAlign.RIGHT,
      isOptional: true,
      isResizable: true,
      minWidth: 140,
      isSortable: true,
      isUtmColumn: true,
    },
    {
      id: 'profit',
      content: 'Lucro',
      isActive: true,
      contentAlign: EHeadAlign.RIGHT,
      isOptional: true,
      isResizable: true,
      minWidth: 140,
      isSortable: true,
      isUtmColumn: true,
    },
    {
      id: 'roi',
      content: 'ROI',
      isActive: true,
      contentAlign: EHeadAlign.RIGHT,
      isOptional: true,
      isResizable: true,
      minWidth: 132,
      isSortable: true,
      isUtmColumn: true,
    },
    {
      id: 'roas',
      content: 'ROAS',
      isActive: true,
      contentAlign: EHeadAlign.RIGHT,
      isOptional: true,
      isResizable: true,
      minWidth: 112,
      isSortable: true,
      isUtmColumn: false,
    },
    {
      id: 'cpa',
      content: 'CPA',
      isActive: true,
      contentAlign: EHeadAlign.RIGHT,
      isOptional: true,
      isResizable: true,
      minWidth: 112,
      isSortable: true,
      isUtmColumn: false,
    },
    {
      id: 'cpa_utm',
      content: 'CPA',
      isActive: true,
      contentAlign: EHeadAlign.RIGHT,
      isOptional: true,
      isResizable: true,
      minWidth: 136,
      isSortable: true,
      isUtmColumn: true,
    },
    {
      id: 'prints',
      content: 'Impressões',
      isActive: true,
      contentAlign: EHeadAlign.RIGHT,
      isOptional: true,
      isResizable: true,
      minWidth: 144,
      isSortable: true,
      isUtmColumn: false,
    },
    {
      id: 'ctr',
      content: 'CTR',
      isActive: true,
      contentAlign: EHeadAlign.RIGHT,
      isOptional: true,
      isResizable: true,
      minWidth: 102,
      isSortable: true,
      isUtmColumn: false,
    },
    {
      id: 'cpc',
      content: 'CPC',
      isActive: true,
      contentAlign: EHeadAlign.RIGHT,
      isOptional: true,
      isResizable: true,
      minWidth: 112,
      isSortable: true,
      isUtmColumn: false,
    },
    {
      id: 'cpm',
      content: 'CPM',
      isActive: true,
      contentAlign: EHeadAlign.RIGHT,
      isOptional: true,
      isResizable: true,
      minWidth: 112,
      isSortable: true,
      isUtmColumn: false,
    },
    {
      id: 'credit_card',
      content: 'Cartão de Crédito',
      isActive: true,
      contentAlign: EHeadAlign.LEFT,
      isOptional: true,
      isResizable: true,
      minWidth: 220,
      isSortable: true,
      isUtmColumn: true,
    },
    {
      id: 'boleto',
      content: 'Boletos',
      isActive: true,
      contentAlign: EHeadAlign.LEFT,
      isOptional: true,
      isResizable: true,
      minWidth: 184,
      isSortable: true,
      isUtmColumn: true,
    },
    {
      id: 'boleto_conversion',
      content: 'Conversão de Boletos',
      isActive: true,
      contentAlign: EHeadAlign.LEFT,
      isOptional: true,
      isResizable: true,
      minWidth: 240,
      isSortable: true,
      isUtmColumn: true,
    },
    {
      id: 'pix',
      content: 'Pix',
      isActive: true,
      contentAlign: EHeadAlign.LEFT,
      isOptional: true,
      isResizable: true,
      minWidth: 184,
      isSortable: true,
      isUtmColumn: true,
    },
    {
      id: 'pix_conversion',
      content: 'Conversão de Pix',
      isActive: true,
      contentAlign: EHeadAlign.LEFT,
      isOptional: true,
      isResizable: true,
      minWidth: 240,
      isSortable: true,
      isUtmColumn: true,
    },
    {
      id: 'orders',
      content: 'Pedidos',
      isActive: true,
      contentAlign: EHeadAlign.CENTER,
      isOptional: true,
      isResizable: true,
      minWidth: 176,
      isSortable: true,
      isUtmColumn: true,
    },
  ];
};

export const DEFAULT_COLUMNS_SIZE = {
  checkbox: 66,
  is_active: 70,
  ad_name: 364,
  insights: 116,
  status: 132,
  budget: 136,
  net_revenue: 204,
  spend_amount: 142,
  cogs: 140,
  profit: 140,
  roi: 132,
  roas: 112,
  cpa: 112,
  cpa_utm: 136,
  credit_card: 220,
  boleto: 184,
  boleto_conversion: 240,
  pix: 184,
  pix_conversion: 240,
  prints: 144,
  ctr: 102,
  cpc: 112,
  cpm: 112,
  orders: 176,
};
