import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { EYampiType } from '@domain/enums/common/integrations/providers/EYampi';
import { EButtonVariant } from '@domain/enums/components/EButton';
import { EHeadingSize } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';
import { IYampiCredentialOptionProps } from '@domain/interfaces/dashboard/IntegrationCenter/SideModalContent/StoreProvider/IYampiCredentialOption';

import { updateYampiSchema } from '@helpers/validators/dashboard/integrationCenter/yampi';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useToast } from '@helpers/hooks/useToast';

import yampiAuthService from '@services/pages/dashboard/integrationCenter/storeProvider/yampi/yampiAuth';

import Text from '@components/common/core/DataDisplay/Text';
import Form from '@components/common/core/Inputs/Form';

import DeleteCredentialModal from '@components/Dashboard/IntegrationCenter/SideModalContent/DeleteCredentialModal';
import * as S from './styles';

const CredentialOption: React.FC<IYampiCredentialOptionProps> = ({ yampiCredential, mutate }) => {
  const { mutateStore } = useStoreConfig();
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();

  const [isDisablingCredential, setIsDisablingCredential] = React.useState<boolean>(false);
  const [isUpdatingCredential, setIsUpdatingCredential] = React.useState<boolean>(false);
  const [isDeleteCredentialModalOpen, setIsDeleteCredentialModalOpen] = React.useState<boolean>(
    false,
  );

  const handleDeleteCredentialModalOpen = React.useCallback(
    () => setIsDeleteCredentialModalOpen(state => !state),
    [],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(updateYampiSchema),
  });

  const onDeleteCredential = React.useCallback(async () => {
    setIsDisablingCredential(true);

    try {
      const newTypes = yampiCredential.types.filter(type => type !== EYampiType.STORE);

      const data = {
        types: newTypes,
      };

      await yampiAuthService.disableYampiCredential({
        storeAliasId,
        yampiCredentialAliasId: yampiCredential.alias_id,
        data,
      });

      toast.success('Integração desativada com sucesso!');

      setIsDisablingCredential(false);
      handleDeleteCredentialModalOpen();

      mutate();
    } catch (error: any) {
      setIsDisablingCredential(false);
      toast.error(error?.response?.data?.message);
    }
  }, [yampiCredential, storeAliasId, toast, handleDeleteCredentialModalOpen, mutate]);

  const onSubmit = React.useCallback(
    async formData => {
      setIsUpdatingCredential(true);

      try {
        await yampiAuthService.updateYampiCredential({
          storeAliasId,
          yampiCredentialAliasId: yampiCredential.alias_id,
          data: {
            access_token: formData.access_token.includes('**') ? undefined : formData.access_token,
            secret_key: formData.secret_key.includes('**') ? undefined : formData.secret_key,
            types: yampiCredential.types,
          },
        });

        mutateStore();

        toast.success('Credencial atualizada com sucesso.');

        setIsUpdatingCredential(false);
      } catch (error: any) {
        setIsUpdatingCredential(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [mutateStore, storeAliasId, toast, yampiCredential],
  );

  return (
    <S.Content>
      <S.CredentialDescriptionWrapper>
        <S.CredentialDescriptionTitle type={EHeadingSize.H5}>
          O que acontece se eu desativar minha integração?
        </S.CredentialDescriptionTitle>

        <S.CredentialDescriptionText>
          Ao desativar a integração,
          <strong> não será mais possível </strong>
          processar os pedidos da Yampi Loja.
        </S.CredentialDescriptionText>
      </S.CredentialDescriptionWrapper>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.UpdateCredentialHeading type={EHeadingSize.H5}>
          Atualizar Credencial
        </S.UpdateCredentialHeading>

        <S.InputGroup>
          <S.Label htmlFor="yampi-access-token">Token de Acesso da API Admin</S.Label>

          <S.Input
            {...register('access_token')}
            type="text"
            id="yampi-access-token"
            placeholder="Token de Acesso da API Admin"
            defaultValue="**************************"
          />
          {errors.access_token && <Text isErrorFeedback>{errors.access_token.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label htmlFor="yampi-secret-key">Chave secreta da API</S.Label>

          <S.Input
            {...register('secret_key')}
            type="text"
            id="yampi-secret-key"
            placeholder="Chave secreta da API"
            defaultValue="**************************"
          />
          {errors.secret_key && <Text isErrorFeedback>{errors.secret_key.message}</Text>}
        </S.InputGroup>

        <S.UpdateAndDisableButtonBackground>
          <S.UpdateAndDisableButtonsWrapper>
            <S.UpdateCredentialButton
              type="submit"
              isLoading={isUpdatingCredential}
              disabled={isUpdatingCredential}
            >
              Atualizar credencial
            </S.UpdateCredentialButton>

            <S.DisableCredentialButton
              type="button"
              onClick={handleDeleteCredentialModalOpen}
              disabled={isDisablingCredential}
              isLoading={isDisablingCredential}
              variant={EButtonVariant.SECONDARY}
            >
              Desativar credencial
            </S.DisableCredentialButton>
          </S.UpdateAndDisableButtonsWrapper>
        </S.UpdateAndDisableButtonBackground>
      </Form>

      <DeleteCredentialModal
        isOpen={isDeleteCredentialModalOpen}
        toggle={handleDeleteCredentialModalOpen}
        onDeleteCredential={onDeleteCredential}
        isLoading={isDisablingCredential}
      />
    </S.Content>
  );
};

export default CredentialOption;
