import queryString from 'query-string';

import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';

export class VariantService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listVariants({ storeAliasId, page }: any): any {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/variants?limit=10&page=${page + 1}`,
      this.apiService,
    );

    const isLoading = !isValidating && !data;

    return {
      variants: data?.variants,
      totalPages: data?.total_pages,
      mutate,
      error,
      isLoading,
      isValidating,
    };
  }

  public listVariantsPromise({ storeAliasId, page, filter }: any): Promise<any> {
    const sstringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: sstringfiedFilter });

    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/variants?limit=10&page=${
        page + 1
      }&${queryStringFilter}`,
    );
  }
}

const variantService = new VariantService(API_DOMAIN);

export default variantService;
