import { useHome } from '@helpers/hooks/pages/dashboard/home/useHome';
import { useConfig } from '@helpers/hooks/useConfig';
import { CaretRight, GearSix, Check } from 'phosphor-react';
import React from 'react';
import { IParams } from '@domain/interfaces/IParams';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import HelpCenter from '../HelpCenter';
import * as S from './styles';

const OnboardingFinished: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const { colors } = useTheme();
  const { user, analytics } = useConfig();
  const history = useHistory();
  const { onboardingSteps, homeSetup } = useHome();

  const handleClick = React.useCallback(
    url => {
      history.push(`/${storeAliasId}${url}`);
    },
    [history, storeAliasId],
  );

  const handleBannerClick = React.useCallback(
    url => {
      analytics?.track(
        'Home Feature Banner Clicked',
        {
          email: user?.email,
        },
        { context: { groupId: storeAliasId } },
      );
      history.push(`/${storeAliasId}${url}`);
    },
    [analytics, history, storeAliasId, user],
  );

  const getGreetingsText = React.useCallback(() => {
    const date = new Date();
    const hours = date.getHours();

    if (hours >= 0 && hours < 12) {
      return 'Bom dia';
    }

    if (hours >= 12 && hours < 18) {
      return 'Boa tarde';
    }

    return 'Boa noite';
  }, []);

  return (
    <S.Container>
      <S.Title>
        {getGreetingsText()}, {user.first_name}!
      </S.Title>
      <S.SubTitle>Veja o resumo do seu negócio na plataforma</S.SubTitle>
      <S.StepsContainer>
        <S.StepsWrapper>
          <S.CardSteps>
            <S.Header>
              <S.HeaderTitleWrapper>
                <S.IconWrapper>
                  <GearSix size={20} weight="fill" fill={colors.gray[1]} color={colors.gray[1]} />
                </S.IconWrapper>
                <S.CardTitleWrapper>
                  <S.CardTitle>Complemente sua operação</S.CardTitle>
                  <S.CardDesciption>Aumente ainda mais a precisão dos dados</S.CardDesciption>
                </S.CardTitleWrapper>
              </S.HeaderTitleWrapper>

              {/* <S.ProgressBarWrapper>
                <S.StepsText>0 de 4 tarefas concluídas</S.StepsText>
                <ProgressBar total={4} value={1} colorBar={colors.gray[5]} height={7} />
              </S.ProgressBarWrapper> */}
            </S.Header>

            <S.Divider />

            <S.Body>
              {onboardingSteps.map(step => {
                const isStepCompleted = homeSetup[step.id];
                return (
                  <S.StepWrapper onClick={() => handleClick(step.linkTo)}>
                    <S.StepMark completed={isStepCompleted}>
                      {isStepCompleted && <Check size={24} color={colors.green.dark} />}
                    </S.StepMark>

                    <S.StepTitleWrapper>
                      <S.StepTitle>{step.title}</S.StepTitle>

                      <S.StepDescription>{step.description}</S.StepDescription>
                    </S.StepTitleWrapper>

                    <CaretRight size={24} color={colors.gray[3]} />
                  </S.StepWrapper>
                );
              })}
            </S.Body>
          </S.CardSteps>

          <HelpCenter />
        </S.StepsWrapper>
        <S.CardsWrapper>
          <S.TipsCardsProduct
            onClick={() => handleBannerClick('/dashboard/overview/products-analytic')}
          >
            {/* <S.TipsCardsImage src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/home/product_analytics.png" /> */}
            <S.TipsIconContainer>
              <S.TipsIconWrapper>
                <CaretRight size={20} color={colors.gray[1]} />
              </S.TipsIconWrapper>
            </S.TipsIconContainer>
            <S.TipsDescriptions>
              <S.TipTitle>Acompanhe os seus produtos</S.TipTitle>
              <S.TipDescription>
                Saiba quando um produto está te dando lucro e quando ele precisa ser escalado!
              </S.TipDescription>
            </S.TipsDescriptions>
          </S.TipsCardsProduct>
          <S.TipsCardsAutomation
            onClick={() => handleBannerClick('/dashboard/marketing/automations')}
          >
            <S.TipsIconContainer>
              <S.TipsIconWrapper>
                <CaretRight size={20} color={colors.gray[1]} />
              </S.TipsIconWrapper>
            </S.TipsIconContainer>
            <S.TipsDescriptions>
              <S.TipTitle>Automatize o seu negócio</S.TipTitle>
              <S.TipDescription>
                Conheça a ferramenta de automações e aumente a eficiência do seu negócio!
              </S.TipDescription>
            </S.TipsDescriptions>
          </S.TipsCardsAutomation>
          <S.TipsCardsAds onClick={() => handleBannerClick('/dashboard/marketing/ads-manager')}>
            <S.TipsIconContainer>
              <S.TipsIconWrapper>
                <CaretRight size={20} color={colors.gray[1]} />
              </S.TipsIconWrapper>
            </S.TipsIconContainer>
            <S.TipsDescriptions>
              <S.TipTitle>Gerencie seus Ads</S.TipTitle>
              <S.TipDescription>
                Conheça o nosso gerenciador de ads e faça tomadas de decisões mais rápidas!
              </S.TipDescription>
            </S.TipsDescriptions>
          </S.TipsCardsAds>
        </S.CardsWrapper>
      </S.StepsContainer>
    </S.Container>
  );
};

export default OnboardingFinished;
