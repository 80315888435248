import React from 'react';

import { numberFormatter, currency as getCurrency } from '@helpers/masks';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { IOrderTableDataProps } from '@domain/interfaces/dashboard/Orders/IOrderTableData';

import Table from '@components/common/core/Utils/Table';

import * as S from './styles';

const Tax: React.FC<IOrderTableDataProps> = ({ order }) => {
  const orderTaxAmountGreaterThanZero = order.tax_amount > 0;

  const taxCurrency = getCurrency(order.currency);
  const formattedTaxAmount = numberFormatter(order.tax_amount, 2);

  const taxValue = orderTaxAmountGreaterThanZero ? `${taxCurrency} ${formattedTaxAmount}` : '-';

  return (
    <Table.Data align={EHeadAlign.CENTER}>
      <S.ValueText>{taxValue}</S.ValueText>
    </Table.Data>
  );
};

export default Tax;
