import { ESynchronizationStatus, ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { EBusinessType } from '@domain/enums/stores/Etype';
import { ISynchronization } from '@domain/interfaces/hooks/IStoreSynchronization';

import {
  DROPSHIPPING_PROVIDERS,
  ECOMMERCE_PROVIDERS,
  GATEWAYS,
  INFO_PRODUCT_PROVIDERS,
  STORE_PROVIDERS,
} from '@constants/common/integrations';

export const getProductsPercentage = (
  onboardingSynchronizations: Array<ISynchronization>,
): number => {
  const storeProvidersNames = STORE_PROVIDERS.map(integration => integration.name);

  const filteredSynchronizations = onboardingSynchronizations.filter(integration =>
    storeProvidersNames.includes(integration.name),
  );

  if (!filteredSynchronizations.length) return 100;

  const foundProductsSynchronizationProvider = filteredSynchronizations.find(
    synchronization => synchronization.type === ESynchronizationType.STORE_PRODUCT,
  );

  if (!foundProductsSynchronizationProvider) return 100;

  return foundProductsSynchronizationProvider.progressPercentage;
};

const getStoreFrontArray = (
  storeType: EBusinessType | undefined,
): Array<
  | typeof DROPSHIPPING_PROVIDERS[number]
  | typeof INFO_PRODUCT_PROVIDERS[number]
  | typeof ECOMMERCE_PROVIDERS[number]
> => {
  if (storeType === EBusinessType.DROPSHIPPING) return DROPSHIPPING_PROVIDERS;

  if (storeType === EBusinessType.TRADITIONAL_ECOMMERCE) return ECOMMERCE_PROVIDERS;

  return INFO_PRODUCT_PROVIDERS;
};

export const getOrdersPercentage = (
  onboardingSynchronizations: Array<ISynchronization>,
  storeType?: EBusinessType | undefined,
): number => {
  const storeFrontArray = getStoreFrontArray(storeType);

  const storeProviderNames = storeFrontArray.map(integration => integration.name);

  const filteredSynchronizations = onboardingSynchronizations.filter(integration =>
    storeProviderNames.includes(integration.name),
  );

  if (!filteredSynchronizations.length) return 100;

  const foundOrdersSynchronizationProvider = filteredSynchronizations.find(
    synchronization => synchronization.type === ESynchronizationType.STORE_ORDER,
  );

  if (!foundOrdersSynchronizationProvider) return 100;

  return foundOrdersSynchronizationProvider.progressPercentage;
};

export const getDsersPercentage = (onboardingSynchronizations: Array<ISynchronization>): number => {
  const filteredSynchronizations = onboardingSynchronizations.filter(
    integration => integration.name === 'Dsers',
  );

  if (!filteredSynchronizations.length) return 100;

  const mappedSynchronizationsProgress = filteredSynchronizations.map(synchronization => {
    if (synchronization.status === ESynchronizationStatus.ERROR) return 100;

    return synchronization.progressPercentage;
  });

  const dsersPercentageSum = mappedSynchronizationsProgress.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0,
  );

  const dsersPercentage = dsersPercentageSum / filteredSynchronizations.length;

  return dsersPercentage;
};

export const getGatewaysPercentage = (
  onboardingSynchronizations: Array<ISynchronization>,
): number => {
  const gatewaysName = GATEWAYS.map(integration => integration.name);

  const filteredSynchronizations = onboardingSynchronizations.filter(integration =>
    gatewaysName.includes(integration.label),
  );

  if (!filteredSynchronizations.length) return 100;

  const mappedSynchronizationsProgress = filteredSynchronizations.map(synchronization => {
    if (synchronization.status === ESynchronizationStatus.ERROR) return 100;

    return synchronization.progressPercentage;
  });

  const gatewaysPercentageSum = mappedSynchronizationsProgress.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0,
  );

  const gatewaysPercentage = gatewaysPercentageSum / filteredSynchronizations.length;

  return gatewaysPercentage;
};
