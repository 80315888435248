import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import SwitchBase from '@components/common/core/Inputs/Switch';
import BadgeBase from '@components/common/core/DataDisplay/Badge';

export const Badge = styled(BadgeBase)``;

export const SwitchLabel = styled(LabelBase)`
  margin: unset;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[2]};
  line-height: 120%;
  margin-left: 8px;
  margin-right: 16px;
  margin-top: 1px;
  cursor: pointer;
`;

export const Switch = styled(SwitchBase)``;

export const InputGroup = styled(InputGroupBase)`
  padding: 16px 12px;
  box-sizing: border-box;
  flex-direction: row;
  margin: unset;
  align-items: center;
`;

export const DescriptionText = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.gray[2]};

  & > strong {
    color: ${({ theme }) => theme.colors.gray[1]};
  }
`;

export const Description = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[6]};
  padding: 8px 12px;
  box-sizing: border-box;
`;

export const OptionWrapper = styled.div`
  &:nth-child(2) {
    & > div:first-child {
      border-top: 1px solid ${({ theme }) => theme.colors.background[2]};
    }
  }
`;

export const Wrapper = styled.div`
  max-width: 440px;
  width: 100%;
  height: fit-content;
  border: 1px solid ${({ theme }) => theme.colors.background[2]};
  border-radius: 7px;
  overflow: hidden;
`;
