/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { PagSeguro as PagseguroIcon } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { EGateway } from '@domain/enums/common/EGateway';
import { IParams } from '@domain/interfaces/IParams';
import { ISideModalGatewayContent } from '@domain/interfaces/dashboard/IntegrationCenter/SideModalContent/ISideModalContent';

import { useToast } from '@helpers/hooks/useToast';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useDate } from '@helpers/hooks/useDate';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { pagseguroSchema } from '@helpers/validators/dashboard/integrationCenter/pagseguro';

import { getStoreProvider } from '@helpers/utils/common/integration';
import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { GATEWAYS } from '@constants/common/integrations';

import pagseguroCredentialService from '@services/pages/dashboard/integrationCenter/gateway/pagseguro';

import Text from '@components/common/core/DataDisplay/Text';
import Save from '@components/Dashboard/IntegrationCenter/SideModalContent/Save';
import Form from '@components/common/core/Inputs/Form';
import Input from '@components/common/core/Inputs/TextField';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Pagseguro: React.FC<ISideModalGatewayContent> = ({
  syncOnCreate,
  isFeedSideModal,
  toggle,
}) => {
  const foundIntegration = GATEWAYS.find(
    integration => integration.identifier === EGateway.PAG_SEGURO,
  ) as typeof GATEWAYS[number];

  const { toast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(pagseguroSchema),
  });
  const { storeAliasId } = useParams<IParams>();
  const { addSynchronization } = useSynchronization();
  const { format, subDays, utcToZonedTime } = useDate();
  const { storeIntegrations } = useStoreConfig();

  const { pagseguroCredential, mutate, isLoading } = pagseguroCredentialService.getCredential({
    storeAliasId,
  });

  const [isLoadingSubmittedForm, setIsLoadingSubmittedForm] = React.useState<boolean>(false);
  const [isLoadingDeleteCredential, setIsLoadingDeleteCredential] = React.useState<boolean>(false);
  const [hasEmailInputValue, setHasEmailInputValue] = React.useState<boolean>(false);
  const [hasAccessTokenInputValue, setHasAccessTokenInputValue] = React.useState<boolean>(false);

  const onEmailInputChange = React.useCallback(event => {
    if (event.target.value) {
      setHasEmailInputValue(true);
    } else {
      setHasEmailInputValue(false);
    }
  }, []);

  const onAccessTokenInputChange = React.useCallback(event => {
    if (event.target.value) {
      setHasAccessTokenInputValue(true);
    } else {
      setHasAccessTokenInputValue(false);
    }
  }, []);

  const onSubmit = React.useCallback(
    async formData => {
      setIsLoadingSubmittedForm(true);

      const lastThirtyDays = utcToZonedTime(subDays(new Date(), 30));

      const storeProvider = getStoreProvider(storeIntegrations);

      const hasGatewaySynchronization = storeProvider?.gateways.includes(EGateway.PAG_SEGURO);

      try {
        const params = `?start_date=${format(lastThirtyDays, 'yyyy-MM-dd')}&end_date=${format(
          utcToZonedTime(new Date()),
          'yyyy-MM-dd',
        )}`;

        if (pagseguroCredential?.id) {
          await pagseguroCredentialService.updateCredential({
            storeAliasId,
            data: formData,
          });

          if (storeProvider && hasGatewaySynchronization) {
            addSynchronization({
              name: storeProvider.name,
              type: ESynchronizationType.STORE_GATEWAY,
              label: foundIntegration.name,
              dependencies: [ESynchronizationType.STORE_PRODUCT, ESynchronizationType.STORE_ORDER],
              storeAliasId,
              config: {
                isOnboardingSynchronization: false,
                showNotification: true,
              },
              request: storeProvider.generateGatewaySyncRequest(
                foundIntegration.synchronizationName,
                storeAliasId,
                params,
              ),
            });
          }
        } else {
          await pagseguroCredentialService.createCredential({
            storeAliasId,
            data: formData,
          });

          if (syncOnCreate && storeProvider && hasGatewaySynchronization) {
            addSynchronization({
              name: storeProvider.name,
              type: ESynchronizationType.STORE_GATEWAY,
              label: foundIntegration.name,
              dependencies: [ESynchronizationType.STORE_PRODUCT, ESynchronizationType.STORE_ORDER],
              storeAliasId,
              config: {
                isOnboardingSynchronization: false,
                showNotification: true,
              },
              request: storeProvider.generateGatewaySyncRequest(
                foundIntegration.synchronizationName,
                storeAliasId,
                params,
              ),
            });
          }
        }

        await Promise.all([mutate()]);

        toast.success('Credencial salva com sucesso!');

        setIsLoadingSubmittedForm(false);

        if (toggle) {
          toggle();
        }
      } catch (error: any) {
        setIsLoadingSubmittedForm(false);
        toast.error(getTranslatedIntegrationsError(error));
      }
    },
    [
      pagseguroCredential,
      storeAliasId,
      mutate,
      toast,
      addSynchronization,
      subDays,
      format,
      utcToZonedTime,
      syncOnCreate,
      storeIntegrations,
      toggle,
      foundIntegration,
    ],
  );

  const handleDeleteCredential = React.useCallback(async () => {
    setIsLoadingDeleteCredential(true);

    try {
      await pagseguroCredentialService.deleteCredential({
        storeAliasId,
      });

      await Promise.all([mutate()]);

      toast.success('Credencial desativada com sucesso!');
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoadingDeleteCredential(false);
    }
  }, [storeAliasId, mutate, toast]);

  if (isLoading) {
    return <SkeletonLoading />;
  }

  return (
    <S.ContentWrapper>
      <S.Header>
        <PagseguroIcon size={52} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com PagSeguro
        </S.Heading>
      </S.Header>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>
        <S.FunctionalityDescriptionText>
          A integração com seu Gateway de pagamento serve para
          <strong> puxarmos seu faturamento </strong>
          aumentando a<strong> exatidão na análise de seus dados.</strong>
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <S.TutorialWrapper>
        <S.TutorialHeading type={EHeadingSize.H5}>Tutorial de integração</S.TutorialHeading>
        <S.TutorialList>
          <S.TutorialStep>Acesse a sua conta PagSeguro</S.TutorialStep>
          <S.TutorialStep>
            No menu lateral, selecione &apos;Venda Online&apos; e depois, toque em
            &apos;Configurações&apos;
          </S.TutorialStep>
          <S.TutorialStep>Vá na opção &apos;Integrações&apos;</S.TutorialStep>
          <S.TutorialStep>E pressione o botão &apos;Gerar Token&apos;</S.TutorialStep>
        </S.TutorialList>
      </S.TutorialWrapper>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label htmlFor="pagseguro-email">E-mail</S.Label>

          <Input
            {...register('email')}
            placeholder="E-mail"
            type="text"
            name="email"
            defaultValue={pagseguroCredential?.email}
            onChange={onEmailInputChange}
            id="pagseguro-email"
          />
          {errors.email && <Text isErrorFeedback>{errors.email.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label htmlFor="pagseguro-access-token">Access Token</S.Label>

          <Input
            {...register('access_token')}
            placeholder="Chave de Acesso PagSeguro"
            type="text"
            name="access_token"
            defaultValue={pagseguroCredential?.access_token}
            onChange={onAccessTokenInputChange}
            id="pagseguro-access-token"
          />
          {errors.access_token && <Text isErrorFeedback>{errors.access_token.message}</Text>}
        </S.InputGroup>

        <Save
          submitText={isFeedSideModal ? 'Reprocessar pedidos' : 'Salvar alterações'}
          cancelText="Cancelar"
          onDelete={isFeedSideModal ? undefined : handleDeleteCredential}
          toggle={toggle}
          hasCredentials={Boolean(pagseguroCredential?.id)}
          isSubmitting={isLoadingSubmittedForm}
          isDeleting={isLoadingDeleteCredential}
          isDeleteButtonDisabled={isLoadingDeleteCredential || isLoadingSubmittedForm}
          isSaveButtonDisabled={
            (!pagseguroCredential?.id && (!hasEmailInputValue || !hasAccessTokenInputValue)) ||
            isLoadingSubmittedForm ||
            isLoadingDeleteCredential
          }
        />
      </Form>
    </S.ContentWrapper>
  );
};

export default Pagseguro;
