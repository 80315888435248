import React from 'react';
import { X as Close } from 'phosphor-react';
import { useTheme } from 'styled-components';

import { IConditionalAbandonCartNodeModalSelect } from '@domain/interfaces/dashboard/Automations/Canvas/Node/ConditionalNode/IConditionalAbandonCartNodeModal';
import {
  EAbandonCartType,
  EAbandonCartProductOptions,
  EAbandonCartTotalValueOptions,
  EAbandonedCartSellRecoveredPicker,
} from '@domain/enums/dashboard/automations/node/conditionalNode/EAbandonCart';

import { currencyFormatter, numberFormatter } from '@helpers/masks';
import { commaNotationNumberToDotNotationNumber } from '@helpers/masks/numberFormatter';

import OptionProduct from './OptionProduct';
import OptionTotalValue from './OptionTotalValue';
import PickerSellRecovered from './PickerSellRecovered';

import * as S from './styles';

const ConditionalSelect: React.FC<IConditionalAbandonCartNodeModalSelect> = ({
  isDynamic,
  value,
  index,
  onUpdate,
  onDelete,
  logicalOperator,
  showLogicalOperator,
}) => {
  const { colors } = useTheme();

  const handleChangeType = React.useCallback(
    e => {
      const choosen = e.target.value as EAbandonCartType;

      if (choosen === EAbandonCartType.PRODUCT_NAME) {
        onUpdate(
          { ...value, type: choosen, options: EAbandonCartProductOptions.CONTAINS, input: '' },
          index,
        );
      } else if (choosen === EAbandonCartType.ABANDONED_CART_TOTAL_VALUE) {
        onUpdate(
          { ...value, type: choosen, options: EAbandonCartTotalValueOptions.SMALLER, input: '' },
          index,
        );
      } else if (choosen === EAbandonCartType.SELL_RECOVERED) {
        onUpdate(
          {
            ...value,
            type: choosen,
            options: EAbandonCartTotalValueOptions.EQUAL,
            input: EAbandonedCartSellRecoveredPicker.TRUE,
          },
          index,
        );
      }
    },
    [value, index, onUpdate],
  );

  const handleChangeOptionsProduct = React.useCallback(
    name => {
      onUpdate({ ...value, options: name }, index);
    },
    [value, index, onUpdate],
  );

  const handleChangeOptionsTotalValue = React.useCallback(
    name => {
      onUpdate({ ...value, options: name }, index);
    },
    [value, index, onUpdate],
  );

  const handleChangeInput = React.useCallback(
    text => {
      onUpdate({ ...value, input: text }, index);
    },
    [value, index, onUpdate],
  );

  const handleChangeProductInput = React.useCallback(
    e => {
      handleChangeInput(e.target.value);
    },
    [handleChangeInput],
  );

  const handleChangeTotalValueInput = React.useCallback(
    e => {
      handleChangeInput(currencyFormatter(e.target.value));

      e.target.value = currencyFormatter(e.target.value);
    },
    [handleChangeInput],
  );

  const handleChangePickerSellRecovered = React.useCallback(
    name => {
      onUpdate({ ...value, input: name }, index);
    },
    [value, index, onUpdate],
  );

  const translateLogicalOperator = (operator: string): string => {
    const translated = operator === 'AND' ? 'E' : 'OU';
    return translated;
  };

  return (
    <S.Wrapper>
      <S.Row>
        <S.Select
          name="type"
          defaultValue={value?.type ?? EAbandonCartType.PRODUCT_NAME}
          onChange={handleChangeType}
        >
          <S.Option value={EAbandonCartType.PRODUCT_NAME}>Nome do Produto</S.Option>
          <S.Option value={EAbandonCartType.ABANDONED_CART_TOTAL_VALUE}>Valor Total</S.Option>
          <S.Option value={EAbandonCartType.SELL_RECOVERED}>Pedido criado</S.Option>
        </S.Select>

        {value.type === EAbandonCartType.PRODUCT_NAME && (
          <OptionProduct onChange={handleChangeOptionsProduct} defaultValue={value.options} />
        )}

        {value.type === EAbandonCartType.ABANDONED_CART_TOTAL_VALUE && (
          <OptionTotalValue onChange={handleChangeOptionsTotalValue} defaultValue={value.options} />
        )}

        <S.TextFieldWrapper>
          {value.type === EAbandonCartType.PRODUCT_NAME && (
            <S.TextField
              type="text"
              defaultValue={value.input}
              onChange={handleChangeProductInput}
            />
          )}

          {value.type === EAbandonCartType.ABANDONED_CART_TOTAL_VALUE && (
            <S.TextField
              type="text"
              defaultValue={numberFormatter(commaNotationNumberToDotNotationNumber(value.input), 2)}
              onChange={handleChangeTotalValueInput}
            />
          )}

          {value.type === EAbandonCartType.SELL_RECOVERED && (
            <PickerSellRecovered
              onChange={handleChangePickerSellRecovered}
              defaultValue={value.input}
            />
          )}

          {isDynamic && onDelete && (
            <S.CloseButton onClick={() => onDelete(index)}>
              <Close size={12} color={colors.gray[1]} />
            </S.CloseButton>
          )}
        </S.TextFieldWrapper>
      </S.Row>

      {showLogicalOperator && (
        <S.LogicalOperatorWrapper>
          <S.LogicalOperator>{translateLogicalOperator(logicalOperator)}</S.LogicalOperator>
        </S.LogicalOperatorWrapper>
      )}
    </S.Wrapper>
  );
};

export default ConditionalSelect;
