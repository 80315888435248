import styled from 'styled-components/macro';

export const NameWrapper = styled.div`
  max-width: 158px;
  width: 100%;

  & > span > span {
    display: block;
  }
`;

export const AvatarWrapper = styled.div`
  & > span > span {
    display: block;
    border-radius: 50%;
  }
`;

export const AdSenseCredentialWrapper = styled.div`
  padding: 8px 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 8px;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[5]};
  }
`;
