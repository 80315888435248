import React from 'react';

import { GoogleCredentialsProvider } from '@helpers/hooks/common/integrations/marketing/google/useGoogleCredentials';

import Wrapper from './Wrapper';

const Google: React.FC = () => {
  return (
    <GoogleCredentialsProvider>
      <Wrapper />
    </GoogleCredentialsProvider>
  );
};

export default Google;
