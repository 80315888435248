import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';

export const FavoriteIconWrapper = styled.div`
  max-width: 20px;
  width: 100%;
  margin: 0 auto;
`;

export const NetProfitWrapper = styled.div`
  max-width: 68px;
  width: 100%;
  margin-left: auto;
`;

export const ConversionWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;

  & > span > span {
    display: block;
  }
`;

export const CpaWrapper = styled.div`
  max-width: 68px;
  width: 100%;
  margin-left: auto;
`;

export const TaxesWrapper = styled.div`
  max-width: 68px;
  width: 100%;
  margin-left: auto;
`;

export const ProfitMarginWrapper = styled.div`
  max-width: 68px;
  width: 100%;
  margin: 0 auto;
`;

export const MarketingWrapper = styled.div`
  max-width: 68px;
  width: 100%;
  margin-left: auto;
`;

export const CogsWrapper = styled.div`
  max-width: 68px;
  width: 100%;
  margin-left: auto;
`;

export const NetRevenueWrapper = styled.div`
  max-width: 68px;
  width: 100%;
  margin-left: auto;
`;

export const ImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;

  & > span > span {
    display: block;
  }
`;

export const RankingWrapper = styled.div`
  width: 24px;
`;

export const Table = styled(TableBase)`
  & > table {
    & > thead {
      & > tr {
        & > th {
          padding: 10px 20px;
        }

        & > th:first-child {
          padding-left: 24px;
          padding-right: 0;
        }

        & > th:last-child {
          padding-right: 36px;
        }
      }
    }

    & > tbody {
      & > tr {
        cursor: pointer;

        & > td {
          padding: 10px 20px;
          font-size: 0.875rem;
        }

        & > td:first-child {
          padding-left: 24px;
          padding-right: 0;
        }

        & > td:last-child {
          padding-right: 36px;
        }
      }
    }
  }
`;

export const Wrapper = styled.div``;
