import styled, { css } from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

interface IVoteProps {
  isVoted?: boolean;
}

export const VoteWrapper = styled.div<IVoteProps>`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  transition: all 0.3s;

  ${({ isVoted, theme }) =>
    isVoted &&
    css`
      & > div {
        border-bottom-color: ${theme.colors.green.default};
      }

      & > svg {
        color: ${theme.colors.green.default};
      }

      & > span {
        color: ${theme.colors.green.default};
      }
    `}

  &:hover {
    border-color: ${({ theme }) => theme.colors.green.default};

    & > div {
      border-bottom-color: ${({ theme }) => theme.colors.green.default};
    }

    & > svg {
      color: ${({ theme }) => theme.colors.green.default};
    }

    & > span {
      color: ${({ theme }) => theme.colors.green.default};
    }
  }
`;

export const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid ${({ theme }) => theme.colors.font};
  transition: all 0.3s;
`;

export const VotesCount = styled(TextBase)`
  /* line-height: 120%; */
  transition: all 0.3s;
`;
