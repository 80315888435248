import React from 'react';
import { MarketingCircle } from '@profitfy/pakkun-icons';
import { useHistory } from 'react-router-dom';

import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const NoAdSenseCampaignsCard: React.FC = () => {
  const history = useHistory();
  const { analytics, selectedStore, user } = useConfig();

  const redirectToCampaignsPage = React.useCallback(() => {
    analytics?.track(
      'Product Analytic Details No Campaigns Clicked',
      {
        source: 'top_card',
        email: user?.email,
      },
      { context: { groupId: selectedStore?.alias_id } },
    );

    history.push('campaigns');
  }, [history, analytics, selectedStore, user]);

  return (
    <S.CardWrapper>
      <S.Content>
        <MarketingCircle size={40} />

        <S.Text>
          Nenhum custo de ADS vinculado. Para adicionar{' '}
          <S.RedirectButton onClick={redirectToCampaignsPage}>clique aqui</S.RedirectButton>
        </S.Text>
      </S.Content>
    </S.CardWrapper>
  );
};

export default NoAdSenseCampaignsCard;
