import React from 'react';

import { numberFormatter, currency as getCurrency } from '@helpers/masks';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { IOrderTableDataProps } from '@domain/interfaces/dashboard/Orders/IOrderTableData';

import Table from '@components/common/core/Utils/Table';

import * as S from './styles';

const Shipping: React.FC<IOrderTableDataProps> = ({ order }) => {
  const isShippingValueIsGreaterThanZero = order.shipping_cost_owner_amount > 0;

  const shippingCurrency = getCurrency(order.currency);
  const formattedShippingValue = numberFormatter(order.shipping_cost_owner_amount, 2);

  const shippingValue = isShippingValueIsGreaterThanZero
    ? `${shippingCurrency} ${formattedShippingValue}`
    : '-';

  return (
    <Table.Data align={EHeadAlign.RIGHT}>
      <S.ShippingText>{shippingValue}</S.ShippingText>
    </Table.Data>
  );
};

export default Shipping;
