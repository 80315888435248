import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.DetailsWrapper>
      <Skeleton width="100%" height={168} style={{ borderRadius: 15 }} />
    </S.DetailsWrapper>
  );
};

export default SkeletonLoading;
