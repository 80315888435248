import React from 'react';
import { BalanceCircle, ProductCost, MegaPhone, Dolar } from '@profitfy/pakkun-icons';

import { ESelectedChart } from '@domain/enums/dashboard/main/ESelectedChart';

import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';

import NoAdSenseCampaignsCard from './Card/NoAdSenseCampaignsCard';
import SyncingMarketing from './Card/SyncingMarketing';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

import Card from './Card';

const OverviewCards: React.FC = () => {
  const {
    selectedChart,
    isLoadingFinancialDashboardProvider,
    isValidatingFinancialDashboardProvider,
    financialReport,
    isLoadingFinancialDashboardPromise,
    marketingSynchronizationId,
  } = useFinancialDashboard();

  if (
    isLoadingFinancialDashboardProvider ||
    isValidatingFinancialDashboardProvider ||
    isLoadingFinancialDashboardPromise
  ) {
    return <SkeletonLoading />;
  }

  const { net_revenue, cogs, marketing, fee } = financialReport;

  const hasMarketingAmount = marketing.total_amount !== 0;

  return (
    <S.OverviewCardsWrapper>
      <Card
        id={ESelectedChart.REVENUE}
        isSelected={selectedChart === ESelectedChart.REVENUE}
        icon={<Dolar size={38} />}
        title="Receita Líquida"
        value={net_revenue.amount}
        percentage={net_revenue.growth}
      />
      <Card
        id={ESelectedChart.COGS}
        isSelected={selectedChart === ESelectedChart.COGS}
        icon={<ProductCost size={38} />}
        title="Custo dos Produtos"
        value={cogs.cogs_amount}
        percentage={cogs.growth}
      />
      {hasMarketingAmount ? (
        <>
          {marketingSynchronizationId ? (
            <SyncingMarketing />
          ) : (
            <Card
              id={ESelectedChart.MARKETING}
              isSelected={selectedChart === ESelectedChart.MARKETING}
              icon={<MegaPhone size={38} />}
              title="Marketing"
              value={marketing.total_amount}
              percentage={marketing.growth}
            />
          )}
        </>
      ) : (
        <NoAdSenseCampaignsCard />
      )}
      <Card
        id={ESelectedChart.TAXES}
        isSelected={selectedChart === ESelectedChart.TAXES}
        icon={<BalanceCircle size={38} />}
        title="Taxas e Impostos"
        value={fee.total_amount}
        percentage={fee.growth}
      />
    </S.OverviewCardsWrapper>
  );
};

export default OverviewCards;
