import React from 'react';

import { useForm } from 'react-hook-form';

import { EButtonSize } from '@domain/enums/components/EButton';
import { IAssignUserForm } from '@domain/interfaces/dashboard/AdminPanel/ICustomerSuccess';

import { useToast } from '@helpers/hooks/useToast';

import customerSuccessService from '@services/pages/dashboard/adminPanel/customerSuccess';

import Form from '@components/common/core/Inputs/Form';

import * as S from './styles';

const AssignUserForm: React.FC<IAssignUserForm> = ({ customerSuccess }) => {
  const { toast } = useToast();
  const { handleSubmit, register } = useForm();

  const [isAssigningUser, setIsAssigningUser] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async formData => {
      setIsAssigningUser(true);

      try {
        await customerSuccessService.assignCustomerSuccess({
          customer_success_alias_id: formData.customer_success_alias_id,
          data: { user_email: formData.user_email },
        });

        toast.success('Usuário associado com sucesso.');

        setIsAssigningUser(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsAssigningUser(false);
      }
    },
    [toast],
  );

  return (
    <>
      <S.Heading>Associar usuário</S.Heading>

      <S.AssignUserContent>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>Colaborador</S.Label>

            <S.AssingUserSelect {...register('customer_success_alias_id')}>
              <S.Option value="" selected disabled>
                Selecione o colaborador
              </S.Option>

              {customerSuccess.map((customerSuccessValue: any) => {
                const fullName = `${customerSuccessValue.first_name} ${customerSuccessValue.last_name}`;

                return <S.Option value={customerSuccessValue.alias_id}>{fullName}</S.Option>;
              })}
            </S.AssingUserSelect>
          </S.InputGroup>

          <S.InputGroup>
            <S.Label htmlFor="user-email">Email do usuário</S.Label>

            <S.Input
              {...register('user_email')}
              id="user-email"
              type="text"
              placeholder="Insira o e-mail do usuário"
            />
          </S.InputGroup>

          <S.AssignUserButton
            type="submit"
            buttonSize={EButtonSize.MEDIUM}
            isLoading={isAssigningUser}
            disabled={isAssigningUser}
          >
            Associar
          </S.AssignUserButton>
        </Form>
      </S.AssignUserContent>
    </>
  );
};

export default AssignUserForm;
