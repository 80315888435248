import React from 'react';

import { useMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useMessageSettings';

import { EMessageType } from '@domain/enums/dashboard/automations/EMessage';
import { IVariablesProps } from '@domain/interfaces/common/automations/IVariables';

import {
  ABANDONED_CART_TRIGGER_VARIABLES,
  BOLETO_TRIGGER_VARIABLES,
  PIX_TRIGGER_VARIABLES,
  REJECTED_PAYMENT_TRIGGER_VARIABLES,
  SIMPLE_SHIPMENT_TRIGGER_VARIABLES,
  UPDATED_FULFILLMENT_TRIGGER_VARIABLES,
} from '@constants/pages/dashboard/automations/messages/triggers';

import * as S from './styles';

const Variables: React.FC<IVariablesProps> = ({
  setValue,
  handleTextAreaChange,
  value,
  textRef,
  handleCursorPosition,
}) => {
  const { selectedMessageTrigger } = useMessageSettings();

  const variablesRef = React.useRef<HTMLDivElement>(null);

  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);
  const [maxVariablesOfDisplay, setMaxVariablesOfDisplay] = React.useState<number>(4);

  const handlePopoverOpen = React.useCallback(() => {
    setIsPopoverOpen(currentState => !currentState);
  }, []);

  const getConfigMessageVariables = (): Array<Record<any, any>> => {
    if (selectedMessageTrigger === EMessageType.ABANDONED_CART)
      return ABANDONED_CART_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.BOLETO) return BOLETO_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.REJECTED_PAYMENT)
      return REJECTED_PAYMENT_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.PIX) return PIX_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.SIMPLE_SHIPMENT)
      return SIMPLE_SHIPMENT_TRIGGER_VARIABLES;

    if (selectedMessageTrigger === EMessageType.UPDATED_FULFILLMENT)
      return UPDATED_FULFILLMENT_TRIGGER_VARIABLES;

    return [];
  };

  const variablesArray = getConfigMessageVariables();

  const variablesQuantity = variablesArray.length;

  const hasMoreThanFourVariables = variablesQuantity > maxVariablesOfDisplay;

  const variablesToDisplay = variablesArray.slice(0, maxVariablesOfDisplay);

  const restVariables = variablesArray.filter(variable => {
    if (variablesToDisplay.includes(variable)) return false;

    return true;
  });

  const onVariableClick = React.useCallback(
    data => {
      const ref: any = textRef.current;
      ref.focus();
      const start = value.substring(0, ref.selectionStart);
      const end = value.substring(ref.selectionStart);
      // const newValue = `${value}${data}`;
      const newValue = start + data + end;

      setValue(newValue);
      handleTextAreaChange(newValue);
      if (handleCursorPosition) {
        handleCursorPosition(Number(start.length) + Number(data.length));
      }
    },
    [setValue, value, handleTextAreaChange, textRef, handleCursorPosition],
  );

  const onPopoverOptionClick = React.useCallback(
    data => {
      const newValue = `${value}${data}`;

      setValue(newValue);
      handleTextAreaChange(newValue);
    },
    [setValue, value, handleTextAreaChange],
  );

  const checkVariablesElementOverflow = React.useCallback(() => {
    if (variablesRef.current) {
      const elementWidth = variablesRef?.current?.offsetWidth;
      const contentWidth = variablesRef?.current?.scrollWidth;

      const isElementOverflow = elementWidth < contentWidth;

      if (isElementOverflow) {
        setMaxVariablesOfDisplay(currentState => currentState - 1);
      }
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', checkVariablesElementOverflow);

    return () => {
      window.removeEventListener('resize', checkVariablesElementOverflow);
    };
  }, [checkVariablesElementOverflow]);

  return (
    <S.VariablesWrapper ref={variablesRef}>
      {variablesToDisplay.map(variable => {
        return (
          <S.VariableButton
            key={variable.name}
            onClick={() => onVariableClick(variable.nameToDisplay)}
            type="button"
          >
            {variable.name}
          </S.VariableButton>
        );
      })}

      {hasMoreThanFourVariables && (
        <S.Popover open={isPopoverOpen} onOpenChange={handlePopoverOpen}>
          <S.PopoverTrigger>
            <S.VariableButton color="" type="button">
              + {restVariables.length}
            </S.VariableButton>
          </S.PopoverTrigger>

          <S.PopoverContent>
            {restVariables.map(variable => {
              return (
                <S.PopoverOption
                  key={variable.name}
                  onClick={() => onPopoverOptionClick(variable.nameToDisplay)}
                >
                  {variable.name}
                </S.PopoverOption>
              );
            })}

            <S.PopoverArrow />
          </S.PopoverContent>
        </S.Popover>
      )}
    </S.VariablesWrapper>
  );
};

export default Variables;
