import React from 'react';

import { ITextFieldProps } from '@domain/interfaces/components/ITextField';
import { Cancel, Check } from '@profitfy/pakkun-icons';
import { CircleSpinner } from 'react-spinners-kit';

import theme from '@styles/themes/dark/theme';

import * as S from './styles';

const TextField: React.ForwardRefRenderFunction<HTMLInputElement, ITextFieldProps> = (
  {
    placeholder = '',
    width,
    type,
    name,
    disabled = false,
    isSuccess = false,
    hasAnyError = false,
    hasAnyWarning = false,
    append: AppendIcon,
    prepend: PrependIcon,
    prependIconColor,
    prependIconSize,
    prependIconBar = true,
    button,
    buttonLoading,
    onChange,
    value,
    defaultValue,
    className,
    id,
    onClick,
    appendText,
    prependText,
    onBlur,
    onFocus,
    autoFocus,
    noVerticalRule,
    parentRef,
    onMouseEnter,
    onMouseLeave,
    isError,
    noFeedbackError,
    readOnly = false,
    noFeedbackSuccess = false,
    ...rest
  },
  ref,
) => {
  const [isInputFocused, setIsInputFocused] = React.useState<boolean>(false);

  const handleOnInputFocus = React.useCallback(
    event => {
      if (onFocus) onFocus(event);

      setIsInputFocused(true);
    },
    [onFocus],
  );

  const handleOnInputBlur = React.useCallback(
    event => {
      if (onBlur) onBlur(event);

      setIsInputFocused(false);
    },
    [onBlur],
  );

  const getFeedbackInputIcon = (): JSX.Element => {
    if (!isError && value && !disabled && !noFeedbackError && !noFeedbackSuccess) {
      return <Check size={19} color={theme.colors.success.default} />;
    }

    if (isError) {
      return <Cancel size={19} color={theme.colors.danger.default} />;
    }

    return <></>;
  };

  return (
    <S.InputButtonWrapper
      className={className}
      ref={parentRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <S.Container
        value={value}
        isError={isError}
        disabled={disabled}
        hasAnyError={hasAnyError}
        isSuccess={isSuccess}
        hasAnyWarning={hasAnyWarning}
        width={width}
        button={button}
        noFeedbackError={noFeedbackError}
        isFocused={isInputFocused}
        readOnly={readOnly}
        noFeedbackSuccess={noFeedbackSuccess}
      >
        {PrependIcon && (
          <>
            <PrependIcon
              size={prependIconSize || 20}
              color={prependIconColor}
              style={!prependIconBar ? { marginRight: '12px' } : null}
            />
            {prependIconBar && <S.VerticalRule />}
          </>
        )}

        {prependText && (
          <>
            <S.PrependAndAppendText>{prependText}</S.PrependAndAppendText>
            <S.VerticalRule />
          </>
        )}

        <S.Input
          onFocus={handleOnInputFocus}
          onBlur={handleOnInputBlur}
          disabled={disabled}
          placeholder={placeholder}
          type={type}
          name={name}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
          ref={ref}
          id={id}
          autoFocus={autoFocus}
          readOnly={readOnly}
          {...rest}
        />

        <S.FeedBackIconWrapper>{getFeedbackInputIcon()}</S.FeedBackIconWrapper>

        {AppendIcon && (
          <>
            <S.VerticalRule prepend={PrependIcon} />
            <AppendIcon size={16} />
          </>
        )}

        {appendText && (
          <>
            {noVerticalRule ? <></> : <S.VerticalRule />}
            <S.PrependAndAppendText>{appendText}</S.PrependAndAppendText>
          </>
        )}
      </S.Container>

      {button && (
        <S.Button disabled={disabled} onClick={onClick} isLoading={buttonLoading}>
          {buttonLoading && (
            <CircleSpinner size={15} color={theme.colors.gray[1]} loading={buttonLoading} />
          )}

          {!buttonLoading && button}
        </S.Button>
      )}
    </S.InputButtonWrapper>
  );
};

export default React.forwardRef(TextField);
