import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import BadgeBase from '@components/common/core/DataDisplay/Badge';

export const ProfileStatus = styled(BadgeBase)``;

export const ProfileName = styled(TextBase)`
  font-size: 0.875rem;
`;

export const Checkbox = styled(CheckboxBase)``;

export const Option = styled(LabelBase)`
  margin: unset;
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
`;

export const ProfileOptionGroup = styled(InputGroupBase)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: unset;
`;
