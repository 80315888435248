import React from 'react';
import { useParams } from 'react-router-dom';

import { EDropshippingProvider } from '@domain/enums/common/integrations/EDropshippingProvider';
import { EShopifyCredentialType } from '@domain/enums/common/integrations/providers/EShopify';
import { IParams } from '@domain/interfaces/IParams';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useToast } from '@helpers/hooks/useToast';

import shopifyService from '@services/pages/dashboard/integrationCenter/marketplace/shopify/shopifyAuth';

import CustomAppIntegrateForm from './CustomAppIntegrateForm';
import ShopifyConfig from './ShopifyConfig';
import SkeletonLoading from './SkeletonLoading';

const Shopify: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { storeIntegrations } = useStoreConfig();

  const [hasShopifyCredential, setHasShopifyCredential] = React.useState<boolean>(false);
  const [storeDomain, setStoreDomain] = React.useState<string>('');
  const [credentialType, setCredentialType] = React.useState<EShopifyCredentialType>(
    EShopifyCredentialType.CUSTOM_APP,
  );
  const [isGettingShopifyCredential, setIsGettingShopifyCredential] = React.useState<boolean>(
    false,
  );

  const getShopifyCredential = React.useCallback(async () => {
    setIsGettingShopifyCredential(true);

    try {
      const { data } = await shopifyService.getShopifyCredentialPromise({ storeAliasId });

      if (data?.shopify_credential.is_active) {
        setHasShopifyCredential(true);
      }

      setCredentialType(data?.shopify_credential.type);
      setStoreDomain(data?.shopify_credential.domain);
      setIsGettingShopifyCredential(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsGettingShopifyCredential(false);
    }
  }, [storeAliasId, toast]);

  React.useEffect(() => {
    if (storeIntegrations) {
      const { store_front } = storeIntegrations;

      const foundIntegration = store_front.find(
        integration => integration.name === EDropshippingProvider.SHOPIFY,
      );

      if (foundIntegration?.is_active) {
        getShopifyCredential();
      }
    }
  }, [getShopifyCredential, storeIntegrations]);

  if (isGettingShopifyCredential) {
    return <SkeletonLoading />;
  }

  if (hasShopifyCredential) {
    return <ShopifyConfig storeDomain={storeDomain} credentialType={credentialType} />;
  }

  return <CustomAppIntegrateForm credentialType={credentialType} domain={storeDomain} />;
};

export default Shopify;
