import React from 'react';

import { EBadgeColors } from '@domain/enums/components/EBadge';
import { ETextSize } from '@domain/enums/components/EText';
import { EButtonSize } from '@domain/enums/components/EButton';
import { IFeedOrderProps } from '@domain/interfaces/dashboard/Feed/IFeed';
import { getProviderIcon } from '@helpers/utils/common/provider';
import { useDate } from '@helpers/hooks/useDate';
import { numberFormatter } from '@helpers/masks';
import { getFormattedCurrency } from '@helpers/utils/common/currency';
import { useConfig } from '@helpers/hooks/useConfig';
import Sidemodal from '../Sidemodal';

import * as S from './styles';

export const Order: React.FC<IFeedOrderProps> = ({ order }) => {
  const { utcToZonedTime, format } = useDate();
  const { analytics, selectedStore, user } = useConfig();

  const firstOrderLineItem = order?.line_items[0];

  const [isSidemodalOpen, setIsSidemodalOpen] = React.useState<boolean>(false);
  const [isFirstOrderItemImageError, setIsFirstOrderItemImageError] = React.useState<boolean>(
    false,
  );

  const handleSidemodalOpen = React.useCallback(() => {
    setIsSidemodalOpen(!isSidemodalOpen);
  }, [isSidemodalOpen]);

  const solveButtonClick = React.useCallback(() => {
    analytics?.track(
      'Task Feed Solve Button Clicked',
      {
        email: user?.email,
      },
      { context: { groupId: selectedStore?.alias_id } },
    );

    handleSidemodalOpen();
  }, [analytics, selectedStore, handleSidemodalOpen, user]);

  const ProviderIcon = getProviderIcon(order?.provider);

  const hasProviderUrl = order?.provider_detail_url;

  return (
    <>
      <S.Wrapper>
        <S.ImageAndOrder>
          <S.ImageWrapper>
            {!isFirstOrderItemImageError && firstOrderLineItem.product?.image_url ? (
              <S.Image
                src={firstOrderLineItem.product?.image_url}
                alt="Profitfy.me Feed"
                onError={() => setIsFirstOrderItemImageError(true)}
              />
            ) : (
              <S.NoImage />
            )}

            <S.ProviderImageWrapper>
              <ProviderIcon size={20} color="white" />
            </S.ProviderImageWrapper>
          </S.ImageWrapper>

          <S.OrderNumberAndStatus>
            <S.Tooltip>
              <S.Tooltip.Trigger>
                {hasProviderUrl ? (
                  <S.OrderNumberLink
                    href={order?.provider_detail_url}
                    target="_blank"
                  >{`Pedido ${order?.name}`}</S.OrderNumberLink>
                ) : (
                  <S.OrderNumber>{`Pedido ${order?.name}`}</S.OrderNumber>
                )}
              </S.Tooltip.Trigger>

              <S.Tooltip.Content side="top" sideOffset={5}>
                <S.TooltipText>{`Pedido ${order?.name}`}</S.TooltipText>
                <S.Tooltip.Arrow />
              </S.Tooltip.Content>
            </S.Tooltip>
            <S.OrderStatus color={EBadgeColors.GRAY}>Pedido não conciliado</S.OrderStatus>
          </S.OrderNumberAndStatus>
        </S.ImageAndOrder>

        <S.OrderDetails>
          <S.Tooltip>
            <S.Tooltip.Trigger>
              <S.FirstOrderItem size={ETextSize.B5}>
                {firstOrderLineItem.product?.name}
              </S.FirstOrderItem>
            </S.Tooltip.Trigger>

            <S.Tooltip.Content side="top" sideOffset={5}>
              {order?.line_items.map(lineItem => (
                <S.TooltipText key={lineItem.alias_id}>{lineItem.product?.name}</S.TooltipText>
              ))}
              <S.Tooltip.Arrow />
            </S.Tooltip.Content>
          </S.Tooltip>
          <S.OrderDate size={ETextSize.B5}>
            {format(utcToZonedTime(order?.provider_created_at), 'dd/MM/yyyy HH:mm:ss')}
          </S.OrderDate>
        </S.OrderDetails>

        <S.OrderRevenueWrapper>
          <S.Tooltip>
            <S.Tooltip.Trigger>
              <S.OrderRevenueValue>{`${getFormattedCurrency(order?.currency)} ${numberFormatter(
                order?.provider_amount,
                2,
              )}`}</S.OrderRevenueValue>
            </S.Tooltip.Trigger>

            <S.Tooltip.Content side="top" sideOffset={5}>
              <S.TooltipText>{`${getFormattedCurrency(order?.currency)} ${numberFormatter(
                order?.provider_amount,
                2,
              )}`}</S.TooltipText>
              <S.Tooltip.Arrow />
            </S.Tooltip.Content>
          </S.Tooltip>
          <S.OrderRevenueDescription size={ETextSize.B5}>Receita</S.OrderRevenueDescription>
        </S.OrderRevenueWrapper>

        <S.SolveButton buttonSize={EButtonSize.SMALL} onClick={solveButtonClick}>
          Ajustar
        </S.SolveButton>
      </S.Wrapper>

      <Sidemodal isOpen={isSidemodalOpen} toggle={handleSidemodalOpen} order={order} />
    </>
  );
};

export default Order;
