import React from 'react';
import ReactDOM from 'react-dom';
import { SkeletonTheme } from 'react-loading-skeleton';

import { IModal } from '@domain/interfaces/components/IModal';

import { useLocalStorage } from '@helpers/hooks/useLocalStorage';
import ModalBody from './ModalBody';
import ModalHeader from './ModalHeader';

import * as S from './styles';

export interface IModalProps {
  isOpen: boolean;
  toggle: () => void;
  blockBackgroundClick?: boolean;
  children: React.ReactNode;
}

const CustomModal: IModal & React.FC<IModalProps> = ({
  toggle,
  isOpen,
  blockBackgroundClick = false,
  children,
  ...rest
}) => {
  const portalDiv = document.getElementById('portal');

  const [userTheme] = useLocalStorage('user-theme', 'dark');

  const backgroundClick = React.useCallback(
    event => {
      event.stopPropagation();

      if (!blockBackgroundClick) {
        toggle();
      }
    },
    [toggle, blockBackgroundClick],
  );

  const onContentClick = React.useCallback(event => {
    event.stopPropagation();
  }, []);

  if (!portalDiv) return null;

  return ReactDOM.createPortal(
    <>
      {isOpen && (
        <SkeletonTheme
          color={userTheme === 'dark' ? '#252931' : '#E8E9ED'}
          highlightColor={userTheme === 'dark' ? '#303440' : '#F0F1F4'}
        >
          <S.ModalContent onClick={onContentClick} {...rest}>
            {children}
          </S.ModalContent>
          <S.ModalBackground onClick={backgroundClick} />
        </SkeletonTheme>
      )}
    </>,
    portalDiv,
  );
};

CustomModal.Body = ModalBody;
CustomModal.Header = ModalHeader;

export default CustomModal;
