export enum EProvider {
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  TABOOLA = 'TABOOLA',
}

export enum EAdSenseProvider {
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  TABOOLA = 'TABOOLA',
  TIKTOK = 'TIKTOK',
}
