import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresCardOtherChargebackValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresCardOtherChargebackValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresCardOtherChargebackValuesResponse => {
  const currentSelectedStoreCardOtherChargebackAmountValue =
    currentSelectedStoreFinancialDetailsReportData?.card?.other?.charged_back.amount;

  const currentSelectedStoreCardOtherChargebackQuantityValue =
    currentSelectedStoreFinancialDetailsReportData?.card?.other?.charged_back.quantity;

  const storesCardOtherChargebackAmountValues = selectedStoresData?.map(
    value => value.financial_details_report?.card?.other?.charged_back.amount,
  );

  const storesCardOtherChargebackQuantityValues = selectedStoresData?.map(
    value => value.financial_details_report?.card?.other?.charged_back.quantity,
  );

  const cardOtherChargebackAmountValues = [
    ...storesCardOtherChargebackAmountValues,
    currentSelectedStoreCardOtherChargebackAmountValue,
  ];

  const cardOtherChargebackQuantityValues = [
    ...storesCardOtherChargebackQuantityValues,
    currentSelectedStoreCardOtherChargebackQuantityValue,
  ];

  const reducedCardOtherChargebackAmountValues = cardOtherChargebackAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedCardOtherChargebackQuantityValues = cardOtherChargebackQuantityValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  return {
    other_chargeback_amount: reducedCardOtherChargebackAmountValues,
    other_chargeback_quantity: reducedCardOtherChargebackQuantityValues,
  };
};
