import React from 'react';
import { CircleSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components/macro';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';

import * as S from './styles';

const Syncing: React.FC = () => {
  const theme = useTheme();

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title type={EHeadingSize.H2} fontWeight={EHeadingWeight.REGULAR}>
          Estamos sincronizando suas campanhas
        </S.Title>
        <S.Description type={EHeadingSize.H3} fontWeight={EHeadingWeight.REGULAR}>
          Você pode continuar navegando na plataforma enquanto isso.
        </S.Description>
        <CircleSpinner size={32} color={theme.colors.green.default} />
      </S.Content>
    </S.Wrapper>
  );
};

export default Syncing;
