import { AxiosResponse } from 'axios';

import {
  IGetUserResponse,
  IUserPromiseResponse,
  IAddAffiliateProps,
  IUpdateUserProps,
  IUpdateUserOnboardingProps,
} from '@domain/interfaces/IUser';

import { useFetch } from '@providers/Fetch/useFetch';

import { ApiService } from '@services/api';

import { USER_API_DOMAIN } from '@constants/api';

export class UserService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getUserPromise(
    authorization: string | undefined,
  ): Promise<AxiosResponse<IUserPromiseResponse>> {
    if (authorization) {
      return this.apiService.get<IUserPromiseResponse>('/api/v1/users/me', {
        headers: {
          authorization,
        },
      });
    }

    return this.apiService.get<IUserPromiseResponse>('/api/v1/users/me');
  }

  public getUser(): IGetUserResponse {
    const { data, error, mutate, isValidating } = useFetch('/api/v1/users/me', this.apiService);

    const isLoading = !data && !error;

    return {
      user: data?.user,
      affiliate: data?.affiliate,
      term: data?.term,
      user_intercom_hash: data?.user_intercom_hash,
      error,
      mutate,
      isValidating,
      isLoading,
    };
  }

  public updateUser({ data }: IUpdateUserProps): Promise<any> {
    return this.apiService.put('/api/v1/users/me', { ...data });
  }

  public addAffiliate({ data }: IAddAffiliateProps): Promise<any> {
    return this.apiService.post('/api/v1/users/me/affiliate', { ...data });
  }

  public updateUserOnboarding({ data }: IUpdateUserOnboardingProps): Promise<any> {
    return this.apiService.put('/api/v1/users/me/onboarding', { ...data });
  }

  public assignDemoStore(): Promise<any> {
    return this.apiService.post('/api/v1/users/me/demo-store');
  }
}

const userService = new UserService(USER_API_DOMAIN);

export default userService;
