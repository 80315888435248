export enum ETextType {
  SPAN = 'span',
  P = 'p',
}

export enum ETextWeight {
  LIGHT = 'light',
  REGULAR = 'regular',
  MEDIUM = 'medium',
  SEMIBOLD = 'semibold',
  BOLD = 'bold',
}

export enum ETextSize {
  B1 = 'b1',
  B2 = 'b2',
  B3 = 'b3',
  B4 = 'b4',
  B5 = 'b5',
}
