import React from 'react';

import errorImage from '@assets/components/PageError/error.svg';
import { ETextSize } from '@domain/enums/components/EText';
import { IPageErrorProps } from '@domain/interfaces/components/IPageError';

import * as S from './styles';

const PageError: React.FC<IPageErrorProps> = ({ mutate, isNotDashboardError }) => {
  return (
    <S.PageErrorWrapper isNotDashboardError={isNotDashboardError}>
      <S.Image src={errorImage} alt="Profitfy.me Algo Inesperado Aconteceu" />
      <S.Text size={ETextSize.B2}>
        Aconteceu um imprevisto e não foi possível carregar seus dados no momento.
      </S.Text>
      <S.Text>
        Você pode tentar carregar seus dados novamente, e se o problema persistir, contate o
        suporte.
      </S.Text>
      <S.Button onClick={mutate}>Tentar novamente</S.Button>
    </S.PageErrorWrapper>
  );
};

export default PageError;
