import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';

export const Tooltip = styled(CustomTooltipBase)``;

export const TooltipContent = styled(CustomTooltipBase.Content)`
  width: 100%;
  max-width: 281px;
`;

export const ProductCostText = styled(TextBase)`
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
`;

export const ProductCostDataContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;
