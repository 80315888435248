import React from 'react';

import { ISaveProps } from '@domain/interfaces/dashboard/ProductCostDetails/IHistoricSidemodal';

import * as S from './styles';

const Save: React.FC<ISaveProps> = ({ isSubmitting, isSaveButtonDisabled }) => {
  return (
    <S.Background>
      <S.ConfirmButton type="submit" isLoading={isSubmitting} disabled={isSaveButtonDisabled}>
        Salvar alterações
      </S.ConfirmButton>
    </S.Background>
  );
};

export default Save;
