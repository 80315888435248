import React from 'react';
import FacebookBreakEven from './FacebookBreakEven';
import ProfitEstimate from './ProfitEstimate';
import * as S from './styles';

const Tables: React.FC = () => {
  return (
    <S.Container>
      <ProfitEstimate />
      <FacebookBreakEven />
    </S.Container>
  );
};

export default Tables;
