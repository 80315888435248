import React from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { IParams } from '@domain/interfaces/IParams';

import {
  ICreateOrderProvider,
  IProductForOrder,
  INewProductForOrder,
} from '@domain/interfaces/common/orders/ICreateOrder';
import { IProduct } from '@domain/interfaces/common/product/IProduct';
import { IPeriod } from '@domain/interfaces/components/IDatePicker';

import { useOrders } from '@helpers/hooks/pages/dashboard/orders/useOrders';
import { useDate } from '@helpers/hooks/useDate';

import productService from '@services/common/product/product';

const CreateOrderContext = React.createContext<ICreateOrderProvider | null>(null);

export const CreateOrderProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();
  const { format, subDays } = useDate();
  const { newProductsForOrder, handleNewProductsForOrder, isCreatingOrder } = useOrders();

  const [selectedProduct, setSelectedProduct] = React.useState<IProductForOrder | undefined>(
    undefined,
  );
  const [initialProduct, setInitialProduct] = React.useState<IProductForOrder | undefined>(
    undefined,
  );
  const [isProductsComponentOpen, setIsProductsComponentOpen] = React.useState<boolean>(false);
  const [isLoadingProducts, setIsLoadingProducts] = React.useState<boolean>(false);
  const [productsPage, setProductsPage] = React.useState<number>(0);
  const [productsPageCount, setProductsPageCount] = React.useState<number>(1);
  const [products, setProducts] = React.useState<Array<IProduct>>([]);
  const [quantity, setQuantity] = React.useState<number>(1);
  const [isCardExpanded, setIsCardExpanded] = React.useState<boolean>(false);
  const [isHoveringInput, setIsHoveringInput] = React.useState<boolean>(false);
  const [isHoveringProductsComponent, setIsHoveringProductsComponent] = React.useState<boolean>(
    false,
  );
  const [index, setIndex] = React.useState<number>(0);
  const [searchName, setSearchName] = React.useState<string>('');

  const [period] = React.useState<IPeriod>({
    startDate: subDays(new Date(), 6),
    endDate: new Date(),
  });

  const inputComponentRef = React.useRef<HTMLDivElement>(null);
  const focusedProductCardRef = React.useRef<HTMLDivElement>(null);

  const handleCardExpanded = React.useCallback(() => {
    if (!isCreatingOrder) {
      setIsCardExpanded(!isCardExpanded);
    }
  }, [isCardExpanded, isCreatingOrder]);

  const onCardBlur = React.useCallback(() => {
    if (!selectedProduct && initialProduct) {
      const filteredNewProducts = newProductsForOrder.filter(
        newProductForOrder => newProductForOrder?.id !== initialProduct.id,
      );
      handleNewProductsForOrder(filteredNewProducts);
    }
    if (!selectedProduct && !initialProduct) {
      const newProducts = newProductsForOrder.slice(0, -1);
      handleNewProductsForOrder(newProducts);
    }
  }, [newProductsForOrder, initialProduct, handleNewProductsForOrder, selectedProduct]);

  const resetInfiniteScroll = React.useCallback(() => {
    setProducts([]);
    setProductsPage(0);
  }, []);

  const loadProducts = React.useCallback(async () => {
    setIsLoadingProducts(true);

    try {
      const { data } = await productService.listProductsPromise({
        storeAliasId,
        page: productsPage,
        startDate: format(period.startDate, 'yyyy-MM-dd'),
        endDate: format(period.endDate, 'yyyy-MM-dd'),
        filter: { name: searchName },
      });

      setProducts(currentProducts => [...currentProducts, ...data?.products]);
      setProductsPageCount(data?.total_pages);
      setIsLoadingProducts(false);
    } catch (error: any) {
      setIsLoadingProducts(false);
      // toast.error(error?.response?.data?.message);
    }
  }, [storeAliasId, productsPage, searchName, format, period]);

  const handleLoadMore = React.useCallback(() => {
    setProductsPage(oldPage => oldPage + 1);
  }, []);

  const handleSearchName = React.useCallback(
    debouncedSearchName => {
      resetInfiniteScroll();
      setSearchName(debouncedSearchName);
    },
    [resetInfiniteScroll, setSearchName],
  );

  const handleSelectProduct = React.useCallback(
    (product: IProduct) => {
      const productForOrder = {
        product,
        quantity,
        id: uuidv4(),
      };

      setSelectedProduct(productForOrder);
      setIsProductsComponentOpen(false);
      resetInfiniteScroll();

      if (initialProduct) {
        const mappedNewProducts = newProductsForOrder.map(productOrder => {
          if (productOrder?.id === initialProduct.id) {
            const newProduct = {
              product: productOrder.product,
              quantity,
              id: productOrder.id,
            };

            return newProduct;
          }

          return productOrder;
        });

        setInitialProduct(productForOrder);
        handleNewProductsForOrder(mappedNewProducts);
      }

      if (!initialProduct) {
        const newProducts = newProductsForOrder.slice(0, -1);
        const newProduct = {
          product: productForOrder.product,
          quantity,
          id: productForOrder.id,
        };

        setInitialProduct(productForOrder);
        handleNewProductsForOrder([...newProducts, newProduct]);
      }

      focusedProductCardRef?.current?.focus();
    },
    [resetInfiniteScroll, handleNewProductsForOrder, initialProduct, quantity, newProductsForOrder],
  );

  const onInputFocus = React.useCallback(() => {
    setIsProductsComponentOpen(true);
  }, []);

  const closeProductsComponent = React.useCallback(() => {
    resetInfiniteScroll();
    setIsProductsComponentOpen(false);
    focusedProductCardRef?.current?.focus();
  }, [resetInfiniteScroll]);

  const removeProduct = React.useCallback(() => {
    setSelectedProduct(undefined);
    focusedProductCardRef?.current?.focus();
  }, []);

  const increaseQuantity = React.useCallback(() => {
    const mappedProducts = newProductsForOrder.map(newProductForOrder => {
      if (newProductForOrder?.id === initialProduct?.id) {
        return {
          ...newProductForOrder,
          quantity: quantity + 1,
        };
      }

      return newProductForOrder;
    });

    handleNewProductsForOrder(mappedProducts);
    setQuantity(previousQuantity => previousQuantity + 1);
  }, [newProductsForOrder, handleNewProductsForOrder, initialProduct, quantity]);

  const decreaseQuantity = React.useCallback(() => {
    const mappedProducts = newProductsForOrder.map(newProductForOrder => {
      if (newProductForOrder?.id === initialProduct?.id && quantity > 1) {
        return {
          ...newProductForOrder,
          quantity: quantity - 1,
        };
      }

      return newProductForOrder;
    });

    handleNewProductsForOrder(mappedProducts);

    setQuantity(previousQuantity => {
      if (previousQuantity === 1) return previousQuantity;

      return previousQuantity - 1;
    });
  }, [handleNewProductsForOrder, newProductsForOrder, quantity, initialProduct]);

  const handleExistingProduct = React.useCallback((productForOrder: INewProductForOrder) => {
    setQuantity(productForOrder.quantity);
    setSelectedProduct({ product: productForOrder.product, id: productForOrder.id });
    setInitialProduct({ product: productForOrder.product, id: productForOrder.id });
  }, []);

  const handleIsHoveringInput = React.useCallback(status => setIsHoveringInput(status), []);

  const handleIsHoveringProductsComponent = React.useCallback(
    status => setIsHoveringProductsComponent(status),
    [],
  );

  const handleIndex = React.useCallback(newIndex => setIndex(newIndex), []);

  const hasMore = productsPage + 1 < productsPageCount;

  React.useEffect(() => {
    if (!initialProduct) setIsCardExpanded(true);
  }, [initialProduct]);
  return (
    <CreateOrderContext.Provider
      value={{
        isLoadingProducts,
        loadProducts,
        products,
        productsPage,
        productsPageCount,
        setProductsPage,
        handleLoadMore,
        hasMore,
        handleSelectProduct,
        selectedProduct,
        isProductsComponentOpen,
        onInputFocus,
        removeProduct,
        decreaseQuantity,
        increaseQuantity,
        quantity,
        isCardExpanded,
        handleCardExpanded,
        inputComponentRef,
        focusedProductCardRef,
        isHoveringInput,
        handleIsHoveringInput,
        closeProductsComponent,
        handleIndex,
        handleExistingProduct,
        index,
        onCardBlur,
        initialProduct,
        handleSearchName,
        handleIsHoveringProductsComponent,
        isHoveringProductsComponent,
      }}
    >
      {children}
    </CreateOrderContext.Provider>
  );
};

export const useCreateOrder = (): ICreateOrderProvider => {
  const context = React.useContext(CreateOrderContext);

  if (!context) {
    throw new Error('useCreateOrder must be used within an CreateOrderProvider');
  }

  return context;
};
