import styled, { css } from 'styled-components/macro';

import {
  ICardButton,
  IIntegrationCardHeadingProps,
  IStyleIntegrationCardProps,
  IIconWrapperProps,
} from '@domain/interfaces/dashboard/IntegrationCenter/IIntegrationCard';

import TextBase from '@components/common/core/DataDisplay/Text';

export const NotActivatedText = styled(TextBase)<ICardButton>`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.green.default};
  padding: 2px 8px;
  border-radius: 18px;
  transition: all 0.3s;

  &:hover {
    background-color: rgba(46, 179, 180, 0.3);
  }
`;

export const ActivatedText = styled(TextBase)<ICardButton>`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.green.default};
  padding: 2px 8px;
  border-radius: 18px;
  background-color: rgba(46, 179, 180, 0.3);
  transition: all 0.3s;
  font-weight: 500;
  font-size: 1rem;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const Heading = styled(TextBase)<IIntegrationCardHeadingProps>`
  margin-top: 12px;
  font-size: 1.2rem;
  line-height: 120%;

  ${({ isShopifyAlternative }) =>
    isShopifyAlternative &&
    css`
      font-size: 1rem;
    `}
`;

export const IconAndNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Card = styled.button<IStyleIntegrationCardProps>`
  height: 183px;
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  background-color: ${({ theme }) => theme.colors.background[2]};
  border-radius: 15px;
  background: ${({ theme }) => theme.colors.background[2]};
  border: solid 1px ${({ theme }) => theme.colors.card};
  justify-content: space-between;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.green.default};
    cursor: pointer;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background-color: ${({ theme }) => theme.colors.gray[5]};

      &:hover {
        border: 1px solid ${({ theme }) => theme.colors.gray[5]};
        cursor: not-allowed;
      }

      ${NotActivatedText} {
        background-color: unset;
        color: ${({ theme }) => theme.colors.gray[2]};
      }
    `}
`;

export const IconWrapper = styled.div<IIconWrapperProps>`
  opacity: 1;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.4;
    `}
`;
