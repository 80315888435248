import styled from 'styled-components/macro';

import ButtonBase from '@components/common/core/Inputs/Button';

export const CancelButton = styled(ButtonBase)``;

export const SaveButton = styled(ButtonBase)``;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background[2]};
  display: flex;
  justify-content: flex-end;
`;
