import React from 'react';

import { IPhoneInputProps } from '@domain/interfaces/components/IPhoneInput';

import * as S from './styles';

const PhoneInput: React.FC<IPhoneInputProps> = ({ ...props }) => {
  return <S.PhoneInput {...props} />;
};

export default PhoneInput;
