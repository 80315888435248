import { yupResolver } from '@hookform/resolvers/yup';
import { Copy, PerfectPay as Icon } from '@profitfy/pakkun-icons';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import { EKiwifyType } from '@domain/enums/common/integrations/providers/EKiwify';
import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { IParams } from '@domain/interfaces/IParams';

import { useInfoProduct } from '@helpers/hooks/pages/onboarding/useInfoProduct';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';
import { useToast } from '@helpers/hooks/useToast';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { perfectPaySchema } from '@helpers/validators/dashboard/integrationCenter/infoProduct/perfectPay';

// import kiwifyService from '@services/pages/dashboard/integrationCenter/infoProduct/platforms/kiwifyAuth';
import perfectPayService from '@services/pages/dashboard/integrationCenter/infoProduct/platforms/perfectPayAuth';

import NextStep from '@components/Onboarding/NextStep';
import Text from '@components/common/core/DataDisplay/Text';

import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const PerfectPay: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { selectedMarketings } = useInfoProduct();
  const { isLoadingTrial, handleStoreBonus } = useOnboarding();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(perfectPaySchema) });

  const [webhookURL, setWebhookURL] = React.useState<string>('');
  const [accessTokenValue, setAccessTokenValue] = React.useState<string>('');
  const [providerId, setProviderId] = React.useState<string>('');
  const [isURLCopied, setIsURLCopied] = React.useState<boolean>(false);
  const [isGettingWebhookURL, setIsGettingWebhookURL] = React.useState<boolean>(false);
  const [isCreatingCredential, setIsCreatingCredential] = React.useState<boolean>(false);

  const accessTokenInputRegister = register('access_token');
  const providerIdInputRegister = register('provider_id');

  const onAccessTokenChange = React.useCallback(
    event => {
      accessTokenInputRegister.onChange(event);

      setAccessTokenValue(event.target.value);
    },
    [accessTokenInputRegister],
  );

  const onProviderIdChange = React.useCallback(
    event => {
      providerIdInputRegister.onChange(event);

      setProviderId(event.target.value);
    },
    [providerIdInputRegister],
  );

  const handleCopyURL = React.useCallback(() => {
    navigator.clipboard.writeText(webhookURL);
    setIsURLCopied(true);
    toast.success('URL copiada com sucesso.');
  }, [toast, webhookURL]);

  const onSubmit = React.useCallback(
    async formData => {
      setIsCreatingCredential(true);

      const data = {
        public_token: formData.access_token,
        provider_id: formData.provider_id,
      };

      try {
        await perfectPayService.createCredential({ storeAliasId, data });

        toast.success('Credencial criada com sucesso.');

        setIsCreatingCredential(false);

        if (selectedMarketings.length) {
          history.push(`/onboarding/${storeAliasId}/info-product/integrations/marketing`);
        } else {
          await handleStoreBonus();
        }
      } catch (error: any) {
        toast.error(getTranslatedIntegrationsError(error));
      } finally {
        setIsCreatingCredential(false);
      }
    },
    [storeAliasId, toast, selectedMarketings, history, handleStoreBonus],
  );

  const generateWebhookURL = React.useCallback(async () => {
    setIsGettingWebhookURL(true);

    try {
      const { data } = await perfectPayService.generateWebhookURL({ storeAliasId });

      setWebhookURL(data?.webhook_url);
      setIsGettingWebhookURL(false);
    } catch (error: any) {
      setIsGettingWebhookURL(false);
      toast.error(error?.response?.data?.message);
    }
  }, [toast, storeAliasId]);

  React.useEffect(() => {
    generateWebhookURL();
  }, [generateWebhookURL]);

  if (isGettingWebhookURL) {
    return (
      <>
        <SkeletonLoading />
        <NextStep>Próximo passo</NextStep>
      </>
    );
  }

  return (
    <S.Wrapper>
      <S.FormWrapper>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Header>
            <S.Title>Integre sua plataforma de infoproduto</S.Title>
          </S.Header>

          <S.IntegrationTitle>
            <Icon size={36} />

            <S.IntegrationTitleText fontWeight={EHeadingWeight.REGULAR}>
              Integração com Perfect Pay
            </S.IntegrationTitleText>
          </S.IntegrationTitle>

          <S.Body>
            <S.InputGroup>
              <S.Label>URL do webhook</S.Label>

              <S.WebhookURLWrapper onClick={handleCopyURL} type="button">
                <S.WebhookURL weight={ETextWeight.MEDIUM}>{webhookURL}</S.WebhookURL>

                <S.IconWrapper>
                  <Copy size={18} color={theme.colors.gray[1]} outline={!isURLCopied} />
                </S.IconWrapper>
              </S.WebhookURLWrapper>
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Public Token</S.Label>

              <S.Input
                {...providerIdInputRegister}
                type="text"
                value={providerId}
                placeholder="Public Token"
                onChange={onProviderIdChange}
                isError={errors?.access_token}
              />
              {errors.provider_id && <Text isErrorFeedback>{errors.provider_id.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Token de Integração</S.Label>

              <S.Input
                {...accessTokenInputRegister}
                type="text"
                value={accessTokenValue}
                placeholder="Token de integração"
                onChange={onAccessTokenChange}
                isError={errors?.access_token}
              />
              {errors.access_token && <Text isErrorFeedback>{errors.access_token.message}</Text>}
            </S.InputGroup>
          </S.Body>

          <NextStep
            type="submit"
            isLoading={isCreatingCredential || isLoadingTrial}
            disabled={isCreatingCredential || !accessTokenValue || isLoadingTrial}
          >
            Próximo passo
          </NextStep>
        </S.Form>
      </S.FormWrapper>

      <S.TutorialWrapper>
        <S.TutorialImage
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/onboarding/images/content/onboarding/onboarding-integration-tutorial.svg"
          alt="Profitfy.me Integration Tutorial"
        />
        <S.TutotialTitle weight={ETextWeight.BOLD}>Tutorial de integração</S.TutotialTitle>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          1 - Copie a URL no campo &quot;URL do webhook&quot;.
        </S.TutotialStep>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          2 -{' '}
          <S.Link href="https://app.perfectpay.com.br/ferramentas/webhook" target="_blank">
            Clique aqui e vá para Perfect Pay.
          </S.Link>
        </S.TutotialStep>
        {/*  */}
        <S.StepsWrapper>
          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>Clique em &quot;Adicionar&quot;</S.StepText>
          </S.StepWrapper>
          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>Cole no campo &quot;URL&quot;</S.StepText>
          </S.StepWrapper>
        </S.StepsWrapper>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          3 - Após colar, copie o token do campo “Public token” na Kiwify e cole no campo “Public
          Token” da Profitfy.
        </S.TutotialStep>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          4 -{' '}
          <S.Link href="https://app.perfectpay.com.br/ferramentas/apis" target="_blank">
            Clique aqui e vá para Perfect Pay.
          </S.Link>
        </S.TutotialStep>
        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          Adicione um Token de integração, copie o token e cole no campo “Token de Integração” da
          Profitfy
        </S.TutotialStep>
      </S.TutorialWrapper>
    </S.Wrapper>
  );
};

export default PerfectPay;
