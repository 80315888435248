export const ORDERS_SORTER = [
  {
    value: 'tax_amount',
    label: 'Impostos',
  },
  {
    value: 'name',
    label: 'Pedido',
  },
  {
    value: 'status',
    label: 'Status',
  },
  {
    value: 'gateway_amount',
    label: 'Receita Líquida',
  },
];
