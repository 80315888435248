import React from 'react';
import { SimpleCheck } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { Question } from 'phosphor-react';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { EPlanRecurrence } from '@domain/enums/subscription/ESubscription';
import { IPlanProps } from '@domain/interfaces/pricing/plans/IPlan';

import { useConfig } from '@helpers/hooks/useConfig';

import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const Plan: React.FC<IPlanProps> = ({ plan, selectedRecurrenceType }) => {
  const isMonthlyRecurrence = selectedRecurrenceType === EPlanRecurrence.MONTHLY;
  const isQuarterRecurrence = selectedRecurrenceType === EPlanRecurrence.QUARTER;
  const isSemiAnualRecurrence = selectedRecurrenceType === EPlanRecurrence.SEMIANUAL;

  const theme = useTheme();
  const history = useHistory();
  const { analytics } = useConfig();

  const isMostPopularPlan = plan.name === 'Essentials';
  const isEnterprisePlan = plan.name === 'Enterprise';
  const isLitePlan = plan.name === 'Lite';
  const isUnlimitedPlan = plan.name === 'Unlimited';

  const onButtonClick = React.useCallback(() => {
    analytics?.track('Website Test For Free Button Clicked', {
      plan_name: plan.name,
      recurrence: selectedRecurrenceType,
    });

    history.push('/sign-up/1');
  }, [history, analytics, plan, selectedRecurrenceType]);

  const getPlanPrice = React.useCallback(() => {
    if (isMonthlyRecurrence) return numberFormatter(plan.monthlyPrice, 0);

    if (isQuarterRecurrence) return numberFormatter(plan.quarterMonthlyPrice || 0, 0);

    return numberFormatter(plan.semesterMonthlyPrice || 0, 0);
  }, [isMonthlyRecurrence, isQuarterRecurrence, plan]);

  const getRecurrenceText = React.useCallback(() => {
    if (isQuarterRecurrence && !isLitePlan && !isEnterprisePlan) return 'Cobrado a cada 3 meses';

    if (isSemiAnualRecurrence && !isLitePlan && !isEnterprisePlan) return 'Cobrado a cada 6 meses';

    return '';
  }, [isQuarterRecurrence, isSemiAnualRecurrence, isLitePlan, isEnterprisePlan]);

  const getExtraOrderPrice = React.useCallback(() => {
    if (isLitePlan) return 'Sem pedidos extras';

    if (selectedRecurrenceType === EPlanRecurrence.MONTHLY)
      return `${numberFormatter(plan.extraOrderPrice, 2)} por pedido extra`;

    if (selectedRecurrenceType === EPlanRecurrence.QUARTER)
      return `${numberFormatter(plan.extraOrderPriceQuarter, 2)} por pedido extra`;

    return `${numberFormatter(plan.extraOrderPriceSemiannual, 2)} por pedido extra`;
  }, [isLitePlan, selectedRecurrenceType, plan]);

  const isRecurrence = isQuarterRecurrence || isSemiAnualRecurrence;

  return (
    <S.Wrapper>
      <S.PlanCard isMostPopularPlan={isMostPopularPlan}>
        <S.PlanTitle type={EHeadingSize.H4}>{plan.name}</S.PlanTitle>

        {/* <S.PlanDescription>{plan.description}</S.PlanDescription> */}

        <S.PlanPriceWrapper>
          <S.PlanPrice>
            <S.Currency>R$</S.Currency>
            <S.Price>{getPlanPrice()}</S.Price>
          </S.PlanPrice>

          <S.PlanPriceLabel isRecurrence={isRecurrence}>por mês</S.PlanPriceLabel>
        </S.PlanPriceWrapper>

        {isRecurrence && <S.ChargeRecurrenceText>{getRecurrenceText()}</S.ChargeRecurrenceText>}

        <S.SignUpButton onClick={onButtonClick}>Testar gratuitamente</S.SignUpButton>

        <S.Benefits>
          <S.Benefit>
            <SimpleCheck size={14} color={theme.colors.green.default} />
            <S.BenefitText>
              <strong>{plan.ordersPerMonth === 0 ? 'Ilimitados' : plan.ordersPerMonth}</strong>{' '}
              Pedidos pagos/mês
            </S.BenefitText>
          </S.Benefit>

          <S.Benefit>
            <SimpleCheck size={14} color={theme.colors.green.default} />
            <S.BenefitText>
              <strong>
                {plan.marketingProvider === 0 ? 'Ilimitadas' : plan.marketingProvider}
              </strong>{' '}
              {plan.marketingProvider === 1 ? 'Fonte de tráfego' : 'Fontes de tráfego'}
            </S.BenefitText>
          </S.Benefit>

          <S.Benefit>
            <SimpleCheck size={14} color={theme.colors.green.default} />
            <S.BenefitText>
              <strong>{plan.members === 0 ? 'Ilimitados' : plan.members}</strong>{' '}
              {plan.members === 1 ? 'Usuário' : 'Usuários'}
            </S.BenefitText>
          </S.Benefit>

          <S.Benefit>
            <SimpleCheck size={14} color={theme.colors.green.default} />
            <S.BenefitText>
              <strong>{plan.facebookProfile === 0 ? 'Ilimitados' : plan.facebookProfile}</strong>{' '}
              {plan.facebookProfile === 1 ? 'Perfil do Facebook' : 'Perfis do Facebook'}
            </S.BenefitText>
          </S.Benefit>

          <S.Tooltip>
            <S.BenefitTooltipTrigger>
              <S.Benefit>
                <SimpleCheck size={14} color={theme.colors.green.default} />
                <S.BenefitText>
                  Automação de mensagens{' '}
                  <Question size={16} color={theme.colors.gray[1]} weight="bold" />
                </S.BenefitText>
              </S.Benefit>
            </S.BenefitTooltipTrigger>

            <S.TooltipContent side="top" sideOffset={5}>
              <S.Tooltip.Arrow />

              <S.TooltipText>
                Verifique as limitações para cada plano na tabela abaixo
              </S.TooltipText>
            </S.TooltipContent>
          </S.Tooltip>

          {plan.features.map((feature: string) => (
            <S.Benefit>
              <SimpleCheck size={14} color={theme.colors.green.default} />
              <S.BenefitText>{feature}</S.BenefitText>
            </S.Benefit>
          ))}
        </S.Benefits>

        {!isUnlimitedPlan && (
          <S.Tooltip>
            <S.TooltipTrigger>{getExtraOrderPrice()}</S.TooltipTrigger>

            <S.TooltipContent side="top" sideOffset={5}>
              <S.Tooltip.Arrow />

              <S.TooltipText>
                {isLitePlan ? (
                  <>
                    Caso você <strong>exceda a quantidade de pedidos</strong> contratado pelo plano,
                    você terá acesso aos pedidos até o momento em que foi excedido.
                  </>
                ) : (
                  <>
                    Caso você <strong>exceda a quantidade de pedidos</strong> contratado pelo plano,
                    será cobrado um valor <strong>adicional a cada pedido aprovado.</strong>
                  </>
                )}
              </S.TooltipText>
            </S.TooltipContent>
          </S.Tooltip>
        )}
      </S.PlanCard>
    </S.Wrapper>
  );
};

export default Plan;
