import styled from 'styled-components/macro';

import FilterButtonBase from '@components/common/core/Utils/Filter/FilterButton';

export const Content = styled.div`
  max-width: 1262px;
  width: 100%;
  position: relative;
`;

export const FilterButton = styled(FilterButtonBase)`
  margin-bottom: 16px;
`;
