import React from 'react';

import { ECampaignStatus } from '@domain/enums/dashboard/adsManager/ECampaigns';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';

import { useCampaigns } from '@helpers/hooks/pages/dashboard/adsManager/useCampaigns';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useToast } from '@helpers/hooks/useToast';

import * as S from './styles';

const DeactivateCampaignsModal: React.FC = () => {
  const {
    isDeactivateCampaignsModalOpen,
    handleDeactivateCampaignsModalOpen,
    selectedCampaigns,
    onUpdateStatus,
  } = useCampaigns();
  const { currentSynchronizations, synchronizationsQueue } = useSynchronization();
  const { toast } = useToast();

  const parsedCurrentSynchronizations = currentSynchronizations
    ? currentSynchronizations.synchronizations
    : [];
  const mergedSynchronizations = [...parsedCurrentSynchronizations, ...synchronizationsQueue];

  const activateCampaigns = React.useCallback(() => {
    const filteredSynchronizations = mergedSynchronizations.filter(
      synchronization => synchronization.type === ESynchronizationType.ADS_MANAGER_CAMPAIGNS_STATUS,
    );

    const hasActiveSynchronization = selectedCampaigns.find(campaignAliasId => {
      const foundSynchronization = filteredSynchronizations.find(synchronization =>
        synchronization.externalId?.includes(campaignAliasId),
      );

      return Boolean(foundSynchronization);
    });

    if (hasActiveSynchronization) {
      toast.error('Já existe um anúncio sendo atualizado no momento.');
      handleDeactivateCampaignsModalOpen();
    } else {
      onUpdateStatus(ECampaignStatus.PAUSED, selectedCampaigns);
      handleDeactivateCampaignsModalOpen();
    }
  }, [
    selectedCampaigns,
    onUpdateStatus,
    handleDeactivateCampaignsModalOpen,
    mergedSynchronizations,
    toast,
  ]);

  return (
    <S.Modal isOpen={isDeactivateCampaignsModalOpen} toggle={handleDeactivateCampaignsModalOpen}>
      <S.Modal.Header>
        <S.Title>Pausar campanhas</S.Title>
      </S.Modal.Header>
      <S.Modal.Body>
        <S.Description>{`Tem certeza em pausar ${selectedCampaigns.length} campanha(s)?`}</S.Description>

        <S.ButtonsWrapper>
          <S.CancelButton type="button" onClick={handleDeactivateCampaignsModalOpen}>
            Cancelar
          </S.CancelButton>
          <S.ActivateButton onClick={activateCampaigns} type="button">
            Pausar
          </S.ActivateButton>
        </S.ButtonsWrapper>
      </S.Modal.Body>
    </S.Modal>
  );
};

export default DeactivateCampaignsModal;
