import styled, { css } from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import ButtonBase from '@components/common/core/Inputs/Button';
import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';
import FormBase from '@components/common/core/Inputs/Form';

export const Popover = styled(CustomPopoverBase)``;

export const PopoverTrigger = styled(CustomPopoverBase.Trigger)``;

export const PopoverContent = styled(CustomPopoverBase.Content)`
  display: flex;
  flex-direction: column;
  width: 144px;
  border-radius: 5px;
`;

export const PopoverArrow = styled(CustomPopoverBase.Arrow)``;

export const PopoverOption = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background: unset;
  padding: 8px 16px;
  border: unset;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[1]};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[5]};
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 24px;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const Heading = styled(TextBase)`
  color: #fff;
`;

export const Form = styled(FormBase)`
  width: 100%;
  display: flex;
  gap: 24px;

  @media only screen and (max-width: 955px) {
    flex-direction: column;
  }
`;

export const MessageConfigurationsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InputGroupWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;

export const ConfigsWrapper = styled.div<{ hasMaxHeight: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: unset;

  ${({ hasMaxHeight }) =>
    hasMaxHeight &&
    css`
      max-height: 192px;
    `};

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[1]};
    border-radius: 25px;
    margin-bottom: 4px;
  }
`;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)`
  width: 100%;
`;

export const InputGroup = styled(InputGroupBase)`
  width: 100%;
`;

export const Select = styled(SelectBase)`
  width: 100%;
`;

export const Option = styled(OptionBase)``;

export const AddContentButtonWrapper = styled.div<{ hasMarginTop?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ hasMarginTop }) =>
    hasMarginTop &&
    css`
      margin-top: 32px;
    `}
`;

export const HorizontalRule = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray[5]};
`;

export const AddContentButton = styled(ButtonBase)`
  min-width: 175px;
  border-radius: 25px;

  span {
    font-size: 0.875rem;
    font-weight: 500;
  }
`;
