import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import ImageBase from '@components/common/core/DataDisplay/Image';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Button = styled(ButtonBase)`
  background: linear-gradient(225deg, #1f9aea 0%, #49f898 100%);
`;

export const Description = styled(TextBase)``;

export const Title = styled(TextBase)`
  font-size: 1.125rem;
  font-weight: 600;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Content = styled.div`
  padding: 0 14px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Image = styled(ImageBase)`
  margin-bottom: 32px;
`;

export const Modal = styled(ModalBase)`
  padding: 12px 12px 24px 12px;
  max-width: 502px;
  box-sizing: border-box;
`;
