import React from 'react';

import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { EBadgeColors } from '@domain/enums/components/EBadge';
import { ICustomValueBillingRowProps } from '@domain/interfaces/dashboard/Feed/ICustomValueBilling';

import { useFeedCustomValueBillings } from '@helpers/hooks/pages/dashboard/feed/useCustomValueBillings';
import { useDate } from '@helpers/hooks/useDate';

import { numberFormatter, currency as getCurrency } from '@helpers/masks';

import * as S from './style';

const CustomValueBillingRow: React.FC<ICustomValueBillingRowProps> = ({ customValueBilling }) => {
  const { format } = useDate();

  const {
    handleSelectedCustomValueBillings,
    selectedCustomValueBillings,
  } = useFeedCustomValueBillings();

  const onCheckboxChange = React.useCallback(
    event => {
      if (!event || event.target) return;

      if (event.checked) {
        const newCustomValueBillingId = event.value;

        handleSelectedCustomValueBillings([
          ...selectedCustomValueBillings,
          newCustomValueBillingId,
        ]);
      } else {
        const filteredCustomValueBillings = selectedCustomValueBillings?.filter(
          selectedExpense => selectedExpense !== event.value,
        );

        handleSelectedCustomValueBillings(filteredCustomValueBillings);
      }
    },
    [selectedCustomValueBillings, handleSelectedCustomValueBillings],
  );

  const billingDate = format(new Date(customValueBilling.billing_date), 'dd/MM/yyyy');

  const currency = getCurrency(customValueBilling.currency);

  const formattedBillingAmount = `${currency}${numberFormatter(customValueBilling.amount, 2)}`;

  const isCheckboxChecked = selectedCustomValueBillings.includes(customValueBilling.id);

  return (
    <S.Wrapper>
      <S.NameWrapper>
        <S.CheckboxWrapper>
          <S.SelectCustomValueBillingCheckbox
            id={customValueBilling.id}
            value={customValueBilling.id}
            checked={isCheckboxChecked}
            onChange={onCheckboxChange}
          />
        </S.CheckboxWrapper>

        <S.TitleAndDescriptionWrapper>
          <S.Title>{customValueBilling.custom_value.description}</S.Title>
          <S.Description size={ETextSize.B5}>Nome</S.Description>
        </S.TitleAndDescriptionWrapper>
      </S.NameWrapper>

      <S.PaymentDataWrapper>
        <S.Title weight={ETextWeight.MEDIUM}>{billingDate}</S.Title>
        <S.Description size={ETextSize.B5}>Data de pagamento</S.Description>
      </S.PaymentDataWrapper>

      <S.ValueWrapper>
        <S.Title weight={ETextWeight.MEDIUM}>{formattedBillingAmount}</S.Title>
        <S.Description size={ETextSize.B5}>Valor</S.Description>
      </S.ValueWrapper>

      <S.StatusWrapper>
        <S.Badge color={EBadgeColors.YELLOW} secondary>
          Pendente
        </S.Badge>

        <S.Description size={ETextSize.B5}>Status</S.Description>
      </S.StatusWrapper>

      {/* <S.CategoryWrapper>
        <S.Badge color={EBadgeColors.YELLOW} secondary>
        </S.Badge>

        <S.Description size={ETextSize.B5}>Categoria</S.Description>
      </S.CategoryWrapper> */}
    </S.Wrapper>
  );
};

export default CustomValueBillingRow;
