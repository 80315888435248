import { yupResolver } from '@hookform/resolvers/yup';
import {
  ChatTeardropText,
  DotsSixVertical,
  FilePdf,
  Image,
  Layout,
  SpeakerHigh,
  VideoCamera,
} from 'phosphor-react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { ETextSize } from '@domain/enums/components/EText';
import {
  ECategory,
  EConfigOption,
  EMessageType,
} from '@domain/enums/dashboard/automations/EMessage';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';
import { useCanvasContent } from '@helpers/hooks/pages/dashboard/automations/useCanvasContent';
import { useCanvasMessage } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessage';
import { useCanvasMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessageSettings';

import { messageSchema } from '@helpers/validators/dashboard/automations/messages/message';

import Text from '@components/common/core/DataDisplay/Text';
import Content from './Content';
import Preview from './Preview';
import SavedContent from './SavedContent';

import * as S from './styles';

const MAX_CONTENTS_PER_MESSAGE = 5;

const ConfigMessageContent: React.FC = () => {
  const theme = useTheme();
  const { handleWhatsappNodeSelectMessage } = useAutomation();
  const { updateMessage, createMessage } = useCanvasMessage();
  const {
    handleSelectedMessageTrigger,
    handleMessageName,
    selectedMessageToUpdate,
    contentsToUpdate,
    selectedMessageTrigger,
    isTemplateMessage,
    handleShouldCloseModal,
    handleContentsToUpdate,
  } = useCanvasMessageSettings();
  const { handleSelectedContentType, selectedContentType } = useCanvasContent();

  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(messageSchema), reValidateMode: 'onChange', mode: 'onBlur' });

  const messageNameRegister = register('message_name');

  const handlePopoverOpen = React.useCallback(() => {
    setIsPopoverOpen(!isPopoverOpen);
  }, [isPopoverOpen]);

  const onPopoverOptionClick = React.useCallback(
    event => {
      if (event.target.value === EConfigOption.PRODUCT_IMAGE) {
        const newContent = {
          id: Math.random(),
          type: EConfigOption.PRODUCT_IMAGE,
          content: '{{product_image}}',
        };

        handleContentsToUpdate([...contentsToUpdate, newContent]);
        handlePopoverOpen();
      } else {
        handleSelectedContentType(event.target.value);
        handlePopoverOpen();
      }
    },
    [handlePopoverOpen, handleSelectedContentType, handleContentsToUpdate, contentsToUpdate],
  );

  const onSelectTriggerChange = React.useCallback(
    event => {
      handleSelectedMessageTrigger(event.target.value);
    },
    [handleSelectedMessageTrigger],
  );

  const onMessageNameChange = React.useCallback(
    event => {
      messageNameRegister.onChange(event);

      handleMessageName(event.target.value);
    },
    [handleMessageName, messageNameRegister],
  );

  const filteredContentsToUpdate = contentsToUpdate.filter(content => !content.shouldBeExcluded);

  const onSubmit = React.useCallback(
    async data => {
      if (!selectedMessageToUpdate || !selectedMessageTrigger) return;

      if (selectedMessageToUpdate.alias_id === 'message-to-be-created') {
        const filteredContents = contentsToUpdate.filter(content => !content.shouldBeExcluded);

        const message = await createMessage(
          selectedMessageToUpdate.title,
          filteredContents,
          ECategory.WHATSAPP,
          selectedMessageTrigger,
          false,
        );

        if (message) {
          handleWhatsappNodeSelectMessage(message);
          handleShouldCloseModal(true);
        }
      } else {
        const message = await updateMessage(
          selectedMessageToUpdate?.alias_id,
          data.message_name,
          contentsToUpdate,
          ECategory.WHATSAPP,
          selectedMessageTrigger,
          isTemplateMessage,
        );

        if (message) {
          handleWhatsappNodeSelectMessage(message);
          handleShouldCloseModal(true);
        }
      }
    },
    [
      updateMessage,
      selectedMessageToUpdate,
      contentsToUpdate,
      selectedMessageTrigger,
      isTemplateMessage,
      createMessage,
      handleShouldCloseModal,
      handleWhatsappNodeSelectMessage,
    ],
  );

  const filteredContents = contentsToUpdate.filter(content => !content.shouldBeExcluded);
  const isWithinLimit = filteredContents.length < MAX_CONTENTS_PER_MESSAGE;

  return (
    <>
      <S.Header>
        <S.HeadingWrapper>
          <Layout size={28} color={theme.colors.gray[1]} />
          <S.Heading size={ETextSize.B3}>Configure a mensagem</S.Heading>
        </S.HeadingWrapper>
      </S.Header>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.MessageConfigurationsWrapper>
          <S.InputGroupWrapper>
            <S.InputGroup>
              <S.Label>Nome da mensagem</S.Label>
              <S.Input
                {...messageNameRegister}
                type="text"
                placeholder="digite o nome da mensagem"
                onChange={onMessageNameChange}
                defaultValue={selectedMessageToUpdate?.title}
                isError={errors.message_name}
              />
              {errors.message_name && <Text isErrorFeedback>{errors.message_name.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Tipo de Gatilho</S.Label>

              <S.Select
                name="choose-trigger-select"
                onChange={onSelectTriggerChange}
                defaultValue={selectedMessageToUpdate?.type}
              >
                <S.Option value={EMessageType.ABANDONED_CART}>Carrinho abandonado</S.Option>
                <S.Option value={EMessageType.BOLETO}>Boleto gerado</S.Option>
                <S.Option value={EMessageType.REJECTED_PAYMENT}>Cartão recusado</S.Option>
                <S.Option value={EMessageType.PIX}>Pix gerado</S.Option>
                <S.Option value={EMessageType.SIMPLE_SHIPMENT}>Rastreio criado</S.Option>
                <S.Option value={EMessageType.UPDATED_FULFILLMENT}>Rastreio por status</S.Option>
              </S.Select>
            </S.InputGroup>
          </S.InputGroupWrapper>

          <S.ConfigsWrapper hasMaxHeight={selectedContentType === EConfigOption.NONE}>
            <Content />

            {selectedContentType === EConfigOption.NONE && (
              <>
                {filteredContentsToUpdate?.map((content, index) => {
                  if (content.type === EConfigOption.TEXT)
                    return (
                      <SavedContent
                        key={content.id}
                        configOption={EConfigOption.TEXT}
                        contentId={content.id}
                        contentText="Conteúdo de texto"
                        icon={DotsSixVertical}
                        index={index}
                      />
                    );

                  if (content.type === EConfigOption.PRODUCT_IMAGE)
                    return (
                      <SavedContent
                        key={content.id}
                        configOption={EConfigOption.PRODUCT_IMAGE}
                        contentId={content.id}
                        contentText="Imagem do Produto"
                        icon={DotsSixVertical}
                        index={index}
                      />
                    );

                  if (content.type === EConfigOption.IMAGE)
                    return (
                      <SavedContent
                        key={content.id}
                        configOption={EConfigOption.IMAGE}
                        contentId={content.id}
                        contentText="Conteúdo de imagem"
                        icon={DotsSixVertical}
                        index={index}
                      />
                    );

                  if (content.type === EConfigOption.AUDIO)
                    return (
                      <SavedContent
                        key={content.id}
                        configOption={EConfigOption.AUDIO}
                        contentId={content.id}
                        contentText="Conteúdo de áudio"
                        icon={DotsSixVertical}
                        index={index}
                      />
                    );

                  if (content.type === EConfigOption.VIDEO)
                    return (
                      <SavedContent
                        key={content.id}
                        configOption={EConfigOption.VIDEO}
                        contentId={content.id}
                        contentText="Conteúdo de vídeo"
                        icon={DotsSixVertical}
                        index={index}
                      />
                    );

                  if (content.type === EConfigOption.PDF)
                    return (
                      <SavedContent
                        key={content.id}
                        configOption={EConfigOption.PDF}
                        contentId={content.id}
                        contentText="Conteúdo de PDF"
                        icon={DotsSixVertical}
                        index={index}
                      />
                    );

                  return <></>;
                })}
              </>
            )}
          </S.ConfigsWrapper>

          {selectedContentType === EConfigOption.NONE && isWithinLimit && (
            <S.AddContentButtonWrapper hasMarginTop={Boolean(contentsToUpdate?.length)}>
              <S.HorizontalRule />

              <S.Popover open={isPopoverOpen} onOpenChange={handlePopoverOpen}>
                <S.PopoverTrigger>
                  <S.AddContentButton type="button" variant={EButtonVariant.SECONDARY_BUTTON}>
                    + Adicionar conteúdo
                  </S.AddContentButton>
                </S.PopoverTrigger>

                <S.PopoverContent sideOffset={4.5}>
                  <S.PopoverOption value={EConfigOption.TEXT} onClick={onPopoverOptionClick}>
                    <ChatTeardropText size={18} color={theme.colors.gray[1]} />
                    Texto
                  </S.PopoverOption>

                  <S.PopoverOption value={EConfigOption.IMAGE} onClick={onPopoverOptionClick}>
                    <Image size={18} color={theme.colors.gray[1]} />
                    Imagem
                  </S.PopoverOption>

                  <S.PopoverOption value={EConfigOption.AUDIO} onClick={onPopoverOptionClick}>
                    <SpeakerHigh size={18} color={theme.colors.gray[1]} />
                    Áudio
                  </S.PopoverOption>

                  <S.PopoverOption value={EConfigOption.VIDEO} onClick={onPopoverOptionClick}>
                    <VideoCamera size={18} color={theme.colors.gray[1]} />
                    Vídeo
                  </S.PopoverOption>

                  <S.PopoverOption value={EConfigOption.PDF} onClick={onPopoverOptionClick}>
                    <FilePdf size={18} color={theme.colors.gray[1]} />
                    PDF
                  </S.PopoverOption>

                  <S.PopoverOption
                    value={EConfigOption.PRODUCT_IMAGE}
                    onClick={onPopoverOptionClick}
                  >
                    <Image size={18} color={theme.colors.gray[1]} />
                    Imagem do Produto
                  </S.PopoverOption>

                  <S.PopoverArrow />
                </S.PopoverContent>
              </S.Popover>

              <S.HorizontalRule />
            </S.AddContentButtonWrapper>
          )}
        </S.MessageConfigurationsWrapper>

        <Preview />
      </S.Form>
    </>
  );
};

export default ConfigMessageContent;
