import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Sent } from '@profitfy/pakkun-icons';

import logoImg from '@assets/img/brand/profitfy-logo.svg';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import Form from '@components/common/core/Inputs/Form';
import { forgotPasswordSchema } from '@helpers/validators/forgotPassword';
import Text from '@components/common/core/DataDisplay/Text';
import forgotPasswordService from '@services/pages/auth/forgotPassword';
import { useToast } from '@helpers/hooks/useToast';

import * as S from './styles';

const ForgotPassword: React.FC = () => {
  const { toast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(forgotPasswordSchema),
  });

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      try {
        await forgotPasswordService.forgotPassword({ ...formData });

        toast.success('Um e-mail de recuperação de senha foi enviado!');
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsSubmittingForm(false);
      }
    },
    [toast],
  );

  return (
    <S.Container>
      <S.Wrapper>
        <S.Logo src={logoImg} alt="Profitfy.me Logo" />

        <S.ContentWrapper>
          <S.Heading type={EHeadingSize.H3} fontWeight={EHeadingWeight.REGULAR}>
            Recuperar senha
          </S.Heading>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <S.InputGroup>
              <S.Label>Enviaremos um link de recuperação para:</S.Label>
              <S.Input
                {...register('email')}
                type="text"
                name="email"
                placeholder="Digite seu e-mail"
              />
              {errors.email && <Text isErrorFeedback>{errors.email.message}</Text>}
            </S.InputGroup>

            <S.Button
              type="submit"
              iconPrepend={Sent}
              isLoading={isSubmittingForm}
              disabled={isSubmittingForm}
            >
              Enviar
            </S.Button>
          </Form>
        </S.ContentWrapper>
      </S.Wrapper>
    </S.Container>
  );
};

export default ForgotPassword;
