import { AxiosResponse } from 'axios';

import {
  ICreateTinyCredentialProps,
  ICreateTinyCredentialResponse,
  IDisableTinyCredentialProps,
  IEnableTinyCredentialProps,
  IGetTinyCredentialPromiseProps,
  IGetTinyCredentialPromiseResponse,
  IGetTinyCredentialProps,
  IGetTinyCredentialResponse,
  IUpdateTinyCredentialProps,
  IUpdateTinyCredentialResponse,
  IGenerateURLProps,
} from '@domain/interfaces/dashboard/IntegrationCenter/Ecommerce/Tiny/ITinyAuth';

import { ApiService } from '@services/api';

import { useFetch } from '@providers/Fetch/useFetch';

import { API_DOMAIN } from '@constants/api';

export class TinyAuthService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public createTinyCredential({
    storeAliasId,
    data,
  }: ICreateTinyCredentialProps): Promise<AxiosResponse<ICreateTinyCredentialResponse>> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/tiny-credentials`, {
      ...data,
    });
  }

  public getTinyCredential({ storeAliasId }: IGetTinyCredentialProps): IGetTinyCredentialResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/tiny-credentials`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      tinyCredential: data?.tiny_credential,
      isLoading,
      isValidating,
      error,
      mutate,
    };
  }

  public getTinyCredentialPromise({
    storeAliasId,
  }: IGetTinyCredentialPromiseProps): Promise<AxiosResponse<IGetTinyCredentialPromiseResponse>> {
    return this.apiService.get(`/api/v1/users/stores/${storeAliasId}/tiny-credentials`);
  }

  public disableTinyCredential({
    storeAliasId,
    tinyCredentialAliasId,
  }: IDisableTinyCredentialProps): Promise<AxiosResponse<void>> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/tiny-credentials/${tinyCredentialAliasId}`,
      { is_active: false },
    );
  }

  public enableTinyCredential({
    storeAliasId,
    tinyCredentialAliasId,
  }: IEnableTinyCredentialProps): Promise<AxiosResponse<void>> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/tiny-credentials/${tinyCredentialAliasId}`,
      { is_active: true },
    );
  }

  public updateTinyCredential({
    storeAliasId,
    tinyCredentialAliasId,
    data,
  }: IUpdateTinyCredentialProps): Promise<AxiosResponse<IUpdateTinyCredentialResponse>> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/tiny-credentials/${tinyCredentialAliasId}`,
      { ...data },
    );
  }

  public async generateWebhookURL({ storeAliasId }: IGenerateURLProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/tiny-credentials/generate-url`,
    );
  }
}

const tinyAuthService = new TinyAuthService(API_DOMAIN);

export default tinyAuthService;
