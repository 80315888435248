import styled from 'styled-components/macro';

export const ValueToFilterInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  background-color: unset;
  border: none;
  color: ${({ theme }) => theme.colors.font};
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;

  &::placeholder {
    color: ${({ theme }) => theme.colors.font};
    opacity: 0.6;
  }

  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.font};
    opacity: 0.6;
  }

  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.font};
    opacity: 0.6;
  }

  &:focus {
    outline: none;
  }
`;

export const ValueToFilterWrapper = styled.div`
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[5]};
`;

export const Wrapper = styled.div`
  max-height: 364px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 4px;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.gray[4]};
    position: absolute;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[1]};
    border-radius: 25px;
    transition: all 0.3s;
  }

  ::-webkit-scrollbar-thumb:hover {
    opacity: brightness(0.8);
  }

  & > div > div {
    display: flex;
    flex-direction: column;
    padding: 16px 24px 20px 24px;
    box-sizing: border-box;
    align-items: flex-start;
    gap: 20px;
  }
`;
