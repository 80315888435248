import styled from 'styled-components/macro';

export const NameWrapper = styled.div`
  max-width: 180px;
  width: 100%;

  & > span > span {
    display: block;
  }
`;

export const IdWrapper = styled.div`
  max-width: 164px;
  width: 100%;

  & > span > span {
    display: block;
  }
`;

export const AdSenseCredentialWrapper = styled.div`
  width: 100%;
  gap: 2px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
