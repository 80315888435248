import styled, { css } from 'styled-components/macro';

import {
  ITableHeadProps,
  ITableWrapperProps,
  ITableDataProps,
  IMoreOptionsWrapperProps,
} from '@domain/interfaces/dashboard/CustomValues/IBillingTable';
import { EHeadAlign } from '@domain/enums/components/ETable';
import BadgeBase from '@components/common/core/DataDisplay/Badge';
import zIndex from '@styles/themes/common/zIndex';

export const OptionDot = styled.span`
  display: block;
  width: 4px;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.gray[2]};
  border-radius: 50%;
`;

export const MoreOptionsWrapper = styled.button<IMoreOptionsWrapperProps>`
  width: 26px;
  height: 26px;
  align-items: center;
  justify-content: center;
  gap: 2px;
  opacity: 0;
  display: flex;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  border-radius: 50%;
  transition: filter 0.3s;
  border: unset;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  ${({ isHovering }) =>
    isHovering &&
    css`
      opacity: 1;
    `}
`;

export const Badge = styled(BadgeBase)`
  margin: 0 auto;
`;

export const TableFooter = styled.tfoot`
  & > tr {
    border-bottom: unset;
    background-color: ${({ theme }) => theme.colors.black.dark};

    & > td {
      padding: 16px;

      & > div {
        display: inline-block;
      }
    }
  }
`;

export const TableData = styled.td<ITableDataProps>`
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.font};

  ${({ textAlign }) =>
    textAlign === EHeadAlign.CENTER &&
    css`
      text-align: center;
    `};

  ${({ textAlign }) =>
    textAlign === EHeadAlign.RIGHT &&
    css`
      text-align: right;
    `};
`;

export const TableHead = styled.th<ITableHeadProps>`
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.font};
  text-align: left;

  ${({ textAlign }) =>
    textAlign === EHeadAlign.CENTER &&
    css`
      text-align: center;
    `};

  ${({ textAlign }) =>
    textAlign === EHeadAlign.RIGHT &&
    css`
      text-align: right;
    `};
`;

export const TableRow = styled.tr``;

export const TableBody = styled.tbody`
  & > tr {
    background-color: ${({ theme }) => theme.colors.background[8]};

    & > td {
      padding: 12px 128px;
    }

    & > td:first-child {
      padding-left: 120px;
    }

    & > td:last-child {
      padding-right: 120px;
    }
  }
`;

export const TableHeader = styled.thead`
  box-shadow: ${({ theme }) => `inset 0px 0px 0px 1px ${theme.colors.gray[4]}`};
  background-color: ${({ theme }) => theme.colors.background[1]};

  & > tr {
    & > th {
      padding: 12px 128px;
    }

    & > th:first-child {
      padding-left: 120px;
    }

    & > th:last-child {
      padding-right: 120px;
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  min-width: max-content;
  border-collapse: collapse;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray[4]}`};
`;

export const ChevronButton = styled.button`
  position: absolute;
  top: -16px;
  right: 36px;
  height: 40px;
  width: 40px;
  background-color: ${({ theme }) => theme.colors.background[8]};
  border: 1px solid ${({ theme }) => theme.colors.gray[1]};
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s;
  z-index: ${zIndex.firstLayer};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    filter: brightness(1.1);
  }

  &:hover {
    filter: brightness(0.9);
  }
`;

export const TableWrapper = styled.div<ITableWrapperProps>`
  position: relative;
  max-height: 170px;
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  bottom: -6px;

  &::-webkit-scrollbar {
    height: 6px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.background[3]};
    border-radius: 25px;
    margin-bottom: 4px;
  }

  ${({ isMaximized, maxHeight }) =>
    isMaximized &&
    css`
      max-height: ${`${maxHeight}px`};
      overflow-y: scroll;
    `}
`;
