import React from 'react';

import { IHeading } from '@domain/interfaces/components/IHeading';
import { EHeadingWeight, EHeadingSize } from '@domain/enums/components/EHeading';

import * as S from './styles';

const Heading: React.FC<IHeading> = ({
  type = EHeadingSize.H4,
  fontWeight = EHeadingWeight.MEDIUM,
  children,
  isHiddenContent,
  ...rest
}) => {
  return (
    <S.Heading
      as={type}
      type={type}
      fontWeight={fontWeight}
      isHiddenContent={isHiddenContent}
      {...rest}
    >
      {!isHiddenContent ? children : <span {...rest}>{children}</span>}
    </S.Heading>
  );
};

export default Heading;
