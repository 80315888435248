/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';

import { useTheme } from 'styled-components';
import { Play, Microphone, Pause } from 'phosphor-react';

import { IAudioPlayerProps } from '@domain/interfaces/common/automations/IAudioPlayer';

import * as S from './styles';

const AudioPlayer: React.FC<IAudioPlayerProps> = ({ audioSource, handleRecordingFinished }) => {
  const theme = useTheme();

  const audioRef = React.useRef<HTMLAudioElement>(null);
  const sliderRef = React.useRef(null);
  const trackRef = React.useRef<any>(null);

  const [playState, setPlayState] = React.useState<string>('play');
  const [currentTime, setCurrentTime] = React.useState<number>(0);
  const [sliderValue, setSliderValue] = React.useState<number>(0);

  const onPlay = React.useCallback(() => {
    if (playState === 'play') {
      audioRef?.current?.play();

      setPlayState('pause');
    } else {
      audioRef?.current?.pause();
      setPlayState('play');
    }
  }, [playState]);

  const calculateTime = (timeInSeconds: number): string => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);

    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${minutes}:${returnedSeconds}`;
  };

  const handleChange = React.useCallback(newValue => {
    setSliderValue(newValue);

    const audio = audioRef.current;

    if (audio) {
      const newTime = (newValue / 100) * audio.duration;

      audio.currentTime = newTime;
    }
  }, []);

  const handleTrackClick = React.useCallback(event => {
    const track = trackRef.current;

    if (track) {
      const { left, width } = track.getBoundingClientRect();

      const positionX = event.clientX - left;

      const newValue = (positionX / width) * 100;

      setSliderValue(newValue);

      const audio = audioRef.current;

      if (audio) {
        const newTime = (newValue / 100) * audio.duration;

        audio.currentTime = newTime;
      }
    }
  }, []);

  const handleTimeUpdate = React.useCallback(() => {
    const time = audioRef.current?.currentTime ?? 0;

    setCurrentTime(time);

    const duration = audioRef.current?.duration ?? 0;

    const progress = (time / duration) * 100;

    setSliderValue(progress);
  }, []);

  React.useEffect(() => {
    audioRef.current?.addEventListener('timeupdate', handleTimeUpdate);
    audioRef.current?.addEventListener('ended', handleTimeUpdate);

    return () => {
      audioRef.current?.removeEventListener('timeupdate', handleTimeUpdate);
      audioRef.current?.removeEventListener('ended', handleTimeUpdate);
    };
  }, [handleTimeUpdate]);

  return (
    <S.Wrapper>
      <S.Audio ref={audioRef} src={audioSource} preload="metadata" />

      <S.MicrophoneButton onClick={handleRecordingFinished} type="button">
        <Microphone size={20} color={theme.colors.gray[1]} weight="fill" />
      </S.MicrophoneButton>

      <S.PlayButton onClick={onPlay} type="button">
        {playState === 'play' && <Play size={20} color={theme.colors.gray[1]} weight="fill" />}

        {playState === 'pause' && <Pause size={20} color={theme.colors.gray[1]} weight="fill" />}
      </S.PlayButton>

      <S.CurrentTime>{calculateTime(currentTime)}</S.CurrentTime>

      <S.SliderRoot
        ref={sliderRef}
        className="SliderRoot"
        value={[sliderValue]}
        max={100}
        step={1}
        aria-label="Volume"
        onChange={handleChange}
      >
        <S.SliderTrack className="SliderTrack" ref={trackRef} onClick={handleTrackClick}>
          <S.SliderRange className="SliderRange" />
        </S.SliderTrack>

        <S.SliderThumb className="SliderThumb" />
      </S.SliderRoot>
    </S.Wrapper>
  );
};

export default AudioPlayer;
