import React from 'react';

import { EUpdateFulfillmentFulfillmentStatusOptions } from '@domain/enums/dashboard/automations/node/conditionalNode/EUpdateFulfillmente';

import * as S from './styles';

interface IOptionStatusProps {
  defaultValue?: string;
  onChange: (data: string) => void;
}

const OptionFulfillmentStatus: React.FC<IOptionStatusProps> = ({
  defaultValue = EUpdateFulfillmentFulfillmentStatusOptions.EQUAL,
  onChange,
}) => {
  return (
    <S.Select
      name="options"
      defaultValue={defaultValue}
      onChange={e => {
        onChange(e.target.value);
      }}
    >
      <S.Option value={EUpdateFulfillmentFulfillmentStatusOptions.EQUAL}>Igual</S.Option>
      <S.Option value={EUpdateFulfillmentFulfillmentStatusOptions.NOT_EQUAL}>Diferente de</S.Option>
    </S.Select>
  );
};

export default OptionFulfillmentStatus;
