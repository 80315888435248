import styled from 'styled-components/macro';

export const BillingWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  padding-left: 90px;
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    padding: 0;
    bottom: 64px;
  }
`;
