import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { EProgressBarStep } from '@domain/enums/onboarding/progressBar/ESteps';
import { IParams } from '@domain/interfaces/IParams';

import { useEcommerce } from '@helpers/hooks/pages/onboarding/useEcommerce';

import { ECOMMERCE_PROVIDERS } from '@constants/common/integrations';

import SideBar from '@components/Onboarding/SideBar';

import * as S from './styles';

const Platforms: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const history = useHistory();
  const { selectedProvider, hasSelectedStoreIntegrations } = useEcommerce();

  const getProviderContent = React.useCallback(
    (provider: typeof ECOMMERCE_PROVIDERS[number] | undefined) => {
      if (provider) {
        const Content = provider.onboardingContent;

        return <Content />;
      }

      return <></>;
    },
    [],
  );

  React.useEffect(() => {
    if (!selectedProvider && hasSelectedStoreIntegrations) {
      history.push(`/onboarding/${storeAliasId}/ecommerce/integrations/gateways`);
    }

    if (!selectedProvider && !hasSelectedStoreIntegrations) {
      history.push(`/onboarding/${storeAliasId}/ecommerce/integrations`);
    }
  }, [history, storeAliasId, selectedProvider, hasSelectedStoreIntegrations]);

  const foundProvider = ECOMMERCE_PROVIDERS.find(
    integration => integration.identifier === selectedProvider,
  );

  return (
    <S.Wrapper>
      <SideBar
        onboardingStep={EProgressBarStep.INTEGRATIONS}
        linkToGoBack={`/onboarding/${storeAliasId}/ecommerce/integrations`}
      />
      {getProviderContent(foundProvider)}
    </S.Wrapper>
  );
};

export default Platforms;
