import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import DatePickerBase from '@components/common/core/Utils/DatePicker';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const DetailsHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 16px;

  @media only screen and (max-width: 568px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 16px;
  }
`;

export const DatePicker = styled(DatePickerBase)`
  & > button {
    & > span {
      color: ${({ theme }) => theme.colors.background[6]};
    }
  }
`;

export const DetailsTextColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DetailsTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const DetailsText = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.background[6]};
`;

export const TaxSubtitle = styled(TextBase)`
  font-size: 0.875rem;
`;

export const TaxText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.green.default};
  font-size: 1.25rem;
  font-weight: 700;
`;

export const HeadingText = styled(HeadingBase)``;

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
`;

export const FiltersSortWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const AddOrderButton = styled.button`
  border: 0;
  border-radius: 5px;
  background-color: #00f2a1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Mukta';
  font-weight: 700;
  font-size: 0.875rem;
  height: 42px;
  padding: 12px 22px;
  color: ${({ theme }) => theme.colors.black.default};
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
  }
`;
