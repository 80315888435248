import { IData } from '@domain/interfaces/components/IFilter';

import { DROPSHIPPING_PROVIDERS } from '@constants/common/integrations';

export const parseProviderData = (): Array<IData> => {
  const mergedIntegrations = [...DROPSHIPPING_PROVIDERS];

  const parsedProviderData = mergedIntegrations.map(integration => {
    return {
      value: integration.identifier,
      label: integration.name,
    };
  });

  return parsedProviderData;
};

export const getParsedStatus = (status: string): string =>
  ({
    PAID: 'Aprovado',
    PENDING: 'Pendente',
    CANCELED: 'Cancelado',
    REFUNDED: 'Estornado',
    CHARGED_BACK: 'Chargeback',
    IN_ANALYSIS: 'Em Análise',
    REJECTED: 'Recusado',
    NO_INFORMATION: 'Sem Informação',
  }[status] || 'Sem Informação');

export const getParsedUtmSource = (utmSource: string): string =>
  ({
    FACEBOOK: 'Facebook',
    GOOGLE: 'Google',
    TIKTOK: 'Tiktok',
    TABOOLA: 'Taboola',
    ABANDONED_CART: 'Carrinho abandonado',
    SMS: 'SMS',
    OTHER: 'Outro',
    EMAIL: 'E-mail',
    ORGANIC: 'Tráfego orgânico',
  }[utmSource] || '');
