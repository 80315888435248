import React from 'react';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Copy } from '@profitfy/pakkun-icons';

import { EButtonSize } from '@domain/enums/components/EButton';
import { INoUtmLinksProps } from '@domain/interfaces/dashboard/AdsManager/UtmSidemodal/INoUtmLinks';

import { useToast } from '@helpers/hooks/useToast';

import * as S from './styles';

const NoUtmLinks: React.FC<INoUtmLinksProps> = ({ adSenseAds }) => {
  const scriptCode = `utm_source=facebook-profitfy&utm_medium={{placement}}
&utm_campaign={{campaign.id}}&utm_term={{adset.id}}&utm_content={{ad.id}}`;

  const { toast } = useToast();

  const [isCodeCopied, setIsCodeCopied] = React.useState<boolean>(false);

  const handleCopyCode = React.useCallback(() => {
    navigator.clipboard.writeText(scriptCode);
    setIsCodeCopied(true);
    toast.success('Copiado com sucesso!');
  }, [scriptCode, toast]);

  return (
    <S.Wrapper>
      <S.Title>Adicionar Profitfy UTM Tags Manualmente</S.Title>

      <S.Description>
        Caso a sincronização automática falhe, é possível adicionar as UTMs de forma manual, basta
        copiar o conteúdo abaixo e colar no campo de Parâmetros de url nos criativos.
      </S.Description>

      <S.CodeWrapper>
        <S.CodeEditor language="javascript" style={darcula} showLineNumbers>
          {scriptCode}
        </S.CodeEditor>
        <S.CopyButton buttonSize={EButtonSize.MEDIUM} onClick={handleCopyCode}>
          <Copy size={14} />
          {isCodeCopied ? 'Copiado' : 'Copiar'}
        </S.CopyButton>
      </S.CodeWrapper>

      <S.LinksWrapper>
        {adSenseAds.map(adSenseAd => (
          <S.Link href={adSenseAd.external_url} target="_blank" key={adSenseAd.alias_id}>
            {adSenseAd.name}
          </S.Link>
        ))}
      </S.LinksWrapper>
    </S.Wrapper>
  );
};

export default NoUtmLinks;
