import React from 'react';

import { EHeadingWeight } from '@domain/enums/components/EHeading';

import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const Header: React.FC = () => {
  const { user } = useConfig();

  return (
    <S.Wrapper>
      <S.Title
        fontWeight={EHeadingWeight.REGULAR}
      >{`${user?.first_name}, bem-vindo(a) à Profitfy!`}</S.Title>
      <S.Description>
        Enquanto seus dados estão sendo processados, você pode realizar algumas outras configurações
        secundarias para deixar sua análise mais precisa.
      </S.Description>
    </S.Wrapper>
  );
};

export default Header;
