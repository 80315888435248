import styled from 'styled-components/macro';

import CardBase from '@components/common/core/Surfaces/Card';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Text = styled(TextBase)``;

export const Card = styled(CardBase)`
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  position: relative;
  gap: 12px;
  border-radius: 8px;

  & > svg {
    min-width: 24px;
  }
`;

export const Button = styled.button`
  border: none;
  padding: 0;
  background-color: transparent;
  width: 100%;
  margin-bottom: 16px;
  z-index: ${({ theme }) => theme.zIndex.firstLayer};
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.2);
  }

  &:active {
    filter: brightness(0.9);
  }
`;
