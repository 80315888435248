import { parseISO, format } from 'date-fns';
import { numberFormatter } from '@helpers/masks';

export function getSpreadSheetHeaders(data: any): any {
  const mappedData = data.map((dataDay: any) => {
    const parsedDate = parseISO(dataDay.date);

    return {
      label: format(parsedDate, 'dd/MM/yyyy'),
      key: format(parsedDate, 'dd/MM/yyyy'),
    };
  });

  return [{ label: '', key: 'name' }, ...mappedData];
}

export function getSpreadSheetData(data: any): any {
  const grossRevenueValuesObject = {};
  const pendingOrdersValuesObject = {};
  const inAnalysisOrdersValuesObject = {};
  const noInformationOrdersValuesObject = {};
  const discountRevenueValuesObject = {};
  const chargedBackRevenueValuesObject = {};
  const refundRevenueValuesObject = {};
  const netRevenueValuesObject = {};
  const cogsValuesObject = {};
  const marketingValuesObject = {};
  const salesFeeValuesObject = {};
  const gatewayFeeValuesObject = {};
  const shippingCostOwnerValuesObject = {};
  const taxValuesObject = {};
  const otherSpendsValuesObject = {};
  const financialIncomeValuesObject = {};
  const netProfitValuesObject = {};

  data.forEach((dataDay: any) => {
    const parsedDate = parseISO(dataDay.date);

    grossRevenueValuesObject[format(parsedDate, 'dd/MM/yyyy')] = numberFormatter(
      dataDay.gross_revenue.amount,
      2,
    );

    pendingOrdersValuesObject[format(parsedDate, 'dd/MM/yyyy')] = numberFormatter(
      dataDay.pending_orders.amount,
      2,
    );

    inAnalysisOrdersValuesObject[format(parsedDate, 'dd/MM/yyyy')] = numberFormatter(
      dataDay.in_analysis_orders.amount,
      2,
    );

    noInformationOrdersValuesObject[format(parsedDate, 'dd/MM/yyyy')] = numberFormatter(
      dataDay.no_information_orders.amount,
      2,
    );

    discountRevenueValuesObject[format(parsedDate, 'dd/MM/yyyy')] = numberFormatter(
      dataDay.discount_revenue.amount,
      2,
    );

    chargedBackRevenueValuesObject[format(parsedDate, 'dd/MM/yyyy')] = numberFormatter(
      dataDay.charged_back_revenue.amount,
      2,
    );

    refundRevenueValuesObject[format(parsedDate, 'dd/MM/yyyy')] = numberFormatter(
      dataDay.refund_revenue.amount,
      2,
    );

    netRevenueValuesObject[format(parsedDate, 'dd/MM/yyyy')] = numberFormatter(
      dataDay.net_revenue.amount,
      2,
    );

    cogsValuesObject[format(parsedDate, 'dd/MM/yyyy')] = numberFormatter(dataDay.cogs.amount, 2);

    marketingValuesObject[format(parsedDate, 'dd/MM/yyyy')] = numberFormatter(
      dataDay.marketing.amount,
      2,
    );

    salesFeeValuesObject[format(parsedDate, 'dd/MM/yyyy')] = numberFormatter(
      dataDay.sales_fee.amount,
      2,
    );

    gatewayFeeValuesObject[format(parsedDate, 'dd/MM/yyyy')] = numberFormatter(
      dataDay.gateway_fee.amount,
      2,
    );

    shippingCostOwnerValuesObject[format(parsedDate, 'dd/MM/yyyy')] = numberFormatter(
      dataDay.shipping_cost_owner.amount,
      2,
    );

    taxValuesObject[format(parsedDate, 'dd/MM/yyyy')] = numberFormatter(dataDay.tax.amount, 2);

    otherSpendsValuesObject[format(parsedDate, 'dd/MM/yyyy')] = numberFormatter(
      dataDay.other_spends.amount,
      2,
    );

    financialIncomeValuesObject[format(parsedDate, 'dd/MM/yyyy')] = numberFormatter(
      dataDay.financial_income.amount,
      2,
    );

    netProfitValuesObject[format(parsedDate, 'dd/MM/yyyy')] = numberFormatter(
      dataDay.net_profit.amount,
      2,
    );
  });

  return [
    { name: 'Receita Bruta', ...grossRevenueValuesObject },
    { name: 'Pedidos Pendentes', ...pendingOrdersValuesObject },
    { name: 'Pedidos em Análise', ...inAnalysisOrdersValuesObject },
    { name: 'Pedidos Não Conciliados', ...noInformationOrdersValuesObject },
    { name: 'Descontos', ...discountRevenueValuesObject },
    { name: 'Chargebacks', ...chargedBackRevenueValuesObject },
    { name: 'Devoluções', ...refundRevenueValuesObject },
    { name: 'Receita Líquida', ...netRevenueValuesObject },
    { name: 'Custo dos Produtos', ...cogsValuesObject },
    { name: 'Marketing', ...marketingValuesObject },
    { name: 'Taxas de Checkout', ...salesFeeValuesObject },
    { name: 'Taxas de Gateway', ...gatewayFeeValuesObject },
    { name: 'Despesas com Envio', ...shippingCostOwnerValuesObject },
    { name: 'Impostos', ...taxValuesObject },
    { name: 'Outras Despesas', ...otherSpendsValuesObject },
    { name: 'Receitas Financeiras', ...financialIncomeValuesObject },
    { name: 'Lucro Líquido', ...netProfitValuesObject },
  ];
}
