import styled from 'styled-components/macro';

export const Content = styled.div`
  width: 100%;
  padding: 86px 16px 16px 16px;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[7]};
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
`;
