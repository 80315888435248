import React from 'react';
import { Upload } from 'phosphor-react';
import { useTheme } from 'styled-components';

import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';

import { useToast } from '@helpers/hooks/useToast';
import { useContent } from '@helpers/hooks/pages/dashboard/automations/useContent';
import { useMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useMessageSettings';

import * as S from './styles';

const SelectAudio: React.FC = () => {
  const theme = useTheme();
  const { toast } = useToast();
  const { contentsToUpdate, handleContentsToUpdate } = useMessageSettings();
  const {
    isEditingContent,
    handleEditingContent,
    handleSelectedContentType,
    contentToEdit,
  } = useContent();

  const [isUploadError, setIsUploadError] = React.useState<boolean>(false);

  const resetConfig = React.useCallback(() => {
    handleEditingContent(false);
    handleSelectedContentType(EConfigOption.NONE);
  }, [handleEditingContent, handleSelectedContentType]);

  const handleUpdateAudioContent = React.useCallback(
    audio => {
      if (!isEditingContent || !contentToEdit) return;

      const data = [...contentsToUpdate];

      const foundContent = data.find(content => content.id === contentToEdit.id);

      if (!foundContent) return;

      const index = data.indexOf(foundContent);

      data[index].content = audio;
      data[index].isEdited = true;

      handleContentsToUpdate(data);
    },
    [isEditingContent, contentToEdit, contentsToUpdate, handleContentsToUpdate],
  );

  const onSave = React.useCallback(
    file => {
      if (!file) return;

      const MAX_FILE_SIZE = 1024 * 1024 * 3;

      if (file.size > MAX_FILE_SIZE) {
        toast.error('O arquivo deve ter no máximo 3MB');
        return;
      }

      if (isEditingContent) {
        handleUpdateAudioContent(file);
        resetConfig();
        return;
      }

      const newContent = {
        id: Math.random(),
        type: EConfigOption.AUDIO,
        content: file,
        isNewItemInArray: true,
      };

      handleContentsToUpdate([...contentsToUpdate, newContent]);

      resetConfig();
    },
    [
      toast,
      isEditingContent,
      handleUpdateAudioContent,
      resetConfig,
      handleContentsToUpdate,
      contentsToUpdate,
    ],
  );

  const onUploadFile = React.useCallback(
    event => {
      const allowedFormats = ['audio/ogg', 'audio/mpeg', 'audio/mp3', 'audio/webm'];

      const newFileUploaded = event.target.files[0] as File;

      const MAX_FILE_SIZE = 1024 * 1024 * 3; // 3MB

      if (newFileUploaded.size > MAX_FILE_SIZE) {
        toast.error('O arquivo deve ter no máximo 3MB');
        return;
      }

      if (allowedFormats.includes(newFileUploaded?.type)) {
        setIsUploadError(false);
        onSave(newFileUploaded);
      } else {
        setIsUploadError(true);
      }
    },
    [onSave, toast],
  );

  return (
    <S.Wrapper>
      <Upload size={30} color={theme.colors.gray[1]} />

      <S.Title weight={ETextWeight.MEDIUM}>Selecionar o arquivo .OGG/MP3/WEBM</S.Title>

      <S.Description size={ETextSize.B5}>ou arraste o arquivo até aqui</S.Description>

      {isUploadError && (
        <S.NoSupportedFormatErrorText isErrorFeedback size={ETextSize.B5}>
          Formato de arquivo não permitido, tente novamente.
        </S.NoSupportedFormatErrorText>
      )}

      <S.Input type="file" name="files" onChange={onUploadFile} />
    </S.Wrapper>
  );
};

export default SelectAudio;
