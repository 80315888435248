/* eslint-disable no-await-in-loop */
/* eslint-disable dot-notation */
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import ComparedAreaCharts from '@components/common/core/DataDisplay/Charts/ComparedAreaCharts';
import FunnelChart from '@components/common/core/DataDisplay/Charts/FunnelChart';
import PieChart from '@components/common/core/DataDisplay/Charts/PieChart';
import { data as comparedAreaChartsData } from '@constants/comparedAreaChartsExample';
import { data as pieChartData, COLORS } from '@constants/pieChartExample';
import { useIugu } from '@helpers/hooks/useIugu';
import { useDebounce } from '@helpers/hooks/useDebounce';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import onboardingStepService from '@services/pages/onboarding/onboardingStep';
import { IParams } from '@domain/interfaces/IParams';
import DatePickerRange from '@components/common/core/Utils/DatePickerRange';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';
import {
  ESynchronizationRequestType,
  ESynchronizationType,
} from '@domain/enums/hooks/ESynchronization';
import { LONG_API_DOMAIN } from '@constants/api';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';

import QRCode from 'react-qr-code';

// import NotificationComponent from './notification';

import TimePicker from '@components/common/core/Utils/TimePicker';
import * as S from './styles';

const ErrorDisplay: React.FC<any> = (
  { error, resetError }, // <-- props passed to fallbackUI component
) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <button type="button" onClick={resetError}>
        Try again
      </button>
    </div>
  );
};

const Teste: React.FC = () => {
  const { analytics } = useConfig();

  const { mutateSubscription } = useStoreSubscription();

  const { handleSubmit, register } = useForm({ mode: 'onBlur' });
  const iugu = useIugu('32100AB23A5B464C964437C238C1844D');
  const { storeAliasId } = useParams<IParams>();
  const { onboardingSteps } = onboardingStepService.getOnboardingSteps({ storeAliasId });
  const { addSynchronization } = useSynchronization();
  const { toast } = useToast();

  const [teste, setTeste] = React.useState<string>('');
  const [period, setPeriod] = React.useState<any>({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [isQRCodeVisible, setIsQRCodeVisible] = React.useState<boolean>(false);

  const handleQRCodeVisible = React.useCallback(() => setIsQRCodeVisible(true), []);

  const debouncedValue = useDebounce(teste, 500);

  const onChange = React.useCallback(event => setTeste(event.target.value), []);

  const onRandomizeClick = React.useCallback(() => {
    const item = localStorage.getItem('random-ads-manager');

    if (item) {
      localStorage.removeItem('random-ads-manager');
      toast.success('Randomizador desabilitado');
    } else {
      localStorage.setItem('random-ads-manager', 'true');
      toast.success('Randomizador habilitado');
    }
  }, [toast]);

  const onTeste1Click = React.useCallback(() => {
    addSynchronization({
      name: 'Teste',
      type: ESynchronizationType.STORE_PRODUCT,
      label: 'Produtos',
      dependencies: [],
      storeAliasId,
      config: {
        isOnboardingSynchronization: false,
        showNotification: true,
      },
      request: {
        type: ESynchronizationRequestType.POST,
        domain: LONG_API_DOMAIN,
        api: `/dev/api/v1/users/stores/${storeAliasId}/dsers/products/sync`,
        requestParams: '?start_date=2000-02-01&end_date=2022-07-11',
      },
    });
  }, [addSynchronization, storeAliasId]);

  const onTeste2Click = React.useCallback(() => {
    addSynchronization({
      name: 'Teste',
      type: ESynchronizationType.STORE_ORDER,
      label: 'Produtos',
      dependencies: [],
      storeAliasId,
      config: {
        isOnboardingSynchronization: false,
        showNotification: true,
      },
      request: {
        type: ESynchronizationRequestType.POST,
        domain: LONG_API_DOMAIN,
        api: `/prod/api/v1/users/stores/${storeAliasId}/dsers/orders/sync`,
        requestParams: '?start_date=2000-02-01&end_date=2022-07-11',
      },
    });
  }, [addSynchronization, storeAliasId]);

  const onSubmit = React.useCallback(
    async (data, event) => {
      event.preventDefault();

      await iugu.setTestMode(true);

      const token = await iugu.createPaymentToken(event.target);

      console.log(token);
    },
    [iugu],
  );

  const onClick = React.useCallback(() => {
    onboardingSteps.map(step => {
      onboardingStepService.deleteStep({ storeAliasId, onboardingStepId: step.id });

      return undefined;
    });
  }, [onboardingSteps, storeAliasId]);

  const onTeste = React.useCallback(() => {
    analytics?.track('teste', { prop: 'teste' }, {});
  }, [analytics]);

  const onSubscriptionMutateClick = React.useCallback(async () => {
    const data = await mutateSubscription();

    console.log(data);
  }, [mutateSubscription]);

  const onGtagClick = React.useCallback(() => {
    const dataLayer = (window as Record<string, any>)?.dataLayer;

    if (dataLayer) {
      dataLayer.push({ event: 'Profitfy Test' });
      console.log(dataLayer);
    }
  }, []);

  // const requestNotificationPermission = React.useCallback(() => {

  // }, []);

  const notificationTest = React.useCallback(async () => {
    const promise = await Notification.requestPermission();

    console.log(promise);

    // const notification = new Notification('Teste');

    // setTimeout(() => notification.close(), 10000);
  }, []);

  const toastTest = React.useCallback(() => {
    toast.error('Store does not exists');
  }, [toast]);

  React.useEffect(() => {
    console.log(debouncedValue);
  }, [debouncedValue]);

  React.useEffect(() => {
    notificationTest();
  }, [notificationTest]);

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorDisplay}>
        <S.ChartContainer>
          <button onClick={toastTest} type="button">
            toast test
          </button>
          <button type="button" onClick={onGtagClick}>
            gtag teste
          </button>
          <button type="button" onClick={onSubscriptionMutateClick}>
            mutate subscription
          </button>
          <button type="button" onClick={onTeste}>
            teste track
          </button>
          <button type="button" onClick={onRandomizeClick}>
            Randomize Ads Manager Data
          </button>
          <button type="button" onClick={onTeste1Click}>
            Teste1
          </button>
          <button type="button" onClick={onTeste2Click}>
            Teste2
          </button>
          <DatePickerRange period={period} setPeriod={setPeriod} minDate={new Date()} />
          <TextFieldBase type="text" onChange={onChange} />
          <form onSubmit={handleSubmit(onSubmit)}>
            {onboardingSteps && (
              <button type="button" onClick={onClick}>
                teste
              </button>
            )}
            <input {...register('number')} placeholder="numero cartao" data-iugu="number" />
            <input
              {...register('verification_value')}
              placeholder="cvv"
              data-iugu="verification_value"
            />
            <input
              {...register('expiration')}
              placeholder="vencimento (MM/AAAA ou MM/AA)"
              data-iugu="expiration"
            />
            <input {...register('full_name')} placeholder="nome completo" data-iugu="full_name" />

            <button type="submit">Gerar Token</button>
          </form>
          <S.ComparedAreaChartContainer>
            <ComparedAreaCharts data={comparedAreaChartsData} />
          </S.ComparedAreaChartContainer>

          <TimePicker />

          <div style={{ margin: 20, maxWidth: 256 }}>
            {isQRCodeVisible && <QRCode value="teste frontend" />}

            {!isQRCodeVisible && (
              <button type="button" onClick={handleQRCodeVisible}>
                Gerar qrcode
              </button>
            )}
          </div>

          <S.FunnelchartContainer>
            <FunnelChart />
          </S.FunnelchartContainer>

          <S.PieChartContainer>
            <PieChart data={pieChartData} colors={COLORS} />
          </S.PieChartContainer>
        </S.ChartContainer>
      </ErrorBoundary>
    </>
  );
};

export default React.memo(Teste);
