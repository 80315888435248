import React from 'react';

import { BillingProvider } from '@helpers/hooks/pages/dashboard/customValues/useBilling';
import { IBillingProps } from '@domain/interfaces/dashboard/CustomValues/IBilling';
import Wrapper from './Wrapper';

const Billing: React.FC<IBillingProps> = ({ selectedCustomValueChecked }) => {
  return (
    <BillingProvider selectedCustomValueChecked={selectedCustomValueChecked}>
      <Wrapper />
    </BillingProvider>
  );
};

export default Billing;
