import React from 'react';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import { ITransactionProvider } from '@domain/interfaces/common/transaction/ITransaction';

import transactionService from '@services/common/transaction/transaction';

const TransactionContext = React.createContext<ITransactionProvider | null>(null);

export const TransactionProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();

  const [transactionsPage, setTransactionsPage] = React.useState<number>(0);
  const [transactionsPageCount, setTransactionsPageCount] = React.useState<number>(1);

  const {
    transactions,
    pageCount: transactionsTotalPages,
    isLoading: isLoadingTransactions,
    isValidating: isValidatingTransactions,
    mutate: mutateTransactions,
    error: transactionsError,
  } = transactionService.listStoreTransactions({ storeAliasId, page: transactionsPage });

  const onTransactionsPageChange = React.useCallback(
    ({ selected }) => setTransactionsPage(selected),
    [],
  );

  React.useEffect(() => {
    if (transactionsTotalPages) {
      setTransactionsPageCount(transactionsTotalPages);
    }
  }, [transactionsTotalPages]);

  return (
    <TransactionContext.Provider
      value={{
        isLoadingTransactions,
        isValidatingTransactions,
        mutateTransactions,
        onTransactionsPageChange,
        transactions,
        transactionsError,
        transactionsPage,
        transactionsPageCount,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

export const useTransaction = (): ITransactionProvider => {
  const context = React.useContext(TransactionContext);

  if (!context) {
    throw new Error('useTransaction must be used within TransactionProvider');
  }

  return context;
};
