import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import DatePickerBase from '@components/common/core/Utils/DatePicker';

export const Title = styled(HeadingBase)``;

export const Wrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 32px;
`;

export const ResumeWrapper = styled.div`
  display: flex;
  align-items: center;
  align-items: flex-end;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 20px;
`;

export const ItemTitle = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.fonts.body};
`;

export const ItemValue = styled(TextBase)`
  color: ${({ theme }) => theme.colors.green.default};
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.body};
`;

export const DatePicker = styled(DatePickerBase)`
  margin-left: auto;
  height: auto;
  align-items: flex-end;
`;
