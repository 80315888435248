import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Text = styled(TextBase)`
  color: ${({ theme }) => theme.colors.green.dark};
`;

export const NextMonthButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 5px;
  padding: 4px 6px 4px 8px;
  border: 1px solid ${({ theme }) => theme.colors.font};
  color: ${({ theme }) => theme.colors.font};
  margin-right: 12px;
  font-weight: 700;
  transition: all 0.3s;

  &:hover {
    border-color: ${({ theme }) => theme.colors.green.default};
    color: ${({ theme }) => theme.colors.green.default};
  }

  &:active {
    border-color: ${({ theme }) => theme.colors.green.dark};
    color: ${({ theme }) => theme.colors.green.dark};
  }
`;

export const PreviousMonthButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 5px;
  padding: 4px 8px 4px 6px;
  border: 1px solid ${({ theme }) => theme.colors.font};
  color: ${({ theme }) => theme.colors.font};
  margin-left: 12px;
  font-weight: 700;
  transition: all 0.3s;

  &:hover {
    border-color: ${({ theme }) => theme.colors.green.default};
    color: ${({ theme }) => theme.colors.green.default};
  }

  &:active {
    border-color: ${({ theme }) => theme.colors.green.dark};
    color: ${({ theme }) => theme.colors.green.dark};
  }
`;

export const HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 18px;
`;
