import React from 'react';

import { IFormProps } from '@domain/interfaces/components/IForm';

import * as S from './styles';

const Form: React.HTMLAttributes<React.FormEvent> & React.FC<IFormProps> = ({
  children,
  innerRef,
  ...rest
}) => {
  return (
    <S.Form ref={innerRef} {...rest}>
      {children}
    </S.Form>
  );
};

export default Form;
