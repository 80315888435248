import { v4 } from 'uuid';

import {
  EPixType,
  EPixProductOptions,
} from '@domain/enums/dashboard/automations/node/conditionalNode/EPix';

export const DEFAULT_PIX_CONTENT = {
  id: v4(),
  type: EPixType.PRODUCT_NAME,
  options: EPixProductOptions.CONTAINS,
  input: '',
};
