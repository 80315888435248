import styled, { css } from 'styled-components/macro';

import { IGatewayIconWrapperProps } from '@domain/interfaces/dashboard/ProductCostDetails/IRow';

import TextBase from '@components/common/core/DataDisplay/Text';

import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';
import CustomHoverCardBase from '@components/common/core/Utils/CustomHoverCard';

export const HoverCard = styled(CustomHoverCardBase)``;

export const Tooltip = styled(CustomTooltipBase)``;

export const TooltipContent = styled(CustomTooltipBase.Content)`
  width: 100%;
  max-width: 281px;
`;

export const GatewayAndMarketingDataContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  & > div {
    width: 30px;
    height: 30px;
  }
`;

export const GatewayIconWrapper = styled.div<IGatewayIconWrapperProps>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ hasMarginLeft }) =>
    hasMarginLeft &&
    css`
      padding-left: 2px;
    `}
`;

export const GatewayTooltipContentWrapper = styled.div`
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
`;

export const GatewayTooltipContentText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};

  span {
    color: ${({ theme }) => theme.colors.gray[1]};
  }
`;

export const OrderSourceIconWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OrderSourceTooltipContentText = styled(TextBase)``;
