import {
  IFinancialReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresNetProfitValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresNetProfitValues = (
  currentSelectedStoreFinancialReportData: IFinancialReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresNetProfitValuesResponse => {
  const currentSelectedStoreNetProfitAmountValue =
    currentSelectedStoreFinancialReportData?.net_profit?.amount;

  const currentSelectedStoreNetProfitGrowthValue =
    currentSelectedStoreFinancialReportData?.net_profit?.growth;

  const storesNetProfitAmountValues = selectedStoresData.map(
    value => value.financial_report?.net_profit?.amount,
  );

  const storesNetProfitGrowthValues = selectedStoresData.map(
    value => value.financial_report?.net_profit?.growth,
  );

  const netProfitAmountValues = [
    ...storesNetProfitAmountValues,
    currentSelectedStoreNetProfitAmountValue,
  ];

  const netProfitGrowthValues = [
    ...storesNetProfitGrowthValues,
    currentSelectedStoreNetProfitGrowthValue,
  ];

  const reducedNetProfitAmountValues = netProfitAmountValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const reducedNetProfitGrowthValues = netProfitGrowthValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  return {
    net_profit_amount: reducedNetProfitAmountValues,
    net_profit_growth: reducedNetProfitGrowthValues,
  };
};
