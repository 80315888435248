import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import {
  ISynchronization,
  ISynchronizations,
} from '@domain/interfaces/hooks/IStoreSynchronization';

export const getSynchronizationStatus = (
  currentSynchronizations: ISynchronizations,
  synchronizationsQueue: Array<ISynchronization>,
  synchronizationName: string | undefined,
  synchronizationType: ESynchronizationType,
  storeAliasId: string | undefined,
): boolean => {
  if (!currentSynchronizations?.synchronizations.length && !synchronizationsQueue.length)
    return false;

  if (synchronizationName) {
    const foundSynchronizationInCurrentSynchronizations = currentSynchronizations?.synchronizations.find(
      synchronization =>
        synchronization.name === synchronizationName &&
        synchronization.type === synchronizationType,
    );

    if (foundSynchronizationInCurrentSynchronizations?.storeAliasId !== storeAliasId) return false;

    if (foundSynchronizationInCurrentSynchronizations) return true;

    const filteredSynchronizationsInSynchronizationsQueue = synchronizationsQueue.filter(
      synchronization =>
        synchronization.name === synchronizationName &&
        synchronization.type === synchronizationType,
    );

    const foundSynchronizationInFilteredSynchronizationsQueue = filteredSynchronizationsInSynchronizationsQueue.find(
      synchronization => synchronization.storeAliasId === storeAliasId,
    );

    if (!foundSynchronizationInFilteredSynchronizationsQueue) return false;

    if (filteredSynchronizationsInSynchronizationsQueue.length) return true;
  } else {
    const foundSynchronizationInCurrentSynchronizations = currentSynchronizations?.synchronizations.find(
      synchronization => synchronization.type === synchronizationType,
    );

    if (foundSynchronizationInCurrentSynchronizations?.storeAliasId !== storeAliasId) return false;

    if (foundSynchronizationInCurrentSynchronizations) return true;

    const filteredSynchronizationsInSynchronizationsQueue = synchronizationsQueue.filter(
      synchronization => synchronization.type === synchronizationType,
    );

    const foundSynchronizationInFilteredSynchronizationsQueue = filteredSynchronizationsInSynchronizationsQueue.find(
      synchronization => synchronization.storeAliasId === storeAliasId,
    );

    if (!foundSynchronizationInFilteredSynchronizationsQueue) return false;

    if (filteredSynchronizationsInSynchronizationsQueue.length) return true;
  }

  return false;
};
