import styled from 'styled-components/macro';

import CustomDropDownBase from '@components/common/core/Utils/CustomDropDown';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import LabelBase from '@components/common/core/DataDisplay/Label';

export const Label = styled(LabelBase)`
  margin: unset;
  cursor: pointer;
`;

export const Checkbox = styled(CheckboxBase)``;

export const CheckboxGroup = styled(InputGroupBase)`
  width: 100%;
  padding: 12px 20px;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin: unset;
`;

export const CheckboxGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.black.light};
  max-height: 356px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 4px;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    position: absolute;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[6]};
    border-radius: 25px;
    transition: all 0.3s;
  }

  ::-webkit-scrollbar-thumb:hover {
    opacity: brightness(0.8);
  }
`;

export const Arrow = styled(CustomDropDownBase.Arrow)`
  fill: ${({ theme }) => theme.colors.black.light};
`;

export const Content = styled(CustomDropDownBase.Content)`
  padding: unset;
`;
