import React from 'react';
import ReactPaginate from 'react-paginate';

import { IPaginateProps } from '@domain/interfaces/components/IPaginate';

import * as S from './styles';

const smallDevice = window.matchMedia('(max-width: 500px)').matches;

const Paginate: React.FC<IPaginateProps> = ({ pageCount, onPageChange, forcePage, ...rest }) => {
  return (
    <S.PaginateWrapper {...rest}>
      <ReactPaginate
        forcePage={forcePage}
        pageCount={pageCount}
        previousLabel="<"
        nextLabel=">"
        onPageChange={onPageChange}
        marginPagesDisplayed={smallDevice ? 1 : 2}
        pageRangeDisplayed={smallDevice ? 1 : 2}
        containerClassName="pagination"
        breakClassName="pagination-item"
        breakLinkClassName="page-link"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        disabledClassName="disabled"
        activeClassName="active"
        nextClassName="page-item"
        previousClassName="page-item"
        nextLinkClassName="page-link icon-prev-next"
        previousLinkClassName="page-link icon-prev-next"
      />
    </S.PaginateWrapper>
  );
};

export default React.memo(Paginate);
