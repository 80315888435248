import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.ContentWrapper>
      <S.Header>
        <S.IconWrapper>
          <Skeleton width="100%" height="100%" count={1} />
        </S.IconWrapper>

        <S.TitleWrapper>
          <Skeleton width="100%" height={24} count={1} />
        </S.TitleWrapper>
      </S.Header>

      <S.Tab>
        <Skeleton width={380} height={31} count={1} />
      </S.Tab>

      <S.DescriptionTitle>
        <Skeleton width={420} height={21} count={1} />
      </S.DescriptionTitle>

      <S.DescriptionText>
        <Skeleton width="100%" height={45} count={1} />
      </S.DescriptionText>
    </S.ContentWrapper>
  );
};

export default SkeletonLoading;
