import React from 'react';

import { CheckoutProvider } from '@helpers/hooks/pages/subscription/useCheckout';
import { AddressProvider } from '@helpers/hooks/common/store/address/useAddress';
import { PersonalInfoProvider } from '@helpers/hooks/common/store/personalInfo/usePersonalInfo';
import { PaymentProvider } from '@helpers/hooks/common/store/payment/usePayment';
import { CouponProvider } from '@helpers/hooks/pages/subscription/useCoupon';

import Wrapper from '@components/Subscription/Checkout/Wrapper';

const Checkout: React.FC = () => {
  return (
    <AddressProvider>
      <PersonalInfoProvider>
        <PaymentProvider>
          <CouponProvider>
            <CheckoutProvider>
              <Wrapper />
            </CheckoutProvider>
          </CouponProvider>
        </PaymentProvider>
      </PersonalInfoProvider>
    </AddressProvider>
  );
};

export default Checkout;
