import React from 'react';
import { Pen, SimpleClose } from '@profitfy/pakkun-icons';

import { ILabelProps } from '@domain/interfaces/dashboard/ProductTag/ISideModalTags';

import * as S from './styles';

const Label: React.FC<ILabelProps> = ({
  isHovering,
  handleIsEditing,
  tagName,
  handleDeleteModalOpen,
}) => {
  return (
    <S.Wrapper>
      <S.TagName>{tagName}</S.TagName>

      {isHovering && (
        <S.ButtonsWrapper>
          <S.EditButton onClick={handleIsEditing}>
            <Pen size={14} />
          </S.EditButton>

          <S.DeleteButton onClick={handleDeleteModalOpen}>
            <SimpleClose size={14} />
          </S.DeleteButton>
        </S.ButtonsWrapper>
      )}
    </S.Wrapper>
  );
};

export default Label;
