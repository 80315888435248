import styled, { keyframes } from 'styled-components';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import FormBase from '@components/common/core/Inputs/Form';
import LabelBase from '@components/common/core/DataDisplay/Label';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import TextBase from '@components/common/core/DataDisplay/Text';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';

const appearEffect = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const Form = styled(FormBase)`
  @media only screen and (max-width: 1115px) {
    width: unset;
  }
`;

export const FormHeading = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.white.default};
  font-size: 1.75rem;
  margin-bottom: 32px;
  line-height: 120%;
`;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Label = styled(LabelBase)``;

export const GatewayNameInput = styled(TextFieldBase)`
  min-width: 523px;

  @media only screen and (max-width: 566px) {
    min-width: unset;
  }
`;

export const PaymentMethodsLabel = styled(LabelBase)`
  margin-bottom: 16px;
`;

export const PaymentMethodsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: fit-content;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: fit-content;
`;

export const Checkbox = styled(CheckboxBase)``;

export const CheckboxText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.white.default};
`;

export const InputAndSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 1115px) {
    justify-content: unset;
    gap: 24px;
  }
`;

export const Select = styled(SelectBase)`
  min-width: 137px;
`;

export const TaxInputGroup = styled(InputGroupBase)`
  animation: ${appearEffect} 400ms;
  margin-bottom: 24px;
`;

export const TaxInput = styled(TextFieldBase)`
  min-width: 361px;

  span {
    color: ${({ theme }) => theme.colors.green.default};
  }

  @media only screen and (max-width: 566px) {
    min-width: unset;
  }
`;

export const Option = styled(OptionBase)``;

export const CardInputsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  & > div > div {
    min-width: unset;
  }
`;
