import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import LabelBase from '@components/common/core/DataDisplay/Label';
import ButtonBase from '@components/common/core/Inputs/Button';
import RadioBase from '@components/common/core/Inputs/Radio';
import TextBase from '@components/common/core/DataDisplay/Text';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';

export const CheckboxText = styled(TextBase)`
  margin-top: 2px;
`;

export const Checkbox = styled(CheckboxBase)``;

export const CheckboxOption = styled(LabelBase)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  cursor: pointer;
`;

export const RadioText = styled(TextBase)``;

export const Radio = styled(RadioBase)``;

export const RadioOption = styled(LabelBase)`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const Button = styled(ButtonBase)`
  margin-left: auto;
  margin-top: 32px;
`;

export const Input = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)``;

export const DayPickerWrapper = styled.div`
  width: fit-content;

  & > div > div > div {
    margin-bottom: unset;
  }
`;

export const MaxCyclesSelect = styled(SelectBase)``;

export const SelectOption = styled(OptionBase)``;

export const AmountAndEndDateWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

export const NameAndIdentifierWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 24px;
`;

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  padding: 16px 24px;
  box-sizing: border-box;
  border-radius: 7px;
  margin-bottom: 32px;
`;
