import React from 'react';
import { useParams } from 'react-router-dom';

import { ICustomValuesCategoriesContext } from '@domain/interfaces/dashboard/CustomValuesCategories/ICustomValuesCategories';
import customValuesCategoriesService from '@services/pages/dashboard/customValuesCategories/customValueCategories';
import { IParams } from '@domain/interfaces/IParams';
import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';

const CustomValuesCategoriesContext = React.createContext<ICustomValuesCategoriesContext | null>(
  null,
);

export const CustomValuesCategoriesProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();
  const { analytics, user } = useConfig();
  const { toast } = useToast();

  const {
    customValuesCategories,
    error: customValuesCategoriesError,
    isValidating: isValidatingCustomValuesCategories,
    mutate: mutateCustomValuesCategories,
    isLoading,
  } = customValuesCategoriesService.getCustomValuesCategories({ storeAliasId });

  const [isCreateCategorySidemodalOpen, setIsCreateCategorySidemodalOpen] = React.useState<boolean>(
    false,
  );
  const [
    isCreatingNewCustomValuesCategory,
    setIsCreatingNewCustomValueCategory,
  ] = React.useState<boolean>(false);
  const [
    isUpdatingCustomValuesCategory,
    setIsUpdatingCustomValuesCategory,
  ] = React.useState<boolean>(false);
  const [
    isDeletingCustomValuesCategory,
    setIsDeletingCustomValuesCategory,
  ] = React.useState<boolean>(false);
  const [
    isLoadingCustomValuesCategories,
    setIsLoadingCustomValuesCategories,
  ] = React.useState<boolean>(isLoading);

  const handleCreateCategorySidemodalOpen = React.useCallback(
    () => setIsCreateCategorySidemodalOpen(!isCreateCategorySidemodalOpen),
    [isCreateCategorySidemodalOpen],
  );

  const createCustomValuesCategory = React.useCallback(
    async data => {
      setIsCreatingNewCustomValueCategory(true);

      try {
        await customValuesCategoriesService.createCustomValueCategory({ storeAliasId, data });

        toast.success('Categoria criada com sucesso!');
        analytics?.track(
          'Custom Values Category Added',
          {
            category_name: data.name,
            group: data.group,
            email: user?.email,
          },
          { context: { groupId: storeAliasId } },
        );
        analytics?.trackHubSpot(storeAliasId, { additional_value_category_created: true });

        handleCreateCategorySidemodalOpen();

        setIsLoadingCustomValuesCategories(true);

        await mutateCustomValuesCategories();
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsCreatingNewCustomValueCategory(false);
        setIsLoadingCustomValuesCategories(false);
      }
    },
    [
      mutateCustomValuesCategories,
      storeAliasId,
      toast,
      handleCreateCategorySidemodalOpen,
      analytics,
      user,
    ],
  );

  const updateCustomValuesCategory = React.useCallback(
    async ({ customValuesCategoryAliasId, data }) => {
      setIsUpdatingCustomValuesCategory(true);

      try {
        await customValuesCategoriesService.updateCustomValuesCategory({
          storeAliasId,
          data,
          customValuesCategoryAliasId,
        });

        toast.success('Categoria atualizada com sucesso!');

        await mutateCustomValuesCategories();
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsUpdatingCustomValuesCategory(false);
      }
    },
    [mutateCustomValuesCategories, storeAliasId, toast],
  );

  const deleteCustomValuesCategory = React.useCallback(
    async customValuesCategoryAliasId => {
      setIsDeletingCustomValuesCategory(true);

      try {
        await customValuesCategoriesService.deleteCustomValuesCategory({
          customValuesCategoryAliasId,
          storeAliasId,
        });

        toast.success('Categoria excluída com sucesso!');

        setIsLoadingCustomValuesCategories(true);

        await mutateCustomValuesCategories();
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsDeletingCustomValuesCategory(false);
        setIsLoadingCustomValuesCategories(false);
      }
    },
    [mutateCustomValuesCategories, storeAliasId, toast],
  );

  React.useEffect(() => {
    setIsLoadingCustomValuesCategories(isLoading);
  }, [isLoading]);

  return (
    <CustomValuesCategoriesContext.Provider
      value={{
        isCreateCategorySidemodalOpen,
        handleCreateCategorySidemodalOpen,
        customValuesCategories,
        createCustomValuesCategory,
        customValuesCategoriesError,
        deleteCustomValuesCategory,
        isCreatingNewCustomValuesCategory,
        isDeletingCustomValuesCategory,
        isLoadingCustomValuesCategories,
        isUpdatingCustomValuesCategory,
        isValidatingCustomValuesCategories,
        mutateCustomValuesCategories,
        updateCustomValuesCategory,
      }}
    >
      {children}
    </CustomValuesCategoriesContext.Provider>
  );
};

export const useCustomValuesCategories = (): ICustomValuesCategoriesContext => {
  const context = React.useContext(CustomValuesCategoriesContext);

  if (!context) {
    throw new Error('useCustomValuesCategories must be used within CustomValuesCategoriesProvider');
  }

  return context;
};
