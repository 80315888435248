import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { EHeadAlign } from '@domain/enums/components/ETable';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.Table>
      <S.Table.Header>
        <S.Table.Row>
          <S.Table.Head />
          <S.Table.Head>Descrição</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Começa em</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Termina em</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Valor</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Frequência</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Status</S.Table.Head>
          <S.Table.Head />
        </S.Table.Row>
      </S.Table.Header>

      <S.Table.Body>
        <S.Table.Row borderBottom>
          <S.Table.Data>
            <S.CheckboxWrapper>
              <Skeleton width="100%" height={16} />
            </S.CheckboxWrapper>
          </S.Table.Data>

          <S.Table.Data>
            <S.DescriptionWrapper>
              <S.DescriptionNameWrapper>
                <Skeleton width="100%" height={27} />
              </S.DescriptionNameWrapper>

              <S.DescriptionCategoryWrapper>
                <Skeleton width="100%" height={27} />
              </S.DescriptionCategoryWrapper>
            </S.DescriptionWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.CENTER}>
            <S.StartDateWrapper>
              <Skeleton width="100%" height={27} />
            </S.StartDateWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.CENTER}>
            <S.EndDateWrapper>
              <Skeleton width="100%" height={27} />
            </S.EndDateWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.RIGHT}>
            <S.ValueWrapper>
              <Skeleton width="100%" height={27} />
            </S.ValueWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.CENTER}>
            <S.FrequencyWrapper>
              <Skeleton width="100%" height={32} />
            </S.FrequencyWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.CENTER}>
            <S.StatusWrapper>
              <Skeleton width="100%" height={32} />
            </S.StatusWrapper>
          </S.Table.Data>

          <S.Table.Data />
        </S.Table.Row>

        <S.Table.Row borderBottom>
          <S.Table.Data>
            <S.CheckboxWrapper>
              <Skeleton width="100%" height={16} />
            </S.CheckboxWrapper>
          </S.Table.Data>

          <S.Table.Data>
            <S.DescriptionWrapper>
              <S.DescriptionNameWrapper>
                <Skeleton width="100%" height={27} />
              </S.DescriptionNameWrapper>

              <S.DescriptionCategoryWrapper>
                <Skeleton width="100%" height={27} />
              </S.DescriptionCategoryWrapper>
            </S.DescriptionWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.CENTER}>
            <S.StartDateWrapper>
              <Skeleton width="100%" height={27} />
            </S.StartDateWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.CENTER}>
            <S.EndDateWrapper>
              <Skeleton width="100%" height={27} />
            </S.EndDateWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.RIGHT}>
            <S.ValueWrapper>
              <Skeleton width="100%" height={27} />
            </S.ValueWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.CENTER}>
            <S.FrequencyWrapper>
              <Skeleton width="100%" height={32} />
            </S.FrequencyWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.CENTER}>
            <S.StatusWrapper>
              <Skeleton width="100%" height={32} />
            </S.StatusWrapper>
          </S.Table.Data>

          <S.Table.Data />
        </S.Table.Row>

        <S.Table.Row borderBottom>
          <S.Table.Data>
            <S.CheckboxWrapper>
              <Skeleton width="100%" height={16} />
            </S.CheckboxWrapper>
          </S.Table.Data>

          <S.Table.Data>
            <S.DescriptionWrapper>
              <S.DescriptionNameWrapper>
                <Skeleton width="100%" height={27} />
              </S.DescriptionNameWrapper>

              <S.DescriptionCategoryWrapper>
                <Skeleton width="100%" height={27} />
              </S.DescriptionCategoryWrapper>
            </S.DescriptionWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.CENTER}>
            <S.StartDateWrapper>
              <Skeleton width="100%" height={27} />
            </S.StartDateWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.CENTER}>
            <S.EndDateWrapper>
              <Skeleton width="100%" height={27} />
            </S.EndDateWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.RIGHT}>
            <S.ValueWrapper>
              <Skeleton width="100%" height={27} />
            </S.ValueWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.CENTER}>
            <S.FrequencyWrapper>
              <Skeleton width="100%" height={32} />
            </S.FrequencyWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.CENTER}>
            <S.StatusWrapper>
              <Skeleton width="100%" height={32} />
            </S.StatusWrapper>
          </S.Table.Data>

          <S.Table.Data />
        </S.Table.Row>

        <S.Table.Row borderBottom>
          <S.Table.Data>
            <S.CheckboxWrapper>
              <Skeleton width="100%" height={16} />
            </S.CheckboxWrapper>
          </S.Table.Data>

          <S.Table.Data>
            <S.DescriptionWrapper>
              <S.DescriptionNameWrapper>
                <Skeleton width="100%" height={27} />
              </S.DescriptionNameWrapper>

              <S.DescriptionCategoryWrapper>
                <Skeleton width="100%" height={27} />
              </S.DescriptionCategoryWrapper>
            </S.DescriptionWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.CENTER}>
            <S.StartDateWrapper>
              <Skeleton width="100%" height={27} />
            </S.StartDateWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.CENTER}>
            <S.EndDateWrapper>
              <Skeleton width="100%" height={27} />
            </S.EndDateWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.RIGHT}>
            <S.ValueWrapper>
              <Skeleton width="100%" height={27} />
            </S.ValueWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.CENTER}>
            <S.FrequencyWrapper>
              <Skeleton width="100%" height={32} />
            </S.FrequencyWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.CENTER}>
            <S.StatusWrapper>
              <Skeleton width="100%" height={32} />
            </S.StatusWrapper>
          </S.Table.Data>

          <S.Table.Data />
        </S.Table.Row>

        <S.Table.Row>
          <S.Table.Data>
            <S.CheckboxWrapper>
              <Skeleton width="100%" height={16} />
            </S.CheckboxWrapper>
          </S.Table.Data>

          <S.Table.Data>
            <S.DescriptionWrapper>
              <S.DescriptionNameWrapper>
                <Skeleton width="100%" height={27} />
              </S.DescriptionNameWrapper>

              <S.DescriptionCategoryWrapper>
                <Skeleton width="100%" height={27} />
              </S.DescriptionCategoryWrapper>
            </S.DescriptionWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.CENTER}>
            <S.StartDateWrapper>
              <Skeleton width="100%" height={27} />
            </S.StartDateWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.CENTER}>
            <S.EndDateWrapper>
              <Skeleton width="100%" height={27} />
            </S.EndDateWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.RIGHT}>
            <S.ValueWrapper>
              <Skeleton width="100%" height={27} />
            </S.ValueWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.CENTER}>
            <S.FrequencyWrapper>
              <Skeleton width="100%" height={32} />
            </S.FrequencyWrapper>
          </S.Table.Data>

          <S.Table.Data align={EHeadAlign.CENTER}>
            <S.StatusWrapper>
              <Skeleton width="100%" height={32} />
            </S.StatusWrapper>
          </S.Table.Data>

          <S.Table.Data />
        </S.Table.Row>
      </S.Table.Body>
    </S.Table>
  );
};

export default SkeletonLoading;
