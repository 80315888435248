export enum EProductAnalyticsStatus {
  UNSPECIFIED = 'UNSPECIFIED',
  UNKNOWN = 'UNKNOWN',
  ENABLED = 'ENABLED',
  PAUSED = 'PAUSED',
  REMOVED = 'REMOVED',
  ARCHIVED = 'ARCHIVED',
  IN_PROCESS = 'IN_PROCESS',
  WITH_ISSUES = 'WITH_ISSUES',
}

export enum EProductAnalyticsProvider {
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  TABOOLA = 'TABOOLA',
  TIKTOK = 'TIKTOK',
}
