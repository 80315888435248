import React from 'react';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import { EFilterOperator } from '@domain/enums/components/EFilter';
import { IFilterProps, ISelectedFilter } from '@domain/interfaces/components/IFilter';
import { IParams } from '@domain/interfaces/IParams';
import { IProduct } from '@domain/interfaces/common/product/IProduct';
import { IPeriod } from '@domain/interfaces/components/IDatePicker';

import { useToast } from '@helpers/hooks/useToast';
import { useDebounce } from '@helpers/hooks/useDebounce';
import { useDate } from '@helpers/hooks/useDate';

import productService from '@services/common/product/product';

import Product from './Product';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Products: React.FC<IFilterProps> = ({
  config,
  handleOnClick,
  defaultValue,
  updateData,
  addNewData,
}) => {
  const { config: filterConfig } = config;

  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { format, subDays } = useDate();

  const [products, setProducts] = React.useState<Array<IProduct>>([]);
  const [optionToSearch, setOptionToSearch] = React.useState<string>('');
  const [isLoadingProducts, setIsLoadingProducts] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(1);
  const [period] = React.useState<IPeriod>({
    startDate: subDays(new Date(), 6),
    endDate: new Date(),
  });

  const debouncedValue = useDebounce(optionToSearch, 700);

  const onSearchValueChange = React.useCallback(event => {
    setOptionToSearch(event.target.value);
  }, []);

  const loadProducts = React.useCallback(async () => {
    setIsLoadingProducts(true);

    try {
      const { data: listProductsData } = await productService.listProductsPromise({
        storeAliasId,
        page,
        startDate: format(period.startDate, 'yyyy-MM-dd'),
        endDate: format(period.endDate, 'yyyy-MM-dd'),
        filter: { name: debouncedValue },
      });

      setTotalPages(listProductsData.total_pages);
      setProducts(previousState => [...previousState, ...listProductsData.products]);
      setIsLoadingProducts(false);
    } catch (error: any) {
      setIsLoadingProducts(false);
      toast.error(error?.response?.data?.message);
    }
  }, [period, page, storeAliasId, toast, format, debouncedValue]);

  const next = React.useCallback(() => {
    if (products.length) {
      setPage(oldPage => oldPage + 1);
    }
  }, [products.length]);

  const handleOptionClick = React.useCallback(
    (product: IProduct) => {
      const newData: ISelectedFilter = {
        field: config.field,
        label: product.name,
        value: product.id,
        aliasIdHelper: product.alias_id,
        operator: EFilterOperator.EQUAL,
      };

      if (defaultValue) {
        updateData(newData);
      } else {
        addNewData(newData);
      }

      handleOnClick();
    },
    [handleOnClick, config, addNewData, defaultValue, updateData],
  );

  React.useEffect(() => {
    setPage(0);
    setTotalPages(1);
    setProducts([]);
    setOptionToSearch(debouncedValue);
  }, [debouncedValue]);

  React.useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const hasMore = page < totalPages;

  return (
    <S.Wrapper id="products-filter">
      <S.ValueToFilterWrapper>
        <S.ValueToFilterInput
          type="text"
          placeholder={filterConfig.placeholder}
          onChange={onSearchValueChange}
          autoFocus
        />
      </S.ValueToFilterWrapper>

      <InfiniteScroll
        dataLength={products.length}
        next={next}
        hasMore={hasMore}
        loader={<SkeletonLoading />}
        scrollableTarget="products-filter"
        scrollThreshold={0.95}
      >
        {Boolean(!products.length) && isLoadingProducts && <SkeletonLoading />}

        {products.map(product => (
          <Product product={product} key={product.alias_id} handleOptionClick={handleOptionClick} />
        ))}
      </InfiniteScroll>
    </S.Wrapper>
  );
};

export default Products;
