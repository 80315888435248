import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 396px;
  position: fixed;
  z-index: 1;
  bottom: 24px;
  right: 24px;
  padding: 16px;
  padding-top: 52px;
  box-sizing: border-box;
  border-radius: 7px;
  background: radial-gradient(
      64.29% 51.81% at 109.07% -4.25%,
      rgba(183, 138, 255, 0.2) 18.01%,
      rgba(0, 0, 0, 0) 68.74%
    ),
    radial-gradient(
      93.15% 89.25% at -17.95% 7.54%,
      rgba(183, 138, 255, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    radial-gradient(
      75.58% 60.91% at 117.66% 97.4%,
      rgba(183, 138, 255, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    #000000;
`;

export const Image = styled(ImageBase)`
  margin-bottom: 16px;
  max-width: 447px;
  border-radius: 5px;
`;

export const SubTitle = styled(TextBase)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.green.default};
  font-size: 0.75rem;
`;

export const Title = styled(HeadingBase)`
  color: #ffffff;
  margin-bottom: 8px;
  line-height: 130%;
`;

export const Description = styled(TextBase)`
  line-height: 150%;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const SeeNewsButtons = styled.button`
  padding: 10px 24px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: linear-gradient(225deg, #916be3 0%, #59d1ec 100%);
  border: unset;
  color: ${({ theme }) => theme.colors.gray[8]};
  font-weight: 700;
  font-size: 0.875rem;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  cursor: pointer;
`;

export const CancelButton = styled.button`
  width: 100%;
  max-width: 92px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: unset;
  padding: unset;
  border: unset;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  color: #ffffff;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 32px;
  background: unset;
  border: unset;
  padding: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;
