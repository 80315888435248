import React from 'react';
import { Currency } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import * as S from './styles';

const Header: React.FC = () => {
  const theme: any = useTheme();

  return (
    <S.HeaderWrapper>
      <Currency color={theme.colors.green.default} size={36} />

      <S.Title>Novo custo de Ads</S.Title>
    </S.HeaderWrapper>
  );
};

export default Header;
