import React from 'react';

import { WhatsappLogo, SignOut } from 'phosphor-react';
import { useReactFlow } from 'react-flow-renderer';
import { v4 } from 'uuid';

import { ENodes } from '@domain/enums/dashboard/automations/node/ENodes';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';
import { useStorePermission } from '@helpers/hooks/common/useStorePermission';

import Option from '../Option';

import * as S from './styles';

const ActionsTab: React.FC = () => {
  const reactFlowInstance = useReactFlow();
  const {
    trackNode,
    nodes,
    initialNodes,
    handleMessagePerWorkflowLimitationModalOpen,
  } = useAutomation();
  const { maxMessagePerWorkflow } = useStorePermission();

  const isNewAutomation = initialNodes.length === 0;
  const currentAutomationWhatsappNodes = initialNodes.filter(node => node.type === ENodes.WHATSAPP);
  const whatsappNodes = nodes.filter(node => node.type === ENodes.WHATSAPP);

  const onDragStartEndAutomationNode = (event: React.DragEvent<HTMLDivElement>): void => {
    event.dataTransfer.setData('application/reactflow', ENodes.END);
    event.dataTransfer.effectAllowed = 'move';
  };

  const onDragStartSendWhatsAppNode = (event: React.DragEvent<HTMLDivElement>): void => {
    if (
      (whatsappNodes?.length ?? 0) >= maxMessagePerWorkflow &&
      maxMessagePerWorkflow !== -1 &&
      isNewAutomation
    ) {
      handleMessagePerWorkflowLimitationModalOpen();
      return;
    }

    if (
      (whatsappNodes?.length ?? 0) >= maxMessagePerWorkflow &&
      maxMessagePerWorkflow !== -1 &&
      (whatsappNodes?.length ?? 0) >= currentAutomationWhatsappNodes.length
    ) {
      handleMessagePerWorkflowLimitationModalOpen();
      return;
    }

    event.dataTransfer.setData('application/reactflow', ENodes.WHATSAPP);
    event.dataTransfer.effectAllowed = 'move';
  };

  const handleClickEndAutomationNode = React.useCallback(() => {
    const type = ENodes.END;

    const rules: any[] = [];

    const newNode = {
      id: type,
      type,
      position: {
        x: Math.random() * 500,
        y: Math.random() * 500,
      },
      data: { label: `${type}`, rules, name: v4() },
    };

    reactFlowInstance.addNodes(newNode);

    trackNode(type);
  }, [reactFlowInstance, trackNode]);

  const handleClickSendWhatsAppNode = React.useCallback(() => {
    if (
      (whatsappNodes?.length ?? 0) >= maxMessagePerWorkflow &&
      maxMessagePerWorkflow !== -1 &&
      isNewAutomation
    ) {
      handleMessagePerWorkflowLimitationModalOpen();
      return;
    }

    if (
      (whatsappNodes?.length ?? 0) >= maxMessagePerWorkflow &&
      maxMessagePerWorkflow !== -1 &&
      (whatsappNodes?.length ?? 0) >= currentAutomationWhatsappNodes.length
    ) {
      handleMessagePerWorkflowLimitationModalOpen();
      return;
    }

    const type = ENodes.WHATSAPP;

    const rules: any[] = [];

    const newNode = {
      id: v4(),
      type,
      position: {
        x: Math.random() * 500,
        y: Math.random() * 500,
      },
      data: { label: `${type}`, rules, name: v4() },
    };

    reactFlowInstance.addNodes(newNode);

    trackNode(type);
  }, [
    reactFlowInstance,
    trackNode,
    handleMessagePerWorkflowLimitationModalOpen,
    whatsappNodes,
    maxMessagePerWorkflow,
    isNewAutomation,
    currentAutomationWhatsappNodes,
  ]);

  return (
    <S.Wrapper>
      <Option
        onClick={handleClickSendWhatsAppNode}
        onDragStart={onDragStartSendWhatsAppNode}
        icon={WhatsappLogo}
      >
        Enviar mensagem
      </Option>

      <Option
        onClick={handleClickEndAutomationNode}
        onDragStart={onDragStartEndAutomationNode}
        icon={SignOut}
        variant="danger"
      >
        Encerrar automação
      </Option>
    </S.Wrapper>
  );
};

export default ActionsTab;
