import React from 'react';

import { GoogleCredentialsProvider } from '@helpers/hooks/common/integrations/marketing/google/useGoogleCredentials';

import { IMarketingContentProps } from '@domain/interfaces/dashboard/IntegrationCenter/Marketing/IMarketingContent';

import Wrapper from './Wrapper';

const Google: React.FC<IMarketingContentProps> = ({ callback }) => {
  return (
    <GoogleCredentialsProvider>
      <Wrapper callback={callback} />
    </GoogleCredentialsProvider>
  );
};

export default Google;
