import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ImageBase from '@components/common/core/DataDisplay/Image';
import TextBase from '@components/common/core/DataDisplay/Text';

export const SubtotalValue = styled(TextBase)`
  line-height: 120%;
`;

export const SubtotalLabel = styled(TextBase)`
  line-height: 120%;
`;

export const DiscountValue = styled(TextBase)`
  line-height: 120%;
  color: ${({ theme }) => theme.colors.success.default};
`;

export const DiscountLabel = styled(TextBase)`
  line-height: 120%;
  color: ${({ theme }) => theme.colors.success.default};
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    cursor: pointer;
  }
`;

export const TotalRecurrenceText = styled(TextBase)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[3]};
  line-height: 120%;
`;

export const TotalValue = styled(TextBase)`
  line-height: 120%;
  font-size: 1.125rem;
  font-weight: 700;
`;

export const TotalValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const TotalLabel = styled(TextBase)`
  line-height: 120%;
  font-size: 1.125rem;
  font-weight: 700;
`;

export const ValueWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TotalOrDiscountWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 16px;
  gap: 12px;
`;

export const PlanPrice = styled(TextBase)`
  display: inline-block;
  margin-bottom: 32px;

  & > small {
    color: ${({ theme }) => theme.colors.gray[2]};
    font-weight: 400;
    font-size: 1rem;
    margin-left: 4px;
  }
`;

export const PlanName = styled(TextBase)`
  font-size: 1.125rem;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 8px;
  line-height: 100%;

  & > strong {
    color: ${({ theme }) => theme.colors.gray[3]};
  }
`;

export const PlanDetailsWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
`;

export const HorizontalRule = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.background[2]};
  margin: unset;
  margin-bottom: 12px;
`;

export const Title = styled(HeadingBase)`
  display: inline-block;
  margin-bottom: 16px;
`;

export const ContentWrapper = styled.div`
  max-width: 440px;
  width: 100%;
  margin: 0 auto;

  &::before {
    content: '';
    position: absolute;
    width: 14px;
    height: 12px;
    top: 58px;
    right: 38px;
    background-repeat: no-repeat;

    @media only screen and (max-width: 596px) {
      display: none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 22px;
    height: 22px;
    top: 341px;
    right: 33px;
    background-repeat: no-repeat;

    @media only screen and (max-width: 1176px) {
      display: none;
    }
  }
`;

export const Logo = styled(ImageBase)`
  max-width: 132px;
  width: 100%;
  margin-bottom: 82px;
  position: relative;

  @media only screen and (max-width: 885px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  width: 50%;
  min-height: 100vh;
  background: linear-gradient(
    8.42deg,
    #000000 -5.05%,
    #000000 10.18%,
    #050609 48.24%,
    #11131a 72.41%
  );
  padding: 32px;
  box-sizing: border-box;
  position: relative;

  @media only screen and (max-width: 885px) {
    width: 100%;
    background: unset;
  }

  &::before {
    content: '';
    position: absolute;
    width: 17px;
    height: 142px;
    bottom: 48px;
    right: 8px;
    background-repeat: no-repeat;

    @media only screen and (max-height: 850px) {
      display: none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 161px;
    height: 22px;
    bottom: 54px;
    left: 32px;
    background-repeat: no-repeat;

    @media only screen and (max-height: 745px) {
      display: none;
    }
  }
`;
