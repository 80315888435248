export const PLANS_BENEFITS = [
  {
    identifier: 'trial_ended',
    benefits: [],
  },
  {
    identifier: 'free_monthly_v1',
    benefits: ['Dashboard de previsibilidade financeira', 'Automações'],
  },
  {
    identifier: 'starter_monthly_v1',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Automações',
    ],
  },
  {
    identifier: 'essentials_monthly_v1',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Automações',
    ],
  },
  {
    identifier: 'premium_monthly_v1',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Onboarding personalizado',
      'Automações',
    ],
  },
  {
    identifier: 'pro_monthly_v1',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Onboarding personalizado',
      'Automações',
    ],
  },
  {
    identifier: 'elite_monthly_v1',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Onboarding personalizado',
      'Automações',
      'Assessoria dedicada',
      'Suporte prioritário',
    ],
  },
  {
    identifier: 'lite_monthly_v1',
    benefits: ['Dashboard de previsibilidade financeira', 'Automações'],
  },
  {
    identifier: 'pro_monthly_familia_da_escala_v1',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Onboarding personalizado',
      'Automações',
    ],
  },
  {
    identifier: 'starter_monthly_v2',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Automações',
    ],
  },
  {
    identifier: 'free_monthly_v2',
    benefits: ['Dashboard de previsibilidade financeira', 'Automações'],
  },
  {
    identifier: 'starter_monthly_v3',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Automações',
    ],
  },
  {
    identifier: 'essentials_monthly_v2',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Automações',
    ],
  },
  {
    identifier: 'premium_monthly_v2',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Onboarding personalizado',
      'Automações',
    ],
  },
  {
    identifier: 'unlimited_monthly_v1',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Onboarding personalizado',
      'Automações',
      'Assessoria dedicada',
      'Suporte prioritário',
    ],
  },
  {
    identifier: 'enterprise_one_monthly_v1',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Onboarding personalizado',
      'Automações',
      'Assessoria dedicada',
      'Suporte prioritário',
    ],
  },
  {
    identifier: 'enterprise_two_monthly_v1',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Onboarding personalizado',
      'Automações',
      'Assessoria dedicada',
      'Suporte prioritário',
    ],
  },
  {
    identifier: 'enterprise_three_monthly_v1',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Onboarding personalizado',
      'Automações',
      'Assessoria dedicada',
      'Suporte prioritário',
    ],
  },
  {
    identifier: 'enterprise_four_monthly_v1',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Onboarding personalizado',
      'Automações',
      'Assessoria dedicada',
      'Suporte prioritário',
    ],
  },
  {
    identifier: 'enterprise_five_monthly_v1',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Onboarding personalizado',
      'Automações',
      'Assessoria dedicada',
      'Suporte prioritário',
    ],
  },
  {
    identifier: 'enterprise_six_monthly_v1',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Onboarding personalizado',
      'Automações',
      'Assessoria dedicada',
      'Suporte prioritário',
    ],
  },
  {
    identifier: 'enterprise_seven_monthly_v1',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Onboarding personalizado',
      'Automações',
      'Assessoria dedicada',
      'Suporte prioritário',
    ],
  },
  {
    identifier: 'enterprise_one_monthly_10_off_v1',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Onboarding personalizado',
      'Automações',
      'Assessoria dedicada',
      'Suporte prioritário',
    ],
  },
  {
    identifier: 'enterprise_two_monthly_10_off_v1',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Onboarding personalizado',
      'Automações',
      'Assessoria dedicada',
      'Suporte prioritário',
    ],
  },
  {
    identifier: 'enterprise_three_monthly_10_off_v1',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Onboarding personalizado',
      'Automações',
      'Assessoria dedicada',
      'Suporte prioritário',
    ],
  },
  {
    identifier: 'enterprise_four_monthly_10_off_v1',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Onboarding personalizado',
      'Automações',
      'Assessoria dedicada',
      'Suporte prioritário',
    ],
  },
  {
    identifier: 'enterprise_six_monthly_10_off_v1',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Onboarding personalizado',
      'Automações',
      'Assessoria dedicada',
      'Suporte prioritário',
    ],
  },
  {
    identifier: 'starter_monthly_10_off_v2',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Automações',
    ],
  },
  {
    identifier: 'essentials_monthly_10_off_v2',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Automações',
    ],
  },
  {
    identifier: 'premium_monthly_10_off_v2',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Onboarding personalizado',
      'Automações',
    ],
  },
  {
    identifier: 'partners_v1',
    benefits: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios',
      'Onboarding personalizado',
      'Automações',
      'Assessoria dedicada',
      'Suporte prioritário',
    ],
  },
];
