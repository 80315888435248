import React from 'react';
import { useHistory } from 'react-router-dom';

import { EMambaTermsStatus } from '@domain/enums/common/mamba/EMambaTermStatus';
import { EBequestTermsStatus } from '@domain/enums/common/bequest/EBequestTermStatus';

import { useConfig } from '@helpers/hooks/useConfig';

import SecondForm from '@components/Auth/SignUp/SecondForm';
import PageError from '@components/common/core/Utils/PageError';
import personalInfoService from '@services/pages/auth/personalInfo';

import * as S from './styles';

const SignUp: React.FC = () => {
  const history = useHistory();
  const { appAffiliate, term } = useConfig();

  if (
    appAffiliate &&
    appAffiliate.referral_code === 'njlimzb' &&
    term.term_to_provide_data_to_mamba_culture_accepted_status === EMambaTermsStatus.NONE
  ) {
    history.push('/consent/mamba-digital');
  } else if (
    appAffiliate &&
    appAffiliate.referral_code === 'nwjkody' &&
    term.term_to_provide_data_to_bequest_culture_accepted_status === EBequestTermsStatus.NONE
  ) {
    history.push('/consent/bequest-digital');
  }

  const { error, isValidating, mutate, personalInfo } = personalInfoService.getPersonalInfo();

  if (error?.message === 'Request failed with status code 401') {
    history.push('/');
  }

  if (error && !isValidating) {
    return <PageError mutate={mutate} />;
  }

  return (
    <S.Container>
      <S.LeftSide>
        <S.ContentWrapper>
          <SecondForm personalInfo={personalInfo} />
        </S.ContentWrapper>
      </S.LeftSide>
      <S.RightSide />
    </S.Container>
  );
};

export default React.memo(SignUp);
