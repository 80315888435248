import React from 'react';
import { Warning } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';

import { ETextSize } from '@domain/enums/components/EText';
import { ICredentialErrorModalProps } from '@domain/interfaces/integrations/storeProvider/shopify/IShopify';
import { IParams } from '@domain/interfaces/IParams';

import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const CredentialErrorModal: React.FC<ICredentialErrorModalProps> = ({ isOpen, toggle }) => {
  const theme = useTheme();
  const { storeAliasId } = useParams<IParams>();
  const { analytics } = useConfig();

  const trackArticleClick = React.useCallback(() => {
    analytics?.track(
      'Shopify Integration Article Clicked',
      {
        click_source: 'credential_error_modal',
      },
      { context: { groupId: storeAliasId } },
    );
  }, [analytics, storeAliasId]);

  React.useEffect(() => {
    if (isOpen) {
      analytics?.track(
        'Shopify Integration Credential Error Viewed',
        {},
        { context: { groupId: storeAliasId } },
      );
    }
  }, [isOpen, analytics, storeAliasId]);

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.HeadingWrapper>
        <Warning size={24} color={theme.colors.danger.default} outline />
        <S.Heading>Existe alguma credencial incorreta</S.Heading>
      </S.HeadingWrapper>

      <S.Description size={ETextSize.B5}>
        <strong>Revise as credenciais</strong> preenchidas para se certificar que não exista algum
        erro. <br /> Caso tenha alguma dúvida sobre como realizar a integração com Shopify,{' '}
        <S.Link
          href="https://ajuda.profitfy.me/pt-BR/articles/5901324-como-integrar-com-a-loja-shopify"
          target="_blank"
          onClick={trackArticleClick}
        >
          você pode clicar aqui para acessar o link do artigo.
        </S.Link>
      </S.Description>

      <S.UnderstoodButton onClick={toggle}>Entendi</S.UnderstoodButton>
    </S.Modal>
  );
};

export default CredentialErrorModal;
