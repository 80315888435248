import styled, { css } from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.div`
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.gray[4]};
  border-radius: 12px;
  padding: 16px;
  box-sizing: border-box;
  margin-bottom: 32px;

  @media only screen and (max-width: 955px) {
    margin-bottom: unset;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled(TextBase)`
  margin-bottom: 24px;
  line-height: 120%;
`;

export const SelectOptionWrapper = styled.div`
  width: 100%;
  padding: 4px;
  display: flex;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.background[2]};
  box-sizing: border-box;
  margin-bottom: 12px;
`;

export const SelectOptionButton = styled.button<{ isSelected: boolean }>`
  width: 50%;
  background-color: ${({ theme }) => theme.colors.background[2]};
  border: unset;
  padding: unset;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.gray[3]};
  border-radius: 5px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.colors.gray[5]};
      color: ${({ theme }) => theme.colors.gray[1]};
    `}
`;
