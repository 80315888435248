import styled from 'styled-components/macro';

import ButtonBase from '@components/common/core/Inputs/Button';

export const ActiveIntegrationButtonBackground = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.background[11]};
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  left: 0;
`;

export const ActiveIntegrationButtonWrapper = styled.div`
  display: flex;
`;

export const ActiveIntegrationButton = styled(ButtonBase)``;
