import React from 'react';
import { WarningCircle } from 'phosphor-react';
import { useTheme } from 'styled-components';

import * as S from './styles';

interface IConnectedInstanceModalProps {
  isOpen: boolean;
  toggle: () => void;
}

const ConnectedInstanceModal: React.FC<IConnectedInstanceModalProps> = ({ isOpen, toggle }) => {
  const { colors } = useTheme();

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Header>
        <WarningCircle color={colors.info.default} size={24} />
        <S.Heading>Instância conectada</S.Heading>
      </S.Header>
      <S.Body>
        <S.Text>
          A sua instância do WhatsApp está conectada! Agora ela estará apta a ser usada para o envio
          de mensagens sempre que precisar, sem a necessidade de manter o seu celular ligado.
        </S.Text>
        <S.Button onClick={toggle}>Entendi</S.Button>
      </S.Body>
    </S.Modal>
  );
};

export default ConnectedInstanceModal;
