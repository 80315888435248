import React from 'react';
import { useParams } from 'react-router-dom';

import { EInfoProductProvider } from '@domain/enums/common/integrations/EInfoProductProvider';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';
import { ISynchronizationRequest } from '@domain/interfaces/hooks/IStoreSynchronization';

import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useDate } from '@helpers/hooks/useDate';

import { getSynchronizationStatus } from '@helpers/utils/common/synchronization';

import { INFO_PRODUCT_PROVIDERS } from '@constants/common/integrations';

import { useStoreIntegrations } from '@helpers/hooks/pages/dashboard/integrationCenter/useStoreIntegrations';
import { useToast } from '@helpers/hooks/useToast';
import * as S from './styles';

const SyncOption: React.FC = () => {
  const { toast } = useToast();
  const { handleSideModalOpen } = useStoreIntegrations();
  const foundIntegration = INFO_PRODUCT_PROVIDERS.find(
    integration => integration.identifier === EInfoProductProvider.HOTMART,
  ) as typeof INFO_PRODUCT_PROVIDERS[number];

  const { storeAliasId } = useParams<IParams>();
  const { utcToZonedTime, format } = useDate();
  const {
    addSynchronization,
    currentSynchronizations,
    synchronizationsQueue,
  } = useSynchronization();

  const [period, setPeriod] = React.useState({
    startDate: utcToZonedTime(new Date()),
    endDate: utcToZonedTime(new Date()),
  });

  const syncOrdersRef = React.useRef<HTMLInputElement>(null);

  const onSync = React.useCallback(async () => {
    const { startDate, endDate } = period;

    const formattedStartDate = format(utcToZonedTime(startDate), 'yyyy-MM-dd');
    const formattedEndDate = format(utcToZonedTime(endDate), 'yyyy-MM-dd');

    if (syncOrdersRef.current) {
      if (syncOrdersRef.current.checked) {
        const params = `?start_date=${formattedStartDate}&end_date=${formattedEndDate}`;

        const emptyRequestObject = {} as ISynchronizationRequest;

        addSynchronization({
          name: foundIntegration.name,
          type: ESynchronizationType.STORE_ORDER,
          label: 'Pedidos',
          dependencies: [],
          storeAliasId,
          config: {
            isOnboardingSynchronization: false,
            showNotification: true,
          },
          request:
            foundIntegration.generateSyncRequest?.(
              ESynchronizationType.STORE_ORDER,
              storeAliasId,
              params,
            ) || emptyRequestObject,
        });
      }

      toast.success('A sincronização iniciou!');
      handleSideModalOpen();
    }
  }, [
    period,
    format,
    utcToZonedTime,
    toast,
    handleSideModalOpen,
    addSynchronization,
    foundIntegration,
    storeAliasId,
  ]);

  const datePickerMaxDate = utcToZonedTime(new Date());

  const isSyncingHistoric = getSynchronizationStatus(
    currentSynchronizations,
    synchronizationsQueue,
    foundIntegration?.name,
    ESynchronizationType.STORE_ORDER,
    storeAliasId,
  );

  return (
    <S.Content>
      <S.InputGroup>
        <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
          Escolha o que sincronizar
        </S.Title>

        <S.SwitchGroup>
          <S.Switch
            id="sync_orders"
            name="sync_orders"
            isLoading={false}
            defaultChecked
            innerRef={syncOrdersRef}
          />

          <S.Label>Pedidos</S.Label>
        </S.SwitchGroup>
      </S.InputGroup>

      <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
        Selecione um período para sincronizar
      </S.Title>

      <S.CustomDatePicker period={period} setPeriod={setPeriod} maxDate={datePickerMaxDate} />

      <S.SyncButton onClick={onSync} isLoading={isSyncingHistoric} disabled={isSyncingHistoric}>
        Sincronizar
      </S.SyncButton>
    </S.Content>
  );
};

export default SyncOption;
