import styled from 'styled-components/macro';

import ButtonBase from '@components/common/core/Inputs/Button';

export const CancelButton = styled.button`
  margin-right: 16px;
  background-color: unset;
  border: unset;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.green.default};
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Background = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.background[11]};
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  left: 0;
`;

export const SaveWrapper = styled.div`
  display: flex;
`;

export const DeleteButton = styled(ButtonBase)`
  margin-right: 16px;
`;

export const ConfirmButton = styled(ButtonBase)``;
