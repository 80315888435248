import * as yup from 'yup';

export const editOrderModalSchema = yup.object().shape({
  status: yup.string().required('É necessário um status'),
  type: yup.string().required('É necessário um tipo de pedido'),
  gateway_amount: yup.string().required('É necessário informar o custo do gateway'),
  info_product_fee_amount: yup.string().required('É necessário informar o custo do infoproduto'),
});

export const editChargebackModalSchema = yup.object().shape({
  status: yup.string().required('É necessário um status'),
  type: yup.string().required('É necessário um tipo de pedido'),
  refunded_amount: yup.string().required('É necessário informar um valor de chargeback'),
});
