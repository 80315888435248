import React from 'react';

import { IHistoricSidemodalProps } from '@domain/interfaces/dashboard/ProductCostDetails/IHistoricSidemodal';
import { useVariantHistoric } from '@helpers/hooks/pages/dashboard/productCostDetails/variantHistoric';

import ComponentError from '@components/common/core/Utils/ComponentError';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';

import * as S from './styles';

const HistoricSidemodal: React.FC<IHistoricSidemodalProps> = ({ isOpen, toggle, variant }) => {
  const {
    loadVariantCosts,
    loadDefaultVariantCost,
    handleSelectedVariant,
    userStep,
    handleUserStep,
    variantCostsError,
    defaultVariantCostError,
    isLoadingDefaultVariantCost,
    isLoadingVariantCosts,
  } = useVariantHistoric();

  const resetStepOnToggle = React.useCallback(() => {
    handleUserStep('FIRST');

    toggle();
  }, [toggle, handleUserStep]);

  const mutate = React.useCallback(async () => {
    const promises = [];

    if (variant) {
      if (variantCostsError) promises.push(loadVariantCosts(variant.id));

      if (defaultVariantCostError) promises.push(loadDefaultVariantCost(variant.id));
    }

    await Promise.all(promises);
  }, [
    loadDefaultVariantCost,
    loadVariantCosts,
    variantCostsError,
    defaultVariantCostError,
    variant,
  ]);

  React.useEffect(() => {
    if (variant) {
      loadVariantCosts(variant.id);
      loadDefaultVariantCost(variant.id);
      handleSelectedVariant(variant);
    }
  }, [variant, loadVariantCosts, loadDefaultVariantCost, handleSelectedVariant]);

  if (
    (variantCostsError && !isLoadingVariantCosts) ||
    (defaultVariantCostError && !isLoadingDefaultVariantCost)
  ) {
    return (
      <S.Sidemodal isOpen={isOpen} toggle={resetStepOnToggle}>
        <S.ErrorWrapper>
          <ComponentError mutate={mutate} />
        </S.ErrorWrapper>
      </S.Sidemodal>
    );
  }

  return (
    <S.Sidemodal isOpen={isOpen} toggle={resetStepOnToggle}>
      <S.ContentWrapper>
        {userStep === 'FIRST' && <FirstStep />}

        {userStep === 'SECOND' && <SecondStep />}
      </S.ContentWrapper>
    </S.Sidemodal>
  );
};

export default HistoricSidemodal;
