import React from 'react';

import { EBadgeColors } from '@domain/enums/components/EBadge';
import { ESwitchSize } from '@domain/enums/components/ESwitch';
import { EPlanRecurrence } from '@domain/enums/subscription/ESubscription';

import { useCheckout } from '@helpers/hooks/pages/subscription/useCheckout';
import { useConfig } from '@helpers/hooks/useConfig';
import { useCoupon } from '@helpers/hooks/pages/subscription/useCoupon';

import { numberFormatter } from '@helpers/masks';
import {
  getSelectedPlanRecurrencePrices,
  getSelectedPlanRecurrenceDiscounts,
} from '@helpers/utils/subscription/checkout/recurrence';

import * as S from './styles';

const Recurrence: React.FC = () => {
  const { analytics, user, selectedStore } = useConfig();
  const { selectedCoupon, resetCoupon } = useCoupon();
  const {
    handleRecurrenceType,
    selectedPlan,
    selectedInstallment,
    handleSelectedInstallment,
    recurrenceType,
  } = useCheckout();

  const quarterOptionRef = React.useRef<HTMLInputElement>(null);
  const semiAnualOptionRef = React.useRef<HTMLInputElement>(null);

  const onQuarterLabelClick = React.useCallback(
    checked => {
      if (!semiAnualOptionRef.current || !quarterOptionRef.current) return;

      if (checked) {
        quarterOptionRef.current.checked = true;
        semiAnualOptionRef.current.checked = false;
        handleSelectedInstallment(1);
        handleRecurrenceType(EPlanRecurrence.QUARTER);

        if (selectedCoupon && selectedCoupon.max_cycles && selectedCoupon.max_cycles > 0) {
          resetCoupon();
        }
      } else {
        quarterOptionRef.current.checked = false;

        if (selectedInstallment > 3) handleSelectedInstallment(1);

        handleRecurrenceType(EPlanRecurrence.MONTHLY);
      }
    },
    [
      handleRecurrenceType,
      selectedInstallment,
      handleSelectedInstallment,
      selectedCoupon,
      resetCoupon,
    ],
  );

  const onSemianualLabelClick = React.useCallback(
    checked => {
      if (!semiAnualOptionRef.current || !quarterOptionRef.current) return;

      if (checked) {
        semiAnualOptionRef.current.checked = true;
        quarterOptionRef.current.checked = false;
        handleSelectedInstallment(1);
        handleRecurrenceType(EPlanRecurrence.SEMIANUAL);

        if (selectedCoupon && selectedCoupon.max_cycles && selectedCoupon.max_cycles > 0) {
          resetCoupon();
        }
      } else {
        semiAnualOptionRef.current.checked = false;
        handleRecurrenceType(EPlanRecurrence.MONTHLY);
      }
    },
    [handleRecurrenceType, handleSelectedInstallment, selectedCoupon, resetCoupon],
  );

  const onSwitchChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!semiAnualOptionRef.current || !quarterOptionRef.current) return;

      const { name, checked } = event.target;

      if (name === 'quarter-option' && checked) {
        semiAnualOptionRef.current.checked = false;

        if (selectedInstallment > 3) handleSelectedInstallment(1);

        handleRecurrenceType(EPlanRecurrence.QUARTER);

        if (selectedCoupon && selectedCoupon.max_cycles && selectedCoupon.max_cycles > 0) {
          resetCoupon();
        }

        analytics?.track(
          'Subscription Checkout Button Switch',
          {
            button_source: 'QUARTERLY',
            email: user?.email,
          },
          {
            context: { groupId: selectedStore?.alias_id },
          },
        );
        return;
      }

      if (name === 'semianual-option' && checked) {
        quarterOptionRef.current.checked = false;
        handleRecurrenceType(EPlanRecurrence.SEMIANUAL);

        if (selectedCoupon && selectedCoupon.max_cycles && selectedCoupon.max_cycles > 0) {
          resetCoupon();
        }

        analytics?.track(
          'Subscription Checkout Button Switch',
          {
            button_source: 'SEMIANNUAL',
            email: user?.email,
          },
          {
            context: { groupId: selectedStore?.alias_id },
          },
        );
        return;
      }

      handleSelectedInstallment(1);
      handleRecurrenceType(EPlanRecurrence.MONTHLY);
    },
    [
      handleRecurrenceType,
      handleSelectedInstallment,
      selectedInstallment,
      analytics,
      user,
      selectedStore,
      selectedCoupon,
      resetCoupon,
    ],
  );

  React.useEffect(() => {
    if (recurrenceType === EPlanRecurrence.MONTHLY) return;

    if (!quarterOptionRef.current && !semiAnualOptionRef.current) return;

    if (
      recurrenceType === EPlanRecurrence.QUARTER &&
      quarterOptionRef.current &&
      semiAnualOptionRef.current
    ) {
      quarterOptionRef.current.checked = true;
      semiAnualOptionRef.current.checked = false;
    }

    if (
      recurrenceType === EPlanRecurrence.SEMIANUAL &&
      quarterOptionRef.current &&
      semiAnualOptionRef.current
    ) {
      semiAnualOptionRef.current.checked = true;
      quarterOptionRef.current.checked = false;
    }
  }, [recurrenceType]);

  const [, quarterPrice, semiAnualPrice] = getSelectedPlanRecurrencePrices(selectedPlan);
  const [, quarterDiscount, semiAnualDiscount] = getSelectedPlanRecurrenceDiscounts(selectedPlan);

  return (
    <S.Wrapper>
      <S.OptionWrapper>
        <S.Description>
          <S.DescriptionText>
            Economize assinando o plano <strong>trimestral</strong>
          </S.DescriptionText>
        </S.Description>

        <S.InputGroup>
          <S.Switch
            id="quarter-option"
            name="quarter-option"
            isLoading={false}
            size={ESwitchSize.SMALL}
            innerRef={quarterOptionRef}
            onChange={onSwitchChange}
          />

          <S.SwitchLabel onClick={() => onQuarterLabelClick(!quarterOptionRef?.current?.checked)}>
            {`Pague R$${numberFormatter(quarterPrice, 2)} a cada 3 meses`}
          </S.SwitchLabel>

          <S.Badge color={EBadgeColors.SUCCESS}>{`Desconto de R$${numberFormatter(
            quarterDiscount,
            0,
          )}`}</S.Badge>
        </S.InputGroup>
      </S.OptionWrapper>

      <S.OptionWrapper onClick={() => onSemianualLabelClick(!semiAnualOptionRef?.current?.checked)}>
        <S.Description>
          <S.DescriptionText>
            Economize assinando o plano <strong>semestral</strong>
          </S.DescriptionText>
        </S.Description>

        <S.InputGroup>
          <S.Switch
            id="semianual-option"
            name="semianual-option"
            isLoading={false}
            size={ESwitchSize.SMALL}
            innerRef={semiAnualOptionRef}
            onChange={onSwitchChange}
          />

          <S.SwitchLabel>{`Pague R$${numberFormatter(
            semiAnualPrice,
            2,
          )} a cada 6 meses`}</S.SwitchLabel>

          <S.Badge color={EBadgeColors.SUCCESS}>{`Desconto de R$${numberFormatter(
            semiAnualDiscount,
            0,
          )}`}</S.Badge>
        </S.InputGroup>
      </S.OptionWrapper>
    </S.Wrapper>
  );
};

export default Recurrence;
