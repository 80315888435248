import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled(ModalBase)`
  width: 100%;
  max-width: 528px;
  height: 586px;
  padding: 32px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: 20px;
`;

export const GroupTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Title = styled(TextBase)`
  font-size: 1.25rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const CloseButton = styled.button`
  background: unset;
  padding: unset;
  border: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;
