import styled from 'styled-components/macro';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Link = styled.a`
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.font};
  font-size: 0.875rem;
  text-decoration: underline;
  text-underline-offset: 4px;
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CodeEditor = styled(SyntaxHighlighter)``;

export const CodeWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 16px;

  pre {
    padding: 0 !important;
    margin: 0 !important;
    background: ${({ theme }) => theme.colors.black.light} !important;
    font-family: ${({ theme }) => theme.fonts.body}!important;
    font-size: 0.875rem !important;
    border-radius: 7px !important;
    padding: 32px !important;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
      position: absolute;
    }

    code {
      span {
        font-size: 0.875rem !important;
      }

      .linenumber {
        min-width: unset !important;
      }
    }
  }
`;

export const CopyButton = styled(ButtonBase)`
  position: absolute;
  top: 10px;
  right: 16px;
  background-color: unset;
  border: 1px solid ${({ theme }) => theme.colors.green.default};

  span {
    color: ${({ theme }) => theme.colors.green.default};
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &:hover {
    filter: brightness(1.2);
    background-color: unset;
  }

  &:active {
    filter: brightness(0.9);
    background-color: unset;
  }
`;

export const Description = styled(TextBase)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[2]};
  line-height: 120%;
  margin-top: 12px;
  margin-bottom: 12px;
  display: inline-block;
  max-width: 524px;
`;

export const Title = styled(HeadingBase)``;

export const Wrapper = styled.div``;
