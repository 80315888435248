import styled from 'styled-components/macro';

export const Header = styled.thead`
  background-color: ${({ theme }) => theme.colors.gray[4]};

  & > tr {
    & > th {
      padding: 10px 28px;
    }

    & > th:first-child {
      padding-left: 32px;
    }

    & > th:last-child {
      padding-right: 32px;
    }
  }
`;
