import React from 'react';

import { EPreDefinedDates } from '@domain/enums/components/EDatePicker';
import {
  IDatePickerRangeProvider,
  IDatePickerRangeProps,
} from '@domain/interfaces/components/IDatePickerRange';
import { useDate } from '../useDate';

const DatePickerRangeContext = React.createContext<IDatePickerRangeProvider | null>(null);

export const DatePickerRangeProvider: React.FC<IDatePickerRangeProps> = ({
  period,
  setPeriod,
  minDate,
  maxDate,
  excludeTodayInLastSevenDays,
  children,
}) => {
  const { utcToZonedTime, subDays, format, isBefore, isAfter } = useDate();

  const [selectedDate, setSelectedDate] = React.useState<EPreDefinedDates>(EPreDefinedDates.TODAY);
  const [startDate, setStartDate] = React.useState<Date>(utcToZonedTime(period.startDate));
  const [endDate, setEndDate] = React.useState<Date>(utcToZonedTime(period.endDate));
  const [isCalendarOpen, setIsCalendarOpen] = React.useState<boolean>(false);
  const [isCustomDatePickerOpen, setIsCustomDatePickerOpen] = React.useState<boolean>(false);
  const [customStartDate, setCustomStartDate] = React.useState<Date>(
    utcToZonedTime(period.startDate),
  );
  const [customEndDate, setCustomEndDate] = React.useState<Date>(utcToZonedTime(period.endDate));

  const resetCustomDatePicker = React.useCallback(() => {
    setIsCalendarOpen(false);
    setIsCustomDatePickerOpen(false);
  }, []);

  const handleCustomDatePickerOpen = React.useCallback(() => {
    if (isCustomDatePickerOpen) {
      resetCustomDatePicker();
    }

    setIsCustomDatePickerOpen(!isCustomDatePickerOpen);
  }, [isCustomDatePickerOpen, resetCustomDatePicker]);

  const handleCalendarOpen = React.useCallback(() => {
    setIsCalendarOpen(!isCalendarOpen);
  }, [isCalendarOpen]);

  const isAfterOrEqual = React.useCallback(
    (date, dateToCompare) => {
      const formattedDate = format(date, 'dd/MM/yyyy');
      const formattedDateToCompare = format(dateToCompare, 'dd/MM/yyyy');

      if (formattedDate === formattedDateToCompare) return true;

      return isAfter(date, dateToCompare);
    },
    [isAfter, format],
  );

  const isBeforeOrEqual = React.useCallback(
    (date, dateToCompare) => {
      const formattedDate = format(date, 'dd/MM/yyyy');
      const formattedDateToCompare = format(dateToCompare, 'dd/MM/yyyy');

      if (formattedDate === formattedDateToCompare) return true;

      return isBefore(date, dateToCompare);
    },
    [isBefore, format],
  );

  const handlePreDefinedDates = React.useCallback(
    date => {
      let isStartDateValid = true;
      let isEndDateValid = true;

      if (date === EPreDefinedDates.TODAY) {
        isStartDateValid = minDate ? isAfterOrEqual(utcToZonedTime(new Date()), minDate) : true;
        isEndDateValid = maxDate ? isBeforeOrEqual(utcToZonedTime(new Date()), maxDate) : true;

        if (isStartDateValid && isEndDateValid) {
          setStartDate(utcToZonedTime(new Date()));
          setEndDate(utcToZonedTime(new Date()));
        }
      }

      if (date === EPreDefinedDates.YESTERDAY) {
        isStartDateValid = minDate
          ? isAfterOrEqual(subDays(utcToZonedTime(new Date()), 1), minDate)
          : true;
        isEndDateValid = maxDate
          ? isBeforeOrEqual(subDays(utcToZonedTime(new Date()), 1), maxDate)
          : true;

        if (isStartDateValid && isEndDateValid) {
          setStartDate(subDays(utcToZonedTime(new Date()), 1));
          setEndDate(subDays(utcToZonedTime(new Date()), 1));
        }
      }

      if (date === EPreDefinedDates.LAST_SEVEN_DAYS) {
        if (excludeTodayInLastSevenDays) {
          isStartDateValid = minDate
            ? isAfterOrEqual(subDays(utcToZonedTime(new Date()), 7), minDate)
            : true;
          isEndDateValid = maxDate
            ? isBeforeOrEqual(subDays(utcToZonedTime(new Date()), 1), maxDate)
            : true;

          if (isStartDateValid && isEndDateValid) {
            setStartDate(subDays(utcToZonedTime(new Date()), 7));
            setEndDate(subDays(utcToZonedTime(new Date()), 1));
          }
        } else {
          isStartDateValid = minDate
            ? isAfterOrEqual(subDays(utcToZonedTime(new Date()), 6), minDate)
            : true;
          isEndDateValid = maxDate ? isBeforeOrEqual(utcToZonedTime(new Date()), maxDate) : true;

          if (isStartDateValid && isEndDateValid) {
            setStartDate(subDays(utcToZonedTime(new Date()), 6));
            setEndDate(utcToZonedTime(new Date()));
          }
        }
      }

      if (date === EPreDefinedDates.THIS_MONTH) {
        const today = utcToZonedTime(new Date());
        const firstDayOfTheMonth = utcToZonedTime(
          new Date(today.getFullYear(), today.getMonth(), 1),
        );

        isStartDateValid = minDate ? isAfterOrEqual(firstDayOfTheMonth, minDate) : true;
        isEndDateValid = maxDate ? isBeforeOrEqual(today, maxDate) : true;

        if (isStartDateValid && isEndDateValid) {
          setStartDate(firstDayOfTheMonth);
          setEndDate(today);
        }
      }

      resetCustomDatePicker();
    },
    [
      subDays,
      utcToZonedTime,
      resetCustomDatePicker,
      isAfterOrEqual,
      isBeforeOrEqual,
      maxDate,
      minDate,
      excludeTodayInLastSevenDays,
    ],
  );

  const handleCustomDate = React.useCallback(
    dates => {
      const [start, end] = dates;

      setCustomStartDate(start);
      setCustomEndDate(end);

      if (end) {
        setStartDate(start);
        setEndDate(end);
        handleCustomDatePickerOpen();
      }
    },
    [handleCustomDatePickerOpen],
  );

  React.useEffect(() => {
    const today = utcToZonedTime(new Date());
    const formattedToday = format(today, 'yyyy-MM-dd');
    const formattedYesterday = format(subDays(utcToZonedTime(new Date()), 1), 'yyyy-MM-dd');
    const formattedLastSevenDays = format(subDays(utcToZonedTime(new Date()), 6), 'yyyy-MM-dd');
    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');
    const formattedFirstDayOfMonth = format(
      utcToZonedTime(new Date(today.getFullYear(), today.getMonth(), 1)),
      'yyyy-MM-dd',
    );

    if (formattedStartDate === formattedToday && formattedEndDate === formattedToday) {
      setSelectedDate(EPreDefinedDates.TODAY);
    } else if (
      formattedStartDate === formattedYesterday &&
      formattedEndDate === formattedYesterday
    ) {
      setSelectedDate(EPreDefinedDates.YESTERDAY);
    } else if (
      formattedStartDate === formattedLastSevenDays &&
      formattedEndDate === formattedToday
    ) {
      setSelectedDate(EPreDefinedDates.LAST_SEVEN_DAYS);
    } else if (
      formattedStartDate === formattedFirstDayOfMonth &&
      formattedEndDate === formattedToday
    ) {
      setSelectedDate(EPreDefinedDates.THIS_MONTH);
    } else {
      setSelectedDate(EPreDefinedDates.CUSTOM);
    }
  }, [startDate, endDate, format, utcToZonedTime, subDays]);

  React.useEffect(() => {
    if (startDate) setCustomStartDate(startDate);

    if (endDate) setCustomEndDate(endDate);

    if (startDate && endDate) {
      const formattedPeriodStartDate = format(period.startDate, 'yyyy-MM-dd');
      const formattedPeriodEndDate = format(period.endDate, 'yyyy-MM-dd');
      const formattedStartDate = format(startDate, 'yyyy-MM-dd');
      const formattedEndDate = format(endDate, 'yyyy-MM-dd');

      if (
        formattedPeriodStartDate !== formattedStartDate ||
        formattedPeriodEndDate !== formattedEndDate
      ) {
        setPeriod({ startDate, endDate });
      }
    }
  }, [startDate, endDate, setPeriod, period, format]);

  return (
    <DatePickerRangeContext.Provider
      value={{
        startDate,
        endDate,
        isCalendarOpen,
        handleCalendarOpen,
        selectedDate,
        handlePreDefinedDates,
        handleCustomDatePickerOpen,
        isCustomDatePickerOpen,
        customEndDate,
        customStartDate,
        handleCustomDate,
        maxDate,
        minDate,
      }}
    >
      {children}
    </DatePickerRangeContext.Provider>
  );
};

export const useDatePickerRange = (): IDatePickerRangeProvider => {
  const context = React.useContext(DatePickerRangeContext);

  if (!context) {
    throw new Error('useDateRangePicker must be used within DateRangePickerProvider');
  }

  return context;
};
