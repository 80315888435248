import React from 'react';

import { ETextSize } from '@domain/enums/components/EText';

import { useFeedCustomValueBillings } from '@helpers/hooks/pages/dashboard/feed/useCustomValueBillings';

import CustomValueBillingRow from './CustomValueBillingRow';
import Footer from './Footer';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const CustomValueBillings: React.FC = () => {
  const {
    customValueBillings,
    isLoadingCustomValueBillings,
    isValidatingCustomValueBillings,
  } = useFeedCustomValueBillings();

  const isLoading =
    !customValueBillings?.length && isLoadingCustomValueBillings && isValidatingCustomValueBillings;

  if (
    !isLoadingCustomValueBillings &&
    !isValidatingCustomValueBillings &&
    !customValueBillings.length
  ) {
    return <></>;
  }

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>Existem algumas despesas pendentes </S.Title>

        <S.Description size={ETextSize.B5}>
          Algumas despesas foram agendadas para serem pagas nos próximos dias, aqui estão elas caso
          deseje sinalizar que ja foram pagas.
        </S.Description>
      </S.Header>

      <S.CustomValueBillingWrapper>
        {isLoading && <SkeletonLoading />}

        {customValueBillings?.map(customValueBilling => {
          return (
            <CustomValueBillingRow
              key={customValueBilling.id}
              customValueBilling={customValueBilling}
            />
          );
        })}
      </S.CustomValueBillingWrapper>

      {!isLoading && <Footer />}
    </S.Wrapper>
  );
};

export default CustomValueBillings;
