import React from 'react';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { ISaveProps } from '@domain/interfaces/dashboard/IntegrationCenter/SideModalContent/ISave';

import * as S from './styles';

const Save: React.FC<ISaveProps> = ({
  submitText,
  cancelText,
  deleteText,
  onDelete,
  hasCredentials = false,
  isSubmitting,
  isDeleting,
  isDeleteButtonDisabled,
  isSaveButtonDisabled,
  toggle,
  submitButtonOnClick,
  submitButtonType = 'submit',
}) => {
  return (
    <S.Background>
      <S.SaveWrapper>
        {cancelText && (
          <S.CancelButton onClick={toggle} disabled={isSaveButtonDisabled}>
            {cancelText}
          </S.CancelButton>
        )}

        {hasCredentials && onDelete && (
          <S.DeleteButton
            onClick={onDelete}
            variant={EButtonVariant.DANGER}
            isLoading={isDeleting}
            disabled={isDeleteButtonDisabled}
          >
            {deleteText}
          </S.DeleteButton>
        )}

        {submitText && (
          <S.ConfirmButton
            type={submitButtonType}
            isLoading={isSubmitting}
            disabled={isSaveButtonDisabled}
            onClick={submitButtonOnClick}
          >
            {submitText}
          </S.ConfirmButton>
        )}
      </S.SaveWrapper>
    </S.Background>
  );
};

export default Save;
