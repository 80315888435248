import React from 'react';

import { useTheme } from 'styled-components/macro';

import { getParsedPaymentType, getPaymentType } from '@helpers/dashboard/orders/ordersUtils';
import { EBadgeColors } from '@domain/enums/components/EBadge';
import { ETextWeight } from '@domain/enums/components/EText';
import { numberFormatter, currency as getCurrency } from '@helpers/masks';
import { IOrderTableDataProps } from '@domain/interfaces/dashboard/Orders/IOrderTableData';

import Table from '@components/common/core/Utils/Table';

import * as S from './styles';

const NetRevenue: React.FC<IOrderTableDataProps> = ({ order }) => {
  const theme = useTheme();

  const paymentType = getPaymentType(order.type, theme);
  const parsedPaymentType = getParsedPaymentType(order.type);

  const revenueCurrency = getCurrency(order.currency);
  const formattedGatewayAmount = numberFormatter(order.gateway_amount, 2);

  const revenueValue = `${revenueCurrency} ${formattedGatewayAmount}`;

  return (
    <Table.Data>
      <S.RevenueWrapper>
        <S.RevenueValueNeutro weight={ETextWeight.MEDIUM}>{revenueValue}</S.RevenueValueNeutro>

        <S.RevenueType>
          <S.RevenueTypeBadge color={EBadgeColors.GRAY}>
            {paymentType}
            <S.RevenueTypeText weight={ETextWeight.BOLD}>{parsedPaymentType}</S.RevenueTypeText>
          </S.RevenueTypeBadge>
        </S.RevenueType>
      </S.RevenueWrapper>
    </Table.Data>
  );
};

export default NetRevenue;
