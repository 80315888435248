import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';

export const Modal = styled(ModalBase)`
  width: 100%;
  max-width: 1018px;
  box-sizing: border-box;
  max-height: 492px;

  @media only screen and (max-width: 955px) {
    max-height: unset;
    max-width: 440px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: unset;
  padding: unset;
  border: unset;
  cursor: pointer;
`;
