import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import LabelBase from '@components/common/core/DataDisplay/Label';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';

export const InputGroup = styled(InputGroupBase)``;

export const AppendContent = styled.div`
  display: block;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray[3]};
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0px 16px;
  background-color: ${({ theme }) => theme.colors.background.input};
  border-radius: 0 5px 5px 0;
`;

export const InputWrapper = styled.div`
  display: flex;
`;

export const TextField = styled(TextFieldBase)`
  width: 100%;

  & > div {
    border-radius: 5px 0 0 5px;
  }
`;

export const Label = styled(LabelBase)`
  font-weight: 700;
`;

export const TutorialText = styled(TextBase)``;

export const TutorialHeading = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const TutorialWrapper = styled.div`
  margin-bottom: 40px;
`;

export const FunctionalityDescriptionText = styled(TextBase)``;

export const FunctionalityDescriptionHeading = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const FunctionalityDescriptionWrapper = styled.div`
  margin-bottom: 48px;
`;

export const Heading = styled(HeadingBase)`
  margin-left: 10px;
`;

export const Header = styled.div`
  margin-bottom: 40px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
`;

export const ContentWrapper = styled.div`
  max-width: 487px;
  width: 100%;
`;
