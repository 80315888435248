import { ApiService } from '@services/api';

import { useFetch } from '@providers/Fetch/useFetch';

import { API_DOMAIN } from '@constants/api';

import {
  ICreateGroupPromiseProps,
  ICreateGroupPromiseResponse,
  IDeleteGroupPromiseProps,
  IGetGroupProps,
  IGetGroupResponse,
  IListGroupsProps,
  IListGroupsResponse,
  IUpdateGroupsPromiseProps,
  IUpdateGroupsPromiseResponse,
} from '@domain/interfaces/services/group/IGroup';
import { AxiosResponse } from 'axios';

export class GroupService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listGroups({ storeAliasId }: IListGroupsProps): IListGroupsResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/groups`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      groups: data?.groups,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public getGroup({ storeAliasId, groupAliasId }: IGetGroupProps): IGetGroupResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/groups/${groupAliasId}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      group: data?.group,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public createGroupPromise({
    storeAliasId,
    data,
  }: ICreateGroupPromiseProps): Promise<AxiosResponse<ICreateGroupPromiseResponse>> {
    return this.apiService.post<ICreateGroupPromiseResponse>(
      `/api/v1/users/stores/${storeAliasId}/groups`,
      // { ...data, has_workflow_full_write_access: false },
      { ...data },
    );
  }

  public updateGroupsPromise({
    storeAliasId,
    data,
  }: IUpdateGroupsPromiseProps): Promise<AxiosResponse<IUpdateGroupsPromiseResponse>> {
    return this.apiService.put<IUpdateGroupsPromiseResponse>(
      `/api/v1/users/stores/${storeAliasId}/groups`,
      { ...data },
    );
  }

  public deleteGroupPromise({
    storeAliasId,
    data,
  }: IDeleteGroupPromiseProps): Promise<AxiosResponse<void>> {
    return this.apiService.delete<void>(`/api/v1/users/stores/${storeAliasId}/groups`, { ...data });
  }
}

const groupService = new GroupService(API_DOMAIN);

export default groupService;
