import React from 'react';
// import { LineChart } from '@profitfy/pakkun-icons';
// import { useTheme } from 'styled-components/macro';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { ICpa } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IAdSenseCampaign';

import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const Cpa: React.FC<ICampaignProps> = ({ data }) => {
  const cpaData: ICpa = data;

  // const theme = useTheme();

  return (
    <S.Wrapper>
      <S.CpaValue>{`R$ ${numberFormatter(cpaData.amount, 2)}`}</S.CpaValue>
      {/* <S.ForecastText>
        <LineChart outline size={12} color={theme.colors.gray[2]} />
        R$ 294,28
      </S.ForecastText> */}
    </S.Wrapper>
  );
};

export default Cpa;
