import valid from 'card-validator';

export const getCardIssuer = (number: string): string | undefined => {
  const validation = valid.number(number);

  return validation.card?.niceType;
};

export const isCardNumberValid = (number: string): string | undefined => {
  const validation = valid.number(number);

  if (!validation.isValid) return 'Insira um cartão válido.';

  const card = validation?.card;

  if (
    card?.niceType &&
    card.niceType !== 'Visa' &&
    card.niceType !== 'Mastercard' &&
    card.niceType !== 'Elo' &&
    card.niceType !== 'Diners Club' &&
    card.niceType !== 'American Express'
  )
    return 'Esse cartão não é suportado! Por favor, tente outro.';

  return undefined;
};

export const isExpirationDateValid = (date: string): string | undefined => {
  const validation = valid.expirationDate(date);

  if (!validation.isValid) return 'Insira uma data de vencimento válida.';

  return undefined;
};

export const isCvvValid = (cvv: string): string | undefined => {
  if (cvv.length < 3) return 'Insira um CVV / CID válido';

  const validation = valid.cvv(cvv, 4);

  if (!validation.isPotentiallyValid) return 'Insira um CVV / CID válido.';

  return undefined;
};
