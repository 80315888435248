import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import {
  IGenerateURLProps,
  IGetShopifyCredentialProps,
  IGetShopifyCredentialResponse,
  ICreateCredentialProps,
  IUpdateCredentialProps,
} from '@domain/interfaces/dashboard/IntegrationCenter/Marketplace/Shopify/IShopifyAuth';
import { API_DOMAIN } from '@constants/api';
import { AxiosResponse } from 'axios';

export class ShopifyService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getShopifyCredential({
    storeAliasId,
  }: IGetShopifyCredentialProps): IGetShopifyCredentialResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/shopify-credentials`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      shopifyCredential: data?.shopify_credential,
      error,
      isLoading,
      isValidating,
      mutate,
    };
  }

  public getShopifyCredentialPromise({ storeAliasId }: IGetShopifyCredentialProps): Promise<any> {
    return this.apiService.get(`/api/v1/users/stores/${storeAliasId}/shopify-credentials`);
  }

  public createCredential({ storeAliasId, data }: ICreateCredentialProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/shopify-credentials/custom-app`,
      { ...data },
    );
  }

  public updateCredential({ storeAliasId, data }: IUpdateCredentialProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/shopify-credentials/custom-app`,
      { ...data },
    );
  }

  public deleteCredential(storeAliasId: string | undefined): Promise<AxiosResponse<void>> {
    return this.apiService.delete<void>(`/api/v1/users/stores/${storeAliasId}/shopify-credentials`);
  }

  public generateURL({ storeAliasId, data }: IGenerateURLProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/shopify/auth/generate-url`, {
      ...data,
    });
  }

  public disableCredential(storeAliasId?: string): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/shopify-credentials/disable`);
  }
}

const shopifyService = new ShopifyService(API_DOMAIN);

export default shopifyService;
