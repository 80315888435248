import React from 'react';

import { EUserStoreRole } from '@domain/enums/common/userStore/EUserStoreRole';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import TikTokIntegration from '@components/Dashboard/IntegrationCenter/DropshippingWrapper/AdsProvider/TikTok';
import NoAccess from '@components/common/core/Utils/NoAccess';

const TikTok: React.FC = () => {
  const { accessManagementData } = useStoreConfig();
  const { isInternalTeam } = useConfig();

  const isStoreOwner = accessManagementData?.role === EUserStoreRole.OWNER;
  const hasAccessToPage =
    accessManagementData?.group?.has_integrations_full_write_access ||
    accessManagementData?.group?.has_integrations_full_read_access;

  if (!isStoreOwner && !hasAccessToPage && !isInternalTeam) {
    return <NoAccess />;
  }

  return <TikTokIntegration />;
};

export default TikTok;
