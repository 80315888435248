import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';
import InputBase from '@components/common/core/Inputs/TextField';

export const Wrapper = styled.div`
  max-width: 1024px;
  width: 100%;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 1165px) {
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ManagerButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  justify-content: flex-end;

  @media only screen and (max-width: 1165px) {
    justify-content: flex-start;
  }
`;

export const FilterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 1165px) {
    margin-top: 20px;
  }
`;

export const Spacer = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const Title = styled(HeadingBase)`
  font-size: 1.5rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white.default};
  width: 100%;

  @media only screen and (max-width: 1165px) {
    margin-bottom: 20px;
  }
`;

export const SummaryCardsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 1165px) {
    flex-direction: column;
    justify-content: unset;
    gap: 12px;
  }
`;

export const Group = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  justify-content: flex-end;
`;

export const ManageMessagesButton = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.colors.background[2]};
  padding: 2px 16px 2px 16px;
  box-shadow: unset;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background[2]};
    box-shadow: unset;
  }

  & > span {
    color: ${({ theme }) => theme.colors.white.default};
  }
`;

export const NewAutomationButton = styled(ButtonBase)`
  padding: 2px 16px 2px 16px;
`;

export const SubTitle = styled(HeadingBase)`
  font-size: 1.25rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white.default};
`;

export const Deletar = styled.div`
  width: 85px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.background[2]};
`;

export const TextInput = styled(InputBase)`
  width: 100%;
  max-width: 323px;
`;

export const TableWrapper = styled.div`
  border-radius: 12px;
  overflow: hidden;
  margin-top: 16px;

  & > div {
    padding-top: 42px;

    ::-webkit-scrollbar {
      width: 4px;
      position: absolute;
    }

    ::-webkit-scrollbar:horizontal {
      height: 4px;
      width: 4px;
      position: absolute;
    }

    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.gray[4]};
      position: absolute;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.gray[1]};
      border-radius: 25px;
      transition: all 0.3s;
    }

    ::-webkit-scrollbar-thumb:horizontal {
      height: 1px;
      background-color: ${({ theme }) => theme.colors.gray[1]};
      border-radius: 25px;
      transition: all 0.3s;
    }

    ::-webkit-scrollbar-thumb:hover {
      opacity: brightness(0.8);
    }

    & > table {
      position: relative;
      border-collapse: collapse;
    }

    & > table > thead {
      display: block;
      margin-top: -42px;
      position: sticky;
      height: 42px;
      top: -42px;
      z-index: ${({ theme }) => theme.zIndex.firstLayer};
    }

    & > table > thead > tr {
      & > th {
        border: unset;
        padding: 10px 12px !important;
        box-sizing: border-box;
      }

      & > th:nth-child(1) {
        min-width: 76px;
        max-width: 76px;
        width: 76px;
        padding-left: 10px !important;
        padding-right: 0 !important;
      }

      & > th:nth-child(2) {
        min-width: 212px;
        max-width: 212px;
        width: 212px;
      }

      & > th:nth-child(3) {
        min-width: 108px;
        max-width: 108px;
        width: 108px;
      }

      & > th:nth-child(4) {
        min-width: 132px;
        max-width: 132px;
        width: 132px;
      }

      & > th:nth-child(5) {
        min-width: 150px;
        max-width: 150px;
        width: 150px;
        display: inline-block !important;
      }

      & > th:nth-child(6) {
        min-width: 180px;
        max-width: 180px;
        width: 180px;

        display: inline-block !important;
      }

      & > th:nth-child(7) {
        min-width: 76px;
        max-width: 76px;
        width: 76px;
      }

      & > th:nth-child(8) {
        min-width: 88px;
        max-width: 88px;
        width: 88px;
      }
    }

    & > table > tbody > tr {
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray[4]};
      :hover {
        background-color: ${({ theme }) => theme.colors.black.default};
      }

      & > td {
        border: unset;
        height: 60px !important;
        padding: 8px 12px !important;
        box-sizing: border-box;
      }

      & > td:nth-child(1) {
        min-width: 76px;
        width: 76px;
        max-width: 76px;
        padding-left: 20px !important;
        padding-right: 0 !important;
      }

      & > td:nth-child(2) {
        min-width: 212px;
        max-width: 212px;
        width: 212px;
      }

      & > td:nth-child(3) {
        min-width: 108px;
        max-width: 108px;
        width: 108px;
      }

      & > td:nth-child(4) {
        min-width: 132px;
        max-width: 132px;
        width: 132px;
      }

      & > td:nth-child(5) {
        min-width: 150px;
        max-width: 150px;
        width: 150px;
      }

      & > td:nth-child(6) {
        min-width: 180px;
        max-width: 180px;
        width: 180px;
      }

      & > td:nth-child(7) {
        min-width: 76px;
        max-width: 76px;
        width: 76px;
      }

      & > td:nth-child(8) {
        min-width: 88px;
        max-width: 88px;
        width: 88px;
      }
    }
  }
`;
