import React from 'react';

import { useAddress } from '@helpers/hooks/common/store/address/useAddress';
import { usePersonalInfo } from '@helpers/hooks/common/store/personalInfo/usePersonalInfo';
import { usePayment } from '@helpers/hooks/common/store/payment/usePayment';
import { useConfig } from '@helpers/hooks/useConfig';

import LoadingScreen from '@components/common/core/Utils/LoadingScreen';
import PageError from '@components/common/core/Utils/PageError';
import Overview from '../Overview';
import Content from '../Content';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const { isLoadingAddress, isValidatingAddress, addressError, mutateAddress } = useAddress();
  const { analytics, user, selectedStore } = useConfig();
  const {
    isLoadingLegalPerson,
    isLoadingNaturalPerson,
    isValidatingLegalPerson,
    isValidatingNaturalPerson,
    legalPersonError,
    naturalPersonError,
    mutateLegalPerson,
    mutateNaturalPerson,
  } = usePersonalInfo();
  const {
    isLoadingSubscription,
    isValidatingSubscription,
    subscriptionError,
    mutateSubscription,
    isLoadingPlans,
    isValidatingPlans,
    mutatePlans,
    plansError,
  } = usePayment();

  const mutate = React.useCallback(async () => {
    const promises = [];

    if (addressError) promises.push(mutateAddress());

    if (legalPersonError) promises.push(mutateLegalPerson());

    if (naturalPersonError) promises.push(mutateNaturalPerson());

    if (subscriptionError) promises.push(mutateSubscription());

    if (plansError) promises.push(mutatePlans());

    await Promise.all(promises);
  }, [
    addressError,
    mutateAddress,
    legalPersonError,
    mutateLegalPerson,
    naturalPersonError,
    mutateNaturalPerson,
    subscriptionError,
    mutateSubscription,
    plansError,
    mutatePlans,
  ]);

  React.useEffect(() => {
    analytics?.track(
      'Subscription Personal Data Added',
      {
        email: user?.email,
      },
      { context: { groupId: selectedStore?.alias_id } },
    );
  }, [analytics, selectedStore, user]);

  if (
    isLoadingAddress ||
    isLoadingLegalPerson ||
    isLoadingNaturalPerson ||
    isLoadingSubscription ||
    isLoadingPlans
    // isVerifyingCouponId
  ) {
    return (
      <S.Wrapper>
        <LoadingScreen />
      </S.Wrapper>
    );
  }

  if (
    (addressError && !isValidatingAddress) ||
    (legalPersonError && !isValidatingLegalPerson) ||
    (naturalPersonError && !isValidatingNaturalPerson) ||
    (subscriptionError && !isValidatingSubscription) ||
    (plansError && !isValidatingPlans)
  ) {
    return (
      <S.ErrorWrapper>
        <PageError mutate={mutate} />
      </S.ErrorWrapper>
    );
  }

  return (
    <S.Wrapper>
      <Overview />

      <Content />
    </S.Wrapper>
  );
};

export default Wrapper;
