import React from 'react';

import NavigationTab from '@components/Dashboard/FinancialData/NavigationTab';
import FinancialDataForm from '@components/Dashboard/FinancialData/FinancialDataForm';
import { FinancialDataProvider } from '@helpers/hooks/pages/dashboard/financialData/useFinancialData';

import * as S from './styles';

const FinancialData: React.FC = () => {
  const [selectedTab, setSelectedTab] = React.useState<string>('financial');

  const handleTabSelect = React.useCallback((userSelectedTab: string) => {
    setSelectedTab(userSelectedTab);
  }, []);

  return (
    <S.Container>
      <NavigationTab handleTabSelect={handleTabSelect} selectedTab={selectedTab} />

      <FinancialDataProvider>
        <FinancialDataForm />
      </FinancialDataProvider>
    </S.Container>
  );
};

export default FinancialData;
