import React from 'react';

import { ILabelProps } from '@domain/interfaces/components/ILabel';

import * as S from './styles';

const Label: React.FC<ILabelProps> = ({ children, htmlFor, ...rest }) => {
  return (
    <S.Label htmlFor={htmlFor} {...rest}>
      {children}
    </S.Label>
  );
};

export default Label;
