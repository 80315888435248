import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import ButtonBase from '@components/common/core/Inputs/Button';

export const SeePlansButton = styled(ButtonBase)`
  padding: 12px 16px;

  & > span {
    font-size: 0.875rem;
  }
`;

export const UpdateCredentialsButton = styled(ButtonBase)`
  padding: 12px 16px;

  & > span {
    font-size: 0.875rem;
    font-weight: 500;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
`;

export const SelectedProfilesText = styled(TextBase)`
  width: 100%;
  text-align: right;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[3]};
`;

export const AdSenseCredentialsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background[2]};
  border-radius: 5px;
  overflow-x: hidden;
  max-height: 256px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 4px;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.gray[4]};
    position: absolute;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[1]};
    border-radius: 25px;
    transition: all 0.3s;
  }

  ::-webkit-scrollbar-thumb:hover {
    opacity: brightness(0.8);
  }
`;

export const SearchInput = styled(TextFieldBase)``;

export const SearchInputAndAdSenseCredentialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Description = styled(TextBase)`
  line-height: 150%;
`;

export const Title = styled(TextBase)`
  font-size: 1.125rem;
`;

export const FacebookModal = styled(ModalBase)`
  max-width: 464px;
  width: 100%;
  box-sizing: border-box;

  & > div:first-child > span {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  & > div {
    max-width: unset;
    width: 100%;
  }
`;
