import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';

export const Tooltip = styled(CustomTooltipBase)``;

export const ValueText = styled(TextBase)`
  font-size: 0.875rem;
`;

export const FeeAmount = styled(TextBase)`
  font-weight: 700;
`;

export const DescriptionText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
  font-size: 0.875rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;
