import React from 'react';
import { useTheme } from 'styled-components/macro';

import { IButtonWithIconProps } from '@domain/interfaces/components/IButtonWithIcon';

import * as S from './styles';

const ButtonWithIcon: React.FC<IButtonWithIconProps> = ({ onClick, children, icon: Icon }) => {
  const theme = useTheme();

  return (
    <S.Button onClick={onClick}>
      <S.IconWrapper>
        <Icon size={14} color={theme.colors.font} />
      </S.IconWrapper>

      <S.Text>{children}</S.Text>
    </S.Button>
  );
};

export default ButtonWithIcon;
