import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background-color: #25d366;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Name = styled(TextBase)`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white.dark};
`;
