import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import SelectBase from '@components/common/core/Inputs/Select/index';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import DayPickerBase from '@components/common/core/Utils/DayPicker';
import LabelBase from '@components/common/core/DataDisplay/Label';

export const Wrapper = styled.div`
  max-width: 768px;
  width: 100%;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 24px;
`;

export const Container = styled.div`
  width: 100%;
  background-color: #24262f;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

export const FormTitle = styled(HeadingBase)`
  font-size: 1.25rem;
  margin: 24px 0;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 247px;
  width: 100%;
`;

export const Label = styled(LabelBase)`
  font-size: 0.875rem;
  font-weight: 400;
  color: #d9dde9;
  margin-bottom: 0;
`;

export const Input = styled(TextFieldBase)``;

export const Select = styled(SelectBase)`
  max-width: 247px;
  width: 100%;

  & > div {
    max-width: 247px;
    width: 100%;
  }

  & > label > select {
    max-width: 247px;
    width: 100%;
  }
`;

export const Option = styled(OptionBase)``;

export const DayPicker = styled(DayPickerBase)`
  width: 100%;

  & > div {
    margin-bottom: 0;
  }
`;

export const SaveButton = styled.button`
  border: 0;
  border-radius: 5px;
  background-color: #00f2a1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Mukta';
  font-weight: 700;
  font-size: 0.875rem;
  height: 42px;
  padding: 12px 22px;
  color: ${({ theme }) => theme.colors.black.default};
  transition: all 0.3s;
  align-self: flex-end;

  &:hover {
    cursor: pointer;
  }
`;
