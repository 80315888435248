import * as yup from 'yup';

export const productCustomValueSchema = yup.object().shape({
  currency: yup.string().required('Este campo é obrigatório.'),
  amount: yup.string().required('Este campo é obrigatório.'),
  description: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'É necessário informar pelo menos dois caractéres.'),
});

export const customValuesSchema = yup.object().shape({
  currency: yup.string().required('Este campo é obrigatório.'),
  amount: yup.string().required('Este campo é obrigatório.'),
  category: yup.string().required('Este campo é obrigatório.'),
  description: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'É necessário informar pelo menos dois caractéres.'),
  period: yup.string().required('Este campo é obrigatório.'),
});

export const updateCustomValueSchema = yup.object().shape({
  currency: yup.string().required('Este campo é obrigatório.'),
  amount: yup.string().required('Este campo é obrigatório.'),
  description: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'É necessário informar pelo menos dois caractéres.'),
  period: yup.string().required('Este campo é obrigatório.'),
});
