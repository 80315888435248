import styled, { keyframes } from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import RadioBase from '@components/common/core/Inputs/Radio';
import AlertBase from '@components/common/core/DataDisplay/Alert';
import LabelBase from '@components/common/core/DataDisplay/Label';

const appearFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray[7]};
  display: flex;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: calc(842px + 64px);
  width: 100%;
  padding: 142px 32px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media only screen and (max-width: 1028px) {
    max-width: calc(620px + 64px);
  }
`;

export const Heading = styled(HeadingBase)`
  font-size: 1.75rem;
  margin-bottom: 24px;
`;

export const BenefitsWrapper = styled.div`
  animation: ${appearFromBottom} 700ms;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 40px;
`;

export const Benefit = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const BenefitText = styled(TextBase)``;

export const RadioGroup = styled(InputGroupBase)`
  animation: ${appearFromBottom} 700ms;
  margin-bottom: 24px;
`;

export const RadioGroupLabel = styled(LabelBase)`
  font-size: 1rem;
  margin-bottom: 16px;
`;

export const Label = styled(LabelBase)`
  /* font-family: 'Poppins'; */
  font-family: 'Saira';
`;

export const RadioLabel = styled(LabelBase)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  cursor: pointer;
`;

export const RadioText = styled(TextBase)``;

export const Radio = styled(RadioBase)``;

export const InputGroup = styled(InputGroupBase)`
  animation: ${appearFromBottom} 700ms;
`;

export const Input = styled(TextFieldBase)`
  max-width: 361px;

  & > div > span {
    color: ${({ theme }) => theme.colors.green.default};
  }
`;

export const Alert = styled(AlertBase)`
  animation: ${appearFromBottom} 700ms;
  width: unset;
  max-width: 497px;
  margin-bottom: 22px;

  & > div > span:first-of-type {
    color: #fff;
    font-weight: 700;
    line-height: 120%;
  }
`;
