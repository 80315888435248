import React from 'react';
import { useTheme } from 'styled-components/macro';
import { Cancel, ChevronDown } from '@profitfy/pakkun-icons';

import { EUserInviteStatus } from '@domain/enums/common/userInvite/EUserInviteStatus';

import { useAccessManager } from '@helpers/hooks/pages/dashboard/accessManagement/useAccessManager';
import { useToast } from '@helpers/hooks/useToast';

import PendingInvitationRow from './PendingInvitationRow';

import * as S from './styles';

const PendingInvitationsTable: React.FC = () => {
  const {
    userInvites,
    handleDeletePendingInviteModalOpen,
    selectedPendingInvitationsId,
    handleSelectedPendingInvitationsId,
  } = useAccessManager();
  const { toast } = useToast();
  const theme = useTheme();

  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);

  const handlePopoverOpen = React.useCallback(() => {
    setIsPopoverOpen(!isPopoverOpen);
  }, [isPopoverOpen]);

  const onDeleteInviteButtonClick = React.useCallback(() => {
    if (selectedPendingInvitationsId.length) {
      handleDeletePendingInviteModalOpen();
      handlePopoverOpen();
    } else {
      toast.error('Selecione pelo menos um convite.');
    }
  }, [handleDeletePendingInviteModalOpen, handlePopoverOpen, toast, selectedPendingInvitationsId]);

  const handleCheckAllInvitesChange = React.useCallback(
    event => {
      if (!event || event.target) return;

      const filteredPendingUserInvites = userInvites.filter(
        userInvite => userInvite.status === EUserInviteStatus.PENDING,
      );

      if (event.checked) {
        const mappedInvitesId = filteredPendingUserInvites.map(filteredInvite => filteredInvite.id);

        handleSelectedPendingInvitationsId(mappedInvitesId);
      } else {
        handleSelectedPendingInvitationsId([]);
      }
    },
    [handleSelectedPendingInvitationsId, userInvites],
  );

  const filteredPendingUserInvites = userInvites.filter(
    userInvite => userInvite.status === EUserInviteStatus.PENDING,
  );

  const hasSelectedAllInvites =
    filteredPendingUserInvites.length > 0 &&
    filteredPendingUserInvites.length === selectedPendingInvitationsId.length;

  return (
    <S.Wrapper>
      <S.Header>
        <S.Heading>Convites pendentes</S.Heading>

        <S.ButtonsWrapper>
          <S.CustomPopover open={isPopoverOpen} onOpenChange={handlePopoverOpen}>
            <S.ButtonTrigger>
              Ações
              <ChevronDown size={16} color={theme.colors.font} />
            </S.ButtonTrigger>

            <S.CustomPopoverContent side="bottom" sideOffset={4}>
              <S.DeleteSelectedGroupsButton onClick={onDeleteInviteButtonClick}>
                <Cancel outline size={16} color={theme.colors.danger.default} />
                Excluir selecionados
              </S.DeleteSelectedGroupsButton>
              <S.CustomPopoverArrow />
            </S.CustomPopoverContent>
          </S.CustomPopover>
        </S.ButtonsWrapper>
      </S.Header>

      <S.Table>
        <S.Table.Header>
          <S.Table.Row>
            <S.Table.Head>
              <S.CheckboxWrapper>
                <S.Checkbox
                  checked={hasSelectedAllInvites}
                  onChange={handleCheckAllInvitesChange}
                />
              </S.CheckboxWrapper>
            </S.Table.Head>
            <S.Table.Head>E-mail</S.Table.Head>
          </S.Table.Row>
        </S.Table.Header>

        <S.Table.Body>
          {filteredPendingUserInvites.map(userInvite => (
            <PendingInvitationRow key={userInvite.alias_id} userInvite={userInvite} />
          ))}
        </S.Table.Body>
      </S.Table>
    </S.Wrapper>
  );
};

export default PendingInvitationsTable;
