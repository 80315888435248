import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { EHeadAlign } from '@domain/enums/components/ETable';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  const randomArray = Array.from(Array(10).keys());

  return (
    <S.Table>
      <S.Table.Header>
        <S.Table.Row>
          <S.Table.Head />
          <S.Table.Head align={EHeadAlign.RIGHT}>R. Bruta</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>P. Pendentes</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>P. Análise</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>P. Não Conciliados</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Descontos</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Chargebacks</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Devoluções</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>R. Líquida</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>C. Produtos</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Marketing</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Taxas Checkout</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Taxas Gateway</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Despesas Envio</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Impostos</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Outras Despesas</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>R. Financeiras</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Lucro Líquido</S.Table.Head>
          <S.Table.Head />
        </S.Table.Row>
      </S.Table.Header>

      <S.Table.Body>
        {randomArray.map((value, index) => (
          <S.Table.Row key={value} borderBottom={index + 1 !== randomArray.length}>
            <S.Table.Data>
              <S.DateWrapper>
                <Skeleton width="100%" height={16} />
              </S.DateWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.DataTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.DataTextWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.DataTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.DataTextWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.DataTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.DataTextWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.DataTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.DataTextWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.DataTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.DataTextWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.DataTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.DataTextWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.DataTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.DataTextWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.DataTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.DataTextWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.DataTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.DataTextWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.DataTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.DataTextWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.DataTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.DataTextWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.DataTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.DataTextWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.DataTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.DataTextWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.DataTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.DataTextWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.DataTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.DataTextWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.DataTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.DataTextWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.DataTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.DataTextWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.PercentageWrapper>
                <Skeleton width="100%" height={16} />
              </S.PercentageWrapper>
            </S.Table.Data>
          </S.Table.Row>
        ))}
      </S.Table.Body>
    </S.Table>
  );
};

export default SkeletonLoading;
