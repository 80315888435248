import React from 'react';

import { sumFeeOrderAmount } from '@helpers/dashboard/orders/ordersUtils';
import { numberFormatter } from '@helpers/masks';
import Tooltip from '@components/common/core/Utils/Tooltip';
import { IFeeTooltipProps } from '@domain/interfaces/dashboard/Boleto/IBoleto';

import * as S from './styles';

const FeeTooltip: React.FC<IFeeTooltipProps> = ({ order, children }) => {
  const hasFeeAmount = sumFeeOrderAmount(order) > 0;
  const formattedGatewayFeeAmount = hasFeeAmount ? numberFormatter(order.gateway_fee_amount, 2) : 0;
  // const formattedTaxFeeAmount = hasFeeAmount ? numberFormatter(order.tax_amount, 2) : 0;
  const formattedMarketplaceFeeAmount = hasFeeAmount
    ? numberFormatter(order.marketplace_fee_amount, 2)
    : 0;
  const hasMarketplaceFee = order.marketplace_fee_amount > 0;
  const formattedCheckoutFeeAmount = hasFeeAmount
    ? numberFormatter(order.checkout_fee_amount, 2)
    : 0;

  return (
    <Tooltip
      content={
        <S.Wrapper>
          <S.TextWrapper>
            <S.DescriptionText>Taxas do Gateway: </S.DescriptionText>
            <S.ValueText>{formattedGatewayFeeAmount}</S.ValueText>
          </S.TextWrapper>

          <S.TextWrapper>
            <S.DescriptionText>Taxas do checkout / tarifas: </S.DescriptionText>
            <S.ValueText>{formattedCheckoutFeeAmount}</S.ValueText>
          </S.TextWrapper>

          {/* <S.TextWrapper>
            <S.DescriptionText>Impostos: </S.DescriptionText>
            <S.ValueText>{formattedTaxFeeAmount}</S.ValueText>
          </S.TextWrapper> */}

          {hasMarketplaceFee && (
            <S.TextWrapper>
              <S.DescriptionText>Taxas Marketplace: </S.DescriptionText>
              <S.ValueText>{formattedMarketplaceFeeAmount}</S.ValueText>
            </S.TextWrapper>
          )}
        </S.Wrapper>
      }
    >
      {children}
    </Tooltip>
  );
};

export default FeeTooltip;
