export const FAQ = [
  {
    title: 'Como funciona o plano gratuito?',
    description:
      'No plano Lite da Profitfy, você pode ter até 50 pedidos pagos por mês, sem pedidos extras, não existe nenhuma cobrança e não precisa cadastrar seu cartão de crédito. Com sua operação aumentando, você poderá realizar o upgrade de maneira fácil a partir da plataforma, ou entrar em contato com nossa equipe, para ajudar você na escolha certa. ',
  },
  {
    title: 'Como funcionam os pedidos extras',
    description:
      'Caso a você exceda a quantidade de pedidos disponível em seu plano, será cobrado um valor adicional a cada pedido excedente aprovado, sem precisar mudar de plano. Ou você pode fazer a mudança para um plano maior, com o limite de pedidos pagos mensais, maior.',
  },
  {
    title: 'Caso eu faça o upgrade de um plano para o outro o que acontece?',
    description:
      'Não tendo pedidos extras, é aplicado um desconto proporcional ao período pago não usufruído e então você paga o próximo plano com esse desconto. Na próxima cobrança, o valor do plano é o valor cheio.\n\nAgora, caso você tenha pedidos extras, você paga por esses pedidos e em seguida é adicionado um desconto proporcional ao período pago não usufruído e então o próximo plano vem com esse desconto. E então, na próxima cobrança o valor do plano será o valor cheio. ',
  },
  {
    title: 'Caso eu faça o downgrade de um plano para o outro o que acontece?',
    description:
      'Se você possuir pedidos extras, é cobrado o valor do pedido extra e em seguida é feito o downgrade. Agora, caso não tenha pedidos extras, você irá diretamente para o downgrade. Lembrando que sempre que se você vai para um plano inferior o número de pedidos no mês que seu plano comporta muda, assim como tudo que está incluso nele. ',
  },
  {
    title: 'Posso escolher um plano e adicionar mais lojas?',
    description: 'Não. Na Profitfy 2.0 cada loja tem o seu respectivo plano.',
  },
  {
    title: 'É possível mudar o dia de cobrança?',
    description: 'Infelizmente não é possível. O dia de cobrança será 30 dias após a compra ',
  },
  {
    title: 'O valor do plano é cobrado na hora que escolho, ou no final do período?',
    description: 'É cobrado na hora que você escolhe o seu plano.',
  },
];
