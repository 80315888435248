import styled from 'styled-components/macro';

import TextFieldBase from '@components/common/core/Inputs/TextField';
import CustomDropDownBase from '@components/common/core/Utils/CustomDropDown';
import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';

export const VariantsWrapper = styled.div``;

export const Popover = styled(CustomPopoverBase)``;

export const Dropdown = styled(CustomDropDownBase)``;

export const Input = styled(TextFieldBase)`
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 100%;

  & > button {
    width: 100%;
  }
`;
