import styled, { keyframes } from 'styled-components/macro';

const initialAnimation = keyframes`
  0% {
    bottom: 0;
    opacity: 0;
  }
  100% {
    bottom: 24px;
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.thirdLayer};
`;

export const BottomContainer = styled.div`
  width: 100%;
  max-width: 1160px;
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${({ theme }) => theme.zIndex.thirdLayer};
  animation: ${initialAnimation} 1s ease-in;
`;
