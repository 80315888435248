import React from 'react';

import { EUserInviteStatus } from '@domain/enums/common/userInvite/EUserInviteStatus';

import { useAccessManager } from '@helpers/hooks/pages/dashboard/accessManagement/useAccessManager';
import { useQuery } from '@helpers/hooks/useQuery';

import GroupTable from './GroupTable';
import MembersTable from './MembersTable';
import PendingInvitationsTable from './PendingInvitationsTable';
import InvitedModal from '../InvitedModal';

import * as S from './styles';

const Tables: React.FC = () => {
  const query = useQuery();
  const { userInvites } = useAccessManager();

  const templateQuery = query.get('has_invited');

  const [showInvitedModal, setShowInvitedModal] = React.useState<boolean>(false);

  const handleShowInvitedModal = React.useCallback(() => {
    setShowInvitedModal(state => !state);
  }, []);

  React.useEffect(() => {
    if (templateQuery === 'true') {
      setShowInvitedModal(true);
    }
  }, [templateQuery]);

  const filteredPendingInvites = userInvites.filter(
    userInvite => userInvite.status === EUserInviteStatus.PENDING,
  );

  return (
    <S.Wrapper>
      <GroupTable />

      <MembersTable />

      {Boolean(filteredPendingInvites.length) && <PendingInvitationsTable />}

      <InvitedModal isOpen={showInvitedModal} toggle={handleShowInvitedModal} />
    </S.Wrapper>
  );
};

export default Tables;
