import styled from 'styled-components/macro';
import { Editor } from 'react-draft-wysiwyg';
import '../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const DraftContainer = styled.div`
  width: 100%;

  .draft-wrapper {
    border-radius: 5px !important;
    background-color: ${({ theme }) => theme.colors.background[2]};

    .draft-toolbar {
      background-color: transparent;
      border: none;

      .rdw-option-wrapper {
        border-color: transparent;
        background: none;

        &:hover {
          box-shadow: none;
        }
      }

      .rdw-option-active {
        border: 1px solid ${({ theme }) => theme.colors.font};
        box-shadow: none;
      }
    }

    .draft-editor {
      box-sizing: border-box;
      font-size: 1rem;
      font-family: ${({ theme }) => theme.fonts.body};
      color: ${({ theme }) => theme.colors.font};
      line-height: 19px;
      height: 155px;
      padding: 12px 16px;
      border-top: 1px solid ${({ theme }) => theme.colors.background[7]};

      ::-webkit-scrollbar {
        width: 0px;
      }
    }
  }
`;

export const DraftWysiwyg = styled(Editor)``;
