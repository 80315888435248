import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';

export const DisableCredentialButton = styled(ButtonBase)``;

export const CredentialDescriptionText = styled(TextBase)``;

export const CredentialDescriptionTitle = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const CredentialDescriptionWrapper = styled.div`
  margin-bottom: 24px;
`;

export const Content = styled.div``;
