import { ERankingProductSortOption } from '@domain/enums/dashboard/productAnalytics/ERankingProductSort';
import { IProductMetrics } from '@domain/interfaces/dashboard/ProductAnalytics/IProducts';
import { numberFormatter } from '@helpers/masks';

import { sortNumberData, sortStringData } from '@helpers/utils/common/sort';

export const sortProductsData = (
  data: Array<IProductMetrics>,
  option: ERankingProductSortOption | undefined,
  type: 'ASC' | 'DESC' | undefined,
): Array<IProductMetrics> | undefined => {
  if (!data) return undefined;

  if (option === ERankingProductSortOption.FAVORITE_PRODUCT || !option) {
    const filteredFavoriteProducts = data?.filter(({ product }) => product.is_favorite);

    const filteredNoFavoriteProducts = data?.filter(({ product }) => !product.is_favorite);

    const sortedFavoriteProducts = filteredFavoriteProducts?.sort(
      (currentFavoriteObject, nextFavoriteObject) => {
        return sortNumberData(
          currentFavoriteObject.metrics.net_profit.amount,
          nextFavoriteObject.metrics.net_profit.amount,
          type,
        );
      },
    );

    const sortedNoFavoriteProducts = filteredNoFavoriteProducts?.sort(
      (currentNotFavoriteObject, nextNotFavoriteObject) => {
        return sortNumberData(
          currentNotFavoriteObject.metrics.net_profit.amount,
          nextNotFavoriteObject.metrics.net_profit.amount,
          type,
        );
      },
    );

    if (type === 'ASC') {
      const mergedSortedArrays = [...sortedNoFavoriteProducts, ...sortedFavoriteProducts];

      return mergedSortedArrays;
    }

    const mergedSortedArrays = [...sortedFavoriteProducts, ...sortedNoFavoriteProducts];

    return mergedSortedArrays;
  }

  const newData = [...data];

  newData.sort((currentObject, nextObject) => {
    if (option === ERankingProductSortOption.PRODUCT_NAME) {
      return sortStringData(currentObject.product.name, nextObject.product.name, type);
    }

    if (option === ERankingProductSortOption.NET_REVENUE_AMOUNT) {
      return sortNumberData(
        currentObject.metrics.net_revenue.amount,
        nextObject.metrics.net_revenue.amount,
        type,
      );
    }

    if (option === ERankingProductSortOption.PENDING_LINE_ITEMS_QUANTITY) {
      return sortNumberData(
        currentObject.metrics.pending_line_items.quantity,
        nextObject.metrics.pending_line_items.quantity,
        type,
      );
    }

    if (option === ERankingProductSortOption.COGS_AMOUNT) {
      return sortNumberData(
        currentObject.metrics.cogs.amount,
        nextObject.metrics.cogs.amount,
        type,
      );
    }

    if (option === ERankingProductSortOption.MARKETING_COST) {
      return sortNumberData(
        currentObject.metrics.marketing.total_amount,
        nextObject.metrics.marketing.total_amount,
        type,
      );
    }

    if (option === ERankingProductSortOption.TAXES_AND_FESS_AMOUNT) {
      return sortNumberData(currentObject.metrics.fee.amount, nextObject.metrics.fee.amount, type);
    }

    if (option === ERankingProductSortOption.CPA_AMOUNT) {
      return sortNumberData(currentObject.metrics.cpa.amount, nextObject.metrics.cpa.amount, type);
    }

    if (option === ERankingProductSortOption.BOLETO_CONVERSION) {
      return sortNumberData(
        currentObject.metrics.boleto_conversion.percentage,
        nextObject.metrics.boleto_conversion.percentage,
        type,
      );
    }

    if (option === ERankingProductSortOption.PROFIT_MARGIN) {
      return sortNumberData(
        currentObject.metrics.profit_margin.percentage,
        nextObject.metrics.profit_margin.percentage,
        type,
      );
    }

    if (option === ERankingProductSortOption.NET_PROFIT_AMOUNT) {
      return sortNumberData(
        currentObject.metrics.net_profit.amount,
        nextObject.metrics.net_profit.amount,
        type,
      );
    }

    return sortNumberData(
      currentObject.metrics.net_profit.amount,
      nextObject.metrics.net_profit.amount,
      type,
    );
  });

  return newData;
};

export const getFormattedValue = (value: number, quantity?: number): string => {
  const amountValue = numberFormatter(value, 2);

  if (quantity) {
    const quantityValue = numberFormatter(quantity, 0);

    return `R$ ${amountValue} (${quantityValue})`;
  }

  return `R$ ${amountValue}`;
};
