import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const Description = styled(HeadingBase)`
  display: inline-block;
  margin-bottom: 24px;
  text-align: center;
`;

export const Title = styled(HeadingBase)`
  display: inline-block;
  margin-bottom: 16px;
  text-align: center;
`;

export const Content = styled.div`
  position: relative;
  margin: 224px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1196px;
  width: 100%;
  height: 60vh;

  &::before {
    content: '';
    position: absolute;
    top: -153px;
    left: 0px;
    width: 107px;
    height: 87px;
    background-repeat: no-repeat;

    @media only screen and (max-width: 1208px) {
      background: unset;
    }
  }

  &::after {
    content: '';
    background-repeat: no-repeat;
    width: 168px;
    height: 34px;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  /* &::after {
    content: '';
    position: absolute;
    width: 1480px;
    height: 359px;
    background-repeat: no-repeat;
    bottom: -414px;

    @media only screen and (max-width: 1208px) {
      background: unset;
    }
  } */

  @media only screen and (max-width: 1366px) {
    margin: 128px auto 0 auto;
  }

  @media only screen and (max-width: 1208px) {
    flex-direction: column;
    margin: 176px auto 0 auto;
    max-width: 628px;
  }

  @media only screen and (max-height: 720px) {
    margin: 176px auto 0 auto;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;
