import React from 'react';

import { SimplePlus } from '@profitfy/pakkun-icons';
import { FunnelSimple } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import {
  EFilterStep,
  EFilterType,
  EFilterVariant,
  EFilterMode,
} from '@domain/enums/components/EFilter';
import { IFilterConfig, IFilterButtonProps } from '@domain/interfaces/components/IFilter';
import { getFilterComponent } from '@helpers/utils/components/filter';
import { ThemeProvider } from '@helpers/hooks/useTheme';

import * as S from './styles';

const FilterButton: React.FC<IFilterButtonProps> = ({
  data,
  config,
  addNewData,
  filterAlias,
  variant = EFilterVariant.LARGE,
  mode = EFilterMode.REGULAR,
  ...rest
}) => {
  const theme = useTheme();

  const [filterStep, setFilterStep] = React.useState<EFilterStep>(EFilterStep.FILTERS);
  const [selectedFilter, setSelectedFilter] = React.useState<IFilterConfig | undefined>(undefined);
  const [filterSearch, setFilterSearch] = React.useState<string>('');
  const [isFilterOpen, setIsFilterOpen] = React.useState<boolean>(false);

  const handleFilterOpen = React.useCallback(() => {
    if (isFilterOpen) {
      setFilterStep(EFilterStep.FILTERS);
    }

    setIsFilterOpen(!isFilterOpen);
  }, [isFilterOpen]);

  const handleFilterClick = React.useCallback((filter: IFilterConfig) => {
    setSelectedFilter(filter);
    setFilterStep(EFilterStep.SELECTED_FILTER);
  }, []);

  const onSearchFilterChange = React.useCallback(event => {
    setFilterSearch(event.target.value);
  }, []);

  const isFilterDisabled = React.useCallback(
    (filter: IFilterConfig) => {
      const foundFilter = data.find(filterData => filterData.field === filter.field);

      const isAdsManagerAdSenseAccountFilter =
        filter.type === EFilterType.ADS_MANAGER_AD_SENSE_ACCOUNT;
      const hasSelectedAdsManagerCredential = data.find(
        filterData => filterData.field === 'ad_sense_credential_id',
      );
      const isADsManagerAdSenseAccountFilterDisabled =
        isAdsManagerAdSenseAccountFilter && !hasSelectedAdsManagerCredential;

      return Boolean(foundFilter || isADsManagerAdSenseAccountFilterDisabled);
    },
    [data],
  );

  const getFilterIconSize = React.useCallback(() => {
    if (variant === EFilterVariant.MEDIUM) return 12;

    return 14;
  }, [variant]);

  const getIcon = React.useCallback(() => {
    if (mode === EFilterMode.AUTOMATION) {
      return <FunnelSimple size={16} color={theme.colors.gray[2]} />;
    }

    if (filterAlias === 'automation_messages') {
      return <FunnelSimple size={16} color={theme.colors.gray[1]} weight="bold" />;
    }

    return <SimplePlus size={getFilterIconSize()} color={theme.colors.gray[2]} />;
  }, [filterAlias, theme, getFilterIconSize, mode]);

  const isFirstStep = filterStep === EFilterStep.FILTERS;
  const filteredFilters = config.filter(filter => {
    const loweredCaseLabel = filter.label.toLowerCase();
    const loweredCaseFilterSearch = filterSearch.toLowerCase();

    return loweredCaseLabel.includes(loweredCaseFilterSearch);
  });
  const Filter = getFilterComponent(selectedFilter?.type || '');

  return (
    <S.Popover open={isFilterOpen} onOpenChange={handleFilterOpen}>
      <S.Popover.Trigger>
        <S.ButtonWrapper variant={variant} mode={mode} {...rest}>
          {mode === EFilterMode.AUTOMATION && getIcon()}
          Filtrar
          {mode !== EFilterMode.AUTOMATION && getIcon()}
        </S.ButtonWrapper>
      </S.Popover.Trigger>

      <S.PopoverContent sideOffset={5} filterType={selectedFilter?.type}>
        <ThemeProvider>
          {isFirstStep ? (
            <S.FiltersWrapper>
              <S.SearchFilterWrapper>
                <S.SearchFilterInput
                  type="text"
                  placeholder="Encontrar filtro..."
                  onChange={onSearchFilterChange}
                />
              </S.SearchFilterWrapper>

              <S.Filters>
                {filteredFilters.map(filter => (
                  <S.FilterButton
                    onClick={() => handleFilterClick(filter)}
                    key={filter.field}
                    disabled={isFilterDisabled(filter)}
                  >
                    {filter.label}
                  </S.FilterButton>
                ))}

                {!filteredFilters.length && (
                  <S.NoFilterFound>Não encontramos nenhum filtro.</S.NoFilterFound>
                )}
              </S.Filters>
            </S.FiltersWrapper>
          ) : (
            <>
              {selectedFilter && (
                <Filter
                  config={selectedFilter}
                  handleOnClick={handleFilterOpen}
                  addNewData={addNewData}
                  updateData={addNewData}
                  data={data}
                />
              )}
            </>
          )}
        </ThemeProvider>
      </S.PopoverContent>
    </S.Popover>
  );
};

export default FilterButton;
