import React from 'react';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { useProductDetails } from '@helpers/hooks/pages/dashboard/productAnalytics/useProductDetails';
import { numberFormatter } from '@helpers/masks';
import PieChart from './PieChart';

import * as S from './styles';

const Pix: React.FC = () => {
  const { productFinancialDetailsReport } = useProductDetails();

  const { pix } = productFinancialDetailsReport;

  return (
    <S.Card>
      <S.Content>
        <S.Title fontWeight={EHeadingWeight.LIGHT}>Pix</S.Title>

        <S.DataWrapper>
          <S.ChartWrapper>
            <PieChart />
            <S.ChartPercentage>{`${numberFormatter(pix.conversion, 0, true)}%`}</S.ChartPercentage>
          </S.ChartWrapper>

          <S.OrdersDataWrapper>
            <S.OrdersStatusWrapper>
              <S.OrdersAmountAndQuantity>
                <S.OrdersAmount>{`R$ ${numberFormatter(pix.paid.amount, 2)}`}</S.OrdersAmount>
                <S.OrdersQuantity>{`(${pix.paid.quantity})`}</S.OrdersQuantity>
              </S.OrdersAmountAndQuantity>

              <S.StatusWrapper>
                <S.ApprovedBullet />
                <S.StatusText>Aprovados</S.StatusText>
              </S.StatusWrapper>
            </S.OrdersStatusWrapper>

            <S.OrdersStatusWrapper>
              <S.OrdersAmountAndQuantity>
                <S.OrdersAmount>{`R$ ${numberFormatter(pix.pending.amount, 2)}`}</S.OrdersAmount>
                <S.OrdersQuantity>{`(${pix.pending.quantity})`}</S.OrdersQuantity>
              </S.OrdersAmountAndQuantity>

              <S.StatusWrapper>
                <S.InAnalysisBullet />
                <S.StatusText>Pendentes</S.StatusText>
              </S.StatusWrapper>
            </S.OrdersStatusWrapper>

            <S.OrdersStatusWrapper>
              <S.OrdersAmountAndQuantity>
                <S.OrdersAmount>{`R$ ${numberFormatter(pix.canceled.amount, 2)}`}</S.OrdersAmount>
                <S.OrdersQuantity>{`(${pix.canceled.quantity})`}</S.OrdersQuantity>
              </S.OrdersAmountAndQuantity>

              <S.StatusWrapper>
                <S.RefusedBullet />
                <S.StatusText>Cancelados</S.StatusText>
              </S.StatusWrapper>
            </S.OrdersStatusWrapper>
          </S.OrdersDataWrapper>
        </S.DataWrapper>
      </S.Content>
    </S.Card>
  );
};

export default Pix;
