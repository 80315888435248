import React from 'react';
import { useParams } from 'react-router-dom';
import refiner from 'refiner-js';

import { EStatus } from '@domain/enums/subscription/ESubscription';
import { IStoreSubscriptionProvider } from '@domain/interfaces/hooks/IStoreSubscription';
import { IParams } from '@domain/interfaces/IParams';
import { IPlan } from '@domain/interfaces/pricing/plans/IPlan';

import { useDate } from '@helpers/hooks/useDate';
import { useConfig } from '@helpers/hooks/useConfig';
import { useToast } from '@helpers/hooks/useToast';
import { useLocalStorage } from '@helpers/hooks/useLocalStorage';
import { useTransaction } from '@helpers/hooks/common/store/subscription/useTransaction';
import { useSubscription } from '@helpers/hooks/common/store/subscription/useSubscription';
import { useQuery } from '@helpers/hooks/useQuery';

import { getEnvironment } from '@helpers/utils/environment';

import { ALL_PLANS } from '@constants/plans';

import subscriptionService from '@services/pages/subscription/subscription';
import plansService from '@services/pages/subscription/plans';
import { IStore } from '@domain/interfaces/stores/IStore';

const StoreSubscriptionContext = React.createContext<IStoreSubscriptionProvider | null>(null);

export const StoreSubscriptionProvider: React.FC = ({ children }) => {
  const [hideExceededAlert, setHideExceededAlert] = useLocalStorage(
    `@profitfy:${getEnvironment()}/hide-exceeded-alert`,
    null,
  );
  const [hideFreePlanExceedAlert, setHideFreePlanExceedAlert] = useLocalStorage(
    `@profitfy:${getEnvironment()}/hide-free-plan-exceeded-alert`,
    null,
  );
  const query = useQuery();
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { user, analytics, selectedStore, personalInfo, isInternalTeam } = useConfig();
  const { format } = useDate();
  const { createTrial } = useSubscription();
  const {
    transactionsError,
    mutateTransactions,
    isLoadingTransactions,
    isValidatingTransactions,
  } = useTransaction();

  const {
    subscription,
    mutate: mutateSubscription,
    error: subscriptionError,
    isLoading: isLoadingSubscription,
    isValidating: isValidatingSubscription,
  } = subscriptionService.getSubscription({ storeAliasId });

  const updateCardRef = query.get('updateCard');

  const [
    isChangePaymentMethodSideModalOpen,
    setIsChangePaymentMethodSideModalOpen,
  ] = React.useState<boolean>(false);
  const [alertsComponentHeight, setAlertsComponentHeight] = React.useState<number>(0);
  const [subscriptionPlan, setSubscriptionPlan] = React.useState<
    typeof ALL_PLANS[number] | undefined
  >(undefined);
  const [currentStore, setCurrentStore] = React.useState<IStore | undefined>(undefined);
  const [isValidatingCurrentStore, setIsValidatingCurrentStore] = React.useState<boolean>(false);

  const handleCurrentStore = React.useCallback(newStore => {
    setCurrentStore(newStore);
  }, []);

  const handleValidatingCurrentStore = React.useCallback(
    state => setIsValidatingCurrentStore(state),
    [],
  );

  const mutateSubscriptionProviderData = React.useCallback(async () => {
    const promises = [];

    if (subscriptionError) promises.push(mutateSubscription());

    if (transactionsError) promises.push(mutateTransactions());

    await Promise.all(promises);
  }, [subscriptionError, transactionsError, mutateSubscription, mutateTransactions]);

  const handleChangePaymentMethodSideModalOpen = React.useCallback(() => {
    setIsChangePaymentMethodSideModalOpen(!isChangePaymentMethodSideModalOpen);
  }, [isChangePaymentMethodSideModalOpen]);

  const updateCancelationSubscription = React.useCallback(
    async data => {
      try {
        if (subscription) {
          await subscriptionService.updateCancelationSubscription({
            storeAliasId,
            subscriptionAliasId: subscription.alias_id,
            data,
          });

          toast.success('Motivo atualizado com sucesso!');

          mutateSubscription();
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
    [subscription, storeAliasId, toast, mutateSubscription],
  );

  const cancelSubscription = React.useCallback(
    async data => {
      try {
        if (subscription) {
          await subscriptionService.cancelSubscription({
            storeAliasId,
            subscriptionAliasId: subscription.alias_id,
            data,
          });

          toast.success('Assinatura cancelada com sucesso!');

          mutateSubscription();
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
    [mutateSubscription, storeAliasId, subscription, toast],
  );

  const changePlan = React.useCallback(
    async identifier => {
      try {
        const { data } = await plansService.getPlansPromise({ storeAliasId });

        if (data.plans) {
          const foundPlan = data.plans.find((plan: IPlan) => plan.identifier === identifier);

          await subscriptionService.changePlan({
            storeAliasId,
            planAliasId: foundPlan.alias_id,
            subscriptionAliasId: subscription.alias_id,
          });

          toast.success('Plano alterado com sucesso!');

          Promise.all([mutateSubscription(), mutateTransactions()]);
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
    [mutateSubscription, storeAliasId, subscription, toast, mutateTransactions],
  );

  const handleAlertsComponentHeight = React.useCallback(
    newHeight => setAlertsComponentHeight(newHeight),
    [],
  );

  const activeSubscription = React.useCallback(async () => {
    await subscriptionService.activeSubscription({
      storeAliasId,
      subscriptionAliasId: subscription.alias_id,
    });
  }, [storeAliasId, subscription]);

  const handleTrial = React.useCallback(async () => {
    try {
      await createTrial(storeAliasId);

      await mutateSubscription();
    } catch (error: any) {
      console.log(error);
    }
  }, [createTrial, mutateSubscription, storeAliasId]);

  React.useEffect(() => {
    if (subscription && subscription.plan) {
      const foundPlan = ALL_PLANS.find(
        plan =>
          plan.identifier === subscription?.plan?.identifier ||
          plan.quarterIdentifier === subscription?.plan?.identifier ||
          plan.semiAnualIdentifier === subscription?.plan?.identifier,
      );

      setSubscriptionPlan(foundPlan);
    }
  }, [subscription]);

  React.useEffect(() => {
    if (subscription && selectedStore) {
      const isTrialing = subscription.status === EStatus.TRIAL_STARTED;
      const maxOrders = subscription.allowed_orders_quantity || 0;
      const currentOrders = subscription.current_orders_quantity || 0;
      const exceededOrders = currentOrders - maxOrders;

      analytics?.group(selectedStore?.alias_id, {
        created_at: selectedStore.created_at,
        name: selectedStore.name,
        currency: selectedStore.currency,
        timezone: selectedStore.iana_timezone,
        avatar: selectedStore.image_url,
        is_active: selectedStore.is_active,
        is_onboarding_finish: selectedStore.is_onboarding_finished,
        is_trialing: isTrialing,
        trial_start_date: isTrialing
          ? format(new Date(subscription.period_started_at), 'dd/MM/yyyy')
          : null,
        trial_end_date: isTrialing
          ? format(new Date(subscription.period_ended_at), 'dd/MM/yyyy')
          : null,
      });

      analytics?.trackHubSpot(selectedStore.alias_id, {
        created_at: selectedStore.created_at,
        is_active: selectedStore.is_active,
        extra_paid_orders: Boolean(exceededOrders),
        extra_paid_orders_amount: exceededOrders,
      });
    }
  }, [subscription, selectedStore, analytics, format]);

  React.useEffect(() => {
    if (subscription && user) {
      const isTrialing = subscription.status === EStatus.TRIAL_STARTED;

      refiner('identifyUser', {
        id: user.id,
        email: user.email,
        name: `${user.first_name} ${user.last_name}`,
        signed_up_at: user.created_at,
        created_at: user.created_at ? format(new Date(user.created_at), 'dd/MM/yyyy') : undefined,
        status: 'active',
        birth_date: personalInfo?.birth_date
          ? format(new Date(personalInfo?.birth_date), 'dd/MM/yyyy')
          : undefined,
        role: personalInfo?.role,
        phone: personalInfo
          ? `${personalInfo?.phone_prefix}${personalInfo?.phone_number}`
          : undefined,
        trial_started_date_at: isTrialing
          ? new Date(subscription.period_started_at).toISOString()
          : undefined,
        subscription_status: subscription.status,
      });
    }
  }, [format, subscription, personalInfo, user]);

  React.useEffect(() => {
    if (
      !subscription &&
      !isLoadingSubscription &&
      !isValidatingSubscription &&
      !subscriptionError &&
      !isValidatingCurrentStore &&
      currentStore?.alias_id === storeAliasId &&
      currentStore?.is_onboarding_finished
    ) {
      handleTrial();
    }
  }, [
    subscription,
    isLoadingSubscription,
    isValidatingSubscription,
    subscriptionError,
    handleTrial,
    currentStore,
    isValidatingCurrentStore,
    storeAliasId,
  ]);

  React.useEffect(() => {
    if (updateCardRef === 'true') {
      setIsChangePaymentMethodSideModalOpen(true);
    }
  }, [updateCardRef]);

  // React.useEffect(() => {
  //   if (subscription?.plan?.identifier === 'free_monthly_v1' && !!window.Intercom) {
  //     window.Intercom('update', {
  //       hide_default_launcher: true,
  //     });
  //   } else if (window.Intercom) {
  //     window.Intercom('update', {
  //       hide_default_launcher: false,
  //     });
  //   }
  // }, [subscription]);

  const isLoadingSubscriptionProviderData = isLoadingTransactions || isLoadingSubscription;
  const isValidatingSubscriptionProviderData = isValidatingTransactions;
  const isSubscriptionProviderDataError = Boolean(transactionsError) || Boolean(subscriptionError);

  const hasAllPermissions = isInternalTeam || user?.role === 'BETA' || user?.role === 'VIP';

  return (
    <StoreSubscriptionContext.Provider
      value={{
        alertsComponentHeight,
        handleAlertsComponentHeight,
        hideExceededAlert,
        setHideExceededAlert,
        subscription,
        changePlan,
        cancelSubscription,
        isLoadingSubscriptionProviderData,
        isSubscriptionProviderDataError,
        isValidatingSubscriptionProviderData,
        subscriptionPlan,
        mutateSubscription,
        handleChangePaymentMethodSideModalOpen,
        isChangePaymentMethodSideModalOpen,
        hideFreePlanExceedAlert,
        setHideFreePlanExceedAlert,
        hasAllPermissions,
        updateCancelationSubscription,
        activeSubscription,
        mutateSubscriptionProviderData,
        handleCurrentStore,
        handleValidatingCurrentStore,
      }}
    >
      {children}
    </StoreSubscriptionContext.Provider>
  );
};

export const useStoreSubscription = (): IStoreSubscriptionProvider => {
  const context = React.useContext(StoreSubscriptionContext);

  if (!context) {
    throw new Error('useStoreSubscription must be used within StoreSubscriptionProvider');
  }

  return context;
};
