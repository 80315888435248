import styled from 'styled-components/macro';

export const TesteWrapper = styled.div`
  max-height: 48px;
`;

export const PieChartContainer = styled.div`
  max-width: 220px;
  width: 100%;
  max-height: 220px;
  height: 220px;
`;

export const FunnelchartContainer = styled.div`
  max-width: 156px;
  width: 100%;
  margin-bottom: 108px;
`;

export const GroupedBarChartContainer = styled.div`
  max-width: 778px;
  width: 100%;
  height: 228px;
  margin-bottom: 108px;
`;

export const ComparedAreaChartContainer = styled.div`
  max-width: 1158px;
  width: 100%;
  height: 209px;
  margin-bottom: 108px;
`;

export const LineChartContainer = styled.div`
  max-width: 778px;
  width: 100%;
  height: 316px;
  margin-bottom: 108px;
`;

export const ChartContainer = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
`;
