import React from 'react';

import { EHeadAlign } from '@domain/enums/components/ETable';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  const randomArray = Array.from(Array(10).keys());

  return (
    <S.Table>
      <S.Table.Header>
        <S.Table.Row>
          <S.Table.Head>N. Do Pedido</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Aprovado em</S.Table.Head>
          <S.Table.Head>Produto</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Receita líquida</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Custo dos Produtos</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Taxas</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Impostos</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Status</S.Table.Head>
          <S.Table.Head />
        </S.Table.Row>
      </S.Table.Header>

      <S.Table.Body>
        {randomArray.map(value => (
          <S.Table.Row borderBottom key={value}>
            <S.Table.Data>
              <S.OrderWrapper>
                <S.OrderProviderIconWrapper>
                  <Skeleton width="100%" height={38} />
                </S.OrderProviderIconWrapper>
                <S.OrderDetailsWrapper>
                  <S.OrderProviderWrapper>
                    <Skeleton width="100%" height={20} />
                  </S.OrderProviderWrapper>
                  <S.OrderApprovedTextWrapper>
                    <Skeleton width="100%" height={20} />
                  </S.OrderApprovedTextWrapper>
                </S.OrderDetailsWrapper>
              </S.OrderWrapper>
            </S.Table.Data>

            <S.Table.Data align={EHeadAlign.RIGHT}>
              <S.ApprovedAtWrapper>
                <S.DaysApprovedWrapper>
                  <Skeleton width="100%" height={20} />
                </S.DaysApprovedWrapper>
                <S.ApprovedAtTextWrapper>
                  <Skeleton width="100%" height={20} />
                </S.ApprovedAtTextWrapper>
              </S.ApprovedAtWrapper>
            </S.Table.Data>

            <S.Table.Data>
              <S.ProductWrapper>
                <S.ProductImageWrapper>
                  <Skeleton width="100%" height={48} />
                </S.ProductImageWrapper>
                <S.ProductNameWrapper>
                  <Skeleton width="100%" height={24} />
                </S.ProductNameWrapper>
              </S.ProductWrapper>
            </S.Table.Data>

            <S.Table.Data align={EHeadAlign.RIGHT}>
              <S.NetRevenueWrapper>
                <Skeleton width="100%" height={20} />
              </S.NetRevenueWrapper>
            </S.Table.Data>

            <S.Table.Data align={EHeadAlign.RIGHT}>
              <S.CogsWrapper>
                <Skeleton width="100%" height={20} />
              </S.CogsWrapper>
            </S.Table.Data>

            <S.Table.Data align={EHeadAlign.RIGHT}>
              <S.FeesWrapper>
                <Skeleton width="100%" height={20} />
              </S.FeesWrapper>
            </S.Table.Data>

            <S.Table.Data align={EHeadAlign.RIGHT}>
              <S.TaxesWrapper>
                <Skeleton width="100%" height={20} />
              </S.TaxesWrapper>
            </S.Table.Data>

            <S.Table.Data align={EHeadAlign.CENTER}>
              <S.StatusWrapper>
                <Skeleton width="100%" height={24} />
              </S.StatusWrapper>
            </S.Table.Data>

            <S.Table.Data />
          </S.Table.Row>
        ))}
      </S.Table.Body>
    </S.Table>
  );
};

export default SkeletonLoading;
