import React from 'react';

import { ISideModalSave } from '@domain/interfaces/components/ISideModalSave';

import * as S from './styles';

const SideModalSave: React.FC<ISideModalSave> = ({
  onCancel,
  onSuccess,
  successButtonText,
  cancelButtonText,
  cancelButtonTextColor,
  isLoading,
  isDisabled,
  type,
}) => {
  return (
    <S.Background>
      <S.SaveWrapper>
        {cancelButtonText && (
          <S.DeleteButton
            onClick={onCancel}
            disabled={isLoading}
            type="button"
            color={cancelButtonTextColor}
          >
            {cancelButtonText}
          </S.DeleteButton>
        )}
        <S.ConfirmButton
          onClick={onSuccess}
          isLoading={isLoading}
          disabled={isDisabled}
          type={type}
        >
          {successButtonText}
        </S.ConfirmButton>
      </S.SaveWrapper>
    </S.Background>
  );
};

export default SideModalSave;
