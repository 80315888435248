export enum ETag {
  ECOMMERCE = 'ECOMMERCE',
  INFOPRODUCT = 'INFOPRODUCT',
}

export enum EChannel {
  WHATSAPP = 'WHATSAPP',
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export enum ESelectedOption {
  ALL_TEMPLATES = 'ALL_TEMPLATES',
  ECOMMERCE = 'ECOMMERCE',
  INFOPRODUCT = 'INFOPRODUCT',
}
