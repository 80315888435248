import React from 'react';

import { EPlanRecurrence } from '@domain/enums/subscription/ESubscription';
import { IPlan } from '@domain/interfaces/common/plan/IPlan';
import { ICardTokenProps } from '@domain/interfaces/common/payment/IPayment';
import {
  ICancelSubscriptionData,
  ISubscriptionContext,
} from '@domain/interfaces/common/subscription/ISubscription';

import { useConfig } from '@helpers/hooks/useConfig';

import { ALL_PLANS } from '@constants/plans';

import subscriptionService from '@services/common/subscription/subscription';
import plansService from '@services/common/plans/plans';
import paymentMethodService from '@services/common/paymentMethod/paymentMethod';
import gatewayCustomerService from '@services/common/gatewayCustomer/gatewayCustomer';
import checkoutService from '@services/pages/subscription/checkout';

const SubscriptionContext = React.createContext<ISubscriptionContext | null>(null);

export const SubscriptionProvider: React.FC = ({ children }) => {
  const { analytics, user } = useConfig();

  const [selectedPlanIdentifier, setSelectedPlanIdentifier] = React.useState<string | undefined>(
    undefined,
  );
  const [selectedRecurrenceType, setSelectedRecurrenceType] = React.useState<EPlanRecurrence>(
    EPlanRecurrence.MONTHLY,
  );

  const handleSelectedPlanIdentifier = React.useCallback(
    planIdentifier => setSelectedPlanIdentifier(planIdentifier),
    [],
  );

  const handleSelectedRecurrenceType = React.useCallback(
    recurrenceType => setSelectedRecurrenceType(recurrenceType),
    [],
  );

  const resetSelectedPlan = React.useCallback(() => {
    setSelectedPlanIdentifier(undefined);
    setSelectedRecurrenceType(EPlanRecurrence.MONTHLY);
  }, []);

  const createTrial = React.useCallback(
    async (storeAliasId: string | undefined) => {
      const { data: trialData } = await subscriptionService.createTrial({ storeAliasId });

      analytics?.track(
        'Trial Started',
        {
          trial_start_date: trialData?.subscription?.period_started_at,
          trial_end_date: trialData?.subscription?.period_ended_at,
          email: user?.email,
        },
        {
          context: { groupId: storeAliasId },
        },
      );

      const dataLayer = (window as Record<string, any>)?.dataLayer;

      if (dataLayer) {
        dataLayer.push({ event: 'Trial Started' });
      }
    },
    [analytics, user],
  );

  const createFreeSubscription = React.useCallback(
    async (storeAliasId: string | undefined, planIdentifier: string) => {
      const plansResponse = await plansService.listAllPlansPromise({ storeAliasId });

      const foundPlan = plansResponse.data.plans.find(
        plan => plan.identifier === planIdentifier,
      ) as IPlan;

      await subscriptionService.createMonthlySubscription({
        storeAliasId,
        planAliasId: foundPlan.alias_id,
      });
    },
    [],
  );

  const updateFreeSubscription = React.useCallback(
    async (
      storeAliasId: string | undefined,
      planIdentifier: string,
      subscriptionAliasId: string | undefined,
    ) => {
      const plansResponse = await plansService.listAllPlansPromise({ storeAliasId });

      const foundPlan = plansResponse.data.plans.find(
        plan => plan.identifier === planIdentifier,
      ) as IPlan;

      await subscriptionService.updateMonthlySubscription({
        storeAliasId,
        planAliasId: foundPlan.alias_id,
        subscriptionAliasId,
      });
    },
    [],
  );

  const createSubscription = React.useCallback(
    async (
      storeAliasId: string | undefined,
      token: ICardTokenProps,
      planIdentifier: string,
      installments = 1,
      couponId: string | undefined,
    ) => {
      const isMonthlyPlan = ALL_PLANS.find(plan => plan.identifier === planIdentifier);
      const isQuarterPlan = ALL_PLANS.find(plan => plan.quarterIdentifier === planIdentifier);
      const isSemiAnualPlan = ALL_PLANS.find(plan => plan.semiAnualIdentifier === planIdentifier);

      if (!isMonthlyPlan && !isQuarterPlan && !isSemiAnualPlan) {
        throw new Error('É necessário selecionar um plano válido.');
      }

      const gatewayCustomerPromise = gatewayCustomerService.getGatewayCustomerPromise({
        storeAliasId,
      });
      const paymentMethodPromise = paymentMethodService.getPaymentMethodPromise({ storeAliasId });
      const plansPromise = plansService.listAllPlansPromise({ storeAliasId });

      const [gatewayCustomerResponse, paymentMethodResponse, plansResponse] = await Promise.all([
        gatewayCustomerPromise,
        paymentMethodPromise,
        plansPromise,
      ]);

      const foundPlan = plansResponse.data.plans.find(
        plan => plan.identifier === planIdentifier,
      ) as IPlan;

      if (!gatewayCustomerResponse.data.gateway_customer) {
        await gatewayCustomerService.createGatewayCustomer({ storeAliasId });
      }

      if (paymentMethodResponse.data.payment_method) {
        await paymentMethodService.deletePaymentMethod({ storeAliasId });
      }

      const paymentMethodData = {
        token: token.id,
        is_default: Boolean(isMonthlyPlan),
      };

      await paymentMethodService.createPaymentMethod({
        storeAliasId,
        data: paymentMethodData,
      });

      if (isMonthlyPlan) {
        await subscriptionService.createMonthlySubscription({
          storeAliasId,
          planAliasId: foundPlan.alias_id,
          couponId,
        });

        return;
      }

      if (isQuarterPlan || isSemiAnualPlan) {
        const createRecurrenceSubscriptionData = {
          installments,
        };

        await subscriptionService.createRecurrenceSubscription({
          storeAliasId,
          planAliasId: foundPlan.alias_id,
          data: createRecurrenceSubscriptionData,
          couponId,
        });
      }
    },
    [],
  );

  const updateSubscription = React.useCallback(
    async (
      storeAliasId: string | undefined,
      subscriptionAliasId: string | undefined,
      token: ICardTokenProps,
      currentPlanIdentifier: string | undefined,
      planIdentifier: string,
      installments = 1,
      couponId: string | undefined,
    ) => {
      const isMonthlyPlan = ALL_PLANS.find(plan => plan.identifier === planIdentifier);
      const isQuarterPlan = ALL_PLANS.find(plan => plan.quarterIdentifier === planIdentifier);
      const isSemiAnualPlan = ALL_PLANS.find(plan => plan.semiAnualIdentifier === planIdentifier);
      const isCurrentMonthlyPlan = ALL_PLANS.find(
        plan => plan.identifier === currentPlanIdentifier,
      );
      const isCurrentQuarterPlan = ALL_PLANS.find(
        plan => plan.quarterIdentifier === currentPlanIdentifier,
      );
      const isCurrentSemiAnualPlan = ALL_PLANS.find(
        plan => plan.semiAnualIdentifier === currentPlanIdentifier,
      );

      const gatewayCustomerPromise = gatewayCustomerService.getGatewayCustomerPromise({
        storeAliasId,
      });
      const paymentMethodPromise = paymentMethodService.getPaymentMethodPromise({ storeAliasId });
      const plansPromise = plansService.listAllPlansPromise({ storeAliasId });

      const [gatewayCustomerResponse, paymentMethodResponse, plansResponse] = await Promise.all([
        gatewayCustomerPromise,
        paymentMethodPromise,
        plansPromise,
      ]);

      const foundPlan = plansResponse.data.plans.find(
        plan => plan.identifier === planIdentifier,
      ) as IPlan;

      if (!gatewayCustomerResponse.data.gateway_customer) {
        await gatewayCustomerService.createGatewayCustomer({ storeAliasId });
      }

      if (paymentMethodResponse.data.payment_method) {
        await paymentMethodService.deletePaymentMethod({ storeAliasId });
      }

      const paymentMethodData = {
        token: token.id,
        is_default: Boolean(isMonthlyPlan),
      };

      await paymentMethodService.createPaymentMethod({
        storeAliasId,
        data: paymentMethodData,
      });

      const recurrenceSubscriptionData = {
        installments,
      };

      if (!isMonthlyPlan && !isQuarterPlan && !isSemiAnualPlan) {
        throw new Error('É necessário selecionar um plano válido.');
      }

      if (!isCurrentMonthlyPlan && !isCurrentQuarterPlan && !isCurrentSemiAnualPlan) {
        throw new Error('Use another function to create.');
      }

      if (isMonthlyPlan && isCurrentMonthlyPlan) {
        await subscriptionService.updateMonthlySubscription({
          storeAliasId,
          subscriptionAliasId,
          planAliasId: foundPlan.alias_id,
          couponId,
        });

        return;
      }

      if ((isCurrentQuarterPlan || isCurrentSemiAnualPlan) && isMonthlyPlan) {
        await subscriptionService.updateMonthlySubscription({
          storeAliasId,
          subscriptionAliasId,
          planAliasId: foundPlan.alias_id,
          couponId,
        });

        return;
      }

      if (isCurrentMonthlyPlan && (isQuarterPlan || isSemiAnualPlan)) {
        await subscriptionService.updateRecurrenceSubscription({
          storeAliasId,
          subscriptionAliasId,
          planAliasId: foundPlan.alias_id,
          data: recurrenceSubscriptionData,
          couponId,
        });

        return;
      }

      await subscriptionService.updateRecurrenceSubscription({
        storeAliasId,
        subscriptionAliasId,
        planAliasId: foundPlan.alias_id,
        data: recurrenceSubscriptionData,
        couponId,
      });
    },
    [],
  );

  const createSubscriptionByCheckoutLink = React.useCallback(
    async (
      storeAliasId: string | undefined,
      token: ICardTokenProps,
      planAliasId: string | undefined,
      hash: string,
      installments = 1,
    ) => {
      const gatewayCustomerPromise = gatewayCustomerService.getGatewayCustomerPromise({
        storeAliasId,
      });
      const paymentMethodPromise = paymentMethodService.getPaymentMethodPromise({ storeAliasId });
      const plansPromise = plansService.listAllPlansPromise({ storeAliasId });

      const [gatewayCustomerResponse, paymentMethodResponse, plansResponse] = await Promise.all([
        gatewayCustomerPromise,
        paymentMethodPromise,
        plansPromise,
      ]);

      const foundPlan = plansResponse.data.plans.find(
        plan => plan.alias_id === planAliasId,
      ) as IPlan;

      const isMonthlyPlan = ALL_PLANS.find(plan => plan.identifier === foundPlan.identifier);
      const isQuarterPlan = ALL_PLANS.find(plan => plan.quarterIdentifier === foundPlan.identifier);
      const isSemiAnualPlan = ALL_PLANS.find(
        plan => plan.semiAnualIdentifier === foundPlan.identifier,
      );

      if (!gatewayCustomerResponse.data.gateway_customer) {
        await gatewayCustomerService.createGatewayCustomer({ storeAliasId });
      }

      if (paymentMethodResponse.data.payment_method) {
        await paymentMethodService.deletePaymentMethod({ storeAliasId });
      }

      const paymentMethodData = {
        token: token.id,
        is_default: Boolean(isMonthlyPlan),
      };

      await paymentMethodService.createPaymentMethod({
        storeAliasId,
        data: paymentMethodData,
      });

      if (isMonthlyPlan) {
        const data = {
          hash,
        };

        await checkoutService.createMonthlySubscriptionByCheckoutLink({ storeAliasId, data });

        return;
      }

      if (isQuarterPlan || isSemiAnualPlan) {
        const data = {
          hash,
          installments,
        };

        await checkoutService.createNonMonthlySubscriptionByCheckoutLink({ storeAliasId, data });
      }
    },
    [],
  );

  const updateSubscriptionByCheckoutLink = React.useCallback(
    async (
      storeAliasId: string | undefined,
      token: ICardTokenProps,
      currentPlanIdentifier: string | undefined,
      planAliasId: string | undefined,
      hash: string,
      installments = 1,
    ) => {
      const gatewayCustomerPromise = gatewayCustomerService.getGatewayCustomerPromise({
        storeAliasId,
      });
      const paymentMethodPromise = paymentMethodService.getPaymentMethodPromise({ storeAliasId });
      const plansPromise = plansService.listAllPlansPromise({ storeAliasId });

      const [gatewayCustomerResponse, paymentMethodResponse, plansResponse] = await Promise.all([
        gatewayCustomerPromise,
        paymentMethodPromise,
        plansPromise,
      ]);

      const foundPlan = plansResponse.data.plans.find(
        plan => plan.alias_id === planAliasId,
      ) as IPlan;

      const isMonthlyPlan = ALL_PLANS.find(plan => plan.identifier === foundPlan.identifier);
      const isQuarterPlan = ALL_PLANS.find(plan => plan.quarterIdentifier === foundPlan.identifier);
      const isSemiAnualPlan = ALL_PLANS.find(
        plan => plan.semiAnualIdentifier === foundPlan.identifier,
      );
      const isCurrentMonthlyPlan = ALL_PLANS.find(
        plan => plan.identifier === currentPlanIdentifier,
      );
      const isCurrentQuarterPlan = ALL_PLANS.find(
        plan => plan.quarterIdentifier === currentPlanIdentifier,
      );
      const isCurrentSemiAnualPlan = ALL_PLANS.find(
        plan => plan.semiAnualIdentifier === currentPlanIdentifier,
      );

      if (!isMonthlyPlan && !isQuarterPlan && !isSemiAnualPlan) {
        throw new Error('É necessário selecionar um plano válido.');
      }

      if (!isCurrentMonthlyPlan && !isCurrentQuarterPlan && !isCurrentSemiAnualPlan) {
        throw new Error('Use another function to create.');
      }

      if (!gatewayCustomerResponse.data.gateway_customer) {
        await gatewayCustomerService.createGatewayCustomer({ storeAliasId });
      }

      if (paymentMethodResponse.data.payment_method) {
        await paymentMethodService.deletePaymentMethod({ storeAliasId });
      }

      const paymentMethodData = {
        token: token.id,
        is_default: Boolean(isMonthlyPlan),
      };

      await paymentMethodService.createPaymentMethod({
        storeAliasId,
        data: paymentMethodData,
      });

      if (isMonthlyPlan && isCurrentMonthlyPlan) {
        const data = {
          hash,
        };

        await checkoutService.updateMonthlySubscriptionByCheckoutLink({ storeAliasId, data });
        return;
      }

      if ((isCurrentQuarterPlan || isCurrentSemiAnualPlan) && isMonthlyPlan) {
        const data = {
          hash,
        };

        await checkoutService.updateMonthlySubscriptionByCheckoutLink({ storeAliasId, data });
        return;
      }

      if (isCurrentMonthlyPlan && (isQuarterPlan || isSemiAnualPlan)) {
        const data = {
          hash,
          installments,
        };

        await checkoutService.updateNonMonthlySubscriptionByCheckoutLink({ storeAliasId, data });
        return;
      }

      const data = {
        hash,
        installments,
      };

      await checkoutService.updateNonMonthlySubscriptionByCheckoutLink({ storeAliasId, data });
    },
    [],
  );

  const cancelSubscription = React.useCallback(
    async (
      storeAliasId: string | undefined,
      subscriptionAliasId: string | undefined,
      data: ICancelSubscriptionData,
    ) => {
      await subscriptionService.cancelSubscription({ storeAliasId, subscriptionAliasId, data });
    },
    [],
  );

  return (
    <SubscriptionContext.Provider
      value={{
        createTrial,
        createSubscription,
        updateSubscription,
        cancelSubscription,
        handleSelectedPlanIdentifier,
        handleSelectedRecurrenceType,
        resetSelectedPlan,
        selectedRecurrenceType,
        selectedPlanIdentifier,
        createSubscriptionByCheckoutLink,
        updateSubscriptionByCheckoutLink,
        createFreeSubscription,
        updateFreeSubscription,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscription = (): ISubscriptionContext => {
  const context = React.useContext(SubscriptionContext);

  if (!context) {
    throw new Error('useSubscription must be used within SubscriptionProvider');
  }

  return context;
};
