import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { useMediaQuery } from '@helpers/hooks/useMediaQuery';

import * as S from './styles';

const HowToAddTheScript: React.FC = () => {
  const mobileWidth = useMediaQuery('1348px');

  const [offset, setOffset] = React.useState<number>(0);

  React.useEffect(() => {
    const onScroll = (): void => {
      setOffset(window.pageYOffset);
    };
    window.addEventListener('scroll', onScroll);

    return window.removeEventListener('scroll', onScroll, true);
  }, []);

  const scrollOffset = 86 - offset;
  const stepsScrollOffset = 190 - offset;

  return (
    <S.Wrapper scrollOffset={scrollOffset}>
      <S.Title
        fontWeight={EHeadingWeight.REGULAR}
        type={mobileWidth ? EHeadingSize.H5 : EHeadingSize.H4}
      >
        Como instalar o Script?
      </S.Title>

      <S.StepsWrapper scrollOffset={stepsScrollOffset}>
        <S.Step>
          <S.StepHeader>
            <S.StepNumber>1</S.StepNumber>
            <S.StepDescription>
              {mobileWidth ? 'Copie o script acima' : 'Copie o script ao lado'}
            </S.StepDescription>
          </S.StepHeader>
        </S.Step>

        <S.Step>
          <S.StepHeader>
            <S.StepNumber>2</S.StepNumber>
            <S.StepDescription>
              No Dashboard da Shopify, clique em <strong>Loja virtual.</strong>
            </S.StepDescription>
          </S.StepHeader>
          <S.StepImage
            src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/ads-manager/onboarding/how-to-add-the-script/shopify-1.png"
            alt="Profitfy.me Virtual Store"
          />
        </S.Step>

        <S.Step>
          <S.StepHeader>
            <S.StepNumber>3</S.StepNumber>
            <S.StepDescription>
              Clique em <strong>Ações</strong>
            </S.StepDescription>
          </S.StepHeader>
          <S.StepImage
            src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/ads-manager/onboarding/how-to-add-the-script/shopify-2.png"
            alt="Profitfy.me Actions Select"
          />
        </S.Step>

        <S.Step>
          <S.StepHeader>
            <S.StepNumber>4</S.StepNumber>
            <S.StepDescription>
              Clique na opção <strong>Editar código</strong>
            </S.StepDescription>
          </S.StepHeader>
          <S.StepImage
            src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/ads-manager/onboarding/how-to-add-the-script/shopify-3.png"
            alt="Profitfy.me Actions Select"
          />
        </S.Step>

        <S.Step>
          <S.StepHeader>
            <S.StepNumber>5</S.StepNumber>
            <S.StepDescription>
              Clique em <strong>theme.liquid</strong>
            </S.StepDescription>
          </S.StepHeader>
          <S.StepImage
            src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/ads-manager/onboarding/how-to-add-the-script/shopify-4.png"
            alt="Profitfy.me Actions Select"
          />
        </S.Step>

        <S.Step>
          <S.StepHeader>
            <S.StepNumber>6</S.StepNumber>
            <S.StepDescription>
              Cole o script <strong>abaixo da tag &lt;head&gt;</strong>
            </S.StepDescription>
          </S.StepHeader>
          <S.StepImage
            src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/ads-manager/onboarding/how-to-add-the-script/shopify-5.png"
            alt="Profitfy.me Actions Select"
          />
        </S.Step>

        {/* <S.Step>
          <S.StepHeader>
            <S.StepNumber>2</S.StepNumber>
            <S.StepDescription>
              No GTAG, clique em <strong>adicionar uma nova tag.</strong>
            </S.StepDescription>
          </S.StepHeader>
          <S.StepImage
            src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/ads-manager/onboarding/how-to-add-the-script/1.png"
            alt="Profitfy.me Install Gtag"
          />
        </S.Step>

        <S.Step>
          <S.StepHeader>
            <S.StepNumber>3</S.StepNumber>
            <S.StepDescription>
              Clique onde diz <strong>escolha o tipo de tag para iniciar.</strong>
            </S.StepDescription>
          </S.StepHeader>
          <S.StepImage
            src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/ads-manager/onboarding/how-to-add-the-script/2.png"
            alt="Profitfy.me Install Gtag"
          />
        </S.Step>

        <S.Step>
          <S.StepHeader>
            <S.StepNumber>4</S.StepNumber>
            <S.StepDescription>
              Selecione <strong>HTML personalizado.</strong>
            </S.StepDescription>
          </S.StepHeader>
          <S.StepImage
            src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/ads-manager/onboarding/how-to-add-the-script/3.png"
            alt="Profitfy.me Install Gtag"
          />
        </S.Step>

        <S.Step>
          <S.StepHeader>
            <S.StepNumber>5</S.StepNumber>
            <S.StepDescription>Cole o código que você copiou posteriormente.</S.StepDescription>
          </S.StepHeader>
          <S.StepImage
            src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/ads-manager/onboarding/how-to-add-the-script/5.png"
            alt="Profitfy.me Install Gtag"
          />
        </S.Step>

        <S.Step>
          <S.StepHeader>
            <S.StepNumber>6</S.StepNumber>
            <S.StepDescription>
              Clique em <strong>Escolha um adicionador para disparar essa tag</strong>
            </S.StepDescription>
          </S.StepHeader>
          <S.StepImage
            src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/ads-manager/onboarding/how-to-add-the-script/6.png"
            alt="Profitfy.me Install Gtag"
          />
        </S.Step>

        <S.Step>
          <S.StepHeader>
            <S.StepNumber>7</S.StepNumber>
            <S.StepDescription>
              Selecione <strong>All Pages</strong>
            </S.StepDescription>
          </S.StepHeader>
          <S.StepImage
            src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/ads-manager/onboarding/how-to-add-the-script/7.png"
            alt="Profitfy.me Install Gtag"
          />
        </S.Step>

        <S.Step>
          <S.StepHeader>
            <S.StepNumber>8</S.StepNumber>
            <S.StepDescription>
              Clique em <strong>Salvar</strong>
            </S.StepDescription>
          </S.StepHeader>
          <S.StepImage
            src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/ads-manager/onboarding/how-to-add-the-script/8.png"
            alt="Profitfy.me Install Gtag"
          />
        </S.Step>

        <S.Step>
          <S.StepHeader>
            <S.StepNumber>9</S.StepNumber>
            <S.StepDescription>
              Clique em <strong>Enviar</strong>
            </S.StepDescription>
          </S.StepHeader>
          <S.StepImage
            src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/ads-manager/onboarding/how-to-add-the-script/9.png"
            alt="Profitfy.me Install Gtag"
          />
        </S.Step>

        <S.Step>
          <S.StepHeader>
            <S.StepNumber>10</S.StepNumber>
            <S.StepDescription>
              Clique em <strong>Publicar</strong>
            </S.StepDescription>
          </S.StepHeader>
          <S.StepImage
            src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/ads-manager/onboarding/how-to-add-the-script/10.png"
            alt="Profitfy.me Install Gtag"
          />
        </S.Step> */}
      </S.StepsWrapper>
    </S.Wrapper>
  );
};

export default HowToAddTheScript;
