import React from 'react';
import Datepicker from 'react-datepicker';
import { Calendar } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import Text from '@components/common/core/DataDisplay/Text';
import { useDate } from '@helpers/hooks/useDate';
import { useConfig } from '@helpers/hooks/useConfig';
import { ICustomDateProps } from '@domain/interfaces/components/IDayPicker';
import Header from './Header';

import * as S from './styles';

const CustomDate: React.FC<ICustomDateProps> = ({
  isOpen,
  handleIsOpen,
  minDate,
  maxDate,
  setSelectedDate,
  selectedDate,
}) => {
  const { format } = useDate();
  const { isInternalTeam } = useConfig();
  const theme: any = useTheme();

  const getNameOfDay = React.useCallback(
    nameOfDay =>
      ({
        Sunday: 'Dom',
        Monday: 'Seg',
        Tuesday: 'Ter',
        Wednesday: 'Qua',
        Thursday: 'Qui',
        Friday: 'Sex',
        Saturday: 'Sab',
      }[nameOfDay]),
    [],
  );

  const handleOnChange = React.useCallback(
    (date: Date) => {
      setSelectedDate(date);

      handleIsOpen();
    },
    [setSelectedDate, handleIsOpen],
  );

  return (
    <S.DateInputsWrapper>
      <S.InputWrapper>
        <S.DateButton type="button" onClick={handleIsOpen}>
          <Text>{selectedDate && format(selectedDate, 'dd/MM/yyyy')}</Text>
          <Calendar size={20} outline color={theme.colors.font} />
        </S.DateButton>
      </S.InputWrapper>
      <S.CalendarWrapper isOpen={isOpen}>
        <Datepicker
          minDate={isInternalTeam ? undefined : minDate}
          maxDate={maxDate}
          selected={selectedDate}
          onChange={handleOnChange}
          inline
          formatWeekDay={nameOfDay => getNameOfDay(nameOfDay)}
          peekNextMonth={false}
          renderCustomHeader={({ date, changeYear, decreaseMonth, increaseMonth }) => (
            <Header
              date={date}
              changeYear={changeYear}
              decreaseMonth={decreaseMonth}
              increaseMonth={increaseMonth}
            />
          )}
        />
      </S.CalendarWrapper>
    </S.DateInputsWrapper>
  );
};

export default CustomDate;
