import React from 'react';

const AppMobile: React.FC = () => {
  React.useEffect(() => {
    const appleMobileNamesToMatch = /iPhone|iPad|iPod/i;

    if (appleMobileNamesToMatch.test(navigator.userAgent)) {
      window.location.href = 'https://apps.apple.com/br/app/profitfy/id1640367466';
    } else {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.profitfy&hl=pt_BR&gl=US';
    }
  }, []);

  return <div />;
};

export default AppMobile;
