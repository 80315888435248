import React from 'react';
import { WarningCircle } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';

import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';

import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import { ADS_PROVIDERS } from '@constants/common/integrations';

import * as S from './styles';

interface INotOperationalModalProps {
  isOpen: boolean;
  toggle: () => void;
}

const NotOperationalModal: React.FC<INotOperationalModalProps> = ({ isOpen, toggle }) => {
  const theme = useTheme();
  const { storeAliasId } = useParams<IParams>();
  const { storeIntegrations } = useStoreConfig();
  const { addSynchronization } = useSynchronization();
  const { analytics, user } = useConfig();

  const sync = React.useCallback(() => {
    if (storeIntegrations) {
      const activeAdSenseIntegrations = storeIntegrations.marketing.filter(
        integration => integration.is_active,
      );
      const marketingIntegrations = ADS_PROVIDERS.filter(integration =>
        activeAdSenseIntegrations.find(
          activeIntegration => activeIntegration.name === integration.identifier,
        ),
      );

      marketingIntegrations.forEach(integration => {
        addSynchronization({
          name: integration.name,
          type: ESynchronizationType.AD_SENSE_CAMPAIGNS,
          label: 'Campanhas',
          dependencies: [],
          storeAliasId,
          config: {
            isOnboardingSynchronization: false,
            showNotification: true,
          },
          request: integration.generateSyncRequest(
            ESynchronizationType.AD_SENSE_CAMPAIGNS,
            storeAliasId,
            undefined,
            undefined,
          ),
        });
      });
    }
  }, [addSynchronization, storeAliasId, storeIntegrations]);

  const onClick = React.useCallback(() => {
    analytics?.track('Marketing Non-Operational Status Button Clicked', {
      type: 'Sincronizar campanhas',
      email: user?.email,
    });

    sync();

    toggle();
  }, [toggle, sync, analytics, user]);

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Header>
        <WarningCircle size={24} color={theme.colors.danger.default} />
        <S.Title>Sincronize suas campanhas</S.Title>
      </S.Header>

      <S.Body>
        <S.Description>
          Você possui algumas campanhas com o status <strong>não operacional</strong>. Sincronize
          suas campanhas para ajustar esse status e garantir o funcionamento correto.
        </S.Description>

        <S.SyncButton onClick={onClick}>Sincronizar campanhas</S.SyncButton>
      </S.Body>
    </S.Modal>
  );
};

export default NotOperationalModal;
