import React from 'react';
import {
  ResponsiveContainer,
  LineChart as Chart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

import { ILineChartProps } from '@domain/interfaces/components/ILineChart';
import { friendlyChartNumberFormatter } from '@helpers/masks';

import ChartTooltip from './ChartTooltip';
import * as S from './styles';

const LineChart: React.FC<ILineChartProps> = ({ data, initialConfig, currency }) => {
  return (
    <S.ChartWrapper>
      <ResponsiveContainer width="100%">
        <Chart data={data} margin={{ bottom: 16, left: 0, right: 0, top: 0 }}>
          <XAxis dataKey="name" tick={{ dy: 24 }} axisLine={false} tickLine={false} />
          <YAxis
            width={40}
            axisLine={false}
            tickLine={false}
            tickFormatter={tick => friendlyChartNumberFormatter(tick)}
          />
          <CartesianGrid strokeDasharray="10 10" vertical={false} />
          <Tooltip content={<ChartTooltip currency={currency} />} />

          {initialConfig.map(line => (
            <Line
              type="monotone"
              dataKey={line.dataKey}
              stroke={line.strokeColor}
              strokeWidth={3}
              key={line.name}
              name={line.name}
            />
          ))}
        </Chart>
      </ResponsiveContainer>
    </S.ChartWrapper>
  );
};

export default React.memo(LineChart);
