import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';
import { Barcode, CreditCard, Palette, ShoppingCart, Truck, Wallet } from 'phosphor-react';

export const DEFAULT_MESSAGE_TEMPLATES = [
  {
    id: 'no-content',
    title: 'Em branco',
    type: 'NO_CONTENT',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Palette,
  },
  {
    id: 'abandoned-cart-first-condition',
    title: 'Carrinho abandonado',
    type: 'ABANDONED_CART',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: ShoppingCart,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá {{first_name}}! 😀\n\nVocê ainda não finalizou a sua compra, mas não se preocupe! 😢\n\nAinda dá tempo de garantir antes que o estoque acabe devido à alta demanda!\n\nSeu carrinho está esperando por você, clique no link abaixo: 🛒\n\n{{checkout_link}}\n\nE, se precisar de alguma ajuda, estamos aqui! 🤩',
      },
    ],
  },
  {
    id: 'refused-credit-card-first-condition',
    title: 'Cartão Recusado',
    type: 'REJECTED_PAYMENT',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: CreditCard,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá, {{first_name}}!\n\nSeu pagamento não foi finalizado, pois seu cartão foi recusado. 😥\n\nPara garantir sua compra, clique no link abaixo e escolha outra forma de pagamento: 🛒\n\n{{checkout_link}}\n\nE, se preferir cartão de crédito, recomendamos que revise todas as informações. 🤩\n\n',
      },
    ],
  },
  {
    id: 'boleto-first-condition',
    title: 'Recuperação de Boleto',
    type: 'BOLETO',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Barcode,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Parabéns pelo seu pedido, {{first_name}}! 🎉\n\nEstamos aguardando a confirmação do pagamento para enviá-lo!\n\n📌 *Detalhes do Pedido*: #{{order_id}}\n{{products_with_variant}}\n🚚 *Endereço de entrega*: {{shipping_address}}\n🏷 *Método de pagamento*: Boleto\n💵 *Valor total*: {{total_amount}}\n\n⚠️ Se ainda não tiver efetuado o pagamento, gere um boleto pelo link abaixo:',
      },
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content: '{{boleto_url}}',
      },
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content: 'Observação: Em caso de pagamento realizado, por favor, ignore esta mensagem.',
      },
    ],
  },
  {
    id: 'pix-first-condition',
    title: 'Recuperação de Pix',
    type: 'PIX',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Wallet,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Parabéns pelo seu pedido, {{first_name}}! 🎉\n\nEstamos aguardando a confirmação do pagamento para enviá-lo!\n\n📌 *Detalhes do Pedido*: #{{order_id}}\n{{products_with_variant}}\n🚚 *Endereço de entrega*: {{shipping_address}}\n🏷 *Método de pagamento*: PIX\n💵 Valor total: {{total_amount}}\n\n⚠️ Se o código PIX expirar, basta gerar um novo.\n\n⚠️ Se preferir, escolha outra forma de pagamento, como boleto ou cartão de crédito.',
      },
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content: '{{pix_code}}',
      },
    ],
  },
  {
    id: 'simple-shipment-first-condition',
    title: 'Rastreio criado',
    type: 'SIMPLE_SHIPMENT',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Truck,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá {{first_name}}! 😀\n\nSeu pedido #{{order_id}} já foi enviado e pode ser rastreado pelo número {{tracking_number}}.\n\nAcesse o link para acompanhar a entrega: {{shipment_link}}.\n\nQualquer dúvida, estamos à disposição. Obrigado por escolher [SUA EMPRESA].',
      },
    ],
  },
];
