import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';

import * as S from './styles';

const WhatItIsFor: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
        Para que serve?
      </S.Title>

      <S.Description>
        Esse script servirá para garantir a obtenção de dados de conversão de suas campanhas.
        <br />É necessário que o script esteja instalado corretamente para conseguirmos cruzar com
        seus dados financeiros.
      </S.Description>
    </S.Wrapper>
  );
};

export default WhatItIsFor;
