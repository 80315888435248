import styled from 'styled-components/macro';

export const NewCategoryWrapper = styled.div`
  max-width: 148px;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  max-width: 120px;
  width: 100%;
`;

export const TitleAndCategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 36px;
`;

export const GoBackWrapper = styled.div`
  max-width: 52px;
  width: 100%;
  margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  margin-bottom: 28px;
`;
