import styled from 'styled-components/macro';

import CardBase from '@components/common/core/Surfaces/Card';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Value = styled(TextBase)`
  display: flex;
  font-size: 0.875rem;
  font-weight: 700;
`;

export const Title = styled(TextBase)`
  display: flex;
  font-size: 0.875rem;
  font-weight: 400;
`;

export const TextWrapper = styled.div``;

export const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  background-color: #24363f;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Card = styled(CardBase)`
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border: unset;
  padding: 8px 12px;
  height: 60px;
  display: flex;
  align-items: center;
  min-width: 154px;
  box-sizing: border-box;
`;
