import queryString from 'query-string';

import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';
import {
  IListAdSenseAccountsProps,
  IListAdSenseAccountsResponse,
  IListAdSenseAccountsWithInsightsResponse,
} from '@domain/interfaces/dashboard/AdSense/IAdSenseAccount';

export class AdSenseAccountService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listAdSenseAccountsPromise({
    storeAliasId,
    adSenseCredentialAliasId,
    page,
    filter,
  }: IListAdSenseAccountsProps): Promise<any> {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/ad-sense-credentials/${adSenseCredentialAliasId}/ad-sense-accounts?limit=10&page=${page}&${queryStringFilter}`,
    );
  }

  public listAdSenseAccounts({
    storeAliasId,
    page,
    filter,
  }: IListAdSenseAccountsProps): IListAdSenseAccountsResponse {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/ad-sense-accounts?limit=10&page=${page}&${queryStringFilter}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      adSenseAccounts: data?.ad_sense_accounts,
      totalPages: data?.total_pages,
      isLoading,
      isValidating,
      error,
      mutate,
    };
  }

  public listAdSenseAccountsWithMetrics({
    storeAliasId,
    page,
    filter,
  }: IListAdSenseAccountsProps): IListAdSenseAccountsWithInsightsResponse {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/ad-sense-accounts-insights?limit=30&page=${page}&${queryStringFilter}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      adSenseAccounts: data?.ad_sense_accounts,
      totalPages: data?.total_pages,
      isLoading,
      isValidating,
      error,
      mutate,
    };
  }
}

const adSenseAccountService = new AdSenseAccountService(API_DOMAIN);

export default adSenseAccountService;
