import * as yup from 'yup';

import { currencyToNumber } from '@helpers/masks';
import { IGetFormSchemaProps } from '@domain/interfaces/onboarding/dropshipping/gateways/otherGateways';

export const getFormSchema = ({
  isPixInputGroupVisible,
  isBoletoInputGroupVisible,
  isCredicCardInputGroupVisible,
  pixTaxType,
  boletoTaxType,
  isPredefinedGateway,
}: IGetFormSchemaProps): any => {
  const gatewayNameValidation = yup.string().required('É necessário informar um nome.');

  const gatewayNameField = {
    gateway_name: !isPredefinedGateway ? gatewayNameValidation : yup.string(),
  };

  const validationWithPercentageType = yup
    .string()
    .required('É necessário informar uma porcentagem.')
    .test('min and max value', 'A porcentagem precisa ser entre 0% a 100%.', value => {
      return currencyToNumber(value || '') >= 0 && currencyToNumber(value || '') <= 100;
    });

  const pixTaxValidation = isPixInputGroupVisible
    ? yup.string().required('É necessário informar uma taxa de pix.')
    : yup.string();

  const pixTaxValidationWithPercentage = isPixInputGroupVisible
    ? validationWithPercentageType
    : yup.string();

  const boletoTaxValidation = isBoletoInputGroupVisible
    ? yup.string().required('É necessário informar uma taxa de boleto.')
    : yup.string();

  const boletoTaxValidationWithPercentage = isBoletoInputGroupVisible
    ? validationWithPercentageType
    : yup.string();

  const credicCardTaxValidation = isCredicCardInputGroupVisible
    ? validationWithPercentageType
    : yup.string();

  const creditCardTransactionAmountValidation = isCredicCardInputGroupVisible
    ? yup.string().required('É necessário informar uma taxa para o cartão.')
    : yup.string();

  const FormSchema = yup.object().shape({
    ...gatewayNameField,
    pix_tax: pixTaxValidation,
    boleto_tax: boletoTaxValidation,
    credic_card_tax: credicCardTaxValidation,
    credit_card_transaction_amount: creditCardTransactionAmountValidation,
  });

  const FormSchemaWithPercentageTypeUseCase = yup.object().shape({
    ...gatewayNameField,
    pix_tax: pixTaxValidationWithPercentage,
    boleto_tax: boletoTaxValidationWithPercentage,
    credic_card_tax: credicCardTaxValidation,
    credit_card_transaction_amount: creditCardTransactionAmountValidation,
  });

  const FormSchemaWithPixTaxPercentage = yup.object().shape({
    ...gatewayNameField,
    pix_tax: pixTaxValidationWithPercentage,
    boleto_tax: boletoTaxValidation,
    credic_card_tax: credicCardTaxValidation,
    credit_card_transaction_amount: creditCardTransactionAmountValidation,
  });

  const FormSchemaWithBoletoTaxPercentage = yup.object().shape({
    ...gatewayNameField,
    pix_tax: pixTaxValidation,
    boleto_tax: boletoTaxValidationWithPercentage,
    credic_card_tax: credicCardTaxValidation,
    credit_card_transaction_amount: creditCardTransactionAmountValidation,
  });

  if (pixTaxType === 'percentage' && boletoTaxType === 'percentage') {
    return FormSchemaWithPercentageTypeUseCase;
  }

  if (pixTaxType === 'percentage' && boletoTaxType === 'fixed') {
    return FormSchemaWithPixTaxPercentage;
  }

  if (pixTaxType === 'fixed' && boletoTaxType === 'percentage') {
    return FormSchemaWithBoletoTaxPercentage;
  }

  return FormSchema;
};
