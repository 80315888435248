import React from 'react';
import { GoBack } from '@profitfy/pakkun-icons';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { useProductCostDetails } from '@helpers/hooks/pages/dashboard/productCostDetails/productCostDetails';
import { IParams } from '@domain/interfaces/IParams';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Header: React.FC = () => {
  const { isLoadingProduct, product, productError, isValidatingProduct } = useProductCostDetails();
  const { storeAliasId } = useParams<IParams>();
  const history = useHistory();
  const theme: any = useTheme();

  const onClick = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/data/products-costs`);
  }, [history, storeAliasId]);

  if (isLoadingProduct || isValidatingProduct) {
    return <SkeletonLoading />;
  }

  if (productError) {
    return <></>;
  }

  return (
    <S.HeaderWrapperButton onClick={onClick}>
      <GoBack size={28} color={theme.colors.font} />

      <S.Title fontWeight={EHeadingWeight.LIGHT}>{product?.name}</S.Title>
    </S.HeaderWrapperButton>
  );
};

export default Header;
