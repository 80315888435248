import React from 'react';

import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { ISaveProps } from '@domain/interfaces/dashboard/AdsManager/UtmSidemodal/ISave';

import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import * as S from './styles';

const Save: React.FC<ISaveProps> = ({ step, handleSave, handleCancel }) => {
  const { currentSynchronizations, synchronizationsQueue } = useSynchronization();

  const parsedCurrentSynchronizations = currentSynchronizations
    ? currentSynchronizations.synchronizations
    : [];
  const mergedSynchronizations = [...parsedCurrentSynchronizations, ...synchronizationsQueue];

  const foundUtmSynchronization = mergedSynchronizations.find(
    synchronization => synchronization.type === ESynchronizationType.ADS_WITHOUT_UTMS,
  );

  return (
    <S.Wrapper>
      <S.CancelButton onClick={handleCancel}>Voltar</S.CancelButton>
      <S.SaveButton onClick={handleSave} disabled={Boolean(foundUtmSynchronization)}>
        {step === 'FIRST' ? 'Adicionar UTMs Tag' : 'Salvar'}
      </S.SaveButton>
    </S.Wrapper>
  );
};

export default Save;
