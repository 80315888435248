import React from 'react';
import { ChevronDown, Sync, Task } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { useFinancialDetails } from '@helpers/hooks/pages/dashboard/financialDetails/useFinancialDetails';
import { useConfig } from '@helpers/hooks/useConfig';
import { useStorePermission } from '@helpers/hooks/common/useStorePermission';

import * as S from './styles';

const ActionsSelect: React.FC = () => {
  const theme = useTheme();
  const { analytics, selectedStore, user } = useConfig();
  const { hasExportSpreadsheetFeature } = useStorePermission();
  const {
    mutateComparedCustomValues,
    mutateCustomValues,
    mutateFinancialTableByDay,
    mutateFinancialTableByPeriod,
    handleExportSpreadSheetOpen,
    handleNoExportSpreadSheetPermissionModalOpen,
  } = useFinancialDetails();

  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);
  const [
    isHoveringExportSpreadSheetButton,
    setIsHoveringExportSpreadSheetButton,
  ] = React.useState<boolean>(false);
  const [isHoveringReloadDataButton, setIsHoveringReloadDataButton] = React.useState<boolean>(
    false,
  );

  const handlePopoverOpen = React.useCallback(status => setIsPopoverOpen(status), []);

  const handleExportSpreadSheetClick = React.useCallback(() => {
    handlePopoverOpen(false);

    if (hasExportSpreadsheetFeature) {
      handleExportSpreadSheetOpen();

      analytics?.track(
        'SpreadSheet Button Clicked',
        {
          email: user?.email,
        },
        { context: { groupId: selectedStore?.alias_id } },
      );
    } else {
      handleNoExportSpreadSheetPermissionModalOpen();
    }
  }, [
    handlePopoverOpen,
    handleExportSpreadSheetOpen,
    hasExportSpreadsheetFeature,
    handleNoExportSpreadSheetPermissionModalOpen,
    analytics,
    selectedStore,
    user,
  ]);

  const onSpreadSheetButtonMouseEnter = React.useCallback(() => {
    setIsHoveringExportSpreadSheetButton(true);
  }, []);

  const onSpreadSheetButtonMouseLeave = React.useCallback(() => {
    setIsHoveringExportSpreadSheetButton(false);
  }, []);

  const onReloadDataButtonMouseEnter = React.useCallback(() => {
    setIsHoveringReloadDataButton(true);
  }, []);

  const onReloadDataButtonMouseLeave = React.useCallback(() => {
    setIsHoveringReloadDataButton(false);
  }, []);

  const reloadPage = React.useCallback(() => {
    mutateComparedCustomValues();
    mutateCustomValues();
    mutateFinancialTableByDay();
    mutateFinancialTableByPeriod();
  }, [
    mutateComparedCustomValues,
    mutateCustomValues,
    mutateFinancialTableByDay,
    mutateFinancialTableByPeriod,
  ]);

  return (
    <S.Popover open={isPopoverOpen} onOpenChange={handlePopoverOpen}>
      <S.Popover.Trigger>
        <S.ActionsSelectButton>
          Ações
          <ChevronDown size={16} color={theme.colors.font} />
        </S.ActionsSelectButton>
      </S.Popover.Trigger>

      <S.PopoverContent side="bottom" sideOffset={5}>
        <S.ActionButtonsWrapper>
          <S.ActionButton
            onMouseEnter={onSpreadSheetButtonMouseEnter}
            onMouseLeave={onSpreadSheetButtonMouseLeave}
            onClick={handleExportSpreadSheetClick}
          >
            <Task
              outline
              size={20}
              color={
                isHoveringExportSpreadSheetButton ? theme.colors.green.default : theme.colors.font
              }
            />
            Exportar para Planilha
          </S.ActionButton>
          <S.ActionButton
            onMouseEnter={onReloadDataButtonMouseEnter}
            onMouseLeave={onReloadDataButtonMouseLeave}
            onClick={reloadPage}
          >
            <Sync
              size={20}
              color={isHoveringReloadDataButton ? theme.colors.green.default : theme.colors.font}
            />
            Recarregar dados
          </S.ActionButton>
        </S.ActionButtonsWrapper>
        <S.PopoverArrow />
      </S.PopoverContent>
    </S.Popover>
  );
};

export default ActionsSelect;
