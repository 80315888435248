import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';

export const TooltipText = styled(TextBase)``;

export const TooltipArrow = styled(CustomTooltipBase.Arrow)`
  fill: ${({ theme }) => theme.colors.black.light};
`;

export const TooltipContent = styled(CustomTooltipBase.Content)`
  background-color: ${({ theme }) => theme.colors.black.light};
`;

export const Tooltip = styled(CustomTooltipBase)``;

export const CustomValueAmount = styled(TextBase)`
  width: fit-content;
`;

export const CustomValueName = styled(TextBase)`
  max-width: 264px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;

  @media only screen and (max-width: 492px) {
    max-width: 224px;
  }

  @media only screen and (max-width: 448px) {
    max-width: 192px;
  }

  @media only screen and (max-width: 416px) {
    max-width: 164px;
  }

  @media only screen and (max-width: 376px) {
    max-width: 128px;
  }

  @media only screen and (max-width: 345px) {
    max-width: 100px;
  }
`;

export const ChartPercentage = styled(TextBase)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.875rem;
`;

export const CustomValueDetails = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-between;

  @media only screen and (max-width: 582px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
`;

export const ChartWrapper = styled.div`
  min-height: 64px;
  min-width: 64px;
  width: 64px;
  height: 64px;
  position: relative;
`;

export const Wrapper = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 24px;
  background-color: ${({ theme }) => theme.colors.gray[7]};
  border-radius: 6px;

  @media only screen and (max-width: 416px) {
    gap: 12px;
  }
`;
