export const getFinancialNetProfitChartWithMultiStoresData = (
  currentSelectedStoreData: any[],
  selectedStoresData: any[],
): any => {
  if (selectedStoresData && currentSelectedStoreData) {
    const newArray: Array<Record<string, any>> = [];

    const newData = [currentSelectedStoreData, ...selectedStoresData];

    newData?.forEach((array: any) => {
      newArray.push(...array);
    });

    const result = Object.values(
      newArray.reduce((acc: Record<any, any>, value: Record<any, any>) => {
        const { date, net_profit } = value;

        const { value: net_profit_value } = net_profit;

        acc[date] = {
          date,
          net_profit_value: (acc[date] ? acc[date].net_profit_value : 0) + net_profit_value,
        };

        return acc;
      }, {}),
    );

    return result;
  }

  return [];
};
