import React from 'react';
import { CircleSpinner } from 'react-spinners-kit';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { IParams } from '@domain/interfaces/IParams';
import { IBestSellingProduct } from '@domain/interfaces/dashboard/BenefitArea/IBestSellingProduct';
import { ISimulator } from '@domain/interfaces/dashboard/BenefitArea/ISimulator';

import { useToast } from '@helpers/hooks/useToast';

import { numberFormatter } from '@helpers/masks';

import zendropService from '@services/pages/dashboard/benefitArea/zendrop';

import * as S from './styles';

const Simulator: React.FC<ISimulator> = ({ sendEvent, isSendingCogsInformation }) => {
  const theme = useTheme();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();

  const [isLoadingProduct, setIsLoadingProduct] = React.useState<boolean>(false);
  const [isImageError, setIsImageError] = React.useState<boolean>(false);
  const [bestSellingProduct, setBestSellingProduct] = React.useState<IBestSellingProduct>(
    {} as IBestSellingProduct,
  );

  const getTopOneProduct = React.useCallback(async () => {
    setIsLoadingProduct(true);

    try {
      const { data } = await zendropService.getBestSellingProduct({
        storeAliasId,
      });

      setBestSellingProduct(data?.product);
      setIsLoadingProduct(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsLoadingProduct(false);
    }
  }, [storeAliasId, toast]);

  React.useEffect(() => {
    getTopOneProduct();
  }, [getTopOneProduct]);

  const hasProductImage = Boolean(bestSellingProduct?.image_url) && !isImageError;

  const formattedProductAmount = `R$ ${numberFormatter(bestSellingProduct?.cogs_amount, 2)}`;

  const formattedZendropSelectAmount = `R$ ${numberFormatter(
    bestSellingProduct?.zendrop_cogs_amount,
    2,
  )}`;

  const formattedTotal = `R$ ${numberFormatter(bestSellingProduct?.net_profit_amount, 2)}`;

  return (
    <S.Wrapper>
      <S.Heading weight={ETextWeight.SEMIBOLD} size={ETextSize.B2}>
        Simulação com o seu produto mais vendido dos últimos 30 dias
      </S.Heading>

      <S.ProductWrapper>
        {isLoadingProduct && (
          <CircleSpinner size={24} color={theme.colors.green.default} loading={isLoadingProduct} />
        )}

        {!isLoadingProduct && (
          <>
            {!hasProductImage && <S.NoImage />}

            {hasProductImage && (
              <S.ProductImage
                src={bestSellingProduct?.image_url}
                alt="profitfy-top-one-product-image"
                onError={() => setIsImageError(true)}
              />
            )}
            <S.ProductName weight={ETextWeight.BOLD}>{bestSellingProduct?.name}</S.ProductName>
          </>
        )}
      </S.ProductWrapper>

      <S.SummaryWrapper>
        <S.SummaryValues>
          <S.SummaryDetail>
            <S.SummaryDetailText>Custo atual do produto (últimos 30 dias)</S.SummaryDetailText>
            <S.SummaryDetailValue weight={ETextWeight.BOLD}>
              {formattedProductAmount}
            </S.SummaryDetailValue>
          </S.SummaryDetail>

          <S.SummaryDetail>
            <S.SummaryDetailText>Custo com Zendrop Select (últimos 30 dias)</S.SummaryDetailText>
            <S.SummaryDetailValue weight={ETextWeight.BOLD}>
              {formattedZendropSelectAmount}
            </S.SummaryDetailValue>
          </S.SummaryDetail>
        </S.SummaryValues>

        <S.SummaryDetailTotalWrapper>
          <S.StoppedProfitingText weight={ETextWeight.BOLD}>
            Você deixou de lucrar
          </S.StoppedProfitingText>
          <S.SummaryDetailTotal size={ETextSize.B1}>{formattedTotal}</S.SummaryDetailTotal>
        </S.SummaryDetailTotalWrapper>
      </S.SummaryWrapper>

      <S.ButtonsWrapper>
        <S.OportunityButton
          onClick={sendEvent}
          isLoading={isSendingCogsInformation}
          disabled={isSendingCogsInformation}
        >
          Aproveitar oportunidade
        </S.OportunityButton>
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export default Simulator;
