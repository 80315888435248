import React from 'react';

import { useToast } from '@helpers/hooks/useToast';
import { useBilling } from '@helpers/hooks/pages/dashboard/customValues/useBilling';
import { useCustomValuesOut } from '@helpers/hooks/pages/dashboard/customValues/useCustomValuesOut';
// import Overview from '../Overview';
import Table from '../Table';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const {
    customValueBillingsError,
    isLoadingCustomValueBillings,
    isValidatingCustomValueBillings,
  } = useBilling();
  const { handleSelectedCustomValueChecked } = useCustomValuesOut();
  const { toast } = useToast();

  React.useEffect(() => {
    if (
      customValueBillingsError &&
      !(isLoadingCustomValueBillings || isValidatingCustomValueBillings)
    ) {
      toast.error('Ocorreu um problema ao carregar as cobranças! Por favor, tente novamente.');
      handleSelectedCustomValueChecked(undefined);
    }
  }, [
    toast,
    customValueBillingsError,
    handleSelectedCustomValueChecked,
    isLoadingCustomValueBillings,
    isValidatingCustomValueBillings,
  ]);

  if (customValueBillingsError) {
    return <></>;
  }

  return (
    <S.BillingWrapper>
      <Table />
      {/* <Overview /> */}
    </S.BillingWrapper>
  );
};

export default Wrapper;
