import React from 'react';

import { IRowProps } from '@domain/interfaces/components/ITable';

import * as S from './styles';

const Row: React.FC<IRowProps> = ({ children, bottomSpacing, borderBottom, style, ...rest }) => {
  return (
    <S.Row bottomSpacing={bottomSpacing} borderBottom={borderBottom} style={style} {...rest}>
      {children}
    </S.Row>
  );
};

export default Row;
