import { ISynchronizationRequest } from '@domain/interfaces/hooks/IStoreSynchronization';
import {
  ESynchronizationType,
  ESynchronizationRequestType,
} from '@domain/enums/hooks/ESynchronization';
import { IAliases } from '@domain/interfaces/integrations/marketing/facebook/IAliases';
import { IUpdateFacebookCampaign } from '@domain/interfaces/dashboard/AdSense/Facebook/IFacebookCampaign';

import { LONG_API_DOMAIN } from '@constants/api';

import { getEnvironment } from '@helpers/utils/environment';

import facebookCampaignService from '@services/pages/dashboard/adSense/facebook/facebookCampaign';

export const getUpdateFacebookCampaignPromise = ({
  adSenseCampaignAliasId,
  data,
  productAliasId,
  storeAliasId,
}: IUpdateFacebookCampaign): Promise<any> => {
  return facebookCampaignService.updateFacebookCampaign({
    adSenseCampaignAliasId,
    data,
    productAliasId,
    storeAliasId,
  });
};

export const getFacebookSynchronizationRequestConfig = (
  type: ESynchronizationType,
  storeAliasId: string | undefined,
  aliases: IAliases | undefined,
  data: any | undefined,
): ISynchronizationRequest => {
  if (type === ESynchronizationType.AD_SENSE_ACCOUNTS) {
    return {
      type: ESynchronizationRequestType.POST,
      domain: LONG_API_DOMAIN,
      api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/ad-sense-credentials/${
        aliases?.adSenseCredentialAliasId
      }/facebook/ad-sense-accounts/sync`,
    };
  }

  if (type === ESynchronizationType.UPDATE_AD_SENSE_ACCOUNTS) {
    return {
      type: ESynchronizationRequestType.PUT,
      domain: LONG_API_DOMAIN,
      api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/ad-sense-credentials/${
        aliases?.adSenseCredentialAliasId
      }/facebook/ad-sense-accounts/${aliases?.adSenseAccountAliasId}`,
      requestBody: { ...data },
    };
  }

  if (type === ESynchronizationType.AD_SENSE_CAMPAIGNS) {
    return {
      type: ESynchronizationRequestType.POST,
      domain: LONG_API_DOMAIN,
      api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/facebook/ad-sense-campaigns/sync`,
    };
  }

  return {
    type: ESynchronizationRequestType.PUT,
    domain: LONG_API_DOMAIN,
    api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/products/${
      aliases?.productAliasId
    }/facebook/ad-sense-campaigns/${aliases?.adSenseCampaignAliasId}`,
    requestBody: { ...data },
  };
};
