import React from 'react';

import { numberFormatter } from '@helpers/masks';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { ISpend } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IAdSenseCampaign';

import * as S from './styles';

const SpendAmount: React.FC<ICampaignProps> = ({ data }) => {
  const spendAmountData: ISpend = data;

  return (
    <S.Wrapper>
      <S.SpendAmount>{`(-) R$ ${numberFormatter(spendAmountData.amount, 2)}`}</S.SpendAmount>
    </S.Wrapper>
  );
};

export default SpendAmount;
