import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const StoresWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StoreCardWrapper = styled.div`
  width: 100%;
`;

export const ExitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const TextAndProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 16px;
`;

export const HeadingWrapper = styled(HeadingBase)`
  width: 100%;
  margin-bottom: 8px;
`;

export const Image = styled(ImageBase)`
  max-width: 261px;
  margin-bottom: 84px;
`;

export const ContentWrapper = styled.div`
  max-width: 404px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding-top: 60px;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background[1]};
  min-height: 100vh;
  position: relative;
  padding: 0 32px 64px 32px;
`;
