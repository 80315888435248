import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { ISkeletonLoadingProps } from '@domain/interfaces/dashboard/FinancialDashboard/IChart';

import * as S from './styles';

const SkeletonLoading: React.FC<ISkeletonLoadingProps> = ({ height = 300 }) => {
  return (
    <S.ChartContainer>
      <Skeleton width="100%" height={height} style={{ borderRadius: 12 }} />
    </S.ChartContainer>
  );
};

export default SkeletonLoading;
