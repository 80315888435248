import React from 'react';
import { WarningCircle } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import * as S from './styles';

interface IWrongDomainModalProps {
  isOpen: boolean;
  toggle: () => void;
}

const WrongDomainModal: React.FC<IWrongDomainModalProps> = ({ isOpen, toggle }) => {
  const theme = useTheme();

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Wrapper>
        <S.Title>
          <WarningCircle size={24} color={theme.colors.warning.default} weight="bold" />
          Dados inválidos!
        </S.Title>

        <S.Body>
          <S.Description>
            Ocorreu um problema ao integrar com a Shopify! Verifique se todos os dados informados,
            como <strong>o domínio, o Token de acesso da API Admin e a Chave secreta da API</strong>{' '}
            estão corretos.
          </S.Description>
          <S.SubmitButton onClick={toggle}>Entendi</S.SubmitButton>
        </S.Body>
      </S.Wrapper>
    </S.Modal>
  );
};

export default WrongDomainModal;
