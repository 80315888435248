import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import LabelBase from '@components/common/core/DataDisplay/Label';
import DayPickerBase from '@components/common/core/Utils/DayPicker';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';

export const Checkbox = styled(CheckboxBase)``;

export const CheckboxInputGroup = styled(InputGroupBase)`
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  box-sizing: border-box;
  margin-bottom: 32px;

  & > label {
    margin-bottom: unset;
    cursor: pointer;
    font-size: 1rem;
  }
`;

export const Label = styled(LabelBase)``;

export const DayPicker = styled(DayPickerBase)``;

export const Input = styled(TextFieldBase)``;

export const Option = styled(OptionBase)``;

export const Select = styled(SelectBase)`
  width: 100%;

  & > label > select {
    width: 100%;
  }
`;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 32px;
`;

export const PauseButtonText = styled(TextBase)``;

export const IconWrapper = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PauseButton = styled.button`
  border: none;
  background-color: unset;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: all 0.3s;
  cursor: pointer;
  margin-bottom: 32px;

  &:hover {
    border-color: ${({ theme }) => theme.colors.green.default};
  }
`;

export const BodyWrapper = styled.div`
  & > div:first-of-type {
    max-width: 274px;

    & > label > select {
      max-width: 274px;
    }
  }
`;
