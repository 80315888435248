import React from 'react';

import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { EStatus } from '@domain/enums/subscription/ESubscription';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';

import { ALL_PLANS } from '@constants/plans';

import Modal from './Modal';

const ExceededFacebookProfiles: React.FC = () => {
  const { storeIntegrations } = useStoreConfig();
  const { subscription } = useStoreSubscription();

  const [hasExceeded, setHasExceeded] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (subscription?.status !== EStatus.PAID) return;

    if (!storeIntegrations) return;

    const foundFacebookIntegration = storeIntegrations.marketing.find(
      integration => integration.name === EAdSenseProvider.FACEBOOK,
    );

    if (!foundFacebookIntegration) return;

    const subscriptionPlan = subscription.plan;

    if (!subscriptionPlan) return;

    const foundSubscriptionPlan = ALL_PLANS.find(
      plan => plan.identifier === subscriptionPlan.identifier,
    );

    if (foundSubscriptionPlan?.facebookProfile === 0 || !foundSubscriptionPlan) return;

    if (
      foundFacebookIntegration.quantity &&
      foundFacebookIntegration.quantity <= foundSubscriptionPlan?.facebookProfile
    ) {
      setHasExceeded(false);

      return;
    }

    if (foundFacebookIntegration.quantity === 0) {
      setHasExceeded(false);

      return;
    }

    setHasExceeded(true);
  }, [storeIntegrations, subscription]);

  const isSubscriptionPage = window.location.href.includes('dashboard/subscription');

  if (!hasExceeded || isSubscriptionPage) {
    return <></>;
  }

  return <Modal />;
};

export default ExceededFacebookProfiles;
