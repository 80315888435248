import { ESelectedChart } from '@domain/enums/dashboard/main/ESelectedChart';

export const getFinancialChartDataWithMultiStoresData = (
  currentSelectedStoreData: any[],
  selectedStoresData: any[],
  selectedChart: ESelectedChart,
): any => {
  const newArray: Array<Record<string, any>> = [];

  const newData = selectedStoresData
    ? [currentSelectedStoreData, ...selectedStoresData]
    : [currentSelectedStoreData];

  newData?.forEach((array: any) => {
    newArray.push(...array);
  });

  if (selectedChart === ESelectedChart.REVENUE) {
    const result = Object.values(
      newArray.reduce((acc: Record<any, any>, value: Record<any, any>) => {
        const { name, gateway_revenue, cogs, marketing, fee, net_profit } = value;

        acc[name] = {
          name,
          gateway_revenue: (acc[name] ? acc[name].gateway_revenue : 0) + gateway_revenue,
          cogs: (acc[name] ? acc[name].cogs : 0) + cogs,
          marketing: (acc[name] ? acc[name].marketing : 0) + marketing,
          fee: (acc[name] ? acc[name].fee : 0) + fee,
          net_profit: (acc[name] ? acc[name].net_profit : 0) + net_profit,
        };

        return acc;
      }, {}),
    );

    return result;
  }

  if (selectedChart === ESelectedChart.COGS) {
    const result = Object.values(
      newArray.reduce((acc: Record<any, any>, value: Record<any, any>) => {
        const { name, delivered, pending } = value;

        acc[name] = {
          name,
          delivered: (acc[name] ? acc[name].delivered : 0) + delivered,
          pending: (acc[name] ? acc[name].pending : 0) + pending,
        };

        return acc;
      }, {}),
    );

    return result;
  }

  if (selectedChart === ESelectedChart.TAXES) {
    const result = Object.values(
      newArray.reduce((acc: Record<any, any>, value: Record<any, any>) => {
        const { name, checkout_fee, gateway_fee, marketplace_fee, tax } = value;

        acc[name] = {
          name,
          checkout_fee: (acc[name] ? acc[name].checkout_fee : 0) + checkout_fee,
          gateway_fee: (acc[name] ? acc[name].gateway_fee : 0) + gateway_fee,
          marketplace_fee: (acc[name] ? acc[name].marketplace_fee : 0) + marketplace_fee,
          tax: (acc[name] ? acc[name].tax : 0) + tax,
        };

        return acc;
      }, {}),
    );

    return result;
  }

  if (selectedChart === ESelectedChart.MARKETING) {
    const result = Object.values(
      newArray.reduce((acc: Record<any, any>, value: Record<any, any>) => {
        const { name, facebook, google, manual, taboola } = value;

        acc[name] = {
          name,
          facebook: (acc[name] ? acc[name].facebook : 0) + facebook,
          google: (acc[name] ? acc[name].google : 0) + google,
          manual: (acc[name] ? acc[name].manual : 0) + manual,
          taboola: (acc[name] ? acc[name].taboola : 0) + taboola,
        };

        return acc;
      }, {}),
    );

    return result;
  }

  return newData;
};
