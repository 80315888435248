import React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';

import * as S from './styles';

const Arrow: React.FC<PopoverPrimitive.PopoverArrowProps & React.RefAttributes<SVGSVGElement>> = ({
  children,
  ...rest
}) => {
  return <S.PopoverArrow {...rest}>{children}</S.PopoverArrow>;
};

export default Arrow;
