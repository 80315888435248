import React from 'react';

import { useTheme } from 'styled-components';

import { ETextWeight } from '@domain/enums/components/EText';

import { CARDS } from '@constants/pages/dashboard/benefitArea/zendrop/cards';

import * as S from './styles';

const BenefitsSection: React.FC = () => {
  const theme = useTheme();

  return (
    <S.Container>
      <S.Content>
        <S.Heading>Com a Zendrop Select você tem</S.Heading>

        <S.CardsWrapper>
          {CARDS.map(card => {
            const Icon = card.icon;

            return (
              <S.Card key={card.title}>
                <Icon
                  size={64}
                  color={theme.colors.green.default}
                  weight="duotone"
                  style={{ marginBottom: 24 }}
                />

                <S.CardTitle weight={ETextWeight.SEMIBOLD}>{card.title}</S.CardTitle>

                <S.CardDescription weight={ETextWeight.MEDIUM}>
                  {card.description}
                </S.CardDescription>
              </S.Card>
            );
          })}
        </S.CardsWrapper>
      </S.Content>
    </S.Container>
  );
};

export default BenefitsSection;
