import * as yup from 'yup';

export const traySchema = yup.object().shape({
  domain: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'Informe um domínio válido.')
    .max(400, 'Informe um domínio válido.')
    .test('domain validator', 'Informe um domínio válido.', value => {
      const regex = new RegExp(
        /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g,
      );

      if (value) return regex.test(value);

      return false;
    }),
});
