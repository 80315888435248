import { ApiService } from '@services/api';
import { USER_API_DOMAIN } from '@constants/api';
import {
  IAssignCustomerSuccessProps,
  IGetCustomerSuccessReportProps,
} from '@domain/interfaces/dashboard/AdminPanel/ICustomerSuccess';

export class CustomerSuccessService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listAllCustomerSuccess(): Promise<any> {
    return this.apiService.get(`/api/v1/users/customer-success`);
  }

  public getCustomerSuccessReport({
    customer_success_alias_id,
    start_date,
    end_date,
  }: IGetCustomerSuccessReportProps): Promise<any> {
    return this.apiService.get(
      `/api/v1/users/customer-success/${customer_success_alias_id}/report?start_date=${start_date}&end_date=${end_date}`,
    );
  }

  public assignCustomerSuccess({
    customer_success_alias_id,
    data,
  }: IAssignCustomerSuccessProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/customer-success/${customer_success_alias_id}`, {
      ...data,
    });
  }
}

const customerSuccessService = new CustomerSuccessService(USER_API_DOMAIN);

export default customerSuccessService;
