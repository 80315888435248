import React from 'react';
import { SimplePlus } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';

import { useEditKit } from '@helpers/hooks/pages/dashboard/kit/useEditKit';

import * as S from './styles';

const NewVariant: React.FC = () => {
  const theme = useTheme();
  const { addVariant } = useEditKit();

  const addNewVariant = React.useCallback(() => {
    addVariant({
      quantity: 1,
      variant: undefined,
      id: uuidv4(),
    });
  }, [addVariant]);

  return (
    <S.Wrapper type="button" onClick={addNewVariant} id="new_kit_variant">
      <S.Text>Adicionar variante</S.Text>

      <S.PlusIconWrapper>
        <SimplePlus size={20} color={theme.colors.gray[1]} />
      </S.PlusIconWrapper>
    </S.Wrapper>
  );
};

export default NewVariant;
