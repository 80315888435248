import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
`;

export const Header = styled.div`
  max-width: 600px;
  width: 100%;
  margin-bottom: 32px;
`;

export const Body = styled.div`
  max-width: 600px;
  width: 100%;
`;
