import React from 'react';

import { useCheckoutFee } from '@helpers/hooks/pages/dashboard/checkoutFee/useCheckoutFee';
import PageError from '@components/common/core/Utils/PageError';
import InstantHelp from '@components/common/core/Utils/InstantHelp';
import Header from '../Header';
import DefaultCheckoutFee from '../DefaultCheckoutFee';
import HistoricTable from '../HistoricTable';
import NewCheckoutFeeSidemodal from '../NewCheckoutFeeSidemodal';
import HistoricSidebar from '../HistoricSidebar';
import SyncPastCheckoutFeeModal from '../SyncPastCheckoutFeeModal';
import SyncPastCheckoutFeeSidemodal from '../SyncPastCheckoutFeeSidemodal';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const {
    defaultCheckoutFee,
    isValidatingCheckoutFee,
    isCheckoutFeeError,
    mutate,
  } = useCheckoutFee();

  if (isCheckoutFeeError && !isValidatingCheckoutFee) {
    return <PageError mutate={mutate} />;
  }

  return (
    <S.Container>
      <Header />

      {defaultCheckoutFee && <DefaultCheckoutFee />}

      <HistoricTable />

      <InstantHelp
        name="checkout_fee"
        title="Taxas de Checkout"
        articleLink="https://ajuda.profitfy.me/pt-BR/articles/6214195-como-configurar-a-taxa-de-checkout"
      />

      <NewCheckoutFeeSidemodal />

      <HistoricSidebar />

      <SyncPastCheckoutFeeModal />

      <SyncPastCheckoutFeeSidemodal />
    </S.Container>
  );
};

export default Wrapper;
