export const zipCodeFormatter = (value = ''): string => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');
};

export const formattedZipCodeToRaw = (value = ''): string => {
  return value.replace('-', '');
};
