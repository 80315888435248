import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useParams } from 'react-router-dom';
import { GoogleAds as GoogleAdsIcon } from '@profitfy/pakkun-icons';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { EProgressBarStep } from '@domain/enums/onboarding/progressBar/ESteps';
import { EButtonTextColor, EButtonVariant } from '@domain/enums/components/EButton';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { IParams } from '@domain/interfaces/IParams';
import { IMarketingContentProps } from '@domain/interfaces/dashboard/IntegrationCenter/Marketing/IMarketingContent';

import { useConfig } from '@helpers/hooks/useConfig';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useGoogleCredentials } from '@helpers/hooks/common/integrations/marketing/google/useGoogleCredentials';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';

import { GoogleAdAccountsProvider } from '@helpers/hooks/common/integrations/marketing/google/useGoogleAdAccounts';

import GoogleAdsImg from '@assets/pages/Onboarding/dropshipping/marketing/google-ads.svg';

import SideBar from '@components/Onboarding/SideBar';
import NextStep from '@components/Onboarding/NextStep';
import PageError from '@components/common/core/Utils/PageError';
import Header from '../Header';
import AdAccountsTable from '../Table';
import { PageSkeletonLoading, TableSkeletonLoading } from '../SkeletonLoading';

import * as S from './styles';

const Wrapper: React.FC<IMarketingContentProps> = ({ callback }) => {
  const { storeAliasId } = useParams<IParams>();
  const { analytics } = useConfig();
  const { currentSynchronizations } = useSynchronization();
  const { isLoadingTrial } = useOnboarding();
  const {
    adSenseCredentials,
    isGoogleCredentialsDataError,
    isLoadingGoogleCredentialsData,
    isValidatingGoogleCredentialsData,
    isLoggingIn,
    isLoggingOut,
    mutate,
    handleLogin,
  } = useGoogleCredentials();

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: response => {
      handleLogin(response);

      analytics?.track(
        'Onboarding Profile Button Clicked',
        {
          provider: EAdSenseProvider.GOOGLE,
        },
        { context: { groupId: storeAliasId } },
      );
    },
    onError: response => console.log(response),
    flow: 'auth-code',
    scope:
      'openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/adwords',
  });

  const handleNextStepButtonClick = React.useCallback(() => {
    callback();

    analytics?.track(
      'Onboarding Marketing Integration Done',
      {
        provider: EAdSenseProvider.GOOGLE,
      },
      { context: { groupId: storeAliasId } },
    );
  }, [analytics, storeAliasId, callback]);

  const isSyncing = Boolean(
    currentSynchronizations?.synchronizations.find(synchronization => {
      if (
        synchronization.type === ESynchronizationType.AD_SENSE_ACCOUNTS ||
        synchronization.type === ESynchronizationType.AD_SENSE_CAMPAIGNS
      ) {
        return true;
      }

      return false;
    }),
  );

  const isLoading =
    isLoadingGoogleCredentialsData ||
    isValidatingGoogleCredentialsData ||
    isLoggingIn ||
    isLoggingOut;

  if (isLoading) {
    return (
      <S.Container>
        <SideBar
          onboardingStep={EProgressBarStep.INTEGRATIONS}
          linkToGoBack={`/onboarding/${storeAliasId}/dropshipping/integrations`}
        />

        <PageSkeletonLoading />
      </S.Container>
    );
  }

  if (isGoogleCredentialsDataError && !isValidatingGoogleCredentialsData) {
    return (
      <S.Container>
        <SideBar
          onboardingStep={EProgressBarStep.INTEGRATIONS}
          linkToGoBack={`/onboarding/${storeAliasId}/dropshipping/integrations`}
        />

        <S.ErrorWrapper>
          <PageError mutate={mutate} />
        </S.ErrorWrapper>
      </S.Container>
    );
  }

  return (
    <S.Container>
      <SideBar
        onboardingStep={EProgressBarStep.INTEGRATIONS}
        linkToGoBack={`/onboarding/${storeAliasId}/dropshipping/integrations`}
      />

      {Boolean(adSenseCredentials.length) && (
        <S.HeaderAndTableContainer>
          <Header />

          {isSyncing && <TableSkeletonLoading />}

          {!isSyncing && (
            <>
              {adSenseCredentials.map((adSenseCredential, index) => (
                <GoogleAdAccountsProvider
                  adSenseCredentialAliasId={adSenseCredential.alias_id}
                  key={adSenseCredential.alias_id}
                >
                  <AdAccountsTable
                    openFirstProfile={Boolean(index === 0)}
                    adSenseCredential={adSenseCredential}
                  />
                </GoogleAdAccountsProvider>
              ))}
            </>
          )}
        </S.HeaderAndTableContainer>
      )}

      {Boolean(!adSenseCredentials.length) && (
        <S.ContentWrapper>
          <S.Image src={GoogleAdsImg} alt="profitfy-goat-with-google-ads-logo" />

          <S.Heading type={EHeadingSize.H2}>Conectar perfil do Google Ads</S.Heading>

          <S.ButtonsWrapper>
            <S.AddNewProfileButton onClick={handleGoogleLogin}>
              <GoogleAdsIcon size={22} />
              Adicionar perfil do Google Ads
            </S.AddNewProfileButton>

            <S.HorizontalRuleWrapper>
              <S.HorizontalRule />
              <S.HorizontalRuleText>Ou</S.HorizontalRuleText>
              <S.HorizontalRule />
            </S.HorizontalRuleWrapper>

            <S.SkipStepButton
              onClick={handleNextStepButtonClick}
              variant={EButtonVariant.SECONDARY_BUTTON}
            >
              Conectar depois
            </S.SkipStepButton>
          </S.ButtonsWrapper>
        </S.ContentWrapper>
      )}

      {Boolean(adSenseCredentials.length) && (
        <NextStep
          onClick={handleNextStepButtonClick}
          textColor={EButtonTextColor.DARK}
          disabled={isSyncing || isLoadingTrial}
          isLoading={isSyncing || isLoadingTrial}
        >
          Próximo Passo
        </NextStep>
      )}
    </S.Container>
  );
};

export default Wrapper;
