import React from 'react';

import { useTheme } from 'styled-components';
import { X as CloseIcon, HourglassHigh } from 'phosphor-react';
import { useUpdateNodeInternals } from 'react-flow-renderer';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';

import {
  IWaitNodeModalProps,
  ITime,
} from '@domain/interfaces/dashboard/Automations/Canvas/Node/WaitNode/IWaitNodeModal';

import { ETime } from '@domain/enums/dashboard/automations/node/waitNode/ETime';

import * as S from './styles';

const WaitModal: React.FC<IWaitNodeModalProps> = ({ isOpen, toggle, rules: initialRule }) => {
  const { colors } = useTheme();
  const { setNodes, selectedNodeId } = useAutomation();
  const updateNodeInternals = useUpdateNodeInternals();

  const [rules, setRules] = React.useState<ITime[]>(initialRule);

  const getFirstRule = React.useCallback(() => {
    const allRules = [...rules];
    const firstRule = allRules.shift() as ITime;
    return { firstRule };
  }, [rules]);

  const { firstRule } = getFirstRule();

  const handleChangeInput = React.useCallback(
    e => {
      const arrayCopy = [...rules];
      const firstItem = arrayCopy[0];

      const value = e.target.value.replace(/[^\d]/g, '');

      arrayCopy[0] = { unit: firstItem.unit, amount: value };
      setRules(arrayCopy);

      e.target.value = value;
    },
    [rules],
  );

  const handleChangeTimeType = React.useCallback(
    e => {
      const arrayCopy = [...rules];
      const firstItem = arrayCopy[0];
      arrayCopy[0] = { unit: e.target.value, amount: firstItem.amount };
      setRules(arrayCopy);
    },
    [rules],
  );

  const handleCloseModal = React.useCallback(() => {
    setRules(initialRule);
    toggle();
  }, [toggle, initialRule]);

  const handleSaveRule = React.useCallback(() => {
    if (rules[0].amount === '') {
      return;
    }
    setNodes((nds: any) =>
      nds.map((node: any) => {
        if (node.id === selectedNodeId) {
          node.data = {
            ...node.data,
            rules,
          };
        }

        return node;
      }),
    );

    updateNodeInternals(selectedNodeId as string);
    toggle();
  }, [selectedNodeId, setNodes, rules, updateNodeInternals, toggle]);

  React.useEffect(() => {
    setRules(initialRule);
  }, [initialRule]);

  return (
    <S.Wrapper isOpen={isOpen} toggle={handleCloseModal}>
      <S.Row>
        <S.GroupTitle>
          <HourglassHigh size={28} color={colors.gray[1]} />
          <S.Title>Configurar aguardar</S.Title>
        </S.GroupTitle>
        <S.CloseButton onClick={toggle}>
          <CloseIcon size={20} color={colors.gray[3]} />
        </S.CloseButton>
      </S.Row>

      <S.Row>
        <S.SubTitle>Aguardar intervalo de tempo</S.SubTitle>
      </S.Row>

      <S.Row>
        <S.Description>
          Essa opção irá permitir continuar o fluxo de automação após o término do tempo configurado
        </S.Description>
      </S.Row>

      <S.Row>
        <S.TextField type="text" defaultValue={firstRule.amount} onChange={handleChangeInput} />

        <S.Select name="time" defaultValue={firstRule.unit} onChange={handleChangeTimeType}>
          <S.Option value={ETime.SECONDS}>Segundos</S.Option>
          <S.Option value={ETime.MINUTES}>Minutos</S.Option>
          <S.Option value={ETime.HOURS}>Horas</S.Option>
          <S.Option value={ETime.DAYS}>Dias</S.Option>
        </S.Select>
      </S.Row>

      <S.SaveButtonWrapper onClick={handleSaveRule}>
        <S.SaveButton>Salvar configuração</S.SaveButton>
      </S.SaveButtonWrapper>
    </S.Wrapper>
  );
};

export default WaitModal;
