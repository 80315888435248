import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Button = styled(ButtonBase)`
  position: relative;
  margin-top: 40px;

  &:first-child {
    &::before {
      content: '';
      position: absolute;
      width: 161px;
      height: 22px;
      bottom: -48px;
      background-repeat: no-repeat;

      @media only screen and (max-width: 852px) {
        display: none;
      }
    }
  }

  span {
    font-size: 0.875rem;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Description = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
  line-height: 150%;
  display: inline-block;
  margin-bottom: 8px;

  strong {
    color: ${({ theme }) => theme.colors.gray[1]};
  }
`;

export const Title = styled(HeadingBase)`
  display: inline-block;
  margin-bottom: 32px;

  mark {
    color: ${({ theme }) => theme.colors.green.default};
    background-color: unset;
  }
`;

export const Content = styled.div`
  max-width: 384px;
  width: 100%;
  margin: 0 auto;
  margin-top: 156px;

  @media only screen and (max-height: 600px) {
    margin-top: 92px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 22px;
    height: 22px;
    top: 341px;
    right: 33px;
    background-repeat: no-repeat;

    @media only screen and (max-width: 852px) {
      display: none;
    }
  }
`;

export const ContentWrapper = styled.div`
  width: 50%;
  min-height: 100vh;
  background: linear-gradient(
    8.42deg,
    #000000 -5.05%,
    #000000 10.18%,
    #050609 48.24%,
    #11131a 72.41%
  );
  padding: 32px;
  box-sizing: border-box;
  position: relative;

  @media only screen and (max-width: 852px) {
    width: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    width: 17px;
    height: 142px;
    bottom: 54px;
    left: 32px;
    background-repeat: no-repeat;

    @media only screen and (max-width: 852px) {
      display: none;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 161px;
    height: 22px;
    top: 351px;
    right: -190px;
    background-repeat: no-repeat;

    @media only screen and (max-width: 852px) {
      display: none;
    }
  }
`;

export const Wrapper = styled.div`
  background-color: #000;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  background-repeat: no-repeat;
  background-position: right;
`;
