import React from 'react';
import { useTheme } from 'styled-components';
import { X as CloseIcon } from 'phosphor-react';

import { useMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useMessageSettings';

import ModalContent from './ModalContent';

import * as S from './styles';

const UpdateMessageModal: React.FC = () => {
  const theme = useTheme();

  const { isUpdateMessageModalOpen, resetMessageSettings } = useMessageSettings();

  const onClose = React.useCallback(() => {
    resetMessageSettings();
  }, [resetMessageSettings]);

  return (
    <S.Modal isOpen={isUpdateMessageModalOpen} toggle={onClose}>
      <ModalContent />

      <S.CloseButton onClick={onClose} type="button">
        <CloseIcon size={20} color={theme.colors.gray[3]} />
      </S.CloseButton>
    </S.Modal>
  );
};

export default UpdateMessageModal;
