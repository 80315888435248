import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Description = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 28px;
  font-weight: 400;
  text-align: center;
  display: inherit;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: 'Poppins', 'Open Sans';

  & > svg {
    margin-top: -2px;
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const Title = styled.h1`
  text-align: center;
  line-height: 60px;
  margin-bottom: 24px;
  font-size: 3.75rem;
  color: #fff;
  font-family: 'Poppins', 'Open Sans';
  font-weight: 600;
  max-width: 800px;

  mark {
    background-color: unset;
    color: ${({ theme }) => theme.colors.green.default};
  }
`;

export const PlansWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
`;

export const Header = styled.div`
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  max-width: 1384px;
  width: 100%;
  margin: 0 auto;
`;

export const Wrapper = styled.section`
  margin-bottom: 128px;
`;
