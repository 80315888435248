import React from 'react';
import { Calendar } from '@profitfy/pakkun-icons';
import { useDate } from '@helpers/hooks/useDate';
import { useParams, useHistory } from 'react-router-dom';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import Save from '@components/common/core/DataDisplay/SideModal/Save';
import { useToast } from '@helpers/hooks/useToast';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import {
  ESynchronizationRequestType,
  ESynchronizationType,
} from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';
import { useEditKit } from '@helpers/hooks/pages/dashboard/kit/useEditKit';
import { LONG_API_DOMAIN } from '@constants/api';
import { getEnvironment } from '@helpers/utils/environment';

import * as S from './styles';

const SyncPastCogsSidemodal: React.FC = () => {
  const { utcToZonedTime, format } = useDate();
  const { toast } = useToast();
  const { addSynchronization } = useSynchronization();
  const { storeAliasId } = useParams<IParams>();
  const { editKitVariants, handleSyncSideModalOpen, isSyncSideModalOpen } = useEditKit();
  const history = useHistory();

  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [period, setPeriod] = React.useState({
    startDate: utcToZonedTime(new Date()),
    endDate: utcToZonedTime(new Date()),
  });

  const handleOnSave = React.useCallback(async () => {
    setIsSaving(true);

    try {
      const firstVariant = editKitVariants[0];

      const params = `?start_date=${format(
        utcToZonedTime(period.startDate),
        'yyyy-MM-dd',
      )}&end_date=${format(utcToZonedTime(period.endDate), 'yyyy-MM-dd')}`;

      addSynchronization({
        name: 'Custo dos Produtos',
        type: ESynchronizationType.STORE_ORDER,
        label: 'Pedidos',
        dependencies: [ESynchronizationType.STORE_PRODUCT],
        storeAliasId,
        config: {
          isOnboardingSynchronization: false,
          showNotification: true,
        },
        request: {
          type: ESynchronizationRequestType.POST,
          domain: LONG_API_DOMAIN,
          api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/orders/cogs/sync`,
          requestParams: params,
          requestBody: {
            variant_ids: [firstVariant.variant?.id],
            scope: 'variant',
          },
        },
      });

      handleSyncSideModalOpen();

      setIsSaving(false);

      history.goBack();
    } catch (error: any) {
      setIsSaving(false);
      toast.error(error?.response?.data?.message);
    }
  }, [
    toast,
    addSynchronization,
    format,
    utcToZonedTime,
    period,
    storeAliasId,
    handleSyncSideModalOpen,
    history,
    editKitVariants,
  ]);

  const onCancel = React.useCallback(() => {
    handleSyncSideModalOpen();

    setIsSaving(false);
  }, [handleSyncSideModalOpen]);

  const maxDate = utcToZonedTime(new Date());

  return (
    <S.Sidemodal isOpen={isSyncSideModalOpen} toggle={onCancel}>
      <S.Header>
        <Calendar size={32} outline />
        <S.Title>Alterar período</S.Title>
      </S.Header>
      <S.Body>
        <S.ProductName fontWeight={EHeadingWeight.REGULAR}>
          Selecione o período para sincronizar
        </S.ProductName>
        <S.CustomDatePicker period={period} setPeriod={setPeriod} maxDate={maxDate} />

        <Save
          cancelContent="Cancelar"
          saveContent="Salvar alterações"
          isSubmitting={isSaving}
          isSaveButtonDisabled={isSaving}
          isDeleteButtonDisabled={isSaving}
          type="button"
          onSave={handleOnSave}
        />
      </S.Body>
    </S.Sidemodal>
  );
};

export default SyncPastCogsSidemodal;
