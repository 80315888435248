import styled from 'styled-components/macro';

import SidemodalBase from '@components/common/core/DataDisplay/SideModal';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import LabelBase from '@components/common/core/DataDisplay/Label';

export const Checkbox = styled(CheckboxBase)``;

export const CheckboxText = styled(TextBase)``;

export const CheckboxTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const CheckboxWrapper = styled(LabelBase)`
  cursor: pointer;
  margin: unset;
`;

export const InputGroup = styled(InputGroupBase)`
  margin: unset;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

export const IntegrationsWrapper = styled.div`
  max-width: 508px;
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray[7]};
  border-radius: 5px;

  & > div {
    padding: 16px 0;
    border-top: 1px solid ${({ theme }) => theme.colors.gray[5]};
  }

  & > div:first-child {
    padding: unset;
    border: unset;
    padding-bottom: 16px;
  }

  & > div:last-child {
    padding: unset;
    padding-top: 16px;
  }

  & > div:only-child {
    padding: unset;
  }
`;

export const SuggestionButton = styled.button`
  background-color: unset;
  border: unset;
  padding: unset;
  margin-bottom: 16px;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.green.default};
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const DescriptionText = styled(TextBase)`
  line-height: 120%;
  margin-bottom: 16px;
  display: inline-block;
`;

export const InstructionTitle = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const Title = styled(HeadingBase)``;

export const Body = styled.div``;

export const Header = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
  margin-bottom: 48px;
`;

export const Sidemodal = styled(SidemodalBase)`
  background-color: ${({ theme }) => theme.colors.gray[6]};
`;
