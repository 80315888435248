import React from 'react';
import {
  Dsers,
  Shopify,
  NuvemShop,
  MercadoLivre,
  Bling,
  CartPanda,
  Yampi,
} from '@profitfy/pakkun-icons';

import { ETextWeight } from '@domain/enums/components/EText';
import { EHeadAlign } from '@domain/enums/components/ETable';
import { IOrderSynchronization } from '@domain/interfaces/common/orders/IOrders';
import { useDate } from '@helpers/hooks/useDate';

import { numberFormatter, currency } from '@helpers/masks';
import {
  getBadgeColors,
  getParsedStatus,
  sumFeeOrderAmount,
  getOrderLineItems,
  getProviderIcon,
} from '@helpers/dashboard/orders/ordersUtils';
import { ITableRowProps } from '@domain/interfaces/dashboard/Boleto/IBoleto';
import TooltipContent from '../TooltipContent';
import FeeTooltip from './FeeTooltip';

import * as S from './styles';

const TableRow: React.FC<ITableRowProps> = ({ order }) => {
  const { format, utcToZonedTime } = useDate();

  const [isProductImageError, setIsProductImageError] = React.useState<boolean>(false);

  const synchronizationIcon = {
    DSERS: <Dsers size={12} />,
    SHOPIFY: <Shopify size={12} />,
    NUVEM_SHOP: <NuvemShop size={12} color="white" />,
    CART_PANDA: <CartPanda size={12} />,
    MERCADO_LIVRE: <MercadoLivre size={12} />,
    BLING: <Bling size={12} />,
    YAMPI: <Yampi size={12} />,
  };

  const getMostRecentSync = React.useCallback(
    (orderSynchronizations: Array<IOrderSynchronization>) => {
      const mostRecentSynchronization = orderSynchronizations.reduce((previous, current) => {
        return new Date(previous.updated_at) > new Date(current.updated_at) ? previous : current;
      });

      return mostRecentSynchronization;
    },
    [],
  );

  const getMostRecentSyncIcon = React.useCallback(
    (orderSynchronizations: Array<IOrderSynchronization>) => {
      const mostRecentSynchronization = getMostRecentSync(orderSynchronizations);

      const Icon = synchronizationIcon[mostRecentSynchronization.provider];

      return Icon;
    },
    [synchronizationIcon, getMostRecentSync],
  );

  const productCost = order.cogs_amount + order.iof_amount;
  const { firstLineItem, restLineItems } = getOrderLineItems(order.line_items);
  const isFeeAmountGreaterThanZero = sumFeeOrderAmount(order) > 0;
  const isOrderCogsAmountGreaterThanZero = productCost > 0;
  const formattedFeeAmount = numberFormatter(sumFeeOrderAmount(order), 2);
  const orderCreatedAt = format(
    utcToZonedTime(new Date(order.provider_created_at)),
    'dd/MM/yyyy HH:mm:ss',
  );
  const orderApprovedAt = format(
    utcToZonedTime(new Date(order.approved_at || '')),
    'dd/MM/yyyy HH:mm:ss',
  );

  const ProviderIcon = getProviderIcon(order.provider);

  return (
    <S.BoletoTable.Row borderBottom>
      <S.BoletoTable.Data>
        <S.OrderDetails>
          <S.ImageIconWrapper>
            <ProviderIcon size={20} />
          </S.ImageIconWrapper>
          <S.OrderDetailsTextWrapper>
            {order.provider_detail_url ? (
              <S.OrderNumber href={order.provider_detail_url} target="_blank">
                {order.name}
              </S.OrderNumber>
            ) : (
              <S.OrderNumber>{order.name}</S.OrderNumber>
            )}
            <S.OrderDetailsDate>{orderCreatedAt}</S.OrderDetailsDate>
          </S.OrderDetailsTextWrapper>
        </S.OrderDetails>
      </S.BoletoTable.Data>

      <S.BoletoTable.Data>
        <S.ApprovedAtWrapper>
          <S.ApprovedAtDaysText>{`${order.time_to_approve_in_days} dia(s)`}</S.ApprovedAtDaysText>
          <S.ApprovedAtDate>{orderApprovedAt}</S.ApprovedAtDate>
        </S.ApprovedAtWrapper>
      </S.BoletoTable.Data>

      <S.BoletoTable.Data>
        <S.ProductWrapper>
          {firstLineItem.product?.image_url && !isProductImageError ? (
            <S.ProductImage
              src={firstLineItem.product.image_url}
              alt="Profitfy.me Boleto"
              onError={() => setIsProductImageError(true)}
            />
          ) : (
            <S.NoImage />
          )}
          <S.ProductDetailsWrapper>
            <S.ProductDetailsText weight={ETextWeight.MEDIUM}>
              {firstLineItem.product?.name}
            </S.ProductDetailsText>

            {restLineItems?.length ? (
              <>
                <S.SeeMoreProducts
                  data-tip
                  data-for={`products-tooltip-${order.alias_id}`}
                  data-event="click focus"
                >
                  ver mais
                </S.SeeMoreProducts>

                <S.Popover id={`products-tooltip-${order.alias_id}`}>
                  {restLineItems.map(lineItem => (
                    <S.TooltipProductText key={lineItem.alias_id} weight={ETextWeight.MEDIUM}>
                      {lineItem.product?.name}
                    </S.TooltipProductText>
                  ))}
                </S.Popover>
              </>
            ) : (
              <></>
            )}
          </S.ProductDetailsWrapper>
        </S.ProductWrapper>
      </S.BoletoTable.Data>

      <S.BoletoTable.Data>
        <S.RevenueValue weight={ETextWeight.MEDIUM}>
          {`${currency(order.currency)} ${numberFormatter(order.gateway_amount, 2)}`}
        </S.RevenueValue>
      </S.BoletoTable.Data>

      <S.BoletoTable.Data align={EHeadAlign.RIGHT}>
        <S.ProductCostText weight={ETextWeight.REGULAR}>
          {isOrderCogsAmountGreaterThanZero
            ? `${currency(order.currency)} ${numberFormatter(productCost, 2)}`
            : '-'}

          {order.order_synchronizations?.length ? (
            <S.Tooltip
              content={
                <TooltipContent synchronization={getMostRecentSync(order.order_synchronizations)} />
              }
            >
              <S.SynchronizationIconWrapper>
                {getMostRecentSyncIcon(order.order_synchronizations)}
              </S.SynchronizationIconWrapper>
            </S.Tooltip>
          ) : (
            <></>
          )}
        </S.ProductCostText>
      </S.BoletoTable.Data>

      <S.BoletoTable.Data align={EHeadAlign.RIGHT}>
        <FeeTooltip order={order}>
          <S.RevenueValue>{`R$ ${
            isFeeAmountGreaterThanZero ? formattedFeeAmount : '-'
          }`}</S.RevenueValue>
        </FeeTooltip>
      </S.BoletoTable.Data>

      <S.BoletoTable.Data align={EHeadAlign.RIGHT}>
        {order.tax_amount > 0
          ? `${currency(order.currency)} ${numberFormatter(order.tax_amount, 2)}`
          : '-'}
      </S.BoletoTable.Data>

      <S.BoletoTable.Data>
        <S.StatusBadge secondary color={getBadgeColors(order.status)}>
          {getParsedStatus(order.status)}
        </S.StatusBadge>
      </S.BoletoTable.Data>
    </S.BoletoTable.Row>
  );
};

export default TableRow;
