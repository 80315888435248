import subscribeErrors from '@locales/subscription/checkout/subscribe.json';
import commonErrors from '@locales/common/commonErrors.json';

export const getCheckoutSubscribeTranslatedErrorMessage = (error: any): string => {
  const errorMessage = error?.response?.data?.message;

  if (!errorMessage) return error;

  const mergedArray = [...subscribeErrors, ...commonErrors];

  const foundErrorMessage = mergedArray.find(
    subscribeError => subscribeError.apiError === errorMessage,
  );

  if (foundErrorMessage) return foundErrorMessage.translatedError;

  return errorMessage;
};
