import { ApiService } from '@services/api';
import { WHATSAPP_CONFIG_API_DOMAIN } from '@constants/api';
import { useFetch } from '@providers/Fetch/useFetch';
import {
  IListAllCredentialsResponse,
  IDeleteCredentialProps,
  IGetCredentialProps,
  IListAllCredentialsProps,
  IUpdateCredentialProps,
} from '@domain/interfaces/dashboard/WhatsappInstance/IWhatsappInstance';

export class WhatsappCredentialService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listAllCredentials({
    storeAliasId,
    start_date,
    end_date,
  }: IListAllCredentialsProps): IListAllCredentialsResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/whatsapp-credentials/metrics?start_date=${start_date}&end_date=${end_date}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      whatsappCredentials: data?.whatsapp_credentials,
      error,
      mutate,
      isLoading,
      isValidating,
    };
  }

  public getCredential({ storeAliasId, credentialAliasId }: IGetCredentialProps): Promise<any> {
    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/whatsapp-credentials/${credentialAliasId}`,
    );
  }

  public updateCredential({
    storeAliasId,
    credentialAliasId,
    data,
  }: IUpdateCredentialProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/whatsapp-credentials/${credentialAliasId}`,
      {
        ...data,
      },
    );
  }

  public deleteCredential({
    storeAliasId,
    credentialAliasId,
  }: IDeleteCredentialProps): Promise<any> {
    return this.apiService.delete(
      `/api/v1/users/stores/${storeAliasId}/whatsapp-credentials/${credentialAliasId}`,
    );
  }
}

const whatsappCredentialService = new WhatsappCredentialService(WHATSAPP_CONFIG_API_DOMAIN);

export default whatsappCredentialService;
