import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import SwitchBase from '@components/common/core/Inputs/Switch';

export const Switch = styled(SwitchBase)`
  margin-bottom: 16px;
`;

export const Input = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)``;

export const Title = styled(HeadingBase)`
  margin-bottom: 12px;
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[6]};
  padding: 24px 32px;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 24px;
`;
