import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Switch } from 'react-router-dom';

import { DowngradeProvider } from '@helpers/hooks/common/store/subscription/useDowngrade';
import { SubscriptionProvider } from '@helpers/hooks/common/store/subscription/useSubscription';
import { SynchronizationProvider } from '@helpers/hooks/common/useSynchronization';
import { ConfigProvider } from '@helpers/hooks/useConfig';
import { DateProvider } from '@helpers/hooks/useDate';
import { RetrospectiveDataProvider } from '@helpers/hooks/useRetrospectiveData';
import { ThemeProvider } from '@helpers/hooks/useTheme';
import { ToastProvider } from '@helpers/hooks/useToast';

// import Subscription from '@routes/Subscription';

import CheckoutFinished from '@components/Subscription/CheckoutFinished';
import AppMobile from '@pages/AppMobile';
import ForgotPassword from '@pages/Auth/ForgotPassword';
import NewPassword from '@pages/Auth/NewPassword';
import ResetPassword from '@pages/Auth/ResetPassword';
import SignIn from '@pages/Auth/SignIn';
import SignUpAffiliateForm from '@pages/Auth/SignUp/AffiliateFormSocial';
import SignUpFirstForm from '@pages/Auth/SignUp/FirstForm';
import SignUpSecondForm from '@pages/Auth/SignUp/SecondForm';
import SignUpThirdForm from '@pages/Auth/SignUp/ThirdForm';
import Close from '@pages/Close';
import FacebookLogin from '@pages/FacebookLogin';
import Migration from '@pages/Migration';
import Pricing from '@pages/Pricing';
import Stores from '@pages/Stores';
import Checkout from '@pages/Subscription/Checkout';
import StorePricing from '@pages/Subscription/PricingWrapper';
import PrivacyPolicy from '@pages/TermsAndPrivacy/PrivacyPolicy';
import TermsOfAdhesion from '@pages/TermsAndPrivacy/TermsOfAdhesion';
import TermsOfUsage from '@pages/TermsAndPrivacy/TermsOfUsage';
import VerifyScript from '@pages/VerifyScript';
import Consent from '@routes/Consent';
import Dashboard from '@routes/Dashboard';
import Downgrade from '@routes/Downgrade';
import Onboarding from '@routes/Onboarding';

import Synchronization from '@components/Synchronization';
import CookiesUsage from '@components/common/core/Utils/CookiesUsage';
import Route from '@components/routes/Route';

import GlobalStyle from './styles/globalStyle.js';

const App: React.FC = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <DateProvider>
          <GoogleOAuthProvider clientId="209205472574-s8auoutm1unkefelsrp56pgm7tm0cqt0.apps.googleusercontent.com">
            <ConfigProvider>
              <SubscriptionProvider>
                <ThemeProvider>
                  <ToastProvider>
                    <SynchronizationProvider>
                      <DowngradeProvider>
                        <RetrospectiveDataProvider>
                          <Switch>
                            <Route path="/" exact component={SignIn} />
                            <Route path="/terms-of-usage" isPublic exact component={TermsOfUsage} />
                            <Route
                              path="/terms-of-adhesion"
                              isPublic
                              exact
                              component={TermsOfAdhesion}
                            />
                            <Route
                              path="/privacy-policy"
                              isPublic
                              exact
                              component={PrivacyPolicy}
                            />
                            <Route path="/app" isPublic exact component={AppMobile} />
                            <Route
                              path="/:storeAliasId/facebook-login"
                              isPublic
                              exact
                              component={FacebookLogin}
                            />
                            <Route path="/migration" exact component={Migration} />
                            <Route path="/sign-up/1" exact component={SignUpFirstForm} />
                            <Route path="/sign-up/3" exact component={SignUpThirdForm} />
                            <Route path="/sign-up/2" isPrivate exact component={SignUpSecondForm} />
                            <Route
                              path="/sign-up/affiliate"
                              isPrivate
                              exact
                              component={SignUpAffiliateForm}
                            />

                            <Route path="/close" isPrivate exact component={Close} />
                            <Route
                              path="/verified-utm-installation"
                              isPublic
                              exact
                              component={VerifyScript}
                            />
                            <Route path="/forgot-password" exact component={ForgotPassword} />
                            <Route
                              path="/reset-password/:resetPasswordToken"
                              exact
                              component={ResetPassword}
                            />
                            <Route
                              path="/new-password/:newPasswordToken"
                              exact
                              component={NewPassword}
                            />
                            <Route path="/pricing" isPublic exact component={Pricing} />

                            <Route
                              path="/:storeAliasId/pricing"
                              isPrivate
                              exact
                              component={StorePricing}
                            />

                            <Route path="/stores" isPrivate exact component={Stores} />

                            {/* <Route
                              path="/:storeAliasId/subscription/"
                              isPrivate
                              component={() => <Subscription />}
                            /> */}

                            <Route path="/onboarding/" isPrivate component={() => <Onboarding />} />

                            <Route
                              isPrivate
                              path="/:storeAliasId/subscription/:checkoutStep"
                              component={Checkout}
                            />

                            <Route
                              isPrivate
                              exact
                              path="/:storeAliasId/checkout-finished"
                              component={CheckoutFinished}
                            />

                            <Route
                              path="/:storeAliasId/dashboard/"
                              isPrivate
                              component={() => <Dashboard />}
                            />

                            <Route path="/consent/" isPrivate component={() => <Consent />} />

                            <Route
                              path="/:storeAliasId/downgrade/"
                              isPrivate
                              component={() => <Downgrade />}
                            />
                          </Switch>
                        </RetrospectiveDataProvider>
                        <CookiesUsage />
                        <GlobalStyle />
                        <Toaster containerStyle={{ zIndex: 999999 }} />
                        <Synchronization />
                      </DowngradeProvider>
                    </SynchronizationProvider>
                  </ToastProvider>
                </ThemeProvider>
              </SubscriptionProvider>
            </ConfigProvider>
          </GoogleOAuthProvider>
        </DateProvider>
      </BrowserRouter>
    </div>
  );
};

export default React.memo(App);
