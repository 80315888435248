import React from 'react';
import { Ticket, Roi, ProfitMargin } from '@profitfy/pakkun-icons';

import { useProductDetails } from '@helpers/hooks/pages/dashboard/productAnalytics/useProductDetails';
import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const ProductDataCard: React.FC = () => {
  const { productFinancialDetailsReport, productFinancialReport } = useProductDetails();

  const { net_revenue, cogs, marketing, fee } = productFinancialReport;
  const { average_ticket, profit_margin } = productFinancialDetailsReport;

  const totalCost = cogs.amount + marketing.amount + fee.amount;

  const roi = ((net_revenue.amount - totalCost) / totalCost) * 100;

  return (
    <S.Card>
      <S.Content>
        <S.MetricWrapper>
          <S.IconWrapper>
            <Roi size={24} outline />
          </S.IconWrapper>

          <S.MetricTextWrapper>
            <S.MetricValue>{`${numberFormatter(roi || 0, 2)}%`}</S.MetricValue>
            <S.MetricName>Retorno Sobre Investimento</S.MetricName>
          </S.MetricTextWrapper>
        </S.MetricWrapper>

        <S.MetricWrapper>
          <S.IconWrapper>
            <ProfitMargin size={24} outline />
          </S.IconWrapper>

          <S.MetricTextWrapper>
            <S.MetricValue>{`${numberFormatter(profit_margin.amount, 2)}%`}</S.MetricValue>
            <S.MetricName>Margem de Lucro</S.MetricName>
          </S.MetricTextWrapper>
        </S.MetricWrapper>

        <S.MetricWrapper>
          <S.IconWrapper>
            <Ticket size={24} outline />
          </S.IconWrapper>

          <S.MetricTextWrapper>
            <S.MetricValue>{`R$ ${numberFormatter(average_ticket.amount, 2)}`}</S.MetricValue>
            <S.MetricName>Ticket Médio</S.MetricName>
          </S.MetricTextWrapper>
        </S.MetricWrapper>
      </S.Content>
    </S.Card>
  );
};

export default ProductDataCard;
