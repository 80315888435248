export const SOCIAL_MEDIA = {
  facebook: {
    url: 'https://www.facebook.com/profitfy.me/',
    src: 'https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/pricing/footer/facebook.svg',
    alt: 'profitfy-facebook-social-media',
  },
  instagram: {
    url: 'https://www.instagram.com/profitfy.me/',
    src: 'https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/pricing/footer/instagram.svg',
    alt: 'profitfy-instagram-social-media',
  },
  linkedin: {
    url: 'https://www.linkedin.com/company/profitfy-me/about/',
    src: 'https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/pricing/footer/linkedin.svg',
    alt: 'profitfy-linkedin-social-media',
  },
  youtube: {
    url: 'https://www.youtube.com/channel/UCkR88gvakxVMggrTuLGlh5A',
    src: 'https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/pricing/footer/youtube.svg',
    alt: 'profitfy-youtube-social-media',
  },
};
