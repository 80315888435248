import {
  CartPanda,
  Hotmart,
  Kiwify,
  NuvemShop,
  Shopify,
  Yampi,
  Zouti,
} from '@profitfy/pakkun-icons';

import { ETriggers } from '@domain/enums/dashboard/automations/ETriggers';

export const INTEGRATIONS = [
  {
    id: 'YAMPI',
    name: 'Yampi',
    icon: Yampi,
    triggers: [
      ETriggers.ABANDONED_CART,
      ETriggers.PIX,
      ETriggers.BOLETO,
      // ETriggers.BOLETO_RECOVER,
      ETriggers.REJECTED_PAYMENT,
      // ETriggers.UPDATED_FULFILLMENT,
      // ETriggers.PIX_RECOVER,
      ETriggers.SIMPLE_SHIPMENT,
      ETriggers.UPDATED_FULFILLMENT,
    ],
  },
  {
    id: 'CART_PANDA',
    name: 'CartPanda',
    icon: CartPanda,
    triggers: [
      ETriggers.ABANDONED_CART,
      ETriggers.PIX,
      ETriggers.BOLETO,
      ETriggers.REJECTED_PAYMENT,
      ETriggers.SIMPLE_SHIPMENT,
      ETriggers.UPDATED_FULFILLMENT,
    ],
  },
  {
    id: 'ZOUTI',
    name: 'Zouti',
    icon: Zouti,
    triggers: [
      ETriggers.ABANDONED_CART,
      ETriggers.BOLETO,
      ETriggers.PIX,
      ETriggers.SIMPLE_SHIPMENT,
    ],
  },
  {
    id: 'HOTMART',
    name: 'Hotmart',
    icon: Hotmart,
    triggers: [
      ETriggers.ABANDONED_CART,
      ETriggers.PIX,
      ETriggers.BOLETO,
      ETriggers.REJECTED_PAYMENT,
    ],
  },
  {
    id: 'NUVEM_SHOP',
    name: 'NuvemShop',
    icon: NuvemShop,
    triggers: [ETriggers.ABANDONED_CART, ETriggers.PIX, ETriggers.BOLETO],
  },
  // {
  //   id: 'SHOPIFY',
  //   name: 'Shopify',
  //   icon: Shopify,
  //   triggers: [
  //     ETriggers.PIX,
  //     ETriggers.BOLETO,
  //     ETriggers.SIMPLE_SHIPMENT,
  //     ETriggers.UPDATED_FULFILLMENT,
  //   ],
  // },
  {
    id: 'KIWIFY',
    name: 'Kiwify',
    icon: Kiwify,
    triggers: [ETriggers.ABANDONED_CART, ETriggers.BOLETO, ETriggers.PIX],
  },
];
