import React from 'react';

import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { EHeadingSize } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';
import { IShopifyCredentialOptionProps } from '@domain/interfaces/dashboard/IntegrationCenter/SideModalContent/StoreProvider/IShopifyCredentialOption';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useToast } from '@helpers/hooks/useToast';
import { updateShopifySchema } from '@helpers/validators/dashboard/integrationCenter/shopifyCredentials';
import { yupResolver } from '@hookform/resolvers/yup';

import shopifyAuthService from '@services/pages/dashboard/integrationCenter/marketplace/shopify/shopifyAuth';

import Text from '@components/common/core/DataDisplay/Text';
import Form from '@components/common/core/Inputs/Form';

import DeleteCredentialModal from '@components/Dashboard/IntegrationCenter/SideModalContent/DeleteCredentialModal';
import * as S from './styles';

const CredentialOption: React.FC<IShopifyCredentialOptionProps> = ({ storeDomain }) => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { mutateStore } = useStoreConfig();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(updateShopifySchema),
  });

  const [isDeletingCredential, setIsDeletingCredential] = React.useState<boolean>(false);
  const [isUpdatingCredential, setIsUpdatingCredential] = React.useState<boolean>(false);
  const [isDeleteCredentialModalOpen, setIsDeleteCredentialModalOpen] = React.useState<boolean>(
    false,
  );

  const handleDeleteCredentialModalOpen = React.useCallback(
    () => setIsDeleteCredentialModalOpen(state => !state),
    [],
  );

  const onDeleteCredential = React.useCallback(async () => {
    setIsDeletingCredential(true);

    try {
      await shopifyAuthService.disableCredential(storeAliasId);

      toast.success('Credencial desativada com sucesso!');

      await mutateStore();
      handleDeleteCredentialModalOpen();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsDeletingCredential(false);
    }
  }, [handleDeleteCredentialModalOpen, mutateStore, storeAliasId, toast]);

  const onSubmit = React.useCallback(
    async formData => {
      setIsUpdatingCredential(true);

      try {
        await shopifyAuthService.updateCredential({
          storeAliasId,
          data: {
            access_token: formData.access_token,
            secret_api_key: formData.secret_key,
            is_active: true,
          },
        });

        mutateStore();

        toast.success('Credencial atualizada com sucesso.');

        setIsUpdatingCredential(false);
      } catch (error: any) {
        setIsUpdatingCredential(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [mutateStore, storeAliasId, toast],
  );

  return (
    <S.Content>
      <S.CredentialDescriptionWrapper>
        <S.CredentialDescriptionTitle type={EHeadingSize.H5}>
          O que acontece se eu desativar minha integração?
        </S.CredentialDescriptionTitle>

        <S.CredentialDescriptionText>
          Ao desativar a integração,
          <strong> não será mais possível </strong>
          processar os pedidos da Shopify.
        </S.CredentialDescriptionText>
      </S.CredentialDescriptionWrapper>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.UpdateCredentialHeading type={EHeadingSize.H5}>
          Atualizar Credencial
        </S.UpdateCredentialHeading>

        <S.InputGroup>
          <S.Label htmlFor="shopify-store-url">URL da Loja</S.Label>

          <S.Input readOnly type="text" id="shopify-store-url" defaultValue={storeDomain} />
        </S.InputGroup>

        <S.InputGroup>
          <S.Label htmlFor="shopify-access-token">Token de Acesso da API Admin</S.Label>

          <S.Input
            {...register('access_token')}
            type="text"
            id="shopify-access-token"
            placeholder="Token de Acesso da API Admin"
            defaultValue="**************************"
          />
          {errors.access_token && <Text isErrorFeedback>{errors.access_token.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label htmlFor="shopify-secret-key">Chave secreta da API</S.Label>

          <S.Input
            {...register('secret_key')}
            type="text"
            id="shopify-secret-key"
            placeholder="Chave secreta da API"
            defaultValue="**************************"
          />
          {errors.secret_key && <Text isErrorFeedback>{errors.secret_key.message}</Text>}
        </S.InputGroup>

        <S.UpdateAndDisableButtonBackground>
          <S.UpdateAndDisableButtonsWrapper>
            <S.UpdateCredentialButton
              type="submit"
              isLoading={isUpdatingCredential}
              disabled={isUpdatingCredential}
            >
              Atualizar credencial
            </S.UpdateCredentialButton>

            <S.DisableCredentialButton
              type="button"
              onClick={handleDeleteCredentialModalOpen}
              disabled={isDeletingCredential}
              isLoading={isDeletingCredential}
              variant={EButtonVariant.SECONDARY}
            >
              Desativar credencial
            </S.DisableCredentialButton>
          </S.UpdateAndDisableButtonsWrapper>
        </S.UpdateAndDisableButtonBackground>
      </Form>

      <DeleteCredentialModal
        isOpen={isDeleteCredentialModalOpen}
        toggle={handleDeleteCredentialModalOpen}
        onDeleteCredential={onDeleteCredential}
        isLoading={isDeletingCredential}
      />
    </S.Content>
  );
};

export default CredentialOption;
