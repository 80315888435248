import React from 'react';

import { NuvemShop as NuvemShopIcon } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { useToast } from '@helpers/hooks/useToast';
import { newWindow } from '@helpers/hooks/usePopup';
import { IParams } from '@domain/interfaces/IParams';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import nuvemShopAuthService from '@services/pages/dashboard/integrationCenter/marketplace/nuvemShop/nuvemShopAuth';
import ActiveIntegrationFooter from '@components/Dashboard/IntegrationCenter/Sidemodal/ActiveIntegrationFooter';

import * as S from './styles';

const NuvemShop: React.FC = () => {
  const theme: any = useTheme();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { mutateStore } = useStoreConfig();

  const [isIntegrating, setIsIntegrating] = React.useState<boolean>(false);
  const [userWindow, setUserWindow] = React.useState<Window | undefined>(undefined);

  const onIntegrateClick = React.useCallback(async () => {
    setIsIntegrating(true);

    try {
      const { data: nuvemShopData } = await nuvemShopAuthService.generateURL({ storeAliasId });

      const window = newWindow(nuvemShopData?.auth_url, 'NuvemShop', 1280, 720);

      setUserWindow(window);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsIntegrating(false);
    }
  }, [storeAliasId, toast]);

  // React.useEffect(() => {
  //   if (userWindow) {
  //     userWindow.addEventListener('beforeunload', () => {
  //       mutateStoreIntegrations();
  //     });
  //   }
  // }, [userWindow, mutateStoreIntegrations]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (userWindow?.closed) {
        clearInterval(interval);
        mutateStore();
      }
    }, 1000);
  }, [userWindow, mutateStore]);

  return (
    <S.ContentWrapper>
      <S.Header>
        <NuvemShopIcon size={52} color={theme.colors.gray[2]} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com NuvemShop
        </S.Heading>
      </S.Header>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>
        <S.FunctionalityDescriptionText>
          A integração com sua plataforma de ecommerce serve para puxar seus dados de loja.
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <S.TutorialWrapper>
        <S.TutorialHeading type={EHeadingSize.H5}>Tutorial de integração</S.TutorialHeading>
        <S.TutorialText>Basta clicar no botão abaixo e integrar com a plataforma.</S.TutorialText>
      </S.TutorialWrapper>

      <ActiveIntegrationFooter onIntegrateClick={onIntegrateClick} isIntegrating={isIntegrating} />
    </S.ContentWrapper>
  );
};

export default NuvemShop;
