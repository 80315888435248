import React from 'react';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';

import { useCampaigns } from '@helpers/hooks/pages/dashboard/adsManager/useCampaigns';

import * as S from './styles';

const Insights: React.FC<ICampaignProps> = () => {
  const { adSenseCampaignsReport } = useCampaigns();

  const noUtmInsightsQuantity = adSenseCampaignsReport.filter(
    campaign => campaign.has_all_ads_with_utm_synchronized === false,
  );

  return (
    <S.Wrapper>
      <S.InsightsQuantity>{noUtmInsightsQuantity.length}</S.InsightsQuantity>
      <S.InsightsLabel>Insights</S.InsightsLabel>
    </S.Wrapper>
  );
};

export default Insights;
