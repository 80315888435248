import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ClapSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components/macro';

import { IParams } from '@domain/interfaces/IParams';
import { EType } from '@domain/enums/onboarding/EType';
import { EStep } from '@domain/enums/onboarding/EStep';
import { IOnboardingStep } from '@domain/interfaces/onboarding/IOnboarding';

import onboardingStepService from '@services/pages/onboarding/onboardingStep';

import PageError from '@components/Stores/PageError';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const theme: any = useTheme();
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const {
    onboardingSteps,
    error,
    mutate,
    isValidating,
  } = onboardingStepService.getOnboardingSteps({ storeAliasId });

  const handleDropshippingInternationalSteps = React.useCallback(
    (allSteps: Array<IOnboardingStep>): undefined => {
      const foundStoreOnboardingFinished = allSteps.find(
        step => step.configuration_step === EStep.STORE_ONBOARDING_FINISHED,
      );
      const foundStoreDsersConfig = allSteps.find(
        step => step.configuration_step === EStep.STORE_DSERS_CONFIG,
      );
      const foundStoreCheckoutFeeConfig = allSteps.find(
        step => step.configuration_step === EStep.STORE_CHECKOUT_FEE_CONFIG,
      );
      const foundStoreIntegrations = allSteps.find(
        step => step.configuration_step === EStep.STORE_INTEGRATIONS,
      );

      if (foundStoreOnboardingFinished && foundStoreOnboardingFinished.is_completed) {
        history.push(`/${storeAliasId}/dashboard/overview/financial`);

        return undefined;
      }

      if (
        foundStoreOnboardingFinished ||
        (foundStoreDsersConfig && foundStoreDsersConfig.is_completed)
      ) {
        history.push(`/onboarding/${storeAliasId}/finished`);

        return undefined;
      }

      if (
        foundStoreDsersConfig ||
        (foundStoreCheckoutFeeConfig && foundStoreCheckoutFeeConfig.is_completed)
      ) {
        history.push(`/onboarding/${storeAliasId}/dropshipping/dsers`);

        return undefined;
      }

      if (
        foundStoreCheckoutFeeConfig ||
        (foundStoreIntegrations && foundStoreIntegrations.is_completed)
      ) {
        history.push(`/onboarding/${storeAliasId}/dropshipping/checkout-fee`);

        return undefined;
      }

      history.push(`/onboarding/${storeAliasId}/dropshipping/integrations`);

      return undefined;
    },
    [history, storeAliasId],
  );

  const handleEcommerceSteps = React.useCallback(
    (allSteps: Array<IOnboardingStep>): undefined => {
      const foundStoreOnboardingFinished = allSteps.find(
        step => step.configuration_step === EStep.STORE_ONBOARDING_FINISHED,
      );

      if (foundStoreOnboardingFinished && foundStoreOnboardingFinished.is_completed) {
        history.push(`/${storeAliasId}/dashboard/overview/financial`);

        return undefined;
      }

      if (foundStoreOnboardingFinished) {
        history.push(`/onboarding/${storeAliasId}/finished`);

        return undefined;
      }

      history.push(`/onboarding/${storeAliasId}/ecommerce/integrations`);

      return undefined;
    },
    [history, storeAliasId],
  );

  React.useEffect(() => {
    if (onboardingSteps) {
      if (!onboardingSteps.length) {
        history.push(`/onboarding/${storeAliasId}/dropshipping/integrations`);
      } else {
        const { type } = onboardingSteps[0];

        if (type === EType.DROPSHIPPING_INTERNATIONAL) {
          handleDropshippingInternationalSteps(onboardingSteps);
        }

        if (type === EType.TRADITIONAL_ECOMMERCE) {
          handleEcommerceSteps(onboardingSteps);
        }
      }
    }
  }, [
    onboardingSteps,
    handleDropshippingInternationalSteps,
    history,
    storeAliasId,
    handleEcommerceSteps,
  ]);

  if (error && !isValidating) {
    return <PageError mutate={mutate} />;
  }

  return (
    <S.LoadingWrapper>
      <ClapSpinner size={48} frontColor={theme.colors.green.default} />
    </S.LoadingWrapper>
  );
};

export default Wrapper;
