import axios, { AxiosResponse } from 'axios';
import { differenceInSeconds } from 'date-fns';

import { ESynchronizationStatus, ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import {
  ISynchronization,
  ISynchronizations,
} from '@domain/interfaces/hooks/IStoreSynchronization';
import synchronizationService from '@services/hooks/synchronization';

export const buildNewSynchronizationPromise = (
  synchronization: ISynchronization,
): Promise<AxiosResponse<any>> => {
  const { request } = synchronization;

  if (request.type === 'GET') {
    return axios.get(`${request.domain}${request.api}${request.requestParams || ''}`);
  }

  if (request.type === 'POST') {
    return axios.post(`${request.domain}${request.api}${request.requestParams || ''}`, {
      ...request.requestBody,
    });
  }

  if (request.type === 'PUT') {
    return axios.put(`${request.domain}${request.api}${request.requestParams || ''}`, {
      ...request.requestBody,
    });
  }

  return axios.delete(`${request.domain}${request.api}${request.requestParams || ''}`);
};

export const handleCurrentSynchronizationsStatuses = (
  updatedSynchronizations: Array<ISynchronization>,
  currentSynchronizationsToAdd: Array<ISynchronization>,
  finishedSynchronizationsToAdd: Array<ISynchronization>,
  failedSynchronizationsToAdd: Array<ISynchronization>,
): void => {
  updatedSynchronizations.forEach(updatedSynchronization => {
    if (updatedSynchronization.status === ESynchronizationStatus.COMPLETED)
      finishedSynchronizationsToAdd.push(updatedSynchronization);

    if (updatedSynchronization.status === ESynchronizationStatus.ERROR)
      failedSynchronizationsToAdd.push(updatedSynchronization);

    if (updatedSynchronization.status === ESynchronizationStatus.PROCESSING)
      currentSynchronizationsToAdd.push(updatedSynchronization);
  });
};

export const handleSynchronizationsInQueueToBeAddedToSync = (
  synchronizationsInQueue: Array<ISynchronization>,
  currentSynchronizationsToAdd: Array<ISynchronization>,
): void => {
  synchronizationsInQueue.forEach(synchronizationInQueue => {
    if (currentSynchronizationsToAdd.length) {
      const filteredCurrentSynchronizationsToAddByStore = currentSynchronizationsToAdd.filter(
        currentSynchronization =>
          currentSynchronization.storeAliasId === synchronizationInQueue.storeAliasId,
      );

      const mappedFilteredCurrentSynchronizationsToAddByStore = filteredCurrentSynchronizationsToAddByStore.map(
        currentSynchronization => currentSynchronization.type,
      );

      const isAbleToSyncComparedToCurrentSynchronizationsToAdd = !mappedFilteredCurrentSynchronizationsToAddByStore.some(
        currentSynchronization =>
          synchronizationInQueue.dependencies.includes(currentSynchronization),
      );

      if (isAbleToSyncComparedToCurrentSynchronizationsToAdd) {
        currentSynchronizationsToAdd.push({
          ...synchronizationInQueue,
          status: ESynchronizationStatus.PROCESSING,
        });
      }
    } else {
      currentSynchronizationsToAdd.push({
        ...synchronizationInQueue,
        status: ESynchronizationStatus.PROCESSING,
      });
    }
  });
};

export const getNewSynchronizationsQueue = (
  synchronizationsInQueue: Array<ISynchronization>,
  currentSynchronizationsToAdd: Array<ISynchronization>,
): Array<ISynchronization> => {
  const newSynchronizationsQueue = synchronizationsInQueue.filter(synchronizationInQueue => {
    const hasSynchronizationInCurrentSynchronizationsToAdd = currentSynchronizationsToAdd.find(
      synchronizationToAdd => {
        const isSameName = synchronizationToAdd.name === synchronizationInQueue.name;
        const isSameType = synchronizationToAdd.type === synchronizationInQueue.type;
        const isSameStore =
          synchronizationToAdd.storeAliasId === synchronizationInQueue.storeAliasId;
        const isSameCreatedAt = synchronizationToAdd.createdAt === synchronizationInQueue.createdAt;

        return isSameName && isSameType && isSameStore && isSameCreatedAt;
      },
    );

    return !hasSynchronizationInCurrentSynchronizationsToAdd;
  });

  return newSynchronizationsQueue;
};

export const waitForNextSync = async (
  currentSynchronizations: ISynchronizations,
  duration: number,
): Promise<void> => {
  if (currentSynchronizations.ping) {
    const dateNow = new Date();
    const dateToCompare = new Date(currentSynchronizations.ping);

    const difference = differenceInSeconds(dateNow, dateToCompare);

    if (difference < duration / 1000) {
      await new Promise(resolver => setTimeout(resolver, duration - difference * 1000));
    }
  }
};

export const buildSynchronizationsPromises = (
  synchronizations: Array<ISynchronization>,
): Array<Promise<any>> => {
  const promises = synchronizations.map(synchronization => {
    if (synchronization.id && synchronization.type === ESynchronizationType.DEMO_STORE) {
      return synchronizationService.getDemoSynchronization({
        synchronization_id: synchronization.id,
      });
    }

    if (synchronization.id) {
      return synchronizationService.getSynchronization({
        storeAliasId: synchronization.storeAliasId,
        synchronization_id: synchronization.id,
      });
    }

    const newSynchronizationPromise = buildNewSynchronizationPromise(synchronization);

    return newSynchronizationPromise;
  });

  return promises;
};
