import React from 'react';

import { useParams } from 'react-router-dom';

import { ECartPandaType } from '@domain/enums/common/integrations/providers/ECartPanda';
import { EHeadingSize } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';
import { ICartPandaCredentialOptionProps } from '@domain/interfaces/dashboard/IntegrationCenter/SideModalContent/StoreProvider/ICartPandaCredentialOption';

import { useToast } from '@helpers/hooks/useToast';

import cartPandaAuthService from '@services/pages/dashboard/integrationCenter/storeProvider/cartPanda/cartPandaAuth';

import Save from '@components/Dashboard/IntegrationCenter/SideModalContent/Save';

import DeleteCredentialModal from '@components/Dashboard/IntegrationCenter/SideModalContent/DeleteCredentialModal';
import * as S from './styles';

const CredentialOption: React.FC<ICartPandaCredentialOptionProps> = ({
  cartPandaCredential,
  mutate,
}) => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();

  const [isDisablingCredential, setIsDisablingCredential] = React.useState<boolean>(false);
  const [isDeleteCredentialModalOpen, setIsDeleteCredentialModalOpen] = React.useState<boolean>(
    false,
  );

  const handleDeleteCredentialModalOpen = React.useCallback(
    () => setIsDeleteCredentialModalOpen(state => !state),
    [],
  );

  const onDeleteCredential = React.useCallback(async () => {
    setIsDisablingCredential(true);

    const newTypes = cartPandaCredential.types.filter(type => type !== ECartPandaType.STORE);

    try {
      await cartPandaAuthService.updateCartPandaCredential({
        storeAliasId,
        cartPandaCredentialAliasId: cartPandaCredential.alias_id,
        data: { types: [...newTypes] },
      });

      toast.success('Credencial desativada com sucesso!');

      setIsDisablingCredential(false);
      handleDeleteCredentialModalOpen();

      mutate();
    } catch (error: any) {
      setIsDisablingCredential(false);
      toast.error(error?.response?.data?.message);
    }
  }, [mutate, storeAliasId, toast, cartPandaCredential, handleDeleteCredentialModalOpen]);

  return (
    <S.Content>
      <S.CredentialDescriptionWrapper>
        <S.CredentialDescriptionTitle type={EHeadingSize.H5}>
          O que acontece se eu desativar minha integração?
        </S.CredentialDescriptionTitle>
        <S.CredentialDescriptionText>
          Ao desativar a integração,
          <strong> não será mais possível </strong>
          processar os pedidos da CartPanda.
        </S.CredentialDescriptionText>
      </S.CredentialDescriptionWrapper>

      <Save
        hasCredentials
        deleteText="Desativar credencial"
        onDelete={handleDeleteCredentialModalOpen}
        isDeleteButtonDisabled={isDisablingCredential}
        isDeleting={isDisablingCredential}
      />

      <DeleteCredentialModal
        isOpen={isDeleteCredentialModalOpen}
        toggle={handleDeleteCredentialModalOpen}
        onDeleteCredential={onDeleteCredential}
        isLoading={isDisablingCredential}
      />
    </S.Content>
  );
};

export default CredentialOption;
