import styled from 'styled-components/macro';

import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import SelectBase from '@components/common/core/Inputs/Select';
import DayPickerBase from '@components/common/core/Utils/DayPicker';

export const Select = styled(SelectBase)`
  & > label > select {
    width: 508px;
  }
`;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)``;

export const InputGroup = styled(InputGroupBase)`
  max-width: 508px;
  width: 100%;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 32px;
  }
`;

export const InputGroupWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const DayPicker = styled(DayPickerBase)`
  width: 100%;
`;
