import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding-left: 128px;
  padding-right: 32px;
  width: 100%;
  min-height: calc(100vh - 86px);
  background-color: ${({ theme }) => theme.colors.background[1]};
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
  overflow-x: hidden;

  @media only screen and (max-width: 768px) {
    padding: 0 20px 96px 20px;
  }
`;

export const OnboardingSetupWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  pointer-events: none;
`;
