import styled from 'styled-components/macro';

export const ErrorWrapper = styled.div`
  background-color: #000;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  background-color: #000;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;

  @media only screen and (max-width: 885px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
`;
