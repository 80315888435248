import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const SubmitButton = styled(ButtonBase)``;

export const Description = styled(TextBase)`
  font-size: 1rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Title = styled(TextBase)`
  font-size: 1.125rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[1]};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Wrapper = styled.div`
  max-width: 408px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Modal = styled(ModalBase)``;
