import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';

export const Table = styled(TableBase)`
  margin-top: 12px;
  tr:nth-child(even) {
    background-color: #2b2e38;
  }
`;
