import React from 'react';

import * as S from './styles';

const EmptyScreen: React.FC = () => {
  return (
    <S.Container>
      <S.Title>Parabéns, você não tem nenhum aviso.</S.Title>
      <S.Image />
    </S.Container>
  );
};

export default EmptyScreen;
