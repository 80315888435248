import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';
import {
  IGetDefaultTaxProps,
  IGetDefaultTaxResponse,
  IListHistoricTaxesProps,
  IListHistoricTaxesResponse,
  ICreateDefaultTaxProps,
  ICreatePeriodTaxProps,
  IUpdateDefaultTaxProps,
  IUpdatePeriodTaxProps,
  IDeleteTaxProps,
} from '@domain/interfaces/dashboard/TaxesAndFees/Taxes/ITaxes';

export class TaxService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getDefaultTax({ storeAliasId }: IGetDefaultTaxProps): IGetDefaultTaxResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/taxes/default`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      tax: data?.tax,
      error,
      mutate,
      isLoading,
      isValidating,
    };
  }

  public listHistoricTaxes({
    storeAliasId,
    page,
  }: IListHistoricTaxesProps): IListHistoricTaxesResponse {
    const limit = 10;

    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/taxes?limit=${limit}&page=${page + 1}`,
      this.apiService,
    );

    const isLoading = !error && !data;

    return {
      taxes: data?.taxes,
      totalPages: data?.total_pages,
      error,
      mutate,
      isLoading,
      isValidating,
    };
  }

  public createDefaultTax({ storeAliasId, data }: ICreateDefaultTaxProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/taxes/default`, {
      ...data,
    });
  }

  public createPeriodTax({ storeAliasId, data }: ICreatePeriodTaxProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/taxes/period`, {
      ...data,
    });
  }

  public updateDefaultTax({
    storeAliasId,
    taxAliasId,
    data,
  }: IUpdateDefaultTaxProps): Promise<any> {
    return this.apiService.put(`/api/v1/users/stores/${storeAliasId}/taxes/${taxAliasId}/default`, {
      ...data,
    });
  }

  public updatePeriodTax({ storeAliasId, taxAliasId, data }: IUpdatePeriodTaxProps): Promise<any> {
    return this.apiService.put(`/api/v1/users/stores/${storeAliasId}/taxes/${taxAliasId}/period`, {
      ...data,
    });
  }

  public deleteTax({ storeAliasId, taxAliasId }: IDeleteTaxProps): Promise<any> {
    return this.apiService.delete(`/api/v1/users/stores/${storeAliasId}/taxes/${taxAliasId}`);
  }
}

const taxService = new TaxService(API_DOMAIN);

export default taxService;
