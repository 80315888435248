import React from 'react';

import { EHeadAlign } from '@domain/enums/components/ETable';

import MarketingTooltip from './MarketingTooltip';

import * as S from './styles';

const Table: React.ForwardRefRenderFunction<HTMLTableElement> = ({ children, ...rest }, ref) => {
  return (
    <S.RankingTable ref={ref} {...rest}>
      <S.Table.Header>
        <S.Table.Row>
          <S.Table.Head />
          <S.Table.Head align={EHeadAlign.CENTER}>Produto</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Receita Líquida</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Pendentes</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>C. Produto</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>
            <MarketingTooltip />
          </S.Table.Head>
          {/* <S.Table.Head align={EHeadAlign.CENTER}>Margem de Lucro</S.Table.Head> */}
          <S.Table.Head align={EHeadAlign.RIGHT}>Impostos e Tarifas</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>CPA</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Conv. Boleto</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Lucro Líquido</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER} />
          <S.Table.Head align={EHeadAlign.CENTER} />
        </S.Table.Row>
      </S.Table.Header>

      <S.Table.Body>{children}</S.Table.Body>
    </S.RankingTable>
  );
};

export default React.forwardRef(Table);
