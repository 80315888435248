import styled from 'styled-components/macro';

import { ISelectedBackgroundProps } from '@domain/interfaces/components/ITab';

export const SelectedBackground = styled.div<ISelectedBackgroundProps>`
  position: absolute;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  background-color: green;
`;

export const OptionWrapper = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  gap: 12px;
`;
