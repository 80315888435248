import styled from 'styled-components/macro';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import LabelBase from '@components/common/core/DataDisplay/Label';
import ButtonBase from '@components/common/core/Inputs/Button';
import FormBase from '@components/common/core/Inputs/Form';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import SelectBase from '@components/common/core/Inputs/Select';

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  gap: 16px;
`;

export const Heading = styled(HeadingBase)``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 12px;
  padding: 24px;
`;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)``;

export const Button = styled(ButtonBase)`
  width: fit-content;
  margin-left: auto;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 135px;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  border-radius: 4px;
  padding: 10px;
  resize: none;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.gray[2]};
  font-size: 0.875rem;
  border-color: ${({ theme }) => theme.colors.gray[4]};

  &:focus {
    outline: none;
  }
`;

export const Form = styled(FormBase)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Option = styled(OptionBase)``;

export const Select = styled(SelectBase)`
  width: 100%;
`;
