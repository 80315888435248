import React from 'react';

import { useParams } from 'react-router-dom';
import { IParams } from '@domain/interfaces/IParams';
import { useToast } from '@helpers/hooks/useToast';
import { EShippingAmountType, EShippingType } from '@domain/enums/dashboard/shipping/EShipping';
import { IShippingProvider } from '@domain/interfaces/dashboard/Shipping/IShipping';
import { currencyToNumber, numberFormatter } from '@helpers/masks';

import shippingService from '@services/pages/dashboard/shipping/shipping';

const ShippingContext = React.createContext<IShippingProvider | null>(null);

export const ShippingProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();

  const [isSavingConfig, setIsSavingConfig] = React.useState<boolean>(false);
  const [isConfigShippingModalOpen, setIsConfigShippingModalOpen] = React.useState<boolean>(false);
  const [shippingAmount, setShippingAmount] = React.useState<string>('');
  const [amountType, setAmountType] = React.useState<EShippingAmountType>(
    EShippingAmountType.FIXED,
  );

  const {
    shipping,
    isLoading: isLoadingShipping,
    isValidating: isValidatingShipping,
    mutate: mutateShipping,
  } = shippingService.getShipping({ storeAliasId });

  const handleShippingAmount = React.useCallback(newValue => {
    setShippingAmount(newValue);
  }, []);

  const handleShippingAmountType = React.useCallback(newValue => {
    setAmountType(newValue);
  }, []);

  const handleConfigShippingModalOpen = React.useCallback(() => {
    setIsConfigShippingModalOpen(currentState => !currentState);
  }, []);

  const deleteShipping = React.useCallback(async () => {
    setIsSavingConfig(true);

    try {
      if (!shipping?.alias_id) {
        setIsSavingConfig(false);
        return;
      }

      await shippingService.deleteShippingDefault({
        storeAliasId,
        shippingAliasId: shipping?.alias_id,
      });

      await mutateShipping();

      toast.success('Configuração de frete salva com sucesso.');

      setIsSavingConfig(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsSavingConfig(false);
    }
  }, [mutateShipping, shipping, storeAliasId, toast]);

  const updatingShipping = React.useCallback(
    async shippingType => {
      setIsSavingConfig(true);

      try {
        await shippingService.updateShippingDefault({
          storeAliasId,
          shippingAliasId: shipping?.alias_id,
          data: {
            amount:
              shippingType === EShippingType.AVERAGE_BY_ORDER
                ? currencyToNumber(shippingAmount)
                : 0,
            amount_type: amountType,
            type: shippingType,
          },
        });

        await mutateShipping();

        toast.success('Configuração de frete salva com sucesso.');

        setIsSavingConfig(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsSavingConfig(false);
      }
    },
    [amountType, toast, storeAliasId, mutateShipping, shipping, shippingAmount],
  );

  const createShipping = React.useCallback(
    async (shippingType: EShippingType) => {
      setIsSavingConfig(true);

      try {
        await shippingService.createShippingDefault({
          storeAliasId,
          data: {
            amount:
              shippingType === EShippingType.AVERAGE_BY_ORDER
                ? currencyToNumber(shippingAmount)
                : 0,
            amount_type: amountType,
            type: shippingType,
          },
        });

        await mutateShipping();

        toast.success('Configuração de frete criada com sucesso.');

        setIsSavingConfig(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsSavingConfig(false);
      }
    },
    [amountType, toast, storeAliasId, mutateShipping, shippingAmount],
  );

  const upsertShipping = React.useCallback(
    (shippingType: EShippingType) => {
      if (shipping?.id) {
        updatingShipping(shippingType);
      } else {
        createShipping(shippingType);
      }
    },
    [updatingShipping, createShipping, shipping],
  );

  React.useEffect(() => {
    if (shipping && shipping?.type === EShippingType.AVERAGE_BY_ORDER) {
      const formattedAmount = numberFormatter(shipping?.amount, 2);

      setShippingAmount(formattedAmount);
      setAmountType(shipping.amount_type);
    } else {
      setShippingAmount('');
    }
  }, [shipping]);

  return (
    <ShippingContext.Provider
      value={{
        shipping,
        isLoadingShipping,
        isValidatingShipping,
        mutateShipping,
        isSavingConfig,
        upsertShipping,
        handleShippingAmount,
        handleShippingAmountType,
        handleConfigShippingModalOpen,
        deleteShipping,
        amountType,
        isConfigShippingModalOpen,
        shippingAmount,
      }}
    >
      {children}
    </ShippingContext.Provider>
  );
};

export const useShipping = (): IShippingProvider => {
  const context = React.useContext(ShippingContext);

  if (!context) {
    throw new Error('useShipping must be used within ShippingContext');
  }

  return context;
};
