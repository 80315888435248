import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { USER_API_DOMAIN } from '@constants/api';
import {
  ICreateLegalPersonProps,
  IDeleteLegalPersonProps,
  IGetLegalPersonProps,
  IGetLegalPersonResponse,
  IUpdateLegalPersonProps,
} from '@domain/interfaces/subscription/ILegalPerson';

export class LegalPersonService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getLegalPerson({ storeAliasId }: IGetLegalPersonProps): IGetLegalPersonResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/legal-people`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      legalPerson: data?.legal_person,
      error,
      mutate,
      isLoading,
      isValidating,
    };
  }

  public createLegalPerson({ data, storeAliasId }: ICreateLegalPersonProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/legal-people`, { ...data });
  }

  public updateLegalPerson({ data, storeAliasId }: IUpdateLegalPersonProps): Promise<any> {
    return this.apiService.put(`/api/v1/users/stores/${storeAliasId}/legal-people`, { ...data });
  }

  public deleteLegalPerson({ storeAliasId }: IDeleteLegalPersonProps): Promise<any> {
    return this.apiService.delete(`/api/v1/users/stores/${storeAliasId}/legal-people`);
  }
}

const legalPersonService = new LegalPersonService(USER_API_DOMAIN);

export default legalPersonService;
