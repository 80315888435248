import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import ModalBase from '@components/common/core/DataDisplay/Modal';
import ButtonBase from '@components/common/core/Inputs/Button';
import Alert from '@components/common/core/DataDisplay/Alert';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';

export const UpdateInfoButton = styled(ButtonBase)`
  margin-top: 32px;
  margin-left: auto;
`;

export const InstallmentAlert = styled(Alert)`
  background-color: #221e18;
  padding: 8px 10px;

  & > div {
    & > span:last-of-type {
      line-height: 150%;
    }
  }
`;

export const Option = styled(OptionBase)``;

export const Select = styled(SelectBase)`
  width: 100%;
`;

export const ExpirationDateAndSecurityCodeWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

export const Input = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Description = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[2]};
  display: inline-block;
  margin-bottom: 32px;
`;

export const Title = styled(HeadingBase)`
  line-height: 100%;
`;

export const Body = styled.div`
  & > form {
    & > div:nth-child(4) {
      margin-bottom: 16px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
`;

export const Modal = styled(ModalBase)`
  max-width: 498px;
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
`;
