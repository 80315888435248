import styled from 'styled-components/macro';
import BadgeBase from '@components/common/core/DataDisplay/Badge';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Badge = styled(BadgeBase)`
  margin-left: auto;
`;

export const PriceWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 76px;
  background-color: #183231;
  border-color: #00f2a1;
  border: 1px solid #00f2a1;
  border-radius: 4px;
  padding: 4px, 8px, 4px, 8px;
  box-sizing: border-box;
  transition: all 0.3s;
  margin-left: auto;
  justify-content: center;
`;

export const Text = styled(TextBase)`
  font-size: 0.75rem;
  font-weight: 600;
  color: #00f2a1;
`;
