import React from 'react';

import { EOrderStatus } from '@domain/enums/common/EOrder';
import { EHeadAlign } from '@domain/enums/components/ETable';
import { IOrderTableDataProps } from '@domain/interfaces/dashboard/Orders/IOrderTableData';

import { getBadgeColorByValue } from '@helpers/utils/components/badge';

import { numberFormatter } from '@helpers/masks';

import Table from '@components/common/core/Utils/Table';

import * as S from './styles';

const NetProfit: React.FC<IOrderTableDataProps> = ({ order }) => {
  const shouldShowNetProfit = order.status === EOrderStatus.PAID;
  const isNetProfitGreaterThanZero = order.net_profit > 0;

  const formattedNetProfitValue = `R$ ${numberFormatter(order.net_profit, 2)}`;

  return (
    <Table.Data align={EHeadAlign.RIGHT}>
      {!isNetProfitGreaterThanZero && !shouldShowNetProfit && '-'}

      {(isNetProfitGreaterThanZero || shouldShowNetProfit) && (
        <S.PriceWrapper>
          <S.Text>{formattedNetProfitValue}</S.Text>
        </S.PriceWrapper>
      )}
    </Table.Data>
  );
};

export default NetProfit;
