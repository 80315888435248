import React from 'react';
import { SimpleMegaPhone, Search } from '@profitfy/pakkun-icons';
import { FixedSizeList as List } from 'react-window';

import { useScriptAndUtms } from '@helpers/hooks/pages/dashboard/adsManager/onboarding/useScriptAndUtms';
import { useDebounce } from '@helpers/hooks/useDebounce';

import SideModalSave from '@components/common/core/Utils/SideModalSave';
import CampaignOption from './CampaignOption';

import * as S from './styles';

const CampaignsSideModal: React.FC = () => {
  const {
    isCampaignsSideModalOpen,
    handleCampaignsSideModalOpen,
    filteredAds,
    handleSearchName,
    adSenseAds,
    selectedAdSenseAds,
    handleSelectedAdSenseAds,
    handleStoreSelectedAdSenseAds,
  } = useScriptAndUtms();

  const [searchName, setSearchName] = React.useState<string>('');

  const debouncedName = useDebounce(searchName, 700);

  const onSearchNameChange = React.useCallback(event => {
    setSearchName(event.target.value);
  }, []);

  const onCheckChange = React.useCallback(
    event => {
      if (event && !event.target) {
        if (selectedAdSenseAds.length === adSenseAds.length) {
          handleSelectedAdSenseAds([]);
        } else {
          handleSelectedAdSenseAds(adSenseAds);
        }
      }
    },
    [adSenseAds, handleSelectedAdSenseAds, selectedAdSenseAds],
  );

  const onSelectCampaignsClick = React.useCallback(() => {
    handleStoreSelectedAdSenseAds(selectedAdSenseAds);
    handleCampaignsSideModalOpen();
  }, [selectedAdSenseAds, handleStoreSelectedAdSenseAds, handleCampaignsSideModalOpen]);

  React.useEffect(() => {
    handleSearchName(debouncedName);
  }, [handleSearchName, debouncedName]);

  const isAllCampaignsChecked = adSenseAds.length === selectedAdSenseAds.length;
  const maxListHeight = window.innerHeight * 0.6;
  const campaignsQuantity = filteredAds.length;
  const isOverflowing = campaignsQuantity * 36 > maxListHeight;
  const listHeight =
    campaignsQuantity * 36 >= maxListHeight ? maxListHeight : campaignsQuantity * 36;

  return (
    <S.SideModal isOpen={isCampaignsSideModalOpen} toggle={handleCampaignsSideModalOpen}>
      <S.Header>
        <SimpleMegaPhone size={36} />
        <S.Title>Selecione criativos que deseja inserir UTMs</S.Title>
      </S.Header>

      <S.Body>
        <S.SearchInput
          type="text"
          placeholder="Buscar criativo"
          onChange={onSearchNameChange}
          prepend={Search}
        />
        <S.CampaignsWrapper>
          <S.CheckboxGroup>
            <S.CheckboxLabel htmlFor="all-campaigns-checkbox">Todos os criativos</S.CheckboxLabel>
            <S.Checkbox
              checked={isAllCampaignsChecked}
              id="all-campaigns-checkbox"
              onChange={onCheckChange}
            />
          </S.CheckboxGroup>

          <List
            height={listHeight}
            itemCount={filteredAds.length}
            itemSize={36}
            width={isOverflowing ? 'calc(100% + 4px)' : '100%'}
          >
            {CampaignOption}
          </List>
        </S.CampaignsWrapper>

        <SideModalSave
          onCancel={handleCampaignsSideModalOpen}
          type="button"
          successButtonText="Selecionar criativos"
          cancelButtonText="Fechar"
          onSuccess={onSelectCampaignsClick}
        />
      </S.Body>
    </S.SideModal>
  );
};

export default CampaignsSideModal;
