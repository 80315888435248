import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import CustomValuesBase from '@components/Dashboard/FinancialDashboard/CustomValues';
import StoreGoalBase from '@components/Dashboard/FinancialDashboard/StoreGoal';

export const CustomValues = styled(CustomValuesBase)`
  height: 221px;
  min-width: 401px;

  @media only screen and (max-width: 1435px) {
    height: 224px;
  }
`;

export const StoreGoal = styled(StoreGoalBase)`
  min-width: 401px;

  @media only screen and (max-width: 1435px) {
    height: unset;
  }
`;

export const Container = styled.div`
  max-width: 1282px;
  width: 100%;
`;

export const Alert = styled.div`
  width: 100%;
  background-color: #122427;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  border-radius: 5px;
  padding: 12px 24px;
  margin-bottom: 18px;
  box-sizing: border-box;
`;

export const WarningIconWrapper = styled.div`
  min-width: 24px;
  min-height: 24px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AlertTitle = styled(HeadingBase)`
  color: #ffffff;
`;

export const AlertDescription = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  justify-content: space-between;

  @media only screen and (max-width: 1435px) {
    flex-direction: column;
  }
`;

export const FirstSection = styled.div`
  max-width: 862px;
  width: 100%;

  @media only screen and (max-width: 1435px) {
    max-width: unset;
  }
`;

export const SecondSection = styled.div`
  min-width: 398px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media only screen and (max-width: 1435px) {
    width: 100%;
    flex-direction: row;
  }

  @media only screen and (max-width: 1410px) {
    overflow: scroll hidden;
    padding-bottom: 8px;

    &:active {
      cursor: grabbing;
    }

    &::-webkit-scrollbar {
      height: 2px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.background[3]};
      border-radius: 25px;
    }
  }
`;
