import React from 'react';
import { Warning } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import * as S from './styles';

const ConfirmModal: React.FC<any> = ({ isOpen, toggle, syncUtms }) => {
  const theme = useTheme();

  const [isInputChecked, setIsInputChecked] = React.useState<boolean>(false);

  const onCheckChange = React.useCallback(event => {
    if (!event || event.target) return;

    setIsInputChecked(event.checked);
  }, []);

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Modal.Header>
        <Warning outline color={theme.colors.warning.default} size={24} />
        Deseja confirmar a adição das UTM Tags?
      </S.Modal.Header>

      <S.Modal.Body>
        <S.CheckboxGroup>
          <S.Checkbox id="input-checkbox" checked={isInputChecked} onChange={onCheckChange} />
          <S.CheckboxLabel htmlFor="input-checkbox">
            Eu concordo e estou ciente dos tópicos anteriores
          </S.CheckboxLabel>
        </S.CheckboxGroup>

        <S.ButtonsWrapper>
          <S.CancelButton onClick={toggle}>Voltar</S.CancelButton>
          <S.SaveButton onClick={syncUtms} disabled={!isInputChecked}>
            Confirmar
          </S.SaveButton>
        </S.ButtonsWrapper>
      </S.Modal.Body>
    </S.Modal>
  );
};

export default ConfirmModal;
