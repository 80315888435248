import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Container = styled.section`
  position: relative;
  width: calc(100% + 72px);
  padding-top: 96px;
  padding-bottom: 96px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 620px) {
    padding: 32px 0;
  }

  @media only screen and (max-height: 768px) {
    padding-top: 20px;
    padding-bottom: 30px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 978px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 1150px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media only screen and (max-width: 1032px) {
    flex-direction: column;
    align-items: flex-start;
    width: unset;
    gap: 32px;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const WhoIsZendropWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Title = styled(TextBase)`
  color: #ffffff;
`;

export const Description = styled(TextBase)``;

export const BenefitsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const BenefitsTitle = styled(TextBase)`
  color: #ffffff;
`;

export const Benefit = styled.div`
  display: flex;
  gap: 12px;
`;

export const BenefitText = styled(TextBase)``;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  background-color: #24363f;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
