import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';
import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';

export const RenameTooltipContent = styled(TextBase)``;

export const CustomPopover = styled(CustomPopoverBase)``;

export const CustomTooltip = styled(CustomTooltipBase)``;

export const RenameWrapper = styled.div`
  padding: unset;
  background: unset;
  border: unset;
  position: absolute;
  right: 16px;
  top: 14px;

  & > button {
    cursor: pointer;
  }
`;

export const ActionName = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
`;

export const ActionIconWrapper = styled.div``;

export const ActionButton = styled.button`
  background: unset;
  padding: unset;
  border: unset;
  display: flex;
  align-items: center;
  gap: 2px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    span {
      color: ${({ theme }) => theme.colors.green.default};
    }
  }
`;

export const RenameActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    & > span {
      color: ${({ theme }) => theme.colors.green.default};
    }
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  bottom: 4px;
  left: 36px;
  display: flex;
  align-items: center;
  gap: 12px;
`;
