import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { EBadgeColors } from '@domain/enums/components/EBadge';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { IBenefitProps } from '@domain/interfaces/dashboard/BenefitArea/IBenefit';
import { IParams } from '@domain/interfaces/IParams';

import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const Benefit: React.FC<IBenefitProps> = ({ id, title, image, badgeText, benefitDescription }) => {
  const { storeAliasId } = useParams<IParams>();
  const { analytics } = useConfig();
  const history = useHistory();

  const handleSeeMoreButtonClick = React.useCallback(() => {
    analytics?.track(
      'Know More Button Clicked',
      { partner: title },
      { context: { groupId: storeAliasId } },
    );

    history.push(`/${storeAliasId}/dashboard/benefit-area/${id}`);
  }, [history, storeAliasId, id, analytics, title]);

  return (
    <S.Wrapper>
      <S.Header>
        <S.Heading type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
          {title}
        </S.Heading>

        <S.Image src={image.src} alt={image.alt} />
      </S.Header>

      <S.Body>
        <S.ContentWrapper>
          <S.Badge color={EBadgeColors.PRIMARY}>{badgeText}</S.Badge>

          <S.BenefitTextWrapper>
            <S.BenefitTitle weight={ETextWeight.MEDIUM}>Beneficio:</S.BenefitTitle>

            <S.BenefitDescription size={ETextSize.B5}>{benefitDescription}</S.BenefitDescription>
          </S.BenefitTextWrapper>
        </S.ContentWrapper>

        <S.SeeMoreButton onClick={handleSeeMoreButtonClick}>Saiba mais</S.SeeMoreButton>
      </S.Body>
    </S.Wrapper>
  );
};

export default Benefit;
