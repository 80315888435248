import { EAutomationsTemplate } from '@domain/enums/common/automation/ETemplate';

import { ABANDONED_CART_EDGES } from '@constants/pages/dashboard/automations/templates/edges/abandonedCart';
import { ABANDONED_CART_2_EDGES } from '@constants/pages/dashboard/automations/templates/edges/abandonedCart2';
import { ABANDONED_CART_FREE_EDGES } from '@constants/pages/dashboard/automations/templates/edges/abandonedCartFree';
import { ABANDONED_CART_STARTER_EDGES } from '@constants/pages/dashboard/automations/templates/edges/abandonedCartStarter';
import { BOLETO_EDGES } from '@constants/pages/dashboard/automations/templates/edges/boleto';
import { BOLETO_FREE_EDGES } from '@constants/pages/dashboard/automations/templates/edges/boletoFree';
import { BOLETO_STARTER_EDGES } from '@constants/pages/dashboard/automations/templates/edges/boletoStarter';
import { PIX_EDGES } from '@constants/pages/dashboard/automations/templates/edges/pix';
import { PIX_FREE_EDGES } from '@constants/pages/dashboard/automations/templates/edges/pixFree';
import { PIX_STARTER_EDGES } from '@constants/pages/dashboard/automations/templates/edges/pixStarter';
import { REFUSED_CREDIT_CARD_EDGES } from '@constants/pages/dashboard/automations/templates/edges/refusedCreditCard';
import { REFUSED_CREDIT_CARD_FREE_EDGES } from '@constants/pages/dashboard/automations/templates/edges/refusedCreditCardFree';
import { REFUSED_CREDIT_CARD_STARTER_EDGES } from '@constants/pages/dashboard/automations/templates/edges/refusedCreditCardStarter';
import { SIMPLE_SHIPMENT_EDGES } from '@constants/pages/dashboard/automations/templates/edges/simpleShipment';

export const generateInitialEdgesArray = (template: EAutomationsTemplate): Array<any> => {
  if (template === EAutomationsTemplate.ABANDONED_CART) return ABANDONED_CART_EDGES;

  if (template === EAutomationsTemplate.ABANDONED_CART_2) return ABANDONED_CART_2_EDGES;

  if (template === EAutomationsTemplate.ABANDONED_CART_FREE) return ABANDONED_CART_FREE_EDGES;

  if (template === EAutomationsTemplate.ABANDONED_CART_STARTER) return ABANDONED_CART_STARTER_EDGES;

  if (template === EAutomationsTemplate.BOLETO) return BOLETO_EDGES;

  if (template === EAutomationsTemplate.BOLETO_FREE) return BOLETO_FREE_EDGES;

  if (template === EAutomationsTemplate.BOLETO_STARTER) return BOLETO_STARTER_EDGES;

  if (template === EAutomationsTemplate.PIX) return PIX_EDGES;

  if (template === EAutomationsTemplate.PIX_FREE) return PIX_FREE_EDGES;

  if (template === EAutomationsTemplate.PIX_STARTER) return PIX_STARTER_EDGES;

  if (template === EAutomationsTemplate.REFUSED_CREDIT_CARD) return REFUSED_CREDIT_CARD_EDGES;

  if (template === EAutomationsTemplate.REFUSED_CREDIT_CARD_FREE)
    return REFUSED_CREDIT_CARD_FREE_EDGES;

  if (template === EAutomationsTemplate.REFUSED_CREDIT_CARD_STARTER)
    return REFUSED_CREDIT_CARD_STARTER_EDGES;

  if (template === EAutomationsTemplate.SIMPLE_SHIPMENT) return SIMPLE_SHIPMENT_EDGES;

  return [];
};
