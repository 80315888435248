import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { EButtonSize, EButtonVariant } from '@domain/enums/components/EButton';
import { IParams } from '@domain/interfaces/IParams';

import { useSubscription } from '@helpers/hooks/common/store/subscription/useSubscription';
import { useDowngrade } from '@helpers/hooks/common/store/subscription/useDowngrade';
import { useConfig } from '@helpers/hooks/useConfig';

import { ALL_PLANS } from '@constants/plans';

import * as S from './styles';

const NextStep: React.FC = () => {
  const isAdProvidersStep = window.location.pathname.includes('ad-providers');
  const isInvitedMembersStep = window.location.pathname.includes('invited-members');
  const isFacebookProfilesStep = window.location.pathname.includes('facebook-profiles');
  const isStoreWorkflowStep = window.location.pathname.includes('store-workflows');

  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { selectedPlanIdentifier } = useSubscription();
  const {
    selectedAdProviders,
    selectedInvitedMembers,
    selectedFacebookProfiles,
    selectedStoreWorkflows,
    handleSubscribe,
    isLoadingSubscription,
    hasToDowngradeFacebookProfiles,
    hasToDowngradeInvitedMembers,
    hasToDowngradeStoreWorkflows,
  } = useDowngrade();
  const { analytics, user } = useConfig();

  const foundSelectedPlan = ALL_PLANS.find(
    plan =>
      plan.identifier === selectedPlanIdentifier ||
      plan.quarterIdentifier === selectedPlanIdentifier ||
      plan.semiAnualIdentifier === selectedPlanIdentifier,
  );

  const goBack = React.useCallback(() => {
    history.push(`/${storeAliasId}/pricing`);
  }, [history, storeAliasId]);

  const handleAdsProvider = React.useCallback(async () => {
    analytics?.track(
      'Subscription Flow Downgraded Traffic Sources Selected',
      {
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );

    if (!hasToDowngradeInvitedMembers && !hasToDowngradeFacebookProfiles) {
      await handleSubscribe(storeAliasId);
    } else {
      history.push(`/${storeAliasId}/downgrade/invited-members`);
    }
  }, [
    analytics,
    user,
    storeAliasId,
    handleSubscribe,
    history,
    hasToDowngradeInvitedMembers,
    hasToDowngradeFacebookProfiles,
  ]);

  const handleInvitedMembers = React.useCallback(async () => {
    analytics?.track(
      'Subscription Flow Downgraded Users Selected',
      {
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );

    if (!hasToDowngradeFacebookProfiles) {
      await handleSubscribe(storeAliasId);
    } else {
      history.push(`/${storeAliasId}/downgrade/facebook-profiles`);
    }
  }, [analytics, user, storeAliasId, handleSubscribe, history, hasToDowngradeFacebookProfiles]);

  const handleFacebookProfiles = React.useCallback(async () => {
    analytics?.track(
      'Subscription Flow Downgraded Facebook Profile Selected',
      {
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );

    if (!hasToDowngradeStoreWorkflows) {
      await handleSubscribe(storeAliasId);
    } else {
      history.push(`/${storeAliasId}/downgrade/store-workflows`);
    }
  }, [analytics, handleSubscribe, storeAliasId, user, hasToDowngradeStoreWorkflows, history]);

  const handleStoreWorkflows = React.useCallback(async () => {
    analytics?.track(
      'Subscription Flow Downgraded Store Workflows Selected',
      {
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );
    analytics?.track(
      'Subscription Flow Downgraded Finished',
      {
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );

    await handleSubscribe(storeAliasId);
  }, [analytics, user, handleSubscribe, storeAliasId]);

  const nextStepClick = React.useCallback(async () => {
    if (isAdProvidersStep) {
      await handleAdsProvider();
    }

    if (isInvitedMembersStep) {
      await handleInvitedMembers();
    }

    if (isFacebookProfilesStep) {
      await handleFacebookProfiles();
    }

    if (isStoreWorkflowStep) {
      await handleStoreWorkflows();
    }
  }, [
    isAdProvidersStep,
    isInvitedMembersStep,
    isFacebookProfilesStep,
    isStoreWorkflowStep,
    handleAdsProvider,
    handleInvitedMembers,
    handleFacebookProfiles,
    handleStoreWorkflows,
  ]);

  const maxAdProviders = foundSelectedPlan?.marketingProvider || Infinity;
  const maxInvitedMembers = foundSelectedPlan?.members || Infinity;
  const maxFacebookProfiles = foundSelectedPlan?.facebookProfile || Infinity;
  const maxWorkflows = foundSelectedPlan?.benefits.maxWorkflows || Infinity;

  const hasSurpassedSelectedAdProvidersLimit = selectedAdProviders.length > maxAdProviders;
  const hasSurpassedSelectedInvitedMembersLimit = selectedInvitedMembers.length > maxInvitedMembers;
  const hasSurpassedSelectedFacebookProfilesLimit =
    selectedFacebookProfiles.length > maxFacebookProfiles;
  const hasSurpassedSelectedStoreWorkflowsLimit = selectedStoreWorkflows.length > maxWorkflows;

  const isNextStepButtonDisabled =
    (isAdProvidersStep && hasSurpassedSelectedAdProvidersLimit) ||
    (isFacebookProfilesStep && hasSurpassedSelectedFacebookProfilesLimit) ||
    (isInvitedMembersStep && hasSurpassedSelectedInvitedMembersLimit) ||
    (isStoreWorkflowStep && hasSurpassedSelectedStoreWorkflowsLimit);

  return (
    <S.Wrapper>
      <S.Button onClick={goBack} variant={EButtonVariant.SECONDARY} buttonSize={EButtonSize.MEDIUM}>
        Voltar para página de Planos
      </S.Button>
      <S.CustomTooltip open={isNextStepButtonDisabled}>
        <S.CustomTooltipTrigger
          onClick={nextStepClick}
          disabled={isNextStepButtonDisabled || isLoadingSubscription}
        >
          Próximo passo
        </S.CustomTooltipTrigger>

        {hasSurpassedSelectedAdProvidersLimit && (
          <S.CustomTooltip.Content sideOffset={5}>
            <S.CustomTooltip.Arrow />

            <S.TooltipText>
              Você selecionou mais fontes de tráfego que o permitido pelo plano escolhido.
            </S.TooltipText>
          </S.CustomTooltip.Content>
        )}

        {hasSurpassedSelectedInvitedMembersLimit && (
          <S.CustomTooltip.Content sideOffset={5}>
            <S.CustomTooltip.Arrow />

            <S.TooltipText>
              Você selecionou mais usuários que o permitido pelo plano escolhido.
            </S.TooltipText>
          </S.CustomTooltip.Content>
        )}

        {hasSurpassedSelectedFacebookProfilesLimit && (
          <S.CustomTooltip.Content sideOffset={5}>
            <S.CustomTooltip.Arrow />

            <S.TooltipText>
              Você selecionou mais perfis do Facebook que o permitido pelo plano escolhido.
            </S.TooltipText>
          </S.CustomTooltip.Content>
        )}

        {hasSurpassedSelectedStoreWorkflowsLimit && (
          <S.CustomTooltip.Content sideOffset={5}>
            <S.CustomTooltip.Arrow />

            <S.TooltipText>
              Você selecionou mais automações que o permitido pelo plano escolhido.
            </S.TooltipText>
          </S.CustomTooltip.Content>
        )}
      </S.CustomTooltip>
    </S.Wrapper>
  );
};

export default NextStep;
