import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const Title = styled(HeadingBase)`
  font-size: 1.25rem;
  font-weight: 500;
`;

export const Logo = styled(ImageBase)`
  max-width: 200px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;
