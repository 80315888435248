import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const Title = styled(HeadingBase)``;

export const HeaderWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
  padding-bottom: 16px;
  margin-bottom: 48px;
`;
