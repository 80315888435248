import styled, { css } from 'styled-components/macro';

import { IAvatar } from '@domain/interfaces/components/IAvatar';

import Image from '../Image';
import Heading from '../Heading';

export const Avatar = styled.div<IAvatar>`
  min-width: ${props => props.size};
  min-height: ${props => props.size};
  width: ${props => props.size};
  height: ${props => props.size};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.background[14]};
  border: 1px solid ${({ theme }) => theme.colors.background[13]};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isHiddenContent }) =>
    isHiddenContent &&
    css`
      background-color: ${({ theme }) => theme.colors.gray[5]} !important;

      h3,
      h5,
      svg {
        display: none;
      }
    `}
`;

export const AvatarImg = styled(Image)<IAvatar>`
  width: ${props => props.size};
  height: ${props => props.size};
  border-radius: 50%;
`;

export const AvatarWord = styled(Heading)`
  color: ${({ theme }) => theme.colors.background[15]};
  text-transform: uppercase;
  /* font-family: 'Poppins'; */
  font-family: 'Saira';
  font-style: normal;
  font-weight: 500;
`;

export const Icon = styled.img``;
