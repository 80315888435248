import React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';

import * as S from './styles';

const Content: React.FC<
  PopoverPrimitive.PopoverContentProps & React.RefAttributes<HTMLDivElement>
> = ({ children, ...rest }) => {
  return <S.PopoverContent {...rest}>{children}</S.PopoverContent>;
};

export default Content;
