import styled, { css } from 'styled-components/macro';

import { IBadge } from '@domain/interfaces/components/IBadge';
import { EBadgeColors } from '@domain/enums/components/EBadge';

export const BadgeCircle = styled.span`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-right: 8px;
  display: block;
`;

export const SecondaryContainer = styled.div<IBadge>`
  padding: 4px 12px;
  border-radius: 15px;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  display: flex;
  align-items: center;
  line-height: 120%;
  text-transform: uppercase;
  width: fit-content;
  border: 1px solid ${({ theme }) => theme.colors.gray[3]};

  ${({ color }) =>
    color &&
    css`
      border: 1px solid ${color};

      & > span:first-child {
        background-color: ${color};
      }
    `}

  ${({ color }) =>
    color === EBadgeColors.GREEN &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.success.default};

      & > span:first-child {
        background-color: ${({ theme }) => theme.colors.success.default};
      }
    `}

  ${({ color }) =>
    color === EBadgeColors.RED &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.danger.default};

      & > span:first-child {
        background-color: ${({ theme }) => theme.colors.danger.default};
      }
    `}

  ${({ color }) =>
    color === EBadgeColors.RED &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.danger.default};

      & > span:first-child {
        background-color: ${({ theme }) => theme.colors.danger.default};
      }
    `}

  ${({ color }) =>
    color === EBadgeColors.YELLOW &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.warning.default};

      & > span:first-child {
        background-color: ${({ theme }) => theme.colors.warning.default};
      }
    `}

  ${({ color }) =>
    color === EBadgeColors.GRAY &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.gray[4]};

      & > span:first-child {
        background-color: ${({ theme }) => theme.colors.gray[3]};
      }
    `}

  ${({ color }) =>
    color === EBadgeColors.PURPLE &&
    css`
      border: 1px solid #bf55ec;
      & > span:first-child {
        background-color: #bf55ec;
      }
    `}

  ${({ color }) =>
    color === EBadgeColors.ORANGE &&
    css`
      border: 1px solid #ff7800;

      & > span:first-child {
        background-color: #ff7800;
      }
    `}

  ${({ color }) =>
    color === EBadgeColors.SKY_BLUE &&
    css`
      border: 1px solid #5d85c7;

      & > span:first-child {
        background-color: #5d85c7;
      }
    `}

  ${({ color }) =>
    color === EBadgeColors.PINK &&
    css`
      border: 1px solid #ff7fab;

      & > span:first-child {
        background-color: #ff7fab;
      }
    `}

  ${({ color }) =>
    color === EBadgeColors.BLUE &&
    css`
      border: 1px solid #0231e8;

      & > span:first-child {
        background-color: #0231e8;
      }
    `}
`;

export const Container = styled.div<IBadge>`
  padding: 2px 10px;
  border-radius: 14px;
  width: fit-content;
  font-size: 0.75rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.body};

  ${({ color }) =>
    color === EBadgeColors.PRIMARY &&
    css`
      background-color: rgba(46, 179, 180, 0.3);
      color: ${({ theme }) => theme.colors.green.default};
    `}

  ${({ color }) =>
    color === EBadgeColors.GREEN &&
    css`
      background-color: rgb(36, 54, 63);
      color: #25cfbb;
    `}

  ${({ color }) =>
    color === EBadgeColors.SUCCESS &&
    css`
      background-color: rgba(89, 181, 120, 0.26);
      color: ${({ theme }) => theme.colors.success.light};
    `}

  ${({ color }) =>
    color === EBadgeColors.RED &&
    css`
      background-color: #e0313159;
      color: #ffc9c9;
    `}

  ${({ color }) =>
    color === EBadgeColors.YELLOW &&
    css`
      background-color: rgba(246, 166, 9, 0.3);
      color: ${({ theme }) => theme.colors.warning.default};
    `}

  ${({ color, theme }) =>
    color === EBadgeColors.GRAY &&
    css`
      background-color: rgba(81, 85, 98, 0.3);
      color: ${theme.colors.gray[1]};
    `}

    ${({ color }) =>
    color === EBadgeColors.PURPLE &&
    css`
      background-color: rgba(156, 54, 181, 0.35);
      color: rgb(238, 190, 250);
    `}
`;
