import React from 'react';

import * as S from './styles';

const Phone: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>Gerencie de qualquer lugar</S.Title>
        <S.Description>
          Baixe agora o nosso app e tenha a gestão da sua operação na palma da sua mão.
        </S.Description>
        <S.LinksWrapper>
          <S.AppStoreLink href="https://play.google.com/store/apps/details?id=com.profitfy">
            <S.AppStoreImage
              src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/pricing/phone/google.svg"
              alt="Profitfy Play Store"
              loading="lazy"
            />
          </S.AppStoreLink>
          <S.AppStoreLink href="https://apps.apple.com/br/app/profitfy/id1640367466">
            <S.AppStoreImage
              src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/pricing/phone/apple.svg"
              alt="Profitfy App Store"
              loading="lazy"
            />
          </S.AppStoreLink>
        </S.LinksWrapper>
      </S.Content>

      <S.ImageWrapper>
        <S.Image
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/pricing/phone/app-mobile.svg"
          alt="Profitfy App Mobile"
          loading="lazy"
        />
        <S.BlurBackground />
      </S.ImageWrapper>
    </S.Wrapper>
  );
};

export default Phone;
