import React from 'react';

import { useHistory } from 'react-router-dom';
import { useConfig } from '@helpers/hooks/useConfig';

import { EButtonSize } from '@domain/enums/components/EButton';

import ProfitfyLogoImg from '@assets/img/brand/profitfy-logo.svg';

import * as S from './styles';

const DesktopSuggestionContent: React.FC = () => {
  const { user, handleLogout, analytics } = useConfig();

  const history = useHistory();

  const handleBackStores = React.useCallback(() => {
    history.push('/stores');
  }, [history]);

  const onLogoutClick = React.useCallback(() => {
    analytics?.track('Has Seen Alert To Complete Onboarding On Desktop', {
      click_source: 'LOGOUT',
      email: user.email,
    });

    handleLogout();
  }, [handleLogout, analytics, user]);

  const onGoBackToStoresClick = React.useCallback(() => {
    analytics?.track('Has Seen Alert To Complete Onboarding On Desktop', {
      click_source: 'GO_BACK_TO_STORES',
      email: user.email,
    });

    handleBackStores();
  }, [handleBackStores, analytics, user]);

  React.useEffect(() => {
    analytics?.track('Notice to Download the App Clicked');
  }, [analytics]);

  return (
    <S.Wrapper>
      <S.ProfitfyLogo src={ProfitfyLogoImg} alt="profitfy logo" />

      <S.HeadingAndDescriptionWrapper>
        <S.Heading>Olá {user.first_name}!</S.Heading>

        <S.Description>
          Para que sua experiência seja ainda melhor, pedimos que você prossiga com seu onboarding,
          pelo seu desktop.
          {/* <br />
          <br />
          Enviamos um link para seu e-mail que te redireciona para a etapa de onboarding. Você só
          precisa logar com a conta criada aqui no app. */}
        </S.Description>
      </S.HeadingAndDescriptionWrapper>

      <S.ButtonsWrapper>
        <S.SkipButton onClick={onLogoutClick}>Sair</S.SkipButton>

        <S.BackToStoresButton buttonSize={EButtonSize.SMALL} onClick={onGoBackToStoresClick}>
          Voltar para escolha de lojas
        </S.BackToStoresButton>
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export default DesktopSuggestionContent;
