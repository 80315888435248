import React from 'react';
import { WarningTriangle } from '@profitfy/pakkun-icons';
import { useHistory, useParams } from 'react-router-dom';

import { IBlurredContentProps } from '@domain/interfaces/dashboard/AdsManager/Table/Content/IInsight';
import { IParams } from '@domain/interfaces/IParams';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useAdsManager } from '@helpers/hooks/pages/dashboard/adsManager/useAdsManager';

import FirstTooltip from '@components/Dashboard/AdsManager/UtmsTooltipsContent/FirstTooltip';
import OnboardingNotFinished from '@components/Dashboard/AdsManager/UtmsTooltipsContent/OnboardingNotFinished';
import UtmError from '@components/Dashboard/AdsManager/UtmsTooltipsContent/UtmError';

import * as S from './styles';

const BlurredContent: React.FC<IBlurredContentProps> = ({ index }) => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { store } = useStoreConfig();
  const { isFirstTooltipOpen, closeFirstTooltip } = useAdsManager();

  const [isTooltipOpen, setIsTooltipOpen] = React.useState<boolean>(false);

  const redirectToUtmOnboarding = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/marketing/ads-manager/onboarding/script-utms`);
  }, [history, storeAliasId]);

  const onOpenChange = React.useCallback(state => setIsTooltipOpen(state), []);

  const hasNotFinishedUtmOnboarding = !store.is_utm_onboarding_finished;
  const isFirstRow = index === 0;

  const isFirstUtmTooltipOpen = isFirstTooltipOpen && hasNotFinishedUtmOnboarding && isFirstRow;

  return (
    <S.CustomHoverCard open={isTooltipOpen || isFirstUtmTooltipOpen} onOpenChange={onOpenChange}>
      <S.CustomHoverCard.Trigger>
        <S.InsightWrapper>
          <WarningTriangle size={14} />
        </S.InsightWrapper>
      </S.CustomHoverCard.Trigger>

      <S.CustomHoverCardContent side="top" sideOffset={5}>
        {isFirstUtmTooltipOpen && <FirstTooltip closeFirstTooltip={closeFirstTooltip} />}
        {hasNotFinishedUtmOnboarding && !isFirstUtmTooltipOpen && (
          <OnboardingNotFinished startUtmOnboarding={redirectToUtmOnboarding} />
        )}
        {!hasNotFinishedUtmOnboarding && <UtmError />}
        <S.CustomHoverCard.Arrow />
      </S.CustomHoverCardContent>
    </S.CustomHoverCard>
  );
};

export default BlurredContent;
