import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import SidemodalBase from '@components/common/core/DataDisplay/SideModal';
import TextBase from '@components/common/core/DataDisplay/Text';

export const InformationText = styled(TextBase)`
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[3]};
  display: inline-block;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 16px;
`;

export const Title = styled(HeadingBase)``;

export const Body = styled.div``;

export const Header = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
  margin-bottom: 48px;

  @media only screen and (max-height: 576px) {
    margin-bottom: 24px;
  }
`;

export const Sidemodal = styled(SidemodalBase)`
  background-color: ${({ theme }) => theme.colors.gray[6]};
`;
