import React from 'react';
import { Calendar } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import FilterButton from '@components/common/core/Utils/Filter/FilterButton';
import { useFinancialDetails } from '@helpers/hooks/pages/dashboard/financialDetails/useFinancialDetails';
import {
  FINANCIAL_DETAILS_FILTER_CONFIG,
  FINANCIAL_DETAILS_FILTER_BY_DAY_CONFIG,
} from '@helpers/utils/filters/financialDetailsFilter';
import { ETableType } from '@domain/enums/dashboard/financialDetails/EFinancialDetails';
import DatePickerRange from '@components/common/core/Utils/DatePickerRange';
import { EFilterVariant } from '@domain/enums/components/EFilter';
import ColumnsSelect from './ColumnsSelect';
import ActionsSelect from './ActionsSelect';

import * as S from './styles';

const Header: React.FC = () => {
  const theme = useTheme();
  const {
    filterData,
    handleFilterData,
    tableType,
    period,
    setPeriod,
    handleTableType,
    maxDateLimiter,
    minDateLimiter,
  } = useFinancialDetails();

  const [isHoveringTableTypeButton, setIsHoveringTableTypeButton] = React.useState<boolean>(false);

  const onTableTypeButtonMouseEnter = React.useCallback(() => {
    setIsHoveringTableTypeButton(true);
  }, []);

  const onTableTypeButtonMouseLeave = React.useCallback(() => {
    setIsHoveringTableTypeButton(false);
  }, []);

  const addNewData = React.useCallback(
    newData => {
      handleFilterData([...filterData, newData]);
    },
    [filterData, handleFilterData],
  );

  const onTableTypeClick = React.useCallback(() => {
    if (tableType === ETableType.PERIOD) handleTableType(ETableType.DAILY);

    if (tableType === ETableType.DAILY) handleTableType(ETableType.PERIOD);
  }, [tableType, handleTableType]);

  const filterConfig =
    tableType === ETableType.PERIOD
      ? FINANCIAL_DETAILS_FILTER_CONFIG
      : FINANCIAL_DETAILS_FILTER_BY_DAY_CONFIG;

  return (
    <S.Wrapper>
      <S.Title>Detalhes Financeiros</S.Title>

      <S.OptionsWrapper>
        <S.FilterAndTableTypeButtonWrapper>
          {Boolean(!filterData.length) && (
            <FilterButton
              data={filterData}
              addNewData={addNewData}
              config={filterConfig}
              variant={EFilterVariant.MEDIUM}
            />
          )}
          <S.TableTypeButton
            onMouseEnter={onTableTypeButtonMouseEnter}
            onMouseLeave={onTableTypeButtonMouseLeave}
            onClick={onTableTypeClick}
          >
            <Calendar
              outline
              size={16}
              color={isHoveringTableTypeButton ? theme.colors.green.default : theme.colors.gray[1]}
            />
            {tableType === ETableType.DAILY ? 'Analisar por período' : 'Analisar por dia'}
          </S.TableTypeButton>
        </S.FilterAndTableTypeButtonWrapper>

        <S.DatePickerAndColumnsAndActionsWrapper>
          <DatePickerRange
            period={period}
            setPeriod={setPeriod}
            maxDate={maxDateLimiter()}
            minDate={minDateLimiter()}
          />

          <S.ColumnsAndActionsWrapper>
            <ColumnsSelect />
            <ActionsSelect />
          </S.ColumnsAndActionsWrapper>
        </S.DatePickerAndColumnsAndActionsWrapper>
      </S.OptionsWrapper>
    </S.Wrapper>
  );
};

export default Header;
