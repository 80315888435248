import React from 'react';

import { EUserStoreRole } from '@domain/enums/common/userStore/EUserStoreRole';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';
import { useStorePermission } from '@helpers/hooks/common/useStorePermission';

import { AdsManagerProvider } from '@helpers/hooks/pages/dashboard/adsManager/useAdsManager';

import NoAccess from '@components/common/core/Utils/NoAccess';
import Wrapper from '@components/Dashboard/AdsManager/Wrapper';
import LimitationPage from '@components/common/core/Utils/LimitationPage';

const AdsManager: React.FC = () => {
  const { accessManagementData } = useStoreConfig();
  const { isInternalTeam } = useConfig();
  const { hasAdsManagerFeature } = useStorePermission();

  const isStoreOwner = accessManagementData?.role === EUserStoreRole.OWNER;
  const hasAccessToPage =
    accessManagementData?.group?.has_ads_manager_full_write_access ||
    accessManagementData?.group?.has_ads_manager_full_read_access;

  if (!isStoreOwner && !hasAccessToPage && !isInternalTeam) {
    return <NoAccess />;
  }

  if (!hasAdsManagerFeature) {
    return (
      <LimitationPage
        imageSrc="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/temp/limitation-temp.svg"
        title="Gerenciador de ads não disponível"
        description="Escolha um plano que te permita gerenciar os seus anúncios para que consiga usufruir o melhor da ferramenta."
        trackingPropertyName="NO_ADS_MANAGER_ACCESS"
      />
    );
  }

  return (
    <AdsManagerProvider>
      <Wrapper />
    </AdsManagerProvider>
  );
};

export default AdsManager;
