import { AxiosResponse } from 'axios';

import {
  IGetSubscriptionPlanChangePromiseResponse,
  IGetSubscriptionPlanChangeProps,
  IGetSubscriptionPlanChangeResponse,
} from '@domain/interfaces/common/subscription/ISubscriptionPlanChange';

import { useFetch } from '@providers/Fetch/useFetch';

import { ApiService } from '@services/api';

import { USER_API_DOMAIN } from '@constants/api';

export class SubscriptionPlanChangeService {
  private readonly apiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getSubscriptionPlanChange({
    storeAliasId,
    subscriptionAliasId,
  }: IGetSubscriptionPlanChangeProps): IGetSubscriptionPlanChangeResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/subscriptions/${subscriptionAliasId}/subscription-plan-changes`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      subscriptionPlanChange: data.subscription_plan_change,
      plan: data.plan,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public getSubscriptionPlanChangePromise({
    storeAliasId,
    subscriptionAliasId,
  }: IGetSubscriptionPlanChangeProps): Promise<
    AxiosResponse<IGetSubscriptionPlanChangePromiseResponse>
  > {
    return this.apiService.get<IGetSubscriptionPlanChangePromiseResponse>(
      `/api/v1/users/stores/${storeAliasId}/subscriptions/${subscriptionAliasId}/subscription-plan-changes`,
    );
  }
}

const subscriptionPlanChangeService = new SubscriptionPlanChangeService(USER_API_DOMAIN);

export default subscriptionPlanChangeService;
