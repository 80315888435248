import React from 'react';

import { numberFormatter } from '@helpers/masks';

import { ISummaryCardProps } from '@domain/interfaces/dashboard/Automations/AutomationsManager/ISummaryCard';

import * as S from './styles';

const SummaryCard: React.FC<ISummaryCardProps> = ({
  icon: Icon,
  iconBackgroundColor,
  iconColor,
  description,
  isFinancial = false,
  value,
}) => {
  const parseQuantity = React.useCallback(
    (num: number) => {
      return isFinancial ? `R$ ${numberFormatter(num, 2)}` : numberFormatter(num, 0, true);
    },
    [isFinancial],
  );

  return (
    <S.Wrapper>
      <S.IconContainer backgroundColor={iconBackgroundColor}>
        <Icon size={32} color={iconColor} />
      </S.IconContainer>
      <S.Column>
        <S.Quantity financial={isFinancial}>{parseQuantity(value)}</S.Quantity>
        <S.QuantityDescription>{description}</S.QuantityDescription>
      </S.Column>
    </S.Wrapper>
  );
};

export default SummaryCard;
