import React from 'react';
import { useParams } from 'react-router-dom';

import { EProgressBarStep } from '@domain/enums/downgrade/EDowngradeStep';
import { IParams } from '@domain/interfaces/IParams';

import SideBar from '@components/Downgrade/SideBar';
import NavBar from '@components/Downgrade/NavBar';
import InvitedMembersContent from '@components/Downgrade/InvitedMembers';

import * as S from './styles';

const InvitedMembers: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();

  const previousRouteLink = `/${storeAliasId}/downgrade/ad-providers`;

  return (
    <S.Container>
      <SideBar
        onboardingStep={EProgressBarStep.INVITED_MEMBERS}
        previousRouteLink={previousRouteLink}
      />

      <S.Content>
        <NavBar />
        <InvitedMembersContent />
      </S.Content>
    </S.Container>
  );
};

export default InvitedMembers;
