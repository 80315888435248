import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { SimpleCheck, Abmex as AbmexIcon } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components';
import { ETextSize } from '@domain/enums/components/EText';

import IntegrateForm from '@components/Onboarding/TraditionalEcommerce/Gateways/OtherGateway/IntegrateForm';

import * as S from './styles';

const Abmex: React.FC = () => {
  const theme = useTheme();

  return (
    <S.ContentWrapper>
      <S.HeadingAndIntegrateFormWrapper>
        <S.Title>Integre com Gateway de pagamento</S.Title>

        <S.IntegrationTitle>
          <AbmexIcon size={36} />

          <S.IntegrationTitleText fontWeight={EHeadingWeight.REGULAR}>
            Integração com Abmex
          </S.IntegrationTitleText>
        </S.IntegrationTitle>

        <IntegrateForm gatewayName="Abmex" gatewayId="Abmex" />
      </S.HeadingAndIntegrateFormWrapper>

      <S.BenefitsWrapper>
        <S.BenefitsHeading type={EHeadingSize.H6}>
          Por que nos fornecer seus dados de gateway?
        </S.BenefitsHeading>

        <S.BenefitsBody>
          <S.Benefit>
            <SimpleCheck size={12} color={theme.colors.green.dark} />
            <S.BenefitDescription size={ETextSize.B5}>Exatidão dos dados</S.BenefitDescription>
          </S.Benefit>

          <S.Benefit>
            <SimpleCheck size={12} color={theme.colors.green.dark} />
            <S.BenefitDescription size={ETextSize.B5}>
              Confiabilidade dos dados
            </S.BenefitDescription>
          </S.Benefit>
        </S.BenefitsBody>
      </S.BenefitsWrapper>
    </S.ContentWrapper>
  );
};

export default Abmex;
