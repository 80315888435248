import styled, { css } from 'styled-components/macro';

import CardBase from '@components/common/core/Surfaces/Card';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import { IPercentageProps } from '@domain/interfaces/dashboard/ProductAnalytics/IProductDetails';

export const PercentageArrow = styled.div<IPercentageProps>`
  margin-right: 4px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid ${({ theme }) => theme.colors.danger.default};

  ${({ isPositive, theme }) =>
    isPositive &&
    css`
      border-top: 0;
      border-bottom: 6px solid ${theme.colors.success.default};
    `}
`;

export const PercentageNumber = styled(TextBase)<IPercentageProps>`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.danger.default};
  display: flex;
  gap: 4px;
  align-items: center;
  line-height: 120%;

  ${({ isPositive, theme }) =>
    isPositive &&
    css`
      color: ${theme.colors.success.default};
    `}
`;

export const PercentageWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Value = styled(TextBase)`
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 120%;
`;

export const ValueAndPercentageWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
`;

export const Title = styled(HeadingBase)`
  font-size: 1rem;
  font-weight: 400;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const CardWrapper = styled(CardBase)`
  position: relative;
  padding: 20px 18px;
  box-sizing: border-box;
  min-width: 264px;
  height: 96px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 12px;
  border: unset;
  cursor: default;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const EditCogsIconWrapper = styled.button`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.firstLayer};
  border: unset;
  padding: unset;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 14px;
  right: 14px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;
