import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Table = styled(TableBase)``;

export const EditButton = styled.button`
  background: unset;
  padding: unset;
  border: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  cursor: pointer;
`;

export const CheckboxWrapper = styled.div`
  width: fit-content;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.gray[7]};
`;

export const Checkbox = styled(CheckboxBase)``;

export const GroupName = styled(TextBase)``;

export const TableDataContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
`;
