import React from 'react';
import { Currency } from '@profitfy/pakkun-icons';

import { EHeadingWeight } from '@domain/enums/components/EHeading';

import SideModalSave from '@components/common/core/Utils/SideModalSave';
import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { numberFormatter } from '@helpers/masks';
import PieChart from './PieChart';
import CustomValue from './CustomValue';

import * as S from './styles';

const CustomValuesSideModal: React.FC = () => {
  const {
    isLoadingFinancialDashboardProvider,
    isValidatingFinancialDashboardProvider,
    isCustomValuesSideModalOpen,
    handleCustomValuesSideModalOpen,
    customValues,
    customValuesTotalAmount,
  } = useFinancialDashboard();

  if (isLoadingFinancialDashboardProvider || isValidatingFinancialDashboardProvider) {
    return <></>;
  }

  return (
    <S.SideModal isOpen={isCustomValuesSideModalOpen} toggle={handleCustomValuesSideModalOpen}>
      <S.Header>
        <Currency size={36} />
        <S.Title fontWeight={EHeadingWeight.REGULAR}>Meus valores adicionais</S.Title>
      </S.Header>

      <S.Body>
        <S.ChartWrapper>
          <PieChart />
          <S.TotalWrapper>
            <S.TotalLabel>Total</S.TotalLabel>
            <S.TotalValue>{`R$${numberFormatter(customValuesTotalAmount, 2)}`}</S.TotalValue>
          </S.TotalWrapper>
        </S.ChartWrapper>

        <S.CustomValuesWrapper>
          {customValues.map(customValue => (
            <CustomValue key={customValue.name} customValue={customValue} />
          ))}
        </S.CustomValuesWrapper>

        <SideModalSave
          successButtonText="Fechar"
          type="button"
          onSuccess={handleCustomValuesSideModalOpen}
        />
      </S.Body>
    </S.SideModal>
  );
};

export default CustomValuesSideModal;
