import React from 'react';
import { Warning } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { IDeleteModalProps } from '@domain/interfaces/dashboard/ProductTag/ISideModalTags';

import * as S from './styles';

const DeleteModal: React.FC<IDeleteModalProps> = ({ isOpen, toggle, handleDeleteProductTag }) => {
  const theme = useTheme();

  const [isDeletingProductTag, setIsDeletingProductTag] = React.useState<boolean>(false);

  const onModalToggle = React.useCallback(() => {
    if (!isDeletingProductTag) {
      toggle();
    }
  }, [isDeletingProductTag, toggle]);

  const onDelete = React.useCallback(async () => {
    setIsDeletingProductTag(true);

    await handleDeleteProductTag();
  }, [handleDeleteProductTag]);

  return (
    <S.Modal isOpen={isOpen} toggle={onModalToggle}>
      <S.Modal.Header>
        <Warning size={24} color={theme.colors.danger.default} outline />
        Deseja excluir a tag do produto?
      </S.Modal.Header>
      <S.Modal.Body>
        <S.Text>
          Ao clicar em excluir, a tag será excluída e não será possível reverter esta ação.
        </S.Text>

        <S.ButtonsWrapper>
          <S.CancelButton onClick={onModalToggle}>Cancelar</S.CancelButton>
          <S.ConfirmButton
            onClick={onDelete}
            isLoading={isDeletingProductTag}
            disabled={isDeletingProductTag}
          >
            Confirmar
          </S.ConfirmButton>
        </S.ButtonsWrapper>
      </S.Modal.Body>
    </S.Modal>
  );
};

export default DeleteModal;
