import React from 'react';

import { useMediaQuery } from '@helpers/hooks/useMediaQuery';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  const mobileWidth = useMediaQuery('1124px');

  return (
    <S.Container>
      {mobileWidth ? (
        <S.ProfitCard>
          <Skeleton width="100%" height="100%" style={{ borderRadius: 15 }} />
        </S.ProfitCard>
      ) : (
        <></>
      )}

      <S.OverviewCardsWrapper>
        <S.OverviewCard>
          <Skeleton width="100%" height="100%" style={{ borderRadius: 12 }} />
        </S.OverviewCard>

        <S.OverviewCard>
          <Skeleton width="100%" height="100%" style={{ borderRadius: 12 }} />
        </S.OverviewCard>

        <S.OverviewCard>
          <Skeleton width="100%" height="100%" style={{ borderRadius: 12 }} />
        </S.OverviewCard>

        <S.OverviewCard>
          <Skeleton width="100%" height="100%" style={{ borderRadius: 12 }} />
        </S.OverviewCard>
      </S.OverviewCardsWrapper>

      <S.SectionsWrapper>
        <S.FirstSection>
          <S.FunnelChart>
            <Skeleton width="100%" height="100%" style={{ borderRadius: 7 }} />
          </S.FunnelChart>

          <S.MetricsCardWrapper>
            <S.MetricCard>
              <Skeleton width="100%" height="100%" style={{ borderRadius: 15 }} />
            </S.MetricCard>
            <S.MetricCard>
              <Skeleton width="100%" height="100%" style={{ borderRadius: 15 }} />
            </S.MetricCard>
            <S.MetricCard>
              <Skeleton width="100%" height="100%" style={{ borderRadius: 15 }} />
            </S.MetricCard>
            <S.MetricCard>
              <Skeleton width="100%" height="100%" style={{ borderRadius: 15 }} />
            </S.MetricCard>
            <S.MetricCard>
              <Skeleton width="100%" height="100%" style={{ borderRadius: 15 }} />
            </S.MetricCard>
          </S.MetricsCardWrapper>

          <S.PaymentMethodsCardsWrapper>
            <S.PaymentMethodsCard>
              <Skeleton width="100%" height="100%" style={{ borderRadius: 12 }} />
            </S.PaymentMethodsCard>

            <S.PaymentMethodsCard>
              <Skeleton width="100%" height="100%" style={{ borderRadius: 12 }} />
            </S.PaymentMethodsCard>

            <S.PaymentMethodsCard>
              <Skeleton width="100%" height="100%" style={{ borderRadius: 12 }} />
            </S.PaymentMethodsCard>
          </S.PaymentMethodsCardsWrapper>
        </S.FirstSection>

        <S.SecondSection>
          {!mobileWidth ? (
            <S.ProfitCard>
              <Skeleton width="100%" height="100%" style={{ borderRadius: 15 }} />
            </S.ProfitCard>
          ) : (
            <></>
          )}

          <S.SecondSectionCard>
            <Skeleton width="100%" height="100%" style={{ borderRadius: 15 }} />
          </S.SecondSectionCard>

          <S.SecondSectionCard>
            <Skeleton width="100%" height="100%" style={{ borderRadius: 15 }} />
          </S.SecondSectionCard>
        </S.SecondSection>
      </S.SectionsWrapper>
    </S.Container>
  );
};

export default SkeletonLoading;
