import React from 'react';

import { useCreateOrder } from '@helpers/hooks/pages/dashboard/orders/useCreateOrder';
import { INewProductForOrder } from '@domain/interfaces/common/orders/ICreateOrder';
import BlurredVariant from './BlurredProduct';
import FocusedVariant from './FocusedProduct';

import * as S from './styles';

interface IProductCardProps {
  index: number;
  product: INewProductForOrder;
}

const Product: React.FC<IProductCardProps> = ({ product, index }) => {
  const { isCardExpanded, handleIndex, handleExistingProduct, initialProduct } = useCreateOrder();

  React.useEffect(() => {
    if (index) {
      handleIndex(index);
    }

    if (product?.product) {
      handleExistingProduct(product);
    }
  }, [product, index, handleExistingProduct, handleIndex]);

  return (
    <S.Wrapper>
      {isCardExpanded || !initialProduct?.product ? <FocusedVariant /> : <BlurredVariant />}
    </S.Wrapper>
  );
};

export default Product;
