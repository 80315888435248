import React from 'react';
import { ChevronDown } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { useEditVariant } from '@helpers/hooks/pages/dashboard/kit/useEditVariant';

import * as S from './styles';

const BlurredVariant: React.FC = () => {
  const theme = useTheme();
  const { handleCardExpanded, quantity, initialVariant, index } = useEditVariant();

  const [isVariantImageError, setIsVariantImageError] = React.useState<boolean>(false);

  return (
    <S.Wrapper onClick={handleCardExpanded}>
      <S.VariantNumberText fontWeight={EHeadingWeight.MEDIUM}>
        {`#${index + 1}`}
      </S.VariantNumberText>

      {!isVariantImageError && initialVariant?.variant?.image_url ? (
        <S.Image
          src={initialVariant?.variant?.image_url}
          alt="Profitfy.me Variante"
          onError={() => setIsVariantImageError(true)}
        />
      ) : (
        <S.NoImage />
      )}

      <S.ProductDetails>
        <S.ProductName>{initialVariant?.variant?.name}</S.ProductName>
        <S.ProductSku>{`SKU: ${initialVariant?.variant?.sku}`}</S.ProductSku>
      </S.ProductDetails>

      <S.QuantityText>{`Qtd ${quantity}x`}</S.QuantityText>

      <S.ExpandIconWrapper>
        <ChevronDown size={20} color={theme.colors.gray[1]} />
      </S.ExpandIconWrapper>
    </S.Wrapper>
  );
};

export default BlurredVariant;
