import React from 'react';

import { ILink } from '@domain/interfaces/components/ILink';

import * as S from './styles';

const Link: React.FC<ILink> = ({ to, children, ...rest }) => {
  return (
    <S.Container to={to} {...rest}>
      {children}
    </S.Container>
  );
};

export default Link;
