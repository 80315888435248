import styled from 'styled-components/macro';

export const TableFooter = styled.tfoot<any>`
  background-color: ${({ theme }) => theme.colors.gray[5]};
  position: sticky;
  position: -webkit-sticky;
  display: block;
  z-index: ${({ theme }) => theme.zIndex.secondLayer};
  margin-bottom: -97px;
  top: ${({ top }) => `${top}px`};
`;

export const TableBody = styled.tbody``;

export const TableHead = styled.thead`
  background-color: ${({ theme }) => theme.colors.gray[5]};
  position: sticky;
  position: -webkit-sticky;
  top: -53px;
  display: block;
  margin-top: -53px;
  z-index: ${({ theme }) => theme.zIndex.secondLayer};
`;

export const DraggableWrapper = styled.div``;

export const Table = styled.table`
  border-collapse: collapse;
  position: relative;
`;

export const TableWrapper = styled.div``;

export const Wrapper = styled.div`
  width: 100%;
  border-radius: 12px;
`;
