import React from 'react';

import { useToast } from '@helpers/hooks/useToast';
import { Cancel } from '@profitfy/pakkun-icons';
import { DotsThree, PaperPlaneTilt, PencilSimple } from 'phosphor-react';
import { useHistory, useParams } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';
import { useTheme } from 'styled-components';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { IParams } from '@domain/interfaces/IParams';

import { useAutomationsManager } from '@helpers/hooks/pages/dashboard/automations/useAutomationsManager';
import { numberFormatter } from '@helpers/masks';
import { getWorkflowTriggerIcon } from '@helpers/utils/dashboard/automations/AutomationsManager/automationsManagerUtils';

import automationsService from '@services/pages/dashboard/automations/automations';

import DeleteModal from './DeleteModal';

import * as S from './styles';

const Row: React.FC<ListChildComponentProps> = ({ index, style }) => {
  const theme = useTheme();
  const history = useHistory();

  const { toast } = useToast();

  const { storeAliasId } = useParams<IParams>();

  const {
    workflows,
    isDeletingWorkflow,
    deleteWorkflow,
    mutateWorkflows,
    handleTestSideModalOpen,
    handleSelectedAutomationToTest,
  } = useAutomationsManager();

  const switchRef = React.useRef<HTMLInputElement>(null);

  const [isUpdateError, setIsUpdateError] = React.useState<boolean>(false);
  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);

  const onMouseEnter = React.useCallback(() => {
    setIsHovering(true);
  }, []);

  const onMouseLeave = React.useCallback(() => {
    setIsHovering(false);
  }, []);

  const handlePopoverOpen = React.useCallback(() => {
    setIsPopoverOpen(!isPopoverOpen);
  }, [isPopoverOpen]);

  const handleDeleteModalOpen = React.useCallback(() => setIsDeleteModalOpen(!isDeleteModalOpen), [
    isDeleteModalOpen,
  ]);

  const onDeleteClick = React.useCallback(() => {
    setIsPopoverOpen(false);
    handleDeleteModalOpen();
  }, [handleDeleteModalOpen]);

  const workflowData = workflows[index];

  const { metrics, workflow } = workflowData;

  const onTestClick = React.useCallback(() => {
    setIsPopoverOpen(false);
    handleTestSideModalOpen();
    handleSelectedAutomationToTest(workflow);
  }, [handleSelectedAutomationToTest, handleTestSideModalOpen, workflow]);

  const onDelete = React.useCallback(() => {
    deleteWorkflow(workflow.alias_id);
    handleDeleteModalOpen();
  }, [deleteWorkflow, workflow, handleDeleteModalOpen]);

  const activeWorkflow = React.useCallback(
    async workflowAliasId => {
      try {
        await automationsService.updateWorkflow({
          storeAliasId,
          workflowAliasId,
          data: {
            is_active: true,
          },
        });

        toast.success('Workflow ativado com sucesso.');

        mutateWorkflows();
      } catch (error) {
        setIsUpdateError(true);
        toast.error('Ocorreu um problema ao ativar o workflow! Por favor, tente novamente.');
      }
    },
    [toast, storeAliasId, mutateWorkflows],
  );

  const deactiveWorkflow = React.useCallback(
    async workflowAliasId => {
      try {
        await automationsService.updateWorkflow({
          storeAliasId,
          workflowAliasId,
          data: {
            is_active: false,
          },
        });

        toast.success('Workflow desativado com sucesso.');

        mutateWorkflows();
      } catch (error) {
        setIsUpdateError(true);
        toast.error('Ocorreu um problema ao desativar o workflow! Por favor, tente novamente.');
      }
    },
    [toast, storeAliasId, mutateWorkflows],
  );

  const onSwitchChange = React.useCallback(
    event => {
      if (event.target.checked) {
        activeWorkflow(workflow.alias_id);
      } else {
        deactiveWorkflow(workflow.alias_id);
      }
    },
    [activeWorkflow, deactiveWorkflow, workflow],
  );

  const handleEditAutomation = React.useCallback(() => {
    history.push(
      `/${storeAliasId}/dashboard/marketing/automations/canvas/workflow/${workflow.alias_id}`,
    );
  }, [history, storeAliasId, workflow]);

  const {
    icon: triggerIcon,
    backgroundColor: triggerIconBackgroundColor,
    name: triggerName,
  } = getWorkflowTriggerIcon(workflow.type);

  React.useEffect(() => {
    if (isUpdateError) {
      if (switchRef.current) {
        switchRef.current.checked = !switchRef.current.checked;
        setIsUpdateError(false);
      }
    }
  }, [isUpdateError]);

  return (
    <>
      <S.Table.Row style={style} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <S.Table.Data>
          <S.Switch
            innerRef={switchRef}
            name={`switch-${workflow.alias_id}`}
            id={`switch-${workflow.alias_id}`}
            defaultChecked={workflow.is_active}
            onChange={onSwitchChange}
          />
        </S.Table.Data>

        <S.Table.Data>
          <S.TextContent>{workflow.name}</S.TextContent>
        </S.Table.Data>

        <S.Table.Data align={EHeadAlign.RIGHT}>
          <S.TextContent>
            {numberFormatter(metrics.delivery_events_quantity, 2, true)}
          </S.TextContent>
        </S.Table.Data>

        <S.Table.Data align={EHeadAlign.RIGHT}>
          <S.TextContent>
            {numberFormatter(metrics.executed_elements_quantity, 2, true)}
          </S.TextContent>
        </S.Table.Data>

        <S.Table.Data align={EHeadAlign.RIGHT}>
          <S.TextContent>
            {numberFormatter(metrics.recovered_orders.quantity, 2, true)} (
            {`${numberFormatter(metrics.recovered_orders.percentage || 0, 2)}`}%)
          </S.TextContent>
        </S.Table.Data>

        <S.Table.Data align={EHeadAlign.RIGHT}>
          <S.TextContent isPositive={metrics?.recovered_amount > 0}>{`R$ ${numberFormatter(
            metrics.recovered_amount || 0,
            2,
          )}`}</S.TextContent>
        </S.Table.Data>

        <S.Table.Data align={EHeadAlign.CENTER}>
          <S.TriggerIconContent>
            <S.Tooltip>
              <S.Tooltip.Trigger>
                <S.TriggerIconWrapper color={triggerIconBackgroundColor}>
                  {triggerIcon}
                </S.TriggerIconWrapper>
              </S.Tooltip.Trigger>

              <S.Tooltip.Content side="bottom" sideOffset={5}>
                <S.TriggerTooltipContentText>{triggerName}</S.TriggerTooltipContentText>
                <S.Tooltip.Arrow />
              </S.Tooltip.Content>
            </S.Tooltip>
          </S.TriggerIconContent>
        </S.Table.Data>

        <S.Table.Data>
          <S.ActionsButtonWrapper>
            <S.ActionsButton onClick={handleEditAutomation} isHovering={isHovering}>
              <PencilSimple size={16} />
            </S.ActionsButton>

            <S.Popover onOpenChange={handlePopoverOpen} open={isPopoverOpen}>
              <S.Popover.Trigger>
                <S.ActionsButton isHovering={isHovering}>
                  <DotsThree size={24} />
                </S.ActionsButton>
              </S.Popover.Trigger>

              <S.Popover.Content sideOffset={5}>
                <S.ActionButton isDeleteButton onClick={onDeleteClick}>
                  <Cancel outline size={16} color={theme.colors.danger.default} />
                  Excluir
                </S.ActionButton>

                <S.ActionButton isDeleteButton={false} onClick={onTestClick}>
                  <PaperPlaneTilt size={16} />
                  Testar
                </S.ActionButton>
              </S.Popover.Content>
            </S.Popover>
          </S.ActionsButtonWrapper>

          <DeleteModal
            content="Deseja realmente excluir a automação? Essa ação é irreversível!"
            isLoading={isDeletingWorkflow}
            isOpen={isDeleteModalOpen}
            onDelete={onDelete}
            title="Excluir automação"
            toggle={handleDeleteModalOpen}
          />
        </S.Table.Data>
      </S.Table.Row>
      {/* <TestSideModal isOpen={isTestSideModalOpen} toggle={handleTestSideModalOpen} /> */}
    </>
  );
};

export default Row;
