import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';
import LabelBase from '@components/common/core/DataDisplay/Label';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';

export const CredentialDescriptionText = styled(TextBase)``;

export const CredentialDescriptionTitle = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const CredentialDescriptionWrapper = styled.div`
  margin-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[4]};
  padding-bottom: 24px;
`;

export const Content = styled.div``;

export const UpdateCredentialHeading = styled(HeadingBase)`
  margin-bottom: 24px;
`;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Input = styled(TextFieldBase)`
  width: 100%;
`;

export const Label = styled(LabelBase)`
  font-weight: 700;
`;

export const UpdateAndDisableButtonBackground = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.background[11]};
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  left: 0;
`;

export const UpdateAndDisableButtonsWrapper = styled.div`
  display: flex;
`;

export const DisableCredentialButton = styled(ButtonBase)`
  margin-left: 20px;
  border: 2px solid ${({ theme }) => theme.colors.danger.default};
  box-shadow: none;

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.danger.default};
    box-shadow: none;
  }

  & > span {
    color: ${({ theme }) => theme.colors.danger.default};

    &:hover {
      color: ${({ theme }) => theme.colors.danger.default};
    }
  }
`;

export const UpdateCredentialButton = styled(ButtonBase)``;
