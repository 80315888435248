import styled from 'styled-components/macro';

export const NewCustomValueButtonWrapper = styled.div`
  max-width: 152px;
  width: 100%;
`;

export const CategoryButtonWrapper = styled.div`
  max-width: 120px;
  width: 100%;
`;

export const HeaderButtonsWrapper = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
  flex-wrap: wrap;
  max-width: 304px;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  max-width: 258px;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 1262px;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 48px;
  justify-content: space-between;
  gap: 12px;
`;
