import * as yup from 'yup';

export const monetizzeSchema = yup.object().shape({
  webhook_token: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(32, 'Informe uma chave única válida.')
    .max(200, 'Informe uma chave única válida.'),
  consumer_key: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(32, 'Informe uma chave de cliente válida.')
    .max(200, 'Informe uma chave de cliente válida'),
});
