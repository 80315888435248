import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const ContentWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const Title = styled(HeadingBase)``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
