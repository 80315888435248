import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';
import styled from 'styled-components/macro';

export const Question = styled(TextBase)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray[1]};
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.16px;
`;

export const Description = styled(TextBase)`
  line-height: 105%;
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray[1]};
  font-size: 0.875rem;
`;

export const SocialMediaWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.green.default};
  gap: 8px;
`;

export const SocialMediaLink = styled.a`
  text-decoration: none;
`;

export const LinkText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.green.default};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 105%;
`;

export const SubmitButton = styled(ButtonBase)`
  width: 100%;
`;

export const Modal = styled(ModalBase)`
  max-width: 379px;
  width: 100%;

  @media only screen and (max-width: 970px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const Body = styled.div`
  /* padding: 24px 32px 32px 32px; */
  max-width: 379px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
