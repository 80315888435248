import React from 'react';
import FunnelGraph from 'funnel-graph-js';
import 'funnel-graph-js/dist/css/theme.min.css';
import 'funnel-graph-js/dist/css/main.min.css';

import { EHeadingWeight } from '@domain/enums/components/EHeading';

import { useProductDetails } from '@helpers/hooks/pages/dashboard/productAnalytics/useProductDetails';

import { getMarketingReportName } from '@helpers/utils/dashboard/productAnalytic/marketingProvider';

import ComponentError from '@components/common/core/Utils/ComponentError';
import ProviderSelect from './ProviderSelect';

import * as S from './styles';

const FunnelChart: React.FC = () => {
  const {
    funnelChartProvider,
    marketingReport,
    isLoadingMarketingReport,
    isValidatingMarketingReport,
    marketingReportError,
    mutateMarketingReport,
  } = useProductDetails();

  const marketingReportName = getMarketingReportName(funnelChartProvider) as string;

  const data = {
    labels: ['Page View', 'Initiate Checkout', 'Add Payment Info', 'Purchase'],
    colors: ['#25CFBB', '#44EB99'],
    values: [
      marketingReport ? marketingReport[marketingReportName].page_view.quantity : 0,
      marketingReport ? marketingReport[marketingReportName].initiated_checkout.quantity : 0,
      marketingReport ? marketingReport[marketingReportName].add_payment_info.quantity : 0,
      marketingReport ? marketingReport[marketingReportName].purchase.quantity : 0,
    ],
  };

  const [funnel, setFunnel] = React.useState<any>(null);

  const funnelWrapperRef = React.useRef<HTMLDivElement>(null);
  const funnelRef = React.useRef<HTMLDivElement>(null);

  const getWidth = React.useCallback(() => {
    if (funnelWrapperRef.current) {
      const { offsetWidth } = funnelWrapperRef.current;

      if (offsetWidth > 577) return offsetWidth;
    }

    return 577;
  }, []);

  const handleResize = React.useCallback(() => {
    if (funnelWrapperRef.current && funnelRef.current && funnel) {
      funnel.updateWidth(getWidth());
    }
  }, [funnel, getWidth]);

  React.useEffect(() => {
    if (!funnel && marketingReport && funnelRef.current) {
      const funnelGraph = new FunnelGraph({
        container: '.funnel',
        gradientDirection: 'vertical',
        data,
        displayPercent: true,
        direction: 'horizontal',
        height: 188,
        width: getWidth(),
        subLabelValue: 'raw',
      });

      funnelGraph.draw();

      setFunnel(funnelGraph);
    }
  }, [getWidth, data, funnel, marketingReport]);

  React.useEffect(() => {
    if (data && funnel) {
      funnel.updateData(data);
    }
  }, [data, funnel]);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  if (isLoadingMarketingReport || isValidatingMarketingReport) {
    return <div>Loading...</div>;
  }

  if (marketingReportError && !isValidatingMarketingReport) {
    return <ComponentError mutate={mutateMarketingReport} />;
  }

  return (
    <>
      <S.Container>
        <S.Header>
          <S.Title fontWeight={EHeadingWeight.REGULAR}>Performance de Funil</S.Title>

          <S.ProvidersButtonWrapper>
            <ProviderSelect />
            {/* <S.ProviderButton
              onClick={() => handleFunnelChartProvider(EAdSenseProvider.GOOGLE)}
              isSelected={funnelChartProvider === EAdSenseProvider.GOOGLE}
            >
              <Google size={20} />
            </S.ProviderButton> */}
          </S.ProvidersButtonWrapper>
        </S.Header>
        <S.Wrapper ref={funnelWrapperRef}>
          <S.Funnel className="funnel" ref={funnelRef} />
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default FunnelChart;
