import {
  IFinancialReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresGatewaysValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresGatewayValues = (
  currentSelectedStoreFinancialReportData: IFinancialReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresGatewaysValuesResponse => {
  const currentSelectedStoreAppmaxAmountValue =
    currentSelectedStoreFinancialReportData?.gateway?.appmax_amount;

  const currentSelectedStoreCartPandaPayAmountValue =
    currentSelectedStoreFinancialReportData?.gateway?.cart_panda_pay_amount;

  const currentSelectedStoreHubsaleAmountValue =
    currentSelectedStoreFinancialReportData?.gateway?.hub_sale_amount;

  const currentSelectedStoreMercadoPagoAmountValue =
    currentSelectedStoreFinancialReportData?.gateway?.mercado_pago_amount;

  const currentSelectedStorePagSeguroAmountValue =
    currentSelectedStoreFinancialReportData?.gateway?.pag_seguro_amount;

  const currentSelectedStorePagarmeAmountValue =
    currentSelectedStoreFinancialReportData?.gateway?.pagarme_amount;

  const currentSelectedStoreUpnidAmountValue =
    currentSelectedStoreFinancialReportData?.gateway?.upnid_amount;

  const currentSelectStoreZoutiAmountValue =
    currentSelectedStoreFinancialReportData?.gateway?.zouti_amount;

  const currentSelectStoreOtherGatewayAmountValue =
    currentSelectedStoreFinancialReportData?.gateway?.other_gateway_amount;

  const storesAppmaxAmountValues = selectedStoresData.map(
    value => value.financial_report?.gateway?.appmax_amount,
  );

  const storesCartPandaPayAmountValues = selectedStoresData.map(
    value => value.financial_report?.gateway?.cart_panda_pay_amount,
  );

  const storesHubsaleAmountValues = selectedStoresData.map(
    value => value.financial_report?.gateway?.hub_sale_amount,
  );

  const storesMercadoPagoAmountValues = selectedStoresData.map(
    value => value.financial_report?.gateway?.mercado_pago_amount,
  );

  const storesPagSeguroAmountValues = selectedStoresData.map(
    value => value.financial_report?.gateway?.pag_seguro_amount,
  );

  const storesPagarMeAmountValues = selectedStoresData.map(
    value => value.financial_report?.gateway?.pagarme_amount,
  );

  const storesUpnidAmountValues = selectedStoresData.map(
    value => value.financial_report?.gateway?.upnid_amount,
  );

  const storesZoutiAmountValues = selectedStoresData.map(
    value => value.financial_report?.gateway?.zouti_amount,
  );

  const storesOtherGatewayAmountValues = selectedStoresData.map(
    value => value.financial_report?.gateway?.other_gateway_amount,
  );

  const appmaxAmountValues = [...storesAppmaxAmountValues, currentSelectedStoreAppmaxAmountValue];

  const cartPandaPayAmountValues = [
    ...storesCartPandaPayAmountValues,
    currentSelectedStoreCartPandaPayAmountValue,
  ];

  const hubsaleAmountValues = [
    ...storesHubsaleAmountValues,
    currentSelectedStoreHubsaleAmountValue,
  ];

  const mercadoPagoAmountValues = [
    ...storesMercadoPagoAmountValues,
    currentSelectedStoreMercadoPagoAmountValue,
  ];

  const pagSeguroAmountValues = [
    ...storesPagSeguroAmountValues,
    currentSelectedStorePagSeguroAmountValue,
  ];

  const pagarMeAmountValues = [
    ...storesPagarMeAmountValues,
    currentSelectedStorePagarmeAmountValue,
  ];

  const upnidAmountValues = [...storesUpnidAmountValues, currentSelectedStoreUpnidAmountValue];

  const zoutiAmountValues = [...storesZoutiAmountValues, currentSelectStoreZoutiAmountValue];

  const otherGatewayAmountValues = [
    ...storesOtherGatewayAmountValues,
    currentSelectStoreOtherGatewayAmountValue,
  ];

  const reducedAppmaxAmountValues = appmaxAmountValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const reducedCartPandaPayAmountValues = cartPandaPayAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedHubsaleAmountValues = hubsaleAmountValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const reducedMercadoPagoAmountValues = mercadoPagoAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedPagSeguroAmountValues = pagSeguroAmountValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const reducedPagarMeAmountValues = pagarMeAmountValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const reducedUpnidAmountValues = upnidAmountValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const reducedZoutiAmountValues = zoutiAmountValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const reducedOtherGatewayValues = otherGatewayAmountValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  return {
    appmax_amount: reducedAppmaxAmountValues,
    cart_panda_pay_amount: reducedCartPandaPayAmountValues,
    hub_sale_amount: reducedHubsaleAmountValues,
    mercado_pago_amount: reducedMercadoPagoAmountValues,
    pag_seguro_amount: reducedPagSeguroAmountValues,
    pagarme_amount: reducedPagarMeAmountValues,
    upnid_amount: reducedUpnidAmountValues,
    zouti_amount: reducedZoutiAmountValues,
    other_gateway_amount: reducedOtherGatewayValues,
  };
};
