import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { useSuggestions } from '@helpers/hooks/pages/dashboard/ideasChannel/useSuggestions';

import SkeletonLoading from './SkeletonLoading';

import Description from './Description';
import Vote from './Vote';

import * as S from './styles';

const Idea: React.FC = () => {
  const { suggestions, isLoadingSuggestions, isValidatingSuggestions } = useSuggestions();

  const filteredSuggestions = suggestions.filter(
    suggestion => suggestion.description !== 'suggestion_automation',
  );

  const randomArray = Array.from(Array(20).keys());

  if (isLoadingSuggestions || isValidatingSuggestions) {
    return (
      <>
        {randomArray.map(element => (
          <SkeletonLoading key={element} />
        ))}
      </>
    );
  }

  return (
    <>
      {filteredSuggestions?.map(suggestion => {
        return (
          <S.IdeaWrapper key={suggestion.id}>
            <Vote suggestions={suggestion} />

            <Description suggestions={suggestion} />
          </S.IdeaWrapper>
        );
      })}

      {!filteredSuggestions?.length && (
        <S.NoSuggestionInfo type={EHeadingSize.H6} fontWeight={EHeadingWeight.REGULAR}>
          Nenhuma sugestão encontrada, clique em <strong>Nova Ideia</strong> para deixar a sua.
        </S.NoSuggestionInfo>
      )}
    </>
  );
};

export default Idea;
