import React from 'react';
import { SimpleCalendar } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import DatePicker from 'react-datepicker';

import { useDatePickerRange } from '@helpers/hooks/components/useDatePickerRange';
import { useConfig } from '@helpers/hooks/useConfig';
import { useDate } from '@helpers/hooks/useDate';
import Header from './Header';

import * as S from './styles';

const CustomDate: React.FC = () => {
  const theme = useTheme();
  const {
    isCalendarOpen,
    handleCalendarOpen,
    customStartDate,
    customEndDate,
    handleCustomDate,
    maxDate,
    minDate,
  } = useDatePickerRange();
  const { selectedStore, isInternalTeam } = useConfig();
  const { format } = useDate();

  const getNameOfDay = React.useCallback(
    nameOfDay =>
      ({
        Sunday: 'Dom',
        Monday: 'Seg',
        Tuesday: 'Ter',
        Wednesday: 'Qua',
        Thursday: 'Qui',
        Friday: 'Sex',
        Saturday: 'Sab',
      }[nameOfDay]),
    [],
  );

  const dateToSync = selectedStore ? new Date(selectedStore.initial_date_to_sync) : new Date();
  const calendarMinDate = isInternalTeam ? undefined : minDate || dateToSync;

  return (
    <S.Wrapper>
      <S.DateInputGroup>
        <S.DateLabel>Começa em:</S.DateLabel>
        <S.DateButton onClick={handleCalendarOpen}>
          <S.DateText>{customStartDate && format(customStartDate, 'dd/MM/yyyy')}</S.DateText>
          <SimpleCalendar size={16} color={theme.colors.font} outline />
        </S.DateButton>
      </S.DateInputGroup>

      <S.DateInputGroup>
        <S.DateLabel>Termina em:</S.DateLabel>
        <S.DateButton onClick={handleCalendarOpen}>
          <S.DateText>{customEndDate && format(customEndDate, 'dd/MM/yyyy')}</S.DateText>
          <SimpleCalendar size={16} color={theme.colors.font} outline />
        </S.DateButton>
      </S.DateInputGroup>

      <S.CalendarWrapper isOpen={isCalendarOpen}>
        <DatePicker
          selected={customStartDate}
          minDate={calendarMinDate}
          maxDate={maxDate}
          onChange={handleCustomDate}
          startDate={customStartDate}
          endDate={customEndDate}
          showPreviousMonths={false}
          selectsRange
          inline
          formatWeekDay={nameOfDay => getNameOfDay(nameOfDay)}
          peekNextMonth={false}
          renderCustomHeader={({ date, changeYear, decreaseMonth, increaseMonth }) => (
            <Header
              date={date}
              changeYear={changeYear}
              decreaseMonth={decreaseMonth}
              increaseMonth={increaseMonth}
            />
          )}
        />
      </S.CalendarWrapper>
    </S.Wrapper>
  );
};

export default CustomDate;
