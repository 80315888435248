import React from 'react';
import { Evermart as Icon, Copy } from '@profitfy/pakkun-icons';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components/macro';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useInfoProduct } from '@helpers/hooks/pages/onboarding/useInfoProduct';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import evermartService from '@services/pages/dashboard/integrationCenter/infoProduct/platforms/evermartAuth';

import NextStep from '@components/Onboarding/NextStep';

import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Evermart: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { isLoadingTrial, handleStoreBonus } = useOnboarding();
  const { selectedMarketings } = useInfoProduct();
  const { handleSubmit, register } = useForm();

  const [webhookURL, setWebhookURL] = React.useState<string>('');
  const [accountIdValue, setAccountIdValue] = React.useState<string>('');
  const [isGettingWebhookURL, setIsGettingWebhookURL] = React.useState<boolean>(false);
  const [isCreatingCredential, setIsCreatingCredential] = React.useState<boolean>(false);
  const [isURLCopied, setIsURLCopied] = React.useState<boolean>(false);

  const accountIdInputRegister = register('account_id');

  const onAccountIdChange = React.useCallback(
    event => {
      accountIdInputRegister.onChange(event);

      setAccountIdValue(event.target.value);
    },
    [accountIdInputRegister],
  );

  const handleCopyURL = React.useCallback(() => {
    navigator.clipboard.writeText(webhookURL);
    setIsURLCopied(true);
    toast.success('URL copiada com sucesso.');
  }, [toast, webhookURL]);

  const onSubmit = React.useCallback(
    async formData => {
      setIsCreatingCredential(true);

      try {
        await evermartService.createCredential({
          storeAliasId,
          data: { provider_id: formData.account_id },
        });

        toast.success('Credencial criada com sucesso.');

        setIsCreatingCredential(false);

        if (selectedMarketings.length) {
          history.push(`/onboarding/${storeAliasId}/info-product/integrations/marketing`);
        } else {
          await handleStoreBonus();
        }
      } catch (error: any) {
        toast.error(getTranslatedIntegrationsError(error));
      } finally {
        setIsCreatingCredential(false);
      }
    },
    [storeAliasId, toast, selectedMarketings, history, handleStoreBonus],
  );

  const generateWebhookURL = React.useCallback(async () => {
    setIsGettingWebhookURL(true);

    try {
      const { data } = await evermartService.generateWebhookURL({ storeAliasId });

      setWebhookURL(data?.webhook_url);
      setIsGettingWebhookURL(false);
    } catch (error: any) {
      setIsGettingWebhookURL(false);
      toast.error(error?.response?.data?.message);
    }
  }, [toast, storeAliasId]);

  React.useEffect(() => {
    generateWebhookURL();
  }, [generateWebhookURL]);

  if (isGettingWebhookURL) {
    return (
      <>
        <SkeletonLoading />
        <NextStep>Próximo passo</NextStep>
      </>
    );
  }

  return (
    <S.Wrapper>
      <S.FormWrapper>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Header>
            <S.Title>Integre sua plataforma de infoproduto</S.Title>
          </S.Header>

          <S.IntegrationTitle>
            <Icon size={36} />

            <S.IntegrationTitleText fontWeight={EHeadingWeight.REGULAR}>
              Integração com Evermart
            </S.IntegrationTitleText>
          </S.IntegrationTitle>

          <S.Body>
            <S.InputGroup>
              <S.Label>URL do webhook</S.Label>

              <S.WebhookURLWrapper onClick={handleCopyURL} type="button">
                <S.WebhookURL weight={ETextWeight.MEDIUM}>{webhookURL}</S.WebhookURL>

                <S.IconWrapper>
                  <Copy size={18} color={theme.colors.gray[1]} outline={!isURLCopied} />
                </S.IconWrapper>
              </S.WebhookURLWrapper>
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>ID da conta</S.Label>

              <S.Input
                {...accountIdInputRegister}
                type="text"
                value={accountIdValue}
                placeholder="ID da Conta Evermart"
                onChange={onAccountIdChange}
              />
            </S.InputGroup>
          </S.Body>

          <NextStep
            type="submit"
            isLoading={isCreatingCredential || isLoadingTrial}
            disabled={isCreatingCredential || !accountIdValue || isLoadingTrial}
          >
            Próximo passo
          </NextStep>
        </S.Form>
      </S.FormWrapper>

      <S.TutorialWrapper>
        <S.TutorialImage
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/onboarding/images/content/onboarding/onboarding-integration-tutorial.svg"
          alt="Profitfy.me Integration Tutorial"
        />
        <S.TutotialTitle weight={ETextWeight.BOLD}>Tutorial de integração</S.TutotialTitle>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          1 - Faça login na Evermart.
        </S.TutotialStep>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          2 - Copie a URL no campo &quot;URL do Webhook&quot;.
        </S.TutotialStep>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          3 -{' '}
          <S.Link
            href="https://dashboard.mycheckout.com.br/integrations/webhooks/new"
            target="_blank"
          >
            Clique aqui e vá para a página de webhooks da Evermart.
          </S.Link>
        </S.TutotialStep>

        <S.StepsWrapper>
          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              Cole a URL copiada no campo &quot;Enviar para URL&quot;
            </S.StepText>
          </S.StepWrapper>
          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>Clique em &quot;Salvar&quot;</S.StepText>
          </S.StepWrapper>
        </S.StepsWrapper>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          4 -{' '}
          <S.Link href="https://dashboard.mycheckout.com.br/user/account" target="_blank">
            Após Salvar, clique aqui e vá para a página de perfil da Evermart.
          </S.Link>
        </S.TutotialStep>

        <S.StepsWrapper>
          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              Copie o &quot;ID&quot;, que está em &quot;Minha Conta&quot;
            </S.StepText>
          </S.StepWrapper>
          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>Cole no campo &quot;ID da conta&quot;</S.StepText>
          </S.StepWrapper>
        </S.StepsWrapper>
      </S.TutorialWrapper>
    </S.Wrapper>
  );
};

export default Evermart;
