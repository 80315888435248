import React from 'react';
import {
  DeepMap,
  FieldError,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import { useSubscriptionData } from '@helpers/hooks/pages/dashboard/subscription/useSubscriptionData';

import { cpfFormatter, phone } from '@helpers/masks';

import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

interface INaturalPersonFormProps {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
}

const NaturalPersonForm: React.FC<INaturalPersonFormProps> = ({ register, errors, setValue }) => {
  const { naturalPerson } = useSubscriptionData();

  const documentRegister = register('document');
  const phoneNumberRegister = register('phone_number');

  const onDocumentChange = React.useCallback(
    event => {
      event.target.value = cpfFormatter(event.target.value);
      documentRegister.onChange(event);
    },
    [documentRegister],
  );

  const onPhoneNumberChange = React.useCallback(
    event => {
      event.target.value = phone(event.target.value);
      phoneNumberRegister.onChange(event);
    },
    [phoneNumberRegister],
  );

  React.useEffect(() => {
    if (naturalPerson) {
      setValue('document', cpfFormatter(naturalPerson.cpf));
      setValue('phone_number', phone(`${naturalPerson.phone_prefix}${naturalPerson.phone_number}`));
    }
  }, [naturalPerson, setValue]);

  const isDocumentError = Boolean(errors.document);
  const isPhoneNumberError = Boolean(errors.phone_number);

  return (
    <S.Wrapper>
      <S.InputGroup>
        <S.Label>CPF</S.Label>
        <S.Input
          {...documentRegister}
          type="text"
          placeholder="Digite o CPF"
          onChange={onDocumentChange}
          isError={isDocumentError}
        />
        {errors.document && <Text isErrorFeedback>{errors.document.message}</Text>}
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>Celular</S.Label>
        <S.Input
          {...phoneNumberRegister}
          type="text"
          placeholder="Digite o celular"
          onChange={onPhoneNumberChange}
          isError={isPhoneNumberError}
        />
        {errors.phone_number && <Text isErrorFeedback>{errors.phone_number.message}</Text>}
      </S.InputGroup>
    </S.Wrapper>
  );
};

export default NaturalPersonForm;
