import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Button = styled(ButtonBase)`
  margin-left: auto;
`;

export const Description = styled(TextBase)`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray[1]};
  line-height: 150%;
`;

export const Title = styled(TextBase)`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
`;

export const Modal = styled(ModalBase)`
  max-width: 464px;
`;
