import styled from 'styled-components/macro';

export const CategoriesWrapper = styled.div`
  width: 100%;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.background[2]};

  & > div:not(:last-child) {
    border-bottom: 2px solid ${({ theme }) => theme.colors.gray[4]};
  }
`;
