import React from 'react';

import { EProgressBarStep } from '@domain/enums/onboarding/progressBar/ESteps';

import * as S from './styles';

const ProgressBarHorizontal: React.FC = () => {
  const [isCreateStoreIconCompleted, setIsCreateStoreIconCompleted] = React.useState<boolean>(
    false,
  );
  const [isCreateStoreStepCompleted, setIsCreateStoreStepCompleted] = React.useState<boolean>(
    false,
  );
  const [isIntegrationIconCompleted, setIsIntegrationIconCompleted] = React.useState<boolean>(
    false,
  );
  const [step, setStep] = React.useState<EProgressBarStep>(EProgressBarStep.CREATE_STORE);

  const handleCompleteCreateStoreIcon = React.useCallback((): any => {
    const timer = setTimeout(() => {
      setIsCreateStoreIconCompleted(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleCompleteCreateStore = React.useCallback(() => {
    const timer = setTimeout(() => {
      setIsCreateStoreStepCompleted(true);
      setStep(EProgressBarStep.INTEGRATIONS);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const handleCompleteIntegrationIcon = React.useCallback(() => {
    const timer = setTimeout(() => {
      setIsIntegrationIconCompleted(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const handleCompleteIntegration = React.useCallback(() => {
    const timer = setTimeout(() => {
      setStep(EProgressBarStep.GENERAL_CONFIG);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  React.useEffect(() => {
    handleCompleteCreateStoreIcon();
    handleCompleteCreateStore();
    handleCompleteIntegrationIcon();
    handleCompleteIntegration();
  }, [
    handleCompleteCreateStoreIcon,
    handleCompleteCreateStore,
    handleCompleteIntegrationIcon,
    handleCompleteIntegration,
  ]);

  return (
    <S.Wrapper>
      <S.ProgressAndNameStepsWrapper>
        <S.ProgressWrapper>
          <S.ProgressBackground>
            <S.ProgressBar step={step} />
          </S.ProgressBackground>

          <S.CreateStoreProgressCircle isComplete={isCreateStoreIconCompleted}>
            {isCreateStoreIconCompleted && (
              <S.Icon>
                <polyline points="20 6 9 17 4 12" />
              </S.Icon>
            )}
            <S.StepName isComplete={isCreateStoreStepCompleted}>Cadastro de Loja</S.StepName>
          </S.CreateStoreProgressCircle>

          <S.IntegrationsProgressCircle step={step} isComplete={isIntegrationIconCompleted}>
            {isIntegrationIconCompleted && (
              <S.Icon>
                <polyline points="20 6 9 17 4 12" />
              </S.Icon>
            )}
            <S.StepName isComplete={isIntegrationIconCompleted}>Integrar plataformas</S.StepName>
          </S.IntegrationsProgressCircle>

          <S.GeneralConfigProgressCircle step={step} isComplete={false}>
            {false && (
              <S.Icon>
                <polyline points="20 6 9 17 4 12" />
              </S.Icon>
            )}
            <S.StepName isComplete={false}>Configurações gerais</S.StepName>
          </S.GeneralConfigProgressCircle>

          <S.FinishStepProgressCircle step={step} isComplete={false}>
            {false && (
              <S.Icon>
                <polyline points="20 6 9 17 4 12" />
              </S.Icon>
            )}
            <S.StepName isComplete={false}>Finalizar</S.StepName>
          </S.FinishStepProgressCircle>
        </S.ProgressWrapper>
      </S.ProgressAndNameStepsWrapper>
    </S.Wrapper>
  );
};

export default ProgressBarHorizontal;
