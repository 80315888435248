import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';

export const ManualIconTrigger = styled(CustomPopoverBase.Trigger)`
  width: 21px;
  height: 21px;
  border-radius: 11px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  position: absolute;
  top: -5px;
  right: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Popover = styled(CustomPopoverBase)``;

export const OrderDate = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
  font-size: 0.75rem;
  font-weight: 400;
  margin-top: 2px;
`;

export const OrderNumber = styled.a`
  color: #00f2a1;
  display: block;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.green.default};
  }
`;

export const OrderTextWrapper = styled.div``;

export const IconWrapper = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  background-color: ${({ theme }) => theme.colors.background[3]};
  position: relative;
`;

export const OrderDetails = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ManuallyIconPopoverContent = styled(TextBase)`
  width: 195px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 10px;
  font-size: 0.875rem;
`;
