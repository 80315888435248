import styled from 'styled-components/macro';

export const Wrapper = styled.button`
  background-color: unset;
  border: unset;
  padding: unset;
  padding: 8px 0;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.green.default};
  font-size: 0.875rem;
  font-weight: 400;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;
