import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import TextBase from '@components/common/core/DataDisplay/Text';
import SwitchBase from '@components/common/core/Inputs/Switch';
import BadgeBase from '@components/common/core/DataDisplay/Badge';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';

export const TooltipText = styled(TextBase)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const TooltipContent = styled.div`
  max-width: 242px;
  width: 100%;
`;

export const Badge = styled(BadgeBase)`
  margin: 0 auto;
`;

export const Switch = styled(SwitchBase)`
  width: 100px;
  margin: 0 auto;
`;

export const CampaignName = styled(TextBase)`
  font-size: 0.875rem;
  max-width: 600px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 140px;

  &:hover {
    overflow: visible;
    white-space: normal;
    height: auto;
  }
`;

export const CampaignNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Checkbox = styled(CheckboxBase)``;

export const Table = styled(TableBase)``;

export const ProviderStatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 90px;

  &:hover {
    overflow: visible;
    white-space: normal;
    height: auto;
  }
`;

export const ProviderStatusText = styled(TextBase)`
  font-size: 0.875rem;
  max-width: 600px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`;

export const CustomTooltip = styled(CustomTooltipBase)``;

export const Text = styled(TextBase)`
  width: 200px;
`;
