import React from 'react';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { Checks } from 'phosphor-react';
import * as S from './styles';

const Header: React.FC = () => {
  const { handleMarkAsReadFeed } = useStoreConfig();
  return (
    <S.Wrapper>
      <S.Title>Avisos</S.Title>

      <S.MarkAsRead onClick={handleMarkAsReadFeed} iconPrepend={Checks}>
        Marcar todos como lido
      </S.MarkAsRead>
    </S.Wrapper>
  );
};

export default Header;
