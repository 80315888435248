/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-danger */
import React from 'react';
import { Play } from 'phosphor-react';
import useWindowSize from 'react-use/lib/useWindowSize';

import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';

import { useCanvasMessage } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessage';
import { useCanvasMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessageSettings';
import { useCanvasContent } from '@helpers/hooks/pages/dashboard/automations/useCanvasContent';

import { getAudioWaves, processText } from '@helpers/utils/dashboard/automations/preview';

import * as S from './styles';

const Preview: React.FC = () => {
  const { width: screenWidthSize } = useWindowSize();
  const { isUpdatingMessage } = useCanvasMessage();
  const { contentsToUpdate } = useCanvasMessageSettings();
  const { textPreview, imagePreview, selectedContentType, videoPreview } = useCanvasContent();

  const hasContent = Boolean(contentsToUpdate?.length);

  const filteredNewContentsToUpdate = contentsToUpdate.filter(content => !content.shouldBeExcluded);

  const hasSameTextContent = filteredNewContentsToUpdate.find(
    savedContent => savedContent.content === textPreview,
  );

  const hasSameImageContent = filteredNewContentsToUpdate.find(savedContent => {
    const content = savedContent.content as File;

    if (savedContent.type === EConfigOption.IMAGE && content?.name === imagePreview?.name) {
      return true;
    }

    return false;
  });

  const hasSameVideoContent = filteredNewContentsToUpdate.find(savedContent => {
    const content = savedContent.content as File;

    if (savedContent.type === EConfigOption.VIDEO && content?.name === imagePreview?.name) {
      return true;
    }

    return false;
  });

  const blob = imagePreview ? new Blob([imagePreview], { type: 'image/png' }) : undefined;

  const previewImageSource = blob ? URL.createObjectURL(blob as Blob & { type: 'image/png' }) : '';

  const videoBlog = videoPreview ? new Blob([videoPreview], { type: 'video/mp4' }) : undefined;

  const previewVideoSource = videoBlog
    ? URL.createObjectURL(videoBlog as Blob & { type: 'video/mp4' })
    : '';

  const showPreviewText =
    imagePreview && !hasSameTextContent && selectedContentType !== EConfigOption.NONE;

  const showPreviewImage =
    imagePreview?.name && !hasSameImageContent && selectedContentType !== EConfigOption.NONE;

  const showPreviewVideo =
    videoPreview?.name && !hasSameVideoContent && selectedContentType !== EConfigOption.NONE;

  const audioWaves = React.useMemo(() => {
    return getAudioWaves(screenWidthSize);
  }, [screenWidthSize]);

  return (
    <S.Wrapper>
      <S.Preview>
        {filteredNewContentsToUpdate?.map((contentItem, index) => {
          const isFirstItemOfArray = index === 0;

          if (contentItem.type === EConfigOption.TEXT) {
            return (
              <S.TextContent key={contentItem.id} isFirstItemOfArray={isFirstItemOfArray}>
                <S.MessageText
                  dangerouslySetInnerHTML={{ __html: processText(contentItem.content as string) }}
                />

                <S.Time>11:47</S.Time>

                {isFirstItemOfArray && <S.ContentArrow />}
              </S.TextContent>
            );
          }

          if (contentItem.type === EConfigOption.IMAGE) {
            let imageSource = '';

            if (typeof contentItem.content === 'string') {
              imageSource = contentItem.content as string;
            } else {
              imageSource = URL.createObjectURL(contentItem.content as File);
            }

            return (
              <S.ImageContent key={contentItem.id}>
                <S.Image src={imageSource} alt="" />

                <S.Time>11:47</S.Time>
              </S.ImageContent>
            );
          }

          if (contentItem.type === EConfigOption.VIDEO) {
            let videoSource = '';

            if (typeof contentItem.content === 'string') {
              videoSource = contentItem.content as string;
            } else {
              videoSource = URL.createObjectURL(contentItem.content as File);
            }

            return (
              <S.VideoContent key={contentItem.id}>
                <S.Video src={videoSource} controls />

                <S.Time>11:47</S.Time>
              </S.VideoContent>
            );
          }

          if (contentItem.type === EConfigOption.AUDIO) {
            return (
              <S.AudioContent>
                <S.IconWrapper>
                  <Play size={20} color="#9C8D8D" weight="fill" />
                </S.IconWrapper>

                <S.BulletAndWavesWrapper>
                  <S.AudioBullet />

                  <S.WavesWrapper>
                    {audioWaves.map(wave => {
                      return <S.Wave key={wave.value} height={wave.height} />;
                    })}
                  </S.WavesWrapper>

                  <S.CurrentTimeText>0:00</S.CurrentTimeText>

                  <S.TotalTimeText>1:20</S.TotalTimeText>
                </S.BulletAndWavesWrapper>

                <S.Avatar />
              </S.AudioContent>
            );
          }

          return <></>;
        })}

        {showPreviewText && (
          <S.TextContent>
            <S.MessageText dangerouslySetInnerHTML={{ __html: processText(textPreview) }} />

            <S.Time>11:47</S.Time>
          </S.TextContent>
        )}

        {showPreviewImage && (
          <S.ImageContent>
            <S.Image src={previewImageSource} alt="" />

            <S.Time>11:47</S.Time>
          </S.ImageContent>
        )}

        {showPreviewVideo && (
          <S.VideoContent>
            <S.Video src={previewVideoSource} controls />

            <S.Time>11:47</S.Time>
          </S.VideoContent>
        )}
      </S.Preview>

      <S.UpdateMessageButton
        type="submit"
        isLoading={isUpdatingMessage}
        disabled={!hasContent || isUpdatingMessage}
      >
        Atualizar mensagem
      </S.UpdateMessageButton>
    </S.Wrapper>
  );
};

export default Preview;
