import {
  Wallet,
  ShoppingBagOpen,
  PlugsConnected,
  CurrencyCircleDollar,
  Palette,
  Headset,
} from 'phosphor-react';

export const CARDS = [
  {
    icon: Wallet,
    title: 'Redução de custo',
    description:
      'Para lojistas de nichos disputados, ter o custo reduzido te permite ser mais atrativo.',
  },
  {
    icon: ShoppingBagOpen,
    title: 'Fornecedores confiáveis',
    description:
      'Fornecedores confiáveis é o que torna a Zendrop uma plataforma essencial para escalar seu negócio.',
  },
  {
    icon: PlugsConnected,
    title: 'Facilidade de integração',
    description:
      'A integração é feita por um plugin simples à sua loja, com apenas alguns cliques.',
  },
  {
    icon: CurrencyCircleDollar,
    title: 'Melhores preços',
    description:
      'Evite camadas e camadas de intermediários que causam preços  altos e pouca margem de lucro.',
  },
  {
    icon: Palette,
    title: 'Desenvolvimento da marca',
    description:
      'Transforme sua loja em uma marca elevada usando a embalagem e rotulagem personalizadas.',
  },
  {
    icon: Headset,
    title: 'Suporte eficiente',
    description:
      'Canal de suporte exclusivo, com equipe de sucesso à disposição para responder o mais rápido possível.',
  },
];
