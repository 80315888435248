import { EGatewayPaymentType } from '@domain/enums/common/EGatewayPaymentType';
import { IOtherGateway } from '@domain/interfaces/dashboard/IntegrationCenter/Gateway/IOtherGateway';
import { IGetFormInitialValueResponse } from '@domain/interfaces/utils/integrations/otherGateway';

import { currencyFormatter, numberFormatter } from '@helpers/masks';

export const getFormInitialValue = (
  otherGateways: IOtherGateway[],
  selectedStoreId: string | undefined,
): IGetFormInitialValueResponse => {
  const foundOtherGatewayName = otherGateways.find(gateway => gateway.store_id === selectedStoreId);

  const foundPix = otherGateways.find(gateway => gateway.type === EGatewayPaymentType.PIX);
  const foundBoleto = otherGateways.find(gateway => gateway.type === EGatewayPaymentType.BOLETO);
  const foundCard = otherGateways.find(gateway => gateway.type === EGatewayPaymentType.CARD);

  const formattedPixAmount = foundPix ? numberFormatter(foundPix.amount, 2) : '';
  const formattedBoletoAmount = foundBoleto ? numberFormatter(foundBoleto.amount, 2) : '';
  const formattedCardAmount = foundCard ? numberFormatter(foundCard.amount, 2) : '';
  const formattedCardTransactionAmount = foundCard
    ? numberFormatter(foundCard.transaction_amount, 2)
    : '';

  const pixInitialValue = currencyFormatter(formattedPixAmount);
  const pixTaxTypeInitialValue = foundPix?.is_percentage ? 'percentage' : 'fixed';

  const boletoInitialValue = currencyFormatter(formattedBoletoAmount);
  const boletoTaxTypeInitialValue = foundBoleto?.is_percentage ? 'percentage' : 'fixed';

  const creditCardInitialValue = currencyFormatter(formattedCardAmount);
  const creditCardTransactionAmountInitialValue = currencyFormatter(formattedCardTransactionAmount);

  const gatewayNameInitialValue = foundOtherGatewayName?.name || '';

  return {
    pixInitialValue,
    pixTaxTypeInitialValue,
    boletoInitialValue,
    boletoTaxTypeInitialValue,
    creditCardInitialValue,
    gatewayNameInitialValue,
    creditCardTransactionAmountInitialValue,
  };
};
