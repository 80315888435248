import { AxiosResponse } from 'axios';

import {
  IListStoreTransactionsPromiseResponse,
  IListStoreTransactionsProps,
  IListStoreTransactionsResponse,
} from '@domain/interfaces/common/transaction/ITransaction';

import { useFetch } from '@providers/Fetch/useFetch';

import { ApiService } from '@services/api';

import { USER_API_DOMAIN } from '@constants/api';

export class TransactionService {
  private readonly apiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listStoreTransactions({
    storeAliasId,
    page,
  }: IListStoreTransactionsProps): IListStoreTransactionsResponse {
    const { data, mutate, error, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/transactions?limit=10&page=${page + 1}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      transactions: data?.transactions,
      pageCount: data?.total_pages,
      error,
      isLoading,
      isValidating,
      mutate,
    };
  }

  public listStoreTransactionsPromise({
    storeAliasId,
    page,
  }: IListStoreTransactionsProps): Promise<AxiosResponse<IListStoreTransactionsPromiseResponse>> {
    return this.apiService.get<IListStoreTransactionsPromiseResponse>(
      `/api/v1/users/stores/${storeAliasId}/transactions?limit=10&page=${page + 1}`,
    );
  }
}

const transactionService = new TransactionService(USER_API_DOMAIN);

export default transactionService;
