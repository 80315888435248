import styled from 'styled-components';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Content = styled.div`
  max-width: 1260px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Logo = styled.img`
  margin-bottom: 30px;
  max-width: 152px;
`;

export const InfosWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media only screen and (max-width: 930px) {
    flex-direction: column;
    gap: 42px;
  }
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
`;

export const ContactTitle = styled(HeadingBase)`
  font-weight: 600;
`;

export const ContactNumber = styled(TextBase)``;

export const ContactEmail = styled(TextBase)``;

export const OpeningHours = styled(TextBase)``;

export const CNPJ = styled(TextBase)`
  font-weight: 700;
  margin-top: 7px;
`;

export const Address = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
`;

export const AddressTitle = styled(HeadingBase)`
  font-weight: 600;
`;

export const FullAddress = styled(TextBase)``;

export const About = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
`;

export const AboutTitle = styled(HeadingBase)`
  font-weight: 600;
`;

export const TermsLink = styled.a`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 1rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.gray[2]};
  text-decoration: none;
  transition: all 0.3s;

  &:hover {
    color: ${({ theme }) => theme.colors.green.default};
  }

  &:active {
    color: ${({ theme }) => theme.colors.green.dark};
  }
`;

export const SocialMediaAndCopyrightWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 930px) {
    margin-top: 42px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
`;

export const Copyright = styled(TextBase)``;

export const SocialMediaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

export const SocialMediaLink = styled.a`
  background-color: #23262f;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Facebook = styled.img``;

export const Instagram = styled.img``;

export const Linkedin = styled.img``;

export const Youtube = styled.img``;

export const Container = styled.footer`
  background: #000000;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-bottom: -32px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
`;
