import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import TextBase from '@components/common/core/DataDisplay/Text';
import TextFieldBase from '@components/common/core/Inputs/TextField';

export const ErrorWrapper = styled.div`
  max-width: 1262px;
  width: 100%;
  border-radius: 15px;
  padding: 64px;
  background-color: ${({ theme }) => theme.colors.gray[5]};
`;

export const VariantsTable = styled(TableBase)``;

export const ProductWrapper = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
`;

export const Text = styled(TextBase)``;

export const Input = styled(TextFieldBase)`
  background-color: ${({ theme }) => theme.colors.background[3]};
  max-width: 105px;
`;
