import React from 'react';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { useCheckoutFee } from '@helpers/hooks/pages/dashboard/checkoutFee/useCheckoutFee';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Header: React.FC = () => {
  const {
    isLoadingCheckoutFee,
    isValidatingCheckoutFee,
    handleNewCheckoutFeeSideModalOpen,
  } = useCheckoutFee();

  if (isLoadingCheckoutFee || isValidatingCheckoutFee) {
    return <SkeletonLoading />;
  }

  return (
    <S.Wrapper>
      <S.Title>Taxas de vendas / checkout</S.Title>

      <S.AddNewTax variant={EButtonVariant.SECONDARY} onClick={handleNewCheckoutFeeSideModalOpen}>
        Configurar taxa
      </S.AddNewTax>
    </S.Wrapper>
  );
};

export default Header;
