import React from 'react';

import { useParams } from 'react-router-dom';

import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { useDate } from '@helpers/hooks/useDate';
import { IParams } from '@domain/interfaces/IParams';
import { EStatus } from '@domain/enums/subscription/ESubscription';
import { IPeriod } from '@domain/interfaces/components/IDatePicker';
import { ISelectedFilter } from '@domain/interfaces/components/IFilter';
import { ISelectedField } from '@domain/interfaces/components/ISort';
import { parseDataFilter } from '@helpers/utils/components/filter';
import { IOrdersProvider, INewProductForOrder } from '@domain/interfaces/common/orders/IOrders';
import { EModalStep } from '@domain/enums/common/EStepsModal';

import ordersService from '@services/pages/dashboard/orders/orders';

const OrdersContext = React.createContext<IOrdersProvider | null>(null);

export const OrdersProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();
  const { utcToZonedTime, differenceInDays, addDays } = useDate();
  const { subscription, hasAllPermissions } = useStoreSubscription();
  const [createOrderStep, setCreateOrderStep] = React.useState<EModalStep>(EModalStep.FIRST_STEP);
  const [isCreateOrderModalOpen, setIsCreateOrderModalOpen] = React.useState<boolean>(false);
  const [newProductsForOrder, setNewProductsForOrder] = React.useState<Array<INewProductForOrder>>(
    [],
  );
  const [isCreatingOrder, setIsCreatingOrder] = React.useState<boolean>(false);

  const [index, setIndex] = React.useState<number>(0);

  const handleIndex = React.useCallback(newIndex => setIndex(newIndex), []);

  const getMaxDate = React.useCallback(() => {
    if (
      subscription?.plan?.identifier.includes('free') &&
      subscription.exceeded_order_limit_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(subscription?.exceeded_order_limit_at);
    }

    const isSubscriptionPeriodEndedAtAfterNow = subscription?.period_ended_at
      ? differenceInDays(utcToZonedTime(new Date()), new Date(subscription.period_ended_at)) >= 0
      : true;

    if (subscription?.status === EStatus.CANCELED && isSubscriptionPeriodEndedAtAfterNow) {
      return utcToZonedTime(new Date());
    }

    if (
      subscription?.status === EStatus.CANCELED &&
      subscription?.period_ended_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(addDays(new Date(subscription.period_ended_at), 1));
    }

    if (
      subscription?.status === EStatus.CANCELED &&
      subscription?.canceled_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(subscription.canceled_at);
    }

    if (subscription && subscription.status === EStatus.TRIAL_ENDED && !hasAllPermissions) {
      return utcToZonedTime(addDays(new Date(subscription.period_ended_at), 1));
    }

    return utcToZonedTime(new Date());
  }, [subscription, utcToZonedTime, hasAllPermissions, differenceInDays, addDays]);

  const initialDate = getMaxDate();

  const [period, setPeriod] = React.useState<IPeriod>({
    startDate: initialDate,
    endDate: initialDate,
  });
  const [ordersPage, setOrdersPage] = React.useState<number>(0);
  const [ordersPageCount, setOrdersPageCount] = React.useState<number>(0);
  const [filterData, setFilterData] = React.useState<Array<ISelectedFilter>>([]);
  const [sortData, setSortData] = React.useState<ISelectedField | undefined>(undefined);

  const {
    orders,
    metric,
    totalPages: ordersTotalPages,
    isLoading: isLoadingOrders,
    isValidating: isValidatingOrders,
    mutate: mutateOrders,
    error: ordersError,
  } = ordersService.listOrdersWithLineItem({
    storeAliasId,
    page: ordersPage,
    startDate: period.startDate,
    endDate: period.endDate,
    filter: parseDataFilter(filterData),
    sort: sortData,
  });

  const mutate = React.useCallback(() => {
    const promises = [];

    if (ordersError) promises.push(mutateOrders());

    Promise.all(promises);
  }, [ordersError, mutateOrders]);

  const handleFilterData = React.useCallback(newData => {
    setFilterData(newData);
  }, []);

  const handleSortData = React.useCallback(newData => {
    setSortData(newData);
  }, []);

  const handleCreateOrderStep = React.useCallback(step => setCreateOrderStep(step), []);
  const handleCreateOrderStepReset = React.useCallback(
    () => setCreateOrderStep(EModalStep.FIRST_STEP),
    [],
  );

  const toggleCreateOrderModal = React.useCallback(() => {
    setIsCreateOrderModalOpen(state => !state);
  }, []);

  const handleNewProductsForOrder = React.useCallback(products => {
    setNewProductsForOrder(products);
  }, []);

  const addProductForOrder = React.useCallback(
    (product: INewProductForOrder) => {
      handleNewProductsForOrder([...newProductsForOrder, product]);
    },
    [handleNewProductsForOrder, newProductsForOrder],
  );

  const handlePageChange = React.useCallback(({ selected }) => setOrdersPage(selected), []);

  React.useEffect(() => {
    if (ordersTotalPages) {
      setOrdersPageCount(ordersTotalPages);
    }
  }, [ordersTotalPages]);

  React.useEffect(() => {
    if (period && filterData) {
      setOrdersPage(0);
    }
  }, [period, filterData]);

  const isLoadingOrdersProvider = isLoadingOrders;
  const isValidatingOrdersProvider = isValidatingOrders;
  const isOrdersProviderError = Boolean(ordersError);

  return (
    <OrdersContext.Provider
      value={{
        handleFilterData,
        handlePageChange,
        handleSortData,
        mutate,
        setPeriod,
        filterData,
        isLoadingOrdersProvider,
        isOrdersProviderError,
        isValidatingOrdersProvider,
        metric,
        orders,
        ordersPage,
        ordersPageCount,
        period,
        sortData,
        mutateOrders,
        createOrderStep,
        handleCreateOrderStep,
        isCreateOrderModalOpen,
        toggleCreateOrderModal,
        newProductsForOrder,
        handleNewProductsForOrder,
        addProductForOrder,
        index,
        handleIndex,
        isCreatingOrder,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

export const useOrders = (): IOrdersProvider => {
  const context = React.useContext(OrdersContext);

  if (!context) {
    throw new Error('useOrders must be used within OrdersProvider');
  }

  return context;
};
