import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { getEnvironment } from '@helpers/utils/environment';

export class ApiService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = axios.create({ baseURL, withCredentials: true });

    this.api.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.message !== 'Email/Password incorrect' &&
          error?.response?.data?.message !== 'Shopify unauthorized' &&
          error?.response?.data?.integration_message !== 'Request failed with status code 401'
        ) {
          delete this.api.defaults.headers.common.authorization;
          window.localStorage.removeItem(`@profitfy:${getEnvironment()}/user`);
          window.localStorage.removeItem(`@profitfy:${getEnvironment()}/personal-info`);
          window.localStorage.removeItem(`@profitfy:${getEnvironment()}/authorization`);
          window.localStorage.removeItem(`@profitfy:${getEnvironment()}/stores`);
          window.localStorage.removeItem(`@profitfy:${getEnvironment()}/synchronizations`);
          window.localStorage.removeItem(`@profitfy:${getEnvironment()}/synchronizationsQueue`);
          window.localStorage.removeItem(`@profitfy:${getEnvironment()}/currentSynchronizations`);
          window.localStorage.removeItem(`@profitfy:${getEnvironment()}/synchronizationsInQueue`);
          window.location.href = window.location.origin;
        }

        return Promise.reject(error);
      },
    );
  }

  public get<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const authorization = localStorage.getItem(`@profitfy:${getEnvironment()}/authorization`);

    if (authorization) {
      this.api.defaults.headers.authorization = JSON.parse(authorization);
    }

    return this.api.get<T>(`${process.env.REACT_APP_STAGE}${url}`, config);
  }

  public post<T = any>(
    url: string,
    data?: Record<string, any>,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    const authorization = localStorage.getItem(`@profitfy:${getEnvironment()}/authorization`);

    if (authorization) {
      this.api.defaults.headers.authorization = JSON.parse(authorization);
    }

    return this.api.post<T>(`${process.env.REACT_APP_STAGE}${url}`, data, config);
  }

  public put<T = any>(
    url: string,
    data?: Record<string, any>,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    const authorization = localStorage.getItem(`@profitfy:${getEnvironment()}/authorization`);

    if (authorization) {
      this.api.defaults.headers.authorization = JSON.parse(authorization);
    }

    return this.api.put<T>(`${process.env.REACT_APP_STAGE}${url}`, data, config);
  }

  public delete<T = any>(
    url: string,
    data?: Record<string, any>,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    const authorization = localStorage.getItem(`@profitfy:${getEnvironment()}/authorization`);

    if (authorization) {
      this.api.defaults.headers.authorization = JSON.parse(authorization);
    }

    return this.api.delete<T>(`${process.env.REACT_APP_STAGE}${url}`, { ...config, data });
  }
}
