import React from 'react';
import { useParams } from 'react-router-dom';

import {
  ICheckoutFeeProvider,
  IPeriodCheckoutFee,
} from '@domain/interfaces/dashboard/CheckoutFee/ICheckoutFee';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useDate } from '@helpers/hooks/useDate';
import { useConfig } from '@helpers/hooks/useConfig';
import { useToast } from '@helpers/hooks/useToast';
import { IParams } from '@domain/interfaces/IParams';
import checkoutFeeService from '@services/pages/dashboard/checkoutFee/checkoutFee';
import {
  ESynchronizationRequestType,
  ESynchronizationType,
} from '@domain/enums/hooks/ESynchronization';
import { LONG_API_DOMAIN } from '@constants/api';
import { getEnvironment } from '@helpers/utils/environment';

const CheckoutFeeContext = React.createContext<ICheckoutFeeProvider | null>(null);

export const CheckoutFeeProvider: React.FC = ({ children }) => {
  const { addSynchronization } = useSynchronization();
  const { format, utcToZonedTime } = useDate();
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { analytics, selectedStore, user } = useConfig();

  const [historicCheckoutFeePage, setHistoricCheckoutFeePage] = React.useState<number>(0);
  const [historicCheckoutFeePageCount, setHistoricCheckoutFeePageCount] = React.useState<number>(1);
  const [isEditingCheckoutFee, setIsEditingCheckoutFee] = React.useState<boolean>(false);
  const [userPeriodCheckoutFee, setUserPeriodCheckoutFee] = React.useState<
    IPeriodCheckoutFee | undefined
  >(undefined);
  const [
    isConfiguringDefaultCheckoutFee,
    setIsConfiguringDefaultCheckoutFee,
  ] = React.useState<boolean>(false);
  const [isCreatingPeriodCheckoutFee, setIsCreatingPeriodCheckoutFee] = React.useState<boolean>(
    false,
  );
  const [isUpdatingHistoricCheckoutFee, setIsUpdatingHistoricCheckoutFee] = React.useState<boolean>(
    false,
  );
  const [isDeletingHistoricCheckoutFee, setIsDeletingHistoricCheckoutFee] = React.useState<boolean>(
    false,
  );
  const [
    isSyncPastCheckoutFeeModalOpen,
    setIsSyncPastCheckoutFeeModalOpen,
  ] = React.useState<boolean>(false);
  const [
    isSyncPastCheckoutFeeSidemodalOpen,
    setIsSyncPastCheckoutFeeSidemodalOpen,
  ] = React.useState<boolean>(false);
  const [isNewCheckoutFeeSideModalOpen, setIsNewCheckoutFeeSideModalOpen] = React.useState<boolean>(
    false,
  );
  const [
    isEditCheckoutFeeSideModalOpen,
    setIsEditCheckoutFeeSideModalOpen,
  ] = React.useState<boolean>(false);

  const {
    checkoutFee: defaultCheckoutFee,
    error: defaultCheckoutFeeError,
    isValidating: isValidatingDefaultCheckoutFee,
    isLoading: isLoadingDefaultCheckoutFee,
    mutate: mutateDefaultCheckoutFee,
  } = checkoutFeeService.getDefaultCheckoutFee({ storeAliasId });
  const {
    checkoutFees: historicCheckoutFees,
    error: historicCheckoutFeesError,
    isValidating: isValidatingHistoricCheckoutFees,
    isLoading: isLoadingHistoricCheckoutFees,
    mutate: mutateHistoricCheckoutFees,
    totalPages: historicCheckoutFeesTotalPages,
  } = checkoutFeeService.listHistoricCheckoutFees({ storeAliasId, page: historicCheckoutFeePage });

  const mutate = React.useCallback(async () => {
    const promises = [];

    if (defaultCheckoutFeeError) promises.push(mutateDefaultCheckoutFee());

    if (historicCheckoutFeesError) promises.push(mutateHistoricCheckoutFees());

    await Promise.all(promises);
  }, [
    defaultCheckoutFeeError,
    mutateDefaultCheckoutFee,
    historicCheckoutFeesError,
    mutateHistoricCheckoutFees,
  ]);

  const handleEditCheckoutFeeSideModalOpen = React.useCallback(() => {
    setIsEditCheckoutFeeSideModalOpen(!isEditCheckoutFeeSideModalOpen);
  }, [isEditCheckoutFeeSideModalOpen]);

  const handleNewCheckoutFeeSideModalOpen = React.useCallback(() => {
    setIsNewCheckoutFeeSideModalOpen(!isNewCheckoutFeeSideModalOpen);
  }, [isNewCheckoutFeeSideModalOpen]);

  const onHistoricCheckoutFeesPageChange = React.useCallback(
    ({ selected }) => setHistoricCheckoutFeePage(selected),
    [],
  );

  const handleSyncPastCheckoutFeeModalOpen = React.useCallback(
    () => setIsSyncPastCheckoutFeeModalOpen(!isSyncPastCheckoutFeeModalOpen),
    [isSyncPastCheckoutFeeModalOpen],
  );

  const handleSyncPastCheckoutFeeSideModalOpen = React.useCallback(
    () => setIsSyncPastCheckoutFeeSidemodalOpen(!isSyncPastCheckoutFeeSidemodalOpen),
    [isSyncPastCheckoutFeeSidemodalOpen],
  );

  const handleUserPeriodCheckoutFee = React.useCallback(checkoutFee => {
    setUserPeriodCheckoutFee(checkoutFee);
  }, []);

  const handleEditingCheckoutFee = React.useCallback(() => {
    setIsEditingCheckoutFee(!isEditingCheckoutFee);
  }, [isEditingCheckoutFee]);

  const configDefaultCheckoutFee = React.useCallback(
    async data => {
      setIsConfiguringDefaultCheckoutFee(true);

      try {
        if (!defaultCheckoutFee) {
          await checkoutFeeService.createDefaultCheckoutFee({ storeAliasId, data });

          analytics?.track(
            'Checkout Fee Added',
            {
              amount: data.amount,
              percentage_amount: data.percentage_amount,
              email: user?.email,
            },
            { context: { groupId: selectedStore?.alias_id } },
          );

          toast.success('Tarifa padrão criada com sucesso!');

          handleNewCheckoutFeeSideModalOpen();

          mutateDefaultCheckoutFee();
          mutateHistoricCheckoutFees();
        } else {
          await checkoutFeeService.updateDefaultCheckoutFee({
            storeAliasId,
            data,
            checkoutFeeAliasId: defaultCheckoutFee.alias_id,
          });

          toast.success('Tarifa padrão atualizada com sucesso!');

          handleNewCheckoutFeeSideModalOpen();

          mutateDefaultCheckoutFee();
          mutateHistoricCheckoutFees();
        }

        handleSyncPastCheckoutFeeModalOpen();

        setIsConfiguringDefaultCheckoutFee(false);
      } catch (error: any) {
        setIsConfiguringDefaultCheckoutFee(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [
      defaultCheckoutFee,
      mutateDefaultCheckoutFee,
      toast,
      storeAliasId,
      selectedStore,
      analytics,
      handleNewCheckoutFeeSideModalOpen,
      handleSyncPastCheckoutFeeModalOpen,
      mutateHistoricCheckoutFees,
      user,
    ],
  );

  const createCheckoutFeePeriod = React.useCallback(
    async data => {
      setIsCreatingPeriodCheckoutFee(true);

      try {
        const response = await checkoutFeeService.createPeriodCheckoutFee({ storeAliasId, data });

        analytics?.track(
          'Checkout Fee Added',
          { amount: data.amount, percentage_amount: data.percentage_amount, email: user?.email },
          { context: { groupId: selectedStore?.alias_id } },
        );

        toast.success('Tarifa por período criada com sucesso!');

        setIsCreatingPeriodCheckoutFee(false);

        return response;
      } catch (error: any) {
        setIsCreatingPeriodCheckoutFee(false);
        toast.error(error?.response?.data?.message);
      }

      return undefined;
    },
    [toast, storeAliasId, analytics, selectedStore, user],
  );

  const updateCheckoutFeePeriod = React.useCallback(
    async data => {
      setIsUpdatingHistoricCheckoutFee(true);

      try {
        await checkoutFeeService.updatePeriodCheckoutFee({
          storeAliasId,
          data,
          checkoutFeeAliasId: userPeriodCheckoutFee?.alias_id,
        });

        toast.success('Tarifa por período atualizada com sucesso!');

        mutateHistoricCheckoutFees();

        handleEditingCheckoutFee();

        setIsUpdatingHistoricCheckoutFee(false);
      } catch (error: any) {
        setIsUpdatingHistoricCheckoutFee(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [
      mutateHistoricCheckoutFees,
      storeAliasId,
      toast,
      userPeriodCheckoutFee,
      handleEditingCheckoutFee,
    ],
  );

  const updateDefaultCheckoutFee = React.useCallback(
    async data => {
      setIsUpdatingHistoricCheckoutFee(true);

      try {
        await checkoutFeeService.updateDefaultCheckoutFee({
          storeAliasId,
          data,
          checkoutFeeAliasId: userPeriodCheckoutFee?.alias_id,
        });

        toast.success('Tarifa padrão atualizada com sucesso!');

        mutateHistoricCheckoutFees();
        mutateDefaultCheckoutFee();

        handleSyncPastCheckoutFeeModalOpen();

        setIsUpdatingHistoricCheckoutFee(false);
      } catch (error: any) {
        setIsUpdatingHistoricCheckoutFee(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [
      mutateHistoricCheckoutFees,
      storeAliasId,
      toast,
      userPeriodCheckoutFee,
      mutateDefaultCheckoutFee,
      handleSyncPastCheckoutFeeModalOpen,
    ],
  );

  const deleteCheckoutFeePeriod = React.useCallback(
    async data => {
      setIsDeletingHistoricCheckoutFee(true);

      const periodAliasId = data.alias_id;
      const periodStartDate = data.start_date;
      const periodEndDate = data.end_date;

      const periodCheckoutFeeDataToCreate = {
        amount: 0,
        name: 'any_checkout_fee_name',
        percentage_amount: 0,
        start_date: format(utcToZonedTime(periodStartDate), 'yyyy-MM-dd'),
        end_date: format(utcToZonedTime(periodEndDate), 'yyyy-MM-dd'),
      };

      try {
        await checkoutFeeService.deleteCheckoutFee({
          storeAliasId,
          checkoutFeeAliasId: periodAliasId,
        });

        toast.success('Tarifa excluída com sucesso!');

        if (data.is_default) {
          handleSyncPastCheckoutFeeModalOpen();
        } else {
          await checkoutFeeService.createPeriodCheckoutFee({
            storeAliasId,
            data: periodCheckoutFeeDataToCreate,
          });

          const params = `?start_date=${format(
            utcToZonedTime(periodStartDate),
            'yyyy-MM-dd',
          )}&end_date=${format(utcToZonedTime(periodEndDate), 'yyyy-MM-dd')}`;

          addSynchronization({
            name: 'Taxas de Checkout',
            type: ESynchronizationType.STORE_ORDER,
            label: 'Pedidos',
            dependencies: [ESynchronizationType.STORE_PRODUCT],
            storeAliasId,
            config: {
              isOnboardingSynchronization: false,
              showNotification: true,
            },
            request: {
              type: ESynchronizationRequestType.POST,
              domain: LONG_API_DOMAIN,
              api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/orders/checkout-fee/sync`,
              requestParams: params,
            },
          });
        }

        setIsDeletingHistoricCheckoutFee(false);

        mutateHistoricCheckoutFees();
        mutateDefaultCheckoutFee();
      } catch (error: any) {
        setIsDeletingHistoricCheckoutFee(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [
      mutateHistoricCheckoutFees,
      toast,
      storeAliasId,
      addSynchronization,
      format,
      utcToZonedTime,
      handleSyncPastCheckoutFeeModalOpen,
      mutateDefaultCheckoutFee,
    ],
  );

  React.useEffect(() => {
    if (historicCheckoutFeesTotalPages) {
      setHistoricCheckoutFeePageCount(historicCheckoutFeesTotalPages);
    }
  }, [historicCheckoutFeesTotalPages]);

  const isLoadingCheckoutFee = isLoadingDefaultCheckoutFee || isLoadingHistoricCheckoutFees;
  const isValidatingCheckoutFee =
    isValidatingDefaultCheckoutFee || isValidatingHistoricCheckoutFees;
  const isCheckoutFeeError = Boolean(defaultCheckoutFeeError) || Boolean(historicCheckoutFeesError);

  return (
    <CheckoutFeeContext.Provider
      value={{
        configDefaultCheckoutFee,
        createCheckoutFeePeriod,
        defaultCheckoutFee,
        deleteCheckoutFeePeriod,
        handleEditingCheckoutFee,
        handleSyncPastCheckoutFeeModalOpen,
        handleSyncPastCheckoutFeeSideModalOpen,
        handleUserPeriodCheckoutFee,
        historicCheckoutFeePage,
        historicCheckoutFeePageCount,
        historicCheckoutFees,
        isCheckoutFeeError,
        isConfiguringDefaultCheckoutFee,
        isCreatingPeriodCheckoutFee,
        isDeletingHistoricCheckoutFee,
        isEditingCheckoutFee,
        isLoadingCheckoutFee,
        isSyncPastCheckoutFeeSidemodalOpen,
        isSyncPastCheckoutFeeModalOpen,
        isUpdatingHistoricCheckoutFee,
        isValidatingCheckoutFee,
        mutate,
        onHistoricCheckoutFeesPageChange,
        updateCheckoutFeePeriod,
        userPeriodCheckoutFee,
        handleNewCheckoutFeeSideModalOpen,
        isNewCheckoutFeeSideModalOpen,
        mutateHistoricCheckoutFees,
        handleEditCheckoutFeeSideModalOpen,
        isEditCheckoutFeeSideModalOpen,
        updateDefaultCheckoutFee,
      }}
    >
      {children}
    </CheckoutFeeContext.Provider>
  );
};

export const useCheckoutFee = (): ICheckoutFeeProvider => {
  const context = React.useContext(CheckoutFeeContext);

  if (!context) {
    throw new Error('useCheckoutFee must be used within CheckoutFeeProvider');
  }

  return context;
};
