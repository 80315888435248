import React from 'react';

import { EFilterVariant } from '@domain/enums/components/EFilter';
import { IHeaderProps } from '@domain/interfaces/dashboard/FinancialDashboard/IHeader';
import { FINANCIAL_FILTER_CONFIG } from '@helpers/utils/filters/financialFilter';

import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';

import FilterButton from '@components/common/core/Utils/Filter/FilterButton';
import DatePickerRange from '@components/common/core/Utils/DatePickerRange';
import ActionsButton from './ActionsButton';

import * as S from './styles';

const Header: React.FC<IHeaderProps> = ({ filterData, addNewFilterData }) => {
  const { period, setPeriod, maxDateLimiter, minDateLimiter } = useFinancialDashboard();

  const handleNewData = React.useCallback(
    newData => {
      addNewFilterData([newData]);
    },
    [addNewFilterData],
  );

  return (
    <S.HeaderWrapper>
      <S.Title>Financeiro</S.Title>

      <S.Body>
        <FilterButton
          variant={EFilterVariant.MEDIUM}
          data={filterData}
          config={FINANCIAL_FILTER_CONFIG}
          addNewData={handleNewData}
        />

        {/* <S.MultiStoresSelect /> */}

        <S.DatePickerAndActionsButtonWrapper>
          <DatePickerRange
            period={period}
            setPeriod={setPeriod}
            maxDate={maxDateLimiter()}
            minDate={minDateLimiter()}
          />

          <ActionsButton />
        </S.DatePickerAndActionsButtonWrapper>
      </S.Body>
    </S.HeaderWrapper>
  );
};

export default Header;
