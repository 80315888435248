import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresCardOtherRejectedValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresCardOtherRejectedValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresCardOtherRejectedValuesResponse => {
  const currentSelectedStoreCardOtherRejectedAmountValue =
    currentSelectedStoreFinancialDetailsReportData?.card?.other?.rejected.amount;

  const currentSelectedStoreCardOtherRejectedQuantityValue =
    currentSelectedStoreFinancialDetailsReportData?.card?.other?.rejected.quantity;

  const storesCardOtherRejectedAmountValues = selectedStoresData?.map(
    value => value.financial_details_report?.card?.other?.rejected.amount,
  );

  const storesCardOtherRejectedQuantityValues = selectedStoresData?.map(
    value => value.financial_details_report?.card?.other?.rejected.quantity,
  );

  const cardOtherRejectedAmountValues = [
    ...storesCardOtherRejectedAmountValues,
    currentSelectedStoreCardOtherRejectedAmountValue,
  ];

  const cardOtherRejectedQuantityValues = [
    ...storesCardOtherRejectedQuantityValues,
    currentSelectedStoreCardOtherRejectedQuantityValue,
  ];

  const reducedCardOtherRejectedAmountValues = cardOtherRejectedAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedCardOtherRejectedQuantityValues = cardOtherRejectedQuantityValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  return {
    other_rejected_amount: reducedCardOtherRejectedAmountValues,
    other_rejected_quantity: reducedCardOtherRejectedQuantityValues,
  };
};
