import styled, { css } from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 328px;
  height: 80px;
  background-color: ${({ theme }) => theme.colors.background[8]};
  border-radius: 12px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 36px;

  @media only screen and (max-width: 1165px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const IconContainer = styled.div<{ backgroundColor: string }>`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) =>
    css`
      ${backgroundColor}
    `};
  margin-right: 16px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`;

export const Quantity = styled(TextBase)<{ financial: boolean }>`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray[1]};
  line-height: 1;

  ${({ theme, financial }) =>
    financial &&
    css`
      color: ${theme.colors.success.default};
    `}
`;

export const QuantityDescription = styled(TextBase)`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[2]};
  line-height: 1;
`;
