import { EGateway } from '@domain/enums/common/EGateway';
import { EDropshippingProvider } from '@domain/enums/common/integrations/EDropshippingProvider';
import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import {
  IGateway,
  IMarketing,
  IParsedGateway,
  IParsedMarketing,
  IParsedProvider,
  IProvider,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

export const parseProviders = (providers: IProvider): Array<IParsedProvider> => {
  const parsedProviders: Array<IParsedProvider> = [];

  Object.entries(providers).forEach(([key, value]) => {
    const parsedProviderName = key.replace('_amount', '').toUpperCase() as EDropshippingProvider;

    parsedProviders.push({
      provider: parsedProviderName,
      amount: value,
    });
  });

  return parsedProviders;
};

export const parseGateways = (gateways: IGateway): Array<IParsedGateway> => {
  const parsedGateways: Array<IParsedGateway> = [];

  Object.entries(gateways).forEach(([key, value]) => {
    const parsedGatewayName = key.replace('_amount', '').toUpperCase() as EGateway;

    parsedGateways.push({
      provider: parsedGatewayName,
      amount: value,
    });
  });

  return parsedGateways;
};

export const parseMarketings = (marketings: IMarketing): Array<IParsedMarketing> => {
  const parsedMarketings: Array<IParsedMarketing> = [];

  Object.entries(marketings).forEach(([key, value]) => {
    if (key.includes('_amount') && key !== 'total_amount') {
      const parsedMarketingName = key.replace('_amount', '').toUpperCase() as EAdSenseProvider;

      parsedMarketings.push({
        provider: parsedMarketingName,
        amount: Number(value),
      });
    }
  });

  return parsedMarketings;
};
