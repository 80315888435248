import styled from 'styled-components/macro';

import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Select = styled(SelectBase)`
  width: 100%;
  max-width: 227px;
`;

export const Option = styled(OptionBase)``;
