import React from 'react';

import { INewMarketingCostSidemodalProps } from '@domain/interfaces/dashboard/ProductAnalytics/IRankingTable';
import Header from './Header';
import Body from './Body';

import * as S from './styles';

const NewMarketingCostSidemodal: React.FC<INewMarketingCostSidemodalProps> = ({
  isOpen,
  toggle,
  product,
}) => {
  return (
    <S.Sidemodal isOpen={isOpen} toggle={toggle}>
      <Header />

      <Body toggle={toggle} product={product} />
    </S.Sidemodal>
  );
};

export default NewMarketingCostSidemodal;
