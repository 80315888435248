import React from 'react';

import { INTEGRATIONS } from '@constants/pages/dashboard/automations/integrations';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';
import { useAutomationsTemplate } from '@helpers/hooks/pages/dashboard/automations/useAutomationsTemplate';
import { useCanvasMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessageSettings';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { ETriggers } from '@domain/enums/dashboard/automations/ETriggers';

import SuggestIntegrationModal from './SuggestIntegrationModal';

import IntegrationsCard from './IntegrationCard';

import * as S from './styles';

const Integrations: React.FC = () => {
  const { storeIntegrations } = useStoreConfig();
  const { generateInitialNodesAndEdges, templateType, templateQuery } = useAutomationsTemplate();

  const {
    pickIntegration,
    selectedIntegration,
    toggleSuggestionIntegrationModal,
    showSuggestionIntegrationModal,
    handleSelectWorkflowTriggerType,
    setShowSideModal,
    toggleShowRequestIntegrationByTrigger,
    handleSelectTriggerForMissingCredential,
  } = useAutomation();
  const { handleSelectedMessageTrigger } = useCanvasMessageSettings();

  const onIntegrationCardClick = React.useCallback(
    integration => {
      if (templateQuery) {
        pickIntegration(integration);
        handleSelectTriggerForMissingCredential(templateType as ETriggers);

        const foundCredentialProvider = storeIntegrations.workflow.find(
          workflow_integration => workflow_integration.name === integration?.id,
        );

        if (foundCredentialProvider?.name === 'ZOUTI') {
          const hasTypesBesidesStore = foundCredentialProvider.types.some(
            (zoutiTypes: any) => zoutiTypes !== 'GATEWAY',
          );

          if (!hasTypesBesidesStore) return toggleShowRequestIntegrationByTrigger();
        }

        if (foundCredentialProvider?.is_active) {
          generateInitialNodesAndEdges();

          setShowSideModal(false);
          handleSelectWorkflowTriggerType(templateType as ETriggers);
          handleSelectedMessageTrigger(templateType as any);
        } else {
          toggleShowRequestIntegrationByTrigger();
        }
      }

      pickIntegration(integration);

      return undefined;
    },
    [
      pickIntegration,
      handleSelectWorkflowTriggerType,
      setShowSideModal,
      storeIntegrations,
      templateQuery,
      toggleShowRequestIntegrationByTrigger,
      templateType,
      handleSelectedMessageTrigger,
      handleSelectTriggerForMissingCredential,
      generateInitialNodesAndEdges,
    ],
  );

  const isNuvemShopActive = storeIntegrations.workflow.find(
    workflow_integration => workflow_integration.name === 'NUVEM_SHOP',
  )?.is_active;

  const isShopifyActive = storeIntegrations.workflow.find(
    workflow_integration => workflow_integration.name === 'SHOPIFY',
  )?.is_active;

  const isLojaIntegradaActive = storeIntegrations.workflow.find(
    workflow_integration => workflow_integration.name === 'LOJA_INTEGRADA',
  )?.is_active;

  const isKiwifyActive = storeIntegrations.workflow.find(
    workflow_integration => workflow_integration.name === 'KIWIFY',
  )?.is_active;

  const getIntegrations = INTEGRATIONS.filter(integration => {
    if (templateQuery === 'refused-credit-card' && integration.id === 'ZOUTI') {
      return false;
    }

    if (integration.id === 'NUVEM_SHOP' && !isNuvemShopActive) return false;
    if (integration.id === 'SHOPIFY' && !isShopifyActive) return false;
    if (integration.id === 'LOJA_INTEGRADA' && !isLojaIntegradaActive) return false;
    if (integration.id === 'KIWIFY' && !isKiwifyActive) return false;

    return true;
  });

  return (
    <S.Wrapper>
      <S.Title>Selecione a integração</S.Title>
      <S.TextInput type="text" placeholder="Procurar integração" />

      <S.IntegrationsContainer>
        {getIntegrations.map(integration => {
          return (
            <IntegrationsCard
              key={integration.id}
              onClick={() => onIntegrationCardClick(integration)}
              active={integration.id === selectedIntegration?.id}
              name={integration.name}
              icon={integration.icon}
            />
          );
        })}
      </S.IntegrationsContainer>
      <S.SuggestionContainer onClick={toggleSuggestionIntegrationModal}>
        <S.SuggestionText>Sugerir nova integração</S.SuggestionText>
      </S.SuggestionContainer>

      <SuggestIntegrationModal
        isOpen={showSuggestionIntegrationModal}
        toggle={toggleSuggestionIntegrationModal}
      />
    </S.Wrapper>
  );
};

export default Integrations;
