import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  box-sizing: border-box;
  border: 1px dashed ${({ theme }) => theme.colors.gray[4]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
`;

export const Title = styled(TextBase)`
  line-height: 120%;
  margin-top: 12px;
  margin-bottom: 4px;
`;

export const Description = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[3]};
  line-height: 120%;
`;

export const NoSupportedFormatErrorText = styled(TextBase)``;

export const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;
