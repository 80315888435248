import React from 'react';

import { ETriggers } from '@domain/enums/dashboard/automations/ETriggers';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';
import { triggersMapper } from '@helpers/utils/dashboard/automations/triggersMapper';
import { useQuery } from '@helpers/hooks/useQuery';

import TriggerCard from './TriggerCard';

import * as S from './styles';

const Triggers: React.FC = () => {
  const { selectedIntegration } = useAutomation();

  const query = useQuery();

  const templateQuery = query.get('template');

  if (!selectedIntegration || templateQuery) {
    return <EmptyTriggers />;
  }

  const Icon = selectedIntegration.icon;

  const TRIGGERS = selectedIntegration.triggers.map((trigger: ETriggers) =>
    triggersMapper(trigger),
  );

  return (
    <S.Wrapper>
      <S.SelectedIntegration>
        <Icon size={30} />
        <S.SelectedIntegrationName>{selectedIntegration.name}</S.SelectedIntegrationName>
      </S.SelectedIntegration>

      {TRIGGERS.map(({ description, icon, id, title, type }) => {
        return (
          <TriggerCard key={id} title={title} description={description} icon={icon} type={type} />
        );
      })}
    </S.Wrapper>
  );
};

const EmptyTriggers: React.FC = () => {
  return (
    <S.EmptyWrapper>
      <S.EmptyMessage>Selecione uma integração para continuar</S.EmptyMessage>
    </S.EmptyWrapper>
  );
};

export default Triggers;
