import React from 'react';
import { CircleSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components/macro';

import { ECouponStatus } from '@domain/enums/common/coupon/ECouponStatus';
import { EStatus } from '@domain/enums/subscription/ESubscription';

import { useCoupon } from '@helpers/hooks/pages/subscription/useCoupon';
import { useQuery } from '@helpers/hooks/useQuery';
import { usePayment } from '@helpers/hooks/common/store/payment/usePayment';
import { useSubscription } from '@helpers/hooks/common/store/subscription/useSubscription';

import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const CouponInput: React.FC = () => {
  const theme = useTheme();
  const query = useQuery();
  const { subscription } = usePayment();
  const { selectedPlanIdentifier } = useSubscription();
  const {
    isVerifyingCouponIdentifier,
    couponStatus,
    verifyCouponIdentifier,
    resetStatus,
    selectedCoupon,
  } = useCoupon();

  const isCurrentSubscriptionPaid =
    subscription?.status === EStatus.PAID && !subscription?.plan?.identifier.includes('free');
  const [hasSelectedToAddCoupon, setHasSelectedToAddCoupon] = React.useState<boolean>(false);
  const [couponIdentifier, setCouponIdentifier] = React.useState<string>('');
  const [isInputFocused, setIsInputFocused] = React.useState<boolean>(false);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const couponIdQuery = query.get('coupon_id');

  const onFocus = React.useCallback(() => {
    setIsInputFocused(true);
  }, []);

  const onBlur = React.useCallback(() => {
    setIsInputFocused(false);
  }, []);

  const onChange = React.useCallback(event => {
    setCouponIdentifier(event.target.value);

    event.target.value = event.target.value.toUpperCase();
  }, []);

  const onApply = React.useCallback(() => {
    if (!selectedPlanIdentifier || !subscription) return;

    verifyCouponIdentifier(selectedPlanIdentifier, subscription.alias_id, couponIdentifier);
  }, [verifyCouponIdentifier, couponIdentifier, selectedPlanIdentifier, subscription]);

  const onAddCoupon = React.useCallback(() => {
    setHasSelectedToAddCoupon(true);
    setIsInputFocused(true);
  }, []);

  const onInputWrapperBlur = React.useCallback(
    event => {
      if (event.currentTarget.contains(event.relatedTarget)) return;

      setHasSelectedToAddCoupon(false);
      resetStatus();
    },
    [resetStatus],
  );

  const isCouponValid =
    couponStatus !== ECouponStatus.EXPIRED && couponStatus !== ECouponStatus.NONEXISTENT;

  const hasCouponIdQuery = couponIdQuery && couponIdQuery !== 'undefined';

  React.useEffect(() => {
    if (!isCouponValid && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isCouponValid]);

  React.useEffect(() => {
    if (!selectedCoupon) {
      setHasSelectedToAddCoupon(false);
    }
  }, [selectedCoupon]);

  if (isCurrentSubscriptionPaid) {
    return <></>;
  }

  console.log({ selectedCoupon, hasSelectedToAddCoupon, hasCouponIdQuery });

  return (
    <S.Wrapper>
      {!selectedCoupon && !hasSelectedToAddCoupon && !hasCouponIdQuery && (
        <S.AddCouponButton onClick={onAddCoupon}>Adicionar cupom</S.AddCouponButton>
      )}

      {!selectedCoupon && hasSelectedToAddCoupon && (
        <>
          <S.InputWrapper isValid={isCouponValid} onBlur={onInputWrapperBlur}>
            <S.Input
              type="text"
              autoFocus
              onChange={onChange}
              ref={inputRef}
              onFocus={onFocus}
              onBlur={onBlur}
            />

            {Boolean(couponIdentifier.length) && !isVerifyingCouponIdentifier && (
              <S.ApplyButton onClick={onApply}>Aplicar</S.ApplyButton>
            )}

            {isVerifyingCouponIdentifier && (
              <CircleSpinner size={12} color={theme.colors.gray[1]} />
            )}
          </S.InputWrapper>

          {couponStatus === ECouponStatus.EXPIRED && isInputFocused && (
            <Text isErrorFeedback>Cupom inválido.</Text>
          )}

          {couponStatus === ECouponStatus.NONEXISTENT && isInputFocused && (
            <Text isErrorFeedback>Cupom inválido.</Text>
          )}
        </>
      )}
    </S.Wrapper>
  );
};

export default CouponInput;
