import React from 'react';
import { Search } from '@profitfy/pakkun-icons';

import { EPreDefinedDates } from '@domain/enums/components/EDatePicker';
import { EStatus } from '@domain/enums/subscription/ESubscription';

import { useRankingTable } from '@helpers/hooks/pages/dashboard/productAnalytics/useRankingTable';
import { useDebounce } from '@helpers/hooks/useDebounce';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { useDate } from '@helpers/hooks/useDate';

import { RANKING_PRODUCTS_SORTER } from '@helpers/utils/sorters/rankingProductsSorter';

import * as S from './styles';

const Header: React.FC = () => {
  const { period, setPeriod, handleProductSearch, handleSortData } = useRankingTable();
  const { subscription } = useStoreSubscription();
  const { utcToZonedTime } = useDate();

  const isSubscriptionPeriodEndedAtAfterNow = subscription?.period_ended_at
    ? new Date(subscription.period_ended_at) >= utcToZonedTime(new Date())
    : true;

  const defaultPreDefinedDate =
    subscription?.status === EStatus.CANCELED && !isSubscriptionPeriodEndedAtAfterNow
      ? EPreDefinedDates.CUSTOM
      : EPreDefinedDates.TODAY;

  const [productName, setProductName] = React.useState<string>('');

  const debouncedProductName = useDebounce(productName, 700);

  const handleOnChange = React.useCallback(event => setProductName(event.target.value), []);

  React.useEffect(() => {
    handleProductSearch(debouncedProductName);
  }, [debouncedProductName, handleProductSearch]);

  return (
    <S.Wrapper>
      <S.DatePickerWrapper>
        <S.DatePicker
          period={period}
          setPeriod={setPeriod}
          defaultPreDefinedDate={defaultPreDefinedDate}
        />
      </S.DatePickerWrapper>

      <S.TitleAndFilterWrapper>
        <S.TitleAndSortButtonWrapper>
          <S.Title>Ranking de mais vendidos</S.Title>
          <S.Sort handleSortData={handleSortData} config={RANKING_PRODUCTS_SORTER} />
        </S.TitleAndSortButtonWrapper>

        <S.Input
          type="text"
          prepend={Search}
          placeholder="Pesquisar..."
          onChange={handleOnChange}
        />
      </S.TitleAndFilterWrapper>
    </S.Wrapper>
  );
};

export default Header;
