export enum ECartPandaGateways {
  APPMAX = 'appmax',
  MERCADO_PAGO = 'mercado-pago',
  CLOUD_FOX = 'cloud-fox',
  UPNID = 'upnid',
  PAG_SEGURO = 'pag-seguro',
  CIELO = 'cielo',
  PAGARME = 'pagarme',
  HUB_SALE = 'hub-sale',
  CART_PANDA_PAY = 'cart-panda-pay',
}

export enum ECartPandaType {
  STORE = 'STORE',
  ABANDONED_CART = 'ABANDONED_CART',
}
