import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 24px;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const Heading = styled(TextBase)`
  color: #fff;
`;

export const Description = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[3]};
`;

export const TemplatesWrapper = styled.div`
  width: 100%;
  max-width: 954px;
  overflow: scroll hidden;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  border-radius: 12px;
  padding: 24px 24px 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;

  &::-webkit-scrollbar {
    height: 8px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[3]};
    border-radius: 25px;
    margin-bottom: 4px;
  }
`;
