import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import LabelBase from '@components/common/core/DataDisplay/Label';
import ButtonBase from '@components/common/core/Inputs/Button';

export const SubmitButton = styled(ButtonBase)`
  margin-left: auto;
`;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Text = styled(TextBase)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[2]};
  line-height: 150%;
  display: inline-block;
  margin-bottom: 16px;

  & > strong {
    color: ${({ theme }) => theme.colors.gray[1]};
  }
`;

export const Modal = styled(ModalBase)`
  max-width: 500px;
  width: 100%;
  box-sizing: border-box;

  & > div {
    max-width: unset;
  }

  & > div:first-child {
    & > span {
      display: flex;
      align-items: center;
      gap: 16px;
      font-size: 1.125rem;
      font-weight: 500;
    }
  }
`;
