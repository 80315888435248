import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { USER_API_DOMAIN } from '@constants/api';
import {
  ICreateNaturalPersonProps,
  IDeleteNaturalPersonProps,
  IGetNaturalPersonProps,
  IGetNaturalPersonResponse,
  IUpdateNaturalPersonProps,
} from '@domain/interfaces/subscription/INaturalPerson';

export class NaturalPersonService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getNaturalPerson({ storeAliasId }: IGetNaturalPersonProps): IGetNaturalPersonResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/natural-people`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      naturalPerson: data?.natural_person,
      mutate,
      error,
      isValidating,
      isLoading,
    };
  }

  public createNaturalPerson({ data, storeAliasId }: ICreateNaturalPersonProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/natural-people`, {
      ...data,
    });
  }

  public updateNaturalPerson({ data, storeAliasId }: IUpdateNaturalPersonProps): Promise<any> {
    return this.apiService.put(`/api/v1/users/stores/${storeAliasId}/natural-people`, { ...data });
  }

  public deleteNaturalPerson({ storeAliasId }: IDeleteNaturalPersonProps): Promise<any> {
    return this.apiService.delete(`/api/v1/users/stores/${storeAliasId}/natural-people`);
  }
}

const naturalPersonService = new NaturalPersonService(USER_API_DOMAIN);

export default naturalPersonService;
