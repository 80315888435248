import React from 'react';

import { useFinancialData } from '@helpers/hooks/pages/dashboard/financialData/useFinancialData';
import { cnpjFormatter, phone } from '@helpers/masks';

import * as S from './styles';

const NaturalPerson: React.FC = () => {
  const { register, legalPerson } = useFinancialData();

  const cnpjInput = register('cnpj');
  const phoneInput = register('phone');

  return (
    <>
      <S.CnpjAndCompanyNameWrapper>
        <S.InputGroup>
          <S.Label>CNPJ</S.Label>
          <S.TextField
            {...cnpjInput}
            type="text"
            defaultValue={cnpjFormatter(legalPerson?.cnpj)}
            onChange={event => {
              cnpjInput.onChange(event);

              const { value } = event.target;

              event.target.value = cnpjFormatter(value);
            }}
          />
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Razão Social</S.Label>
          <S.TextField
            {...register('company_name')}
            type="text"
            defaultValue={legalPerson?.company_name}
          />
        </S.InputGroup>
      </S.CnpjAndCompanyNameWrapper>

      <S.PhoneAndEmailWrapper>
        <S.InputGroup>
          <S.Label>Telefone de contato</S.Label>
          <S.TextField
            {...phoneInput}
            type="text"
            onChange={event => {
              phoneInput.onChange(event);

              const { value } = event.target;

              event.target.value = phone(value);
            }}
          />
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>E-mail</S.Label>
          <S.TextField {...register('email')} type="text" />
        </S.InputGroup>
      </S.PhoneAndEmailWrapper>
    </>
  );
};

export default NaturalPerson;
