import React from 'react';

import { numberFormatter } from '@helpers/masks';
import { sumFeeOrderAmount } from '@helpers/dashboard/orders/ordersUtils';

import { IFeeContentProps } from '@domain/interfaces/dashboard/Orders/IFeeContent';
import { ETextWeight } from '@domain/enums/components/EText';

import * as S from './styles';

const FeeContent: React.FC<IFeeContentProps> = ({ order }) => {
  const hasFeeAmount = sumFeeOrderAmount(order) > 0;

  const formattedSumFeesAmount = hasFeeAmount ? numberFormatter(sumFeeOrderAmount(order), 2) : 0;

  const formattedGatewayFeeAmount = hasFeeAmount ? numberFormatter(order.gateway_fee_amount, 2) : 0;

  const formattedMarketplaceFeeAmount = hasFeeAmount
    ? numberFormatter(order.marketplace_fee_amount, 2)
    : 0;

  const hasMarketplaceFee = order.marketplace_fee_amount > 0;

  const formattedInfoProductFeeAmount = hasFeeAmount
    ? numberFormatter(order.info_product_fee_amount, 2)
    : 0;

  const hasInfoProductFee = order.info_product_fee_amount > 0;

  const formattedCheckoutFeeAmount = hasFeeAmount
    ? numberFormatter(order.checkout_fee_amount, 2)
    : 0;

  return (
    <S.Tooltip>
      <S.Tooltip.Trigger>
        <S.FeeAmount weight={ETextWeight.MEDIUM}>
          {hasFeeAmount ? `R$ ${formattedSumFeesAmount}` : '-'}
        </S.FeeAmount>
      </S.Tooltip.Trigger>

      <S.Tooltip.Content side="top" sideOffset={5}>
        <S.Wrapper>
          <S.TextWrapper>
            <S.DescriptionText>Taxas do Gateway: </S.DescriptionText>
            <S.ValueText>{`R$${formattedGatewayFeeAmount}`}</S.ValueText>
          </S.TextWrapper>

          <S.TextWrapper>
            <S.DescriptionText>Taxas do checkout / tarifas: </S.DescriptionText>
            <S.ValueText>{`R$${formattedCheckoutFeeAmount}`}</S.ValueText>
          </S.TextWrapper>

          {hasMarketplaceFee && (
            <S.TextWrapper>
              <S.DescriptionText>Taxas Marketplace: </S.DescriptionText>
              <S.ValueText>{`R$${formattedMarketplaceFeeAmount}`}</S.ValueText>
            </S.TextWrapper>
          )}

          {hasInfoProductFee && (
            <S.TextWrapper>
              <S.DescriptionText>Taxas Infoproduto: </S.DescriptionText>
              <S.ValueText>{`R$${formattedInfoProductFeeAmount}`}</S.ValueText>
            </S.TextWrapper>
          )}
        </S.Wrapper>

        <S.Tooltip.Arrow />
      </S.Tooltip.Content>
    </S.Tooltip>
  );
};

export default FeeContent;
