import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { EUserStoreRole } from '@domain/enums/common/userStore/EUserStoreRole';
import { EStatus } from '@domain/enums/subscription/ESubscription';
import { IParams } from '@domain/interfaces/IParams';

import { WhatsappInstanceProvider } from '@helpers/hooks/pages/dashboard/whatsappInstance/useWhatsappInstance';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';

import Content from '@components/Dashboard/WhatsappInstance/NewInstance';

const NewInstance: React.FC = () => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { accessManagementData } = useStoreConfig();
  const { hasAllPermissions, subscription } = useStoreSubscription();

  const isStoreOwner = accessManagementData?.role === EUserStoreRole.OWNER;
  const hasAccessToPage = accessManagementData?.group?.has_workflow_full_write_access;

  const hasPermission =
    hasAllPermissions || subscription?.status === EStatus.TRIAL_STARTED || !subscription;

  if (!isStoreOwner && !hasAccessToPage && !hasPermission) {
    history.push(`/${storeAliasId}/dashboard/dashboard/marketing/messages-instances`);

    return <></>;
  }

  return (
    <WhatsappInstanceProvider>
      <Content />
    </WhatsappInstanceProvider>
  );
};

export default NewInstance;
