import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ImageBase from '@components/common/core/DataDisplay/Image';

export const Text = styled(TextBase)``;

export const Body = styled.div``;

export const IntegrationTitleText = styled(HeadingBase)``;

export const IntegrationTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  gap: 16px;
`;

export const Title = styled(HeadingBase)`
  font-size: 1.75rem;
  line-height: 120%;
  margin-bottom: 36px;
`;

export const Header = styled.div``;

export const TutorialContent = styled(TextBase)`
  display: inline-block;
  font-size: 0.875rem;
  line-height: 150%;
`;

export const TutorialTitle = styled(HeadingBase)`
  font-size: 1rem;
  line-height: 120%;
  display: inline-block;
  margin-bottom: 8px;
`;

export const TutorialImage = styled(ImageBase)`
  max-width: 140px;
  margin-bottom: 24px;
  display: block;
`;

export const TutorialWrapper = styled.div`
  max-width: 416px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray[4]};
  border-radius: 7px;
  padding: 28px 32px;
  box-sizing: border-box;
  height: fit-content;
`;

export const IntegrationContent = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  padding: 142px 32px;
  max-width: calc(1008px + 64px);
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  gap: 28px;

  @media only screen and (max-width: 1292px) {
    flex-direction: column;
    max-width: calc(620px + 64px);
  }
`;
