import React from 'react';

import { EAvatarSize } from '@domain/enums/components/EAvatar';
import { useParams, useHistory } from 'react-router-dom';
import {
  IParams,
  ISuggestion,
  ISuggestionDetail,
  IUpVoter,
} from '@domain/interfaces/dashboard/IdeasChannel/suggestions';
import { friendlyNumberFormatter } from '@helpers/masks';
import { useToast } from '@helpers/hooks/useToast';
import { MONTHS } from '@constants/months';
import { useSuggestions } from '@helpers/hooks/pages/dashboard/ideasChannel/useSuggestions';
import { EButtonVariant } from '@domain/enums/components/EButton';

import suggestionsService from '@services/pages/dashboard/ideasChannel/suggestions';

import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Idea: React.FC = () => {
  const { goBack } = useHistory();
  const { ideaAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { voteUp, voteDown } = useSuggestions();

  const [suggestion, setSuggestion] = React.useState<ISuggestion>({} as ISuggestion);
  const [suggestionDetail, setSuggestionDetail] = React.useState<ISuggestionDetail>(
    {} as ISuggestionDetail,
  );
  const [upVoters, setUpVoters] = React.useState<IUpVoter[]>([]);
  const [createdAtDate, setIsCreatedAtDate] = React.useState<string>('');
  const [isGettingSuggestion, setIsGettingSuggestion] = React.useState<boolean>(false);
  const [isDeletingSuggestion, setIsDeletingSuggestion] = React.useState<boolean>(false);
  const [isGettingSuggestionDetails, setIsGettingSuggestionDetails] = React.useState<boolean>(
    false,
  );
  const [isUpvoted, setIsUpvoted] = React.useState<boolean>(false);
  const [votes, setVotes] = React.useState<number>(0);

  const getSuggestion = React.useCallback(async () => {
    setIsGettingSuggestion(true);

    try {
      const { data } = await suggestionsService.getSuggestion({ ideaAliasId });

      setSuggestion(data?.suggestion);
      setVotes(data?.suggestion.votes_up);
      setIsUpvoted(data?.suggestion.is_voted_up_by_user);

      setIsGettingSuggestion(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsGettingSuggestion(false);
    }
  }, [ideaAliasId, toast]);

  const getSuggestionDetails = React.useCallback(async () => {
    setIsGettingSuggestionDetails(true);

    try {
      const { data } = await suggestionsService.getSuggestionDetail({ ideaAliasId });

      setSuggestionDetail(data?.created_by);
      setUpVoters(data?.up_voters);

      setIsGettingSuggestionDetails(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsGettingSuggestionDetails(false);
    }
  }, [ideaAliasId, toast]);

  const handleUpvote = React.useCallback(() => {
    if (isUpvoted) {
      voteDown({ ideaAliasId });
      setVotes(previousState => previousState - 1);
    } else {
      voteUp({ ideaAliasId });
      setVotes(previousState => previousState + 1);
    }

    setIsUpvoted(!isUpvoted);
  }, [isUpvoted, voteDown, voteUp, ideaAliasId]);

  const handleDeleteIdea = React.useCallback(async () => {
    setIsDeletingSuggestion(true);

    try {
      await suggestionsService.deleteSuggestion({ ideaAliasId });

      toast.success('Sugestão deletada com sucesso!');

      setIsDeletingSuggestion(false);
    } catch (error: any) {
      setIsDeletingSuggestion(false);

      toast.error(error?.response?.data?.message);
    }

    goBack();
  }, [ideaAliasId, goBack, toast]);

  React.useEffect(() => {
    const createdSuggestionDate = suggestion.created_at;

    if (!createdSuggestionDate) return;

    let date: string;

    if (typeof createdSuggestionDate === 'number') {
      date = new Date(createdSuggestionDate * 1000).toISOString();
    } else {
      date = createdSuggestionDate;
    }

    const createdAtYear = date.substring(0, 4);
    const createdAtMonth = date.substring(5, 7);
    const createdAtDay = date.substring(8, 10);

    const foundMonth = MONTHS.find(month => month.id === createdAtMonth);

    const formattedDate = `${createdAtDay} de ${foundMonth?.name} de ${createdAtYear}.`;

    if (foundMonth) setIsCreatedAtDate(formattedDate);
  }, [suggestion.created_at]);

  React.useEffect(() => {
    getSuggestion();
  }, [getSuggestion]);

  React.useEffect(() => {
    getSuggestionDetails();
  }, [getSuggestionDetails]);

  if (isGettingSuggestion || isGettingSuggestionDetails) {
    return <SkeletonLoading />;
  }

  const { phone_prefix: userPhonePrefix, phone_number: userPhoneNumber, user } = suggestionDetail;

  const formattedUserName = `${user?.first_name} ${user?.last_name}`;
  const formattedPhoneNumber = `${userPhonePrefix} ${userPhoneNumber}`;

  return (
    <S.IdeaWrapper>
      <S.Header>
        <S.CustomHoverCard>
          <S.CustomHoverCardTrigger>
            <S.VoteWrapper isVoted={isUpvoted} onClick={handleUpvote}>
              <S.ArrowUp />
              <S.VotesCount>{friendlyNumberFormatter(votes)}</S.VotesCount>
            </S.VoteWrapper>
          </S.CustomHoverCardTrigger>

          <S.CustomHoverCardContent side="bottom" sideOffset={10}>
            <S.UserInfoTitle>Votos de Usuários</S.UserInfoTitle>

            <S.UpVotersWrapper>
              {upVoters.map(vote => {
                const { phone_prefix, phone_number, user: UpVoterUser } = vote;

                const phoneNumber = `${phone_prefix} ${phone_number}`;
                const userName = `${UpVoterUser?.first_name} ${UpVoterUser?.last_name}`;

                return (
                  <S.UpVoterInfoWrapper>
                    <S.UserInfo>{userName}</S.UserInfo>
                    <S.UserInfo>{UpVoterUser?.email}</S.UserInfo>
                    <S.UserInfo>{phoneNumber}</S.UserInfo>
                  </S.UpVoterInfoWrapper>
                );
              })}
            </S.UpVotersWrapper>

            <S.CustomHoverCardArrow />
          </S.CustomHoverCardContent>
        </S.CustomHoverCard>

        <S.Title>{suggestion.title}</S.Title>
      </S.Header>

      <S.Body>
        <S.CustomHoverCard>
          <S.CustomHoverCardTrigger>
            <S.Avatar word={user?.first_name?.charAt(0)} size={EAvatarSize.SMALL} />
          </S.CustomHoverCardTrigger>

          <S.CustomHoverCardContent side="bottom" sideOffset={10}>
            <S.UserInfoTitle>Informações do Usúario</S.UserInfoTitle>

            <S.UserInfosWrapper>
              <S.UserInfo>{formattedUserName}</S.UserInfo>
              <S.UserInfo>{user?.email}</S.UserInfo>
              <S.UserInfo>{formattedPhoneNumber}</S.UserInfo>
            </S.UserInfosWrapper>

            <S.CustomHoverCardArrow />
          </S.CustomHoverCardContent>
        </S.CustomHoverCard>

        <S.DescriptionWrapper>
          <S.UserName>{formattedUserName}</S.UserName>
          <S.DescriptionText>{suggestion.description}</S.DescriptionText>
          <S.DateText>{createdAtDate}</S.DateText>
        </S.DescriptionWrapper>
      </S.Body>

      <S.Button
        variant={EButtonVariant.DANGER}
        onClick={handleDeleteIdea}
        isLoading={isDeletingSuggestion}
        disabled={isDeletingSuggestion}
      >
        Deletar idea
      </S.Button>
    </S.IdeaWrapper>
  );
};

export default Idea;
