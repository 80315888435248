export enum ESelectedChart {
  REVENUE = 'revenue',
  COGS = 'cogs',
  MARKETING = 'marketing',
  TAXES = 'taxes',
  PROFIT = 'profit',
  AVERAGE_TICKET = 'average_ticket',
  ROI = 'roi',
  PENDING_ORDERS = 'pending_orders',
  COMPENSATED_BOLETOS = 'compensated_boletos',
  PROFIT_MARGIN = 'profit_margin',
  P_PENDING = 'p_pending',
  BOLETOS = 'boletos',
  CREDIT_CARDS = 'credit_cards',
  PIX = 'pix',
  OVERVIEW_FINANCE = 'overview_finance',
  ORDERS_QUANTITY = 'orders_quantity',
}
