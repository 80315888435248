import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';

import * as S from './styles';

const Content: React.FC<Accordion.AccordionContentProps & React.RefAttributes<HTMLDivElement>> = ({
  children,
  ...rest
}) => {
  return <S.Wrapper {...rest}>{children}</S.Wrapper>;
};

export default Content;
