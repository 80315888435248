import React from 'react';
import { WarningCircle } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';

import { useActivateSubscription } from '@helpers/hooks/pages/dashboard/useActivateSubscription';

import { getFailedTransactionDescription } from '@helpers/utils/dashboard/transactions/transactionsUtils';

import * as S from './styles';

const TransactionError: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const {
    transactionError,
    showTransactionErrorModal,
    handleShowTransactionErrorModal,
  } = useActivateSubscription();

  const onReactivateSubscriptionClick = React.useCallback(() => {
    handleShowTransactionErrorModal();

    history.push(
      `/${storeAliasId}/dashboard/subscription?defaultTab=general&reactivateSubscription=true`,
    );
  }, [history, storeAliasId, handleShowTransactionErrorModal]);

  return (
    <S.Modal isOpen={showTransactionErrorModal} toggle={handleShowTransactionErrorModal}>
      <S.Wrapper>
        <S.Header>
          <WarningCircle size={24} color={theme.colors.danger.default} />
          <S.Title type={EHeadingSize.H5}>A transação falhou!</S.Title>
        </S.Header>
        <S.Body>
          <S.Description>
            A tentativa de reativação da assinatura falhou! Atualize o seu cartão e tente novamente.
          </S.Description>

          <S.ReasonWrapper>
            <S.ReasonLabel>Motivo da falha:</S.ReasonLabel>
            <S.ReasonDescription>
              <S.ReasonDescriptionText>
                {getFailedTransactionDescription(transactionError)}
              </S.ReasonDescriptionText>
            </S.ReasonDescription>
          </S.ReasonWrapper>

          <S.ReactiveSubscriptionButton onClick={onReactivateSubscriptionClick}>
            Atualizar cartão
          </S.ReactiveSubscriptionButton>
        </S.Body>
      </S.Wrapper>
    </S.Modal>
  );
};

export default TransactionError;
