export enum EUserRole {
  AGENCIA_DE_MARKETING = 'AGENCIA_DE_MARKETING',
  GERENTE_DE_ECOMMERCE = 'GERENTE_DE_ECOMMERCE',
  ANALISTA_DE_ECOMMERCE = 'ANALISTA_DE_ECOMMERCE',
  SOCIAL_MIDIA = 'SOCIAL_MIDIA',
  GESTOR_DE_TRAFEGO = 'GESTOR_DE_TRAFEGO',
  WEB_DESIGNER = 'WEB_DESIGNER',
  DESENVOLVEDOR = 'DESENVOLVEDOR',
  SUPORTE = 'SUPORTE',
  DIRETOR_EXECUTIVO = 'DIRETOR_EXECUTIVO',
  DIRETOR_DE_MARKETING = 'DIRETOR_DE_MARKETING',
  DIRETOR_DE_OPERACOES = 'DIRETOR_DE_OPERACOES',
  DIRETOR_DE_TECNOLOGIA = 'DIRETOR_DE_TECNOLOGIA',
  PROPRIETARIO_DO_ECOMMERCE = 'PROPRIETARIO_DO_ECOMMERCE',
  OUTRO = 'OUTRO',
}
