import React from 'react';
import { Plus, Check } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { useEcommerce } from '@helpers/hooks/pages/onboarding/useEcommerce';

import { ECOMMERCE_GATEWAYS } from '@constants/common/integrations';

import Sidemodal from './Sidemodal';

import * as S from './styles';

const Gateways: React.FC = () => {
  const theme: any = useTheme();
  const { selectedGateways } = useEcommerce();

  const [isSideModalOpen, setIsSideModalOpen] = React.useState<boolean>(false);

  const handleSideModalOpen = React.useCallback(() => setIsSideModalOpen(!isSideModalOpen), [
    isSideModalOpen,
  ]);

  const getGatewayIcon = React.useCallback(
    (integration: typeof ECOMMERCE_GATEWAYS[number] | undefined) => {
      if (!integration) return <></>;

      const Icon = integration.icon;

      return <Icon size={20} />;
    },
    [],
  );

  return (
    <>
      <S.Wrapper>
        <S.CardWrapper onClick={handleSideModalOpen}>
          <S.Header>
            <S.IconWrapper>
              <Plus color={theme.colors.gray[1]} size={9} />
            </S.IconWrapper>

            <S.DescriptionText>
              Selecionar <strong>Gateway de pagamento</strong>
            </S.DescriptionText>
          </S.Header>

          {selectedGateways.map(integration => {
            const foundGateway = ECOMMERCE_GATEWAYS.find(
              gatewayIntegration => gatewayIntegration.identifier === integration,
            );

            return (
              <React.Fragment key={foundGateway?.identifier}>
                <S.HorizontalRule />

                <S.IntegrationWrapper>
                  <S.IntegrationName>
                    {getGatewayIcon(foundGateway)}
                    <S.Text>{foundGateway?.name}</S.Text>
                  </S.IntegrationName>

                  <Check size={20} outline />
                </S.IntegrationWrapper>
              </React.Fragment>
            );
          })}
        </S.CardWrapper>

        <S.ExampleText>Ex: Mercado Pago, AppMax, etc.</S.ExampleText>
      </S.Wrapper>

      {isSideModalOpen ? (
        <Sidemodal isOpen={isSideModalOpen} toggle={handleSideModalOpen} />
      ) : (
        <></>
      )}
    </>
  );
};

export default Gateways;
