import React from 'react';
import { Check, Warning } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { IOverviewContentProps } from '@domain/interfaces/dashboard/AdsManager/UtmSidemodal/IOverviewContent';

import * as S from './styles';

const OverviewContent: React.FC<IOverviewContentProps> = ({ handleStep, selectedAdSenseAds }) => {
  const theme = useTheme();

  return (
    <S.Wrapper>
      <S.Title>Adicionar Profitfy UTM Tags</S.Title>

      <S.Description>
        Isso adicionará UTM tags para suas campanhas existentes, te dando mais assertividade nas
        conversões e no tracking do lucro.
      </S.Description>

      <S.InsightsWrapper>
        <S.Insight>
          <S.InsightIconWrapper style={{ backgroundColor: '#223C30' }}>
            <Check size={14} color={theme.colors.success.light} />
          </S.InsightIconWrapper>

          <S.InsightText>
            Foram{' '}
            <S.DetectedCampaigns onClick={() => handleStep('SECOND')}>
              {`selecionados (${selectedAdSenseAds?.length}) criativos`}
            </S.DetectedCampaigns>{' '}
            para serem adicionadas <strong>UTMs</strong>
          </S.InsightText>
        </S.Insight>

        <S.Insight>
          <S.InsightIconWrapper style={{ backgroundColor: '#223C30' }}>
            <Check size={14} color={theme.colors.success.light} />
          </S.InsightIconWrapper>

          <S.InsightText>
            Os trackings de lucro só irão funcionar a partir da adição das UTM’s
          </S.InsightText>
        </S.Insight>

        <S.Insight>
          <S.InsightIconWrapper style={{ backgroundColor: '#4D3F2C' }}>
            <Warning size={14} color={theme.colors.warning.light} />
          </S.InsightIconWrapper>

          <S.InsightText>
            As <strong>performances</strong> de suas campanhas podem ser afetadas por essa
            atualização.
          </S.InsightText>
        </S.Insight>

        <S.Insight>
          <S.InsightIconWrapper style={{ backgroundColor: '#4D3F2C' }}>
            <Warning size={14} color={theme.colors.warning.light} />
          </S.InsightIconWrapper>

          <S.InsightText>
            Isso adicionará tags em seus criativos então <strong>poderá</strong> entrar em revisão.
          </S.InsightText>
        </S.Insight>
      </S.InsightsWrapper>
    </S.Wrapper>
  );
};

export default OverviewContent;
