import { IGroup } from '@domain/interfaces/common/group/IGroup';

export const getSelectedGroupPermissions = (group: IGroup): Array<string> => {
  const permissions: Array<string> = [];

  Object.entries(group).forEach(([key, value]) => {
    if (key.includes('has_') && value) {
      permissions.push(key);
    }
  });

  return permissions;
};
