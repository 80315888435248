import React from 'react';

import { TikTokCredentialsProvider } from '@helpers/hooks/common/integrations/marketing/tiktok/useTikTokCrendentials';

import Wrapper from './Wrapper';

const TikTok: React.FC = () => {
  return (
    <TikTokCredentialsProvider>
      <Wrapper />
    </TikTokCredentialsProvider>
  );
};

export default TikTok;
