import styled from 'styled-components/macro';

import BadgeBase from '@components/common/core/DataDisplay/Badge';
import ButtonBase from '@components/common/core/Inputs/Button';
import TextBase from '@components/common/core/DataDisplay/Text';
import NoImageBase from '@components/common/core/DataDisplay/NoImage';

export const ActionButton = styled(ButtonBase)`
  font-weight: 500;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ProductStatus = styled(BadgeBase)``;

export const ProductName = styled(TextBase)`
  width: 100%;
  max-width: 324px;
  min-width: 324px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ProviderIconWrapper = styled.div`
  position: absolute;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  display: flex;
  align-items: center;
  justify-content: center;
  top: -4px;
  right: -8px;
`;

export const NoImage = styled(NoImageBase)`
  background-color: ${({ theme }) => theme.colors.background[3]};
`;

export const ProductImage = styled.img`
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  border-radius: 50%;
`;

export const ProductImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  position: relative;
`;

export const ProductDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Wrapper = styled.div`
  min-width: 768px;
  padding: 12px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 48px;

  & > button:nth-child(2) {
    width: 100%;
  }
`;
