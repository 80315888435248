import axios, { AxiosInstance } from 'axios';

import { IGetAddressProps } from '@domain/interfaces/subscription/IAddressFormCard';

export class ZipCodeService {
  private readonly api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = axios.create({ baseURL });
  }

  public getAddressByZipCode({ zipCode }: IGetAddressProps): Promise<any> {
    return this.api.get(`/${zipCode}/json/`);
  }
}

export const zipCodeService = new ZipCodeService('https://viacep.com.br/ws');

export default zipCodeService;
