import styled from 'styled-components/macro';
import CustomAccordionBase from '@components/common/core/Utils/CustomAccordion';

import TextBase from '@components/common/core/DataDisplay/Text';

export const ContentText = styled(TextBase)`
  padding: 0px 32px 16px 32px;
  display: inline-block;
  white-space: pre-line;
  font-family: 'Poppins', 'Open Sans';
  font-size: 0.875rem;
  font-weight: 400;
  color: #fff;
  line-height: 200%;
`;

export const AccordionContent = styled(CustomAccordionBase.Content)`
  span {
    @media only screen and (max-width: 678px) {
      padding: 0 16px 16px 16px;
    }
  }
`;

export const AccordionTrigger = styled(CustomAccordionBase.Trigger)`
  height: unset;
  padding: 12px 24px;
  font-family: 'Poppins', 'Open Sans';
  font-size: 0.875rem;
  font-weight: 400;
  color: #fff;
`;

export const AccordionItem = styled(CustomAccordionBase.Item)`
  border-radius: 7px;
  background-color: #24272e;
`;

export const Accordion = styled(CustomAccordionBase)`
  max-width: 1248px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AccordionsWrapper = styled.div`
  display: flex;
  gap: 32px;
  align-items: flex-start;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const Title = styled.span`
  font-family: 'Poppins', 'Open Sans';
  font-size: 4rem;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 56px;
  line-height: 80px;
  text-align: center;

  @media only screen and (max-width: 948px) {
    font-size: 3rem;
    line-height: 40px;
    text-align: center;
    margin-bottom: 40px;
  }
`;

export const Content = styled.div`
  max-width: 1384px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Wrapper = styled.section`
  padding: 96px 28px 128px 28px;
  box-sizing: border-box;
  position: relative;

  @media only screen and (max-width: 728px) {
    padding: 64px 0;
  }
`;
