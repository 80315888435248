import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.Wrapper>
      <S.ContentWrapper>
        <S.TitleWrapper>
          <Skeleton width="100%" height={34} />
        </S.TitleWrapper>

        <S.DescriptionWrapper>
          <Skeleton width="100%" height={20} />
          <Skeleton width="100%" height={20} />
        </S.DescriptionWrapper>

        <S.IntegrationWrapper>
          <Skeleton width="100%" height={58} style={{ borderRadius: 7 }} />
        </S.IntegrationWrapper>

        <S.ExampleTextWrapper>
          <Skeleton width="100%" height={24} />
        </S.ExampleTextWrapper>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default SkeletonLoading;
