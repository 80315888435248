import { AxiosResponse } from 'axios';

import { ApiService } from '@services/api';

import { USER_API_DOMAIN } from '@constants/api';
import { IUpdateBequestProps } from '@domain/interfaces/common/bequest/IBequest';

export class MambaService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public updateMambaTerms({ data }: IUpdateBequestProps): Promise<AxiosResponse<void>> {
    return this.apiService.post<void>(`/api/v1/users/bequest-terms-of-service`, {
      ...data,
    });
  }
}

const mambaService = new MambaService(USER_API_DOMAIN);

export default mambaService;
