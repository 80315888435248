import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <>
      <S.AdSenseCredentialWrapper>
        <S.AvatarWrapper>
          <Skeleton height={32} width={32} />
        </S.AvatarWrapper>
        <S.NameWrapper>
          <Skeleton width="100%" height={20} />
        </S.NameWrapper>
      </S.AdSenseCredentialWrapper>

      <S.AdSenseCredentialWrapper>
        <S.AvatarWrapper>
          <Skeleton height={32} width={32} />
        </S.AvatarWrapper>
        <S.NameWrapper>
          <Skeleton width="100%" height={20} />
        </S.NameWrapper>
      </S.AdSenseCredentialWrapper>

      <S.AdSenseCredentialWrapper>
        <S.AvatarWrapper>
          <Skeleton height={32} width={32} />
        </S.AvatarWrapper>
        <S.NameWrapper>
          <Skeleton width="100%" height={20} />
        </S.NameWrapper>
      </S.AdSenseCredentialWrapper>
    </>
  );
};

export default SkeletonLoading;
