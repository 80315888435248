import {
  Bling,
  CartPanda,
  Dsers,
  MercadoLivre,
  NuvemShop,
  Pen,
  Shopify,
  Tiny,
} from '@profitfy/pakkun-icons';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { ETextWeight } from '@domain/enums/components/EText';
import {
  IProductSynchronization,
  ITableRowProps,
} from '@domain/interfaces/dashboard/ProductCost/IProductCost';

import Tooltip from '@components/common/core/Utils/Tooltip';
import { IParams } from '@domain/interfaces/IParams';
import { getProviderIcon } from '@helpers/dashboard/productCost/productCostUtils';
import { useToast } from '@helpers/hooks/useToast';
import productCostService from '@services/pages/dashboard/productCost/productCost';
import { TrashSimple } from 'phosphor-react';
import { useParams } from 'react-router-dom';
import { useConfig } from '@helpers/hooks/useConfig';
import TooltipContent from './TooltipContent';

import DeleteModal from './DeleteModal';
import DeleteModalError from './DeleteModalError';
import * as S from './styles';

const TableRow: React.FC<ITableRowProps> = ({ product, mutate }) => {
  const syncIcon = {
    SHOPIFY: <Shopify size={16} />,
    CART_PANDA: <CartPanda size={16} />,
    DSERS: <Dsers size={16} />,
    NUVEM_SHOP: <NuvemShop size={16} />,
    MERCADO_LIVRE: <MercadoLivre size={16} />,
    BLING: <Bling size={16} />,
    TINY: <Tiny size={16} />,
  };

  const theme: any = useTheme();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { analytics, user } = useConfig();

  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const [isImageError, setIsImageError] = React.useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
  const [isDeleteModalErrorOpen, setIsDeleteModalErrorOpen] = React.useState<boolean>(false);

  const handleDeleteModalOpen = React.useCallback(() => setIsDeleteModalOpen(!isDeleteModalOpen), [
    isDeleteModalOpen,
  ]);

  const handleDeleteModalErrorOpen = React.useCallback(
    () => setIsDeleteModalErrorOpen(prev => !prev),
    [],
  );

  const getMostRecentSync = React.useCallback(
    (productSynchronizations: Array<IProductSynchronization>) => {
      const mostRecentSynchronization = productSynchronizations.reduce((previous, current) => {
        return new Date(previous.updated_at) > new Date(current.updated_at) ? previous : current;
      });

      return mostRecentSynchronization;
    },
    [],
  );

  const getMostRecentSyncIcon = React.useCallback(
    (productSynchronizations: Array<IProductSynchronization>) => {
      const mostRecentSynchronization = getMostRecentSync(productSynchronizations);
      const Icon = syncIcon[mostRecentSynchronization.provider];

      return Icon;
    },
    [syncIcon, getMostRecentSync],
  );

  const handleIsHovering = React.useCallback(status => setIsHovering(status), []);

  const ProviderIcon = getProviderIcon(product?.provider);

  const onDelete = React.useCallback(async () => {
    setIsDeleting(true);

    try {
      await productCostService.deleteProduct({ storeAliasId, productAliasId: product.alias_id });

      await mutate();

      analytics?.track(
        'Delete Product Button Clicked',
        { email: user?.email },
        { context: { storeAliasId } },
      );

      handleDeleteModalOpen();

      toast.success('Produto excluído com sucesso!');
    } catch (error: any) {
      setIsDeleteModalOpen(false);
      handleDeleteModalErrorOpen();
    } finally {
      setIsDeleting(false);
    }
  }, [
    handleDeleteModalOpen,
    mutate,
    product.alias_id,
    storeAliasId,
    toast,
    handleDeleteModalErrorOpen,
    analytics,
    user,
  ]);

  return (
    <S.ProductCostTable.Row
      borderBottom
      key={product.alias_id}
      onMouseEnter={() => handleIsHovering(true)}
      onMouseLeave={() => handleIsHovering(false)}
    >
      <S.ProductCostTable.Data>
        <S.ProductDetailsWrapper>
          {product.image_url && !isImageError ? (
            <S.Image
              src={product.image_url}
              alt="Profitfy.me Custo do Produto"
              onError={() => setIsImageError(true)}
            />
          ) : (
            <S.NoImage />
          )}
          <S.ProductDetailsText weight={ETextWeight.MEDIUM}>{product.name}</S.ProductDetailsText>
        </S.ProductDetailsWrapper>
      </S.ProductCostTable.Data>

      <S.ProductCostTable.Data>
        <S.ProviderWrapper>
          <ProviderIcon size={20} color="white" />
        </S.ProviderWrapper>
      </S.ProductCostTable.Data>

      <S.ProductCostTable.Data>
        {product.product_synchronizations?.length ? (
          <S.Tooltip
            content={
              <TooltipContent
                synchronization={getMostRecentSync(product.product_synchronizations)}
              />
            }
          >
            <S.SynchronizationIconWrapper>
              {getMostRecentSyncIcon(product.product_synchronizations)}
            </S.SynchronizationIconWrapper>
          </S.Tooltip>
        ) : (
          <></>
        )}
      </S.ProductCostTable.Data>

      <S.ProductCostTable.Data align={EHeadAlign.CENTER}>
        <S.ActionsButtonWrapper>
          <S.ActionsButton isHovering={isHovering}>
            <Tooltip content="Editar">
              <S.ActionButton to={`products-costs/${product.alias_id}`}>
                <Pen outline size={16} color={theme.colors.font} />
              </S.ActionButton>
            </Tooltip>
          </S.ActionsButton>

          <S.ActionsButton isHovering={isHovering}>
            <Tooltip content="Excluir">
              <S.ActionDeleteButton onClick={handleDeleteModalOpen}>
                <TrashSimple size={16} color={theme.colors.font} />
              </S.ActionDeleteButton>
            </Tooltip>
          </S.ActionsButton>
        </S.ActionsButtonWrapper>
      </S.ProductCostTable.Data>
      <DeleteModal
        isLoading={isDeleting}
        isOpen={isDeleteModalOpen}
        onDelete={onDelete}
        toggle={handleDeleteModalOpen}
        productName={product.name}
      />

      <DeleteModalError isOpen={isDeleteModalErrorOpen} toggle={handleDeleteModalErrorOpen} />
    </S.ProductCostTable.Row>
  );
};

export default TableRow;
