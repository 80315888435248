import { ApiService } from '@services/api';
import { LONG_API_DOMAIN } from '@constants/api';
import {
  ISyncFacebookCampaigns,
  IUpdateFacebookCampaign,
} from '@domain/interfaces/dashboard/AdSense/Facebook/IFacebookCampaign';

export class FacebookCampaignService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public syncFacebookCampaigns({ storeAliasId }: ISyncFacebookCampaigns): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/facebook/ad-sense-campaigns/sync`,
    );
  }

  public updateFacebookCampaign({
    storeAliasId,
    adSenseCampaignAliasId,
    productAliasId,
    data,
  }: IUpdateFacebookCampaign): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/facebook/ad-sense-campaigns/${adSenseCampaignAliasId}`,
      { ...data },
    );
  }
}

const facebookCampaignService = new FacebookCampaignService(LONG_API_DOMAIN);

export default facebookCampaignService;
