import React from 'react';
import { Plus, ProfileConfig, OperationalExpends, Exit, Group } from '@profitfy/pakkun-icons';
import { useHistory, useLocation } from 'react-router-dom';

import { EAvatarSize } from '@domain/enums/components/EAvatar';
import { ETextWeight } from '@domain/enums/components/EText';
import { EUserStoreRole } from '@domain/enums/common/userStore/EUserStoreRole';
import {
  ESynchronizationRequestType,
  ESynchronizationType,
} from '@domain/enums/hooks/ESynchronization';
import { IStore } from '@domain/interfaces/stores/IStore';

import { useConfig } from '@helpers/hooks/useConfig';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useSubscriptionStatus } from '@helpers/hooks/common/store/subscription/useSubscriptionStatus';

import { getEnvironment } from '@helpers/utils/environment';

import { LONG_API_DOMAIN } from '@constants/api';

import * as S from './styles';

const Dropdown: React.FC = ({ children }) => {
  const { handleLogout, isInternalTeam } = useConfig();
  const { stores, accessManagementData } = useStoreConfig();
  const history = useHistory();
  const { pathname } = useLocation();
  const { addSynchronization } = useSynchronization();
  const { handleProcessingPaymentAlertOpen } = useSubscriptionStatus();

  const isStoreOwner = accessManagementData?.role === EUserStoreRole.OWNER;

  const [storeAliasId, setStoreAliasId] = React.useState<string>('');

  const handleAdmin = React.useCallback(async () => {
    history.push(`/${storeAliasId}/dashboard/admin-panel`);
  }, [history, storeAliasId]);

  const handleInviteMember = React.useCallback(async () => {
    history.push(`/${storeAliasId}/dashboard/access-manager`);
  }, [history, storeAliasId]);

  const handleProfile = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/profile`);
  }, [history, storeAliasId]);

  const handleStoreSubscription = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/subscription`);
  }, [history, storeAliasId]);

  const handleAddStore = React.useCallback(() => {
    history.push(`/stores`);
  }, [history]);

  const handleChangeStore = React.useCallback(
    newStoreAliasId => {
      handleProcessingPaymentAlertOpen(false);
      history.push(`/${newStoreAliasId}/dashboard/overview/financial`);
    },
    [history, handleProcessingPaymentAlertOpen],
  );

  const syncDemoData = React.useCallback(() => {
    addSynchronization({
      name: 'Demo',
      type: ESynchronizationType.DEMO_STORE,
      label: 'Demo',
      dependencies: [],
      config: {
        isOnboardingSynchronization: false,
        showNotification: true,
      },
      request: {
        type: ESynchronizationRequestType.POST,
        domain: LONG_API_DOMAIN,
        api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/demo/update-store-data`,
      },
    });
  }, [addSynchronization, storeAliasId]);

  React.useEffect(() => {
    const foundStoreAliasId = pathname.match(/store-([a-zA-Z0-9]+)/);

    if (foundStoreAliasId?.length) {
      setStoreAliasId(foundStoreAliasId[0]);
    }
  }, [pathname]);

  const isDevEnvironment = getEnvironment() === 'dev';

  const storesWithoutDemoStore = stores?.filter(
    store => store.alias_id !== 'store-EfH8xlMH5Cqhvu01VJD9MqwfWlmr',
  );

  return (
    <S.CustomDropdown>
      <S.CustomDropdown.Trigger>{children}</S.CustomDropdown.Trigger>

      <S.CustomDropdown.Content sideOffset={12} align="end" style={{ borderRadius: 15 }}>
        <S.StoresWrapper>
          {storesWithoutDemoStore?.map((store: IStore) => (
            <S.CustomDropdown.Item
              onClick={() => handleChangeStore(store.alias_id)}
              key={store.alias_id}
            >
              <S.StoreWrapper>
                <S.StoreAvatar
                  isCurrentStore={storeAliasId === store.alias_id}
                  word={store?.name.charAt(0) || 'N'}
                  size={EAvatarSize.SMALL}
                  alt="Store avatar"
                />
                <S.StoreName
                  isCurrentStore={storeAliasId === store.alias_id}
                  weight={ETextWeight.MEDIUM}
                >
                  {store?.name}
                </S.StoreName>
              </S.StoreWrapper>
            </S.CustomDropdown.Item>
          ))}
        </S.StoresWrapper>

        <S.CustomDropdown.Separator />

        <S.CustomDropdown.Item onClick={handleAddStore}>
          <S.NewStoreWrapper>
            <Plus size={16} color="gray" />
            <S.OptionName weight={ETextWeight.MEDIUM}>Adicionar nova loja</S.OptionName>
          </S.NewStoreWrapper>
        </S.CustomDropdown.Item>

        <S.CustomDropdown.Separator />

        <S.OptionsWrapper>
          {isInternalTeam && (
            <S.CustomDropdown.Item onClick={handleAdmin}>
              <S.OptionWrapper>
                <Group size={24} color="#D9DDE9" />
                <S.OptionName weight={ETextWeight.MEDIUM}>Painel de Admin</S.OptionName>
              </S.OptionWrapper>
            </S.CustomDropdown.Item>
          )}

          <S.CustomDropdown.Item onClick={handleProfile}>
            <S.OptionWrapper>
              <ProfileConfig size={24} color="#D9DDE9" />
              <S.OptionName weight={ETextWeight.MEDIUM}>Meu perfil</S.OptionName>
            </S.OptionWrapper>
          </S.CustomDropdown.Item>

          {(isStoreOwner || isInternalTeam) && (
            <S.CustomDropdown.Item onClick={handleInviteMember}>
              <S.OptionWrapper>
                <Group size={24} color="#D9DDE9" />
                <S.OptionName weight={ETextWeight.MEDIUM}>Convidar membro</S.OptionName>
              </S.OptionWrapper>
            </S.CustomDropdown.Item>
          )}

          <S.CustomDropdown.Item onClick={handleStoreSubscription}>
            <S.OptionWrapper>
              <OperationalExpends size={24} color="#D9DDE9" />
              <S.OptionName weight={ETextWeight.MEDIUM}>Minha assinatura</S.OptionName>
            </S.OptionWrapper>
          </S.CustomDropdown.Item>

          <S.CustomDropdown.Item onClick={handleLogout}>
            <S.OptionWrapper>
              <Exit size={20} color="#D9DDE9" />
              <S.OptionName weight={ETextWeight.MEDIUM}>Sair</S.OptionName>
            </S.OptionWrapper>
          </S.CustomDropdown.Item>

          {isDevEnvironment && (
            <S.CustomDropdown.Item onClick={syncDemoData}>
              <S.OptionWrapper>
                <S.OptionName weight={ETextWeight.MEDIUM}>Atualizar dados</S.OptionName>
              </S.OptionWrapper>
            </S.CustomDropdown.Item>
          )}
        </S.OptionsWrapper>

        <S.CustomDropdown.Arrow />
      </S.CustomDropdown.Content>
    </S.CustomDropdown>
  );
};

export default Dropdown;
