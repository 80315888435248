import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useToast } from '@helpers/hooks/useToast';

import { EDowngradeStep } from '@domain/enums/subscription/EDowngradeStep';
import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { ISubscriptionPlansProvider } from '@domain/interfaces/dashboard/Subscription/ISubscriptionPlans';
import { IParams } from '@domain/interfaces/IParams';

import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';

import { ALL_PLANS } from '@constants/plans';

import userStoreService from '@services/pages/dashboard/accessManagement/userStore';
import adSenseCredentialService from '@services/pages/dashboard/adSense/adSenseCredential';
import { IAdSenseCredential } from '@domain/interfaces/dashboard/AdSense/IAdSenseCredential';

const SubscriptionPlansContext = React.createContext<ISubscriptionPlansProvider | null>(null);

export const SubscriptionPlansProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const history = useHistory();
  const { changePlan } = useStoreSubscription();
  const { storeAliasId } = useParams<IParams>();

  const [isChangingPlan, setIsChangingPlan] = React.useState<boolean>(false);
  const [isUpgradingModalOpen, setIsUpgradingModalOpen] = React.useState<boolean>(false);
  const [isDowngradingModalOpen, setIsDowngradingModalOpen] = React.useState<boolean>(false);
  const [isDowngradeSideModalOpen, setIsDowngradeSideModalOpen] = React.useState<boolean>(false);
  const [downgradeStep, setDowngradeStep] = React.useState<EDowngradeStep>(
    EDowngradeStep.ADS_PROVIDER,
  );
  const [selectedPlanToChange, setSelectedPlanToChange] = React.useState<string | undefined>(
    undefined,
  );
  const [selectedAdsProviderToRemove, setSelectedAdsProviderToRemove] = React.useState<
    Array<EAdSenseProvider>
  >([]);
  const [selectedMembersToRemove, setSelectedMembersToRemove] = React.useState<Array<string>>([]);
  const [
    selectedFacebookCredentialsToRemove,
    setSelectedFacebookCredentialsToRemove,
  ] = React.useState<Array<string>>([]);
  const [isDowngradingSubscription, setIsDowngradingSubscription] = React.useState<boolean>(false);
  const [isPendingPaymentModalOpen, setIsPendingPaymentModalOpen] = React.useState<boolean>(false);

  const {
    users,
    isLoading: isLoadingUserStores,
    isValidating: isValidatingUserStore,
    mutate: mutateUsersStore,
    error: usersStoreError,
  } = userStoreService.listUsersStore({ storeAliasId });
  const {
    adSenseCredentials: facebookCredentials,
    isLoading: isLoadingFacebookCredentials,
    isValidating: isValidatingFacebookCredentials,
    mutate: mutateFacebookCredentials,
    error: facebookCredentialsError,
  } = adSenseCredentialService.listAdSenseCredentials({
    storeAliasId,
    page: 1,
    filter: { provider: EAdSenseProvider.FACEBOOK },
  });

  const isLoadingDowngradeRequests = isLoadingUserStores || isLoadingFacebookCredentials;
  const isValidatingDowngradeRequests = isValidatingUserStore || isValidatingFacebookCredentials;
  const isDowngradeRequestsError = Boolean(usersStoreError) || Boolean(facebookCredentialsError);

  const mutate = React.useCallback(() => {
    const promises = [];

    if (usersStoreError) promises.push(mutateUsersStore());

    if (facebookCredentialsError) promises.push(mutateFacebookCredentials());

    Promise.all(promises);
  }, [usersStoreError, mutateUsersStore, facebookCredentialsError, mutateFacebookCredentials]);

  const handlePendingPaymentModalOpen = React.useCallback(() => {
    setIsPendingPaymentModalOpen(state => !state);
  }, []);

  const handleDowngradingSubscription = React.useCallback(
    state => setIsDowngradingSubscription(state),
    [],
  );

  const resetDowngrade = React.useCallback(() => {
    setSelectedPlanToChange(undefined);
    setSelectedAdsProviderToRemove([]);
    setSelectedFacebookCredentialsToRemove([]);
    setSelectedMembersToRemove([]);
    setDowngradeStep(EDowngradeStep.ADS_PROVIDER);
    setIsDowngradeSideModalOpen(false);
  }, []);

  const handleSelectedAdsProviderToRemove = React.useCallback(newSelectedAdsProviderToRemove => {
    setSelectedAdsProviderToRemove(newSelectedAdsProviderToRemove);
  }, []);

  const handleSelectedMembersToRemove = React.useCallback(newSelectedMembersToRemove => {
    setSelectedMembersToRemove(newSelectedMembersToRemove);
  }, []);

  const handleSelectedFacebookCredentialsToRemove = React.useCallback(
    newSelectedFacebookCredentialsToRemove => {
      setSelectedFacebookCredentialsToRemove(newSelectedFacebookCredentialsToRemove);
    },
    [],
  );

  const handleDowngradeSideModalOpen = React.useCallback(() => {
    setIsDowngradeSideModalOpen(state => !state);
  }, []);

  const handleChangingPlan = React.useCallback(state => setIsChangingPlan(state), []);

  const handleSelectedPlanToChange = React.useCallback(
    planToChange => setSelectedPlanToChange(planToChange),
    [],
  );

  const handleUpgradingModalOpen = React.useCallback(
    () => setIsUpgradingModalOpen(state => !state),
    [],
  );

  const handleDowngradingModalOpen = React.useCallback(
    () => setIsDowngradingModalOpen(state => !state),
    [],
  );

  const handleAdsProvider = React.useCallback(async () => {
    if (!selectedAdsProviderToRemove.length) return;

    try {
      const listAdSenseCredentialsPromises = selectedAdsProviderToRemove.map(provider => {
        return adSenseCredentialService.listAdSenseCredentialsPromise({
          storeAliasId,
          page: 1,
          filter: { provider },
        });
      });

      const listAdSenseCredentialsResponses = await Promise.all(listAdSenseCredentialsPromises);

      const blockAdSenseCredentialsPromises = listAdSenseCredentialsResponses.map(response => {
        const { data } = response;

        const credentialIds = data.ad_sense_credentials.map(
          (credential: IAdSenseCredential) => credential.id,
        );

        return adSenseCredentialService.blockAdSenseCredentials({
          storeAliasId,
          data: {
            ad_sense_credential_ids: credentialIds,
          },
        });
      });

      await Promise.all(blockAdSenseCredentialsPromises);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [selectedAdsProviderToRemove, storeAliasId, toast]);

  const handleInvitedMembers = React.useCallback(async () => {
    if (!selectedMembersToRemove) return;

    try {
      const membersIds = selectedMembersToRemove;

      await userStoreService.deleteUsersStorePromise({
        storeAliasId,
        data: { user_ids: membersIds },
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [selectedMembersToRemove, storeAliasId, toast]);

  const handleFacebookCredentials = React.useCallback(async () => {
    if (!selectedFacebookCredentialsToRemove) return;

    try {
      const credentialIds = selectedFacebookCredentialsToRemove;

      await adSenseCredentialService.blockAdSenseCredentials({
        storeAliasId,
        data: {
          ad_sense_credential_ids: credentialIds,
        },
      });
    } catch (error: any) {
      toast(error?.response?.data?.message);
    }
  }, [selectedFacebookCredentialsToRemove, storeAliasId, toast]);

  const downgrade = React.useCallback(async () => {
    setIsChangingPlan(true);

    if (!selectedPlanToChange) {
      toast.error('Selecione um plano!');
      setIsChangingPlan(false);
      return;
    }

    await Promise.all([handleAdsProvider, handleInvitedMembers, handleFacebookCredentials]);

    const selectedPlanProvider = selectedPlanToChange;

    resetDowngrade();

    if (window.location.href.includes('pricing')) {
      setIsChangingPlan(false);
      history.push(`/${storeAliasId}/subscription/address`);
      return;
    }

    await changePlan(selectedPlanProvider);

    setIsChangingPlan(false);
  }, [
    changePlan,
    handleAdsProvider,
    handleFacebookCredentials,
    handleInvitedMembers,
    resetDowngrade,
    selectedPlanToChange,
    toast,
    history,
    storeAliasId,
  ]);

  const handleDowngradeStep = React.useCallback(
    async (currentStep: EDowngradeStep | undefined, selectedPlan: string | undefined) => {
      if (!selectedPlan) return;

      setIsDowngradeSideModalOpen(true);

      const foundSelectedPlan = ALL_PLANS.find(
        plan => plan.identifier === selectedPlan,
      ) as typeof ALL_PLANS[number];

      const maxMembers = foundSelectedPlan.members === 0 ? Infinity : foundSelectedPlan.members;
      const maxFacebookProfiles =
        foundSelectedPlan.facebookProfile === 0 ? Infinity : foundSelectedPlan.facebookProfile;

      const shouldDowngradeInvitedMembers = users?.length > maxMembers;
      const shouldDowngradeFacebookProfiles = facebookCredentials?.length > maxFacebookProfiles;

      const isAdsProviderStep = currentStep === EDowngradeStep.ADS_PROVIDER;
      const isInvitedMembersStep = currentStep === EDowngradeStep.INVITED_MEMBERS;

      if (!currentStep && shouldDowngradeInvitedMembers) {
        setDowngradeStep(EDowngradeStep.INVITED_MEMBERS);
        return;
      }

      if (!currentStep && shouldDowngradeFacebookProfiles) {
        setDowngradeStep(EDowngradeStep.FACEBOOK_AD_CREDENTIALS);
        return;
      }

      if (isAdsProviderStep && shouldDowngradeInvitedMembers) {
        setDowngradeStep(EDowngradeStep.INVITED_MEMBERS);
        return;
      }

      if (isAdsProviderStep && shouldDowngradeFacebookProfiles) {
        setDowngradeStep(EDowngradeStep.FACEBOOK_AD_CREDENTIALS);
        return;
      }

      if (isInvitedMembersStep && shouldDowngradeFacebookProfiles) {
        setDowngradeStep(EDowngradeStep.FACEBOOK_AD_CREDENTIALS);
        return;
      }

      downgrade();
    },
    [facebookCredentials, users, downgrade],
  );

  const upgrade = React.useCallback(async () => {
    setIsChangingPlan(true);

    if (selectedPlanToChange) {
      const selectedPlanIdentifier = selectedPlanToChange;

      handleSelectedPlanToChange(undefined);
      handleUpgradingModalOpen();

      await changePlan(selectedPlanIdentifier);
    } else {
      toast.error('Selecione um plano!');
    }

    setIsChangingPlan(false);
  }, [
    selectedPlanToChange,
    handleSelectedPlanToChange,
    handleUpgradingModalOpen,
    changePlan,
    toast,
  ]);

  return (
    <SubscriptionPlansContext.Provider
      value={{
        handleDowngradingModalOpen,
        handleSelectedPlanToChange,
        handleUpgradingModalOpen,
        isChangingPlan,
        isDowngradingModalOpen,
        isUpgradingModalOpen,
        selectedPlanToChange,
        handleChangingPlan,
        handleDowngradeSideModalOpen,
        isDowngradeSideModalOpen,
        downgradeStep,
        facebookCredentials,
        isDowngradeRequestsError,
        isLoadingDowngradeRequests,
        isValidatingDowngradeRequests,
        mutate,
        users,
        handleDowngradeStep,
        resetDowngrade,
        selectedAdsProviderToRemove,
        selectedFacebookCredentialsToRemove,
        selectedMembersToRemove,
        handleSelectedAdsProviderToRemove,
        handleSelectedMembersToRemove,
        handleSelectedFacebookCredentialsToRemove,
        upgrade,
        handleDowngradingSubscription,
        isDowngradingSubscription,
        handlePendingPaymentModalOpen,
        isPendingPaymentModalOpen,
      }}
    >
      {children}
    </SubscriptionPlansContext.Provider>
  );
};

export const useSubscriptionPlans = (): ISubscriptionPlansProvider => {
  const context = React.useContext(SubscriptionPlansContext);

  if (!context) {
    throw new Error('useSubscriptionPlans must be used within SubscriptionPlansProvider');
  }

  return context;
};
