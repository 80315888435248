import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
`;

export const ExtraGatewaysIcon = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.gray[7]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -40px;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.font};
  font-size: 0.75rem;
  font-weight: 500;
`;

export const GatewaysIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  margin-right: -40px;
`;
