import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import HeaderBase from '@components/common/core/Utils/Table/Head';

export const BoletoTable = styled(TableBase)`
  & > table {
    & > thead > tr {
      & > th {
        padding-left: 16px;
        padding-right: 16px;
      }
      & > th:first-child {
        padding-left: 32px;
        padding-right: 16px;
      }

      & > th:last-child {
        padding-right: 32px;
      }
    }

    & > tbody > tr {
      & > td {
        padding-left: 16px;
        padding-right: 16px;
      }
      & > td:first-child {
        padding-left: 32px;
        padding-right: 16px;
      }

      & > td:last-child {
        padding-right: 32px;
      }
    }
  }
`;

export const HeadEnd = styled(HeaderBase)`
  display: flex;
  justify-content: flex-end;
`;

export const HeadWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;
