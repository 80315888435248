import React from 'react';

import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import { ChevronRight } from '@profitfy/pakkun-icons';
import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';
import { IProduct, IMetrics } from '@domain/interfaces/dashboard/ProductAnalytics/IProducts';
import { ETextSize } from '@domain/enums/components/EText';
import { ITopThreeProductsIndexProps } from '@domain/interfaces/dashboard/FinancialDashboard/ITopThreeProducts';

import { useConfig } from '@helpers/hooks/useConfig';
import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

interface IProductContainerProps {
  product: IProduct;
  metrics: IMetrics;
  isNetProfitSelected: boolean;
  index: number;
}

const ProductContainer: React.FC<IProductContainerProps> = ({
  metrics,
  product,
  isNetProfitSelected,
  index,
}) => {
  const { storeAliasId } = useParams<IParams>();
  const { isDashboardDataHidden } = useConfig();
  const { colors } = useTheme();

  const [isImageError, setIsImageError] = React.useState<boolean>(false);

  const TopThreeProductsIndex: React.FC<ITopThreeProductsIndexProps> = ({
    indice,
    productName,
  }) => {
    return (
      <>
        <strong>{`#${indice + 1}`}</strong>
        {` - ${productName}`}
      </>
    );
  };

  const parseNetRevenueAmount = (netRevenueAmount: number): any => {
    return `R$ ${numberFormatter(netRevenueAmount, 2, true)}`;
  };

  const productAmountLabel = isNetProfitSelected ? 'Lucro Líquido' : 'Receita Líquida';
  const amount = isNetProfitSelected ? metrics.net_profit.amount : metrics.net_revenue.amount;
  const quantity = isNetProfitSelected ? metrics.net_profit.quantity : metrics.net_revenue.quantity;

  return (
    <S.ProductContainer
      to={`/${storeAliasId}/dashboard/overview/products-analytic/${product.alias_id}/details`}
      key={product.alias_id}
    >
      {!isImageError && product?.image_url ? (
        <S.Image
          src={product.image_url}
          alt="product-image"
          isHiddenContent={isDashboardDataHidden}
          onError={() => setIsImageError(true)}
        />
      ) : (
        <S.NoImageFill />
      )}
      <S.ProductGroup>
        <S.ProductName isHiddenContent={isDashboardDataHidden} fontWeight={EHeadingWeight.REGULAR}>
          <TopThreeProductsIndex indice={index} productName={product.name} />
        </S.ProductName>

        <S.ProductValueAndQuantityOrdersWrapper>
          <S.ProductValue isHiddenContent={isDashboardDataHidden}>
            {parseNetRevenueAmount(amount)}
          </S.ProductValue>
          <S.QuantityOrders isHiddenContent={isDashboardDataHidden}>{`(${
            quantity || 0
          })`}</S.QuantityOrders>
        </S.ProductValueAndQuantityOrdersWrapper>

        <S.ProductInfo size={ETextSize.B5}>{productAmountLabel}</S.ProductInfo>
      </S.ProductGroup>

      <ChevronRight size={20} color={colors.gray[1]} />
    </S.ProductContainer>
  );
};

export default ProductContainer;
