import React from 'react';
import { Feature } from '@profitfy/pakkun-icons';
import { useHistory } from 'react-router-dom';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ESyncType } from '@domain/enums/dashboard/adsManager/EScriptAndUtms';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';
import { useScriptAndUtms } from '@helpers/hooks/pages/dashboard/adsManager/onboarding/useScriptAndUtms';

import storesService from '@services/pages/stores/stores';

import * as S from './styles';

const Finished: React.FC = () => {
  const { finishedSynchronizations, handleFinishedSynchronizations } = useSynchronization();
  const { mutateStore, store } = useStoreConfig();
  const { toast } = useToast();
  const history = useHistory();
  const { analytics } = useConfig();
  const { syncType } = useScriptAndUtms();

  const [isUpdatingStore, setIsUpdatingStore] = React.useState<boolean>(false);

  const onClick = React.useCallback(async () => {
    setIsUpdatingStore(true);

    try {
      await storesService.updateStore(store.alias_id, {
        ...store,
        is_utm_onboarding_finished: true,
      });

      analytics?.track(
        'Ads Manager UTM Onboarding Finished',
        {},
        { context: { groupId: store.alias_id } },
      );

      await mutateStore();

      setIsUpdatingStore(false);
      handleFinishedSynchronizations([]);
      history.push(`/${store.alias_id}/dashboard/marketing/ads-manager`);
    } catch (error: any) {
      setIsUpdatingStore(false);
      toast.error(error?.response?.data?.message);
    }
  }, [handleFinishedSynchronizations, store, history, toast, mutateStore, analytics]);

  const foundSynchronization = finishedSynchronizations.find(
    synchronization => synchronization.type === ESynchronizationType.ADS_WITHOUT_UTMS,
  );

  const successfulQuantityVariable = foundSynchronization?.variables.find(
    variable => variable.key === 'total_utm_successful_quantity',
  );

  const syncedAds = successfulQuantityVariable?.value || '0';
  const isScheduledSyncType = syncType === ESyncType.SCHEDULE;
  const imageSource = isScheduledSyncType
    ? 'https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/ads-manager/onboarding/schedule/schedule.svg'
    : 'https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/ads-manager/onboarding/add-utms/congratulations.svg';

  return (
    <S.Wrapper>
      <S.ImageAndContentWrapper>
        <S.Image src={imageSource} alt="Profitfy Utm Tags Finished" />

        <S.Content>
          <S.ContentTitle type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
            {isScheduledSyncType ? (
              <>
                Perfeito, a sincronização de suas UTMs foi <strong>agendada.</strong>
              </>
            ) : (
              <>
                Parabéns, foram sincronizadas <strong>{syncedAds}</strong> UTMs tags em seus
                criativos
              </>
            )}
          </S.ContentTitle>

          <S.InsightsWrapper>
            <S.Insight>
              <S.InsightIconWrapper>
                <Feature size={12} />
              </S.InsightIconWrapper>
              <S.InsightText>
                {isScheduledSyncType ? (
                  <>
                    Você terá maior <strong>capacidade de análise</strong>
                  </>
                ) : (
                  <>
                    Você terá maior <strong>capacidade de análise</strong>
                  </>
                )}
              </S.InsightText>
            </S.Insight>

            <S.Insight>
              <S.InsightIconWrapper>
                <Feature size={12} />
              </S.InsightIconWrapper>
              <S.InsightText>
                Cruzamento de suas métricas <strong>financeiras</strong> <br />
                com as de <strong>campanhas</strong>
              </S.InsightText>
            </S.Insight>

            <S.Insight>
              <S.InsightIconWrapper>
                <Feature size={12} />
              </S.InsightIconWrapper>
              <S.InsightText>
                Maior assertividade nas <strong>conversões</strong>
              </S.InsightText>
            </S.Insight>
          </S.InsightsWrapper>
        </S.Content>
      </S.ImageAndContentWrapper>

      <S.NextStepButton onClick={onClick} isLoading={isUpdatingStore} disabled={isUpdatingStore}>
        Ver minhas campanhas
      </S.NextStepButton>
    </S.Wrapper>
  );
};

export default Finished;
