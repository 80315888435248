import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.gray[8]};
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  overflow-y: scroll;
`;
