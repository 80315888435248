import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import FormBase from '@components/common/core/Inputs/Form';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import SelectBase from '@components/common/core/Inputs/Select';

export const Option = styled(OptionBase)``;

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 48px;
  align-self: flex-start;

  @media only screen and (max-width: 1345px) {
    padding-top: 60px;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 491px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Heading = styled(HeadingBase)``;

export const CreateNewGroupButton = styled.button`
  width: 100%;
  max-width: 135px;
  height: 36px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Form = styled(FormBase)`
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 8px;
  padding: 24px 32px;
  box-sizing: border-box;
`;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)``;

export const GroupSelect = styled(SelectBase)`
  width: 100%;
`;

export const SendInviteButton = styled(ButtonBase)`
  margin-left: auto;
`;
