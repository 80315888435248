import React from 'react';
import { useForm } from 'react-hook-form';
import { SimpleCheck, SimpleClose } from '@profitfy/pakkun-icons';
import { CircleSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components/macro';
import { yupResolver } from '@hookform/resolvers/yup';

import Form from '@components/common/core/Inputs/Form';
import { IInputProps } from '@domain/interfaces/dashboard/ProductTag/ISideModalTags';
import { useCampaigns } from '@helpers/hooks/pages/dashboard/campaigns/useCampaigns';
import { updateProductTagSchema } from '@helpers/validators/dashboard/productTag/productTag';
import { useToast } from '@helpers/hooks/useToast';

import * as S from './styles';

const Input: React.FC<IInputProps> = ({
  handleIsEditing,
  handleTagName,
  tagName,
  onBlur,
  productTag,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(updateProductTagSchema) });
  const theme = useTheme();
  const { updateProductTag } = useCampaigns();
  const { toast } = useToast();

  const [isUpdatingProductTag, setIsUpdatingProductTag] = React.useState<boolean>(false);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const onSubmit = React.useCallback(
    async data => {
      const productTagName = data.tag;
      const productTagAliasId = productTag.alias_id;

      if (productTagName !== tagName) {
        setIsUpdatingProductTag(true);

        await updateProductTag(productTagAliasId, productTagName);

        setIsUpdatingProductTag(false);
      }

      handleTagName(data.tag);
      handleIsEditing();
    },
    [handleTagName, handleIsEditing, productTag, updateProductTag, tagName],
  );

  const onCancel = React.useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '[Produto Teste]';

      handleIsEditing();
    }
  }, [handleIsEditing]);

  React.useEffect(() => {
    if (errors.tag) {
      toast.error(errors.tag.message);
    }
  }, [errors, toast]);

  return (
    <S.Wrapper onBlur={onBlur}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Input
            {...register('tag')}
            ref={inputRef}
            type="text"
            defaultValue={tagName}
            autoFocus
          />

          <S.ButtonsWrapper>
            <S.SaveButton type="submit">
              {isUpdatingProductTag ? (
                <CircleSpinner size={10} color={theme.colors.green.default} />
              ) : (
                <SimpleCheck size={14} />
              )}
            </S.SaveButton>
            <S.CancelButton type="button" onClick={onCancel}>
              <SimpleClose size={14} />
            </S.CancelButton>
          </S.ButtonsWrapper>
        </S.InputGroup>
      </Form>
    </S.Wrapper>
  );
};

export default Input;
