import logoImg from '@assets/img/brand/profitfy-logo.svg';
import {
  EExperienceRange,
  EProfitRange,
  EUserSegmentationStep,
} from '@domain/enums/onboarding/EUsersSegmentation';
import { useConfig } from '@helpers/hooks/useConfig';
import { useToast } from '@helpers/hooks/useToast';
import usersSegmentationervice from '@services/pages/onboarding/usersSegmentation';
import userService from '@services/user';
import { SignOut } from 'phosphor-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import sha256 from 'crypto-js/sha256';
import { normalizeEmail, normalizeName, normalizePhone } from '@helpers/utils/common/normalizer';

import FirstStep from './FirstStep';
import FourthStep from './FourthStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import * as S from './styles';

const UserSegmentation: React.FC = () => {
  const history = useHistory();
  const theme = useTheme();
  const { toast } = useToast();
  const { handleLogout, user, analytics, handleUserOnboarding, personalInfo } = useConfig();

  const [step, setStep] = React.useState<EUserSegmentationStep>(EUserSegmentationStep.FIRST_STEP);

  const [selectedSecondAnswer, setSelectedSecondAnswer] = React.useState<string>(
    EProfitRange.LESS_THAN_10_THOUSAND,
  );
  const [selectedThirdAnswer, setSelectedThirdAnswer] = React.useState<string>(
    EExperienceRange.LESS_THAN_6_MONTHS,
  );

  const [isCreatingUserSegmentation, setIsCreatingUserSegmentation] = React.useState<boolean>(
    false,
  );

  const handleSecondAnswer = React.useCallback(answer => setSelectedSecondAnswer(answer), []);
  const handleThirdAnswer = React.useCallback(answer => setSelectedThirdAnswer(answer), []);

  const formattedPhoneToRaw = (value = ''): string => {
    let newValue = value.replace(' ', '').replace('(', '').replace(')', '').replace('-', '');

    newValue = `55${newValue}`;

    return newValue;
  };

  const submitUserSegmentation = React.useCallback(async () => {
    setIsCreatingUserSegmentation(true);

    try {
      const payload = {
        data: {
          experience_range: selectedSecondAnswer,
          profit_range: selectedThirdAnswer,
        },
      };

      await usersSegmentationervice.createSegmentation(payload);

      const dataLayer = (window as Record<string, any>)?.dataLayer;

      if (dataLayer) {
        dataLayer.push({
          event: 'User Segmentation Form Submitted',
          phone: formattedPhoneToRaw(`${personalInfo?.phone_prefix}${personalInfo?.phone_number}`),
          hashed_phone: sha256(
            normalizePhone(
              formattedPhoneToRaw(`${personalInfo?.phone_prefix}${personalInfo?.phone_number}`),
            ),
          ).toString(),
          monthly_revenue: selectedThirdAnswer,
          segmentation_experience: selectedSecondAnswer,
          full_name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          hashed_full_name: sha256(
            normalizeName(`${user.first_name} ${user.last_name}`),
          ).toString(),
          hashed_first_name: sha256(normalizeName(user.first_name)).toString(),
          hashed_last_name: sha256(normalizeName(user.last_name)).toString(),
          hashed_email: sha256(normalizeEmail(user.email)).toString(),
        });
      }

      analytics?.track('User Segmentation Answers', {
        experience_range: selectedSecondAnswer,
        profit_range: selectedThirdAnswer,
        email: user?.email,
      });

      const userOnb = await userService.updateUserOnboarding({
        data: {
          has_answered_form_segmentation: true,
        },
      });

      handleUserOnboarding(userOnb.data.user);

      analytics?.track('User Onboarding Finished', {
        email: user?.email,
      });

      setStep(EUserSegmentationStep.FOURTH_STEP);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsCreatingUserSegmentation(false);
    }
  }, [
    selectedSecondAnswer,
    selectedThirdAnswer,
    toast,
    analytics,
    user,
    handleUserOnboarding,
    personalInfo,
  ]);

  const nextStep = React.useCallback(async () => {
    if (step === EUserSegmentationStep.FIRST_STEP) setStep(EUserSegmentationStep.SECOND_STEP);

    if (step === EUserSegmentationStep.SECOND_STEP) setStep(EUserSegmentationStep.THIRD_STEP);

    if (step === EUserSegmentationStep.THIRD_STEP) submitUserSegmentation();

    if (step === EUserSegmentationStep.FOURTH_STEP) {
      setIsCreatingUserSegmentation(true);

      try {
        const { data } = await userService.assignDemoStore();

        if (!data?.store) history.push('/stores');

        setIsCreatingUserSegmentation(false);

        history.push(`/store-EfH8xlMH5Cqhvu01VJD9MqwfWlmr/dashboard/overview/financial`);
      } catch (error: any) {
        history.push('/stores');
      }
    }
  }, [history, step, submitUserSegmentation]);

  const goBack = React.useCallback(backStep => {
    setStep(backStep);
  }, []);

  return (
    <S.Container>
      <S.ProgressBar step={step} />
      <S.HeaderWrapper>
        <S.LogoWrapper>
          <S.Logo src={logoImg} alt="profitfy-logo" />
        </S.LogoWrapper>

        <S.LogoutWrapper>
          <S.Button onClick={handleLogout}>
            <S.IconWrapper>
              <SignOut size={20} color={theme.colors.green.default} />
            </S.IconWrapper>

            <S.Text>Sair</S.Text>
          </S.Button>
        </S.LogoutWrapper>
      </S.HeaderWrapper>

      <S.Wrapper>
        {step === EUserSegmentationStep.FIRST_STEP && <FirstStep nextStep={nextStep} />}

        {step === EUserSegmentationStep.SECOND_STEP && (
          <SecondStep
            handleSecondAnswer={handleSecondAnswer}
            selectedAnswer={selectedSecondAnswer}
            goBack={goBack}
          />
        )}

        {step === EUserSegmentationStep.THIRD_STEP && (
          <ThirdStep
            handleThirdAnswer={handleThirdAnswer}
            selectedAnswer={selectedThirdAnswer}
            goBack={goBack}
          />
        )}

        {step === EUserSegmentationStep.FOURTH_STEP && <FourthStep />}

        {step !== EUserSegmentationStep.FIRST_STEP && (
          <S.SubmitButton type="submit" isLoading={isCreatingUserSegmentation} onClick={nextStep}>
            {step === EUserSegmentationStep.FOURTH_STEP ? 'Explorar a plataforma' : 'Continuar'}
          </S.SubmitButton>
        )}
      </S.Wrapper>
    </S.Container>
  );
};

export default UserSegmentation;
