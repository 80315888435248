import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

export const NoNotificationsText = styled(TextBase)``;

export const NoNotificationsWrapper = styled.div`
  height: 408px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NotificationsWrapper = styled.div`
  height: fit-content;
  max-height: 408px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 4px;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.gray[4]};
    position: absolute;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[1]};
    border-radius: 25px;
    transition: all 0.3s;
  }

  ::-webkit-scrollbar-thumb:hover {
    opacity: brightness(0.8);
  }
`;

export const ConfigurationsButton = styled.button`
  padding: unset;
  background-color: unset;
  border: unset;
  margin-right: 24px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const MainTab = styled.button`
  width: 100px;
  padding: unset;
  background-color: unset;
  border: unset;
  padding-bottom: 16px;
  font-family: ${({ theme }) => theme.fonts.body};
  line-height: 120%;
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.green.default};
  position: relative;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  &::before {
    content: '';
    height: 2px;
    width: 100px;
    background-color: ${({ theme }) => theme.colors.green.default};
    position: absolute;
    bottom: -2px;
    left: 0;
  }
`;

export const TabsAndConfigurationsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const ReadAllButton = styled.button`
  padding: unset;
  background-color: unset;
  border: unset;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.gray[2]};
  line-height: 120%;
  font-size: 0.875rem;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Title = styled(HeadingBase)``;

export const TitleAndReadAllWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0 24px;
`;

export const Header = styled.div`
  padding-top: 20px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray[4]};
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.24);
`;

export const Wrapper = styled.div``;
