import integrationsErrors from '@locales/integrations/integrations.json';

export const getTranslatedIntegrationsError = (
  error: Record<string, any>,
): string | Record<string, any> => {
  const errorMessage = error?.response?.data?.message;

  if (!errorMessage) return error;

  const errorsWithTranslation = [...integrationsErrors];

  const foundErrorMessage = errorsWithTranslation.find(
    errorWithTranslation => errorWithTranslation.apiError === errorMessage,
  );

  if (foundErrorMessage) return foundErrorMessage.translatedError;

  return errorMessage;
};
