import React from 'react';

import { useParams } from 'react-router-dom';

import { currencyToNumber } from '@helpers/masks';

import { IParams } from '@domain/interfaces/IParams';
import { IOrder } from '@domain/interfaces/common/orders/IOrders';
import { IChargebackProvider } from '@domain/interfaces/dashboard/Feed/IChargeback';

import { useDate } from '@helpers/hooks/useDate';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';

import ordersService from '@services/pages/dashboard/orders/orders';
import feedService from '@services/pages/dashboard/feed/feed';

import { useFeed } from './useFeed';

const ChargebackContext = React.createContext<IChargebackProvider | null>(null);

export const ChargebackProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { format, utcToZonedTime, subDays } = useDate();
  const { mutateFeedQuantity, mutateFeedGateways } = useStoreConfig();
  const { analytics, user } = useConfig();
  const {
    customValueCategoriesError,
    isLoadingCustomValueCategories,
    customValuesCategories,
    createCustomValue,
    createChargebackCategory,
  } = useFeed();

  const [isLoadingChargedbackOrders, setIsLoadingChargedbackOrders] = React.useState<boolean>(true);
  const [chargedbackOrders, setChargedbackOrders] = React.useState<Array<IOrder>>([]);
  const [chargedbackOrdersPage, setChargedbackOrdersPage] = React.useState<number>(0);
  const [chargedbackOrdersPageCount, setChargedbackOrdersPageCount] = React.useState<number>(1);
  const [isChargedbackOrdersError, setIsChargedbackOrdersError] = React.useState<boolean>(false);
  const [isSolvingOrder, setIsSolvingOrder] = React.useState<boolean>(false);

  const loadChargedbackOrders = React.useCallback(async () => {
    setIsLoadingChargedbackOrders(true);

    try {
      setIsChargedbackOrdersError(false);

      const { data } = await feedService.listFeedOrdersPromise({
        storeAliasId,
        page: chargedbackOrdersPage,
        startDate: format(subDays(utcToZonedTime(new Date()), 29), 'yyyy-MM-dd'),
        endDate: format(utcToZonedTime(new Date()), 'yyyy-MM-dd'),
        filter: {
          status: 'CHARGED_BACK',
          order_field: 'refunded_at',
          is_resolved_by_feed: 'false',
        },
      });

      if (data) {
        setChargedbackOrders(currentChargedbackOrders => [
          ...currentChargedbackOrders,
          ...data.orders,
        ]);
        setChargedbackOrdersPageCount(data.total_pages);
      }

      setIsLoadingChargedbackOrders(false);
    } catch (error: any) {
      setIsLoadingChargedbackOrders(false);
      setIsChargedbackOrdersError(true);
      toast.error(error?.response?.data?.message);
    }
  }, [format, storeAliasId, subDays, toast, utcToZonedTime, chargedbackOrdersPage]);

  const handleLoadMore = React.useCallback(() => {
    setChargedbackOrdersPage(oldPage => oldPage + 1);
  }, []);

  const updateOrder = React.useCallback(
    async (order: IOrder) => {
      await ordersService.updateOrder({
        storeAliasId,
        orderAliasId: order.alias_id,
        order,
      });
    },
    [storeAliasId],
  );

  const solveChargebackOrder = React.useCallback(
    async (order: IOrder, refundedAmount: string, date: Date) => {
      setIsSolvingOrder(true);

      try {
        if (isLoadingCustomValueCategories) {
          toast.info('Aguarde um instante e tente novamente.');
        }

        if (customValueCategoriesError) {
          toast.error('Não foi possível carregar os dados! Atualize a página e tente novamente.');
        }

        if (!isLoadingCustomValueCategories && !customValueCategoriesError) {
          const isRefundedAmountZero = currencyToNumber(refundedAmount) === 0;

          if (!isRefundedAmountZero) {
            const foundCategory = customValuesCategories.find(
              category => category.name === 'Chargeback',
            );

            if (!foundCategory) {
              const category = await createChargebackCategory();
              await createCustomValue(
                category.alias_id,
                order,
                currencyToNumber(refundedAmount),
                date,
              );
            } else {
              await createCustomValue(
                foundCategory.alias_id,
                order,
                currencyToNumber(refundedAmount),
                date,
              );
            }
          }

          await updateOrder(order);

          setChargedbackOrders(currentOrders => {
            const filteredOrders = currentOrders.filter(
              currentOrder => currentOrder.alias_id !== order.alias_id,
            );

            return filteredOrders;
          });

          analytics?.track(
            'Task Feed Solved',
            { type: 'CHARGE_BACK', email: user?.email },
            { context: { groupId: storeAliasId } },
          );
          analytics?.trackHubSpot(storeAliasId, { task_feed_solved: true });

          mutateFeedQuantity();
          mutateFeedGateways();

          toast.success('Pedido atualizado com sucesso!');
        }

        setIsSolvingOrder(false);
      } catch (error: any) {
        setIsSolvingOrder(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [
      createChargebackCategory,
      createCustomValue,
      customValueCategoriesError,
      customValuesCategories,
      isLoadingCustomValueCategories,
      toast,
      updateOrder,
      mutateFeedQuantity,
      analytics,
      mutateFeedGateways,
      user,
      storeAliasId,
    ],
  );

  const hasMore = chargedbackOrdersPage + 1 < chargedbackOrdersPageCount;

  return (
    <ChargebackContext.Provider
      value={{
        isLoadingChargedbackOrders,
        chargedbackOrders,
        loadChargedbackOrders,
        hasMore,
        handleLoadMore,
        isChargedbackOrdersError,
        solveChargebackOrder,
        isSolvingOrder,
      }}
    >
      {children}
    </ChargebackContext.Provider>
  );
};

export const useChargeback = (): IChargebackProvider => {
  const context = React.useContext(ChargebackContext);

  if (!context) {
    throw new Error('useChargeback must be used within ChargebackContext');
  }

  return context;
};
