import React from 'react';
import { useTheme } from 'styled-components';
import { X as CloseIcon, PencilSimple } from 'phosphor-react';

import { ICogsMassEditModal } from '@domain/interfaces/dashboard/ProductCost/IModal';

import { useProductCost } from '@helpers/hooks/pages/dashboard/productCost/productCost';

import EditMode from './EditMode';
import SpecificMode from './SpecificMode';
import NameMode from './NameMode';

import * as S from './styles';

const Modal: React.FC<ICogsMassEditModal> = ({ isOpen, toggle }) => {
  const { colors } = useTheme();
  const { handleResetProducts } = useProductCost();

  const [selectedMode, setSelectedMode] = React.useState<'specific' | 'name' | null>(null);

  const handleClose = React.useCallback(() => {
    handleResetProducts();
    toggle();
  }, [toggle, handleResetProducts]);

  return (
    <S.Wrapper isOpen={isOpen} toggle={handleClose}>
      <S.TitleWrapper>
        <S.GroupTitle>
          <PencilSimple size={20} color={colors.green.default} />
          <S.Title>Editar custo dos produtos em massa</S.Title>
        </S.GroupTitle>
        <S.CloseButton onClick={handleClose}>
          <CloseIcon size={20} color={colors.gray[3]} />
        </S.CloseButton>
      </S.TitleWrapper>

      <S.Row>
        {!selectedMode && <EditMode selectMode={setSelectedMode} />}
        {selectedMode === 'specific' && <SpecificMode toggle={toggle} />}
        {selectedMode === 'name' && <NameMode toggle={toggle} />}
      </S.Row>
    </S.Wrapper>
  );
};

export default Modal;
