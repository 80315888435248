import * as yup from 'yup';

export const legalPersonSchema = yup.object().shape({
  document: yup
    .string()
    .required('Este campo é obrigatório.')
    .test('legal document', 'Informe um CNPJ válido.', value => {
      if (value?.length === 18) return true;

      return false;
    }),
  name: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'Informe uma razão social válida.'),
  phone_number: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(14, 'Informe um celular válido.'),
  zip_code: yup
    .string()
    .required('Este campo é obrigatório.')
    .test('zip code', 'Informe um CEP válido.', value => {
      if (value?.length === 9) return true;

      return false;
    }),
  city: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'Informe uma cidade válida.')
    .max(60, 'O número máximo de carácteres foi atingido.'),
  street: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'Informe um endereço válido.')
    .max(80, 'O número máximo de carácteres foi atingido.'),
  street_number: yup.string().required('Este campo é obrigatório.'),
  neighborhood: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'Informe um bairro válido.')
    .max(60, 'O número máximo de carácteres foi atingido.'),
  complementary: yup.string().max(200, 'O número máximo de carácteres foi atingido.'),
});

export const naturalPersonSchema = yup.object().shape({
  document: yup
    .string()
    .required('Este campo é obrigatório.')
    .test('natural document', 'Informe um CPF válido.', value => {
      if (value?.length === 14) return true;

      return false;
    }),
  phone_number: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(14, 'Informe um celular válido.'),
  zip_code: yup
    .string()
    .required('Este campo é obrigatório.')
    .test('zip code', 'Informe um CEP válido.', value => {
      if (value?.length === 9) return true;

      return false;
    }),
  city: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'Informe uma cidade válida.')
    .max(60, 'O número máximo de carácteres foi atingido.'),
  street: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'Informe um endereço válido.')
    .max(80, 'O número máximo de carácteres foi atingido.'),
  street_number: yup.string().required('Este campo é obrigatório.'),
  neighborhood: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'Informe um bairro válido.')
    .max(60, 'O número máximo de carácteres foi atingido.'),
  complementary: yup.string().max(200, 'O número máximo de carácteres foi atingido.'),
});
