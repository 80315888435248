import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.CardWrapper>
      <Skeleton width="100%" height={232} style={{ borderRadius: 10 }} />
    </S.CardWrapper>
  );
};

export default SkeletonLoading;
