/* eslint-disable no-console */
import React from 'react';

import { ChevronDown, ChevronRight } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { IFeatureProps } from '@domain/interfaces/dashboard/AccessManagement/INewGroup';
import { useAccessManager } from '@helpers/hooks/pages/dashboard/accessManagement/useAccessManager';

import * as S from './styles';

const Feature: React.FC<IFeatureProps> = ({ feature, defaultOpen }) => {
  const theme = useTheme();

  const { handleSelectedPermissions, selectedPermissions } = useAccessManager();

  const [isPermissionsVisible, setIsPermissionsVisible] = React.useState<boolean>(defaultOpen);

  const handlePermissionsVisible = React.useCallback(() => {
    setIsPermissionsVisible(!isPermissionsVisible);
  }, [isPermissionsVisible]);

  const getFeatureIcon = (): JSX.Element => {
    if (isPermissionsVisible) {
      return <ChevronDown size={24} color={theme.colors.gray[1]} />;
    }

    return <ChevronRight size={24} color={theme.colors.gray[1]} />;
  };

  const onCheckboxChange = React.useCallback(
    event => {
      if (!event || event.target) return;

      if (event.checked) {
        handleSelectedPermissions([...selectedPermissions, event.value]);
      } else {
        const filteredPermissions = selectedPermissions.filter(
          permission => permission !== event.value,
        );

        handleSelectedPermissions(filteredPermissions);
      }
    },
    [handleSelectedPermissions, selectedPermissions],
  );

  return (
    <S.FeatureAndPermissionsWrapper>
      <S.Feature type="button" onClick={handlePermissionsVisible} isVisible={isPermissionsVisible}>
        <S.FeatureNameAndIconWrapper>
          <S.FeatureName>{feature.name}</S.FeatureName>
          {getFeatureIcon()}
        </S.FeatureNameAndIconWrapper>

        {isPermissionsVisible && <S.HorizontalRule />}
      </S.Feature>

      <S.PermissionsWrapper isVisible={isPermissionsVisible}>
        {feature.permissions.map(permission => {
          const isPermissionChecked = selectedPermissions.includes(permission.value);

          return (
            <S.CheckboxAndPermissionNameWrapper key={permission.name}>
              <S.PermissionName>{permission.name}</S.PermissionName>
              <S.Checkbox
                value={permission.value}
                onChange={onCheckboxChange}
                defaultChecked={isPermissionChecked}
              />
            </S.CheckboxAndPermissionNameWrapper>
          );
        })}
      </S.PermissionsWrapper>
    </S.FeatureAndPermissionsWrapper>
  );
};

export default Feature;
