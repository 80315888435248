import React from 'react';

import { FREE_LIMIT_TEMPLATES } from '@constants/pages/dashboard/automations/templates/templates/freeLimit';
import { NO_LIMIT_TEMPLATES } from '@constants/pages/dashboard/automations/templates/templates/noLimit';
import { STARTER_LIMIT_TEMPLATES } from '@constants/pages/dashboard/automations/templates/templates/starterLimit';

import { ESelectedOption, ETag } from '@domain/enums/dashboard/automations/ETemplate';
import { EStatus } from '@domain/enums/subscription/ESubscription';

import { useTemplate } from '@helpers/hooks/pages/dashboard/automations/useTemplate';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';

import Header from './Header';
import Template from './Template';
import TemplateOptions from './TemplateOptions';
import TemplateSummaryModal from './TemplateSummaryModal';

import * as S from './styles';

const Templates: React.FC = () => {
  const {
    searchValue,
    selectedOption,
    handleTemplateSummaryModalOpen,
    isTemplateSummaryModalOpen,
  } = useTemplate();
  // const { handleStatus, tutorialStatus } = useStepModal();
  const { subscription } = useStoreSubscription();

  // const [showTutorial, setShowTutorial] = React.useState<boolean>(false);

  const getTemplates = React.useCallback(() => {
    if (subscription?.plan?.identifier.includes('free')) return FREE_LIMIT_TEMPLATES;

    if (subscription?.plan?.identifier.includes('starter')) return STARTER_LIMIT_TEMPLATES;

    return NO_LIMIT_TEMPLATES;
  }, [subscription]);

  const filteredAutomationsTemplatesBySubscription = getTemplates().filter(template => {
    if (template.alias === 'no-content') return true;

    if (subscription.status === EStatus.TRIAL_ENDED || subscription.status === EStatus.CANCELED)
      return false;

    return true;
  }, []);

  const filteredAutomationsTemplatesBySearchOptions = filteredAutomationsTemplatesBySubscription.filter(
    template => {
      if (!selectedOption || selectedOption === ESelectedOption.ALL_TEMPLATES) return true;

      if (selectedOption === ESelectedOption.ECOMMERCE) {
        return template.tags.includes(ETag.ECOMMERCE);
      }

      if (selectedOption === ESelectedOption.INFOPRODUCT) {
        return template.tags.includes(ETag.INFOPRODUCT);
      }

      return false;
    },
  );

  const filteredAutomationsTemplatesBySearchInput = filteredAutomationsTemplatesBySearchOptions.filter(
    template => {
      const templateName = template.name.toLowerCase();

      const templateTags = template.tags;

      const loweredSearchValue = searchValue.toLowerCase();

      const hasSearchTag = templateTags.find(tag => {
        const tagName = tag.toLowerCase();

        if (tagName.includes(loweredSearchValue)) return true;

        return false;
      });

      if (templateName.includes(loweredSearchValue) || hasSearchTag) return true;

      return false;
    },
  );

  // const handleCloseTutorial = React.useCallback(() => {
  //   handleStatus({ automations_template_tutorial: false });
  //   setShowTutorial(false);
  // }, [handleStatus, setShowTutorial]);

  // React.useEffect(() => {
  //   if (tutorialStatus?.automations_template_tutorial === undefined) {
  //     setShowTutorial(true);
  //     handleStatus({ automations_template_tutorial: true });
  //   }

  //   if (tutorialStatus?.automations_template_tutorial) {
  //     setShowTutorial(true);
  //   }
  // }, [tutorialStatus, handleStatus]);

  return (
    <S.Container>
      <TemplateOptions />

      <S.ContentWrapper>
        <Header />

        <S.TemplatesWrapper>
          {filteredAutomationsTemplatesBySearchInput.map(template => {
            return <Template key={template.alias} template={template} />;
          })}
        </S.TemplatesWrapper>

        <TemplateSummaryModal
          isOpen={isTemplateSummaryModalOpen}
          toggle={handleTemplateSummaryModalOpen}
        />
      </S.ContentWrapper>

      {/* <StepsModal
        isOpen={showTutorial}
        tutorial={tutorial}
        page="Automations Templates"
        side="right"
        toggle={handleCloseTutorial}
      /> */}
    </S.Container>
  );
};

export default Templates;
