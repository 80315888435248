import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useVariant } from '@helpers/hooks/pages/dashboard/kit/useVariant';
import VariantsSkeletonLoading from '@components/Dashboard/Kit/VariantsSkeletonLoading';
import NoVariants from '@components/Dashboard/Kit/NoVariants';
import Variant from './Variant';

import * as S from './styles';

const VariantsDropdown: React.FC = () => {
  const {
    variants,
    loadVariants,
    hasMore,
    handleLoadMore,
    inputComponentRef,
    isLoadingVariants,
  } = useVariant();

  const [parentWidth, setParentWidth] = React.useState<number>(
    inputComponentRef?.current?.offsetWidth || 0,
  );

  React.useEffect(() => {
    loadVariants();
  }, [loadVariants]);

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      if (inputComponentRef.current) {
        setParentWidth(inputComponentRef.current.offsetWidth);
      }
    });
  }, [inputComponentRef]);

  return (
    <S.Wrapper width={parentWidth} id="variants-list">
      <InfiniteScroll
        dataLength={variants.length}
        next={handleLoadMore}
        hasMore={hasMore}
        loader={<VariantsSkeletonLoading />}
        scrollableTarget="variants-list"
        scrollThreshold={0.95}
      >
        {!variants.length && isLoadingVariants ? <VariantsSkeletonLoading /> : <></>}

        {!variants.length && !isLoadingVariants ? <NoVariants /> : <></>}

        {variants.map(variant => (
          <Variant key={variant.alias_id} variant={variant} />
        ))}
      </InfiniteScroll>
    </S.Wrapper>
  );
};

export default VariantsDropdown;
