import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Heading = styled(HeadingBase)`
  color: #ffffff;
  margin-bottom: 22px;
`;

export const CreateNewGoalButton = styled(ButtonBase)`
  white-space: nowrap;
`;

export const CreateNewGoalText = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  max-width: 342px;
`;

export const NoRetroactiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  max-width: 342px;
`;
