export enum EGroup {
  MARKETING_EXPENSES = 'MARKETING_EXPENSES',
  GENERAL_EXPENSES = 'GENERAL_EXPENSES',
  ADMINISTRATIVE_EXPENSES = 'ADMINISTRATIVE_EXPENSES',
  SHIPPING_EXPENSES = 'SHIPPING_EXPENSES',
  TAX = 'TAX',
  COMMISSIONS = 'COMMISSIONS',
  FINANCIAL_INCOMES = 'FINANCIAL_INCOMES',
  FINANCIAL_EXPENSES = 'FINANCIAL_EXPENSES',
  OTHER = 'OTHER',
  NONE = 'none',
}
