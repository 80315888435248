import { SOCIAL_MEDIA } from '@constants/pages/pricing/socialMedia';
import { ECreateStoreStep, EEcommerceOptions } from '@domain/enums/onboarding/ECreateStore';
import { InstagramLogo } from 'phosphor-react';
import React from 'react';
import * as S from './styles';

interface IInfoStepProps {
  selectedAnswer: EEcommerceOptions;
  isOpen: boolean;
  toggle: () => void;
  handleStep: (backStep: ECreateStoreStep) => void;
}

const InfoStep: React.FC<IInfoStepProps> = ({ selectedAnswer, isOpen, toggle, handleStep }) => {
  const { instagram } = SOCIAL_MEDIA;

  const handleNext = React.useCallback(() => {
    handleStep(ECreateStoreStep.STORE_NAME_STEP);
    toggle();
  }, [handleStep, toggle]);

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      {selectedAnswer === EEcommerceOptions.VIRTUAL_STORE_AND_MARKETPLACES ? (
        <S.Body>
          <S.Question>Suporte parcial</S.Question>

          <S.Description>
            Você poderá usar normalmente a Profitfy para sua loja virtual. Para o marketplace,
            estamos criando ferramentas para receber esse tipo de operação.
          </S.Description>

          <S.SubmitButton onClick={handleNext}>Continuar</S.SubmitButton>
        </S.Body>
      ) : (
        <S.Body>
          <S.Question>Sem suporte para marketplaces</S.Question>

          <S.Description>
            Infelizmente a Profitfy ainda não atende a operação para marketplaces, estamos
            trablhando duro para atender.
          </S.Description>
          <S.Description>
            Não se preocupe, assim que tivermos atualizações, te manteremos atualizado por e-mail,
            whatsapp e outros canais.
          </S.Description>
          <S.Description>Não esqueça de seguir nosso instagram.</S.Description>

          <S.SocialMediaWrapper>
            <InstagramLogo size={20} />
            <S.SocialMediaLink href={instagram.url} target="_blank">
              <S.LinkText>@profitfy.me</S.LinkText>
            </S.SocialMediaLink>
          </S.SocialMediaWrapper>
        </S.Body>
      )}
    </S.Modal>
  );
};

export default InfoStep;
