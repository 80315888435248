import styled, { css } from 'styled-components/macro';

import {
  IProgressBarProps,
  IStepNameProps,
  IProgressCircleProps,
} from '@domain/interfaces/onboarding/components/progressBar/IProgressBar';
import { EProgressBarStep } from '@domain/enums/onboarding/progressBar/ESteps';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 42px;
  margin-top: 46px;
`;

export const Heading = styled(HeadingBase)`
  & > span {
    /* font-family: 'Poppins'; */
    font-family: 'Saira';
    font-weight: 500;
    font-size: 24px;
    color: ${({ theme }) => theme.colors.green.default};
  }
`;

export const ProgressAndNameStepsWrapper = styled.div`
  width: 100%;
  height: 179px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 16px;
`;

export const StepsNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  gap: 24px;
`;

export const StepName = styled(TextBase)<IStepNameProps>`
  ${({ isComplete, theme }) =>
    isComplete &&
    css`
      color: ${theme.colors.green.default};
    `}
`;

export const ProgressWrapper = styled.div`
  position: relative;
  height: 165px;
  min-width: 28px;
  margin-top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProgressBackground = styled.div`
  width: 2px;
  height: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.gray[2]};
`;

export const ProgressBar = styled.div<IProgressBarProps>`
  width: 100%;
  height: 30%;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.green.default};

  ${({ step }) =>
    step === EProgressBarStep.GENERAL_CONFIG &&
    css`
      height: 65%;
    `}

  ${({ step }) =>
    step === EProgressBarStep.FINISH_STEP &&
    css`
      height: 100%;
    `}
`;

export const ProgressCircleAndIconWrapper = styled.div``;

export const CreateStoreProgressCircle = styled.div<IProgressCircleProps>`
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, isComplete }) =>
    isComplete ? theme.colors.green.default : theme.colors.gray[6]};
  border: 2px solid ${({ theme }) => theme.colors.green.default};
  height: 24px;
  width: 24px;
  border-radius: 50%;
`;

export const IntegrationsProgressCircle = styled.div<IProgressCircleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, isComplete }) =>
    isComplete ? theme.colors.green.default : theme.colors.gray[6]};
  height: 24px;
  width: 24px;
  border-radius: 50%;
  position: absolute;
  top: 50px;
  transform: translateY(-50%);
  border: 2px solid ${({ theme }) => theme.colors.gray[2]};

  ${({ step }) =>
    step !== EProgressBarStep.CREATE_STORE &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.green.default};
    `}
`;

export const GeneralConfigProgressCircle = styled.div<IProgressCircleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, isComplete }) =>
    isComplete ? theme.colors.green.default : theme.colors.gray[6]};
  height: 24px;
  width: 24px;
  border-radius: 50%;
  position: absolute;
  transform: translateY(-50%);
  top: 100px;
  border: 2px solid ${({ theme }) => theme.colors.gray[2]};

  ${({ step }) =>
    (step === EProgressBarStep.GENERAL_CONFIG || step === EProgressBarStep.FINISH_STEP) &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.green.default};
    `}
`;

export const FinishStepProgressCircle = styled.div<IProgressCircleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, isComplete }) =>
    isComplete ? theme.colors.green.default : theme.colors.gray[6]};
  border: 2px solid ${({ theme }) => theme.colors.green.default};
  height: 24px;
  width: 24px;
  border-radius: 50%;
  position: absolute;
  transform: translateY(-50%);
  top: 151px;
  border: 2px solid ${({ theme }) => theme.colors.gray[2]};

  ${({ step }) =>
    step === EProgressBarStep.FINISH_STEP &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.green.default};
    `}
`;

export const Icon = styled.svg`
  fill: none;
  stroke: ${({ theme }) => theme.colors.gray[6]};
  stroke-width: 3;
  position: absolute;
  top: 0;
  left: 0;
`;
