import queryString from 'query-string';

import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DASHBOARD_DOMAIN } from '@constants/api';
import {
  IGetFinancialCustomValuesProps,
  IGetFinancialCustomValuesResponse,
  IGetFinancialDashboardNetProfitChartProps,
  IGetFinancialDashboardNetProfitChartResponse,
  IGetFinancialDashboardProps,
  IGetFinancialDashboardResponse,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';
import { AxiosResponse } from 'axios';

export class FinancialDashboardService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getFinancialDashboard({
    storeAliasId,
    startDate,
    endDate,
    filter,
  }: IGetFinancialDashboardProps): IGetFinancialDashboardResponse {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/dashboards/financial?start_date=${startDate}&end_date=${endDate}&${queryStringFilter}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      financialDetailsReport: data?.financial_details_report,
      financialReport: data?.financial_report,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public getFinancialDashboardPromise({
    storeAliasId,
    startDate,
    endDate,
    filter,
  }: IGetFinancialDashboardProps): Promise<AxiosResponse<IGetFinancialDashboardPromiseResponse>> {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/dashboards/financial?start_date=${startDate}&end_date=${endDate}&${queryStringFilter}`,
    );
  }

  public getFinancialCustomValues({
    storeAliasId,
    startDate,
    endDate,
  }: IGetFinancialCustomValuesProps): IGetFinancialCustomValuesResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/dashboards/financial/custom-values?start_date=${startDate}&end_date=${endDate}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      customValues: data?.custom_values,
      totalAmount: data?.total_amount,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public getFinancialDashboardNetProfitChart({
    storeAliasId,
    startDate,
    endDate,
    filter,
  }: IGetFinancialDashboardNetProfitChartProps): IGetFinancialDashboardNetProfitChartResponse {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/financial-charts/net-profit?start_date=${startDate}&end_date=${endDate}&${queryStringFilter}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      netProfitChart: data?.net_profit_chart,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public getFinancialDashboardNetProfitChartPromise({
    storeAliasId,
    startDate,
    endDate,
    filter,
  }: IGetFinancialDashboardNetProfitChartProps): Promise<any> {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/financial-charts/net-profit?start_date=${startDate}&end_date=${endDate}&${queryStringFilter}`,
    );
  }
}

const financialDashboardService = new FinancialDashboardService(API_DASHBOARD_DOMAIN);

export default financialDashboardService;
