import React from 'react';
import { useParams } from 'react-router-dom';

import { EUserStoreRole } from '@domain/enums/common/userStore/EUserStoreRole';
import { IParams } from '@domain/interfaces/IParams';

import { useConfig } from '@helpers/hooks/useConfig';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useStorePermission } from '@helpers/hooks/common/useStorePermission';

import { ProductDetailsProvider } from '@helpers/hooks/pages/dashboard/productAnalytics/useProductDetails';

import Wrapper from '@components/Dashboard/ProductAnalytics/ProductDetails/Wrapper';
import NoAccess from '@components/common/core/Utils/NoAccess';
import LimitationPage from '@components/common/core/Utils/LimitationPage';

const ProductDetails: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const { accessManagementData } = useStoreConfig();
  const { analytics, user, isInternalTeam } = useConfig();
  const { hasProductAnalyticsFeature } = useStorePermission();

  const isStoreOwner = accessManagementData?.role === EUserStoreRole.OWNER;
  const hasAccessToPage =
    accessManagementData?.group?.has_financial_product_dashboard_full_write_access ||
    accessManagementData?.group?.has_financial_product_dashboard_full_read_access;

  React.useEffect(() => {
    analytics?.track(
      'Product Analytic Details Page Viewed',
      {
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );
    analytics?.trackHubSpot(storeAliasId, { product_analysis_viewed: true });
  }, [analytics, storeAliasId, user]);

  if (!isStoreOwner && !hasAccessToPage && !isInternalTeam) {
    return <NoAccess />;
  }

  if (!hasProductAnalyticsFeature) {
    return (
      <LimitationPage
        imageSrc="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/temp/limitation-temp.svg"
        title="Análise de produtos não disponível"
        description="Escolha um plano que te permita exportar planilhas para que consiga usufruir o melhor da ferramenta."
        trackingPropertyName="NO_PRODUCT_ANALYTICS_ACCESS"
      />
    );
  }

  return (
    <ProductDetailsProvider>
      <Wrapper />
    </ProductDetailsProvider>
  );
};

export default ProductDetails;
