import { EBadgeColors } from '@domain/enums/components/EBadge';

export const getBadgeColorByValue = (amount: number): EBadgeColors => {
  if (amount > 0) {
    return EBadgeColors.SUCCESS;
  }
  if (amount < 0) {
    return EBadgeColors.RED;
  }

  return EBadgeColors.GRAY;
};
