import queryString from 'query-string';

import { format } from 'date-fns';
import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';
import {
  IListOrdersWithLineItemProps,
  IListOrdersWithLineItemPromiseProps,
  IListOrdersWithLineItemResponse,
  IUpdateOrder,
  ICreateManualOrder,
} from '@domain/interfaces/common/orders/IOrders';

export class OrdersService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public updateOrder({ orderAliasId, storeAliasId, order }: IUpdateOrder): Promise<any> {
    return this.apiService.put(`/api/v1/users/stores/${storeAliasId}/orders/${orderAliasId}`, {
      ...order,
    });
  }

  public listOrdersWithLineItemPromise({
    page,
    startDate,
    endDate,
    storeAliasId,
  }: IListOrdersWithLineItemPromiseProps): Promise<any> {
    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/orders-line-item?limit=10&page=${
        page + 1
      }&start_date=${startDate}&end_date=${endDate}&filter&sort`,
    );
  }

  public listOrdersWithLineItem({
    page,
    startDate,
    endDate,
    storeAliasId,
    filter,
    sort,
  }: IListOrdersWithLineItemProps): IListOrdersWithLineItemResponse {
    const stringfiedFilter = JSON.stringify(filter);
    const stringfiedSort = JSON.stringify(sort);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });
    const queryStringSort = queryString.stringify({ sort: stringfiedSort });

    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/orders-line-item?limit=20&page=${
        page + 1
      }&start_date=${format(startDate, 'yyyy-MM-dd')}&end_date=${format(
        endDate,
        'yyyy-MM-dd',
      )}&${queryStringFilter}&${queryStringSort}`,
      this.apiService,
    );

    const isLoading = !error && !data;

    return {
      orders: data?.orders,
      metric: data?.metric,
      mutate,
      error,
      isLoading,
      isValidating,
      totalPages: data?.total_pages,
    };
  }

  public createManualOrder({ data, storeAliasId }: ICreateManualOrder): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/orders`, { ...data });
  }
}

const ordersService = new OrdersService(API_DOMAIN);

export default ordersService;
