import styled, { css } from 'styled-components/macro';

import SwitchBase from '@components/common/core/Inputs/Switch';

export const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Switch = styled(SwitchBase)`
  display: block;

  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
  padding: 10px 12px;
  box-sizing: border-box;
`;
