import styled, { css } from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';
import TextBase from '@components/common/core/DataDisplay/Text';
import { ITableProps } from '@domain/interfaces/dashboard/FinancialDetails/IDailyTable';

export const ColumnName = styled(TextBase)``;

export const TooltipArrow = styled(CustomTooltipBase.Arrow)`
  fill: ${({ theme }) => theme.colors.black.light};
`;

export const TooltipContent = styled(CustomTooltipBase.Content)`
  background-color: ${({ theme }) => theme.colors.black.light};
`;

export const TooltipTrigger = styled(CustomTooltipBase.Trigger)`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: 500;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.font};
  margin-left: auto;
  display: block;
`;

export const Tooltip = styled(CustomTooltipBase)``;

export const Table = styled(TableBase)<ITableProps>`
  & > table > thead > tr {
    & > th {
      padding: 8px 10px !important;
    }

    & > th:first-child {
      padding-left: 28px !important;
      position: sticky;
      position: -webkit-sticky;
      left: 0;
      background-color: ${({ theme }) => theme.colors.gray[4]};
    }

    & > th:last-child {
      padding-right: 28px !important;
    }
  }

  & > table > tbody > tr {
    & > td {
      padding: 8px 10px !important;
    }

    & > td:first-child {
      padding-left: 28px !important;
      position: sticky;
      position: -webkit-sticky;
      left: 0;
      background-color: ${({ theme }) => theme.colors.gray[6]};
      transition: all 0.1s;

      ${({ hasScrolled }) =>
        hasScrolled &&
        css`
          -webkit-box-shadow: inset -1px 0px 0px 0px ${({ theme }) => theme.colors.gray[4]};
          -moz-box-shadow: inset -1px 0px 0px 0px ${({ theme }) => theme.colors.gray[4]};
          box-shadow: inset -1px 0px 0px 0px ${({ theme }) => theme.colors.gray[4]};
        `}
    }

    & > td:last-child {
      padding-right: 28px !important;
    }

    &:hover {
      & > td:first-child {
        background-color: ${({ theme }) => theme.colors.gray[5]};
      }
    }
  }
`;
