import React from 'react';

import { IRadioProps } from '@domain/interfaces/components/IRadio';

import * as S from './styles';

const Radio: React.ForwardRefRenderFunction<HTMLInputElement, IRadioProps> = (
  { name, defaultChecked, checked, onChange, value, id, disabled = false, ...rest },
  ref,
) => {
  return (
    <S.Container {...rest}>
      <S.Input
        name={name}
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={event => onChange(event)}
        ref={ref}
        value={value}
        type="radio"
        id={id}
        disabled={disabled}
      />
      <S.Checkmark />
    </S.Container>
  );
};

export default React.forwardRef(Radio);
