import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const NewLegalPersonButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.green.default};
  font-family: ${({ theme }) => theme.fonts.body};
  padding: 0;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.2);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Title = styled(HeadingBase)``;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const TitleAndNewLealPersonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`;

export const FinancialDataWrapper = styled.div`
  margin-bottom: 40px;
`;
