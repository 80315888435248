import styled from 'styled-components/macro';

import { ITooltipTriggerProps } from '@domain/interfaces/integrations/marketing/facebook/ITable';

import CustomTooltip from '@components/common/core/Utils/CustomTooltip';

export const Tooltip = styled(CustomTooltip)``;

export const TooltipTrigger = styled(CustomTooltip.Trigger)<ITooltipTriggerProps>`
  width: 100%;
  max-width: ${({ textMaxWidth }) => textMaxWidth}px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.gray[1]};

  &:hover {
    cursor: pointer;
  }
`;

export const TooltipContent = styled(CustomTooltip.Content)`
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray[1]};
`;
