import React from 'react';
import { Task } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import { useAccessManager } from '@helpers/hooks/pages/dashboard/accessManagement/useAccessManager';
import { useToast } from '@helpers/hooks/useToast';

import { formSchema } from '@helpers/validators/dashboard/accessManagement/newGroup';

import { DEFAULT_FEATURES } from '@constants/accessManagement';

import groupService from '@services/pages/dashboard/accessManagement/group';

import Text from '@components/common/core/DataDisplay/Text';
import SideModalSave from '@components/common/core/Utils/SideModalSave';
import Feature from './Feature';

import * as S from './styles';

const EditGroupSideModal: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const {
    selectedPermissions,
    selectedGroupToEdit,
    handleSelectedGroupToEdit,
    handleSelectedPermissions,
    mutateGroups,
    mutateUsersStore,
    mutateUserInvites,
  } = useAccessManager();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  });

  const [groupNameValue, setGroupNameValue] = React.useState<string>('');
  const [isUpdatingGroup, setIsUpdatingGroup] = React.useState<boolean>(false);

  const groupNameInputRegister = register('group_name', { value: selectedGroupToEdit?.name });

  const toggle = React.useCallback(() => {
    handleSelectedGroupToEdit(undefined);
  }, [handleSelectedGroupToEdit]);

  const onGroupNameChange = React.useCallback(
    event => {
      groupNameInputRegister.onChange(event);

      setGroupNameValue(event.target.value);
    },
    [groupNameInputRegister],
  );

  const getReadPermissions = React.useCallback((permissions: Record<string, any>): Record<
    string,
    any
  > => {
    const permissionsCopy = { ...permissions };

    const permissionsCopyArrayWithName = Object.keys(permissionsCopy);
    const permissionsCopyArray = permissionsCopyArrayWithName.filter(
      permission => !permission.includes('name'),
    );

    const newPermissions = {};

    // eslint-disable-next-line dot-notation
    newPermissions['name'] = permissions.name;

    const writePermissionArray = permissionsCopyArray.filter(permission =>
      permission.includes('write'),
    );

    writePermissionArray.forEach(permission => {
      newPermissions[permission] = permissions[permission];
    });

    writePermissionArray.forEach(permission => {
      const readPermission = permission.replace('write', 'read');

      newPermissions[readPermission] = permissions[permission];
    });

    return newPermissions;
  }, []);

  const onSubmit = React.useCallback(
    async formData => {
      setIsUpdatingGroup(true);

      const data = {
        name: formData.group_name,
      };
      selectedPermissions.forEach(selectedPermission => {
        data[`${selectedPermission}`] = true;
      });

      const permissions: Array<string> = [];

      DEFAULT_FEATURES.forEach(defaultFeature => {
        defaultFeature.permissions.forEach(permission => {
          const foundSelectedPermission = selectedPermissions.find(
            selectedPermission => selectedPermission === permission.value,
          );

          if (!foundSelectedPermission) permissions.push(permission.value);
        });
      });
      permissions.forEach(permission => {
        data[`${permission}`] = false;
      });

      const newPermissions = getReadPermissions(data);

      try {
        const groupData = {
          groups: [
            {
              group_id: selectedGroupToEdit?.id || '',
              data: {
                ...newPermissions,
                name: data.name,
                // has_workflow_full_write_access: false,
                // has_workflow_full_read_access: false,
              },
            },
          ],
        };

        await groupService.updateGroupsPromise({ storeAliasId, data: groupData });

        toast.success('Grupo atualizado com sucesso!');

        await Promise.all([mutateGroups(), mutateUserInvites(), mutateUsersStore()]);

        handleSelectedPermissions([]);
        setIsUpdatingGroup(false);
        handleSelectedGroupToEdit(undefined);
      } catch (error: any) {
        setIsUpdatingGroup(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [
      handleSelectedGroupToEdit,
      handleSelectedPermissions,
      selectedGroupToEdit,
      selectedPermissions,
      storeAliasId,
      toast,
      mutateGroups,
      mutateUserInvites,
      mutateUsersStore,
      getReadPermissions,
    ],
  );

  React.useEffect(() => {
    if (selectedGroupToEdit) {
      setGroupNameValue(selectedGroupToEdit.name);
    }
  }, [selectedGroupToEdit]);

  const updateGroupButtonDisabled = Boolean(!selectedPermissions.length);

  return (
    <S.SideModal isOpen={Boolean(selectedGroupToEdit)} toggle={toggle}>
      <S.HeadingWrapper>
        <Task size={24} outline />
        <S.Heading>Alterar permissões de grupo</S.Heading>
      </S.HeadingWrapper>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label>Nome do grupo</S.Label>
          <S.Input
            {...groupNameInputRegister}
            type="text"
            value={groupNameValue}
            placeholder="Digite o nome do grupo"
            onChange={onGroupNameChange}
            isError={errors.group_name}
          />
          {errors.group_name && <Text isErrorFeedback>{errors.group_name.message}</Text>}
        </S.InputGroup>

        <S.FeaturesWrapper>
          <S.FeaturesTitle>Defina as permissões para este grupo</S.FeaturesTitle>

          {DEFAULT_FEATURES.map((feature, index) => {
            return (
              <Feature key={feature.name} defaultOpen={Boolean(index === 0)} feature={feature} />
            );
          })}
        </S.FeaturesWrapper>

        <SideModalSave
          type="submit"
          successButtonText="Salvar alterações"
          cancelButtonText="Fechar"
          cancelButtonTextColor="#fff"
          isDisabled={updateGroupButtonDisabled || isUpdatingGroup}
          isLoading={isUpdatingGroup}
          onCancel={toggle}
        />
      </S.Form>
    </S.SideModal>
  );
};

export default EditGroupSideModal;
