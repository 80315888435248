import { v4 } from 'uuid';

import { IWorkflowToCanvas } from '@domain/interfaces/common/automations/IWorkflows';
import { IWorkflowEdge as IWorkflowEdgeBackend } from '@domain/interfaces/mappers/automations/profitfyBackend/IWorkflowEdge';
import { IWorkflowNode as IWorkflowNodeBackend } from '@domain/interfaces/mappers/automations/profitfyBackend/IWorkflowNode';
import { ENodeType } from '@domain/enums/mappers/automations/profitfy/ENodeType';

interface IHideInternalNodesResponse {
  edges: IWorkflowEdgeBackend[];
  nodes: IWorkflowNodeBackend[];
}

export const hideInternalOrderStatus = ({
  workflow_nodes,
  workflow_edges,
}: IWorkflowToCanvas): IHideInternalNodesResponse => {
  const newEdges = [];
  const newNodes = [];

  const edgesToRemove: IWorkflowEdgeBackend[] = [];

  for (const workflow_node of workflow_nodes) {
    if (workflow_node.type !== ENodeType.INTERNAL_ORDER_STATUS) {
      newNodes.push(workflow_node);
      continue;
    }

    const foundTargetEdge = workflow_edges.find(edge => edge.target === workflow_node.provider_id);

    const foundSourceEdge = workflow_edges.find(edge => edge.source === workflow_node.provider_id);

    if (!foundTargetEdge || !foundSourceEdge) continue;

    edgesToRemove.push(foundTargetEdge, foundSourceEdge);

    const newEdge: IWorkflowEdgeBackend = {
      provider_id: `createdEdge${v4()}`,
      source: foundTargetEdge?.source,
      target: foundSourceEdge?.target,
      source_handle: foundTargetEdge?.source_handle || undefined,
      target_handle: foundSourceEdge?.target_handle || undefined,
      alias_id: 'any_alias_id',
      created_at: new Date(),
      updated_at: new Date(),
      id: 'any_id',
      workflow_id: foundTargetEdge.workflow_id,
    };

    newEdges.push(newEdge);
  }

  const filteredEdges = workflow_edges.filter(
    workflow_edge => !edgesToRemove.some(edge => edge.provider_id === workflow_edge.provider_id),
  );

  filteredEdges.push(...newEdges);

  return {
    edges: filteredEdges,
    nodes: newNodes,
  };
};

export const hideInternalFinishNode = ({
  workflow_nodes,
  workflow_edges,
}: IWorkflowToCanvas): IHideInternalNodesResponse => {
  const newEdges = [];
  const newNodes = [];

  const edgesToRemove: IWorkflowEdgeBackend[] = [];

  for (const workflow_node of workflow_nodes) {
    if (workflow_node.type !== ENodeType.INTERNAL_WORKFLOW_STATUS) {
      newNodes.push(workflow_node);
      continue;
    }

    const foundTargetEdge = workflow_edges.find(edge => edge.target === workflow_node.provider_id);

    const foundSourceEdge = workflow_edges.find(edge => edge.source === workflow_node.provider_id);

    if (!foundTargetEdge && !foundSourceEdge) continue;

    if (foundTargetEdge) edgesToRemove.push(foundTargetEdge);
    if (foundSourceEdge) edgesToRemove.push(foundSourceEdge);

    if (foundSourceEdge) {
      const newEdge: IWorkflowEdgeBackend = {
        provider_id: `createdEdge${v4()}`,
        source: foundTargetEdge?.source as string,
        target: foundSourceEdge?.target,
        source_handle: foundTargetEdge?.source_handle,
        target_handle: foundSourceEdge?.target_handle,
        alias_id: 'any_alias_id',
        created_at: new Date(),
        updated_at: new Date(),
        id: 'any_id',
        workflow_id: foundTargetEdge?.workflow_id as string,
      };

      newEdges.push(newEdge);
    }
  }

  const filteredEdges = workflow_edges.filter(
    workflow_edge => !edgesToRemove.some(edge => edge.provider_id === workflow_edge.provider_id),
  );

  filteredEdges.push(...newEdges);

  return {
    edges: filteredEdges,
    nodes: newNodes,
  };
};

export const hideInternalSellRecoveredStatusNode = ({
  workflow_nodes,
  workflow_edges,
}: IWorkflowToCanvas): IHideInternalNodesResponse => {
  const newEdges = [];
  const newNodes = [];

  const edgesToRemove: IWorkflowEdgeBackend[] = [];

  for (const workflow_node of workflow_nodes) {
    if (workflow_node.type !== ENodeType.INTERNAL_SELL_RECOVERED_STATUS) {
      newNodes.push(workflow_node);
      continue;
    }

    const foundTargetEdge = workflow_edges.find(edge => edge.target === workflow_node.provider_id);

    const foundSourceEdge = workflow_edges.find(edge => edge.source === workflow_node.provider_id);

    if (!foundTargetEdge || !foundSourceEdge) continue;

    edgesToRemove.push(foundTargetEdge, foundSourceEdge);

    const newEdge: IWorkflowEdgeBackend = {
      provider_id: `createdEdge${v4()}`,
      source: foundTargetEdge?.source,
      target: foundSourceEdge?.target,
      source_handle: foundTargetEdge.target_handle,
      target_handle: foundSourceEdge.target_handle,
      alias_id: 'any_alias_id',
      created_at: new Date(),
      updated_at: new Date(),
      id: 'any_id',
      workflow_id: foundTargetEdge.workflow_id,
    };

    newEdges.push(newEdge);
  }

  const filteredEdges = workflow_edges.filter(
    workflow_edge => !edgesToRemove.some(edge => edge.provider_id === workflow_edge.provider_id),
  );

  filteredEdges.push(...newEdges);

  return {
    edges: filteredEdges,
    nodes: newNodes,
  };
};
