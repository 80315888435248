import React from 'react';
import { useParams } from 'react-router-dom';

import { EScriptAndUtmsStep } from '@domain/enums/dashboard/adsManager/EScriptAndUtms';
import { IParams } from '@domain/interfaces/IParams';

import { useMediaQuery } from '@helpers/hooks/useMediaQuery';
import { useScriptAndUtms } from '@helpers/hooks/pages/dashboard/adsManager/onboarding/useScriptAndUtms';
import { useConfig } from '@helpers/hooks/useConfig';

import Header from '@components/Dashboard/AdsManager/UtmOnboarding/ScriptAndUtms/PlatformFlowSteps/Core/Header';
import WhatItIsFor from '@components/Dashboard/AdsManager/UtmOnboarding/ScriptAndUtms/PlatformFlowSteps/Core/WhatItIsFor';
import AddUtms from '@components/Dashboard/AdsManager/UtmOnboarding/ScriptAndUtms/PlatformFlowSteps/Core/AddUtms';
import CampaignsSideModal from '@components/Dashboard/AdsManager/UtmOnboarding/ScriptAndUtms/PlatformFlowSteps/Core/AddUtms/AddUtmsInExistingCampaigns/CampaignsSideModal';
import ScheduleSync from '@components/Dashboard/AdsManager/UtmOnboarding/ScriptAndUtms/PlatformFlowSteps/Core/ScheduleSync';
import Code from '@components/Dashboard/AdsManager/UtmOnboarding/ScriptAndUtms/PlatformFlowSteps/Cartpanda/Code';
import HowToAddTheScript from '@components/Dashboard/AdsManager/UtmOnboarding/ScriptAndUtms/PlatformFlowSteps/Cartpanda/HowToAddTheScript';

import * as S from './styles';

const Cartpanda: React.FC = () => {
  const mobileWidth = useMediaQuery('1348px');
  const { userStep } = useScriptAndUtms();
  const { analytics } = useConfig();
  const { storeAliasId } = useParams<IParams>();

  React.useEffect(() => {
    analytics?.track(
      'Ads Manager UTM Onboarding Viewed',
      {},
      { context: { groupId: storeAliasId } },
    );
  }, [analytics, storeAliasId]);

  const isScriptStep = userStep === EScriptAndUtmsStep.SCRIPT;

  return (
    <S.Wrapper>
      <S.OnboardingStepsWrapper isScriptStep={isScriptStep}>
        <Header />
        <WhatItIsFor />
        <Code />
        {mobileWidth && isScriptStep && <HowToAddTheScript />}
        <AddUtms />
        <ScheduleSync />
      </S.OnboardingStepsWrapper>

      <CampaignsSideModal />

      {!mobileWidth && isScriptStep && <HowToAddTheScript />}
    </S.Wrapper>
  );
};

export default Cartpanda;
