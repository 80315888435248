import { AxiosResponse } from 'axios';

import {
  ICreatePaymentMethodProps,
  ICreatePaymentMethodResponse,
  IDeletePaymentMethodProps,
  IGetPaymentMethodPromiseResponse,
  IGetPaymentMethodProps,
  IGetPaymentMethodResponse,
} from '@domain/interfaces/common/paymentMethod/IPaymentMethod';

import { useFetch } from '@providers/Fetch/useFetch';

import { ApiService } from '@services/api';

import { USER_API_DOMAIN } from '@constants/api';

export class PaymentMethodService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getPaymentMethod({ storeAliasId }: IGetPaymentMethodProps): IGetPaymentMethodResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/payment-methods`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      paymentMethod: data?.payment_method,
      error,
      isLoading,
      isValidating,
      mutate,
    };
  }

  public getPaymentMethodPromise({
    storeAliasId,
  }: IGetPaymentMethodProps): Promise<AxiosResponse<IGetPaymentMethodPromiseResponse>> {
    return this.apiService.get<IGetPaymentMethodPromiseResponse>(
      `/api/v1/users/stores/${storeAliasId}/payment-methods`,
    );
  }

  public createPaymentMethod({
    storeAliasId,
    data,
  }: ICreatePaymentMethodProps): Promise<AxiosResponse<ICreatePaymentMethodResponse>> {
    return this.apiService.post<ICreatePaymentMethodResponse>(
      `/api/v1/users/stores/${storeAliasId}/payment-methods`,
      {
        ...data,
      },
    );
  }

  public deletePaymentMethod({
    storeAliasId,
  }: IDeletePaymentMethodProps): Promise<AxiosResponse<void>> {
    return this.apiService.delete<void>(`/api/v1/users/stores/${storeAliasId}/payment-methods`);
  }
}

const paymentMethodService = new PaymentMethodService(USER_API_DOMAIN);

export default paymentMethodService;
