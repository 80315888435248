import React from 'react';

import { EUserStoreRole } from '@domain/enums/common/userStore/EUserStoreRole';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import { WhatsappInstanceProvider } from '@helpers/hooks/pages/dashboard/whatsappInstance/useWhatsappInstance';

import NoAccess from '@components/common/core/Utils/NoAccess';
import Wrapper from '@components/Dashboard/WhatsappInstance/Wrapper';

const Instances: React.FC = () => {
  const { accessManagementData } = useStoreConfig();
  const { isInternalTeam } = useConfig();

  const isStoreOwner = accessManagementData?.role === EUserStoreRole.OWNER;
  const hasAccessToPage =
    accessManagementData?.group?.has_workflow_full_write_access ||
    accessManagementData?.group?.has_workflow_full_read_access;

  if (!isStoreOwner && !hasAccessToPage && !isInternalTeam) {
    return <NoAccess />;
  }

  return (
    <WhatsappInstanceProvider>
      <Wrapper />
    </WhatsappInstanceProvider>
  );
};

export default Instances;
