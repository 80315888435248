import EmptyData from '@components/common/core/DataDisplay/EmptyData';
import { EHeadAlign } from '@domain/enums/components/ETable';
import { usePricingTool } from '@helpers/hooks/pages/dashboard/pricingTool/usePricingTool';
import React from 'react';
import Row from './Row';
import * as S from './styles';

const Table: React.FC = () => {
  const { markup } = usePricingTool();

  return (
    <S.Table>
      <S.Table.Header>
        <S.Table.Row>
          <S.Table.Head align={EHeadAlign.RIGHT}>Markup</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Preço final</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Custo fixo</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>C. de marketing</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Lucro</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Máx. CPA</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Margem de lucro</S.Table.Head>
        </S.Table.Row>
      </S.Table.Header>

      <S.Table.Body>
        <>
          {markup?.length ? (
            <>
              {markup.map((item, index) => (
                <Row key={item} markup={item} index={index} />
              ))}
            </>
          ) : (
            <S.Table.Row>
              <S.Table.Data colSpan={7} align={EHeadAlign.CENTER}>
                <EmptyData content="Não encontramos nenhuma informação" />
              </S.Table.Data>
            </S.Table.Row>
          )}
        </>
      </S.Table.Body>
    </S.Table>
  );
};

export default Table;
