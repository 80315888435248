import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Modal = styled(ModalBase)`
  width: 100%;
  max-width: 464px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
  align-items: center;
`;

export const Heading = styled(TextBase)`
  font-size: 1.125rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white.default};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Text = styled(TextBase)`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white.default};
`;

export const Button = styled(ButtonBase)`
  margin-left: auto;
`;
