import React from 'react';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import {
  IProductCostProvider,
  IProduct,
} from '@domain/interfaces/dashboard/ProductCost/IProductCost';

import { useToast } from '@helpers/hooks/useToast';
import { useDate } from '@helpers/hooks/useDate';
import { useDebounce } from '@helpers/hooks/useDebounce';

import productCostService from '@services/pages/dashboard/productCost/productCost';

const ProductCostContext = React.createContext<IProductCostProvider | null>(null);

export const ProductCostProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { format, utcToZonedTime, subDays } = useDate();

  const [showMassEditCogsModal, setShowMassEditCogsModal] = React.useState<boolean>(false);
  const [products, setProducts] = React.useState<Array<IProduct>>([]);
  const [productsPage, setProductsOrdersPage] = React.useState<number>(0);
  const [productsPageCount, setProductsPageCount] = React.useState<number>(1);
  const [isLoadingProducts, setIsLoadingProducts] = React.useState<boolean>(true);

  const [optionToSearch, setOptionToSearch] = React.useState<string>('');
  const [optionToFilter, setOptionToFilter] = React.useState<string>('');

  const debouncedValue = useDebounce(optionToSearch, 700);

  const loadProducts = React.useCallback(async () => {
    setIsLoadingProducts(true);

    try {
      const { data } = await productCostService.getProductsPromise({
        storeAliasId,
        page: productsPage,
        startDate: format(utcToZonedTime(subDays(new Date(), 29)), 'yyyy-MM-dd'),
        endDate: format(utcToZonedTime(new Date()), 'yyyy-MM-dd'),
        filter: {
          name: optionToFilter,
        },
      });

      if (data) {
        setProducts(currentProducts => [...currentProducts, ...data.products]);

        setProductsPageCount(data.total_pages);
      }
    } catch (error: any) {
      setIsLoadingProducts(false);
      toast.error(error?.response?.data?.message);
    }
    setIsLoadingProducts(false);
  }, [format, productsPage, storeAliasId, toast, utcToZonedTime, subDays, optionToFilter]);

  const handleLoadMore = React.useCallback(() => {
    setProductsOrdersPage(oldPage => oldPage + 1);
  }, []);

  const toggleShowMassEditCogsModal = React.useCallback(() => {
    setShowMassEditCogsModal(prev => !prev);
  }, []);

  const handleProductSearch = React.useCallback(productName => setOptionToSearch(productName), []);

  const handleResetProducts = React.useCallback(() => {
    setProducts([]);
    setProductsOrdersPage(0);
  }, []);

  React.useEffect(() => {
    setProductsOrdersPage(0);
    setProducts([]);
    setOptionToFilter(debouncedValue);
  }, [debouncedValue]);

  const hasMore = productsPage + 1 < productsPageCount;

  return (
    <ProductCostContext.Provider
      value={{
        showMassEditCogsModal,
        toggleShowMassEditCogsModal,
        hasMore,
        isLoadingProducts,
        loadProducts,
        handleLoadMore,
        products,
        handleProductSearch,
        handleResetProducts,
      }}
    >
      {children}
    </ProductCostContext.Provider>
  );
};

export const useProductCost = (): IProductCostProvider => {
  const context = React.useContext(ProductCostContext);

  if (!context) {
    throw new Error('useProductCost must be used within ProductCostProvider');
  }

  return context;
};
