import React from 'react';
import { useTheme } from 'styled-components/macro';
import { CaretDown } from '@profitfy/pakkun-icons';

import Text from '@components/common/core/DataDisplay/Text';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { IDatePickerProps } from '@domain/interfaces/components/IDatePicker';
import { EPreDefinedDates } from '@domain/enums/components/EDatePicker';
import { useDate } from '@helpers/hooks/useDate';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { EStatus } from '@domain/enums/subscription/ESubscription';
import CustomDate from './CustomDate';
import PreDefinedDates from './PreDefinedDates';

import * as S from './styles';

const getPreDefinedDatesText = (type: string): string | undefined =>
  ({
    TODAY: 'Hoje',
    YESTERDAY: 'Ontem',
    LAST_SEVEN_DAYS: 'Últimos 7 dias',
    LAST_THIRTY_DAYS: 'Últimos 30 dias',
  }[type]);

const DatePicker: React.FC<IDatePickerProps> = ({
  period,
  setPeriod,
  color,
  defaultPreDefinedDate,
  ...rest
}) => {
  const theme: any = useTheme();
  const parentRef = React.useRef<any>(null);
  const {
    subDays,
    format,
    utcToZonedTime,
    isBefore,
    isAfter,
    differenceInDays,
    addDays,
  } = useDate();
  const { subscription, hasAllPermissions } = useStoreSubscription();

  const [startDate, setStartDate] = React.useState<Date>(period.startDate);
  const [endDate, setEndDate] = React.useState<Date>(period.endDate);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isCustomDatePickerOpen, setIsCustomDatePickerOpen] = React.useState<boolean>(false);
  const [selectDateType, setSelectDateType] = React.useState<EPreDefinedDates>(
    EPreDefinedDates.TODAY,
  );

  const handleSelectDateType = React.useCallback(type => setSelectDateType(type), []);

  const handleIsCustomDatePickerOpen = React.useCallback(
    () => setIsCustomDatePickerOpen(!isCustomDatePickerOpen),
    [isCustomDatePickerOpen],
  );

  const maxDateLimiter = React.useCallback(() => {
    const isFreePlan = subscription && subscription.plan?.identifier.includes('free');

    if (isFreePlan && subscription.exceeded_order_limit_at && !hasAllPermissions) {
      return utcToZonedTime(new Date(subscription.exceeded_order_limit_at));
    }

    const isSubscriptionPeriodEndedAtAfterNow = subscription?.period_ended_at
      ? differenceInDays(utcToZonedTime(new Date()), new Date(subscription.period_ended_at)) >= 0
      : true;

    if (subscription?.status === EStatus.CANCELED && isSubscriptionPeriodEndedAtAfterNow) {
      return utcToZonedTime(new Date());
    }

    if (
      subscription?.status === EStatus.CANCELED &&
      subscription?.period_ended_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(addDays(new Date(subscription.period_ended_at), 1));
    }

    if (
      subscription?.status === EStatus.CANCELED &&
      subscription?.canceled_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(subscription.canceled_at);
    }

    if (subscription && subscription.status === EStatus.TRIAL_ENDED && !hasAllPermissions) {
      return utcToZonedTime(new Date(subscription.period_ended_at));
    }

    if (
      subscription &&
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_CREATE &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(new Date(subscription.period_ended_at));
    }

    if (
      subscription &&
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_CREATE_FAILED &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(new Date(subscription.period_ended_at));
    }

    if (
      subscription &&
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_UPDATE &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(new Date(subscription.period_ended_at));
    }

    return utcToZonedTime(new Date());
  }, [hasAllPermissions, subscription, utcToZonedTime, differenceInDays, addDays]);

  const minDateLimiter = React.useCallback(() => {
    if (subscription && subscription.status === EStatus.TRIAL_ENDED && !hasAllPermissions) {
      return utcToZonedTime(new Date(subscription.period_ended_at));
    }

    if (
      subscription &&
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_CREATE &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(new Date(subscription.period_started_at));
    }

    if (
      subscription &&
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_CREATE_FAILED &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(new Date(subscription.period_started_at));
    }

    if (
      subscription &&
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_UPDATE &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(new Date(subscription.period_started_at));
    }

    return undefined;
  }, [hasAllPermissions, subscription, utcToZonedTime]);

  const onChange = React.useCallback(
    dates => {
      const [start, end] = dates;

      setStartDate(start);
      setEndDate(end);

      if (end) {
        handleIsCustomDatePickerOpen();
        setPeriod({ startDate: start, endDate: end });
        setIsOpen(false);
        handleIsCustomDatePickerOpen();
      }
    },
    [handleIsCustomDatePickerOpen, setPeriod],
  );

  const onSelectPredefinedDate = React.useCallback(
    dates => {
      const [start, end] = dates;

      setStartDate(start);
      setEndDate(end);

      setPeriod({ startDate: start, endDate: end });
      setIsOpen(false);
    },
    [setPeriod],
  );

  const handleIsOpen = React.useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const parseDatePickerButtonText = React.useCallback(() => {
    if (selectDateType === EPreDefinedDates.CUSTOM) {
      return `${format(period.startDate, 'dd/MM/yyyy')} - ${format(period.endDate, 'dd/MM/yyyy')}`;
    }

    return getPreDefinedDatesText(selectDateType);
  }, [period, selectDateType, format]);

  const isAfterOrEqual = React.useCallback(
    (date, dateToCompare) => {
      const formattedDate = format(date, 'dd/MM/yyyy');
      const formattedDateToCompare = format(dateToCompare, 'dd/MM/yyyy');

      if (formattedDate === formattedDateToCompare) return true;

      return isAfter(date, dateToCompare);
    },
    [isAfter, format],
  );

  const isBeforeOrEqual = React.useCallback(
    (date, dateToCompare) => {
      const formattedDate = format(date, 'dd/MM/yyyy');
      const formattedDateToCompare = format(dateToCompare, 'dd/MM/yyyy');

      if (formattedDate === formattedDateToCompare) return true;

      return isBefore(date, dateToCompare);
    },
    [isBefore, format],
  );

  const handlePreDefinedDates = React.useCallback(
    range => {
      let start;
      let end;
      let isStartDateValid = true;
      let isEndDateValid = true;
      const minDate = minDateLimiter();
      const maxDate = maxDateLimiter();

      // if (subscription?.status === EStatus.CANCELED && subscription?.canceled_at) {
      //   const formattedToday = format(utcToZonedTime(new Date()), 'yyyy-MM-dd');
      //   const formattedCanceledDate = format(
      //     utcToZonedTime(subscription?.canceled_at),
      //     'yyyy-MM-dd',
      //   );

      //   const isDateBefore = isBefore(new Date(formattedCanceledDate), new Date(formattedToday));

      //   if (isDateBefore) {
      //     toast.error(
      //       'Não foi possível selecionar este período. A assinatura da loja consta como cancelada.',
      //     );

      //     return undefined;
      //   }
      // }

      if (range === EPreDefinedDates.TODAY) {
        end = utcToZonedTime(new Date());
        start = utcToZonedTime(new Date());

        isStartDateValid = minDate ? isAfterOrEqual(start, minDate) : true;
        isEndDateValid = maxDate ? isBeforeOrEqual(end, maxDate) : true;

        if (isStartDateValid && isEndDateValid) {
          handleSelectDateType(EPreDefinedDates.TODAY);
          onSelectPredefinedDate([start, end]);
        }
      }

      if (range === EPreDefinedDates.YESTERDAY) {
        end = subDays(utcToZonedTime(new Date()), 1);
        start = subDays(utcToZonedTime(new Date()), 1);

        isStartDateValid = minDate ? isAfterOrEqual(start, minDate) : true;
        isEndDateValid = maxDate ? isBeforeOrEqual(end, maxDate) : true;

        if (isStartDateValid && isEndDateValid) {
          handleSelectDateType(EPreDefinedDates.YESTERDAY);
          onSelectPredefinedDate([start, end]);
        }
      }

      if (range === EPreDefinedDates.LAST_SEVEN_DAYS) {
        end = utcToZonedTime(new Date());
        start = subDays(utcToZonedTime(new Date()), 6);

        isStartDateValid = minDate ? isAfterOrEqual(start, minDate) : true;
        isEndDateValid = maxDate ? isBeforeOrEqual(end, maxDate) : true;

        if (isStartDateValid && isEndDateValid) {
          handleSelectDateType(EPreDefinedDates.LAST_SEVEN_DAYS);
          onSelectPredefinedDate([start, end]);
        }
      }

      if (range === EPreDefinedDates.LAST_THIRTY_DAYS) {
        end = utcToZonedTime(new Date());
        start = subDays(utcToZonedTime(new Date()), 29);

        isStartDateValid = minDate ? isAfterOrEqual(start, minDate) : true;
        isEndDateValid = maxDate ? isBeforeOrEqual(end, maxDate) : true;

        if (isStartDateValid && isEndDateValid) {
          handleSelectDateType(EPreDefinedDates.LAST_THIRTY_DAYS);
          onSelectPredefinedDate([start, end]);
        }
      }

      return undefined;
    },
    [
      onSelectPredefinedDate,
      handleSelectDateType,
      subDays,
      utcToZonedTime,
      minDateLimiter,
      maxDateLimiter,
      isAfterOrEqual,
      isBeforeOrEqual,
    ],
  );

  React.useEffect(() => {
    if (defaultPreDefinedDate) {
      handleSelectDateType(defaultPreDefinedDate);
    }
  }, [defaultPreDefinedDate, handleSelectDateType]);

  return (
    <S.Container {...rest}>
      <S.DatePickerButton type="button" onClick={handleIsOpen} ref={parentRef}>
        <Text size={ETextSize.B3} weight={ETextWeight.REGULAR}>
          {parseDatePickerButtonText()}
        </Text>
        <CaretDown size={20} color={color || theme.colors.font} />
      </S.DatePickerButton>

      <S.DropDown isOpen={isOpen} target={parentRef} toggle={handleIsOpen} width={340}>
        <CustomDate
          startDate={startDate}
          endDate={endDate}
          onChange={onChange}
          isOpen={isCustomDatePickerOpen}
          handleIsOpen={handleIsCustomDatePickerOpen}
          handleSelectDateType={handleSelectDateType}
        />
        <PreDefinedDates handlePreDefinedDates={handlePreDefinedDates} />
      </S.DropDown>
    </S.Container>
  );
};

export default DatePicker;
