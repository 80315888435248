import React from 'react';

import { Feature } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components';

import { ETextWeight, ETextSize } from '@domain/enums/components/EText';
import { ISimulationSection } from '@domain/interfaces/dashboard/BenefitArea/ISimulationSection';

import Simulator from './Simulator';

import * as S from './styles';

const SimulationSection: React.FC<ISimulationSection> = ({
  sendEvent,
  isSendingCogsInformation,
}) => {
  const theme = useTheme();

  return (
    <S.Container>
      <S.Content>
        <S.LeftSide>
          <S.WhoIsZendropWrapper>
            <S.Title weight={ETextWeight.BOLD} size={ETextSize.B3}>
              Quem é a Zendrop?
            </S.Title>

            <S.Description>
              A <strong>Zendrop</strong> é a{' '}
              <strong>
                maior plataforma de <br /> Dropshipping
              </strong>{' '}
              dos Estados Unidos. Que <br /> conecta fornecedores confiáveis com <br /> comerciantes
              on-line e lojas de dropshipping.
            </S.Description>
          </S.WhoIsZendropWrapper>

          <S.BenefitsWrapper>
            <S.BenefitsTitle weight={ETextWeight.BOLD} size={ETextSize.B3}>
              Por que a Zendrop?
            </S.BenefitsTitle>

            <S.Benefit>
              <S.IconWrapper>
                <Feature size={14} color={theme.colors.green.default} />
              </S.IconWrapper>
              <S.BenefitText>
                Mais de <strong>1 milhão de produtos;</strong>
              </S.BenefitText>
            </S.Benefit>

            <S.Benefit>
              <S.IconWrapper>
                <Feature size={14} color={theme.colors.green.default} />
              </S.IconWrapper>
              <S.BenefitText>Suporte de classe mundial;</S.BenefitText>
            </S.Benefit>

            <S.Benefit>
              <S.IconWrapper>
                <Feature size={14} color={theme.colors.green.default} />
              </S.IconWrapper>
              <S.BenefitText>
                Fornecimento de <strong>produtos ilimitados por mês;</strong>
              </S.BenefitText>
            </S.Benefit>

            <S.Benefit>
              <S.IconWrapper>
                <Feature size={14} color={theme.colors.green.default} />
              </S.IconWrapper>
              <S.BenefitText>Marca personalizada;</S.BenefitText>
            </S.Benefit>

            <S.Benefit>
              <S.IconWrapper>
                <Feature size={14} color={theme.colors.green.default} />
              </S.IconWrapper>
              <S.BenefitText>Produtos dos EUA e China;</S.BenefitText>
            </S.Benefit>

            <S.Benefit>
              <S.IconWrapper>
                <Feature size={14} color={theme.colors.green.default} />
              </S.IconWrapper>
              <S.BenefitText>
                Academia <strong>Zendrop.</strong>
              </S.BenefitText>
            </S.Benefit>
          </S.BenefitsWrapper>
        </S.LeftSide>

        <Simulator sendEvent={sendEvent} isSendingCogsInformation={isSendingCogsInformation} />
      </S.Content>
    </S.Container>
  );
};

export default SimulationSection;
