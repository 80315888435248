import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Google, Apple } from '@profitfy/pakkun-icons';
import { useGoogleLogin } from '@react-oauth/google';
import AppleSignin from 'react-apple-signin-auth';
import sha256 from 'crypto-js/sha256';

import { ETextSize } from '@domain/enums/components/EText';
import { EButtonTextColor } from '@domain/enums/components/EButton';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';

import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';
import { useQuery } from '@helpers/hooks/useQuery';

import { signInSchema } from '@helpers/validators/signIn';
import { getEnvironment } from '@helpers/utils/environment';
import { encrypt } from '@helpers/utils/common/encryptor';
import { normalizeEmail, normalizeName } from '@helpers/utils/common/normalizer';

import { AUTH_API_DOMAIN } from '@constants/api';

import logoImg from '@assets/img/brand/profitfy-logo.svg';

import signInService from '@services/pages/auth/signIn';
import googleSignInService from '@services/pages/auth/google/signIn';
import userService from '@services/user';

import Text from '@components/common/core/DataDisplay/Text';
import Form from '@components/common/core/Inputs/Form';

import * as S from './styles';

const SignIn: React.FC = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(signInSchema),
  });
  const { toast } = useToast();
  const {
    handleAuthorization,
    handleUser,
    affiliate,
    handleAppAffiliate,
    handleTerm,
  } = useConfig();
  const { analytics } = useConfig();
  const query = useQuery();

  const utmSourceQuery = query.get('utm_source');
  const utmCampaignQuery = query.get('utm_campaign');
  const utmContentQuery = query.get('utm_content');
  const utmMediumQuery = query.get('utm_medium');
  const utmTermQuery = query.get('utm_term');

  const authorizationQuery = query.get('authorization');
  const isSignUpQuery = query.get('is_sign_up');

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [emailValue, setEmailValue] = React.useState<string>('');
  const [passwordValue, setPasswordValue] = React.useState<string>('');

  const emailRegister = register('email');
  const passwordRegister = register('password');

  const onEmailValueChange = React.useCallback(
    event => {
      emailRegister.onChange(event);

      setEmailValue(event.target.value);
    },
    [emailRegister],
  );

  const onPasswordValueChange = React.useCallback(
    event => {
      passwordRegister.onChange(event);

      setPasswordValue(event.target.value);
    },
    [passwordRegister],
  );

  const handleGoogleLogin = React.useCallback(
    async code => {
      setIsSubmittingForm(true);

      try {
        const encryptionKey = process.env.REACT_APP_GOOGLE_ENCRYPTION_KEY || '';

        const { data: signInData } = await googleSignInService.signIn({
          access_token: encrypt(code.access_token, encryptionKey),
          referral_code: affiliate || undefined,
          utm_source: utmSourceQuery,
          utm_campaign: utmCampaignQuery,
          utm_content: utmContentQuery,
          utm_medium: utmMediumQuery,
          utm_term: utmTermQuery,
        });

        handleAuthorization(signInData?.access_token);

        const { data: userData } = await userService.getUserPromise(signInData?.access_token);

        if (signInData?.is_sign_up) {
          await analytics?.track('Signed Up', {
            created_at: userData.user.created_at,
            email: userData.user.email,
            utm_source: utmSourceQuery,
            utm_campaign: utmCampaignQuery,
            utm_content: utmContentQuery,
            utm_medium: utmMediumQuery,
            utm_term: utmTermQuery,
          });

          analytics?.identifyHubSpot({
            has_signed_up: true,
            created_at: userData?.user.created_at,
          });

          const dataLayer = (window as Record<string, any>)?.dataLayer;

          if (dataLayer) {
            dataLayer.push({
              full_name: `${userData.user.first_name} ${userData.user.last_name}`,
              email: userData.user.email,
              event: 'User Creation Form Submitted',
              hashed_full_name: sha256(
                normalizeName(`${userData.user.first_name} ${userData.user.last_name}`),
              ).toString(),
              hashed_first_name: sha256(normalizeName(userData.user.first_name)).toString(),
              hashed_last_name: sha256(normalizeName(userData.user.last_name)).toString(),
              hashed_email: sha256(normalizeEmail(userData.user.email)).toString(),
            });
          }
        }

        handleUser({ ...userData?.user, user_intercom_hash: userData?.user_intercom_hash });
        handleTerm(userData?.term);

        if (userData?.affiliate) {
          handleAppAffiliate(userData.affiliate);
        }

        setIsSubmittingForm(false);

        history.push('/stores');
      } catch (error: any) {
        setIsSubmittingForm(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [
      affiliate,
      handleAuthorization,
      handleUser,
      history,
      toast,
      analytics,
      handleAppAffiliate,
      handleTerm,
      utmCampaignQuery,
      utmContentQuery,
      utmMediumQuery,
      utmSourceQuery,
      utmTermQuery,
    ],
  );

  const onGoogleLogin = useGoogleLogin({
    onSuccess: codeResponse => {
      handleGoogleLogin(codeResponse);
    },
    onError: response => console.log(response),
    scope: 'openid https://www.googleapis.com/auth/userinfo.profile',
  });

  const onSubmit = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      try {
        const { data: signInData } = await signInService.login({
          email: formData?.email,
          password: formData?.password,
          utm_source: utmSourceQuery,
          utm_campaign: utmCampaignQuery,
          utm_content: utmContentQuery,
          utm_medium: utmMediumQuery,
          utm_term: utmTermQuery,
        });

        handleAuthorization(signInData?.access_token);

        const { data: userData } = await userService.getUserPromise(signInData?.access_token);
        handleUser({ ...userData?.user, user_intercom_hash: userData?.user_intercom_hash });
        handleTerm(userData?.term);

        if (userData?.affiliate) {
          handleAppAffiliate(userData.affiliate);
        }

        setIsSubmittingForm(false);

        history.push('/stores');
      } catch (error: any) {
        setIsSubmittingForm(false);

        if (error?.response?.data?.message === 'Email/Password incorrect') {
          toast.error(
            'Email ou senha incorretos. Caso você tenha uma conta na Profitfy 1.0, crie uma nova conta.',
          );
        } else {
          toast.error(error?.response?.data?.message);
        }
      }
    },
    [
      toast,
      handleAuthorization,
      history,
      handleUser,
      handleAppAffiliate,
      handleTerm,
      utmCampaignQuery,
      utmContentQuery,
      utmMediumQuery,
      utmSourceQuery,
      utmTermQuery,
    ],
  );

  const handleRedirectLogin = React.useCallback(
    async (authorization: string) => {
      setIsSubmittingForm(true);

      try {
        handleAuthorization(authorization);

        const { data: userData } = await userService.getUserPromise(authorization);

        handleUser({ ...userData?.user, user_intercom_hash: userData?.user_intercom_hash });
        handleTerm(userData?.term);

        if (userData?.affiliate) {
          handleAppAffiliate(userData.affiliate);
        }

        setIsSubmittingForm(false);

        if (isSignUpQuery === 'true') {
          await analytics?.track('Signed Up', {
            created_at: userData.user.created_at,
            utm_source: utmSourceQuery,
            utm_campaign: utmCampaignQuery,
            utm_content: utmContentQuery,
            utm_medium: utmMediumQuery,
            utm_term: utmTermQuery,
          });

          analytics?.identifyHubSpot({
            has_signed_up: true,
            created_at: userData?.user.created_at,
          });
        }

        history.push('/stores');

        setIsSubmittingForm(false);
      } catch (error: any) {
        setIsSubmittingForm(false);

        if (error?.response?.data?.message === 'Email/Password incorrect') {
          toast.error(
            'Email ou senha incorretos. Caso você tenha uma conta na Profitfy 1.0, crie uma nova conta.',
          );
        } else {
          toast.error(error?.response?.data?.message);
        }
      }
    },
    [
      analytics,
      handleAuthorization,
      handleUser,
      history,
      toast,
      isSignUpQuery,
      handleAppAffiliate,
      handleTerm,
    ],
  );

  React.useEffect(() => {
    if (authorizationQuery) {
      handleRedirectLogin(authorizationQuery);
    }
  }, [authorizationQuery, handleRedirectLogin]);

  React.useEffect(() => {
    analytics?.page();
  }, [analytics]);

  return (
    <S.Container>
      <S.LeftSide>
        <S.ContentWrapper>
          <S.Logo src={logoImg} alt="profitfy-logo" />

          <S.TitleAndSubTitleWrapper>
            <S.Title type={EHeadingSize.H3} fontWeight={EHeadingWeight.REGULAR}>
              Comece a usar Gratuitamente!
            </S.Title>
            <S.SubTitle size={ETextSize.B5}>Não precisa cadastrar cartão de crédito.</S.SubTitle>
          </S.TitleAndSubTitleWrapper>

          <S.ExternalLoginWrapper>
            <S.ExternalLoginButton onClick={() => onGoogleLogin()} disabled={isSubmittingForm}>
              <Google size={22} />
              Continuar com Google
            </S.ExternalLoginButton>

            <AppleSignin
              authOptions={{
                clientId: 'com.profitfy.web',
                scope: 'email name',
                redirectURI: `${AUTH_API_DOMAIN}/${getEnvironment()}/auth/apple/sign-in`,
                state: JSON.stringify({ referral_code: affiliate || undefined }),
                nonce: '',
                usePopup: false,
              }}
              uiType="dark"
              render={(props: any) => (
                <S.ExternalLoginButton
                  onClick={onGoogleLogin}
                  disabled={isSubmittingForm}
                  {...props}
                >
                  <Apple size={22} />
                  Continuar com Apple
                </S.ExternalLoginButton>
              )}
            />
          </S.ExternalLoginWrapper>

          <S.HorizontalRulesWrapper>
            <S.HorizontalRule />
            <S.HorizontalRuleText>Ou</S.HorizontalRuleText>
            <S.HorizontalRule />
          </S.HorizontalRulesWrapper>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <S.InputGroup>
              <S.Label>E-mail</S.Label>
              <S.Input
                {...emailRegister}
                onChange={onEmailValueChange}
                type="text"
                name="email"
                placeholder="Digite seu e-mail"
                isError={errors.email}
                value={emailValue}
              />
              {errors.email && <Text isErrorFeedback>{errors.email.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Senha</S.Label>
              <S.Input
                {...passwordRegister}
                onChange={onPasswordValueChange}
                type="password"
                name="password"
                placeholder="Digite sua senha"
                isError={errors.password}
                value={passwordValue}
                data-openreplay-obscured
              />
              {errors.password && <Text isErrorFeedback>{errors.password.message}</Text>}
            </S.InputGroup>

            <S.Link to="/forgot-password">Esqueceu sua senha?</S.Link>

            <S.LoginButton
              type="submit"
              textColor={EButtonTextColor.DARK}
              isLoading={isSubmittingForm}
              disabled={isSubmittingForm}
            >
              Entrar
            </S.LoginButton>
          </Form>

          <S.NoAccountText>
            Não tem uma conta? <S.Link to="/sign-up/1">Cadastrar</S.Link>
          </S.NoAccountText>
        </S.ContentWrapper>
      </S.LeftSide>
    </S.Container>
  );
};

export default SignIn;
