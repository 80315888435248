import { ApiService } from '@services/api';
import { USER_API_DOMAIN } from '@constants/api';
import { IMigrationStoreOwnerProps } from '@domain/interfaces/dashboard/AdminPanel/IStoreOwner';

export class MigrationStoreOwnerService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public migrationStoreOwner({ storeAliasId, data }: IMigrationStoreOwnerProps): Promise<any> {
    return this.apiService.put(`/api/v1/users/stores/${storeAliasId}/owner`, {
      ...data,
    });
  }
}

const migrationStoreOwnerService = new MigrationStoreOwnerService(USER_API_DOMAIN);

export default migrationStoreOwnerService;
