import React from 'react';
import { LineChart } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import * as S from './styles';

const BlurredContent: React.FC = () => {
  const theme = useTheme();

  return (
    <>
      <S.TotalAmountText>(-) R$ 00,00</S.TotalAmountText>

      <S.ForeCastAmount>
        <LineChart outline size={12} color={theme.colors.gray[2]} />
        (-) R$ 00,00
      </S.ForeCastAmount>

      <S.TotalLabel>Total</S.TotalLabel>
    </>
  );
};

export default BlurredContent;
