import React from 'react';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { IHeadProps } from '@domain/interfaces/components/ITable';

import * as S from './styles';

const Head: React.ForwardRefRenderFunction<HTMLTableCellElement, IHeadProps> = (
  { align = EHeadAlign.LEFT, upperCase, children, colSpan, ...rest },
  ref,
) => {
  return (
    <S.Head colSpan={colSpan} textAlign={align} upperCase={upperCase} ref={ref} {...rest}>
      {children}
    </S.Head>
  );
};

export default React.forwardRef(Head);
