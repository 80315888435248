import React from 'react';
import { Plus, Hand, Sync, ChevronRight, IIconProps } from '@profitfy/pakkun-icons';
import { Star as FavoriteProductIcon } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';
import { CircleSpinner } from 'react-spinners-kit';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { EBadgeColors } from '@domain/enums/components/EBadge';
import { IParams } from '@domain/interfaces/IParams';

import { useRankingTable } from '@helpers/hooks/pages/dashboard/productAnalytics/useRankingTable';
import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';

import { numberFormatter } from '@helpers/masks';
import { getBadgeColorByValue } from '@helpers/utils/components/badge';
import { getFormattedValue } from '@helpers/utils/dashboard/productAnalytic/rankingProducts';

import { ADS_PROVIDERS } from '@constants/common/integrations';

import NewMarketingCostSidemodal from '@components/Dashboard/ProductAnalytics/RankingTable/NewMarketingCostSidemodal';
import Badge from '@components/common/core/DataDisplay/Badge';
import Tooltip from '@components/common/core/Utils/Tooltip';
import productCostDetailsService from '@services/pages/dashboard/productCostDetails/productCostDetails';
import PieChart from './PieChart';

import * as S from './styles';

const Row: React.FC<ListChildComponentProps> = ({ index, style }) => {
  const theme = useTheme();
  const history = useHistory();

  const { toast } = useToast();
  const { analytics } = useConfig();
  const { storeAliasId } = useParams<IParams>();
  const { productsMetrics, mutate: mutateProduct } = useRankingTable();

  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const [isImageError, setIsImageError] = React.useState<boolean>(false);
  const [isUpdatingProduct, setIsUpdatingProduct] = React.useState<boolean>(false);
  const [
    isNewMarketingCostSidemodalOpen,
    setIsNewMarketingCostSidemodalOpen,
  ] = React.useState<boolean>(false);

  const productMetrics = productsMetrics[index];

  const { product, metrics } = productMetrics;

  const isFavoriteProduct = product?.is_favorite;

  const productDetailsPageURL = `/${storeAliasId}/dashboard/overview/products-analytic/${product.alias_id}/details`;

  const data = [
    {
      name: 'Conversão',
      value: metrics.boleto_conversion.percentage,
    },
    {
      name: 'Total',
      value: 100 - metrics.boleto_conversion.percentage,
    },
  ];

  const colors = ['#2EB3B4', '#515562'];

  const handleIsNewMarketingCostSidemodalOpen = React.useCallback(() => {
    setIsNewMarketingCostSidemodalOpen(!isNewMarketingCostSidemodalOpen);
  }, [isNewMarketingCostSidemodalOpen]);

  const handleNewMarketingCostClick = React.useCallback(
    event => {
      event.stopPropagation();

      handleIsNewMarketingCostSidemodalOpen();
    },
    [handleIsNewMarketingCostSidemodalOpen],
  );

  const onMouseEnter = React.useCallback(() => setIsHovering(true), []);

  const onMouseLeave = React.useCallback(() => setIsHovering(false), []);

  const handleSyncCampaigns = React.useCallback(() => {
    history.push(
      `/${storeAliasId}/dashboard/overview/products-analytic/${product.alias_id}/campaigns`,
    );
  }, [history, storeAliasId, product]);

  const handleSyncCampaignsClick = React.useCallback(
    event => {
      event.stopPropagation();

      handleSyncCampaigns();
    },
    [handleSyncCampaigns],
  );

  const redirectToProductDetails = React.useCallback(() => {
    history.push(productDetailsPageURL);
  }, [history, productDetailsPageURL]);

  const stopParentPropagation = React.useCallback(event => {
    event.stopPropagation();
  }, []);

  const trackFavoriteProduct = React.useCallback(() => {
    const trackContext = { context: { groupId: storeAliasId } };

    if (!isFavoriteProduct) {
      analytics?.track('Product Ranking Favorite Product Added', {}, trackContext);
    } else {
      analytics?.track('Product Ranking Remove Favorite Product', {}, trackContext);
    }
  }, [analytics, storeAliasId, isFavoriteProduct]);

  const onFavoriteProductIconClick = React.useCallback(
    async event => {
      event.stopPropagation();

      setIsUpdatingProduct(true);

      try {
        await productCostDetailsService.updateProduct({
          storeAliasId,
          productAliasId: product.alias_id,
          data: {
            is_favorite: !isFavoriteProduct,
            is_onboarding_finished: product?.is_onboarding_finished,
          },
        });

        await mutateProduct();

        trackFavoriteProduct();

        const toastMessage = isFavoriteProduct
          ? 'Produto removido dos favoritos com sucesso!'
          : 'Produto favoritado com sucesso!';

        toast.success(toastMessage);

        setIsUpdatingProduct(false);
      } catch (error: any) {
        setIsUpdatingProduct(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [toast, storeAliasId, product, isFavoriteProduct, mutateProduct, trackFavoriteProduct],
  );

  const hasMarketingValue = metrics.marketing.total_amount > 0;

  const marketingsAmount: Array<any> = [];

  Object.entries(metrics.marketing).forEach(([key, value]) => {
    const parsedProviderName = key.replace('_amount', '').toUpperCase();

    const foundIntegration = ADS_PROVIDERS.find(
      integration => integration.identifier === parsedProviderName,
    );

    if (foundIntegration && parsedProviderName !== 'manual' && parsedProviderName !== 'total') {
      marketingsAmount.push({
        name: foundIntegration.name,
        icon: foundIntegration.icon,
        value,
      });
    }
  });

  const formattedNetRevenueAmountWithQuantity = getFormattedValue(
    metrics.net_revenue.amount,
    metrics.net_revenue.quantity,
  );

  const formattedPendingLineItemsWithQuantity = getFormattedValue(
    metrics.pending_line_items.amount,
    metrics.pending_line_items.quantity,
  );

  const formattedCogsAmount = getFormattedValue(metrics.cogs.amount);

  const formattedMarketingTotalAmount = getFormattedValue(metrics.marketing.total_amount);

  const formattedFeeAmount = getFormattedValue(metrics.fee.amount);

  const formattedCpaAmount = getFormattedValue(metrics.cpa.amount);

  const formattedNetProfitAmount = getFormattedValue(metrics.net_profit.amount);

  const hasProductImage = product.image_url && !isImageError;

  const favoriteIconWeight = isFavoriteProduct ? 'fill' : 'regular';
  const favoriteIconTooltipContent = isFavoriteProduct ? 'Remover favorito' : 'Favoritar';
  const favoriteIconColor = isFavoriteProduct ? theme.colors.green.default : theme.colors.gray[2];

  return (
    <>
      <S.Table.Row
        borderBottom
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={redirectToProductDetails}
        style={style}
      >
        <S.Table.Data>
          <S.Link to={productDetailsPageURL}>
            <S.RankingText>{`#${index + 1}`}</S.RankingText>
          </S.Link>
        </S.Table.Data>

        <S.Table.Data align={EHeadAlign.CENTER}>
          <S.CustomTooltip>
            <S.CustomTooltipTrigger>
              <S.Link to={productDetailsPageURL}>
                {!hasProductImage && <S.NoImage />}

                {hasProductImage && (
                  <S.ProductImage
                    src={product.image_url}
                    alt="Profitfy.me Custo do Produto"
                    onError={() => setIsImageError(true)}
                  />
                )}
              </S.Link>
            </S.CustomTooltipTrigger>

            <S.CustomTooltip.Content side="top" sideOffset={5}>
              <S.ProductNameText>{product.name}</S.ProductNameText>
              <S.CustomTooltip.Arrow />
            </S.CustomTooltip.Content>
          </S.CustomTooltip>
        </S.Table.Data>

        <S.Table.Data align={EHeadAlign.RIGHT}>
          {formattedNetRevenueAmountWithQuantity}
        </S.Table.Data>

        <S.Table.Data align={EHeadAlign.RIGHT}>
          {formattedPendingLineItemsWithQuantity}
        </S.Table.Data>

        <S.Table.Data align={EHeadAlign.RIGHT}>{formattedCogsAmount}</S.Table.Data>

        <S.Table.Data align={EHeadAlign.RIGHT}>
          <S.MarketingData>
            <S.CustomTooltip>
              <S.CustomTooltip.Trigger>
                <S.MarketingText>
                  {!hasMarketingValue && <>{formattedMarketingTotalAmount}</>}

                  {hasMarketingValue && (
                    <Badge color={EBadgeColors.PURPLE}>{formattedMarketingTotalAmount}</Badge>
                  )}
                </S.MarketingText>
              </S.CustomTooltip.Trigger>

              <S.CustomTooltip.Content side="top" sideOffset={5}>
                <S.MarketingValueTooltipWrapper>
                  {marketingsAmount.map(integration => {
                    const Icon = integration?.icon as React.FC<IIconProps>;

                    return (
                      <S.MarketingTooltipLineWrapper>
                        <Icon size={16} />
                        <S.MarketingTooltipText>
                          {`R$ ${numberFormatter(integration?.value || 0, 2)}`}
                        </S.MarketingTooltipText>
                      </S.MarketingTooltipLineWrapper>
                    );
                  })}
                </S.MarketingValueTooltipWrapper>

                <S.CustomTooltip.Arrow />
              </S.CustomTooltip.Content>
            </S.CustomTooltip>

            {isHovering && (
              <>
                <S.CustomTooltip>
                  <S.CustomTooltip.Trigger>
                    <S.CustomPopover>
                      <S.CustomPopover.Trigger onClick={stopParentPropagation}>
                        <S.MarketingWrapper>
                          <Plus size={10} color={theme.colors.gray[1]} />
                        </S.MarketingWrapper>
                      </S.CustomPopover.Trigger>

                      <S.CustomPopover.Content>
                        <S.MoreOptionWrapper>
                          <S.ActionButton onClick={handleSyncCampaignsClick}>
                            <Sync size={16} color={theme.colors.font} />
                            Sincronização Automática
                          </S.ActionButton>
                          <S.ActionButton onClick={handleNewMarketingCostClick}>
                            <Hand size={16} color={theme.colors.font} />
                            Lançamento Manual
                          </S.ActionButton>
                        </S.MoreOptionWrapper>

                        <S.CustomPopover.Arrow />
                      </S.CustomPopover.Content>
                    </S.CustomPopover>
                  </S.CustomTooltip.Trigger>

                  <S.CustomTooltip.Content side="top" sideOffset={5}>
                    <S.AddCostText>Adicionar custos</S.AddCostText>
                    <S.CustomTooltip.Arrow />
                  </S.CustomTooltip.Content>
                </S.CustomTooltip>
              </>
            )}

            {isNewMarketingCostSidemodalOpen && (
              <NewMarketingCostSidemodal
                isOpen={isNewMarketingCostSidemodalOpen}
                toggle={handleIsNewMarketingCostSidemodalOpen}
                product={product}
              />
            )}
          </S.MarketingData>
        </S.Table.Data>

        {/* <S.Table.Data align={EHeadAlign.CENTER}>
          <S.ProfitMarginData>
            <S.ProfitMarginBadge color={getBadgeColorByValue(metrics.profit_margin.percentage)}>
              {`${numberFormatter(metrics.profit_margin.percentage, 0, true)}%`}
            </S.ProfitMarginBadge>
          </S.ProfitMarginData>
        </S.Table.Data> */}

        <S.Table.Data align={EHeadAlign.RIGHT}>{formattedFeeAmount}</S.Table.Data>

        <S.Table.Data align={EHeadAlign.RIGHT}>{formattedCpaAmount}</S.Table.Data>

        <S.Table.Data align={EHeadAlign.CENTER}>
          <S.ConversionWrapper>
            <PieChart data={data} colors={colors} />
            <S.ConversionValue>{`${numberFormatter(
              metrics.boleto_conversion.percentage,
              0,
              true,
            )}%`}</S.ConversionValue>
          </S.ConversionWrapper>
        </S.Table.Data>

        <S.Table.Data align={EHeadAlign.RIGHT}>
          <S.NetProfitTooltip>
            <S.NetProfitTooltip.Trigger>
              <S.Badge color={getBadgeColorByValue(metrics.net_profit.amount)}>
                {`${formattedNetProfitAmount} (${numberFormatter(
                  metrics.profit_margin.percentage,
                  0,
                  true,
                )}%)`}
              </S.Badge>
            </S.NetProfitTooltip.Trigger>

            <S.NetProfitTooltip.Content side="top" sideOffset={5}>
              <S.NetProfitWrapper>
                <S.NetProfitContent>
                  <S.NetProfitText>Lucro Líquido:</S.NetProfitText>
                  <S.NetProfitText>
                    <strong>{formattedNetProfitAmount}</strong>
                  </S.NetProfitText>
                </S.NetProfitContent>

                <S.NetProfitContent>
                  <S.NetProfitText>Margem de Lucro:</S.NetProfitText>
                  <S.NetProfitText>
                    <strong>{`${numberFormatter(
                      metrics.profit_margin.percentage,
                      0,
                      true,
                    )}%`}</strong>
                  </S.NetProfitText>
                </S.NetProfitContent>
              </S.NetProfitWrapper>
              <S.NetProfitTooltip.Arrow />
            </S.NetProfitTooltip.Content>
          </S.NetProfitTooltip>
        </S.Table.Data>

        <S.Table.Data align={EHeadAlign.CENTER}>
          <Tooltip content={favoriteIconTooltipContent}>
            <S.FavoriteIconWrapper onClick={onFavoriteProductIconClick}>
              {isUpdatingProduct ? (
                <CircleSpinner size={12} color={theme.colors.green.default} />
              ) : (
                <FavoriteProductIcon
                  size={20}
                  color={favoriteIconColor}
                  weight={favoriteIconWeight}
                />
              )}
            </S.FavoriteIconWrapper>
          </Tooltip>
        </S.Table.Data>

        <S.Table.Data align={EHeadAlign.CENTER}>
          <S.ChevronIconWrapper>
            {isHovering ? <ChevronRight size={24} color={theme.colors.font} /> : <></>}
          </S.ChevronIconWrapper>
        </S.Table.Data>
      </S.Table.Row>
    </>
  );
};

export default Row;
