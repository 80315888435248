import styled, { css } from 'styled-components';

import {
  IFeatureStyleProps,
  IPermissionsWrapperProps,
} from '@domain/interfaces/dashboard/AccessManagement/INewGroup';

import TextBase from '@components/common/core/DataDisplay/Text';
import CheckBoxBase from '@components/common/core/Inputs/Checkbox';
import LabelBase from '@components/common/core/DataDisplay/Label';

export const CheckboxLabel = styled(LabelBase)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin: unset;
  cursor: pointer;
`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Checkbox = styled(CheckBoxBase)``;

export const FeatureAndPermissionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Feature = styled.button<IFeatureStyleProps>`
  width: 100%;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.gray[7]};
  border-radius: 8px;
  padding: 20px 26px 0 24px;
  border: unset;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;

  ${({ isVisible }) =>
    isVisible &&
    css`
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
    `}
`;

export const FeatureNameAndIconWrapper = styled.div`
  width: 100%;
  height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FeatureName = styled(TextBase)`
  color: #fff;
`;

export const HorizontalRule = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray[4]};
`;

export const PermissionsWrapper = styled.div<IPermissionsWrapperProps>`
  display: none;
  background-color: unset;

  ${({ isVisible }) =>
    isVisible &&
    css`
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background-color: ${({ theme }) => theme.colors.gray[7]};
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      padding: 14px 24px 24px;
      box-sizing: border-box;
      gap: 24px;
    `}
`;

export const PermissionName = styled(LabelBase)`
  font-size: 0.875rem;
  margin: unset;
  line-height: unset;
  width: 100%;
  cursor: pointer;
`;
