import styled from 'styled-components/macro';

import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import TextBase from '@components/common/core/DataDisplay/Text';

export const AddedOptionsWrapper = styled.div`
  width: 100%;
  height: auto;
  max-height: 370px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 32px;
  box-sizing: border-box;
  margin-top: 25px;

  scrollbar-color: ${({ theme }) => theme.colors.gray[4]} ${({ theme }) => theme.colors.gray[6]};
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[4]};
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const FullWidthSelect = styled(SelectBase)`
  width: 100%;
`;

export const Option = styled(OptionBase)``;

export const Description = styled(TextBase)`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[1]};
  margin-bottom: 8px;
`;
