import * as yup from 'yup';

export const profileSchema = yup.object().shape({
  first_name: yup
    .string()
    .matches(/^[a-zA-Z]*$/, 'Este campo deve conter apenas o primeiro nome.')
    .min(2, 'O nome precisa conter no mínimo 2 letras.')
    .max(30, 'O nome pode conter no máximo 30 letras.'),
  last_name: yup
    .string()
    .matches(/^[a-zA-Z ]*$/, 'Este campo deve conter apenas o sobrenome')
    .min(2, 'O sobrenome precisa conter no mínimo 2 letras.')
    .max(50, 'O sobrenome pode conter no máximo 50 letras'),
  email: yup
    .string()
    .email('Você precisa inserir um e-mail válido.')
    .required('Este campo é obrigatório.'),
  phone_number: yup
    .string()
    .required('É necessário informar um telefone.')
    .min(11, 'Informe um telefone válido.'),
  birth_day: yup.number().required('É necessário informar a data de nascimento'),
  birth_month: yup.number().required('É necessário informar uma data de nascimento'),
  birth_year: yup.number().required('É necessário informar a data de nascimento'),
  role: yup.string().required('É necessário informar o seu cargo de atuação.'),
});
