import React from 'react';
import { Plus } from 'phosphor-react';
import { useTheme } from 'styled-components';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';

import WebSearchDark from '@assets/dashboard/automations/canvas/empty-instances.svg';

import * as S from './styles';

const EmptyInstance: React.FC = () => {
  const { colors } = useTheme();
  const { toggleShowAddWhatsappInstanceModal, toggleShowSaveAutomationModal } = useAutomation();

  const handleAddInstance = React.useCallback(() => {
    toggleShowAddWhatsappInstanceModal();
    toggleShowSaveAutomationModal();
  }, [toggleShowAddWhatsappInstanceModal, toggleShowSaveAutomationModal]);

  return (
    <S.Wrapper>
      <S.Image src={WebSearchDark} alt="Whatsapp instances not found" />
      <S.Message>Conecte-se à uma instância do Whatsapp para poder salvar a automação.</S.Message>
      <S.NewInstance
        onClick={handleAddInstance}
        iconPrepend={Plus}
        iconColor={colors.black.default}
      >
        Nova instância
      </S.NewInstance>
    </S.Wrapper>
  );
};

export default EmptyInstance;
