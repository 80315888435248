import styled, { css } from 'styled-components/macro';

import { IHeaderCellProps } from '@domain/interfaces/dashboard/AdsManager/Table/IHeaderCell';
import {
  IHeaderCellContentProps,
  IHeaderCellTitleProps,
} from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IHeaderCell';
import { EHeadAlign } from '@domain/enums/components/ETable';

import TextBase from '@components/common/core/DataDisplay/Text';

export const RemoveColumnButton = styled.button`
  background: unset;
  border: unset;
  padding: 0;
  margin-left: auto;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SortableWrapper = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

export const Text = styled(TextBase)<IHeaderCellTitleProps>`
  font-size: 0.875rem;
  font-weight: 700;
  transition: all 0.3s;

  ${({ isSortSelected, theme }) =>
    isSortSelected &&
    css`
      color: ${theme.colors.green.default};
    `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const ResizableElement = styled.div<any>`
  width: 4px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.fourthLayer};
  transition: background-color 0.3s, height 0.5s;
  cursor: col-resize;

  &:hover {
    background-color: ${({ theme }) => theme.colors.green.default};
  }

  ${({ isPressing, theme, tableHeight }) =>
    isPressing &&
    css`
      background-color: ${theme.colors.green.default};
      height: ${`${tableHeight + 53 + 98}px`};
      width: 1px;
    `}
`;

export const HeaderCellContent = styled.div<IHeaderCellContentProps>`
  display: flex;
  align-items: center;
  padding: 14px 12px;
  box-sizing: border-box;
  position: relative;

  ${({ isSortable }) =>
    isSortable &&
    css`
      cursor: pointer;
    `}
`;

export const HeaderCell = styled.th<IHeaderCellProps>`
  background-color: ${({ theme }) => theme.colors.gray[5]};
  height: 50px;
  transition: all 0.3s;
  position: sticky;
  position: -webkit-sticky;
  top: -53px;
  -webkit-box-shadow: inset 0px -1px 0px 0px ${({ theme }) => theme.colors.gray[4]},
    inset -1px 0px 0px 0px ${({ theme }) => theme.colors.gray[4]};
  box-shadow: inset 0px -1px 0px 0px ${({ theme }) => theme.colors.gray[4]},
    inset -1px 0px 0px 0px ${({ theme }) => theme.colors.gray[4]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[6]};
  }

  ${({ contentAlign }) =>
    contentAlign === EHeadAlign.CENTER &&
    css`
      & > div {
        justify-content: center;
      }
    `}

  ${({ contentAlign }) =>
    contentAlign === EHeadAlign.RIGHT &&
    css`
      & > div {
        justify-content: flex-end;
      }
    `}
`;
