import styled, { css } from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import { ICardProps } from '@domain/interfaces/subscription/ICreditCard';

export const IssuerLogo = styled(ImageBase)`
  max-width: 40px;
  width: 100%;
`;

export const ChipAndIssuerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Cvv = styled.span`
  background-color: rgba(255, 255, 255, 0.8);
  color: ${({ theme }) => theme.colors.black.default};
  height: 24px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Line = styled.div`
  background-color: rgba(120, 120, 2, 0.3);
  height: 4px;
  width: 100%;
`;

export const RandomBar = styled.div`
  height: 24px;
  width: calc(100% - 60px);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const CvvWrapper = styled.div`
  width: 100%;
  margin: 0 12px;
  display: flex;
  align-items: center;
`;

export const BlackBar = styled.div`
  width: 100%;
  height: 48px;
  background-color: #000;
  margin-top: 24px;
  margin-bottom: 16px;
`;

export const ExpirationDate = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.font};
`;

export const Name = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.font};
`;

export const NameAndExpirationDateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardNumber = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.font};
  font-size: 1.25rem;
  font-family: monospace;
  margin-bottom: 8px;
`;

export const Chip = styled(ImageBase)`
  max-width: 48px;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 16px;
`;

export const BackCard = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom right, #000000 0%, rgba(0, 0, 0, 0.8) 95.9%);
  border-radius: 12px;

  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(180deg);
`;

export const FrontCard = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom right, #000000 0%, rgba(0, 0, 0, 0.8) 95.9%);
  border-radius: 12px;
  padding: 24px;
  box-sizing: border-box;
  display: block;

  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
`;

export const FlipCard = styled.div<ICardProps>`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  border-radius: 12px;
  background: #fff;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.green.default};

  ${({ isCvvFocused }) =>
    isCvvFocused &&
    css`
      transform: rotateY(180deg);
    `}
`;

export const Card = styled.div`
  max-width: 320px;
  width: 100%;
  height: 182px;
  margin: 0 auto 36px auto;

  perspective: 1000px;
`;
