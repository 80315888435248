import styled, { css } from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';
import ImageBase from '@components/common/core/DataDisplay/Image';
import { IProviderButtonProps } from '@domain/interfaces/dashboard/ProductAnalytics/IProductDetails';

export const ErrorImage = styled(ImageBase)`
  max-width: 164px;
`;

export const ActivateButton = styled(ButtonBase)`
  margin-bottom: 32px;
  margin-top: 24px;
`;

export const Description = styled(TextBase)`
  display: block;
  margin-top: 24px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ProviderButton = styled.button<IProviderButtonProps>`
  border: 1px solid ${({ theme }) => theme.colors.gray[1]};
  border-radius: 8px;
  transition: all 0.3s;
  background-color: unset;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.colors.green.default};
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      border-color: ${theme.colors.green.default};
    `}
`;

export const ProvidersButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Title = styled(HeadingBase)``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  max-width: 848px;
  width: 100%;
  height: 378px;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  border: 1px solid ${({ theme }) => theme.colors.gray[4]};
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.gray[7]};
`;
