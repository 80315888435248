import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import LabelBase from '@components/common/core/DataDisplay/Label';
import ButtonBase from '@components/common/core/Inputs/Button';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import Alert from '@components/common/core/DataDisplay/Alert';

export const InstallmentAlert = styled(Alert)`
  background-color: #221e18;
  padding: 8px 10px;

  & > div {
    & > span:last-of-type {
      line-height: 150%;
    }
  }
`;

export const Option = styled(OptionBase)``;

export const Select = styled(SelectBase)`
  width: 100%;
`;

export const NextStepButton = styled(ButtonBase)`
  width: 100%;
`;

export const ExpirationDateAndSecurityCodeWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;

  & > div {
    margin: unset;
  }
`;

export const Input = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)``;

export const FormContent = styled.div`
  & > div {
    margin-bottom: 24px;
  }

  & > div:last-of-type {
    margin-bottom: 32px;
  }
`;

export const StepNumber = styled(TextBase)`
  font-size: 1.25rem;
  font-weight: 700;
`;

export const StepNumberWrapper = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray[6]};
`;

export const Title = styled(HeadingBase)``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 28px;
`;

export const Wrapper = styled.div`
  @media only screen and (max-width: 450px) {
    padding: 0 32px;
  }
`;
