import React from 'react';

import Header from '@components/Dashboard/ProductAnalytics/ProductDetails/Header';
import OverviewCards from '@components/Dashboard/ProductAnalytics/ProductDetails/OverviewCards';
import FunnelChart from '@components/Dashboard/ProductAnalytics/ProductDetails/FunnelChart';
import Metrics from '@components/Dashboard/ProductAnalytics/ProductDetails/Metrics';
import PaymentMethodsCards from '@components/Dashboard/ProductAnalytics/ProductDetails/PaymentMethodsCards';
import ProfitCard from '@components/Dashboard/ProductAnalytics/ProductDetails/ProfitCard';
import ProductDataCard from '@components/Dashboard/ProductAnalytics/ProductDetails/ProductDataCard';
import OrdersDataCard from '@components/Dashboard/ProductAnalytics/ProductDetails/OrdersDataCard';
import { useMediaQuery } from '@helpers/hooks/useMediaQuery';
import { useProductDetails } from '@helpers/hooks/pages/dashboard/productAnalytics/useProductDetails';
import PageError from '@components/common/core/Utils/PageError';
import NoProviderIntegration from '@components/Dashboard/ProductAnalytics/ProductDetails/NoProviderIntegration';
import { hasActiveAdSenseCampaign } from '@helpers/utils/dashboard/productAnalytic/productDetails';

import { ProductCostDetailsProvider } from '@helpers/hooks/pages/dashboard/productCostDetails/productCostDetails';

import InstantHelp from '@components/common/core/Utils/InstantHelp';

import SkeletonLoading from '../SkeletonLoading';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const {
    isLoadingProductDetails,
    isValidatingProductDetails,
    isProductDetailsError,
    mutateProductDetails,
    adSenseCampaigns,
  } = useProductDetails();

  const isMobileWidth = useMediaQuery('1124px');

  if (isProductDetailsError && !isValidatingProductDetails) {
    return <PageError mutate={mutateProductDetails} />;
  }

  const isLoading = isLoadingProductDetails || isValidatingProductDetails;

  return (
    <S.Container>
      <Header />

      {isLoading && <SkeletonLoading />}

      {!isLoading && (
        <>
          {isMobileWidth && <ProfitCard />}

          <ProductCostDetailsProvider variantsRequestLimit={5}>
            <OverviewCards />
          </ProductCostDetailsProvider>

          <S.SectionsWrapper>
            <S.FirstSection>
              {hasActiveAdSenseCampaign(adSenseCampaigns) && <FunnelChart />}

              {!hasActiveAdSenseCampaign(adSenseCampaigns) && <NoProviderIntegration />}

              <Metrics />

              <PaymentMethodsCards />
            </S.FirstSection>

            <S.SecondSection>
              {!isMobileWidth && <ProfitCard />}

              <ProductDataCard />

              <OrdersDataCard />
            </S.SecondSection>
          </S.SectionsWrapper>
        </>
      )}

      <InstantHelp
        name="product_details"
        title="Como ativar o Marketing Individual"
        articleLink="https://ajuda.profitfy.me/pt-BR/articles/6461256-como-ativar-o-marketing-individual-de-cada-produto"
      />
    </S.Container>
  );
};

export default Wrapper;
