import { ApiService } from '@services/api';
import { API_DOMAIN } from '@constants/api';
import { ICreateGoalProps, IUpdateGoalProps } from '@domain/interfaces/dashboard/Goal/IGoal';

export class GoalService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public createGoal({ storeAliasId, data }: ICreateGoalProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/goals`, { ...data });
  }

  public updateGoal({ storeAliasId, goalAliasId, data }: IUpdateGoalProps): Promise<any> {
    return this.apiService.put(`/api/v1/users/stores/${storeAliasId}/goals/${goalAliasId}`, {
      ...data,
    });
  }
}

const goalService = new GoalService(API_DOMAIN);

export default goalService;
