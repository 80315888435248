import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';
import { ShoppingCart } from 'phosphor-react';

export const ABANDONED_CART_FREE = [
  {
    id: 'abandoned-cart-free-first-condition',
    title: 'Carrinho abandonado',
    type: 'ABANDONED_CART',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: ShoppingCart,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá, {{first_name}}! 😀\n\nNotamos que você deixou alguns itens no seu carrinho. 🛒\n\nClique no link abaixo e garanta seu produto com mais 10% de desconto utilizando o cupom: BLABLA10.\n\n{{checkout_link}}',
      },
    ],
  },
];
