import { MarkerType } from 'react-flow-renderer';

import { IWorkflowToCanvas } from '@domain/interfaces/common/automations/IWorkflows';
import IReactFlowWorkflowEdge from '@domain/interfaces/mappers/automations/reactFlow/IWorkflowEdge';

const map = ({ workflow_edges }: IWorkflowToCanvas): IReactFlowWorkflowEdge[] => {
  const mappedEdges = workflow_edges.map(edge => {
    const { provider_id, source, source_handle, target, target_handle } = edge;

    const mappedEdge: IReactFlowWorkflowEdge = {
      id: provider_id,
      source,
      target,
      sourceHandle: source_handle,
      targetHandle: target_handle,
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        orient: 'auto',
      },
      style: {
        strokeWidth: 4,
      },
      type: 'buttonedge',
    };

    return mappedEdge;
  });

  return mappedEdges;
};

export default { map };
