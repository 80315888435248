import React from 'react';

import { INextStepProps } from '@domain/interfaces/dashboard/AccessManagement/INextStep';

import * as S from './styles';

const NextStep: React.FC<INextStepProps> = ({
  onClick,
  disabled,
  isLoading,
  type = 'button',
  children,
  textColor,
}) => {
  return (
    <S.Wrapper>
      <S.Button
        textColor={textColor}
        onClick={onClick}
        disabled={disabled}
        isLoading={isLoading}
        type={type}
      >
        {children}
      </S.Button>
    </S.Wrapper>
  );
};

export default NextStep;
