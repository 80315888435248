import React from 'react';
import { useParams } from 'react-router-dom';

import {
  IEditBudgetPopoverContentProps,
  IUpdateAdSenseCampaignData,
} from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IAdSenseCampaignsActions';
import { EButtonSize, EButtonVariant } from '@domain/enums/components/EButton';
import { IParams } from '@domain/interfaces/IParams';

import { currencyToNumber, currencyFormatter, numberFormatter } from '@helpers/masks';

import { useCampaigns } from '@helpers/hooks/pages/dashboard/adsManager/useCampaigns';
import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const EditCampaignDailyBudgetContent: React.FC<IEditBudgetPopoverContentProps> = ({
  setIsEditBudgetPopoverOpen,
}) => {
  const {
    handleEditBudgetModalOpen,
    isEditBudgetModalOpen,
    mappedAdSenseCampaigns,
    onUpdateAdSenseCampaign,
  } = useCampaigns();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { analytics } = useConfig();

  const foundAdSenseCampaign = mappedAdSenseCampaigns.find(
    campaign => campaign.alias_id === isEditBudgetModalOpen,
  );

  const foundBudget = foundAdSenseCampaign?.data.find(data => data.id === 'budget');

  const [budget, setBudget] = React.useState<string>(foundBudget?.data || '0');
  const [isEditingCampaignBudget, setIsEditingCampaignBudget] = React.useState<boolean>(false);

  const onCancel = React.useCallback(() => {
    handleEditBudgetModalOpen(undefined);
    setIsEditBudgetPopoverOpen(false);
  }, [setIsEditBudgetPopoverOpen, handleEditBudgetModalOpen]);

  const onBudgetChange = React.useCallback(event => {
    const { value } = event.target;

    const parsedValue = currencyFormatter(value);

    setBudget(parsedValue);

    event.target.value = parsedValue;
  }, []);

  const onUpdateClick = React.useCallback(async () => {
    const parsedNumber = currencyToNumber(budget || '0,00');

    if (foundAdSenseCampaign && parsedNumber >= 15) {
      setIsEditingCampaignBudget(true);

      const data: IUpdateAdSenseCampaignData = {
        daily_budget: parsedNumber,
      };

      await onUpdateAdSenseCampaign(isEditBudgetModalOpen, data);

      analytics?.track(
        'Ads Manager Table Campaign Budget Edited',
        {},
        { context: { groupId: storeAliasId } },
      );

      setIsEditingCampaignBudget(false);
      setIsEditBudgetPopoverOpen(false);
    }

    if (!budget) toast.error('Informe um valor válido.');

    if (budget && parsedNumber < 15)
      toast.error('O orçamento precisar ser igual ou maior que R$15.');
  }, [
    foundAdSenseCampaign,
    budget,
    isEditBudgetModalOpen,
    onUpdateAdSenseCampaign,
    setIsEditBudgetPopoverOpen,
    toast,
    analytics,
    storeAliasId,
  ]);

  return (
    <S.CustomPopoverContent sideOffset={5}>
      <S.Wrapper>
        <S.Input
          type="text"
          defaultValue={numberFormatter(foundBudget?.data || 0, 2)}
          onChange={onBudgetChange}
        />

        <S.ButtonsWrapper>
          <S.CancelButton
            type="button"
            onClick={onCancel}
            variant={EButtonVariant.SECONDARY}
            buttonSize={EButtonSize.MEDIUM}
            disabled={isEditingCampaignBudget}
          >
            Cancelar
          </S.CancelButton>
          <S.RenameButton
            type="button"
            onClick={onUpdateClick}
            buttonSize={EButtonSize.MEDIUM}
            disabled={isEditingCampaignBudget}
            isLoading={isEditingCampaignBudget}
          >
            Publicar
          </S.RenameButton>
        </S.ButtonsWrapper>
      </S.Wrapper>
    </S.CustomPopoverContent>
  );
};

export default EditCampaignDailyBudgetContent;
