import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const AmountText = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[1]};
  filter: blur(5px);
  user-select: none;
`;

export const LabelText = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[2]};
  filter: blur(5px);
  user-select: none;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  overflow: hidden;
  padding: 0 8px;
  box-sizing: border-box;
`;

export const TotalLabel = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
  line-height: 120%;
  filter: blur(5px);
  user-select: none;
  font-size: 0.875rem;
  margin-right: 8px;
  height: 20px;
`;
