import styled, { css } from 'styled-components/macro';

import { IOptionProps } from '@domain/interfaces/components/ITab';

export const OptionButton = styled.button<IOptionProps>`
  padding: 2px 12px;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  font-size: 1rem;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.background[15]};
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 18px;
  margin-right: 4px;

  ${({ isSelected }) =>
    !isSelected &&
    css`
      &:hover {
        background-color: rgba(167, 175, 198, 0.3);
      }
    `};

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      color: ${theme.colors.green.default};
      background-color: rgba(46, 179, 180, 0.3);
    `}
`;
