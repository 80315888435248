import React from 'react';

import {
  ESimpleShipmentType,
  ESimpleShipmentProductOptions,
  ESimpleShipmentTotalValueOptions,
} from '@domain/enums/dashboard/automations/node/switchNode/ESimpleShipment';

import { ISwitchSimpleShipmentNodeModal } from '@domain/interfaces/dashboard/Automations/Canvas/Node/SwitchNode/ISwitchSimpleShipmentNodeModal';
import RuleSelect from './RuleSelect';

import * as S from './styles';

const SimpleShipmentModal: React.FC<ISwitchSimpleShipmentNodeModal> = ({
  rules,
  handleChangeRule,
  handleChangeRules,
}) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);

  const handleChangeType = React.useCallback(
    e => {
      const choosen = e.target.value as ESimpleShipmentType;

      if (choosen === ESimpleShipmentType.PRODUCT_NAME) {
        handleChangeRules({
          type: choosen,
          options: ESimpleShipmentProductOptions.CONTAINS,
          input: '',
        });
      } else if (choosen === ESimpleShipmentType.REJECTED_PAYMENT_TOTAL_VALUE) {
        handleChangeRules({
          type: choosen,
          options: ESimpleShipmentTotalValueOptions.SMALLER,
          input: '',
        });
      }
    },
    [handleChangeRules],
  );

  let ruleWithProblem: null | number = null;

  const isFirstRuleEmpty = rules[0].input === '';
  const isSecondRuleEmpty = rules[1].input === '';
  const isThirdRuleEmpty = rules[2].input === '';

  if (isFirstRuleEmpty && !isSecondRuleEmpty && !isThirdRuleEmpty) {
    ruleWithProblem = 0;
  }

  if (!isFirstRuleEmpty && isSecondRuleEmpty && !isThirdRuleEmpty) {
    ruleWithProblem = 1;
  }

  if (isFirstRuleEmpty && isSecondRuleEmpty && !isThirdRuleEmpty) {
    ruleWithProblem = 0;
  }

  return (
    <>
      <S.AddedOptionsWrapper ref={scrollRef}>
        <S.Container>
          <S.Description>Selecione o dado para a condição</S.Description>
          <S.FullWidthSelect name="type" defaultValue={rules[0].type} onChange={handleChangeType}>
            <S.Option value={ESimpleShipmentType.PRODUCT_NAME}>Nome do Produto</S.Option>
            <S.Option value={ESimpleShipmentType.REJECTED_PAYMENT_TOTAL_VALUE}>
              Valor Total
            </S.Option>
          </S.FullWidthSelect>
        </S.Container>

        {rules.length > 0 &&
          rules.map((value, index) => (
            <RuleSelect
              key={value.id}
              isDynamic={false}
              value={value}
              index={index}
              onUpdate={handleChangeRule}
              ruleWithProblem={ruleWithProblem}
            />
          ))}
      </S.AddedOptionsWrapper>
    </>
  );
};

export default SimpleShipmentModal;
