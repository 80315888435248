import React from 'react';

import { useStoreIntegrations } from '@helpers/hooks/pages/dashboard/integrationCenter/useStoreIntegrations';

import Header from './Header';
import DropshippingProvider from './DropshippingProvider';
import AdsProvider from './AdsProvider';
import Gateway from './Gateway';
import Erp from './Erp';
import StoreIntegrations from './StoreIntegrations';

import * as S from './styles';

const DropshippingWrapper: React.FC = () => {
  const { selectedTab } = useStoreIntegrations();

  return (
    <S.Container>
      <Header />

      {selectedTab === 0 && <StoreIntegrations />}

      {(selectedTab === 0 || selectedTab === 1) && <DropshippingProvider />}

      {(selectedTab === 0 || selectedTab === 2) && <Erp />}

      {(selectedTab === 0 || selectedTab === 3) && <Gateway />}

      {(selectedTab === 0 || selectedTab === 4) && <AdsProvider />}
    </S.Container>
  );
};

export default DropshippingWrapper;
