import { ApiService } from '@services/api';
import { LONG_API_DOMAIN } from '@constants/api';
import {
  ISyncTaboolaAccountsProps,
  IUpdateTaboolaAccountProps,
} from '@domain/interfaces/dashboard/AdSense/Taboola/ITaboolaAccount';

export class TaboolaAccountService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public syncTaboolaAccounts({
    adSenseCredentialAliasId,
    storeAliasId,
  }: ISyncTaboolaAccountsProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/ad-sense-credentials/${adSenseCredentialAliasId}/taboola/ad-sense-accounts/sync`,
    );
  }

  public updateTaboolaAccount({
    adSenseAccountAliasId,
    adSenseCredentialAliasId,
    data,
    storeAliasId,
  }: IUpdateTaboolaAccountProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/ad-sense-credentials/${adSenseCredentialAliasId}/taboola/ad-sense-accounts/${adSenseAccountAliasId}`,
      { ...data },
    );
  }
}

const taboolaAccountService = new TaboolaAccountService(LONG_API_DOMAIN);

export default taboolaAccountService;
