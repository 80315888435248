import { ETag, EChannel } from '@domain/enums/dashboard/automations/ETemplate';
import { EAutomationsTemplate } from '@domain/enums/common/automation/ETemplate';
import { ETriggers } from '@domain/enums/dashboard/automations/ETriggers';

export const STARTER_LIMIT_TEMPLATES = [
  {
    alias: 'no-content',
    id: EAutomationsTemplate.NO_CONTENT,
    type: undefined,
    name: 'Em Branco',
    description:
      'Crie uma automação completamente nova, personalizada de acordo com as necessidades específicas do seu negócio.',
    tags: [ETag.ECOMMERCE],
    supported_channels: [EChannel.WHATSAPP, EChannel.EMAIL, EChannel.SMS],
    image_url:
      'https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/automations/no-content-template.svg',
    nodes: [],
  },
  {
    alias: 'abandoned-cart-4',
    id: EAutomationsTemplate.ABANDONED_CART_STARTER,
    type: ETriggers.ABANDONED_CART,
    name: 'Carrinho Abandonado Simples',
    description:
      'Esta é uma solução automatizada básica projetada para ajudar a recuperar carrinhos abandonados em sua loja online',
    tags: [ETag.INFOPRODUCT, ETag.ECOMMERCE],
    supported_channels: [EChannel.WHATSAPP],
    image_url:
      'https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/automations/simple-abandoned-cart.svg',
  },
  {
    alias: 'boleto-3',
    id: EAutomationsTemplate.BOLETO_STARTER,
    type: ETriggers.BOLETO,
    name: 'Recuperação de Boleto Simples',
    description:
      'Esta é uma automação simples desenvolvida para auxiliar na recuperação de boletos não pagos em seu negócio online.',
    tags: [ETag.ECOMMERCE],
    supported_channels: [EChannel.WHATSAPP],
    image_url:
      'https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/automations/simple-boleto-recovery.svg',
  },
  {
    alias: 'refused-credit-card-3',
    id: EAutomationsTemplate.REFUSED_CREDIT_CARD_STARTER,
    type: ETriggers.REJECTED_PAYMENT,
    name: 'Cartão Recusado Simples',
    description:
      'Esta é uma solução automatizada básica projetada para auxiliar na recuperação de pedidos com cartão recusado em sua loja online.',
    tags: [ETag.ECOMMERCE, ETag.INFOPRODUCT],
    supported_channels: [EChannel.WHATSAPP],
    image_url:
      'https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/automations/simple-refused-credit-card.svg',
  },
  {
    alias: 'pix-3',
    id: EAutomationsTemplate.PIX_STARTER,
    type: ETriggers.PIX,
    name: 'Recuperação de Pix Simples',
    description:
      'Esta é uma automação simples projetada para auxiliar na recuperação de transações Pix não concluídas em seu negócio online.',
    tags: [ETag.ECOMMERCE, ETag.INFOPRODUCT],
    supported_channels: [EChannel.WHATSAPP],
    image_url:
      'https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/automations/simple-pix-recovery.svg',
  },
];
