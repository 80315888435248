import React from 'react';

import { currencyFormatter, numberFormatter } from '@helpers/masks';

import { EOrderType } from '@domain/enums/common/EOrder';
import { IRefundedOrderFormProps } from '@domain/interfaces/dashboard/Orders/IEditOrder';

import Text from '@components/common/core/DataDisplay/Text';
import Option from '@components/common/core/Inputs/Select/Option';

import * as S from './styles';

const RefundedEditForm: React.FC<IRefundedOrderFormProps> = ({
  order,
  errors,
  register,
  setDate,
  date,
}) => {
  const handleAmountChange = React.useCallback(event => {
    event.target.value = currencyFormatter(event.target.value);
  }, []);

  return (
    <>
      <S.InputGroup>
        <S.Label>Tipo do pedido</S.Label>

        <S.Select {...register('type')} name="type" defaultValue={order.type}>
          <Option disabled value="" />
          <Option value={EOrderType.CREDIT_CARD}>Cartão de Crédito</Option>
          <Option value={EOrderType.BOLETO}>Boleto</Option>
          <Option value={EOrderType.PIX}>Pix</Option>
        </S.Select>

        {errors.type && <Text isErrorFeedback>{errors.type.message}</Text>}
      </S.InputGroup>

      <S.InputGroupWrapper>
        <S.InputGroup>
          <S.Label>Devolvido em:</S.Label>

          <S.DayPicker
            selectedDate={date}
            setSelectedDate={setDate}
            minDate={new Date(order.created_at)}
          />
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Valor devolvido</S.Label>

          <S.Input
            {...register('refunded_amount')}
            name="refunded_amount"
            type="text"
            placeholder="Insira o valor"
            defaultValue={numberFormatter(order.refunded_amount || 0, 2)}
            onChange={handleAmountChange}
            prependText="R$"
          />

          {errors.refunded_amount && <Text isErrorFeedback>{errors.refunded_amount.message}</Text>}
        </S.InputGroup>
      </S.InputGroupWrapper>
    </>
  );
};

export default RefundedEditForm;
