import React from 'react';

import { EButtonSize } from '@domain/enums/components/EButton';
import { useFeedCustomValueBillings } from '@helpers/hooks/pages/dashboard/feed/useCustomValueBillings';

import * as S from './styles';

const Footer: React.FC = () => {
  const {
    selectedCustomValueBillings,
    handleSelectAllCustomValueBillings,
    updateStatusToPaid,
    isUpdatingFinancialStatus,
  } = useFeedCustomValueBillings();

  const [
    isSelectedAllCustomValueBillingCheckboxChecked,
    setIsSelectedAllCustomValueBillingCheckboxChecked,
  ] = React.useState<boolean>(false);

  const onCheckboxChange = React.useCallback(
    event => {
      if (!event || event.target) return;

      handleSelectAllCustomValueBillings(event.checked);
      setIsSelectedAllCustomValueBillingCheckboxChecked(event.checked);
    },
    [handleSelectAllCustomValueBillings],
  );

  return (
    <S.Wrapper>
      <S.CheckboxAndSelectAllExpensesTextWrapper>
        <S.CheckboxWrapper>
          <S.SelectAllExpensesCheckbox
            id="select-all-custom-value-billings"
            onChange={onCheckboxChange}
          />
        </S.CheckboxWrapper>

        <S.SelectAllExpensesText>
          {!isSelectedAllCustomValueBillingCheckboxChecked
            ? 'Selecionar todas despesas'
            : 'Deselecionar todas despesas'}
        </S.SelectAllExpensesText>
      </S.CheckboxAndSelectAllExpensesTextWrapper>

      <S.ChangeStatusButton
        buttonSize={EButtonSize.MEDIUM}
        onClick={updateStatusToPaid}
        isLoading={isUpdatingFinancialStatus}
        disabled={!selectedCustomValueBillings.length || isUpdatingFinancialStatus}
      >
        Trocar status para pago
      </S.ChangeStatusButton>
    </S.Wrapper>
  );
};

export default Footer;
