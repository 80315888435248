import React from 'react';
import { SimpleCalendar } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { useDatePickerRange } from '@helpers/hooks/components/useDatePickerRange';
import { EPreDefinedDates } from '@domain/enums/components/EDatePicker';
import { useDate } from '@helpers/hooks/useDate';
import CustomDatePickerPopover from '../CustomDatePickerPopover';

import * as S from './styles';

const Wrapper: React.FC = ({ ...rest }) => {
  const theme = useTheme();
  const {
    selectedDate,
    handlePreDefinedDates,
    isCustomDatePickerOpen,
    handleCustomDatePickerOpen,
    startDate,
    endDate,
  } = useDatePickerRange();
  const { format } = useDate();

  const [isHoveringCustomDate, setIsHoveringCustomDate] = React.useState<boolean>(false);

  const handleMouseEnter = React.useCallback(() => setIsHoveringCustomDate(true), []);

  const handleMouseLeave = React.useCallback(() => setIsHoveringCustomDate(false), []);

  const hoveringColor = theme.colors.green.default;
  const notHoveringColor = theme.colors.gray[2];

  const isTodaySelected = selectedDate === EPreDefinedDates.TODAY;
  const isYesterdaySelected = selectedDate === EPreDefinedDates.YESTERDAY;
  const isLastSevenDaysSelected = selectedDate === EPreDefinedDates.LAST_SEVEN_DAYS;
  const isLastThirtyDaysSelected = selectedDate === EPreDefinedDates.THIS_MONTH;
  const isCustomDateSelected = selectedDate === EPreDefinedDates.CUSTOM;

  const calendarIconColor =
    isHoveringCustomDate || isCustomDateSelected ? hoveringColor : notHoveringColor;

  const parsedDateText = `${format(startDate, 'dd/MM')} - ${format(endDate, 'dd/MM')}`;

  const customDateText = selectedDate === EPreDefinedDates.CUSTOM ? parsedDateText : 'Customizado';

  return (
    <S.Container {...rest}>
      <S.DateButton
        isSelected={isTodaySelected}
        onClick={() => handlePreDefinedDates(EPreDefinedDates.TODAY)}
      >
        Hoje
      </S.DateButton>
      <S.DateButton
        isSelected={isYesterdaySelected}
        onClick={() => handlePreDefinedDates(EPreDefinedDates.YESTERDAY)}
      >
        Ontem
      </S.DateButton>
      <S.DateButton
        isSelected={isLastSevenDaysSelected}
        onClick={() => handlePreDefinedDates(EPreDefinedDates.LAST_SEVEN_DAYS)}
      >
        7 Dias
      </S.DateButton>
      <S.DateButton
        isSelected={isLastThirtyDaysSelected}
        onClick={() => handlePreDefinedDates(EPreDefinedDates.THIS_MONTH)}
      >
        Este mês
      </S.DateButton>

      <S.CustomDatePopover open={isCustomDatePickerOpen} onOpenChange={handleCustomDatePickerOpen}>
        <S.CustomDatePopover.Trigger>
          <S.CustomDateWrapper
            isSelected={isCustomDateSelected}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {customDateText}
            <SimpleCalendar outline size={16} color={calendarIconColor} />
          </S.CustomDateWrapper>
        </S.CustomDatePopover.Trigger>

        <CustomDatePickerPopover />
      </S.CustomDatePopover>
    </S.Container>
  );
};

export default Wrapper;
