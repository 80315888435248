import React from 'react';
import { useHistory } from 'react-router-dom';

import { EDropshippingProvider } from '@domain/enums/common/integrations/EDropshippingProvider';
import { EGateway } from '@domain/enums/common/EGateway';
import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { IDropshippingProvider } from '@domain/interfaces/onboarding/dropshipping/IDropshipping';
import { IStoreIntegrations } from '@domain/interfaces/stores/IStores';
import { IOnboardingStep } from '@domain/interfaces/onboarding/IOnboarding';
import { EStep } from '@domain/enums/onboarding/EStep';

import { useToast } from '@helpers/hooks/useToast';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';
import { useConfig } from '@helpers/hooks/useConfig';

import subscriptionService from '@services/pages/subscription/subscription';
import onboardingStepService from '@services/pages/onboarding/onboardingStep';

const DropshippingContext = React.createContext<IDropshippingProvider | null>(null);

export const DropshippingProvider: React.FC = ({ children }) => {
  const { user, analytics } = useConfig();
  const { toast } = useToast();
  const history = useHistory();
  const { completeStep } = useOnboarding();

  const [selectedProvider, setSelectedProvider] = React.useState<EDropshippingProvider | undefined>(
    undefined,
  );
  const [selectedGateways, setSelectedGateways] = React.useState<Array<EGateway>>([]);
  const [selectedMarketings, setSelectedMarketings] = React.useState<Array<EAdSenseProvider>>([]);
  const [currentGatewayToIntegrate, setCurrentGatewayToIntegrate] = React.useState<
    EGateway | undefined
  >(undefined);
  const [currentMarketingToIntegrate, setCurrentMarketingToIntegrate] = React.useState<
    EAdSenseProvider | undefined
  >(undefined);
  const [hasSelectedStoreIntegrations, setHasSelectedStoreIntegrations] = React.useState<boolean>(
    false,
  );
  const [storeIntegrations, setStoreIntegrations] = React.useState<IStoreIntegrations | undefined>(
    undefined,
  );
  const [isLoadingTrial, setIsLoadingTrial] = React.useState<boolean>(false);

  const createTrial = React.useCallback(
    async storeAliasId => {
      setIsLoadingTrial(true);

      try {
        const { data: trialData } = await subscriptionService.createTrial({ storeAliasId });

        analytics?.track(
          'Trial Started',
          {
            trial_start_date: trialData?.subscription?.period_started_at,
            trial_end_date: trialData?.subscription?.period_ended_at,
            email: user?.email,
          },
          { context: { groupId: storeAliasId } },
        );

        const dataLayer = (window as Record<string, any>)?.dataLayer;

        if (dataLayer) {
          dataLayer.push({ event: 'Trial Started' });
        }

        const {
          data: onboardingStepsData,
        } = await onboardingStepService.listOnboardingStepsPromise({ storeAliasId });

        const foundOnboardingStep:
          | IOnboardingStep
          | undefined = onboardingStepsData.onboarding_steps.find(
          (onboardingStep: IOnboardingStep) =>
            onboardingStep.configuration_step === EStep.STORE_DSERS_CONFIG,
        );

        if (foundOnboardingStep) {
          completeStep(foundOnboardingStep.id);
        }

        setIsLoadingTrial(false);

        history.push(`/onboarding/${storeAliasId}/finished`);
      } catch (error: any) {
        setIsLoadingTrial(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [toast, history, completeStep, analytics, user],
  );

  const handleStoreBonus = React.useCallback(
    async storeAliasId => {
      setIsLoadingTrial(true);

      try {
        const { data: subscriptionData } = await subscriptionService.getSubscriptionPromise({
          storeAliasId,
        });

        if (subscriptionData.subscription) {
          setIsLoadingTrial(false);

          history.push(`/onboarding/${storeAliasId}/finished`);

          return undefined;
        }

        await createTrial(storeAliasId);
      } catch (error: any) {
        setIsLoadingTrial(false);
        toast.error(error?.response?.data?.message);
      }

      return undefined;
    },
    [toast, history, createTrial],
  );

  const handleSelectedProvider = React.useCallback(provider => setSelectedProvider(provider), []);

  const handleSelectedGateways = React.useCallback(gateways => setSelectedGateways(gateways), []);

  const handleSelectedMarketings = React.useCallback(
    marketings => setSelectedMarketings(marketings),
    [],
  );

  const handleStoreIntegrations = React.useCallback(
    integrations => setStoreIntegrations(integrations),
    [],
  );

  const handleSelectedStoreIntegrations = React.useCallback(
    status => setHasSelectedStoreIntegrations(status),
    [],
  );

  const handleNextGatewayToIntegrate = React.useCallback(() => {
    if (selectedGateways.length) {
      const selectedGatewaysArrayCopy = [...selectedGateways];

      const nextGatewayToIntegrate = selectedGatewaysArrayCopy.shift();

      setCurrentGatewayToIntegrate(nextGatewayToIntegrate);
      setSelectedGateways([...selectedGatewaysArrayCopy]);

      return true;
    }

    setCurrentGatewayToIntegrate(undefined);

    return false;
  }, [selectedGateways]);

  const handleNextMarketingToIntegrate = React.useCallback(() => {
    if (selectedMarketings.length) {
      const selectedMarketingsArrayCopy = [...selectedMarketings];

      const nextMarketingToIntegrate = selectedMarketingsArrayCopy.shift();

      setCurrentMarketingToIntegrate(nextMarketingToIntegrate);
      setSelectedMarketings([...selectedMarketingsArrayCopy]);

      return true;
    }

    setCurrentMarketingToIntegrate(undefined);

    return false;
  }, [selectedMarketings]);

  React.useEffect(() => {
    if (storeIntegrations) {
      const foundStoreProvider = storeIntegrations.store_front.find(provider => provider.is_active);

      if (foundStoreProvider) setSelectedProvider(foundStoreProvider.name as EDropshippingProvider);

      const foundActiveStoreGateways = storeIntegrations.gateways.filter(
        gateway => gateway.is_active,
      );

      const hasOtherGateway = storeIntegrations.gateways.find(
        gateway => gateway.other_gateway?.has_other_gateway,
      );

      const mappedActiveStoreGateways = foundActiveStoreGateways.map(
        gateway => gateway.name as EGateway,
      );

      if (hasOtherGateway) {
        const otherGatewaysNames =
          (hasOtherGateway.other_gateway?.gateway_names as Array<EGateway>) ||
          ([] as Array<EGateway>);

        const uniqueGatewaysNames = [...new Set(otherGatewaysNames)];

        mappedActiveStoreGateways.push(...uniqueGatewaysNames);
      }

      setSelectedGateways(mappedActiveStoreGateways);
    }
  }, [storeIntegrations]);

  return (
    <DropshippingContext.Provider
      value={{
        handleSelectedProvider,
        selectedProvider,
        handleStoreIntegrations,
        storeIntegrations,
        handleSelectedGateways,
        selectedGateways,
        handleSelectedMarketings,
        selectedMarketings,
        handleSelectedStoreIntegrations,
        hasSelectedStoreIntegrations,
        handleNextGatewayToIntegrate,
        currentGatewayToIntegrate,
        handleNextMarketingToIntegrate,
        currentMarketingToIntegrate,
        handleStoreBonus,
        isLoadingTrial,
      }}
    >
      {children}
    </DropshippingContext.Provider>
  );
};

export const useDropshipping = (): IDropshippingProvider => {
  const context = React.useContext(DropshippingContext);

  if (!context) {
    throw new Error('useDropshipping must be used within DropshippingProvider');
  }

  return context;
};
