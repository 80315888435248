import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import SelectBase from '@components/common/core/Inputs/Select/index';
import SidemodalBase from '@components/common/core/DataDisplay/SideModal';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextBase from '@components/common/core/DataDisplay/Text';
import OptionBase from '@components/common/core/Inputs/Select/Option';

export const ListItem = styled.li`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.font};
`;

export const Option = styled(OptionBase)``;

export const ErrorWrapper = styled.div`
  margin: 128px 0;
`;

export const Select = styled(SelectBase)`
  & > label > select {
    width: 508px;
  }
`;

export const Sidemodal = styled(SidemodalBase)`
  max-width: 588px;
  box-sizing: border-box;
  width: 100%;
`;

export const Input = styled(TextFieldBase)``;

export const InputGroup = styled(InputGroupBase)`
  max-width: 374px;
  width: 100%;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 32px;
  }
`;

export const Label = styled(LabelBase)``;

export const Header = styled.div`
  margin-bottom: 16px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
`;

export const Heading = styled(HeadingBase)`
  margin-left: 16px;
`;

export const ProductsTitle = styled(TextBase)`
  font-size: 1rem;
  font-style: normal;
  text-align: left;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const ProductListWrapper = styled.ul`
  margin-top: 0px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const ContentWrapper = styled.div`
  max-width: 487px;
  width: 100%;
  margin-bottom: 108px;
`;
