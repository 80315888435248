import React from 'react';
import { Store } from '@profitfy/pakkun-icons';
import { useParams } from 'react-router-dom';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { EGateway } from '@domain/enums/common/EGateway';
import { ISidemodalProps } from '@domain/interfaces/onboarding/integrations/ISidemodal';
import { IParams } from '@domain/interfaces/IParams';
import { IGateway } from '@domain/interfaces/common/integration/IGateway';

import { useDropshipping } from '@helpers/hooks/pages/onboarding/useDropshipping';
import { useConfig } from '@helpers/hooks/useConfig';
import { useMediaQueryHeight } from '@helpers/hooks/useMediaQuery';

import { DROPSHIPPING_PROVIDERS, GATEWAYS } from '@constants/common/integrations';

import Save from './Save';
import SuggestionModal from './SuggestionModal';

import * as S from './styles';

const Sidemodal: React.FC<ISidemodalProps> = ({ isOpen, toggle }) => {
  const {
    handleSelectedGateways,
    selectedGateways: userGateways,
    selectedProvider,
    storeIntegrations,
  } = useDropshipping();
  const { analytics, user } = useConfig();
  const { storeAliasId } = useParams<IParams>();

  const mobileHeight = useMediaQueryHeight('768px');

  const [selectedGateways, setSelectedGateways] = React.useState<Array<EGateway>>([]);
  const [isSuggestionModalOpen, setIsSuggestionModalOpen] = React.useState<boolean>(false);

  const handleSuggestionModalOpen = React.useCallback(
    () => setIsSuggestionModalOpen(!isSuggestionModalOpen),
    [isSuggestionModalOpen],
  );

  const onSave = React.useCallback(() => {
    handleSelectedGateways(selectedGateways);

    analytics?.track(
      'Onboarding Selected Gateways',
      {
        selected_gateways: selectedGateways.map((selectedGateway: any) => selectedGateway.name),
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );

    toggle();
  }, [toggle, selectedGateways, handleSelectedGateways, analytics, storeAliasId, user]);

  const isDefaultChecked = React.useCallback(
    (integration: typeof GATEWAYS[number]) => {
      const foundIntegration = userGateways.find(
        userGateway => userGateway === integration.identifier,
      );

      return Boolean(foundIntegration);
    },
    [userGateways],
  );

  const onChange = React.useCallback(
    event => {
      if (event && !event.target) {
        if (event.checked) {
          const foundGateway = GATEWAYS.find(integration => integration.identifier === event.value);

          if (foundGateway)
            setSelectedGateways(previousState => [...previousState, foundGateway.identifier]);
        } else {
          const filteredGateways = selectedGateways.filter(
            integration => integration !== event.value,
          );

          setSelectedGateways(filteredGateways);
        }
      }
    },
    [selectedGateways],
  );

  const hasAlreadyIntegrated = React.useCallback(
    (gatewayIntegration: EGateway): boolean => {
      if (storeIntegrations) {
        const hasOtherGateway = storeIntegrations.gateways.find(
          gateway => gateway.other_gateway?.has_other_gateway,
        );

        const preDefinedGateways = GATEWAYS.filter(gateway => gateway.isPredefinedOtherGateway);
        const mappedPreDefinedGateways = preDefinedGateways.map(gateway => gateway.identifier);
        const isOtherGateway =
          mappedPreDefinedGateways.includes(gatewayIntegration) ||
          gatewayIntegration === EGateway.OTHER_GATEWAY;

        if (hasOtherGateway && isOtherGateway) return true;

        if (!hasOtherGateway && isOtherGateway) return false;

        const foundGateway = storeIntegrations.gateways.find(
          integration => integration.name === gatewayIntegration,
        );

        if (foundGateway) return foundGateway.is_active;
      }

      return false;
    },
    [storeIntegrations],
  );

  React.useEffect(() => {
    if (userGateways.length) {
      setSelectedGateways(userGateways);
    }
  }, [userGateways]);

  const getGateways = React.useCallback(() => {
    const gatewaysToReturn: Array<IGateway> = [];

    const foundSelectedProvider = DROPSHIPPING_PROVIDERS.find(
      provider => provider.identifier === selectedProvider,
    );

    if (!foundSelectedProvider) return gatewaysToReturn;

    const providerGateways = foundSelectedProvider.gateways;

    const filteredProviderGateways = GATEWAYS.filter(gateway =>
      providerGateways.includes(gateway.identifier),
    );

    gatewaysToReturn.push(...filteredProviderGateways);

    const preDefinedGateways = GATEWAYS.filter(gateway => gateway.isPredefinedOtherGateway);

    const otherGateway = GATEWAYS.find(
      gateway => gateway.identifier === EGateway.OTHER_GATEWAY,
    ) as typeof GATEWAYS[number];

    const foundSelectedOtherGateway = selectedGateways.includes(EGateway.OTHER_GATEWAY)
      ? otherGateway
      : undefined;
    const foundOtherGateway =
      preDefinedGateways.find(gateway => selectedGateways.includes(gateway.identifier)) ||
      foundSelectedOtherGateway;

    if (foundOtherGateway) {
      gatewaysToReturn.push(foundOtherGateway);
    } else {
      const uniquePreDefinedGateways = [...new Set(preDefinedGateways)];

      gatewaysToReturn.push(...uniquePreDefinedGateways, otherGateway);
    }

    return gatewaysToReturn;
  }, [selectedProvider, selectedGateways]);

  const filteredGateways = getGateways();

  return (
    <>
      <S.Sidemodal isOpen={isOpen} toggle={toggle} blockOverflowYScroll={mobileHeight}>
        <S.Header>
          <Store size={24} />
          <S.Title fontWeight={EHeadingWeight.REGULAR} type={EHeadingSize.H4}>
            Selecione seus gateways de pagamento
          </S.Title>
        </S.Header>

        <S.Body>
          {/* <S.NoIntegrationText>
            Não encontrou a plataforma que você usa?{' '}
            <S.SuggestionButton onClick={openSuggestionModal}>
              Nos informe clicando aqui.
            </S.SuggestionButton>
          </S.NoIntegrationText> */}

          <S.IntegrationsWrapper>
            {filteredGateways.map(integration => {
              const Icon = integration.icon;

              return (
                <S.InputGroup key={integration.identifier}>
                  <S.CheckboxWrapper htmlFor={integration.identifier}>
                    <S.CheckboxTextWrapper>
                      <Icon size={24} />
                      <S.CheckboxText>{integration.name}</S.CheckboxText>
                    </S.CheckboxTextWrapper>
                  </S.CheckboxWrapper>

                  <S.Checkbox
                    value={integration.identifier}
                    defaultChecked={isDefaultChecked(integration)}
                    id={integration.identifier}
                    onChange={onChange}
                    disabled={hasAlreadyIntegrated(integration.identifier)}
                  />
                </S.InputGroup>
              );
            })}
          </S.IntegrationsWrapper>
        </S.Body>

        <Save onCancel={toggle} onSave={onSave} disabled={!selectedGateways.length} />
      </S.Sidemodal>

      <SuggestionModal isOpen={isSuggestionModalOpen} toggle={handleSuggestionModalOpen} />
    </>
  );
};

export default Sidemodal;
