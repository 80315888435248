import React from 'react';

import { IHeaderProps } from '@domain/interfaces/dashboard/Feed/IAdSenseAccount';

import * as S from './styles';

const Header: React.FC<IHeaderProps> = ({ provider }) => {
  return (
    <S.Header>
      <S.Title>Conta(s) de anúncio bloqueada(s) do {provider}</S.Title>
    </S.Header>
  );
};

export default Header;
