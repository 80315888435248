import React from 'react';
import { SimplePlus, Check } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { useMarketingIntegration } from '@helpers/hooks/pages/dashboard/adsManager/onboarding/useMarketingIntegration';

import * as S from './styles';

const AddIntegration: React.FC = () => {
  const theme = useTheme();
  const { handleSideModalOpen, selectedIntegrations } = useMarketingIntegration();

  return (
    <S.Wrapper>
      <S.AddIntegrationButton onClick={handleSideModalOpen}>
        <S.AddIntegrationWrapper>
          <S.IconWrapper>
            <SimplePlus color={theme.colors.gray[1]} size={14} />
          </S.IconWrapper>
          Selecionar plataforma de anúncio
        </S.AddIntegrationWrapper>

        <S.SelectedIntegrationsWrapper>
          {selectedIntegrations.map(integration => {
            const Icon = integration.icon;

            return (
              <S.SelectedIntegration key={integration.id}>
                <Icon size={24} />
                <S.SelectedIntegrationName>{integration.name}</S.SelectedIntegrationName>
                <Check outline size={24} />
              </S.SelectedIntegration>
            );
          })}
        </S.SelectedIntegrationsWrapper>
      </S.AddIntegrationButton>

      <S.ExamplesText>Ex.: Facebook, Google, Taboola</S.ExamplesText>
    </S.Wrapper>
  );
};

export default AddIntegration;
