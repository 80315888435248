import {
  CartPanda,
  Check,
  NuvemShop,
  Hubsale,
  Istpay,
  Woocommerce,
  Shopify,
  Kiwify,
  Cloudfox,
  Evermart,
  Yever,
  Abmex,
  DomPagamentos,
} from '@profitfy/pakkun-icons';

interface IParseIntegrationResponse {
  id: string;
  name: string;
  icon: any;
}

export const parseIntegration = (name?: string): IParseIntegrationResponse => {
  if (!name) {
    return {
      id: 'empty',
      name: '',
      icon: Check,
    };
  }

  const cartPanda = name.match(/Cartpanda/);
  const nuvemShop = name.match(/Nuvemshop/);
  const doppus = name.match(/Doppus/);
  const istPay = name.match(/Istpay/);
  const wooCommerce = name.match(/Woocommerce/);
  const shopify = name.match(/Shopify/);
  const kiwify = name.match(/Kiwify/);
  const cloudFox = name.match(/Cloudfox/);
  const evermart = name.match(/Evermart/);
  const yever = name.match(/Yever/);
  const abmex = name.match(/Abmex/);
  const domPagamentos = name.match(/Dompagamentos/);

  const defaultIntegration = {
    id: 'empty',
    name: '',
    icon: Check,
  };

  if (cartPanda) {
    return {
      id: 'cartpanda',
      name,
      icon: CartPanda,
    };
  }

  if (nuvemShop) {
    return {
      id: 'nuvemshop',
      name,
      icon: NuvemShop,
    };
  }

  if (doppus) {
    return {
      id: 'doppus',
      name,
      icon: Hubsale,
    };
  }

  if (istPay) {
    return {
      id: 'istpay',
      name,
      icon: Istpay,
    };
  }

  if (wooCommerce) {
    return {
      id: 'woocommerce',
      name,
      icon: Woocommerce,
    };
  }

  if (shopify) {
    return {
      id: 'shopify',
      name,
      icon: Shopify,
    };
  }

  if (kiwify) {
    return {
      id: 'kiwify',
      name,
      icon: Kiwify,
    };
  }

  if (cloudFox) {
    return {
      id: 'cloudFox',
      name,
      icon: Cloudfox,
    };
  }

  if (evermart) {
    return {
      id: 'evermart',
      name,
      icon: Evermart,
    };
  }

  if (yever) {
    return {
      id: 'yever',
      name,
      icon: Yever,
    };
  }

  if (abmex) {
    return {
      id: 'abmex',
      name,
      icon: Abmex,
    };
  }

  if (domPagamentos) {
    return {
      id: 'dompagamentos',
      name,
      icon: DomPagamentos,
    };
  }

  return defaultIntegration;
};
