import React from 'react';

import { ECurrency } from '@domain/enums/common/ECurrency';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { useNewKit } from '@helpers/hooks/pages/dashboard/kit/useNewKit';
import { currencyFormatter } from '@helpers/masks';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const KitCost: React.FC<any> = ({ register, errors }) => {
  const { kitCurrency, handleKitCurrency } = useNewKit();

  const handleCurrencyChange = React.useCallback(
    event => {
      handleKitCurrency(event.target.value);
    },
    [handleKitCurrency],
  );

  const handleCurrencyAmountChange = React.useCallback(event => {
    event.target.value = currencyFormatter(event.target.value);
  }, []);

  return (
    <S.Wrapper>
      <S.Title fontWeight={EHeadingWeight.REGULAR} type={EHeadingSize.H5}>
        Custo do kit
      </S.Title>

      <S.InputGroup>
        <S.Label>Moeda</S.Label>

        <S.RadioLabel>
          <S.Radio
            defaultChecked
            value={ECurrency.USD}
            name="currency_type"
            id="usd_option"
            onChange={handleCurrencyChange}
          />
          <S.RadioText>USD (Dólar)</S.RadioText>
        </S.RadioLabel>

        <S.RadioLabel>
          <S.Radio
            value={ECurrency.BRL}
            name="currency_type"
            id="brl_option"
            onChange={handleCurrencyChange}
          />
          <S.RadioText>BRL (Real)</S.RadioText>
        </S.RadioLabel>
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>Adicionar custo</S.Label>
        <S.Input
          {...register('kit_cost')}
          type="text"
          prependText={kitCurrency === ECurrency.USD ? '$' : 'R$'}
          placeholder="00,00"
          onChange={handleCurrencyAmountChange}
        />
        {errors.kit_cost && <Text isErrorFeedback>{errors.kit_cost.message}</Text>}
      </S.InputGroup>
    </S.Wrapper>
  );
};

export default KitCost;
