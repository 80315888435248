import { ApiService } from '@services/api';
import { API_DOMAIN } from '@constants/api';
import { IDeleteVariantKitItemProps } from '@domain/interfaces/dashboard/Kit/variantKitItem';

export class VariantKitItemService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public deleteVariantKitItem({
    storeAliasId,
    variantKitAliasId,
    variantKitItemAliasId,
  }: IDeleteVariantKitItemProps): Promise<any> {
    return this.apiService.delete(
      `/api/v1/users/stores/${storeAliasId}/variant-kits/${variantKitAliasId}/variant-kit-items/${variantKitItemAliasId}`,
    );
  }
}

const variantKitItemService = new VariantKitItemService(API_DOMAIN);

export default variantKitItemService;
