import styled from 'styled-components/macro';

import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const NeighborhoodAndComplementWrapper = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
  flex-wrap: wrap;

  & > div:first-child {
    max-width: 324px;
    width: 100%;
  }

  & > div:last-child {
    max-width: 324px;
    width: 100%;
  }
`;

export const StreetAndNumberWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 24px;

  & > div:first-child {
    max-width: 452px;
    width: 100%;
  }

  & > div:last-child {
    max-width: 195px;
    width: 100%;
  }
`;

export const ZipcodeAndCityAndStateWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 24px;

  & > div:first-child {
    max-width: 208px;
    width: 100%;
  }

  & > div:nth-child(2) {
    max-width: 208px;
    width: 100%;
  }

  & > div:last-child {
    max-width: 208px;
    width: 100%;
  }
`;

export const TextField = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)``;

export const FormWrapper = styled.div``;

export const Title = styled(HeadingBase)``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 32px;
`;

export const AddressWrapper = styled.div``;
