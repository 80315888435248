import React from 'react';

import { useCustomValuesCategories } from '@helpers/hooks/pages/dashboard/customValues/useCustomValuesCategories';
import PageError from '@components/common/core/Utils/PageError';
import Header from '../Header';
import Categories from '../Categories';
import CreateCategorySidemodal from '../CreateCategorySidemodal';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const {
    isLoadingCustomValuesCategories,
    isValidatingCustomValuesCategories,
    customValuesCategoriesError,
    mutateCustomValuesCategories,
  } = useCustomValuesCategories();

  if (
    customValuesCategoriesError &&
    !isLoadingCustomValuesCategories &&
    !isValidatingCustomValuesCategories
  ) {
    return <PageError mutate={mutateCustomValuesCategories} />;
  }

  return (
    <S.Container>
      <Header />

      <Categories />

      <CreateCategorySidemodal />
    </S.Container>
  );
};

export default Wrapper;
