import styled, { css } from 'styled-components/macro';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { IHeadStyleProps } from '@domain/interfaces/components/ITable';

export const Head = styled.th<IHeadStyleProps>`
  color: ${({ theme }) => theme.colors.font};
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: 500;
  font-size: 0.875rem;
  text-align: left;

  ${({ textAlign }) =>
    textAlign === EHeadAlign.CENTER &&
    css`
      text-align: center;
    `};

  ${({ textAlign }) =>
    textAlign === EHeadAlign.RIGHT &&
    css`
      text-align: right;
    `};

  ${({ upperCase }) =>
    upperCase &&
    css`
      text-transform: uppercase;
    `};
`;
