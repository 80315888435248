import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const Title = styled(HeadingBase)``;

export const Header = styled.div`
  margin-top: 40px;
  margin-bottom: 24px;
`;

export const Container = styled.div`
  max-width: 768px;
  width: 100%;
  padding-bottom: 104px;
`;
