import styled from 'styled-components/macro';

import SideModalBase from '@components/common/core/DataDisplay/SideModal';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import SelectBase from '@components/common/core/Inputs/Select';
import DayPickerBase from '@components/common/core/Utils/DayPicker';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';

export const TooltipText = styled(TextBase)``;

export const TooltipTextWrapper = styled.div`
  max-width: 328px;
  width: 100%;
`;

export const Tooltip = styled(CustomTooltipBase)``;

export const DayPicker = styled(DayPickerBase)`
  width: 100%;

  & > div {
    width: 100%;

    & > div:first-child {
      width: 100%;

      & > button {
        width: 100%;
      }
    }
  }
`;

export const Select = styled(SelectBase)`
  & > label > select {
    width: 508px;
  }
`;

export const Label = styled(LabelBase)``;

export const AmountLabel = styled(LabelBase)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Input = styled(TextFieldBase)``;

export const InputGroup = styled(InputGroupBase)`
  max-width: 508px;
  width: 100%;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 32px;
  }
`;

export const Product = styled.li`
  color: ${({ theme }) => theme.colors.font};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 0.875rem;
  line-height: 120%;
  font-weight: 400;

  &::before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.font};
  }
`;

export const ProductsList = styled.ul`
  margin: unset;
  margin-bottom: 24px;
  padding-left: 24px;
`;

export const Text = styled(TextBase)``;

export const ProductsWrapper = styled.div``;

export const Title = styled(HeadingBase)``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
  margin-bottom: 16px;
`;

export const SideModal = styled(SideModalBase)``;
