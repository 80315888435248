import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { numberFormatter } from '@helpers/masks';
import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { ETextType } from '@domain/enums/components/EText';

import PieChart from './PieChart';
import HoverCard from '../HoverCard';

import * as S from './styles';

const CreditCard: React.FC = () => {
  const { financialDetailsReport } = useFinancialDashboard();

  const { card } = financialDetailsReport;

  return (
    <S.Card>
      <S.Content>
        <S.Title fontWeight={EHeadingWeight.REGULAR} type={EHeadingSize.H6}>
          Cartões
        </S.Title>

        <S.DataWrapper>
          <S.ChartWrapper>
            <PieChart />
            <S.TextAndPercentageWrapper>
              <S.ChartText>Conversão</S.ChartText>
              <S.ChartPercentage>{`${numberFormatter(
                card.conversion,
                0,
                true,
              )}%`}</S.ChartPercentage>
            </S.TextAndPercentageWrapper>
          </S.ChartWrapper>

          <S.OrdersDataWrapper>
            <S.OrdersStatusWrapper>
              <S.StatusWrapper>
                <S.StatusText>Aprovados</S.StatusText>
              </S.StatusWrapper>

              <HoverCard amount={card.paid.amount} quantity={card.paid.quantity}>
                <S.OrdersAmount type={ETextType.P}>{`R$ ${numberFormatter(card.paid.amount, 2)} (${
                  card.paid.quantity
                })`}</S.OrdersAmount>
              </HoverCard>
            </S.OrdersStatusWrapper>

            <S.OrdersStatusWrapper>
              <S.StatusWrapper>
                <S.StatusText>Em análise</S.StatusText>
              </S.StatusWrapper>

              <HoverCard amount={card.in_analysis.amount} quantity={card.in_analysis.quantity}>
                <S.OrdersAmount type={ETextType.P}>{`R$ ${numberFormatter(
                  card.in_analysis.amount,
                  2,
                )} (${card.in_analysis.quantity})`}</S.OrdersAmount>
              </HoverCard>
            </S.OrdersStatusWrapper>

            <S.CustomTooltip>
              <S.CustomTooltip.Trigger>
                <S.OrdersStatusWrapper>
                  <S.StatusWrapper>
                    <S.StatusText>Outros</S.StatusText>
                  </S.StatusWrapper>

                  <S.OrdersAmount type={ETextType.P}>{`R$ ${numberFormatter(
                    card.other.total.amount,
                    2,
                  )} (${card.other.total.quantity})`}</S.OrdersAmount>
                </S.OrdersStatusWrapper>
              </S.CustomTooltip.Trigger>

              <S.CustomTooltipContent side="top" sideOffset={5}>
                <S.TooltipContent>
                  <S.TooltipValueWrapper>
                    <S.TooltipLabel>Recusado:</S.TooltipLabel>
                    <S.TooltipValue>{`R$ ${numberFormatter(card.other.rejected.amount, 2)} (${
                      card.other.rejected.quantity
                    })`}</S.TooltipValue>
                  </S.TooltipValueWrapper>

                  <S.TooltipValueWrapper>
                    <S.TooltipLabel>Devolvido:</S.TooltipLabel>
                    <S.TooltipValue>{`R$ ${numberFormatter(card.other.refunded.amount, 2)} (${
                      card.other.refunded.quantity
                    })`}</S.TooltipValue>
                  </S.TooltipValueWrapper>

                  <S.TooltipValueWrapper>
                    <S.TooltipLabel>Chargeback:</S.TooltipLabel>
                    <S.TooltipValue>{`R$ ${numberFormatter(card.other.charged_back.amount, 2)} (${
                      card.other.charged_back.quantity
                    })`}</S.TooltipValue>
                  </S.TooltipValueWrapper>
                </S.TooltipContent>

                <S.CustomTooltip.Arrow />
              </S.CustomTooltipContent>
            </S.CustomTooltip>
          </S.OrdersDataWrapper>
        </S.DataWrapper>
      </S.Content>
    </S.Card>
  );
};

export default CreditCard;
