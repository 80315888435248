export const getHandleColorSwitchNode = (index: number): string => {
  const colors = {
    1: '#D9DDE9',
    2: '#A7AFC6',
    3: '#838BA1',
    4: '#515562',
  };

  const defaultColor = '#D9DDE9';

  return colors[index] || defaultColor;
};
