import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.Wrapper>
      <S.GoBackWrapper>
        <Skeleton width="100%" height={28} />
      </S.GoBackWrapper>

      <S.TitleAndCategoryWrapper>
        <S.TitleWrapper>
          <Skeleton width="100%" height={28} />
        </S.TitleWrapper>

        <S.NewCategoryWrapper>
          <Skeleton width="100%" height={28} />
        </S.NewCategoryWrapper>
      </S.TitleAndCategoryWrapper>
    </S.Wrapper>
  );
};

export default SkeletonLoading;
