export enum ENodeType {
  WEBHOOK = 'WEBHOOK',
  WAIT = 'WAIT',
  IF = 'IF',
  SWITCH = 'SWITCH',
  HTTP_REQUEST = 'HTTP_REQUEST',
  WHATSAPP = 'WHATSAPP',
  ABANDONED_CART = 'ABANDONED_CART',
  END = 'END',
  REJECTED_PAYMENT = 'REJECTED_PAYMENT',
  PIX = 'PIX',
  BOLETO = 'BOLETO',
  UPDATED_FULFILLMENT = 'UPDATED_FULFILLMENT',
  SIMPLE_SHIPMENT = 'SIMPLE_SHIPMENT',

  INTERNAL_ORDER_STATUS = 'INTERNAL_ORDER_STATUS',
  INTERNAL_WORKFLOW_STATUS = 'INTERNAL_WORKFLOW_STATUS',
  INTERNAL_SELL_RECOVERED_STATUS = 'INTERNAL_SELL_RECOVERED_STATUS',
}
