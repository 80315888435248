import React from 'react';

import * as S from './styles';

const BlurredContent: React.FC = () => {
  return (
    <>
      <S.ContentWrapper>
        <S.Content>
          <S.LabelText>Receita</S.LabelText>
          <S.AmountText>R$ 00,00</S.AmountText>
        </S.Content>

        <S.Content>
          <S.LabelText>CPA</S.LabelText>
          <S.AmountText>R$ 00,00</S.AmountText>
        </S.Content>
      </S.ContentWrapper>

      <S.TotalLabel>Total</S.TotalLabel>
    </>
  );
};

export default BlurredContent;
