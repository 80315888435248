import React from 'react';

import { CouponProvider } from '@helpers/hooks/common/coupons/useCoupon';

import Wrapper from '@components/Dashboard/AdminPanel/Coupon/Wrapper';

const Coupon: React.FC = () => {
  return (
    <CouponProvider>
      <Wrapper />
    </CouponProvider>
  );
};

export default Coupon;
