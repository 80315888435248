import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { IParams } from '@domain/interfaces/IParams';
import { IWorkflows } from '@domain/interfaces/common/automations/IWorkflows';

import { useDowngrade } from '@helpers/hooks/common/store/subscription/useDowngrade';
import { useSubscription } from '@helpers/hooks/common/store/subscription/useSubscription';

import { getWorkflowTriggerIcon } from '@helpers/utils/dashboard/automations/AutomationsManager/automationsManagerUtils';

import { ALL_PLANS } from '@constants/plans';

import NextStep from '../NextStep';

import * as S from './styles';

const Workflows: React.FC = () => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { selectedPlanIdentifier } = useSubscription();
  const {
    storeWorkflows,
    selectedStoreWorkflows,
    handleSelectedStoreWorkflows,
    maxWorkflows,
  } = useDowngrade();

  const selectedPlan =
    (ALL_PLANS.find(
      plan =>
        plan.identifier === selectedPlanIdentifier ||
        plan.quarterIdentifier === selectedPlanIdentifier ||
        plan.semiAnualIdentifier === selectedPlanIdentifier,
    ) as typeof ALL_PLANS[number]) || undefined;

  const selectedPlanWorkflows =
    selectedPlan?.benefits.maxWorkflows === undefined ? Infinity : selectedPlan?.marketingProvider;

  const hasToDowngradeWorkflows = storeWorkflows.length > (selectedPlanWorkflows || 0);

  const onChange = React.useCallback(
    event => {
      if (event.target) return;

      const storeWorkflow = event.value;

      const foundWorkflow = selectedStoreWorkflows.find(
        workflow => workflow.workflow.alias_id === storeWorkflow,
      );
      const foundStoreWorkflow = storeWorkflows.find(
        workflow => workflow.workflow.alias_id === storeWorkflow,
      ) as IWorkflows;

      if (foundWorkflow) {
        const filteredWorkflows = selectedStoreWorkflows.filter(
          workflow => workflow.workflow.alias_id !== storeWorkflow,
        );
        handleSelectedStoreWorkflows(filteredWorkflows);
      } else {
        handleSelectedStoreWorkflows([...selectedStoreWorkflows, foundStoreWorkflow]);
      }
    },
    [handleSelectedStoreWorkflows, selectedStoreWorkflows, storeWorkflows],
  );

  React.useEffect(() => {
    if (!hasToDowngradeWorkflows) {
      history.push(`/${storeAliasId}/subscription/address`);
    }
  }, [hasToDowngradeWorkflows, history, storeAliasId]);

  const hasExceededQuantity = selectedStoreWorkflows.length > maxWorkflows;

  return (
    <S.Wrapper>
      <S.Title type={EHeadingSize.H3}>Automações</S.Title>

      <S.Description>Selecione as automações que deseja manter:</S.Description>

      <S.AdProvidersOptionsWrapper>
        {storeWorkflows.map(workflow => {
          const {
            icon: triggerIcon,
            backgroundColor: triggerIconBackgroundColor,
            name: triggerName,
          } = getWorkflowTriggerIcon(workflow.workflow.type);

          return (
            <S.ProviderOptionGroup key={workflow.workflow.alias_id}>
              <S.Option htmlFor={`option-${workflow.workflow.alias_id}`}>
                <S.Tooltip>
                  <S.Tooltip.Trigger>
                    <S.TriggerIconWrapper color={triggerIconBackgroundColor}>
                      {triggerIcon}
                    </S.TriggerIconWrapper>
                  </S.Tooltip.Trigger>

                  <S.Tooltip.Content side="bottom" sideOffset={5}>
                    <S.TriggerTooltipContentText>{triggerName}</S.TriggerTooltipContentText>
                    <S.Tooltip.Arrow />
                  </S.Tooltip.Content>
                </S.Tooltip>

                <S.ProviderName>{workflow.workflow.name}</S.ProviderName>
              </S.Option>

              <S.Checkbox
                id={`option-${workflow.workflow.alias_id}`}
                value={workflow.workflow.alias_id}
                onChange={onChange}
              />
            </S.ProviderOptionGroup>
          );
        })}
      </S.AdProvidersOptionsWrapper>

      <S.SelectedQuantity
        size={ETextSize.B5}
        weight={ETextWeight.MEDIUM}
        hasExceededQuantity={hasExceededQuantity}
      >
        <mark>{selectedStoreWorkflows.length}</mark>
        {`/${maxWorkflows} selecionada(s)`}
      </S.SelectedQuantity>

      <NextStep />
    </S.Wrapper>
  );
};

export default Workflows;
