import React from 'react';

import { useEditVariant } from '@helpers/hooks/pages/dashboard/kit/useEditVariant';
import { IVariantCardProps } from '@domain/interfaces/dashboard/Kit/editVariant';
import BlurredVariant from './BlurredVariant';
import FocusedVariant from './FocusedVariant';

import * as S from './styles';

const Variant: React.FC<IVariantCardProps> = ({ variant, index }) => {
  const { isCardExpanded, handleIndex, handleExistingVariant, initialVariant } = useEditVariant();

  React.useEffect(() => {
    if (index) {
      handleIndex(index);
    }

    if (variant?.variant) {
      handleExistingVariant(variant);
    }
  }, [variant, index, handleExistingVariant, handleIndex]);

  return (
    <S.Wrapper>
      {isCardExpanded || !initialVariant ? <FocusedVariant /> : <BlurredVariant />}
    </S.Wrapper>
  );
};

export default Variant;
