import React from 'react';
import { Sync } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';

import { IPeriod } from '@domain/interfaces/dashboard/ProductCostDetails/IHistoricSidemodal';
import { useDate } from '@helpers/hooks/useDate';
import { useVariantHistoric } from '@helpers/hooks/pages/dashboard/productCostDetails/variantHistoric';
import Form from '@components/common/core/Inputs/Form';
import { currencyFormatter, numberFormatter, currencyToNumber } from '@helpers/masks';
import Save from './Save';

import * as S from './styles';

const SecondStep: React.FC = () => {
  const {
    isLoadingVariantCosts,
    isLoadingDefaultVariantCost,
    defaultVariantCostError,
    variantCostsError,
    variantCostToEdit,
    isUpdatingVariantCost,
    updateVariantCost,
  } = useVariantHistoric();
  const { handleSubmit, register } = useForm({ mode: 'onBlur' });

  const costInput = register('cost');

  const { utcToZonedTime, format } = useDate();

  const [period, setPeriod] = React.useState<IPeriod>({
    startDate: utcToZonedTime(new Date()),
    endDate: utcToZonedTime(new Date()),
  });

  const onSubmit = React.useCallback(
    async data => {
      if (variantCostToEdit) {
        await updateVariantCost({
          ...variantCostToEdit,
          cost: currencyToNumber(data.cost),
          currency: data.currency,
          start_date: format(period.startDate, 'yyyy-MM-dd'),
          end_date: format(period.endDate, 'yyyy-MM-dd'),
        });
      }
    },
    [format, updateVariantCost, variantCostToEdit, period],
  );

  React.useEffect(() => {
    if (variantCostToEdit && variantCostToEdit.start_date && variantCostToEdit.end_date) {
      setPeriod({
        startDate: utcToZonedTime(variantCostToEdit.start_date),
        endDate: utcToZonedTime(variantCostToEdit.end_date),
      });
    }
  }, [variantCostToEdit, utcToZonedTime]);

  if (isLoadingVariantCosts || isLoadingDefaultVariantCost) {
    return <div>Loading...</div>;
  }

  if (defaultVariantCostError || variantCostsError) {
    return <div>Error...</div>;
  }

  const maxDate = utcToZonedTime(new Date());

  return (
    <>
      <S.Header>
        <Sync size={52} />
        <S.Title>Histórico de Variante</S.Title>
      </S.Header>

      <S.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <>
            <S.InputGroup>
              <S.Label>Insira o custo</S.Label>

              <S.Input
                {...costInput}
                type="text"
                placeholder="0,00"
                defaultValue={numberFormatter(variantCostToEdit?.cost || 0, 2)}
                onChange={event => {
                  costInput.onChange(event);

                  const { value } = event.target;

                  event.target.value = currencyFormatter(value);
                }}
              />
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Selecione a moeda</S.Label>

              <S.Select {...register('currency')} defaultValue={variantCostToEdit?.currency}>
                <S.Option value="BRL">BRL</S.Option>
                <S.Option value="USD">USD</S.Option>
              </S.Select>
            </S.InputGroup>

            <S.InputGroup>
              <S.DateRangePicker period={period} setPeriod={setPeriod} maxDate={maxDate} />
            </S.InputGroup>

            <Save
              isSaveButtonDisabled={isUpdatingVariantCost}
              isSubmitting={isUpdatingVariantCost}
            />
          </>
        </Form>
      </S.Body>
    </>
  );
};

export default SecondStep;
