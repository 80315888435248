import React from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components/macro';
import { SkeletonTheme } from 'react-loading-skeleton';

import darkTheme from '@styles/themes/dark/theme';
import lightTheme from '@styles/themes/light/theme';
import { IThemeProvider } from '@domain/interfaces/components/ITheme';
import { useLocalStorage } from '@helpers/hooks/useLocalStorage';

const ThemeContext = React.createContext<IThemeProvider | null>(null);

export const ThemeProvider: React.FC = ({ children }) => {
  const [userTheme, setUserTheme] = useLocalStorage('user-theme', 'dark');

  const handleTheme = React.useCallback(() => {
    if (userTheme === 'dark') {
      setUserTheme('light');
    } else {
      setUserTheme('dark');
    }
  }, [userTheme, setUserTheme]);

  return (
    <ThemeContext.Provider value={{ handleTheme, userTheme }}>
      <StyledComponentsThemeProvider theme={userTheme === 'dark' ? darkTheme : lightTheme}>
        <SkeletonTheme
          color={userTheme === 'dark' ? '#252931' : '#E8E9ED'}
          highlightColor={userTheme === 'dark' ? '#303440' : '#F0F1F4'}
        >
          {children}
        </SkeletonTheme>
      </StyledComponentsThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useUserTheme = (): IThemeProvider => {
  const context = React.useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used within ThemeProvider');
  }

  return context;
};
