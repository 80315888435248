import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/CustomModal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 32px;
`;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)`
  & > div {
    background-color: ${({ theme }) => theme.colors.gray[5]};
  }
`;

export const Modal = styled(ModalBase)`
  & > div:first-child {
    & > span {
      display: flex;
      align-items: center;
      gap: 8px;

      & > svg {
        margin-top: 3px;
      }
    }
  }
`;

export const Text = styled(TextBase)`
  display: block;
  margin-bottom: 32px;
`;

export const Button = styled(ButtonBase)`
  margin-left: auto;
`;
