import React from 'react';

import { useCustomValuesOut } from '@helpers/hooks/pages/dashboard/customValues/useCustomValuesOut';
import Header from './Header';
import Body from './Body';

import * as S from './styles';

const EditCustomValueSidemodal: React.FC = () => {
  const {
    isEditCustomValueSidemodalOpen,
    handleEditCustomValueSidemodalOpen,
  } = useCustomValuesOut();

  return (
    <>
      {isEditCustomValueSidemodalOpen ? (
        <S.Sidemodal
          isOpen={isEditCustomValueSidemodalOpen}
          toggle={handleEditCustomValueSidemodalOpen}
        >
          <Header />

          <Body />
        </S.Sidemodal>
      ) : (
        <></>
      )}
    </>
  );
};

export default EditCustomValueSidemodal;
