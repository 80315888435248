import React from 'react';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import { IPaymentContext } from '@domain/interfaces/common/payment/IPayment';

import subscriptionService from '@services/pages/subscription/subscription';
import plansService from '@services/common/plans/plans';

const PaymentContext = React.createContext<IPaymentContext | null>(null);

export const PaymentProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();

  const {
    subscription,
    isLoading: isLoadingSubscription,
    isValidating: isValidatingSubscription,
    mutate: mutateSubscription,
    error: subscriptionError,
  } = subscriptionService.getSubscription({ storeAliasId });
  const {
    plans,
    isLoading: isLoadingPlans,
    isValidating: isValidatingPlans,
    mutate: mutatePlans,
    error: plansError,
  } = plansService.listAllPlans({ storeAliasId });

  return (
    <PaymentContext.Provider
      value={{
        isLoadingSubscription,
        isValidatingSubscription,
        mutateSubscription,
        subscription,
        subscriptionError,
        isLoadingPlans,
        isValidatingPlans,
        mutatePlans,
        plans,
        plansError,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export const usePayment = (): IPaymentContext => {
  const context = React.useContext(PaymentContext);

  if (!context) {
    throw new Error('usePayment must be used within PaymentProvider');
  }

  return context;
};
