import React from 'react';

import { EStatus } from '@domain/enums/subscription/ESubscription';
import { IStorePermissionsProvider } from '@domain/interfaces/hooks/IStorePermissions';

import { useStoreSubscription } from '../useStoreSubscription';
import { useDate } from '../useDate';

const StorePermissionsContext = React.createContext<IStorePermissionsProvider | null>(null);

export const StorePermissionsProvider: React.FC = ({ children }) => {
  const { subscription, hasAllPermissions } = useStoreSubscription();
  const { utcToZonedTime, differenceInDays, addDays } = useDate();

  const maxDateLimiter = React.useCallback(() => {
    const isFreePlan = subscription && subscription.plan?.identifier.includes('free');

    if (isFreePlan && subscription.exceeded_order_limit_at && !hasAllPermissions) {
      return utcToZonedTime(new Date(subscription.exceeded_order_limit_at));
    }

    const isSubscriptionPeriodEndedAtAfterNow = subscription?.period_ended_at
      ? differenceInDays(utcToZonedTime(new Date()), new Date(subscription.period_ended_at)) >= 0
      : true;

    if (subscription?.status === EStatus.CANCELED && isSubscriptionPeriodEndedAtAfterNow) {
      return utcToZonedTime(new Date());
    }

    if (
      subscription?.status === EStatus.CANCELED &&
      subscription?.period_ended_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(addDays(new Date(subscription.period_ended_at), 1));
    }

    if (
      subscription?.status === EStatus.CANCELED &&
      subscription?.canceled_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(subscription.canceled_at);
    }

    if (subscription && subscription.status === EStatus.TRIAL_ENDED && !hasAllPermissions) {
      return utcToZonedTime(new Date(subscription.period_ended_at));
    }

    if (
      subscription &&
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_CREATE &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(new Date(subscription.period_ended_at));
    }

    if (
      subscription &&
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_CREATE_FAILED &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(new Date(subscription.period_ended_at));
    }

    if (
      subscription &&
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_UPDATE &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(new Date(subscription.period_ended_at));
    }

    return utcToZonedTime(new Date());
  }, [hasAllPermissions, subscription, utcToZonedTime, differenceInDays, addDays]);

  return (
    <StorePermissionsContext.Provider value={{ maxDateLimiter }}>
      {children}
    </StorePermissionsContext.Provider>
  );
};

export const useStorePermissions = (): IStorePermissionsProvider => {
  const context = React.useContext(StorePermissionsContext);

  if (!context) {
    throw new Error('useStorePermissions must be used within StorePermissionsProvider');
  }

  return context;
};
