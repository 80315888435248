import React from 'react';

import { ETextSize } from '@domain/enums/components/EText';
import { IFeedOrderProps } from '@domain/interfaces/dashboard/Feed/IFeed';
import { useDate } from '@helpers/hooks/useDate';

import * as S from './styles';

export const Order: React.FC<IFeedOrderProps> = ({ order }) => {
  const { utcToZonedTime, format } = useDate();

  const firstOrderLineItem = order?.line_items[0];

  const hasProviderUrl = order?.provider_detail_url;

  return (
    <S.Wrapper>
      <S.Tooltip>
        <S.Tooltip.Trigger>
          {hasProviderUrl ? (
            <S.OrderNumberLink
              href={order?.provider_detail_url}
              target="_blank"
            >{`Pedido ${order?.name}`}</S.OrderNumberLink>
          ) : (
            <S.OrderNumber>{`Pedido ${order?.name}`}</S.OrderNumber>
          )}
        </S.Tooltip.Trigger>

        <S.Tooltip.Content side="top" sideOffset={5}>
          <S.TooltipText>{`Pedido ${order?.name}`}</S.TooltipText>
          <S.Tooltip.Arrow />
        </S.Tooltip.Content>
      </S.Tooltip>

      <S.Tooltip>
        <S.Tooltip.Trigger>
          <S.FirstOrderItem size={ETextSize.B5}>
            {firstOrderLineItem.product?.name}
          </S.FirstOrderItem>
        </S.Tooltip.Trigger>

        <S.Tooltip.Content side="top" sideOffset={5}>
          {order?.line_items.map(lineItem => (
            <S.TooltipText key={lineItem.alias_id}>{lineItem.product?.name}</S.TooltipText>
          ))}
          <S.Tooltip.Arrow />
        </S.Tooltip.Content>
      </S.Tooltip>

      <S.OrderDate size={ETextSize.B5}>
        {format(utcToZonedTime(order?.provider_created_at), 'dd/MM/yyyy HH:mm:ss')}
      </S.OrderDate>
    </S.Wrapper>
  );
};

export default Order;
