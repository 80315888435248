import GoBack from '@components/common/core/Utils/GoBack';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ECreateStoreStep, EEcommerceOptions } from '@domain/enums/onboarding/ECreateStore';
import React from 'react';
import * as S from './styles';

interface ISecondStepProps {
  handleSecondAnswer: (answer: string) => void;
  selectedAnswer: EEcommerceOptions;
  goBack: (backStep: ECreateStoreStep) => void;
}

const SecondStep: React.FC<ISecondStepProps> = ({ handleSecondAnswer, selectedAnswer, goBack }) => {
  const onRadioChange = React.useCallback(
    event => {
      handleSecondAnswer(event.target.value);
    },
    [handleSecondAnswer],
  );

  const handleGoBack = React.useCallback(() => goBack(ECreateStoreStep.FIRST_STEP), [goBack]);

  return (
    <>
      <GoBack
        text="Voltar"
        onClick={handleGoBack}
        size={16}
        type={EHeadingSize.H6}
        fontWeight={EHeadingWeight.REGULAR}
      />

      <S.Question>Como você opera o seu e-commerce?</S.Question>

      <S.RadioGroup>
        <S.RadioLabel checked={selectedAnswer === EEcommerceOptions.VIRTUAL_STORE_ONLY}>
          <S.Radio
            name="ecommerce_type"
            value={EEcommerceOptions.VIRTUAL_STORE_ONLY}
            onChange={onRadioChange}
            id={EEcommerceOptions.VIRTUAL_STORE_ONLY}
            defaultChecked={selectedAnswer === EEcommerceOptions.VIRTUAL_STORE_ONLY}
          />
          <S.RadioText>Apenas loja virtual</S.RadioText>
        </S.RadioLabel>

        <S.RadioLabel checked={selectedAnswer === EEcommerceOptions.VIRTUAL_STORE_AND_MARKETPLACES}>
          <S.Radio
            name="ecommerce_type"
            value={EEcommerceOptions.VIRTUAL_STORE_AND_MARKETPLACES}
            onChange={onRadioChange}
            id={EEcommerceOptions.VIRTUAL_STORE_AND_MARKETPLACES}
            defaultChecked={selectedAnswer === EEcommerceOptions.VIRTUAL_STORE_AND_MARKETPLACES}
          />
          <S.RadioText>Loja virtual + marketplaces</S.RadioText>
        </S.RadioLabel>

        <S.RadioLabel checked={selectedAnswer === EEcommerceOptions.MARKETPLACES_ONLY}>
          <S.Radio
            name="ecommerce_type"
            value={EEcommerceOptions.MARKETPLACES_ONLY}
            onChange={onRadioChange}
            id={EEcommerceOptions.MARKETPLACES_ONLY}
            defaultChecked={selectedAnswer === EEcommerceOptions.MARKETPLACES_ONLY}
          />
          <S.RadioText>Apenas marketplaces</S.RadioText>
        </S.RadioLabel>
      </S.RadioGroup>
    </>
  );
};

export default SecondStep;
