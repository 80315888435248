import styled from 'styled-components/macro';

export const OrdersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Container = styled.div`
  max-width: 902px;
  width: 100%;
  padding-top: 50px;
`;
