import React from 'react';
import { CSVLink } from 'react-csv';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { endOfMonth, startOfMonth } from 'date-fns';
import { Overview } from '@profitfy/pakkun-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ETextSize } from '@domain/enums/components/EText';
import { EAlertVariant } from '@domain/enums/components/EAlert';
import { EButtonVariant } from '@domain/enums/components/EButton';
import { useConfig } from '@helpers/hooks/useConfig';
import { IParams } from '@domain/interfaces/IParams';
import { spreadSheetFormSchema } from '@helpers/validators/dashboard/main/spreadSheet/spreadSheet';
import { ISideModal } from '@domain/interfaces/dashboard/FinanceDashboard/SpreadSheet/ISideModal';
import { useToast } from '@helpers/hooks/useToast';

import {
  getSpreadSheetData,
  getSpreadSheetHeaders,
} from '@helpers/utils/dashboard/spreadSheet/exportSpreadSheet';

import Text from '@components/common/core/DataDisplay/Text';
import Form from '@components/common/core/Inputs/Form';
import financialDetailsService from '@services/pages/dashboard/financialDetails/financialDetails';

import { useDate } from '@helpers/hooks/useDate';

import * as S from './styles';

const SideModal: React.FC<ISideModal> = ({ toggle }) => {
  const csvRef = React.useRef<any>(null);

  const { format } = useDate();
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { selectedStore, analytics, user } = useConfig();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(spreadSheetFormSchema),
  });

  const [data, setData] = React.useState<any>([]);
  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);

  const createdAtStoreYear = Number(selectedStore?.created_at.substring(0, 4));
  const currentYear = new Date().getFullYear();

  const differenceOfYears = currentYear - createdAtStoreYear;

  const years = [...Array(differenceOfYears + 1).keys()];

  const yearSelectOptions = years.map((year: number) => (
    <S.Option key={createdAtStoreYear + year} value={createdAtStoreYear + year}>
      {createdAtStoreYear + year}
    </S.Option>
  ));

  const onSubmit = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      const month = formData.spread_sheet_month;
      const year = formData.spread_sheet_year;

      const referenceDate = new Date(Number(year), Number(month - 1));

      const startDate = format(startOfMonth(referenceDate), 'yyyy-MM-dd');
      const endDate = format(endOfMonth(referenceDate), 'yyyy-MM-dd');

      try {
        const response = await financialDetailsService.getFinancialTableByDayPromise({
          storeAliasId,
          startDate,
          endDate,
        });

        setData(response?.data?.financial_table_by_day);

        csvRef.current.link.click();

        toast.success('Planilha exportada com sucesso!');

        analytics?.track(
          'SpreadSheet Exported',
          {
            email: user?.email,
          },
          { context: { groupId: selectedStore?.alias_id } },
        );

        setIsSubmittingForm(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
    [format, storeAliasId, toast, analytics, selectedStore, user],
  );

  return (
    <S.SideModalContentWrapper>
      <S.Header>
        <Overview size={24} />
        <S.HeaderTitle fontWeight={EHeadingWeight.REGULAR}>Exportar para planilha</S.HeaderTitle>
      </S.Header>

      <S.Title fontWeight={EHeadingWeight.REGULAR} type={EHeadingSize.H5}>
        Descrição da funcionalidade
      </S.Title>

      <S.Description size={ETextSize.B5}>
        Você pode exportar seus dados de acordo com o mês selecionado abaixo. Essa ferramenta dará a
        liberdade para trabalhar com os dados da maneira que desejar.
      </S.Description>

      <S.Alert variant={EAlertVariant.WARNING}>
        Você poderá exportar os dados apenas de um mês por vez
      </S.Alert>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label>Selecione o mês que deseja exportar</S.Label>
          <S.MonthSelect {...register('spread_sheet_month')} name="spread_sheet_month">
            <S.Option value="01">Janeiro</S.Option>
            <S.Option value="02">Fevereiro</S.Option>
            <S.Option value="03">Março</S.Option>
            <S.Option value="04">Abril</S.Option>
            <S.Option value="05">Maio</S.Option>
            <S.Option value="06">Junho</S.Option>
            <S.Option value="07">Julho</S.Option>
            <S.Option value="08">Agosto</S.Option>
            <S.Option value="09">Setembro</S.Option>
            <S.Option value="10">Outubro</S.Option>
            <S.Option value="11">Novembro</S.Option>
            <S.Option value="12">Dezembro</S.Option>
          </S.MonthSelect>
          {errors.spread_sheet_month && (
            <Text isErrorFeedback>{errors.spread_sheet_month.message}</Text>
          )}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Selecione o ano que deseja exportar</S.Label>
          <S.YearSelect
            {...register('spread_sheet_year')}
            name="spread_sheet_year"
            defaultValue={String(currentYear)}
          >
            {yearSelectOptions}
          </S.YearSelect>
          {errors.spread_sheet_year && (
            <Text isErrorFeedback>{errors.spread_sheet_year.message}</Text>
          )}
        </S.InputGroup>

        <S.CancelAndExportButtonsWrapper>
          <S.CancelButton type="button" variant={EButtonVariant.TERTIARY} onClick={toggle}>
            Cancelar
          </S.CancelButton>

          <S.ExportButton type="submit" isLoading={isSubmittingForm} disabled={isSubmittingForm}>
            Exportar
          </S.ExportButton>

          <CSVLink
            ref={csvRef}
            data={getSpreadSheetData(data)}
            headers={getSpreadSheetHeaders(data)}
            filename="Profitfy-Financeiro"
          />
        </S.CancelAndExportButtonsWrapper>
      </Form>
    </S.SideModalContentWrapper>
  );
};

export default SideModal;
