import React from 'react';
import { Rocket } from '@profitfy/pakkun-icons';
import { useLocation, useHistory } from 'react-router-dom';

import { useConfig } from '@helpers/hooks/useConfig';
import { ETextWeight } from '@domain/enums/components/EText';
import { EButtonVariant } from '@domain/enums/components/EButton';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';

import * as S from './styles';

const ExceededOrdersAlert: React.FC = () => {
  const { user, analytics, selectedStore } = useConfig();
  const { pathname } = useLocation();
  const { setHideExceededAlert } = useStoreSubscription();
  const history = useHistory();

  const [storeAliasId, setStoreAliasId] = React.useState<string>('');

  const handleLearnMore = React.useCallback(() => {
    analytics?.track(
      'Extra Order Alert Clicked',
      {
        button_source: 'Saiba mais',
        email: user?.email,
      },
      { context: { groupId: selectedStore?.alias_id } },
    );

    history.push(`/${storeAliasId}/dashboard/subscription`);
  }, [history, storeAliasId, analytics, selectedStore, user]);

  const handleUnderstood = React.useCallback(() => {
    analytics?.track(
      'Extra Order Alert Clicked',
      {
        button_source: 'Ok, entendi',
        email: user?.email,
      },
      { context: { groupId: selectedStore?.alias_id } },
    );

    setHideExceededAlert(new Date().toISOString());
  }, [setHideExceededAlert, analytics, selectedStore, user]);

  React.useEffect(() => {
    const foundStoreAliasId = pathname.match(/store-([a-zA-Z0-9]+)/);

    if (foundStoreAliasId?.length) {
      setStoreAliasId(foundStoreAliasId[0]);
    }
  }, [pathname]);

  React.useEffect(() => {
    analytics?.track(
      'Extra Order Alert Viewed',
      {
        email: user?.email,
      },
      { context: { groupId: selectedStore?.alias_id } },
    );
  }, [analytics, selectedStore, user]);

  return (
    <S.Container>
      <S.Content>
        <S.IconAndTextWrapper>
          <Rocket size={32} />
          <S.TextWrapper>
            <S.CongratulationsText weight={ETextWeight.BOLD}>
              {`Parabéns, ${user.first_name}! Você ultrapassou o limite de Pedidos contratado, isso mostra que está crescendo.`}
            </S.CongratulationsText>
            <S.DescriptionText>
              Para melhorar sua experiência, vamos continuar processando seus pedidos adicionais
              para não perder nenhum deles!
            </S.DescriptionText>
          </S.TextWrapper>
        </S.IconAndTextWrapper>
        <S.ButtonsWrapper>
          <S.LearnMoreButton variant={EButtonVariant.SECONDARY} onClick={handleLearnMore}>
            Saiba mais
          </S.LearnMoreButton>
          <S.UnderstoodButton onClick={handleUnderstood}>Ok, entendi</S.UnderstoodButton>
        </S.ButtonsWrapper>
      </S.Content>
    </S.Container>
  );
};

export default ExceededOrdersAlert;
