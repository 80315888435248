import React from 'react';

import { EDropshippingProvider } from '@domain/enums/common/integrations/EDropshippingProvider';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import IntegrateForm from './IntegrateForm';
import LpqvConfig from './LPQVConfig';

const LPQV: React.FC = () => {
  const { storeIntegrations } = useStoreConfig();

  const { store_front } = storeIntegrations;

  const foundIntegration = store_front.find(
    integration => integration.name === EDropshippingProvider.LPQV,
  );

  if (foundIntegration?.is_active) {
    return <LpqvConfig />;
  }

  return <IntegrateForm />;
};

export default LPQV;
