import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';
import { Wallet } from 'phosphor-react';

export const PIX_STARTER = [
  {
    id: 'pix-starter-first-condition',
    title: 'Recuperação de Pix (1ª condição)',
    type: 'PIX',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Wallet,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá {{first_name}}!\n\nParabéns pelo seu pedido 🎉\n\nEle já foi separado e está pronto para ser enviado! 🚚\n\n📌 Detalhes do Pedido: #{{order_id}}\n{{products_with_variant}}\n💵 Valor total: {{total_amount}}\n\n🕗 Realize o pagamento do Pix dentro de 30 minutos para que o pedido não seja cancelado.📱',
      },
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content: '{{pix_code}}',
      },
    ],
  },
  {
    id: 'pix-starter-second-condition',
    title: 'Recuperação de Pix (2ª condição)',
    type: 'PIX',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Wallet,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá, {{first_name}}! 😊\n\nInfelizmente o seu prazo de pagamento expirou! 😥\n\nClique no link abaixo para fazer um novo pedido no nosso site: 🛒\n\n{{checkout_link}}\n\nE, se precisar de alguma ajuda, estamos aqui! 🤩',
      },
    ],
  },
];
