import { Palette } from 'phosphor-react';

import { ABANDONED_CART } from './messagesTemplates/abandonedCart';
import { ABANDONED_CART_2 } from './messagesTemplates/abandonedCart2';
import { ABANDONED_CART_FREE } from './messagesTemplates/abandonedCartFree';
import { ABANDONED_CART_STARTER } from './messagesTemplates/abandonedCartStarter';
import { BOLETO } from './messagesTemplates/boleto';
import { BOLETO_FREE } from './messagesTemplates/boletoFree';
import { BOLETO_STARTER } from './messagesTemplates/boletoStarter';
import { PIX } from './messagesTemplates/pix';
import { PIX_FREE } from './messagesTemplates/pixFree';
import { PIX_STARTER } from './messagesTemplates/pixStarter';
import { REFUSED_CREDIT_CARD } from './messagesTemplates/refusedCreditCard';
import { REFUSED_CREDIT_CARD_FREE } from './messagesTemplates/refusedCreditCardFree';
import { REFUSED_CREDIT_CARD_STARTER } from './messagesTemplates/refusedCreditCardStarter';
import { SIMPLE_SHIPMENT } from './messagesTemplates/simpleShipment';
import { UPDATED_FULFILLMENT } from './messagesTemplates/updatedFulfillment';

interface IMessageTemplates {
  id: string;
  title: string;
  type: string;
  category: string;
  description: string;
  icon: any;
  message_contents?: any[];
}

export const MESSAGES_TEMPLATES: IMessageTemplates[] = [
  {
    id: 'no-content',
    title: 'Em branco',
    type: 'NO_CONTENT',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Palette,
  },
  ...ABANDONED_CART,
  ...ABANDONED_CART_2,
  ...BOLETO,
  ...PIX,
  ...REFUSED_CREDIT_CARD,
  ...ABANDONED_CART_FREE,
  ...ABANDONED_CART_STARTER,
  ...BOLETO_FREE,
  ...PIX_FREE,
  ...REFUSED_CREDIT_CARD_FREE,
  ...REFUSED_CREDIT_CARD_STARTER,
  ...BOLETO_STARTER,
  ...PIX_STARTER,
  ...SIMPLE_SHIPMENT,
  ...UPDATED_FULFILLMENT,
];
