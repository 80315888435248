import React from 'react';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.GoBackButton>
      <S.Image>
        <Skeleton width="100%" height="100%" style={{ borderRadius: '50%' }} />
      </S.Image>

      <S.Text>
        <Skeleton width={475} height={23} />
      </S.Text>
    </S.GoBackButton>
  );
};

export default SkeletonLoading;
