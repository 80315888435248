import styled from 'styled-components/macro';

import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextBase from '@components/common/core/DataDisplay/Text';

export const OptionText = styled(TextBase)`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.font};
  margin-bottom: 10px;
  font-weight: 400;
  max-width: 320px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: unset;
  display: block;
`;

export const Checkbox = styled(CheckboxBase)``;

export const CheckboxLabel = styled(LabelBase)`
  margin: unset;
  cursor: pointer;
  line-height: 100%;
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const CheckboxGroup = styled(InputGroupBase)`
  margin: unset;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 4px) !important;
`;
