import React from 'react';
import { ChevronDown } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { useFinancialDetails } from '@helpers/hooks/pages/dashboard/financialDetails/useFinancialDetails';
import ColumnOption from './ColumnOption';

import * as S from './styles';

const ColumnsSelect: React.FC = () => {
  const theme = useTheme();
  const { columnsConfig } = useFinancialDetails();

  return (
    <S.Popover>
      <S.Popover.Trigger>
        <S.ColumnsSelectButton>
          Colunas
          <ChevronDown size={16} color={theme.colors.font} />
        </S.ColumnsSelectButton>
      </S.Popover.Trigger>

      <S.PopoverContent>
        <S.ColumnsOptionsWrapper>
          {columnsConfig.map(column => (
            <ColumnOption key={column.value} column={column} />
          ))}
        </S.ColumnsOptionsWrapper>
        <S.PopoverArrow />
      </S.PopoverContent>
    </S.Popover>
  );
};

export default ColumnsSelect;
