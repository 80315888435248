import styled from 'styled-components/macro';

import CardBase from '@components/common/core/Surfaces/Card';
import TextBase from '@components/common/core/DataDisplay/Text';

export const MetricName = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
  font-size: 0.875rem;
  line-height: 120%;
`;

export const MetricValue = styled(TextBase)`
  font-weight: 600;
  line-height: 120%;
  color: #fff;
`;

export const MetricTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  background-color: #24363f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
`;

export const MetricWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
`;

export const Card = styled(CardBase)`
  border: unset;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  padding: 24px 32px;
  box-sizing: border-box;
`;
