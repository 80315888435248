import React from 'react';
import { Bag } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';

import { INewLegalPersonSideModalProps } from '@domain/interfaces/dashboard/FinancialData/INewLelgalPersonSideModal';

import { useToast } from '@helpers/hooks/useToast';
import { useFinancialData } from '@helpers/hooks/pages/dashboard/financialData/useFinancialData';

import { cnpjFormatter, phone, getCnpjNumber, parseToRawPhone } from '@helpers/masks';

import legalPersonService from '@services/common/legalPerson/legalPerson';

import Form from '@components/common/core/Inputs/Form';

import * as S from './styles';

const NewLegalPersonSideModal: React.FC<INewLegalPersonSideModalProps> = ({ isOpen, toggle }) => {
  const { toast } = useToast();
  const { mutateLegalPerson } = useFinancialData();
  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm({
    mode: 'onBlur',
    // resolver: yupResolver(firstFormSchema),
  });

  const cnpjInput = register('cnpj');
  const phoneInput = register('phone');

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async data => {
      setIsSubmittingForm(true);

      try {
        await legalPersonService.createLegalPerson({
          data: {
            cnpj: getCnpjNumber(data?.cnpj),
            company_name: data?.company_name,
            phone_number: parseToRawPhone(data?.phone_number),
          },
        });
        await mutateLegalPerson();

        toast.success('Dados jurídicos cadastrados com sucesso!');

        toggle();
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsSubmittingForm(false);
      }
    },
    [toast, toggle, mutateLegalPerson],
  );

  return (
    <S.SideModal isOpen={isOpen} toggle={toggle}>
      <S.HeaderWrapper>
        <Bag size={50} />

        <S.Title>Cadastrar dados jurídicos</S.Title>
      </S.HeaderWrapper>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label>CNPJ</S.Label>
          <S.TextField
            {...cnpjInput}
            type="text"
            onChange={event => {
              cnpjInput.onChange(event);

              const { value } = event.target;

              event.target.value = cnpjFormatter(value);
            }}
          />
        </S.InputGroup>
        <S.InputGroup>
          <S.Label>Razão Social</S.Label>
          <S.TextField {...register('company_name')} type="text" />
        </S.InputGroup>
        <S.InputGroup>
          <S.Label>Telefone de Contato</S.Label>
          <S.TextField
            {...phoneInput}
            type="text"
            onChange={event => {
              phoneInput.onChange(event);

              const { value } = event.target;

              event.target.value = phone(value);
            }}
          />
        </S.InputGroup>
        <S.InputGroup>
          <S.Label>E-mail</S.Label>
          <S.TextField {...register('email')} type="text" />
        </S.InputGroup>

        <S.Button isLoading={isSubmittingForm} disabled={isSubmittingForm}>
          Finalizar cadastro
        </S.Button>
      </Form>
    </S.SideModal>
  );
};

export default NewLegalPersonSideModal;
