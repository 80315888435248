import React from 'react';

import { Pen, SimpleClose } from '@profitfy/pakkun-icons';
import { ILabelProps } from '@domain/interfaces/dashboard/ProductTag/ISideModalTags';
import { useTheme } from 'styled-components';
import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const Label: React.FC<ILabelProps> = ({
  isHovering,
  handleIsEditing,
  tagName,
  handleDeleteModalOpen,
}) => {
  const theme = useTheme();

  const { analytics, selectedStore } = useConfig();

  const onEditButtonClick = React.useCallback(() => {
    analytics?.track(
      'Product Tags Onboarding Edit Tag Button Clicked',
      {},
      {
        context: { groupId: selectedStore?.alias_id },
      },
    );

    handleIsEditing();
  }, [handleIsEditing, selectedStore, analytics]);

  return (
    <S.Wrapper>
      <S.TagName>{tagName}</S.TagName>

      <S.ButtonsWrapper>
        {isHovering && (
          <S.EditButton onClick={onEditButtonClick}>
            <Pen size={14} />
          </S.EditButton>
        )}

        <S.DeleteButton onClick={handleDeleteModalOpen}>
          <SimpleClose size={14} color={theme.colors.gray[2]} />
        </S.DeleteButton>
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export default Label;
