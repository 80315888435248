import React from 'react';

import { currencyFormatter, numberFormatter } from '@helpers/masks';

import { EOrderType } from '@domain/enums/common/EOrder';
import { IDefaultOrderFormProps } from '@domain/interfaces/dashboard/Orders/IEditOrder';

import Text from '@components/common/core/DataDisplay/Text';
import Option from '@components/common/core/Inputs/Select/Option';

import * as S from './styles';

const DefaultEditForm: React.FC<IDefaultOrderFormProps> = ({
  order,
  errors,
  register,
  setDate,
  date,
}) => {
  const handleAmountChange = React.useCallback(event => {
    event.target.value = currencyFormatter(event.target.value);
  }, []);

  return (
    <>
      <S.InputGroup>
        <S.Label>Tipo do pedido</S.Label>

        <S.Select {...register('type')} name="type" defaultValue={order.type}>
          <Option disabled value="" />
          <Option value={EOrderType.CREDIT_CARD}>Cartão de Crédito</Option>
          <Option value={EOrderType.BOLETO}>Boleto</Option>
          <Option value={EOrderType.PIX}>Pix</Option>
        </S.Select>

        {errors.type && <Text isErrorFeedback>{errors.type.message}</Text>}
      </S.InputGroup>

      <S.InputGroupWrapper>
        <S.InputGroup>
          <S.Label>Receita Líquida</S.Label>

          <S.Input
            {...register('gateway_amount')}
            type="text"
            name="gateway_amount"
            placeholder="Insira o valor"
            defaultValue={numberFormatter(order.gateway_amount || order.provider_amount || 0, 2)}
            onChange={handleAmountChange}
            prependText="R$"
          />

          {errors.gateway_amount && <Text isErrorFeedback>{errors.gateway_amount.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Custo do produto</S.Label>

          <S.Input
            {...register('cogs_amount')}
            type="text"
            name="cogs_amount"
            placeholder="opcional"
            defaultValue={numberFormatter(order.cogs_amount || 0, 2)}
            onChange={handleAmountChange}
            prependText="R$"
          />

          {errors.cogs_amount && <Text isErrorFeedback>{errors.cogs_amount.message}</Text>}
        </S.InputGroup>
      </S.InputGroupWrapper>

      <S.InputGroupWrapper>
        <S.InputGroup>
          <S.Label>Taxas do gateway</S.Label>

          <S.Input
            {...register('gateway_fee_amount')}
            type="text"
            name="gateway_fee_amount"
            placeholder="opcional"
            defaultValue={numberFormatter(order.gateway_fee_amount || 0, 2)}
            onChange={handleAmountChange}
            prependText="R$"
          />

          {errors.gateway_fee_amount && (
            <Text isErrorFeedback>{errors.gateway_fee_amount.message}</Text>
          )}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Frete do Pedido</S.Label>

          <S.Input
            {...register('shipping_cost_owner_amount')}
            type="text"
            name="shipping_cost_owner_amount"
            placeholder="opcional"
            defaultValue={numberFormatter(order.shipping_cost_owner_amount || 0, 2)}
            onChange={handleAmountChange}
            prependText="R$"
          />
          {errors.shipping_cost_owner_amount && (
            <Text isErrorFeedback>{errors.shipping_cost_owner_amount.message}</Text>
          )}
        </S.InputGroup>
      </S.InputGroupWrapper>

      <S.InputGroup>
        <S.Label>Taxas do infoproduto</S.Label>

        <S.Input
          {...register('info_product_fee_amount')}
          type="text"
          name="info_product_fee_amount"
          placeholder="opcional"
          defaultValue={numberFormatter(order.info_product_fee_amount || 0, 2)}
          onChange={handleAmountChange}
          prependText="R$"
        />

        {errors.info_product_fee_amount && (
          <Text isErrorFeedback>{errors.info_product_fee_amount.message}</Text>
        )}
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>Aprovado em:</S.Label>
        <S.DayPicker
          selectedDate={date}
          setSelectedDate={setDate}
          minDate={new Date(order.created_at)}
        />
      </S.InputGroup>
    </>
  );
};

export default DefaultEditForm;
