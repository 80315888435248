import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Description = styled(TextBase)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 12px;
`;

export const Wrapper = styled.div`
  max-width: 720px;
  width: 100%;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 24px;
  transition: all 0.3s;

  @media only screen and (max-width: 1428px) {
    max-width: 648px;
  }

  @media only screen and (max-width: 1348px) {
    max-width: 720px;
  }
`;
