/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { CartPanda as CartPandaIcon } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { EGateway } from '@domain/enums/common/EGateway';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useDate } from '@helpers/hooks/useDate';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { cartPandaSchema } from '@helpers/validators/dashboard/integrationCenter/cartPanda';

import { getStoreProvider } from '@helpers/utils/common/integration';

import { GATEWAYS } from '@constants/common/integrations';

import cartPandaPayCredentialService from '@services/pages/dashboard/integrationCenter/gateway/cartPandaPay';

import Text from '@components/common/core/DataDisplay/Text';
import Save from '@components/Dashboard/IntegrationCenter/Sidemodal/Save';
import Form from '@components/common/core/Inputs/Form';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const CartPandaPaySideModal: React.FC = () => {
  const foundIntegration = GATEWAYS.find(
    integration => integration.identifier === EGateway.CART_PANDA_PAY,
  ) as typeof GATEWAYS[number];

  const { mutateStore: mutateIntegrations, storeIntegrations } = useStoreConfig();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(cartPandaSchema),
  });
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { addSynchronization } = useSynchronization();
  const { format, subDays, utcToZonedTime } = useDate();

  const { cartPandaPayCredential, mutate, isLoading } = cartPandaPayCredentialService.getCredential(
    {
      storeAliasId,
    },
  );

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [isLoadingDeleteCredential, setIsLoadingDeleteCredential] = React.useState<boolean>(false);
  const [hasAccessTokenInputValue, setHasAccessTokenInputValue] = React.useState<boolean>(false);
  const [hasDomainInputValue, setHasDomainInputValue] = React.useState<boolean>(false);

  const onAccessTokenInputChange = React.useCallback(event => {
    setHasAccessTokenInputValue(Boolean(event.target.value));
  }, []);

  const onDomainInputChange = React.useCallback(event => {
    setHasDomainInputValue(Boolean(event.target.value));
  }, []);

  const sanitizeUrl = React.useCallback(url => {
    const replacedUrl = url?.replace('https://', '').replace('wwww.', '');
    const parsedUrl = replacedUrl.split('.mycartpanda.com')[0];

    return parsedUrl;
  }, []);

  const onSubmit = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      const sanitizedUrl = sanitizeUrl(formData.domain);

      const lastThirtyDays = utcToZonedTime(subDays(new Date(), 29));

      const storeProvider = getStoreProvider(storeIntegrations);

      const hasGatewaySynchronization = storeProvider?.gateways.includes(EGateway.CART_PANDA_PAY);

      try {
        const params = `?start_date=${format(lastThirtyDays, 'yyyy-MM-dd')}&end_date=${format(
          utcToZonedTime(new Date()),
          'yyyy-MM-dd',
        )}`;

        if (cartPandaPayCredential?.id) {
          const response = await cartPandaPayCredentialService.updateCredential({
            storeAliasId,
            data: { access_token: formData.access_token, domain: sanitizedUrl },
          });

          if (response && storeProvider && hasGatewaySynchronization) {
            addSynchronization({
              name: storeProvider.name,
              type: ESynchronizationType.STORE_GATEWAY,
              label: foundIntegration.name,
              dependencies: [ESynchronizationType.STORE_PRODUCT, ESynchronizationType.STORE_ORDER],
              storeAliasId,
              config: {
                isOnboardingSynchronization: false,
                showNotification: true,
              },
              request: storeProvider.generateGatewaySyncRequest(
                foundIntegration.synchronizationName,
                storeAliasId,
                params,
              ),
            });
          }
        } else {
          const response = await cartPandaPayCredentialService.createCredential({
            storeAliasId,
            data: formData,
          });

          if (response && storeProvider && hasGatewaySynchronization) {
            addSynchronization({
              name: storeProvider.name,
              type: ESynchronizationType.STORE_GATEWAY,
              label: foundIntegration.name,
              dependencies: [ESynchronizationType.STORE_PRODUCT, ESynchronizationType.STORE_ORDER],
              storeAliasId,
              config: {
                isOnboardingSynchronization: false,
                showNotification: true,
              },
              request: storeProvider.generateGatewaySyncRequest(
                foundIntegration.synchronizationName,
                storeAliasId,
                params,
              ),
            });
          }
        }

        await Promise.all([mutate(), mutateIntegrations()]);

        toast.success('Credencial salva com sucesso!');
      } catch (error: any) {
        toast.error(getTranslatedIntegrationsError(error));
      } finally {
        setIsSubmittingForm(false);
      }
    },
    [
      sanitizeUrl,
      cartPandaPayCredential,
      storeAliasId,
      mutate,
      toast,
      mutateIntegrations,
      addSynchronization,
      subDays,
      format,
      utcToZonedTime,
      storeIntegrations,
      foundIntegration,
    ],
  );

  const handleDeleteCredential = React.useCallback(async () => {
    setIsLoadingDeleteCredential(true);
    try {
      await cartPandaPayCredentialService.deleteCredential({
        storeAliasId,
      });

      await Promise.all([mutate(), mutateIntegrations()]);

      toast.success('Credencial desativada com sucesso!');
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoadingDeleteCredential(false);
    }
  }, [storeAliasId, mutate, toast, mutateIntegrations]);

  if (isLoading) {
    return <SkeletonLoading />;
  }

  return (
    <S.ContentWrapper>
      <S.Header>
        <CartPandaIcon size={52} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com CartPanda Pay
        </S.Heading>
      </S.Header>

      <S.TutorialWrapper>
        <S.TutorialHeading type={EHeadingSize.H5}>Tutorial de integração</S.TutorialHeading>
        <S.TutorialList>
          <S.TutorialStep>Acesse a sua conta CartPanda</S.TutorialStep>
          <S.TutorialStep>Na barra de navegação, clique em sua foto&apos;</S.TutorialStep>
          <S.TutorialStep>Copie e Access Token abaixo da foto</S.TutorialStep>
        </S.TutorialList>
      </S.TutorialWrapper>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label htmlFor="cart-panda-store-url">URL da Loja</S.Label>
          <S.InputWrapper>
            <S.TextField
              {...register('domain')}
              type="text"
              id="cart-panda-store-url"
              defaultValue={cartPandaPayCredential?.domain}
              name="domain"
              onChange={onDomainInputChange}
            />
            <S.AppendContent>.mycartpanda.com</S.AppendContent>
          </S.InputWrapper>
          {errors.domain && <Text isErrorFeedback>{errors.domain.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label htmlFor="access-token">Access Token</S.Label>
          <S.InputWrapper>
            <S.TextField
              {...register('access_token')}
              type="text"
              id="access-token"
              name="access_token"
              defaultValue={cartPandaPayCredential?.access_token}
              onChange={onAccessTokenInputChange}
            />
          </S.InputWrapper>
          {errors.access_token && <Text isErrorFeedback>{errors.access_token.message}</Text>}
        </S.InputGroup>

        <Save
          onDelete={handleDeleteCredential}
          hasCredentials={Boolean(cartPandaPayCredential?.id)}
          isSubmitting={isSubmittingForm}
          isDeleting={isLoadingDeleteCredential}
          isDeleteButtonDisabled={isLoadingDeleteCredential || isSubmittingForm}
          isSaveButtonDisabled={
            (!cartPandaPayCredential?.id && (!hasAccessTokenInputValue || !hasDomainInputValue)) ||
            isSubmittingForm ||
            isLoadingDeleteCredential
          }
        />
      </Form>
    </S.ContentWrapper>
  );
};

export default CartPandaPaySideModal;
