import React from 'react';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  const skeletonStyle = {
    borderRadius: 8,
    lineHeight: 'unset',
  };

  return (
    <S.ContentWrapper>
      <S.Heading>
        <Skeleton width={291} height={61} style={skeletonStyle} />
      </S.Heading>

      <S.Body>
        <S.ShippingOption>
          <Skeleton width="100%" height={69} style={skeletonStyle} />
        </S.ShippingOption>

        <S.ShippingOption>
          <Skeleton width="100%" height={69} style={skeletonStyle} />
        </S.ShippingOption>

        <S.ShippingOption>
          <Skeleton width="100%" height={69} style={skeletonStyle} />
        </S.ShippingOption>

        <S.ShippingOption>
          <Skeleton width="100%" height={257} style={skeletonStyle} />
        </S.ShippingOption>
      </S.Body>
    </S.ContentWrapper>
  );
};

export default SkeletonLoading;
