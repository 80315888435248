import { ApiService } from '@services/api';

import { useFetch } from '@providers/Fetch/useFetch';

import { API_DOMAIN } from '@constants/api';

import {
  IAcceptUserInvitePromiseProps,
  ICreateUserInvitePromiseProps,
  ICreateUserInvitePromiseResponse,
  IDeleteUserInvitesPromiseProps,
  IListUserInvitesProps,
  IListUserInvitesResponse,
} from '@domain/interfaces/services/group/IUserInvite';
import { AxiosResponse } from 'axios';

export class UserInviteService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listUserInvites({ storeAliasId }: IListUserInvitesProps): IListUserInvitesResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/user-group-invites`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      userInvites: data?.user_group_invites,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public createUserInvitePromise({
    storeAliasId,
    groupAliasId,
    data,
  }: ICreateUserInvitePromiseProps): Promise<AxiosResponse<ICreateUserInvitePromiseResponse>> {
    return this.apiService.post<ICreateUserInvitePromiseResponse>(
      `/api/v1/users/stores/${storeAliasId}/groups/${groupAliasId}/user-group-invites`,
      { ...data },
    );
  }

  public acceptUserInvitePromise({
    data,
  }: IAcceptUserInvitePromiseProps): Promise<AxiosResponse<void>> {
    return this.apiService.post<any>(`/api/v1/users/stores/user-group-invites`, { ...data });
  }

  public deleteUserInvitesPromise({
    storeAliasId,
    data,
  }: IDeleteUserInvitesPromiseProps): Promise<AxiosResponse<any>> {
    return this.apiService.delete<void>(`/api/v1/users/stores/${storeAliasId}/user-group-invites`, {
      ...data,
    });
  }
}

const userInviteService = new UserInviteService(API_DOMAIN);

export default userInviteService;
