import { NovuProvider } from '@novu/notification-center';
import React from 'react';

import { NotificationProvider } from '@helpers/hooks/common/store/notification/useNotification';
import { SubscriptionStatusProvider } from '@helpers/hooks/common/store/subscription/useSubscriptionStatus';
import { TransactionProvider } from '@helpers/hooks/common/store/subscription/useTransaction';
import { StorePermissionProvider } from '@helpers/hooks/common/useStorePermission';
import { StorePermissionsProvider } from '@helpers/hooks/common/useStorePermissions';
import { AnnouncementProvider } from '@helpers/hooks/pages/dashboard/admin/useAnnouncement';
import { HomeProvider } from '@helpers/hooks/pages/dashboard/home/useHome';
import { SuggestionsProvider } from '@helpers/hooks/pages/dashboard/ideasChannel/useSuggestions';
import { ActivateSubscriptionProvider } from '@helpers/hooks/pages/dashboard/useActivateSubscription';
import { StoreConfigProvider } from '@helpers/hooks/useStoreConfig';
import { StoreSubscriptionProvider } from '@helpers/hooks/useStoreSubscription';

import { useConfig } from '@helpers/hooks/useConfig';

import Wrapper from '@components/Dashboard/Wrapper';
import { WhatsappInstanceProvider } from '@helpers/hooks/pages/dashboard/whatsappInstance/useWhatsappInstance';

const NOVU_APPLICATION_IDENTIFIER =
  process.env.REACT_APP_STAGE === 'dev' ? 'lRTEFv5ld7Rm' : 'Wdr9eHs-CFEk';

const Dashboard: React.FC<any> = ({ children }) => {
  const { user } = useConfig();

  return (
    <NovuProvider
      subscriberId={user?.id}
      applicationIdentifier={NOVU_APPLICATION_IDENTIFIER}
      socketUrl="https://natori-ws.profitfy.me"
      backendUrl="https://natori-api.profitfy.me"
    >
      <NotificationProvider>
        <TransactionProvider>
          <StoreSubscriptionProvider>
            <StoreConfigProvider>
              <StorePermissionsProvider>
                <SubscriptionStatusProvider>
                  <SuggestionsProvider>
                    <AnnouncementProvider>
                      <ActivateSubscriptionProvider>
                        <StorePermissionProvider>
                          <HomeProvider>
                            <WhatsappInstanceProvider>
                              <Wrapper>{children}</Wrapper>
                            </WhatsappInstanceProvider>
                          </HomeProvider>
                        </StorePermissionProvider>
                      </ActivateSubscriptionProvider>
                    </AnnouncementProvider>
                  </SuggestionsProvider>
                </SubscriptionStatusProvider>
              </StorePermissionsProvider>
            </StoreConfigProvider>
          </StoreSubscriptionProvider>
        </TransactionProvider>
      </NotificationProvider>
    </NovuProvider>
  );
};

export default Dashboard;
