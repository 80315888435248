import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ICreateCouponData } from '@domain/interfaces/common/coupon/ICoupon';
import { ECouponType } from '@domain/enums/common/coupon/ECouponType';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';

import { useDate } from '@helpers/hooks/useDate';
import { useCoupon } from '@helpers/hooks/common/coupons/useCoupon';
import { useToast } from '@helpers/hooks/useToast';

import { currencyFormatter, currencyToNumber } from '@helpers/masks';
import { getCouponSchema } from '@helpers/validators/dashboard/adminPanel/coupon';

import Form from '@components/common/core/Inputs/Form';
import DayPicker from '@components/common/core/Utils/DayPicker';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const NewCoupon: React.FC = () => {
  const { format } = useDate();
  const { toast } = useToast();
  const { createCoupon, isCreatingCoupon } = useCoupon();

  const [hasSelectedMaxQuantity, setHasSelectedMaxQuantity] = React.useState<boolean>(false);
  const [date, setDate] = React.useState<Date>(new Date());

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(getCouponSchema(hasSelectedMaxQuantity)),
  });

  const nameRegister = register('name');
  const identifierRegister = register('identifier');
  const amountRegister = register('amount');
  const quantityRegister = register('quantity');
  const typeRegister = register('type');
  const cyclesRegister = register('max_cycles');

  const handleDate = React.useCallback(selectedDate => setDate(selectedDate), []);

  const onSelectedMaxQuantityChange = React.useCallback(event => {
    if (!event || event.target) return;

    setHasSelectedMaxQuantity(event.checked);
  }, []);

  const onAmountChange = React.useCallback(event => {
    event.target.value = currencyFormatter(event.target.value);
  }, []);

  const onSubmit = React.useCallback(
    async formState => {
      const isAmountValid =
        formState.type === ECouponType.PERCENTAGE && currencyToNumber(formState.amount) <= 100;

      if (formState.type === ECouponType.PERCENTAGE && !isAmountValid) {
        toast.error('Porcentagem não válida.');

        return;
      }

      const createCouponData: ICreateCouponData = {
        name: formState.name,
        identifier: formState.identifier.toLowerCase(),
        type: formState.type,
        amount: currencyToNumber(formState.amount),
        max_cycles: Number(formState.max_cycles) === 0 ? undefined : Number(formState.max_cycles),
        has_max_quantity: hasSelectedMaxQuantity,
        quantity: hasSelectedMaxQuantity ? Number(formState.quantity) : 1,
        expires_at: format(date, 'yyyy-MM-dd'),
      };

      await createCoupon(createCouponData);
    },
    [date, format, hasSelectedMaxQuantity, toast, createCoupon],
  );

  const cycles = Array.from(Array(24).keys());

  return (
    <S.Wrapper>
      <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
        Novo cupom
      </S.Title>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.NameAndIdentifierWrapper>
          <S.InputGroup>
            <S.Label>Nome</S.Label>
            <S.Input {...nameRegister} type="text" placeholder="Insira o nome do cupom" />
            {errors.name && <Text isErrorFeedback>{errors.name.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Identificador (Ex. PROFITFY10OFF)</S.Label>
            <S.Input
              {...identifierRegister}
              type="text"
              placeholder="Insira o identificador do cupom"
            />
            {errors.identifier && <Text isErrorFeedback>{errors.identifier.message}</Text>}
          </S.InputGroup>
        </S.NameAndIdentifierWrapper>

        <S.InputGroup>
          <S.Label>Tipo</S.Label>

          <S.RadioOption htmlFor="percentage-coupon">
            <S.Radio
              {...typeRegister}
              value={ECouponType.PERCENTAGE}
              defaultChecked
              id="percentage-coupon"
            />
            <S.RadioText>Porcentagem</S.RadioText>
          </S.RadioOption>

          <S.RadioOption htmlFor="fixed-coupon">
            <S.Radio {...typeRegister} value={ECouponType.FIXED} id="fixed-coupon" />
            <S.RadioText>Fixo</S.RadioText>
          </S.RadioOption>
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Quantidade de Ciclos</S.Label>

          <S.MaxCyclesSelect {...cyclesRegister} name="max-cycles-select">
            <S.SelectOption value={0}>0</S.SelectOption>

            {cycles.map(cycle => {
              return <S.SelectOption value={cycle + 1}>{cycle + 1}</S.SelectOption>;
            })}
          </S.MaxCyclesSelect>
        </S.InputGroup>

        <S.AmountAndEndDateWrapper>
          <S.InputGroup>
            <S.Label>Valor (será considerado como $ ou %, dependendo do tipo)</S.Label>
            <S.Input
              {...amountRegister}
              type="text"
              onChange={onAmountChange}
              placeholder="Insira o valor de desconto do cupom"
            />
            {errors.amount && <Text isErrorFeedback>{errors.amount.message}</Text>}
          </S.InputGroup>

          <S.DayPickerWrapper>
            <S.InputGroup>
              <S.Label>Data de término do cupom</S.Label>
              <DayPicker selectedDate={date} setSelectedDate={handleDate} />
            </S.InputGroup>
          </S.DayPickerWrapper>
        </S.AmountAndEndDateWrapper>

        <S.InputGroup>
          <S.CheckboxOption>
            <S.Checkbox checked={hasSelectedMaxQuantity} onChange={onSelectedMaxQuantityChange} />
            <S.CheckboxText>Habilitar quantidade máxima do cupom</S.CheckboxText>
          </S.CheckboxOption>
        </S.InputGroup>

        {hasSelectedMaxQuantity && (
          <S.InputGroup>
            <S.Label>Quantidade máxima de uso do cupom</S.Label>
            <S.Input
              {...quantityRegister}
              type="number"
              defaultValue={0}
              placeholder="Insira a quantidade máxima de uso do cupom"
            />
            {errors.quantity && <Text isErrorFeedback>{errors.quantity.message}</Text>}
          </S.InputGroup>
        )}

        <S.Button type="submit" isLoading={isCreatingCoupon}>
          Criar novo cupom
        </S.Button>
      </Form>
    </S.Wrapper>
  );
};

export default NewCoupon;
