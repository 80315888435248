import styled from 'styled-components/macro';

import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import LabelBase from '@components/common/core/DataDisplay/Label';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import ButtonBase from '@components/common/core/Inputs/Button';
import AlertBase from '@components/common/core/DataDisplay/Alert';
import CustomDateRangePickerBase from '@components/common/core/Utils/CustomDatePicker';

export const CustomDateRangePicker = styled(CustomDateRangePickerBase)`
  & > div:first-child {
    margin-bottom: 0;
  }
`;

export const Button = styled(ButtonBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 32px;
`;

export const Label = styled(LabelBase)`
  font-weight: 600;
`;

export const Option = styled(OptionBase)``;

export const Select = styled(SelectBase)``;

export const GatewayOptionWrapper = styled.div``;

export const Alert = styled(AlertBase)`
  margin-bottom: 16px;
  padding: 8px 16px;
`;
