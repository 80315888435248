import React from 'react';
import { useTheme } from 'styled-components/macro';
import { HandManual, IIconProps, Plus } from '@profitfy/pakkun-icons';

import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { EButtonVariant } from '@domain/enums/components/EButton';
import { EAdSenseProvider } from '@domain/enums/common/EAdSense';

import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';

import { numberFormatter } from '@helpers/masks';
import { parseMarketings } from '@helpers/utils/dashboard/financialDashboard/financialDashboard';

import { ADS_PROVIDERS } from '@constants/common/integrations';

import * as S from './styles';

const HoverCard: React.FC = ({ children }) => {
  const theme = useTheme();
  const { financialReport, handleNewCustomAdsSideModalOpen } = useFinancialDashboard();

  const { marketing } = financialReport;

  const parsedGateways = parseMarketings(marketing);

  const filteredMarketings = parsedGateways.filter(integration => integration.amount > 0);

  const onHoverCardClick = React.useCallback(event => {
    event.stopPropagation();
  }, []);

  return (
    <S.HoverCard>
      <S.HoverCard.Trigger>{children}</S.HoverCard.Trigger>

      <S.HoverCardContent onClick={onHoverCardClick}>
        <S.MarketingIntegrationsWrapper>
          {filteredMarketings.map(integration => {
            const provider = integration.provider as EAdSenseProvider | 'MANUAL';

            if (provider === 'MANUAL') {
              return (
                <S.Row key={integration.provider}>
                  <S.IconAndNameWrapper>
                    <HandManual size={16} color={theme.colors.font} />
                    <S.MarketingName>Manual</S.MarketingName>
                  </S.IconAndNameWrapper>

                  <S.MarketingValue size={ETextSize.B5} weight={ETextWeight.MEDIUM}>
                    {`R$ ${numberFormatter(integration.amount, 2)}`}
                  </S.MarketingValue>
                </S.Row>
              );
            }

            const foundMarketing = ADS_PROVIDERS.find(
              marketingIntegration => marketingIntegration.identifier === integration.provider,
            );

            const Icon = foundMarketing?.icon as React.FC<IIconProps>;

            return (
              <S.Row key={integration.provider}>
                <S.IconAndNameWrapper>
                  <Icon size={16} />
                  <S.MarketingName>{foundMarketing?.name}</S.MarketingName>
                </S.IconAndNameWrapper>

                <S.MarketingValue size={ETextSize.B5} weight={ETextWeight.MEDIUM}>
                  {`R$ ${numberFormatter(integration.amount, 2)}`}
                </S.MarketingValue>
              </S.Row>
            );
          })}
        </S.MarketingIntegrationsWrapper>

        <S.AddManualAdsButton
          onClick={handleNewCustomAdsSideModalOpen}
          iconPrepend={() => (
            <S.IconWrapper>
              <Plus size={10} color={theme.colors.gray[1]} />
            </S.IconWrapper>
          )}
          variant={EButtonVariant.TERTIARY}
        >
          Adicionar Ads manual
        </S.AddManualAdsButton>

        <S.HoverCard.Arrow />
      </S.HoverCardContent>
    </S.HoverCard>
  );
};

export default HoverCard;
