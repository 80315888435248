import { EFilterType } from '@domain/enums/components/EFilter';
import { IFilterConfig } from '@domain/interfaces/components/IFilter';

export const FINANCIAL_DETAILS_FILTER_BY_DAY_CONFIG: Array<IFilterConfig> = [
  {
    field: 'order_field',
    label: 'Data',
    type: EFilterType.SELECT,
    config: {
      placeholder: 'Buscar por data...',
      data: [
        {
          value: 'provider_created_at',
          label: 'Criação do pedido',
        },
        {
          value: 'approved_at',
          label: 'Aprovação do pedido',
        },
      ],
    },
  },
];

export const FINANCIAL_DETAILS_FILTER_CONFIG: Array<IFilterConfig> = [
  {
    field: 'order_field',
    label: 'Data',
    type: EFilterType.SELECT,
    config: {
      placeholder: 'Buscar por data...',
      data: [
        {
          value: 'provider_created_at',
          label: 'Criação do pedido',
        },
        {
          value: 'approved_at',
          label: 'Aprovação do pedido',
        },
      ],
    },
  },
  {
    field: 'product_id',
    label: 'Produto',
    type: EFilterType.PRODUCTS,
    config: {
      placeholder: 'Encontrar produto...',
    },
  },
];
