import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ImageBase from '@components/common/core/DataDisplay/Image';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1196px;
  height: fit-content;
  gap: 55px;
  padding-top: 176px;

  &::before {
    content: '';
    position: absolute;
    top: 50px;
    left: 0px;
    width: 107px;
    height: 87px;
    background-repeat: no-repeat;

    @media only screen and (max-width: 1208px) {
      display: none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 1480px;
    height: 359px;
    background-repeat: no-repeat;
    bottom: -414px;

    @media only screen and (max-width: 1208px) {
      display: none;
    }
  }

  @media only screen and (max-width: 1276px) {
    padding-top: 48px;
    flex-direction: column;
    gap: 24px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  width: 100%;
`;

export const Image = styled(ImageBase)`
  max-width: 722px;
  height: 360px;
  border-radius: 14px;
`;

export const Heading = styled(HeadingBase)`
  font-size: 1.75rem;

  mark {
    color: ${({ theme }) => theme.colors.green.default};
    background: unset;
  }
`;

export const BenefitsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const Benefit = styled.div`
  display: flex;
  gap: 12px;
`;

export const BenefitText = styled(TextBase)``;

export const CreateNewInstanceButton = styled(ButtonBase)`
  width: fit-content;
  position: relative;

  &::before {
    content: '';
    background-repeat: no-repeat;
    width: 168px;
    height: 34px;
    position: absolute;
    right: -212px;
    top: 16px;

    @media only screen and (max-width: 410px) {
      display: none;
    }
  }
`;

export const IconWrapper = styled.div`
  background-color: #24363f;
  border-radius: 5px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
