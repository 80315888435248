import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import LabelBase from '@components/common/core/DataDisplay/Label';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import ButtonBase from '@components/common/core/Inputs/Button';

export const DraftContentWrapper = styled.div`
  width: 100%;
  max-width: 702px;

  @media only screen and (max-width: 1000px) {
    max-width: unset;
  }
`;

export const Header = styled.div`
  margin-bottom: 40px;
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const DescriptionText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.background[6]};
`;

export const Body = styled.div``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Label = styled(LabelBase)`
  font-weight: 500;
`;

export const TextField = styled(TextFieldBase)``;

export const Select = styled(SelectBase)`
  width: 100%;
`;

export const Option = styled(OptionBase)``;

export const Button = styled(ButtonBase)`
  margin-left: auto;
`;
