import React from 'react';

import { IHistoricSidemodalProps } from '@domain/interfaces/dashboard/ProductCostDetails/IHistoricSidemodal';
import { useVariantHistoric } from '@helpers/hooks/pages/dashboard/productCostDetails/variantHistoric';
import { useProductCostDetails } from '@helpers/hooks/pages/dashboard/productCostDetails/productCostDetails';

import ComponentError from '@components/common/core/Utils/ComponentError';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';

import * as S from './styles';

const HistoricSidemodal: React.FC<IHistoricSidemodalProps> = ({ isOpen, toggle }) => {
  const { selectedVariant } = useProductCostDetails();

  const {
    loadVariantCosts,
    loadDefaultVariantCost,
    handleSelectedVariant,
    userStep,
    handleUserStep,
    variantCostsError,
    defaultVariantCostError,
    isLoadingDefaultVariantCost,
    isLoadingVariantCosts,
  } = useVariantHistoric();

  const resetStepOnToggle = React.useCallback(() => {
    handleUserStep('FIRST');

    toggle();
  }, [toggle, handleUserStep]);

  const mutate = React.useCallback(async () => {
    const promises = [];

    if (selectedVariant) {
      if (variantCostsError) promises.push(loadVariantCosts(selectedVariant?.id));

      if (defaultVariantCostError) promises.push(loadDefaultVariantCost(selectedVariant?.id));
    }

    await Promise.all(promises);
  }, [
    loadDefaultVariantCost,
    loadVariantCosts,
    variantCostsError,
    defaultVariantCostError,
    selectedVariant,
  ]);

  React.useEffect(() => {
    if (selectedVariant) {
      loadVariantCosts(selectedVariant.id);
      loadDefaultVariantCost(selectedVariant.id);
      handleSelectedVariant(selectedVariant);
    }
  }, [selectedVariant, loadVariantCosts, loadDefaultVariantCost, handleSelectedVariant]);

  if (
    (variantCostsError && !isLoadingVariantCosts) ||
    (defaultVariantCostError && !isLoadingDefaultVariantCost)
  ) {
    return (
      <S.Sidemodal isOpen={isOpen} toggle={resetStepOnToggle}>
        <S.ErrorWrapper>
          <ComponentError mutate={mutate} />
        </S.ErrorWrapper>
      </S.Sidemodal>
    );
  }

  return (
    <S.Sidemodal isOpen={isOpen} toggle={resetStepOnToggle}>
      <S.ContentWrapper>
        {userStep === 'FIRST' && <FirstStep />}

        {userStep === 'SECOND' && <SecondStep />}
      </S.ContentWrapper>
    </S.Sidemodal>
  );
};

export default HistoricSidemodal;
