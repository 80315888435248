import Wrapper from '@components/Dashboard/PricingTool/Wrapper';
import { PricingToolProvider } from '@helpers/hooks/pages/dashboard/pricingTool/usePricingTool';
import React from 'react';

const PricingTool: React.FC = () => {
  return (
    <PricingToolProvider>
      <Wrapper />
    </PricingToolProvider>
  );
};

export default PricingTool;
