import React from 'react';

import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { useTheme } from 'styled-components/macro';
import { useMediaQuery } from '@helpers/hooks/useMediaQuery';
import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';

import CustomTooltip from './Tooltip';

import * as S from './styles';

const ProfitChart: React.FC = () => {
  const theme = useTheme();
  const mobileWidth = useMediaQuery('1124px');
  const { netProfitChart } = useFinancialDashboard();

  const mappedChartData = netProfitChart.map(netProfit => {
    return {
      name: netProfit.date,
      value: netProfit.net_profit_value,
    };
  });

  const getChartColor = React.useCallback(
    value => {
      if (value > 0) return theme.colors.success.default;

      return theme.colors.danger.default;
    },
    [theme],
  );

  return (
    <S.ChartWrapper>
      <ResponsiveContainer width="99%">
        <BarChart
          data={mappedChartData}
          barSize={mobileWidth ? 24 : 12}
          outerRadius={5}
          margin={{ bottom: 0, left: 0, right: 0, top: 0 }}
        >
          <Bar dataKey="value" fill={theme.colors.success.default} radius={2}>
            {mappedChartData.map(profit => {
              const color = getChartColor(profit.value);

              return <Cell fill={color} key={profit.name} />;
            })}
          </Bar>
          <Tooltip
            cursor={{ fill: 'rgba(66, 64, 64, 0.2)' }}
            content={<CustomTooltip />}
            allowEscapeViewBox={{ x: true, y: true }}
            position={{ x: 4, y: 64 }}
          />
        </BarChart>
      </ResponsiveContainer>
    </S.ChartWrapper>
  );
};

export default ProfitChart;
