import React from 'react';

import { EHeadingSize } from '@domain/enums/components/EHeading';

import FailureImg from '@assets/pages/FacebookLogin/error.svg';

import * as S from './styles';

const FailureLoginContent: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Heading type={EHeadingSize.H2}>Não foi possível conectar a conta!</S.Heading>
      <S.Description>
        Abra uma nova guia anônima e <strong>tente novamente.</strong>
      </S.Description>

      <S.Image src={FailureImg} alt="profitfy-goat-sad" />
    </S.Wrapper>
  );
};

export default FailureLoginContent;
