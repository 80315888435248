import React from 'react';

import { EHeadAlign } from '@domain/enums/components/ETable';

import { IOrderTableDataProps } from '@domain/interfaces/dashboard/Orders/IOrderTableData';

import Table from '@components/common/core/Utils/Table';
import Content from './FeeContent';

const Fee: React.FC<IOrderTableDataProps> = ({ order }) => {
  return (
    <Table.Data align={EHeadAlign.CENTER}>
      <Content order={order} />
    </Table.Data>
  );
};

export default Fee;
