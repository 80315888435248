import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ETextSize } from '@domain/enums/components/EText';
import { useChargeback } from '@helpers/hooks/pages/dashboard/feed/useChargeback';
import Order from './Order';
import InfiniteScrollLoading from './InfiniteScrollLoading';

import * as S from './styles';

const ChargebackOrders: React.FC = () => {
  const {
    isLoadingChargedbackOrders,
    loadChargedbackOrders,
    chargedbackOrders,
    hasMore,
    handleLoadMore,
    isChargedbackOrdersError,
  } = useChargeback();

  React.useEffect(() => {
    loadChargedbackOrders();
  }, [loadChargedbackOrders]);

  if (!isChargedbackOrdersError && !isLoadingChargedbackOrders && !chargedbackOrders.length) {
    return <></>;
  }

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>Pedido com chargeback</S.Title>
        <S.Description size={ETextSize.B5}>
          Esse pedido teve o status alterado para chargeback em seu gateway de pagamento. Informe o
          valor do chargeback para contabilizá-lo
        </S.Description>
      </S.Header>

      <S.OrdersWrapper id="chargeback-orders-list">
        <InfiniteScroll
          dataLength={chargedbackOrders.length}
          next={handleLoadMore}
          hasMore={hasMore}
          loader={<InfiniteScrollLoading />}
          scrollThreshold={0.95}
          scrollableTarget="chargeback-orders-list"
        >
          {!chargedbackOrders.length && isLoadingChargedbackOrders ? (
            <InfiniteScrollLoading />
          ) : (
            <></>
          )}

          {chargedbackOrders.map(order => (
            <Order key={order.alias_id} order={order} />
          ))}
        </InfiniteScroll>
      </S.OrdersWrapper>
    </S.Wrapper>
  );
};

export default ChargebackOrders;
