import React from 'react';
import { SimpleCheck, SimpleClose } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { EStatus } from '@domain/enums/subscription/ESubscription';
import { IParams } from '@domain/interfaces/IParams';

import { useSubscriptionPlans } from '@helpers/hooks/pages/dashboard/subscription/useSubscriptionPlans';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { useDowngrade } from '@helpers/hooks/common/store/subscription/useDowngrade';
import { useSubscription } from '@helpers/hooks/common/store/subscription/useSubscription';
import { useToast } from '@helpers/hooks/useToast';
import { useTransaction } from '@helpers/hooks/common/store/subscription/useTransaction';
import { useConfig } from '@helpers/hooks/useConfig';

import { ALL_PLANS } from '@constants/plans';
import { PLANS_BENEFITS } from '@constants/common/plans/benefits';

import Arrow from './Arrow';

import * as S from './styles';

const DowngradeModal: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const {
    isDowngradingModalOpen,
    handleDowngradingModalOpen,
    handleSelectedPlanToChange,
    isDowngradingSubscription,
    handleDowngradingSubscription,
  } = useSubscriptionPlans();
  const { subscription, mutateSubscription } = useStoreSubscription();
  const { adProviders, invitedMembers, facebookProfiles, storeWorkflows } = useDowngrade();
  const { toast } = useToast();
  const { mutateTransactions } = useTransaction();
  const { analytics, user } = useConfig();
  const {
    selectedPlanIdentifier,
    createFreeSubscription,
    updateFreeSubscription,
  } = useSubscription();

  const currentPlanIdentifier =
    subscription.status === EStatus.TRIAL_ENDED ? 'trial_ended' : subscription.plan?.identifier;

  const currentPlan = ALL_PLANS.find(
    plan =>
      plan.identifier === currentPlanIdentifier ||
      plan.quarterIdentifier === currentPlanIdentifier ||
      plan.semiAnualIdentifier === currentPlanIdentifier,
  );
  const selectedPlan =
    (ALL_PLANS.find(
      plan =>
        plan.identifier === selectedPlanIdentifier ||
        plan.quarterIdentifier === selectedPlanIdentifier ||
        plan.semiAnualIdentifier === selectedPlanIdentifier,
    ) as typeof ALL_PLANS[number]) || undefined;
  const currentPlanBenefits = PLANS_BENEFITS.find(
    plan => plan.identifier === currentPlan?.identifier,
  );
  const selectedPlanBenefits = PLANS_BENEFITS.find(
    plan => plan.identifier === selectedPlan?.identifier,
  );

  const featuresThatWillBeRemoved = currentPlanBenefits?.benefits.filter(benefit => {
    return !selectedPlanBenefits?.benefits.find(
      selectedPlanBenefit => selectedPlanBenefit === benefit,
    );
  });
  const hasFeaturesThatWillBeRemoved = Boolean(featuresThatWillBeRemoved?.length);

  const selectedPlanMarketingProviders =
    selectedPlan?.marketingProvider === 0 ? Infinity : selectedPlan?.marketingProvider;
  const selectedPlanInvitedMembers = selectedPlan?.members === 0 ? Infinity : selectedPlan?.members;
  const selectedPlanFacebookProfiles =
    selectedPlan?.facebookProfile === 0 ? Infinity : selectedPlan?.facebookProfile;
  const selectedPlanStoreWorkflows = !selectedPlan?.benefits.maxWorkflows
    ? Infinity
    : selectedPlan?.benefits.maxWorkflows;

  const hasToDowngradeAdsProviders = adProviders.length > (selectedPlanMarketingProviders || 0);
  const hasToDowngradeInvitedMembers = invitedMembers.length > (selectedPlanInvitedMembers || 0);
  const hasToDowngradeFacebookProfiles =
    facebookProfiles.length > (selectedPlanFacebookProfiles || 0);
  const hasToDowngradeStoreWorkflows = storeWorkflows.length > (selectedPlanStoreWorkflows || 0);

  const hasToFinishDowngradeStep =
    hasToDowngradeAdsProviders ||
    hasToDowngradeInvitedMembers ||
    hasToDowngradeFacebookProfiles ||
    hasToDowngradeStoreWorkflows;

  const isTrialEndedSubscription = subscription?.status === EStatus.TRIAL_ENDED;
  const isCanceledSubscription = subscription?.status === EStatus.CANCELED;

  const toggle = React.useCallback(() => {
    if (isDowngradingModalOpen) {
      handleSelectedPlanToChange(undefined);
    }

    handleDowngradingModalOpen();
  }, [isDowngradingModalOpen, handleSelectedPlanToChange, handleDowngradingModalOpen]);

  const handleNextStep = React.useCallback(async () => {
    const planIdentifier = selectedPlan?.identifier;

    if (planIdentifier?.includes('free')) {
      handleDowngradingSubscription(true);

      try {
        if (
          subscription.status === EStatus.TRIAL_ENDED ||
          subscription.status === EStatus.CANCELED
        ) {
          await createFreeSubscription(storeAliasId, planIdentifier);
        } else {
          await updateFreeSubscription(storeAliasId, planIdentifier, subscription.alias_id);
        }

        await mutateSubscription();
        await mutateTransactions();

        toast.success('Assinatura modificada com sucesso!');

        handleDowngradingModalOpen();
        handleDowngradingSubscription(false);
      } catch (error: any) {
        handleDowngradingSubscription(false);
        toast.error(error?.response?.data?.message);
      }

      return;
    }

    handleDowngradingModalOpen();

    history.push(`/${storeAliasId}/subscription/address?identifier=${planIdentifier}`);
  }, [
    handleDowngradingModalOpen,
    history,
    storeAliasId,
    selectedPlan,
    createFreeSubscription,
    updateFreeSubscription,
    mutateSubscription,
    subscription,
    toast,
    mutateTransactions,
    handleDowngradingSubscription,
  ]);

  const handleDowngradeConfirm = React.useCallback(async () => {
    analytics?.track(
      'Subscription Flow Downgraded Modal Clicked',
      {
        email: user?.email,
        click_source: 'CONTINUE_DOWNGRADE',
      },
      { context: { groupId: storeAliasId } },
    );

    if (hasToFinishDowngradeStep) {
      handleDowngradingModalOpen();

      history.push(`/${storeAliasId}/downgrade/ad-providers`);

      return;
    }

    await handleNextStep();
  }, [
    handleDowngradingModalOpen,
    history,
    storeAliasId,
    hasToFinishDowngradeStep,
    analytics,
    user,
    handleNextStep,
  ]);

  const onSeePlansButtonClick = React.useCallback(() => {
    analytics?.track(
      'Subscription Flow Downgraded Modal Clicked',
      {
        email: user?.email,
        click_source: 'SEE_PLANS',
      },
      { context: { groupId: storeAliasId } },
    );

    toggle();
  }, [toggle, analytics, user, storeAliasId]);

  const parseValue = React.useCallback((value, wordGenre) => {
    if ((value === 0 || value === undefined) && wordGenre === 'masculine') return 'Ilimitados';

    if ((value === 0 || value === undefined) && wordGenre === 'feminine') return 'Ilimitadas';

    return value;
  }, []);

  React.useEffect(() => {
    if (currentPlan && selectedPlan && currentPlan?.identifier === selectedPlan?.identifier) {
      history.push(`/${storeAliasId}/subscription/address`);
    }
  }, [currentPlan, selectedPlan, history, storeAliasId]);

  React.useEffect(() => {
    if (
      !isDowngradingSubscription &&
      isDowngradingModalOpen &&
      (isTrialEndedSubscription || isCanceledSubscription) &&
      !hasToFinishDowngradeStep
    ) {
      handleNextStep();
    }
  }, [
    isTrialEndedSubscription,
    hasToFinishDowngradeStep,
    isDowngradingModalOpen,
    isDowngradingSubscription,
    isCanceledSubscription,
    handleNextStep,
  ]);

  const isOpen = isDowngradingModalOpen && !(isTrialEndedSubscription && !hasToFinishDowngradeStep);
  const hasContentToBeRemoved = hasFeaturesThatWillBeRemoved || hasToFinishDowngradeStep;

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Modal.Body>
        {hasContentToBeRemoved ? (
          <S.Title>O plano escolhido não suporta alguns recursos utilizados.</S.Title>
        ) : (
          <S.Title>Troca de plano</S.Title>
        )}

        {hasContentToBeRemoved ? (
          <S.Description>
            Ao continuar com este plano, será reduzido o uso de alguns recursos:
          </S.Description>
        ) : (
          <S.Description>
            Tem certeza de que deseja realizar a troca do plano? Essa ação é irreversível.
          </S.Description>
        )}

        {hasToFinishDowngradeStep && (
          <S.BenefitsDowngradeWrapper>
            {hasToDowngradeAdsProviders && (
              <S.BenefitDowngrade>
                <S.OldBenefit>
                  <SimpleCheck size={14} color={theme.colors.green.default} />
                  <S.OldBenefitText>
                    <strong>{parseValue(currentPlan?.marketingProvider, 'feminine')}</strong> Fontes
                    de tráfego
                  </S.OldBenefitText>
                </S.OldBenefit>
                <S.NewBenefit>
                  <Arrow size={32} />
                  <SimpleCheck size={14} color={theme.colors.gray[2]} />
                  <S.NewBenefitText>
                    <strong>{parseValue(selectedPlan?.marketingProvider, 'feminine')}</strong>{' '}
                    Fontes de tráfego
                  </S.NewBenefitText>
                </S.NewBenefit>
              </S.BenefitDowngrade>
            )}

            {hasToDowngradeInvitedMembers && (
              <S.BenefitDowngrade>
                <S.OldBenefit>
                  <SimpleCheck size={14} color={theme.colors.green.default} />
                  <S.OldBenefitText>
                    <strong>{parseValue(currentPlan?.members, 'masculine')}</strong> Usuários
                  </S.OldBenefitText>
                </S.OldBenefit>
                <S.NewBenefit>
                  <Arrow size={32} />
                  <SimpleCheck size={14} color={theme.colors.gray[2]} />
                  <S.NewBenefitText>
                    <strong>{parseValue(selectedPlan?.members, 'masculine')}</strong> Usuários
                  </S.NewBenefitText>
                </S.NewBenefit>
              </S.BenefitDowngrade>
            )}

            {hasToDowngradeFacebookProfiles && (
              <S.BenefitDowngrade>
                <S.OldBenefit>
                  <SimpleCheck size={14} color={theme.colors.green.default} />
                  <S.OldBenefitText>
                    <strong>{parseValue(currentPlan?.facebookProfile, 'masculine')}</strong> Perfis
                    do facebook
                  </S.OldBenefitText>
                </S.OldBenefit>
                <S.NewBenefit>
                  <Arrow size={32} />
                  <SimpleCheck size={14} color={theme.colors.gray[2]} />
                  <S.NewBenefitText>
                    <strong>{parseValue(selectedPlan?.facebookProfile, 'masculine')}</strong> Perfis
                    do facebook
                  </S.NewBenefitText>
                </S.NewBenefit>
              </S.BenefitDowngrade>
            )}

            {hasToDowngradeStoreWorkflows && (
              <S.BenefitDowngrade>
                <S.OldBenefit>
                  <SimpleCheck size={14} color={theme.colors.green.default} />
                  <S.OldBenefitText>
                    <strong>{parseValue(currentPlan?.benefits.maxWorkflows, 'feminine')}</strong>{' '}
                    Automações
                  </S.OldBenefitText>
                </S.OldBenefit>
                <S.NewBenefit>
                  <Arrow size={32} />
                  <SimpleCheck size={14} color={theme.colors.gray[2]} />
                  <S.NewBenefitText>
                    <strong>{parseValue(selectedPlan?.benefits.maxWorkflows, 'feminine')}</strong>{' '}
                    Automações
                  </S.NewBenefitText>
                </S.NewBenefit>
              </S.BenefitDowngrade>
            )}
          </S.BenefitsDowngradeWrapper>
        )}

        {Boolean(featuresThatWillBeRemoved?.length) &&
          !isTrialEndedSubscription &&
          hasToFinishDowngradeStep && (
            <S.NoPermissionsLabel>
              E também perderá as seguintes funcionalidades:
            </S.NoPermissionsLabel>
          )}

        {!isTrialEndedSubscription && (
          <S.FeaturesToBeRemoved>
            {featuresThatWillBeRemoved?.map(feature => (
              <S.FeatureToBeRemoved key={feature}>
                <SimpleClose size={14} color={theme.colors.danger.default} />
                <S.FeatureToBeRemovedText>{feature}</S.FeatureToBeRemovedText>
              </S.FeatureToBeRemoved>
            ))}
          </S.FeaturesToBeRemoved>
        )}

        <S.ButtonsWrapper>
          <S.SeePlansButton
            onClick={onSeePlansButtonClick}
            variant={EButtonVariant.SECONDARY_BUTTON}
            isLoading={isDowngradingSubscription}
          >
            Ver outros planos
          </S.SeePlansButton>
          <S.ConfirmButton onClick={handleDowngradeConfirm} isLoading={isDowngradingSubscription}>
            Continuar mesmo assim
          </S.ConfirmButton>
        </S.ButtonsWrapper>
      </S.Modal.Body>
    </S.Modal>
  );
};

export default DowngradeModal;
