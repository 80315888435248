import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 8px;
  padding: 24px 28px;
  box-sizing: border-box;
`;

export const Heading = styled(HeadingBase)`
  color: #fff;
  margin-bottom: 12px;
`;

export const Description = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
  margin-bottom: 12px;
`;

export const Example = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const InputGroup = styled(InputGroupBase)`
  margin: 24px 0;
`;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)``;

export const AddTagButton = styled(ButtonBase)`
  max-width: 127px;
  margin-left: auto;
`;
