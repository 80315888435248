import React from 'react';
import { Plus, Check } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { useEcommerce } from '@helpers/hooks/pages/onboarding/useEcommerce';

import { ADS_PROVIDERS } from '@constants/common/integrations';

import Sidemodal from './Sidemodal';

import * as S from './styles';

const Marketings: React.FC = () => {
  const theme: any = useTheme();
  const { selectedMarketings } = useEcommerce();

  const [isSidemodalOpen, setIsSidemodalOpen] = React.useState<boolean>(false);

  const handleSidemodalOpen = React.useCallback(() => setIsSidemodalOpen(!isSidemodalOpen), [
    isSidemodalOpen,
  ]);

  const getIntegrationIcon = React.useCallback(
    (integration: typeof ADS_PROVIDERS[number] | undefined) => {
      if (integration) {
        const Icon = integration.icon;

        return <Icon size={20} />;
      }

      return <></>;
    },
    [],
  );

  return (
    <>
      <S.Wrapper>
        <S.CardWrapper onClick={handleSidemodalOpen}>
          <S.Header>
            <S.IconWrapper>
              <Plus color={theme.colors.gray[1]} size={9} />
            </S.IconWrapper>

            <S.DescriptionText>
              Selecionar plataforma de <strong>Marketing</strong>
            </S.DescriptionText>
          </S.Header>

          {selectedMarketings.map(integration => {
            const foundMarketing = ADS_PROVIDERS.find(
              marketingIntegration => marketingIntegration.identifier === integration,
            );

            return (
              <React.Fragment key={integration}>
                <S.HorizontalRule />

                <S.IntegrationWrapper>
                  <S.IntegrationName>
                    {getIntegrationIcon(foundMarketing)}
                    <S.Text>{foundMarketing?.name}</S.Text>
                  </S.IntegrationName>

                  <Check size={20} outline />
                </S.IntegrationWrapper>
              </React.Fragment>
            );
          })}
        </S.CardWrapper>

        <S.ExampleText>Ex: Google, Facebook, etc.</S.ExampleText>
      </S.Wrapper>

      {isSidemodalOpen ? (
        <Sidemodal isOpen={isSidemodalOpen} toggle={handleSidemodalOpen} />
      ) : (
        <></>
      )}
    </>
  );
};

export default Marketings;
