import styled, { keyframes } from 'styled-components/macro';

const initialAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Wrapper = styled.div`
  background-color: #122427;
  width: 100%;
  padding: 12px;
  border-radius: 5px;
  box-sizing: border-box;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-fill-mode: forwards;
  will-change: 'transform, opacity';
  animation-name: ${initialAnimation};
`;
