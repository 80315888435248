import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { EButtonTextColor } from '@domain/enums/components/EButton';
import { IParams } from '@domain/interfaces/IParams';

import { useAccessManager } from '@helpers/hooks/pages/dashboard/accessManagement/useAccessManager';
import { useToast } from '@helpers/hooks/useToast';

import { formSchema } from '@helpers/validators/dashboard/accessManagement/newGroup';

import { DEFAULT_FEATURES } from '@constants/accessManagement';

import groupService from '@services/pages/dashboard/accessManagement/group';

import Text from '@components/common/core/DataDisplay/Text';
import Return from '../Return';
import Feature from './Feature';

import * as S from './styles';

const NewGroup: React.FC = () => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { selectedPermissions, handleSelectedPermissions } = useAccessManager();
  const { toast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  });

  const [groupNameValue, setGroupNameValue] = React.useState<string>('');
  const [isCreatingGroup, setIsCreatingGroup] = React.useState<boolean>(false);

  const groupNameInputRegister = register('group_name');

  const onGroupNameChange = React.useCallback(
    event => {
      groupNameInputRegister.onChange(event);

      setGroupNameValue(event.target.value);
    },
    [groupNameInputRegister],
  );

  const onSubmit = React.useCallback(
    async formData => {
      setIsCreatingGroup(true);

      const data = {
        name: formData.group_name,
      };
      selectedPermissions.forEach(selectedPermission => {
        const readPermission = selectedPermission.replace('write', 'read');

        data[`${selectedPermission}`] = true;
        data[readPermission] = true;
      });

      const permissions: Array<string> = [];

      DEFAULT_FEATURES.forEach(defaultFeature => {
        defaultFeature.permissions.forEach(permission => {
          const foundSelectedPermission = selectedPermissions.find(
            selectedPermission => selectedPermission === permission.value,
          );

          if (!foundSelectedPermission) permissions.push(permission.value);
        });
      });
      permissions.forEach(permission => {
        const readPermission = permission.replace('write', 'read');

        data[`${permission}`] = false;
        data[readPermission] = false;
      });

      try {
        await groupService.createGroupPromise({
          storeAliasId,
          data: {
            ...data,
            // has_workflow_full_write_access: false,
            // has_workflow_full_read_access: false,
          },
        });

        handleSelectedPermissions([]);
        setIsCreatingGroup(false);

        history.push(`/${storeAliasId}/dashboard/access-manager/onboarding/invite`);
      } catch (error: any) {
        setIsCreatingGroup(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [history, storeAliasId, selectedPermissions, handleSelectedPermissions, toast],
  );

  const hasNoPermissionsSelected = Boolean(!selectedPermissions.length);

  return (
    <S.Container>
      <Return />

      <S.ContentWrapper>
        <S.Heading>Novo grupo de acesso</S.Heading>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>Nome do grupo</S.Label>
            <S.Input
              {...groupNameInputRegister}
              type="text"
              value={groupNameValue}
              placeholder="Digite o nome do grupo"
              onChange={onGroupNameChange}
              isError={errors.group_name}
            />
            {errors.group_name && <Text isErrorFeedback>{errors.group_name.message}</Text>}
          </S.InputGroup>

          <S.FeaturesWrapper>
            <S.FeaturesTitle>Defina as permissões para este grupo</S.FeaturesTitle>

            {DEFAULT_FEATURES.map((feature, index) => {
              const isFirstFeature = index === 0;

              return <Feature key={feature.name} defaultOpen={isFirstFeature} feature={feature} />;
            })}
          </S.FeaturesWrapper>

          <S.CreateGroupButton
            type="submit"
            textColor={EButtonTextColor.DARK}
            disabled={hasNoPermissionsSelected || isCreatingGroup}
            isLoading={isCreatingGroup}
          >
            Criar grupo
          </S.CreateGroupButton>
        </S.Form>
      </S.ContentWrapper>
    </S.Container>
  );
};

export default NewGroup;
