import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import {
  IGetCartPandaCredentialProps,
  IGetCartPandaCredentialResponse,
  ICreateCredentialProps,
  IDisableCartPandaCredentialProps,
  IEnableCartPandaCredentialProps,
  IUpdateCartPandaCredentialProps,
  IUpdateCartPandaCredentialResponse,
} from '@domain/interfaces/dashboard/IntegrationCenter/StoreProvider/CartPanda/ICartPandaAuth';
import { API_DOMAIN } from '@constants/api';
import { AxiosResponse } from 'axios';

export class CartPandaAuthService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public createCredential({ storeAliasId, data }: ICreateCredentialProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/cart-panda-credentials`, {
      ...data,
    });
  }

  public getCartPandaCredentialPromise({
    storeAliasId,
  }: IGetCartPandaCredentialProps): Promise<any> {
    return this.apiService.get(`/api/v1/users/stores/${storeAliasId}/cart-panda-credentials`);
  }

  public getCartPandaCredential({
    storeAliasId,
  }: IGetCartPandaCredentialProps): IGetCartPandaCredentialResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/cart-panda-credentials`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      cartPandaCredential: data?.cart_panda_credential,
      error,
      isLoading,
      isValidating,
      mutate,
    };
  }

  public disableCredential({
    storeAliasId,
    cartPandaCredentialAliasId,
  }: IDisableCartPandaCredentialProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/cart-panda-credentials/${cartPandaCredentialAliasId}`,
      { is_active: false },
    );
  }

  public enableCredential({
    storeAliasId,
    cartPandaCredentialAliasId,
  }: IEnableCartPandaCredentialProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/cart-panda-credentials/${cartPandaCredentialAliasId}`,
      { is_active: true },
    );
  }

  public updateCartPandaCredential({
    storeAliasId,
    cartPandaCredentialAliasId,
    data,
  }: IUpdateCartPandaCredentialProps): Promise<AxiosResponse<IUpdateCartPandaCredentialResponse>> {
    return this.apiService.put<IUpdateCartPandaCredentialResponse>(
      `/api/v1/users/stores/${storeAliasId}/cart-panda-credentials/${cartPandaCredentialAliasId}`,
      {
        ...data,
      },
    );
  }
}

const cartPandaAuthService = new CartPandaAuthService(API_DOMAIN);

export default cartPandaAuthService;
