import React from 'react';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import { IFeedWhatsappInstanceProvider } from '@domain/interfaces/dashboard/Feed/IWhatsappInstance';

import feedService from '@services/pages/dashboard/feed/feed';

const WhatsappInstanceContext = React.createContext<IFeedWhatsappInstanceProvider | null>(null);

export const WhatsappInstanceProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();

  const {
    error: whatsappInstanceError,
    isLoading: isLoadingWhatsappInstance,
    isValidating: isValidatingWhatsappInstance,
    mutate: mutateWhatsappInstance,
    whatsappInstances,
  } = feedService.listFeedWhatsappInstances({ storeAliasId });

  return (
    <WhatsappInstanceContext.Provider
      value={{
        isLoadingWhatsappInstance,
        isValidatingWhatsappInstance,
        mutateWhatsappInstance,
        whatsappInstanceError,
        whatsappInstances,
      }}
    >
      {children}
    </WhatsappInstanceContext.Provider>
  );
};

export const useWhatsappInstance = (): IFeedWhatsappInstanceProvider => {
  const context = React.useContext(WhatsappInstanceContext);

  if (!context) {
    throw new Error('useWhatsappInstance must be used within WhatsappInstanceContext');
  }

  return context;
};
