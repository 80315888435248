import React from 'react';
import { useTheme } from 'styled-components/macro';

import { randomInteger } from '@helpers/utils/common/random';
import { numberFormatter } from '@helpers/masks';

import Chart from '../Chart';

import * as S from './styles';

const BlurredContent: React.FC = () => {
  const randomNumber = randomInteger(0, 100);

  const theme = useTheme();

  const chartData = [
    {
      name: 'Conversão',
      value: randomNumber,
    },
    {
      name: 'Total',
      value: 100 - randomNumber,
    },
  ];

  const colors = [theme.colors.success.light, theme.colors.gray[4]];

  return (
    <>
      <S.Content>
        <S.ChartWrapper>
          <Chart data={chartData} colors={colors} />
          <S.ConversionPercentage>{`${numberFormatter(
            randomNumber,
            0,
            true,
          )}%`}</S.ConversionPercentage>
        </S.ChartWrapper>
        <S.TextContentWrapper>
          <S.ContentWrapper>
            <S.LabelText>Pagos</S.LabelText>
            <S.AmountText>{`R$ ${numberFormatter(0, 2)} (${0})`}</S.AmountText>
          </S.ContentWrapper>

          <S.ContentWrapper>
            <S.LabelText>Pendentes</S.LabelText>
            <S.AmountText>{`R$ ${numberFormatter(0, 2)} (${0})`}</S.AmountText>
          </S.ContentWrapper>
        </S.TextContentWrapper>
      </S.Content>
      <S.TotalLabel>Total</S.TotalLabel>
    </>
  );
};

export default BlurredContent;
