import React from 'react';

import { EUserStoreRole } from '@domain/enums/common/userStore/EUserStoreRole';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import { EditKitProvider } from '@helpers/hooks/pages/dashboard/kit/useEditKit';

import Wrapper from '@components/Dashboard/Kit/EditKit/Wrapper';
import NoAccess from '@components/common/core/Utils/NoAccess';

const EditKit: React.FC = () => {
  const { accessManagementData } = useStoreConfig();
  const { isInternalTeam } = useConfig();

  const isStoreOwner = accessManagementData?.role === EUserStoreRole.OWNER;
  const hasAccessToPage =
    accessManagementData?.group?.has_kits_full_write_access ||
    accessManagementData?.group?.has_kits_full_read_access;

  if (!isStoreOwner && !hasAccessToPage && !isInternalTeam) {
    return <NoAccess />;
  }

  return (
    <EditKitProvider>
      <Wrapper />
    </EditKitProvider>
  );
};

export default EditKit;
