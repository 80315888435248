import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { ISynchronizationRequest } from '@domain/interfaces/hooks/IStoreSynchronization';

export const getNoRetroactiveSynchronizationRequestConfig = (
  type: ESynchronizationType,
  storeAliasId: string | undefined,
  params: string,
): ISynchronizationRequest => {
  return {} as ISynchronizationRequest;
};

export const getNoRetroactiveGatewaySynchronizationRequestConfig = (
  gateway: string,
  storeAliasId: string | undefined,
  params: string,
): ISynchronizationRequest => {
  return {} as ISynchronizationRequest;
};
