import { ENodes } from '@domain/enums/dashboard/automations/node/ENodes';
import { ENodeType } from '@domain/enums/mappers/automations/profitfy/ENodeType';

interface ITypeMapperDTO<T> {
  [key: string]: T;
}

const map = (nodeType: ENodes): ENodeType => {
  const type: ITypeMapperDTO<ENodeType> = {
    [ENodes.ABANDONED_CART]: ENodeType.ABANDONED_CART,
    [ENodes.END]: ENodeType.END,
    [ENodes.BOLETO]: ENodeType.BOLETO,
    [ENodes.IF]: ENodeType.IF,
    [ENodes.REJECTED_PAYMENT]: ENodeType.REJECTED_PAYMENT,
    [ENodes.PIX]: ENodeType.PIX,
    [ENodes.WHATSAPP]: ENodeType.WHATSAPP,
    [ENodes.SWITCH]: ENodeType.SWITCH,
    [ENodes.WAIT]: ENodeType.WAIT,
    [ENodes.SIMPLE_SHIPMENT]: ENodeType.SIMPLE_SHIPMENT,
    [ENodes.UPDATED_FULFILLMENT]: ENodeType.UPDATED_FULFILLMENT,
  };

  const mappedType = type[nodeType];

  return mappedType;
};

export default { map };
