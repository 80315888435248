import React from 'react';

import { IModal, IModalProps } from '@domain/interfaces/components/IModal';
import ModalBody from './ModalBody';
import ModalHeader from './ModalHeader';

import * as S from './styles';

const Modal: IModal & React.FC<IModalProps> = ({
  toggle,
  isOpen,
  blockBackgroundClick = false,
  children,
  ...rest
}) => {
  const backgroundClick = React.useCallback(() => {
    if (!blockBackgroundClick && toggle) {
      toggle();
    }
  }, [toggle, blockBackgroundClick]);

  return (
    <>
      {isOpen && (
        <>
          <S.ModalBackground onClick={backgroundClick} />
          <S.ModalContent {...rest}>{children}</S.ModalContent>
        </>
      )}
    </>
  );
};

Modal.Body = ModalBody;
Modal.Header = ModalHeader;

export default Modal;
