import styled from 'styled-components/macro';

import ButtonBase from '@components/common/core/Inputs/Button';

export const Background = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  position: fixed;
  max-width: 588px;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  transform: translateX(-40px);
  box-sizing: border-box;
`;

export const ConfirmButton = styled(ButtonBase)``;
