import React from 'react';
import { Facebook } from '@profitfy/pakkun-icons';

import { EBadgeColors } from '@domain/enums/components/EBadge';
import { ETextSize } from '@domain/enums/components/EText';
import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { EButtonSize } from '@domain/enums/components/EButton';
import { IAdAccountProps } from '@domain/interfaces/dashboard/Feed/IAdSenseAccount';

import { useFeedAdSenseAccount } from '@helpers/hooks/pages/dashboard/feed/useFeedAdSenseAccount';

import * as S from './styles';

const AdAccount: React.FC<IAdAccountProps> = ({ adSenseAccount }) => {
  const { solveAdSenseAccount, isSolvingAdSenseAccount } = useFeedAdSenseAccount();

  const getAdAccountProviderIcon = (): JSX.Element => {
    if (adSenseAccount.provider === EAdSenseProvider.FACEBOOK) {
      return <Facebook size={40} />;
    }

    return <></>;
  };

  const onUnderstoodButtonClick = React.useCallback(() => {
    solveAdSenseAccount(adSenseAccount.id);
  }, [solveAdSenseAccount, adSenseAccount]);

  return (
    <S.Wrapper>
      <S.IconAndInfosWrapper>
        {getAdAccountProviderIcon()}

        <S.InfosWrapper>
          <S.AccountIdText>{adSenseAccount.provider_id}</S.AccountIdText>
          <S.AccountStatusBadge color={EBadgeColors.RED}>Bloqueado</S.AccountStatusBadge>
        </S.InfosWrapper>
      </S.IconAndInfosWrapper>

      <S.ProfileWrapper>
        <S.Tooltip content={adSenseAccount.ad_sense_credential.name}>
          <S.ProfileName>{adSenseAccount.ad_sense_credential.name}</S.ProfileName>
        </S.Tooltip>

        <S.InfoDescription size={ETextSize.B5}>Perfil</S.InfoDescription>
      </S.ProfileWrapper>

      <S.UserNameWrapper>
        <S.Tooltip content={adSenseAccount.name}>
          <S.UserName>{adSenseAccount.name}</S.UserName>
        </S.Tooltip>

        <S.InfoDescription size={ETextSize.B5}>Nome</S.InfoDescription>
      </S.UserNameWrapper>

      <S.UnderstoodButton
        onClick={onUnderstoodButtonClick}
        isLoading={isSolvingAdSenseAccount}
        disabled={isSolvingAdSenseAccount}
        buttonSize={EButtonSize.SMALL}
      >
        Entendi
      </S.UnderstoodButton>
    </S.Wrapper>
  );
};

export default AdAccount;
