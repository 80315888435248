import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DASHBOARD_DOMAIN } from '@constants/api';

import {
  IListProductNetProfitChartProps,
  IListProductNetProfitChartResponse,
} from '@domain/interfaces/dashboard/ProductAnalytics/IProductNetProfit';

export class ProductNetProfitService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listProductNetProfitChart({
    storeAliasId,
    productAliasId,
    startDate,
    endDate,
  }: IListProductNetProfitChartProps): IListProductNetProfitChartResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/financial-charts/net-profit?start_date=${startDate}&end_date=${endDate}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      productNetProfitChart: data?.net_profit_chart,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }
}

const productNetProfitService = new ProductNetProfitService(API_DASHBOARD_DOMAIN);

export default productNetProfitService;
