import styled, { css } from 'styled-components/macro';

import { IRoiTextProps } from '@domain/interfaces/dashboard/AdsManager/Table/Content/IRoi';

import TextBase from '@components/common/core/DataDisplay/Text';

export const ForecastAmount = styled(TextBase)<IRoiTextProps>`
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.success.default};
  display: flex;
  align-items: center;
  gap: 4px;

  ${({ amount, theme }) =>
    amount === 0 &&
    css`
      color: ${theme.colors.gray[1]};
    `};

  ${({ amount, theme }) =>
    amount < 0 &&
    css`
      color: ${theme.colors.danger.default};
    `};
`;

export const RoiAmount = styled(TextBase)<IRoiTextProps>`
  font-size: 0.875rem;
  line-height: 120%;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.success.default};

  ${({ amount, theme }) =>
    amount === 0 &&
    css`
      color: ${theme.colors.gray[1]};
    `};

  ${({ amount, theme }) =>
    amount < 0 &&
    css`
      color: ${theme.colors.danger.default};
    `};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 4px;
  height: 58px;
  padding: 10px 12px;
  box-sizing: border-box;
`;
