import React from 'react';

import { EHeadAlign } from '@domain/enums/components/ETable';
import Paginate from '@components/common/core/Utils/Paginate';
import { useKit } from '@helpers/hooks/pages/dashboard/kit/useKit';
import TableRow from '../TableRow';

import * as S from './styles';

const Table: React.FC = () => {
  const { page, pageCount, onPageChange, variantKits } = useKit();

  return (
    <S.KitTable>
      <S.KitTable.Header>
        <S.KitTable.Row>
          <S.KitTable.Head>Kit</S.KitTable.Head>
          <S.KitTable.Head align={EHeadAlign.RIGHT}>Quantidade</S.KitTable.Head>
          <S.KitTable.Head>Itens</S.KitTable.Head>
          <S.KitTable.Head align={EHeadAlign.RIGHT}>Custo do kit</S.KitTable.Head>
          <S.KitTable.Head align={EHeadAlign.CENTER}>Status</S.KitTable.Head>
          <S.KitTable.Head />
        </S.KitTable.Row>
      </S.KitTable.Header>

      <S.KitTable.Body>
        {variantKits.map(variantKit => (
          <TableRow variantKit={variantKit} key={variantKit.alias_id} />
        ))}
      </S.KitTable.Body>

      <S.KitTable.Footer>
        <S.KitTable.Row>
          <S.KitTable.Data colSpan={6} align={EHeadAlign.RIGHT}>
            <Paginate forcePage={page} pageCount={pageCount} onPageChange={onPageChange} />
          </S.KitTable.Data>
        </S.KitTable.Row>
      </S.KitTable.Footer>
    </S.KitTable>
  );
};

export default Table;
