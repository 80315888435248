import React from 'react';

import { EDropshippingProvider } from '@domain/enums/common/integrations/EDropshippingProvider';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import Shopify from '../PlatformFlow/Shopify';
import Cartpanda from '../PlatformFlow/Cartpanda';

const Wrapper: React.FC = () => {
  const { storeIntegrations } = useStoreConfig();

  const { store_front: storeFrontIntegrations } = storeIntegrations;

  const activeIntegration = storeFrontIntegrations.find(integration => integration.is_active);

  if (activeIntegration?.name === EDropshippingProvider.CART_PANDA) {
    return <Cartpanda />;
  }

  return <Shopify />;
};

export default Wrapper;
