/* eslint-disable import/no-duplicates */
import React from 'react';

import { useTheme } from 'styled-components/macro';
import { Sync, Search } from '@profitfy/pakkun-icons';
import { formatDistanceToNow } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import { ETextSize } from '@domain/enums/components/EText';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { useFacebookCredentials } from '@helpers/hooks/common/integrations/marketing/facebook/useFacebookCredentials';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useDebounce } from '@helpers/hooks/useDebounce';
import { useDate } from '@helpers/hooks/useDate';

import FacebookOnboardingButton from '@components/common/core/Utils/FacebookOnboardingButton';
import AuthenticateModal from '../AuthenticateModal';

import * as S from './styles';

const Header: React.FC = () => {
  const theme = useTheme();
  const { utcToZonedTime } = useDate();
  const { currentSynchronizations } = useSynchronization();

  const {
    adSenseCredentials,
    syncAllAdSenseAccounts,
    handleAdAccountSearchName,
    mutateAdSenseCredentials,
    handleFacebookData,
    handleFacebookProfileData,
  } = useFacebookCredentials();

  const [lastTimeUpdated] = React.useState<Date>(new Date());
  const [, setUpdatedDate] = React.useState<Date>(new Date());
  const [adAccountSearchName, setAdAccountSearchName] = React.useState<string>('');
  const [isAuthenticateModalOpen, setIsAuthenticateModalOpen] = React.useState<boolean>(false);

  const handleFail = React.useCallback(() => {
    handleFacebookData(undefined);
    handleFacebookProfileData(undefined);
  }, [handleFacebookData, handleFacebookProfileData]);

  const handleAuthenticateModalOpen = React.useCallback(() => {
    setIsAuthenticateModalOpen(!isAuthenticateModalOpen);
  }, [isAuthenticateModalOpen]);

  const handleSyncDataButtonClick = React.useCallback(async () => {
    await mutateAdSenseCredentials();
    syncAllAdSenseAccounts(undefined);
  }, [mutateAdSenseCredentials, syncAllAdSenseAccounts]);

  const onSearchInputChange = React.useCallback(event => {
    setAdAccountSearchName(event.target.value);
  }, []);

  const hasFacebookProfile = Boolean(adSenseCredentials.length);

  const isSyncing = Boolean(
    currentSynchronizations?.synchronizations.find(synchronization => {
      if (
        synchronization.type === ESynchronizationType.AD_SENSE_ACCOUNTS ||
        synchronization.type === ESynchronizationType.AD_SENSE_CAMPAIGNS
      ) {
        return true;
      }

      return false;
    }),
  );

  const debouncedAdAccountSearchName = useDebounce(adAccountSearchName, 700);

  React.useEffect(() => {
    handleAdAccountSearchName(debouncedAdAccountSearchName);
  }, [debouncedAdAccountSearchName, handleAdAccountSearchName]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setUpdatedDate(new Date());
    }, 60000);

    return () => clearInterval(interval);
  }, [lastTimeUpdated]);

  return (
    <S.Wrapper>
      <S.HeadingWrapper>
        <S.Heading>Integração com o Facebook</S.Heading>
        <S.Text size={ETextSize.B5}>
          Conecte perfis do Facebook para contabilizar seus gastos com anúncios.
        </S.Text>
      </S.HeadingWrapper>

      <S.Body>
        <FacebookOnboardingButton
          hasFacebookProfile={hasFacebookProfile}
          handleAuthenticateModalOpen={handleAuthenticateModalOpen}
          onSuccess={handleFacebookData}
          onProfileSuccess={handleFacebookProfileData}
          onFail={handleFail}
        />

        <S.SyncAndInputWrapper>
          <S.LastUpdatedText size={ETextSize.B5}>
            Atualizado há <br />
            {`${formatDistanceToNow(utcToZonedTime(lastTimeUpdated), {
              locale: pt,
            })}`}
          </S.LastUpdatedText>

          <S.SyncDataButton onClick={handleSyncDataButtonClick} disabled={isSyncing}>
            <Sync size={17} color={theme.colors.gray[1]} />
          </S.SyncDataButton>

          <S.Input
            type="text"
            prepend={() => <Search size={20} color={theme.colors.gray[3]} />}
            onChange={onSearchInputChange}
          />
        </S.SyncAndInputWrapper>
      </S.Body>

      <AuthenticateModal isOpen={isAuthenticateModalOpen} toggle={handleAuthenticateModalOpen} />
    </S.Wrapper>
  );
};

export default Header;
