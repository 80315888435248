import React from 'react';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import { IFeedAdSenseAccountProvider } from '@domain/interfaces/dashboard/Feed/IAdSenseAccount';

import { useDate } from '@helpers/hooks/useDate';
import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';

import feedService from '@services/pages/dashboard/feed/feed';

const FeedAdSenseAccountContext = React.createContext<IFeedAdSenseAccountProvider | null>(null);

export const FeedAdSenseAccountProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { format, utcToZonedTime, subDays } = useDate();
  const { analytics, user } = useConfig();

  const [isSolvingAdSenseAccount, setIsSolvingAdSenseAccount] = React.useState<boolean>(false);

  const startDate = format(subDays(utcToZonedTime(new Date()), 29), 'yyyy-MM-dd');
  const endDate = format(utcToZonedTime(new Date()), 'yyyy-MM-dd');

  const {
    adSenseAccounts,
    mutate: mutateAdSenseAccounts,
    error: adSenseAccountsError,
    isLoading: isLoadingAdSenseAccounts,
    isValidating: isValidatingdSenseAccounts,
  } = feedService.listFeedAdSenseAccounts({
    storeAliasId,
    startDate,
    endDate,
  });

  const solveAdSenseAccount = React.useCallback(
    async (adSenseAccountId: string) => {
      setIsSolvingAdSenseAccount(true);

      try {
        await feedService.updateAdSenseAccountByFeed({
          storeAliasId,
          data: {
            is_resolved_by_feed: true,
            ad_sense_account_id: adSenseAccountId,
          },
        });

        analytics?.track(
          'Ad Account Blocked Button Clicked',
          { user: user?.email },
          { context: { groupId: storeAliasId } },
        );

        setIsSolvingAdSenseAccount(false);

        mutateAdSenseAccounts();
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsSolvingAdSenseAccount(false);
      }
    },
    [storeAliasId, toast, mutateAdSenseAccounts, analytics, user],
  );

  return (
    <FeedAdSenseAccountContext.Provider
      value={{
        adSenseAccounts,
        adSenseAccountsError,
        isLoadingAdSenseAccounts,
        isValidatingdSenseAccounts,
        mutateAdSenseAccounts,
        solveAdSenseAccount,
        isSolvingAdSenseAccount,
      }}
    >
      {children}
    </FeedAdSenseAccountContext.Provider>
  );
};

export const useFeedAdSenseAccount = (): IFeedAdSenseAccountProvider => {
  const context = React.useContext(FeedAdSenseAccountContext);

  if (!context) {
    throw new Error('useFeedAdSenseAccount must be used within FeedAdSenseAccountContext');
  }

  return context;
};
