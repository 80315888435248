import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 42px;
`;

export const InstancesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
`;
