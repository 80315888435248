import styled from 'styled-components/macro';
import PhoneInputBase from 'react-phone-input-2';

import { IPhoneInputProps } from '@domain/interfaces/components/IPhoneInput';

import 'react-phone-input-2/lib/style.css';

export const PhoneInput = styled(PhoneInputBase)<IPhoneInputProps>`
  border-radius: 5px;
  height: 42px;
  border: 1px solid transparent;
  transition: all 0.3s;

  .form-control {
    height: 42px;
    background-color: ${({ theme }) => theme.colors.background[2]};
    border: 0;
    width: 100%;
    color: ${({ theme }) => theme.colors.gray[1]};
    font-family: ${({ theme }) => theme.fonts.body};
    font-weight: 500;
    font-size: 1rem;
    transition: all 0.3s;
  }

  .flag-dropdown {
    border: 0;
    background-color: ${({ theme }) => theme.colors.background[2]};
    border-radius: 5px;
    transition: all 0.3s;

    .selected-flag {
      background-color: ${({ theme }) => theme.colors.background[2]};
      border: 0;
      border-radius: 5px;
      transition: all 0.3s;

      &:hover {
        background-color: transparent;
      }

      &:active {
        background-color: ${({ theme }) => theme.colors.background[2]};
      }
    }

    .open {
      background-color: ${({ theme }) => theme.colors.background[2]} !important;
    }
  }

  .country-list {
    background-color: ${({ theme }) => theme.colors.background[2]};

    .country {
      span {
        color: ${({ theme }) => theme.colors.gray[1]};
        font-family: ${({ theme }) => theme.fonts.body};
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[3]};
      }
    }

    .highlight {
      background-color: ${({ theme }) => theme.colors.gray[3]} !important;
    }
  }
`;
