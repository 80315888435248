import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const Title = styled(HeadingBase)``;

export const Table = styled(TableBase)``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
