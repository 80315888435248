import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import { EStep } from '@domain/enums/onboarding/EStep';
import { EType } from '@domain/enums/onboarding/EType';
import { EGateway } from '@domain/enums/common/EGateway';

import { useEcommerce } from '@helpers/hooks/pages/onboarding/useEcommerce';
import { useMediaQuery } from '@helpers/hooks/useMediaQuery';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';
import { useConfig } from '@helpers/hooks/useConfig';

import { ECOMMERCE_GATEWAYS } from '@constants/common/integrations';

import storesService from '@services/pages/stores/stores';
import onboardingStepService from '@services/pages/onboarding/onboardingStep';

import ComponentError from '@components/common/core/Utils/ComponentError';
import DesktopSuggestionContent from '@components/Onboarding/DesktopSuggestionContent';
import NextStep from '@components/Onboarding/NextStep';
import Header from '../Header';
import Provider from '../Provider';
import Gateways from '../Gateways';
import Marketings from '../Marketings';
import SkeletonLoading from '../SkeletonLoading';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const history = useHistory();

  const { storeAliasId } = useParams<IParams>();
  const { addStep } = useOnboarding();
  const { analytics, user, isMobileDevice, selectedStore } = useConfig();
  const {
    handleStoreIntegrations,
    selectedProvider,
    selectedGateways,
    handleSelectedProvider,
    handleSelectedGateways,
    handleSelectedStoreIntegrations,
    handleSelectedMarketings,
  } = useEcommerce();

  const {
    integrations: storeIntegrations,
    isLoading: isLoadingStore,
    isValidating: isValidatingStore,
    mutate: mutateStore,
    error: storeError,
  } = storesService.getStore({ storeAliasId });

  const isMobileWidth = useMediaQuery('702px');

  const { onboardingSteps } = onboardingStepService.getOnboardingSteps({ storeAliasId });

  const handleNewIntegrationsOnNextStep = React.useCallback(() => {
    handleStoreIntegrations(undefined);

    const foundNewProvider = storeIntegrations.store_front.find(
      provider => provider.name === selectedProvider && !provider.is_active,
    ) as any;

    const filteredPredefinedGateways = selectedGateways.filter(gateway => {
      const foundIntegration = ECOMMERCE_GATEWAYS.find(
        integration => integration.identifier === gateway,
      );

      const isPredefined = Boolean(foundIntegration?.isPredefinedOtherGateway);

      if (!isPredefined) return false;

      const foundOtherGatewayIntegration = storeIntegrations.gateways.find(
        gatewayIntegration => gatewayIntegration.name === EGateway.OTHER_GATEWAY,
      );

      if (!foundOtherGatewayIntegration) return false;

      const foundPredefinedGateway = foundOtherGatewayIntegration.other_gateway?.other_gateway_names.includes(
        gateway,
      );

      if (foundPredefinedGateway) return false;

      return true;
    });

    const filteredNewGateways = storeIntegrations.gateways.filter(gateway => {
      const foundGateway = selectedGateways.find(
        selectedGateway => selectedGateway === gateway.name,
      );

      if (foundGateway) return !gateway.is_active;

      return false;
    });

    const mappedFilteredNewGateway = filteredNewGateways.map(
      newGateway => newGateway.name,
    ) as Array<EGateway>;

    handleSelectedProvider(foundNewProvider?.name);
    handleSelectedGateways([...mappedFilteredNewGateway, ...filteredPredefinedGateways]);
    handleSelectedStoreIntegrations(true);
  }, [
    handleStoreIntegrations,
    storeIntegrations,
    selectedProvider,
    handleSelectedProvider,
    handleSelectedStoreIntegrations,
    handleSelectedGateways,
    selectedGateways,
  ]);

  const nextStep = React.useCallback(async () => {
    handleNewIntegrationsOnNextStep();

    analytics?.track(
      'Onboarding Selected Integrations',
      {
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );

    history.push(`/onboarding/${storeAliasId}/ecommerce/integrations/platforms`);
  }, [history, handleNewIntegrationsOnNextStep, storeAliasId, analytics, user]);

  React.useEffect(() => {
    if (storeIntegrations) {
      handleStoreIntegrations(storeIntegrations);
    }
  }, [storeIntegrations, handleStoreIntegrations]);

  React.useEffect(() => {
    handleSelectedMarketings([]);
  }, [handleSelectedMarketings]);

  React.useEffect(() => {
    handleSelectedStoreIntegrations(false);
  }, [handleSelectedStoreIntegrations]);

  React.useEffect(() => {
    if (onboardingSteps) {
      const foundStep = onboardingSteps.find(
        step => step.configuration_step === EStep.STORE_INTEGRATIONS,
      );

      if (!foundStep) {
        addStep([
          {
            configuration_step: EStep.STORE_INTEGRATIONS,
            type: EType.TRADITIONAL_ECOMMERCE,
          },
        ]);
      }
    }
  }, [onboardingSteps, addStep]);

  if (isLoadingStore || isValidatingStore) {
    return <SkeletonLoading />;
  }

  if (storeError && !isValidatingStore) {
    return (
      <S.ComponentErrorWrapper>
        <ComponentError mutate={mutateStore} />
      </S.ComponentErrorWrapper>
    );
  }

  if (isMobileDevice && !selectedStore?.is_onboarding_finished) {
    return <DesktopSuggestionContent />;
  }

  return (
    <S.Wrapper>
      <Header />

      <S.ContentWrapper>
        {isMobileWidth ? (
          <S.ProviderAndMarketingWrapper>
            <Provider />

            {Boolean(selectedProvider) && <Gateways />}

            {Boolean(selectedGateways.length) && <Marketings />}
          </S.ProviderAndMarketingWrapper>
        ) : (
          <>
            <S.ProviderAndMarketingWrapper>
              <Provider />

              {Boolean(selectedGateways.length) && Boolean(selectedProvider) && <Marketings />}
            </S.ProviderAndMarketingWrapper>

            {Boolean(selectedProvider) && <Gateways />}
          </>
        )}
      </S.ContentWrapper>

      <NextStep disabled={!selectedGateways.length || !selectedProvider} onClick={nextStep}>
        Próximo passo
      </NextStep>
    </S.Wrapper>
  );
};

export default Wrapper;
