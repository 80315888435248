import React from 'react';

import * as S from './styles';

const ActionButtonWrapper: React.ForwardRefRenderFunction<HTMLDivElement, any> = (
  { children, ...rest },
  ref,
) => {
  return (
    <S.Wrapper ref={ref} {...rest}>
      {children}
    </S.Wrapper>
  );
};

export default React.forwardRef(ActionButtonWrapper);
