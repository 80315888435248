/* eslint-disable import/no-duplicates */
import React from 'react';
import { Taboola, Plus, Sync, Search } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { formatDistanceToNow } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import { ETextSize } from '@domain/enums/components/EText';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';

import { useTaboolaCredentials } from '@helpers/hooks/common/integrations/marketing/taboola/useTaboolaCredentials';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useDate } from '@helpers/hooks/useDate';
import { useDebounce } from '@helpers/hooks/useDebounce';

import NewCredentialSideModal from './NewCredentialSideModal';

import * as S from './styles';

const Header: React.FC = () => {
  const theme = useTheme();

  const [lastTimeUpdated] = React.useState<Date>(new Date());
  const [, setUpdatedDate] = React.useState<Date>(new Date());

  const { utcToZonedTime } = useDate();
  const {
    syncAllAdSenseAccounts,
    handleNewCredentialSideModalOpen,
    handleAdAccountSearchName,
  } = useTaboolaCredentials();
  const { currentSynchronizations } = useSynchronization();

  const [adAccountSearchName, setAdAccountSearchName] = React.useState<string>('');

  const onSearchInputChange = React.useCallback(event => {
    setAdAccountSearchName(event.target.value);
  }, []);

  const isSyncing = Boolean(
    currentSynchronizations?.synchronizations.find(synchronization => {
      if (
        synchronization.type === ESynchronizationType.AD_SENSE_ACCOUNTS ||
        synchronization.type === ESynchronizationType.AD_SENSE_CAMPAIGNS
      ) {
        return true;
      }

      return false;
    }),
  );

  const debouncedAdAccountSearchName = useDebounce(adAccountSearchName, 700);

  React.useEffect(() => {
    handleAdAccountSearchName(debouncedAdAccountSearchName);
  }, [handleAdAccountSearchName, debouncedAdAccountSearchName]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setUpdatedDate(new Date());
    }, 60000);

    return () => clearInterval(interval);
  }, [lastTimeUpdated]);

  return (
    <S.Wrapper>
      <S.HeadingWrapper>
        <S.Heading>Integração com o Taboola</S.Heading>
        <S.Text size={ETextSize.B5}>
          Conecte perfis do Taboola para contabilizar seus gastos com anúncios.
        </S.Text>
      </S.HeadingWrapper>

      <S.Body>
        <S.AddNewProfileButton onClick={handleNewCredentialSideModalOpen}>
          <Taboola size={22} />
          Adicionar perfil do Taboola
          <S.VerticalRule />
          <Plus size={20} color={theme.colors.gray[1]} />
        </S.AddNewProfileButton>

        <S.SyncAndInputWrapper>
          <S.LastUpdatedText size={ETextSize.B5}>
            Atualizado há <br />
            {`${formatDistanceToNow(utcToZonedTime(lastTimeUpdated), {
              locale: pt,
            })}`}
          </S.LastUpdatedText>

          <S.SyncDataButton onClick={syncAllAdSenseAccounts} disabled={isSyncing}>
            <Sync size={17} color={theme.colors.gray[1]} />
          </S.SyncDataButton>

          <S.Input
            type="text"
            prepend={() => <Search size={20} color={theme.colors.gray[3]} />}
            onChange={onSearchInputChange}
          />
        </S.SyncAndInputWrapper>
      </S.Body>

      <NewCredentialSideModal />
    </S.Wrapper>
  );
};

export default Header;
