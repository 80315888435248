import styled, { keyframes } from 'styled-components/macro';

import SideModalBase from '@components/common/core/DataDisplay/SideModal';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

const totalAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const CustomValuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TotalValue = styled(TextBase)`
  line-height: 120%;
  font-size: 1.5rem;
`;

export const TotalLabel = styled(TextBase)`
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[2]};
  font-size: 1.25rem;
`;

export const TotalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${totalAnimation} 2s ease-in-out;
`;

export const ChartWrapper = styled.div`
  height: 198px;
  position: relative;
  margin-bottom: 32px;
`;

export const Body = styled.div`
  padding-top: 24px;
`;

export const Title = styled(HeadingBase)``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
`;

export const SideModal = styled(SideModalBase)``;
