import { Task, PieChart, Person } from '@profitfy/pakkun-icons';

export const mobileMenuRoutes = [
  {
    name: 'Pedidos',
    url: '/dashboard/reports/orders',
    icon: Task,
  },
  {
    name: 'Financeiro',
    url: '/dashboard/overview/financial',
    icon: PieChart,
  },
  {
    name: 'Perfil',
    url: '/dashboard/profile',
    icon: Person,
  },
];
