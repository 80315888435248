import React from 'react';
import { useForm } from 'react-hook-form';
import { Upnid as Icon, SimpleCheck } from '@profitfy/pakkun-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { ETextSize } from '@domain/enums/components/EText';
import { IParams } from '@domain/interfaces/IParams';

import { useDropshipping } from '@helpers/hooks/pages/onboarding/useDropshipping';
import { useToast } from '@helpers/hooks/useToast';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { upnidSchema } from '@helpers/validators/dashboard/integrationCenter/upnid';

import upnidCredentialService from '@services/pages/dashboard/integrationCenter/gateway/upnid';

import NextStep from '@components/Onboarding/NextStep';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const Upnid: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(upnidSchema) });
  const { handleNextGatewayToIntegrate, selectedMarketings } = useDropshipping();
  const { storeAliasId } = useParams<IParams>();
  const history = useHistory();
  const { toast } = useToast();

  const [isLoadingSubmittedForm, setIsLoadingSubmittedForm] = React.useState<boolean>(false);
  const [accessTokenValue, setAccessTokenValue] = React.useState<string>('');

  const accessTokenRegister = register('access_token');

  const onAccessTokenValueChange = React.useCallback(
    event => {
      accessTokenRegister.onChange(event);

      setAccessTokenValue(event.target.value);
    },
    [accessTokenRegister],
  );

  const onSubmit = React.useCallback(
    async data => {
      setIsLoadingSubmittedForm(true);

      try {
        await upnidCredentialService.createCredential({
          storeAliasId,
          data,
        });

        toast.success('Credencial salva com sucesso!');

        setIsLoadingSubmittedForm(false);

        const hasNextGateway = handleNextGatewayToIntegrate();

        if (!hasNextGateway && selectedMarketings.length) {
          history.push(`/onboarding/${storeAliasId}/dropshipping/integrations/marketing`);
        }

        if (!hasNextGateway && !selectedMarketings.length) {
          history.push(
            `/onboarding/${storeAliasId}/dropshipping/integrations/integration-finished`,
          );
        }
      } catch (error: any) {
        setIsLoadingSubmittedForm(false);
        toast.error(getTranslatedIntegrationsError(error));
      }
    },
    [history, handleNextGatewayToIntegrate, storeAliasId, selectedMarketings, toast],
  );

  const hasAllInputsValue = accessTokenValue;

  return (
    <S.Wrapper>
      <S.FormWrapper>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Header>
            <S.Title>Integre com Gateway de pagamento</S.Title>
          </S.Header>

          <S.IntegrationTitle>
            <Icon size={36} />

            <S.IntegrationTitleText fontWeight={EHeadingWeight.REGULAR}>
              Integração com Upnid
            </S.IntegrationTitleText>
          </S.IntegrationTitle>

          <S.Body>
            <S.BenefitsWrapper>
              <S.Benefit>
                <SimpleCheck size={12} />
                <S.DescriptionText size={ETextSize.B5}>Exatidão dos dados</S.DescriptionText>
              </S.Benefit>
              <S.Benefit>
                <SimpleCheck size={12} />
                <S.DescriptionText size={ETextSize.B5}>Confiabilidade dos dados</S.DescriptionText>
              </S.Benefit>
            </S.BenefitsWrapper>

            <S.InputGroup>
              <S.Label>Access Token</S.Label>
              <S.Input
                {...accessTokenRegister}
                placeholder="Token de Acesso Upnid"
                type="text"
                id="upnid-access-token"
                onChange={onAccessTokenValueChange}
                value={accessTokenValue}
                isError={errors.access_token}
              />
              {errors.access_token && <Text isErrorFeedback>{errors.access_token.message}</Text>}
            </S.InputGroup>
          </S.Body>

          <NextStep
            type="submit"
            isLoading={isLoadingSubmittedForm}
            disabled={isLoadingSubmittedForm || !hasAllInputsValue}
          >
            Próximo passo
          </NextStep>
        </S.Form>
      </S.FormWrapper>

      <S.TutorialWrapper>
        <S.TutorialImage
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/onboarding/images/content/onboarding/onboarding-integration-tutorial.svg"
          alt="Profitfy.me Integration Tutorial"
        />
        <S.TutorialTitle>Tutorial de integração</S.TutorialTitle>
        <S.TutorialContent>
          Para realizar a integração você precisará do <strong>Access Token</strong> que é
          encontrado neste link:
        </S.TutorialContent>
        <S.TutorialLink
          href="https://ajuda.profitfy.me/pt-BR/articles/5904060-como-gerar-api-key-upnid"
          target="_blank"
        >
          Encontre suas credenciais clicando aqui!
        </S.TutorialLink>
      </S.TutorialWrapper>
    </S.Wrapper>
  );
};

export default Upnid;
