import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ImageBase from '@components/common/core/DataDisplay/Image';

export const Content = styled.div`
  width: 100%;
  max-width: 864px;
  height: fit-content;
  padding-top: 92px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 32px;

  @media only screen and (max-width: 908px) {
    flex-direction: column;
    gap: 32px;
    width: fit-content;
    align-items: flex-start;
    padding-top: 48px;
  }

  @media only screen and (max-width: 414px) {
    align-items: center;
    width: fit-content;
  }
`;

export const HeadingAndQrCodeWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  @media only screen and (max-width: 908px) {
    flex-direction: column;
    gap: 32px;
    align-items: center;
  }

  @media only screen and (max-width: 414px) {
    align-items: center;
  }
`;

export const HeadingAndStepsWrapper = styled.div`
  width: 100%;
  max-width: 493px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Heading = styled(HeadingBase)`
  color: #ffffff;

  mark {
    color: ${({ theme }) => theme.colors.green.default};
    background: unset;
  }
`;

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StepText = styled(TextBase)`
  color: #ffffff;
`;

export const LoadingQRCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
`;

export const LoadingQRCodeWarn = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[5]};
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  line-height: 1.3;
`;

export const WhatsappConfigImg = styled.img`
  margin: 4px;
  margin-bottom: -4px;
`;

export const QRCodeWrapper = styled.div`
  width: 100%;
  max-width: 280px;
  min-height: 280px;
  max-height: 280px;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 27px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const QRCodeImage = styled(ImageBase)`
  max-width: 225px;
`;
