import React from 'react';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.ContentWrapper>
      <S.Header>
        <S.IconWrapper>
          <Skeleton width="100%" height="100%" count={1} />
        </S.IconWrapper>

        <S.TitleWrapper>
          <Skeleton width="100%" height={24} count={1} />
        </S.TitleWrapper>
      </S.Header>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityTitleWrapper>
          <Skeleton width="100%" height={22} count={1} />
        </S.FunctionalityTitleWrapper>

        <Skeleton width="100%" height={18} count={4} />
      </S.FunctionalityDescriptionWrapper>

      <S.TutorialWrapper>
        <S.TutorialTitleWrapper>
          <Skeleton width="100%" height={22} count={1} />
        </S.TutorialTitleWrapper>

        <Skeleton width="100%" height={18} count={2} />
      </S.TutorialWrapper>

      <S.InputGroup>
        <S.LabelWrapper>
          <Skeleton width="100%" height={18} count={1} />
        </S.LabelWrapper>

        <Skeleton width="100%" height={40} count={1} />
      </S.InputGroup>

      <S.InputGroup>
        <S.LabelWrapper>
          <Skeleton width="100%" height={18} count={1} />
        </S.LabelWrapper>

        <Skeleton width="100%" height={40} count={1} />
      </S.InputGroup>
    </S.ContentWrapper>
  );
};

export default SkeletonLoading;
