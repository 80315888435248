import React from 'react';

import { IWindowSize } from '@domain/interfaces/hooks/IWindowSize';

export const useWindowSize = (): IWindowSize => {
  const [windowSize, setWindowSize] = React.useState<IWindowSize>({
    width: undefined,
    height: undefined,
  });

  const handleResize = (): void => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};
