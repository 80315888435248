import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';

export const Table = styled(TableBase)`
  margin-top: 12px;
  max-width: 784px;
  width: 100%;

  @media only screen and (max-width: 1280px) {
    max-width: unset;
  }

  tr:nth-child(even) {
    background-color: #2b2e38;
  }

  & > table > tbody > tr > td {
    padding: 10px 12px;
  }

  & > table > tbody > tr > td:first-child {
    padding: 10px 12px 10px 24px;
  }

  & > table > tbody > tr > td:last-child {
    padding: 10px 24px 10px 12px;
  }

  & > table > thead > tr > th {
    padding: 10px 12px;
  }

  & > table > thead > tr > th:first-child {
    padding: 10px 12px 10px 24px;
  }

  & > table > thead > tr > th:last-child {
    padding: 10px 24px 10px 12px;
  }
`;
