import styled from 'styled-components/macro';

export const Button = styled.button`
  background-color: unset;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.green.light};
`;

export const OptionsWrapper = styled.div`
  display: flex;
`;

export const Input = styled.input`
  background-color: unset;
  border: none;
  max-width: 236px;
  width: 100%;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.font};

  &:focus {
    outline: none;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  width: 100%;
`;
