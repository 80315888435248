import React from 'react';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';

import Integrations from './Integrations';
import Triggers from './Triggers';
import MissingCredential from './Triggers/MissingCredential';

import * as S from './styles';

const SideModal: React.FC = () => {
  const {
    showRequestIntegrationByTrigger,
    toggleShowRequestIntegrationByTrigger,
  } = useAutomation();

  return (
    <>
      <S.Wrapper>
        <Integrations />
        <Triggers />
      </S.Wrapper>

      {showRequestIntegrationByTrigger && (
        <MissingCredential
          isOpen={showRequestIntegrationByTrigger}
          toggle={toggleShowRequestIntegrationByTrigger}
        />
      )}
    </>
  );
};

export default SideModal;
