import { AxiosResponse } from 'axios';

import {
  ICreateAddressProps,
  IDeleteAddressProps,
  IGetAddressPromiseResponse,
  IGetAddressProps,
  IGetAddressResponse,
  IUpdateAddressProps,
  IUpdateAddressResponse,
} from '@domain/interfaces/common/address/IAddress';

import { useFetch } from '@providers/Fetch/useFetch';

import { ApiService } from '@services/api';

import { USER_API_DOMAIN } from '@constants/api';

export class AddressService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getAddress({ storeAliasId }: IGetAddressProps): IGetAddressResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/addresses`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      address: data?.address,
      error,
      mutate,
      isLoading,
      isValidating,
    };
  }

  public getAddressPromise({
    storeAliasId,
  }: IGetAddressProps): Promise<AxiosResponse<IGetAddressPromiseResponse>> {
    return this.apiService.get<IGetAddressPromiseResponse>(
      `/api/v1/users/stores/${storeAliasId}/addresses`,
    );
  }

  public createAddress({
    storeAliasId,
    data,
  }: ICreateAddressProps): Promise<AxiosResponse<IGetAddressResponse>> {
    return this.apiService.post<IGetAddressResponse>(
      `/api/v1/users/stores/${storeAliasId}/addresses`,
      { ...data },
    );
  }

  public updateAddress({
    storeAliasId,
    data,
  }: IUpdateAddressProps): Promise<AxiosResponse<IUpdateAddressResponse>> {
    return this.apiService.put<IUpdateAddressResponse>(
      `/api/v1/users/stores/${storeAliasId}/addresses`,
      { ...data },
    );
  }

  public deleteAddress({ storeAliasId }: IDeleteAddressProps): Promise<AxiosResponse<void>> {
    return this.apiService.delete<void>(`/api/v1/users/stores/${storeAliasId}/address`);
  }
}

const addressService = new AddressService(USER_API_DOMAIN);

export default addressService;
