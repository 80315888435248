import React from 'react';
import { SimpleClose, SimpleMinus, SimplePlus } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { useEditVariant } from '@helpers/hooks/pages/dashboard/kit/useEditVariant';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import VariantInput from './VariantInput';

import * as S from './styles';

const FocusedVariant: React.FC = () => {
  const theme = useTheme();
  const {
    isVariantsComponentOpen,
    selectedVariant,
    removeVariant,
    handleCardExpanded,
    quantity,
    increaseQuantity,
    decreaseQuantity,
    focusedVariantCardRef,
    onCardBlur,
    index,
  } = useEditVariant();

  const [isVariantImageError, setIsVariantImageError] = React.useState<boolean>(false);

  const onBlur = React.useCallback(
    event => {
      const isBlurringOutside = !event.currentTarget.contains(event.relatedTarget);

      if (isBlurringOutside && !isVariantsComponentOpen) {
        onCardBlur();
        handleCardExpanded();
      }
    },
    [handleCardExpanded, isVariantsComponentOpen, onCardBlur],
  );

  React.useEffect(() => {
    focusedVariantCardRef?.current?.focus();
  }, [focusedVariantCardRef]);

  return (
    <S.Wrapper tabIndex={0} onBlur={onBlur} ref={focusedVariantCardRef}>
      <S.Title fontWeight={EHeadingWeight.REGULAR} type={EHeadingSize.H5}>
        Adicionar variante
      </S.Title>

      <S.VariantWrapper>
        {selectedVariant ? (
          <S.Variant>
            <S.VariantImageAndDetails>
              {!isVariantImageError && selectedVariant?.variant?.image_url ? (
                <S.VariantImage
                  src={selectedVariant?.variant?.image_url}
                  alt="Profitfy.me Variante"
                  onError={() => setIsVariantImageError(true)}
                />
              ) : (
                <S.NoImage />
              )}

              <S.VariantDetails>
                <S.VariantName>{selectedVariant.variant?.name}</S.VariantName>
                <S.VariantSku>{`SKU: ${selectedVariant.variant?.sku}`}</S.VariantSku>
              </S.VariantDetails>
            </S.VariantImageAndDetails>

            <S.RemoveButton type="button" onClick={removeVariant}>
              <SimpleClose size={16} color={theme.colors.gray[2]} />
            </S.RemoveButton>
          </S.Variant>
        ) : (
          <VariantInput />
        )}

        <S.VariantNumberText>{`#${index + 1}`}</S.VariantNumberText>
      </S.VariantWrapper>

      <S.QuantityWrapper>
        <S.QuantityLabel>Quantidade</S.QuantityLabel>
        <S.QuantityButtonsWrapper>
          <S.RemoveQuantityButton onClick={decreaseQuantity} type="button">
            <SimpleMinus size={14} color={theme.colors.gray[1]} />
          </S.RemoveQuantityButton>
          <S.QuantityText>{quantity}</S.QuantityText>
          <S.AddQuantityButton onClick={increaseQuantity} type="button">
            <SimplePlus size={14} color={theme.colors.gray[1]} />
          </S.AddQuantityButton>
        </S.QuantityButtonsWrapper>
      </S.QuantityWrapper>
    </S.Wrapper>
  );
};

export default FocusedVariant;
