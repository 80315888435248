import React from 'react';

import { OnboardingTagProvider } from '@helpers/hooks/pages/dashboard/productAnalytics/useOnboardingTag';

import Content from '@components/Dashboard/ProductAnalytics/ProductTagOnboarding/NewTag';

const NewTag: React.FC = () => {
  return (
    <OnboardingTagProvider>
      <Content />
    </OnboardingTagProvider>
  );
};

export default NewTag;
