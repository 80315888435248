import React from 'react';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { Plus } from '@profitfy/pakkun-icons';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  const randomArray = Array.from(Array(10).keys());

  return (
    <S.Container>
      <S.Header>
        <S.Title>Custos de kit</S.Title>

        <S.NewKitButton icon={Plus}>Cadastrar novo kit</S.NewKitButton>
      </S.Header>

      <S.KitTable>
        <S.KitTable.Header>
          <S.KitTable.Row>
            <S.KitTable.Head>Kit</S.KitTable.Head>
            <S.KitTable.Head align={EHeadAlign.RIGHT}>Quantidade</S.KitTable.Head>
            <S.KitTable.Head>Itens</S.KitTable.Head>
            <S.KitTable.Head align={EHeadAlign.RIGHT}>Custo do kit</S.KitTable.Head>
            <S.KitTable.Head align={EHeadAlign.CENTER}>Status</S.KitTable.Head>
            <S.KitTable.Head />
          </S.KitTable.Row>
        </S.KitTable.Header>

        <S.KitTable.Body>
          {randomArray.map(index => (
            <S.KitTable.Row borderBottom>
              <S.KitTable.Data>
                <Skeleton key={index} width={100} height={25} />
              </S.KitTable.Data>

              <S.KitTable.Data align={EHeadAlign.RIGHT}>
                <Skeleton key={index} width={50} height={25} />
              </S.KitTable.Data>

              <S.KitTable.Data>
                <Skeleton key={index} width={100} height={25} />
              </S.KitTable.Data>

              <S.KitTable.Data align={EHeadAlign.RIGHT}>
                <Skeleton key={index} width={100} height={25} />
              </S.KitTable.Data>

              <S.KitTable.Data align={EHeadAlign.CENTER}>
                <Skeleton key={index} width={100} height={25} />
              </S.KitTable.Data>

              <S.KitTable.Data />
            </S.KitTable.Row>
          ))}
        </S.KitTable.Body>
      </S.KitTable>
    </S.Container>
  );
};

export default SkeletonLoading;
