import React from 'react';
import { useParams } from 'react-router-dom';

import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';
import { ICustomValue } from '@domain/interfaces/dashboard/CustomValues/ICustomValue';
import { IParams } from '@domain/interfaces/IParams';
import customValueService from '@services/pages/dashboard/customValue/customValue';
import customValuesCategoriesService from '@services/pages/dashboard/customValuesCategories/customValueCategories';
import { ISelectedFilter } from '@domain/interfaces/components/IFilter';
import { PRE_DEFINED_CUSTOM_VALUES_CATEGORIES } from '@constants/predefinedCustomValuesCategories';
import { ICustomValuesInContext } from '@domain/interfaces/dashboard/CustomValues/ICustomValuesContext';
import { parseDataFilter } from '@helpers/utils/components/filter';

const CustomValuesInContext = React.createContext<ICustomValuesInContext | null>(null);

export const CustomValuesInProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { analytics, user } = useConfig();

  const [filterData, setFilterData] = React.useState<Array<ISelectedFilter>>([]);
  const [page, setPage] = React.useState<number>(0);
  const [pageCount, setPageCount] = React.useState<number>(1);
  const [isNewCustomValueSidemodalOpen, setIsNewCustomValueSidemodalOpen] = React.useState<boolean>(
    false,
  );
  const [
    isEditCustomValueSidemodalOpen,
    setIsEditCustomValueSidemodalOpen,
  ] = React.useState<boolean>(false);
  const [isCreatingCustomValue, setIsCreatingCustomValue] = React.useState<boolean>(false);
  const [
    isCreatingPredefinedCustomValueCategory,
    setIsCreatingPredefinedCustomValueCategory,
  ] = React.useState<boolean>(false);
  const [isUpdatingCustomValue, setIsUpdatingCustomValue] = React.useState<boolean>(false);
  const [isDeletingCustomValue, setIsDeletingCustomValue] = React.useState<boolean>(false);
  const [customValueToEdit, setCustomValueToEdit] = React.useState<ICustomValue | undefined>(
    undefined,
  );
  const [selectedCustomValueChecked, setSelectedCustomValueChecked] = React.useState<
    ICustomValue | undefined
  >(undefined);

  const {
    customValues,
    isValidating: isValidatingCustomValues,
    error: customValuesError,
    isLoading: isLoadingCustomValues,
    mutate: mutateCustomValues,
    totalPages,
  } = customValueService.getCustomValues({
    storeAliasId,
    page,
    filter: {
      is_spend: 'false',
      ...parseDataFilter(filterData),
    },
  });
  const {
    customValuesCategories,
    error: customValuesCategoriesError,
    isLoading: isLoadingCustomValuesCategories,
    mutate: mutateCustomValuesCategories,
    isValidating: isValidatingCustomValuesCategories,
  } = customValuesCategoriesService.getCustomValuesCategories({ storeAliasId });

  const handleFilterData = React.useCallback(newData => {
    setFilterData(newData);
  }, []);

  const handleSelectedCustomValueChecked = React.useCallback(
    (customValue: ICustomValue | undefined) => setSelectedCustomValueChecked(customValue),
    [],
  );

  const handleCustomValueToEdit = React.useCallback(
    (customValue: ICustomValue | undefined) => setCustomValueToEdit(customValue),
    [],
  );

  const onPageChange = React.useCallback(({ selected }) => setPage(selected), []);

  const handleEditCustomValueSidemodalOpen = React.useCallback(
    () => setIsEditCustomValueSidemodalOpen(!isEditCustomValueSidemodalOpen),
    [isEditCustomValueSidemodalOpen],
  );

  const handleNewCustomValueSidemodalOpen = React.useCallback(
    () => setIsNewCustomValueSidemodalOpen(!isNewCustomValueSidemodalOpen),
    [isNewCustomValueSidemodalOpen],
  );

  const findCustomValueCategoryData = React.useCallback(
    (customValue: ICustomValue) => {
      let foundCategory;

      if (customValuesCategories) {
        foundCategory = customValuesCategories.find(
          category => category.id === customValue.custom_value_category_id,
        );
      }

      return foundCategory;
    },
    [customValuesCategories],
  );

  const createPredefinedCustomValuesCategories = React.useCallback(
    async (preDefinedCategoriesToBeCreated: typeof PRE_DEFINED_CUSTOM_VALUES_CATEGORIES) => {
      setIsCreatingPredefinedCustomValueCategory(true);

      try {
        if (preDefinedCategoriesToBeCreated.length) {
          const promises = preDefinedCategoriesToBeCreated.map(category => {
            return customValuesCategoriesService.createCustomValueCategory({
              storeAliasId,
              data: category,
            });
          });

          await Promise.all(promises);

          await mutateCustomValuesCategories();
        }

        setIsCreatingPredefinedCustomValueCategory(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsCreatingPredefinedCustomValueCategory(false);
      }
    },
    [storeAliasId, toast, mutateCustomValuesCategories],
  );

  const verifyPreDefinedCustomValuesCategories = React.useCallback(() => {
    const preDefinedCategoriesToBeCreated = PRE_DEFINED_CUSTOM_VALUES_CATEGORIES.filter(
      preDefinedCategory =>
        !customValuesCategories.find(category =>
          Boolean(category.name === preDefinedCategory.name),
        ),
    );

    createPredefinedCustomValuesCategories(preDefinedCategoriesToBeCreated);
  }, [customValuesCategories, createPredefinedCustomValuesCategories]);

  const createCustomValue = React.useCallback(
    async data => {
      setIsCreatingCustomValue(true);

      try {
        await customValueService.createCustomValue({
          storeAliasId,
          customValuesCategoryAliasId: data.category,
          data: {
            amount: data.amount,
            currency: data.currency,
            description: data.description,
            end_date: data.end_date,
            is_active: data.is_active,
            is_spend: false,
            period: data.period,
            start_date: data.start_date,
            send_notification: false,
          },
        });

        analytics?.track(
          'Custom Values Added',
          {
            amount: data.amount,
            currency: data.currency,
            description: data.description,
            period: data.period,
            end_date: data.end_date,
            start_date: data.start_date,
            is_spend: false,
            email: user?.email,
          },
          { context: { groupId: storeAliasId } },
        );
        analytics?.trackHubSpot(storeAliasId, { additional_value_added: true });

        toast.success('Valor adicional criado com sucesso!');

        setIsNewCustomValueSidemodalOpen(false);

        await mutateCustomValues();
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsCreatingCustomValue(false);
      }
    },
    [mutateCustomValues, toast, storeAliasId, analytics, user],
  );

  const updateCustomValue = React.useCallback(
    async data => {
      setIsUpdatingCustomValue(true);

      try {
        await customValueService.updateCustomValue({
          storeAliasId,
          customValueAliasId: customValueToEdit?.alias_id,
          data,
        });

        toast.success('Valor adicional atualizado com sucesso!');

        handleEditCustomValueSidemodalOpen();

        await mutateCustomValues();
      } catch {
        toast.error(
          'Ocorreu um problema ao atualizar o valor adicional! Por favor, tente novamente.',
        );
      } finally {
        setIsUpdatingCustomValue(false);
      }
    },
    [
      customValueToEdit,
      toast,
      mutateCustomValues,
      handleEditCustomValueSidemodalOpen,
      storeAliasId,
    ],
  );

  const deleteCustomValue = React.useCallback(
    async customValueAliasId => {
      setIsDeletingCustomValue(true);

      try {
        await customValueService.deleteCustomValue({ storeAliasId, customValueAliasId });

        toast.success('Valor adicional excluído com sucesso!');

        await mutateCustomValues();
      } catch {
        toast.error(
          'Ocorreu um problema ao excluir o valor adicional! Por favor, tente novamente.',
        );
      } finally {
        setIsDeletingCustomValue(false);
      }
    },
    [mutateCustomValues, toast, storeAliasId],
  );

  React.useEffect(() => {
    if (totalPages) {
      setPageCount(totalPages);
    }
  }, [totalPages]);

  return (
    <CustomValuesInContext.Provider
      value={{
        createCustomValue,
        customValueToEdit,
        customValues,
        customValuesCategories,
        customValuesCategoriesError,
        customValuesError,
        deleteCustomValue,
        findCustomValueCategoryData,
        handleCustomValueToEdit,
        handleEditCustomValueSidemodalOpen,
        handleNewCustomValueSidemodalOpen,
        handleSelectedCustomValueChecked,
        isCreatingCustomValue,
        isCreatingPredefinedCustomValueCategory,
        isDeletingCustomValue,
        isEditCustomValueSidemodalOpen,
        isLoadingCustomValues,
        isLoadingCustomValuesCategories,
        isNewCustomValueSidemodalOpen,
        isUpdatingCustomValue,
        isValidatingCustomValues,
        isValidatingCustomValuesCategories,
        mutateCustomValues,
        mutateCustomValuesCategories,
        onPageChange,
        page,
        pageCount,
        selectedCustomValueChecked,
        updateCustomValue,
        verifyPreDefinedCustomValuesCategories,
        handleFilterData,
        filterData,
      }}
    >
      {children}
    </CustomValuesInContext.Provider>
  );
};

export const useCustomValuesIn = (): ICustomValuesInContext => {
  const context = React.useContext(CustomValuesInContext);

  if (!context) {
    throw new Error('useCustomValuesIn must be used within CustomValuesInProvider');
  }

  return context;
};
