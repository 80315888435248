import React from 'react';

import { useCampaigns } from '@helpers/hooks/pages/dashboard/campaigns/useCampaigns';
import { EHeadAlign } from '@domain/enums/components/ETable';
import Paginate from '@components/common/core/Utils/Paginate';
import EmptyData from '@components/common/core/DataDisplay/EmptyData';
import Row from './Row';
import NotOperationalModal from './NotOperationalModal';

import * as S from './styles';

const Table: React.FC = () => {
  const {
    isAllAdSenseCampaignsChecked,
    handleAllAdSenseCampaignsChecked,
    adSenseCampaignsPage,
    adSenseCampaignsPageCount,
    onAdSenseCampaignsPageChange,
    adSenseCampaigns,
  } = useCampaigns();

  const [isNotOperationalModalOpen, setIsNotOperationalModalOpen] = React.useState<boolean>(false);

  const handleIsNotOperationalModalOpen = React.useCallback(() => {
    setIsNotOperationalModalOpen(state => !state);
  }, []);

  React.useEffect(() => {
    const notOperationalCampaigns = adSenseCampaigns?.filter(campaign => !campaign.is_operational);

    if (notOperationalCampaigns?.length > 0) {
      setIsNotOperationalModalOpen(true);
    }
  }, [adSenseCampaigns]);

  return (
    <>
      <S.Wrapper>
        <S.Table>
          <S.Table.Header>
            <S.Table.Row>
              <S.Table.Head>
                <S.Checkbox
                  checked={isAllAdSenseCampaignsChecked}
                  onChange={handleAllAdSenseCampaignsChecked}
                />
              </S.Table.Head>
              <S.Table.Head>Nome da Campanha</S.Table.Head>
              <S.Table.Head align={EHeadAlign.CENTER}>Clicks</S.Table.Head>
              <S.Table.Head align={EHeadAlign.CENTER}>Impressões</S.Table.Head>
              <S.Table.Head align={EHeadAlign.CENTER}>Conversões</S.Table.Head>
              <S.Table.Head align={EHeadAlign.CENTER}>Todas conversões</S.Table.Head>
              <S.Table.Head align={EHeadAlign.CENTER}>Custos</S.Table.Head>
              <S.Table.Head align={EHeadAlign.CENTER}>Status no Provedor</S.Table.Head>
              <S.Table.Head align={EHeadAlign.CENTER}>Status</S.Table.Head>
              <S.Table.Head align={EHeadAlign.CENTER} />
            </S.Table.Row>
          </S.Table.Header>

          <S.Table.Body>
            {adSenseCampaigns?.length ? (
              <>
                {adSenseCampaigns?.map((adSenseCampaign, index) => (
                  <Row
                    adSenseCampaign={adSenseCampaign}
                    key={adSenseCampaign.alias_id}
                    index={index}
                  />
                ))}
              </>
            ) : (
              <S.Table.Row>
                <S.Table.Data colSpan={10} align={EHeadAlign.CENTER}>
                  <EmptyData content="Não encontramos nenhuma conta de anúncio." />
                </S.Table.Data>
              </S.Table.Row>
            )}
          </S.Table.Body>

          {Boolean(adSenseCampaigns?.length) && (
            <S.Table.Footer>
              <S.Table.Row>
                <S.Table.Data colSpan={10} align={EHeadAlign.RIGHT}>
                  <Paginate
                    forcePage={adSenseCampaignsPage}
                    pageCount={adSenseCampaignsPageCount}
                    onPageChange={onAdSenseCampaignsPageChange}
                  />
                </S.Table.Data>
              </S.Table.Row>
            </S.Table.Footer>
          )}
        </S.Table>
      </S.Wrapper>

      <NotOperationalModal
        isOpen={isNotOperationalModalOpen}
        toggle={handleIsNotOperationalModalOpen}
      />
    </>
  );
};

export default Table;
