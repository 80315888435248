import { API_DOMAIN } from '@constants/api';
import {
  IGenerateURLProps,
  IGetNuvemShopCredentialProps,
  IUpdateNuvemShopCredentialProps,
} from '@domain/interfaces/dashboard/IntegrationCenter/Marketplace/NuvemShop/INuvemShopAuth';
import { ApiService } from '@services/api';

export class NuvemShopService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getNuvemShopCredentialPromise({
    storeAliasId,
  }: IGetNuvemShopCredentialProps): Promise<any> {
    return this.apiService.get(`/api/v1/users/stores/${storeAliasId}/nuvem-shop-credentials`);
  }

  public updateNuvemShopCredential({
    storeAliasId,
    nuvemShopCredentialAliasId,
    data,
  }: IUpdateNuvemShopCredentialProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/nuvem-shop-credentials/${nuvemShopCredentialAliasId}/types`,
      {
        ...data,
      },
    );
  }

  public generateURL({ storeAliasId }: IGenerateURLProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/nuvem-shop/auth/generate-url`,
    );
  }

  public disableCredential(storeAliasId?: string): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/nuvem-shop-credentials/disable`,
    );
  }
}

const nuvemShopService = new NuvemShopService(API_DOMAIN);

export default nuvemShopService;
