import React from 'react';
import { useParams } from 'react-router-dom';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import shopifyAuthService from '@services/pages/dashboard/integrationCenter/marketplace/shopify/shopifyAuth';
import { IParams } from '@domain/interfaces/IParams';
import { useToast } from '@helpers/hooks/useToast';

import DisableIntegrationFooter from '@components/Dashboard/IntegrationCenter/Sidemodal/DisableIntegrationFooter';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import * as S from './styles';

const CredentialOption: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { mutateStore } = useStoreConfig();

  const [isDeletingCredential, setIsDeletingCredential] = React.useState<boolean>(false);

  const onDeleteCredential = React.useCallback(async () => {
    setIsDeletingCredential(true);

    try {
      await shopifyAuthService.disableCredential(storeAliasId);

      toast.success('Credencial desativada com sucesso!');

      await mutateStore();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsDeletingCredential(false);
    }
  }, [mutateStore, storeAliasId, toast]);

  return (
    <S.Content>
      <S.CredentialDescriptionWrapper>
        <S.CredentialDescriptionTitle type={EHeadingSize.H5}>
          O que acontece se eu desativar minha integração?
        </S.CredentialDescriptionTitle>
        <S.CredentialDescriptionText>
          Ao desativar a integração,
          <strong> não será mais possível </strong>
          processar os pedidos da Shopify.
        </S.CredentialDescriptionText>
      </S.CredentialDescriptionWrapper>

      <DisableIntegrationFooter
        onDeleteCredential={onDeleteCredential}
        isDeletingCredential={isDeletingCredential}
      />
    </S.Content>
  );
};

export default CredentialOption;
