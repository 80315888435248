import styled, { css } from 'styled-components/macro';

import { IPercentageProgressText } from '@domain/interfaces/onboarding/processingPage/IProcessingPage';

import TextBase from '@components/common/core/DataDisplay/Text';

export const ProcessingIconWrapper = styled.div`
  max-width: 24px;
  max-height: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PercentageProgressText = styled(TextBase)<IPercentageProgressText>`
  line-height: 120%;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[3]};
  display: inline-block;
  margin-left: 28px;
  transition: all 0.3s;
  width: 48px;

  ${({ isGreaterThanZero, theme }) =>
    isGreaterThanZero &&
    css`
      color: ${theme.colors.green.default};
    `};
`;

export const ProgressBar = styled.div`
  position: absolute;
  height: 10px;
  width: 0;
  background-color: ${({ theme }) => theme.colors.green.default};
  border-radius: 5px;
  transition: all 0.3s;
`;

export const ProgressBarWrapper = styled.div`
  max-width: 528px;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 1128px) {
    max-width: unset;
  }
`;

export const ProcessingTitle = styled(TextBase)`
  width: 224px;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[1]};
  display: inline-block;
  margin-left: 18px;
`;

export const InQueueIcon = styled.span`
  display: block;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.gray[3]};
`;

export const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

export const IconAndTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ProcessingGroup = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;

  @media only screen and (max-width: 1128px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ProcessingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

export const Title = styled(TextBase)`
  font-weight: 500;
  font-size: 1.125rem;
  margin-bottom: 28px;
  display: inline-block;
`;

export const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray[4]};
  border-radius: 6px;
  padding: 28px 32px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 36px;
`;
