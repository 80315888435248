import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import ButtonBase from '@components/common/core/Inputs/Button';

export const DeleteStoreButton = styled.button`
  background-color: unset;
  border: unset;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.danger.default};
  font-weight: 700;
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
`;

export const UpdateStoreButton = styled(ButtonBase)``;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Input = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 32px;
`;

export const Form = styled.form``;

export const Wrapper = styled.div`
  padding: 32px;
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background[8]};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

export const Title = styled(HeadingBase)`
  font-weight: 400;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const SubTitle = styled(TextBase)`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[2]};
  margin-bottom: 32px;
`;
