import React from 'react';
import { BarChart } from '@profitfy/pakkun-icons';

import { useFinancialData } from '@helpers/hooks/pages/dashboard/financialData/useFinancialData';
import { IFinancialDataFormProps } from '@domain/interfaces/dashboard/FinancialData/IFinancialData';
import SkeletonLoading from './SkeletonLoading';
import LegalPerson from '../LegalPerson';
import NaturalPerson from '../NaturalPerson';

import * as S from './styles';

const FinancialData: React.FC<IFinancialDataFormProps> = ({
  handleNewLegalPersonSideModalOpen,
}) => {
  const {
    legalPerson,
    isLoadingNaturalPerson,
    isLoadingLegalPerson,
    isValidatingLegalPerson,
    isValidatingNaturalPerson,
  } = useFinancialData();

  if (
    isLoadingNaturalPerson ||
    isLoadingLegalPerson ||
    isValidatingLegalPerson ||
    isValidatingNaturalPerson
  ) {
    return <SkeletonLoading />;
  }

  return (
    <S.FinancialDataWrapper>
      <S.TitleAndNewLealPersonWrapper>
        <S.TitleWrapper>
          <BarChart size={24} outline />
          <S.Title>Dados financeiros</S.Title>
        </S.TitleWrapper>

        {!legalPerson && (
          <S.NewLegalPersonButton type="button" onClick={handleNewLegalPersonSideModalOpen}>
            Cadastrar dados jurídicos
          </S.NewLegalPersonButton>
        )}
      </S.TitleAndNewLealPersonWrapper>

      {legalPerson ? <LegalPerson /> : <NaturalPerson />}
    </S.FinancialDataWrapper>
  );
};

export default FinancialData;
