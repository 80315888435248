import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';
import { IGetHomeSetupResponse, IGetHomeSetupProps } from '@domain/interfaces/dashboard/Home/IHome';

export class HomeService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getHomeSetup({ storeAliasId }: IGetHomeSetupProps): IGetHomeSetupResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/onboarding`,
      this.apiService,
    );

    const isLoading = !error && !data;

    return {
      homeSetup: data?.setup,
      error,
      mutate,
      isLoading,
      isValidating,
    };
  }
}

const homeService = new HomeService(API_DOMAIN);

export default homeService;
