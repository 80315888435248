import styled from 'styled-components/macro';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

export const TooltipTrigger = styled(TooltipPrimitive.Trigger)`
  background: unset;
  padding: unset;
  margin: unset;
  box-shadow: unset;
  border: unset;
  text-align: left;
`;
