import React from 'react';

import { SimpleCheck } from '@profitfy/pakkun-icons';
import { EHeadingSize } from '@domain/enums/components/EHeading';
import { EButtonTextColor } from '@domain/enums/components/EButton';
import { useHistory, useParams } from 'react-router-dom';
import { IParams } from '@domain/interfaces/IParams';
import { useConfig } from '@helpers/hooks/useConfig';

import WelcomeImg from '@assets/dashboard/accessManagement/welcome.svg';

import * as S from './styles';

const Welcome: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();

  const { analytics, user } = useConfig();
  const history = useHistory();

  const handleInviteTeamButtonClick = React.useCallback(async () => {
    analytics?.track(
      'Invite Members Button Clicked',
      {
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );

    history.push(`/${storeAliasId}/dashboard/access-manager/onboarding/select-group`);
  }, [history, storeAliasId, analytics, user]);

  return (
    <S.ContentWrapper>
      <S.RightSide>
        <S.Heading type={EHeadingSize.H2}>Convide sua Equipe</S.Heading>

        <S.BenefitsWrapper>
          <S.Benefit>
            <SimpleCheck size={12} />
            <S.BenefitDescription>Convidar colaboradores</S.BenefitDescription>
          </S.Benefit>

          <S.Benefit>
            <SimpleCheck size={12} />
            <S.BenefitDescription>
              Definir grupos de acesso para os colaboradores
            </S.BenefitDescription>
          </S.Benefit>

          <S.Benefit>
            <SimpleCheck size={12} />
            <S.BenefitDescription>Delegar quais acessos esses grupos terão</S.BenefitDescription>
          </S.Benefit>
        </S.BenefitsWrapper>

        <S.InviteTeamButton onClick={handleInviteTeamButtonClick} textColor={EButtonTextColor.DARK}>
          Convidar minha equipe
        </S.InviteTeamButton>
      </S.RightSide>

      <S.Image src={WelcomeImg} alt="profitfy-access-management-welcome" />
    </S.ContentWrapper>
  );
};

export default Welcome;
