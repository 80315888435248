import React from 'react';
import { ResponsiveContainer, PieChart as Chart, Pie, Cell } from 'recharts';

import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';

import * as S from './styles';

const PieChart: React.FC = () => {
  const { financialDetailsReport } = useFinancialDashboard();

  const percentage = financialDetailsReport.goal
    ? (financialDetailsReport.goal.current_amount * 100) / financialDetailsReport.goal?.goal_amount
    : 0;

  const data = [
    {
      name: 'Percentage',
      value: percentage / 2,
    },
    {
      name: 'Total',
      value: 100 - percentage / 2,
    },
  ];

  const colors = ['#25CFBB', '#515562'];

  return (
    <S.ChartWrapper>
      <ResponsiveContainer width="100%">
        <Chart>
          <Pie
            data={data}
            innerRadius="85%"
            outerRadius="100%"
            fill="#000"
            paddingAngle={0}
            dataKey="value"
            strokeOpacity={0}
          >
            {data.map((pie, index) => (
              <Cell key={`cell-${pie.name}`} fill={colors[index]} />
            ))}
          </Pie>
        </Chart>
      </ResponsiveContainer>
    </S.ChartWrapper>
  );
};

export default PieChart;
