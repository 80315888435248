import styled, { css } from 'styled-components/macro';

import { EHeadAlign } from '@domain/enums/components/ETable';

export const Data = styled.td`
  color: ${({ theme }) => theme.colors.font};
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  font-size: 16px;

  ${({ align }) =>
    align === EHeadAlign.CENTER &&
    css`
      text-align: center;
    `};

  ${({ align }) =>
    align === EHeadAlign.RIGHT &&
    css`
      text-align: right;
    `};
`;
