export enum EShopifyGateways {
  APPMAX = 'appmax',
  MERCADO_PAGO = 'mercado-pago',
  CLOUD_FOX = 'cloud-fox',
  UPNID = 'upnid',
  PAG_SEGURO = 'pag-seguro',
  CIELO = 'cielo',
  PAGARME = 'pagarme',
  HUB_SALE = 'hub-sale',
  CART_PANDA_PAY = 'cart-panda-pay',
}

export enum EShopifyCredentialType {
  CUSTOM_APP = 'CUSTOM_APP',
  PUBLIC_APP = 'PUBLIC_APP',
}

export enum EShopifyType {
  STORE = 'STORE',
  ABANDONED_CART = 'ABANDONED_CART',
  PIX = 'PIX',
  BOLETO = 'BOLETO',
  REJECTED_PAYMENT = 'REJECTED_PAYMENT',
}
