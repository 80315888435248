import React from 'react';
import { useLocation } from 'react-router-dom';

import Route from '@components/routes/Route';
import Platforms from '@pages/Onboarding/TraditionalEcommerce/Platforms';
import Gateways from '@pages/Onboarding/TraditionalEcommerce/Gateways';
import Marketing from '@pages/Onboarding/TraditionalEcommerce/Marketing';
import Integrations from '@pages/Onboarding/TraditionalEcommerce/Integrations';

import { EcommerceProvider } from '@helpers/hooks/pages/onboarding/useEcommerce';

const Ecommerce: React.FC = () => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location]);

  return (
    <EcommerceProvider>
      <Route
        isPrivate
        exact
        path="/onboarding/:storeAliasId/ecommerce/integrations"
        component={Integrations}
      />
      <Route
        isPrivate
        exact
        path="/onboarding/:storeAliasId/ecommerce/integrations/platforms"
        component={Platforms}
      />
      <Route
        isPrivate
        exact
        path="/onboarding/:storeAliasId/ecommerce/integrations/gateways"
        component={Gateways}
      />
      <Route
        isPrivate
        exact
        path="/onboarding/:storeAliasId/ecommerce/integrations/marketing"
        component={Marketing}
      />
    </EcommerceProvider>
  );
};

export default React.memo(Ecommerce);
