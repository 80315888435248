import React from 'react';
import Table from './Table';
import * as S from './styles';

const ProfitEstimate: React.FC = () => {
  return (
    <S.Container>
      <S.Title>Estimador de lucro</S.Title>
      <Table />
    </S.Container>
  );
};

export default ProfitEstimate;
