import {
  Dsers,
  Shopify,
  NuvemShop,
  CartPanda,
  MercadoLivre,
  Bling,
  Yampi,
  Woocommerce,
  Kiwify,
  Evermart,
  Hotmart,
  IIconProps,
} from '@profitfy/pakkun-icons';

export const getProviderIcon = (provider: string): React.FC<IIconProps> =>
  ({
    DSERS: Dsers,
    SHOPIFY: Shopify,
    NUVEM_SHOP: NuvemShop,
    CART_PANDA: CartPanda,
    MERCADO_LIVRE: MercadoLivre,
    BLING: Bling,
    YAMPI: Yampi,
    WOO_COMMERCE: Woocommerce,
    KIWIFY: Kiwify,
    EVERMART: Evermart,
    HOTMART: Hotmart,
  }[provider] || Shopify);
