import * as yup from 'yup';

export const resetPasswordSchema = yup.object().shape({
  password: yup.string().required('Este campo é obrigatório'),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('password'), undefined],
      'A senha está diferente da senha informada no campo anterior.',
    ),
});
