import React from 'react';

import { EMessageContentType } from '@domain/enums/dashboard/automations/EMessage';
import { ETextSize } from '@domain/enums/components/EText';
import { IMessageTypeTags } from '@domain/interfaces/dashboard/Automations/IMessage';

import * as S from './styles';

const MessageTypeTags: React.FC<IMessageTypeTags> = ({ messageContents }) => {
  const [tags, setTags] = React.useState<Array<EMessageContentType>>([]);

  const maxTagsOfDisplay = 2;

  const tagsQuantity = tags.length;

  const hasMoreThanTwoTags = tagsQuantity > maxTagsOfDisplay;

  const tagsToDisplay = tags.slice(0, 2);

  const restTags = tags.filter(tag => {
    if (tagsToDisplay.includes(tag)) return false;

    return true;
  });

  const translateTag = (tag: EMessageContentType): string => {
    if (tag === EMessageContentType.TEXT) return 'texto';

    if (tag === EMessageContentType.IMAGE) return 'imagem';

    if (tag === EMessageContentType.VIDEO) return 'vídeo';

    return 'áudio';
  };

  React.useEffect(() => {
    if (messageContents) {
      const tagsArray: Array<EMessageContentType> = [];

      messageContents.forEach(messageContent => {
        if (messageContent.type === EMessageContentType.TEXT) {
          const hasTextContent = tagsArray.find(tag => tag === EMessageContentType.TEXT);

          if (!hasTextContent) {
            tagsArray.push(EMessageContentType.TEXT);
          }
        }

        if (messageContent.type === EMessageContentType.AUDIO) {
          const hasAudioContent = tagsArray.find(tag => tag === EMessageContentType.AUDIO);

          if (!hasAudioContent) {
            tagsArray.push(EMessageContentType.AUDIO);
          }
        }

        if (messageContent.type === EMessageContentType.IMAGE) {
          const hasImageContent = tagsArray.find(tag => tag === EMessageContentType.IMAGE);

          if (!hasImageContent) {
            tagsArray.push(EMessageContentType.IMAGE);
          }
        }

        if (messageContent.type === EMessageContentType.VIDEO) {
          const hasVideoContent = tagsArray.find(tag => tag === EMessageContentType.VIDEO);

          if (!hasVideoContent) {
            tagsArray.push(EMessageContentType.VIDEO);
          }
        }
      });

      setTags(tagsArray);
    }
  }, [messageContents]);

  return (
    <S.Wrapper>
      {tagsToDisplay.map(tag => {
        const translatedTag = translateTag(tag);

        return <S.BadgeTag color="">{translatedTag}</S.BadgeTag>;
      })}

      {hasMoreThanTwoTags && (
        <S.Tooltip>
          <S.TooltipTrigger>
            <S.BadgeTag color="">+ {restTags.length}</S.BadgeTag>
          </S.TooltipTrigger>

          <S.TooltipContent>
            {restTags.map(tag => {
              const translatedTag = translateTag(tag);

              return <S.TooltipText size={ETextSize.B5}>{translatedTag}</S.TooltipText>;
            })}

            <S.TooltipArrow />
          </S.TooltipContent>
        </S.Tooltip>
      )}
    </S.Wrapper>
  );
};

export default MessageTypeTags;
