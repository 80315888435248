import React from 'react';

import { getEnvironment } from '@helpers/utils/environment';
import { useLocalStorage } from '@helpers/hooks/useLocalStorage';

import { ETextSize, ETextWeight } from '@domain/enums/components/EText';

import * as S from './styles';

const CookiesUsage: React.FC = () => {
  const [cookies, setCookies] = useLocalStorage(`@profitfy:${getEnvironment()}/cookies`, false);

  const handleAcepptCookies = React.useCallback(() => {
    setCookies(true);
  }, [setCookies]);

  return (
    <>
      {!cookies && (
        <S.Modal>
          <S.Text size={ETextSize.B5}>
            <strong>Nós usamos cookies</strong> e outras tecnologias semelhantes para melhorar a sua
            experiência em nossos serviços. Ao utilizar nossos serviços, você concorda com tal
            monitoramento. Informamos que atualizamos nossa <strong>politica de privacidade</strong>
            .
          </S.Text>

          <S.AcepptCookiesButton onClick={handleAcepptCookies}>
            <S.AcepptCookiesButtonText size={ETextSize.B5} weight={ETextWeight.BOLD}>
              Permitir Todos os cookies
            </S.AcepptCookiesButtonText>
          </S.AcepptCookiesButton>
        </S.Modal>
      )}
    </>
  );
};

export default CookiesUsage;
