import React from 'react';
import { useParams } from 'react-router-dom';

import { ECalculateMode } from '@domain/enums/dashboard/tax/ETax';
import {
  ESynchronizationRequestType,
  ESynchronizationType,
} from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';
import {
  IDefaultTaxData,
  IPeriodTax,
  ITaxProvider,
} from '@domain/interfaces/dashboard/TaxesAndFees/Taxes/ITaxes';

import { useToast } from '@helpers/hooks/useToast';
import { useDate } from '@helpers/hooks/useDate';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useConfig } from '@helpers/hooks/useConfig';

import { getEnvironment } from '@helpers/utils/environment';

import { LONG_API_DOMAIN } from '@constants/api';

import taxService from '@services/pages/dashboard/tax/tax';

const TaxContext = React.createContext<ITaxProvider | null>(null);

export const TaxProvider: React.FC = ({ children }) => {
  const { addSynchronization } = useSynchronization();
  const { format, utcToZonedTime } = useDate();
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { analytics, selectedStore, user } = useConfig();

  const [historicTaxPage, setHistoricTaxPage] = React.useState<number>(0);
  const [historicTaxPageCount, setHistoricTaxPageCount] = React.useState<number>(1);
  const [isEditingTax, setIsEditingTax] = React.useState<boolean>(false);
  const [userPeriodTax, setUserPeriodTax] = React.useState<IPeriodTax | undefined>(undefined);
  const [isConfiguringDefaultTax, setIsConfiguringDefaultTax] = React.useState<boolean>(false);
  const [isCreatingPeriodTax, setIsCreatingPeriodTax] = React.useState<boolean>(false);
  const [isUpdatingHistoricTax, setIsUpdatingHistoricTax] = React.useState<boolean>(false);
  const [isDeletingHistoricTax, setIsDeletingHistoricTax] = React.useState<boolean>(false);
  const [isSyncPastTaxModalOpen, setIsSyncPastTaxModalOpen] = React.useState<boolean>(false);
  const [isSyncPastTaxSidemodalOpen, setIsSyncPastTaxSidemodalOpen] = React.useState<boolean>(
    false,
  );
  const [isNewTaxSideModalOpen, setIsNewTaxSideModalOpen] = React.useState<boolean>(false);
  const [isEditTaxSideModalOpen, setIsEditTaxSideModalOpen] = React.useState<boolean>(false);
  const [isWithAdsModalOpen, setIsWithAdsModalOpen] = React.useState<boolean>(false);
  const [isUpdateWithAdsModalOpen, setIsUpdateIsWithAdsModalOpen] = React.useState<boolean>(false);
  const [defaultTaxData, setDefaultTaxData] = React.useState<IDefaultTaxData | undefined>(
    undefined,
  );

  const {
    tax: defaultTax,
    isLoading: isLoadingDefaultTax,
    isValidating: isValidatingDefaultTax,
    error: defaultTaxError,
    mutate: mutateDefaultTax,
  } = taxService.getDefaultTax({ storeAliasId });

  const {
    taxes: historicTaxes,
    totalPages: historicTaxesTotalPages,
    error: historicTaxesError,
    isLoading: isLoadingHistoricTaxes,
    isValidating: isValidatingHistoricTaxes,
    mutate: mutateHistoricTaxes,
  } = taxService.listHistoricTaxes({ storeAliasId, page: historicTaxPage });

  const mutate = React.useCallback(async () => {
    const promises = [];

    if (defaultTaxError) promises.push(mutateDefaultTax());

    if (historicTaxesError) promises.push(mutateHistoricTaxes());

    await Promise.all(promises);
  }, [defaultTaxError, mutateDefaultTax, historicTaxesError, mutateHistoricTaxes]);

  const handleDefaultTaxData = React.useCallback(data => setDefaultTaxData(data), []);

  const handleWithAdsModalOpen = React.useCallback(
    () => setIsWithAdsModalOpen(state => !state),
    [],
  );

  const handleUpdateWithAdsModalOpen = React.useCallback(
    () => setIsUpdateIsWithAdsModalOpen(state => !state),
    [],
  );

  const handleEditTaxSideModalOpen = React.useCallback(() => {
    setIsEditTaxSideModalOpen(!isEditTaxSideModalOpen);
  }, [isEditTaxSideModalOpen]);

  const handleNewTaxSideModalOpen = React.useCallback(() => {
    setIsNewTaxSideModalOpen(!isNewTaxSideModalOpen);
  }, [isNewTaxSideModalOpen]);

  const onHistoricTaxesPageChange = React.useCallback(
    ({ selected }) => setHistoricTaxPage(selected),
    [],
  );

  const handleSyncPastTaxModalOpen = React.useCallback(
    () => setIsSyncPastTaxModalOpen(!isSyncPastTaxModalOpen),
    [isSyncPastTaxModalOpen],
  );

  const handleSyncPastTaxSideModalOpen = React.useCallback(
    () => setIsSyncPastTaxSidemodalOpen(!isSyncPastTaxSidemodalOpen),
    [isSyncPastTaxSidemodalOpen],
  );

  const handleUserPeriodTax = React.useCallback(tax => {
    setUserPeriodTax(tax);
  }, []);

  const handleEditingTax = React.useCallback(() => {
    setIsEditingTax(!isEditingTax);
  }, [isEditingTax]);

  const configDefaultTax = React.useCallback(
    async (data: any) => {
      setIsConfiguringDefaultTax(true);

      try {
        if (!defaultTax) {
          await taxService.createDefaultTax({ storeAliasId, data });

          analytics?.track(
            'Taxes Added',
            {
              calculate_mode: data.calculate_mode,
              percentage_amount: data.percentage_amount,
              regime: data.regime,
              email: user?.email,
            },
            { context: { groupId: selectedStore?.alias_id } },
          );

          toast.success('Imposto padrão criado com sucesso!');

          handleNewTaxSideModalOpen();

          mutateDefaultTax();
          mutateHistoricTaxes();
        } else {
          await taxService.updateDefaultTax({
            storeAliasId,
            data,
            taxAliasId: defaultTax.alias_id,
          });

          toast.success('Imposto padrão atualizado com sucesso!');

          handleNewTaxSideModalOpen();

          mutateDefaultTax();
          mutateHistoricTaxes();
        }

        if (data.calculate_mode !== ECalculateMode.WITH_ADS) {
          handleSyncPastTaxModalOpen();
        }

        setIsConfiguringDefaultTax(false);
      } catch (error: any) {
        setIsConfiguringDefaultTax(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [
      defaultTax,
      storeAliasId,
      toast,
      mutateDefaultTax,
      mutateHistoricTaxes,
      handleNewTaxSideModalOpen,
      handleSyncPastTaxModalOpen,
      analytics,
      selectedStore,
      user,
    ],
  );

  const createTaxPeriod = React.useCallback(
    async (data: any) => {
      setIsCreatingPeriodTax(true);

      try {
        const response = await taxService.createPeriodTax({ storeAliasId, data });

        analytics?.track(
          'Taxes Historic Added',
          {
            calculate_mode: data.calculate_mode,
            percentage_amount: data.percentage_amount,
            regime: data.regime,
            start_date: data.start_date,
            end_date: data.end_date,
            email: user?.email,
          },
          { context: { groupId: selectedStore?.alias_id } },
        );

        toast.success('Imposto por período criado com sucesso!');

        setIsCreatingPeriodTax(false);

        return response;
      } catch (error: any) {
        setIsCreatingPeriodTax(false);
        toast.error(error?.response?.data?.message);
      }

      return undefined;
    },
    [storeAliasId, toast, analytics, selectedStore, user],
  );

  const updateTaxPeriod = React.useCallback(
    async (data: any) => {
      setIsUpdatingHistoricTax(true);

      try {
        await taxService.updatePeriodTax({
          storeAliasId,
          taxAliasId: userPeriodTax?.alias_id,
          data,
        });

        toast.success('Imposto por período atualizado com sucesso!');

        mutateHistoricTaxes();

        handleEditingTax();

        setIsUpdatingHistoricTax(false);
      } catch (error: any) {
        setIsUpdatingHistoricTax(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [mutateHistoricTaxes, userPeriodTax, handleEditingTax, storeAliasId, toast],
  );

  const updateDefaultTax = React.useCallback(
    async data => {
      setIsUpdatingHistoricTax(true);

      try {
        await taxService.updateDefaultTax({
          storeAliasId,
          data,
          taxAliasId: userPeriodTax?.alias_id,
        });

        toast.success('Imposto padrão atualizado com sucesso!');

        mutateHistoricTaxes();
        mutateDefaultTax();

        if (data.calculate_mode !== ECalculateMode.WITH_ADS) {
          handleSyncPastTaxModalOpen();
        }

        setIsUpdatingHistoricTax(false);
      } catch (error: any) {
        setIsUpdatingHistoricTax(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [
      mutateHistoricTaxes,
      storeAliasId,
      toast,
      userPeriodTax,
      mutateDefaultTax,
      handleSyncPastTaxModalOpen,
    ],
  );

  const deleteTaxPeriod = React.useCallback(
    async data => {
      setIsDeletingHistoricTax(true);

      const periodAliasId = data.alias_id;
      const periodStartDate = data.start_date;
      const periodEndDate = data.end_date;
      const periodCalculateMode = data.calculate_mode;
      const periodRegime = data.regime;

      const periodTaxDataToCreate = {
        calculate_mode: periodCalculateMode,
        regime: periodRegime,
        percentage_amount: 0,
        name: 'any_tax_name',
        start_date: format(utcToZonedTime(periodStartDate), 'yyyy-MM-dd'),
        end_date: format(utcToZonedTime(periodEndDate), 'yyyy-MM-dd'),
      };

      try {
        await taxService.deleteTax({ storeAliasId, taxAliasId: periodAliasId });

        toast.success('Imposto excluído com sucesso!');

        if (data.is_default) {
          handleSyncPastTaxModalOpen();
        } else {
          await taxService.createPeriodTax({
            storeAliasId,
            data: periodTaxDataToCreate,
          });

          const params = `?start_date=${format(
            utcToZonedTime(periodStartDate),
            'yyyy-MM-dd',
          )}&end_date=${format(utcToZonedTime(periodEndDate), 'yyyy-MM-dd')}`;

          addSynchronization({
            name: 'Impostos',
            type: ESynchronizationType.STORE_ORDER,
            label: 'Pedidos',
            dependencies: [ESynchronizationType.STORE_PRODUCT],
            storeAliasId,
            config: {
              isOnboardingSynchronization: false,
              showNotification: true,
            },
            request: {
              type: ESynchronizationRequestType.POST,
              domain: LONG_API_DOMAIN,
              api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/orders/tax/sync`,
              requestParams: params,
            },
          });
        }

        setIsDeletingHistoricTax(false);

        mutateHistoricTaxes();
        mutateDefaultTax();
      } catch (error: any) {
        setIsDeletingHistoricTax(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [
      storeAliasId,
      toast,
      addSynchronization,
      format,
      utcToZonedTime,
      handleSyncPastTaxModalOpen,
      mutateDefaultTax,
      mutateHistoricTaxes,
    ],
  );

  React.useEffect(() => {
    if (historicTaxesTotalPages) {
      setHistoricTaxPageCount(historicTaxesTotalPages);
    }
  }, [historicTaxesTotalPages]);

  const isLoadingTax = isLoadingDefaultTax || isLoadingHistoricTaxes;
  const isValidatingTax = isValidatingDefaultTax || isValidatingHistoricTaxes;
  const isTaxError = Boolean(defaultTaxError) || Boolean(historicTaxesError);

  return (
    <TaxContext.Provider
      value={{
        configDefaultTax,
        createTaxPeriod,
        defaultTax,
        deleteTaxPeriod,
        handleEditTaxSideModalOpen,
        handleEditingTax,
        handleNewTaxSideModalOpen,
        handleSyncPastTaxModalOpen,
        handleSyncPastTaxSideModalOpen,
        handleUserPeriodTax,
        historicTaxPage,
        historicTaxPageCount,
        historicTaxes,
        isConfiguringDefaultTax,
        isCreatingPeriodTax,
        isDeletingHistoricTax,
        isEditTaxSideModalOpen,
        isEditingTax,
        isLoadingTax,
        isNewTaxSideModalOpen,
        isSyncPastTaxModalOpen,
        isSyncPastTaxSidemodalOpen,
        isTaxError,
        isUpdatingHistoricTax,
        isValidatingTax,
        mutate,
        mutateHistoricTaxes,
        onHistoricTaxesPageChange,
        updateDefaultTax,
        updateTaxPeriod,
        userPeriodTax,
        handleWithAdsModalOpen,
        isWithAdsModalOpen,
        handleDefaultTaxData,
        defaultTaxData,
        handleUpdateWithAdsModalOpen,
        isUpdateWithAdsModalOpen,
      }}
    >
      {children}
    </TaxContext.Provider>
  );
};

export const useTax = (): ITaxProvider => {
  const context = React.useContext(TaxContext);

  if (!context) {
    throw new Error('useTax must be used within TaxProvider');
  }

  return context;
};
