import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { IMarketingIntegrationProvider } from '@domain/interfaces/dashboard/AdsManager/Onboarding/IMarketingIntegration';
import { IParams } from '@domain/interfaces/IParams';

import { ADS_PROVIDERS } from '@constants/common/integrations';

const MarketingIntegrationContext = React.createContext<IMarketingIntegrationProvider | null>(null);

export const MarketingIntegrationProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();

  const [isSideModalOpen, setIsSideModalOpen] = React.useState<boolean>(false);
  const [selectedIntegrations, setSelectedIntegrations] = React.useState<typeof ADS_PROVIDERS>([]);
  const [currentIntegration, setCurrentIntegration] = React.useState<
    typeof ADS_PROVIDERS[number] | undefined
  >();
  const [hasClickedNextStep, setHasClickedNextStep] = React.useState<boolean>(false);

  const handleSideModalOpen = React.useCallback(() => {
    setIsSideModalOpen(!isSideModalOpen);
  }, [isSideModalOpen]);

  const handleSelectedIntegrations = React.useCallback(integrations => {
    setSelectedIntegrations(integrations);
  }, []);

  const handleNextStep = React.useCallback(() => {
    if (!hasClickedNextStep) {
      setHasClickedNextStep(true);
    }

    if (selectedIntegrations.length) {
      const newCurrentIntegration = selectedIntegrations.shift();
      setCurrentIntegration(newCurrentIntegration);
    } else {
      history.push(`/${storeAliasId}/dashboard/ads-manager/onboarding/script-utms`);
    }
  }, [hasClickedNextStep, selectedIntegrations, history, storeAliasId]);

  return (
    <MarketingIntegrationContext.Provider
      value={{
        isSideModalOpen,
        handleSideModalOpen,
        selectedIntegrations,
        handleSelectedIntegrations,
        currentIntegration,
        handleNextStep,
      }}
    >
      {children}
    </MarketingIntegrationContext.Provider>
  );
};

export const useMarketingIntegration = (): IMarketingIntegrationProvider => {
  const context = React.useContext(MarketingIntegrationContext);

  if (!context) {
    throw new Error('useMarketingIntegration must be used within MarketingIntegrationProvider');
  }

  return context;
};
