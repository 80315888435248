import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';
import {
  ICreateKitProps,
  IDeleteKitProps,
  IGetKitProps,
  IGetKitResponse,
  IListKitsProps,
  IListKitsResponse,
  IUpdateKitProps,
} from '@domain/interfaces/dashboard/Kit/variantKit';

export class KitService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listKits({ storeAliasId, page }: IListKitsProps): IListKitsResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/variant-kits?limit=10&page=${page + 1}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      variantKits: data?.variant_kits,
      totalPages: data?.total_pages,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public getKit({ storeAliasId, variantKitAliasId }: IGetKitProps): IGetKitResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/variant-kits/${variantKitAliasId}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      variantKit: data?.variant_kit,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public createKit({ storeAliasId, data }: ICreateKitProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/variant-kits`, { ...data });
  }

  public updateKit({ storeAliasId, variantKitAliasId, data }: IUpdateKitProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/variant-kits/${variantKitAliasId}`,
      { ...data },
    );
  }

  public deleteKit({ storeAliasId, variantKitAliasId }: IDeleteKitProps): Promise<any> {
    return this.apiService.delete(
      `/api/v1/users/stores/${storeAliasId}/variant-kits/${variantKitAliasId}`,
    );
  }
}

const kitService = new KitService(API_DOMAIN);

export default kitService;
