import React from 'react';
import { useTheme } from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import { randomInteger } from '@helpers/utils/common/random';
import { numberFormatter } from '@helpers/masks';

import OnboardingNotFinished from '@components/Dashboard/AdsManager/UtmsTooltipsContent/OnboardingNotFinished';
import Chart from '../Chart';

import * as S from './styles';

const BlurredContent: React.FC = () => {
  const randomNumber = randomInteger(0, 100);

  const { storeAliasId } = useParams<IParams>();
  const history = useHistory();
  const theme = useTheme();

  const redirectToUtmOnboarding = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/marketing/ads-manager/onboarding/script-utms`);
  }, [history, storeAliasId]);

  const chartData = [
    {
      name: 'Conversão',
      value: randomNumber,
    },
    {
      name: 'Total',
      value: 100 - randomNumber,
    },
  ];

  const colors = [theme.colors.success.light, theme.colors.gray[4]];

  return (
    <S.CustomHoverCard>
      <S.CustomHoverCardTrigger>
        <S.ChartWrapper>
          <Chart data={chartData} colors={colors} />
          <S.ConversionPercentage>{`${numberFormatter(
            randomNumber,
            0,
            true,
          )}%`}</S.ConversionPercentage>
        </S.ChartWrapper>
        <S.TextContentWrapper>
          <S.ContentWrapper>
            <S.LabelText>Pagos</S.LabelText>
            <S.AmountText>{`R$ ${numberFormatter(0, 2)} (${0})`}</S.AmountText>
          </S.ContentWrapper>

          <S.ContentWrapper>
            <S.LabelText>Pendentes</S.LabelText>
            <S.AmountText>{`R$ ${numberFormatter(0, 2)} (${0})`}</S.AmountText>
          </S.ContentWrapper>
        </S.TextContentWrapper>
      </S.CustomHoverCardTrigger>

      <S.CustomHoverCardContent side="top" sideOffset={5}>
        <OnboardingNotFinished startUtmOnboarding={redirectToUtmOnboarding} />
        <S.CustomHoverCard.Arrow />
      </S.CustomHoverCardContent>
    </S.CustomHoverCard>
  );
};

export default BlurredContent;
