import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { USER_API_DOMAIN } from '@constants/api';
import {
  IGetProfileResponse,
  IUpdateProfileProps,
} from '@domain/interfaces/dashboard/Profile/IProfile';

export class ProfileService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getProfile(): IGetProfileResponse {
    const { data, error, mutate, isValidating } = useFetch(`/api/v1/users/me`, this.apiService);

    const isLoading = !error && !data;

    return {
      profile: data?.user,
      mutate,
      error,
      isLoading,
      isValidating,
    };
  }

  public updateProfile({ profileData }: IUpdateProfileProps): Promise<any> {
    return this.apiService.put(`/api/v1/users/me`, { ...profileData });
  }
}

const profileService = new ProfileService(USER_API_DOMAIN);

export default profileService;
