import React from 'react';
import { Percent } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { ECalculateMode, ERegime } from '@domain/enums/dashboard/tax/ETax';
import {
  ESynchronizationRequestType,
  ESynchronizationType,
} from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';

import { useTax } from '@helpers/hooks/pages/dashboard/tax/useTax';
import { useDate } from '@helpers/hooks/useDate';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useToast } from '@helpers/hooks/useToast';
import { useHome } from '@helpers/hooks/pages/dashboard/home/useHome';

import { currencyFormatter, currencyToNumber, numberFormatter } from '@helpers/masks';

import { getEnvironment } from '@helpers/utils/environment';

import { taxSchema } from '@helpers/validators/dashboard/tax/tax';

import { LONG_API_DOMAIN } from '@constants/api';

import Text from '@components/common/core/DataDisplay/Text';
import Form from '@components/common/core/Inputs/Form';
import SideModalSave from '@components/common/core/Utils/SideModalSave';
import WithAdsModal from '../WithAdsModal';

import * as S from './styles';

const NewTaxSidemodal: React.FC = () => {
  const theme: any = useTheme();
  const { storeAliasId } = useParams<IParams>();
  const { addSynchronization } = useSynchronization();
  const { format, utcToZonedTime } = useDate();
  const { toast } = useToast();
  const {
    isNewTaxSideModalOpen,
    handleNewTaxSideModalOpen,
    defaultTax,
    configDefaultTax,
    createTaxPeriod,
    isCreatingPeriodTax,
    isConfiguringDefaultTax,
    mutateHistoricTaxes,
    handleWithAdsModalOpen,
    handleDefaultTaxData,
  } = useTax();
  const { mutateHomeSetup } = useHome();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(taxSchema),
  });

  watch(['calculate_mode']);

  const aliquotInput = register('aliquot');

  const [taxType, setTaxType] = React.useState<string>('current');
  const [period, setPeriod] = React.useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const onChange = React.useCallback(
    event => {
      setTaxType(event.target.value);

      const calculateModeValue = getValues('calculate_mode');

      if (calculateModeValue === ECalculateMode.WITH_ADS && event.target.value === 'period') {
        setValue('calculate_mode', ECalculateMode.WITH_COGS);
        toast.error('A regra com Custo de Marketing só é selecionável para o imposto atual.');
      }
    },
    [toast, getValues, setValue],
  );

  const onSubmit = React.useCallback(
    async data => {
      console.log('ta aqui? ');

      if (taxType === 'current' && data.calculate_mode === ECalculateMode.WITH_ADS) {
        handleWithAdsModalOpen();
        handleDefaultTaxData({
          calculate_mode: data.calculate_mode,
          percentage_amount: currencyToNumber(data.aliquot),
          regime: data.regime,
          name: 'any_tax_name',
        });

        mutateHomeSetup();

        return;
      }

      if (taxType === 'current') {
        configDefaultTax({
          calculate_mode: data.calculate_mode,
          percentage_amount: currencyToNumber(data.aliquot),
          regime: data.regime,
          name: 'any_tax_name',
        });

        mutateHomeSetup();

        return;
      }

      const response = await createTaxPeriod({
        calculate_mode: data.calculate_mode,
        percentage_amount: currencyToNumber(data.aliquot),
        regime: data.regime,
        start_date: format(period.startDate, 'yyyy-MM-dd'),
        end_date: format(period.endDate, 'yyyy-MM-dd'),
        name: 'any_tax_name',
      });

      if (response) {
        const params = `?start_date=${format(
          utcToZonedTime(period.startDate),
          'yyyy-MM-dd',
        )}&end_date=${format(utcToZonedTime(period.endDate), 'yyyy-MM-dd')}`;

        addSynchronization({
          name: 'Impostos',
          type: ESynchronizationType.STORE_GATEWAY,
          label: 'Pedidos',
          dependencies: [ESynchronizationType.STORE_PRODUCT],
          storeAliasId,
          config: {
            isOnboardingSynchronization: false,
            showNotification: true,
          },
          request: {
            type: ESynchronizationRequestType.POST,
            domain: LONG_API_DOMAIN,
            api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/orders/tax/sync`,
            requestParams: params,
          },
        });

        handleNewTaxSideModalOpen();

        mutateHistoricTaxes();

        mutateHomeSetup();
      }
    },
    [
      configDefaultTax,
      format,
      period,
      taxType,
      storeAliasId,
      addSynchronization,
      utcToZonedTime,
      createTaxPeriod,
      handleNewTaxSideModalOpen,
      mutateHistoricTaxes,
      handleWithAdsModalOpen,
      handleDefaultTaxData,
      mutateHomeSetup,
    ],
  );

  React.useEffect(() => {
    if (defaultTax?.calculate_mode === ECalculateMode.WITH_ADS) {
      setValue('calculate_mode', ECalculateMode.WITH_ADS);
    }
  }, [defaultTax, setValue]);

  const isDefaultCalculateModeValue = defaultTax?.calculate_mode === ECalculateMode.WITH_ADS;

  return (
    <>
      <WithAdsModal />

      <S.Sidemodal isOpen={isNewTaxSideModalOpen} toggle={handleNewTaxSideModalOpen}>
        <S.Header>
          <Percent size={36} color={theme.colors.green.default} outline />
          <S.Heading>Configurar Imposto</S.Heading>
        </S.Header>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.RadioGroup>
            <S.Label>Escolha como deseja cadastrar seu imposto:</S.Label>

            <S.RadioLabel>
              <S.Radio
                value="current"
                defaultChecked={taxType === 'current'}
                name="type"
                onChange={onChange}
                id="current-option"
              />
              <S.RadioText>Imposto atual</S.RadioText>
            </S.RadioLabel>

            {!isDefaultCalculateModeValue && (
              <S.RadioLabel>
                <S.Radio
                  defaultChecked={taxType === 'period'}
                  value="period"
                  name="type"
                  onChange={onChange}
                  id="period-option"
                />
                <S.RadioText>Imposto por período</S.RadioText>
              </S.RadioLabel>
            )}
          </S.RadioGroup>

          <S.InputGroup>
            <S.Label>Regra de tributação</S.Label>
            <S.Select {...register('calculate_mode')} defaultValue={defaultTax?.calculate_mode}>
              {taxType === 'current' && (
                <S.Option value={ECalculateMode.WITH_ADS}>
                  Receita Líquida - Custo do Produto - Custo de Marketing
                </S.Option>
              )}
              <S.Option value={ECalculateMode.WITH_COGS}>
                Receita Líquida - Custo do Produto
              </S.Option>
              <S.Option value={ECalculateMode.WITHOUT_COGS}>Receita Líquida</S.Option>
            </S.Select>
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Regime de tributação</S.Label>
            <S.Select {...register('regime')} defaultValue={defaultTax?.regime}>
              <S.Option value={ERegime.SIMPLES_NACIONAL}>Simples Nacional</S.Option>
              <S.Option value={ERegime.LUCRO_PRESUMIDO}>Lucro Presumido</S.Option>
              <S.Option value={ERegime.LUCRO_REAL}>Lucro Real</S.Option>
            </S.Select>
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Alíquota</S.Label>

            <S.Input
              {...aliquotInput}
              type="text"
              prependText="%"
              placeholder="Inserir valor"
              defaultValue={
                defaultTax ? numberFormatter(defaultTax.percentage_amount, 2) : undefined
              }
              onChange={event => {
                aliquotInput.onChange(event);

                const { value } = event.target;

                event.target.value = currencyFormatter(value);
              }}
            />
            {errors.aliquot && <Text isErrorFeedback>{errors.aliquot.message}</Text>}
          </S.InputGroup>

          {taxType === 'period' && <S.CustomDatePicker period={period} setPeriod={setPeriod} />}

          <SideModalSave
            successButtonText="Salvar"
            cancelButtonText="Cancelar"
            type="submit"
            onCancel={handleNewTaxSideModalOpen}
            isLoading={isCreatingPeriodTax || isConfiguringDefaultTax}
            isDisabled={isCreatingPeriodTax || isConfiguringDefaultTax}
          />
        </Form>
      </S.Sidemodal>
    </>
  );
};

export default NewTaxSidemodal;
