import React from 'react';

import * as S from './styles';

const PrivacyPolicyContent: React.FC = () => {
  return (
    <S.Content>
      <S.Title>Política de Privacidade</S.Title>

      <S.Paragraph>
        CONTROLADORA DOS DADOS: <strong>PROFITFY TECNOLOGIA LTDA.</strong> (“Profitfy”), inscrita no
        CNPJ/ME sob o n.° 37.365.788/0001-40, com sede na Avenida General Ataliba Lionel, n° 1205,
        9° andar, sala 92, Santana, São Paulo/SP, CEP 02033-000.
      </S.Paragraph>

      <S.Paragraph>Olá, bem-vindo(a)!</S.Paragraph>
      <S.Paragraph>
        Nós da <strong>Profitfy</strong> valorizamos a privacidade e queremos contribuir para a
        proteção de seus dados pessoais. Por essa razão, apresentamos nossa Política de Privacidade,
        para que você saiba como fazemos uso de seus dados pessoais.
      </S.Paragraph>

      <S.Subtitle>1. O que faz a Profitfy?</S.Subtitle>
      <S.Paragraph>
        Atuamos principalmente na seguinte frente de negócio:{' '}
        <strong>Ferramenta de Administração Financeira e Vendas para Lojas Online.</strong>
      </S.Paragraph>
      <S.Paragraph>
        Nossa atividade principal consiste no oferecimento de ferramentas para ajudar sua loja
        on-line na administração de suas operações diárias, contemplando as áreas de finanças,
        marketing, estoque e vendas, reunindo dados e métricas de modo a explorar o potencial máximo
        de seu negócio, tornando-o mais lucrativo.
      </S.Paragraph>

      <S.Subtitle>2. Como Funciona o Tratamento de Dados Por Parte da Profitfy?</S.Subtitle>
      <S.Paragraph>
        Como toda empresa que possui endereço eletrônico, para realizar nossas atividades,
        precisamos coletar e tratar algumas informações que são consideradas pela legislação como
        dados pessoais. Para mantê-lo informado sobre o modo como fazemos o tratamento desses seus
        dados, preparamos o seguinte quadro resumo, com as principais perguntas que você possa vir a
        ter.
      </S.Paragraph>
      <S.Paragraph>
        Caso queira obter maiores informações sobre alguns dos tópicos, clique em Leia Mais na seção
        correspondente abaixo.
      </S.Paragraph>

      <S.Subtitle>3. Quais Dados Pessoais Coletamos?</S.Subtitle>
      <S.Paragraph>Em síntese, podemos coletar os seguintes dados pessoais:</S.Paragraph>
      <S.Paragraph underline>Dados coletados em nosso site:</S.Paragraph>
      <S.ItemList>
        <S.Item>
          Mediante seu cadastro espontâneo em formulário: nome completo, e-mail, documento de
          identidade, CPF, data de nascimento, telefone, WhatsApp e endereço.
        </S.Item>
        <S.Item>
          Também mediante seu cadastro espontâneo em formulário: CNPJ, razão social, endereço,
          telefone, e-mail, domínio, produtos, custos operacionais, margem de lucro e dados dos
          colaboradores (nome completo, e-mail, documento de identidade, CPF, data de nascimento,
          telefone, WhatsApp e endereço).
        </S.Item>
        <S.Item>Mediante sua navegação: cookies.</S.Item>
        <S.Item>
          via API e/ou mediante cadastro espontâneo: custos de marketing, plataformas e aplicativos
          utilizados, ROI da operação, contas, campanhas, conjuntos e criativos de anúncio e suas
          respectivas métricas, pedidos, clientes, temas, dados fiscais, fretes e serviços de
          logística.
        </S.Item>
      </S.ItemList>
      <S.Paragraph underline>
        Dados coletados em aplicações para dispositivos móveis e computadores pessoais de nossos
        clientes:{' '}
      </S.Paragraph>
      <S.ItemList>
        <S.Item>Identificadores de cookies que foram designados ao seu navegador;</S.Item>
        <S.Item>Endereço IP do seu dispositivo;</S.Item>
        <S.Item>Páginas acessadas;</S.Item>
        <S.Item>Data e hora dos acessos;</S.Item>
        <S.Item>Dispositivo de acesso utilizado.</S.Item>
      </S.ItemList>
      <S.Paragraph underline>Dados coletados de parceiros:</S.Paragraph>
      <S.ItemList>
        <S.Item>Informação de correspondência de identificadores;</S.Item>
        <S.Item>
          Dados relacionados a possíveis fraudes (ex.: lista de IPs utilizados para práticas
          fraudulentas);
        </S.Item>
        <S.Item>Dados geolocacionais.</S.Item>
      </S.ItemList>
      <S.Paragraph underline>Dados coletados em mídias sociais e em eventos: </S.Paragraph>
      <S.ItemList>
        <S.Item>
          Dados inseridos em seus perfis de mídias sociais (ex.: LinkedIn) ou em inscrições de
          eventos cujos temas tenham relação com nossas atividades empresariais.
        </S.Item>
      </S.ItemList>
      <S.Paragraph underline>Dados publicamente acessíveis:</S.Paragraph>
      <S.ItemList>
        <S.Item>
          Dados disponibilizados publicamente por entidades públicas ou análogas (ex.: Receita
          Federal, Juntas Comerciais etc.).
        </S.Item>
      </S.ItemList>
      <S.Paragraph>
        Para além dessas situações, poderemos utilizar seus dados pessoais de forma anonimizada (ou
        seja, de maneira que não leve à identificação de ninguém), com a finalidade de produzir
        relatórios analíticos de inteligência de negócio, a fim de melhorar nossos serviços.
      </S.Paragraph>

      <S.Subtitle>4. Para Que Utilizamos os Dados Pessoais?</S.Subtitle>
      <S.Paragraph>
        Em síntese, utilizamos os dados pessoais coletados para finalidades ligadas à prestação de
        nossos serviços; ou seja, os dados fornecidos pelos Usuários serão armazenados e utilizados
        pela Profitfy.me ou por terceiros por ela contratados, para fornecer seus serviços de uso da
        plataforma e para o envio de mensagens sobre produtos, serviços, promoções e/ou novidades da
        plataforma, bem como sobre registros de sistema, atualizações, registro de chamados, entre
        outros, caso o cliente assim autorize e permita. Utilizamos ainda com as seguintes
        finalidades:
      </S.Paragraph>
      <S.ItemList>
        <S.Item>
          <strong>Parcerias e melhoria da plataforma:</strong> Os dados poderão ser compartilhados
          com terceiros contratados pela Profitfy para uso nas ferramentas de manutenção da
          plataforma, prestação de serviços de parceiros e anúncios personalizados;{' '}
        </S.Item>
        <S.Item>
          <strong>Comunicação com o usuário:</strong> A Profitfy utilizará o e-mail ou telefone do
          usuário para enviar informações sobre sua assinatura de planos (confirmação de Compra e
          atualizações da situação da assinatura), atualizações, comunicação de novas features,
          registros de sistema e sugestões de melhoria de uso de nossa plataforma;{' '}
        </S.Item>
        <S.Item>
          <strong>Publicidade via mala direta, e-mail ou SMS:</strong> No momento do cadastro de
          seus dados de contato, o Usuário terá a opção de aceitar ou proibir o envio de mensagens
          publicitárias. Ademais, a qualquer momento, o Usuário poderá alterar sua decisão,
          acessando sua conta no site ou entrando em contato com o SAC ou, ainda, através do campo
          de descadastro existente nas newsletters enviadas pela Profitfy, sendo que, neste caso, o
          descadastramento da base de dados poderá levar até 90 dias úteis para ser efetivado.
        </S.Item>
      </S.ItemList>
      <S.Paragraph>
        Além das formas expostas acima, a Profitfy poderá, a seu exclusivo critério, utilizar os
        dados pessoais do Usuário nas seguintes formas: (i) atualização de cadastro; (ii) garantia
        da segurança do Usuário; (iii) resposta a solicitações do próprio Usuário; (iv) informação
        acerca de alterações nos Termos e Condições de Uso ou das Políticas de Privacidade; (v)
        elaboração de estatísticas com relação ao uso do Site, garantido o anonimato do Usuário,
        inclusive para fins de aperfeiçoamento e entendimento do perfil dos Usuários para a melhoria
        da plataforma; (vi) aperfeiçoamento de ferramentas de interatividade entre a plataforma e o
        Usuário, garantido seu anonimato; (vii) cumprimento de ordens judiciais; e (viii) defesa dos
        direitos da Profitfy.me contra o Usuário malicioso em procedimentos judiciais ou
        administrativos.
      </S.Paragraph>

      <S.Subtitle>5. Com quem compartilhamos os dados pessoais?</S.Subtitle>
      <S.Paragraph>
        Os dados pessoais podem ser compartilhados com alguns destinatários, quais sejam:
      </S.Paragraph>
      <S.Paragraph>
        <u>Plataformas de dados:</u> utilizamos empresas para armazenamento de nossos dados, para
        melhorar a eficiência e agilidade de nossa plataforma. Nesses bancos de dados, são
        respeitadas todas as normas de segurança dos dados pessoais de nossos clientes, sendo
        observadas as disposições da Lei Geral de Proteção de Dados.
      </S.Paragraph>
      <S.Paragraph>
        <u>Plataformas de parceiros:</u> nossa empresa poderá compartilhar seus dados pessoais com
        empresas parceiras (exemplo: CustomerX, RDStation, Calixa, Intercom, Mixpanel, MLabs, Meta,
        Google, Refiner, Active Campaign) dentre outras), que possibilitam a melhoria em nossos
        produtos e serviços, bem como auxiliam no melhor resultado de metrificação para nossos
        clientes.
      </S.Paragraph>
      <S.Paragraph>
        <u>Parceiros de conferência de identificadores:</u> para melhorar a eficiência da associação
        dos nossos identificadores com outros, podemos ter de compartilhar com nossos parceiros os
        identificadores que designamos aos navegadores por meio de cookies.
      </S.Paragraph>
      <S.Paragraph>
        <u>Plataforma de meio de pagamento:</u> podem ser compartilhadas informações de cobrança,
        caso o cliente escolha pela utilização de nosso parceiro de meio de pagamento.
      </S.Paragraph>
      <S.Paragraph>
        <u>Autoridades públicas ou órgãos oficiais:</u> para cumprimento de obrigações legais a que
        estamos submetidos, podemos ter de vir a compartilhar dados com autoridades públicas ou
        órgãos oficiais, mediante solicitação ou previsão legal expressa, inclusive as informações
        de transações com criptomoedas mensais realizadas por nossos clientes.
      </S.Paragraph>
      <S.Paragraph>
        Destacamos que este compartilhamento é necessário para o processamento de pagamentos
        recorrentes, CRM, acompanhamento do usuário para customer success, pesquisas segmentadas,
        parcerias comerciais e na melhoria dos serviços da plataforma.
      </S.Paragraph>

      <S.Subtitle>6. Como protegemos os dados pessoais?</S.Subtitle>
      <S.Paragraph>
        Na <strong>Profitfy</strong>, implementamos todas as medidas de segurança que estão ao nosso
        alcance para proteger os seus dados pessoais de acessos não autorizados e de situações
        acidentais ou ilícitas de destruição, perda, alteração, comunicação ou difusão.{' '}
      </S.Paragraph>
      <S.Paragraph>
        Temos uma política interna de proteção de dados, que se aplica a todos aqueles que tratam
        dados pessoais em nosso nome, e contamos com um robusto plano de resposta a incidentes em
        caso de ocorrência de alguma situação indesejada relacionada aos dados que tratamos.
      </S.Paragraph>
      <S.Paragraph>
        Além disso, temos processos periódicos de avaliação de nosso programa de governança em
        privacidade e de descoberta e tratamento de riscos em nossas atividades.{' '}
      </S.Paragraph>
      <S.Paragraph>
        Por fim, nos preocupamos em treinar nossos colaboradores sobre a importância do tema
        proteção de dados, a fim de mantê-los atualizados quanto às melhores práticas de mercado.
      </S.Paragraph>

      <S.Subtitle>7. Por quanto tempo guardamos os dados pessoais?</S.Subtitle>
      <S.Paragraph>
        A retenção de dados pessoais pela <strong>Profitfy</strong> respeita as seguintes
        diretrizes:
      </S.Paragraph>
      <S.Paragraph>
        Apenas são mantidos dados pessoais estritamente necessários para o cumprimento das
        finalidades do tratamento, ou seja, aqueles informados espontaneamente pelo usuário e
        informações de compliance, pelo prazo de 05 (cinco) anos. Assim, nos livramos de dados
        pessoais desnecessários ou excessivos.
      </S.Paragraph>
      <S.Paragraph>
        Sem prejuízo, preservamos as informações necessárias para o cumprimento de obrigações legais
        e regulatórias ou o exercício de nossos direitos em demandas administrativas, judiciais ou
        arbitrais.
      </S.Paragraph>

      <S.Subtitle>8. Quais são os direitos relacionados aos dados pessoais?</S.Subtitle>
      <S.Paragraph>
        Nos orgulhamos de ser uma empresa que leva a sério os direitos dos titulares de dados
        pessoais. Por isso, garantimos que os direitos sobre seus dados pessoais previstos na lei
        (art. 18, Lei Federal n. 13.709/2018) possam ser plenamente exercidos, respeitados os
        limites aplicáveis.
      </S.Paragraph>
      <S.Paragraph>Destacamos os seguintes direitos que você pode exercer:</S.Paragraph>
      <S.Paragraph>
        <u>Acesso aos dados:</u> qualquer um pode requerer o acesso aos seus dados pessoais
        armazenados por nós, bem como informações sobre possível o compartilhamento com terceiros.
      </S.Paragraph>
      <S.Paragraph>
        <u>Correção:</u> caso os dados pessoais estejam incorretos, poderá ser feita uma solicitação
        de correção ou atualização.
      </S.Paragraph>
      <S.Paragraph>
        <u>Exclusão:</u> dados fornecidos mediante consentimento podem ser alvo de exclusão.
        Contudo, é possível que, mesmo após o requerimento de exclusão, alguns dados pessoais
        permaneçam armazenados, devido a obrigações legais ou para a proteção de interesses nossos
        ou de nossos clientes.{' '}
      </S.Paragraph>
      <S.Paragraph>
        <u>Oposição:</u> você pode se opor ao tratamento de seus dados pessoais por parte da{' '}
        <strong>Profitfy.</strong>
      </S.Paragraph>
      <S.Paragraph>
        <strong>
          <u>Atenção:</u>
        </strong>{' '}
        a oposição do Usuário sobre o tratamento de seus dados pessoais, autorizados pela
        concordância com esta Política de Privacidade, acarretará sua impossibilidade de utilizar os
        serviços que oferecemos.
      </S.Paragraph>
      <S.Paragraph>
        <u>Portabilidade:</u> para que você possa exercer a portabilidade de seus dados pessoais,
        podemos providenciar a você uma cópia de seus dados pessoais em formato de leitura comum.
      </S.Paragraph>

      <S.Subtitle>9. Canais de Contato</S.Subtitle>
      <S.Paragraph>
        Em caso de dúvidas e solicitações, entre em contato com o nosso Encarregado pelo Tratamento
        de Dados Pessoais, pelo e-mail abaixo:
      </S.Paragraph>
      <S.Paragraph>kevinsilva@profitfy.me</S.Paragraph>
      <S.Paragraph>Será um prazer ajudar!</S.Paragraph>

      <br />
      <S.Subtitle>
        Estas Políticas de Privacidade foram atualizados em 18 de outubro de 2022.
      </S.Subtitle>
    </S.Content>
  );
};

export default PrivacyPolicyContent;
