import styled, { css } from 'styled-components/macro';

import {
  IProgressBarProps,
  IStepNameProps,
  IProgressCircleProps,
} from '@domain/interfaces/onboarding/components/progressBar/IProgressBar';
import { EProgressBarStep } from '@domain/enums/onboarding/progressBar/ESteps';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;

export const ProgressAndNameStepsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  gap: 16px;
  flex-direction: column;
`;

export const StepsNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 67%;
  margin-top: 10px;
  margin-left: -16px;

  @media only screen and (max-width: 690px) {
    & {
      width: 97%;
    }
  }
`;

export const StepName = styled(TextBase)<IStepNameProps>`
  ${({ isComplete, theme }) =>
    isComplete &&
    css`
      color: ${theme.colors.green.default};
    `}
`;

export const ProgressWrapper = styled.div`
  position: relative;
  height: 3px;
  width: 60%;
  margin-top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 690px) {
    & {
      width: 80%;
    }
  }
`;

export const ProgressBackground = styled.div`
  width: 100%;
  height: 2%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.gray[2]};
`;

export const ProgressBar = styled.div<IProgressBarProps>`
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.green.default};

  ${({ step }) =>
    step === EProgressBarStep.INTEGRATIONS &&
    css`
      width: 35%;
    `}

  ${({ step }) =>
    step === EProgressBarStep.GENERAL_CONFIG &&
    css`
      width: 70%;
    `}

  ${({ step }) =>
    step === EProgressBarStep.FINISH_STEP &&
    css`
      width: 100%;
    `}
`;

export const ProgressCircleAndIconWrapper = styled.div``;

export const CreateStoreProgressCircle = styled.div<IProgressCircleProps>`
  position: absolute;
  top: -10px;
  left: 0px;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, isComplete }) =>
    isComplete ? theme.colors.green.default : theme.colors.gray[6]};
  border: 2px solid
    ${({ theme, isComplete }) => (isComplete ? theme.colors.green.default : theme.colors.gray[2])};
  height: 24px;
  width: 24px;
  border-radius: 50%;

  & > span {
    white-space: nowrap;
    margin-top: 68px;
  }
`;

export const IntegrationsProgressCircle = styled.div<IProgressCircleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, isComplete }) =>
    isComplete ? theme.colors.green.default : theme.colors.gray[6]};
  height: 24px;
  width: 24px;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: 34%;
  transform: translateX(-50%);
  border: 2px solid ${({ theme }) => theme.colors.gray[2]};

  ${({ step }) =>
    step !== EProgressBarStep.CREATE_STORE &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.green.default};
    `}

  & > span {
    white-space: nowrap;
    margin-top: 68px;
  }
`;

export const GeneralConfigProgressCircle = styled.div<IProgressCircleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, isComplete }) =>
    isComplete ? theme.colors.green.default : theme.colors.gray[6]};
  height: 24px;
  width: 24px;
  border-radius: 50%;
  position: absolute;
  transform: translateX(-50%);
  top: -10px;
  left: 70%;
  border: 2px solid ${({ theme }) => theme.colors.gray[2]};

  ${({ step }) =>
    (step === EProgressBarStep.GENERAL_CONFIG || step === EProgressBarStep.FINISH_STEP) &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.green.default};
    `}

  & > span {
    white-space: nowrap;
    margin-top: 68px;
  }
`;

export const FinishStepProgressCircle = styled.div<IProgressCircleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, isComplete }) =>
    isComplete ? theme.colors.green.default : theme.colors.gray[6]};
  border: 2px solid ${({ theme }) => theme.colors.green.default};
  height: 24px;
  width: 24px;
  border-radius: 50%;
  position: absolute;
  transform: translateX(-50%);
  top: -10px;
  left: 100%;
  border: 2px solid ${({ theme }) => theme.colors.gray[2]};

  ${({ step }) =>
    step === EProgressBarStep.FINISH_STEP &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.green.default};
    `}

  & > span {
    white-space: nowrap;
    margin-top: 68px;
  }
`;

export const Icon = styled.svg`
  fill: none;
  stroke: #0f1116;
  stroke-width: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
`;
