import React from 'react';

import { useAdsManager } from '@helpers/hooks/pages/dashboard/adsManager/useAdsManager';

import * as S from './styles';

const ColumnSelectContent: React.FC = () => {
  const { tableColumns, handleTableColumns } = useAdsManager();

  const onCheckboxChange = React.useCallback(
    event => {
      if (event && !event.target) {
        const newTableColumns = tableColumns.map(column => {
          if (column.id === event.value) {
            return {
              ...column,
              isActive: !column.isActive,
            };
          }

          return column;
        });

        handleTableColumns(newTableColumns);
      }
    },
    [tableColumns, handleTableColumns],
  );

  const filteredColumns = tableColumns.filter(column => column.isOptional);

  return (
    <S.Content side="bottom" sideOffset={5}>
      <S.CheckboxGroupWrapper>
        {filteredColumns.map(column => (
          <S.CheckboxGroup key={column.id}>
            <S.Checkbox
              id={column.id}
              defaultChecked={column.isActive}
              value={column.id}
              onChange={onCheckboxChange}
            />
            <S.Label htmlFor={column.id}>{column.content}</S.Label>
          </S.CheckboxGroup>
        ))}
      </S.CheckboxGroupWrapper>

      <S.Arrow />
    </S.Content>
  );
};

export default ColumnSelectContent;
