import React from 'react';

import { Warning } from '@profitfy/pakkun-icons';
import { ETextWeight } from '@domain/enums/components/EText';
import { useAccessManager } from '@helpers/hooks/pages/dashboard/accessManagement/useAccessManager';

import * as S from './styles';

const DeleteGroupModal: React.FC = () => {
  const {
    isDeleteGroupModalOpen,
    handleDeleteGroupModalOpen,
    handleDeleteGroups,
    isDeletingGroups,
  } = useAccessManager();

  return (
    <S.Modal isOpen={isDeleteGroupModalOpen} toggle={handleDeleteGroupModalOpen}>
      <S.Header>
        <Warning size={24} outline />
        <S.Heading weight={ETextWeight.MEDIUM}>Certeza que deseja excluir o grupo?</S.Heading>
      </S.Header>

      <S.Description>
        Os usuários desse grupo perderão o acesso a sua conta, e terão que ser convidados novamente.
      </S.Description>

      <S.ButtonsWrapper>
        <S.CancelButton onClick={handleDeleteGroupModalOpen} disabled={isDeletingGroups}>
          Voltar
        </S.CancelButton>
        <S.DeleteGroupButton
          onClick={handleDeleteGroups}
          isLoading={isDeletingGroups}
          disabled={isDeletingGroups}
        >
          Excluir grupo
        </S.DeleteGroupButton>
      </S.ButtonsWrapper>
    </S.Modal>
  );
};

export default DeleteGroupModal;
