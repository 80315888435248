import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Modal = styled(ModalBase)`
  width: 100%;
  max-width: 433px;
  box-sizing: border-box;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 22px;
`;

export const Title = styled(TextBase)`
  font-size: 1.125rem;
  color: #fff;
`;

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 32px;
`;

export const StepTitle = styled(TextBase)`
  color: #fff;
  line-height: 150%;
  margin-bottom: 8px;
`;

export const Step = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
`;

export const StepBullet = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${({ theme }) => theme.colors.green.default};
  border-radius: 50%;
`;

export const StepDescription = styled(TextBase)`
  line-height: 150%;
`;

export const AddNewProfileButton = styled(ButtonBase)`
  width: 100%;
`;
