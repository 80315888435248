import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Modal = styled(ModalBase)`
  width: 100%;
  max-width: 428px;
  box-sizing: border-box;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
`;

export const Heading = styled(TextBase)``;

export const Description = styled(TextBase)``;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-top: 24px;
  gap: 18px;
`;

export const CancelButton = styled.button`
  background: unset;
  border: unset;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[1]};
  cursor: pointer;
`;

export const ConfirmButton = styled(ButtonBase)``;
