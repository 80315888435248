import styled from 'styled-components/macro';
import TextFieldBase from '@components/common/core/Inputs/TextField';

export const Input = styled(TextFieldBase)`
  max-width: 324px;

  @media only screen and (max-width: 1124px) {
    margin-top: 24px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 42px;

  @media only screen and (max-width: 1124px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
