import { AES, enc } from 'crypto-js';

export const encrypt = (value: string, encryptionKey: string): string => {
  const encrypted = AES.encrypt(value, encryptionKey).toString();

  return encrypted;
};

export const decrypt = (value: string, encryptionKey: string): any => {
  const bytes = AES.decrypt(value, encryptionKey);
  const decryptedData = bytes.toString(enc.Utf8);

  return decryptedData;
};
