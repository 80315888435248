import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { EButtonSize } from '@domain/enums/components/EButton';
import { ETextSize } from '@domain/enums/components/EText';

import { useGateway } from '@helpers/hooks/pages/dashboard/feed/useGateway';
import { useConfig } from '@helpers/hooks/useConfig';

import { GATEWAYS } from '@constants/common/integrations';

import Order from './Order';
import InfiniteScrollLoading from './InfiniteScrollLoading';

import * as S from './styles';

const GatewayOrders: React.FC = () => {
  const {
    gateway: provider,
    isLoadingGatewayOrders,
    gatewayOrders,
    hasMore,
    handleLoadMore,
    isSyncingGateway,
  } = useGateway();
  const { analytics, selectedStore, user } = useConfig();

  const [isSideModalOpen, setIsSideModalOpen] = React.useState<boolean>(false);

  const handleSideModalOpen = React.useCallback(() => {
    setIsSideModalOpen(!isSideModalOpen);
  }, [isSideModalOpen]);

  const solveButtonClick = React.useCallback(() => {
    analytics?.track(
      'Task Feed Solve Button Clicked',
      {
        email: user?.email,
      },
      { context: { groupId: selectedStore?.alias_id } },
    );

    handleSideModalOpen();
  }, [analytics, selectedStore, handleSideModalOpen, user]);

  const SideModalContent = GATEWAYS.find(integration => integration.identifier === provider)
    ?.sideModalContent;

  const selectedGatewayProvider = GATEWAYS.find(integration => integration.identifier === provider);

  return (
    <>
      <S.Wrapper>
        <S.Header>
          <S.Title>{`Pedido(s) não conciliado(s) com ${selectedGatewayProvider?.name}`}</S.Title>
          <S.Description size={ETextSize.B5}>
            Não foi possível conciliar os seguintes pedidos com seu gateway. Clique em resolver,
            verifique se a credencial está correta e reprocesse
          </S.Description>
        </S.Header>

        <S.OrdersWrapper id={`gateway-${provider}-orders`}>
          <InfiniteScroll
            dataLength={gatewayOrders.length}
            scrollableTarget={`gateway-${provider}-orders`}
            hasMore={hasMore}
            next={handleLoadMore}
            loader={<InfiniteScrollLoading />}
            scrollThreshold={0.95}
          >
            {!gatewayOrders.length && isLoadingGatewayOrders ? <InfiniteScrollLoading /> : <></>}

            {gatewayOrders.map(order => (
              <Order key={order.alias_id} order={order} />
            ))}
          </InfiniteScroll>
        </S.OrdersWrapper>

        <S.SolveButton
          onClick={solveButtonClick}
          buttonSize={EButtonSize.SMALL}
          isLoading={isSyncingGateway}
          disabled={isSyncingGateway}
        >
          Ajustar
        </S.SolveButton>
      </S.Wrapper>

      {isSideModalOpen && SideModalContent ? (
        <S.SideModal isOpen={isSideModalOpen} toggle={handleSideModalOpen}>
          <SideModalContent syncOnCreate toggle={handleSideModalOpen} isFeedSideModal />
        </S.SideModal>
      ) : (
        <></>
      )}
    </>
  );
};

export default GatewayOrders;
