import { Dsers } from '@profitfy/pakkun-icons';

import { EProductCosts } from '@domain/enums/common/EProductCosts';
import { IErp } from '@domain/interfaces/common/integration/IErp';

import { getDsersSynchronizationRequestConfig } from '@helpers/utils/integrations/cogs/dsers';

import DsersSidemodal from '@components/Dashboard/IntegrationCenter/Sidemodal/Cogs/Dsers';

export const ERPS: Array<IErp> = [
  {
    id: 'dsers',
    name: 'Dsers',
    icon: Dsers,
    sideModal: DsersSidemodal,
    idToVerifyStatus: 'has_dsers_credential',
    identifier: EProductCosts.DSERS,
    lowerIdentifier: 'dsers',
    generateSyncRequest: getDsersSynchronizationRequestConfig,
  },
];
