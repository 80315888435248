import React from 'react';
import { Calendar } from '@profitfy/pakkun-icons';
import { useParams } from 'react-router-dom';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import {
  ESynchronizationRequestType,
  ESynchronizationType,
} from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';

import { useDate } from '@helpers/hooks/useDate';
import { useProductCostDetails } from '@helpers/hooks/pages/dashboard/productCostDetails/productCostDetails';
import { useToast } from '@helpers/hooks/useToast';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import { getEnvironment } from '@helpers/utils/environment';
import { getSynchronizationStatus } from '@helpers/utils/common/synchronization';

import { LONG_API_DOMAIN } from '@constants/api';

import Save from '@components/common/core/DataDisplay/SideModal/Save';

import * as S from './styles';

const COST_SYNC_NAME = 'Custo dos Produtos';

const SyncPastCogsSidemodal: React.FC = () => {
  const { utcToZonedTime, format } = useDate();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const {
    addSynchronization,
    currentSynchronizations,
    synchronizationsQueue,
  } = useSynchronization();
  const {
    isSyncPastCogsSidemodalOpen,
    handleSyncPastCogsSidemodalOpen,
    product,
    isLoadingProduct,
    isValidatingProduct,
    savedVariants,
    costToApplyAll,
    currencyToApplyAll,
  } = useProductCostDetails();

  const hasEditedAll = costToApplyAll || currencyToApplyAll;

  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [period, setPeriod] = React.useState({
    startDate: utcToZonedTime(new Date()),
    endDate: utcToZonedTime(new Date()),
  });

  const handleOnSave = React.useCallback(async () => {
    setIsSaving(true);

    try {
      const filteredSavedVariants = savedVariants.map(variant => {
        return variant.id;
      });

      const params = `?start_date=${format(
        utcToZonedTime(period.startDate),
        'yyyy-MM-dd',
      )}&end_date=${format(utcToZonedTime(period.endDate), 'yyyy-MM-dd')}`;

      addSynchronization({
        name: COST_SYNC_NAME,
        type: ESynchronizationType.STORE_ORDER,
        label: 'Pedidos',
        dependencies: [ESynchronizationType.STORE_PRODUCT],
        storeAliasId,
        config: {
          isOnboardingSynchronization: false,
          showNotification: true,
        },
        request: {
          type: ESynchronizationRequestType.POST,
          domain: LONG_API_DOMAIN,
          api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/orders/cogs/sync`,
          requestParams: params,
          requestBody: {
            scope: hasEditedAll ? 'product' : 'variant',
            product_id: hasEditedAll ? product.id : undefined,
            variant_ids: hasEditedAll ? [] : filteredSavedVariants,
          },
        },
      });

      handleSyncPastCogsSidemodalOpen();

      setIsSaving(false);
    } catch (error: any) {
      setIsSaving(false);
      toast.error(error?.response?.data?.message);
    }
  }, [
    savedVariants,
    toast,
    addSynchronization,
    format,
    utcToZonedTime,
    period,
    storeAliasId,
    handleSyncPastCogsSidemodalOpen,
    hasEditedAll,
    product,
  ]);

  const maxDate = utcToZonedTime(new Date());

  const isLoading = isLoadingProduct || isValidatingProduct;

  const isSyncing = getSynchronizationStatus(
    currentSynchronizations,
    synchronizationsQueue,
    COST_SYNC_NAME,
    ESynchronizationType.STORE_ORDER,
    storeAliasId,
  );

  return (
    <S.Sidemodal isOpen={isSyncPastCogsSidemodalOpen} toggle={handleSyncPastCogsSidemodalOpen}>
      <S.Header>
        <Calendar size={32} outline />
        <S.Title>Alterar período</S.Title>
      </S.Header>

      {isLoading && <div>Loading...</div>}

      {!isLoading && (
        <S.Body>
          <S.ProductName fontWeight={EHeadingWeight.REGULAR}>{product.name}</S.ProductName>
          <S.CustomDatePicker period={period} setPeriod={setPeriod} maxDate={maxDate} />

          <Save
            cancelContent="Cancelar"
            saveContent="Salvar alterações"
            isSubmitting={isSaving || isSyncing}
            isSaveButtonDisabled={isSaving || isSyncing}
            isDeleteButtonDisabled={isSaving || isSyncing}
            type="button"
            onSave={handleOnSave}
          />
        </S.Body>
      )}
    </S.Sidemodal>
  );
};

export default SyncPastCogsSidemodal;
