import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { useToast } from '@helpers/hooks/useToast';
import { EButtonTextColor } from '@domain/enums/components/EButton';
import { ETextWeight } from '@domain/enums/components/EText';

import { affiliateFormSchema } from '@helpers/validators/signUp';

import Text from '@components/common/core/DataDisplay/Text';

import userService from '@services/user';

import logoImg from '@assets/img/brand/profitfy-logo.svg';

import * as S from './styles';

const AffiliateFormSocial: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(affiliateFormSchema),
  });

  const history = useHistory();
  const { toast } = useToast();
  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [affiliateValue, setAffiliateValue] = React.useState<string>('');
  const [isBenefitsTimeOutFinished, setIsBenefitsTimeOutFinished] = React.useState<boolean>(false);
  const [isInputGroupTimeOutFinished, setIsInputGroupTimeOutFinished] = React.useState<boolean>(
    false,
  );

  const onAffiliateChange = React.useCallback(event => {
    setAffiliateValue(event.target.value);
  }, []);

  const onSubmit = React.useCallback(async () => {
    setIsSubmittingForm(true);

    if (!affiliateValue) {
      history.push('/stores');
      return;
    }

    const data = {
      referral_code: affiliateValue,
    };

    try {
      await userService.addAffiliate({ data });

      toast.success('Dados salvos com sucesso!');

      setIsSubmittingForm(false);

      history.push('/stores');
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsSubmittingForm(false);
    }
  }, [history, toast, affiliateValue]);

  React.useEffect(() => {
    const benefitsTimeout = setTimeout(() => {
      setIsBenefitsTimeOutFinished(true);
    }, 700);

    return () => clearTimeout(benefitsTimeout);
  }, []);

  React.useEffect(() => {
    const inputGroupTimeout = setTimeout(() => {
      setIsInputGroupTimeOutFinished(true);
    }, 1500);

    return () => clearTimeout(inputGroupTimeout);
  }, []);

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.Logo src={logoImg} alt="profitfy-logo" />

      {isBenefitsTimeOutFinished && (
        <>
          <S.BenefitsWrapper>
            <S.BenefitTitle weight={ETextWeight.BOLD}>
              Digite o código do seu afiliado
            </S.BenefitTitle>
          </S.BenefitsWrapper>
        </>
      )}

      {isInputGroupTimeOutFinished && (
        <>
          <S.InputGroupAndCreateAccountButton>
            <S.InputGroup>
              <S.Label>Código do afiliado</S.Label>
              <S.Input
                {...register('affiliate')}
                onChange={onAffiliateChange}
                type="affiliate"
                name="affiliate"
                placeholder="Digite o código do seu afiliado"
                isError={errors.affiliate}
                value={affiliateValue}
                data-openreplay-obscured
              />
              {errors.affiliate && <Text isErrorFeedback>{errors.affiliate.message}</Text>}
            </S.InputGroup>

            <S.CreateAccountButton
              type="submit"
              textColor={EButtonTextColor.DARK}
              isLoading={isSubmittingForm}
              disabled={errors.phone || isSubmittingForm}
            >
              Continuar
            </S.CreateAccountButton>
          </S.InputGroupAndCreateAccountButton>
        </>
      )}
    </S.Form>
  );
};

export default AffiliateFormSocial;
