import React from 'react';

import { ITooltipProps } from '@domain/interfaces/dashboard/IntegrationCenter/IIntegrationCard';

import * as S from './styles';

const Tooltip: React.FC<ITooltipProps> = ({ children, content, side, sideOffset }) => {
  return (
    <S.Tooltip>
      <S.TooltipTrigger>{children}</S.TooltipTrigger>

      {content && (
        <S.TooltipContent side={side} sideOffset={sideOffset}>
          {content}

          <S.TooltipArrow />
        </S.TooltipContent>
      )}
    </S.Tooltip>
  );
};

export default Tooltip;
