import { ISynchronizationRequest } from '@domain/interfaces/hooks/IStoreSynchronization';

import {
  ESynchronizationType,
  ESynchronizationRequestType,
} from '@domain/enums/hooks/ESynchronization';

import { IAliases } from '@domain/interfaces/integrations/marketing/tiktok/IAliases';
import { IUpdateTikTokCampaign } from '@domain/interfaces/dashboard/AdSense/TikTok/ITikTokCampaign';

import { LONG_API_DOMAIN } from '@constants/api';

import { getEnvironment } from '@helpers/utils/environment';

import tiktokCampaignService from '@services/pages/dashboard/adSense/tiktok/tiktokCampaign';

export const getUpdateTikTokCampaignPromise = ({
  adSenseCampaignAliasId,
  data,
  productAliasId,
  storeAliasId,
}: IUpdateTikTokCampaign): Promise<any> => {
  return tiktokCampaignService.updateTikTokCampaign({
    adSenseCampaignAliasId,
    data,
    productAliasId,
    storeAliasId,
  });
};

export const getTikTokSynchronizationRequestConfig = (
  type: ESynchronizationType,
  storeAliasId: string | undefined,
  aliases: IAliases | undefined,
  data: any | undefined,
): ISynchronizationRequest => {
  if (type === ESynchronizationType.AD_SENSE_ACCOUNTS) {
    return {
      type: ESynchronizationRequestType.POST,
      domain: LONG_API_DOMAIN,
      api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/ad-sense-credentials/${
        aliases?.adSenseCredentialAliasId
      }/tiktok/ad-sense-accounts/sync`,
    };
  }

  if (type === ESynchronizationType.UPDATE_AD_SENSE_ACCOUNTS) {
    return {
      type: ESynchronizationRequestType.PUT,
      domain: LONG_API_DOMAIN,
      api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/ad-sense-credentials/${
        aliases?.adSenseCredentialAliasId
      }/tiktok/ad-sense-accounts/${aliases?.adSenseAccountAliasId}`,
      requestBody: { ...data },
    };
  }

  if (type === ESynchronizationType.AD_SENSE_CAMPAIGNS) {
    return {
      type: ESynchronizationRequestType.POST,
      domain: LONG_API_DOMAIN,
      api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/tiktok/ad-sense-campaigns/sync`,
    };
  }

  return {
    type: ESynchronizationRequestType.PUT,
    domain: LONG_API_DOMAIN,
    api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/products/${
      aliases?.productAliasId
    }/tiktok/ad-sense-campaigns/${aliases?.adSenseCampaignAliasId}`,
    requestBody: { ...data },
  };
};
