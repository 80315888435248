import React from 'react';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { ISaveProps } from '@domain/interfaces/dashboard/IntegrationCenter/ISave';

import * as S from './styles';

const Save: React.FC<ISaveProps> = ({
  onDelete,
  hasCredentials = false,
  isSubmitting,
  isDeleting,
  isDeleteButtonDisabled,
  isSaveButtonDisabled,
}) => {
  return (
    <S.Background>
      <S.SaveWrapper>
        {hasCredentials && (
          <S.DisableIntegrationButton
            onClick={onDelete}
            variant={EButtonVariant.SECONDARY}
            isLoading={isDeleting}
            disabled={isDeleteButtonDisabled}
          >
            Desativar integração
          </S.DisableIntegrationButton>
        )}

        <S.ConfirmButton type="submit" isLoading={isSubmitting} disabled={isSaveButtonDisabled}>
          Salvar alterações
        </S.ConfirmButton>
      </S.SaveWrapper>
    </S.Background>
  );
};

export default Save;
