export enum EStatus {
  PAID = 'PAID',
  CANCELED = 'CANCELED',
  TRIAL_STARTED = 'TRIAL_STARTED',
  TRIAL_ENDED = 'TRIAL_ENDED',
  PAYMENT_REFUSED = 'PAYMENT_REFUSED',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  PROCESSING_PAYMENT_IN_CREATE = 'PROCESSING_PAYMENT_IN_CREATE',
  PROCESSING_PAYMENT_IN_CREATE_FAILED = 'PROCESSING_PAYMENT_IN_CREATE_FAILED',
  PROCESSING_PAYMENT_IN_UPDATE = 'PROCESSING_PAYMENT_IN_UPDATE',
}

export enum EPlanRecurrence {
  MONTHLY = 'MONTHLY',
  QUARTER = 'QUARTER',
  SEMIANUAL = 'SEMIANUAL',
}
