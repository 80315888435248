import styled, { css } from 'styled-components/macro';

import { ICard } from '@domain/interfaces/components/ICard';

export const Container = styled.div<ICard>`
  padding: 24px;
  border-radius: 15px;
  background: ${({ theme }) => theme.colors.background[2]};
  border: solid 1px ${({ theme }) => theme.colors.card};
  box-sizing: border-box;
  width: 100%;

  ${({ spotlight }) =>
    spotlight &&
    css`
      background: ${({ theme }) => theme.gradient.greenLight};
    `}
`;
