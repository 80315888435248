import React from 'react';

import { useParams } from 'react-router-dom';
import { GoBack } from '@profitfy/pakkun-icons';
import { IParams } from '@domain/interfaces/IParams';
import { useTheme } from 'styled-components/macro';

import * as S from './styles';

export const Return: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const { colors } = useTheme();

  return (
    <S.ReturnWrapper>
      <S.Link to={`/${storeAliasId}/dashboard/ideas`}>
        <GoBack size={25} color={colors.gray[1]} />

        <S.Text>Retornar ao canal de idéias</S.Text>
      </S.Link>
    </S.ReturnWrapper>
  );
};

export default Return;
