import React from 'react';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { useProductCostDetails } from '@helpers/hooks/pages/dashboard/productCostDetails/productCostDetails';

import EmptyData from '@components/common/core/DataDisplay/EmptyData';
import Paginate from '@components/common/core/Utils/Paginate';
import SkeletonLoading from './SkeletonLoading';

import Row from './Row';

import * as S from './styles';

const Table: React.FC = () => {
  const {
    isLoadingVariants,
    variants,
    isValidatingVariants,
    page,
    pageCount,
    onPageChange,
  } = useProductCostDetails();

  const isLoading = isLoadingVariants || isValidatingVariants;

  const hasVariants = Boolean(variants?.length);

  return (
    <>
      <S.VariantsTable>
        <S.VariantsTable.Header>
          <S.VariantsTable.Row>
            <S.VariantsTable.Head>Variante</S.VariantsTable.Head>
            <S.VariantsTable.Head align={EHeadAlign.CENTER}>Preço</S.VariantsTable.Head>
            <S.VariantsTable.Head align={EHeadAlign.CENTER}>Moeda</S.VariantsTable.Head>
            <S.VariantsTable.Head align={EHeadAlign.CENTER}>Custo da Variante</S.VariantsTable.Head>
            <S.VariantsTable.Head align={EHeadAlign.CENTER} />
          </S.VariantsTable.Row>
        </S.VariantsTable.Header>

        <S.VariantsTable.Body>
          {isLoading && <SkeletonLoading />}

          {!isLoading && hasVariants && (
            <>
              {variants?.map(variant => (
                <Row key={variant.id} variant={variant} />
              ))}
            </>
          )}

          {!isLoading && !hasVariants && (
            <S.VariantsTable.Row>
              <S.VariantsTable.Data colSpan={5} align={EHeadAlign.CENTER}>
                <EmptyData content="Não encontramos nenhuma variante." />
              </S.VariantsTable.Data>
            </S.VariantsTable.Row>
          )}
        </S.VariantsTable.Body>

        <S.VariantsTable.Footer>
          <S.VariantsTable.Row>
            <S.VariantsTable.Data colSpan={5} align={EHeadAlign.RIGHT}>
              <Paginate forcePage={page} onPageChange={onPageChange} pageCount={pageCount} />
            </S.VariantsTable.Data>
          </S.VariantsTable.Row>
        </S.VariantsTable.Footer>
      </S.VariantsTable>
    </>
  );
};

export default Table;
