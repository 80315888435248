import React from 'react';

import { Facebook, Copy } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { EButtonTextColor } from '@domain/enums/components/EButton';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { IAuthenticateModalProps } from '@domain/interfaces/onboarding/components/facebook/modal';
import { IParams } from '@domain/interfaces/IParams';
import { useConfig } from '@helpers/hooks/useConfig';
import { useToast } from '@helpers/hooks/useToast';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useFacebookCredentials } from '@helpers/hooks/common/integrations/marketing/facebook/useFacebookCredentials';

import * as S from './styles';

const AuthenticateModal: React.FC<IAuthenticateModalProps> = ({ isOpen, toggle }) => {
  const { storeAliasId } = useParams<IParams>();

  const theme = useTheme();
  const { toast } = useToast();
  const { authorization } = useConfig();
  const { currentSynchronizations } = useSynchronization();
  const { syncAllAdSenseAccounts, mutateAdSenseCredentials } = useFacebookCredentials();

  const [isLinkCopied, setIsLinkCopied] = React.useState<boolean>(false);

  const authenticationLink = `https://app.profitfy.me/${storeAliasId}/facebook-login?auth=${authorization}`;

  const handleCopyLink = React.useCallback(() => {
    navigator.clipboard.writeText(authenticationLink);
    setIsLinkCopied(true);
    toast.success('Copiado com sucesso!');
  }, [authenticationLink, toast]);

  const handleUpdateAccountsButtonClick = React.useCallback(async () => {
    const { data: adSenseCredentialsResponse } = await mutateAdSenseCredentials();
    syncAllAdSenseAccounts(adSenseCredentialsResponse?.ad_sense_credentials);
    toggle();
  }, [syncAllAdSenseAccounts, toggle, mutateAdSenseCredentials]);

  const isSyncing = Boolean(
    currentSynchronizations?.synchronizations.find(synchronization => {
      if (
        synchronization.type === ESynchronizationType.AD_SENSE_ACCOUNTS ||
        synchronization.type === ESynchronizationType.AD_SENSE_CAMPAIGNS
      ) {
        return true;
      }

      return false;
    }),
  );

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.HeadingWrapper>
        <Facebook size={24} />
        <S.Title weight={ETextWeight.MEDIUM}>Autenticar perfil do Facebook</S.Title>
      </S.HeadingWrapper>

      <S.StepsWrapper>
        <S.StepTitle>Conecte seu perfil do Facebook seguindo os passos abaixo:</S.StepTitle>
        <S.Step>
          <S.StepBullet />
          <S.StepDescription size={ETextSize.B5}>Copie o link no campo abaixo.</S.StepDescription>
        </S.Step>

        <S.Step>
          <S.StepBullet />
          <S.StepDescription size={ETextSize.B5}>
            Cole no navegador onde o perfil que deseja conectar está logado.
          </S.StepDescription>
        </S.Step>

        <S.Step>
          <S.StepBullet />
          <S.StepDescription size={ETextSize.B5}>
            Logue outra conta que deseja conectar.
          </S.StepDescription>
        </S.Step>
      </S.StepsWrapper>

      <S.AuthenticateLinkGroup>
        <S.LinkLabel>Link para autenticação com facebook</S.LinkLabel>

        <S.AuthenticateLinkWrapper onClick={handleCopyLink}>
          <S.AuthenticateLink weight={ETextWeight.MEDIUM}>{authenticationLink}</S.AuthenticateLink>

          <Copy size={18} color={theme.colors.gray[1]} outline={!isLinkCopied} />
        </S.AuthenticateLinkWrapper>
      </S.AuthenticateLinkGroup>

      <S.Description size={ETextSize.B5}>
        Após autenticar com sua conta do facebook por meio do link acima, clique no botão abaixo,
        para realizar a sincronização.
      </S.Description>

      <S.ButtonsWrapper>
        <S.CancelButton onClick={toggle}>Cancelar</S.CancelButton>
        <S.UpdateAccountButton
          textColor={EButtonTextColor.DARK}
          onClick={handleUpdateAccountsButtonClick}
          disabled={isSyncing || !isLinkCopied}
        >
          Atualizar minhas contas
        </S.UpdateAccountButton>
      </S.ButtonsWrapper>
    </S.Modal>
  );
};

export default AuthenticateModal;
