import React from 'react';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { ICard } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/ISummaryReport';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import { numberFormatter } from '@helpers/masks';

import BlurredContent from './BlurredContent';

import * as S from './styles';

const CreditCard: React.FC<ICampaignProps> = ({ data }) => {
  const cardData: ICard = data;

  const { store } = useStoreConfig();
  const { isDemoEnvironment } = useConfig();

  const isContentBlurred = !store.is_utm_onboarding_finished && !isDemoEnvironment;

  return (
    <S.Wrapper>
      {isContentBlurred ? (
        <BlurredContent />
      ) : (
        <>
          <S.ContentWrapper>
            <S.Content>
              <S.LabelText>Receita</S.LabelText>
              <S.AmountText>{`R$ ${numberFormatter(cardData.total_amount, 2)}`}</S.AmountText>
            </S.Content>

            <S.Content>
              <S.LabelText>CPA</S.LabelText>
              <S.AmountText>{`R$ ${numberFormatter(cardData.total_cpa_amount, 2)}`}</S.AmountText>
            </S.Content>
          </S.ContentWrapper>

          <S.TotalLabel>Total</S.TotalLabel>
        </>
      )}
    </S.Wrapper>
  );
};

export default CreditCard;
