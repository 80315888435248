import React from 'react';

import { EBadgeColors } from '@domain/enums/components/EBadge';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { IMemberRowProps } from '@domain/interfaces/dashboard/AccessManagement/IMemberRow';

import { useAccessManager } from '@helpers/hooks/pages/dashboard/accessManagement/useAccessManager';

import * as S from './styles';

const MemberRow: React.FC<IMemberRowProps> = ({ user }) => {
  const { selectedUsersStoreId, handleSelectedUsersStoreId } = useAccessManager();

  const foundSelectedUser = selectedUsersStoreId.find(userId => userId === user.user.id);

  const onCheckboxChange = React.useCallback(
    event => {
      if (!event || event.target) return;

      if (foundSelectedUser) {
        const filteredUsersStoreId = selectedUsersStoreId.filter(
          selectedUserId => selectedUserId !== user.user.id,
        );

        handleSelectedUsersStoreId(filteredUsersStoreId);
      } else {
        handleSelectedUsersStoreId([...selectedUsersStoreId, user.user.id]);
      }
    },
    [foundSelectedUser, user.user.id, handleSelectedUsersStoreId, selectedUsersStoreId],
  );

  return (
    <S.Table.Row borderBottom>
      <S.Table.Data>
        <S.CheckboxWrapper>
          <S.Checkbox checked={Boolean(foundSelectedUser)} onChange={onCheckboxChange} />
        </S.CheckboxWrapper>
      </S.Table.Data>

      <S.Table.Data>
        <S.MemberNameTableDataContent>
          <S.MemberName
            weight={ETextWeight.MEDIUM}
          >{`${user.user.first_name} ${user.user.last_name}`}</S.MemberName>
          <S.GroupName weight={ETextWeight.MEDIUM} size={ETextSize.B5}>
            {user.group.name}
          </S.GroupName>
        </S.MemberNameTableDataContent>
      </S.Table.Data>

      <S.Table.Data>
        <S.EmailTableDataContent>
          {user.user.email}
          <S.Badge color={EBadgeColors.PRIMARY}>Membro</S.Badge>
        </S.EmailTableDataContent>
      </S.Table.Data>
    </S.Table.Row>
  );
};

export default MemberRow;
