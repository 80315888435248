import styled, { keyframes } from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

const appearFromBottom = keyframes`
  from {
    transform: translateX(-120px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const Wrapper = styled.button`
  position: relative;
  animation: ${appearFromBottom} 0.8s;
  width: 100%;
  max-width: 400px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.background[2]};
  padding: 20px 24px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.green.default};
  display: flex;
  gap: 12px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const TitleAndDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  line-height: 120%;
`;

export const Title = styled(TextBase)`
  color: ${({ theme }) => theme.colors.white.default};
  line-height: 120%;
`;

export const Description = styled(TextBase)`
  color: #ffffff;
  text-align: left;
  line-height: 150%;
`;

export const CloseIcon = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: unset;
  padding: unset;
  border: unset;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  min-width: 40px;
  width: 40px;
  min-height: 40px;
  height: 40px;
`;
