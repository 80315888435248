import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';

import * as S from './styles';

const Header: React.FC = ({ children, ...rest }) => {
  return (
    <S.Heading as={EHeadingSize.H5} weight={EHeadingWeight.MEDIUM} {...rest}>
      {children}
    </S.Heading>
  );
};

export default Header;
