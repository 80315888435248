import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import { ISubItem } from '@domain/interfaces/components/ISidebar';
import { ETextWeight } from '@domain/enums/components/EText';

import { useMediaQueryHeight } from '@helpers/hooks/useMediaQuery';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import * as S from './styles';

const SubItem: React.FC<ISubItem> = ({
  icon: Icon,
  name,
  url,
  selectedMainItem,
  isSidebarExpanded,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const { pathname } = useLocation();
  const isSmallDeviceHeight = useMediaQueryHeight('700px');
  const { store } = useStoreConfig();

  const [isHoveringItem, setIsHoveringItem] = React.useState<boolean>(false);
  const [storeAliasId, setStoreAliasId] = React.useState<string>('');

  const isMouseLeavingItem = React.useCallback(() => setIsHoveringItem(false), []);

  const isMouseEnteringItem = React.useCallback(() => setIsHoveringItem(true), []);

  const isSelected = React.useCallback(() => {
    if (pathname.includes(`${selectedMainItem?.url}${url}`)) return true;

    return false;
  }, [selectedMainItem, pathname, url]);

  const getMainRoute = React.useCallback((urlItem: string | undefined): string | undefined => {
    if (urlItem === '/automations') return '/marketing';

    if (urlItem === '/ads') return '/marketing';

    if (urlItem === '/products') return '/overview';

    return urlItem;
  }, []);

  const onItemClick = React.useCallback(() => {
    const isAutomationsUrl = url === '/automations';
    const hasNotFinishedAutomationsOnboarding = !store?.is_automation_onboarding_finished;

    if (isAutomationsUrl && hasNotFinishedAutomationsOnboarding) {
      history.push(
        `/${storeAliasId}/dashboard${getMainRoute(selectedMainItem?.url)}${url}/welcome`,
      );
    } else {
      history.push(`/${storeAliasId}/dashboard${getMainRoute(selectedMainItem?.url)}${url}`);
    }
  }, [history, selectedMainItem, url, storeAliasId, getMainRoute, store]);

  const getIconSize = React.useCallback(() => {
    if (isSmallDeviceHeight) return 18;

    return 24;
  }, [isSmallDeviceHeight]);

  React.useEffect(() => {
    const foundStoreAliasId = pathname.match(/store-([a-zA-Z0-9]+)/);

    if (foundStoreAliasId?.length) {
      setStoreAliasId(foundStoreAliasId[0]);
    }
  }, [pathname]);

  const isSubItemSelected = isSelected();

  const getIconColor = (): string => {
    if (isSubItemSelected) return theme.colors.green.default;

    return theme.colors.gray[1];
  };

  const iconColor = getIconColor();

  const textContent = isSidebarExpanded ? name : '';

  return (
    <S.SubItem
      onMouseEnter={isMouseEnteringItem}
      onMouseLeave={isMouseLeavingItem}
      onClick={onItemClick}
      isHoveringItem={isHoveringItem}
      isSelected={isSelected()}
      isSidebarExpanded={isSidebarExpanded}
    >
      <Icon size={getIconSize()} color={iconColor} weight="bold" />

      <S.Text weight={ETextWeight.MEDIUM}>{textContent}</S.Text>
    </S.SubItem>
  );
};

export default React.memo(SubItem);
