import { ApiService } from '@services/api';
import { WHATSAPP_CONFIG_API_DOMAIN } from '@constants/api';
import { useFetch } from '@providers/Fetch/useFetch';
import {
  IListAllWhatsappCredentialsWorkflowProps,
  IListAllWhatsappCredentialsWorkflowResponse,
} from '@domain/interfaces/dashboard/WhatsappInstance/IWhatsappCredentialWorkflow';

export class WhatsappCredentialWorkflowService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listAllWhatsappCredentialsWorkflow({
    storeAliasId,
    workflowAliasId,
  }: IListAllWhatsappCredentialsWorkflowProps): IListAllWhatsappCredentialsWorkflowResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/workflows/${workflowAliasId}/whatsapp-credentials-workflows`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      whatsappCredentialsWorkflow: data?.whatsapp_credentials_workflows,
      error,
      isLoading,
      isValidating,
      mutate,
    };
  }
}

const whatsappCredentialWorkflowService = new WhatsappCredentialWorkflowService(
  WHATSAPP_CONFIG_API_DOMAIN,
);

export default whatsappCredentialWorkflowService;
