import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import FormBase from '@components/common/core/Inputs/Form';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.gray[7]};
  display: flex;
  align-items: flex-start;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 32px;
  box-sizing: border-box;

  & > div {
    margin: 0;
  }
`;

export const HeaderAndTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 18px;
  margin: 0 auto;
  padding-top: 108px;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 120px;
  max-width: 1016px;

  @media only screen and (max-width: 1380px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media only screen and (max-height: 768px) {
    padding-top: 48px;
  }

  @media only screen and (max-height: 545px) {
    padding-top: 20px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  padding-top: 142px;
  gap: 78px;

  @media only screen and (max-width: 1320px) {
    padding-left: 20px;
    padding-right: 20px;
    gap: 24px;
  }

  @media only screen and (max-width: 1250px) {
    flex-direction: column;
    padding: 80px 20px 120px 20px;
  }

  @media only screen and (max-height: 768px) {
    padding-top: 48px;
  }
`;

export const Form = styled(FormBase)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 489px;

  @media only screen and (max-width: 620px) {
    min-width: unset;
  }
`;

export const Heading = styled(HeadingBase)`
  font-size: 1.75rem;
  color: ${({ theme }) => theme.colors.white.default};
  margin-bottom: 32px;
`;

export const IconAndTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-bottom: 42px;
`;

export const FormTitle = styled(HeadingBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)``;
