import React from 'react';

import {
  PendingOrder,
  PaidOrders,
  ProfitMargin,
  Cpa,
  Roi,
  Ticket,
  BoxWithCurrency,
} from '@profitfy/pakkun-icons';
import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { useMediaQuery } from '@helpers/hooks/useMediaQuery';

import MetricCard from './MetricCard';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Metrics: React.FC = () => {
  const mobileWidth = useMediaQuery('1360px');
  const {
    financialDetailsReport,
    isLoadingFinancialDashboardProvider,
    isValidatingFinancialDashboardProvider,
    isLoadingFinancialDashboardPromise,
  } = useFinancialDashboard();

  const sliderPaymentMethodsCardsRef = React.useRef<HTMLDivElement>(null);

  const [isMouseMoveOn, setIsMouseMoveOn] = React.useState<boolean>(false);
  const [onMouseMoveDownEvent, setOnMouseMoveDownEvent] = React.useState<any>({});
  const [scrollLeft, setScrollLeft] = React.useState<number | undefined>(0);

  const handleOnMouseDown = React.useCallback(event => {
    setIsMouseMoveOn(true);
    setOnMouseMoveDownEvent(event);
  }, []);

  const handleOnMouseUp = React.useCallback(() => {
    setIsMouseMoveOn(false);
    setScrollLeft(sliderPaymentMethodsCardsRef?.current?.scrollLeft);
    setOnMouseMoveDownEvent({});
  }, []);

  const handleOnMouseMove = React.useCallback(
    event => {
      event.persist();

      if (isMouseMoveOn && sliderPaymentMethodsCardsRef.current) {
        const position = onMouseMoveDownEvent.pageX - event.clientX;

        sliderPaymentMethodsCardsRef.current.scrollLeft = (scrollLeft || 0) + position;
      }
    },
    [isMouseMoveOn, scrollLeft, sliderPaymentMethodsCardsRef, onMouseMoveDownEvent],
  );

  if (
    isLoadingFinancialDashboardProvider ||
    isValidatingFinancialDashboardProvider ||
    isLoadingFinancialDashboardPromise
  ) {
    return <SkeletonLoading />;
  }

  return (
    <>
      {mobileWidth ? (
        <S.MobileWrapper
          onMouseDown={handleOnMouseDown}
          onMouseUp={handleOnMouseUp}
          onMouseMove={handleOnMouseMove}
          ref={sliderPaymentMethodsCardsRef}
          tabIndex={0}
        >
          <MetricCard
            type="QUANTITY"
            title="Pedidos aprovados"
            value={financialDetailsReport.paid_orders.amount}
            icon={PaidOrders}
          />
          <MetricCard
            type="QUANTITY"
            title="Pedidos pendentes"
            value={financialDetailsReport.pending_orders.amount}
            icon={PendingOrder}
          />
          <MetricCard
            type="CURRENCY"
            title="Ticket médio"
            value={financialDetailsReport.average_ticket.amount}
            icon={Ticket}
          />
          <MetricCard
            type="PERCENTAGE"
            title="Margem de lucro"
            value={financialDetailsReport.profit_margin.amount}
            icon={ProfitMargin}
          />
          <MetricCard
            type="CURRENCY"
            title="Custo por aquisição"
            value={financialDetailsReport.cpa.amount}
            icon={Cpa}
          />
          <MetricCard
            type="PERCENTAGE"
            title="ROI"
            value={financialDetailsReport.roi.amount * 100}
            icon={Roi}
          />
        </S.MobileWrapper>
      ) : (
        <S.Wrapper>
          <MetricCard
            type="QUANTITY"
            title="Pedidos aprovados"
            value={financialDetailsReport.paid_orders.amount}
            icon={BoxWithCurrency}
          />
          <MetricCard
            type="PERCENTAGE"
            title="Margem de lucro"
            value={financialDetailsReport.profit_margin.amount}
            icon={ProfitMargin}
          />
          <MetricCard
            type="QUANTITY"
            title="Pedidos pendentes"
            value={financialDetailsReport.pending_orders.amount}
            icon={PendingOrder}
          />
          <MetricCard
            type="CURRENCY"
            title="Custo por aquisição"
            value={financialDetailsReport.cpa.amount}
            icon={Cpa}
          />
          <MetricCard
            type="CURRENCY"
            title="Ticket médio"
            value={financialDetailsReport.average_ticket.amount}
            icon={Ticket}
          />
          <MetricCard
            type="PERCENTAGE"
            title="ROI"
            value={financialDetailsReport.roi.amount * 100}
            icon={Roi}
          />
        </S.Wrapper>
      )}
    </>
  );
};

export default Metrics;
