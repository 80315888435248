import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { STORE_CONFIG_API_DOMAIN } from '@constants/api';
import {
  ICreateFinancialDashboardConfigurationProps,
  IGetFinancialDashboardConfigurationProps,
  IGetFinancialDashboardConfigurationResponse,
  IUpdateFinancialDashboardConfigurationProps,
} from '@domain/interfaces/dashboard/FinancialDashboardConfig/IFinancialDashboardConfig';

export class FinancialDashboardConfigService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getFinancialDashboardConfiguration({
    storeAliasId,
  }: IGetFinancialDashboardConfigurationProps): IGetFinancialDashboardConfigurationResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/financial-dashboard-configurations`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      financialDashboardConfiguration: data?.financial_dashboard_configuration,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public createFinancialDashboardConfiguration({
    storeAliasId,
    data,
  }: ICreateFinancialDashboardConfigurationProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/financial-dashboard-configurations`,
      { ...data },
    );
  }

  public updateFinancialDashboardConfiguration({
    storeAliasId,
    data,
  }: IUpdateFinancialDashboardConfigurationProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/financial-dashboard-configurations`,
      { ...data },
    );
  }
}

const financialDashboardConfigService = new FinancialDashboardConfigService(
  STORE_CONFIG_API_DOMAIN,
);

export default financialDashboardConfigService;
