import styled, { css } from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

import { IChartButtonProps } from '@domain/interfaces/dashboard/Chart/IChart';

export const ChartContainer = styled.div`
  max-width: 860px;
  width: 100%;
  position: relative;
  border: 2px solid ${({ theme }) => theme.colors.gray[6]};
  border-radius: 12px;
  padding: 14px 22px;
  box-sizing: border-box;
  margin-bottom: 20px;

  @media only screen and (max-width: 1360px) {
    max-width: unset;
  }
`;

export const ChartAndInfosWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 16px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Chart = styled.div`
  width: 100%;
  height: 228px;
`;

export const ChartButton = styled.div<IChartButtonProps>`
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: unset;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;

  ${({ theme, isSelected }) =>
    isSelected &&
    css`
      background-color: ${`${theme.colors.green.default}19`};
    `};

  &:hover {
    background-color: ${({ theme }) => `${theme.colors.green.default}19`};
  }
`;

export const ChartButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Title = styled(HeadingBase)`
  font-size: 0.875rem;
  line-height: 100%;
`;

export const ChartHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-between;
`;

export const ErrorWrapper = styled.div`
  height: 363px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(100, 100, 100, 0.1);
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 28px;
  box-sizing: border-box;
`;

export const GrossRevenueAndNetRevenueWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 158px;
  gap: 36px;

  @media only screen and (max-width: 768px) {
    flex-direction: row;
  }
`;

export const GrossRevenueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const GrossRevenueValue = styled(TextBase)`
  color: ${({ theme }) => theme.colors.white.default};
  line-height: 120%;
`;

export const Description = styled(TextBase)`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.gray[2]};
  text-align: center;
  line-height: 120%;
`;

export const PendingAndPercentageShippingsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 158px;
  gap: 36px;

  @media only screen and (max-width: 768px) {
    flex-direction: row;
  }
`;

export const PendingShippingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PendingShippingsValue = styled(TextBase)`
  color: ${({ theme }) => theme.colors.white.default};
  line-height: 120%;
`;

export const PercentageShippings = styled(TextBase)`
  color: ${({ theme }) => theme.colors.white.default};
  line-height: 120%;
`;

export const NetRevenueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PercentageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NetRevenueValue = styled(TextBase)`
  color: ${({ theme }) => theme.colors.white.default};
`;
