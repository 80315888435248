import React from 'react';

import { ETextSize } from '@domain/enums/components/EText';

import SelectAudio from './SelectAudio';
import RecordAudio from './RecordAudio';

import * as S from './styles';

const AudioTypeConfig: React.FC = () => {
  const [selectedOption, setSelectedOption] = React.useState<string>('select_audio');

  const onOptionClick = React.useCallback(event => {
    setSelectedOption(event.target.value);
  }, []);

  const isSelectAudioOption = selectedOption === 'select_audio';
  const isRecordAudioOption = selectedOption === 'record_audio';

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title size={ETextSize.B5}>Configure o áudio</S.Title>

        <S.SelectOptionWrapper>
          <S.SelectOptionButton
            type="button"
            value="select_audio"
            isSelected={isSelectAudioOption}
            onClick={onOptionClick}
          >
            Selecionar arquivo de áudio
          </S.SelectOptionButton>

          <S.SelectOptionButton
            type="button"
            value="record_audio"
            isSelected={isRecordAudioOption}
            onClick={onOptionClick}
          >
            Gravar um novo áudio
          </S.SelectOptionButton>
        </S.SelectOptionWrapper>

        {isSelectAudioOption && <SelectAudio />}

        {isRecordAudioOption && <RecordAudio />}
      </S.Content>
    </S.Wrapper>
  );
};

export default AudioTypeConfig;
