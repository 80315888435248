import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ImageBase from '@components/common/core/DataDisplay/Image';
import NoImageBase from '@components/common/core/DataDisplay/NoImage';

export const ExpandIconWrapper = styled.div``;

export const QuantityText = styled(TextBase)`
  white-space: nowrap;
`;

export const ProductSku = styled(TextBase)`
  max-width: 432px;
  width: 100%;
  -webkit-line-clamp: 2;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 150%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.colors.font};
`;

export const ProductName = styled(TextBase)`
  line-height: 150%;
  max-width: 460px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
`;

export const NoImage = styled(NoImageBase)`
  background-color: ${({ theme }) => theme.colors.gray[5]};
  min-width: 48px;
  height: 48px;
`;

export const Image = styled(ImageBase)`
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

export const VariantNumberText = styled(HeadingBase)`
  color: #fff;
  font-size: 1rem;
`;

export const Wrapper = styled.div`
  padding: 16px 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 8px;
  gap: 20px;
  cursor: pointer;
  transition: all 0.3s;
  border: 2px solid transparent;
  margin-bottom: 24px;

  &:hover {
    border-color: ${({ theme }) => theme.colors.gray[4]};
  }
`;
