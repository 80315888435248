import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const SolveButton = styled(ButtonBase)`
  white-space: nowrap;
`;

export const DescriptionText = styled(TextBase)`
  line-height: 120%;
  color: #ffffff;
`;

export const TitleText = styled(TextBase)`
  line-height: 120%;
  display: block;
  margin-bottom: 4px;
  color: #ffffff;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconAndTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & > svg {
    min-width: 32px;
    width: 32px;
    height: 32px;
  }
`;

export const Content = styled.div`
  max-width: 1262px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;

  @media only screen and (max-width: 1168px) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 12px 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.danger.default};
  border-radius: 7px;
`;
