import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import ImageBase from '@components/common/core/DataDisplay/Image';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Modal = styled(ModalBase)`
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 620px;
  box-sizing: border-box;
  z-index: ${({ theme }) => theme.zIndex.sixthLayer + 2};
`;

export const Image = styled(ImageBase)`
  width: 100%;
  max-width: 250px;
  margin-bottom: 10px;
`;

export const Title = styled(TextBase)`
  margin-bottom: 18px;
  color: ${({ theme }) => theme.colors.white.default};
  line-height: 120%;
  text-align: center;
`;

export const Description = styled(TextBase)`
  text-align: center;
  margin-bottom: 48px;
  max-width: 524px;
`;

export const AnalyzeDataButton = styled(ButtonBase)``;
