import React from 'react';
import { Store, ShopifyPlusYampiOnboarding } from '@profitfy/pakkun-icons';
import { useParams } from 'react-router-dom';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { EDropshippingProvider } from '@domain/enums/common/integrations/EDropshippingProvider';
import { ISidemodalProps } from '@domain/interfaces/onboarding/integrations/ISidemodal';
import { IParams } from '@domain/interfaces/IParams';

import { useDropshipping } from '@helpers/hooks/pages/onboarding/useDropshipping';
import { useConfig } from '@helpers/hooks/useConfig';
import { useMediaQueryHeight } from '@helpers/hooks/useMediaQuery';

import { DROPSHIPPING_PROVIDERS } from '@constants/common/integrations';

import SuggestionModal from './SuggestionModal';

import Save from './Save';

import * as S from './styles';

const SideModal: React.FC<ISidemodalProps> = ({ isOpen, toggle }) => {
  const { handleSelectedProvider, selectedProvider, storeIntegrations } = useDropshipping();
  const { analytics, user } = useConfig();
  const { storeAliasId } = useParams<IParams>();

  const mobileHeight = useMediaQueryHeight('768px');

  const [selectedPlatform, setSelectedPlatform] = React.useState<EDropshippingProvider | undefined>(
    undefined,
  );
  const [isSuggestionModalOpen, setIsSuggestionModalOpen] = React.useState<boolean>(false);

  const handleSuggestionModalOpen = React.useCallback(
    () => setIsSuggestionModalOpen(!isSuggestionModalOpen),
    [isSuggestionModalOpen],
  );

  const openSuggestionModal = React.useCallback(() => {
    handleSuggestionModalOpen();
  }, [handleSuggestionModalOpen]);

  const onChange = React.useCallback(event => {
    setSelectedPlatform(event.target.value);
  }, []);

  const onSave = React.useCallback(() => {
    const foundProvider = DROPSHIPPING_PROVIDERS.find(
      integration => integration.identifier === selectedPlatform,
    );

    handleSelectedProvider(selectedPlatform);

    analytics?.track(
      'Onboarding Selected E-commerce',
      {
        ecommerce_provider: foundProvider?.name,
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );
    analytics?.trackHubSpot(storeAliasId, { ecom_platform: foundProvider?.name });

    toggle();
  }, [selectedPlatform, handleSelectedProvider, toggle, analytics, storeAliasId, user]);

  const hasProviderIntegration = storeIntegrations?.store_front.find(
    provider => provider.is_active,
  );

  return (
    <>
      <S.Sidemodal isOpen={isOpen} toggle={toggle} blockOverflowYScroll={mobileHeight}>
        <S.Header>
          <Store size={24} />
          <S.Title fontWeight={EHeadingWeight.REGULAR} type={EHeadingSize.H4}>
            Selecionar plataformas de ecommerce
          </S.Title>
        </S.Header>

        <S.Body>
          <S.NoIntegrationText>
            Não encontrou a plataforma que você usa?{' '}
            <S.SuggestionButton onClick={openSuggestionModal}>
              Nos informe clicando aqui.
            </S.SuggestionButton>
          </S.NoIntegrationText>

          <S.IntegrationsWrapper>
            {DROPSHIPPING_PROVIDERS.map(integration => {
              const Icon = integration.icon;

              const isShopifyPlusYampiIntegration =
                integration.name === 'Checkout Yampi + Loja Shopify';

              return (
                <S.InputGroup key={integration.identifier}>
                  <S.RadioLabel>
                    <S.RadioTextWrapper>
                      {isShopifyPlusYampiIntegration && <ShopifyPlusYampiOnboarding size={24} />}

                      {!isShopifyPlusYampiIntegration && <Icon size={24} />}
                      <S.RadioText>{integration.name}</S.RadioText>

                      {integration.identifier === EDropshippingProvider.LPQV && (
                        <S.BetaBadge>Beta</S.BetaBadge>
                      )}
                    </S.RadioTextWrapper>

                    <S.Radio
                      value={integration.identifier}
                      name="platform"
                      defaultChecked={
                        selectedPlatform
                          ? selectedPlatform === integration.identifier
                          : selectedProvider === integration.identifier
                      }
                      onChange={onChange}
                      id={`${integration.identifier}-option`}
                      disabled={Boolean(hasProviderIntegration)}
                    />
                  </S.RadioLabel>
                </S.InputGroup>
              );
            })}
          </S.IntegrationsWrapper>
        </S.Body>

        <Save onCancel={toggle} onSave={onSave} />
      </S.Sidemodal>

      <SuggestionModal isOpen={isSuggestionModalOpen} toggle={handleSuggestionModalOpen} />
    </>
  );
};

export default SideModal;
