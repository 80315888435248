export enum ECalculateMode {
  WITH_COGS = 'WITH-COGS',
  WITHOUT_COGS = 'WITHOUT-COGS',
  WITH_ADS = 'WITH-ADS',
}

export enum ERegime {
  SIMPLES_NACIONAL = 'SIMPLES_NACIONAL',
  LUCRO_PRESUMIDO = 'LUCRO_PRESUMIDO',
  LUCRO_REAL = 'LUCRO_REAL',
}
