import styled from 'styled-components/macro';

export const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;

export const HiddenContent = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[5]} !important;
`;
