import React from 'react';

import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';
import { ISavedContent } from '@domain/interfaces/common/automations/ISavedContent';
import { IContentProvider } from '@domain/interfaces/common/automations/IContent';

const ContentContext = React.createContext<IContentProvider | null>(null);

export const ContentProvider: React.FC = ({ children }) => {
  const [textPreview, setTextPreview] = React.useState<string>('');
  const [imagePreview, setImagePreview] = React.useState<File | undefined>(undefined);
  const [videoPreview, setVideoPreview] = React.useState<File | undefined>(undefined);
  const [pdfPreview, setPDFPreview] = React.useState<File | undefined>(undefined);
  const [contentToEdit, setContentToEdit] = React.useState<ISavedContent | undefined>(undefined);
  const [isEditingContent, setIsEditingContent] = React.useState<boolean>(false);
  const [isCreatingContent, setIsCreatingContent] = React.useState<boolean>(false);
  const [selectedContentType, setSelectedContentType] = React.useState<EConfigOption>(
    EConfigOption.NONE,
  );

  const handleContentToEdit = React.useCallback(
    newContentToEdit => setContentToEdit(newContentToEdit),
    [],
  );

  const handleEditingContent = React.useCallback(state => setIsEditingContent(state), []);

  const handleCreatingContent = React.useCallback(state => setIsCreatingContent(state), []);

  const handleSelectedContentType = React.useCallback(
    contentType => setSelectedContentType(contentType),
    [],
  );

  const handleTextPreview = React.useCallback(text => setTextPreview(text), []);

  const handleImagePreview = React.useCallback(image => setImagePreview(image), []);

  const handleVideoPreview = React.useCallback(video => setVideoPreview(video), []);

  const handlePDFPreview = React.useCallback(pdf => setPDFPreview(pdf), []);

  return (
    <ContentContext.Provider
      value={{
        handleContentToEdit,
        handleCreatingContent,
        handleEditingContent,
        handleSelectedContentType,
        handleImagePreview,
        handleTextPreview,
        isCreatingContent,
        isEditingContent,
        selectedContentType,
        contentToEdit,
        textPreview,
        imagePreview,
        videoPreview,
        handleVideoPreview,
        pdfPreview,
        handlePDFPreview,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};

export const useContent = (): IContentProvider => {
  const context = React.useContext(ContentContext);

  if (!context) throw new Error('useContent must be used within ContentContext');

  return context;
};
