import styled from 'styled-components/macro';

import feedEmptyBackground from '@assets/components/Feed/feed.svg';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Container = styled.div`
  max-width: 1262px;
  width: 100%;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(TextBase)`
  font-size: 2.25rem;
  margin-top: 40px;
  text-align: center;
  max-width: 473px;
  display: block;
`;

export const Image = styled.div`
  height: 371px;
  width: 100%;
  background-image: url(${feedEmptyBackground});
  background-position: center;
  background-repeat: no-repeat;
`;
