import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Text = styled(TextBase)`
  font-size: 0.625rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const Wrapper = styled.div`
  width: 28px;
  height: 11px;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
