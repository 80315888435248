import Text from '@components/common/core/DataDisplay/Text';
import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  padding: 48px 40px 0px 40px;
  max-width: 1200px;
`;

export const Title = styled(Text)`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 180%;
`;

export const CalculatorWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 24px;

  @media (max-width: 1280px) {
    flex-direction: column;
  }
`;
