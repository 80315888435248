export default {
  mainShadow: '0px 6px 18px rgba(0, 0, 0, 0.06)',
  buttonDefault: '2px 1px 16px 1px rgba(37, 207, 187, 0.2)',
  buttonHover: '2px 1px 16px 1px rgba(111, 213, 195, 0.2)',
  buttonPressed: '1px 2px 5px rgba(46, 179, 180, 0.35)',
  dangerFocusedTyping: '0px 0px 3px #FF2626',
  warningFocusedTyping: '0px 0px 3px #FFAB00',
  successFocusedTyping: '0px 0px 3px #28CF87',
  calendar: '2px 1px 16px 1px rgba(0, 0, 0, 0.2)',
};
