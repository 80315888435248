import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 625px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 52px;
  align-self: flex-start;
  padding-top: 72px;
`;
