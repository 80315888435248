import React from 'react';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { IMainSection } from '@domain/interfaces/dashboard/BenefitArea/IMainSection';

import ZendropLogoImg from '@assets/dashboard/benefitArea/zendrop-logo.svg';

import * as S from './styles';

const MainSection: React.FC<IMainSection> = ({ sendEvent, isSendingCogsInformation }) => {
  return (
    <S.Container>
      <S.Content>
        <S.Image src={ZendropLogoImg} alt="profitfy-zendrop-logo-image" />

        <S.Title fontWeight={EHeadingWeight.MEDIUM}>
          Faça parte do{' '}
          <strong>
            <mark>Zendrop Select</mark>
          </strong>{' '}
          e <strong>aumente o seu lucro</strong> em até <strong>12%</strong>
        </S.Title>

        <S.Description>
          O <strong>Zendrop Select</strong> é um plano <strong>exclusivo</strong> para clientes com{' '}
          <strong>alto volume.</strong> Faça parte desse grupo <strong>sem pagar nada</strong> e
          tenha acesso à <strong>vários benefícios!</strong>
        </S.Description>

        <S.ButtonsWrapper>
          <S.OportunityButton
            onClick={sendEvent}
            isLoading={isSendingCogsInformation}
            disabled={isSendingCogsInformation}
          >
            Aproveitar oportunidade
          </S.OportunityButton>
        </S.ButtonsWrapper>
      </S.Content>
    </S.Container>
  );
};

export default MainSection;
