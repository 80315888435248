import React from 'react';

import { EHeadingSize } from '@domain/enums/components/EHeading';

import * as S from './styles';

const Header: React.FC = () => {
  return (
    <S.HeaderWrapper>
      <S.Title type={EHeadingSize.H3}>Canal de Ideias</S.Title>
    </S.HeaderWrapper>
  );
};

export default Header;
