import React from 'react';

import { EBadgeColors } from '@domain/enums/components/EBadge';
import { IMemberOptionProps } from '@domain/interfaces/downgrade/IDowngrade';

import { useDowngrade } from '@helpers/hooks/common/store/subscription/useDowngrade';

import * as S from './styles';

const MemberOption: React.FC<IMemberOptionProps> = ({ userStore }) => {
  const { selectedInvitedMembers, handleSelectedInvitedMembers } = useDowngrade();

  const onChange = React.useCallback(
    event => {
      if (event.target) return;

      const invitedMember = event.value;

      const foundInvitedMember = selectedInvitedMembers.find(
        member => member.user.alias_id === invitedMember,
      );

      if (foundInvitedMember) {
        const filteredInvitedMembers = selectedInvitedMembers.filter(
          member => member.user.alias_id !== invitedMember,
        );
        handleSelectedInvitedMembers(filteredInvitedMembers);
      } else {
        handleSelectedInvitedMembers([...selectedInvitedMembers, userStore]);
      }
    },
    [selectedInvitedMembers, handleSelectedInvitedMembers, userStore],
  );

  const foundInvitedMember = selectedInvitedMembers.find(
    member => member.user.alias_id === userStore.user.alias_id,
  );

  return (
    <S.MemberOptionGroup>
      <S.Option htmlFor={`option-${userStore.user.alias_id}`}>
        <S.MemberName>{`${userStore.user.first_name} ${userStore.user.last_name}`}</S.MemberName>
        <S.UserGroup color={EBadgeColors.PRIMARY}>{userStore.group.name}</S.UserGroup>
      </S.Option>
      <S.Checkbox
        id={`option-${userStore.user.alias_id}`}
        value={userStore.user.alias_id}
        onChange={onChange}
        checked={Boolean(foundInvitedMember)}
      />
    </S.MemberOptionGroup>
  );
};

export default MemberOption;
