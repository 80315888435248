export enum EBadgeColors {
  GREEN = 'green',
  RED = 'red',
  YELLOW = 'yellow',
  GRAY = 'gray',
  PURPLE = 'purple',
  BLUE = 'blue',
  ORANGE = 'orange',
  PINK = 'pink',
  SKY_BLUE = 'sky_blue',
  MAROON = 'maroon',
  PRIMARY = 'primary',
  SUCCESS = 'success',
}
