import { AxiosResponse } from 'axios';

import {
  IGenerateTrayUrlProps,
  IGenerateTrayUrlResponse,
  IDisableTrayCredentialProps,
  IEnableTrayCredentialProps,
  IGetTrayCredentialPromiseProps,
  IGetTrayCredentialPromiseResponse,
  IGetTrayCredentialProps,
  IGetTrayCredentialResponse,
} from '@domain/interfaces/dashboard/IntegrationCenter/Ecommerce/Tray/ITrayAuth';

import { ApiService } from '@services/api';

import { useFetch } from '@providers/Fetch/useFetch';

import { API_DOMAIN } from '@constants/api';

export class TrayAuthService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getTrayCredential({ storeAliasId }: IGetTrayCredentialProps): IGetTrayCredentialResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/tray-credentials`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      trayCredential: data?.tray_credential,
      isLoading,
      isValidating,
      error,
      mutate,
    };
  }

  public getTrayCredentialPromise({
    storeAliasId,
  }: IGetTrayCredentialPromiseProps): Promise<AxiosResponse<IGetTrayCredentialPromiseResponse>> {
    return this.apiService.get(`/api/v1/users/stores/${storeAliasId}/tray-credentials`);
  }

  public generateTrayUrl({
    storeAliasId,
    data,
  }: IGenerateTrayUrlProps): Promise<AxiosResponse<IGenerateTrayUrlResponse>> {
    return this.apiService.post<IGenerateTrayUrlResponse>(
      `/api/v1/users/stores/${storeAliasId}/tray-credentials`,
      { ...data },
    );
  }

  public disableTrayCredential({
    storeAliasId,
    trayCredentialAliasId,
  }: IDisableTrayCredentialProps): Promise<AxiosResponse<void>> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/tray-credentials/${trayCredentialAliasId}`,
      { is_active: false },
    );
  }

  public enableTrayCredential({
    storeAliasId,
    trayCredentialAliasId,
  }: IEnableTrayCredentialProps): Promise<AxiosResponse<void>> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/tray-credentials/${trayCredentialAliasId}`,
      { is_active: true },
    );
  }
}

const trayAuthService = new TrayAuthService(API_DOMAIN);

export default trayAuthService;
