import React from 'react';

import { FeedProvider } from '@helpers/hooks/pages/dashboard/feed/useFeed';
import Wrapper from '@components/Dashboard/Feed/Wrapper';

const Feed: React.FC = () => {
  return (
    <FeedProvider>
      <Wrapper />
    </FeedProvider>
  );
};

export default Feed;
