import React from 'react';

import { useFilter } from '@helpers/hooks/useFilter';
import SelectedFilter from '../SelectedFilter';
import AddFilter from '../AddFilter';
import ClearFilter from '../ClearFilter';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const { data } = useFilter();

  return (
    <S.Wrapper>
      {data.map(selectedFilter => (
        <SelectedFilter selectedFilter={selectedFilter} key={selectedFilter.field} />
      ))}
      <AddFilter />
      <ClearFilter />
    </S.Wrapper>
  );
};

export default Wrapper;
