import React from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import { ADS_PROVIDERS } from '@constants/common/integrations';

import { EAdSenseProvider } from '@domain/enums/common/EAdSense';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useStoreIntegrations } from '@helpers/hooks/pages/dashboard/integrationCenter/useStoreIntegrations';
import { useStorePermission } from '@helpers/hooks/common/useStorePermission';

import { getAdsProviderIntegrationStatus } from '@helpers/utils/dashboard/integrationsCenter/adsProviders';

import Text from '@components/common/core/DataDisplay/Text';
import IntegrationCard from '@components/Dashboard/IntegrationCenter/IntegrationCard';
import LimitationModal from '@components/common/core/Utils/LimitationModal';
import SkeletonLoadingCard from '../../SkeletonLoadingCard';

import * as S from './styles';

const AdsProvider: React.FC = () => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { searchIntegrationsValue } = useStoreIntegrations();
  const { storeIntegrations, isLoadingStore, isValidatingStore } = useStoreConfig();
  const { maxMarketingIntegrations } = useStorePermission();

  const [isLimitationModalOpen, setIsLimitationModalOpen] = React.useState<boolean>(false);

  const randomArray = Array.from(Array(5).keys());

  const handleLimitationModalOpen = React.useCallback(() => {
    setIsLimitationModalOpen(state => !state);
  }, []);

  const onCardClick = React.useCallback(
    (identifier: EAdSenseProvider) => {
      if (!storeIntegrations) return;

      const { marketing } = storeIntegrations;

      const activeIntegrations = marketing.filter(integration => integration.is_active);

      const hasCurrentIntegrationActive = activeIntegrations.find(integration =>
        integration.name.toLocaleLowerCase().includes(identifier.toLocaleLowerCase()),
      );

      if (
        !hasCurrentIntegrationActive &&
        activeIntegrations.length >= maxMarketingIntegrations &&
        maxMarketingIntegrations !== -1
      ) {
        handleLimitationModalOpen();
      } else {
        history.push(`/${storeAliasId}/dashboard/data/integrations/${identifier.toLowerCase()}`);
      }
    },
    [history, storeAliasId, maxMarketingIntegrations, storeIntegrations, handleLimitationModalOpen],
  );

  if (isLoadingStore || isValidatingStore) {
    return (
      <S.MarketingWrapper>
        <S.Heading>Marketing</S.Heading>

        <S.CardsWrapper>
          {randomArray.map(element => (
            <SkeletonLoadingCard key={element} />
          ))}
        </S.CardsWrapper>
      </S.MarketingWrapper>
    );
  }

  const filteredMarketingIntegrations = ADS_PROVIDERS.filter(integration => {
    const integrationName = integration.name.toLowerCase();
    const searchIntegration = searchIntegrationsValue.toLowerCase();
    const isIntegrationIncludedOnSearch = integrationName.includes(searchIntegration);

    if (!isIntegrationIncludedOnSearch) return false;

    return true;
  });

  return (
    <S.MarketingWrapper>
      <S.Heading>Marketing</S.Heading>

      <S.CardsWrapper>
        {filteredMarketingIntegrations.map(filteredIntegration => {
          const isActivated = getAdsProviderIntegrationStatus(
            storeIntegrations,
            filteredIntegration.identifier,
          );

          return (
            <IntegrationCard
              icon={filteredIntegration.icon}
              isActivated={isActivated}
              title={filteredIntegration.name}
              key={filteredIntegration.id}
              onClick={() => onCardClick(filteredIntegration.identifier)}
            />
          );
        })}

        {!filteredMarketingIntegrations.length && (
          <Text>Não encontramos nenhuma integração que contenha esse nome.</Text>
        )}
      </S.CardsWrapper>

      <LimitationModal
        isOpen={isLimitationModalOpen}
        toggle={handleLimitationModalOpen}
        imageSrc="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/temp/limitation-temp.svg"
        title="Limite de fontes de tráfego atingido"
        description="Escolha um plano que te permita adicionar mais fontes de tráfego para que consiga usufruir o melhor da ferramenta."
        trackingPropertyName="MAX_MARKETING_INTEGRATION_REACHED"
      />
    </S.MarketingWrapper>
  );
};

export default AdsProvider;
