import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  padding-bottom: 8px;
  justify-content: space-between;
  gap: 26px;

  &:active {
    cursor: grabbing;
  }

  &::-webkit-scrollbar {
    height: 2px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.background[3]};
    border-radius: 25px;
  }
`;
