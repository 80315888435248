import React from 'react';
import { SimpleCheck } from '@profitfy/pakkun-icons';
import { useParams, useHistory } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import { useCheckout } from '@helpers/hooks/pages/subscription/useCheckout';

import * as S from './styles';

const Stepper: React.FC = () => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { isAddressStep, isPaymentStep, isPersonalInfoStep, isOverviewStep } = useCheckout();

  const hasPassedAddressStep = !isAddressStep;
  const hasPassedPersonalInfoStep = isPaymentStep || isOverviewStep;
  const hasPassedPaymentStep = isOverviewStep;

  const handleAddressButtonClick = React.useCallback(() => {
    if (!hasPassedAddressStep) return;

    history.push(`/${storeAliasId}/subscription/address${window.location.search}`);
  }, [hasPassedAddressStep, storeAliasId, history]);

  const handlePersonalInfoButtonClick = React.useCallback(() => {
    if (!hasPassedPersonalInfoStep) return;

    history.push(`/${storeAliasId}/subscription/personal-info${window.location.search}`);
  }, [hasPassedPersonalInfoStep, history, storeAliasId]);

  const handlePaymentButtonClick = React.useCallback(() => {
    if (!hasPassedPaymentStep) return;

    history.push(`/${storeAliasId}/subscription/payment${window.location.search}`);
  }, [hasPassedPaymentStep, storeAliasId, history]);

  return (
    <S.Wrapper>
      <S.Step hasPassedStep={hasPassedAddressStep} onClick={handleAddressButtonClick}>
        <S.StepLabel>Endereço</S.StepLabel>
        <S.StepDecorator hasPassedStep={hasPassedAddressStep} isCurrentStep={isAddressStep}>
          {hasPassedAddressStep && <SimpleCheck size={16} color="#000" />}
        </S.StepDecorator>
      </S.Step>

      <S.Step hasPassedStep={hasPassedPersonalInfoStep} onClick={handlePersonalInfoButtonClick}>
        <S.StepLabel>Dados Pessoais</S.StepLabel>
        <S.StepDecorator
          hasPassedStep={hasPassedPersonalInfoStep}
          isCurrentStep={isPersonalInfoStep}
        >
          {hasPassedPersonalInfoStep && <SimpleCheck size={16} color="#000" />}
        </S.StepDecorator>
      </S.Step>

      <S.Step hasPassedStep={hasPassedPaymentStep} onClick={handlePaymentButtonClick}>
        <S.StepLabel>Pagamento</S.StepLabel>
        <S.StepDecorator hasPassedStep={hasPassedPaymentStep} isCurrentStep={isPaymentStep}>
          {hasPassedPaymentStep && <SimpleCheck size={16} color="#000" />}
        </S.StepDecorator>
      </S.Step>
    </S.Wrapper>
  );
};

export default Stepper;
