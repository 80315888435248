import React from 'react';
import { utcToZonedTime as fnsUtcToZonedTime } from 'date-fns-tz';
import {
  format as fnsFormat,
  subDays as fnsSubDays,
  addDays as fnsAddDays,
  differenceInSeconds as fnsDifferenceInSeconds,
  differenceInDays as fnsDifferenceInDays,
  isBefore as fnsIsBefore,
  isAfter as fnsIsAfter,
  isThisWeek as fnsIsThisWeek,
} from 'date-fns';

import { IDateProvider } from '@domain/interfaces/hooks/IDateProvider';

const DateContext = React.createContext<IDateProvider | null>(null);

export const DateProvider: React.FC = ({ children }) => {
  const [storeTimezone, setStoreTimezone] = React.useState<string>('');

  const handleTimezone = React.useCallback(
    userStoreTimezone => setStoreTimezone(userStoreTimezone),
    [],
  );

  const isThisWeek = React.useCallback((date: Date): boolean => {
    const isDateThisWeek = fnsIsThisWeek(date);

    return isDateThisWeek;
  }, []);

  const utcToZonedTime = React.useCallback(
    (date: string | number | Date, timezone?: string) => {
      const zonedDate = fnsUtcToZonedTime(new Date(date), timezone || storeTimezone);

      return zonedDate;
    },
    [storeTimezone],
  );

  const format = React.useCallback((date: Date, dateFormat: string) => {
    // const timezonedDate = utcToZonedDate(date.toUTCString());

    const formattedDate = fnsFormat(date, dateFormat);

    return formattedDate;
  }, []);

  const subDays = React.useCallback((date: Date, daysToSub: number) => {
    // const timezonedDate = utcToZonedDate(date);
    const subbedDate = fnsSubDays(date, daysToSub);

    return subbedDate;
  }, []);

  const addDays = React.useCallback((date: Date, daysToAdd: number) => {
    // const timezonedDate = utcToZonedDate(date);
    const addedDate = fnsAddDays(date, daysToAdd);

    return addedDate;
  }, []);

  const differenceInSeconds = React.useCallback((firstDate: Date, lastDate: Date) => {
    const difference = fnsDifferenceInSeconds(lastDate, firstDate);

    return difference;
  }, []);

  const differenceInDays = React.useCallback((firstDate: Date, lastDate: Date) => {
    const difference = fnsDifferenceInDays(lastDate, firstDate);

    return difference;
  }, []);

  const isBefore = React.useCallback((date: Date | number, dateToCompare: Date | number) => {
    const isDateBefore = fnsIsBefore(date, dateToCompare);

    return isDateBefore;
  }, []);

  const isAfter = React.useCallback((date: Date | number, dateToCompare: Date | number) => {
    const isDateAfter = fnsIsAfter(date, dateToCompare);

    return isDateAfter;
  }, []);

  return (
    <DateContext.Provider
      value={{
        addDays,
        format,
        handleTimezone,
        subDays,
        utcToZonedTime,
        differenceInSeconds,
        differenceInDays,
        storeTimezone,
        isBefore,
        isAfter,
        isThisWeek,
      }}
    >
      {children}
    </DateContext.Provider>
  );
};

export const useDate = (): IDateProvider => {
  const context = React.useContext(DateContext);

  if (!context) {
    throw new Error('useDate must be used within FilterProvider');
  }

  return context;
};
