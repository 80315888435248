import React from 'react';

import { IMissingCredentialModalProps } from '@domain/interfaces/dashboard/Automations/SideModal/Triggers/IMissingCredential';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';

import Yampi from './Yampi';
import CartPanda from './CartPanda';
import Zouti from './Zouti';
import Hotmart from './Hotmart';

import * as S from './styles';

const MissingCredential: React.FC<IMissingCredentialModalProps> = ({ isOpen, toggle }) => {
  const { selectedIntegration } = useAutomation();

  return (
    <S.Wrapper isOpen={isOpen} toggle={toggle}>
      {selectedIntegration && selectedIntegration.id === 'YAMPI' && <Yampi toggle={toggle} />}

      {selectedIntegration && selectedIntegration.id === 'CART_PANDA' && (
        <CartPanda toggle={toggle} />
      )}

      {selectedIntegration && selectedIntegration.id === 'ZOUTI' && <Zouti toggle={toggle} />}

      {selectedIntegration && selectedIntegration.id === 'HOTMART' && <Hotmart toggle={toggle} />}
    </S.Wrapper>
  );
};

export default MissingCredential;
