import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { useLocalStorage } from '@helpers/hooks/useLocalStorage';

import * as S from './styles';

const VariantsSkeletonLoading: React.FC = () => {
  const [userTheme] = useLocalStorage('user-theme', 'dark');

  return (
    <SkeletonTheme
      color={userTheme === 'dark' ? '#252931' : '#E8E9ED'}
      highlightColor={userTheme === 'dark' ? '#303440' : '#F0F1F4'}
    >
      <S.Wrapper>
        <S.VariantWrapper>
          <S.ImageWrapper>
            <Skeleton width="100%" height={48} />
          </S.ImageWrapper>

          <S.ContentWrapper>
            <S.VariantNameWrapper>
              <Skeleton width="100%" height={24} />
            </S.VariantNameWrapper>
            <S.VariantSkuWrapper>
              <Skeleton width="100%" height={20} />
            </S.VariantSkuWrapper>
          </S.ContentWrapper>
        </S.VariantWrapper>

        <S.VariantWrapper>
          <S.ImageWrapper>
            <Skeleton width="100%" height={48} />
          </S.ImageWrapper>

          <S.ContentWrapper>
            <S.VariantNameWrapper>
              <Skeleton width="100%" height={24} />
            </S.VariantNameWrapper>
            <S.VariantSkuWrapper>
              <Skeleton width="100%" height={20} />
            </S.VariantSkuWrapper>
          </S.ContentWrapper>
        </S.VariantWrapper>

        <S.VariantWrapper>
          <S.ImageWrapper>
            <Skeleton width="100%" height={48} />
          </S.ImageWrapper>

          <S.ContentWrapper>
            <S.VariantNameWrapper>
              <Skeleton width="100%" height={24} />
            </S.VariantNameWrapper>
            <S.VariantSkuWrapper>
              <Skeleton width="100%" height={20} />
            </S.VariantSkuWrapper>
          </S.ContentWrapper>
        </S.VariantWrapper>
      </S.Wrapper>
    </SkeletonTheme>
  );
};

export default VariantsSkeletonLoading;
