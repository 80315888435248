import styled from 'styled-components/macro';

import BackgroundImg from '@assets/pages/SignIn/background-img.svg';

import Details1 from '@assets/pages/SignUp/details1.svg';
import Details2 from '@assets/pages/SignUp/details2.svg';
import Details3 from '@assets/pages/SignUp/details3.svg';
import Details5 from '@assets/pages/SignUp/details5.svg';

export const Container = styled.div`
  width: 100vw;
  display: flex;
`;

export const LeftSide = styled.div`
  position: relative;
  width: 70vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 20px;
  background: ${({ theme }) => theme.gradient.grayDark};

  &::before {
    content: '';
    position: absolute;
    top: 33px;
    left: 26px;
    width: 28px;
    height: 30px;
    background-image: url(${Details2});
    background-repeat: no-repeat;

    @media only screen and (max-width: 620px) {
      display: none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 58px;
    right: 53px;
    width: 16px;
    height: 16px;
    background-image: url(${Details3});
    background-repeat: no-repeat;

    @media only screen and (max-width: 620px) {
      display: none;
    }
  }

  @media only screen and (max-width: 1080px) {
    width: 100vw;
  }
`;

export const RightSide = styled.div`
  position: relative;
  width: 30vw;
  min-width: 573px;
  background-color: #000;
  background-image: url(${BackgroundImg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right bottom;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    left: 64px;
    bottom: 661px;
    width: 161px;
    height: 22px;
    background-image: url(${Details5});
    background-repeat: no-repeat;
  }

  @media only screen and (max-width: 1080px) {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 442px;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;

  &::before {
    content: '';
    position: absolute;
    bottom: 54px;
    left: 32px;
    width: 18px;
    height: 142px;
    background-image: url(${Details1});
    background-repeat: no-repeat;

    @media only screen and (max-width: 620px) {
      display: none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 48px;
    right: 34px;
    width: 161px;
    height: 22px;
    background-image: url(${Details5});
    background-repeat: no-repeat;

    @media only screen and (max-width: 1410px) {
      display: none;
    }

    @media only screen and (max-width: 1080px) {
      display: block;
    }

    @media only screen and (max-width: 620px) {
      display: none;
    }
  }
`;
