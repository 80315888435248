import styled from 'styled-components/macro';
import * as Accordion from '@radix-ui/react-accordion';
import { ChevronDown } from '@profitfy/pakkun-icons';

export const Chevron = styled(ChevronDown)`
  &[data-state='open'] {
    transform: rotate(180deg);
  }
`;

export const Trigger = styled(Accordion.Trigger)`
  all: unset;
  padding: 0 32px;
  height: 45px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.font};
  cursor: pointer;
`;

export const Wrapper = styled(Accordion.AccordionHeader)`
  all: unset;
  display: flex;
`;
