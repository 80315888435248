import React from 'react';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { Tray as TrayIcon } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { newWindow } from '@helpers/hooks/usePopup';

import { traySchema } from '@helpers/validators/dashboard/integrationCenter/tray';

import trayAuthService from '@services/pages/dashboard/integrationCenter/ecommerce/tray/trayAuth';
import ActiveIntegrationFooter from '@components/Dashboard/IntegrationCenter/Sidemodal/ActiveIntegrationFooter';

import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const Tray: React.FC = () => {
  const theme: any = useTheme();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { mutateStore } = useStoreConfig();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(traySchema) });

  const [isIntegrating, setIsIntegrating] = React.useState<boolean>(false);
  const [userWindow, setUserWindow] = React.useState<Window | undefined>(undefined);

  const domainRegister = register('domain');

  // const onIntegrateClick = React.useCallback(async () => {
  //   setIsIntegrating(true);

  //   try {
  //     const { data: trayData } = await trayAuthService.generateTrayUrl({ storeAliasId });

  //     const window = newWindow(trayData.auth_url, 'Tray', 1280, 720);

  //     setUserWindow(window);
  //   } catch (error: any) {
  //     toast.error(error?.response?.data?.message);
  //   } finally {
  //     setIsIntegrating(false);
  //   }
  // }, [storeAliasId, toast]);

  const onSubmit = React.useCallback(
    async formData => {
      const includeProtocol =
        formData.domain.includes('https://') || formData.domain.includes('http://');
      const lastDomainCharacter = formData.domain.slice(-1);

      let { domain } = formData;

      if (!includeProtocol) {
        domain = `https://${domain}`;
      }

      if (lastDomainCharacter === '/') {
        domain = domain.slice(0, -1);
      }

      domain = domain.replace('https://', '');

      setIsIntegrating(true);

      try {
        const { data: trayData } = await trayAuthService.generateTrayUrl({
          storeAliasId,
          data: { base_url: domain },
        });

        const window = newWindow(trayData.auth_url, 'Tray', 1280, 720);

        setUserWindow(window);
        setIsIntegrating(false);
      } catch (error: any) {
        setIsIntegrating(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [storeAliasId, toast],
  );

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (userWindow?.closed) {
        clearInterval(interval);
        mutateStore();
      }
    }, 1000);
  }, [userWindow, mutateStore]);

  return (
    <S.ContentWrapper>
      <S.Header>
        <TrayIcon size={52} color={theme.colors.gray[2]} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com Tray
        </S.Heading>
      </S.Header>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>
        <S.FunctionalityDescriptionText>
          A integração com sua plataforma de e-commerce serve para puxar seus dados de loja.
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <S.TutorialWrapper>
        <S.TutorialHeading type={EHeadingSize.H5}>Tutorial de integração</S.TutorialHeading>
        <S.TutorialText>
          Basta informar o domínio da loja Tray e depois clicar no botão abaixo e integrar com a
          plataforma.
        </S.TutorialText>
      </S.TutorialWrapper>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label>Domínio</S.Label>
          <S.TextField
            {...domainRegister}
            type="text"
            id="tray-store-url"
            placeholder="https://www.exemplo.com.br"
          />
          {errors.domain && <Text isErrorFeedback>{errors?.domain?.message}</Text>}
        </S.InputGroup>
        <ActiveIntegrationFooter isIntegrating={isIntegrating} />
      </Form>
    </S.ContentWrapper>
  );
};

export default Tray;
