import styled, { css } from 'styled-components';

import { IDefaultGroupProps } from '@domain/interfaces/dashboard/AccessManagement/ISelectGroup';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import RadioBase from '@components/common/core/Inputs/Radio';
import BadgeBase from '@components/common/core/DataDisplay/Badge';

export const Radio = styled(RadioBase)``;

export const Badge = styled(BadgeBase)``;

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 120px;
  align-self: flex-start;

  @media only screen and (max-width: 1345px) {
    padding-top: 60px;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 402px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
`;

export const Heading = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.white.default};
  line-height: 100%;
  margin-bottom: 16px;
`;

export const Description = styled(TextBase)`
  color: ${({ theme }) => theme.colors.white.default};
  margin-bottom: 24px;
`;

export const DefaultGroup = styled.div<IDefaultGroupProps>`
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 8px;
  padding: 20px 24px 0 24px;
  box-sizing: border-box;

  ${({ isPermissionsVisible }) =>
    isPermissionsVisible &&
    css`
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
      height: unset;
    `}
`;

export const DefaultGroupContent = styled.div`
  width: 100%;
  height: 28px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;

export const HorizontalRule = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray[4]};
`;

export const DefaultGroupText = styled(TextBase)`
  color: #fff;
`;

export const TextAndBadgeWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-left: 24px;
  border: unset;
  background: unset;
  cursor: pointer;
`;

export const ChevronIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
`;

export const GroupAndPermissionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const PermissionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0px 6px 18px 1px rgba(0, 0, 0, 0.06);
  padding: 16px 20px;
  padding-left: 74px;
  box-sizing: border-box;
`;

export const Permission = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
  line-height: 150%;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const CreateNewAccessGroupButton = styled.button`
  width: 100%;
  max-width: 195px;
  height: 36px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;
