import { WarningOctagon } from 'phosphor-react';
import React from 'react';
import { useTheme } from 'styled-components';
import * as S from './styles';

interface IDeleteCredentialModalProps {
  isOpen: boolean;
  toggle: () => void;
  onDelete: () => void;
}
const ConfirmDeleteModal: React.FC<IDeleteCredentialModalProps> = ({
  isOpen,
  toggle,
  onDelete,
}) => {
  const { colors } = useTheme();

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.QuestionWrapper>
        <WarningOctagon size={24} color={colors.danger.default} />

        <S.Question>Deseja excluir esta loja?</S.Question>
      </S.QuestionWrapper>

      <S.Text>
        Ao realizar a exclusão, todos os dados vinculados a essa loja serão removidos do nosso
        sistema.<S.TextHighlight> Esta ação é irreversível</S.TextHighlight>, sendo necessário criar
        uma nova loja caso deseje continuar.
      </S.Text>

      <S.ButtonsWrapper>
        <S.CancelButton onClick={toggle}>Cancelar</S.CancelButton>
        <S.ConfirmButton onClick={onDelete}>Excluir Loja</S.ConfirmButton>
      </S.ButtonsWrapper>
    </S.Modal>
  );
};

export default ConfirmDeleteModal;
