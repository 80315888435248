export enum EShippingType {
  INTEGRATION = 'INTEGRATION',
  PAID_BY_CUSTOMER = 'PAID_BY_CUSTOMER',
  AVERAGE_BY_ORDER = 'AVERAGE_BY_ORDER',
}

export enum EShippingAmountType {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE',
}
