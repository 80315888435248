/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-duplicates */
import React from 'react';
import pt from 'date-fns/locale/pt-BR';
import { formatDistanceToNow } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { INotificationRowProps } from '@domain/interfaces/components/INavBarNotification';

import { useDate } from '@helpers/hooks/useDate';
import { useNotification } from '@helpers/hooks/common/store/notification/useNotification';

import { getNotificationType } from '@helpers/utils/common/notification/type';

import * as S from './styles';

const NotificationRow: React.FC<INotificationRowProps> = ({ notification }) => {
  const history = useHistory();
  const { utcToZonedTime } = useDate();
  const { markNotificationAsRead, hasMarkedAllNotificationsAsRead } = useNotification();
  const { payload, createdAt, read } = notification;

  const [hasMarkedAsRead, setHasMarkedAsRead] = React.useState<boolean>(read);

  const onNotificationClick = React.useCallback(() => {
    markNotificationAsRead(notification._id);
    setHasMarkedAsRead(true);

    if (payload?.internal_url) {
      history.push(`/${payload.store_alias_id}/${payload.internal_url}`);
    }

    if (payload?.external_url) {
      window.open(payload.external_url as string, '_blank');
    }
  }, [markNotificationAsRead, notification, history, payload]);

  React.useEffect(() => {
    if (hasMarkedAllNotificationsAsRead) {
      setHasMarkedAsRead(true);
    }
  }, [hasMarkedAllNotificationsAsRead]);

  const notificationType = payload?.type as string | undefined;
  const notificationTitle = (payload?.title as string) || 'No title';
  const notificationDescription = (payload?.data as string) || 'No description';
  const receivedAt = `Há ${formatDistanceToNow(utcToZonedTime(createdAt), {
    locale: pt,
  })} atrás`;

  const Icon = getNotificationType(notificationType);

  return (
    <S.Wrapper onClick={onNotificationClick}>
      <S.NotificationIcon>
        <Icon />
      </S.NotificationIcon>

      <S.NotificationContent>
        <S.Title>{notificationTitle}</S.Title>
        <S.Description>{notificationDescription}</S.Description>
        <S.ReceivedAt>{receivedAt}</S.ReceivedAt>
      </S.NotificationContent>

      {!hasMarkedAsRead && <S.NewNotificationIcon />}
    </S.Wrapper>
  );
};

export default NotificationRow;
