import React from 'react';

import { useReactFlow } from 'react-flow-renderer';
import { useTheme } from 'styled-components';
import { v4 } from 'uuid';

import { ENodes } from '@domain/enums/dashboard/automations/node/ENodes';
import { ITriggerCardProps } from '@domain/interfaces/dashboard/Automations/Canvas/SideModal/Trigger/ITriggerCard';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';
import { useCanvasMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessageSettings';
import { useConfig } from '@helpers/hooks/useConfig';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { EMessageType } from '@domain/enums/dashboard/automations/EMessage';

import * as S from './styles';

const TriggerCard: React.FC<ITriggerCardProps> = ({ title, description, icon: Icon, type }) => {
  const theme = useTheme();

  const { storeIntegrations } = useStoreConfig();
  const { analytics, selectedStore } = useConfig();

  const {
    setShowSideModal,
    handleSelectWorkflowTriggerType,
    selectedIntegration,
    toggleShowRequestIntegrationByTrigger,
    handleSelectTriggerForMissingCredential,
  } = useAutomation();
  const { handleSelectedMessageTrigger } = useCanvasMessageSettings();

  const reactFlowInstance = useReactFlow();

  const onClick = React.useCallback(() => {
    handleSelectTriggerForMissingCredential(type);

    const foundCredentialProvider = storeIntegrations.workflow.find(
      workflow_integration => workflow_integration.name === selectedIntegration?.id,
    );

    if (!foundCredentialProvider?.is_active) {
      toggleShowRequestIntegrationByTrigger();
      return undefined;
    }

    if (foundCredentialProvider?.name === 'ZOUTI') {
      const hasTypesBesidesStore = foundCredentialProvider.types.some(
        (zoutiTypes: any) => zoutiTypes !== 'GATEWAY',
      );

      if (!hasTypesBesidesStore) return toggleShowRequestIntegrationByTrigger();
    }

    const nodeId = v4();

    const newNode = {
      id: nodeId,
      position: { x: 574, y: 28 },
      data: { label: `Node ${nodeId}`, name: v4() },
      type,
    };

    const endNodeId = v4();

    const newNodeEnd = {
      id: endNodeId,
      position: { x: 574, y: 428 },
      data: { label: `Node ${endNodeId}`, name: v4() },
      type: ENodes.END,
    };

    reactFlowInstance.addNodes([newNode, newNodeEnd]);
    setShowSideModal(false);
    handleSelectWorkflowTriggerType(type);
    handleSelectedMessageTrigger((type as unknown) as EMessageType);

    analytics?.track(
      'Automation Tool Trigger Selected',
      { click_source: title },
      { context: { groupId: selectedStore?.alias_id } },
    );

    return undefined;
  }, [
    selectedIntegration,
    storeIntegrations,
    toggleShowRequestIntegrationByTrigger,
    reactFlowInstance,
    setShowSideModal,
    handleSelectWorkflowTriggerType,
    type,
    analytics,
    selectedStore,
    title,
    handleSelectedMessageTrigger,
    handleSelectTriggerForMissingCredential,
  ]);

  return (
    <S.Wrapper onClick={onClick}>
      <S.Icon>
        <Icon size={32} color={theme.colors.green.default} />
      </S.Icon>

      <S.MessageContainer>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
      </S.MessageContainer>
    </S.Wrapper>
  );
};

export default TriggerCard;
