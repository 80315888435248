import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

export const InfiniteScrollLoading: React.FC = () => {
  return (
    <>
      <S.Wrapper>
        <S.OrderNumberWrapper>
          <Skeleton width="100%" height={27} />
        </S.OrderNumberWrapper>

        <S.FirstOrderItemWrapper>
          <Skeleton width="100%" height={24} />
        </S.FirstOrderItemWrapper>

        <S.OrderDateWrapper>
          <Skeleton width="100%" height={24} />
        </S.OrderDateWrapper>
      </S.Wrapper>

      <S.Wrapper>
        <S.OrderNumberWrapper>
          <Skeleton width="100%" height={27} />
        </S.OrderNumberWrapper>

        <S.FirstOrderItemWrapper>
          <Skeleton width="100%" height={24} />
        </S.FirstOrderItemWrapper>

        <S.OrderDateWrapper>
          <Skeleton width="100%" height={24} />
        </S.OrderDateWrapper>
      </S.Wrapper>

      <S.Wrapper>
        <S.OrderNumberWrapper>
          <Skeleton width="100%" height={27} />
        </S.OrderNumberWrapper>

        <S.FirstOrderItemWrapper>
          <Skeleton width="100%" height={24} />
        </S.FirstOrderItemWrapper>

        <S.OrderDateWrapper>
          <Skeleton width="100%" height={24} />
        </S.OrderDateWrapper>
      </S.Wrapper>
    </>
  );
};

export default InfiniteScrollLoading;
