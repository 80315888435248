import React from 'react';

import { ProductCost } from '@profitfy/pakkun-icons';
import { Truck } from 'phosphor-react';
import { useTheme } from 'styled-components';
import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { numberFormatter } from '@helpers/masks';
import { ETextWeight } from '@domain/enums/components/EText';

import * as S from './styles';

const HoverCard: React.FC = ({ children }) => {
  const { financialReport } = useFinancialDashboard();

  const theme = useTheme();

  const onHoverCardClick = React.useCallback(event => {
    event.stopPropagation();
  }, []);

  const { cogs } = financialReport;

  return (
    <S.HoverCard>
      <S.HoverCardTrigger>{children}</S.HoverCardTrigger>

      <S.HoverCardContent onClick={onHoverCardClick}>
        <S.Row>
          <S.IconAndNameWrapper>
            <ProductCost size={20} />
            <S.Name>Custo dos produtos</S.Name>
          </S.IconAndNameWrapper>

          <S.Value weight={ETextWeight.MEDIUM}>{`R$ ${numberFormatter(
            cogs.product_cost_amount,
            2,
          )}`}</S.Value>
        </S.Row>

        <S.Row>
          <S.IconAndNameWrapper>
            <Truck size={20} color={theme.colors.green.default} />
            <S.Name>Frete</S.Name>
          </S.IconAndNameWrapper>

          <S.Value weight={ETextWeight.MEDIUM}>{`R$ ${numberFormatter(
            cogs.shipping_cost_owner_amount,
            2,
          )}`}</S.Value>
        </S.Row>
        <S.HoverCardArrow />
      </S.HoverCardContent>
    </S.HoverCard>
  );
};

export default HoverCard;
