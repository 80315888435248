import styled, { css, DefaultTheme } from 'styled-components/macro';

import { IOptionProps } from '@domain/interfaces/dashboard/Automations/Canvas/TaskMenu/ITaskMenu';

const wrapperModifiers = {
  danger: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.danger.default};
  `,
  default: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.background[2]};
  `,
};

export const Wrapper = styled.div<Pick<IOptionProps, 'variant'>>`
  ${({ theme, variant }) => css`
    width: 100%;
    height: 32px;
    padding: 0 12px;
    border-radius: 5px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    font-weight: 500;
    /* font-family: 'Poppins'; */
    font-family: 'Mukta';
    color: ${theme.colors.gray[1]};
    cursor: grab;
    box-sizing: border-box;

    ${!!variant && wrapperModifiers[variant](theme)}
  `}
`;

export const Group = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  color: ${({ theme }) => theme.colors.gray[1]};
`;
