import React from 'react';

import { useStoreIntegrations } from '@helpers/hooks/pages/dashboard/integrationCenter/useStoreIntegrations';

import Header from './Header';
import InfoProductProvider from './InfoProductProvider';
import AdsProvider from './AdsProvider';
import StoreIntegrations from './StoreIntegrations';

import * as S from './styles';

const InfoProductWrapper: React.FC = () => {
  const { selectedTab } = useStoreIntegrations();

  const isPlatformSelected = selectedTab === 0 || selectedTab === 1;
  const isMarketingSelected = selectedTab === 0 || selectedTab === 2;

  return (
    <S.Container>
      <Header />

      {selectedTab === 0 && <StoreIntegrations />}

      {isPlatformSelected && <InfoProductProvider />}

      {isMarketingSelected && <AdsProvider />}
    </S.Container>
  );
};

export default InfoProductWrapper;
