import React from 'react';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import { useAccessManager } from '@helpers/hooks/pages/dashboard/accessManagement/useAccessManager';
import { useConfig } from '@helpers/hooks/useConfig';

import PageError from '@components/common/core/Utils/PageError';
import Welcome from '../Welcome';
import Tables from '../Tables';
import SkeletonLoading from '../SkeletonLoading';

const Wrapper: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const {
    groups,
    userInvites,
    isLoadingAccessManagement,
    isValidatingAccessManagement,
    isAccessManagementError,
    users,
    mutate,
  } = useAccessManager();
  const { user, analytics } = useConfig();

  React.useEffect(() => {
    analytics?.track(
      'Invite Members Screen Viewed',
      {
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );
  }, [analytics, user, storeAliasId]);

  if (isLoadingAccessManagement || isValidatingAccessManagement) {
    return <SkeletonLoading />;
  }

  if (isAccessManagementError) {
    return <PageError mutate={mutate} />;
  }

  const hasOneOrMoreGroupsOrUserInvites =
    groups.length > 1 || userInvites.length > 0 || users.length > 0;

  return <>{hasOneOrMoreGroupsOrUserInvites ? <Tables /> : <Welcome />}</>;
};

export default Wrapper;
