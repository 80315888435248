import React from 'react';
import { Tag } from 'phosphor-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components/macro';

import { ETextSize } from '@domain/enums/components/EText';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { INewTagSideModalProps } from '@domain/interfaces/dashboard/ProductAnalytics/INewTagSideModal';

import { useOnboardingTag } from '@helpers/hooks/pages/dashboard/productAnalytics/useOnboardingTag';

import { createProductTagSchema } from '@helpers/validators/dashboard/productTag/productTag';

import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';
import SideModalSave from '@components/common/core/Utils/SideModalSave';

import * as S from './styles';

const NewTagSideModal: React.FC<INewTagSideModalProps> = ({ isOpen, toggle }) => {
  const { createProductTag } = useOnboardingTag();
  const theme = useTheme();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(createProductTagSchema) });

  const [isCreatingProductTag, setIsCreatingProductTag] = React.useState<boolean>(false);
  const [tagValue, setTagValue] = React.useState<string>('');

  const tagInputRegister = register('new_tag');

  const onTagChange = React.useCallback(
    event => {
      tagInputRegister.onChange(event);

      setTagValue(event.target.value);
    },
    [tagInputRegister],
  );

  const onSubmit = React.useCallback(
    async formData => {
      setIsCreatingProductTag(true);

      await createProductTag(formData.new_tag, 'save_tag');

      setIsCreatingProductTag(false);
    },
    [createProductTag],
  );

  return (
    <S.SideModal isOpen={isOpen} toggle={toggle}>
      <S.ContentWrapper>
        <S.HeadingWrapper>
          <Tag size={28} color={theme.colors.green.default} />
          <S.Heading type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
            Adicionar identificador de sincronização
          </S.Heading>
        </S.HeadingWrapper>

        <S.Description>
          Para ativar suas campanhas mais facilmente, <strong>crie uma tag (identificador)</strong>{' '}
          com um nome em <strong>comum das suas campanhas</strong> deste produto.
        </S.Description>

        <S.Example size={ETextSize.B5}>
          <strong>Exemplo:</strong> [CONV][LAL]<strong>[PRODUTO X]</strong>, sua tag será:{' '}
          <strong>[PRODUTO X]</strong>
        </S.Example>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>Digite uma palavra chave que identifique suas campanhas deste produto</S.Label>
            <S.Input
              {...tagInputRegister}
              type="text"
              value={tagValue}
              placeholder="Ex. [produto x]"
              onChange={onTagChange}
            />
            {errors.new_tag && <Text isErrorFeedback>{errors.new_tag.message}</Text>}
          </S.InputGroup>

          <SideModalSave
            type="submit"
            successButtonText="Salvar Tag"
            cancelButtonText="Cancelar"
            cancelButtonTextColor="#fff"
            isLoading={isCreatingProductTag}
            isDisabled={isCreatingProductTag || !tagValue}
          />
        </Form>
      </S.ContentWrapper>
    </S.SideModal>
  );
};

export default NewTagSideModal;
