import React from 'react';
import {
  DeepMap,
  FieldError,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import { useSubscriptionData } from '@helpers/hooks/pages/dashboard/subscription/useSubscriptionData';

import { cnpjFormatter, phone } from '@helpers/masks';

import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

interface ILegalPersonFormProps {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
}

const LegalPersonForm: React.FC<ILegalPersonFormProps> = ({ register, errors, setValue }) => {
  const { legalPerson } = useSubscriptionData();

  const documentRegister = register('document');
  const nameRegister = register('name');
  const phoneNumberRegister = register('phone_number');

  const onDocumentChange = React.useCallback(
    event => {
      event.target.value = cnpjFormatter(event.target.value);
      documentRegister.onChange(event);
    },
    [documentRegister],
  );

  const onPhoneNumberChange = React.useCallback(
    event => {
      event.target.value = phone(event.target.value);
      phoneNumberRegister.onChange(event);
    },
    [phoneNumberRegister],
  );

  React.useEffect(() => {
    if (legalPerson) {
      setValue('document', cnpjFormatter(legalPerson.cnpj));
      setValue('name', legalPerson.company_name);
      setValue('phone_number', phone(`${legalPerson.phone_prefix}${legalPerson.phone_number}`));
    }
  }, [legalPerson, setValue]);

  const isDocumentError = Boolean(errors.document);
  const isNameError = Boolean(errors.name);
  const isPhoneNumberError = Boolean(errors.phone_number);

  return (
    <>
      <S.LegalDocumentAndPhoneWrapper>
        <S.InputGroup>
          <S.Label>CNPJ</S.Label>
          <S.Input
            {...documentRegister}
            type="text"
            placeholder="Digite o CNPJ"
            onChange={onDocumentChange}
            isError={isDocumentError}
          />
          {errors.document && <Text isErrorFeedback>{errors.document.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Celular</S.Label>
          <S.Input
            {...phoneNumberRegister}
            type="text"
            placeholder="Digite o celular"
            onChange={onPhoneNumberChange}
            isError={isPhoneNumberError}
          />
          {errors.phone_number && <Text isErrorFeedback>{errors.phone_number.message}</Text>}
        </S.InputGroup>
      </S.LegalDocumentAndPhoneWrapper>

      <S.InputGroup>
        <S.Label>Razão Social</S.Label>
        <S.Input
          {...nameRegister}
          type="text"
          placeholder="Digite a razão social"
          isError={isNameError}
        />
        {errors.name && <Text isErrorFeedback>{errors.name.message}</Text>}
      </S.InputGroup>
    </>
  );
};

export default LegalPersonForm;
