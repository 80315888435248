import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';

import { useSubscriptionData } from '@helpers/hooks/pages/dashboard/subscription/useSubscriptionData';

import CurrentPlan from './CurrentPlan';
import PaymentMethod from './PaymentMethod';
import BillingDetails from './BillingDetails';
import PaymentMethodSkeletonLoading from './PaymentMethod/SkeletonLoading';
import BillingDetailsSkeletonLoading from './BillingDetails/SkeletonLoading';

import * as S from './styles';

const Details: React.FC = () => {
  const { paymentMethod, naturalPerson, legalPerson, isLoadingData } = useSubscriptionData();

  return (
    <S.Wrapper>
      <S.Title type={EHeadingSize.H3} fontWeight={EHeadingWeight.MEDIUM}>
        Detalhes da assinatura
      </S.Title>

      <S.ContentWrapper>
        <CurrentPlan />

        {isLoadingData && (
          <>
            <PaymentMethodSkeletonLoading />
            <BillingDetailsSkeletonLoading />
          </>
        )}

        {Boolean(paymentMethod) && !isLoadingData && <PaymentMethod />}

        {Boolean(naturalPerson || legalPerson) && !isLoadingData && <BillingDetails />}
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default Details;
