export const phone = (value = ''): string => {
  let newValue = value;

  if (newValue.startsWith('55')) {
    newValue = newValue.slice(2);
  }

  return newValue
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1');
};

export const parseToRawPhone = (value = ''): string => {
  const newValue = `55${value}`;

  return newValue.replace(' ', '').replace(')', '').replace('(', '').replace('-', '');
};

export const parseToRawPhoneWithoutCountry = (value = ''): string => {
  const newValue = `${value}`;

  return newValue.replace(' ', '').replace(')', '').replace('(', '').replace('-', '');
};
