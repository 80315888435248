import styled from 'styled-components/macro';

import DropDownBase from '@components/common/core/Utils/DropDown';

export const DropDown = styled(DropDownBase)`
  & > div:first-child {
    border-bottom-color: ${({ theme }) => theme.colors.background[4]};
  }

  & > div:last-child {
    padding: 24px;
    background-color: ${({ theme }) => theme.colors.background[4]};

    @media only screen and (max-width: 388px) {
      padding: 12px;
      width: fit-content;
    }
  }
`;

export const DatePickerButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.2);
  }

  svg {
    margin-left: 12px;
  }
`;

export const Container = styled.div``;
