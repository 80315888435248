import styled from 'styled-components/macro';

export const AppStoreImage = styled.img`
  height: 50px;

  @media only screen and (max-width: 948px) {
    height: 44px;
  }
`;

export const AppStoreLink = styled.a``;

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media only screen and (max-width: 948px) {
    gap: 8px;
    justify-content: center;
  }
`;

export const BlurBackground = styled.div`
  width: 143px;
  height: 353px;
  display: block;
  -webkit-filter: blur(100px);
  filter: blur(100px);
  background-color: #28e6c3;
  overflow: hidden;
  z-index: 0;
  aspect-ratio: 0.4062992125984252 / 1;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Image = styled.img`
  max-width: 244px;
  width: 100%;
  z-index: 1;
  position: relative;
`;

export const ImageWrapper = styled.div`
  position: relative;
`;

export const Description = styled.span`
  font-family: 'Poppins', 'Open Sans';
  font-size: 1.125rem;
  color: #fff;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 48px;

  @media only screen and (max-width: 948px) {
    text-align: center;
    margin-bottom: 32px;
  }
`;

export const Title = styled.span`
  font-family: 'Poppins', 'Open Sans';
  font-size: 4rem;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 32px;
  line-height: 80px;

  @media only screen and (max-width: 948px) {
    font-size: 3rem;
    line-height: 40px;
    text-align: center;
    margin-bottom: 24px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 456px;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 92px;
  margin-bottom: 128px;

  @media only screen and (max-width: 948px) {
    flex-direction: column-reverse;
  }
`;
