import React from 'react';

import Header from '@components/Dashboard/CustomValues/In/Header';
import Table from '@components/Dashboard/CustomValues/In/Table';
import Billing from '@components/Dashboard/CustomValues/In/Billing';
import NewCustomValueSidemodal from '@components/Dashboard/CustomValues/In/NewCustomValueSidemodal';
import EditCustomValueSidemodal from '@components/Dashboard/CustomValues/In/EditCustomValueSidemodal';
import { useCustomValuesIn } from '@helpers/hooks/pages/dashboard/customValues/useCustomValuesIn';
import PageError from '@components/common/core/Utils/PageError';

import { CUSTOM_VALUES_FILTER_CONFIG } from '@helpers/utils/filters/customValuesFilter';

import InstantHelp from '@components/common/core/Utils/InstantHelp';
import Filter from '@components/common/core/Utils/Filter';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const {
    customValuesError,
    customValuesCategoriesError,
    customValueToEdit,
    selectedCustomValueChecked,
    isValidatingCustomValues,
    isValidatingCustomValuesCategories,
    mutateCustomValues,
    mutateCustomValuesCategories,
    customValuesCategories,
    isLoadingCustomValues,
    isLoadingCustomValuesCategories,
    verifyPreDefinedCustomValuesCategories,
    handleFilterData,
    filterData,
  } = useCustomValuesIn();

  const addNewData = React.useCallback(
    newData => {
      handleFilterData([newData]);
    },
    [handleFilterData],
  );

  React.useEffect(() => {
    if (customValuesCategories && !customValuesCategories.length) {
      verifyPreDefinedCustomValuesCategories();
    }
  }, [verifyPreDefinedCustomValuesCategories, customValuesCategories]);

  const mutate = React.useCallback(async () => {
    const promises = [];

    if (customValuesError) promises.push(mutateCustomValues());

    if (customValuesCategoriesError) promises.push(mutateCustomValuesCategories());

    await Promise.all(promises);
  }, [
    customValuesError,
    customValuesCategoriesError,
    mutateCustomValuesCategories,
    mutateCustomValues,
  ]);

  if (
    (customValuesError && !isValidatingCustomValues) ||
    (customValuesCategoriesError && !isValidatingCustomValuesCategories)
  ) {
    return <PageError mutate={mutate} />;
  }

  const isLoading =
    isLoadingCustomValues || isLoadingCustomValuesCategories || isValidatingCustomValues;

  const isInstantHelpComponentVisible = !isLoading && !selectedCustomValueChecked;

  return (
    <S.Content>
      <Header />

      {Boolean(!filterData.length) && (
        <S.FilterButton
          data={filterData}
          addNewData={addNewData}
          config={CUSTOM_VALUES_FILTER_CONFIG}
        />
      )}

      {Boolean(filterData.length) && (
        <Filter
          filterData={filterData}
          handleFilterData={handleFilterData}
          config={CUSTOM_VALUES_FILTER_CONFIG}
        />
      )}

      <Table />

      {isInstantHelpComponentVisible && (
        <InstantHelp
          name="custom_values_in"
          title="Valores Adicionais"
          articleLink="https://ajuda.profitfy.me/pt-BR/articles/5901683-como-configurar-valores-adicionais"
        />
      )}

      {selectedCustomValueChecked && (
        <Billing selectedCustomValueChecked={selectedCustomValueChecked} />
      )}

      <NewCustomValueSidemodal />

      {customValueToEdit && <EditCustomValueSidemodal />}
    </S.Content>
  );
};

export default Wrapper;
