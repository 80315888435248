import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ImageBase from '@components/common/core/DataDisplay/Image';
import NoImage from '@components/common/core/DataDisplay/NoImage';

export const NoImageFill = styled(NoImage)`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[4]};
`;

export const Image = styled(ImageBase)`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

export const Text = styled(HeadingBase)``;

export const GoBackButton = styled.button`
  background-color: unset;
  border: unset;
  padding: unset;
  display: flex;
  align-items: center;
  gap: 16px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;
