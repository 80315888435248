import React from 'react';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { IDailyBudget } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/ISummaryReport';

import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const Budget: React.FC<ICampaignProps> = ({ data }) => {
  const budgetData: IDailyBudget = data;

  return (
    <S.Wrapper>
      <S.BudgetAmount>{`R$ ${numberFormatter(budgetData.total_amount, 2)}`}</S.BudgetAmount>
      <S.BudgetLabel>Diariamente</S.BudgetLabel>
    </S.Wrapper>
  );
};

export default Budget;
