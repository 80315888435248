import * as yup from 'yup';

export const duplicateCampaignsModalSchema = yup.object().shape({
  quantity: yup
    .string()
    .required('Este campo é obrigatório.')
    .test('quantity validator', 'A quantidade precisa ser maior ou igual a 1.', value => {
      if (Number(value) && Number(value) >= 1) return true;

      return false;
    }),
});
