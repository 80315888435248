import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const UnderstoodButton = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.colors.warning.default};
  box-shadow: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.warning.default};
    box-shadow: none;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const DescriptionText = styled(TextBase)`
  line-height: 1;
  color: ${({ theme }) => theme.colors.background[1]};
  font-weight: 600;
  font-size: 1rem;
  display: inline;
`;

export const Link = styled(TextBase)`
  display: inline;
  color: ${({ theme }) => theme.colors.background[1]};
  font-weight: 600;
  font-size: 1rem;
  display: inline;
  line-height: 1;
  text-decoration: underline;
  margin-left: 4px;
`;

export const LinkWrapper = styled.div`
  display: inline-flex;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 7px;
  background-color: #a37cff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CongratulationsText = styled(TextBase)`
  line-height: 120%;
  display: block;
  margin-bottom: 4px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconAndTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  & > svg {
    min-width: 32px;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 12px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c0a6fd;
`;

export const Content = styled.div`
  max-width: 1262px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media only screen and (max-width: 1168px) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
`;
