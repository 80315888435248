import styled from 'styled-components/macro';

import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import LabelBase from '@components/common/core/DataDisplay/Label';
import DateRangePickerBase from '@components/common/core/Utils/CustomDatePicker';
import ButtonBase from '@components/common/core/Inputs/Button';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';

export const Select = styled(SelectBase)`
  max-width: 264px;
  width: 100%;

  & > label > select {
    width: 100%;
  }
`;

export const Option = styled(OptionBase)``;

export const SubmitButton = styled(ButtonBase)`
  margin-top: 32px;
`;

export const HorizontalRule = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.gray[3]};
  margin: 48px 0;
`;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Input = styled(TextFieldBase)`
  max-width: 324px;
`;

export const Label = styled(LabelBase)``;

export const DateRangePicker = styled(DateRangePickerBase)`
  & > div:first-child {
    margin-bottom: 0;
  }
`;

export const Wrapper = styled.div``;
