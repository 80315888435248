import styled from 'styled-components/macro';
import { Handle as HandleBase } from 'react-flow-renderer';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.div`
  height: 56px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  border: 2px solid #cdb4db;
  gap: 12px;
  box-sizing: border-box;
  border-radius: 12px;
  position: relative;
`;

export const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background-color: #cdb4db;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Name = styled(TextBase)`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white.dark};
`;

export const HandleContainer = styled.div`
  width: 24px;
  height: 20px;
  border-radius: 100px;
  padding: 4px 6px 4px 6px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  box-sizing: border-box;
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
`;

export const Handle = styled(HandleBase)`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.gray[3]};
  border: none;
  bottom: 4px;
`;
