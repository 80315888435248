import React from 'react';
import { ChevronDown } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { useCreateOrder } from '@helpers/hooks/pages/dashboard/orders/useCreateOrder';

import * as S from './styles';

const BlurredProduct: React.FC = () => {
  const theme = useTheme();
  const { handleCardExpanded, quantity, initialProduct, index } = useCreateOrder();

  const [isProductImageError, setIsProductImageError] = React.useState<boolean>(false);

  return (
    <S.Wrapper onClick={handleCardExpanded}>
      <S.ProductNumberText fontWeight={EHeadingWeight.MEDIUM}>
        {`#${index + 1}`}
      </S.ProductNumberText>

      {!isProductImageError && initialProduct?.product?.image_url ? (
        <S.Image
          src={initialProduct?.product?.image_url}
          alt="Profitfy.me Producte"
          onError={() => setIsProductImageError(true)}
        />
      ) : (
        <S.NoImage />
      )}

      <S.ProductDetails>
        <S.ProductName>{initialProduct?.product?.name}</S.ProductName>
      </S.ProductDetails>

      <S.QuantityText>{`Qtd ${quantity}x`}</S.QuantityText>

      <S.ExpandIconWrapper>
        <ChevronDown size={20} color={theme.colors.gray[1]} />
      </S.ExpandIconWrapper>
    </S.Wrapper>
  );
};

export default BlurredProduct;
