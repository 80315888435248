import React from 'react';

import { Check } from '@profitfy/pakkun-icons';
import { CircleSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components/macro';
import { ISynchronization } from '@domain/interfaces/hooks/IStoreSynchronization';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { numberFormatter } from '@helpers/masks';
import { useConfig } from '@helpers/hooks/useConfig';
import { getOrdersPercentage } from '@helpers/utils/onboarding/synchronization';

import * as S from './styles';

const ProcessingStatus: React.FC = () => {
  const theme = useTheme();
  const {
    currentSynchronizations,
    synchronizationsQueue,
    finishedSynchronizations,
    failedSynchronizations,
  } = useSynchronization();

  const { selectedStore } = useConfig();

  const getIcon = React.useCallback(
    (
      percentage: number,
      synchronizations: Array<ISynchronization>,
      synchronizationType: ESynchronizationType,
    ) => {
      if (percentage >= 100)
        return (
          <S.ProcessingIconWrapper>
            <Check size={24} />
          </S.ProcessingIconWrapper>
        );

      const foundSynchronization = synchronizations.find(
        synchronization => synchronization.type === synchronizationType,
      );

      if (foundSynchronization)
        return (
          <S.ProcessingIconWrapper>
            <CircleSpinner size={16} color={theme.colors.green.default} />
          </S.ProcessingIconWrapper>
        );

      return <S.InQueueIcon />;
    },
    [theme],
  );

  const parsedCurrentSynchronizations = currentSynchronizations
    ? currentSynchronizations.synchronizations
    : [];

  const mergedSynchronizations = [
    ...parsedCurrentSynchronizations,
    ...synchronizationsQueue,
    ...finishedSynchronizations,
    ...failedSynchronizations,
  ];

  const onboardingSynchronizations = mergedSynchronizations.filter(
    synchronization => synchronization.config.isOnboardingSynchronization,
  );

  const ordersPercentage = getOrdersPercentage(onboardingSynchronizations, selectedStore?.type);

  const Icon = getIcon(
    ordersPercentage,
    currentSynchronizations?.synchronizations || [],
    ESynchronizationType.STORE_ORDER,
  );

  const formattedOrdersPercentage = `(${numberFormatter(ordersPercentage, 0, true)}%)`;

  return (
    <S.Wrapper>
      <S.Title>Processando histórico de 30 dias</S.Title>

      <S.ProcessingGroup>
        <S.IconAndTitleWrapper>
          {Icon}
          <S.ProcessingTitle>Buscando pedidos na sua plataforma</S.ProcessingTitle>
        </S.IconAndTitleWrapper>

        <S.ProgressWrapper>
          <S.ProgressBarWrapper>
            <S.ProgressBar style={{ width: `${ordersPercentage}%` }} />
          </S.ProgressBarWrapper>

          <S.PercentageProgressText isGreaterThanZero={ordersPercentage > 0}>
            {formattedOrdersPercentage}
          </S.PercentageProgressText>
        </S.ProgressWrapper>
      </S.ProcessingGroup>
    </S.Wrapper>
  );
};

export default ProcessingStatus;
