import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const NoDataWrapper = styled.div`
  margin: 0 auto;
  max-width: 564px;
  width: 362px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 16px;
`;

export const Image = styled(ImageBase)`
  margin-bottom: 24px;
`;

export const Heading = styled(HeadingBase)``;
