import { ApiService } from '@services/api';
import { LONG_API_DOMAIN } from '@constants/api';
import {
  ICreateGoogleCredentialProps,
  IDeleteAdSenseCredentialProps,
} from '@domain/interfaces/dashboard/AdSense/Google/IGoogleCredential';

export class GoogleCredentialService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public createGoogleCredential({
    data,
    storeAliasId,
  }: ICreateGoogleCredentialProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/google/ad-sense-credentials`,
      { ...data },
    );
  }

  public deleteAdSenseCredential({
    adSenseCredentialAliasId,
    storeAliasId,
  }: IDeleteAdSenseCredentialProps): Promise<any> {
    return this.apiService.delete(
      `/api/v1/users/stores/${storeAliasId}/ad-sense-credentials/${adSenseCredentialAliasId}`,
    );
  }
}

const googleCredentialService = new GoogleCredentialService(LONG_API_DOMAIN);

export default googleCredentialService;
