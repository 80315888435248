import React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import * as S from './styles';

const Content: React.FC<
  TooltipPrimitive.TooltipContentProps & React.RefAttributes<HTMLDivElement>
> = ({ children, ...rest }) => {
  return <S.TooltipContent {...rest}>{children}</S.TooltipContent>;
};

export default Content;
