import React from 'react';

import { ITabProps, ITab } from '@domain/interfaces/components/ITab';
import Option from './Option';

import * as S from './styles';

const Tab: ITab & React.FC<ITabProps> = ({ children, defaultTab, onChangeTab, ...rest }) => {
  const [currentTab, setCurrentTab] = React.useState<number>(Number(defaultTab));

  const handleTabSelect = React.useCallback(
    index => {
      setCurrentTab(index);
      onChangeTab(index);
    },
    [onChangeTab],
  );

  React.useEffect(() => {
    setCurrentTab(Number(defaultTab));
  }, [defaultTab]);

  return (
    <S.OptionWrapper {...rest}>
      {children.map((tab: any, index: number) => {
        return (
          <Option
            key={tab?.props?.children}
            isSelected={currentTab === index}
            onClick={() => handleTabSelect(index)}
          >
            {tab?.props?.children}
          </Option>
        );
      })}
    </S.OptionWrapper>
  );
};

Tab.Option = Option;

export default Tab;
