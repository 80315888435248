import {
  IFinancialReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresProvidersValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresProviderValues = (
  currentSelectedStoreFinancialReportData: IFinancialReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresProvidersValuesResponse => {
  const currentSelectedStoreCartPandaAmountValue =
    currentSelectedStoreFinancialReportData?.provider?.cart_panda_amount;

  const currentSelectedStoreMercadoLivreAmountValue =
    currentSelectedStoreFinancialReportData?.provider?.mercado_livre_amount;

  const currentSelectedStoreNuvemShopAmountValue =
    currentSelectedStoreFinancialReportData?.provider?.nuvem_shop_amount;

  const currentSelectedStoreShopifyAmountValue =
    currentSelectedStoreFinancialReportData?.provider?.shopify_amount;

  const currentSelectedStoreYampiAmountValue =
    currentSelectedStoreFinancialReportData?.provider?.yampi_amount;

  const storesCartPandaAmountValues = selectedStoresData.map(
    value => value.financial_report?.provider?.cart_panda_amount,
  );

  const storesMercadoLivreAmountValues = selectedStoresData.map(
    value => value.financial_report?.provider?.mercado_livre_amount,
  );

  const storesNuvemShopAmountValues = selectedStoresData.map(
    value => value.financial_report?.provider?.nuvem_shop_amount,
  );

  const storesShopifyAmountValues = selectedStoresData.map(
    value => value.financial_report?.provider?.shopify_amount,
  );

  const storesYampiAmountValues = selectedStoresData.map(
    value => value.financial_report?.provider?.yampi_amount,
  );

  const cartPandaAmountValues = [
    ...storesCartPandaAmountValues,
    currentSelectedStoreCartPandaAmountValue,
  ];

  const mercadoLivreAmountValues = [
    ...storesMercadoLivreAmountValues,
    currentSelectedStoreMercadoLivreAmountValue,
  ];

  const nuvemShopAmountValues = [
    ...storesNuvemShopAmountValues,
    currentSelectedStoreNuvemShopAmountValue,
  ];

  const shopifyAmountValues = [
    ...storesShopifyAmountValues,
    currentSelectedStoreShopifyAmountValue,
  ];

  const yampiAmountValues = [...storesYampiAmountValues, currentSelectedStoreYampiAmountValue];

  const reducedCartPandaAmountValues = cartPandaAmountValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const reducedMercadoLivreAmountValues = mercadoLivreAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedNuvemShopAmountValues = nuvemShopAmountValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const reducedShopifyAmountValues = shopifyAmountValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const reducedYampiAmountValues = yampiAmountValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  return {
    cart_panda_amount: reducedCartPandaAmountValues,
    mercado_livre_amount: reducedMercadoLivreAmountValues,
    nuvem_shop_amount: reducedNuvemShopAmountValues,
    shopify_amount: reducedShopifyAmountValues,
    yampi_amount: reducedYampiAmountValues,
  };
};
