import React from 'react';

import { ETabOption } from '@domain/enums/dashboard/storeSubscription/ETabOptions';
import { ITabProps } from '@domain/interfaces/dashboard/StoreSubscription/ITab';

import * as S from './styles';

const Tab: React.FC<ITabProps> = ({ selectedOption, handleOption }) => {
  const onTabOptionClick = React.useCallback(
    tabOption => {
      handleOption(tabOption);
    },
    [handleOption],
  );

  return (
    <S.Wrapper>
      <S.Option
        isSelected={selectedOption === ETabOption.SUBSCRIPTION}
        onClick={() => onTabOptionClick(ETabOption.SUBSCRIPTION)}
      >
        Assinatura
      </S.Option>
      <S.Option
        isSelected={selectedOption === ETabOption.PLANS}
        onClick={() => onTabOptionClick(ETabOption.PLANS)}
      >
        Planos
      </S.Option>
    </S.Wrapper>
  );
};

export default Tab;
