import { IFilterConfig } from '@domain/interfaces/components/IFilter';
import { EFilterType } from '@domain/enums/components/EFilter';

export const CUSTOM_VALUES_FILTER_CONFIG: Array<IFilterConfig> = [
  {
    field: 'is_active',
    label: 'Status',
    type: EFilterType.SELECT,
    config: {
      placeholder: 'Encontrar status...',
      data: [
        {
          label: 'Ativo',
          value: 'true',
        },
        {
          label: 'Inátivo',
          value: 'false',
        },
      ],
    },
  },
  {
    field: 'period',
    label: 'Frequência',
    type: EFilterType.SELECT,
    config: {
      placeholder: 'Encontrar frequência...',
      data: [
        {
          label: 'Única',
          value: 'ONCE',
        },
        {
          label: 'Diária',
          value: 'DAILY',
        },
        {
          label: 'Semanal',
          value: 'WEEKLY',
        },
        {
          label: 'Mensal',
          value: 'MONTHLY',
        },
        {
          label: 'Anual',
          value: 'ANNUALLY',
        },
      ],
    },
  },
  {
    field: 'custom_value_category_id',
    label: 'Categoria',
    type: EFilterType.SELECT,
    config: {
      placeholder: 'Encontrar categoria...',
    },
  },
  {
    field: 'description',
    label: 'Descrição',
    type: EFilterType.TEXT,
    config: {
      placeholder: 'Encontrar descrição...',
    },
  },
  {
    field: 'date',
    label: 'Período',
    type: EFilterType.DATE_RANGE_PICKER,
    config: {
      placeholder: 'Encontrar período...',
    },
  },
];
