import React from 'react';
import { useLocation } from 'react-router-dom';

import Route from '@components/routes/Route';
import DowngradeWrapper from '@pages/Downgrade';
import AdProviders from '@pages/Downgrade/AdProviders';
import InvitedMembers from '@pages/Downgrade/InvitedMembers';
import FacebookProfiles from '@pages/Downgrade/FacebookProfiles';
import Workflows from '@pages/Downgrade/Workflows';

const Downgrade: React.FC = () => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location]);

  return (
    <DowngradeWrapper>
      <Route isPrivate path="/:storeAliasId/downgrade/ad-providers" exact component={AdProviders} />
      <Route
        isPrivate
        path="/:storeAliasId/downgrade/invited-members"
        exact
        component={InvitedMembers}
      />
      <Route
        isPrivate
        path="/:storeAliasId/downgrade/facebook-profiles"
        exact
        component={FacebookProfiles}
      />
      <Route
        isPrivate
        path="/:storeAliasId/downgrade/store-workflows"
        exact
        component={Workflows}
      />
    </DowngradeWrapper>
  );
};

export default Downgrade;
