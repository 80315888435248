import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import InputBase from '@components/common/core/Inputs/TextField';

export const Wrapper = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  /* border-right: 1px solid ${({ theme }) => theme.colors.gray[5]}; */
`;

export const Title = styled(HeadingBase)`
  font-size: 1.125rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[1]};
  padding: 24px 16px;
`;

export const TextInput = styled(InputBase)`
  width: 100%;
  border-radius: 0;

  > * {
    border-radius: 0;
  }
`;

export const IntegrationsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  scrollbar-color: ${({ theme }) => theme.colors.gray[4]} ${({ theme }) => theme.colors.gray[6]};
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[4]};
  }
`;

export const SuggestionContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.colors.gray[5]};
  cursor: pointer;
`;

export const SuggestionText = styled(TextBase)`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.green.default};
`;
