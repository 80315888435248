import React from 'react';

import { Feature } from '@profitfy/pakkun-icons';
import { EHeadingSize } from '@domain/enums/components/EHeading';
import { EButtonVariant } from '@domain/enums/components/EButton';
import { useHistory, useParams } from 'react-router-dom';
import { IParams } from '@domain/interfaces/IParams';
import { useConfig } from '@helpers/hooks/useConfig';
import { useOnboardingTag } from '@helpers/hooks/pages/dashboard/productAnalytics/useOnboardingTag';

import * as S from './styles';

const Welcome: React.FC = () => {
  const history = useHistory();

  const { completeOnboarding, isCompletingOnboarding } = useOnboardingTag();
  const { analytics } = useConfig();
  const { storeAliasId } = useParams<IParams>();

  const trackContext = {
    context: { groupId: storeAliasId },
  };

  const handleCreateTag = React.useCallback(() => {
    analytics?.track(
      'Product Tags Onboarding Create Campaign Identifier Button Clicked',
      {},
      trackContext,
    );

    history.push('campaigns/onboarding/new-tag');
  }, [history, analytics, trackContext]);

  const handleSkipOnboarding = React.useCallback(async () => {
    analytics?.track('Product Tags Onboarding Skip Onboarding Button Clicked', {}, trackContext);

    completeOnboarding();
  }, [analytics, trackContext, completeOnboarding]);

  return (
    <S.ContentWrapper>
      <S.Image
        src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/productAnalytics/productTagSynchronization.svg"
        alt="profitfy-product-tag-image"
      />

      <S.Content>
        <S.Heading type={EHeadingSize.H3}>
          Sincronize custo de Ads ao produto e tenha <span>mais assertividade.</span>
        </S.Heading>

        <S.BenefitsWrapper>
          <S.Benefit>
            <S.BenefitIconWrapper>
              <Feature size={14} />
            </S.BenefitIconWrapper>
            <S.BenefitText>Automatização de custo de Ads.</S.BenefitText>
          </S.Benefit>

          <S.Benefit>
            <S.BenefitIconWrapper>
              <Feature size={14} />
            </S.BenefitIconWrapper>
            <S.BenefitText>Assertividade em suas métricas de marketing.</S.BenefitText>
          </S.Benefit>

          <S.Benefit>
            <S.BenefitIconWrapper>
              <Feature size={14} />
            </S.BenefitIconWrapper>
            <S.BenefitText>Ganho de performance na análise.</S.BenefitText>
          </S.Benefit>
        </S.BenefitsWrapper>

        <S.ButtonsWrapper>
          <S.CreateTagButton onClick={handleCreateTag}>
            Criar identificador de campanhas (Tag)
          </S.CreateTagButton>

          <S.HorizontalRuleWrapper>
            <S.HorizontalRule />
            <S.HorizontalRuleText>ou</S.HorizontalRuleText>
            <S.HorizontalRule />
          </S.HorizontalRuleWrapper>

          <S.SkipOnboardingButton
            onClick={handleSkipOnboarding}
            variant={EButtonVariant.SECONDARY_BUTTON}
            isLoading={isCompletingOnboarding}
            disabled={isCompletingOnboarding}
          >
            Pular onboarding e ir para tabela de campanhas
          </S.SkipOnboardingButton>
        </S.ButtonsWrapper>
      </S.Content>
    </S.ContentWrapper>
  );
};

export default Welcome;
