import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';
import { IOnboardingTagProvider } from '@domain/interfaces/dashboard/ProductAnalytics/IOnboardingTag';

import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import { ADS_PROVIDERS } from '@constants/common/integrations';

import productCostDetailsService from '@services/pages/dashboard/productCostDetails/productCostDetails';
import productTagService from '@services/pages/dashboard/productTag/productTag';

const OnboardingTagContext = React.createContext<IOnboardingTagProvider | null>(null);

export const OnboardingTagProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const { analytics } = useConfig();
  const { addSynchronization, currentSynchronizations } = useSynchronization();
  const { storeIntegrations } = useStoreConfig();
  const { toast } = useToast();
  const { storeAliasId, productAliasId } = useParams<IParams>();

  const [isCompletingOnboarding, setIsCompletingOnboarding] = React.useState<boolean>(false);

  const {
    productTags,
    isLoading: isLoadingProductTags,
    isValidating: isValidatingProductTag,
    mutate: mutateProductTags,
  } = productTagService.listProductTags({
    storeAliasId,
    productAliasId,
    page: 1,
  });

  const {
    product,
    mutate: mutateProduct,
    isLoading: isLoadingProduct,
    isValidating: isValidatingProduct,
  } = productCostDetailsService.getProduct({
    storeAliasId,
    productAliasId,
  });

  const trackContext = {
    context: { groupId: storeAliasId },
  };

  const completeOnboarding = React.useCallback(async () => {
    setIsCompletingOnboarding(true);

    try {
      await productCostDetailsService.updateProduct({
        storeAliasId,
        productAliasId,
        data: { is_onboarding_finished: true, is_favorite: product.is_favorite },
      });

      mutateProduct();

      analytics?.track('Product Tags Onboarding Finished', {}, trackContext);

      setIsCompletingOnboarding(false);
    } catch (error: any) {
      setIsCompletingOnboarding(false);
      toast.error(error?.response?.data?.message);
    }
  }, [mutateProduct, storeAliasId, productAliasId, analytics, trackContext, toast, product]);

  const handleSyncCampaigns = React.useCallback(async () => {
    if (storeIntegrations) {
      const activeAdSenseIntegrations = storeIntegrations.marketing.filter(
        integration => integration.is_active,
      );

      const marketingIntegrations = ADS_PROVIDERS.filter(integration =>
        activeAdSenseIntegrations.find(
          activeIntegration => activeIntegration.name === integration.identifier,
        ),
      );

      marketingIntegrations.forEach(integration => {
        const { name, generateSyncRequest } = integration;

        addSynchronization({
          name,
          type: ESynchronizationType.AD_SENSE_CAMPAIGNS,
          label: 'Campanhas',
          dependencies: [],
          storeAliasId,
          config: {
            isOnboardingSynchronization: false,
            showNotification: true,
          },
          request: generateSyncRequest(
            ESynchronizationType.AD_SENSE_CAMPAIGNS,
            storeAliasId,
            undefined,
            undefined,
          ),
        });
      });
    }

    await completeOnboarding();

    history.push(
      `/${storeAliasId}/dashboard/overview/products-analytic/${productAliasId}/campaigns`,
    );
  }, [
    addSynchronization,
    storeAliasId,
    productAliasId,
    storeIntegrations,
    completeOnboarding,
    history,
  ]);

  const trackNewTag = React.useCallback(
    (action: string) => {
      const saveTagEventMessage = 'Product Tags Onboarding Save Tag Button Clicked';
      const addTagEventMessage = 'Product Tags Onboarding Add Tag Button Clicked';

      const trackEventMessage = action === 'save_tag' ? saveTagEventMessage : addTagEventMessage;

      return analytics?.track(trackEventMessage, {}, trackContext);
    },
    [analytics, trackContext],
  );

  const createProductTag = React.useCallback(
    async (name, action) => {
      try {
        await productTagService.createProductTag({
          storeAliasId,
          productAliasId,
          data: {
            name,
          },
        });

        toast.success('Custo dos Produtos atualizado com sucesso!');

        toast.success('Tag criada com sucesso!');

        await mutateProductTags();

        trackNewTag(action);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
    [mutateProductTags, productAliasId, storeAliasId, toast, trackNewTag],
  );

  const updateProductTag = React.useCallback(
    async (productTagAliasId, name) => {
      try {
        await productTagService.updateProductTag({
          storeAliasId,
          productAliasId,
          productTagAliasId,
          data: {
            name,
          },
        });

        toast.success('Tag atualizada com sucesso!');

        await mutateProductTags();
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
    [mutateProductTags, toast, storeAliasId, productAliasId],
  );

  const deleteProductTag = React.useCallback(
    async productTagAliasId => {
      try {
        await productTagService.deleteProductTag({
          storeAliasId,
          productAliasId,
          productTagAliasId,
        });

        toast.success('Tag excluída com sucesso!');

        await mutateProductTags();
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
    [mutateProductTags, storeAliasId, productAliasId, toast],
  );

  const isSyncCampaigns = Boolean(
    currentSynchronizations?.synchronizations.find(
      synchronization => synchronization.type === ESynchronizationType.AD_SENSE_CAMPAIGNS,
    ),
  );

  return (
    <OnboardingTagContext.Provider
      value={{
        product,
        productTags,
        isLoadingProductTags,
        isValidatingProductTag,
        createProductTag,
        deleteProductTag,
        updateProductTag,
        handleSyncCampaigns,
        isSyncCampaigns,
        completeOnboarding,
        isCompletingOnboarding,
        isLoadingProduct,
        isValidatingProduct,
      }}
    >
      {children}
    </OnboardingTagContext.Provider>
  );
};

export const useOnboardingTag = (): IOnboardingTagProvider => {
  const context = React.useContext(OnboardingTagContext);

  if (!context) {
    throw new Error('useOnboardingTag must be used within OnboardingTagProvider');
  }

  return context;
};
