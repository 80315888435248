import styled from 'styled-components/macro';

export const InputGroup = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

export const LabelWrapper = styled.div`
  margin-bottom: 12px;
  max-width: 108px;
  width: 100%;
`;

export const TutorialWrapper = styled.div`
  margin-bottom: 40px;
  width: 100%;
`;

export const TutorialTitleWrapper = styled.div`
  max-width: 188px;
  width: 100%;
  margin-bottom: 8px;
`;

export const FunctionalityDescriptionWrapper = styled.div`
  width: 100%;
  margin-bottom: 48px;
`;

export const FunctionalityTitleWrapper = styled.div`
  margin-bottom: 8px;
  max-width: 236px;
  width: 100%;
`;

export const Header = styled.div`
  margin-bottom: 76px;
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  max-width: 52px;
  width: 100%;
  height: 52px;
  margin-right: 24px;
`;

export const TitleWrapper = styled.div`
  max-width: 216px;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  max-width: 487px;
  width: 100%;
`;
