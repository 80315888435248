import { AxiosResponse } from 'axios';

import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { USER_API_DOMAIN } from '@constants/api';
import {
  IActiveSubscriptionProps,
  ICancelSubscription,
  IChangePlanProps,
  ICreateSubscriptionProps,
  ICreateTrialProps,
  IGetStoreOrdersQuantityProps,
  IGetStoreOrdersQuantityResponse,
  IGetSubscriptionPlanProps,
  IGetSubscriptionProps,
  IGetSubscriptionResponse,
  IUpdateCancelationSubscriptionProps,
} from '@domain/interfaces/subscription/ISubscription';

export class SubscriptionService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getSubscriptionPlan({
    storeAliasId,
    subscriptionAliasId,
  }: IGetSubscriptionPlanProps): Promise<any> {
    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/plans/subscriptions/${subscriptionAliasId}`,
    );
  }

  public getStoreOrdersQuantity({
    storeAliasId,
    endDate,
    startDate,
  }: IGetStoreOrdersQuantityProps): IGetStoreOrdersQuantityResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/orders/quantity?start_date=${startDate}&end_date=${endDate}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      ordersQuantity: data?.orders_quantity,
      error,
      mutate,
      isLoading,
      isValidating,
    };
  }

  public getSubscriptionPromise({ storeAliasId }: IGetSubscriptionProps): Promise<any> {
    return this.apiService.get(`/api/v1/users/stores/${storeAliasId}/subscriptions`);
  }

  public getSubscription({ storeAliasId }: IGetSubscriptionProps): IGetSubscriptionResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/subscriptions`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      subscription: data?.subscription,
      error,
      isLoading,
      isValidating,
      mutate,
    };
  }

  public createTrial({ storeAliasId }: ICreateTrialProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/subscriptions/trial`);
  }

  public createSubscription({ storeAliasId, planAliasId }: ICreateSubscriptionProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/plans/${planAliasId}/subscriptions`,
    );
  }

  public changePlan({
    planAliasId,
    subscriptionAliasId,
    storeAliasId,
  }: IChangePlanProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/plans/${planAliasId}/subscriptions/${subscriptionAliasId}/change-plan`,
    );
  }

  public cancelSubscription({
    subscriptionAliasId,
    data,
    storeAliasId,
  }: ICancelSubscription): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/subscriptions/${subscriptionAliasId}/cancel`,
      {
        ...data,
      },
    );
  }

  public updateCancelationSubscription({
    storeAliasId,
    subscriptionAliasId,
    data,
  }: IUpdateCancelationSubscriptionProps): Promise<AxiosResponse<void>> {
    return this.apiService.put<void>(
      `/api/v1/users/stores/${storeAliasId}/subscriptions/${subscriptionAliasId}/cancellation-reason`,
      { ...data },
    );
  }

  public activeSubscription({
    storeAliasId,
    subscriptionAliasId,
  }: IActiveSubscriptionProps): Promise<AxiosResponse<void>> {
    return this.apiService.post<void>(
      `/api/v1/users/stores/${storeAliasId}/subscriptions/${subscriptionAliasId}/active`,
    );
  }
}

const subscriptionService = new SubscriptionService(USER_API_DOMAIN);

export default subscriptionService;
