import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';

import {
  IUpsertOtherGatewayProps,
  IGetOtherGatewayProps,
  IGetOtherGatewayResponse,
} from '@domain/interfaces/dashboard/IntegrationCenter/Gateway/IOtherGateway';

export class OtherGatewayIntegrationService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listOtherGateways({ storeAliasId }: IGetOtherGatewayProps): IGetOtherGatewayResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/other-gateways`,
      this.apiService,
    );

    const isLoading = !error && !data;

    return {
      otherGateways: data?.other_gateways,
      error,
      mutate,
      isLoading,
      isValidating,
    };
  }

  public upsertOtherGateway({ storeAliasId, data }: IUpsertOtherGatewayProps): Promise<any> {
    return this.apiService.put(`/api/v1/users/stores/${storeAliasId}/other-gateways`, { ...data });
  }
}

const otherGatewayIntegrationService = new OtherGatewayIntegrationService(API_DOMAIN);

export default otherGatewayIntegrationService;
