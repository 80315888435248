import React from 'react';

import { useParams } from 'react-router-dom';
import { Box } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { currencyToNumber } from '@helpers/masks';

import {
  editOrderModalSchema,
  editChargebackModalSchema,
} from '@helpers/validators/dashboard/orders/editOrdersModal/editOrderModal';

import { ILineItem } from '@domain/interfaces/common/orders/IOrders';
import { IEditOrderModalProps } from '@domain/interfaces/dashboard/Orders/IEditOrderModal';
import { EOrderStatus } from '@domain/enums/common/EOrder';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ETextWeight } from '@domain/enums/components/EText';

import { useToast } from '@helpers/hooks/useToast';
import { useDate } from '@helpers/hooks/useDate';
import { IParams } from '@domain/interfaces/IParams';
import { useConfig } from '@helpers/hooks/useConfig';

import ordersService from '@services/pages/dashboard/orders/orders';

import SideModalSave from '@components/common/core/Utils/SideModalSave';
import Text from '@components/common/core/DataDisplay/Text';
import Form from '@components/common/core/Inputs/Form';

import DefaultEditForm from './DefaultEditForm';
import ChagebackEditForm from './ChargebackEditForm';
import RefundedEditForm from './RefundedEditForm';
import CanceledEditForm from './CanceledEditForm';

import * as S from './styles';

const DEFAULT_FORM = ['IN_ANALYSIS', 'REJECTED', 'NO_INFORMATION', 'PENDING', 'PAID'];
const CHARGEBACK_FORM = ['CHARGED_BACK'];
const REFUNDED_FORM = ['REFUNDED'];
const CANCELLED_FORM = ['CANCELED'];

const EditOrderModal: React.FC<IEditOrderModalProps> = ({
  toggle,
  order,
  mutateOrders,
  isOpen,
}) => {
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { utcToZonedTime } = useDate();
  const { analytics, selectedStore, user } = useConfig();

  const refundedAtDefaultDate = order?.refunded_at
    ? utcToZonedTime(new Date(order?.refunded_at))
    : new Date();

  const approvedAtDefaultDate = order?.approved_at
    ? utcToZonedTime(new Date(order?.approved_at))
    : new Date();

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [refundedDate, setRefundedDate] = React.useState(refundedAtDefaultDate);
  const [approvedDate, setApprovedDate] = React.useState(approvedAtDefaultDate);
  const [orderStatusSelected, setOrderStatusSelected] = React.useState<EOrderStatus>(order.status);

  const hasSelectedRefundedOrChargeback =
    orderStatusSelected === EOrderStatus.CHARGED_BACK ||
    orderStatusSelected === EOrderStatus.REFUNDED;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(
      hasSelectedRefundedOrChargeback ? editChargebackModalSchema : editOrderModalSchema,
    ),
  });

  const handleOrderStatus = React.useCallback((event: any) => {
    const orderStatus = event.target.value;

    setOrderStatusSelected(orderStatus);
  }, []);

  const onSubmit = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      const isChargeBackOrder = formData.status === EOrderStatus.CHARGED_BACK;
      const isRefundedOrder = formData.status === EOrderStatus.REFUNDED;
      const isPaidOrder = formData.status === EOrderStatus.PAID;

      const hasRefundedAt = isChargeBackOrder || isRefundedOrder;

      const gatewayAmount = currencyToNumber(formData?.gateway_amount || '0');
      const gatewayFeeAmount = currencyToNumber(formData?.gateway_fee_amount || '0');
      const cogsAmount = currencyToNumber(formData?.cogs_amount || '0');
      const refundedAmount = currencyToNumber(formData?.refunded_amount || '0');
      const shippingCostOwnerAmount = currencyToNumber(formData?.shipping_cost_owner_amount || '0');
      const infoProductFeeAmount = currencyToNumber(formData?.info_product_fee_amount || '0');
      const refundedAt = hasRefundedAt ? utcToZonedTime(refundedDate).toISOString() : null;
      const approvedAt = isPaidOrder ? utcToZonedTime(approvedDate).toISOString() : null;

      const parsedOrderData = {
        ...formData,
        gateway_amount: gatewayAmount,
        gateway_fee_amount: gatewayFeeAmount,
        cogs_amount: cogsAmount,
        refunded_amount: refundedAmount,
        shipping_cost_owner_amount: shippingCostOwnerAmount,
        info_product_fee_amount: infoProductFeeAmount,
        refunded_at: refundedAt,
        approved_at: approvedAt,
      };

      const orderData = { ...order, ...parsedOrderData };

      try {
        await ordersService.updateOrder({
          order: orderData,
          orderAliasId: order.alias_id,
          storeAliasId,
        });

        analytics?.track(
          'Order Edited',
          {
            email: user?.email,
          },
          { context: { groupId: selectedStore?.alias_id } },
        );

        toast.success('Pedido alterado com sucesso!');

        setIsSubmittingForm(false);

        mutateOrders();
        toggle();
      } catch (error: any) {
        setIsSubmittingForm(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [
      toast,
      toggle,
      order,
      storeAliasId,
      approvedDate,
      refundedDate,
      mutateOrders,
      utcToZonedTime,
      analytics,
      selectedStore,
      user,
    ],
  );

  return (
    <S.Sidemodal isOpen={isOpen} toggle={toggle}>
      <S.Header>
        <Box size={24} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Editar pedido {order.name}
        </S.Heading>
      </S.Header>

      <S.ProductsTitle weight={ETextWeight.BOLD}>Produtos</S.ProductsTitle>

      <S.ProductListWrapper>
        {order.line_items.map((lineItem: ILineItem) => (
          <S.ListItem key={lineItem.id}>{lineItem.product?.name}</S.ListItem>
        ))}
      </S.ProductListWrapper>

      <S.ContentWrapper>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>Status do pedido</S.Label>
            <S.Select
              {...register('status')}
              name="status"
              defaultValue={orderStatusSelected}
              onChange={handleOrderStatus}
            >
              <S.Option disabled value="" />
              <S.Option value={EOrderStatus.PAID}>Aprovado</S.Option>
              <S.Option value={EOrderStatus.CHARGED_BACK}>Chargeback</S.Option>
              <S.Option value={EOrderStatus.REFUNDED}>Devolvido</S.Option>
              <S.Option value={EOrderStatus.CANCELED}>Cancelado</S.Option>
            </S.Select>
            {errors.status && <Text isErrorFeedback>{errors.status.message}</Text>}
          </S.InputGroup>

          {DEFAULT_FORM.includes(orderStatusSelected) && (
            <DefaultEditForm
              order={order}
              errors={errors}
              register={register}
              setDate={setApprovedDate}
              date={approvedDate}
            />
          )}

          {REFUNDED_FORM.includes(orderStatusSelected) && (
            <RefundedEditForm
              order={order}
              errors={errors}
              register={register}
              setDate={setRefundedDate}
              date={refundedDate}
            />
          )}

          {CHARGEBACK_FORM.includes(orderStatusSelected) && (
            <ChagebackEditForm
              order={order}
              errors={errors}
              register={register}
              setDate={setRefundedDate}
              date={refundedDate}
            />
          )}

          {CANCELLED_FORM.includes(orderStatusSelected) && (
            <CanceledEditForm order={order} errors={errors} register={register} />
          )}

          <SideModalSave
            successButtonText="Editar"
            cancelButtonText="Cancelar"
            onCancel={toggle}
            isLoading={isSubmittingForm}
            isDisabled={isSubmittingForm}
          />
        </Form>
      </S.ContentWrapper>
    </S.Sidemodal>
  );
};

export default EditOrderModal;
