import TextBase from '@components/common/core/DataDisplay/Text';
import styled, { css } from 'styled-components/macro';

import LabelBase from '@components/common/core/DataDisplay/Label';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import RadioBase from '@components/common/core/Inputs/Radio';

interface IRadioLabelProps {
  checked: boolean;
}

export const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const Question = styled(TextBase)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray[1]};
  font-size: 1rem;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.16px;
  margin-bottom: 12px;
`;

export const RadioGroup = styled(InputGroupBase)`
  gap: 8px;
  margin: unset;
`;

export const Radio = styled(RadioBase)``;

export const RadioLabel = styled(LabelBase)<IRadioLabelProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  gap: 8px;
  padding: 6px 14px;
  width: 100%;
  border-radius: 7px;
  border: 1px solid ${({ theme }) => theme.colors.gray[4]};
  box-sizing: border-box;
  position: relative;
  transition: all 0.3s;
  margin: unset;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  ${({ checked }) =>
    checked &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.green.default};
    `}
`;

export const RadioText = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.gray[1]};
`;
