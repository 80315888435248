import React from 'react';

import { useTheme } from 'styled-components/macro';
import { Pen } from '@profitfy/pakkun-icons';
import { IOverviewCardProps } from '@domain/interfaces/dashboard/ProductAnalytics/IProductDetails';
import { numberFormatter } from '@helpers/masks';
import { useProductCostDetails } from '@helpers/hooks/pages/dashboard/productCostDetails/productCostDetails';
import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const Card: React.FC<IOverviewCardProps> = ({ icon: Icon, percentage, value, name }) => {
  const theme = useTheme();

  const { handleEditCogsSideModalOpen } = useProductCostDetails();
  const { analytics, selectedStore } = useConfig();

  const isPercentagePositive = percentage >= 0;

  const parsedPercentageValue = numberFormatter(
    isPercentagePositive ? percentage : Math.abs(percentage),
    2,
  );

  const isCogsCard = name === 'Custo dos Produtos';

  const onEditCogsIconClick = React.useCallback(() => {
    analytics?.track(
      'Product Tags Link Ad Cost Button Clicked',
      {},
      {
        context: { groupId: selectedStore?.alias_id },
      },
    );

    handleEditCogsSideModalOpen();
  }, [handleEditCogsSideModalOpen, analytics, selectedStore]);

  return (
    <>
      <S.CardWrapper>
        <S.Content>
          <Icon size={40} />

          <S.TextWrapper>
            <S.Title>{name}</S.Title>
            <S.ValueAndPercentageWrapper>
              <S.Value>{`R$ ${numberFormatter(value, 2)}`}</S.Value>
              <S.PercentageWrapper>
                <S.PercentageArrow isPositive={isPercentagePositive} />
                <S.PercentageNumber
                  isPositive={isPercentagePositive}
                >{`${parsedPercentageValue}%`}</S.PercentageNumber>
              </S.PercentageWrapper>
            </S.ValueAndPercentageWrapper>
          </S.TextWrapper>
        </S.Content>

        {isCogsCard && (
          <S.EditCogsIconWrapper onClick={onEditCogsIconClick}>
            <Pen size={16} outline color={theme.colors.gray[1]} />
          </S.EditCogsIconWrapper>
        )}
      </S.CardWrapper>
    </>
  );
};

export default Card;
