import React from 'react';
import { Folder } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';

import CampaignsAction from './CampaignsAction';

import * as S from './styles';

const AdName: React.FC<ICampaignProps> = ({ data, adSenseCampaignAliasId, isHoveringRow }) => {
  const adNameData: string = data;

  const theme = useTheme();

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        <S.IconWrapper>
          <Folder outline size={16} color={theme.colors.font} />
        </S.IconWrapper>
        <S.Name>{adNameData}</S.Name>
      </S.ContentWrapper>

      {isHoveringRow && <CampaignsAction adSenseCampaignAliasId={adSenseCampaignAliasId} />}
    </S.Wrapper>
  );
};

export default AdName;
