import * as yup from 'yup';
import valid from 'card-validator';

export const paymentSchema = yup.object().shape({
  number: yup
    .string()
    .required('Este campo é obrigatório.')
    .test('valid number', 'Insira um cartão válido.', value => {
      const parsedValue = value?.replaceAll(' ', '');

      const validation = valid.number(parsedValue);

      return validation.isValid;
    })
    .test('valid issuer', 'Esse cartão não é suportado! Por favor, tente outro.', value => {
      const validation = valid.number(value);

      const card = validation?.card;

      if (
        card?.niceType &&
        (card.niceType === 'Visa' ||
          card.niceType === 'Mastercard' ||
          card.niceType === 'Elo' ||
          card.niceType === 'Diners Club' ||
          card.niceType === 'American Express')
      )
        return true;

      return false;
    }),
  full_name: yup.string().required('Este campo é obrigatório.').min(2, 'Insira um nome válido.'),
  expiration: yup
    .string()
    .required('Este campo é obrigatório.')
    .test('valid expiration date', 'Insira uma data de vencimento válida.', value => {
      const validation = valid.expirationDate(value);

      return validation.isValid;
    }),
  verification_value: yup
    .string()
    .required('Este campo é obrigatório.')
    .test('valid cvv', 'Insira um CVV válido.', value => {
      const validation = valid.cvv(value, 4);

      return validation.isPotentiallyValid;
    }),
});
