import styled, { css } from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const Wrapper = styled.div`
  width: fit-content;
  min-width: 231px;
  height: fit-content;
  margin-top: -48px;
  display: flex;
  flex-direction: column;
`;

export const Heading = styled(HeadingBase)`
  margin-bottom: 24px;
  color: #ffffff;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;

  @media only screen and (max-width: 1086px) {
    flex-direction: row;
  }

  @media only screen and (max-width: 420px) {
    flex-direction: column;
  }
`;

export const Option = styled.button<{ isSelected?: boolean }>`
  width: fit-content;
  height: 32px;
  background: unset;
  padding: 4px 16px;
  border: unset;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
  transition: filter 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.colors.green.dark};
      border-radius: 20px;
      color: ${({ theme }) => theme.colors.black.default};
    `}

  @media only screen and (max-width: 1615px) {
    justify-content: center;
    align-items: center;
  }
`;
