import React from 'react';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.ContentWrapper>
      <S.Alert>
        <Skeleton height={82} width="100%" style={{ borderRadius: 5, lineHeight: 'unset' }} />
      </S.Alert>

      <S.Content>
        <Skeleton height={228} width="100%" style={{ borderRadius: 5, lineHeight: 'unset' }} />
      </S.Content>
    </S.ContentWrapper>
  );
};

export default SkeletonLoading;
