import { ApiService } from '@services/api';
import { SUGGESTION_API_DOMAIN } from '@constants/api';

interface ICreateSegmentationData {
  experience_range: string;
  profit_range: string;
}

interface ICreateSegmentationProps {
  data: ICreateSegmentationData;
}

export class UsersSegmentationervice {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public createSegmentation({ data }: ICreateSegmentationProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/onboarding`, { ...data });
  }
}

const usersSegmentationervice = new UsersSegmentationervice(SUGGESTION_API_DOMAIN);

export default usersSegmentationervice;
