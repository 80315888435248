import styled from 'styled-components/macro';
import TooltipBase from 'react-tooltip';

import { IPopoverProps } from '@domain/interfaces/components/IPopover';

export const Popover = styled(TooltipBase)<IPopoverProps>`
  background-color: ${({ theme }) => theme.colors.gray[7]} !important;
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 10px !important;
  /* max-width: ${({ width }) => `${width}px`}; */
  min-width: 148px !important;
  width: fit-content !important;
  opacity: 1 !important;
  box-sizing: border-box;
  z-index: ${({ theme }) => theme.zIndex.secondLayer} !important;
  padding: unset !important;
  border: unset !important;

  &.place-right::after {
    border-right-color: ${({ theme }) => theme.colors.gray[7]} !important;
  }

  &.place-left::after {
    border-left-color: ${({ theme }) => theme.colors.gray[7]} !important;
  }

  &.place-top::after {
    border-top-color: ${({ theme }) => theme.colors.gray[7]} !important;
  }

  &.place-bottom::after {
    border-bottom-color: ${({ theme }) => theme.colors.gray[7]} !important;
  }
`;
