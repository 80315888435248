import { MarkerType } from 'react-flow-renderer';

const defaultProps = {
  animated: true,
  type: 'buttonedge',
  style: {
    strokeWidth: 4,
  },
  markerEnd: {
    type: MarkerType.ArrowClosed,
    orient: 'auto',
  },
};

export const REFUSED_CREDIT_CARD_FREE_EDGES = [
  {
    ...defaultProps,
    id: `reactflow__edge-node1-node2`,
    source: `node1`,
    target: `node2`,
  },
  {
    ...defaultProps,
    id: `reactflow__edge-node2-node3`,
    source: `node2`,
    target: `node3`,
  },
];
