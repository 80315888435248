import { IPricingToolProvider } from '@domain/interfaces/dashboard/PricingTool/IPricingTool';
import React from 'react';

const PricingToolContext = React.createContext<IPricingToolProvider | null>(null);

export const PricingToolProvider: React.FC = ({ children }) => {
  const [productCost, setProductCost] = React.useState<number>(0);
  const [iofAliexpress, setIofAliexpress] = React.useState<number>(0.0038);
  const [checkout, setCheckout] = React.useState<number>(0.025);
  const [gateway, setGateway] = React.useState<number>(0.05);
  const [tax, setTax] = React.useState<number>(0.0);
  const [marketing, setMarketing] = React.useState<number>(0.2);
  const [customMarkup, setCustomMarkup] = React.useState<number>(1);
  const [shipmentFee, setShipmentFee] = React.useState<number>(0.0);

  const handleProductCost = React.useCallback(value => {
    setProductCost(value);
  }, []);
  const handleIofAliexpress = React.useCallback(value => {
    setIofAliexpress(value);
  }, []);
  const handleCheckout = React.useCallback(value => {
    setCheckout(value);
  }, []);
  const handleGateway = React.useCallback(value => {
    setGateway(value);
  }, []);
  const handleTax = React.useCallback(value => {
    setTax(value);
  }, []);
  const handleMarketing = React.useCallback(value => {
    setMarketing(value);
  }, []);
  const handleCustomMarkup = React.useCallback(value => {
    setCustomMarkup(value);
  }, []);
  const handleShipmentFee = React.useCallback(value => {
    setShipmentFee(value);
  }, []);

  const markup = [1.75, 2.0, 2.25, 2.75, 3.0, customMarkup];
  const finalPrice = [
    (productCost + shipmentFee) * markup[0],
    (productCost + shipmentFee) * markup[1],
    (productCost + shipmentFee) * markup[2],
    (productCost + shipmentFee) * markup[3],
    (productCost + shipmentFee) * markup[4],
    (productCost + shipmentFee) * markup[5],
  ];
  const fixedCost = [
    productCost * iofAliexpress +
      finalPrice[0] * checkout +
      finalPrice[0] * gateway +
      finalPrice[0] * tax +
      productCost +
      shipmentFee,
    productCost * iofAliexpress +
      finalPrice[1] * checkout +
      finalPrice[1] * gateway +
      finalPrice[1] * tax +
      productCost +
      shipmentFee,
    productCost * iofAliexpress +
      finalPrice[2] * checkout +
      finalPrice[2] * gateway +
      finalPrice[2] * tax +
      productCost +
      shipmentFee,
    productCost * iofAliexpress +
      finalPrice[3] * checkout +
      finalPrice[3] * gateway +
      finalPrice[3] * tax +
      productCost +
      shipmentFee,
    productCost * iofAliexpress +
      finalPrice[4] * checkout +
      finalPrice[4] * gateway +
      finalPrice[4] * tax +
      productCost +
      shipmentFee,
    productCost * iofAliexpress +
      finalPrice[5] * checkout +
      finalPrice[5] * gateway +
      finalPrice[5] * tax +
      productCost +
      shipmentFee,
  ];
  const marketingCost = [
    finalPrice[0] * marketing,
    finalPrice[1] * marketing,
    finalPrice[2] * marketing,
    finalPrice[3] * marketing,
    finalPrice[4] * marketing,
    finalPrice[5] * marketing,
  ];
  const profit = [
    finalPrice[0] - fixedCost[0] - marketingCost[0],
    finalPrice[1] - fixedCost[1] - marketingCost[1],
    finalPrice[2] - fixedCost[2] - marketingCost[2],
    finalPrice[3] - fixedCost[3] - marketingCost[3],
    finalPrice[4] - fixedCost[4] - marketingCost[4],
    finalPrice[5] - fixedCost[5] - marketingCost[5],
  ];
  const maxCpa = [
    marketingCost[0] + profit[0],
    marketingCost[1] + profit[1],
    marketingCost[2] + profit[2],
    marketingCost[3] + profit[3],
    marketingCost[4] + profit[4],
    marketingCost[5] + profit[5],
  ];
  const profitMargin = [
    (profit[0] * 100) / finalPrice[0],
    (profit[1] * 100) / finalPrice[1],
    (profit[2] * 100) / finalPrice[2],
    (profit[3] * 100) / finalPrice[3],
    (profit[4] * 100) / finalPrice[4],
    (profit[5] * 100) / finalPrice[5],
  ];

  return (
    <PricingToolContext.Provider
      value={{
        productCost,
        iofAliexpress,
        checkout,
        gateway,
        tax,
        marketing,
        customMarkup,
        shipmentFee,
        markup,
        finalPrice,
        fixedCost,
        marketingCost,
        profit,
        maxCpa,
        profitMargin,
        handleProductCost,
        handleIofAliexpress,
        handleCheckout,
        handleGateway,
        handleTax,
        handleMarketing,
        handleCustomMarkup,
        handleShipmentFee,
      }}
    >
      {children}
    </PricingToolContext.Provider>
  );
};

export const usePricingTool = (): IPricingToolProvider => {
  const context = React.useContext(PricingToolContext);

  if (!context) {
    throw new Error('usePricingTool must be used within PricingToolProvider');
  }

  return context;
};
