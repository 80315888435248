import styled, { css } from 'styled-components/macro';

import { EHeadAlign } from '@domain/enums/components/ETable';

export const TableRow = styled.tr``;

export const TableData = styled.td`
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.font};
  font-weight: 400;
  padding: 0 12px 24px 12px;

  &:first-child {
    padding-left: 0px;
  }

  ${({ align }) =>
    align === EHeadAlign.CENTER &&
    css`
      text-align: center;
    `};

  ${({ align }) =>
    align === EHeadAlign.RIGHT &&
    css`
      text-align: right;
    `};
`;

export const Dot = styled.span`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.green.default};
`;

export const ActionsButton = styled.button`
  padding: 8px;
  border: none;
  background-color: transparent;
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  cursor: pointer;
  margin: 0 auto;
  position: relative;
`;

export const ActionsWrapper = styled.div`
  padding: 12px 0;
`;

export const ActionButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.font};
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:hover {
    color: ${({ theme }) => theme.colors.green.default};
  }

  &:active {
    color: ${({ theme }) => theme.colors.green.dark};
  }

  &:first-child {
    margin-bottom: 12px;
  }
`;

export const AccessibleActionsButtonName = styled.span`
  position: absolute;
  opacity: 0;
  width: 0;
`;
