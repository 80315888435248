import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';
import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const SeePlansButton = styled(ButtonBase)`
  width: 100%;
`;

export const ConfirmButton = styled(ButtonBase)`
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  @media only screen and (max-width: 482px) {
    flex-direction: column;
  }
`;

export const FeatureToBeRemovedText = styled(TextBase)`
  font-size: 0.875rem;
  font-weight: 500;
`;

export const FeatureToBeRemoved = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FeaturesToBeRemoved = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
`;

export const NoPermissionsLabel = styled(TextBase)`
  display: inline-block;
  margin-bottom: 16px;
`;

export const NewBenefitText = styled(TextBase)`
  font-size: 0.875rem;
`;

export const NewBenefit = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: calc(60% - 16px);
  margin-left: 16px;

  & > svg:first-child {
    margin-right: 12px;
  }
`;

export const OldBenefitText = styled(TextBase)`
  font-size: 0.875rem;
`;

export const OldBenefit = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 40%;
`;

export const BenefitDowngrade = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[4]};
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

export const BenefitsDowngradeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  background-color: ${({ theme }) => theme.colors.background[2]};
  padding: 16px;
  box-sizing: border-box;
  border-radius: 5px;
`;

export const Description = styled(TextBase)`
  display: inline-block;
  margin-bottom: 16px;
  font-weight: 500;
`;

export const Title = styled(HeadingBase)`
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[4]};
  padding-bottom: 16px;
  margin-bottom: 32px;
`;

export const Modal = styled(ModalBase)`
  @media only screen and (max-width: 482px) {
    padding: 32px 20px;
  }

  & > div:first-child {
    max-width: 588px;
    margin: unset;

    @media only screen and (max-width: 1242px) {
      width: 564px;
    }

    @media only screen and (max-width: 787px) {
      width: 492px;
    }

    @media only screen and (max-width: 586px) {
      width: 392px;
    }

    @media only screen and (max-width: 482px) {
      width: 320px;
    }
  }
`;
