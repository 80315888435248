import styled from 'styled-components/macro';

import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';

export const Popover = styled(CustomPopoverBase)``;

export const PopoverTrigger = styled(CustomPopoverBase.Trigger)``;

export const PopoverContent = styled(CustomPopoverBase.Content)`
  display: flex;
  flex-direction: column;
  width: 144px;
  border-radius: 5px;
`;

export const PopoverArrow = styled(CustomPopoverBase.Arrow)``;

export const PopoverOption = styled.button`
  display: flex;
  background: unset;
  padding: 8px 16px;
  border: unset;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[1]};
  transition: all 0.3s;
  cursor: pointer;
  text-align: left;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[5]};
  }
`;

export const VariablesWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  overflow: hidden;
`;

export const VariableButton = styled.button`
  min-width: max-content;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  border-radius: 20px;
  border: unset;
  height: 25px;
  padding: 2px 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.gray[1]};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;
