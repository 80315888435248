import { ApiService } from '@services/api';
import { LONG_API_DOMAIN } from '@constants/api';
import { AxiosResponse } from 'axios';

export class FinancialDataService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public sendFinancialData(storeAliasId: string | undefined): Promise<AxiosResponse<void>> {
    return this.apiService.post<void>(
      `/api/v1/users/stores/${storeAliasId}/segments/send-financial-data`,
    );
  }
}

const financialDataService = new FinancialDataService(LONG_API_DOMAIN);

export default financialDataService;
