export const MONTHS = [
  { id: '01', name: 'janeiro' },
  { id: '02', name: 'fevereiro' },
  { id: '03', name: 'março' },
  { id: '04', name: 'abril' },
  { id: '05', name: 'maio' },
  { id: '06', name: 'junho' },
  { id: '07', name: 'julho' },
  { id: '08', name: 'agosto' },
  { id: '09', name: 'setembro' },
  { id: '10', name: 'outubro' },
  { id: '11', name: 'novembro' },
  { id: '12', name: 'dezembro' },
];
