import {
  AirplaneTilt,
  Barcode,
  CreditCard,
  QrCode,
  ShoppingCart,
  ShoppingCartSimple,
  Ticket,
  Truck,
  XCircle,
} from 'phosphor-react';

import { ETriggers } from '@domain/enums/dashboard/automations/ETriggers';
import { ITrigger } from '@domain/interfaces/dashboard/Automations/ITriggers';

export const triggersMapper = (trigger: ETriggers): ITrigger => {
  if (trigger === ETriggers.ABANDONED_CART) {
    return {
      id: 'abandoned_cart',
      title: 'Carrinho abandonado',
      description: 'Começa quando um cliente abandona um carrinho abandonado',
      icon: ShoppingCart,
      type: ETriggers.ABANDONED_CART,
    };
  }

  if (trigger === ETriggers.BOLETO) {
    return {
      id: 'boleto_generated',
      title: 'Boleto gerado',
      description: 'Começa quando um cliente realiza um pedido',
      icon: Barcode,
      type: ETriggers.BOLETO,
    };
  }

  if (trigger === ETriggers.PIX) {
    return {
      id: 'pix_generated',
      title: 'Pix gerado',
      description: 'Começa quando um cliente tem o pagamento do pedido aprovado',
      icon: QrCode,
      type: ETriggers.PIX,
    };
  }

  if (trigger === ETriggers.REJECTED_PAYMENT) {
    return {
      id: 'refused_card',
      title: 'Cartão recusado',
      description: 'Começa quando o pedido de um cliente é cancelado',
      icon: CreditCard,
      type: ETriggers.REJECTED_PAYMENT,
    };
  }

  if (trigger === ETriggers.BOLETO_RECOVER) {
    return {
      id: 'boleto_recover',
      title: 'Recuperação de boletos',
      description: 'Começa quando o pedido de um cliente é entregue ao destinatário',
      icon: Barcode,
      type: ETriggers.BOLETO_RECOVER,
    };
  }

  if (trigger === ETriggers.PIX_RECOVER) {
    return {
      id: 'pix_recover',
      title: 'Recuperação de pix',
      description: 'Começa quando o pedido de um cliente é entregue ao destinatário',
      icon: QrCode,
      type: ETriggers.PIX_RECOVER,
    };
  }

  if (trigger === ETriggers.UPDATED_FULFILLMENT) {
    return {
      id: 'updated_fulfillment',
      title: 'Rastreio por status',
      description: 'Começa quando há uma atualização no rastreio de um pedido',
      icon: AirplaneTilt,
      type: ETriggers.UPDATED_FULFILLMENT,
    };
  }

  if (trigger === ETriggers.SIMPLE_SHIPMENT) {
    return {
      id: 'simple_shipment',
      title: 'Rastreio criado',
      description: 'Começa quando o pedido de um cliente é enviado ao comprador',
      icon: Truck,
      type: ETriggers.SIMPLE_SHIPMENT,
    };
  }

  if (trigger === ETriggers.APPROVED_ORDER) {
    return {
      id: 'approved_order',
      title: 'Pedido Aprovado',
      description: 'Começa quando o pedido de um cliente é aprovado',
      icon: ShoppingCartSimple,
      type: ETriggers.APPROVED_ORDER,
    };
  }

  if (trigger === ETriggers.CANCELED_ORDER) {
    return {
      id: 'canceled_order',
      title: 'Pedido Cancelado',
      description: 'Começa quando o pedido de um cliente é cancelado',
      icon: XCircle,
      type: ETriggers.CANCELED_ORDER,
    };
  }

  if (trigger === ETriggers.COUPON) {
    return {
      id: 'coupon',
      title: 'Cupom',
      description: 'Começa quando o um cliente tem direito a um cupom',
      icon: Ticket,
      type: ETriggers.COUPON,
    };
  }

  return {
    id: 'empty',
    title: '',
    description: '',
    icon: null,
    type: ETriggers.ABANDONED_CART,
  };
};
