import queryString from 'query-string';

import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';

import {
  IListAdSenseCampaignsProps,
  IListAdSenseCampaignsResponse,
  IListAdSenseCampaignsWithInsightsResponse,
} from '@domain/interfaces/dashboard/AdSense/IAdSenseCampaign';

export class AdSenseCampaignService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listAdSenseCampaigns({
    storeAliasId,
    productAliasId,
    page,
    filter,
  }: IListAdSenseCampaignsProps): IListAdSenseCampaignsResponse {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    const { data, isValidating, error, mutate } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/ad-sense-campaigns?limit=10&page=${page}&${queryStringFilter}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      adSenseCampaigns: data?.ad_sense_campaigns,
      totalPages: data?.total_pages,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public listAdSenseCampaignWithInsights({
    storeAliasId,
    productAliasId,
    page,
    filter,
  }: IListAdSenseCampaignsProps): IListAdSenseCampaignsWithInsightsResponse {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    const { data, isValidating, error, mutate } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/ad-sense-campaigns-insights?limit=10&page=${page}&${queryStringFilter}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      adSenseCampaigns: data?.ad_sense_campaigns,
      totalPages: data?.total_pages,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }
}

const adSenseCampaignsService = new AdSenseCampaignService(API_DOMAIN);

export default adSenseCampaignsService;
