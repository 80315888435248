import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ImageBase from '@components/common/core/DataDisplay/Image';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.gray[7]};
  display: flex;
  align-items: flex-start;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 36px;
  box-sizing: border-box;
  align-self: flex-start;

  @media only screen and (max-width: 420px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const Image = styled(ImageBase)`
  width: 100%;
  max-width: 351px;
  margin-bottom: 42px;
`;

export const Heading = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.white.default};
  margin-bottom: 40px;
`;

export const AddNewProfileButton = styled.button`
  max-width: 379px;
  width: 100%;
  height: 42px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 5px;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-weight: 400;
  font-size: 1rem;
  border: 0;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[1]};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;
