import styled, { css } from 'styled-components/macro';

import { IWrapperProps } from '@domain/interfaces/components/IInstantHelp';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import BadgeBase from '@components/common/core/DataDisplay/Badge';
import ImageBase from '@components/common/core/DataDisplay/Image';
import VideoModalBase from '@components/common/core/DataDisplay/VideoModal';

import playVideoImg from '@assets/components/InstantHelp/play-video.svg';
import empytImg from '@assets/components/InstantHelp/empyt-image.svg';

export const Wrapper = styled.div<IWrapperProps>`
  position: relative;
  margin-top: ${({ marginTop }) => marginTop}px;
  padding: 24px 16px;
  width: 100%;
  max-width: 745px;
  border-radius: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.gray[4]};
  gap: 16px;
  box-sizing: border-box;

  @media only screen and (max-width: 635px) {
    flex-direction: column;
    align-items: flex-start;
    max-width: 343px;
  }
`;

export const ContentLeftSide = styled.button`
  position: relative;
  padding: unset;
  border: unset;
  background: unset;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 51px;
    height: 50px;
    background-image: url(${playVideoImg});
    background-repeat: no-repeat;
  }
`;

export const ContentRightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
  margin-left: 8px;
`;

export const Image = styled(ImageBase)`
  width: 100%;
  max-width: 219px;
  filter: brightness(0.4);
  border-radius: 2px;

  ${({ src }) =>
    src === empytImg &&
    css`
      filter: unset;
    `}

  @media only screen and (max-width: 635px) {
    max-width: unset;
    margin-top: 18px;
  }
`;

export const HelpTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const Heading = styled(HeadingBase)`
  color: #ffffff;
  line-height: 100%;
`;

export const HelpText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.green.default};
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const Badge = styled(BadgeBase)`
  @media only screen and (max-width: 385px) {
    min-width: 24px;
  }
`;

export const VideoModal = styled(VideoModalBase)``;

export const CloseIconWrapper = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  padding: unset;
  border: unset;
  background-color: unset;
  cursor: pointer;
`;
