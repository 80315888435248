import React from 'react';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { ICpc } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IAdSenseCampaign';

import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const Cpc: React.FC<ICampaignProps> = ({ data }) => {
  const cpcData: ICpc = data;

  return (
    <S.Wrapper>
      <S.CpcText>{`R$${numberFormatter(cpcData.amount, 2)}`}</S.CpcText>
    </S.Wrapper>
  );
};

export default Cpc;
