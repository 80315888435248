import React from 'react';

import { IText } from '@domain/interfaces/components/IText';
import { ETextType, ETextWeight, ETextSize } from '@domain/enums/components/EText';

import * as S from './styles';

const Text: React.FC<IText> = ({
  type = ETextType.SPAN,
  weight = ETextWeight.REGULAR,
  size = ETextSize.B4,
  children,
  isErrorFeedback = false,
  isHiddenContent,
  dangerouslySetInnerHTML,
  ...rest
}) => {
  return (
    <S.Text
      as={type}
      isErrorFeedback={isErrorFeedback}
      weight={weight}
      size={size}
      isHiddenContent={isHiddenContent}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      {...rest}
    >
      {!isHiddenContent ? children : <span {...rest}>{children}</span>}
    </S.Text>
  );
};

export default Text;
