import React from 'react';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  const commonProp = { lineHeight: 'unset' };

  return (
    <S.Wrapper>
      <S.FormWrapper>
        <S.Heading>
          <Skeleton width="100%" height={34} style={{ ...commonProp }} />
        </S.Heading>

        <S.IntegrationTitle>
          <Skeleton width="100%" height={36} style={{ ...commonProp }} />
        </S.IntegrationTitle>

        <S.Input>
          <Skeleton width="100%" height={42} style={{ ...commonProp, marginBottom: 24 }} />
        </S.Input>

        <S.InputGroup>
          <S.Label>
            <Skeleton width={70} height={16} style={{ ...commonProp }} />
          </S.Label>

          <S.Input>
            <Skeleton width="100%" height={42} style={{ ...commonProp }} />
          </S.Input>
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>
            <Skeleton width={30} height={16} style={{ ...commonProp }} />
          </S.Label>

          <S.Input>
            <Skeleton width="100%" height={42} style={{ ...commonProp }} />
          </S.Input>
        </S.InputGroup>
      </S.FormWrapper>

      <S.TutorialWrapper>
        <Skeleton width="100%" height={784} style={{ borderRadius: 7, ...commonProp }} />
      </S.TutorialWrapper>
    </S.Wrapper>
  );
};

export default SkeletonLoading;
