import styled from 'styled-components/macro';

export const Body = styled.tbody`
  & > tr {
    transition: all 0.1s;

    &:hover {
      background-color: ${({ theme }) => theme.colors.gray[5]};
    }
  }
  /* box-shadow: inset 0px 1px 0 ${({ theme }) => theme.colors.background[3]},
    inset 0px -1px 0 ${({ theme }) => theme.colors.background[3]}; */

  /* & > tr:first-child {
    & > td {
      padding-top: 32px;
    }
  }

  & > tr:last-child {
    & > td {
      padding-bottom: 32px;
    }
  } */
`;
