import { AxiosResponse } from 'axios';

import {
  ICreateNaturalPersonProps,
  ICreateNaturalPersonResponse,
  IDeleteNaturalPersonProps,
  IGetNaturalPersonPromiseResponse,
  IGetNaturalPersonProps,
  IGetNaturalPersonResponse,
  IUpdateNaturalPersonProps,
  IUpdateNaturalPersonResponse,
} from '@domain/interfaces/common/naturalPerson/INaturalPerson';

import { useFetch } from '@providers/Fetch/useFetch';

import { ApiService } from '@services/api';

import { USER_API_DOMAIN } from '@constants/api';

export class NaturalPersonService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getNaturalPerson({ storeAliasId }: IGetNaturalPersonProps): IGetNaturalPersonResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/natural-people`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      naturalPerson: data?.natural_person,
      mutate,
      error,
      isValidating,
      isLoading,
    };
  }

  public getNaturalPersonPromise({
    storeAliasId,
  }: IGetNaturalPersonProps): Promise<AxiosResponse<IGetNaturalPersonPromiseResponse>> {
    return this.apiService.get<IGetNaturalPersonPromiseResponse>(
      `/api/v1/users/stores/${storeAliasId}/natural-people`,
    );
  }

  public createNaturalPerson({
    data,
    storeAliasId,
  }: ICreateNaturalPersonProps): Promise<AxiosResponse<ICreateNaturalPersonResponse>> {
    return this.apiService.post<ICreateNaturalPersonResponse>(
      `/api/v1/users/stores/${storeAliasId}/natural-people`,
      {
        ...data,
      },
    );
  }

  public updateNaturalPerson({
    data,
    storeAliasId,
  }: IUpdateNaturalPersonProps): Promise<AxiosResponse<IUpdateNaturalPersonResponse>> {
    return this.apiService.put<IUpdateNaturalPersonResponse>(
      `/api/v1/users/stores/${storeAliasId}/natural-people`,
      {
        ...data,
      },
    );
  }

  public deleteNaturalPerson({
    storeAliasId,
  }: IDeleteNaturalPersonProps): Promise<AxiosResponse<any>> {
    return this.apiService.delete<any>(`/api/v1/users/stores/${storeAliasId}/natural-people`);
  }
}

const naturalPersonService = new NaturalPersonService(USER_API_DOMAIN);

export default naturalPersonService;
