export const OLD_PLANS_V1 = [
  {
    name: 'Trial Ended',
    identifier: 'trial_ended',
    quarterIdentifier: 'trial_ended',
    semiAnualIdentifier: 'trial_ended',
    monthlyPrice: 0,
    quarterPrice: 0,
    semesterPrice: 0,
    anualPrice: 0,
    description: '',
    ordersPerMonth: 500000000000,
    extraOrderPrice: 0,
    extraOrderPriceQuarter: 0,
    extraOrderPriceSemiannual: 0,
    marketingProvider: 0,
    members: 0,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: 0,
      exportFinancialDashboardSpreadSheet: false,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: false,
      maxMembers: 0,
      maxFacebookProfiles: 0,
      maxWorkflows: 0,
      maxMessagePerWorkflow: 0,
      maxWhatsappInstances: 0,
      maxWhatsappMessagesSent: 0,
    },
  },
  {
    name: 'Grátis',
    identifier: 'free_monthly_v1',
    quarterIdentifier: 'free_monthly_v1',
    semiAnualIdentifier: 'free_monthly_v1',
    monthlyPrice: 0,
    quarterPrice: 0,
    semesterPrice: 0,
    anualPrice: 0,
    description: 'Feito para aqueles que estão iniciando um negócio.',
    ordersPerMonth: 50,
    extraOrderPrice: 0,
    extraOrderPriceQuarter: 0,
    extraOrderPriceSemiannual: 0,
    marketingProvider: 1,
    members: 1,
    facebookProfile: 1,
    features: [
      'Dashboard de previsibilidade financeira',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: 1,
      exportFinancialDashboardSpreadSheet: false,
      productAnalytics: false,
      maxMarketplacesIntegrations: 2,
      adsManager: false,
      maxMembers: 1,
      maxFacebookProfiles: 1,
      maxWorkflows: 1,
      maxMessagePerWorkflow: 1,
      maxWhatsappInstances: 1,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Starter',
    identifier: 'starter_monthly_v1',
    quarterIdentifier: 'starter_monthly_v1',
    semiAnualIdentifier: 'starter_monthly_v1',
    monthlyPrice: 69,
    quarterPrice: 0,
    semesterPrice: 0,
    anualPrice: 0,
    description: 'Feito para aqueles que estão iniciando um negócio.',
    ordersPerMonth: 200,
    extraOrderPrice: 0.26,
    extraOrderPriceQuarter: 0,
    extraOrderPriceSemiannual: 0,
    marketingProvider: 1,
    members: 1,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: 2,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 4,
      adsManager: false,
      maxMembers: 2,
      maxFacebookProfiles: 2,
      maxWorkflows: 2,
      maxMessagePerWorkflow: 2,
      maxWhatsappInstances: 1,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Essentials',
    identifier: 'essentials_monthly_v1',
    quarterIdentifier: 'essentials_monthly_v1',
    semiAnualIdentifier: 'essentials_monthly_v1',
    monthlyPrice: 147,
    quarterPrice: 0,
    semesterPrice: 0,
    anualPrice: 0,
    description: 'Feito para aqueles que estão iniciando um negócio.',
    ordersPerMonth: 500,
    extraOrderPrice: 0.24,
    extraOrderPriceQuarter: 0,
    extraOrderPriceSemiannual: 0,
    marketingProvider: 1,
    members: 1,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: 3,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: undefined,
      adsManager: true,
      maxMembers: 3,
      maxFacebookProfiles: undefined,
      maxWorkflows: 4,
      maxMessagePerWorkflow: 4,
      maxWhatsappInstances: 2,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Premium',
    identifier: 'premium_monthly_v1',
    quarterIdentifier: 'premium_monthly_v1',
    semiAnualIdentifier: 'premium_monthly_v1',
    monthlyPrice: 267,
    quarterPrice: 0,
    semesterPrice: 0,
    anualPrice: 0,
    description: 'Feito para aqueles que estão iniciando um negócio.',
    ordersPerMonth: 1000,
    extraOrderPrice: 0.22,
    extraOrderPriceQuarter: 0,
    extraOrderPriceSemiannual: 0,
    marketingProvider: 1,
    members: 1,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: undefined,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: undefined,
      adsManager: true,
      maxMembers: undefined,
      maxFacebookProfiles: undefined,
      maxWorkflows: 10,
      maxMessagePerWorkflow: 10,
      maxWhatsappInstances: 2,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Pro',
    identifier: 'pro_monthly_v1',
    quarterIdentifier: 'pro_monthly_v1',
    semiAnualIdentifier: 'pro_monthly_v1',
    monthlyPrice: 597,
    quarterPrice: 0,
    semesterPrice: 0,
    anualPrice: 0,
    description: 'Feito para aqueles que estão iniciando um negócio.',
    ordersPerMonth: 2500,
    extraOrderPrice: 0.1,
    extraOrderPriceQuarter: 0,
    extraOrderPriceSemiannual: 0,
    marketingProvider: 1,
    members: 1,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: undefined,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: undefined,
      adsManager: true,
      maxMembers: undefined,
      maxFacebookProfiles: undefined,
      maxWorkflows: undefined,
      maxMessagePerWorkflow: undefined,
      maxWhatsappInstances: undefined,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Elite',
    identifier: 'elite_monthly_v1',
    quarterIdentifier: 'elite_monthly_v1',
    semiAnualIdentifier: 'elite_monthly_v1',
    monthlyPrice: 847,
    quarterPrice: 0,
    semesterPrice: 0,
    anualPrice: 0,
    description: 'Feito para aqueles que estão iniciando um negócio.',
    ordersPerMonth: 5000,
    extraOrderPrice: 0.01,
    extraOrderPriceQuarter: 0,
    extraOrderPriceSemiannual: 0,
    marketingProvider: 1,
    members: 1,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: undefined,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: undefined,
      adsManager: true,
      maxMembers: undefined,
      maxFacebookProfiles: undefined,
      maxWorkflows: undefined,
      maxMessagePerWorkflow: undefined,
      maxWhatsappInstances: undefined,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Lite',
    identifier: 'lite_monthly_v1',
    quarterIdentifier: 'lite_quarter_v1',
    semiAnualIdentifier: 'lite_semester_v1',
    monthlyPrice: 0,
    quarterPrice: 0,
    semesterPrice: 0,
    anualPrice: 0,
    description: 'Feito para aqueles que estão iniciando um negócio.',
    ordersPerMonth: 50,
    extraOrderPrice: 0.33,
    extraOrderPriceQuarter: 0,
    extraOrderPriceSemiannual: 0,
    marketingProvider: 0,
    members: 1,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: 1,
      exportFinancialDashboardSpreadSheet: false,
      productAnalytics: false,
      maxMarketplacesIntegrations: 2,
      adsManager: true,
      maxMembers: 1,
      maxFacebookProfiles: 1,
      maxWorkflows: 1,
      maxMessagePerWorkflow: 1,
      maxWhatsappInstances: 1,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Pro',
    identifier: 'pro_monthly_familia_da_escala_v1',
    quarterIdentifier: 'lite_quarter_v1',
    semiAnualIdentifier: 'lite_semester_v1',
    monthlyPrice: 597,
    quarterPrice: 0,
    semesterPrice: 0,
    anualPrice: 0,
    description: 'Feito para aqueles que estão iniciando um negócio.',
    ordersPerMonth: 2500,
    orderPrice: 0,
    extraOrderPriceQuarter: 0,
    extraOrderPriceSemiannual: 0,
    marketingProvider: 1,
    members: 1,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: undefined,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: undefined,
      adsManager: true,
      maxMembers: undefined,
      maxFacebookProfiles: undefined,
      maxWorkflows: undefined,
      maxMessagePerWorkflow: undefined,
      maxWhatsappInstances: undefined,
      maxWhatsappMessagesSent: undefined,
    },
  },
];

export const OLD_PLANS_V2 = [
  {
    name: 'Starter',
    identifier: 'starter_monthly_v2',
    quarterIdentifier: 'starter_quarterly_v1',
    semiAnualIdentifier: 'starter_semiannual_v1',
    monthlyPrice: 69,
    quarterMonthlyPrice: 58.65,
    quarterPrice: 175.95,
    semesterMonthlyPrice: 51.75,
    semesterPrice: 310.5,
    anualPrice: 69,
    description: 'Para aqueles que estão explorando novos canais de venda.',
    ordersPerMonth: 250,
    extraOrderPrice: 0.3,
    extraOrderPriceQuarter: 0.26,
    extraOrderPriceSemiannual: 0.23,
    marketingProvider: 2,
    members: 2,
    facebookProfile: 2,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
    ],
    benefits: {
      maxAdsIntegrations: 2,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: false,
      maxMembers: 2,
      maxFacebookProfiles: 2,
      maxWorkflows: 2,
      maxMessagePerWorkflow: 2,
      maxWhatsappInstances: 1,
      maxWhatsappMessagesSent: undefined,
    },
  },
];

export const PLANS = [
  {
    name: 'Lite',
    identifier: 'free_monthly_v2',
    quarterIdentifier: 'free_monthly_v2',
    semiAnualIdentifier: 'free_monthly_v2',
    monthlyPrice: 0,
    quarterPrice: 0,
    semesterPrice: 0,
    anualPrice: 0,
    description: 'Feito para aqueles que estão iniciando um negócio.',
    ordersPerMonth: 50,
    extraOrderPrice: 0,
    extraOrderPriceQuarter: 0,
    extraOrderPriceSemiannual: 0,
    marketingProvider: 1,
    members: 1,
    facebookProfile: 1,
    features: ['Dashboard de previsibilidade financeira'],
    benefits: {
      maxAdsIntegrations: 1,
      exportFinancialDashboardSpreadSheet: false,
      productAnalytics: false,
      maxMarketplacesIntegrations: 2,
      adsManager: false,
      maxMembers: 1,
      maxFacebookProfiles: 1,
      maxWorkflows: 1,
      maxMessagePerWorkflow: 1,
      maxWhatsappInstances: 1,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Starter',
    identifier: 'starter_monthly_v3',
    quarterIdentifier: 'starter_quarterly_v2',
    semiAnualIdentifier: 'starter_semiannual_v2',
    monthlyPrice: 89,
    quarterMonthlyPrice: 75.65,
    quarterPrice: 226.95,
    semesterMonthlyPrice: 66.75,
    semesterPrice: 400.5,
    anualPrice: 69,
    description: 'Para aqueles que estão explorando novos canais de venda.',
    ordersPerMonth: 250,
    extraOrderPrice: 0.3,
    extraOrderPriceQuarter: 0.26,
    extraOrderPriceSemiannual: 0.23,
    marketingProvider: 2,
    members: 2,
    facebookProfile: 2,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
    ],
    benefits: {
      maxAdsIntegrations: 2,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: false,
      maxMembers: 2,
      maxFacebookProfiles: 2,
      maxWorkflows: 2,
      maxMessagePerWorkflow: 2,
      maxWhatsappInstances: 1,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Essentials',
    identifier: 'essentials_monthly_v2',
    quarterIdentifier: 'essentials_quarterly_v1',
    semiAnualIdentifier: 'essentials_semiannual_v1',
    monthlyPrice: 147,
    quarterMonthlyPrice: 124.95,
    quarterPrice: 374.85,
    semesterMonthlyPrice: 110.25,
    semesterPrice: 661.5,
    anualPrice: 147,
    description: 'Para aqueles que estão se preparando para tracionar suas vendas.',
    ordersPerMonth: 650,
    extraOrderPrice: 0.27,
    extraOrderPriceQuarter: 0.23,
    extraOrderPriceSemiannual: 0.21,
    marketingProvider: 3,
    members: 3,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
    ],
    benefits: {
      maxAdsIntegrations: 3,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: true,
      maxMembers: 3,
      maxFacebookProfiles: undefined,
      maxWorkflows: 4,
      maxMessagePerWorkflow: 4,
      maxWhatsappInstances: 2,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Premium',
    identifier: 'premium_monthly_v2',
    quarterIdentifier: 'premium_quarterly_v1',
    semiAnualIdentifier: 'premium_semiannual_v1',
    monthlyPrice: 267,
    quarterMonthlyPrice: 226.95,
    quarterPrice: 680.85,
    semesterMonthlyPrice: 200.25,
    semesterPrice: 1201.5,
    anualPrice: 267,
    description: 'Para aqueles estão escalando para chegar no próximo nível.',
    ordersPerMonth: 1250,
    extraOrderPrice: 0.22,
    extraOrderPriceQuarter: 0.19,
    extraOrderPriceSemiannual: 0.17,
    marketingProvider: 0,
    members: 0,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
    ],
    benefits: {
      maxAdsIntegrations: undefined,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: true,
      maxMembers: undefined,
      maxFacebookProfiles: undefined,
      maxWorkflows: 10,
      maxMessagePerWorkflow: 10,
      maxWhatsappInstances: 2,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Unlimited',
    identifier: 'unlimited_monthly_v1',
    quarterIdentifier: 'unlimited_quarterly_v1',
    semiAnualIdentifier: 'unlimited_semiannual_v1',
    monthlyPrice: 497,
    quarterMonthlyPrice: 422.45,
    quarterPrice: 1267.35,
    semesterMonthlyPrice: 372.75,
    semesterPrice: 2236.5,
    anualPrice: 0,
    description: 'Para contas grandes ou agências que gerenciam muitas contas e pedidos.',
    ordersPerMonth: 0,
    extraOrderPrice: 0,
    extraOrderPriceQuarter: 0,
    extraOrderPriceSemiannual: 0,
    marketingProvider: 0,
    members: 0,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: undefined,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: true,
      maxMembers: undefined,
      maxFacebookProfiles: undefined,
      maxWorkflows: undefined,
      maxMessagePerWorkflow: undefined,
      maxWhatsappInstances: undefined,
      maxWhatsappMessagesSent: undefined,
    },
  },
];

const ENTERPRISE_PLANS = [
  {
    name: 'Enterprise',
    identifier: 'enterprise_one_monthly_v1',
    quarterIdentifier: 'enterprise_one_quarterly_v1',
    semiAnualIdentifier: 'enterprise_one_semiannual_v1',
    monthlyPrice: 447,
    quarterMonthlyPrice: 379.95,
    quarterPrice: 1139.85,
    semesterMonthlyPrice: 335.25,
    semesterPrice: 2011.5,
    anualPrice: 0,
    description: 'Para contas grandes ou agências que gerenciam muitas contas e pedidos.',
    ordersPerMonth: 2500,
    extraOrderPrice: 0.2,
    extraOrderPriceQuarter: 0.17,
    extraOrderPriceSemiannual: 0.15,
    marketingProvider: 0,
    members: 0,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: undefined,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: true,
      maxMembers: undefined,
      maxFacebookProfiles: undefined,
      maxWorkflows: undefined,
      maxMessagePerWorkflow: undefined,
      maxWhatsappInstances: undefined,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Enterprise',
    identifier: 'enterprise_two_monthly_v1',
    quarterIdentifier: 'enterprise_two_quarterly_v1',
    semiAnualIdentifier: 'enterprise_two_semiannual_v1',
    monthlyPrice: 647,
    quarterMonthlyPrice: 549.95,
    quarterPrice: 1649.85,
    semesterMonthlyPrice: 485.25,
    semesterPrice: 2911.5,
    anualPrice: 0,
    description: 'Para contas grandes ou agências que gerenciam muitas contas e pedidos.',
    ordersPerMonth: 5000,
    extraOrderPrice: 0.17,
    extraOrderPriceQuarter: 0.15,
    extraOrderPriceSemiannual: 0.13,
    marketingProvider: 0,
    members: 0,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: undefined,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: true,
      maxMembers: undefined,
      maxFacebookProfiles: undefined,
      maxWorkflows: undefined,
      maxMessagePerWorkflow: undefined,
      maxWhatsappInstances: undefined,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Enterprise',
    identifier: 'enterprise_three_monthly_v1',
    quarterIdentifier: 'enterprise_three_quarterly_v1',
    semiAnualIdentifier: 'enterprise_three_semiannual_v1',
    monthlyPrice: 997,
    quarterMonthlyPrice: 847.45,
    quarterPrice: 2542.35,
    semesterMonthlyPrice: 747.75,
    semesterPrice: 4486.5,
    anualPrice: 0,
    description: 'Para contas grandes ou agências que gerenciam muitas contas e pedidos.',
    ordersPerMonth: 10000,
    extraOrderPrice: 0.14,
    extraOrderPriceQuarter: 0.12,
    extraOrderPriceSemiannual: 0.11,
    marketingProvider: 0,
    members: 0,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: undefined,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: true,
      maxMembers: undefined,
      maxFacebookProfiles: undefined,
      maxWorkflows: undefined,
      maxMessagePerWorkflow: undefined,
      maxWhatsappInstances: undefined,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Enterprise',
    identifier: 'enterprise_four_monthly_v1',
    quarterIdentifier: 'enterprise_four_quarterly_v1',
    semiAnualIdentifier: 'enterprise_four_semiannual_v1',
    monthlyPrice: 1497,
    quarterMonthlyPrice: 1272.45,
    quarterPrice: 3817.35,
    semesterMonthlyPrice: 1122.75,
    semesterPrice: 6736.5,
    anualPrice: 0,
    description: 'Para contas grandes ou agências que gerenciam muitas contas e pedidos.',
    ordersPerMonth: 20000,
    extraOrderPrice: 0.11,
    extraOrderPriceQuarter: 0.09,
    extraOrderPriceSemiannual: 0.08,
    marketingProvider: 0,
    members: 0,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: undefined,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: true,
      maxMembers: undefined,
      maxFacebookProfiles: undefined,
      maxWorkflows: undefined,
      maxMessagePerWorkflow: undefined,
      maxWhatsappInstances: undefined,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Enterprise',
    identifier: 'enterprise_five_monthly_v1',
    quarterIdentifier: 'enterprise_five_quarterly_v1',
    semiAnualIdentifier: 'enterprise_five_semiannual_v1',
    monthlyPrice: 1997,
    quarterMonthlyPrice: 1697.45,
    quarterPrice: 5092.35,
    semesterMonthlyPrice: 1497.75,
    semesterPrice: 8986.5,
    anualPrice: 0,
    description: 'Para contas grandes ou agências que gerenciam muitas contas e pedidos.',
    ordersPerMonth: 40000,
    extraOrderPrice: 0.09,
    extraOrderPriceQuarter: 0.077,
    extraOrderPriceSemiannual: 0.068,
    marketingProvider: 0,
    members: 0,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: undefined,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: true,
      maxMembers: undefined,
      maxFacebookProfiles: undefined,
      maxWorkflows: undefined,
      maxMessagePerWorkflow: undefined,
      maxWhatsappInstances: undefined,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Enterprise',
    identifier: 'enterprise_six_monthly_v1',
    quarterIdentifier: 'enterprise_six_quarterly_v1',
    semiAnualIdentifier: 'enterprise_six_semiannual_v1',
    monthlyPrice: 2997,
    quarterMonthlyPrice: 2547.45,
    quarterPrice: 7642.35,
    semesterMonthlyPrice: 2247.75,
    semesterPrice: 13486.5,
    anualPrice: 0,
    description: 'Para contas grandes ou agências que gerenciam muitas contas e pedidos.',
    ordersPerMonth: 80000,
    extraOrderPrice: 0.08,
    extraOrderPriceQuarter: 0.068,
    extraOrderPriceSemiannual: 0.06,
    marketingProvider: 0,
    members: 0,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: undefined,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: true,
      maxMembers: undefined,
      maxFacebookProfiles: undefined,
      maxWorkflows: undefined,
      maxMessagePerWorkflow: undefined,
      maxWhatsappInstances: undefined,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Enterprise',
    identifier: 'enterprise_seven_monthly_v1',
    quarterIdentifier: 'enterprise_seven_quarterly_v1',
    semiAnualIdentifier: 'enterprise_seven_semiannual_v1',
    monthlyPrice: 4997,
    quarterMonthlyPrice: 4247.45,
    quarterPrice: 12742.35,
    semesterMonthlyPrice: 3747.75,
    semesterPrice: 22486.5,
    anualPrice: 0,
    description: 'Para contas grandes ou agências que gerenciam muitas contas e pedidos.',
    ordersPerMonth: 120000,
    extraOrderPrice: 0.07,
    extraOrderPriceQuarter: 0.06,
    extraOrderPriceSemiannual: 0.053,
    marketingProvider: 0,
    members: 0,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: undefined,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: true,
      maxMembers: undefined,
      maxFacebookProfiles: undefined,
      maxWorkflows: undefined,
      maxMessagePerWorkflow: undefined,
      maxWhatsappInstances: undefined,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Enterprise',
    identifier: 'enterprise_one_monthly_10_off_v1',
    quarterIdentifier: 'enterprise_one_quarterly_10_off_v1',
    semiAnualIdentifier: 'enterprise_one_semiannual_10_off_v1',
    monthlyPrice: 402.3,
    quarterMonthlyPrice: 341.96,
    quarterPrice: 1025.87,
    semesterMonthlyPrice: 284.96,
    semesterPrice: 1709.77,
    anualPrice: 0,
    description: 'Para contas grandes ou agências que gerenciam muitas contas e pedidos.',
    ordersPerMonth: 2500,
    extraOrderPrice: 0.2,
    extraOrderPriceQuarter: 0.17,
    extraOrderPriceSemiannual: 0.15,
    marketingProvider: 0,
    members: 0,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: undefined,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: true,
      maxMembers: undefined,
      maxFacebookProfiles: undefined,
      maxWorkflows: undefined,
      maxMessagePerWorkflow: undefined,
      maxWhatsappInstances: undefined,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Enterprise',
    identifier: 'enterprise_two_monthly_10_off_v1',
    quarterIdentifier: 'enterprise_two_quarterly_10_off_v1',
    semiAnualIdentifier: 'enterprise_two_semiannual_10_off_v1',
    monthlyPrice: 582.3,
    quarterMonthlyPrice: 494.96,
    quarterPrice: 1484.87,
    semesterMonthlyPrice: 412.46,
    semesterPrice: 2474.78,
    anualPrice: 0,
    description: 'Para contas grandes ou agências que gerenciam muitas contas e pedidos.',
    ordersPerMonth: 5000,
    extraOrderPrice: 0.17,
    extraOrderPriceQuarter: 0.145,
    extraOrderPriceSemiannual: 0.128,
    marketingProvider: 0,
    members: 0,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: undefined,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: true,
      maxMembers: undefined,
      maxFacebookProfiles: undefined,
      maxWorkflows: undefined,
      maxMessagePerWorkflow: undefined,
      maxWhatsappInstances: undefined,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Enterprise',
    identifier: 'enterprise_three_monthly_10_off_v1',
    quarterIdentifier: 'enterprise_three_quarterly_10_off_v1',
    semiAnualIdentifier: 'enterprise_three_semiannual_10_off_v1',
    monthlyPrice: 897.3,
    quarterMonthlyPrice: 762.7,
    quarterPrice: 2288.12,
    semesterMonthlyPrice: 635.59,
    semesterPrice: 3813.53,
    anualPrice: 0,
    description: 'Para contas grandes ou agências que gerenciam muitas contas e pedidos.',
    ordersPerMonth: 10000,
    extraOrderPrice: 0.14,
    extraOrderPriceQuarter: 0.119,
    extraOrderPriceSemiannual: 0.105,
    marketingProvider: 0,
    members: 0,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: undefined,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: true,
      maxMembers: undefined,
      maxFacebookProfiles: undefined,
      maxWorkflows: undefined,
      maxMessagePerWorkflow: undefined,
      maxWhatsappInstances: undefined,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Enterprise',
    identifier: 'enterprise_four_monthly_10_off_v1',
    quarterIdentifier: 'enterprise_four_quarterly_10_off_v1',
    semiAnualIdentifier: 'enterprise_four_semiannual_10_off_v1',
    monthlyPrice: 1347.3,
    quarterMonthlyPrice: 1145.2,
    quarterPrice: 3435.62,
    semesterMonthlyPrice: 954.33,
    semesterPrice: 5726.03,
    anualPrice: 0,
    description: 'Para contas grandes ou agências que gerenciam muitas contas e pedidos.',
    ordersPerMonth: 20000,
    extraOrderPrice: 0.11,
    extraOrderPriceQuarter: 0.094,
    extraOrderPriceSemiannual: 0.083,
    marketingProvider: 0,
    members: 0,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: undefined,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: true,
      maxMembers: undefined,
      maxFacebookProfiles: undefined,
      maxWorkflows: undefined,
      maxMessagePerWorkflow: undefined,
      maxWhatsappInstances: undefined,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Enterprise',
    identifier: 'enterprise_six_monthly_10_off_v1',
    quarterIdentifier: 'enterprise_six_quarterly_10_off_v1',
    semiAnualIdentifier: 'enterprise_six_semiannual_10_off_v1',
    monthlyPrice: 2697.3,
    quarterMonthlyPrice: 2292.71,
    quarterPrice: 6878.13,
    semesterMonthlyPrice: 1910.59,
    semesterPrice: 11463.54,
    anualPrice: 0,
    description: 'Para contas grandes ou agências que gerenciam muitas contas e pedidos.',
    ordersPerMonth: 80000,
    extraOrderPrice: 0.072,
    extraOrderPriceQuarter: 0.061,
    extraOrderPriceSemiannual: 0.051,
    marketingProvider: 0,
    members: 0,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
      'Suporte prioritário',
      'Onboarding personalizado',
    ],
    benefits: {
      maxAdsIntegrations: undefined,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: true,
      maxMembers: undefined,
      maxFacebookProfiles: undefined,
      maxWorkflows: undefined,
      maxMessagePerWorkflow: undefined,
      maxWhatsappInstances: undefined,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Starter',
    identifier: 'starter_monthly_10_off_v2',
    quarterIdentifier: 'starter_quarterly_10_off_v1',
    semiAnualIdentifier: 'starter_semiannual_10_off_v1',
    monthlyPrice: 62.1,
    quarterMonthlyPrice: 52.79,
    quarterPrice: 158.36,
    semesterMonthlyPrice: 43.99,
    semesterPrice: 263.93,
    anualPrice: 62.1,
    description: 'Para aqueles que estão explorando novos canais de venda.',
    ordersPerMonth: 250,
    extraOrderPrice: 0.3,
    extraOrderPriceQuarter: 0.255,
    extraOrderPriceSemiannual: 0.225,
    marketingProvider: 2,
    members: 2,
    facebookProfile: 2,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
    ],
    benefits: {
      maxAdsIntegrations: 2,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: false,
      maxMembers: 2,
      maxFacebookProfiles: 2,
      maxWorkflows: 2,
      maxMessagePerWorkflow: 2,
      maxWhatsappInstances: 1,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Essentials',
    identifier: 'essentials_monthly_10_off_v2',
    quarterIdentifier: 'essentials_quarterly_10_off_v1',
    semiAnualIdentifier: 'essentials_semiannual_10_off_v1',
    monthlyPrice: 132.3,
    quarterMonthlyPrice: 112.46,
    quarterPrice: 337.37,
    semesterMonthlyPrice: 93.71,
    semesterPrice: 562.28,
    anualPrice: 132.3,
    description: 'Para aqueles que estão se preparando para tracionar suas vendas.',
    ordersPerMonth: 650,
    extraOrderPrice: 0.27,
    extraOrderPriceQuarter: 0.23,
    extraOrderPriceSemiannual: 0.203,
    marketingProvider: 3,
    members: 3,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
    ],
    benefits: {
      maxAdsIntegrations: 3,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: true,
      maxMembers: 3,
      maxFacebookProfiles: undefined,
      maxWorkflows: 4,
      maxMessagePerWorkflow: 4,
      maxWhatsappInstances: 2,
      maxWhatsappMessagesSent: undefined,
    },
  },
  {
    name: 'Premium',
    identifier: 'premium_monthly_10_off_v2',
    quarterIdentifier: 'premium_quarterly_10_off_v1',
    semiAnualIdentifier: 'premium_semiannual_10_off_v1',
    monthlyPrice: 240.3,
    quarterMonthlyPrice: 204.26,
    quarterPrice: 612.77,
    semesterMonthlyPrice: 170.21,
    semesterPrice: 1021.28,
    anualPrice: 240.3,
    description: 'Para aqueles estão escalando para chegar no próximo nível.',
    ordersPerMonth: 1250,
    extraOrderPrice: 0.22,
    extraOrderPriceQuarter: 0.187,
    extraOrderPriceSemiannual: 0.165,
    marketingProvider: 0,
    members: 0,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
    ],
    benefits: {
      maxAdsIntegrations: undefined,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: true,
      maxMembers: undefined,
      maxFacebookProfiles: undefined,
      maxWorkflows: 10,
      maxMessagePerWorkflow: 10,
      maxWhatsappInstances: 2,
      maxWhatsappMessagesSent: undefined,
    },
  },
];

const UNLIMITED_PLANS = [
  {
    name: 'Unlimited',
    identifier: 'partners_v1',
    quarterIdentifier: 'partners_v1',
    semiAnualIdentifier: 'partners_v1',
    monthlyPrice: 0,
    quarterMonthlyPrice: 0,
    quarterPrice: 0,
    semesterMonthlyPrice: 0,
    semesterPrice: 0,
    anualPrice: 0,
    description: 'Para aqueles estão escalando para chegar no próximo nível.',
    ordersPerMonth: 0,
    extraOrderPrice: 0,
    extraOrderPriceQuarter: 0,
    extraOrderPriceSemiannual: 0,
    marketingProvider: 0,
    members: 0,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
    ],
    benefits: {
      maxAdsIntegrations: undefined,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: true,
      maxMembers: undefined,
      maxFacebookProfiles: undefined,
      maxWorkflows: undefined,
      maxMessagePerWorkflow: undefined,
      maxWhatsappInstances: undefined,
      maxWhatsappMessagesSent: undefined,
    },
  },
];

const ZOUTI_PLANS = [
  {
    name: 'Zouti',
    identifier: 'zouti_v1',
    quarterIdentifier: 'zouti_v1',
    semiAnualIdentifier: 'zouti_v1',
    monthlyPrice: 0,
    quarterMonthlyPrice: 0,
    quarterPrice: 0,
    semesterMonthlyPrice: 0,
    semesterPrice: 0,
    anualPrice: 0,
    description: 'Para aqueles estão escalando para chegar no próximo nível.',
    ordersPerMonth: 0,
    extraOrderPrice: 0,
    extraOrderPriceQuarter: 0,
    extraOrderPriceSemiannual: 0,
    marketingProvider: 0,
    members: 0,
    facebookProfile: 0,
    features: [
      'Dashboard de previsibilidade financeira',
      'Exportar para excel',
      'Análise de desempenho por produto',
      'Gerenciador de anúncios Profitfy',
      'Assessor dedicado',
    ],
    benefits: {
      maxAdsIntegrations: undefined,
      exportFinancialDashboardSpreadSheet: true,
      productAnalytics: true,
      maxMarketplacesIntegrations: 2,
      adsManager: true,
      maxMembers: undefined,
      maxFacebookProfiles: undefined,
      maxWorkflows: undefined,
      maxMessagePerWorkflow: undefined,
      maxWhatsappInstances: undefined,
      maxWhatsappMessagesSent: undefined,
    },
  },
];

export const ALL_PLANS = [
  ...OLD_PLANS_V1,
  ...OLD_PLANS_V2,
  ...PLANS,
  ...ENTERPRISE_PLANS,
  ...UNLIMITED_PLANS,
  ...ZOUTI_PLANS,
];
