import React from 'react';

import { INavItem } from '@domain/interfaces/components/ISidebar';
import { ETextWeight } from '@domain/enums/components/EText';

import { useMediaQueryHeight } from '@helpers/hooks/useMediaQuery';

import * as S from './styles';

const NavItem: React.FC<INavItem> = ({
  onClick,
  menuRoute,
  selected = false,
  isSidebarExpanded,
  ...rest
}) => {
  const { icon: Icon, name } = menuRoute;

  const isSmallDeviceHeight = useMediaQueryHeight('700px');

  const [isHoveringMainMenu, setIsHoveringMainMenu] = React.useState<boolean>(false);

  const handleMainMenuHoverLeave = React.useCallback(() => setIsHoveringMainMenu(false), []);

  const handleMainMenuHoverEnter = React.useCallback(() => setIsHoveringMainMenu(true), []);

  const getIconSize = React.useCallback(() => {
    if (isSmallDeviceHeight) return 18;

    return 24;
  }, [isSmallDeviceHeight]);

  const isCampaignItem = name === 'Campanhas';

  return (
    <S.Item
      onClick={() => onClick(menuRoute)}
      onMouseEnter={handleMainMenuHoverEnter}
      onMouseLeave={handleMainMenuHoverLeave}
      {...rest}
    >
      <S.Wrapper
        isHovering={isHoveringMainMenu}
        isSelected={selected}
        isSidebarExpanded={isSidebarExpanded}
      >
        <Icon size={getIconSize()} weight="bold" />

        <S.Text selected={selected} weight={ETextWeight.MEDIUM} {...rest}>
          {name}
        </S.Text>

        {isCampaignItem && <S.NewText>Beta</S.NewText>}
      </S.Wrapper>
    </S.Item>
  );
};

export default React.memo(NavItem);
