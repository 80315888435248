import React from 'react';

import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { numberFormatter } from '@helpers/masks';

import BarChart from './BarChart';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const PaymentMethodsOverview: React.FC = () => {
  const {
    isLoadingFinancialDashboardProvider,
    isValidatingFinancialDashboardProvider,
    isLoadingFinancialDashboardPromise,
    financialDetailsReport,
  } = useFinancialDashboard();

  if (
    isLoadingFinancialDashboardProvider ||
    isValidatingFinancialDashboardProvider ||
    isLoadingFinancialDashboardPromise
  ) {
    return <SkeletonLoading />;
  }

  const {
    boleto_percentage,
    card_percentage,
    pix_percentage,
  } = financialDetailsReport.payment_method;

  return (
    <S.Card>
      <S.PaymentMethodsOverview>
        <S.PaymentMethod>
          <S.Percentage>{`${numberFormatter(card_percentage, 1)}%`}</S.Percentage>
          <S.LabelWrapper>
            <S.CreditCardBullet />
            <S.Label>Cartão</S.Label>
          </S.LabelWrapper>
        </S.PaymentMethod>

        <S.PaymentMethod>
          <S.Percentage>{`${numberFormatter(boleto_percentage, 1)}%`}</S.Percentage>
          <S.LabelWrapper>
            <S.BoletoBullet />
            <S.Label>Boleto</S.Label>
          </S.LabelWrapper>
        </S.PaymentMethod>

        <S.PaymentMethod>
          <S.Percentage>{`${numberFormatter(pix_percentage, 1)}%`}</S.Percentage>
          <S.LabelWrapper>
            <S.PixBullet />
            <S.Label>Pix</S.Label>
          </S.LabelWrapper>
        </S.PaymentMethod>
      </S.PaymentMethodsOverview>

      <S.ChartWrapper>
        <BarChart />
      </S.ChartWrapper>
    </S.Card>
  );
};

export default PaymentMethodsOverview;
