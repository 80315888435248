/* eslint-disable no-console */
/* eslint-disable no-alert */
import React from 'react';

import io, { Socket } from 'socket.io-client';

import { useConfig } from '@helpers/hooks/useConfig';

import { WHATSAPP_CONFIG_API_DOMAIN } from '@constants/api';

export const useSocket = (): Record<string, Socket> => {
  const { authorization } = useConfig();

  const socket = React.useMemo(
    () =>
      io(WHATSAPP_CONFIG_API_DOMAIN, {
        auth: { token: authorization },
        transports: ['websocket'],
        withCredentials: true,
      }),
    [authorization],
  );

  React.useEffect((): any => {
    socket.on('connect', () => {
      console.log(`[SOCKET] Connect => A new connect: ${socket}`);
    });

    return () => socket.off('connect');
  }, [socket]);

  React.useEffect((): any => {
    socket.on('disconnect', () => {
      console.log(`[SOCKET] disconnect => ${socket}`);
    });

    return () => socket.off('disconnect');
  }, [socket]);

  return { socket };
};
