import React from 'react';
import { X as Close } from 'phosphor-react';
import { useTheme } from 'styled-components';

import {
  ESimpleShipmentType,
  ESimpleShipmentProductOptions,
  ESimpleShipmentTotalValueOptions,
} from '@domain/enums/dashboard/automations/node/conditionalNode/ESimpleShipment';

import { currencyFormatter } from '@helpers/masks';

import { IConditionalSimpleShipmentModalSelect } from '@domain/interfaces/dashboard/Automations/Canvas/Node/ConditionalNode/IConditionalSimpleShipmentModal';
import OptionProduct from './OptionProduct';
import OptionTotalValue from './OptionTotalValue';

import * as S from './styles';

const ConditionalSelect: React.FC<IConditionalSimpleShipmentModalSelect> = ({
  isDynamic,
  value,
  index,
  onUpdate,
  onDelete,
  logicalOperator,
  showLogicalOperator,
}) => {
  const { colors } = useTheme();

  const handleChangeType = React.useCallback(
    e => {
      const choosen = e.target.value as ESimpleShipmentType;

      if (choosen === ESimpleShipmentType.PRODUCT_NAME) {
        onUpdate(
          { ...value, type: choosen, options: ESimpleShipmentProductOptions.CONTAINS, input: '' },
          index,
        );
      } else if (choosen === ESimpleShipmentType.REJECTED_PAYMENT_TOTAL_VALUE) {
        onUpdate(
          { ...value, type: choosen, options: ESimpleShipmentTotalValueOptions.SMALLER, input: '' },
          index,
        );
      }
    },
    [value, index, onUpdate],
  );

  const handleChangeOptionsProduct = React.useCallback(
    name => {
      onUpdate({ ...value, options: name }, index);
    },
    [value, index, onUpdate],
  );

  const handleChangeOptionsTotalValue = React.useCallback(
    name => {
      onUpdate({ ...value, options: name }, index);
    },
    [value, index, onUpdate],
  );

  const handleChangeInput = React.useCallback(
    text => {
      onUpdate({ ...value, input: text }, index);
    },
    [value, index, onUpdate],
  );

  const handleChangeProductInput = React.useCallback(
    e => {
      handleChangeInput(e.target.value);
    },
    [handleChangeInput],
  );

  const handleChangeTotalValueInput = React.useCallback(
    e => {
      handleChangeInput(currencyFormatter(e.target.value));

      e.target.value = currencyFormatter(e.target.value);
    },
    [handleChangeInput],
  );

  const translateLogicalOperator = (operator: string): string => {
    const translated = operator === 'AND' ? 'E' : 'OU';
    return translated;
  };

  return (
    <S.Wrapper>
      <S.Row>
        <S.Select name="type" defaultValue={value.type} onChange={handleChangeType}>
          <S.Option value={ESimpleShipmentType.PRODUCT_NAME}>Nome do Produto</S.Option>
          <S.Option value={ESimpleShipmentType.REJECTED_PAYMENT_TOTAL_VALUE}>Valor Total</S.Option>
        </S.Select>

        {value.type === ESimpleShipmentType.PRODUCT_NAME && (
          <OptionProduct onChange={handleChangeOptionsProduct} defaultValue={value.options} />
        )}

        {value.type === ESimpleShipmentType.REJECTED_PAYMENT_TOTAL_VALUE && (
          <OptionTotalValue onChange={handleChangeOptionsTotalValue} />
        )}

        <S.TextFieldWrapper>
          {value.type === ESimpleShipmentType.PRODUCT_NAME && (
            <S.TextField
              type="text"
              defaultValue={value.input}
              onChange={handleChangeProductInput}
            />
          )}

          {value.type === ESimpleShipmentType.REJECTED_PAYMENT_TOTAL_VALUE && (
            <S.TextField
              type="text"
              defaultValue={value.input}
              onChange={handleChangeTotalValueInput}
            />
          )}

          {isDynamic && onDelete && (
            <S.CloseButton onClick={() => onDelete(index)}>
              <Close size={12} color={colors.gray[1]} />
            </S.CloseButton>
          )}
        </S.TextFieldWrapper>
      </S.Row>

      {showLogicalOperator && (
        <S.LogicalOperatorWrapper>
          <S.LogicalOperator>{translateLogicalOperator(logicalOperator)}</S.LogicalOperator>
        </S.LogicalOperatorWrapper>
      )}
    </S.Wrapper>
  );
};

export default ConditionalSelect;
