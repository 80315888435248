import React from 'react';

import { ISideBarProps } from '@domain/interfaces/downgrade/sideBar/ISideBar';

import logoImg from '@assets/img/brand/profitfy-logo.svg';

import ProgressBar from './ProgressBar';
import GoBack from './GoBack';

import * as S from './styles';

const SideBar: React.FC<ISideBarProps> = ({ onboardingStep, previousRouteLink }) => {
  return (
    <S.Wrapper>
      <S.Content>
        <S.Logo src={logoImg} alt="profitfy-logo" />

        <GoBack previousRouteLink={previousRouteLink} />

        <ProgressBar step={onboardingStep} />
      </S.Content>
    </S.Wrapper>
  );
};

export default SideBar;
