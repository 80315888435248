import React from 'react';

import { useForm } from 'react-hook-form';
import { Zouti as Icon, SimpleCheck } from '@profitfy/pakkun-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom';

import { EZoutiType } from '@domain/enums/common/integrations/gateways/EZouti';
import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { ETextSize } from '@domain/enums/components/EText';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useEcommerce } from '@helpers/hooks/pages/onboarding/useEcommerce';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { zoutiSchema } from '@helpers/validators/dashboard/integrationCenter/zouti';

import zoutiCredentialService from '@services/pages/dashboard/integrationCenter/gateway/zouti';

import NextStep from '@components/Onboarding/NextStep';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const ZoutiPay: React.FC = () => {
  const history = useHistory();

  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { selectedMarketings, handleNextGatewayToIntegrate } = useEcommerce();

  const [isLoadingSubmittedForm, setIsLoadingSubmittedForm] = React.useState<boolean>(false);

  const {
    handleSubmit,
    register,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ mode: 'onBlur', reValidateMode: 'onChange', resolver: yupResolver(zoutiSchema) });

  const [secretKey, publicKey] = getValues(['secret_key', 'public_key']);

  watch();

  const onSubmit = React.useCallback(
    async formData => {
      setIsLoadingSubmittedForm(true);

      try {
        await zoutiCredentialService.createCredential({
          storeAliasId,
          data: { ...formData, types: [EZoutiType.GATEWAY] },
        });

        toast.success('Credencial salva com sucesso!');

        setIsLoadingSubmittedForm(false);

        const hasNextGateway = handleNextGatewayToIntegrate();

        if (!hasNextGateway && selectedMarketings.length) {
          history.push(`/onboarding/${storeAliasId}/ecommerce/integrations/marketing`);
        }

        if (!hasNextGateway && !selectedMarketings.length) {
          history.push(`/onboarding/${storeAliasId}/ecommerce/integrations/integration-finished`);
        }
      } catch (error: any) {
        setIsLoadingSubmittedForm(false);
        toast.error(getTranslatedIntegrationsError(error));
      }
    },
    [history, handleNextGatewayToIntegrate, storeAliasId, selectedMarketings, toast],
  );

  const hasAllInputsValue = secretKey && publicKey;

  return (
    <S.Wrapper>
      <S.FormWrapper>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Header>
            <S.Title>Integre com Gateway de pagamento</S.Title>
          </S.Header>

          <S.IntegrationTitle>
            <Icon size={36} />

            <S.IntegrationTitleText fontWeight={EHeadingWeight.REGULAR}>
              Integração com Zouti
            </S.IntegrationTitleText>
          </S.IntegrationTitle>

          <S.Body>
            <S.BenefitsWrapper>
              <S.Benefit>
                <SimpleCheck size={12} />
                <S.DescriptionText size={ETextSize.B5}>Exatidão dos dados</S.DescriptionText>
              </S.Benefit>

              <S.Benefit>
                <SimpleCheck size={12} />
                <S.DescriptionText size={ETextSize.B5}>Confiabilidade dos dados</S.DescriptionText>
              </S.Benefit>
            </S.BenefitsWrapper>

            <S.FunctionalityDescriptionText>
              Saiba mais sobre a Zouti{' '}
              <S.ZoutiLink href="https://zouti.com.br" target="_blank">
                clicando aqui.
              </S.ZoutiLink>
            </S.FunctionalityDescriptionText>

            <S.InputGroup>
              <S.Label htmlFor="zouti-secret-key">Chave secreta</S.Label>

              <S.TextField
                {...register('secret_key')}
                type="text"
                value={secretKey}
                placeholder="Chave secreta da Zouti"
                id="zouti-secret-key"
                isError={errors.secret_key}
              />
              {errors.secret_key && <Text isErrorFeedback>{errors.secret_key.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label htmlFor="zouti-public-key">Chave pública</S.Label>

              <S.TextField
                {...register('public_key')}
                type="text"
                value={publicKey}
                placeholder="Chave pública da Zouti"
                id="zouti-public-key"
                isError={errors.public_key}
              />
              {errors.public_key && <Text isErrorFeedback>{errors.public_key.message}</Text>}
            </S.InputGroup>
          </S.Body>

          <NextStep
            type="submit"
            isLoading={isLoadingSubmittedForm}
            disabled={!hasAllInputsValue || isLoadingSubmittedForm}
          >
            Próximo passo
          </NextStep>
        </S.Form>
      </S.FormWrapper>

      <S.TutorialWrapper>
        <S.TutorialImage
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/onboarding/images/content/onboarding/onboarding-integration-tutorial.svg"
          alt="Profitfy.me Integration Tutorial"
        />
        <S.TutorialTitle>Tutorial de integração</S.TutorialTitle>

        <S.TutorialList>
          <S.TutorialStep>Acesse sua conta Zouti;</S.TutorialStep>

          <S.TutorialStep>
            Na barra lateral esquerda, clique em <strong>&quot;configurações&quot;</strong>;
          </S.TutorialStep>

          <S.TutorialStep>
            Clique em <strong>&quot;Credenciais de API&quot;</strong>;
          </S.TutorialStep>

          <S.TutorialStep>
            Copie os campos <strong>&quot;Chave Secreta&quot;</strong> e{' '}
            <strong>&quot;Chave Pública&quot;</strong>;
          </S.TutorialStep>

          <S.TutorialStep>Cole nos respectivos campos ao lado.</S.TutorialStep>
        </S.TutorialList>
      </S.TutorialWrapper>
    </S.Wrapper>
  );
};

export default ZoutiPay;
