import styled from 'styled-components/macro';

import SideModalBase from '@components/common/core/DataDisplay/SideModal';

export const SideModal = styled(SideModalBase)`
  max-width: 588px;
  width: 100%;
  box-sizing: border-box;
  padding: 0;

  & > div:first-child {
    padding: 0;
  }
`;
