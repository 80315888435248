import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import Text from '@components/common/core/DataDisplay/Text';
import { useEditKit } from '@helpers/hooks/pages/dashboard/kit/useEditKit';

import * as S from './styles';

const KitNameAndStatus: React.FC<any> = ({ register, errors }) => {
  const { variantKit, handleKitActive } = useEditKit();

  const onSwitchChange = React.useCallback(
    event => {
      handleKitActive(event.target.checked);
    },
    [handleKitActive],
  );

  return (
    <S.Wrapper>
      <S.InputGroup>
        <S.Title fontWeight={EHeadingWeight.REGULAR} type={EHeadingSize.H5}>
          Status do kit
        </S.Title>

        <S.Switch
          id="edit-kit"
          name="edit-kit"
          defaultChecked={variantKit.is_active}
          onChange={onSwitchChange}
          disabled={false}
        />
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>Nomeie o kit</S.Label>
        <S.Input
          {...register('kit_name')}
          type="text"
          placeholder="Ex. Kit Teste"
          defaultValue={variantKit.name}
        />
        {errors.kit_name && <Text isErrorFeedback>{errors.kit_name.message}</Text>}
      </S.InputGroup>
    </S.Wrapper>
  );
};

export default KitNameAndStatus;
