import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';
import ImageBase from '@components/common/core/DataDisplay/Image';

import TopLeftImgDetail from '@assets/dashboard/benefitArea/details2.svg';
import BottomRightImgDetail from '@assets/dashboard/benefitArea/details1.svg';

export const Container = styled.section`
  position: relative;
  width: calc(100% + 72px);
  background: linear-gradient(253.21deg, #0b0d11 4.75%, #000000 71.62%);
  padding-top: 96px;
  padding-bottom: 96px;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 52px;
    left: 52px;
    width: 103px;
    height: 75px;
    background-image: url(${TopLeftImgDetail});
    background-repeat: no-repeat;

    @media only screen and (max-width: 670px) {
      display: none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 52px;
    right: 52px;
    width: 50px;
    height: 174px;
    background-image: url(${BottomRightImgDetail});
    background-repeat: no-repeat;

    @media only screen and (max-width: 670px) {
      display: none;
    }
  }

  @media only screen and (max-width: 420px) {
    padding-bottom: 60px;
  }

  @media only screen and (max-height: 768px) {
    padding-top: 20px;
    padding-bottom: 30px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 691px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 860px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const Image = styled(ImageBase)`
  margin-bottom: 32px;
  max-width: 296px;
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 32px;
  text-align: center;
  font-size: 2.25rem;
  line-height: 130%;
  color: #ffffff;

  mark {
    color: #9b6afa;
    background: unset;
  }
`;

export const Description = styled(TextBase)`
  width: 100%;
  max-width: 603px;
  color: ${({ theme }) => theme.colors.gray[3]};
  font-size: 1.125rem;
  line-height: 150%;
  margin-bottom: 32px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media only screen and (max-width: 462px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const KnowZendropButton = styled(ButtonBase)``;

export const OportunityButton = styled(ButtonBase)``;
