import React from 'react';

import { ETabOption } from '@domain/enums/dashboard/storeSubscription/ETabOptions';

import { useStoreSubscription } from '@helpers/hooks/pages/dashboard/storeSubscription/useStoreSubscription';

import Subscription from '../Subscription';
import Plans from '../Plans';

import Tab from '../Tab';

import * as S from './styles';

export const Wrapper: React.FC = () => {
  const { handleSelectedTab, selectedTab } = useStoreSubscription();

  const getMaxWidth = React.useCallback(() => {
    if (selectedTab === ETabOption.SUBSCRIPTION) return '1024px';

    return '1386px';
  }, [selectedTab]);

  return (
    <S.Wrapper style={{ maxWidth: getMaxWidth() }}>
      <Tab selectedOption={selectedTab} handleOption={handleSelectedTab} />

      {selectedTab === ETabOption.SUBSCRIPTION && <Subscription />}

      {selectedTab === ETabOption.PLANS && <Plans />}
    </S.Wrapper>
  );
};

export default Wrapper;
