import styled, { css } from 'styled-components/macro';

import CardBase from '@components/common/core/Surfaces/Card';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import {
  IPercentageProps,
  IProfitValueProps,
} from '@domain/interfaces/dashboard/ProductAnalytics/IProductDetails';

export const ChartWrapper = styled.div`
  height: 76px;
  width: 100%;
`;

export const ComparedPeriodText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
  margin-bottom: 16px;
  display: block;
`;

export const PercentageValue = styled(TextBase)<IPercentageProps>`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.danger.default};
  display: flex;
  gap: 4px;
  align-items: center;
  line-height: 24px;

  ${({ isPositive, theme }) =>
    isPositive &&
    css`
      color: ${theme.colors.success.default};
    `}
`;

export const PercentageArrow = styled.div<IPercentageProps>`
  margin-right: 4px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid ${({ theme }) => theme.colors.danger.default};

  ${({ isPositive, theme }) =>
    isPositive &&
    css`
      border-top: 0;
      border-bottom: 6px solid ${theme.colors.success.default};
    `}
`;

export const PercentageWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ProfitValue = styled(TextBase)<IProfitValueProps>`
  font-size: 2rem;
  font-weight: 500;
  line-height: 120%;

  ${({ isPositive, theme }) =>
    isPositive &&
    css`
      color: ${theme.colors.success.default};
    `}

  ${({ isNegative, theme }) =>
    isNegative &&
    css`
      color: ${theme.colors.danger.default};
    `}
`;

export const ProfitValueAndPercentageWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-end;
`;

export const Title = styled(HeadingBase)``;

export const Card = styled(CardBase)`
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border: unset;

  @media only screen and (max-width: 1124px) {
    margin-bottom: 24px;
  }
`;
