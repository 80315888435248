import React from 'react';
import { useTheme } from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { ChevronDown } from '@profitfy/pakkun-icons';

import { EBadgeColors } from '@domain/enums/components/EBadge';
import { ETextSize } from '@domain/enums/components/EText';
import { IParams } from '@domain/interfaces/IParams';

import { PREDEFINED_ADM_GROUP_PERMISSIONS } from '@constants/accessManagement';

import NextStep from './NextStep';
import Return from '../Return';

import * as S from './styles';

const SelectGroup: React.FC = () => {
  const theme = useTheme();
  const { storeAliasId } = useParams<IParams>();
  const history = useHistory();

  const [, setSelectedGroupToCreate] = React.useState<string>('common_member_group');
  const [
    isDefaultGroupPermissionsVisible,
    setIsDefaultGroupPermissionsVisible,
  ] = React.useState<boolean>(true);

  const onRadioChange = React.useCallback(event => {
    setSelectedGroupToCreate(event.target.value);
  }, []);

  const handleDefaultGroupPermissionsVisible = React.useCallback(() => {
    setIsDefaultGroupPermissionsVisible(!isDefaultGroupPermissionsVisible);
  }, [isDefaultGroupPermissionsVisible]);

  const onCreateNewAccessGroupButtonClick = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/access-manager/onboarding/new-group`);
  }, [history, storeAliasId]);

  const nextStep = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/access-manager/onboarding/invite`);
  }, [history, storeAliasId]);

  return (
    <S.Container>
      <Return />

      <S.ContentWrapper>
        <S.Heading>Deseja criar um novo grupo?</S.Heading>
        <S.Description>Você também pode selecionar um dos grupos existentes.</S.Description>

        <S.GroupAndPermissionsWrapper>
          <S.DefaultGroup isPermissionsVisible={isDefaultGroupPermissionsVisible}>
            <S.DefaultGroupContent>
              <S.Radio
                name="group-type"
                value="admin-group"
                defaultChecked
                onChange={onRadioChange}
                id="admin-option"
              />

              <S.TextAndBadgeWrapper onClick={handleDefaultGroupPermissionsVisible}>
                <S.DefaultGroupText>Administradores</S.DefaultGroupText>
                <S.Badge color={EBadgeColors.PRIMARY}>Padrão</S.Badge>
              </S.TextAndBadgeWrapper>

              <S.ChevronIconWrapper>
                <ChevronDown size={24} color={theme.colors.gray[1]} />
              </S.ChevronIconWrapper>
            </S.DefaultGroupContent>

            {isDefaultGroupPermissionsVisible && <S.HorizontalRule />}
          </S.DefaultGroup>

          {isDefaultGroupPermissionsVisible && (
            <S.PermissionsWrapper>
              {PREDEFINED_ADM_GROUP_PERMISSIONS.map(permission => (
                <S.Permission key={permission} size={ETextSize.B5}>
                  {permission}
                </S.Permission>
              ))}
            </S.PermissionsWrapper>
          )}
        </S.GroupAndPermissionsWrapper>

        <S.CreateNewAccessGroupButton onClick={onCreateNewAccessGroupButtonClick}>
          Criar novo grupo de acesso
        </S.CreateNewAccessGroupButton>

        <NextStep onClick={nextStep}>Próximo passo</NextStep>
      </S.ContentWrapper>
    </S.Container>
  );
};

export default SelectGroup;
