import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Enter } from '@profitfy/pakkun-icons';
import { useHistory, useParams } from 'react-router-dom';

import Text from '@components/common/core/DataDisplay/Text';
import Form from '@components/common/core/Inputs/Form';
import { resetPasswordSchema } from '@helpers/validators/resetPassword';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import logoImg from '@assets/img/brand/profitfy-logo.svg';
import resetPasswordService from '@services/pages/auth/resetPassword';
import { useToast } from '@helpers/hooks/useToast';
import { IParams } from '@domain/interfaces/IParams';

import * as S from './styles';

const ResetPassword: React.FC = () => {
  const { resetPasswordToken } = useParams<IParams>();
  const history = useHistory();
  const { toast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(resetPasswordSchema),
  });

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [isResettingPasswordSuccess, setIsResettingPasswordSuccess] = React.useState<boolean>(
    false,
  );

  const onSubmit = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      try {
        await resetPasswordService.resetPassword({
          token: resetPasswordToken || '',
          data: {
            password: formData.password,
            password_confirmation: formData.confirmPassword,
          },
        });

        toast.success('Senha redefinida com sucesso!');

        setIsResettingPasswordSuccess(true);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsSubmittingForm(false);
      }
    },
    [toast, resetPasswordToken],
  );

  React.useEffect(() => {
    if (!resetPasswordToken || isResettingPasswordSuccess) {
      history.push('/');
      setIsResettingPasswordSuccess(false);
    }
  }, [resetPasswordToken, isResettingPasswordSuccess, history]);

  return (
    <S.Container>
      <S.Wrapper>
        <S.Logo src={logoImg} alt="Profitfy.me Logo" />

        <S.ContentWrapper>
          <S.Heading type={EHeadingSize.H3} fontWeight={EHeadingWeight.REGULAR}>
            Redefinir senha
          </S.Heading>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <S.InputGroup>
              <S.Label>Nova senha</S.Label>
              <S.Input
                {...register('password')}
                name="password"
                type="password"
                placeholder="Digite a senha"
                data-openreplay-obscured
              />
              {errors.password && <Text isErrorFeedback>{errors.password.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Confirmar senha</S.Label>
              <S.Input
                {...register('confirmPassword')}
                name="confirmPassword"
                type="password"
                placeholder="Digite novamente a senha"
                data-openreplay-obscured
              />
              {errors.confirmPassword && (
                <Text isErrorFeedback>{errors.confirmPassword.message}</Text>
              )}
            </S.InputGroup>

            <S.Button
              type="submit"
              iconPrepend={Enter}
              isLoading={isSubmittingForm}
              disabled={isSubmittingForm}
              iconPrependOutline
            >
              Redefinir senha
            </S.Button>
          </Form>
        </S.ContentWrapper>
      </S.Wrapper>
    </S.Container>
  );
};

export default ResetPassword;
