import styled from 'styled-components/macro';

import ButtonBase from '@components/common/core/Inputs/Button';

export const Button = styled(ButtonBase)``;

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding: 16px 0;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray[5]};
`;
