import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import InputBase from '@components/common/core/Inputs/TextField';

export const Wrapper = styled(ModalBase)`
  width: 100%;
  max-width: 442px;
  height: 100%;
  max-height: 80vh;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  width: 100%;
  height: 90%;
  padding: 32px 32px 10px 32px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GroupTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled(TextBase)`
  font-size: 1.25rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
  margin-left: 8px;
`;

export const CloseButton = styled.button`
  background: unset;
  padding: unset;
  border: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Description = styled(TextBase)`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[2]};
  margin-top: 24px;
  margin-bottom: 15px;
`;

export const CardList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 13px;

  scrollbar-color: ${({ theme }) => theme.colors.gray[4]} ${({ theme }) => theme.colors.gray[6]};
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[4]};
  }
`;

export const SuggestionContainer = styled.div`
  position: absolute;
  bottom: 0px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  width: 100%;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.colors.gray[5]};
  padding: 0 32px;
  box-sizing: border-box;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
`;

export const SuggestionButton = styled.button`
  background: unset;
  padding: unset;
  border: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const SuggestionText = styled(TextBase)`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.green.default};
`;

export const Input = styled(InputBase)`
  & > button {
    background-color: ${({ theme }) => theme.colors.background[2]};
  }

  & > div {
    background-color: ${({ theme }) => theme.colors.gray[5]};
  }
`;
