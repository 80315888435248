import * as yup from 'yup';

export const zoutiSchema = yup.object().shape({
  secret_key: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(10, 'Informe uma credencial válida')
    .max(200, 'Informe uma credencial válida'),
  public_key: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(10, 'Informe uma credencial válida')
    .max(200, 'Informe uma credencial válida'),
});
