import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Text = styled(TextBase)``;

export const Wrapper = styled.div`
  max-width: 767px;
  width: 100%;
`;
