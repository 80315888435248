import React from 'react';
import { Percent, Bag } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { ECalculateMode, ERegime } from '@domain/enums/dashboard/tax/ETax';
import {
  ESynchronizationRequestType,
  ESynchronizationType,
} from '@domain/enums/hooks/ESynchronization';

import { useTax } from '@helpers/hooks/pages/dashboard/tax/useTax';
import { useDate } from '@helpers/hooks/useDate';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import { taxSchema } from '@helpers/validators/dashboard/tax/tax';
import { currencyFormatter, currencyToNumber, numberFormatter } from '@helpers/masks';
import { IParams } from '@domain/interfaces/IParams';
import { getEnvironment } from '@helpers/utils/environment';
import { LONG_API_DOMAIN } from '@constants/api';

import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';
import SideModalSave from '@components/common/core/Utils/SideModalSave';
import WithAdsModal from '../WithAdsModal';

import * as S from './styles';

const HistoricSidebar: React.FC = () => {
  const {
    handleEditTaxSideModalOpen,
    isEditTaxSideModalOpen,
    userPeriodTax,
    isUpdatingHistoricTax,
    updateTaxPeriod,
    updateDefaultTax,
    handleUpdateWithAdsModalOpen,
    handleDefaultTaxData,
  } = useTax();

  const { addSynchronization } = useSynchronization();
  const { storeAliasId } = useParams<IParams>();

  const { utcToZonedTime, format } = useDate();
  const theme: any = useTheme();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(taxSchema),
  });

  const aliquotInput = register('aliquot');

  const [period, setPeriod] = React.useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const onSubmit = React.useCallback(
    async data => {
      if (userPeriodTax?.is_default && data.calculate_mode === ECalculateMode.WITH_ADS) {
        handleUpdateWithAdsModalOpen();
        handleDefaultTaxData({
          calculate_mode: data.calculate_mode,
          name: 'any_tax_name',
          percentage_amount: currencyToNumber(data.aliquot),
          regime: data.regime,
        });

        return;
      }

      if (userPeriodTax?.is_default) {
        await updateDefaultTax({
          calculate_mode: data.calculate_mode,
          name: 'any_tax_name',
          percentage_amount: currencyToNumber(data.aliquot),
          regime: data.regime,
        });

        handleEditTaxSideModalOpen();

        return;
      }

      await updateTaxPeriod({
        calculate_mode: data.calculate_mode,
        name: 'any_tax_name',
        percentage_amount: currencyToNumber(data.aliquot),
        regime: data.regime,
        start_date: format(utcToZonedTime(period.startDate), 'yyyy-MM-dd'),
        end_date: format(utcToZonedTime(period.endDate), 'yyyy-MM-dd'),
      });

      const params = `?start_date=${format(
        utcToZonedTime(period.startDate),
        'yyyy-MM-dd',
      )}&end_date=${format(utcToZonedTime(period.endDate), 'yyyy-MM-dd')}`;

      addSynchronization({
        name: 'Impostos',
        type: ESynchronizationType.STORE_GATEWAY,
        label: 'Pedidos',
        dependencies: [ESynchronizationType.STORE_PRODUCT],
        storeAliasId,
        config: {
          isOnboardingSynchronization: false,
          showNotification: true,
        },
        request: {
          type: ESynchronizationRequestType.POST,
          domain: LONG_API_DOMAIN,
          api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/orders/tax/sync`,
          requestParams: params,
        },
      });

      handleEditTaxSideModalOpen();
    },
    [
      format,
      period,
      addSynchronization,
      storeAliasId,
      utcToZonedTime,
      handleEditTaxSideModalOpen,
      updateTaxPeriod,
      updateDefaultTax,
      userPeriodTax,
      handleDefaultTaxData,
      handleUpdateWithAdsModalOpen,
    ],
  );

  React.useEffect(() => {
    if (userPeriodTax && userPeriodTax.start_date && userPeriodTax.end_date) {
      setPeriod({
        startDate: utcToZonedTime(userPeriodTax.start_date),
        endDate: utcToZonedTime(userPeriodTax.end_date),
      });
    }
  }, [userPeriodTax, utcToZonedTime]);

  const maxDate = utcToZonedTime(new Date());

  return (
    <>
      <WithAdsModal />
      {userPeriodTax && (
        <S.Sidemodal isOpen={isEditTaxSideModalOpen} toggle={handleEditTaxSideModalOpen}>
          <S.Header>
            <Percent size={36} color={theme.colors.green.default} outline />
            <S.Heading>Configurar Imposto</S.Heading>
          </S.Header>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <S.InputGroup>
              <S.Label>CNAE</S.Label>
              <S.Input {...register('cnae')} type="text" prepend={Bag} placeholder="Opcional" />
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Regra de tributação</S.Label>
              <S.Select {...register('calculate_mode')} defaultValue={userPeriodTax.calculate_mode}>
                {userPeriodTax.is_default && (
                  <S.Option value={ECalculateMode.WITH_ADS}>
                    Receita Líquida - Custo do Produto - Custo de Marketing
                  </S.Option>
                )}
                <S.Option value={ECalculateMode.WITH_COGS}>
                  Receita Líquida - Custo do Produto
                </S.Option>
                <S.Option value={ECalculateMode.WITHOUT_COGS}>Receita Líquida</S.Option>
              </S.Select>
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Regime de tributação</S.Label>
              <S.Select {...register('regime')} defaultValue={userPeriodTax.regime}>
                <S.Option value={ERegime.SIMPLES_NACIONAL}>Simples Nacional</S.Option>
                <S.Option value={ERegime.LUCRO_PRESUMIDO}>Lucro Presumido</S.Option>
                <S.Option value={ERegime.LUCRO_REAL}>Lucro Real</S.Option>
              </S.Select>
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Alíquota</S.Label>

              <S.Input
                {...aliquotInput}
                type="text"
                prepend={Bag}
                placeholder="Inserir valor"
                defaultValue={numberFormatter(userPeriodTax.percentage_amount, 2)}
                onChange={event => {
                  aliquotInput.onChange(event);

                  const { value } = event.target;

                  event.target.value = currencyFormatter(value);
                }}
              />
              {errors.aliquot && <Text isErrorFeedback>{errors.aliquot.message}</Text>}
            </S.InputGroup>

            {userPeriodTax.start_date && userPeriodTax.end_date && (
              <S.CustomDatePicker period={period} setPeriod={setPeriod} maxDate={maxDate} />
            )}

            <SideModalSave
              successButtonText="Salvar"
              cancelButtonText="Cancelar"
              type="submit"
              onCancel={handleEditTaxSideModalOpen}
              isLoading={isUpdatingHistoricTax}
              isDisabled={isUpdatingHistoricTax}
            />
          </Form>
        </S.Sidemodal>
      )}
    </>
  );
};

export default HistoricSidebar;
