export enum ECouponType {
  PRODUCT_NAME = 'PRODUCT_NAME',
  REJECTED_PAYMENT_TOTAL_VALUE = 'REJECTED_PAYMENT_TOTAL_VALUE',
}

export enum ECouponProductOptions {
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  NOT_EQUAL = 'NOT_EQUAL',
}

export enum ECouponTotalValueOptions {
  SMALLER = 'SMALLER',
  SMALLER_EQUAL = 'SMALLER_EQUAL',
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  LARGER = 'LARGER',
  LARGER_EQUAL = 'LARGER_EQUAL',
}

export enum ECouponOrderStatusOptions {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
}

export enum ECouponOrderStatusPicker {
  PAID = 'PAID',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
  CHARGED_BACK = 'CHARGED_BACK',
  IN_ANALYSIS = 'IN_ANALYSIS',
  REJECTED = 'REJECTED',
}

export type CouponOptions<Type> = Type extends ECouponType.PRODUCT_NAME
  ? ECouponProductOptions
  : Type extends ECouponType.REJECTED_PAYMENT_TOTAL_VALUE
  ? ECouponTotalValueOptions
  : ECouponOrderStatusOptions;
