import React from 'react';

import { useSuggestions } from '@helpers/hooks/pages/dashboard/ideasChannel/useSuggestions';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';

import SkeletonLoading from './SkeletonLoading';

import Vote from './Vote';
import Description from './Description';

import * as S from './styles';

const Idea: React.FC = () => {
  const { suggestions, isLoadingSuggestions, isValidatingSuggestions } = useSuggestions();

  const suggestionsSkeleton = Array.from(Array(20).keys());

  const isLoading = isLoadingSuggestions || isValidatingSuggestions;

  if (isLoading) {
    return (
      <>
        {suggestionsSkeleton.map(element => (
          <SkeletonLoading key={element} />
        ))}
      </>
    );
  }

  return (
    <>
      {suggestions?.map(suggestion => {
        return (
          <S.IdeaWrapper key={suggestion.id}>
            <Vote suggestions={suggestion} />

            <Description suggestions={suggestion} />
          </S.IdeaWrapper>
        );
      })}

      {!suggestions?.length && (
        <S.NoSuggestionInfo type={EHeadingSize.H6} fontWeight={EHeadingWeight.REGULAR}>
          Nenhuma sugestão encontrada.
        </S.NoSuggestionInfo>
      )}
    </>
  );
};

export default Idea;
