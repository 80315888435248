import React from 'react';
import { WarningCircle } from 'phosphor-react';
import { Search } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useParams, useHistory } from 'react-router-dom';

import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { EButtonVariant } from '@domain/enums/components/EButton';
import { IAdSenseCredential } from '@domain/interfaces/dashboard/AdSense/IAdSenseCredential';
import { IParams } from '@domain/interfaces/IParams';

import { useDebounce } from '@helpers/hooks/useDebounce';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { ALL_PLANS } from '@constants/plans';

import adSenseCredentialService from '@services/pages/dashboard/adSense/adSenseCredential';

import AdSenseCredentialOption from './AdSenseCredentialOption';

import * as S from './styles';

const Modal: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { subscription } = useStoreSubscription();
  const { mutateStore } = useStoreConfig();

  const subscriptionPlanIdentifier = subscription.plan?.identifier as string;

  const storePlan = ALL_PLANS.find(
    plan => plan.identifier === subscriptionPlanIdentifier,
  ) as typeof ALL_PLANS[number];

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isLoadingCredentials, setIsLoadingCredentials] = React.useState<boolean>(false);
  const [adSenseCredentials, setAdSenseCredentials] = React.useState<Array<IAdSenseCredential>>([]);
  const [selectedCredentials, setSelectedCredentials] = React.useState<Array<IAdSenseCredential>>(
    [],
  );
  const [credentialToSearch, setCredentialToSearch] = React.useState<string>('');
  const [isUpdatingCredentials, setIsUpdatingCredentials] = React.useState<boolean>(false);

  const notBlockedByDowngradeAdSenseCredentials = adSenseCredentials.filter(
    adSenseCredential => !adSenseCredential.is_blocked_by_downgrade,
  );

  const debouncedCredentialToSearch = useDebounce(credentialToSearch, 500);

  const onSearchInputChange = React.useCallback(event => {
    setCredentialToSearch(event.target.value.toLowerCase());
  }, []);

  const updateSelectedCredential = React.useCallback(
    (selectedCredential: IAdSenseCredential) => {
      const currentSelectedCredentials = [...selectedCredentials];

      const foundCredentialIndex = currentSelectedCredentials.findIndex(
        credential => credential.id === selectedCredential.id,
      );

      if (foundCredentialIndex >= 0) {
        currentSelectedCredentials.splice(foundCredentialIndex, 1);
      } else {
        if (selectedCredentials.length >= storePlan.facebookProfile) {
          toast.error(
            'Você já selecionou a quantidade máxima de perfis possível para o seu plano atual',
          );

          return;
        }

        currentSelectedCredentials.push(selectedCredential);
      }

      setSelectedCredentials(currentSelectedCredentials);
    },
    [selectedCredentials, storePlan, toast],
  );

  const onSeePlansClick = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/subscription`);
  }, [history, storeAliasId]);

  const onUpdateCredentialsClick = React.useCallback(async () => {
    const notSelectedCredentials = adSenseCredentials.filter(
      adSenseCredential =>
        !selectedCredentials.find(
          selectedCredential => selectedCredential.id === adSenseCredential.id,
        ),
    );

    const adSenseCredentialIds = notSelectedCredentials.map(credential => credential.id);

    setIsUpdatingCredentials(true);

    try {
      await adSenseCredentialService.blockAdSenseCredentials({
        storeAliasId,
        data: { ad_sense_credential_ids: adSenseCredentialIds },
      });

      setIsUpdatingCredentials(false);

      mutateStore();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);

      setIsUpdatingCredentials(false);
    }
  }, [adSenseCredentials, selectedCredentials, storeAliasId, toast, mutateStore]);

  const toggle = React.useCallback(() => {
    return null;
  }, []);

  const loadFacebookCredentials = React.useCallback(async () => {
    setIsLoadingCredentials(true);

    try {
      const { data } = await adSenseCredentialService.listAdSenseCredentialsPromise({
        storeAliasId,
        page: 1,
        filter: { provider: EAdSenseProvider.FACEBOOK },
      });

      setAdSenseCredentials(data.ad_sense_credentials);
    } finally {
      setIsLoadingCredentials(false);
    }
  }, [storeAliasId]);

  React.useEffect(() => {
    loadFacebookCredentials();
  }, [loadFacebookCredentials]);

  React.useEffect(() => {
    if (
      !isLoadingCredentials &&
      storePlan &&
      storePlan?.facebookProfile !== 0 &&
      notBlockedByDowngradeAdSenseCredentials.length > storePlan?.facebookProfile
    ) {
      setIsOpen(true);
    }
  }, [isLoadingCredentials, notBlockedByDowngradeAdSenseCredentials, storePlan]);

  const selectedCredentialsText =
    storePlan?.facebookProfile > 1
      ? `${selectedCredentials.length}/${storePlan.facebookProfile} Perfis Selecionados`
      : `${selectedCredentials.length}/1 Perfil Selecionado`;

  const filteredAdSenseCredentials = notBlockedByDowngradeAdSenseCredentials.filter(
    adSenseCredential => adSenseCredential.name.toLowerCase().includes(debouncedCredentialToSearch),
  );

  return (
    <S.FacebookModal isOpen={isOpen} toggle={toggle}>
      <S.FacebookModal.Header>
        <WarningCircle size={24} color={theme.colors.warning.default} />
        <S.Title>Quantidade de perfis excedido</S.Title>
      </S.FacebookModal.Header>
      <S.FacebookModal.Body>
        <S.Description>
          A sua loja atingiu o limite máximo de perfis do facebook. Selecione quais perfis você
          deseja manter para poder continuar ou faça um upgrade para continuar com seus perfis
          atuais.
        </S.Description>

        <S.SearchInputAndAdSenseCredentialWrapper>
          <S.SearchInput
            type="string"
            placeholder="Procurar perfil"
            prepend={Search}
            onChange={onSearchInputChange}
          />

          <S.AdSenseCredentialsWrapper>
            {filteredAdSenseCredentials.map(adSenseCredential => (
              <AdSenseCredentialOption
                key={adSenseCredential.id}
                adSenseCredential={adSenseCredential}
                updateSelectedCredential={updateSelectedCredential}
                selectedAdSenseCredentials={selectedCredentials}
              />
            ))}
          </S.AdSenseCredentialsWrapper>

          <S.SelectedProfilesText>{selectedCredentialsText}</S.SelectedProfilesText>
        </S.SearchInputAndAdSenseCredentialWrapper>

        <S.ButtonsWrapper>
          <S.UpdateCredentialsButton
            variant={EButtonVariant.SECONDARY_BUTTON}
            onClick={onUpdateCredentialsClick}
            isLoading={isUpdatingCredentials}
          >
            Manter os perfis selecionados
          </S.UpdateCredentialsButton>
          <S.SeePlansButton onClick={onSeePlansClick} disabled={isUpdatingCredentials}>
            Visualizar planos
          </S.SeePlansButton>
        </S.ButtonsWrapper>
      </S.FacebookModal.Body>
    </S.FacebookModal>
  );
};

export default Modal;
