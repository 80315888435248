import React from 'react';
import { PlayCircle, PauseCircle } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { IEditSelectContentProps } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IAdSenseCampaignsActions';

import { useCampaigns } from '@helpers/hooks/pages/dashboard/adsManager/useCampaigns';
import { useToast } from '@helpers/hooks/useToast';
import { useAdsManager } from '@helpers/hooks/pages/dashboard/adsManager/useAdsManager';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import * as S from './styles';

const EditSelectContent: React.FC<IEditSelectContentProps> = ({ setIsEditSelectOpen }) => {
  const {
    handleDeactivateCampaignsModalOpen,
    handleActivateCampaignsModalOpen,
    selectedCampaigns,
  } = useCampaigns();
  const { toast } = useToast();
  const theme = useTheme();
  const { handleUtmSidemodalOpen } = useAdsManager();
  const { store } = useStoreConfig();

  const [isHoveringActivateButton, setIsHoveringActivateButton] = React.useState<boolean>(false);
  const [isHoveringDeactivateButton, setIsHoveringDeactivateButton] = React.useState<boolean>(
    false,
  );

  const onActivateButtonMouseEnter = React.useCallback(() => setIsHoveringActivateButton(true), []);

  const onActivateButtonMouseLeave = React.useCallback(
    () => setIsHoveringActivateButton(false),
    [],
  );

  const onDeactivateButtonMouseEnter = React.useCallback(
    () => setIsHoveringDeactivateButton(true),
    [],
  );

  const onDeactivateButtonMouseLeave = React.useCallback(
    () => setIsHoveringDeactivateButton(false),
    [],
  );

  const onActivateClick = React.useCallback(() => {
    if (selectedCampaigns.length === 0) {
      toast.error('Selecione pelo menos uma campanha para poder ativar.');
    } else {
      setIsEditSelectOpen(false);
      handleActivateCampaignsModalOpen();
    }
  }, [toast, selectedCampaigns, handleActivateCampaignsModalOpen, setIsEditSelectOpen]);

  const onDeactivateClick = React.useCallback(() => {
    if (selectedCampaigns.length === 0) {
      toast.error('Selecione pelo menos uma campanha para poder desativar.');
    } else {
      setIsEditSelectOpen(false);
      handleDeactivateCampaignsModalOpen();
    }
  }, [toast, selectedCampaigns, handleDeactivateCampaignsModalOpen, setIsEditSelectOpen]);

  const onAddUtmsClick = React.useCallback(() => {
    setIsEditSelectOpen(false);
    handleUtmSidemodalOpen();
  }, [handleUtmSidemodalOpen, setIsEditSelectOpen]);

  return (
    <S.Content side="bottom" sideOffset={5}>
      <S.ButtonsWrapper>
        <S.ActionButton
          onClick={onActivateClick}
          onMouseEnter={onActivateButtonMouseEnter}
          onMouseLeave={onActivateButtonMouseLeave}
        >
          <PlayCircle
            size={18}
            color={isHoveringActivateButton ? theme.colors.green.default : theme.colors.font}
          />
          Ativar
        </S.ActionButton>

        <S.ActionButton
          onClick={onDeactivateClick}
          onMouseEnter={onDeactivateButtonMouseEnter}
          onMouseLeave={onDeactivateButtonMouseLeave}
        >
          <PauseCircle
            size={18}
            color={isHoveringDeactivateButton ? theme.colors.green.default : theme.colors.font}
          />
          Pausar
        </S.ActionButton>

        {store?.is_utm_onboarding_finished && (
          <S.ActionButton onClick={onAddUtmsClick}>
            <S.UtmBadge>UTM</S.UtmBadge>
            Adicionar UTMs não existentes
          </S.ActionButton>
        )}
      </S.ButtonsWrapper>

      <S.Arrow />
    </S.Content>
  );
};

export default EditSelectContent;
