import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { ECurrency } from '@domain/enums/common/ECurrency';
import {
  IEditKitProvider,
  IEditKitVariant,
  IInitialVariantKit,
} from '@domain/interfaces/dashboard/Kit/editKit';
import kitService from '@services/pages/dashboard/kit/kit';
import variantKitItemService from '@services/pages/dashboard/kit/variantKitItem';
import { IParams } from '@domain/interfaces/IParams';
import { useToast } from '@helpers/hooks/useToast';
import { currencyToNumber } from '@helpers/masks';

const EditKitContext = React.createContext<IEditKitProvider | null>(null);

export const EditKitProvider: React.FC = ({ children }) => {
  const { storeAliasId, variantKitAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const history = useHistory();

  const {
    variantKit,
    isLoading: isLoadingVariantKit,
    isValidating: isValidatingVariantKit,
    mutate: mutateVariantKit,
    error: variantKitError,
  } = kitService.getKit({ storeAliasId, variantKitAliasId });

  const [editKitVariants, setEditKitVariants] = React.useState<Array<IEditKitVariant>>([]);
  const [kitCurrency, setKitCurrency] = React.useState<ECurrency>(ECurrency.USD);
  const [isKitActive, setIsKitActive] = React.useState<boolean>(true);
  const [isUpdatingKit, setIsUpdatingKit] = React.useState<boolean>(false);
  const [isSyncModalOpen, setIsSyncModalOpen] = React.useState<boolean>(false);
  const [isSyncSideModalOpen, setIsSyncSideModalOpen] = React.useState<boolean>(false);
  const [initialVariantKit, setInitialVariantKit] = React.useState<Array<IInitialVariantKit>>([]);

  const handleSyncModalOpen = React.useCallback(() => setIsSyncModalOpen(!isSyncModalOpen), [
    isSyncModalOpen,
  ]);

  const handleSyncSideModalOpen = React.useCallback(
    () => setIsSyncSideModalOpen(!isSyncSideModalOpen),
    [isSyncSideModalOpen],
  );

  const mutate = React.useCallback(async () => {
    const promises = [];

    if (variantKitError) {
      promises.push(mutateVariantKit());
    }

    await Promise.all(promises);
  }, [variantKitError, mutateVariantKit]);

  const handleEditKitVariants = React.useCallback(variants => {
    setEditKitVariants(variants);
  }, []);

  const addVariant = React.useCallback(
    (editKitVariant: IEditKitVariant) => {
      handleEditKitVariants([...editKitVariants, editKitVariant]);
    },
    [editKitVariants, handleEditKitVariants],
  );

  const handleKitCurrency = React.useCallback(currency => setKitCurrency(currency), []);

  const handleKitActive = React.useCallback(status => setIsKitActive(status), []);

  const getEditedVariantQuantity = React.useCallback(
    id => {
      const foundEditedVariant = editKitVariants.find(editKitVariant => editKitVariant.id === id);

      return foundEditedVariant?.quantity;
    },
    [editKitVariants],
  );

  const getVariantsKitToDelete = React.useCallback(() => {
    const filteredVariants = initialVariantKit.filter(variantKitItem => {
      const foundEditedVariantKit = editKitVariants.find(
        editKitVariant => editKitVariant.id === variantKitItem.id,
      );

      if (foundEditedVariantKit) return false;

      return true;
    });

    return filteredVariants;
  }, [editKitVariants, initialVariantKit]);

  const getVariantsKitItemToUpdate = React.useCallback(() => {
    const filteredVariantKitItemsToUpdate = initialVariantKit.filter(variantKitItem => {
      const foundEditedVariantKit = editKitVariants.find(editKitVariant => {
        const isSameId = editKitVariant.id === variantKitItem.id;

        return isSameId;
      });

      if (foundEditedVariantKit) return true;

      return false;
    });

    const mappedFilteredVariantsToUpdate = filteredVariantKitItemsToUpdate.map(
      filteredVariantKitItem => {
        const quantity = getEditedVariantQuantity(filteredVariantKitItem.id) || 0;

        return {
          id: filteredVariantKitItem.variantKitItemId,
          quantity,
          variant_id: filteredVariantKitItem?.variant?.id || '',
        };
      },
    );

    return mappedFilteredVariantsToUpdate;
  }, [editKitVariants, getEditedVariantQuantity, initialVariantKit]);

  const getVariantsKitItemToCreate = React.useCallback(() => {
    const filteredVariantKitItemsToCreate = editKitVariants.filter(editKitVariant => {
      const foundVariantKitItem = initialVariantKit.find(
        variantKitItem => variantKitItem.id === editKitVariant.id,
      );

      if (foundVariantKitItem) return false;

      return true;
    });

    const mappedVariantKitItemsToCreate = filteredVariantKitItemsToCreate.map(
      filteredVariantKitItem => {
        return {
          quantity: filteredVariantKitItem.quantity,
          variant_id: filteredVariantKitItem.variant?.id || '',
        };
      },
    );

    return mappedVariantKitItemsToCreate;
  }, [editKitVariants, initialVariantKit]);

  const updateKit = React.useCallback(
    async data => {
      setIsUpdatingKit(true);

      try {
        const variantsKitToDelete = getVariantsKitToDelete();

        if (variantsKitToDelete.length) {
          const promises = variantsKitToDelete.map(variantKitToDelete => {
            return variantKitItemService.deleteVariantKitItem({
              storeAliasId,
              variantKitAliasId,
              variantKitItemAliasId: variantKitToDelete.variantKitAliasId || '',
            });
          });

          await Promise.all(promises);
        }

        const mappedVariantKitItemsToUpdate = getVariantsKitItemToUpdate();
        const mappedVariantKitItemsToCreate = getVariantsKitItemToCreate();

        await kitService.updateKit({
          storeAliasId,
          variantKitAliasId,
          data: {
            name: data.kit_name,
            cost: currencyToNumber(data.kit_cost),
            currency: kitCurrency,
            is_active: isKitActive,
            variant_kit_items: [...mappedVariantKitItemsToUpdate, ...mappedVariantKitItemsToCreate],
          },
        });

        setIsUpdatingKit(false);

        toast.success('Kit atualizado com sucesso!');

        if (isKitActive) {
          handleSyncModalOpen();
        } else {
          history.goBack();
        }
      } catch (error: any) {
        setIsUpdatingKit(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [
      toast,
      getVariantsKitToDelete,
      kitCurrency,
      storeAliasId,
      variantKitAliasId,
      getVariantsKitItemToCreate,
      getVariantsKitItemToUpdate,
      isKitActive,
      handleSyncModalOpen,
      history,
    ],
  );

  React.useEffect(() => {
    if (variantKit) {
      const mappedVariantKit = variantKit.variant_kit_items.map(variantItem => {
        return {
          variant: variantItem.variant,
          quantity: variantItem.quantity,
          id: uuidv4(),
          variantKitItemId: variantItem.id,
          variantKitAliasId: variantItem.alias_id,
        };
      });

      setIsKitActive(variantKit.is_active);
      setKitCurrency(variantKit.currency);
      setEditKitVariants(mappedVariantKit);
      setInitialVariantKit(mappedVariantKit);
    }
  }, [variantKit]);

  const isLoadingEditKit = isLoadingVariantKit;
  const isValidatingEditKit = isValidatingVariantKit;
  const isEditKitError = Boolean(variantKitError);

  return (
    <EditKitContext.Provider
      value={{
        addVariant,
        editKitVariants,
        handleEditKitVariants,
        handleKitActive,
        handleKitCurrency,
        isKitActive,
        kitCurrency,
        isEditKitError,
        isLoadingEditKit,
        isValidatingEditKit,
        mutate,
        variantKit,
        updateKit,
        getVariantsKitItemToCreate,
        getVariantsKitItemToUpdate,
        getVariantsKitToDelete,
        isUpdatingKit,
        isSyncModalOpen,
        isSyncSideModalOpen,
        handleSyncModalOpen,
        handleSyncSideModalOpen,
      }}
    >
      {children}
    </EditKitContext.Provider>
  );
};

export const useEditKit = (): IEditKitProvider => {
  const context = React.useContext(EditKitContext);

  if (!context) {
    throw new Error('useEditKit must be used within EditKitContext');
  }

  return context;
};
