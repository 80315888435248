import React from 'react';
import { useParams } from 'react-router-dom';

import { IFeedCustomValuesBillingProvider } from '@domain/interfaces/dashboard/Feed/ICustomValueBilling';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import feedService from '@services/pages/dashboard/feed/feed';
import customValueBillingService from '@services/pages/dashboard/feed/customValueBilling';

const FeedCustomValuesBillingContext = React.createContext<IFeedCustomValuesBillingProvider | null>(
  null,
);

export const FeedCustomValuesBillingProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { analytics, user } = useConfig();
  const { mutateFeedQuantity } = useStoreConfig();

  const [isUpdatingFinancialStatus, setIsUpdatingFinancialStatus] = React.useState<boolean>(false);
  const [selectedCustomValueBillings, setSelectedCustomValueBillings] = React.useState<
    Array<string>
  >([]);

  const {
    customValueBillings,
    mutate: mutateCustomValueBillings,
    isLoading: isLoadingCustomValueBillings,
    isValidating: isValidatingCustomValueBillings,
    error: customValueBillingError,
  } = feedService.listFeedCustomValueBillings({ storeAliasId });

  const updateStatusToPaid = React.useCallback(async () => {
    setIsUpdatingFinancialStatus(true);

    try {
      if (selectedCustomValueBillings.length) {
        await customValueBillingService.updateFinancialStatus({
          storeAliasId,
          data: { custom_value_billing_ids: selectedCustomValueBillings },
        });

        toast.success('Status alterado com sucesso.');

        analytics?.track(
          'Has Updated Custom Value Status To Paid',
          { email: user.email },
          { context: { groupId: storeAliasId } },
        );

        mutateCustomValueBillings();
        mutateFeedQuantity();

        setIsUpdatingFinancialStatus(false);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsUpdatingFinancialStatus(false);
    }
  }, [
    selectedCustomValueBillings,
    toast,
    storeAliasId,
    mutateCustomValueBillings,
    mutateFeedQuantity,
    analytics,
    user,
  ]);

  const handleSelectedCustomValueBillings = React.useCallback(newSelectedCustomValueBillings => {
    setSelectedCustomValueBillings(newSelectedCustomValueBillings);
  }, []);

  const handleSelectAllCustomValueBillings = React.useCallback(
    status => {
      if (status) {
        const customValueBillingsAliasId = customValueBillings.map(
          customValueBilling => customValueBilling.id,
        );

        setSelectedCustomValueBillings(customValueBillingsAliasId);
      } else {
        setSelectedCustomValueBillings([]);
      }
    },
    [customValueBillings],
  );

  return (
    <FeedCustomValuesBillingContext.Provider
      value={{
        handleSelectedCustomValueBillings,
        selectedCustomValueBillings,
        handleSelectAllCustomValueBillings,
        customValueBillings,
        customValueBillingError,
        isLoadingCustomValueBillings,
        isValidatingCustomValueBillings,
        mutateCustomValueBillings,
        isUpdatingFinancialStatus,
        updateStatusToPaid,
      }}
    >
      {children}
    </FeedCustomValuesBillingContext.Provider>
  );
};

export const useFeedCustomValueBillings = (): IFeedCustomValuesBillingProvider => {
  const context = React.useContext(FeedCustomValuesBillingContext);

  if (!context) {
    throw new Error(
      'useFeedCustomValueBillings must be used within FeedCustomValuesBillingContext',
    );
  }

  return context;
};
