import React from 'react';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';
import { IEduzzCredentialOptionProps } from '@domain/interfaces/dashboard/IntegrationCenter/InfoProduct/IEduzz';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useToast } from '@helpers/hooks/useToast';
import { useParams } from 'react-router-dom';

import eduzzService from '@services/pages/dashboard/integrationCenter/infoProduct/platforms/eduzz';

import DisableIntegrationFooter from '@components/Dashboard/IntegrationCenter/Sidemodal/DisableIntegrationFooter';

import DeleteCredentialModal from '@components/Dashboard/IntegrationCenter/SideModalContent/DeleteCredentialModal';
import * as S from './styles';

const CredentialOption: React.FC<IEduzzCredentialOptionProps> = ({ credential }) => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { mutateStore } = useStoreConfig();

  const [isDeletingCredential, setIsDeletingCredential] = React.useState<boolean>(false);
  const [isDeleteCredentialModalOpen, setIsDeleteCredentialModalOpen] = React.useState<boolean>(
    false,
  );

  const handleDeleteCredentialModalOpen = React.useCallback(
    () => setIsDeleteCredentialModalOpen(state => !state),
    [],
  );

  const onDeleteCredential = React.useCallback(async () => {
    setIsDeletingCredential(true);

    try {
      await eduzzService.updateCredential({
        storeAliasId,
        credentialAliasId: credential.alias_id,
        data: {
          api_key: credential.api_key,
          origin_key: credential.origin_key,
          public_key: credential.public_key,
          is_active: false,
        },
      });

      toast.success('Credencial desativada com sucesso!');
      handleDeleteCredentialModalOpen();

      mutateStore();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsDeletingCredential(false);
    }
  }, [toast, mutateStore, storeAliasId, credential, handleDeleteCredentialModalOpen]);

  return (
    <S.Content>
      <S.CredentialDescriptionWrapper>
        <S.CredentialDescriptionTitle type={EHeadingSize.H5}>
          O que acontece se eu desativar minha integração?
        </S.CredentialDescriptionTitle>

        <S.CredentialDescriptionText>
          Ao desativar a integração,
          <strong> não será mais possível </strong>
          processar os pedidos da Eduzz.
        </S.CredentialDescriptionText>
      </S.CredentialDescriptionWrapper>

      <DisableIntegrationFooter
        onDeleteCredential={handleDeleteCredentialModalOpen}
        isDeletingCredential={isDeletingCredential}
      />

      <DeleteCredentialModal
        isOpen={isDeleteCredentialModalOpen}
        toggle={handleDeleteCredentialModalOpen}
        onDeleteCredential={onDeleteCredential}
        isLoading={isDeletingCredential}
      />
    </S.Content>
  );
};

export default CredentialOption;
