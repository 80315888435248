import React from 'react';

import { ITooltip } from '@domain/interfaces/components/ITooltip';

import * as S from './styles';
import 'rc-tooltip/assets/bootstrap_white.css';

const Tooltip: React.FC<ITooltip> = ({ content, children, ...rest }) => {
  const wrapperRef = React.useRef<any>(null);

  const getTooltipContainer = React.useCallback(() => wrapperRef.current || undefined, []);

  return (
    <S.Wrapper ref={wrapperRef} {...rest}>
      <S.Tooltip
        placement="top"
        trigger={['hover']}
        overlay={content}
        getTooltipContainer={getTooltipContainer}
      >
        {children}
      </S.Tooltip>
    </S.Wrapper>
  );
};

export default Tooltip;
