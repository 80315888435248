import React from 'react';

import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import { ISelfTestWorkflow, IWorkflows } from '@domain/interfaces/common/automations/IWorkflows';
import { ISelectedFilter } from '@domain/interfaces/components/IFilter';
import { IAutomationManager } from '@domain/interfaces/dashboard/Automations/AutomationsManager/IAutomationManager';

import { useDate } from '@helpers/hooks/useDate';
import { useToast } from '@helpers/hooks/useToast';
import { sliceIntoChunks } from '@helpers/utils/common/chunk';
import { filterString } from '@helpers/utils/common/filter';
import { filterWorkflowsData } from '@helpers/utils/dashboard/automations/AutomationsManager/automations';

import { EModalStep } from '@domain/enums/common/EStepsModal';
import { ETriggers } from '@domain/enums/dashboard/automations/ETriggers';
import { IWorkflow as IWorkflowBackend } from '@domain/interfaces/mappers/automations/profitfyBackend/IWorkflow';
import { currencyToNumber } from '@helpers/masks';
import automationsService from '@services/pages/dashboard/automations/automations';

const AutomationsManagerContext = React.createContext<IAutomationManager | null>(null);

const WORKFLOW_TABLE_LIMIT = 10;

export const AutomationsManagerProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();
  const { subDays } = useDate();
  const { toast } = useToast();

  const [page, setPage] = React.useState<number>(0);
  const [name, setName] = React.useState<string>('');
  const [filterData, setFilterData] = React.useState<Array<ISelectedFilter>>([]);

  const [isDeletingWorkflow, setIsDeletingWorkflow] = React.useState<boolean>(false);
  const [isSavingTestAutomation, setIsSavingTestAutomation] = React.useState<boolean>(false);
  const [selectedWorkflowToTest, setSelectedWorkflowToTest] = React.useState<
    IWorkflowBackend | undefined
  >();
  const [isTestSideModalOpen, setIsTestSideModalOpen] = React.useState<boolean>(false);
  const [formDataTestAutomation, setFormDataTestAutomation] = React.useState<any>({});
  const [testAutomationStep, setTestAutomationStep] = React.useState<EModalStep>(
    EModalStep.FIRST_STEP,
  );
  const [
    selectedWorkflowTriggerType,
    setSelectedWorkflowTriggerType,
  ] = React.useState<ETriggers | null>(null);

  const {
    workflow_metrics,
    workflows,
    isLoading: isLoadingWorkflows,
    isValidating: isValidatingWorkflows,
    mutate: mutateWorkflows,
    error: workflowsError,
  } = automationsService.listWorkflows({
    storeAliasId,
    endDate: new Date(),
    startDate: subDays(new Date(), 7),
  });

  const handleWorkflowSearch = React.useCallback(workflowName => setName(workflowName), []);

  const onPageChange = React.useCallback(({ selected }) => setPage(selected), []);

  const handleFilterData = React.useCallback(newData => {
    setFilterData(newData);
  }, []);

  const handleTestSideModalOpen = React.useCallback(() => {
    setIsTestSideModalOpen(state => !state);
  }, []);

  const handleSetFormValues = React.useCallback(values => {
    setFormDataTestAutomation((prevValues: any) => ({
      ...prevValues,
      ...values,
    }));
  }, []);

  const handleTestAutomationStep = React.useCallback(step => setTestAutomationStep(step), []);
  const handleTestAutomationStepReset = React.useCallback(
    () => setTestAutomationStep(EModalStep.FIRST_STEP),
    [],
  );

  const handleSelectedAutomationToTest = React.useCallback(workflow => {
    setSelectedWorkflowToTest(workflow);
    setSelectedWorkflowTriggerType(workflow.type);
  }, []);

  const saveTestAutomation = React.useCallback(
    async values => {
      setIsSavingTestAutomation(true);

      const payload: ISelfTestWorkflow = {
        workflow_test_data: {
          currency: 'BRL',
          type: selectedWorkflowToTest?.type,
          customer: {
            email: values.email,
            name: `${values.first_name} ${values.last_name}`,
            phone: values.phone.replace(/\D+/g, ''),
          },
          product: {
            name: values.product,
            quantity: values.quantity,
            total_amount: currencyToNumber(values.order_value),
          },
          workflow: {
            id: selectedWorkflowToTest?.id || '',
            is_rejected_payment: selectedWorkflowToTest?.type === ETriggers.REJECTED_PAYMENT,
            type: selectedWorkflowToTest?.type as ETriggers,
          },
          address: {
            city: values.city,
            address: `${values.street}, ${values.street_number}`,
            neighborhood: values.neighborhood,
            country: 'Brazil',
            country_code: 'BR',
            state: values.state,
            zip_code: values.zip_code,
          },
        },
      };

      try {
        await automationsService.selfTestWorkflow({
          storeAliasId,
          data: payload,
        });

        toast.success(`Teste realizado com sucesso!`);
        setIsSavingTestAutomation(false);
        handleTestSideModalOpen();
        handleTestAutomationStepReset();
      } catch (error: any) {
        setIsSavingTestAutomation(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [
      handleTestAutomationStepReset,
      handleTestSideModalOpen,
      selectedWorkflowToTest,
      storeAliasId,
      toast,
    ],
  );

  React.useEffect(() => {
    if (name) {
      setPage(0);
    }
  }, [name]);

  const filteredWorkflowsChunk = filterWorkflowsData(workflows, filterData);

  const chunkedWorkflows = sliceIntoChunks<IWorkflows>(
    filteredWorkflowsChunk,
    WORKFLOW_TABLE_LIMIT,
  );

  const pageCount = chunkedWorkflows ? chunkedWorkflows.length : 1;

  const filteredWorkflows = filteredWorkflowsChunk
    ? filteredWorkflowsChunk.filter(workflow => filterString(workflow.workflow.name, name))
    : [];

  const deleteWorkflow = React.useCallback(
    async workflowAliasId => {
      setIsDeletingWorkflow(true);

      try {
        await automationsService.deleteWorkflow({
          storeAliasId,
          workflowAliasId,
        });

        toast.success('Workflow excluído com sucesso!');

        await mutateWorkflows();
      } catch (error) {
        toast.error('Ocorreu um problema ao excluir o workflow! Por favor, tente novamente.');
      } finally {
        setIsDeletingWorkflow(false);
      }
    },
    [mutateWorkflows, toast, storeAliasId],
  );

  return (
    <AutomationsManagerContext.Provider
      value={{
        filterData,
        handleFilterData,
        name,
        handleWorkflowSearch,
        page,
        pageCount,
        onPageChange,
        workflows: filteredWorkflows,
        workflow_metrics,
        isDeletingWorkflow,
        deleteWorkflow,
        isLoadingWorkflows,
        isValidatingWorkflows,
        workflowsError,
        mutateWorkflows,
        handleTestSideModalOpen,
        isTestSideModalOpen,
        handleSetFormValues,
        formDataTestAutomation,
        testAutomationStep,
        handleTestAutomationStep,
        isSavingTestAutomation,
        handleSelectedAutomationToTest,
        saveTestAutomation,
        selectedWorkflowTriggerType,
      }}
    >
      {children}
    </AutomationsManagerContext.Provider>
  );
};

export const useAutomationsManager = (): IAutomationManager => {
  const context = React.useContext(AutomationsManagerContext);

  if (!context) {
    throw new Error('useAutomationsManager must be used within AutomationsManagerProvider');
  }

  return context;
};
