/* eslint-disable import/no-duplicates */
import React from 'react';
import { WarningCircle } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { formatDistanceToNow } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import { useFacebookCredentials } from '@helpers/hooks/common/integrations/marketing/facebook/useFacebookCredentials';
import { useDate } from '@helpers/hooks/useDate';
import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

interface INotOperationalModalProps {
  isOpen: boolean;
  toggle: () => void;
  handleIsAuthenticateModalOpen: () => void;
}

const NotOperationalModal: React.FC<INotOperationalModalProps> = ({
  isOpen,
  toggle,
  handleIsAuthenticateModalOpen,
}) => {
  const theme = useTheme();
  const { adSenseCredentials } = useFacebookCredentials();
  const { utcToZonedTime } = useDate();
  const { analytics, user } = useConfig();

  const onClick = React.useCallback(() => {
    analytics?.track('Marketing Non-Operational Status Button Clicked', {
      type: 'conectar perfil',
      email: user?.email,
    });

    handleIsAuthenticateModalOpen();

    toggle();
  }, [toggle, handleIsAuthenticateModalOpen, analytics, user]);

  const notOperationalCredentials = adSenseCredentials.filter(
    credential => !credential.is_operational,
  );

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Header>
        <WarningCircle size={24} color={theme.colors.danger.default} />
        <S.Title>Ajuste o(s) seu(s) perfil(is) do Facebook</S.Title>
      </S.Header>

      <S.Body>
        <S.Description>
          Os seguintes perfis estão com status <strong>não operacional</strong>. Conecte-se
          novamente à esses perfis para garantir o funcionamento correto.
        </S.Description>

        <S.NotOperationalCredentialsWrapper>
          {notOperationalCredentials.map(credential => {
            const lastTimeUpdated = new Date(credential.created_at);

            const connectedAt = `Conectado há ${formatDistanceToNow(
              utcToZonedTime(lastTimeUpdated),
              {
                locale: pt,
              },
            )}`;

            return (
              <S.NotOperationalCredential>
                <S.Name>{credential.name}</S.Name>
                <S.ConnectedAt>{connectedAt}</S.ConnectedAt>
              </S.NotOperationalCredential>
            );
          })}
        </S.NotOperationalCredentialsWrapper>

        <S.ConnectProfileButton onClick={onClick}>Conectar perfil</S.ConnectProfileButton>
      </S.Body>
    </S.Modal>
  );
};

export default NotOperationalModal;
