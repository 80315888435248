import {
  IFinancialReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresMarketingValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresMarketingValues = (
  currentSelectedStoreFinancialReportData: IFinancialReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresMarketingValuesResponse => {
  const currentSelectedStoreMarketingTotalAmountValue =
    currentSelectedStoreFinancialReportData?.marketing?.total_amount;

  const currentSelectedStoreMarketingManualAmountValue =
    currentSelectedStoreFinancialReportData?.marketing?.manual_amount;

  const currentSelectedStoreMarketingGrowthValue =
    currentSelectedStoreFinancialReportData?.marketing?.growth;

  const storesMarketingTotalAmountValues = selectedStoresData.map(
    value => value.financial_report?.marketing?.total_amount,
  );

  const storesMarketingManualAmountValues = selectedStoresData.map(
    value => value.financial_report?.marketing?.manual_amount,
  );

  const storesMarketingGrowthAmountValues = selectedStoresData.map(
    value => value.financial_report?.marketing?.growth,
  );

  const marketingTotalAmountValues = [
    ...storesMarketingTotalAmountValues,
    currentSelectedStoreMarketingTotalAmountValue,
  ];

  const marketingManualAmountValues = [
    ...storesMarketingManualAmountValues,
    currentSelectedStoreMarketingManualAmountValue,
  ];

  const marketingGrowthValues = [
    ...storesMarketingGrowthAmountValues,
    currentSelectedStoreMarketingGrowthValue,
  ];

  const reducedMarketingTotalAmountValues = marketingTotalAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedMarketingManualAmountValues = marketingManualAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedMarketingGrowthValues = marketingGrowthValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  return {
    marketing_total_amount: reducedMarketingTotalAmountValues,
    marketing_manual_amount: reducedMarketingManualAmountValues,
    marketing_growth: reducedMarketingGrowthValues,
  };
};
