import React from 'react';
import { Warning } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { EModalType } from '@domain/enums/components/EModal';
import { IDeleteModalProps } from '@domain/interfaces/components/IDeleteModal';

import * as S from './styles';

const ConfirmDeleteModal: React.FC<IDeleteModalProps> = ({
  isOpen,
  toggle,
  onDelete,
  isLoading,
  title,
  content,
  shouldWriteDelete,
}) => {
  const theme = useTheme();

  const [inputValue, setInputValue] = React.useState<string>('');

  const onInputChange = React.useCallback(event => {
    const newValue = event.target.value;

    setInputValue(newValue);
  }, []);

  const isDisabled = React.useCallback(() => {
    if (isLoading) return true;

    if (shouldWriteDelete && inputValue !== 'deletar') return true;

    return false;
  }, [inputValue, shouldWriteDelete, isLoading]);

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Modal.Header type={EModalType.DANGER}>
        <Warning size={24} outline color={theme.colors.danger.default} />
        {title}
      </S.Modal.Header>

      <S.Modal.Body>
        <S.Text>{content}</S.Text>

        {shouldWriteDelete && (
          <S.InputGroup>
            <S.Input
              placeholder="Digite 'deletar' para continuar"
              onChange={onInputChange}
              type="text"
              name="delete"
            />
          </S.InputGroup>
        )}

        <S.Button onClick={onDelete} isLoading={isLoading} disabled={isDisabled()}>
          Excluir
        </S.Button>
      </S.Modal.Body>
    </S.Modal>
  );
};

export default ConfirmDeleteModal;
