/* eslint-disable no-restricted-syntax */
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { EGateway } from '@domain/enums/common/EGateway';
// import { EProductCosts } from '@domain/enums/common/EProductCosts';
import { EInfoProductProvider } from '@domain/enums/common/integrations/EInfoProductProvider';
import { EButtonTextColor } from '@domain/enums/components/EButton';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { EStep } from '@domain/enums/onboarding/EStep';
import { EType } from '@domain/enums/onboarding/EType';
import { EStoreType } from '@domain/enums/stores/Etype';
import { IParams } from '@domain/interfaces/IParams';
import { ISynchronizationRequest } from '@domain/interfaces/hooks/IStoreSynchronization';
import { IOnboardingStep } from '@domain/interfaces/onboarding/IOnboarding';
import { IIntegration, IStoreIntegrations } from '@domain/interfaces/stores/IStores';

import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';
import { useConfig } from '@helpers/hooks/useConfig';
import { useDate } from '@helpers/hooks/useDate';
import { useToast } from '@helpers/hooks/useToast';

import {
  DROPSHIPPING_PROVIDERS,
  ECOMMERCE_PROVIDERS,
  // ERPS,
  GATEWAYS,
  INFO_PRODUCT_PROVIDERS,
} from '@constants/common/integrations';

import onboardingStepService from '@services/pages/onboarding/onboardingStep';
import storesService from '@services/pages/stores/stores';

import * as S from './styles';

const Finished: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const { addSynchronization } = useSynchronization();
  const history = useHistory();
  const { toast } = useToast();
  const { format, utcToZonedTime, subDays } = useDate();
  const { addStep, completeStep } = useOnboarding();
  const { analytics, user } = useConfig();

  const {
    onboardingSteps,
    mutate: mutateOnboardingStep,
  } = onboardingStepService.getOnboardingSteps({ storeAliasId });

  const { integrations: storeIntegrations } = storesService.getStore({ storeAliasId });

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [hasNoHistoricToSync, setHasNoHistoricToSync] = React.useState<boolean>(false);
  const [storeType, setStoreType] = React.useState<EStoreType>(EStoreType.DROPSHIPPING);

  // const addDsersProductsSynchronization = React.useCallback(async () => {
  //   const foundCogsProviderIntegration = ERPS.find(
  //     integration => integration.identifier === EProductCosts.DSERS,
  //   ) as typeof ERPS[number];

  //   const params = `?start_date=${format(new Date(2000, 1, 1), 'yyyy-MM-dd')}&end_date=${format(
  //     utcToZonedTime(new Date()),
  //     'yyyy-MM-dd',
  //   )}`;

  //   const { generateSyncRequest } = foundCogsProviderIntegration;

  //   addSynchronization({
  //     name: foundCogsProviderIntegration.name,
  //     type: ESynchronizationType.STORE_PRODUCT,
  //     label: 'Produtos',
  //     dependencies: [ESynchronizationType.STORE_PRODUCT],
  //     storeAliasId,
  //     config: {
  //       isOnboardingSynchronization: true,
  //       showNotification: false,
  //     },
  //     request: generateSyncRequest(ESynchronizationType.STORE_PRODUCT, storeAliasId, params),
  //   });

  //   await new Promise(resolve => setTimeout(resolve, 500));
  // }, [addSynchronization, format, utcToZonedTime, storeAliasId]);

  // const addDsersOrdersSynchronization = React.useCallback(async () => {
  //   const foundCogsProviderIntegration = ERPS.find(
  //     integration => integration.identifier === EProductCosts.DSERS,
  //   ) as typeof ERPS[number];

  //   const params = `?start_date=${format(
  //     utcToZonedTime(subDays(new Date(), 30)),
  //     'yyyy-MM-dd',
  //   )}&end_date=${format(utcToZonedTime(new Date()), 'yyyy-MM-dd')}`;

  //   const { generateSyncRequest } = foundCogsProviderIntegration;

  //   addSynchronization({
  //     name: foundCogsProviderIntegration.name,
  //     type: ESynchronizationType.STORE_ORDER,
  //     label: 'Pedidos',
  //     dependencies: [ESynchronizationType.STORE_PRODUCT, ESynchronizationType.STORE_ORDER],
  //     storeAliasId,
  //     config: {
  //       isOnboardingSynchronization: true,
  //       showNotification: false,
  //     },
  //     request: generateSyncRequest(ESynchronizationType.STORE_ORDER, storeAliasId, params),
  //   });

  //   await new Promise(resolve => setTimeout(resolve, 500));
  // }, [addSynchronization, format, utcToZonedTime, storeAliasId, subDays]);

  const addStoreProviderSynchronization = React.useCallback(
    async (
      foundStoreProviderIntegration:
        | typeof DROPSHIPPING_PROVIDERS[number]
        | typeof INFO_PRODUCT_PROVIDERS[number],
    ) => {
      const params = `?start_date=${format(
        utcToZonedTime(subDays(new Date(), 30)),
        'yyyy-MM-dd',
      )}&end_date=${format(utcToZonedTime(new Date()), 'yyyy-MM-dd')}`;

      const { generateSyncRequest } = foundStoreProviderIntegration;

      const emptyRequestObject = {} as ISynchronizationRequest;

      addSynchronization({
        name: foundStoreProviderIntegration.name,
        type: ESynchronizationType.STORE_ORDER,
        label: 'Pedidos',
        dependencies: [ESynchronizationType.STORE_PRODUCT],
        storeAliasId,
        config: {
          isOnboardingSynchronization: true,
          showNotification: false,
        },
        request:
          generateSyncRequest?.(ESynchronizationType.STORE_ORDER, storeAliasId, params) ||
          emptyRequestObject,
      });

      await new Promise(resolve => setTimeout(resolve, 500));
    },
    [addSynchronization, format, utcToZonedTime, storeAliasId, subDays],
  );

  const addStoreGatewaysSynchronizations = React.useCallback(
    (
      foundStoreGatewayIntegration: typeof GATEWAYS[number],
      foundStoreProviderIntegration: typeof DROPSHIPPING_PROVIDERS[number],
    ) => {
      const gatewayIdentifier = foundStoreGatewayIntegration.identifier;

      const hasSynchronization = foundStoreProviderIntegration.gateways.includes(gatewayIdentifier);

      if (
        hasSynchronization ||
        foundStoreGatewayIntegration.isPredefinedOtherGateway ||
        foundStoreGatewayIntegration.identifier === EGateway.OTHER_GATEWAY
      ) {
        const params = `?start_date=${format(
          utcToZonedTime(subDays(new Date(), 30)),
          'yyyy-MM-dd',
        )}&end_date=${format(utcToZonedTime(new Date()), 'yyyy-MM-dd')}`;

        const { generateGatewaySyncRequest } = foundStoreProviderIntegration;

        addSynchronization({
          name: foundStoreProviderIntegration.name,
          type: ESynchronizationType.STORE_GATEWAY,
          label: foundStoreGatewayIntegration.name,
          dependencies: [ESynchronizationType.STORE_PRODUCT, ESynchronizationType.STORE_ORDER],
          storeAliasId,
          config: {
            isOnboardingSynchronization: true,
            showNotification: false,
          },
          request: generateGatewaySyncRequest(
            foundStoreGatewayIntegration.synchronizationName,
            storeAliasId,
            params,
          ),
        });
      }
    },
    [addSynchronization, format, subDays, utcToZonedTime, storeAliasId],
  );

  const getStoreFrontArray = React.useCallback((): Array<
    | typeof DROPSHIPPING_PROVIDERS[number]
    | typeof INFO_PRODUCT_PROVIDERS[number]
    | typeof ECOMMERCE_PROVIDERS[number]
  > => {
    if (storeType === EStoreType.DROPSHIPPING) return DROPSHIPPING_PROVIDERS;

    if (storeType === EStoreType.TRADITIONAL_ECOMMERCE) return ECOMMERCE_PROVIDERS;

    return INFO_PRODUCT_PROVIDERS;
  }, [storeType]);

  const handleStoreSynchronizations = React.useCallback(
    async (integrations: IStoreIntegrations) => {
      const storeFrontArray = getStoreFrontArray();

      const foundStoreProviderIntegration = storeFrontArray.find(integration => {
        const foundActiveIntegration = integrations.store_front.find(
          storeFrontIntegration => storeFrontIntegration.is_active,
        ) as IIntegration;

        return integration.identifier === foundActiveIntegration.name;
      }) as typeof DROPSHIPPING_PROVIDERS[number];

      if (foundStoreProviderIntegration) {
        // const foundStoreDsersIntegration = integrations.product_cost.find(
        //   integration => integration.name === EProductCosts.DSERS,
        // ) as IIntegration;

        const filteredActiveStoreGatewaysIntegrations = integrations.gateways.filter(
          integration => integration.is_active,
        );

        const foundOtherGateway = integrations.gateways.find(
          gateway => gateway.other_gateway?.has_other_gateway,
        );

        const otherGatewayNames = foundOtherGateway
          ? foundOtherGateway.other_gateway?.gateway_names || []
          : [];

        const hasOtherName = otherGatewayNames.includes('OTHER');

        if (hasOtherName) {
          const otherNameIndex = otherGatewayNames.indexOf('OTHER');

          otherGatewayNames.splice(otherNameIndex, 1);

          otherGatewayNames.push(EGateway.OTHER_GATEWAY);
        }

        const mappedFilteredActiveStoreGatewayIntegrations = filteredActiveStoreGatewaysIntegrations.map(
          integration => integration.name,
        );

        const gateways = [...mappedFilteredActiveStoreGatewayIntegrations, ...otherGatewayNames];

        const filteredStoreGatewaysIntegrations = GATEWAYS.filter(integration =>
          gateways.includes(integration.identifier),
        );

        // if (foundStoreDsersIntegration.is_active) {
        //   await addDsersProductsSynchronization();
        // }

        await addStoreProviderSynchronization(foundStoreProviderIntegration);

        // if (foundStoreDsersIntegration.is_active) {
        //   await addDsersOrdersSynchronization();
        // }

        for (const integration of filteredStoreGatewaysIntegrations) {
          addStoreGatewaysSynchronizations(integration, foundStoreProviderIntegration);
        }

        analytics?.track(
          'Onboarding Process Historic Button Clicked',
          {
            email: user?.email,
          },
          { context: { groupId: storeAliasId } },
        );
        analytics?.trackHubSpot(storeAliasId, { onboarding_process_historic_button_clicked: true });
      }
    },
    [
      // addDsersOrdersSynchronization,
      // addDsersProductsSynchronization,
      addStoreProviderSynchronization,
      addStoreGatewaysSynchronizations,
      analytics,
      storeAliasId,
      user,
      getStoreFrontArray,
    ],
  );

  const handleProcessingHistoryButton = React.useCallback(async () => {
    setIsLoading(true);

    try {
      const { data: dataStore } = await storesService.getStorePromise({ storeAliasId });

      const integrations = dataStore?.integrations as IStoreIntegrations;

      await handleStoreSynchronizations(integrations);

      const { data: onboardingStepsData } = await mutateOnboardingStep();

      const foundOnboardingStep:
        | IOnboardingStep
        | undefined = onboardingStepsData.onboarding_steps.find(
        (onboardingStep: IOnboardingStep) =>
          onboardingStep.configuration_step === EStep.STORE_DSERS_CONFIG,
      );

      if (foundOnboardingStep) {
        completeStep(foundOnboardingStep.id);
      }

      setIsLoading(false);

      history.push(`/${storeAliasId}/dashboard/overview/financial?processing_data=true`);
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message);
    }
  }, [
    history,
    storeAliasId,
    toast,
    handleStoreSynchronizations,
    completeStep,
    mutateOnboardingStep,
  ]);

  const handleFinishOnboardingButton = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/overview/financial?no_retroactive_data=true`);
  }, [history, storeAliasId]);

  React.useEffect(() => {
    const parsedStoreType =
      storeType === EStoreType.DROPSHIPPING ? EType.DROPSHIPPING_INTERNATIONAL : storeType;

    if (onboardingSteps) {
      const foundStep = onboardingSteps.find(
        step =>
          step.configuration_step === EStep.STORE_ONBOARDING_FINISHED &&
          step.type === parsedStoreType,
      );

      if (!foundStep) {
        addStep([
          {
            configuration_step: EStep.STORE_ONBOARDING_FINISHED,
            type: parsedStoreType,
          },
        ]);
      }
    }
  }, [onboardingSteps, addStep, storeType]);

  const noHistoricToSyncPlatforms = [
    EInfoProductProvider.KIWIFY,
    EInfoProductProvider.EVERMART,
    EInfoProductProvider.PAYT,
  ];

  React.useEffect(() => {
    if (storeIntegrations) {
      const foundNoHistoricToSyncIntegration = storeIntegrations.store_front.find(
        integration =>
          noHistoricToSyncPlatforms.includes(integration.name as EInfoProductProvider) &&
          integration.is_active,
      );

      if (foundNoHistoricToSyncIntegration) setHasNoHistoricToSync(true);
    }
  }, [storeIntegrations, noHistoricToSyncPlatforms]);

  // React.useEffect(() => {
  //   if (store) setStoreType(store.type);
  // }, [store]);

  const getHeadingContent = (): JSX.Element => {
    if (!hasNoHistoricToSync) {
      return (
        <>
          {user?.has_activated_utm_campaign ? (
            <>
              Parabéns, o seu bônus de 30
              <br />
              dias de trial está ativo.
            </>
          ) : (
            <>
              Parabéns! <br />
            </>
          )}
          <br />
          <br />
          Agora iremos processar <br /> seu histórico de 30 dias.
        </>
      );
    }

    return (
      <>
        Parabéns! Você terminou suas <br /> configurações de Onboarding. <br />
        <br />
        {user?.has_activated_utm_campaign && (
          <>
            O seu bônus de 30 dias de
            <br />
            trial está ativo.
          </>
        )}
      </>
    );
  };

  return (
    <S.Container>
      <S.LeftSide>
        <S.HeadingAndButtonWrapper>
          <S.Heading type={EHeadingSize.H2} fontWeight={EHeadingWeight.REGULAR}>
            {getHeadingContent()}
          </S.Heading>

          <S.FinishOnboardingButton
            onClick={
              hasNoHistoricToSync ? handleFinishOnboardingButton : handleProcessingHistoryButton
            }
            textColor={EButtonTextColor.DARK}
            isLoading={isLoading}
            disabled={isLoading}
          >
            {hasNoHistoricToSync
              ? 'Continuar para o dashboard'
              : 'Processar meu histórico de 30 dias'}
          </S.FinishOnboardingButton>
        </S.HeadingAndButtonWrapper>
      </S.LeftSide>

      <S.RightSide />
    </S.Container>
  );
};

export default Finished;
