import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresPixConversionValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresPixConversionValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresPixConversionValuesResponse => {
  const currentSelectedStorePixConversionValue =
    currentSelectedStoreFinancialDetailsReportData?.pix?.conversion;

  const storesPixConversionValues = selectedStoresData?.map(
    value => value.financial_details_report?.pix?.conversion,
  );

  const pixConversionAmountValues = [
    ...storesPixConversionValues,
    currentSelectedStorePixConversionValue,
  ];

  const reducedPixConversionValues = pixConversionAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const currentSelectedStore = 1;
  const selectedStoresQuantity = selectedStoresData?.length;

  const storesQuantity = selectedStoresQuantity + currentSelectedStore;

  const finalResult = reducedPixConversionValues / storesQuantity;

  return {
    pix_conversion: finalResult,
  };
};
