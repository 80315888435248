import React from 'react';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import tsx from 'react-syntax-highlighter/dist/esm/languages/prism/tsx';
import { Copy } from '@profitfy/pakkun-icons';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { EButtonSize } from '@domain/enums/components/EButton';

import { useAdsManager } from '@helpers/hooks/pages/dashboard/adsManager/useAdsManager';
import { useToast } from '@helpers/hooks/useToast';

import SideModalSave from '@components/common/core/Utils/SideModalSave';

import * as S from './styles';

S.CodeEditor.registerLanguage('tsx', tsx);

const AddUTMTagsManuallySidemodal: React.FC = () => {
  // prettier-ignore
  const scriptCode = `utm_source=facebook-profitfy&utm_medium={{placement}}
&utm_campaign={{campaign.id}}&utm_term={{adset.id}}&utm_content={{ad.id}}`;

  const {
    isAddUTMTagsManualySidemodalOpen,
    handleAddUTMTagsManuallySidemodalOpen,
  } = useAdsManager();
  const { toast } = useToast();

  const [isCodeCopied, setIsCodeCopied] = React.useState<boolean>(false);

  const handleSyncClick = React.useCallback(() => {
    console.log('clicked');
  }, []);

  const handleCopyCode = React.useCallback(() => {
    navigator.clipboard.writeText(scriptCode);
    setIsCodeCopied(true);
    toast.success('Copiado com sucesso!');
  }, [scriptCode, toast]);

  return (
    <S.SideModal
      isOpen={isAddUTMTagsManualySidemodalOpen}
      toggle={handleAddUTMTagsManuallySidemodalOpen}
    >
      <S.Title type={EHeadingSize.H3} fontWeight={EHeadingWeight.REGULAR}>
        Adicionar Profitfy UTM Tags
      </S.Title>

      <S.HowToDoWrapper>
        <S.HowToDoTitle>Como fazer?</S.HowToDoTitle>
        <S.Text>
          Você precisará acessar os links dos criativos que foram listados para você logo abaixo.
          Após isso irá adicionar o parâmetro de URL no campo indicado no print.
        </S.Text>
      </S.HowToDoWrapper>

      <S.AdsWithoutUtmsWrapper>
        <S.DescriptionTitle>Criativos sem UTM Tags</S.DescriptionTitle>

        <S.AdsWithoutUtmsList>
          <S.AdWithoutUtm>
            <S.AdLink href="https://profitfy.me" target="_blank">
              Teste 1
            </S.AdLink>
          </S.AdWithoutUtm>
          <S.AdWithoutUtm>
            <S.AdLink href="https://profitfy.me" target="_blank">
              Teste 2
            </S.AdLink>
          </S.AdWithoutUtm>
          <S.AdWithoutUtm>
            <S.AdLink href="https://profitfy.me" target="_blank">
              Teste 3
            </S.AdLink>
          </S.AdWithoutUtm>
        </S.AdsWithoutUtmsList>
      </S.AdsWithoutUtmsWrapper>

      <S.UrlParameterWrapper>
        <S.DescriptionTitle>Parâmetro de URL</S.DescriptionTitle>

        <S.CodeWrapper>
          <S.CodeEditor language="javascript" style={darcula} showLineNumbers>
            {scriptCode}
          </S.CodeEditor>
          <S.CopyButton buttonSize={EButtonSize.MEDIUM} onClick={handleCopyCode}>
            <Copy size={14} />
            {isCodeCopied ? 'Copiado' : 'Copiar'}
          </S.CopyButton>
        </S.CodeWrapper>
      </S.UrlParameterWrapper>

      <S.ExternalTutorialWrapper>
        <S.DescriptionTitle>
          Adicione no campo de Parâmetros de URL no criativo do Facebook
        </S.DescriptionTitle>
        <S.Image
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/ads-manager/add-utms-manually/external-tutorial.png"
          alt="Profitfy.me How to Add Manually"
        />
      </S.ExternalTutorialWrapper>

      <SideModalSave
        successButtonText="Sincronizar campanhas"
        cancelButtonText="Voltar"
        onSuccess={handleSyncClick}
        onCancel={handleAddUTMTagsManuallySidemodalOpen}
      />
    </S.SideModal>
  );
};

export default AddUTMTagsManuallySidemodal;
