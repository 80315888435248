import React from 'react';

import { useForm } from 'react-hook-form';
import { useToast } from '@helpers/hooks/useToast';

import transferPlansService from '@services/pages/dashboard/adminPanel/transferPlan';

import Form from '@components/common/core/Inputs/Form';

import * as S from './styles';

const StorePlan: React.FC = () => {
  const { toast } = useToast();

  const { handleSubmit, register } = useForm();

  const [isTransferingPlan, setIsTransferingPlan] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async formData => {
      setIsTransferingPlan(true);

      const storeAliasId = formData.current_store_alias_id;
      const destinationStoreAliasId = formData.destination_store_alias_id;

      try {
        await transferPlansService.transferPlan({
          storeAliasId,
          data: {
            destination_store_alias_id: destinationStoreAliasId,
          },
        });

        toast.success('Plano transferido com sucesso.');

        setIsTransferingPlan(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsTransferingPlan(false);
      }
    },
    [toast],
  );

  return (
    <S.ContentWrapper>
      <S.Heading>Transferência de Plano</S.Heading>

      <S.Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label htmlFor="current-store-alias-id">Loja atual</S.Label>
            <S.Input
              {...register('current_store_alias_id')}
              type="text"
              placeholder="Insira o Alias Id da loja atual"
              id="current-store-alias-id"
            />
          </S.InputGroup>

          <S.InputGroup>
            <S.Label htmlFor="destination-store-alias-id">Loja de Destino</S.Label>
            <S.Input
              {...register('destination_store_alias_id')}
              type="text"
              placeholder="Insira o Alias Id da loja de destino"
              id="destination-store-alias-id"
            />
          </S.InputGroup>

          <S.Button type="submit" isLoading={isTransferingPlan} disabled={isTransferingPlan}>
            Transferir
          </S.Button>
        </Form>
      </S.Content>
    </S.ContentWrapper>
  );
};

export default StorePlan;
