import React from 'react';
import { SimpleCheck, SimpleClose } from '@profitfy/pakkun-icons';

import { IInputProps } from '@domain/interfaces/dashboard/CustomValuesCategories/IInput';

import * as S from './styles';

const Input: React.FC<IInputProps> = ({
  defaultValue,
  onBlur,
  onChange,
  onCancelClick,
  onSaveClick,
}) => {
  const handleKeyPress = React.useCallback(
    event => {
      if (event.key === 'Enter') {
        onSaveClick();
      }
    },
    [onSaveClick],
  );

  return (
    <S.InputWrapper onBlur={onBlur}>
      <S.Input
        defaultValue={defaultValue}
        onChange={onChange}
        onKeyPress={handleKeyPress}
        autoFocus
      />

      <S.OptionsWrapper tabIndex={0}>
        <S.Button onClick={onSaveClick}>
          <SimpleCheck size={12} />
        </S.Button>
        <S.Button onClick={onCancelClick}>
          <SimpleClose size={12} />
        </S.Button>
      </S.OptionsWrapper>
    </S.InputWrapper>
  );
};

export default Input;
