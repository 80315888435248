import { ApiService } from '@services/api';
import { LONG_API_DOMAIN } from '@constants/api';
import { ISyncMarketingProps } from '@domain/interfaces/dashboard/Marketing/IMarketing';

export class MarketingService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public syncMarketing({ storeAliasId, startDate, endDate }: ISyncMarketingProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/marketing/daily/sync?start_date=${startDate}&end_date=${endDate}`,
    );
  }
}

const marketingService = new MarketingService(LONG_API_DOMAIN);

export default marketingService;
