import React from 'react';

import { EButtonSize } from '@domain/enums/components/EButton';

import { useMambaDigital } from '@helpers/hooks/pages/consent/useMambaDigital';

import TermsReader from './TermsReader';

import * as S from './styles';

const Body: React.FC = () => {
  const { isCheckboxSelected, onCheckboxChange, onSubmit, isSubmittingTerms } = useMambaDigital();

  return (
    <S.Wrapper>
      <S.CheckboxAndTermsReaderWrapper>
        <TermsReader />

        <S.LabelAndCheckboxWrapper>
          <S.Checkbox
            defaultChecked={isCheckboxSelected}
            onChange={onCheckboxChange}
            id="mamba-checkbox"
          />

          <S.Label htmlFor="mamba-checkbox">
            Aceito conceder acesso aos dados de minha loja à Mamba
          </S.Label>
        </S.LabelAndCheckboxWrapper>
      </S.CheckboxAndTermsReaderWrapper>

      <S.SubmitButton
        onClick={onSubmit}
        isLoading={isSubmittingTerms}
        buttonSize={EButtonSize.MEDIUM}
      >
        Prosseguir para a plataforma
      </S.SubmitButton>
    </S.Wrapper>
  );
};

export default Body;
