import React from 'react';

import { Sync, Hand } from '@profitfy/pakkun-icons';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';
import { useProductDetails } from '@helpers/hooks/pages/dashboard/productAnalytics/useProductDetails';

import GoBack from '@components/common/core/Utils/GoBack';
import DatePicker from '@components/common/core/Utils/DatePicker';

import Popover from '@components/common/core/Utils/Popover';
import NewMarketingCostSidemodal from '@components/Dashboard/ProductAnalytics/ProductDetails/NewMarketingCostSidemodal';

import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Header: React.FC = () => {
  const history = useHistory();
  const theme = useTheme();
  const {
    product,
    period,
    setPeriod,
    isLoadingProductDetails,
    isValidatingProductDetails,
  } = useProductDetails();

  const [
    isNewMarketingCostSidemodalOpen,
    setIsNewMarketingCostSidemodalOpen,
  ] = React.useState<boolean>(false);

  const onGoBackClick = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const handleSyncCampaignsClick = React.useCallback(
    event => {
      event.stopPropagation();

      history.push('campaigns');
    },
    [history],
  );

  const handleIsNewMarketingCostSidemodalOpen = React.useCallback(() => {
    setIsNewMarketingCostSidemodalOpen(!isNewMarketingCostSidemodalOpen);
  }, [isNewMarketingCostSidemodalOpen]);

  const handleNewMarketingCostClick = React.useCallback(
    event => {
      event.stopPropagation();

      handleIsNewMarketingCostSidemodalOpen();
    },
    [handleIsNewMarketingCostSidemodalOpen],
  );

  return (
    <S.Wrapper>
      {isLoadingProductDetails || isValidatingProductDetails ? (
        <SkeletonLoading />
      ) : (
        <GoBack
          text={product.name}
          src={product.image_url}
          alt="Profitfy.me Product"
          onClick={onGoBackClick}
        />
      )}

      <S.SyncAdsAndDatePickerWrapper>
        <S.AddAdsCostButton data-tip data-for="marketing-options" data-event="click">
          <S.IconWrapper>
            <Sync size={14} color={theme.colors.font} />
          </S.IconWrapper>
          <S.Text>Adicionar custo de Ads</S.Text>
        </S.AddAdsCostButton>

        <Popover width={240} id="marketing-options" place="bottom">
          <S.MoreOptionWrapper>
            <S.ActionButton onClick={handleSyncCampaignsClick}>
              <Sync size={16} color={theme.colors.font} />
              Sincronização Automática
            </S.ActionButton>
            <S.ActionButton onClick={handleNewMarketingCostClick}>
              <Hand size={16} color={theme.colors.font} />
              Lançamento Manual
            </S.ActionButton>
          </S.MoreOptionWrapper>
        </Popover>

        <DatePicker period={period} setPeriod={setPeriod} />
      </S.SyncAdsAndDatePickerWrapper>

      {isNewMarketingCostSidemodalOpen && (
        <NewMarketingCostSidemodal
          isOpen={isNewMarketingCostSidemodalOpen}
          toggle={handleIsNewMarketingCostSidemodalOpen}
          product={product}
        />
      )}
    </S.Wrapper>
  );
};

export default Header;
