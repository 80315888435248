import styled, { css } from 'styled-components/macro';

import DropDownBase from '@components/common/core/Utils/DropDown';
import PopoverBase from '@components/common/core/Utils/CustomPopover';
import { IDatePickerButtonProps } from '@domain/interfaces/components/IDatePickerRange';

export const DropDown = styled(DropDownBase)`
  & > div:first-child {
    border-bottom-color: ${({ theme }) => theme.colors.gray[6]};
  }

  & > div:last-child {
    padding: 24px;
    background-color: ${({ theme }) => theme.colors.gray[6]};

    @media only screen and (max-width: 388px) {
      padding: 12px;
      width: fit-content;
    }
  }
`;

export const DatePickerButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;

  &:hover {
    filter: brightness(1.2);
    background-color: #1e222e;
    border-radius: 3px;
    padding: initial 6px;
  }

  svg {
    margin-left: 12px;
  }
`;

export const CustomDateWrapper = styled.div<IDatePickerButtonProps>`
  padding: 0 12px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  color: ${({ theme }) => theme.colors.gray[2]};
  border-radius: 5px;
  font-family: ${({ theme }) => theme.fonts.body};
  transition: all 0.3s;
  cursor: pointer;

  @media only screen and (max-width: 416px) {
    padding: 0 8px;
  }

  &:hover {
    background-color: #102c2e;
    color: ${({ theme }) => theme.colors.green.default};
  }

  &:active {
    filter: brightness(0.9);
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background-color: #102c2e;
      color: ${theme.colors.green.default};
    `}
`;

export const CustomDatePopover = styled(PopoverBase)``;

export const DateButton = styled.button<IDatePickerButtonProps>`
  padding: 0 12px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: unset;
  border: unset;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.gray[2]};
  border-radius: 5px;
  transition: all 0.3s;
  cursor: pointer;

  @media only screen and (max-width: 416px) {
    padding: 0 8px;
  }

  &:hover {
    background-color: #102c2e;
    color: ${({ theme }) => theme.colors.green.default};
  }

  &:active {
    filter: brightness(0.9);
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background-color: #102c2e;
      color: ${theme.colors.green.default};
    `}
`;

export const Container = styled.div`
  display: flex;
  width: fit-content;
`;
