import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Link = styled.a`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.green.default};
  width: 100%;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 16px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const FeaturePageButton = styled.button`
  background-color: unset;
  padding: unset;
  border: unset;
  width: 100%;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.green.default};
  font-size: 1rem;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
    text-decoration: underline;
  }

  &:active {
    filter: brightness(0.9);
    text-decoration: underline;
  }
`;

export const VideoWrapper = styled.button`
  padding: unset;
  border: unset;
  width: 100%;
  height: 108px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const BenefitText = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
`;

export const Benefit = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  & > svg {
    margin-bottom: 2px;
  }
`;

export const BenefitsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
`;

export const Description = styled(TextBase)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[2]};
  line-height: 120%;
  display: inline-block;
  margin-bottom: 24px;
`;

export const Body = styled.div``;

export const Title = styled(HeadingBase)``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
`;

export const Wrapper = styled.div`
  max-width: 300px;
  width: 100%;
  padding: 18px 26px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.gray[5]};
  border-radius: 7px;
  height: fit-content;

  @media only screen and (max-width: 1116px) {
    max-width: unset;
  }
`;
