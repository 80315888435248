import React from 'react';
import { LineChart } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import OnboardingNotFinished from '@components/Dashboard/AdsManager/UtmsTooltipsContent/OnboardingNotFinished';

import * as S from './styles';

const BlurredContent: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const history = useHistory();
  const theme = useTheme();

  const redirectToUtmOnboarding = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/marketing/ads-manager/onboarding/script-utms`);
  }, [history, storeAliasId]);

  return (
    <S.CustomHoverCard>
      <S.CustomHoverCardTrigger>
        <S.CpaValue>R$ 00,00</S.CpaValue>

        <S.ForecastText>
          <LineChart outline size={12} color={theme.colors.gray[2]} />
          R$ 00,00
        </S.ForecastText>
      </S.CustomHoverCardTrigger>

      <S.CustomHoverCardContent side="top" sideOffset={5}>
        <OnboardingNotFinished startUtmOnboarding={redirectToUtmOnboarding} />
        <S.CustomHoverCard.Arrow />
      </S.CustomHoverCardContent>
    </S.CustomHoverCard>
  );
};

export default BlurredContent;
