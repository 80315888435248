import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';

export const Table = styled(TableBase)`
  border-top-left-radius: unset;
  border-top-right-radius: unset;

  td:nth-child(2) {
    padding-left: 5px;
  }
`;

export const SwitchWrapper = styled.div`
  width: 76px;
`;

export const AccountIdWrapper = styled.div`
  width: 128px;
  display: flex;
  flex-direction: column;
`;

export const AccountNameWrapper = styled.div`
  width: 128px;
  display: flex;
  flex-direction: column;
`;

export const SpendAmountWrapper = styled.div`
  width: 92px;
  display: flex;
  flex-direction: column;
`;
