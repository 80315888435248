import React from 'react';

import { IStoreIntegrationProvider } from '@domain/interfaces/dashboard/IntegrationCenter/IStoreIntegration';

const StoreIntegrationsContext = React.createContext<IStoreIntegrationProvider | null>(null);

export const StoreIntegrationsProvider: React.FC = ({ children }) => {
  const [searchIntegrationsValue, setSearchIntegrationsValue] = React.useState<string>('');
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const [isSideModalOpen, setIsSideModalOpen] = React.useState<boolean>(false);
  const [selectedIntegration, setSelectedIntegration] = React.useState<string | undefined>(
    undefined,
  );

  const handleSideModalOpen = React.useCallback(() => {
    setIsSideModalOpen(!isSideModalOpen);
  }, [isSideModalOpen]);

  const handleSelectedIntegration = React.useCallback(identifier => {
    setSelectedIntegration(identifier);
  }, []);

  const handleSearchIntegrationsValue = React.useCallback(
    event => setSearchIntegrationsValue(event.target.value),
    [],
  );

  const handleSelectedTab = React.useCallback(index => setSelectedTab(index), []);

  return (
    <StoreIntegrationsContext.Provider
      value={{
        handleSearchIntegrationsValue,
        searchIntegrationsValue,
        selectedTab,
        handleSelectedTab,
        isSideModalOpen,
        handleSideModalOpen,
        selectedIntegration,
        handleSelectedIntegration,
      }}
    >
      {children}
    </StoreIntegrationsContext.Provider>
  );
};

export const useStoreIntegrations = (): IStoreIntegrationProvider => {
  const context = React.useContext(StoreIntegrationsContext);

  if (!context) {
    throw new Error('useStoreIntegrations must be used within StoreIntegrationsProvider');
  }

  return context;
};
