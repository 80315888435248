/* eslint-disable no-plusplus */
import React, { MutableRefObject } from 'react';

import { useCampaigns } from '@helpers/hooks/pages/dashboard/adsManager/useCampaigns';

import Header from './Header';
import Footer from './Footer';

import * as S from './styles';

const Table: React.ForwardRefRenderFunction<HTMLTableElement> = ({ children, ...rest }, ref) => {
  const { tableElementRef, mappedAdSenseCampaigns, tableParentElementRef } = useCampaigns();

  const offsetComparedToTop =
    window.pageYOffset + tableParentElementRef.current?.getBoundingClientRect().top || 0;
  const tableHeaderAndFooterHeight = 53 + 97;
  const maxHeight =
    window.innerHeight > 720
      ? window.innerHeight - offsetComparedToTop - tableHeaderAndFooterHeight
      : window.innerHeight - tableHeaderAndFooterHeight - 32;
  const mappedItemsLength = mappedAdSenseCampaigns?.length || 0;

  const footerTop = mappedItemsLength * 60 >= maxHeight ? maxHeight - 18 : mappedItemsLength * 60;

  return (
    <S.Table
      id="scrollable-table"
      ref={event => {
        tableElementRef.current = event;

        if (typeof ref === 'function') {
          ref(event);
        } else if (ref) {
          (ref as MutableRefObject<HTMLTableElement | null>).current = event;
        }
      }}
      {...rest}
    >
      <S.TableHead>
        <Header />
      </S.TableHead>

      <S.TableBody>{children}</S.TableBody>

      <S.TableFooter top={footerTop}>
        <Footer />
      </S.TableFooter>
    </S.Table>
  );
};

export default React.forwardRef(Table);
