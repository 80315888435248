import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import MultiStoresSelectBase from '../MultiStoresSelect';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 24px;
  width: fit-content;
`;

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 960px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
  }
`;

export const DatePickerAndActionsButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  gap: 28px;

  @media only screen and (max-width: 960px) {
    margin-left: unset;
  }

  @media only screen and (max-width: 530px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const MultiStoresSelect = styled(MultiStoresSelectBase)`
  margin-left: 12px;

  @media only screen and (max-width: 960px) {
    margin-left: unset;
  }
`;
