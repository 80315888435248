import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import ButtonBase from '@components/common/core/Inputs/Button';
import TextFieldBase from '@components/common/core/Inputs/TextField';

export const Input = styled(TextFieldBase)`
  background-color: ${({ theme }) => theme.colors.gray[5]};
  border-color: ${({ theme }) => theme.colors.gray[5]};

  & > div {
    background-color: ${({ theme }) => theme.colors.gray[5]};
    border-color: ${({ theme }) => theme.colors.gray[5]};

    & > input {
      color: ${({ theme }) => theme.colors.gray[1]};

      &::placeholder {
        color: ${({ theme }) => theme.colors.gray[2]};
      }

      &::-ms-input-placeholder {
        color: ${({ theme }) => theme.colors.gray[2]};
      }

      &::-ms-input-placeholder {
        color: ${({ theme }) => theme.colors.gray[2]};
      }
    }
  }
`;

export const Button = styled(ButtonBase)`
  max-width: 374px !important;
  width: 100%;

  &:first-of-type {
    margin-bottom: 24px;
  }
`;

export const Label = styled(LabelBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const InputGroup = styled(InputGroupBase)`
  max-width: 374px;
  width: 100%;
  margin-bottom: 32px;
`;

export const Heading = styled(HeadingBase)`
  margin-bottom: 42px;
  color: ${({ theme }) => theme.colors.gray[1]};

  @media only screen and (max-width: 1024px) {
    margin-top: 42px;
    margin-bottom: 28px;
  }
`;

export const Logo = styled(ImageBase)`
  max-width: 162px;
  width: 100%;

  @media only screen and (max-width: 1368px) {
    top: 24px;
    left: 24px;
  }

  @media only screen and (max-width: 1024px) {
    position: relative;
    top: unset;
    left: unset;
    max-width: 224px;
    margin-top: 64px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: center;
  align-items: center;
  flex-direction: column;
  max-width: 374px;
  width: 100%;
  margin-bottom: 90px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  padding: 32px;
  border-radius: 7px;
  margin-bottom: 32px;

  @media only screen and (max-width: 1368px) {
    margin-top: 196px;
  }

  @media only screen and (max-width: 1024px) {
    margin: 0 auto;
    margin-top: unset;
    background-color: transparent;
    padding: unset;
  }

  @media only screen and (max-width: 425px) {
    box-sizing: border-box;
    padding: 0 16px;
  }
`;

export const Wrapper = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;
`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
  overflow: hidden;
  position: relative;
  background-color: #14161a;
  padding: 128px 32px 92px 32px;
  box-sizing: border-box;

  @media only screen and (max-width: 1024px) {
    background-image: none;
    padding: 128px 16px 64px 16px;
  }
`;
