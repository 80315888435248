import React from 'react';
import { Check } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { ISuggestionModalProps } from '@domain/interfaces/onboarding/integrations/ISuggestionModal';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';

import { suggestionSchema } from '@helpers/validators/onboarding/dropshipping/suggestionModal';

import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const SuggestionModal: React.FC<ISuggestionModalProps> = ({ isOpen, toggle }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(suggestionSchema) });
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { analytics, user } = useConfig();

  const onSubmit = React.useCallback(
    data => {
      analytics?.track(
        'Platform ADS Suggestion Created',
        {
          suggestion: data?.suggestion,
          email: user?.email,
        },
        { context: { groupId: storeAliasId } },
      );
      analytics?.trackHubSpot(storeAliasId, { is_suggestion_created: true });

      toast.success('Sugestão enviada com sucesso!');

      toggle();
    },
    [toast, analytics, user, storeAliasId, toggle],
  );

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Modal.Header>
        <Check size={24} outline />
        Nos informe a plataforma que você utiliza
      </S.Modal.Header>

      <S.Modal.Body>
        <S.Description>
          Nos informe qual plataforma você utiliza e ela será adicionada ao nosso planejamento de
          novas integrações para o futuro.
        </S.Description>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>Nome da plataforma</S.Label>
            <S.Input {...register('suggestion')} type="text" placeholder="Ex. Facebook" />
            {errors.suggestion && <Text isErrorFeedback>{errors.suggestion.message}</Text>}
          </S.InputGroup>

          <S.ButtonsWrapper>
            <S.CancelButton type="button" onClick={toggle}>
              Não, obrigado
            </S.CancelButton>
            <S.SendButton type="submit">Enviar</S.SendButton>
          </S.ButtonsWrapper>
        </Form>
      </S.Modal.Body>
    </S.Modal>
  );
};

export default SuggestionModal;
