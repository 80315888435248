import React from 'react';
import { WarningTriangle } from '@profitfy/pakkun-icons';

import { useAdsManager } from '@helpers/hooks/pages/dashboard/adsManager/useAdsManager';

import * as S from './styles';

const NoUtmInsight: React.FC = () => {
  const { handleUtmSidemodalOpen } = useAdsManager();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const onOpenChange = React.useCallback(state => setIsOpen(state), []);

  const onSyncClick = React.useCallback(() => {
    handleUtmSidemodalOpen();
    onOpenChange(false);
  }, [handleUtmSidemodalOpen, onOpenChange]);

  return (
    <S.CustomHover open={isOpen} onOpenChange={onOpenChange}>
      <S.CustomHover.Trigger>
        <S.InsightWrapper>
          <WarningTriangle size={14} />
        </S.InsightWrapper>
      </S.CustomHover.Trigger>

      <S.CustomHoverContent side="top" sideOffset={5}>
        <S.ContentWrapper>
          <S.Text>
            <strong>Não foi localizado UTMs em sua campanha.</strong> UTMs são credenciais
            utilizadas nas URL de suas campanhas para que nós, possamos cruzar com seus dados
            financeiros. <S.SyncButton onClick={onSyncClick}>Sincronizar UTMs</S.SyncButton>
          </S.Text>
        </S.ContentWrapper>

        <S.CustomHover.Arrow />
      </S.CustomHoverContent>
    </S.CustomHover>
  );
};

export default NoUtmInsight;
