import React from 'react';

import { StoreSubscriptionProvider } from '@helpers/hooks/useStoreSubscription';
import { SubscriptionPlansProvider } from '@helpers/hooks/pages/dashboard/subscription/useSubscriptionPlans';
import { StoreConfigProvider } from '@helpers/hooks/useStoreConfig';
import { TransactionProvider } from '@helpers/hooks/common/store/subscription/useTransaction';

import Pricing from './Pricing';

const PricingWrapper: React.FC = () => {
  return (
    <TransactionProvider>
      <StoreSubscriptionProvider>
        <StoreConfigProvider>
          <SubscriptionPlansProvider>
            <Pricing />
          </SubscriptionPlansProvider>
        </StoreConfigProvider>
      </StoreSubscriptionProvider>
    </TransactionProvider>
  );
};

export default PricingWrapper;
