import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 269px;
  min-width: 269px;
  height: 147px;
  border-radius: 12px;
  overflow: hidden;
  height: 195px;

  span {
    display: block;
  }

  @media only screen and (max-width: 1360px) {
    max-width: 348px;
    width: 100%;
  }
`;
