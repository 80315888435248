import React from 'react';
import {
  ChatTeardropText,
  Image,
  Layout,
  SpeakerHigh,
  DotsSixVertical,
  VideoCamera,
} from 'phosphor-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from 'styled-components';

import { ETextSize } from '@domain/enums/components/EText';
import { EButtonVariant } from '@domain/enums/components/EButton';
import { EMessageType, EConfigOption } from '@domain/enums/dashboard/automations/EMessage';
import { IMessage } from '@domain/interfaces/common/automations/IMessage';

import { useCanvasMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessageSettings';
import { useCanvasContent } from '@helpers/hooks/pages/dashboard/automations/useCanvasContent';
import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';

import { messageSchema } from '@helpers/validators/dashboard/automations/messages/message';

import Text from '@components/common/core/DataDisplay/Text';
import Preview from './Preview';
import SavedContent from './SavedContent';
import Content from './Content';

import * as S from './styles';

interface IUpdateMessageContent {
  handleClose: () => void;
}

const MAX_CONTENTS_PER_MESSAGE = 5;

const UpdateMessageContent: React.FC<IUpdateMessageContent> = ({ handleClose }) => {
  const theme = useTheme();
  const { handleWhatsappNodeSelectMessage } = useAutomation();
  const {
    handleSelectedMessageTrigger,
    handleMessageName,
    selectedDefaultMessageToUpdate,
    contentsToUpdate,
    selectedMessageTrigger,
    resetOnMessageModalClose,
  } = useCanvasMessageSettings();
  const { handleSelectedContentType, selectedContentType } = useCanvasContent();

  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(messageSchema), reValidateMode: 'onChange', mode: 'onBlur' });

  const messageNameRegister = register('message_name');

  const handlePopoverOpen = React.useCallback(() => {
    setIsPopoverOpen(!isPopoverOpen);
  }, [isPopoverOpen]);

  const onPopoverOptionClick = React.useCallback(
    event => {
      handleSelectedContentType(event.target.value);
      handlePopoverOpen();
    },
    [handlePopoverOpen, handleSelectedContentType],
  );

  const onSelectTriggerChange = React.useCallback(
    event => {
      handleSelectedMessageTrigger(event.target.value);
    },
    [handleSelectedMessageTrigger],
  );

  const onMessageNameChange = React.useCallback(
    event => {
      messageNameRegister.onChange(event);

      handleMessageName(event.target.value);
    },
    [handleMessageName, messageNameRegister],
  );

  const filteredContentsToUpdate = contentsToUpdate.filter(content => !content.shouldBeExcluded);

  const onSubmit = React.useCallback(
    data => {
      if (!selectedDefaultMessageToUpdate || !selectedMessageTrigger) return;

      const filteredContents = contentsToUpdate.filter(content => !content.shouldBeExcluded);

      const mappedContents: Array<any> = filteredContents.map(content => {
        return {
          id: content.id,
          type: content.type,
          content: content.content,
        };
      });

      const newMessage: IMessage = {
        ...selectedDefaultMessageToUpdate,
        title: data.message_name,
        message_contents: mappedContents,
      };

      handleWhatsappNodeSelectMessage(newMessage);
      resetOnMessageModalClose();
      handleClose();
    },
    [
      selectedDefaultMessageToUpdate,
      contentsToUpdate,
      selectedMessageTrigger,
      handleWhatsappNodeSelectMessage,
      resetOnMessageModalClose,
      handleClose,
    ],
  );

  const filteredContents = contentsToUpdate.filter(content => !content.shouldBeExcluded);
  const isWithinLimit = filteredContents.length < MAX_CONTENTS_PER_MESSAGE;

  return (
    <>
      <S.Header>
        <S.HeadingWrapper>
          <Layout size={28} color={theme.colors.gray[1]} />
          <S.Heading size={ETextSize.B3}>Configure a mensagem</S.Heading>
        </S.HeadingWrapper>
      </S.Header>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.MessageConfigurationsWrapper>
          <S.InputGroupWrapper>
            <S.InputGroup>
              <S.Label>Nome da mensagem</S.Label>
              <S.Input
                {...messageNameRegister}
                type="text"
                placeholder="digite o nome da mensagem"
                onChange={onMessageNameChange}
                defaultValue={selectedDefaultMessageToUpdate?.title}
                isError={errors.message_name}
              />
              {errors.message_name && <Text isErrorFeedback>{errors.message_name.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Tipo de Gatilho</S.Label>

              <S.Select
                name="choose-trigger-select"
                onChange={onSelectTriggerChange}
                defaultValue={selectedDefaultMessageToUpdate?.type}
              >
                <S.Option value={EMessageType.ABANDONED_CART}>Carrinho abandonado</S.Option>
              </S.Select>
            </S.InputGroup>
          </S.InputGroupWrapper>

          <S.ConfigsWrapper hasMaxHeight={selectedContentType === EConfigOption.NONE}>
            <Content />

            {selectedContentType === EConfigOption.NONE && (
              <>
                {filteredContentsToUpdate?.map((content, index) => {
                  if (content.type === EConfigOption.TEXT)
                    return (
                      <SavedContent
                        key={content.id}
                        configOption={EConfigOption.TEXT}
                        contentId={content.id}
                        contentText="Conteúdo de texto"
                        icon={DotsSixVertical}
                        index={index}
                      />
                    );

                  if (content.type === EConfigOption.IMAGE)
                    return (
                      <SavedContent
                        key={content.id}
                        configOption={EConfigOption.IMAGE}
                        contentId={content.id}
                        contentText="Conteúdo de imagem"
                        icon={DotsSixVertical}
                        index={index}
                      />
                    );

                  if (content.type === EConfigOption.AUDIO)
                    return (
                      <SavedContent
                        key={content.id}
                        configOption={EConfigOption.AUDIO}
                        contentId={content.id}
                        contentText="Conteúdo de áudio"
                        icon={DotsSixVertical}
                        index={index}
                      />
                    );

                  if (content.type === EConfigOption.VIDEO)
                    return (
                      <SavedContent
                        key={content.id}
                        configOption={EConfigOption.VIDEO}
                        contentId={content.id}
                        contentText="Conteúdo de vídeo"
                        icon={DotsSixVertical}
                        index={index}
                      />
                    );

                  return <></>;
                })}
              </>
            )}
          </S.ConfigsWrapper>

          {selectedContentType === EConfigOption.NONE && isWithinLimit && (
            <S.AddContentButtonWrapper hasMarginTop={Boolean(contentsToUpdate?.length)}>
              <S.HorizontalRule />

              <S.Popover open={isPopoverOpen} onOpenChange={handlePopoverOpen}>
                <S.PopoverTrigger>
                  <S.AddContentButton type="button" variant={EButtonVariant.SECONDARY_BUTTON}>
                    + Adicionar conteúdo
                  </S.AddContentButton>
                </S.PopoverTrigger>

                <S.PopoverContent sideOffset={4.5}>
                  <S.PopoverOption value={EConfigOption.TEXT} onClick={onPopoverOptionClick}>
                    <ChatTeardropText size={18} color={theme.colors.gray[1]} />
                    Texto
                  </S.PopoverOption>

                  <S.PopoverOption value={EConfigOption.IMAGE} onClick={onPopoverOptionClick}>
                    <Image size={18} color={theme.colors.gray[1]} />
                    Imagem
                  </S.PopoverOption>

                  <S.PopoverOption value={EConfigOption.AUDIO} onClick={onPopoverOptionClick}>
                    <SpeakerHigh size={18} color={theme.colors.gray[1]} />
                    Áudio
                  </S.PopoverOption>

                  <S.PopoverOption value={EConfigOption.VIDEO} onClick={onPopoverOptionClick}>
                    <VideoCamera size={18} color={theme.colors.gray[1]} />
                    Vídeo
                  </S.PopoverOption>

                  <S.PopoverArrow />
                </S.PopoverContent>
              </S.Popover>

              <S.HorizontalRule />
            </S.AddContentButtonWrapper>
          )}
        </S.MessageConfigurationsWrapper>

        <Preview />
      </S.Form>
    </>
  );
};

export default UpdateMessageContent;
