import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.Wrapper>
      <Skeleton width="100%" height={195} />
    </S.Wrapper>
  );
};

export default SkeletonLoading;
