import React from 'react';
import { useHistory } from 'react-router-dom';

import { EBequestTermsStatus } from '@domain/enums/common/bequest/EBequestTermStatus';
import { IUpdateBequestData } from '@domain/interfaces/common/bequest/IBequest';
import { IBequestDigitalContext } from '@domain/interfaces/consent/IBequestDigital';

import { useConfig } from '@helpers/hooks/useConfig';
import { useToast } from '@helpers/hooks/useToast';

import bequestService from '@services/common/bequest/bequest';
import userService from '@services/user';

const BequestDigitalContext = React.createContext<IBequestDigitalContext | null>(null);

export const BequestDigitalProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { authorization, handleUser, handleTerm, handleAppAffiliate } = useConfig();
  const history = useHistory();

  const [isCheckboxSelected, setIsCheckboxSelected] = React.useState<boolean>(true);
  const [isSubmittingTerms, setIsSubmittingTerms] = React.useState<boolean>(false);

  const onCheckboxChange = React.useCallback(event => {
    if (event.target) return;

    if (event.stopPropagation) {
      event.stopPropagation();
    }

    setIsCheckboxSelected(event.checked);
  }, []);

  const onSubmit = React.useCallback(async () => {
    setIsSubmittingTerms(true);

    const data: IUpdateBequestData = {
      terms_status: isCheckboxSelected
        ? EBequestTermsStatus.ACCEPTED
        : EBequestTermsStatus.REJECTED,
    };

    try {
      await bequestService.updateMambaTerms({ data });
      const { data: userData } = await userService.getUserPromise(authorization);

      handleUser({ ...userData?.user, user_intercom_hash: userData?.user_intercom_hash });
      handleTerm(userData?.term);

      if (userData.affiliate) {
        handleAppAffiliate(userData.affiliate);
      }

      setIsSubmittingTerms(false);

      history.push('/onboarding/segmentation');
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsSubmittingTerms(false);
    }
  }, [
    isCheckboxSelected,
    toast,
    history,
    authorization,
    handleUser,
    handleAppAffiliate,
    handleTerm,
  ]);

  return (
    <BequestDigitalContext.Provider
      value={{ isCheckboxSelected, onCheckboxChange, onSubmit, isSubmittingTerms }}
    >
      {children}
    </BequestDigitalContext.Provider>
  );
};

export const useBequestDigital = (): IBequestDigitalContext => {
  const context = React.useContext(BequestDigitalContext);

  if (!context) {
    throw new Error('useBequestDigital must be used within BequestDigitalProvider');
  }

  return context;
};
