import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { IHistoricRowSkeletonLoading } from '@domain/interfaces/dashboard/Tax/ISkeletonLoading';

import * as S from './styles';

const SkeletonLoading: React.FC<IHistoricRowSkeletonLoading> = ({ borderBottom }) => {
  return (
    <>
      <S.Table.Row borderBottom={borderBottom}>
        <S.Table.Data>
          <Skeleton width={72} height={27} />
        </S.Table.Data>
        <S.Table.Data>
          <Skeleton width={72} height={27} />
        </S.Table.Data>
        <S.Table.Data>
          <Skeleton width={50} height={27} />
        </S.Table.Data>
        <S.Table.Data>
          <Skeleton width={50} height={27} />
        </S.Table.Data>
        <S.Table.Data>
          <Skeleton width={50} height={27} />
        </S.Table.Data>
      </S.Table.Row>
    </>
  );
};

export default SkeletonLoading;
