import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';

export const Table = styled(TableBase)``;

export const TableRow = styled(TableBase.Row)`
  height: 42px !important;
  padding: 0;
  display: flex;
  width: 100%;
  text-align: center;
`;

export const TableHead = styled(TableBase.Head)``;

export const AutomationsTable = styled.table`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.gray[6]};

  & > thead {
    & > tr {
      & > th {
        padding: 10px 12px;
      }

      & > th:first-child {
        padding-left: 24px;
        padding-right: 0;
      }

      & > th:last-child {
        padding-right: 36px;
      }
    }
  }

  & > tbody {
    & > tr {
      cursor: pointer;

      & > td {
        padding: 8px 12px;
        font-size: 0.875rem;
      }

      & > td:first-child {
        padding-left: 24px;
        padding-right: 0;
      }

      & > td:last-child {
        padding-right: 36px;
      }
    }
  }
`;
