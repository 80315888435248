import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const IdeaWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 50px;
`;

export const NoSuggestionInfo = styled(HeadingBase)``;
