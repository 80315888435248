import React from 'react';
import { WarningCircle } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import * as S from './styles';

interface IInvitedModalProps {
  isOpen: boolean;
  toggle: () => void;
}
const InvitedModal: React.FC<IInvitedModalProps> = ({ isOpen, toggle }) => {
  const theme = useTheme();

  const onClick = React.useCallback(() => {
    toggle();
  }, [toggle]);

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Header>
        <WarningCircle size={24} color={theme.colors.warning.default} />
        <S.Title>Verifique a caixa de spam</S.Title>
      </S.Header>

      <S.Body>
        <S.Description>
          Verifique a sua caixa de <strong>spam</strong> ou <strong>promoções</strong> caso não
          encontre o convite.
        </S.Description>

        <S.UnderstoodButton onClick={onClick}>Entendi</S.UnderstoodButton>
      </S.Body>
    </S.Modal>
  );
};
export default InvitedModal;
