import React from 'react';

import { useParams, useHistory } from 'react-router-dom';

import { EStep } from '@domain/enums/onboarding/EStep';
import { EType } from '@domain/enums/onboarding/EType';
import { IParams } from '@domain/interfaces/IParams';

import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';
import { useInfoProduct } from '@helpers/hooks/pages/onboarding/useInfoProduct';
import { useConfig } from '@helpers/hooks/useConfig';

import NextStep from '@components/Onboarding/NextStep';
import ComponentError from '@components/common/core/Utils/ComponentError';

import storesService from '@services/pages/stores/stores';
import onboardingStepService from '@services/pages/onboarding/onboardingStep';

import DesktopSuggestionContent from '@components/Onboarding/DesktopSuggestionContent';
import SkeletonLoading from '../SkeletonLoading';
import PlatformProvider from '../Platforms';
import Marketings from '../Marketings';
import Header from '../Header';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const history = useHistory();

  const { storeAliasId } = useParams<IParams>();
  const { addStep } = useOnboarding();
  const { analytics, user, isMobileDevice, selectedStore } = useConfig();

  const {
    selectedPlatform,
    handleStoreIntegrations,
    handleSelectedStoreIntegrations,
    handleSelectedPlatform,
    handleSelectedMarketings,
  } = useInfoProduct();

  const {
    integrations: storeIntegrations,
    isLoading: isLoadingStore,
    isValidating: isValidatingStore,
    mutate: mutateStore,
    error: storeError,
  } = storesService.getStore({ storeAliasId });

  const { onboardingSteps } = onboardingStepService.getOnboardingSteps({ storeAliasId });

  const handleNewIntegrationsOnNextStep = React.useCallback(() => {
    handleStoreIntegrations(undefined);

    const foundNewProvider = storeIntegrations.store_front.find(
      provider => provider.name === selectedPlatform && !provider.is_active,
    ) as any;

    handleSelectedPlatform(foundNewProvider?.name);
    handleSelectedStoreIntegrations(true);
  }, [
    handleStoreIntegrations,
    handleSelectedStoreIntegrations,
    handleSelectedPlatform,
    storeIntegrations,
    selectedPlatform,
  ]);

  const nextStep = React.useCallback(() => {
    handleNewIntegrationsOnNextStep();

    analytics?.track(
      'Onboarding Selected Integrations',
      {
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );

    history.push(`/onboarding/${storeAliasId}/info-product/integrations/platforms`);
  }, [history, storeAliasId, handleNewIntegrationsOnNextStep, analytics, user]);

  React.useEffect(() => {
    if (storeIntegrations) {
      handleStoreIntegrations(storeIntegrations);
    }
  }, [storeIntegrations, handleStoreIntegrations]);

  React.useEffect(() => {
    handleSelectedMarketings([]);
  }, [handleSelectedMarketings]);

  React.useEffect(() => {
    handleSelectedStoreIntegrations(false);
  }, [handleSelectedStoreIntegrations]);

  React.useEffect(() => {
    if (onboardingSteps) {
      const foundStep = onboardingSteps.find(
        step =>
          step.configuration_step === EStep.STORE_INTEGRATIONS && step.type === EType.INFO_PRODUCT,
      );

      if (!foundStep) {
        addStep([
          {
            configuration_step: EStep.STORE_INTEGRATIONS,
            type: EType.INFO_PRODUCT,
          },
        ]);
      }
    }
  }, [onboardingSteps, addStep]);

  if (isLoadingStore || isValidatingStore) {
    return <SkeletonLoading />;
  }

  if (storeError && !isValidatingStore) {
    return (
      <S.ComponentErrorWrapper>
        <ComponentError mutate={mutateStore} />
      </S.ComponentErrorWrapper>
    );
  }

  if (isMobileDevice && !selectedStore?.is_onboarding_finished) {
    return <DesktopSuggestionContent />;
  }

  return (
    <S.Wrapper>
      <Header />

      <S.ContentWrapper>
        <S.ProviderAndMarketingWrapper>
          <PlatformProvider />

          {Boolean(selectedPlatform) && <Marketings />}
        </S.ProviderAndMarketingWrapper>
      </S.ContentWrapper>

      <NextStep disabled={!selectedPlatform} onClick={nextStep}>
        Próximo passo
      </NextStep>
    </S.Wrapper>
  );
};

export default Wrapper;
