import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import BadgeBase from '@components/common/core/DataDisplay/Badge';

export const RevenueBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RevenueTypeBadge = styled(BadgeBase)``;

export const RevenueTypeText = styled(TextBase)`
  margin-left: 5px;
  color: inherit;
`;

export const RevenueType = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const RevenueValueNeutro = styled(TextBase)`
  color: #00f2a1;
  font-size: 0.875rem;
  font-weight: 600;
`;

export const RevenueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
