import React from 'react';

import { useConfig } from '@helpers/hooks/useConfig';

import Calculator from '../Calculator';
import Tables from '../Tables';
import * as S from './styles';

const Wrapper: React.FC = () => {
  const { analytics, user } = useConfig();

  React.useEffect(() => {
    analytics?.track('Pricing Calculator Page Viewed', { email: user?.email });
  }, [analytics, user]);

  return (
    <S.Container>
      <S.Title>Calculadora de precificação</S.Title>
      <S.CalculatorWrapper>
        <Calculator />
        <Tables />
      </S.CalculatorWrapper>
    </S.Container>
  );
};

export default Wrapper;
