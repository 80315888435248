import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';

export const PercentageTextWrapper = styled.div`
  max-width: 62px;
  width: 100%;
  margin-left: auto;
`;

export const DateValueTextWrapper = styled.div`
  max-width: 92px;
  width: 100%;
  margin-left: auto;
`;

export const DataTypeTextWrapper = styled.div`
  max-width: 152px;
  width: 100%;
`;

export const Table = styled(TableBase)`
  transition: all 0.3s;

  & > table > thead > tr {
    & > th {
      padding: 8px;
      font-size: 1rem;
    }

    & > th:first-child {
      padding-left: 16px;
    }

    & > th:last-child {
      padding-right: 72px;
    }
  }

  & > table > tbody > tr {
    & > td {
      padding: 8px;
      padding-top: 8px !important;
    }

    & > td:first-child {
      padding-left: 16px;
      width: 24px;
    }

    & > td:last-child {
      padding-right: 72px;
      width: 124px;
    }

    & > td:nth-child(3) {
      width: 200px;
    }

    & > td:nth-child(4) {
      width: 200px;
    }

    & > td:nth-child(5) {
      width: 124px;
    }
  }

  & > table > tbody > tr:last-child {
    & > td {
      padding: 16px 8px;
      padding-top: 16px !important;
    }

    & > td:first-child {
      padding-left: 16px;
    }

    & > td:last-child {
      padding-right: 72px;
    }
  }
`;
