import React from 'react';

import { EInfoProductProvider } from '@domain/enums/common/integrations/EInfoProductProvider';

import { useStoreIntegrations } from '@helpers/hooks/pages/dashboard/integrationCenter/useStoreIntegrations';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { getInfoProductProviderIntegrationStatus } from '@helpers/utils/dashboard/integrationsCenter/infoProductProviders';

import { INFO_PRODUCT_PROVIDERS } from '@constants/common/integrations';

import IntegrationCard from '@components/Dashboard/IntegrationCenter/IntegrationCard';
import Text from '@components/common/core/DataDisplay/Text';
import SkeletonLoadingCard from '../../SkeletonLoadingCard';

import * as S from './styles';

const InfoProductProvider: React.FC = () => {
  const emptyArray = Array.from(Array(5).keys());

  const {
    searchIntegrationsValue,
    isSideModalOpen,
    handleSideModalOpen,
    handleSelectedIntegration,
    selectedIntegration,
  } = useStoreIntegrations();
  const { isLoadingStore, isValidatingStore, storeIntegrations } = useStoreConfig();

  const foundInfoProductProviderIntegration = INFO_PRODUCT_PROVIDERS.find(
    integration => integration.identifier === selectedIntegration,
  );

  const SideModalContent = foundInfoProductProviderIntegration?.sideModal;

  const onCardClick = React.useCallback(
    (identifier: EInfoProductProvider) => {
      handleSideModalOpen();
      handleSelectedIntegration(identifier);
    },
    [handleSelectedIntegration, handleSideModalOpen],
  );

  const checkIsIntegrationCardDisabled = React.useCallback(
    (identifier: EInfoProductProvider): boolean => {
      const hasIntegration = storeIntegrations?.store_front.find(
        integration => integration.is_active,
      );

      if (!hasIntegration) return false;

      const foundStoreFrontIntegration = storeIntegrations?.store_front.find(
        integration => integration.name === identifier && integration.is_active,
      );

      if (foundStoreFrontIntegration) return false;

      return true;
    },
    [storeIntegrations],
  );

  if (isLoadingStore || isValidatingStore) {
    return (
      <S.Wrapper>
        <S.Heading>Plataformas</S.Heading>

        <S.CardsWrapper>
          {emptyArray.map(element => (
            <SkeletonLoadingCard key={element} />
          ))}
        </S.CardsWrapper>
      </S.Wrapper>
    );
  }

  const filteredInfoProductProvidersIntegrations = INFO_PRODUCT_PROVIDERS.filter(integration => {
    const integrationName = integration.name.toLowerCase();
    const searchIntegration = searchIntegrationsValue.toLowerCase();
    const isIntegrationIncludedOnSearch = integrationName.includes(searchIntegration);

    if (!isIntegrationIncludedOnSearch) return false;

    return true;
  });

  return (
    <S.Wrapper>
      <S.Heading>Plataformas</S.Heading>

      <S.CardsWrapper>
        {filteredInfoProductProvidersIntegrations.map(filteredIntegration => {
          const isActivated = getInfoProductProviderIntegrationStatus(
            storeIntegrations,
            filteredIntegration.identifier,
          );

          return (
            <IntegrationCard
              type="providers"
              icon={filteredIntegration.icon}
              isActivated={isActivated}
              title={filteredIntegration.name}
              key={filteredIntegration.id}
              isDisabled={checkIsIntegrationCardDisabled(filteredIntegration.identifier)}
              onClick={() => onCardClick(filteredIntegration.identifier)}
            />
          );
        })}

        {!filteredInfoProductProvidersIntegrations.length && (
          <Text>Não encontramos nenhuma integração que contenha esse nome.</Text>
        )}
      </S.CardsWrapper>

      {SideModalContent && (
        <S.SideModal isOpen={isSideModalOpen} toggle={handleSideModalOpen}>
          <SideModalContent />
        </S.SideModal>
      )}
    </S.Wrapper>
  );
};

export default InfoProductProvider;
