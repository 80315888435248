import { MarkerType } from 'react-flow-renderer';

const defaultProps = {
  animated: true,
  type: 'buttonedge',
  style: {
    strokeWidth: 4,
  },
  markerEnd: {
    type: MarkerType.ArrowClosed,
    orient: 'auto',
  },
};

export const BOLETO_STARTER_EDGES = [
  {
    ...defaultProps,
    id: `reactflow__edge-node1-node2`,
    source: `node1`,
    target: `node2`,
  },
  {
    ...defaultProps,
    id: `reactflow__edge-node2-node3`,
    source: `node2`,
    target: `node3`,
  },
  {
    ...defaultProps,
    id: `reactflow__edge-node3-node4`,
    source: `node3`,
    target: `node4`,
  },
  {
    ...defaultProps,
    id: `reactflow__edge-node4-node5`,
    sourceHandle: 'positive',
    source: `node4`,
    target: `node5`,
  },
  {
    ...defaultProps,
    id: `reactflow__edge-node4-nodeEnd1`,
    sourceHandle: 'negative',
    source: `node4`,
    target: `nodeEnd1`,
  },
  {
    ...defaultProps,
    id: `reactflow__edge-node5-node6`,
    source: `node5`,
    target: `node6`,
  },
];
