import styled from 'styled-components/macro';

export const SolveButtonWrapper = styled.div`
  max-width: 92px;
  width: 100%;
`;

export const OrderRevenueDescription = styled.div`
  max-width: 48px;
  width: 100%;
`;

export const OrderRevenueValueWrapper = styled.div`
  max-width: 128px;
  width: 100%;
`;

export const OrderRevenueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 128px;
  width: 100%;
`;

export const OrderDateWrapper = styled.div`
  max-width: 112px;
  width: 100%;
`;

export const FirstOrderItemWrapper = styled.div`
  max-width: 224px;
  width: 100%;
`;

export const OrderDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 224px;
  width: 100%;
`;

export const OrderStatusWrapper = styled.div`
  max-width: 144px;
  width: 100%;
`;

export const OrderNumberWrapper = styled.div`
  max-width: 72px;
  width: 100%;
`;

export const OrderNumberAndStatus = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ImageWrapper = styled.div`
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;

  & > span > span {
    display: block;
  }
`;

export const ImageAndOrder = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  max-width: 224px;
  width: 100%;
`;

export const Wrapper = styled.div`
  min-width: 768px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 28px;
  gap: 16px;
`;
