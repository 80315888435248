import { ApiService } from '@services/api';
import { LONG_API_DOMAIN } from '@constants/api';
import {
  ISyncGoogleCampaigns,
  IUpdateGoogleCampaign,
} from '@domain/interfaces/dashboard/AdSense/Google/IGoogleCampaign';

export class GoogleCampaignService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public syncGoogleCampaigns({ storeAliasId }: ISyncGoogleCampaigns): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/google/ad-sense-campaigns/sync`,
    );
  }

  public updateGoogleCampaign({
    adSenseCampaignAliasId,
    data,
    productAliasId,
    storeAliasId,
  }: IUpdateGoogleCampaign): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/google/ad-sense-campaigns/${adSenseCampaignAliasId}`,
      { ...data },
    );
  }
}

const googleCampaingService = new GoogleCampaignService(LONG_API_DOMAIN);

export default googleCampaingService;
