import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { IParams } from '@domain/interfaces/IParams';

import { useDowngrade } from '@helpers/hooks/common/store/subscription/useDowngrade';
import { useSubscription } from '@helpers/hooks/common/store/subscription/useSubscription';

import { ALL_PLANS } from '@constants/plans';

import NextStep from '../NextStep';
import ProfileOption from './ProfileOption';

import * as S from './styles';

const FacebookProfiles: React.FC = () => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { facebookProfiles, selectedFacebookProfiles, maxFacebookProfiles } = useDowngrade();
  const { selectedPlanIdentifier } = useSubscription();

  const selectedPlan =
    (ALL_PLANS.find(
      plan =>
        plan.identifier === selectedPlanIdentifier ||
        plan.quarterIdentifier === selectedPlanIdentifier ||
        plan.semiAnualIdentifier === selectedPlanIdentifier,
    ) as typeof ALL_PLANS[number]) || undefined;

  const selectedPlanFacebookProfiles =
    selectedPlan?.facebookProfile === 0 ? Infinity : selectedPlan?.facebookProfile;

  const hasToDowngradeFacebookProfiles =
    facebookProfiles.length > (selectedPlanFacebookProfiles || 0);

  React.useEffect(() => {
    if (!hasToDowngradeFacebookProfiles) {
      history.push(`/${storeAliasId}/downgrade/store-workflows`);
    }
  }, [hasToDowngradeFacebookProfiles, history, storeAliasId]);

  const hasExceededQuantity = selectedFacebookProfiles.length > maxFacebookProfiles;

  return (
    <S.Wrapper>
      <S.Title type={EHeadingSize.H3}>Perfis do Facebook</S.Title>

      <S.Description>Selecione os perfis do Facebook que deseja manter:</S.Description>

      <S.FacebookProfilesOptionsWrapper>
        {facebookProfiles.map(profile => (
          <ProfileOption adSenseCredential={profile} key={profile.alias_id} />
        ))}
      </S.FacebookProfilesOptionsWrapper>

      <S.SelectedQuantity
        size={ETextSize.B5}
        weight={ETextWeight.MEDIUM}
        hasExceededQuantity={hasExceededQuantity}
      >
        <mark>{selectedFacebookProfiles.length}</mark>
        {`/${maxFacebookProfiles} usuários selecionado(s)`}
      </S.SelectedQuantity>

      <NextStep />
    </S.Wrapper>
  );
};

export default FacebookProfiles;
