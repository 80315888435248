import styled, { css } from 'styled-components/macro';

import { IHeading } from '@domain/interfaces/components/IHeading';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';

export const Heading = styled.h1<IHeading>`
  color: ${({ theme }) => theme.colors.font};
  margin: 0;

  ${({ type }) =>
    type === EHeadingSize.H1 &&
    css`
      font-size: 3rem;
      line-height: 100%;
    `};

  ${({ type }) =>
    type === EHeadingSize.H2 &&
    css`
      font-size: 2.25rem;
      line-height: 110%;
    `};

  ${({ type }) =>
    type === EHeadingSize.H3 &&
    css`
      font-size: 1.5rem;
      line-height: 120%;
    `};

  ${({ type }) =>
    type === EHeadingSize.H4 &&
    css`
      font-size: 1.25rem;
      line-height: 120%;
    `};

  ${({ type }) =>
    type === EHeadingSize.H5 &&
    css`
      font-size: 1.125rem;
      line-height: 120%;
    `};

  ${({ type }) =>
    type === EHeadingSize.H6 &&
    css`
      font-size: 1rem;
      line-height: 120%;
    `};

  ${({ fontWeight }) =>
    fontWeight === EHeadingWeight.LIGHT &&
    css`
      font-weight: 300;
    `};

  ${({ fontWeight }) =>
    fontWeight === EHeadingWeight.MEDIUM &&
    css`
      font-weight: 500;
    `};

  ${({ fontWeight }) =>
    fontWeight === EHeadingWeight.REGULAR &&
    css`
      font-weight: 400;
    `};

  ${({ fontWeight }) =>
    fontWeight === EHeadingWeight.SEMIBOLD &&
    css`
      font-weight: 600;
    `};

  ${({ isHiddenContent }) =>
    isHiddenContent &&
    css`
      background-color: ${({ theme }) => theme.colors.background[2]};
      margin: 2px 0;
      text-overflow: unset !important;

      span {
        opacity: 0;
      }
    `}
`;
