import React from 'react';
import { Facebook } from '@profitfy/pakkun-icons';

import * as S from './styles';

const FacebookIcon: React.FC = () => {
  return (
    <S.Wrapper>
      <Facebook size={36} />
    </S.Wrapper>
  );
};

export default FacebookIcon;
