import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresPixCancelledValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresPixCancelledValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresPixCancelledValuesResponse => {
  const currentSelectedStorePixCancelledAmountValue =
    currentSelectedStoreFinancialDetailsReportData?.pix?.canceled?.amount;

  const currentSelectedStorePixCancelledQuantityValue =
    currentSelectedStoreFinancialDetailsReportData?.pix?.canceled?.quantity;

  const storesPixCancelledAmountValues = selectedStoresData?.map(
    value => value.financial_details_report?.pix?.canceled?.amount,
  );

  const storesPixCancelledQuantityValues = selectedStoresData?.map(
    value => value.financial_details_report?.pix?.canceled?.quantity,
  );

  const pixCancelledAmountValues = [
    ...storesPixCancelledAmountValues,
    currentSelectedStorePixCancelledAmountValue,
  ];

  const pixCancelledQuantityValues = [
    ...storesPixCancelledQuantityValues,
    currentSelectedStorePixCancelledQuantityValue,
  ];

  const reducedPixCancelledAmountValues = pixCancelledAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedPixCancelledQuantityValues = pixCancelledQuantityValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  return {
    pix_cancelled_amount: reducedPixCancelledAmountValues,
    pix_cancelled_quantity: reducedPixCancelledQuantityValues,
  };
};
