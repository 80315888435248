import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import DropDownBase from '@components/common/core/Utils/DropDown';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import ButtonBase from '@components/common/core/Inputs/Button';

export const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-bottom: 24px;
  align-items: center;
`;

export const SearchProductInput = styled(TextFieldBase)`
  grid-area: 2 / 5 / 3 / 6;
`;

export const Title = styled(HeadingBase)`
  grid-area: 1 / 1 / 2 / 5;
`;

export const OptionButton = styled.button`
  border: none;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.background[5]};
  border-radius: 5px;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.font};
  font-size: 1rem;
  cursor: pointer;

  & > svg {
    margin-left: 16px;
  }
`;

export const OptionDropDown = styled(DropDownBase)``;

export const EditAllCostsButton = styled(ButtonBase)`
  box-shadow: unset;
  cursor: pointer;
  grid-area: 2 / 4 / 3 / 5;
  margin-left: auto;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background[2]};
    box-shadow: unset;
  }

  & > span {
    color: ${({ theme }) => theme.colors.white.default};
    font-size: 0.875rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const FilterButtonWrapper = styled.div`
  grid-area: 2 / 1 / 3 / 2;
`;
