export const hasChartCurrency = (selectedChart: string): string | undefined =>
  ({
    revenue: 'R$',
    cogs: 'R$',
    marketing: 'R$',
    taxes: 'R$',
    profit: 'R$',
    compensated_boletos: 'R$',
    pending_orders: 'R$',
    overview_finance: 'R$',
  }[selectedChart] || undefined);
