import React from 'react';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { IDataProps } from '@domain/interfaces/components/ITable';

import * as S from './styles';

const Data: React.ForwardRefRenderFunction<HTMLTableCellElement, IDataProps> = (
  { align = EHeadAlign.LEFT, colSpan, children, ...rest },
  ref,
) => {
  return (
    <S.Data align={align} colSpan={colSpan} ref={ref} {...rest}>
      {children}
    </S.Data>
  );
};

export default React.forwardRef(Data);
