import React from 'react';

import { Shopify as ShopifyIcon } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { IShopifyConfigProps } from '@domain/interfaces/dashboard/IntegrationCenter/SideModalContent/StoreProvider/IShopifyConfig';
import { EShopifyCredentialType } from '@domain/enums/common/integrations/providers/EShopify';

import CredentialOption from './CredentialOption';
import CredentialOptionWithUpdateCredential from './CredentialOptionWithUpdateCredential';
import GatewayOption from './GatewayOption';
import SyncOption from './SyncOption';

import * as S from './styles';

const ShopifyConfig: React.FC<IShopifyConfigProps> = ({ storeDomain, credentialType }) => {
  const theme: any = useTheme();

  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const onChangeTab = React.useCallback(index => setSelectedTab(index), []);

  return (
    <S.ContentWrapper>
      <S.Header>
        <ShopifyIcon size={52} color={theme.colors.gray[2]} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.REGULAR}>
          Integração com Shopify
        </S.Heading>
      </S.Header>

      <S.Tab defaultTab={selectedTab} onChangeTab={onChangeTab}>
        <S.Option>Geral</S.Option>
        <S.Option>Sincronização</S.Option>
        <S.Option>Gateway</S.Option>
      </S.Tab>

      {selectedTab === 0 && credentialType === EShopifyCredentialType.CUSTOM_APP ? (
        <CredentialOptionWithUpdateCredential storeDomain={storeDomain} />
      ) : (
        <CredentialOption />
      )}

      {selectedTab === 1 && <SyncOption />}

      {selectedTab === 2 && <GatewayOption />}
    </S.ContentWrapper>
  );
};

export default ShopifyConfig;
