import React from 'react';

import { EHeadAlign } from '@domain/enums/components/ETable';

import Skeleton from 'react-loading-skeleton';
import Table from '@components/common/core/Utils/Table';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  const randomArray = Array.from(Array(10).keys());

  return (
    <S.Container>
      <S.Table>
        <Table.Header>
          <Table.Row>
            <Table.Head>N. do Pedido</Table.Head>
            <Table.Head>Produto</Table.Head>
            <Table.Head>Receita Líquida</Table.Head>
            <Table.Head align={EHeadAlign.RIGHT}>C. Produtos</Table.Head>
            <S.Table.Head align={EHeadAlign.RIGHT}>Frete</S.Table.Head>
            <Table.Head align={EHeadAlign.CENTER}>Taxas</Table.Head>
            <Table.Head align={EHeadAlign.CENTER}>Impostos</Table.Head>
            <Table.Head align={EHeadAlign.CENTER}>Lucro</Table.Head>
            <S.Table.Head align={EHeadAlign.CENTER}>Status</S.Table.Head>
            <S.Table.Head align={EHeadAlign.CENTER} />
            <S.Table.Head />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {randomArray.map(element => (
            <Table.Row key={element} borderBottom>
              <Table.Data>
                <S.OrderDetails>
                  <S.IconWrapper>
                    <Skeleton width={38} height={38} style={{ borderRadius: '50%' }} />
                  </S.IconWrapper>

                  <S.OrderTextWrapper>
                    <Skeleton width={78} height={27} />
                    <Skeleton width={73} height={27} />
                  </S.OrderTextWrapper>
                </S.OrderDetails>
              </Table.Data>

              <Table.Data>
                <S.ProductWrapper>
                  <Skeleton width={195} height={27} />
                  <Skeleton width={51} height={23} />
                </S.ProductWrapper>
              </Table.Data>

              <Table.Data>
                <S.RevenueWrapper>
                  <Skeleton width={102} height={27} />
                  <Skeleton width={102} height={27} />
                </S.RevenueWrapper>
              </Table.Data>

              <Table.Data align={EHeadAlign.RIGHT}>
                <S.TextWrapper>
                  <Skeleton width={72} height={27} />
                </S.TextWrapper>
              </Table.Data>

              <Table.Data>
                <S.TextWrapper>
                  <Skeleton width={72} height={27} />
                </S.TextWrapper>
              </Table.Data>

              <Table.Data>
                <S.TextWrapper>
                  <Skeleton width={72} height={27} />
                </S.TextWrapper>
              </Table.Data>

              <Table.Data>
                <S.TextWrapper>
                  <Skeleton width={72} height={27} />
                </S.TextWrapper>
              </Table.Data>

              <Table.Data>
                <S.TextWrapper>
                  <Skeleton width={106} height={31} style={{ borderRadius: '15px' }} />
                </S.TextWrapper>
              </Table.Data>

              <Table.Data>
                <S.TextWrapper>
                  <Skeleton width={106} height={31} style={{ borderRadius: '15px' }} />
                </S.TextWrapper>
              </Table.Data>

              <Table.Data>
                <Skeleton width={30} height={30} style={{ borderRadius: '50%', marginRight: 16 }} />
                <Skeleton width={30} height={30} style={{ borderRadius: '50%' }} />
              </Table.Data>

              <Table.Data />
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.Data colSpan={8} align={EHeadAlign.RIGHT}>
              <Skeleton width={145} height={32} />
            </Table.Data>
          </Table.Row>
        </Table.Footer>
      </S.Table>
    </S.Container>
  );
};

export default SkeletonLoading;
