import React from 'react';

import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { EInfoProductProvider } from '@domain/enums/common/integrations/EInfoProductProvider';
import { IStoreIntegrations } from '@domain/interfaces/stores/IStores';
import { IInfoProductProvider } from '@domain/interfaces/onboarding/infoProduct/IInfoProduct';

const InfoProductContext = React.createContext<IInfoProductProvider | null>(null);

export const InfoProductProvider: React.FC = ({ children }) => {
  const [storeIntegrations, setStoreIntegrations] = React.useState<IStoreIntegrations | undefined>(
    undefined,
  );
  const [selectedPlatform, setSelectedPlatform] = React.useState<EInfoProductProvider | undefined>(
    undefined,
  );
  const [selectedMarketings, setSelectedMarketings] = React.useState<Array<EAdSenseProvider>>([]);
  const [hasSelectedStoreIntegrations, setHasSelectedStoreIntegrations] = React.useState<boolean>(
    false,
  );
  const [currentMarketingToIntegrate, setCurrentMarketingToIntegrate] = React.useState<
    EAdSenseProvider | undefined
  >(undefined);

  const handleSelectedPlatform = React.useCallback(platform => setSelectedPlatform(platform), []);

  const handleStoreIntegrations = React.useCallback(
    integrations => setStoreIntegrations(integrations),
    [],
  );

  const handleSelectedMarketings = React.useCallback(
    marketings => setSelectedMarketings(marketings),
    [],
  );

  const handleSelectedStoreIntegrations = React.useCallback(
    status => setHasSelectedStoreIntegrations(status),
    [],
  );

  const handleNextMarketingToIntegrate = React.useCallback(() => {
    if (selectedMarketings.length) {
      const selectedMarketingsArrayCopy = [...selectedMarketings];

      const nextMarketingToIntegrate = selectedMarketingsArrayCopy.shift();

      setCurrentMarketingToIntegrate(nextMarketingToIntegrate);
      setSelectedMarketings([...selectedMarketingsArrayCopy]);

      return true;
    }

    setCurrentMarketingToIntegrate(undefined);

    return false;
  }, [selectedMarketings]);

  React.useEffect(() => {
    if (storeIntegrations) {
      const foundStoreProvider = storeIntegrations.store_front.find(provider => provider.is_active);

      if (foundStoreProvider) setSelectedPlatform(foundStoreProvider.name as EInfoProductProvider);
    }
  }, [storeIntegrations]);

  return (
    <InfoProductContext.Provider
      value={{
        handleStoreIntegrations,
        storeIntegrations,
        handleSelectedMarketings,
        selectedMarketings,
        handleSelectedStoreIntegrations,
        hasSelectedStoreIntegrations,
        handleNextMarketingToIntegrate,
        currentMarketingToIntegrate,
        handleSelectedPlatform,
        selectedPlatform,
      }}
    >
      {children}
    </InfoProductContext.Provider>
  );
};

export const useInfoProduct = (): IInfoProductProvider => {
  const context = React.useContext(InfoProductContext);

  if (!context) {
    throw new Error('useInfoProduct must be used within InfoProductProvider');
  }

  return context;
};
