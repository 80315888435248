import styled, { css } from 'styled-components/macro';

import { IMoreOptionsWrapperProps } from '@domain/interfaces/dashboard/ProductCostDetails/IRow';

export const ActionsButton = styled.button<IMoreOptionsWrapperProps>`
  width: 26px;
  height: 26px;
  align-items: center;
  justify-content: center;
  gap: 2px;
  opacity: 0;
  display: flex;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  border-radius: 50%;
  transition: filter 0.3s;
  border: unset;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  ${({ isHovering }) =>
    isHovering &&
    css`
      opacity: 1;
    `}
`;
