import React from 'react';

export const getDescription = {
  pejotaplus: (): JSX.Element => (
    <>
      A Pejota.Plus é uma empresa de contabilidade que tem muita experiência no digital e
      disponibilizou a <strong>abertura gratuita da sua empresa.</strong>
    </>
  ),
  appmax: (): JSX.Element => (
    <>
      A Appmax é um Gateway de <br /> Pagamento que presta serviços <br /> essenciais para o seu
      negócio, e te <br />
      disponibilizou <strong>R$500 de Cashback.</strong>
    </>
  ),
  zendrop: (): JSX.Element => (
    <>
      O <strong>Zendrop Select</strong> é o plano exclusivo <br /> de atendimento para clientes de
      ALTO VOLUME.
    </>
  ),
};
