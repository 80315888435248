import React from 'react';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { ICpm } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IAdSenseCampaign';

import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const Cpm: React.FC<ICampaignProps> = ({ data }) => {
  const cpmData: ICpm = data;

  return (
    <S.Wrapper>
      <S.CpmText>{`R$${numberFormatter(cpmData.amount, 2)}`}</S.CpmText>
    </S.Wrapper>
  );
};

export default Cpm;
