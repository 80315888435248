import React from 'react';

interface IArrowProps {
  size: number;
}

const Arrow: React.FC<IArrowProps> = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50 25L41.6667 20.1887V29.8113L50 25ZM0 25.8333H42.5V24.1667H0V25.8333Z"
        fill="#A7AFC6"
      />
    </svg>
  );
};

export default Arrow;
