import React from 'react';
import { useTheme } from 'styled-components';

import { EGateway } from '@domain/enums/common/EGateway';

import { useStoreIntegrations } from '@helpers/hooks/pages/dashboard/integrationCenter/useStoreIntegrations';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import {
  findGatewaysIntegration,
  getStoreIntegrations,
} from '@helpers/utils/dashboard/integrationsCenter/gateways';

import { GATEWAYS } from '@constants/common/integrations';

import IntegrationCard from '@components/Dashboard/IntegrationCenter/IntegrationCard';
import Text from '@components/common/core/DataDisplay/Text';
import SkeletonLoadingCard from '../../SkeletonLoadingCard';

import * as S from './styles';

const Gateway: React.FC = () => {
  const randomArray = Array.from(Array(5).keys());

  const theme = useTheme();
  const {
    searchIntegrationsValue,
    isSideModalOpen,
    handleSideModalOpen,
    handleSelectedIntegration,
    selectedIntegration,
  } = useStoreIntegrations();
  const { storeIntegrations, isLoadingStore, isValidatingStore } = useStoreConfig();

  const SideModalContent = GATEWAYS.find(
    integration => integration.identifier === selectedIntegration,
  )?.sideModal;

  const handleIntegrationIsActive = React.useCallback(
    (identifier: EGateway) => {
      return findGatewaysIntegration(storeIntegrations, identifier);
    },
    [storeIntegrations],
  );

  const onGatewayIntegrationCardClick = React.useCallback(
    (identifier: EGateway) => {
      handleSideModalOpen();
      handleSelectedIntegration(identifier);
    },
    [handleSelectedIntegration, handleSideModalOpen],
  );

  const newFilteredActiveIntegrations = getStoreIntegrations(
    storeIntegrations,
    searchIntegrationsValue,
    GATEWAYS,
  );

  if (isLoadingStore || isValidatingStore) {
    return (
      <S.GatewayWrapper>
        <S.Heading>Gateway de Pagamento</S.Heading>

        <S.CardsWrapper>
          {randomArray.map(element => (
            <SkeletonLoadingCard key={element} />
          ))}
        </S.CardsWrapper>
      </S.GatewayWrapper>
    );
  }

  return (
    <S.GatewayWrapper>
      <S.Heading>Gateway de Pagamento</S.Heading>

      <S.CardsWrapper>
        {newFilteredActiveIntegrations.map(filteredIntegration => {
          const iconColor =
            filteredIntegration.identifier === EGateway.OTHER_GATEWAY && theme.colors.green.default;

          return (
            <IntegrationCard
              icon={filteredIntegration.icon}
              iconColor={iconColor || undefined}
              isActivated={handleIntegrationIsActive(filteredIntegration.identifier)}
              title={filteredIntegration.name}
              key={filteredIntegration.id}
              onClick={() => onGatewayIntegrationCardClick(filteredIntegration.identifier)}
            />
          );
        })}

        {!newFilteredActiveIntegrations.length && (
          <Text>Não encontramos nenhuma integração que contenha esse nome.</Text>
        )}
      </S.CardsWrapper>

      {SideModalContent && (
        <S.SideModal isOpen={isSideModalOpen} toggle={handleSideModalOpen}>
          <SideModalContent />
        </S.SideModal>
      )}
    </S.GatewayWrapper>
  );
};

export default Gateway;
