/* eslint-disable no-restricted-syntax */
import React from 'react';
import { useParams } from 'react-router-dom';

import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';
import { IFilterQueryStringData } from '@domain/interfaces/components/IFilter';
import { ITikTokCredentialsProvider } from '@domain/interfaces/integrations/marketing/tiktok/ITikTokCredentials';

import { useToast } from '@helpers/hooks/useToast';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import { ADS_PROVIDERS } from '@constants/common/integrations';

import adSenseCredentialService from '@services/pages/dashboard/adSense/adSenseCredential';

const TikTokCredentialsContext = React.createContext<ITikTokCredentialsProvider | null>(null);

export const TikTokCredentialsProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { addSynchronization } = useSynchronization();
  const { storeAliasId } = useParams<IParams>();

  const [isLoggingIn] = React.useState<boolean>(false);
  const [isLoggingOut, setIsLoggingOut] = React.useState<boolean>(false);
  const [adAccountSearchName, setAdAccountSearchName] = React.useState<string>('');

  const [adSenseCredentialsFilter] = React.useState<IFilterQueryStringData>({
    provider: EAdSenseProvider.TIKTOK,
  });

  const {
    adSenseCredentials,
    isLoading: isLoadingAdSenseCredentials,
    isValidating: isValidatingAdSenseCredentials,
    mutate: mutateAdSenseCredentials,
    error: adSenseCredentialsError,
  } = adSenseCredentialService.listAdSenseCredentials({
    storeAliasId,
    page: 1,
    filter: adSenseCredentialsFilter,
  });

  const mutate = React.useCallback(async () => {
    const promises = [];

    if (adSenseCredentialsError) promises.push(mutateAdSenseCredentials());

    await Promise.all(promises);
  }, [adSenseCredentialsError, mutateAdSenseCredentials]);

  const handleAdAccountSearchName = React.useCallback(newSearchName => {
    setAdAccountSearchName(newSearchName);
  }, []);

  const syncAllAdSenseAccounts = React.useCallback(async () => {
    const foundTikTokIntegration = ADS_PROVIDERS.find(
      integration => integration.identifier === EAdSenseProvider.TIKTOK,
    ) as typeof ADS_PROVIDERS[number];

    for await (const adSenseCredential of adSenseCredentials) {
      const { generateSyncRequest, name } = foundTikTokIntegration;

      const aliases = {
        adSenseCredentialAliasId: adSenseCredential.alias_id,
      };

      addSynchronization({
        name,
        type: ESynchronizationType.AD_SENSE_ACCOUNTS,
        label: 'Perfil',
        dependencies: [ESynchronizationType.AD_SENSE_ACCOUNTS],
        storeAliasId,
        externalId: adSenseCredential.alias_id,
        config: {
          isOnboardingSynchronization: false,
          showNotification: true,
        },
        request: generateSyncRequest(
          ESynchronizationType.AD_SENSE_ACCOUNTS,
          storeAliasId,
          aliases,
          undefined,
        ),
      });

      await new Promise(resolve => setTimeout(resolve, 500));
    }
  }, [adSenseCredentials, addSynchronization, storeAliasId]);

  const syncAdSenseAccounts = React.useCallback(
    (adSenseCredentialAliasId: string | undefined) => {
      const foundTikTokIntegration = ADS_PROVIDERS.find(
        integration => integration.identifier === EAdSenseProvider.TIKTOK,
      ) as typeof ADS_PROVIDERS[number];

      const { generateSyncRequest, name } = foundTikTokIntegration;

      const aliases = {
        adSenseCredentialAliasId,
      };

      addSynchronization({
        name,
        type: ESynchronizationType.AD_SENSE_ACCOUNTS,
        label: 'Perfil',
        dependencies: [ESynchronizationType.AD_SENSE_ACCOUNTS],
        storeAliasId,
        externalId: adSenseCredentialAliasId,
        config: {
          isOnboardingSynchronization: false,
          showNotification: true,
        },
        request: generateSyncRequest(
          ESynchronizationType.AD_SENSE_ACCOUNTS,
          storeAliasId,
          aliases,
          undefined,
        ),
      });
    },
    [addSynchronization, storeAliasId],
  );

  const handleLogout = React.useCallback(
    async adSenseCredentialAliasId => {
      setIsLoggingOut(true);

      try {
        await adSenseCredentialService.deleteAdSenseCredential({
          storeAliasId,
          adSenseCredentialAliasId,
        });

        toast.success('Perfil desconectado com sucesso!');

        await mutateAdSenseCredentials();

        setIsLoggingOut(false);
      } catch (error: any) {
        setIsLoggingOut(false);
      }
    },
    [storeAliasId, toast, mutateAdSenseCredentials],
  );

  const isLoadingTikTokCredentialsData = isLoadingAdSenseCredentials;
  const isValidatingTikTokCredentialsData = isValidatingAdSenseCredentials;
  const isTikTokCredentialsDataError = Boolean(adSenseCredentialsError);

  return (
    <TikTokCredentialsContext.Provider
      value={{
        adSenseCredentials,
        isLoadingTikTokCredentialsData,
        isValidatingTikTokCredentialsData,
        mutate,
        handleLogout,
        isLoggingIn,
        isLoggingOut,
        adAccountSearchName,
        handleAdAccountSearchName,
        syncAllAdSenseAccounts,
        syncAdSenseAccounts,
        mutateAdSenseCredentials,
        isTikTokCredentialsDataError,
      }}
    >
      {children}
    </TikTokCredentialsContext.Provider>
  );
};

export const useTikTokCrendentials = (): ITikTokCredentialsProvider => {
  const context = React.useContext(TikTokCredentialsContext);

  if (!context) {
    throw new Error('useTikTokCrendentials must be used within TikTokCredentialsProvider');
  }

  return context;
};
