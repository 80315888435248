import React from 'react';

import { ENotificationType } from '@domain/enums/dashboard/notification/ENotificationType';

import CustomValueBillingCreatedIcon from '@components/common/core/Utils/Notification/CustomValueBillingCreatedIcon';
import FacebookIcon from '@components/common/core/Utils/Notification/FacebookIcon';
import ProductFirstTimeOrder from '@components/common/core/Utils/Notification/ProductFirstTimeOrder';

export const getNotificationType = (type: string | undefined): React.FC => {
  if (type === ENotificationType.CUSTOM_VALUE_BILLING_CREATED) return CustomValueBillingCreatedIcon;

  if (type === ENotificationType.FACEBOOK_AD_SENSE_ACCOUNT_BLOCKED) return FacebookIcon;

  if (type === ENotificationType.PRODUCT_FIRST_TIME_ORDER) return ProductFirstTimeOrder;

  return React.Fragment;
};
