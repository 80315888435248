import React from 'react';
import { LojaIntegrada as LojaIntegradaIcon } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ELojaIntegradaType } from '@domain/enums/common/integrations/providers/ELojaIntegrada';
import { IParams } from '@domain/interfaces/IParams';
import { IIntegrateFormProps } from '@domain/interfaces/dashboard/IntegrationCenter/SideModalContent/IIntegrateForm';
import { ICreateLojaIntegradaCredentialData } from '@domain/interfaces/dashboard/IntegrationCenter/Ecommerce/LojaIntegrada/ILojaIntegradaAuth';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { lojaIntegradaSchema } from '@helpers/validators/dashboard/integrationCenter/lojaIntegrada';

import lojaIntegradaAuthService from '@services/pages/dashboard/integrationCenter/ecommerce/lojaIntegrada/lojaIntegradaAuth';

import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';
import Save from '@components/Dashboard/IntegrationCenter/SideModalContent/Save';

import * as S from './styles';

const IntegrateForm: React.FC<IIntegrateFormProps> = ({ mutate }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(lojaIntegradaSchema),
  });
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { mutateStore } = useStoreConfig();

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async data => {
      setIsSubmittingForm(true);

      try {
        const payload: ICreateLojaIntegradaCredentialData = {
          store_key: data.store_key,
          is_active: true,
          type: [ELojaIntegradaType.STORE],
        };

        await lojaIntegradaAuthService.createLojaIntegradaCredential({ storeAliasId, payload });

        toast.success('Credencial cadastrada com sucesso!');

        await mutateStore();

        setIsSubmittingForm(false);

        if (mutate) {
          mutate();
        }
      } catch (error: any) {
        setIsSubmittingForm(false);
        toast.error(getTranslatedIntegrationsError(error));
      }
    },
    [toast, mutateStore, mutate, storeAliasId],
  );

  return (
    <S.ContentWrapper>
      <S.Header>
        <LojaIntegradaIcon size={52} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com Loja Integrada
        </S.Heading>
      </S.Header>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>
        <S.FunctionalityDescriptionText>
          A integração com sua plataforma de e-commerce serve para puxar seus dados de loja.
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <S.TutorialWrapper>
        <S.TutorialHeading type={EHeadingSize.H5}>Tutorial de integração</S.TutorialHeading>
        <S.TutorialText>Basta adicionar a Chave API abaixo.</S.TutorialText>
      </S.TutorialWrapper>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label htmlFor="store_key">Chave API</S.Label>

          <S.Input {...register('store_key')} type="text" id="store_key" placeholder="Chave API" />
          {errors.store_key && <Text isErrorFeedback>{errors.store_key.message}</Text>}
        </S.InputGroup>

        <Save
          hasCredentials={false}
          submitText="Salvar alterações"
          isDeleting={isSubmittingForm}
          isDeleteButtonDisabled={isSubmittingForm}
          isSaveButtonDisabled={isSubmittingForm}
          isSubmitting={isSubmittingForm}
          cancelText="Cancelar"
        />
      </Form>
    </S.ContentWrapper>
  );
};

export default IntegrateForm;
