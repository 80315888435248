import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ImageBase from '@components/common/core/DataDisplay/Image';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Image = styled(ImageBase)`
  width: 100%;
  max-width: 302px;
`;

export const Heading = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const Description = styled(TextBase)`
  margin-bottom: 32px;

  & > strong {
    color: ${({ theme }) => theme.colors.green.default};
  }
`;
