import styled from 'styled-components/macro';

export const CardWrapper = styled.div`
  width: 296px;
  height: 232px;

  & > span {
    display: block;
    height: 0px;

    & > span {
      display: block;
    }
  }
`;
