import React from 'react';

import * as S from './styles';

const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

const Header: React.FC<any> = ({ date, decreaseMonth, increaseMonth }: any) => {
  return (
    <S.HeaderWrapper>
      <S.PreviousMonthButton type="button" onClick={decreaseMonth}>{`<`}</S.PreviousMonthButton>

      <S.Text>{`${months[date.getMonth()]} ${date.getFullYear()}`}</S.Text>

      <S.NextMonthButton type="button" onClick={increaseMonth}>{`>`}</S.NextMonthButton>
    </S.HeaderWrapper>
  );
};

export default Header;
