import React from 'react';
import { CurrencyDollarSimple } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import * as S from './styles';

const CustomValueBillingCreatedIcon: React.FC = () => {
  const theme = useTheme();

  return (
    <S.Wrapper>
      <CurrencyDollarSimple size={36} color={theme.colors.green.default} />
    </S.Wrapper>
  );
};

export default CustomValueBillingCreatedIcon;
