import styled, { css } from 'styled-components/macro';

import { ITabOptionProps } from '@domain/interfaces/dashboard/StoreSubscription/ITab';

export const Option = styled.button<ITabOptionProps>`
  height: 40px;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: unset;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray[1]};
  border: unset;
  border-bottom: 1px solid transparent;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.green.default};
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      color: ${theme.colors.green.default};
      border-bottom: 1px solid ${theme.colors.green.default};
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
