import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 100%;
  padding: 16px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[5]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  &:last-child {
    border-bottom: unset;
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const TitleAndDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const PaymentDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;
