import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';
import { Barcode } from 'phosphor-react';

export const BOLETO_STARTER = [
  {
    id: 'boleto-starter-first-condition',
    title: 'Recuperação de Boleto (1ª condição)',
    type: 'BOLETO',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Barcode,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Parabéns pelo seu pedido, {{first_name}}! 🎉\n\nEle já foi separado e está pronto para ser enviado! 🚚\n\n📌 Detalhes do Pedido: #{{order_id}}\n{{products_with_variant}}\n\n💵 Valor total: {{total_amount}}\n\n⚠️ Se ainda não tiver efetuado o pagamento, gere um boleto pelo link abaixo:',
      },
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content: '{{boleto_url}}',
      },
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content: 'Observação: Em caso de pagamento realizado, por favor, ignore esta mensagem.',
      },
    ],
  },
  {
    id: 'boleto-starter-second-condition',
    title: 'Recuperação de Boleto (2ª condição)',
    type: 'BOLETO',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Barcode,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          '{{first_name}}, não deixe seu pedido expirar! 😥\n\nEstamos apenas aguardando a confirmação do pagamento para enviá-lo! 🚚\n\nCopie e cole o código abaixo para pagar via Internet Banking:',
      },
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content: '{{boleto_url}}',
      },
    ],
  },
];
