import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const VerifyButton = styled(ButtonBase)`
  margin-left: auto;
`;

export const Input = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Description = styled(TextBase)`
  display: inline-block;
  margin-bottom: 32px;
`;

export const Modal = styled(ModalBase)`
  & > div:last-child {
    max-width: 428px;
  }
`;
