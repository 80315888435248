import { ApiService } from '@services/api';
import { API_DASHBOARD_DOMAIN } from '@constants/api';
import { IGetBestSellingProductProps } from '@domain/interfaces/dashboard/BenefitArea/IBestSellingProduct';

export class ZendropService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getBestSellingProduct({ storeAliasId }: IGetBestSellingProductProps): Promise<any> {
    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/products/zendrop/best-selling`,
    );
  }
}

const zendropService = new ZendropService(API_DASHBOARD_DOMAIN);

export default zendropService;
