import React from 'react';
import { Bell } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import { useNotification } from '@helpers/hooks/common/store/notification/useNotification';

import Content from './Content';

import * as S from './styles';

const Notification: React.FC = () => {
  const theme = useTheme();
  const { resetNotificationsFeed, hasUnseenNotifications } = useNotification();

  const [isNotificationCenterOpen, setIsNotificationCenterOpen] = React.useState<boolean>(false);

  const onOpenChange = React.useCallback(
    state => {
      setIsNotificationCenterOpen(state);

      if (!state) {
        resetNotificationsFeed();
      }
    },
    [resetNotificationsFeed],
  );

  return (
    <S.DropDown open={isNotificationCenterOpen} onOpenChange={onOpenChange}>
      <S.DropDownTrigger>
        <Bell color={theme.colors.gray[3]} size={24} />

        {hasUnseenNotifications && <S.UnseenNotificationsAlert />}
      </S.DropDownTrigger>

      <S.DropDownContent>
        <Content />

        <S.DropDownArrow />
      </S.DropDownContent>
    </S.DropDown>
  );
};

export default Notification;
