import React from 'react';

import { EHeadingWeight } from '@domain/enums/components/EHeading';

import { useConfig } from '@helpers/hooks/useConfig';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import PejotaPlusImg from '@assets/dashboard/benefitArea/small-pj-plus.svg';

import * as S from './styles';

const MainSection: React.FC = () => {
  const { analytics } = useConfig();
  const { store } = useStoreConfig();

  const onActivateBenefitClick = React.useCallback(() => {
    analytics?.track(
      'Activate Benefit Button Clicked',
      { partner: 'Pejota.Plus' },
      { context: { groupId: store?.alias_id } },
    );
  }, [analytics, store]);

  return (
    <S.Container>
      <S.Content>
        <S.Image src={PejotaPlusImg} alt="profitfy-pejota-plus-small-image" />

        <S.Title fontWeight={EHeadingWeight.SEMIBOLD}>a pjrocks!</S.Title>

        <S.SubTitle fontWeight={EHeadingWeight.SEMIBOLD}>
          Te ajudando a trabalhar certo, <br /> enquanto você economiza dinheiro.
        </S.SubTitle>

        <S.Description>
          Garantimos que sua empresa tenha a estrutura tributária correta para que você consiga{' '}
          <strong>economizar dinheiro</strong>, ao invés de pagar um Civic sempre que for declarar
          seus impostos, por falta de entendimento.
        </S.Description>

        <S.Link
          href="https://api.whatsapp.com/send?phone=5511987155948&text=Quero%20descomplicar%20minha%20contabilidade"
          target="_blank"
          onClick={onActivateBenefitClick}
        >
          <S.ActivateBenefitButton>Ativar meu benefício</S.ActivateBenefitButton>
        </S.Link>
      </S.Content>
    </S.Container>
  );
};

export default MainSection;
