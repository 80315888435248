import React from 'react';
import {
  ResponsiveContainer,
  BarChart as Chart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
} from 'recharts';

import { IGroupedBarChart } from '@domain/interfaces/components/IGroupedBarChart';
import { friendlyChartNumberFormatter } from '@helpers/masks';

import ChartTooltip from './ChartTooltip';
import * as S from './styles';

const GroupedBarChart: React.FC<IGroupedBarChart> = ({ data, initialConfig, currency }) => {
  return (
    <S.ChartWrapper>
      <ResponsiveContainer width="100%">
        <Chart data={data} margin={{ bottom: 16 }}>
          <XAxis dataKey="name" tick={{ dy: 24 }} axisLine={false} tickLine={false} />
          <YAxis
            tick={{ dx: -10 }}
            axisLine={false}
            tickLine={false}
            tickFormatter={tick => friendlyChartNumberFormatter(tick, 2)}
          />
          <CartesianGrid strokeDasharray="10 10" vertical={false} />
          <Tooltip
            cursor={{ fill: 'rgba(66, 64, 64, 0.2)' }}
            content={<ChartTooltip currency={currency} />}
          />
          {initialConfig.map(bar => {
            return (
              <Bar
                key={bar.name}
                dataKey={bar.dataKey}
                stackId="stacked"
                fill={bar.fill}
                barSize={54}
                name={bar.name}
                isAnimationActive
              />
            );
          })}
          <ReferenceLine y={0} stroke="white" />
        </Chart>
      </ResponsiveContainer>
    </S.ChartWrapper>
  );
};

export default React.memo(GroupedBarChart);
