import React from 'react';
import { useParams } from 'react-router-dom';

import { ISelectedFilter, IFilterProps } from '@domain/interfaces/components/IFilter';
import { IParams } from '@domain/interfaces/IParams';
import { IAdSenseCredential } from '@domain/interfaces/dashboard/AdSense/IAdSenseCredential';
import { EFilterOperator } from '@domain/enums/components/EFilter';
import { EAdSenseProvider } from '@domain/enums/common/EAdSense';

import { useToast } from '@helpers/hooks/useToast';
import { useDebounce } from '@helpers/hooks/useDebounce';

import adSenseCredentialService from '@services/pages/dashboard/adSense/adSenseCredential';

import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const AdsManagerAdSenseCredential: React.FC<IFilterProps> = ({
  config,
  handleOnClick,
  defaultValue,
  updateData,
  addNewData,
}) => {
  const { config: filterConfig } = config;

  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();

  const [adSenseCredentials, setAdSenseCredentials] = React.useState<Array<IAdSenseCredential>>([]);
  const [optionToSearch, setOptionToSearch] = React.useState<string>('');
  const [isLoadingAdSenseCredentials, setIsLoadingAdSenseCredentials] = React.useState<boolean>(
    false,
  );

  const debouncedValue = useDebounce(optionToSearch, 700);

  const loadAdSenseCredentials = React.useCallback(async () => {
    setIsLoadingAdSenseCredentials(true);

    try {
      const { data } = await adSenseCredentialService.listAdSenseCredentialsPromise({
        storeAliasId,
        page: 0,
        filter: {
          provider: EAdSenseProvider.FACEBOOK,
        },
      });

      if (data.ad_sense_credentials) {
        setAdSenseCredentials(data.ad_sense_credentials);
      }

      setIsLoadingAdSenseCredentials(false);
    } catch (error: any) {
      setIsLoadingAdSenseCredentials(false);
      toast(error?.response?.data?.message);
    }
  }, [storeAliasId, toast]);

  const onSearchValueChange = React.useCallback(event => {
    setOptionToSearch(event.target.value);
  }, []);

  const handleOptionClick = React.useCallback(
    (adSenseCredential: IAdSenseCredential) => {
      const newData: ISelectedFilter = {
        field: config.field,
        label: adSenseCredential.name,
        value: adSenseCredential.id,
        aliasIdHelper: adSenseCredential.alias_id,
        operator: EFilterOperator.EQUAL,
      };

      if (defaultValue) {
        updateData(newData);
      } else {
        addNewData(newData);
      }

      handleOnClick();
    },
    [handleOnClick, config, addNewData, defaultValue, updateData],
  );

  React.useEffect(() => {
    loadAdSenseCredentials();
  }, [loadAdSenseCredentials]);

  const filteredCredentials = adSenseCredentials.filter(adSenseCredential =>
    adSenseCredential.name.toLowerCase().includes(debouncedValue.toLowerCase()),
  );

  return (
    <S.Wrapper>
      <S.ValueToFilterWrapper>
        <S.ValueToFilterInput
          type="text"
          placeholder={filterConfig.placeholder}
          onChange={onSearchValueChange}
          autoFocus
        />
      </S.ValueToFilterWrapper>

      {isLoadingAdSenseCredentials && <SkeletonLoading />}

      {filteredCredentials.map(adSenseCredential => (
        <S.AdSenseCredentialWrapper
          key={adSenseCredential.alias_id}
          onClick={() => handleOptionClick(adSenseCredential)}
        >
          <S.Avatar>{adSenseCredential.name.charAt(0)}</S.Avatar>
          <S.Name>{adSenseCredential.name}</S.Name>
        </S.AdSenseCredentialWrapper>
      ))}
    </S.Wrapper>
  );
};

export default AdsManagerAdSenseCredential;
