import React from 'react';
import {
  DndContext,
  useSensor,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { Coordinates } from '@dnd-kit/utilities';

import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import DraggableItem from './DraggableItem';

import * as S from './styles';

const defaultCoordinates = {
  x: -104,
  y: 16,
};

const Synchronization: React.FC = () => {
  const {
    currentSynchronizations,
    synchronizationsQueue,
    finishedSynchronizations,
    failedSynchronizations,
  } = useSynchronization();

  const parsedCurrentSynchronizations = currentSynchronizations
    ? currentSynchronizations.synchronizations
    : [];

  const mergedSynchronizations = [
    ...parsedCurrentSynchronizations,
    ...synchronizationsQueue,
    ...finishedSynchronizations,
    ...failedSynchronizations,
  ];

  const filteredSynchronizations = mergedSynchronizations.filter(
    synchronization => synchronization.config.showNotification,
  );

  const [coordinates, setCoordinates] = React.useState<Coordinates>(defaultCoordinates);

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const onDragEnd = React.useCallback(({ delta }) => {
    setCoordinates(previousState => ({
      x: previousState.x + delta.x,
      y: previousState.y + delta.y,
    }));
  }, []);

  return (
    <DndContext sensors={sensors} onDragEnd={onDragEnd} modifiers={[restrictToWindowEdges]}>
      {Boolean(filteredSynchronizations.length) && (
        <S.Wrapper>
          <DraggableItem top={coordinates.y} left={coordinates.x} />
        </S.Wrapper>
      )}
    </DndContext>
  );
};

export default Synchronization;
