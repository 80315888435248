import styled, { css } from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import { ICheckboxProps } from '@domain/interfaces/dashboard/Campaigns/ICampaigns';

export const Checkbox = styled(CheckboxBase)<ICheckboxProps>`
  & > div {
    ${({ checked, theme }) =>
      !checked &&
      css`
        background-color: ${theme.colors.gray[6]};
      `}
  }
`;

export const Table = styled(TableBase)`
  & > table {
    & > thead > tr {
      & > th {
        padding-left: 10px;
        padding-right: 10px;
      }
      & > th:first-child {
        padding-left: 16px;
        padding-right: 10px;
      }

      & > th:last-child {
        padding-right: 10px;
      }
    }

    & > tbody > tr {
      & > td {
        padding-left: 10px;
        padding-right: 10px;
      }
      & > td:first-child {
        padding-left: 16px;
        padding-right: 10px;
      }

      & > td:last-child {
        padding-right: 16px;
      }
    }
  }
`;

export const Wrapper = styled.div``;
