export enum EAutomationsTemplate {
  NO_CONTENT = 'no-content',
  ABANDONED_CART = 'abandoned-cart',
  ABANDONED_CART_2 = 'abandoned-cart-2',
  ABANDONED_CART_FREE = 'abandoned-cart-3',
  ABANDONED_CART_STARTER = 'abandoned-cart-4',
  REFUSED_CREDIT_CARD = 'refused-credit-card',
  REFUSED_CREDIT_CARD_FREE = 'refused-credit-card-2',
  REFUSED_CREDIT_CARD_STARTER = 'refused-credit-card-3',
  BOLETO = 'boleto',
  BOLETO_FREE = 'boleto-2',
  BOLETO_STARTER = 'boleto-3',
  PIX = 'pix',
  PIX_FREE = 'pix-2',
  PIX_STARTER = 'pix-3',
  SIMPLE_SHIPMENT = 'simple-shipment',
}
