import * as yup from 'yup';

export const createOrder = yup.object().shape({
  name: yup.string().required('É necessário um nome'),
  status: yup.string().required('É necessário um status'),
  type: yup.string().required('É necessário um tipo de pedido'),
  gateway_amount: yup.string().required('É necessário informar o custo do gateway'),
  cogs_amount: yup.string().required('É necessário informar o custo do gateway'),
  gateway_fee_amount: yup.string().required('É necessário informar o custo do gateway'),
  shipping_cost_owner_amount: yup.string().required('É necessário informar o custo do gateway'),
  info_product_fee_amount: yup.string().required('É necessário informar o custo do infoproduto'),
  // client_name: yup.string().required('É necessário um nome do cliente'),
  // email: yup.string().required('É necessário um email do cliente'),
  // phone_number: yup.string().required('É necessário um telefone do cliente'),
});
