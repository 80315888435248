import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import InstantHelpBase from '@components/common/core/Utils/InstantHelp';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
`;

export const Heading = styled(HeadingBase)`
  line-height: 130%;
`;

export const ArticlesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`;

export const InstantHelp = styled(InstantHelpBase)`
  max-width: 423px;
  max-height: 100px;

  @media only screen and (max-width: 905px) {
    max-width: unset;
  }
`;
