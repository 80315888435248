import React from 'react';
import { CogsCircle, SimpleCheck } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useParams, useHistory } from 'react-router-dom';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';

// import { useConfig } from '@helpers/hooks/useConfig';

import VideoModal from '@components/common/core/DataDisplay/VideoModal';

import * as S from './styles';

const CogsFeature: React.FC = () => {
  const theme = useTheme();
  // const { analytics, user } = useConfig();
  const { storeAliasId } = useParams<IParams>();
  const history = useHistory();

  const [isVideoModalOpen, setIsVideoModalOpen] = React.useState<boolean>(false);

  const handleVideoModalOpen = React.useCallback(() => setIsVideoModalOpen(!isVideoModalOpen), [
    isVideoModalOpen,
  ]);

  // const onVideoClick = React.useCallback(() => {
  //   analytics?.track(
  //     'Onboarding Product Costs Video Tutorial Clicked',
  //     {
  //       email: user?.email,
  //     },
  //     { context: { groupId: storeAliasId } },
  //   );

  //   handleVideoModalOpen();
  // }, [handleVideoModalOpen, analytics, storeAliasId, user]);

  const onFeatureClick = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/data/products-costs`);
  }, [history, storeAliasId]);

  return (
    <S.Wrapper>
      <S.Header>
        <CogsCircle size={28} />
        <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.MEDIUM}>
          Custo de produtos
        </S.Title>
      </S.Header>

      <S.Body>
        <S.Description>Veja se os custos de seus produtos estão todos corretos.</S.Description>

        <S.BenefitsWrapper>
          <S.Benefit>
            <SimpleCheck size={14} color={theme.colors.green.default} />
            <S.BenefitText>Exatidão dos dados</S.BenefitText>
          </S.Benefit>
          <S.Benefit>
            <SimpleCheck size={14} color={theme.colors.green.default} />
            <S.BenefitText>Confiabilidade dos dados</S.BenefitText>
          </S.Benefit>
        </S.BenefitsWrapper>

        {/* <S.VideoWrapper onClick={onVideoClick}>
          <PlayCircle size={48} />
        </S.VideoWrapper> */}

        <S.Link
          href="https://ajuda.profitfy.me/pt-BR/articles/5901668-como-alterar-custo-dos-produtos"
          target="_blank"
        >
          Veja o tutorial
        </S.Link>
        <S.FeaturePageButton onClick={onFeatureClick}>
          Ajustar custos de produtos
        </S.FeaturePageButton>

        <VideoModal
          isOpen={isVideoModalOpen}
          toggle={handleVideoModalOpen}
          src="https://www.youtube.com/embed/vWAC8Wkt9ok?autoplay=1"
        />
      </S.Body>
    </S.Wrapper>
  );
};

export default CogsFeature;
