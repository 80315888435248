import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background-color: #25d366;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 32px;
  gap: 5px;
  margin-left: 10px;
`;

export const IntegrationName = styled(TextBase)`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[1]};
  line-height: 1;
`;

export const Name = styled(TextBase)`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white.dark};
  line-height: 1;
`;

export const SummaryContainer = styled.div`
  width: 100%;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.background[2]};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

export const StatsNumber = styled(TextBase)`
  font-size: 1.125rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.green.default};
  line-height: 1;
`;

export const StatsDescription = styled(TextBase)`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[2]};
  line-height: 1;
`;
