import { ApiService } from '@services/api';
import { API_DOMAIN } from '@constants/api';

import {
  IGetCredentialProps,
  ICreateCredentialProps,
  IGenerateURLProps,
  IUpdateCredentialProps,
} from '@domain/interfaces/dashboard/IntegrationCenter/InfoProduct/Platforms/IPaytAuth';

export class PaytService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getCredential({ storeAliasId }: IGetCredentialProps): Promise<any> {
    return this.apiService.get(`/api/v1/users/stores/${storeAliasId}/payt-credentials`);
  }

  public createCredential({ storeAliasId, data }: ICreateCredentialProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/payt-credentials`, {
      ...data,
    });
  }

  public updateCredential({
    storeAliasId,
    credentialAliasId,
    data,
  }: IUpdateCredentialProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/payt-credentials/${credentialAliasId}`,
      {
        ...data,
      },
    );
  }

  public generateWebhookURL({ storeAliasId }: IGenerateURLProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/payt-credentials/generate-webhook-url`,
    );
  }
}

const paytService = new PaytService(API_DOMAIN);

export default paytService;
