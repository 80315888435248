import React from 'react';

import Header from '@components/Dashboard/ProductCostDetails/Header';
import Table from '@components/Dashboard/ProductCostDetails/Table';
import Details from '@components/Dashboard/ProductCostDetails/Details';
import Save from '@components/Dashboard/ProductCostDetails/Save';
import { useProductCostDetails } from '@helpers/hooks/pages/dashboard/productCostDetails/productCostDetails';
import PageError from '@components/common/core/Utils/PageError';
import SyncPastCogsModal from '../SyncPastCogsModal';
import SyncPastCogsSidemodal from '../SyncPastCogsSidemodal';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const {
    productError,
    variantsError,
    mutateProduct,
    mutateVariants,
    isValidatingProduct,
    isValidatingVariants,
  } = useProductCostDetails();

  const mutate = React.useCallback(async () => {
    const promises = [];

    if (productError) promises.push(mutateProduct());
    if (variantsError) promises.push(mutateVariants());

    await Promise.all(promises);
  }, [mutateProduct, mutateVariants, productError, variantsError]);

  if ((productError && !isValidatingProduct) || (variantsError && !isValidatingVariants)) {
    return <PageError mutate={mutate} />;
  }

  return (
    <S.Container>
      <Header />
      <Details />
      <Table />
      <Save />
      <SyncPastCogsModal />
      <SyncPastCogsSidemodal />
    </S.Container>
  );
};

export default Wrapper;
