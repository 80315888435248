import React from 'react';

import { ECampaignStatus } from '@domain/enums/dashboard/adsManager/ECampaigns';

import { useAdsManager } from '@helpers/hooks/pages/dashboard/adsManager/useAdsManager';

import * as S from './styles';

const CampaignsSelectContent: React.FC<any> = ({ handleDropdownOpen }) => {
  const { campaignsStatusFilter, handleCampaignsStatusFilter } = useAdsManager();

  const onStatusClick = React.useCallback(
    status => {
      if (status === 'ALL') {
        handleCampaignsStatusFilter([ECampaignStatus.PAUSED, ECampaignStatus.ENABLED]);
      } else {
        handleCampaignsStatusFilter([status]);
      }

      handleDropdownOpen(false);
    },
    [handleCampaignsStatusFilter, handleDropdownOpen],
  );

  const isSelected = React.useCallback(
    (statusToCompare: ECampaignStatus) => {
      if (campaignsStatusFilter.length > 1) return false;

      return campaignsStatusFilter[0] === statusToCompare;
    },
    [campaignsStatusFilter],
  );

  return (
    <S.Content side="bottom" sideOffset={5}>
      <S.ButtonsWrapper>
        <S.ActionButton
          onClick={() => onStatusClick(ECampaignStatus.ENABLED)}
          isSelected={isSelected(ECampaignStatus.ENABLED)}
        >
          Ativo
        </S.ActionButton>
        <S.ActionButton
          onClick={() => onStatusClick(ECampaignStatus.PAUSED)}
          isSelected={isSelected(ECampaignStatus.PAUSED)}
        >
          Inativo
        </S.ActionButton>
        <S.ActionButton
          onClick={() => onStatusClick('ALL')}
          isSelected={campaignsStatusFilter.length > 1}
        >
          Todas Campanhas
        </S.ActionButton>
      </S.ButtonsWrapper>

      <S.Arrow />
    </S.Content>
  );
};

export default CampaignsSelectContent;
