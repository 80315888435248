import React from 'react';
import Skeleton from 'react-loading-skeleton';

import Table from '@components/common/core/Utils/Table';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <S.TableHead />
          <S.TableHead />
          <S.TableHead />
          <S.TableHead />
          <S.TableHead />
          <S.TableHead />
          <S.TableHead />
          <S.TableHead />
          <S.TableHead />
          <S.TableHead />
          <S.TableHead />
          <S.TableHead />
          <S.TableHead />
          <S.TableHead />
          <S.TableHead />
          <S.TableHead />
        </Table.Row>
      </Table.Header>

      <Table.Body>
        <Table.Row>
          <Table.Data>
            <Skeleton width={504} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
        </Table.Row>

        <Table.Row>
          <Table.Data>
            <Skeleton width={504} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
          <Table.Data>
            <Skeleton width={76} height={24} />
          </Table.Data>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default SkeletonLoading;
