import React from 'react';
import { Star } from 'phosphor-react';

import * as S from './styles';

interface ICardProps {
  text: string;
  authorName: string;
  authorSocialMedia: string;
}

const Card: React.FC<ICardProps> = ({ text, authorName, authorSocialMedia }) => {
  return (
    <S.Card>
      <S.Text>{text}</S.Text>

      <S.Spacer />

      <S.AuthorAndRating>
        <S.Author>
          <S.AuthorName>{authorName}</S.AuthorName>
          <S.AuthorSocialMedia>{authorSocialMedia}</S.AuthorSocialMedia>
        </S.Author>

        <S.Rating>
          <Star size={20} color="#FFC46C" weight="fill" />
          <Star size={20} color="#FFC46C" weight="fill" />
          <Star size={20} color="#FFC46C" weight="fill" />
          <Star size={20} color="#FFC46C" weight="fill" />
          <Star size={20} color="#FFC46C" weight="fill" />
        </S.Rating>
      </S.AuthorAndRating>
    </S.Card>
  );
};

export default Card;
