import {
  Eduzz,
  Evermart,
  Hotmart,
  Kiwify,
  Monetizze,
  Payt,
  PerfectPay,
} from '@profitfy/pakkun-icons';

import { EInfoProductProvider } from '@domain/enums/common/integrations/EInfoProductProvider';
import { IInfoProductProvider } from '@domain/interfaces/common/integration/IInfoProductProvider';

import {
  getNoRetroactiveGatewaySynchronizationRequestConfig,
  getNoRetroactiveSynchronizationRequestConfig,
} from '@helpers/utils/integrations/infoProductProviders/noRetroactive';

import { getEduzzSynchronizationRequestConfig } from '@helpers/utils/integrations/infoProductProviders/eduzz';
import { getHotmartSynchronizationRequestConfig } from '@helpers/utils/integrations/infoProductProviders/hotmart';
import { getMonetizzeSynchronizationRequestConfig } from '@helpers/utils/integrations/infoProductProviders/monetizze';
import { getPerfectPaySynchronizationRequestConfig } from '@helpers/utils/integrations/infoProductProviders/perfectPay';

import EduzzSideModal from '@components/Dashboard/IntegrationCenter/SideModalContent/InfoProductProvider/Eduzz';
import EvermartSideModal from '@components/Dashboard/IntegrationCenter/SideModalContent/InfoProductProvider/Evermart';
import HotmartSideModal from '@components/Dashboard/IntegrationCenter/SideModalContent/InfoProductProvider/Hotmart';
import KiwifySideModal from '@components/Dashboard/IntegrationCenter/SideModalContent/InfoProductProvider/Kiwify';
import MonetizzeSideModal from '@components/Dashboard/IntegrationCenter/SideModalContent/InfoProductProvider/Monetizze';
import PaytSideModal from '@components/Dashboard/IntegrationCenter/SideModalContent/InfoProductProvider/Payt';
import PerfectPaySideModal from '@components/Dashboard/IntegrationCenter/SideModalContent/InfoProductProvider/PerfectPay';

import OnboardingEduzzContent from '@components/Onboarding/InfoProduct/Platforms/Eduzz';
import OnboardingEvermartContent from '@components/Onboarding/InfoProduct/Platforms/Evermart';
import OnboardingHotmartContent from '@components/Onboarding/InfoProduct/Platforms/Hotmart';
import OnboardingKiwifyContent from '@components/Onboarding/InfoProduct/Platforms/Kiwify';
import OnboardingMonetizzeContent from '@components/Onboarding/InfoProduct/Platforms/Monetizze';
import OnboardingPaytContent from '@components/Onboarding/InfoProduct/Platforms/Payt';
import OnboardingPerfectPayContent from '@components/Onboarding/InfoProduct/Platforms/PerfectPay';

export const INFO_PRODUCT_PROVIDERS: Array<IInfoProductProvider> = [
  {
    id: 'kiwify',
    name: 'Kiwify',
    icon: Kiwify,
    sideModal: KiwifySideModal,
    identifier: EInfoProductProvider.KIWIFY,
    lowerIdentifier: 'kiwify',
    onboardingContent: OnboardingKiwifyContent,
    generateSyncRequest: getNoRetroactiveSynchronizationRequestConfig,
    canSyncRetroactive: false,
    gateways: [],
    generateGatewaySyncRequest: getNoRetroactiveGatewaySynchronizationRequestConfig,
    idToVerifyStatus: 'has_kiwify_credential',
  },
  {
    id: 'evermart',
    name: 'Evermart',
    icon: Evermart,
    sideModal: EvermartSideModal,
    identifier: EInfoProductProvider.EVERMART,
    lowerIdentifier: 'evermart',
    onboardingContent: OnboardingEvermartContent,
    generateSyncRequest: getNoRetroactiveSynchronizationRequestConfig,
    canSyncRetroactive: false,
    gateways: [],
    generateGatewaySyncRequest: getNoRetroactiveGatewaySynchronizationRequestConfig,
    idToVerifyStatus: 'has_evermart_credential',
  },
  {
    id: 'hotmart',
    name: 'Hotmart',
    icon: Hotmart,
    sideModal: HotmartSideModal,
    identifier: EInfoProductProvider.HOTMART,
    lowerIdentifier: 'hotmart',
    onboardingContent: OnboardingHotmartContent,
    generateSyncRequest: getHotmartSynchronizationRequestConfig,
    canSyncRetroactive: true,
    gateways: [],
    generateGatewaySyncRequest: getNoRetroactiveGatewaySynchronizationRequestConfig,
    idToVerifyStatus: 'has_hotmart_credential',
  },
  {
    id: 'monetizze',
    name: 'Monetizze',
    icon: Monetizze,
    sideModal: MonetizzeSideModal,
    identifier: EInfoProductProvider.MONETIZZE,
    lowerIdentifier: 'monetizze',
    onboardingContent: OnboardingMonetizzeContent,
    generateSyncRequest: getMonetizzeSynchronizationRequestConfig,
    canSyncRetroactive: true,
    gateways: [],
    generateGatewaySyncRequest: getNoRetroactiveGatewaySynchronizationRequestConfig,
    idToVerifyStatus: 'has_monetizze_credential',
  },
  {
    id: 'eduzz',
    name: 'Eduzz',
    icon: Eduzz,
    sideModal: EduzzSideModal,
    identifier: EInfoProductProvider.EDUZZ,
    lowerIdentifier: 'eduzz',
    onboardingContent: OnboardingEduzzContent,
    generateSyncRequest: getEduzzSynchronizationRequestConfig,
    canSyncRetroactive: true,
    gateways: [],
    generateGatewaySyncRequest: getNoRetroactiveGatewaySynchronizationRequestConfig,
    idToVerifyStatus: 'has_eduzz_credential',
  },
  {
    id: 'payt',
    name: 'Payt',
    icon: Payt,
    sideModal: PaytSideModal,
    identifier: EInfoProductProvider.PAYT,
    lowerIdentifier: 'payt',
    onboardingContent: OnboardingPaytContent,
    generateSyncRequest: getNoRetroactiveSynchronizationRequestConfig,
    canSyncRetroactive: false,
    gateways: [],
    generateGatewaySyncRequest: getNoRetroactiveGatewaySynchronizationRequestConfig,
    idToVerifyStatus: 'has_payt_credential',
  },
  {
    id: 'perfect_pay',
    name: 'Perfect Pay',
    icon: PerfectPay,
    sideModal: PerfectPaySideModal,
    identifier: EInfoProductProvider.PERFECT_PAY,
    lowerIdentifier: 'perfect_pay',
    onboardingContent: OnboardingPerfectPayContent,
    generateSyncRequest: getPerfectPaySynchronizationRequestConfig,
    canSyncRetroactive: true,
    gateways: [],
    generateGatewaySyncRequest: getNoRetroactiveGatewaySynchronizationRequestConfig,
    idToVerifyStatus: 'has_perfect_pay_credential',
  },
];
