import React from 'react';

import { EDeniedCardTotalValueOptions } from '@domain/enums/dashboard/automations/node/conditionalNode/EDeniedCard';

import * as S from './styles';

interface IOptionTotalValueProps {
  defaultValue?: string;
  onChange: (data: string) => void;
}

const OptionTotalValue: React.FC<IOptionTotalValueProps> = ({
  onChange,
  defaultValue = EDeniedCardTotalValueOptions.SMALLER,
}) => {
  return (
    <S.Select
      name="options"
      defaultValue={defaultValue}
      onChange={e => {
        onChange(e.target.value);
      }}
    >
      <S.Option value={EDeniedCardTotalValueOptions.SMALLER}>Menor que</S.Option>
      <S.Option value={EDeniedCardTotalValueOptions.SMALLER_EQUAL}>Menor ou igual</S.Option>
      <S.Option value={EDeniedCardTotalValueOptions.EQUAL}>Igual</S.Option>
      <S.Option value={EDeniedCardTotalValueOptions.NOT_EQUAL}>Diferente</S.Option>
      <S.Option value={EDeniedCardTotalValueOptions.LARGER}>Maior que</S.Option>
      <S.Option value={EDeniedCardTotalValueOptions.LARGER_EQUAL}>Maior ou igual</S.Option>
    </S.Select>
  );
};

export default OptionTotalValue;
