import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Wrapper = styled.div`
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.gray[4]};
  border-radius: 12px;
  padding: 16px;
  box-sizing: border-box;
`;

export const NoSavedContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NoSavedContentBody = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

export const NoSavedContentFooter = styled.div`
  width: calc(100% + 32px);
  margin-bottom: -16px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  @media only screen and (max-width: 955px) {
    padding: 16px 22px;
    flex-direction: column;
    gap: 14px;
    align-items: flex-start;
  }
`;

export const FileInfosWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const FileNameAndSizeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FileName = styled(TextBase)`
  line-height: 120%;
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const FileSize = styled(TextBase)`
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[3]};
`;

export const IconBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  border-radius: 5px;
  background-color: #5b8def;
`;

export const SavedContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IconAndTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const SavedContentText = styled(TextBase)`
  line-height: 120%;
`;

export const Title = styled(TextBase)`
  font-size: 1.125rem;
  line-height: 120%;
  margin-bottom: 4px;
  margin-top: 12px;
`;

export const Description = styled(TextBase)`
  line-height: 120%;
  margin-bottom: 4px;
`;

export const SupportedExtensionsText = styled(TextBase)`
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[3]};
  margin-bottom: 37px;
`;

export const NoSupportedFormatErrorText = styled(TextBase)`
  line-height: 120%;
  margin-top: -28px;
  margin-bottom: 8px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;

  @media only screen and (max-width: 955px) {
    width: 100%;
  }
`;

export const SaveButton = styled(ButtonBase)`
  @media only screen and (max-width: 955px) {
    width: 100%;
  }
`;

export const CancelButton = styled(ButtonBase)`
  @media only screen and (max-width: 955px) {
    width: 100%;
  }
`;

export const EditAndDeleteMessageButtonsWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const EditMessageButton = styled.button`
  background: unset;
  border: unset;
  padding: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const DeleteMessageButton = styled.button`
  background: unset;
  border: unset;
  padding: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;
