import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';

export const Logo = styled(ImageBase)`
  display: none;

  @media only screen and (max-width: 885px) {
    display: block;
    max-width: 132px;
    width: 100%;
    margin-bottom: 82px;
    margin-left: 32px;
    position: relative;
  }
`;

export const Wrapper = styled.div`
  padding: 48px 0;
  margin: 0 auto;
  max-width: 452px;
  width: 100%;

  @media only screen and (max-width: 885px) {
    max-width: unset;

    background: linear-gradient(
      8.42deg,
      #000000 -5.05%,
      #000000 10.18%,
      #050609 48.24%,
      #11131a 72.41%
    );
  }
`;
