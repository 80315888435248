import queryString from 'query-string';
import { format } from 'date-fns';

import { ApiService } from '@services/api';
import { IChartProps } from '@domain/interfaces/dashboard/Chart/IChart';
import { API_DASHBOARD_DOMAIN } from '@constants/api';

export class ChartService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getGatewayRevenue({
    storeAliasId,
    startDate,
    endDate,
    filter,
  }: IChartProps): Promise<any> {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/financial-charts/net-revenue?start_date=${format(
        startDate,
        'yyyy-MM-dd',
      )}&end_date=${format(endDate, 'yyyy-MM-dd')}&${queryStringFilter}`,
    );
  }

  public getFinancialSummary({
    storeAliasId,
    startDate,
    endDate,
    filter,
  }: IChartProps): Promise<any> {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/financial-charts/financial-summary?start_date=${format(
        startDate,
        'yyyy-MM-dd',
      )}&end_date=${format(endDate, 'yyyy-MM-dd')}&${queryStringFilter}`,
    );
  }

  public getCogs({ storeAliasId, startDate, endDate, filter }: IChartProps): Promise<any> {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/financial-charts/cogs?start_date=${format(
        startDate,
        'yyyy-MM-dd',
      )}&end_date=${format(endDate, 'yyyy-MM-dd')}&${queryStringFilter}`,
    );
  }

  public getMarketing({ storeAliasId, startDate, endDate, filter }: IChartProps): Promise<any> {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/financial-charts/marketing?start_date=${format(
        startDate,
        'yyyy-MM-dd',
      )}&end_date=${format(endDate, 'yyyy-MM-dd')}&${queryStringFilter}`,
    );
  }

  public getFee({ storeAliasId, startDate, endDate, filter }: IChartProps): Promise<any> {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/financial-charts/fees?start_date=${format(
        startDate,
        'yyyy-MM-dd',
      )}&end_date=${format(endDate, 'yyyy-MM-dd')}&${queryStringFilter}`,
    );
  }

  public getNetProfit({ storeAliasId, startDate, endDate, filter }: IChartProps): Promise<any> {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/financial-charts/net-profit?start_date=${format(
        startDate,
        'yyyy-MM-dd',
      )}&end_date=${format(endDate, 'yyyy-MM-dd')}&${queryStringFilter}`,
    );
  }
}

const chartService = new ChartService(API_DASHBOARD_DOMAIN);

export default chartService;
