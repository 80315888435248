import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.Wrapper>
      <S.AvatarWrapper>
        <Skeleton width={64} height={64} style={{ borderRadius: '50%' }} />

        <S.AvatarTextWrapper>
          <Skeleton width={64} height={28} />
          <S.NameWrapper>
            <Skeleton width="100%" height={24} />
          </S.NameWrapper>
        </S.AvatarTextWrapper>
      </S.AvatarWrapper>

      <S.PersonInfoWrapper>
        <S.HeaderWrapper>
          <Skeleton width="100%" height={24} />
        </S.HeaderWrapper>

        <S.InputGroupsWrapper>
          <S.InputGroup>
            <Skeleton width={52} height={19} />
            <Skeleton width="100%" height={42} />
          </S.InputGroup>
          <S.InputGroup>
            <Skeleton width={64} height={19} />
            <Skeleton width="100%" height={42} />
          </S.InputGroup>
        </S.InputGroupsWrapper>

        <S.InputGroupsWrapper>
          <S.InputGroup>
            <Skeleton width={56} height={19} />
            <Skeleton width="100%" height={42} />
          </S.InputGroup>
          <S.InputGroup>
            <Skeleton width={84} height={19} />
            {/* <Skeleton width="100%" height={42} /> */}

            <S.SelectWrapper>
              <S.OptionWrapper>
                <Skeleton width={84} height={42} />
              </S.OptionWrapper>
              <S.OptionWrapper>
                <Skeleton width={84} height={42} />
              </S.OptionWrapper>
              <S.OptionWrapper>
                <Skeleton width={84} height={42} />
              </S.OptionWrapper>
            </S.SelectWrapper>
          </S.InputGroup>
        </S.InputGroupsWrapper>

        <S.InputGroupsWrapper>
          <S.InputGroup>
            <Skeleton width={64} height={19} />
            <Skeleton width="100%" height={42} />
          </S.InputGroup>
          <S.InputGroup>
            <Skeleton width={64} height={19} />
            <Skeleton width="100%" height={42} />
          </S.InputGroup>
        </S.InputGroupsWrapper>

        <S.ButtonWrapper>
          <Skeleton width={164} height={42} />
        </S.ButtonWrapper>
      </S.PersonInfoWrapper>
    </S.Wrapper>
  );
};

export default SkeletonLoading;
