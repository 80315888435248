export const data = [
  {
    name: '01/07',
    past: 102,
    current: 118,
  },
  {
    name: '02/07',
    past: 98,
    current: 76,
  },
  {
    name: '03/07',
    past: 116,
    current: 165,
  },
  {
    name: '04/07',
    past: 121,
    current: 154,
  },
  {
    name: '05/07',
    past: 123,
    current: 145,
  },
  {
    name: '06/07',
    past: 119,
    current: 152,
  },
  {
    name: '07/07',
    past: 124,
    current: 176,
  },
  {
    name: '08/07',
    past: 187,
    current: 165,
  },
  {
    name: '09/07',
    past: 119,
    current: 209,
  },
  {
    name: '10/07',
    past: 198,
    current: 189,
  },
  {
    name: '11/07',
    past: 224,
    current: 176,
  },
  {
    name: '12/07',
    past: 199,
    current: 189,
  },
  {
    name: '13/07',
    past: 176,
    current: 214,
  },
  {
    name: '14/07',
    past: 156,
    current: 253,
  },
  {
    name: '15/07',
    past: 123,
    current: 289,
  },
  {
    name: '16/07',
    past: 134,
    current: 312,
  },
  {
    name: '17/07',
    past: 145,
    current: 345,
  },
  {
    name: '18/07',
    past: 142,
    current: 332,
  },
  {
    name: '19/07',
    past: 152,
    current: 311,
  },
  {
    name: '20/07',
    past: 142,
    current: 307,
  },
  {
    name: '21/07',
    past: 144,
    current: 296,
  },
  {
    name: '22/07',
    past: 131,
    current: 288,
  },
  {
    name: '23/07',
    past: 147,
    current: 299,
  },
  {
    name: '24/07',
    past: 167,
    current: 282,
  },
  {
    name: '25/07',
    past: 178,
    current: null,
  },
  {
    name: '26/07',
    past: 162,
    current: null,
  },
  {
    name: '27/07',
    past: 154,
    current: null,
  },
  {
    name: '28/07',
    past: 166,
    current: null,
  },
  {
    name: '29/07',
    past: 178,
    current: null,
  },
  {
    name: '30/07',
    past: 155,
    current: null,
  },
  {
    name: '31/07',
    past: 137,
    current: null,
  },
];
