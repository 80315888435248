import React from 'react';

import { EStatus } from '@domain/enums/subscription/ESubscription';

import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';

import Trial from './Content/Trial';
import TrialEnded from './Content/TrialEnded';
import Canceled from './Content/Canceled';
import Paid from './Content/Paid';
import Free from './Content/Free';
import Unlimited from './Content/Unlimited';

const CurrentPlan: React.FC = () => {
  const { subscription } = useStoreSubscription();

  const subscriptionStatus = subscription?.status;
  const isFreeSubscriptionPlan = subscription?.plan?.identifier.includes('free');
  const isUnlimitedSubscriptionPlan = subscription?.plan?.identifier.includes('partners');

  if (subscriptionStatus === EStatus.PAID && isUnlimitedSubscriptionPlan) return <Unlimited />;

  if (subscriptionStatus === EStatus.PAID && isFreeSubscriptionPlan) return <Free />;

  if (subscriptionStatus === EStatus.PAID) return <Paid />;

  if (subscriptionStatus === EStatus.CANCELED) return <Canceled />;

  if (subscriptionStatus === EStatus.TRIAL_ENDED) return <TrialEnded />;

  return <Trial />;
};

export default CurrentPlan;
