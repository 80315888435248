import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

export const SkeletonLoading: React.FC = () => {
  return (
    <S.Wrapper>
      <S.TableWrapper>
        <Skeleton width="100%" height={548} style={{ borderRadius: 15 }} />
      </S.TableWrapper>
    </S.Wrapper>
  );
};

export default SkeletonLoading;
