import React from 'react';
import { Facebook, GoogleAds, Taboola, TikTok } from '@profitfy/pakkun-icons';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { EBadgeColors } from '@domain/enums/components/EBadge';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { IRowProps } from '@domain/interfaces/dashboard/Campaigns/ICampaigns';

import { useCampaigns } from '@helpers/hooks/pages/dashboard/campaigns/useCampaigns';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useToast } from '@helpers/hooks/useToast';

import * as S from './styles';

const Row: React.FC<IRowProps> = ({ adSenseCampaign }) => {
  const { toast } = useToast();
  const { currentSynchronizations } = useSynchronization();
  const {
    isAllAdSenseCampaignsChecked,
    handleCheckedAdSenseCampaigns,
    checkedAdSenseCampaigns,
    updateAdSenseCampaign,
    isUpdatingCheckedAdSenseCampaigns,
  } = useCampaigns();

  const [canEdit, setCanEdit] = React.useState<boolean>(true);
  const [isChecked, setIsChecked] = React.useState<boolean>(false);
  const [isUpdatingCampaign, setIsUpdatingCampaign] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (
      currentSynchronizations?.synchronizations?.some(
        sync => sync.type === ESynchronizationType.UPDATE_AD_SENSE_CAMPAIGNS,
      )
    ) {
      setCanEdit(false);
    } else {
      setCanEdit(true);
    }
  }, [currentSynchronizations]);

  const onCheckboxChange = React.useCallback(
    event => {
      setIsChecked(event.checked);

      if (event.checked) {
        handleCheckedAdSenseCampaigns([...checkedAdSenseCampaigns, adSenseCampaign]);
      } else {
        const filteredCampaigns = checkedAdSenseCampaigns.filter(
          checkedCampaign => checkedCampaign.name !== adSenseCampaign.name,
        );

        handleCheckedAdSenseCampaigns([...filteredCampaigns]);
      }
    },
    [adSenseCampaign, checkedAdSenseCampaigns, handleCheckedAdSenseCampaigns],
  );

  const onSwitchChange = React.useCallback(
    async event => {
      if (adSenseCampaign.is_blocked_by_downgrade) {
        toast.error('Não é possível realizar a ação.');

        event.target.checked = !event.target.checked;

        return;
      }

      setIsUpdatingCampaign(true);

      await updateAdSenseCampaign({
        adSenseCampaignAliasId: adSenseCampaign.alias_id,
        data: { is_active: event.target.checked, provider: adSenseCampaign.provider },
      });

      setIsUpdatingCampaign(false);
    },
    [updateAdSenseCampaign, adSenseCampaign, toast],
  );

  React.useEffect(() => {
    setIsChecked(isAllAdSenseCampaignsChecked);
  }, [isAllAdSenseCampaignsChecked]);

  const getProviderIcon = (provider: string): any =>
    ({
      GOOGLE: <GoogleAds size={18} />,
      FACEBOOK: <Facebook size={18} />,
      TABOOLA: <Taboola size={18} />,
      TIKTOK: <TikTok size={18} />,
    }[provider] || 'Sem informação');

  const isAdAccountBlockedByDowngrade = adSenseCampaign.is_blocked_by_downgrade;

  return (
    <S.Table.Row borderBottom>
      <S.Table.Data>
        <S.Checkbox
          checked={isChecked}
          onChange={onCheckboxChange}
          disabled={isUpdatingCheckedAdSenseCampaigns || isAdAccountBlockedByDowngrade}
        />
      </S.Table.Data>
      <S.Table.Data>
        <S.CampaignNameWrapper>
          {getProviderIcon(adSenseCampaign.provider)}
          <S.CampaignName>{adSenseCampaign.name}</S.CampaignName>
        </S.CampaignNameWrapper>
      </S.Table.Data>
      <S.Table.Data>
        <S.ProviderStatusContainer>
          <S.ProviderStatusText>
            {adSenseCampaign?.ad_sense_campaign_insights[0]?.clicks || 0}
          </S.ProviderStatusText>
        </S.ProviderStatusContainer>
      </S.Table.Data>
      <S.Table.Data>
        <S.ProviderStatusContainer>
          <S.ProviderStatusText>
            {adSenseCampaign?.ad_sense_campaign_insights[0]?.impressions || 0}
          </S.ProviderStatusText>
        </S.ProviderStatusContainer>
      </S.Table.Data>
      <S.Table.Data>
        <S.ProviderStatusContainer>
          <S.ProviderStatusText>
            {adSenseCampaign?.ad_sense_campaign_insights[0]?.conversions || 0}
          </S.ProviderStatusText>
        </S.ProviderStatusContainer>
      </S.Table.Data>
      <S.Table.Data>
        <S.ProviderStatusContainer>
          <S.ProviderStatusText>
            {adSenseCampaign?.ad_sense_campaign_insights[0]?.all_conversions || 0}
          </S.ProviderStatusText>
        </S.ProviderStatusContainer>
      </S.Table.Data>
      <S.Table.Data>
        <S.ProviderStatusContainer>
          <S.ProviderStatusText>
            {adSenseCampaign?.ad_sense_campaign_insights[0]?.spend_amount || 0}
          </S.ProviderStatusText>
        </S.ProviderStatusContainer>
      </S.Table.Data>
      <S.Table.Data>
        <S.ProviderStatusContainer>
          <S.ProviderStatusText>{adSenseCampaign?.status}</S.ProviderStatusText>
        </S.ProviderStatusContainer>
      </S.Table.Data>

      <S.Table.Data align={EHeadAlign.CENTER}>
        <S.Badge
          secondary
          color={adSenseCampaign.is_operational ? EBadgeColors.GREEN : EBadgeColors.RED}
        >
          {adSenseCampaign.is_operational ? 'Operacional' : 'Não Operacional'}
        </S.Badge>
      </S.Table.Data>
      <S.Table.Data align={EHeadAlign.CENTER}>
        <S.CustomTooltip>
          <S.CustomTooltip.Trigger>
            <S.Switch
              id={`status-${adSenseCampaign.alias_id}`}
              name={`status-${adSenseCampaign.alias_id}`}
              defaultChecked={adSenseCampaign.is_active}
              isLoading={isUpdatingCampaign}
              onChange={canEdit ? onSwitchChange : () => null}
              disabled={!canEdit}
            />
          </S.CustomTooltip.Trigger>

          <S.CustomTooltip.Content style={{ width: 250 }} side="top">
            <S.Text>
              {`Você só pode sincronizar uma campanha de cada vez.\n Crie TAGS de sincronização para sincronizar mais de uma ao mesmo tempo`}
            </S.Text>
          </S.CustomTooltip.Content>
        </S.CustomTooltip>
      </S.Table.Data>
    </S.Table.Row>
  );
};

export default Row;
