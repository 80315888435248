import React from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { ADS_PROVIDERS } from '@constants/common/integrations';
import { IParams } from '@domain/interfaces/IParams';
import { getStoreIntegrations } from '@helpers/utils/dashboard/integrationsCenter/storeIntegrations';

import IntegrationCard from '@components/Dashboard/IntegrationCenter/IntegrationCard';
import Text from '@components/common/core/DataDisplay/Text';
import { useStoreIntegrations } from '@helpers/hooks/pages/dashboard/integrationCenter/useStoreIntegrations';
import SkeletonLoadingCard from '../../SkeletonLoadingCard';

import * as S from './styles';

const StoreIntegrations: React.FC = () => {
  const history = useHistory();
  const {
    isSideModalOpen,
    handleSideModalOpen,
    handleSelectedIntegration,
    selectedIntegration,
  } = useStoreIntegrations();

  const { storeAliasId } = useParams<IParams>();

  const randomArray = Array.from(Array(5).keys());

  const { storeIntegrations, isLoadingStore, isValidatingStore, store } = useStoreConfig();

  const activeIntegrations = getStoreIntegrations(storeIntegrations, store.type);

  const SideModalContent = activeIntegrations.find(
    integration => integration?.identifier === selectedIntegration,
  )?.sideModal;

  const onSelectedStoreIntegrationCardClick = React.useCallback(
    (identifier: string) => {
      const isMarketingIntegration = Boolean(
        ADS_PROVIDERS.find(integration => integration.identifier === identifier),
      );

      if (isMarketingIntegration) {
        history.push(`/${storeAliasId}/dashboard/data/integrations/${identifier.toLowerCase()}`);
      } else {
        handleSideModalOpen();
        handleSelectedIntegration(identifier);
      }
    },
    [handleSelectedIntegration, handleSideModalOpen, history, storeAliasId],
  );

  if (isLoadingStore || isValidatingStore) {
    return (
      <S.MyIntegrationsWrapper>
        <S.Heading>Minhas Integrações</S.Heading>

        <S.CardsWrapper>
          {randomArray.map(element => (
            <SkeletonLoadingCard key={element} />
          ))}
        </S.CardsWrapper>
      </S.MyIntegrationsWrapper>
    );
  }

  return (
    <S.MyIntegrationsWrapper>
      <S.Heading>Minhas Integrações</S.Heading>

      <S.CardsWrapper>
        {activeIntegrations.map(filteredIntegration => (
          <IntegrationCard
            icon={filteredIntegration?.icon}
            isActivated
            title={filteredIntegration?.name}
            key={filteredIntegration?.id}
            onClick={() => onSelectedStoreIntegrationCardClick(filteredIntegration?.identifier)}
          />
        ))}

        {!activeIntegrations.length && <Text>Você não possui integrações ativas.</Text>}
      </S.CardsWrapper>

      {SideModalContent && (
        <S.SideModal isOpen={isSideModalOpen} toggle={handleSideModalOpen}>
          <SideModalContent />
        </S.SideModal>
      )}
    </S.MyIntegrationsWrapper>
  );
};

export default StoreIntegrations;
