export enum EExperienceRange {
  LESS_THAN_6_MONTHS = 'LESS_THAN_6_MONTHS',
  BETWEEN_6_MONTHS_1_YEAR = 'BETWEEN_6_MONTHS_1_YEAR',
  BETWEEN_1_YEAR_2_YEARS = 'BETWEEN_1_YEAR_2_YEARS',
  BETWEEN_2_YEARS_3_YEARS = 'BETWEEN_2_YEARS_3_YEARS',
  MORE_THAN_3_YEARS = 'MORE_THAN_3_YEARS',
  NOT_INFORM = 'NOT_INFORM',
}

export enum EProfitRange {
  LESS_THAN_10_THOUSAND = 'LESS_THAN_10_THOUSAND',
  BETWEEN_10_50_THOUSAND = 'BETWEEN_10_50_THOUSAND',
  BETWEEN_50_100_THOUSAND = 'BETWEEN_50_100_THOUSAND',
  BETWEEN_100_250_THOUSAND = 'BETWEEN_100_250_THOUSAND',
  BETWEEN_250_500_THOUSAND = 'BETWEEN_250_500_THOUSAND',
  MORE_THAN_500_THOUSAND = 'MORE_THAN_500_THOUSAND',
  NOT_INFORM = 'NOT_INFORM',
}

export enum EUserSegmentationStep {
  FIRST_STEP = 'FIRST_STEP',
  SECOND_STEP = 'SECOND_STEP',
  THIRD_STEP = 'THIRD_STEP',
  FOURTH_STEP = 'FOUR_STEP',
}
