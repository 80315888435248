/* eslint-disable dot-notation */
import React from 'react';

import { Menu } from '@profitfy/pakkun-icons';
import { EAvatarSize } from '@domain/enums/components/EAvatar';
import { IStore } from '@domain/interfaces/stores/IStore';
import { useConfig } from '@helpers/hooks/useConfig';
import { useLocation } from 'react-router-dom';

import storesService from '@services/pages/stores/stores';

import Text from '@components/common/core/DataDisplay/Text';
import Dropdown from './Dropdown';

import * as S from './styles';

const NavBar: React.FC = () => {
  const { pathname } = useLocation();
  const { user, analytics, handleSelectedStore } = useConfig();

  const [currentStore, setCurrentStore] = React.useState<IStore>();
  const [storeAliasId, setStoreAliasId] = React.useState<string>('');

  const { store } = storesService.getStore({ storeAliasId });

  const trackNewsButton = React.useCallback(() => {
    analytics?.track(
      'New Features Post Viewed',
      { email: user?.email },
      { context: { groupId: currentStore?.alias_id } },
    );
  }, [analytics, user, currentStore]);

  React.useEffect(() => {
    if (store) {
      setCurrentStore(store);
      handleSelectedStore(store);
    }
  }, [store, handleSelectedStore]);

  React.useEffect(() => {
    let widget: any;

    const config = {
      key: '9346df30-3ffd-4e1d-9a5b-86d17ca4a4f2',
      callbacks: {
        onReady: (frillWidget: any) => {
          widget = frillWidget;
        },
      },
    };

    if ('Frill' in window) {
      widget = window['Frill'].widget(config);
    } else {
      window['Frill_Config'] = window['Frill_Config'] || [];
      window['Frill_Config'].push(config);
    }

    return () => {
      if (widget) {
        widget.destroy();
      }

      if (window['Frill_Config']) {
        window['Frill_Config'] = window['Frill_Config'].filter((c: any) => c !== config);
      }
    };
  }, []);

  React.useEffect(() => {
    const foundStoreAliasId = pathname.match(/store-([a-zA-Z0-9]+)/);

    if (foundStoreAliasId?.length) {
      setStoreAliasId(foundStoreAliasId[0]);
    }
  }, [pathname]);

  const currentStoreName = currentStore?.name;

  const formattedUserName = `${user.first_name} ${user.last_name}`;

  const avatarWord = currentStoreName?.charAt(0) || 'N';

  return (
    <S.NavBar>
      <S.TitleWrapper>
        <Menu size={24} />
        <Text>PROFITFY</Text>
      </S.TitleWrapper>

      <S.MenuWrapper>
        <S.NewsButton id="my-frill-widget" className="my-frill-widget" onClick={trackNewsButton}>
          Novidades
        </S.NewsButton>

        <Dropdown storeAliasId={storeAliasId}>
          <S.CurrentStoreWrapper>
            <S.StoreAvatar word={avatarWord} size={EAvatarSize.MEDIUM} alt="Avatar" />

            <S.UserDetails>
              <S.CurrentStoreName>{currentStoreName}</S.CurrentStoreName>
              <S.ProfileName>{formattedUserName}</S.ProfileName>
            </S.UserDetails>
          </S.CurrentStoreWrapper>
        </Dropdown>
      </S.MenuWrapper>
    </S.NavBar>
  );
};

export default React.memo(NavBar);
