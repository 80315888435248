import { Node } from 'react-flow-renderer';

import { ENodeType } from '@domain/enums/mappers/automations/profitfy/ENodeType';

import { REFUSED_CREDIT_CARD_FREE_NODES } from '@constants/pages/dashboard/automations/templates/nodes/refusedCreditCardFree';
import { MESSAGES_TEMPLATES } from '@constants/pages/dashboard/automations/messages/templates';

export const getRefusedCreditCardFreeNodesArray = (): Array<Node<any>> => {
  const newWhatsappNodes: Array<Node<any>> = [];

  const nodesWithoutWhatsappNodes = REFUSED_CREDIT_CARD_FREE_NODES.filter(
    node => node.type !== ENodeType.WHATSAPP,
  );

  REFUSED_CREDIT_CARD_FREE_NODES.forEach(node => {
    if (node.type === ENodeType.WHATSAPP) {
      const foundTemplate = MESSAGES_TEMPLATES.find(
        template => template.id === node.data.message?.id,
      );

      newWhatsappNodes.push({
        ...node,
        data: {
          ...node.data,
          category: foundTemplate?.category,
          type: foundTemplate?.type,
          message: {
            title: foundTemplate?.title,
            message_contents: foundTemplate?.message_contents,
            id: foundTemplate?.id,
          },
        },
      });
    }
  });

  return [...nodesWithoutWhatsappNodes, ...newWhatsappNodes];
};
