import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { USER_API_DOMAIN } from '@constants/api';
import {
  ICalculateTrialDaysProps,
  ICalculateTrialDaysResponse,
} from '@domain/interfaces/subscription/IMigration';

export class MigrationService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public calculateTrialDays({
    storeAliasId,
  }: ICalculateTrialDaysProps): ICalculateTrialDaysResponse {
    const { data, error, isValidating, mutate } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/subscriptions/calculate-trial-days`,
      this.apiService,
    );

    const isLoading = !error && !data;

    return {
      storeCase: data?.case,
      trialDays: data?.trial_days,
      mutate,
      error,
      isLoading,
      isValidating,
    };
  }

  public calculateTrialDaysPromise({ storeAliasId }: ICalculateTrialDaysProps): Promise<any> {
    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/subscriptions/calculate-trial-days`,
    );
  }
}

const migrationService = new MigrationService(USER_API_DOMAIN);

export default migrationService;
