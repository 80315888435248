import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  .react-datepicker {
    background-color: ${({ theme }) => theme.colors.background[2]};
    border: 1px solid ${({ theme }) => theme.colors.gray[4]};
    display: flex;
    flex-direction: row;
    width: fit-content;
    max-height: 270px;
    border-radius: 5px;
  }

  .react-datepicker__input-container {
    input {
      width: 148px;
      height: 36px;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.background[2]};
      border: unset;
      padding: unset;
      color: ${({ theme }) => theme.colors.gray[1]};
      /* font-family: 'Poppins'; */
      font-family: 'Mukta';
      font-size: 0.875rem;
      font-weight: 500;
      padding: 10px 12px;
      box-sizing: border-box;
    }
  }

  .react-datepicker__header {
    border-bottom: unset;
  }

  .react-datepicker__time-container {
    border-left: 1px solid ${({ theme }) => theme.colors.gray[4]};
  }

  .react-datepicker__header--time {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[4]};
  }

  .react-datepicker__time-list {
    height: unset !important;
    padding: 6px 28px;
    box-sizing: border-box;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }

  .react-datepicker__time {
    background-color: ${({ theme }) => theme.colors.background[2]};

    .react-datepicker__time-box {
      max-height: 210px;
      overflow: hidden scroll;

      &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.colors.gray[4]};
        border-radius: 25px;
        margin-bottom: 4px;
      }
    }

    .react-datepicker__time-list-item {
      width: 100%;
      padding-top: 4px;
      padding-bottom: 4px;
      color: #fff;
      font-size: 0.875rem;
      /* font-family: 'Poppins'; */
      font-family: 'Mukta';
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[6]} !important;
      }
    }
  }

  .react-datepicker__navigation {
    position: absolute;
  }

  .react-datepicker__navigation--next {
    right: 12px;
  }

  .react-datepicker-time__header {
    color: ${({ theme }) => theme.colors.green.dark};
    /* font-family: 'Poppins'; */
    font-family: 'Mukta';
    padding: 16px 22px;
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.background[2]};
  }

  .react-datepicker__month-container {
    .react-datepicker__header {
      .react-datepicker__current-month {
        text-align: center;
        font-size: 1rem;
        font-family: ${({ theme }) => theme.fonts.body};
        color: ${({ theme }) => theme.colors.green.dark};
        font-weight: 500;
      }

      .react-datepicker__day-names {
        display: flex;
        margin-bottom: 10px;

        .react-datepicker__day-name {
          width: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: ${({ theme }) => theme.fonts.body};
          font-size: 1rem;
          color: ${({ theme }) => theme.colors.font};
          font-weight: 500;

          @media only screen and (max-width: 388px) {
            width: 36px;
          }
        }
      }
    }

    .react-datepicker__month {
      .react-datepicker__week {
        display: flex;

        .react-datepicker__day {
          width: 45px;
          height: 32px;
          /* border: 1px solid ${({ theme }) => theme.colors.gray[3]}; */
          border-right: none;
          font-family: ${({ theme }) => theme.fonts.body};
          font-size: 0.75rem;
          color: ${({ theme }) => theme.colors.font};
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.3s;

          @media only screen and (max-width: 388px) {
            width: 35px;
          }

          &:hover {
            background-color: ${({ theme }) => theme.colors.green.dark};
            color: ${({ theme }) => theme.colors.gray[1]};
            font-weight: 700;
          }

          &:last-child {
            /* border-right: 1px solid ${({ theme }) => theme.colors.gray[3]}; */
          }
        }

        .react-datepicker__day--outside-month {
          /* width: 46px; */
          border: none;
          z-index: -1;
          opacity: 0;

          @media only screen and (max-width: 388px) {
            width: 36px;
          }

          &:hover {
            background-color: ${({ theme }) => theme.colors.green.dark};
            color: ${({ theme }) => theme.colors.gray[1]};
            font-weight: 700;
          }
        }

        .react-datepicker__day--in-range {
          background-color: ${({ theme }) => `${theme.colors.green.dark}B2`};
          color: ${({ theme }) => theme.colors.gray[1]};
        }

        .react-datepicker__day--in-selecting-range {
          background-color: ${({ theme }) => `${theme.colors.green.dark}B2`};
          color: ${({ theme }) => theme.colors.gray[1]};
        }

        .react-datepicker__day--range-start {
          background-color: ${({ theme }) => theme.colors.green.dark};
          color: ${({ theme }) => theme.colors.gray[1]};
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        .react-datepicker__day--range-end {
          background-color: ${({ theme }) => theme.colors.green.dark};
          color: ${({ theme }) => theme.colors.gray[1]};
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        .react-datepicker__day--selected {
          background-color: ${({ theme }) => theme.colors.green.dark};
          color: ${({ theme }) => theme.colors.gray[1]};
          border-radius: 4px;

          &.react-datepicker__day--selecting-range-start {
            border-radius: unset;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
        }

        .react-datepicker__day--disabled {
          cursor: not-allowed;
          filter: brightness(0.6);

          &:hover {
            background-color: unset;
          }
        }
      }

      .react-datepicker__week:first-child {
        .react-datepicker__day--outside-month {
          /* border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]}; */
          height: 32px;
        }
      }

      .react-datepicker__week:not(:first-child) {
        .react-datepicker__day {
          border-top: none;
        }
      }

      .react-datepicker__day--outside-month {
        &:last-child {
          border: none;
        }
      }
    }
  }

  .react-datepicker-popper {
    z-index: 9999999;
  }
`;
