import { ITableColumn } from '@domain/interfaces/dashboard/AdsManager/IAdsManager';

export const getResizerNewPosition = (
  tableColumns: Array<ITableColumn>,
  activeTableIndex: number | null,
  tableParentElementRef: React.MutableRefObject<HTMLDivElement | null>,
  event: any,
): number => {
  const filteredTableColumns = tableColumns.filter(column => column.isActive);

  const foundHeader = filteredTableColumns.find(
    (column: ITableColumn, index: number) => index === activeTableIndex,
  );

  if (foundHeader && foundHeader.isResizable) {
    const width =
      event.clientX -
      (foundHeader.ref?.current?.offsetLeft || 0) -
      (foundHeader.ref?.current?.offsetWidth || 0) +
      (tableParentElementRef.current?.children[0].scrollLeft || 0) -
      128;

    if (width > 0) return width;

    if (width < 0) {
      const { minWidth } = foundHeader;

      if (minWidth + 2 <= (foundHeader.ref?.current?.offsetWidth || 0) + width) {
        return width;
      }

      return minWidth + 2 - (foundHeader.ref?.current?.offsetWidth || 0);
    }

    return 0;
  }

  return 0;
};
