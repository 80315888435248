import React from 'react';
import { useParams } from 'react-router-dom';

import { EEcommerceProvider } from '@domain/enums/common/integrations/EEcommerceProvider';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';

import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useDate } from '@helpers/hooks/useDate';

import { getSynchronizationStatus } from '@helpers/utils/common/synchronization';

import { ECOMMERCE_PROVIDERS } from '@constants/common/integrations';

import { useStoreIntegrations } from '@helpers/hooks/pages/dashboard/integrationCenter/useStoreIntegrations';
import { useToast } from '@helpers/hooks/useToast';
import * as S from './styles';

const OrdersOption: React.FC = () => {
  const { toast } = useToast();
  const { handleSideModalOpen } = useStoreIntegrations();
  const foundIntegration = ECOMMERCE_PROVIDERS.find(
    integration => integration.identifier === EEcommerceProvider.SHOPIFY,
  ) as typeof ECOMMERCE_PROVIDERS[number];

  const { storeAliasId } = useParams<IParams>();
  const { utcToZonedTime, format } = useDate();
  const {
    addSynchronization,
    currentSynchronizations,
    synchronizationsQueue,
  } = useSynchronization();

  const [period, setPeriod] = React.useState({
    startDate: utcToZonedTime(new Date()),
    endDate: utcToZonedTime(new Date()),
  });

  const syncOrdersRef = React.useRef<HTMLInputElement>(null);
  const syncProductsRef = React.useRef<HTMLInputElement>(null);

  const onSync = React.useCallback(async () => {
    const { startDate, endDate } = period;

    if (syncOrdersRef.current && syncProductsRef.current) {
      if (syncProductsRef.current.checked) {
        const params = `?start_date=${format(new Date(2000, 1, 1), 'yyyy-MM-dd')}&end_date=${format(
          utcToZonedTime(endDate),
          'yyyy-MM-dd',
        )}`;

        addSynchronization({
          name: foundIntegration.name,
          type: ESynchronizationType.STORE_PRODUCT,
          label: 'Produtos',
          dependencies: [],
          storeAliasId,
          config: {
            isOnboardingSynchronization: false,
            showNotification: true,
          },
          request: foundIntegration.generateSyncRequest(
            ESynchronizationType.STORE_PRODUCT,
            storeAliasId,
            params,
          ),
        });
      }

      if (syncOrdersRef.current.checked) {
        const params = `?start_date=${format(
          utcToZonedTime(startDate),
          'yyyy-MM-dd',
        )}&end_date=${format(utcToZonedTime(endDate), 'yyyy-MM-dd')}`;

        addSynchronization({
          name: foundIntegration.name,
          type: ESynchronizationType.STORE_ORDER,
          label: 'Pedidos',
          dependencies: [ESynchronizationType.STORE_PRODUCT],
          storeAliasId,
          config: {
            isOnboardingSynchronization: false,
            showNotification: true,
          },
          request: foundIntegration.generateSyncRequest(
            ESynchronizationType.STORE_ORDER,
            storeAliasId,
            params,
          ),
        });
      }

      toast.success('A sincronização iniciou!');
      handleSideModalOpen();
    }
  }, [
    period,
    toast,
    handleSideModalOpen,
    format,
    utcToZonedTime,
    addSynchronization,
    foundIntegration,
    storeAliasId,
  ]);

  const isSyncingProducts = getSynchronizationStatus(
    currentSynchronizations,
    synchronizationsQueue,
    foundIntegration.name,
    ESynchronizationType.STORE_PRODUCT,
    storeAliasId,
  );
  const isSyncingOrders = getSynchronizationStatus(
    currentSynchronizations,
    synchronizationsQueue,
    foundIntegration.name,
    ESynchronizationType.STORE_ORDER,
    storeAliasId,
  );

  return (
    <S.Content>
      <S.InputGroup>
        <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
          Escolha o que sincronizar
        </S.Title>

        <S.SwitchGroup>
          <S.Switch
            id="sync_products"
            name="sync_products"
            isLoading={false}
            defaultChecked
            innerRef={syncProductsRef}
          />

          <S.Label>Produtos</S.Label>
        </S.SwitchGroup>

        <S.SwitchGroup>
          <S.Switch
            id="sync_orders"
            name="sync_orders"
            isLoading={false}
            defaultChecked
            innerRef={syncOrdersRef}
          />

          <S.Label>Pedidos</S.Label>
        </S.SwitchGroup>
      </S.InputGroup>

      <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
        Selecione um período para sincronizar
      </S.Title>

      <S.CustomDatePicker
        period={period}
        setPeriod={setPeriod}
        maxDate={utcToZonedTime(new Date())}
      />

      <S.SyncButton
        onClick={onSync}
        isLoading={isSyncingProducts || isSyncingOrders}
        disabled={isSyncingProducts || isSyncingOrders}
      >
        Sincronizar
      </S.SyncButton>
    </S.Content>
  );
};

export default OrdersOption;
