import styled from 'styled-components';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Name = styled(TextBase)`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: 500;
  max-width: 158px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
`;

export const Avatar = styled.span`
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.green.default};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[4]};
`;

export const AdSenseCredentialWrapper = styled.button`
  background: unset;
  border: unset;
  padding: 8px 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
  transition: all 0.3s;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[5]};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[5]};
  }
`;

export const ValueToFilterInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  background-color: unset;
  border: none;
  color: ${({ theme }) => theme.colors.font};
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;

  &::placeholder {
    color: ${({ theme }) => theme.colors.font};
    opacity: 0.6;
  }

  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.font};
    opacity: 0.6;
  }

  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.font};
    opacity: 0.6;
  }

  &:focus {
    outline: none;
  }
`;

export const ValueToFilterWrapper = styled.div`
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[5]};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
