import React from 'react';

import { IFirstTooltipProps } from '@domain/interfaces/dashboard/AdsManager/UtmsTooltipsContent/IFirstTooltip';

import * as S from './styles';

const FirstTooltip: React.FC<IFirstTooltipProps> = ({ closeFirstTooltip }) => {
  return (
    <S.Wrapper>
      <S.Title>Você precisa fazer a instalação das UTMs.</S.Title>
      <S.Text>
        Instale nossa sincronização com as UTMs para você obter insights financeiros, e
        potencializar sua analise.
      </S.Text>
      <S.UnderstoodButton onClick={closeFirstTooltip}>Entendi</S.UnderstoodButton>
    </S.Wrapper>
  );
};

export default FirstTooltip;
