import React from 'react';

import { getEnvironment } from '@helpers/utils/environment';

import { IStatus, IStepModalProvider } from '@domain/interfaces/components/IStepsModal';

import { useLocalStorage } from '../useLocalStorage';

const StepModalContext = React.createContext<IStepModalProvider | null>(null);

export const StepModalProvider: React.FC = ({ children }) => {
  const [tutorialStatus, setTutorialStatus] = useLocalStorage(
    `@profitfy:${getEnvironment()}/tutorials`,
    null,
  );

  const handleStatus = React.useCallback(
    (status: Partial<IStatus>) => {
      setTutorialStatus({ ...tutorialStatus, ...status });
    },
    [tutorialStatus, setTutorialStatus],
  );

  return (
    <StepModalContext.Provider
      value={{
        handleStatus,
        tutorialStatus,
      }}
    >
      {children}
    </StepModalContext.Provider>
  );
};

export const useStepModal = (): IStepModalProvider => {
  const context = React.useContext(StepModalContext);

  if (!context) {
    throw new Error('useStepModal must be used within StepModalProvider');
  }

  return context;
};
