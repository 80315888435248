import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  ICreateSuggestionProps,
  IUseSuggestionsContext,
  IVoteDown,
  IVoteUp,
} from '@domain/interfaces/dashboard/IdeasChannel/suggestions';

import { useDate } from '@helpers/hooks/useDate';
import { useToast } from '@helpers/hooks/useToast';

import suggestionsService from '@services/pages/dashboard/ideasChannel/suggestions';
import { IParams } from '@domain/interfaces/IParams';
import { useConfig } from '@helpers/hooks/useConfig';

const SuggestionsContext = React.createContext<IUseSuggestionsContext | null>(null);

export const SuggestionsProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const { toast } = useToast();
  const { format } = useDate();
  const { storeAliasId } = useParams<IParams>();
  const { analytics, selectedStore, user, isInternalTeam } = useConfig();

  const [isCreatingSuggestion, setIsCreatingSuggestion] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(0);
  const [pageCount, setPageCount] = React.useState<number>(1);

  const {
    suggestions,
    error: suggestionsError,
    mutate: mutateSuggestions,
    isLoading: isLoadingSuggestions,
    isValidating: isValidatingSuggestions,
    totalPages,
  } = suggestionsService.listAllSuggestions({
    startDate: '2022-01-01',
    endDate: format(new Date(), 'yyyy-MM-dd'),
    page,
  });

  const onPageChange = React.useCallback(({ selected }) => setPage(selected), []);

  const createSuggestion = React.useCallback(
    async ({ data }: ICreateSuggestionProps) => {
      setIsCreatingSuggestion(true);

      try {
        const { data: createSuggestionData } = await suggestionsService.createSuggestion({ data });

        toast.success('Sugestão criada com sucesso!');

        await mutateSuggestions();

        analytics?.track(
          'Suggestion Created',
          {
            email: user?.email,
          },
          { context: { groupId: selectedStore?.alias_id } },
        );

        setIsCreatingSuggestion(false);

        history.push(
          `/${storeAliasId}/dashboard/ideas/details/${createSuggestionData.suggestion.id}`,
        );
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
    [mutateSuggestions, toast, history, storeAliasId, analytics, selectedStore, user],
  );

  const voteUp = React.useCallback(
    async ({ ideaAliasId }: IVoteUp) => {
      try {
        await suggestionsService.votesUp({ ideaAliasId });

        analytics?.track(
          'Suggestion Voted',
          {
            email: user?.email,
          },
          { context: { groupId: selectedStore?.alias_id } },
        );

        toast.success('Voto feito com sucesso!');
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
    [toast, analytics, selectedStore, user],
  );

  const voteDown = React.useCallback(
    async ({ ideaAliasId }: IVoteDown) => {
      try {
        await suggestionsService.votesDown({ ideaAliasId });

        analytics?.track(
          'Suggestion Voted',
          {
            email: user?.email,
          },
          { context: { groupId: selectedStore?.alias_id } },
        );

        toast.success('Voto desfeito com sucesso!');
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
    [toast, analytics, selectedStore, user],
  );

  React.useEffect(() => {
    if (totalPages) setPageCount(totalPages);
  }, [totalPages]);

  React.useEffect(() => {
    if (!isInternalTeam && window.location.href.includes('admin')) {
      history.push(`/${storeAliasId}/dashboard/overview/financial`);
    }
  }, [history, isInternalTeam, storeAliasId]);

  return (
    <SuggestionsContext.Provider
      value={{
        suggestions,
        suggestionsError,
        isCreatingSuggestion,
        isLoadingSuggestions,
        isValidatingSuggestions,
        mutateSuggestions,
        totalPages,
        page,
        pageCount,
        createSuggestion,
        onPageChange,
        voteUp,
        voteDown,
      }}
    >
      {children}
    </SuggestionsContext.Provider>
  );
};

export const useSuggestions = (): IUseSuggestionsContext => {
  const context = React.useContext(SuggestionsContext);

  if (!context) {
    throw new Error('useSuggestions must be used within SuggestionsProvider');
  }

  return context;
};
