import React from 'react';

import { useParams } from 'react-router-dom';
import { EStatus } from '@domain/enums/subscription/ESubscription';
import { ERankingProductSortOption } from '@domain/enums/dashboard/productAnalytics/ERankingProductSort';
import {
  IRankingTableProvider,
  IPeriod,
} from '@domain/interfaces/dashboard/ProductAnalytics/IRankingTable';
import { IParams } from '@domain/interfaces/IParams';
import { ISelectedField } from '@domain/interfaces/components/ISort';
import { IProductMetrics } from '@domain/interfaces/dashboard/ProductAnalytics/IProducts';
import { useDate } from '@helpers/hooks/useDate';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { sliceIntoChunks } from '@helpers/utils/common/chunk';
import { filterString } from '@helpers/utils/common/filter';
import { sortProductsData } from '@helpers/utils/dashboard/productAnalytic/rankingProducts';

import productsService from '@services/pages/dashboard/productAnalytics/products';
import customValueCategoriesService from '@services/pages/dashboard/customValuesCategories/customValueCategories';

const RankingTableContext = React.createContext<IRankingTableProvider | null>(null);

const RANKING_TABLE_LIMIT = 10;

export const RankingTableProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();
  const { utcToZonedTime, format, differenceInDays, addDays } = useDate();
  const { subscription, hasAllPermissions } = useStoreSubscription();

  const getMaxDate = React.useCallback(() => {
    if (
      subscription?.plan?.identifier.includes('free') &&
      subscription.exceeded_order_limit_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(subscription?.exceeded_order_limit_at);
    }

    const isSubscriptionPeriodEndedAtAfterNow = subscription?.period_ended_at
      ? differenceInDays(utcToZonedTime(new Date()), new Date(subscription.period_ended_at)) >= 0
      : true;

    if (subscription?.status === EStatus.CANCELED && isSubscriptionPeriodEndedAtAfterNow) {
      return utcToZonedTime(new Date());
    }

    if (
      subscription?.status === EStatus.CANCELED &&
      subscription?.period_ended_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(addDays(new Date(subscription.period_ended_at), 1));
    }

    if (
      subscription?.status === EStatus.CANCELED &&
      subscription?.canceled_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(subscription.canceled_at);
    }

    if (subscription && subscription.status === EStatus.TRIAL_ENDED && !hasAllPermissions) {
      return utcToZonedTime(addDays(new Date(subscription.period_ended_at), 1));
    }

    return utcToZonedTime(new Date());
  }, [subscription, utcToZonedTime, hasAllPermissions, differenceInDays, addDays]);

  const initialDate = getMaxDate();

  const [period, setPeriod] = React.useState<IPeriod>({
    startDate: utcToZonedTime(initialDate),
    endDate: utcToZonedTime(initialDate),
  });
  const [page, setPage] = React.useState<number>(0);
  const [name, setName] = React.useState<string>('');
  const [sortData, setSortData] = React.useState<ISelectedField | undefined>(undefined);

  console.log({ sortData });

  const {
    productsMetrics: productsMetricsFull,
    mutate,
    error,
    isLoading,
    isValidating,
  } = productsService.getProducts({
    storeAliasId,
    startDate: format(period.startDate, 'yyyy-MM-dd'),
    endDate: format(period.endDate, 'yyyy-MM-dd'),
    filter: {},
  });

  const productsMetrics = productsMetricsFull?.filter(
    product_metric => !product_metric?.product?.is_deleted,
  );

  const {
    customValuesCategories,
    mutate: mutateCustomValuesCategories,
    error: customValuesCategoriesError,
    isLoading: isLoadingCustomValuesCategories,
    isValidating: isValidatingCustomValuesCategories,
  } = customValueCategoriesService.getCustomValuesCategories({ storeAliasId });

  const handleProductSearch = React.useCallback(productName => setName(productName), []);

  const onPageChange = React.useCallback(({ selected }) => setPage(selected), []);

  const handleSortData = React.useCallback(newData => {
    setSortData(newData);
  }, []);

  React.useEffect(() => {
    if (period || name) {
      setPage(0);
    }
  }, [period, name]);

  const sortOption = sortData?.field as ERankingProductSortOption | undefined;
  const sortType = sortData?.value;

  const sortedSelectedProductsMetricsChunk = sortProductsData(
    productsMetrics,
    sortOption,
    sortType,
  );

  const chunkedProductsMetrics = sliceIntoChunks<IProductMetrics>(
    sortedSelectedProductsMetricsChunk,
    RANKING_TABLE_LIMIT,
  );

  const pageCount = chunkedProductsMetrics ? chunkedProductsMetrics.length : 1;

  const filteredProductsMetrics = sortedSelectedProductsMetricsChunk
    ? sortedSelectedProductsMetricsChunk
        .filter(productMetric => filterString(productMetric.product.name, name))
        .filter(productMetric => !productMetric.product.is_deleted)
    : [];

  return (
    <RankingTableContext.Provider
      value={{
        period,
        setPeriod,
        onPageChange,
        page,
        pageCount,
        error,
        isLoading,
        isValidating,
        mutate,
        productsMetrics: filteredProductsMetrics,
        handleProductSearch,
        customValuesCategories,
        customValuesCategoriesError,
        isLoadingCustomValuesCategories,
        isValidatingCustomValuesCategories,
        mutateCustomValuesCategories,
        handleSortData,
        sortData,
      }}
    >
      {children}
    </RankingTableContext.Provider>
  );
};

export const useRankingTable = (): IRankingTableProvider => {
  const context = React.useContext(RankingTableContext);

  if (!context) {
    throw new Error('useRankingTable must be used within RankingTableContext');
  }

  return context;
};
