import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Text = styled(TextBase)`
  line-height: 120%;
`;

export const Container = styled.div`
  width: 100%;
  padding: 12px 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2d2055;
  gap: 12px;
`;
