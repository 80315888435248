import React from 'react';

import EmptyData from '@components/common/core/DataDisplay/EmptyData';

import { EHeadAlign } from '@domain/enums/components/ETable';

import * as S from './styles';

const EmptyDataRow: React.FC = () => {
  return (
    <S.AutomationsTable>
      <S.Table.Header>
        <S.Table.Row>
          <S.Table.Head align={EHeadAlign.CENTER}>Status</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Nome</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Envios (7d)</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Execuções (7d)</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Recuperados (7d)</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Valor Recuperado (7d)</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Gatilho</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Ações</S.Table.Head>
        </S.Table.Row>
      </S.Table.Header>

      <S.Table.Body>
        <S.Table.Row>
          <S.Table.Data colSpan={11} align={EHeadAlign.CENTER}>
            <EmptyData content="Não encontramos nenhuma automação." />
          </S.Table.Data>
        </S.Table.Row>
      </S.Table.Body>
    </S.AutomationsTable>
  );
};

export default EmptyDataRow;
