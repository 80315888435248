import styled from 'styled-components/macro';

export const CommentariesWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
  margin: 0 16px;
`;

export const Carousel = styled.div``;

export const CarouselWrapper = styled.div``;

export const Title = styled.span`
  font-family: 'Poppins', 'Open Sans';
  font-size: 4rem;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 56px;
  line-height: 80px;
  text-align: center;

  @media only screen and (max-width: 948px) {
    font-size: 3rem;
    line-height: 40px;
    text-align: center;
    margin-bottom: 40px;
  }
`;

export const LeftTransition = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 306px;
  width: 256px;
  z-index: 1;
  background-image: linear-gradient(to left, rgba(15, 17, 22, 0), rgba(15, 17, 22, 1));

  @media only screen and (max-width: 948px) {
    width: 128px;
  }
`;

export const RightTransition = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 306px;
  width: 256px;
  z-index: 1;
  background-image: linear-gradient(to right, rgba(15, 17, 22, 0), rgba(15, 17, 22, 1));

  @media only screen and (max-width: 948px) {
    width: 128px;
  }
`;

export const TickerWrapper = styled.div`
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
