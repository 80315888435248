import React from 'react';

import { isAfter } from 'date-fns';
import { useLocation } from 'react-router-dom';

import { EStatus } from '@domain/enums/subscription/ESubscription';
import { useSubscriptionStatus } from '@helpers/hooks/common/store/subscription/useSubscriptionStatus';
import { useActivateSubscription } from '@helpers/hooks/pages/dashboard/useActivateSubscription';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';

import { DEMO_STORE_PROD_ALIAS_ID } from '@constants/demoStore';
import { useWhatsappInstance } from '@helpers/hooks/pages/dashboard/whatsappInstance/useWhatsappInstance';
import InDemoStore from '../Alerts/InDemoStore';
import CanceledPlanWithPendingPayment from './CanceledPlanWithPendingPayment';
import ExceededOrdersAlert from './ExceededOrdersAlert';
import FreePlanOrdersExceeded from './FreePlanOrdersExceeded';
import PendingPayment from './PendingPayment';
import PremiumFeature from './PremiumFeature';
import TrialEndedModal from './TrialEndedModal';

import UnconnectedInstance from '../Alerts/UnconnectedInstance';
import * as S from './styles';

const SubscriptionAlerts: React.FC = () => {
  const location = useLocation();
  const { isVerifyingTransaction } = useActivateSubscription();
  const { store } = useStoreConfig();
  const {
    hideExceededAlert,
    handleAlertsComponentHeight,
    subscription,
    hasAllPermissions,
  } = useStoreSubscription();
  const {
    hasExceededOrders,
    isPaymentPending,
    isCancelledByCreditCardIssue,
  } = useSubscriptionStatus();
  const { hasUnconnectedWhatsapp } = useWhatsappInstance();

  const componentRef = React.useRef<HTMLDivElement>(null);

  const [isAccessingPremiumFeature, setIsAccessingPremiumFeature] = React.useState<boolean>(false);
  const [isPendingPaymentAlertShow, setIsPendingPaymentAlertShow] = React.useState<boolean>(true);
  const [
    isExceededFreePlanOrdersModalOpen,
    setIsExceededFreePlanOrdersModalOpen,
  ] = React.useState<boolean>(false);

  const handlePendingPaymentAlertShow = React.useCallback(() => {
    setIsPendingPaymentAlertShow(currentState => !currentState);
  }, []);

  const handleExceededFreePlanOrdersModalOpen = React.useCallback(() => {
    setIsExceededFreePlanOrdersModalOpen(state => !state);
  }, []);

  React.useEffect(() => {
    if (location.pathname.includes('/dashboard/overview/products-analytic')) {
      setIsAccessingPremiumFeature(true);
    } else {
      setIsAccessingPremiumFeature(false);
    }
  }, [location]);

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      if (componentRef.current) {
        handleAlertsComponentHeight(componentRef.current.clientHeight);
      }
    });
  }, [componentRef, handleAlertsComponentHeight]);

  React.useEffect(() => {
    if (componentRef.current) {
      handleAlertsComponentHeight(componentRef.current.clientHeight);
    }
  });

  const isLastExceededWarningExpired =
    hideExceededAlert && subscription
      ? isAfter(new Date(subscription.period_started_at), new Date(hideExceededAlert))
      : true;

  const isTrialEnded = subscription?.status === EStatus.TRIAL_ENDED;
  const isTrialing = subscription?.status === EStatus.TRIAL_STARTED;
  const hasExceededFreePlanOrders =
    subscription?.status === EStatus.PAID &&
    subscription?.plan?.identifier.includes('free') &&
    subscription.exceeded_order_limit_at;

  React.useEffect(() => {
    if (hasExceededFreePlanOrders) {
      setIsExceededFreePlanOrdersModalOpen(true);
    }
  }, [hasExceededFreePlanOrders]);

  if (isCancelledByCreditCardIssue || isPaymentPending) {
    return (
      <S.BottomContainer>
        {isCancelledByCreditCardIssue && !isVerifyingTransaction && (
          <CanceledPlanWithPendingPayment />
        )}

        {isPaymentPending && isPendingPaymentAlertShow && (
          <PendingPayment onClose={handlePendingPaymentAlertShow} />
        )}
      </S.BottomContainer>
    );
  }

  return (
    <S.Container ref={componentRef}>
      {hasExceededOrders && isLastExceededWarningExpired && <ExceededOrdersAlert />}
      {isTrialEnded && !hasAllPermissions && <TrialEndedModal />}
      {isTrialing && isAccessingPremiumFeature && <PremiumFeature />}
      {hasExceededFreePlanOrders && !hasAllPermissions && (
        <FreePlanOrdersExceeded
          isOpen={isExceededFreePlanOrdersModalOpen}
          toggle={handleExceededFreePlanOrdersModalOpen}
        />
      )}
      {store?.alias_id === DEMO_STORE_PROD_ALIAS_ID && <InDemoStore />}
      {hasUnconnectedWhatsapp && <UnconnectedInstance />}
    </S.Container>
  );
};

export default SubscriptionAlerts;
