import React from 'react';
import { useTheme } from 'styled-components/macro';
import { useHistory, useLocation } from 'react-router-dom';

import { INavigationButtonProps } from '@domain/interfaces/components/IBottomNavigation';

import * as S from './styles';

const NavigationButton: React.FC<INavigationButtonProps> = ({
  icon: Icon,
  title,
  selected,
  url,
}) => {
  const theme: any = useTheme();
  const history = useHistory();
  const { pathname } = useLocation();

  const [isHoveringButton, setIsHoveringButton] = React.useState<boolean>(false);
  const [storeAliasId, setStoreAliasId] = React.useState<string>('');

  const isIconFilled = React.useCallback(() => {
    if (isHoveringButton || selected) return true;

    return false;
  }, [isHoveringButton, selected]);

  const onNavigationButtonClick = React.useCallback(() => {
    history.push(`/${storeAliasId}${url}`);
  }, [history, storeAliasId, url]);

  React.useEffect(() => {
    const foundStoreAliasId = pathname.match(/store-([a-zA-Z0-9]+)/);

    if (foundStoreAliasId?.length) {
      setStoreAliasId(foundStoreAliasId[0]);
    }
  }, [pathname]);

  return (
    <S.NavigationButton
      onMouseEnter={() => setIsHoveringButton(true)}
      onMouseLeave={() => setIsHoveringButton(false)}
      onClick={onNavigationButtonClick}
    >
      <Icon
        size={24}
        color={isIconFilled() ? theme.colors.green.default : theme.colors.gray[3]}
        outline={!isHoveringButton}
      />
      <S.NavigationText isIconFilled={isIconFilled()}>{title}</S.NavigationText>
    </S.NavigationButton>
  );
};

export default NavigationButton;
