import styled from 'styled-components/macro';

export const Container = styled.div`
  max-width: 768px;
  width: 100%;
  padding-bottom: 104px;
`;

export const Header = styled.div`
  width: 50%;
  height: 30px;
  margin-top: 40px;
  margin-bottom: 24px;
`;

export const NameAndStatusWrapper = styled.div`
  height: 212px;
  width: 100%;
  margin-bottom: 24px;
`;

export const Variant = styled.div`
  height: 83px;
  margin-bottom: 24px;
`;

export const NewVariant = styled.div`
  height: 64px;
  margin-bottom: 24px;
`;

export const KitCost = styled.div`
  height: 265px;
`;
