import React from 'react';

import { IPopover, IPopoverProps } from '@domain/interfaces/components/IPopover';
import Body from './Body';
import Header from './Header';

import * as S from './styles';

const Popover: IPopover & React.FC<IPopoverProps> = ({
  place = 'bottom',
  children,
  width = 320,
  ...rest
}) => {
  return (
    <S.Popover globalEventOff="click" place={place} width={width} clickable {...rest}>
      {children}
    </S.Popover>
  );
};

Popover.Header = Header;
Popover.Body = Body;
Popover.show = S.Popover.show;
Popover.hide = S.Popover.hide;

export default Popover;
