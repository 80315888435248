import React from 'react';
import { Storefront, Trash } from 'phosphor-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useToast } from '@helpers/hooks/useToast';

import storesService from '@services/pages/stores/stores';

import { myStoreSchema } from '@helpers/validators/dashboard/profile/myStore';

import Text from '@components/common/core/DataDisplay/Text';
import DeleteStoreModal from './DeleteStoreModal';

import * as S from './styles';

const MyStore: React.FC = () => {
  const theme = useTheme();
  const { store, mutateStore } = useStoreConfig();
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', reValidateMode: 'onChange', resolver: yupResolver(myStoreSchema) });

  const storeNameRegister = register('name');

  const [isUpdatingStore, setIsUpdatingStore] = React.useState<boolean>(false);
  const [isDeleteStoreModalOpen, setIsDeleteStoreModalOpen] = React.useState<boolean>(false);

  const handleIsDeleteStoreModalOpen = React.useCallback(
    () => setIsDeleteStoreModalOpen(state => !state),
    [],
  );

  const onSubmit = React.useCallback(
    async data => {
      setIsUpdatingStore(true);

      try {
        await storesService.updateStore(storeAliasId, { ...store, ...data });

        toast.success('Loja atualizada com sucesso!');

        mutateStore();
        setIsUpdatingStore(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsUpdatingStore(false);
      }
    },
    [mutateStore, storeAliasId, store, toast],
  );

  return (
    <>
      <S.Wrapper>
        <S.TitleContainer>
          <Storefront size={28} color={theme.colors.gray[1]} />
          <S.Title>Minha Loja</S.Title>
        </S.TitleContainer>
        <S.SubTitle>Altere os dados de sua loja</S.SubTitle>

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>Nome da loja</S.Label>
            <S.Input {...storeNameRegister} type="text" defaultValue={store.name} />
            {errors.name && <Text isErrorFeedback>{errors.name.message}</Text>}
          </S.InputGroup>

          <S.ButtonsWrapper>
            <S.DeleteStoreButton onClick={handleIsDeleteStoreModalOpen} type="button">
              <Trash size={20} color={theme.colors.danger.default} />
              Excluir loja
            </S.DeleteStoreButton>

            <S.UpdateStoreButton type="submit" isLoading={isUpdatingStore}>
              Atualizar
            </S.UpdateStoreButton>
          </S.ButtonsWrapper>
        </S.Form>
      </S.Wrapper>

      <DeleteStoreModal isOpen={isDeleteStoreModalOpen} toggle={handleIsDeleteStoreModalOpen} />
    </>
  );
};

export default MyStore;
