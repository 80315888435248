import { BalanceCircle, Dolar, HomeCircle, TaxCircle } from '@profitfy/pakkun-icons';
import React from 'react';

import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { EBusinessType } from '@domain/enums/stores/Etype';

import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const HoverCard: React.FC = ({ children }) => {
  const { financialReport } = useFinancialDashboard();
  const { store } = useStoreConfig();

  const { fee } = financialReport;

  const isGatewayFeeAmountGreaterThanZero = fee.gateway_fee_amount > 0;
  const isCheckoutFeeAmountGreaterThanZero = fee.checkout_fee_amount > 0;
  const isMarketplaceFeeAmountGreaterThanZero = fee.marketplace_fee_amount > 0;
  const isTaxAmountGreaterThanZero = fee.tax_amount > 0;
  const isInfoProductAmountGreaterThanZero = fee.info_product_fee_amount > 0;

  const onHoverCardClick = React.useCallback(event => {
    event.stopPropagation();
  }, []);

  const isInfoProductStore = store.type === EBusinessType.INFO_PRODUCT;

  return (
    <S.HoverCard>
      <S.HoverCardTrigger>{children}</S.HoverCardTrigger>

      <S.HoverCardContent onClick={onHoverCardClick}>
        {isGatewayFeeAmountGreaterThanZero && (
          <S.Row>
            <S.IconAndNameWrapper>
              <Dolar size={24} />
              <S.TaxName>Taxas de Gateway</S.TaxName>
            </S.IconAndNameWrapper>

            <S.TaxValue size={ETextSize.B5} weight={ETextWeight.MEDIUM}>
              {`R$ ${numberFormatter(fee.gateway_fee_amount, 2)}`}
            </S.TaxValue>
          </S.Row>
        )}

        {isCheckoutFeeAmountGreaterThanZero && (
          <S.Row>
            <S.IconAndNameWrapper>
              <TaxCircle size={24} />
              <S.TaxName>Taxas de Checkout</S.TaxName>
            </S.IconAndNameWrapper>

            <S.TaxValue size={ETextSize.B5}>{`R$ ${numberFormatter(
              fee.checkout_fee_amount,
              2,
            )}`}</S.TaxValue>
          </S.Row>
        )}

        {isMarketplaceFeeAmountGreaterThanZero && (
          <S.Row>
            <S.IconAndNameWrapper>
              <HomeCircle size={24} />
              <S.TaxName>Taxas de Marketplace</S.TaxName>
            </S.IconAndNameWrapper>

            <S.TaxValue size={ETextSize.B5}>{`R$ ${numberFormatter(
              fee.marketplace_fee_amount,
              2,
            )}`}</S.TaxValue>
          </S.Row>
        )}

        {isTaxAmountGreaterThanZero && (
          <S.Row>
            <S.IconAndNameWrapper>
              <BalanceCircle size={24} />
              <S.TaxName>Taxas de Impostos</S.TaxName>
            </S.IconAndNameWrapper>

            <S.TaxValue size={ETextSize.B5}>{`R$ ${numberFormatter(
              fee.tax_amount,
              2,
            )}`}</S.TaxValue>
          </S.Row>
        )}

        {isInfoProductStore && isInfoProductAmountGreaterThanZero && (
          <S.Row>
            <S.IconAndNameWrapper>
              <Dolar size={24} />
              <S.TaxName>Taxas de Infoproduto</S.TaxName>
            </S.IconAndNameWrapper>

            <S.TaxValue size={ETextSize.B5}>{`R$ ${numberFormatter(
              fee.info_product_fee_amount,
              2,
            )}`}</S.TaxValue>
          </S.Row>
        )}

        <S.HoverCard.Arrow />
      </S.HoverCardContent>
    </S.HoverCard>
  );
};

export default HoverCard;
