import { ApiService } from '@services/api';
import { ISignInProps } from '@domain/interfaces/auth/ISignIn';
import { AUTH_API_DOMAIN } from '@constants/api';

export class SignInService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public login(data: ISignInProps): Promise<any> {
    return this.apiService.post('/auth/sign-in', { ...data });
  }
}

export const signInService = new SignInService(AUTH_API_DOMAIN);

export default signInService;
