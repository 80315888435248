import { ApiService } from '@services/api';
import { API_DOMAIN } from '@constants/api';

import { IUpdateFinancialStatusProps } from '@domain/interfaces/dashboard/Feed/ICustomValueBilling';

export class CustomValueBillingService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public updateFinancialStatus({ storeAliasId, data }: IUpdateFinancialStatusProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/custom-values-billings/batch-update`,
      {
        ...data,
      },
    );
  }
}

const customValueBillingService = new CustomValueBillingService(API_DOMAIN);

export default customValueBillingService;
