import React from 'react';

import DatePicker from 'react-datepicker';

import Header from '@components/common/core/Utils/CustomDatePicker/CustomDate/Header';

import * as S from './styles';

interface ITimePickerProps {
  onCalendarOpen?: () => void;
  onCalendarClose?: () => void;
}

const TimePicker: React.FC<ITimePickerProps> = ({ onCalendarClose, onCalendarOpen }) => {
  const [startDate, setStartDate] = React.useState(new Date());

  const getNameOfDay = React.useCallback(
    nameOfDay =>
      ({
        Sunday: 'Dom',
        Monday: 'Seg',
        Tuesday: 'Ter',
        Wednesday: 'Qua',
        Thursday: 'Qui',
        Friday: 'Sex',
        Saturday: 'Sab',
      }[nameOfDay]),
    [],
  );

  const onChange = React.useCallback(date => {
    setStartDate(date);
  }, []);

  return (
    <S.Wrapper>
      <DatePicker
        showPopperArrow
        selected={startDate}
        onChange={onChange}
        onCalendarOpen={onCalendarOpen}
        onCalendarClose={onCalendarClose}
        locale="pt-BR"
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat="dd/MM/yyyy, HH:mm"
        timeCaption="Horário"
        formatWeekDay={nameOfDay => getNameOfDay(nameOfDay)}
        peekNextMonth={false}
        renderCustomHeader={({ date, changeYear, decreaseMonth, increaseMonth }) => (
          <Header
            date={date}
            changeYear={changeYear}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
          />
        )}
      />
    </S.Wrapper>
  );
};

export default TimePicker;
