import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

export const InfiniteScrollLoading: React.FC = () => {
  return (
    <>
      <S.Wrapper>
        <S.Container>
          <Skeleton width="100%" height={48} />
        </S.Container>
      </S.Wrapper>
    </>
  );
};

export default InfiniteScrollLoading;
