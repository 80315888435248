import styled from 'styled-components/macro';

export const ProductWrapper = styled.div`
  max-width: 395px;
  width: 100%;

  @media only screen and (max-width: 1250px) {
    max-width: unset;
  }
`;
