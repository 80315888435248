import React from 'react';

import * as S from './styles';

const Header: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Title>Produto vendido sem edição de custo</S.Title>
      <S.Description>
        Este produto foi vendido e não teve alteração de custo na plataforma. Ao editar você terá
        maior assertividade na análise de suas métricas
      </S.Description>
    </S.Wrapper>
  );
};

export default Header;
