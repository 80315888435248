import React from 'react';
import { Option, TrashSimple, Info } from 'phosphor-react';
import { Position, NodeProps, useUpdateNodeInternals } from 'react-flow-renderer';
import { useTheme } from 'styled-components/macro';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';

import ConditionalModal from './ConditionalModal';

import * as S from './styles';

const ConditionalNode: React.FC<NodeProps> = ({ id, type, data }) => {
  const { handleSelectedNodeId, connectionInProgress, nodes, setEdges, setNodes } = useAutomation();
  const updateNodeInternals = useUpdateNodeInternals();
  const theme = useTheme();

  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [isHoveringNode, setIsHoveringNode] = React.useState<boolean>(false);

  const onMouseEnter = React.useCallback(() => setIsHoveringNode(true), []);

  const onMouseLeave = React.useCallback(() => setIsHoveringNode(false), []);

  const toggle = React.useCallback(() => setIsModalOpen(state => !state), []);

  const handleClickNode = React.useCallback(() => {
    toggle();
    handleSelectedNodeId(id);
  }, [handleSelectedNodeId, id, toggle]);

  const onDelete = React.useCallback(
    event => {
      event.stopPropagation();
      setEdges((els: any) => {
        const mappedEdges = els.map((edge: any) => {
          if (edge.source.includes(id)) {
            return false;
          }
          if (edge.target.includes(id)) {
            return false;
          }
          return edge;
        });
        const filteredEdges = mappedEdges.filter((edge: any) => edge);
        return filteredEdges;
      });
      const newNodes = nodes.filter(node => node.id !== id);
      setNodes(newNodes);
    },
    [id, nodes, setEdges, setNodes],
  );

  React.useEffect(() => {
    updateNodeInternals(id);
  }, [data, id, updateNodeInternals]);

  const { rules } = data;

  const missingConfig = rules && rules.some((rule: any) => rule?.input === '');

  return (
    <S.Wrapper
      missingRules={missingConfig}
      onClick={handleClickNode}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <S.TargetHandle
        position={Position.Top}
        type="target"
        style={{ zIndex: connectionInProgress ? 999999 : -1 }}
      />
      <S.IconContainer>
        <Option size={20} />
      </S.IconContainer>

      <S.Name>Condição</S.Name>

      <S.InputHandleContainer>
        <S.InputHandleNegative position={Position.Bottom} type="source" id="negative" />
        <S.InputHandlePositive position={Position.Bottom} type="source" id="positive" />
      </S.InputHandleContainer>

      {missingConfig && (
        <S.InfoWrapper>
          <S.Info>
            <Info size={16} color={theme.colors.danger.light} weight="fill" />
          </S.Info>
        </S.InfoWrapper>
      )}

      {isHoveringNode && (
        <S.OptionsWrapper>
          <S.Option onClick={onDelete}>
            <TrashSimple size={16} color={theme.colors.font} weight="fill" />
          </S.Option>
        </S.OptionsWrapper>
      )}

      {isModalOpen && <ConditionalModal isOpen={isModalOpen} toggle={toggle} rules={rules} />}
    </S.Wrapper>
  );
};

export default ConditionalNode;
