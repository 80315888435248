import React from 'react';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Header>
        <Skeleton width="100%" height={60} />
      </S.Header>

      <S.Body>
        <Skeleton width="100%" height={150} />
      </S.Body>
    </S.Wrapper>
  );
};

export default SkeletonLoading;
