import React from 'react';
import Ticker from 'react-ticker';

import Card from './Card';

import * as S from './styles';

const Commentary: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Title>+ de 20.000 negócios confiam na gente!</S.Title>

      <S.TickerWrapper>
        <S.LeftTransition />

        <Ticker direction="toLeft" height={306}>
          {({ index }) => (
            <S.CommentariesWrapper>
              <Card
                text="Eu fiquei muitos anos sofrendo, planilhando, perdendo oportunidades de rentabilizar meu negócio. A Profitfy, trouxe a tecnologia necessária para eu me desempenhar melhor, me permitindo que eu focasse mais na linha estratégica."
                authorName="Caio Rodrigo"
                authorSocialMedia="@caiorodrigooficial"
              />
              <Card
                text="A Profitfy  já sincroniza com minha plataforma de pedidos, já convertendo com a cotação do dólar, sempre de forma precisa. Isso me economiza muito tempo! Precisão é Profify. Eu tenho 5 lojas, imagina eu ter que fazer isso tudo na mão."
                authorName="Raphael Mancini"
                authorSocialMedia="@rapha.mancini"
              />
              <Card
                text="Não se trata apenas de uma funcionalidade. A Profitfy integra tudo, desde produto, diversas fonte de tráfego, custos. O conjunto das ferramenta, é que faz a diferença e me dão informações para eu tomar minhas decisões."
                authorName="Kadu Baracho"
                authorSocialMedia="@kadubaracho"
              />
            </S.CommentariesWrapper>
          )}
        </Ticker>

        <S.RightTransition />
      </S.TickerWrapper>
    </S.Wrapper>
  );
};

export default Commentary;
