import React from 'react';
import tsx from 'react-syntax-highlighter/dist/esm/languages/prism/tsx';
import { useParams } from 'react-router-dom';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Check, Copy } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { EButtonSize, EButtonVariant } from '@domain/enums/components/EButton';
import { EScriptAndUtmsStep } from '@domain/enums/dashboard/adsManager/EScriptAndUtms';
import { IParams } from '@domain/interfaces/IParams';

import { useScriptAndUtms } from '@helpers/hooks/pages/dashboard/adsManager/onboarding/useScriptAndUtms';
import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';

import { newWindow } from '@helpers/hooks/usePopup';

import StoreDomainModal from './StoreDomainModal';

import * as S from './styles';

S.CodeEditor.registerLanguage('tsx', tsx);

const Code: React.FC = () => {
  const scriptCode = `<script
    async
    src="https://profitfy-scripts.s3.us-west-2.amazonaws.com/utms/minifiedScript.js">
  </script>`;

  const {
    handleNextStep,
    userStep,
    hasShopifyIntegration,
    userWindow,
    shouldReAuthenticateShopify,
    authenticateShopify,
    addScriptToShopify,
  } = useScriptAndUtms();
  const theme = useTheme();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { analytics } = useConfig();

  const [isCodeCopied, setIsCodeCopied] = React.useState<boolean>(false);
  const [verifyWindow, setVerifyWindow] = React.useState<Window | undefined>(undefined);
  const [isDomainModalOpen, setIsDomainModalOpen] = React.useState<boolean>(false);
  const [isWindowClosed, setIsWindowClosed] = React.useState<boolean>(false);
  const [isWindowForcedClosed, setIsWindowForcedClosed] = React.useState<boolean>(false);
  const [isScriptError, setIsScriptError] = React.useState<boolean>(false);
  const [
    isAutomaticallyInstallingScript,
    setIsAutomaticallyInstallingScript,
  ] = React.useState<boolean>(false);

  const handleCopyCode = React.useCallback(() => {
    navigator.clipboard.writeText(scriptCode);
    setIsCodeCopied(true);
    toast.success('Copiado com sucesso!');
  }, [scriptCode, toast]);

  const handleDomainModalOpen = React.useCallback(() => {
    setIsDomainModalOpen(!isDomainModalOpen);
  }, [isDomainModalOpen]);

  const handleVerifyInstallation = React.useCallback((domain: string) => {
    setIsWindowClosed(false);
    setIsWindowForcedClosed(false);

    const window = newWindow(domain, 'Verificação de Script', 1280, 720);

    setVerifyWindow(window);
  }, []);

  const onVerifyInstallationClick = React.useCallback(() => {
    analytics?.track(
      'Ads Manager UTM Onboarding Verify Button Clicked',
      {},
      { context: { groupId: storeAliasId } },
    );

    setIsDomainModalOpen(true);
  }, [analytics, storeAliasId]);

  const onAutomaticallyScriptInstallClick = React.useCallback(async () => {
    setIsAutomaticallyInstallingScript(true);

    const shouldReAuthenticate = await shouldReAuthenticateShopify();

    if (shouldReAuthenticate) {
      await authenticateShopify(shouldReAuthenticate.domain);
    } else {
      await addScriptToShopify();
      setIsDomainModalOpen(true);
      setIsAutomaticallyInstallingScript(false);
    }
  }, [shouldReAuthenticateShopify, authenticateShopify, addScriptToShopify]);

  const handleWindowClose = React.useCallback(async () => {
    const shouldReAuthenticate = await shouldReAuthenticateShopify();

    if (shouldReAuthenticate) {
      toast.error('Ocorreu um problema ao integrar com a Shopify, tente novamente!');
    } else {
      await addScriptToShopify();
      setIsDomainModalOpen(true);
    }

    setIsAutomaticallyInstallingScript(false);
  }, [shouldReAuthenticateShopify, toast, addScriptToShopify]);

  const isScriptStep = userStep === EScriptAndUtmsStep.SCRIPT;

  React.useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (verifyWindow && !verifyWindow.closed) {
      timeout = setTimeout(() => {
        verifyWindow.close();
        setVerifyWindow(undefined);
        setIsWindowForcedClosed(true);
        setIsScriptError(true);

        analytics?.track(
          'Ads Manager UTM Onboarding Script Installed Failed',
          {},
          { context: { groupId: storeAliasId } },
        );

        toast.error('Não foi possível detectar o script. Reveja a instalação e tente novamente!');
      }, 60000);
    }

    const interval = setInterval(() => {
      if (verifyWindow?.closed) {
        clearInterval(interval);
        setIsWindowClosed(true);
        setVerifyWindow(undefined);

        if (timeout) {
          clearTimeout(timeout);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [verifyWindow, toast, analytics, storeAliasId]);

  React.useEffect(() => {
    if (isWindowClosed && !isWindowForcedClosed && userStep === EScriptAndUtmsStep.SCRIPT) {
      analytics?.track(
        'Ads Manager UTM Onboarding Script Installed Success',
        {},
        { context: { groupId: storeAliasId } },
      );

      handleNextStep();
    }
  }, [handleNextStep, isWindowClosed, isWindowForcedClosed, userStep, analytics, storeAliasId]);

  React.useEffect(() => {
    if (isCodeCopied) {
      const timeout = setTimeout(() => {
        setIsCodeCopied(false);
      }, 5000);

      return () => clearTimeout(timeout);
    }

    return undefined;
  }, [isCodeCopied]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (userWindow?.closed) {
        clearInterval(interval);
        handleWindowClose();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [userWindow, handleWindowClose]);

  return (
    <>
      <S.Wrapper>
        <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
          Copie o código
          {!isScriptStep && (
            <S.FinishedStepWrapper>
              <Check size={22} color={theme.colors.success.light} />
            </S.FinishedStepWrapper>
          )}
        </S.Title>

        {isScriptStep && (
          <>
            <S.CodeWrapper>
              <S.CodeEditor language="javascript" style={darcula} showLineNumbers>
                {scriptCode}
              </S.CodeEditor>
              <S.CopyButton buttonSize={EButtonSize.MEDIUM} onClick={handleCopyCode}>
                <Copy size={14} />
                {isCodeCopied ? 'Copiado' : 'Copiar'}
              </S.CopyButton>
            </S.CodeWrapper>

            <S.ButtonsWrapper>
              {hasShopifyIntegration && (
                <S.InstallScriptAutomaticallyButton
                  buttonSize={EButtonSize.MEDIUM}
                  onClick={onAutomaticallyScriptInstallClick}
                  disabled={isAutomaticallyInstallingScript || isScriptError}
                >
                  {isScriptError
                    ? 'Tente com a instalação manual'
                    : 'Realizar instalação automaticamente'}
                </S.InstallScriptAutomaticallyButton>
              )}

              <S.VerifyButton
                buttonSize={EButtonSize.MEDIUM}
                onClick={onVerifyInstallationClick}
                disabled={Boolean(verifyWindow) || isAutomaticallyInstallingScript}
                isLoading={isAutomaticallyInstallingScript}
                variant={
                  hasShopifyIntegration && !isScriptError
                    ? EButtonVariant.SECONDARY_BUTTON
                    : EButtonVariant.PRIMARY
                }
              >
                Verificar instalação
              </S.VerifyButton>
            </S.ButtonsWrapper>
          </>
        )}
      </S.Wrapper>

      <StoreDomainModal
        isOpen={isDomainModalOpen}
        toggle={handleDomainModalOpen}
        handleVerifyInstallation={handleVerifyInstallation}
      />
    </>
  );
};

export default Code;
