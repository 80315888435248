import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { IParams } from '@domain/interfaces/IParams';

import { useDowngrade } from '@helpers/hooks/common/store/subscription/useDowngrade';
import { useSubscription } from '@helpers/hooks/common/store/subscription/useSubscription';

import { ALL_PLANS } from '@constants/plans';

import MemberOption from './MemberOption';
import NextStep from '../NextStep';

import * as S from './styles';

const InvitedMembers: React.FC = () => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { selectedPlanIdentifier } = useSubscription();
  const { invitedMembers, selectedInvitedMembers, maxInvitedMembers } = useDowngrade();

  const selectedPlan =
    (ALL_PLANS.find(
      plan =>
        plan.identifier === selectedPlanIdentifier ||
        plan.quarterIdentifier === selectedPlanIdentifier ||
        plan.semiAnualIdentifier === selectedPlanIdentifier,
    ) as typeof ALL_PLANS[number]) || undefined;

  const selectedPlanInvitedMembers = selectedPlan?.members === 0 ? Infinity : selectedPlan?.members;

  const hasToDowngradeInvitedMembers = invitedMembers.length > (selectedPlanInvitedMembers || 0);

  React.useEffect(() => {
    if (!hasToDowngradeInvitedMembers) {
      history.push(`/${storeAliasId}/downgrade/facebook-profiles`);
    }
  }, [hasToDowngradeInvitedMembers, history, storeAliasId]);

  const hasExceededQuantity = selectedInvitedMembers.length > maxInvitedMembers;

  return (
    <S.Wrapper>
      <S.Title type={EHeadingSize.H3}>Usuários</S.Title>

      <S.Description>Selecione os usuários que deseja manter:</S.Description>

      <S.InvitedMembersOptionsWrapper>
        {invitedMembers.map(member => (
          <MemberOption userStore={member} key={member.user.alias_id} />
        ))}
      </S.InvitedMembersOptionsWrapper>

      <S.SelectedQuantity
        size={ETextSize.B5}
        weight={ETextWeight.MEDIUM}
        hasExceededQuantity={hasExceededQuantity}
      >
        <mark>{selectedInvitedMembers.length}</mark>
        {`/${maxInvitedMembers} usuários selecionado(s)`}
      </S.SelectedQuantity>

      <NextStep />
    </S.Wrapper>
  );
};

export default InvitedMembers;
