import React from 'react';

import { useVariant } from '@helpers/hooks/pages/dashboard/kit/useVariant';
import { IVariantProps } from '@domain/interfaces/dashboard/Kit/variant';

import * as S from './styles';

const Variant: React.FC<IVariantProps> = ({ variant }) => {
  const { handleSelectVariant } = useVariant();

  const [isVariantImageError, setIsVariantImageError] = React.useState<boolean>(false);

  return (
    <S.Wrapper onClick={() => handleSelectVariant(variant)}>
      {isVariantImageError || !variant.image_url ? (
        <S.NoImage />
      ) : (
        <S.Image
          src={variant.image_url}
          alt="Profitfy.me Variante"
          onError={() => setIsVariantImageError(true)}
        />
      )}

      <S.VariantDetails>
        <S.VariantName>{variant.name}</S.VariantName>
        <S.VariantSku>{`SKU: ${variant.sku}`}</S.VariantSku>
      </S.VariantDetails>
    </S.Wrapper>
  );
};

export default Variant;
