import React from 'react';
import { useTheme } from 'styled-components';
import { WhatsappLogo } from 'phosphor-react';

import * as S from './styles';

const MinimalNode: React.FC = () => {
  const { colors } = useTheme();

  return (
    <>
      <S.IconContainer>
        <WhatsappLogo size={20} color={colors.white.default} />
      </S.IconContainer>

      <S.Name>Enviar whatsapp</S.Name>
    </>
  );
};

export default MinimalNode;
