import styled, { css } from 'styled-components/macro';

import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import ButtonBase from '@components/common/core/Inputs/Button';
import ImageBase from '@components/common/core/DataDisplay/Image';
import TextBase from '@components/common/core/DataDisplay/Text';

export const MessagesWrapper = styled.div<{ hasMessages?: boolean }>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 24px;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  border-radius: 12px;
  max-height: 372px;
  overflow-y: scroll;

  ${({ hasMessages }) =>
    !hasMessages &&
    css`
      justify-content: center;
      align-items: center;
    `}

  ::-webkit-scrollbar {
    width: 4px;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.gray[4]};
    position: absolute;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[1]};
    border-radius: 25px;
    transition: all 0.3s;
  }

  ::-webkit-scrollbar-thumb:hover {
    opacity: brightness(0.8);
  }
`;

export const MessagePickerWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

export const PreviewWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

export const InputGroup = styled(InputGroupBase)`
  margin-top: 24px;
  margin-bottom: 24px;
  box-sizing: border-box;
`;

export const Label = styled(LabelBase)``;

export const Select = styled(SelectBase)`
  width: 280px;
`;

export const Option = styled(OptionBase)``;

export const SelectMessageButton = styled(ButtonBase)`
  height: 40px;
  margin-top: 32px;
`;

export const EmptyDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Image = styled(ImageBase)`
  width: 100%;
  max-width: 290px;
`;

export const NoMessagesText = styled(TextBase)`
  font-size: 1.125rem;
  line-height: 120%;
`;
