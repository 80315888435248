import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ChartBase from '@components/Dashboard/FinancialDashboard/Chart';

export const Chart = styled(ChartBase)`
  max-height: 277px;
  margin-bottom: 16px;

  #chart-wrapper {
    height: 204px;
  }

  @media only screen and (max-width: 1435px) {
    max-width: unset;
    max-height: unset;

    #chart-wrapper {
      height: 228px;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const WaitingTextWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  z-index: 1;
`;

export const WaitingText = styled(HeadingBase)`
  color: #ffffff;
  font-size: 0.9375rem;
`;

export const BlurredContent = styled.div`
  position: absolute;
  width: 100%;
  height: 277px;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  border-radius: 12px;

  @media only screen and (max-width: 1435px) {
    height: 300px;
  }

  @media only screen and (max-width: 768px) {
    height: 364px;
  }
`;
