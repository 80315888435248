import React from 'react';
import { Payt as PaytIcon, Copy } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components/macro';
import { yupResolver } from '@hookform/resolvers/yup';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { EInfoProductProvider } from '@domain/enums/common/integrations/EInfoProductProvider';
import { IParams } from '@domain/interfaces/IParams';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useToast } from '@helpers/hooks/useToast';
import { useParams } from 'react-router-dom';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { paytSchema } from '@helpers/validators/dashboard/integrationCenter/infoProduct/payt';

import paytService from '@services/pages/dashboard/integrationCenter/infoProduct/platforms/paytAuth';

import ActiveIntegrationFooter from '@components/Dashboard/IntegrationCenter/Sidemodal/ActiveIntegrationFooter';
import Text from '@components/common/core/DataDisplay/Text';

import PaytConfig from './PaytConfig';
import ActiveIntegration from './ActiveIntegration';
// import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Payt: React.FC = () => {
  const theme = useTheme();

  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { storeIntegrations, mutateStore } = useStoreConfig();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(paytSchema),
  });

  const [webhookURL, setWebhookURL] = React.useState<string>('');
  const [secretKeyValue, setSecretKeyValue] = React.useState<string>('');
  const [isGettingWebhookURL, setIsGettingWebhookURL] = React.useState<boolean>(false);
  const [credentialAliasId, setCredentialAliasId] = React.useState<string>('');
  const [isCreatingCredential, setIsCreatingCredential] = React.useState<boolean>(false);
  const [hasPaytCredential, setHasPaytCredential] = React.useState<boolean>(false);
  const [isGettingCredential, setIsGettingCredential] = React.useState<boolean>(false);
  const [isURLCopied, setIsURLCopied] = React.useState<boolean>(false);

  const hasPaytIntegration = Boolean(
    storeIntegrations?.store_front.find(
      integration => integration.name === EInfoProductProvider.PAYT && integration.is_active,
    ),
  );

  const secretKeyInputRegister = register('secret_key');

  const onSecretKeyChange = React.useCallback(
    event => {
      secretKeyInputRegister.onChange(event);

      setSecretKeyValue(event.target.value);
    },
    [secretKeyInputRegister],
  );

  const handleCopyURL = React.useCallback(() => {
    navigator.clipboard.writeText(webhookURL);
    setIsURLCopied(true);
    toast.success('URL copiada com sucesso.');
  }, [toast, webhookURL]);

  const onSubmit = React.useCallback(
    async formData => {
      setIsCreatingCredential(true);

      try {
        await paytService.createCredential({
          storeAliasId,
          data: { ...formData },
        });

        toast.success('Credencial criada com sucesso.');

        mutateStore();

        setIsCreatingCredential(false);
      } catch (error: any) {
        toast.error(getTranslatedIntegrationsError(error));
        setIsCreatingCredential(false);
      }
    },
    [mutateStore, storeAliasId, toast],
  );

  const getPaytCredential = React.useCallback(async () => {
    setIsGettingCredential(true);

    try {
      const { data } = await paytService.getCredential({ storeAliasId });

      if (data?.payt_credential.is_active) {
        setHasPaytCredential(true);
      }

      setCredentialAliasId(data?.payt_credential.alias_id);
      setIsGettingCredential(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsGettingCredential(false);
    } finally {
      setIsGettingCredential(false);
    }
  }, [toast, storeAliasId]);

  const generateWebhookURL = React.useCallback(async () => {
    setIsGettingWebhookURL(true);

    try {
      const { data } = await paytService.generateWebhookURL({ storeAliasId });

      setWebhookURL(data?.webhook_url);
      setIsGettingWebhookURL(false);
    } catch (error: any) {
      setIsGettingWebhookURL(false);
      toast.error(error?.response?.data?.message);
    }
  }, [toast, storeAliasId]);

  React.useEffect(() => {
    if (hasPaytIntegration) {
      getPaytCredential();
    } else {
      generateWebhookURL();
    }
  }, [getPaytCredential, generateWebhookURL, hasPaytIntegration]);

  if (isGettingCredential || isGettingWebhookURL) {
    return <></>;
  }

  if (hasPaytIntegration && !hasPaytCredential) {
    return <ActiveIntegration credentialAliasId={credentialAliasId} />;
  }

  if (hasPaytCredential) {
    return <PaytConfig credentialAliasId={credentialAliasId} />;
  }

  return (
    <S.ContentWrapper>
      <S.HeadingWrapper>
        <PaytIcon size={52} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com Payt
        </S.Heading>
      </S.HeadingWrapper>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>
        <S.FunctionalityDescriptionText>
          A integração com a PayT serve para puxarmos seus dados de plataforma aumentando a exatidão
          dos seus dados.
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <S.TutorialWrapper>
        <S.TutotialTitle weight={ETextWeight.BOLD}>Tutorial de integração</S.TutotialTitle>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          1 - Copie a URL no campo abaixo.
        </S.TutotialStep>

        <S.WebhookURLWrapper onClick={handleCopyURL} type="button">
          <S.WebhookURL weight={ETextWeight.MEDIUM}>{webhookURL}</S.WebhookURL>
          <S.IconWrapper>
            <Copy size={18} color={theme.colors.gray[1]} outline={!isURLCopied} />
          </S.IconWrapper>
        </S.WebhookURLWrapper>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          2 -{' '}
          <S.Link href="https://app.payt.com.br/admin/postbacks/create" target="_blank">
            Clique aqui e vá para a página de Postback da Payt.
          </S.Link>
        </S.TutotialStep>

        <S.StepsWrapper>
          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              Preencha o campo &quot;Nome&quot; como desejar.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              No campo &quot;Produto&quot;, selecione &quot;Todos os Produtos.&quot;.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              No campo &quot;Tipo&quot;, selecione &quot;PayT V1&quot;.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              No campo &quot;Url&quot; da PayT, cole a Url da Profitfy gerada acima.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              No campo &quot;Eventos&quot; selecione todas as opções.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              Para finalizar, no canto superior direito, copie a &quot;Chave Única&quot; da PayT.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              Na Profitfy, cole no campo &quot;Chave Única&quot; abaixo.
            </S.StepText>
          </S.StepWrapper>
        </S.StepsWrapper>

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>Chave Única</S.Label>

            <S.Input
              {...secretKeyInputRegister}
              type="text"
              value={secretKeyValue}
              placeholder="Chave Única da Payt"
              onChange={onSecretKeyChange}
              isError={errors.secret_key}
            />
            {errors.secret_key && <Text isErrorFeedback>{errors.secret_key.message}</Text>}
          </S.InputGroup>

          <ActiveIntegrationFooter
            isIntegrating={isCreatingCredential}
            disabled={!secretKeyValue}
          />
        </S.Form>
      </S.TutorialWrapper>
    </S.ContentWrapper>
  );
};

export default Payt;
