import React from 'react';
import { MetroSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components/macro';

import * as S from './styles';

const MoreContentLoader: React.FC = () => {
  const theme = useTheme();

  return (
    <S.Wrapper>
      <MetroSpinner size={16} color={theme.colors.green.default} />
    </S.Wrapper>
  );
};

export default MoreContentLoader;
