import React from 'react';
import { SimpleCheck } from '@profitfy/pakkun-icons';

import { EPlanRecurrence } from '@domain/enums/subscription/ESubscription';

import { PLANS } from '@constants/plans';

import Plan from './Plan';
import RecurrenceType from '../Plans/RecurrenceType';

import * as S from './styles';

const Plans: React.FC = () => {
  const [selectedRecurrenceType, setSelectedRecurrenceType] = React.useState<EPlanRecurrence>(
    EPlanRecurrence.MONTHLY,
  );

  const handleSelectedRecurrenceType = React.useCallback(
    type => setSelectedRecurrenceType(type),
    [],
  );

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>Escolha o plano que mais combina com você</S.Title>

        <S.DescriptionWrapper>
          <S.Description>
            <SimpleCheck size={14} color="#fff" />7 dias de teste
          </S.Description>
          <S.Description>
            <SimpleCheck size={14} color="#fff" />
            Não é necessário cartão de crédito
          </S.Description>
        </S.DescriptionWrapper>
      </S.Header>

      <RecurrenceType
        selectedRecurrenceType={selectedRecurrenceType}
        handleSelectedRecurrenceType={handleSelectedRecurrenceType}
      />

      <S.PlansWrapper>
        {PLANS.map((plan, index) => (
          <Plan
            plan={plan}
            selectedRecurrenceType={selectedRecurrenceType}
            key={plan.identifier}
            index={index}
          />
        ))}
      </S.PlansWrapper>
    </S.Wrapper>
  );
};

export default Plans;
