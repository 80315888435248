import styled from 'styled-components/macro';

import { ISkeletonWrapperProps } from '@domain/interfaces/dashboard/FinancialDashboard/IStoreGoal';

export const Wrapper = styled.div<ISkeletonWrapperProps>`
  max-width: 420px;
  width: 100%;
  border-radius: 12px;
  height: ${({ height }) => height}px;
  overflow: hidden;

  span {
    display: block;
  }

  @media only screen and (max-width: 1388px) {
    max-width: 380px;
  }

  @media only screen and (max-width: 1360px) {
    max-width: unset;
  }
`;
