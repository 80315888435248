import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const StartNowButton = styled(ButtonBase)`
  position: relative;

  &::before {
    content: '';
    background-repeat: no-repeat;
    width: 168px;
    height: 34px;
    position: absolute;
    right: -212px;
    top: 16px;
  }
`;

export const FeatureText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: #24363f;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Feature = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const FeaturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 24px;
`;

export const Title = styled(HeadingBase)`
  line-height: 150%;
  font-size: 1.75rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[1]};
  display: inline-block;
  margin-bottom: 20px;

  & > strong {
    color: ${({ theme }) => theme.colors.green.default};
    font-weight: 500;
  }
`;

export const DescriptionWrapper = styled.div`
  @media only screen and (max-width: 1208px) {
    width: 100%;
  }
`;

export const OnboardingImage = styled.video`
  max-width: 724px;
  border-radius: 15px;

  @media only screen and (max-width: 1298px) {
    max-width: 628px;
  }
`;

export const Content = styled.div`
  position: relative;
  margin: 176px auto 0 auto;
  display: flex;
  align-items: center;
  max-width: 1196px;
  width: 100%;
  gap: 48px;
  justify-content: space-between;

  &::before {
    content: '';
    position: absolute;
    top: -113px;
    left: 0px;
    width: 107px;
    height: 87px;
    background-repeat: no-repeat;

    @media only screen and (max-width: 1208px) {
      background: unset;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 1480px;
    height: 359px;
    background-repeat: no-repeat;
    bottom: -414px;

    @media only screen and (max-width: 1208px) {
      background: unset;
    }
  }

  @media only screen and (max-width: 1366px) {
    margin: 128px auto 0 auto;
  }

  @media only screen and (max-width: 1208px) {
    flex-direction: column;
    margin: 32px auto 0 auto;
    max-width: 628px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;
