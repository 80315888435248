import styled, { css } from 'styled-components/macro';

import { IDropdown } from '@domain/interfaces/components/IDropDown';
import { EDropdownPosition } from '@domain/enums/components/EDropdown';

export const DropdownWrapper = styled.div<IDropdown>`
  position: absolute;
  width: ${({ targetWidth }) => `${targetWidth}px`};
  left: ${({ targetLeftPosition }) => `${targetLeftPosition}px`};
  top: ${({ targetTopPosition = 0, targetHeight = 0 }) =>
    `${targetTopPosition + targetHeight + 12}px`};
  z-index: ${({ theme }) => theme.zIndex.secondLayer};
`;

export const DropdownArrow = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 11.5px 8px 11.5px;
  border-color: transparent transparent ${({ theme }) => theme.colors.gray[6]} transparent;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.sixthLayer};
  background-color: transparent;
`;

export const Container = styled.div<IDropdown>`
  position: absolute;
  margin-top: 8px;
  width: ${props => props.width}px;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  transform: ${({ targetWidth = 0 }) => `translateX(calc(-50% + ${targetWidth / 2}px))`};
  left: 50%;
  z-index: ${({ theme }) => theme.zIndex.sixthLayer};
  transform: translateX(-50%);

  ${({ position }) =>
    position === EDropdownPosition.LEFT &&
    css`
      left: 0;
      transform: unset;
    `}

  ${({ position, dropdownOverflowRight }) =>
    position === EDropdownPosition.RIGHT &&
    css`
      left: 50%;
      transform: ${`translateX(calc(-50% + ${dropdownOverflowRight}px))`};
    `}

  ${({ isOverflowInLeft, overflowSize }) =>
    isOverflowInLeft &&
    css`
      left: 50%;
      transform: ${`translateX(calc(-50% + ${overflowSize}px))`};
    `}

  ${({ isOverflowInRight, overflowSize }) =>
    isOverflowInRight &&
    css`
      left: 50%;
      transform: ${`translateX(calc(-50% + ${overflowSize - 24}px))`};
      /* transform: ${`translateX(-50%)`}; */
    `}
`;
