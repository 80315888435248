import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';

import BackgroundImg from '@assets/pages/Onboarding/Finished/background-img.svg';
import Details1 from '@assets/pages/Onboarding/Finished/details1.svg';
import Details2 from '@assets/pages/Onboarding/Finished/details2.svg';
import Details3 from '@assets/pages/Onboarding/Finished/details3.svg';
import Details4 from '@assets/pages/Onboarding/Finished/details4.svg';
import Details5 from '@assets/pages/Onboarding/Finished/details5.svg';

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
`;

export const LeftSide = styled.div`
  position: relative;
  width: 70vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 20px;
  background: ${({ theme }) => theme.gradient.grayDark};

  &::before {
    content: '';
    position: absolute;
    top: 33px;
    left: 26px;
    width: 28px;
    height: 30px;
    background-image: url(${Details2});
    background-repeat: no-repeat;

    @media only screen and (max-width: 620px) {
      display: none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 58px;
    right: 53px;
    width: 16px;
    height: 16px;
    background-image: url(${Details3});
    background-repeat: no-repeat;

    @media only screen and (max-width: 620px) {
      display: none;
    }
  }

  @media only screen and (max-width: 1080px) {
    width: 100vw;
  }
`;

export const HeadingAndButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
  margin: 0 auto;
  padding-top: 158px;

  @media only screen and (max-height: 475px) {
    padding-top: 100px;
  }
`;

export const Heading = styled(HeadingBase)`
  line-height: 150%;

  &::before {
    content: '';
    position: absolute;
    bottom: 54px;
    left: 32px;
    width: 18px;
    height: 142px;
    background-image: url(${Details1});
    background-repeat: no-repeat;

    @media only screen and (max-width: 620px) {
      display: none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 661px;
    right: 48px;
    width: 22px;
    height: 22px;
    background-image: url(${Details4});
    background-repeat: no-repeat;

    @media only screen and (max-width: 1140px) {
      display: none;
    }
  }
`;

export const FinishOnboardingButton = styled(ButtonBase)`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -72px;
    width: 161px;
    height: 22px;
    background-image: url(${Details5});
    background-repeat: no-repeat;
  }
`;

export const RightSide = styled.div`
  position: relative;
  width: 30vw;
  min-width: 573px;
  background-color: #000;
  background-image: url(${BackgroundImg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right bottom;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    left: 64px;
    bottom: 661px;
    width: 161px;
    height: 22px;
    background-image: url(${Details5});
    background-repeat: no-repeat;
  }

  @media only screen and (max-width: 1080px) {
    display: none;
  }
`;
