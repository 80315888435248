import React from 'react';
import * as HoverCardPrimitive from '@radix-ui/react-hover-card';

import { ICustomHoverCard } from '@domain/interfaces/components/ICustomHoverCard';
import Content from './Content';
import Trigger from './Trigger';
import Arrow from './Arrow';

import * as S from './styles';

const CustomHoverCard: ICustomHoverCard & React.FC<HoverCardPrimitive.HoverCardProps> = ({
  children,
  openDelay = 300,
  closeDelay = 300,
  ...rest
}) => {
  return (
    <S.HoverCard openDelay={openDelay} closeDelay={closeDelay} {...rest}>
      {children}
    </S.HoverCard>
  );
};

CustomHoverCard.Content = Content;
CustomHoverCard.Trigger = Trigger;
CustomHoverCard.Arrow = Arrow;

export default CustomHoverCard;
