import queryString from 'query-string';

import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { WHATSAPP_CONFIG_API_DOMAIN } from '@constants/api';
import {
  IListMessagesProps,
  ICreateMessageProps,
  IDeleteMessageProps,
  IListMessagesResponse,
  IRetrieveMessageProps,
  IUpdateMessageProps,
} from '@domain/interfaces/common/automations/IMessage';

export class MessagesService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listMessages({ storeAliasId, page, filter }: IListMessagesProps): IListMessagesResponse {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/messages?page=${
        page + 1
      }&limit=10&${queryStringFilter}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      messages: data?.messages,
      totalPages: data?.total_pages,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public createMessage({ storeAliasId, data }: ICreateMessageProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/messages`, { ...data });
  }

  public updateMessage({
    storeAliasId,
    message_alias_id,
    data,
  }: IUpdateMessageProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/messages/${message_alias_id}`,
      { ...data },
    );
  }

  public deleteMessage({ storeAliasId, message_alias_id }: IDeleteMessageProps): Promise<any> {
    return this.apiService.delete(
      `/api/v1/users/stores/${storeAliasId}/messages/${message_alias_id}`,
    );
  }

  public retrieveMessage({ storeAliasId, message_alias_id }: IRetrieveMessageProps): Promise<any> {
    return this.apiService.get(`/api/v1/users/stores/${storeAliasId}/messages/${message_alias_id}`);
  }
}

const messagesService = new MessagesService(WHATSAPP_CONFIG_API_DOMAIN);

export default messagesService;
