export enum EAbandonCartType {
  PRODUCT_NAME = 'PRODUCT_NAME',
  ABANDONED_CART_TOTAL_VALUE = 'ABANDONED_CART_TOTAL_VALUE',
  ORDER_STATUS = 'ORDER_STATUS',
  SELL_RECOVERED = 'SELL_RECOVERED',
}

export enum EAbandonCartProductOptions {
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  NOT_EQUAL = 'NOT_EQUAL',
}

export enum EAbandonCartTotalValueOptions {
  SMALLER = 'SMALLER',
  SMALLER_EQUAL = 'SMALLER_EQUAL',
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  LARGER = 'LARGER',
  LARGER_EQUAL = 'LARGER_EQUAL',
}

export enum EAbandonCartOrderStatusOptions {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
}

export enum EAbandonCartOrderStatusPicker {
  PAID = 'PAID',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
  CHARGED_BACK = 'CHARGED_BACK',
  IN_ANALYSIS = 'IN_ANALYSIS',
  REJECTED = 'REJECTED',
}

export enum EAbandonedCartSellRecoveredPicker {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

export type AbandonCartOptions<Type> = Type extends EAbandonCartType.PRODUCT_NAME
  ? EAbandonCartProductOptions
  : Type extends EAbandonCartType.ABANDONED_CART_TOTAL_VALUE
  ? EAbandonCartTotalValueOptions
  : EAbandonCartOrderStatusOptions;
