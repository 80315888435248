import React from 'react';

import { IOptionProps } from '@domain/interfaces/components/ITab';

import * as S from './styles';

const Option: React.FC<IOptionProps> = ({ children, isSelected, onClick }) => {
  return (
    <S.OptionButton isSelected={isSelected} onClick={onClick}>
      {children}
    </S.OptionButton>
  );
};

export default Option;
