import React from 'react';
import { WarningCircle } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';
import { useToast } from '@helpers/hooks/useToast';

import storesService from '@services/pages/stores/stores';

import * as S from './styles';

const DeactivatedStore: React.FC = () => {
  const theme = useTheme();
  const { store, mutateStore } = useStoreConfig();
  const { analytics, user } = useConfig();
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handleOpen = React.useCallback(() => {
    setIsOpen(state => !state);
  }, []);

  const onButtonClick = React.useCallback(async () => {
    try {
      await storesService.reactivateStore(storeAliasId);

      analytics?.track(
        'Has Activated Store',
        {
          email: user?.email,
        },
        { context: { groupId: storeAliasId } },
      );

      handleOpen();

      mutateStore();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [mutateStore, storeAliasId, toast, handleOpen, analytics, user]);

  React.useEffect(() => {
    if (store && !store.is_active) {
      setIsOpen(true);
    }
  }, [store]);

  React.useEffect(() => {
    if (isOpen) {
      analytics?.track(
        'Deactivated Store Modal Viewed',
        {
          email: user?.email,
        },
        { context: { groupId: storeAliasId } },
      );
    }
  }, [analytics, isOpen, user, storeAliasId]);

  return (
    <S.Modal isOpen={isOpen} toggle={handleOpen}>
      <S.Header>
        <WarningCircle size={24} color={theme.colors.danger.default} />
        <S.Title>Sua loja está desativada!</S.Title>
      </S.Header>

      <S.Body>
        <S.Description>
          A sua loja ficou inativa por conta do tempo inativo dentro da nossa plataforma. Clique em
          ativar ela para voltar a processar os seus dados.
        </S.Description>

        <S.Button onClick={onButtonClick}>Ativar loja</S.Button>
      </S.Body>
    </S.Modal>
  );
};

export default DeactivatedStore;
