import React from 'react';

import { useAdsManager } from '@helpers/hooks/pages/dashboard/adsManager/useAdsManager';
import { useCampaigns } from '@helpers/hooks/pages/dashboard/adsManager/useCampaigns';

import * as S from './styles';

const Footer: React.FC = () => {
  const { tableColumns, columnsSize } = useAdsManager();
  const { mappedAdSenseCampaignsFooter } = useCampaigns();

  const [isHovering, setIsHovering] = React.useState<boolean>(false);

  const onMouseEnter = React.useCallback(() => {
    setIsHovering(true);
  }, []);

  const onMouseLeave = React.useCallback(() => {
    setIsHovering(false);
  }, []);

  const totalCampaignsCellWidth =
    columnsSize[tableColumns[0].id] +
    columnsSize[tableColumns[1].id] +
    columnsSize[tableColumns[2].id] +
    4;

  return (
    <S.Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {mappedAdSenseCampaignsFooter.map((column: any) => {
        const Component = column.component;
        const width =
          column.id !== 'total_campaigns' ? columnsSize[column.id] : totalCampaignsCellWidth;

        return (
          <S.HeaderCell key={column.id} isHovering={isHovering}>
            <S.HeaderCellContent style={{ width }}>
              <Component
                rowAliasId={column.id}
                data={column.data}
                forecastData={column.forecastData}
              />
            </S.HeaderCellContent>
          </S.HeaderCell>
        );
      })}
    </S.Wrapper>
  );
};

export default Footer;
