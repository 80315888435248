import { AxiosResponse } from 'axios';

import {
  ICreateNonMonthlySubscriptionByCheckoutLinkProps,
  ICreateNonMonthlySubscriptionByCheckoutLinkResponse,
  ICreateSubscriptionByCheckoutLinkProps,
  ICreateSubscriptionByCheckoutLinkResponse,
  IGenerateCheckoutLinkProps,
  IGenerateCheckoutLinkResponse,
  IUpdateNonMonthlySubscriptionByCheckoutLinkProps,
  IUpdateNonMonthlySubscriptionByCheckoutLinkResponse,
  IUpdateSubscriptionByCheckoutLinkProps,
  IUpdateSubscriptionByCheckoutLinkResponse,
} from '@domain/interfaces/subscription/ICheckout';

import { ApiService } from '@services/api';

import { USER_API_DOMAIN } from '@constants/api';

export class CheckoutService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public generateCheckoutLink({
    storeAliasId,
    planAliasId,
    data,
  }: IGenerateCheckoutLinkProps): Promise<AxiosResponse<IGenerateCheckoutLinkResponse>> {
    return this.apiService.post<IGenerateCheckoutLinkResponse>(
      `/api/v1/users/stores/${storeAliasId}/plans/${planAliasId}/checkouts/link`,
      { ...data },
    );
  }

  public createMonthlySubscriptionByCheckoutLink({
    storeAliasId,
    data,
  }: ICreateSubscriptionByCheckoutLinkProps): Promise<
    AxiosResponse<ICreateSubscriptionByCheckoutLinkResponse>
  > {
    return this.apiService.post<ICreateSubscriptionByCheckoutLinkResponse>(
      `/api/v1/users/stores/${storeAliasId}/subscriptions/checkouts`,
      { ...data },
    );
  }

  public createNonMonthlySubscriptionByCheckoutLink({
    storeAliasId,
    data,
  }: ICreateNonMonthlySubscriptionByCheckoutLinkProps): Promise<
    AxiosResponse<ICreateNonMonthlySubscriptionByCheckoutLinkResponse>
  > {
    return this.apiService.post<ICreateNonMonthlySubscriptionByCheckoutLinkResponse>(
      `/api/v1/users/stores/${storeAliasId}/subscriptions/checkouts/non-monthly`,
      { ...data },
    );
  }

  public updateMonthlySubscriptionByCheckoutLink({
    storeAliasId,
    data,
  }: IUpdateSubscriptionByCheckoutLinkProps): Promise<
    AxiosResponse<IUpdateSubscriptionByCheckoutLinkResponse>
  > {
    return this.apiService.put<IUpdateSubscriptionByCheckoutLinkResponse>(
      `/api/v1/users/stores/${storeAliasId}/subscriptions/checkouts/change-plan`,
      { ...data },
    );
  }

  public updateNonMonthlySubscriptionByCheckoutLink({
    storeAliasId,
    data,
  }: IUpdateNonMonthlySubscriptionByCheckoutLinkProps): Promise<
    AxiosResponse<IUpdateNonMonthlySubscriptionByCheckoutLinkResponse>
  > {
    return this.apiService.put<IUpdateNonMonthlySubscriptionByCheckoutLinkResponse>(
      `/api/v1/users/stores/${storeAliasId}/subscriptions/checkouts/change-plan/non-monthly`,
      { ...data },
    );
  }
}

const checkoutService = new CheckoutService(USER_API_DOMAIN);

export default checkoutService;
