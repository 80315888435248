import queryString from 'query-string';

import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DASHBOARD_DOMAIN } from '@constants/api';
import {
  IGetFinancialTableByDayProps,
  IGetFinancialTableByDayResponse,
  IGetFinancialTableByPeriodProps,
  IGetFinancialTableByPeriodResponse,
} from '@domain/interfaces/dashboard/FinancialDetails/IFinancialDetails';

export class FinancialDetailsService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getFinancialTableByPeriod({
    storeAliasId,
    startDate,
    endDate,
    filter,
  }: IGetFinancialTableByPeriodProps): IGetFinancialTableByPeriodResponse {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/dashboards/financial-table-by-period?start_date=${startDate}&end_date=${endDate}&${queryStringFilter}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      period: data?.period,
      comparePeriod: data?.compare_period,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public getFinancialTableByDay({
    storeAliasId,
    startDate,
    endDate,
    filter,
  }: IGetFinancialTableByDayProps): IGetFinancialTableByDayResponse {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/dashboards/financial-table-by-day?start_date=${startDate}&end_date=${endDate}&${queryStringFilter}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      financialTableByDay: data?.financial_table_by_day,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public getFinancialTableByDayPromise({
    storeAliasId,
    startDate,
    endDate,
  }: IGetFinancialTableByDayProps): Promise<any> {
    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/dashboards/financial-table-by-day?start_date=${startDate}&end_date=${endDate}`,
    );
  }
}

const financialDetailsService = new FinancialDetailsService(API_DASHBOARD_DOMAIN);

export default financialDetailsService;
