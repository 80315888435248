import React from 'react';
import { Facebook } from '@profitfy/pakkun-icons';
import { ListChildComponentProps } from 'react-window';

import { useScriptAndUtms } from '@helpers/hooks/pages/dashboard/adsManager/onboarding/useScriptAndUtms';

import * as S from './styles';

const CampaignOption: React.FC<ListChildComponentProps> = ({ index, style }) => {
  const { filteredAds, selectedAdSenseAds, handleSelectedAdSenseAd } = useScriptAndUtms();

  const campaign = filteredAds[index];

  const [isChecked, setIsChecked] = React.useState<boolean>(false);

  const onCheckChange = React.useCallback(
    event => {
      if (event && !event.target) {
        handleSelectedAdSenseAd(campaign);
      }
    },
    [handleSelectedAdSenseAd, campaign],
  );

  React.useEffect(() => {
    const foundSelectedAdSenseCampaign = selectedAdSenseAds.find(
      selectedCampaign => selectedCampaign.alias_id === campaign.alias_id,
    );

    if (foundSelectedAdSenseCampaign) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [campaign, selectedAdSenseAds]);

  return (
    <S.Wrapper style={style}>
      <S.CheckboxGroup>
        <S.CheckboxLabel htmlFor={`campaign-${campaign.alias_id}`}>
          <Facebook size={18} />
          <S.OptionText>{campaign.name}</S.OptionText>
        </S.CheckboxLabel>
        <S.Checkbox
          checked={isChecked}
          onChange={onCheckChange}
          id={`campaign-${campaign.alias_id}`}
        />
      </S.CheckboxGroup>
    </S.Wrapper>
  );
};

export default CampaignOption;
