import React from 'react';
// import { HexColorPicker } from 'react-colorful';

import { IColorPickerProps } from '@domain/interfaces/dashboard/CustomValuesCategories/IColorPicker';

import * as S from './styles';

const ColorPicker: React.FC<IColorPickerProps> = ({ handleIsChoosingColor, onSelectColor }) => {
  const colorPickerRef = React.useRef<HTMLDivElement>(null);

  const onColorSelect = React.useCallback(
    color => {
      onSelectColor(color);

      handleIsChoosingColor(false);
    },
    [handleIsChoosingColor, onSelectColor],
  );

  React.useEffect(() => {
    if (colorPickerRef?.current) {
      colorPickerRef.current.focus();
    }
  });

  return (
    <S.Wrapper ref={colorPickerRef} tabIndex={0}>
      <S.Arrow />
      <S.ColorPickerWrapper>
        <S.Color color="#800000" onClick={() => onColorSelect('#800000')} />
        <S.Color color="#E50000" onClick={() => onColorSelect('#E50000')} />
        <S.Color color="#FF4081" onClick={() => onColorSelect('#FF4081')} />
        <S.Color color="#FF7FAB" onClick={() => onColorSelect('#FF7FAB')} />
        <S.Color color="#F900EA" onClick={() => onColorSelect('#F900EA')} />
        <S.Color color="#EA80FC" onClick={() => onColorSelect('#EA80FC')} />
        <S.Color color="#BF55EC" onClick={() => onColorSelect('#BF55EC')} />
        <S.Color color="#9B59B6" onClick={() => onColorSelect('#9B59B6')} />
        <S.Color color="#7C4DFF" onClick={() => onColorSelect('#7C4DFF')} />
        <S.Color color="#0231E8" onClick={() => onColorSelect('#0231E8')} />
        <S.Color color="#81B1FF" onClick={() => onColorSelect('#81B1FF')} />
        <S.Color color="#3397DD" onClick={() => onColorSelect('#3397DD')} />
        <S.Color color="#3082B7" onClick={() => onColorSelect('#3082B7')} />
        <S.Color color="#04A9F4" onClick={() => onColorSelect('#04A9F4')} />
        <S.Color color="#02BCD4" onClick={() => onColorSelect('#02BCD4')} />
        <S.Color color="#1BBC9C" onClick={() => onColorSelect('#1BBC9C')} />
        <S.Color color="#2ECD6F" onClick={() => onColorSelect('#2ECD6F')} />
        <S.Color color="#F9D900" onClick={() => onColorSelect('#F9D900')} />
        <S.Color color="#AF7E2E" onClick={() => onColorSelect('#AF7E2E')} />
        <S.Color color="#FF7800" onClick={() => onColorSelect('#FF7800')} />
        <S.Color color="#E65100" onClick={() => onColorSelect('#E65100')} />
        <S.Color color="#B5BCC2" onClick={() => onColorSelect('#B5BCC2')} />
        <S.Color color="#667684" onClick={() => onColorSelect('#667684')} />
        <S.CustomColor />
      </S.ColorPickerWrapper>
    </S.Wrapper>
  );
};

export default ColorPicker;
