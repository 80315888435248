import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import styled from 'styled-components/macro';

export const Container = styled.div`
  max-width: 1024px;
  width: 100%;
  padding-top: 36px;
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1024px;
  width: 100%;
  padding-top: 32px;
  gap: 16px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StepsWrapper = styled.div`
  max-width: 672px;
  width: 100%;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 336px;
  width: 100%;
  gap: 16px;

  @media only screen and (max-width: 768px) {
    max-width: 672px;
  }
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 4px;
  line-height: 225%;
`;

export const SubTitle = styled(TextBase)`
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const Divider = styled.hr`
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.gray[5]};
`;

export const CardSteps = styled.div`
  max-width: 672px;
  width: 100%;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  box-sizing: border-box;
`;

export const TipsCardsProduct = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 336px;
  height: 269px;
  width: 100%;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  box-sizing: border-box;
  position: relative;
  background-image: url('https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/home/product_analytics.png');

  @media only screen and (max-width: 768px) {
    max-width: 672px;
  }
  cursor: pointer;
`;

export const TipsCardsAutomation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 336px;
  height: 269px;
  width: 100%;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  box-sizing: border-box;
  position: relative;
  background-image: url('https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/home/automations.png');

  @media only screen and (max-width: 768px) {
    max-width: 672px;
  }
  cursor: pointer;
`;

export const TipsCardsAds = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 336px;
  height: 269px;
  width: 100%;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  box-sizing: border-box;
  position: relative;
  background-image: url('https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/home/ad_manager.png');

  @media only screen and (max-width: 768px) {
    max-width: 672px;
  }
  cursor: pointer;
`;

export const TipsDescriptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px;
  gap: 8px;
`;

export const TipTitle = styled(TextBase)`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const TipDescription = styled(TextBase)`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 105%;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const Header = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 24px 32px;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  border-radius: 7px;
  width: 36px;
  height: 36px;
`;

export const TipsIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px;
`;

export const TipsIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  border-radius: 50%;
  width: 36px;
  height: 36px;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    max-width: 672px;
  }
`;

export const CardTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProgressBarWrapper = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 4px;
`;

export const CardTitle = styled(TextBase)`
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const CardDesciption = styled(TextBase)`
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const StepsText = styled(TextBase)`
  font-weight: 500;
  font-size: 0.75rem;
  display: flex;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.gray[3]};
`;

export const Body = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 8px 32px;

  display: flex;
  flex-direction: column;
  gap: 1px;
`;

export const StepWrapper = styled.div`
  width: 100%;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 8px;
  cursor: pointer;

  &:hover {
    outline: 2px solid ${({ theme }) => theme.colors.green.default};
    border-radius: 12px;
  }
`;

export const StepMark = styled.div<{ completed?: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.gray[1]};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ completed }) =>
    completed && {
      backgroundColor: '#59B57840',
      borderColor: '#59B57840',
    }}
`;

export const StepTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  pointer-events: all;
  margin-left: 20px;
  margin-right: auto;
`;

export const StepTitle = styled(TextBase)`
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const StepDescription = styled(TextBase)`
  font-weight: 400;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray[2]};
`;
