import styled, { css } from 'styled-components/macro';
import BadgeBase from '@components/common/core/DataDisplay/Badge';
import TextBase from '@components/common/core/DataDisplay/Text';
import { EOrderStatus } from '@domain/enums/common/EOrder';

interface IStatusProps {
  status: EOrderStatus;
}

export const StatusBadge = styled(BadgeBase)`
  margin: 0 auto;
`;

export const StatusWrapper = styled.div<IStatusProps>`
  display: flex;
  width: 100%;
  max-width: 110px;
  background-color: #183231;
  border-color: #00f2a1;
  border: 1px solid #00f2a1;
  border-radius: 4px;
  padding: 4px, 8px, 4px, 8px;
  box-sizing: border-box;
  transition: all 0.3s;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  gap: 4px;

  ${({ status }) =>
    status === EOrderStatus.PENDING &&
    css`
      background-color: #383637;
      border-color: #efc980;
      border: 1px solid #efc980;

      & > span:last-child {
        color: #efc980;
      }
    `};

  ${({ status }) =>
    status === EOrderStatus.PAID &&
    css`
      background-color: #20302c;
      border-color: #38884e;
      border: 1px solid #38884e;

      & > span:last-child {
        color: #50e170;
      }
    `};

  ${({ status }) =>
    status === EOrderStatus.REFUNDED &&
    css`
      background-color: #2d2528;
      border-color: #d06c42;
      border: 1px solid #d06c42;

      & > span:last-child {
        color: #d06c42;
      }
    `};

  ${({ status }) =>
    status === EOrderStatus.CANCELED &&
    css`
      background-color: #352933;
      border-color: #d0425c;
      border: 1px solid #d0425c;

      & > span:last-child {
        color: #d0425c;
      }
    `};

  ${({ status }) =>
    status === EOrderStatus.CHARGED_BACK &&
    css`
      background-color: #272136;
      border-color: #9542d0;
      border: 1px solid #9542d0;

      & > span:last-child {
        color: #9542d0;
      }
    `};

  ${({ status }) =>
    status === EOrderStatus.IN_ANALYSIS &&
    css`
      background-color: #212839;
      border-color: #5b8def;
      border: 1px solid #5b8def;

      & > span:last-child {
        color: #5b8def;
      }
    `};

  ${({ status }) =>
    status === EOrderStatus.REJECTED &&
    css`
      background-color: #352933;
      border-color: #d0425c;
      border: 1px solid #d0425c;

      & > span:last-child {
        color: #d0425c;
      }
    `};

  ${({ status }) =>
    status === EOrderStatus.NO_INFORMATION &&
    css`
      background-color: #292b35;
      border-color: #a7afc6;
      border: 1px solid #a7afc6;

      & > span:last-child {
        color: #a7afc6;
      }
    `};
`;

export const Text = styled(TextBase)`
  font-size: 0.75rem;
  font-weight: 600;
  color: #00f2a1;
`;
