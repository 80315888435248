import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import { useSubscription } from '@helpers/hooks/common/store/subscription/useSubscription';

import * as S from './styles';

const Downgrade: React.FC = ({ children }) => {
  const history = useHistory();
  const { selectedPlanIdentifier } = useSubscription();
  const { storeAliasId } = useParams<IParams>();

  React.useEffect(() => {
    if (!selectedPlanIdentifier) {
      history.push(`/${storeAliasId}/dashboard/subscription`);
    }
  }, [selectedPlanIdentifier, history, storeAliasId]);

  return (
    <S.Container>
      <S.Wrapper>{children}</S.Wrapper>
    </S.Container>
  );
};

export default Downgrade;
