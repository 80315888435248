import React from 'react';

import Option from './Option';

import * as S from './styles';

export interface ITabProps {
  children: Array<React.ReactNode>;
  defaultTab: number | string;
  onChangeTab: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Tab: React.FC<ITabProps> = ({ children, defaultTab, onChangeTab, ...rest }) => {
  const [currentTab, setCurrentTab] = React.useState<number>(Number(defaultTab));

  const handleTabSelect = React.useCallback(
    index => {
      setCurrentTab(index);
      onChangeTab(index);
    },
    [onChangeTab],
  );

  React.useEffect(() => {
    if (defaultTab) {
      setCurrentTab(Number(defaultTab));
    }
  }, [defaultTab]);

  return (
    <S.Wrapper {...rest}>
      {children.map((tab: any, index: number) => {
        return (
          <Option
            key={tab?.props?.children}
            isSelected={currentTab === index}
            onClick={() => handleTabSelect(index)}
          >
            {tab?.props?.children}
          </Option>
        );
      })}
    </S.Wrapper>
  );
};

export default Tab;
