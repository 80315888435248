import React from 'react';
import { GoBack } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { IGoBackButtonProps } from '@domain/interfaces/components/IGoBackButton';

import * as S from './styles';

const GoBackButton: React.FC<IGoBackButtonProps> = ({ onClick, buttonText, ...rest }) => {
  const theme: any = useTheme();

  return (
    <S.Button {...rest} onClick={onClick} type="button">
      <GoBack color={theme.colors.font} size={28} />
      {buttonText || 'Retornar para o passo anterior'}
    </S.Button>
  );
};

export default GoBackButton;
