import React from 'react';

import { ETabOption } from '@domain/enums/dashboard/storeSubscription/ETabOptions';
import { IStoreSubscriptionContext } from '@domain/interfaces/hooks/dashboard/IStoreSubscription';

import { useQuery } from '@helpers/hooks/useQuery';

const StoreSubscriptionContext = React.createContext<IStoreSubscriptionContext | null>(null);

export const StoreSubscriptionProvider: React.FC = ({ children }) => {
  const query = useQuery();

  const [selectedTab, setSelectedTab] = React.useState<ETabOption>(ETabOption.SUBSCRIPTION);

  const defaultTab = query.get('defaultTab');

  const handleSelectedTab = React.useCallback(option => {
    setSelectedTab(option);
  }, []);

  React.useEffect(() => {
    if (defaultTab === 'plans') {
      setSelectedTab(ETabOption.PLANS);
    }

    if (defaultTab === 'general') {
      setSelectedTab(ETabOption.SUBSCRIPTION);
    }
  }, [defaultTab]);

  return (
    <StoreSubscriptionContext.Provider value={{ handleSelectedTab, selectedTab }}>
      {children}
    </StoreSubscriptionContext.Provider>
  );
};

export const useStoreSubscription = (): IStoreSubscriptionContext => {
  const context = React.useContext(StoreSubscriptionContext);

  if (!context) {
    throw new Error('useStoreSubscription must be used within StoreSubscriptionProvider');
  }

  return context;
};
