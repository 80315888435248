export const PROVIDER_CODE_ERRORS = {
  4: 'Transação não autorizada! Cartão bloqueado pelo banco emissor.',
  6: 'Transação não autorizada! Cartão cancelado.',
  7: 'Transação negada.',
  8: 'Transação não autorizada. Código de segurança inválido.',
  12: 'Transação inválida, erro no cartão.',
  39: 'Transação não autorizada! Erro no banco emissor.',
  43: 'Transação não autorizada! Cartão bloqueado por roubo.',
  51: 'Transação não autorizada! Limite excedido ou sem saldo.',
  52: 'Cartão com dígito de controle inválido.',
  54: 'Transação não autorizada. Cartão vencido',
  57: 'Transação não permitida para o cartão. Tente novamente com outro.',
  59: 'Transação não autorizada. Suspeita de fraude.',
  63: 'Transação não autorizada. Violação de segurança',
  65: 'Transação não autorizada! Excedida a quantidade de transações para o cartão.',
  67: 'Transação não autorizada! Cartão bloqueado para compras hoje.',
  70: 'Transação não autorizada! Limite excedido ou sem saldo.',
  89: 'Erro na transação.',
  90: 'Transação não permitida. Falha da operação.',
  91: 'Transação não autorizada. Banco emissor temporariamente indisponível.',
};
