export enum EEcommerceProvider {
  TINY = 'TINY',
  BLING = 'BLING',
  NUVEM_SHOP = 'NUVEM_SHOP',
  SHOPIFY = 'SHOPIFY',
  CART_PANDA = 'CART_PANDA',
  YAMPI = 'YAMPI',
  WOO_COMMERCE = 'WOO_COMMERCE',
  LOJA_INTEGRADA = 'LOJA_INTEGRADA',
  TRAY = 'TRAY',
}
