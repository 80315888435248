import React from 'react';
import { Warning } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { ISyncingCampaignsModalProps } from '@domain/interfaces/dashboard/ProductTag/ISideModalTags';

import * as S from './styles';

const SyncingCampaignsModal: React.FC<ISyncingCampaignsModalProps> = ({ isOpen, toggle }) => {
  const theme = useTheme();

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Modal.Header>
        <Warning size={24} color={theme.colors.warning.default} outline />
        Essa sincronização pode levar alguns minutos
      </S.Modal.Header>
      <S.Modal.Body>
        <S.Text>Estamos sincronizando suas campanhas com suas plataformas de anúncios.</S.Text>

        <S.ButtonsWrapper>
          <S.ConfirmButton onClick={toggle}>Entendi</S.ConfirmButton>
        </S.ButtonsWrapper>
      </S.Modal.Body>
    </S.Modal>
  );
};

export default SyncingCampaignsModal;
