import styled from 'styled-components/macro';

import BadgeBase from '@components/common/core/DataDisplay/Badge';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`;

export const BadgeTag = styled(BadgeBase)`
  background-color: ${({ theme }) => theme.colors.gray[4]};
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const TooltipText = styled(TextBase)``;

export const Tooltip = styled(CustomTooltipBase)``;

export const TooltipTrigger = styled(CustomTooltipBase.Trigger)``;

export const TooltipContent = styled(CustomTooltipBase.Content)`
  display: flex;
  flex-direction: column;
`;

export const TooltipArrow = styled(CustomTooltipBase.Arrow)``;
