import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import ImageBase from '@components/common/core/DataDisplay/Image';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Modal = styled(ModalBase)`
  width: 100%;
  max-width: 456px;
  box-sizing: border-box;
  padding: 0px;
`;

export const Header = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.green.light};
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 32px;
  box-sizing: border-box;
`;

export const Image = styled(ImageBase)`
  width: 100%;
  max-width: 164px;
`;

export const TemplateName = styled(TextBase)``;

export const Body = styled.div`
  width: 100%;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-sizing: border-box;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const InfoTitle = styled(TextBase)`
  line-height: 120%;
`;

export const TemplateDescrition = styled(TextBase)`
  line-height: 120%;
`;

export const ChooseTemplateButton = styled(ButtonBase)`
  width: 100%;
`;
