import queryString from 'query-string';

import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { ADS_API_DOMAIN } from '@constants/api';

import {
  IListAdSenseCampaignsReportProps,
  IListAdSenseCampaignsReportResponse,
} from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IAdSenseCampaigns';

export class AdSenseCampaignsService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listAdSenseCampaignsReport({
    storeAliasId,
    startDate,
    endDate,
    filter,
  }: IListAdSenseCampaignsReportProps): IListAdSenseCampaignsReportResponse {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/tables/marketing?start_date=${startDate}&end_date=${endDate}&${queryStringFilter}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      adSenseCampaignsReport: data?.ad_sense_campaigns_report,
      lastSync: data?.last_sync,
      summaryReport: data?.summary_report,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }
}

const adSenseCampaignsService = new AdSenseCampaignsService(ADS_API_DOMAIN);

export default adSenseCampaignsService;
