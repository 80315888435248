import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/CustomModal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';
import PaginateBase from '@components/common/core/Utils/Paginate';

export const Wrapper = styled(ModalBase)`
  width: 100%;
  max-width: 1104px;
  padding: 32px 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media only screen and (max-height: 540px) {
    height: 95vh;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`;

export const GroupTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled(TextBase)`
  font-size: 1.25rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
  margin-left: 8px;
`;

export const Description = styled(TextBase)`
  margin-top: 24px;
  color: ${({ theme }) => theme.colors.gray[3]};
`;

export const EditMessageButton = styled(ButtonBase)``;

export const NewMessageButton = styled(ButtonBase)``;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: unset;
  padding: unset;
  border: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Paginate = styled(PaginateBase)`
  margin-left: auto;
  margin-top: 12px;
`;
