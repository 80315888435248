import React from 'react';

import { EAbandonCartTotalValueOptions } from '@domain/enums/dashboard/automations/node/conditionalNode/EAbandonCart';

import * as S from './styles';

interface IOptionTotalValueProps {
  defaultValue?: string;
  onChange: (data: string) => void;
}

const OptionTotalValue: React.FC<IOptionTotalValueProps> = ({
  onChange,
  defaultValue = EAbandonCartTotalValueOptions.SMALLER,
}) => {
  return (
    <S.Select
      name="options"
      defaultValue={defaultValue}
      onChange={e => {
        onChange(e.target.value);
      }}
    >
      <S.Option value={EAbandonCartTotalValueOptions.SMALLER}>Menor que</S.Option>
      <S.Option value={EAbandonCartTotalValueOptions.SMALLER_EQUAL}>Menor ou igual</S.Option>
      <S.Option value={EAbandonCartTotalValueOptions.EQUAL}>Igual</S.Option>
      <S.Option value={EAbandonCartTotalValueOptions.NOT_EQUAL}>Diferente</S.Option>
      <S.Option value={EAbandonCartTotalValueOptions.LARGER}>Maior que</S.Option>
      <S.Option value={EAbandonCartTotalValueOptions.LARGER_EQUAL}>Maior ou igual</S.Option>
    </S.Select>
  );
};

export default OptionTotalValue;
