import React from 'react';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { ICpc } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/ISummaryReport';

import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const Cpc: React.FC<ICampaignProps> = ({ data }) => {
  const cpcData: ICpc = data;

  return (
    <S.Wrapper>
      <S.CpcAmount>{`R$ ${numberFormatter(cpcData.average_amount, 2)}`}</S.CpcAmount>
      <S.CpcLabel>Por Clique</S.CpcLabel>
    </S.Wrapper>
  );
};

export default Cpc;
