import React from 'react';

import {
  OperationalExpends,
  ProductCost,
  BalanceCircle,
  MarketingCircle,
} from '@profitfy/pakkun-icons';

import { useProductDetails } from '@helpers/hooks/pages/dashboard/productAnalytics/useProductDetails';
import { hasActiveAdSenseCampaign } from '@helpers/utils/dashboard/productAnalytic/productDetails';

import { useProductCostDetails } from '@helpers/hooks/pages/dashboard/productCostDetails/productCostDetails';

import EditCogsSideModal from './EditCogsSideModal';
import HistoricSidemodalWrapper from './HistoricSidemodalWrapper';
import UpdateVariantCostModal from './UpdateVariantCostModal';
import SyncPastCogsSidemodal from './SyncPastCogsSidemodal';
import NoAdSenseCampaignsCard from './NoAdSenseCampaignsCard';
import Card from './Card';

import * as S from './styles';

const OverviewCards: React.FC = () => {
  const {
    selectedVariant,
    handleResetVariant,
    isSyncPastCogsModalOpen,
    handleSyncPastCogsModalOpen,
    isEditCogsSideModalOpen,
    handleEditCogsSideModalOpen,
  } = useProductCostDetails();

  const { productFinancialReport, adSenseCampaigns } = useProductDetails();

  const { cogs, fee, marketing, net_revenue } = productFinancialReport;

  const hasSelectedVariantToEdit = Boolean(selectedVariant?.id);

  return (
    <>
      <S.Wrapper>
        <Card
          name="Receita Líquida"
          value={net_revenue.amount}
          percentage={net_revenue.growth}
          icon={OperationalExpends}
        />

        <Card
          name="Custo dos Produtos"
          value={cogs.amount}
          percentage={cogs.growth}
          icon={ProductCost}
        />

        {hasActiveAdSenseCampaign(adSenseCampaigns) ? (
          <Card
            name="Marketing"
            value={marketing.amount}
            percentage={marketing.growth}
            icon={MarketingCircle}
          />
        ) : (
          <NoAdSenseCampaignsCard />
        )}

        <Card
          name="Impostos e Taxas"
          value={fee.amount}
          percentage={fee.growth}
          icon={BalanceCircle}
        />
      </S.Wrapper>

      {!hasSelectedVariantToEdit && (
        <EditCogsSideModal isOpen={isEditCogsSideModalOpen} toggle={handleEditCogsSideModalOpen} />
      )}

      {hasSelectedVariantToEdit && <HistoricSidemodalWrapper isOpen toggle={handleResetVariant} />}

      <UpdateVariantCostModal
        isOpen={isSyncPastCogsModalOpen}
        toggle={handleSyncPastCogsModalOpen}
      />

      <SyncPastCogsSidemodal />
    </>
  );
};

export default OverviewCards;
