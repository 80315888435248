import React from 'react';
import { Tray as TrayIcon } from '@profitfy/pakkun-icons';
import { useHistory, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { EEcommerceProvider } from '@domain/enums/common/integrations/EEcommerceProvider'; // TODO: change to ecommerce
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useEcommerce } from '@helpers/hooks/pages/onboarding/useEcommerce';
import { useDate } from '@helpers/hooks/useDate';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import { newWindow } from '@helpers/hooks/usePopup';

import { ECOMMERCE_PROVIDERS } from '@constants/common/integrations';

import { traySchema } from '@helpers/validators/dashboard/integrationCenter/tray';

import integrationCenterService from '@services/pages/dashboard/integrationCenter/integrationCenter';
import trayAuthService from '@services/pages/dashboard/integrationCenter/ecommerce/tray/trayAuth';

import NextStep from '@components/Onboarding/NextStep';
import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const Tray: React.FC = () => {
  const history = useHistory();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { selectedMarketings, selectedGateways } = useEcommerce();
  const { addSynchronization } = useSynchronization();
  const { format, utcToZonedTime } = useDate();
  const { sendFinancialData, handleStoreBonus } = useOnboarding();

  const [isIntegrating, setIsIntegrating] = React.useState<boolean>(false);
  const [userWindow, setUserWindow] = React.useState<Window | undefined>(undefined);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(traySchema) });

  const domainRegister = register('domain');

  // const nextStep = React.useCallback(async () => {
  //   setIsIntegrating(true);

  //   try {
  //     const { data: trayData } = await trayAuthService.generateTrayUrl({ storeAliasId });

  //     const window = newWindow(trayData?.auth_url, 'Tray', 1280, 720);

  //     setUserWindow(window);
  //   } catch (error: any) {
  //     setIsIntegrating(false);
  //     toast.error(error?.response?.data?.message);
  //   }
  // }, [toast, storeAliasId]);

  const onSubmit = React.useCallback(
    async formData => {
      const includeProtocol =
        formData.domain.includes('https://') || formData.domain.includes('http://');
      const lastDomainCharacter = formData.domain.slice(-1);

      let { domain } = formData;

      if (!includeProtocol) {
        domain = `https://${domain}`;
      }

      if (lastDomainCharacter === '/') {
        domain = domain.slice(0, -1);
      }

      domain = domain.replace('https://', '');

      setIsIntegrating(true);

      try {
        const { data: trayData } = await trayAuthService.generateTrayUrl({
          storeAliasId,
          data: { base_url: domain },
        });

        const window = newWindow(trayData?.auth_url, 'Tray', 1280, 720);

        setUserWindow(window);
        setIsIntegrating(false);
      } catch (error: any) {
        setIsIntegrating(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [storeAliasId, toast],
  );

  const handleWindowClose = React.useCallback(async () => {
    const { data } = await integrationCenterService.getStoreIntegrations({ storeAliasId });

    const hasTrayCredential = data?.store_integrations?.store_front?.has_tray_credential;

    if (hasTrayCredential) {
      const foundTrayIntegration = ECOMMERCE_PROVIDERS.find(
        integration => integration.identifier === EEcommerceProvider.TRAY,
      ) as typeof ECOMMERCE_PROVIDERS[number];

      const { generateSyncRequest } = foundTrayIntegration;

      const params = `?start_date=${format(new Date(2000, 1, 1), 'yyyy-MM-dd')}&end_date=${format(
        utcToZonedTime(new Date()),
        'yyyy-MM-dd',
      )}`;

      addSynchronization({
        name: 'Tray',
        type: ESynchronizationType.STORE_PRODUCT,
        label: 'Produtos',
        dependencies: [],
        storeAliasId,
        config: {
          isOnboardingSynchronization: true,
          showNotification: false,
        },
        request: generateSyncRequest(ESynchronizationType.STORE_PRODUCT, storeAliasId, params),
      });

      sendFinancialData(storeAliasId);

      setIsIntegrating(false);

      if (!selectedGateways.length && !selectedMarketings.length) {
        handleStoreBonus();
        return;
      }

      if (selectedGateways.length) {
        history.push(`/onboarding/${storeAliasId}/ecommerce/integrations/gateways`);
      }

      if (!selectedGateways.length && selectedMarketings.length) {
        history.push(`/onboarding/${storeAliasId}/ecommerce/integrations/marketing`);
      }
    } else {
      setIsIntegrating(false);
    }
  }, [
    addSynchronization,
    format,
    history,
    selectedMarketings,
    storeAliasId,
    utcToZonedTime,
    sendFinancialData,
    handleStoreBonus,
    selectedGateways,
  ]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (userWindow?.closed) {
        clearInterval(interval);
        handleWindowClose();
      }
    }, 1000);
  }, [userWindow, handleWindowClose]);

  return (
    <S.Wrapper>
      <S.FormWrapper>
        <S.Header>
          <S.Title>Integre com sua plataforma de E-commerce</S.Title>
        </S.Header>

        <S.IntegrationTitle>
          <TrayIcon size={36} />

          <S.IntegrationTitleText fontWeight={EHeadingWeight.REGULAR}>
            Integração com Tray
          </S.IntegrationTitleText>
        </S.IntegrationTitle>

        <S.Body>
          <S.Text>
            Para continuar com a integração, vá para o próximo passo e realize a integração com o
            Tray
          </S.Text>
        </S.Body>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>Domínio</S.Label>
            <S.TextField
              {...domainRegister}
              type="text"
              id="tray-store-url"
              placeholder="https://exemplo.com.br"
            />
            {errors.domain && <Text isErrorFeedback>{errors?.domain?.message}</Text>}
          </S.InputGroup>

          <NextStep type="submit" isLoading={isIntegrating} disabled={isIntegrating}>
            Próximo passo
          </NextStep>
        </Form>
      </S.FormWrapper>

      <S.TutorialWrapper>
        <S.TutorialImage
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/onboarding/images/content/onboarding/onboarding-integration-tutorial.svg"
          alt="Profitfy.me Integration Tutorial"
        />
        <S.TutorialTitle>Tutorial de integração</S.TutorialTitle>
        <S.TutorialContent>
          Para realizar a integração você precisará informar o <strong>domínio</strong>.
        </S.TutorialContent>
        <S.TutorialLink
          href="https://ajuda.profitfy.me/pt-BR/articles/8828207-como-integrar-com-a-tray"
          target="_blank"
        >
          Veja como integrar clicando aqui!
        </S.TutorialLink>
      </S.TutorialWrapper>
    </S.Wrapper>
  );
};

export default Tray;
