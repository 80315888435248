import React from 'react';

import { IModalHeader } from '@domain/interfaces/components/IModal';

import * as S from './styles';

const ModalHeader: React.FC<IModalHeader> = ({ children, toggle }) => {
  // const getIconColor = React.useCallback(() => {
  //   if (type === EModalType.WARNING) {
  //     return theme.colors.warning.default;
  //   }

  //   if (type === EModalType.DANGER) {
  //     return theme.colors.danger.default;
  //   }

  //   if (type === EModalType.INFO) {
  //     return theme.colors.green.dark;
  //   }

  //   return theme.colors.green.dark;
  // }, [type]);

  return (
    <S.Header>
      <S.Title>{children}</S.Title>

      {toggle && (
        <S.ExitIconWrapper onClick={toggle}>
          <S.ExitIcon size={20} color="#c53030" />
        </S.ExitIconWrapper>
      )}
    </S.Header>
  );
};

export default ModalHeader;
