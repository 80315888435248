import React from 'react';
import {
  ResponsiveContainer,
  AreaChart as Chart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
} from 'recharts';
import { useTheme } from 'styled-components/macro';

import { IComparedAreaChartsProps, IData } from '@domain/interfaces/components/IComparedAreaCharts';
import { friendlyNumberFormatter } from '@helpers/masks';

import ChartTooltip from './ChartTooltip';
import * as S from './styles';

const ComparedAreaCharts: React.FC<IComparedAreaChartsProps> = ({ data }) => {
  const theme: any = useTheme();

  const getPositionWithValue = React.useCallback((dataToSearch: Array<IData>) => {
    let position = 0;

    dataToSearch.map((dataObject, index) => {
      if (dataObject.current !== null) {
        position = index;
      }

      return null;
    });

    return position;
  }, []);

  return (
    <S.ChartWrapper>
      <ResponsiveContainer width="100%" height="100%">
        <Chart data={data}>
          <XAxis dataKey="name" tick={{ dy: 8 }} axisLine={false} tickLine={false} />
          <YAxis
            tick={{ dx: -10 }}
            axisLine={false}
            tickLine={false}
            tickFormatter={tick => friendlyNumberFormatter(tick)}
          />
          <ReferenceLine
            x={data[getPositionWithValue(data)].name || 0}
            stroke={theme.colors.background[3]}
            strokeWidth={2}
            strokeDasharray="10 10"
          />
          <CartesianGrid
            strokeDasharray="10 10"
            horizontal={false}
            stroke={theme.colors.background[3]}
          />
          <Tooltip content={<ChartTooltip />} />
          <Area
            type="monotone"
            dataKey="past"
            stroke={theme.colors.gray[3]}
            fillOpacity={0.4}
            fill={theme.colors.gray[3]}
            connectNulls={false}
            name="Valor anterior"
          />
          <Area
            type="monotone"
            dataKey="current"
            stroke={theme.colors.green.dark}
            fillOpacity={0.4}
            fill={theme.colors.green.dark}
            connectNulls={false}
            name="Valor atual"
          />
        </Chart>
      </ResponsiveContainer>
    </S.ChartWrapper>
  );
};

export default React.memo(ComparedAreaCharts);
