/* eslint-disable no-restricted-syntax */
import React from 'react';
import { useParams } from 'react-router-dom';

import facebookCredentialService from '@services/pages/dashboard/adSense/facebook/facebookCredential';
import adSenseCredentialService from '@services/pages/dashboard/adSense/adSenseCredential';

import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';
import { IFilterQueryStringData } from '@domain/interfaces/components/IFilter';
import { IFacebookCredentialsProvider } from '@domain/interfaces/integrations/marketing/facebook/IFacebookCredentials';
import { IAdSenseCredential } from '@domain/interfaces/dashboard/AdSense/IAdSenseCredential';

import { useToast } from '@helpers/hooks/useToast';
import { useDate } from '@helpers/hooks/useDate';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import { ADS_PROVIDERS } from '@constants/common/integrations';

const FacebookCredentialsContext = React.createContext<IFacebookCredentialsProvider | null>(null);

export const FacebookCredentialsProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { utcToZonedTime } = useDate();
  const { addSynchronization } = useSynchronization();

  const [isLoggingIn, setIsLoggingIn] = React.useState<boolean>(false);
  const [isLoggingOut, setIsLoggingOut] = React.useState<boolean>(false);
  const [adAccountSearchName, setAdAccountSearchName] = React.useState<string>('');
  const [facebookData, setFacebookData] = React.useState<Record<string, any> | undefined>(
    undefined,
  );
  const [facebookProfileData, setFacebookProfileData] = React.useState<
    Record<string, any> | undefined
  >(undefined);
  const [adSenseCredentialsFilter] = React.useState<IFilterQueryStringData>({
    provider: EAdSenseProvider.FACEBOOK,
  });

  const {
    adSenseCredentials,
    isLoading: isLoadingAdSenseCredentials,
    isValidating: isValidatingAdSenseCredentials,
    mutate: mutateAdSenseCredentials,
    error: adSenseCredentialsError,
  } = adSenseCredentialService.listAdSenseCredentials({
    storeAliasId,
    page: 1,
    filter: adSenseCredentialsFilter,
  });

  const mutate = React.useCallback(async () => {
    const promises = [];

    if (adSenseCredentialsError) promises.push(mutateAdSenseCredentials());

    await Promise.all(promises);
  }, [adSenseCredentialsError, mutateAdSenseCredentials]);

  const handleFacebookData = React.useCallback(data => {
    setFacebookData(data);
  }, []);

  const handleFacebookProfileData = React.useCallback(data => {
    setFacebookProfileData(data);
  }, []);

  const handleAdAccountSearchName = React.useCallback(newSearchName => {
    setAdAccountSearchName(newSearchName);
  }, []);

  const syncAllAdSenseAccounts = React.useCallback(
    async (newAdSenseCredentials: Array<IAdSenseCredential> | undefined) => {
      const foundFacebookIntegration = ADS_PROVIDERS.find(
        integration => integration.identifier === EAdSenseProvider.FACEBOOK,
      ) as typeof ADS_PROVIDERS[number];

      const adSenseCredentialsToBeHandled = newAdSenseCredentials || adSenseCredentials;

      for await (const adSenseCredential of adSenseCredentialsToBeHandled) {
        const { generateSyncRequest } = foundFacebookIntegration;

        const aliases = {
          adSenseCredentialAliasId: adSenseCredential.alias_id,
        };

        addSynchronization({
          name: 'Facebook',
          type: ESynchronizationType.AD_SENSE_ACCOUNTS,
          label: 'Perfil',
          dependencies: [ESynchronizationType.AD_SENSE_ACCOUNTS],
          storeAliasId,
          externalId: adSenseCredential.alias_id,
          config: {
            isOnboardingSynchronization: false,
            showNotification: true,
          },
          request: generateSyncRequest(
            ESynchronizationType.AD_SENSE_ACCOUNTS,
            storeAliasId,
            aliases,
            undefined,
          ),
        });

        await new Promise(resolve => setTimeout(resolve, 500));
      }
    },
    [adSenseCredentials, addSynchronization, storeAliasId],
  );

  const syncAdSenseAccounts = React.useCallback(
    (adSenseCredentialAliasId: string | undefined) => {
      const foundFacebookIntegration = ADS_PROVIDERS.find(
        integration => integration.identifier === EAdSenseProvider.FACEBOOK,
      ) as typeof ADS_PROVIDERS[number];

      const { generateSyncRequest } = foundFacebookIntegration;

      const aliases = {
        adSenseCredentialAliasId,
      };

      addSynchronization({
        name: 'Facebook',
        type: ESynchronizationType.AD_SENSE_ACCOUNTS,
        label: 'Perfil',
        dependencies: [ESynchronizationType.AD_SENSE_ACCOUNTS],
        storeAliasId,
        externalId: adSenseCredentialAliasId,
        config: {
          isOnboardingSynchronization: false,
          showNotification: true,
        },
        request: generateSyncRequest(
          ESynchronizationType.AD_SENSE_ACCOUNTS,
          storeAliasId,
          aliases,
          undefined,
        ),
      });
    },
    [addSynchronization, storeAliasId],
  );

  const handleLogin = React.useCallback(
    async (sessionData: Record<string, any>, profileData: Record<string, any>) => {
      setIsLoggingIn(true);

      try {
        const data = {
          ...sessionData,
          access_token: sessionData?.accessToken,
          name: profileData?.name || 'Sem nome',
          provider_id: profileData?.id,
          provider_created_at: utcToZonedTime(new Date()).toISOString(),
        };

        const {
          data: facebookCredentialData,
        } = await facebookCredentialService.createFacebookCredential({
          storeAliasId,
          data,
        });

        toast.success('Conta conectada com sucesso!');

        handleFacebookData(undefined);
        handleFacebookProfileData(undefined);

        setIsLoggingIn(false);

        const { data: adSenseCredentialsResponse } = await mutateAdSenseCredentials();

        const foundAdSenseCredential = adSenseCredentialsResponse.ad_sense_credentials.find(
          (adSenseCredential: IAdSenseCredential) =>
            adSenseCredential.id ===
            facebookCredentialData?.facebook_credential.ad_sense_credential_id,
        );

        if (foundAdSenseCredential) {
          syncAdSenseAccounts(foundAdSenseCredential.alias_id);
        }

        setIsLoggingIn(false);
      } catch (error: any) {
        setIsLoggingIn(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [
      mutateAdSenseCredentials,
      syncAdSenseAccounts,
      toast,
      storeAliasId,
      utcToZonedTime,
      handleFacebookData,
      handleFacebookProfileData,
    ],
  );

  const handleLogout = React.useCallback(
    async adSenseCredentialAliasId => {
      setIsLoggingOut(true);

      try {
        await adSenseCredentialService.deleteAdSenseCredential({
          storeAliasId,
          adSenseCredentialAliasId,
        });

        toast.success('Perfil desconectado com sucesso!');

        await mutateAdSenseCredentials();

        setIsLoggingOut(false);
      } catch (error: any) {
        setIsLoggingOut(false);
      }
    },
    [storeAliasId, toast, mutateAdSenseCredentials],
  );

  React.useEffect(() => {
    if (facebookData && facebookProfileData && !facebookProfileData.error) {
      handleLogin(facebookData, facebookProfileData);
    }
  }, [facebookData, facebookProfileData, handleLogin]);

  const isLoadingFacebookCredentialsData = isLoadingAdSenseCredentials;
  const isValidatingFacebookCredentialsData = isValidatingAdSenseCredentials;
  const isFacebookCredentialsDataError = Boolean(adSenseCredentialsError);

  return (
    <FacebookCredentialsContext.Provider
      value={{
        adSenseCredentials,
        isLoadingFacebookCredentialsData,
        isValidatingFacebookCredentialsData,
        isFacebookCredentialsDataError,
        mutate,
        handleLogin,
        handleLogout,
        isLoggingIn,
        isLoggingOut,
        adAccountSearchName,
        handleAdAccountSearchName,
        syncAllAdSenseAccounts,
        mutateAdSenseCredentials,
        handleFacebookData,
        handleFacebookProfileData,
      }}
    >
      {children}
    </FacebookCredentialsContext.Provider>
  );
};

export const useFacebookCredentials = (): IFacebookCredentialsProvider => {
  const context = React.useContext(FacebookCredentialsContext);

  if (!context) {
    throw new Error('useFacebookCredentials must be used within FacebookCredentialsProvider');
  }

  return context;
};
