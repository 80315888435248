import styled from 'styled-components/macro';

export const ExampleTextWrapper = styled.div`
  max-width: 132px;
  width: 100%;
`;

export const IntegrationWrapper = styled.div`
  max-width: 300px;
  width: 100%;
  margin-bottom: 8px;
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
  margin-bottom: 28px;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

export const ContentWrapper = styled.div`
  max-width: 632px;
  width: 100%;
`;

export const Wrapper = styled.div`
  padding: 142px 32px;
  max-width: calc(842px + 64px);
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;

  @media only screen and (max-width: 1028px) {
    max-width: calc(620px + 64px);
  }

  @media only screen and (max-width: 702px) {
    padding-top: 48px;
  }

  @media only screen and (max-height: 768px) {
    padding-top: 48px;
  }
`;
