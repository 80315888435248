import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { domainSchema } from '@helpers/validators/components/domainModal';

import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const StoreDomainModal: React.FC<any> = ({ isOpen, toggle, handleVerifyInstallation }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(domainSchema) });

  const [domainValue, setDomainValue] = React.useState<string>('');

  const domainRegister = register('domain');

  const onDomainValueChange = React.useCallback(
    event => {
      domainRegister.onChange(event);

      setDomainValue(event.target.value);
    },
    [domainRegister],
  );

  const onSubmit = React.useCallback(
    data => {
      let parsedDomain = data?.domain;

      const [domain] = parsedDomain.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/');

      parsedDomain = `https://${domain}/?verify_utm_script=true`;

      handleVerifyInstallation(parsedDomain);

      toggle();
    },
    [handleVerifyInstallation, toggle],
  );

  const hasAllInputsValue = domainValue;

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Modal.Header>Domínio da loja</S.Modal.Header>
      <S.Modal.Body>
        <S.Description>
          Informe o domínio de sua loja para realizar a verificação se o script está instalado
          corretamente.
        </S.Description>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>Domínio da Loja</S.Label>
            <S.Input
              {...domainRegister}
              value={domainValue}
              onChange={onDomainValueChange}
              type="text"
              placeholder="minhaloja.com.br"
              isError={Boolean(errors?.domain)}
            />
            {errors.domain && <Text isErrorFeedback>{errors.domain.message}</Text>}
          </S.InputGroup>

          <S.VerifyButton type="submit" disabled={!hasAllInputsValue}>
            Verificar
          </S.VerifyButton>
        </Form>
      </S.Modal.Body>
    </S.Modal>
  );
};

export default StoreDomainModal;
