import React from 'react';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { IDisableIntegrationFooterProps } from '@domain/interfaces/dashboard/IntegrationCenter/IDisableCredentialFooter';

import * as S from './styles';

const DisableIntegrationFooter: React.FC<IDisableIntegrationFooterProps> = ({
  onDeleteCredential,
  isDeletingCredential,
}) => {
  return (
    <S.DisableButtonBackground>
      <S.DisableButtonWrapper>
        <S.DisableIntegrationButton
          onClick={onDeleteCredential}
          disabled={isDeletingCredential}
          isLoading={isDeletingCredential}
          variant={EButtonVariant.SECONDARY}
        >
          Desativar integração
        </S.DisableIntegrationButton>
      </S.DisableButtonWrapper>
    </S.DisableButtonBackground>
  );
};

export default DisableIntegrationFooter;
