import styled from 'styled-components/macro';

import LinkBase from '@components/common/core/Navigation/Link';
import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const AddCategoryIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.background[3]};
`;

export const AddCategory = styled.button`
  background-color: unset;
  border: none;
  cursor: pointer;
  padding: 8px 12px;
  height: fit-content;
  display: flex;
  gap: 8px;
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.font};
  align-items: center;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: all 0.3s;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.green.dark};
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.font};
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 28px;
  align-items: center;
`;

export const GoBackLink = styled(LinkBase)`
  color: ${({ theme }) => theme.colors.font};
  font-size: 1rem;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 4px;

  & > svg:first-child {
    margin-bottom: 2px;
  }
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 28px;
`;
