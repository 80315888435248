import styled, { css } from 'styled-components/macro';

import { IButton } from '@domain/interfaces/components/IButton';
import { EButtonSize, EButtonTextColor, EButtonVariant } from '@domain/enums/components/EButton';

import Text from '@components/common/core/DataDisplay/Text';

export const Container = styled.button<IButton>`
  position: relative;
  height: 42px;
  padding: 12px 22px;
  background-color: ${({ theme }) => theme.colors.green.dark};
  box-shadow: ${({ theme }) => theme.shadows.buttonDefault};
  border: 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.green.default};
    box-shadow: ${({ theme }) => theme.shadows.buttonHover};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.green.dark};
    box-shadow: ${({ theme }) => theme.shadows.buttonPressed};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray[2]};
    box-shadow: none;
    cursor: not-allowed;
  }

  ${({ buttonSize }) =>
    buttonSize === EButtonSize.SMALL &&
    css`
      height: 32px;
      padding: 6px 20px;
    `}

  ${({ buttonSize }) =>
    buttonSize === EButtonSize.MEDIUM &&
    css`
      height: 36px;
      padding: 8px 20px;
    `}

  ${props =>
    props.variant === EButtonVariant.SECONDARY &&
    css`
      background-color: transparent;
      border: 2px solid ${({ theme }) => theme.colors.green.dark};
      box-shadow: unset;

      &:hover {
        background-color: transparent;
        box-shadow: ${({ theme }) => theme.shadows.buttonHover};
        border: 2px solid ${({ theme }) => theme.colors.green.default};
      }

      &:active {
        background-color: transparent;
        box-shadow: ${({ theme }) => theme.shadows.buttonPressed};
        border: 2px solid ${({ theme }) => theme.colors.green.dark};
      }

      &:disabled {
        background-color: transparent;
        border: 2px solid ${({ theme }) => theme.colors.gray[2]};
        box-shadow: none;
      }
    `}

  ${props =>
    props.variant === EButtonVariant.TERTIARY &&
    css`
      background-color: unset;
      border: unset;
      box-shadow: unset;

      &:hover {
        background-color: unset;
        border: unset;
        box-shadow: unset;
      }
    `}

    ${props =>
    props.variant === EButtonVariant.DANGER &&
    css`
      background-color: transparent;
      border: 2px solid ${({ theme }) => theme.colors.danger.light};
      box-shadow: unset;

      &:hover {
        background-color: transparent;
        border: 2px solid ${({ theme }) => theme.colors.danger.light};
        box-shadow: unset;
      }

      &:active {
        background-color: transparent;
        border: 2px solid ${({ theme }) => theme.colors.danger.light};
        box-shadow: unset;
      }

      &:disabled {
        background-color: transparent;
        border: 2px solid ${({ theme }) => theme.colors.gray[2]};
        box-shadow: unset;
      }
    `}

    ${({ variant }) =>
    variant === EButtonVariant.SECONDARY_BUTTON &&
    css`
      background-color: ${({ theme }) => theme.colors.background[2]};
      box-shadow: unset;
      border: unset;

      &:hover {
        background-color: ${({ theme }) => theme.colors.background[2]};
        box-shadow: unset;
        filter: brightness(1.1);
      }

      &:active {
        background-color: ${({ theme }) => theme.colors.background[2]};
        box-shadow: unset;
        filter: brightness(0.9);
      }

      &:disabled {
        background-color: ${({ theme }) => theme.colors.gray[2]};
        box-shadow: none;
        cursor: not-allowed;
      }
    `}
`;

export const ButtonText = styled(Text)<IButton>`
  color: ${({ theme }) => theme.colors.gray[7]};
  transition: all 0.3s;

  ${({ textColor }) =>
    textColor === EButtonTextColor.DARK &&
    css`
      color: ${({ theme }) => theme.colors.gray[7]};
    `}

  ${({ iconPrepend }) =>
    iconPrepend &&
    css`
      margin-left: 8px;
    `}

  ${({ iconAppend }) =>
    iconAppend &&
    css`
      margin-right: 8px;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.gray[7]};
    `}

    ${({ disabled, textColor }) =>
    disabled &&
    textColor === EButtonTextColor.DARK &&
    css`
      color: ${({ theme }) => theme.colors.gray[7]};
    `}

  ${({ buttonSize }) =>
    buttonSize === EButtonSize.SMALL &&
    css`
      font-size: 0.875rem;
    `}

    ${({ buttonSize }) =>
    buttonSize === EButtonSize.MEDIUM &&
    css`
      font-size: 0.875rem;
    `}

  ${({ variant }) =>
    variant === EButtonVariant.SECONDARY &&
    css`
      color: ${({ theme }) => theme.colors.green.dark};

      &:hover {
        color: ${({ theme }) => theme.colors.green.default};
      }

      &:active {
        color: ${({ theme }) => theme.colors.green.dark};
      }
    `}

  ${({ variant, disabled }) =>
    variant === EButtonVariant.SECONDARY &&
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.gray[2]};
    `}

    ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0;
    `}

    ${({ variant }) =>
    variant === EButtonVariant.TERTIARY &&
    css`
      color: ${({ theme }) => theme.colors.green.default};

      &:hover {
        color: ${({ theme }) => theme.colors.green.light};
      }

      &:active {
        color: ${({ theme }) => theme.colors.green.dark};
      }
    `}

  ${({ variant, disabled }) =>
    variant === EButtonVariant.TERTIARY &&
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.gray[2]};
    `}

    ${({ variant }) =>
    variant === EButtonVariant.DANGER &&
    css`
      color: ${({ theme }) => theme.colors.danger.light};

      &:hover {
        color: ${({ theme }) => theme.colors.danger.light};
      }

      &:active {
        color: ${({ theme }) => theme.colors.danger.light};
      }
    `}

    ${({ variant, theme }) =>
    variant === EButtonVariant.SECONDARY_BUTTON &&
    css`
      color: ${theme.colors.font};
    `}

    ${({ variant, disabled, theme }) =>
    variant === EButtonVariant.SECONDARY_BUTTON &&
    disabled &&
    css`
      color: ${theme.colors.gray[7]};
    `}

    ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0;
    `}
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
