import { ISelectedStoresChartDataResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getFinancialChartMultiStoresData = (
  currentSelectedStoreChartData: ISelectedStoresChartDataResponse,
  selectedStoresChartDataResponse: ISelectedStoresChartDataResponse[],
): ISelectedStoresChartDataResponse => {
  const shippingDeliveredPercentages = selectedStoresChartDataResponse?.map(
    chartResponse => chartResponse.shipping_delivered?.percentage,
  );

  const shippingPendingAmountValues = selectedStoresChartDataResponse?.map(
    chartResponse => chartResponse.shipping_pending?.amount,
  );

  const shippingPendingQuantityValues = selectedStoresChartDataResponse?.map(
    chartResponse => chartResponse.shipping_pending?.quantity,
  );

  const grossRevenueAmountValues = selectedStoresChartDataResponse?.map(
    chartResponse => chartResponse.gross_revenue_amount,
  );

  const netRevenueAmountValues = selectedStoresChartDataResponse?.map(
    chartResponse => chartResponse.net_revenue_amount_by_approved_at,
  );

  const mergedGrossRevenueAmountValues = grossRevenueAmountValues
    ? [currentSelectedStoreChartData?.gross_revenue_amount, ...grossRevenueAmountValues]
    : [currentSelectedStoreChartData?.gross_revenue_amount];

  const mergedNetRevenueAmountValues = netRevenueAmountValues
    ? [currentSelectedStoreChartData?.net_revenue_amount_by_approved_at, ...netRevenueAmountValues]
    : [currentSelectedStoreChartData?.net_revenue_amount_by_approved_at];

  const mergedShippingDeliveredPercentages = shippingDeliveredPercentages
    ? [
        currentSelectedStoreChartData?.shipping_delivered?.percentage,
        ...shippingDeliveredPercentages,
      ]
    : [currentSelectedStoreChartData?.shipping_delivered?.percentage];

  const mergedShippingPendingAmountValues = shippingPendingAmountValues
    ? [currentSelectedStoreChartData?.shipping_pending?.amount, ...shippingPendingAmountValues]
    : [currentSelectedStoreChartData?.shipping_pending?.amount];

  const mergedShippingPendingQuantityValues = shippingPendingQuantityValues
    ? [currentSelectedStoreChartData?.shipping_pending?.quantity, ...shippingPendingQuantityValues]
    : [currentSelectedStoreChartData?.shipping_pending?.quantity];

  const reducedGrossRevenueAmountValues = mergedGrossRevenueAmountValues?.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedNetRevenueAmountValues = mergedNetRevenueAmountValues?.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedShippingDeliveredPercentages = mergedShippingDeliveredPercentages?.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedShippingPendingAmountValues = mergedShippingPendingAmountValues?.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedShippingPendingQuantityValues = mergedShippingPendingQuantityValues?.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const chartResponseDataWithMultiStoreData = {
    ...currentSelectedStoreChartData,
    shipping_delivered: {
      percentage: reducedShippingDeliveredPercentages,
    },
    shipping_pending: {
      amount: reducedShippingPendingAmountValues,
      quantity: reducedShippingPendingQuantityValues,
    },
    gross_revenue_amount: reducedGrossRevenueAmountValues,
    net_revenue_amount_by_approved_at: reducedNetRevenueAmountValues,
  };

  return chartResponseDataWithMultiStoreData;
};
