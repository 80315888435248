import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 32px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  margin-top: 16px;
  cursor: pointer;
  pointer-events: all;
`;

export const StepWrapper = styled.div`
  width: 100%;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

export const HelpCenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  pointer-events: all;
  margin-left: 20px;
  margin-right: auto;
`;

export const HelpDescription = styled(TextBase)`
  font-weight: 400;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  border-radius: 7px;
  width: 36px;
  height: 36px;
`;

export const HelpText = styled(TextBase)`
  font-weight: 500;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.gray[1]};
`;
