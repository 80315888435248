import React from 'react';

import { EModalStep } from '@domain/enums/common/EStepsModal';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ITestSideModalProps } from '@domain/interfaces/dashboard/Automations/TestSideModal/ITestSideModal';
import { useAutomationsManager } from '@helpers/hooks/pages/dashboard/automations/useAutomationsManager';
import { PaperPlaneTilt } from 'phosphor-react';
import { useTheme } from 'styled-components';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import * as S from './styles';

const TestSideModal: React.FC<ITestSideModalProps> = ({ isOpen, toggle }) => {
  const theme = useTheme();

  const { handleTestAutomationStep, testAutomationStep } = useAutomationsManager();

  return (
    <S.Sidemodal isOpen={isOpen} toggle={toggle}>
      <S.Header>
        <PaperPlaneTilt size={28} color={theme.colors.green.default} />
        <S.Title fontWeight={EHeadingWeight.REGULAR} type={EHeadingSize.H4}>
          Testar automação
        </S.Title>
      </S.Header>

      <S.InformationText>
        Salve as modificações atuais para poder realizar o teste da automação. Alterações que não
        foram salvas não irão funcionar no teste.
      </S.InformationText>

      {testAutomationStep === EModalStep.FIRST_STEP && (
        <FirstStep handleTestAutomationStep={handleTestAutomationStep} />
      )}

      {testAutomationStep === EModalStep.SECOND_STEP && (
        <SecondStep handleTestAutomationStep={handleTestAutomationStep} />
      )}

      {testAutomationStep === EModalStep.THIRD_STEP && <ThirdStep />}
    </S.Sidemodal>
  );
};

export default TestSideModal;
