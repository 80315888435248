import LabelBase from '@components/common/core/DataDisplay/Label';
import TextBase from '@components/common/core/DataDisplay/Text';
import FormBase from '@components/common/core/Inputs/Form';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import PhoneInputBase from '@components/common/core/Utils/PhoneInput';
import styled, { css } from 'styled-components/macro';

export const TitleForm = styled(TextBase)`
  line-height: 120%;
  color: ${({ theme }) => theme.colors.font};
  display: inline-block;
  font-size: 1.25rem;
  margin-bottom: 16px;
  margin: 24px 0px;
`;

export const Form = styled(FormBase)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InputGroup = styled(InputGroupBase)``;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)`
  width: 100%;
`;

export const PhoneInput = styled(PhoneInputBase)`
  border-radius: 5px;
  height: 42px;
  border: 1px solid transparent;
  transition: all 0.3s;

  ${({ isError, theme, value }) =>
    Boolean(value) &&
    Boolean(!isError) &&
    css`
      background-color: ${theme.colors.black.default};
      border-color: ${theme.colors.success.default};
    `}

  ${({ isError, theme }) =>
    Boolean(isError) &&
    css`
      border-color: ${theme.colors.danger.default};
      background-color: ${theme.colors.black.default};
    `}

  .form-control {
    height: 42px;
    background-color: ${({ theme }) => theme.colors.background[2]};
    border: 0;
    width: 100%;
    color: ${({ theme }) => theme.colors.gray[1]};
    font-family: ${({ theme }) => theme.fonts.body};
    font-weight: 500;
    font-size: 1rem;
    transition: all 0.3s;

    ${({ isError, theme, value }) =>
      Boolean(value) &&
      Boolean(!isError) &&
      css`
        background-color: ${theme.colors.black.default};
      `}

    ${({ isError, theme }) =>
      Boolean(isError) &&
      css`
        background-color: ${theme.colors.black.default};
      `}
  }

  .flag-dropdown {
    border: 0;
    background-color: ${({ theme }) => theme.colors.background[2]};
    border-radius: 5px;
    transition: all 0.3s;

    ${({ isError, theme, value }) =>
      Boolean(value) &&
      Boolean(!isError) &&
      css`
        background-color: ${theme.colors.black.default};
      `}

    ${({ isError, theme }) =>
      Boolean(isError) &&
      css`
        background-color: ${theme.colors.black.default};
      `}

    .selected-flag {
      background-color: ${({ theme }) => theme.colors.background[2]};
      border: 0;
      border-radius: 5px;
      transition: all 0.3s;

      ${({ isError, theme, value }) =>
        Boolean(value) &&
        Boolean(!isError) &&
        css`
          background-color: ${theme.colors.black.default};
        `}

      ${({ isError, theme }) =>
        Boolean(isError) &&
        css`
          background-color: ${theme.colors.black.default};
        `}

      &:hover {
        background-color: transparent;
      }

      &:active {
        background-color: ${({ theme }) => theme.colors.background[2]};
      }
    }

    .open {
      background-color: ${({ theme }) => theme.colors.background[2]} !important;

      ${({ isError, theme, value }) =>
        Boolean(value) &&
        Boolean(!isError) &&
        css`
          background-color: ${theme.colors.black.default} !important;
        `}

      ${({ isError, theme }) =>
        Boolean(isError) &&
        css`
          background-color: ${theme.colors.black.default} !important;
        `}
    }
  }

  .country-list {
    background-color: ${({ theme }) => theme.colors.background[2]};

    .country {
      span {
        color: ${({ theme }) => theme.colors.gray[1]};
        font-family: ${({ theme }) => theme.fonts.body};
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[3]};
      }
    }

    .highlight {
      background-color: ${({ theme }) => theme.colors.gray[3]} !important;
    }
  }
`;
