import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import ButtonBase from '@components/common/core/Inputs/Button';

export const SendButton = styled(ButtonBase)``;

export const CancelButton = styled.button`
  padding: unset;
  background: unset;
  border: unset;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.gray[2]};
  font-size: 0.875rem;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  justify-content: flex-end;
`;

export const Input = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 32px;
`;

export const Description = styled(TextBase)`
  line-height: 150%;
  font-size: 0.875rem;
  display: inline-block;
  margin-bottom: 32px;
`;

export const Modal = styled(ModalBase)`
  & > div:first-child {
    & > span {
      gap: 12px;
      font-size: 1.125rem;
      font-weight: 500;
    }
  }

  & > div:last-child {
    max-width: 484px;
  }
`;
