import React from 'react';
import { useTheme } from 'styled-components/macro';
import { CircleSpinner } from 'react-spinners-kit';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';

import { useScriptAndUtms } from '@helpers/hooks/pages/dashboard/adsManager/onboarding/useScriptAndUtms';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import * as S from './styles';

const Processing: React.FC = () => {
  const theme = useTheme();
  const { handleNextStep } = useScriptAndUtms();
  const { finishedSynchronizations } = useSynchronization();

  React.useEffect(() => {
    if (finishedSynchronizations.length) {
      const foundSynchronization = finishedSynchronizations.find(
        synchronization => synchronization.type === ESynchronizationType.ADS_WITHOUT_UTMS,
      );

      if (foundSynchronization) {
        handleNextStep();
      }
    }
  }, [finishedSynchronizations, handleNextStep]);

  return (
    <S.Wrapper>
      <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
        Estamos adicionando UTMs. Isso pode levar alguns minutos...
      </S.Title>
      <S.StatusWrapper>
        <CircleSpinner size={24} color={theme.colors.green.default} />
        {/* <S.StatusText>
          Foram sincronizados <strong>1</strong>/200 campanhas
        </S.StatusText> */}
      </S.StatusWrapper>
    </S.Wrapper>
  );
};

export default Processing;
