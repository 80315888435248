import React from 'react';

import EmptyData from '@components/common/core/DataDisplay/EmptyData';

import * as S from './styles';

const NoVariants: React.FC = () => {
  return (
    <S.Wrapper>
      <EmptyData content="Não encontramos nenhuma variante." />
    </S.Wrapper>
  );
};

export default NoVariants;
