import styled, { keyframes, css } from 'styled-components/macro';

import { IProfileButtonProps } from '@domain/interfaces/onboarding/components/facebook/table';

import TableBase from '@components/common/core/Utils/Table';
import AvatarBase from '@components/common/core/DataDisplay/Avatar';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import CustomPopover from '@components/common/core/Utils/CustomPopover';
import TextBase from '@components/common/core/DataDisplay/Text';
import BadgeBase from '@components/common/core/DataDisplay/Badge';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';

const appearEffect = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const TooltipText = styled(TextBase)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const TooltipContent = styled.div`
  max-width: 242px;
  width: 100%;
`;

export const Tooltip = styled(CustomTooltipBase)``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1016px;
  max-height: 445px;
  overflow: hidden;
  border-radius: 15px;

  @media only screen and (max-width: 1365px) {
    max-height: 405px;
  }
`;

export const FacebookProfileButton = styled.button<IProfileButtonProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 71px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border: unset;
  padding: 12px 30px 12px 16px;
  border-radius: 15px;
  transition: filter 0.3s;

  @media only screen and (max-width: 455px) {
    padding: 12px;
  }

  ${({ showTableRows }) =>
    showTableRows &&
    css`
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray[4]};
    `}

  &:hover {
    cursor: pointer;
    filter: brightness(1.3);
  }
`;

export const Table = styled(TableBase)`
  width: 100%;
  max-height: 374px;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  overflow-y: scroll;
  overflow-x: hidden;

  @media only screen and (max-width: 1365px) {
    max-height: 334px;
  }

  @media only screen and (max-width: 1190px) {
    overflow-x: scroll;
  }

  ::-webkit-scrollbar {
    width: 4px;
    position: absolute;
  }

  ::-webkit-scrollbar:horizontal {
    height: 4px;
    width: 4px;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.gray[4]};
    position: absolute;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[1]};
    border-radius: 25px;
    transition: all 0.3s;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray[2]};
    border-radius: 25px;
    transition: all 0.3s;
  }

  ::-webkit-scrollbar-thumb:hover {
    opacity: brightness(0.8);
  }

  & > div > div > div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[5]};
  }

  tr {
    &:hover {
      background-color: ${({ theme }) => theme.colors.black.light};
    }

    & > td:first-child {
      width: 76px;
    }
  }
`;

export const TableBody = styled(TableBase.Body)`
  width: 100%;
  min-height: 374px;
  animation: ${appearEffect} 300ms;
`;

export const Avatar = styled(AvatarBase)`
  h5 {
    color: ${({ theme }) => theme.colors.green.default};
  }
`;

export const AvatarAndUserInfosWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const UserInfos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const UserName = styled(HeadingBase)``;

export const ConnectedTimeText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
  font-size: 0.75rem;
  text-align: left;
`;

export const BadgeAndMoreOptionsWrapper = styled.div`
  display: flex;
  gap: 18px;

  @media only screen and (max-width: 455px) {
    gap: 8px;
  }
`;

export const Badge = styled(BadgeBase)``;

export const MoreOptionsWrapper = styled.button`
  background-color: ${({ theme }) => theme.colors.gray[5]};
  border-radius: 5px;
  border: unset;
  padding: unset;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  transition: filter 0.2s;

  &:hover {
    cursor: pointer;
    filter: brightness(0.8);
  }
`;

export const MoreOptionsPopover = styled(CustomPopover)``;

export const UnlinkAccountButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.danger.default};
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
`;

export const Bullet = styled.div`
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white.default};
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
