import React from 'react';

import { ResponsiveContainer, BarChart as Chart, Bar, XAxis, YAxis } from 'recharts';
import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';

import * as S from './styles';

const BarChart: React.FC = () => {
  const { financialDetailsReport } = useFinancialDashboard();

  const {
    boleto_percentage,
    pix_percentage,
    card_percentage,
  } = financialDetailsReport.payment_method;

  const data = [
    {
      boleto: boleto_percentage,
      credit_card: card_percentage,
      pix: pix_percentage,
    },
  ];

  return (
    <S.Wrapper>
      <ResponsiveContainer width="99%">
        <Chart data={data} layout="vertical">
          <XAxis type="number" hide />
          <YAxis type="category" hide />
          <Bar dataKey="boleto" fill="#5B8DEF" stackId="payment-methods" />
          <Bar dataKey="credit_card" fill="#50E170" stackId="payment-methods" />
          <Bar dataKey="pix" fill="#B78AFF" stackId="payment-methods" />
        </Chart>
      </ResponsiveContainer>
    </S.Wrapper>
  );
};

export default BarChart;
