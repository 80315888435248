import React from 'react';
import { Kiwify as KiwifyIcon, Copy } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { EInfoProductProvider } from '@domain/enums/common/integrations/EInfoProductProvider';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { kiwifySchema } from '@helpers/validators/dashboard/integrationCenter/infoProduct/kiwify';

import kiwifyService from '@services/pages/dashboard/integrationCenter/infoProduct/platforms/kiwifyAuth';

import ActiveIntegrationFooter from '@components/Dashboard/IntegrationCenter/Sidemodal/ActiveIntegrationFooter';
import Text from '@components/common/core/DataDisplay/Text';
import { EKiwifyType } from '@domain/enums/common/integrations/providers/EKiwify';
import KiwifyConfig from './KiwifyConfig';
import SkeletonLoading from './SkeletonLoading';
import ActiveIntegration from './ActiveIntegration';

import * as S from './styles';

const Kiwify: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const theme = useTheme();

  const { storeIntegrations, mutateStore } = useStoreConfig();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(kiwifySchema),
  });

  const [webhookURL, setWebhookURL] = React.useState<string>('');
  const [secretKey, setSecretKey] = React.useState<string>('');
  const [accessTokenValue, setAccessTokenValue] = React.useState<string>('');
  const [credentialAliasId, setCredentialAliasId] = React.useState<string>('');
  const [isURLCopied, setIsURLCopied] = React.useState<boolean>(false);
  const [hasKiwifyCredential, setHasKiwifyCredential] = React.useState<boolean>(false);
  const [isGettingWebhookURL, setIsGettingWebhookURL] = React.useState<boolean>(false);
  const [isGettingCredential, setIsGettingCredential] = React.useState<boolean>(false);
  const [isCreatingCredential, setIsCreatingCredential] = React.useState<boolean>(false);

  const hasKiwifyIntegration = Boolean(
    storeIntegrations?.store_front.find(
      integration => integration.name === EInfoProductProvider.KIWIFY && integration.is_active,
    ),
  );

  const accessTokenInputRegister = register('access_token');

  const onAccessTokenChange = React.useCallback(
    event => {
      accessTokenInputRegister.onChange(event);

      setAccessTokenValue(event.target.value);
    },
    [accessTokenInputRegister],
  );

  const handleCopyURL = React.useCallback(() => {
    navigator.clipboard.writeText(webhookURL);
    setIsURLCopied(true);
    toast.success('URL copiada com sucesso.');
  }, [toast, webhookURL]);

  const onSubmit = React.useCallback(
    async formData => {
      setIsCreatingCredential(true);

      const data = {
        webhook_token: formData.access_token,
        secret_key: secretKey,
        types: [EKiwifyType.STORE],
      };

      try {
        await kiwifyService.createCredential({ storeAliasId, data });

        toast.success('Credencial criada com sucesso.');

        mutateStore();

        setIsCreatingCredential(false);
      } catch (error: any) {
        toast.error(getTranslatedIntegrationsError(error));
        setIsCreatingCredential(false);
      }
    },
    [secretKey, mutateStore, storeAliasId, toast],
  );

  const getKiwifyCredential = React.useCallback(async () => {
    setIsGettingCredential(true);

    try {
      const { data } = await kiwifyService.getCredential({ storeAliasId });

      if (data?.kiwify_credential.is_active) {
        setHasKiwifyCredential(true);
      }

      setCredentialAliasId(data?.kiwify_credential.alias_id);

      setIsGettingCredential(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsGettingCredential(false);
    } finally {
      setIsGettingCredential(false);
    }
  }, [toast, storeAliasId]);

  const generateWebhookURL = React.useCallback(async () => {
    setIsGettingWebhookURL(true);

    try {
      const { data } = await kiwifyService.generateWebhookURL({ storeAliasId });

      setWebhookURL(data?.webhook_url);
      setSecretKey(data?.secret_key);
      setIsGettingWebhookURL(false);
    } catch (error: any) {
      setIsGettingWebhookURL(false);
      toast.error(error?.response?.data?.message);
    }
  }, [toast, storeAliasId]);

  React.useEffect(() => {
    if (hasKiwifyIntegration) {
      getKiwifyCredential();
    } else {
      generateWebhookURL();
    }
  }, [getKiwifyCredential, generateWebhookURL, hasKiwifyIntegration]);

  if (isGettingWebhookURL || isGettingCredential) {
    return <SkeletonLoading />;
  }

  if (hasKiwifyIntegration && !hasKiwifyCredential) {
    return <ActiveIntegration credentialAliasId={credentialAliasId} />;
  }

  if (hasKiwifyCredential) {
    return <KiwifyConfig credentialAliasId={credentialAliasId} />;
  }

  return (
    <S.ContentWrapper>
      <S.HeadingWrapper>
        <KiwifyIcon size={52} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com Kiwify
        </S.Heading>
      </S.HeadingWrapper>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>
        <S.FunctionalityDescriptionText>
          A integração com sua plataforma de info-produto serve para puxarmos seus dados de
          plataforma aumentando a exatidão dos seus dados.
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <S.TutorialWrapper>
        <S.TutotialTitle weight={ETextWeight.BOLD}>Tutorial de integração</S.TutotialTitle>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          1 - Copie a URL no campo abaixo.
        </S.TutotialStep>

        <S.WebhookURLWrapper onClick={handleCopyURL} type="button">
          <S.WebhookURL weight={ETextWeight.MEDIUM}>{webhookURL}</S.WebhookURL>
          <S.IconWrapper>
            <Copy size={18} color={theme.colors.gray[1]} outline={!isURLCopied} />
          </S.IconWrapper>
        </S.WebhookURLWrapper>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          2 -{' '}
          <S.Link href="https://dashboard.kiwify.com.br/apps/webhooks/integrations" target="_blank">
            Clique aqui e vá para Kiwify.
          </S.Link>
        </S.TutotialStep>

        <S.StepsWrapper>
          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>Clique em &quot;Criar webhook&quot;</S.StepText>
          </S.StepWrapper>
          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>Cole no campo &quot;URL do Webhook&quot;</S.StepText>
          </S.StepWrapper>
        </S.StepsWrapper>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          3 - Após colar, copie o token do campo “Token” na Kiwify e cole no campo “Token” da
          Profitfy.
        </S.TutotialStep>
      </S.TutorialWrapper>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label>Token</S.Label>

          <S.Input
            {...accessTokenInputRegister}
            type="text"
            value={accessTokenValue}
            placeholder="Kiwify Token"
            onChange={onAccessTokenChange}
            isError={errors?.access_token}
          />
          {errors.access_token && <Text isErrorFeedback>{errors.access_token.message}</Text>}
        </S.InputGroup>

        <ActiveIntegrationFooter
          isIntegrating={isCreatingCredential}
          disabled={!accessTokenValue || isCreatingCredential}
        />
      </S.Form>
    </S.ContentWrapper>
  );
};

export default Kiwify;
