import React from 'react';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import legalPersonService from '@services/common/legalPerson/legalPerson';
import naturalPersonService from '@services/common/naturalPerson/naturalPerson';
import {
  ICreateLegalPersonData,
  ILegalPersonPersonalInfoProvider,
  IUpdateLegalPersonData,
} from '@domain/interfaces/common/legalPerson/ILegalPerson';
import {
  ICreateNaturalPersonData,
  INaturalPersonPersonalInfoProvider,
  IUpdateNaturalPersonData,
} from '@domain/interfaces/common/naturalPerson/INaturalPerson';

const PersonalInfoContext = React.createContext<
  (ILegalPersonPersonalInfoProvider & INaturalPersonPersonalInfoProvider) | null
>(null);

export const PersonalInfoProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();

  const {
    legalPerson,
    isLoading: isLoadingLegalPerson,
    isValidating: isValidatingLegalPerson,
    mutate: mutateLegalPerson,
    error: legalPersonError,
  } = legalPersonService.getLegalPerson({ storeAliasId });
  const {
    naturalPerson,
    isLoading: isLoadingNaturalPerson,
    isValidating: isValidatingNaturalPerson,
    mutate: mutateNaturalPerson,
    error: naturalPersonError,
  } = naturalPersonService.getNaturalPerson({ storeAliasId });

  const createLegalPerson = React.useCallback(
    async (legalPersonData: ICreateLegalPersonData) => {
      await legalPersonService.createLegalPerson({ storeAliasId, data: legalPersonData });

      await mutateLegalPerson();
    },
    [mutateLegalPerson, storeAliasId],
  );

  const createNaturalPerson = React.useCallback(
    async (naturalPersonData: ICreateNaturalPersonData) => {
      await naturalPersonService.createNaturalPerson({ storeAliasId, data: naturalPersonData });

      await mutateNaturalPerson();
    },
    [mutateNaturalPerson, storeAliasId],
  );

  const updateLegalPerson = React.useCallback(
    async (legalPersonData: IUpdateLegalPersonData) => {
      await legalPersonService.updateLegalPerson({ storeAliasId, data: legalPersonData });

      await mutateLegalPerson();
    },
    [mutateLegalPerson, storeAliasId],
  );

  const updateNaturalPerson = React.useCallback(
    async (naturalPersonData: IUpdateNaturalPersonData) => {
      await naturalPersonService.updateNaturalPerson({ storeAliasId, data: naturalPersonData });

      await mutateNaturalPerson();
    },
    [mutateNaturalPerson, storeAliasId],
  );

  const deleteLegalPerson = React.useCallback(async () => {
    await legalPersonService.deleteLegalPerson({ storeAliasId });

    await mutateLegalPerson();
  }, [mutateLegalPerson, storeAliasId]);

  const deleteNaturalPerson = React.useCallback(async () => {
    await naturalPersonService.deleteNaturalPerson({ storeAliasId });

    await mutateNaturalPerson();
  }, [mutateNaturalPerson, storeAliasId]);

  return (
    <PersonalInfoContext.Provider
      value={{
        createLegalPerson,
        createNaturalPerson,
        deleteLegalPerson,
        deleteNaturalPerson,
        isLoadingLegalPerson,
        isLoadingNaturalPerson,
        isValidatingLegalPerson,
        isValidatingNaturalPerson,
        legalPerson,
        legalPersonError,
        mutateLegalPerson,
        mutateNaturalPerson,
        naturalPerson,
        naturalPersonError,
        updateLegalPerson,
        updateNaturalPerson,
      }}
    >
      {children}
    </PersonalInfoContext.Provider>
  );
};

export const usePersonalInfo = (): ILegalPersonPersonalInfoProvider &
  INaturalPersonPersonalInfoProvider => {
  const context = React.useContext(PersonalInfoContext);

  if (!context) {
    throw new Error('usePersonalInfo must be used within PersonalInfoProvider');
  }

  return context;
};
