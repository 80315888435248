/* eslint-disable no-underscore-dangle */
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
// import { useTheme } from 'styled-components/macro';
// import { GearSix } from 'phosphor-react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';

import { useNotification } from '@helpers/hooks/common/store/notification/useNotification';

import NotificationRow from './NotificationRow';
import FirstLoader from './FirstLoader';
import MoreContentLoader from './MoreContentLoader';

import * as S from './styles';

const Content: React.FC = () => {
  // const theme = useTheme();

  const {
    loadNotifications,
    updateFeedPage,
    notifications,
    hasMoreFeedPage,
    isLoadingNotifications,
    markAllNotificationsAsRead,
  } = useNotification();

  React.useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  const filteredNotifications = notifications.filter(
    notification => notification.payload?.title !== 'any_title',
  );

  return (
    <S.Wrapper>
      <S.Header>
        <S.TitleAndReadAllWrapper>
          <S.Title type={EHeadingSize.H3} fontWeight={EHeadingWeight.MEDIUM}>
            Notificações
          </S.Title>
          <S.ReadAllButton onClick={markAllNotificationsAsRead}>
            Marcar todas como lidas
          </S.ReadAllButton>
        </S.TitleAndReadAllWrapper>

        <S.TabsAndConfigurationsWrapper>
          <S.MainTab>Principal</S.MainTab>
          {/* <S.ConfigurationsButton>
            <GearSix size={20} color={theme.colors.gray[2]} />
          </S.ConfigurationsButton> */}
        </S.TabsAndConfigurationsWrapper>
      </S.Header>

      <S.NotificationsWrapper id="notifications-feed">
        <InfiniteScroll
          dataLength={filteredNotifications.length}
          next={updateFeedPage}
          hasMore={hasMoreFeedPage}
          loader={<MoreContentLoader />}
          scrollableTarget="notifications-feed"
          scrollThreshold={0.95}
        >
          {!filteredNotifications.length && isLoadingNotifications && <FirstLoader />}

          {filteredNotifications.map(notification => (
            <NotificationRow notification={notification} key={notification._id} />
          ))}

          {!filteredNotifications.length && !isLoadingNotifications && (
            <S.NoNotificationsWrapper>
              <S.NoNotificationsText>Não há nenhuma notificação no momento.</S.NoNotificationsText>
            </S.NoNotificationsWrapper>
          )}
        </InfiniteScroll>
      </S.NotificationsWrapper>
    </S.Wrapper>
  );
};

export default Content;
