import React from 'react';
import { useTheme } from 'styled-components/macro';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { IPixConversion } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IAdSenseCampaign';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import { numberFormatter } from '@helpers/masks';

import Chart from './Chart';
import BlurredContent from './BlurredContent';

import * as S from './styles';

const PixConversion: React.FC<ICampaignProps> = ({ data, rowData }) => {
  const pixConversionData: IPixConversion = data;

  const { store } = useStoreConfig();
  const { isDemoEnvironment } = useConfig();
  const theme = useTheme();

  const chartData = [
    {
      name: 'Conversão',
      value: pixConversionData.conversion,
    },
    {
      name: 'Total',
      value: 100 - pixConversionData.conversion,
    },
  ];

  const colors = ['#B78AFF', theme.colors.gray[4]];

  const isContentBlurred =
    (!store.is_utm_onboarding_finished || !rowData.has_at_least_one_ads_with_utm_synchronized) &&
    !isDemoEnvironment &&
    !rowData.has_all_ads_with_utm_synchronized;

  return (
    <S.Wrapper>
      {isContentBlurred ? (
        <BlurredContent />
      ) : (
        <>
          <S.ChartWrapper>
            <Chart data={chartData} colors={colors} />
            <S.ConversionPercentage>{`${numberFormatter(
              pixConversionData.conversion,
              0,
              true,
            )}%`}</S.ConversionPercentage>
          </S.ChartWrapper>
          <S.TextContentWrapper>
            <S.ContentWrapper>
              <S.LabelText>Pagos</S.LabelText>
              <S.AmountText>
                {`R$ ${numberFormatter(pixConversionData.paid.amount, 2)} (${
                  pixConversionData.paid.quantity
                })`}
              </S.AmountText>
            </S.ContentWrapper>

            <S.ContentWrapper>
              <S.LabelText>Pendentes</S.LabelText>
              <S.AmountText>
                {`R$ ${numberFormatter(pixConversionData.pending.amount, 2)} (${
                  pixConversionData.pending.quantity
                })`}
              </S.AmountText>
            </S.ContentWrapper>
          </S.TextContentWrapper>
        </>
      )}
    </S.Wrapper>
  );
};

export default PixConversion;
