import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import TextBase from '@components/common/core/DataDisplay/Text';

export const DataText = styled(TextBase)`
  font-size: 0.875rem;
`;

export const Table = styled(TableBase)``;
