import { AxiosResponse } from 'axios';

import { ApiService } from '@services/api';

import { useFetch } from '@providers/Fetch/useFetch';

import { API_DOMAIN } from '@constants/api';

import {
  ICreateLojaIntegradaCredentialProps,
  IDeleteLojaIntegradaCredentialProps,
  IGetLojaIntegradaCredentialPromiseProps,
  IGetLojaIntegradaCredentialPromiseResponse,
  IGetLojaIntegradaCredentialProps,
  IGetLojaIntegradaCredentialResponse,
  IUpdateLojaIntegradaCredentialProps,
} from '@domain/interfaces/dashboard/IntegrationCenter/Ecommerce/LojaIntegrada/ILojaIntegradaAuth';

export class LojaIntegradaAuthService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getLojaIntegradaCredential({
    storeAliasId,
  }: IGetLojaIntegradaCredentialProps): IGetLojaIntegradaCredentialResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/loja-integrada-credentials`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      lojaIntegradaCredential: data?.loja_integrada_credential,
      isLoading,
      isValidating,
      error,
      mutate,
    };
  }

  public getLojaIntegradaCredentialPromise({
    storeAliasId,
  }: IGetLojaIntegradaCredentialPromiseProps): Promise<
    AxiosResponse<IGetLojaIntegradaCredentialPromiseResponse>
  > {
    return this.apiService.get<IGetLojaIntegradaCredentialPromiseResponse>(
      `/api/v1/users/stores/${storeAliasId}/loja-integrada-credentials`,
    );
  }

  public createLojaIntegradaCredential({
    storeAliasId,
    payload,
  }: ICreateLojaIntegradaCredentialProps): Promise<AxiosResponse<void>> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/loja-integrada-credentials`, {
      ...payload,
    });
  }

  public updateLojaIntegradaCredential({
    storeAliasId,
    payload,
  }: IUpdateLojaIntegradaCredentialProps): Promise<AxiosResponse<void>> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/loja-integrada-credentials`, {
      ...payload,
    });
  }

  public deleteLojaIntegradaCredential({
    storeAliasId,
  }: IDeleteLojaIntegradaCredentialProps): Promise<AxiosResponse<void>> {
    return this.apiService.delete(`/api/v1/users/store/${storeAliasId}/loja-integrada-credentials`);
  }
}

const lojaIntegradaAuthService = new LojaIntegradaAuthService(API_DOMAIN);

export default lojaIntegradaAuthService;
