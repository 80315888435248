import React from 'react';

import { EHeadAlign } from '@domain/enums/components/ETable';
import Paginate from '@components/common/core/Utils/Paginate';
import { useBoleto } from '@helpers/hooks/pages/dashboard/boleto/useBoleto';
import EmptyData from '@components/common/core/DataDisplay/EmptyData';
import TableRow from '../TableRow';

import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Table: React.FC = () => {
  const {
    orders,
    boletosPage,
    boletosPageCount,
    onBoletoPageChange,
    isLoadingBoletoData,
    isValidatingBoletoData,
  } = useBoleto();

  if (isLoadingBoletoData || isValidatingBoletoData) {
    return <SkeletonLoading />;
  }

  return (
    <S.BoletoTable>
      <S.BoletoTable.Header>
        <S.BoletoTable.Row>
          <S.BoletoTable.Head>N. Do Pedido</S.BoletoTable.Head>
          <S.BoletoTable.Head align={EHeadAlign.RIGHT}>Aprovado em</S.BoletoTable.Head>
          <S.BoletoTable.Head>Produto</S.BoletoTable.Head>
          <S.BoletoTable.Head align={EHeadAlign.RIGHT}>Receita líquida</S.BoletoTable.Head>
          <S.BoletoTable.Head align={EHeadAlign.RIGHT}>Custo dos Produtos</S.BoletoTable.Head>
          <S.BoletoTable.Head align={EHeadAlign.RIGHT}>Taxas</S.BoletoTable.Head>
          <S.BoletoTable.Head align={EHeadAlign.RIGHT}>Impostos</S.BoletoTable.Head>
          <S.BoletoTable.Head align={EHeadAlign.CENTER}>Status</S.BoletoTable.Head>
        </S.BoletoTable.Row>
      </S.BoletoTable.Header>

      <S.BoletoTable.Body>
        {orders?.map(order => (
          <TableRow key={order.alias_id} order={order} />
        ))}

        {Boolean(!orders?.length) && (
          <S.BoletoTable.Row>
            <S.BoletoTable.Data colSpan={8} align={EHeadAlign.CENTER}>
              <EmptyData content="Não encontramos nenhum boleto." />
            </S.BoletoTable.Data>
          </S.BoletoTable.Row>
        )}
      </S.BoletoTable.Body>

      {Boolean(orders?.length) && (
        <S.BoletoTable.Footer>
          <S.BoletoTable.Row>
            <S.BoletoTable.Data colSpan={8} align={EHeadAlign.RIGHT}>
              <Paginate
                forcePage={boletosPage}
                pageCount={boletosPageCount}
                onPageChange={onBoletoPageChange}
              />
            </S.BoletoTable.Data>
          </S.BoletoTable.Row>
        </S.BoletoTable.Footer>
      )}
    </S.BoletoTable>
  );
};

export default Table;
