import { ApiService } from '@services/api';
import { IResetPasswordProps } from '@domain/interfaces/auth/IResetPassword';
import { AUTH_API_DOMAIN } from '@constants/api';

export class ResetPasswordService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public resetPassword({ token, data }: IResetPasswordProps): Promise<any> {
    return this.apiService.post(`/auth/reset-password?token=${token}`, { ...data });
  }
}

export const resetPasswordService = new ResetPasswordService(AUTH_API_DOMAIN);

export default resetPasswordService;
