import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';

import { EUserRole } from '@domain/enums/auth/secondForm/EUserRole';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';

import { useProfile } from '@helpers/hooks/pages/dashboard/profile/useProfile';

import { profileSchema } from '@helpers/validators/dashboard/profile/profile';

import Avatar from '@components/common/core/DataDisplay/Avatar';
import Text from '@components/common/core/DataDisplay/Text';
import Form from '@components/common/core/Inputs/Form';
import PhoneInput from '@components/common/core/Utils/PhoneInput';

import * as S from './styles';

const PersonalInfo: React.FC = () => {
  const { profile, personalInfo, updatePersonalInfo } = useProfile();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(profileSchema),
  });

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [phoneValue, setPhoneValue] = React.useState<string>(
    `${personalInfo.phone_prefix}${personalInfo.phone_number}`,
  );

  const onSubmit = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      await updatePersonalInfo(formData, phoneValue);

      setIsSubmittingForm(false);
    },
    [phoneValue, updatePersonalInfo],
  );

  const monthDays = [...Array(31).keys()];
  const years = [...Array(122).keys()];

  const monthSelectOptions = monthDays.map((day: number) => (
    <S.Option key={day} value={1 + day}>
      {1 + day}
    </S.Option>
  ));

  const yearSelectOptions = years.map((year: number) => (
    <S.Option key={1900 + year} value={1900 + year}>
      {1900 + year}
    </S.Option>
  ));

  const splittedBirthDate = personalInfo?.birth_date?.split('-') || [];

  return (
    <S.Container>
      <S.AvatarWrapper>
        <Avatar word={profile?.first_name.charAt(0)} />
        <S.AvatarTextWrapper>
          <S.Title type={EHeadingSize.H3} fontWeight={EHeadingWeight.MEDIUM}>
            Perfil
          </S.Title>
          <S.ProfileNameText type={EHeadingSize.H6} fontWeight={EHeadingWeight.REGULAR}>
            {`${profile?.first_name} ${profile?.last_name}`}
          </S.ProfileNameText>
        </S.AvatarTextWrapper>
      </S.AvatarWrapper>

      <S.PersonInfoWrapper>
        <S.HeaderWrapper>
          <S.Heading type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
            Informações pessoais
          </S.Heading>
        </S.HeaderWrapper>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputsGroupWrapper>
            <S.InputGroup>
              <S.Label htmlFor="profile-first-name">Nome</S.Label>
              <S.Input
                {...register('first_name')}
                type="text"
                placeholder="Insira seu nome"
                defaultValue={profile?.first_name}
                id="profile-first-name"
              />
              {errors.first_name && <Text isErrorFeedback>{errors.first_name.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label htmlFor="profile-last-name">Sobrenome</S.Label>
              <S.Input
                {...register('last_name')}
                type="text"
                placeholder="Insira seu sobrenome"
                defaultValue={profile?.last_name}
                id="profile-last-name"
              />
              {errors.last_name && <Text isErrorFeedback>{errors.last_name.message}</Text>}
            </S.InputGroup>
          </S.InputsGroupWrapper>

          <S.InputsGroupWrapper>
            <S.InputGroup>
              <S.Label htmlFor="profile-phone-number">Celular</S.Label>
              <PhoneInput
                {...register('phone_number', { value: phoneValue })}
                country="br"
                value={phoneValue}
                placeholder="Insira seu número"
                onChange={phoneNumber => setPhoneValue(phoneNumber)}
                inputProps={{ name: 'phone_number', id: 'profile-phone-number' }}
              />
              {errors.phone_number && <Text isErrorFeedback>{errors.phone_number.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Data de nascimento</S.Label>
              <S.DateBirthSelectWrapper>
                <S.DayBirthSelect
                  {...register('birth_day')}
                  name="birth_day"
                  defaultValue={String(Number(splittedBirthDate[2])) || '1'}
                >
                  {monthSelectOptions}
                </S.DayBirthSelect>

                <S.MonthBirthSelect
                  {...register('birth_month')}
                  name="birth_month"
                  defaultValue={splittedBirthDate[1] || '01'}
                >
                  <S.Option value="01">Janeiro</S.Option>
                  <S.Option value="02">Fevereiro</S.Option>
                  <S.Option value="03">Março</S.Option>
                  <S.Option value="04">Abril</S.Option>
                  <S.Option value="05">Maio</S.Option>
                  <S.Option value="06">Junho</S.Option>
                  <S.Option value="07">Julho</S.Option>
                  <S.Option value="08">Agosto</S.Option>
                  <S.Option value="09">Setembro</S.Option>
                  <S.Option value="10">Outubro</S.Option>
                  <S.Option value="11">Novembro</S.Option>
                  <S.Option value="12">Dezembro</S.Option>
                </S.MonthBirthSelect>

                <S.YearBirthSelect
                  {...register('birth_year')}
                  name="birth_year"
                  defaultValue={splittedBirthDate[0] || '2022'}
                >
                  {yearSelectOptions}
                </S.YearBirthSelect>
              </S.DateBirthSelectWrapper>
            </S.InputGroup>
          </S.InputsGroupWrapper>

          <S.InputsGroupWrapper>
            <S.InputGroup>
              <S.Label>Cargo/função?</S.Label>
              <S.UserRoleSelect
                {...register('role')}
                name="role"
                defaultValue={personalInfo.role || ''}
              >
                <S.Option disabled value="">
                  Selecione seu cargo
                </S.Option>
                <S.Option value={EUserRole.AGENCIA_DE_MARKETING}>Agência de marketing</S.Option>
                <S.Option value={EUserRole.GERENTE_DE_ECOMMERCE}>Gerente de e-commerce</S.Option>
                <S.Option value={EUserRole.ANALISTA_DE_ECOMMERCE}>Analista de e-commerce</S.Option>
                <S.Option value={EUserRole.SOCIAL_MIDIA}>Social Midia</S.Option>
                <S.Option value={EUserRole.GESTOR_DE_TRAFEGO}>Gestor de Tráfego</S.Option>
                <S.Option value={EUserRole.WEB_DESIGNER}>Web Designer</S.Option>
                <S.Option value={EUserRole.DESENVOLVEDOR}>Desenvolvedor</S.Option>
                <S.Option value={EUserRole.SUPORTE}>Suporte</S.Option>
                <S.Option value={EUserRole.DIRETOR_EXECUTIVO}>Diretor Executivo</S.Option>
                <S.Option value={EUserRole.DIRETOR_DE_MARKETING}>Diretor de Marketing</S.Option>
                <S.Option value={EUserRole.DIRETOR_DE_OPERACOES}>Diretor de Operações</S.Option>
                <S.Option value={EUserRole.DIRETOR_DE_TECNOLOGIA}>Diretor de Tecnologia</S.Option>
                <S.Option value={EUserRole.PROPRIETARIO_DO_ECOMMERCE}>
                  Proprietário do e-commerce
                </S.Option>
                <S.Option value={EUserRole.OUTRO}>Outro</S.Option>
              </S.UserRoleSelect>
              {errors.role && <Text isErrorFeedback>{errors.role.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label htmlFor="profile-email">E-mail</S.Label>
              <S.Input
                {...register('email')}
                type="text"
                placeholder="Digite seu e-mail"
                defaultValue={profile?.email}
                id="profile-email"
                disabled
              />
              {errors.email && <Text isErrorFeedback>{errors.email.message}</Text>}
            </S.InputGroup>
          </S.InputsGroupWrapper>

          <S.Button type="submit" disabled={isSubmittingForm} isLoading={isSubmittingForm}>
            Salvar alterações
          </S.Button>
        </Form>
      </S.PersonInfoWrapper>
    </S.Container>
  );
};

export default PersonalInfo;
