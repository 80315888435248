import styled, { css } from 'styled-components/macro';

import { IDataCellprops } from '@domain/interfaces/dashboard/AdsManager/Table/IDataCell';

export const DataCellContent = styled.div`
  height: 100%;
  overflow-x: hidden;
  /* padding: 10px 12px; */
  box-sizing: border-box;
  height: 58px;
`;

export const DataCell = styled.td<IDataCellprops>`
  background-color: ${({ theme }) => theme.colors.gray[6]};
  -webkit-box-shadow: inset 0px -1px 0px 0px ${({ theme }) => theme.colors.gray[4]},
    inset -1px 0px 0px 0px ${({ theme }) => theme.colors.gray[4]};
  box-shadow: inset 0px -1px 0px 0px ${({ theme }) => theme.colors.gray[4]},
    inset -1px 0px 0px 0px ${({ theme }) => theme.colors.gray[4]};
  height: 58px;
  transition: all 0.3s;

  ${({ isHovering }) =>
    isHovering &&
    css`
      background-color: ${({ theme }) => theme.colors.gray[7]};
    `}
`;

export const Wrapper = styled.tr`
  & > td:first-child {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    left: 0;
    z-index: ${({ theme }) => theme.zIndex.firstLayer};
  }

  & > td:nth-child(2) {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    left: 68px;
    z-index: ${({ theme }) => theme.zIndex.firstLayer};
  }

  & > td:nth-child(3) {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    left: 140px;
    z-index: ${({ theme }) => theme.zIndex.firstLayer};
  }
`;
