import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.div`
  width: 253px;
  height: auto;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  border-radius: 12px;
  position: absolute;
  left: 32px;
  top: 32px;
  padding: 16px;
  z-index: 5;
`;

export const Title = styled(TextBase)`
  font-size: 1.125rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[1]};
  line-height: 1;
  margin-bottom: 16px;
  display: block;
`;
