import React from 'react';
import { ChevronDown, ChevronUp } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { useWindowSize } from '@helpers/hooks/useWindowSize';
import { EHeadAlign } from '@domain/enums/components/ETable';
import { useBilling } from '@helpers/hooks/pages/dashboard/customValues/useBilling';
import Paginate from '@components/common/core/Utils/Paginate';
import Row from './Row';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Table: React.FC = () => {
  const theme: any = useTheme();
  const { height } = useWindowSize();
  const {
    isLoadingCustomValueBillings,
    customValueBillings,
    page,
    pageCount,
    handlePage,
    isValidatingCustomValueBillings,
  } = useBilling();

  const [isMaximized, setIsMaximized] = React.useState<boolean>(false);

  const handleMaximized = React.useCallback(() => setIsMaximized(!isMaximized), [isMaximized]);

  const getMaxHeight = (): number => {
    return (height || 200) - 264;
  };

  if (isLoadingCustomValueBillings || isValidatingCustomValueBillings) {
    return <SkeletonLoading />;
  }

  return (
    <>
      <S.ChevronButton onClick={handleMaximized}>
        {isMaximized ? (
          <ChevronDown color={theme.colors.font} size={24} />
        ) : (
          <ChevronUp color={theme.colors.font} size={24} />
        )}
      </S.ChevronButton>
      <S.TableWrapper maxHeight={getMaxHeight()} isMaximized={isMaximized}>
        <S.Table>
          <S.TableHeader>
            <S.TableRow>
              <S.TableHead>Data</S.TableHead>
              <S.TableHead textAlign={EHeadAlign.RIGHT}>Valor</S.TableHead>
              <S.TableHead textAlign={EHeadAlign.CENTER}>Categoria</S.TableHead>
              <S.TableHead />
            </S.TableRow>
          </S.TableHeader>

          <S.TableBody>
            {customValueBillings.map(billing => (
              <Row key={billing.alias_id} billing={billing} />
            ))}
          </S.TableBody>

          {pageCount > 1 && (
            <S.TableFooter>
              <S.TableRow>
                <S.TableData colSpan={4} textAlign={EHeadAlign.RIGHT}>
                  <Paginate forcePage={page} pageCount={pageCount} onPageChange={handlePage} />
                </S.TableData>
              </S.TableRow>
            </S.TableFooter>
          )}
        </S.Table>
      </S.TableWrapper>
    </>
  );
};

export default Table;
