import React from 'react';
import { useParams } from 'react-router-dom';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { EBadgeColors } from '@domain/enums/components/EBadge';
import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { ITableRowProps } from '@domain/interfaces/integrations/marketing/google/ITable';
import { IParams } from '@domain/interfaces/IParams';

import { useGoogleCredentials } from '@helpers/hooks/common/integrations/marketing/google/useGoogleCredentials';
import { useGoogleAdAccounts } from '@helpers/hooks/common/integrations/marketing/google/useGoogleAdAccounts';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useConfig } from '@helpers/hooks/useConfig';
import { useToast } from '@helpers/hooks/useToast';

import Badge from '@components/common/core/DataDisplay/Badge';
import Tooltip from './Tooltip';

import * as S from './styles';

const Row: React.FC<ITableRowProps> = ({ adSenseAccount }) => {
  const { analytics } = useConfig();

  const { storeAliasId } = useParams<IParams>();

  const { updateAdSenseAccount } = useGoogleAdAccounts();
  const { adSenseCredentials } = useGoogleCredentials();
  const { toast } = useToast();
  const {
    currentSynchronizations,
    synchronizationsQueue,
    finishedSynchronizations,
    failedSynchronizations,
    handleFailedSynchronizations,
    handleFinishedSynchronizations,
  } = useSynchronization();

  const currentSynchronizationsArray = currentSynchronizations
    ? [...currentSynchronizations.synchronizations]
    : [];
  const mergedSynchronizations = [...synchronizationsQueue, ...currentSynchronizationsArray];

  const [isUpdatingAdAccount, setIsUpdatingAdAccount] = React.useState<boolean>(false);
  const [isSyncing, setIsSyncing] = React.useState<boolean>(false);

  const switchRef = React.useRef<HTMLInputElement>(null);

  const onSwitchChange = React.useCallback(
    async event => {
      try {
        if (adSenseAccount.is_blocked_by_downgrade) {
          toast.error('Não é possível realizar a ação.');

          if (switchRef.current) {
            switchRef.current.checked = !switchRef.current.checked;
          }

          return;
        }

        if (!event.target.checked) {
          setIsUpdatingAdAccount(true);
        }

        const data = {
          ...adSenseAccount,
          is_active: event.target.checked,
        };

        await updateAdSenseAccount(adSenseAccount.alias_id, data);

        setIsUpdatingAdAccount(false);

        if (switchRef.current) {
          if (switchRef.current.checked) {
            analytics?.track(
              'Onboarding Ads Account Activated',
              {
                provider: EAdSenseProvider.GOOGLE,
              },
              { context: { groupId: storeAliasId } },
            );
          }
        }
      } catch (error: any) {
        if (switchRef.current) {
          switchRef.current.checked = !switchRef.current.checked;
        }

        setIsUpdatingAdAccount(false);
        toast.error(error?.response?.message?.data);
      }
    },
    [adSenseAccount, toast, updateAdSenseAccount, analytics, storeAliasId],
  );

  const parseIsOperationalName = React.useCallback(() => {
    if (adSenseAccount.is_blocked_by_downgrade) return 'Inativo';

    if (adSenseAccount.is_operational) return 'Operacional';

    return 'Não operacional';
  }, [adSenseAccount]);

  const getBadgeColor = React.useCallback(() => {
    if (adSenseAccount.is_blocked_by_downgrade) return EBadgeColors.YELLOW;

    if (adSenseAccount.is_operational) return EBadgeColors.PRIMARY;

    return EBadgeColors.RED;
  }, [adSenseAccount]);

  const parseStatusAccount = React.useCallback(status => {
    return status === 'ENABLED' ? 'Ativo' : 'Bloqueado';
  }, []);

  React.useEffect(() => {
    if (mergedSynchronizations.length) {
      const foundSynchronization = mergedSynchronizations.find(
        synchronization => synchronization.externalId === adSenseAccount.alias_id,
      );

      setIsSyncing(Boolean(foundSynchronization));
    } else {
      setIsSyncing(false);
    }
  }, [adSenseAccount, mergedSynchronizations]);

  React.useEffect(() => {
    if (finishedSynchronizations.length) {
      const filteredFinishedSynchronizations = finishedSynchronizations.filter(
        synchronization => synchronization.externalId === adSenseAccount.alias_id,
      );

      if (filteredFinishedSynchronizations.length) {
        const newFinishedSynchronzation = finishedSynchronizations.filter(
          synchronization => synchronization.externalId !== adSenseAccount.alias_id,
        );

        handleFinishedSynchronizations(newFinishedSynchronzation);
      }
    }
  }, [finishedSynchronizations, handleFinishedSynchronizations, adSenseAccount]);

  React.useEffect(() => {
    if (failedSynchronizations.length) {
      const filteredFailedSynchronizations = failedSynchronizations.filter(
        synchronization => synchronization.externalId === adSenseAccount.alias_id,
      );

      if (filteredFailedSynchronizations.length) {
        if (switchRef?.current) {
          switchRef.current.checked = false;
        }

        const newFailedSynchronization = failedSynchronizations.filter(
          synchronization => synchronization.externalId !== adSenseAccount.alias_id,
        );

        handleFailedSynchronizations(newFailedSynchronization);
      }
    }
  }, [toast, switchRef, failedSynchronizations, adSenseAccount, handleFailedSynchronizations]);

  const getAdSenseAccountProfile = React.useCallback(() => {
    const foundAdAccountProfile = adSenseCredentials.find(
      adSenseCredential => adSenseCredential.id === adSenseAccount.ad_sense_credential_id,
    );

    return foundAdAccountProfile?.name;
  }, [adSenseCredentials, adSenseAccount]);

  const {
    clicks,
    impressions,
    conversions,
    all_conversions,
    // spend_amount,
  } = adSenseAccount.ad_sense_account_insight;

  const adSenseAccountId = adSenseAccount.provider_id;
  const adSenseAccountName = adSenseAccount.name;
  const adSenseAccountProfile = getAdSenseAccountProfile();
  // const formattedSpendAmount = `R$ ${numberFormatter(spend_amount, 0)}`;
  const adSenseAccountStatus = parseIsOperationalName();
  const adSenseAccountProviderStatus = parseStatusAccount(adSenseAccount.status);
  const isAdAccountBlockedByDowngrade = adSenseAccount.is_blocked_by_downgrade;

  return (
    <S.Table.Row borderBottom>
      <S.Table.Data>
        <S.Switch
          name={`switch-${adSenseAccount.id}`}
          id={`switch-${adSenseAccount.id}`}
          onChange={!isSyncing ? onSwitchChange : () => null}
          isLoading={isUpdatingAdAccount}
          defaultChecked={adSenseAccount.is_active}
          disabled={isSyncing || isUpdatingAdAccount}
          innerRef={switchRef}
        />
      </S.Table.Data>

      <S.Table.Data>
        <S.InfosWrapper>
          <Tooltip content={adSenseAccountId} side="top" sideOffset={10}>
            <S.AccountInfo>{adSenseAccountId}</S.AccountInfo>
          </Tooltip>

          <S.Description>ID da conta</S.Description>
        </S.InfosWrapper>
      </S.Table.Data>

      <S.Table.Data>
        <S.InfosWrapper>
          <Tooltip content={adSenseAccountName} textMaxWidth={100} side="top" sideOffset={10}>
            <S.AccountInfo>{adSenseAccountName}</S.AccountInfo>
          </Tooltip>

          <S.Description>Nome</S.Description>
        </S.InfosWrapper>
      </S.Table.Data>

      <S.Table.Data>
        <S.InfosWrapper>
          <Tooltip content={adSenseAccountProfile} textMaxWidth={100} side="top" sideOffset={10}>
            <S.AccountInfo>{adSenseAccountProfile}</S.AccountInfo>
          </Tooltip>
          <S.Description>Perfil</S.Description>
        </S.InfosWrapper>
      </S.Table.Data>

      <S.Table.Data>
        <S.InfosWrapper>
          <S.AccountInfo>{clicks}</S.AccountInfo>
          <S.Description>Clicks</S.Description>
        </S.InfosWrapper>
      </S.Table.Data>

      <S.Table.Data>
        <S.InfosWrapper>
          <S.AccountInfo>{impressions}</S.AccountInfo>

          <Tooltip content="Impressões" side="bottom" sideOffset={8}>
            <S.Description>Impressões</S.Description>
          </Tooltip>
        </S.InfosWrapper>
      </S.Table.Data>

      <S.Table.Data>
        <S.InfosWrapper>
          <S.AccountInfo>{conversions}</S.AccountInfo>

          <Tooltip content="Conversões" side="bottom" sideOffset={8}>
            <S.Description>Conversões</S.Description>
          </Tooltip>
        </S.InfosWrapper>
      </S.Table.Data>

      <S.Table.Data>
        <S.InfosWrapper>
          <S.AccountInfo>{all_conversions}</S.AccountInfo>

          <Tooltip content="Todas conversões" side="bottom" sideOffset={8}>
            <S.Description>Todas conversões</S.Description>
          </Tooltip>
        </S.InfosWrapper>
      </S.Table.Data>

      {/* <S.Table.Data align={EHeadAlign.RIGHT}>
        <S.InfosWrapper>
          <S.AccountInfo>{formattedSpendAmount}</S.AccountInfo>

          <Tooltip content="Gastos dos últimos 7 dias" side="bottom" sideOffset={8}>
            <S.Description>Gastos dos últimos 7 dias</S.Description>
          </Tooltip>
        </S.InfosWrapper>
      </S.Table.Data> */}

      <S.Table.Data align={EHeadAlign.RIGHT}>
        <Tooltip content="Status da conta no Google" side="bottom" sideOffset={8}>
          <Badge
            color={
              adSenseAccountProviderStatus === 'Ativo' ? EBadgeColors.PRIMARY : EBadgeColors.RED
            }
          >
            {adSenseAccountProviderStatus}
          </Badge>
        </Tooltip>
      </S.Table.Data>

      <S.Table.Data align={EHeadAlign.RIGHT}>
        <S.Tooltip>
          <S.Tooltip.Trigger>
            <Badge color={getBadgeColor()}>{adSenseAccountStatus}</Badge>
          </S.Tooltip.Trigger>

          {isAdAccountBlockedByDowngrade && (
            <S.Tooltip.Content side="top" sideOffset={5}>
              <S.TooltipContent>
                <S.TooltipText>
                  Essa conta de anúncio foi inativada{' '}
                  <strong>após a realização do Downgrade.</strong>
                  <br />
                  Os dados retroativos dessa conta <strong>não foram excluídos.</strong>
                </S.TooltipText>
              </S.TooltipContent>

              <S.Tooltip.Arrow />
            </S.Tooltip.Content>
          )}
        </S.Tooltip>
      </S.Table.Data>
    </S.Table.Row>
  );
};

export default Row;
