import React from 'react';

import { Copy } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';

import { IParams } from '@domain/interfaces/IParams';
import { IPlan } from '@domain/interfaces/subscription/IPlans';
import { IGenerateCheckoutLinkData } from '@domain/interfaces/subscription/ICheckout';

import { useToast } from '@helpers/hooks/useToast';

import { numberFormatter } from '@helpers/masks';

import { checkoutSchema } from '@helpers/validators/dashboard/adminPanel/checkout';

import checkoutService from '@services/pages/subscription/checkout';
import plansService from '@services/pages/subscription/plans';

import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const Checkout: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(checkoutSchema) });

  const [isLinkCopied, setIsLinkCopied] = React.useState<boolean>(false);
  const [isGeneratingLink, setIsGeneratingLink] = React.useState<boolean>(false);
  const [checkoutLink, setCheckoutLink] = React.useState<string>('');

  const handleCopyLink = React.useCallback(() => {
    navigator.clipboard.writeText(checkoutLink);
    setIsLinkCopied(true);
    toast.success('Link Copiado com sucesso');
  }, [checkoutLink, toast]);

  const {
    plans,
    isLoading: isLoadingPlans,
    isValidating: isValidatingPlans,
    error: plansError,
  } = plansService.getPlans({ storeAliasId });

  const onSubmit = React.useCallback(
    async formData => {
      setIsGeneratingLink(true);

      const storeAliasIdToGenerate = formData.alias_id as string;

      const replacedAliasId = storeAliasIdToGenerate.replace('store-', '');

      try {
        const checkoutLinkData: IGenerateCheckoutLinkData = {
          allowed_orders_quantity: formData.orders_quantity,
          coupon_id: formData.coupon_id ? formData.coupon_id : undefined,
        };

        const { data } = await checkoutService.generateCheckoutLink({
          storeAliasId: `store-${replacedAliasId}`,
          planAliasId: formData.plan,
          data: checkoutLinkData,
        });

        setCheckoutLink(data?.url);
        setIsGeneratingLink(false);
      } catch (error: any) {
        toast.error(error?.response?.message?.data);
        setIsGeneratingLink(false);
      }
    },
    [toast],
  );

  const getPlanText = (planIdentifier: string): string => {
    if (planIdentifier.includes('one')) return '1';

    if (planIdentifier.includes('two')) return '2';

    if (planIdentifier.includes('three')) return '3';

    if (planIdentifier.includes('four')) return '4';

    return '';
  };

  const getOptionText = React.useCallback((plan: IPlan) => {
    const includesTenPercentDiscount = plan.identifier.includes('10_off');

    const tenPercentText = includesTenPercentDiscount ? ' (10% OFF)' : '';

    const isMonthlyPlan = plan.identifier.includes('monthly');
    const isQuarterlyPlan = plan.identifier.includes('quarterly');

    const formattedPlanAmount = `R$ ${numberFormatter(plan.amount, 2)}`;

    const planName = plan.name;
    const planNumber = getPlanText(plan.identifier);

    if (isMonthlyPlan) {
      return `${planName} Mensal ${planNumber} (${formattedPlanAmount}) ${tenPercentText}`;
    }

    if (isQuarterlyPlan) {
      return `${planName} Trimestral ${planNumber} (${formattedPlanAmount}) ${tenPercentText}`;
    }

    return `${planName} Semestral ${planNumber} (${formattedPlanAmount}) ${tenPercentText}`;
  }, []);

  const onCouponsLinkClick = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/admin/coupons`);
  }, [history, storeAliasId]);

  if (isLoadingPlans || isValidatingPlans) return <div>Loading...</div>;

  if (Boolean(plansError) && !isValidatingPlans) return <div>Error</div>;

  const filteredEnterprisePlans = plans.filter(
    plan => plan.identifier.includes('enterprise') || plan.identifier.includes('10_off'),
  );

  return (
    <S.ContentWrapper>
      <S.Header>
        <S.Heading>Checkout Personalizado</S.Heading>

        <S.Button onClick={onCouponsLinkClick}>Acessar cupons</S.Button>
      </S.Header>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label>Plano</S.Label>
          <S.PlansSelect {...register('plan')}>
            {filteredEnterprisePlans.map(plan => {
              return (
                <S.Option value={plan.alias_id} key={plan.alias_id}>
                  {getOptionText(plan)}
                </S.Option>
              );
            })}
          </S.PlansSelect>
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Alias ID</S.Label>
          <S.Input
            {...register('alias_id')}
            type="text"
            placeholder="Insira o Alias Id da loja"
            isError={errors.alias_id}
          />
          {errors.alias_id && <Text isErrorFeedback>{errors.alias_id.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Quantidade de Pedidos</S.Label>
          <S.Input
            {...register('orders_quantity')}
            type="text"
            placeholder="Insira a quantidade de pedidos"
            isError={errors.orders_quantity}
          />
          {errors.orders_quantity && <Text isErrorFeedback>{errors.orders_quantity.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Id do Cupom (opcional)</S.Label>
          <S.Input {...register('coupon_id')} type="text" placeholder="Insira o id do cupom" />
        </S.InputGroup>

        {Boolean(checkoutLink) && (
          <S.InputGroup>
            <S.Label>Link do Checkout</S.Label>
            <S.CheckoutLinkWrapper onClick={handleCopyLink} type="button">
              <S.CheckoutLink>{checkoutLink}</S.CheckoutLink>
              <S.IconWrapper>
                <Copy size={20} color={theme.colors.gray[1]} outline={!isLinkCopied} />
              </S.IconWrapper>
            </S.CheckoutLinkWrapper>
          </S.InputGroup>
        )}

        <S.GeneratorButton type="submit" isLoading={isGeneratingLink}>
          Gerar Link
        </S.GeneratorButton>
      </S.Form>
    </S.ContentWrapper>
  );
};

export default Checkout;
