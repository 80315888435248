import React from 'react';

import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';

import { useCanvasContent } from '@helpers/hooks/pages/dashboard/automations/useCanvasContent';

import TextTypeConfig from './TextTypeConfig';
import ImageTypeConfig from './ImageTypeConfig';
import AudioTypeConfig from './AudioTypeConfig';
import VideoTypeConfig from './VideoTypeConfig';

const Content: React.FC = () => {
  const { selectedContentType } = useCanvasContent();

  return (
    <>
      {selectedContentType === EConfigOption.TEXT && <TextTypeConfig />}

      {selectedContentType === EConfigOption.IMAGE && <ImageTypeConfig />}

      {selectedContentType === EConfigOption.AUDIO && <AudioTypeConfig />}

      {selectedContentType === EConfigOption.VIDEO && <VideoTypeConfig />}
    </>
  );
};

export default Content;
