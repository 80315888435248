import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import BadgeBase from '@components/common/core/DataDisplay/Badge';
import ButtonBase from '@components/common/core/Inputs/Button';
import TooltipBase from '@components/common/core/Utils/Tooltip';

export const Tooltip = styled(TooltipBase)`
  cursor: pointer;
`;

export const Wrapper = styled.div`
  min-width: 768px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 28px;
  gap: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[5]};

  &:last-of-type {
    border-bottom: unset;
  }
`;

export const IconAndInfosWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 224px;
  width: 100%;
  gap: 24px;
`;

export const InfosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const AccountIdText = styled(TextBase)``;

export const AccountStatusBadge = styled(BadgeBase)``;

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 192px;
  width: 100%;
`;

export const ProfileName = styled(TextBase)`
  max-width: 192px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
`;

export const UserNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 192px;
  width: 100%;
`;

export const UserName = styled(TextBase)`
  max-width: 192px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
`;

export const InfoDescription = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const UnderstoodButton = styled(ButtonBase)``;
