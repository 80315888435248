import styled from 'styled-components/macro';

import CheckboxBase from '@components/common/core/Inputs/Checkbox';

export const Checkbox = styled(CheckboxBase)``;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 58px;
  padding: 10px 12px;
  box-sizing: border-box;
`;
