/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { useTheme } from 'styled-components';
import { useAudioRecorder, RECORD_STATUS } from '@sarafhbk/react-audio-recorder';
import { Microphone, Pause, Play, X as CancelIcon, Record, Check } from 'phosphor-react';

import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';

import { useMediaQuery } from '@helpers/hooks/useMediaQuery';
import { useContent } from '@helpers/hooks/pages/dashboard/automations/useContent';
import { useMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useMessageSettings';
import { useToast } from '@helpers/hooks/useToast';

import AudioPlayer from './AudioPlayer';

import * as S from './styles';

const RecordAudio: React.FC = () => {
  const theme = useTheme();

  const mobileWidth = useMediaQuery('955px');
  const { toast } = useToast();
  const { contentsToUpdate, handleContentsToUpdate } = useMessageSettings();
  const {
    handleEditingContent,
    handleSelectedContentType,
    isEditingContent,
    contentToEdit,
  } = useContent();
  const {
    audioResult,
    timer,
    startRecording,
    stopRecording: finishRecording,
    pauseRecording,
    resumeRecording,
    status,
  } = useAudioRecorder();

  const [isRecordingFinished, setIsRecordingFinished] = React.useState<boolean>(false);
  const [audioFile, setAudioFile] = React.useState<File>({} as File);

  const resetConfig = React.useCallback(() => {
    handleEditingContent(false);
    handleSelectedContentType(EConfigOption.NONE);
  }, [handleEditingContent, handleSelectedContentType]);

  const handleUpdateAudioContent = React.useCallback(
    audio => {
      if (!isEditingContent || !contentToEdit) return;

      const data = [...contentsToUpdate];

      const foundContent = data.find(content => content.id === contentToEdit.id);

      if (!foundContent) return;

      const index = data.indexOf(foundContent);

      data[index].content = audio;
      data[index].isEdited = true;

      handleContentsToUpdate(data);
    },
    [isEditingContent, contentToEdit, contentsToUpdate, handleContentsToUpdate],
  );

  const onCancel = React.useCallback(() => {
    resetConfig();
  }, [resetConfig]);

  const onSave = React.useCallback(
    file => {
      if (!file) return;

      const MAX_FILE_SIZE = 1024 * 1024 * 3;

      if (file.size > MAX_FILE_SIZE) {
        toast.error('O arquivo deve ter no máximo 3MB');
        return;
      }

      if (isEditingContent) {
        handleUpdateAudioContent(file);
        resetConfig();
        return;
      }

      const newContent = {
        id: Math.random(),
        type: EConfigOption.AUDIO,
        content: file,
        isNewItemInArray: true,
      };

      handleContentsToUpdate([...contentsToUpdate, newContent]);

      resetConfig();
    },
    [
      toast,
      isEditingContent,
      handleUpdateAudioContent,
      resetConfig,
      handleContentsToUpdate,
      contentsToUpdate,
    ],
  );

  const handleRecordingFinished = React.useCallback(() => {
    setIsRecordingFinished(currentState => !currentState);
  }, []);

  const onFinish = React.useCallback(() => {
    finishRecording();
    handleRecordingFinished();
  }, [finishRecording, handleRecordingFinished]);

  const formattedTime = new Intl.DateTimeFormat('pt-BR', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: 'UTC',
  }).format(new Date(timer * 1000));

  const convertBlobToFile = React.useCallback(async () => {
    fetch(audioResult)
      .then(response => response.blob())
      .then(blob => {
        if (blob) {
          const file = new File([blob], `audio-${Math.random()}.wav`, {
            type: 'audio/wave',
          });

          setAudioFile(file);
        }
      })
      .catch(error => console.log({ blobFetchError: error }));
  }, [audioResult]);

  React.useEffect(() => {
    convertBlobToFile();
  }, [convertBlobToFile]);

  const isRecordingStatus = status === RECORD_STATUS.RECORDING;
  const isPausedStatus = status === RECORD_STATUS.PAUSED;
  const showFinishRecordingButton = isRecordingStatus || isPausedStatus;

  return (
    <S.Wrapper>
      {!isRecordingFinished && (
        <>
          <S.Timer>{formattedTime}</S.Timer>

          <S.Controls>
            <S.DeleteAudioButton onClick={onCancel} type="button">
              <CancelIcon size={24} color={theme.colors.gray[1]} weight="fill" />
            </S.DeleteAudioButton>

            {!showFinishRecordingButton && (
              <S.StartRecordingButton type="button" onClick={startRecording}>
                <Microphone size={24} color={theme.colors.gray[8]} weight="fill" />
                Começar a gravar
              </S.StartRecordingButton>
            )}

            {showFinishRecordingButton && (
              <S.FinishRecordingButton onClick={onFinish} type="button">
                <Record size={24} color={theme.colors.gray[1]} weight="fill" />
                Finalizar gravação
              </S.FinishRecordingButton>
            )}

            <S.PauseButton
              type="button"
              onClick={isPausedStatus ? resumeRecording : pauseRecording}
              isPaused={isPausedStatus}
            >
              {!isPausedStatus && <Pause size={24} color={theme.colors.gray[1]} weight="fill" />}

              {isPausedStatus && <Play size={24} color={theme.colors.gray[1]} weight="fill" />}
            </S.PauseButton>
          </S.Controls>
        </>
      )}

      {isRecordingFinished && (
        <>
          {mobileWidth && (
            <AudioPlayer
              handleRecordingFinished={handleRecordingFinished}
              audioSource={audioResult}
            />
          )}

          <S.Controls isRecordingFinished={isRecordingFinished}>
            <S.DeleteAudioButton onClick={onCancel} type="button">
              <CancelIcon size={24} color={theme.colors.gray[1]} weight="fill" />
            </S.DeleteAudioButton>

            {!mobileWidth && (
              <AudioPlayer
                handleRecordingFinished={handleRecordingFinished}
                audioSource={audioResult}
              />
            )}

            <S.SaveButton onClick={() => onSave?.(audioFile)} type="button">
              <Check size={24} color={theme.colors.gray[8]} weight="fill" />
            </S.SaveButton>
          </S.Controls>
        </>
      )}
    </S.Wrapper>
  );
};

export default RecordAudio;
