import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import AlertBase from '@components/common/core/DataDisplay/Alert';
import LabelBase from '@components/common/core/DataDisplay/Label';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import ButtonBase from '@components/common/core/Inputs/Button';

export const SideModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  padding-bottom: 22px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
  margin-bottom: 42px;
`;

export const HeaderTitle = styled(HeadingBase)`
  margin-left: 16px;
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const Description = styled(TextBase)``;

export const Alert = styled(AlertBase)`
  margin-top: 42px;
  border: 0;
  margin-bottom: 12px;

  & > span {
    font-size: 0.875rem;
    font-weight: 400;
  }
`;

export const InputGroup = styled(InputGroupBase)`
  margin-top: 12px;
`;

export const Label = styled(LabelBase)`
  font-size: 1rem;
`;

export const YearSelect = styled(SelectBase)`
  width: 100%;

  & > label > select {
    width: 100%;
  }
`;

export const MonthSelect = styled(SelectBase)`
  width: 100%;

  & > label > select {
    width: 100%;
  }
`;

export const Option = styled(OptionBase)``;

export const CancelAndExportButtonsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding: 24px 0;
  align-items: center;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.colors.gray[5]};
`;

export const ExportButton = styled(ButtonBase)`
  margin-right: 24px;
`;

export const CancelButton = styled(ButtonBase)`
  & > span {
    font-weight: 400;
  }
`;
