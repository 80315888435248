import styled, { css } from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import { IParagraphProps } from '@domain/interfaces/components/ITermsOfUsageModal';

export const ExternalLink = styled.a`
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.font};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 150%;
  text-decoration: underline;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.green.default};
  }

  &:active {
    color: ${({ theme }) => theme.colors.green.default};
  }
`;

export const Subtitle = styled(HeadingBase)`
  font-weight: 600;
  font-size: 0.875rem;
  margin-top: 32px;
  margin-bottom: 16px;
  text-decoration: underline;
`;

export const Paragraph = styled.p<IParagraphProps>`
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.font};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 150%;

  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `}

  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}
`;

export const Title = styled(HeadingBase)`
  font-size: 1rem;
  font-weight: 400;
  text-decoration: underline;
  text-transform: uppercase;
`;

export const Content = styled.div``;
