import React from 'react';

import { useSubscriptionPlans } from '@helpers/hooks/pages/dashboard/subscription/useSubscriptionPlans';
import { useMediaQuery } from '@helpers/hooks/useMediaQuery';

import LoadingScreen from '@components/common/core/Utils/LoadingScreen';
import InstantHelp from '@components/common/core/Utils/InstantHelp';
import PendingPayment from '@components/Dashboard/MySubscription/Plans/Modals/PendingPayment';
import ComparePlans from '../ComparePlans';
import UpgradingModal from '../Modals/UpgradingModal';
import DowngradingModal from '../Modals/DowngradingModal';
import Slider from '../Slider';

import * as S from './styles';

const AvailablePlansList: React.FC = () => {
  const { isChangingPlan } = useSubscriptionPlans();
  const shouldHideTableComparison = useMediaQuery('999px');

  return (
    <>
      {isChangingPlan && <LoadingScreen showChargingSubscriptionText />}

      <PendingPayment />

      <S.Wrapper>
        <S.Container>
          <Slider />

          {!shouldHideTableComparison && <ComparePlans />}
        </S.Container>

        <InstantHelp
          name="profile_plan"
          title="Como funcionam os Planos da Profitfy.me"
          articleLink="https://ajuda.profitfy.me/pt-BR/articles/6122687-como-funcionam-os-planos-da-profitfy-me"
        />
      </S.Wrapper>

      <UpgradingModal />

      <DowngradingModal />
    </>
  );
};

export default AvailablePlansList;
