import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { EGatewayPaymentType } from '@domain/enums/common/EGatewayPaymentType';
import { IUpsertOtherGatewayProps } from '@domain/interfaces/dashboard/IntegrationCenter/Gateway/IOtherGateway';
import { IIntegrateFormProps } from '@domain/interfaces/dashboard/IntegrationCenter/IIntegrateForm';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useDropshipping } from '@helpers/hooks/pages/onboarding/useDropshipping';

import { currencyFormatter, currencyToNumber } from '@helpers/masks';
import { getFormSchema } from '@helpers/validators/onboarding/dropshipping/otherGateway';

import { GATEWAYS } from '@constants/common/integrations';

import otherGatewayIntegrationService from '@services/pages/dashboard/integrationCenter/gateway/otherGateway';

import NextStep from '@components/Onboarding/NextStep';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const IntegrateForm: React.FC<IIntegrateFormProps> = ({ gatewayName, gatewayId }) => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { handleNextGatewayToIntegrate } = useDropshipping();

  const [isUpsertingGateway, setIsUpsertingGateway] = React.useState<boolean>(false);
  const [pixTaxType, setPixTaxType] = React.useState<string>('fixed');
  const [boletoTaxType, setBoletoTaxType] = React.useState<string>('fixed');
  const [gatewayNameValue, setGatewayNameValue] = React.useState<string>(gatewayName || '');
  const [pixValue, setPixValue] = React.useState<string>('');
  const [boletoValue, setBoletoValue] = React.useState<string>('');
  const [credicCardValue, setCredicCardValue] = React.useState<string>('');
  const [
    creditCardTransactionAmountValue,
    setCreditCardTransactionAmountValue,
  ] = React.useState<string>('');
  const [isPixInputGroupVisible, setIsPixInputGroupVisible] = React.useState<boolean>(false);
  const [isBoletoInputGroupVisible, setIsBoletoInputGroupVisible] = React.useState<boolean>(false);
  const [isCredicCardInputGroupVisible, setIsCredicCardInputGroupVisible] = React.useState<boolean>(
    false,
  );

  const foundIntegration = GATEWAYS.find(integration => integration.id === gatewayId);

  const isPredefinedGateway = Boolean(foundIntegration?.isPredefinedOtherGateway);

  const formSchema = getFormSchema({
    isPixInputGroupVisible,
    isBoletoInputGroupVisible,
    isCredicCardInputGroupVisible,
    pixTaxType,
    boletoTaxType,
    isPredefinedGateway,
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  });

  const gatewayNameInputRegister = register('gateway_name');
  const pixInputRegister = register('pix_tax');
  const boletoInputRegister = register('boleto_tax');
  const credicCardInputRegister = register('credic_card_tax');
  const creditCardTransactionAmountInputRegister = register('credit_card_transaction_amount');

  const onGatewayNameChange = React.useCallback(
    event => {
      gatewayNameInputRegister.onChange(event);

      setGatewayNameValue(event.target.value);
    },
    [gatewayNameInputRegister],
  );

  const onPixInputChange = React.useCallback(
    event => {
      pixInputRegister.onChange(event);

      const formattedValue = currencyFormatter(event.target.value);

      setPixValue(formattedValue);
    },
    [pixInputRegister],
  );

  const onBoletoInputChange = React.useCallback(
    event => {
      boletoInputRegister.onChange(event);

      const formattedValue = currencyFormatter(event.target.value);

      setBoletoValue(formattedValue);
    },
    [boletoInputRegister],
  );

  const onCredicCardInputChange = React.useCallback(
    event => {
      credicCardInputRegister.onChange(event);

      const formattedValue = currencyFormatter(event.target.value);

      setCredicCardValue(formattedValue);
    },
    [credicCardInputRegister],
  );

  const onCreditCardTransactionAmountInputChange = React.useCallback(
    event => {
      creditCardTransactionAmountInputRegister.onChange(event);

      const formattedValue = currencyFormatter(event.target.value);

      setCreditCardTransactionAmountValue(formattedValue);
    },
    [creditCardTransactionAmountInputRegister],
  );

  const onPixCheckBoxChange = React.useCallback(
    event => {
      if (event && event.checked) {
        setIsPixInputGroupVisible(true);
      } else {
        setIsPixInputGroupVisible(false);
        setPixValue('');
        clearErrors('pix_tax');
      }
    },
    [clearErrors],
  );

  const onBoletoCheckBoxChange = React.useCallback(
    event => {
      if (event && event.checked) {
        setIsBoletoInputGroupVisible(true);
      } else {
        setIsBoletoInputGroupVisible(false);
        setBoletoValue('');
        clearErrors('boleto_tax');
      }
    },
    [clearErrors],
  );

  const onCredicCardCheckBoxChange = React.useCallback(
    event => {
      if (event && event.checked) {
        setIsCredicCardInputGroupVisible(true);
      } else {
        setIsCredicCardInputGroupVisible(false);
        setCredicCardValue('');
        clearErrors('credic_card_tax');
      }
    },
    [clearErrors],
  );

  const onPixSelectChange = React.useCallback(event => {
    setPixTaxType(event.target.value);
  }, []);

  const onBoletoSelectChange = React.useCallback(event => {
    setBoletoTaxType(event.target.value);
  }, []);

  const upsertOtherGateway = React.useCallback(
    async ({ data }: IUpsertOtherGatewayProps) => {
      setIsUpsertingGateway(true);

      try {
        await otherGatewayIntegrationService.upsertOtherGateway({ storeAliasId, data });

        toast.success('Gateway criado com sucesso.');

        setIsUpsertingGateway(false);

        handleNextGatewayToIntegrate();
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsUpsertingGateway(false);
      }
    },
    [toast, storeAliasId, handleNextGatewayToIntegrate],
  );

  const onSubmit = React.useCallback(
    async formData => {
      const upsertOtherGatewayArrayData = [];

      if (isPixInputGroupVisible) {
        const data = {
          name: formData?.gateway_name || gatewayName,
          amount: currencyToNumber(formData?.pix_tax),
          transaction_amount: 0,
          installments: 1,
          is_percentage: pixTaxType === 'percentage',
          type: EGatewayPaymentType.PIX,
        };

        upsertOtherGatewayArrayData.push(data);
      }

      if (isBoletoInputGroupVisible) {
        const data = {
          name: formData?.gateway_name || gatewayName,
          amount: currencyToNumber(formData?.boleto_tax),
          transaction_amount: 0,
          installments: 1,
          is_percentage: boletoTaxType === 'percentage',
          type: EGatewayPaymentType.BOLETO,
        };

        upsertOtherGatewayArrayData.push(data);
      }

      if (isCredicCardInputGroupVisible) {
        const data = {
          name: formData?.gateway_name || gatewayName,
          amount: currencyToNumber(formData?.credic_card_tax),
          transaction_amount: currencyToNumber(formData?.credit_card_transaction_amount),
          installments: 1,
          is_percentage: true,
          type: EGatewayPaymentType.CARD,
        };

        upsertOtherGatewayArrayData.push(data);
      }

      upsertOtherGateway({ data: { other_gateways: upsertOtherGatewayArrayData } });
    },
    [
      isPixInputGroupVisible,
      boletoTaxType,
      isBoletoInputGroupVisible,
      isCredicCardInputGroupVisible,
      pixTaxType,
      upsertOtherGateway,
      gatewayName,
    ],
  );

  React.useEffect(() => {
    if (gatewayName) {
      setGatewayNameValue(gatewayName);
    }
  }, [gatewayName]);

  React.useEffect(() => {
    if (gatewayName === 'Appmax') {
      setPixValue('0,98');
      setPixTaxType('percentage');

      setBoletoValue('3,49');
      setBoletoTaxType('fixed');

      setCredicCardValue('5,99');
    }
  }, [gatewayName]);

  const gatewayNameInputError = Boolean(!gatewayNameValue) && Boolean(errors.gateway_name);
  const pixIputError = Boolean(!pixValue) && Boolean(errors.pix_tax);
  const boletoIputError = Boolean(!boletoValue) && Boolean(errors.boleto_tax);
  const credicCardIputError = Boolean(!credicCardValue) && Boolean(errors.credic_card_tax);

  const isNextStepButtonDisabled =
    gatewayNameInputError || pixIputError || boletoIputError || credicCardIputError;

  const isPaymentMethodsInputsVisible =
    isPixInputGroupVisible || isBoletoInputGroupVisible || isCredicCardInputGroupVisible;

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.InputGroup>
        <S.Label>{isPredefinedGateway ? 'Nome' : 'Nome do Gateway (Ex: Cielo)'}</S.Label>
        <S.GatewayNameInput
          {...gatewayNameInputRegister}
          type="text"
          value={gatewayNameValue}
          onChange={onGatewayNameChange}
          placeholder="Digite o nome do gateway"
          isError={errors.gateway_name}
        />
        {errors.gateway_name && <Text isErrorFeedback>{errors.gateway_name.message}</Text>}
      </S.InputGroup>

      <S.InputGroup>
        <S.PaymentMethodsLabel>Selecione a(s) forma(s) de pagamento:</S.PaymentMethodsLabel>

        <S.PaymentMethodsWrapper>
          <S.CheckBoxWrapper>
            <S.Checkbox name="pix" onChange={onPixCheckBoxChange} />
            <S.CheckboxText>Pix</S.CheckboxText>
          </S.CheckBoxWrapper>

          <S.CheckBoxWrapper>
            <S.Checkbox name="boleto" onChange={onBoletoCheckBoxChange} />
            <S.CheckboxText>Boleto</S.CheckboxText>
          </S.CheckBoxWrapper>

          <S.CheckBoxWrapper>
            <S.Checkbox name="credic_card" onChange={onCredicCardCheckBoxChange} />
            <S.CheckboxText>Cartão</S.CheckboxText>
          </S.CheckBoxWrapper>
        </S.PaymentMethodsWrapper>
      </S.InputGroup>

      {isPixInputGroupVisible && (
        <S.TaxInputGroup>
          <S.Label>Informe quanto é sua taxa de Pix</S.Label>

          <S.InputAndSelectWrapper>
            <S.TaxInput
              {...pixInputRegister}
              type="text"
              value={pixValue}
              onChange={onPixInputChange}
              prependText={pixTaxType === 'percentage' ? '%' : 'R$'}
              placeholder={pixTaxType === 'percentage' ? '2,5%' : '2,5'}
              isError={errors.pix_tax}
            />
            <S.Select name="pix_tax_type" onChange={onPixSelectChange} defaultValue={pixTaxType}>
              <S.Option value="fixed">Fixo</S.Option>
              <S.Option value="percentage">Porcentagem</S.Option>
            </S.Select>
          </S.InputAndSelectWrapper>
          {errors.pix_tax && <Text isErrorFeedback>{errors.pix_tax.message}</Text>}
        </S.TaxInputGroup>
      )}

      {isBoletoInputGroupVisible && (
        <S.TaxInputGroup>
          <S.Label>Informe quanto é sua taxa de Boleto</S.Label>

          <S.InputAndSelectWrapper>
            <S.TaxInput
              {...boletoInputRegister}
              type="text"
              value={boletoValue}
              onChange={onBoletoInputChange}
              prependText={boletoTaxType === 'percentage' ? '%' : 'R$'}
              placeholder={boletoTaxType === 'percentage' ? '2,5%' : '2,5'}
              isError={errors.boleto_tax}
            />
            <S.Select
              name="boleto_tax_type"
              onChange={onBoletoSelectChange}
              defaultValue={boletoTaxType}
            >
              <S.Option value="fixed">Fixo</S.Option>
              <S.Option value="percentage">Porcentagem</S.Option>
            </S.Select>
          </S.InputAndSelectWrapper>
          {errors.boleto_tax && <Text isErrorFeedback>{errors.boleto_tax.message}</Text>}
        </S.TaxInputGroup>
      )}

      {isCredicCardInputGroupVisible && (
        <S.CardInputsWrapper>
          <S.TaxInputGroup>
            <S.Label>Informe quanto é sua taxa de cartão</S.Label>
            <S.TaxInput
              {...credicCardInputRegister}
              type="text"
              value={credicCardValue}
              onChange={onCredicCardInputChange}
              prependText="%"
              placeholder="4,99%"
              isError={errors.credic_card_tax}
            />
            {errors.credic_card_tax && (
              <Text isErrorFeedback>{errors.credic_card_tax.message}</Text>
            )}
          </S.TaxInputGroup>

          <S.TaxInputGroup>
            <S.Label>Informe a taxa fixa do cartão</S.Label>
            <S.TaxInput
              {...creditCardTransactionAmountInputRegister}
              type="text"
              value={creditCardTransactionAmountValue}
              onChange={onCreditCardTransactionAmountInputChange}
              prependText="R$"
              placeholder="0,99"
              isError={errors.credit_card_transaction_amount}
            />
            {errors.credit_card_transaction_amount && (
              <Text isErrorFeedback>{errors.credit_card_transaction_amount.message}</Text>
            )}
          </S.TaxInputGroup>
        </S.CardInputsWrapper>
      )}

      <NextStep
        type="submit"
        disabled={isNextStepButtonDisabled || !isPaymentMethodsInputsVisible || isUpsertingGateway}
      >
        Próximo passo
      </NextStep>
    </S.Form>
  );
};

export default IntegrateForm;
