import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { SUGGESTION_API_DOMAIN } from '@constants/api';
import {
  IListAllSuggestionsProps,
  IListAllAutomationSuggestionsProps,
  IListAllSuggestionsResponse,
  IListAllAutomationSuggestionsResponse,
  ICreateSuggestionProps,
  IGetSuggestionProps,
  IGetSuggestionDetailsProps,
  IVoteUp,
  IVoteDown,
  IDelete,
} from '@domain/interfaces/dashboard/IdeasChannel/suggestions';

export class SuggestionsService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listAllSuggestions({
    startDate,
    endDate,
    page,
  }: IListAllSuggestionsProps): IListAllSuggestionsResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/suggestions?start_date=${startDate}&end_date=${endDate}&page=${
        page + 1
      }&limit=20`,
      this.apiService,
    );

    const isLoading = !error && !data;

    return {
      suggestions: data?.suggestions,
      totalPages: data?.total_pages,
      error,
      mutate,
      isLoading,
      isValidating,
    };
  }

  public listAllAutomationSuggestions({
    endDate,
    startDate,
  }: IListAllAutomationSuggestionsProps): IListAllAutomationSuggestionsResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/suggestions/automations?start_date=${startDate}&end_date=${endDate}`,
      this.apiService,
    );

    const isLoading = !error && !data;

    return {
      suggestions: data?.suggestions,
      error,
      mutate,
      isLoading,
      isValidating,
    };
  }

  public getSuggestion({ ideaAliasId }: IGetSuggestionProps): Promise<any> {
    return this.apiService.get(`/api/v1/users/suggestions/${ideaAliasId}`);
  }

  public getSuggestionDetail({ ideaAliasId }: IGetSuggestionDetailsProps): Promise<any> {
    return this.apiService.get(`/api/v1/users/suggestions/${ideaAliasId}/details`);
  }

  public createSuggestion({ data }: ICreateSuggestionProps): Promise<any> {
    return this.apiService.post('/api/v1/users/suggestions', {
      ...data,
    });
  }

  public votesUp({ ideaAliasId }: IVoteUp): Promise<any> {
    return this.apiService.post(`/api/v1/users/suggestions/${ideaAliasId}/vote-up`);
  }

  public votesDown({ ideaAliasId }: IVoteDown): Promise<any> {
    return this.apiService.post(`/api/v1/users/suggestions/${ideaAliasId}/vote-down`);
  }

  public deleteSuggestion({ ideaAliasId }: IDelete): Promise<any> {
    return this.apiService.delete(`/api/v1/users/suggestions/${ideaAliasId}`);
  }
}

const suggestionsService = new SuggestionsService(SUGGESTION_API_DOMAIN);

export default suggestionsService;
