import { IFilterConfig } from '@domain/interfaces/components/IFilter';
import {
  EProductAnalyticsProvider,
  EProductAnalyticsStatus,
} from '@domain/enums/common/EProductAnalitycs';
import { EFilterType } from '@domain/enums/components/EFilter';

export const PRODUCT_ANALYTICS_CONFIG: Array<IFilterConfig> = [
  {
    field: 'statuses',
    label: 'Status na plataforma',
    type: EFilterType.SELECT,
    config: {
      placeholder: 'Encontrar status no provedor...',
      data: [
        {
          value: EProductAnalyticsStatus.UNSPECIFIED,
          label: 'Não especificado',
        },
        {
          value: EProductAnalyticsStatus.UNKNOWN,
          label: 'Desconhecido',
        },
        {
          value: EProductAnalyticsStatus.ENABLED,
          label: 'Ativado',
        },
        {
          value: EProductAnalyticsStatus.PAUSED,
          label: 'Pausado',
        },
        {
          value: EProductAnalyticsStatus.REMOVED,
          label: 'Removido',
        },
        {
          value: EProductAnalyticsStatus.ARCHIVED,
          label: 'Arquivado',
        },
        {
          value: EProductAnalyticsStatus.IN_PROCESS,
          label: 'Em processamento',
        },
        {
          value: EProductAnalyticsStatus.WITH_ISSUES,
          label: 'Com problemas',
        },
      ],
    },
  },

  {
    field: 'providers',
    label: 'Plataforma',
    type: EFilterType.SELECT,
    config: {
      placeholder: 'Encontrar plataforma...',
      data: [
        {
          value: EProductAnalyticsProvider.FACEBOOK,
          label: 'Facebook',
        },
        {
          value: EProductAnalyticsProvider.GOOGLE,
          label: 'Google',
        },
        {
          value: EProductAnalyticsProvider.TABOOLA,
          label: 'Taboola',
        },
        {
          value: EProductAnalyticsProvider.TIKTOK,
          label: 'TikTok',
        },
      ],
    },
  },
  {
    field: 'is_active',
    label: 'Status',
    type: EFilterType.SELECT,
    config: {
      placeholder: 'Encontrar status...',
      data: [
        {
          value: true,
          label: 'Ativado',
        },
        {
          value: false,
          label: 'Desativado',
        },
      ],
    },
  },
];
