import React from 'react';

import { Hotmart as Icon, Copy } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { EAlertVariant } from '@domain/enums/components/EAlert';
import { IParams } from '@domain/interfaces/IParams';

import { useHistory, useParams } from 'react-router-dom';
import { useInfoProduct } from '@helpers/hooks/pages/onboarding/useInfoProduct';
import { useToast } from '@helpers/hooks/useToast';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';

import {
  FIRST_STEP_TUTORIAL,
  SECOND_STEP_TUTORIAL,
  WEBHOOK_STEP_TUTORIAL,
} from '@constants/hotmartTutorialSteps';

import { yupResolver } from '@hookform/resolvers/yup';
import { hotmartSchema } from '@helpers/validators/dashboard/integrationCenter/infoProduct/hotmart';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { useTheme } from 'styled-components/macro';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import hotmartService from '@services/pages/dashboard/integrationCenter/infoProduct/platforms/hotmart';

import NextStep from '@components/Onboarding/NextStep';
import Text from '@components/common/core/DataDisplay/Text';

import { EHotmartType } from '@domain/enums/common/integrations/providers/EHotmart';
import * as S from './styles';

const webhookURL = 'https://dosu-api.profitfy.me/prod/api/v1/webhooks/hotmart/events';

const Hotmart: React.FC = () => {
  const history = useHistory();
  const theme = useTheme();
  const { toast } = useToast();
  const { isLoadingTrial, handleStoreBonus } = useOnboarding();
  const { selectedMarketings } = useInfoProduct();
  const { storeAliasId } = useParams<IParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(hotmartSchema),
  });

  const [clientIdValue, setClientIdValue] = React.useState<string>('');
  const [clientSecretValue, setClientSecretValue] = React.useState<string>('');
  const [basicValue, setBasicValue] = React.useState<string>('');
  const [isCreatingCredential, setIsCreatingCredential] = React.useState<boolean>(false);
  const [providerId, setProviderId] = React.useState<string>('');
  const [isURLCopied, setIsURLCopied] = React.useState<boolean>(false);

  const clientIdInputRegister = register('client_id');
  const clientSecretInputRegister = register('client_secret');
  const basicInputRegister = register('basic');
  const hottokInputRegister = register('provider_id');

  const handleCopyURL = React.useCallback(() => {
    navigator.clipboard.writeText(webhookURL);
    setIsURLCopied(true);
    toast.success('URL copiada com sucesso.');
  }, [toast]);

  const onClientIdChange = React.useCallback(
    event => {
      clientIdInputRegister.onChange(event);

      setClientIdValue(event.target.value);
    },
    [clientIdInputRegister],
  );

  const onClientSecretChange = React.useCallback(
    event => {
      clientSecretInputRegister.onChange(event);

      setClientSecretValue(event.target.value);
    },
    [clientSecretInputRegister],
  );

  const onBasicChange = React.useCallback(
    event => {
      basicInputRegister.onChange(event);

      setBasicValue(event.target.value);
    },
    [basicInputRegister],
  );

  const onProviderIdChange = React.useCallback(
    event => {
      hottokInputRegister.onChange(event);

      setProviderId(event.target.value);
    },
    [hottokInputRegister],
  );

  const onSubmit = React.useCallback(
    async data => {
      setIsCreatingCredential(true);

      try {
        await hotmartService.createCredential({
          storeAliasId,
          data: {
            basic: data?.basic,
            client_id: data?.client_id,
            client_secret: data?.client_secret,
            provider_id: data?.provider_id,
            types: [EHotmartType.STORE],
          },
        });

        toast.success('Credencial criada com sucesso!');

        setIsCreatingCredential(false);

        if (selectedMarketings.length) {
          history.push(`/onboarding/${storeAliasId}/info-product/integrations/marketing`);
        } else {
          handleStoreBonus();
        }
      } catch (error: any) {
        toast.error(getTranslatedIntegrationsError(error));
        setIsCreatingCredential(false);
      }
    },
    [toast, storeAliasId, history, handleStoreBonus, selectedMarketings],
  );

  const { title: firstStepTitle, link: firstStepLink, steps: firstSteps } = FIRST_STEP_TUTORIAL;
  const { title: secondStepTitle, link: secondStepLink, steps: secondSteps } = SECOND_STEP_TUTORIAL;
  const {
    title: webhookStepTitle,
    link: webhookStepLink,
    steps: webhookSteps,
  } = WEBHOOK_STEP_TUTORIAL;

  const hasAllInputsValues =
    Boolean(clientIdValue) &&
    Boolean(clientSecretValue) &&
    Boolean(basicValue) &&
    Boolean(providerId);

  return (
    <S.Wrapper>
      <S.FormWrapper>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Header>
            <S.Title>Integre sua plataforma de infoproduto</S.Title>
          </S.Header>

          <S.IntegrationTitle>
            <Icon size={36} />

            <S.IntegrationTitleText fontWeight={EHeadingWeight.REGULAR}>
              Integração com Hotmart
            </S.IntegrationTitleText>
          </S.IntegrationTitle>

          <S.Alert variant={EAlertVariant.WARNING}>
            Atualmente a integração com a{' '}
            <strong>Hotmart suporta apenas pedidos que foram transacionados com Real (BRL)</strong>.{' '}
            <br /> <br /> Estamos estudando a possibilidade de expandir o serviço para as demais
            moedas.
          </S.Alert>

          <S.Body>
            <S.InputGroup>
              <S.Label>URL do webhook</S.Label>

              <S.WebhookURLWrapper onClick={handleCopyURL} type="button">
                <S.WebhookURL weight={ETextWeight.MEDIUM}>{webhookURL}</S.WebhookURL>

                <S.IconWrapper>
                  <Copy size={18} color={theme.colors.gray[1]} outline={!isURLCopied} />
                </S.IconWrapper>
              </S.WebhookURLWrapper>
            </S.InputGroup>

            <S.InputGroup>
              <S.Label htmlFor="client_id">Client ID</S.Label>
              <S.Input
                {...clientIdInputRegister}
                id="client_id"
                type="text"
                value={clientIdValue}
                placeholder="Hotmart Client ID"
                onChange={onClientIdChange}
                isError={errors.client_id}
              />
              {errors.client_id && <Text isErrorFeedback>{errors.client_id.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label htmlFor="client_secret">Client Secret</S.Label>
              <S.Input
                {...clientSecretInputRegister}
                id="client_secret"
                type="text"
                value={clientSecretValue}
                placeholder="Hotmart Client Secret"
                onChange={onClientSecretChange}
                isError={errors.client_secret}
              />
              {errors.client_secret && <Text isErrorFeedback>{errors.client_secret.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label htmlFor="basic">Basic</S.Label>
              <S.Input
                {...basicInputRegister}
                id="basic"
                type="text"
                value={basicValue}
                placeholder="Hotmart Basic"
                onChange={onBasicChange}
                isError={errors.basic}
              />
              {errors.basic && <Text isErrorFeedback>{errors.basic.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label htmlFor="provider_id">Hottok</S.Label>
              <S.Input
                {...hottokInputRegister}
                id="basic"
                type="text"
                value={providerId}
                placeholder="Hotmart Hottok"
                onChange={onProviderIdChange}
                isError={errors.provider_id}
              />
              {errors.provider_id && <Text isErrorFeedback>{errors.provider_id.message}</Text>}
            </S.InputGroup>
          </S.Body>

          <NextStep
            type="submit"
            isLoading={isCreatingCredential || isLoadingTrial}
            disabled={isCreatingCredential || isLoadingTrial || !hasAllInputsValues}
          >
            Próximo passo
          </NextStep>
        </S.Form>
      </S.FormWrapper>

      <S.TutorialWrapper>
        <S.TutorialImage
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/onboarding/images/content/onboarding/onboarding-integration-tutorial.svg"
          alt="Profitfy.me Integration Tutorial"
        />
        <S.TutotialTitle weight={ETextWeight.BOLD}>Tutorial de integração</S.TutotialTitle>

        <S.ContentTutorialWrapper>
          <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
            1 -{' '}
            <S.Link href={webhookStepLink} target="_blank">
              {webhookStepTitle}
            </S.Link>
          </S.TutotialStep>

          <S.StepsWrapper>
            {webhookSteps.map(step => {
              return (
                <S.StepWrapper key={step.id}>
                  <S.StepBullet />
                  <S.StepText size={ETextSize.B5}>{step.text}</S.StepText>
                </S.StepWrapper>
              );
            })}
          </S.StepsWrapper>

          <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
            2 -{' '}
            <S.Link href={firstStepLink} target="_blank">
              {firstStepTitle}
            </S.Link>
          </S.TutotialStep>

          <S.StepsWrapper>
            {firstSteps.map(step => {
              return (
                <S.StepWrapper key={step.id}>
                  <S.StepBullet />
                  <S.StepText size={ETextSize.B5}>{step.text}</S.StepText>
                </S.StepWrapper>
              );
            })}
          </S.StepsWrapper>

          <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
            3 -{' '}
            <S.Link href={secondStepLink} target="_blank">
              {secondStepTitle}
            </S.Link>
          </S.TutotialStep>

          <S.StepsWrapper>
            {secondSteps.map(step => {
              return (
                <S.StepWrapper key={step.id}>
                  <S.StepBullet />
                  <S.StepText size={ETextSize.B5}>{step.text}</S.StepText>
                </S.StepWrapper>
              );
            })}
          </S.StepsWrapper>
        </S.ContentTutorialWrapper>
      </S.TutorialWrapper>
    </S.Wrapper>
  );
};

export default Hotmart;
