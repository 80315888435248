import React from 'react';

import { useConfig } from '@helpers/hooks/useConfig';

import FirstForm from '@components/Auth/SignUp/FirstForm';

import * as S from './styles';

const SignUp: React.FC = () => {
  const { analytics } = useConfig();

  React.useEffect(() => {
    analytics?.page();
  }, [analytics]);

  return (
    <S.Container>
      <S.Container>
        <S.LeftSide>
          <S.ContentWrapper>
            <FirstForm />
          </S.ContentWrapper>
        </S.LeftSide>
      </S.Container>
    </S.Container>
  );
};

export default React.memo(SignUp);
