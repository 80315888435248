import React from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@helpers/hooks/useQuery';
import { useConfig } from '@helpers/hooks/useConfig';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { EPlanRecurrence } from '@domain/enums/subscription/ESubscription';
import { IParams } from '@domain/interfaces/IParams';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const query = useQuery();
  const { storeAliasId } = useParams<IParams>();
  const { analytics, user } = useConfig();

  const recurrenceTypeRef = query.get('recurrence_type');

  const onDashboardButtonClick = React.useCallback(() => {
    window.location.href = `${window.location.origin}/${storeAliasId}/dashboard/overview/financial`;
  }, [storeAliasId]);

  React.useEffect(() => {
    analytics?.track(
      'Subscription Success Page Viewed',
      {
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );
  }, [analytics, user, storeAliasId]);

  const isMonthlyRecurrence = recurrenceTypeRef === EPlanRecurrence.MONTHLY;
  const isPeriodRecurrence =
    recurrenceTypeRef === EPlanRecurrence.QUARTER ||
    recurrenceTypeRef === EPlanRecurrence.SEMIANUAL;

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        <S.Content>
          {isMonthlyRecurrence && (
            <S.Title type={EHeadingSize.H2} fontWeight={EHeadingWeight.REGULAR}>
              Seu pagamento está <mark>aprovado</mark>.
            </S.Title>
          )}

          {isPeriodRecurrence && (
            <S.Title type={EHeadingSize.H2} fontWeight={EHeadingWeight.REGULAR}>
              Seu pagamento está sendo <mark>processado</mark>.
            </S.Title>
          )}

          {isMonthlyRecurrence && (
            <S.Description>
              A sua assinatura agora está ativa. A renovação será feita de forma automática.
            </S.Description>
          )}

          {isPeriodRecurrence && (
            <S.Description>
              Quando a sua assinatura constar como paga, a renovação da sua assinatura será feita de
              forma automática.
            </S.Description>
          )}

          <S.Description>
            Você <strong>poderá acessar todos seus dados</strong> de cobrança e dados pessoais{' '}
            <strong>dentro do seu perfil</strong>.
          </S.Description>
          <S.ButtonsWrapper>
            <S.Button onClick={onDashboardButtonClick}>Ir para o Dashboard</S.Button>
            {/* <S.Button variant={EButtonVariant.SECONDARY_BUTTON}>Ir para meu perfil</S.Button> */}
          </S.ButtonsWrapper>
        </S.Content>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default Wrapper;
