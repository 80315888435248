import GoBack from '@components/common/core/Utils/GoBack';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { EProfitRange, EUserSegmentationStep } from '@domain/enums/onboarding/EUsersSegmentation';
import React from 'react';
import * as S from './styles';

interface ISecondStepProps {
  handleSecondAnswer: (answer: string) => void;
  selectedAnswer: string;
  goBack: (backStep: EUserSegmentationStep) => void;
}

const SecondStep: React.FC<ISecondStepProps> = ({ handleSecondAnswer, selectedAnswer, goBack }) => {
  const onRadioChange = React.useCallback(
    event => {
      handleSecondAnswer(event.target.value);
    },
    [handleSecondAnswer],
  );

  const handleGoBack = React.useCallback(() => goBack(EUserSegmentationStep.FIRST_STEP), [goBack]);

  return (
    <>
      <GoBack
        text="Voltar"
        onClick={handleGoBack}
        size={16}
        type={EHeadingSize.H6}
        fontWeight={EHeadingWeight.REGULAR}
      />

      <S.Question>Qual a faixa de faturamento mensal da sua empresa?</S.Question>

      <S.RadioGroup>
        <S.RadioLabel checked={selectedAnswer === EProfitRange.LESS_THAN_10_THOUSAND}>
          <S.Radio
            name="profit_range"
            value={EProfitRange.LESS_THAN_10_THOUSAND}
            onChange={onRadioChange}
            id={EProfitRange.LESS_THAN_10_THOUSAND}
            defaultChecked={selectedAnswer === EProfitRange.LESS_THAN_10_THOUSAND}
          />
          <S.RadioText>Menos de 10 mil reais</S.RadioText>
        </S.RadioLabel>

        <S.RadioLabel checked={selectedAnswer === EProfitRange.BETWEEN_10_50_THOUSAND}>
          <S.Radio
            name="profit_range"
            value={EProfitRange.BETWEEN_10_50_THOUSAND}
            onChange={onRadioChange}
            id={EProfitRange.BETWEEN_10_50_THOUSAND}
            defaultChecked={selectedAnswer === EProfitRange.BETWEEN_10_50_THOUSAND}
          />
          <S.RadioText>Entre 10 mil e 50 mil reais</S.RadioText>
        </S.RadioLabel>

        <S.RadioLabel checked={selectedAnswer === EProfitRange.BETWEEN_50_100_THOUSAND}>
          <S.Radio
            name="profit_range"
            value={EProfitRange.BETWEEN_50_100_THOUSAND}
            onChange={onRadioChange}
            id={EProfitRange.BETWEEN_50_100_THOUSAND}
            defaultChecked={selectedAnswer === EProfitRange.BETWEEN_50_100_THOUSAND}
          />
          <S.RadioText>Entre 50 mil e 100 mil</S.RadioText>
        </S.RadioLabel>

        <S.RadioLabel checked={selectedAnswer === EProfitRange.BETWEEN_100_250_THOUSAND}>
          <S.Radio
            name="profit_range"
            value={EProfitRange.BETWEEN_100_250_THOUSAND}
            onChange={onRadioChange}
            id={EProfitRange.BETWEEN_100_250_THOUSAND}
            defaultChecked={selectedAnswer === EProfitRange.BETWEEN_100_250_THOUSAND}
          />
          <S.RadioText>Entre 100 mil e 250 mil</S.RadioText>
        </S.RadioLabel>

        <S.RadioLabel checked={selectedAnswer === EProfitRange.BETWEEN_250_500_THOUSAND}>
          <S.Radio
            name="profit_range"
            value={EProfitRange.BETWEEN_250_500_THOUSAND}
            onChange={onRadioChange}
            id={EProfitRange.BETWEEN_250_500_THOUSAND}
            defaultChecked={selectedAnswer === EProfitRange.BETWEEN_250_500_THOUSAND}
          />
          <S.RadioText>Entre 250 mil e 500 mil</S.RadioText>
        </S.RadioLabel>

        <S.RadioLabel checked={selectedAnswer === EProfitRange.MORE_THAN_500_THOUSAND}>
          <S.Radio
            name="profit_range"
            value={EProfitRange.MORE_THAN_500_THOUSAND}
            onChange={onRadioChange}
            id={EProfitRange.MORE_THAN_500_THOUSAND}
            defaultChecked={selectedAnswer === EProfitRange.MORE_THAN_500_THOUSAND}
          />
          <S.RadioText>Mais de 500 mil reais</S.RadioText>
        </S.RadioLabel>

        <S.RadioLabel checked={selectedAnswer === EProfitRange.NOT_INFORM}>
          <S.Radio
            name="profit_range"
            value={EProfitRange.NOT_INFORM}
            onChange={onRadioChange}
            id={EProfitRange.NOT_INFORM}
            defaultChecked={selectedAnswer === EProfitRange.NOT_INFORM}
          />
          <S.RadioText>Prefiro não informar</S.RadioText>
        </S.RadioLabel>
      </S.RadioGroup>
    </>
  );
};

export default SecondStep;
