import React from 'react';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { IDescriptionProps } from '@domain/interfaces/dashboard/IdeasChannel/suggestions';

import * as S from './styles';

const Description: React.FC<IDescriptionProps> = ({ suggestions }) => {
  return (
    <S.DescriptionWrapper to={`ideas/details/${suggestions.id}`}>
      <S.Title type={EHeadingSize.H5}>{suggestions.title}</S.Title>
      <S.DescriptionText>{suggestions.description}</S.DescriptionText>
    </S.DescriptionWrapper>
  );
};

export default Description;
