import React from 'react';
import { Warning } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import { useSubscriptionPlans } from '@helpers/hooks/pages/dashboard/subscription/useSubscriptionPlans';
import { useSubscription } from '@helpers/hooks/common/store/subscription/useSubscription';

import * as S from './styles';

const UpgradingModal: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { isUpgradingModalOpen, handleUpgradingModalOpen } = useSubscriptionPlans();
  const { selectedPlanIdentifier } = useSubscription();

  const handleUpgradeConfirm = React.useCallback(() => {
    history.push(`/${storeAliasId}/subscription/address?identifier=${selectedPlanIdentifier}`);
  }, [history, storeAliasId, selectedPlanIdentifier]);

  return (
    <S.Modal isOpen={isUpgradingModalOpen} toggle={handleUpgradingModalOpen}>
      <S.Modal.Header>
        <Warning size={24} color={theme.colors.success.default} outline />
        Você está prestes a subir de plano!
      </S.Modal.Header>
      <S.Modal.Body>
        <S.Text>
          Ao clicar em confirmar, iremos te dar um <strong>desconto</strong> pelo
          <strong> período pago do plano atual </strong> não usufruído e renovaremos a data de
          expiração para os próximos <strong>30 dias.</strong>
        </S.Text>

        <S.ButtonsWrapper>
          <S.CancelButton onClick={handleUpgradingModalOpen}>Cancelar</S.CancelButton>
          <S.ConfirmButton onClick={handleUpgradeConfirm}>Confirmar</S.ConfirmButton>
        </S.ButtonsWrapper>
      </S.Modal.Body>
    </S.Modal>
  );
};

export default UpgradingModal;
