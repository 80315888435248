import React from 'react';

import { SimplePercent } from '@profitfy/pakkun-icons';
import { ETextWeight } from '@domain/enums/components/EText';
import { IConfigShippingModalProps } from '@domain/interfaces/dashboard/Shipping/IConfigShippingModal';
import { EShippingAmountType } from '@domain/enums/dashboard/shipping/EShipping';
import { currencyFormatter } from '@helpers/masks';
import { useShipping } from '@helpers/hooks/pages/dashboard/shipping/useShipping';

import * as S from './styles';

const ConfigShippingModal: React.FC<IConfigShippingModalProps> = ({ isOpen, toggle }) => {
  const {
    handleShippingAmountType,
    amountType,
    handleShippingAmount,
    shippingAmount,
  } = useShipping();

  const [amount, setAmount] = React.useState<string>('');

  const onChange = React.useCallback(event => {
    const formattedAmount = currencyFormatter(event.target.value);

    setAmount(formattedAmount);
  }, []);

  const onSelectChange = React.useCallback(
    event => {
      handleShippingAmountType(event.target.value);
    },
    [handleShippingAmountType],
  );

  const onConfirm = React.useCallback(() => {
    handleShippingAmount(amount);
    toggle();
  }, [handleShippingAmount, amount, toggle]);

  React.useEffect(() => {
    if (shippingAmount) {
      setAmount(shippingAmount);
    }
  }, [shippingAmount]);

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.HeadingWrapper>
        <SimplePercent size={18} />
        <S.Heading weight={ETextWeight.MEDIUM}>Configuração de frete médio</S.Heading>
      </S.HeadingWrapper>

      <S.InputGroup>
        <S.Label>Tipo</S.Label>

        <S.Select name="amount-type" onChange={onSelectChange} value={amountType}>
          <S.Option value={EShippingAmountType.FIXED}>Fixo</S.Option>
          <S.Option value={EShippingAmountType.PERCENTAGE}>Porcentagem</S.Option>
        </S.Select>
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>Valor de frete por venda concluída</S.Label>
        <S.Input
          type="text"
          value={amount}
          noFeedbackError
          onChange={onChange}
          placeholder="Digite o valor do frete"
          prependText={amountType === EShippingAmountType.FIXED ? 'R$' : '%'}
        />
      </S.InputGroup>

      <S.ButtonsWrapper>
        <S.CancelButton onClick={toggle}>Cancelar</S.CancelButton>
        <S.ConfirmButton onClick={onConfirm}>Confirmar</S.ConfirmButton>
      </S.ButtonsWrapper>
    </S.Modal>
  );
};

export default ConfigShippingModal;
