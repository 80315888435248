import React from 'react';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';
import { IEvermartCredentialOptionProps } from '@domain/interfaces/dashboard/IntegrationCenter/InfoProduct/IEvermart';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useToast } from '@helpers/hooks/useToast';
import { useParams } from 'react-router-dom';

import evermartService from '@services/pages/dashboard/integrationCenter/infoProduct/platforms/evermartAuth';

import DisableIntegrationFooter from '@components/Dashboard/IntegrationCenter/Sidemodal/DisableIntegrationFooter';

import DeleteCredentialModal from '@components/Dashboard/IntegrationCenter/SideModalContent/DeleteCredentialModal';
import * as S from './styles';

const CredentialOption: React.FC<IEvermartCredentialOptionProps> = ({ credentialAliasId }) => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { mutateStore } = useStoreConfig();

  const [isDeletingCredential, setIsDeletingCredential] = React.useState<boolean>(false);
  const [isDeleteCredentialModalOpen, setIsDeleteCredentialModalOpen] = React.useState<boolean>(
    false,
  );

  const handleDeleteCredentialModalOpen = React.useCallback(
    () => setIsDeleteCredentialModalOpen(state => !state),
    [],
  );

  const onDeleteCredential = React.useCallback(async () => {
    setIsDeletingCredential(true);

    try {
      await evermartService.updateCredential({
        storeAliasId,
        data: { is_active: false },
        credentialAliasId,
      });

      toast.success('Credencial Ativada com sucesso!');
      handleDeleteCredentialModalOpen();

      await mutateStore();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsDeletingCredential(false);
    }
  }, [toast, mutateStore, storeAliasId, credentialAliasId, handleDeleteCredentialModalOpen]);

  return (
    <S.Content>
      <S.CredentialDescriptionWrapper>
        <S.CredentialDescriptionTitle type={EHeadingSize.H5}>
          O que acontece se eu desativar minha integração?
        </S.CredentialDescriptionTitle>
        <S.CredentialDescriptionText>
          Ao desativar a integração,
          <strong> não será mais possível </strong>
          processar os pedidos da Evermart.
        </S.CredentialDescriptionText>
      </S.CredentialDescriptionWrapper>

      <DisableIntegrationFooter
        onDeleteCredential={handleDeleteCredentialModalOpen}
        isDeletingCredential={isDeletingCredential}
      />

      <DeleteCredentialModal
        isOpen={isDeleteCredentialModalOpen}
        toggle={handleDeleteCredentialModalOpen}
        onDeleteCredential={onDeleteCredential}
        isLoading={isDeletingCredential}
      />
    </S.Content>
  );
};

export default CredentialOption;
