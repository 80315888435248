import { ApiService } from '@services/api';
import { LONG_API_DOMAIN } from '@constants/api';
import { IGenerateTikTokURLProps } from '@domain/interfaces/dashboard/AdSense/TikTok/ITikTokCredential';

export class TikTokCredentialService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public generateUrl({ storeAliasId }: IGenerateTikTokURLProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/tiktok/auth/generate-url`);
  }
}

const tikTokCredentialService = new TikTokCredentialService(LONG_API_DOMAIN);

export default tikTokCredentialService;
