import React from 'react';

import { EProgressBarStep } from '@domain/enums/onboarding/progressBar/ESteps';

import Wrapper from '@components/Onboarding/InfoProduct/Integrations/Wrapper';
import SideBar from '@components/Onboarding/SideBar';

import * as S from './styles';

const Integrations: React.FC = () => {
  return (
    <S.Wrapper>
      <SideBar onboardingStep={EProgressBarStep.INTEGRATIONS} linkToGoBack="" />

      <Wrapper />
    </S.Wrapper>
  );
};

export default Integrations;
