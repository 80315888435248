import styled from 'styled-components/macro';
import TooltipBase from 'rc-tooltip';

export const Tooltip = styled(TooltipBase)``;

export const Wrapper = styled.div`
  .rc-tooltip {
    opacity: 1;

    .rc-tooltip-content {
      .rc-tooltip-inner {
        min-height: unset;
        padding: 12px 16px;
        color: ${({ theme }) => theme.colors.font};
        font-family: ${({ theme }) => theme.fonts.body};
        font-size: 0.875rem;
        background-color: ${({ theme }) => theme.colors.gray[7]};
        border: unset;
        height: unset;
        border-radius: 7px;
      }
    }
  }

  .rc-tooltip-placement-top {
    .rc-tooltip-arrow {
      border-top-color: ${({ theme }) => theme.colors.gray[7]};
    }
  }

  .rc-tooltip-placement-bottom {
    .rc-tooltip-arrow {
      border-bottom-color: ${({ theme }) => theme.colors.gray[7]};
    }
  }

  .rc-tooltip-placement-left {
    .rc-tooltip-arrow {
      border-left-color: ${({ theme }) => theme.colors.gray[7]};
    }
  }

  .rc-tooltip-placement-right {
    .rc-tooltip-arrow {
      border-right-color: ${({ theme }) => theme.colors.gray[7]};
    }
  }
`;
