import { EButtonVariant } from '@domain/enums/components/EButton';
import React from 'react';

import { ITestSideModalSaveProps } from '@domain/interfaces/dashboard/Automations/TestSideModal/ITestSideModal';
import * as S from './styles';

const Save: React.FC<ITestSideModalSaveProps> = ({
  onCancel,
  isLastStep = false,
  isSavingTestAutomation,
}) => {
  return (
    <S.Wrapper>
      <S.ButtonsWrapper>
        <S.CancelButton onClick={onCancel} variant={EButtonVariant.TERTIARY}>
          Cancelar
        </S.CancelButton>

        <S.SaveButton
          type="submit"
          isLoading={isSavingTestAutomation}
          disabled={isSavingTestAutomation}
        >
          {isLastStep ? 'Testar automação' : 'Próximo passo'}
        </S.SaveButton>
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export default Save;
