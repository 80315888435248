import React from 'react';
import { Download } from 'phosphor-react';

import * as S from './styles';

const TermsReader: React.FC = () => {
  const onDownloadButtonClick = React.useCallback(() => {
    window.open(
      'https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/terms/Termos+Profitfy+%26+Bequest.pdf',
      '_blank',
    );
  }, []);

  return (
    <S.Wrapper>
      <S.DownloadButton onClick={onDownloadButtonClick}>
        <Download size={20} color="#fff" weight="bold" />
      </S.DownloadButton>

      <S.Title>Termos de Adesão</S.Title>

      <S.Paragraph>Ao aceitar este termo, declaro que:</S.Paragraph>

      <S.Paragraph>
        1. Concordo com o compartilhamento de todos os dados de minha operação com a Bequest Digital
        LTDA. (&quot;BEQUEST&quot;), inscrita no CNPJ/ME sob nº 48.100.082/0001-72, incluindo, mas
        não se limitando ao volume de vendas, valores investidos em anúncios, lucros, dados de
        produtos, custos operacionais e os demais dados presentes na plataforma da Profitfy, com
        exceção de dados pessoais;
      </S.Paragraph>

      <S.Paragraph>
        2. Autorizo a BEQUEST a utilizar essas informações para análise e diagnóstico da minha
        operação, com a finalidade de melhorar meus resultados e minha performance em vendas e
        publicidade;
      </S.Paragraph>
      <S.Paragraph>
        3. Estou ciente de que, na hipótese de intenção de revogação desta autorização de acesso aos
        meus dados, precisarei contatar o time da Profitfy e perderei os benefícios de análise da
        minha operação pela BEQUEST;
      </S.Paragraph>
      <S.Paragraph>
        4. Li e compreendi todas as cláusulas aqui presentes, bem como concordo com os termos e
        condições nele estabelecidos.
      </S.Paragraph>
      <S.Paragraph>
        O presente termo tem validade a partir da data de aceitação pelo Usuário e permanecerá
        válido enquanto perdurar a relação comercial entre o Usuário e a BEQUEST, ou até a
        solicitação de revogação da autorização de acesso pelo Usuário à Profitfy, o que ocorrer
        primeiro.
      </S.Paragraph>

      <S.Subtitle>Estes Termos foram atualizados em 03 de junho de 2024.</S.Subtitle>
    </S.Wrapper>
  );
};

export default TermsReader;
