import React from 'react';

import { ISaveProps } from '@domain/interfaces/dashboard/CustomValuesCategories/ISave';
import { useCustomValuesCategories } from '@helpers/hooks/pages/dashboard/customValues/useCustomValuesCategories';

import * as S from './styles';

const SaveButton: React.FC<ISaveProps> = ({ onCancel, onSave }) => {
  const { isCreatingNewCustomValuesCategory } = useCustomValuesCategories();

  return (
    <S.SaveButtonWrapper>
      <S.CancelButton onClick={onCancel}>Cancelar</S.CancelButton>
      <S.Button
        onClick={onSave}
        isLoading={isCreatingNewCustomValuesCategory}
        disabled={isCreatingNewCustomValuesCategory}
      >
        Salvar
      </S.Button>
    </S.SaveButtonWrapper>
  );
};

export default SaveButton;
