import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DASHBOARD_DOMAIN } from '@constants/api';

import {
  IGetProductFinancialProps,
  IGetProductFinancialResponse,
} from '@domain/interfaces/dashboard/ProductAnalytics/IProductFinancial';

export class ProductFinancialService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getProductFinancial({
    storeAliasId,
    productAliasId,
    startDate,
    endDate,
  }: IGetProductFinancialProps): IGetProductFinancialResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/dashboards/financial?start_date=${startDate}&end_date=${endDate}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      product: data?.product,
      productFinancialDetailsReport: data?.financial_details_report,
      productFinancialReport: data?.financial_report,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }
}

const productFinancialService = new ProductFinancialService(API_DASHBOARD_DOMAIN);

export default productFinancialService;
