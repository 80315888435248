import React from 'react';

import { IProductOptionFilterProps } from '@domain/interfaces/components/IFilter';

import * as S from './styles';

const Product: React.FC<IProductOptionFilterProps> = ({ product, handleOptionClick }) => {
  const [isImageError, setIsImageError] = React.useState<boolean>(false);

  return (
    <S.OptionWrapper key={product.alias_id} onClick={() => handleOptionClick(product)}>
      {product.image_url && !isImageError ? (
        <S.ProductImage
          src={product.image_url}
          alt="Profitfy Product"
          onError={() => setIsImageError(true)}
        />
      ) : (
        <S.NoImage />
      )}

      <S.Tooltip>
        <S.TooltipTrigger>{product.name}</S.TooltipTrigger>

        <S.Tooltip.Content side="top" sideOffset={5}>
          <S.TooltipText>{product.name}</S.TooltipText>
          <S.Tooltip.Arrow />
        </S.Tooltip.Content>
      </S.Tooltip>
    </S.OptionWrapper>
  );
};

export default Product;
