import styled from 'styled-components/macro';

export const RefreshButton = styled.button`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.firstLayer};
  border: unset;
  padding: unset;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 14px;
  right: 14px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  & > svg {
    margin-top: 1px;
    margin-left: 2px;
  }
`;
