import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { IAdSenseCampaign } from '@domain/interfaces/dashboard/AdSense/IAdSenseCampaign';
import { IStoreIntegrations } from '@domain/interfaces/dashboard/IntegrationCenter/IIntegrationCenter';

import { ADS_PROVIDERS } from '@constants/common/integrations';

export const hasActiveAdSenseCampaign = (adSenseCampaigns: Array<IAdSenseCampaign>): boolean => {
  return adSenseCampaigns.some(adSenseCampaign => adSenseCampaign.is_active);
};

export const hasIntegration = (
  storeIntegrations: IStoreIntegrations,
  provider: EAdSenseProvider,
): boolean => {
  const foundIntegrationObject = ADS_PROVIDERS.find(
    integration => integration.identifier === provider,
  );

  const storeMarketingIntegrations = storeIntegrations.marketing;

  if (foundIntegrationObject) {
    return storeMarketingIntegrations[foundIntegrationObject?.idToVerifyStatus];
  }

  return false;
};
