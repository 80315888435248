import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresCardConversionValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresCardConversionValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresCardConversionValuesResponse => {
  const currentSelectedStoreCardConversionValue =
    currentSelectedStoreFinancialDetailsReportData?.card?.conversion;

  const storesCardConversionValues = selectedStoresData?.map(
    value => value.financial_details_report?.card?.conversion,
  );

  const cardConversionAmountValues = [
    ...storesCardConversionValues,
    currentSelectedStoreCardConversionValue,
  ];

  const reducedCardConversionValues = cardConversionAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const currentSelectedStore = 1;
  const selectedStoresQuantity = selectedStoresData?.length;

  const storesQuantity = selectedStoresQuantity + currentSelectedStore;

  const finalResult = reducedCardConversionValues / storesQuantity;

  return {
    card_conversion: finalResult,
  };
};
