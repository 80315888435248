/* eslint-disable no-eval */
import React from 'react';

import {
  ISynchronization,
  ISynchronizationProvider,
  INewSynchronization,
} from '@domain/interfaces/hooks/IStoreSynchronization';
import { ESynchronizationStatus } from '@domain/enums/hooks/ESynchronization';

import { getEnvironment } from '@helpers/utils/environment';
import {
  buildSynchronizationsPromises,
  getNewSynchronizationsQueue,
  handleCurrentSynchronizationsStatuses,
  handleSynchronizationsInQueueToBeAddedToSync,
  waitForNextSync,
} from '@helpers/utils/hooks/common/synchronization';

import { useLocalStorage } from '../useLocalStorage';

const SynchronizationContext = React.createContext<ISynchronizationProvider | null>(null);

export const SynchronizationProvider: React.FC = ({ children }) => {
  const DURATION_TO_NEXT_SYNC = 10000;

  const [resyncData, setResyncData] = useLocalStorage(
    `@profitfy:${getEnvironment()}/resync-data`,
    null,
  );
  const [currentSynchronizations, setCurrentSynchronizations]: any = useLocalStorage(
    `@profitfy:${getEnvironment()}/synchronizations`,
    null,
  );
  const [synchronizationsQueue, setSynchronizationsQueue]: any = useLocalStorage(
    `@profitfy:${getEnvironment()}/synchronizationsInQueue`,
    [],
  );
  const [finishedSynchronizations, setFinishedSynchronizations] = React.useState<
    Array<ISynchronization>
  >([]);
  const [failedSynchronizations, setFailedSynchronizations] = React.useState<
    Array<ISynchronization>
  >([]);

  const handleFinishedSynchronizations = React.useCallback(newFinishedSynchronizations => {
    setFinishedSynchronizations(newFinishedSynchronizations);
  }, []);

  const handleFailedSynchronizations = React.useCallback(newFailedSynchronizations => {
    setFailedSynchronizations(newFailedSynchronizations);
  }, []);

  const handleResyncData = React.useCallback(newResyncData => setResyncData(newResyncData), [
    setResyncData,
  ]);

  const manageSynchronizationsJob = React.useCallback(
    async (ping: string | undefined, updatedCurrentSynchronizations: Array<ISynchronization>) => {
      const rawSynchronizationsInQueue = localStorage.getItem(
        `@profitfy:${getEnvironment()}/synchronizationsInQueue`,
      );
      // const rawCurrentSynchronizations = localStorage.getItem(
      //   `@profitfy:${getEnvironment()}/synchronizations`,
      // );
      const currentSynchronizationsToAdd: Array<ISynchronization> = [];
      const finishedSynchronizationsToAdd: Array<ISynchronization> = [];
      const failedSynchronizationsToAdd: Array<ISynchronization> = [];

      if (rawSynchronizationsInQueue) {
        const synchronizationsInQueue: Array<ISynchronization> = JSON.parse(
          rawSynchronizationsInQueue,
        );
        // const activeSynchronizations: ISynchronizations | null = rawCurrentSynchronizations
        //   ? JSON.parse(rawCurrentSynchronizations)
        //   : null;

        handleCurrentSynchronizationsStatuses(
          updatedCurrentSynchronizations,
          currentSynchronizationsToAdd,
          finishedSynchronizationsToAdd,
          failedSynchronizationsToAdd,
        );

        handleSynchronizationsInQueueToBeAddedToSync(
          synchronizationsInQueue,
          currentSynchronizationsToAdd,
        );

        const newSynchronizationsQueue = getNewSynchronizationsQueue(
          synchronizationsInQueue,
          currentSynchronizationsToAdd,
        );

        if (currentSynchronizationsToAdd.length) {
          setCurrentSynchronizations({
            ping,
            synchronizations: currentSynchronizationsToAdd,
          });
        } else {
          setCurrentSynchronizations(null);
        }

        setSynchronizationsQueue(newSynchronizationsQueue);
        setFinishedSynchronizations(previousState => [
          ...previousState,
          ...finishedSynchronizationsToAdd,
        ]);
        setFailedSynchronizations(previousState => [
          ...previousState,
          ...failedSynchronizationsToAdd,
        ]);
      }
    },
    [setCurrentSynchronizations, setSynchronizationsQueue],
  );

  const runCurrentSynchronizations = React.useCallback(async () => {
    await waitForNextSync(currentSynchronizations, DURATION_TO_NEXT_SYNC);

    const synchronizations = currentSynchronizations?.synchronizations.filter(
      (synchronization: any) => {
        if (getEnvironment() === 'prod') {
          const hasDevRequest = synchronization.request.api.includes('/dev/');

          if (hasDevRequest) return false;
        }

        return true;
      },
    );

    const synchronizationsPromises = buildSynchronizationsPromises(synchronizations);

    const synchronizationsPromisesResponses = await Promise.all(
      synchronizationsPromises.map(synchronizationPromise =>
        synchronizationPromise
          .then(response => response)
          .catch(error => {
            if (error.response) {
              return error.response;
            }

            return {
              status: 469,
            };
          }),
      ),
    );

    const mappedResponses = synchronizations.map(
      (synchronization: ISynchronization, index: number): ISynchronization => {
        const promiseResponse = synchronizationsPromisesResponses[index];

        if (promiseResponse.status < 400) {
          const { data } = promiseResponse;

          return {
            ...synchronization,
            id: data.synchronization.id,
            status: data.synchronization.status,
            externalCreatedAt: data.synchronization.created_at,
            externalUpdatedAt: data.synchronization.updated_at,
            progressPercentage: data.synchronization.progress_percentage,
            variables: data.synchronization.variables,
          };
        }

        return {
          ...synchronization,
          id: 'error',
          status: ESynchronizationStatus.ERROR,
          externalCreatedAt: new Date().toISOString(),
          externalUpdatedAt: new Date().toISOString(),
          progressPercentage: 0,
          variables: [],
        };
      },
    );

    if (mappedResponses.length) {
      manageSynchronizationsJob(new Date().toISOString(), mappedResponses);
    } else {
      manageSynchronizationsJob(new Date().toISOString(), []);
    }
  }, [currentSynchronizations, manageSynchronizationsJob]);

  const addSynchronization = React.useCallback(
    (newSynchronization: INewSynchronization) => {
      const parsedNewSynchronization: ISynchronization = {
        ...newSynchronization,
        createdAt: new Date().toISOString(),
        status: ESynchronizationStatus.IN_QUEUE,
        callback: newSynchronization.callback ? newSynchronization.callback.toString() : undefined,
        progressPercentage: 0,
        variables: [],
      };

      setSynchronizationsQueue((previousState: Array<ISynchronization>) => {
        return [...previousState, parsedNewSynchronization];
      });
    },
    [setSynchronizationsQueue],
  );

  React.useEffect(() => {
    if (currentSynchronizations) {
      runCurrentSynchronizations();
    }
  }, [currentSynchronizations, runCurrentSynchronizations]);

  React.useEffect(() => {
    const hasNoCurrentSynchronizationsAndQueueIsNotEmpty =
      !currentSynchronizations && synchronizationsQueue.length;

    if (hasNoCurrentSynchronizationsAndQueueIsNotEmpty) {
      const lastPing = undefined;
      const updatedCurrentSynchronizations: Array<ISynchronization> = [];

      manageSynchronizationsJob(lastPing, updatedCurrentSynchronizations);
    }
  }, [currentSynchronizations, synchronizationsQueue, manageSynchronizationsJob]);

  return (
    <SynchronizationContext.Provider
      value={{
        addSynchronization,
        currentSynchronizations,
        failedSynchronizations,
        finishedSynchronizations,
        synchronizationsQueue,
        handleFinishedSynchronizations,
        handleFailedSynchronizations,
        resyncData,
        handleResyncData,
      }}
    >
      {children}
    </SynchronizationContext.Provider>
  );
};

export const useSynchronization = (): ISynchronizationProvider => {
  const context = React.useContext(SynchronizationContext);

  if (!context) {
    throw new Error('useSynchronization must be used within Synchronizationprovider');
  }

  return context;
};
