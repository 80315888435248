import styled from 'styled-components/macro';

export const AddedOptionsWrapper = styled.div`
  width: 100%;
  height: auto;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 32px;
  box-sizing: border-box;
  margin-top: 25px;

  scrollbar-color: ${({ theme }) => theme.colors.gray[4]} ${({ theme }) => theme.colors.gray[6]};
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[4]};
  }
`;
