import styled, { css } from 'styled-components/macro';

import { INavigationTextProps } from '@domain/interfaces/components/IBottomNavigation';

export const NavigationText = styled.span<INavigationTextProps>`
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.gray[3]};
  font-size: 12px;

  ${({ isIconFilled }) =>
    isIconFilled &&
    css`
      color: ${({ theme }) => theme.colors.green.default};
    `}
`;

export const NavigationButton = styled.button`
  width: 64px;
  height: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  padding: 0;
  gap: 4px;
  transition: all 0.3s;
`;
