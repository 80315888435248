import styled, { css } from 'styled-components/macro';

import { IParagraphProps } from '@domain/interfaces/components/IParagraph';

import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const DownloadButton = styled.button`
  position: absolute;
  top: 16px;
  left: 16px;
  width: 32px;
  height: 32px;
  border: unset;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  box-shadow: 0px 1px 14px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Subtitle = styled(HeadingBase)`
  font-weight: 600;
  font-size: 0.875rem;
  margin-top: 32px;
  margin-bottom: 16px;
  text-decoration: underline;
`;

export const Paragraph = styled.p<IParagraphProps>`
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.font};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 150%;

  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `}

  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}
`;

export const Title = styled(HeadingBase)`
  font-size: 1rem;
  font-weight: 400;
  text-decoration: underline;
  text-transform: uppercase;
  text-align: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 32px 16px 16px 16px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 12px;
  max-height: 324px;
  overflow-y: scroll;
  position: relative;

  ::-webkit-scrollbar {
    width: 4px;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    position: absolute;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[5]};
    border-radius: 25px;
    transition: all 0.3s;
  }

  ::-webkit-scrollbar-thumb:hover {
    opacity: brightness(0.8);
  }
`;
