import React from 'react';

const useLocalStorage = (key: string, initialValue: any): any => {
  const [storedValue, setStoredValue] = React.useState(() => {
    const value = localStorage.getItem(key);

    if (value) {
      return JSON.parse(value);
    }

    return initialValue;
  });

  React.useEffect(() => {
    localStorage.setItem(key, JSON.stringify(storedValue));
  });

  return [storedValue, setStoredValue];
};

const getAccessToken = (): any => localStorage.getItem('jwt');

export { useLocalStorage, getAccessToken };
