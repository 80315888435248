import React from 'react';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  const commonStyles = {
    borderRadius: 5,
    lineHeight: 'unset',
  };

  return (
    <S.Wrapper>
      <S.Heading>
        <Skeleton width="100%" height={22} style={commonStyles} />
      </S.Heading>

      <S.Tab>
        <Skeleton width="100%" height={31} style={commonStyles} />
      </S.Tab>

      <S.InputGroup>
        <S.Label>
          <Skeleton width="100%" height={16} style={{ ...commonStyles, marginBottom: 10 }} />
        </S.Label>

        <S.Input>
          <Skeleton width="100%" height={42} style={commonStyles} />
        </S.Input>
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>
          <Skeleton width="100%" height={16} style={{ ...commonStyles, marginBottom: 16 }} />
        </S.Label>

        <S.CheckboxWrapper>
          <S.CheckBox>
            <Skeleton width="100%" height={27} style={commonStyles} />
          </S.CheckBox>

          <S.CheckBox>
            <Skeleton width="100%" height={27} style={commonStyles} />
          </S.CheckBox>

          <S.CheckBox>
            <Skeleton width="100%" height={27} style={commonStyles} />
          </S.CheckBox>
        </S.CheckboxWrapper>
      </S.InputGroup>
    </S.Wrapper>
  );
};

export default SkeletonLoading;
