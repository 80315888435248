import queryString from 'query-string';
import { AxiosResponse } from 'axios';

import {
  IListProductsPromiseResponse,
  IListProductsProps,
  IListProductsResponse,
} from '@domain/interfaces/common/product/IProduct';

import { useFetch } from '@providers/Fetch/useFetch';

import { ApiService } from '@services/api';

import { API_DOMAIN } from '@constants/api';

export class ProductService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listProducts({
    storeAliasId,
    page,
    startDate,
    endDate,
    filter,
  }: IListProductsProps): IListProductsResponse {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/products?limit=10&page=${
        page + 1
      }&start_date=${startDate}&end_date=${endDate}&${queryStringFilter}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      totalPages: data?.total_pages,
      products: data?.products,
      mutate,
      error,
      isValidating,
      isLoading,
    };
  }

  public listProductsPromise({
    storeAliasId,
    page,
    startDate,
    endDate,
    filter,
  }: IListProductsProps): Promise<AxiosResponse<IListProductsPromiseResponse>> {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    return this.apiService.get<IListProductsPromiseResponse>(
      `/api/v1/users/stores/${storeAliasId}/products?limit=10&page=${
        page + 1
      }&start_date=${startDate}&end_date=${endDate}&${queryStringFilter}`,
    );
  }
}

const productService = new ProductService(API_DOMAIN);

export default productService;
