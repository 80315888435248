import React from 'react';

import { ETextType } from '@domain/enums/components/EText';
import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

interface IHoverCardProps {
  amount: number;
  quantity: number;
}

const HoverCard: React.FC<IHoverCardProps> = ({ children, amount, quantity }) => {
  return (
    <S.HoverCard>
      <S.HoverCard.Trigger>{children}</S.HoverCard.Trigger>

      <S.HoverCardContent side="top">
        <S.OrdersAmount type={ETextType.P}>{`R$ ${numberFormatter(
          amount,
          2,
        )} (${quantity})`}</S.OrdersAmount>

        <S.HoverCard.Arrow />
      </S.HoverCardContent>
    </S.HoverCard>
  );
};

export default HoverCard;
