import styled from 'styled-components/macro';

export const InputGroup = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 12px;

  @media only screen and (max-width: 1368px) {
    margin-bottom: 8px;
  }
`;
