import { USER_API_DOMAIN } from '@constants/api';
import { IPushNotificationProps } from '@domain/interfaces/dashboard/AdminPanel/IPushNotification';
import { ApiService } from '@services/api';

export class PushNotificationService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public pushNotification({ data }: IPushNotificationProps): Promise<any> {
    return this.apiService.post(`/api/v1/admin/push-notifications`, {
      ...data,
    });
  }
}

const pushNotificationService = new PushNotificationService(USER_API_DOMAIN);

export default pushNotificationService;
