import React from 'react';
import { Cpm, Ctr, Cpc, Cpa, Roas } from '@profitfy/pakkun-icons';

import { useProductDetails } from '@helpers/hooks/pages/dashboard/productAnalytics/useProductDetails';

import { getMarketingReportName } from '@helpers/utils/dashboard/productAnalytic/marketingProvider';

import MetricCard from './MetricCard';

import * as S from './styles';

const Metrics: React.FC = () => {
  const {
    marketingReport,
    isLoadingMarketingReport,
    isValidatingMarketingReport,
    marketingReportError,
    productFinancialDetailsReport,
    funnelChartProvider,
  } = useProductDetails();

  const { cpa } = productFinancialDetailsReport;

  const sliderMetricsRef = React.useRef<HTMLDivElement>(null);

  const [isMouseMoveOn, setIsMouseMoveOn] = React.useState<boolean>(false);
  const [onMouseMoveDownEvent, setOnMouseMoveDownEvent] = React.useState<any>({});
  const [scrollLeft, setScrollLeft] = React.useState<number | undefined>(0);

  const handleOnMouseDown = React.useCallback(event => {
    setIsMouseMoveOn(true);
    setOnMouseMoveDownEvent(event);
  }, []);

  const handleOnMouseUp = React.useCallback(() => {
    setIsMouseMoveOn(false);
    setScrollLeft(sliderMetricsRef?.current?.scrollLeft);
    setOnMouseMoveDownEvent({});
  }, []);

  const handleOnMouseMove = React.useCallback(
    event => {
      event.persist();

      if (isMouseMoveOn && sliderMetricsRef.current) {
        const position = onMouseMoveDownEvent.pageX - event.clientX;

        sliderMetricsRef.current.scrollLeft = (scrollLeft || 0) + position;
      }
    },
    [isMouseMoveOn, scrollLeft, sliderMetricsRef, onMouseMoveDownEvent],
  );

  if (isLoadingMarketingReport || isValidatingMarketingReport) {
    return <div>Loading...</div>;
  }

  if (marketingReportError && !isValidatingMarketingReport) {
    return <></>;
  }

  const marketingReportName = getMarketingReportName(funnelChartProvider) as string;

  const data = marketingReport[marketingReportName];

  return (
    <S.Wrapper
      onMouseDown={handleOnMouseDown}
      onMouseUp={handleOnMouseUp}
      onMouseMove={handleOnMouseMove}
      ref={sliderMetricsRef}
      tabIndex={0}
    >
      <MetricCard type="CURRENCY" title="CPM" value={data?.cpm?.amount} icon={Cpm} />
      <MetricCard type="PERCENTAGE" title="CTR" value={data?.ctr?.percentage} icon={Ctr} />
      <MetricCard type="CURRENCY" title="CPC" value={data?.cpc?.amount} icon={Cpc} />
      <MetricCard type="CURRENCY" title="CPA" value={cpa.amount} icon={Cpa} />
      <MetricCard type="MULTIPLIER" title="ROAS" value={data?.roas.amount} icon={Roas} />
    </S.Wrapper>
  );
};

export default Metrics;
