import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';

import * as S from './styles';

const Item: React.FC<Accordion.AccordionItemProps & React.RefAttributes<HTMLDivElement>> = ({
  children,
  ...rest
}) => {
  return <S.Wrapper {...rest}>{children}</S.Wrapper>;
};

export default Item;
