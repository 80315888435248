import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 42px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 430px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

export const Heading = styled.div`
  width: 100%;
  max-width: 266px;
`;

export const NewInstanceButton = styled.div`
  width: 100%;
  max-width: 151px;
`;

export const Instance = styled.div`
  width: 324px;

  @media only screen and (max-width: 620px) {
    width: 100%;
  }
`;

export const InstancesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
`;
