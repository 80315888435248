import React from 'react';

import { ISelect, ISelectProps } from '@domain/interfaces/components/ISelect';
import Option from './Option';

import * as S from './styles';

const Select: ISelect & React.ForwardRefRenderFunction<HTMLSelectElement, ISelectProps> = (
  {
    size,
    name,
    defaultValue,
    children,
    onChange,
    value,
    autoFocus,
    readOnly = false,
    prepend: PrependIcon,
    prependIconColor,
    prependIconSize,
    prependText,
    prependIconBar = true,
    ...rest
  },
  ref,
) => {
  return (
    <S.Container {...rest}>
      {PrependIcon && (
        <>
          <PrependIcon
            size={prependIconSize || 20}
            color={prependIconColor}
            style={!prependIconBar ? { marginRight: '12px' } : null}
          />
          {prependIconBar && <S.VerticalRule />}
        </>
      )}

      {prependText && (
        <>
          <S.PrependAndAppendText>{prependText}</S.PrependAndAppendText>
          <S.VerticalRule />
        </>
      )}
      <S.Label>
        <S.Select
          size={size}
          ref={ref}
          name={name}
          defaultValue={defaultValue}
          onChange={onChange}
          value={value}
          autoFocus={autoFocus}
          readOnly={readOnly}
          tabIndex={readOnly ? -1 : undefined}
        >
          {children}
        </S.Select>
      </S.Label>
    </S.Container>
  );
};

Select.Option = Option;

export default React.forwardRef(Select);
