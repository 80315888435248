import React from 'react';
import { useTheme } from 'styled-components/macro';
import { Warning } from '@profitfy/pakkun-icons';
import { useHistory } from 'react-router-dom';

import { EModalType } from '@domain/enums/components/EModal';
import { useEditKit } from '@helpers/hooks/pages/dashboard/kit/useEditKit';

import * as S from './styles';

const SyncModal: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { handleSyncModalOpen, handleSyncSideModalOpen, isSyncModalOpen } = useEditKit();

  const onSyncClick = React.useCallback(() => {
    handleSyncModalOpen();
    handleSyncSideModalOpen();
  }, [handleSyncModalOpen, handleSyncSideModalOpen]);

  const onCancel = React.useCallback(() => {
    handleSyncModalOpen();
    history.goBack();
  }, [handleSyncModalOpen, history]);

  return (
    <S.Modal isOpen={isSyncModalOpen} toggle={onCancel}>
      <S.Modal.Header type={EModalType.WARNING}>
        <Warning size={24} color={theme.colors.warning.default} outline />
        Alterar custos para pedidos anteriores?
      </S.Modal.Header>
      <S.Modal.Body>
        <S.Description>
          Caso você <strong>não altere</strong>, os custos só serão aplicados para os{' '}
          <strong>próximos pedidos.</strong>
        </S.Description>
        <S.ButtonsWrapper>
          <S.CancelButton onClick={onCancel}>Não Alterar</S.CancelButton>
          <S.SyncButton onClick={onSyncClick}>Alterar</S.SyncButton>
        </S.ButtonsWrapper>
      </S.Modal.Body>
    </S.Modal>
  );
};

export default SyncModal;
