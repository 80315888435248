import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const LabelText = styled(TextBase)``;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TooltipTitle = styled(TextBase)`
  margin-bottom: 4px;
`;

export const TooltipCard = styled.div`
  padding: 8px 16px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.background[2]};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
`;
