import { CaretRight, Headset } from 'phosphor-react';
import React from 'react';
import { useTheme } from 'styled-components';
import * as S from './styles';

const HelpCenter: React.FC = () => {
  const { colors } = useTheme();

  const handleClick = React.useCallback(() => {
    window.open('https://ajuda.profitfy.me', '_blank');
  }, []);

  return (
    <S.Wrapper onClick={handleClick}>
      <S.IconWrapper>
        <Headset size={20} weight="fill" color={colors.gray[1]} />
      </S.IconWrapper>

      <S.HelpCenterWrapper>
        <S.HelpText>Possui dúvidas?</S.HelpText>
        <S.HelpDescription>Consulte a nossa central de ajuda.</S.HelpDescription>
      </S.HelpCenterWrapper>

      <CaretRight size={24} color={colors.gray[3]} />
    </S.Wrapper>
  );
};

export default HelpCenter;
