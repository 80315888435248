import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Heading = styled.div`
  margin-bottom: 63px;
  width: 100%;
  max-width: 320px;
`;

export const Tab = styled.div`
  width: 100%;
  max-width: 220px;
  margin-bottom: 48px;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const Label = styled.div`
  width: 100%;
  max-width: 220px;
`;

export const Input = styled.div`
  width: 100%;
  max-width: 504px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

export const CheckBox = styled.div`
  width: 100%;
  max-width: 43px;
`;
