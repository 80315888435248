import React from 'react';
import { ResponsiveContainer, PieChart as Chart, Pie, Cell } from 'recharts';

import { useProductDetails } from '@helpers/hooks/pages/dashboard/productAnalytics/useProductDetails';

import * as S from './styles';

const PieChart: React.FC = () => {
  const { productFinancialDetailsReport } = useProductDetails();

  const { pix } = productFinancialDetailsReport;

  const data = [
    {
      name: 'Conversão',
      value: pix.conversion,
    },
    {
      name: 'Total',
      value: 100 - pix.conversion,
    },
  ];

  const colors = ['#5B8DEF', '#515562'];

  return (
    <S.ChartWrapper>
      <ResponsiveContainer width="100%">
        <Chart>
          <Pie
            data={data}
            innerRadius="70%"
            outerRadius="100%"
            fill="#000"
            paddingAngle={0}
            dataKey="value"
            strokeOpacity={0}
          >
            {data.map((pie, index) => (
              <Cell key={`cell-${pie.name}`} fill={colors[index]} />
            ))}
          </Pie>
        </Chart>
      </ResponsiveContainer>
    </S.ChartWrapper>
  );
};

export default PieChart;
