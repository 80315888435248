import React from 'react';

import { useTheme } from 'styled-components';
import { useOtherGatewayIntegration } from '@helpers/hooks/pages/dashboard/integrationCenter/gateway/useOtherGatewayIntegration';
import { IntegrationsCircle } from '@profitfy/pakkun-icons';
import { EHeadingSize } from '@domain/enums/components/EHeading';
import { ISideModalGatewayContent } from '@domain/interfaces/dashboard/IntegrationCenter/SideModalContent/ISideModalContent';

import IntegrateForm from './IntegrateForm';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const OtherGateway: React.FC<ISideModalGatewayContent> = ({
  syncOnCreate = false,
  isFeedSideModal,
  toggle,
}) => {
  const theme = useTheme();

  const { isLoadingOtherGateways, otherGateways } = useOtherGatewayIntegration();

  if (isLoadingOtherGateways || Boolean(!otherGateways)) {
    return <SkeletonLoading />;
  }

  return (
    <S.Wrapper>
      <S.HeadingWrapper>
        <IntegrationsCircle size={52} color={theme.colors.green.default} />

        <S.Heading type={EHeadingSize.H4}>Integração com Gateway Outro</S.Heading>
      </S.HeadingWrapper>

      <IntegrateForm
        isFeedSideModal={isFeedSideModal}
        toggle={toggle}
        syncOnCreate={syncOnCreate}
      />
    </S.Wrapper>
  );
};

export default OtherGateway;
