import styled from 'styled-components/macro';

import ButtonBase from '@components/common/core/Inputs/Button';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';
import TextBase from '@components/common/core/DataDisplay/Text';

export const CustomTooltipTrigger = styled(CustomTooltipBase.Trigger)`
  height: 36px;
  padding: 8px 20px;
  font-size: 0.875rem;
  border-radius: 5px;
  border: unset;
  background-color: ${({ theme }) => theme.colors.green.default};
  color: ${({ theme }) => theme.colors.gray[7]};
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 700;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.green.default};
    box-shadow: ${({ theme }) => theme.shadows.buttonHover};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.green.dark};
    box-shadow: ${({ theme }) => theme.shadows.buttonPressed};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray[2]};
    box-shadow: none;
    cursor: not-allowed;
  }
`;

export const TooltipText = styled(TextBase)`
  display: block;
  max-width: 220px;
  width: 100%;
  font-size: 0.875rem;
  text-align: center;
`;

export const CustomTooltip = styled(CustomTooltipBase)``;

export const Button = styled(ButtonBase)``;

export const Wrapper = styled.div`
  height: 68px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.background[2]};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;
