import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';
import { ShoppingCart } from 'phosphor-react';

export const ABANDONED_CART = [
  {
    id: 'abandoned-cart-first-condition',
    title: 'Carrinho abandonado (1ª condição)',
    type: 'ABANDONED_CART',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: ShoppingCart,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá {{first_name}}! 😀\n\nVocê ainda não finalizou a sua compra, mas não se preocupe! 😢\n\nAinda dá tempo de garantir antes que o estoque acabe devido à alta demanda!\n\nSeu carrinho está esperando por você, clique no link abaixo: 🛒\n\n{{checkout_link}}\n\nE, se precisar de alguma ajuda, estamos aqui! 🤩',
      },
    ],
  },
  {
    id: 'abandoned-cart-second-condition',
    title: 'Carrinho abandonado (2ª condição)',
    type: 'ABANDONED_CART',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: ShoppingCart,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Voltamos, {{first_name}}! 😄\n\nQueremos lembrar que o seu carrinho ainda está esperando por você. 🛒\n\nClique no link abaixo para finalizar agora mesmo:\n\n{{checkout_link}}\n\nE, se precisar de alguma ajuda, estamos aqui! 🤩',
      },
    ],
  },
  {
    id: 'abandoned-cart-third-condition',
    title: 'Carrinho abandonado (3ª condição)',
    type: 'ABANDONED_CART',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: ShoppingCart,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá, {{first_name}}!\n\nTemos uma boa notícia: *FRETE GRÁTIS* nas próximas horas, para você finalizar a sua compra!\n\nClique no link abaixo para não perder essa oportunidade incrível! 🎉\n\n{{checkout_link}}',
      },
    ],
  },
];
