import React from 'react';

import { FAQ } from '@constants/pages/pricing/faq';

import * as S from './styles';

const Faq: React.FC = () => {
  const half = Math.ceil(FAQ.length / 2);

  const firstPartFaq = FAQ.slice(0, half);
  const secondPartFaq = FAQ.slice(half);

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>FAQ</S.Title>

        <S.AccordionsWrapper>
          <S.Accordion type="single" collapsible>
            {firstPartFaq.map((faq, index) => (
              <S.AccordionItem value={`value-${index}`}>
                <S.AccordionTrigger>{faq.title}</S.AccordionTrigger>
                <S.AccordionContent>
                  <S.ContentText>{faq.description}</S.ContentText>
                </S.AccordionContent>
              </S.AccordionItem>
            ))}
          </S.Accordion>

          <S.Accordion type="single" collapsible>
            {secondPartFaq.map((faq, index) => (
              <S.AccordionItem value={`value-${index}`}>
                <S.AccordionTrigger>{faq.title}</S.AccordionTrigger>
                <S.AccordionContent>
                  <S.ContentText>{faq.description}</S.ContentText>
                </S.AccordionContent>
              </S.AccordionItem>
            ))}
          </S.Accordion>
        </S.AccordionsWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

export default Faq;
