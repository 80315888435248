import React from 'react';

import { useFeedAdSenseAccount } from '@helpers/hooks/pages/dashboard/feed/useFeedAdSenseAccount';
import { EAdSenseProvider } from '@domain/enums/common/EAdSense';

import Header from '../Header';
import AdAccount from '../AdAccount';
import SkeletonLoading from '../SkeletonLoading';

const Facebook: React.FC = () => {
  const {
    adSenseAccounts,
    isLoadingAdSenseAccounts,
    isValidatingdSenseAccounts,
  } = useFeedAdSenseAccount();

  const isLoading = isLoadingAdSenseAccounts || isValidatingdSenseAccounts;

  const hasAdSenseAccounts = Boolean(adSenseAccounts?.length) && !isLoading;

  const filteredFacebookAdSenseAccounts = adSenseAccounts?.filter(
    adSenseAccount => adSenseAccount.provider === EAdSenseProvider.FACEBOOK,
  );

  return (
    <>
      <Header provider="Facebook" />

      {!hasAdSenseAccounts && <SkeletonLoading />}

      {hasAdSenseAccounts && (
        <>
          {filteredFacebookAdSenseAccounts?.map(adSenseAccount => {
            return <AdAccount key={adSenseAccount.alias_id} adSenseAccount={adSenseAccount} />;
          })}
        </>
      )}
    </>
  );
};

export default Facebook;
