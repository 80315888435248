import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';

import {
  ICreateCustomValuesCategoryProps,
  IDeleteCustomValuesCategoryProps,
  IGetCustomValuesCategoriesProps,
  IGetCustomValuesCategoriesResponse,
  IUpdateCustomValuesCategoryProps,
} from '@domain/interfaces/dashboard/CustomValuesCategories/ICustomValuesCategories';
import { getEnvironment } from '@helpers/utils/environment';

export class CustomValuesCategoriesService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getCustomValuesCategories({
    storeAliasId,
  }: IGetCustomValuesCategoriesProps): IGetCustomValuesCategoriesResponse {
    // # DEMO CODE
    const user = localStorage.getItem(`@profitfy:${getEnvironment()}/user`);

    if (user) {
      const parsedJson = JSON.parse(user);

      if (parsedJson) {
        const { email } = parsedJson;

        if (email === 'profitfydemo@gmail.com') {
          const { data, error, mutate, isValidating } = useFetch(
            `/customValues/categories.json`,
            this.apiService,
          );

          const isLoading = !error && !data;

          return {
            customValuesCategories: data?.custom_value_categories,
            error,
            mutate,
            isLoading,
            isValidating,
          };
        }
      }
    }

    // END CODE

    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/custom-value-categories`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      customValuesCategories: data?.custom_value_categories,
      error,
      mutate,
      isLoading,
      isValidating,
    };
  }

  public getCustomValuesCategoriesPromise({
    storeAliasId,
  }: IGetCustomValuesCategoriesProps): Promise<any> {
    return this.apiService.get(`/api/v1/users/stores/${storeAliasId}/custom-value-categories`);
  }

  public createCustomValueCategory({
    storeAliasId,
    data,
  }: ICreateCustomValuesCategoryProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/custom-value-categories`, {
      ...data,
    });
  }

  public updateCustomValuesCategory({
    customValuesCategoryAliasId,
    data,
    storeAliasId,
  }: IUpdateCustomValuesCategoryProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/custom-value-categories/${customValuesCategoryAliasId}`,
      { ...data },
    );
  }

  public deleteCustomValuesCategory({
    customValuesCategoryAliasId,
    storeAliasId,
  }: IDeleteCustomValuesCategoryProps): Promise<any> {
    return this.apiService.delete(
      `/api/v1/users/stores/${storeAliasId}/custom-value-categories/${customValuesCategoryAliasId}`,
    );
  }
}

const customValueCategoriesService = new CustomValuesCategoriesService(API_DOMAIN);

export default customValueCategoriesService;
