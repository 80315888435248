import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoadingCard: React.FC = () => {
  return (
    <S.CardWrapper>
      <Skeleton width="100%" height={185} style={{ borderRadius: '15px' }} />
    </S.CardWrapper>
  );
};

export default SkeletonLoadingCard;
