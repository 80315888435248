import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import BadgeBase from '@components/common/core/DataDisplay/Badge';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';

export const Wrapper = styled.div`
  width: 100%;
  padding: 16px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[5]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  &:last-child {
    border-bottom: unset;
  }
`;

export const Title = styled(TextBase)``;

export const Description = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const TitleAndDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span:first-child {
    color: #ffffff;
  }
`;

export const PaymentDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Badge = styled(BadgeBase)`
  text-transform: none;
  margin-bottom: 8px;
  padding: 2px 12px;
`;

export const CheckboxWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.black.light};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SelectCustomValueBillingCheckbox = styled(CheckboxBase)``;
