import styled from 'styled-components/macro';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const OptionWrapper = styled.div``;

export const SelectWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;

  @media only screen and (max-width: 450px) {
    flex-direction: column;

    & > div:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

export const EmailWrapper = styled.div`
  max-width: 677px;
  width: 100%;
`;

export const InputWrapper = styled.div`
  max-width: 326px;
  width: 100%;
`;

export const InputGroup = styled.div`
  max-width: 326px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InputGroupsWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 24px;
`;

export const NameWrapper = styled.div`
  max-width: 277px;
  width: 100%;
`;

export const AvatarTextWrapper = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  width: 100%;

  @media only screen and (max-width: 820px) {
    max-width: 322px;
    margin: 0 auto;
    margin-bottom: 32px;
  }
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 24px;
  max-width: 232px;
  width: 100%;
`;

export const PersonInfoWrapper = styled.div`
  @media only screen and (max-width: 820px) {
    max-width: 322px;
    margin: 0 auto;
  }
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background[8]};
  width: 100%;
  height: fit-content;
  padding: 32px;
  border-radius: 12px;
  box-sizing: border-box;
`;
