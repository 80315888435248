import React from 'react';

import { EPlanRecurrence } from '@domain/enums/subscription/ESubscription';

import { PLANS } from '@constants/plans';

import NextArrow from './NextArrow';
import PreviousArrow from './PreviousArrow';
// import Plan from '../AvailablePlansCard';
import RecurrenceType from './RecurrenceType';
import Plan from '../Plan';

import * as S from './styles';

const Slider: React.FC = () => {
  const [selectedRecurrenceType, setSelectedRecurrenceType] = React.useState<EPlanRecurrence>(
    EPlanRecurrence.MONTHLY,
  );

  const handleSelectedRecurrenceType = React.useCallback(
    type => setSelectedRecurrenceType(type),
    [],
  );

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
    responsive: [
      {
        breakpoint: 1548,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1362,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <RecurrenceType
        selectedRecurrenceType={selectedRecurrenceType}
        handleSelectedRecurrenceType={handleSelectedRecurrenceType}
      />

      <S.Slider {...sliderSettings}>
        {PLANS.map((plan, index) => (
          <Plan
            plan={plan}
            key={plan.identifier}
            index={index}
            selectedRecurrenceType={selectedRecurrenceType}
          />
        ))}
      </S.Slider>
    </>
  );
};

export default Slider;
