import React from 'react';
import { Calendar } from '@profitfy/pakkun-icons';
import { useDate } from '@helpers/hooks/useDate';
import { useParams } from 'react-router-dom';

import { useTax } from '@helpers/hooks/pages/dashboard/tax/useTax';
import { EHeadingWeight } from '@domain/enums/components/EHeading';
import Save from '@components/common/core/DataDisplay/SideModal/Save';
import { useToast } from '@helpers/hooks/useToast';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import {
  ESynchronizationRequestType,
  ESynchronizationType,
} from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';
import { LONG_API_DOMAIN } from '@constants/api';
import { getEnvironment } from '@helpers/utils/environment';

import * as S from './styles';

const SyncPastTaxSideModal: React.FC = () => {
  const {
    isSyncPastTaxSidemodalOpen,
    handleSyncPastTaxSideModalOpen,
    defaultTax,
    createTaxPeriod,
  } = useTax();
  const { utcToZonedTime, format } = useDate();
  const { toast } = useToast();
  const { addSynchronization } = useSynchronization();
  const { storeAliasId } = useParams<IParams>();

  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [period, setPeriod] = React.useState({
    startDate: utcToZonedTime(new Date()),
    endDate: utcToZonedTime(new Date()),
  });

  const handleOnSave = React.useCallback(async () => {
    setIsSaving(true);

    try {
      const boolean = await createTaxPeriod({
        calculate_mode: defaultTax?.calculate_mode,
        percentage_amount: defaultTax?.percentage_amount || 0,
        regime: defaultTax?.regime,
        start_date: format(utcToZonedTime(period.startDate), 'yyyy-MM-dd'),
        end_date: format(utcToZonedTime(period.endDate), 'yyyy-MM-dd'),
        name: 'any_tax_name',
      });

      handleSyncPastTaxSideModalOpen();

      if (boolean) {
        const params = `?start_date=${format(
          utcToZonedTime(period.startDate),
          'yyyy-MM-dd',
        )}&end_date=${format(utcToZonedTime(period.endDate), 'yyyy-MM-dd')}`;

        addSynchronization({
          name: 'Impostos',
          type: ESynchronizationType.STORE_GATEWAY,
          label: 'Pedidos',
          dependencies: [ESynchronizationType.STORE_PRODUCT],
          storeAliasId,
          config: {
            isOnboardingSynchronization: false,
            showNotification: true,
          },
          request: {
            type: ESynchronizationRequestType.POST,
            domain: LONG_API_DOMAIN,
            api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/orders/tax/sync`,
            requestParams: params,
          },
        });
      }

      setIsSaving(false);
    } catch (error: any) {
      setIsSaving(false);
      toast.error(error?.response?.data?.message);
    }
  }, [
    handleSyncPastTaxSideModalOpen,
    period,
    format,
    toast,
    utcToZonedTime,
    addSynchronization,
    storeAliasId,
    defaultTax,
    createTaxPeriod,
  ]);

  const maxDate = utcToZonedTime(new Date());

  return (
    <S.Sidemodal isOpen={isSyncPastTaxSidemodalOpen} toggle={handleSyncPastTaxSideModalOpen}>
      <S.Header>
        <Calendar size={32} outline />
        <S.Title>Alterar período</S.Title>
      </S.Header>

      <S.Body>
        <S.ProductName fontWeight={EHeadingWeight.REGULAR}>
          Definir periodo para reprocessar
        </S.ProductName>
        <S.CustomDatePicker period={period} setPeriod={setPeriod} maxDate={maxDate} />

        <Save
          cancelContent="Cancelar"
          saveContent="Salvar alterações"
          isSubmitting={isSaving}
          isSaveButtonDisabled={isSaving}
          isDeleteButtonDisabled={isSaving}
          type="button"
          onSave={handleOnSave}
        />
      </S.Body>
    </S.Sidemodal>
  );
};

export default SyncPastTaxSideModal;
