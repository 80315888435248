import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const DatePickerAndColumnsAndActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media only screen and (max-width: 604px) {
    flex-direction: column-reverse;
    align-items: unset;
  }
`;

export const ColumnsAndActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const TableTypeButton = styled.button`
  background-color: unset;
  border: unset;
  padding: unset;
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[1]};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.green.default};
  }

  &:active {
    color: ${({ theme }) => theme.colors.green.dark};
  }
`;

export const FilterAndTableTypeButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 16px;
  width: 100%;
`;
