import * as yup from 'yup';

import { EStoreType } from '@domain/enums/stores/Etype';

export const dropshippingSchema = yup.object().shape({
  store_name: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'O nome da loja precisa ter pelo menos 2 caractéres.')
    .max(1000, 'O nome da loja pode ter até 1000 caractéres.'),
  domain: yup.string().test('domain validator', 'Informe um domínio válido.', value => {
    if (!value) return true;

    const regex = new RegExp(
      /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g,
    );

    if (value) return regex.test(value);

    return false;
  }),
});

export const infoProductSchema = yup.object().shape({
  store_name: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'O nome do seu negócio precisa ter pelo menos 2 caractéres.')
    .max(1000, 'O nome do seu negócio pode ter até 1000 caractéres.'),
  domain: yup.string().test('domain validator', 'Informe um domínio válido.', value => {
    if (!value) return true;

    const regex = new RegExp(
      /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g,
    );

    if (value) return regex.test(value);

    return false;
  }),
});

export const createStoreSchema = yup.object().shape({
  store_name: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'O nome do seu negócio precisa ter pelo menos 2 caractéres.')
    .max(1000, 'O nome do seu negócio pode ter até 1000 caractéres.'),
});

export const getFormSchema = (storeSegment: EStoreType | undefined): yup.ObjectSchema => {
  if (storeSegment === EStoreType.DROPSHIPPING) return dropshippingSchema;

  if (storeSegment === EStoreType.INFO_PRODUCT) return infoProductSchema;

  return yup.object().shape({});
};

export const getCreateStoreFormSchema = (isSubmitForm: boolean): yup.ObjectSchema => {
  if (isSubmitForm) return createStoreSchema;

  return yup.object().shape({});
};
