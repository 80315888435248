import React from 'react';
import { useTheme } from 'styled-components/macro';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { ECampaignStatus } from '@domain/enums/dashboard/adsManager/ECampaigns';

import * as S from './styles';

const Status: React.FC<ICampaignProps> = ({ data }) => {
  const statusData: ECampaignStatus = data;

  const theme = useTheme();

  const getStatus = React.useCallback(
    (status: ECampaignStatus) =>
      ({
        ENABLED: {
          color: theme.colors.success.default,
          name: 'Ativo',
        },
        UNSPECIFIED: {
          color: theme.colors.gray[3],
          name: 'Não Específicado',
        },
        UNKNOWN: {
          color: theme.colors.gray[3],
          name: 'Desconhecido',
        },
        PAUSED: {
          color: theme.colors.warning.default,
          name: 'Pausado',
        },
        REMOVED: {
          color: theme.colors.danger.default,
          name: 'Removido',
        },
        ARCHIVED: {
          color: theme.colors.danger.default,
          name: 'Arquivado',
        },
        IN_PROCESS: {
          color: theme.colors.warning.default,
          name: 'Processando',
        },
        WITH_ISSUES: {
          color: theme.colors.danger.default,
          name: 'Com problema',
        },
      }[status]),
    [theme],
  );

  const status = getStatus(statusData);

  return (
    <S.Wrapper>
      <S.Bullet color={status.color} />
      <S.StatusName>{status.name}</S.StatusName>
    </S.Wrapper>
  );
};

export default Status;
