import styled from 'styled-components/macro';

export const Footer = styled.tfoot`
  & > tr {
    & > td {
      padding: 12px 24px 8px 24px !important;
      text-align: right;
    }
  }
`;
