import React from 'react';
import { Info } from 'phosphor-react';
import { useTheme } from 'styled-components';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';

import dsersSyncService from '@services/pages/dashboard/integrationCenter/erp/dsers/dsersSync';

import * as S from './styles';

interface IDeleteSyncsModalProps {
  isOpen: boolean;
  toggle: () => void;
}
const DeleteSyncsModal: React.FC<IDeleteSyncsModalProps> = ({ isOpen, toggle }) => {
  const { colors } = useTheme();
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();

  const [isDeletingSyncs, setIsDeletingSyncs] = React.useState<boolean>(false);

  const onDeleteSyncs = React.useCallback(async () => {
    setIsDeletingSyncs(true);

    try {
      await dsersSyncService.deleteDsersSyncs({ storeAliasId });

      toast.success('Sincronizações deletadas com sucesso!');

      setIsDeletingSyncs(false);
      toggle();
    } catch (error: any) {
      setIsDeletingSyncs(false);
      toast.error(error?.response?.data?.message);
    }
  }, [toast, storeAliasId, toggle]);

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.QuestionWrapper>
        <Info size={24} color={colors.warning.default} />

        <S.Question>Deseja deletar todas as sincronizações da integração?</S.Question>
      </S.QuestionWrapper>

      <S.Text>Ao confirmar nós iremos deletar todas suas sincronizações desta integração.</S.Text>

      <S.ButtonsWrapper>
        <S.CancelButton onClick={toggle}>Cancelar</S.CancelButton>
        <S.ConfirmButton onClick={onDeleteSyncs} isLoading={isDeletingSyncs}>
          Deletar sincronizações
        </S.ConfirmButton>
      </S.ButtonsWrapper>
    </S.Modal>
  );
};

export default DeleteSyncsModal;
