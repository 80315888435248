import styled from 'styled-components/macro';

import SideModalBase from '@components/common/core/DataDisplay/SideModal';

export const HorizontalRule = styled.hr`
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.gray[3]};
  margin: 24px 0;
`;

export const Sidemodal = styled(SideModalBase)`
  max-width: 787px;
  overflow-x: hidden;
`;
