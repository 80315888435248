import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';

import {
  ICreateShippingDefaultProps,
  IGetShippingDefaultProps,
  IUpdateShippingDefaultProps,
  IDeleteShippingDefaultProps,
  IGetShippingDefaultResponse,
} from '@domain/interfaces/services/shipping/IShipping';

export class ShippingService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getShipping({ storeAliasId }: IGetShippingDefaultProps): IGetShippingDefaultResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/shippings/default`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      shipping: data?.shipping,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public createShippingDefault({ storeAliasId, data }: ICreateShippingDefaultProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/shippings/default`, {
      ...data,
    });
  }

  public updateShippingDefault({
    storeAliasId,
    shippingAliasId,
    data,
  }: IUpdateShippingDefaultProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/shippings/${shippingAliasId}/default`,
      {
        ...data,
      },
    );
  }

  public deleteShippingDefault({
    storeAliasId,
    shippingAliasId,
  }: IDeleteShippingDefaultProps): Promise<any> {
    return this.apiService.delete(
      `/api/v1/users/stores/${storeAliasId}/shippings/${shippingAliasId}`,
    );
  }
}

const shippingService = new ShippingService(API_DOMAIN);

export default shippingService;
