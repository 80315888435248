import React from 'react';

import { useAdsManager } from '@helpers/hooks/pages/dashboard/adsManager/useAdsManager';

import * as S from './styles';

const UtmError: React.FC = () => {
  const { handleUtmSidemodalOpen } = useAdsManager();

  return (
    <S.Wrapper>
      <S.Title>Não foi localizado UTMs em sua campanha. </S.Title>
      <S.Text>
        Para visualizar dados financeiros de suas campanhas é necessário estar com as UTM’s da
        campanha sincronizada.{' '}
      </S.Text>
      <S.SyncButton onClick={handleUtmSidemodalOpen}>Sincronizar UTMs</S.SyncButton>
    </S.Wrapper>
  );
};

export default UtmError;
