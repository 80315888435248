import React from 'react';

import * as S from './styles';

const Header: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Title>Selecione todas as plataformas que você utiliza.</S.Title>
      <S.Description>
        Nessa etapa é importante que você selecione todas as plataformas que utiliza para fazer
        integração e deixar seus dados o mais assertivos possível.
      </S.Description>
    </S.Wrapper>
  );
};

export default Header;
