import React from 'react';

import { Layout } from 'phosphor-react';
import { useTheme } from 'styled-components';

import { ETextWeight } from '@domain/enums/components/EText';
import { ENodes } from '@domain/enums/dashboard/automations/node/ENodes';
import { DEFAULT_MESSAGE_TEMPLATES } from '@constants/pages/dashboard/automations/messages/messageTemplates';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';

import MessageTemplate from './MessageTemplate';

import * as S from './styles';

const SelectTemplateContent: React.FC = () => {
  const theme = useTheme();

  const { nodes } = useAutomation();

  const triggerNodes = [
    ENodes.ABANDONED_CART,
    ENodes.BOLETO,
    ENodes.PIX,
    ENodes.REJECTED_PAYMENT,
    ENodes.BOLETO_RECOVER,
    ENodes.PIX_RECOVER,
    ENodes.SIMPLE_SHIPMENT,
    ENodes.UPDATED_FULFILLMENT,
  ];

  const filteredTriggerNodes = nodes.filter(node => triggerNodes.includes(node.type));

  const triggerNode = filteredTriggerNodes[0];

  const filteredTemplates = DEFAULT_MESSAGE_TEMPLATES.filter(
    template => template.type === triggerNode.type || template.type === 'NO_CONTENT',
  );

  return (
    <>
      <S.Header>
        <S.HeadingWrapper>
          <Layout size={28} color={theme.colors.gray[1]} />
          <S.Heading>Escolha um template</S.Heading>
        </S.HeadingWrapper>

        <S.Description weight={ETextWeight.MEDIUM}>
          Crie a sua mensagem do zero ou selecione um de nossos templates prontos
        </S.Description>
      </S.Header>

      <S.TemplatesWrapper>
        {filteredTemplates.map(template => {
          return <MessageTemplate key={template.id} template={template} />;
        })}
      </S.TemplatesWrapper>
    </>
  );
};

export default SelectTemplateContent;
