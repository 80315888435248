import React from 'react';

import { EGroup } from '@domain/enums/dashboard/customValuesCategories/EGroup';
import { useCustomValuesCategories } from '@helpers/hooks/pages/dashboard/customValues/useCustomValuesCategories';
import Category from './Category';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Categories: React.FC = () => {
  const {
    isLoadingCustomValuesCategories,
    isValidatingCustomValuesCategories,
    isUpdatingCustomValuesCategory,
  } = useCustomValuesCategories();

  if (
    (isLoadingCustomValuesCategories || isValidatingCustomValuesCategories) &&
    !isUpdatingCustomValuesCategory
  ) {
    return <SkeletonLoading />;
  }

  return (
    <S.CategoriesWrapper>
      <Category name="Despesas gerais" groupType={EGroup.GENERAL_EXPENSES} />
      <Category name="Despesas administrativas" groupType={EGroup.ADMINISTRATIVE_EXPENSES} />
      <Category name="Despesas de marketing" groupType={EGroup.MARKETING_EXPENSES} />
      <Category name="Taxas | Tarifas sobre vendas" groupType={EGroup.TAX} />
      <Category name="Despesas de envio" groupType={EGroup.SHIPPING_EXPENSES} />
      <Category name="Comissões" groupType={EGroup.COMMISSIONS} />
      <Category name="Receitas financeiras" groupType={EGroup.FINANCIAL_INCOMES} />
      <Category name="Despesas financeiras" groupType={EGroup.FINANCIAL_EXPENSES} />
      <Category name="Outros" groupType={EGroup.OTHER} />
    </S.CategoriesWrapper>
  );
};

export default Categories;
