import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import ImageBase from '@components/common/core/DataDisplay/Image';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Modal = styled(ModalBase)`
  width: 100%;
  max-width: 396px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled(ImageBase)`
  width: 100%;
  max-width: 154px;
  margin-bottom: 24px;
`;

export const Heading = styled(HeadingBase)`
  margin-bottom: 8px;
  color: #ffffff;
`;

export const Description = styled(TextBase)`
  margin-bottom: 32px;
  color: #ffffff;
  text-align: center;
`;

export const QRCodeWrapper = styled.div`
  width: 100%;
  max-width: 196px;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 12px;
`;

export const CloseIcon = styled.button`
  background: unset;
  padding: unset;
  border: unset;
  position: absolute;
  top: 16px;
  right: 16px;
`;
