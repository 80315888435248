import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';

import ButtonBase from '@components/common/core/Inputs/Button';

export const Modal = styled(ModalBase)`
  width: 100%;
  max-width: 428px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
`;

export const Heading = styled(TextBase)``;

export const Description = styled(TextBase)`
  color: #ffffff;
  margin-bottom: 32px;
`;

export const ConfirmButton = styled(ButtonBase)`
  margin-left: auto;
`;
