import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const ForecastText = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[2]};
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
  filter: blur(5px);
  user-select: none;
  font-size: 0.875rem;
  width: 36px;
`;

export const CpaLabel = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
  line-height: 120%;
  white-space: nowrap;
  filter: blur(5px);
  user-select: none;
  font-size: 0.875rem;
  width: 48px;
  height: 20px;
`;

export const CpaAmount = styled(TextBase)`
  line-height: 120%;
  white-space: nowrap;
  filter: blur(5px);
  user-select: none;
  font-size: 0.875rem;
  width: 28px;
`;
