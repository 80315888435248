import React from 'react';

import { CircleSpinner } from 'react-spinners-kit';
import { TikTok as TikTokIcon } from '@profitfy/pakkun-icons';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { useTikTokCrendentials } from '@helpers/hooks/common/integrations/marketing/tiktok/useTikTokCrendentials';
import { newWindow } from '@helpers/hooks/usePopup';
import { IParams } from '@domain/interfaces/IParams';
import { useToast } from '@helpers/hooks/useToast';
import { useParams } from 'react-router-dom';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { TikTokAdAccountsProvider } from '@helpers/hooks/common/integrations/marketing/tiktok/useTikTokAdAccounts';
import { useTheme } from 'styled-components';

import TikTokImg from '@assets/pages/Onboarding/dropshipping/marketing/tiktok.svg';
import tikTokCredentialService from '@services/pages/dashboard/adSense/tiktok/tiktokCredential';

import PageError from '@components/common/core/Utils/PageError';

import Header from '../Header';
import AdAccountsTable from '../Table';

import { PageSkeletonLoading, TableSkeletonLoading } from '../SkeletonLoading';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const theme = useTheme();

  const { currentSynchronizations } = useSynchronization();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();

  const {
    adSenseCredentials,
    isLoadingTikTokCredentialsData,
    isValidatingTikTokCredentialsData,
    isTikTokCredentialsDataError,
    isLoggingOut,
    isLoggingIn,
    mutate: mutateOnPageError,
    mutateAdSenseCredentials,
    syncAdSenseAccounts,
  } = useTikTokCrendentials();

  const [isIntegrating, setIsIntegrating] = React.useState<boolean>(false);
  const [userWindow, setUserWindow] = React.useState<Window | undefined>(undefined);

  const onAddNewProfileButtonClick = React.useCallback(async () => {
    setIsIntegrating(true);

    try {
      const { data: tiktokData } = await tikTokCredentialService.generateUrl({ storeAliasId });

      const window = newWindow(tiktokData?.auth_url, 'TikTok', 1280, 720);

      setUserWindow(window);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsIntegrating(false);
    }
  }, [storeAliasId, toast]);

  const isSyncing = Boolean(
    currentSynchronizations?.synchronizations.find(synchronization => {
      if (
        synchronization.type === ESynchronizationType.AD_SENSE_ACCOUNTS ||
        synchronization.type === ESynchronizationType.AD_SENSE_CAMPAIGNS
      ) {
        return true;
      }

      return false;
    }),
  );

  const mutate = React.useCallback(async () => {
    const { data: adSenseCredentialsResponse } = await mutateAdSenseCredentials();

    const lastCredentialAddedIndex = 0;

    const lastCredentialAddedAliasId =
      adSenseCredentialsResponse?.ad_sense_credentials[lastCredentialAddedIndex]?.alias_id;

    if (lastCredentialAddedAliasId) {
      syncAdSenseAccounts(lastCredentialAddedAliasId);
    }
  }, [mutateAdSenseCredentials, syncAdSenseAccounts]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (userWindow?.closed) {
        clearInterval(interval);
        mutate();
      }
    }, 1000);
  }, [userWindow, mutate]);

  const isLoading =
    isLoadingTikTokCredentialsData ||
    isValidatingTikTokCredentialsData ||
    isLoggingIn ||
    isLoggingOut;

  if (isLoading) {
    return (
      <>
        <PageSkeletonLoading />
      </>
    );
  }

  if (isTikTokCredentialsDataError && !isLoadingTikTokCredentialsData) {
    return (
      <>
        <S.ErrorWrapper>
          <PageError mutate={mutateOnPageError} />
        </S.ErrorWrapper>
      </>
    );
  }

  return (
    <>
      {Boolean(adSenseCredentials.length) && (
        <S.HeaderAndTableContainer>
          <Header />

          {isSyncing && <TableSkeletonLoading />}

          {!isSyncing && (
            <>
              {adSenseCredentials.map((adSenseCredential, index) => (
                <TikTokAdAccountsProvider
                  adSenseCredentialAliasId={adSenseCredential.alias_id}
                  key={adSenseCredential.alias_id}
                >
                  <AdAccountsTable
                    openFirstProfile={Boolean(index === 0)}
                    adSenseCredential={adSenseCredential}
                  />
                </TikTokAdAccountsProvider>
              ))}
            </>
          )}
        </S.HeaderAndTableContainer>
      )}

      {Boolean(!adSenseCredentials.length) && (
        <S.ContentWrapper>
          <S.Image src={TikTokImg} alt="profitfy-goat-with-tiktok-logo" />

          <S.Heading type={EHeadingSize.H2}>Conectar perfil do TikTok</S.Heading>

          <S.AddNewProfileButton onClick={onAddNewProfileButtonClick}>
            {!isIntegrating ? (
              <>
                <TikTokIcon size={22} />
                Adicionar perfil do TikTok
              </>
            ) : (
              <CircleSpinner size={15} color={theme.colors.gray[1]} loading={isIntegrating} />
            )}
          </S.AddNewProfileButton>
        </S.ContentWrapper>
      )}
    </>
  );
};

export default Wrapper;
