import styled from 'styled-components/macro';

import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import ButtonBase from '@components/common/core/Inputs/Button';

export const RenameButton = styled(ButtonBase)`
  width: 100%;
  border-radius: unset;
`;

export const CancelButton = styled(ButtonBase)`
  width: 100%;
  border-radius: unset;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Input = styled(TextFieldBase)``;

export const Wrapper = styled.div`
  border-radius: 5px;
  overflow: hidden;
`;

export const CustomPopoverContent = styled(CustomPopoverBase.Content)``;
