import { EFilterType } from '@domain/enums/components/EFilter';
import { IFilterConfig } from '@domain/interfaces/components/IFilter';

export const AUTOMATIONS_MESSAGES_FILTER_CONFIG: Array<IFilterConfig> = [
  {
    field: 'teste',
    label: 'Teste',
    type: EFilterType.SELECT,
    config: {
      placeholder: 'Buscar por teste...',
      data: [
        {
          value: 'teste1',
          label: 'Teste1',
        },
        {
          value: 'teste2',
          label: 'Teste2',
        },
      ],
    },
  },
];
