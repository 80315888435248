import React from 'react';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { EBenefit } from '@domain/enums/dashboard/benefitArea/EBenefit';

import { useConfig } from '@helpers/hooks/useConfig';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { BENEFITS } from '@constants/benefitsArea';

import Benefit from './Benefit';

import * as S from './styles';

const BenefitArea: React.FC = () => {
  const { analytics } = useConfig();
  const { store } = useStoreConfig();

  React.useEffect(() => {
    analytics?.track('Benefit Area Page Viewed', {}, { context: { groupId: store?.alias_id } });
  }, [analytics, store]);

  const cogsAmountValueToDisplayZendropCard = 30000;

  const benefitsWithoutZendrop = BENEFITS.filter(benefit => benefit.id !== EBenefit.ZENDROP);

  const benefits =
    store?.last_30_days_cogs_amount >= cogsAmountValueToDisplayZendropCard
      ? BENEFITS
      : benefitsWithoutZendrop;

  return (
    <S.Wrapper>
      <S.Heading fontWeight={EHeadingWeight.REGULAR}>
        Você já começa com todos esses <strong>benefícios!</strong>
      </S.Heading>

      <S.Description>
        Para que sua jornada seja ainda <strong>mais lucrativa</strong>, grandes marcas
        disponibilizaram <strong>benefícios exclusivos</strong> para te apoiar.
      </S.Description>

      <S.BenefitsWrapper>
        {benefits.map(benefit => {
          return (
            <Benefit
              key={benefit.id}
              id={benefit.id}
              title={benefit.title}
              image={benefit.image}
              badgeText={benefit.badgeText}
              benefitDescription={benefit.benefitDescription}
            />
          );
        })}
      </S.BenefitsWrapper>
    </S.Wrapper>
  );
};

export default BenefitArea;
