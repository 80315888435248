import styled, { keyframes } from 'styled-components/macro';

const slideUpAndFade = keyframes`
  0% {
    opacity: 0;
    transform: translateY(4px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Wrapper = styled.div`
  animation-duration: 700ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-fill-mode: forwards;
  animation-name: ${slideUpAndFade};
  will-change: 'transform, opacity';
`;
