import styled from 'styled-components/macro';
import SliderBase from 'react-slick';

export const Slider = styled(SliderBase)`
  max-width: 1386px;
  width: 1386px;
  /* width: 100%; */
  margin: 0 auto;
  margin-bottom: 36px;

  @media only screen and (max-width: 1548px) {
    max-width: 1148px;
  }

  @media only screen and (max-width: 1362px) {
    max-width: 848px;
  }

  @media only screen and (max-width: 1160px) {
    max-width: 578px;
  }

  @media only screen and (max-width: 768px) {
    max-width: 270px;
  }

  @media only screen and (max-width: 392px) {
    width: 244px;
  }

  .slick-slide {
    /* height: 445px; */
    display: flex;
    align-items: flex-end;
  }

  .slick-dots,
  .slick-next,
  .slick-prev {
    position: absolute;
    display: block;
    padding: 0;
  }
  .slick-dots li button:before,
  .slick-next:before,
  .slick-prev:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-next:focus,
  .slick-next:hover,
  .slick-prev:focus,
  .slick-prev:hover {
    color: transparent;
    outline: 0;
    background: 0 0;
  }
  .slick-next:focus:before,
  .slick-next:hover:before,
  .slick-prev:focus:before,
  .slick-prev:hover:before {
    opacity: 1;
  }
  .slick-next.slick-disabled:before,
  .slick-prev.slick-disabled:before {
    opacity: 0.25;
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: #fff;
  }
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }
  .slick-dots {
    bottom: -25px;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 6px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 24px;
    height: 6px;
    cursor: pointer;
    color: transparent;
    background-color: #fff !important;
    border-radius: 15px;
    border: 0;
    outline: 0;
    background: 0 0;
  }

  .slick-dots li.slick-active button {
    background-color: ${({ theme }) => theme.colors.green.dark} !important;
  }
  .slick-dots li button:focus,
  .slick-dots li button:hover {
    outline: 0;
  }
  .slick-dots li button:focus:before,
  .slick-dots li button:hover:before {
    opacity: 1;
  }
`;
