import React from 'react';

import { TaboolaCredentialsProvider } from '@helpers/hooks/common/integrations/marketing/taboola/useTaboolaCredentials';

import { IMarketingContentProps } from '@domain/interfaces/dashboard/IntegrationCenter/Marketing/IMarketingContent';

import Wrapper from './Wrapper';

const Taboola: React.FC<IMarketingContentProps> = ({ callback }) => {
  return (
    <TaboolaCredentialsProvider>
      <Wrapper callback={callback} />
    </TaboolaCredentialsProvider>
  );
};

export default Taboola;
