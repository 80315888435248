import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import ImageBase from '@components/common/core/DataDisplay/Image';

export const Wrapper = styled.button`
  position: relative;
  width: 324px;
  height: 319px;
  border-radius: 12px;
  padding: unset;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray[7]};
  border: 2px solid ${({ theme }) => theme.colors.gray[6]};
  box-sizing: border-box;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
    border-color: ${({ theme }) => theme.colors.green.default};

    & > div {
      border-radius: 12px;
    }
  }

  &:active {
    filter: brightness(0.9);
    border-color: ${({ theme }) => theme.colors.green.dark};
  }

  @media only screen and (max-width: 840px) {
    width: 100%;
  }
`;

export const TemplateContent = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  height: 228px;
  border-radius: 6px 6px 12px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TriggersWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TriggerBackground = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${({ color }) => color};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TemplateContentImage = styled(ImageBase)`
  max-width: 164px;
`;

export const TemplateInfos = styled.div`
  width: 100%;
  background: transparent;
  height: 91px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  padding: 16px;
  box-sizing: border-box;
`;

export const TemplateName = styled(TextBase)`
  color: #ffffff;
  font-size: 1.125rem;
  line-height: 120%;
`;
