import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import LabelBase from '@components/common/core/DataDisplay/Label';
import SidemodalBase from '@components/common/core/DataDisplay/SideModal';
import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import RadioBase from '@components/common/core/Inputs/Radio';
import ButtonBase from '@components/common/core/Inputs/Button';

export const BetaBadge = styled.span`
  border: 1px solid ${({ theme }) => theme.colors.green.default};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  padding: 2px 8px;
  font-size: 0.75rem;
  border-radius: 15px;
  color: ${({ theme }) => theme.colors.green.default} !important;
  line-height: 120%;
`;

export const RadioText = styled(TextBase)``;

export const RadioTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const RadioLabel = styled(LabelBase)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: unset;
  cursor: pointer;
`;

export const Radio = styled(RadioBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin: unset;
`;

export const IntegrationsWrapper = styled.div`
  max-width: 508px;
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray[7]};
  border-radius: 5px;
  margin-bottom: 16px;

  & > div {
    padding: 16px 0;
    border-top: 1px solid ${({ theme }) => theme.colors.gray[5]};
  }

  & > div:first-child {
    padding: unset;
    border: unset;
    padding-bottom: 16px;
  }

  & > div:last-child {
    padding: unset;
    padding-top: 16px;
  }

  & > div:only-child {
    padding: unset;
  }

  @media only screen and (max-height: 768px) {
    max-height: 330px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.gray[1]};
      border-radius: 25px;
      margin-bottom: 4px;
    }
  }

  @media only screen and (max-height: 576px) {
    max-height: 275px;
  }
`;

export const ErpButton = styled(ButtonBase)`
  width: 100%;
  margin-top: 16px;
`;

export const SuggestionButton = styled.button`
  background-color: unset;
  border: unset;
  padding: unset;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.green.default};
  line-height: 120%;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const ErpAndStoreButton = styled.button`
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  font-weight: 600;
  background-color: unset;
  border: unset;
  color: ${({ theme }) => theme.colors.info.dark};
  text-decoration: underline;
  cursor: pointer;
`;

export const SuggestionText = styled(TextBase)`
  line-height: 150%;
  display: inline-block;
`;

export const NoIntegrationText = styled(TextBase)`
  line-height: 150%;
  display: inline-block;
  /* color: ${({ theme }) => theme.colors.info.dark}; */
  color: #00388b;
`;

export const InfoWrapper = styled.div`
  padding: 16px;
  border-radius: 7px;
  background-color: #6c99f1;
  margin-bottom: 16px;
`;

export const Title = styled(HeadingBase)``;

export const Body = styled.div``;

export const Header = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
  margin-bottom: 48px;

  @media only screen and (max-height: 576px) {
    margin-bottom: 24px;
  }
`;

export const Sidemodal = styled(SidemodalBase)`
  background-color: ${({ theme }) => theme.colors.gray[6]};
`;
