import React from 'react';

import * as S from './styles';

const Header: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Logo
        src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/partners/mamba-digital/mamba.png"
        alt="Profitfy & Mamba"
      />
      <S.Title>Termos de Adesão - Parceira Profitfy & Mamba</S.Title>
    </S.Wrapper>
  );
};

export default Header;
