import React from 'react';
import { Plus } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import * as S from './styles';

const EmptyKit: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const theme = useTheme();
  const history = useHistory();

  const handleCreateNewKit = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/data/kits/new-kit`);
  }, [history, storeAliasId]);

  return (
    <S.Container>
      <S.Header />
      <S.ContentWrapper>
        <S.Title>Cadastrar Custo de Kits</S.Title>
        <S.CreateKitButton onClick={handleCreateNewKit}>
          <Plus size={12} color={theme.colors.font} />
          Criar novo custo de kit
        </S.CreateKitButton>
      </S.ContentWrapper>
    </S.Container>
  );
};

export default EmptyKit;
