import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { useFinancialDetails } from '@helpers/hooks/pages/dashboard/financialDetails/useFinancialDetails';
import { EHeadAlign } from '@domain/enums/components/ETable';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  const randomArray = Array.from(Array(10).keys());
  const { periodTableDatesNames } = useFinancialDetails();

  const [dateToCompare, comparedDate] = periodTableDatesNames;
  return (
    <S.Table>
      <S.Table.Header>
        <S.Table.Row>
          <S.Table.Head />
          <S.Table.Head />
          <S.Table.Head align={EHeadAlign.RIGHT}>{comparedDate}</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>{dateToCompare}</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT} />
          <S.Table.Head align={EHeadAlign.RIGHT} />
        </S.Table.Row>
      </S.Table.Header>

      <S.Table.Body>
        {randomArray.map((value, index) => (
          <S.Table.Row key={value} borderBottom={index + 1 !== randomArray.length}>
            <S.Table.Data />
            <S.Table.Data>
              <S.DataTypeTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.DataTypeTextWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.DateValueTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.DateValueTextWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.DateValueTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.DateValueTextWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.PercentageTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.PercentageTextWrapper>
            </S.Table.Data>
            <S.Table.Data>
              <S.PercentageTextWrapper>
                <Skeleton width="100%" height={16} />
              </S.PercentageTextWrapper>
            </S.Table.Data>
          </S.Table.Row>
        ))}
      </S.Table.Body>
    </S.Table>
  );
};

export default SkeletonLoading;
