import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { useNewKit } from '@helpers/hooks/pages/dashboard/kit/useNewKit';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const KitName: React.FC<any> = ({ register, errors }) => {
  const { handleKitName } = useNewKit();

  const onNameInputChange = React.useCallback(
    event => {
      if (event.target.value) {
        handleKitName(true);
      } else {
        handleKitName(false);
      }
    },
    [handleKitName],
  );

  return (
    <S.Wrapper>
      <S.Title fontWeight={EHeadingWeight.REGULAR} type={EHeadingSize.H5}>
        Nome do kit
      </S.Title>

      <S.InputGroup>
        <S.Label>Nomeie o kit</S.Label>
        <S.Input
          {...register('kit_name')}
          type="text"
          placeholder="Ex. Kit Teste"
          onChange={onNameInputChange}
        />
        {errors.kit_name && <Text isErrorFeedback>{errors.kit_name.message}</Text>}
      </S.InputGroup>
    </S.Wrapper>
  );
};

export default KitName;
