import styled from 'styled-components/macro';

import CustomDropDownBase from '@components/common/core/Utils/CustomDropDown';

export const ProviderOption = styled.button`
  width: 100%;
  padding: 12px 20px;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin: unset;
  background: unset;
  border: unset;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.font};
  text-align: left;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[6]};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.gray[6]};
  }
`;

export const ProviderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.black.light};
  max-height: 356px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 4px;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    position: absolute;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[6]};
    border-radius: 25px;
    transition: all 0.3s;
  }

  ::-webkit-scrollbar-thumb:hover {
    opacity: brightness(0.8);
  }
`;

export const Arrow = styled(CustomDropDownBase.Arrow)`
  fill: ${({ theme }) => theme.colors.black.light};
`;

export const Content = styled(CustomDropDownBase.Content)`
  padding: unset;
`;

export const DropDownTriggerContent = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.font};
  font-family: ${({ theme }) => theme.fonts.body};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DropDownTrigger = styled(CustomDropDownBase.Trigger)`
  border: unset;
  padding: unset;
  background: ${({ theme }) => theme.colors.gray[6]};
  height: 32px;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  border-radius: 5px;
  transition: all 0.3s;
  padding: 8px 7px;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const DropDown = styled(CustomDropDownBase)``;
