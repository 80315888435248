import React from 'react';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { useTax } from '@helpers/hooks/pages/dashboard/tax/useTax';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Header: React.FC = () => {
  const { isLoadingTax, isValidatingTax, handleNewTaxSideModalOpen } = useTax();

  if (isLoadingTax || isValidatingTax) {
    return <SkeletonLoading />;
  }

  return (
    <S.Wrapper>
      <S.Title>Impostos</S.Title>

      <S.AddNewTax variant={EButtonVariant.SECONDARY} onClick={handleNewTaxSideModalOpen}>
        Configurar imposto
      </S.AddNewTax>
    </S.Wrapper>
  );
};

export default Header;
