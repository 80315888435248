import React from 'react';
import valid from 'card-validator';

import chip from '@assets/subscription/card/chip.svg';
import visaIcon from '@assets/subscription/card/issuers/visa.svg';
import mastercardIcon from '@assets/subscription/card/issuers/mastercard.svg';
import dinersIcon from '@assets/subscription/card/issuers/diners.svg';
import eloIcon from '@assets/subscription/card/issuers/elo.svg';
import amexIcon from '@assets/subscription/card/issuers/amex.svg';

import * as S from './styles';

const CreditCard: React.FC<any> = ({ getValues, watch }) => {
  watch(['number', 'full_name', 'expiration', 'verification_value']);

  const [activeElementFocused, setActiveElementFocused] = React.useState<Element | null>(
    document.activeElement,
  );

  const isCvvFocused = (activeElementFocused as HTMLInputElement)?.name === 'verification_value';
  const cardNumber = getValues('number') || '';
  const cardName = getValues('full_name') || '';
  const expirationDate = getValues('expiration') || '';
  const cvv = getValues('verification_value') || '';

  const getIssuer = React.useCallback((): string | undefined => {
    const cardValidation = valid.number(cardNumber);

    if (cardValidation.card?.niceType === 'Visa') return visaIcon;

    if (cardValidation.card?.niceType === 'Mastercard') return mastercardIcon;

    if (cardValidation.card?.niceType === 'Elo') return eloIcon;

    if (cardValidation.card?.niceType === 'Diners Club') return dinersIcon;

    if (cardValidation.card?.niceType === 'American Express') return amexIcon;

    return undefined;
  }, [cardNumber]);

  const formatCardNumber = React.useCallback(() => {
    const parsedCardNumber = cardNumber.replaceAll(' ', '');

    let asteriskQuantity = 16 - (parsedCardNumber.length || 0);
    const issuer = valid.number(parsedCardNumber);

    if (issuer.card?.niceType === 'Diners Club') {
      asteriskQuantity -= 2;
    }

    if (issuer.card?.niceType === 'American Express') {
      asteriskQuantity -= 1;
    }

    let asteriskText = '';

    for (let i = 0; i < asteriskQuantity; i += 1) {
      asteriskText += '*';
    }

    const parsedText = `${cardNumber}${asteriskText}`;

    const formattedText = parsedText
      .replace(/([0-9*]{4})([0-9*])/, '$1 $2')
      .replace(/([0-9*]{4})([0-9*])/, '$1 $2')
      .replace(/([0-9*]{4})([0-9*])/, '$1 $2');

    return formattedText;
  }, [cardNumber]);

  React.useEffect(() => {
    const onFocus = (event: any): void => setActiveElementFocused(event.target);
    const onBlur = (): void => setActiveElementFocused(null);

    window.addEventListener('focus', onFocus, true);
    window.addEventListener('blur', onBlur, true);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return (
    <S.Card>
      <S.FlipCard isCvvFocused={isCvvFocused}>
        <S.FrontCard>
          <S.ChipAndIssuerWrapper>
            <S.Chip src={chip} alt="Profitfy.me Cartão Chip" />

            {getIssuer() && <S.IssuerLogo src={getIssuer() || ''} alt="Profitfy.me Cartão" />}
          </S.ChipAndIssuerWrapper>
          <S.CardNumber>{formatCardNumber()}</S.CardNumber>

          <S.NameAndExpirationDateWrapper>
            <S.Name>{cardName.toUpperCase()}</S.Name>

            <S.ExpirationDate>{expirationDate}</S.ExpirationDate>
          </S.NameAndExpirationDateWrapper>
        </S.FrontCard>

        <S.BackCard>
          <S.BlackBar />

          <S.CvvWrapper>
            <S.RandomBar>
              <S.Line />
              <S.Line />
            </S.RandomBar>

            <S.Cvv>{cvv}</S.Cvv>
          </S.CvvWrapper>
        </S.BackCard>
      </S.FlipCard>
    </S.Card>
  );
};

export default CreditCard;
