import React from 'react';
import { Calendar } from '@profitfy/pakkun-icons';
import { useDate } from '@helpers/hooks/useDate';
import { useParams } from 'react-router-dom';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import {
  ESynchronizationRequestType,
  ESynchronizationType,
} from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';

import { useCheckoutFee } from '@helpers/hooks/pages/dashboard/checkoutFee/useCheckoutFee';
import { useToast } from '@helpers/hooks/useToast';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import { getEnvironment } from '@helpers/utils/environment';

import { LONG_API_DOMAIN } from '@constants/api';

import Save from '@components/common/core/DataDisplay/SideModal/Save';

import * as S from './styles';

const SyncPastCheckoutFeeSidemodal: React.FC = () => {
  const {
    isSyncPastCheckoutFeeSidemodalOpen,
    handleSyncPastCheckoutFeeSideModalOpen,
    createCheckoutFeePeriod,
    defaultCheckoutFee,
  } = useCheckoutFee();
  const { utcToZonedTime, format } = useDate();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { addSynchronization } = useSynchronization();

  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [period, setPeriod] = React.useState({
    startDate: utcToZonedTime(new Date()),
    endDate: utcToZonedTime(new Date()),
  });

  const handleOnSave = React.useCallback(async () => {
    setIsSaving(true);

    try {
      const boolean = await createCheckoutFeePeriod({
        amount: defaultCheckoutFee?.amount || 0,
        name: 'any_checkout_fee_name',
        percentage_amount: defaultCheckoutFee?.percentage_amount || 0,
        start_date: format(utcToZonedTime(period.startDate), 'yyyy-MM-dd'),
        end_date: format(utcToZonedTime(period.endDate), 'yyyy-MM-dd'),
      });

      handleSyncPastCheckoutFeeSideModalOpen();

      if (boolean) {
        const params = `?start_date=${format(
          utcToZonedTime(period.startDate),
          'yyyy-MM-dd',
        )}&end_date=${format(utcToZonedTime(period.endDate), 'yyyy-MM-dd')}`;

        addSynchronization({
          name: 'Taxas de Checkout',
          type: ESynchronizationType.STORE_ORDER,
          label: 'Pedidos',
          dependencies: [],
          storeAliasId,
          config: {
            isOnboardingSynchronization: false,
            showNotification: true,
          },
          request: {
            type: ESynchronizationRequestType.POST,
            domain: LONG_API_DOMAIN,
            api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/orders/checkout-fee/sync`,
            requestParams: params,
          },
        });
      }

      setIsSaving(false);
    } catch (error: any) {
      setIsSaving(false);
      toast.error(error?.response?.data?.message);
    }
  }, [
    period,
    format,
    toast,
    utcToZonedTime,
    addSynchronization,
    storeAliasId,
    createCheckoutFeePeriod,
    handleSyncPastCheckoutFeeSideModalOpen,
    defaultCheckoutFee,
  ]);

  const maxDate = utcToZonedTime(new Date());

  return (
    <S.Sidemodal
      isOpen={isSyncPastCheckoutFeeSidemodalOpen}
      toggle={handleSyncPastCheckoutFeeSideModalOpen}
    >
      <S.Header>
        <Calendar size={32} outline />
        <S.Title>Alterar período</S.Title>
      </S.Header>

      <S.Body>
        <S.ProductName fontWeight={EHeadingWeight.REGULAR}>
          Definir periodo para reprocessar
        </S.ProductName>

        <S.CustomDatePicker period={period} setPeriod={setPeriod} maxDate={maxDate} />

        <Save
          cancelContent="Cancelar"
          saveContent="Salvar alterações"
          isSubmitting={isSaving}
          isSaveButtonDisabled={isSaving}
          isDeleteButtonDisabled={isSaving}
          type="button"
          onSave={handleOnSave}
        />
      </S.Body>
    </S.Sidemodal>
  );
};

export default SyncPastCheckoutFeeSidemodal;
