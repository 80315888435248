import React from 'react';

import { ETextWeight } from '@domain/enums/components/EText';

import * as S from './styles';

const ChartTooltip: React.FC = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <S.TooltipCard>
        <S.TooltipTitle weight={ETextWeight.SEMIBOLD}>{label}</S.TooltipTitle>

        {payload.map((objectData: any) => (
          <S.LabelWrapper key={objectData.dataKey}>
            <S.DotColor color={objectData.payload.fill} />
            <S.LabelText>{`${objectData.name}: ${objectData.value}`}</S.LabelText>
          </S.LabelWrapper>
        ))}
      </S.TooltipCard>
    );
  }

  return null;
};

export default ChartTooltip;
