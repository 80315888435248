import React from 'react';

import { ISuggestionCardProps } from '@domain/interfaces/dashboard/Automations/Canvas/SideModal/Integration/ISuggestionIntegrationCard';

import Vote from './Vote';

import { parseIntegration } from './utils';
import * as S from './styles';

const SuggestionCard: React.FC<ISuggestionCardProps> = ({ suggestion }) => {
  const { icon: Icon, name } = parseIntegration(suggestion.title);

  return (
    <S.Wrapper>
      <Icon size={24} />
      <S.Name>{name}</S.Name>
      <S.IconGroup>
        <Vote suggestion={suggestion} />
      </S.IconGroup>
    </S.Wrapper>
  );
};

export default SuggestionCard;
