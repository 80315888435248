import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const CancelButton = styled.button`
  background-color: unset;
  border: unset;
  color: ${({ theme }) => theme.colors.gray[1]};
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.body};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const ConfirmButton = styled(ButtonBase)``;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 28px;
`;

export const Description = styled(TextBase)`
  display: inline-block;
  margin-bottom: 24px;
  line-height: 120%;
`;

export const Title = styled(TextBase)`
  margin-left: 8px;
`;

export const Modal = styled(ModalBase)``;
