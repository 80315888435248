import React from 'react';

import Header from '@components/Dashboard/IdeasChannel/OverviewIdeas/Header';
import Idea from '@components/Dashboard/IdeasChannel/OverviewIdeas/Idea';
import NewIdeaAndSearch from '@components/Dashboard/IdeasChannel/OverviewIdeas/NewIdeaAndSearch';
import Paginate from '@components/common/core/Utils/Paginate';

import { useSuggestions } from '@helpers/hooks/pages/dashboard/ideasChannel/useSuggestions';
import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const OverviewIdeas: React.FC = () => {
  const { analytics, selectedStore, user } = useConfig();
  const { onPageChange, page, suggestions } = useSuggestions();

  const filteredSuggestions = suggestions.filter(
    suggestion => suggestion.description !== 'suggestion_automation',
  );

  const pageCount = Math.ceil(filteredSuggestions?.length / 10);

  React.useEffect(() => {
    analytics?.track(
      'Suggestion Page Viewed',
      {
        email: user?.email,
      },
      { context: { groupId: selectedStore?.alias_id } },
    );
  }, [analytics, selectedStore, user]);

  return (
    <S.Container>
      <Header />

      <NewIdeaAndSearch />

      <Idea />

      {Boolean(filteredSuggestions?.length) && (
        <Paginate forcePage={page} pageCount={pageCount} onPageChange={onPageChange} />
      )}
    </S.Container>
  );
};

export default OverviewIdeas;
