import styled, { css } from 'styled-components/macro';

import ButtonBase from '@components/common/core/Inputs/Button';
import TextBase from '@components/common/core/DataDisplay/Text';
import ImageBase from '@components/common/core/DataDisplay/Image';

import ChatBackgroundImg from '@assets/dashboard/automations/messages/chat-background.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  box-sizing: border-box;
`;

export const Preview = styled.div`
  position: relative;
  width: 390px;
  height: 320px;
  border-radius: 5px;
  box-sizing: border-box;
  background-image: url(${ChatBackgroundImg});
  background-size: cover;
  padding: 8px 18px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden scroll;

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[1]};
    border-radius: 25px;
    margin-bottom: 4px;
  }

  @media only screen and (max-width: 955px) {
    width: 100%;
    height: 220px;
  }
`;

export const TextContent = styled.div<{ isFirstItemOfArray?: boolean }>`
  position: relative;
  background: #fff;
  width: 100%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 6px;
  box-sizing: border-box;

  ${({ isFirstItemOfArray }) =>
    isFirstItemOfArray &&
    css`
      border-radius: 0px 6px 6px 6px;
    `}
`;

export const ImageContent = styled.div`
  position: relative;
  background: #fff;
  width: 100%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 2px;
  box-sizing: border-box;
`;

export const Image = styled(ImageBase)`
  border-radius: 6px;
`;

export const VideoContent = styled.div`
  position: relative;
  background: #fff;
  width: 60%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 2px;
  box-sizing: border-box;
`;

export const Video = styled.video`
  border-radius: 6px;
  width: 100%;
  height: 100%;
`;

export const AudioContent = styled.div`
  background-color: #fff;
  width: 100%;
  min-height: 52px;
  box-sizing: border-box;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  overflow: hidden;
`;

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  min-width: 20px;
`;

export const BulletAndWavesWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const CurrentTimeText = styled(TextBase)`
  position: absolute;
  left: 0;
  bottom: -23px;
  font-weight: 300;
  font-size: 0.6875rem;
  color: #8696a0;
`;

export const TotalTimeText = styled(TextBase)`
  position: absolute;
  right: 0;
  bottom: -23px;
  font-weight: 300;
  font-size: 0.6875rem;
  color: #8696a0;
`;

export const WavesWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1px;
`;

export const AudioBullet = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #4fc3f7;
`;

export const Wave = styled.div<{ height: number }>`
  width: 3px;
  height: ${({ height }) => height}px;
  background-color: #ced0d1;
`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[2]};
`;

export const MessageText = styled(TextBase)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.black.light};
  text-align: left;
`;

export const Time = styled(TextBase)`
  position: absolute;
  right: 4px;
  bottom: 2px;
  font-size: 0.6875rem;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const ContentArrow = styled.div`
  width: 6px;
  height: 6px;
  position: absolute;
  top: 0;
  left: -5px;
  background-color: #fff;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
`;

export const UpdateMessageButton = styled(ButtonBase)`
  height: 40px;
`;
