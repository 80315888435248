import React from 'react';
import { useLocation } from 'react-router-dom';

import { mobileMenuRoutes } from '@constants/mobileMenuRoutes';
import { IMobileNavigation } from '@domain/interfaces/components/IBottomNavigation';
import NavigationButton from './NavigationButton';

import * as S from './styles';

const BottomNavigation: React.FC = () => {
  const { pathname } = useLocation();

  const [selectedNavigation, setSelectedNavigation] = React.useState<IMobileNavigation | undefined>(
    undefined,
  );

  React.useEffect(() => {
    let selectedMenu;

    mobileMenuRoutes.map(menu => {
      if (pathname.includes(menu.url)) {
        selectedMenu = menu;
      }

      return null;
    });

    setSelectedNavigation(selectedMenu);
  }, [pathname]);

  return (
    <S.BottomNavigationWrapper>
      {mobileMenuRoutes.map(menu => (
        <NavigationButton
          icon={menu.icon}
          title={menu.name}
          selected={selectedNavigation?.url === menu.url}
          url={menu.url}
          key={menu.url}
        />
      ))}
    </S.BottomNavigationWrapper>
  );
};

export default BottomNavigation;
