import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import ImageBase from '@components/common/core/DataDisplay/Image';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const ConfirmButton = styled(ButtonBase)`
  margin-left: auto;

  @media only screen and (max-width: 932px) {
    margin-left: unset;
  }
`;

export const Body = styled.div`
  overflow-y: scroll;
  max-height: 456px;
  width: 100%;
  margin-bottom: 40px;
  padding-right: 16px;

  @media only screen and (max-width: 932px) {
    max-height: unset;
  }

  ::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.gray[5]};
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray[1]};
    border-radius: 5px;
    transition: all 0.3s;

    &:hover {
      background: ${({ theme }) => theme.colors.gray[2]};
    }
  }
`;

export const LastUpdatedText = styled(TextBase)`
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray[3]};
  text-align: center;
  margin: 0 40px;
`;

export const Title = styled(HeadingBase)`
  font-size: 1.125rem;
  font-weight: 600;
  text-align: center;
  margin: 0 40px;
`;

export const Logo = styled(ImageBase)`
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
`;

export const Header = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
`;

export const Modal = styled(ModalBase)`
  max-width: 912px;
  width: 100%;
  padding: 40px;
  box-sizing: border-box;

  @media only screen and (max-width: 932px) {
    height: 100vh;
    padding: 64px 40px;
  }
`;
