import React from 'react';

import { useFilter } from '@helpers/hooks/useFilter';

import * as S from './styles';

const ClearFilter: React.FC = () => {
  const { handleData } = useFilter();

  const cleanFilters = React.useCallback(() => {
    handleData([]);
  }, [handleData]);

  return <S.Wrapper onClick={cleanFilters}>Limpar filtros</S.Wrapper>;
};

export default ClearFilter;
