import React from 'react';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { ETextWeight } from '@domain/enums/components/EText';

import { useConfig } from '@helpers/hooks/useConfig';

import PaymentInfoModal from '@components/common/core/Utils/PaymentInfoModal';

import * as S from './styles';

const ProcessingPayment: React.FC = () => {
  const { analytics, user, selectedStore } = useConfig();

  const [isPaymentInfoModalOpen, setIsPaymentInfoModalOpen] = React.useState<boolean>(false);

  const handlePaymentInfoModal = React.useCallback(() => {
    if (!isPaymentInfoModalOpen) {
      analytics?.track(
        'Checkout Payment Declined Button Clicked',
        {
          email: user?.email,
        },
        {
          context: { groupId: selectedStore?.alias_id },
        },
      );
    }

    setIsPaymentInfoModalOpen(state => !state);
  }, [isPaymentInfoModalOpen, user, selectedStore, analytics]);

  React.useEffect(() => {
    analytics?.track(
      'Checkout Payment Declined Viewed',
      {
        email: user?.email,
      },
      {
        context: { groupId: selectedStore?.alias_id },
      },
    );
  }, [analytics, user, selectedStore]);

  return (
    <S.Container>
      <S.Wrapper>
        <S.Description weight={ETextWeight.BOLD}>
          Oops, seu pagamento foi recusado! Por favor atualize seu cartão de crédito para continuar
          utilizando a plataforma.
        </S.Description>

        <S.UpdatePaymentInfoButton
          onClick={handlePaymentInfoModal}
          variant={EButtonVariant.SECONDARY_BUTTON}
        >
          Atualizar cartão de crédito
        </S.UpdatePaymentInfoButton>
      </S.Wrapper>

      <PaymentInfoModal isOpen={isPaymentInfoModalOpen} toggle={handlePaymentInfoModal} />
    </S.Container>
  );
};

export default ProcessingPayment;
