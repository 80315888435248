import React from 'react';
import { useParams } from 'react-router-dom';

import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';
import { IAdSenseAccount } from '@domain/interfaces/dashboard/AdSense/IAdSenseAccount';
import {
  IGoogleAdAccountsProviderProps,
  IGoogleAdAccountsProvider,
} from '@domain/interfaces/integrations/marketing/google/IGoogleAdAccounts';

import { useToast } from '@helpers/hooks/useToast';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useGoogleCredentials } from '@helpers/hooks/common/integrations/marketing/google/useGoogleCredentials';

import { ADS_PROVIDERS } from '@constants/common/integrations';

import googleAccountService from '@services/pages/dashboard/adSense/google/googleAccount';
import adSenseAccountService from '@services/pages/dashboard/adSense/adSenseAccount';

const GoogleAdAccountsContext = React.createContext<IGoogleAdAccountsProvider | null>(null);

export const GoogleAdAccountsProvider: React.FC<IGoogleAdAccountsProviderProps> = ({
  adSenseCredentialAliasId,
  children,
}) => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { adAccountSearchName } = useGoogleCredentials();
  const { addSynchronization } = useSynchronization();

  const [adSenseAccountsPage, setAdSenseAccountsPage] = React.useState<number>(0);
  const [adSenseAccountPageCount, setAdSenseAccountsPageCount] = React.useState<number>(1);
  const [adSenseAccounts, setAdSenseAccounts] = React.useState<Array<IAdSenseAccount>>([]);
  const [isLoadingAdSenseAccounts, setIsLoadingAdSenseAccounts] = React.useState<boolean>(false);
  const [isAdSenseAccountsError, setIsAdSenseAccountsError] = React.useState<boolean>(false);

  const loadAdSenseAccounts = React.useCallback(async () => {
    setIsLoadingAdSenseAccounts(true);
    setIsAdSenseAccountsError(false);

    try {
      const { data: adSenseAccountsData } = await adSenseAccountService.listAdSenseAccountsPromise({
        storeAliasId,
        adSenseCredentialAliasId,
        page: adSenseAccountsPage + 1,
        filter: {
          provider: EAdSenseProvider.GOOGLE,
          name: adAccountSearchName,
        },
      });

      if (adSenseAccountsData) {
        setAdSenseAccounts(currentAdSenseAccounts => [
          ...currentAdSenseAccounts,
          ...adSenseAccountsData.ad_sense_accounts,
        ]);
        setAdSenseAccountsPageCount(adSenseAccountsData.total_pages);
      }

      setIsLoadingAdSenseAccounts(false);
    } catch (error: any) {
      setIsLoadingAdSenseAccounts(false);
      setIsAdSenseAccountsError(true);
      toast.error(error?.response?.data?.message);
    }
  }, [adAccountSearchName, adSenseAccountsPage, storeAliasId, adSenseCredentialAliasId, toast]);

  const activateAdSenseAccount = React.useCallback(
    (adSenseAccountAliasId, data) => {
      const foundGoogleIntegration = ADS_PROVIDERS.find(
        integration => integration.identifier === EAdSenseProvider.GOOGLE,
      ) as typeof ADS_PROVIDERS[number];

      const { generateSyncRequest } = foundGoogleIntegration;

      const aliases = {
        adSenseAccountAliasId,
        adSenseCredentialAliasId,
      };

      addSynchronization({
        name: 'Google Ads',
        type: ESynchronizationType.UPDATE_AD_SENSE_ACCOUNTS,
        label: 'Perfil',
        dependencies: [],
        storeAliasId,
        externalId: adSenseAccountAliasId,
        config: {
          isOnboardingSynchronization: false,
          showNotification: true,
        },
        request: generateSyncRequest(
          ESynchronizationType.UPDATE_AD_SENSE_ACCOUNTS,
          storeAliasId,
          aliases,
          data,
        ),
      });
    },
    [adSenseCredentialAliasId, addSynchronization, storeAliasId],
  );

  const deactiveAdSenseAccount = React.useCallback(
    async (adSenseAccountAliasId, data) => {
      try {
        await googleAccountService.updateGoogleAccount({
          storeAliasId,
          adSenseAccountAliasId,
          adSenseCredentialAliasId: adSenseCredentialAliasId || '',
          data,
        });
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
    [adSenseCredentialAliasId, storeAliasId, toast],
  );

  const resetInfiniteScroll = React.useCallback(() => {
    setAdSenseAccounts([]);
    setAdSenseAccountsPage(0);
  }, []);

  const updateAdSenseAccount = React.useCallback(
    async (adSenseAccountAliasId, data) => {
      if (data?.is_active) {
        activateAdSenseAccount(adSenseAccountAliasId, data);
      } else {
        await deactiveAdSenseAccount(adSenseAccountAliasId, data);
      }
    },
    [activateAdSenseAccount, deactiveAdSenseAccount],
  );

  const handleLoadMore = React.useCallback(() => {
    setAdSenseAccountsPage(oldPage => oldPage + 1);
  }, []);

  React.useEffect(() => {
    resetInfiniteScroll();
  }, [resetInfiniteScroll, adAccountSearchName]);

  const hasMore = adSenseAccountsPage + 1 < adSenseAccountPageCount;

  return (
    <GoogleAdAccountsContext.Provider
      value={{
        adSenseAccounts,
        handleLoadMore,
        hasMore,
        isAdSenseAccountsError,
        isLoadingAdSenseAccounts,
        loadAdSenseAccounts,
        updateAdSenseAccount,
        resetInfiniteScroll,
      }}
    >
      {children}
    </GoogleAdAccountsContext.Provider>
  );
};

export const useGoogleAdAccounts = (): IGoogleAdAccountsProvider => {
  const context = React.useContext(GoogleAdAccountsContext);

  if (!context) {
    throw new Error('useGoogleAdAccounts must be used within GoogleAdAccountsProvider');
  }

  return context;
};
