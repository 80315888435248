import React from 'react';
import FadeIn from 'react-fade-in';
import { useHistory, useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import profitfyLogo from '@assets/img/brand/profitfy-logo.svg';

import ProgressBarHorizontal from '@components/Onboarding/ProgressBarHorizontal';

import * as S from './styles';

const AlmostDone: React.FC = () => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();

  const [showProgressBar, setShowProgressBar] = React.useState<boolean>(false);

  const handleShowProgressBar = React.useCallback(() => {
    const timer = setTimeout(() => {
      setShowProgressBar(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      history.push(`/onboarding/${storeAliasId}/dropshipping/checkout-fee`);
    }, 12000);

    return () => clearTimeout(timeout);
  }, [history, storeAliasId]);

  return (
    <S.Container>
      <S.Wrapper>
        <S.Header>
          <S.IconWrapper>
            <S.LogoProfitfy src={profitfyLogo} alt="Profitfy Logo" />
          </S.IconWrapper>
        </S.Header>
        <S.DetailLeftTop />
        <S.DetailLeftDown />
        <S.DetailRightTop />
        <S.DetailRightMiddle />

        <S.Content>
          <FadeIn delay={1000} transitionDuration={500} onComplete={handleShowProgressBar}>
            <S.Title>Parabéns, você está quase terminando...</S.Title>
            <S.SubTitle>Agora serão feitas as configurações gerais da Loja.</S.SubTitle>
            <S.ProgressTitle>
              Seu <span>progresso</span>
            </S.ProgressTitle>
          </FadeIn>
          {showProgressBar && (
            <S.ProgressContainer>
              <ProgressBarHorizontal />
            </S.ProgressContainer>
          )}
        </S.Content>
      </S.Wrapper>
    </S.Container>
  );
};

export default AlmostDone;
