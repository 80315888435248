import React from 'react';
import { CartPanda as CartPandaIcon } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import Form from '@components/common/core/Inputs/Form';
import { cartPandaSchema } from '@helpers/validators/dashboard/integrationCenter/cartPanda';
import Text from '@components/common/core/DataDisplay/Text';
import { useToast } from '@helpers/hooks/useToast';
import cartPandaService from '@services/pages/dashboard/integrationCenter/storeProvider/cartPanda/cartPandaAuth';
import { IParams } from '@domain/interfaces/IParams';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { ECartPandaType } from '@domain/enums/common/integrations/providers/ECartPanda';

import ActiveIntegrationFooter from '@components/Dashboard/IntegrationCenter/Sidemodal/ActiveIntegrationFooter';

import * as S from './styles';

const CartPanda: React.FC = () => {
  const { mutateStore } = useStoreConfig();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(cartPandaSchema),
  });
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);

  const sanitizeUrl = React.useCallback(url => {
    const replacedUrl = url.replace('https://', '').replace('wwww.', '');

    const parsedUrl = replacedUrl.split('.mycartpanda.com')[0];

    return parsedUrl;
  }, []);

  const onSubmit = React.useCallback(
    async data => {
      setIsSubmittingForm(true);

      const sanitizedUrl = sanitizeUrl(data.domain);

      try {
        await cartPandaService.createCredential({
          storeAliasId,
          data: {
            access_token: data.access_token,
            domain: sanitizedUrl,
            types: [ECartPandaType.STORE],
          },
        });

        toast.success('Credencial cadastrada com sucesso!');

        mutateStore();

        setIsSubmittingForm(false);
      } catch (error: any) {
        setIsSubmittingForm(false);
        toast.error(getTranslatedIntegrationsError(error));
      }
    },
    [storeAliasId, toast, sanitizeUrl, mutateStore],
  );

  return (
    <S.ContentWrapper>
      <S.Header>
        <CartPandaIcon size={52} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com CartPanda
        </S.Heading>
      </S.Header>

      <S.TutorialWrapper>
        <S.TutorialHeading type={EHeadingSize.H5}>Tutorial de integração</S.TutorialHeading>
        <S.TutorialText>
          Basta adicionar a URL da sua loja e o Access Token no campo abaixo.
        </S.TutorialText>
      </S.TutorialWrapper>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label htmlFor="cart-panda-store-url">URL da Loja</S.Label>

          <S.InputWrapper>
            <S.TextField {...register('domain')} type="text" id="cart-panda-store-url" />
            <S.AppendContent>.mycartpanda.com</S.AppendContent>
          </S.InputWrapper>
          {errors.domain && <Text isErrorFeedback>{errors.domain.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label htmlFor="access-token">Access Token</S.Label>

          <S.InputWrapper>
            <S.TextField {...register('access_token')} type="text" id="access-token" />
          </S.InputWrapper>
          {errors.access_token && <Text isErrorFeedback>{errors?.access_token?.message}</Text>}
        </S.InputGroup>

        <ActiveIntegrationFooter isIntegrating={isSubmittingForm} />
      </Form>
    </S.ContentWrapper>
  );
};

export default CartPanda;
