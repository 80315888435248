import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';
import { GoBack as GoBackIcon } from '@profitfy/pakkun-icons';

import { ETextWeight } from '@domain/enums/components/EText';
import { IGoBackProps } from '@domain/interfaces/downgrade/sideBar/ISideBar';

import * as S from './styles';

const GoBack: React.FC<IGoBackProps> = ({ previousRouteLink }) => {
  const theme = useTheme();
  const history = useHistory();

  const handleGoBack = React.useCallback(() => {
    history.push(previousRouteLink);
  }, [history, previousRouteLink]);

  return (
    <S.Wrapper onClick={handleGoBack}>
      <GoBackIcon size={18} color={theme.colors.gray[1]} />
      <S.Text weight={ETextWeight.LIGHT}>Retornar para o passo anterior</S.Text>
    </S.Wrapper>
  );
};

export default GoBack;
