import styled from 'styled-components/macro';

export const Container = styled.div`
  max-width: 1262px;
  width: 100%;
  display: flex;
  gap: 68px;
  padding-top: 24px;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 32px;
  }
`;
