/* eslint-disable dot-notation */
import React from 'react';
import { useTheme } from 'styled-components';
import { Menu } from '@profitfy/pakkun-icons';
import { EyeSlash, Eye } from 'phosphor-react';
import { useLocation, useHistory } from 'react-router-dom';

import { EUserStoreRole } from '@domain/enums/common/userStore/EUserStoreRole';
import { EAvatarSize } from '@domain/enums/components/EAvatar';
import { IStore } from '@domain/interfaces/stores/IStore';

import { useConfig } from '@helpers/hooks/useConfig';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import Text from '@components/common/core/DataDisplay/Text';
import Dropdown from './Dropdown';
import Notification from './Notification';

import * as S from './styles';

const NavBar: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { feedQuantity, store, accessManagementData } = useStoreConfig();
  const { pathname } = useLocation();
  const {
    user,
    analytics,
    isDashboardDataHidden,
    handleHideDashboardData,
    isInternalTeam,
  } = useConfig();

  const [currentStore, setCurrentStore] = React.useState<IStore>();

  const onFeedClick = React.useCallback(() => {
    analytics?.track(
      'Task Feed Button Clicked',
      { feed_elements_quantity: feedQuantity, email: user?.email },
      { context: { groupId: currentStore?.alias_id } },
    );

    const foundStoreAliasId = pathname.match(/store-([a-zA-Z0-9]+)/)?.pop();

    history.push(`/store-${foundStoreAliasId}/dashboard/feed`);
  }, [history, pathname, analytics, feedQuantity, currentStore, user]);

  React.useEffect(() => {
    if (store) {
      setCurrentStore(store);
    }
  }, [store]);

  React.useEffect(() => {
    let widget: any;

    const config = {
      key: '9346df30-3ffd-4e1d-9a5b-86d17ca4a4f2',
      callbacks: {
        onReady: (frillWidget: any) => {
          widget = frillWidget;
        },
      },
    };

    if ('Frill' in window) {
      widget = (window['Frill'] as Record<string, any>).widget(config);
    } else {
      window['Frill_Config'] = window['Frill_Config'] || [];
      window['Frill_Config'].push(config);
    }

    return () => {
      if (widget) {
        widget.destroy();
      }
      if (window['Frill_Config']) {
        window['Frill_Config'] = window['Frill_Config'].filter((c: any) => c !== config);
      }
    };
  }, []);

  const trackNewsButton = React.useCallback(() => {
    return analytics?.track(
      'New Features Post Viewed',
      { email: user?.email },
      { context: { groupId: currentStore?.alias_id } },
    );
  }, [analytics, user, currentStore]);

  const isFeedQuantityGreaterThanZero = feedQuantity > 0;

  const isStoreOwner = accessManagementData?.role === EUserStoreRole.OWNER;
  const hasAccessToFeed = accessManagementData?.group?.has_feed_full_write_access;
  const shouldBeShowingFeedButton =
    (isInternalTeam || isStoreOwner || hasAccessToFeed) && store?.is_onboarding_finished;

  return (
    <S.NavBar>
      <S.TitleWrapper>
        <Menu size={24} />
        <Text>PROFITFY</Text>
      </S.TitleWrapper>

      <S.MenuWrapper>
        <S.Tooltip>
          <S.TooltipTrigger>
            <S.HideDashboardDataIconWrapper onClick={handleHideDashboardData}>
              {isDashboardDataHidden ? (
                <EyeSlash size={24} color={theme.colors.gray[3]} />
              ) : (
                <Eye size={24} color={theme.colors.gray[3]} />
              )}
            </S.HideDashboardDataIconWrapper>
          </S.TooltipTrigger>

          <S.TooltipContent>
            <S.TooltipContentText>
              {isDashboardDataHidden
                ? 'Mostrar informações sensíveis'
                : 'Esconder informações sensíveis'}
            </S.TooltipContentText>

            <S.TooltipArrow />
          </S.TooltipContent>
        </S.Tooltip>

        {shouldBeShowingFeedButton && (
          <S.FeedButton onClick={onFeedClick}>
            {isFeedQuantityGreaterThanZero ? (
              <S.FeedQuantity>{feedQuantity}</S.FeedQuantity>
            ) : (
              <></>
            )}
            Avisos
          </S.FeedButton>
        )}

        <S.NewsButton id="my-frill-widget" className="my-frill-widget" onClick={trackNewsButton}>
          Novidades
        </S.NewsButton>

        <Notification />

        <Dropdown>
          <S.CurrentStoreWrapper>
            <S.StoreAvatar
              word={currentStore?.name.charAt(0) || 'N'}
              size={EAvatarSize.MEDIUM}
              alt="Avatar"
              isHiddenContent={isDashboardDataHidden}
            />
            <S.UserDetails>
              <S.CurrentStoreName isHiddenContent={isDashboardDataHidden}>
                {currentStore?.name}
              </S.CurrentStoreName>
              <S.ProfileName
                isHiddenContent={isDashboardDataHidden}
              >{`${user.first_name} ${user.last_name}`}</S.ProfileName>
            </S.UserDetails>
          </S.CurrentStoreWrapper>
        </Dropdown>
      </S.MenuWrapper>
    </S.NavBar>
  );
};

export default React.memo(NavBar);
