import styled, { css } from 'styled-components/macro';

import LinkBase from '@components/common/core/Navigation/Link';
import Image from '@components/common/core/DataDisplay/Image';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';

export const Container = styled.div`
  width: 100vw;
  display: flex;
`;

export const LeftSide = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 20px;
  background: #14161a;

  @media only screen and (max-width: 1080px) {
    width: 100vw;
  }
`;

export const ContentWrapper = styled.div`
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 374px;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
`;

export const Logo = styled(Image)`
  max-width: 200px;
  width: 100%;
`;

export const TitleAndSubTitleWrapper = styled.div`
  margin-top: 70px;
  margin-bottom: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const SubTitle = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const ExternalLoginButton = styled.button`
  width: 100%;
  height: 42px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-weight: 400;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray[1]};
  border: 0;
  padding: 12px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const ExternalLoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export const InputGroup = styled(InputGroupBase)`
  max-width: 374px;
  width: 100%;

  &:first-of-type {
    margin-bottom: 24px;

    @media only screen and (max-width: 1368px) {
      margin-bottom: 16px;
    }
  }
`;

export const Label = styled(LabelBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const Input = styled(TextFieldBase)`
  & > div {
    background-color: ${({ theme }) => theme.colors.gray[6]};
    border-color: ${({ theme }) => theme.colors.gray[6]};

    & > input {
      color: ${({ theme }) => theme.colors.gray[1]};

      &::placeholder {
        color: ${({ theme }) => theme.colors.gray[3]};
      }

      &::-ms-input-placeholder {
        color: ${({ theme }) => theme.colors.gray[3]};
      }

      &::-ms-input-placeholder {
        color: ${({ theme }) => theme.colors.gray[3]};
      }
    }

    ${({ isError, theme, value }) =>
      Boolean(value) &&
      Boolean(!isError) &&
      css`
        border-color: ${theme.colors.success.default};
        background-color: ${theme.colors.black.default};
      `}

    ${({ isError, theme }) =>
      Boolean(isError) &&
      css`
        border-color: ${theme.colors.danger.default};
        background-color: ${theme.colors.black.default};
      `}
  }
`;

export const LoginButton = styled(ButtonBase)`
  max-width: 374px;
  width: 100%;
  margin: 28px 0 12px 0;
`;

export const Link = styled(LinkBase)`
  margin-left: auto;
  font-size: 0.875rem;
`;

export const HorizontalRulesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
`;

export const HorizontalRule = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.gray[5]};
  width: 162px;

  @media only screen and (max-width: 420px) {
    width: 130px;
  }

  @media only screen and (max-width: 360px) {
    width: 100px;
  }
`;

export const HorizontalRuleText = styled(TextBase)`
  margin: 0 18px;
`;

export const NoAccountText = styled(TextBase)`
  display: flex;
  margin-left: auto;
  gap: 4px;
  font-size: 0.875rem;
`;
