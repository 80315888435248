import React from 'react';

import { MarketingCircle } from '@profitfy/pakkun-icons';
import { CircleSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { useToast } from '@helpers/hooks/useToast';

import synchronizationService from '@services/hooks/synchronization';

import * as S from './styles';

const SyncingMarketing: React.FC = () => {
  const theme = useTheme();
  const { toast } = useToast();
  const {
    marketingSynchronizationId,
    setMarketingSynchronizationId,
    mutateFinancialDashboard,
    loadChart,
  } = useFinancialDashboard();

  const { storeAliasId } = useParams<IParams>();

  const pingProfileSynchronization = React.useCallback(
    async (
      synchronizationId: string,
      interval: NodeJS.Timeout,
      selectedStoreAliasId: string | undefined,
    ) => {
      try {
        const { data } = await synchronizationService.getSynchronization({
          storeAliasId: selectedStoreAliasId,
          synchronization_id: synchronizationId,
        });

        if (data.synchronization.status === 'COMPLETED') {
          toast.success('Marketing sincronizado com sucesso!');
          setMarketingSynchronizationId(null);
          Promise.all([mutateFinancialDashboard(), loadChart()]);
          clearInterval(interval);
        }

        if (data.synchronization.status === 'ERROR') {
          toast.error(
            'Algumas contas de anúncio podem ter falhado na sincronização. Verifique suas contas em integrações',
          );
          setMarketingSynchronizationId(null);
          Promise.all([mutateFinancialDashboard(), loadChart()]);
          clearInterval(interval);
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        clearInterval(interval);
      }
    },
    [setMarketingSynchronizationId, toast, mutateFinancialDashboard, loadChart],
  );

  React.useEffect(() => {
    if (marketingSynchronizationId) {
      const interval = setInterval(() => {
        pingProfileSynchronization(marketingSynchronizationId, interval, storeAliasId);
      }, 15000);

      return () => clearInterval(interval);
    }

    return undefined;
  }, [marketingSynchronizationId, pingProfileSynchronization, storeAliasId]);

  return (
    <S.CardWrapper>
      <S.Content>
        <MarketingCircle size={40} />

        <S.SyncContent>
          <S.Text>Sincronizando custo de marketing</S.Text>
          <CircleSpinner size={24} color={theme.colors.green.default} />
        </S.SyncContent>
      </S.Content>
    </S.CardWrapper>
  );
};

export default SyncingMarketing;
