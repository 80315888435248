import React from 'react';

import { ChevronDown } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { EAvatarSize } from '@domain/enums/components/EAvatar';
import { ETextSize } from '@domain/enums/components/EText';
import { EButtonSize } from '@domain/enums/components/EButton';
import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const MultiStoresSelect: React.FC = ({ ...props }) => {
  const theme = useTheme();

  const { analytics, selectedStore: currentSelectedStore } = useConfig();

  const {
    loadMultiStoresData,
    activeMultiSelectStoresMode,
    deactiveMultiSelectStoresMode,
    reloadPage,
    isMultiStoreSelectMode,
  } = useFinancialDashboard();

  const { store: selectedCurrentStore, stores } = useStoreConfig();

  const [selectedStores, setSelectedStores] = React.useState<string[]>([]);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);

  const [
    isSelectAllStoresButtonClicked,
    setIsSelectAllStoresButtonClicked,
  ] = React.useState<boolean>(false);

  const filteredStoresWithoutCurrentStore = stores.filter(
    store => store.alias_id !== selectedCurrentStore.alias_id,
  );

  const hasStoresSelected = Boolean(selectedStores.length);

  const handlePopoverOpen = React.useCallback(() => {
    setIsPopoverOpen(currentState => !currentState);
  }, []);

  const onCheckboxChange = React.useCallback(
    event => {
      if (!event || event.target) return;

      if (event.checked) {
        const newStoreAliasId = event.value;

        setSelectedStores(currentSelectedStores => [...currentSelectedStores, newStoreAliasId]);
      } else {
        const filteredStores = selectedStores?.filter(
          selectedStore => selectedStore !== event.value,
        );

        setSelectedStores(filteredStores);
      }
    },
    [selectedStores],
  );

  const onSelectAllStores = React.useCallback(() => {
    if (!isSelectAllStoresButtonClicked) {
      setIsSelectAllStoresButtonClicked(true);

      const storeAliases = filteredStoresWithoutCurrentStore.map(store => store.alias_id);

      setSelectedStores(storeAliases);
    } else {
      setIsSelectAllStoresButtonClicked(false);
      setSelectedStores([]);
    }
  }, [filteredStoresWithoutCurrentStore, isSelectAllStoresButtonClicked]);

  const onCancel = React.useCallback(() => {
    handlePopoverOpen();
    setSelectedStores([]);

    if (isMultiStoreSelectMode) {
      deactiveMultiSelectStoresMode();
      reloadPage();
    }

    if (isSelectAllStoresButtonClicked) setIsSelectAllStoresButtonClicked(false);
  }, [
    handlePopoverOpen,
    isSelectAllStoresButtonClicked,
    reloadPage,
    isMultiStoreSelectMode,
    deactiveMultiSelectStoresMode,
  ]);

  const onApplyMultiStoreSelect = React.useCallback(async () => {
    loadMultiStoresData(selectedStores);
    handlePopoverOpen();

    if (hasStoresSelected) {
      activeMultiSelectStoresMode();
    }

    if (hasStoresSelected) {
      analytics?.track(
        'Multiselection Stores Button Clicked',
        {},
        { context: { groupId: currentSelectedStore?.alias_id } },
      );
    }
  }, [
    loadMultiStoresData,
    selectedStores,
    handlePopoverOpen,
    activeMultiSelectStoresMode,
    analytics,
    currentSelectedStore,
    hasStoresSelected,
  ]);

  const currentStoreAvatarWord = selectedCurrentStore?.name.charAt(0) || 'N';

  return (
    <S.Wrapper {...props}>
      <S.Popover open={isPopoverOpen} onOpenChange={handlePopoverOpen}>
        <S.PopoverTrigger>
          Multiseleção de lojas <mark>({selectedStores.length + 1})</mark>
          <ChevronDown size={16} color={theme.colors.gray[1]} />
        </S.PopoverTrigger>

        <S.PopoverContent sideOffset={5} side="bottom">
          <S.CurrentStoreWrapper>
            <S.CurrentStoreAvatar word={currentStoreAvatarWord} size={EAvatarSize.SMALL} />
            <S.CurrentStoreName size={ETextSize.B5}>
              {selectedCurrentStore?.name}
            </S.CurrentStoreName>
          </S.CurrentStoreWrapper>

          <S.SelectStoresWrapper>
            <S.SelectOtherStoresText size={ETextSize.B5}>
              Selecionar outras lojas
            </S.SelectOtherStoresText>

            <S.SelectAllStoresButton onClick={onSelectAllStores}>
              {!isSelectAllStoresButtonClicked ? 'Selecionar todas' : 'Deselecionar todas'}
            </S.SelectAllStoresButton>
          </S.SelectStoresWrapper>

          <S.StoresWrapper>
            {filteredStoresWithoutCurrentStore?.map(store => {
              const isCheckBoxChecked = selectedStores.includes(store.alias_id);

              return (
                <S.StoreRow>
                  <S.AvatarAndStoreNameWrapper>
                    <S.StoreAvatar word={store.name.charAt(0) || 'N'} size={EAvatarSize.SMALL} />
                    <S.StoreName>{store.name}</S.StoreName>
                  </S.AvatarAndStoreNameWrapper>

                  <S.StoreCheckBox
                    id={store.alias_id}
                    value={store.alias_id}
                    checked={isCheckBoxChecked}
                    onChange={onCheckboxChange}
                  />
                </S.StoreRow>
              );
            })}
          </S.StoresWrapper>

          <S.ButtonsWrapper>
            <S.CancelButton onClick={onCancel}>Cancelar</S.CancelButton>

            <S.ApplyMultiStoreSelectButton
              buttonSize={EButtonSize.SMALL}
              onClick={onApplyMultiStoreSelect}
            >
              Aplicar
            </S.ApplyMultiStoreSelectButton>
          </S.ButtonsWrapper>

          <S.PopoverArrow />
        </S.PopoverContent>
      </S.Popover>
    </S.Wrapper>
  );
};

export default MultiStoresSelect;
