import styled from 'styled-components/macro';

import FormBase from '@components/common/core/Inputs/Form';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import CustomDatePickerBase from '@components/common/core/Utils/CustomDatePicker';

export const CustomDatePicker = styled(CustomDatePickerBase)`
  label {
    font-size: 0.875rem;
    font-weight: 400;
    font-family: ${({ theme }) => theme.fonts.body};
  }
`;

export const Checkbox = styled(CheckboxBase)``;

export const CheckboxInputGroup = styled(InputGroupBase)`
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  box-sizing: border-box;
  margin-bottom: 32px;

  & > label {
    margin-bottom: unset;
    cursor: pointer;
    font-size: 1rem;
  }
`;

export const Input = styled(TextFieldBase)``;

export const Option = styled(OptionBase)``;

export const Select = styled(SelectBase)`
  width: 100%;

  & > label > select {
    width: 100%;
  }
`;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 32px;
  box-sizing: border-box;
`;

export const CurrencyInputGroup = styled(InputGroupBase)`
  max-width: 275px;
  width: 100%;
  margin-bottom: 32px;
`;

export const Form = styled(FormBase)``;

export const BodyWrapper = styled.div`
  padding-top: 32px;
`;
