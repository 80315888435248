import React from 'react';

import { useTheme } from 'styled-components';
import { X as CloseIcon, CheckCircle } from 'phosphor-react';

import { ETextWeight } from '@domain/enums/components/EText';
import { ISuccessModal } from '@domain/interfaces/dashboard/BenefitArea/ISuccessModal';

import * as S from './styles';

const SuccessModal: React.FC<ISuccessModal> = ({ isOpen, toggle }) => {
  const theme = useTheme();

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <CheckCircle size={128} color={theme.colors.green.default} style={{ marginBottom: 48 }} />

      <S.Title weight={ETextWeight.MEDIUM}>Tudo pronto!</S.Title>

      <S.Description>
        Seus dados estão corretos e agora, em até 2 dias úteis um representante da Zendrop entrará
        em contato com você.
      </S.Description>

      <S.ConfirmButton onClick={toggle}>Entendi</S.ConfirmButton>

      <S.CloseIcon onClick={toggle}>
        <CloseIcon size={32} color={theme.colors.gray[1]} />
      </S.CloseIcon>
    </S.Modal>
  );
};

export default SuccessModal;
