import styled from 'styled-components/macro';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';

import SideModalBase from '@components/common/core/DataDisplay/SideModal';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';
import ImageBase from '@components/common/core/DataDisplay/Image';

export const Image = styled(ImageBase)`
  max-width: 490px;
  width: 100%;
`;

export const ExternalTutorialWrapper = styled.div``;

export const CopyButton = styled(ButtonBase)`
  position: absolute;
  right: 16px;
  background-color: unset;
  border: 1px solid ${({ theme }) => theme.colors.green.default};
  bottom: 50%;
  transform: translateY(50%);

  span {
    color: ${({ theme }) => theme.colors.green.default};
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &:hover {
    filter: brightness(1.2);
    background-color: unset;
  }

  &:active {
    filter: brightness(0.9);
    background-color: unset;
  }
`;

export const CodeEditor = styled(SyntaxHighlighter)``;

export const CodeWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 32px;

  pre {
    padding: 0 !important;
    margin: 0 !important;
    background: ${({ theme }) => theme.colors.black.light} !important;
    font-family: ${({ theme }) => theme.fonts.body}!important;
    font-size: 0.875rem !important;
    border-radius: 7px !important;
    padding: 32px !important;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
      position: absolute;
    }

    code {
      span {
        font-size: 0.875rem !important;
      }

      .linenumber {
        min-width: unset !important;
      }
    }
  }
`;

export const UrlParameterWrapper = styled.div``;

export const DescriptionTitle = styled(TextBase)`
  display: inline-block;
  margin-bottom: 12px;
`;

export const AdLink = styled.a`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.green.default};
  text-decoration: underline;
  line-height: 120%;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const AdWithoutUtm = styled.li``;

export const AdsWithoutUtmsList = styled.ul`
  padding: unset;
  padding-left: 16px;
  margin: unset;
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > li::marker {
    color: ${({ theme }) => theme.colors.green.default};
  }
`;

export const AdsWithoutUtmsWrapper = styled.div`
  margin-bottom: 28px;
`;

export const Text = styled(TextBase)``;

export const HowToDoTitle = styled(TextBase)`
  font-size: 1.125rem;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.heading};
`;

export const HowToDoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
`;

export const Title = styled(HeadingBase)`
  display: inline-block;
  margin-bottom: 40px;
`;

export const SideModal = styled(SideModalBase)`
  max-width: 786px;
`;
