import React from 'react';
import { CartPanda as CartPandaIcon } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import { ECartPandaType } from '@domain/enums/common/integrations/providers/ECartPanda';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';

import cartPandaAuthService from '@services/pages/dashboard/integrationCenter/storeProvider/cartPanda/cartPandaAuth';
import ComponentError from '@components/common/core/Utils/ComponentError';
import SkeletonLoading from '../SkeletonLoading';
import EnableIntegration from './EnableIntegration';
import CredentialOption from './CredentialOption';
import SyncOption from './SyncOption';
import GatewayOption from './GatewayOption';

import * as S from './styles';

const CartPandaConfig: React.FC = () => {
  const theme: any = useTheme();
  const { storeAliasId } = useParams<IParams>();

  const {
    cartPandaCredential,
    isLoading,
    isValidating,
    mutate,
    error,
  } = cartPandaAuthService.getCartPandaCredential({ storeAliasId });

  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const onChangeTab = React.useCallback(index => setSelectedTab(index), []);

  if (isLoading || isValidating) {
    return <SkeletonLoading />;
  }

  if (error && !isLoading) {
    return <ComponentError mutate={mutate} />;
  }

  if (!cartPandaCredential.types.includes(ECartPandaType.STORE)) {
    return <EnableIntegration cartPandaCredential={cartPandaCredential} mutate={mutate} />;
  }

  return (
    <S.ContentWrapper>
      <S.Header>
        <CartPandaIcon size={52} color={theme.colors.gray[2]} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.REGULAR}>
          Integração com CartPanda
        </S.Heading>
      </S.Header>

      <S.Tab defaultTab={selectedTab} onChangeTab={onChangeTab}>
        <S.Option>Geral</S.Option>
        <S.Option>Sincronização</S.Option>
        <S.Option>Gateway</S.Option>
      </S.Tab>

      {selectedTab === 0 && (
        <CredentialOption cartPandaCredential={cartPandaCredential} mutate={mutate} />
      )}

      {selectedTab === 1 && <SyncOption />}

      {selectedTab === 2 && <GatewayOption />}
    </S.ContentWrapper>
  );
};

export default CartPandaConfig;
