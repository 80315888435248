import { EButtonVariant } from '@domain/enums/components/EButton';
import React from 'react';

import { ISaveProps } from '@domain/interfaces/onboarding/integrations/ISave';

import * as S from './styles';

const Save: React.FC<ISaveProps> = ({ onSave, onCancel, disabled }) => {
  return (
    <S.Wrapper>
      <S.ButtonsWrapper>
        <S.CancelButton onClick={onCancel} variant={EButtonVariant.TERTIARY}>
          Cancelar
        </S.CancelButton>

        <S.SaveButton onClick={onSave} disabled={disabled}>
          Selecionar integração
        </S.SaveButton>
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export default Save;
