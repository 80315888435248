import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';

export const Image = styled(ImageBase)`
  max-width: 556px;
  margin-top: 32px;
`;

export const Wrapper = styled.div``;
