export const sliceIntoChunks = <T = any>(
  array: Array<T> | undefined,
  chunkSize: number,
): Array<Array<T>> | undefined => {
  if (!array) return undefined;

  const chunkedArray = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);

    chunkedArray.push(chunk);
  }

  return chunkedArray;
};
