import styled, { css } from 'styled-components/macro';

export interface IDataProps {
  isPopularProps?: boolean;
  isFirstData?: boolean;
  isLastData?: boolean;
  showBorder?: boolean;
}

export interface IDataTextProps {
  center?: boolean;
}

export const DataText = styled.span<IDataTextProps>`
  display: inline-block;
  font-family: 'Poppins', 'Open Sans';
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[2]};
  display: flex;
  min-height: 32px;
  align-items: center;

  ${({ center }) =>
    center &&
    css`
      justify-content: center;
    `};
`;

export const FeatureName = styled.span`
  padding: 32px 0 12px 0;
  display: inline-block;
  font-family: 'Poppins', 'Open Sans';
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
`;

export const SignUpButton = styled.button`
  width: 144px;
  background-color: #004eff;
  height: 36px;
  font-family: 'Poppins', 'Open Sans';
  font-size: 0.875rem;
  font-weight: 700;
  border-radius: 65px;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const PlanName = styled.span`
  font-family: 'Poppins', 'Open Sans';
  font-size: 1.25rem;
  font-weight: 500;
  color: #fff;
`;

export const PlanTitleWrapper = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

export const TableData = styled.div<IDataProps>`
  min-height: 32px;

  ${({ isPopularProps }) =>
    isPopularProps &&
    css`
      border-right: 2px solid #33d69f;
      border-left: 2px solid #33d69f;
      background-color: #33d69f1a;
    `};

  ${({ isFirstData, isPopularProps }) =>
    isPopularProps &&
    isFirstData &&
    css`
      border-top: 2px solid #33d69f;
      border-radius: 15px 15px 0 0;
    `};

  ${({ isLastData, isPopularProps }) =>
    isPopularProps &&
    isLastData &&
    css`
      border-bottom: 2px solid #33d69f;
      border-radius: 0 0 15px 15px;
    `};

  ${({ showBorder }) =>
    showBorder &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray[6]};
    `};
`;

export const TableRow = styled.div`
  display: contents;
`;

export const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, auto);
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`;

export const Title = styled.span`
  font-family: 'Poppins', 'Open Sans';
  font-size: 4rem;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 56px;
  line-height: 80px;
  text-align: center;

  @media only screen and (max-width: 948px) {
    font-size: 3rem;
    line-height: 40px;
    text-align: center;
    margin-bottom: 40px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  margin-bottom: 128px;
  margin-top: 64px;
`;
