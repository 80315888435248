import React from 'react';

import { IOptionProps } from '@domain/interfaces/components/IOption';

import * as S from './styles';

const Option: React.FC<IOptionProps> = ({ disabled, value, children, ...rest }) => {
  return (
    <S.Option disabled={disabled} value={value} {...rest}>
      {children}
    </S.Option>
  );
};

export default Option;
