import React from 'react';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { IRoas } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IAdSenseCampaign';

import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const Roas: React.FC<ICampaignProps> = ({ data }) => {
  const roasData: IRoas = data;

  return (
    <S.Wrapper>
      <S.RoasAmount>{`${numberFormatter(roasData.amount, 1)}x`}</S.RoasAmount>
    </S.Wrapper>
  );
};

export default Roas;
