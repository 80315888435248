import React from 'react';
import { LineChart } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { IRoi } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/ISummaryReport';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import { numberFormatter } from '@helpers/masks';

import BlurredContent from './BlurredContent';

import * as S from './styles';

const Roi: React.FC<ICampaignProps> = ({ data, forecastData }) => {
  const roiData: IRoi = data;

  const { store } = useStoreConfig();
  const { isDemoEnvironment } = useConfig();
  const theme = useTheme();

  const getIconColor = React.useCallback(
    amount => {
      if (amount > 0) return theme.colors.success.default;

      if (amount < 0) return theme.colors.danger.default;

      return theme.colors.gray[1];
    },
    [theme],
  );

  const forecastAmount = forecastData?.total_amount || 0;

  const isContentBlurred = !store.is_utm_onboarding_finished && !isDemoEnvironment;

  return (
    <S.Wrapper>
      {isContentBlurred ? (
        <BlurredContent />
      ) : (
        <>
          <S.AverageAmountText amount={roiData.average_amount}>
            {`${numberFormatter(roiData.average_amount, 1)}x`}
          </S.AverageAmountText>

          {Boolean(forecastData) && (
            <S.ForeCastAmount amount={forecastAmount}>
              <LineChart outline size={12} color={getIconColor(forecastAmount)} />
              {`${numberFormatter(forecastAmount, 1)}x`}
            </S.ForeCastAmount>
          )}

          <S.TotalLabel>Total</S.TotalLabel>
        </>
      )}
    </S.Wrapper>
  );
};

export default Roi;
