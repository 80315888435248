import Text from '@components/common/core/DataDisplay/Text';
import { useToast } from '@helpers/hooks/useToast';
import { pushNotificationFormSchema } from '@helpers/validators/dashboard/adminPanel/pushNotification';
import { yupResolver } from '@hookform/resolvers/yup';
import pushNotificationService from '@services/pages/dashboard/adminPanel/pushNotifications';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as S from './styles';

const PushNotification: React.FC = () => {
  const { toast } = useToast();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(pushNotificationFormSchema),
  });

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async formData => {
      setIsSubmitting(true);

      try {
        await pushNotificationService.pushNotification({
          data: {
            title: formData.title,
            message_body: formData.message_body,
            target: formData.target,
          },
        });

        toast.success('Notificação enviada com sucesso.');

        setIsSubmitting(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsSubmitting(false);
      }
    },
    [toast],
  );

  return (
    <S.ContentWrapper>
      <S.Heading>Push notification</S.Heading>

      <S.Content>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label htmlFor="title">Título</S.Label>
            <S.Input
              {...register('title')}
              type="text"
              placeholder="Insira o titulo da notificação"
              id="title"
            />
            {errors.title && <Text isErrorFeedback>{errors.title.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label htmlFor="message_body">Mensagem</S.Label>
            <S.TextArea
              {...register('message_body')}
              placeholder="Insira a mensagem da notificação"
              id="message_body"
            />
            {errors.message_body && <Text isErrorFeedback>{errors.message_body.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label htmlFor="target">Mensagem</S.Label>
            <S.TextArea
              {...register('target')}
              placeholder="Insira os alvos da notificação"
              id="target"
            />
            {errors.target && <Text isErrorFeedback>{errors.target.message}</Text>}
          </S.InputGroup>

          <S.Button type="submit" isLoading={isSubmitting} disabled={isSubmitting}>
            Enviar
          </S.Button>
        </S.Form>
      </S.Content>
    </S.ContentWrapper>
  );
};

export default PushNotification;
