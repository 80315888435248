import { API_DOMAIN } from '@constants/api';
import { IDeleteCredentialProps } from '@domain/interfaces/dashboard/AdminPanel/IDeleteCredential';
import { ApiService } from '@services/api';

export class DeleteCredentialService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public deleteCredential({
    integration_type,
    store_alias_id,
  }: IDeleteCredentialProps): Promise<any> {
    return this.apiService.delete(
      `/api/v1/admin/users/stores/${store_alias_id}/integration-credentials/${integration_type}`,
    );
  }
}

const deleteCredentialService = new DeleteCredentialService(API_DOMAIN);

export default deleteCredentialService;
