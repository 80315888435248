import IWorkflowNode from '@domain/interfaces/mappers/automations/profitfy/IWorkflowNode';
import IReactFlowWorkflowNode from '@domain/interfaces/mappers/automations/reactFlow/IWorkflowNode';

import nodeTypeMapper from './nodeTypeMapper';

const map = ({
  data,
  height,
  id,
  position,
  type,
  width,
}: IReactFlowWorkflowNode): IWorkflowNode => {
  const mappedType = nodeTypeMapper.map(type);

  const { metrics, ...rest } = data;

  const mappedNode: IWorkflowNode = {
    provider_id: id,
    width,
    height,
    metadata: {
      data: rest,
    },
    position_x: position.x,
    position_y: position.y,
    name: data.name,
    type: mappedType,
  };

  return mappedNode;
};

export default { map };
