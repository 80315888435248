import React from 'react';

import { WhatsappLogo, Envelope, ChatDots } from 'phosphor-react';

import { ECategory } from '@domain/enums/dashboard/automations/EMessage';
import { ICategoryProps } from '@domain/interfaces/dashboard/Automations/IMessage';

import * as S from './styles';

const Category: React.FC<ICategoryProps> = ({ category }) => {
  const getIcon = (): JSX.Element => {
    if (category === ECategory.WHATSAPP) {
      return (
        <S.Background color="#59B578">
          <WhatsappLogo size={18} color="#000" weight="light" />
        </S.Background>
      );
    }

    if (category === ECategory.EMAIL) {
      return (
        <S.Background color="#EFC980">
          <Envelope size={18} color="#000" weight="light" />
        </S.Background>
      );
    }

    if (category === ECategory.SMS) {
      return (
        <S.Background color="#5B8DEF">
          <ChatDots size={18} color="#000" weight="light" />
        </S.Background>
      );
    }

    return <></>;
  };

  return <S.Wrapper>{getIcon()}</S.Wrapper>;
};

export default Category;
