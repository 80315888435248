export enum EScriptAndUtmsStep {
  SCRIPT = 'SCRIPT',
  UTM_TAGS = 'UTM_TAGS',
  PROCESSING_UTM_TAGS = 'PROCESSING_UTM_TAGS',
  UTM_TAGS_PROCESSED = 'UTM_TAGS_PROCESSED',
  SCHEDULE = 'SCHEDULE',
}

export enum ESyncType {
  SCHEDULE = 'SCHEDULE',
  SYNC_NOW = 'SYNC_NOW',
}
