import React from 'react';
import { useHistory } from 'react-router-dom';
import { Trash } from 'phosphor-react';

import { ICardProps } from '@domain/interfaces/stores/ICard';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';

import storesService from '@services/pages/stores/stores';

import DeleteModal from './DeleteModal';

import * as S from './styles';

const Store: React.FC<ICardProps> = ({ name, storeAliasId, store }) => {
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const handleOnStoreClick = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/overview/financial`);
  }, [history, storeAliasId]);

  const toggleDeleteModal = React.useCallback(() => {
    setShowDeleteModal(prev => !prev);
  }, []);

  const handleDeleteStoreModal = React.useCallback(() => {
    toggleDeleteModal();
  }, [toggleDeleteModal]);

  const handleDeleteModal = React.useCallback(async () => {
    await storesService.deleteStore(storeAliasId);
    toggleDeleteModal();
    history.push('/stores?update=true');
  }, [storeAliasId, toggleDeleteModal, history]);

  const isOnboardingFinished = store.is_onboarding_finished;

  return (
    <S.Wrapper>
      <S.Left shouldRadius={isOnboardingFinished} onClick={handleOnStoreClick}>
        <S.InitialLetterWrapper>
          <S.InitialLetter>{name.charAt(0).toUpperCase()}</S.InitialLetter>
        </S.InitialLetterWrapper>

        <S.TextWrapper>
          <S.Text size={ETextSize.B3} weight={ETextWeight.MEDIUM}>
            {name}
          </S.Text>
        </S.TextWrapper>
      </S.Left>
      {!isOnboardingFinished && (
        <S.Right onClick={handleDeleteStoreModal}>
          <Trash size={24} />
        </S.Right>
      )}

      <DeleteModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        onDelete={handleDeleteModal}
      />
    </S.Wrapper>
  );
};

export default Store;
