import React from 'react';

import { ETextWeight } from '@domain/enums/components/EText';
import { IMessageProps } from '@domain/interfaces/dashboard/Automations/IMessage';

import MessageTypeTags from './MessageTypeTags';
import Preview from './Preview';
import Category from './Category';

import * as S from './styles';

const Message: React.FC<IMessageProps> = ({ message, toggleModal }) => {
  return (
    <S.Wrapper>
      <Preview
        message={message}
        messageContents={message.message_contents}
        toggleModal={toggleModal}
      />

      <S.Infos>
        <S.Name weight={ETextWeight.BOLD}>{message.title}</S.Name>

        <S.BadgeTagsAndChannelsWrapper>
          <MessageTypeTags messageContents={message.message_contents} />

          <Category category={message.category} />
        </S.BadgeTagsAndChannelsWrapper>
      </S.Infos>
    </S.Wrapper>
  );
};

export default Message;
