import React from 'react';

import { useForm } from 'react-hook-form';

import { ETextWeight } from '@domain/enums/components/EText';
import { EButtonSize } from '@domain/enums/components/EButton';

import {
  ICustomerSuccess,
  ICustomerSuccessReport,
} from '@domain/interfaces/dashboard/AdminPanel/ICustomerSuccess';

import { useDate } from '@helpers/hooks/useDate';
import { useToast } from '@helpers/hooks/useToast';
import { numberFormatter } from '@helpers/masks';

import customerSuccessService from '@services/pages/dashboard/adminPanel/customerSuccess';

import Form from '@components/common/core/Inputs/Form';

import * as S from './styles';
import AssignUserForm from './AssignUserForm';

const Wallet: React.FC = () => {
  const { toast } = useToast();

  const { handleSubmit, register } = useForm();
  const { utcToZonedTime, format } = useDate();

  const [isGettingCustomerSuccess, setIsGettingCustomerSuccess] = React.useState<boolean>(false);
  const [isGettingReportData, setIsGettingReportData] = React.useState<boolean>(false);
  const [customerSuccess, setCustomerSuccess] = React.useState<Array<ICustomerSuccess>>([]);
  const [customerSuccessReport, setCustomerSuccessReport] = React.useState<ICustomerSuccessReport>(
    {} as ICustomerSuccessReport,
  );
  const [period, setPeriod] = React.useState({
    startDate: utcToZonedTime(new Date()),
    endDate: utcToZonedTime(new Date()),
  });

  const onSubmit = React.useCallback(
    async formData => {
      setIsGettingReportData(true);

      const start_date = format(period.startDate, 'yyyy-MM-dd');
      const end_date = format(period.endDate, 'yyyy-MM-dd');

      try {
        const { data } = await customerSuccessService.getCustomerSuccessReport({
          customer_success_alias_id: formData.customer_success_alias_id,
          start_date,
          end_date,
        });

        setCustomerSuccessReport({
          id: data?.customer_success.id,
          ...data?.report,
        });

        setIsGettingReportData(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsGettingReportData(false);
      }
    },
    [period, format, toast],
  );

  const getCustomerSuccess = React.useCallback(async () => {
    setIsGettingCustomerSuccess(true);

    try {
      const { data } = await customerSuccessService.listAllCustomerSuccess();

      setCustomerSuccess(data?.customers_success);
      setIsGettingCustomerSuccess(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsGettingCustomerSuccess(false);
    }
  }, [toast]);

  React.useEffect(() => {
    getCustomerSuccess();
  }, [getCustomerSuccess]);

  const hasData = Boolean(customerSuccessReport?.id);

  const formattedCustomerSuccessReportAmount = `R$${numberFormatter(
    customerSuccessReport?.net_revenue_amount,
    2,
  )}`;

  if (isGettingCustomerSuccess) {
    return <S.LoadingText>...carregando</S.LoadingText>;
  }

  return (
    <S.ContentWrapper>
      <AssignUserForm customerSuccess={customerSuccess} />

      <S.Heading>Ver relatório de vendas</S.Heading>

      <S.WalletReportContent>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>Colaborador</S.Label>

            <S.ReportDataSelect {...register('customer_success_alias_id')}>
              <S.Option value="" selected disabled>
                Selecione o colaborador
              </S.Option>

              {customerSuccess.map(customerSuccessValue => {
                const fullName = `${customerSuccessValue.first_name} ${customerSuccessValue.last_name}`;

                return <S.Option value={customerSuccessValue.alias_id}>{fullName}</S.Option>;
              })}
            </S.ReportDataSelect>
          </S.InputGroup>

          <S.CustomDatePicker period={period} setPeriod={setPeriod} />

          {hasData && (
            <S.DataWrapper>
              <S.Data>
                <S.DataText>Faturamento:</S.DataText>
                <S.NetRevenueDataValue weight={ETextWeight.SEMIBOLD}>
                  {formattedCustomerSuccessReportAmount}
                </S.NetRevenueDataValue>
              </S.Data>

              <S.Data>
                <S.DataText>Total de Clientes:</S.DataText>
                <S.SubscriptionsDataValue weight={ETextWeight.SEMIBOLD}>
                  {customerSuccessReport?.total_subscriptions_quantity}
                </S.SubscriptionsDataValue>
              </S.Data>
            </S.DataWrapper>
          )}

          <S.ReportButton
            type="submit"
            buttonSize={EButtonSize.MEDIUM}
            isLoading={isGettingReportData}
            disabled={isGettingReportData}
          >
            {hasData ? 'Gerar novamente' : 'Gerar relatório'}
          </S.ReportButton>
        </Form>
      </S.WalletReportContent>
    </S.ContentWrapper>
  );
};

export default Wallet;
