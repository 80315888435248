import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';
import {
  IListHistoricCheckoutFeesProps,
  IListHistoricCheckoutFeesResponse,
  ICreateDefaultCheckoutFeeProps,
  ICreatePeriodCheckoutFeeProps,
  IUpdateDefaultCheckoutFeeProps,
  IUpdatePeriodCheckoutFeeProps,
  IDeleteCheckoutFeeProps,
  IGetDefaultCheckoutFeeProps,
  IGetDefaultCheckoutFeeResponse,
} from '@domain/interfaces/dashboard/CheckoutFee/ICheckoutFee';

export class CheckoutFeeService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getDefaultCheckoutFee({
    storeAliasId,
  }: IGetDefaultCheckoutFeeProps): IGetDefaultCheckoutFeeResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/checkout-fees/default`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      checkoutFee: data?.checkout_fee,
      error,
      mutate,
      isLoading,
      isValidating,
    };
  }

  public listHistoricCheckoutFees({
    storeAliasId,
    page,
  }: IListHistoricCheckoutFeesProps): IListHistoricCheckoutFeesResponse {
    const limit = 10;

    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/checkout-fees?limit=${limit}&page=${page + 1}`,
      this.apiService,
    );

    const isLoading = !error && !data;

    return {
      checkoutFees: data?.checkout_fees,
      totalPages: data?.total_pages,
      error,
      mutate,
      isLoading,
      isValidating,
    };
  }

  public createDefaultCheckoutFee({
    storeAliasId,
    data,
  }: ICreateDefaultCheckoutFeeProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/checkout-fees/default`, {
      ...data,
      amount: 0,
    });
  }

  public createPeriodCheckoutFee({
    storeAliasId,
    data,
  }: ICreatePeriodCheckoutFeeProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/checkout-fees/period`, {
      ...data,
    });
  }

  public updateDefaultCheckoutFee({
    storeAliasId,
    checkoutFeeAliasId,
    data,
  }: IUpdateDefaultCheckoutFeeProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/checkout-fees/${checkoutFeeAliasId}/default`,
      {
        ...data,
      },
    );
  }

  public updatePeriodCheckoutFee({
    storeAliasId,
    checkoutFeeAliasId,
    data,
  }: IUpdatePeriodCheckoutFeeProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/checkout-fees/${checkoutFeeAliasId}/period`,
      {
        ...data,
      },
    );
  }

  public deleteCheckoutFee({
    storeAliasId,
    checkoutFeeAliasId,
  }: IDeleteCheckoutFeeProps): Promise<any> {
    return this.apiService.delete(
      `/api/v1/users/stores/${storeAliasId}/checkout-fees/${checkoutFeeAliasId}`,
    );
  }
}

const checkoutFeeService = new CheckoutFeeService(API_DOMAIN);

export default checkoutFeeService;
