/* eslint-disable no-restricted-syntax */
import React from 'react';
import { useParams } from 'react-router-dom';

import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { IFilterQueryStringData } from '@domain/interfaces/components/IFilter';
import { IParams } from '@domain/interfaces/IParams';
import { IGoogleCredentialsProvider } from '@domain/interfaces/integrations/marketing/google/IGoogleCredentials';

import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useToast } from '@helpers/hooks/useToast';

import { ADS_PROVIDERS } from '@constants/common/integrations';

import adSenseCredentialService from '@services/pages/dashboard/adSense/adSenseCredential';
import googleCredentialService from '@services/pages/dashboard/adSense/google/googleCredential';

const GoogleCredentialsContext = React.createContext<IGoogleCredentialsProvider | null>(null);

export const GoogleCredentialsProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();
  const { addSynchronization } = useSynchronization();
  const { toast } = useToast();

  const [isLoggingIn, setIsLoggingIn] = React.useState<boolean>(false);
  const [isLoggingOut, setIsLoggingOut] = React.useState<boolean>(false);
  const [adAccountSearchName, setAdAccountSearchName] = React.useState<string>('');

  const [adSenseCredentialsFilter] = React.useState<IFilterQueryStringData>({
    provider: EAdSenseProvider.GOOGLE,
  });

  const {
    adSenseCredentials,
    isLoading: isLoadingAdSenseCredentials,
    isValidating: isValidatingAdSenseCredentials,
    mutate: mutateAdSenseCredentials,
    error: adSenseCredentialsError,
  } = adSenseCredentialService.listAdSenseCredentials({
    storeAliasId,
    page: 1,
    filter: adSenseCredentialsFilter,
  });

  const handleAdAccountSearchName = React.useCallback(newSearchName => {
    setAdAccountSearchName(newSearchName);
  }, []);

  const mutate = React.useCallback(async () => {
    const promises = [];

    if (adSenseCredentialsError) promises.push(mutateAdSenseCredentials());

    await Promise.all(promises);
  }, [adSenseCredentialsError, mutateAdSenseCredentials]);

  const syncAllAdSenseAccounts = React.useCallback(async () => {
    const foundGoogleIntegration = ADS_PROVIDERS.find(
      integration => integration.identifier === EAdSenseProvider.GOOGLE,
    ) as typeof ADS_PROVIDERS[number];

    for await (const adSenseCredential of adSenseCredentials) {
      const { generateSyncRequest } = foundGoogleIntegration;

      const aliases = {
        adSenseCredentialAliasId: adSenseCredential.alias_id,
      };

      addSynchronization({
        name: 'Google Ads',
        type: ESynchronizationType.AD_SENSE_ACCOUNTS,
        label: 'Perfil',
        dependencies: [ESynchronizationType.AD_SENSE_ACCOUNTS],
        storeAliasId,
        externalId: adSenseCredential.alias_id,
        config: {
          isOnboardingSynchronization: false,
          showNotification: true,
        },
        request: generateSyncRequest(
          ESynchronizationType.AD_SENSE_ACCOUNTS,
          storeAliasId,
          aliases,
          undefined,
        ),
      });

      await new Promise(resolve => setTimeout(resolve, 500));
    }
  }, [adSenseCredentials, addSynchronization, storeAliasId]);

  const handleLogin = React.useCallback(
    async responseGoogle => {
      setIsLoggingIn(true);

      const data = {
        storeAliasId,
        data: { code: responseGoogle.code },
      };

      try {
        const { data: googleCredentialData } = await googleCredentialService.createGoogleCredential(
          data,
        );

        toast.success('Conta conectada com sucesso!');

        setIsLoggingIn(false);

        const [adSenseCredentialsResponse] = await Promise.all([mutateAdSenseCredentials()]);

        const adSenseCredentialToSync = adSenseCredentialsResponse.data.ad_sense_credentials;

        const foundAdSenseCredential = adSenseCredentialToSync.find(
          (adSenseCredential: any) =>
            adSenseCredential.id === googleCredentialData?.google_credential.ad_sense_credential_id,
        );

        if (foundAdSenseCredential) {
          const foundGoogleIntegration = ADS_PROVIDERS.find(
            integration => integration.identifier === EAdSenseProvider.GOOGLE,
          ) as typeof ADS_PROVIDERS[number];

          const { generateSyncRequest } = foundGoogleIntegration;

          const aliases = {
            adSenseCredentialAliasId: foundAdSenseCredential.alias_id,
          };

          addSynchronization({
            name: 'Google Ads',
            type: ESynchronizationType.AD_SENSE_ACCOUNTS,
            label: 'Perfil',
            dependencies: [],
            storeAliasId,
            externalId: foundAdSenseCredential.alias_id,
            config: {
              isOnboardingSynchronization: false,
              showNotification: true,
            },
            request: generateSyncRequest(
              ESynchronizationType.AD_SENSE_ACCOUNTS,
              storeAliasId,
              aliases,
              undefined,
            ),
            callback: mutateAdSenseCredentials,
          });
        }
      } catch (error: any) {
        setIsLoggingIn(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [mutateAdSenseCredentials, storeAliasId, toast, addSynchronization],
  );

  const handleLogout = React.useCallback(
    async adSenseCredentialAliasId => {
      setIsLoggingOut(true);

      try {
        await adSenseCredentialService.deleteAdSenseCredential({
          storeAliasId,
          adSenseCredentialAliasId,
        });

        toast.success('Perfil desconectado com sucesso!');

        await mutateAdSenseCredentials();

        setIsLoggingOut(false);
      } catch (error: any) {
        setIsLoggingOut(false);
      }
    },
    [storeAliasId, toast, mutateAdSenseCredentials],
  );

  const isLoadingGoogleCredentialsData = isLoadingAdSenseCredentials;
  const isValidatingGoogleCredentialsData = isValidatingAdSenseCredentials;
  const isGoogleCredentialsDataError = Boolean(adSenseCredentialsError);

  return (
    <GoogleCredentialsContext.Provider
      value={{
        adSenseCredentials,
        isGoogleCredentialsDataError,
        isLoadingGoogleCredentialsData,
        isValidatingGoogleCredentialsData,
        mutate,
        syncAllAdSenseAccounts,
        isLoggingIn,
        isLoggingOut,
        handleLogin,
        handleLogout,
        adAccountSearchName,
        handleAdAccountSearchName,
      }}
    >
      {children}
    </GoogleCredentialsContext.Provider>
  );
};

export const useGoogleCredentials = (): IGoogleCredentialsProvider => {
  const context = React.useContext(GoogleCredentialsContext);

  if (!context) {
    throw new Error('useGoogleCredentials must be used within GoogleCredentialsProvider');
  }

  return context;
};
