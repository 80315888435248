import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
`;

export const Container = styled.div`
  width: 100%;
`;
