import styled, { css } from 'styled-components/macro';

import { IParagraphProps } from '@domain/interfaces/components/ITermsOfAdhesionModal';

import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const Paragraph = styled.p<IParagraphProps>`
  position: relative;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.font};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 150%;

  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `}

  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}

    ${({ bullets }) =>
    bullets &&
    css`
      padding-left: 8px;

      &::before {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.font};
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    `}
`;

export const Subtitle = styled(HeadingBase)`
  font-weight: 600;
  font-size: 0.875rem;
  margin-top: 32px;
  margin-bottom: 16px;
  text-decoration: underline;
`;
