import React from 'react';
import { useParams } from 'react-router-dom';

import { ECancellationReason } from '@domain/enums/common/subscription/ECancellationReason';
import { EStatus } from '@domain/enums/subscription/ESubscription';
import { ETransactionStatus } from '@domain/enums/common/transaction/ETransactionStatus';
import { IParams } from '@domain/interfaces/IParams';
import { IActivateSubscriptionProvider } from '@domain/interfaces/hooks/dashboard/IActivateSubscriptionProvider';

import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { useTransaction } from '@helpers/hooks/common/store/subscription/useTransaction';
import { useDate } from '@helpers/hooks/useDate';

import transactionService from '@services/common/transaction/transaction';

const ActivateSubscriptionContent = React.createContext<IActivateSubscriptionProvider | null>(null);

export const ActivateSubscriptionProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();
  const { subscription, mutateSubscription } = useStoreSubscription();
  const { transactions, mutateTransactions } = useTransaction();
  const { isBefore } = useDate();

  const [isVerifyingTransaction, setIsVerifyingTransaction] = React.useState<boolean>(false);
  const [showTransactionErrorModal, setShowTransactionErrorModal] = React.useState<boolean>(false);
  const [transactionError, setTransactionError] = React.useState<string>('');

  const resetActivateSubscriptionProvider = React.useCallback(() => {
    setShowTransactionErrorModal(false);
    setTransactionError('');
  }, []);

  const handleShowTransactionErrorModal = React.useCallback(() => {
    setShowTransactionErrorModal(state => !state);
  }, []);

  const verifyLastTransaction = React.useCallback(
    async (interval, acurrentStoreAliasId, storeSubscription) => {
      try {
        const { data } = await transactionService.listStoreTransactionsPromise({
          storeAliasId: acurrentStoreAliasId,
          page: 0,
        });

        const { transactions: dataTransactions } = data;

        const lastTransaction = dataTransactions[0];

        if (lastTransaction.status === ETransactionStatus.PENDING) return;

        const lastTransactionDate = lastTransaction.created_at;

        const isAttemptBeforeLastTransaction = isBefore(
          new Date(storeSubscription.active_attempted_at),
          new Date(lastTransactionDate),
        );

        if (isAttemptBeforeLastTransaction) {
          if (lastTransaction.lr) {
            setShowTransactionErrorModal(true);
            setTransactionError(lastTransaction.lr);
            await mutateTransactions();
            mutateSubscription();
          }

          setIsVerifyingTransaction(false);
          clearInterval(interval);
        }
      } catch (error: any) {
        console.log(error);
        throw new Error(error);
      }
    },
    [isBefore, mutateSubscription, mutateTransactions],
  );

  React.useEffect(() => {
    setIsVerifyingTransaction(false);

    if (!subscription || !transactions) return undefined;

    if (!subscription.active_attempted_at) return undefined;

    const lastTransaction = transactions[0];

    if (!lastTransaction) return undefined;

    const lastTransactionDate = lastTransaction.created_at;

    const isAttemptBeforeLastTransaction = isBefore(
      new Date(subscription.active_attempted_at),
      new Date(lastTransactionDate),
    );

    const isLastTransactionCancelled = lastTransaction.status === ETransactionStatus.CANCELED;

    if (isAttemptBeforeLastTransaction && isLastTransactionCancelled) {
      const isSubscriptionLastTransaction = lastTransaction.subscription_id === subscription.id;

      if (
        subscription.cancellation_reason !== ECancellationReason.CREDIT_CARD_ISSUE ||
        subscription.status !== EStatus.CANCELED ||
        !lastTransaction?.lr ||
        !isSubscriptionLastTransaction
      )
        return undefined;

      setTransactionError(lastTransaction.lr);
      setShowTransactionErrorModal(true);

      return undefined;
    }

    setIsVerifyingTransaction(true);

    const interval = setInterval(() => {
      verifyLastTransaction(interval, storeAliasId, subscription);
    }, 30000);

    return () => clearInterval(interval);
  }, [isBefore, subscription, transactions, verifyLastTransaction, storeAliasId]);

  return (
    <ActivateSubscriptionContent.Provider
      value={{
        transactionError,
        showTransactionErrorModal,
        handleShowTransactionErrorModal,
        isVerifyingTransaction,
        resetActivateSubscriptionProvider,
      }}
    >
      {children}
    </ActivateSubscriptionContent.Provider>
  );
};

export const useActivateSubscription = (): IActivateSubscriptionProvider => {
  const context = React.useContext(ActivateSubscriptionContent);

  if (!context) {
    throw new Error('useActivateSubscription must be used within ActivateSubscriptionContent');
  }

  return context;
};
