import React from 'react';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';

import CanvasWrapper from './CanvasWrapper';

const Wrapper: React.FC = () => {
  const { isLoadingWorkflow } = useAutomation();

  if (isLoadingWorkflow) {
    return <div>...loading</div>;
  }

  return <CanvasWrapper />;
};

export default Wrapper;
