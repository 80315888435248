/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { MercadoPago as MercadoPagoIcon } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { EGateway } from '@domain/enums/common/EGateway';
import { ISideModalGatewayContent } from '@domain/interfaces/dashboard/IntegrationCenter/SideModalContent/ISideModalContent';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useDate } from '@helpers/hooks/useDate';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { mercadoPagoSchema } from '@helpers/validators/dashboard/integrationCenter/mercadoPago';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';
import { getStoreProvider } from '@helpers/utils/common/integration';

import { GATEWAYS } from '@constants/common/integrations';

import mercadoPagoCredentialService from '@services/pages/dashboard/integrationCenter/gateway/mercadoPago';

import Text from '@components/common/core/DataDisplay/Text';
import Save from '@components/Dashboard/IntegrationCenter/SideModalContent/Save';
import Form from '@components/common/core/Inputs/Form';
import InputGroup from '@components/common/core/Inputs/InputGroup';
import Input from '@components/common/core/Inputs/TextField';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const MercadoPago: React.FC<ISideModalGatewayContent> = ({
  syncOnCreate,
  isFeedSideModal,
  toggle,
}) => {
  const foundIntegration = GATEWAYS.find(
    integration => integration.identifier === EGateway.MERCADO_PAGO,
  ) as typeof GATEWAYS[number];

  const { toast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(mercadoPagoSchema),
  });
  const { storeAliasId } = useParams<IParams>();
  const { addSynchronization } = useSynchronization();
  const { format, subDays, utcToZonedTime } = useDate();
  const { storeIntegrations } = useStoreConfig();

  const { mercadoPagoCredential, mutate, isLoading } = mercadoPagoCredentialService.getCredential({
    storeAliasId,
  });

  const [isLoadingSubmittedForm, setIsLoadingSubmittedForm] = React.useState<boolean>(false);
  const [isLoadingDeleteCredential, setIsLoadingDeleteCredential] = React.useState<boolean>(false);
  const [hasInputValue, setHasInputValue] = React.useState<boolean>(false);

  const onChange = React.useCallback(event => {
    if (event.target.value) {
      setHasInputValue(true);
    } else {
      setHasInputValue(false);
    }
  }, []);

  const onSubmit = React.useCallback(
    async formData => {
      setIsLoadingSubmittedForm(true);

      const lastThirtyDays = utcToZonedTime(subDays(new Date(), 30));

      const storeProvider = getStoreProvider(storeIntegrations);

      const hasGatewaySynchronization = storeProvider?.gateways.includes(EGateway.MERCADO_PAGO);

      try {
        const params = `?start_date=${format(lastThirtyDays, 'yyyy-MM-dd')}&end_date=${format(
          utcToZonedTime(new Date()),
          'yyyy-MM-dd',
        )}`;

        if (mercadoPagoCredential?.id) {
          await mercadoPagoCredentialService.updateCredential({
            storeAliasId,
            data: formData,
          });

          if (storeProvider && hasGatewaySynchronization) {
            addSynchronization({
              name: storeProvider.name,
              type: ESynchronizationType.STORE_GATEWAY,
              label: foundIntegration.name,
              dependencies: [ESynchronizationType.STORE_PRODUCT, ESynchronizationType.STORE_ORDER],
              storeAliasId,
              config: {
                isOnboardingSynchronization: false,
                showNotification: true,
              },
              request: storeProvider.generateGatewaySyncRequest(
                foundIntegration.synchronizationName,
                storeAliasId,
                params,
              ),
            });
          }
        } else {
          await mercadoPagoCredentialService.createCredential({
            storeAliasId,
            data: formData,
          });

          if (syncOnCreate && storeProvider && hasGatewaySynchronization) {
            addSynchronization({
              name: storeProvider.name,
              type: ESynchronizationType.STORE_GATEWAY,
              label: foundIntegration.name,
              dependencies: [ESynchronizationType.STORE_PRODUCT, ESynchronizationType.STORE_ORDER],
              storeAliasId,
              config: {
                isOnboardingSynchronization: false,
                showNotification: true,
              },
              request: storeProvider.generateGatewaySyncRequest(
                foundIntegration.synchronizationName,
                storeAliasId,
                params,
              ),
            });
          }
        }

        await Promise.all([mutate()]);

        toast.success('Credencial salva com sucesso!');

        setIsLoadingSubmittedForm(false);

        if (toggle) {
          toggle();
        }
      } catch (error: any) {
        setIsLoadingSubmittedForm(false);
        toast.error(getTranslatedIntegrationsError(error));
      }
    },
    [
      mercadoPagoCredential,
      storeAliasId,
      mutate,
      toast,
      addSynchronization,
      subDays,
      format,
      utcToZonedTime,
      syncOnCreate,
      storeIntegrations,
      toggle,
      foundIntegration,
    ],
  );

  const handleDeleteCredential = React.useCallback(async () => {
    setIsLoadingDeleteCredential(true);

    try {
      await mercadoPagoCredentialService.deleteCredential({
        storeAliasId,
      });

      await Promise.all([mutate()]);

      toast.success('Credencial desativada com sucesso!');
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoadingDeleteCredential(false);
    }
  }, [storeAliasId, mutate, toast]);

  if (isLoading) {
    return <SkeletonLoading />;
  }

  return (
    <S.ContentWrapper>
      <S.Header>
        <MercadoPagoIcon size={52} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com MercadoPago
        </S.Heading>
      </S.Header>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>
        <S.FunctionalityDescriptionText>
          A integração com seu Gateway de pagamento serve para
          <strong> puxarmos seu faturamento </strong>
          aumentando a<strong> exatidão na análise de seus dados.</strong>
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <S.TutorialWrapper>
        <S.TutorialHeading type={EHeadingSize.H5}>Tutorial de integração</S.TutorialHeading>
        <S.TutorialText>
          Para realizar a integração você precisará da
          <strong> Public Key </strong>e do
          <strong> Access Token </strong>
          que são encontrados neste link:
          <strong>
            <S.Link href="https://mercadopago.com/mlb/account/credentials" target="_blank">
              {' '}
              mercadopago.com/mlb/account/credentials
            </S.Link>
            .
          </strong>
        </S.TutorialText>
      </S.TutorialWrapper>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup>
          <S.Label htmlFor="mercado-pago-public-key">Public Key</S.Label>

          <Input
            {...register('public_key')}
            placeholder="Chave Pública MercadoPago"
            type="text"
            name="public_key"
            id="mercado-pago-public-key"
          />
        </InputGroup>

        <InputGroup>
          <S.Label htmlFor="mercado-pago-access-token">Access Token</S.Label>

          <Input
            {...register('access_token')}
            placeholder="Chave de Acesso MercadoPago"
            type="text"
            name="access_token"
            defaultValue={mercadoPagoCredential?.access_token}
            onChange={onChange}
            id="mercado-pago-access-token"
          />
          {errors.access_token && <Text isErrorFeedback>{errors.access_token.message}</Text>}
        </InputGroup>

        <Save
          submitText={isFeedSideModal ? 'Reprocessar pedidos' : 'Salvar alterações'}
          cancelText="Cancelar"
          onDelete={isFeedSideModal ? undefined : handleDeleteCredential}
          toggle={toggle}
          hasCredentials={Boolean(mercadoPagoCredential?.id)}
          isSubmitting={isLoadingSubmittedForm}
          isDeleting={isLoadingDeleteCredential}
          isDeleteButtonDisabled={isLoadingDeleteCredential || isLoadingSubmittedForm}
          isSaveButtonDisabled={
            (!mercadoPagoCredential?.id && !hasInputValue) ||
            isLoadingSubmittedForm ||
            isLoadingDeleteCredential
          }
        />
      </Form>
    </S.ContentWrapper>
  );
};

export default MercadoPago;
