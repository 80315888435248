import {
  FINISHED_ONBOARDING_DROPSHIPPING_STEPS,
  FINISHED_ONBOARDING_ECOMMERCE_STEPS,
  FINISHED_ONBOARDING_INFO_PRODUCT_STEPS,
  ONBOARDING_DROPSHIPPING_STEPS,
  ONBOARDING_ECOMMERCE_STEPS,
  ONBOARDING_INFO_PRODUCT_STEPS,
  SIDE_MODAL_ONBOARDING_DROPSHIPPING_STEPS,
  SIDE_MODAL_ONBOARDING_ECOMMERCE_STEPS,
  SIDE_MODAL_ONBOARDING_INFO_PRODUCT_STEPS,
} from '@constants/pages/dashboard/home/home';
import { EBusinessType } from '@domain/enums/stores/Etype';
import { IHomeOnboardingSteps, IHomeProvider } from '@domain/interfaces/dashboard/Home/IHome';
import { IParams } from '@domain/interfaces/IParams';
import { useConfig } from '@helpers/hooks/useConfig';
import homeService from '@services/pages/dashboard/home/home';
import React from 'react';
import { useParams } from 'react-router-dom';

const HomeContext = React.createContext<IHomeProvider | null>(null);

export const HomeProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();
  const { selectedStore, analytics, user } = useConfig();

  const [onboardingSteps, setOnboardingSteps] = React.useState<Array<IHomeOnboardingSteps>>(
    ONBOARDING_DROPSHIPPING_STEPS,
  );
  const [isOnboardingFinished, setIsOnboardingFinished] = React.useState<boolean>(false);
  const [isSecondOnboardingFinished, setIsSecondOnboardingFinished] = React.useState<boolean>(
    false,
  );
  const [sideModalOnboardingSteps, setSideModalOnboardingSteps] = React.useState<
    Array<IHomeOnboardingSteps>
  >(SIDE_MODAL_ONBOARDING_DROPSHIPPING_STEPS);
  const [quantityCrucialSteps, setQuantityCrucialSteps] = React.useState<number>(0);
  const [currentQuantityCrucialSteps, setCurrentQuantityCrucialSteps] = React.useState<number>(0);

  const {
    error: homeSetupError,
    isLoading: isLoadingHomeSetup,
    isValidating: isValidatingHomeSetup,
    mutate: mutateHomeSetup,
    homeSetup,
  } = homeService.getHomeSetup({
    storeAliasId,
  });

  // const isOnboardingFinished = false;

  const loadSteps = React.useCallback(async () => {
    if (selectedStore?.type === EBusinessType.DROPSHIPPING)
      setOnboardingSteps(
        isOnboardingFinished
          ? FINISHED_ONBOARDING_DROPSHIPPING_STEPS
          : ONBOARDING_DROPSHIPPING_STEPS,
      );

    if (selectedStore?.type === EBusinessType.TRADITIONAL_ECOMMERCE)
      setOnboardingSteps(
        isOnboardingFinished ? FINISHED_ONBOARDING_ECOMMERCE_STEPS : ONBOARDING_ECOMMERCE_STEPS,
      );

    if (selectedStore?.type === EBusinessType.INFO_PRODUCT)
      setOnboardingSteps(
        isOnboardingFinished
          ? FINISHED_ONBOARDING_INFO_PRODUCT_STEPS
          : ONBOARDING_INFO_PRODUCT_STEPS,
      );
  }, [isOnboardingFinished, selectedStore]);

  const loadSideModalSteps = React.useCallback(async () => {
    if (selectedStore?.type === EBusinessType.DROPSHIPPING)
      setSideModalOnboardingSteps(SIDE_MODAL_ONBOARDING_DROPSHIPPING_STEPS);

    if (selectedStore?.type === EBusinessType.TRADITIONAL_ECOMMERCE)
      setSideModalOnboardingSteps(SIDE_MODAL_ONBOARDING_ECOMMERCE_STEPS);

    if (selectedStore?.type === EBusinessType.INFO_PRODUCT)
      setSideModalOnboardingSteps(SIDE_MODAL_ONBOARDING_INFO_PRODUCT_STEPS);
  }, [selectedStore]);

  const checkOnboardingFinished = React.useCallback(() => {
    if (selectedStore?.type === EBusinessType.DROPSHIPPING) {
      const isFinished =
        homeSetup?.has_store_integration &&
        homeSetup?.has_payment_gateway &&
        homeSetup?.has_checkout_fee;
      setIsOnboardingFinished(isFinished);
    }

    if (selectedStore?.type === EBusinessType.TRADITIONAL_ECOMMERCE) {
      const isFinished = homeSetup?.has_store_integration && homeSetup?.has_payment_gateway;

      setIsOnboardingFinished(isFinished);
    }

    if (selectedStore?.type === EBusinessType.INFO_PRODUCT) {
      const isFinished = homeSetup?.has_store_integration;

      setIsOnboardingFinished(isFinished);
    }
  }, [selectedStore, homeSetup]);

  const loadQuantityCrucialSteps = React.useCallback(() => {
    if (selectedStore?.type === EBusinessType.DROPSHIPPING) {
      setQuantityCrucialSteps(3);
    }

    if (selectedStore?.type === EBusinessType.TRADITIONAL_ECOMMERCE) {
      setQuantityCrucialSteps(2);
    }

    if (selectedStore?.type === EBusinessType.INFO_PRODUCT) {
      setQuantityCrucialSteps(1);
    }
  }, [selectedStore]);

  const loadCurrentQuantityCrucialSteps = React.useCallback(() => {
    let count = 0;

    if (selectedStore?.type === EBusinessType.DROPSHIPPING) {
      count += homeSetup?.has_store_integration ? 1 : 0;
      count += homeSetup?.has_payment_gateway ? 1 : 0;
      count += homeSetup?.has_checkout_fee ? 1 : 0;
      setCurrentQuantityCrucialSteps(count);
    }

    if (selectedStore?.type === EBusinessType.TRADITIONAL_ECOMMERCE) {
      count += homeSetup?.has_store_integration ? 1 : 0;
      count += homeSetup?.has_payment_gateway ? 1 : 0;
      setCurrentQuantityCrucialSteps(count);
    }

    if (selectedStore?.type === EBusinessType.INFO_PRODUCT) {
      count += homeSetup?.has_store_integration ? 1 : 0;
      setCurrentQuantityCrucialSteps(count);
    }
  }, [homeSetup, selectedStore]);

  const loadCurrentSecondOnboardingQuantity = React.useCallback(() => {
    let count = 0;

    if (selectedStore?.type === EBusinessType.DROPSHIPPING) {
      count += homeSetup?.has_ads_manager_configured ? 1 : 0;
      count += homeSetup?.has_checkout_fee ? 1 : 0;
      count += homeSetup?.has_custom_values_configured ? 1 : 0;
      count += homeSetup?.has_default_shipping ? 1 : 0;
      count += homeSetup?.has_dsers_credential ? 1 : 0;
      count += homeSetup?.has_message_automations ? 1 : 0;
      count += homeSetup?.has_product_cost_configured ? 1 : 0;
      count += homeSetup?.has_tax_configured ? 1 : 0;
      count += homeSetup?.has_marketing_integration ? 1 : 0;

      if (count === 9) {
        setIsSecondOnboardingFinished(true);
      }
    }

    if (selectedStore?.type === EBusinessType.TRADITIONAL_ECOMMERCE) {
      count += homeSetup?.has_ads_manager_configured ? 1 : 0;
      count += homeSetup?.has_checkout_fee ? 1 : 0;
      count += homeSetup?.has_custom_values_configured ? 1 : 0;
      count += homeSetup?.has_default_shipping ? 1 : 0;
      count += homeSetup?.has_dsers_credential ? 1 : 0;
      count += homeSetup?.has_message_automations ? 1 : 0;
      count += homeSetup?.has_product_cost_configured ? 1 : 0;
      count += homeSetup?.has_tax_configured ? 1 : 0;
      count += homeSetup?.has_marketing_integration ? 1 : 0;

      if (count === 9) {
        setIsSecondOnboardingFinished(true);
      }
    }

    if (selectedStore?.type === EBusinessType.INFO_PRODUCT) {
      count += homeSetup?.has_ads_manager_configured ? 1 : 0;
      count += homeSetup?.has_checkout_fee ? 1 : 0;
      count += homeSetup?.has_custom_values_configured ? 1 : 0;
      count += homeSetup?.has_default_shipping ? 1 : 0;
      count += homeSetup?.has_dsers_credential ? 1 : 0;
      count += homeSetup?.has_message_automations ? 1 : 0;
      count += homeSetup?.has_product_cost_configured ? 1 : 0;
      count += homeSetup?.has_tax_configured ? 1 : 0;
      count += homeSetup?.has_marketing_integration ? 1 : 0;

      if (count === 9) {
        setIsSecondOnboardingFinished(true);
      }
    }
  }, [homeSetup, selectedStore]);

  React.useEffect(() => {
    loadSteps();
    loadQuantityCrucialSteps();
    loadSideModalSteps();
    loadCurrentQuantityCrucialSteps();
  }, [loadSteps, loadQuantityCrucialSteps, loadSideModalSteps, loadCurrentQuantityCrucialSteps]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (!isOnboardingFinished) {
        mutateHomeSetup();
      }
    }, 30000);

    return () => clearInterval(interval);
  }, [mutateHomeSetup, isOnboardingFinished, checkOnboardingFinished]);

  React.useEffect(() => {
    if (!isOnboardingFinished) {
      checkOnboardingFinished();
    }
  }, [checkOnboardingFinished, isOnboardingFinished]);

  // React.useEffect(() => {
  //   if (isOnboardingFinished) {
  //     analytics?.track(
  //       'Has Finished Home First Page',
  //       {
  //         email: user?.email,
  //       },
  //       { context: { groupId: storeAliasId } },
  //     );

  //     analytics?.trackHubSpot(storeAliasId, { is_onboarding_finished: true });

  //     analytics?.track(
  //       'Store Onboarding Finished',
  //       {
  //         email: user?.email,
  //       },
  //       { context: { groupId: storeAliasId } },
  //     );
  //   }
  // }, [analytics, isOnboardingFinished, user, storeAliasId]);

  React.useEffect(() => {
    if (!isSecondOnboardingFinished) {
      loadCurrentSecondOnboardingQuantity();
    }
  }, [isSecondOnboardingFinished, loadCurrentSecondOnboardingQuantity]);

  React.useEffect(() => {
    if (!isSecondOnboardingFinished) {
      analytics?.track(
        'Has Finished Home Second Page',
        {
          email: user?.email,
        },
        { context: { groupId: storeAliasId } },
      );
    }
  }, [analytics, isSecondOnboardingFinished, user, storeAliasId]);

  return (
    <HomeContext.Provider
      value={{
        isOnboardingFinished,
        onboardingSteps,
        homeSetupError,
        isLoadingHomeSetup,
        isValidatingHomeSetup,
        mutateHomeSetup,
        homeSetup,
        sideModalOnboardingSteps,
        quantityCrucialSteps,
        currentQuantityCrucialSteps,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

export const useHome = (): IHomeProvider => {
  const context = React.useContext(HomeContext);

  if (!context) {
    throw new Error('useHome must be used within HomeProvider');
  }

  return context;
};
