/* eslint-disable dot-notation */
import React from 'react';
import { getEnvironment } from '@helpers/utils/environment';

const Iugu = {
  getIugu() {
    if (!window['Iugu']) {
      throw new Error('Iugu script has not been loaded yet.');
    }

    return window['Iugu'];
  },
  setAccountToken(token: string) {
    this.getIugu().setAccountID(token);
  },
  setTestMode(mode: boolean) {
    if (getEnvironment() === 'dev') {
      this.getIugu().setTestMode(mode);
    }
  },
  createPaymentToken(form: React.ReactHTMLElement<HTMLFormElement>) {
    return new Promise((resolve, reject) => {
      this.getIugu().createPaymentToken(form, (res: any) => {
        if (res.errors) {
          console.log(res.errors);

          reject(res);
        }

        resolve(res);
      });
    });
  },
  validateCreditCardNumber(number: string) {
    return this.getIugu().utils.validateCreditCardNumber(number);
  },
};

export const useIugu = (accountId: string): any => {
  React.useEffect(() => {
    if (document.getElementById('iugu-script')) {
      Iugu.setAccountToken(accountId);

      return undefined;
    }

    const script = document.createElement('script');
    script.src = 'https://js.iugu.com/v2';
    script.id = 'iugu-script';

    script.onload = () => {
      Iugu.setAccountToken(accountId);
    };

    document.head.appendChild(script);

    return () => document.getElementById('iugu-script')?.remove();
  }, [accountId]);

  return Iugu;
};
