import React from 'react';
import { Currency } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { IEditBillingProps } from '@domain/interfaces/dashboard/CustomValues/IEditBilling';
import Form from '@components/common/core/Inputs/Form';
import DayPicker from '@components/common/core/Utils/DayPicker';
import { currencyFormatter, currencyToNumber, numberFormatter } from '@helpers/masks';
import { editBillingSchema } from '@helpers/validators/dashboard/customValues/billings';
import Text from '@components/common/core/DataDisplay/Text';
import { useBilling } from '@helpers/hooks/pages/dashboard/customValues/useBilling';
import { useDate } from '@helpers/hooks/useDate';
import SideModalSave from '@components/common/core/Utils/SideModalSave';

import * as S from './styles';

const EditBilling: React.FC<IEditBillingProps> = ({ isOpen, toggle }) => {
  const theme: any = useTheme();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(editBillingSchema),
  });
  const { utcToZonedTime, format } = useDate();
  const {
    selectedBillingToUpdate,
    updateCustomValueBilling,
    isUpdatingCustomValueBilling,
  } = useBilling();

  const amountInput = register('amount');

  const [date, setDate] = React.useState(new Date());

  const handleDate = React.useCallback(selectedDate => setDate(selectedDate), []);

  const onSubmit = React.useCallback(
    async data => {
      if (selectedBillingToUpdate) {
        await updateCustomValueBilling({
          currency: selectedBillingToUpdate.currency,
          amount: currencyToNumber(data.amount),
          billing_date: format(date, 'yyyy-MM-dd'),
        });
      }
    },
    [date, format, selectedBillingToUpdate, updateCustomValueBilling],
  );

  const onAmountChange = React.useCallback(event => {
    event.target.value = currencyFormatter(event.target.value);
  }, []);

  React.useEffect(() => {
    if (selectedBillingToUpdate) {
      setDate(utcToZonedTime(selectedBillingToUpdate.billing_date));
    }
  }, [selectedBillingToUpdate, utcToZonedTime]);

  return (
    <S.SidemodalWrapper>
      <S.Sidemodal isOpen={isOpen} toggle={toggle}>
        <S.Header>
          <Currency size={36} color={theme.colors.green.default} />
          <S.Title>Editar cobrança</S.Title>
        </S.Header>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>Valor</S.Label>
            <S.Input
              {...amountInput}
              onChange={onAmountChange}
              type="text"
              placeholder="Insira o valor de cobrança."
              defaultValue={numberFormatter(selectedBillingToUpdate?.amount || 0, 2)}
            />
            {errors.amount && <Text isErrorFeedback>{errors.amount.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Data de cobrança</S.Label>
            <DayPicker selectedDate={date} setSelectedDate={handleDate} />
          </S.InputGroup>

          <SideModalSave
            type="submit"
            successButtonText="Salvar alterações"
            cancelButtonText="Cancelar"
            onCancel={toggle}
            isLoading={isUpdatingCustomValueBilling}
            isDisabled={isUpdatingCustomValueBilling}
          />
        </Form>
      </S.Sidemodal>
    </S.SidemodalWrapper>
  );
};

export default EditBilling;
