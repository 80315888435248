import styled from 'styled-components/macro';

export const OverviewCardsWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 8px;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 24px;

  @media only screen and (max-width: 1360px) {
    margin-bottom: 16px;
  }

  @media only screen and (max-width: 1282px) {
    flex-wrap: wrap;

    & > div {
      max-width: 49%;
      width: 100%;
    }
  }

  @media only screen and (max-width: 976px) {
    & > div {
      max-width: unset;
    }
  }
`;
