import styled from 'styled-components/macro';

export const Body = styled.div`
  width: 100%;
  max-width: 364px;
  font-family: ${({ theme }) => theme.fonts.body};

  margin-top: 24px;

  text-align: left;
`;
