import React from 'react';
import { Pen } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { Search } from '@profitfy/pakkun-icons';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { EFilterVariant } from '@domain/enums/components/EFilter';
import { IHeaderProps } from '@domain/interfaces/dashboard/ProductCost/IHeader';

import { COGS_FILTER_CONFIG } from '@helpers/utils/filters/cogsFilter';

import { useDebounce } from '@helpers/hooks/useDebounce';
import { useProductCost } from '@helpers/hooks/pages/dashboard/productCost/productCost';

import FilterButton from '@components/common/core/Utils/Filter/FilterButton';

import SkeletonLoading from './SkeletonLoading';
import Modal from './CogsMassEditModal';

import * as S from './styles';

const Header: React.FC<IHeaderProps> = ({
  isLoading,
  handleSearchProduct,
  filterData,
  handleFilterData,
}) => {
  const theme = useTheme();

  const [productName, setProductName] = React.useState<string>('');

  const debouncedProductName = useDebounce(productName, 700);
  const { showMassEditCogsModal, toggleShowMassEditCogsModal } = useProductCost();

  const handleOnChange = React.useCallback(event => setProductName(event.target.value), []);

  const handleNewData = React.useCallback(
    newData => {
      handleFilterData([newData]);
    },
    [handleFilterData],
  );

  React.useEffect(() => {
    handleSearchProduct(debouncedProductName);
  }, [debouncedProductName, handleSearchProduct]);

  return (
    <S.HeaderWrapper>
      {isLoading && <SkeletonLoading />}

      <S.Title>Edite seus custos de produtos</S.Title>

      {Boolean(!filterData.length) && (
        <S.FilterButtonWrapper>
          <FilterButton
            data={filterData}
            config={COGS_FILTER_CONFIG}
            addNewData={handleNewData}
            variant={EFilterVariant.MEDIUM}
          />
        </S.FilterButtonWrapper>
      )}

      <S.EditAllCostsButton
        variant={EButtonVariant.SECONDARY_BUTTON}
        onClick={toggleShowMassEditCogsModal}
      >
        <Pen size={16} color={theme.colors.gray[1]} />
        Editar custo em massa
      </S.EditAllCostsButton>

      <S.SearchProductInput
        type="text"
        onChange={handleOnChange}
        placeholder="Procure por um produto"
        append={Search}
      />

      {showMassEditCogsModal && (
        <Modal isOpen={showMassEditCogsModal} toggle={toggleShowMassEditCogsModal} />
      )}
    </S.HeaderWrapper>
  );
};

export default Header;
