import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
// import { useParams } from 'react-router-dom';

import logoImg from '@assets/img/brand/profitfy-logo.svg';
import Text from '@components/common/core/DataDisplay/Text';
import Form from '@components/common/core/Inputs/Form';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
// import { IParams } from '@domain/interfaces/IParams';
import { useToast } from '@helpers/hooks/useToast';
import { newPasswordSchema } from '@helpers/validators/newPassword';

import * as S from './styles';

const NewPassword: React.FC = () => {
  // const { newPasswordToken } = useParams<IParams>();
  // const history = useHistory();
  const { toast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(newPasswordSchema),
  });

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [, setIsResettingPasswordSuccess] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      try {
        // await resetPasswordService.resetPassword({
        //   token: newPasswordToken || '',
        //   data: {
        //     password: formData.password,
        //     password_confirmation: formData.confirmPassword,
        //   },
        // });

        toast.success('Nova senha defenida com sucesso!');

        setIsResettingPasswordSuccess(true);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsSubmittingForm(false);
      }
    },
    [toast],
  );

  // React.useEffect(() => {
  //   if (!newPasswordToken || isResettingPasswordSuccess) {
  //     history.push('/');
  //     setIsResettingPasswordSuccess(false);
  //   }
  // }, [newPasswordToken, isResettingPasswordSuccess, history]);

  return (
    <S.Container>
      <S.Wrapper>
        <S.Logo src={logoImg} alt="Profitfy.me Logo" />

        <S.ContentWrapper>
          <S.Heading type={EHeadingSize.H3} fontWeight={EHeadingWeight.REGULAR}>
            Nova senha
          </S.Heading>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <S.InputGroup>
              <S.Label>Senha</S.Label>
              <S.Input
                {...register('password')}
                name="password"
                type="password"
                placeholder="Digite a senha"
                data-openreplay-obscured
              />
              {errors.password && <Text isErrorFeedback>{errors.password.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Confirmar senha</S.Label>
              <S.Input
                {...register('confirmPassword')}
                name="confirmPassword"
                type="password"
                placeholder="Digite novamente a senha"
                data-openreplay-obscured
              />
              {errors.confirmPassword && (
                <Text isErrorFeedback>{errors.confirmPassword.message}</Text>
              )}
            </S.InputGroup>

            <S.Button type="submit" isLoading={isSubmittingForm} disabled={isSubmittingForm}>
              Criar senha
            </S.Button>
          </Form>
        </S.ContentWrapper>
      </S.Wrapper>
    </S.Container>
  );
};

export default NewPassword;
