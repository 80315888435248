import React from 'react';

import { BequestDigitalProvider } from '@helpers/hooks/pages/consent/useBequestDigital';

import Wrapper from '@components/Consent/BequestDigital/Wrapper';

import * as S from './styles';

const BequestDigital: React.FC = () => {
  return (
    <BequestDigitalProvider>
      <S.Wrapper>
        <Wrapper />
      </S.Wrapper>
    </BequestDigitalProvider>
  );
};

export default BequestDigital;
