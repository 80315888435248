import React from 'react';
import { useTheme } from 'styled-components/macro';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { IBoletoConversion } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IAdSenseCampaign';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import { numberFormatter } from '@helpers/masks';

import Chart from './Chart';
import BlurredContent from './BlurredContent';

import * as S from './styles';

const BoletoConversion: React.FC<ICampaignProps> = ({ data, rowData }) => {
  const boletoConversionData: IBoletoConversion = data;

  const { store } = useStoreConfig();
  const { isDemoEnvironment } = useConfig();
  const theme = useTheme();

  const chartData = [
    {
      name: 'Conversão',
      value: boletoConversionData.conversion,
    },
    {
      name: 'Total',
      value: 100 - boletoConversionData.conversion,
    },
  ];

  const colors = [theme.colors.success.light, theme.colors.gray[4]];

  const isContentBlurred =
    (!store.is_utm_onboarding_finished || !rowData.has_at_least_one_ads_with_utm_synchronized) &&
    !isDemoEnvironment &&
    !rowData.has_all_ads_with_utm_synchronized;

  return (
    <S.Wrapper>
      {isContentBlurred ? (
        <BlurredContent />
      ) : (
        <>
          <S.ChartWrapper>
            <Chart data={chartData} colors={colors} />
            <S.ConversionPercentage>{`${numberFormatter(
              boletoConversionData.conversion,
              0,
              true,
            )}%`}</S.ConversionPercentage>
          </S.ChartWrapper>
          <S.TextContentWrapper>
            <S.ContentWrapper>
              <S.LabelText>Pagos</S.LabelText>
              <S.AmountText>{`R$ ${numberFormatter(boletoConversionData.paid.amount, 2)} (${
                boletoConversionData.paid.quantity
              })`}</S.AmountText>
            </S.ContentWrapper>

            <S.ContentWrapper>
              <S.LabelText>Pendentes</S.LabelText>
              <S.AmountText>{`R$ ${numberFormatter(boletoConversionData.pending.amount, 2)} (${
                boletoConversionData.pending.quantity
              })`}</S.AmountText>
            </S.ContentWrapper>
          </S.TextContentWrapper>
        </>
      )}
    </S.Wrapper>
  );
};

export default BoletoConversion;
