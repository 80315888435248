import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const ChartWrapper = styled.div`
  height: 20px;
  width: 176px;

  path {
    transform: scaleY(4.44) translateY(-5px);
  }
`;

export const Label = styled(TextBase)`
  line-height: 120%;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[2]};
  font-weight: 300;
`;

export const PixBullet = styled.span`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #b78aff;
`;

export const CreditCardBullet = styled.span`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #50e170;
`;

export const BoletoBullet = styled.span`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #5b8def;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Percentage = styled(TextBase)`
  line-height: 120%;
  font-size: 1rem;
  font-weight: 700;
`;

export const PaymentMethod = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PaymentMethodsOverview = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media only screen and (max-width: 1360px) {
    gap: 36px;
  }

  @media only screen and (max-width: 576px) {
    gap: 20px;
  }
`;

export const Card = styled.div`
  max-width: 420px;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 12px 24px;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 12px;
  box-sizing: border-box;
  gap: 36px;

  @media only screen and (max-width: 1408px) {
    padding: 12px;
  }

  @media only screen and (max-width: 1388px) {
    max-width: 380px;
  }

  @media only screen and (max-width: 1360px) {
    padding: 12px 24px;
    max-width: unset;
  }
`;
