/* eslint-disable no-restricted-syntax */
import { ISelectedFilter } from '@domain/interfaces/components/IFilter';
import { IWorkflows } from '@domain/interfaces/common/automations/IWorkflows';

export const filterWorkflowsData = (
  data: IWorkflows[],
  filters: ISelectedFilter[],
): IWorkflows[] | undefined => {
  if (!data) return undefined;

  let newData = [...data];

  for (const filter of filters) {
    const filterType = filter?.value;

    // if (filterType === ETriggers.ABANDONED_CART) {
    newData = newData.filter(workflow => {
      return workflow.workflow.type === filterType;
    });
    // }
  }

  return newData;
};
