import React from 'react';
import { useHistory } from 'react-router-dom';

import { useNewKit } from '@helpers/hooks/pages/dashboard/kit/useNewKit';

import * as S from './styles';

const Submit: React.FC = () => {
  const history = useHistory();
  const { isCreatingKit } = useNewKit();

  const onCancelClick = React.useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <S.Wrapper>
      <S.CancelButton type="button" onClick={onCancelClick} disabled={isCreatingKit}>
        Cancelar
      </S.CancelButton>
      <S.SubmitButton type="submit" disabled={isCreatingKit} isLoading={isCreatingKit}>
        Salvar novo kit
      </S.SubmitButton>
    </S.Wrapper>
  );
};

export default Submit;
