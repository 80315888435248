import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { EButtonTextColor } from '@domain/enums/components/EButton';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';
import { useStorePermission } from '@helpers/hooks/common/useStorePermission';

import { formSchema } from '@helpers/validators/dashboard/accessManagement/invite';

import groupService from '@services/pages/dashboard/accessManagement/group';
import userInviteService from '@services/pages/dashboard/accessManagement/userInvite';
import userStoreService from '@services/pages/dashboard/accessManagement/userStore';

import LimitationModal from '@components/common/core/Utils/LimitationModal';
import Text from '@components/common/core/DataDisplay/Text';
import Return from '../Return';

import * as S from './styles';

const NewMember: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const { analytics } = useConfig();
  const history = useHistory();
  const { toast } = useToast();
  const { maxUsers } = useStorePermission();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  });

  const [emailValue, setEmailValue] = React.useState<string>('');
  const [isCreatingInvite, setIsCreatingInvite] = React.useState<boolean>(false);
  const [isLimitationModalOpen, setIsLimitationModalOpen] = React.useState<boolean>(false);

  const { groups } = groupService.listGroups({ storeAliasId });

  const emailInputRegister = register('email');

  const handleLimitationModalOpen = React.useCallback(
    () => setIsLimitationModalOpen(state => !state),
    [],
  );

  const onEmailChange = React.useCallback(
    event => {
      emailInputRegister.onChange(event);

      setEmailValue(event.target.value);
    },
    [emailInputRegister],
  );

  const onSubmit = React.useCallback(
    async formData => {
      setIsCreatingInvite(true);

      const data = {
        email: formData.email,
      };

      try {
        const { data: usersStoreData } = await userStoreService.listUsersStorePromise({
          storeAliasId,
        });

        const invitedUsers = usersStoreData.users.length;

        if (invitedUsers + 1 >= maxUsers && maxUsers >= 0) {
          handleLimitationModalOpen();
          return;
        }

        await userInviteService.createUserInvitePromise({
          storeAliasId,
          groupAliasId: formData.group,
          data,
        });

        toast.success('Convite enviado!');

        analytics?.track(
          'Invite Members Sent Invitation',
          {
            email: formData?.email,
          },
          { context: { groupId: storeAliasId } },
        );

        setIsCreatingInvite(false);

        history.push(`/${storeAliasId}/dashboard/access-manager?has_invited=true`);
      } catch (error: any) {
        setIsCreatingInvite(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [storeAliasId, toast, history, analytics, handleLimitationModalOpen, maxUsers],
  );

  const onCreateNewGroupButtonClick = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/access-manager/onboarding/new-group`);
  }, [history, storeAliasId]);

  return (
    <S.Container>
      <Return />

      <S.ContentWrapper>
        <S.Header>
          <S.Heading>Novo membro</S.Heading>
          <S.CreateNewGroupButton onClick={onCreateNewGroupButtonClick}>
            Criar novo grupo
          </S.CreateNewGroupButton>
        </S.Header>

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>E-mail</S.Label>
            <S.Input
              {...emailInputRegister}
              value={emailValue}
              onChange={onEmailChange}
              type="text"
              placeholder="Digite o seu e-mail"
              isError={errors.email}
            />
            {errors.email && <Text isErrorFeedback>{errors.email.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Grupo</S.Label>
            <S.GroupSelect {...register('group')} defaultValue="">
              <S.Option value="" disabled>
                Selecione um grupo
              </S.Option>
              {groups?.map(group => (
                <S.Option key={group.alias_id} value={group.alias_id}>
                  {group.name}
                </S.Option>
              ))}
            </S.GroupSelect>
            {errors.group && <Text isErrorFeedback>{errors.group.message}</Text>}
          </S.InputGroup>

          <S.SendInviteButton
            type="submit"
            textColor={EButtonTextColor.DARK}
            disabled={isCreatingInvite}
            isLoading={isCreatingInvite}
          >
            Enviar convite
          </S.SendInviteButton>
        </S.Form>
      </S.ContentWrapper>

      <LimitationModal
        isOpen={isLimitationModalOpen}
        toggle={handleLimitationModalOpen}
        imageSrc="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/temp/limitation-temp.svg"
        title="Limite de usuários atingido"
        description="Escolha um plano que te permita convidar mais para que consiga usufruir o melhor da ferramenta."
        trackingPropertyName="MAX_USERS_REACHED"
      />
    </S.Container>
  );
};

export default NewMember;
