export enum ESelectedCategoryOption {
  ALL_MESSAGES = '',
  WHATSAPP = 'WHATSAPP',
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export enum EMessageContentType {
  TEXT = 'TEXT',
  AUDIO = 'AUDIO',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  PDF = 'PDF',
  PRODUCT_IMAGE = 'PRODUCT_IMAGE',
}

export enum ECategory {
  WHATSAPP = 'WHATSAPP',
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export enum ECreateMessageStep {
  SELECT_MESSAGE_TYPE = 'SELECT_MESSAGE_TYPE',
  SELECT_MESSAGE_TEMPLATE = 'SELECT_MESSAGE_TEMPLATE',
  CONFIG_MESSAGE = 'CONFIG_MESSAGE',
}

export enum EMessageType {
  ABANDONED_CART = 'ABANDONED_CART',
  PIX = 'PIX',
  BOLETO = 'BOLETO',
  REJECTED_PAYMENT = 'REJECTED_PAYMENT',
  SIMPLE_SHIPMENT = 'SIMPLE_SHIPMENT',
  UPDATED_FULFILLMENT = 'UPDATED_FULFILLMENT',
}

export enum EConfigOption {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  PDF = 'PDF',
  PRODUCT_IMAGE = 'PRODUCT_IMAGE',
  NONE = 'NONE',
}
