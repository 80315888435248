import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import OnboardingNotFinished from '@components/Dashboard/AdsManager/UtmsTooltipsContent/OnboardingNotFinished';

import * as S from './styles';

const BlurredContent: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const history = useHistory();

  const redirectToUtmOnboarding = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/marketing/ads-manager/onboarding/script-utms`);
  }, [history, storeAliasId]);

  return (
    <S.CustomHoverCard>
      <S.CustomHoverCardTrigger>
        <S.ContentWrapper>
          <S.LabelText>Receita</S.LabelText>
          <S.AmountText>R$ 00,00</S.AmountText>
        </S.ContentWrapper>

        <S.ContentWrapper>
          <S.LabelText>CPA</S.LabelText>
          <S.AmountText>R$ 00,00</S.AmountText>
        </S.ContentWrapper>
      </S.CustomHoverCardTrigger>

      <S.CustomHoverCardContent side="top" sideOffset={5}>
        <OnboardingNotFinished startUtmOnboarding={redirectToUtmOnboarding} />
        <S.CustomHoverCard.Arrow />
      </S.CustomHoverCardContent>
    </S.CustomHoverCard>
  );
};

export default BlurredContent;
