import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const CpmText = styled(TextBase)``;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 58px;
  padding: 10px 12px;
  box-sizing: border-box;
`;
