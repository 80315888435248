import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ETextSize } from '@domain/enums/components/EText';
import { useNoInformation } from '@helpers/hooks/pages/dashboard/feed/useNoInformation';
import Order from './Order';
import InfiniteScrollLoading from './InfiniteScrollLoading';

import * as S from './styles';

const NoInformationOrders: React.FC = () => {
  const {
    isLoadingNoInformationOrders,
    loadNoInformationOrders,
    noInformationOrders,
    hasMore,
    handleLoadMore,
  } = useNoInformation();

  React.useEffect(() => {
    loadNoInformationOrders();
  }, [loadNoInformationOrders]);

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>Necessário alteração manual do pedido</S.Title>
        <S.Description size={ETextSize.B5}>
          Esse pedido foi criado manualmente em sua plataforma de e-commerce. Informe os dados para
          contabilizá-lo
        </S.Description>
      </S.Header>

      <S.OrdersWrapper id="no-information-orders-list">
        <InfiniteScroll
          dataLength={noInformationOrders.length}
          next={handleLoadMore}
          hasMore={hasMore}
          loader={<InfiniteScrollLoading />}
          scrollThreshold={0.95}
          scrollableTarget="no-information-orders-list"
        >
          {!noInformationOrders.length && isLoadingNoInformationOrders ? (
            <InfiniteScrollLoading />
          ) : (
            <></>
          )}

          {noInformationOrders.map(order => (
            <Order key={order.alias_id} order={order} />
          ))}
        </InfiniteScroll>
      </S.OrdersWrapper>
    </S.Wrapper>
  );
};

export default NoInformationOrders;
