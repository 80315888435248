import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import TextBase from '@components/common/core/DataDisplay/Text';
import BadgeBase from '@components/common/core/DataDisplay/Badge';
import ButtonBase from '@components/common/core/Inputs/Button';
import CustomTooltip from '@components/common/core/Utils/CustomTooltip';
import NoImageBase from '@components/common/core/DataDisplay/NoImage';

export const NoImage = styled(NoImageBase)`
  background-color: ${({ theme }) => theme.colors.gray[5]};
  min-width: 48px;
  height: 48px;
`;

export const TooltipText = styled(TextBase)``;

export const Tooltip = styled(CustomTooltip)``;

export const SolveButton = styled(ButtonBase)``;

export const OrderRevenueDescription = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const OrderRevenueValue = styled(TextBase)`
  width: 128px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`;

export const OrderRevenueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 128px;
  width: 100%;
`;

export const OrderDate = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const FirstOrderItem = styled(TextBase)`
  width: 224px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`;

export const OrderDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 224px;
  width: 100%;
`;

export const OrderStatus = styled(BadgeBase)``;

export const OrderNumberLink = styled.a`
  width: 148px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.font};
  font-size: 1rem;
  font-weight: 400;
  text-decoration: unset;
  text-decoration: underline;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.green.default};
  }

  &:active {
    color: ${({ theme }) => theme.colors.green.dark};
  }
`;

export const OrderNumber = styled(TextBase)`
  width: 148px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`;

export const OrderNumberAndStatus = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Image = styled(ImageBase)`
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

export const ProviderImage = styled(ImageBase)`
  min-width: 16px;
  width: 16px;
  height: 16px;
`;

export const ProviderImageWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -11px;
  right: -14px;
`;

export const ImageWrapper = styled.div`
  position: relative;
`;

export const ImageAndOrder = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  max-width: 224px;
  width: 100%;
`;

export const Wrapper = styled.div`
  min-width: 768px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 28px;
  gap: 16px;
`;
