import React from 'react';

import { EHeadAlign } from '@domain/enums/components/ETable';

import { useOrders } from '@helpers/hooks/pages/dashboard/orders/useOrders';

import Paginate from '@components/common/core/Utils/Paginate';
import EmptyData from '@components/common/core/DataDisplay/EmptyData';

import SkeletonLoading from './SkeletonLoading';
import Row from './Row';

import * as S from './styles';

const Body: React.FC = () => {
  const {
    isLoadingOrdersProvider,
    isValidatingOrdersProvider,
    ordersPage,
    ordersPageCount,
    handlePageChange,
    orders,
  } = useOrders();

  const hasOrders = Boolean(orders?.length);

  const isLoading = isLoadingOrdersProvider || isValidatingOrdersProvider;

  if (isLoading && !hasOrders) return <SkeletonLoading />;

  return (
    <S.Container>
      <S.Table>
        <S.Table.Header>
          <S.Table.Row>
            <S.Table.Head>N. do Pedido</S.Table.Head>
            <S.Table.Head>Produto</S.Table.Head>
            <S.Table.Head align={EHeadAlign.CENTER}>Receita</S.Table.Head>
            <S.Table.Head align={EHeadAlign.RIGHT}>C. Produtos</S.Table.Head>
            <S.Table.Head align={EHeadAlign.RIGHT}>Frete</S.Table.Head>
            <S.Table.Head align={EHeadAlign.RIGHT}>Taxas</S.Table.Head>
            <S.Table.Head align={EHeadAlign.RIGHT}>Impostos</S.Table.Head>
            <S.Table.Head align={EHeadAlign.CENTER}>Lucro Líquido</S.Table.Head>
            <S.Table.Head align={EHeadAlign.CENTER}>Status</S.Table.Head>
            <S.Table.Head align={EHeadAlign.CENTER}>Origem</S.Table.Head>
            <S.Table.Head />
          </S.Table.Row>
        </S.Table.Header>

        <S.Table.Body>
          {hasOrders && (
            <>
              {orders?.map(order => (
                <Row order={order} key={order.id} />
              ))}
            </>
          )}

          {!hasOrders && (
            <S.Table.Row>
              <S.Table.Data colSpan={11} align={EHeadAlign.CENTER}>
                <EmptyData content="Não encontramos nenhum pedido." />
              </S.Table.Data>
            </S.Table.Row>
          )}
        </S.Table.Body>
      </S.Table>

      {hasOrders && (
        <S.PaginateWrapper>
          <S.Table.Footer>
            <S.Table.Row>
              <S.Table.Data colSpan={11} align={EHeadAlign.RIGHT}>
                <Paginate
                  forcePage={ordersPage}
                  pageCount={ordersPageCount}
                  onPageChange={handlePageChange}
                />
              </S.Table.Data>
            </S.Table.Row>
          </S.Table.Footer>
        </S.PaginateWrapper>
      )}
    </S.Container>
  );
};

export default Body;
