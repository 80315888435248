import React from 'react';

import { VariantProvider } from '@helpers/hooks/pages/dashboard/kit/useVariant';
import { useNewKit } from '@helpers/hooks/pages/dashboard/kit/useNewKit';
import Variant from './Variant';
import NewVariant from './NewVariant';

import * as S from './styles';

const Variants: React.FC = () => {
  const { newKitVariants } = useNewKit();

  return (
    <S.Wrapper>
      {newKitVariants.map((variant, index) => (
        <VariantProvider key={`${variant?.variant?.alias_id || 'new_variant'}${index * 1}`}>
          <Variant variant={variant} index={index} />
        </VariantProvider>
      ))}

      <NewVariant />
    </S.Wrapper>
  );
};

export default Variants;
