/* eslint-disable import/no-duplicates */
import React from 'react';

import pt from 'date-fns/locale/pt-BR';

import { formatDistanceToNow } from 'date-fns';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { ICardProps } from '@domain/interfaces/dashboard/FinancialDashboard/ICard';
import { numberFormatter } from '@helpers/masks';
import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { useDate } from '@helpers/hooks/useDate';

import MarketingInfo from './MarketingInfo';
import NetRevenueInfo from './NetRevenueInfo';
import CogsInfo from './CogsInfo';
import TaxesInfo from './TaxesInfo';
import CogsHoverCard from './CogsInfo/HoverCard';
import NetRevenueHoverCard from './NetRevenueInfo/HoverCard';
import MarketingHoverCard from './MarketingInfo/HoverCard';
import TaxesHoverCard from './TaxesInfo/HoverCard';
import RefreshMarketingData from './RefreshMarketingData';

import * as S from './styles';

const Card: React.FC<ICardProps> = ({ icon, title, value, percentage, id, isSelected }) => {
  const { handleSelectedChart, financialReport } = useFinancialDashboard();
  const { utcToZonedTime } = useDate();

  const lastMarketingSync = financialReport.marketing.last_sync;

  const [, setUpdatedDate] = React.useState<Date>(new Date(lastMarketingSync));
  const [isHoveringCard, setIsHoveringCard] = React.useState<boolean>(false);

  const onMouseEnter = React.useCallback(() => setIsHoveringCard(true), []);

  const onMouseLeave = React.useCallback(() => setIsHoveringCard(false), []);

  const handleCardClick = React.useCallback(() => {
    handleSelectedChart(id);
  }, [id, handleSelectedChart]);

  const isPercentagePositive = percentage >= 0;

  const parsedPercentageValue = numberFormatter(
    isPercentagePositive ? percentage : Math.abs(percentage),
    1,
  );

  React.useEffect(() => {
    const interval = setInterval(() => {
      setUpdatedDate(new Date());
    }, 60000);

    return () => clearInterval(interval);
  }, [lastMarketingSync]);

  const isMarketingCard = title === 'Marketing';
  const isNetRevenueCard = title === 'Receita Líquida';
  const isTaxCard = title === 'Taxas e Impostos';
  const isCogsCard = title === 'Custo dos Produtos';

  const lastSyncText = `Atualizado há ${formatDistanceToNow(utcToZonedTime(lastMarketingSync), {
    locale: pt,
  })}`;

  return (
    <S.CardWrapper
      onClick={handleCardClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {icon}

      <S.Content isMarketing={isMarketingCard}>
        <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
          {title}
        </S.Title>

        <S.ValueAndPercentageWrapper>
          <S.Value weight={ETextWeight.SEMIBOLD} size={ETextSize.B3}>
            {`R$ ${numberFormatter(value, 2)}`}
          </S.Value>

          <S.PercentageWrapper>
            <S.PercentageArrow isPositive={isPercentagePositive} />
            <S.PercentageNumber
              isPositive={isPercentagePositive}
            >{`${parsedPercentageValue}%`}</S.PercentageNumber>
          </S.PercentageWrapper>
        </S.ValueAndPercentageWrapper>

        {isMarketingCard && <S.LastSyncText>{lastSyncText}</S.LastSyncText>}
      </S.Content>

      {isNetRevenueCard && (
        <NetRevenueHoverCard>
          <NetRevenueInfo />
        </NetRevenueHoverCard>
      )}

      {isCogsCard && (
        <CogsHoverCard>
          <CogsInfo />
        </CogsHoverCard>
      )}

      {isMarketingCard && (
        <>
          <MarketingHoverCard>
            <MarketingInfo />
          </MarketingHoverCard>

          {isHoveringCard && <RefreshMarketingData />}
        </>
      )}

      {isTaxCard && (
        <TaxesHoverCard>
          <TaxesInfo />
        </TaxesHoverCard>
      )}

      <S.SelectedOverlayer isSelected={isSelected} />
    </S.CardWrapper>
  );
};

export default Card;
