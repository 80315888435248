import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const SeePlansButton = styled(ButtonBase)`
  width: 100%;
  background-color: unset;
  background: linear-gradient(225deg, #1f9aea 0%, #49f898 100%);
`;

export const NotInterestedButton = styled(ButtonBase)`
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Description = styled(TextBase)``;

export const Title = styled(TextBase)`
  font-size: 1.25rem;
  font-weight: 600;
`;

export const TitleAndDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContentWrapper = styled.div`
  padding: 0 14px 14px 14px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Image = styled(ImageBase)`
  max-width: 480px;
`;

export const Wrapper = styled.div`
  padding: 128px 0px 40px 0px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 504px;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  height: 100%;

  @media only screen and (max-height: 720px) {
    padding: 40px 0px;
  }
`;
