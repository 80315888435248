import React from 'react';

import * as S from './styles';

const BlurredContent: React.FC = () => {
  return (
    <>
      <S.ApprovedOrders>00 Aprovados</S.ApprovedOrders>
      <S.PendingOrders>00 Pendentes</S.PendingOrders>
      <S.OrdersLabel>Total</S.OrdersLabel>
    </>
  );
};

export default BlurredContent;
