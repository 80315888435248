import { ITableColumn } from '@domain/interfaces/dashboard/AdsManager/IAdsManager';

import {
  IAdSenseCampaign,
  ISummaryReport,
  IMappedAdSenseCampaignData,
  IMappedAdSenseCampaignFooter,
} from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IAdSenseCampaigns';
import { IAdSenseCampaignToUpdate } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IAdSenseCampaignsActions';

import Checkbox from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/Checkbox';
import Switch from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/Switch';
import AdName from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/AdName';
import Insights from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/Insights';
import Status from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/Status';
import Budget from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/Budget';
import NetRevenue from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/NetRevenue';
import SpendAmount from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/SpendAmount';
import Cogs from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/Cogs';
import Profit from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/Profit';
import Roi from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/Roi';
import Roas from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/Roas';
import Cpa from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/Cpa';
import CpaUtm from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/CpaUtm';
import CreditCard from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/CreditCard';
import Boleto from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/Boleto';
import BoletoConversion from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/BoletoConversion';
import Pix from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/Pix';
import PixConversion from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/PixConversion';
import Prints from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/Prints';
import Ctr from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/Ctr';
import Cpc from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/Cpc';
import Cpm from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/Cpm';
import Orders from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Body/Content/Orders';

import TotalCampaigns from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/TotalCampaigns';
import TotalInsights from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/Insights';
import TotalStatus from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/Status';
import TotalBudget from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/Budget';
import TotalNetRevenue from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/NetRevenue';
import TotalSpendAmount from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/SpendAmount';
import TotalCogs from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/Cogs';
import TotalProfit from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/Profit';
import TotalRoi from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/Roi';
import TotalRoas from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/Roas';
import TotalCpa from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/Cpa';
import TotalCpaUtm from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/CpaUtm';
import TotalCreditCard from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/CreditCard';
import TotalBoleto from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/Boleto';
import TotalBoletoConversion from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/BoletoConversion';
import TotalPix from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/Pix';
import TotalPixConversion from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/PixConversion';
import TotalPrints from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/Prints';
import TotalCtr from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/Ctr';
import TotalCpc from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/Cpc';
import TotalCpm from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/Cpm';
import TotalOrders from '@components/Dashboard/AdsManager/Campaigns/CampaignsTable/Table/Footer/Content/Orders';

export const mapCampaignsToDuplicate = (
  campaigns: Array<string>,
  adSenseCampaignsReport: Array<IAdSenseCampaign>,
): Array<IAdSenseCampaignToUpdate> => {
  const mappedCampaigns = campaigns.map(campaign => {
    const foundAdSenseCampaign = adSenseCampaignsReport.find(
      adSenseCampaign => adSenseCampaign.alias_id === campaign,
    ) as IAdSenseCampaign;

    return {
      id: foundAdSenseCampaign.id,
      alias_id: foundAdSenseCampaign.alias_id,
      name: foundAdSenseCampaign.name,
      provider: foundAdSenseCampaign.provider,
      status: foundAdSenseCampaign.status,
      daily_budget: foundAdSenseCampaign.daily_budget,
      provider_id: foundAdSenseCampaign.provider_id,
    };
  });

  return mappedCampaigns;
};

const sortStringData = (currentData: any, nextData: any, type: 'ASC' | 'DESC'): number => {
  if (type === 'DESC') {
    if (currentData > nextData) return 1;

    if (currentData < nextData) return -1;

    return 0;
  }

  if (nextData > currentData) return 1;

  if (nextData < currentData) return -1;

  return 0;
};

const sortNumberData = (currentData: any, nextData: any, type: 'ASC' | 'DESC'): number => {
  const sortDescValue = (value: number): any => {
    return value === 0 ? Infinity : value;
  };

  const sortAscValue = (value: number): any => {
    return value === 0 ? -Infinity : value;
  };

  if (type === 'DESC') {
    return sortDescValue(currentData) - sortDescValue(nextData);
  }

  return sortAscValue(nextData) - sortAscValue(currentData);
};

export const sortMappedCampaignsData = (
  tableColumns: Array<ITableColumn>,
  mappedData: Array<IMappedAdSenseCampaignData>,
  sort: any,
): Array<IMappedAdSenseCampaignData> => {
  const { id, sort: sortType } = sort;
  const activeTableColumns = tableColumns.filter(column => column.isActive);

  const indexOfColumn = activeTableColumns.findIndex(column => column.id === id);
  const selectedColumn = activeTableColumns[indexOfColumn];

  if (indexOfColumn === -1) return mappedData;

  const newMappedData = [...mappedData];

  newMappedData.sort((currentObject, nextObject) => {
    if (selectedColumn.id === 'ad_name') {
      return sortStringData(
        currentObject.data[indexOfColumn].data,
        nextObject.data[indexOfColumn].data,
        sortType,
      );
    }

    if (selectedColumn.id === 'insights') {
      return sortStringData(
        currentObject.data[indexOfColumn].data.length,
        nextObject.data[indexOfColumn].data.length,
        sortType,
      );
    }

    if (selectedColumn.id === 'status') {
      return sortStringData(
        currentObject.data[indexOfColumn].data,
        nextObject.data[indexOfColumn].data,
        sortType,
      );
    }

    if (selectedColumn.id === 'budget') {
      return sortNumberData(
        currentObject.data[indexOfColumn].data,
        nextObject.data[indexOfColumn].data,
        sortType,
      );
    }

    if (selectedColumn.id === 'net_revenue') {
      return sortNumberData(
        currentObject.data[indexOfColumn].data.amount,
        nextObject.data[indexOfColumn].data.amount,
        sortType,
      );
    }

    if (selectedColumn.id === 'spend_amount') {
      return sortNumberData(
        currentObject.data[indexOfColumn].data.amount,
        nextObject.data[indexOfColumn].data.amount,
        sortType,
      );
    }

    if (selectedColumn.id === 'cogs') {
      return sortNumberData(
        currentObject.data[indexOfColumn].data.amount,
        nextObject.data[indexOfColumn].data.amount,
        sortType,
      );
    }

    if (selectedColumn.id === 'profit') {
      return sortNumberData(
        currentObject.data[indexOfColumn].data.amount,
        nextObject.data[indexOfColumn].data.amount,
        sortType,
      );
    }

    if (selectedColumn.id === 'roi') {
      return sortNumberData(
        currentObject.data[indexOfColumn].data.amount,
        nextObject.data[indexOfColumn].data.amount,
        sortType,
      );
    }

    if (selectedColumn.id === 'roas') {
      return sortNumberData(
        currentObject.data[indexOfColumn].data.amount,
        nextObject.data[indexOfColumn].data.amount,
        sortType,
      );
    }

    if (selectedColumn.id === 'cpa') {
      return sortNumberData(
        currentObject.data[indexOfColumn].data.amount,
        nextObject.data[indexOfColumn].data.amount,
        sortType,
      );
    }

    if (selectedColumn.id === 'cpa_utm') {
      return sortNumberData(
        currentObject.data[indexOfColumn].data.amount,
        nextObject.data[indexOfColumn].data.amount,
        sortType,
      );
    }

    if (selectedColumn.id === 'prints') {
      return sortNumberData(
        currentObject.data[indexOfColumn].data.quantity,
        nextObject.data[indexOfColumn].data.quantity,
        sortType,
      );
    }

    if (selectedColumn.id === 'ctr') {
      return sortNumberData(
        currentObject.data[indexOfColumn].data.percentage,
        nextObject.data[indexOfColumn].data.percentage,
        sortType,
      );
    }

    if (selectedColumn.id === 'cpc') {
      return sortNumberData(
        currentObject.data[indexOfColumn].data.amount,
        nextObject.data[indexOfColumn].data.amount,
        sortType,
      );
    }

    if (selectedColumn.id === 'cpm') {
      return sortNumberData(
        currentObject.data[indexOfColumn].data.amount,
        nextObject.data[indexOfColumn].data.amount,
        sortType,
      );
    }

    if (selectedColumn.id === 'credit_card') {
      return sortNumberData(
        currentObject.data[indexOfColumn].data.cpa_amount,
        nextObject.data[indexOfColumn].data.cpa_amount,
        sortType,
      );
    }

    if (selectedColumn.id === 'boleto') {
      return sortNumberData(
        currentObject.data[indexOfColumn].data.cpa_amount,
        nextObject.data[indexOfColumn].data.cpa_amount,
        sortType,
      );
    }

    if (selectedColumn.id === 'boleto_conversion') {
      return sortNumberData(
        currentObject.data[indexOfColumn].data.conversion,
        nextObject.data[indexOfColumn].data.conversion,
        sortType,
      );
    }

    if (selectedColumn.id === 'pix') {
      return sortNumberData(
        currentObject.data[indexOfColumn].data.cpa_amount,
        nextObject.data[indexOfColumn].data.cpa_amount,
        sortType,
      );
    }

    if (selectedColumn.id === 'pix_conversion') {
      return sortNumberData(
        currentObject.data[indexOfColumn].data.conversion,
        nextObject.data[indexOfColumn].data.conversion,
        sortType,
      );
    }

    return sortNumberData(
      currentObject.data[indexOfColumn].data.paid_quantity +
        currentObject.data[indexOfColumn].data.pending_quantity,
      nextObject.data[indexOfColumn].data.conversion +
        nextObject.data[indexOfColumn].data.pending_quantity,
      sortType,
    );
  });

  return newMappedData;
};

export const mapFooterCampaignsData = (
  tableColumns: Array<ITableColumn>,
  data: ISummaryReport,
): Array<IMappedAdSenseCampaignFooter> => {
  const activeColumns = tableColumns.filter(column => column.isActive);

  const slicedActiveColumns = activeColumns.slice(3, activeColumns.length);

  const mappedData = slicedActiveColumns.map(column => {
    if (column.id === 'insights') {
      return {
        id: column.id,
        data: undefined,
        forecastData: undefined,
        component: TotalInsights,
      };
    }

    if (column.id === 'status') {
      return {
        id: column.id,
        data: undefined,
        forecastData: undefined,
        component: TotalStatus,
      };
    }

    if (column.id === 'budget') {
      return {
        id: column.id,
        data: data.marketing_report.daily_budget,
        forecastData: undefined,
        component: TotalBudget,
      };
    }

    if (column.id === 'net_revenue') {
      return {
        id: column.id,
        data: data.financial_report.net_revenue,
        forecastData: data?.forecast_report?.net_revenue,
        component: TotalNetRevenue,
      };
    }

    if (column.id === 'spend_amount') {
      return {
        id: column.id,
        data: data.marketing_report.spend,
        forecastData: undefined,
        component: TotalSpendAmount,
      };
    }

    if (column.id === 'cogs') {
      return {
        id: column.id,
        data: data.financial_report.cogs,
        forecastData: data?.forecast_report?.cogs,
        component: TotalCogs,
      };
    }

    if (column.id === 'profit') {
      return {
        id: column.id,
        data: data.financial_report.net_profit,
        forecastData: data?.forecast_report?.net_profit,
        component: TotalProfit,
      };
    }

    if (column.id === 'roi') {
      return {
        id: column.id,
        data: data.financial_report.roi,
        forecastData: data?.forecast_report?.roi,
        component: TotalRoi,
      };
    }

    if (column.id === 'roas') {
      return {
        id: column.id,
        data: data.marketing_report.roas,
        forecastData: undefined,
        component: TotalRoas,
      };
    }

    if (column.id === 'cpa') {
      return {
        id: column.id,
        data: data.marketing_report.cpa,
        forecastData: undefined,
        component: TotalCpa,
      };
    }

    if (column.id === 'cpa_utm') {
      return {
        id: column.id,
        data: data.financial_report.cpa,
        forecastData: data?.forecast_report?.cpa,
        component: TotalCpaUtm,
      };
    }

    if (column.id === 'credit_card') {
      return {
        id: column.id,
        data: data.financial_report.card,
        forecastData: undefined,
        component: TotalCreditCard,
      };
    }

    if (column.id === 'boleto') {
      return {
        id: column.id,
        data: data.financial_report.boleto,
        forecastData: undefined,
        component: TotalBoleto,
      };
    }

    if (column.id === 'boleto_conversion') {
      return {
        id: column.id,
        data: data.financial_report.boleto_conversion,
        forecastData: undefined,
        component: TotalBoletoConversion,
      };
    }

    if (column.id === 'pix') {
      return {
        id: column.id,
        data: data.financial_report.pix,
        forecastData: undefined,
        component: TotalPix,
      };
    }

    if (column.id === 'pix_conversion') {
      return {
        id: column.id,
        data: data.financial_report.pix_conversion,
        forecastData: undefined,
        component: TotalPixConversion,
      };
    }

    if (column.id === 'prints') {
      return {
        id: column.id,
        data: data.marketing_report.impressions,
        forecastData: undefined,
        component: TotalPrints,
      };
    }

    if (column.id === 'ctr') {
      return {
        id: column.id,
        data: data.marketing_report.ctr,
        forecastData: undefined,
        component: TotalCtr,
      };
    }

    if (column.id === 'cpc') {
      return {
        id: column.id,
        data: data.marketing_report.cpc,
        forecastData: undefined,
        component: TotalCpc,
      };
    }

    if (column.id === 'cpm') {
      return {
        id: column.id,
        data: data.marketing_report.cpm,
        forecastData: undefined,
        component: TotalCpm,
      };
    }

    return {
      id: column.id,
      data: data.financial_report.orders,
      forecastData: undefined,
      component: TotalOrders,
    };
  });

  return [
    {
      id: 'total_campaigns',
      data: data.ad_sense_campaign,
      component: TotalCampaigns,
    },
    ...mappedData,
  ];
};

export const mapCampaignsData = (
  tableColumns: Array<ITableColumn>,
  data: Array<IAdSenseCampaign>,
): Array<IMappedAdSenseCampaignData> => {
  const filteredTableColumns = tableColumns.filter(column => column.isActive);

  const mappedData = data.map(innerData => {
    const newData = filteredTableColumns.map(column => {
      if (column.id === 'checkbox') {
        return {
          id: column.id,
          data: '',
          forecastData: undefined,
          rowData: innerData,
          component: Checkbox,
        };
      }

      if (column.id === 'is_active') {
        return {
          id: column.id,
          data: innerData.status,
          rowData: innerData,
          forecastData: undefined,
          component: Switch,
        };
      }

      if (column.id === 'ad_name') {
        return {
          id: column.id,
          data: innerData.name,
          rowData: innerData,
          component: AdName,
        };
      }

      if (column.id === 'insights') {
        return {
          id: column.id,
          data: innerData,
          forecastData: undefined,
          rowData: innerData,
          component: Insights,
        };
      }

      if (column.id === 'status') {
        return {
          id: column.id,
          data: innerData.status,
          forecastData: undefined,
          rowData: innerData,
          component: Status,
        };
      }

      if (column.id === 'budget') {
        return {
          id: column.id,
          data: innerData.daily_budget,
          forecastData: undefined,
          rowData: innerData,
          component: Budget,
        };
      }

      if (column.id === 'net_revenue') {
        return {
          id: column.id,
          data: innerData.financial_report.net_revenue,
          forecastData: innerData?.forecast_report?.net_revenue,
          rowData: innerData,
          component: NetRevenue,
        };
      }

      if (column.id === 'spend_amount') {
        return {
          id: column.id,
          data: innerData.marketing_report.spend,
          rowData: innerData,
          component: SpendAmount,
        };
      }

      if (column.id === 'cogs') {
        return {
          id: column.id,
          data: innerData.financial_report.cogs,
          forecastData: innerData?.forecast_report?.cogs,
          rowData: innerData,
          component: Cogs,
        };
      }

      if (column.id === 'profit') {
        return {
          id: column.id,
          data: innerData.financial_report.net_profit,
          forecastData: innerData?.forecast_report?.net_profit,
          rowData: innerData,
          component: Profit,
        };
      }

      if (column.id === 'roi') {
        return {
          id: column.id,
          data: innerData.financial_report.roi,
          forecastData: innerData?.forecast_report?.roi,
          rowData: innerData,
          component: Roi,
        };
      }

      if (column.id === 'roas') {
        return {
          id: column.id,
          data: innerData.marketing_report.roas,
          rowData: innerData,
          component: Roas,
        };
      }

      if (column.id === 'cpa') {
        return {
          id: column.id,
          data: innerData.marketing_report.cpa,
          rowData: innerData,
          component: Cpa,
        };
      }

      if (column.id === 'cpa_utm') {
        return {
          id: column.id,
          data: innerData.financial_report.cpa,
          forecastData: innerData?.forecast_report?.cpa,
          rowData: innerData,
          component: CpaUtm,
        };
      }

      if (column.id === 'credit_card') {
        return {
          id: column.id,
          data: innerData.financial_report.card,
          rowData: innerData,
          component: CreditCard,
        };
      }

      if (column.id === 'boleto') {
        return {
          id: column.id,
          data: innerData.financial_report.boleto,
          rowData: innerData,
          component: Boleto,
        };
      }

      if (column.id === 'boleto_conversion') {
        return {
          id: column.id,
          data: innerData.financial_report.boleto_conversion,
          rowData: innerData,
          component: BoletoConversion,
        };
      }

      if (column.id === 'pix') {
        return {
          id: column.id,
          data: innerData.financial_report.pix,
          rowData: innerData,
          component: Pix,
        };
      }

      if (column.id === 'pix_conversion') {
        return {
          id: column.id,
          data: innerData.financial_report.pix_conversion,
          rowData: innerData,
          component: PixConversion,
        };
      }

      if (column.id === 'prints') {
        return {
          id: column.id,
          data: innerData.marketing_report.impressions,
          rowData: innerData,
          component: Prints,
        };
      }

      if (column.id === 'ctr') {
        return {
          id: column.id,
          data: innerData.marketing_report.ctr,
          rowData: innerData,
          component: Ctr,
        };
      }

      if (column.id === 'cpc') {
        return {
          id: column.id,
          data: innerData.marketing_report.cpc,
          rowData: innerData,
          component: Cpc,
        };
      }

      if (column.id === 'cpm') {
        return {
          id: column.id,
          data: innerData.marketing_report.cpm,
          rowData: innerData,
          component: Cpm,
        };
      }

      return {
        id: column.id,
        data: innerData.financial_report.orders,
        rowData: innerData,
        component: Orders,
      };
    });

    return {
      alias_id: innerData.alias_id,
      data: [...newData],
    };
  });

  return mappedData;
};
