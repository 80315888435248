import React from 'react';

import { EAlertVariant } from '@domain/enums/components/EAlert';
import { useOnboardingTag } from '@helpers/hooks/pages/dashboard/productAnalytics/useOnboardingTag';
import { useConfig } from '@helpers/hooks/useConfig';

import InstantHelp from '@components/common/core/Utils/InstantHelp';
import FirstTag from './FirstTag';
import MyTags from './MyTags';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const NewTag: React.FC = () => {
  const { analytics, selectedStore } = useConfig();
  const { productTags, isLoadingProductTags, isValidatingProductTag } = useOnboardingTag();

  const [clickedArticleName, setClickedArticleName] = React.useState<string>('');

  const handleClickedArticleName = React.useCallback(
    articleName => setClickedArticleName(articleName),
    [],
  );

  React.useEffect(() => {
    const synchronizationTagArticleName = clickedArticleName === 'synchronization_tag';

    if (synchronizationTagArticleName) {
      analytics?.track(
        'Product Tags Onboarding Access Article About Tag',
        {},
        { context: { groupId: selectedStore?.alias_id } },
      );
    }
  }, [analytics, clickedArticleName, selectedStore]);

  const isLoading = isLoadingProductTags || isValidatingProductTag;

  if (isLoading) return <SkeletonLoading />;

  return (
    <S.ContentWrapper>
      <S.Alert variant={EAlertVariant.WARNING}>
        A função de sincronizar as tags com Google Ads está em manutenção por tempo indeterminado.
        Enquanto isso, você poderá ativar suas campanhas manualmente.
      </S.Alert>

      <S.Content>{!productTags.length ? <FirstTag /> : <MyTags />}</S.Content>

      <InstantHelp
        name="synchronization_tag"
        title="Tag de sincronização"
        articleLink=""
        marginTop={24}
        handleClickedArticle={handleClickedArticleName}
      />
    </S.ContentWrapper>
  );
};

export default NewTag;
