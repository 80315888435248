import { EEcommerceProvider } from '@domain/enums/common/integrations/EEcommerceProvider'; // TODO: ecommerce
import { IStoreIntegrations } from '@domain/interfaces/stores/IStores';

export const getEcommerceProviderIntegrationStatus = (
  storeIntegrations: IStoreIntegrations,
  identifier: EEcommerceProvider,
): boolean => {
  const foundStoreFrontIntegration = storeIntegrations.store_front?.find(
    integration => integration.name === identifier,
  );

  return Boolean(foundStoreFrontIntegration?.is_active);
};
