import { ICustomValue } from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';
import { ICustomValueToReturn } from '@domain/interfaces/dashboard/FinancialDetails/ICustomValue';
import { numberFormatter } from '@helpers/masks';
import { format, differenceInDays, subDays } from 'date-fns';

const MONTHS = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];

export const getPeriodTableDatesNames = (
  startDate: Date,
  endDate: Date,
  today: Date,
): Array<string> => {
  const formattedStartDate = format(startDate, 'dd/MM/yyyy');
  const formattedEndDate = format(endDate, 'dd/MM/yyyy');
  const formattedToday = format(today, 'dd/MM/yyyy');

  if (formattedStartDate === formattedEndDate && formattedStartDate === formattedToday) {
    return ['Hoje', 'Ontem'];
  }

  if (formattedStartDate === formattedEndDate) {
    const previousDate = subDays(startDate, 1);

    const parsedDateToCompare = `${startDate.getDate()} ${MONTHS[startDate.getMonth()]}`;
    const parsedComparedDate = `${previousDate.getDate()} ${MONTHS[previousDate.getMonth()]}`;

    return [parsedDateToCompare, parsedComparedDate];
  }

  const differenceInDaysFromStartDateToEndDate = differenceInDays(endDate, startDate);

  const comparedEndDate = subDays(startDate, 1);
  const comparedStartDate = subDays(comparedEndDate, differenceInDaysFromStartDateToEndDate);

  const parsedDateToCompare = `${startDate.getDate()} ${
    MONTHS[startDate.getMonth()]
  } - ${endDate.getDate()} ${MONTHS[endDate.getMonth()]}`;
  const parsedComparedDate = `${comparedStartDate.getDate()} ${
    MONTHS[comparedStartDate.getMonth()]
  } - ${comparedEndDate.getDate()} ${MONTHS[comparedEndDate.getMonth()]}`;

  return [parsedDateToCompare, parsedComparedDate];
};

export const getPeriodTableDates = (startDate: Date, endDate: Date): Array<Date> => {
  const differenceInDaysFromStartDateToEndDate = differenceInDays(endDate, startDate);

  const comparedEndDate = subDays(startDate, 1);
  const comparedStartDate = subDays(comparedEndDate, differenceInDaysFromStartDateToEndDate);

  return [comparedStartDate, comparedEndDate];
};

export const getAllCustomValues = (
  customValues: Array<ICustomValue>,
  comparedCustomValues: Array<ICustomValue>,
): Array<ICustomValueToReturn> => {
  const customValuesToReturn: Array<ICustomValueToReturn> = [];

  customValues.forEach(customValue =>
    customValuesToReturn.push({
      alias_id: customValue.alias_id,
      hex_color: customValue.hex_color,
      name: customValue.name,
    }),
  );

  comparedCustomValues.forEach(comparedCustomValue => {
    const foundCustomValueToReturn = customValuesToReturn.find(
      customValueToReturn => customValueToReturn.alias_id === comparedCustomValue.alias_id,
    );

    if (!foundCustomValueToReturn) {
      customValuesToReturn.push({
        alias_id: comparedCustomValue.alias_id,
        hex_color: comparedCustomValue.hex_color,
        name: comparedCustomValue.name,
      });
    }
  });

  return customValuesToReturn;
};

export const getCustomValueAmount = (id: string, customValues: Array<ICustomValue>): string => {
  const foundCustomValue = customValues.find(customValue => customValue.alias_id === id);

  if (foundCustomValue) return numberFormatter(foundCustomValue.amount, 2);

  return '0';
};

export const getCustomValuePercentage = (id: string, customValues: Array<ICustomValue>): string => {
  const foundCustomValue = customValues.find(customValue => customValue.alias_id === id);

  if (foundCustomValue) return numberFormatter(foundCustomValue.percentage, 1);

  return '0';
};
