import React from 'react';

import { Monetizze as MonetizzeIcon, Copy } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { EInfoProductProvider } from '@domain/enums/common/integrations/EInfoProductProvider';
import { IParams } from '@domain/interfaces/IParams';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useToast } from '@helpers/hooks/useToast';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { monetizzeSchema } from '@helpers/validators/dashboard/integrationCenter/infoProduct/monetizze';

import monetizzeService from '@services/pages/dashboard/integrationCenter/infoProduct/platforms/monetizze';

import ActiveIntegrationFooter from '@components/Dashboard/IntegrationCenter/Sidemodal/ActiveIntegrationFooter';
import Text from '@components/common/core/DataDisplay/Text';
import MonetizzeConfig from './MonetizzeConfig';
import ActiveIntegration from './ActiveIntegration';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Monetizze: React.FC = () => {
  const theme = useTheme();

  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { storeIntegrations, mutateStore } = useStoreConfig();

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    watch,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(monetizzeSchema),
  });

  const [webhookURL, setWebhookURL] = React.useState<string>('');
  const [isURLCopied, setIsURLCopied] = React.useState<boolean>(false);
  const [credentialAliasId, setCredentialAliasId] = React.useState<string>('');
  const [isGettingCredential, setIsGettingCredential] = React.useState<boolean>(false);
  const [hasMonetizzeCredential, setHasMonetizzeCredential] = React.useState<boolean>(false);
  const [isGettingWebhookURL, setIsGettingWebhookURL] = React.useState<boolean>(false);
  const [isCreatingCredential, setIsCreatingCredential] = React.useState<boolean>(false);

  const [webhookTokenValue] = getValues(['webhook_token']);
  const [consumerKeyValue] = getValues(['consumer_key']);

  watch();

  const hasMonetizzeIntegration = Boolean(
    storeIntegrations?.store_front.find(
      integration => integration.name === EInfoProductProvider.MONETIZZE && integration.is_active,
    ),
  );

  const webhookTokenInputRegister = register('webhook_token');
  const consumerKeyInputRegister = register('consumer_key');

  const handleCopyURL = React.useCallback(() => {
    navigator.clipboard.writeText(webhookURL);

    setIsURLCopied(true);

    toast.success('URL copiada com sucesso.');
  }, [toast, webhookURL]);

  const getMonetizzeCredential = React.useCallback(async () => {
    setIsGettingCredential(true);

    try {
      const { data } = await monetizzeService.getCredential({ storeAliasId });

      if (data?.monetizze_credential.is_active) {
        setHasMonetizzeCredential(true);
      }

      setCredentialAliasId(data?.monetizze_credential.alias_id);

      setIsGettingCredential(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsGettingCredential(false);
    } finally {
      setIsGettingCredential(false);
    }
  }, [toast, storeAliasId]);

  const generateWebhookURL = React.useCallback(async () => {
    setIsGettingWebhookURL(true);

    try {
      const { data } = await monetizzeService.generateWebhookURL({ storeAliasId });

      setWebhookURL(data?.webhook_url);
      setIsGettingWebhookURL(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [storeAliasId, toast]);

  const onSubmit = React.useCallback(
    async formData => {
      setIsCreatingCredential(true);

      try {
        await monetizzeService.createCredential({ storeAliasId, data: { ...formData } });

        toast.success('Credencial criada com sucesso.');

        mutateStore();

        setIsCreatingCredential(false);
      } catch (error: any) {
        toast.error(getTranslatedIntegrationsError(error));
        setIsCreatingCredential(false);
      }
    },
    [mutateStore, toast, storeAliasId],
  );

  React.useEffect(() => {
    if (hasMonetizzeIntegration) {
      getMonetizzeCredential();
    } else {
      generateWebhookURL();
    }
  }, [getMonetizzeCredential, hasMonetizzeIntegration, generateWebhookURL]);

  const isWebhookTokenError = Boolean(errors.webhook_token);
  const isConsumerKeyError = Boolean(errors.consumer_key);

  const isActiveIntegrationFooterDisabled =
    isCreatingCredential || !webhookTokenValue || !consumerKeyValue;

  if (isGettingCredential || isGettingWebhookURL) {
    return <SkeletonLoading />;
  }

  if (hasMonetizzeIntegration && !hasMonetizzeCredential) {
    return <ActiveIntegration credentialAliasId={credentialAliasId} />;
  }

  if (hasMonetizzeCredential) {
    return <MonetizzeConfig credentialAliasId={credentialAliasId} />;
  }

  return (
    <S.ContentWrapper>
      <S.HeadingWrapper>
        <MonetizzeIcon size={52} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com Monetizze
        </S.Heading>
      </S.HeadingWrapper>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>

        <S.FunctionalityDescriptionText>
          A integração com sua plataforma de info-produto serve para puxarmos seus dados de
          plataforma aumentando a exatidão dos seus dados.
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.TutorialWrapper>
          <S.TutotialTitle weight={ETextWeight.BOLD}>Tutorial de integração</S.TutotialTitle>

          <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
            1 - Copie a URL no campo abaixo.
          </S.TutotialStep>

          <S.WebhookURLWrapper onClick={handleCopyURL} type="button">
            <S.WebhookURL weight={ETextWeight.MEDIUM}>{webhookURL}</S.WebhookURL>
            <S.IconWrapper>
              <Copy size={18} color={theme.colors.gray[1]} outline={!isURLCopied} />
            </S.IconWrapper>
          </S.WebhookURLWrapper>

          <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
            2 -{' '}
            <S.Link href="https://app.monetizze.com.br/ferramentas/postback" target="_blank">
              Clique aqui e vá para a página de postback da Monetizze.
            </S.Link>
          </S.TutotialStep>

          <S.StepsWrapper>
            <S.StepWrapper>
              <S.StepBullet />
              <S.StepText size={ETextSize.B5}>
                No campo &quot;Produto&quot;, selecione &quot;Todos os produtos&quot;.
              </S.StepText>
            </S.StepWrapper>

            <S.StepWrapper>
              <S.StepBullet />
              <S.StepText size={ETextSize.B5}>
                No campo &quot;Tipo&quot;, selecione &quot;Postback (server to server)&quot;.
              </S.StepText>
            </S.StepWrapper>

            <S.StepWrapper>
              <S.StepBullet />
              <S.StepText size={ETextSize.B5}>
                No campo &quot;URL&quot;, cole a URL copiada no campo acima.
              </S.StepText>
            </S.StepWrapper>

            <S.StepWrapper>
              <S.StepBullet />
              <S.StepText size={ETextSize.B5}>
                No campo &quot;Eventos&quot;, selecione todas as opções.
              </S.StepText>
            </S.StepWrapper>

            <S.StepWrapper>
              <S.StepBullet />
              <S.StepText size={ETextSize.B5}>
                Após preencher os campos, clique no botão &quot;Salvar&quot;.
              </S.StepText>
            </S.StepWrapper>

            <S.StepWrapper>
              <S.StepBullet />
              <S.StepText size={ETextSize.B5}>
                Para finalizar, no canto inferior esquerdo, copie a &quot;Chave única&quot;.
              </S.StepText>
            </S.StepWrapper>

            <S.StepWrapper>
              <S.StepBullet />
              <S.StepText size={ETextSize.B5}>Cole no campo abaixo.</S.StepText>
            </S.StepWrapper>
          </S.StepsWrapper>

          <S.InputGroup>
            <S.Label>Chave única</S.Label>

            <S.Input
              {...webhookTokenInputRegister}
              type="text"
              value={webhookTokenValue}
              placeholder="Chave única da Monetizze"
              isError={isWebhookTokenError}
            />
            {isWebhookTokenError && <Text isErrorFeedback>{errors.webhook_token.message}</Text>}
          </S.InputGroup>

          <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
            3 -{' '}
            <S.Link href="https://app.monetizze.com.br/ferramentas/api" target="_blank">
              Clique aqui e vá para a página de API da Monetizze.
            </S.Link>
          </S.TutotialStep>

          <S.StepsWrapper>
            <S.StepWrapper>
              <S.StepBullet />
              <S.StepText size={ETextSize.B5}>
                No campo &quot;Descrição&quot;, digite o nome &quot;Profitfy.me&quot;.
              </S.StepText>
            </S.StepWrapper>

            <S.StepWrapper>
              <S.StepBullet />
              <S.StepText size={ETextSize.B5}>Clique no botão &quot;Salvar&quot;.</S.StepText>
            </S.StepWrapper>

            <S.StepWrapper>
              <S.StepBullet />
              <S.StepText size={ETextSize.B5}>
                Após salvar, copie o valor no campo &quot;X_CONSUMER_KEY&quot;.
              </S.StepText>
            </S.StepWrapper>

            <S.StepWrapper>
              <S.StepBullet />
              <S.StepText size={ETextSize.B5}>Cole no campo abaixo.</S.StepText>
            </S.StepWrapper>
          </S.StepsWrapper>

          <S.InputGroup>
            <S.Label>Chave do Cliente</S.Label>

            <S.Input
              {...consumerKeyInputRegister}
              type="text"
              value={consumerKeyValue}
              placeholder="Chave do Cliente Monetizze"
              isError={isConsumerKeyError}
            />
            {isConsumerKeyError && <Text isErrorFeedback>{errors.consumer_key.message}</Text>}
          </S.InputGroup>
        </S.TutorialWrapper>

        <ActiveIntegrationFooter
          isIntegrating={isCreatingCredential}
          disabled={isActiveIntegrationFooterDisabled}
        />
      </S.Form>
    </S.ContentWrapper>
  );
};

export default Monetizze;
