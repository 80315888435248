import React from 'react';

import { ISwitchPixNodeModal } from '@domain/interfaces/dashboard/Automations/Canvas/Node/SwitchNode/ISwitchPixModal';
import {
  EPixType,
  EPixProductOptions,
  EPixTotalValueOptions,
  EPixOrderStatusOptions,
  EPixOrderStatusPicker,
} from '@domain/enums/dashboard/automations/node/switchNode/EPix';

import RuleSelect from './RuleSelect';

import * as S from './styles';

const AbandonedCartModal: React.FC<ISwitchPixNodeModal> = ({
  rules,
  handleChangeRule,
  handleChangeRules,
}) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);

  const handleChangeType = React.useCallback(
    e => {
      const choosen = e.target.value as EPixType;

      if (choosen === EPixType.PRODUCT_NAME) {
        handleChangeRules({
          type: choosen,
          options: EPixProductOptions.CONTAINS,
          input: '',
        });
      } else if (choosen === EPixType.PIX_TOTAL_VALUE) {
        handleChangeRules({
          type: choosen,
          options: EPixTotalValueOptions.SMALLER,
          input: '',
        });
      } else if (choosen === EPixType.ORDER_STATUS) {
        handleChangeRules({
          type: choosen,
          options: EPixOrderStatusOptions.EQUAL,
          input: EPixOrderStatusPicker.PAID,
        });
      }
    },
    [handleChangeRules],
  );

  let ruleWithProblem: null | number = null;

  const isFirstRuleEmpty = rules[0].input === '';
  const isSecondRuleEmpty = rules[1].input === '';
  const isThirdRuleEmpty = rules[2].input === '';

  if (isFirstRuleEmpty && !isSecondRuleEmpty && !isThirdRuleEmpty) {
    ruleWithProblem = 0;
  }

  if (!isFirstRuleEmpty && isSecondRuleEmpty && !isThirdRuleEmpty) {
    ruleWithProblem = 1;
  }

  if (isFirstRuleEmpty && isSecondRuleEmpty && !isThirdRuleEmpty) {
    ruleWithProblem = 0;
  }

  return (
    <>
      <S.AddedOptionsWrapper ref={scrollRef}>
        <S.Container>
          <S.Description>Selecione o dado para a condição</S.Description>
          <S.FullWidthSelect name="type" defaultValue={rules[0].type} onChange={handleChangeType}>
            <S.Option value={EPixType.PRODUCT_NAME}>Nome do Produto</S.Option>
            <S.Option value={EPixType.PIX_TOTAL_VALUE}>Valor Total</S.Option>
            <S.Option value={EPixType.ORDER_STATUS}>Status do Pedido</S.Option>
          </S.FullWidthSelect>
        </S.Container>

        {rules.length > 0 &&
          rules.map((value, index) => (
            <RuleSelect
              key={value.id}
              isDynamic={false}
              value={value}
              index={index}
              onUpdate={handleChangeRule}
              ruleWithProblem={ruleWithProblem}
            />
          ))}
      </S.AddedOptionsWrapper>
    </>
  );
};

export default AbandonedCartModal;
