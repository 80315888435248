import styled, { css } from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import { IDotColorProps, ILabelTextProps } from '@domain/interfaces/components/IChartTooltip';

export const LabelText = styled(TextBase)<ILabelTextProps>`
  ${({ isNetAmountPositive, theme }) =>
    isNetAmountPositive &&
    css`
      color: ${theme.colors.success.default};
    `}

  ${({ isNetAmountNegative, theme }) =>
    isNetAmountNegative &&
    css`
      color: ${theme.colors.danger.default};
    `}
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
`;

export const DotColor = styled.div<IDotColorProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin-right: 8px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TooltipTitle = styled(TextBase)`
  margin-bottom: 4px;
`;

export const TooltipCard = styled.div`
  padding: 8px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.background[2]};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
`;
