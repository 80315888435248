import React from 'react';

import { useFinancialData } from '@helpers/hooks/pages/dashboard/financialData/useFinancialData';
import { useToast } from '@helpers/hooks/useToast';

import { getCnpjNumber, getCpfNumber, parseToRawPhone } from '@helpers/masks';

import addressService from '@services/common/address/address';
import legalPersonService from '@services/common/legalPerson/legalPerson';
import naturalPersonService from '@services/common/naturalPerson/naturalPerson';

import Form from '@components/common/core/Inputs/Form';
import SaveButton from '@components/common/core/Inputs/SaveButton';
import PageError from '@components/common/core/Utils/PageError';
import NewLegalPersonSideModal from './NewLegalPersonSideModal';
import FinancialData from './FinancialData';
import Address from './Address';

import * as S from './styles';

const FinancialDataForm: React.FC = () => {
  const { toast } = useToast();
  const {
    handleSubmit,
    legalPerson,
    isLoadingAddress,
    isLoadingLegalPerson,
    isLoadingNaturalPerson,
    addressError,
    legalPersonError,
    naturalPersonError,
    isValidatingAddress,
    isValidatingLegalPerson,
    isValidatingNaturalPerson,
    mutateAddress,
    mutateLegalPerson,
    mutateNaturalPerson,
  } = useFinancialData();

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [isNewLegalPersonSideModalOpen, setIsNewLegalPersonSideModalOpen] = React.useState<boolean>(
    false,
  );

  const isLoading = isLoadingAddress || isLoadingLegalPerson || isLoadingNaturalPerson;

  const handleNewLegalPersonSideModalOpen = React.useCallback(
    () => setIsNewLegalPersonSideModalOpen(!isNewLegalPersonSideModalOpen),
    [isNewLegalPersonSideModalOpen],
  );

  const legalPersonSubmit = React.useCallback(
    async data => {
      setIsSubmittingForm(true);

      try {
        const updateLegalPerson = legalPersonService.updateLegalPerson({
          data: {
            company_name: data?.company_name,
            cnpj: getCnpjNumber(data?.cnpj),
            phone_number: parseToRawPhone(data?.phone_number),
          },
        });

        const updateAddress = addressService.updateAddress({
          data: {
            city: data?.city,
            complementary: data?.complement || null,
            country: 'Brazil',
            country_code: 'BR',
            neighborhood: data?.neighborhood,
            state: data?.state,
            state_code: 'SP',
            street: data?.street,
            street_number: data?.number,
            zip_code: data?.zip_code,
          },
        });

        await Promise.all([updateLegalPerson, updateAddress]);

        toast.success('Informações atualizadas com sucesso!');
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsSubmittingForm(false);
      }
    },
    [toast],
  );

  const naturalPersonSubmit = React.useCallback(
    async data => {
      setIsSubmittingForm(true);

      try {
        const updateNaturalPerson = naturalPersonService.updateNaturalPerson({
          data: {
            cpf: getCpfNumber(data?.cpf),
            phone_number: parseToRawPhone(data?.phone_number),
          },
        });

        const updateAddress = addressService.updateAddress({
          data: {
            city: data?.city,
            complementary: data?.complement || null,
            country: 'Brazil',
            country_code: 'BR',
            neighborhood: data?.neighborhood,
            state: data?.state,
            state_code: 'SP',
            street: data?.street,
            street_number: data?.number,
            zip_code: data?.zip_code,
          },
        });

        await Promise.all([updateNaturalPerson, updateAddress]);

        toast.success('Informações atualizadas com sucesso!');
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsSubmittingForm(false);
      }
    },
    [toast],
  );

  const onSubmit = React.useCallback(
    data => {
      if (legalPerson) {
        legalPersonSubmit(data);
      } else {
        naturalPersonSubmit(data);
      }
    },
    [legalPerson, legalPersonSubmit, naturalPersonSubmit],
  );

  const mutate = React.useCallback(() => {
    mutateAddress();
    mutateLegalPerson();
    mutateNaturalPerson();
  }, [mutateAddress, mutateLegalPerson, mutateNaturalPerson]);

  if (
    (addressError || legalPersonError || naturalPersonError) &&
    !isValidatingAddress &&
    !isValidatingLegalPerson &&
    !isValidatingNaturalPerson
  ) {
    return <PageError mutate={mutate} />;
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.FinancialDataFormWrapper>
          <FinancialData handleNewLegalPersonSideModalOpen={handleNewLegalPersonSideModalOpen} />
          <Address />
        </S.FinancialDataFormWrapper>

        {!isLoading && (
          <SaveButton type="submit" isLoading={isSubmittingForm} disabled={isSubmittingForm}>
            Salvar alterações
          </SaveButton>
        )}
      </Form>

      <NewLegalPersonSideModal
        isOpen={isNewLegalPersonSideModalOpen}
        toggle={handleNewLegalPersonSideModalOpen}
      />
    </>
  );
};

export default FinancialDataForm;
