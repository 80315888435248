import React from 'react';

import MainSection from './MainSection';
import SecondarySection from './SecondarySection';

import * as S from './styles';

const PejotaPlus: React.FC = () => {
  return (
    <S.Container>
      <MainSection />
      <SecondarySection />
    </S.Container>
  );
};

export default PejotaPlus;
