import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';

export const CustomPopover = styled(CustomPopoverBase)``;

export const EditBudgetWrapper = styled.div`
  & > button {
    cursor: pointer;
  }
`;

export const BudgetFrequency = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const BudgetValue = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[1]};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 4px;
  height: 58px;
  padding: 10px 12px;
  box-sizing: border-box;
`;
