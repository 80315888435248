import { EAdSenseProvider } from '@domain/enums/common/EAdSense';

export const getProviderName = (provider: string): string | undefined =>
  ({
    facebook_report: 'Facebook',
    taboola_report: 'Taboola',
    google_report: 'Google',
  }[provider]);

export const getAdSenseProvider = (provider: string): EAdSenseProvider | undefined =>
  ({
    facebook_report: EAdSenseProvider.FACEBOOK,
    taboola_report: EAdSenseProvider.TABOOLA,
    google_report: EAdSenseProvider.GOOGLE,
  }[provider]);

export const getMarketingReportName = (provider: EAdSenseProvider): string | undefined =>
  ({
    FACEBOOK: 'facebook_report',
    TABOOLA: 'taboola_report',
    GOOGLE: 'google_report',
    TIKTOK: 'tiktok_report',
  }[provider]);

export const getAdSenseProviderName = (provider: EAdSenseProvider): string | undefined =>
  ({
    FACEBOOK: 'Facebook',
    TABOOLA: 'Taboola',
    GOOGLE: 'Google',
    TIKTOK: 'Tiktok',
  }[provider]);
