import React from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { X as CloseIcon } from 'phosphor-react';
import { IParams } from '@domain/interfaces/IParams';

import { IAnnouncementProps } from '@domain/interfaces/IAnnouncement';
import { ETextSize } from '@domain/enums/components/EText';
import { useAnnouncement } from '@helpers/hooks/pages/dashboard/admin/useAnnouncement';

import { useTheme } from 'styled-components';

import * as S from './styles';

const Announcement: React.FC<IAnnouncementProps> = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const history = useHistory();

  const { storeAliasId } = useParams<IParams>();
  const { announcement } = useAnnouncement();

  const [isImageLoaded, setIsImageLoaded] = React.useState<boolean>(false);

  const onSeeNewsButtonClick = React.useCallback(() => {
    const hasInternalURL = Boolean(announcement.internal_url);

    if (hasInternalURL) {
      history.push(`/${storeAliasId}/dashboard/${announcement.internal_url}`);
    } else {
      window.open(announcement.external_url, '_blank');
    }
  }, [announcement, history, storeAliasId]);

  return (
    <>
      {isOpen && (
        <S.Wrapper>
          <S.Image
            src={announcement.image_url}
            alt="Profitfy announcement image"
            onLoad={() => setIsImageLoaded(true)}
            onError={onClose}
          />

          <S.SubTitle>Novidade</S.SubTitle>
          <S.Title>{announcement.title}</S.Title>

          <S.Description size={ETextSize.B5}>{announcement.description}</S.Description>

          <S.ButtonsWrapper>
            <S.SeeNewsButtons onClick={onSeeNewsButtonClick}>Ver novidade</S.SeeNewsButtons>
            <S.CancelButton onClick={onClose}>Fechar</S.CancelButton>
          </S.ButtonsWrapper>

          {isImageLoaded && (
            <S.CloseButton onClick={onClose}>
              <CloseIcon size={24} color={theme.colors.gray[2]} weight="bold" />
            </S.CloseButton>
          )}
        </S.Wrapper>
      )}
    </>
  );
};

export default Announcement;
