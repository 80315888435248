import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';

import {
  IListBoletosProps,
  IListBoletosResponse,
} from '@domain/interfaces/dashboard/Boleto/IBoleto';

export class BoletoService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listBoletos({
    storeAliasId,
    page,
    startDate,
    endDate,
  }: IListBoletosProps): IListBoletosResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/orders/boleto?limit=20&page=${page}&start_date=${startDate}&end_date=${endDate}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      orders: data?.orders,
      metric: data?.metric,
      totalPages: data?.total_pages,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }
}

const boletoService = new BoletoService(API_DOMAIN);

export default boletoService;
