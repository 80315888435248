import React from 'react';
import QRCode from 'react-qr-code';
import { X } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { ETextWeight } from '@domain/enums/components/EText';
import { IDownloadAppMobileModal } from '@domain/interfaces/IDownloadAppMobileModal';
import { IParams } from '@domain/interfaces/IParams';

import { useConfig } from '@helpers/hooks/useConfig';

import Logo from '@assets/img/brand/profitfy-logo.svg';

import * as S from './styles';

const DownloadAppMobileModal: React.FC<IDownloadAppMobileModal> = ({ isOpen, toggle }) => {
  const theme = useTheme();
  const { analytics, user } = useConfig();
  const { storeAliasId } = useParams<IParams>();

  React.useEffect(() => {
    if (isOpen) {
      analytics?.track(
        'Download the App Button Clicked',
        { email: user.email },
        { context: { groupId: storeAliasId } },
      );
    }
  }, [isOpen, user, analytics, storeAliasId]);

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.CloseIcon onClick={toggle}>
        <X size={24} color={theme.colors.gray[1]} />
      </S.CloseIcon>

      <S.Logo src={Logo} alt="profitfy logo" />

      <S.Heading type={EHeadingSize.H3}>Escaneie o QR Code</S.Heading>

      <S.Description weight={ETextWeight.MEDIUM}>
        Escaneie o QR Code abaixo para poder baixar <br /> o aplicativo da Profitfy
      </S.Description>

      <S.QRCodeWrapper>
        <QRCode
          value="https://app.profitfy.me/app"
          size={106}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          viewBox="0 0 106 106"
        />
      </S.QRCodeWrapper>
    </S.Modal>
  );
};

export default DownloadAppMobileModal;
