import React from 'react';

import { Kiwify as Icon } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { IKiwifyConfigProps } from '@domain/interfaces/dashboard/IntegrationCenter/InfoProduct/IKiwify';

import CredentialOption from './CredentialOption';

import * as S from './styles';

const KiwifyConfig: React.FC<IKiwifyConfigProps> = ({ credentialAliasId }) => {
  const theme = useTheme();

  return (
    <S.ContentWrapper>
      <S.Header>
        <Icon size={52} color={theme.colors.gray[2]} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.REGULAR}>
          Integração com Kiwify
        </S.Heading>
      </S.Header>

      <CredentialOption credentialAliasId={credentialAliasId} />
    </S.ContentWrapper>
  );
};

export default KiwifyConfig;
