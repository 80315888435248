import React from 'react';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.Wrapper>
      <S.GroupsTable>
        <Skeleton
          width="100%"
          height={270}
          style={{
            borderRadius: 5,
            lineHeight: 'unset',
          }}
        />
      </S.GroupsTable>

      <S.MembersTable>
        <Skeleton
          width="100%"
          height={181}
          style={{
            borderRadius: 5,
            lineHeight: 'unset',
          }}
        />
      </S.MembersTable>

      <S.PendingInvitationsTable>
        <Skeleton
          width="100%"
          height={169}
          style={{
            borderRadius: 5,
            lineHeight: 'unset',
          }}
        />
      </S.PendingInvitationsTable>
    </S.Wrapper>
  );
};

export default SkeletonLoading;
