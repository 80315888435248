/* eslint-disable no-restricted-syntax */
import React from 'react';
import { useParams } from 'react-router-dom';

import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';
import { IFilterQueryStringData } from '@domain/interfaces/components/IFilter';
import { ITaboolaCredentialsProvider } from '@domain/interfaces/integrations/marketing/taboola/ITaboolaCredentials';

import { useToast } from '@helpers/hooks/useToast';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import { ADS_PROVIDERS } from '@constants/common/integrations';

import taboolaCredentialService from '@services/pages/dashboard/adSense/taboola/taboolaCredential';
import adSenseCredentialService from '@services/pages/dashboard/adSense/adSenseCredential';

const TaboolaCredentialsContext = React.createContext<ITaboolaCredentialsProvider | null>(null);

export const TaboolaCredentialsProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { addSynchronization } = useSynchronization();

  const [isLoggingIn, setIsLoggingIn] = React.useState<boolean>(false);
  const [isLoggingOut, setIsLoggingOut] = React.useState<boolean>(false);
  const [adAccountSearchName, setAdAccountSearchName] = React.useState<string>('');
  const [isNewCredentialSideModalOpen, setIsNewCredentialSideModalOpen] = React.useState<boolean>(
    false,
  );

  const [adSenseCredentialsFilter] = React.useState<IFilterQueryStringData>({
    provider: EAdSenseProvider.TABOOLA,
  });

  const {
    adSenseCredentials,
    isLoading: isLoadingAdSenseCredentials,
    isValidating: isValidatingAdSenseCredentials,
    mutate: mutateAdSenseCredentials,
    error: adSenseCredentialsError,
  } = adSenseCredentialService.listAdSenseCredentials({
    storeAliasId,
    page: 1,
    filter: adSenseCredentialsFilter,
  });

  const handleNewCredentialSideModalOpen = React.useCallback(
    () => setIsNewCredentialSideModalOpen(!isNewCredentialSideModalOpen),
    [isNewCredentialSideModalOpen],
  );

  const mutate = React.useCallback(async () => {
    const promises = [];

    if (adSenseCredentialsError) promises.push(mutateAdSenseCredentials());

    await Promise.all(promises);
  }, [adSenseCredentialsError, mutateAdSenseCredentials]);

  const handleAdAccountSearchName = React.useCallback(newSearchName => {
    setAdAccountSearchName(newSearchName);
  }, []);

  const syncAllAdSenseAccounts = React.useCallback(async () => {
    const foundTaboolaIntegration = ADS_PROVIDERS.find(
      integration => integration.identifier === EAdSenseProvider.TABOOLA,
    ) as typeof ADS_PROVIDERS[number];

    for await (const adSenseCredential of adSenseCredentials) {
      const { generateSyncRequest } = foundTaboolaIntegration;

      const aliases = {
        adSenseCredentialAliasId: adSenseCredential.alias_id,
      };

      addSynchronization({
        name: 'Taboola',
        type: ESynchronizationType.AD_SENSE_ACCOUNTS,
        label: 'Perfil',
        dependencies: [ESynchronizationType.AD_SENSE_ACCOUNTS],
        storeAliasId,
        externalId: adSenseCredential.alias_id,
        config: {
          isOnboardingSynchronization: false,
          showNotification: true,
        },
        request: generateSyncRequest(
          ESynchronizationType.AD_SENSE_ACCOUNTS,
          storeAliasId,
          aliases,
          undefined,
        ),
      });

      await new Promise(resolve => setTimeout(resolve, 500));
    }
  }, [adSenseCredentials, addSynchronization, storeAliasId]);

  const handleLogin = React.useCallback(
    async credential => {
      setIsLoggingIn(true);

      try {
        const {
          data: taboolaCredentialData,
        } = await taboolaCredentialService.createTaboolaCredential({
          storeAliasId,
          data: {
            client_id: credential.client_id,
            client_secret: credential.client_secret,
          },
        });

        toast.success('Conta conectada com sucesso!');

        setIsLoggingIn(false);

        const [adSenseCredentialsResponse] = await Promise.all([mutateAdSenseCredentials()]);

        const adSenseCredentialToSync = adSenseCredentialsResponse.data.ad_sense_credentials;

        const foundAdSenseCredential = adSenseCredentialToSync.find(
          (adSenseCredential: any) =>
            adSenseCredential.id ===
            taboolaCredentialData?.taboola_credential.ad_sense_credential_id,
        );

        if (foundAdSenseCredential) {
          const foundTaboolaIntegration = ADS_PROVIDERS.find(
            integration => integration.identifier === EAdSenseProvider.TABOOLA,
          ) as typeof ADS_PROVIDERS[number];

          const { generateSyncRequest } = foundTaboolaIntegration;

          const aliases = {
            adSenseCredentialAliasId: foundAdSenseCredential.alias_id,
          };

          addSynchronization({
            name: 'Taboola',
            type: ESynchronizationType.AD_SENSE_ACCOUNTS,
            label: 'Perfil',
            dependencies: [ESynchronizationType.AD_SENSE_ACCOUNTS],
            storeAliasId,
            externalId: foundAdSenseCredential.alias_id,
            config: {
              isOnboardingSynchronization: false,
              showNotification: true,
            },
            request: generateSyncRequest(
              ESynchronizationType.AD_SENSE_ACCOUNTS,
              storeAliasId,
              aliases,
              undefined,
            ),
            callback: mutateAdSenseCredentials,
          });
        }
      } catch (error: any) {
        setIsLoggingIn(false);

        if (error?.response?.data?.integration_message === 'Request failed with status code 401') {
          toast.error('Credencial inválida, tente novamente.');
        } else {
          toast.error(error?.response?.data?.message);
        }
      }
    },
    [mutateAdSenseCredentials, storeAliasId, toast, addSynchronization],
  );

  const handleLogout = React.useCallback(
    async adSenseCredentialAliasId => {
      setIsLoggingOut(true);

      try {
        await adSenseCredentialService.deleteAdSenseCredential({
          storeAliasId,
          adSenseCredentialAliasId,
        });

        toast.success('Perfil desconectado com sucesso!');

        await mutateAdSenseCredentials();

        setIsLoggingOut(false);
      } catch (error: any) {
        setIsLoggingOut(false);
      }
    },
    [storeAliasId, toast, mutateAdSenseCredentials],
  );

  const isLoadingTaboolaCredentialsData = isLoadingAdSenseCredentials;
  const isValidatingTaboolaCredentialsData = isValidatingAdSenseCredentials;
  const isTaboolaCredentialsDataError = Boolean(adSenseCredentialsError);

  return (
    <TaboolaCredentialsContext.Provider
      value={{
        adSenseCredentials,
        isLoadingTaboolaCredentialsData,
        isValidatingTaboolaCredentialsData,
        isTaboolaCredentialsDataError,
        mutate,
        handleLogin,
        handleLogout,
        isLoggingIn,
        isLoggingOut,
        adAccountSearchName,
        handleAdAccountSearchName,
        syncAllAdSenseAccounts,
        handleNewCredentialSideModalOpen,
        isNewCredentialSideModalOpen,
      }}
    >
      {children}
    </TaboolaCredentialsContext.Provider>
  );
};

export const useTaboolaCredentials = (): ITaboolaCredentialsProvider => {
  const context = React.useContext(TaboolaCredentialsContext);

  if (!context) {
    throw new Error('useTaboolaCredentials must be used within TaboolaCredentialsProvider');
  }

  return context;
};
