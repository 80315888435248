import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const UnderstoodButton = styled.button`
  margin-left: auto;
  padding: unset;
  border: unset;
  background: unset;
  color: ${({ theme }) => theme.colors.green.default};
  transition: all 0.3s;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 400;
  display: block;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Text = styled(TextBase)`
  font-size: 0.875rem;
  display: inline-block;
`;

export const Title = styled(TextBase)`
  font-size: 0.875rem;
  font-weight: 700;
  display: inline-block;
`;

export const Wrapper = styled.div`
  max-width: 312px;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
`;
