import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 382px;
  gap: 12px;

  @media only screen and (max-width: 1280px) {
    min-width: unset;
  }
`;

export const Group = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  flex-direction: column;
`;

export const CostMarkupWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
`;

export const ProductCostCard = styled.div`
  width: 100%;
  border-radius: 12px;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  box-sizing: border-box;
`;

export const MktCostCard = styled.div`
  max-width: 195px;
  width: 100%;
  border-radius: 12px;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  box-sizing: border-box;

  @media (max-width: 1600px) {
    max-width: 100%;
  }
`;

export const CustomMarkupCard = styled.div`
  max-width: 195px;
  width: 100%;
  border-radius: 12px;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  box-sizing: border-box;

  @media (max-width: 1600px) {
    max-width: 100%;
  }
`;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: unset;
  gap: 8px;
`;

export const Label = styled(TextBase)`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 105%;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Input = styled(TextFieldBase)`
  width: 100%;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const InputMktCost = styled(TextFieldBase)`
  width: 100%;
`;
