import styled, { css } from 'styled-components/macro';

import CardBase from '@components/common/core/Surfaces/Card';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';
import SwitchBase from '@components/common/core/Inputs/Switch';

import {
  IPercentageProps,
  IProfitValueProps,
} from '@domain/interfaces/dashboard/ProductAnalytics/IProductDetails';

export const ChartWrapper = styled.div`
  height: 70%;
  width: 35%;
  margin-bottom: -20px;

  @media only screen and (max-width: 528px) {
    width: 100%;
    height: 64px;
    margin-bottom: unset;
  }
`;

export const ComparedPeriodText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
  display: block;
`;

export const PercentageValue = styled(TextBase)<IPercentageProps>`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.danger.default};
  display: flex;
  gap: 4px;
  align-items: center;
  line-height: 24px;

  ${({ isPositive, theme }) =>
    isPositive &&
    css`
      color: ${theme.colors.success.default};
    `}
`;

export const PercentageArrow = styled.div<IPercentageProps>`
  margin-right: 4px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid ${({ theme }) => theme.colors.danger.default};

  ${({ isPositive, theme }) =>
    isPositive &&
    css`
      border-top: 0;
      border-bottom: 6px solid ${theme.colors.success.default};
    `}
`;

export const PercentageWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ProfitValue = styled(TextBase)<IProfitValueProps>`
  font-size: 2rem;
  font-weight: 500;
  line-height: 120%;

  ${({ isPositive, theme }) =>
    isPositive &&
    css`
      color: ${theme.colors.success.default};
    `}

  ${({ isNegative, theme }) =>
    isNegative &&
    css`
      color: ${theme.colors.danger.default};
    `}

  ${({ isSmall }) =>
    isSmall &&
    css`
      font-size: 1.5rem;
    `}
`;

export const ProfitValueAndPercentageWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-end;
`;

export const Title = styled(HeadingBase)``;

export const Card = styled(CardBase)`
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  max-width: 398px;
  height: 147px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border: unset;
  border-radius: 12px;

  @media only screen and (max-width: 1360px) {
    max-width: unset;
    margin-bottom: 16px;
  }

  @media only screen and (max-width: 528px) {
    height: unset;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media only screen and (max-width: 528px) {
    margin-top: 12px;
  }
`;

export const CardFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.gray[4]};
`;

export const SwitchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-left: 20px;
`;

export const Switch = styled(SwitchBase)`
  width: unset;
`;

export const SwitchText = styled(TextBase)``;

export const SeeDetailsButton = styled(ButtonBase)`
  cursor: pointer;
  border: unset;
  border-radius: 0;

  &:hover {
    border: unset;
    box-shadow: unset;
  }

  & > span {
    color: ${({ theme }) => theme.colors.gray[1]};
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 120%;
    margin-right: 5px;
  }
`;

export const ContentAndChartContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
  flex: 1;

  @media only screen and (max-width: 528px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`;
