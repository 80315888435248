import React from 'react';
import { CreditCard } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { ESubscriptionRecurrence } from '@domain/enums/common/subscription/ESubscriptionRecurrence';
import { IParams } from '@domain/interfaces/IParams';

import { cardNumberFormatter, cvvFormatter, expirationDateFormatter } from '@helpers/masks';
import { getCardIssuer } from '@helpers/subscription/payment';

import { paymentSchema } from '@helpers/validators/subscription/checkout/payment';

import { useIugu } from '@helpers/hooks/useIugu';
import { useToast } from '@helpers/hooks/useToast';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';

import paymentMethodService from '@services/common/paymentMethod/paymentMethod';

import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';
import CreditCardViewer from './CreditCard';

import * as S from './styles';

interface IUpdatePaymentMethodProps {
  isOpen: boolean;
  toggle: () => void;
}

const UpdatePaymentMethod: React.FC<IUpdatePaymentMethodProps> = ({ isOpen, toggle }) => {
  const iugu = useIugu('32100AB23A5B464C964437C238C1844D');
  const theme = useTheme();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { subscription, mutateSubscription } = useStoreSubscription();
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ mode: 'onBlur', reValidateMode: 'onChange', resolver: yupResolver(paymentSchema) });

  const formRef = React.useRef<HTMLFormElement>(null);

  const [isUpdatingPaymentMethod, setIsUpdatingPaymentMethod] = React.useState<boolean>(false);

  const numberRegister = register('number');
  const fullNameRegister = register('full_name');
  const expirationRegister = register('expiration');
  const verificationValueRegister = register('verification_value');

  const onNumberChange = React.useCallback(
    event => {
      const formattedNumber = cardNumberFormatter(event.target.value);
      event.target.value = formattedNumber;
      numberRegister.onChange(event);
    },
    [numberRegister],
  );

  const onExpirationChange = React.useCallback(
    event => {
      const formattedExpiration = expirationDateFormatter(event.target.value);
      event.target.value = formattedExpiration;
      expirationRegister.onChange(event);
    },
    [expirationRegister],
  );

  const onVerificationValueChange = React.useCallback(
    event => {
      const number = getValues('number');
      const cardIssuer = getCardIssuer(number);
      let formattedVerificationValue = '';

      if (cardIssuer === 'American Express') {
        formattedVerificationValue = cvvFormatter(event.target.value, 4);
      } else {
        formattedVerificationValue = cvvFormatter(event.target.value);
      }

      event.target.value = formattedVerificationValue;
      verificationValueRegister.onChange(event);
    },
    [verificationValueRegister, getValues],
  );

  const onSubmit = React.useCallback(async () => {
    setIsUpdatingPaymentMethod(true);

    try {
      await iugu.setTestMode(true);
      const createdCardToken = await iugu.createPaymentToken(formRef.current);

      const isMonthlySubscription = subscription?.recurrence === ESubscriptionRecurrence.MONTHLY;

      const {
        data: paymentMethodResponseData,
      } = await paymentMethodService.getPaymentMethodPromise({ storeAliasId });

      if (paymentMethodResponseData.payment_method) {
        await paymentMethodService.deletePaymentMethod({ storeAliasId });
      }

      await paymentMethodService.createPaymentMethod({
        storeAliasId,
        data: { token: createdCardToken.id, is_default: Boolean(isMonthlySubscription) },
      });

      setIsUpdatingPaymentMethod(false);

      toast.success('Método de pagamento atualizado!');

      toggle();

      mutateSubscription();
    } catch (error: any) {
      setIsUpdatingPaymentMethod(false);

      if (error?.errors?.adblock) {
        toast.error(
          'Desabilite o AdBlock e depois recarregue a página para poder continuar essa etapa.',
        );
      } else if (error?.errors?.record_invalid === 'Validation failed: Number is invalid') {
        toast.error('Número do cartão inválido.');
      } else if (error?.errors?.verification_value === 'is_invalid') {
        toast.error('CVV / CID inválidos');
      } else if (error?.errors?.last_name === 'is_invalid') {
        toast.error('Sobrenome do cartão inválido.');
      } else if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        throw error;
      }
    }
  }, [toast, iugu, storeAliasId, subscription, toggle, mutateSubscription]);

  const isNumberError = Boolean(errors.number);
  const isFullNameError = Boolean(errors.full_name);
  const isExpirationError = Boolean(errors.expiration);
  const isVerificationValue = Boolean(errors.verification_value);

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Header>
        <CreditCard size={24} color={theme.colors.gray[1]} />
        <S.Title type={EHeadingSize.H5}>Editar método de pagamento</S.Title>
      </S.Header>

      <S.Body>
        <CreditCardViewer getValues={getValues} watch={watch} />

        <Form innerRef={formRef} onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>Número do cartão</S.Label>
            <S.Input
              {...numberRegister}
              type="text"
              placeholder="4111 1111 1111 1111"
              onChange={onNumberChange}
              data-iugu="number"
              data-openreplay-obscured
              isError={isNumberError}
            />
            {isNumberError && <Text isErrorFeedback>{errors.number.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Nome (Impresso no Cartão)</S.Label>
            <S.Input
              {...fullNameRegister}
              type="text"
              placeholder="YUNG BUDA"
              data-iugu="full_name"
              data-openreplay-obscured
              isError={isFullNameError}
            />
            {isFullNameError && <Text isErrorFeedback>{errors.full_name.message}</Text>}
          </S.InputGroup>

          <S.ExpirationDateAndSecurityCodeWrapper>
            <S.InputGroup>
              <S.Label>Validade</S.Label>
              <S.Input
                {...expirationRegister}
                type="text"
                placeholder="MM/AA"
                onChange={onExpirationChange}
                data-iugu="expiration"
                isError={isExpirationError}
              />
              {isExpirationError && <Text isErrorFeedback>{errors.expiration.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Cód. Segurança (CVV)</S.Label>
              <S.Input
                {...verificationValueRegister}
                type="text"
                placeholder="123"
                onChange={onVerificationValueChange}
                data-iugu="verification_value"
                data-openreplay-obscured
                isError={isVerificationValue}
              />
              {isVerificationValue && (
                <Text isErrorFeedback>{errors.verification_value.message}</Text>
              )}
            </S.InputGroup>
          </S.ExpirationDateAndSecurityCodeWrapper>

          <S.SubmitButton isLoading={isUpdatingPaymentMethod}>
            Salvar método de pagamento
          </S.SubmitButton>
        </Form>
      </S.Body>
    </S.Modal>
  );
};

export default UpdatePaymentMethod;
