import { ERankingProductSortOption } from '@domain/enums/dashboard/productAnalytics/ERankingProductSort';

export const RANKING_PRODUCTS_SORTER = [
  {
    value: ERankingProductSortOption.PRODUCT_NAME,
    label: 'Produto',
  },
  {
    value: ERankingProductSortOption.FAVORITE_PRODUCT,
    label: 'Favoritos',
  },
  {
    value: ERankingProductSortOption.NET_REVENUE_AMOUNT,
    label: 'Receita Liquida',
  },
  {
    value: ERankingProductSortOption.PENDING_LINE_ITEMS_QUANTITY,
    label: 'Pendentes',
  },
  {
    value: ERankingProductSortOption.COGS_AMOUNT,
    label: 'Custo dos Produtos',
  },
  {
    value: ERankingProductSortOption.MARKETING_COST,
    label: 'Marketing',
  },
  {
    value: ERankingProductSortOption.TAXES_AND_FESS_AMOUNT,
    label: 'Impostos e Tarifas',
  },
  {
    value: ERankingProductSortOption.CPA_AMOUNT,
    label: 'CPA',
  },
  {
    value: ERankingProductSortOption.BOLETO_CONVERSION,
    label: 'Conversão de Boleto',
  },
  {
    value: ERankingProductSortOption.NET_PROFIT_AMOUNT,
    label: 'Lucro Líquido',
  },
  {
    value: ERankingProductSortOption.PROFIT_MARGIN,
    label: 'Margem de Lucro',
  },
];
