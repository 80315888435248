import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';
import {
  ICreateYampiCredentialProps,
  IDisableYampiCredentialProps,
  IGetYampiCredentialProps,
  IGetYampiCredentialResponse,
  IUpdateYampiCredentialProps,
  IUpdateYampiCredentialResponse,
  IGetYampiCredentialPromiseProps,
} from '@domain/interfaces/dashboard/IntegrationCenter/StoreProvider/Yampi/IYampIAuth';
import { AxiosResponse } from 'axios';

export class YampiAuthService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getYampiCredential({
    storeAliasId,
  }: IGetYampiCredentialProps): IGetYampiCredentialResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/yampi-credentials`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      yampiCredential: data?.yampi_credential,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public async getYampiCredentialPromise({
    storeAliasId,
  }: IGetYampiCredentialPromiseProps): Promise<any> {
    return this.apiService.get(`/api/v1/users/stores/${storeAliasId}/yampi-credentials`);
  }

  public createYampiCredential({ storeAliasId, data }: ICreateYampiCredentialProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/yampi-credentials`, {
      ...data,
    });
  }

  public updateYampiCredential({
    storeAliasId,
    yampiCredentialAliasId,
    data,
  }: IUpdateYampiCredentialProps): Promise<AxiosResponse<IUpdateYampiCredentialResponse>> {
    return this.apiService.put<IUpdateYampiCredentialResponse>(
      `/api/v1/users/stores/${storeAliasId}/yampi-credentials/${yampiCredentialAliasId}`,
      {
        ...data,
      },
    );
  }

  public disableYampiCredential({
    storeAliasId,
    yampiCredentialAliasId,
    data,
  }: IDisableYampiCredentialProps): Promise<AxiosResponse<void>> {
    return this.apiService.delete(
      `/api/v1/users/stores/${storeAliasId}/yampi-credentials/${yampiCredentialAliasId}`,
      { ...data },
    );
  }
}

const yampiAuthService = new YampiAuthService(API_DOMAIN);

export default yampiAuthService;
