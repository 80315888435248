import React from 'react';
import { useParams } from 'react-router-dom';

import { ECouponStatus } from '@domain/enums/common/coupon/ECouponStatus';
import { EPlanRecurrence, EStatus } from '@domain/enums/subscription/ESubscription';
import { ICouponProvider } from '@domain/interfaces/subscription/checkout/ICoupon';
import { ICoupon } from '@domain/interfaces/common/coupon/ICoupon';
import { IParams } from '@domain/interfaces/IParams';

import { useQuery } from '@helpers/hooks/useQuery';
import { useToast } from '@helpers/hooks/useToast';
import { usePayment } from '@helpers/hooks/common/store/payment/usePayment';
import { useConfig } from '@helpers/hooks/useConfig';
import { useSubscription } from '@helpers/hooks/common/store/subscription/useSubscription';
import { useDate } from '@helpers/hooks/useDate';

import { getEnvironment } from '@helpers/utils/environment';

import couponService from '@services/common/coupon/coupon';
import plansService from '@services/common/plans/plans';

const anniversaryCouponId =
  getEnvironment() === 'prod'
    ? 'a69f4e00-d5a2-4c0f-962c-5110c51c3a18'
    : '43aae24b-e095-41a5-a1e9-b0c320eaeb86';

const CouponContext = React.createContext<ICouponProvider | null>(null);

export const CouponProvider: React.FC = ({ children }) => {
  const query = useQuery();
  const { toast } = useToast();
  const { subscription } = usePayment();
  const { analytics, user } = useConfig();
  const { storeAliasId } = useParams<IParams>();
  const { selectedPlanIdentifier } = useSubscription();
  const { isAfter, differenceInDays } = useDate();

  const isCurrentSubscriptionPaid =
    subscription?.status === EStatus.PAID && !subscription?.plan?.identifier.includes('free');

  const couponIdQuery = query.get('coupon_id');
  const hashQuery = query.get('hash');
  const planAliasIdQuery = query.get('plan_alias_id');

  const [selectedCoupon, setSelectedCoupon] = React.useState<ICoupon | undefined>(undefined);
  const [isVerifyingCouponId, setIsVerifyingCouponId] = React.useState<boolean>(false);
  const [couponStatus, setCouponStatus] = React.useState<ECouponStatus | undefined>(undefined);
  const [isVerifyingCouponIdentifier, setIsVerifyingCouponIdentifier] = React.useState<boolean>(
    false,
  );
  const [recurrenceType, setRecurrenceType] = React.useState<EPlanRecurrence>(
    EPlanRecurrence.MONTHLY,
  );

  const resetCoupon = React.useCallback(() => {
    setSelectedCoupon(undefined);
    setCouponStatus(undefined);
  }, []);

  const resetStatus = React.useCallback(() => {
    setCouponStatus(undefined);
  }, []);

  const handleRecurrenceType = React.useCallback(recurrence => setRecurrenceType(recurrence), []);

  const verifyCouponIdentifier = React.useCallback(
    async (planIdentifier, subscriptionAliasId, couponIdentifier) => {
      setIsVerifyingCouponIdentifier(true);

      try {
        const { data: plansData } = await plansService.listAllPlansPromise({ storeAliasId });

        if (!plansData) {
          setCouponStatus(ECouponStatus.NONEXISTENT);
          setIsVerifyingCouponIdentifier(false);
          return;
        }

        const { plans } = plansData;

        const foundPlan = plans.find(plan => plan.identifier === planIdentifier);

        if (!foundPlan) {
          setCouponStatus(ECouponStatus.NONEXISTENT);
          setIsVerifyingCouponIdentifier(false);
          return;
        }

        const parsedCoupon = couponIdentifier.toLowerCase();

        const { data } = await couponService.getCouponByIdentifierPromise({
          couponIdentifier: parsedCoupon,
          planAliasId: foundPlan.alias_id,
          subscriptionAliasId,
        });
        const { coupon, is_valid } = data;

        if (
          coupon?.max_cycles &&
          coupon.max_cycles > 0 &&
          recurrenceType !== EPlanRecurrence.MONTHLY
        ) {
          setCouponStatus(ECouponStatus.NONEXISTENT);
          setIsVerifyingCouponIdentifier(false);
          return;
        }

        if (is_valid && coupon) {
          setCouponStatus(ECouponStatus.AVAILABLE);
          setSelectedCoupon(coupon);

          analytics?.track(
            'Has Applied Coupon',
            { discount_coupon: coupon, email: user.email },
            { context: { groupId: storeAliasId } },
          );
        }

        if (!is_valid && coupon) setCouponStatus(ECouponStatus.EXPIRED);

        if (!is_valid && !coupon) setCouponStatus(ECouponStatus.NONEXISTENT);

        setIsVerifyingCouponIdentifier(false);
      } catch (error: any) {
        setIsVerifyingCouponIdentifier(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [toast, analytics, user, storeAliasId, recurrenceType],
  );

  const verifyCouponId = React.useCallback(
    async (planIdentifier, subscriptionAliasId, couponId, planAliasId) => {
      setIsVerifyingCouponId(true);

      try {
        const { data: plansData } = await plansService.listAllPlansPromise({ storeAliasId });

        if (!plansData) {
          setCouponStatus(ECouponStatus.NONEXISTENT);
          setIsVerifyingCouponIdentifier(false);
          return;
        }

        const { plans } = plansData;

        const foundPlan = plans.find(
          plan => plan.identifier === planIdentifier || plan.alias_id === planAliasId,
        );

        if (!foundPlan) {
          setCouponStatus(ECouponStatus.NONEXISTENT);
          setIsVerifyingCouponIdentifier(false);
          return;
        }

        const { data } = await couponService.getCouponByIdPromise({
          couponId,
          planAliasId: foundPlan.alias_id,
          subscriptionAliasId,
        });

        const { coupon, is_valid } = data;

        if (
          coupon?.max_cycles &&
          coupon.max_cycles > 0 &&
          recurrenceType !== EPlanRecurrence.MONTHLY
        ) {
          setCouponStatus(ECouponStatus.NONEXISTENT);
          setIsVerifyingCouponIdentifier(false);
          return;
        }

        if (coupon && is_valid) {
          setCouponStatus(ECouponStatus.AVAILABLE);
          setSelectedCoupon(coupon);
        }
      } finally {
        setIsVerifyingCouponId(false);
      }
    },
    [storeAliasId, recurrenceType],
  );

  React.useEffect(() => {
    const isSubscriptionPaid = subscription?.status === EStatus.PAID;
    const isSubscriptionTrialStarted = subscription?.status === EStatus.TRIAL_STARTED;
    const isSubscriptionTrialEnded = subscription?.status === EStatus.TRIAL_ENDED;
    const isSubscriptionCanceled = subscription?.status === EStatus.CANCELED;

    if (
      (!selectedPlanIdentifier && !hashQuery) ||
      isSubscriptionPaid ||
      isSubscriptionTrialStarted ||
      !subscription
    )
      return;

    if (couponIdQuery === 'undefined') return;

    const isAnniversaryPromotionFinished = isAfter(new Date(), new Date('2023-07-15'));

    if (isSubscriptionTrialEnded && !isAnniversaryPromotionFinished && !hashQuery) {
      verifyCouponId(selectedPlanIdentifier, subscription.alias_id, anniversaryCouponId, undefined);

      return;
    }

    if (
      isSubscriptionCanceled &&
      subscription.canceled_at &&
      !isAnniversaryPromotionFinished &&
      !hashQuery
    ) {
      const canceledAt = subscription.canceled_at;

      if (differenceInDays(new Date(canceledAt), new Date()) >= 30) {
        verifyCouponId(
          selectedPlanIdentifier,
          subscription.alias_id,
          anniversaryCouponId,
          undefined,
        );
      }
    }

    if (couponIdQuery && hashQuery) {
      verifyCouponId(
        selectedPlanIdentifier,
        subscription.alias_id,
        couponIdQuery,
        planAliasIdQuery,
      );
    }
  }, [
    couponIdQuery,
    verifyCouponId,
    selectedPlanIdentifier,
    subscription,
    differenceInDays,
    isAfter,
    hashQuery,
    planAliasIdQuery,
  ]);

  React.useEffect(() => {
    if (isCurrentSubscriptionPaid && selectedCoupon) {
      setSelectedCoupon(undefined);
    }
  }, [isCurrentSubscriptionPaid, selectedCoupon]);

  React.useEffect(() => {
    if (!selectedCoupon) return;

    if (recurrenceType === EPlanRecurrence.MONTHLY) return;

    if (!selectedCoupon.max_cycles || selectedCoupon.max_cycles < 0) return;

    setSelectedCoupon(undefined);
  }, [selectedCoupon, recurrenceType]);

  return (
    <CouponContext.Provider
      value={{
        isVerifyingCouponId,
        isVerifyingCouponIdentifier,
        verifyCouponIdentifier,
        couponStatus,
        selectedCoupon,
        resetCoupon,
        resetStatus,
        handleRecurrenceType,
        recurrenceType,
      }}
    >
      {children}
    </CouponContext.Provider>
  );
};

export const useCoupon = (): ICouponProvider => {
  const context = React.useContext(CouponContext);

  if (!context) {
    throw new Error('useCoupon must be used within CouponProvider');
  }

  return context;
};
