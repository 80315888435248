import React from 'react';
import { WarningCircle } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { deleteStoreSchema } from '@helpers/validators/dashboard/myProfile/deleteStore';

import storesService from '@services/pages/stores/stores';

import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

interface IDeleteStoreModalProps {
  isOpen: boolean;
  toggle: () => void;
}

const DeleteStoreModal: React.FC<IDeleteStoreModalProps> = ({ isOpen, toggle }) => {
  const { store } = useStoreConfig();

  const storeName = store?.name;

  const schema = deleteStoreSchema(store?.name);

  const theme = useTheme();
  const history = useHistory();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  watch();

  const storeNameRegister = register('store_name');
  const [storeNameValue] = getValues(['store_name']);

  const [remainingSeconds, setRemainingSeconds] = React.useState<number>(0);
  const [isDeletingStore, setIsDeletingStore] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(async () => {
    setIsDeletingStore(true);

    try {
      await storesService.deleteStore(storeAliasId);

      toast.success('Loja excluída com sucesso!');

      history.push('/stores');
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsDeletingStore(false);
    }
  }, [storeAliasId, history, toast]);

  React.useEffect(() => {
    if (isOpen) {
      setRemainingSeconds(10);
    } else {
      setRemainingSeconds(0);
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (remainingSeconds === 10) {
      let counter = 10;

      const interval = setInterval(() => {
        counter -= 1;

        setRemainingSeconds(counter);

        if (counter === 0) {
          clearInterval(interval);
        }
      }, 1000);
    }
  }, [remainingSeconds]);

  const isButtonDisabled = storeNameValue !== `excluir ${storeName}`;

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Header>
        <WarningCircle size={24} color={theme.colors.danger.default} />
        <S.Title>Confirmação para remover loja</S.Title>
      </S.Header>

      <S.Body>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Description>
            <S.DescriptionText>
              Você tem certeza de que deseja excluir a loja? Essa ação é irreversível.
            </S.DescriptionText>
            <S.DescriptionText>
              Caso deseje trocar as credenciais de sua loja, entre em contato com o nosso suporte.
            </S.DescriptionText>
          </S.Description>

          <S.InputGroup>
            <S.Label>{`Digite 'excluir ${storeName}' para continuar`}</S.Label>
            <S.Input {...storeNameRegister} type="text" placeholder={`excluir ${storeName}`} />
            {errors?.store_name && <Text isErrorFeedback>{errors.store_name.message}</Text>}
          </S.InputGroup>

          <S.ButtonsWrapper>
            <S.CancelButton
              variant={EButtonVariant.SECONDARY_BUTTON}
              disabled={isDeletingStore}
              onClick={toggle}
              type="button"
            >
              Cancelar
            </S.CancelButton>
            <S.DeleteButton
              disabled={remainingSeconds !== 0 || isButtonDisabled}
              isLoading={isDeletingStore}
              type="submit"
            >
              {remainingSeconds > 0 ? `Excluir loja (${remainingSeconds})` : `Excluir loja`}
            </S.DeleteButton>
          </S.ButtonsWrapper>
        </S.Form>
      </S.Body>
    </S.Modal>
  );
};

export default DeleteStoreModal;
