import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useCreateOrder } from '@helpers/hooks/pages/dashboard/orders/useCreateOrder';
import VariantsSkeletonLoading from '@components/Dashboard/Kit/VariantsSkeletonLoading';
import EmptyData from '@components/common/core/DataDisplay/EmptyData';
import Product from './Product';

import * as S from './styles';

const ProductsDropdown: React.FC = () => {
  const {
    products,
    loadProducts,
    hasMore,
    handleLoadMore,
    inputComponentRef,
    isLoadingProducts,
  } = useCreateOrder();

  const [parentWidth, setParentWidth] = React.useState<number>(
    inputComponentRef?.current?.offsetWidth || 0,
  );

  React.useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      if (inputComponentRef.current) {
        setParentWidth(inputComponentRef.current.offsetWidth);
      }
    });
  }, [inputComponentRef]);

  return (
    <S.Wrapper width={parentWidth} id="products-list">
      <InfiniteScroll
        dataLength={products.length}
        next={handleLoadMore}
        hasMore={hasMore}
        loader={<VariantsSkeletonLoading />}
        scrollableTarget="products-list"
        scrollThreshold={0.95}
      >
        {!products.length && isLoadingProducts ? <VariantsSkeletonLoading /> : <></>}

        {!products.length && !isLoadingProducts ? (
          <S.NoProductWrapper>
            <EmptyData content="Não encontramos nenhum produto." />
          </S.NoProductWrapper>
        ) : (
          <></>
        )}

        {products.map(variant => (
          <Product key={variant.alias_id} product={variant} />
        ))}
      </InfiniteScroll>
    </S.Wrapper>
  );
};

export default ProductsDropdown;
