import valid from 'card-validator';

export const cardNumberFormatter = (number: string): string => {
  const issuer = valid.number(number);

  if (issuer.card?.niceType === 'Diners Club' && number.length === 18) {
    return number.slice(0, -1);
  }

  if (issuer.card?.niceType === 'American Express' && number.length === 19) {
    return number.slice(0, -1);
  }

  return number
    .replace(/\D/g, '')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})\d+?$/, '$1');
};

export const expirationDateFormatter = (date: string): string => {
  const containsSlash = date.includes('/');

  const parsedDate = date.replace(/\D/g, '');

  if (parsedDate.length === 2 && !containsSlash) {
    return `${parsedDate}/`;
  }

  return parsedDate.replace(/(\d{2})(\d)/, '$1/$2').replace(/(\/\d{2})\d+?$/, '$1');
};

export const cvvFormatter = (number: string, maxDigits = 3): string => {
  if (maxDigits === 4) {
    return number.replace(/\D/g, '').replace(/(\d{4})\d+?$/, '$1');
  }

  return number.replace(/\D/g, '').replace(/(\d{3})\d+?$/, '$1');
};
