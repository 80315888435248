/* eslint-disable import/no-duplicates */
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ChevronDown, ChevronRight, Cancel } from '@profitfy/pakkun-icons';
import { formatDistanceToNow } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { EAvatarSize } from '@domain/enums/components/EAvatar';
import { EBadgeColors } from '@domain/enums/components/EBadge';
import { EHeadAlign } from '@domain/enums/components/ETable';
import { ITableProps } from '@domain/interfaces/integrations/marketing/taboola/ITable';

import { useTheme } from 'styled-components/macro';
import { useTaboolaAdAccounts } from '@helpers/hooks/common/integrations/marketing/taboola/useTaboolaAdAccounts';
import { useDate } from '@helpers/hooks/useDate';
import { useTaboolaCredentials } from '@helpers/hooks/common/integrations/marketing/taboola/useTaboolaCredentials';

import DeleteProfileModal from '@pages/Onboarding/Dropshipping/Marketing/DeleteProfileModal';
import EmptyData from '@components/common/core/DataDisplay/EmptyData';
import Row from './Row';
import TableSkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Table: React.FC<ITableProps> = ({ adSenseCredential, openFirstProfile }) => {
  const theme = useTheme();
  const { utcToZonedTime } = useDate();
  const { handleLogout } = useTaboolaCredentials();

  const {
    adSenseAccounts,
    hasMore,
    handleLoadMore,
    loadAdSenseAccounts,
    isAdSenseAccountsError,
    isLoadingAdSenseAccounts,
  } = useTaboolaAdAccounts();

  const [showTableRows, setShowTableRows] = React.useState<boolean>(!!openFirstProfile);
  const [isDeleteProfileModalOpen, setIsDeleteProfileModalOpen] = React.useState<boolean>(false);
  const [isMoreOptionsPopoverOpen, setIsMoreOptionsPopoverOpen] = React.useState<boolean>(false);

  const handleDeleteProfileModalOpen = React.useCallback(() => {
    setIsDeleteProfileModalOpen(!isDeleteProfileModalOpen);
  }, [isDeleteProfileModalOpen]);

  const handleMoreOptionsPopoverOpen = React.useCallback(() => {
    setIsMoreOptionsPopoverOpen(!isMoreOptionsPopoverOpen);
  }, [isMoreOptionsPopoverOpen]);

  const handleTableRowsIsShow = React.useCallback(() => {
    setShowTableRows(!showTableRows);
  }, [showTableRows]);

  const handleMoreOptionsClick = React.useCallback(
    event => {
      event.stopPropagation();

      handleMoreOptionsPopoverOpen();
    },
    [handleMoreOptionsPopoverOpen],
  );

  const handleRemoveAdSenseCredential = React.useCallback(
    event => {
      event.stopPropagation();

      handleDeleteProfileModalOpen();
      handleMoreOptionsPopoverOpen();
    },
    [handleDeleteProfileModalOpen, handleMoreOptionsPopoverOpen],
  );

  const parseIsOperationalName = React.useCallback(() => {
    if (adSenseCredential.is_blocked_by_downgrade) return 'Inativo';

    if (adSenseCredential.is_operational) return 'Operacional';

    return 'Não operacional';
  }, [adSenseCredential]);

  const getBadgeColor = React.useCallback(() => {
    if (adSenseCredential.is_blocked_by_downgrade) return EBadgeColors.YELLOW;

    if (adSenseCredential.is_operational) return EBadgeColors.PRIMARY;

    return EBadgeColors.RED;
  }, [adSenseCredential]);

  React.useEffect(() => {
    loadAdSenseAccounts();
  }, [loadAdSenseAccounts]);

  const getTableIcon = (): JSX.Element => {
    if (!showTableRows) return <ChevronRight size={25} color={theme.colors.gray[1]} />;

    return <ChevronDown size={25} color={theme.colors.gray[1]} />;
  };

  const lastTimeUpdated = new Date(adSenseCredential.created_at);

  const hasNoAdSenseAccounts =
    Boolean(!adSenseAccounts.length) && !isLoadingAdSenseAccounts && !isAdSenseAccountsError;
  const isInitialLoading = Boolean(!adSenseAccounts?.length) && isLoadingAdSenseAccounts;
  const isLoadingMore = Boolean(adSenseAccounts?.length) && isLoadingAdSenseAccounts;
  const isCredentialBlockedByDowngrade = adSenseCredential.is_blocked_by_downgrade;

  return (
    <S.Wrapper>
      <S.TaboolaProfileButton onClick={handleTableRowsIsShow} showTableRows={showTableRows}>
        <S.AvatarAndUserInfosWrapper>
          <S.Avatar word={adSenseCredential.name.charAt(0)} size={EAvatarSize.MEDIUM} />
          <S.UserInfos>
            <S.UserName type={EHeadingSize.H5}>{adSenseCredential.name}</S.UserName>
            <S.ConnectedTimeText>
              {`Conectado há ${formatDistanceToNow(utcToZonedTime(lastTimeUpdated), {
                locale: pt,
              })}`}
            </S.ConnectedTimeText>
          </S.UserInfos>
        </S.AvatarAndUserInfosWrapper>

        <S.BadgeAndMoreOptionsWrapper>
          <S.Tooltip>
            <S.Tooltip.Trigger>
              <S.Badge color={getBadgeColor()}>{parseIsOperationalName()}</S.Badge>
            </S.Tooltip.Trigger>

            {isCredentialBlockedByDowngrade && (
              <S.Tooltip.Content side="top" sideOffset={5}>
                <S.TooltipContent>
                  <S.TooltipText>
                    Esse perfil foi inativado <strong>após a realização do Downgrade.</strong>
                    <br />
                    Os dados retroativos desse perfil <strong>não foram excluídos.</strong>
                  </S.TooltipText>
                </S.TooltipContent>

                <S.Tooltip.Arrow />
              </S.Tooltip.Content>
            )}
          </S.Tooltip>

          <S.MoreOptionsPopover open={isMoreOptionsPopoverOpen}>
            <S.MoreOptionsPopover.Trigger asChild onClick={handleMoreOptionsClick}>
              <S.MoreOptionsWrapper>
                <S.Bullet />
                <S.Bullet />
                <S.Bullet />
              </S.MoreOptionsWrapper>
            </S.MoreOptionsPopover.Trigger>

            <S.MoreOptionsPopover.Content side="bottom">
              <S.UnlinkAccountButton onClick={handleRemoveAdSenseCredential}>
                <Cancel size={20} color={theme.colors.danger.default} outline />
                Desvincular perfil com a Profitfy
              </S.UnlinkAccountButton>

              <S.MoreOptionsPopover.Arrow />
            </S.MoreOptionsPopover.Content>
          </S.MoreOptionsPopover>

          <S.IconWrapper>{getTableIcon()}</S.IconWrapper>
        </S.BadgeAndMoreOptionsWrapper>
      </S.TaboolaProfileButton>

      {showTableRows && (
        <InfiniteScroll
          dataLength={adSenseAccounts.length}
          scrollableTarget={`adAccount-${adSenseCredential.name}-list`}
          hasMore={hasMore}
          next={handleLoadMore}
          loader={<TableSkeletonLoading />}
          scrollThreshold={0.95}
        >
          <S.Table id={`adAccount-${adSenseCredential.name}-list`}>
            <S.TableBody>
              {isInitialLoading && <TableSkeletonLoading />}

              {adSenseAccounts?.map((adSenseAccount: any) => (
                <Row adSenseAccount={adSenseAccount} key={adSenseAccount.alias_id} />
              ))}

              {isLoadingMore && <TableSkeletonLoading />}

              {hasNoAdSenseAccounts && (
                <S.Table.Row>
                  <S.Table.Data colSpan={5} align={EHeadAlign.CENTER}>
                    <EmptyData content="Não encontramos nenhuma conta de anúncio existente." />
                  </S.Table.Data>
                </S.Table.Row>
              )}
            </S.TableBody>
          </S.Table>
        </InfiniteScroll>
      )}

      <DeleteProfileModal
        isOpen={isDeleteProfileModalOpen}
        toggle={handleDeleteProfileModalOpen}
        onConfirm={() => handleLogout(adSenseCredential.alias_id)}
      />
    </S.Wrapper>
  );
};

export default Table;
