import React from 'react';

import { ITable, ITableProps } from '@domain/interfaces/components/ITable';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import Row from './Row';
import Head from './Head';
import Data from './Data';

import * as S from './styles';

const Table: ITable & React.FC<ITableProps> = ({ children, containerRef, ...rest }) => {
  return (
    <S.Container ref={containerRef} {...rest}>
      <S.Table>{children}</S.Table>
    </S.Container>
  );
};

Table.Header = Header;
Table.Body = Body;
Table.Row = Row;
Table.Head = Head;
Table.Data = Data;
Table.Footer = Footer;

export default Table;
