import React from 'react';

import { useWhatsappInstance } from '@helpers/hooks/pages/dashboard/whatsappInstance/useWhatsappInstance';

import Header from './Header';
import Instance from './Instance';

import NoDeletedInstanceModal from './NoDeletedInstanceModal';

import * as S from './styles';

const Instances: React.FC = () => {
  const {
    isNoDeletedInstanceModalOpen,
    handleNoDeletedInstanceModalOpen,
    whatsappCredentials: instances,
  } = useWhatsappInstance();

  return (
    <S.Wrapper>
      <Header />

      <S.InstancesWrapper>
        {instances?.map(instance => {
          return (
            <Instance
              key={instance.whatsapp_credential.id}
              instance={instance.whatsapp_credential}
              metrics={instance.metrics}
            />
          );
        })}
      </S.InstancesWrapper>

      <NoDeletedInstanceModal
        isOpen={isNoDeletedInstanceModalOpen}
        toggle={handleNoDeletedInstanceModalOpen}
      />
    </S.Wrapper>
  );
};

export default Instances;
