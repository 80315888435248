import React from 'react';

import { Feature } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useToast } from '@helpers/hooks/useToast';

import storesService from '@services/pages/stores/stores';

import * as S from './styles';

const Welcome: React.FC = () => {
  const theme = useTheme();

  const { toast } = useToast();

  const { mutateStore, store } = useStoreConfig();

  const [isFinishingOnboarding, setIsFinishingOnboarding] = React.useState<boolean>(false);

  const finishOnboarding = React.useCallback(async () => {
    setIsFinishingOnboarding(true);

    const data = {
      ...store,
      is_whatsapp_onboarding_finished: true,
    };

    try {
      await storesService.updateStore(store.alias_id, data);

      await mutateStore();

      setIsFinishingOnboarding(false);
    } catch (error: any) {
      setIsFinishingOnboarding(false);
      toast.error(error?.response?.data?.message);
    }
  }, [mutateStore, toast, store]);

  return (
    <S.ContentWrapper>
      <S.Image
        src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/automations/Messaging-bro.svg"
        alt="profitfy-messages-instances-welcome-image"
      />

      <S.Content>
        <S.Heading>
          Conecte com múltiplos <mark>enviadores de mensagens</mark>
        </S.Heading>

        <S.BenefitsWrapper>
          <S.Benefit>
            <S.IconWrapper>
              <Feature size={12} color={theme.colors.green.default} />
            </S.IconWrapper>
            <S.BenefitText>Conecte vários enviadores de mensagens.</S.BenefitText>
          </S.Benefit>

          <S.Benefit>
            <S.IconWrapper>
              <Feature size={12} color={theme.colors.green.default} />
            </S.IconWrapper>
            <S.BenefitText>Rotacione os números para evitar bloqueios.</S.BenefitText>
          </S.Benefit>
        </S.BenefitsWrapper>

        <S.CreateNewInstanceButton
          onClick={finishOnboarding}
          isLoading={isFinishingOnboarding}
          disabled={isFinishingOnboarding}
        >
          Conectar enviadores
        </S.CreateNewInstanceButton>
      </S.Content>
    </S.ContentWrapper>
  );
};

export default Welcome;
