import { WarningOctagon } from 'phosphor-react';
import React from 'react';
import { useTheme } from 'styled-components';
import * as S from './styles';

interface IDeleteCredentialModalProps {
  isOpen: boolean;
  toggle: () => void;
  onDelete: () => void;
  isLoading: boolean;
  productName: string;
}
const ConfirmDeleteModal: React.FC<IDeleteCredentialModalProps> = ({
  isOpen,
  toggle,
  onDelete,
  isLoading,
  productName,
}) => {
  const { colors } = useTheme();

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.QuestionWrapper>
        <WarningOctagon size={24} color={colors.danger.default} />

        <S.Question>Excluir produto ?</S.Question>
      </S.QuestionWrapper>

      <S.Text>Tem certeza que deseja excluir o produto {productName} ?</S.Text>

      <S.ButtonsWrapper>
        <S.CancelButton onClick={toggle}>Cancelar</S.CancelButton>
        <S.ConfirmButton onClick={onDelete} isLoading={isLoading}>
          Excluir
        </S.ConfirmButton>
      </S.ButtonsWrapper>
    </S.Modal>
  );
};

export default ConfirmDeleteModal;
