import React from 'react';
import { Info, ChevronRight, ChevronDown } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { useFinancialDetails } from '@helpers/hooks/pages/dashboard/financialDetails/useFinancialDetails';
import { numberFormatter } from '@helpers/masks';
import {
  getAllCustomValues,
  getCustomValueAmount,
  getCustomValuePercentage,
} from '@helpers/utils/dashboard/financialDetails/financialDetails';
import { IParams } from '@domain/interfaces/IParams';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const PeriodTable: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const theme = useTheme();
  const {
    periodTableDatesNames,
    financialTablePeriod,
    financialTableComparePeriod,
    isLoadingFinancialDetails,
    isValidatingFinancialDetails,
    customValues,
    comparedCustomValues,
    filterData,
  } = useFinancialDetails();

  const [isCustomSpendsExpanded, setIsCustomSpendsExpanded] = React.useState<boolean>(false);

  const handleCustomSpendsExpanded = React.useCallback(
    () => setIsCustomSpendsExpanded(!isCustomSpendsExpanded),
    [isCustomSpendsExpanded],
  );

  const parsePercentageText = React.useCallback(percentage => {
    if (percentage < 0) {
      const positivePercentage = percentage * -1;

      return `-${numberFormatter(positivePercentage, 1)}`;
    }

    return numberFormatter(percentage, 1);
  }, []);

  const [dateToCompare, comparedDate] = periodTableDatesNames;

  if (isLoadingFinancialDetails || isValidatingFinancialDetails) {
    return <SkeletonLoading />;
  }

  const allCustomValues = getAllCustomValues(customValues, comparedCustomValues);

  const foundProductFilterData = filterData.find(filter => filter.field === 'product_id');

  return (
    <S.Table>
      <S.Table.Header>
        <S.Table.Row>
          <S.Table.Head />
          <S.Table.Head />
          <S.Table.Head align={EHeadAlign.RIGHT}>{comparedDate}</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>{dateToCompare}</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT} />
          <S.Table.Head align={EHeadAlign.RIGHT} />
        </S.Table.Row>
      </S.Table.Header>

      <S.Table.Body>
        <S.Table.Row borderBottom>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText highlight>Receita bruta</S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText highlight right>
              {`R$ ${numberFormatter(financialTableComparePeriod?.gross_revenue.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText highlight right>
              {`R$ ${numberFormatter(financialTablePeriod?.gross_revenue.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText highlight right>
              {`${parsePercentageText(financialTablePeriod?.gross_revenue.growth || 0)}%`}
              <S.PercentageArrow
                isPositive={(financialTablePeriod?.gross_revenue.growth || 0) >= 0}
              />
            </S.DataText>
          </S.Table.Data>
        </S.Table.Row>

        <S.Table.Row borderBottom>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText>Pedidos pendentes</S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(financialTableComparePeriod?.pending_orders.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(financialTablePeriod?.pending_orders.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText right>
              {`${parsePercentageText(financialTablePeriod?.pending_orders.growth || 0)}%`}
              <S.PercentageArrow
                isPositive={(financialTablePeriod?.pending_orders.growth || 0) >= 0}
              />
            </S.DataText>
          </S.Table.Data>
        </S.Table.Row>

        <S.Table.Row borderBottom>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText>Pedidos em análise</S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(
                financialTableComparePeriod?.in_analysis_orders.amount || 0,
                2,
              )}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(financialTablePeriod?.in_analysis_orders.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText right>
              {`${parsePercentageText(financialTablePeriod?.in_analysis_orders.growth || 0)}%`}
              <S.PercentageArrow
                isPositive={(financialTablePeriod?.in_analysis_orders.growth || 0) >= 0}
              />
            </S.DataText>
          </S.Table.Data>
        </S.Table.Row>

        <S.Table.Row borderBottom>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText>
              Pedidos não conciliados
              <S.HoverCard>
                <S.HoverCard.Trigger>
                  <Info size={16} outline color={theme.colors.gray[2]} />
                  <S.HoverCardContent side="bottom" sideOffset={5}>
                    <S.HoverCardContentWrapper>
                      <S.HoverCardText>
                        São pedidos que não foram possíveis de conciliar com seu gateway. Isso pode
                        ter ocorrido por credenciais inválidas, ou criação do pedido manualmente.
                        <S.Link to={`/${storeAliasId}/dashboard/feed`}>
                          Clique aqui para saber mais.
                        </S.Link>
                      </S.HoverCardText>
                    </S.HoverCardContentWrapper>
                    <S.HoverCardArrow />
                  </S.HoverCardContent>
                </S.HoverCard.Trigger>
              </S.HoverCard>
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(
                financialTableComparePeriod?.no_information_orders.amount || 0,
                2,
              )}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(financialTablePeriod?.no_information_orders.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText right>
              {`${parsePercentageText(financialTablePeriod?.no_information_orders.growth || 0)}%`}
              <S.PercentageArrow
                isPositive={(financialTablePeriod?.no_information_orders.growth || 0) >= 0}
              />
            </S.DataText>
          </S.Table.Data>
        </S.Table.Row>

        <S.Table.Row borderBottom>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText>Descontos</S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(
                financialTableComparePeriod?.discount_revenue.amount || 0,
                2,
              )}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(financialTablePeriod?.discount_revenue.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText right>
              {`${parsePercentageText(financialTablePeriod?.discount_revenue.growth || 0)}%`}
              <S.PercentageArrow
                isPositive={(financialTablePeriod?.discount_revenue.growth || 0) >= 0}
              />
            </S.DataText>
          </S.Table.Data>
        </S.Table.Row>

        <S.Table.Row borderBottom>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText>Chargebacks</S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(
                financialTableComparePeriod?.charged_back_revenue.amount || 0,
                2,
              )}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(financialTablePeriod?.charged_back_revenue.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText right>
              {`${parsePercentageText(financialTablePeriod?.charged_back_revenue.growth || 0)}%`}
              <S.PercentageArrow
                isPositive={(financialTablePeriod?.charged_back_revenue.growth || 0) >= 0}
              />
            </S.DataText>
          </S.Table.Data>
        </S.Table.Row>

        <S.Table.Row borderBottom>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText>Devoluções</S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(financialTableComparePeriod?.refund_revenue.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(financialTablePeriod?.refund_revenue.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText right>
              {`${parsePercentageText(financialTablePeriod?.refund_revenue.growth || 0)}%`}
              <S.PercentageArrow
                isPositive={(financialTablePeriod?.refund_revenue.growth || 0) >= 0}
              />
            </S.DataText>
          </S.Table.Data>
        </S.Table.Row>

        <S.Table.Row borderBottom>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText highlight>Receita líquida</S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText highlight right>
              {`R$ ${numberFormatter(financialTableComparePeriod?.net_revenue.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText highlight right>
              {`R$ ${numberFormatter(financialTablePeriod?.net_revenue.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText highlight right>
              {`${parsePercentageText(financialTablePeriod?.net_revenue.percentage || 0)}%`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText highlight right>
              {`${parsePercentageText(financialTablePeriod?.net_revenue.growth || 0)}%`}
              <S.PercentageArrow
                isPositive={(financialTablePeriod?.net_revenue.growth || 0) >= 0}
              />
            </S.DataText>
          </S.Table.Data>
        </S.Table.Row>

        <S.Table.Row borderBottom>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText>Custo com produtos</S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(financialTableComparePeriod?.cogs.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>{`R$ ${numberFormatter(
              financialTablePeriod?.cogs.amount || 0,
              2,
            )}`}</S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>{`${parsePercentageText(
              financialTablePeriod?.cogs.percentage || 0,
            )}%`}</S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`${parsePercentageText(financialTablePeriod?.cogs.growth || 0)}%`}
              <S.PercentageArrow isPositive={(financialTablePeriod?.cogs.growth || 0) >= 0} />
            </S.DataText>
          </S.Table.Data>
        </S.Table.Row>

        <S.Table.Row borderBottom>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText>Marketing</S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(financialTableComparePeriod?.marketing.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(financialTablePeriod?.marketing.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`${parsePercentageText(financialTablePeriod?.marketing.percentage || 0)}%`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`${parsePercentageText(financialTablePeriod?.cogs.growth || 0)}%`}
              <S.PercentageArrow isPositive={(financialTablePeriod?.marketing.growth || 0) >= 0} />
            </S.DataText>
          </S.Table.Data>
        </S.Table.Row>

        <S.Table.Row borderBottom>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText>Taxas de checkout / tarifas</S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(financialTableComparePeriod?.sales_fee.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(financialTablePeriod?.sales_fee.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`${parsePercentageText(financialTablePeriod?.sales_fee.percentage || 0)}%`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`${parsePercentageText(financialTablePeriod?.sales_fee.growth || 0)}%`}
              <S.PercentageArrow isPositive={(financialTablePeriod?.sales_fee.growth || 0) >= 0} />
            </S.DataText>
          </S.Table.Data>
        </S.Table.Row>

        <S.Table.Row borderBottom>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText>Taxas de gateways</S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(financialTableComparePeriod?.gateway_fee.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(financialTablePeriod?.gateway_fee.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`${parsePercentageText(financialTablePeriod?.gateway_fee.percentage || 0)}%`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`${parsePercentageText(financialTablePeriod?.gateway_fee.growth || 0)}%`}
              <S.PercentageArrow
                isPositive={(financialTablePeriod?.gateway_fee.growth || 0) >= 0}
              />
            </S.DataText>
          </S.Table.Data>
        </S.Table.Row>

        <S.Table.Row borderBottom>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText>Despesas de envio</S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(
                financialTableComparePeriod?.shipping_cost_owner.amount || 0,
                2,
              )}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(financialTablePeriod?.shipping_cost_owner.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`${parsePercentageText(financialTablePeriod?.shipping_cost_owner.percentage || 0)}%`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`${parsePercentageText(financialTablePeriod?.shipping_cost_owner.growth || 0)}%`}
              <S.PercentageArrow
                isPositive={(financialTablePeriod?.shipping_cost_owner.growth || 0) >= 0}
              />
            </S.DataText>
          </S.Table.Data>
        </S.Table.Row>

        <S.Table.Row borderBottom>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText>Impostos</S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(financialTableComparePeriod?.tax.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(financialTablePeriod?.tax.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`${parsePercentageText(financialTablePeriod?.tax.percentage || 0)}%`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`${parsePercentageText(financialTablePeriod?.tax.growth || 0)}%`}
              <S.PercentageArrow isPositive={(financialTablePeriod?.tax.growth || 0) >= 0} />
            </S.DataText>
          </S.Table.Data>
        </S.Table.Row>

        <S.CustomSpendsRow
          borderBottom={!isCustomSpendsExpanded}
          onClick={handleCustomSpendsExpanded}
          isExpanded={isCustomSpendsExpanded}
        >
          <S.Table.Data>
            {isCustomSpendsExpanded ? (
              <S.CustomSpendsIconWrapper>
                <ChevronDown size={16} color={theme.colors.gray[1]} />
              </S.CustomSpendsIconWrapper>
            ) : (
              <S.CustomSpendsIconWrapper>
                <ChevronRight size={16} color={theme.colors.gray[1]} />
              </S.CustomSpendsIconWrapper>
            )}
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText>Outras despesas</S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(financialTableComparePeriod?.other_spends.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(financialTablePeriod?.other_spends.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`${parsePercentageText(financialTablePeriod?.other_spends.percentage || 0)}%`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`${parsePercentageText(financialTablePeriod?.other_spends.growth || 0)}%`}
              <S.PercentageArrow
                isPositive={(financialTablePeriod?.other_spends.growth || 0) >= 0}
              />
            </S.DataText>
          </S.Table.Data>
        </S.CustomSpendsRow>

        {isCustomSpendsExpanded && !foundProductFilterData && (
          <>
            {allCustomValues.map(customValue => (
              <S.CustomSpendRow isExpanded={isCustomSpendsExpanded} borderBottom>
                <S.Table.Data />
                <S.Table.Data>
                  <S.DataText>
                    <S.ColorBullet color={customValue.hex_color} />
                    {customValue.name}
                  </S.DataText>
                </S.Table.Data>
                <S.Table.Data>
                  <S.DataText right>
                    {`R$ ${getCustomValueAmount(customValue.alias_id, comparedCustomValues)}`}
                  </S.DataText>
                </S.Table.Data>
                <S.Table.Data>
                  <S.DataText right>
                    {`R$ ${getCustomValueAmount(customValue.alias_id, customValues)}`}
                  </S.DataText>
                </S.Table.Data>
                <S.Table.Data>
                  <S.DataText right>
                    {`${getCustomValuePercentage(customValue.alias_id, customValues)}%`}
                  </S.DataText>
                </S.Table.Data>
                <S.Table.Data />
              </S.CustomSpendRow>
            ))}
          </>
        )}

        <S.Table.Row borderBottom>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText>Receitas financeiras</S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(
                financialTableComparePeriod?.financial_income.amount || 0,
                2,
              )}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`R$ ${numberFormatter(financialTablePeriod?.financial_income.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`${parsePercentageText(financialTablePeriod?.financial_income.percentage || 0)}%`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText right>
              {`${parsePercentageText(financialTablePeriod?.financial_income.growth || 0)}%`}
              <S.PercentageArrow
                isPositive={(financialTablePeriod?.financial_income.growth || 0) >= 0}
              />
            </S.DataText>
          </S.Table.Data>
        </S.Table.Row>

        <S.Table.Row>
          <S.Table.Data />
          <S.Table.Data>
            <S.DataText highlight>Lucro Líquido</S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText
              highlight
              right
              isPositive={(financialTableComparePeriod?.net_profit.amount || 0) > 0}
              isNegative={(financialTableComparePeriod?.net_profit.amount || 0) < 0}
            >
              {`R$ ${numberFormatter(financialTableComparePeriod?.net_profit.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText
              highlight
              right
              isPositive={(financialTablePeriod?.net_profit.amount || 0) > 0}
              isNegative={(financialTablePeriod?.net_profit.amount || 0) < 0}
            >
              {`R$ ${numberFormatter(financialTablePeriod?.net_profit.amount || 0, 2)}`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText
              highlight
              right
              isPositive={(financialTablePeriod?.net_profit.percentage || 0) > 0}
              isNegative={(financialTablePeriod?.net_profit.percentage || 0) < 0}
            >
              {`${parsePercentageText(financialTablePeriod?.net_profit.percentage || 0)}%`}
            </S.DataText>
          </S.Table.Data>
          <S.Table.Data>
            <S.DataText
              highlight
              right
              isPositive={(financialTablePeriod?.net_profit.growth || 0) > 0}
              isNegative={(financialTablePeriod?.net_profit.growth || 0) < 0}
            >
              {`${parsePercentageText(financialTablePeriod?.net_profit.growth || 0)}%`}
              <S.PercentageArrow isPositive />
            </S.DataText>
          </S.Table.Data>
        </S.Table.Row>
      </S.Table.Body>
    </S.Table>
  );
};

export default PeriodTable;
