import React from 'react';

import Header from '@components/Dashboard/Boleto/Header';
import Table from '@components/Dashboard/Boleto/Table';
import { useBoleto } from '@helpers/hooks/pages/dashboard/boleto/useBoleto';
import PageError from '@components/common/core/Utils/PageError';
import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const { analytics, selectedStore, user } = useConfig();

  React.useEffect(() => {
    analytics?.track(
      'Boleto Page Viewed',
      { email: user?.email },
      { context: { groupId: selectedStore?.alias_id } },
    );
  }, [selectedStore, analytics, user]);

  const { isValidatingBoletoData, isBoletoDataError, mutateBoletoData } = useBoleto();

  if (isBoletoDataError && !isValidatingBoletoData) {
    return <PageError mutate={mutateBoletoData} />;
  }

  return (
    <S.Container>
      <Header />
      <Table />
    </S.Container>
  );
};

export default Wrapper;
