import styled, { css } from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import TextBase from '@components/common/core/DataDisplay/Text';
import { IMoreOptionsButtonProps } from '@domain/interfaces/dashboard/Campaigns/ICampaigns';
import { IActionButtonProps } from '@domain/interfaces/components/IActionButton';

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`;

export const ActionButton = styled.button<IActionButtonProps>`
  border: none;
  background-color: transparent;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.font};
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;

  ${({ isDeleteButton, theme }) =>
    isDeleteButton &&
    css`
      color: ${theme.colors.danger.default};
    `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[6]};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.gray[6]};
    filter: brightness(0.9);
  }
`;

export const MoreOptionText = styled(TextBase)``;

export const Bullet = styled.span`
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.font};
`;

export const OptionsIcon = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 50%;
  gap: 4px;
  padding: 3px;
  box-sizing: border-box;
`;

export const MoreOptionsButton = styled.button<IMoreOptionsButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  height: 42px;
  color: ${({ theme }) => theme.colors.font};
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 400;
  border-radius: 5px;
  background-color: unset;
  border: 1px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s;
  gap: 8px;
  opacity: 0;
  z-index: -1;

  ${({ enabled }) =>
    enabled &&
    css`
      opacity: 1;
      z-index: unset;
    `};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.font};
  }
`;

export const Input = styled(TextFieldBase)`
  max-width: 324px;
`;

export const ButtonIconWrapper = styled.span`
  width: 26px;
  height: 26px;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NewTagButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  height: 42px;
  color: ${({ theme }) => theme.colors.font};
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 400;
  border-radius: 5px;
  background-color: unset;
  border: 1px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s;
  gap: 8px;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.font};
  }
`;

export const Button = styled(ButtonBase)`
  & > span {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export const Title = styled(HeadingBase)``;

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 790px) {
    justify-content: flex-start;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media only screen and (max-width: 448px) {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
`;

export const TitleAndButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media only screen and (max-width: 790px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 16px;
`;

export const FilterAndInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;
