import React from 'react';
import FacebookLogin from '@greatsumini/react-facebook-login';

import FacebookIcon from '@assets/components/FacebookButton/facebook.png';

import LimitationModal from '@components/common/core/Utils/LimitationModal';

import * as S from './styles';

interface IFacebookButtonProps {
  hasFacebookProfile?: boolean;
  handleAuthenticateModalOpen?: () => void;
  width?: number;
  onSuccess?: (data: Record<string, any> | undefined) => void;
  onProfileSuccess?: (data: Record<string, any> | undefined) => void;
  onFail?: () => void;
}

const ExternalFacebookButton: React.FC<IFacebookButtonProps> = ({
  hasFacebookProfile,
  handleAuthenticateModalOpen,
  width = 267,
  onSuccess,
  onProfileSuccess,
  onFail,
}) => {
  const [isLimitationModalOpen, setIsLimitationModalOpen] = React.useState<boolean>(false);

  const handleLimitationModalOpen = React.useCallback(() => {
    setIsLimitationModalOpen(state => !state);
  }, []);

  const onFacebookButtonClick = React.useCallback(() => {
    handleAuthenticateModalOpen?.();
  }, [handleAuthenticateModalOpen]);

  return (
    <>
      <FacebookLogin
        appId="2444384415680634"
        scope="ads_read,public_profile,business_management,ads_management"
        initParams={{
          localStorage: false,
          version: 'v9.0',
        }}
        onSuccess={onSuccess}
        onFail={onFail}
        onProfileSuccess={onProfileSuccess}
        render={({ onClick }) => (
          <S.FacebookLoginButton
            width={width}
            onClick={hasFacebookProfile ? onFacebookButtonClick : onClick}
          >
            <S.FacebookIcon src={FacebookIcon} alt="facebook-login-icon" />
            <S.Text>Continuar com Facebook</S.Text>
          </S.FacebookLoginButton>
        )}
      />

      <LimitationModal
        isOpen={isLimitationModalOpen}
        toggle={handleLimitationModalOpen}
        imageSrc="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/temp/limitation-temp.svg"
        title="Limite de perfis do Facebook atingido"
        description="Escolha um plano que te permita adicionar mais perfis do Facebook para que consiga usufruir o melhor da ferramenta."
        trackingPropertyName="MAX_FACEBOOK_PROFILES_REACHED"
      />
    </>
  );
};

export default ExternalFacebookButton;
