import React from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import { EHeadingSize } from '@domain/enums/components/EHeading';

import { useMessage } from '@helpers/hooks/pages/dashboard/automations/useMessage';
import { useConfig } from '@helpers/hooks/useConfig';

import GoBack from '@components/common/core/Utils/GoBack';
import MessageOptions from './MessageOptions';
import Header from './Header';
import CreateMessageModal from './CreateMessageModal';
import UpdateMessageModal from './UpdateMessageModal';
import Message from './Message';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Messages: React.FC = () => {
  const history = useHistory();

  const { storeAliasId } = useParams<IParams>();
  const { analytics } = useConfig();

  const { messages, isLoadingMessages, isValidatingMessages } = useMessage();

  const handleGoBack = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/marketing/automations`);
  }, [history, storeAliasId]);

  React.useEffect(() => {
    analytics?.track(
      'Automation Tool Manage Messages Page Viewed',
      {},
      { context: { groupId: storeAliasId } },
    );
  }, [storeAliasId, analytics]);

  const isLoading = isLoadingMessages || isValidatingMessages;

  const hasMessages = Boolean(messages?.length) && !isLoading;

  return (
    <S.Wrapper>
      <GoBack text="Voltar às automações" onClick={handleGoBack} />
      <S.Container>
        <MessageOptions />

        <S.ContentWrapper>
          <Header />

          <S.MessagesWrapper>
            {isLoading && <SkeletonLoading />}

            {hasMessages &&
              messages?.map(message => {
                return <Message key={message.id} message={message} />;
              })}

            {!hasMessages && (
              <S.EmptyData
                contentSize={EHeadingSize.H5}
                content="Não encontramos nenhuma mensagem"
              />
            )}
          </S.MessagesWrapper>

          <UpdateMessageModal />

          <CreateMessageModal />
        </S.ContentWrapper>
      </S.Container>
    </S.Wrapper>
  );
};

export default Messages;
