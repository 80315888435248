import React from 'react';

import { ETextWeight } from '@domain/enums/components/EText';
import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const ChartTooltip: React.FC<any> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload.pop();

    const parseDate = (date: string): string => {
      const [year, month, day] = date.split('-');

      return `${day}/${month}/${year}`;
    };

    return (
      <S.TooltipCard>
        <S.TooltipTitle weight={ETextWeight.SEMIBOLD}>
          {parseDate(data.payload.name)}
        </S.TooltipTitle>

        <S.LabelWrapper>
          <S.LabelText>{`R$ ${numberFormatter(data.payload.value, 2)}`}</S.LabelText>
        </S.LabelWrapper>
      </S.TooltipCard>
    );
  }

  return null;
};

export default ChartTooltip;
