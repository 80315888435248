import React from 'react';

import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { useMediaQuery } from '@helpers/hooks/useMediaQuery';
import { useConfig } from '@helpers/hooks/useConfig';
import { useRetrospectiveData } from '@helpers/hooks/useRetrospectiveData';

import { FINANCIAL_FILTER_CONFIG } from '@helpers/utils/filters/financialFilter';

import PageError from '@components/common/core/Utils/PageError';
import Filter from '@components/common/core/Utils/Filter';
import Header from '../Header';
import OverviewCards from '../OverviewCards';
import Chart from '../Chart';
import ProfitCard from '../ProfitCard';
import TopThreeProducts from '../TopThreeProducts';
import Metrics from '../Metrics';
import StoreGoal from '../StoreGoal';
import NewStoreGoalSideModal from '../NewStoreGoalSideModal';
import EditStoreGoalSideModal from '../EditStoreGoalSideModal';
import PaymentMethodsOverview from '../PaymentMethodsOverview';
import PaymentMethodsCards from '../PaymentMethodsCards';
import CustomValues from '../CustomValues';
import CustomValuesSideModal from '../CustomValuesSideModal';
import NewCustomAdsSideModal from '../NewCustomAdsSideModal';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const { analytics, selectedStore, user } = useConfig();

  const { trackFinancialDashboardView } = useRetrospectiveData();

  const {
    isFinancialDashboardProviderError,
    isValidatingFinancialDashboardProvider,
    mutate,
    filterData,
    handleFilterData,
  } = useFinancialDashboard();

  const mobileWidth = useMediaQuery('1360px');

  React.useEffect(() => {
    analytics?.track(
      'Financial Dashboard Viewed',
      {
        email: user?.email,
      },
      { context: { groupId: selectedStore?.alias_id } },
    );
    analytics?.trackHubSpot(storeAliasId, { financial_dashboard_viewed: true });
  }, [selectedStore, analytics, user, storeAliasId]);

  React.useEffect(() => {
    trackFinancialDashboardView();
  }, [trackFinancialDashboardView]);

  if (isFinancialDashboardProviderError && !isValidatingFinancialDashboardProvider) {
    return <PageError mutate={mutate} />;
  }

  return (
    <S.Container>
      <Header filterData={filterData} addNewFilterData={handleFilterData} />

      {Boolean(filterData.length) && (
        <Filter
          config={FINANCIAL_FILTER_CONFIG}
          handleFilterData={handleFilterData}
          filterData={filterData}
        />
      )}

      {mobileWidth && <ProfitCard />}

      <OverviewCards />

      <S.Wrapper>
        <S.FirstSection>
          <Chart />

          <S.MetricsAndStoreGoalAndPaymentMethodsWrapper>
            <Metrics />

            <S.StoreGoalAndPaymentMethodsWrapper>
              {mobileWidth ? <PaymentMethodsCards /> : <StoreGoal />}

              <PaymentMethodsOverview />
            </S.StoreGoalAndPaymentMethodsWrapper>
          </S.MetricsAndStoreGoalAndPaymentMethodsWrapper>

          {!mobileWidth && <PaymentMethodsCards />}
        </S.FirstSection>

        <S.SecondSection>
          {!mobileWidth && <ProfitCard />}

          <TopThreeProducts />

          {mobileWidth && <StoreGoal />}

          <CustomValues />
        </S.SecondSection>
      </S.Wrapper>

      <NewStoreGoalSideModal />
      <EditStoreGoalSideModal />
      <CustomValuesSideModal />
      <NewCustomAdsSideModal />
    </S.Container>
  );
};

export default Wrapper;
