import styled from 'styled-components/macro';

import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

export const UnderstoodButton = styled.button`
  background-color: unset;
  border: unset;
  padding: unset;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.green.default};
  font-size: 0.875rem;
  width: fit-content;
  font-weight: 500;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Description = styled(TextBase)`
  line-height: 120%;
  font-size: 0.875rem;
`;

export const DescriptionWrapper = styled.div`
  max-width: 280px;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const HeaderText = styled(HeadingBase)`
  font-size: 0.875rem;
  font-weight: 500;
  text-align: right;
`;

export const PopoverContent = styled(CustomPopoverBase.Content)`
  background-color: ${({ theme }) => theme.colors.black.light};
`;

export const Popover = styled(CustomPopoverBase)``;
