import React from 'react';
// import { LineChart } from '@profitfy/pakkun-icons';
// import { useTheme } from 'styled-components/macro';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { ISpend } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/ISummaryReport';

import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const SpendAmount: React.FC<ICampaignProps> = ({ data }) => {
  const spendAmountData: ISpend = data;

  // const theme = useTheme();

  return (
    <S.Wrapper>
      <S.TotalAmountText>
        {`(-) R$ ${numberFormatter(spendAmountData.total_amount, 2)}`}
      </S.TotalAmountText>
      {/* <S.ForeCastAmount>
        <LineChart outline size={12} color={theme.colors.gray[2]} />
        R$ 294,28
      </S.ForeCastAmount> */}
      <S.TotalLabel>Total</S.TotalLabel>
    </S.Wrapper>
  );
};

export default SpendAmount;
