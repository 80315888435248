import React from 'react';
import { useParams } from 'react-router-dom';
import { Lpqv } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { lpqvSchema } from '@helpers/validators/dashboard/integrationCenter/lpqvCredentials';

import lpqvAuthService from '@services/pages/dashboard/integrationCenter/storeProvider/lpqv/lpqvAuth';

import Text from '@components/common/core/DataDisplay/Text';
import Form from '@components/common/core/Inputs/Form';
import ActiveIntegrationFooter from '@components/Dashboard/IntegrationCenter/Sidemodal/ActiveIntegrationFooter';

import * as S from './styles';

const IntegrateForm: React.FC = () => {
  const { mutateStore } = useStoreConfig();
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(lpqvSchema),
  });

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [storeSlugValue, setStoreSlugValue] = React.useState<string>('');
  const [authTokenValue, setAuthTokenValue] = React.useState<string>('');

  const storeSlugRegister = register('provider_id');
  const authTokenRegister = register('auth_token');

  const onStoreSlugChange = React.useCallback(
    event => {
      storeSlugRegister.onChange(event);

      setStoreSlugValue(event.target.value);
    },
    [storeSlugRegister],
  );

  const onAuthTokenChange = React.useCallback(
    event => {
      authTokenRegister.onChange(event);

      setAuthTokenValue(event.target.value);
    },
    [authTokenRegister],
  );

  const onSubmit = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      const data = {
        provider_id: formData.provider_id,
        auth_token: formData.auth_token,
      };

      try {
        await lpqvAuthService.createCredential({ storeAliasId, data });

        toast.success('Credencial criada com sucesso.');

        mutateStore();

        setIsSubmittingForm(false);
      } catch (error: any) {
        setIsSubmittingForm(false);
        toast.error(getTranslatedIntegrationsError(error));
      }
    },
    [storeAliasId, toast, mutateStore],
  );

  return (
    <S.ContentWrapper>
      <S.Header>
        <Lpqv size={52} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com LPQV
        </S.Heading>
      </S.Header>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>
        <S.FunctionalityDescriptionText>
          A integração com sua plataforma de e-commerce serve para puxar seus dados de loja.
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <S.TutorialWrapper>
        <S.TutorialHeading type={EHeadingSize.H5}>Tutorial de integração</S.TutorialHeading>
        <S.TutorialText>
          Informe o Slug da Loja e o Token.. Para saber como obter essas informações,{' '}
        </S.TutorialText>
        <S.ArticleLink
          href="https://ajuda.profitfy.me/pt-BR/articles/8076401-como-integrar-com-lpqv"
          target="_blank"
        >
          <strong>veja o nosso tutorial.</strong>
        </S.ArticleLink>
      </S.TutorialWrapper>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label htmlFor="store-slug">Slug da Loja</S.Label>
          <S.Input
            {...storeSlugRegister}
            type="text"
            id="store-slug"
            onChange={onStoreSlugChange}
            value={storeSlugValue}
            isError={Boolean(errors.provider_id)}
          />
          {errors.provider_id && <Text isErrorFeedback>{errors.provider_id.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label htmlFor="auth-token">Token</S.Label>
          <S.Input
            {...authTokenRegister}
            type="text"
            id="auth-token"
            onChange={onAuthTokenChange}
            value={authTokenValue}
            isError={Boolean(errors.auth_token)}
          />
          {errors.auth_token && <Text isErrorFeedback>{errors.auth_token.message}</Text>}
        </S.InputGroup>

        <ActiveIntegrationFooter isIntegrating={isSubmittingForm} />
      </Form>
    </S.ContentWrapper>
  );
};

export default IntegrateForm;
