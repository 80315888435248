import React from 'react';

import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import { IOrder } from '@domain/interfaces/common/orders/IOrders';
import {
  IGatewayProvider,
  IGatewayProviderProps,
} from '@domain/interfaces/dashboard/Feed/IGateway';

import { useToast } from '@helpers/hooks/useToast';
import { useDate } from '@helpers/hooks/useDate';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import { GATEWAYS } from '@constants/common/integrations';

import feedService from '@services/pages/dashboard/feed/feed';

const GatewayContext = React.createContext<IGatewayProvider | null>(null);

export const GatewayProvider: React.FC<IGatewayProviderProps> = ({ gateway, children }) => {
  const gatewayIntegration = GATEWAYS.find(integration => integration.identifier === gateway);

  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { format, utcToZonedTime, subDays } = useDate();
  const { currentSynchronizations, synchronizationsQueue } = useSynchronization();
  const { mutateFeedQuantity, mutateFeedGateways } = useStoreConfig();
  const { analytics, user } = useConfig();

  const [isLoadingGatewayOrders, setIsLoadingGatewayOrders] = React.useState<boolean>(false);
  const [gatewayOrders, setGatewayOrders] = React.useState<Array<IOrder>>([]);
  const [gatewayOrdersPage, setGatewayOrdersPage] = React.useState<number>(0);
  const [gatewayOrdersPageCount, setGatewayOrdersPageCount] = React.useState<number>(1);
  const [isSyncingGateway, setIsSyncingGateway] = React.useState<boolean>(false);

  const loadGatewayOrders = React.useCallback(async () => {
    setIsLoadingGatewayOrders(true);

    try {
      const { data } = await feedService.listFeedOrdersPromise({
        storeAliasId,
        page: gatewayOrdersPage,
        startDate: format(subDays(utcToZonedTime(new Date()), 29), 'yyyy-MM-dd'),
        endDate: format(utcToZonedTime(new Date()), 'yyyy-MM-dd'),
        filter: {
          gateway_name: gateway,
          status: 'NO_INFORMATION',
          type: 'NO_INFORMATION',
          order_field: 'provider_created_at',
        },
      });

      if (data) {
        setGatewayOrders(currentNoInformationOrders => [
          ...currentNoInformationOrders,
          ...data.orders,
        ]);
        setGatewayOrdersPageCount(data.total_pages);
      }

      setIsLoadingGatewayOrders(false);
    } catch (error: any) {
      setIsLoadingGatewayOrders(false);
      toast.error(error?.response?.data?.message);
    }
  }, [format, gatewayOrdersPage, storeAliasId, subDays, toast, utcToZonedTime, gateway]);

  const handleLoadMore = React.useCallback(() => {
    setGatewayOrdersPage(oldPage => oldPage + 1);
  }, []);

  const resetInfiniteScroll = React.useCallback(() => {
    setGatewayOrders([]);
    setGatewayOrdersPage(0);
    loadGatewayOrders();
  }, [loadGatewayOrders]);

  React.useEffect(() => {
    loadGatewayOrders();
  }, [loadGatewayOrders]);

  React.useEffect(() => {
    if (currentSynchronizations?.synchronizations.length || synchronizationsQueue?.length) {
      const foundCurrentSynchronization = currentSynchronizations?.synchronizations.find(
        synchronization => synchronization.label === gatewayIntegration?.name,
      );

      const foundSynchronizationsQueue = synchronizationsQueue.find(
        synchronization => synchronization.label === gatewayIntegration?.name,
      );

      if (isSyncingGateway) {
        if (!foundCurrentSynchronization && !foundSynchronizationsQueue) {
          mutateFeedGateways();
          mutateFeedQuantity();
          resetInfiniteScroll();
        }

        setIsSyncingGateway(
          Boolean(foundCurrentSynchronization) || Boolean(foundSynchronizationsQueue),
        );
      } else {
        setIsSyncingGateway(
          Boolean(foundCurrentSynchronization) || Boolean(foundSynchronizationsQueue),
        );
      }
    }

    if (isSyncingGateway && !currentSynchronizations && !synchronizationsQueue?.length) {
      mutateFeedGateways();
      mutateFeedQuantity();
      resetInfiniteScroll();

      setIsSyncingGateway(false);
    }
  }, [
    currentSynchronizations,
    gatewayIntegration,
    isSyncingGateway,
    mutateFeedGateways,
    mutateFeedQuantity,
    synchronizationsQueue,
    resetInfiniteScroll,
  ]);

  React.useEffect(() => {
    if (isSyncingGateway) {
      analytics?.track(
        'Task Feed Solved',
        { type: 'GATEWAY', email: user?.email },
        { context: { groupId: storeAliasId } },
      );
      analytics?.trackHubSpot(storeAliasId, { task_feed_solved: true });
    }
  }, [isSyncingGateway, analytics, storeAliasId, user]);

  const hasMore = gatewayOrdersPage + 1 < gatewayOrdersPageCount;

  return (
    <GatewayContext.Provider
      value={{
        handleLoadMore,
        hasMore,
        loadGatewayOrders,
        gatewayOrders,
        isLoadingGatewayOrders,
        gateway,
        isSyncingGateway,
      }}
    >
      {children}
    </GatewayContext.Provider>
  );
};

export const useGateway = (): IGatewayProvider => {
  const context = React.useContext(GatewayContext);

  if (!context) {
    throw new Error('useGateway must be used within GatewayContext');
  }

  return context;
};
