import React from 'react';

import { useEditKit } from '@helpers/hooks/pages/dashboard/kit/useEditKit';

import * as S from './styles';

const Submit: React.FC = () => {
  const { isUpdatingKit } = useEditKit();

  return (
    <S.Wrapper>
      <S.SubmitButton type="submit" isLoading={isUpdatingKit} disabled={isUpdatingKit}>
        Salvar alterações
      </S.SubmitButton>
    </S.Wrapper>
  );
};

export default Submit;
