import React from 'react';
import { Position } from 'react-flow-renderer';
import { QrCode } from 'phosphor-react';

import * as S from './styles';

const PixNode: React.FC = () => {
  return (
    <S.Wrapper>
      <S.IconContainer>
        <QrCode size={20} />
      </S.IconContainer>
      <S.Name>Pix Gerado</S.Name>
      <S.HandleContainer>
        <S.Handle position={Position.Bottom} type="source" />
      </S.HandleContainer>
    </S.Wrapper>
  );
};
export default PixNode;
