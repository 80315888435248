import styled, { Keyframes, keyframes } from 'styled-components/macro';

import DropdownBase from '@components/common/core/Utils/DropDown';
import TextBase from '@components/common/core/DataDisplay/Text';
import TooltipBase from '@components/common/core/Utils/CustomTooltip';
import AvatarBase from '../../DataDisplay/Avatar';

const wave = ({ theme }: any): Keyframes => keyframes`
  0% {
    width: 0;
    height: 0;
    background-color: ${theme.colors.danger.default};
  }
  100% {
    width: 12px;
    height: 12px;
    background-color: transparent;
  }
`;

export const Tooltip = styled(TooltipBase)``;

export const TooltipContent = styled(TooltipBase.Content)`
  background-color: ${({ theme }) => theme.colors.gray[6]};
`;

export const TooltipTrigger = styled(TooltipBase.Trigger)`
  margin-right: 16px;
`;

export const TooltipArrow = styled(TooltipBase.Arrow)`
  fill: ${({ theme }) => theme.colors.gray[6]};
`;

export const TooltipContentText = styled(TextBase)``;

export const FeedQuantity = styled.span`
  width: 20px;
  padding: 4px 6px;
  line-height: 120%;
  background-color: #ae2828;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 0.875rem;
  color: #fff;
  font-weight: 500;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FeedButton = styled.button`
  background-color: unset;
  border: unset;
  padding: unset;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 7px;
  gap: 8px;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 0.875rem;
  color: #fff;
  font-weight: 500;
  margin-right: 12px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
    background-color: ${({ theme }) => theme.colors.gray[6]};
    color: ${({ theme }) => theme.colors.green.default};
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const NotificationsDropdown = styled(DropdownBase)``;

export const SecondWave = styled.div`
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ${wave} 1s infinite;
  animation-delay: 0.3s;
`;

export const FirstWave = styled.div`
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ${wave} 1s infinite;
`;

export const Dot = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${({ theme }) => theme.colors.danger.default};
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
`;

export const WaveNotificationWrapper = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  top: -2px;
  right: -2px;
  overflow: hidden;
`;

export const OptionButton = styled.button`
  margin-right: 24px !important;
  cursor: pointer;
  margin: 0;
  background-color: transparent;
  border: none;
  padding: 0;
  position: relative;
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;

  & > img {
    cursor: pointer;
  }

  & > div {
    cursor: pointer;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  & > span {
    margin-left: 16px;
  }
`;

export const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px 40px;
  padding-left: 136px;
  background-color: ${({ theme }) => theme.colors.background[1]};

  @media only screen and (max-width: 768px) {
    padding: 16px 20px;
  }
`;

export const NewsButton = styled.div`
  font-family: ${({ theme }) => theme.fonts.body};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white.default};
  border-radius: 7px;
  margin-right: 32px;
  padding: 8px 12px;
  margin-right: 32px !important;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
    background-color: ${({ theme }) => theme.colors.gray[6]};
    color: ${({ theme }) => theme.colors.green.default};
  }

  &:active {
    filter: brightness(0.9);
  }

  & > div {
    z-index: 0;
  }
`;

export const CurrentStoreWrapper = styled.div`
  display: flex;
  position: relative;
  padding: 0;
  border: none;
  background-color: unset;
  cursor: pointer;
`;

export const CurrentStoreName = styled(TextBase)`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const ProfileName = styled(TextBase)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const UserDetails = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;

export const StoreAvatar = styled(AvatarBase)`
  background-color: ${({ theme }) => theme.colors.green.dark};
`;

export const HideDashboardDataIconWrapper = styled.button`
  background-color: unset;
  padding: unset;
  border: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    transition: all 0.3s;

    &:hover {
      filter: brightness(1.1);
    }

    &:active {
      filter: brightness(0.9);
    }
  }
`;
