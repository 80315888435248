import { Info } from 'phosphor-react';
import React from 'react';
import { useTheme } from 'styled-components';
import * as S from './styles';

interface IDeleteCredentialModalProps {
  isOpen: boolean;
  toggle: () => void;
  onDeleteCredential: () => void;
  isLoading: boolean;
}
const DeleteCredentialModal: React.FC<IDeleteCredentialModalProps> = ({
  isOpen,
  toggle,
  onDeleteCredential,
  isLoading,
}) => {
  const { colors } = useTheme();

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.QuestionWrapper>
        <Info size={24} color={colors.warning.default} />

        <S.Question>Deseja desativar a integração ?</S.Question>
      </S.QuestionWrapper>

      <S.Text>
        Ao desativar a integração nós iremos parar de processar os dados para a sua loja. Essa ação
        não irá remover a credencial. Caso queira remover ela, entre em contato com o nosso suporte.
      </S.Text>

      <S.ButtonsWrapper>
        <S.CancelButton onClick={toggle}>Cancelar</S.CancelButton>
        <S.ConfirmButton onClick={onDeleteCredential} isLoading={isLoading}>
          Desativar credencial
        </S.ConfirmButton>
      </S.ButtonsWrapper>
    </S.Modal>
  );
};

export default DeleteCredentialModal;
