import {
  IFinancialReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresFeeValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresFeeValues = (
  currentSelectedStoreFinancialReportData: IFinancialReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresFeeValuesResponse => {
  const currentSelectedStoreFeeAmountValue =
    currentSelectedStoreFinancialReportData?.fee?.total_amount;

  const currentSelectedStoreFeeGrowthValue = currentSelectedStoreFinancialReportData?.fee?.growth;

  const currentSelectedStoreTaxAmountValue =
    currentSelectedStoreFinancialReportData?.fee?.tax_amount;

  const currentSelectedStoreMarketPlaceFeeAmountValue =
    currentSelectedStoreFinancialReportData?.fee?.marketplace_fee_amount;

  const currentSelectedStoreCheckoutFeeAmountValue =
    currentSelectedStoreFinancialReportData?.fee?.checkout_fee_amount;

  const currentSelectedStoreGatewayFeeAmountValue =
    currentSelectedStoreFinancialReportData?.fee?.gateway_fee_amount;

  const currentSelectedStoreInfoProductFeeAmountValue =
    currentSelectedStoreFinancialReportData?.fee?.info_product_fee_amount;

  const storesFeeAmountValues = selectedStoresData.map(
    value => value.financial_report?.fee.total_amount,
  );

  const storesFeeGrowthValues = selectedStoresData.map(
    value => value.financial_report?.fee?.growth,
  );

  const storesCheckoutFeeAmountValues = selectedStoresData.map(
    value => value.financial_report?.fee?.checkout_fee_amount,
  );

  const storesGatewayFeeAmountValues = selectedStoresData.map(
    value => value.financial_report?.fee?.gateway_fee_amount,
  );

  const storesTaxAmountValues = selectedStoresData.map(
    value => value.financial_report?.fee?.tax_amount,
  );

  const storesMarketPlaceFeeAmountValues = selectedStoresData.map(
    value => value.financial_report?.fee?.marketplace_fee_amount,
  );

  const storesInfoProductFeeAmountValues = selectedStoresData.map(
    value => value.financial_report?.fee?.info_product_fee_amount,
  );

  const feeAmountValues = [...storesFeeAmountValues, currentSelectedStoreFeeAmountValue];
  const feeGrowthValues = [...storesFeeGrowthValues, currentSelectedStoreFeeGrowthValue];
  const taxAmountValues = [...storesTaxAmountValues, currentSelectedStoreTaxAmountValue];

  const checkoutFeeValues = [
    ...storesCheckoutFeeAmountValues,
    currentSelectedStoreCheckoutFeeAmountValue,
  ];

  const gatewayFeeValues = [
    ...storesGatewayFeeAmountValues,
    currentSelectedStoreGatewayFeeAmountValue,
  ];

  const marketingPlaceFeeValues = [
    ...storesMarketPlaceFeeAmountValues,
    currentSelectedStoreMarketPlaceFeeAmountValue,
  ];

  const infoProductFeeValues = [
    ...storesInfoProductFeeAmountValues,
    currentSelectedStoreInfoProductFeeAmountValue,
  ];

  const reducedCogsAmountValues = feeAmountValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const reducedCogsGrowthValues = feeGrowthValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const reducedCheckoutFeeAmountValues = checkoutFeeValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const reducedGatewayFeeAmountValues = gatewayFeeValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const reducedTaxAmountValues = taxAmountValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const reducedMarketPlaceFeeValues = marketingPlaceFeeValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const reducedInfoProductFeeValues = infoProductFeeValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  return {
    fee_amount: reducedCogsAmountValues,
    fee_growth: reducedCogsGrowthValues,
    checkout_fee_amount: reducedCheckoutFeeAmountValues,
    gateway_fee_amount: reducedGatewayFeeAmountValues,
    tax_amount: reducedTaxAmountValues,
    marketplace_fee_amount: reducedMarketPlaceFeeValues,
    info_product_fee_amount: reducedInfoProductFeeValues,
  };
};
