import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { numberFormatter } from '@helpers/masks';
import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { ETextType } from '@domain/enums/components/EText';

import PieChart from './PieChart';
import HoverCard from '../HoverCard';

import * as S from './styles';

const Pix: React.FC = () => {
  const { financialDetailsReport } = useFinancialDashboard();

  const { pix } = financialDetailsReport;

  return (
    <S.Card>
      <S.Content>
        <S.Title fontWeight={EHeadingWeight.REGULAR} type={EHeadingSize.H6}>
          Pix
        </S.Title>

        <S.DataWrapper>
          <S.ChartWrapper>
            <PieChart />
            <S.TextAndPercentageWrapper>
              <S.ChartText>Conversão</S.ChartText>
              <S.ChartPercentage>{`${numberFormatter(
                pix.conversion,
                0,
                true,
              )}%`}</S.ChartPercentage>
            </S.TextAndPercentageWrapper>
          </S.ChartWrapper>

          <S.OrdersDataWrapper>
            <S.OrdersStatusWrapper>
              <S.StatusWrapper>
                <S.StatusText>Aprovados</S.StatusText>
              </S.StatusWrapper>

              <HoverCard amount={pix.paid.amount} quantity={pix.paid.quantity}>
                <S.OrdersAmount type={ETextType.P}>{`R$ ${numberFormatter(pix.paid.amount, 2)} (${
                  pix.paid.quantity
                })`}</S.OrdersAmount>
              </HoverCard>
            </S.OrdersStatusWrapper>

            <S.OrdersStatusWrapper>
              <S.StatusWrapper>
                <S.StatusText>Pendentes</S.StatusText>
              </S.StatusWrapper>

              <HoverCard amount={pix.pending.amount} quantity={pix.pending.quantity}>
                <S.OrdersAmount type={ETextType.P}>{`R$ ${numberFormatter(
                  pix.pending.amount,
                  2,
                )} (${pix.pending.quantity})`}</S.OrdersAmount>
              </HoverCard>
            </S.OrdersStatusWrapper>

            <S.OrdersStatusWrapper>
              <S.StatusWrapper>
                <S.StatusText>Cancelados</S.StatusText>
              </S.StatusWrapper>

              <HoverCard amount={pix.canceled.amount} quantity={pix.canceled.quantity}>
                <S.OrdersAmount type={ETextType.P}>{`R$ ${numberFormatter(
                  pix.canceled.amount,
                  2,
                )} (${pix.canceled.quantity})`}</S.OrdersAmount>
              </HoverCard>
            </S.OrdersStatusWrapper>
          </S.OrdersDataWrapper>
        </S.DataWrapper>
      </S.Content>
    </S.Card>
  );
};

export default Pix;
