import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import LabelBase from '@components/common/core/DataDisplay/Label';

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 32px;
`;

export const Input = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const Form = styled.form``;

export const CancelButton = styled(ButtonBase)``;

export const DeleteButton = styled(ButtonBase)``;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
`;

export const DescriptionText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const Body = styled.div``;

export const Title = styled(TextBase)`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
`;

export const Modal = styled(ModalBase)`
  max-width: 464px;
  width: 100%;
  box-sizing: border-box;
`;
