import React from 'react';
import { useParams } from 'react-router-dom';

import { EDropshippingProvider } from '@domain/enums/common/integrations/EDropshippingProvider';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import nuvemShopService from '@services/pages/dashboard/integrationCenter/marketplace/nuvemShop/nuvemShopAuth';
import IntegrateForm from './IntegrateForm';
import NuvemShopConfig from './NuvemShopConfig';

import SkeletonLoading from './SkeletonLoading';

const NuvemShop: React.FC = () => {
  const { storeIntegrations } = useStoreConfig();
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();

  const [hasNuvemShopCredential, setHasNuvemShopCredential] = React.useState<boolean>(false);
  const [isGettingNuvemShopCredential, setIsGettingNuvemShopCredential] = React.useState<boolean>(
    false,
  );

  const hasNuvemShopIntegration = Boolean(
    storeIntegrations?.store_front.find(
      integration => integration.name === EDropshippingProvider.NUVEM_SHOP && integration.is_active,
    ),
  );

  const getNuvemShopCredential = React.useCallback(async (): Promise<any> => {
    setIsGettingNuvemShopCredential(true);

    try {
      const { data } = await nuvemShopService.getNuvemShopCredentialPromise({ storeAliasId });

      if (data.nuvem_shop_credential.is_active) {
        setHasNuvemShopCredential(true);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsGettingNuvemShopCredential(false);
    }
  }, [storeAliasId, toast]);

  React.useEffect(() => {
    if (hasNuvemShopIntegration) {
      getNuvemShopCredential();
    }
  }, [getNuvemShopCredential, hasNuvemShopIntegration]);

  if (isGettingNuvemShopCredential) {
    return <SkeletonLoading />;
  }

  if (hasNuvemShopCredential) {
    return <NuvemShopConfig />;
  }

  return <IntegrateForm />;
};

export default NuvemShop;
