import styled from 'styled-components/macro';

import FormBase from '@components/common/core/Inputs/Form';

export const Wrapper = styled(FormBase)`
  max-width: 388px;
  width: 100%;
  margin: 0 auto;
  margin-top: 48px;
`;
