import styled, { css } from 'styled-components/macro';

import {
  ITableDataProps,
  IMoreOptionsWrapperProps,
} from '@domain/interfaces/dashboard/CustomValues/IBillingTable';
import { EHeadAlign } from '@domain/enums/components/ETable';
import BadgeBase from '@components/common/core/DataDisplay/Badge';
import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';
import { IActionButtonProps } from '@domain/interfaces/components/IActionButton';

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`;

export const Popover = styled(CustomPopoverBase)``;

export const OptionDot = styled.span`
  display: block;
  width: 4px;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.gray[2]};
  border-radius: 50%;
`;

export const MoreOptionsWrapper = styled.div<IMoreOptionsWrapperProps>`
  width: 26px;
  height: 26px;
  align-items: center;
  justify-content: center;
  gap: 2px;
  opacity: 0;
  display: flex;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  border-radius: 50%;
  transition: filter 0.3s;
  border: unset;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  ${({ isHovering }) =>
    isHovering &&
    css`
      opacity: 1;
    `}
`;

export const ActionButton = styled.button<IActionButtonProps>`
  border: none;
  background-color: transparent;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.font};
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;

  ${({ isDeleteButton, theme }) =>
    isDeleteButton &&
    css`
      color: ${theme.colors.danger.default};
    `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[6]};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.gray[6]};
    filter: brightness(0.9);
  }
`;

export const ActionsButton = styled.button<IMoreOptionsWrapperProps>`
  width: 26px;
  height: 26px;
  align-items: center;
  justify-content: center;
  gap: 2px;
  opacity: 0;
  display: flex;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  border-radius: 50%;
  transition: filter 0.3s;
  border: unset;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  ${({ isHovering }) =>
    isHovering &&
    css`
      opacity: 1;
    `}
`;

export const Badge = styled(BadgeBase)`
  margin: 0 auto;
`;

export const TableData = styled.td<ITableDataProps>`
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.font};

  ${({ textAlign }) =>
    textAlign === EHeadAlign.CENTER &&
    css`
      text-align: center;
    `};

  ${({ textAlign }) =>
    textAlign === EHeadAlign.RIGHT &&
    css`
      text-align: right;
    `};
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.colors.background[3]};
`;
