import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ELoginMethod } from '@domain/enums/common/user/ELoginMethod';

import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';

import { passwordSchema } from '@helpers/validators/dashboard/profile/password';

import personalInfoService from '@services/pages/auth/personalInfo';

import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const Password: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(passwordSchema) });
  const { toast } = useToast();
  const { handleAuthorization, user } = useConfig();

  const [currentPasswordValue, setCurrentPasswordValue] = React.useState<string>('');
  const [newPasswordValue, setNewPasswordValue] = React.useState<string>('');
  const [confirmNewPasswordValue, setConfirmNewPasswordValue] = React.useState<string>('');
  const [isUpdatingPassword, setIsUpdatingPassword] = React.useState<boolean>(false);

  const currentPasswordRegister = register('current_password');
  const newPasswordRegister = register('new_password');
  const confirmNewPasswordRegister = register('confirm_new_password');

  const onSubmit = React.useCallback(
    async data => {
      setIsUpdatingPassword(true);

      try {
        const { data: updatePasswordData } = await personalInfoService.updatePassword({
          data: {
            current_password: data?.current_password,
            new_password: data?.new_password,
            new_password_confirmation: data?.confirm_new_password,
          },
        });

        handleAuthorization(updatePasswordData?.access_token);

        toast.success('Senha alterada com sucesso!');

        setIsUpdatingPassword(false);
      } catch (error: any) {
        setIsUpdatingPassword(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [toast, handleAuthorization],
  );

  const onCurrentPasswordChange = React.useCallback(
    event => {
      currentPasswordRegister.onChange(event);

      setCurrentPasswordValue(event.target.value);
    },
    [currentPasswordRegister],
  );

  const onNewPasswordValueChange = React.useCallback(
    event => {
      newPasswordRegister.onChange(event);

      setNewPasswordValue(event.target.value);
    },
    [newPasswordRegister],
  );

  const onConfirmNewPasswordValueChange = React.useCallback(
    event => {
      confirmNewPasswordRegister.onChange(event);

      setConfirmNewPasswordValue(event.target.value);
    },
    [confirmNewPasswordRegister],
  );

  // const isSocialLogin = user?.login_method === ELoginMethod.SOCIAL;
  const isSocialLogin = false;

  return (
    <S.Wrapper>
      <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
        {isSocialLogin ? 'Criar senha' : 'Alterar senha'}
      </S.Title>

      {isSocialLogin ? (
        <>
          <S.Description>
            Ao criar uma nova senha, será mantida a possibilidade de se autenticar na Profitfy
            utilizando a rede social, podendo realizar a autenticação tanto com a senha quanto com a
            rede social.
          </S.Description>

          <S.NewPasswordButton type="button">Criar nova senha</S.NewPasswordButton>
        </>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>Senha atual</S.Label>
            <S.Input
              {...currentPasswordRegister}
              type="password"
              value={currentPasswordValue}
              onChange={onCurrentPasswordChange}
              isError={errors?.current_password}
              placeholder="Insira sua senha atual"
            />
            {errors?.current_password && (
              <Text isErrorFeedback>{errors?.current_password?.message}</Text>
            )}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Nova senha</S.Label>
            <S.Input
              {...newPasswordRegister}
              type="password"
              value={newPasswordValue}
              onChange={onNewPasswordValueChange}
              isError={errors?.new_password}
              placeholder="Insira sua nova senha"
            />
            {errors?.new_password && <Text isErrorFeedback>{errors?.new_password?.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Confirmar nova senha</S.Label>
            <S.Input
              {...confirmNewPasswordRegister}
              type="password"
              value={confirmNewPasswordValue}
              onChange={onConfirmNewPasswordValueChange}
              isError={errors?.confirm_new_password}
              placeholder="Confirme sua nova senha"
            />
            {errors?.confirm_new_password && (
              <Text isErrorFeedback>{errors?.confirm_new_password?.message}</Text>
            )}
          </S.InputGroup>

          <S.SubmitButton isLoading={isUpdatingPassword} disabled={isUpdatingPassword}>
            Salvar alterações
          </S.SubmitButton>
        </Form>
      )}
    </S.Wrapper>
  );
};

export default Password;
