import React from 'react';

import { WarningCircle } from 'phosphor-react';

import { ETextWeight } from '@domain/enums/components/EText';
import { IUpdateShopifyCredentialModal } from '@domain/interfaces/IUpdateShopifyCredentialModal';

import SideModalContent from './SideModalContent';

import * as S from './styles';

const UpdateShopifyCredentialModal: React.FC<IUpdateShopifyCredentialModal> = ({
  isOpen,
  toggle,
  domain,
}) => {
  const [isSideModalOpen, setIsSideModalOpen] = React.useState<boolean>(false);

  const handleSideModalOpen = React.useCallback(() => {
    toggle();
    setIsSideModalOpen(currentState => !currentState);
  }, [toggle]);

  return (
    <>
      <S.Modal isOpen={isOpen} toggle={toggle}>
        <S.Header>
          <WarningCircle size={24} color="#D0425C" />
          <S.Heading weight={ETextWeight.MEDIUM}>Atualize sua credencial da Shopify</S.Heading>
        </S.Header>

        <S.Description>
          Em março, a forma de integração atual com a Shopify deixará de funcionar.
          <br />
          <br />
          Para que a Profitfy continue mantendo a coleta correta de seus dados, é necessário
          realizar uma nova integração com a Shopify.
        </S.Description>

        <S.UpdateCredentialButton onClick={handleSideModalOpen}>
          Atualizar credencial
        </S.UpdateCredentialButton>
      </S.Modal>

      <S.SideModal isOpen={isSideModalOpen} toggle={handleSideModalOpen}>
        <SideModalContent domain={domain} />
      </S.SideModal>
    </>
  );
};

export default UpdateShopifyCredentialModal;
