import styled from 'styled-components/macro';

export const CardWrapper = styled.div`
  width: 199px;
  height: 60px;
  overflow: hidden;
  border-radius: 12px;

  span {
    display: block;
  }

  @media only screen and (max-width: 1456px) {
    width: 182px;
  }

  @media only screen and (max-width: 1360px) {
    min-width: 200px;
    width: 200px;
  }
`;

export const MobileWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  padding-bottom: 8px;
  justify-content: space-between;
  gap: 16px;

  &:active {
    cursor: grabbing;
  }

  &::-webkit-scrollbar {
    height: 2px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.background[3]};
    border-radius: 25px;
  }
`;

export const Wrapper = styled.div`
  max-width: 422px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  @media only screen and (max-width: 1424px) {
    justify-content: unset;
  }

  @media only screen and (max-width: 1408px) {
    min-width: 392px;
  }

  @media only screen and (max-width: 1360px) {
    max-width: unset;
  }
`;
