import React from 'react';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { useProductCostDetails } from '@helpers/hooks/pages/dashboard/productCostDetails/productCostDetails';
import EmptyData from '@components/common/core/DataDisplay/EmptyData';
import Paginate from '@components/common/core/Utils/Paginate';
import SkeletonLoading from './SkeletonLoading';
import Row from './Row';

import * as S from './styles';

const Table: React.FC = () => {
  const {
    isLoadingVariants,
    variants,
    isValidatingVariants,
    page,
    pageCount,
    onPageChange,
  } = useProductCostDetails();

  return (
    <>
      <S.VariantsTable>
        <S.VariantsTable.Header>
          <S.VariantsTable.Row>
            <S.VariantsTable.Head>Produto</S.VariantsTable.Head>
            <S.VariantsTable.Head align={EHeadAlign.RIGHT}>Preço</S.VariantsTable.Head>
            <S.VariantsTable.Head>Moeda</S.VariantsTable.Head>
            <S.VariantsTable.Head>Custo da Variante</S.VariantsTable.Head>
            <S.VariantsTable.Head align={EHeadAlign.CENTER} />
          </S.VariantsTable.Row>
        </S.VariantsTable.Header>

        <S.VariantsTable.Body>
          {isLoadingVariants || isValidatingVariants ? (
            <SkeletonLoading />
          ) : (
            <>
              {variants?.length ? (
                <>
                  {variants?.map(variant => (
                    <Row key={variant.id} variant={variant} />
                  ))}
                </>
              ) : (
                <S.VariantsTable.Row>
                  <S.VariantsTable.Data colSpan={5} align={EHeadAlign.CENTER}>
                    <EmptyData content="Não encontramos nenhuma variante." />
                  </S.VariantsTable.Data>
                </S.VariantsTable.Row>
              )}
            </>
          )}
        </S.VariantsTable.Body>

        <S.VariantsTable.Footer>
          <S.VariantsTable.Row>
            <S.VariantsTable.Data colSpan={5} align={EHeadAlign.RIGHT}>
              <Paginate forcePage={page} onPageChange={onPageChange} pageCount={pageCount} />
            </S.VariantsTable.Data>
          </S.VariantsTable.Row>
        </S.VariantsTable.Footer>
      </S.VariantsTable>
    </>
  );
};

export default Table;
