import { EPersonType } from '@domain/enums/common/personType/EPersonType';
import { ICreateAddressData, IUpdateAddressData } from '@domain/interfaces/common/address/IAddress';
import {
  ICreateLegalPersonData,
  IUpdateLegalPersonData,
} from '@domain/interfaces/common/legalPerson/ILegalPerson';
import {
  ICreateNaturalPersonData,
  IUpdateNaturalPersonData,
} from '@domain/interfaces/common/naturalPerson/INaturalPerson';

import {
  formattedZipCodeToRaw,
  getCnpjNumber,
  getCpfNumber,
  parseToRawPhone,
} from '@helpers/masks';

export const parseAddressForm = (
  data: Record<string, any>,
): ICreateAddressData | IUpdateAddressData => {
  const parsedData: ICreateAddressData | IUpdateAddressData = {
    city: data.city,
    country: 'Brasil',
    country_code: 'BR',
    neighborhood: data.neighborhood,
    state: data.state,
    state_code: data.state,
    street: data.street,
    street_number: data.street_number,
    zip_code: formattedZipCodeToRaw(data.zip_code),
    complementary: data.complementary || undefined,
  };

  return parsedData;
};

export const parsePersonalInfoForm = (
  data: Record<string, any>,
  personType: EPersonType,
):
  | ICreateLegalPersonData
  | IUpdateLegalPersonData
  | ICreateNaturalPersonData
  | IUpdateNaturalPersonData => {
  if (personType === EPersonType.LEGAL_PERSON) {
    const parsedData: ICreateLegalPersonData | IUpdateLegalPersonData = {
      cnpj: getCnpjNumber(data.document),
      company_name: data.name,
      phone_number: parseToRawPhone(data.phone_number),
    };

    return parsedData;
  }

  const parsedData: ICreateNaturalPersonData | IUpdateNaturalPersonData = {
    cpf: getCpfNumber(data.document),
    phone_number: parseToRawPhone(data.phone_number),
  };

  return parsedData;
};
