import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 430px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

export const Heading = styled(HeadingBase)`
  color: #ffffff;
`;

export const NewInstanceButton = styled(ButtonBase)`
  color: #ffffff;
`;
