import styled from 'styled-components/macro';

import CustomDatePickerBase from '@components/common/core/Utils/CustomDatePicker';

export const Wrapper = styled.div`
  padding: 24px 24px 2px 24px;
`;

export const CustomDatePicker = styled(CustomDatePickerBase)`
  & > div {
    flex-direction: column;
  }
`;
