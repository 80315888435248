/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-danger */
import React from 'react';

import { Play } from 'phosphor-react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import useWindowSize from 'react-use/lib/useWindowSize';

import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';

import { useMessage } from '@helpers/hooks/pages/dashboard/automations/useMessage';
import { useMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useMessageSettings';
import { useContent } from '@helpers/hooks/pages/dashboard/automations/useContent';

import { getAudioWaves, processText } from '@helpers/utils/dashboard/automations/preview';

import * as S from './styles';

const Preview: React.FC = () => {
  const { width: screenWidthSize } = useWindowSize();

  const { isCreatingMessage } = useMessage();
  const { contents } = useMessageSettings();
  const { textPreview, imagePreview, selectedContentType, videoPreview, pdfPreview } = useContent();

  const hasContent = Boolean(contents?.length);

  const hasSameTextContent = contents.find(savedContent => savedContent.content === textPreview);

  const hasSameImageContent = contents.find(savedContent => {
    const content = savedContent.content as File;

    if (savedContent.type === EConfigOption.IMAGE && content?.name === imagePreview?.name) {
      return true;
    }

    return false;
  });

  const hasSameVideoContent = contents.find(savedContent => {
    const content = savedContent.content as File;

    if (savedContent.type === EConfigOption.VIDEO && content?.name === imagePreview?.name) {
      return true;
    }

    return false;
  });

  const hasSamePDFContent = contents.find(savedContent => {
    const content = savedContent.content as File;

    if (savedContent.type === EConfigOption.PDF && content?.name === imagePreview?.name) {
      return true;
    }

    return false;
  });

  const blob = imagePreview ? new Blob([imagePreview], { type: 'image/png' }) : undefined;

  const previewImageSource = blob ? URL.createObjectURL(blob as Blob & { type: 'image/png' }) : '';

  const videoBlog = videoPreview ? new Blob([videoPreview], { type: 'video/mp4' }) : undefined;

  const previewVideoSource = videoBlog
    ? URL.createObjectURL(videoBlog as Blob & { type: 'video/mp4' })
    : '';

  const pdfBlob = pdfPreview ? new Blob([pdfPreview], { type: 'application/pdf' }) : undefined;

  const previewPDFSource = pdfBlob
    ? URL.createObjectURL(pdfBlob as Blob & { type: 'application/pdf' })
    : '';

  const showPreviewText =
    textPreview && !hasSameTextContent && selectedContentType !== EConfigOption.NONE;

  const showPreviewImage =
    imagePreview?.name && !hasSameImageContent && selectedContentType !== EConfigOption.NONE;

  const showPreviewVideo =
    videoPreview?.name && !hasSameVideoContent && selectedContentType !== EConfigOption.NONE;

  const showPreviewPDF =
    pdfPreview?.name && !hasSamePDFContent && selectedContentType !== EConfigOption.NONE;

  const audioWaves = React.useMemo(() => {
    return getAudioWaves(screenWidthSize);
  }, [screenWidthSize]);

  React.useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  return (
    <S.Wrapper>
      <S.Preview>
        {contents?.map((contentItem, index) => {
          const isFirstItemOfArray = index === 0;

          if (contentItem.type === EConfigOption.TEXT) {
            return (
              <S.TextContent key={contentItem.id} isFirstItemOfArray={isFirstItemOfArray}>
                <S.MessageText
                  dangerouslySetInnerHTML={{ __html: processText(contentItem.content as string) }}
                />

                <S.Time>11:47</S.Time>

                {isFirstItemOfArray && <S.ContentArrow />}
              </S.TextContent>
            );
          }

          if (contentItem.type === EConfigOption.PRODUCT_IMAGE) {
            return (
              <S.ProductImageContent key={contentItem.id}>
                <S.ProductImageWrapper>
                  <S.ProductImageText>IMAGEM DO PRODUTO</S.ProductImageText>
                </S.ProductImageWrapper>
                <S.Time>11:47</S.Time>
              </S.ProductImageContent>
            );
          }

          if (contentItem.type === EConfigOption.IMAGE) {
            const imageSource = URL.createObjectURL(contentItem.content as File);

            return (
              <S.ImageContent key={contentItem.id}>
                <S.Image src={imageSource} alt="" />

                <S.Time>11:47</S.Time>
              </S.ImageContent>
            );
          }

          if (contentItem.type === EConfigOption.VIDEO) {
            if (!contentItem.content) return <></>;

            const videoSource = URL.createObjectURL(contentItem.content as File);

            return (
              <S.VideoContent key={contentItem.id}>
                <S.Video src={videoSource} controls />

                <S.Time>11:47</S.Time>
              </S.VideoContent>
            );
          }

          if (contentItem.type === EConfigOption.PDF) {
            if (!contentItem.content) return <></>;

            const videoSource = URL.createObjectURL(contentItem.content as File);

            return (
              <S.PDFContent key={contentItem.id}>
                <Document file={videoSource}>
                  <Page renderTextLayer={false} scale={0.3} pageNumber={1} />
                </Document>

                <S.Time>11:47</S.Time>
              </S.PDFContent>
            );
          }

          if (contentItem.type === EConfigOption.AUDIO) {
            return (
              <S.AudioContent>
                <S.IconWrapper>
                  <Play size={20} color="#9C8D8D" weight="fill" />
                </S.IconWrapper>

                <S.BulletAndWavesWrapper>
                  <S.AudioBullet />

                  <S.WavesWrapper>
                    {audioWaves.map(wave => {
                      return <S.Wave key={wave.value} height={wave.height} />;
                    })}
                  </S.WavesWrapper>

                  <S.CurrentTimeText>0:00</S.CurrentTimeText>

                  <S.TotalTimeText>1:20</S.TotalTimeText>
                </S.BulletAndWavesWrapper>

                <S.Avatar />
              </S.AudioContent>
            );
          }

          return <></>;
        })}

        {showPreviewText && (
          <S.TextContent>
            <S.MessageText dangerouslySetInnerHTML={{ __html: processText(textPreview) }} />

            <S.Time>11:47</S.Time>
          </S.TextContent>
        )}

        {showPreviewImage && (
          <S.ImageContent>
            <S.Image src={previewImageSource} alt="" />

            <S.Time>11:47</S.Time>
          </S.ImageContent>
        )}

        {showPreviewVideo && (
          <S.VideoContent>
            <S.Video src={previewVideoSource} controls />

            <S.Time>11:47</S.Time>
          </S.VideoContent>
        )}

        {showPreviewPDF && (
          <S.PDFContent>
            <Document file={previewPDFSource}>
              <Page renderTextLayer={false} scale={0.5} pageNumber={1} />
            </Document>

            <S.Time>11:47</S.Time>
          </S.PDFContent>
        )}
      </S.Preview>

      <S.CreateMessageButton
        type="submit"
        isLoading={isCreatingMessage}
        disabled={!hasContent || isCreatingMessage}
      >
        Criar mensagem
      </S.CreateMessageButton>
    </S.Wrapper>
  );
};

export default Preview;
