import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Form from '@components/common/core/Inputs/Form';
import { thirdFormSchema } from '@helpers/validators/signUp';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const ThirdForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(thirdFormSchema),
  });

  const onSubmit = React.useCallback(formData => {
    console.log(formData);
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <S.Heading>Criar conta</S.Heading>
      <S.HeadingDescription>
        Preencha os campos abaixo para continuarmos com seu cadastro
      </S.HeadingDescription>

      <S.InputGroup>
        <S.Label>Qual seu cargo de atuação?</S.Label>
        <S.UserRoleSelect {...register('user_role')} name="user_role" defaultValue="">
          <S.Option disabled value="" />
          <S.Option value="option_1">Opção 1</S.Option>
          <S.Option value="option_2">Opção 2</S.Option>
          <S.Option value="option_3">Opção 3</S.Option>
          <S.Option value="option_4">Opção 4</S.Option>
          <S.Option value="option_5">Opção 5</S.Option>
          <S.Option value="option_6">Opção 6</S.Option>
        </S.UserRoleSelect>
        {errors.user_role && <Text isErrorFeedback>{errors.user_role.message}</Text>}
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>Qual o número de funcionários da sua empresa?</S.Label>
        <S.UserCompanySize
          {...register('user_company_size')}
          name="user_company_size"
          defaultValue=""
        >
          <S.Option disabled value="" />
          <S.Option value="1">Até 5 funcionários</S.Option>
          <S.Option value="2">De 6 ~ 10 funcionários</S.Option>
          <S.Option value="3">De 11 ~ 20 funcionários</S.Option>
          <S.Option value="4">De 21 ~ 50 funcionários</S.Option>
          <S.Option value="5">Mais que 51 funcionários</S.Option>
        </S.UserCompanySize>
        {errors.user_company_size && (
          <Text isErrorFeedback>{errors.user_company_size.message}</Text>
        )}
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>Qual seu segmento de mercado?</S.Label>
        <S.UserMarketSegment
          {...register('user_market_segment')}
          name="user_market_segment"
          defaultValue=""
        >
          <S.Option disabled value="" />
          <S.Option value="option_1">Opção 1</S.Option>
          <S.Option value="option_2">Opção 2</S.Option>
          <S.Option value="option_3">Opção 3</S.Option>
          <S.Option value="option_4">Opção 4</S.Option>
          <S.Option value="option_5">Opção 5</S.Option>
          <S.Option value="option_6">Opção 6</S.Option>
          <S.Option value="option_7">Opção 7</S.Option>
        </S.UserMarketSegment>
        {errors.user_market_segment && (
          <Text isErrorFeedback>{errors.user_market_segment.message}</Text>
        )}
      </S.InputGroup>

      <S.Button type="submit">Continuar</S.Button>
    </Form>
  );
};

export default ThirdForm;
