import React from 'react';

import { XCircle } from 'phosphor-react';
import { useHistory, useParams } from 'react-router-dom';

import { ETextWeight, ETextSize } from '@domain/enums/components/EText';
import { EButtonVariant } from '@domain/enums/components/EButton';
import { IParams } from '@domain/interfaces/IParams';

import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

export const CanceledPlanWithPendingPayment: React.FC = () => {
  const history = useHistory();

  const { user } = useConfig();
  const { storeAliasId } = useParams<IParams>();

  const handleClick = React.useCallback(() => {
    history.push(
      `/${storeAliasId}/dashboard/subscription?defaultTab=general&reactivateSubscription=true`,
    );
  }, [history, storeAliasId]);

  const titleText = `Olá, ${user.first_name}! Informamos que seu plano foi cancelado.`;

  return (
    <S.Container>
      <S.Content>
        <S.IconAndTextWrapper>
          <XCircle size={26} color="#5B0514" weight="fill" />

          <S.TextWrapper>
            <S.TitleText weight={ETextWeight.BOLD}>{titleText}</S.TitleText>

            <S.DescriptionText size={ETextSize.B5}>
              Pedimos que atualize sua forma de pagamento para continuar usufruindo de nossos
              serviços. Caso necessite de ajuda, entre em contato com nossa equipe de suporte.
            </S.DescriptionText>
          </S.TextWrapper>
        </S.IconAndTextWrapper>

        <S.SolveButton variant={EButtonVariant.SECONDARY_BUTTON} onClick={handleClick}>
          Atualizar cartão
        </S.SolveButton>
      </S.Content>
    </S.Container>
  );
};

export default CanceledPlanWithPendingPayment;
