import React from 'react';
import { useParams } from 'react-router-dom';

import { EProgressBarStep } from '@domain/enums/downgrade/EDowngradeStep';
import { IParams } from '@domain/interfaces/IParams';

import SideBar from '@components/Downgrade/SideBar';
import NavBar from '@components/Downgrade/NavBar';
import StoreWorkflows from '@components/Downgrade/Workflows';

import * as S from './styles';

const Workflows: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();

  const previousRouteLink = `/${storeAliasId}/downgrade/invited-members`;

  return (
    <S.Container>
      <SideBar
        onboardingStep={EProgressBarStep.STORE_WORKFLOWS}
        previousRouteLink={previousRouteLink}
      />

      <S.Content>
        <NavBar />
        <StoreWorkflows />
      </S.Content>
    </S.Container>
  );
};

export default Workflows;
