import React from 'react';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { ETextSize } from '@domain/enums/components/EText';

import TaboolaTutorialImg from '@assets/pages/Onboarding/dropshipping/marketing/taboola-tutorial.svg';

import * as S from './styles';

const TutorialIntegration: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Image src={TaboolaTutorialImg} alt="profitfy-tutorial-image" />

      <S.Title type={EHeadingSize.H6}>Tutorial de integração</S.Title>

      <S.Description size={ETextSize.B5}>
        Para realizar a integração você precisará do <strong>Client ID</strong> e do <br />
        <strong>Client Secret</strong> que são encontradas neste link:
      </S.Description>

      <S.Link
        href="https://ajuda.profitfy.me/pt-BR/articles/6251974-como-integrar-com-o-taboola"
        target="_blank"
      >
        Veja como integrar clicando aqui.
      </S.Link>
    </S.Wrapper>
  );
};

export default TutorialIntegration;
