import React from 'react';

import Return from '@components/Dashboard/AdminPanel/IdeasChannel/IdeaDetails/Return';
import Idea from '@components/Dashboard/AdminPanel/IdeasChannel/IdeaDetails/Idea';

import * as S from './styles';

const IdeaDetails: React.FC = () => {
  return (
    <S.Container>
      <Return />

      <Idea />
    </S.Container>
  );
};

export default IdeaDetails;
