import React from 'react';

import { Video, Article, Link as LinkIcon } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { EBadgeColors } from '@domain/enums/components/EBadge';
import { EHeadingSize } from '@domain/enums/components/EHeading';
import { ETextSize } from '@domain/enums/components/EText';
import { IInstantHelpProps } from '@domain/interfaces/components/IInstantHelp';
import { useConfig } from '@helpers/hooks/useConfig';
import { getImageProps } from '@helpers/utils/components/instantHelp';

import * as S from './styles';

const InstantHelp: React.FC<IInstantHelpProps> = ({
  videoURL,
  name,
  title,
  articleLink,
  articleText,
  marginTop = 40,
  handleClickedArticle,
  ...rest
}) => {
  const theme = useTheme();

  const { analytics, selectedStore } = useConfig();

  const [isVideoModalOpen, setIsVideoModalOpen] = React.useState<boolean>(false);

  const handleVideoModalOpen = React.useCallback(() => {
    setIsVideoModalOpen(!isVideoModalOpen);
  }, [isVideoModalOpen]);

  const onArticleClick = React.useCallback(() => {
    handleClickedArticle?.(name);

    analytics?.track(
      'Access Article Button Clicked',
      { clicked_help_link: name },
      { context: { groupId: selectedStore?.alias_id } },
    );
  }, [analytics, selectedStore, name, handleClickedArticle]);

  const imageProps = getImageProps(title, name);

  return (
    <S.Wrapper marginTop={marginTop} {...rest}>
      {Boolean(videoURL) && (
        <>
          <S.ContentLeftSide onClick={handleVideoModalOpen}>
            <S.Image {...imageProps} />
          </S.ContentLeftSide>

          <S.ContentRightSide>
            <S.Heading type={EHeadingSize.H6}>{title}</S.Heading>

            <S.HelpTextWrapper>
              <Video size={18} />

              <S.Link href="https://ajuda.profitfy.me/pt-BR/" target="_blank">
                <S.HelpText size={ETextSize.B5}>
                  {articleText}

                  {!articleText && `Acessar video sobre ${title}`}
                </S.HelpText>
              </S.Link>

              <S.Badge color={EBadgeColors.PRIMARY}>1 min</S.Badge>
            </S.HelpTextWrapper>

            <S.HelpTextWrapper>
              <Article size={18} />
              <S.Link href={articleLink} target="_blank" onClick={onArticleClick}>
                <S.HelpText size={ETextSize.B5}>
                  {articleText}

                  {!articleText && `Acessar video sobre ${title}`}
                </S.HelpText>
              </S.Link>

              <LinkIcon size={12} color={theme.colors.gray[1]} />
            </S.HelpTextWrapper>
          </S.ContentRightSide>
        </>
      )}

      {Boolean(!videoURL) && (
        <>
          <S.Content>
            <S.Heading type={EHeadingSize.H6}>{title}</S.Heading>

            <S.HelpTextWrapper>
              <Article size={18} />

              <S.Link href={articleLink} target="_blank" onClick={onArticleClick}>
                <S.HelpText size={ETextSize.B5}>
                  {articleText}

                  {!articleText && `Acessar video sobre ${title}`}
                </S.HelpText>
              </S.Link>

              <LinkIcon size={12} color={theme.colors.gray[1]} />
            </S.HelpTextWrapper>
          </S.Content>
        </>
      )}

      <S.VideoModal isOpen={isVideoModalOpen} toggle={handleVideoModalOpen} src={videoURL || ''} />
    </S.Wrapper>
  );
};

export default InstantHelp;
