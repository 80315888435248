import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 692px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;

export const Alert = styled.div`
  width: 100%;
  max-width: 692px;
  margin-bottom: 16px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 692px;
`;
