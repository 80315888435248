import React from 'react';

import { useCoupon } from '@helpers/hooks/common/coupons/useCoupon';

import PageError from '@components/common/core/Utils/PageError';
import Header from '../Header';
import NewCoupon from '../NewCoupon';
import Coupons from '../Coupons';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const { isLoading, isValidating, isError, mutate } = useCoupon();

  if (isLoading) {
    return <S.Text>Carregando...</S.Text>;
  }

  if (isError && !isValidating) {
    return <PageError mutate={mutate} />;
  }

  return (
    <S.Wrapper>
      <Header />

      <NewCoupon />

      <Coupons />
    </S.Wrapper>
  );
};

export default Wrapper;
