/* eslint-disable dot-notation */
import {
  ICompareProductFinancialPeriod,
  IProductFinancialPeriod,
} from '@domain/interfaces/common/product/IProductFinancialTableByPeriod';
import {
  IComparePeriod,
  IFinancialPeriod,
} from '@domain/interfaces/dashboard/FinancialDetails/IFinancialDetails';

export const parseProductFinancialData = (
  dataToParse: IProductFinancialPeriod,
): IFinancialPeriod => {
  const dataToReturn: Partial<IProductFinancialPeriod> = { ...dataToParse };

  delete dataToReturn['pending_line_items'];
  delete dataToReturn['in_analysis_line_items'];
  delete dataToReturn['no_information_line_items'];

  dataToReturn['pending_orders'] = dataToParse.pending_line_items;
  dataToReturn['in_analysis_orders'] = dataToParse.in_analysis_line_items;
  dataToReturn['no_information_orders'] = dataToParse.no_information_line_items;

  return dataToReturn as IFinancialPeriod;
};

export const parseCompareProductFinancialData = (
  dataToParse: ICompareProductFinancialPeriod,
): IComparePeriod => {
  const dataToReturn: Partial<ICompareProductFinancialPeriod> = { ...dataToParse };

  delete dataToReturn['pending_line_items'];
  delete dataToReturn['in_analysis_line_items'];
  delete dataToReturn['no_information_line_items'];

  dataToReturn['pending_orders'] = dataToParse.pending_line_items;
  dataToReturn['in_analysis_orders'] = dataToParse.in_analysis_line_items;
  dataToReturn['no_information_orders'] = dataToParse.no_information_line_items;

  return dataToReturn as IComparePeriod;
};
