import { ESubscriptionRecurrence } from '@domain/enums/common/subscription/ESubscriptionRecurrence';
import { EPlanRecurrence } from '@domain/enums/subscription/ESubscription';

export const getRecurrence = (recurrence: EPlanRecurrence | undefined): ESubscriptionRecurrence => {
  if (recurrence === EPlanRecurrence.SEMIANUAL) return ESubscriptionRecurrence.SEMIANNUAL;

  if (recurrence === EPlanRecurrence.QUARTER) return ESubscriptionRecurrence.QUARTERLY;

  return ESubscriptionRecurrence.MONTHLY;
};
