import React from 'react';
import { useParams } from 'react-router-dom';

import boletoService from '@services/pages/dashboard/boleto/boleto';
import { IParams } from '@domain/interfaces/IParams';
import { IPeriod } from '@domain/interfaces/components/IDatePicker';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { useDate } from '@helpers/hooks/useDate';
import { EStatus } from '@domain/enums/subscription/ESubscription';
import { IBoletoProvider } from '@domain/interfaces/dashboard/Boleto/IBoleto';

const BoletoContext = React.createContext<IBoletoProvider | null>(null);

export const BoletoProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();
  const { utcToZonedTime, format, differenceInDays, addDays } = useDate();
  const { subscription, hasAllPermissions } = useStoreSubscription();

  const getMaxDate = React.useCallback(() => {
    if (
      subscription?.plan?.identifier.includes('free') &&
      subscription.exceeded_order_limit_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(subscription?.exceeded_order_limit_at);
    }

    const isSubscriptionPeriodEndedAtAfterNow = subscription?.period_ended_at
      ? differenceInDays(utcToZonedTime(new Date()), new Date(subscription.period_ended_at)) >= 0
      : true;

    if (subscription?.status === EStatus.CANCELED && isSubscriptionPeriodEndedAtAfterNow) {
      return utcToZonedTime(new Date());
    }

    if (
      subscription?.status === EStatus.CANCELED &&
      subscription?.period_ended_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(addDays(new Date(subscription.period_ended_at), 1));
    }

    if (
      subscription?.status === EStatus.CANCELED &&
      subscription?.canceled_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(subscription.canceled_at);
    }

    if (subscription && subscription.status === EStatus.TRIAL_ENDED && !hasAllPermissions) {
      return utcToZonedTime(new Date(subscription.period_ended_at));
    }

    return utcToZonedTime(new Date());
  }, [subscription, utcToZonedTime, hasAllPermissions, differenceInDays, addDays]);

  const initialDate = getMaxDate();

  const [boletosPage, setBoletosPage] = React.useState<number>(0);
  const [boletosPageCount, setBoletosPageCount] = React.useState<number>(1);
  const [period, setPeriod] = React.useState<IPeriod>({
    startDate: initialDate,
    endDate: initialDate,
  });

  const {
    orders,
    metric,
    totalPages: boletosTotalPages,
    isLoading: isLoadingBoletos,
    isValidating: isValidatingBoletos,
    mutate: mutateBoletos,
    error: boletosError,
  } = boletoService.listBoletos({
    storeAliasId,
    page: boletosPage + 1,
    startDate: format(period.startDate, 'yyyy-MM-dd'),
    endDate: format(period.endDate, 'yyyy-MM-dd'),
  });

  const mutateBoletoData = React.useCallback(async () => {
    const promises = [];

    if (boletosError) promises.push(mutateBoletos());

    await Promise.all(promises);
  }, [boletosError, mutateBoletos]);

  const onBoletoPageChange = React.useCallback(({ selected }) => setBoletosPage(selected), []);

  React.useEffect(() => {
    if (boletosTotalPages) setBoletosPageCount(boletosTotalPages);
  }, [boletosTotalPages]);

  React.useEffect(() => {
    if (period) {
      setBoletosPage(0);
    }
  }, [period]);

  const isLoadingBoletoData = isLoadingBoletos;

  const isValidatingBoletoData = isValidatingBoletos;

  const isBoletoDataError = Boolean(boletosError);

  return (
    <BoletoContext.Provider
      value={{
        boletosPage,
        metric,
        orders,
        boletosPageCount,
        isBoletoDataError,
        isLoadingBoletoData,
        isValidatingBoletoData,
        mutateBoletoData,
        setPeriod,
        period,
        onBoletoPageChange,
      }}
    >
      {children}
    </BoletoContext.Provider>
  );
};

export const useBoleto = (): IBoletoProvider => {
  const context = React.useContext(BoletoContext);

  if (!context) {
    throw new Error('useBoleto must be used within BoletoContext');
  }

  return context;
};
