import { EProductCosts } from '@domain/enums/common/EProductCosts';
import { IStoreIntegrations } from '@domain/interfaces/stores/IStores';

export const getProductCostIntegrationStatus = (
  storeIntegrations: IStoreIntegrations,
  identifier: EProductCosts,
): boolean => {
  const foundProductCostIntegration = storeIntegrations.product_cost.find(
    integration => integration.name === identifier,
  );

  return Boolean(foundProductCostIntegration?.is_active);
};
