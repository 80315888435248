import React from 'react';
import { ListChildComponentProps } from 'react-window';

import { useAdsManager } from '@helpers/hooks/pages/dashboard/adsManager/useAdsManager';
import { useCampaigns } from '@helpers/hooks/pages/dashboard/adsManager/useCampaigns';

import * as S from './styles';

const CampaignsRow: React.FC<ListChildComponentProps> = ({ index, style }) => {
  const { columnsSize } = useAdsManager();
  const { mappedAdSenseCampaigns, isRenameModalOpen } = useCampaigns();

  const [isHovering, setIsHovering] = React.useState<boolean>(false);

  const onMouseEnter = React.useCallback(() => {
    setIsHovering(true);
  }, []);

  const onMouseLeave = React.useCallback(() => {
    setIsHovering(false);
  }, []);

  const { alias_id: rowAliasId, data: rowData } = mappedAdSenseCampaigns[index];

  const isRenameOpen = isRenameModalOpen === rowAliasId;

  return (
    <S.Wrapper style={style} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {rowData.map((data: any) => {
        const Component = data.component;
        const width = columnsSize[data.id];

        return (
          <S.DataCell key={data.id} isHovering={isHovering}>
            <S.DataCellContent style={{ width }}>
              <Component
                rowAliasId={`${data.id}-${rowAliasId}`}
                data={data.data}
                forecastData={data.forecastData}
                adSenseCampaignAliasId={rowAliasId}
                isHoveringRow={isHovering || isRenameOpen}
                rowData={data.rowData}
                index={index}
              />
            </S.DataCellContent>
          </S.DataCell>
        );
      })}
    </S.Wrapper>
  );
};

export default CampaignsRow;
