import React from 'react';

const Close: React.FC = () => {
  React.useEffect(() => {
    window.open('about:blank', '_self');
    window.close();
  }, []);

  return <div />;
};

export default Close;
