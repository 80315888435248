import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import CustomDatePickerBase from '@components/common/core/Utils/CustomDatePicker';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 620px;
`;

export const Heading = styled(HeadingBase)``;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 7px;
  padding: 24px;
`;

export const InputsGroupWrapper = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  margin-bottom: 24px;

  @media only screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const InputGroup = styled(InputGroupBase)``;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)``;

export const ButtonsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 445px) {
    flex-direction: column-reverse;
    gap: 12px;
    align-items: flex-start;
  }
`;

export const PreviewAnnouncementButton = styled(ButtonBase)`
  @media only screen and (max-width: 445px) {
    width: 100%;
  }
`;

export const CreateAnnouncementButton = styled(ButtonBase)`
  @media only screen and (max-width: 445px) {
    width: 100%;
  }
`;

export const CustomDatePicker = styled(CustomDatePickerBase)`
  & > div {
    gap: 24px;
  }

  .input-wrapper {
    width: 100%;
  }

  & > div > div > button {
    width: 100%;
  }

  label {
    font-size: 0.875rem;
  }
`;
