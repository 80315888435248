import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';
import { Truck } from 'phosphor-react';

export const UPDATED_FULFILLMENT = [
  {
    id: 'updated-fulfillment-posted',
    title: 'Rastreio por status',
    type: 'UPDATED_FULFILLMENT',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Truck,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá {{first_name}}! 😊\n\nÓtima notícia o  seu pedido #{{order_id}} já foi *POSTADO*.\n\n Para acompanhar o trajeto do seu pacote, basta clicar no link abaixo:\n\n {{tracking_link}}',
      },
    ],
  },
  {
    id: 'updated-fulfillment-in-transit',
    title: 'Rastreio por status',
    type: 'UPDATED_FULFILLMENT',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Truck,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá {{first_name}}! 😊\n\nA sua encomenda #{{order_id}} está atualmente *EM TRÂNSITO*!\n\n Em breve, ela estará em suas mãos. Para acompanhar o rastreamento, sinta-se à vontade para clicar no link abaixo:\n\n {{tracking_link}}',
      },
    ],
  },
  {
    id: 'updated-fulfillment-out-for-delivery',
    title: 'Rastreio por status',
    type: 'UPDATED_FULFILLMENT',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Truck,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá {{first_name}}! 😃\n\nEstamos super animados em informar que a sua encomanda #{{order_id}} *SAIU PARA A ENTREGA*!🥳🎉\n\n Prepare-se para recebê-la no endereço fornecido no cadastro.\n\n Para ficar por dentro de cada passo, clique no link de rastreamento abaixo:\n\n {{tracking_link}}',
      },
    ],
  },
  {
    id: 'updated-fulfillment-delivery-problems',
    title: 'Rastreio por status',
    type: 'UPDATED_FULFILLMENT',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Truck,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá {{first_name}}! 😔\n\n Infelizmente, precisamos informar que houve um *PROBLEMA NA ENTREGA* do seu pedido #{{order_id}}\n\n Por favor, aguarde enquanto tomamos as medidas necessárias.\n\n Para acompanhar o rastreio e receber atualizações, utilize o link abaixo:\n\n {{tracking_link}}',
      },
    ],
  },
  {
    id: 'updated-fulfillment-awaiting-pickup',
    title: 'Rastreio por status',
    type: 'UPDATED_FULFILLMENT',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Truck,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá {{first_name}}! 😊\n\n Queremos informar que o seu pedido #{{order_id}} está *AGUARDANDO RETIRADA* na agência.\n\n Para conferir o endereço da agência e outras informações importantes, acesse o link de rastreio abaixo:\n\n {{tracking_link}}',
      },
    ],
  },
  {
    id: 'updated-fulfillment-awaiting-pickup',
    title: 'Rastreio por status',
    type: 'UPDATED_FULFILLMENT',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Truck,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá {{first_name}}! 😔\n\n Infelizmente, precisamos informar que o seu pedido #{{order_id}} foi *DEVOLVIDO AO REMETENTE*.\n\n Para mais detalhes e acompanhar o rastreio, utilize o link abaixo:\n\n {{tracking_link}}',
      },
    ],
  },
  {
    id: 'updated-fulfillment-delivered',
    title: 'Rastreio por status',
    type: 'UPDATED_FULFILLMENT',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Truck,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá {{first_name}}! 😔\n\n Seu pedido #{{order_id}} foi *ENTREGUE!* 🎉.\n\n Obrigado por comprar conosco. Aproveite seus novos itens! 😊',
      },
    ],
  },
];
