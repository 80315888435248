import React from 'react';
import { useTheme } from 'styled-components/macro';
import { ChevronDown } from '@profitfy/pakkun-icons';

import { useProductDetails } from '@helpers/hooks/pages/dashboard/productAnalytics/useProductDetails';

import { EAdSenseProvider } from '@domain/enums/common/EAdSense';

import {
  getAdSenseProvider,
  getAdSenseProviderName,
  getProviderName,
} from '@helpers/utils/dashboard/productAnalytic/marketingProvider';

import * as S from './styles';

const ProviderSelect: React.FC = () => {
  const theme = useTheme();
  const { handleFunnelChartProvider, funnelChartProvider, marketingReport } = useProductDetails();

  const onHandleFunnelChartProviderClick = React.useCallback(
    (provider: string) => {
      const parsedAdSenseProvider = getAdSenseProvider(provider) as EAdSenseProvider;

      handleFunnelChartProvider(parsedAdSenseProvider);
    },
    [handleFunnelChartProvider],
  );

  const mappedMarketingProviders = Object.entries(marketingReport).map(([key]) => key);
  const filteredMappedMarketingProviders = mappedMarketingProviders.filter(
    provider => provider !== 'status',
  );

  return (
    <S.DropDown>
      <S.DropDownTrigger>
        <S.DropDownTriggerContent>
          {getAdSenseProviderName(funnelChartProvider)}
        </S.DropDownTriggerContent>
        <ChevronDown size={14} color={theme.colors.font} />
      </S.DropDownTrigger>

      <S.Content side="bottom" sideOffset={5}>
        <S.ProviderWrapper>
          {filteredMappedMarketingProviders.map(provider => (
            <S.ProviderOption
              onClick={() => onHandleFunnelChartProviderClick(provider)}
              key={provider}
            >
              {getProviderName(provider)}
            </S.ProviderOption>
          ))}
        </S.ProviderWrapper>

        <S.Arrow />
      </S.Content>
    </S.DropDown>
  );
};

export default ProviderSelect;
