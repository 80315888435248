import React from 'react';
import { useParams } from 'react-router-dom';

import productCostDetailsService from '@services/pages/dashboard/productCostDetails/productCostDetails';
import { IParams } from '@domain/interfaces/IParams';
import {
  IProductCostDetailsProvider,
  IProductCostDetailsProviderProps,
  IVariant,
} from '@domain/interfaces/dashboard/ProductCostDetails/IProductCostDetails';
import { useToast } from '@helpers/hooks/useToast';

const ProductCostDetailsContext = React.createContext<IProductCostDetailsProvider | null>(null);

export const ProductCostDetailsProvider: React.FC<IProductCostDetailsProviderProps> = ({
  children,
  variantsRequestLimit,
}) => {
  const { productAliasId, storeAliasId } = useParams<IParams>();
  const { toast } = useToast();

  const [page, setPage] = React.useState<number>(0);
  const [pageCount, setPageCount] = React.useState<number>(1);
  const [editedVariants, setEditedVariants] = React.useState<Array<IVariant>>([]);
  const [isPageEdited, setIsPageEdited] = React.useState<boolean>(false);
  const [costToApplyAll, setCostToApplyAll] = React.useState<number | undefined>();
  const [isDeletingVariant, setIsDeletingVariant] = React.useState<boolean>(false);
  const [currencyToApplyAll, setCurrencyToApplyAll] = React.useState<string | undefined>();
  const [savedVariants, setSavedVariants] = React.useState<Array<IVariant>>([]);
  const [selectedVariant, setSelectedVariant] = React.useState<IVariant>({} as IVariant);
  const [isSyncPastCogsModalOpen, setIsSyncPastCogsModalOpen] = React.useState<boolean>(false);
  const [isEditCogsSideModalOpen, setIsEditCogsSideModalOpen] = React.useState<boolean>(false);
  const [isSyncPastCogsSidemodalOpen, setIsSyncPastCogsSidemodalOpen] = React.useState<boolean>(
    false,
  );

  const {
    variants,
    error: variantsError,
    totalPages: variantsTotalPages,
    mutate: mutateVariants,
    isValidating: isValidatingVariants,
    isLoading: isLoadingVariants,
  } = productCostDetailsService.getVariants({
    storeAliasId,
    productAliasId,
    page,
    limit: variantsRequestLimit,
  });

  const {
    product,
    error: productError,
    mutate: mutateProduct,
    isValidating: isValidatingProduct,
    isLoading: isLoadingProduct,
  } = productCostDetailsService.getProduct({ storeAliasId, productAliasId });

  const handleVariant = React.useCallback(variant => setSelectedVariant(variant), []);

  const handleEditCogsSideModalOpen = React.useCallback(() => {
    setIsEditCogsSideModalOpen(currentState => !currentState);
  }, []);

  const handleResetVariant = React.useCallback(() => {
    handleVariant({} as IVariant);
  }, [handleVariant]);

  const handleCurrencyToApplyAll = React.useCallback(
    currency => {
      const mappedEditedVariants = editedVariants.map(editedVariant => {
        return {
          ...editedVariant,
          currency,
        };
      });

      setEditedVariants(mappedEditedVariants);

      setCurrencyToApplyAll(currency);
    },
    [editedVariants],
  );

  const handleCostToApplyAll = React.useCallback(
    cost => {
      const mappedEditedVariants = editedVariants.map(editedVariant => {
        return {
          ...editedVariant,
          default_cost: cost,
        };
      });

      setEditedVariants(mappedEditedVariants);

      setCostToApplyAll(cost);
    },
    [editedVariants],
  );

  const handleSyncPastCogsModalOpen = React.useCallback(
    () => setIsSyncPastCogsModalOpen(!isSyncPastCogsModalOpen),
    [isSyncPastCogsModalOpen],
  );

  const handleSyncPastCogsSidemodalOpen = React.useCallback(
    () => setIsSyncPastCogsSidemodalOpen(!isSyncPastCogsSidemodalOpen),
    [isSyncPastCogsSidemodalOpen],
  );

  const onPageChange = React.useCallback(userPage => setPage(userPage.selected), []);

  const updateSavedVariants = React.useCallback(
    newSavedVariants => setSavedVariants(newSavedVariants),
    [],
  );

  const updateEditedVariants = React.useCallback(
    newEditedVariants => setEditedVariants(newEditedVariants),
    [],
  );

  const onDeleteVariant = React.useCallback(
    async variantAliasId => {
      setIsDeletingVariant(true);

      try {
        await productCostDetailsService.deleteVariant({
          storeAliasId,
          productAliasId,
          variantAliasId,
        });

        toast.success('Variante excluída com sucesso!');

        await mutateVariants();
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsDeletingVariant(false);
      }
    },
    [mutateVariants, productAliasId, storeAliasId, toast],
  );

  React.useEffect(() => {
    if (variantsTotalPages) {
      setPageCount(variantsTotalPages);
    }
  }, [variantsTotalPages]);

  React.useEffect(() => {
    if (editedVariants.length || costToApplyAll || currencyToApplyAll) {
      setIsPageEdited(true);
    } else {
      setIsPageEdited(false);
    }
  }, [editedVariants, costToApplyAll, currencyToApplyAll]);

  return (
    <ProductCostDetailsContext.Provider
      value={{
        isLoadingVariants,
        isValidatingVariants,
        mutateVariants,
        variantsTotalPages,
        onPageChange,
        page,
        pageCount,
        variants,
        variantsError,
        isLoadingProduct,
        isValidatingProduct,
        mutateProduct,
        product,
        productError,
        editedVariants,
        updateEditedVariants,
        isPageEdited,
        costToApplyAll,
        handleCostToApplyAll,
        isDeletingVariant,
        onDeleteVariant,
        handleCurrencyToApplyAll,
        currencyToApplyAll,
        updateSavedVariants,
        savedVariants,
        handleSyncPastCogsModalOpen,
        isSyncPastCogsModalOpen,
        handleSyncPastCogsSidemodalOpen,
        isSyncPastCogsSidemodalOpen,
        handleVariant,
        selectedVariant,
        handleResetVariant,
        handleEditCogsSideModalOpen,
        isEditCogsSideModalOpen,
      }}
    >
      {children}
    </ProductCostDetailsContext.Provider>
  );
};

export const useProductCostDetails = (): IProductCostDetailsProvider => {
  const context = React.useContext(ProductCostDetailsContext);

  if (!context) {
    throw new Error('useProductCostDetails must be used within ProductCostDetailsProvider');
  }

  return context;
};
