import React from 'react';

import Text from '@components/common/core/DataDisplay/Text';
import { ETextWeight } from '@domain/enums/components/EText';
import { IPreDefinedDates } from '@domain/interfaces/components/IDatePicker';
import { EPreDefinedDates } from '@domain/enums/components/EDatePicker';

import * as S from './styles';

const PreDefinedDates: React.FC<IPreDefinedDates> = ({ handlePreDefinedDates }) => {
  return (
    <S.PreDefinedDatesWrapper>
      <S.DateButton onClick={() => handlePreDefinedDates(EPreDefinedDates.TODAY)}>
        <Text weight={ETextWeight.MEDIUM}>Hoje</Text>
      </S.DateButton>

      <S.DateButton onClick={() => handlePreDefinedDates(EPreDefinedDates.YESTERDAY)}>
        <Text weight={ETextWeight.MEDIUM}>Ontem</Text>
      </S.DateButton>

      <S.DateButton onClick={() => handlePreDefinedDates(EPreDefinedDates.LAST_SEVEN_DAYS)}>
        <Text weight={ETextWeight.MEDIUM}>7 Dias</Text>
      </S.DateButton>

      <S.DateButton onClick={() => handlePreDefinedDates(EPreDefinedDates.LAST_THIRTY_DAYS)}>
        <Text weight={ETextWeight.MEDIUM}>30 Dias</Text>
      </S.DateButton>
    </S.PreDefinedDatesWrapper>
  );
};

export default PreDefinedDates;
