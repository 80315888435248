import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresBoletoConversionValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresBoletoConversionValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresBoletoConversionValuesResponse => {
  const currentSelectedStoreBoletoConversionValue =
    currentSelectedStoreFinancialDetailsReportData?.boleto?.conversion;

  const storesBoletoConversionValues = selectedStoresData?.map(
    value => value.financial_details_report?.boleto?.conversion,
  );

  const boletoConversionAmountValues = [
    ...storesBoletoConversionValues,
    currentSelectedStoreBoletoConversionValue,
  ];

  const reducedBoletoConversionValues = boletoConversionAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const currentSelectedStore = 1;
  const selectedStoresQuantity = selectedStoresData?.length;

  const storesQuantity = selectedStoresQuantity + currentSelectedStore;

  const finalResult = reducedBoletoConversionValues / storesQuantity;

  return {
    boleto_conversion: finalResult,
  };
};
