import React from 'react';
import * as DropDownMenu from '@radix-ui/react-dropdown-menu';

import * as S from './styles';

const Trigger: React.FC<
  DropDownMenu.DropdownMenuTriggerProps & React.RefAttributes<HTMLButtonElement>
> = ({ children, ...rest }) => {
  return <S.Wrapper {...rest}>{children}</S.Wrapper>;
};

export default Trigger;
