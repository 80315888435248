import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const FinishedStepWrapper = styled.div`
  width: 36px;
  min-width: 36px;
  height: 36px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #223c30;
  border-radius: 6px;
`;

export const Title = styled(HeadingBase)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

export const Wrapper = styled.div`
  max-width: 720px;
  width: 100%;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 24px;
  transition: all 0.3s;

  @media only screen and (max-width: 1428px) {
    max-width: 648px;
  }

  @media only screen and (max-width: 1348px) {
    max-width: 720px;
  }
`;
