import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import ButtonBase from '@components/common/core/Inputs/Button';

export const DuplicateButton = styled(ButtonBase)``;

export const CancelButton = styled.button`
  padding: unset;
  background: unset;
  border: unset;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[3]};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.gray[2]};
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
`;

export const Input = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)``;

export const Title = styled(TextBase)`
  font-size: 1.125rem;
  line-height: 120%;
  font-weight: 500;
`;

export const Modal = styled(ModalBase)`
  max-width: 542px;
  width: 100%;
  box-sizing: border-box;

  & > div:nth-child(2) {
    max-width: unset;
  }
`;
