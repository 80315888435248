import React from 'react';

import { EUpdateFulfillmentOrderStatusOptions } from '@domain/enums/dashboard/automations/node/switchNode/EUpdateFulfillment';

import * as S from './styles';

interface IOptionOrderStatusProps {
  defaultValue?: string;
  onChange: (data: string) => void;
}

const OptionFulfillment: React.FC<IOptionOrderStatusProps> = ({
  defaultValue = EUpdateFulfillmentOrderStatusOptions.EQUAL,
  onChange,
}) => {
  return (
    <S.Select
      name="options"
      defaultValue={defaultValue}
      onChange={e => {
        onChange(e.target.value);
      }}
    >
      <S.Option value={EUpdateFulfillmentOrderStatusOptions.EQUAL}>Igual</S.Option>
      <S.Option value={EUpdateFulfillmentOrderStatusOptions.NOT_EQUAL}>Diferente de</S.Option>
    </S.Select>
  );
};

export default OptionFulfillment;
