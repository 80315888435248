import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import LinkBase from '@components/common/core/Navigation/Link';
import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const DescriptionWrapper = styled(LinkBase)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  transition: all 0.3s;
  align-self: flex-start;

  &:hover {
    filter: unset;

    h5 {
      color: ${({ theme }) => theme.colors.green.default};
    }

    span {
      filter: brightness(1.2);
    }
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Link = styled(LinkBase)`
  align-self: flex-start;

  &:hover {
    filter: none;

    h5 {
      color: ${({ theme }) => theme.colors.green.default};
    }
  }
`;

export const Title = styled(HeadingBase)`
  transition: all 0.3s;
`;

export const DescriptionText = styled(TextBase)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.background[6]};
  transition: all 0.3s;
`;
