import React from 'react';
import { useWindowSize } from 'react-use';

import { IDropdownProps } from '@domain/interfaces/components/IDropDown';
import { EDropdownPosition } from '@domain/enums/components/EDropdown';

import * as S from './styles';

const Dropdown: React.FC<IDropdownProps> = ({
  toggle,
  isOpen,
  target,
  width,
  position = EDropdownPosition.CENTER,
  children,
  ...rest
}) => {
  const dropDownRef = React.useRef<HTMLDivElement>(null);

  const [targetBoundingClientRectRight, setTargetBoundingClientRectRight] = React.useState<number>(
    0,
  );
  const [overflowSize, setOverflowSize] = React.useState<number>(0);
  const [targetOffsetWidth, setTargetOffsetWidth] = React.useState<number>(0);
  const [isOverflowingRight, setIsOverflowingRight] = React.useState<boolean>(false);
  const [isOverflowingLeft, setIsOverflowingLeft] = React.useState<boolean>(false);
  const [dropdownOverflowRight, setDropDownOverflowRight] = React.useState<number>(0);

  useWindowSize();

  React.useEffect(() => {
    const windowWidth = window.innerWidth;

    const targetPositionRelativeToWindow = windowWidth - targetBoundingClientRectRight;

    const overflow = targetPositionRelativeToWindow - width;

    if (overflow <= 0) {
      setOverflowSize(overflow / 2 + targetOffsetWidth / 2);
      setIsOverflowingRight(true);
    }
  }, [targetBoundingClientRectRight, width, targetOffsetWidth]);

  React.useEffect(() => {
    if (target?.current) {
      const targetWidth = target.current.offsetWidth;

      const overflowLeft = width / 2 - targetWidth / 2;

      if (target.current.getBoundingClientRect().left - overflowLeft < 0) {
        setIsOverflowingLeft(true);

        setOverflowSize(overflowLeft);
      }
    }
  }, [target, width]);

  React.useEffect(() => {
    if (target?.current) {
      setTargetBoundingClientRectRight(target.current.getBoundingClientRect().right);
      setTargetOffsetWidth(target.current.offsetWidth);

      const targetHalfWidth = target.current.offsetWidth / 2;
      const halfWidth = width / 2;

      if (halfWidth - targetHalfWidth > 0) {
        setDropDownOverflowRight((halfWidth - targetHalfWidth) * -1);
      }
    }
  }, [target, width]);

  return (
    <>
      <S.DropdownWrapper
        width={width}
        ref={dropDownRef}
        targetHeight={target?.current?.offsetHeight}
        targetWidth={target?.current?.offsetWidth}
        targetTopPosition={(target?.current?.getBoundingClientRect().top || 0) + window.scrollY}
        targetLeftPosition={target?.current?.getBoundingClientRect().left || 0}
        overflowSize={overflowSize || 0}
        {...rest}
      >
        {isOpen && (
          <>
            <S.Overlay onClick={toggle} />
            <S.DropdownArrow />
            <S.Container
              isOverflowInRight={isOverflowingRight}
              isOverflowInLeft={isOverflowingLeft}
              width={width}
              position={position}
              overflowSize={overflowSize || 0}
              targetWidth={target?.current?.offsetWidth}
              dropdownOverflowRight={dropdownOverflowRight}
            >
              {children}
            </S.Container>
          </>
        )}
      </S.DropdownWrapper>
    </>
  );
};

export default Dropdown;
