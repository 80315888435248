import React from 'react';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { IAdSenseCampaign } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/ISummaryReport';

import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const TotalCampaigns: React.FC<ICampaignProps> = ({ data }) => {
  const totalCampaignsData: IAdSenseCampaign = data;

  return (
    <S.Wrapper>
      <S.TotalCampaignsText>
        {`(${numberFormatter(totalCampaignsData.total_quantity, 0, true)}) Campanhas encontradas`}
      </S.TotalCampaignsText>
    </S.Wrapper>
  );
};

export default TotalCampaigns;
