import styled from 'styled-components/macro';

import CustomDropDownBase from '@components/common/core/Utils/CustomDropDown';

export const UnseenNotificationsAlert = styled.span`
  width: 8px;
  min-width: 8px;
  height: 8px;
  min-height: 8px;
  background-color: ${({ theme }) => theme.colors.danger.default};
  border-radius: 50%;
  position: absolute;
  right: 2px;
  top: 2px;
`;

export const ContentWrapper = styled.div``;

export const DropDownContent = styled(CustomDropDownBase.Content)`
  min-width: 364px;
  max-width: 364px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  padding: unset;
  border-radius: 12px;
  overflow: hidden;
`;

export const DropDownArrow = styled(CustomDropDownBase.Arrow)`
  fill: ${({ theme }) => theme.colors.gray[6]};
`;

export const DropDownTrigger = styled(CustomDropDownBase.Trigger)`
  position: relative;
  margin-right: 24px;
  cursor: pointer;
`;

export const DropDown = styled(CustomDropDownBase)``;
