import React from 'react';

import DuplicateCampaignsModal from './DuplicateCampaignsModal';
import ActivateCampaignsModal from './ActivateCampaignsModal';
import DeactivateCampaignsModal from './DeactivateCampaignsModal';

const CampaignsActions: React.FC = () => {
  return (
    <>
      <DuplicateCampaignsModal />
      <ActivateCampaignsModal />
      <DeactivateCampaignsModal />
    </>
  );
};

export default CampaignsActions;
