import { v4 } from 'uuid';

import {
  ECouponProductOptions,
  ECouponType,
} from '@domain/enums/dashboard/automations/node/conditionalNode/ECoupon';

export const DEFAULT_COUPON_CONTENT = {
  id: v4(),
  type: ECouponType.PRODUCT_NAME,
  options: ECouponProductOptions.CONTAINS,
  input: '',
};
