import React from 'react';
import { useTheme } from 'styled-components';
import { X as CloseIcon } from 'phosphor-react';

import { ECreateMessageStep } from '@domain/enums/dashboard/automations/EMessage';

import { useMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useMessageSettings';

import SelectTemplateContent from './SelectTemplateContent';
import ConfigMessageContent from './ConfigMessageContent';

import * as S from './styles';

const CreateMessageModal: React.FC = () => {
  const theme = useTheme();

  const {
    createNewMessageStep,
    handleCreateNewMessageStep,
    handleContents,
    isNewMessageModalOpen,
    handleNewMessageModalOpen,
  } = useMessageSettings();

  const onClose = React.useCallback(() => {
    handleNewMessageModalOpen(false);
    handleCreateNewMessageStep(ECreateMessageStep.SELECT_MESSAGE_TEMPLATE);
    handleContents([]);
  }, [handleNewMessageModalOpen, handleCreateNewMessageStep, handleContents]);

  const getContent = {
    [ECreateMessageStep.SELECT_MESSAGE_TEMPLATE]: <SelectTemplateContent />,
    [ECreateMessageStep.CONFIG_MESSAGE]: <ConfigMessageContent />,
  };

  return (
    <S.Modal isOpen={isNewMessageModalOpen} toggle={onClose}>
      {getContent[createNewMessageStep]}
      <S.CloseButton onClick={onClose}>
        <CloseIcon size={20} color={theme.colors.gray[3]} />
      </S.CloseButton>
    </S.Modal>
  );
};

export default CreateMessageModal;
