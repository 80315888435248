import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams, useHistory } from 'react-router-dom';

import { EOrderStatus, EOrderType } from '@domain/enums/common/EOrder';

import { currencyFormatter, numberFormatter, currencyToNumber } from '@helpers/masks';
import { createOrder } from '@helpers/validators/dashboard/orders/createOrder/createOrder';
import { useOrders } from '@helpers/hooks/pages/dashboard/orders/useOrders';
import ordersService from '@services/pages/dashboard/orders/orders';
import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';

import { IParams } from '@domain/interfaces/IParams';
// import PhoneInput from '@components/common/core/Utils/PhoneInput';
import Text from '@components/common/core/DataDisplay/Text';
import Form from '@components/common/core/Inputs/Form';
import Products from '../Products';

import * as S from './styles';

const CreateOrder: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const history = useHistory();
  const { analytics, user } = useConfig();

  const [approvedDate, setApprovedDate] = React.useState(new Date());
  const [phoneValue, setPhoneValue] = React.useState<string>('');
  const [orderStatusSelected, setOrderStatusSelected] = React.useState<EOrderStatus>();
  const { newProductsForOrder } = useOrders();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'all', resolver: yupResolver(createOrder) });

  const handleAmountChange = React.useCallback(event => {
    event.target.value = currencyFormatter(event.target.value);
  }, []);

  const handleOrderStatus = React.useCallback((event: any) => {
    const orderStatus = event.target.value;

    setOrderStatusSelected(orderStatus);
  }, []);

  const onSubmit = React.useCallback(
    async formData => {
      if (!newProductsForOrder.length) {
        return;
      }

      const isChargeBackOrder = formData.status === EOrderStatus.CHARGED_BACK;
      const isRefundedOrder = formData.status === EOrderStatus.REFUNDED;
      const isPaidOrder = formData.status === EOrderStatus.PAID;

      const hasRefundedAt = isChargeBackOrder || isRefundedOrder;

      const gatewayAmount = currencyToNumber(formData?.gateway_amount || '0');
      const gatewayFeeAmount = currencyToNumber(formData?.gateway_fee_amount || '0');
      const cogsAmount = currencyToNumber(formData?.cogs_amount || '0');
      const refundedAmount = currencyToNumber(formData?.refunded_amount || '0');
      const shippingCostOwnerAmount = currencyToNumber(formData?.shipping_cost_owner_amount || '0');
      const infoProductFeeAmount = currencyToNumber(formData?.info_product_fee_amount || '0');
      // const refundedAt = hasRefundedAt ? utcToZonedTime(refundedDate).toISOString() : null;
      // const approvedAt = isPaidOrder ? utcToZonedTime(approvedDate).toISOString() : null;

      const parsedOrderData = {
        order: {
          order_name: formData.name,
          status: formData.status,
          type: formData.type,
          gateway_amount: gatewayAmount,
          gateway_fee_amount: gatewayFeeAmount,
          cogs_amount: cogsAmount,
          shipping_cost_owner_amount: shippingCostOwnerAmount,
          info_product_fee_amount: infoProductFeeAmount,
          approved_at: approvedDate,
        },
        customer: {
          name: formData.client_name,
          email: formData.email,
          phone_number: formData.phone_number,
        },
        products: newProductsForOrder,
      };

      analytics?.track('Register Order Button Clicked', {
        email: user?.email,
      });

      try {
        await ordersService.createManualOrder({
          storeAliasId,
          data: parsedOrderData,
        });

        toast.success('Pedido criado com sucesso!');

        history.push(`/${storeAliasId}/dashboard/reports/orders`);
      } catch (error) {
        toast.success('Aconteceu um erro ao criar o pedido!');
      }
    },
    [newProductsForOrder, storeAliasId, approvedDate, toast, history, analytics, user],
  );

  return (
    <S.Wrapper>
      <S.Title>Cadastro manual de pedidos</S.Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.Container>
          <S.FormTitle>Produtos</S.FormTitle>

          <S.Row>
            <Products />
          </S.Row>

          <S.FormTitle>Pedido</S.FormTitle>

          <S.Row>
            <S.InputGroup>
              <S.Label>Nome</S.Label>
              <S.Input {...register('name')} type="text" name="name" />
              {errors.name && <Text isErrorFeedback>{errors.name.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Status</S.Label>
              <S.Select
                {...register('status')}
                name="status"
                defaultValue=""
                onChange={handleOrderStatus}
              >
                <S.Option disabled value="" />
                <S.Option value={EOrderStatus.PAID}>Aprovado</S.Option>
                <S.Option value={EOrderStatus.CHARGED_BACK}>Chargeback</S.Option>
                <S.Option value={EOrderStatus.REFUNDED}>Devolvido</S.Option>
                <S.Option value={EOrderStatus.CANCELED}>Cancelado</S.Option>
              </S.Select>
              {errors.status && <Text isErrorFeedback>{errors.status.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Tipo do pedido</S.Label>
              <S.Select {...register('type')} name="type">
                <S.Option disabled value="" />
                <S.Option value={EOrderType.CREDIT_CARD}>Cartão de Crédito</S.Option>
                <S.Option value={EOrderType.BOLETO}>Boleto</S.Option>
                <S.Option value={EOrderType.PIX}>Pix</S.Option>
              </S.Select>
              {errors.type && <Text isErrorFeedback>{errors.type.message}</Text>}
            </S.InputGroup>
          </S.Row>

          <S.Row>
            <S.InputGroup>
              <S.Label>Receita</S.Label>
              <S.Input
                {...register('gateway_amount')}
                type="text"
                name="gateway_amount"
                placeholder="Insira o valor"
                defaultValue={numberFormatter(0, 2)}
                onChange={handleAmountChange}
                prependText="R$"
              />

              {errors.gateway_amount && (
                <Text isErrorFeedback>{errors.gateway_amount.message}</Text>
              )}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Custo do produto</S.Label>
              <S.Input
                {...register('cogs_amount')}
                type="text"
                name="cogs_amount"
                placeholder="opcional"
                defaultValue={numberFormatter(0, 2)}
                onChange={handleAmountChange}
                prependText="R$"
              />

              {errors.cogs_amount && <Text isErrorFeedback>{errors.cogs_amount.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Taxas do gateway</S.Label>
              <S.Input
                {...register('gateway_fee_amount')}
                type="text"
                name="gateway_fee_amount"
                placeholder="opcional"
                defaultValue={numberFormatter(0, 2)}
                onChange={handleAmountChange}
                prependText="R$"
              />

              {errors.gateway_fee_amount && (
                <Text isErrorFeedback>{errors.gateway_fee_amount.message}</Text>
              )}
            </S.InputGroup>
          </S.Row>

          <S.Row>
            <S.InputGroup>
              <S.Label>Frete do pedido</S.Label>
              <S.Input
                {...register('shipping_cost_owner_amount')}
                type="text"
                name="shipping_cost_owner_amount"
                placeholder="opcional"
                defaultValue={numberFormatter(0, 2)}
                onChange={handleAmountChange}
                prependText="R$"
              />
              {errors.shipping_cost_owner_amount && (
                <Text isErrorFeedback>{errors.shipping_cost_owner_amount.message}</Text>
              )}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Taxas do infoproduto</S.Label>
              <S.Input
                {...register('info_product_fee_amount')}
                type="text"
                name="info_product_fee_amount"
                placeholder="opcional"
                defaultValue={numberFormatter(0, 2)}
                onChange={handleAmountChange}
                prependText="R$"
              />

              {errors.info_product_fee_amount && (
                <Text isErrorFeedback>{errors.info_product_fee_amount.message}</Text>
              )}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Aprovado em</S.Label>
              <S.DayPicker selectedDate={approvedDate} setSelectedDate={setApprovedDate} />
            </S.InputGroup>
          </S.Row>

          {/* <S.FormTitle>Cliente</S.FormTitle>

          <S.Row>
            <S.InputGroup>
              <S.Label>Nome do cliente</S.Label>
              <S.Input {...register('client_name')} type="text" name="client_name" />
              {errors.client_name && <Text isErrorFeedback>{errors.client_name.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Email</S.Label>
              <S.Input {...register('email')} type="text" name="email" />
              {errors.email && <Text isErrorFeedback>{errors.email.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Telefone</S.Label>
              <PhoneInput
                {...register('phone_number', { value: phoneValue })}
                country="br"
                value={phoneValue}
                placeholder="Telefone do cliente"
                onChange={phoneNumber => setPhoneValue(phoneNumber)}
                inputProps={{ name: 'phone_number', id: 'profile-phone-number' }}
              />
              {errors.phone_number && <Text isErrorFeedback>{errors.phone_number.message}</Text>}
            </S.InputGroup>
          </S.Row> */}

          <S.SaveButton type="submit">Cadastrar</S.SaveButton>
        </S.Container>
      </Form>
    </S.Wrapper>
  );
};

export default CreateOrder;
