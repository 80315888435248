import React from 'react';
import { Check } from '@profitfy/pakkun-icons';
import { CircleSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components/macro';

// import { EProductCosts } from '@domain/enums/common/EProductCosts';
import { ISynchronization } from '@domain/interfaces/hooks/IStoreSynchronization';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
// import { IIntegration } from '@domain/interfaces/stores/IStores';

import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { numberFormatter } from '@helpers/masks';

import {
  // getDsersPercentage,
  getGatewaysPercentage,
  getOrdersPercentage,
  getProductsPercentage,
} from '@helpers/utils/onboarding/synchronization';

import * as S from './styles';

const ProcessingStatus: React.FC = () => {
  const theme = useTheme();
  const {
    currentSynchronizations,
    synchronizationsQueue,
    finishedSynchronizations,
    failedSynchronizations,
  } = useSynchronization();
  const { storeIntegrations, store } = useStoreConfig();

  const getIcon = React.useCallback(
    (
      percentage: number,
      synchronizations: Array<ISynchronization>,
      synchronizationType: ESynchronizationType,
      isDsers: boolean,
    ) => {
      if (isDsers) {
        const foundSynchronization = synchronizations.find(
          synchronization => synchronization.name === 'Dsers',
        );

        if (foundSynchronization)
          return (
            <S.ProcessingIconWrapper>
              <CircleSpinner size={16} color={theme.colors.green.default} />
            </S.ProcessingIconWrapper>
          );
      }

      if (percentage >= 100)
        return (
          <S.ProcessingIconWrapper>
            <Check size={24} />
          </S.ProcessingIconWrapper>
        );

      const foundSynchronization = synchronizations.find(
        synchronization => synchronization.type === synchronizationType,
      );

      if (foundSynchronization)
        return (
          <S.ProcessingIconWrapper>
            <CircleSpinner size={16} color={theme.colors.green.default} />
          </S.ProcessingIconWrapper>
        );

      return <S.InQueueIcon />;
    },
    [theme],
  );

  const parsedCurrentSynchronizations = currentSynchronizations
    ? currentSynchronizations.synchronizations
    : [];

  const mergedSynchronizations = [
    ...parsedCurrentSynchronizations,
    ...synchronizationsQueue,
    ...finishedSynchronizations,
    ...failedSynchronizations,
  ];

  const onboardingSynchronizations = mergedSynchronizations.filter(
    synchronization => synchronization.config.isOnboardingSynchronization,
  );

  const productsPercentage = getProductsPercentage(onboardingSynchronizations);
  const ordersPercentage = getOrdersPercentage(onboardingSynchronizations, store.type);
  // const dsersPercentage = getDsersPercentage(onboardingSynchronizations);
  const gatewaysPercentage = getGatewaysPercentage(onboardingSynchronizations);

  // const hasDsersIntegration = storeIntegrations.product_cost.find(
  //   integration => integration.name === EProductCosts.DSERS,
  // ) as IIntegration;

  return (
    <S.Wrapper>
      <S.Title>Processando histórico de 30 dias</S.Title>

      <S.ProcessingWrapper>
        <S.ProcessingGroup>
          <S.IconAndTitleWrapper>
            {getIcon(
              productsPercentage,
              currentSynchronizations?.synchronizations || [],
              ESynchronizationType.STORE_PRODUCT,
              false,
            )}
            <S.ProcessingTitle>Processando seus produtos</S.ProcessingTitle>
          </S.IconAndTitleWrapper>
          <S.ProgressWrapper>
            <S.ProgressBarWrapper>
              <S.ProgressBar style={{ width: `${productsPercentage}%` }} />
            </S.ProgressBarWrapper>
            <S.PercentageProgressText
              isGreaterThanZero={productsPercentage > 0}
            >{`(${numberFormatter(productsPercentage, 0, true)}%)`}</S.PercentageProgressText>
          </S.ProgressWrapper>
        </S.ProcessingGroup>

        {/* {hasDsersIntegration?.is_active && (
          <S.ProcessingGroup>
            <S.IconAndTitleWrapper>
              {getIcon(
                dsersPercentage,
                currentSynchronizations?.synchronizations || [],
                ESynchronizationType.STORE_PRODUCT,
                true,
              )}
              <S.ProcessingTitle>Sincronizando custos com Dsers</S.ProcessingTitle>
            </S.IconAndTitleWrapper>
            <S.ProgressWrapper>
              <S.ProgressBarWrapper>
                <S.ProgressBar style={{ width: `${dsersPercentage}%` }} />
              </S.ProgressBarWrapper>
              <S.PercentageProgressText
                isGreaterThanZero={dsersPercentage > 0}
              >{`(${numberFormatter(dsersPercentage, 0, true)}%)`}</S.PercentageProgressText>
            </S.ProgressWrapper>
          </S.ProcessingGroup>
        )} */}

        <S.ProcessingGroup>
          <S.IconAndTitleWrapper>
            {getIcon(
              ordersPercentage,
              currentSynchronizations?.synchronizations || [],
              ESynchronizationType.STORE_ORDER,
              false,
            )}
            <S.ProcessingTitle>Buscando pedidos na sua plataforma</S.ProcessingTitle>
          </S.IconAndTitleWrapper>
          <S.ProgressWrapper>
            <S.ProgressBarWrapper>
              <S.ProgressBar style={{ width: `${ordersPercentage}%` }} />
            </S.ProgressBarWrapper>
            <S.PercentageProgressText isGreaterThanZero={ordersPercentage > 0}>{`(${numberFormatter(
              ordersPercentage,
              0,
              true,
            )}%)`}</S.PercentageProgressText>
          </S.ProgressWrapper>
        </S.ProcessingGroup>

        <S.ProcessingGroup>
          <S.IconAndTitleWrapper>
            {getIcon(
              gatewaysPercentage,
              currentSynchronizations?.synchronizations || [],
              ESynchronizationType.STORE_GATEWAY,
              false,
            )}
            <S.ProcessingTitle>Conciliando Taxas com Gateway</S.ProcessingTitle>
          </S.IconAndTitleWrapper>
          <S.ProgressWrapper>
            <S.ProgressBarWrapper>
              <S.ProgressBar style={{ width: `${gatewaysPercentage}%` }} />
            </S.ProgressBarWrapper>
            <S.PercentageProgressText
              isGreaterThanZero={gatewaysPercentage > 0}
            >{`(${numberFormatter(gatewaysPercentage, 0, true)}%)`}</S.PercentageProgressText>
          </S.ProgressWrapper>
        </S.ProcessingGroup>
      </S.ProcessingWrapper>
    </S.Wrapper>
  );
};

export default ProcessingStatus;
