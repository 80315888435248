import React from 'react';
import { mutate } from 'swr';

import { OnboardingProvider } from '@helpers/hooks/pages/onboarding/useOnboarding';
import { useConfig } from '@helpers/hooks/useConfig';
import { useLocation } from 'react-router-dom';

import NavBar from '@components/Onboarding/NavBar';

import * as S from './styles';

const Onboarding: React.FC = ({ children }) => {
  const { isMobileDevice, selectedStore } = useConfig();

  const { pathname } = useLocation();

  React.useEffect(() => {
    mutate(() => true, undefined, false);
  }, []);

  const hideNavBar =
    pathname.includes('finished') ||
    pathname.includes('welcome') ||
    pathname === '/onboarding/store' ||
    pathname === '/onboarding/segmentation' ||
    (isMobileDevice && !selectedStore?.is_onboarding_finished);

  return (
    <S.Container>
      <S.Wrapper>
        {!hideNavBar && <NavBar />}

        <OnboardingProvider>{children}</OnboardingProvider>
      </S.Wrapper>
    </S.Container>
  );
};

export default Onboarding;
