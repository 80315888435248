import React from 'react';
import { WarningCircle } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import { EButtonSize } from '@domain/enums/components/EButton';

import { useTax } from '@helpers/hooks/pages/dashboard/tax/useTax';
import { useHome } from '@helpers/hooks/pages/dashboard/home/useHome';
import { useToast } from '@helpers/hooks/useToast';

import * as S from './styles';

const WithAdsModal: React.FC = () => {
  const theme = useTheme();
  const {
    isWithAdsModalOpen,
    handleWithAdsModalOpen,
    defaultTaxData,
    configDefaultTax,
    isUpdateWithAdsModalOpen,
    handleUpdateWithAdsModalOpen,
    updateDefaultTax,
  } = useTax();
  const { toast } = useToast();
  const { mutateHomeSetup } = useHome();

  const [remainingSeconds, setRemainingSeconds] = React.useState<number>(0);

  const onConfirm = React.useCallback(() => {
    if (!defaultTaxData) {
      toast.error('Selecione um imposto.');
      return;
    }

    if (isWithAdsModalOpen) {
      configDefaultTax(defaultTaxData);
      handleWithAdsModalOpen();
      mutateHomeSetup();

      return;
    }

    if (isUpdateWithAdsModalOpen) {
      handleUpdateWithAdsModalOpen();
      updateDefaultTax(defaultTaxData);
    }

    mutateHomeSetup();
  }, [
    toast,
    defaultTaxData,
    configDefaultTax,
    handleWithAdsModalOpen,
    handleUpdateWithAdsModalOpen,
    updateDefaultTax,
    isUpdateWithAdsModalOpen,
    isWithAdsModalOpen,
    mutateHomeSetup,
  ]);

  const toggle = React.useCallback(() => {
    if (isWithAdsModalOpen) handleWithAdsModalOpen();

    if (isUpdateWithAdsModalOpen) handleUpdateWithAdsModalOpen();
  }, [
    isWithAdsModalOpen,
    isUpdateWithAdsModalOpen,
    handleWithAdsModalOpen,
    handleUpdateWithAdsModalOpen,
  ]);

  React.useEffect(() => {
    if (isWithAdsModalOpen || isUpdateWithAdsModalOpen) {
      setRemainingSeconds(5);
    }
  }, [isWithAdsModalOpen, isUpdateWithAdsModalOpen]);

  React.useEffect(() => {
    if (remainingSeconds === 5) {
      let counter = 5;

      const interval = setInterval(() => {
        counter -= 1;

        setRemainingSeconds(counter);

        if (counter === 0) {
          clearInterval(interval);
        }
      }, 1000);
    }
  }, [remainingSeconds]);

  return (
    <S.Modal isOpen={isWithAdsModalOpen || isUpdateWithAdsModalOpen} toggle={toggle}>
      <S.Modal.Header>
        <WarningCircle size={24} color={theme.colors.warning.default} />
        Atenção
      </S.Modal.Header>

      <S.Modal.Body>
        <S.Description>
          Utilizando a Regra de Tributação{' '}
          <strong>&quot;Receita Líquida - Custo do Produto - Custo de Marketing&quot;</strong>, ela
          será aplicada para os próximos pedidos e para os pedidos anteriores. Caso exista algum
          histórico de imposto já criado nas regras anteriores, eles serão desconsiderados.
          <br />
          <br />
          Alterando a Regra para: &quot;Receita Líquida&quot; ou &quot;Receita Líquida - Custo do
          Produto&quot;, os históricos já criados e os novos serão contabilizados normalmente.
        </S.Description>

        <S.ButtonsWrapper>
          <S.CancelButton onClick={toggle}>Cancelar</S.CancelButton>
          <S.UnderstoodButton
            buttonSize={EButtonSize.MEDIUM}
            onClick={onConfirm}
            disabled={remainingSeconds !== 0}
          >
            {remainingSeconds > 0 ? `Confirmar (${remainingSeconds})` : 'Confirmar'}
          </S.UnderstoodButton>
        </S.ButtonsWrapper>
      </S.Modal.Body>
    </S.Modal>
  );
};

export default WithAdsModal;
