import React from 'react';
import { useTheme } from 'styled-components/macro';
import { Warning } from '@profitfy/pakkun-icons';

import { useTax } from '@helpers/hooks/pages/dashboard/tax/useTax';
import { EModalType } from '@domain/enums/components/EModal';

import * as S from './styles';

const SyncPastTaxModal: React.FC = () => {
  const theme = useTheme();

  const {
    isSyncPastTaxModalOpen,
    handleSyncPastTaxModalOpen,
    handleSyncPastTaxSideModalOpen,
  } = useTax();

  const onSyncClick = React.useCallback(() => {
    handleSyncPastTaxModalOpen();
    handleSyncPastTaxSideModalOpen();
  }, [handleSyncPastTaxModalOpen, handleSyncPastTaxSideModalOpen]);

  return (
    <S.Modal isOpen={isSyncPastTaxModalOpen} toggle={handleSyncPastTaxModalOpen}>
      <S.Modal.Header type={EModalType.WARNING}>
        <Warning size={24} color={theme.colors.warning.default} outline />
        Alterar imposto para pedidos anteriores?
      </S.Modal.Header>
      <S.Modal.Body>
        <S.Description>
          Caso você <strong>não altere</strong>, o valor só será aplicados para os{' '}
          <strong>próximos períodos.</strong>
        </S.Description>
        <S.ButtonsWrapper>
          <S.CancelButton onClick={handleSyncPastTaxModalOpen}>Não Alterar</S.CancelButton>
          <S.SyncButton onClick={onSyncClick}>Alterar</S.SyncButton>
        </S.ButtonsWrapper>
      </S.Modal.Body>
    </S.Modal>
  );
};

export default SyncPastTaxModal;
