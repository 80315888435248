import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import ImageBase from '@components/common/core/DataDisplay/Image';
import NoImageBase from '@components/common/core/DataDisplay/NoImage';
import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';

export const LineItemsWrapper = styled.div`
  padding: 16px;
`;

export const Tooltip = styled(CustomTooltipBase)``;

export const TooltipContent = styled(CustomTooltipBase.Content)`
  width: 100%;
  max-width: 281px;
`;

export const Popover = styled(CustomPopoverBase)``;

export const SeeMoreProducts = styled(TextBase)`
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 400;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.background[6]};
  cursor: pointer;
`;

export const TooltipProductText = styled(TextBase)`
  max-width: 300px;
  width: 100%;
  overflow: hidden;
  display: block;
`;

export const ProductWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const ProductDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ProductDetailsText = styled(TextBase)`
  max-width: 104px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
`;

export const ProductImage = styled(ImageBase)`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: block;
`;

export const NoImage = styled(NoImageBase)`
  background-color: ${({ theme }) => theme.colors.background[3]};
`;
