import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';
import { Wallet } from 'phosphor-react';

export const PIX = [
  {
    id: 'pix-first-condition',
    title: 'Recuperação de Pix (1ª condição)',
    type: 'PIX',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Wallet,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Parabéns pelo seu pedido, {{first_name}}! 🎉\n\nEstamos aguardando a confirmação do pagamento para enviá-lo!\n\n📌 *Detalhes do Pedido*: #{{order_id}}\n{{products_with_variant}}\n🚚 *Endereço de entrega*: {{shipping_address}}\n🏷 *Método de pagamento*: PIX\n💵 Valor total: {{total_amount}}\n\n⚠️ Se o código PIX expirar, basta gerar um novo.\n\n⚠️ Se preferir, escolha outra forma de pagamento, como boleto ou cartão de crédito.',
      },
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content: '{{pix_code}}',
      },
    ],
  },
  {
    id: 'pix-second-condition',
    title: 'Recuperação de Pix (2ª condição)',
    type: 'PIX',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Wallet,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          '{{first_name}}, não deixe seu pedido expirar! 😥\n\nEstamos aguardando a confirmação do pagamento para enviá-lo! 🚚\n\nCopie e cole o código PIX no seu app de pagamento!',
      },
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content: '{{pix_code}}',
      },
    ],
  },
  {
    id: 'pix-third-condition',
    title: 'Recuperação de Pix (3ª condição)',
    type: 'PIX',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Wallet,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá, {{first_name}}! 😊\n\nInfelizmente o seu prazo de pagamento expirou! 😥\n\nClique no link abaixo para fazer um novo pedido no nosso site: 🛒\n\n{{store_link}}\n\nE, se precisar de alguma ajuda, estamos aqui! 🤩',
      },
    ],
  },
];
