import { ApiService } from '@services/api';
import { LONG_API_DOMAIN } from '@constants/api';
import {
  ISyncFacebookAccountsProps,
  IUpdateFacebookAccountProps,
} from '@domain/interfaces/dashboard/AdSense/Facebook/IFacebookAccount';

export class FacebookAccountService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public syncFacebookAccounts({
    adSenseCredentialAliasId,
    storeAliasId,
  }: ISyncFacebookAccountsProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/ad-sense-credentials/${adSenseCredentialAliasId}/facebook/ad-sense-accounts/sync`,
    );
  }

  public updateFacebookAccount({
    storeAliasId,
    adSenseAccountAliasId,
    adSenseCredentialAliasId,
    data,
  }: IUpdateFacebookAccountProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/ad-sense-credentials/${adSenseCredentialAliasId}/facebook/ad-sense-accounts/${adSenseAccountAliasId}`,
      { ...data },
    );
  }
}

const facebookAccountService = new FacebookAccountService(LONG_API_DOMAIN);

export default facebookAccountService;
