import styled from 'styled-components/macro';

import LinkBase from '@components/common/core/Navigation/Link';
import ImageBase from '@components/common/core/DataDisplay/Image';
import NoImage from '@components/common/core/DataDisplay/NoImage';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

export const ProductContainer = styled(LinkBase)`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-self: flex-start;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 24px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[5]};
    cursor: pointer;
    filter: none;
  }
`;

export const Image = styled(ImageBase)`
  min-width: 55px;
  width: 55px;
  min-height: 55px;
  height: 55px;
  border-radius: 12px;
`;

export const NoImageFill = styled(NoImage)`
  min-width: 55px;
  width: 55px;
  min-height: 55px;
  height: 55px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.gray[4]};
`;

export const ProductGroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 14px;
`;

export const ProductName = styled(HeadingBase)`
  font-size: 14px;
  max-width: 236px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 2px;
`;

export const ProductValueAndQuantityOrdersWrapper = styled.div`
  display: flex;
`;

export const ProductValue = styled(TextBase)`
  color: #59b578;
  margin-right: 6px;
  line-height: 120%;
`;

export const QuantityOrders = styled(TextBase)`
  line-height: 120%;
`;

export const ProductInfo = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
  line-height: 120%;
`;
