import React from 'react';

import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { WhatsappLogo } from 'phosphor-react';
import { useForm } from 'react-hook-form';

import { EButtonSize, EButtonVariant } from '@domain/enums/components/EButton';
import { IParams } from '@domain/interfaces/IParams';
import { IUpdateInstanceModalProps } from '@domain/interfaces/dashboard/WhatsappInstance/IUpdateInstanceModal';

import { useWhatsappInstance } from '@helpers/hooks/pages/dashboard/whatsappInstance/useWhatsappInstance';
import { useToast } from '@helpers/hooks/useToast';

import { updateWhatsappInstanceSchema } from '@helpers/validators/dashboard/whatsappInstance/instance';

import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';

import whatsappCredentialService from '@services/pages/dashboard/whatsappInstance/whatsappCredential';

import * as S from './styles';

const UpdateInstanceModal: React.FC<IUpdateInstanceModalProps> = ({
  isOpen,
  toggle,
  credentialAliasId,
}) => {
  const theme = useTheme();

  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { mutateWhatsappCredential } = useWhatsappInstance();

  const [isUpdatingCredential, setIsUpdatingCredential] = React.useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updateWhatsappInstanceSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const onSubmit = React.useCallback(
    async formData => {
      setIsUpdatingCredential(true);

      try {
        await whatsappCredentialService.updateCredential({
          storeAliasId,
          credentialAliasId,
          data: { is_active: undefined, name: formData.instance_name },
        });

        toast.success('Instância atualizada com sucesso.');

        mutateWhatsappCredential();

        toggle();

        setIsUpdatingCredential(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsUpdatingCredential(false);
      }
    },
    [storeAliasId, toast, credentialAliasId, mutateWhatsappCredential, toggle],
  );

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Header>
        <WhatsappLogo size={24} color={theme.colors.green.default} />
        <S.Heading>Atualize o nome da instância</S.Heading>
      </S.Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label>Nome da instância</S.Label>
          <S.Input
            {...register('instance_name')}
            type="text"
            placeholder="Ex. Instância para Carrinho Abandonado"
          />
          {errors.instance_name && <Text isErrorFeedback>{errors.instance_name.message}</Text>}
        </S.InputGroup>

        <S.ButtonsWrapper>
          <S.CancelButton
            onClick={toggle}
            variant={EButtonVariant.SECONDARY_BUTTON}
            buttonSize={EButtonSize.MEDIUM}
          >
            Cancelar
          </S.CancelButton>

          <S.CreateNewInstanceButton
            type="submit"
            buttonSize={EButtonSize.MEDIUM}
            isLoading={isUpdatingCredential}
            disabled={isUpdatingCredential}
          >
            Atualizar instância
          </S.CreateNewInstanceButton>
        </S.ButtonsWrapper>
      </Form>
    </S.Modal>
  );
};

export default UpdateInstanceModal;
