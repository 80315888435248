import styled, { css } from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import EmptyDataBase from '@components/common/core/DataDisplay/EmptyData';
import { ITableProps } from '@domain/interfaces/dashboard/CustomValues/ITable';

export const EmptyData = styled(EmptyDataBase)``;

export const Table = styled(TableBase)<ITableProps>`
  & > table {
    & > thead {
      & > tr {
        & > th {
          padding: 10px 28px;
        }

        & > th:first-child {
          padding-left: 32px;
        }

        & > th:last-child {
          padding-right: 32px;
        }
      }
    }
  }

  ${({ isSelectedCustomValueToChecked }) =>
    isSelectedCustomValueToChecked &&
    css`
      margin-bottom: 224px;
    `}
`;
