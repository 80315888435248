import React from 'react';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  const messagesArray = Array.from(Array(6).keys());

  return (
    <S.MessagesWrapper>
      {messagesArray.map(element => {
        return (
          <S.Message key={element}>
            <Skeleton
              width="100%"
              height="100%"
              style={{ lineHeight: 'unset', borderRadius: 12 }}
            />
          </S.Message>
        );
      })}
    </S.MessagesWrapper>
  );
};

export default SkeletonLoading;
