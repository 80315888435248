import styled from 'styled-components/macro';

export const Wrapper = styled.tr`
  & > th:first-child {
    position: sticky;
    position: -webkit-sticky;
    top: -53px;
    left: 0;
    z-index: ${({ theme }) => `calc(${theme.zIndex.firstLayer} + 1)`};
  }

  & > th:nth-child(2) {
    position: sticky;
    position: -webkit-sticky;
    top: -53px;
    left: 68px;
    z-index: ${({ theme }) => `calc(${theme.zIndex.firstLayer} + 1)`};
  }

  & > th:nth-child(3) {
    position: sticky;
    position: -webkit-sticky;
    top: -53px;
    left: 140px;
    z-index: ${({ theme }) => `calc(${theme.zIndex.firstLayer} + 1)`};
  }

  & > th:last-child {
    border-right: unset;
  }
`;
