import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  border-radius: 5px;
  overflow: hidden;

  & > div {
    padding-top: 53px;
    padding-bottom: 92px;

    ::-webkit-scrollbar {
      width: 8px;
      position: absolute;
    }

    ::-webkit-scrollbar:horizontal {
      height: 12px;
      width: 4px;
      position: absolute;
    }

    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.gray[4]};
      position: absolute;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.gray[1]};
      border-radius: 25px;
      transition: all 0.3s;
    }

    ::-webkit-scrollbar-thumb:horizontal {
      height: 1px;
      background-color: ${({ theme }) => theme.colors.gray[1]};
      border-radius: 25px;
      transition: all 0.3s;
    }

    ::-webkit-scrollbar-thumb:hover {
      opacity: brightness(0.8);
    }
  }
`;
