import styled, { css } from 'styled-components/macro';

import CardBase from '@components/common/core/Surfaces/Card';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';
import { IBulletProps, ITitleProps } from '@domain/interfaces/dashboard/FinancialDashboard/IGoal';

export const SeeDetailsButton = styled(ButtonBase)`
  width: 100%;
  height: 34px;
  padding-left: 20px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border: unset;
  border-radius: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.gray[4]};

  &:hover {
    border: unset;
    box-shadow: unset;
    border-top: 1px solid ${({ theme }) => theme.colors.gray[4]};
  }

  & > span {
    color: ${({ theme }) => theme.colors.gray[1]};
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 120%;
    margin-right: 5px;
  }
`;

export const Bullet = styled.span<IBulletProps>`
  width: 7px;
  height: 7px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  position: relative;
  display: block;
`;

export const StatusText = styled(TextBase)`
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const OrdersAmount = styled(TextBase)`
  color: ${({ theme }) => theme.colors.white.default};
  line-height: 120%;
  font-size: 0.875rem;
  font-weight: 400;
`;

export const OrdersStatusWrapper = styled.div``;

export const OrdersDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
`;

export const TextAndValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.875rem;
  font-weight: 300;
`;

export const ChartText = styled(TextBase)`
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const ChartValue = styled(TextBase)`
  line-height: 120%;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.white.light};
`;

export const ChartWrapper = styled.div`
  width: 145px;
  height: 120px;
  position: relative;
`;

export const DataWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;

  @media only screen and (max-width: 1360px) {
    width: 100%;
    justify-content: space-around;
  }
`;

export const Title = styled(HeadingBase)<ITitleProps>`
  color: #ffffff;

  ${({ isMultiStoreSelectMode }) =>
    isMultiStoreSelectMode &&
    css`
      text-align: center;
      margin-top: 74px;
    `}
`;

export const Content = styled.div`
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

export const Card = styled(CardBase)`
  padding: 0;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 12px;
  border: unset;
  max-width: 398px;
  width: 100%;
  height: 224px;

  @media only screen and (max-width: 1360px) {
    max-width: unset;
  }
`;
