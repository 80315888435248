import styled, { css } from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import ImageBase from '@components/common/core/DataDisplay/Image';
import BadgeBase from '@components/common/core/DataDisplay/Badge';
import SwitchBase from '@components/common/core/Inputs/Switch';
import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';

export const Tooltip = styled(CustomTooltipBase)``;

export const NameTooltipTrigger = styled(CustomTooltipBase.Trigger)`
  color: #ffffff;
  max-width: 142px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const NameTooltipContent = styled(CustomTooltipBase.Content)`
  background-color: ${({ theme }) => theme.colors.gray[7]};
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const NameTooltipArrow = styled(CustomTooltipBase.Arrow)`
  fill: ${({ theme }) => theme.colors.gray[7]};
`;

export const TooltipContent = styled(CustomTooltipBase.Content)`
  background-color: ${({ theme }) => theme.colors.background[2]};
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TooltipContentText = styled(TextBase)`
  font-size: 0.875rem;
  color: #ffffff;
`;

export const TooltipArrow = styled(CustomTooltipBase.Arrow)`
  fill: ${({ theme }) => theme.colors.background[2]};
`;

export const Popover = styled(CustomPopoverBase)``;

export const PopoverContent = styled(CustomPopoverBase.Content)`
  background-color: ${({ theme }) => theme.colors.background[2]};
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PopoverArrow = styled(CustomPopoverBase.Arrow)`
  fill: ${({ theme }) => theme.colors.background[2]};
`;

export const Wrapper = styled.div`
  width: 324px;
  height: 182px;
  box-sizing: border-box;
  padding: 16px 20px 0px 20px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 620px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const LeftSide = styled.div`
  display: flex;
  gap: 12px;
`;

export const WhatsappLogo = styled(ImageBase)`
  object-fit: unset;
  max-width: 32px;
`;

export const InstanceNameAndPhoneNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InstanceName = styled(TextBase)``;

export const PhoneNumber = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const InstanceStatusBadge = styled(BadgeBase)``;

export const Body = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
`;

export const QuantityAndDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InstanceInfoQuantity = styled(TextBase)`
  font-size: 1.125rem;
  color: #ffffff;
`;

export const InstanceInfoDescription = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
  font-size: 0.75rem;
`;

export const Footer = styled.div`
  width: calc(100% + 40px);
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors.gray[5]};
  padding: 12px 20px;
  box-sizing: border-box;
`;

export const Switch = styled(SwitchBase)``;

export const ReconnectButton = styled.button`
  width: 94px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.green.default};
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: unset;
  padding: unset;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-weight: 500;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black.default};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const ButtonsWrapper = styled.div<{ showButtons: boolean }>`
  display: flex;
  gap: 8px;
  opacity: 0;
  transition: opacity 0.3s;

  ${({ showButtons }) =>
    showButtons &&
    css`
      opacity: 1;
    `}
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const IconAndActionButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ActionButton = styled.button`
  background: unset;
  border: unset;
  padding: unset;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-weight: 400;
  font-size: 0.875rem;
  color: #ffffff;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const ViewAutomationsButton = styled.button`
  background: unset;
  border: unset;
  padding: unset;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;
