import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  max-width: 202px;
  width: 100%;
  margin-bottom: 32px;
`;

export const ZipCodeAndCityAndStateWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 24px;
`;

export const StreetAndNumber = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 24px;
`;

export const NeighborhoodAndComplement = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 24px;
`;

export const LabelWrapper = styled.div``;

export const CepInputWrapper = styled.div`
  max-width: 208px;
  width: 100%;
`;

export const CityInputWrapper = styled.div`
  max-width: 208px;
  width: 100%;
`;

export const StateInputWrapper = styled.div`
  max-width: 208px;
  width: 100%;
`;

export const StreetInputWrapper = styled.div`
  max-width: 452px;
  width: 100%;
`;

export const NumberInputWrapper = styled.div`
  max-width: 195px;
  width: 100%;
`;

export const NeighborhoodInputWrapper = styled.div`
  max-width: 324px;
  width: 100%;
`;

export const ComplementInputWrapper = styled.div`
  max-width: 324px;
  width: 100%;
`;
