import React from 'react';

import { EHeadingSize } from '@domain/enums/components/EHeading';

import { SOCIAL_MEDIA } from '@constants/pages/pricing/socialMedia';

import profitfyLogo from '@assets/img/brand/profitfy-logo.svg';

import * as S from './styles';

const FooterSection: React.FC = () => {
  const { facebook, instagram, linkedin, youtube } = SOCIAL_MEDIA;

  return (
    <S.Container id="footer-section">
      <S.Content>
        <S.Logo src={profitfyLogo} alt="Profitfy Logo" />

        <S.InfosWrapper>
          <S.Contact>
            <S.ContactTitle type={EHeadingSize.H5}>Contato</S.ContactTitle>
            <S.ContactNumber>+55 41 9653-0207</S.ContactNumber>
            <S.ContactEmail>contato@profitfy.me</S.ContactEmail>
            <S.OpeningHours>
              Horário de atendimento: segunda <br />
              a sexta-feira, das 10h às 12h e das <br />
              13h às 18h.
            </S.OpeningHours>
            <S.CNPJ>CNPJ 37.365.788/0001-40</S.CNPJ>
          </S.Contact>

          <S.Address>
            <S.AddressTitle type={EHeadingSize.H5}>Endereço</S.AddressTitle>
            <S.FullAddress>
              Av. Gen. Ataliba Leonel, 1205 <br /> Sala 92 - Carandiru, São Paulo - SP <br />
              CEP: 02033-000
            </S.FullAddress>
          </S.Address>

          <S.About>
            <S.AboutTitle type={EHeadingSize.H5}>Sobre</S.AboutTitle>
            <S.TermsLink href="https://app.profitfy.me/terms-of-usage" target="_blank">
              Termos de Uso e Compromisso
            </S.TermsLink>
            <S.TermsLink href="https://app.profitfy.me/privacy-policy" target="_blank">
              Políticas de privacidade
            </S.TermsLink>
          </S.About>
        </S.InfosWrapper>

        <S.SocialMediaAndCopyrightWrapper>
          <S.SocialMediaWrapper>
            <S.SocialMediaLink href={facebook.url} target="_blank">
              <S.Facebook src={facebook.src} alt={facebook.alt} />
            </S.SocialMediaLink>

            <S.SocialMediaLink href={instagram.url} target="_blank">
              <S.Instagram src={instagram.src} alt={instagram.alt} />
            </S.SocialMediaLink>

            <S.SocialMediaLink href={linkedin.url} target="_blank">
              <S.Linkedin src={linkedin.src} alt={linkedin.alt} />
            </S.SocialMediaLink>

            <S.SocialMediaLink href={youtube.url} target="_blank">
              <S.Youtube src={youtube.src} alt={youtube.alt} />
            </S.SocialMediaLink>
          </S.SocialMediaWrapper>

          <S.Copyright>Copyright 2022 Profitfy.me | Todos os direitos reservados.</S.Copyright>
        </S.SocialMediaAndCopyrightWrapper>
      </S.Content>
    </S.Container>
  );
};

export default FooterSection;
