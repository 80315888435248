import React from 'react';

import { IConditionalUpdateFulfillmentNodeModal } from '@domain/interfaces/dashboard/Automations/Canvas/Node/ConditionalNode/IConditionalUpdateFulfillmentNodeModal';

import ConditionalSelect from './ConditionalSelect';

import * as S from './styles';

const AbandonCartModal: React.FC<IConditionalUpdateFulfillmentNodeModal> = ({
  firstRule,
  rules,
  handleChangeRule,
  handleDeleteRule,
  logicalOperator,
}) => {
  const hasNextCondition = (i: number): boolean => {
    const hasItem = rules[i + 1];

    return !!hasItem;
  };

  return (
    <>
      <S.AddedOptionsWrapper>
        <ConditionalSelect
          isDynamic={false}
          index={0}
          value={firstRule}
          onUpdate={handleChangeRule}
          logicalOperator={logicalOperator}
          showLogicalOperator={rules.length > 0}
        />

        {rules.length > 0 &&
          rules.map((value, index) => (
            <ConditionalSelect
              key={value.id}
              isDynamic
              value={value}
              index={index + 1}
              onUpdate={handleChangeRule}
              logicalOperator={logicalOperator}
              showLogicalOperator={hasNextCondition(index)}
              onDelete={handleDeleteRule}
            />
          ))}
      </S.AddedOptionsWrapper>
    </>
  );
};

export default AbandonCartModal;
