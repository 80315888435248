import { ApiService } from '@services/api';

import {
  IGetUserGroupProps,
  IGetUserGroupResponse,
} from '@domain/interfaces/services/group/IUserGroup';

import { useFetch } from '@providers/Fetch/useFetch';

import { API_DOMAIN } from '@constants/api';

export class UserGroupService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getUserGroup({ storeAliasId }: IGetUserGroupProps): IGetUserGroupResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/user-groups`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      role: data?.role,
      group: data?.group,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }
}

const userGroupService = new UserGroupService(API_DOMAIN);

export default userGroupService;
