import IWorkflowNode from '@domain/interfaces/mappers/automations/profitfy/IWorkflowNode';
import IWorkflowEdge from '@domain/interfaces/mappers/automations/profitfy/IWorkflowEdge';

import {
  createInternalFinishNode,
  createInternalOrderStatusNode,
  createInternalSellRecoveredStatusNode,
} from '@helpers/utils/hooks/dashboard/automations/createInternalNodes';

interface IBuildWorkflowToSaveProps {
  nodes: IWorkflowNode[];
  edges: IWorkflowEdge[];
}

interface IBuildWorkflowToSaveResponse {
  builded_nodes: IWorkflowNode[];
  builded_edges: IWorkflowEdge[];
}

export const buildWorkflowToSave = ({
  edges,
  nodes,
}: IBuildWorkflowToSaveProps): IBuildWorkflowToSaveResponse => {
  const {
    edges: edgesAfterInternalOrderStatus,
    nodes: nodesAfterInternalOrderStatus,
  } = createInternalOrderStatusNode({
    rawEdges: edges,
    rawNodes: nodes,
  });

  const {
    edges: edgesAfterInternalFinishNode,
    nodes: nodesAfterInternalFinishNode,
  } = createInternalFinishNode({
    rawEdges: edgesAfterInternalOrderStatus,
    rawNodes: nodesAfterInternalOrderStatus,
  });

  const {
    edges: edgesAfterInternalSellRecoveredStatusNode,
    nodes: nodesAfterInternalSellRecoveredStatusNode,
  } = createInternalSellRecoveredStatusNode({
    rawEdges: edgesAfterInternalFinishNode,
    rawNodes: nodesAfterInternalFinishNode,
  });

  return {
    builded_nodes: nodesAfterInternalSellRecoveredStatusNode,
    builded_edges: edgesAfterInternalSellRecoveredStatusNode,
  };
};
