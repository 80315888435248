import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';
import {
  IGetProps,
  IGetResponse,
} from '@domain/interfaces/dashboard/IntegrationCenter/IIntegrationCenter';
import { getEnvironment } from '@helpers/utils/environment';

export class IntegrationCenterService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getStoreIntegrations({ storeAliasId }: IGetProps): Promise<any> {
    return this.apiService.get(`/api/v1/users/stores/${storeAliasId}/integrations`);
  }

  public getIntegrations({ storeAliasId }: IGetProps): IGetResponse {
    // # DEMO CODE
    const user = localStorage.getItem(`@profitfy:${getEnvironment()}/user`);

    if (user) {
      const parsedJson = JSON.parse(user);

      if (parsedJson) {
        const { email } = parsedJson;

        if (email === 'profitfydemo@gmail.com') {
          const { data, error, mutate, isValidating } = useFetch(
            `/integrations/integrations.json`,
            this.apiService,
          );

          const isLoading = !error && !data;

          return {
            storeIntegrations: data?.store_integrations,
            error,
            mutate,
            isLoading,
            isValidating,
          };
        }
      }
    }

    // END CODE

    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/integrations`,
      this.apiService,
    );

    const isLoading = !error && !data;

    return {
      storeIntegrations: data?.store_integrations,
      error,
      mutate,
      isLoading,
      isValidating,
    };
  }
}

const integrationCenterService = new IntegrationCenterService(API_DOMAIN);

export default integrationCenterService;
