import React from 'react';
import { useParams } from 'react-router-dom';
import { LojaIntegrada as LojaIntegradaIcon } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';
import {
  ILojaIntegradaCredential,
  IUpdateLojaIntegradaCredentialData,
} from '@domain/interfaces/dashboard/IntegrationCenter/Ecommerce/LojaIntegrada/ILojaIntegradaAuth';

import { useToast } from '@helpers/hooks/useToast';

import lojaIntegradaAuthService from '@services/pages/dashboard/integrationCenter/ecommerce/lojaIntegrada/lojaIntegradaAuth';

import Save from '@components/Dashboard/IntegrationCenter/SideModalContent/Save';

import * as S from './styles';

interface IEnableIntegrationProps {
  lojaIntegradaCredential: ILojaIntegradaCredential;
  mutate: any;
}

const EnableIntegration: React.FC<IEnableIntegrationProps> = ({
  lojaIntegradaCredential,
  mutate,
}) => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const theme = useTheme();

  const [isEnablingCredential, setIsEnablingCredential] = React.useState<boolean>(false);

  const onEnableCredential = React.useCallback(async () => {
    setIsEnablingCredential(true);

    try {
      const payload: IUpdateLojaIntegradaCredentialData = {
        store_key: lojaIntegradaCredential.store_key,
        type: lojaIntegradaCredential.types,
        is_active: true,
      };

      await lojaIntegradaAuthService.updateLojaIntegradaCredential({
        storeAliasId,
        payload,
      });

      toast.success('Integração ativada com sucesso!');

      setIsEnablingCredential(false);

      mutate();
    } catch (error: any) {
      setIsEnablingCredential(true);
      toast.error(error?.response?.data?.message);
    }
  }, [storeAliasId, toast, mutate, lojaIntegradaCredential]);

  return (
    <S.Content>
      <S.Header>
        <LojaIntegradaIcon size={52} color={theme.colors.gray[2]} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.REGULAR}>
          Integração com Loja Integrada
        </S.Heading>
      </S.Header>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>
        <S.FunctionalityDescriptionText>
          A integração com sua plataforma de e-commerce serve para puxar seus dados de loja.
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <S.TutorialWrapper>
        <S.TutorialHeading type={EHeadingSize.H5}>Tutorial de integração</S.TutorialHeading>
        <S.TutorialText>Basta ativar a integração abaixo.</S.TutorialText>
      </S.TutorialWrapper>

      <Save
        submitText="Ativar integração"
        isSaveButtonDisabled={isEnablingCredential}
        isSubmitting={isEnablingCredential}
        submitButtonOnClick={onEnableCredential}
      />
    </S.Content>
  );
};

export default EnableIntegration;
