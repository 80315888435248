import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { useMediaQuery } from '@helpers/hooks/useMediaQuery';

import * as S from './styles';

const HowToAddTheScript: React.FC = () => {
  const mobileWidth = useMediaQuery('1348px');

  const [offset, setOffset] = React.useState<number>(0);

  React.useEffect(() => {
    const onScroll = (): void => {
      setOffset(window.pageYOffset);
    };
    window.addEventListener('scroll', onScroll);

    return window.removeEventListener('scroll', onScroll, true);
  }, []);

  const scrollOffset = 86 - offset;
  const stepsScrollOffset = 190 - offset;

  return (
    <S.Wrapper scrollOffset={scrollOffset}>
      <S.Title
        fontWeight={EHeadingWeight.REGULAR}
        type={mobileWidth ? EHeadingSize.H5 : EHeadingSize.H4}
      >
        Como instalar o Script?
      </S.Title>

      <S.StepsWrapper scrollOffset={stepsScrollOffset}>
        <S.Step>
          <S.StepHeader>
            <S.StepNumber>1</S.StepNumber>
            <S.StepDescription>
              {mobileWidth ? 'Copie o script acima' : 'Copie o script ao lado'}
            </S.StepDescription>
          </S.StepHeader>
        </S.Step>

        <S.Step>
          <S.StepHeader>
            <S.StepNumber>2</S.StepNumber>
            <S.StepDescription>
              No Dashboard da Cartpanda, clique em <strong>Loja Online</strong> e vá até{' '}
              <strong>Configurações.</strong>
            </S.StepDescription>
          </S.StepHeader>
          <S.StepImage
            src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/ads-manager/onboarding/how-to-add-the-script/cartpanda/1.png"
            alt="Profitfy.me Virtual Store"
          />
        </S.Step>

        <S.Step>
          <S.StepHeader>
            <S.StepNumber>3</S.StepNumber>
            <S.StepDescription>
              Desça até o campo de <strong>Scripts Adicionais</strong> e cole o código no campo{' '}
              <strong>Head</strong>
            </S.StepDescription>
          </S.StepHeader>
          <S.StepImage
            src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/ads-manager/onboarding/how-to-add-the-script/cartpanda/2.png"
            alt="Profitfy.me Paste Code"
          />
        </S.Step>
      </S.StepsWrapper>
    </S.Wrapper>
  );
};

export default HowToAddTheScript;
