import {
  IFinancialReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresCogsValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresCogsValues = (
  currentSelectedStoreFinancialReportData: IFinancialReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresCogsValuesResponse => {
  const currentSelectedCogsAmountValue = currentSelectedStoreFinancialReportData?.cogs?.cogs_amount;
  const currentSelectedStoreCogsGrowthValue = currentSelectedStoreFinancialReportData?.cogs?.growth;
  const currentSelectedStoreProductCostAmountValue =
    currentSelectedStoreFinancialReportData?.cogs?.product_cost_amount;

  const currentSelectedStoreShippingCostOwnerAmountValue =
    currentSelectedStoreFinancialReportData?.cogs?.shipping_cost_owner_amount;

  const storesCogsAmountValues = selectedStoresData.map(
    value => value.financial_report?.cogs?.cogs_amount,
  );

  const storesCogsGrowthValues = selectedStoresData.map(
    value => value.financial_report?.cogs?.growth,
  );

  const storesProductCostAmountValues = selectedStoresData.map(
    value => value.financial_report?.cogs?.product_cost_amount,
  );

  const storesShippingCostOwnerAmountValues = selectedStoresData.map(
    value => value.financial_report?.cogs?.shipping_cost_owner_amount,
  );

  const cogsAmountValues = [...storesCogsAmountValues, currentSelectedCogsAmountValue];

  const cogsGrowthValues = [...storesCogsGrowthValues, currentSelectedStoreCogsGrowthValue];

  const productCostAmountValues = [
    ...storesProductCostAmountValues,
    currentSelectedStoreProductCostAmountValue,
  ];

  const shippingCostOwnerAmountValues = [
    ...storesShippingCostOwnerAmountValues,
    currentSelectedStoreShippingCostOwnerAmountValue,
  ];

  const reducedCogsAmountValues = cogsAmountValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const reducedCogsGrowthValues = cogsGrowthValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const reducedProductCostAmountValues = productCostAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedShippingCostOwnerAmountValues = shippingCostOwnerAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  return {
    cogs_amount: reducedCogsAmountValues,
    cogs_growth: reducedCogsGrowthValues,
    cogs_product_cost_amount: reducedProductCostAmountValues,
    cogs_shipping_cost_owner_amount: reducedShippingCostOwnerAmountValues,
  };
};
