import { v4 as idRandom } from 'uuid';

import {
  EAbandonCartType,
  EAbandonedCartSellRecoveredPicker,
  EAbandonCartOrderStatusOptions,
} from '@domain/enums/dashboard/automations/node/conditionalNode/EAbandonCart';

const initialPositionX = 574;
const initialPositionY = 28;

export const ABANDONED_CART_NODES = [
  {
    id: `node1`,
    position: { x: initialPositionX, y: initialPositionY },
    data: { label: `Node 1`, name: idRandom() },
    type: 'ABANDONED_CART',
  },
  {
    id: `node2`,
    position: { x: initialPositionX + 100, y: initialPositionY + 100 },
    data: {
      label: `Node 2`,
      rules: [{ unit: 'MINUTES', amount: 10 }],
      name: idRandom(),
    },
    type: 'WAIT',
  },
  {
    id: `node3`,
    position: { x: initialPositionX + 200, y: initialPositionY + 200 },
    data: {
      label: `Node 3`,
      name: idRandom(),
      message: {
        id: 'abandoned-cart-first-condition',
      },
    },
    type: 'WHATSAPP',
  },
  {
    id: `node4`,
    position: { x: initialPositionX + 300, y: initialPositionY + 700 },
    data: {
      label: `Node 4`,
      rules: [{ unit: 'DAYS', amount: 1 }],
      name: idRandom(),
    },
    type: 'WAIT',
  },
  {
    id: `node5`,
    position: { x: initialPositionX + 200, y: initialPositionY + 900 },
    data: {
      label: `Node 5`,
      rules: [
        {
          id: idRandom(),
          type: EAbandonCartType.SELL_RECOVERED,
          options: EAbandonCartOrderStatusOptions.EQUAL,
          input: EAbandonedCartSellRecoveredPicker.FALSE,
        },
      ],
      name: idRandom(),
    },
    type: 'IF',
  },
  {
    id: `nodeEnd1`,
    position: { x: initialPositionX - 200, y: initialPositionY + 1000 },
    data: { label: `NodeEnd1`, name: idRandom() },
    type: 'END',
  },
  {
    id: `node6`,
    position: { x: initialPositionX + 200, y: initialPositionY + 1100 },
    data: {
      label: `Node 6`,
      name: idRandom(),
      message: {
        id: 'abandoned-cart-second-condition',
      },
    },
    type: 'WHATSAPP',
  },
  {
    id: `node7`,
    position: { x: initialPositionX + 400, y: initialPositionY + 1700 },
    data: {
      label: `Node 7`,
      rules: [{ unit: 'DAYS', amount: 1 }],
      name: idRandom(),
    },
    type: 'WAIT',
  },
  {
    id: `node8`,
    position: { x: initialPositionX + 200, y: initialPositionY + 1900 },
    data: {
      label: `Node 8`,
      rules: [
        {
          id: idRandom(),
          type: EAbandonCartType.SELL_RECOVERED,
          options: EAbandonCartOrderStatusOptions.EQUAL,
          input: EAbandonedCartSellRecoveredPicker.FALSE,
        },
      ],
      name: idRandom(),
    },
    type: 'IF',
  },
  {
    id: `nodeEnd2`,
    position: { x: initialPositionX - 200, y: initialPositionY + 2000 },
    data: { label: `NodeEnd2`, name: idRandom() },
    type: 'END',
  },
  {
    id: `node9`,
    position: { x: initialPositionX + 400, y: initialPositionY + 2200 },
    data: {
      label: `Node 9`,
      name: idRandom(),
      message: {
        id: 'abandoned-cart-third-condition',
      },
    },
    type: 'WHATSAPP',
  },
  {
    id: `node10`,
    position: { x: initialPositionX + 400, y: initialPositionY + 2800 },
    data: { label: `Node 10`, name: idRandom() },
    type: 'END',
  },
];
