import React from 'react';
import { Hotmart as HotmartIcon, Copy } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { EInfoProductProvider } from '@domain/enums/common/integrations/EInfoProductProvider';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { hotmartSchema } from '@helpers/validators/dashboard/integrationCenter/infoProduct/hotmart';

import {
  FIRST_STEP_TUTORIAL,
  SECOND_STEP_TUTORIAL,
  WEBHOOK_STEP_TUTORIAL,
} from '@constants/hotmartTutorialSteps';

import hotmartService from '@services/pages/dashboard/integrationCenter/infoProduct/platforms/hotmart';

import Text from '@components/common/core/DataDisplay/Text';
import ActiveIntegrationFooter from '@components/Dashboard/IntegrationCenter/Sidemodal/ActiveIntegrationFooter';
import { EHotmartType } from '@domain/enums/common/integrations/providers/EHotmart';
import HotmartConfig from './HotmartConfig';
import SkeletonLoading from './SkeletonLoading';
import ActiveIntegration from './ActiveIntegration';

import * as S from './styles';

const webhookURL = 'https://dosu-api.profitfy.me/prod/api/v1/webhooks/hotmart/events';

const Hotmart: React.FC = () => {
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { mutateStore, storeIntegrations } = useStoreConfig();
  const theme = useTheme();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(hotmartSchema),
  });

  const [isCreatingCredential, setIsCreatingCredential] = React.useState<boolean>(false);
  const [clientIdValue, setClientIdValue] = React.useState<string>('');
  const [clientSecretValue, setClientSecretValue] = React.useState<string>('');
  const [hasHotmartCredential, setHasHotmartCredential] = React.useState<boolean>(false);
  const [credentialAliasId, setCredentialAliasId] = React.useState<string>('');
  const [basicValue, setBasicValue] = React.useState<string>('');
  const [isGettingCredential, setIsGettingCredential] = React.useState<boolean>(false);
  const [providerId, setProviderId] = React.useState<string>('');
  const [isURLCopied, setIsURLCopied] = React.useState<boolean>(false);

  const hasHotmartIntegration = Boolean(
    storeIntegrations?.store_front.find(
      integration => integration.name === EInfoProductProvider.HOTMART && integration.is_active,
    ),
  );

  const clientIdInputRegister = register('client_id');
  const clientSecretInputRegister = register('client_secret');
  const basicInputRegister = register('basic');
  const hottokInputRegister = register('provider_id');

  const handleCopyURL = React.useCallback(() => {
    navigator.clipboard.writeText(webhookURL);
    setIsURLCopied(true);
    toast.success('URL copiada com sucesso.');
  }, [toast]);

  const onClientIdChange = React.useCallback(
    event => {
      clientIdInputRegister.onChange(event);

      setClientIdValue(event.target.value);
    },
    [clientIdInputRegister],
  );

  const onClientSecretChange = React.useCallback(
    event => {
      clientSecretInputRegister.onChange(event);

      setClientSecretValue(event.target.value);
    },
    [clientSecretInputRegister],
  );

  const onBasicChange = React.useCallback(
    event => {
      basicInputRegister.onChange(event);

      setBasicValue(event.target.value);
    },
    [basicInputRegister],
  );

  const onProviderIdChange = React.useCallback(
    event => {
      hottokInputRegister.onChange(event);

      setProviderId(event.target.value);
    },
    [hottokInputRegister],
  );

  const onSubmit = React.useCallback(
    async data => {
      setIsCreatingCredential(true);

      try {
        await hotmartService.createCredential({
          storeAliasId,
          data: {
            basic: data.basic,
            client_id: data.client_id,
            client_secret: data.client_secret,
            provider_id: data.provider_id,
            types: [EHotmartType.STORE],
          },
        });

        toast.success('Credencial criada com sucesso!');

        mutateStore();

        setIsCreatingCredential(false);
      } catch (error: any) {
        toast.error(getTranslatedIntegrationsError(error));
        setIsCreatingCredential(false);
      }
    },
    [toast, storeAliasId, mutateStore],
  );

  const getHotmartCredential = React.useCallback(async () => {
    setIsGettingCredential(true);

    try {
      const { data } = await hotmartService.getCredential({ storeAliasId });

      if (data?.hotmart_credential.is_active) {
        setHasHotmartCredential(true);
      }

      setCredentialAliasId(data?.hotmart_credential.alias_id);

      setIsGettingCredential(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsGettingCredential(false);
    } finally {
      setIsGettingCredential(false);
    }
  }, [toast, storeAliasId]);

  React.useEffect(() => {
    if (hasHotmartIntegration) {
      getHotmartCredential();
    }
  }, [getHotmartCredential, hasHotmartIntegration]);

  if (isGettingCredential) {
    return <SkeletonLoading />;
  }

  if (hasHotmartIntegration && !hasHotmartCredential) {
    return <ActiveIntegration credentialAliasId={credentialAliasId} />;
  }

  if (hasHotmartCredential) {
    return <HotmartConfig credentialAliasId={credentialAliasId} />;
  }

  const { title: firstStepTitle, link: firstStepLink, steps: firstSteps } = FIRST_STEP_TUTORIAL;
  const { title: secondStepTitle, link: secondStepLink, steps: secondSteps } = SECOND_STEP_TUTORIAL;
  const {
    title: webhookStepTitle,
    link: webhookStepLink,
    steps: webhookSteps,
  } = WEBHOOK_STEP_TUTORIAL;

  const hasAllInputsValues =
    Boolean(clientIdValue) &&
    Boolean(clientSecretValue) &&
    Boolean(basicValue) &&
    Boolean(providerId);

  return (
    <S.ContentWrapper>
      <S.HeadingWrapper>
        <HotmartIcon size={52} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com Hotmart
        </S.Heading>
      </S.HeadingWrapper>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>
        <S.FunctionalityDescriptionText>
          A integração com sua plataforma de info-produto serve para puxarmos seus dados de
          plataforma aumentando a exatidão dos seus dados.
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <S.TutorialWrapper>
        <S.TutotialTitle weight={ETextWeight.BOLD}>Tutorial de integração</S.TutotialTitle>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          1 -{' '}
          <S.Link href={webhookStepLink} target="_blank">
            {webhookStepTitle}
          </S.Link>
        </S.TutotialStep>

        <S.StepsWrapper>
          {webhookSteps.map(step => {
            return (
              <S.StepWrapper key={step.id}>
                <S.StepBullet />
                <S.StepText size={ETextSize.B5}>{step.text}</S.StepText>
              </S.StepWrapper>
            );
          })}
        </S.StepsWrapper>

        <S.WebhookURLWrapper onClick={handleCopyURL} type="button">
          <S.WebhookURL weight={ETextWeight.MEDIUM}>{webhookURL}</S.WebhookURL>
          <S.IconWrapper>
            <Copy size={18} color={theme.colors.gray[1]} outline={!isURLCopied} />
          </S.IconWrapper>
        </S.WebhookURLWrapper>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          2 -{' '}
          <S.Link href={firstStepLink} target="_blank">
            {firstStepTitle}
          </S.Link>
        </S.TutotialStep>

        <S.StepsWrapper>
          {firstSteps.map(step => {
            return (
              <S.StepWrapper key={step.id}>
                <S.StepBullet />
                <S.StepText size={ETextSize.B5}>{step.text}</S.StepText>
              </S.StepWrapper>
            );
          })}
        </S.StepsWrapper>

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label htmlFor="client_id">Client ID</S.Label>
            <S.Input
              {...clientIdInputRegister}
              id="client_id"
              type="text"
              value={clientIdValue}
              placeholder="Hotmart Client ID"
              onChange={onClientIdChange}
              isError={errors.client_id}
            />
            {errors.client_id && <Text isErrorFeedback>{errors.client_id.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label htmlFor="client_secret">Client Secret</S.Label>
            <S.Input
              {...clientSecretInputRegister}
              id="client_secret"
              type="text"
              value={clientSecretValue}
              placeholder="Hotmart Client Secret"
              onChange={onClientSecretChange}
              isError={errors.client_secret}
            />
            {errors.client_secret && <Text isErrorFeedback>{errors.client_secret.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label htmlFor="basic">Basic</S.Label>
            <S.Input
              {...basicInputRegister}
              id="basic"
              type="text"
              value={basicValue}
              placeholder="Hotmart Basic"
              onChange={onBasicChange}
              isError={errors.basic}
            />
            {errors.basic && <Text isErrorFeedback>{errors.basic.message}</Text>}
          </S.InputGroup>

          <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
            3 -{' '}
            <S.Link href={secondStepLink} target="_blank">
              {secondStepTitle}
            </S.Link>
          </S.TutotialStep>

          <S.StepsWrapper>
            {secondSteps.map(step => {
              return (
                <S.StepWrapper key={step.id}>
                  <S.StepBullet />
                  <S.StepText size={ETextSize.B5}>{step.text}</S.StepText>
                </S.StepWrapper>
              );
            })}
          </S.StepsWrapper>

          <S.InputGroup>
            <S.Label htmlFor="provider_id">Hottok de verificação</S.Label>
            <S.Input
              {...hottokInputRegister}
              id="basic"
              type="text"
              value={providerId}
              placeholder="Hotmart Hottok"
              onChange={onProviderIdChange}
              isError={errors.provider_id}
            />
            {errors.provider_id && <Text isErrorFeedback>{errors.provider_id.message}</Text>}
          </S.InputGroup>

          <ActiveIntegrationFooter
            isIntegrating={isCreatingCredential}
            disabled={isCreatingCredential || !hasAllInputsValues}
          />
        </S.Form>
      </S.TutorialWrapper>
    </S.ContentWrapper>
  );
};

export default Hotmart;
