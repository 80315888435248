export const ABANDONED_CART_TRIGGER_VARIABLES = [
  {
    name: 'link para checkout',
    nameToDisplay: '{{Link para checkout}}',
    data: '{{checkout_link}}',
  },
  {
    name: 'nome',
    nameToDisplay: '{{Nome}}',
    data: '{{first_name}}',
  },
  {
    name: 'total',
    nameToDisplay: '{{Valor total}}',
    data: '{{total_amount}}',
  },
  {
    name: 'email',
    nameToDisplay: '{{E-mail}}',
    data: '{{email_address}}',
  },
  {
    name: 'produto(s)',
    nameToDisplay: '{{Produto(s)}}',
    data: '{{products}}',
  },
  {
    name: 'produto(s) c/ variantes',
    nameToDisplay: '{{Produtos com variantes}}',
    data: '{{products_with_variant}}',
  },
  {
    name: 'telefone',
    nameToDisplay: '{{Telefone}}',
    data: '{{phone_number}}',
  },
  {
    name: 'nome completo',
    nameToDisplay: '{{Nome completo}}',
    data: '{{full_name}}',
  },
  {
    name: 'sobrenome',
    nameToDisplay: '{{Sobrenome}}',
    data: '{{last_name}}',
  },
];

export const PIX_TRIGGER_VARIABLES = [
  {
    name: 'nome',
    nameToDisplay: '{{Nome}}',
    data: '{{first_name}}',
  },
  {
    name: 'sobrenome',
    nameToDisplay: '{{Sobrenome}}',
    data: '{{last_name}}',
  },
  {
    name: 'nome completo',
    nameToDisplay: '{{Nome completo}}',
    data: '{{full_name}}',
  },
  {
    name: 'email',
    nameToDisplay: '{{E-mail}}',
    data: '{{email_address}}',
  },
  {
    name: 'telefone',
    nameToDisplay: '{{Telefone}}',
    data: '{{phone_number}}',
  },
  {
    name: 'produto(s)',
    nameToDisplay: '{{Produto(s)}}',
    data: '{{products}}',
  },
  {
    name: 'produto(s) c/ variantes',
    nameToDisplay: '{{Produtos com variantes}}',
    data: '{{products_with_variant}}',
  },
  {
    name: 'total',
    nameToDisplay: '{{Valor total}}',
    data: '{{total_amount}}',
  },
  {
    name: 'código do pix',
    nameToDisplay: '{{Código do pix}}',
    data: '{{pix_code}}',
  },
  {
    name: 'código do pedido',
    nameToDisplay: '{{Código do pedido}}',
    data: '{{order_id}}',
  },
  {
    name: 'endereço de entrega',
    nameToDisplay: '{{Endereço de entrega}}',
    data: '{{shipping_address}}',
  },
  {
    name: 'nome da loja',
    nameToDisplay: '{{Nome da loja}}',
    data: '{{store_name}}',
  },
  {
    name: 'link da loja',
    nameToDisplay: '{{Link da loja}}',
    data: '{{store_link}}',
  },
];

export const SIMPLE_SHIPMENT_TRIGGER_VARIABLES = [
  {
    name: 'nome',
    nameToDisplay: '{{Nome}}',
    data: '{{first_name}}',
  },
  {
    name: 'sobrenome',
    nameToDisplay: '{{Sobrenome}}',
    data: '{{last_name}}',
  },
  {
    name: 'nome completo',
    nameToDisplay: '{{Nome completo}}',
    data: '{{full_name}}',
  },
  {
    name: 'email',
    nameToDisplay: '{{E-mail}}',
    data: '{{email_address}}',
  },
  {
    name: 'telefone',
    nameToDisplay: '{{Telefone}}',
    data: '{{phone_number}}',
  },
  {
    name: 'produto(s)',
    nameToDisplay: '{{Produto(s)}}',
    data: '{{products}}',
  },
  {
    name: 'produto(s) c/ variantes',
    nameToDisplay: '{{Produtos com variantes}}',
    data: '{{products_with_variant}}',
  },
  {
    name: 'total',
    nameToDisplay: '{{Valor total}}',
    data: '{{total_amount}}',
  },
  {
    name: 'código do pix',
    nameToDisplay: '{{Código do pix}}',
    data: '{{pix_code}}',
  },
  {
    name: 'código do pedido',
    nameToDisplay: '{{Código do pedido}}',
    data: '{{order_id}}',
  },
  {
    name: 'endereço de entrega',
    nameToDisplay: '{{Endereço de entrega}}',
    data: '{{shipping_address}}',
  },
  {
    name: 'nome da loja',
    nameToDisplay: '{{Nome da loja}}',
    data: '{{store_name}}',
  },
  {
    name: 'link da loja',
    nameToDisplay: '{{Link da loja}}',
    data: '{{store_link}}',
  },
  {
    name: 'Código do rastreio',
    nameToDisplay: '{{Código do rastreio}}',
    data: '{{tracking_number}}',
  },
];

export const UPDATED_FULFILLMENT_TRIGGER_VARIABLES = [
  {
    name: 'nome',
    nameToDisplay: '{{Nome}}',
    data: '{{first_name}}',
  },
  {
    name: 'sobrenome',
    nameToDisplay: '{{Sobrenome}}',
    data: '{{last_name}}',
  },
  {
    name: 'nome completo',
    nameToDisplay: '{{Nome completo}}',
    data: '{{full_name}}',
  },
  {
    name: 'email',
    nameToDisplay: '{{E-mail}}',
    data: '{{email_address}}',
  },
  {
    name: 'telefone',
    nameToDisplay: '{{Telefone}}',
    data: '{{phone_number}}',
  },
  {
    name: 'produto(s)',
    nameToDisplay: '{{Produto(s)}}',
    data: '{{products}}',
  },
  {
    name: 'produto(s) c/ variantes',
    nameToDisplay: '{{Produtos com variantes}}',
    data: '{{products_with_variant}}',
  },
  {
    name: 'total',
    nameToDisplay: '{{Valor total}}',
    data: '{{total_amount}}',
  },
  {
    name: 'código do pix',
    nameToDisplay: '{{Código do pix}}',
    data: '{{pix_code}}',
  },
  {
    name: 'código do pedido',
    nameToDisplay: '{{Código do pedido}}',
    data: '{{order_id}}',
  },
  {
    name: 'endereço de entrega',
    nameToDisplay: '{{Endereço de entrega}}',
    data: '{{shipping_address}}',
  },
  {
    name: 'nome da loja',
    nameToDisplay: '{{Nome da loja}}',
    data: '{{store_name}}',
  },
  {
    name: 'link da loja',
    nameToDisplay: '{{Link da loja}}',
    data: '{{store_link}}',
  },
  {
    name: 'Código do rastreio',
    nameToDisplay: '{{Código do rastreio}}',
    data: '{{tracking_number}}',
  },
  {
    name: 'Link do rastreio',
    nameToDisplay: '{{Link do rastreio}}',
    data: '{{shipment_link}}',
  },
];

export const BOLETO_TRIGGER_VARIABLES = [
  {
    name: 'nome',
    nameToDisplay: '{{Nome}}',
    data: '{{first_name}}',
  },
  {
    name: 'sobrenome',
    nameToDisplay: '{{Sobrenome}}',
    data: '{{last_name}}',
  },
  {
    name: 'nome completo',
    nameToDisplay: '{{Nome completo}}',
    data: '{{full_name}}',
  },
  {
    name: 'email',
    nameToDisplay: '{{E-mail}}',
    data: '{{email_address}}',
  },
  {
    name: 'telefone',
    nameToDisplay: '{{Telefone}}',
    data: '{{phone_number}}',
  },
  {
    name: 'produto(s)',
    nameToDisplay: '{{Produto(s)}}',
    data: '{{products}}',
  },
  {
    name: 'produto(s) c/ variantes',
    nameToDisplay: '{{Produtos com variantes}}',
    data: '{{products_with_variant}}',
  },
  {
    name: 'total',
    nameToDisplay: '{{Valor total}}',
    data: '{{total_amount}}',
  },
  {
    name: 'link do boleto',
    nameToDisplay: '{{Link do boleto}}',
    data: '{{boleto_url}}',
  },
  {
    name: 'código do pedido',
    nameToDisplay: '{{Código do pedido}}',
    data: '{{order_id}}',
  },
  {
    name: 'data de expiração do boleto',
    nameToDisplay: '{{Data expiração do boleto}}',
    data: '{{boleto_expired_at}}',
  },
  {
    name: 'endereço de entrega',
    nameToDisplay: '{{Endereço de entrega}}',
    data: '{{shipping_address}}',
  },
];

export const REJECTED_PAYMENT_TRIGGER_VARIABLES = [
  {
    name: 'nome',
    nameToDisplay: '{{Nome}}',
    data: '{{first_name}}',
  },
  {
    name: 'sobrenome',
    nameToDisplay: '{{Sobrenome}}',
    data: '{{last_name}}',
  },
  {
    name: 'nome completo',
    nameToDisplay: '{{Nome completo}}',
    data: '{{full_name}}',
  },
  {
    name: 'email',
    nameToDisplay: '{{E-mail}}',
    data: '{{email_address}}',
  },
  {
    name: 'telefone',
    nameToDisplay: '{{Telefone}}',
    data: '{{phone_number}}',
  },
  {
    name: 'produto(s)',
    nameToDisplay: '{{Produto(s)}}',
    data: '{{products}}',
  },
  {
    name: 'produto(s) c/ variantes',
    nameToDisplay: '{{Produtos com variantes}}',
    data: '{{products_with_variant}}',
  },
  {
    name: 'total',
    nameToDisplay: '{{Valor total}}',
    data: '{{total_amount}}',
  },
  {
    name: 'Link do checkout',
    nameToDisplay: '{{Link do checkout}}',
    data: '{{checkout_link}}',
  },
  {
    name: 'código do pedido',
    nameToDisplay: '{{Código do pedido }}',
    data: '{{order_id}}',
  },
];

export const INFO_PRODUCT_ABANDONED_CART_TRIGGER_VARIABLES = [
  {
    name: 'nome',
    nameToDisplay: '{{Nome}}',
    data: '{{first_name}}',
  },
  {
    name: 'email',
    nameToDisplay: '{{E-mail}}',
    data: '{{email_address}}',
  },
  {
    name: 'produto(s)',
    nameToDisplay: '{{Produto(s)}}',
    data: '{{products}}',
  },
  {
    name: 'nome completo',
    nameToDisplay: '{{Nome completo}}',
    data: '{{full_name}}',
  },
  {
    name: 'sobrenome',
    nameToDisplay: '{{Sobrenome}}',
    data: '{{last_name}}',
  },
];

export const INFO_PRODUCT_PIX_TRIGGER_VARIABLES = [
  {
    name: 'nome',
    nameToDisplay: '{{Nome}}',
    data: '{{first_name}}',
  },
  {
    name: 'sobrenome',
    nameToDisplay: '{{Sobrenome}}',
    data: '{{last_name}}',
  },
  {
    name: 'nome completo',
    nameToDisplay: '{{Nome completo}}',
    data: '{{full_name}}',
  },
  {
    name: 'email',
    nameToDisplay: '{{E-mail}}',
    data: '{{email_address}}',
  },
  {
    name: 'produto(s)',
    nameToDisplay: '{{Produto(s)}}',
    data: '{{products}}',
  },
  {
    name: 'total',
    nameToDisplay: '{{Valor total}}',
    data: '{{total_amount}}',
  },
  {
    name: 'código do pix',
    nameToDisplay: '{{Código do pix}}',
    data: '{{pix_code}}',
  },
  {
    name: 'código do pedido',
    nameToDisplay: '{{Código do pedido}}',
    data: '{{order_id}}',
  },
  {
    name: 'endereço de entrega',
    nameToDisplay: '{{Endereço de entrega}}',
    data: '{{shipping_address}}',
  },
  {
    name: 'nome da loja',
    nameToDisplay: '{{Nome da loja}}',
    data: '{{store_name}}',
  },
  {
    name: 'link da loja',
    nameToDisplay: '{{Link da loja}}',
    data: '{{store_link}}',
  },
];

export const INFO_PRODUCT_BOLETO_TRIGGER_VARIABLES = [
  {
    name: 'nome',
    nameToDisplay: '{{Nome}}',
    data: '{{first_name}}',
  },
  {
    name: 'sobrenome',
    nameToDisplay: '{{Sobrenome}}',
    data: '{{last_name}}',
  },
  {
    name: 'nome completo',
    nameToDisplay: '{{Nome completo}}',
    data: '{{full_name}}',
  },
  {
    name: 'email',
    nameToDisplay: '{{E-mail}}',
    data: '{{email_address}}',
  },
  {
    name: 'produto(s)',
    nameToDisplay: '{{Produto(s)}}',
    data: '{{products}}',
  },
  {
    name: 'total',
    nameToDisplay: '{{Valor total}}',
    data: '{{total_amount}}',
  },
  {
    name: 'código do pedido',
    nameToDisplay: '{{Código do pedido}}',
    data: '{{order_id}}',
  },
  {
    name: 'endereço de entrega',
    nameToDisplay: '{{Endereço de entrega}}',
    data: '{{shipping_address}}',
  },
];

export const INFO_PRODUCT_REJECTED_PAYMENT_TRIGGER_VARIABLES = [
  {
    name: 'nome',
    nameToDisplay: '{{Nome}}',
    data: '{{first_name}}',
  },
  {
    name: 'sobrenome',
    nameToDisplay: '{{Sobrenome}}',
    data: '{{last_name}}',
  },
  {
    name: 'nome completo',
    nameToDisplay: '{{Nome completo}}',
    data: '{{full_name}}',
  },
  {
    name: 'email',
    nameToDisplay: '{{E-mail}}',
    data: '{{email_address}}',
  },
  {
    name: 'produto(s)',
    nameToDisplay: '{{Produto(s)}}',
    data: '{{products}}',
  },
  {
    name: 'total',
    nameToDisplay: '{{Valor total}}',
    data: '{{total_amount}}',
  },
];
