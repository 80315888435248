import { v4 as idRandom } from 'uuid';

import {
  EBoletoType,
  EBoletoOrderStatusPicker,
  EBoletoOrderStatusOptions,
} from '@domain/enums/dashboard/automations/node/conditionalNode/EBoleto';

const initialPositionX = 574;
const initialPositionY = 28;

export const BOLETO_STARTER_NODES = [
  {
    id: `node1`,
    position: { x: initialPositionX, y: initialPositionY },
    data: { label: `Node 1`, name: idRandom() },
    type: 'BOLETO',
  },
  {
    id: `node2`,
    position: { x: initialPositionX + 100, y: initialPositionY + 200 },
    data: {
      label: `Node 2`,
      name: idRandom(),
      message: {
        id: 'boleto-starter-first-condition',
      },
    },
    type: 'WHATSAPP',
  },
  {
    id: `node3`,
    position: { x: initialPositionX + 200, y: initialPositionY + 700 },
    data: {
      label: `Node 3`,
      rules: [{ unit: 'DAYS', amount: 1 }],
      name: idRandom(),
    },
    type: 'WAIT',
  },
  {
    id: `node4`,
    position: { x: initialPositionX + 300, y: initialPositionY + 900 },
    data: {
      label: `Node 4`,
      rules: [
        {
          id: idRandom(),
          type: EBoletoType.ORDER_STATUS,
          options: EBoletoOrderStatusOptions.NOT_EQUAL,
          input: EBoletoOrderStatusPicker.PAID,
        },
      ],
      name: idRandom(),
    },
    type: 'IF',
  },
  {
    id: `nodeEnd1`,
    position: { x: initialPositionX, y: initialPositionY + 1100 },
    data: { label: `NodeEnd1`, name: idRandom() },
    type: 'END',
  },
  {
    id: `node5`,
    position: { x: initialPositionX + 400, y: initialPositionY + 1100 },
    data: {
      label: `Node 5`,
      name: idRandom(),
      message: {
        id: 'boleto-starter-second-condition',
      },
    },
    type: 'WHATSAPP',
  },
  {
    id: `node6`,
    position: { x: initialPositionX + 500, y: initialPositionY + 1600 },
    data: { label: `Node 6`, name: idRandom() },
    type: 'END',
  },
];
