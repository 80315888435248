import React from 'react';
import { LineChart } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import * as S from './styles';

const BlurredContent: React.FC = () => {
  const theme = useTheme();

  return (
    <>
      <S.CpaAmount>0x</S.CpaAmount>

      <S.ForecastText>
        <LineChart outline size={12} color={theme.colors.gray[2]} />
        0x
      </S.ForecastText>

      <S.CpaLabel>Média</S.CpaLabel>
    </>
  );
};

export default BlurredContent;
