import React from 'react';
import { Pen, Copy } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { ICampaignsActionProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaignsAction';

import { useCampaigns } from '@helpers/hooks/pages/dashboard/adsManager/useCampaigns';

import RenamePopoverContent from '@components/Dashboard/AdsManager/Campaigns/CampaignsActions/RenamePopoverContent';
import ActionButtonWrapper from './ActionButtonWrapper';

import * as S from './styles';

const CampaignsAction: React.FC<ICampaignsActionProps> = ({ adSenseCampaignAliasId }) => {
  const theme = useTheme();
  const {
    handleDuplicateModalOpen,
    handleSelectedCampaignToAction,
    handleRenameModalOpen,
  } = useCampaigns();

  // const [isHoveringOrders, setIsHoveringOrders] = React.useState<boolean>(false);
  const [isHoveringEdit, setIsHoveringEdit] = React.useState<boolean>(false);
  const [isHoveringDuplicate, setIsHoveringDuplicate] = React.useState<boolean>(false);
  const [isHoveringRename, setIsHoveringRename] = React.useState<boolean>(false);
  const [isRenamePopoverOpen, setIsRenamePopoverOpen] = React.useState<boolean>(false);
  const [isEditPopoverOpen, setIsEditPopoverOpen] = React.useState<boolean>(false);

  const onDuplicateClick = React.useCallback(() => {
    handleSelectedCampaignToAction(adSenseCampaignAliasId);
    handleDuplicateModalOpen();
  }, [adSenseCampaignAliasId, handleSelectedCampaignToAction, handleDuplicateModalOpen]);

  const onRenamePopoverOpen = React.useCallback(
    status => {
      if (status === true) {
        handleRenameModalOpen(adSenseCampaignAliasId);
      } else {
        handleRenameModalOpen(undefined);
      }

      setIsRenamePopoverOpen(status);
    },
    [handleRenameModalOpen, adSenseCampaignAliasId],
  );

  const onEditPopoverOpen = React.useCallback(
    status => {
      if (status === true) {
        handleRenameModalOpen(adSenseCampaignAliasId);
      } else {
        handleRenameModalOpen(undefined);
      }

      setIsEditPopoverOpen(status);
    },
    [handleRenameModalOpen, adSenseCampaignAliasId],
  );

  return (
    <>
      <S.Wrapper>
        {/* <S.ActionButton
          onMouseEnter={() => setIsHoveringOrders(true)}
          onMouseLeave={() => setIsHoveringOrders(false)}
        >
          <S.ActionIconWrapper>
            <Box
              outline
              size={12}
              color={isHoveringOrders ? theme.colors.green.default : theme.colors.font}
            />
          </S.ActionIconWrapper>
          <S.ActionName>Pedidos</S.ActionName>
        </S.ActionButton> */}

        <S.CustomPopover open={isEditPopoverOpen} onOpenChange={onEditPopoverOpen}>
          <S.CustomPopover.Trigger
            onMouseEnter={() => setIsHoveringEdit(true)}
            onMouseLeave={() => setIsHoveringEdit(false)}
          >
            <S.RenameActionWrapper>
              <S.ActionIconWrapper>
                <Pen
                  outline
                  size={12}
                  color={isHoveringEdit ? theme.colors.green.default : theme.colors.font}
                />
              </S.ActionIconWrapper>
              <S.ActionName>Editar</S.ActionName>
            </S.RenameActionWrapper>
          </S.CustomPopover.Trigger>

          <RenamePopoverContent setIsRenamePopoverOpen={setIsEditPopoverOpen} />
        </S.CustomPopover>

        <S.ActionButton
          onMouseEnter={() => setIsHoveringDuplicate(true)}
          onMouseLeave={() => setIsHoveringDuplicate(false)}
          onClick={onDuplicateClick}
        >
          <S.ActionIconWrapper>
            <Copy
              outline
              size={12}
              color={isHoveringDuplicate ? theme.colors.green.default : theme.colors.font}
            />
          </S.ActionIconWrapper>
          <S.ActionName>Duplicar</S.ActionName>
        </S.ActionButton>
      </S.Wrapper>

      <S.RenameWrapper
        onMouseEnter={() => setIsHoveringRename(true)}
        onMouseLeave={() => setIsHoveringRename(false)}
      >
        <S.CustomPopover open={isRenamePopoverOpen} onOpenChange={onRenamePopoverOpen}>
          <S.CustomPopover.Trigger>
            <S.CustomTooltip>
              <S.CustomTooltip.Trigger asChild>
                <ActionButtonWrapper>
                  <Pen
                    outline
                    size={14}
                    color={isHoveringRename ? theme.colors.green.default : theme.colors.font}
                  />
                </ActionButtonWrapper>
              </S.CustomTooltip.Trigger>

              <S.CustomTooltip.Content side="top" sideOffset={5}>
                <S.RenameTooltipContent>Editar nome</S.RenameTooltipContent>

                <S.CustomTooltip.Arrow />
              </S.CustomTooltip.Content>
            </S.CustomTooltip>
          </S.CustomPopover.Trigger>

          <RenamePopoverContent setIsRenamePopoverOpen={setIsRenamePopoverOpen} />
        </S.CustomPopover>
      </S.RenameWrapper>
    </>
  );
};

export default CampaignsAction;
