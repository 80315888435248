import Text from '@components/common/core/DataDisplay/Text';
import { EModalStep } from '@domain/enums/common/EStepsModal';
import { useAutomationsManager } from '@helpers/hooks/pages/dashboard/automations/useAutomationsManager';
import { secondStepSchema } from '@helpers/validators/dashboard/automations/testSideModal/steps';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import Save from '../Save';
import * as S from './styles';

interface ISecondStepProps {
  handleTestAutomationStep: (step: EModalStep) => void;
}

const SecondStep: React.FC<ISecondStepProps> = ({ handleTestAutomationStep }) => {
  const { handleTestSideModalOpen, handleSetFormValues } = useAutomationsManager();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(secondStepSchema),
  });

  const [phoneValue, setPhoneValue] = React.useState<string>('');

  const onPhoneChange = React.useCallback(phoneNumber => {
    setPhoneValue(phoneNumber);
  }, []);

  const onSubmit = React.useCallback(
    formData => {
      handleTestAutomationStep(EModalStep.THIRD_STEP);
      handleSetFormValues(formData);
    },
    [handleSetFormValues, handleTestAutomationStep],
  );

  return (
    <>
      <S.TitleForm>Dados do comprador</S.TitleForm>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label htmlFor="first_name">Nome</S.Label>
          <S.Input
            {...register('first_name')}
            type="text"
            placeholder="Digite seu primeiro nome"
            id="first_name"
            isError={errors.first_name}
          />
          {errors.first_name && <Text isErrorFeedback>{errors.first_name.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label htmlFor="last_name">Sobrenome</S.Label>
          <S.Input
            {...register('last_name')}
            type="text"
            placeholder="Digite seu sobrenome"
            id="last_name"
            isError={errors.last_name}
          />
          {errors.last_name && <Text isErrorFeedback>{errors.last_name.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label htmlFor="email">E-mail</S.Label>
          <S.Input
            {...register('email')}
            type="text"
            placeholder="Digite seu e-mail"
            id="email"
            isError={errors.email}
          />
          {errors.email && <Text isErrorFeedback>{errors.email.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label htmlFor="phone">Whatsapp (será enviado nesse número)</S.Label>
          <S.PhoneInput
            {...register('phone')}
            country="br"
            value={phoneValue}
            onChange={onPhoneChange}
            inputProps={{ name: 'phone', autoFocus: true }}
            isError={errors.phone}
          />
          {errors.phone && <Text isErrorFeedback>{errors.phone.message}</Text>}
        </S.InputGroup>

        <Save onCancel={handleTestSideModalOpen} />
      </S.Form>
    </>
  );
};

export default SecondStep;
