import {
  IFinancialReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresMarketingIntegrationsValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresMarketingIntegrationsValues = (
  currentSelectedStoreFinancialReportData: IFinancialReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresMarketingIntegrationsValuesResponse => {
  const currentSelectedStoreFacebookAmountValue =
    currentSelectedStoreFinancialReportData?.marketing?.facebook_amount;

  const currentSelectedStoreGoogleAmountValue =
    currentSelectedStoreFinancialReportData?.marketing?.google_amount;

  const currentSelectedStoreTaboolaAmountValue =
    currentSelectedStoreFinancialReportData?.marketing?.taboola_amount;

  const storesFacebookAmountValues = selectedStoresData.map(
    value => value.financial_report?.marketing?.facebook_amount,
  );

  const storesGoogleAmountValues = selectedStoresData.map(
    value => value.financial_report?.marketing?.google_amount,
  );

  const storesTaboolaAmountValues = selectedStoresData.map(
    value => value.financial_report?.marketing?.taboola_amount,
  );

  const facebookAmountValues = [
    ...storesFacebookAmountValues,
    currentSelectedStoreFacebookAmountValue,
  ];

  const googleAmountValues = [...storesGoogleAmountValues, currentSelectedStoreGoogleAmountValue];

  const taboolaAmountValues = [
    ...storesTaboolaAmountValues,
    currentSelectedStoreTaboolaAmountValue,
  ];

  const reducedFacebookAmountValues = facebookAmountValues.reduce((totalValue, currentValue) => {
    return Number(totalValue) + Number(currentValue);
  });

  const reducedGoogleAmountValues = googleAmountValues.reduce((totalValue, currentValue) => {
    return Number(totalValue) + Number(currentValue);
  });

  const reducedTaboolaAmountValues = taboolaAmountValues.reduce((totalValue, currentValue) => {
    return Number(totalValue) + Number(currentValue);
  });

  return {
    facebook_amount: reducedFacebookAmountValues,
    google_amount: reducedGoogleAmountValues,
    taboola_amount: reducedTaboolaAmountValues,
  };
};
