import {
  Appmax,
  MercadoPago,
  Hubsale,
  CartPanda,
  Pagarme,
  PagSeguro,
  Upnid,
  Linepay,
  Istpay,
  Cloudfox,
  Cielo,
  PagSeguroV4,
  HubsaleV2,
  IntegrationsCircle,
  Zouti,
  Abmex,
  Yever,
  DomPagamentos,
} from '@profitfy/pakkun-icons';

import { EGateway } from '@domain/enums/common/EGateway';
import { IGateway } from '@domain/interfaces/common/integration/IGateway';

import CartPandaPaySideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/Gateway/CartPandaPay';
import HubSaleSideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/Gateway/Hubsale';
import MercadoPagoSideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/Gateway/MercadoPago';
import PagarmeSideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/Gateway/Pagarme';
import PagSeguroSideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/Gateway/Pagseguro';
import UpnidSideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/Gateway/Upnid';
import ZoutiPaySideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/Gateway/ZoutiPay';
import OtherGatewaySideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/Gateway/OtherGateway';
import LinepaySideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/Gateway/PredefinedGateways/Linepay';
import IstpaySideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/Gateway/PredefinedGateways/Istpay';
import CieloSideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/Gateway/PredefinedGateways/Cielo';
import AppmaxSideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/Gateway/PredefinedGateways/Appmax';
import CloudfoxSideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/Gateway/PredefinedGateways/Cloudfox';
import HubSaleV2SideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/Gateway/PredefinedGateways/Hubsale';
import PagSeguroV4SideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/Gateway/PredefinedGateways/Pagseguro';
import AbmexSideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/Gateway/PredefinedGateways/Abmex';
import YeverSideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/Gateway/PredefinedGateways/Yever';
import DomPagamentosSideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/Gateway/PredefinedGateways/DomPagamentos';

import CartPandaPaySideModal from '@components/Dashboard/IntegrationCenter/Sidemodal/Gateway/CartPandaPay';
import UpnidSideModal from '@components/Dashboard/IntegrationCenter/Sidemodal/Gateway/Upnid';
import ZoutiPaySideModal from '@components/Dashboard/IntegrationCenter/Sidemodal/Gateway/ZoutiPay';
import HubsaleSideModal from '@components/Dashboard/IntegrationCenter/Sidemodal/Gateway/Hubsale';
import PagarmeSideModal from '@components/Dashboard/IntegrationCenter/Sidemodal/Gateway/Pagarme';
import PagseguroSideModal from '@components/Dashboard/IntegrationCenter/Sidemodal/Gateway/Pagseguro';
import MercadoPagoSideModal from '@components/Dashboard/IntegrationCenter/Sidemodal/Gateway/MercadoPago';
import OtherGatewaySideModal from '@components/Dashboard/IntegrationCenter/Sidemodal/Gateway/OtherGateway/Wrapper';
import LinepaySideModal from '@components/Dashboard/IntegrationCenter/Sidemodal/Gateway/PredefinedGateways/Linepay/Wrapper';
import IstpaySideModal from '@components/Dashboard/IntegrationCenter/Sidemodal/Gateway/PredefinedGateways/Istpay/Wrapper';
import CloudfoxSideModal from '@components/Dashboard/IntegrationCenter/Sidemodal/Gateway/PredefinedGateways/Cloudfox/Wrapper';
import CieloSideModal from '@components/Dashboard/IntegrationCenter/Sidemodal/Gateway/PredefinedGateways/Cielo/Wrapper';
import AppmaxSideModal from '@components/Dashboard/IntegrationCenter/Sidemodal/Gateway/PredefinedGateways/Appmax/Wrapper';
import AbmexSideModal from '@components/Dashboard/IntegrationCenter/Sidemodal/Gateway/PredefinedGateways/Abmex/Wrapper';
import PagseguroV4SideModal from '@components/Dashboard/IntegrationCenter/Sidemodal/Gateway/PredefinedGateways/Pagseguro/Wrapper';
import HubsaleV2SideModal from '@components/Dashboard/IntegrationCenter/Sidemodal/Gateway/PredefinedGateways/Hubsale/Wrapper';
import YeverSideModal from '@components/Dashboard/IntegrationCenter/Sidemodal/Gateway/PredefinedGateways/Yever/Wrapper';
import DomPagamentosSideModal from '@components/Dashboard/IntegrationCenter/Sidemodal/Gateway/PredefinedGateways/DomPagamentos/Wrapper';

import OnboardingCartPandaPayContent from '@components/Onboarding/Dropshipping/Gateways/CartPandaPay';
import OnboardingHubSalePayContent from '@components/Onboarding/Dropshipping/Gateways/Hubsale';
import OnboardingMercadoPagoPayContent from '@components/Onboarding/Dropshipping/Gateways/MercadoPago';
import OnboardingPagarmePayContent from '@components/Onboarding/Dropshipping/Gateways/Pagarme';
import OnboardingPagSeguroPayContent from '@components/Onboarding/Dropshipping/Gateways/PagSeguro';
import OnboardingUpnidPayContent from '@components/Onboarding/Dropshipping/Gateways/Upnid';
import OnboardingZoutiPayContent from '@components/Onboarding/Dropshipping/Gateways/ZoutiPay';
import OnboardingOtherGatewayContent from '@components/Onboarding/Dropshipping/Gateways/OtherGateway';
import OnboardingCieloContent from '@components/Onboarding/Dropshipping/Gateways/PredefinedGateways/Cielo';
import OnboardingLinepayContent from '@components/Onboarding/Dropshipping/Gateways/PredefinedGateways/Linepay';
import OnboardingIstpayContent from '@components/Onboarding/Dropshipping/Gateways/PredefinedGateways/Istpay';
import OnboardingAppmaxContent from '@components/Onboarding/Dropshipping/Gateways/PredefinedGateways/Appmax';
import OnboardingCloudfoxContent from '@components/Onboarding/Dropshipping/Gateways/PredefinedGateways/Cloudfox';
import OnboardingHubsaleV2Content from '@components/Onboarding/Dropshipping/Gateways/PredefinedGateways/Hubsale';
import OnboardingPagSeguroV4Content from '@components/Onboarding/Dropshipping/Gateways/PredefinedGateways/Pagseguro';
import OnboardingAbmexContent from '@components/Onboarding/Dropshipping/Gateways/PredefinedGateways/Abmex';
import OnboardingYeverContent from '@components/Onboarding/Dropshipping/Gateways/PredefinedGateways/Yever';
import OnboardingDomPagamentosContent from '@components/Onboarding/Dropshipping/Gateways/PredefinedGateways/DomPagamentos';

export const GATEWAYS: Array<IGateway> = [
  {
    id: 'zouti',
    name: 'Zouti',
    icon: Zouti,
    sideModal: ZoutiPaySideModal,
    idToVerifyStatus: 'has_zouti_credential',
    identifier: EGateway.ZOUTI,
    sideModalContent: ZoutiPaySideModalContent,
    lowerIdentifier: 'zouti',
    onboardingContent: OnboardingZoutiPayContent,
    synchronizationName: 'zouti',
    isPredefinedOtherGateway: false,
  },
  {
    id: 'mercadopago',
    name: 'Mercado Pago',
    icon: MercadoPago,
    sideModal: MercadoPagoSideModal,
    idToVerifyStatus: 'has_mercado_pago_credential',
    identifier: EGateway.MERCADO_PAGO,
    sideModalContent: MercadoPagoSideModalContent,
    lowerIdentifier: 'mercado_pago',
    onboardingContent: OnboardingMercadoPagoPayContent,
    synchronizationName: 'mercado-pago',
    isPredefinedOtherGateway: false,
  },
  {
    id: 'hubsale',
    name: 'Doppus',
    icon: Hubsale,
    sideModal: HubsaleSideModal,
    idToVerifyStatus: 'has_hub_sale_credential',
    identifier: EGateway.HUB_SALE,
    sideModalContent: HubSaleSideModalContent,
    lowerIdentifier: 'hub_sale',
    onboardingContent: OnboardingHubSalePayContent,
    synchronizationName: 'hub-sale',
    isPredefinedOtherGateway: false,
  },
  {
    id: 'cartpandapay',
    name: 'CartPanda Pay',
    icon: CartPanda,
    sideModal: CartPandaPaySideModal,
    idToVerifyStatus: 'has_cart_panda_pay_credential',
    identifier: EGateway.CART_PANDA_PAY,
    sideModalContent: CartPandaPaySideModalContent,
    lowerIdentifier: 'cart_panda_pay',
    onboardingContent: OnboardingCartPandaPayContent,
    synchronizationName: 'cart-panda-pay',
    isPredefinedOtherGateway: false,
  },
  {
    id: 'pagarme',
    name: 'Pagar.me',
    icon: Pagarme,
    sideModal: PagarmeSideModal,
    idToVerifyStatus: 'has_pagarme_credential',
    identifier: EGateway.PAGARME,
    sideModalContent: PagarmeSideModalContent,
    lowerIdentifier: 'pagarme',
    onboardingContent: OnboardingPagarmePayContent,
    synchronizationName: 'pagarme',
    isPredefinedOtherGateway: false,
  },
  {
    id: 'pagseguro',
    name: 'PagSeguro',
    icon: PagSeguro,
    sideModal: PagseguroSideModal,
    idToVerifyStatus: 'has_pag_seguro_credential',
    identifier: EGateway.PAG_SEGURO,
    sideModalContent: PagSeguroSideModalContent,
    lowerIdentifier: 'pag_seguro',
    onboardingContent: OnboardingPagSeguroPayContent,
    synchronizationName: 'pag-seguro',
    isPredefinedOtherGateway: false,
  },
  {
    id: 'upnid',
    name: 'Upnid',
    icon: Upnid,
    sideModal: UpnidSideModal,
    idToVerifyStatus: 'has_upnid_credential',
    identifier: EGateway.UPNID,
    sideModalContent: UpnidSideModalContent,
    lowerIdentifier: 'upnid',
    onboardingContent: OnboardingUpnidPayContent,
    synchronizationName: 'upnid',
    isPredefinedOtherGateway: false,
  },
  {
    id: 'linepay',
    name: 'Linepay',
    icon: Linepay,
    sideModal: LinepaySideModal,
    idToVerifyStatus: 'has_linepay_credential',
    identifier: EGateway.LINEPAY,
    sideModalContent: LinepaySideModalContent,
    lowerIdentifier: 'linepay',
    onboardingContent: OnboardingLinepayContent,
    synchronizationName: 'other',
    isPredefinedOtherGateway: true,
  },
  {
    id: 'istpay',
    name: 'Istpay',
    icon: Istpay,
    sideModal: IstpaySideModal,
    idToVerifyStatus: 'has_istpay_credential',
    identifier: EGateway.ISTPAY,
    sideModalContent: IstpaySideModalContent,
    lowerIdentifier: 'istpay',
    onboardingContent: OnboardingIstpayContent,
    synchronizationName: 'other',
    isPredefinedOtherGateway: true,
  },
  {
    id: 'cloudfox',
    name: 'CloudFox',
    icon: Cloudfox,
    sideModal: CloudfoxSideModal,
    idToVerifyStatus: 'has_cloud_fox_credential',
    identifier: EGateway.CLOUD_FOX,
    sideModalContent: CloudfoxSideModalContent,
    lowerIdentifier: 'cloudfox',
    onboardingContent: OnboardingCloudfoxContent,
    synchronizationName: 'other',
    isPredefinedOtherGateway: true,
  },
  {
    id: 'cielo',
    name: 'Cielo',
    icon: Cielo,
    sideModal: CieloSideModal,
    idToVerifyStatus: 'has_cielo_credential',
    identifier: EGateway.CIELO,
    sideModalContent: CieloSideModalContent,
    lowerIdentifier: 'cielo',
    onboardingContent: OnboardingCieloContent,
    synchronizationName: 'other',
    isPredefinedOtherGateway: true,
  },
  {
    id: 'appmax',
    name: 'Appmax',
    icon: Appmax,
    sideModal: AppmaxSideModal,
    idToVerifyStatus: 'has_appmax_credential',
    identifier: EGateway.APPMAX,
    sideModalContent: AppmaxSideModalContent,
    lowerIdentifier: 'appmax',
    onboardingContent: OnboardingAppmaxContent,
    synchronizationName: 'other',
    isPredefinedOtherGateway: true,
  },
  {
    id: 'pagseguro_v4',
    name: 'PagSeguro V4',
    icon: PagSeguroV4,
    sideModal: PagseguroV4SideModal,
    idToVerifyStatus: 'has_pag_seguro_v4_credential',
    identifier: EGateway.PAG_SEGURO_V4,
    sideModalContent: PagSeguroV4SideModalContent,
    lowerIdentifier: 'pag_seguro_v4',
    onboardingContent: OnboardingPagSeguroV4Content,
    synchronizationName: 'other',
    isPredefinedOtherGateway: true,
  },
  {
    id: 'hubsale_v2',
    name: 'Doppus V2',
    icon: HubsaleV2,
    sideModal: HubsaleV2SideModal,
    idToVerifyStatus: 'has_hub_sale_v2_credential',
    identifier: EGateway.HUB_SALE_V2,
    sideModalContent: HubSaleV2SideModalContent,
    lowerIdentifier: 'hub_sale',
    onboardingContent: OnboardingHubsaleV2Content,
    synchronizationName: 'other',
    isPredefinedOtherGateway: true,
  },
  {
    id: 'abmex',
    name: 'Abmex',
    icon: Abmex,
    sideModal: AbmexSideModal,
    idToVerifyStatus: 'has_abmex_credential',
    identifier: EGateway.ABMEX,
    sideModalContent: AbmexSideModalContent,
    lowerIdentifier: 'abmex',
    onboardingContent: OnboardingAbmexContent,
    synchronizationName: 'other',
    isPredefinedOtherGateway: true,
  },
  {
    id: 'yever',
    name: 'Yever',
    icon: Yever,
    sideModal: YeverSideModal,
    idToVerifyStatus: 'has_yever_credential',
    identifier: EGateway.YEVER,
    sideModalContent: YeverSideModalContent,
    lowerIdentifier: 'yever',
    onboardingContent: OnboardingYeverContent,
    synchronizationName: 'other',
    isPredefinedOtherGateway: true,
  },
  {
    id: 'dompagamentos',
    name: 'Dom Pagamentos',
    icon: DomPagamentos,
    sideModal: DomPagamentosSideModal,
    idToVerifyStatus: 'has_dom_pagamentos_credential',
    identifier: EGateway.DOM_PAGAMENTOS,
    sideModalContent: DomPagamentosSideModalContent,
    lowerIdentifier: 'dompagamentos',
    onboardingContent: OnboardingDomPagamentosContent,
    synchronizationName: 'other',
    isPredefinedOtherGateway: true,
  },
  {
    id: 'other_gateway',
    name: 'Outros',
    icon: IntegrationsCircle,
    sideModal: OtherGatewaySideModal,
    idToVerifyStatus: 'has_other_gateway_credential',
    identifier: EGateway.OTHER_GATEWAY,
    sideModalContent: OtherGatewaySideModalContent,
    lowerIdentifier: 'other_gateway',
    onboardingContent: OnboardingOtherGatewayContent,
    synchronizationName: 'other',
    isPredefinedOtherGateway: false,
  },
];
