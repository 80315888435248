import React from 'react';
import { ChevronDown, ChevronUp } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { useSort } from '@helpers/hooks/components/useSort';
import SortContent from '../SortContent';

import * as S from './styles';

const Wrapper: React.FC = props => {
  const { selectedField, config } = useSort();
  const theme = useTheme();

  const foundSelectedFieldConfig = config.find(field => field.value === selectedField?.field);

  return (
    <S.Popover>
      <S.SortButton {...props}>
        {foundSelectedFieldConfig?.label || 'Ordenar por'}
        {selectedField?.value === 'ASC' ? (
          <ChevronUp size={20} color={theme.colors.font} />
        ) : (
          <ChevronDown size={20} color={theme.colors.font} />
        )}
      </S.SortButton>

      <SortContent />
    </S.Popover>
  );
};

export default Wrapper;
