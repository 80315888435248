import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ESelectedOption } from '@domain/enums/dashboard/automations/ETemplate';

import { useTemplate } from '@helpers/hooks/pages/dashboard/automations/useTemplate';

import * as S from './styles';

const TemplateOptions: React.FC = () => {
  const { selectedOption, handleSelectedOption } = useTemplate();

  const onOptionClick = React.useCallback(
    event => {
      handleSelectedOption(event.target.value);
    },
    [handleSelectedOption],
  );

  return (
    <S.Wrapper>
      <S.Heading type={EHeadingSize.H3} fontWeight={EHeadingWeight.REGULAR}>
        Escolha um template
      </S.Heading>

      <S.OptionsWrapper>
        <S.Option
          value={ESelectedOption.ALL_TEMPLATES}
          isSelected={selectedOption === ESelectedOption.ALL_TEMPLATES}
          onClick={onOptionClick}
        >
          Todos os templates
        </S.Option>

        <S.Option
          value={ESelectedOption.ECOMMERCE}
          isSelected={selectedOption === ESelectedOption.ECOMMERCE}
          onClick={onOptionClick}
        >
          E-commerce
        </S.Option>

        <S.Option
          value={ESelectedOption.INFOPRODUCT}
          isSelected={selectedOption === ESelectedOption.INFOPRODUCT}
          onClick={onOptionClick}
        >
          Infoproduto
        </S.Option>
      </S.OptionsWrapper>
    </S.Wrapper>
  );
};

export default TemplateOptions;
