import React from 'react';

import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { useDate } from '@helpers/hooks/useDate';

import * as S from './styles';

const Trial: React.FC = () => {
  const { subscription } = useStoreSubscription();
  const { differenceInDays, utcToZonedTime } = useDate();

  const currentDate = utcToZonedTime(new Date());
  const lastTrialDay = utcToZonedTime(subscription?.period_ended_at);
  const remainingDays = differenceInDays(currentDate, lastTrialDay);

  const getRemainingDaysText = React.useCallback(() => {
    if (remainingDays > 1) return `${remainingDays} dias restantes`;

    if (remainingDays === 1) return `${remainingDays} dia restante`;

    return 'Seu trial acaba hoje';
  }, [remainingDays]);

  return (
    <S.CardWrapper>
      <S.Content>
        <S.PlanNameAndPricingWrapper>
          <S.PlanName>Trial</S.PlanName>
          <S.PricingValue>{getRemainingDaysText()}</S.PricingValue>
        </S.PlanNameAndPricingWrapper>

        <S.Divider />

        <S.Description>Obrigado por testar a nossa plataforma!</S.Description>
      </S.Content>
    </S.CardWrapper>
  );
};

export default Trial;
