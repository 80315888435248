import React from 'react';

import { useLocation } from 'react-router-dom';

import { IRetrospectiveDataProvider } from '@domain/interfaces/hooks/IRetrospectiveData';

import trackRetrospectiveDataService from '@services/hooks/retrospective';

import { useToast } from './useToast';

const RetrospectiveDataContext = React.createContext<IRetrospectiveDataProvider | null>(null);

export const RetrospectiveDataProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { pathname } = useLocation();

  const [storeAliasId, setStoreAliasId] = React.useState<string>('');

  const trackFinancialDashboardView = React.useCallback(async () => {
    try {
      await trackRetrospectiveDataService.trackFinancialDashboardView({
        storeAliasId,
        data: {
          total_active_users: 0,
          total_custom_value_added: 0,
          total_financial_product_dashboard_views: 0,
          total_product_cost_edited: 0,
          total_active_ad_sense_campaigns: 0,
          total_financial_dashboard_views: 1,
        },
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [toast, storeAliasId]);

  const trackProductAnalyticsView = React.useCallback(async () => {
    try {
      await trackRetrospectiveDataService.trackProductAnalyticsView({
        storeAliasId,
        data: {
          total_active_users: 0,
          total_custom_value_added: 0,
          total_financial_dashboard_views: 0,
          total_product_cost_edited: 0,
          total_active_ad_sense_campaigns: 0,
          total_financial_product_dashboard_views: 1,
        },
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [toast, storeAliasId]);

  const trackProductCostEditedQuantity = React.useCallback(async () => {
    try {
      await trackRetrospectiveDataService.trackProductCostEditedQuantity({
        storeAliasId,
        data: {
          total_active_users: 0,
          total_custom_value_added: 0,
          total_financial_dashboard_views: 0,
          total_financial_product_dashboard_views: 0,
          total_active_ad_sense_campaigns: 0,
          total_product_cost_edited: 1,
        },
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [toast, storeAliasId]);

  const trackCustomValuesAddedQuantity = React.useCallback(async () => {
    try {
      await trackRetrospectiveDataService.trackEditedProductCostQuantity({
        storeAliasId,
        data: {
          total_active_users: 0,
          total_financial_dashboard_views: 0,
          total_financial_product_dashboard_views: 0,
          total_product_cost_edited: 0,
          total_active_ad_sense_campaigns: 0,
          total_custom_value_added: 1,
        },
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [toast, storeAliasId]);

  const trackActiveCampaignsQuantity = React.useCallback(async () => {
    try {
      await trackRetrospectiveDataService.trackActiveCampaignsQuantity({
        storeAliasId,
        data: {
          total_active_users: 0,
          total_custom_value_added: 0,
          total_financial_dashboard_views: 0,
          total_financial_product_dashboard_views: 0,
          total_product_cost_edited: 0,
          total_active_ad_sense_campaigns: 1,
        },
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [toast, storeAliasId]);

  const trackActiveInvitedUsersQuantity = React.useCallback(async () => {
    try {
      await trackRetrospectiveDataService.trackActiveInvitedUsersQuantity({
        storeAliasId,
        data: {
          total_custom_value_added: 0,
          total_financial_dashboard_views: 0,
          total_financial_product_dashboard_views: 0,
          total_product_cost_edited: 0,
          total_active_ad_sense_campaigns: 0,
          total_active_users: 1,
        },
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [toast, storeAliasId]);

  React.useEffect(() => {
    const foundStoreAliasId = pathname.match(/store-([a-zA-Z0-9]+)/);

    if (foundStoreAliasId?.length) {
      setStoreAliasId(foundStoreAliasId[0]);
    }
  }, [pathname]);

  return (
    <RetrospectiveDataContext.Provider
      value={{
        trackActiveCampaignsQuantity,
        trackActiveInvitedUsersQuantity,
        trackCustomValuesAddedQuantity,
        trackFinancialDashboardView,
        trackProductAnalyticsView,
        trackProductCostEditedQuantity,
      }}
    >
      {children}
    </RetrospectiveDataContext.Provider>
  );
};

export const useRetrospectiveData = (): IRetrospectiveDataProvider => {
  const context = React.useContext(RetrospectiveDataContext);

  if (!context) {
    throw new Error('useRetrospectiveData must be used within RetrospectiveDataProvider');
  }

  return context;
};
