import { v4 } from 'uuid';

import {
  EUpdateFulfillmentType,
  EUpdateFulfillmentProductOptions,
} from '@domain/enums/dashboard/automations/node/conditionalNode/EUpdateFulfillmente';

export const DEFAULT_UPDATE_FULFILLMENT_CONTENT = {
  id: v4(),
  type: EUpdateFulfillmentType.PRODUCT_NAME,
  options: EUpdateFulfillmentProductOptions.CONTAINS,
  input: '',
};
