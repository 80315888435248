import React from 'react';

import { EPixOrderStatusPicker } from '@domain/enums/dashboard/automations/node/conditionalNode/EPix';

import * as S from './styles';

interface IPickerOrderStatusProps {
  defaultValue?: string;
  onChange: (data: string) => void;
}

const PickerOrderStatus: React.FC<IPickerOrderStatusProps> = ({
  defaultValue = EPixOrderStatusPicker.PAID,
  onChange,
}) => {
  return (
    <S.Select
      name="picker"
      defaultValue={defaultValue}
      onChange={e => {
        onChange(e.target.value);
      }}
    >
      <S.Option value={EPixOrderStatusPicker.CANCELED}>Cancelado</S.Option>
      <S.Option value={EPixOrderStatusPicker.CHARGED_BACK}>Chargedback</S.Option>
      <S.Option value={EPixOrderStatusPicker.IN_ANALYSIS}>Em análise</S.Option>
      <S.Option value={EPixOrderStatusPicker.PAID}>Pago</S.Option>
      <S.Option value={EPixOrderStatusPicker.PENDING}>Pendente</S.Option>
      <S.Option value={EPixOrderStatusPicker.REFUNDED}>Reembolsado</S.Option>
      <S.Option value={EPixOrderStatusPicker.REJECTED}>Rejeitado</S.Option>
    </S.Select>
  );
};

export default PickerOrderStatus;
