export default {
  behindLayer: -1,
  firstLayer: 1,
  secondLayer: 9,
  thirdLayer: 99,
  fourthLayer: 999,
  fifthLayer: 9999,
  sixthLayer: 99999,
  seventhLayer: 999999,
};
