import LabelBase from '@components/common/core/DataDisplay/Label';
import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import styled from 'styled-components/macro';

export const Question = styled(TextBase)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray[1]};
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.16px;
`;

export const Description = styled(TextBase)`
  line-height: 105%;
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray[1]};
  font-size: 0.875rem;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const InputGroup = styled(InputGroupBase)``;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)`
  width: 100%;
  max-width: 572px;
`;

export const Select = styled(SelectBase)`
  width: 100%;
  max-width: 572px;

  & > label > select {
    width: 100%;
  }
`;

export const Option = styled(OptionBase)``;
