import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';

import Form from '@components/common/core/Inputs/Form';
import { useNewKit } from '@helpers/hooks/pages/dashboard/kit/useNewKit';
import { newKitSchema } from '@helpers/validators/dashboard/kit/newKit';
import GoBack from '@components/common/core/Utils/GoBack';
import KitName from '../KitName';
import Variants from '../Variants';
import KitCost from '../KitCost';
import Submit from '../Submit';
import SyncModal from '../SyncModal';
import SyncSideModal from '../SyncSideModal';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const history = useHistory();

  const { hasKitName, isVariantsValid, createNewKit } = useNewKit();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(newKitSchema),
  });

  const onSubmit = React.useCallback(data => createNewKit(data), [createNewKit]);

  const handleGoBack = React.useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <S.Container>
      <S.Header>
        <GoBack text="Cadastrar custo do kit" onClick={handleGoBack} />
      </S.Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <KitName register={register} errors={errors} />

        {hasKitName ? <Variants /> : <></>}

        {hasKitName && isVariantsValid() ? <KitCost register={register} errors={errors} /> : <></>}

        {hasKitName && isVariantsValid() ? <Submit /> : <></>}

        <SyncModal />

        <SyncSideModal />
      </Form>
    </S.Container>
  );
};

export default Wrapper;
