import React from 'react';

import { useTax } from '@helpers/hooks/pages/dashboard/tax/useTax';
import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const DefaultTax: React.FC = () => {
  const { defaultTax } = useTax();

  return (
    <S.DefaultTaxWrapper>
      <S.TaxText>{`${numberFormatter(defaultTax?.percentage_amount || 0, 2)}%`}</S.TaxText>
      <S.TaxSubtitle>Imposto atual</S.TaxSubtitle>
    </S.DefaultTaxWrapper>
  );
};

export default DefaultTax;
