import React from 'react';
import { SimpleCheck } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { EHeadingSize } from '@domain/enums/components/EHeading';
import { EPlanRecurrence, EStatus } from '@domain/enums/subscription/ESubscription';
import { IPlanProps } from '@domain/interfaces/pricing/plans/IPlan';
import { IParams } from '@domain/interfaces/IParams';

import { useSubscription } from '@helpers/hooks/common/store/subscription/useSubscription';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { useToast } from '@helpers/hooks/useToast';
import { useDowngrade } from '@helpers/hooks/common/store/subscription/useDowngrade';
import { useConfig } from '@helpers/hooks/useConfig';

import { numberFormatter } from '@helpers/masks';

import { PLANS } from '@constants/plans';

import * as S from './styles';

const Plan: React.FC<IPlanProps> = ({ plan, selectedRecurrenceType, index }) => {
  const isMonthlyRecurrence = selectedRecurrenceType === EPlanRecurrence.MONTHLY;
  const isQuarterRecurrence = selectedRecurrenceType === EPlanRecurrence.QUARTER;
  const isSemiAnualRecurrence = selectedRecurrenceType === EPlanRecurrence.SEMIANUAL;

  const theme = useTheme();
  const history = useHistory();
  const { handleSelectedPlanIdentifier, handleSelectedRecurrenceType } = useSubscription();
  const { storeAliasId } = useParams<IParams>();
  const { subscription } = useStoreSubscription();
  const { toast } = useToast();
  const { user, personalInfo, analytics } = useConfig();
  const {
    verifyDowngrade,
    handleDowngradeModalOpen,
    isVerifyingDowngrade,
    handleVerifyingDowngrade,
    isDowngradeModalOpen,
  } = useDowngrade();

  const isMostPopularPlan = plan.name === 'Essentials';
  const isEnterprisePlan = plan.name === 'Enterprise';
  const isLitePlan = plan.name === 'Lite';
  const isUnlimitedPlan = plan.name === 'Unlimited';

  const isDefaultPlan = PLANS.find(
    defaultPlan =>
      defaultPlan.identifier === subscription?.plan?.identifier ||
      defaultPlan.quarterIdentifier === subscription?.plan?.identifier ||
      defaultPlan.semiAnualIdentifier === subscription?.plan?.identifier,
  );

  const isCurrentPlan =
    isDefaultPlan &&
    subscription?.plan?.identifier === plan.identifier &&
    subscription?.status !== EStatus.CANCELED;
  const isSubscriptionStatusPaid = subscription?.status === EStatus.PAID;

  const handleDowngrade = React.useCallback(async () => {
    handleVerifyingDowngrade(true);

    try {
      await verifyDowngrade(storeAliasId);

      handleVerifyingDowngrade(false);
      handleDowngradeModalOpen();
    } catch (error: any) {
      handleVerifyingDowngrade(false);
      toast.error('Ocorreu um problema para carregar os dados de downgrade! Tente novamente.');
      throw new Error(error);
    }
  }, [verifyDowngrade, storeAliasId, handleVerifyingDowngrade, toast, handleDowngradeModalOpen]);

  const onButtonClick = React.useCallback(async () => {
    analytics?.track(
      'Website Contact Us Button Clicked',
      {
        email: user?.email,
      },
      {
        context: { groupId: storeAliasId },
      },
    );

    const firstName = user?.first_name;
    const email = user?.email;
    const phone = personalInfo?.phone_number;

    if (isEnterprisePlan) {
      window.open(
        `https://share.hsforms.com/1bYEseSS-SKye_z_NjjuAHAcxmgw?firstname=${firstName}&email=${email}&phone=${phone}`,
        '_blank',
      );
      return;
    }

    const foundDefaultPlanIndex = PLANS.findIndex(
      defaultPlan => defaultPlan.identifier === subscription?.plan?.identifier,
    );

    if (isDefaultPlan && foundDefaultPlanIndex < index) {
      handleSelectedPlanIdentifier(plan.identifier);
      handleSelectedRecurrenceType(selectedRecurrenceType || EPlanRecurrence.MONTHLY);
      history.push(`/${storeAliasId}/subscription/address`);
    } else {
      handleSelectedPlanIdentifier(plan.identifier);
      handleSelectedRecurrenceType(selectedRecurrenceType || EPlanRecurrence.MONTHLY);
      handleDowngrade();
    }
  }, [
    isEnterprisePlan,
    plan,
    handleSelectedPlanIdentifier,
    history,
    storeAliasId,
    subscription,
    handleDowngrade,
    handleSelectedRecurrenceType,
    index,
    isDefaultPlan,
    selectedRecurrenceType,
    user,
    personalInfo,
    analytics,
  ]);

  const getPlanPrice = React.useCallback(() => {
    if (isMonthlyRecurrence) return numberFormatter(plan.monthlyPrice, 0);

    if (isQuarterRecurrence) return numberFormatter(plan.quarterMonthlyPrice || 0, 0);

    return numberFormatter(plan.semesterMonthlyPrice || 0, 0);
  }, [isMonthlyRecurrence, isQuarterRecurrence, plan]);

  const getRecurrenceText = React.useCallback(() => {
    if (isQuarterRecurrence && !isLitePlan && !isEnterprisePlan) return 'Cobrado a cada 3 meses';

    if (isSemiAnualRecurrence && !isLitePlan && !isEnterprisePlan) return 'Cobrado a cada 6 meses';

    return '';
  }, [isQuarterRecurrence, isSemiAnualRecurrence, isLitePlan, isEnterprisePlan]);

  const getButtonText = React.useCallback(() => {
    if (isCurrentPlan && subscription.status === EStatus.PAID) return 'Plano Atual';

    if (isEnterprisePlan) return 'Fale Conosco';

    return 'Escolher Plano';
  }, [isCurrentPlan, isEnterprisePlan, subscription]);

  const getExtraOrderPrice = React.useCallback(() => {
    if (isLitePlan) return 'Sem pedidos extras';

    if (selectedRecurrenceType === EPlanRecurrence.MONTHLY)
      return `${numberFormatter(plan.extraOrderPrice, 2)} por pedido extra`;

    if (selectedRecurrenceType === EPlanRecurrence.QUARTER)
      return `${numberFormatter(plan.extraOrderPriceQuarter, 2)} por pedido extra`;

    return `${numberFormatter(plan.extraOrderPriceSemiannual, 2)} por pedido extra`;
  }, [isLitePlan, selectedRecurrenceType, plan]);

  const signUpButtonVariant = isEnterprisePlan ? EButtonVariant.SECONDARY : EButtonVariant.PRIMARY;
  const isRecurrence = isQuarterRecurrence || isSemiAnualRecurrence;

  return (
    <S.Wrapper>
      <S.PlanCard isMostPopularPlan={isMostPopularPlan}>
        <S.PlanTitle type={EHeadingSize.H4}>{plan.name}</S.PlanTitle>

        <S.PlanPriceWrapper>
          <S.PlanPrice>
            <S.Currency>R$</S.Currency>
            <S.Price>{getPlanPrice()}</S.Price>
          </S.PlanPrice>

          <S.PlanPriceLabel isRecurrence={isRecurrence}>por mês</S.PlanPriceLabel>
        </S.PlanPriceWrapper>

        {isRecurrence && <S.ChargeRecurrenceText>{getRecurrenceText()}</S.ChargeRecurrenceText>}

        {/* <S.PlanDescription>{plan.description}</S.PlanDescription> */}

        <S.SignUpButton
          variant={signUpButtonVariant}
          onClick={onButtonClick}
          isLoading={isVerifyingDowngrade || isDowngradeModalOpen}
          disabled={isCurrentPlan && isSubscriptionStatusPaid}
        >
          {getButtonText()}
        </S.SignUpButton>

        <S.Benefits>
          <S.Benefit>
            <SimpleCheck size={14} color={theme.colors.green.default} />
            <S.BenefitText>
              <strong>{plan.ordersPerMonth === 0 ? 'Ilimitados' : plan.ordersPerMonth}</strong>{' '}
              Pedidos pagos/mês
            </S.BenefitText>
          </S.Benefit>

          <S.Benefit>
            <SimpleCheck size={14} color={theme.colors.green.default} />
            <S.BenefitText>
              <strong>
                {plan.marketingProvider === 0 ? 'Ilimitadas' : plan.marketingProvider}
              </strong>{' '}
              {plan.marketingProvider === 1 ? 'Fonte de tráfego' : 'Fontes de tráfego'}
            </S.BenefitText>
          </S.Benefit>

          <S.Benefit>
            <SimpleCheck size={14} color={theme.colors.green.default} />
            <S.BenefitText>
              <strong>{plan.members === 0 ? 'Ilimitados' : plan.members}</strong>{' '}
              {plan.members === 1 ? 'Usuário' : 'Usuários'}
            </S.BenefitText>
          </S.Benefit>

          <S.Benefit>
            <SimpleCheck size={14} color={theme.colors.green.default} />
            <S.BenefitText>
              <strong>{plan.facebookProfile === 0 ? 'Ilimitados' : plan.facebookProfile}</strong>{' '}
              {plan.facebookProfile === 1 ? 'Perfil do Facebook' : 'Perfis do Facebook'}
            </S.BenefitText>
          </S.Benefit>

          {plan.features.map((feature: string) => (
            <S.Benefit>
              <SimpleCheck size={14} color={theme.colors.green.default} />
              <S.BenefitText>{feature}</S.BenefitText>
            </S.Benefit>
          ))}
        </S.Benefits>

        {!isUnlimitedPlan && (
          <S.Tooltip>
            <S.TooltipTrigger>{getExtraOrderPrice()}</S.TooltipTrigger>

            <S.TooltipContent side="top" sideOffset={5}>
              <S.Tooltip.Arrow />

              <S.TooltipText>
                {isLitePlan ? (
                  <>
                    Caso você <strong>exceda a quantidade de pedidos</strong> contratado pelo plano,
                    você terá acesso aos pedidos até o momento em que foi excedido.
                  </>
                ) : (
                  <>
                    Caso você <strong>exceda a quantidade de pedidos</strong> contratado pelo plano,
                    será cobrado um valor <strong>adicional a cada pedido aprovado.</strong>
                  </>
                )}
              </S.TooltipText>
            </S.TooltipContent>
          </S.Tooltip>
        )}
      </S.PlanCard>
    </S.Wrapper>
  );
};

export default Plan;
