import React from 'react';
import { Plus } from '@profitfy/pakkun-icons';
import { useHistory } from 'react-router-dom';

import * as S from './styles';

const Header: React.FC = () => {
  const history = useHistory();

  const handleNewKit = React.useCallback(() => {
    history.push('kits/new-kit');
  }, [history]);

  return (
    <S.Wrapper>
      <S.Title>Custos de kit</S.Title>
      <S.NewKitButton onClick={handleNewKit} icon={Plus}>
        Cadastrar novo kit
      </S.NewKitButton>
    </S.Wrapper>
  );
};

export default Header;
