import { ApiService } from '@services/api';
import { LONG_API_DOMAIN } from '@constants/api';
import { ICreateFacebookCredentialProps } from '@domain/interfaces/dashboard/AdSense/Facebook/IFacebookCredential';

export class FacebookCredentialService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public createFacebookCredential({
    storeAliasId,
    data,
  }: ICreateFacebookCredentialProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/facebook/ad-sense-credentials`,
      { ...data },
    );
  }
}

const facebookCredentialService = new FacebookCredentialService(LONG_API_DOMAIN);

export default facebookCredentialService;
