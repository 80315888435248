import React from 'react';
import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';

import { ICustomDropDown } from '@domain/interfaces/components/ICustomDropDown';
import Content from './Content';
import Item from './Item';
import Label from './Label';
import Separator from './Separator';
import Trigger from './Trigger';
import Arrow from './Arrow';

import * as S from './styles';

const CustomDropDown: ICustomDropDown & React.FC<DropdownPrimitive.DropdownMenuProps> = ({
  children,
  ...rest
}) => {
  return <S.Wrapper {...rest}>{children}</S.Wrapper>;
};

CustomDropDown.Content = Content;
CustomDropDown.Item = Item;
CustomDropDown.Label = Label;
CustomDropDown.Separator = Separator;
CustomDropDown.Trigger = Trigger;
CustomDropDown.Arrow = Arrow;

export default CustomDropDown;
