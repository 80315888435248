import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import ImageBase from '@components/common/core/DataDisplay/Image';

export const OwnerNameText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
  font-size: 0.875rem;
  line-height: 130%;

  strong {
    font-weight: 700;
  }
`;

export const CardText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
  font-size: 0.875rem;
  line-height: 130%;

  strong {
    font-weight: 700;
  }
`;

export const CardDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IssuerLogo = styled(ImageBase)`
  max-width: 64px;
  width: 100%;
`;

export const DataWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 8px;
  height: 56px;
`;

export const Description = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
  font-size: 0.875rem;
  line-height: 130%;
`;

export const Title = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 130%;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Content = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const UpdateDateButton = styled.button`
  background-color: unset;
  min-height: 42px;
  height: 42px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 400;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[1]};
  border: unset;
  border-top: 1px solid ${({ theme }) => theme.colors.gray[3]};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const CardWrapper = styled.div`
  min-width: 296px;
  height: 232px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
