import TextBase from '@components/common/core/DataDisplay/Text';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  border-radius: 12px;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  box-sizing: border-box;
`;

export const TitleCard = styled(TextBase)`
  font-size: 1rem;
  font-weight: 500;
  line-height: 120%;
`;

export const InputsWrapper = styled.div`
  display: flex;
`;

export const Label = styled(TextBase)`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 105%;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Input = styled(TextFieldBase)`
  width: 100%;
  height: 40px;
  max-width: 173px;
  margin-left: auto;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const IofAliExpressSelect = styled(SelectBase)`
  width: 100%;
  max-width: 173px;
  margin-left: auto;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.inputBorderColor};
  background-color: ${({ theme }) => theme.colors.background[2]};
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  padding: 10px 12px;
  transition: all 0.3s;
  box-sizing: border-box;
`;

export const SelectOption = styled(OptionBase)``;
