import * as yup from 'yup';

import { currencyToNumber } from '@helpers/masks';

export const checkoutFeePercentageSchema = yup.object().shape({
  percentage: yup
    .string()
    .required('É necessário informar uma porcentagem.')
    .test('min and max value', 'A porcentagem precisa ser entre 0% a 100%.', value => {
      return currencyToNumber(value || '') >= 0 && currencyToNumber(value || '') <= 100;
    }),
});

export const checkoutFeeFixedSchema = yup.object().shape({
  amount: yup.string().required('É necessário informar um valor.'),
});
