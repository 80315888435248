import React from 'react';

import { Pen } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { IOrderTableDataProps } from '@domain/interfaces/dashboard/Orders/IOrderTableData';

import Table from '@components/common/core/Utils/Table';

import * as S from './styles';

const EditOrder: React.FC<IOrderTableDataProps> = ({ isHovering, handleEditOrderModalOpen }) => {
  const theme = useTheme();

  return (
    <Table.Data>
      <S.ActionsButton onClick={handleEditOrderModalOpen} isHovering={isHovering}>
        <Pen outline size={16} color={theme.colors.font} />
      </S.ActionsButton>
    </Table.Data>
  );
};

export default EditOrder;
