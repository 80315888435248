import queryString from 'query-string';

import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';
import {
  IGetFeedGatewaysProps,
  IGetFeedGatewaysResponse,
  IGetFeedQuantityProps,
  IGetFeedQuantityResponse,
  IListFeedOrdersProps,
  IListFeedOrdersResponse,
  IListFeedAdSenseAccounts,
  IListFeedAdSenseAccountsResponse,
  IUpdateAdSenseAccountByFeedProps,
  IListFeedWhatsappInstancesProps,
  IListFeedWhatsappInstanceResponse,
} from '@domain/interfaces/dashboard/Feed/IFeed';

import {
  IListFeedCustomValueBillingsProps,
  IListFeedCustomValueBillingsResponse,
} from '@domain/interfaces/dashboard/Feed/ICustomValueBilling';
import {
  IListProductsFeedProps,
  IListProductsFeedResponse,
} from '@domain/interfaces/dashboard/Feed/IProduct';

export class FeedService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getFeedQuantity({
    storeAliasId,
    startDate,
    endDate,
  }: IGetFeedQuantityProps): IGetFeedQuantityResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/feed/quantity?start_date=${startDate}&end_date=${endDate}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      feedElementsQuantity: data?.feed_elements_quantity,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public getFeedGateways({
    storeAliasId,
    startDate,
    endDate,
  }: IGetFeedGatewaysProps): IGetFeedGatewaysResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/feed/gateways?start_date=${startDate}&end_date=${endDate}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      gatewaysToFeed: data?.gateways_to_feed,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public listFeedOrders({
    storeAliasId,
    page,
    startDate,
    endDate,
    filter,
  }: IListFeedOrdersProps): IListFeedOrdersResponse {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/feed/orders?limit=10&page=${
        page + 1
      }&start_date=${startDate}&end_date=${endDate}&${queryStringFilter}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      orders: data?.orders,
      totalPages: data?.total_pages,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public listFeedOrdersPromise({
    storeAliasId,
    page,
    startDate,
    endDate,
    filter,
  }: IListFeedOrdersProps): Promise<any> {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/feed/orders?limit=10&page=${
        page + 1
      }&start_date=${startDate}&end_date=${endDate}&${queryStringFilter}`,
    );
  }

  public listFeedAdSenseAccounts({
    storeAliasId,
    startDate,
    endDate,
  }: IListFeedAdSenseAccounts): IListFeedAdSenseAccountsResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/feed/ad-sense-accounts?&start_date=${startDate}&end_date=${endDate}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      adSenseAccounts: data?.ad_sense_accounts,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public listFeedCustomValueBillings({
    storeAliasId,
  }: IListFeedCustomValueBillingsProps): IListFeedCustomValueBillingsResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/feed/custom-value-billings`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      customValueBillings: data?.custom_value_billings,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public updateAdSenseAccountByFeed({
    storeAliasId,
    data,
  }: IUpdateAdSenseAccountByFeedProps): Promise<any> {
    return this.apiService.put(`/api/v1/users/stores/${storeAliasId}/ad-sense-accounts`, {
      ...data,
    });
  }

  public listProductsFeed({ storeAliasId }: IListProductsFeedProps): IListProductsFeedResponse {
    const { data, isValidating, error, mutate } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/feed/products`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      products: data?.products,
      isLoading,
      isValidating,
      error,
      mutate,
    };
  }

  public listFeedWhatsappInstances({
    storeAliasId,
  }: IListFeedWhatsappInstancesProps): IListFeedWhatsappInstanceResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/feed/whatsapp-credentials`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      whatsappInstances: data?.whatsapp_credentials,
      isLoading,
      isValidating,
      error,
      mutate,
    };
  }
}

const feedService = new FeedService(API_DOMAIN);

export default feedService;
