import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { SimpleCheck } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import sha256 from 'crypto-js/sha256';

import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';
import { EButtonTextColor } from '@domain/enums/components/EButton';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { ISecondFormProps } from '@domain/interfaces/pages/signUp/ISignUp';

import { secondFormSchema } from '@helpers/validators/signUp';

import { normalizePhone } from '@helpers/utils/common/normalizer';

import Text from '@components/common/core/DataDisplay/Text';

import personalInfoService from '@services/pages/auth/personalInfo';

import logoImg from '@assets/img/brand/profitfy-logo.svg';

import * as S from './styles';

const SecondForm: React.FC<ISecondFormProps> = ({ personalInfo }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(secondFormSchema),
  });
  const theme = useTheme();

  const formattedPhoneValue = `${personalInfo?.phone_prefix}${personalInfo?.phone_number}`;

  const { handlePersonalInfo, personalInfo: userPersonalInfo, user } = useConfig();
  const history = useHistory();
  const { toast } = useToast();
  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [phoneValue, setPhoneValue] = React.useState<string>(formattedPhoneValue);
  const [isBenefitsTimeOutFinished, setIsBenefitsTimeOutFinished] = React.useState<boolean>(false);
  const [isInputGroupTimeOutFinished, setIsInputGroupTimeOutFinished] = React.useState<boolean>(
    false,
  );

  const onPhoneChange = React.useCallback(phoneNumber => {
    setPhoneValue(phoneNumber);
  }, []);

  const formattedPhoneToRaw = (value = ''): string => {
    let newValue = value.replace(' ', '').replace('(', '').replace(')', '').replace('-', '');

    newValue = `55${newValue}`;

    return newValue;
  };

  const onSubmit = React.useCallback(async () => {
    setIsSubmittingForm(true);

    const data = {
      phone_number: phoneValue,
    };

    try {
      const { data: personalInfoData } = await personalInfoService.createPersonalInfo({ data });

      const dataLayer = (window as Record<string, any>)?.dataLayer;

      if (dataLayer) {
        dataLayer.push({
          phone: formattedPhoneToRaw(phoneValue),
          event: 'User Phone Form Submitted',
          hashed_phone: sha256(normalizePhone(formattedPhoneToRaw(phoneValue))).toString(),
        });
      }

      handlePersonalInfo(personalInfoData?.personal_info);

      toast.success('Dados salvos com sucesso!');

      setIsSubmittingForm(false);

      history.push('/stores');
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsSubmittingForm(false);
    }
  }, [history, toast, phoneValue, handlePersonalInfo]);

  React.useEffect(() => {
    const benefitsTimeout = setTimeout(() => {
      setIsBenefitsTimeOutFinished(true);
    }, 700);

    return () => clearTimeout(benefitsTimeout);
  }, []);

  React.useEffect(() => {
    const inputGroupTimeout = setTimeout(() => {
      setIsInputGroupTimeOutFinished(true);
    }, 1500);

    return () => clearTimeout(inputGroupTimeout);
  }, []);

  React.useEffect(() => {
    if (userPersonalInfo) {
      history.push('/stores');
    }
  }, [history, userPersonalInfo]);

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.Logo src={logoImg} alt="profitfy-logo" />

      {isBenefitsTimeOutFinished && (
        <>
          <S.BenefitsWrapper>
            <S.BenefitTitle weight={ETextWeight.BOLD}>
              Solicitamos um número de celular pessoal porque queremos:
            </S.BenefitTitle>

            <S.Benefit>
              <SimpleCheck size={12} color={theme.colors.green.default} />
              <S.BenefitDescription size={ETextSize.B5}>
                Manter um atendimento personalizado.
              </S.BenefitDescription>
            </S.Benefit>

            <S.Benefit>
              <SimpleCheck size={12} color={theme.colors.green.default} />
              <S.BenefitDescription size={ETextSize.B5}>
                Enviar notificações importantes sobre seu negócio.
              </S.BenefitDescription>
            </S.Benefit>

            <S.Benefit>
              <SimpleCheck size={12} color={theme.colors.green.default} />
              <S.BenefitDescription size={ETextSize.B5}>
                Enviar notificações de atualizações importantes para você.
              </S.BenefitDescription>
            </S.Benefit>

            <S.Benefit>
              <SimpleCheck size={12} color={theme.colors.green.default} />
              <S.BenefitDescription size={ETextSize.B5}>
                Você poderá pausar esse beneficio quando quiser.
              </S.BenefitDescription>
            </S.Benefit>
          </S.BenefitsWrapper>
        </>
      )}

      {isInputGroupTimeOutFinished && (
        <>
          <S.InputGroupAndCreateAccountButton>
            <S.InputGroup>
              <S.Label>Celular para contato</S.Label>
              <S.PhoneInput
                {...register('phone')}
                country="br"
                value={phoneValue}
                onChange={onPhoneChange}
                inputProps={{ name: 'phone', autoFocus: true }}
                isError={errors.phone}
                placeholder="(11) 91234-5678"
              />
              {errors.phone && <Text isErrorFeedback>{errors.phone.message}</Text>}
            </S.InputGroup>

            <S.CreateAccountButton
              type="submit"
              textColor={EButtonTextColor.DARK}
              isLoading={isSubmittingForm}
              disabled={errors.phone || isSubmittingForm}
            >
              Continuar
            </S.CreateAccountButton>
          </S.InputGroupAndCreateAccountButton>
        </>
      )}
    </S.Form>
  );
};

export default SecondForm;
