import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const NextStepButton = styled(ButtonBase)`
  width: 100%;
`;

export const ContentText = styled(TextBase)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray[2]};
  line-height: unset;
`;

export const ContentTitle = styled(HeadingBase)`
  display: inline-block;
  line-height: unset;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const FormOverviewWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray[7]};
  padding: 16px;
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.colors.green.default};
  }

  &:active {
    border-color: ${({ theme }) => theme.colors.green.default};
  }

  & > svg {
    width: 24px;
    height: 24px;
    transition: all 0.3s;
  }
`;

export const Title = styled(HeadingBase)`
  display: inline-block;
  margin-bottom: 12px;
`;

export const PersonalInfoAndPaymentWrapper = styled.div`
  margin-bottom: 24px;

  & > div:first-of-type {
    margin-bottom: 16px;
  }
`;

export const AddressWrapper = styled.div`
  margin-bottom: 32px;
`;

export const Wrapper = styled.div`
  @media only screen and (max-width: 450px) {
    padding: 0 32px;
  }
`;
