import React from 'react';

import Details from './Details';
import Transactions from './Transactions';

import * as S from './styles';

const Subscription: React.FC = () => {
  return (
    <S.Wrapper>
      <Details />

      <Transactions />
    </S.Wrapper>
  );
};

export default Subscription;
