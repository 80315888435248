import styled, { keyframes } from 'styled-components';

import TextBase from '@components/common/core/DataDisplay/Text';
import LabelBase from '@components/common/core/DataDisplay/Label';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';

const appearEffect = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const CardInputsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  & > div > div {
    min-width: unset;
  }
`;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Input = styled(TextFieldBase)``;

export const Checkbox = styled(CheckboxBase)``;

export const PaymentMethodsLabel = styled(LabelBase)`
  margin-bottom: 16px;
`;

export const PaymentMethodsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: fit-content;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: fit-content;
`;

export const CheckboxText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.white.default};
`;

export const InputAndSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`;

export const Select = styled(SelectBase)`
  min-width: 137px;
`;

export const TaxInputGroup = styled(InputGroupBase)`
  animation: ${appearEffect} 300ms;
  margin-bottom: 24px;
`;

export const TaxInput = styled(TextFieldBase)`
  min-width: 345px;

  @media only screen and (max-width: 588px) {
    min-width: unset;
  }

  span {
    color: ${({ theme }) => theme.colors.green.default};
  }
`;

export const Option = styled(OptionBase)``;
