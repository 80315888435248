import React from 'react';
import { Lpqv as Icon } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { EDropshippingProvider } from '@domain/enums/common/integrations/EDropshippingProvider';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useDropshipping } from '@helpers/hooks/pages/onboarding/useDropshipping';
import { useDate } from '@helpers/hooks/useDate';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { lpqvSchema } from '@helpers/validators/dashboard/integrationCenter/lpqvCredentials';

import { DROPSHIPPING_PROVIDERS } from '@constants/common/integrations';

import integrationCenterService from '@services/pages/dashboard/integrationCenter/integrationCenter';
import lpqvAuthService from '@services/pages/dashboard/integrationCenter/storeProvider/lpqv/lpqvAuth';

import NextStep from '@components/Onboarding/NextStep';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const LPQV: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(lpqvSchema) });
  const history = useHistory();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { selectedGateways, selectedMarketings } = useDropshipping();
  const { addSynchronization } = useSynchronization();
  const { format, utcToZonedTime } = useDate();
  const { sendFinancialData } = useOnboarding();

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [storeSlugValue, setStoreSlugValue] = React.useState<string>('');
  const [authTokenValue, setAuthTokenValue] = React.useState<string>('');

  const storeSlugRegister = register('provider_id');
  const authTokenRegister = register('auth_token');

  const onStoreSlugChange = React.useCallback(
    event => {
      storeSlugRegister.onChange(event);

      setStoreSlugValue(event.target.value);
    },
    [storeSlugRegister],
  );

  const onAuthTokenChange = React.useCallback(
    event => {
      authTokenRegister.onChange(event);

      setAuthTokenValue(event.target.value);
    },
    [authTokenRegister],
  );

  const handleSuccessSubmit = React.useCallback(async () => {
    const { data } = await integrationCenterService.getStoreIntegrations({ storeAliasId });

    const hasLPQVCredential = data?.store_integrations?.store_front?.has_lpqv_credential;

    if (hasLPQVCredential) {
      const foundLPQVIntegration = DROPSHIPPING_PROVIDERS.find(
        integration => integration.identifier === EDropshippingProvider.LPQV,
      ) as typeof DROPSHIPPING_PROVIDERS[number];

      const { generateSyncRequest } = foundLPQVIntegration;

      const params = `?start_date=${format(new Date(2000, 1, 1), 'yyyy-MM-dd')}&end_date=${format(
        utcToZonedTime(new Date()),
        'yyyy-MM-dd',
      )}`;

      addSynchronization({
        name: 'LPQV',
        type: ESynchronizationType.STORE_PRODUCT,
        label: 'Produtos',
        dependencies: [],
        storeAliasId,
        config: {
          isOnboardingSynchronization: true,
          showNotification: false,
        },
        request: generateSyncRequest(ESynchronizationType.STORE_PRODUCT, storeAliasId, params),
      });

      sendFinancialData(storeAliasId);

      setIsSubmittingForm(false);

      if (selectedGateways.length) {
        history.push(`/onboarding/${storeAliasId}/dropshipping/integrations/gateways`);
      }

      if (!selectedGateways.length && selectedMarketings.length) {
        history.push(`/onboarding/${storeAliasId}/dropshipping/integrations/marketing`);
      }

      if (!selectedGateways.length && !selectedMarketings.length) {
        history.push(`/onboarding/${storeAliasId}/dropshipping/integrations/integration-finished`);
      }
    } else {
      setIsSubmittingForm(false);
    }
  }, [
    addSynchronization,
    format,
    history,
    selectedGateways,
    selectedMarketings,
    storeAliasId,
    utcToZonedTime,
    sendFinancialData,
  ]);

  const onSubmit = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      const data = {
        provider_id: formData.provider_id,
        auth_token: formData.auth_token,
      };

      try {
        await lpqvAuthService.createCredential({ storeAliasId, data });

        handleSuccessSubmit();
      } catch (error: any) {
        toast.error(getTranslatedIntegrationsError(error));
      }
    },
    [toast, handleSuccessSubmit, storeAliasId],
  );

  const hasAllInputsValue = storeSlugValue && authTokenValue;

  return (
    <S.Wrapper>
      <S.FormWrapper>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Header>
            <S.Title>Integre com sua plataforma de E-commerce</S.Title>
          </S.Header>

          <S.IntegrationTitle>
            <Icon size={36} />

            <S.IntegrationTitleText fontWeight={EHeadingWeight.REGULAR}>
              Integração com LPQV
            </S.IntegrationTitleText>
          </S.IntegrationTitle>

          <S.Body>
            <S.InputGroup>
              <S.Label htmlFor="store-slug">Slug da Loja</S.Label>
              <S.TextField
                {...storeSlugRegister}
                type="text"
                id="store-slug"
                onChange={onStoreSlugChange}
                value={storeSlugValue}
                isError={Boolean(errors.provider_id)}
              />
              {errors.provider_id && <Text isErrorFeedback>{errors.provider_id.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label htmlFor="auth-token">Auth Token</S.Label>
              <S.TextField
                {...authTokenRegister}
                type="text"
                id="auth-token"
                onChange={onAuthTokenChange}
                value={authTokenValue}
                isError={Boolean(errors.auth_token)}
              />
              {errors.auth_token && <Text isErrorFeedback>{errors.auth_token.message}</Text>}
            </S.InputGroup>
          </S.Body>

          <NextStep
            type="submit"
            isLoading={isSubmittingForm}
            disabled={isSubmittingForm || !hasAllInputsValue}
          >
            Próximo passo
          </NextStep>
        </S.Form>
      </S.FormWrapper>

      <S.TutorialWrapper>
        <S.TutorialImage
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/onboarding/images/content/onboarding/onboarding-integration-tutorial.svg"
          alt="Profitfy.me Integration Tutorial"
        />
        <S.TutorialTitle>Tutorial de integração</S.TutorialTitle>
        <S.TutorialContent>
          Para realizar a integração você precisará do <strong>Slug da loja</strong> e do{' '}
          <strong>Token</strong> que são encontrados neste link:
        </S.TutorialContent>
        <S.TutorialLink
          href="https://ajuda.profitfy.me/pt-BR/articles/8076401-como-integrar-com-lpqv"
          target="_blank"
        >
          Encontre suas credenciais clicando aqui!
        </S.TutorialLink>
      </S.TutorialWrapper>
    </S.Wrapper>
  );
};

export default LPQV;
