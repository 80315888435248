import * as yup from 'yup';

export const lpqvSchema = yup.object().shape({
  provider_id: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'Informe um alias válido.')
    .max(400, 'Informe um alias válido.'),
  auth_token: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(10, 'Informe um Token de Acesso da API Admin válido.')
    .max(200, 'Informe um Token de Acesso da API Admin válido.'),
});

export const updateLPQVSchema = yup.object().shape({
  auth_token: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(10, 'Informe um Token de Acesso da API Admin válido.')
    .max(200, 'Informe um Token de Acesso da API Admin válido.')
    .test('valid access token', 'Insira um Token de Acesso da API Admin válido.', value => {
      if (value?.includes('*')) return false;

      return true;
    }),
});
