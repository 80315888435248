import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresCardOtherTotalValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresCardOtherTotalValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresCardOtherTotalValuesResponse => {
  const currentSelectedStoreCardOtherTotalAmountValue =
    currentSelectedStoreFinancialDetailsReportData?.card?.other?.total.amount;

  const currentSelectedStoreCardOtherTotalQuantityValue =
    currentSelectedStoreFinancialDetailsReportData?.card?.other?.total.quantity;

  const storesCardOtherTotalAmountValues = selectedStoresData?.map(
    value => value.financial_details_report?.card?.other?.total.amount,
  );

  const storesCardOtherTotalQuantityValues = selectedStoresData?.map(
    value => value.financial_details_report?.card?.other?.total.quantity,
  );

  const cardOtherTotalAmountValues = [
    ...storesCardOtherTotalAmountValues,
    currentSelectedStoreCardOtherTotalAmountValue,
  ];

  const cardOtherTotalQuantityValues = [
    ...storesCardOtherTotalQuantityValues,
    currentSelectedStoreCardOtherTotalQuantityValue,
  ];

  const reducedCardOtherTotalAmountValues = cardOtherTotalAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedCardOtherTotalQuantityValues = cardOtherTotalQuantityValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  return {
    other_total_amount: reducedCardOtherTotalAmountValues,
    other_total_quantity: reducedCardOtherTotalQuantityValues,
  };
};
