import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Wrapper = styled.section`
  width: calc(100% + 72px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 52px;

  @media only screen and (max-width: 1048px) {
    padding-bottom: unset;
  }

  @media only screen and (max-height: 768px) {
    padding-top: 20px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 919px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1066px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 18px;
  text-transform: uppercase;
  text-align: center;
  line-height: 150%;
  max-width: 575px;

  span {
    color: ${({ theme }) => theme.colors.green.default};
    font-size: 1.5rem;
    /* font-family: 'Poppins'; */
    font-family: 'Saira';

    @media only screen and (max-width: 365px) {
      font-size: 1.125rem;
    }
  }

  @media only screen and (max-width: 365px) {
    font-size: 1.125rem;
  }
`;

export const Description = styled(TextBase)`
  margin-bottom: 38px;
  text-align: center;
  font-size: 1.25rem;
  max-width: 821px;

  @media only screen and (max-width: 1048px) {
    max-width: 640px;
  }

  @media only screen and (max-width: 420px) {
    font-size: 1rem;
  }
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const ActivateBenefitButton = styled(ButtonBase)`
  margin-top: 38px;

  @media only screen and (max-width: 1048px) {
    margin-top: unset;
    margin-bottom: 38px;
  }

  span {
    text-transform: uppercase;
  }
`;

export const FeaturesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;

  @media only screen and (max-width: 1048px) {
    flex-direction: column;
  }
`;

export const Feature = styled.div`
  width: 100%;
  min-width: 285px;
  min-height: 251px;
  border-radius: 7px;
  border: 1px solid ${({ theme }) => theme.colors.green.default};
  background-color: ${({ theme }) => theme.colors.gray[6]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  @media only screen and (max-width: 1048px) {
    width: unset;
  }
`;

export const FeatureTitle = styled(HeadingBase)`
  text-transform: uppercase;
  text-align: center;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.green.default};
  margin-bottom: 18px;
`;

export const FeatureDescription = styled(TextBase)`
  text-align: center;
  line-height: 150%;
  font-size: 0.9375rem;
  width: 100%;
  max-width: 251px;
`;
