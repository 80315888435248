import styled from 'styled-components/macro';

export const MessagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const Message = styled.div`
  width: 324px;
  height: 319px;
  box-sizing: border-box;

  @media only screen and (max-width: 840px) {
    width: 100%;
  }
`;
