import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  overflow-y: hidden;
  overflow-x: scroll;
  cursor: grab;
  padding-bottom: 8px;

  &:active {
    cursor: grabbing;
  }

  &::-webkit-scrollbar {
    height: 2px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.background[3]};
    border-radius: 25px;
  }
`;
