import styled from 'styled-components/macro';

import CustomPopover from '@components/common/core/Utils/CustomPopover';

export const SortButton = styled(CustomPopover.Trigger)`
  background-color: ${({ theme }) => theme.colors.gray[5]};
  border-radius: 5px;
  height: 42px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  font-size: 1rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.font};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Popover = styled(CustomPopover)``;
