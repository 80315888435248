import { ISynchronizationRequest } from '@domain/interfaces/hooks/IStoreSynchronization';
import {
  ESynchronizationType,
  ESynchronizationRequestType,
} from '@domain/enums/hooks/ESynchronization';
import { IAliases } from '@domain/interfaces/integrations/marketing/google/IAliases';
import { IUpdateGoogleCampaign } from '@domain/interfaces/dashboard/AdSense/Google/IGoogleCampaign';

import { LONG_API_DOMAIN } from '@constants/api';

import { getEnvironment } from '@helpers/utils/environment';

import googleCampaignService from '@services/pages/dashboard/adSense/google/googleCampaign';

export const getUpdateGoogleCampaignPromise = ({
  adSenseCampaignAliasId,
  data,
  productAliasId,
  storeAliasId,
}: IUpdateGoogleCampaign): Promise<any> => {
  return googleCampaignService.updateGoogleCampaign({
    adSenseCampaignAliasId,
    data,
    productAliasId,
    storeAliasId,
  });
};

export const getGoogleSynchronizationRequestConfig = (
  type: ESynchronizationType,
  storeAliasId: string | undefined,
  aliases: IAliases | undefined,
  data: any | undefined,
): ISynchronizationRequest => {
  if (type === ESynchronizationType.AD_SENSE_ACCOUNTS) {
    return {
      type: ESynchronizationRequestType.POST,
      domain: LONG_API_DOMAIN,
      api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/ad-sense-credentials/${
        aliases?.adSenseCredentialAliasId
      }/google/ad-sense-accounts/sync`,
    };
  }

  if (type === ESynchronizationType.UPDATE_AD_SENSE_ACCOUNTS) {
    return {
      type: ESynchronizationRequestType.PUT,
      domain: LONG_API_DOMAIN,
      api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/ad-sense-credentials/${
        aliases?.adSenseCredentialAliasId
      }/google/ad-sense-accounts/${aliases?.adSenseAccountAliasId}`,
      requestBody: { ...data },
    };
  }

  if (type === ESynchronizationType.AD_SENSE_CAMPAIGNS) {
    return {
      type: ESynchronizationRequestType.POST,
      domain: LONG_API_DOMAIN,
      api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/google/ad-sense-campaigns/sync`,
    };
  }

  return {
    type: ESynchronizationRequestType.PUT,
    domain: LONG_API_DOMAIN,
    api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/products/${
      aliases?.productAliasId
    }/google/ad-sense-campaigns/${aliases?.adSenseCampaignAliasId}`,
    requestBody: { ...data },
  };
};
