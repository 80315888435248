import React from 'react';

import { useSort } from '@helpers/hooks/components/useSort';
import FieldOption from '../FieldOption';

import * as S from './styles';

const SortContent: React.FC = () => {
  const { config } = useSort();

  return (
    <S.PopoverContent>
      {config.map(field => (
        <FieldOption field={field} key={field.value} />
      ))}

      <S.PopoverArrow />
    </S.PopoverContent>
  );
};

export default SortContent;
