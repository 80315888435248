import React from 'react';

import { IData, ISelectedFilter, IFilterProps } from '@domain/interfaces/components/IFilter';
import { useParams } from 'react-router-dom';
import { IParams } from '@domain/interfaces/IParams';
import { EFilterOperator } from '@domain/enums/components/EFilter';
import { ICustomValuesCategory } from '@domain/interfaces/dashboard/CustomValuesCategories/ICustomValuesCategories';
import { parseCustomValues } from '@helpers/utils/dashboard/customValues/customValues';

import customValueCategoriesService from '@services/pages/dashboard/customValuesCategories/customValueCategories';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Select: React.FC<IFilterProps> = ({
  config,
  handleOnClick,
  defaultValue,
  updateData,
  addNewData,
}) => {
  const { storeAliasId } = useParams<IParams>();

  const { config: filterConfig } = config;

  const [optionToSearch, setOptionToSearch] = React.useState<string>('');
  const [isGettingCustomValues, setIsGettingCustomValues] = React.useState<boolean>(false);
  const [customValuesCategories, setCustomValuesCategories] = React.useState<
    Array<ICustomValuesCategory>
  >([]);

  const handleOptionClick = React.useCallback(
    (option: IData) => {
      const newData: ISelectedFilter = {
        field: config.field,
        label: option.label,
        value: option.value,
        operator: EFilterOperator.EQUAL,
      };

      if (defaultValue) {
        updateData(newData);
      } else {
        addNewData(newData);
      }

      handleOnClick();
    },
    [handleOnClick, config, addNewData, defaultValue, updateData],
  );

  const handleSearchOptionInputChange = React.useCallback(event => {
    setOptionToSearch(event.target.value);
  }, []);

  const filteredFilterConfig = filterConfig?.data?.filter(option => {
    const loweredCaseOptionLabel = option.label.toLowerCase();
    const loweredCaseOptionToSearch = optionToSearch.toLowerCase();

    return loweredCaseOptionLabel.includes(loweredCaseOptionToSearch);
  });

  const parsedCustomValues = parseCustomValues(customValuesCategories);

  const filteredCustomValues = parsedCustomValues.filter(customValue => {
    const loweredCaseOptionLabel = customValue.label.toLowerCase();
    const loweredCaseOptionToSearch = optionToSearch.toLowerCase();

    return loweredCaseOptionLabel.includes(loweredCaseOptionToSearch);
  });

  const isCustomValueField = config.field === 'custom_value_category_id';

  const options = isCustomValueField ? filteredCustomValues : filteredFilterConfig;

  const getCustomValuesCategories = React.useCallback(async () => {
    setIsGettingCustomValues(true);

    try {
      const { data } = await customValueCategoriesService.getCustomValuesCategoriesPromise({
        storeAliasId,
      });

      setCustomValuesCategories(data?.custom_value_categories);
      setIsGettingCustomValues(false);
    } catch {
      setIsGettingCustomValues(false);
    }
  }, [storeAliasId]);

  React.useEffect(() => {
    if (isCustomValueField) {
      getCustomValuesCategories();
    }
  }, [getCustomValuesCategories, isCustomValueField]);

  return (
    <S.Wrapper>
      <S.SearchOptionWrapper>
        <S.SearchOptionInput
          type="text"
          placeholder={filterConfig.placeholder}
          onChange={handleSearchOptionInputChange}
        />
      </S.SearchOptionWrapper>

      <S.Options>
        {isGettingCustomValues && <SkeletonLoading />}

        {options?.map(option => (
          <S.OptionButton onClick={() => handleOptionClick(option)} key={option.value}>
            {option.label}
          </S.OptionButton>
        ))}

        {!options?.length && !isGettingCustomValues && (
          <S.NoOptionFound>Não encontramos nenhum filtro.</S.NoOptionFound>
        )}
      </S.Options>
    </S.Wrapper>
  );
};

export default Select;
