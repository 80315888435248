import React from 'react';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { EProgressBarStep } from '@domain/enums/downgrade/EDowngradeStep';
import { IProgressBarProps } from '@domain/interfaces/downgrade/sideBar/ISideBar';

import * as S from './styles';

const ProgressBar: React.FC<IProgressBarProps> = ({ step }) => {
  const isAdProvidersStepCompleted = step !== EProgressBarStep.AD_PROVIDERS;
  const isInvitedMembersStepCompleted =
    step !== EProgressBarStep.AD_PROVIDERS && step !== EProgressBarStep.INVITED_MEMBERS;
  const isFacebookProfilesStepCompleted =
    step !== EProgressBarStep.AD_PROVIDERS &&
    step !== EProgressBarStep.INVITED_MEMBERS &&
    step !== EProgressBarStep.FACEBOOK_PROFILES;
  const isStoreWorkflowsStepCompleted =
    step !== EProgressBarStep.AD_PROVIDERS &&
    step !== EProgressBarStep.INVITED_MEMBERS &&
    step !== EProgressBarStep.FACEBOOK_PROFILES &&
    step !== EProgressBarStep.STORE_WORKFLOWS;

  return (
    <S.Wrapper>
      <S.Heading type={EHeadingSize.H3}>
        Seu <span>progresso.</span>
      </S.Heading>

      <S.ProgressAndNameStepsWrapper>
        <S.ProgressWrapper>
          <S.ProgressBackground>
            <S.ProgressBar step={step} />
          </S.ProgressBackground>

          <S.AdProvidersProgressCircle isComplete={isAdProvidersStepCompleted}>
            {isAdProvidersStepCompleted && (
              <S.Icon>
                <polyline points="20 6 9 17 4 12" />
              </S.Icon>
            )}
          </S.AdProvidersProgressCircle>

          <S.InvitedMembersProgressCircle step={step} isComplete={isInvitedMembersStepCompleted}>
            {isInvitedMembersStepCompleted && (
              <S.Icon>
                <polyline points="20 6 9 17 4 12" />
              </S.Icon>
            )}
          </S.InvitedMembersProgressCircle>

          <S.FacebookProfilesProgressCircle
            step={step}
            isComplete={isFacebookProfilesStepCompleted}
          >
            {isFacebookProfilesStepCompleted && (
              <S.Icon>
                <polyline points="20 6 9 17 4 12" />
              </S.Icon>
            )}
          </S.FacebookProfilesProgressCircle>

          <S.StoreWorkflowsProgressCircle step={step} isComplete={isStoreWorkflowsStepCompleted}>
            {isStoreWorkflowsStepCompleted && (
              <S.Icon>
                <polyline points="20 6 9 17 4 12" />
              </S.Icon>
            )}
          </S.StoreWorkflowsProgressCircle>

          <S.FinishStepProgressCircle step={step} isComplete={false}>
            {false && (
              <S.Icon>
                <polyline points="20 6 9 17 4 12" />
              </S.Icon>
            )}
          </S.FinishStepProgressCircle>
        </S.ProgressWrapper>

        <S.StepsNameWrapper>
          <S.StepName isComplete={isAdProvidersStepCompleted}>Fontes de Tráfego</S.StepName>
          <S.StepName isComplete={isInvitedMembersStepCompleted}>Usuários</S.StepName>
          <S.StepName isComplete={isFacebookProfilesStepCompleted}>Perfis do Facebook</S.StepName>
          <S.StepName isComplete={isStoreWorkflowsStepCompleted}>Automações</S.StepName>
          <S.StepName isComplete={false}>Checkout</S.StepName>
        </S.StepsNameWrapper>
      </S.ProgressAndNameStepsWrapper>
    </S.Wrapper>
  );
};

export default ProgressBar;
