import React from 'react';
import { Tray as TrayIcon } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';

import trayAuthService from '@services/pages/dashboard/integrationCenter/ecommerce/tray/trayAuth';

import ComponentError from '@components/common/core/Utils/ComponentError';
import CredentialOption from './CredentialOption';
import EnableIntegration from './EnableIntegration';
import GatewayOption from './GatewayOption';
import SyncOption from './SyncOption';
import SkeletonLoading from '../SkeletonLoading';

import * as S from './styles';

const TrayConfig: React.FC = () => {
  const theme: any = useTheme();
  const { storeAliasId } = useParams<IParams>();

  const {
    trayCredential,
    isLoading,
    isValidating,
    mutate,
    error,
  } = trayAuthService.getTrayCredential({ storeAliasId });

  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const onChangeTab = React.useCallback(index => setSelectedTab(index), []);

  if (isLoading || isValidating) {
    return <SkeletonLoading />;
  }

  if (error && !isLoading) {
    return <ComponentError mutate={mutate} />;
  }

  if (!trayCredential.is_active) {
    return <EnableIntegration trayCredential={trayCredential} mutate={mutate} />;
  }

  return (
    <S.ContentWrapper>
      <S.Header>
        <TrayIcon size={52} color={theme.colors.gray[2]} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.REGULAR}>
          Integração com Tray
        </S.Heading>
      </S.Header>

      <S.Tab defaultTab={selectedTab} onChangeTab={onChangeTab}>
        <S.Option>Geral</S.Option>
        <S.Option>Sincronização</S.Option>
        <S.Option>Gateway</S.Option>
      </S.Tab>

      {selectedTab === 0 && <CredentialOption trayCredential={trayCredential} mutate={mutate} />}

      {selectedTab === 1 && <SyncOption />}

      {selectedTab === 2 && <GatewayOption />}
    </S.ContentWrapper>
  );
};

export default TrayConfig;
