import React from 'react';

import { numberFormatter, currency as getCurrency } from '@helpers/masks';
import { getProductCostDsersIcon } from '@helpers/dashboard/orders/ordersUtils';

import { IOrderTableDataProps } from '@domain/interfaces/dashboard/Orders/IOrderTableData';
import { EHeadAlign } from '@domain/enums/components/ETable';
import { ETextWeight } from '@domain/enums/components/EText';
import { EOrderStatus } from '@domain/enums/common/EOrder';
import { EProductCosts } from '@domain/enums/common/EProductCosts';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import Table from '@components/common/core/Utils/Table';
import TooltipContent from './TooltipContent';

import * as S from './styles';

const Cogs: React.FC<IOrderTableDataProps> = ({ order }) => {
  const { storeIntegrations } = useStoreConfig();

  const hasDsersIntegration = storeIntegrations.product_cost.find(
    integration => integration.name === EProductCosts.DSERS && integration.is_active,
  );

  const productCost = order?.cogs_amount + order?.iof_amount || 0;

  const isProductCostGreaterThanZero = productCost > 0;
  const isChargedBackOrder = order.status === EOrderStatus.CHARGED_BACK;

  const productCostCurrency = getCurrency(order.currency);
  const formattedProductCostValue = numberFormatter(productCost, 2);

  const productCostValue =
    isProductCostGreaterThanZero && !isChargedBackOrder
      ? `${productCostCurrency} ${formattedProductCostValue}`
      : '-';

  const orderSynchronizations = order.order_synchronizations;

  const productCostDsersIcon = getProductCostDsersIcon(orderSynchronizations);

  const isDsersTooltipShow =
    isProductCostGreaterThanZero && Boolean(hasDsersIntegration) && !isChargedBackOrder;

  return (
    <Table.Data align={EHeadAlign.RIGHT}>
      <S.ProductCostDataContent>
        <S.ProductCostText weight={ETextWeight.REGULAR}>{productCostValue}</S.ProductCostText>

        {isDsersTooltipShow && (
          <S.Tooltip>
            <S.Tooltip.Trigger>{productCostDsersIcon}</S.Tooltip.Trigger>

            <S.TooltipContent side="bottom">
              <TooltipContent synchronization={orderSynchronizations[0]} />

              <S.Tooltip.Arrow />
            </S.TooltipContent>
          </S.Tooltip>
        )}
      </S.ProductCostDataContent>
    </Table.Data>
  );
};

export default Cogs;
