import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';
import CustomDatePickerBase from '@components/common/core/Utils/CustomDatePicker';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import AlertBase from '@components/common/core/DataDisplay/Alert';
import SwitchBase from '@components/common/core/Inputs/Switch';
import LabelBase from '@components/common/core/DataDisplay/Label';

export const Label = styled(LabelBase)`
  margin: unset;
`;

export const Switch = styled(SwitchBase)``;

export const SwitchGroup = styled(InputGroupBase)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 20px;

  & > div:first-child {
    width: fit-content;
  }
`;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 48px;
`;

export const SyncButton = styled(ButtonBase)``;

export const CustomDatePicker = styled(CustomDatePickerBase)`
  margin-bottom: 48px;
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 12px;
`;

export const Content = styled.div``;

export const Alert = styled(AlertBase)`
  margin-bottom: 22px;
  padding: 8px 16px;
`;
