import React from 'react';
import * as HoverCardPrimitive from '@radix-ui/react-hover-card';

import * as S from './styles';

const Trigger: React.FC<
  HoverCardPrimitive.HoverCardTriggerProps & React.RefAttributes<HTMLAnchorElement>
> = ({ children, ...rest }) => {
  return <S.HoverCardTrigger {...rest}>{children}</S.HoverCardTrigger>;
};

export default Trigger;
