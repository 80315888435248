import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/CustomModal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Wrapper = styled(ModalBase)`
  width: 100%;
  max-width: 480px;
  height: auto;
  padding: 32px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const Message = styled(TextBase)`
  font-size: 1.125rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[1]};
  text-align: center;
  margin: 17px 0;
`;

export const Button = styled(ButtonBase)``;
