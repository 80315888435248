import React from 'react';

import { useForm } from 'react-hook-form';
import { EHeadingSize } from '@domain/enums/components/EHeading';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSuggestions } from '@helpers/hooks/pages/dashboard/ideasChannel/useSuggestions';
import { createSuggestionSchema } from '@helpers/validators/dashboard/ideasChannel.ts/suggestion';

import Draft from '@components/common/core/Inputs/Draft';
import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const DraftContent: React.FC = () => {
  const { createSuggestion, isCreatingSuggestion } = useSuggestions();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(createSuggestionSchema),
  });

  const [draftContent, setDraftContent] = React.useState<string>('');

  const handleDraft = React.useCallback(content => {
    setDraftContent(content);
  }, []);

  const onSubmit = React.useCallback(
    formData => {
      const data = {
        title: formData.suggestion_title,
        description: formData.suggestion_description,
      };

      createSuggestion({ data });
    },
    [createSuggestion],
  );

  return (
    <S.DraftContentWrapper>
      <S.Header>
        <S.Title type={EHeadingSize.H3}>Nova Ideia</S.Title>
        <S.DescriptionText>
          Deixe sua <strong>sugestão</strong>, ela é muito <strong>importante</strong> pra nós.
        </S.DescriptionText>
      </S.Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.Body>
          <S.InputGroup>
            <S.Label>Título</S.Label>
            <S.TextField
              {...register('suggestion_title')}
              name="suggestion_title"
              type="text"
              placeholder="Escreva um título para sua sugestão"
            />
            {errors.suggestion_title && (
              <Text isErrorFeedback>{errors.suggestion_title.message}</Text>
            )}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Descrição da ideia</S.Label>
            <Draft
              {...register('suggestion_description', { value: draftContent })}
              placeholder="Digite a descrição da ideia aqui"
              handleDraft={handleDraft}
            />
            {errors.suggestion_description && !draftContent && (
              <Text isErrorFeedback>{errors.suggestion_description.message}</Text>
            )}
          </S.InputGroup>

          <S.Button type="submit" isLoading={isCreatingSuggestion} disabled={isCreatingSuggestion}>
            Enviar
          </S.Button>
        </S.Body>
      </Form>
    </S.DraftContentWrapper>
  );
};

export default DraftContent;
