import { ADS_PROVIDERS } from './adsProviders';
import { DROPSHIPPING_PROVIDERS } from './dropshippingProviders';
import { ERPS } from './erps';
import { GATEWAYS } from './gateways';
import { INFO_PRODUCT_PROVIDERS } from './infoProductProviders';
import { ECOMMERCE_PROVIDERS } from './ecommerceProviders';
import { ECOMMERCE_GATEWAYS } from './gateways/ecommerce';

const STORE_PROVIDERS = [
  ...DROPSHIPPING_PROVIDERS,
  ...INFO_PRODUCT_PROVIDERS,
  ...ECOMMERCE_PROVIDERS,
];

const DNVB_PROVIDERS = DROPSHIPPING_PROVIDERS;
const ENCAPSULATED_PROVIDERS = DROPSHIPPING_PROVIDERS;
const DNVB_GATEWAYS = GATEWAYS;
const ENCAPSULATED_GATEWAYS = GATEWAYS;

export {
  ADS_PROVIDERS,
  DROPSHIPPING_PROVIDERS,
  ERPS,
  GATEWAYS,
  INFO_PRODUCT_PROVIDERS,
  STORE_PROVIDERS,
  ECOMMERCE_PROVIDERS,
  DNVB_PROVIDERS,
  ENCAPSULATED_PROVIDERS,
  DNVB_GATEWAYS,
  ENCAPSULATED_GATEWAYS,
  ECOMMERCE_GATEWAYS,
};
