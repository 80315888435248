import React from 'react';
import { SphereSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components/macro';

import * as S from './styles';

export const PageLoading: React.FC = () => {
  const theme = useTheme();

  return (
    <S.Container>
      <SphereSpinner size={48} color={theme.colors.green.default} />
    </S.Container>
  );
};

export default PageLoading;
