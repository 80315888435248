import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { ILimitationModalProps } from '@domain/interfaces/components/ILimitationModal';
import { IParams } from '@domain/interfaces/IParams';

import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const LimitationModal: React.FC<ILimitationModalProps> = ({
  isOpen,
  toggle,
  imageSrc,
  title,
  description,
  trackingPropertyName,
}) => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { analytics, user } = useConfig();

  const onNotInterestClick = React.useCallback(() => {
    analytics?.track(
      'Limited Feature Modal Button Clicked',
      {
        type: trackingPropertyName,
        source: 'NOT_INTERESTED',
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );

    toggle();
  }, [toggle, analytics, trackingPropertyName, user, storeAliasId]);

  const onSeePlans = React.useCallback(() => {
    analytics?.track(
      'Limited Feature Modal Button Clicked',
      {
        type: trackingPropertyName,
        source: 'SEE_PLANS',
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );

    history.push(`/${storeAliasId}/dashboard/subscription?tab=plans`);
  }, [history, storeAliasId, analytics, user, trackingPropertyName]);

  React.useEffect(() => {
    if (isOpen) {
      analytics?.track(
        'Limited Feature Modal Viewed',
        {
          type: trackingPropertyName,
          email: user?.email,
        },
        { context: { groupId: storeAliasId } },
      );
    }
  }, [analytics, isOpen, user, storeAliasId, trackingPropertyName]);

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Image src={imageSrc} alt="Profitfy Limited Feature" />

      <S.ContentWrapper>
        <S.TitleAndDescription>
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>
        </S.TitleAndDescription>

        <S.ButtonsWrapper>
          <S.NotInterestedButton
            variant={EButtonVariant.SECONDARY_BUTTON}
            onClick={onNotInterestClick}
          >
            Não tenho interesse
          </S.NotInterestedButton>
          <S.SeePlansButton onClick={onSeePlans}>Consultar planos</S.SeePlansButton>
        </S.ButtonsWrapper>
      </S.ContentWrapper>
    </S.Modal>
  );
};

export default LimitationModal;
