import { ApiService } from '@services/api';

import {
  IDeleteUsersStorePromiseProps,
  IListUsersStorePromise,
  IListUsersStoreProps,
  IListUsersStoreResponse,
} from '@domain/interfaces/services/group/IUserStore';

import { useFetch } from '@providers/Fetch/useFetch';

import { API_DOMAIN } from '@constants/api';
import { AxiosResponse } from 'axios';

export class UserStoreService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listUsersStore({ storeAliasId }: IListUsersStoreProps): IListUsersStoreResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/users-stores`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      users: data?.users,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public listUsersStorePromise({
    storeAliasId,
  }: IListUsersStoreProps): Promise<AxiosResponse<IListUsersStorePromise>> {
    return this.apiService.get(`/api/v1/users/stores/${storeAliasId}/users-stores`);
  }

  public deleteUsersStorePromise({
    storeAliasId,
    data,
  }: IDeleteUsersStorePromiseProps): Promise<AxiosResponse<void>> {
    return this.apiService.delete<void>(`/api/v1/users/stores/${storeAliasId}/users-stores`, {
      ...data,
    });
  }
}

const userStoreService = new UserStoreService(API_DOMAIN);

export default userStoreService;
