import styled from 'styled-components/macro';

export const PageSkeletonWrapper = styled.div`
  width: 100%;
  max-width: 1016px;
  margin: 0 auto;
  padding-top: 36px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 18px;

  @media only screen and (max-width: 420px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const Header = styled.div`
  width: 100%;
  max-width: 1016px;
`;

export const TablesSkeletonWrapper = styled.div`
  width: 100%;
  max-width: 1016px;
  margin: 0 auto;
`;
