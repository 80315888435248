import React from 'react';

import { MessageProvider } from '@helpers/hooks/pages/dashboard/automations/useMessage';
import { MessageSettingsProvider } from '@helpers/hooks/pages/dashboard/automations/useMessageSettings';
import { MessageContentProvider } from '@helpers/hooks/pages/dashboard/automations/useMessageContent';
import { ContentProvider } from '@helpers/hooks/pages/dashboard/automations/useContent';

import Content from '@components/Dashboard/Automations/Messages';

const Messages: React.FC = () => {
  return (
    <ContentProvider>
      <MessageSettingsProvider>
        <MessageContentProvider>
          <MessageProvider>
            <Content />
          </MessageProvider>
        </MessageContentProvider>
      </MessageSettingsProvider>
    </ContentProvider>
  );
};

export default Messages;
