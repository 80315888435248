export const OPEN_REPLAY_AXIOS_CONFIG = {
  failuresOnly: false,
  sanitiser: (data: any): any => {
    if (data.response.body.password) {
      data.response.body.password = '<PASSWORD>';
    }

    return data;
  },
};

export const OPEN_REPLAY_FETCH_CONFIG = {
  overrideGlobal: false,
  failuresOnly: false,
  sessionTokenHeader: undefined,
  ignoreHeaders: false,
  sanitiser: (data: any): any => {
    if (data.response.body.password) {
      data.response.body.password = '<PASSWORD>';
    }

    return data;
  },
};
