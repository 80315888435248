export default {
  background: {
    1: '#0F1116',
    2: '#2E323E',
    3: '#515562',
    4: '#515562',
    5: '#2E323E',
    6: '#A7AFC6',
    7: '#515562',
    8: '#23262F',
    9: '#0F1116',
    10: '#23262F',
    11: '#2E323E',
    12: '#515562',
    13: 'transparent',
    14: '#515562',
    15: '#D9DDE9',
    input: '#2E323E',
  },
  sideBarColor: '#3B404F',
  sideBarSubMenuColor: '#23262F',
  inputBorderColor: 'transparent',
  inputBorderColorHover: 'transparent',
  checkboxStrokeColor: '#0F1116',
  popoverTitleColor: '#D9DDE9',
  chartTextAxis: '#D9DDE9',
  sideModal: '#515562',
  sideModalExitIcon: '#A7AFC6',
  card: '#2E323E',
  font: '#D9DDE9',
  filterFont: '#D9DDE9',
  green: {
    default: '#25CFBB',
    light: '#6FD5C3',
    dark: '#2EB3B4',
  },
  white: {
    default: '#F8F9FE',
    light: '#FBFAFC',
    dark: '#EDEBF0',
  },
  black: {
    default: '#17151A',
    light: '#0B0B0D',
    dark: '#0B0B0D',
  },
  gray: {
    1: '#D9DDE9',
    2: '#A7AFC6',
    3: '#838BA1',
    4: '#515562',
    5: '#3B404F',
    6: '#23262F',
    7: '#0F1116',
    8: '#0F1116',
  },
  danger: {
    default: '#D0425C',
    light: '#D0425C',
    dark: '#D0425C',
    input: {
      defaultBackground: '#22191D',
      activeBackground: '#22191D',
    },
  },
  success: {
    default: '#59B578',
    light: '#50E170',
    dark: '#28BB49',
    input: {
      defaultBackground: '#19221E',
      activeBackground: '#19221E',
    },
  },
  warning: {
    default: '#F6A609',
    light: '#FFBC1F',
    dark: '#E89806',
    input: {
      defaultBackground: '#222219',
      activeBackground: '#222219',
    },
  },
  info: {
    default: '#0063F7',
    light: '#5B8DEF',
    dark: '#004FC4',
  },
  purple: {
    default: '#804DFA',
    light: '#A37CFF',
    dark: '#6727FE',
  },
};
