import styled, { keyframes } from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

const slideUpAndFade = keyframes`
  0% {
    opacity: 0;
    transform: translateY(4px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const PlusIconWrapper = styled.div`
  padding: 20px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${({ theme }) => theme.colors.gray[4]};
`;

export const Text = styled(TextBase)``;

export const Wrapper = styled.button`
  background: unset;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray[4]};
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding-left: 32px;
  margin-bottom: 24px;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s;
  animation-duration: 700ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-fill-mode: forwards;
  animation-name: ${slideUpAndFade};
  will-change: 'transform, opacity';

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;
