import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Description = styled(TextBase)`
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[2]};
  font-size: 0.875rem;
`;

export const Title = styled(TextBase)`
  line-height: 120%;
  color: #ffffff;
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[5]};
  padding: 12px 28px;
  display: flex;
  flex-direction: column;
`;
