import { EOrderStatus, EOrderType } from '@domain/enums/common/EOrder';
import { EFilterType } from '@domain/enums/components/EFilter';
import { EUtmSource } from '@domain/interfaces/common/orders/IOrders';
import { IFilterConfig } from '@domain/interfaces/components/IFilter';
import {
  parseProviderData,
  getParsedStatus,
  getParsedUtmSource,
} from '@helpers/utils/dashboard/orders/orders';

const providerData = parseProviderData();

export const ORDERS_FILTER_CONFIG: Array<IFilterConfig> = [
  {
    field: 'name',
    label: 'Pedido',
    type: EFilterType.TEXT,
    config: {
      placeholder: 'Encontrar pedido...',
    },
  },
  {
    field: 'product_id',
    label: 'Produto',
    type: EFilterType.PRODUCTS,
    config: {
      placeholder: 'Encontrar produto...',
    },
  },
  {
    field: 'provider',
    label: 'Canal de vendas',
    type: EFilterType.SELECT,
    config: {
      placeholder: 'Encontrar canal de venda...',
      data: [...providerData],
    },
  },
  {
    field: 'utm_source',
    label: 'Origem do pedido',
    type: EFilterType.SELECT,
    config: {
      placeholder: 'Encontrar origem do pedido...',
      data: [
        {
          value: EUtmSource.FACEBOOK,
          label: getParsedUtmSource(EUtmSource.FACEBOOK),
        },
        {
          value: EUtmSource.GOOGLE,
          label: getParsedUtmSource(EUtmSource.GOOGLE),
        },
        {
          value: EUtmSource.TIKTOK,
          label: getParsedUtmSource(EUtmSource.TIKTOK),
        },
        {
          value: EUtmSource.TABOOLA,
          label: getParsedUtmSource(EUtmSource.TABOOLA),
        },
        {
          value: EUtmSource.SMS,
          label: getParsedUtmSource(EUtmSource.SMS),
        },
        {
          value: EUtmSource.EMAIL,
          label: getParsedUtmSource(EUtmSource.EMAIL),
        },
        {
          value: EUtmSource.ORGANIC,
          label: getParsedUtmSource(EUtmSource.ORGANIC),
        },
        {
          label: getParsedUtmSource(EUtmSource.ABANDONED_CART),
          value: EUtmSource.ABANDONED_CART,
        },
        {
          value: EUtmSource.OTHER,
          label: getParsedUtmSource(EUtmSource.OTHER),
        },
      ],
    },
  },
  {
    field: 'type',
    label: 'Método de pagamento',
    type: EFilterType.SELECT,
    config: {
      placeholder: 'Encontrar método de pagamento...',
      data: [
        {
          value: EOrderType.CREDIT_CARD,
          label: 'Cartão de Crédito',
        },
        {
          value: EOrderType.BOLETO,
          label: 'Boleto',
        },
        {
          value: EOrderType.PIX,
          label: 'PIX',
        },
      ],
    },
  },
  {
    field: 'status',
    label: 'Status do pedido',
    type: EFilterType.SELECT,
    config: {
      placeholder: 'Encontrar status do pedidos...',
      data: [
        {
          value: EOrderStatus.PAID,
          label: getParsedStatus(EOrderStatus.PAID),
        },
        {
          value: EOrderStatus.PENDING,
          label: getParsedStatus(EOrderStatus.PENDING),
        },
        {
          value: EOrderStatus.CANCELED,
          label: getParsedStatus(EOrderStatus.CANCELED),
        },
        {
          value: EOrderStatus.REFUNDED,
          label: getParsedStatus(EOrderStatus.REFUNDED),
        },
        {
          value: EOrderStatus.CHARGED_BACK,
          label: getParsedStatus(EOrderStatus.CHARGED_BACK),
        },
        {
          value: EOrderStatus.IN_ANALYSIS,
          label: getParsedStatus(EOrderStatus.IN_ANALYSIS),
        },
        {
          value: EOrderStatus.REJECTED,
          label: getParsedStatus(EOrderStatus.REJECTED),
        },
        {
          value: EOrderStatus.NO_INFORMATION,
          label: getParsedStatus(EOrderStatus.NO_INFORMATION),
        },
      ],
    },
  },
];
