import { ApiService } from '@services/api';
import { API_DOMAIN } from '@constants/api';
import {
  IGetCredentialProps,
  ICreateCredentialProps,
  IUpdateCredentialProps,
  IGenerateURLProps,
} from '@domain/interfaces/dashboard/IntegrationCenter/InfoProduct/Platforms/IMonetizzeAuth';

export class MonetizzeService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getCredential({ storeAliasId }: IGetCredentialProps): Promise<any> {
    return this.apiService.get(`/api/v1/users/stores/${storeAliasId}/monetizze-credentials`);
  }

  public createCredential({ storeAliasId, data }: ICreateCredentialProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/monetizze-credentials`, {
      ...data,
    });
  }

  public generateWebhookURL({ storeAliasId }: IGenerateURLProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/monetizze-credentials/generate-webhook-url`,
    );
  }

  public updateCredential({
    storeAliasId,
    credentialAliasId,
    data,
  }: IUpdateCredentialProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/monetizze-credentials/${credentialAliasId}`,
      {
        ...data,
      },
    );
  }
}

const monetizzeService = new MonetizzeService(API_DOMAIN);

export default monetizzeService;
