import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import LabelBase from '@components/common/core/DataDisplay/Label';
import NoImageBase from '@components/common/core/DataDisplay/NoImage';
import ButtonBase from '@components/common/core/Inputs/Button';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import TextFieldBase from '@components/common/core/Inputs/TextField';

export const ApplyToAllButton = styled(ButtonBase)`
  border-radius: 0px 5px 5px 0;

  span {
    font-weight: 500;
  }
`;

export const ApplyToAllSelect = styled(SelectBase)`
  & > label > select {
    border-radius: 5px 0 0 5px;
  }
`;

export const ApplyToAllCurrencyWrapper = styled.div`
  display: flex;
`;

export const ErrorWrapper = styled.div`
  width: 100%;
  margin: 48px 0;
`;

export const DetailsWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray[3]};
  border-radius: 15px;
  padding: 40px 80px;
  display: flex;
  gap: 32px;
  margin-bottom: 24px;

  @media only screen and (max-width: 1060px) {
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (max-width: 800px) {
    padding: 40px 64px;
  }

  @media only screen and (max-width: 748px) {
    padding: 36px 48px;
  }

  @media only screen and (max-width: 648px) {
    padding: 28px;
  }
`;

export const InputsAndShowDeletedVariantsWrapper = styled.div`
  width: 100%;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  /* margin-bottom: 48px; */

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px;
  }
`;

export const Image = styled(ImageBase)`
  width: 86px;
  height: 86px;
  border-radius: 50%;
`;

export const CurrencyInputGroup = styled(InputGroupBase)`
  max-width: 126px;
  width: 100%;
  margin-right: 24px;
`;

export const IOFInputGroup = styled(InputGroupBase)`
  max-width: 160px;
  width: 100%;
  margin-right: 24px;
`;

export const ApplyToAllInputGroup = styled(InputGroupBase)`
  max-width: 430px;
  width: 100%;
`;

export const Label = styled(LabelBase)`
  font-weight: 500;
`;

export const CurrencySelect = styled(SelectBase)`
  max-width: 126px;
  width: 100%;
`;

export const IOFSelect = styled(SelectBase)`
  max-width: 160px;
  width: 100%;

  & > label > select {
    width: 100%;
  }
`;

export const Option = styled(OptionBase)``;

export const ApplyToAllInput = styled(TextFieldBase)`
  max-width: 430px;
  width: 100%;
`;

export const ShowDeletedVariantsWrapper = styled.span`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.background[5]};
  padding: 8px 16px;
  width: fit-content;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.font};
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.background[12]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

// export const Checkbox = styled(CheckboxBase)`
//   margin-left: 12px;
//   margin-right: 8px;
// `;

export const NoImage = styled(NoImageBase)`
  max-width: 86px;
  height: 86px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background[4]};
`;

export const Checkbox = styled(CheckboxBase)``;

export const CheckboxLabel = styled(LabelBase)`
  margin: unset;
  margin-top: 1px;
  cursor: pointer;
`;

export const CheckboxGroup = styled(InputGroupBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
`;
