import React from 'react';
import { useForm } from 'react-hook-form';

import { useDate } from '@helpers/hooks/useDate';
import Form from '@components/common/core/Inputs/Form';
import { currencyFormatter, currencyToNumber } from '@helpers/masks';
import { useVariantHistoric } from '@helpers/hooks/pages/dashboard/productCostDetails/variantHistoric';

import * as S from './styles';

const NewHistoric: React.FC = () => {
  const { utcToZonedTime, format } = useDate();
  const { handleSubmit, register } = useForm({ mode: 'onBlur' });
  const { isCreatingNewVariantCost, createNewVariantCost } = useVariantHistoric();

  const costInput = register('cost');

  const [period, setPeriod] = React.useState({
    startDate: utcToZonedTime(new Date()),
    endDate: utcToZonedTime(new Date()),
  });

  const onSubmit = React.useCallback(
    async data => {
      await createNewVariantCost({
        cost: currencyToNumber(data.cost),
        currency: data.currency,
        start_date: format(period.startDate, 'yyyy-MM-dd'),
        end_date: format(period.endDate, 'yyyy-MM-dd'),
      });
    },
    [createNewVariantCost, format, period],
  );

  const maxDate = utcToZonedTime(new Date());

  return (
    <S.Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <>
          <S.InputGroup>
            <S.Label>Insira o custo</S.Label>

            <S.Input
              {...costInput}
              name="cost"
              type="text"
              placeholder="0,00"
              onChange={event => {
                costInput.onChange(event);

                const { value } = event.target;

                event.target.value = currencyFormatter(value);
              }}
            />
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Selecione a moeda</S.Label>

            <S.Select {...register('currency')} defaultValue="BRL" name="currency">
              <S.Option value="BRL">BRL</S.Option>
              <S.Option value="USD">USD</S.Option>
            </S.Select>
          </S.InputGroup>

          <S.InputGroup>
            <S.DateRangePicker period={period} setPeriod={setPeriod} maxDate={maxDate} />
          </S.InputGroup>

          <S.SubmitButton
            type="submit"
            isLoading={isCreatingNewVariantCost}
            disabled={isCreatingNewVariantCost}
          >
            Adicionar histórico
          </S.SubmitButton>
        </>
      </Form>

      <S.HorizontalRule />
    </S.Wrapper>
  );
};

export default NewHistoric;
