import React from 'react';

import { Task } from '@profitfy/pakkun-icons';
import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { IEditCogsSideModalProps } from '@domain/interfaces/dashboard/ProductCostDetails/IEditCogsSideModal';
import { useProductCostDetails } from '@helpers/hooks/pages/dashboard/productCostDetails/productCostDetails';

import SideModalSave from '@components/common/core/Utils/SideModalSave';

import Details from './Details';
import Table from './Table';

import * as S from './styles';

const EditCogsSideModal: React.FC<IEditCogsSideModalProps> = ({ isOpen, toggle }) => {
  const { handleSyncPastCogsModalOpen } = useProductCostDetails();

  const onSuccess = React.useCallback(() => {
    toggle();
    handleSyncPastCogsModalOpen();
  }, [handleSyncPastCogsModalOpen, toggle]);

  return (
    <S.SideModal isOpen={isOpen} toggle={toggle}>
      <S.HeadingWrapper>
        <Task size={24} outline />
        <S.Heading fontWeight={EHeadingWeight.REGULAR}>Alterar custo de produto</S.Heading>
      </S.HeadingWrapper>

      <Details />

      <Table />

      <SideModalSave
        onSuccess={onSuccess}
        onCancel={toggle}
        successButtonText="Salvar alteração"
        cancelButtonText="Voltar"
        cancelButtonTextColor="#fff"
      />
    </S.SideModal>
  );
};

export default EditCogsSideModal;
