import React from 'react';

import { EBadgeColors } from '@domain/enums/components/EBadge';
import { IAdSenseCredentialOptionProps } from '@domain/interfaces/dashboard/Alerts/ExceededFacebookProfiles/IAdSenseCredentialOption';

import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';

import { ALL_PLANS } from '@constants/plans';

import * as S from './styles';

const AdSenseCredentialOption: React.FC<IAdSenseCredentialOptionProps> = ({
  adSenseCredential,
  updateSelectedCredential,
  selectedAdSenseCredentials,
}) => {
  const { subscription } = useStoreSubscription();

  const subscriptionPlanIdentifier = subscription.plan?.identifier as string;

  const storePlan = ALL_PLANS.find(
    plan => plan.identifier === subscriptionPlanIdentifier,
  ) as typeof ALL_PLANS[number];

  const foundSelectedCredential = selectedAdSenseCredentials.find(
    credential => credential.id === adSenseCredential.id,
  );

  const [isChecked, setIsChecked] = React.useState<boolean>(false);

  const onChange = React.useCallback(
    event => {
      if (!event || event.target) return;

      updateSelectedCredential(adSenseCredential);

      setIsChecked(event.checked);
    },
    [updateSelectedCredential, adSenseCredential],
  );

  const parseOperationalStatus = React.useCallback(isOperational => {
    if (isOperational) return 'Operacional';

    return 'Não Operacional';
  }, []);

  const getOperationalBadgeColor = React.useCallback(isOperational => {
    if (isOperational) return EBadgeColors.PRIMARY;

    return EBadgeColors.RED;
  }, []);

  React.useEffect(() => {
    if (foundSelectedCredential) {
      setIsChecked(true);
    }
  }, [foundSelectedCredential]);

  const adSenseCredentialName = adSenseCredential.name;
  const parsedOperationalStatus = parseOperationalStatus(adSenseCredential.is_operational);
  const operationalBadgeColor = getOperationalBadgeColor(adSenseCredential.is_operational);
  const checkboxId = `ad-sense-credential-${adSenseCredential.id}`;
  const isCheckboxDisabled =
    selectedAdSenseCredentials.length >= storePlan.facebookProfile && !isChecked;

  return (
    <S.Wrapper>
      <S.ProfileLabel>
        <S.Checkbox
          onChange={onChange}
          id={checkboxId}
          defaultChecked={Boolean(foundSelectedCredential)}
          disabled={isCheckboxDisabled}
        />

        <S.ProfileName>{adSenseCredentialName}</S.ProfileName>

        <S.StatusBadge color={operationalBadgeColor}>{parsedOperationalStatus}</S.StatusBadge>
      </S.ProfileLabel>
    </S.Wrapper>
  );
};

export default AdSenseCredentialOption;
