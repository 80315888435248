import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ImageBase from '@components/common/core/DataDisplay/Image';
import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const Wrapper = styled(ModalBase)`
  width: 100%;
  max-width: 928px;
  height: 460px;
  padding: 32px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: 20px;
`;

export const GroupTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Title = styled(TextBase)`
  font-size: 1.25rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const CloseButton = styled.button`
  background: unset;
  padding: unset;
  border: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 864px;
  height: fit-content;
  padding: 0 32px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 908px) {
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;
  }

  @media only screen and (max-width: 414px) {
    align-items: center;
  }
`;

export const HeadingAndStepsWrapper = styled.div`
  width: 100%;
  max-width: 493px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Heading = styled(HeadingBase)`
  color: #ffffff;

  mark {
    color: ${({ theme }) => theme.colors.green.default};
    background: unset;
  }
`;

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StepText = styled(TextBase)`
  color: #ffffff;
`;

export const WhatsappConfigImg = styled.img`
  margin: 4px;
  margin-bottom: -4px;
`;

export const QRCodeWrapper = styled.div`
  width: 100%;
  max-width: 280px;
  min-height: 280px;
  max-height: 280px;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 27px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const QRCodeImage = styled(ImageBase)`
  max-width: 225px;
`;

export const LoadingQRCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
`;

export const LoadingQRCodeWarn = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[5]};
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  line-height: 1.3;
`;
