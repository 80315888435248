export const TRANSACTION_CODE_ERRORS = {
  '0': 'Transação autorizada com sucesso.',
  '00': 'Transação autorizada com sucesso.',
  '01': 'Transação não autorizada. Referida (suspeita de fraude) pelo banco emissor.',
  '1': 'Transação não autorizada. Referida (suspeita de fraude) pelo banco emissor.',
  '02': 'Transação não autorizada. Referida (suspeita de fraude) pelo banco emissor.',
  '2': 'Transação não autorizada. Referida (suspeita de fraude) pelo banco emissor.',
  '03': 'Não foi possível processar a transação.',
  '3': 'Não foi possível processar a transação.',
  '04': 'Transação não autorizada. Cartão bloqueado pelo banco emissor.',
  '4': 'Transação não autorizada. Cartão bloqueado pelo banco emissor.',
  '05': 'Transação não autorizada. Cartão inadimplente (Do not honor).',
  '5': 'Transação não autorizada. Cartão inadimplente (Do not honor).',
  '06': 'Transação não autorizada. Cartão cancelado.',
  '6': 'Transação não autorizada. Cartão cancelado.',
  '07': 'Transação negada.',
  '7': 'Transação negada.',
  '08': 'Transação não autorizada. Código de segurança inválido.',
  '8': 'Transação não autorizada. Código de segurança inválido.',
  '09': 'Transação cancelada parcialmente com sucesso.',
  '9': 'Transação cancelada parcialmente com sucesso.',
  '11': 'Transação autorizada com sucesso para cartão emitido no exterior',
  '12': 'Transação inválida, erro no cartão.',
  '13': 'Transação não permitida. Valor da transação Inválido.',
  '14': 'Transação não autorizada. Cartão Inválido',
  '15': 'Banco emissor indisponível ou inexistente.',
  '19': '-',
  '21': 'Cancelamento não efetuado. Transação não localizada.',
  '22': 'Parcelamento inválido. Número de parcelas inválidas.',
  '23': 'Transação não autorizada. Valor da prestação inválido.',
  '24': 'Quantidade de parcelas inválido.',
  '25': 'Pedido de autorização não enviou número do cartão',
  '28': 'Arquivo temporariamente indisponível.',
  '30': 'Transação não autorizada. Decline Message',
  '39': 'Transação não autorizada. Erro no banco emissor.',
  '41': 'Transação não autorizada. Cartão bloqueado por perda.',
  '43': 'Transação não autorizada. Cartão bloqueado por roubo.',
  '46': 'Conta encerrada',
  '51': 'Transação não autorizada. Limite excedido/sem saldo.',
  '52': 'Cartão com dígito de controle inválido.',
  '53': 'Transação não permitida. Cartão poupança inválido',
  '54': 'Transação não autorizada. Cartão vencido',
  '55': 'Transação não autorizada. Senha inválida',
  '56': 'NÚMERO CARTÃO NÃO PERTENCE AO EMISSOR | NÚMERO CARTÃO INVÁLIDO',
  '57': 'Transação não permitida para o cartão',
  '58': 'Transação não permitida. Opção de pagamento inválida.',
  '59': 'Transação não autorizada. Suspeita de fraude.',
  '60': 'Transação não autorizada.',
  '61': 'Banco emissor indisponível.',
  '62': 'Transação não autorizada. Cartão restrito para uso doméstico',
  '63': 'Transação não autorizada. Violação de segurança',
  '64': 'Transação não autorizada. Valor abaixo do mínimo exigido pelo banco emissor.',
  '65': 'Transação não autorizada. Excedida a quantidade de transações para o cartão.',
  '67': 'Transação não autorizada. Cartão bloqueado para compras hoje.',
  '70': 'Transação não autorizada. Limite excedido/sem saldo.',
  '72': 'Cancelamento não efetuado. Saldo disponível para cancelamento insuficiente.',
  '74': 'Transação não autorizada. A senha está vencida.',
  '75': 'Senha bloqueada. Excedeu tentativas de cartão.',
  '76': 'Cancelamento não efetuado. Banco emissor não localizou a transação original.',
  '77': 'Cancelamento não efetuado. Não foi localizada a transação original.',
  '78': 'Transação não autorizada. Cartão bloqueado primeiro uso.',
  '79': 'Transação não autorizada.',
  '80': 'Transação não autorizada. Divergência na data de transação/pagamento.',
  '81': 'Transação não autorizada. A senha está vencida.',
  '82': 'Transação não autorizada. Cartão inválido.',
  '83': 'Transação não autorizada. Erro no controle de senhas.',
  '85': 'Transação não permitida. Falha na operação.',
  '86': 'Transação não permitida. Falha na operação.',
  '88': 'Falha na criptografia dos dados.',
  '89': 'Erro na transação.',
  '90': 'Transação não permitida. Falha na operação.',
  '91': 'Transação não autorizada. Banco emissor temporariamente indisponível.',
  '92': 'Transação não autorizada. Tempo de comunicação excedido.',
  '93': 'Transação não autorizada. Violação de regra, possível erro no cadastro.',
  '94': 'Transação duplicada.',
  '96': 'Falha no processamento.',
  '97': 'Valor não permitido para essa transação.',
  '98': 'Sistema/comunicação indisponível.',
  '99': 'Sistema/comunicação indisponível.',
  '475': 'Timeout de Cancelamento',
  '999': 'Sistema/comunicação indisponível.',
  A2: 'VERIFIQUE OS DADOS DO CARTÃO',
  A3: 'ERRO NO CARTÃO (NÃO TENTE NOVAMENTE)',
  A5: 'TRANSAÇÃO NÃO PERMITIDA (NÃO TENTE NOVAMENTE)',
  A7: 'ERRO NO CARTÃO (NÃO TENTE NOVAMENTE)',
  AA: 'Tempo Excedido',
  AB: 'FUNÇÃO INCORRETA (DÉBITO)',
  AC: 'Transação não permitida. Cartão de débito sendo usado com crédito.',
  AE: 'Tente Mais Tarde',
  AF: 'Transação não permitida. Falha na operação.',
  AG: 'Transação não permitida. Falha na operação.',
  AH: 'Transação não permitida. Cartão de crédito sendo usado com débito.',
  AI: 'Transação não autorizada. Autenticação não foi realizada.',
  AJ:
    'Transação não permitida. Transação de crédito ou débito em uma operação que permite apenas Private Label.',
  AV: 'Transação não autorizada. Dados Inválidos',
  BD: 'Transação não permitida. Falha da operação.',
  BL: 'Transação não autorizada. Limite diário excedido.',
  BM: 'Transação não autorizada. Cartão Inválido',
  BN: 'Transação não autorizada. Cartão ou conta bloqueado.',
  BO: 'Transação não permitida. Falha da operação.',
  BP: 'Transação não autorizada. Conta corrente inexistente.',
  BP176: 'Transação não permitida.',
  BV: 'Transação não autorizada. Cartão vencido',
  CF: 'Transação não autorizada.C79:J79 Falha na validação dos dados.',
  CG: 'Transação não autorizada. Falha na validação dos dados.',
  DA: 'Transação não autorizada. Falha na validação dos dados.',
  DF: 'Transação não permitida. Falha no cartão ou cartão inválido.',
  DM: 'Transação não autorizada. Limite excedido/sem saldo.',
  DQ: 'Transação não autorizada. Falha na validação dos dados.',
  DS: 'Transação não permitida para o cartão',
  EB: 'Transação não autorizada. Limite diário excedido.',
  EE: 'Transação não permitida. Valor da parcela inferior ao mínimo permitido.',
  EK: 'Transação não permitida para o cartão',
  FA: 'Transação não autorizada.',
  FC: 'Transação não autorizada. Ligue Emissor',
  FD: 'Transação negada. Reter cartão condição especial',
  FE: 'Transação não autorizada. Divergencia na data de transação/pagamento.',
  FF: 'Cancelamento OK',
  FG: 'Transação não autorizada. Ligue AmEx 08007285090.',
  GA: 'Aguarde Contato',
  GD: 'Transação não permitida.',
  HJ: 'Transação não permitida. Código da operação inválido.',
  IA: 'Transação não permitida. Indicador da operação inválido.',
  JB: 'Transação não permitida. Valor da operação inválido.',
  P5: 'TROCA DE SENHA / DESBLOQUEIO',
  KA: 'Transação não permitida. Falha na validação dos dados.',
  KB: 'Transação não permitida. Selecionado a opção incorrente.',
  KE: 'Transação não autorizada. Falha na validação dos dados.',
  N7: 'Transação não autorizada. Código de segurança inválido.',
  R0: 'SUSPENSÃO DE PAGAMENTO RECORRENTE PARA UM SERVIÇO',
  R1: 'Transação não autorizada. Cartão inadimplente (Do not honor)',
  R2: 'TRANSAÇÃO NÃO QUALIFICADA PARA VISA PIN',
  R3: 'SUSPENSÃO DE TODAS AS ORDENS DE AUTORIZAÇÃO',
  U3: 'Transação não permitida. Falha na validação dos dados.',
  N3: 'SAQUE NÃO DISPONÍVEL',
  N8: 'DIFERENÇA. PRÉ AUTORIZAÇÃO',
  NR: 'Transação não permitida.',
  RP: 'Transação não permitida.',
  '99A': 'Token não encontrado',
  '99B': 'Sistema indisponível/Falha na comunicação',
  '99C': 'Sistema indisponível/Exceção no processamento',
  '99Z': 'Sistema indisponível/Retorno desconhecido',
  '99TA': 'Timeout na requisição. O tempo para receber o retorno da requisição excedeu.',
  AF01: 'Recusado manualmente em analise antifraude',
  AF02: 'Recusado automaticamente em analise antifraude',
  AF03: 'Recusado pelo antifraude da adquirente de crédito',
  '126': 'A data de validade do cartão de crédito é inválida',
};
