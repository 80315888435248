import styled, { css } from 'styled-components/macro';

import { EHeadAlign } from '@domain/enums/components/ETable';

export const TableWrapper = styled.div`
  padding: 0;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 6px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.background[5]};
    border-radius: 25px;
    margin-bottom: 4px;
  }
`;

export const Table = styled.table`
  width: 100%;
  min-width: max-content;
  border-collapse: collapse;
`;

export const TableHeader = styled.thead``;

export const TableHead = styled.th`
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: 500;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.font};
  padding: 0 12px 18px 12px;

  &:first-child {
    padding-left: 0px;
  }

  ${({ align }) =>
    align === EHeadAlign.CENTER &&
    css`
      text-align: center;
    `};

  ${({ align }) =>
    align === EHeadAlign.RIGHT &&
    css`
      text-align: right;
    `};
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr``;

export const TableData = styled.td`
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.font};
  font-weight: 400;
  padding: 0 12px 24px 12px;

  &:first-child {
    padding-left: 0px;
  }

  ${({ align }) =>
    align === EHeadAlign.CENTER &&
    css`
      text-align: center;
    `};

  ${({ align }) =>
    align === EHeadAlign.RIGHT &&
    css`
      text-align: right;
    `};
`;

export const TableFooter = styled.tfoot``;

export const HorizontalRule = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.gray[3]};
  margin: 48px 0;
`;

export const ButtonWrapper = styled.div`
  max-width: 176px;
  width: 100%;
`;

export const DateInputWrapper = styled.div``;

export const DateWrapper = styled.div`
  max-width: 240px;
  width: 100%;
`;

export const DateRangePickerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  align-items: center;

  @media only screen and (max-width: 450px) {
    flex-direction: column;
  }
`;

export const SelectWrapper = styled.div``;

export const InputWrapper = styled.div`
  max-width: 504px;
  width: 100%;
`;

export const LabelWrapper = styled.div`
  max-width: 168px;
  width: 100%;
`;

export const InputGroupWrapper = styled.div`
  margin-bottom: 16px;
`;

export const ProductNameWrapper = styled.div`
  max-width: 128px;
  width: 100%;
  margin-bottom: 24px;
`;

export const NewVariantCostWrapper = styled.div`
  max-width: 504px;
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 42px;
  max-width: 256px;
  width: 100%;
`;

export const FirstStepWrapper = styled.div``;
