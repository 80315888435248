import styled from 'styled-components/macro';

import GoBackButton from '@components/common/core/Utils/GoBackButton';

export const Return = styled(GoBackButton)`
  position: absolute;
  top: 42px;
  left: 40px;

  @media only screen and (max-width: 1345px) {
    top: 0px;
    left: -5px;
  }
`;
