import styled, { css, keyframes } from 'styled-components/macro';
import { Handle as HandleBase } from 'react-flow-renderer';

import TextBase from '@components/common/core/DataDisplay/Text';

const optionsAnimation = keyframes`
from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }`;

export const Option = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const OptionsWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: -40px;
  right: -16px;
  padding: 8px 16px;
  width: 50%;
  animation: ${optionsAnimation} 250ms ease-in-out;
`;

export const InfoWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: -40px;
  right: 12px;
  padding: 8px 16px;
  width: 50%;
  animation: ${optionsAnimation} 250ms ease-in-out;
`;

export const Info = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div<{ missingRules: boolean }>`
  ${({ missingRules, theme }) => css`
    display: flex;
    align-items: center;
    height: 56px;
    padding: 12px 16px 12px 16px;
    box-sizing: border-box;
    background-color: ${theme.colors.gray[5]};
    border: 2px solid #efc980;
    border-radius: 12px;
    gap: 12px;
    position: relative;

    ${missingRules &&
    css`
      border: 2px solid ${theme.colors.danger.light};
    `}
  `}
`;

export const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background-color: #efc980;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Name = styled(TextBase)`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white.dark};
`;

export const InputHandleContainer = styled.div`
  width: 24px;
  height: 20px;
  border-radius: 12px;
  padding: 4px 6px 4px 6px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  box-sizing: border-box;
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
`;

export const SourceHandle = styled(HandleBase)`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.gray[3]};
  border: none;
  bottom: 4px;
`;

export const TargetHandleContainer = styled.div`
  width: 24px;
  height: 20px;
  border-radius: 12px;
  padding: 4px 6px 4px 6px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  box-sizing: border-box;
  position: absolute;
  top: -11px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TargetHandle = styled(HandleBase)`
  width: unset;
  height: unset;
  background-color: transparent;
  border: none;
  position: absolute !important;
  transform: unset;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const MissingRulesWarning = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;

  align-items: center;
  justify-content: center;
`;
