import React from 'react';

import { Plus } from 'phosphor-react';
import { useHistory } from 'react-router-dom';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { EButtonSize, EButtonVariant } from '@domain/enums/components/EButton';

import { useConfig } from '@helpers/hooks/useConfig';
import { useStorePermission } from '@helpers/hooks/common/useStorePermission';
import { useWhatsappInstance } from '@helpers/hooks/pages/dashboard/whatsappInstance/useWhatsappInstance';

import LimitationModal from '@components/common/core/Utils/LimitationModal';

import * as S from './styles';

const Header: React.FC = () => {
  const history = useHistory();

  const { analytics, selectedStore } = useConfig();
  const { maxWhatsappInstances } = useStorePermission();
  const { whatsappCredentials: instances } = useWhatsappInstance();

  const [isLimitationModalOpen, setIsLimitationModalOpen] = React.useState<boolean>(false);

  const handleLimitationModalOpen = React.useCallback(() => {
    setIsLimitationModalOpen(state => !state);
  }, []);

  const onNewInstanceButtonClick = React.useCallback(() => {
    analytics?.track(
      'Whatsapp Instance Initial Page Button Clicked',
      {},
      { context: { groupId: selectedStore?.alias_id } },
    );
    if ((instances?.length ?? 0) >= maxWhatsappInstances && maxWhatsappInstances !== -1) {
      handleLimitationModalOpen();
      return;
    }

    history.push('messages-instances/new-instance');
  }, [
    history,
    selectedStore,
    analytics,
    instances,
    maxWhatsappInstances,
    handleLimitationModalOpen,
  ]);

  return (
    <S.Wrapper>
      <S.Heading type={EHeadingSize.H3}>Instâncias de Whatsapp</S.Heading>

      <S.NewInstanceButton
        onClick={onNewInstanceButtonClick}
        iconPrepend={() => <Plus size={14} color="#ffffff" />}
        variant={EButtonVariant.SECONDARY_BUTTON}
        buttonSize={EButtonSize.MEDIUM}
      >
        Nova instância
      </S.NewInstanceButton>

      <LimitationModal
        isOpen={isLimitationModalOpen}
        toggle={handleLimitationModalOpen}
        imageSrc="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/temp/limitation-temp.svg"
        title="Limite de enviadores de WhatsApp atingido"
        description="Escolha um plano que te permita adicionar mais enviadores para que consiga usufruir o melhor da ferramenta."
        trackingPropertyName="MAX_WHATSAPP_INSTANCES_REACHED"
      />
    </S.Wrapper>
  );
};

export default Header;
