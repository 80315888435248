import {
  Bank,
  Barcode,
  Calculator,
  ChartBar,
  ChartBarHorizontal,
  ChartPieSlice,
  ChatCircleText,
  ClipboardText,
  CurrencyDollarSimple,
  Database,
  Door,
  Graph,
  House,
  ListBullets,
  Megaphone,
  Package,
  Percent,
  Robot,
  Scales,
  SignOut,
  TShirt,
  Table,
  Truck,
} from 'phosphor-react';

import { EUserStoreRole } from '@domain/enums/common/userStore/EUserStoreRole';
import { IItem, IMenuRoute } from '@domain/interfaces/components/ISidebar';

export const getUserStoreRoutes = (
  permissions: Record<string, boolean>,
  role: EUserStoreRole,
): Array<IMenuRoute> => {
  const isStoreOwner = role === EUserStoreRole.OWNER;

  const userRoutes: Array<IMenuRoute> = [];
  // const dashboardRoutes: Array<Record<string, any>> = [];
  const financialDashboardRoutes: Array<Record<string, any>> = [];
  const productDashboardRoutes: Array<Record<string, any>> = [];
  const adsManagerRoutes: Array<Record<string, any>> = [];
  const automationsRoutes: Array<Record<string, any>> = [];
  // const marketingRoutes: Array<Record<string, any>> = [];
  const reportsRoutes: Array<Record<string, any>> = [];
  const customValuesRoutes: Array<Record<string, any>> = [];
  const dataRoutes: Array<Record<string, any>> = [];
  const homeRoutes: Array<Record<string, any>> = [];

  homeRoutes.push({
    name: 'Home',
    url: '/home',
    icon: House,
  });

  if (
    permissions?.has_financial_store_dashboard_full_write_access ||
    permissions?.has_financial_store_dashboard_full_read_access ||
    isStoreOwner
  ) {
    financialDashboardRoutes.push({
      name: 'Financeiro',
      url: '/financial',
      icon: ChartBarHorizontal,
    });
  }

  if (
    permissions?.has_financial_product_dashboard_full_write_access ||
    permissions?.has_financial_product_dashboard_full_read_access ||
    isStoreOwner
  ) {
    productDashboardRoutes.push({
      name: 'Performance',
      url: '/products-analytic',
      icon: ChartPieSlice,
    });
  }

  if (
    permissions?.has_ads_manager_full_write_access ||
    permissions?.has_ads_manager_full_read_access ||
    isStoreOwner
  ) {
    adsManagerRoutes.push({
      name: 'Campanhas',
      url: '/ads-manager',
      icon: Table,
    });
  }

  if (
    permissions?.has_workflow_full_write_access ||
    permissions?.has_workflow_full_read_access ||
    isStoreOwner
  ) {
    automationsRoutes.push({
      name: 'Automações de Mensagens',
      url: '/automations',
      icon: Robot,
    });
  }

  if (
    permissions?.has_workflow_full_write_access ||
    permissions?.has_workflow_full_read_access ||
    isStoreOwner
  ) {
    automationsRoutes.push({
      name: 'Enviadores de Mensagens',
      url: '/messages-instances',
      icon: ChatCircleText,
    });
  }

  if (
    permissions?.has_orders_table_full_write_access ||
    permissions?.has_orders_table_full_read_access ||
    isStoreOwner
  ) {
    reportsRoutes.push({
      name: 'Pedidos',
      url: '/orders',
      icon: ListBullets,
    });
  }

  if (
    permissions?.has_boleto_table_full_write_access ||
    permissions?.has_boleto_table_full_read_access ||
    isStoreOwner
  ) {
    reportsRoutes.push({
      name: 'Boletos',
      url: '/boletos',
      icon: Barcode,
    });
  }

  if (
    permissions?.has_financial_details_table_full_write_access ||
    permissions?.has_financial_details_table_full_read_access ||
    isStoreOwner
  ) {
    reportsRoutes.push({
      name: 'Detalhes Financeiros',
      url: '/financial/details',
      icon: Bank,
    });
  }

  if (
    permissions?.has_custom_value_full_write_access ||
    permissions?.has_custom_value_full_read_access ||
    isStoreOwner
  ) {
    customValuesRoutes.push(
      ...[
        {
          name: 'Saída',
          url: '/out',
          icon: Door,
        },
        {
          name: 'Entrada',
          url: '/in',
          icon: SignOut,
        },
      ],
    );
  }

  if (
    permissions?.has_integrations_full_write_access ||
    permissions?.has_integrations_full_read_access ||
    isStoreOwner
  ) {
    dataRoutes.push({
      name: 'Integrações',
      url: '/integrations',
      icon: Graph,
    });
  }

  if (
    permissions?.has_product_cost_full_write_access ||
    permissions?.has_product_cost_full_read_access ||
    isStoreOwner
  ) {
    dataRoutes.push({
      name: 'Custos dos Produtos',
      url: '/products-costs',
      icon: Package,
    });
  }

  if (
    permissions?.has_kits_full_write_access ||
    permissions?.has_kits_full_read_access ||
    isStoreOwner
  ) {
    dataRoutes.push({
      name: 'Custo de Kits',
      url: '/kits',
      icon: TShirt,
    });
  }

  if (
    permissions?.has_tax_full_write_access ||
    permissions?.has_tax_full_read_access ||
    isStoreOwner
  ) {
    dataRoutes.push({
      name: 'Impostos',
      url: '/tax',
      icon: Scales,
    });
  }

  if (
    permissions?.has_checkout_fee_full_write_access ||
    permissions?.has_checkout_fee_full_read_access ||
    isStoreOwner
  ) {
    dataRoutes.push({
      name: 'Taxas e Tarifas',
      url: '/checkout-fee',
      icon: Percent,
    });
  }

  if (
    permissions?.has_shipping_full_write_access ||
    permissions?.has_shipping_full_read_access ||
    isStoreOwner
  ) {
    dataRoutes.push({
      name: 'Frete',
      url: '/shipping',
      icon: Truck,
    });
  }

  dataRoutes.push({
    name: 'Calculadora de precificação',
    url: '/pricing-tool',
    icon: Calculator,
  });

  if (homeRoutes.length) {
    userRoutes.push({
      name: 'Home',
      url: '/home',
      icon: House,
      subItems: homeRoutes as Array<IItem>,
    });
  }

  if (financialDashboardRoutes.length) {
    userRoutes.push({
      name: 'Dashboards',
      url: '/overview',
      icon: ChartBar,
      subItems: financialDashboardRoutes as Array<IItem>,
    });
  }

  if (productDashboardRoutes.length) {
    userRoutes.push({
      name: 'Produto',
      url: '/products',
      icon: ChartPieSlice,
      subItems: productDashboardRoutes as Array<IItem>,
    });
  }

  if (adsManagerRoutes.length) {
    userRoutes.push({
      name: 'Gerenciador de Ads',
      url: '/ads',
      icon: Megaphone,
      subItems: adsManagerRoutes as Array<IItem>,
    });
  }

  if (automationsRoutes.length) {
    userRoutes.push({
      name: 'Automações',
      url: '/automations',
      icon: Robot,
      subItems: automationsRoutes as Array<IItem>,
    });
  }

  if (reportsRoutes.length) {
    userRoutes.push({
      name: 'Relatórios',
      url: '/reports',
      icon: ClipboardText,
      subItems: reportsRoutes as Array<IItem>,
    });
  }

  if (customValuesRoutes.length) {
    userRoutes.push({
      name: 'Valores Adicionais',
      url: '/custom-values',
      icon: CurrencyDollarSimple,
      subItems: customValuesRoutes as Array<IItem>,
    });
  }

  if (dataRoutes.length) {
    userRoutes.push({
      name: 'Dados',
      url: '/data',
      icon: Database,
      subItems: dataRoutes as Array<IItem>,
    });
  }

  return userRoutes;
};
