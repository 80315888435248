import React from 'react';

import { Taboola as TaboolaIcon } from '@profitfy/pakkun-icons';
import { EHeadingSize } from '@domain/enums/components/EHeading';
import { useTaboolaCredentials } from '@helpers/hooks/common/integrations/marketing/taboola/useTaboolaCredentials';

import TaboolaImg from '@assets/pages/Onboarding/dropshipping/marketing/taboola.svg';

import IntegrateForm from '../IntegrateForm';
import { PageSkeletonLoading } from '../SkeletonLoading';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const {
    adSenseCredentials,
    isLoadingTaboolaCredentialsData,
    isValidatingTaboolaCredentialsData,
    isLoggingIn,
    isLoggingOut,
  } = useTaboolaCredentials();

  const [isIntegrateFormShow, setIsIntegrateFormShow] = React.useState<boolean>(false);

  const onAddNewProfileButtonClick = React.useCallback(() => {
    setIsIntegrateFormShow(true);
  }, []);

  const isLoading =
    isLoadingTaboolaCredentialsData ||
    isValidatingTaboolaCredentialsData ||
    isLoggingIn ||
    isLoggingOut;

  if (isLoading) {
    return (
      <>
        <PageSkeletonLoading />
      </>
    );
  }

  return (
    <>
      {!isIntegrateFormShow && Boolean(!adSenseCredentials.length) && (
        <>
          <S.ContentWrapper>
            <S.Image src={TaboolaImg} alt="profitfy-goat-with-taboola-logo" />

            <S.Heading type={EHeadingSize.H2}>Conectar perfil do Taboola</S.Heading>

            <S.AddNewProfileButton onClick={onAddNewProfileButtonClick}>
              <TaboolaIcon size={22} />
              Adicionar perfil do Taboola
            </S.AddNewProfileButton>
          </S.ContentWrapper>
        </>
      )}

      {(isIntegrateFormShow || Boolean(adSenseCredentials.length)) && <IntegrateForm />}
    </>
  );
};

export default Wrapper;
