import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import RadioBase from '@components/common/core/Inputs/Radio';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Modal = styled(ModalBase)`
  background-color: ${({ theme }) => theme.colors.gray[6]};
  max-width: 542px;
  width: 100%;
  overflow-y: scroll;
  max-height: 80vh;

  ::-webkit-scrollbar {
    width: 4px;
    position: absolute;
  }

  ::-webkit-scrollbar:horizontal {
    height: 0;
    width: 4px;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    position: absolute;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[1]};
    border-radius: 25px;
    transition: all 0.3s;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    height: 1px;
    border-radius: 25px;
    transition: all 0.3s;
  }

  ::-webkit-scrollbar-thumb:hover {
    opacity: brightness(0.8);
  }
`;

export const Body = styled.div`
  padding: 8px;
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 12px;
`;

export const Description = styled(TextBase)`
  line-height: 120%;
  margin-bottom: 20px;
  font-size: 0.875rem;
  display: inline-block;
`;

export const RadioText = styled(TextBase)`
  font-size: 0.875rem;
  margin-top: 2px;
`;

export const Radio = styled(RadioBase)``;

export const RadioLabel = styled(LabelBase)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: unset;
  margin-bottom: 4px;
  cursor: pointer;
`;

export const RadioGroup = styled(InputGroupBase)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 36px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 135px;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  border-radius: 4px;
  padding: 10px;
  resize: none;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.gray[2]};
  font-size: 0.875rem;
  border-color: ${({ theme }) => theme.colors.gray[4]};

  &:focus {
    outline: none;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
`;

export const ButtonBack = styled(ButtonBase)`
  margin-left: 20px;
  color: ${({ theme }) => theme.colors.gray[2]};

  & > span {
    color: ${({ theme }) => theme.colors.gray[2]};
  }
`;

export const ButtonCancel = styled(ButtonBase)`
  margin-left: 20px;
  background-color: ${({ theme }) => theme.colors.danger.dark};
  box-shadow: unset;

  &:hover {
    background-color: ${({ theme }) => theme.colors.danger.dark};
    box-shadow: unset;
  }
`;
