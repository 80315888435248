import styled from 'styled-components/macro';

import { Cancel } from '@profitfy/pakkun-icons';

import Text from '@components/common/core/DataDisplay/Text';

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const ExitIconWrapper = styled.div`
  position: absolute;
  right: 0;

  &:hover {
    cursor: pointer;
  }
`;

export const ExitIcon = styled(Cancel)``;

export const Title = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.body};
  margin-right: 20px;
  display: flex;
  align-items: center;
`;

export const Icon = styled(Cancel)``;
