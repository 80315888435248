import React from 'react';

import { Hotmart as HotmartIcon } from '@profitfy/pakkun-icons';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { IHotmartConfigProps } from '@domain/interfaces/dashboard/IntegrationCenter/InfoProduct/IHotmart';

import CredentialOption from './CredentialOption';
import SyncOption from './SyncOption';

import * as S from './styles';

const HotmartConfig: React.FC<IHotmartConfigProps> = ({ credentialAliasId }) => {
  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const onChangeTab = React.useCallback(index => setSelectedTab(index), []);

  return (
    <S.ContentWrapper>
      <S.Header>
        <HotmartIcon size={52} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.REGULAR}>
          Integração com Hotmart
        </S.Heading>
      </S.Header>

      <S.Tab defaultTab={selectedTab} onChangeTab={onChangeTab}>
        <S.Option>Geral</S.Option>
        <S.Option>Sincronização</S.Option>
      </S.Tab>

      {selectedTab === 0 && <CredentialOption credentialAliasId={credentialAliasId} />}
      {selectedTab === 1 && <SyncOption />}
    </S.ContentWrapper>
  );
};

export default HotmartConfig;
