import React from 'react';

import { ENoImageSize } from '@domain/enums/components/ENoImage';
import { IParams } from '@domain/interfaces/IParams';
import { useProductCostDetails } from '@helpers/hooks/pages/dashboard/productCostDetails/productCostDetails';
import { useToast } from '@helpers/hooks/useToast';
import { currencyFormatter, currencyToNumber } from '@helpers/masks';
import productCostDetailsService from '@services/pages/dashboard/productCostDetails/productCostDetails';
import { useParams } from 'react-router-dom';
import { CircleSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Details: React.FC = () => {
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const theme = useTheme();

  const {
    isLoadingProduct,
    product,
    handleCostToApplyAll,
    isValidatingProduct,
    handleCurrencyToApplyAll,
  } = useProductCostDetails();

  const [applyToAllValue, setApplyToAllValue] = React.useState<string>('');
  const [currency, setCurrency] = React.useState<string>('BRL');
  const [isImageError, setIsImageError] = React.useState<boolean>(false);
  const [isInputChecked, setIsInputChecked] = React.useState<boolean>(() => {
    return product?.is_using_remessa_conforme;
  });
  const [isUpdatingProduct, setIsUpdatingProduct] = React.useState<boolean>(false);

  const onCheckChange = React.useCallback(
    async event => {
      if (event && !event.target) {
        setIsUpdatingProduct(true);

        try {
          await productCostDetailsService.updateProduct({
            storeAliasId,
            productAliasId: product?.alias_id,
            data: {
              is_favorite: product?.is_favorite,
              is_onboarding_finished: product?.is_onboarding_finished,
              is_using_remessa_conforme: event.checked,
            },
          });

          const toastMessage = event.checked
            ? 'Remessa conforme habilitada'
            : 'Remessa conforme desabilitada';

          toast.success(toastMessage);

          setIsUpdatingProduct(false);
        } catch (error: any) {
          setIsUpdatingProduct(false);
          setIsInputChecked(false);
          toast.error(error?.response?.data?.message);
        }
      }

      setIsInputChecked(event.checked);
    },
    [product, storeAliasId, toast],
  );

  const onSelectCurrencyChange = React.useCallback(event => {
    setCurrency(event.target.value);
  }, []);

  const onApplyToAllValueChange = React.useCallback(event => {
    event.target.value = currencyFormatter(event.target.value);

    setApplyToAllValue(event.target.value);
  }, []);

  const handleOnApplyToAllClick = React.useCallback(() => {
    const parsedValue = currencyToNumber(applyToAllValue);

    handleCostToApplyAll(parsedValue);
  }, [applyToAllValue, handleCostToApplyAll]);

  const handleOnApplyCurrencyToAllClick = React.useCallback(() => {
    handleCurrencyToApplyAll(currency);
  }, [currency, handleCurrencyToApplyAll]);

  React.useEffect(() => {
    if (product) {
      setIsInputChecked(product.is_using_remessa_conforme);
    }
  }, [product]);

  if (isLoadingProduct || isValidatingProduct) {
    return <SkeletonLoading />;
  }

  return (
    <S.DetailsWrapper>
      {product?.image_url && !isImageError ? (
        <S.Image
          src={product?.image_url}
          alt="Profitfy.me Custo dos Produtos"
          onError={() => setIsImageError(true)}
        />
      ) : (
        <S.NoImage size={ENoImageSize.LARGE} />
      )}

      <S.InputsAndShowDeletedVariantsWrapper>
        <S.InputsWrapper>
          {/* <S.IOFInputGroup>
            <S.Label>IOF</S.Label>
            <S.IOFSelect name="iof">
              <S.Option value="CARD">Cartão (6,38%)</S.Option>
              <S.Option value="BOLETO">Boleto (0,38%)</S.Option>
              <S.Option value="NONE">Nenhum</S.Option>
            </S.IOFSelect>
          </S.IOFInputGroup> */}

          <S.ApplyToAllInputGroup>
            <S.Label>Aplicar um custo a todas as variantes</S.Label>

            <S.ApplyToAllInput
              button="Aplicar a todas"
              type="text"
              onChange={onApplyToAllValueChange}
              onClick={handleOnApplyToAllClick}
            />
          </S.ApplyToAllInputGroup>

          <S.ApplyToAllInputGroup>
            <S.Label>Aplicar moeda a todas as variantes</S.Label>

            <S.ApplyToAllCurrencyWrapper>
              <S.ApplyToAllSelect
                name="apply-to-all-currency"
                defaultValue={currency}
                onChange={onSelectCurrencyChange}
              >
                <S.Option value="BRL">BRL</S.Option>
                <S.Option value="USD">USD</S.Option>
              </S.ApplyToAllSelect>
              <S.ApplyToAllButton onClick={handleOnApplyCurrencyToAllClick}>
                Aplicar a todas
              </S.ApplyToAllButton>
            </S.ApplyToAllCurrencyWrapper>
          </S.ApplyToAllInputGroup>
        </S.InputsWrapper>

        <S.CheckboxGroup>
          {isUpdatingProduct ? (
            <CircleSpinner size={12} color={theme.colors.green.default} />
          ) : (
            <S.Checkbox id="input-checkbox" checked={isInputChecked} onChange={onCheckChange} />
          )}
          <S.CheckboxLabel htmlFor="input-checkbox">Remessa conforme</S.CheckboxLabel>
        </S.CheckboxGroup>

        {/* <S.ShowDeletedVariantsWrapper>
          <S.IconWrapper>
            <AddPerson
              size={14}
              color={userTheme === 'dark' ? theme.colors.gray[1] : theme.colors.gray[3]}
            />
          </S.IconWrapper>
          <S.Checkbox />
          Exibir variantes excluídas
        </S.ShowDeletedVariantsWrapper> */}
      </S.InputsAndShowDeletedVariantsWrapper>
    </S.DetailsWrapper>
  );
};

export default Details;
