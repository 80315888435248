import React from 'react';
import { SignOut, TrashSimple } from 'phosphor-react';
import { useTheme } from 'styled-components';
import { NodeProps, Position } from 'react-flow-renderer';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';

import * as S from './styles';

const AutomationEndNode: React.FC<NodeProps> = ({ id }) => {
  const theme = useTheme();
  const { connectionInProgress, edges, nodes, setEdges, setNodes } = useAutomation();

  const [isHoveringNode, setIsHoveringNode] = React.useState<boolean>(false);

  const onMouseEnter = React.useCallback(() => setIsHoveringNode(true), []);

  const onMouseLeave = React.useCallback(() => setIsHoveringNode(false), []);

  const onDelete = React.useCallback(
    event => {
      event.stopPropagation();

      const newEdges = edges.filter(edge => edge.source !== id && edge.target !== id);
      const newNodes = nodes.filter(node => node.id !== id);

      setEdges(newEdges);
      setNodes(newNodes);
    },
    [edges, id, nodes, setEdges, setNodes],
  );
  return (
    <S.Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <S.Handle
        position={Position.Top}
        type="target"
        style={{ zIndex: connectionInProgress ? 999999 : -1 }}
      />

      <SignOut size={20} color={theme.colors.white.default} />

      {isHoveringNode && (
        <S.OptionsWrapper>
          <S.Option onClick={onDelete}>
            <TrashSimple size={16} color={theme.colors.font} weight="fill" />
          </S.Option>
        </S.OptionsWrapper>
      )}

      <S.Name>Encerrar automação</S.Name>
    </S.Wrapper>
  );
};

export default AutomationEndNode;
