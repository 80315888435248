import React from 'react';
import { Facebook } from '@profitfy/pakkun-icons';
import { ListChildComponentProps } from 'react-window';

import { ICampaignOptionProps } from '@domain/interfaces/dashboard/AdsManager/UtmSidemodal/ICampaignOption';

import * as S from './styles';

const CampaignOption: React.FC<ListChildComponentProps & ICampaignOptionProps> = ({
  adSenseAds,
  selectedAdSenseAds,
  handleSelectedAdSenseAds,
  index,
  style,
}) => {
  const campaign = adSenseAds[index];

  const onCheckChange = React.useCallback(
    event => {
      if (!event || event.target) return;

      if (event.checked) {
        handleSelectedAdSenseAds([...selectedAdSenseAds, campaign]);
      } else {
        const filteredAdSenseAds = selectedAdSenseAds.filter(
          adSenseAd => adSenseAd.alias_id !== campaign.alias_id,
        );

        handleSelectedAdSenseAds(filteredAdSenseAds);
      }
    },
    [handleSelectedAdSenseAds, campaign, selectedAdSenseAds],
  );

  const foundSelectedAdSenseCampaign = selectedAdSenseAds.find(
    selectedCampaign => selectedCampaign.alias_id === campaign.alias_id,
  );

  const isChecked = Boolean(foundSelectedAdSenseCampaign);

  return (
    <S.Wrapper style={style}>
      <S.CheckboxGroup>
        <S.CheckboxLabel htmlFor={`campaign-${campaign.alias_id}`}>
          <Facebook size={18} />
          <S.OptionText>{campaign.name}</S.OptionText>
        </S.CheckboxLabel>
        <S.Checkbox
          checked={isChecked}
          onChange={onCheckChange}
          id={`campaign-${campaign.alias_id}`}
        />
      </S.CheckboxGroup>
    </S.Wrapper>
  );
};

export default CampaignOption;
