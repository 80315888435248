import { IWorkflowToCanvas } from '@domain/interfaces/common/automations/IWorkflows';
import IReactFlowWorkflowNode from '@domain/interfaces/mappers/automations/reactFlow/IWorkflowNode';

import nodeTypeMapper from '@helpers/utils/mappers/automations/profitfyBackend/nodeTypeMapper';

const map = ({ workflow_nodes }: IWorkflowToCanvas): IReactFlowWorkflowNode[] => {
  const mappedNodes = workflow_nodes.map(node => {
    const { provider_id, position_x, position_y, metadata, type, height, width, metrics } = node;

    const mappedType = nodeTypeMapper.map(type);

    const mappedNode: IReactFlowWorkflowNode = {
      id: provider_id,
      position: {
        x: position_x,
        y: position_y,
      },
      data: { ...metadata.data, metrics },
      type: mappedType,
      height,
      width,
    };

    return mappedNode;
  });

  return mappedNodes;
};

export default { map };
