import React from 'react';
import { ResponsiveContainer, PieChart as Chart, Pie, Cell, Tooltip } from 'recharts';

import { IPieChartProps } from '@domain/interfaces/components/IPieChart';

import ChartTooltip from './ChartTooltip';
import * as S from './styles';

const PieChart: React.FC<IPieChartProps> = ({ data, colors }) => {
  return (
    <S.ChartWrapper>
      <ResponsiveContainer width="100%">
        <Chart>
          <Tooltip content={<ChartTooltip />} />
          <Pie
            data={data}
            innerRadius="90%"
            outerRadius="100%"
            fill="#000"
            paddingAngle={0}
            dataKey="value"
            strokeOpacity={0}
          >
            {data.map((pie, index) => (
              <Cell key={`cell-${pie.name}`} fill={colors[index]} />
            ))}
          </Pie>
        </Chart>
      </ResponsiveContainer>
    </S.ChartWrapper>
  );
};

export default React.memo(PieChart);
