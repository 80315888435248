import styled from 'styled-components/macro';

import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';

export const CancelButton = styled.button`
  width: 14px;
  height: 14px;
  padding: unset;
  border: unset;
  background-color: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const SaveButton = styled.button`
  width: 14px;
  height: 14px;
  padding: unset;
  border: unset;
  background-color: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const Input = styled(TextFieldBase)`
  min-width: 288px;

  & > div {
    background-color: unset;
    padding-left: unset;

    & > input {
      padding: 0;
    }
  }
`;

export const InputGroup = styled(InputGroupBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: unset;
`;

export const Wrapper = styled.div`
  width: 100%;
`;
