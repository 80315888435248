import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { SUGGESTION_API_DOMAIN } from '@constants/api';
import {
  IGetMobileNotificationProps,
  IGetMobileNotification,
  IUpdateMobileNotificationProps,
  ICreateMobileNotificationProps,
} from '@domain/interfaces/dashboard/Profile/IMobileNotification';

export class MobileNotificationService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getMobileNotifications({
    storeAliasId,
  }: IGetMobileNotificationProps): IGetMobileNotification {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/notifications`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      mobileNotification: data?.mobile_notification,
      mutate,
      error,
      isLoading,
      isValidating,
    };
  }

  public updateMobileNotification({
    storeAliasId,
    data,
  }: IUpdateMobileNotificationProps): Promise<any> {
    return this.apiService.put(`/api/v1/users/stores/${storeAliasId}/mobile-notifications`, {
      ...data,
    });
  }

  public async createMobileNotification({
    storeAliasId,
    data,
  }: ICreateMobileNotificationProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/mobile-notifications`, {
      ...data,
    });
  }
}

const mobileNotificationService = new MobileNotificationService(SUGGESTION_API_DOMAIN);

export default mobileNotificationService;
