import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import LabelBase from '@components/common/core/DataDisplay/Label';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import NoImageBase from '@components/common/core/DataDisplay/NoImage';
import ButtonBase from '@components/common/core/Inputs/Button';
import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 16px;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;

export const NoImage = styled(NoImageBase)`
  width: 100%;
  max-width: 48px;
  background-color: ${({ theme }) => theme.colors.background[4]};
`;

export const Image = styled(ImageBase)`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

export const ProductName = styled(HeadingBase)``;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputGroup = styled(InputGroupBase)`
  width: 100%;
  max-width: 508px;
  margin-bottom: 24px;
`;

export const Label = styled(LabelBase)`
  font-weight: 500;
`;
export const Input = styled(TextFieldBase)`
  width: 100%;
  height: 40px;

  button {
    font-weight: 700;
    color: #000;
  }
`;

export const ApplyToAllCurrencyWrapper = styled.div`
  display: flex;
`;

export const Select = styled(SelectBase)`
  width: 100%;

  & > label > select {
    border-radius: 5px 0 0 5px;
  }
`;

export const Option = styled(OptionBase)``;

export const ApplyToAllButton = styled(ButtonBase)`
  min-width: 156px;
  border-radius: 0px 5px 5px 0;
  box-shadow: unset;

  span {
    font-weight: 700;
  }
`;
