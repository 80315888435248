import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import SwitchBase from '@components/common/core/Inputs/Switch';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';
import AlertBase from '@components/common/core/DataDisplay/Alert';

export const Alert = styled(AlertBase)`
  margin-bottom: 24px;
`;

export const Input = styled(TextFieldBase)``;

export const UpdateCredentialButton = styled(ButtonBase)`
  margin-bottom: 64px;
`;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 36px;
`;

export const SwitchLabel = styled(LabelBase)`
  margin: unset;
`;

export const SwitchGroup = styled(InputGroupBase)`
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  & > div:first-child {
    width: unset;
  }
`;

export const Switch = styled(SwitchBase)``;

export const DisableCredentialButton = styled(ButtonBase)``;

export const CredentialDescriptionText = styled(TextBase)``;

export const CredentialDescriptionTitle = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const CredentialDescriptionWrapper = styled.div`
  margin-bottom: 24px;
`;

export const Content = styled.div``;

export const TooltipText = styled(TextBase)``;

export const TooltipTextWrapper = styled.div`
  max-width: 328px;
  width: 100%;
`;

export const Tooltip = styled(CustomTooltipBase)``;

export const SyncProductsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
