import styled from 'styled-components/macro';

import ButtonBase from '@components/common/core/Inputs/Button';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 1386px;

  @media only screen and (max-width: 1548px) {
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;
  margin-bottom: 64px;
  width: 100%;
`;

export const Button = styled(ButtonBase)`
  width: auto;
`;
