import ReactFlow from 'react-flow-renderer';
import styled from 'styled-components';

import ButtonBase from '@components/common/core/Inputs/Button';

export const StyledReactFlow = styled(ReactFlow)`
  background-color: ${({ theme }) => theme.colors.gray[6]};
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const TestButton = styled(ButtonBase)`
  margin-right: 16px;
`;

export const SaveButton = styled(ButtonBase)``;

export const ButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 32px;
  right: 32px;
  z-index: 9;
`;

export const LoadingScreen = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndex.fifthLayer};
`;

export const Container = styled.div`
  max-width: 1262px;
  width: 100%;
`;
