import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const ConfirmButton = styled(ButtonBase)``;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;
  width: fit-content;
  align-items: center;
  margin-left: auto;
`;

export const Text = styled(TextBase)`
  line-height: 120%;
  display: block;
  margin-bottom: 24px;
`;

export const Modal = styled(ModalBase)`
  & > div:first-child {
    span {
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 1.125rem;
      line-height: 120%;
      font-weight: 500;
    }
  }
`;
