import React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';

import * as S from './styles';

const Close: React.FC<
  PopoverPrimitive.PopoverCloseProps & React.RefAttributes<HTMLButtonElement>
> = ({ children, ...rest }) => {
  return <S.PopoverClose {...rest}>{children}</S.PopoverClose>;
};

export default Close;
