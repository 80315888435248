import React from 'react';

import { useLocation, useParams, useHistory } from 'react-router-dom';
import { ISidebar, IItem, IMenuRoute } from '@domain/interfaces/components/ISidebar';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { IParams } from '@domain/interfaces/IParams';

import NavItem from './NavItem';
import SubItem from './SubItem';
import MoreOptions from './MoreOptions';

import * as S from './styles';

const Sidebar: React.FC<ISidebar> = ({ menuRoutes, logo }) => {
  const { pathname } = useLocation();
  const { alertsComponentHeight } = useStoreSubscription();
  const { storeAliasId } = useParams<IParams>();

  const history = useHistory();

  const [isSidebarExpanded, setIsSidebarExpanded] = React.useState<boolean>(false);
  const [isSecondaryMenuEnabled, setIsSecondaryMenuEnabled] = React.useState<boolean>(true);
  const [selectedMainItem, setSelectedMainItem] = React.useState<IMenuRoute | undefined>();
  const [secondaryItems, setSecondaryItems] = React.useState<IItem[] | undefined>();

  const handleOnMouseEnterSidebar = React.useCallback(() => setIsSidebarExpanded(true), []);

  const handleOnMouseLeaveSidebar = React.useCallback(() => setIsSidebarExpanded(false), []);

  const handleSecondaryMenuEnabled = React.useCallback(
    () => setIsSecondaryMenuEnabled(!isSecondaryMenuEnabled),
    [isSecondaryMenuEnabled],
  );

  const handleNavItemClick = React.useCallback(
    selectedItem => setSelectedMainItem(selectedItem),
    [],
  );

  const isNavItemSelected = React.useCallback(
    menuRoute => {
      if (selectedMainItem?.url === menuRoute.url) return true;

      return false;
    },
    [selectedMainItem],
  );

  const handleLogoClick = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/overview/financial`);
  }, [history, storeAliasId]);

  React.useEffect(() => {
    let selectedMenuRoute = menuRoutes[0] || undefined;

    menuRoutes.map(menuRoute => {
      if (pathname.includes(menuRoute.url)) {
        selectedMenuRoute = menuRoute;
      }

      return null;
    });

    setSelectedMainItem(selectedMenuRoute);
    setSecondaryItems(selectedMenuRoute?.subItems);
  }, [menuRoutes, pathname]);

  React.useEffect(() => {
    if (selectedMainItem) {
      const selectedItem = menuRoutes.filter(menuRoute => menuRoute.url === selectedMainItem.url);
      setSecondaryItems(selectedItem[0].subItems);
    }
  }, [menuRoutes, selectedMainItem]);

  return (
    <S.Container
      top={alertsComponentHeight}
      onMouseEnter={handleOnMouseEnterSidebar}
      onMouseLeave={handleOnMouseLeaveSidebar}
    >
      <S.MainMenu isSidebarExpanded={isSidebarExpanded}>
        <S.MainMenuItemsWrapper isSidebarExpanded={isSidebarExpanded}>
          <S.LogoProfitfy src={logo} alt="Logo" onClick={handleLogoClick} />

          {menuRoutes?.map(menuRoute => (
            <NavItem
              onClick={handleNavItemClick}
              menuRoute={menuRoute}
              isSidebarExpanded={isSidebarExpanded}
              selected={isNavItemSelected(menuRoute)}
              key={menuRoute.url}
            />
          ))}
        </S.MainMenuItemsWrapper>

        <MoreOptions
          isSecondaryMenuEnabled={isSecondaryMenuEnabled}
          isSidebarExpanded={isSidebarExpanded}
          handleSecondaryMenuEnabled={handleSecondaryMenuEnabled}
        />
      </S.MainMenu>

      <S.SecondaryMenu isSidebarExpanded={isSidebarExpanded && isSecondaryMenuEnabled}>
        <S.SecondaryTitle type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          {selectedMainItem?.name}
        </S.SecondaryTitle>

        {secondaryItems?.map(secondaryItem => (
          <SubItem
            name={secondaryItem.name}
            icon={secondaryItem.icon}
            url={secondaryItem.url}
            key={secondaryItem.url}
            isSidebarExpanded={isSidebarExpanded && isSecondaryMenuEnabled}
            selectedMainItem={selectedMainItem}
          />
        ))}
      </S.SecondaryMenu>
    </S.Container>
  );
};

export default React.memo(Sidebar);
