import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const ErrorWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 32px;
  box-sizing: border-box;

  & > div {
    margin: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.gray[7]};
  display: flex;
  align-items: flex-start;
`;

export const HeaderAndTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 18px;
  margin: 0 auto;
  padding-top: 108px;
  padding-bottom: 120px;
  width: 100%;
  min-height: 100vh;
  max-width: 1016px;
  box-sizing: border-box;

  @media only screen and (max-width: 1380px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 456px;
  margin: 0 auto;
  padding-top: 133px;

  @media only screen and (max-width: 420px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media only screen and (max-height: 690px) {
    padding-top: 73px;
    padding-bottom: 90px;
  }
`;

export const Image = styled(ImageBase)`
  width: 100%;
  max-width: 351px;
  margin-bottom: 42px;
`;

export const Heading = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.white.default};
  margin-bottom: 40px;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  max-width: 379px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const HorizontalRuleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HorizontalRule = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.gray[4]};
`;

export const HorizontalRuleText = styled(TextBase)`
  margin: 0 4px;
`;

export const SkipStepButton = styled(ButtonBase)`
  width: 100%;
`;

export const AddNewProfileButton = styled.button`
  width: 100%;
  height: 42px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 5px;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-weight: 400;
  font-size: 1rem;
  border: 0;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[1]};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;
