import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import ButtonBase from '@components/common/core/Inputs/Button';
import SelectBase from '@components/common/core/Inputs/Select';
import FormBase from '@components/common/core/Inputs/Form';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Header = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Button = styled.button`
  padding: unset;
  border: unset;
  background: unset;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.green.default};
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Option = styled(OptionBase)``;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 480px;
  margin-top: 42px;
`;

export const Heading = styled(HeadingBase)``;

export const Form = styled(FormBase)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 12px;
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Label = styled(LabelBase)``;

export const PlansSelect = styled(SelectBase)`
  width: 100%;
`;

export const Input = styled(TextFieldBase)``;

export const CheckoutLinkWrapper = styled.button`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.black.light};
  border-radius: 7px;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: unset;
  cursor: pointer;
`;

export const CheckoutLink = styled(TextBase)`
  max-width: 434px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.5;
`;

export const IconWrapper = styled.div`
  min-height: 20px;
  min-width: 20px;
  margin-left: auto;
`;

export const GeneratorButton = styled(ButtonBase)`
  margin-left: auto;
`;
