import styled from 'styled-components/macro';

export const BottomNavigationWrapper = styled.div`
  padding: 8px 0;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 14.88px 0.992px rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.colors.background[8]};
  border-radius: 20px 20px 0 0;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.secondLayer};
  width: 100%;
  justify-content: space-around;
`;
