export enum ESynchronizationType {
  STORE_PRODUCT = 'PRODUCT',
  STORE_ORDER = 'ORDER',
  STORE_GATEWAY = 'STORE_GATEWAY',
  DEMO_STORE = 'DEMO_STORE',
  AD_SENSE_CAMPAIGNS = 'AD_SENSE_CAMPAIGNS',
  AD_SENSE_ACCOUNTS = 'AD_SENSE_ACCOUNTS',
  UPDATE_AD_SENSE_ACCOUNTS = 'UPDATE_AD_SENSE_ACCOUNTS',
  UPDATE_AD_SENSE_CAMPAIGNS = 'UPDATE_AD_SENSE_CAMPAIGNS',
  DAILY_MARKETING = 'DAILY_MAKRETING',
  ADS_MANAGER_DATA = 'ADS_MANAGER_DATA',
  ADS_MANAGER_CAMPAIGNS_STATUS = 'ADS_MANAGER_CAMPAIGNS_STATUS',
  ADS_MANAGER_DUPLICATE_CAMPAIGNS = 'ADS_MANAGER_DUPLICATE_CAMPAIGNS',
  ADS_WITHOUT_UTMS = 'ADS_WITHOUT_UTMS',
}

export enum ESynchronizationStatus {
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  PROCESSING = 'PROCESSING',
  IN_QUEUE = 'IN_QUEUE',
}

export enum ESynchronizationRequestType {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum EType {
  PRODUCT = 'PRODUCT',
  ORDER = 'ORDER',
  GATEWAY = 'GATEWAY',
  // MARKETING = 'MARKETING',
  MARKETING_CAMPAIGN = 'MARKETING_CAMPAIGN',
  MARKETING_ACCOUNT = 'MARKETING_ACCOUNT',
  MARKETING_CAMPAIGN_INSIGHT = 'MARKETING_CAMPAIGN_INSIGHT',
  MARKETING_ACCOUNT_INSIGHT = 'MARKETING_ACCOUNT_INSIGHT',
  DEMO_STORE = 'DEMO_STORE',
}

export enum EStatus {
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  PROCESSING = 'PROCESSING',
}

export enum EName {
  SHOPIFY = 'SHOPIFY',
  NUVEM_SHOP = 'NUVEM_SHOP',
  CART_PANDA = 'CART_PANDA',
  DSERS = 'DSERS',
  BLING = 'BLING',
  MERCADO_LIVRE = 'MERCADO_LIVRE',
  YAMPI = 'YAMPI',
  AVERAGE_COST = 'AVERAGE_COST',
  STORE = 'STORE',
  FACEBOOK = 'FACEBOOK',
  COGS = 'COGS',
  CHECKOUT_FEE = 'CHECKOUT_FEE',
  TAX = 'TAX',
  GOOGLE_ADS = 'GOOGLE_ADS',
  TABOOLA = 'TABOOLA',
  DEMO = 'DEMO',
}
