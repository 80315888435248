import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import LinkBase from '@components/common/core/Navigation/Link';
import TextFieldBase from '@components/common/core/Inputs/TextField';

export const SearchInput = styled(TextFieldBase)`
  width: 100%;
  margin-bottom: 16px;
`;

export const PaginateAndExitWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
`;

export const ExitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const Link = styled(LinkBase)``;

export const LogoutButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: ${({ theme }) => theme.zIndex.secondLayer};
`;

export const LogoutText = styled(TextBase)`
  align-self: flex-end;
  text-decoration: none;
  transition: all 0.3s;
  display: block;
  width: fit-content;
  color: ${({ theme }) => theme.colors.green.dark};
  font-family: ${({ theme }) => theme.fonts.body};

  &:hover {
    filter: brightness(0.8);
  }
`;

export const Text = styled(TextBase)``;

export const TextAndProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 16px;
`;

export const Heading = styled(HeadingBase)`
  width: 100%;
  display: inline-block;
  margin-bottom: 8px;
`;

export const Image = styled(ImageBase)`
  max-width: 261px;
  margin-bottom: 84px;
`;

export const ContentWrapper = styled.div`
  max-width: 404px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding-top: 60px;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background[1]};
  min-height: 100vh;
  position: relative;
  padding: 0 32px 64px 32px;
`;
