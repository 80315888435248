import React from 'react';
import { Pen } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { IGroupRowProps } from '@domain/interfaces/dashboard/AccessManagement/IGroupRow';

import { useAccessManager } from '@helpers/hooks/pages/dashboard/accessManagement/useAccessManager';

import * as S from './styles';

const GroupRow: React.FC<IGroupRowProps> = ({ group }) => {
  const {
    selectedGroupsId,
    handleSelectedGroupsId,
    handleSelectedGroupToEdit,
  } = useAccessManager();
  const theme = useTheme();

  const foundSelectedGroup = selectedGroupsId.find(selectedGroupId => selectedGroupId === group.id);

  const [isHovering, setIsHovering] = React.useState<boolean>(false);

  const onMouseEnter = React.useCallback(() => setIsHovering(true), []);

  const onMouseLeave = React.useCallback(() => setIsHovering(false), []);

  const onCheckboxChange = React.useCallback(
    event => {
      if (!event || event.target) return;

      if (foundSelectedGroup) {
        const filteredSelectedGroup = selectedGroupsId.filter(
          selectedGroupId => selectedGroupId !== group.id,
        );

        handleSelectedGroupsId(filteredSelectedGroup);
      } else {
        handleSelectedGroupsId([...selectedGroupsId, group.id]);
      }
    },
    [foundSelectedGroup, group.id, handleSelectedGroupsId, selectedGroupsId],
  );

  const onEditButtonClick = React.useCallback(() => {
    handleSelectedGroupToEdit(group);
  }, [handleSelectedGroupToEdit, group]);

  return (
    <S.Table.Row borderBottom onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <S.Table.Data>
        <S.CheckboxWrapper>
          <S.Checkbox checked={Boolean(foundSelectedGroup)} onChange={onCheckboxChange} />
        </S.CheckboxWrapper>
      </S.Table.Data>

      <S.Table.Data>
        <S.TableDataContent>
          <S.GroupName weight={ETextWeight.MEDIUM} size={ETextSize.B5}>
            {group.name}
          </S.GroupName>

          {isHovering && (
            <S.EditButton onClick={onEditButtonClick}>
              <Pen outline size={12} color={theme.colors.font} />
            </S.EditButton>
          )}
        </S.TableDataContent>
      </S.Table.Data>
    </S.Table.Row>
  );
};

export default GroupRow;
