import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';

export const NewCustomSpendButton = styled(ButtonBase)``;

export const CategoryIcon = styled.span`
  background-color: ${({ theme }) => theme.colors.gray[4]};
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CategoriesButton = styled.button`
  background-color: unset;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.font};
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;

  @media only screen and (max-width: 350px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
`;

export const Title = styled(HeadingBase)``;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  @media only screen and (max-width: 585px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
`;
