import React from 'react';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import {
  ICampaignsProvider,
  IMappedAdSenseCampaignData,
  IMappedAdSenseCampaignFooter,
} from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IAdSenseCampaigns';
import { ECampaignStatus } from '@domain/enums/dashboard/adsManager/ECampaigns';
import { IUpdateAdSenseCampaignData } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IAdSenseCampaignsActions';

import adSenseCampaignsService from '@services/pages/dashboard/adsManager/adSenseCampaigns';

import {
  mapCampaignsData,
  mapCampaignsToDuplicate,
  mapFooterCampaignsData,
  sortMappedCampaignsData,
} from '@helpers/utils/dashboard/adsManager/campaigns';
import { getResizerNewPosition } from '@helpers/utils/dashboard/adsManager/adsManagerTable';

import { useDate } from '@helpers/hooks/useDate';
import { useToast } from '@helpers/hooks/useToast';
import { useAdsManager } from '@helpers/hooks/pages/dashboard/adsManager/useAdsManager';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useConfig } from '@helpers/hooks/useConfig';

import adSenseCampaignsActions from '@services/pages/dashboard/adsManager/adSenseCampaignsActions';
import {
  ESynchronizationRequestType,
  ESynchronizationType,
} from '@domain/enums/hooks/ESynchronization';
import { LONG_API_DOMAIN } from '@constants/api';
import { getEnvironment } from '@helpers/utils/environment';
import { generateDataMock } from '@helpers/utils/adsManagerDataMockGenerator';
import { parseDataFilter } from '@helpers/utils/components/filter';

const CampaignsContext = React.createContext<ICampaignsProvider | null>(null);

export const CampaignsProvider: React.FC = ({ children }) => {
  const shouldRandomizeData = localStorage.getItem('random-ads-manager');

  const {
    period,
    tableColumns,
    setColumnsSize,
    searchFilter,
    campaignsStatusFilter,
    hasSelectedAll,
    handleLastSyncDate,
    filterData,
  } = useAdsManager();
  const { utcToZonedTime, format } = useDate();
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const {
    finishedSynchronizations,
    handleFinishedSynchronizations,
    addSynchronization,
  } = useSynchronization();
  const { analytics } = useConfig();

  const [activeTableIndex, setActiveTableIndex] = React.useState<number | null>(null);
  const [mappedAdSenseCampaigns, setMappedAdSenseCampaigns] = React.useState<
    Array<IMappedAdSenseCampaignData>
  >([]);
  const [mappedAdSenseCampaignsFooter, setMappedAdSenseCampaignsFooter] = React.useState<
    Array<IMappedAdSenseCampaignFooter>
  >([]);
  const [campaignsTableSort, setCampaignsTableSort] = React.useState<any | undefined>({
    id: 'spend_amount',
    sort: 'ASC',
  });
  const [selectedCampaigns, setSelectedCampaigns] = React.useState<Array<string>>([]);
  const [selectedCampaignToAction, setSelectedCampaignToAction] = React.useState<
    string | undefined
  >(undefined);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = React.useState<boolean>(false);
  const [isActivateCampaignsModalOpen, setIsActivateCampaignsModalOpen] = React.useState<boolean>(
    false,
  );
  const [
    isDeactivateCampaignsModalOpen,
    setIsDeactivateCampaignsModalOpen,
  ] = React.useState<boolean>(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = React.useState<string | undefined>(undefined);
  const [isEditBudgetModalOpen, setIsEditBudgetModalOpen] = React.useState<string | undefined>(
    undefined,
  );

  const tableElementRef = React.useRef<HTMLTableElement | null>(null);
  const tableParentElementRef = React.useRef<any>(null);

  const {
    adSenseCampaignsReport,
    lastSync: adSenseCampaignsLastSync,
    summaryReport: adSenseCampaignsSummaryReport,
    isLoading: isLoadingAdSenseCampaigns,
    isValidating: isValidatingAdSenseCampaigns,
    mutate: mutateAdSenseCampaigns,
    error: adSenseCampaignsError,
  } = adSenseCampaignsService.listAdSenseCampaignsReport({
    storeAliasId,
    startDate: format(utcToZonedTime(period.startDate), 'yyyy-MM-dd'),
    endDate: format(utcToZonedTime(period.endDate), 'yyyy-MM-dd'),
    filter: {
      order_field: 'provider_created_at',
      name: searchFilter,
      statuses: campaignsStatusFilter,
      ad_sense_account_id: '',
      ad_sense_credential_id: '',
      ...parseDataFilter(filterData),
    },
  });

  const mutate = React.useCallback(async () => {
    const promises = [];

    if (adSenseCampaignsError) promises.push(mutateAdSenseCampaigns());

    await Promise.all(promises);
  }, [adSenseCampaignsError, mutateAdSenseCampaigns]);

  const handleRenameModalOpen = React.useCallback(adSenseCampaignAliasId => {
    setIsRenameModalOpen(adSenseCampaignAliasId);
  }, []);

  const handleEditBudgetModalOpen = React.useCallback(adSenseCampaignAliasId => {
    setIsEditBudgetModalOpen(adSenseCampaignAliasId);
  }, []);

  const handleSelectedCampaignToAction = React.useCallback(
    campaign => setSelectedCampaignToAction(campaign),
    [],
  );

  const handleDuplicateModalOpen = React.useCallback(() => {
    setIsDuplicateModalOpen(!isDuplicateModalOpen);
  }, [isDuplicateModalOpen]);

  const handleActivateCampaignsModalOpen = React.useCallback(() => {
    setIsActivateCampaignsModalOpen(!isActivateCampaignsModalOpen);
  }, [isActivateCampaignsModalOpen]);

  const handleDeactivateCampaignsModalOpen = React.useCallback(() => {
    setIsDeactivateCampaignsModalOpen(!isDeactivateCampaignsModalOpen);
  }, [isDeactivateCampaignsModalOpen]);

  const onUpdateStatus = React.useCallback(
    async (status: ECampaignStatus, campaigns: Array<string>) => {
      const mappedCampaigns = mapCampaignsToDuplicate(campaigns, adSenseCampaignsReport);

      if (status === ECampaignStatus.ENABLED) {
        analytics?.track(
          'Ads Manager Table Activated Campaign',
          {},
          { context: { groupId: storeAliasId } },
        );
      } else {
        analytics?.track(
          'Ads Manager Table Disabled Campaign',
          {},
          { context: { groupId: storeAliasId } },
        );
      }

      addSynchronization({
        name: 'Gerenciador de Anúncios',
        type: ESynchronizationType.ADS_MANAGER_CAMPAIGNS_STATUS,
        label: 'Ativar/Desativar Anúncio(s)',
        dependencies: [],
        externalId: campaigns,
        storeAliasId,
        config: {
          isOnboardingSynchronization: false,
          showNotification: true,
        },
        request: {
          type: ESynchronizationRequestType.PUT,
          domain: LONG_API_DOMAIN,
          api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/ads-manager/ad-sense-campaigns/status`,
          requestBody: {
            status,
            ad_sense_campaigns: mappedCampaigns,
          },
        },
      });
    },
    [adSenseCampaignsReport, addSynchronization, storeAliasId, analytics],
  );

  const onDuplicate = React.useCallback(
    async (quantity: number, campaigns: Array<string>) => {
      const mappedCampaigns = mapCampaignsToDuplicate(campaigns, adSenseCampaignsReport);

      analytics?.track(
        'Ads Manager Table Duplicated Campaign ',
        {
          duplicated_quantity: 1,
        },
        { context: { groupId: storeAliasId } },
      );

      addSynchronization({
        name: 'Gerenciador de Anúncios',
        type: ESynchronizationType.ADS_MANAGER_DUPLICATE_CAMPAIGNS,
        label: 'Duplicar Anúncio(s)',
        dependencies: [],
        externalId: campaigns,
        storeAliasId,
        config: {
          isOnboardingSynchronization: false,
          showNotification: true,
        },
        request: {
          type: ESynchronizationRequestType.POST,
          domain: LONG_API_DOMAIN,
          api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/ads-manager/ad-sense-campaigns/copy`,
          requestBody: {
            ad_sense_campaigns: mappedCampaigns,
          },
        },
      });
    },
    [adSenseCampaignsReport, addSynchronization, storeAliasId, analytics],
  );

  const onUpdateAdSenseCampaign = React.useCallback(
    async (adSenseCampaignAliasId: string | undefined, data: IUpdateAdSenseCampaignData) => {
      try {
        await adSenseCampaignsActions.updateAdSenseCampaign({
          storeAliasId,
          adSenseCampaignAliasId,
          data,
        });

        mutateAdSenseCampaigns();

        toast.success('Campanha atualizada com sucesso!');

        setIsRenameModalOpen(undefined);
        setIsEditBudgetModalOpen(undefined);
      } catch (error: any) {
        setIsRenameModalOpen(undefined);
        setIsEditBudgetModalOpen(undefined);
        toast.error(error?.response?.data?.message);
      }
    },
    [storeAliasId, toast, mutateAdSenseCampaigns],
  );

  const handleCampaignsTableSort = React.useCallback(newCampaignsTableSort => {
    setCampaignsTableSort(newCampaignsTableSort);
  }, []);

  const onHeaderMouseDown = React.useCallback(index => {
    setActiveTableIndex(index);
    document.onselectstart = () => false;
    document.onmousedown = () => false;
    document.body.style.cursor = 'col-resize';
  }, []);

  const onHeaderMouseMove = React.useCallback(
    event => {
      const width = getResizerNewPosition(
        tableColumns,
        activeTableIndex,
        tableParentElementRef,
        event,
      );

      if (tableElementRef.current) {
        const header = tableElementRef.current.children[0].children[0].children[
          activeTableIndex || 0
        ].children[1] as HTMLDivElement;

        header.style.right = width >= 0 ? `-${width}px` : `${width * -1}px`;
      }
    },
    [activeTableIndex, tableColumns],
  );

  const mouseUp = React.useCallback(
    event => {
      document.onselectstart = () => true;
      document.onmousedown = () => true;
      document.body.style.cursor = 'unset';

      const filteredTableColumns = tableColumns.filter(column => column.isActive);

      const foundColumn = filteredTableColumns.find((column, index) => index === activeTableIndex);

      const width = getResizerNewPosition(
        tableColumns,
        activeTableIndex,
        tableParentElementRef,
        event,
      );

      if (foundColumn) {
        setColumnsSize(previousState => {
          const newState = { ...previousState };

          newState[foundColumn.id] += width;

          return newState;
        });

        if (tableElementRef.current) {
          const header = tableElementRef.current.children[0].children[0].children[
            activeTableIndex || 0
          ].children[1] as HTMLDivElement;

          header.style.right = '0px';
        }
      }

      setActiveTableIndex(null);
    },
    [setActiveTableIndex, activeTableIndex, tableColumns, setColumnsSize],
  );

  const removeListeners = React.useCallback(() => {
    window.removeEventListener('mousemove', onHeaderMouseMove);
    window.removeEventListener('mouseup', mouseUp);
  }, [onHeaderMouseMove, mouseUp]);

  React.useEffect(() => {
    const isAdsManagerSync = finishedSynchronizations.find(synchronization => {
      if (
        synchronization.type === ESynchronizationType.ADS_MANAGER_CAMPAIGNS_STATUS ||
        synchronization.type === ESynchronizationType.ADS_MANAGER_DATA ||
        synchronization.type === ESynchronizationType.ADS_MANAGER_DUPLICATE_CAMPAIGNS ||
        synchronization.type === ESynchronizationType.ADS_WITHOUT_UTMS
      )
        return true;

      return false;
    });

    if (isAdsManagerSync) {
      handleFinishedSynchronizations([]);
      mutateAdSenseCampaigns();
    }
  }, [finishedSynchronizations, handleFinishedSynchronizations, mutateAdSenseCampaigns]);

  React.useEffect(() => {
    if (adSenseCampaignsLastSync) {
      handleLastSyncDate(utcToZonedTime(adSenseCampaignsLastSync));
    }
  }, [adSenseCampaignsLastSync, handleLastSyncDate, utcToZonedTime]);

  React.useEffect(() => {
    if (hasSelectedAll) {
      const mappedSelectedCampaigns = mappedAdSenseCampaigns.map(
        adSenseCampaigns => adSenseCampaigns.alias_id,
      );

      setSelectedCampaigns(mappedSelectedCampaigns);
    } else if (!hasSelectedAll) {
      setSelectedCampaigns([]);
    }
  }, [hasSelectedAll, mappedAdSenseCampaigns]);

  React.useEffect(() => {
    if (activeTableIndex !== null) {
      window.addEventListener('mousemove', onHeaderMouseMove);
      window.addEventListener('mouseup', mouseUp);
    }

    return () => {
      removeListeners();
    };
  }, [activeTableIndex, onHeaderMouseMove, mouseUp, removeListeners]);

  React.useEffect(() => {
    if (adSenseCampaignsReport) {
      const randomizedData = generateDataMock();

      const mappedData = mapCampaignsData(
        tableColumns,
        shouldRandomizeData ? randomizedData.ad_sense_campaigns_report : adSenseCampaignsReport,
      );

      if (campaignsTableSort) {
        const sortableMappedData = sortMappedCampaignsData(
          tableColumns,
          mappedData,
          campaignsTableSort,
        );

        setMappedAdSenseCampaigns(sortableMappedData);
      } else {
        setMappedAdSenseCampaigns(mappedData);
      }
    }
  }, [adSenseCampaignsReport, tableColumns, campaignsTableSort, shouldRandomizeData]);

  React.useEffect(() => {
    if (adSenseCampaignsSummaryReport) {
      const randomizedData = generateDataMock();

      const mappedData = mapFooterCampaignsData(
        tableColumns,
        shouldRandomizeData ? randomizedData.summary_report : adSenseCampaignsSummaryReport,
      );

      setMappedAdSenseCampaignsFooter(mappedData);
    }
  }, [adSenseCampaignsSummaryReport, tableColumns, shouldRandomizeData]);

  const isLoadingCampaigns = isLoadingAdSenseCampaigns;
  const isValidatingCampaigns = isValidatingAdSenseCampaigns;
  const isCampaignsError = Boolean(adSenseCampaignsError);

  return (
    <CampaignsContext.Provider
      value={{
        mappedAdSenseCampaigns,
        isLoadingCampaigns,
        isValidatingCampaigns,
        isCampaignsError,
        mutate,
        activeTableIndex,
        onHeaderMouseDown,
        tableElementRef,
        tableParentElementRef,
        adSenseCampaignsReport,
        mappedAdSenseCampaignsFooter,
        handleCampaignsTableSort,
        campaignsTableSort,
        handleDuplicateModalOpen,
        handleSelectedCampaignToAction,
        onDuplicate,
        selectedCampaignToAction,
        isDuplicateModalOpen,
        selectedCampaigns,
        setSelectedCampaigns,
        onUpdateStatus,
        handleActivateCampaignsModalOpen,
        handleDeactivateCampaignsModalOpen,
        isActivateCampaignsModalOpen,
        isDeactivateCampaignsModalOpen,
        handleRenameModalOpen,
        isRenameModalOpen,
        onUpdateAdSenseCampaign,
        handleEditBudgetModalOpen,
        isEditBudgetModalOpen,
      }}
    >
      {children}
    </CampaignsContext.Provider>
  );
};

export const useCampaigns = (): ICampaignsProvider => {
  const context = React.useContext(CampaignsContext);

  if (!context) {
    throw new Error('useCampaigns must be used within CampaignsProvider');
  }

  return context;
};
