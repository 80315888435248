import { AxiosResponse } from 'axios';

import {
  IListAllCustomPlansProps,
  IListAllCustomPlansResponse,
  IListAllCustomPromiseResponse,
  IListAllDefaultPlansPromiseResponse,
  IListAllDefaultPlansProps,
  IListAllDefaultPlansResponse,
  IListAllPlansPromiseResponse,
  IListAllPlansProps,
  IListAllPlansResponse,
} from '@domain/interfaces/common/plan/IPlan';

import { ApiService } from '@services/api';

import { useFetch } from '@providers/Fetch/useFetch';

import { USER_API_DOMAIN } from '@constants/api';

export class PlansService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listAllPlans({ storeAliasId }: IListAllPlansProps): IListAllPlansResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/plans`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      plans: data?.plans,
      error,
      isLoading,
      isValidating,
      mutate,
    };
  }

  public listAllPlansPromise({
    storeAliasId,
  }: IListAllPlansProps): Promise<AxiosResponse<IListAllPlansPromiseResponse>> {
    return this.apiService.get<IListAllPlansPromiseResponse>(
      `/api/v1/users/stores/${storeAliasId}/plans`,
    );
  }

  public listAllDefaultPlans({
    storeAliasId,
  }: IListAllDefaultPlansProps): IListAllDefaultPlansResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/plans/default`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      plans: data?.plans,
      error,
      isLoading,
      isValidating,
      mutate,
    };
  }

  public listAllDefaultPlansPromise({
    storeAliasId,
  }: IListAllDefaultPlansProps): Promise<AxiosResponse<IListAllDefaultPlansPromiseResponse>> {
    return this.apiService.get<IListAllDefaultPlansPromiseResponse>(
      `/api/v1/users/stores/${storeAliasId}/plans/default`,
    );
  }

  public listAllCustomPlans({
    storeAliasId,
  }: IListAllCustomPlansProps): IListAllCustomPlansResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/plans/custom`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      plans: data?.plans,
      error,
      isLoading,
      isValidating,
      mutate,
    };
  }

  public listAllCustomPlansPromise({
    storeAliasId,
  }: IListAllCustomPlansProps): Promise<AxiosResponse<IListAllCustomPromiseResponse>> {
    return this.apiService.get<IListAllCustomPromiseResponse>(
      `/api/v1/users/stores/${storeAliasId}/plans/custom`,
    );
  }
}

const plansService = new PlansService(USER_API_DOMAIN);

export default plansService;
