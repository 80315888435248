import React from 'react';
import { useLocation } from 'react-router-dom';

import Route from '@components/routes/Route';
import ConsentPage from '@pages/Consent';
import MambaDigital from '@pages/Consent/MambaDigital';
import BequestDigital from '@pages/Consent/BequestDigital';

const Consent: React.FC = () => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location]);

  return (
    <ConsentPage>
      <Route isPrivate path="/consent/mamba-digital" component={MambaDigital} />
      <Route isPrivate path="/consent/bequest-digital" component={BequestDigital} />
    </ConsentPage>
  );
};

export default Consent;
