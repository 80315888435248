import React from 'react';

import Return from '@components/Dashboard/IdeasChannel/NewIdea/Return';
import DraftContent from '@components/Dashboard/IdeasChannel/NewIdea/DraftContent';

import * as S from './styles';

const NewIdea: React.FC = () => {
  return (
    <S.Container>
      <Return />

      <DraftContent />
    </S.Container>
  );
};

export default NewIdea;
