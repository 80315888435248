import React from 'react';
import { ChatTeardropText, PencilSimple, Trash, IconProps } from 'phosphor-react';
import { useTheme } from 'styled-components';

import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';

import { useCanvasMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessageSettings';
import { useCanvasContent } from '@helpers/hooks/pages/dashboard/automations/useCanvasContent';

import * as S from './styles';

export interface ISavedContent {
  index: number;
  configOption: EConfigOption;
  contentId: string | number;
  icon: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
  contentText: string;
}

const SavedContent: React.FC<ISavedContent> = ({
  contentId,
  index,
  configOption,
  icon,
  contentText,
}) => {
  const theme = useTheme();
  const {
    contentsToUpdate,
    handleContentsToUpdate,
    handleArrayToChangePosition,
  } = useCanvasMessageSettings();
  const {
    handleEditingContent,
    handleSelectedContentType,
    handleContentToEdit,
  } = useCanvasContent();

  const onDelete = React.useCallback(() => {
    const filteredContents = contentsToUpdate.filter(content => content.id !== contentId);

    const filteredContentsToBeExcluded = contentsToUpdate.find(content => content.id === contentId);

    if (!filteredContentsToBeExcluded) return;

    handleContentsToUpdate([
      ...filteredContents,
      { ...filteredContentsToBeExcluded, shouldBeExcluded: true },
    ]);
  }, [contentId, contentsToUpdate, handleContentsToUpdate]);

  const onDragOver = React.useCallback(event => {
    event.preventDefault();
  }, []);

  const onDragStart = React.useCallback(
    event => {
      event.dataTransfer.setData('text/plain', index);
    },
    [index],
  );

  const onDrop = React.useCallback(
    event => {
      const draggedIndex = parseInt(event.dataTransfer.getData('text/plain'), 10);

      const newItems = [...contentsToUpdate];

      const [draggedItem] = newItems.splice(draggedIndex, 1);

      newItems.splice(index, 0, draggedItem);

      const contentArrayToChangePosition = newItems.map((item, itemIndex) => {
        return {
          ...item,
          position: itemIndex + 1,
        };
      });

      handleArrayToChangePosition(contentArrayToChangePosition);
      handleContentsToUpdate(newItems);
    },
    [contentsToUpdate, handleContentsToUpdate, index, handleArrayToChangePosition],
  );

  const onEditContent = React.useCallback(() => {
    handleEditingContent(true);
    handleSelectedContentType(configOption);
    handleContentToEdit(contentsToUpdate[index]);
  }, [
    contentsToUpdate,
    handleContentToEdit,
    handleSelectedContentType,
    handleEditingContent,
    configOption,
    index,
  ]);

  const Icon = icon;

  return (
    <S.Wrapper>
      <S.SavedContent draggable onDragStart={onDragStart} onDragOver={onDragOver} onDrop={onDrop}>
        <S.IconAndTextWrapper>
          <S.IconWrapper>
            <Icon size={24} color={theme.colors.gray[1]} />
          </S.IconWrapper>

          <ChatTeardropText size={24} color={theme.colors.gray[1]} />

          <S.SavedContentText>{contentText}</S.SavedContentText>
        </S.IconAndTextWrapper>

        <S.EditAndDeleteMessageButtonsWrapper>
          <S.EditMessageButton onClick={onEditContent} type="button">
            <PencilSimple size={24} color={theme.colors.gray[3]} />
          </S.EditMessageButton>

          <S.DeleteMessageButton onClick={onDelete} type="button">
            <Trash size={24} color={theme.colors.gray[3]} />
          </S.DeleteMessageButton>
        </S.EditAndDeleteMessageButtonsWrapper>
      </S.SavedContent>
    </S.Wrapper>
  );
};

export default SavedContent;
