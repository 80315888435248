import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';

import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 297px;
  max-width: 398px;
  width: 100%;
  padding: 20px 0px 16px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 12px;
  justify-content: space-between;

  @media only screen and (max-width: 1360px) {
    max-width: unset;
  }
`;

export const Option = styled(OptionBase)``;

export const Select = styled(SelectBase)`
  & > label > select {
    height: 32px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

export const Title = styled(HeadingBase)``;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 24px 8px 24px;
`;

export const NoProductInfo = styled(HeadingBase)`
  font-size: 14px;
  margin-left: 24px;
  color: ${({ theme }) => theme.colors.gray[2]};
`;
