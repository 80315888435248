import React from 'react';

import * as S from './styles';

const NoAccess: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Image
        src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/access-management/no-access.svg"
        alt="Profitfy.me No Permission"
      />
    </S.Wrapper>
  );
};

export default NoAccess;
