import React from 'react';
import { useReactFlow } from 'react-flow-renderer';

import { EAutomationsTemplate } from '@domain/enums/common/automation/ETemplate';
import { ETriggers } from '@domain/enums/dashboard/automations/ETriggers';
import { IAutomationsTemplateContext } from '@domain/interfaces/dashboard/Automations/Canvas/IAutomationsTemplate';

import { useQuery } from '@helpers/hooks/useQuery';

import { ALL_TEMPLATES } from '@constants/pages/dashboard/automations/templates/allTemplates';

import { generateInitialEdgesArray } from '@helpers/utils/dashboard/automations/edges';
import { generateInitialNodesArray } from '@helpers/utils/dashboard/automations/nodes';

const FREE_PLAN_TEMPLATES = [
  EAutomationsTemplate.NO_CONTENT,
  EAutomationsTemplate.ABANDONED_CART_FREE,
  EAutomationsTemplate.BOLETO_FREE,
  EAutomationsTemplate.PIX_FREE,
  EAutomationsTemplate.REFUSED_CREDIT_CARD_FREE,
];
const STARTER_PLAN_TEMPLATES = [
  EAutomationsTemplate.NO_CONTENT,
  EAutomationsTemplate.ABANDONED_CART_STARTER,
  EAutomationsTemplate.BOLETO_STARTER,
  EAutomationsTemplate.PIX_STARTER,
  EAutomationsTemplate.REFUSED_CREDIT_CARD_STARTER,
];
const NO_LIMIT_TEMPLATES = [
  EAutomationsTemplate.NO_CONTENT,
  EAutomationsTemplate.ABANDONED_CART,
  EAutomationsTemplate.ABANDONED_CART_2,
  EAutomationsTemplate.REFUSED_CREDIT_CARD,
  EAutomationsTemplate.BOLETO,
  EAutomationsTemplate.PIX,
  EAutomationsTemplate.SIMPLE_SHIPMENT,
];

const AutomationsTemplateContext = React.createContext<IAutomationsTemplateContext | null>(null);

export const AutomationsTemplateProvider: React.FC = ({ children }) => {
  const query = useQuery();
  const reactFlowInstance = useReactFlow();

  const templateQuery = query.get('template');

  const [selectedTemplate, setSelectedTemplate] = React.useState<EAutomationsTemplate>(
    EAutomationsTemplate.NO_CONTENT,
  );
  const [templateType, setTemplateType] = React.useState<ETriggers | undefined>(undefined);

  const generateInitialNodesAndEdges = React.useCallback(() => {
    if (templateQuery) {
      const nodes = generateInitialNodesArray(selectedTemplate);
      const edges = generateInitialEdgesArray(selectedTemplate);

      reactFlowInstance.addNodes(nodes);
      reactFlowInstance.addEdges(edges);
    }
  }, [reactFlowInstance, selectedTemplate, templateQuery]);

  React.useEffect(() => {
    if (!templateQuery) return;

    const templateQueryString = templateQuery as EAutomationsTemplate;

    if (
      FREE_PLAN_TEMPLATES.includes(templateQueryString) ||
      STARTER_PLAN_TEMPLATES.includes(templateQueryString) ||
      NO_LIMIT_TEMPLATES.includes(templateQueryString)
    ) {
      setSelectedTemplate(templateQueryString);
    }
  }, [templateQuery]);

  React.useEffect(() => {
    if (!templateQuery) return;

    const foundTemplate = ALL_TEMPLATES.find(template => template.id === templateQuery);

    if (!foundTemplate) return;

    setTemplateType(foundTemplate.type);
  }, [templateQuery]);

  return (
    <AutomationsTemplateContext.Provider
      value={{ generateInitialNodesAndEdges, templateType, templateQuery }}
    >
      {children}
    </AutomationsTemplateContext.Provider>
  );
};

export const useAutomationsTemplate = (): IAutomationsTemplateContext => {
  const context = React.useContext(AutomationsTemplateContext);

  if (!context) {
    throw new Error('useAutomationsTemplate must be used within AutomationsTemplateProvider');
  }

  return context;
};
