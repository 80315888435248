import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import { ESubscriptionRecurrence } from '@domain/enums/common/subscription/ESubscriptionRecurrence';
import { IParams } from '@domain/interfaces/IParams';

import { useQuery } from '@helpers/hooks/useQuery';
import { useSubscriptionStatus } from '@helpers/hooks/common/store/subscription/useSubscriptionStatus';
import { useActivateSubscription } from '@helpers/hooks/pages/dashboard/useActivateSubscription';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';

import { numberFormatter } from '@helpers/masks';

import UpdateCreditCardModal from './UpdateCreditCardModal';

import * as S from './styles';

const Canceled: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const { isCancelledByCreditCardIssue } = useSubscriptionStatus();
  const { isVerifyingTransaction } = useActivateSubscription();
  const { subscription } = useStoreSubscription();
  const history = useHistory();
  const query = useQuery();

  const reactivateSubscriptionRef = query.get('reactivateSubscription');

  const [isUpdateCreditCardModalOpen, setIsUpdateCreditCardModalOpen] = React.useState<boolean>(
    false,
  );

  const getRecurrenceText = React.useCallback(() => {
    if (subscription?.recurrence === ESubscriptionRecurrence.SEMIANNUAL) return '/seis meses';

    if (subscription?.recurrence === ESubscriptionRecurrence.QUARTERLY) return '/três meses';

    return '/mês';
  }, [subscription]);

  const handleUpdateCreditCardModalOpen = React.useCallback(
    () => setIsUpdateCreditCardModalOpen(state => !state),
    [],
  );

  const onReactivateClick = React.useCallback(() => {
    if (isCancelledByCreditCardIssue) {
      handleUpdateCreditCardModalOpen();
      return;
    }

    history.push(`/${storeAliasId}/pricing`);
  }, [handleUpdateCreditCardModalOpen, isCancelledByCreditCardIssue, history, storeAliasId]);

  React.useEffect(() => {
    if (reactivateSubscriptionRef === 'true') {
      setIsUpdateCreditCardModalOpen(true);
      history.push(`/${storeAliasId}/dashboard/subscription`);
    }
  }, [reactivateSubscriptionRef, history, storeAliasId]);

  const planPrice = subscription.plan?.amount || 9999;
  const planName = subscription.plan?.name;
  const canceledAt = subscription.canceled_at ? new Date(subscription.canceled_at) : new Date();

  return (
    <>
      <S.CardWrapper>
        <S.Content>
          <S.PlanNameAndPricingWrapper>
            <S.TitleHeader>
              <S.PlanName>{planName}</S.PlanName>
              <S.StatusText>
                {isVerifyingTransaction
                  ? 'Processando'
                  : `Cancelada em ${format(canceledAt, 'dd/MM/yyyy')}`}
              </S.StatusText>
            </S.TitleHeader>
            <S.PricingValue>{`R$${numberFormatter(
              planPrice,
              0,
            )}${getRecurrenceText()}`}</S.PricingValue>
          </S.PlanNameAndPricingWrapper>

          <S.Divider />

          <S.Description>
            {isVerifyingTransaction
              ? 'O seu pagamento está sendo processado no momento'
              : 'Reative a sua assinatura para aproveitar nossos benefícios'}
          </S.Description>

          <S.ReactivateButton onClick={onReactivateClick} disabled={isVerifyingTransaction}>
            Reativar assinatura
          </S.ReactivateButton>
        </S.Content>
      </S.CardWrapper>

      <UpdateCreditCardModal
        isOpen={isUpdateCreditCardModalOpen}
        toggle={handleUpdateCreditCardModalOpen}
      />
    </>
  );
};

export default Canceled;
