import styled from 'styled-components/macro';

import CustomHoverCard from '@components/common/core/Utils/CustomHoverCard';

import TextBase from '@components/common/core/DataDisplay/Text';

export const HoverCard = styled(CustomHoverCard)``;

export const HoverCardContent = styled(CustomHoverCard.Content)`
  min-width: 288px;
  padding: 16px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.background[2]};
`;

export const HoverCardTrigger = styled(CustomHoverCard.Trigger)`
  margin-right: -22px;
`;

export const HoverCardArrow = styled(CustomHoverCard.Arrow)`
  fill: ${({ theme }) => theme.colors.background[2]};
`;

export const IconAndNameWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const Name = styled(TextBase)`
  font-size: 0.875rem;
`;

export const Value = styled(TextBase)``;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[4]};

  &:first-of-type {
    padding-top: unset;
  }

  &:last-of-type {
    border-bottom: unset;
    padding-bottom: unset;
  }
`;
