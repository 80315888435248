export enum EUpdateFulfillmentType {
  PRODUCT_NAME = 'PRODUCT_NAME',
  UPDATE_FULFILLMENT_TOTAL_VALUE = 'UPDATE_FULFILLMENT_TOTAL_VALUE',
  ORDER_STATUS = 'ORDER_STATUS',
  FULFILLMENT_STATUS = 'FULFILLMENT_STATUS',
}

export enum EUpdateFulfillmentProductOptions {
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  NOT_EQUAL = 'NOT_EQUAL',
}

export enum EUpdateFulfillmentTotalValueOptions {
  SMALLER = 'SMALLER',
  SMALLER_EQUAL = 'SMALLER_EQUAL',
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  LARGER = 'LARGER',
  LARGER_EQUAL = 'LARGER_EQUAL',
}

export enum EUpdateFulfillmentOrderStatusOptions {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
}

export enum EUpdateFulfillmentFulfillmentStatusOptions {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
}

export enum EUpdateFulfillmentOrderStatusPicker {
  PAID = 'PAID',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
  CHARGED_BACK = 'CHARGED_BACK',
  IN_ANALYSIS = 'IN_ANALYSIS',
  REJECTED = 'REJECTED',
}

export enum EUpdatedFulfillmentStatusPicker {
  POSTED = 'POSTED',
  IN_TRANSIT = 'IN_TRANSIT',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  DELIVERY_PROBLEMS = 'DELIVERY_PROBLEMS',
  AWAITING_PICKUP = 'AWAITING_PICKUP',
  RETURNED_TO_SENDER = 'RETURNED_TO_SENDER',
  DELIVERED = 'DELIVERED',
}

export type UpdateFulfillmentOptions<Type> = Type extends EUpdateFulfillmentType.PRODUCT_NAME
  ? EUpdateFulfillmentProductOptions
  : Type extends EUpdateFulfillmentType.UPDATE_FULFILLMENT_TOTAL_VALUE
  ? EUpdateFulfillmentTotalValueOptions
  : Type extends EUpdateFulfillmentType.ORDER_STATUS
  ? EUpdateFulfillmentOrderStatusOptions
  : EUpdateFulfillmentFulfillmentStatusOptions;
