import styled from 'styled-components/macro';

import CustomHoverCard from '@components/common/core/Utils/CustomHoverCard';

import TextBase from '@components/common/core/DataDisplay/Text';
import ImageBase from '@components/common/core/DataDisplay/Image';
import ButtonBase from '@components/common/core/Inputs/Button';

export const HoverCard = styled(CustomHoverCard)``;

export const HoverCardContent = styled(CustomHoverCard.Content)`
  width: 312px;
`;

export const MarketingIntegrationsWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[5]};
  gap: 16px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IconAndNameWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const MarketingName = styled(TextBase)`
  font-size: 0.875rem;
`;

export const MarketingValue = styled(TextBase)``;

export const MarketingIcon = styled(ImageBase)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

export const AddManualAdsButton = styled(ButtonBase)`
  padding: 12px 16px;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  & > span {
    color: ${({ theme }) => theme.colors.gray[1]};
    font-size: 0.875rem;

    &:hover {
      color: ${({ theme }) => theme.colors.gray[1]};
    }
  }
`;

export const IconWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[6]};
  width: 20px;
  height: 20px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
`;
