import React from 'react';
import { ResponsiveContainer, PieChart as Chart, Pie, Cell } from 'recharts';

import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';

import * as S from './styles';

const PieChart: React.FC = () => {
  const {
    isLoadingFinancialDashboardProvider,
    isValidatingFinancialDashboardProvider,
    customValues,
  } = useFinancialDashboard();

  if (isLoadingFinancialDashboardProvider || isValidatingFinancialDashboardProvider) {
    return <></>;
  }

  const data = customValues.map(customValue => {
    return {
      name: customValue.name,
      value: customValue.amount,
    };
  });

  const colors = customValues.map(customValue => customValue.hex_color);

  return (
    <S.ChartWrapper>
      <ResponsiveContainer width="100%">
        <Chart>
          <Pie
            data={data}
            innerRadius="90%"
            outerRadius="100%"
            fill="#000"
            paddingAngle={0}
            dataKey="value"
            strokeOpacity={0}
          >
            {data.map((pie, index) => (
              <Cell key={`cell-${pie.name}`} fill={colors[index]} />
            ))}
          </Pie>
        </Chart>
      </ResponsiveContainer>
    </S.ChartWrapper>
  );
};

export default PieChart;
