import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Modal = styled(ModalBase)`
  width: 100%;
  max-width: 428px;
  box-sizing: border-box;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

export const Heading = styled(TextBase)`
  font-size: 1.125rem;
`;

export const Description = styled(TextBase)``;

export const UnderstoodButton = styled(ButtonBase)`
  margin-top: 16px;
  margin-left: auto;
`;
