import styled, { css } from 'styled-components/macro';

import {
  IPercentageProps,
  ITitleProps,
} from '@domain/interfaces/dashboard/FinancialDashboard/IStoreGoal';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

export const ReachedGoalValue = styled(TextBase)`
  line-height: 120%;
  color: ${({ theme }) => theme.colors.success.default};
  font-weight: 500;
  font-size: 1rem;
`;

export const ReachedGoal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const GoalTargetText = styled(TextBase)`
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const CurrentProgressText = styled(TextBase)`
  line-height: 120%;
  color: ${({ theme }) => theme.colors.green.default};
  font-weight: 500;
`;

export const ProgressLabel = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
`;

export const GoalTarget = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: -12px;
`;

export const CurrentProgress = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: -12px;
`;

export const PercentageLabel = styled(TextBase)`
  line-height: 120%;
  font-weight: 400;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const Percentage = styled(HeadingBase)<IPercentageProps>`
  line-height: 120%;
  font-weight: 400;
  font-size: 1.5rem;

  ${({ isSmall }) =>
    isSmall &&
    css`
      font-size: 0.875rem;
    `}
`;

export const ChartPercentageWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateX(-50%);
`;

export const ChartAndPercentage = styled.div`
  position: relative;
`;

export const EditGoal = styled.button`
  background-color: unset;
  border: unset;
  padding: unset;
  color: ${({ theme }) => theme.colors.font};
  font-size: 0.75rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.green.default};
  }

  &:active {
    color: ${({ theme }) => theme.colors.green.dark};
  }
`;

export const RemainingPeriodText = styled(TextBase)`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const Title = styled(HeadingBase)<ITitleProps>`
  font-size: 1rem;
  font-weight: 400;

  ${({ isMultiStoreSelectMode }) =>
    isMultiStoreSelectMode &&
    css`
      text-align: center;
      margin-top: 36px;
    `}
`;

export const TitleAndRemainingPeriod = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ChartWrapper = styled.div`
  height: 108px;
  width: 108px;
  transform: rotateY(180deg);
`;

export const HalfPieChart = styled.div`
  height: 54px;
  overflow: hidden;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  margin-bottom: 12px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const StoreGoalCard = styled.div`
  max-width: 420px;
  width: 100%;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 12px;
  flex-direction: column;
  height: 138px;
  box-sizing: border-box;

  @media only screen and (max-width: 1408px) {
    padding: 16px 12px;
  }

  @media only screen and (max-width: 1388px) {
    max-width: 380px;
  }

  @media only screen and (max-width: 1360px) {
    padding: 16px 24px;
    max-width: unset;
  }
`;
