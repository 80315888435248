import React from 'react';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useParams } from 'react-router-dom';

import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { IParams } from '@domain/interfaces/IParams';

import { useConfig } from '@helpers/hooks/useConfig';

import FacebookIcon from '@assets/components/FacebookButton/facebook.png';

import * as S from './styles';

interface IFacebookOnboardingButtonProps {
  hasFacebookProfile?: boolean;
  handleAuthenticateModalOpen?: () => void;
  width?: number;
  onSuccess?: (data: Record<string, any> | undefined) => void;
  onProfileSuccess?: (data: Record<string, any> | undefined) => void;
  onFail?: () => void;
}

const FacebookOnboardingButton: React.FC<IFacebookOnboardingButtonProps> = ({
  hasFacebookProfile,
  handleAuthenticateModalOpen,
  width = 267,
  onSuccess,
  onProfileSuccess,
  onFail,
}) => {
  const { analytics } = useConfig();
  const { storeAliasId } = useParams<IParams>();

  const handleAddNewProfileButtonClick = React.useCallback(
    async onClick => {
      try {
        console.log('here');
        analytics?.track(
          'Onboarding Profile Button Clicked',
          {
            provider: EAdSenseProvider.FACEBOOK,
          },
          { context: { groupId: storeAliasId } },
        );

        await onClick();
      } catch (error) {
        console.log(error);
      }
    },
    [analytics, storeAliasId],
  );

  return (
    <FacebookLogin
      appId="2444384415680634"
      scope="ads_read,public_profile,business_management,ads_management"
      initParams={{
        localStorage: false,
        version: 'v9.0',
      }}
      onSuccess={onSuccess}
      onFail={onFail}
      onProfileSuccess={onProfileSuccess}
      render={({ onClick }) => (
        <S.FacebookLoginButton
          width={width}
          onClick={
            hasFacebookProfile
              ? handleAuthenticateModalOpen
              : () => handleAddNewProfileButtonClick(onClick)
          }
        >
          <S.FacebookIcon src={FacebookIcon} alt="facebook-login-icon" />
          <S.Text>Continuar com Facebook</S.Text>
        </S.FacebookLoginButton>
      )}
    />
  );
};

export default FacebookOnboardingButton;
