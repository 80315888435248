import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  background-color: transparent;
`;

export const Button = styled.button`
  width: 24px;
  height: 24px;
  border: 2px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.background[2]};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  & > svg {
    min-width: 14px;
    min-height: 14px;
  }

  &:hover {
    filter: brightness(1.4);
  }

  &:active {
    filter: brightness(0.8);
  }
`;
