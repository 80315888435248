export enum EGateway {
  APPMAX = 'APPMAX',
  MERCADO_PAGO = 'MERCADO_PAGO',
  HUB_SALE = 'HUB_SALE',
  CART_PANDA_PAY = 'CART_PANDA_PAY',
  PAGARME = 'PAGARME',
  PAG_SEGURO = 'PAG_SEGURO',
  UPNID = 'UPNID',
  ZOUTI = 'ZOUTI',
  CLOUD_FOX = 'CLOUD_FOX',
  PAG_SEGURO_V4 = 'PAG_SEGURO_V4',
  HUB_SALE_V2 = 'HUB_SALE_V2',
  LINEPAY = 'LINEPAY',
  ISTPAY = 'ISTPAY',
  ABMEX = 'ABMEX',
  CIELO = 'CIELO',
  OTHER_GATEWAY = 'OTHER_GATEWAY',
  YEVER = 'YEVER',
  DOM_PAGAMENTOS = 'DOM_PAGAMENTOS',
}
