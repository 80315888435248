import React from 'react';

import { Position } from 'react-flow-renderer';
import { ShoppingCart } from 'phosphor-react';

import * as S from './styles';

const AbandonCartNode: React.FC = () => {
  return (
    <S.Wrapper>
      <S.IconContainer>
        <ShoppingCart size={20} />
      </S.IconContainer>

      <S.Name>Rastreio por status</S.Name>

      <S.HandleContainer>
        <S.Handle position={Position.Bottom} type="source" />
      </S.HandleContainer>
    </S.Wrapper>
  );
};

export default AbandonCartNode;
