import React from 'react';
import { FileImage } from 'phosphor-react';
import { useTheme } from 'styled-components';

import { EButtonSize, EButtonVariant } from '@domain/enums/components/EButton';
import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';
import { ETextSize } from '@domain/enums/components/EText';

import { useContent } from '@helpers/hooks/pages/dashboard/automations/useContent';
import { useMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useMessageSettings';

import * as S from './styles';

const ProductImageTypeConfig: React.FC = () => {
  const { colors } = useTheme();
  const { contentsToUpdate, handleContentsToUpdate } = useMessageSettings();
  const { isEditingContent, handleSelectedContentType, handleEditingContent } = useContent();

  const resetConfig = React.useCallback(() => {
    handleEditingContent(false);
    handleSelectedContentType(EConfigOption.NONE);
  }, [handleEditingContent, handleSelectedContentType]);

  const onCancel = React.useCallback(() => {
    resetConfig();
  }, [resetConfig]);

  const onSave = React.useCallback(() => {
    if (isEditingContent) {
      resetConfig();
      return;
    }

    const newContent = {
      id: Math.random(),
      type: EConfigOption.PRODUCT_IMAGE,
      content: '{{product_image}}',
      isNewItemInArray: true,
    };

    handleContentsToUpdate([...contentsToUpdate, newContent]);

    resetConfig();
  }, [isEditingContent, resetConfig, handleContentsToUpdate, contentsToUpdate]);

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title size={ETextSize.B5}>Imagem do Produto</S.Title>

        <S.FileImageContainer>
          <FileImage size={62} color={colors.white.default} />
        </S.FileImageContainer>

        <S.ButtonsWrapper>
          <S.CancelButton
            onClick={onCancel}
            variant={EButtonVariant.SECONDARY_BUTTON}
            buttonSize={EButtonSize.MEDIUM}
            type="button"
          >
            Cancelar
          </S.CancelButton>

          <S.SaveButton buttonSize={EButtonSize.MEDIUM} onClick={onSave} type="button">
            Salvar
          </S.SaveButton>
        </S.ButtonsWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

export default ProductImageTypeConfig;
