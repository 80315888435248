/* eslint-disable no-restricted-syntax */
import React from 'react';
import { useParams } from 'react-router-dom';

import { EConfigOption, EMessageType } from '@domain/enums/dashboard/automations/EMessage';
import { IParams } from '@domain/interfaces/IParams';
import { ICanvasMessageContentProvider } from '@domain/interfaces/common/automations/ICanvasMessage';
import { ISavedContent } from '@domain/interfaces/common/automations/ISavedContent';

import {
  ABANDONED_CART_TRIGGER_VARIABLES,
  BOLETO_TRIGGER_VARIABLES,
  PIX_TRIGGER_VARIABLES,
  REJECTED_PAYMENT_TRIGGER_VARIABLES,
  SIMPLE_SHIPMENT_TRIGGER_VARIABLES,
  UPDATED_FULFILLMENT_TRIGGER_VARIABLES,
} from '@constants/pages/dashboard/automations/messages/triggers';
import { IPositionData } from '@domain/interfaces/common/automations/IMessage';
import messagesContentService from '@services/pages/dashboard/automations/messagesContent';

const CanvasMessageContentContext = React.createContext<ICanvasMessageContentProvider | null>(null);

export const CanvasMessageContentProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();

  const getTriggerVariables = React.useCallback((messageTrigger: EMessageType) => {
    if (messageTrigger === EMessageType.ABANDONED_CART) return ABANDONED_CART_TRIGGER_VARIABLES;

    if (messageTrigger === EMessageType.BOLETO) return BOLETO_TRIGGER_VARIABLES;

    if (messageTrigger === EMessageType.PIX) return PIX_TRIGGER_VARIABLES;

    if (messageTrigger === EMessageType.SIMPLE_SHIPMENT) return SIMPLE_SHIPMENT_TRIGGER_VARIABLES;

    if (messageTrigger === EMessageType.REJECTED_PAYMENT) return REJECTED_PAYMENT_TRIGGER_VARIABLES;

    if (messageTrigger === EMessageType.UPDATED_FULFILLMENT)
      return UPDATED_FULFILLMENT_TRIGGER_VARIABLES;

    return [];
  }, []);

  const handleTextContent = React.useCallback(
    async (
      content: ISavedContent,
      messageTrigger: EMessageType,
      messageAliasId: string,
    ): Promise<any> => {
      if (!content.content) return undefined;

      let dataContent = content.content as string;

      const variables = getTriggerVariables(messageTrigger);

      variables.forEach(variable => {
        dataContent = dataContent.replace(variable.nameToDisplay, variable.data);
      });

      const data = {
        content: dataContent,
        type: content.type,
      };

      const { data: textMessageData } = await messagesContentService.createTextMessage({
        storeAliasId,
        message_alias_id: messageAliasId,
        data,
      });

      return textMessageData.message_content;
    },
    [getTriggerVariables, storeAliasId],
  );

  const handleProductImageContent = React.useCallback(
    async (content: ISavedContent, messageAliasId: string) => {
      if (!content.content) return undefined;

      const data = {
        content: content.content as string,
        type: content.type,
      };

      const { data: textMessageData } = await messagesContentService.createTextMessage({
        storeAliasId,
        message_alias_id: messageAliasId,
        data,
      });

      return textMessageData.message_content;
    },
    [storeAliasId],
  );

  const handleImageContent = React.useCallback(
    async (content: ISavedContent, messageAliasId: string): Promise<any> => {
      if (!content.content) return undefined;

      const file = content.content as File;

      const fileData = new FormData();

      fileData.append('file', file, file.name);

      const data = {
        file: fileData,
      };

      const { data: imageMessageData } = await messagesContentService.createImageMessage({
        storeAliasId,
        message_alias_id: messageAliasId,
        data,
      });

      return imageMessageData.message_content;
    },
    [storeAliasId],
  );

  const handleVideoContent = React.useCallback(
    async (content: ISavedContent, messageAliasId: string): Promise<any> => {
      if (!content.content) return undefined;

      const file = content.content as File;

      const fileData = new FormData();

      fileData.append('file', file, file.name);

      const data = {
        file: fileData,
      };

      const { data: imageMessageData } = await messagesContentService.createVideoMessage({
        storeAliasId,
        message_alias_id: messageAliasId,
        data,
      });

      return imageMessageData.message_content;
    },
    [storeAliasId],
  );

  const handlePDFContent = React.useCallback(
    async (content: ISavedContent, messageAliasId: string): Promise<any> => {
      if (!content.content) return undefined;

      const file = content.content as File;

      const fileData = new FormData();

      fileData.append('file', file, file.name);

      const data = {
        file: fileData,
      };

      const { data: imageMessageData } = await messagesContentService.createPDFMessage({
        storeAliasId,
        message_alias_id: messageAliasId,
        data,
      });

      return imageMessageData.message_content;
    },
    [storeAliasId],
  );

  const handleAudioContent = React.useCallback(
    async (content: ISavedContent, messageAliasId: string): Promise<any> => {
      if (!content.content) return undefined;

      const file = content.content as File;

      const fileData = new FormData();

      fileData.append('file', file, file.name);

      const data = {
        file: fileData,
      };

      const { data: audioMessageData } = await messagesContentService.createAudioMessage({
        storeAliasId,
        message_alias_id: messageAliasId,
        data,
      });

      return audioMessageData.message_content;
    },
    [storeAliasId],
  );

  const createMessageContent = React.useCallback(
    async (messageAliasId: string, content: ISavedContent, messageTrigger: EMessageType) => {
      if (content.type === EConfigOption.TEXT)
        await handleTextContent(content, messageTrigger, messageAliasId);

      if (content.type === EConfigOption.IMAGE) await handleImageContent(content, messageAliasId);

      if (content.type === EConfigOption.VIDEO) await handleVideoContent(content, messageAliasId);

      if (content.type === EConfigOption.PDF) await handlePDFContent(content, messageAliasId);

      if (content.type === EConfigOption.AUDIO) await handleAudioContent(content, messageAliasId);

      if (content.type === EConfigOption.PRODUCT_IMAGE)
        await handleProductImageContent(content, messageAliasId);
    },
    [
      handleTextContent,
      handleImageContent,
      handleAudioContent,
      handleVideoContent,
      handlePDFContent,
      handleProductImageContent,
    ],
  );

  const deleteMessageContent = React.useCallback(
    async (messageAliasId: string, messageContentId: string) => {
      await messagesContentService.deleteMessageContent({
        storeAliasId,
        message_alias_id: messageAliasId,
        message_content_alias_id: messageContentId,
      });
    },
    [storeAliasId],
  );

  const createMessageContents = React.useCallback(
    async (
      messageAliasId: string,
      contents: Array<ISavedContent>,
      messageTrigger: EMessageType,
    ) => {
      for await (const content of contents) {
        await createMessageContent(messageAliasId, content, messageTrigger);
      }
    },
    [createMessageContent],
  );

  const updateMessageContents = React.useCallback(
    async (
      messageAliasId: string,
      contents: Array<ISavedContent>,
      messageTrigger: EMessageType,
    ) => {
      const contentsToDelete = contents.filter(content => content.shouldBeExcluded);
      const contentsToShow = contents.filter(content => !content.shouldBeExcluded);

      if (contentsToDelete.length) {
        for await (const content of contentsToDelete) {
          await deleteMessageContent(messageAliasId, String(content.id));
        }
      }

      if (contentsToShow.length) {
        const contentsToShowCopy = [...contentsToShow];

        for await (const [index, content] of contentsToShow.entries()) {
          if (!content.isEdited && !content.isNewItemInArray) continue;

          if (content.isEdited) {
            await deleteMessageContent(messageAliasId, String(content.id));
          }

          if (content.type === EConfigOption.TEXT) {
            const dataContent = await handleTextContent(content, messageTrigger, messageAliasId);

            contentsToShowCopy[index].id = dataContent.alias_id;
          }

          if (content.type === EConfigOption.IMAGE) {
            const dataContent = await handleImageContent(content, messageAliasId);

            contentsToShowCopy[index].id = dataContent.alias_id;
          }

          if (content.type === EConfigOption.VIDEO) {
            const dataContent = await handleVideoContent(content, messageAliasId);

            contentsToShowCopy[index].id = dataContent.alias_id;
          }

          if (content.type === EConfigOption.PRODUCT_IMAGE) {
            const dataContent: any = await handleProductImageContent(content, messageAliasId);

            contentsToShowCopy[index].id = dataContent.alias_id;
          }

          if (content.type === EConfigOption.PDF) {
            const dataContent = await handlePDFContent(content, messageAliasId);

            contentsToShowCopy[index].id = dataContent.alias_id;
          }

          if (content.type === EConfigOption.AUDIO) {
            const dataContent = await handleAudioContent(content, messageAliasId);

            contentsToShowCopy[index].id = dataContent.alias_id;
          }
        }

        const messagePositionData: Array<IPositionData> = contentsToShowCopy.map(
          (content, index) => {
            return {
              position: index + 1,
              message_content_alias_id: String(content.id),
            };
          },
        );

        await messagesContentService.updateMessageContentPosition({
          storeAliasId,
          message_alias_id: messageAliasId,
          data: { message_contents: messagePositionData },
        });
      }
    },
    [
      deleteMessageContent,
      handleAudioContent,
      handleImageContent,
      handleTextContent,
      storeAliasId,
      handleVideoContent,
      handlePDFContent,
      handleProductImageContent,
    ],
  );

  return (
    <CanvasMessageContentContext.Provider value={{ createMessageContents, updateMessageContents }}>
      {children}
    </CanvasMessageContentContext.Provider>
  );
};

export const useCanvasMessageContent = (): ICanvasMessageContentProvider => {
  const context = React.useContext(CanvasMessageContentContext);

  if (!context)
    throw new Error('useCanvasMessageContent must be used within CanvasMessageContentProvider');

  return context;
};
