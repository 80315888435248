export const AUTH_API_DOMAIN =
  process.env.REACT_APP_STAGE === 'prod'
    ? 'https://kakashi-api.profitfy.me'
    : 'https://kakashi-api.profitfy.me';

export const API_DOMAIN =
  process.env.REACT_APP_STAGE === 'prod'
    ? 'https://sasuke-api.profitfy.me'
    : 'https://sasuke-api.profitfy.me';

export const LONG_API_DOMAIN =
  process.env.REACT_APP_STAGE === 'prod'
    ? 'https://memna-api.profitfy.me'
    : 'https://hml-memna-api.profitfy.me';

export const API_DASHBOARD_DOMAIN =
  process.env.REACT_APP_STAGE === 'prod'
    ? 'https://naruto-api.profitfy.me'
    : 'https://naruto-api.profitfy.me';

export const ADS_API_DOMAIN =
  process.env.REACT_APP_STAGE === 'prod'
    ? 'https://naruto-api.profitfy.me'
    : 'https://naruto-api.profitfy.me';

export const USER_API_DOMAIN =
  process.env.REACT_APP_STAGE === 'prod'
    ? 'https://itachi-api.profitfy.me'
    : 'https://itachi-api.profitfy.me';

export const SUGGESTION_API_DOMAIN =
  process.env.REACT_APP_STAGE === 'prod'
    ? 'https://rocklee-api.profitfy.me'
    : 'https://rocklee-api.profitfy.me';

export const STORE_CONFIG_API_DOMAIN =
  process.env.REACT_APP_STAGE === 'prod'
    ? 'https://rocklee-api.profitfy.me'
    : 'https://rocklee-api.profitfy.me';

export const WHATSAPP_CONFIG_API_DOMAIN =
  process.env.REACT_APP_STAGE === 'prod'
    ? 'https://himawari-api.profitfy.me'
    : 'https://hml-himawari-api.profitfy.me';
