import { ApiService } from '@services/api';
import { SUGGESTION_API_DOMAIN } from '@constants/api';
import {
  ICreateAnnouncementProps,
  IDeleteAnnouncementProps,
  IUpdateAnnouncementProps,
  IListAnnouncementsResponse,
} from '@domain/interfaces/dashboard/AdminPanel/IAnnouncement';
import { useFetch } from '@providers/Fetch/useFetch';

export class AnnouncementService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listAnnouncements(): IListAnnouncementsResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/announcements`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      announcements: data?.announcements,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public createAnnouncement({ data }: ICreateAnnouncementProps): Promise<any> {
    return this.apiService.post(`/api/v1/announcements`, { ...data });
  }

  public updateAnnouncement({
    announcement_alias_id,
    data,
  }: IUpdateAnnouncementProps): Promise<any> {
    return this.apiService.put(`/api/v1/announcements/${announcement_alias_id}`, { ...data });
  }

  public deleteAnnouncement({ announcement_alias_id }: IDeleteAnnouncementProps): Promise<any> {
    return this.apiService.delete(`/api/v1/announcements/${announcement_alias_id}`);
  }
}

const announcementService = new AnnouncementService(SUGGESTION_API_DOMAIN);

export default announcementService;
