import { AxiosResponse } from 'axios';

import {
  IGenerateBlingUrlProps,
  IGenerateBlingUrlResponse,
  IDisableBlingCredentialProps,
  IEnableBlingCredentialProps,
  IGetBlingCredentialPromiseProps,
  IGetBlingCredentialPromiseResponse,
  IGetBlingCredentialProps,
  IGetBlingCredentialResponse,
} from '@domain/interfaces/dashboard/IntegrationCenter/Ecommerce/Bling/IBlingAuth';

import { ApiService } from '@services/api';

import { useFetch } from '@providers/Fetch/useFetch';

import { API_DOMAIN } from '@constants/api';

export class BlingAuthService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getBlingCredential({
    storeAliasId,
  }: IGetBlingCredentialProps): IGetBlingCredentialResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/bling-credentials`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      blingCredential: data?.bling_credential,
      isLoading,
      isValidating,
      error,
      mutate,
    };
  }

  public getBlingCredentialPromise({
    storeAliasId,
  }: IGetBlingCredentialPromiseProps): Promise<AxiosResponse<IGetBlingCredentialPromiseResponse>> {
    return this.apiService.get(`/api/v1/users/stores/${storeAliasId}/bling-credentials`);
  }

  public generateBlingUrl({
    storeAliasId,
  }: IGenerateBlingUrlProps): Promise<AxiosResponse<IGenerateBlingUrlResponse>> {
    return this.apiService.post<IGenerateBlingUrlResponse>(
      `/api/v1/users/stores/${storeAliasId}/bling/auth/generate-url`,
    );
  }

  public disableBlingCredential({
    storeAliasId,
    blingCredentialAliasId,
  }: IDisableBlingCredentialProps): Promise<AxiosResponse<void>> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/bling-credentials/${blingCredentialAliasId}`,
      { is_active: false },
    );
  }

  public enableBlingCredential({
    storeAliasId,
    blingCredentialAliasId,
  }: IEnableBlingCredentialProps): Promise<AxiosResponse<void>> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/bling-credentials/${blingCredentialAliasId}`,
      { is_active: true },
    );
  }
}

const blingAuthService = new BlingAuthService(API_DOMAIN);

export default blingAuthService;
