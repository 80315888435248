import React from 'react';

import * as S from './styles';

const TermsOfAdhesionContent: React.FC = () => {
  return (
    <>
      <S.Paragraph>
        A adesão aos planos oferecidos pela <strong>PROFITFY TECNOLOGIA LTDA.</strong> (“Profitfy”),
        inscrita no CNPJ/ME sob o n.° 37.365.788/0001-40, com sede na Avenida General Ataliba
        Lionel, n° 1205, 9° andar, sala 92, Santana, São Paulo/SP, CEP 02033-000, está condicionada
        à prévia aceitação e cumprimento de nossos Termos e Condições de Uso e Serviços, Políticas
        de Privacidade e, ainda, os presentes Termos.
      </S.Paragraph>

      <S.Paragraph>
        Em nossa plataforma, o Usuário poderá verificar os planos vigentes para utilização de nossos
        serviços.
      </S.Paragraph>

      <S.Paragraph>
        Cada plano contempla uma gama de serviços oferecidos pela <strong>PROFITFY</strong>, que
        poderá ser alterado unilateralmente por nossa empresa, mediante o prévio aviso ao Usuário, a
        ser enviada ao e-mail cadastrado pelo Usuário na Plataforma <strong>ou</strong> mediante a
        disponibilização de aviso na própria Plataforma. O uso da Plataforma ou contratação dos
        Produtos e Serviços após a entrada em vigor das modificações dos Termos e Condições{' '}
        <strong>
          constituirá aceitação e concordância do Usuário quanto aos novos termos contratuais.
        </strong>
      </S.Paragraph>

      <S.Paragraph>
        É imprescindível que o Usuário verifique qual plano se adéqua melhor à sua necessidade,
        oportunizando uma melhor experiência em nossa plataforma.
      </S.Paragraph>

      <S.Paragraph>
        Nos termos do artigo 49 do Código de Defesa do Consumidor, o Usuário poderá exercer o
        direito de arrependimento, desistindo da assinatura do plano em até 7 (sete) dias a contar
        da data de contratação.
      </S.Paragraph>

      <S.Paragraph>
        Na contratação de planos trimestrais ou semestrais, haverá a concessão de desconto na
        assinatura, bem como nos pedidos extras consumidos durante a vigência do plano contratado,
        conforme previsto na Plataforma, estando vedado o cancelamento da compra após o exaurimento
        do prazo supramencionado para arrependimento.
      </S.Paragraph>

      <S.Subtitle>1. Da troca de plano</S.Subtitle>
      <S.Paragraph>
        O Usuário poderá, a qualquer momento, realizar a troca do plano contratado.{' '}
        <strong>
          Realizada a substituição do plano, a assinatura do Usuário passa a ter como data de
          renovação o dia em que houver a migração solicitada.
        </strong>
      </S.Paragraph>

      <S.Paragraph>Deverão ser obedecidas as seguintes regras para alterações:</S.Paragraph>

      <S.Paragraph>
        <strong>1.1</strong> Downgrade de plano
      </S.Paragraph>

      <S.Paragraph>
        Caso o Usuário decida migrar para um plano com preço inferior ao que se encontra em uso, a{' '}
        <strong>PROFITFY</strong> reserva-se ao direito de compensar os valores divergentes entre os
        planos. Desse modo, solicitado o downgrade, verificar-se-á a disponibilidade de dias não
        utilizados no mês vigente relativos ao plano atual, transformando-os em créditos que serão
        convertidos automática e imediatamente em desconto na cobrança da assinatura do plano
        inferior contratado, não sendo possível deixar créditos em aberto no sistema.
      </S.Paragraph>

      <S.Paragraph>Assim, considerando a seguinte situação hipotética:</S.Paragraph>

      <S.Paragraph bullets>
        O Usuário contrata o plano mensal “A” no valor de R$150,00 no dia 01 de janeiro;
      </S.Paragraph>

      <S.Paragraph bullets>
        Após 15 dias de utilização, decide fazer o downgrade para o plano mensal “B” no valor de R$
        100,00;
      </S.Paragraph>

      <S.Paragraph bullets>
        Para o sistema, será considerado o seguinte cálculo: R$ 150,00 (valor do plano mensal A),
        subtraindo-se R$75,00 (15 dias de uso do plano mensal A), restando em aberto saldo de R$
        75,00 para ser utilizado no novo plano.
      </S.Paragraph>

      <S.Paragraph bullets>
        Tendo em vista a diferença de R$75,00 entre o saldo disponível no sistema para uso no novo
        plano e o valor mensal do plano “B” que será contratado (R$ 100,00), será gerada uma fatura
        de R$ 25,00, para completar o valor a ser pago pela assinatura do novo plano (que valerá de
        15 de janeiro a 14 de fevereiro).
      </S.Paragraph>

      <S.Paragraph bullets>
        No caso de downgrade de planos trimestrais ou semestrais, valerá a mesma regra: o valor
        correspondente aos dias não usados no período a ser finalizado será convertido em créditos,
        que serão imediatamente utilizados em mensalidade(s) do novo plano. Caso os créditos não
        sejam suficientes para abater o valor integral do novo período contratado, será gerada uma
        fatura para cobrança da diferença.
      </S.Paragraph>

      <S.Paragraph>
        <strong>1.1.1</strong> Se no momento do downgrade a conta tiver estourado o limite de
        pedidos do pacote até então contratado, previamente à alteração do plano, será emitida uma
        cobrança dos pedidos extras pendentes de pagamento.
      </S.Paragraph>

      <S.Paragraph>
        <strong>1.2</strong> Upgrade de plano
      </S.Paragraph>

      <S.Paragraph>
        Caso o Usuário decida migrar para um plano com preço superior ao que se encontra em uso, a{' '}
        <strong>PROFITFY</strong> reserva-se ao direito de compensar os valores divergentes entre os
        planos. Desse modo, solicitado o upgrade, verificar-se-á a disponibilidade de dias não
        utilizados no mês vigente relativo ao plano atual, transformando-os em créditos que serão
        convertidos automaticamente em desconto na cobrança da assinatura do plano superior
        contratado.
      </S.Paragraph>

      <S.Paragraph>Assim, considerando a seguinte situação hipotética:</S.Paragraph>

      <S.Paragraph bullets>
        O Usuário contrata o plano mensal “A” no valor de R$150,00 em 01 de janeiro;
      </S.Paragraph>

      <S.Paragraph bullets>
        Após a sua utilização por 15 dias, decide fazer o upgrade para o plano mensal “C” no valor
        de R$ 300,00;
      </S.Paragraph>

      <S.Paragraph bullets>
        Para o sistema será considerado o seguinte cálculo: R$ 150,00 (valor do plano mensal “A”),
        subtraindo-se R$75,00 (15 dias de uso do plano mensal “A”), restando em aberto saldo de R$
        75,00.
      </S.Paragraph>

      <S.Paragraph bullets>
        Tendo em vista a diferença de R$ 225,00 entre o valor do novo plano (R$ 300,00) e o saldo
        disponível no sistema (R$ 75,00), será gerada uma fatura de R$ 225,00 para completar o valor
        a ser pago pela assinatura do novo plano (período de 15 de janeiro a 14 de fevereiro).
      </S.Paragraph>

      <S.Paragraph bullets>
        No caso de upgrade de planos trimestrais ou semestrais, valerá a mesma regra: o valor
        correspondente aos dias não usados no plano a ser finalizado será convertido em créditos,
        que serão imediatamente utilizados em mensalidade(s) do novo plano. Caso os créditos não
        sejam suficientes para abater o valor integral do novo período contratado, será gerada uma
        fatura para cobrança da diferença.
      </S.Paragraph>

      <S.Paragraph>
        <strong>1.2.1</strong> Se no momento do upgrade a conta tiver estourado o limite de pedidos
        do pacote até então contratado, previamente à alteração do plano, será emitida uma cobrança
        dos pedidos extras pendentes de pagamento.
      </S.Paragraph>

      <S.Subtitle>2. Do cancelamento e Renovação da assinatura.</S.Subtitle>
      <S.Paragraph>
        2.1. Ao celebrar este contrato, as partes concordam que a renovação da assinatura será
        automática, nas mesmas condições negociadas e pelo mesmo período contratado, sem a
        necessidade de confirmação por parte do cliente. Caso não queira que a renovação seja feita
        automaticamente, o cancelamento da assinatura deve ser efetuado pelo Cliente antes da
        renovação da assinatura, clicando na opção &quot;Cancelar Assinatura&quot;, disponível na
        aba &quot;Minha Assinatura&quot;, na plataforma.
      </S.Paragraph>

      <S.Paragraph>
        2.2. No caso da contratação da primeira mensalidade, o cliente tem até 7 dias corridos para
        avaliar se irá ou não continuar com o serviço, após esse período ou feita a renovação, não
        será estornado o valor cobrado, por não ser mais um período de avaliação. Neste caso, o
        cliente continuará com acesso até a finalização do período contratado e, para evitar novas
        cobranças, deverá efetuar o cancelamento na aba “Minha Assinatura”.
      </S.Paragraph>

      <S.Subtitle>3. Do limite de Pedidos</S.Subtitle>
      <S.Paragraph>
        Caso o cliente exceda o limite de pedidos disponível em seu plano, um valor por cada pedido
        excedente será cobrado na renovação da assinatura, somando-se ao valor do plano a ser
        renovado.
      </S.Paragraph>

      <S.Paragraph>
        <strong>3.1</strong> O preço de cada pedido extra está disponível na página de planos da
        plataforma e/ou em proposta comercial enviada ao cliente por um assessor de conta.
      </S.Paragraph>

      <S.Paragraph underline>
        Para manifestar sua concordância com a contratação do plano selecionado, o usuário deve
        clicar em &quot;Eu Aceito&quot;, aceitando todas as regras previstas para utilização de
        nossos serviços por meio da plataforma digital. Na hipótese do usuário não concordar com os
        termos de adesão ao plano, este não poderá utilizar nossos serviços.
      </S.Paragraph>

      <S.Paragraph>
        Os presentes Termos de Uso, bem como a utilização da plataforma digital e dos serviços da{' '}
        <strong>PROFITFY</strong> serão regidos pelas leis em vigência na República Federativa do
        Brasil, sendo estipulado, desde já, o foro da cidade de São Paulo/SP como o competente para
        dirimir quaisquer controvérsias ou divergências decorrentes deste Termo de Adesão ao Plano,
        utilização do site e serviços.
      </S.Paragraph>
    </>
  );
};

export default TermsOfAdhesionContent;
