import React from 'react';

import { IOnboardingNotFinishedProps } from '@domain/interfaces/dashboard/AdsManager/UtmsTooltipsContent/IOnboardingNotFinished';

import * as S from './styles';

const OnboardingNotFinished: React.FC<IOnboardingNotFinishedProps> = ({ startUtmOnboarding }) => {
  return (
    <S.Wrapper>
      <S.Title>Veja os dados financeiros de suas campanhas</S.Title>
      <S.Text>
        Faça a instalação das utms para conseguir ver os dados financeiros de suas campanhas.
      </S.Text>
      <S.StartOnboardingButton onClick={startUtmOnboarding}>
        Saber lucro por campanha
      </S.StartOnboardingButton>
    </S.Wrapper>
  );
};

export default OnboardingNotFinished;
