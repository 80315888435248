import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.button`
  position: relative;
  width: 324px;
  height: 319px;
  border-radius: 12px;
  padding: unset;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border: unset;
  box-sizing: border-box;
  transition: all 0.3s;
  cursor: pointer;

  @media only screen and (max-width: 840px) {
    width: 100%;
  }
`;

export const Infos = styled.div`
  width: 100%;
  background: transparent;
  height: 91px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  padding: 16px;
  box-sizing: border-box;
`;

export const BadgeTagsAndChannelsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Name = styled(TextBase)`
  color: #ffffff;
  font-size: 1.125rem;
  line-height: 120%;
`;
