import styled, { css } from 'styled-components/macro';

import {
  ITableHeadProps,
  ITableDataProps,
} from '@domain/interfaces/dashboard/CustomValues/IBillingTable';
import { EHeadAlign } from '@domain/enums/components/ETable';
import zIndex from '@styles/themes/common/zIndex';

export const CategoryWrapper = styled.div`
  max-width: 104px;
  width: 100%;
`;

export const ValueWrapper = styled.div`
  max-width: 76px;
  width: 100%;
`;

export const DateWrapper = styled.div`
  max-width: 82px;
  width: 100%;
`;

export const TableData = styled.td<ITableDataProps>`
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.font};

  ${({ textAlign }) =>
    textAlign === EHeadAlign.CENTER &&
    css`
      text-align: center;
    `};

  ${({ textAlign }) =>
    textAlign === EHeadAlign.RIGHT &&
    css`
      text-align: right;
    `};
`;

export const TableHead = styled.th<ITableHeadProps>`
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.font};
  text-align: left;

  ${({ textAlign }) =>
    textAlign === EHeadAlign.CENTER &&
    css`
      text-align: center;
    `};

  ${({ textAlign }) =>
    textAlign === EHeadAlign.RIGHT &&
    css`
      text-align: right;
    `};
`;

export const TableRow = styled.tr``;

export const TableBody = styled.tbody`
  & > tr {
    background-color: ${({ theme }) => theme.colors.background[8]};

    & > td {
      padding: 12px 128px;
    }

    & > td:first-child {
      padding-left: 120px;
    }

    & > td:last-child {
      padding-right: 120px;
    }
  }
`;

export const TableHeader = styled.thead`
  box-shadow: ${({ theme }) => `inset 0px 0px 0px 1px ${theme.colors.gray[4]}`};
  background-color: ${({ theme }) => theme.colors.background[1]};

  & > tr {
    & > th {
      padding: 12px 128px;
    }

    & > th:first-child {
      padding-left: 120px;
    }

    & > th:last-child {
      padding-right: 120px;
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  min-width: max-content;
  border-collapse: collapse;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray[4]}`};
`;

export const ChevronButton = styled.button`
  position: absolute;
  top: -16px;
  right: 36px;
  height: 40px;
  width: 40px;
  background-color: ${({ theme }) => theme.colors.background[8]};
  border: 1px solid ${({ theme }) => theme.colors.gray[1]};
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s;
  z-index: ${zIndex.firstLayer};

  &:hover {
    filter: brightness(1.1);
  }

  &:hover {
    filter: brightness(0.9);
  }
`;

export const TableWrapper = styled.div`
  position: relative;
  max-height: 170px;
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  bottom: -6px;

  &::-webkit-scrollbar {
    height: 6px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.background[3]};
    border-radius: 25px;
    margin-bottom: 4px;
  }
`;
