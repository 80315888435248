import React from 'react';
import { Check } from '@profitfy/pakkun-icons';
import { Pen } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { EPlanRecurrence } from '@domain/enums/subscription/ESubscription';
import { ECouponType } from '@domain/enums/common/coupon/ECouponType';
import { IParams } from '@domain/interfaces/IParams';

import { useCheckout } from '@helpers/hooks/pages/subscription/useCheckout';
import { useAddress } from '@helpers/hooks/common/store/address/useAddress';
import { usePersonalInfo } from '@helpers/hooks/common/store/personalInfo/usePersonalInfo';
import { useConfig } from '@helpers/hooks/useConfig';
import { useCoupon } from '@helpers/hooks/pages/subscription/useCoupon';

import {
  cnpjFormatter,
  cpfFormatter,
  numberFormatter,
  phone,
  zipCodeFormatter,
} from '@helpers/masks';

import { brazilianStates } from '@constants/brazilianStates';

import LoadingScreen from '@components/common/core/Utils/LoadingScreen';

import * as S from './styles';

const Overview: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { address } = useAddress();
  const { legalPerson, naturalPerson } = usePersonalInfo();
  const { analytics, user } = useConfig();
  const { selectedCoupon } = useCoupon();
  const {
    recurrenceType,
    cardToken,
    selectedPlan,
    selectedInstallment,
    isSubscribing,
  } = useCheckout();

  const [isHoveringAddressCard, setIsHoveringAddressCard] = React.useState<boolean>(false);
  const [isHoveringPersonalInfoCard, setIsHoveringPersonalInfoCard] = React.useState<boolean>(
    false,
  );
  const [isHoveringPaymentCard, setIsHoveringPaymentCard] = React.useState<boolean>(false);

  const handleHoveringAddressCard = React.useCallback(state => {
    setIsHoveringAddressCard(state);
  }, []);

  const handleHoveringPersonalInfoCard = React.useCallback(state => {
    setIsHoveringPersonalInfoCard(state);
  }, []);

  const handleHoveringPaymentCard = React.useCallback(state => {
    setIsHoveringPaymentCard(state);
  }, []);

  const onAddressCardClick = React.useCallback(() => {
    history.push(`/${storeAliasId}/subscription/address${window.location.search}`);
  }, [history, storeAliasId]);

  const onPersonalInfoCardClick = React.useCallback(() => {
    history.push(`/${storeAliasId}/subscription/personal-info${window.location.search}`);
  }, [history, storeAliasId]);

  const onPaymentCardClick = React.useCallback(() => {
    history.push(`/${storeAliasId}/subscription/payment${window.location.search}`);
  }, [history, storeAliasId]);

  const getEditIconColor = React.useCallback(
    isHovering => {
      if (isHovering) return theme.colors.green.default;

      return theme.colors.font;
    },
    [theme],
  );

  const getCardInformation = React.useCallback(() => {
    const cardNumber = cardToken?.extra_info.display_number || '';
    const splittedCardNumber = cardNumber.split('-');
    const lastCardDigits = splittedCardNumber.pop();

    return `${cardToken?.extra_info.brand} **** **** **** ${lastCardDigits}`;
  }, [cardToken]);

  const getTotalPrice = React.useCallback(() => {
    if (recurrenceType === EPlanRecurrence.MONTHLY) return selectedPlan?.monthlyPrice || 0;

    if (recurrenceType === EPlanRecurrence.QUARTER) return selectedPlan?.quarterPrice || 0;

    return selectedPlan?.semesterPrice || 0;
  }, [recurrenceType, selectedPlan]);

  const getCouponDiscount = React.useCallback(() => {
    if (!selectedCoupon) return 0;

    const totalPrice = getTotalPrice();

    if (selectedCoupon.type === ECouponType.FIXED && selectedCoupon.amount <= totalPrice)
      return selectedCoupon.amount;

    if (selectedCoupon.type === ECouponType.FIXED && selectedCoupon.amount > totalPrice)
      return totalPrice;

    return (totalPrice * selectedCoupon.amount) / 100;
  }, [selectedCoupon, getTotalPrice]);

  const getTotalPriceValue = React.useCallback(() => {
    const couponDiscount = selectedCoupon ? getCouponDiscount() : 0;

    if (recurrenceType === EPlanRecurrence.MONTHLY)
      return (selectedPlan?.monthlyPrice || 0) - couponDiscount;

    if (recurrenceType === EPlanRecurrence.QUARTER)
      return (selectedPlan?.quarterPrice || 0) - couponDiscount;

    return (selectedPlan?.semesterPrice || 0) - couponDiscount;
  }, [recurrenceType, selectedPlan, selectedCoupon, getCouponDiscount]);

  const getPaymentInfo = React.useCallback(() => {
    return `Você pagará ${selectedInstallment}x de R$${numberFormatter(
      getTotalPriceValue() / selectedInstallment,
      2,
    )}`;
  }, [selectedInstallment, getTotalPriceValue]);

  React.useEffect(() => {
    analytics?.track(
      'Subscription Checkout Overview Viewed',
      {
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );
  }, [analytics, user, storeAliasId]);

  React.useEffect(() => {
    if (!cardToken) {
      history.push(`/${storeAliasId}/subscription/payment${window.location.search}`);
    }
  }, [cardToken, history, storeAliasId]);

  const foundState = brazilianStates.find(
    state => state.abbreviation === (address.state_code || 'SP'),
  );

  return (
    <S.Wrapper>
      {isSubscribing && <LoadingScreen showChargingSubscriptionText />}

      <S.AddressWrapper>
        <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
          Endereço
        </S.Title>

        <S.FormOverviewWrapper
          onClick={onAddressCardClick}
          onMouseEnter={() => handleHoveringAddressCard(true)}
          onMouseLeave={() => handleHoveringAddressCard(false)}
        >
          <Check size={24} outline />
          <S.ContentWrapper>
            <S.ContentTitle type={EHeadingSize.H6} fontWeight={EHeadingWeight.REGULAR}>
              {`${address.street}, ${address.street_number}`}
            </S.ContentTitle>
            <S.ContentText>{`${address.neighborhood} | CEP ${zipCodeFormatter(
              address.zip_code,
            )}`}</S.ContentText>
            <S.ContentText>{`${foundState?.name} - ${foundState?.abbreviation}`}</S.ContentText>
          </S.ContentWrapper>
          <Pen size={24} color={getEditIconColor(isHoveringAddressCard)} />
        </S.FormOverviewWrapper>
      </S.AddressWrapper>

      <S.PersonalInfoAndPaymentWrapper>
        <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
          Dados pessoais e de pagamento
        </S.Title>

        <S.FormOverviewWrapper
          onClick={onPersonalInfoCardClick}
          onMouseEnter={() => handleHoveringPersonalInfoCard(true)}
          onMouseLeave={() => handleHoveringPersonalInfoCard(false)}
        >
          <Check size={24} outline />
          <S.ContentWrapper>
            <S.ContentTitle type={EHeadingSize.H6} fontWeight={EHeadingWeight.REGULAR}>
              Dados pessoais
            </S.ContentTitle>
            <S.ContentText>
              {legalPerson ? cnpjFormatter(legalPerson.cnpj) : cpfFormatter(naturalPerson.cpf)}
            </S.ContentText>
            {legalPerson && <S.ContentText>{legalPerson.company_name}</S.ContentText>}
            <S.ContentText>
              {legalPerson
                ? phone(`${legalPerson.phone_prefix}${legalPerson.phone_number}`)
                : phone(`${naturalPerson.phone_prefix}${naturalPerson.phone_number}`)}
            </S.ContentText>
          </S.ContentWrapper>
          <Pen size={24} color={getEditIconColor(isHoveringPersonalInfoCard)} />
        </S.FormOverviewWrapper>

        <S.FormOverviewWrapper
          onClick={onPaymentCardClick}
          onMouseEnter={() => handleHoveringPaymentCard(true)}
          onMouseLeave={() => handleHoveringPaymentCard(false)}
        >
          <Check size={24} outline />
          <S.ContentWrapper>
            <S.ContentTitle type={EHeadingSize.H6} fontWeight={EHeadingWeight.REGULAR}>
              Pagamento
            </S.ContentTitle>
            <S.ContentText>{getCardInformation()}</S.ContentText>
            <S.ContentText>{getPaymentInfo()}</S.ContentText>
          </S.ContentWrapper>
          <Pen size={24} color={getEditIconColor(isHoveringPaymentCard)} />
        </S.FormOverviewWrapper>
      </S.PersonalInfoAndPaymentWrapper>

      <S.NextStepButton>Confirmar Pagamento</S.NextStepButton>
    </S.Wrapper>
  );
};

export default Overview;
