import { ApiService } from '@services/api';
import { LONG_API_DOMAIN } from '@constants/api';
import {
  ISyncGoogleAccountsProps,
  IUpdateGoogleAccountProps,
} from '@domain/interfaces/dashboard/AdSense/Google/IGoogleAccount';

export class GoogleAccountService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public syncGoogleAccounts({
    adSenseCredentialAliasId,
    storeAliasId,
  }: ISyncGoogleAccountsProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/ad-sense-credentials/${adSenseCredentialAliasId}/google/ad-sense-accounts/sync`,
    );
  }

  public updateGoogleAccount({
    adSenseAccountAliasId,
    adSenseCredentialAliasId,
    data,
    storeAliasId,
  }: IUpdateGoogleAccountProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/ad-sense-credentials/${adSenseCredentialAliasId}/google/ad-sense-accounts/${adSenseAccountAliasId}`,
      { ...data },
    );
  }
}

const googleAccountService = new GoogleAccountService(LONG_API_DOMAIN);

export default googleAccountService;
