import styled from 'styled-components/macro';

export const Container = styled.div`
  max-width: 1262px;
  width: 100%;
`;

export const OverviewCardsWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 8px;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 32px;

  @media only screen and (max-width: 1282px) {
    flex-wrap: wrap;

    & > div {
      max-width: 49%;
      width: 100%;
    }
  }

  @media only screen and (max-width: 976px) {
    & > div {
      max-width: unset;
    }
  }
`;

export const OverviewCard = styled.div`
  min-width: 264px;
  width: 100%;
  height: 96px;
`;

export const SectionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;

  @media only screen and (max-width: 1124px) {
    flex-direction: column;
  }
`;

export const FirstSection = styled.div`
  max-width: 848px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media only screen and (max-width: 1342px) {
    max-width: 728px;
  }

  @media only screen and (max-width: 1216px) {
    max-width: 624px;
  }

  @media only screen and (max-width: 1124px) {
    max-width: unset;
  }
`;

export const SecondSection = styled.div`
  max-width: 398px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;

  @media only screen and (max-width: 1124px) {
    max-width: unset;
    flex-direction: row;
  }

  @media only screen and (max-width: 632px) {
    flex-direction: column;
  }
`;

export const ProfitCard = styled.div`
  height: 229px;

  @media only screen and (max-width: 1124px) {
    margin-bottom: 24px;
  }
`;

export const SecondSectionCard = styled.div`
  width: 100%;
  height: 203px;
`;

export const FunnelChart = styled.div`
  max-width: 848px;
  width: 100%;
  height: 378px;

  @media only screen and (max-width: 1124px) {
    max-width: unset;
  }
`;

export const MetricsCardWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding-bottom: 8px;
  align-items: center;
  justify-content: space-between;
  overflow-y: hidden;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
`;

export const MetricCard = styled.div`
  min-width: 154px;
  height: 60px;
`;

export const PaymentMethodsCardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  padding-bottom: 8px;
  overflow-y: hidden;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
`;

export const PaymentMethodsCard = styled.div`
  min-width: 270px;
  height: 205px;
`;
