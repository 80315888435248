import styled, { css } from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import NoImageBase from '@components/common/core/DataDisplay/NoImage';
import TextBase from '@components/common/core/DataDisplay/Text';
import LinkBase from '@components/common/core/Navigation/Link';
import DropDownBase from '@components/common/core/Utils/DropDown';
import TableBase from '@components/common/core/Utils/Table';
import TooltipBase from '@components/common/core/Utils/Tooltip';
import { IActionButtonProps } from '@domain/interfaces/components/IActionButton';
import { IActionsButtonProps } from '@domain/interfaces/dashboard/ProductCost/IActionsButton';

export const Tooltip = styled(TooltipBase)``;

export const SynchronizationIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray[5]};
`;

export const MenuDropDown = styled(DropDownBase)``;

export const NoImage = styled(NoImageBase)`
  background-color: ${({ theme }) => theme.colors.background[3]};
`;

export const ProviderImage = styled(ImageBase)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 6px;
  background-color: ${({ theme }) => theme.colors.background[3]};
`;

export const ProviderWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.background[3]};
`;

export const ProductCostTable = styled(TableBase)``;

export const ProductDetailsWrapper = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
`;

export const Image = styled(ImageBase)`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

export const ProductDetailsText = styled(TextBase)`
  max-width: 300px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
`;

export const Dot = styled.span`
  display: block;
  width: 4px;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.gray[2]};
  border-radius: 50%;
`;

export const ActionsButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const ActionsButton = styled.div<IActionsButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  opacity: 0;
  transition: filter 0.3s;
  border: unset;
  background-color: unset;
  padding: unset;

  ${({ isHovering }) =>
    isHovering &&
    css`
      opacity: 1;
    `}

  & > button:last-child {
    padding-top: 2px;
    padding-left: 2px;
  }
`;

export const ActionsWrapper = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
`;

export const DeleteButton = styled.button<IActionButtonProps>`
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: unset;
  transition: color 0.3s;
  position: relative;
  width: 100%;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;

  ${({ isDeleteButton, theme }) =>
    isDeleteButton &&
    css`
      color: ${theme.colors.danger.default};
    `}

  &:hover {
    filter: brightness(1.1);
    background-color: ${({ theme }) => theme.colors.gray[4]};
  }

  &:active {
    filter: brightness(0.9);
    background-color: ${({ theme }) => theme.colors.gray[4]};
  }
`;

export const ActionButton = styled(LinkBase)`
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: unset;
  transition: color 0.3s;
  position: relative;
  width: 100%;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  align-self: unset;

  &:hover {
    filter: brightness(1.1);
    background-color: ${({ theme }) => theme.colors.gray[4]};
  }

  &:active {
    filter: brightness(0.9);
    background-color: ${({ theme }) => theme.colors.gray[4]};
  }
`;

export const ActionDeleteButton = styled.div`
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: unset;
  transition: color 0.3s;
  position: relative;
  width: 100%;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  align-self: unset;

  &:hover {
    filter: brightness(1.1);
    background-color: ${({ theme }) => theme.colors.gray[4]};
  }

  &:active {
    filter: brightness(0.9);
    background-color: ${({ theme }) => theme.colors.gray[4]};
  }
`;

export const AccessibleActionsButtonName = styled.span`
  position: absolute;
  opacity: 0;
  width: 0;
`;
