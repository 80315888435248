import {
  IData,
  IResponseData,
  ISummaryData,
  IFinancialSummaryData,
} from '@domain/interfaces/dashboard/Chart/IChart';

const convertData = (data: Array<IResponseData>): Array<IData> => {
  const newData = data.map(dataValue => {
    const [, month, day] = dataValue.date.split('-');

    return {
      name: `${day}/${month}`,
      value: dataValue.value,
    };
  });

  return newData;
};

const convertNetRevenueData = (data: Array<IResponseData>): any => {
  const newData = data.map(dataValue => {
    const [, month, day] = dataValue.date.split('-');

    return {
      name: `${day}/${month}`,
      value: dataValue.net_revenue.value,
    };
  });

  return newData;
};

const convertCogsData = (data: Array<IResponseData>): any => {
  const newData = data.map(dataValue => {
    const [, month, day] = dataValue.date.split('-');

    return {
      name: `${day}/${month}`,
      delivered: dataValue.delivered.value,
      pending: dataValue.pending.value,
    };
  });

  return newData;
};

const convertMarketingData = (data: Array<IResponseData>): any => {
  const newData = data.map(dataValue => {
    const marketingObject = {};

    Object.entries(dataValue).forEach(([key, value]) => {
      if (key !== 'date' && key !== 'total') {
        marketingObject[key] = value.value;
      }
    });

    const [, month, day] = dataValue.date.split('-');

    return {
      name: `${day}/${month}`,
      ...marketingObject,
    };
  });

  return newData;
};

const convertFeeData = (data: Array<IResponseData>): any => {
  const newData = data.map(dataValue => {
    const [, month, day] = dataValue.date.split('-');

    return {
      name: `${day}/${month}`,
      checkout_fee: dataValue.checkout_fee.value,
      gateway_fee: dataValue.gateway_fee.value,
      marketplace_fee: dataValue.marketplace_fee.value,
      tax: dataValue.tax.value,
    };
  });

  return newData;
};

const convertFinancialSummaryData = (data: Array<IFinancialSummaryData>): Array<ISummaryData> => {
  const newData = data.map(dataValue => {
    const [, month, day] = dataValue.date.split('-');

    return {
      name: `${day}/${month}`,
      gateway_revenue: dataValue.net_revenue.value,
      cogs: dataValue.cogs.value,
      marketing: dataValue.marketing.value,
      fee: dataValue.fee.value,
      net_profit: dataValue.net_profit.value,
    };
  });

  return newData;
};

export const parseData = {
  net_revenue: (data: any): Array<IData> => {
    return convertNetRevenueData(data.net_revenue_chart);
  },
  cogs: (data: any): Array<IData> => {
    return convertCogsData(data.cogs_chart);
  },
  marketing: (data: any): Array<IData> => {
    return convertMarketingData(data.marketing_chart);
  },
  taxes: (data: any): Array<IData> => {
    return convertFeeData(data.fee_chart);
  },
  profit: (data: any): Array<IData> => {
    return convertData(data.net_profit_chart);
  },
  revenue: (data: any): Array<ISummaryData> => {
    return convertFinancialSummaryData(data.financial_summary_chart);
  },
};
