import React from 'react';
import { Eduzz as EduzzIcon, Copy } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { EInfoProductProvider } from '@domain/enums/common/integrations/EInfoProductProvider';
import { IParams } from '@domain/interfaces/IParams';
import { IEduzzCredential } from '@domain/interfaces/dashboard/IntegrationCenter/InfoProduct/Platforms/IEduzzAuth';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useToast } from '@helpers/hooks/useToast';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { eduzzSchema } from '@helpers/validators/dashboard/integrationCenter/infoProduct/eduzz';

import eduzzService from '@services/pages/dashboard/integrationCenter/infoProduct/platforms/eduzz';

import ActiveIntegrationFooter from '@components/Dashboard/IntegrationCenter/Sidemodal/ActiveIntegrationFooter';
import Text from '@components/common/core/DataDisplay/Text';
import EduzzConfig from './EduzzConfig';
import ActiveIntegration from './ActiveIntegration';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Eduzz: React.FC = () => {
  const theme = useTheme();

  const { mutateStore, storeIntegrations } = useStoreConfig();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(eduzzSchema),
  });

  const [apiKeyValue, setClientIdValue] = React.useState<string>('');
  const [publicKeyValue, setPublicKeyValue] = React.useState<string>('');
  const [originKeyValue, setOriginKeyValue] = React.useState<string>('');
  const [emailValue, setEmailValue] = React.useState<string>('');
  const [webhookURL, setWebhookURL] = React.useState<string>('');
  const [isGettingCredential, setIsGettingCredential] = React.useState<boolean>(false);
  const [isGettingWebhookURL, setIsGettingWebhookURL] = React.useState<boolean>(false);
  const [hasEduzzCredential, setHasEduzzCredential] = React.useState<boolean>(false);
  const [isURLCopied, setIsURLCopied] = React.useState<boolean>(false);
  const [isCreatingCredential, setIsCreatingCredential] = React.useState<boolean>(false);
  const [credential, setCredential] = React.useState<IEduzzCredential>({} as IEduzzCredential);

  const hasEduzzIntegration = Boolean(
    storeIntegrations?.store_front.find(
      integration => integration.name === EInfoProductProvider.EDUZZ && integration.is_active,
    ),
  );

  const apiKeyInputRegister = register('api_key');
  const publicKeyInputRegister = register('public_key');
  const originKeyInputRegister = register('origin_key');
  const emailInputRegister = register('email');

  const onApiKeyChange = React.useCallback(
    event => {
      apiKeyInputRegister.onChange(event);

      setClientIdValue(event.target.value);
    },
    [apiKeyInputRegister],
  );

  const onPublicKeyChange = React.useCallback(
    event => {
      publicKeyInputRegister.onChange(event);

      setPublicKeyValue(event.target.value);
    },
    [publicKeyInputRegister],
  );

  const onOriginKeyChange = React.useCallback(
    event => {
      originKeyInputRegister.onChange(event);

      setOriginKeyValue(event.target.value);
    },
    [originKeyInputRegister],
  );

  const onEmailChange = React.useCallback(
    event => {
      emailInputRegister.onChange(event);

      setEmailValue(event.target.value);
    },
    [emailInputRegister],
  );

  const handleCopyURL = React.useCallback(() => {
    navigator.clipboard.writeText(webhookURL);
    setIsURLCopied(true);
    toast.success('URL copiada com sucesso.');
  }, [toast, webhookURL]);

  const onSubmit = React.useCallback(
    async formData => {
      setIsCreatingCredential(true);

      try {
        await eduzzService.createCredential({ storeAliasId, data: { ...formData } });

        toast.success('Credencial criada com sucesso.');

        mutateStore();

        setIsCreatingCredential(false);
      } catch (error: any) {
        toast.error(getTranslatedIntegrationsError(error));
        setIsCreatingCredential(false);
      }
    },
    [mutateStore, storeAliasId, toast],
  );

  const getEduzzCredential = React.useCallback(async () => {
    setIsGettingCredential(true);

    try {
      const { data } = await eduzzService.getCredential({ storeAliasId });

      if (data?.eduzz_credential.is_active) {
        setHasEduzzCredential(true);
      }

      const { alias_id, api_key, origin_key, public_key } = data?.eduzz_credential;

      setCredential({ alias_id, api_key, origin_key, public_key });
      setIsGettingCredential(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsGettingCredential(false);
    } finally {
      setIsGettingCredential(false);
    }
  }, [toast, storeAliasId]);

  const generateWebhookURL = React.useCallback(async () => {
    setIsGettingWebhookURL(true);

    try {
      const { data } = await eduzzService.generateWebhookURL({ storeAliasId });

      setWebhookURL(data?.webhook_url);
      setIsGettingWebhookURL(false);
    } catch (error: any) {
      setIsGettingWebhookURL(false);
      toast.error(error?.response?.data?.message);
    }
  }, [toast, storeAliasId]);

  React.useEffect(() => {
    if (hasEduzzIntegration) {
      getEduzzCredential();
    } else {
      generateWebhookURL();
    }
  }, [getEduzzCredential, hasEduzzIntegration, generateWebhookURL]);

  if (isGettingCredential || isGettingWebhookURL) {
    return <SkeletonLoading />;
  }

  if (hasEduzzIntegration && !hasEduzzCredential) {
    return <ActiveIntegration credential={credential} />;
  }

  if (hasEduzzCredential) {
    return <EduzzConfig credential={credential} />;
  }

  const hasAllInputsValues =
    Boolean(apiKeyValue) &&
    Boolean(publicKeyValue) &&
    Boolean(originKeyValue) &&
    Boolean(emailValue);

  return (
    <S.ContentWrapper>
      <S.HeadingWrapper>
        <EduzzIcon size={52} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com Eduzz
        </S.Heading>
      </S.HeadingWrapper>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>
        <S.FunctionalityDescriptionText>
          A integração com sua plataforma de info-produto serve para puxarmos seus dados de
          plataforma aumentando a exatidão dos seus dados.
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <S.TutorialWrapper>
        <S.TutotialTitle weight={ETextWeight.BOLD}>Tutorial de integração</S.TutotialTitle>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          1 - Copie a URL no campo abaixo.
        </S.TutotialStep>

        <S.WebhookURLWrapper onClick={handleCopyURL} type="button">
          <S.WebhookURL weight={ETextWeight.MEDIUM}>{webhookURL}</S.WebhookURL>
          <S.IconWrapper>
            <Copy size={18} color={theme.colors.gray[1]} outline={!isURLCopied} />
          </S.IconWrapper>
        </S.WebhookURLWrapper>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          2 -{' '}
          <S.Link href="https://orbita.eduzz.com/producer/webhook" target="_blank">
            Clique aqui e vá para a página de webhooks da Eduzz.
          </S.Link>
        </S.TutotialStep>

        <S.StepsWrapper>
          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              No canto superior direito, clique em &quot;NOVA URL&quot;.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              Preencha o campo &quot;Nome&quot; como desejar.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              No campo &quot;Tipo&quot;, selecione &quot;Fatura&quot;.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              No campo &quot;Produto&quot;, selecione &quot;Todos&quot;.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              No campo &quot;URL&quot;, cole a URL gerada acima.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              Para finalizar, clique no botão &quot;CRIAR&quot;.
            </S.StepText>
          </S.StepWrapper>
        </S.StepsWrapper>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          3 -{' '}
          <S.Link href="https://orbita.eduzz.com/producer/config-api" target="_blank">
            Após criar o webhook, clique aqui e vá para a página de credenciais da Eduzz.
          </S.Link>
        </S.TutotialStep>

        <S.StepsWrapper>
          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              Copie os campos, &quot;Public Key&quot;, &quot;API Key&quot;, &quot;Origin Key&quot;.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>Cole nos respectivos campos abaixo.</S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              No campo &quot;Email&quot;, preencha com o email cadastrado na Eduzz.
            </S.StepText>
          </S.StepWrapper>
        </S.StepsWrapper>

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label htmlFor="public_key">Chave Pública</S.Label>
            <S.Input
              {...publicKeyInputRegister}
              id="public_key"
              type="text"
              value={publicKeyValue}
              placeholder="Eduzz Public Key"
              onChange={onPublicKeyChange}
              isError={errors.public_key}
            />
            {errors.public_key && <Text isErrorFeedback>{errors.public_key.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label htmlFor="api_key">Chave de API</S.Label>
            <S.Input
              {...apiKeyInputRegister}
              id="api_key"
              type="text"
              value={apiKeyValue}
              placeholder="Eduzz API Key"
              onChange={onApiKeyChange}
              isError={errors.api_key}
            />
            {errors.api_key && <Text isErrorFeedback>{errors.api_key.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label htmlFor="origin_key">Chave de Origem</S.Label>
            <S.Input
              {...originKeyInputRegister}
              id="origin_key"
              type="text"
              value={originKeyValue}
              placeholder="Eduzz Origin Key"
              onChange={onOriginKeyChange}
              isError={errors.origin_key}
            />
            {errors.origin_key && <Text isErrorFeedback>{errors.origin_key.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label htmlFor="email">Email</S.Label>
            <S.Input
              {...emailInputRegister}
              id="email"
              type="text"
              value={emailValue}
              placeholder="Email"
              onChange={onEmailChange}
              isError={errors.email}
            />
            {errors.email && <Text isErrorFeedback>{errors.email.message}</Text>}
          </S.InputGroup>

          <ActiveIntegrationFooter
            isIntegrating={isCreatingCredential}
            disabled={isCreatingCredential || !hasAllInputsValues}
          />
        </S.Form>
      </S.TutorialWrapper>
    </S.ContentWrapper>
  );
};

export default Eduzz;
