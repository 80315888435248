import React from 'react';

import { EBadgeColors } from '@domain/enums/components/EBadge';
import { ESyncType } from '@domain/enums/dashboard/adsManager/EScriptAndUtms';

import { useUtms } from '@helpers/hooks/pages/dashboard/adsManager/useUtms';

import * as S from './styles';

const ScheduleContent: React.FC = () => {
  const { handleSyncType } = useUtms();

  return (
    <S.Wrapper>
      <S.Title>Adicionar horário para sincronizar UTMs</S.Title>
      <S.Description>
        Recomendamos que faça analise dos anúncios <strong>durante a noite</strong>, quando tem
        menos visitas em sua loja. Os anúncios podem ficar em análise por{' '}
        <strong>algumas horas/minutos.</strong>
      </S.Description>

      <S.InputGroup>
        <S.Label>Escolher opção para sincronizar</S.Label>
        <S.RadioLabel>
          <S.RadioInput
            value={ESyncType.SCHEDULE}
            defaultChecked
            name="sync-type"
            onChange={handleSyncType}
            id="schedule-sync"
          />
          <S.OptionText>Programar para sincronizar às 00:00</S.OptionText>
          <S.Badge color={EBadgeColors.PRIMARY}>Recomendado</S.Badge>
        </S.RadioLabel>

        <S.RadioLabel>
          <S.RadioInput
            value={ESyncType.SYNC_NOW}
            name="sync-type"
            onChange={handleSyncType}
            id="sync-now"
          />
          <S.OptionText>Sincronizar agora</S.OptionText>
        </S.RadioLabel>
      </S.InputGroup>
    </S.Wrapper>
  );
};

export default ScheduleContent;
