import { v4 } from 'uuid';

import {
  ESimpleShipmentProductOptions,
  ESimpleShipmentType,
} from '@domain/enums/dashboard/automations/node/conditionalNode/ESimpleShipment';

export const DEFAULT_SIMPLE_SHIPMENT_CONTENT = {
  id: v4(),
  type: ESimpleShipmentType.PRODUCT_NAME,
  options: ESimpleShipmentProductOptions.CONTAINS,
  input: '',
};
