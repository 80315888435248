import React from 'react';

import { FlowArrow, DotsThree, Plugs, Pencil, Trash } from 'phosphor-react';
import { useTheme } from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';

import { EBadgeColors } from '@domain/enums/components/EBadge';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { IParams } from '@domain/interfaces/IParams';
import { IInstanceProps } from '@domain/interfaces/dashboard/WhatsappInstance/IWhatsappInstance';

import { useWhatsappInstance } from '@helpers/hooks/pages/dashboard/whatsappInstance/useWhatsappInstance';
import { useConfig } from '@helpers/hooks/useConfig';
import { useToast } from '@helpers/hooks/useToast';

import whatsappCredentialService from '@services/pages/dashboard/whatsappInstance/whatsappCredential';

import WhatsappLogoImg from '@assets/dashboard/whatsapp/whatsapp-logo.svg';
import UpdateInstanceModal from '../UpdateInstanceModal';
import DeleteInstanceModal from '../DeleteInstanceModal';

import * as S from './styles';

const Instance: React.FC<IInstanceProps> = ({ instance, metrics }) => {
  const theme = useTheme();
  const history = useHistory();

  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { analytics } = useConfig();

  const switchRef = React.useRef<HTMLInputElement>(null);

  const {
    isUpdateInstanceModalOpen,
    isDeleteInstanceModalOpen,
    handleUpdateInstanceModalOpen,
    handleDeleteInstanceModalOpen,
    mutateWhatsappCredential,
  } = useWhatsappInstance();

  const [isInstanceHovering, setIsInstanceHovering] = React.useState<boolean>(false);
  const [isUpdateError, setIsUpdateError] = React.useState<boolean>(false);
  const [isUpdatingInstancePopoverOpen, setIsUpdatingInstancePopoverOpen] = React.useState<boolean>(
    false,
  );

  const handleUpdatingInstancePopoverOpen = React.useCallback(() => {
    setIsUpdatingInstancePopoverOpen(currentState => !currentState);
  }, []);

  const activeCredential = React.useCallback(
    async credentialAliasId => {
      try {
        await whatsappCredentialService.updateCredential({
          storeAliasId,
          credentialAliasId,
          data: {
            is_active: true,
            name: undefined,
          },
        });

        toast.success('Instância ativada com sucesso.');

        mutateWhatsappCredential();
      } catch (error: any) {
        setIsUpdateError(true);
        toast.error(error?.response?.data?.message);
      }
    },
    [toast, mutateWhatsappCredential, storeAliasId],
  );

  const deactiveCredential = React.useCallback(
    async credentialAliasId => {
      try {
        await whatsappCredentialService.updateCredential({
          storeAliasId,
          credentialAliasId,
          data: {
            is_active: false,
            name: undefined,
          },
        });

        toast.success('Instância desativada com sucesso.');

        mutateWhatsappCredential();
      } catch (error: any) {
        setIsUpdateError(true);
        toast.error(error?.response?.data?.message);
      }
    },
    [toast, storeAliasId, mutateWhatsappCredential],
  );

  const onSwitchChange = React.useCallback(
    event => {
      if (event.target.checked) {
        activeCredential(instance.alias_id);
      } else {
        deactiveCredential(instance.alias_id);
      }
    },
    [activeCredential, deactiveCredential, instance],
  );

  const onMouseEnter = React.useCallback(() => {
    setIsInstanceHovering(true);
  }, []);

  const onMouseLeave = React.useCallback(() => {
    setIsInstanceHovering(false);
  }, []);

  const onEdit = React.useCallback(() => {
    handleUpdateInstanceModalOpen();
    handleUpdatingInstancePopoverOpen();
    setIsInstanceHovering(false);
  }, [handleUpdateInstanceModalOpen, handleUpdatingInstancePopoverOpen]);

  const onViewAutomations = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/marketing/automations`);
  }, [history, storeAliasId]);

  const onRemove = React.useCallback(() => {
    handleDeleteInstanceModalOpen();
    handleUpdatingInstancePopoverOpen();
    setIsInstanceHovering(false);
  }, [handleUpdatingInstancePopoverOpen, handleDeleteInstanceModalOpen]);

  const onReconnect = React.useCallback(() => {
    analytics?.track('Whatsapp Instance Reconnect', {}, { context: { groupId: storeAliasId } });

    history.push(`messages-instances/new-instance?reconnect=true`);
  }, [history, analytics, storeAliasId]);

  const isConnected = instance.is_connected;

  const badgeColor = isConnected ? EBadgeColors.GREEN : EBadgeColors.RED;
  const badgeText = isConnected ? 'Conectado' : 'Desconectado';

  const fullPhoneNumber = `(${instance.phone_prefix}) ${instance.phone_number}`;

  React.useEffect(() => {
    if (isUpdateError) {
      if (switchRef.current) {
        switchRef.current.checked = !switchRef.current.checked;
        setIsUpdateError(false);
      }
    }
  }, [isUpdateError]);

  return (
    <>
      <S.Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <S.Header>
          <S.LeftSide>
            <S.WhatsappLogo src={WhatsappLogoImg} alt="profitfy-whatsapp-logo" />

            <S.InstanceNameAndPhoneNumberWrapper>
              <S.Tooltip>
                <S.NameTooltipTrigger>
                  <S.InstanceName weight={ETextWeight.MEDIUM}>{instance.name}</S.InstanceName>
                </S.NameTooltipTrigger>

                <S.NameTooltipContent>
                  <S.TooltipContentText>{instance.name}</S.TooltipContentText>
                  <S.NameTooltipArrow />
                </S.NameTooltipContent>
              </S.Tooltip>

              <S.PhoneNumber weight={ETextWeight.MEDIUM} size={ETextSize.B5}>
                {fullPhoneNumber}
              </S.PhoneNumber>
            </S.InstanceNameAndPhoneNumberWrapper>
          </S.LeftSide>

          <S.InstanceStatusBadge color={badgeColor}>{badgeText}</S.InstanceStatusBadge>
        </S.Header>

        <S.Body>
          <S.QuantityAndDescriptionWrapper>
            <S.InstanceInfoQuantity weight={ETextWeight.MEDIUM}>
              {metrics.workflow_quantity}
            </S.InstanceInfoQuantity>

            <S.InstanceInfoDescription weight={ETextWeight.MEDIUM}>
              Automações
            </S.InstanceInfoDescription>
          </S.QuantityAndDescriptionWrapper>

          <S.QuantityAndDescriptionWrapper>
            <S.InstanceInfoQuantity weight={ETextWeight.MEDIUM}>
              {metrics.workflow_event_quantity}
            </S.InstanceInfoQuantity>
            <S.InstanceInfoDescription weight={ETextWeight.MEDIUM}>
              Envios nos últimos 7 dias
            </S.InstanceInfoDescription>
          </S.QuantityAndDescriptionWrapper>
        </S.Body>

        <S.Footer>
          {isConnected && (
            <S.Switch
              innerRef={switchRef}
              id={`disable-instance-${instance.id}`}
              name={`disable-instance-${instance.id}`}
              defaultChecked={instance.is_active}
              onChange={onSwitchChange}
            />
          )}

          {!isConnected && (
            <S.ReconnectButton onClick={onReconnect}>
              <Plugs size={16} color={theme.colors.black.default} />
              Reconectar
            </S.ReconnectButton>
          )}

          <S.ButtonsWrapper showButtons={isInstanceHovering}>
            <S.Tooltip>
              <S.Tooltip.Trigger>
                <S.ViewAutomationsButton onClick={onViewAutomations}>
                  <S.IconWrapper>
                    <FlowArrow size={16} color={theme.colors.white.default} />
                  </S.IconWrapper>
                </S.ViewAutomationsButton>
              </S.Tooltip.Trigger>

              <S.TooltipContent side="bottom" sideOffset={8}>
                <S.TooltipContentText>Visualizar automações</S.TooltipContentText>
                <S.TooltipArrow />
              </S.TooltipContent>
            </S.Tooltip>

            <S.Popover
              open={isUpdatingInstancePopoverOpen}
              onOpenChange={handleUpdatingInstancePopoverOpen}
            >
              <S.Popover.Trigger>
                <S.IconWrapper>
                  <DotsThree size={20} color={theme.colors.white.default} />
                </S.IconWrapper>
              </S.Popover.Trigger>

              <S.PopoverContent side="bottom" sideOffset={8}>
                <S.IconAndActionButtonWrapper>
                  <Pencil size={16} color={theme.colors.white.default} />
                  <S.ActionButton onClick={onEdit}>Editar</S.ActionButton>
                </S.IconAndActionButtonWrapper>

                <S.IconAndActionButtonWrapper>
                  <Trash size={16} color={theme.colors.white.default} />
                  <S.ActionButton onClick={onRemove}>Remover instância</S.ActionButton>
                </S.IconAndActionButtonWrapper>
                <S.PopoverArrow />
              </S.PopoverContent>
            </S.Popover>
          </S.ButtonsWrapper>
        </S.Footer>
      </S.Wrapper>

      <UpdateInstanceModal
        isOpen={isUpdateInstanceModalOpen}
        toggle={handleUpdateInstanceModalOpen}
        credentialAliasId={instance.alias_id}
      />

      <DeleteInstanceModal
        isOpen={isDeleteInstanceModalOpen}
        toggle={handleDeleteInstanceModalOpen}
        credentialAliasId={instance.alias_id}
        metrics={metrics}
      />
    </>
  );
};

export default Instance;
