import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonWithIcon from '@components/common/core/Inputs/ButtonWithIcon';

export const NewKitButton = styled(ButtonWithIcon)``;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const Title = styled(HeadingBase)``;

export const ButtonContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  opacity: 1;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconText = styled(TextBase)`
  font-size: 0.875rem;
`;
