import styled, { keyframes } from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

const initialButtonsWrapperAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const DeleteButton = styled.button`
  width: 14px;
  height: 14px;
  padding: unset;
  border: unset;
  background-color: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const EditButton = styled.button`
  width: 14px;
  height: 14px;
  padding: unset;
  border: unset;
  background-color: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const ButtonsWrapper = styled.div`
  animation: ${initialButtonsWrapperAnimation} 0.5s;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const TagName = styled(TextBase)``;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
