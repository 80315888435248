import styled, { css } from 'styled-components/macro';

import { ISwitchInput } from '@domain/interfaces/components/ISwitch';
import { ESwitchSize, ESwitchWidth } from '@domain/enums/components/ESwitch';

export const SwitchLabel = styled.label<ISwitchInput>`
  position: absolute;
  top: 0;
  left: 0;
  width: 44px;
  height: 22px;
  border-radius: 11px;
  background-color: ${({ theme }) => theme.colors.gray[3]};
  cursor: pointer;
  transition: all 0.4s;

  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-left: 4px;
    background-color: #fff;
    transition: 0.4s;

    ${({ customSize }) =>
      customSize === ESwitchSize.SMALL &&
      css`
        width: 10px;
        height: 10px;
        margin-top: 2px;
        margin-left: 3px;
      `};
  }

  ${({ customWidth }) =>
    customWidth === ESwitchWidth.LONG &&
    css`
      width: 69px;
    `}

  ${({ customSize }) =>
    customSize === ESwitchSize.SMALL &&
    css`
      width: 29px;
      height: 14px;
    `};
`;

export const SwitchInput = styled.input<ISwitchInput>`
  opacity: 0;
  z-index: ${({ theme }) => theme.zIndex.firstLayer};
  border-radius: 11px;

  ${({ customWidth }) =>
    customWidth === ESwitchWidth.LONG &&
    css`
      width: 69px;
    `}

  ${({ customSize }) =>
    customSize === ESwitchSize.SMALL &&
    css`
      width: 29px;
      height: 14px;
    `};

  &:checked + ${SwitchLabel} {
    background-color: ${({ theme }) => theme.colors.green.dark};

    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      margin-left: 23px;
      transition: 0.4s;

      ${({ customWidth }) =>
        customWidth === ESwitchWidth.LONG &&
        css`
          margin-left: 48px;
        `}

      ${({ customSize }) =>
        customSize === ESwitchSize.SMALL &&
        css`
          width: 10px;
          height: 10px;
          margin-left: 16px;
        `};
    }
  }
`;

export const SwitchWrapper = styled.div<ISwitchInput>`
  position: relative;
  width: 44px;
  height: 22px;

  ${({ customSize }) =>
    customSize === ESwitchSize.SMALL &&
    css`
      width: 29px;
      height: 14px;
    `};
`;

export const Wrapper = styled.div<ISwitchInput>`
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100px;

  ${({ customSize }) =>
    customSize === ESwitchSize.SMALL &&
    css`
      width: 29px;
    `};
`;
