import styled, { keyframes } from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

const initialAnimation = keyframes`
  0% {
    bottom: 0;
    opacity: 0;
  }
  100% {
    bottom: 24px;
    opacity: 1;
  }
`;

export const UpdatePaymentInfoButton = styled(ButtonBase)``;

export const Description = styled(TextBase)``;

export const Wrapper = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 12px 32px;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.danger.light};
`;

export const Container = styled.div`
  position: fixed;
  left: 128px;
  right: 32px;
  bottom: 24px;
  z-index: ${({ theme }) => theme.zIndex.thirdLayer};
  animation: ${initialAnimation} 1s ease-in;
`;
