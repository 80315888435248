import React from 'react';
import { WarningCircle } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';

import { useSubscriptionPlans } from '@helpers/hooks/pages/dashboard/subscription/useSubscriptionPlans';

import * as S from './styles';

const PendingPayment: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { isPendingPaymentModalOpen, handlePendingPaymentModalOpen } = useSubscriptionPlans();
  const { storeAliasId } = useParams<IParams>();

  const onReactivateSubscriptionClick = React.useCallback(() => {
    history.push(
      `/${storeAliasId}/dashboard/subscription?defaultTab=general&reactivateSubscription=true`,
    );
  }, [history, storeAliasId]);

  return (
    <S.Modal isOpen={isPendingPaymentModalOpen} toggle={handlePendingPaymentModalOpen}>
      <S.Wrapper>
        <S.Header>
          <WarningCircle size={24} color={theme.colors.warning.default} />
          <S.Title type={EHeadingSize.H5}>Transação Pendente</S.Title>
        </S.Header>
        <S.Body>
          <S.Description>
            A sua loja possui uma{' '}
            <strong>transação pendente referente à sua assinatura anterior.</strong> Realize o
            pagamento da transação pendente ao reativar a assinatura.
          </S.Description>

          <S.ReactiveSubscriptionButton onClick={onReactivateSubscriptionClick}>
            Reativar assinatura
          </S.ReactiveSubscriptionButton>
        </S.Body>
      </S.Wrapper>
    </S.Modal>
  );
};

export default PendingPayment;
