import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Text = styled(TextBase)`
  text-align: center;
  display: inline-block;
  max-width: 502px;
  line-height: 120%;

  & > strong {
    margin-left: 4px;
    color: ${({ theme }) => theme.colors.green.default};
  }
`;

export const Heading = styled(HeadingBase)`
  text-align: center;
  line-height: 100%;
  margin-bottom: 24px;
`;

export const TextWrapper = styled.div`
  max-width: 596px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 12%;

  @media only screen and (max-width: 1324px) {
    max-width: 524px;
  }

  @media only screen and (max-width: 1138px) {
    max-width: 596px;
    margin-top: unset;
  }
`;

export const LogoWrapper = styled(ImageBase)`
  max-width: 152px;
  width: 100%;
  margin-bottom: 92px;

  @media only screen and (max-width: 1138px) {
    margin-bottom: 24px;
  }
`;

export const ImageWrapper = styled(ImageBase)`
  max-width: 622px;
  width: 100%;

  @media only screen and (max-width: 1324px) {
    max-width: 524px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  gap: 28px;
  flex-wrap: wrap;
  justify-content: space-between;

  @media only screen and (max-width: 1138px) {
    justify-content: center;
    margin-bottom: 96px;
  }
`;

export const Wrapper = styled.div`
  max-width: calc(1262px + 64px);
  width: 100%;
  margin: 0 auto;
  padding: 40px 32px;
  box-sizing: border-box;
`;

export const Container = styled.div`
  background-image: url('https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/onboarding/images/backgrounds/right-background.svg');
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: right;

  @media only screen and (max-width: 767px) {
    background: unset;
  }
`;
