import React from 'react';

import { EHeadAlign } from '@domain/enums/components/ETable';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';

import { useTransaction } from '@helpers/hooks/common/store/subscription/useTransaction';
import { useSubscriptionData } from '@helpers/hooks/pages/dashboard/subscription/useSubscriptionData';

import Paginate from '@components/common/core/Utils/Paginate';
import Row from './Row';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Transactions: React.FC = () => {
  const {
    transactions,
    transactionsPageCount,
    transactionsPage,
    onTransactionsPageChange,
  } = useTransaction();
  const { isLoadingData } = useSubscriptionData();

  if (isLoadingData) return <SkeletonLoading />;

  if (!transactions.length) return <></>;

  return (
    <S.Wrapper>
      <S.Title type={EHeadingSize.H3} fontWeight={EHeadingWeight.MEDIUM}>
        Transações
      </S.Title>

      <S.Table>
        <S.Table.Header>
          <S.Table.Row>
            <S.Table.Head>Descrição</S.Table.Head>
            <S.Table.Head>Data</S.Table.Head>
            <S.Table.Head align={EHeadAlign.CENTER}>Status</S.Table.Head>
            <S.Table.Head align={EHeadAlign.CENTER}>Detalhes</S.Table.Head>
            <S.Table.Head align={EHeadAlign.RIGHT}>Valor</S.Table.Head>
          </S.Table.Row>
        </S.Table.Header>
        <S.Table.Body>
          {transactions.map(transaction => (
            <Row key={transaction.alias_id} transaction={transaction} />
          ))}
        </S.Table.Body>
        <S.Table.Footer>
          <S.Table.Row>
            <S.Table.Data colSpan={5} align={EHeadAlign.RIGHT}>
              <Paginate
                forcePage={transactionsPage}
                pageCount={transactionsPageCount}
                onPageChange={onTransactionsPageChange}
              />
            </S.Table.Data>
          </S.Table.Row>
        </S.Table.Footer>
      </S.Table>
    </S.Wrapper>
  );
};

export default Transactions;
