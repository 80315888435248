import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresPaymentMethodValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresPaymentMethodValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresPaymentMethodValuesResponse => {
  const currentSelectedStoreBoletoPercentageValue =
    currentSelectedStoreFinancialDetailsReportData?.payment_method?.boleto_percentage;

  const currentSelectedStoreCardPercentageValue =
    currentSelectedStoreFinancialDetailsReportData?.payment_method?.card_percentage;

  const currentSelectedStorePixPercentageValue =
    currentSelectedStoreFinancialDetailsReportData?.payment_method?.pix_percentage;

  const storesBoletoPercentageValues = selectedStoresData?.map(
    value => value.financial_details_report?.payment_method?.boleto_percentage,
  );

  const storesCardPercentageValues = selectedStoresData?.map(
    value => value.financial_details_report?.payment_method?.card_percentage,
  );

  const storesPixPercentageValues = selectedStoresData?.map(
    value => value.financial_details_report?.payment_method?.pix_percentage,
  );

  const boletoPercentageValues = [
    ...storesBoletoPercentageValues,
    currentSelectedStoreBoletoPercentageValue,
  ];

  const cardPercentageValues = [
    ...storesCardPercentageValues,
    currentSelectedStoreCardPercentageValue,
  ];

  const pixPercentageValues = [
    ...storesPixPercentageValues,
    currentSelectedStorePixPercentageValue,
  ];

  const reducedBoletoPercentageValues = boletoPercentageValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedCardPercentageValues = cardPercentageValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const reducedPixPercentageValues = pixPercentageValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const currentSelectedStore = 1;
  const selectedStoresQuantity = selectedStoresData?.length;

  const storesQuantity = selectedStoresQuantity + currentSelectedStore;

  const boletoPercentage = reducedBoletoPercentageValues / storesQuantity;
  const cardPercentage = reducedCardPercentageValues / storesQuantity;
  const pixPercentage = reducedPixPercentageValues / storesQuantity;

  return {
    boleto_percentage: boletoPercentage,
    card_percentage: cardPercentage,
    pix_percentage: pixPercentage,
  };
};
