import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;

    & > span {
      color: ${({ theme }) => theme.colors.green.default};
    }
  }
`;

export const Text = styled(TextBase)``;
