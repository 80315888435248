import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';
import ButtonBase from '@components/common/core/Inputs/Button';

export const AddInstanceWrapper = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 12px;
`;

export const GroupInstanceTooltip = styled.div`
  display: flex;
  align-items: center;
`;

export const AddInstanceText = styled(TextBase)`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.green.default};
`;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
  padding: 0 32px;
  box-sizing: border-box;
`;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)`
  width: 100%;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 32px;
  box-sizing: border-box;
`;

export const TextHelper = styled(TextBase)`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[1]};
  margin-right: 3px;
  margin-bottom: 5px;
`;

export const TriggerIconWrapper = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 5px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Tooltip = styled(CustomTooltipBase)``;

export const TooltipContent = styled(CustomTooltipBase.Content)`
  width: 100%;
  max-width: 281px;
  height: 158px;
  padding: 16px;
`;

export const TriggerTooltipContentText = styled(TextBase)``;

export const InstancesWrapper = styled.div`
  width: 100%;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 12px;

  scrollbar-color: ${({ theme }) => theme.colors.gray[4]} ${({ theme }) => theme.colors.gray[6]};
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[4]};
  }

  & > :first-child {
    border-radius: 5px;
  }

  & > :last-child {
    border-radius: 5px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  justify-content: flex-end;
  padding: 0 32px;
  box-sizing: border-box;
  margin-top: auto;
`;

export const SecondaryButton = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.colors.background[2]};
  padding: 2px 16px 2px 16px;
  box-shadow: unset;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background[2]};
    box-shadow: unset;
  }

  & > span {
    color: ${({ theme }) => theme.colors.white.default};
  }
`;

export const PrimaryButton = styled(ButtonBase)`
  padding: 2px 16px 2px 16px;
`;

export const InstanceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  box-sizing: border-box;
  justify-content: space-between;
  gap: 12px;
  align-items: baseline;
`;

export const TextInstanceRotation = styled(TextBase)`
  font-weight: 500;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[3]};
`;
