import styled, { css } from 'styled-components/macro';

import { ICustomText, INavItemContent } from '@domain/interfaces/components/ISidebar';

import TextBase from '@components/common/core/DataDisplay/Text';

export const NewText = styled.span`
  border: 1px solid ${({ theme }) => theme.colors.green.default};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  padding: 2px 8px;
  font-size: 0.75rem;
  border-radius: 15px;
  color: ${({ theme }) => theme.colors.green.default} !important;
  line-height: 120%;
  margin-left: 8px;
`;

export const Wrapper = styled.div<INavItemContent>`
  z-index: ${({ theme }) => theme.zIndex.secondLayer};
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 4px;
  background: red;
  padding: 8px 6px 6px 6px;
  width: 100%;
  background: transparent;
  transition: margin 0.2s ease-in-out, padding 0.2s ease-in-out;
  position: relative;

  &::after {
    transition: all 0.2s ease-in-out;
  }

  span {
    transition: all 0.2s;
    z-index: 1;
    margin-left: 16px;
  }

  & > svg {
    min-width: 30px;
    z-index: 1;
    color: ${({ theme }) => theme.colors.gray[1]};
  }

  ${({ isHovering }) =>
    isHovering &&
    css`
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 44px;
        border-radius: 12px;
        background-color: ${({ theme }) => theme.colors.background[2]};
        top: 0;
        left: 0;

        @media only screen and (max-height: 700px) {
          height: 38px;
        }
      }

      span {
        color: ${({ theme }) => theme.colors.gray[1]};
      }
    `}

  &:active {
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 44px;
      border-radius: 12px;
      background-color: ${({ theme }) => theme.colors.sideBarSubMenuColor};
      top: 0;
      left: 0;
    }

    span {
      color: ${({ theme }) => theme.colors.green.default};
    }

    & > svg {
      color: ${({ theme }) => theme.colors.green.default};
    }
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      &::after {
        content: '';
        position: absolute;
        width: 40px;
        height: 43px;
        border-radius: 12px;
        background-color: ${({ theme }) => theme.colors.sideBarSubMenuColor};
        top: 0;
        left: 0;
        transition: all 0.2s ease-out;
      }

      span {
        color: ${({ theme }) => theme.colors.green.default};
      }

      & > svg {
        color: ${({ theme }) => theme.colors.green.default};
      }
    `}

  ${({ isSidebarExpanded }) =>
    isSidebarExpanded &&
    css`
      margin-left: 12px;
      padding: 8px 16px;

      & > span {
        margin-left: 8px;

        @media only screen and (max-height: 700px) {
          margin-left: 4px;
        }
      }
    `}

  ${({ isSidebarExpanded, isSelected }) =>
    isSelected &&
    isSidebarExpanded &&
    css`
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 44px;
        border-radius: 12px;
        background-color: ${({ theme }) => theme.colors.sideBarSubMenuColor};
        top: 0;
        left: 0;

        @media only screen and (max-height: 700px) {
          height: 38px;
        }
      }
    `}
`;

export const Text = styled(TextBase)<ICustomText>`
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const Item = styled.div`
  width: 100%;
  position: relative;
  height: 42px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;

  @media only screen and (max-height: 700px) {
    margin-bottom: 4px;
    height: 36px;
  }
`;
