import styled, { css } from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';

export const Container = styled.div``;

export const Header = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

export const Table = styled(TableBase)`
  & > table {
    & > thead {
      & > tr {
        & > th {
          padding: 10px 20px;
        }

        & > th:first-child {
          padding-left: 24px;
          padding-right: 0;
        }

        & > th:last-child {
          padding-right: 36px;
        }
      }
    }

    & > tbody {
      & > tr {
        cursor: pointer;

        & > td {
          padding: 10px 20px;
          font-size: 0.875rem;
        }

        & > td:first-child {
          padding-left: 24px;
          padding-right: 0;
        }

        & > td:last-child {
          padding-right: 36px;
        }
      }
    }
  }
`;

export const Checkbox = styled(CheckboxBase)`
  & > div {
    ${({ checked, theme }) =>
      !checked &&
      css`
        background-color: ${theme.colors.gray[6]};
      `}
  }
`;
