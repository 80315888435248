import React from 'react';

import { TrashSimple, Info } from 'phosphor-react';
import { Position, NodeProps, useUpdateNodeInternals } from 'react-flow-renderer';
import { useTheme } from 'styled-components/macro';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';
import { useCanvasMessage } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessage';

import MinimalNode from './MinimalNode';
import FullNode from './FullNode';
import WhatsAppModal from './WhatsAppModal';

import * as S from './styles';

const SendWhatsAppNode: React.FC<NodeProps> = ({ id, data }) => {
  const { messages } = useCanvasMessage();

  const theme = useTheme();

  const updateNodeInternals = useUpdateNodeInternals();

  const { handleSelectedNodeId, connectionInProgress, nodes, setEdges, setNodes } = useAutomation();

  const [isHoveringNode, setIsHoveringNode] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [full, setFull] = React.useState(false);

  const onMouseEnter = React.useCallback(() => setIsHoveringNode(true), []);

  const onMouseLeave = React.useCallback(() => setIsHoveringNode(false), []);

  const handleModalOpen = React.useCallback(() => {
    setIsModalOpen(currentState => !currentState);
  }, []);

  const handleClickNode = React.useCallback(() => {
    handleModalOpen();
    handleSelectedNodeId(id);
  }, [handleSelectedNodeId, id, handleModalOpen]);

  const onDelete = React.useCallback(
    event => {
      event.stopPropagation();
      setEdges((els: any) => {
        const mappedEdges = els.map((edge: any) => {
          if (edge.source.includes(id)) {
            return false;
          }
          if (edge.target.includes(id)) {
            return false;
          }
          return edge;
        });
        const filteredEdges = mappedEdges.filter((edge: any) => edge);
        return filteredEdges;
      });
      const newNodes = nodes.filter(node => node.id !== id);
      setNodes(newNodes);
    },
    [id, nodes, setEdges, setNodes],
  );

  React.useEffect(() => {
    updateNodeInternals(id);
  }, [data, id, updateNodeInternals]);

  React.useEffect(() => {
    handleSelectedNodeId(id);
  }, [id, handleSelectedNodeId]);

  React.useEffect(() => {
    if (data?.message) {
      setFull(true);
    } else {
      setFull(false);
    }
  }, [data]);

  const messageId = data?.message?.id;

  const storeMessages = messages || [];

  const foundMessage = storeMessages.find(storeMessage => storeMessage.id === messageId);

  return (
    <S.Wrapper
      full={full}
      onClick={handleClickNode}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      missingRules={!full}
    >
      <S.TargetHandle
        position={Position.Top}
        type="target"
        style={{ zIndex: connectionInProgress ? 999999 : -1 }}
      />

      {!full && <MinimalNode />}

      {full && (
        <FullNode
          click_percentage={data?.metrics?.click_events_percentage || 0}
          delivery_quantity={data?.metrics?.delivery_events_quantity || 0}
          title={foundMessage?.title || data?.message?.title}
          message={foundMessage || data?.message}
          messageContents={foundMessage?.message_contents || data?.message?.message_contents}
        />
      )}

      <S.InputHandleContainer>
        <S.SourceHandle position={Position.Bottom} type="source" />
      </S.InputHandleContainer>

      {!full && (
        <S.InfoWrapper>
          <S.Info>
            <Info size={16} color={theme.colors.danger.light} weight="fill" />
          </S.Info>
        </S.InfoWrapper>
      )}

      {isHoveringNode && (
        <S.OptionsWrapper>
          <S.Option onClick={onDelete}>
            <TrashSimple size={16} color={theme.colors.font} weight="fill" />
          </S.Option>
        </S.OptionsWrapper>
      )}

      {isModalOpen && (
        <WhatsAppModal isOpen={isModalOpen} toggle={handleModalOpen} message={data?.message} />
      )}
    </S.Wrapper>
  );
};

export default SendWhatsAppNode;
