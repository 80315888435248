import React from 'react';

import { ChargebackProvider } from '@helpers/hooks/pages/dashboard/feed/useChargeback';
import { NoInformationProvider } from '@helpers/hooks/pages/dashboard/feed/useNoInformation';
import { GatewayProvider } from '@helpers/hooks/pages/dashboard/feed/useGateway';
import { OtherGatewayProvider } from '@helpers/hooks/pages/dashboard/feed/useOtherGateway';
import { FeedAdSenseAccountProvider } from '@helpers/hooks/pages/dashboard/feed/useFeedAdSenseAccount';
import { FeedCustomValuesBillingProvider } from '@helpers/hooks/pages/dashboard/feed/useCustomValueBillings';
import { ProductsProvider } from '@helpers/hooks/pages/dashboard/feed/useProduct';
import { WhatsappInstanceProvider } from '@helpers/hooks/pages/dashboard/feed/useWhatsappInstance';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import EmptyScreen from '../EmptyScreen';
import Header from '../Header';
import ChargebackOrders from '../ChargebackOrders';
import NoInformationOrders from '../NoInformationOrders';
import GatewayOrders from '../GatewayOrders';
import OtherGatewayOrders from '../OtherGatewayOrders';
import AdAccountStatus from '../AdAccountStatus';
import CustomValueBillings from '../CustomValueBillings';
import Products from '../Products';
import WhatsappInstance from '../WhatsappInstance';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const { feedGateways, feedQuantity } = useStoreConfig();
  const { analytics, selectedStore, user } = useConfig();

  const hasNoInformationOrders = feedGateways.find(gateway => gateway === 'MANUAL');
  const hasOtherGatewayOrders = feedGateways.find(gateway => gateway === 'OTHER');
  const hasFeed = feedQuantity > 0;

  React.useEffect(() => {
    analytics?.track(
      'Task Feed Page View',
      {
        email: user?.email,
      },
      { context: { groupId: selectedStore?.alias_id } },
    );
  }, [analytics, selectedStore, user]);

  if (!hasFeed) {
    return <EmptyScreen />;
  }

  const filteredFeedGateways = feedGateways.filter(
    feedGateway => feedGateway !== 'MANUAL' && feedGateway !== 'OTHER',
  );

  return (
    <S.Container>
      <Header />

      <S.OrdersWrapper>
        <WhatsappInstanceProvider>
          <WhatsappInstance />
        </WhatsappInstanceProvider>

        <ProductsProvider>
          <Products />
        </ProductsProvider>

        <FeedCustomValuesBillingProvider>
          <CustomValueBillings />
        </FeedCustomValuesBillingProvider>

        <FeedAdSenseAccountProvider>
          <AdAccountStatus />
        </FeedAdSenseAccountProvider>

        <ChargebackProvider>
          <ChargebackOrders />
        </ChargebackProvider>

        {filteredFeedGateways.map(feedGateway => (
          <GatewayProvider key={feedGateway} gateway={feedGateway}>
            <GatewayOrders />
          </GatewayProvider>
        ))}

        {hasOtherGatewayOrders ? (
          <OtherGatewayProvider>
            <OtherGatewayOrders />
          </OtherGatewayProvider>
        ) : (
          <></>
        )}

        {hasNoInformationOrders ? (
          <NoInformationProvider>
            <NoInformationOrders />
          </NoInformationProvider>
        ) : (
          <></>
        )}
      </S.OrdersWrapper>
    </S.Container>
  );
};

export default React.memo(Wrapper);
