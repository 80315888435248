import React from 'react';

import { PerfectPay as Icon } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { IPerfectPayConfigProps } from '@domain/interfaces/dashboard/IntegrationCenter/InfoProduct/IPerfectPay';

import CredentialOption from './CredentialOption';
import SyncOption from './SyncOption';

import * as S from './styles';

const PerfectPayConfig: React.FC<IPerfectPayConfigProps> = ({ credentialAliasId }) => {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const onChangeTab = React.useCallback(index => setSelectedTab(index), []);

  return (
    <S.ContentWrapper>
      <S.Header>
        <Icon size={52} color={theme.colors.gray[2]} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.REGULAR}>
          Integração com Perfect Pay
        </S.Heading>
      </S.Header>

      <S.Tab defaultTab={selectedTab} onChangeTab={onChangeTab}>
        <S.Option>Geral</S.Option>
        <S.Option>Sincronização</S.Option>
      </S.Tab>

      {selectedTab === 0 && <CredentialOption credentialAliasId={credentialAliasId} />}
      {selectedTab === 1 && <SyncOption />}
    </S.ContentWrapper>
  );
};

export default PerfectPayConfig;
