import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';

import AdAccountsFeature from './AdAccountsFeature';
import CogsFeature from './CogsFeature';
import CustomValuesFeature from './CustomValuesFeature';

import * as S from './styles';

const Features: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
        Enquanto seus dados estão sendo carregados:
      </S.Title>

      <S.FeaturesWrapper>
        <AdAccountsFeature />
        <CogsFeature />
        <CustomValuesFeature />
      </S.FeaturesWrapper>
    </S.Wrapper>
  );
};

export default Features;
