import React from 'react';

import { EAbandonedCartSellRecoveredPicker } from '@domain/enums/dashboard/automations/node/conditionalNode/EAbandonCart';

import * as S from './styles';

interface IPickerOrderStatusProps {
  defaultValue?: string;
  onChange: (data: string) => void;
}

const PickerSellRecovered: React.FC<IPickerOrderStatusProps> = ({
  defaultValue = EAbandonedCartSellRecoveredPicker.TRUE,
  onChange,
}) => {
  return (
    <S.Select
      name="picker"
      defaultValue={defaultValue}
      onChange={e => {
        onChange(e.target.value);
      }}
    >
      <S.Option value={EAbandonedCartSellRecoveredPicker.TRUE}>Verdadeiro</S.Option>
      <S.Option value={EAbandonedCartSellRecoveredPicker.FALSE}>Falso</S.Option>
    </S.Select>
  );
};

export default PickerSellRecovered;
