import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

export const HeaderWrapper = styled.div`
  margin-bottom: 32px;
`;

export const Title = styled(HeadingBase)``;

export const Description = styled(TextBase)`
  color: ${({ theme }) => theme.colors.background[6]};
`;
