import { ApiService } from '@services/api';
import { WHATSAPP_CONFIG_API_DOMAIN } from '@constants/api';

import {
  IGetMessageContentProps,
  IGetMessageContentsProps,
  ICreateTextMessageProps,
  ICreateImageMessageProps,
  ICreateAudioMessageProps,
  ICreateVideoMessageProps,
  IDeleteMessageContentProps,
  ICreateFileMessageProps,
  IUpdateMessageContentPositionProps,
} from '@domain/interfaces/common/automations/IMessage';

export class MessagesContentService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getMessageContent({
    storeAliasId,
    message_alias_id,
    message_content_alias_id,
  }: IGetMessageContentProps): Promise<any> {
    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/messages/${message_alias_id}/message-contents/${message_content_alias_id}`,
    );
  }

  public getMessageContents({
    storeAliasId,
    message_alias_id,
  }: IGetMessageContentsProps): Promise<any> {
    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/messages/${message_alias_id}/message-contents`,
    );
  }

  public createTextMessage({
    storeAliasId,
    message_alias_id,
    data,
  }: ICreateTextMessageProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/messages/${message_alias_id}/text-message-contents`,
      { ...data },
    );
  }

  public createImageMessage({
    storeAliasId,
    message_alias_id,
    data,
  }: ICreateImageMessageProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/messages/${message_alias_id}/image-message-contents`,
      data.file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }

  public createVideoMessage({
    storeAliasId,
    message_alias_id,
    data,
  }: ICreateVideoMessageProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/messages/${message_alias_id}/video-message-contents`,
      data.file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }

  public createPDFMessage({
    storeAliasId,
    message_alias_id,
    data,
  }: ICreateFileMessageProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/messages/${message_alias_id}/file-message-contents`,
      data.file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }

  public createAudioMessage({
    storeAliasId,
    message_alias_id,
    data,
  }: ICreateAudioMessageProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/messages/${message_alias_id}/audio-message-contents`,
      data.file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }

  public updateMessageContentPosition({
    storeAliasId,
    message_alias_id,
    data,
  }: IUpdateMessageContentPositionProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/messages/${message_alias_id}/message-contents`,
      { ...data },
    );
  }

  public deleteMessageContent({
    storeAliasId,
    message_alias_id,
    message_content_alias_id,
  }: IDeleteMessageContentProps): Promise<any> {
    return this.apiService.delete(
      `/api/v1/users/stores/${storeAliasId}/messages/${message_alias_id}/message-contents/${message_content_alias_id}`,
    );
  }
}

const messagesContentService = new MessagesContentService(WHATSAPP_CONFIG_API_DOMAIN);

export default messagesContentService;
