import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';

export const AddNewTax = styled(ButtonBase)``;

export const Title = styled(HeadingBase)``;

export const Wrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 32px;
`;
