import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresBoletoPaidValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresBoletoPaidValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresBoletoPaidValuesResponse => {
  const currentSelectedStoreBoletoPaidAmountValue =
    currentSelectedStoreFinancialDetailsReportData?.boleto?.paid?.amount;

  const currentSelectedStoreBoletoQuantityValue =
    currentSelectedStoreFinancialDetailsReportData?.boleto?.paid?.quantity;

  const storesBoletoPaidAmountValues = selectedStoresData?.map(
    value => value.financial_details_report?.boleto?.paid?.amount,
  );

  const storesBoletoQuantityValues = selectedStoresData?.map(
    value => value.financial_details_report?.boleto?.paid?.quantity,
  );

  const boletoPaidAmountValues = [
    ...storesBoletoPaidAmountValues,
    currentSelectedStoreBoletoPaidAmountValue,
  ];

  const boletoQuantityValues = [
    ...storesBoletoQuantityValues,
    currentSelectedStoreBoletoQuantityValue,
  ];

  const reducedBoletoPaidAmountValues = boletoPaidAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedBoletoQuantityValues = boletoQuantityValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  return {
    boleto_paid_amount: reducedBoletoPaidAmountValues,
    boleto_paid_quantity: reducedBoletoQuantityValues,
  };
};
