import React from 'react';
import { EditorState } from 'draft-js';

import bold from '@assets/components/Draft/bold.svg';
import italic from '@assets/components/Draft/italic.svg';
import underline from '@assets/components/Draft/underline.svg';
import { IDraftProps } from '@domain/interfaces/components/IDraft';

import * as S from './styles';

const Draft: React.FC<IDraftProps> = ({ handleDraft, placeholder }) => {
  const [editorState, setEditorState] = React.useState<EditorState | undefined>(
    EditorState.createEmpty(),
  );

  const onEditorStateChange = React.useCallback(
    state => {
      handleDraft(state.getCurrentContent().getPlainText());
      setEditorState(state);
    },
    [handleDraft],
  );

  return (
    <S.DraftContainer>
      <S.DraftWysiwyg
        toolbar={{
          options: ['inline'],
          inline: {
            options: ['bold', 'italic', 'underline'],
            bold: { icon: bold },
            italic: { icon: italic },
            underline: { icon: underline },
          },
        }}
        toolbarClassName="draft-toolbar"
        editorClassName="draft-editor"
        wrapperClassName="draft-wrapper"
        placeholder={placeholder}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
      />
    </S.DraftContainer>
  );
};

export default Draft;
