import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Option = styled.button`
  background: unset;
  border: unset;
  padding: unset;
  cursor: pointer;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ExpirationDate = styled(TextBase)`
  width: 92px;
`;

export const Status = styled(TextBase)`
  width: 64px;
  text-align: center;
`;

export const Amount = styled(TextBase)`
  width: 72px;
  text-align: right;
`;

export const Type = styled(TextBase)`
  width: 92px;
  text-align: center;
`;

export const Identifier = styled(TextBase)`
  width: 148px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
`;

export const IdentifierWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

export const Name = styled(TextBase)`
  width: 124px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template: 'name identifier type amount status expiration-date options';
  gap: 8px;

  span {
    font-size: 0.875rem;
  }
`;
