import styled, { css } from 'styled-components/macro';

import { IOnboardingWrapperProps } from '@domain/interfaces/dashboard/AdsManager/Onboarding/ScriptsAndUtms';

export const OnboardingStepsWrapper = styled.div<IOnboardingWrapperProps>`
  max-width: 720px;
  margin: 0 auto;
  transition: all 0.3s;

  ${({ isScriptStep }) =>
    isScriptStep &&
    css`
      max-width: 1298px;

      @media only screen and (min-width: 1978px) {
        max-width: 720px;
      }

      @media only screen and (min-width: 1764px) {
        max-width: 1024px;
      }

      @media only screen and (max-width: 1348px) {
        max-width: 720px;
      }
    `}
`;

export const Wrapper = styled.div`
  width: 100%;
`;
