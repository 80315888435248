import React from 'react';

import { useSubscriptionData } from '@helpers/hooks/pages/dashboard/subscription/useSubscriptionData';

import { cnpjFormatter, cpfFormatter, zipCodeFormatter } from '@helpers/masks';

import UpdateBillingData from '@components/Dashboard/StoreSubscription/Subscription/Modals/UpdateBillingData';

import * as S from './styles';

const BillingDetails: React.FC = () => {
  const { legalPerson, naturalPerson, address } = useSubscriptionData();

  const [isBillingDetailsModalOpen, setIsBillingDetailsModalOpen] = React.useState<boolean>(false);

  const handleBillingDetailsModalOpen = React.useCallback(() => {
    setIsBillingDetailsModalOpen(state => !state);
  }, []);

  if ((!legalPerson && !naturalPerson) || !address) {
    return <></>;
  }

  const complementary = address.complementary ? `, ${address.complementary}` : '';
  const streetAndComplementary = `${address.street}, ${address.street_number}${complementary}`;
  const neighborhoodAndCityAndState = `${address.neighborhood}, ${address.city} - ${address.state_code}`;
  const zipCode = `${zipCodeFormatter(address.zip_code)}`;
  const legalDocument = legalPerson
    ? `${cnpjFormatter(legalPerson.cnpj)}`
    : `${cpfFormatter(naturalPerson.cpf)}`;

  return (
    <>
      <S.CardWrapper>
        <S.Content>
          <S.DescriptionWrapper>
            <S.Title>Informações de Cobrança</S.Title>
            <S.Description>Edite as suas informações de cobranças.</S.Description>
          </S.DescriptionWrapper>

          <S.DataWrapper>
            <S.DataText>{streetAndComplementary}</S.DataText>
            <S.DataText>{neighborhoodAndCityAndState}</S.DataText>
            <S.DataText>{zipCode}</S.DataText>
            <S.DataText>{legalDocument}</S.DataText>
          </S.DataWrapper>
        </S.Content>

        <S.UpdateDateButton onClick={handleBillingDetailsModalOpen}>
          Alterar dados
        </S.UpdateDateButton>
      </S.CardWrapper>

      {isBillingDetailsModalOpen && (
        <UpdateBillingData
          isOpen={isBillingDetailsModalOpen}
          toggle={handleBillingDetailsModalOpen}
        />
      )}
    </>
  );
};

export default BillingDetails;
