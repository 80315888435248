import React from 'react';

import { useHistory } from 'react-router-dom';

import * as S from './styles';

const Return: React.FC = () => {
  const history = useHistory();

  const onClick = React.useCallback(() => {
    history.goBack();
  }, [history]);

  return <S.Return onClick={onClick} />;
};

export default Return;
