import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { WhatsappLogo } from 'phosphor-react';
import { useTheme } from 'styled-components';

import { EBadgeColors } from '@domain/enums/components/EBadge';
import { EButtonSize } from '@domain/enums/components/EButton';
import { IParams } from '@domain/interfaces/IParams';
import { IInstanceProps } from '@domain/interfaces/dashboard/Feed/IWhatsappInstance';

import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const Instance: React.FC<IInstanceProps> = ({ instance }) => {
  const { colors } = useTheme();
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { analytics, user } = useConfig();

  const handleReconectClick = React.useCallback(() => {
    analytics?.track(
      'Task Feed Whatsapp Instance Disconnected Button Clicked',
      {
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );

    history.push(
      `/${storeAliasId}/dashboard/marketing/messages-instances/new-instance?reconnect=true`,
    );
  }, [history, storeAliasId, analytics, user]);

  const fullPhoneNumber = `(${instance?.phone_prefix}) ${instance?.phone_number}`;

  return (
    <S.Wrapper>
      <S.NameWrapper>
        <S.IconWrapper>
          <WhatsappLogo size={30} color={colors.white.default} />
        </S.IconWrapper>

        <S.ColumnContainer>
          <S.Title>{instance?.name}</S.Title>
          <S.SubTitle>Nome da instância</S.SubTitle>
        </S.ColumnContainer>
      </S.NameWrapper>

      <S.ColumnContainer>
        <S.Title>{fullPhoneNumber}</S.Title>
        <S.SubTitle>Número da instância</S.SubTitle>
      </S.ColumnContainer>

      <S.InstanceStatus color={EBadgeColors.RED}>Desconectado</S.InstanceStatus>

      <S.ButtonsWrapper>
        <S.ActionButton buttonSize={EButtonSize.SMALL} onClick={handleReconectClick}>
          Reconectar
        </S.ActionButton>
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export default Instance;
