import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { USER_API_DOMAIN } from '@constants/api';
import { IGetPlansProps, IGetPlansResponse } from '@domain/interfaces/subscription/IPlans';

export class PlansService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getPlans({ storeAliasId }: IGetPlansProps): IGetPlansResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/plans`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      plans: data?.plans,
      error,
      isLoading,
      isValidating,
      mutate,
    };
  }

  public getPlansPromise({ storeAliasId }: IGetPlansProps): Promise<any> {
    return this.apiService.get(`/api/v1/users/stores/${storeAliasId}/plans`);
  }
}

const plansService = new PlansService(USER_API_DOMAIN);

export default plansService;
