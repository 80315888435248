import queryString from 'query-string';

import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';
import {
  ICreateCustomValueProps,
  IDeleteCustomValueProps,
  IGetCustomValuesProps,
  IGetCustomValuesResponse,
  IUpdateCustomValueProps,
} from '@domain/interfaces/dashboard/CustomValues/ICustomValue';

export class CustomValueService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getCustomValues({
    storeAliasId,
    page,
    filter,
  }: IGetCustomValuesProps): IGetCustomValuesResponse {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/custom-values?limit=10&page=${
        page + 1
      }&${queryStringFilter}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      customValues: data?.custom_values,
      totalPages: data?.total_pages,
      error,
      mutate,
      isValidating,
      isLoading,
    };
  }

  public createCustomValue({
    storeAliasId,
    customValuesCategoryAliasId,
    data,
  }: ICreateCustomValueProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/custom-value-categories/${customValuesCategoryAliasId}/custom-values`,
      { ...data },
    );
  }

  public updateCustomValue({
    storeAliasId,
    customValueAliasId,
    data,
  }: IUpdateCustomValueProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/custom-values/${customValueAliasId}`,
      { ...data },
    );
  }

  public deleteCustomValue({
    storeAliasId,
    customValueAliasId,
  }: IDeleteCustomValueProps): Promise<any> {
    return this.apiService.delete(
      `/api/v1/users/stores/${storeAliasId}/custom-values/${customValueAliasId}`,
    );
  }
}

const customValueService = new CustomValueService(API_DOMAIN);

export default customValueService;
