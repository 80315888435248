import React from 'react';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { IComponentErrorProps } from '@domain/interfaces/components/IComponentError';
import { ETextWeight } from '@domain/enums/components/EText';

import * as S from './styles';

const ComponentError: React.FC<IComponentErrorProps> = ({ mutate }) => {
  return (
    <S.ComponentErrorWrapper>
      <S.Text weight={ETextWeight.SEMIBOLD}>
        Aconteceu um imprevisto e não foi possível carregar seus dados no momento.
      </S.Text>
      <S.Text>
        Você pode tentar carregar seus dados novamente, e se o problema persistir, contate o
        suporte.
      </S.Text>
      <S.Button variant={EButtonVariant.SECONDARY} onClick={mutate}>
        Tentar novamente
      </S.Button>
    </S.ComponentErrorWrapper>
  );
};

export default ComponentError;
