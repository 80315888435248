import styled from 'styled-components/macro';

export const PaginateWrapper = styled.div`
  width: fit-content;
  display: inline-block;

  ul {
    margin: 0;
    list-style: none;
    padding: 0;
    display: flex;

    li {
      width: 31px;
      height: 33px;
      border: 1px solid ${({ theme }) => theme.colors.gray[2]};
      border-radius: 5px;
      cursor: pointer;

      a {
        font-family: ${({ theme }) => theme.fonts.body};
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${({ theme }) => theme.colors.font};
        font-weight: bold;
        font-size: 14px;
        transition: all 0.3s;
      }

      &:hover {
        a {
          color: ${({ theme }) => theme.colors.green.default};
        }
      }

      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    .active {
      border-color: ${({ theme }) => theme.colors.green.default};

      a {
        color: ${({ theme }) => theme.colors.green.default};
      }
    }

    .pagination-item {
      border: none;

      a {
        letter-spacing: 2px;
        font-size: 20px;
      }
    }
  }
`;
