import React from 'react';

import { IProductItemProps } from '@domain/interfaces/dashboard/ProductCost/IModal';

import * as S from './styles';

const Product: React.FC<IProductItemProps> = ({
  product,
  productsIds,
  addProduct,
  removeProduct,
}) => {
  const [isImageError, setIsImageError] = React.useState<boolean>(false);

  const onCheckboxChange = React.useCallback(
    event => {
      if (!event || event.target) return;

      if (event.checked) {
        addProduct(product.id);
      } else {
        removeProduct(product.id);
      }
    },
    [addProduct, removeProduct, product],
  );

  const isChecked = !!productsIds.find(productId => productId === product.id);

  return (
    <S.Product>
      <S.ImageWrapper>
        {!isImageError && product?.image_url ? (
          <S.Image
            src={product.image_url}
            alt="product-image"
            onError={() => setIsImageError(true)}
          />
        ) : (
          <S.NoImageFill />
        )}
      </S.ImageWrapper>

      <S.ProductName>{product?.name}</S.ProductName>

      <S.CheckBox onChange={onCheckboxChange} checked={isChecked} />
    </S.Product>
  );
};

export default Product;
