import React from 'react';

import { useLocation, useHistory } from 'react-router-dom';
import { IOnboardingProvider } from '@domain/interfaces/onboarding/IOnboarding';
import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';

import { PRE_DEFINED_CUSTOM_VALUES_CATEGORIES } from '@constants/predefinedCustomValuesCategories';

import subscriptionService from '@services/pages/subscription/subscription';

import onboardingStepService from '@services/pages/onboarding/onboardingStep';
import storesService from '@services/pages/stores/stores';
import customValuesCategoriesService from '@services/pages/dashboard/customValuesCategories/customValueCategories';
import financialDataService from '@services/common/segment/financialData';

const OnboardingContext = React.createContext<IOnboardingProvider | null>(null);

export const OnboardingProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { pathname } = useLocation();
  const { analytics, user } = useConfig();
  const history = useHistory();

  const [storeAliasId, setStoreAliasId] = React.useState<string>('');
  const [isCreatingStore, setIsCreatingStore] = React.useState<boolean>(false);
  const [onboardingType, setOnboardingType] = React.useState<string>('DROPSHIPPING_INTERNATIONAL');
  const [integratedPlatforms, setIntegratedPlatforms] = React.useState<Array<string>>([]);
  const [integratedGateways, setIntegratedGateways] = React.useState<Array<string>>([]);
  const [integratedMarketings, setIntegratedMarketings] = React.useState<Array<string>>([]);
  const [isCongratulationsModalOpen, setIsCongratulationsModalOpen] = React.useState<boolean>(
    false,
  );
  const [isLoadingTrial, setIsLoadingTrial] = React.useState<boolean>(false);

  const handleCongratulationsModalOpen = React.useCallback(() => {
    setIsCongratulationsModalOpen(!isCongratulationsModalOpen);
  }, [isCongratulationsModalOpen]);

  const handleIntegratedMarketings = React.useCallback(
    marketings => setIntegratedMarketings(marketings),
    [],
  );

  const handleIntegratedPlatforms = React.useCallback(
    platforms => setIntegratedPlatforms(platforms),
    [],
  );

  const handleIntegratedGateways = React.useCallback(
    gateways => setIntegratedGateways(gateways),
    [],
  );

  const handleOnboardingType = React.useCallback(type => setOnboardingType(type), []);

  const createStore = React.useCallback(
    async data => {
      setIsCreatingStore(true);

      try {
        const { data: createStoreData } = await storesService.createStore(data);

        analytics?.group(createStoreData?.alias_id, {
          created_at: createStoreData.created_at,
          name: createStoreData.name,
          currency: createStoreData.currency,
          timezone: createStoreData.iana_timezone,
          avatar: createStoreData.image_url,
          is_active: createStoreData.is_active,
          is_onboarding_finish: createStoreData.is_onboarding_finished,
          type: createStoreData?.type,
        });

        const promises = PRE_DEFINED_CUSTOM_VALUES_CATEGORIES.map(category =>
          customValuesCategoriesService.createCustomValueCategory({
            storeAliasId: createStoreData.store.alias_id,
            data: category,
          }),
        );

        setIsCreatingStore(false);

        Promise.all(promises);

        return createStoreData.store.alias_id;
      } catch (error: any) {
        setIsCreatingStore(false);
        toast.error(error?.response?.data?.message);
      }

      return undefined;
    },
    [toast, analytics],
  );

  const addStep = React.useCallback(
    async data => {
      await onboardingStepService.createStep({
        storeAliasId,
        data,
      });
    },
    [storeAliasId],
  );

  const completeStep = React.useCallback(
    async onboardingStepId => {
      try {
        await onboardingStepService.updateStep({
          storeAliasId,
          onboardingStepId,
          data: { is_completed: true },
        });
      } catch {
        await onboardingStepService.updateStep({
          storeAliasId,
          onboardingStepId,
          data: { is_completed: true },
        });
      }
    },
    [storeAliasId],
  );

  const removeStep = React.useCallback(
    async onboardingStepId => {
      try {
        await onboardingStepService.deleteStep({ storeAliasId, onboardingStepId });
      } catch {
        await onboardingStepService.deleteStep({ storeAliasId, onboardingStepId });
      }
    },
    [storeAliasId],
  );

  const createTrial = React.useCallback(async () => {
    setIsLoadingTrial(true);

    try {
      const { data: trialData } = await subscriptionService.createTrial({ storeAliasId });

      analytics?.track(
        'Trial Started',
        {
          trial_start_date: trialData?.subscription?.period_started_at,
          trial_end_date: trialData?.subscription?.period_ended_at,
          email: user?.email,
        },
        { context: { groupId: storeAliasId } },
      );

      const dataLayer = (window as Record<string, any>)?.dataLayer;

      if (dataLayer) {
        dataLayer.push({ event: 'Trial Started' });
      }

      setIsLoadingTrial(false);

      history.push(`/onboarding/${storeAliasId}/finished`);
    } catch (error: any) {
      setIsLoadingTrial(false);
      toast.error(error?.response?.data?.message);
    }
  }, [toast, history, analytics, user, storeAliasId]);

  const handleStoreBonus = React.useCallback(async () => {
    setIsLoadingTrial(true);

    try {
      const { data: subscriptionData } = await subscriptionService.getSubscriptionPromise({
        storeAliasId,
      });

      if (subscriptionData.subscription) {
        setIsLoadingTrial(false);
        history.push(`/onboarding/${storeAliasId}/finished`);
      }

      await createTrial();
    } catch (error: any) {
      setIsLoadingTrial(false);
      toast.error(error?.response?.data?.message);
    }
  }, [toast, history, createTrial, storeAliasId]);

  const sendFinancialData = React.useCallback(selectedStoreAliasId => {
    setTimeout(() => {
      financialDataService.sendFinancialData(selectedStoreAliasId);
    }, 30000);
  }, []);

  React.useEffect(() => {
    const foundStoreAliasId = pathname.match(/store-([a-zA-Z0-9]+)/);

    if (foundStoreAliasId?.length && foundStoreAliasId[0] !== 'store-type') {
      setStoreAliasId(foundStoreAliasId[0]);
    }
  }, [pathname]);

  return (
    <OnboardingContext.Provider
      value={{
        createStore,
        isCreatingStore,
        addStep,
        completeStep,
        handleOnboardingType,
        onboardingType,
        removeStep,
        handleIntegratedGateways,
        handleIntegratedPlatforms,
        integratedGateways,
        integratedPlatforms,
        handleIntegratedMarketings,
        integratedMarketings,
        handleCongratulationsModalOpen,
        isCongratulationsModalOpen,
        handleStoreBonus,
        isLoadingTrial,
        sendFinancialData,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = (): IOnboardingProvider => {
  const context = React.useContext(OnboardingContext);

  if (!context) {
    throw new Error('useOnboarding must be used within OnboardingProvider');
  }

  return context;
};
