import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.button<{ isSelected?: boolean }>`
  padding: 3px 18px;
  height: 28px;
  border: none;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.background[2]};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[3]};
  letter-spacing: 0.7px;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-size: 0.875rem;
  transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.colors.gray[5]};
      color: ${({ theme }) => theme.colors.gray[1]};
    `}
`;
