import {
  IFinancialReport,
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetFinancialDashboardMultiStoresDataResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';
import { getStoresNetRevenueValues } from './netRevenue';
import { getStoresCogsValues } from './cogs';
import { getStoresFeeValues } from './fee';
import { getStoresMarketingValues } from './marketing';
import { getStoresMarketingIntegrationsValues } from './marketingIntegrations';
import { getStoresNetProfitValues } from './netProfit';
import { getStoresPaidOrdersValues } from './paidOrders';
import { getStoresPendingOrdersValues } from './pendingOrders';
import { getStoresAverageTicketValues } from './averageTicket';
import { getStoresProfitMarginValues } from './profitMargin';
import { getStoresCpaValues } from './cpa';
import { getStoresRoiValues } from './roi';
import { getStoresCardPaidValues } from './cardPaid';
import { getStoresCardInAnalysisValues } from './cardInAnalysis';
import { getStoresCardCancelledValues } from './cardCancelled';
import { getStoresCardConversionValues } from './cardConversion';
import { getStoresCardOtherChargebackValues } from './cardOtherChargeback';
import { getStoresCardOtherRefundedValues } from './cardOtherRefunded';
import { getStoresCardOtherRejectedValues } from './cardOtherRejected';
import { getStoresCardOtherTotalValues } from './cardOtherTotal';
import { getStoresBoletoPaidValues } from './boletoPaid';
import { getStoresBoletoPendingValues } from './boletoPending';
import { getStoresBoletoCompensatedValues } from './boletoCompensated';
import { getStoresBoletoConversionValues } from './boletoConversion';
import { getStoresPixPaidValues } from './pixPaid';
import { getStoresPixPendingValues } from './pixPending';
import { getStoresPixCancelledValues } from './pixCancelled';
import { getStoresPixConversionValues } from './pixConversion';
import { getStoresPaymentMethodValues } from './paymentMethod';
import { getStoresGatewayValues } from './gateway';
import { getStoresProviderValues } from './provider';

export const getFinancialReportMultiStoresData = (
  currentSelectedStoreFinancialReportData: IFinancialReport,
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresFinancialDashboardData: IGetFinancialDashboardPromiseResponse[],
): IGetFinancialDashboardMultiStoresDataResponse => {
  const {
    net_revenue_amount,
    net_revenue_growth,
    net_revenue_amount_by_approved_at,
  } = getStoresNetRevenueValues(
    currentSelectedStoreFinancialReportData,
    selectedStoresFinancialDashboardData,
  );

  const {
    cogs_amount,
    cogs_growth,
    cogs_product_cost_amount,
    cogs_shipping_cost_owner_amount,
  } = getStoresCogsValues(
    currentSelectedStoreFinancialReportData,
    selectedStoresFinancialDashboardData,
  );

  const {
    fee_amount,
    fee_growth,
    checkout_fee_amount,
    gateway_fee_amount,
    tax_amount,
    marketplace_fee_amount,
    info_product_fee_amount,
  } = getStoresFeeValues(
    currentSelectedStoreFinancialReportData,
    selectedStoresFinancialDashboardData,
  );

  const {
    marketing_total_amount,
    marketing_manual_amount,
    marketing_growth,
  } = getStoresMarketingValues(
    currentSelectedStoreFinancialReportData,
    selectedStoresFinancialDashboardData,
  );

  const { facebook_amount, google_amount, taboola_amount } = getStoresMarketingIntegrationsValues(
    currentSelectedStoreFinancialReportData,
    selectedStoresFinancialDashboardData,
  );

  const { net_profit_amount, net_profit_growth } = getStoresNetProfitValues(
    currentSelectedStoreFinancialReportData,
    selectedStoresFinancialDashboardData,
  );

  const {
    cart_panda_amount,
    mercado_livre_amount,
    nuvem_shop_amount,
    shopify_amount,
    yampi_amount,
  } = getStoresProviderValues(
    currentSelectedStoreFinancialReportData,
    selectedStoresFinancialDashboardData,
  );

  const {
    appmax_amount,
    cart_panda_pay_amount,
    hub_sale_amount,
    mercado_pago_amount,
    pag_seguro_amount,
    pagarme_amount,
    upnid_amount,
    zouti_amount,
    other_gateway_amount,
  } = getStoresGatewayValues(
    currentSelectedStoreFinancialReportData,
    selectedStoresFinancialDashboardData,
  );

  const { paid_orders_amount, paid_orders_quantity } = getStoresPaidOrdersValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const { pending_orders_amount, pending_orders_quantity } = getStoresPendingOrdersValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const { average_ticket_amount } = getStoresAverageTicketValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const { profit_margin_amount } = getStoresProfitMarginValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const { cpa_amount } = getStoresCpaValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const { roi_amount } = getStoresRoiValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const { card_paid_amount, card_paid_quantity } = getStoresCardPaidValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const { card_in_analysis_amount, card_in_analysis_quantity } = getStoresCardInAnalysisValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const { card_cancelled_amount, card_cancelled_quantity } = getStoresCardCancelledValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const { card_conversion } = getStoresCardConversionValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const { other_chargeback_amount, other_chargeback_quantity } = getStoresCardOtherChargebackValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const { other_refunded_amount, other_refunded_quantity } = getStoresCardOtherRefundedValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const { other_rejected_amount, other_rejected_quantity } = getStoresCardOtherRejectedValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const { other_total_amount, other_total_quantity } = getStoresCardOtherTotalValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const { boleto_paid_amount, boleto_paid_quantity } = getStoresBoletoPaidValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const { boleto_pending_amount, boleto_pending_quantity } = getStoresBoletoPendingValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const {
    boleto_compensated_amount,
    boleto_compensated_quantity,
  } = getStoresBoletoCompensatedValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const { boleto_conversion } = getStoresBoletoConversionValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const { pix_paid_amount, pix_paid_quantity } = getStoresPixPaidValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const { pix_pending_amount, pix_pending_quantity } = getStoresPixPendingValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const { pix_cancelled_amount, pix_cancelled_quantity } = getStoresPixCancelledValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const { pix_conversion } = getStoresPixConversionValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const { boleto_percentage, card_percentage, pix_percentage } = getStoresPaymentMethodValues(
    currentSelectedStoreFinancialDetailsReportData,
    selectedStoresFinancialDashboardData,
  );

  const financialReportWithMultiStoresData: IFinancialReport = {
    cogs: {
      cogs_amount,
      product_cost_amount: cogs_product_cost_amount,
      shipping_cost_owner_amount: cogs_shipping_cost_owner_amount,
      growth: cogs_growth,
    },
    fee: {
      info_product_fee_amount,
      marketplace_fee_amount,
      tax_amount,
      checkout_fee_amount,
      gateway_fee_amount,
      total_amount: fee_amount,
      growth: fee_growth,
    },
    marketing: {
      ...currentSelectedStoreFinancialReportData?.marketing,
      facebook_amount,
      google_amount,
      taboola_amount,
      total_amount: marketing_total_amount,
      manual_amount: marketing_manual_amount,
      growth: marketing_growth,
    },
    net_profit: {
      amount: net_profit_amount,
      growth: net_profit_growth,
    },
    net_revenue: {
      amount: net_revenue_amount,
      growth: net_revenue_growth,
      amount_by_approved_at: net_revenue_amount_by_approved_at,
    },
    gateway: {
      appmax_amount,
      cart_panda_pay_amount,
      hub_sale_amount,
      mercado_pago_amount,
      pag_seguro_amount,
      pagarme_amount,
      upnid_amount,
      zouti_amount,
      other_gateway_amount,
    },
    provider: {
      cart_panda_amount,
      mercado_livre_amount,
      nuvem_shop_amount,
      shopify_amount,
      yampi_amount,
    },
  };

  const financialDetailsReportWithMultiStoreData: IFinancialDetailsReport = {
    ...currentSelectedStoreFinancialDetailsReportData,
    paid_orders: {
      amount: paid_orders_amount,
      quantity: paid_orders_quantity,
    },
    pending_orders: {
      amount: pending_orders_amount,
      quantity: pending_orders_quantity,
    },
    average_ticket: {
      amount: average_ticket_amount,
    },
    profit_margin: {
      amount: profit_margin_amount,
    },
    cpa: {
      amount: cpa_amount,
    },
    roi: {
      amount: roi_amount,
    },
    card: {
      paid: {
        amount: card_paid_amount,
        quantity: card_paid_quantity,
      },
      in_analysis: {
        amount: card_in_analysis_amount,
        quantity: card_in_analysis_quantity,
      },
      canceled: {
        amount: card_cancelled_amount,
        quantity: card_cancelled_quantity,
      },
      conversion: card_conversion,
      other: {
        charged_back: {
          amount: other_chargeback_amount,
          quantity: other_chargeback_quantity,
        },
        refunded: {
          amount: other_refunded_amount,
          quantity: other_refunded_quantity,
        },
        rejected: {
          amount: other_rejected_amount,
          quantity: other_rejected_quantity,
        },
        total: {
          amount: other_total_amount,
          quantity: other_total_quantity,
        },
      },
    },
    boleto: {
      paid: {
        amount: boleto_paid_amount,
        quantity: boleto_paid_quantity,
      },
      pending: {
        amount: boleto_pending_amount,
        quantity: boleto_pending_quantity,
      },
      compensated: {
        amount: boleto_compensated_amount,
        quantity: boleto_compensated_quantity,
      },
      conversion: boleto_conversion,
    },
    pix: {
      paid: {
        amount: pix_paid_amount,
        quantity: pix_paid_quantity,
      },
      pending: {
        amount: pix_pending_amount,
        quantity: pix_pending_quantity,
      },
      canceled: {
        amount: pix_cancelled_amount,
        quantity: pix_cancelled_quantity,
      },
      conversion: pix_conversion,
    },
    payment_method: {
      card_percentage,
      boleto_percentage,
      pix_percentage,
    },
  };

  return {
    financialReportWithMultiStoresData,
    financialDetailsReportWithMultiStoreData,
  };
};
