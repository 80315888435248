import styled, { css } from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import SwitchBase from '@components/common/core/Inputs/Switch';
import TextBase from '@components/common/core/DataDisplay/Text';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';
import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';

import { IMoreOptionsWrapperProps } from '@domain/interfaces/dashboard/ProductCostDetails/IRow';

export const Table = styled(TableBase)``;

export const Switch = styled(SwitchBase)`
  width: 76px;
`;

export const TextContent = styled(TextBase)<{ isPositive?: boolean }>`
  ${({ theme, isPositive }) => css`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;

    ${isPositive && `color: ${theme.colors.success.default};`}
  `}
`;

export const ActionsButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const ActionsButton = styled.button<IMoreOptionsWrapperProps>`
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  gap: 2px;
  opacity: 0;
  display: flex;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  border-radius: 50%;
  transition: filter 0.3s;
  border: unset;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  ${({ isHovering }) =>
    isHovering &&
    css`
      opacity: 1;
    `}

  & > svg {
    color: ${({ theme }) => theme.colors.gray[1]};
  }
`;

export const TriggerIconContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TriggerIconWrapper = styled.div<{ color: string }>`
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background-color: ${({ color }) =>
    css`
      ${color}
    `};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Tooltip = styled(CustomTooltipBase)``;

export const TooltipContent = styled(CustomTooltipBase.Content)`
  width: 100%;
  max-width: 281px;
`;

export const TriggerTooltipContentText = styled(TextBase)``;

export const Popover = styled(CustomPopoverBase)``;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`;

export const ActionButton = styled.button<{ isDeleteButton: boolean }>`
  border: none;
  background-color: transparent;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.font};
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  border-radius: 5px !important;

  ${({ isDeleteButton, theme }) =>
    isDeleteButton &&
    css`
      color: ${theme.colors.danger.default};
    `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[6]};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.gray[6]};
    filter: brightness(0.9);
  }
`;
