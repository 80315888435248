import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import ImageBase from '@components/common/core/DataDisplay/Image';

export const CloseButton = styled.button`
  position: absolute;
  padding: 12px;
  background: unset;
  border: unset;
  right: 0;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Image = styled(ImageBase)``;

export const ModalButton = styled.button`
  padding: unset;
  border: unset;
  background-color: unset;
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const StoreImage = styled(ImageBase)`
  width: 162px;
  height: 48px;

  @media only screen and (max-width: 564px) {
    width: 130px;
    height: 39px;
  }

  @media only screen and (max-width: 424px) {
    width: 104px;
    height: 31px;
  }
`;

export const ExternalLink = styled.a`
  position: absolute;
  bottom: 0;
  left: 8%;
  height: 48px;
  width: fit-content;

  @media only screen and (max-width: 564px) {
    height: 39px;
  }

  @media only screen and (max-width: 424px) {
    height: 31px;
  }

  &:nth-of-type(1) {
    bottom: 26%;
  }

  &:nth-of-type(2) {
    bottom: 16%;
  }
`;

export const ModalContent = styled.div`
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
`;

export const Modal = styled(ModalBase)`
  padding: unset;
  max-width: 564px;
  width: 100%;
  background: unset;

  @media only screen and (max-height: 700px) {
    max-width: 508px;
  }

  @media only screen and (max-height: 624px) {
    max-width: 464px;
  }

  @media only screen and (max-height: 584px) {
    max-width: 428px;
  }

  @media only screen and (max-height: 544px) {
    max-width: 376px;
  }
`;
