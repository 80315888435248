import { ApiService } from '@services/api';
import { LONG_API_DOMAIN } from '@constants/api';
import { IGetSynchronizationProps } from '@domain/interfaces/hooks/IStoreSynchronization';

export class SynchronizationService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getSynchronization({
    synchronization_id,
    storeAliasId,
  }: IGetSynchronizationProps): Promise<any> {
    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/synchronizations/${synchronization_id}`,
    );
  }

  public getDemoSynchronization({ synchronization_id }: IGetSynchronizationProps): Promise<any> {
    return this.apiService.get(`/api/v1/users/stores/synchronizations/${synchronization_id}`);
  }
}

const synchronizationService = new SynchronizationService(LONG_API_DOMAIN);

export default synchronizationService;
