import React from 'react';
import { useParams } from 'react-router-dom';

import { ECategory, EMessageType } from '@domain/enums/dashboard/automations/EMessage';
import { IParams } from '@domain/interfaces/IParams';
import {
  ICreateMessageData,
  IMessageProvider,
} from '@domain/interfaces/common/automations/IMessage';
import { ISavedContent } from '@domain/interfaces/common/automations/ISavedContent';

import { useToast } from '@helpers/hooks/useToast';
import { useMessageContent } from '@helpers/hooks/pages/dashboard/automations/useMessageContent';
import { useMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useMessageSettings';

import messagesService from '@services/pages/dashboard/automations/messages';

const MessageContext = React.createContext<IMessageProvider | null>(null);

export const MessageProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { createMessageContents, updateMessageContents } = useMessageContent();
  const {
    messageToBeSearched,
    selectedCategoryOption,
    resetMessageSettings,
  } = useMessageSettings();

  const [isDeletingMessage, setIsDeletingMessage] = React.useState<boolean>(false);
  const [isCreatingMessage, setIsCreatingMessage] = React.useState<boolean>(false);
  const [isUpdatingMessage, setIsUpdatingMessage] = React.useState<boolean>(false);

  const {
    messages,
    isLoading: isLoadingMessages,
    isValidating: isValidatingMessages,
    error: messagesError,
    mutate: mutateMessages,
  } = messagesService.listMessages({
    storeAliasId,
    page: 0,
    filter: { title: messageToBeSearched, category: selectedCategoryOption },
  });

  const createMessage = React.useCallback(
    async (
      title: string,
      contents: Array<ISavedContent>,
      category: ECategory,
      type: EMessageType,
      isFromTemplate: boolean,
    ) => {
      setIsCreatingMessage(true);

      const data: ICreateMessageData = {
        title,
        category,
        type,
        is_from_template: isFromTemplate,
      };

      try {
        const { data: messageData } = await messagesService.createMessage({ storeAliasId, data });

        if (messageData) {
          await createMessageContents(messageData.message.alias_id, contents, type);
        }

        setIsCreatingMessage(false);

        resetMessageSettings();

        mutateMessages();
      } catch (error: any) {
        setIsCreatingMessage(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [storeAliasId, toast, createMessageContents, resetMessageSettings, mutateMessages],
  );

  const updateMessage = React.useCallback(
    async (
      messageAliasId: string,
      title: string,
      contents: Array<ISavedContent>,
      category: ECategory,
      type: EMessageType,
      isFromTemplate: boolean,
    ) => {
      setIsUpdatingMessage(true);

      const data = {
        title,
        category,
        type,
        is_from_template: isFromTemplate,
      };

      console.log({ contents });

      try {
        const { data: messageData } = await messagesService.updateMessage({
          storeAliasId,
          message_alias_id: messageAliasId,
          data,
        });

        if (messageData) {
          await updateMessageContents(messageAliasId, contents, type);
        }

        setIsUpdatingMessage(false);

        resetMessageSettings();

        mutateMessages();
      } catch (error: any) {
        setIsUpdatingMessage(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [storeAliasId, toast, updateMessageContents, resetMessageSettings, mutateMessages],
  );

  const deleteMessage = React.useCallback(
    async (messageAliasId: string) => {
      setIsDeletingMessage(true);

      try {
        await messagesService.deleteMessage({ storeAliasId, message_alias_id: messageAliasId });

        toast.success('Mensagem excluída com sucesso!');

        await mutateMessages();

        setIsDeletingMessage(false);
      } catch (error: any) {
        setIsDeletingMessage(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [mutateMessages, storeAliasId, toast],
  );

  return (
    <MessageContext.Provider
      value={{
        createMessage,
        deleteMessage,
        isCreatingMessage,
        isDeletingMessage,
        isLoadingMessages,
        isUpdatingMessage,
        isValidatingMessages,
        messages,
        messagesError,
        mutateMessages,
        updateMessage,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export const useMessage = (): IMessageProvider => {
  const context = React.useContext(MessageContext);

  if (!context) throw new Error('useMessage must be used within MessageProvider');

  return context;
};
