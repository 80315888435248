import React from 'react';

import { useParams } from 'react-router-dom';
import { WhatsappLogo } from 'phosphor-react';
import { useTheme } from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { EButtonSize } from '@domain/enums/components/EButton';
import { IParams } from '@domain/interfaces/IParams';
import { INewInstanceModalProps } from '@domain/interfaces/dashboard/WhatsappInstance/INewInstanceModal';

import { useToast } from '@helpers/hooks/useToast';
import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';

import { updateWhatsappInstanceSchema } from '@helpers/validators/dashboard/whatsappInstance/instance';

import whatsappCredentialService from '@services/pages/dashboard/whatsappInstance/whatsappCredential';

import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const NewInstanceModal: React.FC<INewInstanceModalProps> = ({
  isOpen,
  toggle,
  credentialAliasId,
}) => {
  const theme = useTheme();

  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();

  const { mutateWhatsappCredentials, handleShowConnectedInstanceModal } = useAutomation();

  const [isUpdatingCredential, setIsUpdatingCredential] = React.useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updateWhatsappInstanceSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const onSubmit = React.useCallback(
    async formData => {
      setIsUpdatingCredential(true);

      try {
        await whatsappCredentialService.updateCredential({
          storeAliasId,
          credentialAliasId,
          data: { is_active: undefined, name: formData.instance_name },
        });

        await mutateWhatsappCredentials();

        setIsUpdatingCredential(false);

        toggle();

        handleShowConnectedInstanceModal();
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsUpdatingCredential(false);
      }
    },
    [
      storeAliasId,
      toast,
      credentialAliasId,
      mutateWhatsappCredentials,
      toggle,
      handleShowConnectedInstanceModal,
    ],
  );

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Header>
        <WhatsappLogo size={24} color={theme.colors.green.default} />
        <S.Heading>Defina um nome para a instância</S.Heading>
      </S.Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label>Nome da instância</S.Label>
          <S.Input
            {...register('instance_name')}
            type="text"
            placeholder="Ex. Instância para Carrinho Abandonado"
          />
          {errors.instance_name && <Text isErrorFeedback>{errors.instance_name.message}</Text>}
        </S.InputGroup>

        <S.CreateNewInstanceButton
          type="submit"
          buttonSize={EButtonSize.MEDIUM}
          isLoading={isUpdatingCredential}
          disabled={isUpdatingCredential}
        >
          Criar instância
        </S.CreateNewInstanceButton>
      </Form>
    </S.Modal>
  );
};

export default NewInstanceModal;
