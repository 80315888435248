import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 554px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 48px;
  gap: 16px;
`;

export const Heading = styled.div``;

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ShippingOption = styled.div`
  width: 100%;
`;
