import React from 'react';

import { EUserStoreRole } from '@domain/enums/common/userStore/EUserStoreRole';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import { CheckoutFeeProvider } from '@helpers/hooks/pages/dashboard/checkoutFee/useCheckoutFee';

import Wrapper from '@components/Dashboard/CheckoutFee/Wrapper';
import NoAccess from '@components/common/core/Utils/NoAccess';

const CheckoutFee: React.FC = () => {
  const { accessManagementData } = useStoreConfig();
  const { isInternalTeam } = useConfig();

  const isStoreOwner = accessManagementData?.role === EUserStoreRole.OWNER;
  const hasAccessToPage =
    accessManagementData?.group?.has_checkout_fee_full_write_access ||
    accessManagementData?.group?.has_checkout_fee_full_read_access;

  if (!isStoreOwner && !hasAccessToPage && !isInternalTeam) {
    return <NoAccess />;
  }

  return (
    <CheckoutFeeProvider>
      <Wrapper />
    </CheckoutFeeProvider>
  );
};

export default CheckoutFee;
