import React from 'react';
import { useTheme } from 'styled-components/macro';
import { useLocation } from 'react-router-dom';
import { Trophy, Question, Lightbulb, DeviceMobile } from 'phosphor-react';

import { EStatus } from '@domain/enums/subscription/ESubscription';
import { IMoreOptionsProps } from '@domain/interfaces/components/ISidebar';

import { useConfig } from '@helpers/hooks/useConfig';
import { useMediaQueryHeight } from '@helpers/hooks/useMediaQuery';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';

import Quota from './Quota';

import * as S from './styles';

const MoreOptions: React.FC<IMoreOptionsProps> = ({
  isSidebarExpanded = false,
  handleSecondaryMenuEnabled,
  isSecondaryMenuEnabled,
}) => {
  const theme = useTheme();
  const { subscription } = useStoreSubscription();
  const { pathname } = useLocation();
  const { analytics, handleDownloadAppMobileModalOpen } = useConfig();
  const isSmallDeviceHeight = useMediaQueryHeight('700px');

  const [storeAliasId, setStoreAliasId] = React.useState<string>('');

  const onHelpCenterClick = React.useCallback(() => {
    analytics?.track('Help Center Button Clicked', {}, { context: { groupId: storeAliasId } });
  }, [analytics, storeAliasId]);

  const getIconSize = React.useCallback(() => {
    if (isSmallDeviceHeight) return 18;

    return 20;
  }, [isSmallDeviceHeight]);

  React.useEffect(() => {
    const foundStoreAliasId = pathname.match(/store-([a-zA-Z0-9]+)/);

    if (foundStoreAliasId?.length) {
      setStoreAliasId(foundStoreAliasId[0]);
    }
  }, [pathname]);

  const iconCommonProps = {
    size: getIconSize(),
    color: theme.colors.gray[1],
  };

  const isPaidSubscription = subscription?.status === EStatus.PAID;

  return (
    <>
      {isSidebarExpanded && (
        <S.OptionsAndQuotaWrapper>
          <S.MoreOptionsWrapper>
            <S.OptionsWrapper>
              <S.AppMobileButton onClick={handleDownloadAppMobileModalOpen}>
                <DeviceMobile {...iconCommonProps} weight="bold" />
                <S.Text>App Profitfy</S.Text>
              </S.AppMobileButton>

              <S.BenefitAreaLink to={`/${storeAliasId}/dashboard/benefit-area`}>
                <Trophy {...iconCommonProps} weight="bold" />
                <S.Text>Área de Benefícios</S.Text>
              </S.BenefitAreaLink>

              <S.IdeaChannelLink to={`/${storeAliasId}/dashboard/ideas`}>
                <Lightbulb {...iconCommonProps} weight="bold" />
                <S.Text>Canal de ideias</S.Text>
              </S.IdeaChannelLink>

              <S.HelpCenterLink
                href="https://ajuda.profitfy.me/pt-BR/"
                target="_blank"
                onClick={onHelpCenterClick}
              >
                <Question {...iconCommonProps} weight="bold" />
                <S.Text>Central de ajuda</S.Text>
              </S.HelpCenterLink>
            </S.OptionsWrapper>
          </S.MoreOptionsWrapper>

          {isPaidSubscription && <Quota />}
        </S.OptionsAndQuotaWrapper>
      )}

      {!isSidebarExpanded && (
        <S.IconsWrapper>
          <DeviceMobile {...iconCommonProps} weight="bold" />
          <Trophy {...iconCommonProps} weight="bold" />
          <Lightbulb {...iconCommonProps} weight="bold" />
          <Question {...iconCommonProps} weight="bold" />
        </S.IconsWrapper>
      )}
    </>
  );
};

export default React.memo(MoreOptions);
