import { EHeadAlign } from '@domain/enums/components/ETable';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  const randomArray = Array.from(Array(10).keys());

  return (
    <S.Table>
      <S.Table.Header>
        <S.Table.Row>
          <S.Table.Head>Descrição</S.Table.Head>
          <S.Table.Head>Data</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Status</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Valor</S.Table.Head>
        </S.Table.Row>
      </S.Table.Header>

      <S.Table.Body>
        {randomArray.map(index => (
          <S.Table.Row borderBottom>
            <S.Table.Data>
              <Skeleton key={index} width={100} height={25} />
            </S.Table.Data>

            <S.Table.Data>
              <Skeleton key={index} width={100} height={25} />
            </S.Table.Data>

            <S.Table.Data align={EHeadAlign.CENTER}>
              <Skeleton key={index} width={70} height={25} />
            </S.Table.Data>

            <S.Table.Data align={EHeadAlign.RIGHT}>
              <Skeleton key={index} width={70} height={25} />
            </S.Table.Data>
          </S.Table.Row>
        ))}
      </S.Table.Body>
    </S.Table>
  );
};

export default SkeletonLoading;
