import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const CouponsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.black.light};
  padding: 8px 12px;
  border-radius: 5px;
  box-sizing: border-box;

  & > div {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
    padding: 8px 0;
  }

  & > div:first-child {
    padding-top: 0;
  }

  & > div:last-child {
    border-bottom: unset;
    padding-bottom: 0;
  }
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 24px;
`;

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  padding: 16px 24px;
  box-sizing: border-box;
  border-radius: 7px;
`;
