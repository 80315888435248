import React from 'react';

import { EUserStoreRole } from '@domain/enums/common/userStore/EUserStoreRole';
import { EBusinessType } from '@domain/enums/stores/Etype';
import { FinancialDashboardProvider } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';

import { useConfig } from '@helpers/hooks/useConfig';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import Wrapper from '@components/Dashboard/FinancialDashboard/Wrapper';
import NoRetroactiveWrapper from '@components/Dashboard/NoRetroactiveFinancialDashboard';
import OnboardingProcessingWrapper from '@components/Onboarding/ProcessingPage/Wrapper';
import OnboardingInfoProductProcessingWrapper from '@components/Onboarding/InfoProductProcessingPage/Wrapper';
import NoAccess from '@components/common/core/Utils/NoAccess';

const IS_FRONT_END_TEST = false;

const FinancialDashboard: React.FC = () => {
  const { accessManagementData, store } = useStoreConfig();
  const { isInternalTeam } = useConfig();

  const isStoreOwner = accessManagementData?.role === EUserStoreRole.OWNER;
  const hasAccessToPage =
    accessManagementData?.group?.has_financial_store_dashboard_full_write_access ||
    accessManagementData?.group?.has_financial_store_dashboard_full_read_access;

  if (!isStoreOwner && !hasAccessToPage && !isInternalTeam) {
    return <NoAccess />;
  }

  if (IS_FRONT_END_TEST) {
    return (
      <FinancialDashboardProvider>
        <NoRetroactiveWrapper />
      </FinancialDashboardProvider>
    );
  }

  const isOnboardingFinished = store?.is_onboarding_finished;

  if (!isOnboardingFinished && store?.type === EBusinessType.INFO_PRODUCT) {
    return <OnboardingInfoProductProcessingWrapper />;
  }

  if (!isOnboardingFinished) {
    return <OnboardingProcessingWrapper />;
  }

  return (
    <FinancialDashboardProvider>
      <Wrapper />
    </FinancialDashboardProvider>
  );
};

export default FinancialDashboard;
