import styled from 'styled-components';

import HeadingBase from '@components/common/core/DataDisplay/Heading';

import TextBase from '@components/common/core/DataDisplay/Text';

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 973px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  padding-top: 142px;
  padding-bottom: 120px;
  gap: 34px;

  @media only screen and (max-width: 1330px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media only screen and (max-width: 1115px) {
    width: unset;
    flex-direction: column;
    gap: 12px;
  }
`;

export const HeadingAndIntegrateFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const FormHeading = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.white.default};
  font-size: 1.75rem;
  margin-bottom: 32px;
  line-height: 120%;
`;

export const BenefitsWrapper = styled.div`
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 416px;
  border-radius: 7px;
  border: 1px solid ${({ theme }) => theme.colors.gray[4]};
  box-sizing: border-box;

  @media only screen and (max-width: 1115px) {
    max-width: unset;
  }
`;

export const BenefitsHeading = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.white.dark};
  margin-bottom: 24px;
`;

export const BenefitsBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
`;

export const Benefit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const BenefitDescription = styled(TextBase)`
  color: #fff;
`;
