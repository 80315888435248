import React from 'react';
import Paginate from '@components/common/core/Utils/Paginate';

import { useCheckoutFee } from '@helpers/hooks/pages/dashboard/checkoutFee/useCheckoutFee';
import EmptyData from '@components/common/core/DataDisplay/EmptyData';
import { EHeadAlign } from '@domain/enums/components/ETable';
import Row from './Row';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const HistoricTable: React.FC = () => {
  const {
    isLoadingCheckoutFee,
    isValidatingCheckoutFee,
    historicCheckoutFees,
    historicCheckoutFeePage,
    historicCheckoutFeePageCount,
    onHistoricCheckoutFeesPageChange,
  } = useCheckoutFee();

  return (
    <S.Table>
      <S.Table.Header>
        <S.Table.Row>
          <S.Table.Head>Data Inicial</S.Table.Head>
          <S.Table.Head>Data Final</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Tarifa</S.Table.Head>
          <S.Table.Head />
          <S.Table.Head />
        </S.Table.Row>
      </S.Table.Header>

      <S.Table.Body>
        {isLoadingCheckoutFee || isValidatingCheckoutFee ? (
          <>
            <SkeletonLoading borderBottom />
            <SkeletonLoading borderBottom />
            <SkeletonLoading borderBottom />
            <SkeletonLoading borderBottom />
            <SkeletonLoading />
          </>
        ) : (
          <>
            {historicCheckoutFees?.length ? (
              <>
                {historicCheckoutFees.map(checkoutFee => (
                  <Row key={checkoutFee.alias_id} checkoutFee={checkoutFee} />
                ))}
              </>
            ) : (
              <S.Table.Row>
                <S.Table.Data colSpan={7} align={EHeadAlign.CENTER}>
                  <EmptyData content="Não encontramos nenhuma taxa." />
                </S.Table.Data>
              </S.Table.Row>
            )}
          </>
        )}
      </S.Table.Body>

      {!isLoadingCheckoutFee && !isValidatingCheckoutFee && historicCheckoutFees?.length ? (
        <S.Table.Footer>
          <S.Table.Row>
            <S.Table.Data colSpan={4} align={EHeadAlign.RIGHT}>
              <Paginate
                forcePage={historicCheckoutFeePage}
                pageCount={historicCheckoutFeePageCount}
                onPageChange={onHistoricCheckoutFeesPageChange}
              />
            </S.Table.Data>
          </S.Table.Row>
        </S.Table.Footer>
      ) : (
        <></>
      )}
    </S.Table>
  );
};

export default HistoricTable;
