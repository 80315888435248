import React from 'react';

import Skeleton from 'react-loading-skeleton';

const SkeletonLoading: React.FC = () => {
  const randomArray = Array.from(Array(10).keys());

  return (
    <>
      {randomArray.map(value => {
        return (
          <Skeleton
            key={value}
            width={184}
            height={20}
            style={{ lineHeight: 'unset', marginBottom: 20 }}
          />
        );
      })}
    </>
  );
};

export default SkeletonLoading;
