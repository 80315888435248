import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';

export const PercentageWrapper = styled.div`
  width: 60px;
  margin-left: auto;
`;

export const DataTextWrapper = styled.div`
  width: 90px;
  margin-left: auto;
`;

export const DateWrapper = styled.div`
  width: 36px;
`;

export const TableHeadTextWrapper = styled.div``;

export const Table = styled(TableBase)`
  & > table > thead > tr {
    & > th {
      padding: 8px 10px !important;
    }

    & > th:first-child {
      padding-left: 28px !important;
      position: sticky;
      position: -webkit-sticky;
      left: 0;
      background-color: ${({ theme }) => theme.colors.gray[4]};
    }

    & > th:last-child {
      padding-right: 28px !important;
    }
  }

  & > table > tbody > tr {
    & > td {
      padding: 8px 10px !important;
    }

    & > td:first-child {
      padding-left: 28px !important;
    }

    & > td:last-child {
      padding-right: 28px !important;
    }

    &:hover {
      & > td:first-child {
        background-color: ${({ theme }) => theme.colors.gray[5]};
      }
    }
  }
`;
