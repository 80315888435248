/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Hubsale as HubsaleIcon } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { EGateway } from '@domain/enums/common/EGateway';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useDate } from '@helpers/hooks/useDate';

import { hubsaleSchema } from '@helpers/validators/dashboard/integrationCenter/hubsale';

import { getStoreProvider } from '@helpers/utils/common/integration';
import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { GATEWAYS } from '@constants/common/integrations';

import hubsaleCredentialService from '@services/pages/dashboard/integrationCenter/gateway/hubsale';

import Text from '@components/common/core/DataDisplay/Text';
import Save from '@components/Dashboard/IntegrationCenter/Sidemodal/Save';
import Form from '@components/common/core/Inputs/Form';
import Input from '@components/common/core/Inputs/TextField';
import SkeletonLoading from './SkeletonLoading';
import * as S from './styles';

const Hubsale: React.FC = () => {
  const foundIntegration = GATEWAYS.find(
    integration => integration.identifier === EGateway.HUB_SALE,
  ) as typeof GATEWAYS[number];

  const { mutateStore: mutateIntegrations, storeIntegrations } = useStoreConfig();

  const { toast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(hubsaleSchema),
  });
  const { storeAliasId } = useParams<IParams>();
  const { addSynchronization } = useSynchronization();
  const { format, subDays, utcToZonedTime } = useDate();

  const { hubsaleCredential, mutate, isLoading } = hubsaleCredentialService.getCredential({
    storeAliasId,
  });

  const [isLoadingSubmittedForm, setIsLoadingSubmittedForm] = React.useState<boolean>(false);
  const [isLoadingDeleteCredential, setIsLoadingDeleteCredential] = React.useState<boolean>(false);
  const [hasClientIdInputValue, setHasClientIdInputValue] = React.useState<boolean>(false);
  const [hasClientSecretInputValue, setHasClientSecretInputValue] = React.useState<boolean>(false);

  const onChangeClientId = React.useCallback(event => {
    if (event.target.value) {
      setHasClientIdInputValue(true);
    } else {
      setHasClientIdInputValue(false);
    }
  }, []);

  const onChangeClientSecret = React.useCallback(event => {
    if (event.target.value) {
      setHasClientSecretInputValue(true);
    } else {
      setHasClientSecretInputValue(false);
    }
  }, []);

  const onSubmit = React.useCallback(
    async formData => {
      setIsLoadingSubmittedForm(true);

      const lastThirtyDays = utcToZonedTime(subDays(new Date(), 29));

      const storeProvider = getStoreProvider(storeIntegrations);

      const hasGatewaySynchronization = storeProvider?.gateways.includes(EGateway.HUB_SALE);

      try {
        const params = `?start_date=${format(lastThirtyDays, 'yyyy-MM-dd')}&end_date=${format(
          utcToZonedTime(new Date()),
          'yyyy-MM-dd',
        )}`;

        if (hubsaleCredential?.id) {
          const response = await hubsaleCredentialService.updateCredential({
            storeAliasId,
            data: formData,
          });

          if (response && storeProvider && hasGatewaySynchronization) {
            addSynchronization({
              name: storeProvider.name,
              type: ESynchronizationType.STORE_GATEWAY,
              label: foundIntegration.name,
              dependencies: [ESynchronizationType.STORE_PRODUCT, ESynchronizationType.STORE_ORDER],
              storeAliasId,
              config: {
                isOnboardingSynchronization: false,
                showNotification: true,
              },
              request: storeProvider.generateGatewaySyncRequest(
                foundIntegration.synchronizationName,
                storeAliasId,
                params,
              ),
            });
          }
        } else {
          const response = await hubsaleCredentialService.createCredential({
            storeAliasId,
            data: formData,
          });

          if (response && storeProvider && hasGatewaySynchronization) {
            addSynchronization({
              name: storeProvider.name,
              type: ESynchronizationType.STORE_GATEWAY,
              label: foundIntegration.name,
              dependencies: [ESynchronizationType.STORE_PRODUCT, ESynchronizationType.STORE_ORDER],
              storeAliasId,
              config: {
                isOnboardingSynchronization: false,
                showNotification: true,
              },
              request: storeProvider.generateGatewaySyncRequest(
                foundIntegration.synchronizationName,
                storeAliasId,
                params,
              ),
            });
          }
        }

        await Promise.all([mutate(), mutateIntegrations()]);

        toast.success('Credencial salva com sucesso!');
      } catch (error: any) {
        toast.error(getTranslatedIntegrationsError(error));
      } finally {
        setIsLoadingSubmittedForm(false);
      }
    },
    [
      hubsaleCredential,
      storeAliasId,
      mutate,
      toast,
      mutateIntegrations,
      addSynchronization,
      subDays,
      format,
      utcToZonedTime,
      storeIntegrations,
      foundIntegration,
    ],
  );

  const handleDeleteCredential = React.useCallback(async () => {
    setIsLoadingDeleteCredential(true);

    try {
      await hubsaleCredentialService.deleteCredential({
        storeAliasId,
      });

      await Promise.all([mutate(), mutateIntegrations()]);

      toast.success('Credencial desativada com sucesso!');
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoadingDeleteCredential(false);
    }
  }, [storeAliasId, mutate, toast, mutateIntegrations]);

  if (isLoading) {
    return <SkeletonLoading />;
  }

  return (
    <S.ContentWrapper>
      <S.Header>
        <HubsaleIcon size={52} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com Doppus
        </S.Heading>
      </S.Header>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>
        <S.FunctionalityDescriptionText>
          A integração com seu Gateway de pagamento serve para
          <strong> puxarmos seu faturamento </strong>
          aumentando a<strong> exatidão na análise de seus dados.</strong>
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <S.TutorialWrapper>
        <S.TutorialHeading type={EHeadingSize.H5}>Tutorial de integração</S.TutorialHeading>
        <S.TutorialText>
          Para obter os campos
          <strong>Client ID e Client Secret, </strong>
          acesse seu
          <strong> painel da Doppus. Vá em Configurações &gt; API. </strong>
          depois clique em
          <strong> &apos;Nova chave&apos;.</strong>
        </S.TutorialText>
      </S.TutorialWrapper>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label htmlFor="hubsale-client-id">Client Id</S.Label>

          <Input
            {...register('client_id')}
            placeholder="Chave de Acesso Client Id"
            type="text"
            name="client_id"
            defaultValue={hubsaleCredential?.client_id}
            onChange={onChangeClientId}
            id="hubsale-client-id"
          />
          {errors.client_id && <Text isErrorFeedback>{errors.client_id.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label htmlFor="hubsale-client-secret">Client Secret</S.Label>

          <Input
            {...register('client_secret')}
            placeholder="Chave de Acesso Client Secret"
            type="text"
            name="client_secret"
            defaultValue={hubsaleCredential?.client_secret}
            onChange={onChangeClientSecret}
            id="hubsale-client-secret"
          />
          {errors.client_secret && <Text isErrorFeedback>{errors.client_secret.message}</Text>}
        </S.InputGroup>

        <Save
          onDelete={handleDeleteCredential}
          hasCredentials={Boolean(hubsaleCredential?.id)}
          isSubmitting={isLoadingSubmittedForm}
          isDeleting={isLoadingDeleteCredential}
          isDeleteButtonDisabled={isLoadingDeleteCredential || isLoadingSubmittedForm}
          isSaveButtonDisabled={
            (!hubsaleCredential?.id && (!hasClientIdInputValue || !hasClientSecretInputValue)) ||
            isLoadingSubmittedForm ||
            isLoadingDeleteCredential
          }
        />
      </Form>
    </S.ContentWrapper>
  );
};

export default Hubsale;
