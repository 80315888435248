import * as yup from 'yup';

export const noAutoFillSchema = yup.object().shape({
  zip_code: yup
    .string()
    .required('Este campo é obrigatório.')
    .test('zip code', 'Informe um CEP válido.', value => {
      if (value?.length === 9) return true;

      return false;
    }),
  city: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'Informe uma cidade válida.')
    .max(60, 'O número máximo de carácteres foi atingido.'),
  street: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'Informe um endereço válido.')
    .max(80, 'O número máximo de carácteres foi atingido.'),
  street_number: yup.string().required('Este campo é obrigatório.'),
  neighborhood: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'Informe um bairro válido.')
    .max(60, 'O número máximo de carácteres foi atingido.'),
  complementary: yup.string().max(200, 'O número máximo de carácteres foi atingido.'),
});

export const withAutoFillSchema = yup.object().shape({
  zip_code: yup
    .string()
    .required('Este campo é obrigatório.')
    .test('zip code', 'Informe um CEP válido.', value => {
      if (value?.length === 9) return true;

      return false;
    }),
  street_number: yup.string().required('Este campo é obrigatório.'),
  complementary: yup.string().max(200, 'O número máximo de carácteres foi atingido.'),
});

export const getAddressSchema = (hasAutoFill: boolean): yup.ObjectSchema => {
  return noAutoFillSchema;
};
