import React from 'react';

import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import { IOrder } from '@domain/interfaces/common/orders/IOrders';
import { INoInformationProvider } from '@domain/interfaces/dashboard/Feed/INoInformation';

import { useDate } from '@helpers/hooks/useDate';
import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import ordersService from '@services/pages/dashboard/orders/orders';
import feedService from '@services/pages/dashboard/feed/feed';

const NoInformationContext = React.createContext<INoInformationProvider | null>(null);

export const NoInformationProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { format, utcToZonedTime, subDays } = useDate();
  const { mutateFeedQuantity, mutateFeedGateways } = useStoreConfig();
  const { analytics, user } = useConfig();

  const [isLoadingNoInformationOrders, setIsLoadingNoInformationOrders] = React.useState<boolean>(
    true,
  );
  const [noInformationOrders, setNoInformationOrders] = React.useState<Array<IOrder>>([]);
  const [noInformationOrdersPage, setNoInformationOrdersPage] = React.useState<number>(0);
  const [noInformationOrdersPageCount, setNoInformationOrdersPageCount] = React.useState<number>(1);
  const [isSolvingOrder, setIsSolvingOrder] = React.useState<boolean>(false);

  const loadNoInformationOrders = React.useCallback(async () => {
    setIsLoadingNoInformationOrders(true);

    try {
      const { data } = await feedService.listFeedOrdersPromise({
        storeAliasId,
        page: noInformationOrdersPage,
        startDate: format(subDays(utcToZonedTime(new Date()), 29), 'yyyy-MM-dd'),
        endDate: format(utcToZonedTime(new Date()), 'yyyy-MM-dd'),
        filter: {
          gateway_name: 'MANUAL',
          status: 'NO_INFORMATION',
          type: 'NO_INFORMATION',
          order_field: 'provider_created_at',
        },
      });

      if (data) {
        setNoInformationOrders(currentNoInformationOrders => [
          ...currentNoInformationOrders,
          ...data.orders,
        ]);
        setNoInformationOrdersPageCount(data.total_pages);
      }

      setIsLoadingNoInformationOrders(false);
    } catch (error: any) {
      setIsLoadingNoInformationOrders(false);
      toast.error(error?.response?.data?.message);
    }
  }, [format, noInformationOrdersPage, storeAliasId, subDays, toast, utcToZonedTime]);

  const handleLoadMore = React.useCallback(() => {
    setNoInformationOrdersPage(oldPage => oldPage + 1);
  }, []);

  const updateOrder = React.useCallback(
    async (order: IOrder) => {
      await ordersService.updateOrder({
        storeAliasId,
        orderAliasId: order.alias_id,
        order,
      });
    },
    [storeAliasId],
  );

  const solveNoInformationOrder = React.useCallback(
    async (order: IOrder) => {
      setIsSolvingOrder(true);

      try {
        await updateOrder(order);

        setNoInformationOrders(currentOrders => {
          const filteredOrders = currentOrders.filter(
            currentOrder => currentOrder.alias_id !== order.alias_id,
          );

          return filteredOrders;
        });

        analytics?.track(
          'Task Feed Solved',
          { type: 'NO_INFORMATION', email: user?.email },
          { context: { groupId: storeAliasId } },
        );
        analytics?.trackHubSpot(storeAliasId, { task_feed_solved: true });

        mutateFeedQuantity();
        mutateFeedGateways();

        toast.success('Pedido atualizado com sucesso!');

        setIsSolvingOrder(false);
      } catch (error: any) {
        setIsSolvingOrder(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [toast, updateOrder, mutateFeedQuantity, analytics, storeAliasId, mutateFeedGateways, user],
  );

  const hasMore = noInformationOrdersPage + 1 < noInformationOrdersPageCount;

  return (
    <NoInformationContext.Provider
      value={{
        noInformationOrders,
        isLoadingNoInformationOrders,
        handleLoadMore,
        hasMore,
        loadNoInformationOrders,
        isSolvingOrder,
        solveNoInformationOrder,
      }}
    >
      {children}
    </NoInformationContext.Provider>
  );
};

export const useNoInformation = (): INoInformationProvider => {
  const context = React.useContext(NoInformationContext);

  if (!context) {
    throw new Error('useNoInformation must be used within NoInformationContext');
  }

  return context;
};
