import React from 'react';

import { Yampi as YampiIcon } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';

import yampiAuthService from '@services/pages/dashboard/integrationCenter/storeProvider/yampi/yampiAuth';

import ComponentError from '@components/common/core/Utils/ComponentError';
import SkeletonLoading from '../SkeletonLoading';
import EnableIntegration from './EnableIntegration';
import CredentialOption from './CredentialOption';
import SyncOption from './SyncOption';
import GatewayOption from './GatewayOption';

import * as S from './styles';

const YampiConfig: React.FC = () => {
  const theme: any = useTheme();
  const { storeAliasId } = useParams<IParams>();

  const {
    yampiCredential,
    isLoading,
    isValidating,
    mutate,
    error,
  } = yampiAuthService.getYampiCredential({ storeAliasId });

  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const onChangeTab = React.useCallback(index => setSelectedTab(index), []);

  if (isLoading || isValidating) {
    return <SkeletonLoading />;
  }

  if (error && !isValidating) {
    return <ComponentError mutate={mutate} />;
  }

  if (!yampiCredential.is_active) {
    return <EnableIntegration yampiCredential={yampiCredential} mutate={mutate} />;
  }

  return (
    <S.ContentWrapper>
      <S.Header>
        <YampiIcon size={52} color={theme.colors.gray[2]} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.REGULAR}>
          Integração com Yampi Loja
        </S.Heading>
      </S.Header>

      <S.Tab defaultTab={selectedTab} onChangeTab={onChangeTab}>
        <S.Option>Geral</S.Option>
        <S.Option>Sincronização</S.Option>
        <S.Option>Gateway</S.Option>
      </S.Tab>

      {selectedTab === 0 && <CredentialOption yampiCredential={yampiCredential} mutate={mutate} />}

      {selectedTab === 1 && <SyncOption />}

      {selectedTab === 2 && <GatewayOption />}
    </S.ContentWrapper>
  );
};

export default YampiConfig;
