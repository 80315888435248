import React from 'react';
import { useParams } from 'react-router-dom';
import { isAfter } from 'date-fns';
import { useToast } from '@helpers/hooks/useToast';

import { EStatus } from '@domain/enums/subscription/ESubscription';
import { ESubscriptionPlanChangeStatus } from '@domain/enums/common/subscription/ESubscriptionPlanChangeStatus';
import { ETransactionStatus } from '@domain/enums/common/transaction/ETransactionStatus';
import { IParams } from '@domain/interfaces/IParams';
import { ISubscriptionStatusProvider } from '@domain/interfaces/hooks/ISubscriptionStatus';
import { ISubscriptionPlanChange } from '@domain/interfaces/common/subscription/ISubscriptionPlanChange';
import { IPlan } from '@domain/interfaces/common/plan/IPlan';
import { IPaymentMethod } from '@domain/interfaces/common/paymentMethod/IPaymentMethod';

import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { useTransaction } from '@helpers/hooks/common/store/subscription/useTransaction';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import subscriptionPlanChangeService from '@services/common/subscription/subscriptionPlanChange';
import paymentMethodService from '@services/common/paymentMethod/paymentMethod';

export const SubscriptionStatusContext = React.createContext<ISubscriptionStatusProvider | null>(
  null,
);

export const SubscriptionStatusProvider: React.FC = ({ children }) => {
  const { subscription, mutateSubscription } = useStoreSubscription();
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { mutateTransactions, transactions } = useTransaction();
  const { handleResyncData } = useSynchronization();

  const [hasExceededOrders, setHasExceededOrders] = React.useState<boolean>(false);
  const [isPaymentPending, setIsPaymentPending] = React.useState<boolean>(false);
  const [isPaymentRefused, setIsPaymentRefused] = React.useState<boolean>(false);
  const [isCanceledAndPaymentRefused, setIsCanceledAndPaymentRefused] = React.useState<boolean>(
    false,
  );
  const [isCancelledByCreditCardIssue, setIsCancelledByCreditCardIssue] = React.useState<boolean>(
    false,
  );
  const [hasFreePlanExceedOrders, setHasFreePlanExceedOrders] = React.useState<boolean>(false);
  const [isProcessingInUpdate, setIsProcessingInUpdate] = React.useState<boolean>(false);
  const [isProcessingInCreate, setIsProcessingInCreate] = React.useState<boolean>(false);
  const [
    isVerifyingProcessingPaymentInCreate,
    setIsVerifyingProcessingPaymentInCreate,
  ] = React.useState<boolean>(false);
  const [
    isVerifyingProcessingPaymentInUpdate,
    setIsVerifyingProcessingPaymentInUpdate,
  ] = React.useState<boolean>(false);
  const [isProcessingPaymentAlertOpen, setIsProcessingPaymentAlertOpen] = React.useState<boolean>(
    false,
  );
  const [subscriptionPlanChange, setSubscriptionPlanChange] = React.useState<
    ISubscriptionPlanChange | undefined
  >(undefined);
  const [subscriptionPlanChangePlan, setSubscriptionPlanChangePlan] = React.useState<
    IPlan | undefined
  >(undefined);
  const [paymentMethod, setPaymentMethod] = React.useState<IPaymentMethod | undefined>(undefined);

  const reset = React.useCallback(async () => {
    await mutateSubscription();

    setIsProcessingInCreate(false);
    setIsProcessingInUpdate(false);
    setIsVerifyingProcessingPaymentInCreate(false);
    setIsVerifyingProcessingPaymentInUpdate(false);
    setIsProcessingPaymentAlertOpen(false);
    setSubscriptionPlanChange(undefined);
    setSubscriptionPlanChangePlan(undefined);
  }, [mutateSubscription]);

  const handleProcessingPaymentAlertOpen = React.useCallback(
    state => setIsProcessingPaymentAlertOpen(state),
    [],
  );

  const loadSubscriptionPlanChange = React.useCallback(async () => {
    try {
      const subscriptionPlanChangePromise = subscriptionPlanChangeService.getSubscriptionPlanChangePromise(
        {
          storeAliasId,
          subscriptionAliasId: subscription.alias_id,
        },
      );

      const paymentMethodPromise = paymentMethodService.getPaymentMethodPromise({ storeAliasId });

      const [subscriptionPlanChangeResponse, paymentMethodResponse] = await Promise.all([
        subscriptionPlanChangePromise,
        paymentMethodPromise,
      ]);

      const { data: subscriptionPlanChangeData } = subscriptionPlanChangeResponse;
      const { data: paymentMethodData } = paymentMethodResponse;

      if (!subscriptionPlanChangeData.subscription_plan_change) return;

      setPaymentMethod(paymentMethodData.payment_method);
      setSubscriptionPlanChange(subscriptionPlanChangeData.subscription_plan_change);
      setSubscriptionPlanChangePlan(subscriptionPlanChangeData.plan);

      const isSubscriptionPlanChangeUpdateAtAfterSubscriptionCreatedAt = isAfter(
        new Date(subscriptionPlanChangeData.subscription_plan_change.updated_at),
        new Date(subscription.created_at),
      );

      if (!isSubscriptionPlanChangeUpdateAtAfterSubscriptionCreatedAt) return;

      if (
        subscriptionPlanChangeData.subscription_plan_change.status ===
        ESubscriptionPlanChangeStatus.FAILED
      ) {
        setIsProcessingPaymentAlertOpen(true);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [subscription, storeAliasId, toast]);

  const verifyProcessingPaymentInCreate = React.useCallback(() => {
    const interval = setInterval(async () => {
      const { data } = await mutateSubscription();

      const mutatedSubscription = data?.subscription;

      if (mutatedSubscription?.status === EStatus.PAID) {
        const dataLayer = (window as Record<string, any>)?.dataLayer;

        if (dataLayer) {
          dataLayer.push({
            event: 'Subscription Started',
            subscription_recurrence: mutatedSubscription?.recurrence,
            subscription_price: mutatedSubscription?.plan?.amount,
            subscription_plan: mutatedSubscription?.plan?.identifier,
          });
        }
      }

      if (mutatedSubscription?.status !== EStatus.PROCESSING_PAYMENT_IN_CREATE) {
        clearInterval(interval);
        setIsVerifyingProcessingPaymentInCreate(false);
        setIsProcessingInCreate(false);
        mutateTransactions();
      }
    }, 10000);
  }, [mutateSubscription, mutateTransactions]);

  const verifyProcessingPaymentInUpdate = React.useCallback(() => {
    const interval = setInterval(async () => {
      const { data } = await subscriptionPlanChangeService.getSubscriptionPlanChangePromise({
        storeAliasId,
        subscriptionAliasId: subscription.alias_id,
      });

      const subscriptionPlanChangeData = data?.subscription_plan_change;

      if (subscriptionPlanChangeData?.status === ESubscriptionPlanChangeStatus.SUCCESS) {
        const dataLayer = (window as Record<string, any>)?.dataLayer;

        if (dataLayer) {
          dataLayer.push({
            event: 'Subscription Started',
            subscription_recurrence: subscription?.recurrence,
            subscription_price: subscription?.plan?.amount,
            subscription_plan: subscription?.plan?.identifier,
          });
        }

        handleResyncData({
          storeAliasId,
          daysToSync: 30,
        });

        clearInterval(interval);
        mutateSubscription();
        setIsProcessingInUpdate(false);
        setIsVerifyingProcessingPaymentInUpdate(false);
        mutateTransactions();
      }

      if (subscriptionPlanChangeData?.status === ESubscriptionPlanChangeStatus.FAILED) {
        clearInterval(interval);
        setIsProcessingInUpdate(false);
        setIsVerifyingProcessingPaymentInUpdate(false);

        const isSubscriptionPlanChangeUpdateAtAfterSubscriptionCreatedAt = isAfter(
          new Date(data.subscription_plan_change.updated_at),
          new Date(subscription.created_at),
        );

        setSubscriptionPlanChange(data.subscription_plan_change);
        setSubscriptionPlanChangePlan(data.plan);
        mutateTransactions();

        if (isSubscriptionPlanChangeUpdateAtAfterSubscriptionCreatedAt) {
          setIsProcessingPaymentAlertOpen(true);
        }
      }
    }, 10000);
  }, [mutateSubscription, storeAliasId, subscription, mutateTransactions, handleResyncData]);

  React.useEffect(() => {
    if (!transactions || !transactions?.length) return;

    const transactionsCopy = [...transactions];

    const lastTransaction = transactionsCopy.shift();

    // if (
    //   lastTransaction?.status === ETransactionStatus.CANCELED &&
    //   subscription?.status === EStatus.CANCELED &&
    //   subscription?.cancellation_reason === 'CREDIT_CARD_ISSUE' &&
    //   lastTransaction?.total_orders_exceed_quantity > 0
    // ) {
    //   setIsCancelledByCreditCardIssue(true);
    // }

    if (
      lastTransaction?.status === ETransactionStatus.CANCELED &&
      subscription?.status === EStatus.CANCELED &&
      subscription?.cancellation_reason === 'CREDIT_CARD_ISSUE'
    ) {
      setIsCancelledByCreditCardIssue(true);
    }
  }, [subscription, transactions]);

  React.useEffect(() => {
    if (subscription?.status === EStatus.PROCESSING_PAYMENT_IN_CREATE) {
      setIsProcessingInCreate(true);
    }

    if (subscription?.status === EStatus.PROCESSING_PAYMENT_IN_CREATE_FAILED) {
      setIsProcessingInCreate(false);
      setIsProcessingPaymentAlertOpen(true);
    }

    if (subscriptionPlanChange?.status === ESubscriptionPlanChangeStatus.PROCESSING) {
      setIsProcessingInUpdate(true);
    }
  }, [subscription, subscriptionPlanChange]);

  React.useEffect(() => {
    if (isProcessingInCreate && !isVerifyingProcessingPaymentInCreate) {
      setIsVerifyingProcessingPaymentInCreate(true);
      verifyProcessingPaymentInCreate();
    }

    if (isProcessingInUpdate && !isVerifyingProcessingPaymentInUpdate) {
      setIsVerifyingProcessingPaymentInUpdate(true);
      verifyProcessingPaymentInUpdate();
    }
  }, [
    isProcessingInCreate,
    isProcessingInUpdate,
    verifyProcessingPaymentInCreate,
    verifyProcessingPaymentInUpdate,
    isVerifyingProcessingPaymentInCreate,
    isVerifyingProcessingPaymentInUpdate,
  ]);

  React.useEffect(() => {
    if (subscription) {
      loadSubscriptionPlanChange();
    }
  }, [loadSubscriptionPlanChange, subscription]);

  React.useEffect(() => {
    if (!subscription) return;

    if (subscription?.status === EStatus.TRIAL_ENDED) return;

    if (!transactions?.length) return;

    const lastStoreTransaction = transactions[0];
    const isProcessingPayment =
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_CREATE ||
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_UPDATE ||
      subscriptionPlanChange?.status === ESubscriptionPlanChangeStatus.PROCESSING;
    const isPaymentFailed =
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_CREATE_FAILED ||
      subscriptionPlanChange?.status === ESubscriptionPlanChangeStatus.FAILED;

    if (
      subscription.exceeded_order_limit_at &&
      !subscription?.plan?.identifier.includes('zouti') &&
      !subscription?.plan?.identifier.includes('unlimited') &&
      subscription?.plan?.identifier !== 'free_monthly_v1'
    ) {
      setHasExceededOrders(true);
    }

    if (
      subscription.exceeded_order_limit_at &&
      subscription?.plan?.identifier === 'free_monthly_v1'
    ) {
      setHasFreePlanExceedOrders(true);
    }

    if (
      subscription.status === EStatus.PAID &&
      lastStoreTransaction?.status === ETransactionStatus.PENDING &&
      !isProcessingPayment &&
      !isPaymentFailed
    ) {
      setIsPaymentPending(true);
    }

    if (
      subscription.status === EStatus.CANCELED &&
      lastStoreTransaction?.status === ETransactionStatus.CANCELED &&
      !isProcessingPayment &&
      !isPaymentFailed
    ) {
      setIsPaymentRefused(true);
      setIsCanceledAndPaymentRefused(true);
    }
  }, [subscription, transactions, subscriptionPlanChange]);

  return (
    <SubscriptionStatusContext.Provider
      value={{
        handleProcessingPaymentAlertOpen,
        isProcessingPaymentAlertOpen,
        subscriptionPlanChange,
        subscriptionPlanChangePlan,
        reset,
        paymentMethod,
        hasExceededOrders,
        hasFreePlanExceedOrders,
        isCanceledAndPaymentRefused,
        isPaymentPending,
        isPaymentRefused,
        isCancelledByCreditCardIssue,
      }}
    >
      {children}
    </SubscriptionStatusContext.Provider>
  );
};

export const useSubscriptionStatus = (): ISubscriptionStatusProvider => {
  const context = React.useContext(SubscriptionStatusContext);

  if (!context) {
    throw new Error('useSubscriptionStatus must be used within SubscriptionProvider');
  }

  return context;
};
