import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Header = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  padding: 12px 28px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(TextBase)`
  line-height: 120%;
  color: #ffffff;
`;
