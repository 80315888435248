import React from 'react';
import { useTheme } from 'styled-components';
import { FloppyDisk, X as CloseIcon } from 'phosphor-react';

import { ISaveAutomationModalProps } from '@domain/interfaces/dashboard/Automations/Canvas/SaveAutomationModal/ISaveAutomationModal';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';

import SelectInstances from './SelectInstances';
import EmptyInstance from './EmptyInstances';

import * as S from './styles';

const SaveAutomationModal: React.FC<ISaveAutomationModalProps> = ({ isOpen, toggle }) => {
  const { colors } = useTheme();
  const { whatsappCredentials } = useAutomation();

  const hasWhatsappInstances = whatsappCredentials.length > 0;

  return (
    <S.Wrapper isOpen={isOpen} toggle={toggle}>
      <S.HeaderWrapper>
        <S.GroupTitle>
          <FloppyDisk size={28} color={colors.gray[1]} />
          <S.Title>Salvar automação</S.Title>
        </S.GroupTitle>
        <S.CloseButton onClick={toggle}>
          <CloseIcon size={20} color={colors.gray[3]} />
        </S.CloseButton>
      </S.HeaderWrapper>

      {!hasWhatsappInstances && <EmptyInstance />}

      {hasWhatsappInstances && <SelectInstances toggle={toggle} />}
    </S.Wrapper>
  );
};

export default SaveAutomationModal;
