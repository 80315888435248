import React from 'react';

import {
  getBadgeColors,
  getBadgeIconColors,
  getBadgeIcons,
  getParsedStatus,
} from '@helpers/dashboard/orders/ordersUtils';

import { IOrderTableDataProps } from '@domain/interfaces/dashboard/Orders/IOrderTableData';

import Table from '@components/common/core/Utils/Table';
import { CheckCircle } from 'phosphor-react';

import * as S from './styles';

const Status: React.FC<IOrderTableDataProps> = ({ order }) => {
  const orderStatus = order.status;

  const status = getParsedStatus(orderStatus);
  const badgeColor = getBadgeIconColors(orderStatus);
  const Icon = getBadgeIcons(orderStatus);

  return (
    <Table.Data>
      <S.StatusWrapper status={orderStatus}>
        <Icon size={14} color={badgeColor} />
        <S.Text>{status}</S.Text>
      </S.StatusWrapper>
    </Table.Data>
  );
};

export default Status;
