import React from 'react';

import { INavigationTabProps } from '@domain/interfaces/dashboard/FinancialData/INavigationTab';
import Tab from '@components/common/core/Navigation/Tab';

import * as S from './styles';

const NavigationTab: React.FC<INavigationTabProps> = () => {
  return (
    <S.NavigationTabWrapper>
      <Tab defaultTab={0} onChangeTab={() => console.log('change')}>
        <Tab.Option>Dados financeiros</Tab.Option>
        <Tab.Option>Financeiro</Tab.Option>
      </Tab>
    </S.NavigationTabWrapper>
  );
};

export default NavigationTab;
