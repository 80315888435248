import React from 'react';
import { Box } from '@profitfy/pakkun-icons';

import { IStoreOrdersQuantityProps } from '@domain/interfaces/pricing/IStoreOrdersQuantity';

import * as S from './styles';

const StoreOrdersQuantity: React.FC<IStoreOrdersQuantityProps> = ({ quantity }) => {
  if (quantity <= 0) {
    return <></>;
  }

  return (
    <S.Wrapper>
      <Box size={32} />
      <S.Text>
        Você teve
        <strong>{` ${quantity} `}</strong>
        pedidos pagos
        <strong> nos últimos 30 dias. </strong>
        Use essa informação para ver qual plano mais combina com você.
      </S.Text>
    </S.Wrapper>
  );
};

export default StoreOrdersQuantity;
