import React from 'react';

import Skeleton from 'react-loading-skeleton';

import { EHeadAlign } from '@domain/enums/components/ETable';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  const arrayOfTenPositions = Array.from(Array(5).keys());

  return (
    <S.Wrapper>
      <S.Table>
        <S.Table.Header>
          <S.Table.Row>
            <S.Table.Head />
            <S.Table.Head align={EHeadAlign.CENTER}>Produto</S.Table.Head>
            <S.Table.Head align={EHeadAlign.RIGHT}>Receita Líquida</S.Table.Head>
            <S.Table.Head align={EHeadAlign.RIGHT}>Pendentes</S.Table.Head>
            <S.Table.Head align={EHeadAlign.RIGHT}>C. Produto</S.Table.Head>
            <S.Table.Head align={EHeadAlign.RIGHT}>Marketing</S.Table.Head>
            {/* <S.Table.Head align={EHeadAlign.CENTER}>Margem de Lucro</S.Table.Head> */}
            <S.Table.Head align={EHeadAlign.RIGHT}>Impostos e Tarifas</S.Table.Head>
            <S.Table.Head align={EHeadAlign.RIGHT}>CPA</S.Table.Head>
            <S.Table.Head align={EHeadAlign.CENTER}>Conv. Boleto</S.Table.Head>
            <S.Table.Head align={EHeadAlign.RIGHT}>Lucro Líquido</S.Table.Head>
            <S.Table.Head align={EHeadAlign.CENTER} />
            <S.Table.Head align={EHeadAlign.CENTER} />
          </S.Table.Row>
        </S.Table.Header>

        <S.Table.Body>
          {arrayOfTenPositions.map(value => (
            <S.Table.Row key={value} borderBottom>
              <S.Table.Data>
                <S.RankingWrapper>
                  <Skeleton width="100%" height={20} />
                </S.RankingWrapper>
              </S.Table.Data>

              <S.Table.Data>
                <S.ImageWrapper>
                  <Skeleton width="100%" height={48} />
                </S.ImageWrapper>
              </S.Table.Data>

              <S.Table.Data>
                <S.NetRevenueWrapper>
                  <Skeleton width="100%" height={20} />
                </S.NetRevenueWrapper>
              </S.Table.Data>

              <S.Table.Data>
                <S.CpaWrapper>
                  <Skeleton width="100%" height={20} />
                </S.CpaWrapper>
              </S.Table.Data>

              <S.Table.Data>
                <S.CogsWrapper>
                  <Skeleton width="100%" height={20} />
                </S.CogsWrapper>
              </S.Table.Data>

              <S.Table.Data>
                <S.MarketingWrapper>
                  <Skeleton width="100%" height={20} />
                </S.MarketingWrapper>
              </S.Table.Data>

              {/* <S.Table.Data>
                <S.ProfitMarginWrapper>
                  <Skeleton width="100%" height={20} />
                </S.ProfitMarginWrapper>
              </S.Table.Data> */}

              <S.Table.Data>
                <S.TaxesWrapper>
                  <Skeleton width="100%" height={20} />
                </S.TaxesWrapper>
              </S.Table.Data>

              <S.Table.Data>
                <S.CpaWrapper>
                  <Skeleton width="100%" height={20} />
                </S.CpaWrapper>
              </S.Table.Data>

              <S.Table.Data>
                <S.ConversionWrapper>
                  <Skeleton width="100%" height={40} />
                </S.ConversionWrapper>
              </S.Table.Data>

              <S.Table.Data>
                <S.NetProfitWrapper>
                  <Skeleton width="100%" height={20} />
                </S.NetProfitWrapper>
              </S.Table.Data>

              <S.Table.Data>
                <S.FavoriteIconWrapper>
                  <Skeleton width={20} height={20} />
                </S.FavoriteIconWrapper>
              </S.Table.Data>

              <S.Table.Data />
            </S.Table.Row>
          ))}
        </S.Table.Body>
      </S.Table>
    </S.Wrapper>
  );
};

export default SkeletonLoading;
