import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';
import { ShoppingCart } from 'phosphor-react';

export const ABANDONED_CART_2 = [
  {
    id: 'abandoned-cart-coupon-first-condition',
    title: 'Carrinho abandonado com cupom (1ª condição)',
    type: 'ABANDONED_CART',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: ShoppingCart,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Pode comemorar, {{first_name}}! 🎉 \n\nVocê ganhou 10% de desconto para finalizar sua compra! 🎁\n\nClique no link abaixo e utilize o cupom: BLABLA10\n\n{{checkout_link}}',
      },
    ],
  },
  {
    id: 'abandoned-cart-coupon-second-condition',
    title: 'Carrinho abandonado com cupom (2ª condição)',
    type: 'ABANDONED_CART',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: ShoppingCart,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          '{{first_name}}, você não utilizou o seu cupom de desconto? 😥\n\nNão perca essa chance, porque é por tempo limitado!\n\nClique no link abaixo e utilize o cupom: BLABLA10\n\n{{checkout_link}}',
      },
    ],
  },
  {
    id: 'abandoned-cart-coupon-third-condition',
    title: 'Carrinho abandonado com cupom (3ª condição)',
    type: 'ABANDONED_CART',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: ShoppingCart,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá, {{first_name}}! 😊\n\nInfelizmente o seu cupom expirou, mas ainda é possível finalizar sua compra! 🛍️\n\nClique no link abaixo e garanta o seu carrinho:  🛒\n\n{{checkout_link}}\n\nE, se precisar de alguma ajuda, estamos aqui! 🤩',
      },
    ],
  },
];
