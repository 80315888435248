import React from 'react';

import { useSubscriptionStatus } from '@helpers/hooks/common/store/subscription/useSubscriptionStatus';

import ProcessingPayment from './ProcessingPayment';

const Alerts: React.FC = () => {
  const { isProcessingPaymentAlertOpen } = useSubscriptionStatus();

  return <>{isProcessingPaymentAlertOpen && <ProcessingPayment />}</>;
};

export default Alerts;
