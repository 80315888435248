import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';
import { Truck } from 'phosphor-react';

export const SIMPLE_SHIPMENT = [
  {
    id: 'simple-shipment-first-condition',
    title: 'Rastreio criado',
    type: 'SIMPLE_SHIPMENT',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: Truck,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá {{first_name}}! 😀\n\nSeu pedido #{{order_id}} já foi enviado e pode ser rastreado pelo número {{tracking_number}}.\n\nQualquer dúvida, estamos à disposição. Obrigado por escolher {{store_name}}.',
      },
    ],
  },
];
