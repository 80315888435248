import React from 'react';

import { SubscriptionPlansProvider } from '@helpers/hooks/pages/dashboard/subscription/useSubscriptionPlans';

import AvailablePlansList from './AvailablePlansList';

const Plans: React.FC = () => {
  return (
    <SubscriptionPlansProvider>
      <AvailablePlansList />
    </SubscriptionPlansProvider>
  );
};

export default Plans;
