import React from 'react';
import { Pencil } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { EPersonType } from '@domain/enums/common/personType/EPersonType';
import { IParams } from '@domain/interfaces/IParams';
import { IUpdateLegalPersonData } from '@domain/interfaces/common/legalPerson/ILegalPerson';
import { ICreateLegalPersonData } from '@domain/interfaces/subscription/ILegalPerson';
import {
  ICreateNaturalPersonData,
  IUpdateNaturalPersonData,
} from '@domain/interfaces/common/naturalPerson/INaturalPerson';

import {
  legalPersonSchema,
  naturalPersonSchema,
} from '@helpers/validators/dashboard/subscription/updateBillingData';

import { useToast } from '@helpers/hooks/useToast';
import { useSubscriptionData } from '@helpers/hooks/pages/dashboard/subscription/useSubscriptionData';

import {
  parseAddressForm,
  parsePersonalInfoForm,
} from '@helpers/utils/subscription/checkout/formData';

import addressService from '@services/common/address/address';
import legalPersonService from '@services/common/legalPerson/legalPerson';
import naturalPersonService from '@services/common/naturalPerson/naturalPerson';

import Form from '@components/common/core/Inputs/Form';
import AddressForm from './AddressForm';
import LegalPersonForm from './LegalPersonForm';
import NaturalPersonForm from './NaturalPersonForm';

import * as S from './styles';

const UpdatePaymentMethod: React.FC<any> = ({ isOpen, toggle }) => {
  const { legalPerson, naturalPerson, address, mutateData } = useSubscriptionData();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const theme = useTheme();

  const schema = legalPerson ? legalPersonSchema : naturalPersonSchema;

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'onBlur', reValidateMode: 'onChange', resolver: yupResolver(schema) });

  const [isUpdatingBillingData, setIsUpdatingBillingData] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async data => {
      setIsUpdatingBillingData(true);

      try {
        const parsedAddressForm = parseAddressForm(data);

        if (address) {
          await addressService.updateAddress({ storeAliasId, data: parsedAddressForm });
        } else {
          await addressService.createAddress({ storeAliasId, data: parsedAddressForm });
        }

        const personType = legalPerson ? EPersonType.LEGAL_PERSON : EPersonType.NATURAL_PERSON;

        const parsedPersonalInfoForm = parsePersonalInfoForm(data, personType);

        if (personType === EPersonType.LEGAL_PERSON && !legalPerson) {
          await legalPersonService.createLegalPerson({
            storeAliasId,
            data: parsedPersonalInfoForm as ICreateLegalPersonData,
          });
        }

        if (personType === EPersonType.LEGAL_PERSON && legalPerson) {
          await legalPersonService.updateLegalPerson({
            storeAliasId,
            data: parsedPersonalInfoForm as IUpdateLegalPersonData,
          });
        }

        if (personType === EPersonType.NATURAL_PERSON && !naturalPerson) {
          await naturalPersonService.createNaturalPerson({
            storeAliasId,
            data: parsedPersonalInfoForm as ICreateNaturalPersonData,
          });
        }

        if (personType === EPersonType.NATURAL_PERSON && naturalPerson) {
          await naturalPersonService.updateNaturalPerson({
            storeAliasId,
            data: parsedPersonalInfoForm as IUpdateNaturalPersonData,
          });
        }

        setIsUpdatingBillingData(false);

        toggle();

        mutateData();
      } catch (error: any) {
        setIsUpdatingBillingData(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [address, legalPerson, naturalPerson, storeAliasId, toast, toggle, mutateData],
  );

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Header>
        <Pencil size={24} color={theme.colors.gray[1]} />
        <S.Title type={EHeadingSize.H5}>Editar informações de cobrança</S.Title>
      </S.Header>

      <S.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <AddressForm
            register={register}
            setValue={setValue}
            errors={errors}
            clearErrors={clearErrors}
          />

          {legalPerson ? (
            <LegalPersonForm errors={errors} register={register} setValue={setValue} />
          ) : (
            <NaturalPersonForm errors={errors} register={register} setValue={setValue} />
          )}
          {/* <NaturalPersonForm errors={errors} register={register} setValue={setValue} /> */}

          <S.SubmitButton
            type="submit"
            isLoading={isUpdatingBillingData}
            disabled={isUpdatingBillingData}
          >
            Atualizar dados
          </S.SubmitButton>
        </Form>
      </S.Body>
    </S.Modal>
  );
};

export default UpdatePaymentMethod;
