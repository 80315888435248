import React from 'react';
import { WarningCircle } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import * as S from './styles';

const WarningIcon: React.FC = () => {
  const theme = useTheme();

  return (
    <S.Wrapper>
      <WarningCircle size={22} color={theme.colors.warning.default} />
    </S.Wrapper>
  );
};

export default WarningIcon;
