import * as yup from 'yup';

export const createProductTagSchema = yup.object().shape({
  new_tag: yup
    .string()
    .required('Este campo é obrigatório.')
    .test('special characters', 'Insira apenas letras ou números.', value => {
      return !/([^0-9a-zA-Z ])/g.test(value || '');
    }),
});

export const updateProductTagSchema = yup.object().shape({
  tag: yup
    .string()
    .required('Este campo é obrigatório.')
    .test('special characters', 'Insira apenas letras ou números.', value => {
      return !/([^0-9a-zA-Z ])/g.test(value || '');
    }),
});
