import styled from 'styled-components';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Modal = styled(ModalBase)`
  max-width: 472px;
  box-sizing: border-box;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
  justify-content: flex-end;
`;

export const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;
`;

export const Question = styled(TextBase)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray[1]};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.16px;
`;

export const Text = styled(TextBase)`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray[1]};
  line-height: 150%;
  display: inline-block;
  margin-bottom: 24px;
  margin-top: 24px;
`;

export const ConfirmButton = styled(ButtonBase)``;

export const CancelButton = styled.button`
  border: 0;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.background[2]};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Mukta';
  font-weight: 500;
  font-size: 0.875rem;
  height: 42px;
  padding: 12px 22px;
  color: ${({ theme }) => theme.colors.gray[1]};
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    color: #fff;
  }
`;
