import React from 'react';

import { Plus } from '@profitfy/pakkun-icons';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { useOnboardingTag } from '@helpers/hooks/pages/dashboard/productAnalytics/useOnboardingTag';
import { EButtonSize, EButtonVariant } from '@domain/enums/components/EButton';
import { useConfig } from '@helpers/hooks/useConfig';

import TagWrapper from './TagWrapper';
import NewTagSideModal from './NewTagSideModal';

import * as S from './styles';

const MyTags: React.FC = () => {
  const { analytics, selectedStore } = useConfig();
  const {
    productTags,
    handleSyncCampaigns,
    isSyncCampaigns,
    isCompletingOnboarding,
  } = useOnboardingTag();

  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const handleModalOpen = React.useCallback(() => {
    setIsModalOpen(currentState => !currentState);
  }, []);

  const onSync = React.useCallback(() => {
    const trackContext = {
      context: { groupId: selectedStore?.alias_id },
    };

    analytics?.track(
      'Product Tags Onboarding Cost Sync Campaigns Button Clicked',
      {},
      trackContext,
    );

    analytics?.track('Product Tags Onboarding Finished', {}, trackContext);

    handleSyncCampaigns();
  }, [handleSyncCampaigns, analytics, selectedStore]);

  return (
    <>
      <S.Heading type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
        Suas Tags
      </S.Heading>

      <S.TagsWrapper>
        {productTags.map(productTag => (
          <TagWrapper productTag={productTag} key={productTag.alias_id} />
        ))}
      </S.TagsWrapper>

      <S.ButtonsWrapper>
        <S.AddTagButton
          onClick={handleModalOpen}
          iconPrepend={() => <Plus size={10} color="#ffffff" />}
          variant={EButtonVariant.SECONDARY_BUTTON}
          buttonSize={EButtonSize.MEDIUM}
        >
          Adicionar tag
        </S.AddTagButton>

        <S.SyncCampaignsButton
          onClick={onSync}
          buttonSize={EButtonSize.MEDIUM}
          isLoading={isSyncCampaigns || isCompletingOnboarding}
          disabled={isSyncCampaigns || isCompletingOnboarding}
        >
          Sincronizar campanhas
        </S.SyncCampaignsButton>
      </S.ButtonsWrapper>

      <NewTagSideModal isOpen={isModalOpen} toggle={handleModalOpen} />
    </>
  );
};

export default MyTags;
