import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresPaidOrderValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresPaidOrdersValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresPaidOrderValuesResponse => {
  const currentSelectedStorePaidOrdersAmountValue =
    currentSelectedStoreFinancialDetailsReportData?.paid_orders?.amount;

  const currentSelectedStorePaidOrdersQuantityValue =
    currentSelectedStoreFinancialDetailsReportData?.paid_orders?.quantity;

  const storesPaidOrdersAmountValues = selectedStoresData?.map(
    value => value.financial_details_report?.paid_orders?.amount,
  );

  const storesPaidOrdersQuantityValues = selectedStoresData?.map(
    value => value.financial_details_report?.paid_orders?.quantity,
  );

  const paidOrdersAmountValues = [
    ...storesPaidOrdersAmountValues,
    currentSelectedStorePaidOrdersAmountValue,
  ];

  const paidOrdersQuantityValues = [
    ...storesPaidOrdersQuantityValues,
    currentSelectedStorePaidOrdersQuantityValue,
  ];

  const reducedPaidOrdersAmountValues = paidOrdersAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedPaidOrdersQuantityValues = paidOrdersQuantityValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  return {
    paid_orders_amount: reducedPaidOrdersAmountValues,
    paid_orders_quantity: reducedPaidOrdersQuantityValues,
  };
};
