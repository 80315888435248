import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { numberFormatter } from '@helpers/masks';
import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';

import { ETextType } from '@domain/enums/components/EText';
import PieChart from './PieChart';
import HoverCard from '../HoverCard';

import * as S from './styles';

const Boletos: React.FC = () => {
  const { financialDetailsReport } = useFinancialDashboard();

  const { boleto } = financialDetailsReport;

  return (
    <S.Card>
      <S.Content>
        <S.Title fontWeight={EHeadingWeight.REGULAR} type={EHeadingSize.H6}>
          Boletos
        </S.Title>

        <S.DataWrapper>
          <S.ChartWrapper>
            <PieChart />
            <S.TextAndPercentageWrapper>
              <S.ChartText>Conversão</S.ChartText>
              <S.ChartPercentage>{`${numberFormatter(
                boleto.conversion,
                0,
                true,
              )}%`}</S.ChartPercentage>
            </S.TextAndPercentageWrapper>
          </S.ChartWrapper>

          <S.OrdersDataWrapper>
            <S.OrdersStatusWrapper>
              <S.StatusWrapper>
                <S.StatusText>Aprovados</S.StatusText>
              </S.StatusWrapper>

              <HoverCard amount={boleto.paid.amount} quantity={boleto.paid.quantity}>
                <S.OrdersAmount type={ETextType.P}>{`R$ ${numberFormatter(
                  boleto.paid.amount,
                  2,
                )} (${boleto.paid.quantity})`}</S.OrdersAmount>
              </HoverCard>
            </S.OrdersStatusWrapper>

            <S.OrdersStatusWrapper>
              <S.StatusWrapper>
                <S.StatusText>Pendentes</S.StatusText>
              </S.StatusWrapper>

              <HoverCard amount={boleto.pending.amount} quantity={boleto.pending.quantity}>
                <S.OrdersAmount type={ETextType.P}>{`R$ ${numberFormatter(
                  boleto.pending.amount,
                  2,
                )} (${boleto.pending.quantity})`}</S.OrdersAmount>
              </HoverCard>
            </S.OrdersStatusWrapper>

            <S.OrdersStatusWrapper>
              <S.StatusWrapper>
                <S.StatusText>Compensados</S.StatusText>
              </S.StatusWrapper>

              <HoverCard amount={boleto.compensated.amount} quantity={boleto.compensated.quantity}>
                <S.OrdersAmount type={ETextType.P}>{`R$ ${numberFormatter(
                  boleto.compensated.amount,
                  2,
                )} (${boleto.compensated.quantity})`}</S.OrdersAmount>
              </HoverCard>
            </S.OrdersStatusWrapper>
          </S.OrdersDataWrapper>
        </S.DataWrapper>
      </S.Content>
    </S.Card>
  );
};

export default Boletos;
