import React from 'react';
import { useParams } from 'react-router-dom';

import {
  ESynchronizationRequestType,
  ESynchronizationType,
} from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';
import { IVariantHistoricContext } from '@domain/interfaces/dashboard/ProductCostDetails/IHistoricSidemodal';
import {
  ICreateVariantCostData,
  IVariant,
  IVariantCost,
} from '@domain/interfaces/dashboard/ProductCostDetails/IProductCostDetails';

import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';
import { useDate } from '@helpers/hooks/useDate';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import { getEnvironment } from '@helpers/utils/environment';

import { LONG_API_DOMAIN } from '@constants/api';

import productCostDetailsService from '@services/pages/dashboard/productCostDetails/productCostDetails';

const VariantHistoricContext = React.createContext<IVariantHistoricContext | null>(null);

export const VariantHistoricProvider: React.FC = ({ children }) => {
  const { productAliasId, storeAliasId } = useParams<IParams>();
  const { analytics, selectedStore, user } = useConfig();
  const { toast } = useToast();
  const { format, utcToZonedTime } = useDate();
  const { addSynchronization } = useSynchronization();

  const [page, setPage] = React.useState<number>(0);
  const [pageCount, setPageCount] = React.useState<number>(1);
  const [variantCosts, setVariantCosts] = React.useState<Array<IVariantCost>>([]);
  const [isLoadingVariantCosts, setIsLoadingVariantCosts] = React.useState<boolean>(false);
  const [variantCostsError, setVariantCostsError] = React.useState<any>(undefined);
  const [defaultVariantCost, setDefaultVariantCost] = React.useState<IVariantCost | undefined>(
    undefined,
  );
  const [isLoadingDefaultVariantCost, setIsLoadingDefaultVariantCost] = React.useState<boolean>(
    false,
  );
  const [defaultVariantCostError, setDefaultVariantCostError] = React.useState<any>(undefined);
  const [userStep, setUserStep] = React.useState<string>('FIRST');
  const [selectedVariant, setSelectedVariant] = React.useState<IVariant | undefined>(undefined);
  const [isCreatingNewVariantCost, setIsCreatingNewVariantCost] = React.useState<boolean>(false);
  const [variantCostToEdit, setVariantCostToEdit] = React.useState<IVariantCost | undefined>(
    undefined,
  );
  const [isUpdatingVariantCost, setIsUpdatingVariantCost] = React.useState<boolean>(false);
  const [isDeletingVariantCost, setIsDeletingVariantCost] = React.useState<boolean>(false);

  const handleVariantCostToEdit = React.useCallback(
    variantCost => setVariantCostToEdit(variantCost),
    [],
  );

  const handleSelectedVariant = React.useCallback(variant => setSelectedVariant(variant), []);

  const handleNextStep = React.useCallback(() => {
    setUserStep('SECOND');
  }, []);

  const handleUserStep = React.useCallback(step => setUserStep(step), []);

  const handlePageChange = React.useCallback(({ selected }) => setPage(selected), []);

  const loadVariantCosts = React.useCallback(
    async (variantId: string) => {
      setIsLoadingVariantCosts(true);

      try {
        const { data } = await productCostDetailsService.getVariantCosts({
          storeAliasId,
          productAliasId,
          page,
          variantId,
        });

        setPageCount(data.total_pages);
        setVariantCosts(data.variant_costs);

        setVariantCostsError(undefined);
      } catch (error: any) {
        setVariantCostsError(error);
      } finally {
        setIsLoadingVariantCosts(false);
      }
    },
    [page, productAliasId, storeAliasId],
  );

  const loadDefaultVariantCost = React.useCallback(
    async variantId => {
      setIsLoadingDefaultVariantCost(true);

      try {
        const { data } = await productCostDetailsService.getDefaultVariantCost({
          storeAliasId,
          productAliasId,
          variantId,
        });

        setDefaultVariantCost(data.variant_cost);

        setDefaultVariantCostError(undefined);
      } catch (error: any) {
        setDefaultVariantCostError(error);
      } finally {
        setIsLoadingDefaultVariantCost(false);
      }
    },
    [productAliasId, storeAliasId],
  );

  const createNewVariantCost = React.useCallback(
    async (data: ICreateVariantCostData) => {
      setIsCreatingNewVariantCost(true);

      try {
        await productCostDetailsService.createVariantCost({
          productAliasId,
          storeAliasId,
          variantAliasId: selectedVariant?.alias_id,
          data,
        });

        const { start_date, end_date } = data;

        const params = `?start_date=${format(
          utcToZonedTime(start_date),
          'yyyy-MM-dd',
        )}&end_date=${format(utcToZonedTime(end_date), 'yyyy-MM-dd')}`;

        addSynchronization({
          name: 'Custo dos Produtos',
          type: ESynchronizationType.STORE_ORDER,
          label: 'Pedidos',
          dependencies: [ESynchronizationType.STORE_PRODUCT],
          storeAliasId,
          config: {
            isOnboardingSynchronization: false,
            showNotification: true,
          },
          request: {
            type: ESynchronizationRequestType.POST,
            domain: LONG_API_DOMAIN,
            api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/orders/cogs/sync`,
            requestParams: params,
            requestBody: {
              scope: 'variant',
              product_id: undefined,
              variant_ids: [selectedVariant?.id],
            },
          },
        });

        analytics?.track(
          'Product Variant Historic Cost Added',
          {
            email: user?.email,
          },
          { context: { groupId: selectedStore?.alias_id } },
        );

        toast.success('Histórico criado com sucesso!');

        if (selectedVariant) {
          await loadVariantCosts(selectedVariant.id);
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsCreatingNewVariantCost(false);
      }
    },
    [
      loadVariantCosts,
      productAliasId,
      selectedVariant,
      storeAliasId,
      toast,
      analytics,
      selectedStore,
      user,
      addSynchronization,
      format,
      utcToZonedTime,
    ],
  );

  const updateVariantCost = React.useCallback(
    async data => {
      setIsUpdatingVariantCost(true);

      try {
        await productCostDetailsService.updateVariantCostPeriod({
          storeAliasId,
          productAliasId,
          variantAliasId: selectedVariant?.alias_id,
          variantCostAliasId: variantCostToEdit?.alias_id,
          data,
        });

        toast.success('Histórico atualizado com sucesso!');

        const { start_date, end_date } = data;

        const params = `?start_date=${format(
          utcToZonedTime(start_date),
          'yyyy-MM-dd',
        )}&end_date=${format(utcToZonedTime(end_date), 'yyyy-MM-dd')}`;

        addSynchronization({
          name: 'Custo dos Produtos',
          type: ESynchronizationType.STORE_ORDER,
          label: 'Pedidos',
          dependencies: [ESynchronizationType.STORE_PRODUCT],
          storeAliasId,
          config: {
            isOnboardingSynchronization: false,
            showNotification: true,
          },
          request: {
            type: ESynchronizationRequestType.POST,
            domain: LONG_API_DOMAIN,
            api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/orders/cogs/sync`,
            requestParams: params,
            requestBody: {
              scope: 'variant',
              product_id: undefined,
              variant_ids: [selectedVariant?.id],
            },
          },
        });

        handleUserStep('FIRST');

        if (selectedVariant) {
          await loadVariantCosts(selectedVariant.id);
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsUpdatingVariantCost(false);
      }
    },
    [
      handleUserStep,
      loadVariantCosts,
      productAliasId,
      selectedVariant,
      storeAliasId,
      toast,
      variantCostToEdit,
      addSynchronization,
      format,
      utcToZonedTime,
    ],
  );

  const deleteVariantCost = React.useCallback(
    async variantCostAliasId => {
      setIsDeletingVariantCost(true);

      try {
        if (selectedVariant) {
          await productCostDetailsService.deleteVariantCost({
            variantCostAliasId,
            productAliasId,
            storeAliasId,
            variantAliasId: selectedVariant?.alias_id,
          });

          toast.success('Histórico excluído com sucesso!');

          loadVariantCosts(selectedVariant.id);
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsDeletingVariantCost(false);
      }
    },
    [loadVariantCosts, productAliasId, selectedVariant, storeAliasId, toast],
  );

  return (
    <VariantHistoricContext.Provider
      value={{
        page,
        isLoadingVariantCosts,
        pageCount,
        loadVariantCosts,
        loadDefaultVariantCost,
        variantCostsError,
        variantCosts,
        defaultVariantCost,
        defaultVariantCostError,
        isLoadingDefaultVariantCost,
        handlePageChange,
        userStep,
        handleNextStep,
        handleSelectedVariant,
        selectedVariant,
        createNewVariantCost,
        isCreatingNewVariantCost,
        handleUserStep,
        handleVariantCostToEdit,
        variantCostToEdit,
        isUpdatingVariantCost,
        updateVariantCost,
        deleteVariantCost,
        isDeletingVariantCost,
      }}
    >
      {children}
    </VariantHistoricContext.Provider>
  );
};

export const useVariantHistoric = (): IVariantHistoricContext => {
  const context = React.useContext(VariantHistoricContext);

  if (!context) {
    throw new Error('useVariantHistoric must be used within VariantHistoric');
  }

  return context;
};
