import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';

import {
  IUpdateCredentialProps,
  IGetProps,
  IGetResponse,
  ICreateCredentialProps,
  IDeleteCredentialProps,
  IGenerateURLProps,
} from '@domain/interfaces/dashboard/IntegrationCenter/Gateway/IZouti';

export class ZoutiService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getCredential({ storeAliasId }: IGetProps): IGetResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/zouti-credentials`,
      this.apiService,
    );

    const isLoading = !error && !data;

    return {
      zoutiCredential: data?.zouti_credential,
      error,
      mutate,
      isLoading,
      isValidating,
    };
  }

  public getCredentialPromise({ storeAliasId }: IGetProps): Promise<any> {
    return this.apiService.get(`/api/v1/users/stores/${storeAliasId}/zouti-credentials`);
  }

  public createCredential({ storeAliasId, data }: ICreateCredentialProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/zouti-credentials`, {
      ...data,
    });
  }

  public updateCredential({ storeAliasId, data }: IUpdateCredentialProps): Promise<any> {
    return this.apiService.put(`/api/v1/users/stores/${storeAliasId}/zouti-credentials`, {
      ...data,
    });
  }

  public deleteCredential({ storeAliasId }: IDeleteCredentialProps): Promise<any> {
    return this.apiService.delete(`/api/v1/users/stores/${storeAliasId}/zouti-credentials`);
  }

  public generateUrl({ storeAliasId }: IGenerateURLProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/zouti-credentials/generate-webhook-url`,
    );
  }
}

const zoutiService = new ZoutiService(API_DOMAIN);

export default zoutiService;
