import React from 'react';
import { ArrowSquareOut, Info } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { format } from 'date-fns';

import { ETabOption } from '@domain/enums/dashboard/storeSubscription/ETabOptions';
import { ESubscriptionRecurrence } from '@domain/enums/common/subscription/ESubscriptionRecurrence';

import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { useStoreSubscription as useStoreSubscriptionPage } from '@helpers/hooks/pages/dashboard/storeSubscription/useStoreSubscription';

import { numberFormatter } from '@helpers/masks';

import CancelSubscription from '@components/Dashboard/StoreSubscription/Subscription/Modals/CancelSubscription';

import * as S from './styles';

const Paid: React.FC = () => {
  const theme = useTheme();
  const { subscription } = useStoreSubscription();
  const { handleSelectedTab } = useStoreSubscriptionPage();

  const [isCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] = React.useState<boolean>(
    false,
  );

  const toggle = React.useCallback(() => setIsCancelSubscriptionModalOpen(state => !state), []);

  const onUpgradeClick = React.useCallback(() => {
    handleSelectedTab(ETabOption.PLANS);
  }, [handleSelectedTab]);

  const getRecurrenceText = React.useCallback(() => {
    if (subscription?.recurrence === ESubscriptionRecurrence.SEMIANNUAL) return '/seis meses';

    if (subscription?.recurrence === ESubscriptionRecurrence.QUARTERLY) return '/três meses';

    return '/mês';
  }, [subscription]);

  const currentConsumption = subscription.current_orders_quantity;
  const maxConsumption = subscription.allowed_orders_quantity;

  const consumption =
    (currentConsumption * 100) / maxConsumption > 100
      ? 100
      : (currentConsumption * 100) / maxConsumption;
  const planPrice = subscription.plan?.amount || 9999;
  const subscriptionEndDate = subscription.period_ended_at;
  const planName = subscription.plan?.name;
  const hasExceededOrders = maxConsumption < currentConsumption;
  const isUnlimitedPlan = planName?.includes('Unlimited');

  const totalOrdersExceed = hasExceededOrders ? currentConsumption - maxConsumption : 0;
  const exceededOrderPrice = subscription?.plan?.quota_exceed_price || 0;
  const priceTotalOrderExceed = totalOrdersExceed * (subscription?.plan?.quota_exceed_price || 1);

  return (
    <>
      <S.CardWrapper>
        <S.Content>
          <S.PlanNameAndPricingWrapper>
            <S.TitleHeader>
              <S.PlanName>{planName}</S.PlanName>
              <S.StatusText>{`Renova em ${format(
                new Date(subscriptionEndDate),
                'dd/MM/yyyy',
              )}`}</S.StatusText>
            </S.TitleHeader>
            <S.PricingValue>{`R$${numberFormatter(
              planPrice,
              0,
            )}${getRecurrenceText()}`}</S.PricingValue>
          </S.PlanNameAndPricingWrapper>

          <S.Divider />

          <S.Tooltip>
            <S.Tooltip.Trigger>
              <S.OrderConsumptionWrapper>
                <S.Description>
                  Consumo de pedidos <Info size={20} color={theme.colors.gray[1]} />
                </S.Description>

                <S.Consumption>
                  <S.TotalConsumption>
                    <S.CurrentConsumption style={{ width: `${consumption}%` }} />
                  </S.TotalConsumption>

                  <S.ConsumptionValuesWrapper>
                    <S.CurrentConsumptionText>{currentConsumption}</S.CurrentConsumptionText>
                    <S.MaxConsumptionText>{maxConsumption}</S.MaxConsumptionText>
                  </S.ConsumptionValuesWrapper>
                </S.Consumption>
              </S.OrderConsumptionWrapper>
            </S.Tooltip.Trigger>

            <S.Tooltip.Content side="top" sideOffset={5}>
              <S.TooltipContent>
                <S.TextWrapper>
                  <S.TooltipText>Preço por pedido extra:</S.TooltipText>
                  <S.TooltipText>{`R$ ${numberFormatter(exceededOrderPrice, 2)}`}</S.TooltipText>
                </S.TextWrapper>

                <S.TextWrapper>
                  <S.TooltipText>Total de pedidos extras:</S.TooltipText>
                  <S.TooltipText>{numberFormatter(totalOrdersExceed, 0)}</S.TooltipText>
                </S.TextWrapper>

                <S.TextWrapper>
                  <S.TooltipText>Preço total dos pedidos extras: </S.TooltipText>
                  <S.TooltipText>{`R$ ${numberFormatter(priceTotalOrderExceed, 2)}`}</S.TooltipText>
                </S.TextWrapper>
              </S.TooltipContent>

              <S.Tooltip.Arrow />
            </S.Tooltip.Content>
          </S.Tooltip>
        </S.Content>

        <S.OptionsWrapper>
          <S.CancelationButton onClick={toggle}>Cancelar</S.CancelationButton>

          {!isUnlimitedPlan && (
            <S.UpgradeButton onClick={onUpgradeClick}>
              Upgrade <ArrowSquareOut size={18} color={theme.colors.gray[1]} />
            </S.UpgradeButton>
          )}
        </S.OptionsWrapper>
      </S.CardWrapper>

      <CancelSubscription isOpen={isCancelSubscriptionModalOpen} toggle={toggle} />
    </>
  );
};

export default Paid;
