import styled, { css } from 'styled-components/macro';

import { EHeadAlign } from '@domain/enums/components/ETable';
import { IDataCellprops } from '@domain/interfaces/dashboard/AdsManager/Table/IDataCell';

export const HeaderCellContent = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 12px;
  box-sizing: border-box;
  position: relative;
  height: 100%;
`;

export const HeaderCell = styled.th<IDataCellprops>`
  background-color: ${({ theme }) => theme.colors.gray[6]};
  height: 96px;
  transition: all 0.3s;
  position: sticky;
  position: -webkit-sticky;
  top: -53px;
  -webkit-box-shadow: inset 0px -1px 0px 0px ${({ theme }) => theme.colors.gray[4]},
    inset -1px 0px 0px 0px ${({ theme }) => theme.colors.gray[4]},
    inset 0px 1px 0px 0px ${({ theme }) => theme.colors.gray[4]};
  box-shadow: inset 0px -1px 0px 0px ${({ theme }) => theme.colors.gray[4]},
    inset -1px 0px 0px 0px ${({ theme }) => theme.colors.gray[4]},
    inset 0px 1px 0px 0px ${({ theme }) => theme.colors.gray[4]};

  ${({ isHovering }) =>
    isHovering &&
    css`
      background-color: ${({ theme }) => theme.colors.gray[7]};
    `}

  ${({ contentAlign }) =>
    contentAlign === EHeadAlign.CENTER &&
    css`
      & > div {
        justify-content: center;
      }
    `}

  ${({ contentAlign }) =>
    contentAlign === EHeadAlign.RIGHT &&
    css`
      & > div {
        justify-content: flex-end;
      }
    `}
`;

export const Wrapper = styled.tr`
  & > th:first-child {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    left: 0;
    z-index: ${({ theme }) => `calc(${theme.zIndex.firstLayer} + 1)`};
  }
`;
