import React from 'react';

import { EProductCosts } from '@domain/enums/common/EProductCosts';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import DeleteSyncsModal from '@components/Dashboard/IntegrationCenter/SideModalContent/DeleteSyncsModal';

import DsersConfig from './DsersConfig';
import IntegrateForm from './IntegrateForm';

const Dsers: React.FC = () => {
  const {
    storeIntegrations,
    isDeleteSyncsModalOpen,
    handleDeleteSyncsModalOpen,
  } = useStoreConfig();

  const hasDsersIntegration = Boolean(
    storeIntegrations.product_cost.find(
      integration => integration.name === EProductCosts.DSERS && integration.is_active,
    ),
  );

  if (hasDsersIntegration) {
    return <DsersConfig />;
  }

  return (
    <>
      <IntegrateForm />
      <DeleteSyncsModal isOpen={isDeleteSyncsModalOpen} toggle={handleDeleteSyncsModalOpen} />
    </>
  );
};

export default Dsers;
