import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const TrialEndedModal: React.FC = () => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { analytics } = useConfig();

  const onSeePlansClick = React.useCallback(() => {
    history.push(`/${storeAliasId}/pricing`);
  }, [history, storeAliasId]);

  React.useEffect(() => {
    analytics?.track('Trial Ended Modal Viewed', {}, { context: { groupId: storeAliasId } });
  }, [analytics, storeAliasId]);

  return (
    <S.Modal isOpen>
      <S.Content>
        <S.Image
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/core/profitfy-climbing.svg"
          alt="Profitfy Trial Ended"
        />

        <S.Title>Seu período de avaliação terminou.</S.Title>

        <S.Description>
          <S.DescriptionText>
            Não é possível continuar utilizando a plataforma pois seu{' '}
            <strong>período de avaliação terminou.</strong>
          </S.DescriptionText>
          <S.DescriptionText>
            Esperamos que tenha aproveitado ao máximo! Agora é hora de você{' '}
            <strong>escolher seu plano.</strong>
          </S.DescriptionText>
        </S.Description>

        <S.SeePlansButton onClick={onSeePlansClick}>Consultar planos</S.SeePlansButton>
      </S.Content>
    </S.Modal>
  );
};

export default TrialEndedModal;
