import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Title = styled(HeadingBase)``;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const MarkAsRead = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.colors.background[2]};
  padding: 2px 16px 2px 16px;
  box-shadow: unset;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background[2]};
    box-shadow: unset;
  }

  & > span {
    color: ${({ theme }) => theme.colors.white.default};
  }
`;
