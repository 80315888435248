import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import LabelBase from '@components/common/core/DataDisplay/Label';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Modal = styled(ModalBase)`
  width: 100%;
  max-width: calc(548px - 64px);
`;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 22px;
`;

export const Title = styled(TextBase)`
  font-size: 1.125rem;
  color: #fff;
`;

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 32px;
`;

export const StepTitle = styled(TextBase)`
  color: #fff;
  line-height: 150%;
  margin-bottom: 8px;
`;

export const Step = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
`;

export const StepBullet = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${({ theme }) => theme.colors.green.default};
  border-radius: 50%;
`;

export const StepDescription = styled(TextBase)`
  line-height: 150%;
`;

export const AuthenticateLinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const LinkLabel = styled(LabelBase)``;

export const AuthenticateLinkWrapper = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  border-radius: 5px;
  height: 40px;
  width: 100%;
  padding: 10px 12px 10px 10px;
  gap: 10px;
  margin-bottom: 32px;
  border: unset;
  cursor: pointer;
`;

export const AuthenticateLink = styled(TextBase)`
  max-width: 434px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Description = styled(TextBase)`
  margin-bottom: 32px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-left: auto;
`;

export const CancelButton = styled.button`
  border: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-weight: 500;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[2]};
  transition: color 0.2s;

  &:hover {
    cursor: pointer;
    color: #fff;
  }
`;

export const UpdateAccountButton = styled(ButtonBase)``;
