import styled, { css } from 'styled-components/macro';

import { ICouponInputWrapperProps } from '@domain/interfaces/subscription/checkout/ICoupon';

export const ApplyButton = styled.button`
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.green.default};
  background: unset;
  border: unset;
  padding: unset;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Input = styled.input`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.font};
  font-size: 1rem;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.font};
    opacity: 0.6;
  }

  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.font};
    opacity: 0.6;
  }

  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.font};
    opacity: 0.6;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;

    &::placeholder {
      color: ${({ theme }) => theme.colors.gray[3]};
    }
  }

  &:read-only {
    cursor: not-allowed;

    &::placeholder {
      color: ${({ theme }) => theme.colors.gray[3]};
    }
  }
`;

export const InputWrapper = styled.div<ICouponInputWrapperProps>`
  height: 42px;
  border: 1px solid ${({ theme }) => theme.colors.inputBorderColor};
  background-color: ${({ theme }) => theme.colors.background[2]};
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  padding: 10px 12px;
  transition: all 0.3s;
  width: 100%;
  box-sizing: border-box;

  & > div {
    min-width: 12px;
  }

  ${({ isValid, theme }) =>
    !isValid &&
    css`
      border: 1px solid ${theme.colors.danger.default};
      background-color: ${theme.colors.danger.input.defaultBackground};
    `}
`;

export const AddCouponButton = styled.button`
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.green.default};
  background: unset;
  border: unset;
  padding: unset;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Wrapper = styled.div`
  margin-top: 24px;
`;
