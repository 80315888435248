import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';
import {
  IDeleteBilling,
  IGetBillingsProps,
  IGetBillingsResponse,
  IUpdateBilling,
} from '@domain/interfaces/dashboard/CustomValues/IBilling';
import { getEnvironment } from '@helpers/utils/environment';

export class BillingService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getBillings({
    storeAliasId,
    customValueAliasId,
    page,
  }: IGetBillingsProps): IGetBillingsResponse {
    // # DEMO CODE
    const user = localStorage.getItem(`@profitfy:${getEnvironment()}/user`);

    if (user) {
      const parsedJson = JSON.parse(user);

      if (parsedJson) {
        const { email } = parsedJson;

        if (email === 'profitfydemo@gmail.com') {
          const { data, error, mutate, isValidating } = useFetch(
            `/customValues/billings/${customValueAliasId}.json`,
            this.apiService,
          );

          const isLoading = !error && !data;

          return {
            customValueBillings: data?.custom_value_billings,
            totalPages: data?.total_pages,
            isLoading,
            isValidating,
            error,
            mutate,
          };
        }
      }
    }

    // END CODE

    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/custom-values/${customValueAliasId}/custom-values-billings?limit=10&page=${
        page + 1
      }`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      customValueBillings: data?.custom_value_billings,
      totalPages: data?.total_pages,
      isLoading,
      isValidating,
      error,
      mutate,
    };
  }

  public updateBilling({
    storeAliasId,
    customValueAliasId,
    billingAliasId,
    data,
  }: IUpdateBilling): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/custom-values/${customValueAliasId}/custom-values-billings/${billingAliasId}`,
      { ...data },
    );
  }

  public deleteBilling({
    storeAliasId,
    customValueAliasId,
    billingAliasId,
  }: IDeleteBilling): Promise<any> {
    return this.apiService.delete(
      `/api/v1/users/stores/${storeAliasId}/custom-values/${customValueAliasId}/custom-values-billings/${billingAliasId}`,
    );
  }
}

const billingService = new BillingService(API_DOMAIN);

export default billingService;
