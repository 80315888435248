import React from 'react';

import { EDropshippingProvider } from '@domain/enums/common/integrations/EDropshippingProvider';

import { useStoreIntegrations } from '@helpers/hooks/pages/dashboard/integrationCenter/useStoreIntegrations';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { getDropshippingProviderIntegrationStatus } from '@helpers/utils/dashboard/integrationsCenter/dropshippingProviders';

import { DROPSHIPPING_PROVIDERS } from '@constants/common/integrations';

import IntegrationCard from '@components/Dashboard/IntegrationCenter/IntegrationCard';
import Text from '@components/common/core/DataDisplay/Text';
import SkeletonLoadingCard from '../../SkeletonLoadingCard';

import * as S from './styles';

const DropshippingProvider: React.FC = () => {
  const emptyArray = Array.from(Array(5).keys());

  const {
    searchIntegrationsValue,
    isSideModalOpen,
    handleSideModalOpen,
    handleSelectedIntegration,
    selectedIntegration,
  } = useStoreIntegrations();
  const { storeIntegrations, isLoadingStore, isValidatingStore } = useStoreConfig();

  const SideModalContent = DROPSHIPPING_PROVIDERS.find(
    integration => integration.identifier === selectedIntegration,
  )?.sideModal;

  const onIntegrationCardClick = React.useCallback(
    (identifier: EDropshippingProvider) => {
      handleSideModalOpen();
      handleSelectedIntegration(identifier);
    },
    [handleSelectedIntegration, handleSideModalOpen],
  );

  const checkIsIntegrationCardDisabled = React.useCallback(
    (identifier: EDropshippingProvider): boolean => {
      const hasIntegration = storeIntegrations?.store_front.find(
        integration => integration.is_active,
      );

      if (!hasIntegration) return false;

      const foundStoreFrontIntegration = storeIntegrations?.store_front.find(
        integration => integration.name === identifier && integration.is_active,
      );

      if (foundStoreFrontIntegration) return false;

      return true;
    },
    [storeIntegrations],
  );

  if (isLoadingStore || isValidatingStore) {
    return (
      <S.DropshippingIntegrationWrapper>
        <S.Heading>Loja Virtual</S.Heading>

        <S.CardsWrapper>
          {emptyArray.map(element => (
            <SkeletonLoadingCard key={element} />
          ))}
        </S.CardsWrapper>
      </S.DropshippingIntegrationWrapper>
    );
  }

  const filteredDropshippingProvidersIntegrations = DROPSHIPPING_PROVIDERS.filter(integration => {
    const integrationName = integration.name.toLowerCase();
    const searchIntegration = searchIntegrationsValue.toLowerCase();
    const isIntegrationIncludedOnSearch = integrationName.includes(searchIntegration);

    if (!isIntegrationIncludedOnSearch) return false;

    return true;
  });

  return (
    <S.DropshippingIntegrationWrapper>
      <S.Heading>Loja Virtual</S.Heading>

      <S.CardsWrapper>
        {filteredDropshippingProvidersIntegrations.map(filteredIntegration => {
          const isActivated = getDropshippingProviderIntegrationStatus(
            storeIntegrations,
            filteredIntegration.identifier,
          );

          return (
            <IntegrationCard
              type="providers"
              icon={filteredIntegration.icon}
              isActivated={isActivated}
              title={filteredIntegration.name}
              key={filteredIntegration.name}
              isDisabled={checkIsIntegrationCardDisabled(filteredIntegration.identifier)}
              onClick={() => onIntegrationCardClick(filteredIntegration.identifier)}
            />
          );
        })}

        {!filteredDropshippingProvidersIntegrations.length && (
          <Text>Não encontramos nenhuma integração que contenha esse nome.</Text>
        )}
      </S.CardsWrapper>

      {SideModalContent && (
        <S.SideModal isOpen={isSideModalOpen} toggle={handleSideModalOpen}>
          <SideModalContent />
        </S.SideModal>
      )}
    </S.DropshippingIntegrationWrapper>
  );
};

export default DropshippingProvider;
