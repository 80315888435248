import React from 'react';
import { useTheme } from 'styled-components';
import { CaretRight } from '@profitfy/pakkun-icons';

import * as S from './styles';

export interface IIntegrationsCardProps {
  name?: string;
  icon?: any;
  active?: boolean;
  children?: JSX.Element | JSX.Element[];
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const IntegrationsCard: React.FC<IIntegrationsCardProps & React.HTMLProps<HTMLButtonElement>> = ({
  icon: Icon,
  name,
  active,
  onClick,
}) => {
  const { colors } = useTheme();
  return (
    <S.Wrapper active={active} type="button" onClick={onClick}>
      <Icon size={30} />
      <S.Name>{name}</S.Name>
      <S.Icon>
        <CaretRight size={20} color={colors.gray[3]} />
      </S.Icon>
    </S.Wrapper>
  );
};

export default IntegrationsCard;
