import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Info } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import Form from '@components/common/core/Inputs/Form';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import SideModalSave from '@components/common/core/Utils/SideModalSave';
import { ISidemodalProps } from '@domain/interfaces/dashboard/Feed/IFeed';
import { useChargeback } from '@helpers/hooks/pages/dashboard/feed/useChargeback';
import { currencyFormatter, numberFormatter } from '@helpers/masks';
import Text from '@components/common/core/DataDisplay/Text';
import { useDate } from '@helpers/hooks/useDate';

import * as S from './styles';

const Sidemodal: React.FC<ISidemodalProps> = ({ isOpen, toggle, order }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });
  const { solveChargebackOrder, isSolvingOrder } = useChargeback();
  const theme = useTheme();
  const { utcToZonedTime } = useDate();

  const [date, setDate] = React.useState<Date>(utcToZonedTime(order?.refunded_at || ''));

  const onSubmit = React.useCallback(
    async data => {
      const parsedOrder = {
        ...order,
        is_resolved_by_feed: true,
      };

      solveChargebackOrder(parsedOrder, data.refunded_amount, date);
    },
    [order, solveChargebackOrder, date],
  );

  const handleAmountChange = React.useCallback(event => {
    event.target.value = currencyFormatter(event.target.value);
  }, []);

  return (
    <S.SideModal isOpen={isOpen} toggle={toggle}>
      <S.Header>
        <Box size={24} />
        <S.Title
          type={EHeadingSize.H5}
          fontWeight={EHeadingWeight.REGULAR}
        >{`Editar ${order?.name}`}</S.Title>
      </S.Header>

      <S.ProductsWrapper>
        <S.Text>Produtos</S.Text>
        <S.ProductsList>
          {order?.line_items.map(lineItem => (
            <S.Product key={lineItem.alias_id}>{lineItem.product?.name}</S.Product>
          ))}
        </S.ProductsList>
      </S.ProductsWrapper>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.AmountLabel>
            Custo com Chargeback
            <S.Tooltip>
              <S.Tooltip.Trigger>
                <Info size={16} outline color={theme.colors.font} />
              </S.Tooltip.Trigger>

              <S.Tooltip.Content sideOffset={5} side="top">
                <S.TooltipTextWrapper>
                  <S.TooltipText>
                    Custo de frete, produto e etc. Geralmente é inserido quando o produto foi
                    enviado.
                  </S.TooltipText>
                </S.TooltipTextWrapper>
                <S.Tooltip.Arrow />
              </S.Tooltip.Content>
            </S.Tooltip>
          </S.AmountLabel>
          <S.Input
            prependText="R$"
            {...register('refunded_amount')}
            type="text"
            name="refunded_amount"
            placeholder="Insira o valor"
            defaultValue={numberFormatter(order?.cogs_amount || 0, 2)}
            onChange={handleAmountChange}
          />
          {errors.refunded_amount && <Text isErrorFeedback>{errors.refunded_amount.message}</Text>}
        </S.InputGroup>
        <S.InputGroup>
          <S.Label>Data do chargeback:</S.Label>
          <S.DayPicker
            selectedDate={date}
            setSelectedDate={setDate}
            minDate={new Date(order?.created_at)}
          />
        </S.InputGroup>

        <SideModalSave
          onCancel={toggle}
          type="submit"
          successButtonText="Salvar alterações"
          cancelButtonText="Cancelar"
          isLoading={isSolvingOrder}
          isDisabled={isSolvingOrder}
        />
      </Form>
    </S.SideModal>
  );
};

export default Sidemodal;
