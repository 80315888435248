import React from 'react';
import { Check } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { EScriptAndUtmsStep } from '@domain/enums/dashboard/adsManager/EScriptAndUtms';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';

import { useScriptAndUtms } from '@helpers/hooks/pages/dashboard/adsManager/onboarding/useScriptAndUtms';

import AddUtmsInExistingCampaigns from './AddUtmsInExistingCampaigns';

import * as S from './styles';

const AddUtms: React.FC = () => {
  const { userStep } = useScriptAndUtms();
  const theme = useTheme();

  const hasFinishedUtmStep =
    userStep !== EScriptAndUtmsStep.SCRIPT && userStep !== EScriptAndUtmsStep.UTM_TAGS;

  return (
    <S.Wrapper>
      <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
        Adicionar Profitfy UTM Tags em seus criativos existentes
        {hasFinishedUtmStep && (
          <S.FinishedStepWrapper>
            <Check size={22} color={theme.colors.success.light} />
          </S.FinishedStepWrapper>
        )}
      </S.Title>

      {userStep === EScriptAndUtmsStep.UTM_TAGS && <AddUtmsInExistingCampaigns />}
    </S.Wrapper>
  );
};

export default AddUtms;
