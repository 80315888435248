import React from 'react';
import { Smiley } from 'phosphor-react';
import { useTheme } from 'styled-components';
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { EButtonSize, EButtonVariant } from '@domain/enums/components/EButton';
import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';
import { ETextSize } from '@domain/enums/components/EText';

import { useCanvasMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessageSettings';
import { useCanvasContent } from '@helpers/hooks/pages/dashboard/automations/useCanvasContent';

import Variables from './Variables';

import * as S from './styles';

const MAX_INPUT_SIZE = 2048;

const TextTypeConfig: React.FC = () => {
  const { colors } = useTheme();
  const { contentsToUpdate, handleContentsToUpdate } = useCanvasMessageSettings();
  const {
    isEditingContent,
    handleTextPreview,
    contentToEdit,
    handleSelectedContentType,
    handleEditingContent,
    textPreview,
  } = useCanvasContent();

  const [value, setValue] = React.useState<string>('');
  const [showPicker, setShowPicker] = React.useState<boolean>(false);
  const [cursorPosition, setCursorPosition] = React.useState<number>(0);
  const textRef = React.useRef(null);

  const resetConfig = React.useCallback(() => {
    handleTextPreview('');
    handleEditingContent(false);
    handleSelectedContentType(EConfigOption.NONE);
  }, [handleTextPreview, handleEditingContent, handleSelectedContentType]);

  const onCancel = React.useCallback(() => {
    resetConfig();
  }, [resetConfig]);

  const onSave = React.useCallback(() => {
    if (isEditingContent) {
      resetConfig();
      return;
    }

    const newContent = {
      id: Math.random(),
      type: EConfigOption.TEXT,
      content: textPreview,
      isNewItemInArray: true,
    };

    handleContentsToUpdate([...contentsToUpdate, newContent]);

    resetConfig();
  }, [isEditingContent, resetConfig, textPreview, handleContentsToUpdate, contentsToUpdate]);

  const handleTextAreaChange = React.useCallback(
    newValue => {
      const data = [...contentsToUpdate];

      if (!isEditingContent) {
        handleTextPreview(newValue);
        return;
      }

      if (!contentToEdit) return;

      const foundContent = data.find(content => content.id === contentToEdit.id);

      if (!foundContent) return;

      const index = data.indexOf(foundContent);

      data[index].content = newValue;
      data[index].isEdited = true;

      handleContentsToUpdate(data);
    },
    [contentToEdit, contentsToUpdate, handleContentsToUpdate, handleTextPreview, isEditingContent],
  );

  const onTextAreaChange = React.useCallback(
    event => {
      const newMessageValue = event.target.value;

      setValue(newMessageValue);
      handleTextAreaChange(newMessageValue);
    },
    [handleTextAreaChange],
  );

  const handleEmoji = React.useCallback(
    ({ native }) => {
      const ref: any = textRef.current;
      ref.focus();

      const start = value.substring(0, ref.selectionStart);
      const end = value.substring(ref.selectionStart);
      const newEmoji = start + native + end;
      setValue(newEmoji);
      handleTextAreaChange(newEmoji);
      setCursorPosition(start.length + native.length);
    },
    [value, handleTextAreaChange],
  );

  const handleCursorPosition = React.useCallback((val: number) => {
    setCursorPosition(val);
  }, []);

  React.useEffect(() => {
    const ref: any = textRef.current;
    ref.selectionEnd = cursorPosition;
  }, [cursorPosition]);

  React.useEffect(() => {
    if (contentToEdit && isEditingContent) {
      setValue(contentToEdit.content as string);
    }
  }, [contentToEdit, isEditingContent]);

  const regex = /{{(.*?)}}/g;
  const matchVariables = value.match(regex);
  const totalCharsOfVariables = matchVariables?.reduce((total, str) => total + str.length, 0) || 0;

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title size={ETextSize.B5}>Defina a mensagem</S.Title>

        <S.VariablesWrapper>
          <Variables
            setValue={setValue}
            value={value}
            handleTextAreaChange={handleTextAreaChange}
            textRef={textRef}
            handleCursorPosition={handleCursorPosition}
          />

          <S.EmojiWrapper>
            {showPicker && (
              <S.EmojiPickerWrapper>
                <Picker onEmojiSelect={handleEmoji} data={emojiData} previewPosition="none" />
              </S.EmojiPickerWrapper>
            )}

            <S.IconSmileWrapper onClick={() => setShowPicker(prev => !prev)}>
              <Smiley size={22} color={colors.gray[1]} />
            </S.IconSmileWrapper>
          </S.EmojiWrapper>
        </S.VariablesWrapper>

        <S.TextArea
          ref={textRef}
          onChange={onTextAreaChange}
          value={value}
          maxLength={MAX_INPUT_SIZE + totalCharsOfVariables}
        />

        <S.ButtonsWrapper>
          <S.CancelButton
            onClick={onCancel}
            variant={EButtonVariant.SECONDARY_BUTTON}
            buttonSize={EButtonSize.MEDIUM}
            type="button"
          >
            Cancelar
          </S.CancelButton>

          <S.SaveButton
            buttonSize={EButtonSize.MEDIUM}
            onClick={onSave}
            disabled={!value}
            type="button"
          >
            Salvar
          </S.SaveButton>
        </S.ButtonsWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

export default TextTypeConfig;
