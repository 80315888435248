import React from 'react';

import { ETag } from '@domain/enums/dashboard/automations/ETemplate';
import { IBadgeTags } from '@domain/interfaces/dashboard/Automations/ITemplateSummaryModal';

import * as S from './styles';

const BadgeTags: React.FC<IBadgeTags> = ({ tags }) => {
  const maxTagsOfDisplay = 2;

  const tagsQuantity = tags.length;

  const hasMoreThanTwoTags = tagsQuantity > maxTagsOfDisplay;

  const tagsToDisplay = tags.slice(0, 2);

  const restTags = tags.filter(tag => {
    if (tagsToDisplay.includes(tag)) return false;

    return true;
  });

  const translateTag = (tagName: ETag): string => {
    if (tagName === ETag.ECOMMERCE) return 'e-commerce';

    return 'infoproduto';
  };

  return (
    <S.Wrapper>
      {tagsToDisplay.map(tag => {
        const translatedTag = translateTag(tag);

        return <S.BadgeTag color="">{translatedTag}</S.BadgeTag>;
      })}

      {hasMoreThanTwoTags && <S.BadgeTag color="">+ {restTags.length}</S.BadgeTag>}
    </S.Wrapper>
  );
};

export default BadgeTags;
