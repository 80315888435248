export enum EFilterOperator {
  EQUAL = 'EQUAL',
}

export enum EFilterType {
  SELECT = 'SELECT',
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  DATE_RANGE_PICKER = 'DATE_RANGE_PICKER',
  ADS_MANAGER_AD_SENSE_CREDENTIAL = 'ADS_MANAGER_AD_SENSE_CREDENTIAL',
  ADS_MANAGER_AD_SENSE_ACCOUNT = 'ADS_MANAGER_AD_SENSE_ACCOUNT',
  PRODUCTS = 'PRODUCTS',
}

export enum EFilterStep {
  FILTERS = 'FILTERS',
  SELECTED_FILTER = 'SELECTED_FILTER',
}

export enum EFilterVariant {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
}

export enum EFilterMode {
  REGULAR = 'REGULAR',
  AUTOMATION = 'AUTOMATION',
}
