import styled, { css } from 'styled-components/macro';

import CustomDropDownBase from '@components/common/core/Utils/CustomDropDown';
import TextBase from '@components/common/core/DataDisplay/Text';
import AvatarBase from '@components/common/core/DataDisplay/Avatar';
import { IStoreProps } from '@domain/interfaces/components/ICustomDropDown';

export const OptionWrapper = styled.div`
  padding: 0;
  border: none;
  background-color: unset;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  margin: 6px 0;

  &:hover {
    filter: brightness(1.2);
    color: ${({ theme }) => theme.colors.green.default};
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
`;

export const OptionName = styled(TextBase)`
  &:hover {
    color: ${({ theme }) => theme.colors.green.dark};
  }
`;

export const NewStoreWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 24px;
  box-sizing: border-box;
  cursor: pointer;
`;

export const StoreName = styled(TextBase)<IStoreProps>`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray[1]};
  max-width: 200px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    filter: brightness(1.2);
    color: ${({ theme }) => theme.colors.green.default};
  }

  ${({ isCurrentStore }) =>
    isCurrentStore &&
    css`
      color: ${({ theme }) => theme.colors.green.default};
    `}
`;

export const StoreAvatar = styled(AvatarBase)<IStoreProps>`
  background-color: ${({ theme }) => theme.colors.gray[4]};

  ${({ isCurrentStore }) =>
    isCurrentStore &&
    css`
      background-color: ${({ theme }) => theme.colors.green.dark};
    `}
`;

export const StoreWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

export const StoresWrapper = styled.div`
  padding: 20px;
  min-width: 292px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 265px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[1]};
    border-radius: 25px;
    margin-bottom: 4px;
  }
`;

export const CustomDropdown = styled(CustomDropDownBase)``;
