import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { EPersonType } from '@domain/enums/common/personType/EPersonType';
import { IParams } from '@domain/interfaces/IParams';

import { useCheckout } from '@helpers/hooks/pages/subscription/useCheckout';
import { usePersonalInfo } from '@helpers/hooks/common/store/personalInfo/usePersonalInfo';
import { useAddress } from '@helpers/hooks/common/store/address/useAddress';

import LegalPersonContent from './LegalPersonContent';
import NaturalPersonContent from './NaturalPersonContent';

import * as S from './styles';

const PersonalInfo: React.FC = () => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { personType, handlePersonType, reset, isSubmittingPersonalInfoForm } = useCheckout();
  const { legalPerson, naturalPerson } = usePersonalInfo();
  const { address } = useAddress();

  const legalPersonRadioRef = React.useRef<HTMLInputElement>(null);
  const naturalPersonRadioRef = React.useRef<HTMLInputElement>(null);

  const onPersonTypeChange = React.useCallback(
    event => {
      reset();
      handlePersonType(event.target.value);
    },
    [handlePersonType, reset],
  );

  React.useEffect(() => {
    if (!legalPersonRadioRef.current || !naturalPersonRadioRef.current) return;

    if (personType === EPersonType.LEGAL_PERSON) {
      naturalPersonRadioRef.current.checked = false;
      legalPersonRadioRef.current.checked = true;
    } else {
      legalPersonRadioRef.current.checked = false;
      naturalPersonRadioRef.current.checked = true;
    }
  }, [personType]);

  React.useEffect(() => {
    if (naturalPerson && !legalPerson) {
      handlePersonType(EPersonType.NATURAL_PERSON);
    }

    handlePersonType(EPersonType.LEGAL_PERSON);
  }, [naturalPerson, legalPerson, handlePersonType]);

  React.useEffect(() => {
    if (!address) {
      history.push(`/${storeAliasId}/subscription/personal-info${window.location.search}`);
    }

    if (address && !legalPerson && !naturalPerson) {
      history.push(`/${storeAliasId}/subscription/personal-info${window.location.search}`);
    }
  }, [address, legalPerson, naturalPerson, history, storeAliasId]);

  const isLegalPersonOptionSelected = personType === EPersonType.LEGAL_PERSON;
  const isNaturalPersonOptionSelected = personType === EPersonType.NATURAL_PERSON;

  return (
    <S.Wrapper>
      <S.Header>
        <S.StepNumberWrapper>
          <S.StepNumber>2</S.StepNumber>
        </S.StepNumberWrapper>

        <S.Title fontWeight={EHeadingWeight.REGULAR}>Dados Pessoais</S.Title>
      </S.Header>

      <S.FormContent>
        <S.PersonalInfoTypeGroup>
          <S.TypeLabel htmlFor={EPersonType.LEGAL_PERSON}>
            <S.RadioInput
              id={EPersonType.LEGAL_PERSON}
              value={EPersonType.LEGAL_PERSON}
              name={EPersonType.LEGAL_PERSON}
              onChange={onPersonTypeChange}
              ref={legalPersonRadioRef}
            />
            <S.RadioText>Pessoa Jurídica</S.RadioText>
          </S.TypeLabel>

          <S.TypeLabel htmlFor={EPersonType.NATURAL_PERSON}>
            <S.RadioInput
              id={EPersonType.NATURAL_PERSON}
              value={EPersonType.NATURAL_PERSON}
              name={EPersonType.NATURAL_PERSON}
              onChange={onPersonTypeChange}
              ref={naturalPersonRadioRef}
            />
            <S.RadioText>Pessoa Física</S.RadioText>
          </S.TypeLabel>
        </S.PersonalInfoTypeGroup>

        {isLegalPersonOptionSelected && <LegalPersonContent />}
        {isNaturalPersonOptionSelected && <NaturalPersonContent />}

        <S.NextStepButton isLoading={isSubmittingPersonalInfoForm}>Continuar</S.NextStepButton>
      </S.FormContent>
    </S.Wrapper>
  );
};

export default PersonalInfo;
