import React from 'react';
import { useMediaQuery } from '@helpers/hooks/useMediaQuery';
import { useConfig } from '@helpers/hooks/useConfig';

import logo from '@assets/img/brand/profitfy-logo.svg';

import Plans from '@components/Pricing/Sections/Plans';
import MobilePlans from '@components/Pricing/Sections/MobilePlans';
import ComparePlans from '@components/Pricing/Sections/ComparePlans';
import Faq from '@components/Pricing/Sections/Faq';
import Footer from '@components/Pricing/Sections/Footer';
import Phone from '@components/Pricing/Sections/Phone';
import Commentary from '@components/Pricing/Sections/Commentary';

import * as S from './styles';

const Pricing: React.FC = () => {
  const isMobileResolution = useMediaQuery('728px');
  const hideTableComparison = useMediaQuery('999px');
  const { analytics } = useConfig();

  const onLogoClick = React.useCallback(() => {
    window.location.href = 'https://profitfy.me';
  }, []);

  React.useEffect(() => {
    analytics?.track('Plans Page Viewed');
  }, [analytics]);

  return (
    <S.Container>
      <S.Content>
        <S.ContentWrapper>
          <S.LogoWrapper>
            <S.Logo src={logo} alt="Profitfy.me" onClick={onLogoClick} />
          </S.LogoWrapper>
        </S.ContentWrapper>

        <S.ContentWrapper>{isMobileResolution ? <MobilePlans /> : <Plans />}</S.ContentWrapper>

        {!hideTableComparison && (
          <S.ContentWrapper>
            <ComparePlans />
          </S.ContentWrapper>
        )}

        <S.ContentWrapper>
          <Phone />
        </S.ContentWrapper>

        <S.ContentWrapper>
          <Commentary />
        </S.ContentWrapper>

        <S.ContentWrapper>
          <Faq />
        </S.ContentWrapper>
        <S.ContentWrapper>
          <Footer />
        </S.ContentWrapper>
      </S.Content>
    </S.Container>
  );
};

export default Pricing;
