import React from 'react';

import { TikTokCredentialsProvider } from '@helpers/hooks/common/integrations/marketing/tiktok/useTikTokCrendentials';
import { IMarketingContentProps } from '@domain/interfaces/dashboard/IntegrationCenter/Marketing/IMarketingContent';

import Wrapper from './Wrapper';

const TikTok: React.FC<IMarketingContentProps> = ({ callback }) => {
  return (
    <TikTokCredentialsProvider>
      <Wrapper callback={callback} />
    </TikTokCredentialsProvider>
  );
};

export default TikTok;
