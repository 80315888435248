import { Facebook, GoogleAds, Taboola, TikTok } from '@profitfy/pakkun-icons';

import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { IAdsProvider } from '@domain/interfaces/common/integration/IAdsProvider';

import {
  getGoogleSynchronizationRequestConfig,
  getUpdateGoogleCampaignPromise,
} from '@helpers/utils/integrations/adSenseProviders/google';
import {
  getFacebookSynchronizationRequestConfig,
  getUpdateFacebookCampaignPromise,
} from '@helpers/utils/integrations/adSenseProviders/facebook';
import {
  getTaboolaSynchronizationRequestConfig,
  getUpdateTaboolaCampaignPromise,
} from '@helpers/utils/integrations/adSenseProviders/taboola';
import {
  getTikTokSynchronizationRequestConfig,
  getUpdateTikTokCampaignPromise,
} from '@helpers/utils/integrations/adSenseProviders/tiktok';

import OnboardingFacebookContent from '@components/Onboarding/Dropshipping/Marketing/Facebook';
import OnboardingGoogleContent from '@components/Onboarding/Dropshipping/Marketing/Google';
import OnboardingTaboolaContent from '@components/Onboarding/Dropshipping/Marketing/Taboola';
import OnboardingTikTokContent from '@components/Onboarding/Dropshipping/Marketing/TikTok';

export const ADS_PROVIDERS: Array<IAdsProvider> = [
  {
    id: 'facebook',
    name: 'Facebook',
    icon: Facebook,
    idToVerifyStatus: 'has_facebook_credential',
    identifier: EAdSenseProvider.FACEBOOK,
    lowerIdentifier: 'facebook',
    chart_hex_color: '#7A51DC',
    onboardingContent: OnboardingFacebookContent,
    marketing_content: OnboardingFacebookContent,
    generateSyncRequest: getFacebookSynchronizationRequestConfig,
    updateCampaign: getUpdateFacebookCampaignPromise,
  },
  {
    id: 'google',
    name: 'Google Ads',
    icon: GoogleAds,
    idToVerifyStatus: 'has_google_credential',
    identifier: EAdSenseProvider.GOOGLE,
    lowerIdentifier: 'google',
    chart_hex_color: '#4A22AA',
    onboardingContent: OnboardingGoogleContent,
    marketing_content: OnboardingGoogleContent,
    generateSyncRequest: getGoogleSynchronizationRequestConfig,
    updateCampaign: getUpdateGoogleCampaignPromise,
  },
  {
    id: 'taboola',
    name: 'Taboola Ads',
    icon: Taboola,
    idToVerifyStatus: 'has_taboola_credential',
    identifier: EAdSenseProvider.TABOOLA,
    lowerIdentifier: 'taboola',
    chart_hex_color: '#4A22FF',
    onboardingContent: OnboardingTaboolaContent,
    marketing_content: OnboardingTaboolaContent,
    generateSyncRequest: getTaboolaSynchronizationRequestConfig,
    updateCampaign: getUpdateTaboolaCampaignPromise,
  },
  {
    id: 'tiktok',
    name: 'TikTok',
    icon: TikTok,
    idToVerifyStatus: 'has_tiktok_credential',
    identifier: EAdSenseProvider.TIKTOK,
    lowerIdentifier: 'tiktok',
    chart_hex_color: '#7B4A1C',
    onboardingContent: OnboardingTikTokContent,
    marketing_content: OnboardingTikTokContent,
    generateSyncRequest: getTikTokSynchronizationRequestConfig,
    updateCampaign: getUpdateTikTokCampaignPromise,
  },
];
