import React from 'react';

import { ITooltipContentProps } from '@domain/interfaces/dashboard/ProductCost/IProductCost';

import * as S from './styles';

const TooltipContent: React.FC<ITooltipContentProps> = ({ synchronization }) => {
  const providerName = {
    DSERS: 'Dsers',
    SHOPIFY: 'Shopify',
    NUVEM_SHOP: 'Nuvem Shop',
    MERCADO_LIVRE: 'Mercado Livre',
    BLING: 'Bling',
    TINY: 'Tiny',
  };

  return <S.Text>{`Custos sincronizados com ${providerName[synchronization.provider]}`}</S.Text>;
};

export default TooltipContent;
