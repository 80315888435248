import GoBack from '@components/common/core/Utils/GoBack';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import {
  EExperienceRange,
  EUserSegmentationStep,
} from '@domain/enums/onboarding/EUsersSegmentation';
import React from 'react';
import * as S from './styles';

interface IThirdStepProps {
  handleThirdAnswer: (answer: string) => void;
  selectedAnswer: string;
  goBack: (backStep: EUserSegmentationStep) => void;
}

const ThirdStep: React.FC<IThirdStepProps> = ({ handleThirdAnswer, selectedAnswer, goBack }) => {
  const onRadioChange = React.useCallback(
    event => {
      handleThirdAnswer(event.target.value);
    },
    [handleThirdAnswer],
  );

  const handleGoBack = React.useCallback(() => goBack(EUserSegmentationStep.SECOND_STEP), [goBack]);

  return (
    <>
      <GoBack
        text="Voltar"
        onClick={handleGoBack}
        size={16}
        type={EHeadingSize.H6}
        fontWeight={EHeadingWeight.REGULAR}
      />

      <S.Question>Quanto tempo de experiência você possui no segmento da sua empresa?</S.Question>

      <S.RadioGroup>
        <S.RadioLabel checked={selectedAnswer === EExperienceRange.LESS_THAN_6_MONTHS}>
          <S.Radio
            name="experience_range"
            value={EExperienceRange.LESS_THAN_6_MONTHS}
            onChange={onRadioChange}
            id={EExperienceRange.LESS_THAN_6_MONTHS}
            defaultChecked={selectedAnswer === EExperienceRange.LESS_THAN_6_MONTHS}
          />
          <S.RadioText>Menos de 6 meses</S.RadioText>
        </S.RadioLabel>

        <S.RadioLabel checked={selectedAnswer === EExperienceRange.BETWEEN_6_MONTHS_1_YEAR}>
          <S.Radio
            name="experience_range"
            value={EExperienceRange.BETWEEN_6_MONTHS_1_YEAR}
            onChange={onRadioChange}
            id={EExperienceRange.BETWEEN_6_MONTHS_1_YEAR}
            defaultChecked={selectedAnswer === EExperienceRange.BETWEEN_6_MONTHS_1_YEAR}
          />
          <S.RadioText>Entre 6 meses e 1 ano</S.RadioText>
        </S.RadioLabel>

        <S.RadioLabel checked={selectedAnswer === EExperienceRange.BETWEEN_1_YEAR_2_YEARS}>
          <S.Radio
            name="experience_range"
            value={EExperienceRange.BETWEEN_1_YEAR_2_YEARS}
            onChange={onRadioChange}
            id={EExperienceRange.BETWEEN_1_YEAR_2_YEARS}
            defaultChecked={selectedAnswer === EExperienceRange.BETWEEN_1_YEAR_2_YEARS}
          />
          <S.RadioText>Entre 1 e 2 anos</S.RadioText>
        </S.RadioLabel>

        <S.RadioLabel checked={selectedAnswer === EExperienceRange.BETWEEN_2_YEARS_3_YEARS}>
          <S.Radio
            name="experience_range"
            value={EExperienceRange.BETWEEN_2_YEARS_3_YEARS}
            onChange={onRadioChange}
            id={EExperienceRange.BETWEEN_2_YEARS_3_YEARS}
            defaultChecked={selectedAnswer === EExperienceRange.BETWEEN_2_YEARS_3_YEARS}
          />
          <S.RadioText>Entre 2 e 3 anos</S.RadioText>
        </S.RadioLabel>

        <S.RadioLabel checked={selectedAnswer === EExperienceRange.MORE_THAN_3_YEARS}>
          <S.Radio
            name="experience_range"
            value={EExperienceRange.MORE_THAN_3_YEARS}
            onChange={onRadioChange}
            id={EExperienceRange.MORE_THAN_3_YEARS}
            defaultChecked={selectedAnswer === EExperienceRange.MORE_THAN_3_YEARS}
          />
          <S.RadioText>Mais de 3 anos</S.RadioText>
        </S.RadioLabel>

        <S.RadioLabel checked={selectedAnswer === EExperienceRange.NOT_INFORM}>
          <S.Radio
            name="experience_range"
            value={EExperienceRange.NOT_INFORM}
            onChange={onRadioChange}
            id={EExperienceRange.NOT_INFORM}
            defaultChecked={selectedAnswer === EExperienceRange.NOT_INFORM}
          />
          <S.RadioText>Prefiro não informar</S.RadioText>
        </S.RadioLabel>
      </S.RadioGroup>
    </>
  );
};

export default ThirdStep;
