import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';

import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { ERankingProductSortOption } from '@domain/enums/dashboard/productAnalytics/ERankingProductSort';

import SkeletonLoading from './SkeletonLoading';
import ProductContainer from './ProductContainer';

import * as S from './styles';

const TopThreeProducts: React.FC = () => {
  // const { isDashboardDataHidden } = useConfig();
  // const { storeAliasId } = useParams<IParams>();
  // const { colors } = useTheme();

  const {
    productsMetrics,
    isLoadingFinancialDashboardProvider,
    isValidatingFinancialDashboardProvider,
    isLoadingProductsPromise,
    handleTopThreeProductsSelect,
    topThreeProductsSelect,
  } = useFinancialDashboard();

  const onSelectChange = React.useCallback(
    event => {
      handleTopThreeProductsSelect(event.target.value);
    },
    [handleTopThreeProductsSelect],
  );

  if (
    isLoadingFinancialDashboardProvider ||
    isValidatingFinancialDashboardProvider ||
    isLoadingProductsPromise
  ) {
    return <SkeletonLoading />;
  }

  const isNetProfitSelected =
    topThreeProductsSelect === ERankingProductSortOption.NET_PROFIT_AMOUNT;

  return (
    <S.ProductWrapper>
      <S.HeaderWrapper>
        <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
          Ranking de produtos
        </S.Title>

        <S.Select name="order-type" defaultValue={topThreeProductsSelect} onChange={onSelectChange}>
          <S.Option value={ERankingProductSortOption.NET_PROFIT_AMOUNT}>Lucro Líquido</S.Option>
          <S.Option value={ERankingProductSortOption.NET_REVENUE_AMOUNT}>Receita Líquida</S.Option>
        </S.Select>
      </S.HeaderWrapper>

      {productsMetrics?.map(({ product, metrics }, index: number) => {
        return (
          <ProductContainer
            index={index}
            isNetProfitSelected={isNetProfitSelected}
            metrics={metrics}
            product={product}
            key={product.alias_id || index}
          />
        );
      })}
    </S.ProductWrapper>
  );
};

export default TopThreeProducts;
