import styled, { css } from 'styled-components/macro';

import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import {
  ICheckboxProps,
  IDescriptionBulletProps,
  IMoreOptionsWrapperProps,
} from '@domain/interfaces/dashboard/CustomValues/IRow';
import TextBase from '@components/common/core/DataDisplay/Text';
import BadgeBase from '@components/common/core/DataDisplay/Badge';
import RowBase from '@components/common/core/Utils/Table/Row';
import { IActionButtonProps } from '@domain/interfaces/components/IActionButton';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';
import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';

export const DescriptionTextWrapper = styled.div`
  max-width: 176px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Popover = styled(CustomPopoverBase)``;

export const Tooltip = styled(CustomTooltipBase)``;

export const TooltipDescription = styled(TextBase)``;

export const OptionDot = styled.span`
  display: block;
  width: 4px;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.gray[2]};
  border-radius: 50%;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`;

export const ActionButton = styled.button<IActionButtonProps>`
  border: none;
  background-color: transparent;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.font};
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;

  ${({ isDeleteButton, theme }) =>
    isDeleteButton &&
    css`
      color: ${theme.colors.danger.default};
    `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[6]};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.gray[6]};
    filter: brightness(0.9);
  }
`;

export const MoreOptionsWrapper = styled.div<IMoreOptionsWrapperProps>`
  width: 26px;
  height: 26px;
  align-items: center;
  justify-content: center;
  gap: 2px;
  opacity: 0;
  display: flex;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  border-radius: 50%;
  transition: filter 0.3s;
  border: unset;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  ${({ isHovering }) =>
    isHovering &&
    css`
      opacity: 1;
    `}
`;

export const Badge = styled(BadgeBase)``;

export const BadgeWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Text = styled(TextBase)``;

export const CategoryName = styled(TextBase)``;

export const CategoryBullet = styled.span<IDescriptionBulletProps>`
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;

  ${({ color }) => css`
    background-color: ${color};
  `}
`;

export const CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DescriptionText = styled(TextBase)``;

export const DescriptionWrapper = styled.div``;

export const Checkbox = styled(CheckboxBase)<ICheckboxProps>`
  & > div {
    border-radius: 2px;

    ${({ checked, theme }) =>
      checked &&
      css`
        background-color: ${theme.colors.green.dark};
      `}

    ${({ checked, theme }) =>
      !checked &&
      css`
        background-color: ${theme.colors.gray[4]};
      `}
  }
`;

export const Row = styled(RowBase)`
  & > td {
    padding: 10px 28px;

    ${({ bottomSpacing }) =>
      bottomSpacing &&
      css`
        padding-bottom: ${bottomSpacing}px;
      `}

    ${({ borderBottom }) =>
      borderBottom &&
      css`
        border-bottom: 1px solid ${({ theme }) => theme.colors.background[3]};
      `}
  }

  & > td:first-child {
    padding-left: 32px;
  }

  & > td:last-child {
    padding-right: 32px;
  }
`;
