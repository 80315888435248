import styled, { css } from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import BadgeBase from '@components/common/core/DataDisplay/Badge';
import AvatarBase from '@components/common/core/DataDisplay/Avatar';
import CustomHoverCardBase from '@components/common/core/Utils/CustomHoverCard';
import ButtonBase from '@components/common/core/Inputs/Button';

interface IVoteProps {
  isVoted?: boolean;
}

export const CustomHoverCard = styled(CustomHoverCardBase)``;

export const CustomHoverCardTrigger = styled(CustomHoverCardBase.Trigger)`
  display: block;
  height: fit-content;
  cursor: pointer;
`;

export const CustomHoverCardContent = styled(CustomHoverCardBase.Content)`
  background-color: ${({ theme }) => theme.colors.gray[6]};
  padding: 16px;
`;

export const CustomHoverCardArrow = styled(CustomHoverCardBase.Arrow)`
  fill: ${({ theme }) => theme.colors.gray[6]};
`;

export const UserInfoTitle = styled(HeadingBase)`
  color: #fff;
  font-size: 1.125rem;
  margin-bottom: 16px;
`;

export const UserInfosWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UpVotersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[1]};
    border-radius: 25px;
    margin-bottom: 4px;
  }
`;

export const UpVoterInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[4]};
  padding: 8px;

  &:last-child {
    border-bottom: unset;
    padding-bottom: unset;
  }
`;

export const UserInfo = styled(TextBase)``;

export const IdeaWrapper = styled.div`
  max-width: 722px;
  width: 100%;

  @media only screen and (max-width: 1000px) {
    max-width: unset;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const Status = styled(BadgeBase)``;

export const Body = styled.div`
  display: flex;
  gap: 16px;
`;

export const DescriptionWrapper = styled.div``;

export const Avatar = styled(AvatarBase)`
  background-color: ${({ theme }) => theme.colors.green.dark};
  min-width: 32px;
  min-height: 32px;
  height: 32px;
  width: 32px;
`;

export const UserName = styled(HeadingBase)`
  margin-top: 4px;
  margin-bottom: 16px;
`;

export const DescriptionText = styled(TextBase)`
  display: block;
  margin-bottom: 24px;
`;

export const DateText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.background[6]};
`;

export const VoteWrapper = styled.div<IVoteProps>`
  border: 2px solid ${({ theme }) => theme.colors.background[2]};
  border-radius: 15px;
  width: 40px;
  height: 40px;
  padding: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  transition: all 0.3s;

  ${({ isVoted, theme }) =>
    isVoted &&
    css`
      & > div {
        border-bottom-color: ${theme.colors.green.default};
      }

      & > span {
        color: ${theme.colors.green.default};
      }
    `}

  &:hover {
    border-color: ${({ theme }) => theme.colors.green.default};

    & > div {
      border-bottom-color: ${({ theme }) => theme.colors.green.default};
    }

    & > span {
      color: ${({ theme }) => theme.colors.green.default};
    }
  }
`;

export const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid ${({ theme }) => theme.colors.font};
  transition: all 0.3s;
`;

export const VotesCount = styled(TextBase)`
  line-height: 120%;
  transition: all 0.3s;
`;

export const Button = styled(ButtonBase)`
  margin-top: 40px;
  margin-left: auto;
`;
