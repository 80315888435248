import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { CurrencyDollarSimple, Package, X as CloseIcon } from 'phosphor-react';
import { Facebook } from '@profitfy/pakkun-icons';

import { ETextWeight, ETextSize } from '@domain/enums/components/EText';
import { ENotificationType } from '@domain/enums/dashboard/notification/ENotificationType';
import { INotificationProps } from '@domain/interfaces/dashboard/Notifications/INotification';

import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const Notification: React.FC<INotificationProps> = ({
  data,
  onCloseManually,
  closeNotification,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { analytics, user } = useConfig();

  const onNotificationClick = React.useCallback(() => {
    analytics?.track(
      'User Notification Clicked',
      { type: data?.type, title: data?.title, description: data?.description, email: user.email },
      { context: { groupId: data?.store_alias_id } },
    );

    if (data?.internal_url) {
      history.push(`/${data.store_alias_id}/${data.internal_url}`);
    }

    if (data?.external_url) {
      window.open(data.external_url, '_blank');
    }

    closeNotification();
  }, [history, data, closeNotification, analytics, user]);

  const getIcon = (): JSX.Element => {
    if (data?.type === ENotificationType.CUSTOM_VALUE_BILLING_CREATED) {
      return <CurrencyDollarSimple size={40} color={theme.colors.green.default} weight="bold" />;
    }

    if (data?.type === ENotificationType.FACEBOOK_AD_SENSE_ACCOUNT_BLOCKED) {
      return <Facebook size={40} />;
    }

    if (data?.type === ENotificationType.PRODUCT_FIRST_TIME_ORDER) {
      return <Package size={40} color={theme.colors.green.default} weight="bold" />;
    }

    return <></>;
  };

  return (
    <S.Wrapper onClick={onNotificationClick}>
      <S.IconWrapper>{getIcon()}</S.IconWrapper>

      <S.TitleAndDescriptionWrapper>
        <S.Title weight={ETextWeight.BOLD}>{data?.title}</S.Title>
        <S.Description size={ETextSize.B5}>{data?.description}</S.Description>
      </S.TitleAndDescriptionWrapper>

      <S.CloseIcon onClick={onCloseManually}>
        <CloseIcon size={20} color={theme.colors.gray[1]} weight="bold" />
      </S.CloseIcon>
    </S.Wrapper>
  );
};

export default Notification;
