import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import * as S from './styles';

const Header: React.FC = () => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();

  const onGenerateLinkClick = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/admin/checkout-generator`);
  }, [history, storeAliasId]);

  return (
    <S.Wrapper>
      <S.Title>Cupons</S.Title>

      <S.Button onClick={onGenerateLinkClick}>Acessar gerador de link de checkout</S.Button>
    </S.Wrapper>
  );
};

export default Header;
