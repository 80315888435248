import { ISynchronizationRequest } from '@domain/interfaces/hooks/IStoreSynchronization';
import {
  ESynchronizationType,
  ESynchronizationRequestType,
} from '@domain/enums/hooks/ESynchronization';

import { LONG_API_DOMAIN } from '@constants/api';

import { getEnvironment } from '@helpers/utils/environment';

export const getShopifySynchronizationRequestConfig = (
  type: ESynchronizationType,
  storeAliasId: string | undefined,
  params: string,
): ISynchronizationRequest => {
  if (type === ESynchronizationType.STORE_PRODUCT) {
    return {
      type: ESynchronizationRequestType.POST,
      domain: LONG_API_DOMAIN,
      api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/shopify/products/sync`,
      requestParams: params,
    };
  }

  return {
    type: ESynchronizationRequestType.POST,
    domain: LONG_API_DOMAIN,
    api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/shopify/orders/sync`,
    requestParams: params,
  };
};

export const getShopifyGatewaySynchronizationRequestConfig = (
  gateway: string,
  storeAliasId: string | undefined,
  params: string,
): ISynchronizationRequest => {
  return {
    type: ESynchronizationRequestType.POST,
    domain: LONG_API_DOMAIN,
    api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/shopify/gateways/${gateway}/sync`,
    requestParams: params,
  };
};
