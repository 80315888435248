import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const ReactiveSubscriptionButton = styled(ButtonBase)`
  width: 100%;
`;

export const ReasonDescriptionText = styled(TextBase)`
  font-weight: 600;
  line-height: 150%;
`;

export const ReasonDescription = styled.div`
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  border-radius: 5px;
  box-sizing: border-box;
`;

export const ReasonLabel = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const ReasonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 24px;
`;

export const Description = styled(TextBase)`
  line-height: 130%;
  font-size: 1rem;
  display: inline-block;
  margin-bottom: 24px;
`;

export const Title = styled(HeadingBase)``;

export const Body = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
`;

export const Wrapper = styled.div`
  max-width: 392px;
  width: 100%;
`;

export const Modal = styled(ModalBase)``;
