import styled, { keyframes, css } from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

const modalAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  50% {
    opacity: 0;
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform: translateY(9);
  }
`;

export const VideoWrapper = styled.div`
  margin-bottom: 24px;
`;

export const Video = styled.img`
  width: 100% !important;
  height: auto !important;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 400px;
  position: fixed;
  z-index: 1;
  bottom: 24px;
  right: 24px;
  padding: 24px;
  padding-top: 36px;
  box-sizing: border-box;
  border-radius: 12px;
  background: radial-gradient(
      64.29% 51.81% at 109.07% -4.25%,
      rgba(183, 138, 255, 0.2) 18.01%,
      rgba(0, 0, 0, 0) 68.74%
    ),
    radial-gradient(
      93.15% 89.25% at -17.95% 7.54%,
      rgba(183, 138, 255, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    radial-gradient(
      75.58% 60.91% at 117.66% 97.4%,
      rgba(183, 138, 255, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    #000000;
  animation: ${modalAnimation} 4s;
  z-index: 9999;
`;

export const Image = styled(ImageBase)`
  margin-bottom: 24px;
  max-width: 447px;
  border-radius: 5px;
`;

export const SubTitle = styled(TextBase)`
  text-transform: uppercase;
  color: #00f2a1;
  font-size: 0.75rem;
`;

export const Title = styled(HeadingBase)`
  color: #ffffff;
  margin-bottom: 8px;
  line-height: 130%;
`;

export const Description = styled(TextBase)`
  line-height: 150%;
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray[2]};
  font-size: 0.875rem;
  margin-bottom: 8px;
`;

export const Item = styled(TextBase)<{ bullets?: boolean }>`
  position: relative;
  line-height: 150%;
  display: block;
  color: ${({ theme }) => theme.colors.gray[2]};
  font-size: 0.875rem;
  font-weight: 700;

  ${({ bullets }) =>
    bullets &&
    css`
      padding-left: 14px;

      &::before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.gray[2]};
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-70%);
      }
    `}
`;

export const LastItem = styled(TextBase)`
  position: relative;
  line-height: 150%;
  display: block;
  color: ${({ theme }) => theme.colors.gray[2]};
  font-size: 0.875rem;
  margin-top: 8px;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const SeeNewsButtons = styled.button`
  padding: 10px 24px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #00f2a1;
  border: unset;
  color: ${({ theme }) => theme.colors.gray[8]};
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 4px;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  cursor: pointer;
`;

export const CancelButton = styled.button`
  width: 100%;
  max-width: 92px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: unset;
  padding: unset;
  border: unset;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  color: #ffffff;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 18px;
  height: 18px;
  background: unset;
  border: unset;
  padding: unset;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;
