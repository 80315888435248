import React from 'react';

import { INoImageProps } from '@domain/interfaces/components/INoImage';
import { ENoImageSize } from '@domain/enums/components/ENoImage';
import noImage from '@assets/components/NoImage/no-image.svg';

import * as S from './styles';

const NoImage: React.FC<INoImageProps> = ({ size = ENoImageSize.MEDIUM, ...rest }) => {
  return (
    <S.NoImageWrapper size={size} {...rest}>
      <S.Image src={noImage} alt="Profitfy.me Sem Imagem" size={size} />
    </S.NoImageWrapper>
  );
};

export default NoImage;
