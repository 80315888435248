import {
  IVariant,
  IUpdateVariantCost,
} from '@domain/interfaces/dashboard/ProductCostDetails/IProductCostDetails';

export const parseVariantCosts = (variants: Array<IVariant>): Array<IUpdateVariantCost> => {
  const mappedVariantCosts = variants.map(variant => {
    return {
      id: variant.id,
      currency: variant.currency,
      cost: variant.default_cost,
    };
  });

  return mappedVariantCosts;
};
