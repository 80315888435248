import styled from 'styled-components/macro';

export const ConfirmButton = styled.button`
  width: 100%;
  background-color: unset;
  padding: unset;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.green.default};
  font-size: 0.875rem;
  font-weight: 400;
  border: 1px solid ${({ theme }) => theme.colors.green.default};
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 0 0 5px 0;

  &:hover {
    background-color: ${({ theme }) => theme.colors.green.default};
    color: ${({ theme }) => theme.colors.white.default};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.green.default};
    color: ${({ theme }) => theme.colors.white.default};
    filter: brightness(0.8);
  }
`;

export const CancelButton = styled.button`
  width: 100%;
  background-color: unset;
  padding: unset;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.green.default};
  font-size: 0.875rem;
  font-weight: 400;
  border: 1px solid ${({ theme }) => theme.colors.green.default};
  border-radius: 0 0 0 5px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.green.default};
    color: ${({ theme }) => theme.colors.white.default};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.green.default};
    color: ${({ theme }) => theme.colors.white.default};
    filter: brightness(0.8);
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;

export const ValueToFilterInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  background-color: unset;
  border: none;
  color: ${({ theme }) => theme.colors.font};
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;

  &::placeholder {
    color: ${({ theme }) => theme.colors.font};
    opacity: 0.6;
  }

  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.font};
    opacity: 0.6;
  }

  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.font};
    opacity: 0.6;
  }

  &:focus {
    outline: none;
  }
`;

export const ValueToFilterWrapper = styled.div`
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
`;

export const Wrapper = styled.div``;
