import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ImageBase from '@components/common/core/DataDisplay/Image';
import TextBase from '@components/common/core/DataDisplay/Text';
import NoImageBase from '@components/common/core/DataDisplay/NoImage';

export const NoImage = styled(NoImageBase)`
  background-color: ${({ theme }) => theme.colors.gray[5]};
`;

export const AddQuantityButton = styled.button`
  height: 42px;
  width: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: unset;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  border-left: 1px solid ${({ theme }) => theme.colors.gray[3]};
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const QuantityText = styled(TextBase)`
  width: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RemoveQuantityButton = styled.button`
  height: 42px;
  width: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: unset;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  border-right: 1px solid ${({ theme }) => theme.colors.gray[3]};
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const QuantityButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.gray[3]};
  border-radius: 5px;
  width: fit-content;
  overflow: hidden;
`;

export const QuantityLabel = styled(TextBase)`
  font-size: 0.875rem;
`;

export const QuantityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const VariantSku = styled(TextBase)`
  max-width: 460px;
  -webkit-line-clamp: 2;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 150%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.colors.font};
`;

export const VariantName = styled(TextBase)`
  color: ${({ theme }) => theme.colors.font};
  font-size: 1rem;
  line-height: 150%;
`;

export const VariantDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const VariantImage = styled(ImageBase)`
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

export const VariantImageAndDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const VariantNumberText = styled(HeadingBase)`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const RemoveButton = styled.button`
  background-color: unset;
  border: unset;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[4]};
  }
`;

export const Variant = styled.div`
  padding: 16px 32px 16px 16px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.gray[4]};
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
`;

export const VariantWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 12px;
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 16px;
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[6]};
  padding: 24px 32px;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 24px;

  &:focus {
    outline: unset;
  }
`;
