import React from 'react';

import { ETextWeight } from '@domain/enums/components/EText';
import { EBusinessType } from '@domain/enums/stores/Etype';
import { IReturnProps } from '@domain/interfaces/onboarding/components/sideBar/ISideBar';
import { useConfig } from '@helpers/hooks/useConfig';
import { GoBack } from '@profitfy/pakkun-icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import * as S from './styles';

const Return: React.FC<IReturnProps> = ({ linkToGoBack }) => {
  const theme = useTheme();
  const history = useHistory();

  const { pathname } = useLocation();
  const { stores, selectedStore } = useConfig();

  const isInfoProductStore = selectedStore?.type === EBusinessType.INFO_PRODUCT;

  const getGoBackLink = (): string => {
    if (linkToGoBack.includes('dropshipping') && isInfoProductStore) {
      const replacedLink = linkToGoBack.replace('dropshipping', 'info-product');

      return replacedLink;
    }

    return linkToGoBack;
  };

  const goBackLink = getGoBackLink();

  const handleGoBack = React.useCallback(() => {
    if (pathname === '/onboarding/store' && stores?.length) {
      history.push('/');
    } else {
      history.push(goBackLink);
    }
  }, [history, stores, goBackLink, pathname]);

  return (
    <S.Wrapper onClick={handleGoBack}>
      <GoBack size={18} color={theme.colors.gray[1]} />
      <S.Text weight={ETextWeight.LIGHT}>Retornar para o passo anterior</S.Text>
    </S.Wrapper>
  );
};

export default Return;
