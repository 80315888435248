import React from 'react';

import { useParams } from 'react-router-dom';
import { IParams } from '@domain/interfaces/IParams';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import CongratulationsModal from '@components/Onboarding/CongratulationsModal';
import Header from '../Header';
import ProcessingStatus from '../ProcessingStatus';
import Features from '../Features';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const { currentSynchronizations } = useSynchronization();
  const { storeAliasId } = useParams<IParams>();

  const [isFinishedOnboardingModalOpen, setIsFinishedOnboardingModalOpen] = React.useState<boolean>(
    false,
  );

  const handleFinishedOnobardingModalOpen = React.useCallback(() => {
    setIsFinishedOnboardingModalOpen(!isFinishedOnboardingModalOpen);
  }, [isFinishedOnboardingModalOpen]);

  const parsedCurrentSynchronizations = currentSynchronizations
    ? currentSynchronizations.synchronizations
    : [];

  React.useEffect(() => {
    const filteredCurrentStoreSynchronizations = parsedCurrentSynchronizations.filter(
      synchronization => synchronization.storeAliasId === storeAliasId,
    );

    if (filteredCurrentStoreSynchronizations.length) {
      setIsFinishedOnboardingModalOpen(false);
    } else {
      setIsFinishedOnboardingModalOpen(true);
    }
  }, [parsedCurrentSynchronizations, storeAliasId]);

  return (
    <S.Wrapper>
      <Header />
      <ProcessingStatus />
      <Features />

      <CongratulationsModal
        isOpen={isFinishedOnboardingModalOpen}
        toggle={handleFinishedOnobardingModalOpen}
      />
    </S.Wrapper>
  );
};

export default Wrapper;
