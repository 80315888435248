import { usePricingTool } from '@helpers/hooks/pages/dashboard/pricingTool/usePricingTool';
import {
  Coins,
  CreditCard,
  CurrencyDollarSimple,
  Percent,
  ShoppingCartSimple,
  Truck,
} from 'phosphor-react';
import React from 'react';
import { useTheme } from 'styled-components';
import * as S from './styles';

const FixedCost: React.FC = () => {
  const theme = useTheme();
  const {
    handleIofAliexpress,
    handleShipmentFee,
    handleCheckout,
    handleGateway,
    handleTax,
  } = usePricingTool();

  return (
    <S.Container>
      <S.TitleCard>Custos Fixos</S.TitleCard>
      <S.InputsWrapper>
        <S.LabelWrapper>
          <Coins size={16} color={theme.colors.font} />
          <S.Label>IOF</S.Label>
        </S.LabelWrapper>
        <S.IofAliExpressSelect
          key="iofAliexpress"
          name="iofAliexpress"
          prepend={() => <Percent size={16} color="#00F2A1" />}
          onChange={event => {
            handleIofAliexpress(Number(event.target.value) || 0);
          }}
        >
          <S.SelectOption value={0.0038}>0,38%</S.SelectOption>
          <S.SelectOption value={0.0638}>6,38%</S.SelectOption>
          <S.SelectOption value={0}>Nenhum</S.SelectOption>
        </S.IofAliExpressSelect>
      </S.InputsWrapper>

      <S.InputsWrapper>
        <S.LabelWrapper>
          <Truck size={16} color={theme.colors.font} />
          <S.Label>Frete</S.Label>
        </S.LabelWrapper>
        <S.Input
          key="shipmentFee"
          name="shipmentFee"
          prepend={() => <CurrencyDollarSimple size={20} color="#00F2A1" />}
          type="number"
          placeholder="0,00"
          onChange={event => {
            handleShipmentFee(Number(event.target.value) || 0.0);
          }}
        />
      </S.InputsWrapper>

      <S.InputsWrapper>
        <S.LabelWrapper>
          <ShoppingCartSimple size={16} color={theme.colors.font} />
          <S.Label>Checkout</S.Label>
        </S.LabelWrapper>
        <S.Input
          key="checkout"
          name="checkout"
          prepend={() => <Percent size={20} color="#00F2A1" />}
          type="number"
          placeholder="2,5"
          onChange={event => {
            handleCheckout(Number(event.target.value) / 100 || 0.025);
          }}
        />
      </S.InputsWrapper>

      <S.InputsWrapper>
        <S.LabelWrapper>
          <CreditCard size={16} color={theme.colors.font} />
          <S.Label>Gateway</S.Label>
        </S.LabelWrapper>
        <S.Input
          key="gateway"
          name="gateway"
          prepend={() => <Percent size={20} color="#00F2A1" />}
          type="number"
          placeholder="5"
          onChange={event => {
            handleGateway(Number(event.target.value) / 100 || 0.05);
          }}
        />
      </S.InputsWrapper>

      <S.InputsWrapper>
        <S.LabelWrapper>
          <Percent size={16} color={theme.colors.font} />
          <S.Label>Impostos</S.Label>
        </S.LabelWrapper>
        <S.Input
          key="tax"
          name="tax"
          prepend={() => <Percent size={20} color="#00F2A1" />}
          type="number"
          placeholder="0"
          onChange={event => {
            handleTax(Number(event.target.value) / 100 || 0);
          }}
        />
      </S.InputsWrapper>
    </S.Container>
  );
};

export default FixedCost;
