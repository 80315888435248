import styled from 'styled-components/macro';

export const Table = styled.table`
  width: 100%;
  min-width: max-content;
  border-collapse: collapse;
`;

export const Container = styled.div`
  padding: 0;
  overflow-x: scroll;
  background: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 15px;

  &::-webkit-scrollbar {
    height: 6px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[6]};
    border-radius: 25px;
    margin-bottom: 4px;
  }
`;
