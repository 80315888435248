import * as yup from 'yup';

export const pagseguroSchema = yup.object().shape({
  email: yup
    .string()
    .email('Ë necessário informar um e-mail')
    .required('Este campo é obrigatório.'),
  access_token: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(10, 'Informe uma credencial válida')
    .max(100, 'Informe uma credencial válida'),
});
