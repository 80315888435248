import React from 'react';

import { useProducts } from '@helpers/hooks/pages/dashboard/feed/useProduct';

import Header from './Header';
import Product from './Product';

import * as S from './styles';

const Products: React.FC = () => {
  const { products, isLoadingProducts, isValidatingProducts } = useProducts();

  const hasProducts = !isLoadingProducts && !isValidatingProducts && products.length;

  if (!hasProducts) return <></>;

  return (
    <S.Wrapper>
      <Header />

      <S.ProductsWrapper>
        {products.map(product => (
          <Product product={product} key={product.alias_id} />
        ))}
      </S.ProductsWrapper>
    </S.Wrapper>
  );
};

export default Products;
