import styled from 'styled-components/macro';

import ButtonBase from '@components/common/core/Inputs/Button';

export const SubmitButton = styled(ButtonBase)`
  height: 36px;
`;

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background-color: ${({ theme }) => theme.colors.background[2]};
`;
