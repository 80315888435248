import React from 'react';

import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';

import zendropEventService from '@services/pages/dashboard/benefitArea/zendropEvent';

import MainSection from './MainSection';
import SimulationSection from './SimulationSection';
import BenefitsSection from './BenefitsSection';
import SuccessModal from './SuccessModal';

import * as S from './styles';

const Zendrop: React.FC = () => {
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { user, analytics } = useConfig();

  const [isSuccessModalOpen, setIsSuccessModalOpen] = React.useState<boolean>(false);
  const [isSendingCogsInformation, setIsSendingCogsInformation] = React.useState<boolean>(false);

  const handleSuccessModalOpen = React.useCallback(() => {
    setIsSuccessModalOpen(currentState => !currentState);
  }, []);

  const sendEvent = React.useCallback(async () => {
    if (user?.is_zendrop_cogs_information_sent) {
      toast.error('Essa ação já foi realizada antes.');

      return;
    }

    setIsSendingCogsInformation(true);

    try {
      await zendropEventService.sendCogsInformationEvent({
        storeAliasId,
      });

      analytics?.track(
        'Zendrop Seize Opportunity Button Clicked',
        { email: user?.email },
        { context: { groupId: storeAliasId } },
      );

      setIsSendingCogsInformation(false);
      handleSuccessModalOpen();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsSendingCogsInformation(false);
    }
  }, [storeAliasId, handleSuccessModalOpen, toast, user, analytics]);

  React.useEffect(() => {
    analytics?.track(
      'Zendrop Page Viewed',
      { email: user?.email },
      { context: { groupId: storeAliasId } },
    );
  }, [analytics, user, storeAliasId]);

  return (
    <S.Container>
      <MainSection sendEvent={sendEvent} isSendingCogsInformation={isSendingCogsInformation} />

      <SimulationSection
        sendEvent={sendEvent}
        isSendingCogsInformation={isSendingCogsInformation}
      />

      <BenefitsSection />

      <SuccessModal isOpen={isSuccessModalOpen} toggle={handleSuccessModalOpen} />
    </S.Container>
  );
};

export default Zendrop;
