import React from 'react';
import ReactDOM from 'react-dom';
import { SkeletonTheme } from 'react-loading-skeleton';

import { useTheme } from 'styled-components/macro';
import { Cancel } from '@profitfy/pakkun-icons';

import { ISideModal } from '@domain/interfaces/components/ISideModal';
import { ETextWeight } from '@domain/enums/components/EText';
import { useLocalStorage } from '@helpers/hooks/useLocalStorage';

import * as S from './styles';

const SideModal: React.FC<ISideModal> = ({
  isOpen,
  children,
  toggle,
  blockOverflowYScroll = false,
  ...rest
}) => {
  const theme: any = useTheme();

  const [userTheme] = useLocalStorage('user-theme', 'dark');

  const portalDiv = document.getElementById('portal');

  const onBackgroundClick = React.useCallback(
    event => {
      event.stopPropagation();

      toggle();
    },
    [toggle],
  );

  const onContentClick = React.useCallback(event => {
    event.stopPropagation();
  }, []);

  if (!portalDiv) return null;

  return ReactDOM.createPortal(
    <>
      {isOpen && (
        <>
          <S.ModalBackground onClick={onBackgroundClick} />
          <S.ModalContent
            onClick={onContentClick}
            blockOverflowYScroll={blockOverflowYScroll}
            {...rest}
          >
            <SkeletonTheme
              color={userTheme === 'dark' ? '#252931' : '#E8E9ED'}
              highlightColor={userTheme === 'dark' ? '#303440' : '#F0F1F4'}
            >
              <S.ModalContentWrapper>
                <S.CloseButtonWrapper>
                  <S.CloseButton onClick={toggle}>
                    <S.CloseButtonWrapperLabel weight={ETextWeight.REGULAR}>
                      Fechar
                    </S.CloseButtonWrapperLabel>
                    <Cancel size={18} color={theme.colors.sideModalExitIcon} />
                  </S.CloseButton>
                </S.CloseButtonWrapper>
                {children}
              </S.ModalContentWrapper>
            </SkeletonTheme>
          </S.ModalContent>
        </>
      )}
    </>,
    portalDiv,
  );
};

export default React.memo(SideModal);
