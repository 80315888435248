import React from 'react';

import { Kiwify as Icon } from '@profitfy/pakkun-icons';
import { useToast } from '@helpers/hooks/useToast';
import { IParams } from '@domain/interfaces/IParams';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { IActiveIntegrationProps } from '@domain/interfaces/dashboard/IntegrationCenter/InfoProduct/IPerfectPay';
import { useParams } from 'react-router-dom';

import perfectPayService from '@services/pages/dashboard/integrationCenter/infoProduct/platforms/perfectPayAuth';

import ActiveIntegrationFooter from '@components/Dashboard/IntegrationCenter/Sidemodal/ActiveIntegrationFooter';

import * as S from './styles';

const ActiveIntegration: React.FC<IActiveIntegrationProps> = ({ credentialAliasId }) => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { mutateStore } = useStoreConfig();

  const [isActivatingIntegration, setIsActivatingIntegration] = React.useState<boolean>(false);

  const handleButtonClick = React.useCallback(async () => {
    setIsActivatingIntegration(true);

    try {
      await perfectPayService.updateCredential({
        storeAliasId,
        data: { is_active: true },
        credentialAliasId,
      });

      toast.success('Credencial Ativada com sucesso!');

      await mutateStore();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsActivatingIntegration(false);
    }
  }, [mutateStore, storeAliasId, toast, credentialAliasId]);

  return (
    <S.ContentWrapper>
      <S.Header>
        <Icon size={52} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.REGULAR}>
          Integração com Perfect Pay
        </S.Heading>
      </S.Header>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>
        <S.FunctionalityDescriptionText>
          A integração com sua plataforma de info-produto serve para puxarmos seus dados de
          plataforma aumentando a exatidão dos seus dados.
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <ActiveIntegrationFooter
        onIntegrateClick={handleButtonClick}
        isIntegrating={isActivatingIntegration}
        disabled={isActivatingIntegration}
      />
    </S.ContentWrapper>
  );
};

export default ActiveIntegration;
