import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const HeaderWrapperButton = styled.button`
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 24px;
  border: none;
  background-color: unset;
  cursor: pointer;
`;

export const Title = styled(HeadingBase)``;
