import styled, { css } from 'styled-components/macro';

import { ENoImageSize } from '@domain/enums/components/ENoImage';
import { INoImageProps } from '@domain/interfaces/components/INoImage';
import ImageBase from '@components/common/core/DataDisplay/Image';

export const Image = styled(ImageBase)<INoImageProps>`
  width: 24px;
  height: 24px;

  ${({ size }) =>
    size === ENoImageSize.SMALL &&
    css`
      width: 16px;
      height: 16px;
    `}

  ${({ size }) =>
    size === ENoImageSize.LARGE &&
    css`
      width: 32px;
      height: 32px;
    `}
`;

export const NoImageWrapper = styled.div<INoImageProps>`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ size }) =>
    size === ENoImageSize.SMALL &&
    css`
      width: 32px;
      height: 32px;
    `}

  ${({ size }) =>
    size === ENoImageSize.LARGE &&
    css`
      width: 64px;
      height: 64px;
    `}
`;
