import React from 'react';

import { TemplateProvider } from '@helpers/hooks/pages/dashboard/automations/useTemplate';
import { StepModalProvider } from '@helpers/hooks/components/useStepModal';

import Templates from '@components/Dashboard/Automations/Templates';

const Automations: React.FC = () => {
  return (
    <TemplateProvider>
      <StepModalProvider>
        <Templates />
      </StepModalProvider>
    </TemplateProvider>
  );
};

export default Automations;
