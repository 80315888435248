import useSWR from 'swr';

export const useFetch = (url: string, fetcher: any, initialData?: any): any => {
  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    revalidateOnReconnect: false,
    dedupingInterval: 5000,
    initialData,
  };

  const { data, error, mutate, isValidating } = useSWR(url, () => fetcher.get(`${url}`), options);

  return { data: data?.data, error, mutate, isValidating };
};
