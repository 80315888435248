import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import { IDotColorProps } from '@domain/interfaces/components/IChartTooltip';

export const LabelText = styled(TextBase)``;

export const DotColor = styled.div<IDotColorProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin-right: 8px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TooltipTitle = styled(TextBase)`
  margin-bottom: 4px;
`;

export const TooltipCard = styled.div`
  padding: 8px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.background[2]};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
`;
