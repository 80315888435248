export const currency = (currencyToParse: string): string =>
  ({
    BRL: 'R$',
    USD: '$',
  }[currencyToParse] || 'R$');

export const currencyFormatter = (value: string): string => {
  let newValue = value;

  const parsedValue = value.replace(/\D/g, '');

  // if (value === '0') return '';

  // if (parsedValue.length === 2 && parsedValue.startsWith('00')) return '';

  if (value.includes('.') && value.split('.')[1].length === 1) {
    newValue = `${value}0`;
  }

  if (parsedValue.length === 1 && parsedValue !== '0') {
    newValue = `0.0${value}`;
  }

  if (parsedValue.length === 2) {
    newValue = `0.${value}`;
  }

  if (parsedValue.length === 4 && parsedValue.startsWith('00')) {
    const removedInitial = parsedValue.replace('00', '');

    newValue = `0.${removedInitial}`;
  }

  if (parsedValue.length === 4 && parsedValue.startsWith('0')) {
    newValue = parsedValue.substring(1);
  }

  return newValue
    .replace(/\D/g, '')
    .replace(/(\d)(\d{2})$/, '$1,$2')
    .replace(/(?=(\d{3})+(\D))\B/g, '.');
};

export const currencyToNumber = (currencyToParse: string): number => {
  return Number(currencyToParse.replace('.', '').replace('.', '').replace(',', '.'));
};
