import React from 'react';

import profitfyLogo from '@assets/img/brand/profitfy-logo.svg';
import Stepper from '../Stepper';
import Forms from '../Forms';

import * as S from './styles';

const Content: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Logo src={profitfyLogo} alt="Profitfy Logo" />

      <Stepper />

      <Forms />
    </S.Wrapper>
  );
};

export default Content;
