import React from 'react';

import { useFeedAdSenseAccount } from '@helpers/hooks/pages/dashboard/feed/useFeedAdSenseAccount';

import Facebook from './Facebook';

import * as S from './styles';

const AdAccountStatus: React.FC = () => {
  const {
    adSenseAccounts,
    isLoadingAdSenseAccounts,
    isValidatingdSenseAccounts,
  } = useFeedAdSenseAccount();

  const hasAdSenseAccounts =
    !isLoadingAdSenseAccounts && !isValidatingdSenseAccounts && adSenseAccounts.length;

  if (!hasAdSenseAccounts) return <></>;

  return (
    <S.ProvidersWrapper>
      <S.Wrapper>
        <Facebook />
      </S.Wrapper>
    </S.ProvidersWrapper>
  );
};

export default AdAccountStatus;
