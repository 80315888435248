import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const FeaturesWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;

  @media only screen and (max-width: 1116px) {
    flex-wrap: wrap;
  }
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 16px;
`;

export const Wrapper = styled.div``;
