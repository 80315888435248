import React from 'react';

import { useCheckoutFee } from '@helpers/hooks/pages/dashboard/checkoutFee/useCheckoutFee';
import { numberFormatter } from '@helpers/masks';
import { getFormattedCurrency } from '@helpers/utils/common/currency';

import * as S from './styles';

const DefaultCheckoutFee: React.FC = () => {
  const { defaultCheckoutFee } = useCheckoutFee();

  const hasPercentageAmount = defaultCheckoutFee.percentage_amount > 0;

  return (
    <S.DefaultCheckoutFeeWrapper>
      <S.CheckoutFeeText>
        {hasPercentageAmount
          ? `${numberFormatter(defaultCheckoutFee.percentage_amount, 2)}%`
          : `${getFormattedCurrency(defaultCheckoutFee.currency)}${numberFormatter(
              defaultCheckoutFee.amount,
              2,
            )}`}
      </S.CheckoutFeeText>
      <S.CheckoutFeeSubtitle>Taxa atual</S.CheckoutFeeSubtitle>
    </S.DefaultCheckoutFeeWrapper>
  );
};

export default DefaultCheckoutFee;
