import { IPlan } from '@domain/interfaces/subscription/IPlans';
import { ALL_PLANS } from '@constants/plans';
import { ITransaction } from '@domain/interfaces/common/transaction/ITransaction';
import { ETransactionType } from '@domain/enums/common/transaction/ETransactionType';
import { EBadgeColors } from '@domain/enums/components/EBadge';
import { TRANSACTION_CODE_ERRORS } from '@constants/errors/transaction';

export const getTransactionsStatusName = (status: string): string =>
  ({
    PAID: 'Pago',
    PENDING: 'Pendente',
    CANCELED: 'Cancelado',
    REFUNDED: 'Estornado',
    CHARGED_BACK: 'Chargeback',
  }[status] || EBadgeColors.GRAY);

export const getTransactionsStatusColor = (status: string): EBadgeColors =>
  ({
    PAID: EBadgeColors.GREEN,
    PENDING: EBadgeColors.YELLOW,
    CANCELED: EBadgeColors.RED,
    REFUNDED: EBadgeColors.RED,
    CHARGED_BACK: EBadgeColors.RED,
  }[status] || EBadgeColors.GRAY);

export const getPlanName = (planId: string, allPlans: Array<IPlan>): string => {
  const foundTransactionPlan = allPlans.find(plan => plan.id === planId);

  if (foundTransactionPlan) {
    const foundPlanWithSameIdentifier = ALL_PLANS.find(
      plan =>
        foundTransactionPlan.identifier === plan.identifier ||
        foundTransactionPlan.identifier === plan.quarterIdentifier ||
        foundTransactionPlan.identifier === plan.semiAnualIdentifier,
    );

    if (foundPlanWithSameIdentifier) return `Plano ${foundPlanWithSameIdentifier.name}`;
  }

  return '';
};

export const getTransactionDescription = (
  transaction: ITransaction,
  allPlans: Array<IPlan>,
): string | undefined => {
  if (transaction.type === ETransactionType.SUBSCRIPTION) {
    const planName = getPlanName(transaction.plan_id, allPlans);

    if (planName) return planName;

    return 'Plano Enterprise';
  }

  if (transaction.type === ETransactionType.SUBSCRIPTION_WITH_CONSUME) {
    const planName = getPlanName(transaction.plan_id, allPlans);

    return `${planName} + Pedidos Extras`;
  }

  if (transaction.type === ETransactionType.CONSUME) {
    return 'Pedidos Extras';
  }

  if (transaction.type === ETransactionType.CONSUME_PRE_CANCELLATION) {
    return 'Pedidos Extras ao Cancelar';
  }

  if (transaction.type === ETransactionType.CONSUME_PRE_PLAN_SWITCH) {
    return 'Pedidos Extras ao Trocar de Plano';
  }

  return undefined;
};

export const getFailedTransactionDescription = (lr: string | undefined = ''): string => {
  const transactionDetail = TRANSACTION_CODE_ERRORS[lr];

  if (transactionDetail) {
    return `${transactionDetail} [LR:${lr}]`;
  }

  return `LR:${lr}`;
};
