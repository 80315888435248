import React from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { X as CloseIcon } from 'phosphor-react';

import { ETextSize } from '@domain/enums/components/EText';
import { IParams } from '@domain/interfaces/IParams';

import { useAnnouncement } from '@helpers/hooks/pages/dashboard/admin/useAnnouncement';

import * as S from './styles';

const PreviewAnnouncement: React.FC = () => {
  const { previewData, handlePreviewAnnouncementOpen } = useAnnouncement();

  const theme = useTheme();
  const history = useHistory();

  const { storeAliasId } = useParams<IParams>();

  const [isImageLoaded, setIsImageLoaded] = React.useState<boolean>(false);

  const onSeeNewsButtonClick = React.useCallback(() => {
    const hasInternalURL = Boolean(previewData.internal_url);

    if (hasInternalURL) {
      history.push(`/${storeAliasId}/dashboard/${previewData?.internal_url}`);
    } else {
      window.open(previewData?.external_url, '_blank');
    }

    handlePreviewAnnouncementOpen();
  }, [previewData, history, storeAliasId, handlePreviewAnnouncementOpen]);

  return (
    <S.Wrapper>
      <S.Image
        src={previewData?.image_url}
        alt="Profitfy preview announcement image"
        onLoad={() => setIsImageLoaded(true)}
      />

      <S.SubTitle>Novidade</S.SubTitle>
      <S.Title>{previewData?.title}</S.Title>

      <S.Description size={ETextSize.B5}>{previewData?.description}</S.Description>

      <S.ButtonsWrapper>
        <S.SeeNewsButtons onClick={onSeeNewsButtonClick}>Ver novidade</S.SeeNewsButtons>
        <S.CancelButton onClick={handlePreviewAnnouncementOpen}>Fechar</S.CancelButton>
      </S.ButtonsWrapper>

      {isImageLoaded && (
        <S.CloseButton onClick={handlePreviewAnnouncementOpen}>
          <CloseIcon size={24} color={theme.colors.gray[2]} weight="bold" />
        </S.CloseButton>
      )}
    </S.Wrapper>
  );
};

export default PreviewAnnouncement;
