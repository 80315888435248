import adressErrors from '@locales/subscription/checkout/address.json';
import commonErrors from '@locales/common/commonErrors.json';

export const getCheckoutAddressTranslatedErrorMessage = (error: any): string => {
  const errorMessage = error?.response?.data?.message;

  if (!errorMessage) return error;

  const mergedArray = [...adressErrors, ...commonErrors];

  const foundErrorMessage = mergedArray.find(adressError => adressError.apiError === errorMessage);

  if (foundErrorMessage) return foundErrorMessage.translatedError;

  return errorMessage;
};
