import React from 'react';

import {
  ChatTeardropText,
  Coins,
  FlowArrow,
  MagnifyingGlass,
  PaperPlaneTilt,
  Plus,
} from 'phosphor-react';

import { useHistory, useParams } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import { useTheme } from 'styled-components';

import { EFilterMode, EFilterVariant } from '@domain/enums/components/EFilter';
import { IParams } from '@domain/interfaces/IParams';

import { useStorePermission } from '@helpers/hooks/common/useStorePermission';
import { useAutomationsManager } from '@helpers/hooks/pages/dashboard/automations/useAutomationsManager';
import { useConfig } from '@helpers/hooks/useConfig';

import { AUTOMATIONS_MANAGER_CONFIG } from '@helpers/utils/filters/automationsManagerFilter';

import Filter from '@components/common/core/Utils/Filter';
import FilterButton from '@components/common/core/Utils/Filter/FilterButton';
import LimitationModal from '@components/common/core/Utils/LimitationModal';
import EmptyData from '../EmpytData';
import SkeletonLoading from '../SkeletonLoading';
import SummaryCard from '../SummaryCard';
import Table from '../Table';
import Row from '../Table/Row';

import * as S from './styles';
import TestSideModal from './TestSideModal';

const AutomationsManager: React.FC = () => {
  const history = useHistory();

  const { colors } = useTheme();
  const { analytics } = useConfig();
  const { storeAliasId } = useParams<IParams>();
  const { maxWorkflows } = useStorePermission();

  const {
    filterData,
    handleFilterData,
    workflow_metrics,
    handleWorkflowSearch,
    name,
    workflows,
    isLoadingWorkflows,
    isValidatingWorkflows,
    isTestSideModalOpen,
    handleTestSideModalOpen,
  } = useAutomationsManager();
  // const { handleStatus, tutorialStatus } = useStepModal();

  // const [showTutorial, setShowTutorial] = React.useState<boolean>(false);
  const [isLimitationModalOpen, setIsLimitationModalOpen] = React.useState<boolean>(false);

  const tableParentElementRef = React.useRef<HTMLDivElement>(null);

  const offsetComparedToTop =
    window.pageYOffset + (tableParentElementRef?.current?.getBoundingClientRect().top || 0) || 0;

  const tableHeader = 42;

  const maxHeight =
    window.innerHeight > 720
      ? window.innerHeight - offsetComparedToTop - tableHeader
      : window.innerHeight - tableHeader - 32;

  const mappedItemsLength = workflows?.length || 0;

  const listHeight = mappedItemsLength * 74 >= maxHeight ? maxHeight : mappedItemsLength * 74 + 18;

  const handleLimitationModalOpen = React.useCallback(() => {
    setIsLimitationModalOpen(state => !state);
  }, []);

  const handleNewData = React.useCallback(
    newData => {
      handleFilterData([newData]);
    },
    [handleFilterData],
  );

  const handleStartNewAutomation = React.useCallback(() => {
    if ((workflows?.length ?? 0) >= maxWorkflows && maxWorkflows !== -1) {
      handleLimitationModalOpen();
      return;
    }

    history.push(`/${storeAliasId}/dashboard/marketing/automations/templates`);
  }, [history, storeAliasId, maxWorkflows, workflows, handleLimitationModalOpen]);

  const handleManageMessages = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/marketing/automations/messages`);
  }, [history, storeAliasId]);

  React.useEffect(() => {
    if (
      !isLoadingWorkflows &&
      !isValidatingWorkflows &&
      !workflows.length &&
      filterData.length === 0
    ) {
      history.push(`/${storeAliasId}/dashboard/marketing/automations/templates`);
    }
  }, [isLoadingWorkflows, workflows, history, storeAliasId, isValidatingWorkflows, filterData]);

  React.useEffect(() => {
    analytics?.track(
      'Automation Tool Automation Manager Page Viewed',
      {},
      { context: { groupId: storeAliasId } },
    );
  }, [storeAliasId, analytics]);

  // const handleCloseTutorial = React.useCallback(() => {
  //   handleStatus({ ads_manager_tutorial: false });
  //   setShowTutorial(false);
  // }, [handleStatus, setShowTutorial]);

  // React.useEffect(() => {
  //   if (tutorialStatus?.ads_manager_tutorial === undefined) {
  //     setShowTutorial(true);
  //     handleStatus({ ads_manager_tutorial: true });
  //   }

  //   if (tutorialStatus?.ads_manager_tutorial) {
  //     setShowTutorial(true);
  //   }
  // }, [tutorialStatus, handleStatus]);

  const isLoading = isLoadingWorkflows || isValidatingWorkflows;

  const hasWorkflows = Boolean(workflows?.length);

  return (
    <S.Wrapper>
      <S.TitleWrapper>
        <S.Title>Gerenciador de Automações</S.Title>

        <S.ManagerButtonsWrapper>
          <S.ManageMessagesButton onClick={handleManageMessages} iconPrepend={ChatTeardropText}>
            Gerenciar mensagens
          </S.ManageMessagesButton>
          <S.NewAutomationButton
            onClick={handleStartNewAutomation}
            iconPrepend={Plus}
            iconColor={colors.gray[6]}
          >
            Nova automação
          </S.NewAutomationButton>
        </S.ManagerButtonsWrapper>
      </S.TitleWrapper>

      <S.SummaryCardsWrapper>
        <SummaryCard
          icon={FlowArrow}
          iconColor={colors.info.light}
          iconBackgroundColor="rgba(91, 141, 239, 0.2)"
          description="Total de automações"
          value={workflow_metrics.workflows_quantity}
        />

        <SummaryCard
          icon={PaperPlaneTilt}
          iconColor={colors.warning.light}
          iconBackgroundColor="rgba(239, 201, 128, 0.2)"
          description="Quantidade de Envios (7d)"
          value={workflow_metrics.delivery_workflow_event_quantity}
        />

        <SummaryCard
          icon={Coins}
          iconColor={colors.success.default}
          iconBackgroundColor="rgba(89, 181, 120, 0.2)"
          description="Valor Total Recuperado (7d)"
          value={workflow_metrics.recovered_amount}
          isFinancial
        />
      </S.SummaryCardsWrapper>

      <S.FilterWrapper>
        <S.SubTitle>Automações</S.SubTitle>

        <S.Group>
          {Boolean(!filterData.length) && (
            <FilterButton
              data={filterData}
              mode={EFilterMode.AUTOMATION}
              config={AUTOMATIONS_MANAGER_CONFIG}
              addNewData={handleNewData}
              variant={EFilterVariant.LARGE}
            />
          )}

          <S.TextInput
            type="text"
            placeholder="Procurar automação"
            prepend={MagnifyingGlass}
            prependIconColor={colors.gray[3]}
            prependIconBar={false}
            defaultValue={name}
            onChange={event => {
              handleWorkflowSearch(event.target.value);
            }}
          />
        </S.Group>
      </S.FilterWrapper>

      {Boolean(filterData.length) && (
        <S.Spacer>
          <Filter
            config={AUTOMATIONS_MANAGER_CONFIG}
            handleFilterData={handleFilterData}
            filterData={filterData}
          />
        </S.Spacer>
      )}

      <S.TableWrapper>
        {isLoading && <SkeletonLoading />}

        {!isLoading && !hasWorkflows && (
          <>
            <EmptyData />
          </>
        )}

        {!isLoading && hasWorkflows && (
          <List
            height={listHeight}
            innerElementType={Table}
            itemCount={workflows.length}
            itemSize={64}
            width="100%"
          >
            {Row}
          </List>
        )}
      </S.TableWrapper>

      {/* <StepsModal
        isOpen={showTutorial}
        side="right"
        tutorial={tutorial}
        page="Automations Manager"
        toggle={handleCloseTutorial}
      /> */}

      <LimitationModal
        isOpen={isLimitationModalOpen}
        toggle={handleLimitationModalOpen}
        imageSrc="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/temp/limitation-temp.svg"
        title="Limite de automações atingido"
        description="Escolha um plano que te permita adicionar mais automações para que consiga usufruir o melhor da ferramenta."
        trackingPropertyName="MAX_WORKFLOWS_REACHED"
      />

      <TestSideModal isOpen={isTestSideModalOpen} toggle={handleTestSideModalOpen} />
    </S.Wrapper>
  );
};

export default AutomationsManager;
