import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;
