import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { EAutomationsTemplate } from '@domain/enums/common/automation/ETemplate';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { IParams } from '@domain/interfaces/IParams';
import { ITemplateSummaryModal } from '@domain/interfaces/dashboard/Automations/ITemplateSummaryModal';

import { useTemplate } from '@helpers/hooks/pages/dashboard/automations/useTemplate';

import BadgeTags from '../Template/BadgeTags';
import SupportedChannels from '../Template/SupportedChannels';

import * as S from './styles';

const TemplateSummaryModal: React.FC<ITemplateSummaryModal> = ({ isOpen, toggle }) => {
  const history = useHistory();

  const { storeAliasId } = useParams<IParams>();

  const { selectedTemplate } = useTemplate();

  const onChoose = React.useCallback(() => {
    const queryString = selectedTemplate.id;

    if (selectedTemplate.id === EAutomationsTemplate.NO_CONTENT) {
      history.push(`/${storeAliasId}/dashboard/marketing/automations/canvas/new`);
    } else {
      history.push(
        `/${storeAliasId}/dashboard/marketing/automations/canvas/new?template=${queryString}`,
      );
    }
  }, [history, selectedTemplate, storeAliasId]);

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Header>
        <S.Image
          src={selectedTemplate.image_url}
          alt={`profitfy-automations-${selectedTemplate.alias}-image`}
        />
      </S.Header>

      <S.Body>
        <S.TemplateName weight={ETextWeight.SEMIBOLD} size={ETextSize.B3}>
          {selectedTemplate.name}
        </S.TemplateName>

        <S.InfoWrapper>
          <S.InfoTitle weight={ETextWeight.BOLD}>Sobre o template</S.InfoTitle>
          <S.TemplateDescrition>{selectedTemplate.description}</S.TemplateDescrition>
        </S.InfoWrapper>

        <S.InfoWrapper>
          <S.InfoTitle weight={ETextWeight.BOLD}>Tags</S.InfoTitle>
          <BadgeTags tags={selectedTemplate.tags} />
        </S.InfoWrapper>

        <S.InfoWrapper>
          <S.InfoTitle weight={ETextWeight.BOLD}>Canais suportados</S.InfoTitle>
          <SupportedChannels channels={selectedTemplate.supported_channels} isHorizontal />
        </S.InfoWrapper>

        <S.ChooseTemplateButton onClick={onChoose}>Escolher template</S.ChooseTemplateButton>
      </S.Body>
    </S.Modal>
  );
};

export default TemplateSummaryModal;
