import React from 'react';

import { useBoleto } from '@helpers/hooks/pages/dashboard/boleto/useBoleto';
import { numberFormatter } from '@helpers/masks';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { EPreDefinedDates } from '@domain/enums/components/EDatePicker';
import { EStatus } from '@domain/enums/subscription/ESubscription';

import * as S from './styles';

const Header: React.FC = () => {
  const { metric, setPeriod, period } = useBoleto();
  const { subscription, hasAllPermissions } = useStoreSubscription();

  const getDefaultPreDefinedDate = React.useCallback(() => {
    const isFreePlan = subscription && subscription.plan?.identifier === 'free_monthly_v1';

    if (hasAllPermissions) {
      return EPreDefinedDates.TODAY;
    }

    if (isFreePlan && subscription.exceeded_order_limit_at) {
      return EPreDefinedDates.CUSTOM;
    }

    if (subscription?.status === EStatus.PAID) {
      return EPreDefinedDates.TODAY;
    }

    return EPreDefinedDates.CUSTOM;
  }, [subscription, hasAllPermissions]);

  return (
    <S.Wrapper>
      <S.Title>Boletos</S.Title>
      <S.ResumeWrapper>
        <S.ItemWrapper>
          <S.ItemValue>{`R$${numberFormatter(
            metric?.total_net_revenue_amount || 0,
            2,
          )}`}</S.ItemValue>
          <S.ItemTitle>Receita líquida</S.ItemTitle>
        </S.ItemWrapper>

        <S.ItemWrapper>
          <S.ItemValue>{metric?.total_orders_quantity || 0}</S.ItemValue>
          <S.ItemTitle>Total de boletos </S.ItemTitle>
        </S.ItemWrapper>

        <S.ItemWrapper>
          <S.ItemValue>{`${numberFormatter(
            metric?.average_time_to_approve_in_days || 0,
            0,
            true,
          )} dia(s)`}</S.ItemValue>
          <S.ItemTitle>Tempo médio para aprovação</S.ItemTitle>
        </S.ItemWrapper>

        <S.DatePicker
          period={period}
          setPeriod={setPeriod}
          defaultPreDefinedDate={getDefaultPreDefinedDate()}
        />
      </S.ResumeWrapper>
    </S.Wrapper>
  );
};

export default Header;
