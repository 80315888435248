import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import ButtonBase from '@components/common/core/Inputs/Button';

export const NewCustomValueButton = styled(ButtonBase)`
  & > span {
    font-size: 0.875rem;
  }
`;

export const Title = styled(HeadingBase)`
  color: #ffffff;
`;

export const Content = styled.div`
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;

export const ChartAndButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media only screen and (max-width: 1360px) {
    width: 100%;
    justify-content: space-around;
  }
`;

export const ChartWrapper = styled.div`
  position: relative;
`;

export const PieChart = styled.div`
  width: 94px;
  height: 94px;
  border-radius: 50%;
  background-color: transparent;
  border: 8px solid ${({ theme }) => theme.colors.gray[4]};
`;

export const TextAndValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.875rem;
  font-weight: 300;
`;

export const ChartText = styled(TextBase)`
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const ChartValue = styled(TextBase)`
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.white.light};
`;
