import React from 'react';
import { Pen, Cancel } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';

import { ITableRowProps } from '@domain/interfaces/dashboard/Kit/variantKit';
import { numberFormatter, currency } from '@helpers/masks';
import { EBadgeColors } from '@domain/enums/components/EBadge';
import DeleteModal from '@components/common/core/Utils/DeleteModal';
import { useKit } from '@helpers/hooks/pages/dashboard/kit/useKit';
import { IParams } from '@domain/interfaces/IParams';

import * as S from './styles';

const TableRow: React.FC<ITableRowProps> = ({ variantKit }) => {
  const theme = useTheme();
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { deleteKit, isDeletingKit } = useKit();

  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);

  const handleDeleteModalOpen = React.useCallback(() => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  }, [isDeleteModalOpen]);

  const handleEditKit = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/data/kits/${variantKit.alias_id}/edit`);
  }, [history, storeAliasId, variantKit]);

  const handleIsHovering = React.useCallback(status => setIsHovering(status), []);

  const hasMultipleVariants = variantKit.variant_kit_items.length > 1;
  const firstVariant = variantKit.variant_kit_items[0];

  return (
    <S.KitTable.Row
      borderBottom
      onMouseEnter={() => handleIsHovering(true)}
      onMouseLeave={() => handleIsHovering(false)}
    >
      <S.KitTable.Data>
        <S.Tooltip>
          <S.Tooltip.Trigger>
            <S.KitName>{variantKit.name}</S.KitName>
          </S.Tooltip.Trigger>

          <S.Tooltip.Content side="top" sideOffset={5}>
            <S.Text>{variantKit.name}</S.Text>
            <S.Tooltip.Arrow />
          </S.Tooltip.Content>
        </S.Tooltip>
      </S.KitTable.Data>

      <S.KitTable.Data>
        <S.QuantityText>{variantKit.variant_kit_items.length}</S.QuantityText>
      </S.KitTable.Data>

      <S.KitTable.Data>
        <S.ItensWrapper>
          <S.FirstVariantName>{firstVariant?.variant?.name}</S.FirstVariantName>

          {hasMultipleVariants ? (
            <S.Popover>
              <S.Popover.Trigger>
                <S.SeeMoreItens>Ver mais</S.SeeMoreItens>
              </S.Popover.Trigger>

              <S.Popover.Content side="bottom" sideOffset={5}>
                <S.SeeMoreVariantsWrapper>
                  {variantKit.variant_kit_items.map(variantKitItem => (
                    <S.Text
                      key={variantKitItem.alias_id}
                    >{`${variantKitItem.quantity}x ${variantKitItem.variant.name}`}</S.Text>
                  ))}
                </S.SeeMoreVariantsWrapper>
                <S.Popover.Arrow />
              </S.Popover.Content>
            </S.Popover>
          ) : (
            <></>
          )}
        </S.ItensWrapper>
      </S.KitTable.Data>

      <S.KitTable.Data>
        <S.RevenueValue>{`${currency(variantKit.currency)} ${numberFormatter(
          variantKit.cost,
          2,
        )}`}</S.RevenueValue>
      </S.KitTable.Data>

      <S.KitTable.Data>
        <S.Badge secondary color={variantKit.is_active ? EBadgeColors.GREEN : EBadgeColors.RED}>
          {variantKit.is_active ? 'Ativo' : 'Inativo'}
        </S.Badge>
      </S.KitTable.Data>

      <S.KitTable.Data>
        <>
          <S.Popover>
            <S.Popover.Trigger>
              <S.ActionsButton isHovering={isHovering}>
                <S.Dot />
                <S.Dot />
                <S.Dot />

                <S.AccessibleActionsButtonName>Ações</S.AccessibleActionsButtonName>
              </S.ActionsButton>
            </S.Popover.Trigger>

            <S.Popover.Content sideOffset={5}>
              <S.ActionButton onClick={handleEditKit} name="edit">
                <Pen size={16} color={theme.colors.font} />
                Editar
              </S.ActionButton>
              <S.ActionButton onClick={handleDeleteModalOpen} isDeleteButton name="delete">
                <Cancel size={16} color={theme.colors.danger.default} outline />
                Deletar
              </S.ActionButton>
              <S.Popover.Arrow />
            </S.Popover.Content>
          </S.Popover>

          <DeleteModal
            content="Deseja realmente excluir esse kit? Essa ação é irreversível."
            title="Excluir kit"
            onDelete={() => deleteKit(variantKit.alias_id)}
            toggle={handleDeleteModalOpen}
            isOpen={isDeleteModalOpen}
            isLoading={isDeletingKit}
          />
        </>
      </S.KitTable.Data>
    </S.KitTable.Row>
  );
};

export default TableRow;
