import * as yup from 'yup';

export const taboolaSchema = yup.object().shape({
  client_id: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(10, 'Informe uma credencial válida'),
  client_secret: yup
    .string()
    .required('Este campo é obrigatório')
    .min(10, 'Informe uma credencial válida'),
});
