import React from 'react';

import { ECalculateMode } from '@domain/enums/dashboard/tax/ETax';
import { EHeadAlign } from '@domain/enums/components/ETable';

import { useTax } from '@helpers/hooks/pages/dashboard/tax/useTax';

import Paginate from '@components/common/core/Utils/Paginate';
import EmptyData from '@components/common/core/DataDisplay/EmptyData';
import SkeletonLoading from './SkeletonLoading';
import Row from './Row';

import * as S from './styles';

const HistoricTable: React.FC = () => {
  const {
    isLoadingTax,
    isValidatingTax,
    historicTaxes,
    historicTaxPage,
    historicTaxPageCount,
    onHistoricTaxesPageChange,
    defaultTax,
  } = useTax();

  const isLoading = isLoadingTax || isValidatingTax;

  const isCalculateModeWithAds = defaultTax?.calculate_mode === ECalculateMode.WITH_ADS;

  const calculateModeWithAdsHistoricTaxes = historicTaxes?.filter(tax => tax.is_default);

  const filteredHistoricTaxes = isCalculateModeWithAds
    ? calculateModeWithAdsHistoricTaxes
    : historicTaxes;

  const page = isCalculateModeWithAds ? 0 : historicTaxPage;
  const pageCount = isCalculateModeWithAds ? 1 : historicTaxPageCount;

  return (
    <S.Table>
      <S.Table.Header>
        <S.Table.Row>
          <S.Table.Head>Data Inicial</S.Table.Head>
          <S.Table.Head>Data Final</S.Table.Head>
          <S.Table.Head>Regra de Tributação</S.Table.Head>
          <S.Table.Head align={EHeadAlign.CENTER}>Regime de Tributação</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Alíquota</S.Table.Head>
          <S.Table.Head />
          <S.Table.Head />
        </S.Table.Row>
      </S.Table.Header>

      <S.Table.Body>
        {isLoading ? (
          <>
            <SkeletonLoading borderBottom />
            <SkeletonLoading borderBottom />
            <SkeletonLoading borderBottom />
            <SkeletonLoading borderBottom />
            <SkeletonLoading />
          </>
        ) : (
          <>
            {filteredHistoricTaxes?.length ? (
              <>
                {filteredHistoricTaxes.map(tax => (
                  <Row tax={tax} key={tax.alias_id} />
                ))}
              </>
            ) : (
              <S.Table.Row>
                <S.Table.Data colSpan={7} align={EHeadAlign.CENTER}>
                  <EmptyData content="Não encontramos nenhum pedido." />
                </S.Table.Data>
              </S.Table.Row>
            )}
          </>
        )}
      </S.Table.Body>

      {!isLoading && filteredHistoricTaxes?.length ? (
        <S.Table.Footer>
          <S.Table.Row>
            <S.Table.Data colSpan={7} align={EHeadAlign.RIGHT}>
              <Paginate
                forcePage={page}
                pageCount={pageCount}
                onPageChange={onHistoricTaxesPageChange}
              />
            </S.Table.Data>
          </S.Table.Row>
        </S.Table.Footer>
      ) : (
        <></>
      )}
    </S.Table>
  );
};

export default HistoricTable;
