import React from 'react';
import { Box, IIconProps, MegaPhone } from '@profitfy/pakkun-icons';

import { ESynchronizationStatus } from '@domain/enums/hooks/ESynchronization';
import {
  ISynchronizationsWithName,
  IGroupedSynchronization,
  ISynchronization,
} from '@domain/interfaces/hooks/IStoreSynchronization';

import { ADS_PROVIDERS, ERPS, STORE_PROVIDERS } from '@constants/common/integrations';

const groupSynchronizations = (
  synchronizations: Array<ISynchronization>,
): IGroupedSynchronization => {
  const groupedSynchronizations = synchronizations.reduce((previousValue, currentValue) => {
    const key = currentValue.name;

    if (!previousValue[key]) {
      previousValue[key] = [];
    }

    previousValue[key].push(currentValue);

    return previousValue;
  }, {});

  return groupedSynchronizations;
};

const getSynchronizationOrder = (synchronizationStatus: ESynchronizationStatus): number => {
  if (synchronizationStatus === ESynchronizationStatus.PROCESSING) return 1;

  if (synchronizationStatus === ESynchronizationStatus.IN_QUEUE) return 2;

  if (synchronizationStatus === ESynchronizationStatus.COMPLETED) return 3;

  return 4;
};

const sortSynchronizations = (
  synchronizations: Array<ISynchronization>,
): Array<ISynchronization> => {
  const synchronizationsToSort = [...synchronizations];

  synchronizationsToSort.sort(
    (currentSynchronization, nextSynchronization) =>
      getSynchronizationOrder(currentSynchronization.status) -
      getSynchronizationOrder(nextSynchronization.status),
  );

  return synchronizationsToSort;
};

const sortGroupedSynchronizations = (
  groupedSynchronizations: IGroupedSynchronization,
): IGroupedSynchronization => {
  const sortedGroupedSynchronizations = {};

  Object.entries(groupedSynchronizations).forEach(([key, synchronizations]) => {
    const sortedSynchronizations = sortSynchronizations(synchronizations);

    sortedGroupedSynchronizations[key] = sortedSynchronizations;
  });

  return sortedGroupedSynchronizations;
};

const synchronizationsObjectToArray = (
  sortedAndGroupedSynchronziations: IGroupedSynchronization,
): Array<ISynchronizationsWithName> => {
  const convertedObjectToArray = Object.entries(sortedAndGroupedSynchronziations).map(
    ([key, synchronizations]) => {
      return {
        name: key,
        synchronizations,
      };
    },
  );

  return convertedObjectToArray;
};

const sortConvertedSynchronizationsToArray = (
  convertedSynchronizations: Array<ISynchronizationsWithName>,
): Array<ISynchronizationsWithName> => {
  const synchronizationsToSort = [...convertedSynchronizations];

  synchronizationsToSort.sort(
    (currentSynchronization, nextSynchronization) =>
      getSynchronizationOrder(currentSynchronization.synchronizations[0].status) -
      getSynchronizationOrder(nextSynchronization.synchronizations[0].status),
  );

  return synchronizationsToSort;
};

export const groupAndSortSynchronizations = (
  synchronizations: Array<ISynchronization>,
): Array<ISynchronizationsWithName> => {
  const groupedSynchronizations = groupSynchronizations(synchronizations);

  const sortedAndGroupedSynchronizations = sortGroupedSynchronizations(groupedSynchronizations);

  const convertedtObjectToArray = synchronizationsObjectToArray(sortedAndGroupedSynchronizations);

  const sortedConvertedObjectToArray = sortConvertedSynchronizationsToArray(
    convertedtObjectToArray,
  );

  return sortedConvertedObjectToArray;
};

export const getSynchronizationNameIcon = (name: string): React.FC<IIconProps> => {
  const foundMarketing = ADS_PROVIDERS.find(integration => integration.name === name);

  if (foundMarketing) return foundMarketing.icon;

  const foundStoreFront = STORE_PROVIDERS.find(integration => integration.name === name);

  if (foundStoreFront) return foundStoreFront.icon;

  const foundErp = ERPS.find(integration => integration.name === name);

  if (foundErp) return foundErp.icon;

  if (name === 'Gerenciador de Anúncios') return MegaPhone;

  if (name === 'COGS') return Box;

  return Box;
};
