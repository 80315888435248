import { EConfigOption } from '@domain/enums/dashboard/automations/EMessage';
import { CreditCard } from 'phosphor-react';

export const REFUSED_CREDIT_CARD_FREE = [
  {
    id: 'refused-credit-card-free-first-condition',
    title: 'Cartão Recusado',
    type: 'REJECTED_PAYMENT',
    category: 'WHATSAPP',
    description: 'Lorem ipsum dolor sit amet consectetur. Elementum nunc tincidunt egestas purus.',
    icon: CreditCard,
    message_contents: [
      {
        id: Math.random(),
        type: EConfigOption.TEXT,
        content:
          'Olá {{first_name}}! 😀\n\nParece que tivemos um pequeno problema ao processar seu pagamento. 😥\n\nPara garantir sua compra, clique no link abaixo e escolha outra forma de pagamento: 🛒\n\n{{checkout_link}}\n\nSe precisar de ajuda, estou por aqui, é só chamar 🤩',
      },
    ],
  },
];
