import React from 'react';
import { Plus, Check } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { useInfoProduct } from '@helpers/hooks/pages/onboarding/useInfoProduct';

import { INFO_PRODUCT_PROVIDERS } from '@constants/common/integrations';

import SideModal from './SideModal';

import * as S from './styles';

const Platforms: React.FC = () => {
  const theme = useTheme();

  const { selectedPlatform } = useInfoProduct();

  const [isSideModalOpen, setIsSideModalOpen] = React.useState<boolean>(false);

  const handleSideModalOpen = React.useCallback(() => setIsSideModalOpen(!isSideModalOpen), [
    isSideModalOpen,
  ]);

  const getPlatformIcon = React.useCallback(platform => {
    const foundPlatformProvider = INFO_PRODUCT_PROVIDERS.find(
      integration => integration.identifier === platform,
    );

    if (foundPlatformProvider) {
      const Icon = foundPlatformProvider.icon;

      return <Icon size={20} />;
    }

    return <></>;
  }, []);

  const getPlatformName = React.useCallback(platform => {
    const foundPlatformProvider = INFO_PRODUCT_PROVIDERS.find(
      integration => integration.identifier === platform,
    );

    if (foundPlatformProvider) return foundPlatformProvider.name;

    return '';
  }, []);

  return (
    <>
      <S.Wrapper>
        <S.CardWrapper onClick={handleSideModalOpen}>
          <S.Header>
            <S.IconWrapper>
              <Plus color={theme.colors.gray[1]} size={9} />
            </S.IconWrapper>

            <S.DescriptionText>
              Selecionar plataforma de <strong>infoproduto</strong>
            </S.DescriptionText>
          </S.Header>

          {selectedPlatform && (
            <>
              <S.HorizontalRule />

              <S.IntegrationWrapper>
                <S.IntegrationName>
                  {getPlatformIcon(selectedPlatform)}

                  <S.Text>{getPlatformName(selectedPlatform)}</S.Text>
                </S.IntegrationName>

                <Check size={20} outline />
              </S.IntegrationWrapper>
            </>
          )}
        </S.CardWrapper>

        <S.ExampleText>Ex: Evermart, Kiwify, etc.</S.ExampleText>
      </S.Wrapper>

      <SideModal isOpen={isSideModalOpen} toggle={handleSideModalOpen} />
    </>
  );
};

export default Platforms;
