import styled, { css } from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import TableBase from '@components/common/core/Utils/Table';

interface IPriceWrapper {
  value: number;
}

export const Table = styled(TableBase)``;

export const Text = styled(TextBase)<IPriceWrapper>`
  color: #00f2a1;
  font-size: 0.875rem;

  ${({ value }) =>
    value < 0 &&
    css`
      color: #d0425c;
    `};
`;

export const DataText = styled(TextBase)`
  font-size: 0.875rem;
`;

export const PriceWrapper = styled.div<IPriceWrapper>`
  background-color: #183231;
  border-color: #00f2a1;
  border: 1px solid #00f2a1;
  border-radius: 4px;
  padding: 4px 16px 4px 16px;
  height: 22px;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: auto;
  box-sizing: border-box;

  ${({ value }) =>
    value < 0 &&
    css`
      background-color: #3b303c;
      border: 1px solid #d0425c;
    `};
`;

export const ProfitBadgeWrapper = styled.div`
  min-width: 120px;
`;
