import { AxiosResponse } from 'axios';

import {
  IActiveSubscriptionProps,
  IActiveSubscriptionResponse,
  ICancelSubscriptionProps,
  ICancelSubscriptionResponse,
  ICreateMonthlySubscriptionProps,
  ICreateMonthlySubscriptionResponse,
  ICreateRecurrenceSubscriptionProps,
  ICreateRecurrenceSubscriptionResponse,
  ICreateTrialProps,
  ICreateTrialResponse,
  IGetSubscriptionPromiseResponse,
  IGetSubscriptionProps,
  IGetSubscriptionResponse,
  IUpdateCancellationReasonProps,
  IUpdateCancellationReasonResponse,
  IUpdateMonthlySubscriptionProps,
  IUpdateMonthlySubscriptionResponse,
  IUpdateRecurrenceSubscriptionProps,
  IUpdateRecurrenceSubscriptionResponse,
} from '@domain/interfaces/common/subscription/ISubscription';

import { ApiService } from '@services/api';

import { useFetch } from '@providers/Fetch/useFetch';

import { USER_API_DOMAIN } from '@constants/api';

export class SubscriptionService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getSubscription({ storeAliasId }: IGetSubscriptionProps): IGetSubscriptionResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/subscriptions`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      subscription: data?.subscription,
      error,
      isLoading,
      isValidating,
      mutate,
    };
  }

  public getSubscriptionPromise({
    storeAliasId,
  }: IGetSubscriptionProps): Promise<AxiosResponse<IGetSubscriptionPromiseResponse>> {
    return this.apiService.get<IGetSubscriptionPromiseResponse>(
      `/api/v1/users/stores/${storeAliasId}/subscriptions`,
    );
  }

  public createMonthlySubscription({
    storeAliasId,
    planAliasId,
    couponId,
  }: ICreateMonthlySubscriptionProps): Promise<AxiosResponse<ICreateMonthlySubscriptionResponse>> {
    return this.apiService.post<ICreateMonthlySubscriptionResponse>(
      `/api/v1/users/stores/${storeAliasId}/plans/${planAliasId}/subscriptions`,
      {
        coupon_id: couponId,
      },
    );
  }

  public createRecurrenceSubscription({
    storeAliasId,
    planAliasId,
    couponId,
    data,
  }: ICreateRecurrenceSubscriptionProps): Promise<
    AxiosResponse<ICreateRecurrenceSubscriptionResponse>
  > {
    return this.apiService.post<ICreateRecurrenceSubscriptionResponse>(
      `/api/v1/users/stores/${storeAliasId}/plans/${planAliasId}/subscriptions/non-monthly`,
      { ...data, coupon_id: couponId },
    );
  }

  public activeSubscription({
    storeAliasId,
    subscriptionAliasId,
  }: IActiveSubscriptionProps): Promise<AxiosResponse<IActiveSubscriptionResponse>> {
    return this.apiService.post<IActiveSubscriptionResponse>(
      `/api/v1/users/stores/${storeAliasId}/subscriptions/${subscriptionAliasId}/active`,
    );
  }

  public createTrial({
    storeAliasId,
  }: ICreateTrialProps): Promise<AxiosResponse<ICreateTrialResponse>> {
    return this.apiService.post<ICreateTrialResponse>(
      `/api/v1/users/stores/${storeAliasId}/subscriptions/trial`,
    );
  }

  public updateMonthlySubscription({
    storeAliasId,
    planAliasId,
    subscriptionAliasId,
    couponId,
  }: IUpdateMonthlySubscriptionProps): Promise<AxiosResponse<IUpdateMonthlySubscriptionResponse>> {
    return this.apiService.put<IUpdateMonthlySubscriptionResponse>(
      `/api/v1/users/stores/${storeAliasId}/plans/${planAliasId}/subscriptions/${subscriptionAliasId}/change-plan`,
      {
        coupon_id: couponId,
      },
    );
  }

  public updateRecurrenceSubscription({
    storeAliasId,
    planAliasId,
    subscriptionAliasId,
    couponId,
    data,
  }: IUpdateRecurrenceSubscriptionProps): Promise<
    AxiosResponse<IUpdateRecurrenceSubscriptionResponse>
  > {
    return this.apiService.put<IUpdateRecurrenceSubscriptionResponse>(
      `/api/v1/users/stores/${storeAliasId}/plans/${planAliasId}/subscriptions/${subscriptionAliasId}/change-plan/non-monthly`,
      { ...data, coupon_id: couponId },
    );
  }

  public cancelSubscription({
    storeAliasId,
    subscriptionAliasId,
    data,
  }: ICancelSubscriptionProps): Promise<AxiosResponse<ICancelSubscriptionResponse>> {
    return this.apiService.put<ICancelSubscriptionResponse>(
      `/api/v1/users/stores/${storeAliasId}/subscriptions/${subscriptionAliasId}/cancel`,
      { ...data },
    );
  }

  public updateCancellationReason({
    storeAliasId,
    subscriptionAliasId,
    data,
  }: IUpdateCancellationReasonProps): Promise<AxiosResponse<IUpdateCancellationReasonResponse>> {
    return this.apiService.put<IUpdateCancellationReasonResponse>(
      `/api/v1/users/stores/${storeAliasId}/subscriptions/${subscriptionAliasId}/cancellation-reason`,
      { ...data },
    );
  }
}

const subscriptionService = new SubscriptionService(USER_API_DOMAIN);

export default subscriptionService;
