import { v4 } from 'uuid';

import {
  EAbandonCartType,
  EAbandonCartProductOptions,
} from '@domain/enums/dashboard/automations/node/conditionalNode/EAbandonCart';

export const DEFAULT_ABANDONED_CART_CONTENT = {
  id: v4(),
  type: EAbandonCartType.PRODUCT_NAME,
  options: EAbandonCartProductOptions.CONTAINS,
  input: '',
};
