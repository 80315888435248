import React from 'react';

import { EHeadingSize } from '@domain/enums/components/EHeading';

import SuccessImg from '@assets/pages/FacebookLogin/success.svg';

import * as S from './styles';

const SuccessLoginContent: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Heading type={EHeadingSize.H2}>Conta conectada com sucesso!</S.Heading>
      <S.Description>
        Volte para a aba anterior, e clique no botão de <strong>Atualizar minhas contas.</strong>
      </S.Description>

      <S.Image src={SuccessImg} alt="profitfy-goat-happy" />
    </S.Wrapper>
  );
};

export default SuccessLoginContent;
