import React from 'react';
import { Search } from '@profitfy/pakkun-icons';

import { useStoreIntegrations } from '@helpers/hooks/pages/dashboard/integrationCenter/useStoreIntegrations';
import { useQuery } from '@helpers/hooks/useQuery';

import Tab from '@components/common/core/Navigation/Tab';

import * as S from './styles';

const Header: React.FC = () => {
  const query = useQuery();
  const { handleSearchIntegrationsValue, handleSelectedTab } = useStoreIntegrations();

  const marketingRef = query.get('marketing');

  React.useEffect(() => {
    if (marketingRef) {
      handleSelectedTab(4 as any);
    }
  }, [marketingRef, handleSelectedTab]);

  return (
    <S.HeaderWrapper>
      <Tab defaultTab={marketingRef ? 4 : 0} onChangeTab={handleSelectedTab}>
        <Tab.Option>Geral</Tab.Option>
        <Tab.Option>Loja Virtual</Tab.Option>
        <Tab.Option>ERP</Tab.Option>
        <Tab.Option>Gateway de Pagamento</Tab.Option>
        <Tab.Option>Marketing</Tab.Option>
      </Tab>

      <S.Input
        name="search-integration"
        type="text"
        placeholder="Procurar integrações"
        prepend={Search}
        onChange={handleSearchIntegrationsValue}
      />
    </S.HeaderWrapper>
  );
};

export default Header;
