import styled, { css } from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import BadgeBase from '@components/common/core/DataDisplay/Badge';
import ImageBase from '@components/common/core/DataDisplay/Image';
import PopoverBase from '@components/common/core/Utils/Popover';
import TooltipBase from '@components/common/core/Utils/Tooltip';
import { IMoreOptionsWrapperProps } from '@domain/interfaces/dashboard/ProductCostDetails/IRow';
import TableBase from '@components/common/core/Utils/Table';

export const Table = styled(TableBase)`
  & > table {
    & > thead {
      & > tr {
        & > th {
          padding: 10px 8px;
        }

        & > th:first-child {
          padding-left: 16px;
        }

        & > th:last-child {
          padding-right: 24px;
        }

        & > th:nth-child(9) {
          padding-left: 5px !important;
          padding-right: 5px !important;
          width: 110px !important;
        }
      }
    }

    & > tbody {
      & > tr {
        & > td {
          padding-left: 6px;
          padding-right: 6px;
        }

        & > td:first-child {
          padding-left: 16px;
          padding-right: 6px;
        }

        & > td:last-child {
          padding-left: 16px;
        }

        & > :nth-child(2) {
          padding-left: 5px !important;
          padding-right: 5px !important;
        }

        & > :nth-child(9) {
          width: 110px !important;
        }
      }
    }
  }
`;

export const Tooltip = styled(TooltipBase)``;

export const SynchronizationIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Popover = styled(PopoverBase)`
  background-color: ${({ theme }) => theme.colors.background[1]} !important;
  width: fit-content !important;

  &.place-right::after {
    border-right-color: ${({ theme }) => theme.colors.background[1]} !important;
  }

  &.place-left::after {
    border-left-color: ${({ theme }) => theme.colors.background[1]} !important;
  }

  &.place-top::after {
    border-top-color: ${({ theme }) => theme.colors.background[1]} !important;
  }

  &.place-bottom::after {
    border-bottom-color: ${({ theme }) => theme.colors.background[1]} !important;
  }
`;

export const ValueText = styled(TextBase)`
  font-weight: 700;
`;

export const TypeText = styled(TextBase)``;

export const ShippingText = styled(TextBase)`
  font-weight: 700;
`;

export const ProductCostText = styled(TextBase)`
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
`;

export const StatusBadge = styled(BadgeBase)``;

export const RevenueTypeText = styled(TextBase)``;

export const RevenueType = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const RevenueValue = styled(TextBase)`
  font-weight: 700;
`;

export const RevenueWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SeeMoreProducts = styled(TextBase)`
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.background[6]};
  cursor: pointer;
`;

export const TooltipProductText = styled(TextBase)`
  max-width: 300px;
  width: 100%;
  overflow: hidden;
  display: block;
`;

export const ProductText = styled(TextBase)`
  max-width: 195px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`;

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8;
`;

export const OrderDate = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
  font-size: 14px;
  margin-top: 2px;
`;

export const OrderNumber = styled(TextBase)`
  color: ${({ theme }) => theme.colors.green.dark};
  display: block;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.green.default};
  }
`;

export const OrderTextWrapper = styled.div``;

export const ImageIcon = styled(ImageBase)`
  width: 20px;
`;

export const IconWrapper = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  background-color: ${({ theme }) => theme.colors.background[3]};
`;

export const OrderDetails = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
`;

export const ActionsButton = styled.button<IMoreOptionsWrapperProps>`
  width: 26px;
  height: 26px;
  align-items: center;
  justify-content: center;
  gap: 2px;
  opacity: 0;
  display: flex;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  border-radius: 50%;
  transition: filter 0.3s;
  border: unset;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  ${({ isHovering }) =>
    isHovering &&
    css`
      opacity: 1;
    `}
`;

export const PaginateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;
