import React from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useToast } from '@helpers/hooks/useToast';
import variantService from '@services/pages/dashboard/kit/variant';
import { IParams } from '@domain/interfaces/IParams';
import { IVariant, IVariantProvider, IKitVariant } from '@domain/interfaces/dashboard/Kit/variant';
import { INewKitVariant } from '@domain/interfaces/dashboard/Kit/newKit';
import { useNewKit } from './useNewKit';

const VariantContext = React.createContext<IVariantProvider | null>(null);

export const VariantProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { newKitVariants, handleNewKitVariants, isCreatingKit } = useNewKit();

  const [selectedVariant, setSelectedVariant] = React.useState<IKitVariant | undefined>(undefined);
  const [initialVariant, setInitialVariant] = React.useState<IKitVariant | undefined>(undefined);
  const [isVariantsComponentOpen, setIsVariantsComponentOpen] = React.useState<boolean>(false);
  const [isLoadingVariants, setIsLoadingVariants] = React.useState<boolean>(false);
  const [variantsPage, setVariantsPage] = React.useState<number>(0);
  const [variantsPageCount, setVariantsPageCount] = React.useState<number>(1);
  const [variants, setVariants] = React.useState<Array<IVariant>>([]);
  const [quantity, setQuantity] = React.useState<number>(1);
  const [isCardExpanded, setIsCardExpanded] = React.useState<boolean>(false);
  const [isHoveringInput, setIsHoveringInput] = React.useState<boolean>(false);
  const [isHoveringVariantsComponent, setIsHoveringVariantsComponent] = React.useState<boolean>(
    false,
  );
  const [index, setIndex] = React.useState<number>(0);
  const [searchName, setSearchName] = React.useState<string>('');

  const inputComponentRef = React.useRef<HTMLDivElement>(null);
  const focusedVariantCardRef = React.useRef<HTMLDivElement>(null);

  const handleCardExpanded = React.useCallback(() => {
    if (!isCreatingKit) {
      setIsCardExpanded(!isCardExpanded);
    }
  }, [isCardExpanded, isCreatingKit]);

  const onCardBlur = React.useCallback(() => {
    if (!selectedVariant && initialVariant) {
      const filteredNewVariants = newKitVariants.filter(
        newKitVariant => newKitVariant?.id !== initialVariant.id,
      );
      handleNewKitVariants(filteredNewVariants);
    }
    if (!selectedVariant && !initialVariant) {
      const newVariants = newKitVariants.slice(0, -1);
      handleNewKitVariants(newVariants);
    }
  }, [newKitVariants, initialVariant, handleNewKitVariants, selectedVariant]);

  const resetInfiniteScroll = React.useCallback(() => {
    setVariants([]);
    setVariantsPage(0);
  }, []);

  const loadVariants = React.useCallback(async () => {
    setIsLoadingVariants(true);

    try {
      const { data } = await variantService.listVariantsPromise({
        storeAliasId,
        page: variantsPage,
        filter: { name: searchName },
      });

      setVariants(currentVariants => [...currentVariants, ...data?.variants]);
      setVariantsPageCount(data?.total_pages);
      setIsLoadingVariants(false);
    } catch (error: any) {
      setIsLoadingVariants(false);
      toast.error(error?.response?.data?.message);
    }
  }, [storeAliasId, toast, variantsPage, searchName]);

  const handleLoadMore = React.useCallback(() => {
    setVariantsPage(oldPage => oldPage + 1);
  }, []);

  const handleSearchName = React.useCallback(
    debouncedSearchName => {
      resetInfiniteScroll();
      setSearchName(debouncedSearchName);
    },
    [resetInfiniteScroll, setSearchName],
  );

  const handleSelectVariant = React.useCallback(
    (variant: IVariant) => {
      const kitVariant = {
        variant,
        quantity,
        id: uuidv4(),
      };

      setSelectedVariant(kitVariant);
      setIsVariantsComponentOpen(false);
      resetInfiniteScroll();

      if (initialVariant) {
        const mappedNewVariants = newKitVariants.map(newKitVariant => {
          if (newKitVariant?.id === initialVariant.id) {
            const newVariant = {
              variant: kitVariant.variant,
              quantity,
              id: kitVariant.id,
            };

            return newVariant;
          }

          return newKitVariant;
        });

        setInitialVariant(kitVariant);
        handleNewKitVariants(mappedNewVariants);
      }

      if (!initialVariant) {
        const newVariants = newKitVariants.slice(0, -1);
        const newVariant = {
          variant: kitVariant.variant,
          quantity,
          id: kitVariant.id,
        };

        setInitialVariant(kitVariant);
        handleNewKitVariants([...newVariants, newVariant]);
      }

      focusedVariantCardRef?.current?.focus();
    },
    [resetInfiniteScroll, newKitVariants, handleNewKitVariants, initialVariant, quantity],
  );

  const onInputFocus = React.useCallback(() => {
    setIsVariantsComponentOpen(true);
  }, []);

  const closeVariantsComponent = React.useCallback(() => {
    resetInfiniteScroll();
    setIsVariantsComponentOpen(false);
    focusedVariantCardRef?.current?.focus();
  }, [resetInfiniteScroll]);

  const removeVariant = React.useCallback(() => {
    setSelectedVariant(undefined);
    focusedVariantCardRef?.current?.focus();
  }, []);

  const increaseQuantity = React.useCallback(() => {
    const mappedVariants = newKitVariants.map(newKitVariant => {
      if (newKitVariant?.id === initialVariant?.id) {
        return {
          ...newKitVariant,
          quantity: quantity + 1,
        };
      }

      return newKitVariant;
    });

    handleNewKitVariants(mappedVariants);
    setQuantity(previousQuantity => previousQuantity + 1);
  }, [newKitVariants, handleNewKitVariants, initialVariant, quantity]);

  const decreaseQuantity = React.useCallback(() => {
    const mappedVariants = newKitVariants.map(newKitVariant => {
      if (newKitVariant?.id === initialVariant?.id && quantity > 1) {
        return {
          ...newKitVariant,
          quantity: quantity - 1,
        };
      }

      return newKitVariant;
    });

    handleNewKitVariants(mappedVariants);

    setQuantity(previousQuantity => {
      if (previousQuantity === 1) return previousQuantity;

      return previousQuantity - 1;
    });
  }, [handleNewKitVariants, newKitVariants, quantity, initialVariant]);

  const handleExistingVariant = React.useCallback((kitVariant: INewKitVariant) => {
    setQuantity(kitVariant.quantity);
    setSelectedVariant({ variant: kitVariant.variant, id: kitVariant.id });
    setInitialVariant({ variant: kitVariant.variant, id: kitVariant.id });
  }, []);

  const handleIsHoveringInput = React.useCallback(status => setIsHoveringInput(status), []);

  const handleIsHoveringVariantsComponent = React.useCallback(
    status => setIsHoveringVariantsComponent(status),
    [],
  );

  const handleIndex = React.useCallback(newIndex => setIndex(newIndex), []);

  const hasMore = variantsPage + 1 < variantsPageCount;

  React.useEffect(() => {
    if (!initialVariant) setIsCardExpanded(true);
  }, [initialVariant]);

  return (
    <VariantContext.Provider
      value={{
        isLoadingVariants,
        loadVariants,
        variants,
        variantsPage,
        variantsPageCount,
        setVariantsPage,
        handleLoadMore,
        hasMore,
        handleSelectVariant,
        selectedVariant,
        isVariantsComponentOpen,
        onInputFocus,
        removeVariant,
        decreaseQuantity,
        increaseQuantity,
        quantity,
        isCardExpanded,
        handleCardExpanded,
        inputComponentRef,
        focusedVariantCardRef,
        isHoveringInput,
        handleIsHoveringInput,
        closeVariantsComponent,
        handleIndex,
        handleExistingVariant,
        index,
        onCardBlur,
        initialVariant,
        handleSearchName,
        handleIsHoveringVariantsComponent,
        isHoveringVariantsComponent,
      }}
    >
      {children}
    </VariantContext.Provider>
  );
};

export const useVariant = (): IVariantProvider => {
  const context = React.useContext(VariantContext);

  if (!context) {
    throw new Error('useVariant must be used within VariantContext');
  }

  return context;
};
