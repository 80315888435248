import React from 'react';

import { Taboola as TaboolaIcon } from '@profitfy/pakkun-icons';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { EProgressBarStep } from '@domain/enums/onboarding/progressBar/ESteps';
import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { EButtonTextColor, EButtonVariant } from '@domain/enums/components/EButton';
import { IParams } from '@domain/interfaces/IParams';
import { IMarketingContentProps } from '@domain/interfaces/dashboard/IntegrationCenter/Marketing/IMarketingContent';

import { useParams } from 'react-router-dom';
import { useTaboolaCredentials } from '@helpers/hooks/common/integrations/marketing/taboola/useTaboolaCredentials';
import { useConfig } from '@helpers/hooks/useConfig';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';

import TaboolaImg from '@assets/pages/Onboarding/dropshipping/marketing/taboola.svg';

import SideBar from '@components/Onboarding/SideBar';
import NextStep from '@components/Onboarding/NextStep';
import IntegrateForm from '../IntegrateForm';

import { PageSkeletonLoading } from '../SkeletonLoading';

import * as S from './styles';

const Wrapper: React.FC<IMarketingContentProps> = ({ callback }) => {
  const { storeAliasId } = useParams<IParams>();
  const { analytics } = useConfig();
  const { isLoadingTrial } = useOnboarding();
  const {
    adSenseCredentials,
    isLoadingTaboolaCredentialsData,
    isValidatingTaboolaCredentialsData,
    isLoggingIn,
    isLoggingOut,
  } = useTaboolaCredentials();

  const [isIntegrateFormShow, setIsIntegrateFormShow] = React.useState<boolean>(false);

  const onAddNewProfileButtonClick = React.useCallback(() => {
    setIsIntegrateFormShow(true);

    analytics?.track(
      'Onboarding Profile Button Clicked',
      {
        provider: EAdSenseProvider.TABOOLA,
      },
      { context: { groupId: storeAliasId } },
    );
  }, [analytics, storeAliasId]);

  const handleNextStepButtonClick = React.useCallback(() => {
    callback();

    analytics?.track(
      'Onboarding Marketing Integration Done',
      {
        provider: EAdSenseProvider.TABOOLA,
      },
      { context: { groupId: storeAliasId } },
    );
  }, [analytics, storeAliasId, callback]);

  const isLoading =
    isLoadingTaboolaCredentialsData ||
    isValidatingTaboolaCredentialsData ||
    isLoggingIn ||
    isLoggingOut;

  if (isLoading) {
    return (
      <S.Container>
        <SideBar
          onboardingStep={EProgressBarStep.INTEGRATIONS}
          linkToGoBack={`/onboarding/${storeAliasId}/dropshipping/integrations`}
        />

        <PageSkeletonLoading />
      </S.Container>
    );
  }

  return (
    <S.Container>
      <SideBar
        onboardingStep={EProgressBarStep.INTEGRATIONS}
        linkToGoBack={`/onboarding/${storeAliasId}/dropshipping/integrations`}
      />

      {!isIntegrateFormShow && Boolean(!adSenseCredentials.length) && (
        <S.ContentWrapper>
          <S.Image src={TaboolaImg} alt="profitfy-goat-with-taboola-logo" />

          <S.Heading type={EHeadingSize.H2}>Conectar perfil do Taboola</S.Heading>

          <S.ButtonsWrapper>
            <S.AddNewProfileButton onClick={onAddNewProfileButtonClick}>
              <TaboolaIcon size={22} />
              Adicionar perfil do Taboola
            </S.AddNewProfileButton>

            <S.HorizontalRuleWrapper>
              <S.HorizontalRule />
              <S.HorizontalRuleText>Ou</S.HorizontalRuleText>
              <S.HorizontalRule />
            </S.HorizontalRuleWrapper>

            <S.SkipStepButton
              onClick={handleNextStepButtonClick}
              variant={EButtonVariant.SECONDARY_BUTTON}
            >
              Conectar depois
            </S.SkipStepButton>
          </S.ButtonsWrapper>
        </S.ContentWrapper>
      )}

      {Boolean(adSenseCredentials.length) && (
        <NextStep
          onClick={handleNextStepButtonClick}
          textColor={EButtonTextColor.DARK}
          isLoading={isLoadingTrial}
          disabled={isLoadingTrial}
        >
          Próximo Passo
        </NextStep>
      )}

      {(isIntegrateFormShow || Boolean(adSenseCredentials.length)) && (
        <IntegrateForm callback={callback} />
      )}
    </S.Container>
  );
};

export default Wrapper;
