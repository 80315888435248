import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const PendingOrders = styled(TextBase)`
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[1]};
  filter: blur(5px);
  user-select: none;
  font-size: 0.875rem;
  margin-right: 8px;
`;

export const ApprovedOrders = styled(TextBase)`
  line-height: 120%;
  color: ${({ theme }) => theme.colors.green.default};
  filter: blur(5px);
  user-select: none;
  font-size: 0.875rem;
  margin-right: 8px;
`;

export const OrdersLabel = styled(TextBase)`
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[2]};
  filter: blur(5px);
  user-select: none;
  font-size: 0.875rem;
  margin-right: 8px;
`;
