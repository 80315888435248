import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { USER_API_DOMAIN } from '@constants/api';
import {
  ICreatePersonalInfoProps,
  IGetPersonalInfoResponse,
  IUpdatePasswordProps,
  IUpdatePersonalInfoProps,
} from '@domain/interfaces/auth/IPersonalInfo';

export class PersonalInfoService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getPersonalInfo(): IGetPersonalInfoResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/personal-infos`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      personalInfo: data?.personal_info,
      isLoading,
      isValidating,
      error,
      mutate,
    };
  }

  public getPersonalInfoPromise(): Promise<any> {
    return this.apiService.get('/api/v1/users/personal-infos');
  }

  public createPersonalInfo({ data }: ICreatePersonalInfoProps): Promise<any> {
    return this.apiService.post('/api/v1/users/personal-infos', { ...data });
  }

  public updatePersonalInfo({ data }: IUpdatePersonalInfoProps): Promise<any> {
    return this.apiService.put('/api/v1/users/personal-infos', { ...data });
  }

  public deletePersonalInfo(): Promise<any> {
    return this.apiService.delete('/api/v1/users/personal-infos');
  }

  public updatePassword({ data }: IUpdatePasswordProps): Promise<any> {
    return this.apiService.put('/api/v1/users/me/password', { ...data });
  }
}

const personalInfoService = new PersonalInfoService(USER_API_DOMAIN);

export default personalInfoService;
