import React from 'react';

import { MagnifyingGlass } from 'phosphor-react';
import { useTheme } from 'styled-components';

import { EHeadingWeight } from '@domain/enums/components/EHeading';

import { useTemplate } from '@helpers/hooks/pages/dashboard/automations/useTemplate';

import * as S from './styles';

const Header: React.FC = () => {
  const theme = useTheme();

  const { searchValue, handleSearchValue } = useTemplate();

  const onSearchInputChange = React.useCallback(
    event => {
      handleSearchValue(event.target.value);
    },
    [handleSearchValue],
  );

  return (
    <S.Wrapper>
      <S.HeadingWrapper>
        <S.Heading fontWeight={EHeadingWeight.SEMIBOLD}>Todos os templates</S.Heading>
        <S.Description>Visualize todos os templates disponíveis para suas automações</S.Description>
      </S.HeadingWrapper>

      <S.Input
        type="text"
        value={searchValue}
        onChange={onSearchInputChange}
        prepend={() => <MagnifyingGlass size={20} color={theme.colors.gray[3]} weight="bold" />}
        placeholder="Pesquisar template"
        noFeedbackError
      />
    </S.Wrapper>
  );
};

export default Header;
