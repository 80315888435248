import styled, { css } from 'styled-components/macro';

import ButtonBase from '@components/common/core/Inputs/Button';
import TextBase from '@components/common/core/DataDisplay/Text';
import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';

import ChatBackgroundImg from '@assets/dashboard/automations/messages/chat-background.svg';

export const Popover = styled(CustomPopoverBase)``;

export const PopoverTrigger = styled(CustomPopoverBase.Trigger)`
  position: absolute;
  top: 16px;
  right: 12px;
`;

export const PopoverContent = styled(CustomPopoverBase.Content)`
  min-width: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PopoverOption = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: unset;
  padding: 8px 16px;
  border: unset;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[1]};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`;

export const PopoverArrow = styled(CustomPopoverBase.Arrow)``;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 232px;
  border-radius: 12px;
  background-image: url(${ChatBackgroundImg});
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 10px 10px 10px 16px;
  box-sizing: border-box;
  overflow: hidden;
`;

export const Content = styled.div<{ isFirstItemOfArray: boolean }>`
  position: relative;
  background: #fff;
  width: 100%;
  height: fit-content;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 6px;
  box-sizing: border-box;

  ${({ isFirstItemOfArray }) =>
    isFirstItemOfArray &&
    css`
      border-radius: 0px 6px 6px 6px;
    `}
`;

export const MessageText = styled(TextBase)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.black.light};
  text-align: left;
`;

export const AudioContent = styled.div`
  background-color: #fff;
  width: 100%;
  min-height: 52px;
  box-sizing: border-box;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  overflow: hidden;
`;

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  min-width: 20px;
`;

export const BulletAndWavesWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const CurrentTimeText = styled(TextBase)`
  position: absolute;
  left: 0;
  bottom: -23px;
  font-weight: 300;
  font-size: 0.6875rem;
  color: #8696a0;
`;

export const TotalTimeText = styled(TextBase)`
  position: absolute;
  right: 0;
  bottom: -23px;
  font-weight: 300;
  font-size: 0.6875rem;
  color: #8696a0;
`;

export const WavesWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1px;
`;

export const AudioBullet = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #4fc3f7;
`;

export const Wave = styled.div<{ height: number }>`
  width: 3px;
  height: ${({ height }) => height}px;
  background-color: #ced0d1;
`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[2]};
`;

export const ContentArrow = styled.div`
  width: 6px;
  height: 6px;
  position: absolute;
  top: 0;
  left: -5px;
  background-color: #fff;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
`;

export const BluredOverlayer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: transparent;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;

  &:hover {
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(3px);
  }
`;

export const UpdateMessageButton = styled(ButtonBase)``;

export const ChooseMessageButton = styled(ButtonBase)``;

export const OptionsWrapper = styled.button`
  background-color: ${({ theme }) => theme.colors.background[2]};
  border: unset;
  padding: unset;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Time = styled(TextBase)`
  position: absolute;
  right: 2px;
  bottom: 2px;
  font-size: 0.6875rem;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const ImageContent = styled.div`
  position: relative;
  background: #fff;
  width: 60%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 2px;
  box-sizing: border-box;
`;

export const Image = styled.img`
  border-radius: 6px;
  width: 100%;
`;

export const ProductImageContent = styled.div`
  position: relative;
  background: #fff;
  width: 60%;
  height: 200px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2px;
  box-sizing: border-box;
`;

export const ProductImageWrapper = styled.div`
  width: 99%;
  height: 200px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.gray[1]};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

export const ProductImageText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.black.default};
  font-weight: 700;
  font-size: 1.5rem;
  max-width: 179px;
  text-align: center;
  line-height: 1;
`;

export const VideoContent = styled.div`
  position: relative;
  background: #fff;
  width: 60%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 2px;
  box-sizing: border-box;
`;

export const Video = styled.video`
  border-radius: 6px;
  width: 100%;
  height: 100%;
`;

export const PDFContent = styled.div`
  position: relative;
  background: #fff;
  width: 80%;
  height: 360px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 2px;
  box-sizing: border-box;
`;
