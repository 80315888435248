import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresCardInAnalysisValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresCardInAnalysisValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresCardInAnalysisValuesResponse => {
  const currentSelectedStoreCardInAnalysisAmountValue =
    currentSelectedStoreFinancialDetailsReportData?.card?.in_analysis?.amount;

  const currentSelectedStoreCardInAnalysisQuantityValue =
    currentSelectedStoreFinancialDetailsReportData?.card?.in_analysis?.quantity;

  const storesCardInAnalysisAmountValues = selectedStoresData?.map(
    value => value.financial_details_report?.card?.in_analysis?.amount,
  );

  const storesCardInAnalysisQuantityValues = selectedStoresData?.map(
    value => value.financial_details_report?.card?.in_analysis?.quantity,
  );

  const cardInAnalysisAmountValues = [
    ...storesCardInAnalysisAmountValues,
    currentSelectedStoreCardInAnalysisAmountValue,
  ];

  const cardInAnalysisQuantityValues = [
    ...storesCardInAnalysisQuantityValues,
    currentSelectedStoreCardInAnalysisQuantityValue,
  ];

  const reducedCardInAnalysisAmountValues = cardInAnalysisAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedCardInAnalysisQuantityValues = cardInAnalysisQuantityValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  return {
    card_in_analysis_amount: reducedCardInAnalysisAmountValues,
    card_in_analysis_quantity: reducedCardInAnalysisQuantityValues,
  };
};
