/* eslint-disable no-underscore-dangle */
import React from 'react';
import { IMessage, useNovuContext, useNotifications } from '@novu/notification-center';

import { INotificationContext } from '@domain/interfaces/common/notification/INotification';

const NotificationContext = React.createContext<INotificationContext | null>(null);

export const NotificationProvider: React.FC = ({ children }) => {
  const { apiService: novuService } = useNovuContext();
  const { markNotificationAsRead, unseenCount } = useNotifications();

  const [notifications, setNotifications] = React.useState<Array<IMessage>>([]);
  const [feedPage, setFeedPage] = React.useState<number>(0);
  const [totalFeedPages, setTotalFeedPages] = React.useState<number>(0);
  const [isLoadingNotifications, setIsLoadingNotifications] = React.useState<boolean>(false);
  const [
    hasMarkedAllNotificationsAsRead,
    setHasMarkedAllNotificationsAsRead,
  ] = React.useState<boolean>(false);

  const resetNotificationsFeed = React.useCallback(() => {
    setFeedPage(0);
    setTotalFeedPages(0);
    setNotifications([]);
    setIsLoadingNotifications(false);
    setHasMarkedAllNotificationsAsRead(false);
  }, []);

  const markAllFeedNotificationsAsRead = React.useCallback(async () => {
    const unreadNotifications = notifications.filter(notification => !notification.read);
    const messageIds = unreadNotifications.map(notification => notification._id);

    await novuService.markMessageAs(messageIds, { seen: true, read: true });

    setHasMarkedAllNotificationsAsRead(true);
  }, [notifications, novuService]);

  const loadNotifications = React.useCallback(async () => {
    setIsLoadingNotifications(true);

    try {
      const { data, totalCount, pageSize } = await novuService.getNotificationsList(feedPage);

      const roundedTotalPages = Math.ceil(totalCount / pageSize);

      setNotifications(currentNotifications => [...currentNotifications, ...data]);
      setTotalFeedPages(roundedTotalPages);

      const unseenMessages = data?.filter(notification => !notification.seen);
      const messageIds = unseenMessages.map(notification => notification._id);

      await novuService.markMessageAs(messageIds, { seen: true });
    } finally {
      setIsLoadingNotifications(false);
    }
  }, [feedPage, novuService]);

  const updateFeedPage = React.useCallback(() => {
    setFeedPage(currentPage => currentPage + 1);
  }, []);

  const hasMoreFeedPage = feedPage < totalFeedPages;
  const hasUnseenNotifications = unseenCount > 0;

  return (
    <NotificationContext.Provider
      value={{
        hasMoreFeedPage,
        loadNotifications,
        notifications,
        resetNotificationsFeed,
        updateFeedPage,
        isLoadingNotifications,
        hasMarkedAllNotificationsAsRead,
        markAllNotificationsAsRead: markAllFeedNotificationsAsRead,
        markNotificationAsRead,
        hasUnseenNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = (): INotificationContext => {
  const context = React.useContext(NotificationContext);

  if (!context) {
    throw new Error('useNotification must be used within NotificationProvider');
  }

  return context;
};
