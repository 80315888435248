import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 401px;
  min-width: 401px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 24px 34px 24px;
  box-sizing: border-box;

  @media only screen and (max-width: 1435px) {
    justify-content: center;
  }
`;

export const Heading = styled(HeadingBase)`
  color: #ffffff;
  margin-bottom: 22px;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Description = styled(TextBase)`
  color: #ffffff;
`;

export const KnowFunctionalityButton = styled(ButtonBase)``;
