import styled from 'styled-components/macro';

import ButtonBase from '@components/common/core/Inputs/Button';
import TextFieldBase from '@components/common/core/Inputs/TextField';

export const NewIdeaAndSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 42px;
  flex-wrap: wrap;
  gap: 16px;
`;

export const NewIdeaButton = styled(ButtonBase)`
  box-shadow: none;

  span {
    display: flex;
    align-items: center;
    gap: 6px;
  }
`;

export const SearchInput = styled(TextFieldBase)`
  max-width: 324px;
  width: 100%;
`;
