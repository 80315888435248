import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresCardPaidValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresCardPaidValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresCardPaidValuesResponse => {
  const currentSelectedStoreCardPaidAmountValue =
    currentSelectedStoreFinancialDetailsReportData?.card?.paid?.amount;

  const currentSelectedStoreCardQuantityValue =
    currentSelectedStoreFinancialDetailsReportData?.card?.paid?.quantity;

  const storesCardPaidAmountValues = selectedStoresData?.map(
    value => value.financial_details_report?.card?.paid?.amount,
  );

  const storesCardQuantityValues = selectedStoresData?.map(
    value => value.financial_details_report?.card?.paid?.quantity,
  );

  const cardPaidAmountValues = [
    ...storesCardPaidAmountValues,
    currentSelectedStoreCardPaidAmountValue,
  ];

  const cardQuantityValues = [...storesCardQuantityValues, currentSelectedStoreCardQuantityValue];

  const reducedCardPaidAmountValues = cardPaidAmountValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const reducedCardQuantityValues = cardQuantityValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  return {
    card_paid_amount: reducedCardPaidAmountValues,
    card_paid_quantity: reducedCardQuantityValues,
  };
};
