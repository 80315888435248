import styled from 'styled-components/macro';

import DatePickerBase from '@components/common/core/Utils/DatePicker';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import SortBase from '@components/common/core/Utils/Sort';

export const Sort = styled(SortBase)`
  height: 32px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border: unset;
  font-size: 0.875rem;
`;

export const Input = styled(TextFieldBase)``;

export const Title = styled(HeadingBase)``;

export const TitleAndSortButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media only screen and (max-width: 672px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const DatePicker = styled(DatePickerBase)``;

export const TitleAndFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 552px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

export const DatePickerWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 552px) {
    justify-content: flex-start;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  margin-bottom: 16px;
`;
