import styled from 'styled-components/macro';

export const Rating = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const AuthorSocialMedia = styled.span`
  font-family: 'Poppins', 'Open Sans';
  font-size: 0.75rem;
  color: #9e9e9e;
  font-weight: 400;
`;

export const AuthorName = styled.span`
  font-family: 'Poppins', 'Open Sans';
  font-size: 0.875rem;
  color: #fff;
  font-weight: 600;
`;

export const Author = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AuthorAndRating = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Spacer = styled.hr`
  border-style: solid;
  border-color: #404047;
  margin: 16px 0;
`;

export const Text = styled.span`
  font-family: 'Poppins', 'Open Sans';
  font-size: 1rem;
  color: #fff;
  font-weight: 400;
`;

export const Card = styled.div`
  box-sizing: border-box;
  padding: 32px;
  border-radius: 20px;
  background-color: #24272e;
  width: 540px;

  @media only screen and (max-width: 596px) {
    width: 392px;
  }
`;
