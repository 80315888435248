import React from 'react';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { useFacebookCredentials } from '@helpers/hooks/common/integrations/marketing/facebook/useFacebookCredentials';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { FacebookAdAccountsProvider } from '@helpers/hooks/common/integrations/marketing/facebook/useFacebookAdAccounts';

import FacebookImg from '@assets/pages/Onboarding/dropshipping/marketing/facebook.svg';

import PageError from '@components/common/core/Utils/PageError';
import FacebookButton from '@components/common/core/Utils/FacebookButton';
import Header from '../Header';
import AdAccountsTable from '../Table';
import NotOperationalModal from '../NotOperationalModal';

import { PageSkeletonLoading, TableSkeletonLoading } from '../SkeletonLoading';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const { currentSynchronizations } = useSynchronization();
  const {
    adSenseCredentials,
    isLoadingFacebookCredentialsData,
    isValidatingFacebookCredentialsData,
    isFacebookCredentialsDataError,
    isLoggingOut,
    isLoggingIn,
    mutate,
    handleFacebookData,
    handleFacebookProfileData,
  } = useFacebookCredentials();

  const [isNotOperationalModalOpen, setIsNotOperationalModalOpen] = React.useState<boolean>(false);
  const [isAuthenticateModalOpen, setIsAuthenticateModalOpen] = React.useState<boolean>(false);

  const handleFail = React.useCallback(() => {
    handleFacebookData(undefined);
    handleFacebookProfileData(undefined);
  }, [handleFacebookData, handleFacebookProfileData]);

  const handleIsAuthenticateModalOpen = React.useCallback(
    () => setIsAuthenticateModalOpen(state => !state),
    [],
  );

  const handleIsNotOperationalModalOpen = React.useCallback(
    () => setIsNotOperationalModalOpen(state => !state),
    [],
  );

  React.useEffect(() => {
    const notOperationalCredentials = adSenseCredentials?.filter(
      credential => !credential.is_operational,
    );

    if (notOperationalCredentials?.length > 0) {
      setIsNotOperationalModalOpen(true);
    }
  }, [adSenseCredentials]);

  const isSyncing = Boolean(
    currentSynchronizations?.synchronizations.find(synchronization => {
      if (
        synchronization.type === ESynchronizationType.AD_SENSE_ACCOUNTS ||
        synchronization.type === ESynchronizationType.AD_SENSE_CAMPAIGNS
      ) {
        return true;
      }

      return false;
    }),
  );

  const isLoading =
    isLoadingFacebookCredentialsData ||
    isValidatingFacebookCredentialsData ||
    isLoggingIn ||
    isLoggingOut;

  if (isLoading) {
    return (
      <>
        <PageSkeletonLoading />
      </>
    );
  }

  if (isFacebookCredentialsDataError && !isValidatingFacebookCredentialsData) {
    return (
      <>
        <S.ErrorWrapper>
          <PageError mutate={mutate} />
        </S.ErrorWrapper>
      </>
    );
  }

  return (
    <>
      {Boolean(adSenseCredentials.length) && (
        <S.HeaderAndTableContainer>
          <Header
            isAuthenticateModalOpen={isAuthenticateModalOpen}
            handleIsAuthenticateModalOpen={handleIsAuthenticateModalOpen}
          />

          {isSyncing && <TableSkeletonLoading />}

          {!isSyncing && (
            <>
              {adSenseCredentials.map((adSenseCredential, index) => (
                <FacebookAdAccountsProvider
                  adSenseCredentialAliasId={adSenseCredential.alias_id}
                  key={adSenseCredential.alias_id}
                >
                  <AdAccountsTable
                    openFirstProfile={Boolean(index === 0)}
                    adSenseCredential={adSenseCredential}
                  />
                </FacebookAdAccountsProvider>
              ))}
            </>
          )}
        </S.HeaderAndTableContainer>
      )}

      {Boolean(!adSenseCredentials.length) && (
        <S.ContentWrapper>
          <S.Image src={FacebookImg} alt="profitfy-goat-with-facebook-logo" />

          <S.Heading type={EHeadingSize.H2}>Conectar perfil do facebook</S.Heading>

          <FacebookButton
            width={379}
            onSuccess={handleFacebookData}
            onProfileSuccess={handleFacebookProfileData}
            onFail={handleFail}
          />
        </S.ContentWrapper>
      )}

      <NotOperationalModal
        isOpen={isNotOperationalModalOpen}
        toggle={handleIsNotOperationalModalOpen}
        handleIsAuthenticateModalOpen={handleIsAuthenticateModalOpen}
      />
    </>
  );
};

export default Wrapper;
