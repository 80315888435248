import React from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useToast } from '@helpers/hooks/useToast';
import variantService from '@services/pages/dashboard/kit/variant';
import { IParams } from '@domain/interfaces/IParams';
import {
  IVariant,
  IEditVariantProvider,
  IKitVariant,
} from '@domain/interfaces/dashboard/Kit/editVariant';
import { IEditKitVariant } from '@domain/interfaces/dashboard/Kit/editKit';
import { useEditKit } from './useEditKit';

const EditVariantContext = React.createContext<IEditVariantProvider | null>(null);

export const EditVariantProvider: React.FC<any> = ({ children, defaultVariant }) => {
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { editKitVariants, handleEditKitVariants } = useEditKit();

  const [selectedVariant, setSelectedVariant] = React.useState<IKitVariant | undefined>(undefined);
  const [initialVariant, setInitialVariant] = React.useState<IKitVariant | undefined>(
    defaultVariant,
  );
  const [isVariantsComponentOpen, setIsVariantsComponentOpen] = React.useState<boolean>(false);
  const [isLoadingVariants, setIsLoadingVariants] = React.useState<boolean>(false);
  const [variantsPage, setVariantsPage] = React.useState<number>(0);
  const [variantsPageCount, setVariantsPageCount] = React.useState<number>(1);
  const [variants, setVariants] = React.useState<Array<IVariant>>([]);
  const [quantity, setQuantity] = React.useState<number>(1);
  const [isCardExpanded, setIsCardExpanded] = React.useState<boolean>(false);
  const [isHoveringInput, setIsHoveringInput] = React.useState<boolean>(false);
  const [isHoveringVariantsComponent, setIsHoveringVariantsComponent] = React.useState<boolean>(
    false,
  );
  const [index, setIndex] = React.useState<number>(0);
  const [searchName, setSearchName] = React.useState<string>('');

  const inputComponentRef = React.useRef<HTMLDivElement>(null);
  const focusedVariantCardRef = React.useRef<HTMLDivElement>(null);

  const handleCardExpanded = React.useCallback(() => {
    setIsCardExpanded(!isCardExpanded);
  }, [isCardExpanded]);

  const onCardBlur = React.useCallback(() => {
    if (!selectedVariant && initialVariant) {
      const filteredNewVariants = editKitVariants.filter(
        editKitVariant => editKitVariant?.id !== initialVariant.id,
      );
      handleEditKitVariants(filteredNewVariants);
    }
    if (!selectedVariant && !initialVariant) {
      const editVariants = editKitVariants.slice(0, -1);
      handleEditKitVariants(editVariants);
    }
  }, [editKitVariants, initialVariant, handleEditKitVariants, selectedVariant]);

  const resetInfiniteScroll = React.useCallback(() => {
    setVariants([]);
    setVariantsPage(0);
  }, []);

  const loadVariants = React.useCallback(async () => {
    setIsLoadingVariants(true);

    try {
      const { data } = await variantService.listVariantsPromise({
        storeAliasId,
        page: variantsPage,
        filter: { name: searchName },
      });

      setVariants(currentVariants => [...currentVariants, ...data?.variants]);
      setVariantsPageCount(data?.total_pages);
      setIsLoadingVariants(false);
    } catch (error: any) {
      setIsLoadingVariants(false);
      toast.error(error?.response?.data?.message);
    }
  }, [storeAliasId, toast, variantsPage, searchName]);

  const handleLoadMore = React.useCallback(() => {
    setVariantsPage(oldPage => oldPage + 1);
  }, []);

  const handleSearchName = React.useCallback(
    debouncedSearchName => {
      resetInfiniteScroll();
      setSearchName(debouncedSearchName);
    },
    [resetInfiniteScroll, setSearchName],
  );

  const handleSelectVariant = React.useCallback(
    (variant: IVariant) => {
      const kitVariant = {
        variant,
        quantity,
        id: uuidv4(),
      };

      setSelectedVariant(kitVariant);
      setIsVariantsComponentOpen(false);
      resetInfiniteScroll();

      if (initialVariant) {
        const mappedEditVariants = editKitVariants.map(editKitVariant => {
          if (editKitVariant?.id === initialVariant.id) {
            return kitVariant;
          }

          return editKitVariant;
        });

        setInitialVariant(kitVariant);
        handleEditKitVariants(mappedEditVariants);
      }

      if (!initialVariant) {
        const editVariants = editKitVariants.slice(0, -1);

        setInitialVariant(kitVariant);
        handleEditKitVariants([...editVariants, kitVariant]);
      }

      focusedVariantCardRef?.current?.focus();
    },
    [resetInfiniteScroll, editKitVariants, handleEditKitVariants, initialVariant, quantity],
  );

  const onInputFocus = React.useCallback(() => {
    setIsVariantsComponentOpen(true);
  }, []);

  const closeVariantsComponent = React.useCallback(() => {
    resetInfiniteScroll();
    setIsVariantsComponentOpen(false);
    focusedVariantCardRef?.current?.focus();
  }, [resetInfiniteScroll]);

  const removeVariant = React.useCallback(() => {
    setSelectedVariant(undefined);
    focusedVariantCardRef?.current?.focus();
  }, []);

  const increaseQuantity = React.useCallback(() => {
    const mappedVariants = editKitVariants.map(editKitVariant => {
      if (editKitVariant?.id === initialVariant?.id) {
        return {
          ...editKitVariant,
          quantity: quantity + 1,
        };
      }

      return editKitVariant;
    });

    handleEditKitVariants(mappedVariants);
    setQuantity(previousQuantity => previousQuantity + 1);
  }, [editKitVariants, handleEditKitVariants, initialVariant, quantity]);

  const decreaseQuantity = React.useCallback(() => {
    const mappedVariants = editKitVariants.map(editKitVariant => {
      if (editKitVariant?.id === initialVariant?.id && quantity > 1) {
        return {
          ...editKitVariant,
          quantity: quantity - 1,
        };
      }

      return editKitVariant;
    });

    handleEditKitVariants(mappedVariants);

    setQuantity(previousQuantity => {
      if (previousQuantity === 1) return previousQuantity;

      return previousQuantity - 1;
    });
  }, [handleEditKitVariants, editKitVariants, quantity, initialVariant]);

  const handleExistingVariant = React.useCallback((editKitVariant: IEditKitVariant) => {
    setQuantity(editKitVariant.quantity);
    setSelectedVariant({ variant: editKitVariant.variant, id: editKitVariant.id });
    setInitialVariant({ variant: editKitVariant.variant, id: editKitVariant.id });
  }, []);

  const handleIsHoveringInput = React.useCallback(status => setIsHoveringInput(status), []);

  const handleIsHoveringVariantsComponent = React.useCallback(
    status => setIsHoveringVariantsComponent(status),
    [],
  );

  const handleIndex = React.useCallback(newIndex => setIndex(newIndex), []);

  const hasMore = variantsPage + 1 < variantsPageCount;

  React.useEffect(() => {
    if (!initialVariant) setIsCardExpanded(true);
  }, [initialVariant]);

  return (
    <EditVariantContext.Provider
      value={{
        isLoadingVariants,
        loadVariants,
        variants,
        variantsPage,
        variantsPageCount,
        setVariantsPage,
        handleLoadMore,
        hasMore,
        handleSelectVariant,
        selectedVariant,
        isVariantsComponentOpen,
        onInputFocus,
        removeVariant,
        decreaseQuantity,
        increaseQuantity,
        quantity,
        isCardExpanded,
        handleCardExpanded,
        inputComponentRef,
        focusedVariantCardRef,
        isHoveringInput,
        handleIsHoveringInput,
        closeVariantsComponent,
        handleIndex,
        handleExistingVariant,
        index,
        onCardBlur,
        initialVariant,
        handleSearchName,
        handleIsHoveringVariantsComponent,
        isHoveringVariantsComponent,
      }}
    >
      {children}
    </EditVariantContext.Provider>
  );
};

export const useEditVariant = (): IEditVariantProvider => {
  const context = React.useContext(EditVariantContext);

  if (!context) {
    throw new Error('useVariant must be used within VariantContext');
  }

  return context;
};
