import styled from 'styled-components/macro';

import ButtonBase from '@components/common/core/Inputs/Button';

export const Button = styled(ButtonBase)``;

export const CancelButton = styled.button`
  background-color: ${({ theme }) => theme.colors.gray[2]};
  color: ${({ theme }) => theme.colors.font};
  padding: 12px 24px;
  border: unset;
  border-radius: 5px;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 700;
  height: 42px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const SaveButtonWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.background[5]};
  padding: 20px;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  width: 100%;
  box-sizing: border-box;
`;
