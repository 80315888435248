import React from 'react';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { IImpressions } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IAdSenseCampaign';

import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const Prints: React.FC<ICampaignProps> = ({ data }) => {
  const impressionsData: IImpressions = data;

  return (
    <S.Wrapper>
      <S.PrintsText>{numberFormatter(impressionsData.quantity, 0, true)}</S.PrintsText>
    </S.Wrapper>
  );
};

export default Prints;
