import React from 'react';

import { IInputGroupProps } from '@domain/interfaces/dashboard/CustomValuesCategories/IInputGroup';
import { useCustomValuesCategories } from '@helpers/hooks/pages/dashboard/customValues/useCustomValuesCategories';
import Label from './Label';
import Input from './Input';
import ColorPicker from './ColorPicker';

import * as S from './styles';

const InputGroup: React.FC<IInputGroupProps> = ({ category }) => {
  const { updateCustomValuesCategory } = useCustomValuesCategories();

  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const [isColorPickerOpen, setIsColorPickerOpen] = React.useState<boolean>(false);
  const [inputValue, setInputValue] = React.useState<string>('');
  const [userColor, setUserColor] = React.useState<string>('');

  const handleUserColor = React.useCallback(
    async color => {
      setUserColor(color);
      setIsColorPickerOpen(false);

      await updateCustomValuesCategory({
        customValuesCategoryAliasId: category.alias_id,
        data: {
          group: category.group,
          hex_color: color,
          name: category.name,
        },
      });
    },
    [category, updateCustomValuesCategory],
  );

  const handleIsEditing = React.useCallback(() => setIsEditing(!isEditing), [isEditing]);

  const handleIsHovering = React.useCallback(status => setIsHovering(status), []);

  const handleIsChoosingColor = React.useCallback(status => setIsColorPickerOpen(status), []);

  const onInputValueChange = React.useCallback(event => {
    setInputValue(event.target.value);
  }, []);

  const onBlur = React.useCallback(
    async event => {
      const blurOutside = !event.currentTarget.contains(event.relatedTarget);

      if (blurOutside) {
        handleIsHovering(false);
        handleIsEditing();
      }

      if (isEditing && inputValue && blurOutside) {
        await updateCustomValuesCategory({
          customValuesCategoryAliasId: category.alias_id,
          data: {
            group: category.group,
            hex_color: category.hex_color,
            name: inputValue,
          },
        });
      }
    },
    [
      handleIsEditing,
      handleIsHovering,
      isEditing,
      inputValue,
      category,
      updateCustomValuesCategory,
    ],
  );

  const onSaveClick = React.useCallback(async () => {
    handleIsHovering(false);
    handleIsEditing();

    if (isEditing && inputValue) {
      await updateCustomValuesCategory({
        customValuesCategoryAliasId: category.alias_id,
        data: {
          group: category.group,
          hex_color: category.hex_color,
          name: inputValue,
        },
      });
    }
  }, [
    handleIsEditing,
    handleIsHovering,
    isEditing,
    inputValue,
    category,
    updateCustomValuesCategory,
  ]);

  const onCancelClick = React.useCallback(() => {
    handleIsHovering(false);
    handleIsEditing();
  }, [handleIsEditing, handleIsHovering]);

  return (
    <S.InputWrapper
      onMouseEnter={() => handleIsHovering(true)}
      onMouseLeave={() => handleIsHovering(false)}
    >
      <S.Trail />

      <S.BulletAndInputWrapper>
        <S.Bullet color={userColor || category.hex_color}>
          {isColorPickerOpen && (
            <ColorPicker
              handleIsChoosingColor={handleIsChoosingColor}
              onSelectColor={handleUserColor}
            />
          )}
        </S.Bullet>

        {isEditing ? (
          <Input
            defaultValue={inputValue || category.name}
            onChange={onInputValueChange}
            onBlur={onBlur}
            onCancelClick={onCancelClick}
            onSaveClick={onSaveClick}
          />
        ) : (
          <Label
            handleIsChoosingColor={handleIsChoosingColor}
            isHovering={isHovering}
            handleIsEditing={handleIsEditing}
            name={inputValue || category.name}
            aliasId={category.alias_id}
            category={category}
          />
        )}
      </S.BulletAndInputWrapper>
    </S.InputWrapper>
  );
};

export default InputGroup;
