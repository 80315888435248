export enum EYampiGateways {
  APPMAX = 'appmax',
  MERCADO_PAGO = 'mercado-pago',
  UPNID = 'upnid',
  PAG_SEGURO = 'pag-seguro',
  CIELO = 'cielo',
  PAGARME = 'pagarme',
  HUB_SALE = 'hub-sale',
}

export enum EYampiType {
  STORE = 'STORE',
  ABANDONED_CART = 'ABANDONED_CART',
}
