import { EPersonType } from '@domain/enums/common/personType/EPersonType';
import * as yup from 'yup';

export const legalPersonSchema = yup.object().shape({
  document: yup
    .string()
    .required('Este campo é obrigatório.')
    .test('legal document', 'Informe um CNPJ válido.', value => {
      if (value?.length === 18) return true;

      return false;
    }),
  name: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'Informe uma razão social válida.'),
  phone_number: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(14, 'Informe um celular válido.'),
});

export const naturalPersonSchema = yup.object().shape({
  document: yup
    .string()
    .required('Este campo é obrigatório.')
    .test('natural document', 'Informe um CPF válido.', value => {
      if (value?.length === 14) return true;

      return false;
    }),
  phone_number: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(14, 'Informe um celular válido.'),
});

export const getPersonalInfoSchema = (personType: EPersonType): yup.ObjectSchema => {
  if (personType === EPersonType.LEGAL_PERSON) return legalPersonSchema;

  return naturalPersonSchema;
};
