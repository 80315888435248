import { EFilterType } from '@domain/enums/components/EFilter';
import { IFilterConfig } from '@domain/interfaces/components/IFilter';

export const ADS_MANAGER_FILTER_CONFIG: Array<IFilterConfig> = [
  {
    field: 'ad_sense_credential_id',
    label: 'Perfil',
    type: EFilterType.ADS_MANAGER_AD_SENSE_CREDENTIAL,
    config: {
      placeholder: 'Encontrar perfil...',
    },
  },
  {
    field: 'ad_sense_account_id',
    label: 'Conta de Anúncio',
    type: EFilterType.ADS_MANAGER_AD_SENSE_ACCOUNT,
    config: {
      placeholder: 'Encontrar conta de anúncio...',
    },
  },
];
