import React from 'react';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.Container>
      <S.Header>
        <Skeleton width="100%" height="100%" />
      </S.Header>

      <S.NameAndStatusWrapper>
        <Skeleton width="100%" height="100%" style={{ borderRadius: 8 }} />
      </S.NameAndStatusWrapper>

      <S.Variant>
        <Skeleton width="100%" height="100%" style={{ borderRadius: 8 }} />
      </S.Variant>

      <S.NewVariant>
        <Skeleton width="100%" height="100%" style={{ borderRadius: 8 }} />
      </S.NewVariant>

      <S.KitCost>
        <Skeleton width="100%" height="100%" style={{ borderRadius: 6 }} />
      </S.KitCost>
    </S.Container>
  );
};

export default SkeletonLoading;
