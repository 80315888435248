import React from 'react';
import { useParams } from 'react-router-dom';
import { Trash } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { EPlanRecurrence } from '@domain/enums/subscription/ESubscription';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { ECheckoutStep } from '@domain/enums/subscription/ECheckoutStep';
import { ECouponType } from '@domain/enums/common/coupon/ECouponType';
import { IParams } from '@domain/interfaces/IParams';

import { useCheckout } from '@helpers/hooks/pages/subscription/useCheckout';
import { useCoupon } from '@helpers/hooks/pages/subscription/useCoupon';
import { useQuery } from '@helpers/hooks/useQuery';

import { numberFormatter } from '@helpers/masks';

import { getSelectedPlanRecurrenceDiscounts } from '@helpers/utils/subscription/checkout/recurrence';

import profitfyLogo from '@assets/img/brand/profitfy-logo.svg';

import CouponInput from './CouponInput';
import Recurrence from '../Recurrence';
import CreditCard from '../CreditCard';

import * as S from './styles';

const Overview: React.FC = () => {
  const theme = useTheme();
  const query = useQuery();
  const { selectedPlan, recurrenceType } = useCheckout();
  const { checkoutStep } = useParams<IParams>();
  const { selectedCoupon, resetCoupon } = useCoupon();

  const isMonthlyRecurrenceType = recurrenceType === EPlanRecurrence.MONTHLY;
  const hasDiscount = !isMonthlyRecurrenceType || selectedCoupon;

  const couponIdQuery = query.get('coupon_id');
  const hasCouponIdQuery = couponIdQuery && couponIdQuery !== 'undefined';

  const getRecurrenceName = React.useCallback(
    () =>
      ({
        MONTHLY: 'Mensal',
        QUARTER: 'Trimestral',
        SEMIANUAL: 'Semestral',
      }[recurrenceType]),
    [recurrenceType],
  );

  const getRecurrenceMonthlyPrice = React.useCallback(() => {
    if (recurrenceType === EPlanRecurrence.MONTHLY) return selectedPlan?.monthlyPrice || 0;

    if (recurrenceType === EPlanRecurrence.QUARTER) return (selectedPlan?.quarterPrice || 0) / 3;

    return (selectedPlan?.semesterPrice || 0) / 6;
  }, [recurrenceType, selectedPlan]);

  const getSubtotalPrice = React.useCallback(() => {
    if (recurrenceType === EPlanRecurrence.MONTHLY) return selectedPlan?.monthlyPrice || 0;

    if (recurrenceType === EPlanRecurrence.QUARTER) return (selectedPlan?.monthlyPrice || 0) * 3;

    return (selectedPlan?.monthlyPrice || 0) * 6;
  }, [recurrenceType, selectedPlan]);

  const getDiscountPrice = React.useCallback(() => {
    const [, quarterDiscount, semiAnualDiscount] = getSelectedPlanRecurrenceDiscounts(selectedPlan);

    if (recurrenceType === EPlanRecurrence.QUARTER) return quarterDiscount;

    return semiAnualDiscount;
  }, [recurrenceType, selectedPlan]);

  const getTotalPrice = React.useCallback(() => {
    if (recurrenceType === EPlanRecurrence.MONTHLY) return selectedPlan?.monthlyPrice || 0;

    if (recurrenceType === EPlanRecurrence.QUARTER) return selectedPlan?.quarterPrice || 0;

    return selectedPlan?.semesterPrice || 0;
  }, [recurrenceType, selectedPlan]);

  const getCouponDiscount = React.useCallback(() => {
    if (!selectedCoupon) return 0;

    const totalPrice = getTotalPrice();

    if (selectedCoupon.type === ECouponType.FIXED && selectedCoupon.amount <= totalPrice)
      return selectedCoupon.amount;

    if (selectedCoupon.type === ECouponType.FIXED && selectedCoupon.amount > totalPrice)
      return totalPrice;

    return (totalPrice * selectedCoupon.amount) / 100;
  }, [selectedCoupon, getTotalPrice]);

  const getTotalPriceText = React.useCallback(() => {
    const couponDiscount = selectedCoupon ? getCouponDiscount() : 0;

    if (recurrenceType === EPlanRecurrence.MONTHLY)
      return (selectedPlan?.monthlyPrice || 0) - couponDiscount;

    if (recurrenceType === EPlanRecurrence.QUARTER)
      return (selectedPlan?.quarterPrice || 0) - couponDiscount;

    return (selectedPlan?.semesterPrice || 0) - couponDiscount;
  }, [recurrenceType, selectedPlan, selectedCoupon, getCouponDiscount]);

  const getRecurrenceText = React.useCallback(() => {
    if (recurrenceType === EPlanRecurrence.QUARTER) return 'a cada três meses';

    if (recurrenceType === EPlanRecurrence.SEMIANUAL) return 'a cada seis meses';

    return 'a cada mês';
  }, [recurrenceType]);

  const isPaymentStep = checkoutStep === ECheckoutStep.PAYMENT;

  return (
    <S.Wrapper>
      <S.Logo src={profitfyLogo} alt="Profitfy Logo" />

      <S.ContentWrapper>
        {isPaymentStep ? (
          <CreditCard />
        ) : (
          <>
            <S.Title fontWeight={EHeadingWeight.REGULAR}>Resumo do plano</S.Title>

            <S.HorizontalRule />
          </>
        )}

        <S.PlanDetailsWrapper>
          <S.PlanName>
            Plano {selectedPlan?.name} - <strong>{getRecurrenceName()}</strong>
          </S.PlanName>

          <S.PlanPrice weight={ETextWeight.MEDIUM} size={ETextSize.B1}>
            R${numberFormatter(getRecurrenceMonthlyPrice(), 0)}
            <small>/mês</small>
          </S.PlanPrice>
        </S.PlanDetailsWrapper>

        <Recurrence />

        <CouponInput />

        <S.TotalOrDiscountWrapper>
          {!hasDiscount && (
            <S.ValueWrapper>
              <S.SubtotalLabel>Total</S.SubtotalLabel>
              <S.SubtotalValue>{`R$ ${numberFormatter(
                selectedPlan?.monthlyPrice || 0,
                2,
              )}`}</S.SubtotalValue>
            </S.ValueWrapper>
          )}

          {hasDiscount && (
            <>
              <S.ValueWrapper>
                <S.SubtotalLabel>Subtotal</S.SubtotalLabel>
                <S.SubtotalValue>{`R$ ${numberFormatter(getSubtotalPrice(), 2)}`}</S.SubtotalValue>
              </S.ValueWrapper>

              {!isMonthlyRecurrenceType && (
                <S.ValueWrapper>
                  <S.DiscountLabel>Desconto</S.DiscountLabel>
                  <S.DiscountValue>{`- R$ ${numberFormatter(
                    getDiscountPrice(),
                    2,
                  )}`}</S.DiscountValue>
                </S.ValueWrapper>
              )}

              {selectedCoupon && (
                <S.ValueWrapper>
                  <S.DiscountLabel>
                    {`Cupom (${selectedCoupon.identifier.toUpperCase()})`}

                    {!hasCouponIdQuery && (
                      <Trash size={20} color={theme.colors.font} onClick={resetCoupon} />
                    )}
                  </S.DiscountLabel>
                  <S.DiscountValue>{`- R$ ${numberFormatter(
                    getCouponDiscount(),
                    2,
                  )}`}</S.DiscountValue>
                </S.ValueWrapper>
              )}
            </>
          )}
        </S.TotalOrDiscountWrapper>

        <S.HorizontalRule />

        {hasDiscount && (
          <S.ValueWrapper>
            <S.TotalLabel>Total</S.TotalLabel>
            <S.TotalValueWrapper>
              <S.TotalValue>{`R$ ${numberFormatter(getTotalPriceText(), 2)}`}</S.TotalValue>
              <S.TotalRecurrenceText>{getRecurrenceText()}</S.TotalRecurrenceText>
            </S.TotalValueWrapper>
          </S.ValueWrapper>
        )}
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default Overview;
