import React from 'react';

import { useParams } from 'react-router-dom';
import { IParams } from '@domain/interfaces/IParams';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { useToast } from '@helpers/hooks/useToast';
import { useDate } from '@helpers/hooks/useDate';
import { EStatus } from '@domain/enums/subscription/ESubscription';
import { ESelectedChart } from '@domain/enums/dashboard/main/ESelectedChart';
import {
  ILineDataConfig,
  IData,
  ISummaryData,
  IBarDataConfig,
} from '@domain/interfaces/dashboard/Chart/IChart';
import { IPeriod } from '@domain/interfaces/components/IDatePicker';
import financialDashboardService from '@services/pages/dashboard/financialDashboard/financialDashboard';
import {
  getChartApi,
  parseBarDataConfig,
  parseChartPeriod,
  parseData,
  parseLineDataConfig,
} from '@helpers/dashboard/chart/chartUtils';
import {
  IFinancialDashboardProvider,
  IGetFinancialDashboardPromiseResponse,
  INetProfitChart,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';
import productsService from '@services/pages/dashboard/productAnalytics/products';
import { ISelectedFilter } from '@domain/interfaces/components/IFilter';
import { parseDataFilter } from '@helpers/utils/components/filter';
import financialDashboardConfigService from '@services/pages/dashboard/financialDashboard/financialDashboardConfig';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useLocalStorage } from '@helpers/hooks/useLocalStorage';
import { getEnvironment } from '@helpers/utils/environment';
import { sortProductsData } from '@helpers/utils/dashboard/productAnalytic/rankingProducts';
import { getFinancialReportMultiStoresData } from '@helpers/utils/dashboard/financialDashboard/MultiStoresSelect/financialReportDashboardData';
import { getFinancialChartMultiStoresData } from '@helpers/utils/dashboard/financialDashboard/MultiStoresSelect/chartResponseData';
import { getFinancialChartDataWithMultiStoresData } from '@helpers/utils/dashboard/financialDashboard/MultiStoresSelect/chartData';
import { getFinancialNetProfitChartWithMultiStoresData } from '@helpers/utils/dashboard/financialDashboard/MultiStoresSelect/netProfitChart';
import { IProductMetrics } from '@domain/interfaces/dashboard/ProductAnalytics/IProducts';
import { ERankingProductSortOption } from '@domain/enums/dashboard/productAnalytics/ERankingProductSort';
import { useConfig } from '@helpers/hooks/useConfig';

const FinancialDashboardContext = React.createContext<IFinancialDashboardProvider | null>(null);

export const FinancialDashboardProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();
  const { subscription, hasAllPermissions } = useStoreSubscription();
  const { toast } = useToast();
  const { utcToZonedTime, format, subDays, differenceInDays, addDays } = useDate();
  const { analytics, user } = useConfig();

  const [marketingSynchronizationId, setMarketingSynchronizationId] = useLocalStorage(
    `@profitfy:${getEnvironment()}/marketing-synchronization`,
    null,
  );

  const maxDateLimiter = React.useCallback(() => {
    const isFreePlan = subscription && subscription.plan?.identifier.includes('free');

    if (isFreePlan && subscription.exceeded_order_limit_at && !hasAllPermissions) {
      return utcToZonedTime(new Date(subscription.exceeded_order_limit_at));
    }

    const isSubscriptionPeriodEndedAtAfterNow = subscription?.period_ended_at
      ? differenceInDays(utcToZonedTime(new Date()), new Date(subscription.period_ended_at)) >= 0
      : true;

    if (subscription?.status === EStatus.CANCELED && isSubscriptionPeriodEndedAtAfterNow) {
      return utcToZonedTime(new Date());
    }

    if (
      subscription?.status === EStatus.CANCELED &&
      subscription?.period_ended_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(addDays(new Date(subscription.period_ended_at), 1));
    }

    if (
      subscription?.status === EStatus.CANCELED &&
      subscription?.canceled_at &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(subscription.canceled_at);
    }

    if (subscription && subscription.status === EStatus.TRIAL_ENDED && !hasAllPermissions) {
      return utcToZonedTime(new Date(subscription.period_ended_at));
    }

    if (
      subscription &&
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_CREATE &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(new Date(subscription.period_ended_at));
    }

    if (
      subscription &&
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_CREATE_FAILED &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(new Date(subscription.period_ended_at));
    }

    if (
      subscription &&
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_UPDATE &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(new Date(subscription.period_ended_at));
    }

    return utcToZonedTime(new Date());
  }, [hasAllPermissions, subscription, utcToZonedTime, differenceInDays, addDays]);

  const minDateLimiter = React.useCallback(() => {
    if (subscription && subscription.status === EStatus.TRIAL_ENDED && !hasAllPermissions) {
      return utcToZonedTime(new Date(subscription.period_ended_at));
    }

    if (
      subscription &&
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_CREATE &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(new Date(subscription.period_started_at));
    }

    if (
      subscription &&
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_CREATE_FAILED &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(new Date(subscription.period_started_at));
    }

    if (
      subscription &&
      subscription.status === EStatus.PROCESSING_PAYMENT_IN_UPDATE &&
      !hasAllPermissions
    ) {
      return utcToZonedTime(new Date(subscription.period_started_at));
    }

    return undefined;
  }, [hasAllPermissions, subscription, utcToZonedTime]);

  const initialDate = maxDateLimiter();

  const [
    topThreeProductsSelect,
    setTopThreeProductsSelect,
  ] = React.useState<ERankingProductSortOption>(ERankingProductSortOption.NET_PROFIT_AMOUNT);
  const [selectedChart, setSelectedChart] = React.useState<ESelectedChart>(ESelectedChart.REVENUE);
  const [isLoadingChart, setIsLoadingChart] = React.useState<boolean>(false);
  const [chartData, setChartData] = React.useState<Array<IData> | Array<ISummaryData>>([]);
  const [chartDataPromise, setChartDataPromise] = React.useState<any>(undefined);
  const [lineChartConfig, setLineChartConfig] = React.useState<Array<ILineDataConfig>>([]);
  const [barChartConfig, setBarChartConfig] = React.useState<Array<IBarDataConfig>>([]);
  const [filterData, setFilterData] = React.useState<Array<ISelectedFilter>>([]);
  const [isChartError, setIsChartError] = React.useState<boolean>(false);
  const [chartResponseData, setChartResponseData] = React.useState<any>(undefined);
  const [chartResponseDataPromise, setChartResponseDataPromise] = React.useState<any>(undefined);
  const [isLoadingProductsPromise, setIsLoadingProductsPromise] = React.useState<boolean>(false);
  const [isMultiStoreSelectMode, setIsMultiStoresSelectMode] = React.useState<boolean>(false);
  const [productMetricsPromise, setProductMetricsPromise] = React.useState<
    Array<IProductMetrics[]>
  >([]);

  const [netProfitChartPromiseData, setNetProfitChartPromiseData] = React.useState<
    Array<INetProfitChart[]>
  >([]);

  const [
    isUpdatingDashboardConfiguration,
    setIsUpdatingDashboardConfiguration,
  ] = React.useState<boolean>(false);

  const [financialDataPromise, setFinancialDataPromise] = React.useState<
    IGetFinancialDashboardPromiseResponse[]
  >([]);

  const [period, setPeriod] = React.useState<IPeriod>({
    startDate: initialDate,
    endDate: initialDate,
  });

  const [
    isLoadingFinancialDashboardPromise,
    setIsLoadingFinancialDashboardPromise,
  ] = React.useState<boolean>(false);

  const [
    isLoadingFinancialDashboardNetProfitChartPromise,
    setIsLoadingFinancialDashboardNetProfitChartPromise,
  ] = React.useState<boolean>(false);

  const [isEditStoreGoalSideModalOpen, setIsEditStoreGoalSideModalOpen] = React.useState<boolean>(
    false,
  );
  const [isNewStoreGoalSideModalOpen, setIsNewStoregoalSideModalOpen] = React.useState<boolean>(
    false,
  );
  const [isCustomValuesSideModalOpen, setIsCustomValuesSideModalOpen] = React.useState<boolean>(
    false,
  );
  const [isNewCustomAdsSideModalOpen, setIsNewCustomAdsSideModalOpen] = React.useState<boolean>(
    false,
  );

  const customValueStartDateReference = startOfMonth(period.startDate);
  const customValueEndDateReference = endOfMonth(period.endDate);

  const {
    financialDetailsReport,
    financialReport,
    isLoading: isLoadingFinancialDashboard,
    isValidating: isValidatingFinancialDashboard,
    mutate: mutateFinancialDashboard,
    error: financialDashboardError,
  } = financialDashboardService.getFinancialDashboard({
    storeAliasId,
    startDate: format(period.startDate, 'yyyy-MM-dd'),
    endDate: format(period.endDate, 'yyyy-MM-dd'),
    filter: parseDataFilter(filterData),
  });

  const {
    customValues,
    totalAmount: customValuesTotalAmount,
    isLoading: isLoadingCustomValues,
    isValidating: isValidatingCustomValues,
    mutate: mutateCustomValues,
    error: customValuesError,
  } = financialDashboardService.getFinancialCustomValues({
    storeAliasId,
    startDate: format(customValueStartDateReference, 'yyyy-MM-dd'),
    endDate: format(customValueEndDateReference, 'yyyy-MM-dd'),
  });

  const {
    netProfitChart,
    isLoading: isLoadingFinancialDashboardNetProfitChart,
    isValidating: isValidatingFinancialDashboardNetProfitChart,
    mutate: mutateFinancialDashboardNetProfitChart,
    error: financialDashboardNetProfitError,
  } = financialDashboardService.getFinancialDashboardNetProfitChart({
    storeAliasId,
    startDate: format(subDays(period.endDate, 6), 'yyyy-MM-dd'),
    endDate: format(period.endDate, 'yyyy-MM-dd'),
    filter: parseDataFilter(filterData),
  });
  const {
    productsMetrics,
    isLoading: isLoadingProducts,
    isValidating: isValidatingProducts,
    mutate: mutateProducts,
    error: productsError,
  } = productsService.listTopThreeProducts({
    startDate: format(period.startDate, 'yyyy-MM-dd'),
    endDate: format(period.endDate, 'yyyy-MM-dd'),
    page: 0,
    storeAliasId,
    filter: '',
  });
  const {
    financialDashboardConfiguration,
    isLoading: isLoadingFinancialDashboardConfiguration,
    isValidating: isValidatingFinancialDashboardConfiguration,
    mutate: mutateFinancialDashboardConfiguration,
    error: financialDashboardConfigurationError,
  } = financialDashboardConfigService.getFinancialDashboardConfiguration({ storeAliasId });

  const mutate = React.useCallback(() => {
    const promises = [];

    if (financialDashboardError) promises.push(mutateFinancialDashboard());

    if (customValuesError) promises.push(mutateCustomValues());

    if (financialDashboardNetProfitError) promises.push(mutateFinancialDashboardNetProfitChart());

    if (productsError) promises.push(mutateProducts());

    if (financialDashboardConfigurationError) mutateFinancialDashboardConfiguration();

    Promise.all(promises);
  }, [
    financialDashboardError,
    mutateFinancialDashboard,
    customValuesError,
    mutateCustomValues,
    financialDashboardNetProfitError,
    mutateFinancialDashboardNetProfitChart,
    productsError,
    mutateProducts,
    financialDashboardConfigurationError,
    mutateFinancialDashboardConfiguration,
  ]);

  const activeMultiSelectStoresMode = React.useCallback(() => {
    setIsMultiStoresSelectMode(true);
  }, []);

  const deactiveMultiSelectStoresMode = React.useCallback(() => {
    setIsMultiStoresSelectMode(false);
  }, []);

  const loadFinancialDashboardPromise = React.useCallback(
    async (aliases: string[]) => {
      setIsLoadingFinancialDashboardPromise(true);

      const { startDate, endDate } = period;

      const formattedStartDate = format(startDate, 'yyyy-MM-dd');
      const formattedEndDate = format(endDate, 'yyyy-MM-dd');

      const promises = aliases.map(alias => {
        return financialDashboardService.getFinancialDashboardPromise({
          storeAliasId: alias,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          filter: parseDataFilter(filterData),
        });
      });

      try {
        const responses = await Promise.all(promises);

        const mappedResponses = aliases.map((alias, index) => {
          const response = responses[index];

          const { data } = response;

          return data;
        });

        setIsLoadingFinancialDashboardPromise(false);
        setFinancialDataPromise(mappedResponses);
      } catch (error: any) {
        setIsLoadingFinancialDashboardPromise(false);

        toast.error(error?.response?.data?.message);
      }
    },
    [filterData, toast, format, period],
  );

  const loadFinancialDashboardNetProfitChartPromise = React.useCallback(
    async (aliases: string[]) => {
      setIsLoadingFinancialDashboardNetProfitChartPromise(true);

      const { endDate } = period;

      const formattedStartDate = format(subDays(endDate, 6), 'yyyy-MM-dd');
      const formattedEndDate = format(endDate, 'yyyy-MM-dd');

      const promises = aliases.map(alias => {
        return financialDashboardService.getFinancialDashboardNetProfitChartPromise({
          storeAliasId: alias,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        });
      });

      try {
        const responses = await Promise.all(promises);

        const mappedResponses = aliases.map((alias, index) => {
          const response: any = responses[index];

          const { data } = response;

          return data?.net_profit_chart;
        });

        setIsLoadingFinancialDashboardNetProfitChartPromise(false);
        setNetProfitChartPromiseData(mappedResponses);
      } catch (error: any) {
        setIsLoadingFinancialDashboardNetProfitChartPromise(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [toast, format, period, subDays],
  );

  const loadTopThreeProductsPromise = React.useCallback(
    async (aliases: string[]) => {
      setIsLoadingProductsPromise(false);

      const { startDate, endDate } = period;

      const formattedStartDate = format(startDate, 'yyyy-MM-dd');
      const formattedEndDate = format(endDate, 'yyyy-MM-dd');

      const promises = aliases.map(alias => {
        return productsService.listTopThreeProductsPromise({
          storeAliasId: alias,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          page: 0,
          filter: '',
        });
      });

      try {
        const responses = await Promise.all(promises);

        const mappedResponses = aliases.map((alias, index) => {
          const response = responses[index];

          const { data } = response;

          return data?.products_metrics;
        });

        setIsLoadingProductsPromise(false);
        setProductMetricsPromise(mappedResponses);
      } catch (error: any) {
        setIsLoadingProductsPromise(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [toast, format, period],
  );

  const loadChartPromise = React.useCallback(
    async (aliases: string[]) => {
      setIsLoadingChart(true);
      setIsChartError(false);

      const { startDate: parsedStartDate, endDate: parsedEndDate } = parseChartPeriod({
        startDate: period.startDate,
        endDate: period.endDate,
        format,
      });

      const promises = aliases.map(alias => {
        return getChartApi[selectedChart]({
          storeAliasId: alias,
          startDate: parsedStartDate,
          endDate: parsedEndDate,
          filter: parseDataFilter(filterData),
        });
      });

      try {
        const responses = await Promise.all(promises);

        const mappedResponses = aliases.map((alias, index) => {
          const response = responses[index];

          const { data } = response;

          return data;
        });

        const mappedParsedDataResponses = aliases.map((alias, index) => {
          const response = responses[index];

          const { data } = response;

          const parsedData = parseData[selectedChart](data);

          return parsedData;
        });

        const parsedLineDataConfig = parseLineDataConfig(selectedChart);
        const parsedBarDataConfig = parseBarDataConfig(selectedChart);
        setChartResponseDataPromise(mappedResponses);

        setChartDataPromise(mappedParsedDataResponses);
        setLineChartConfig(parsedLineDataConfig);
        setBarChartConfig(parsedBarDataConfig);
        setIsLoadingChart(false);
      } catch (error: any) {
        setIsLoadingChart(false);
        setIsChartError(true);
        toast.error(error?.response?.data?.message);
      }
    },
    [toast, period, selectedChart, filterData, format],
  );

  const loadMultiStoresData = React.useCallback(
    async (aliases: string[]) => {
      loadFinancialDashboardPromise(aliases);
      loadChartPromise(aliases);
      loadFinancialDashboardNetProfitChartPromise(aliases);
      loadTopThreeProductsPromise(aliases);
    },
    [
      loadFinancialDashboardPromise,
      loadChartPromise,
      loadFinancialDashboardNetProfitChartPromise,
      loadTopThreeProductsPromise,
    ],
  );

  const handleTopThreeProductsSelect = React.useCallback(
    option => {
      setTopThreeProductsSelect(option);

      analytics?.track(
        'Dashboard Product Ranking Clicked',
        { email: user?.email, click_source: option },
        { context: { groupId: storeAliasId } },
      );
    },
    [analytics, user, storeAliasId],
  );

  const handleFilterData = React.useCallback(newData => {
    setFilterData(newData);
  }, []);

  const handleNewCustomAdsSideModalOpen = React.useCallback(() => {
    setIsNewCustomAdsSideModalOpen(!isNewCustomAdsSideModalOpen);
  }, [isNewCustomAdsSideModalOpen]);

  const handleNewStoreGoalSideModal = React.useCallback(() => {
    setIsNewStoregoalSideModalOpen(!isNewStoreGoalSideModalOpen);
  }, [isNewStoreGoalSideModalOpen]);

  const handleEditStoreGoalSideModal = React.useCallback(() => {
    setIsEditStoreGoalSideModalOpen(!isEditStoreGoalSideModalOpen);
  }, [isEditStoreGoalSideModalOpen]);

  const handleCustomValuesSideModalOpen = React.useCallback(() => {
    setIsCustomValuesSideModalOpen(!isCustomValuesSideModalOpen);
  }, [isCustomValuesSideModalOpen]);

  const handleSelectedChart = React.useCallback(newChart => {
    setSelectedChart(newChart);
  }, []);

  const loadChart = React.useCallback(async () => {
    setIsLoadingChart(true);
    setIsChartError(false);

    try {
      const { startDate: parsedStartDate, endDate: parsedEndDate } = parseChartPeriod({
        startDate: period.startDate,
        endDate: period.endDate,
        format,
      });

      const { data } = await getChartApi[selectedChart]({
        storeAliasId,
        startDate: parsedStartDate,
        endDate: parsedEndDate,
        filter: parseDataFilter(filterData),
      });

      const parsedData = parseData[selectedChart](data);
      const parsedLineDataConfig = parseLineDataConfig(selectedChart);
      const parsedBarDataConfig = parseBarDataConfig(selectedChart);
      setChartResponseData(data);

      setChartData(parsedData);
      setLineChartConfig(parsedLineDataConfig);
      setBarChartConfig(parsedBarDataConfig);
      setIsLoadingChart(false);
    } catch (error: any) {
      setIsLoadingChart(false);
      setIsChartError(true);
      toast.error(error?.response?.data?.message);
    }
  }, [toast, period, selectedChart, storeAliasId, filterData, format]);

  const reloadPage = React.useCallback(() => {
    mutateFinancialDashboard();
    mutateCustomValues();
    mutateProducts();
    mutateFinancialDashboardNetProfitChart();
    loadChart();
  }, [
    mutateFinancialDashboard,
    mutateCustomValues,
    mutateProducts,
    mutateFinancialDashboardNetProfitChart,
    loadChart,
  ]);

  const updateDashboardConfiguration = React.useCallback(
    async status => {
      setIsUpdatingDashboardConfiguration(true);

      try {
        if (!financialDashboardConfiguration) {
          await financialDashboardConfigService.createFinancialDashboardConfiguration({
            storeAliasId,
            data: {
              include_custom_value_in_net_profit: status,
            },
          });
        } else {
          await financialDashboardConfigService.updateFinancialDashboardConfiguration({
            storeAliasId,
            data: {
              include_custom_value_in_net_profit: status,
            },
          });
        }

        setIsUpdatingDashboardConfiguration(false);

        mutateFinancialDashboard();
        mutateFinancialDashboardConfiguration();
        mutateFinancialDashboardNetProfitChart();
        loadChart();
      } catch (error: any) {
        setIsUpdatingDashboardConfiguration(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [
      financialDashboardConfiguration,
      loadChart,
      mutateFinancialDashboard,
      mutateFinancialDashboardConfiguration,
      mutateFinancialDashboardNetProfitChart,
      storeAliasId,
      toast,
    ],
  );

  React.useEffect(() => {
    loadChart();
  }, [loadChart]);

  const isLoadingFinancialDashboardProvider =
    isLoadingFinancialDashboard ||
    isLoadingCustomValues ||
    isLoadingFinancialDashboardNetProfitChart ||
    isLoadingProducts ||
    isLoadingFinancialDashboardConfiguration;
  const isValidatingFinancialDashboardProvider =
    isValidatingFinancialDashboard ||
    isValidatingCustomValues ||
    isValidatingFinancialDashboardNetProfitChart ||
    isValidatingProducts ||
    isValidatingFinancialDashboardConfiguration;
  const isFinancialDashboardProviderError =
    Boolean(financialDashboardError) || Boolean(customValuesError);

  const mergeProductsMetrics = (): IProductMetrics[] => {
    if (productsMetrics && productMetricsPromise) {
      const newProductMetricsArray: IProductMetrics[] = [];

      productMetricsPromise?.forEach((array: any) => {
        newProductMetricsArray.push(...array);
      });

      return [...productsMetrics, ...newProductMetricsArray];
    }

    return productsMetrics;
  };

  const mergedProductsMetrics = mergeProductsMetrics();

  const sortedSelectedProductsMetricsChunk = sortProductsData(
    mergedProductsMetrics,
    topThreeProductsSelect,
    'DESC',
  );

  const slicedSortedSelectedProductsMetricsChunk =
    sortedSelectedProductsMetricsChunk?.slice(0, 3) || [];

  const {
    financialReportWithMultiStoresData,
    financialDetailsReportWithMultiStoreData,
  } = getFinancialReportMultiStoresData(
    financialReport,
    financialDetailsReport,
    financialDataPromise,
  );

  const chartResponseDataWithMultiStoreData = getFinancialChartMultiStoresData(
    chartResponseData,
    chartResponseDataPromise,
  );

  const chartDataWithMultiStoresData = getFinancialChartDataWithMultiStoresData(
    chartData,
    chartDataPromise,
    selectedChart,
  );

  const netProfitChartWithMultiStoreData = getFinancialNetProfitChartWithMultiStoresData(
    netProfitChart,
    netProfitChartPromiseData,
  );

  return (
    <FinancialDashboardContext.Provider
      value={{
        financialReport: financialReportWithMultiStoresData,
        financialDetailsReport: financialDetailsReportWithMultiStoreData,
        chartResponseData: chartResponseDataWithMultiStoreData,
        chartData: chartDataWithMultiStoresData,
        netProfitChart: netProfitChartWithMultiStoreData,
        productsMetrics: slicedSortedSelectedProductsMetricsChunk,
        customValuesTotalAmount,
        barChartConfig,
        mutate,
        customValues,
        handleSelectedChart,
        isChartError,
        isFinancialDashboardProviderError,
        isLoadingChart,
        isLoadingFinancialDashboardProvider,
        isValidatingFinancialDashboardProvider,
        lineChartConfig,
        loadChart,
        period,
        selectedChart,
        setPeriod,
        handleNewCustomAdsSideModalOpen,
        isNewCustomAdsSideModalOpen,
        isEditStoreGoalSideModalOpen,
        handleEditStoreGoalSideModal,
        handleNewStoreGoalSideModal,
        isNewStoreGoalSideModalOpen,
        mutateFinancialDashboard,
        handleCustomValuesSideModalOpen,
        isCustomValuesSideModalOpen,
        filterData,
        handleFilterData,
        financialDashboardConfiguration,
        updateDashboardConfiguration,
        reloadPage,
        maxDateLimiter,
        minDateLimiter,
        marketingSynchronizationId,
        setMarketingSynchronizationId,
        isUpdatingDashboardConfiguration,
        loadMultiStoresData,
        isLoadingFinancialDashboardNetProfitChartPromise,
        isLoadingFinancialDashboardPromise,
        isLoadingProductsPromise,
        isMultiStoreSelectMode,
        activeMultiSelectStoresMode,
        deactiveMultiSelectStoresMode,
        handleTopThreeProductsSelect,
        topThreeProductsSelect,
      }}
    >
      {children}
    </FinancialDashboardContext.Provider>
  );
};

export const useFinancialDashboard = (): IFinancialDashboardProvider => {
  const context = React.useContext(FinancialDashboardContext);

  if (!context) {
    throw new Error('useFinancialDashboard must be used within FinancialDashboardProvider');
  }

  return context;
};
