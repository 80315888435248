import React from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useDate } from '@helpers/hooks/useDate';
import { useRetrospectiveData } from '@helpers/hooks/useRetrospectiveData';
import { useCustomValuesIn } from '@helpers/hooks/pages/dashboard/customValues/useCustomValuesIn';
import { useHome } from '@helpers/hooks/pages/dashboard/home/useHome';

import { customValuesSchema } from '@helpers/validators/dashboard/customValues/customValues';
import { currencyFormatter, currencyToNumber } from '@helpers/masks';

import CustomDatePicker from '@components/common/core/Utils/CustomDatePicker';
import Text from '@components/common/core/DataDisplay/Text';
import DayPicker from '@components/common/core/Utils/DayPicker';
import SideModalSave from '@components/common/core/Utils/SideModalSave';

import * as S from './styles';

const Body: React.FC = () => {
  const { utcToZonedTime, format } = useDate();

  const { trackCustomValuesAddedQuantity } = useRetrospectiveData();

  const {
    customValuesCategories,
    createCustomValue,
    handleNewCustomValueSidemodalOpen,
    isCreatingCustomValue,
  } = useCustomValuesIn();
  const { mutateHomeSetup } = useHome();

  const [date, setDate] = React.useState(utcToZonedTime(new Date()));
  const [endDateChecked, setEndDateChecked] = React.useState<boolean>(false);
  const [period, setPeriod] = React.useState({
    startDate: utcToZonedTime(new Date()),
    endDate: utcToZonedTime(new Date()),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(customValuesSchema) });

  const amountInput = register('amount');

  const onSubmit = React.useCallback(
    async data => {
      await createCustomValue({
        amount: currencyToNumber(data.amount),
        category: data.category,
        currency: data.currency,
        description: data.description,
        end_date: endDateChecked ? format(period.endDate, 'yyyy-MM-dd') : undefined,
        is_active: true,
        is_spend: data.type === 'out',
        period: data.period,
        start_date: endDateChecked
          ? format(period.startDate, 'yyyy-MM-dd')
          : format(date, 'yyyy-MM-dd'),
      });

      trackCustomValuesAddedQuantity();

      mutateHomeSetup();
    },
    [
      createCustomValue,
      format,
      period,
      endDateChecked,
      date,
      trackCustomValuesAddedQuantity,
      mutateHomeSetup,
    ],
  );

  const handleAmountEdit = React.useCallback(event => {
    event.target.value = currencyFormatter(event.target.value);
  }, []);

  const handleCheckbox = React.useCallback(status => {
    setEndDateChecked(status.checked);
  }, []);

  return (
    <S.BodyWrapper>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.CurrencyInputGroup>
          <S.Label>Moeda</S.Label>
          <S.Select {...register('currency')}>
            <S.Option value="BRL">Real</S.Option>
            <S.Option value="USD">Dólar</S.Option>
          </S.Select>
          {errors.currency && <Text isErrorFeedback>{errors.currency.message}</Text>}
        </S.CurrencyInputGroup>

        <S.InputGroup>
          <S.Label>Valor</S.Label>
          <S.Input
            {...amountInput}
            type="text"
            placeholder="Insira o valor de custo"
            onChange={handleAmountEdit}
          />
          {errors.amount && <Text isErrorFeedback>{errors.amount.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Categoria</S.Label>
          <S.Select {...register('category')}>
            {customValuesCategories.map(category => (
              <S.Option value={category.alias_id} key={category.alias_id}>
                {category.name}
              </S.Option>
            ))}
          </S.Select>
          {errors.category && <Text isErrorFeedback>{errors.category.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Descrição</S.Label>
          <S.Input
            {...register('description')}
            type="text"
            placeholder="Insira uma descrição para o custo"
          />
          {errors.description && <Text isErrorFeedback>{errors.description.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Frequência</S.Label>
          <S.Select {...register('period')}>
            <S.Option value="ONCE">Única</S.Option>
            <S.Option value="DAILY">Diária</S.Option>
            <S.Option value="WEEKLY">Semanal</S.Option>
            <S.Option value="MONTHLY">Mensal</S.Option>
            <S.Option value="QUARTERLY">Trimestral</S.Option>
            <S.Option value="SEMI_ANNUALY">Semestral</S.Option>
            <S.Option value="ANNUALLY">Anual</S.Option>
          </S.Select>
          {errors.period && <Text isErrorFeedback>{errors.period.message}</Text>}
        </S.InputGroup>

        <S.CheckboxInputGroup>
          <S.Checkbox checked={endDateChecked} onChange={handleCheckbox} />
          <S.Label>Incluir data de término</S.Label>
        </S.CheckboxInputGroup>

        {endDateChecked ? (
          <S.InputGroup>
            <CustomDatePicker period={period} setPeriod={setPeriod} calendarOnTop />
          </S.InputGroup>
        ) : (
          <S.InputGroup>
            <S.Label>Começa em:</S.Label>
            <DayPicker selectedDate={date} setSelectedDate={setDate} />
          </S.InputGroup>
        )}

        <SideModalSave
          successButtonText="Salvar"
          cancelButtonText="Cancelar"
          type="submit"
          onCancel={handleNewCustomValueSidemodalOpen}
          isLoading={isCreatingCustomValue}
          isDisabled={isCreatingCustomValue}
        />
      </S.Form>
    </S.BodyWrapper>
  );
};

export default Body;
