import React from 'react';

import {
  IField,
  ISelectedField,
  ISortProvider,
  ISortProps,
} from '@domain/interfaces/components/ISort';

const SortContext = React.createContext<ISortProvider | null>(null);

export const SortProvider: React.FC<ISortProps> = ({ handleSortData, config, children }) => {
  const [selectedField, setSelectedField] = React.useState<ISelectedField | undefined>(undefined);

  const onFieldClick = React.useCallback(
    (field: IField) => {
      if (selectedField && selectedField.field === field.value) {
        const newValue = selectedField.value === 'ASC' ? 'DESC' : 'ASC';

        setSelectedField({
          field: field.value,
          value: newValue,
        });
      } else {
        setSelectedField({
          field: field.value,
          value: 'DESC',
        });
      }
    },
    [selectedField],
  );

  React.useEffect(() => {
    if (selectedField) {
      handleSortData(selectedField);
    }
  }, [selectedField, handleSortData]);

  return (
    <SortContext.Provider value={{ config, onFieldClick, selectedField }}>
      {children}
    </SortContext.Provider>
  );
};

export const useSort = (): ISortProvider => {
  const context = React.useContext(SortContext);

  if (!context) {
    throw new Error('useSort must be used within SortProvider');
  }

  return context;
};
