import { yupResolver } from '@hookform/resolvers/yup';
import { Shopify as Icon } from '@profitfy/pakkun-icons';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import { EDropshippingProvider } from '@domain/enums/common/integrations/EDropshippingProvider';
import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';

import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useDropshipping } from '@helpers/hooks/pages/onboarding/useDropshipping';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';
import { useConfig } from '@helpers/hooks/useConfig';
import { useDate } from '@helpers/hooks/useDate';
import { useHelpMessenger } from '@helpers/hooks/useHelpMessenger';
import { useToast } from '@helpers/hooks/useToast';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { shopifySchema } from '@helpers/validators/dashboard/integrationCenter/shopifyCredentials';

import { DROPSHIPPING_PROVIDERS } from '@constants/common/integrations';
import { SHOPIFY_SCOPES } from '@constants/shopifyScopes';

import integrationCenterService from '@services/pages/dashboard/integrationCenter/integrationCenter';
import shopifyService from '@services/pages/dashboard/integrationCenter/marketplace/shopify/shopifyAuth';

import NextStep from '@components/Onboarding/NextStep';
import Text from '@components/common/core/DataDisplay/Text';

import CredentialErrorModal from '@components/Integrations/StoreProvider/Shopify/CredentialErrorModal';
import NoScopesModal from '@components/Integrations/StoreProvider/Shopify/NoScopesModal';
import WrongDomainModal from '@components/Integrations/StoreProvider/Shopify/WrongDomainModal';

import { EShopifyType } from '@domain/enums/common/integrations/providers/EShopify';
import * as S from './styles';

const Shopify: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(shopifySchema) });
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { selectedGateways, selectedMarketings } = useDropshipping();
  const { addSynchronization } = useSynchronization();
  const { format, utcToZonedTime } = useDate();
  const { toast } = useToast();
  const { sendFinancialData } = useOnboarding();
  const { showArticle } = useHelpMessenger();
  const { analytics } = useConfig();

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [shopifyDomainValue, setShopifyDomainValue] = React.useState<string>('');
  const [accessTokenValue, setAccessTokenValue] = React.useState<string>('');
  const [secretKeyValue, setSecretKeyValue] = React.useState<string>('');
  const [isNoScopesModalOpen, setIsNoScopesModalOpen] = React.useState<boolean>(false);
  const [missingScopes, setMissingScopes] = React.useState<Array<string>>([]);
  const [isCredentialErrorModalOpen, setIsCredentialErrorModalOpen] = React.useState<boolean>(
    false,
  );
  const [isWrongDomainModalOpen, setIsWrongDomainModalOpen] = React.useState<boolean>(false);

  const domainRegister = register('shopify_url');
  const accessTokenRegister = register('access_token');
  const secretKeyRegister = register('secret_key');

  const handleNoScopesModalOpen = React.useCallback(() => {
    setIsNoScopesModalOpen(currentState => !currentState);
  }, []);

  const handleCredentialErrorModalOpen = React.useCallback(() => {
    setIsCredentialErrorModalOpen(currentState => !currentState);
  }, []);

  const handleWrongDomainModalOpen = React.useCallback(() => {
    setIsWrongDomainModalOpen(state => !state);
  }, []);

  const onShopifyDomainValueChange = React.useCallback(
    event => {
      domainRegister.onChange(event);

      setShopifyDomainValue(event.target.value);
    },
    [domainRegister],
  );

  const onShopifyAccessTokenValueChange = React.useCallback(
    event => {
      accessTokenRegister.onChange(event);

      setAccessTokenValue(event.target.value);
    },
    [accessTokenRegister],
  );

  const onShopifySecretKeyValueChange = React.useCallback(
    event => {
      secretKeyRegister.onChange(event);

      setSecretKeyValue(event.target.value);
    },
    [secretKeyRegister],
  );

  const sanitizeUrl = React.useCallback(url => {
    const replacedUrl = url.replace('https://', '').replace('wwww.', '');

    const parsedUrl = replacedUrl.split('.myshopify.com')[0];

    return parsedUrl;
  }, []);

  const handleSuccessSubmit = React.useCallback(async () => {
    const { data } = await integrationCenterService.getStoreIntegrations({ storeAliasId });

    const hasShopifyCredential = data?.store_integrations?.store_front?.has_shopify_credential;

    if (hasShopifyCredential) {
      const foundShopifyIntegration = DROPSHIPPING_PROVIDERS.find(
        integration => integration.identifier === EDropshippingProvider.SHOPIFY,
      ) as typeof DROPSHIPPING_PROVIDERS[number];

      const { generateSyncRequest } = foundShopifyIntegration;

      const params = `?start_date=${format(new Date(2000, 1, 1), 'yyyy-MM-dd')}&end_date=${format(
        utcToZonedTime(new Date()),
        'yyyy-MM-dd',
      )}`;

      addSynchronization({
        name: 'Shopify',
        type: ESynchronizationType.STORE_PRODUCT,
        label: 'Produtos',
        dependencies: [],
        storeAliasId,
        config: {
          isOnboardingSynchronization: true,
          showNotification: false,
        },
        request: generateSyncRequest(ESynchronizationType.STORE_PRODUCT, storeAliasId, params),
      });

      sendFinancialData(storeAliasId);

      setIsSubmittingForm(false);

      if (selectedGateways.length) {
        history.push(`/onboarding/${storeAliasId}/dropshipping/integrations/gateways`);
      }

      if (!selectedGateways.length && selectedMarketings.length) {
        history.push(`/onboarding/${storeAliasId}/dropshipping/integrations/marketing`);
      }

      if (!selectedGateways.length && !selectedMarketings.length) {
        history.push(`/onboarding/${storeAliasId}/dropshipping/integrations/integration-finished`);
      }
    } else {
      setIsSubmittingForm(false);
    }
  }, [
    addSynchronization,
    format,
    history,
    selectedGateways,
    selectedMarketings,
    storeAliasId,
    utcToZonedTime,
    sendFinancialData,
  ]);

  const handleFailureSubmit = React.useCallback(
    error => {
      const errorMessage = error?.response?.data?.message as string;

      const isShopifyUnauthorizedError = errorMessage === 'Shopify unauthorized';
      const isShopifyinAnotherStore =
        errorMessage === 'Shopify store already exists in another profitfy store';

      const isNoScopeError = errorMessage.includes('Missing the following scope(s)');

      if (isShopifyUnauthorizedError) handleCredentialErrorModalOpen();

      if (isNoScopeError) {
        const filteredShopifyScopes = SHOPIFY_SCOPES.filter(scope => {
          if (errorMessage.includes(scope)) return true;

          return false;
        });

        if (filteredShopifyScopes) {
          setMissingScopes(filteredShopifyScopes);
          handleNoScopesModalOpen();
        }
      }

      if (isShopifyinAnotherStore)
        toast.error('Para alterar a credencial, entrar em contato com o suporte !');

      if (!isShopifyUnauthorizedError && !isNoScopeError)
        toast.error(getTranslatedIntegrationsError(error));

      setIsSubmittingForm(false);
    },
    [handleCredentialErrorModalOpen, handleNoScopesModalOpen, toast],
  );

  const onSubmit = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      const sanitizedUrl = sanitizeUrl(formData.shopify_url);

      const storeDomain = `${sanitizedUrl}.myshopify.com`;

      const data = {
        store_domain: storeDomain,
        access_token: formData.access_token,
        secret_api_key: formData.secret_key,
        types: [EShopifyType.STORE],
      };

      try {
        await shopifyService.createCredential({ storeAliasId, data });

        handleSuccessSubmit();
      } catch (error: any) {
        setIsSubmittingForm(false);

        const hasErrorMessage = error?.response?.data?.message;

        if (hasErrorMessage) {
          handleFailureSubmit(error);
          return;
        }

        if (error?.code === 'ERR_BAD_RESPONSE') {
          handleWrongDomainModalOpen();
          return;
        }

        throw new Error(error);
      }
    },
    [
      sanitizeUrl,
      handleSuccessSubmit,
      storeAliasId,
      handleFailureSubmit,
      handleWrongDomainModalOpen,
    ],
  );

  const trackArticleClick = React.useCallback(() => {
    analytics?.track(
      'Shopify Integration Article Clicked',
      {
        click_source: 'onboarding_form',
      },
      { context: { groupId: storeAliasId } },
    );
  }, [analytics, storeAliasId]);

  return (
    <S.Wrapper>
      <S.FormWrapper>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Header>
            <S.Title>Integre com sua plataforma de E-commerce</S.Title>
          </S.Header>

          <S.IntegrationTitle>
            <Icon size={36} />

            <S.IntegrationTitleText fontWeight={EHeadingWeight.REGULAR}>
              Integração com Shopify
            </S.IntegrationTitleText>
          </S.IntegrationTitle>

          <S.Body>
            <S.InputGroup>
              <S.Label>Domínio Shopify</S.Label>

              <S.InputWrapper
                isError={errors.shopify_url}
                value={shopifyDomainValue}
                noFeedbackSuccess
              >
                <S.TextField
                  {...domainRegister}
                  autoFocus
                  onChange={onShopifyDomainValueChange}
                  type="text"
                  id="shopify-store-url"
                  noFeedbackSuccess
                />

                <S.AppendContent>.myshopify.com</S.AppendContent>
              </S.InputWrapper>
              {errors.shopify_url && <Text isErrorFeedback>{errors.shopify_url.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label htmlFor="shopify-access-token">Token de acesso da API Admin</S.Label>

              <S.TextField
                {...register('access_token')}
                type="text"
                id="shopify-access-token"
                placeholder="Token de acesso da API Admin"
                value={accessTokenValue}
                onChange={onShopifyAccessTokenValueChange}
                isError={errors.access_token}
                noFeedbackSuccess
              />
              {errors.access_token && <Text isErrorFeedback>{errors.access_token.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label htmlFor="shopify-secret-key">Chave secreta da API</S.Label>

              <S.TextField
                {...register('secret_key')}
                type="text"
                id="shopify-secret-key"
                placeholder="Chave secreta da API"
                value={secretKeyValue}
                onChange={onShopifySecretKeyValueChange}
                isError={errors.secret_key}
                noFeedbackSuccess
              />

              {errors.secret_key && <Text isErrorFeedback>{errors.secret_key.message}</Text>}
            </S.InputGroup>
          </S.Body>

          <NextStep
            type="submit"
            isLoading={isSubmittingForm}
            disabled={isSubmittingForm || !shopifyDomainValue}
          >
            Próximo passo
          </NextStep>
        </S.Form>
      </S.FormWrapper>

      <S.TutorialWrapper>
        <S.TutorialTitle>Tutorial de integração</S.TutorialTitle>
        <S.TutorialContent>
          Informe a URL da Loja, o Access Token e a Secret Key. Para saber como obter essas
          informações, <strong>veja o nosso tutorial:</strong>
        </S.TutorialContent>
        <S.ArticleLink
          href="https://ajuda.profitfy.me/pt-BR/articles/5901324-como-integrar-com-a-loja-shopify"
          target="_blank"
          onClick={trackArticleClick}
        >
          <S.IntegrationArticleImage
            src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/integrations/shopify/shopify-how-to-integrate.jpg"
            alt="Profitfy Shopify Integration"
          />
        </S.ArticleLink>
      </S.TutorialWrapper>

      <CredentialErrorModal
        isOpen={isCredentialErrorModalOpen}
        toggle={handleCredentialErrorModalOpen}
      />

      <NoScopesModal
        isOpen={isNoScopesModalOpen}
        toggle={handleNoScopesModalOpen}
        missingScopes={missingScopes}
      />

      <WrongDomainModal isOpen={isWrongDomainModalOpen} toggle={handleWrongDomainModalOpen} />
    </S.Wrapper>
  );
};

export default Shopify;
