import styled, { keyframes, css } from 'styled-components/macro';

import LabelBase from '@components/common/core/DataDisplay/Label';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import ButtonBase from '@components/common/core/Inputs/Button';
import Image from '@components/common/core/DataDisplay/Image';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import FormBase from '@components/common/core/Inputs/Form';
import PhoneInputBase from '@components/common/core/Utils/PhoneInput';

import Details4 from '@assets/pages/SignUp/details4.svg';

const appearFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const PhoneInput = styled(PhoneInputBase)`
  border-radius: 5px;
  height: 42px;
  border: 1px solid transparent;
  transition: all 0.3s;

  ${({ isError, theme, value }) =>
    Boolean(value) &&
    Boolean(!isError) &&
    css`
      background-color: ${theme.colors.black.default};
      border-color: ${theme.colors.success.default};
    `}

  ${({ isError, theme }) =>
    Boolean(isError) &&
    css`
      border-color: ${theme.colors.danger.default};
      background-color: ${theme.colors.black.default};
    `}

  .form-control {
    height: 42px;
    background-color: ${({ theme }) => theme.colors.background[2]};
    border: 0;
    width: 100%;
    color: ${({ theme }) => theme.colors.gray[1]};
    font-family: ${({ theme }) => theme.fonts.body};
    font-weight: 500;
    font-size: 1rem;
    transition: all 0.3s;

    ${({ isError, theme, value }) =>
      Boolean(value) &&
      Boolean(!isError) &&
      css`
        background-color: ${theme.colors.black.default};
      `}

    ${({ isError, theme }) =>
      Boolean(isError) &&
      css`
        background-color: ${theme.colors.black.default};
      `}
  }

  .flag-dropdown {
    border: 0;
    background-color: ${({ theme }) => theme.colors.background[2]};
    border-radius: 5px;
    transition: all 0.3s;

    ${({ isError, theme, value }) =>
      Boolean(value) &&
      Boolean(!isError) &&
      css`
        background-color: ${theme.colors.black.default};
      `}

    ${({ isError, theme }) =>
      Boolean(isError) &&
      css`
        background-color: ${theme.colors.black.default};
      `}

    .selected-flag {
      background-color: ${({ theme }) => theme.colors.background[2]};
      border: 0;
      border-radius: 5px;
      transition: all 0.3s;

      ${({ isError, theme, value }) =>
        Boolean(value) &&
        Boolean(!isError) &&
        css`
          background-color: ${theme.colors.black.default};
        `}

      ${({ isError, theme }) =>
        Boolean(isError) &&
        css`
          background-color: ${theme.colors.black.default};
        `}

      &:hover {
        background-color: transparent;
      }

      &:active {
        background-color: ${({ theme }) => theme.colors.background[2]};
      }
    }

    .open {
      background-color: ${({ theme }) => theme.colors.background[2]} !important;

      ${({ isError, theme, value }) =>
        Boolean(value) &&
        Boolean(!isError) &&
        css`
          background-color: ${theme.colors.black.default} !important;
        `}

      ${({ isError, theme }) =>
        Boolean(isError) &&
        css`
          background-color: ${theme.colors.black.default} !important;
        `}
    }
  }

  .country-list {
    background-color: ${({ theme }) => theme.colors.background[2]};

    .country {
      span {
        color: ${({ theme }) => theme.colors.gray[1]};
        font-family: ${({ theme }) => theme.fonts.body};
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[3]};
      }
    }

    .highlight {
      background-color: ${({ theme }) => theme.colors.gray[3]} !important;
    }
  }
`;

export const Form = styled(FormBase)``;

export const Logo = styled(Image)`
  max-width: 200px;
  width: 100%;
  margin-bottom: 32px;
  display: block;
`;

export const TitleAndSubTitleWrapper = styled.div`
  animation: ${appearFromBottom} 700ms;
  margin-top: 32px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &::after {
    content: '';
    position: absolute;
    bottom: 661px;
    right: 48px;
    width: 22px;
    height: 22px;
    background-image: url(${Details4});
    background-repeat: no-repeat;

    @media only screen and (max-width: 1140px) {
      display: none;
    }

    @media only screen and (max-width: 620px) {
      display: none;
    }
  }
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const SubTitle = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const BenefitsWrapper = styled.div`
  animation: ${appearFromBottom} 700ms;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 24px;
`;

export const BenefitTitle = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
  display: inline-block;
`;

export const BenefitDescription = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
  font-size: 0.875rem;
`;

export const Benefit = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
`;

export const InputGroupAndCreateAccountButton = styled.div`
  animation: ${appearFromBottom} 700ms;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const CreateAccountButton = styled(ButtonBase)`
  max-width: 374px !important;
  width: 100%;
  margin-top: 24px;
`;

export const Label = styled(LabelBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const InputGroup = styled(InputGroupBase)`
  max-width: 374px;
  width: 100%;
`;
