import React from 'react';
import { Task, Plus } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { EAlertVariant } from '@domain/enums/components/EAlert';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ETextSize } from '@domain/enums/components/EText';
import { IParams } from '@domain/interfaces/IParams';

import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useCampaigns } from '@helpers/hooks/pages/dashboard/campaigns/useCampaigns';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { createProductTagSchema } from '@helpers/validators/dashboard/productTag/productTag';

import { ADS_PROVIDERS } from '@constants/common/integrations';

import Form from '@components/common/core/Inputs/Form';
import SideModalSave from '@components/common/core/Utils/SideModalSave';
import ComponentError from '@components/common/core/Utils/ComponentError';
import Text from '@components/common/core/DataDisplay/Text';
import Paginate from '@components/common/core/Utils/Paginate';
import SyncingCampaignsModal from '../SyncingCampaignsModal';
import TagWrapper from './TagWrapper';

import * as S from './styles';

const SideModalTags: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(createProductTagSchema) });
  const {
    isTagsSideModalOpen,
    handleTagsSideModalOpen,
    productTags,
    isLoadingCampaignsData,
    isCampaignsDataError,
    isValidatingCampaignsData,
    mutateCampaignsData,
    createProductTag,
    hasCreatedOrUpdatedProductTag,
    productTagsPage,
    productTagsPageCount,
    onProductTagsPageChange,
    isSyncingAdSenseCampaigns,
  } = useCampaigns();

  const { addSynchronization } = useSynchronization();
  const { storeAliasId } = useParams<IParams>();
  const { storeIntegrations } = useStoreConfig();

  const [isCreatingProductTag, setIsCreatingProductTag] = React.useState<boolean>(false);
  const [isSyncingCampaignsModalOpen, setIsSyncingCampaignsModalOpen] = React.useState<boolean>(
    false,
  );

  const handleSyncingCampaignsModalOpen = React.useCallback(() => {
    if (isSyncingCampaignsModalOpen) {
      setIsSyncingCampaignsModalOpen(false);
      handleTagsSideModalOpen();
    } else {
      setIsSyncingCampaignsModalOpen(true);
    }
  }, [isSyncingCampaignsModalOpen, handleTagsSideModalOpen]);

  const handleSyncAdSenseCampaigns = React.useCallback(() => {
    if (storeIntegrations) {
      const activeAdSenseIntegrations = storeIntegrations.marketing.filter(
        integration => integration.is_active,
      );
      const marketingIntegrations = ADS_PROVIDERS.filter(integration =>
        activeAdSenseIntegrations.find(
          activeIntegration => activeIntegration.name === integration.identifier,
        ),
      );

      marketingIntegrations.forEach(integration => {
        addSynchronization({
          name: integration.name,
          type: ESynchronizationType.AD_SENSE_CAMPAIGNS,
          label: 'Campanhas',
          dependencies: [],
          storeAliasId,
          config: {
            isOnboardingSynchronization: false,
            showNotification: true,
          },
          request: integration.generateSyncRequest(
            ESynchronizationType.AD_SENSE_CAMPAIGNS,
            storeAliasId,
            undefined,
            undefined,
          ),
        });
      });

      handleSyncingCampaignsModalOpen();
    }
  }, [handleSyncingCampaignsModalOpen, storeAliasId, storeIntegrations, addSynchronization]);

  const onSubmit = React.useCallback(
    async data => {
      setIsCreatingProductTag(true);

      const productTagName = data.new_tag;

      await createProductTag(productTagName);

      setIsCreatingProductTag(false);
    },
    [createProductTag],
  );

  if (isLoadingCampaignsData) {
    return <div>Loading...</div>;
  }

  if (isCampaignsDataError && !isValidatingCampaignsData) {
    return <ComponentError mutate={mutateCampaignsData} />;
  }

  return (
    <S.SideModal isOpen={isTagsSideModalOpen} toggle={handleTagsSideModalOpen}>
      <S.Header>
        <Task outline size={24} />
        <S.Title fontWeight={EHeadingWeight.REGULAR}>Tags de Sincronização</S.Title>
      </S.Header>

      <S.Body>
        <S.DescriptionTitle fontWeight={EHeadingWeight.REGULAR} type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.DescriptionTitle>
        <S.DescriptionText size={ETextSize.B5}>
          Para ativar suas campanhas mais facilmente, crie uma tag (identificador) com um nome em
          comum das suas campanhas deste produto.
        </S.DescriptionText>
        <S.ExampleText size={ETextSize.B5}>
          Exemplo: [CONV][LAL][PRODUTO X] sua tag será: PRODUTO X
        </S.ExampleText>

        <S.Alert
          variant={EAlertVariant.WARNING}
          outlineIcon
          description="A função de sincronizar as tags com Google Ads está em manutenção por tempo indeterminado. Enquanto isso, você poderá ativar suas campanhas manualmente."
        />

        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>Tag de sincronização</S.Label>
            <S.Input {...register('new_tag')} type="text" placeholder="Ex. Produto X" />
            {errors.new_tag && <Text isErrorFeedback>{errors.new_tag.message}</Text>}
          </S.InputGroup>

          <S.CreateTagButton
            type="submit"
            isLoading={isCreatingProductTag}
            disabled={isCreatingProductTag || isSyncingAdSenseCampaigns}
          >
            <Plus size={12} color="#fff" />
            Adicionar Tag
          </S.CreateTagButton>
        </Form>

        {productTags?.length ? (
          <>
            <S.HorizontalRule />

            <S.TagsWrapper>
              <S.TagsTitle fontWeight={EHeadingWeight.REGULAR}>Suas tags</S.TagsTitle>

              {productTags.map(productTag => (
                <TagWrapper productTag={productTag} key={productTag.alias_id} />
              ))}
            </S.TagsWrapper>
          </>
        ) : (
          <></>
        )}

        <S.PaginateWrapper>
          <Paginate
            forcePage={productTagsPage}
            onPageChange={onProductTagsPageChange}
            pageCount={productTagsPageCount}
          />
        </S.PaginateWrapper>

        {hasCreatedOrUpdatedProductTag && (
          <SideModalSave
            successButtonText="Sincronizar campanhas"
            onSuccess={handleSyncAdSenseCampaigns}
            cancelButtonText="Cancelar"
            onCancel={handleTagsSideModalOpen}
            isLoading={isSyncingAdSenseCampaigns}
            isDisabled={isSyncingAdSenseCampaigns}
          />
        )}

        <SyncingCampaignsModal
          isOpen={isSyncingCampaignsModalOpen}
          toggle={handleSyncingCampaignsModalOpen}
        />
      </S.Body>
    </S.SideModal>
  );
};

export default SideModalTags;
