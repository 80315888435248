import React from 'react';

import { WarningCircle } from 'phosphor-react';

import { ETextSize } from '@domain/enums/components/EText';
import { EButtonSize } from '@domain/enums/components/EButton';
import { INoDeletedInstanceModalProps } from '@domain/interfaces/dashboard/WhatsappInstance/INoDeletedInstanceModal';

import * as S from './styles';

const NoDeletedInstanceModal: React.FC<INoDeletedInstanceModalProps> = ({ isOpen, toggle }) => {
  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Header>
        <WarningCircle size={24} color="#D03333" />
        <S.Heading>Não foi possível excluir a instância</S.Heading>
      </S.Header>

      <S.Description size={ETextSize.B5}>
        A instância que você está tentando remover{' '}
        <strong>possui algumas automações associadas à ela. Transfira</strong> essas automações
        <br /> para uma outra instância ou <strong>remova</strong> elas para poder continuar com a
        exclusão dessa instância.
      </S.Description>

      <S.ConfirmButton onClick={toggle} buttonSize={EButtonSize.MEDIUM}>
        Entendi
      </S.ConfirmButton>
    </S.Modal>
  );
};

export default NoDeletedInstanceModal;
