import React from 'react';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  const tableRows = Array.from(Array(5).keys());

  return (
    <>
      {tableRows.map(tableRow => (
        <S.Table.Row key={tableRow} borderBottom>
          <S.Table.Data>
            <S.SwitchWrapper>
              <Skeleton width="100%" height={22} />
            </S.SwitchWrapper>
          </S.Table.Data>

          <S.Table.Data>
            <S.AccountIdWrapper>
              <Skeleton width="100%" height={20} />
              <Skeleton width={80} height={20} />
            </S.AccountIdWrapper>
          </S.Table.Data>

          <S.Table.Data>
            <S.AccountNameWrapper>
              <Skeleton width="100%" height={20} />
              <Skeleton width={60} height={20} />
            </S.AccountNameWrapper>
          </S.Table.Data>

          {/* <S.Table.Data align={EHeadAlign.RIGHT}>
            <S.SpendAmountWrapper>
              <Skeleton width={70} height={20} />
              <Skeleton width="100%" height={20} />
            </S.SpendAmountWrapper>
          </S.Table.Data> */}

          <S.Table.Data>
            <Skeleton width={82} height={20} />
          </S.Table.Data>
        </S.Table.Row>
      ))}
    </>
  );
};

export default SkeletonLoading;
