import React from 'react';

import { ICampaignProps } from '@domain/interfaces/dashboard/AdsManager/Table/Campaign/ICampaign';
import { ICtr } from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/ISummaryReport';

import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

const Ctr: React.FC<ICampaignProps> = ({ data }) => {
  const ctrData: ICtr = data;

  return (
    <S.Wrapper>
      <S.CtrAmount>{`${numberFormatter(ctrData.average_percentage, 2)}%`}</S.CtrAmount>
      <S.CtrLabel>Por Impressões</S.CtrLabel>
    </S.Wrapper>
  );
};

export default Ctr;
