import { Copy } from '@profitfy/pakkun-icons';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { ETextWeight } from '@domain/enums/components/EText';
import { EHotmartType } from '@domain/enums/common/integrations/providers/EHotmart';
import { IParams } from '@domain/interfaces/IParams';
import { IHotmartCredentialOptionProps } from '@domain/interfaces/dashboard/IntegrationCenter/InfoProduct/IHotmart';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useToast } from '@helpers/hooks/useToast';

import hotmartService from '@services/pages/dashboard/integrationCenter/infoProduct/platforms/hotmart';

import DisableIntegrationFooter from '@components/Dashboard/IntegrationCenter/Sidemodal/DisableIntegrationFooter';

import DeleteCredentialModal from '@components/Dashboard/IntegrationCenter/SideModalContent/DeleteCredentialModal';
import * as S from './styles';

const WEBHOOK_URL = 'https://dosu-api.profitfy.me/prod/api/v1/webhooks/hotmart/events';
const HELP_ARTICLE = 'https://ajuda.profitfy.me/pt-BR/articles/6803844-como-integrar-com-a-hotmart';

const CredentialOption: React.FC<IHotmartCredentialOptionProps> = ({ credentialAliasId }) => {
  const theme = useTheme();
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { mutateStore } = useStoreConfig();

  const [isDeletingCredential, setIsDeletingCredential] = React.useState<boolean>(false);
  const [isURLCopied, setIsURLCopied] = React.useState<boolean>(false);
  const [isDeleteCredentialModalOpen, setIsDeleteCredentialModalOpen] = React.useState<boolean>(
    false,
  );

  const handleDeleteCredentialModalOpen = React.useCallback(
    () => setIsDeleteCredentialModalOpen(state => !state),
    [],
  );

  const handleCopyURL = React.useCallback(() => {
    navigator.clipboard.writeText(WEBHOOK_URL);
    setIsURLCopied(true);
    toast.success('URL copiada com sucesso.');
  }, [toast]);

  const onDeleteCredential = React.useCallback(async () => {
    setIsDeletingCredential(true);

    try {
      await hotmartService.updateCredential({
        storeAliasId,
        data: { is_active: false, types: [EHotmartType.STORE] },
        credentialAliasId,
      });

      toast.success('Credencial desativada com sucesso!');
      handleDeleteCredentialModalOpen();

      mutateStore();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsDeletingCredential(false);
    }
  }, [toast, mutateStore, storeAliasId, credentialAliasId, handleDeleteCredentialModalOpen]);

  return (
    <S.Content>
      <S.CredentialDescriptionWrapper>
        <S.CredentialDescriptionTitle type={EHeadingSize.H5}>
          O que acontece se eu desativar minha integração?
        </S.CredentialDescriptionTitle>
        <S.CredentialDescriptionText>
          Ao desativar a integração,
          <strong> não será mais possível </strong>
          processar os pedidos da Hotmart.
        </S.CredentialDescriptionText>
      </S.CredentialDescriptionWrapper>

      <S.Divider />

      <S.CredentialDescriptionTitle type={EHeadingSize.H5}>
        Como cadastrar o Webhook?
      </S.CredentialDescriptionTitle>

      <S.CredentialDescriptionText>
        Para podermos receber as informações de vendas da Hotmart é necessário que tenha o Webhook
        cadastrado. Saiba como cadastrar{' '}
        <S.Link href={HELP_ARTICLE} target="_blank">
          clicando aqui.
        </S.Link>
      </S.CredentialDescriptionText>

      <S.WebhookURLWrapper onClick={handleCopyURL} type="button">
        <S.WebhookURL weight={ETextWeight.MEDIUM}>{WEBHOOK_URL}</S.WebhookURL>
        <S.IconWrapper>
          <Copy size={18} color={theme.colors.gray[1]} outline={!isURLCopied} />
        </S.IconWrapper>
      </S.WebhookURLWrapper>

      <DisableIntegrationFooter
        onDeleteCredential={handleDeleteCredentialModalOpen}
        isDeletingCredential={isDeletingCredential}
      />

      <DeleteCredentialModal
        isOpen={isDeleteCredentialModalOpen}
        toggle={handleDeleteCredentialModalOpen}
        onDeleteCredential={onDeleteCredential}
        isLoading={isDeletingCredential}
      />
    </S.Content>
  );
};

export default CredentialOption;
