import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';

export const RegulationTopic = styled.li`
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 700;
  line-height: 200%;
`;

export const RegulationWrapper = styled.ul``;

export const RegulationContent = styled.div`
  background: #e8e8e8;
  padding: 32px 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
`;

export const AnniversaryText = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  font-family: 'Poppins';
  text-align: center;

  & > strong {
    color: #fff;
    background-color: #000;
    padding: 2px 4px;
    border-radius: 7px;
  }
`;

export const RegulationLink = styled.a`
  font-family: 'Poppins';
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #000;
  font-weight: 500;
`;

export const DueDateText = styled.span`
  font-family: 'Poppins';
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media only screen and (max-width: 416px) {
    flex-direction: column;
  }
`;

export const AnniversaryWrapper = styled.div`
  background: #e8e8e8;
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled(ImageBase)`
  max-width: 152px;
  width: 100%;
  margin-bottom: 48px;
  cursor: pointer;
  margin-top: 32px;
`;

export const LogoWrapper = styled.div`
  max-width: 1384px;
  width: 100%;
  margin: 0 auto;
`;

export const ContentWrapper = styled.div`
  padding: 0 16px;

  @media only screen and (max-width: 728px) {
    padding: 32px;
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  /* padding: 0 16px; */
  box-sizing: border-box;

  /* @media only screen and (max-width: 728px) {
    padding: 32px;
  } */
`;

export const Container = styled.div`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray[7]};
  min-height: 100vh;
`;
