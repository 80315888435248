import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import RadioBase from '@components/common/core/Inputs/Radio';
import BadgeBase from '@components/common/core/DataDisplay/Badge';
import ButtonBase from '@components/common/core/Inputs/Button';

export const ConfirmButton = styled(ButtonBase)`
  margin-left: auto;
`;

export const HorizontalRule = styled.hr`
  margin: 32px -24px 16px -24px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.gray[4]};
`;

export const Badge = styled(BadgeBase)`
  margin-left: 16px;
`;

export const RadioText = styled(TextBase)`
  font-size: 0.875rem;
`;

export const Radio = styled(RadioBase)``;

export const RadioOption = styled(LabelBase)`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const Label = styled(LabelBase)`
  display: inline-block;
  color: #fff;
`;

export const InputGroup = styled(InputGroupBase)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Description = styled(TextBase)`
  margin-top: 12px;
  font-size: 0.875rem;
  margin-bottom: 24px;
  display: inline-block;
`;

export const Title = styled(HeadingBase)``;

export const Wrapper = styled.div`
  max-width: 720px;
  width: 100%;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 24px;
  transition: all 0.3s;

  @media only screen and (max-width: 1428px) {
    max-width: 648px;
  }

  @media only screen and (max-width: 1348px) {
    max-width: 720px;
  }
`;
