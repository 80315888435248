import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';
import SideModalBase from '@components/common/core/DataDisplay/SideModal';

export const Modal = styled(ModalBase)`
  width: 100%;
  max-width: 464px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  align-items: center;
`;

export const Heading = styled(TextBase)`
  color: #ffffff;
  font-size: 1.125rem;
`;

export const Description = styled(TextBase)`
  color: #ffffff;
`;

export const UpdateCredentialButton = styled(ButtonBase)`
  margin-left: auto;
  margin-top: 32px;
`;

export const SideModal = styled(SideModalBase)``;
