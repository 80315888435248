import { API_DOMAIN } from '@constants/api';
import { IAlterCredentialProps } from '@domain/interfaces/dashboard/AdminPanel/IChangeCredential';
import { ApiService } from '@services/api';

export class ChangeCredentialService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public alterCredential({
    data,
    integration_type,
    store_alias_id,
  }: IAlterCredentialProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/admin/users/stores/${store_alias_id}/integration-credentials/${integration_type}`,
      {
        ...data,
      },
    );
  }
}

const changeCredentialService = new ChangeCredentialService(API_DOMAIN);

export default changeCredentialService;
