import { ApiService } from '@services/api';
import { AUTH_API_DOMAIN } from '@constants/api';

import { IGoogleSignInData } from '@domain/interfaces/auth/google/IGoogleSignIn';

export class GoogleSignInService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public signIn(data: IGoogleSignInData): Promise<any> {
    return this.apiService.post('/auth/google/sign-in', { ...data });
  }
}

const googleSignInService = new GoogleSignInService(AUTH_API_DOMAIN);

export default googleSignInService;
