import React from 'react';

import { TaboolaCredentialsProvider } from '@helpers/hooks/common/integrations/marketing/taboola/useTaboolaCredentials';

import Wrapper from './Wrapper';

const Taboola: React.FC = () => {
  return (
    <TaboolaCredentialsProvider>
      <Wrapper />
    </TaboolaCredentialsProvider>
  );
};

export default Taboola;
