import styled from 'styled-components/macro';

import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import LabelBase from '@components/common/core/DataDisplay/Label';

export const Label = styled(LabelBase)`
  margin: unset;
  font-size: 1rem;
  margin-top: 4px;
  width: 100%;
  cursor: pointer;
`;

export const Checkbox = styled(CheckboxBase)``;

export const InputGroup = styled(InputGroupBase)`
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin: unset;
  transition: filter 0.3s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;
