import React from 'react';
import { useParams } from 'react-router-dom';

import { EProgressBarStep } from '@domain/enums/onboarding/progressBar/ESteps';
import { IParams } from '@domain/interfaces/IParams';

import Wrapper from '@components/Onboarding/TraditionalEcommerce/Integrations/Wrapper';
import SideBar from '@components/Onboarding/SideBar';

import * as S from './styles';

const Integrations: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();

  return (
    <S.Wrapper>
      <SideBar
        onboardingStep={EProgressBarStep.INTEGRATIONS}
        linkToGoBack={`/onboarding/${storeAliasId}/ecommerce/integrations`}
      />
      <Wrapper />
    </S.Wrapper>
  );
};

export default Integrations;
