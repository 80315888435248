import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 48px;
`;

export const Heading = styled(HeadingBase)`
  margin-bottom: 8px;
  color: #ffffff;

  strong {
    color: ${({ theme }) => theme.colors.green.default};
  }
`;

export const Description = styled(TextBase)``;

export const BenefitsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 32px;

  @media only screen and (max-width: 660px) {
    flex-direction: column;
  }
`;
