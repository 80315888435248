import styled from 'styled-components/macro';

import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';
import AvatarBase from '@components/common/core/DataDisplay/Avatar';
import TextBase from '@components/common/core/DataDisplay/Text';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 194px;
`;

export const Popover = styled(CustomPopoverBase)``;

export const PopoverArrow = styled(CustomPopoverBase.Arrow)`
  fill: ${({ theme }) => theme.colors.background[2]};
`;

export const PopoverTrigger = styled(CustomPopoverBase.Trigger)`
  width: 100%;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.gray[7]};
  border: 1px solid ${({ theme }) => theme.colors.gray[2]};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.gray[1]};
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  mark {
    color: ${({ theme }) => theme.colors.green.default};
    background: unset;
    margin-right: 6px;
    margin-left: 4px;
  }
`;

export const PopoverContent = styled(CustomPopoverBase.Content)`
  min-width: 291px;
  background-color: ${({ theme }) => theme.colors.background[2]};
  padding: 16px 0;
  box-sizing: border-box;
`;

export const CurrentStoreWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  box-sizing: border-box;
  margin: 16px;
  margin-top: unset;
`;

export const CurrentStoreAvatar = styled(AvatarBase)`
  background-color: ${({ theme }) => theme.colors.green.dark};
`;

export const CurrentStoreName = styled(TextBase)`
  color: ${({ theme }) => theme.colors.green.default};
`;

export const SelectStoresWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px;
`;

export const SelectOtherStoresText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const SelectAllStoresButton = styled.button`
  background-color: unset;
  padding: unset;
  border: unset;
  color: ${({ theme }) => theme.colors.green.default};
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
`;

export const StoresWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  max-height: 265px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[1]};
    border-radius: 25px;
    margin-bottom: 4px;
  }
`;

export const StoreRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[4]};
  padding: 12px 16px;
  box-sizing: border-box;

  &:first-of-type {
    padding-top: unset;
  }

  &:last-of-type {
    border-bottom: unset;
    padding-bottom: unset;
  }
`;

export const AvatarAndStoreNameWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`;

export const StoreAvatar = styled(AvatarBase)``;

export const StoreName = styled(TextBase)``;

export const StoreCheckBox = styled(CheckboxBase)``;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 28px;
  margin-left: auto;
  margin-top: 24px;
  margin-right: 16px;
`;

export const CancelButton = styled.button`
  background-color: unset;
  padding: unset;
  border: unset;
  color: #fff;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
`;

export const ApplyMultiStoreSelectButton = styled(ButtonBase)``;
