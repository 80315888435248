import React from 'react';

import { numberFormatter } from '@helpers/masks';
import { ICustomValueProps } from '@domain/interfaces/dashboard/FinancialDashboard/ICustomValue';
import PieChart from './PieChart';

import * as S from './styles';

const CustomValue: React.FC<ICustomValueProps> = ({ customValue }) => {
  return (
    <S.Wrapper>
      <S.ChartWrapper>
        <PieChart customValue={customValue} />
        <S.ChartPercentage>{`${numberFormatter(customValue.percentage, 0)}%`}</S.ChartPercentage>
      </S.ChartWrapper>

      <S.CustomValueDetails>
        <S.Tooltip>
          <S.Tooltip.Trigger>
            <S.CustomValueName>{customValue.name}</S.CustomValueName>
          </S.Tooltip.Trigger>

          <S.TooltipContent side="top" sideOffset={5}>
            <S.TooltipText>{customValue.name}</S.TooltipText>
            <S.TooltipArrow />
          </S.TooltipContent>
        </S.Tooltip>

        <S.CustomValueAmount>{`R$${numberFormatter(customValue.amount, 2)}`}</S.CustomValueAmount>
      </S.CustomValueDetails>
    </S.Wrapper>
  );
};

export default CustomValue;
