export const newWindow = (url: string, name: string, width: number, height: number): any => {
  const top = (window.screen.height - height) / 4;
  const left = (window.screen.width - width) / 2;

  return window.open(
    url,
    name,
    `toolbar=0,status=0,width=${width},height=${height},top=${top},left=${left}`,
  );
};
