import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const NextStepButton = styled(ButtonBase)`
  margin: 0 auto;
`;

export const InsightText = styled(TextBase)`
  font-size: 0.875rem;
`;

export const InsightIconWrapper = styled.div`
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: #24363f;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Insight = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const InsightsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContentTitle = styled(HeadingBase)`
  line-height: 120%;
  margin-bottom: 24px;
`;

export const Content = styled.div`
  max-width: 306px;
  width: 100%;
`;

export const Image = styled(ImageBase)`
  max-width: 280px;
`;

export const ImageAndContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-around;

  @media only screen and (max-width: 672px) {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
