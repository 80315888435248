export enum EAvatarType {
  ICON = 'icon',
  TEXT = 'text',
  PHOTO = 'photo',
}

export enum EAvatarSize {
  DEFAULT = '64px',
  MEDIUM = '48px',
  SMALL = '32px',
}
