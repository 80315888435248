import React from 'react';

import { ProfileProvider } from '@helpers/hooks/pages/dashboard/profile/useProfile';
import { MobileNotificationProvider } from '@helpers/hooks/pages/dashboard/profile/useMobileNotification';

import Wrapper from '@components/Dashboard/Profile/Wrapper';

const Profile: React.FC = () => {
  return (
    <ProfileProvider>
      <MobileNotificationProvider>
        <Wrapper />
      </MobileNotificationProvider>
    </ProfileProvider>
  );
};

export default Profile;
