import React from 'react';
import { CreditCard } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ESubscriptionRecurrence } from '@domain/enums/common/subscription/ESubscriptionRecurrence';
import { ICardTokenProps } from '@domain/interfaces/common/payment/IPayment';
import { IParams } from '@domain/interfaces/IParams';
import { IUpdateCreditCardModalProps } from '@domain/interfaces/dashboard/Subscription/IUpdateCreditCardModal';

import { useIugu } from '@helpers/hooks/useIugu';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { useToast } from '@helpers/hooks/useToast';
import { useConfig } from '@helpers/hooks/useConfig';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useDate } from '@helpers/hooks/useDate';
import { useActivateSubscription } from '@helpers/hooks/pages/dashboard/useActivateSubscription';
import { useTransaction } from '@helpers/hooks/common/store/subscription/useTransaction';

import { checkoutErrorMapper } from '@helpers/errors/subscription/checkoutMapper';
import { getCardIssuer } from '@helpers/subscription/payment';
import {
  cardNumberFormatter,
  cvvFormatter,
  expirationDateFormatter,
  numberFormatter,
} from '@helpers/masks';

import { paymentSchema } from '@helpers/validators/subscription/checkout/payment';

import { ALL_PLANS } from '@constants/plans';

import paymentMethodService from '@services/common/paymentMethod/paymentMethod';

import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const UpdateCreditCardModal: React.FC<IUpdateCreditCardModalProps> = ({ isOpen, toggle }) => {
  const theme = useTheme();
  const iugu = useIugu('32100AB23A5B464C964437C238C1844D');
  const { toast } = useToast();
  const { subscription, activeSubscription, mutateSubscription } = useStoreSubscription();
  const { storeAliasId } = useParams<IParams>();
  const { user, analytics } = useConfig();
  const { handleResyncData } = useSynchronization();
  const { utcToZonedTime, differenceInDays } = useDate();
  const { resetActivateSubscriptionProvider } = useActivateSubscription();
  const { mutateTransactions } = useTransaction();

  const selectedPlan = ALL_PLANS.find(
    plan =>
      plan.identifier === subscription?.plan?.identifier ||
      plan.quarterIdentifier === subscription?.plan?.identifier ||
      plan.semiAnualIdentifier === subscription?.plan?.identifier,
  );

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ mode: 'onBlur', reValidateMode: 'onChange', resolver: yupResolver(paymentSchema) });

  const numberRegister = register('number');
  const fullNameRegister = register('full_name');
  const expirationRegister = register('expiration');
  const verificationValueRegister = register('verification_value');

  const [isUpdatingPaymentInfo, setIsUpdatingPaymentInfo] = React.useState<boolean>(false);
  const [isCreatingSubscription, setIsCreatingSubscription] = React.useState<boolean>(false);

  const formRef = React.useRef<HTMLFormElement>(null);

  const trackTransactionError = React.useCallback(
    (providerError: string) => {
      analytics?.track(
        'Transaction Not Approved',
        {
          declined_reason: providerError,
          plan_name: selectedPlan?.name,
          plan_price: (selectedPlan as typeof ALL_PLANS[number])?.monthlyPrice,
          email: user?.email,
        },
        { context: { groupId: storeAliasId } },
      );
    },
    [storeAliasId, analytics, selectedPlan, user],
  );

  const subscribe = React.useCallback(
    async (cardToken: ICardTokenProps) => {
      setIsCreatingSubscription(true);
      resetActivateSubscriptionProvider();

      try {
        const previousSubscription = subscription;

        const isMonthlySubscription = subscription?.recurrence === ESubscriptionRecurrence.MONTHLY;

        const {
          data: paymentMethodResponseData,
        } = await paymentMethodService.getPaymentMethodPromise({ storeAliasId });

        if (paymentMethodResponseData.payment_method) {
          await paymentMethodService.deletePaymentMethod({ storeAliasId });
        }

        await paymentMethodService.createPaymentMethod({
          storeAliasId,
          data: { token: cardToken.id, is_default: Boolean(isMonthlySubscription) },
        });

        await activeSubscription();

        const lastSubscriptionDay =
          previousSubscription.canceled_at || previousSubscription.period_ended_at;

        const lastSubscriptionDayToDate = utcToZonedTime(lastSubscriptionDay);

        const difference = differenceInDays(lastSubscriptionDayToDate, new Date()) + 1;

        const daysToSync = difference < 30 ? difference : 30;

        handleResyncData({
          storeAliasId,
          daysToSync,
        });

        setIsCreatingSubscription(false);

        window.location.reload();
      } catch (error: any) {
        setIsCreatingSubscription(false);
        const message = checkoutErrorMapper(error, trackTransactionError);

        if (message === 'Subscription was not activated') {
          toast.info(
            'O seu pagamento está sendo processado. Iremos lhe informar assim que terminar o processamento.',
          );
          toggle();
          Promise.all([mutateSubscription(), mutateTransactions()]);
        } else {
          toast.error(message);
          throw new Error();
        }
      }
    },
    [
      storeAliasId,
      toast,
      trackTransactionError,
      activeSubscription,
      subscription,
      handleResyncData,
      differenceInDays,
      utcToZonedTime,
      resetActivateSubscriptionProvider,
      toggle,
      mutateSubscription,
      mutateTransactions,
    ],
  );

  const onSubmit = React.useCallback(
    async formData => {
      setIsUpdatingPaymentInfo(true);

      try {
        await iugu.setTestMode(true);
        const createdCardToken = await iugu.createPaymentToken(formRef.current);

        await subscribe(createdCardToken);

        setIsUpdatingPaymentInfo(false);
      } catch (error: any) {
        if (error?.errors?.adblock) {
          toast.error(
            'Desabilite o AdBlock e depois recarregue a página para poder continuar essa etapa.',
          );
        } else if (error?.errors?.record_invalid === 'Validation failed: Number is invalid') {
          toast.error('Número do cartão inválido.');
        } else if (error?.errors?.verification_value === 'is_invalid') {
          toast.error('CVV / CID inválidos');
        } else if (error?.errors?.last_name === 'is_invalid') {
          toast.error('Sobrenome do cartão inválido.');
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          setIsUpdatingPaymentInfo(false);
          throw error;
        }
      }
    },
    [iugu, subscribe, toast],
  );

  const onNumberChange = React.useCallback(
    event => {
      const formattedNumber = cardNumberFormatter(event.target.value);
      event.target.value = formattedNumber;
      numberRegister.onChange(event);
    },
    [numberRegister],
  );

  const onExpirationChange = React.useCallback(
    event => {
      const formattedExpiration = expirationDateFormatter(event.target.value);
      event.target.value = formattedExpiration;
      expirationRegister.onChange(event);
    },
    [expirationRegister],
  );

  const onVerificationValueChange = React.useCallback(
    event => {
      const number = getValues('number');
      const cardIssuer = getCardIssuer(number);
      let formattedVerificationValue = '';

      if (cardIssuer === 'American Express') {
        formattedVerificationValue = cvvFormatter(event.target.value, 4);
      } else {
        formattedVerificationValue = cvvFormatter(event.target.value);
      }

      event.target.value = formattedVerificationValue;
      verificationValueRegister.onChange(event);
    },
    [verificationValueRegister, getValues],
  );

  const isNumberError = Boolean(errors.number);
  const isFullNameError = Boolean(errors.full_name);
  const isExpirationError = Boolean(errors.expiration);
  const isVerificationValue = Boolean(errors.verification_value);

  const isMonthlySubscription = subscription?.recurrence === ESubscriptionRecurrence.MONTHLY;

  const isLoading = isUpdatingPaymentInfo || isCreatingSubscription;

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Header>
        <CreditCard size={36} color={theme.colors.green.default} weight="fill" />
        <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
          Reative a sua assinatura
        </S.Title>
      </S.Header>

      <S.Body>
        <Form innerRef={formRef} onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>Número do Cartão</S.Label>
            <S.Input
              {...numberRegister}
              type="text"
              placeholder="4111 1111 1111 1111"
              onChange={onNumberChange}
              data-iugu="number"
              data-openreplay-obscured
              isError={isNumberError}
            />
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Nome (Impresso no Cartão)</S.Label>
            <S.Input
              {...fullNameRegister}
              type="text"
              placeholder="YUNG BUDA"
              data-iugu="full_name"
              data-openreplay-obscured
              isError={isFullNameError}
            />
            {isFullNameError && <Text isErrorFeedback>{errors.full_name.message}</Text>}
          </S.InputGroup>

          <S.ExpirationDateAndSecurityCodeWrapper>
            <S.InputGroup>
              <S.Label>Validade</S.Label>
              <S.Input
                {...expirationRegister}
                type="text"
                placeholder="MM/AA"
                onChange={onExpirationChange}
                data-iugu="expiration"
                isError={isExpirationError}
              />
              {isExpirationError && <Text isErrorFeedback>{errors.expiration.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Cód. Segurança (CVV)</S.Label>
              <S.Input
                {...verificationValueRegister}
                type="text"
                placeholder="123"
                onChange={onVerificationValueChange}
                data-iugu="verification_value"
                data-openreplay-obscured
                isError={isVerificationValue}
              />
              {isVerificationValue && (
                <Text isErrorFeedback>{errors.verification_value.message}</Text>
              )}
            </S.InputGroup>
          </S.ExpirationDateAndSecurityCodeWrapper>

          <S.Description>
            Será feito a cobrança do{' '}
            <strong>
              valor pendente mais o valor do plano {subscription?.plan?.name} (R${' '}
              {numberFormatter(subscription?.plan?.amount || 0, 2)})
            </strong>
          </S.Description>

          <S.UpdateInfoButton isMonthlyRecurrence={isMonthlySubscription} isLoading={isLoading}>
            Reativar assinatura
          </S.UpdateInfoButton>
        </Form>
      </S.Body>
    </S.Modal>
  );
};

export default UpdateCreditCardModal;
