import React from 'react';

import { OtherGatewayIntegrationProvider } from '@helpers/hooks/pages/dashboard/integrationCenter/gateway/useOtherGatewayIntegration';

import Wrapper from '@components/Dashboard/IntegrationCenter/Sidemodal/Gateway/PredefinedGateways/DomPagamentos';

const Cloudfox: React.FC = () => {
  return (
    <OtherGatewayIntegrationProvider>
      <Wrapper />
    </OtherGatewayIntegrationProvider>
  );
};

export default Cloudfox;
