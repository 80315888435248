import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

export const Heading = styled(HeadingBase)`
  margin-bottom: 20px;
`;

export const MarketingWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;
