import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 7px;
  overflow: hidden;
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[5]};
  padding: 12px 28px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(TextBase)`
  line-height: 120%;
  color: #ffffff;
`;

export const Description = styled(TextBase)`
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const CustomValueBillingWrapper = styled.div`
  max-height: 267px;
  overflow-y: scroll;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    width: 4px;
    position: absolute;
  }

  ::-webkit-scrollbar:horizontal {
    height: 0;
    width: 4px;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.gray[4]};
    position: absolute;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[1]};
    border-radius: 25px;
    transition: all 0.3s;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray[1]};
    border-radius: 25px;
    transition: all 0.3s;
  }

  ::-webkit-scrollbar-thumb:hover {
    opacity: brightness(0.8);
  }
`;
