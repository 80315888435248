import React from 'react';

import { getEnvironment } from '@helpers/utils/environment';
import { useLocalStorage } from '@helpers/hooks/useLocalStorage';

import * as S from './styles';

const MarketingTooltip: React.FC = () => {
  const [showTooltip, setShowTooltip] = useLocalStorage(
    `@profitfy:${getEnvironment()}/showProductsRankingMarketingTooltip`,
    true,
  );

  const handleUnderstoodClick = React.useCallback(() => {
    setShowTooltip(false);
  }, [setShowTooltip]);

  return (
    <S.Popover open={showTooltip}>
      <S.Popover.Trigger>
        <S.HeaderText>Marketing</S.HeaderText>
      </S.Popover.Trigger>

      <S.PopoverContent side="top" sideOffset={5}>
        <S.DescriptionWrapper>
          <S.Description>
            Ao adicionar um custo de ADS manual, será contabilizado diretamente em seus gastos com
            marketing.
          </S.Description>

          <S.UnderstoodButton onClick={handleUnderstoodClick}>Entendi</S.UnderstoodButton>
        </S.DescriptionWrapper>

        <S.Popover.Arrow />
      </S.PopoverContent>
    </S.Popover>
  );
};

export default MarketingTooltip;
