import styled from 'styled-components/macro';

export const ChartWrapper = styled.div`
  width: 100%;
  height: 100%;

  .recharts-tooltip-wrapper {
    z-index: ${({ theme }) => theme.zIndex.firstLayer};
  }
`;
