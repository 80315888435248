import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresCpaValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresCpaValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresCpaValuesResponse => {
  const currentSelectedStoreCpaAmountValue =
    currentSelectedStoreFinancialDetailsReportData?.cpa?.amount;

  const storesCpaAmountValues = selectedStoresData?.map(
    value => value.financial_details_report?.cpa?.amount,
  );

  const cpaAmountValues = [...storesCpaAmountValues, currentSelectedStoreCpaAmountValue];

  const reducedCpaAmountValues = cpaAmountValues.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  });

  const currentSelectedStore = 1;
  const selectedStoresQuantity = selectedStoresData?.length;

  const storesQuantity = selectedStoresQuantity + currentSelectedStore;

  const finalResult = reducedCpaAmountValues / storesQuantity;

  return {
    cpa_amount: finalResult,
  };
};
