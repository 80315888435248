import React from 'react';

import { ITooltipContentProps } from '@domain/interfaces/dashboard/Orders/IOrdersLineItem';
import { numberFormatter } from '@helpers/masks';
import { useDate } from '@helpers/hooks/useDate';

import * as S from './styles';

const TooltipContent: React.FC<ITooltipContentProps> = ({ synchronization }) => {
  const { format } = useDate();

  const getCurrency = { BRL: 'R$', USD: '$' };

  const hasSynchronization = Boolean(synchronization?.id);

  const synchronizationCurrency = synchronization?.currency;
  const synchronizationAmount = synchronization?.amount;
  const synchronizationCurrencyAmount = synchronization?.currency_amount;
  const synchronizationUpdatedAt = synchronization?.updated_at;

  if (!hasSynchronization) {
    return (
      <S.Wrapper>
        <S.NoDeliveredOrderText>Esse pedido ainda não foi enviado.</S.NoDeliveredOrderText>
        <S.NoDeliveredOrderText>
          Após o envio será realizada a conciliação <br /> com o Dsers.
        </S.NoDeliveredOrderText>
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper>
      <S.TextWrapper>
        <S.DescriptionText>Custo do produto:</S.DescriptionText>
        <S.ValueText>{`
          ${getCurrency[synchronizationCurrency]}${numberFormatter(synchronizationAmount, 2)}
        `}</S.ValueText>
      </S.TextWrapper>

      <S.TextWrapper>
        <S.DescriptionText>Data de processamento:</S.DescriptionText>
        <S.ValueText>{format(new Date(synchronizationUpdatedAt), 'dd/MM/yyyy')}</S.ValueText>
      </S.TextWrapper>

      {synchronizationCurrencyAmount && synchronizationCurrencyAmount > 0 && (
        <S.TextWrapper>
          <S.DescriptionText>Cotação dólar com spread:</S.DescriptionText>
          <S.ValueText>{`$${numberFormatter(synchronizationCurrencyAmount, 2)}`}</S.ValueText>
        </S.TextWrapper>
      )}

      <S.TextWrapper>
        <S.DescriptionText>Total:</S.DescriptionText>
        <S.ValueText>
          {synchronizationCurrency === 'USD'
            ? `R$${numberFormatter(synchronizationAmount * synchronizationCurrencyAmount, 2)}`
            : `R$${numberFormatter(synchronizationAmount, 2)}`}
        </S.ValueText>
      </S.TextWrapper>

      <S.HorizontalRule />

      <S.FooterText>Esse pedido foi conciliado com o Dsers.</S.FooterText>
    </S.Wrapper>
  );
};

export default TooltipContent;
