import React from 'react';

import { Info } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import * as S from './styles';

const CogsInfo: React.FC = () => {
  const theme = useTheme();

  return (
    <S.Wrapper>
      <Info size={24} color={theme.colors.gray[4]} />
    </S.Wrapper>
  );
};

export default CogsInfo;
