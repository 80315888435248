import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.HeaderWrapper>
      <S.TitleWrapper>
        <Skeleton width="100%" height={28} />
      </S.TitleWrapper>

      <S.HeaderButtonsWrapper>
        <S.CategoryButtonWrapper>
          <Skeleton width="100%" height={29} />
        </S.CategoryButtonWrapper>

        <S.NewCustomValueButtonWrapper>
          <Skeleton width="100%" height={42} />
        </S.NewCustomValueButtonWrapper>
      </S.HeaderButtonsWrapper>
    </S.HeaderWrapper>
  );
};

export default SkeletonLoading;
