import React from 'react';
import { useTheme } from 'styled-components';
import { X as CloseIcon, Option } from 'phosphor-react';
import { useUpdateNodeInternals } from 'react-flow-renderer';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';

import { ISwitchNodeContent } from '@domain/interfaces/dashboard/Automations/Canvas/Node/SwitchNode/ISwitchNodeContent';
import { ISwitchNodeModalProps } from '@domain/interfaces/dashboard/Automations/Canvas/Node/SwitchNode/ISwitchNodeModal';

import { ETriggers } from '@domain/enums/dashboard/automations/ETriggers';

import AbandonedCartModal from './AbandonedCartModal';
import PixModal from './PixModal';
import BoletoModal from './BoletoModal';
import DeniedCardModal from './DeniedCardModal';
import UpdateFulfillmentModal from './UpdateFulfillmentModal';

import * as S from './styles';
import SimpleShipmentModal from './SimpleShipmentModal';

const SwitchModal: React.FC<ISwitchNodeModalProps> = ({ isOpen, toggle, rules: initialRules }) => {
  const { colors } = useTheme();
  const { selectedWorkflowTriggerType, setNodes, selectedNodeId } = useAutomation();
  const updateNodeInternals = useUpdateNodeInternals();

  const [rules, setRules] = React.useState<ISwitchNodeContent[]>(initialRules);

  const handleChangeRule = React.useCallback(
    (values: any, i: number) => {
      const arrayCopy = [...rules];
      arrayCopy[i] = values;
      setRules(arrayCopy);
    },
    [rules],
  );

  const handleChangeRules = React.useCallback(
    values => {
      const arrayCopy = rules.map(rule => {
        return {
          ...values,
          id: rule.id,
        };
      });

      setRules(arrayCopy);
    },
    [rules],
  );

  const handleCloseModal = React.useCallback(() => {
    setRules(initialRules);
    toggle();
  }, [toggle, initialRules]);

  const handleSaveRules = React.useCallback(() => {
    setNodes((nds: any) =>
      nds.map((node: any) => {
        if (node.id === selectedNodeId) {
          // it's important that you create a new object here
          // in order to notify react flow about the change
          node.data = {
            ...node.data,
            rules,
          };
        }

        return node;
      }),
    );

    updateNodeInternals(selectedNodeId as string);
    toggle();
  }, [selectedNodeId, setNodes, rules, updateNodeInternals, toggle]);

  React.useEffect(() => {
    setRules(initialRules);
  }, [initialRules]);

  if (!rules.length) {
    return <></>;
  }

  let ruleWithProblem = false;

  const isFirstRuleEmpty = rules[0].input === '';
  const isSecondRuleEmpty = rules[1].input === '';
  const isThirdRuleEmpty = rules[2].input === '';

  if (isFirstRuleEmpty && !isSecondRuleEmpty && !isThirdRuleEmpty) {
    ruleWithProblem = true;
  }

  if (!isFirstRuleEmpty && isSecondRuleEmpty && !isThirdRuleEmpty) {
    ruleWithProblem = true;
  }

  if (isFirstRuleEmpty && isSecondRuleEmpty && !isThirdRuleEmpty) {
    ruleWithProblem = true;
  }

  return (
    <S.Wrapper isOpen={isOpen} toggle={handleCloseModal}>
      <S.Row>
        <S.GroupTitle>
          <Option size={28} color={colors.gray[1]} />
          <S.Title>Configurar condição múltipla</S.Title>
        </S.GroupTitle>
        <S.CloseButton onClick={handleCloseModal}>
          <CloseIcon size={20} color={colors.gray[3]} />
        </S.CloseButton>
      </S.Row>

      {selectedWorkflowTriggerType === ETriggers.ABANDONED_CART && (
        <AbandonedCartModal
          rules={rules}
          handleChangeRule={handleChangeRule}
          handleChangeRules={handleChangeRules}
        />
      )}

      {selectedWorkflowTriggerType === ETriggers.PIX && (
        <PixModal
          rules={rules}
          handleChangeRule={handleChangeRule}
          handleChangeRules={handleChangeRules}
        />
      )}

      {selectedWorkflowTriggerType === ETriggers.BOLETO && (
        <BoletoModal
          rules={rules}
          handleChangeRule={handleChangeRule}
          handleChangeRules={handleChangeRules}
        />
      )}

      {selectedWorkflowTriggerType === ETriggers.REJECTED_PAYMENT && (
        <DeniedCardModal
          rules={rules}
          handleChangeRule={handleChangeRule}
          handleChangeRules={handleChangeRules}
        />
      )}

      {selectedWorkflowTriggerType === ETriggers.UPDATED_FULFILLMENT && (
        <UpdateFulfillmentModal
          rules={rules}
          handleChangeRule={handleChangeRule}
          handleChangeRules={handleChangeRules}
        />
      )}

      {selectedWorkflowTriggerType === ETriggers.SIMPLE_SHIPMENT && (
        <SimpleShipmentModal
          rules={rules}
          handleChangeRule={handleChangeRule}
          handleChangeRules={handleChangeRules}
        />
      )}

      <S.SaveButtonWrapper onClick={!ruleWithProblem ? handleSaveRules : undefined}>
        <S.SaveButton>Salvar configuração</S.SaveButton>
      </S.SaveButtonWrapper>
    </S.Wrapper>
  );
};

export default SwitchModal;
