import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import { useTheme } from 'styled-components/macro';

import * as S from './styles';

const Trigger: React.FC<
  Accordion.AccordionTriggerProps & React.RefAttributes<HTMLButtonElement>
> = ({ children, ...rest }) => {
  const theme = useTheme();

  return (
    <S.Wrapper>
      <S.Trigger {...rest}>
        {children}
        <S.Chevron size={20} color={theme.colors.font} />
      </S.Trigger>
    </S.Wrapper>
  );
};

export default Trigger;
