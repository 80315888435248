import * as yup from 'yup';

export const passwordSchema = yup.object().shape({
  current_password: yup
    .string()
    .required('Este campo é obrigatório')
    .min(6, 'Senha deve conter no mínimo 6 caracteres'),
  new_password: yup
    .string()
    .required('Este campo é obrigatório')
    .min(6, 'Senha deve conter no mínimo 6 caracteres'),
  confirm_new_password: yup
    .string()
    .oneOf(
      [yup.ref('new_password'), undefined],
      'A senha está diferente da senha informada no campo anterior.',
    ),
});
