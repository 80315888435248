import { PROVIDER_CODE_ERRORS } from '@constants/errors/subscription';

export const translateSubscriptionProviderCodeError = (code: string): string => {
  const transactionErrorMessage = PROVIDER_CODE_ERRORS[code];

  if (transactionErrorMessage) return transactionErrorMessage;

  return '';
};

export const checkoutErrorMapper = (
  error: Record<any, any>,
  trackTransactionError: (providerError: string) => void,
): string => {
  const errorMessage = error?.response?.data?.integration_message;

  if (!errorMessage) return error?.response?.data?.message;

  const errorCode = errorMessage.split(':').pop();

  const errorCodeMessage = translateSubscriptionProviderCodeError(errorCode);

  trackTransactionError(errorCodeMessage || error?.response?.data?.integration_message);

  return errorCodeMessage || error?.response?.data?.integration_message;
};
