import styled from 'styled-components/macro';

import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';

export const PopoverArrow = styled(CustomPopoverBase.Arrow)`
  fill: ${({ theme }) => theme.colors.black.light};
`;

export const PopoverContent = styled(CustomPopoverBase.Content)`
  background-color: ${({ theme }) => theme.colors.black.light};
  border-radius: 10px;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 258px;
`;
