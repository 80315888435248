import styled from 'styled-components/macro';

import SideModalBase from '@components/common/core/DataDisplay/SideModal';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import DayPickerBase from '@components/common/core/Utils/DayPicker';

export const CheckboxGroup = styled(InputGroupBase)`
  flex-direction: row;
  align-items: center;
  gap: 8px;

  & > label {
    margin: unset;
    margin-top: 1px;
    cursor: pointer;
  }
`;

export const DayPicker = styled(DayPickerBase)``;

export const Checkbox = styled(CheckboxBase)``;

export const Option = styled(OptionBase)``;

export const Select = styled(SelectBase)``;

export const Input = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Body = styled.div`
  padding-top: 24px;
`;

export const Title = styled(HeadingBase)``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[2]};
`;

export const SideModal = styled(SideModalBase)``;
