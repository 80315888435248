import React from 'react';
import { Bling as BlingIcon } from '@profitfy/pakkun-icons';
import { useHistory, useParams } from 'react-router-dom';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { EEcommerceProvider } from '@domain/enums/common/integrations/EEcommerceProvider'; // TODO: change to ecommerce
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useEcommerce } from '@helpers/hooks/pages/onboarding/useEcommerce';
import { useDate } from '@helpers/hooks/useDate';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import { newWindow } from '@helpers/hooks/usePopup';

import { ECOMMERCE_PROVIDERS } from '@constants/common/integrations';

import integrationCenterService from '@services/pages/dashboard/integrationCenter/integrationCenter';
import blingAuthService from '@services/pages/dashboard/integrationCenter/ecommerce/bling/blingAuth';

import NextStep from '@components/Onboarding/NextStep';

import * as S from './styles';

const Bling: React.FC = () => {
  const history = useHistory();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { selectedMarketings, selectedGateways } = useEcommerce();
  const { addSynchronization } = useSynchronization();
  const { format, utcToZonedTime } = useDate();
  const { sendFinancialData, handleStoreBonus } = useOnboarding();

  const [isIntegrating, setIsIntegrating] = React.useState<boolean>(false);
  const [userWindow, setUserWindow] = React.useState<Window | undefined>(undefined);

  const nextStep = React.useCallback(async () => {
    setIsIntegrating(true);

    try {
      const { data: blingData } = await blingAuthService.generateBlingUrl({ storeAliasId });

      const window = newWindow(blingData?.auth_url, 'Bling', 1280, 720);

      setUserWindow(window);
    } catch (error: any) {
      setIsIntegrating(false);
      toast.error(error?.response?.data?.message);
    }
  }, [toast, storeAliasId]);

  const handleWindowClose = React.useCallback(async () => {
    const { data } = await integrationCenterService.getStoreIntegrations({ storeAliasId });

    const hasBlingCredential = data?.store_integrations?.store_front?.has_bling_credential;

    if (hasBlingCredential) {
      const foundBlingIntegration = ECOMMERCE_PROVIDERS.find(
        integration => integration.identifier === EEcommerceProvider.BLING,
      ) as typeof ECOMMERCE_PROVIDERS[number];

      const { generateSyncRequest } = foundBlingIntegration;

      const params = `?start_date=${format(new Date(2000, 1, 1), 'yyyy-MM-dd')}&end_date=${format(
        utcToZonedTime(new Date()),
        'yyyy-MM-dd',
      )}`;

      addSynchronization({
        name: 'Bling',
        type: ESynchronizationType.STORE_PRODUCT,
        label: 'Produtos',
        dependencies: [],
        storeAliasId,
        config: {
          isOnboardingSynchronization: true,
          showNotification: false,
        },
        request: generateSyncRequest(ESynchronizationType.STORE_PRODUCT, storeAliasId, params),
      });

      sendFinancialData(storeAliasId);

      setIsIntegrating(false);

      if (!selectedGateways.length && !selectedMarketings.length) {
        handleStoreBonus();
        return;
      }

      if (selectedGateways.length) {
        history.push(`/onboarding/${storeAliasId}/ecommerce/integrations/gateways`);
      }

      if (!selectedGateways.length && selectedMarketings.length) {
        history.push(`/onboarding/${storeAliasId}/ecommerce/integrations/marketing`);
      }
    } else {
      setIsIntegrating(false);
    }
  }, [
    addSynchronization,
    format,
    history,
    selectedMarketings,
    storeAliasId,
    utcToZonedTime,
    sendFinancialData,
    handleStoreBonus,
    selectedGateways,
  ]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (userWindow?.closed) {
        clearInterval(interval);
        handleWindowClose();
      }
    }, 1000);
  }, [userWindow, handleWindowClose]);

  return (
    <S.Wrapper>
      <S.FormWrapper>
        <S.Header>
          <S.Title>Integre com sua plataforma de E-commerce</S.Title>
        </S.Header>

        <S.IntegrationTitle>
          <BlingIcon size={36} />

          <S.IntegrationTitleText fontWeight={EHeadingWeight.REGULAR}>
            Integração com Bling
          </S.IntegrationTitleText>
        </S.IntegrationTitle>

        <S.Body>
          <S.Text>
            Para continuar com a integração, vá para o próximo passo e realize a integração com o
            Bling
          </S.Text>
        </S.Body>

        <NextStep onClick={nextStep} isLoading={isIntegrating} disabled={isIntegrating}>
          Próximo passo
        </NextStep>
      </S.FormWrapper>

      <S.TutorialWrapper>
        <S.TutorialImage
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/onboarding/images/content/onboarding/onboarding-integration-tutorial.svg"
          alt="Profitfy.me Integration Tutorial"
        />
        <S.TutorialTitle>Tutorial de integração</S.TutorialTitle>
        <S.TutorialContent>
          Para realizar a integração você precisará clicar em próximo passo
        </S.TutorialContent>
        <S.TutorialLink
          href="https://ajuda.profitfy.me/pt-BR/articles/8076401-como-integrar-com-Bling"
          target="_blank"
        >
          Veja o tutorial de integração clicando aqui!
        </S.TutorialLink>
      </S.TutorialWrapper>
    </S.Wrapper>
  );
};

export default Bling;
