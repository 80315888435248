import styled from 'styled-components/macro';

import SideModalBase from '@components/common/core/DataDisplay/SideModal';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import CustomDatePickerBase from '@components/common/core/Utils/CustomDatePicker';
import TextBase from '@components/common/core/DataDisplay/Text';
import LabelBase from '@components/common/core/DataDisplay/Label';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import TextFieldBase from '@components/common/core/Inputs/TextField';

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.green.default};
  text-decoration: underline;
`;

export const CustomDatePicker = styled(CustomDatePickerBase)``;

export const ProductName = styled(HeadingBase)`
  margin-bottom: 24px;
`;

export const Body = styled.div`
  padding-top: 40px;
  padding-bottom: 96px;
`;

export const Title = styled(HeadingBase)``;

export const Header = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Sidemodal = styled(SideModalBase)``;

export const FunctionalityDescriptionText = styled(TextBase)``;

export const FunctionalityDescriptionHeading = styled(HeadingBase)`
  margin-bottom: 8px;
  margin-top: 30px;
`;

export const FunctionalityDescriptionWrapper = styled.div`
  margin-bottom: 30px;
`;

export const SwitchLabel = styled(LabelBase)`
  width: fit-content;
  margin: 0;
`;
export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 36px;
`;
