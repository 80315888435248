import React from 'react';

import { EBadgeColors } from '@domain/enums/components/EBadge';
import { IProfileOptionProps } from '@domain/interfaces/downgrade/IDowngrade';

import { useDowngrade } from '@helpers/hooks/common/store/subscription/useDowngrade';

import * as S from './styles';

const ProfileOption: React.FC<IProfileOptionProps> = ({ adSenseCredential }) => {
  const { selectedFacebookProfiles, handleSelectedFacebookProfiles } = useDowngrade();

  const onChange = React.useCallback(
    event => {
      if (event.target) return;

      const facebookProfile = event.value;

      const foundFacebookProfile = selectedFacebookProfiles.find(
        profile => profile.alias_id === facebookProfile,
      );

      if (foundFacebookProfile) {
        const filteredFacebookProfiles = selectedFacebookProfiles.filter(
          profile => profile.alias_id !== facebookProfile,
        );
        handleSelectedFacebookProfiles(filteredFacebookProfiles);
      } else {
        handleSelectedFacebookProfiles([...selectedFacebookProfiles, adSenseCredential]);
      }
    },
    [adSenseCredential, selectedFacebookProfiles, handleSelectedFacebookProfiles],
  );

  const getBadgeColor = React.useCallback(() => {
    if (adSenseCredential.is_blocked_by_downgrade) return EBadgeColors.YELLOW;

    if (adSenseCredential.is_operational) return EBadgeColors.PRIMARY;

    return EBadgeColors.RED;
  }, [adSenseCredential]);

  const getStatusName = React.useCallback(() => {
    if (adSenseCredential.is_blocked_by_downgrade) return 'Inativo';

    if (adSenseCredential.is_operational) return 'Operacional';

    return 'Não Operacional';
  }, [adSenseCredential]);

  const foundFacebookProfile = selectedFacebookProfiles.find(
    profile => profile.alias_id === adSenseCredential.alias_id,
  );

  return (
    <S.ProfileOptionGroup>
      <S.Option htmlFor={`option-${adSenseCredential.alias_id}`}>
        <S.ProfileName>{adSenseCredential.name}</S.ProfileName>
        <S.ProfileStatus color={getBadgeColor()}>{getStatusName()}</S.ProfileStatus>
      </S.Option>
      <S.Checkbox
        id={`option-${adSenseCredential.alias_id}`}
        value={adSenseCredential.alias_id}
        onChange={onChange}
        checked={Boolean(foundFacebookProfile)}
      />
    </S.ProfileOptionGroup>
  );
};

export default ProfileOption;
