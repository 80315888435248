import React from 'react';
import { Calendar } from '@profitfy/pakkun-icons';
import { useDate } from '@helpers/hooks/useDate';
import { useParams } from 'react-router-dom';

import { useProductCostDetails } from '@helpers/hooks/pages/dashboard/productCostDetails/productCostDetails';
import { EHeadingWeight } from '@domain/enums/components/EHeading';
import Save from '@components/common/core/DataDisplay/SideModal/Save';
import { useToast } from '@helpers/hooks/useToast';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import {
  ESynchronizationRequestType,
  ESynchronizationType,
} from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';
import { LONG_API_DOMAIN } from '@constants/api';
import { getEnvironment } from '@helpers/utils/environment';

import * as S from './styles';

const SyncPastCogsSidemodal: React.FC = () => {
  const {
    isSyncPastCogsSidemodalOpen,
    handleSyncPastCogsSidemodalOpen,
    product,
    isLoadingProduct,
    isValidatingProduct,
    savedVariants,
    costToApplyAll,
    currencyToApplyAll,
  } = useProductCostDetails();
  const hasEditedAll = costToApplyAll || currencyToApplyAll;

  const { utcToZonedTime, format } = useDate();
  const { toast } = useToast();
  const { addSynchronization } = useSynchronization();
  const { storeAliasId } = useParams<IParams>();

  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [period, setPeriod] = React.useState({
    startDate: utcToZonedTime(new Date()),
    endDate: utcToZonedTime(new Date()),
  });

  const handleOnSave = React.useCallback(async () => {
    setIsSaving(true);

    try {
      const filteredSavedVariants = savedVariants.map(variant => {
        return variant.id;
      });

      const params = `?start_date=${format(
        utcToZonedTime(period.startDate),
        'yyyy-MM-dd',
      )}&end_date=${format(utcToZonedTime(period.endDate), 'yyyy-MM-dd')}`;

      addSynchronization({
        name: 'Custo dos Produtos',
        type: ESynchronizationType.STORE_ORDER,
        label: 'Pedidos',
        dependencies: [ESynchronizationType.STORE_PRODUCT],
        storeAliasId,
        config: {
          isOnboardingSynchronization: false,
          showNotification: true,
        },
        request: {
          type: ESynchronizationRequestType.POST,
          domain: LONG_API_DOMAIN,
          api: `/${getEnvironment()}/api/v1/users/stores/${storeAliasId}/orders/cogs/sync`,
          requestParams: params,
          requestBody: {
            scope: hasEditedAll ? 'product' : 'variant',
            product_id: hasEditedAll ? product.id : undefined,
            variant_ids: hasEditedAll ? [] : filteredSavedVariants,
          },
        },
      });

      handleSyncPastCogsSidemodalOpen();

      setIsSaving(false);
    } catch (error: any) {
      setIsSaving(false);
      toast.error(error?.response?.data?.message);
    }
  }, [
    savedVariants,
    toast,
    addSynchronization,
    format,
    utcToZonedTime,
    period,
    storeAliasId,
    handleSyncPastCogsSidemodalOpen,
    hasEditedAll,
    product,
  ]);

  const maxDate = utcToZonedTime(new Date());

  return (
    <S.Sidemodal isOpen={isSyncPastCogsSidemodalOpen} toggle={handleSyncPastCogsSidemodalOpen}>
      <S.Header>
        <Calendar size={32} outline />
        <S.Title>Alterar período</S.Title>
      </S.Header>
      {isLoadingProduct || isValidatingProduct ? (
        <div>Loading...</div>
      ) : (
        <S.Body>
          <S.ProductName fontWeight={EHeadingWeight.REGULAR}>{product.name}</S.ProductName>
          <S.CustomDatePicker period={period} setPeriod={setPeriod} maxDate={maxDate} />

          <Save
            cancelContent="Cancelar"
            saveContent="Salvar alterações"
            isSubmitting={isSaving}
            isSaveButtonDisabled={isSaving}
            isDeleteButtonDisabled={isSaving}
            type="button"
            onSave={handleOnSave}
          />
        </S.Body>
      )}
    </S.Sidemodal>
  );
};

export default SyncPastCogsSidemodal;
