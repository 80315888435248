import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import BadgeBase from '@components/common/core/DataDisplay/Badge';
import CheckboxBase from '@components/common/core/Inputs/Checkbox';
import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';

export const CustomPopover = styled(CustomPopoverBase)``;

export const CustomPopoverContent = styled(CustomPopoverBase.Content)`
  background-color: ${({ theme }) => theme.colors.black.light};
  padding: 16px 20px;
  border-radius: 5px;
  width: 100%;
  max-width: 269px;
  padding: 16px 20px;
  box-sizing: border-box;
`;

export const CustomPopoverArrow = styled(CustomPopover.Arrow)`
  fill: ${({ theme }) => theme.colors.black.light};
`;

export const DeleteSelectedGroupsButton = styled.button`
  border: unset;
  padding: unset;
  background-color: unset;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.danger.light};
  display: flex;
  align-items: center;
  gap: 12px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const UpdateGroupPermissionsButton = styled.button`
  border: unset;
  padding: unset;
  background-color: unset;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[1]};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const CheckboxWrapper = styled.div`
  width: fit-content;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.gray[7]};
`;

export const Checkbox = styled(CheckboxBase)``;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Heading = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.white.default};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const ButtonTrigger = styled(CustomPopoverBase.Trigger)`
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 5px;
  min-width: 102px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 120%;
  color: #fff;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const CreateNewGroupButton = styled.button`
  width: 100%;
  min-width: 135px;
  height: 36px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Table = styled(TableBase)`
  width: 100%;

  thead > tr > th:first-child {
    padding: 10px 0 10px 24px;
    width: 48px;
  }

  thead > tr > th:last-child {
    padding-left: 0;
  }

  tr td:first-child {
    padding: 10px 0 10px 24px;
  }

  tr td:last-child {
    padding-left: 0;
  }
`;

export const TableDataContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
`;

export const GroupName = styled(TextBase)``;

export const Badge = styled(BadgeBase)``;
