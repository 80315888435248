import { v4 } from 'uuid';

import {
  EDeniedCardType,
  EDeniedCardProductOptions,
} from '@domain/enums/dashboard/automations/node/conditionalNode/EDeniedCard';

export const DEFAULT_REJECTED_PAYMENT_CONTENT = {
  id: v4(),
  type: EDeniedCardType.PRODUCT_NAME,
  options: EDeniedCardProductOptions.CONTAINS,
  input: '',
};
