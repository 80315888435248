import React from 'react';
import { Pen, Cancel } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import { EBadgeColors } from '@domain/enums/components/EBadge';
import { EHeadAlign } from '@domain/enums/components/ETable';
import { useCustomValuesOut } from '@helpers/hooks/pages/dashboard/customValues/useCustomValuesOut';
import { IRowProps } from '@domain/interfaces/dashboard/CustomValues/IRow';
import { ICustomValuesCategory } from '@domain/interfaces/dashboard/CustomValuesCategories/ICustomValuesCategories';
import { useDate } from '@helpers/hooks/useDate';
import { numberFormatter } from '@helpers/masks';
import { getFrequency, getFrequencyName } from '@helpers/dashboard/customValue/customValue';
import DeleteModal from '@components/common/core/Utils/DeleteModal';
import { getFormattedCurrency } from '@helpers/utils/common/currency';

import * as S from './styles';

const Row: React.FC<IRowProps> = ({ customValue }) => {
  const {
    handleEditCustomValueSidemodalOpen,
    findCustomValueCategoryData,
    handleCustomValueToEdit,
    isDeletingCustomValue,
    deleteCustomValue,
    selectedCustomValueChecked,
    handleSelectedCustomValueChecked,
  } = useCustomValuesOut();
  const { format, utcToZonedTime } = useDate();
  const theme: any = useTheme();

  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const [category, setCategory] = React.useState<ICustomValuesCategory | undefined>(undefined);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);

  const handlePopoverOpen = React.useCallback(() => {
    setIsPopoverOpen(!isPopoverOpen);
  }, [isPopoverOpen]);

  const handleDeleteModalOpen = React.useCallback(() => setIsDeleteModalOpen(!isDeleteModalOpen), [
    isDeleteModalOpen,
  ]);

  const handleIsHovering = React.useCallback(status => setIsHovering(status), []);

  const handleCheckbox = React.useCallback(
    status => {
      if (status.checked) {
        handleSelectedCustomValueChecked(customValue);
      } else {
        handleSelectedCustomValueChecked(undefined);
      }
    },
    [customValue, handleSelectedCustomValueChecked],
  );

  const onDeleteClick = React.useCallback(() => {
    setIsPopoverOpen(false);
    handleDeleteModalOpen();
  }, [handleDeleteModalOpen]);

  const onEditClick = React.useCallback(() => {
    handleCustomValueToEdit(customValue);
    setIsPopoverOpen(false);
    handleEditCustomValueSidemodalOpen();
  }, [handleEditCustomValueSidemodalOpen, handleCustomValueToEdit, customValue]);

  const onDelete = React.useCallback(async () => {
    await deleteCustomValue(customValue.alias_id);
  }, [customValue, deleteCustomValue]);

  const getStatusText = React.useCallback(() => {
    if (customValue.is_active) return 'Ativo';

    if (customValue.period === 'ONCE') return 'Concluído';

    return 'Pausado';
  }, [customValue]);

  const getStatusColor = React.useCallback(() => {
    if (customValue.is_active || customValue.period === 'ONCE') return EBadgeColors.GREEN;

    return EBadgeColors.RED;
  }, [customValue]);

  React.useEffect(() => {
    const foundCategory = findCustomValueCategoryData(customValue);

    setCategory(foundCategory);
  }, [customValue, findCustomValueCategoryData]);

  return (
    <S.Row
      onMouseEnter={() => handleIsHovering(true)}
      onMouseLeave={() => handleIsHovering(false)}
      borderBottom
    >
      <TableBase.Data>
        <S.Checkbox
          checked={selectedCustomValueChecked?.alias_id === customValue.alias_id}
          onChange={handleCheckbox}
        />
      </TableBase.Data>

      <TableBase.Data>
        <S.DescriptionWrapper>
          <S.Tooltip>
            <S.Tooltip.Trigger>
              <S.DescriptionText data-tip data-for={`description-tooltip-${customValue.alias_id}`}>
                <S.DescriptionTextWrapper>{customValue.description}</S.DescriptionTextWrapper>
              </S.DescriptionText>
            </S.Tooltip.Trigger>

            <S.Tooltip.Content sideOffset={5} side="top">
              <S.TooltipDescription>{customValue.description}</S.TooltipDescription>
              <S.Tooltip.Arrow />
            </S.Tooltip.Content>
          </S.Tooltip>
          <S.CategoryWrapper>
            <S.CategoryBullet color={category?.hex_color || '#fff'} />
            <S.CategoryName>{category?.name}</S.CategoryName>
          </S.CategoryWrapper>
        </S.DescriptionWrapper>
      </TableBase.Data>

      <TableBase.Data align={EHeadAlign.CENTER}>
        <S.Text>{format(utcToZonedTime(customValue.start_date), 'dd/MM/yyyy')}</S.Text>
      </TableBase.Data>

      <TableBase.Data align={EHeadAlign.CENTER}>
        <S.Text>
          {customValue.end_date ? format(utcToZonedTime(customValue.end_date), 'dd/MM/yyyy') : '-'}
        </S.Text>
      </TableBase.Data>

      <TableBase.Data align={EHeadAlign.RIGHT}>
        <S.Text>{`${getFormattedCurrency(customValue.currency)}${numberFormatter(
          customValue.amount,
          2,
        )}`}</S.Text>
      </TableBase.Data>

      <TableBase.Data align={EHeadAlign.CENTER}>
        <S.BadgeWrapper>
          <S.Badge color={getFrequency[customValue.period]} secondary>
            {getFrequencyName[customValue.period]}
          </S.Badge>
        </S.BadgeWrapper>
      </TableBase.Data>

      <TableBase.Data align={EHeadAlign.CENTER}>
        <S.BadgeWrapper>
          <S.Badge color={getStatusColor()} secondary>
            {getStatusText()}
          </S.Badge>
        </S.BadgeWrapper>
      </TableBase.Data>

      <TableBase.Data>
        <S.Popover open={isPopoverOpen} onOpenChange={handlePopoverOpen}>
          <S.Popover.Trigger>
            <S.MoreOptionsWrapper isHovering={isHovering}>
              <S.OptionDot />
              <S.OptionDot />
              <S.OptionDot />
            </S.MoreOptionsWrapper>
          </S.Popover.Trigger>

          <S.Popover.Content sideOffset={5}>
            <S.OptionsWrapper>
              <S.ActionButton onClick={onEditClick}>
                <Pen outline size={16} color={theme.colors.font} />
                Editar
              </S.ActionButton>
              <S.ActionButton onClick={onDeleteClick} isDeleteButton>
                <Cancel outline size={16} color={theme.colors.danger.default} />
                Excluir
              </S.ActionButton>
            </S.OptionsWrapper>
            <S.Popover.Arrow />
          </S.Popover.Content>
        </S.Popover>

        <DeleteModal
          content="Deseja realmente excluir o valor adicional? Essa ação é irreversível!"
          isLoading={isDeletingCustomValue}
          isOpen={isDeleteModalOpen}
          onDelete={onDelete}
          title="Excluir valor adicional"
          toggle={handleDeleteModalOpen}
          shouldWriteDelete
        />
      </TableBase.Data>
    </S.Row>
  );
};

export default Row;
