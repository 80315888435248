import React from 'react';
import { Evermart as EvermartIcon, Copy } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { EInfoProductProvider } from '@domain/enums/common/integrations/EInfoProductProvider';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { evermartSchema } from '@helpers/validators/dashboard/integrationCenter/infoProduct/evermart';

import evermartService from '@services/pages/dashboard/integrationCenter/infoProduct/platforms/evermartAuth';

import ActiveIntegrationFooter from '@components/Dashboard/IntegrationCenter/Sidemodal/ActiveIntegrationFooter';
import Text from '@components/common/core/DataDisplay/Text';

import EvermartConfig from './EvermartConfig';
import SkeletonLoading from './SkeletonLoading';
import ActiveIntegration from './ActiveIntegration';

import * as S from './styles';

const Evermart: React.FC = () => {
  const theme = useTheme();

  const { toast } = useToast();

  const { storeAliasId } = useParams<IParams>();

  const { storeIntegrations, mutateStore } = useStoreConfig();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(evermartSchema),
  });

  const [isCreatingCredential, setIsCreatingCredential] = React.useState<boolean>(false);
  const [isURLCopied, setIsURLCopied] = React.useState<boolean>(false);
  const [hasEvermartCredential, setHasEvermartCredential] = React.useState<boolean>(false);
  const [isGettingCredential, setIsGettingCredential] = React.useState<boolean>(false);
  const [credentialAliasId, setCredentialAliasId] = React.useState<string>('');
  const [webhookURL, setWebhookURL] = React.useState<string>('');
  const [isGettingWebhookURL, setIsGettingWebhookURL] = React.useState<boolean>(false);
  const [accountIdValue, setAccessTokenValue] = React.useState<string>('');

  const hasEvermartIntegration = Boolean(
    storeIntegrations?.store_front.find(
      integration => integration.name === EInfoProductProvider.EVERMART && integration.is_active,
    ),
  );

  const accountIdInputRegister = register('account_id');

  const onAccountIdChange = React.useCallback(
    event => {
      accountIdInputRegister.onChange(event);

      setAccessTokenValue(event.target.value);
    },
    [accountIdInputRegister],
  );

  const handleCopyURL = React.useCallback(() => {
    navigator.clipboard.writeText(webhookURL);
    setIsURLCopied(true);
    toast.success('URL copiada com sucesso.');
  }, [toast, webhookURL]);

  const onSubmit = React.useCallback(
    async formData => {
      setIsCreatingCredential(true);

      try {
        await evermartService.createCredential({
          storeAliasId,
          data: { provider_id: formData.account_id },
        });

        toast.success('Credencial criada com sucesso.');

        mutateStore();

        setIsCreatingCredential(false);
      } catch (error: any) {
        toast.error(getTranslatedIntegrationsError(error));
        setIsCreatingCredential(false);
      }
    },
    [mutateStore, storeAliasId, toast],
  );

  const getEvermartCredential = React.useCallback(async () => {
    setIsGettingCredential(true);

    try {
      const { data } = await evermartService.getCredential({ storeAliasId });

      if (data?.evermart_credential.is_active) {
        setHasEvermartCredential(true);
      }

      setCredentialAliasId(data?.evermart_credential.alias_id);

      setIsGettingCredential(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsGettingCredential(false);
    } finally {
      setIsGettingCredential(false);
    }
  }, [toast, storeAliasId]);

  const generateWebhookURL = React.useCallback(async () => {
    setIsGettingWebhookURL(true);

    try {
      const { data } = await evermartService.generateWebhookURL({ storeAliasId });

      setWebhookURL(data?.webhook_url);
      setIsGettingWebhookURL(false);
    } catch (error: any) {
      setIsGettingWebhookURL(false);
      toast.error(error?.response?.data?.message);
    }
  }, [toast, storeAliasId]);

  React.useEffect(() => {
    if (hasEvermartIntegration) {
      getEvermartCredential();
    } else {
      generateWebhookURL();
    }
  }, [getEvermartCredential, generateWebhookURL, hasEvermartIntegration]);

  if (isGettingCredential || isGettingWebhookURL) {
    return <SkeletonLoading />;
  }

  if (hasEvermartIntegration && !hasEvermartCredential) {
    return <ActiveIntegration credentialAliasId={credentialAliasId} />;
  }

  if (hasEvermartCredential) {
    return <EvermartConfig credentialAliasId={credentialAliasId} />;
  }

  return (
    <S.ContentWrapper>
      <S.HeadingWrapper>
        <EvermartIcon size={52} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com Evermart
        </S.Heading>
      </S.HeadingWrapper>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>
        <S.FunctionalityDescriptionText>
          A integração com sua plataforma de info-produto serve para puxarmos seus dados de
          plataforma aumentando a exatidão dos seus dados.
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.TutorialWrapper>
          <S.TutotialTitle weight={ETextWeight.BOLD}>Tutorial de integração</S.TutotialTitle>

          <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
            1 - Faça login na Evermart.
          </S.TutotialStep>

          <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
            2 - Copie a URL no campo abaixo.
          </S.TutotialStep>

          <S.WebhookURLWrapper onClick={handleCopyURL} type="button">
            <S.WebhookURL weight={ETextWeight.MEDIUM}>{webhookURL}</S.WebhookURL>
            <S.IconWrapper>
              <Copy size={18} color={theme.colors.gray[1]} outline={!isURLCopied} />
            </S.IconWrapper>
          </S.WebhookURLWrapper>

          <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
            3 -{' '}
            <S.Link
              href="https://dashboard.mycheckout.com.br/integrations/webhooks/new"
              target="_blank"
            >
              Clique aqui e vá para a página de webhooks da Evermart.
            </S.Link>
          </S.TutotialStep>

          <S.StepsWrapper>
            <S.StepWrapper>
              <S.StepBullet />
              <S.StepText size={ETextSize.B5}>
                Cole a URL copiada no campo &quot;Enviar para URL&quot;
              </S.StepText>
            </S.StepWrapper>
            <S.StepWrapper>
              <S.StepBullet />
              <S.StepText size={ETextSize.B5}>Clique em &quot;Salvar&quot;</S.StepText>
            </S.StepWrapper>
          </S.StepsWrapper>

          <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
            4 -{' '}
            <S.Link href="https://dashboard.mycheckout.com.br/user/account" target="_blank">
              Após Salvar, clique aqui e vá para a página de perfil da Evermart.
            </S.Link>
          </S.TutotialStep>

          <S.StepsWrapper>
            <S.StepWrapper>
              <S.StepBullet />
              <S.StepText size={ETextSize.B5}>
                Copie o &quot;ID&quot;, que está em &quot;Minha Conta&quot;
              </S.StepText>
            </S.StepWrapper>
            <S.StepWrapper>
              <S.StepBullet />
              <S.StepText size={ETextSize.B5}>Cole no campo abaixo</S.StepText>
            </S.StepWrapper>
          </S.StepsWrapper>

          <S.InputGroup>
            <S.Input
              {...accountIdInputRegister}
              type="text"
              value={accountIdValue}
              placeholder="ID da Conta Evermart"
              onChange={onAccountIdChange}
              isError={errors?.account_id}
            />
            {errors.account_id && <Text isErrorFeedback>{errors.account_id.message}</Text>}
          </S.InputGroup>
        </S.TutorialWrapper>

        <ActiveIntegrationFooter
          isIntegrating={isCreatingCredential}
          disabled={isCreatingCredential || !accountIdValue}
        />
      </S.Form>
    </S.ContentWrapper>
  );
};

export default Evermart;
