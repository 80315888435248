import React from 'react';
import { useTheme } from 'styled-components/macro';
import { Warning } from '@profitfy/pakkun-icons';

import { useProductCostDetails } from '@helpers/hooks/pages/dashboard/productCostDetails/productCostDetails';
import { EModalType } from '@domain/enums/components/EModal';

import * as S from './styles';

const SyncPastCogsModal: React.FC = () => {
  const theme = useTheme();

  const {
    isSyncPastCogsModalOpen,
    handleSyncPastCogsModalOpen,
    handleSyncPastCogsSidemodalOpen,
  } = useProductCostDetails();

  const onSyncClick = React.useCallback(() => {
    handleSyncPastCogsModalOpen();
    handleSyncPastCogsSidemodalOpen();
  }, [handleSyncPastCogsSidemodalOpen, handleSyncPastCogsModalOpen]);

  return (
    <S.Modal isOpen={isSyncPastCogsModalOpen} toggle={handleSyncPastCogsModalOpen}>
      <S.Modal.Header type={EModalType.WARNING}>
        <Warning size={24} color={theme.colors.warning.default} outline />
        Alterar custos para pedidos anteriores?
      </S.Modal.Header>
      <S.Modal.Body>
        <S.Description>
          Caso você <strong>não altere</strong>, os custos só serão aplicados para os{' '}
          <strong>próximos pedidos.</strong>
        </S.Description>
        <S.ButtonsWrapper>
          <S.CancelButton onClick={handleSyncPastCogsModalOpen}>Não Alterar</S.CancelButton>
          <S.SyncButton onClick={onSyncClick}>Alterar</S.SyncButton>
        </S.ButtonsWrapper>
      </S.Modal.Body>
    </S.Modal>
  );
};

export default SyncPastCogsModal;
