import { ALL_PLANS } from '@constants/plans';

export const getSelectedPlanRecurrencePrices = (
  selectedPlan?: typeof ALL_PLANS[number],
): Array<number> => {
  if (!selectedPlan) return [0, 0, 0];

  const { monthlyPrice, quarterPrice, semesterPrice } = selectedPlan;

  return [monthlyPrice, quarterPrice, semesterPrice];
};

export const getSelectedPlanRecurrenceDiscounts = (
  selectedPlan?: typeof ALL_PLANS[number],
): Array<number> => {
  if (!selectedPlan) return [0, 0, 0];

  const { monthlyPrice, quarterPrice, semesterPrice } = selectedPlan;

  const monthlyDiscount = 0;
  const quarterDiscount = monthlyPrice * 3 - quarterPrice;
  const semiAnualDiscount = monthlyPrice * 6 - semesterPrice;

  return [monthlyDiscount, quarterDiscount, semiAnualDiscount];
};
