import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Wrapper = styled.div`
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.gray[4]};
  border-radius: 12px;
  padding: 16px;
  box-sizing: border-box;
  margin-bottom: 32px;

  @media only screen and (max-width: 955px) {
    margin-bottom: unset;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VariablesWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

export const VariableButton = styled.button`
  background-color: ${({ theme }) => theme.colors.gray[4]};
  border-radius: 20px;
  border: unset;
  height: 25px;
  padding: 2px 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.gray[1]};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Title = styled(TextBase)`
  margin-bottom: 24px;
  line-height: 120%;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 104px;
  background-color: ${({ theme }) => theme.colors.background[2]};
  border: unset;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 24px;
  box-sizing: border-box;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  color: ${({ theme }) => theme.colors.gray[1]};
  font-size: 0.875rem;
  resize: none;

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray[1]};
    border-radius: 25px;
    margin-bottom: 4px;
  }

  &:focus {
    outline: none;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const SaveButton = styled(ButtonBase)``;

export const CancelButton = styled(ButtonBase)``;

export const EmojiWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  border-radius: 20px;
  border: unset;
  height: 25px;
  width: 40px;
  position: relative;
`;

export const EmojiPickerWrapper = styled.div`
  position: absolute;
  bottom: 30px;
  z-index: 99999999999;
`;

export const IconSmileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const FileImageContainer = styled.div`
  width: 100%;
  height: 150px;

  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;
