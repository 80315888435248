import { v4 as idRandom } from 'uuid';

import {
  EPixType,
  EPixOrderStatusOptions,
  EPixOrderStatusPicker,
} from '@domain/enums/dashboard/automations/node/conditionalNode/EPix';

const initialPositionX = 574;
const initialPositionY = 28;

export const PIX_FREE_NODES = [
  {
    id: `node1`,
    position: { x: initialPositionX, y: initialPositionY },
    data: { label: `Node 1`, name: idRandom() },
    type: 'PIX',
  },
  {
    id: `node2`,
    position: { x: initialPositionX + 100, y: initialPositionY + 200 },
    data: {
      label: `Node 2`,
      rules: [{ unit: 'MINUTES', amount: 5 }],
      name: idRandom(),
    },
    type: 'WAIT',
  },
  {
    id: `node3`,
    position: { x: initialPositionX + 200, y: initialPositionY + 400 },
    data: {
      label: `Node 3`,
      rules: [
        {
          id: idRandom(),
          type: EPixType.ORDER_STATUS,
          options: EPixOrderStatusOptions.NOT_EQUAL,
          input: EPixOrderStatusPicker.PAID,
        },
      ],
      name: idRandom(),
    },
    type: 'IF',
  },
  {
    id: `nodeEnd1`,
    position: { x: initialPositionX, y: initialPositionY + 600 },
    data: { label: `NodeEnd1`, name: idRandom() },
    type: 'END',
  },
  {
    id: `node4`,
    position: { x: initialPositionX + 400, y: initialPositionY + 600 },
    data: {
      label: `Node 4`,
      name: idRandom(),
      message: {
        id: 'pix-free-first-condition',
      },
    },
    type: 'WHATSAPP',
  },
  {
    id: `node5`,
    position: { x: initialPositionX + 500, y: initialPositionY + 1200 },
    data: { label: `Node 5`, name: idRandom() },
    type: 'END',
  },
];
