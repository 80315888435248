import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { IParams } from '@domain/interfaces/IParams';

import { useDowngrade } from '@helpers/hooks/common/store/subscription/useDowngrade';
import { useSubscription } from '@helpers/hooks/common/store/subscription/useSubscription';

import { ADS_PROVIDERS } from '@constants/common/integrations';

import { ALL_PLANS } from '@constants/plans';

import NextStep from '../NextStep';

import * as S from './styles';

const AdProviders: React.FC = () => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { selectedPlanIdentifier } = useSubscription();
  const {
    adProviders,
    selectedAdProviders,
    handleSelectedAdProviders,
    maxAdProviders,
  } = useDowngrade();

  const selectedPlan =
    (ALL_PLANS.find(
      plan =>
        plan.identifier === selectedPlanIdentifier ||
        plan.quarterIdentifier === selectedPlanIdentifier ||
        plan.semiAnualIdentifier === selectedPlanIdentifier,
    ) as typeof ALL_PLANS[number]) || undefined;

  const selectedPlanMarketingProviders =
    selectedPlan?.marketingProvider === 0 ? Infinity : selectedPlan?.marketingProvider;

  const hasToDowngradeAdsProviders = adProviders.length > (selectedPlanMarketingProviders || 0);

  const onChange = React.useCallback(
    event => {
      if (event.target) return;

      const adProvider = event.value;

      const foundAdProvider = selectedAdProviders.find(provider => provider === adProvider);

      if (foundAdProvider) {
        const filteredAdProviders = selectedAdProviders.filter(provider => provider !== adProvider);
        handleSelectedAdProviders(filteredAdProviders);
      } else {
        handleSelectedAdProviders([...selectedAdProviders, adProvider]);
      }
    },
    [handleSelectedAdProviders, selectedAdProviders],
  );

  React.useEffect(() => {
    if (!hasToDowngradeAdsProviders) {
      history.push(`/${storeAliasId}/downgrade/invited-members`);
    }
  }, [hasToDowngradeAdsProviders, history, storeAliasId]);

  const hasExceededQuantity = selectedAdProviders.length > maxAdProviders;

  return (
    <S.Wrapper>
      <S.Title type={EHeadingSize.H3}>Fontes de Tráfego</S.Title>

      <S.Description>Selecione as fontes de tráfego que deseja manter:</S.Description>

      <S.AdProvidersOptionsWrapper>
        {adProviders.map(adProvider => {
          const foundMarketing = ADS_PROVIDERS.find(
            integration => integration.identifier === adProvider,
          ) as typeof ADS_PROVIDERS[number];

          const Icon = foundMarketing.icon;

          return (
            <S.ProviderOptionGroup key={adProvider}>
              <S.Option htmlFor={`option-${adProvider}`}>
                <Icon size={24} />
                <S.ProviderName>{foundMarketing.name}</S.ProviderName>
              </S.Option>

              <S.Checkbox id={`option-${adProvider}`} value={adProvider} onChange={onChange} />
            </S.ProviderOptionGroup>
          );
        })}
      </S.AdProvidersOptionsWrapper>

      <S.SelectedQuantity
        size={ETextSize.B5}
        weight={ETextWeight.MEDIUM}
        hasExceededQuantity={hasExceededQuantity}
      >
        <mark>{selectedAdProviders.length}</mark>
        {`/${maxAdProviders} selecionada(s)`}
      </S.SelectedQuantity>

      <NextStep />
    </S.Wrapper>
  );
};

export default AdProviders;
