import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';
import ImageBase from '@components/common/core/DataDisplay/Image';

import TopLeftImgDetail from '@assets/dashboard/benefitArea/details2.svg';
import BottomRightImgDetail from '@assets/dashboard/benefitArea/details1.svg';

export const Container = styled.section`
  position: relative;
  width: calc(100% + 72px);
  background: linear-gradient(253.21deg, #0b0d11 4.75%, #000000 71.62%);
  padding-top: 52px;
  padding-bottom: 120px;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 52px;
    left: 52px;
    width: 103px;
    height: 75px;
    background-image: url(${TopLeftImgDetail});
    background-repeat: no-repeat;

    @media only screen and (max-width: 670px) {
      display: none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 52px;
    right: 52px;
    width: 50px;
    height: 174px;
    background-image: url(${BottomRightImgDetail});
    background-repeat: no-repeat;

    @media only screen and (max-width: 670px) {
      display: none;
    }
  }

  @media only screen and (max-width: 420px) {
    padding-bottom: 60px;
  }

  @media only screen and (max-height: 768px) {
    padding-top: 20px;
    padding-bottom: 30px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 607px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 620px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const Image = styled(ImageBase)`
  margin-bottom: 52px;
  width: 100%;
  max-width: 58px;

  @media only screen and (max-height: 768px) {
    margin-bottom: 24px;
  }
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 12px;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 1.75rem;
  line-height: 130%;

  @media only screen and (max-width: 365px) {
    font-size: 1.25rem;
  }
`;

export const SubTitle = styled(HeadingBase)`
  margin-bottom: 32px;
  text-align: center;
  font-size: 1.75rem;
  line-height: 130%;
  color: #fb436e;

  @media only screen and (max-width: 365px) {
    font-size: 1.25rem;
  }

  @media only screen and (max-height: 590px) {
    margin-bottom: 12px;
  }
`;

export const Description = styled(TextBase)`
  margin-bottom: 52px;
  text-align: center;
  max-width: 533px;

  @media only screen and (max-width: 365px) {
    font-size: 0.875rem;
  }

  @media only screen and (max-height: 768px) {
    margin-bottom: 24px;
  }
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const ActivateBenefitButton = styled(ButtonBase)`
  span {
    text-transform: uppercase;
  }

  @media only screen and (max-width: 420px) {
    height: 42px;
  }
`;
