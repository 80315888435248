import React from 'react';
import { Star } from '@profitfy/pakkun-icons';
import { useHistory, useParams } from 'react-router-dom';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { EButtonSize } from '@domain/enums/components/EButton';
import { IParams } from '@domain/interfaces/IParams';

import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const UtmOnboardingNotFinishedAlert: React.FC = () => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { analytics } = useConfig();

  const handleStartOnboarding = React.useCallback(() => {
    analytics?.track(
      'Ads Manager Banner Button Clicked',
      {},
      { context: { groupId: storeAliasId } },
    );

    history.push(`/${storeAliasId}/dashboard/marketing/ads-manager/onboarding/script-utms`);
  }, [history, storeAliasId, analytics]);

  return (
    <S.Wrapper>
      <S.IconAndDescriptionWrapper>
        <S.IconWrapper>
          <Star size={24} />
        </S.IconWrapper>
        <S.DescriptionWrapper>
          <S.Title type={EHeadingSize.H6} fontWeight={EHeadingWeight.REGULAR}>
            Veja o lucro e dados financeiros de suas campanhas em tempo real!
          </S.Title>
          <S.Description>
            Não se preocupe, isso não afetará sua tabela de gerenciamento de campanhas. Apenas
            incrementará dados financeiros.{' '}
            <S.Link href="https://google.com" target="_blank">
              Saiba mais
            </S.Link>
          </S.Description>
        </S.DescriptionWrapper>
      </S.IconAndDescriptionWrapper>

      <S.StartOnboardingButton onClick={handleStartOnboarding} buttonSize={EButtonSize.MEDIUM}>
        Saber o lucro de cada campanha
      </S.StartOnboardingButton>
    </S.Wrapper>
  );
};

export default UtmOnboardingNotFinishedAlert;
