import React from 'react';

import { getOrderLineItems } from '@helpers/dashboard/orders/ordersUtils';

import { IOrderTableDataProps } from '@domain/interfaces/dashboard/Orders/IOrderTableData';

import { ETextWeight } from '@domain/enums/components/EText';

import Table from '@components/common/core/Utils/Table';

import * as S from './styles';

const Product: React.FC<IOrderTableDataProps> = ({ order }) => {
  const [isProductImageError, setIsProductImageError] = React.useState<boolean>(false);

  const handleImageError = React.useCallback(() => {
    setIsProductImageError(true);
  }, []);

  const { firstLineItem, restLineItems } = getOrderLineItems(order?.line_items);

  const productImageUrl = firstLineItem.product?.image_url;

  const hasProductImage = productImageUrl && !isProductImageError;

  return (
    <Table.Data>
      <S.ProductWrapper>
        {hasProductImage && (
          <S.ProductImage
            src={productImageUrl}
            alt="Profitfy.me Boleto"
            onError={handleImageError}
          />
        )}

        {!hasProductImage && <S.NoImage />}

        <S.ProductDetailsWrapper>
          <S.ProductDetailsText weight={ETextWeight.MEDIUM}>
            {firstLineItem.product?.name}
          </S.ProductDetailsText>

          {Boolean(restLineItems?.length) && (
            <S.Popover>
              <S.Popover.Trigger>
                <S.SeeMoreProducts>Ver mais</S.SeeMoreProducts>
              </S.Popover.Trigger>

              <S.Popover.Content side="bottom" sideOffset={5}>
                <S.LineItemsWrapper>
                  {restLineItems?.map(lineItem => (
                    <S.TooltipProductText key={lineItem.alias_id} weight={ETextWeight.MEDIUM}>
                      {lineItem.product?.name}
                    </S.TooltipProductText>
                  ))}
                </S.LineItemsWrapper>

                <S.Popover.Arrow />
              </S.Popover.Content>
            </S.Popover>
          )}
        </S.ProductDetailsWrapper>
      </S.ProductWrapper>
    </Table.Data>
  );
};

export default Product;
