import styled from 'styled-components/macro';

import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import TextBase from '@components/common/core/DataDisplay/Text';
import TimePickerBase from '@components/common/core/Utils/TimePicker';

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Select = styled(SelectBase)`
  width: 100%;
  max-width: 148px;
`;

export const Option = styled(OptionBase)``;

export const TextFieldWrapper = styled.div`
  width: 148px;
  position: relative;
  display: flex;
  align-items: center;
`;

export const TextField = styled(TextFieldBase)`
  width: 100%;
  max-width: 227px;
  position: relative;
`;

export const CloseButton = styled.div`
  width: 20px;
  height: 20px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const LogicalOperatorWrapper = styled.div`
  width: 40px;
  height: 33px;
  box-sizing: border-box;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`;

export const RuleRouteWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RuleRouteNumber = styled(TextBase)`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[1]};
  margin-left: 8px;
`;

export const RulesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const RuleError = styled(TextBase)`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.danger.default};
  margin-left: 8px;
`;

export const TimePicker = styled(TimePickerBase)``;

export const RuleRouteTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const RuleColorIndicator = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white.default};
  border: 1px solid ${({ theme }) => theme.colors.white.default};
`;
