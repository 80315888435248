import React from 'react';
import { Package, PencilSimple } from 'phosphor-react';
import { useTheme } from 'styled-components';

import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

interface IEditModeProps {
  selectMode: (mode: 'specific' | 'name') => void;
}
const EditMode: React.FC<IEditModeProps> = ({ selectMode }) => {
  const { colors } = useTheme();
  const { analytics, user } = useConfig();

  const handleMode = React.useCallback(
    (mode: 'specific' | 'name') => {
      if (mode === 'specific') {
        analytics?.track('Bulk Cost Editing Button Clicked', {
          type: 'Produtos específicos',
          email: user?.email,
        });
      }

      if (mode === 'name') {
        analytics?.track('Bulk Cost Editing Button Clicked', {
          type: 'Produtos que contenham um nome',
          email: user?.email,
        });
      }

      selectMode(mode);
    },
    [selectMode, analytics, user],
  );

  return (
    <S.Wrapper>
      <S.Title>Qual a forma de edição do custo dos produtos você deseja?</S.Title>

      <S.CardWrapper>
        <S.Card onClick={() => handleMode('specific')}>
          <S.IconWrapper>
            <Package size={40} color={colors.green.default} />
          </S.IconWrapper>

          <S.CardTitle>Produtos específicos</S.CardTitle>
          <S.CardDescription>
            Selecione todos ou produtos específicos à serem editados
          </S.CardDescription>
        </S.Card>

        <S.Card onClick={() => handleMode('name')}>
          <S.IconWrapper>
            <PencilSimple size={40} color={colors.green.default} />
          </S.IconWrapper>

          <S.CardTitle>Produtos que contenham um nome</S.CardTitle>
          <S.CardDescription>
            Selecione os produtos que contenham o nome informado
          </S.CardDescription>
        </S.Card>
      </S.CardWrapper>
    </S.Wrapper>
  );
};

export default EditMode;
