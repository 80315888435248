import styled from 'styled-components/macro';

import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';

export const Input = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin: unset;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  & > div:first-child {
    max-width: 200px;
  }

  & > div:last-child {
    max-width: 176px;
  }
`;

export const LegalDocumentAndPhoneWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;
