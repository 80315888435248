import queryString from 'query-string';

import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DOMAIN } from '@constants/api';
import {
  IDeleteProductProps,
  IGetProductsProps,
  IGetProductsResponse,
} from '@domain/interfaces/dashboard/ProductCost/IProductCost';

export class ProductCostService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getProducts({
    storeAliasId,
    page,
    startDate,
    endDate,
    filter,
  }: IGetProductsProps): IGetProductsResponse {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/products?limit=10&page=${
        page + 1
      }&${queryStringFilter}&start_date=${startDate}&end_date=${endDate}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      totalPages: data?.total_pages,
      products: data?.products,
      mutate,
      error,
      isValidating,
      isLoading,
    };
  }

  public getProductsPromise({
    storeAliasId,
    page,
    startDate,
    endDate,
    filter,
  }: IGetProductsProps): Promise<any> {
    const stringfiedFilter = JSON.stringify(filter);

    const queryStringFilter = queryString.stringify({ filter: stringfiedFilter });

    return this.apiService.get(
      `/api/v1/users/stores/${storeAliasId}/products?limit=10&page=${
        page + 1
      }&${queryStringFilter}&start_date=${startDate}&end_date=${endDate}`,
    );
  }

  public deleteProduct({ storeAliasId, productAliasId }: IDeleteProductProps): Promise<any> {
    return this.apiService.delete(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}`,
    );
  }
}

const productCostService = new ProductCostService(API_DOMAIN);

export default productCostService;
