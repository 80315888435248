import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { EProgressBarStep } from '@domain/enums/onboarding/progressBar/ESteps';
import { IParams } from '@domain/interfaces/IParams';

import { useInfoProduct } from '@helpers/hooks/pages/onboarding/useInfoProduct';

import { INFO_PRODUCT_PROVIDERS } from '@constants/common/integrations';

import SideBar from '@components/Onboarding/SideBar';

import * as S from './styles';

const Platforms: React.FC = () => {
  const history = useHistory();

  const { storeAliasId } = useParams<IParams>();
  const { selectedPlatform, hasSelectedStoreIntegrations } = useInfoProduct();

  const getPlatformContent = React.useCallback(
    (platform: typeof INFO_PRODUCT_PROVIDERS[number] | undefined) => {
      if (platform) {
        const Content = platform.onboardingContent;

        return <Content />;
      }

      return <></>;
    },
    [],
  );

  React.useEffect(() => {
    if (!selectedPlatform && hasSelectedStoreIntegrations) {
      history.push(`/onboarding/${storeAliasId}/info-product/integrations/marketing`);
    }

    if (!selectedPlatform && !hasSelectedStoreIntegrations) {
      history.push(`/onboarding/${storeAliasId}/info-product/integrations`);
    }
  }, [history, storeAliasId, selectedPlatform, hasSelectedStoreIntegrations]);

  const foundPlatform = INFO_PRODUCT_PROVIDERS.find(
    integration => integration.identifier === selectedPlatform,
  );

  return (
    <S.Wrapper>
      <SideBar
        onboardingStep={EProgressBarStep.INTEGRATIONS}
        linkToGoBack={`/onboarding/${storeAliasId}/info-product/integrations`}
      />

      {getPlatformContent(foundPlatform)}
    </S.Wrapper>
  );
};

export default Platforms;
