import { EFilterType } from '@domain/enums/components/EFilter';
import { IFilterConfig } from '@domain/interfaces/components/IFilter';

export const COGS_FILTER_CONFIG: Array<IFilterConfig> = [
  {
    field: 'is_deleted',
    label: 'Status',
    type: EFilterType.SELECT,
    config: {
      placeholder: 'Encontrar status...',
      data: [
        {
          label: 'Excluído',
          value: 'true',
        },
        {
          label: 'Não Excluído',
          value: 'false',
        },
      ],
    },
  },
];
