import styled from 'styled-components/macro';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import SidemodalBase from '@components/common/core/DataDisplay/SideModal';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import RadioBase from '@components/common/core/Inputs/Radio';
import LabelBase from '@components/common/core/DataDisplay/Label';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import TextBase from '@components/common/core/DataDisplay/Text';
import CustomDatePickerBase from '@components/common/core/Utils/CustomDatePicker';

export const SaveButtonWrapper = styled.div`
  position: relative;
`;

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.background[3]};
  border-radius: 50%;
`;

export const CustomDatePicker = styled(CustomDatePickerBase)`
  & > div:first-child {
    margin-bottom: 0;
    flex-direction: column;

    & > div:first-child {
      margin-bottom: 24px;

      & > label {
        font-size: 0.875rem;
      }
    }

    & > div:nth-child(2) {
      & > label {
        font-size: 0.875rem;
      }
    }
  }
`;

export const SubmitFormButton = styled.button`
  background-color: unset;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  transition: all 0.3s;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.colors.font};
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.green.default};
  }
`;

export const HorizontalRule = styled.hr`
  border-color: ${({ theme }) => theme.colors.background[3]};
  border-style: solid;
  margin: 24px 0;
`;

export const RadioText = styled(TextBase)`
  cursor: pointer;
`;

export const Input = styled(TextFieldBase)`
  max-width: 324px;

  & > div:first-child {
    background-color: ${({ theme }) => theme.colors.background.input};
  }
`;

export const Radio = styled(RadioBase)``;

export const Option = styled(OptionBase)``;

export const Select = styled(SelectBase)`
  max-width: 324px;
  width: 100%;

  & > label > select {
    width: 100%;
  }
`;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 32px;
`;

export const Label = styled(LabelBase)`
  margin-bottom: 16px;
`;

export const RadioLabel = styled(LabelBase)`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const RadioGroup = styled(InputGroupBase)`
  margin-bottom: 32px;

  & > label:last-of-type {
    margin-top: 12px;
  }
`;

export const Heading = styled(HeadingBase)``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
  margin-bottom: 32px;
`;

export const Sidemodal = styled(SidemodalBase)``;
