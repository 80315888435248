import styled from 'styled-components/macro';

import CardBase from '@components/common/core/Surfaces/Card';
import CustomPopover from '@components/common/core/Utils/CustomPopover';
import TextBase from '@components/common/core/DataDisplay/Text';

export const PopoverTrigger = styled(CustomPopover.Trigger)`
  background-color: unset;
  padding: unset;
  border: unset;
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.green.default};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const RedirectButton = styled.button`
  background-color: unset;
  padding: unset;
  border: unset;
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.green.default};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const Popover = styled(CustomPopover)``;

export const PopoverContent = styled(CustomPopover.Content)`
  padding: 10px;
`;

export const Text = styled(TextBase)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[2]};
  text-align: center;
  line-height: 120%;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  & > svg {
    min-width: 40px;
  }
`;

export const CardWrapper = styled(CardBase)`
  padding: 20px 18px;
  box-sizing: border-box;
  min-width: 264px;
  height: 96px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 12px;
  border: unset;
  cursor: default;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ActionButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.font};
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  transition: all 0.5s;

  &:hover {
    color: ${({ theme }) => theme.colors.green.default};
  }
`;
