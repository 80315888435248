import React from 'react';

import { EHeadingSize } from '@domain/enums/components/EHeading';
import { IProgressBarProps } from '@domain/interfaces/onboarding/components/progressBar/IProgressBar';
import { useQuery } from '@helpers/hooks/useQuery';
import { EProgressBarStep } from '@domain/enums/onboarding/progressBar/ESteps';
import { useLocation } from 'react-router-dom';

import * as S from './styles';

const ProgressBar: React.FC<IProgressBarProps> = ({ step }) => {
  const query = useQuery();

  const { pathname } = useLocation();

  const isInfoProductBeta = query.get('is_info_product_beta');

  const isInfoProductType = pathname.includes('info-product');

  const isCreateStoreStepCompleted = step !== EProgressBarStep.CREATE_STORE;

  const isIntegrationStepCompleted =
    step === EProgressBarStep.GENERAL_CONFIG || step === EProgressBarStep.FINISH_STEP;

  const isGeneralConfigStepCompleted = step === EProgressBarStep.FINISH_STEP;

  const isOnboardingFinished = step === EProgressBarStep.FINISH_STEP;

  const isInfoProduct = (isInfoProductBeta && isInfoProductBeta === 'true') || isInfoProductType;

  const firstStepName = isInfoProduct ? 'Cadastro de plataforma digital' : 'Cadastro de loja';

  return (
    <S.Wrapper>
      <S.Heading type={EHeadingSize.H3}>
        Seu <span>progresso.</span>
      </S.Heading>

      <S.ProgressAndNameStepsWrapper>
        <S.ProgressWrapper>
          <S.ProgressBackground>
            <S.ProgressBar step={step} />
          </S.ProgressBackground>

          <S.CreateStoreProgressCircle isComplete={isCreateStoreStepCompleted}>
            {isCreateStoreStepCompleted && (
              <S.Icon>
                <polyline points="20 6 9 17 4 12" />
              </S.Icon>
            )}
          </S.CreateStoreProgressCircle>

          <S.IntegrationsProgressCircle step={step} isComplete={isIntegrationStepCompleted}>
            {isIntegrationStepCompleted && (
              <S.Icon>
                <polyline points="20 6 9 17 4 12" />
              </S.Icon>
            )}
          </S.IntegrationsProgressCircle>

          <S.GeneralConfigProgressCircle step={step} isComplete={isGeneralConfigStepCompleted}>
            {isGeneralConfigStepCompleted && (
              <S.Icon>
                <polyline points="20 6 9 17 4 12" />
              </S.Icon>
            )}
          </S.GeneralConfigProgressCircle>

          <S.FinishStepProgressCircle step={step} isComplete={isOnboardingFinished}>
            {isOnboardingFinished && (
              <S.Icon>
                <polyline points="20 6 9 17 4 12" />
              </S.Icon>
            )}
          </S.FinishStepProgressCircle>
        </S.ProgressWrapper>

        <S.StepsNameWrapper>
          <S.StepName isComplete={isCreateStoreStepCompleted}>{firstStepName}</S.StepName>
          <S.StepName isComplete={isIntegrationStepCompleted}>Integrar plataformas</S.StepName>
          <S.StepName isComplete={isGeneralConfigStepCompleted}>Configurações gerais</S.StepName>
          <S.StepName isComplete={isOnboardingFinished}>Finalizar</S.StepName>
        </S.StepsNameWrapper>
      </S.ProgressAndNameStepsWrapper>
    </S.Wrapper>
  );
};

export default ProgressBar;
