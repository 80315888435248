import { v4 } from 'uuid';

import {
  EApprovedOrderProductOptions,
  EApprovedOrderType,
} from '@domain/enums/dashboard/automations/node/conditionalNode/EApprovedOrder';

export const DEFAULT_APPROVED_ORDER_CONTENT = {
  id: v4(),
  type: EApprovedOrderType.PRODUCT_NAME,
  options: EApprovedOrderProductOptions.CONTAINS,
  input: '',
};
