import { ApiService } from '@services/api';
import { LONG_API_DOMAIN } from '@constants/api';
import {
  ICopyAdSenseCampaignsProps,
  IUpdateAdSenseCampaignProps,
  IUpdateAdSenseCampaignsStatusProps,
} from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IAdSenseCampaignsActions';

export class AdSenseCampaignsActions {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public copyAdSenseCampaigns({ storeAliasId, data }: ICopyAdSenseCampaignsProps): Promise<any> {
    return this.apiService.post(
      `/api/v1/users/stores/${storeAliasId}/ads-manager/ad-sense-campaigns/copy`,
      { ...data },
    );
  }

  public updateAdSenseCampaignsStatus({
    storeAliasId,
    data,
  }: IUpdateAdSenseCampaignsStatusProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/ads-manager/ad-sense-campaigns/status`,
      { ...data },
    );
  }

  public updateAdSenseCampaign({
    storeAliasId,
    adSenseCampaignAliasId,
    data,
  }: IUpdateAdSenseCampaignProps): any {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/ads-manager/ad-sense-campaigns/${adSenseCampaignAliasId}`,
      { ...data },
    );
  }
}

const adSenseCampaignsActions = new AdSenseCampaignsActions(LONG_API_DOMAIN);

export default adSenseCampaignsActions;
