import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const ExamplesText = styled(TextBase)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

export const SelectedIntegrationName = styled(TextBase)`
  width: 100%;
  font-size: 0.875rem;
  text-align: left;
`;

export const SelectedIntegration = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 12px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.gray[4]};
`;

export const SelectedIntegrationsWrapper = styled.div``;

export const AddIntegrationWrapper = styled.span`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.font};
  display: flex;
  align-items: center;
  margin: 22px 0;
`;

export const AddIntegrationButton = styled.button`
  background-color: ${({ theme }) => theme.colors.gray[6]};
  padding: 0 16px;
  border-radius: 7px;
  border: unset;
  margin-bottom: 24px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
