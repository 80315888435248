import React from 'react';
import { Yampi as YampiIcon, Warning } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { EYampiType } from '@domain/enums/common/integrations/providers/EYampi';
import { IParams } from '@domain/interfaces/IParams';
import { IIntegrateFormProps } from '@domain/interfaces/dashboard/IntegrationCenter/SideModalContent/IIntegrateForm';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { yampiSchema } from '@helpers/validators/dashboard/integrationCenter/yampi';

import yampiAuthService from '@services/pages/dashboard/integrationCenter/storeProvider/yampi/yampiAuth';

import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';
import Save from '@components/Dashboard/IntegrationCenter/SideModalContent/Save';

import * as S from './styles';

const IntegrateForm: React.FC<IIntegrateFormProps> = ({ mutate }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(yampiSchema),
  });
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { mutateStore } = useStoreConfig();
  const theme = useTheme();

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async data => {
      setIsSubmittingForm(true);

      try {
        await yampiAuthService.createYampiCredential({
          storeAliasId,
          data: {
            access_token: data?.access_token,
            provider_id: data?.provider_id,
            secret_key: data?.secret_key,
            types: [EYampiType.STORE],
          },
        });

        toast.success('Credencial cadastrada com sucesso!');

        await mutateStore();

        setIsSubmittingForm(false);

        if (mutate) {
          mutate();
        }
      } catch (error: any) {
        setIsSubmittingForm(false);
        toast.error(getTranslatedIntegrationsError(error));
      }
    },
    [storeAliasId, toast, mutateStore, mutate],
  );

  return (
    <S.ContentWrapper>
      <S.Header>
        <YampiIcon size={52} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com Yampi Loja
        </S.Heading>
      </S.Header>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>
        <S.FunctionalityDescriptionText>
          A integração com sua plataforma de e-commerce serve para puxar seus dados de loja.
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <S.TutorialWrapper>
        <S.TutorialHeading type={EHeadingSize.H5}>Tutorial de integração</S.TutorialHeading>
        <S.TutorialText>
          Basta adicionar o Alias, o Access Token e a Secret Key abaixo.
        </S.TutorialText>
      </S.TutorialWrapper>

      <S.AlertWarning>
        <S.WarningIconWrapper>
          <Warning size={18} color={theme.colors.warning.dark} />
        </S.WarningIconWrapper>
        <S.AlertText>
          Esta integração <strong>não se trata de uma integração com checkout.</strong> Informações
          de checkout <strong>não são necessárias</strong> para a realização da mesma.
        </S.AlertText>
      </S.AlertWarning>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label htmlFor="yampi-alias">Alias</S.Label>

          <S.Input
            {...register('provider_id')}
            type="text"
            id="yampi-alias"
            placeholder="Yampi Alias"
          />
          {errors.provider_id && <Text isErrorFeedback>{errors.provider_id.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label htmlFor="yampi-access-token">Access Token</S.Label>

          <S.Input
            {...register('access_token')}
            type="text"
            id="yampi-access-token"
            placeholder="Yampi Access Token"
          />
          {errors.access_token && <Text isErrorFeedback>{errors.access_token.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label htmlFor="yampi-secret-key">Secret Key</S.Label>

          <S.Input
            {...register('secret_key')}
            type="text"
            id="yampi-secret-key"
            placeholder="Yampi Secret Key"
          />
          {errors.secret_key && <Text isErrorFeedback>{errors.secret_key.message}</Text>}
        </S.InputGroup>

        <Save
          hasCredentials={false}
          submitText="Salvar alterações"
          isDeleting={isSubmittingForm}
          isDeleteButtonDisabled={isSubmittingForm}
          isSaveButtonDisabled={isSubmittingForm}
          isSubmitting={isSubmittingForm}
          cancelText="Cancelar"
        />
      </Form>
    </S.ContentWrapper>
  );
};

export default IntegrateForm;
