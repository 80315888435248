import React from 'react';

import { useStoreIntegrations } from '@helpers/hooks/pages/dashboard/integrationCenter/useStoreIntegrations';

import Header from './Header';
import StoreIntegrations from './StoreIntegrations';

import AdsProvider from './AdsProvider';
import EcommerceProvider from './EcommerceProvider';
import Gateway from './Gateway';
import * as S from './styles';

const EcommerceWrapper: React.FC = () => {
  const { selectedTab } = useStoreIntegrations();

  return (
    <S.Container>
      <Header />

      {selectedTab === 0 && <StoreIntegrations />}

      {(selectedTab === 0 || selectedTab === 1) && <EcommerceProvider />}

      {(selectedTab === 0 || selectedTab === 2) && <Gateway />}

      {(selectedTab === 0 || selectedTab === 3) && <AdsProvider />}
    </S.Container>
  );
};

export default EcommerceWrapper;
