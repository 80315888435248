import React from 'react';

import { useWhatsappInstance } from '@helpers/hooks/pages/dashboard/feed/useWhatsappInstance';

import Header from './Header';
import Instance from './Instance';

import * as S from './styles';

const WhatsappInstance: React.FC = () => {
  const {
    isLoadingWhatsappInstance,
    isValidatingWhatsappInstance,
    whatsappInstances,
  } = useWhatsappInstance();

  const hasInstances =
    !isLoadingWhatsappInstance && !isValidatingWhatsappInstance && whatsappInstances.length;

  if (!hasInstances) return <></>;

  return (
    <S.Wrapper>
      <Header />

      <S.WhatsappInstanceWrapper>
        {whatsappInstances.map(instance => (
          <Instance instance={instance} key={instance.alias_id} />
        ))}
      </S.WhatsappInstanceWrapper>
    </S.Wrapper>
  );
};

export default WhatsappInstance;
