import { ApiService } from '@services/api';
import { SUGGESTION_API_DOMAIN } from '@constants/api';
import {
  IListUserAnnouncementResponse,
  IUpsertAnnouncementProps,
} from '@domain/interfaces/dashboard/AdminPanel/IUserAnnouncement';
import { useFetch } from '@providers/Fetch/useFetch';

export class UserAnnouncementService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listUserAnnouncements(): IListUserAnnouncementResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/user-announcements`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      userAnnouncements: data?.user_announcements,
      isLoading,
      isValidating,
      mutate,
      error,
    };
  }

  public upsertAnnouncement({
    announcement_alias_id,
    data,
  }: IUpsertAnnouncementProps): Promise<any> {
    return this.apiService.post(`/api/v1/user-announcements/${announcement_alias_id}`, { ...data });
  }
}

const userAnnouncementService = new UserAnnouncementService(SUGGESTION_API_DOMAIN);

export default userAnnouncementService;
