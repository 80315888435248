import React from 'react';

import ThirdForm from '@components/Auth/SignUp/ThirdForm';
import logoImg from '@assets/img/brand/profitfy-logo.svg';

import * as S from './styles';

const SignUp: React.FC = () => {
  return (
    <S.Container>
      <S.Wrapper>
        <S.ContentWrapper>
          <S.Logo src={logoImg} alt="profitfy-logo" />

          <ThirdForm />
        </S.ContentWrapper>
      </S.Wrapper>

      <S.BackgroundGradient />
    </S.Container>
  );
};

export default React.memo(SignUp);
