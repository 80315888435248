import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';
import { Plus } from '@profitfy/pakkun-icons';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { useCustomValuesCategories } from '@helpers/hooks/pages/dashboard/customValues/useCustomValuesCategories';
import GoBack from '@components/common/core/Utils/GoBack';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Header: React.FC = () => {
  const theme: any = useTheme();
  const history = useHistory();
  const {
    handleCreateCategorySidemodalOpen,
    isLoadingCustomValuesCategories,
    isValidatingCustomValuesCategories,
    isUpdatingCustomValuesCategory,
  } = useCustomValuesCategories();

  const handleGoBack = React.useCallback(() => {
    history.goBack();
  }, [history]);

  if (
    (isLoadingCustomValuesCategories || isValidatingCustomValuesCategories) &&
    !isUpdatingCustomValuesCategory
  ) {
    return <SkeletonLoading />;
  }

  return (
    <S.HeaderWrapper>
      <GoBack text="Voltar" onClick={handleGoBack} />

      <S.TitleWrapper>
        <S.Title fontWeight={EHeadingWeight.MEDIUM} type={EHeadingSize.H3}>
          Categorias
        </S.Title>

        <S.AddCategory onClick={handleCreateCategorySidemodalOpen}>
          <S.AddCategoryIconWrapper>
            <Plus color={theme.colors.font} size={10} />
          </S.AddCategoryIconWrapper>
          Incluir Categoria
        </S.AddCategory>
      </S.TitleWrapper>
    </S.HeaderWrapper>
  );
};

export default Header;
