import * as yup from 'yup';

export const firstStepFormSchema = yup.object().shape({
  email: yup
    .string()
    .email('Você precisa inserir um e-mail válido.')
    .required('Este campo é obrigatório.'),
});

export const secondStepFormSchema = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ]*$/, 'Este campo deve conter apenas o primeiro nome.')
    .min(2, 'O nome precisa conter no mínimo 2 letras.')
    .max(30, 'O nome pode conter no máximo 30 letras.'),
  lastName: yup
    .string()
    .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ]*$/, 'Este campo deve conter apenas o sobrenome')
    .min(2, 'O sobrenome precisa conter no mínimo 2 letras.')
    .max(50, 'O sobrenome pode conter no máximo 50 letras'),
});

export const thirdStepFormSchema = yup.object().shape({
  affiliate: yup.string(),
  password: yup
    .string()
    .required('Este campo é obrigatório')
    .min(6, 'Senha deve conter no mínimo 6 caracteres'),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('password'), undefined],
      'A senha está diferente da senha informada no campo anterior.',
    ),
});

export const firstFormSchema = { firstStepFormSchema, secondStepFormSchema, thirdStepFormSchema };

export const secondFormSchema = yup.object().shape({
  phone: yup
    .string()
    .required('É necessário informar um telefone.')
    .min(6, 'Este campo deve conter no mínimo 6 caracteres'),
});

export const thirdFormSchema = yup.object().shape({
  user_company_size: yup
    .string()
    .required('É necessário informar o número de funcionários da sua empresa.'),
  user_market_segment: yup.string().required('É  necessário informar o seu segmento de mercado.'),
});

export const affiliateFormSchema = yup.object().shape({
  affiliate: yup.string(),
});
