import styled, { css } from 'styled-components/macro';

import { EFilterType } from '@domain/enums/components/EFilter';
import { IDropdownContentProps } from '@domain/interfaces/components/IFilter';

import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  height: 33px;
  padding: 8px 12px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.green.default};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.font};
  font-size: 0.875rem;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.body};
  gap: 8px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.green.default};
    filter: brightness(1.1);
  }

  &:active {
    color: ${({ theme }) => theme.colors.green.default};
    filter: brightness(0.9);
  }
`;

export const DropDownContent = styled(CustomPopoverBase.Content)<IDropdownContentProps>`
  background-color: ${({ theme }) => theme.colors.gray[6]};
  width: 232px;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

  ${({ filterType }) =>
    filterType === EFilterType.DATE_RANGE_PICKER &&
    css`
      width: fit-content;
      overflow: unset;
    `}
`;

export const DropDown = styled(CustomPopoverBase)``;
