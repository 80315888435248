import React from 'react';

import { useAdsManager } from '@helpers/hooks/pages/dashboard/adsManager/useAdsManager';

import * as S from './styles';

const CheckboxContent: React.FC = () => {
  const { hasSelectedAll, handleSelectedAll } = useAdsManager();

  const onCheckboxChange = React.useCallback(
    event => {
      if (event && !event.target) {
        handleSelectedAll(event.checked);
      }
    },
    [handleSelectedAll],
  );

  return <S.Checkbox defaultChecked={hasSelectedAll} onChange={onCheckboxChange} isBigVariant />;
};

export default CheckboxContent;
