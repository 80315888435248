import styled from 'styled-components/macro';

import { IFacebookProfilesSelectedQuantityProps } from '@domain/interfaces/downgrade/IDowngrade';

import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';

export const SelectedQuantity = styled(TextBase)<IFacebookProfilesSelectedQuantityProps>`
  display: block;
  text-align: right;
  color: ${({ theme }) => theme.colors.gray[2]};

  & > mark {
    background: unset;
    color: ${({ theme, hasExceededQuantity }) =>
      hasExceededQuantity ? theme.colors.danger.default : theme.colors.success.default};
  }
`;

export const FacebookProfilesOptionsWrapper = styled.div`
  padding: 24px 32px;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border: 1px solid ${({ theme }) => theme.colors.background[2]};

  & > div {
    padding: 16px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.background[2]};
  }

  & > div:first-child {
    padding-top: unset;
  }

  & > div:last-child {
    padding-bottom: unset;
    border-bottom: unset;
  }
`;

export const Description = styled(TextBase)`
  display: inline-block;
  margin-bottom: 12px;
`;

export const Title = styled(HeadingBase)`
  display: block;
  margin-bottom: 32px;
`;

export const Wrapper = styled.div`
  max-width: 508px;
  width: 100%;
  margin: 0 auto;
  margin-top: 72px;
`;
