export const ADMIN_PANEL_FUNCTIONALITIES = [
  {
    name: 'Canal de Sugestões',
    route: 'ideas',
  },
  {
    name: 'Checkout',
    route: 'checkout-generator',
  },
  {
    name: 'Cupom',
    route: 'coupons',
  },
  {
    name: 'Transferência de Plano',
    route: 'store-plan',
  },
  {
    name: 'Anúncios',
    route: 'announcement',
  },
  {
    name: 'Migrar proprietário de loja',
    route: 'store-migration',
  },
  {
    name: 'Carteira de Vendas',
    route: 'wallet',
  },
  {
    name: 'Adicionar dias de trial',
    route: 'trial',
  },
  {
    name: 'Push notification',
    route: 'push-notification',
  },
  {
    name: 'Deletar credencial',
    route: 'delete-credential',
  },
  {
    name: 'Alterar credencial',
    route: 'change-credential',
  },
];
