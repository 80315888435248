import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  max-width: 487px;
  width: 100%;
`;

export const Header = styled.div`
  margin-bottom: 45px;
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  max-width: 52px;
  width: 100%;
  height: 52px;
  margin-right: 24px;
`;

export const TitleWrapper = styled.div`
  max-width: 216px;
  width: 100%;
`;

export const DescriptionTitle = styled.div`
  margin-bottom: 12px;
`;

export const DescriptionText = styled.div``;
