export enum EApprovedOrderType {
  PRODUCT_NAME = 'PRODUCT_NAME',
  REJECTED_PAYMENT_TOTAL_VALUE = 'REJECTED_PAYMENT_TOTAL_VALUE',
}

export enum EApprovedOrderProductOptions {
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  NOT_EQUAL = 'NOT_EQUAL',
}

export enum EApprovedOrderTotalValueOptions {
  SMALLER = 'SMALLER',
  SMALLER_EQUAL = 'SMALLER_EQUAL',
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  LARGER = 'LARGER',
  LARGER_EQUAL = 'LARGER_EQUAL',
}

export enum EApprovedOrderOrderStatusOptions {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
}

export enum EApprovedOrderOrderStatusPicker {
  PAID = 'PAID',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
  CHARGED_BACK = 'CHARGED_BACK',
  IN_ANALYSIS = 'IN_ANALYSIS',
  REJECTED = 'REJECTED',
}

export type ApprovedOrderOptions<Type> = Type extends EApprovedOrderType.PRODUCT_NAME
  ? EApprovedOrderProductOptions
  : Type extends EApprovedOrderType.REJECTED_PAYMENT_TOTAL_VALUE
  ? EApprovedOrderTotalValueOptions
  : EApprovedOrderOrderStatusOptions;
