import React from 'react';
import { useHistory } from 'react-router-dom';

import { EMambaTermsStatus } from '@domain/enums/common/mamba/EMambaTermStatus';
import { IUpdateMambaData } from '@domain/interfaces/common/mamba/IMamba';
import { IMambaDigitalContext } from '@domain/interfaces/consent/IMambaDigital';

import { useConfig } from '@helpers/hooks/useConfig';
import { useToast } from '@helpers/hooks/useToast';

import mambaService from '@services/common/mamba/mamba';
import userService from '@services/user';

const MambaDigitalContext = React.createContext<IMambaDigitalContext | null>(null);

export const MambaDigitalProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { authorization, handleUser, handleTerm, handleAppAffiliate } = useConfig();
  const history = useHistory();

  const [isCheckboxSelected, setIsCheckboxSelected] = React.useState<boolean>(true);
  const [isSubmittingTerms, setIsSubmittingTerms] = React.useState<boolean>(false);

  const onCheckboxChange = React.useCallback(event => {
    if (event.target) return;

    if (event.stopPropagation) {
      event.stopPropagation();
    }

    setIsCheckboxSelected(event.checked);
  }, []);

  const onSubmit = React.useCallback(async () => {
    setIsSubmittingTerms(true);

    const data: IUpdateMambaData = {
      terms_status: isCheckboxSelected ? EMambaTermsStatus.ACCEPTED : EMambaTermsStatus.REJECTED,
    };

    try {
      await mambaService.updateMambaTerms({ data });
      const { data: userData } = await userService.getUserPromise(authorization);

      handleUser({ ...userData?.user, user_intercom_hash: userData?.user_intercom_hash });
      handleTerm(userData?.term);

      if (userData.affiliate) {
        handleAppAffiliate(userData.affiliate);
      }

      setIsSubmittingTerms(false);

      history.push('/onboarding/segmentation');
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsSubmittingTerms(false);
    }
  }, [
    isCheckboxSelected,
    toast,
    history,
    authorization,
    handleUser,
    handleAppAffiliate,
    handleTerm,
  ]);

  return (
    <MambaDigitalContext.Provider
      value={{ isCheckboxSelected, onCheckboxChange, onSubmit, isSubmittingTerms }}
    >
      {children}
    </MambaDigitalContext.Provider>
  );
};

export const useMambaDigital = (): IMambaDigitalContext => {
  const context = React.useContext(MambaDigitalContext);

  if (!context) {
    throw new Error('useMambaDigital must be used within MambaDigitalProvider');
  }

  return context;
};
