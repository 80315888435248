export default {
  greenLight: 'linear-gradient(225deg, #1F9AEA 0%, #49F898 100%)',
  pinkLightDark: 'linear-gradient(225deg, #FF1E62 0%, #FF6AE4 100%)',
  gradientBlueLightDark: 'linear-gradient(228.88deg, #538BF0 14.08%, #2DC7ED 83.46%)',
  orangeYellow: 'linear-gradient(225deg, #FE285A 0%, #FFCD76 100%)',
  redLightDark: 'linear-gradient(228.88deg, #EA384D 14.08%, #D31027 83.46%)',
  bluePurple: 'linear-gradient(225deg, #916BE3 0%, #59D1EC 100%)',
  purpleIndigo: 'linear-gradient(223.75deg, #284BFF 4.79%, #B633FF 95.02%)',
  blackWhite: 'linear-gradient(180deg, #0F1116 14.36%, rgba(15, 17, 22, 0) 100%)',
  grayDark:
    'linear-gradient(8.42deg, #000000 -5.05%, #000000 10.18%, #050609 48.24%,#11131a 72.41%)',
};
