import React from 'react';
import * as DropDownMenu from '@radix-ui/react-dropdown-menu';

import * as S from './styles';

const Content: React.FC<
  DropDownMenu.DropdownMenuContentProps & React.RefAttributes<HTMLDivElement>
> = ({ children, ...rest }) => {
  return <S.Wrapper {...rest}>{children}</S.Wrapper>;
};

export default Content;
