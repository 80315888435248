import React from 'react';

import { WhatsappLogo, Envelope, ChatDots } from 'phosphor-react';

import { EChannel } from '@domain/enums/dashboard/automations/ETemplate';
import { ISupportedChannels } from '@domain/interfaces/dashboard/Automations/ITemplateSummaryModal';

import * as S from './styles';

const SupportedChannels: React.FC<ISupportedChannels> = ({ channels, isHorizontal }) => {
  const getIcon = (channel: EChannel): JSX.Element => {
    if (channel === EChannel.WHATSAPP) {
      return (
        <S.Background color="#88B898">
          <WhatsappLogo size={22} color="#1A7A30" weight="light" />
        </S.Background>
      );
    }

    if (channel === EChannel.EMAIL) {
      return (
        <S.Background color="#86A4DD">
          <Envelope size={22} color="#004FC4" weight="light" />
        </S.Background>
      );
    }

    if (channel === EChannel.SMS) {
      return (
        <S.Background color="#EFE0C3">
          <ChatDots size={22} color="#B87905" weight="light" />
        </S.Background>
      );
    }

    return <></>;
  };

  return (
    <S.Wrapper isHorizontal={isHorizontal}>{channels.map(channel => getIcon(channel))}</S.Wrapper>
  );
};

export default SupportedChannels;
