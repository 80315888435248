import React from 'react';
import { Target } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import Form from '@components/common/core/Inputs/Form';
import { currencyToNumber, currencyFormatter } from '@helpers/masks';
import SideModalSave from '@components/common/core/Utils/SideModalSave';
import { storeGoalSchema } from '@helpers/validators/dashboard/financialDashboard/storeGoal';
import Text from '@components/common/core/DataDisplay/Text';
import goalService from '@services/pages/dashboard/goal/goal';
import { useToast } from '@helpers/hooks/useToast';
import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { useDate } from '@helpers/hooks/useDate';
import { IParams } from '@domain/interfaces/IParams';
import { EGoalMetric } from '@domain/enums/dashboard/goal/EGoal';
import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const NewStoreGoalSideModal: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(storeGoalSchema) });
  const { toast } = useToast();
  const {
    isNewStoreGoalSideModalOpen,
    handleNewStoreGoalSideModal,
    mutateFinancialDashboard,
  } = useFinancialDashboard();
  const { format } = useDate();
  const { storeAliasId } = useParams<IParams>();
  const { analytics, selectedStore, user } = useConfig();

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [period, setPeriod] = React.useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const onSubmit = React.useCallback(
    async data => {
      setIsSubmittingForm(true);

      try {
        await goalService.createGoal({
          storeAliasId,
          data: {
            name: 'Store Goal',
            metric: data.goal_type,
            goal_amount: currencyToNumber(data.amount),
            start_date: format(period.startDate, 'yyyy-MM-dd'),
            end_date: format(period.endDate, 'yyyy-MM-dd'),
          },
        });

        toast.success('Meta criada com sucesso!');

        analytics?.track(
          'Store Goal Added',
          {
            email: user?.email,
          },
          { context: { groupId: selectedStore?.alias_id } },
        );

        setIsSubmittingForm(false);

        mutateFinancialDashboard();

        handleNewStoreGoalSideModal();
      } catch (error: any) {
        setIsSubmittingForm(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [
      format,
      handleNewStoreGoalSideModal,
      period,
      storeAliasId,
      toast,
      mutateFinancialDashboard,
      analytics,
      selectedStore,
      user,
    ],
  );

  const onAmountChange = React.useCallback(event => {
    event.target.value = currencyFormatter(event.target.value);
  }, []);

  return (
    <S.SideModal isOpen={isNewStoreGoalSideModalOpen} toggle={handleNewStoreGoalSideModal}>
      <S.Header>
        <Target size={36} />
        <S.Title fontWeight={EHeadingWeight.REGULAR}>Criar meta</S.Title>
      </S.Header>

      <S.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label>Meta</S.Label>
            <S.Input
              {...register('amount')}
              placeholder="Insira o valor da meta"
              prependText="R$"
              type="text"
              onChange={onAmountChange}
            />
            {errors.amount && <Text isErrorFeedback>{errors.amount.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Tipo de métrica</S.Label>
            <S.Select {...register('goal_type')} defaultValue="">
              <S.Option value="" disabled hidden>
                Selecione a métrica que deseja como meta
              </S.Option>
              <S.Option value={EGoalMetric.NET_REVENUE}>Receita Líquida</S.Option>
              <S.Option value={EGoalMetric.NET_PROFIT}>Lucro Líquido</S.Option>
            </S.Select>
            {errors.goal_type && <Text isErrorFeedback>{errors.goal_type.message}</Text>}
          </S.InputGroup>

          <S.CustomDatePicker period={period} setPeriod={setPeriod} />

          <SideModalSave
            successButtonText="Salvar meta"
            cancelButtonText="Cancelar"
            onCancel={handleNewStoreGoalSideModal}
            isLoading={isSubmittingForm}
            isDisabled={isSubmittingForm}
          />
        </Form>
      </S.Body>
    </S.SideModal>
  );
};

export default NewStoreGoalSideModal;
