import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { IStoreIntegrations } from '@domain/interfaces/stores/IStores';

export const getAdsProviderIntegrationStatus = (
  storeIntegrations: IStoreIntegrations,
  identifier: EAdSenseProvider,
): boolean => {
  const foundMarketingIntegration = storeIntegrations?.marketing.find(
    integration => integration.name === identifier,
  );

  return Boolean(foundMarketingIntegration?.is_active);
};
