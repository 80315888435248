import styled, { css } from 'styled-components/macro';

import { IMoreOptionsWrapperProps } from '@domain/interfaces/dashboard/ProductCostDetails/IRow';
import { IActionButtonProps } from '@domain/interfaces/components/IActionButton';

import ImageBase from '@components/common/core/DataDisplay/Image';
import TextBase from '@components/common/core/DataDisplay/Text';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import NoImageBase from '@components/common/core/DataDisplay/NoImage';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';

export const TooltipContent = styled(TextBase)``;

export const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const EditedCostWrapper = styled.span`
  width: 28px;
  height: 28px;
  background-color: #275f64;
  border-radius: 50%;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  position: relative;
`;

export const HasEditedTooltip = styled(CustomTooltipBase)``;

export const VariantTooltipName = styled(TextBase)`
  padding: 4px 16px;
`;

export const NoImage = styled(NoImageBase)`
  background-color: ${({ theme }) => theme.colors.background[3]};
`;

export const CurrencySelect = styled(SelectBase)`
  max-width: 126px;
  width: 100%;
  border-radius: 5px;

  & > label > select {
    background-color: ${({ theme }) => theme.colors.background[4]};
    width: 100%;
  }
`;

export const Option = styled(OptionBase)``;

export const ProductWrapper = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
`;

export const Input = styled(TextFieldBase)`
  max-width: 136px;

  & > div {
    background-color: ${({ theme }) => theme.colors.background[4]};
  }
`;

export const Image = styled(ImageBase)`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

export const Text = styled(TextBase)`
  max-width: 428px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Dot = styled.span`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.background[15]};
`;

export const ActionsButton = styled.button<IMoreOptionsWrapperProps>`
  width: 26px;
  height: 26px;
  align-items: center;
  justify-content: center;
  gap: 2px;
  opacity: 0;
  display: flex;
  background-color: ${({ theme }) => theme.colors.gray[4]};
  border-radius: 50%;
  transition: filter 0.3s;
  border: unset;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  ${({ isHovering }) =>
    isHovering &&
    css`
      opacity: 1;
    `}
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`;

export const ActionButton = styled.button<IActionButtonProps>`
  border: none;
  background-color: transparent;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.font};
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;

  ${({ isDeleteButton, theme }) =>
    isDeleteButton &&
    css`
      color: ${theme.colors.danger.default};
    `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[6]};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.gray[6]};
    filter: brightness(0.9);
  }
`;

export const AccessibleActionsButtonName = styled.span`
  position: absolute;
  opacity: 0;
  width: 0;
`;
