import React from 'react';

import {
  Edge,
  MarkerType,
  Node,
  addEdge,
  useEdgesState,
  useNodesState,
  useUpdateNodeInternals,
} from 'react-flow-renderer';

import { useHistory, useParams } from 'react-router-dom';

import { EZoutiType } from '@domain/enums/common/integrations/gateways/EZouti';
import { ECartPandaType } from '@domain/enums/common/integrations/providers/ECartPanda';
import { EYampiType } from '@domain/enums/common/integrations/providers/EYampi';
import { ESelectedCategoryOption } from '@domain/enums/dashboard/automations/EMessage';
import { ETriggers } from '@domain/enums/dashboard/automations/ETriggers';
import { ENodeType } from '@domain/enums/mappers/automations/profitfy/ENodeType';
import { IParams } from '@domain/interfaces/IParams';
import {
  IAutomationProvider,
  IIntegration,
} from '@domain/interfaces/dashboard/Automations/IAutomation';
import { IVoteDown, IVoteUp } from '@domain/interfaces/dashboard/IdeasChannel/suggestions';
import IWorkflowNode from '@domain/interfaces/mappers/automations/profitfy/IWorkflowNode';

import { useCanvasMessage } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessage';
import { useConfig } from '@helpers/hooks/useConfig';
import { useDate } from '@helpers/hooks/useDate';
import { useToast } from '@helpers/hooks/useToast';

import automationsService from '@services/pages/dashboard/automations/automations';
import messagesService from '@services/pages/dashboard/automations/messages';
import suggestionsService from '@services/pages/dashboard/ideasChannel/suggestions';
import zoutiService from '@services/pages/dashboard/integrationCenter/gateway/zouti';
import hotmartService from '@services/pages/dashboard/integrationCenter/infoProduct/platforms/hotmart';
import cartPandaAuthService from '@services/pages/dashboard/integrationCenter/storeProvider/cartPanda/cartPandaAuth';
import yampiAuthService from '@services/pages/dashboard/integrationCenter/storeProvider/yampi/yampiAuth';
import whatsappCredentialWorkflowService from '@services/pages/dashboard/whatsappInstance/whatsAppCredentialWorkflow';
import whatsappCredentialService from '@services/pages/dashboard/whatsappInstance/whatsappCredential';

import edgeMapper from '@helpers/utils/mappers/automations/profitfy/edgeMapper';
import nodeMapper from '@helpers/utils/mappers/automations/profitfy/nodeMapper';

import { filterString } from '@helpers/utils/common/filter';
import { buildWorkflowToSave } from '@helpers/utils/hooks/dashboard/automations/buildWorkflowToSave';
import {
  hideInternalFinishNode,
  hideInternalOrderStatus,
  hideInternalSellRecoveredStatusNode,
} from '@helpers/utils/hooks/dashboard/automations/hideInternalNodes';
import {
  checkForMissingConfiguredNodes,
  checkForMissingConnections,
} from '@helpers/utils/hooks/dashboard/automations/saveValidations';
import edgeBackendToFrontMapper from '@helpers/utils/mappers/automations/profitfyBackend/edgeMapper';
import nodeBackendToFrontMapper from '@helpers/utils/mappers/automations/profitfyBackend/nodeMapper';

import { INTEGRATIONS } from '@constants/pages/dashboard/automations/integrations';
import { MESSAGES_TEMPLATES } from '@constants/pages/dashboard/automations/messages/templates';
import { EModalStep } from '@domain/enums/common/EStepsModal';
import { EKiwifyType } from '@domain/enums/common/integrations/providers/EKiwify';
import { ELojaIntegradaType } from '@domain/enums/common/integrations/providers/ELojaIntegrada';
import { ENuvemShopType } from '@domain/enums/common/integrations/providers/ENuvemShop';
import { EShopifyType } from '@domain/enums/common/integrations/providers/EShopify';
import { ISelfTestWorkflow } from '@domain/interfaces/common/automations/IWorkflows';
import { currencyToNumber } from '@helpers/masks';
import lojaIntegradaAuthService from '@services/pages/dashboard/integrationCenter/ecommerce/lojaIntegrada/lojaIntegradaAuth';
import kiwifyService from '@services/pages/dashboard/integrationCenter/infoProduct/platforms/kiwifyAuth';
import nuvemShopService from '@services/pages/dashboard/integrationCenter/marketplace/nuvemShop/nuvemShopAuth';
import shopifyService from '@services/pages/dashboard/integrationCenter/marketplace/shopify/shopifyAuth';

const AutomationContext = React.createContext<IAutomationProvider | null>(null);

export const AutomationProvider: React.FC = ({ children }) => {
  const updateNodeInternals = useUpdateNodeInternals();

  const { toast } = useToast();
  const { createMessage } = useCanvasMessage();

  const history = useHistory();

  const { format, subDays, utcToZonedTime } = useDate();
  const { storeAliasId, workflowAliasId } = useParams<IParams>();
  const { analytics } = useConfig();

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [initialNodes, setInitialNodes] = React.useState<Array<Node<any>>>([]);
  const [initialEdges, setInitialEdges] = React.useState<Array<Edge<any>>>([]);
  const [selectedIntegration, setSelectedIntegration] = React.useState<null | IIntegration>(null);
  const [showSideModal, setShowSideModal] = React.useState<boolean>(true);
  const [whatsAppCredentialName, setWhatsAppCredentialName] = React.useState<string>('');
  const [selectedNodeId, setSelectedNodeId] = React.useState<string | null>(null);
  const [showSaveAutomationModal, setShowSaveAutomationModal] = React.useState<boolean>(false);
  const [isWorkflowLimitationModalOpen, setIsWorkflowLimitationModalOpen] = React.useState<boolean>(
    false,
  );
  const [
    isMessagePerWorkflowLimitationModalOpen,
    setIsMessagePerWorkflowLimitationModalOpen,
  ] = React.useState<boolean>(false);
  const [
    isWhatsappInstancesLimitationModalOpen,
    setIsWhatsappInstancesLimitationModalOpen,
  ] = React.useState<boolean>(false);
  const [isTestSideModalOpen, setIsTestSideModalOpen] = React.useState<boolean>(false);
  const [connectionInProgress, setConnectionInProgress] = React.useState<boolean>(false);
  const [isSavingAutomation, setIsSavingAutomation] = React.useState<boolean>(false);
  const [isSavingTestAutomation, setIsSavingTestAutomation] = React.useState<boolean>(false);
  const [whatsappInstanceAliasId, setWhatsappInstanceAliasId] = React.useState<string | null>(null);
  const [formDataTestAutomation, setFormDataTestAutomation] = React.useState<any>({});
  const [testAutomationStep, setTestAutomationStep] = React.useState<EModalStep>(
    EModalStep.FIRST_STEP,
  );
  const [
    showSuggestionIntegrationModal,
    setShowSuggestionIntegrationModal,
  ] = React.useState<boolean>(false);
  const [
    selectedWorkflowTriggerType,
    setSelectedWorkflowTriggerType,
  ] = React.useState<ETriggers | null>(null);
  const [
    showRequestIntegrationByTrigger,
    setShowRequestIntegrationByTrigger,
  ] = React.useState<boolean>(false);
  const [showAddWhatsappInstanceModal, setShowAddWhatsappInstanceModal] = React.useState<boolean>(
    false,
  );
  const [
    showRenameWhatsappInstanceModal,
    setShowRenameWhatsappInstanceModal,
  ] = React.useState<boolean>(false);
  const [
    selectedTriggerForMissingCredential,
    setSelectedTriggerForMissingCredential,
  ] = React.useState<ETriggers | null>(null);
  const [showConnectedInstanceModal, setShowConnectedInstanceModal] = React.useState<boolean>(
    false,
  );

  const {
    suggestions,
    mutate: mutateSuggestions,
  } = suggestionsService.listAllAutomationSuggestions({
    startDate: '2022-01-01',
    endDate: format(new Date(), 'yyyy-MM-dd'),
  });

  const {
    workflow,
    isLoading: isLoadingWorkflow,
    isValidating: isValidatingWorkflow,
  } = automationsService.listWorkflowToCanvas({
    storeAliasId,
    workflowAliasId,
    endDate: format(new Date(), 'yyyy-MM-dd'),
    startDate: format(subDays(new Date(), 7), 'yyyy-MM-dd'),
  });

  const lastSevenDays = utcToZonedTime(subDays(new Date(), 6));

  const {
    whatsappCredentials,
    error: isWhatsappCredentialsError,
    isLoading: isLoadingWhatsappCredentials,
    isValidating: isValidatingWhatsappCredentials,
    mutate: mutateWhatsappCredentials,
  } = whatsappCredentialService.listAllCredentials({
    storeAliasId,
    start_date: format(lastSevenDays, 'yyyy-MM-dd'),
    end_date: format(utcToZonedTime(new Date()), 'yyyy-MM-dd'),
  });

  const {
    whatsappCredentialsWorkflow,
    mutate: mutateWhatsappCredentialsWorkflow,
  } = whatsappCredentialWorkflowService.listAllWhatsappCredentialsWorkflow({
    storeAliasId,
    workflowAliasId,
  });

  const {
    messages,
    totalPages: messagesTotalPages,
    isLoading: isLoadingMessages,
    isValidating: isValidatingMessages,
    error: isMessagesError,
    mutate: mutateMessages,
  } = messagesService.listMessages({
    storeAliasId,
    page: 0,
    filter: { title: '', category: ESelectedCategoryOption.WHATSAPP },
  });

  const handleShowConnectedInstanceModal = React.useCallback(() => {
    setShowConnectedInstanceModal(currentState => !currentState);
  }, []);

  const handleWorkflowLimitationModalOpen = React.useCallback(() => {
    setIsWorkflowLimitationModalOpen(state => !state);
  }, []);

  const handleMessagePerWorkflowLimitationModalOpen = React.useCallback(() => {
    setIsMessagePerWorkflowLimitationModalOpen(state => !state);
  }, []);

  const handleWhatsappInstancesLimitationModalOpen = React.useCallback(() => {
    setIsWhatsappInstancesLimitationModalOpen(state => !state);
  }, []);

  const handleTestSideModalOpen = React.useCallback(() => {
    setIsTestSideModalOpen(state => !state);
  }, []);

  const handleSetFormValues = React.useCallback(values => {
    setFormDataTestAutomation((prevValues: any) => ({
      ...prevValues,
      ...values,
    }));
  }, []);

  const handleTestAutomationStep = React.useCallback(step => setTestAutomationStep(step), []);
  const handleTestAutomationStepReset = React.useCallback(
    () => setTestAutomationStep(EModalStep.FIRST_STEP),
    [],
  );

  const handleSelectWorkflowTriggerType = React.useCallback((triggerType: ETriggers | null) => {
    setSelectedWorkflowTriggerType(triggerType);
  }, []);

  React.useEffect(() => {
    console.log({ nodes });
    console.log({ edges });
  }, [nodes, edges]);

  const onConnect = React.useCallback(
    params => {
      const hasMultipleEdges = Boolean(params.sourceHandle);

      const hasSourceAlreadyConnected = Boolean(edges.find(edge => edge.source === params.source));

      const hasHandleSourceAlreadyConnected = Boolean(
        edges.find(
          edge => edge.sourceHandle === params.sourceHandle && edge.source === params.source,
        ),
      );

      const hasEdgeAlreadyConnected = hasMultipleEdges
        ? hasSourceAlreadyConnected && hasHandleSourceAlreadyConnected
        : hasSourceAlreadyConnected;

      if (hasEdgeAlreadyConnected) {
        const foundEdgeSource = edges.find(edge => edge.source === params.source);

        if (!foundEdgeSource) return;

        const existsTargetNode = nodes.find(node => node.id === foundEdgeSource.target);

        if (existsTargetNode) {
          toast.error('Já existe uma conexão ativa.');

          return;
        }

        setEdges((els: any) => {
          const mappedEdges = els.map((edge: any) => {
            if (edge.source.includes(params.source)) {
              return false;
            }
            return edge;
          });

          const filteredEdges = mappedEdges.filter((edge: any) => edge);
          return filteredEdges;
        });
      }

      const isEdgeTryingToConnectToItself = params.source === params.target;

      if (isEdgeTryingToConnectToItself) {
        return;
      }

      const newParams = {
        ...params,
        animated: true,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          orient: 'auto',
        },
        style: {
          strokeWidth: 4,
        },
        type: 'buttonedge',
      };
      setEdges(eds => addEdge(newParams, eds));
    },
    [setEdges, edges, toast, nodes],
  );

  const pickIntegration = React.useCallback(
    integration => {
      const provider = integration.name as string;

      analytics?.track(
        'Automation Tool Integrations Selected',
        { provider: provider.toLowerCase() },
        { context: { groupId: storeAliasId } },
      );

      setSelectedIntegration(integration);
    },
    [storeAliasId, analytics],
  );

  const toggleSuggestionIntegrationModal = React.useCallback(() => {
    setShowSuggestionIntegrationModal(curr => !curr);
  }, []);

  const voteUp = React.useCallback(async ({ ideaAliasId }: IVoteUp) => {
    await suggestionsService.votesUp({ ideaAliasId });
  }, []);

  const voteDown = React.useCallback(async ({ ideaAliasId }: IVoteDown) => {
    await suggestionsService.votesDown({ ideaAliasId });
  }, []);

  const handleSelectedNodeId = React.useCallback((id: string | null) => {
    setSelectedNodeId(id);
  }, []);

  const handleSelectTriggerForMissingCredential = React.useCallback((trigger: ETriggers) => {
    setSelectedTriggerForMissingCredential(trigger);
  }, []);

  const updateYampiCredentials = React.useCallback(async () => {
    try {
      const { data } = await yampiAuthService.getYampiCredentialPromise({ storeAliasId });
      const { yampi_credential } = data;

      const selectedTrigger = (selectedWorkflowTriggerType as unknown) as EYampiType;
      const isTypeUsed = yampi_credential.types.some((type: any) => type === selectedTrigger);

      if (!isTypeUsed) {
        yampiAuthService.updateYampiCredential({
          storeAliasId,
          yampiCredentialAliasId: yampi_credential.alias_id,
          data: {
            types: [...yampi_credential.types, selectedTrigger],
          },
        });
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [selectedWorkflowTriggerType, storeAliasId, toast]);

  const updateHotmartCredentials = React.useCallback(async () => {
    try {
      const { data } = await hotmartService.getCredential({ storeAliasId });
      const { hotmart_credential } = data;

      const selectedTrigger = (selectedWorkflowTriggerType as unknown) as ETriggers;
      const isTypeUsed = hotmart_credential.types.some((type: any) => type === selectedTrigger);

      if (!isTypeUsed) {
        hotmartService.updateCredential({
          storeAliasId,
          credentialAliasId: hotmart_credential.alias_id,
          data: {
            is_active: true,
            types: [...hotmart_credential.types, selectedTrigger],
          },
        });
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [selectedWorkflowTriggerType, storeAliasId, toast]);

  const updateLojaIntegradaCredentials = React.useCallback(async () => {
    try {
      const { data } = await lojaIntegradaAuthService.getLojaIntegradaCredentialPromise({
        storeAliasId,
      });
      const { loja_integrada_credential } = data;

      const selectedTrigger = (selectedWorkflowTriggerType as unknown) as ELojaIntegradaType;
      const isTypeUsed = loja_integrada_credential.types.some(
        (type: any) => type === selectedTrigger,
      );

      if (!isTypeUsed) {
        lojaIntegradaAuthService.updateLojaIntegradaCredential({
          storeAliasId,
          payload: {
            store_key: null,
            is_active: true,
            type: [...loja_integrada_credential.types, selectedTrigger],
          },
        });
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [selectedWorkflowTriggerType, storeAliasId, toast]);

  const updateKiwifyCredentials = React.useCallback(async () => {
    try {
      const { data } = await kiwifyService.getCredential({
        storeAliasId,
      });
      const { kiwify_credential } = data;

      const selectedTrigger = (selectedWorkflowTriggerType as unknown) as EKiwifyType;
      const isTypeUsed = kiwify_credential.types.some((type: any) => type === selectedTrigger);

      if (!isTypeUsed) {
        kiwifyService.updateCredential({
          storeAliasId,
          credentialAliasId: kiwify_credential.alias_id,
          data: {
            is_active: true,
            types: [...kiwify_credential.types, selectedTrigger],
          },
        });
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [selectedWorkflowTriggerType, storeAliasId, toast]);

  const updateZoutiCredentials = React.useCallback(async () => {
    try {
      const { data } = await zoutiService.getCredentialPromise({ storeAliasId });
      const { zouti_credential } = data;

      const selectedTrigger = (selectedWorkflowTriggerType as unknown) as EZoutiType;
      const isTypeUsed = zouti_credential.types.some((type: any) => type === selectedTrigger);

      if (!isTypeUsed) {
        zoutiService.updateCredential({
          storeAliasId,
          data: {
            webhook_id: zouti_credential.webhook_id,
            types: [...zouti_credential.types, selectedTrigger],
          },
        });
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [selectedWorkflowTriggerType, storeAliasId, toast]);

  const updateNuvemShopCredentials = React.useCallback(async () => {
    try {
      const { data } = await nuvemShopService.getNuvemShopCredentialPromise({ storeAliasId });
      const { nuvem_shop_credential } = data;

      const selectedTrigger = (selectedWorkflowTriggerType as unknown) as ENuvemShopType;
      const isTypeUsed = nuvem_shop_credential.types.some((type: any) => type === selectedTrigger);

      if (!isTypeUsed) {
        nuvemShopService.updateNuvemShopCredential({
          storeAliasId,
          nuvemShopCredentialAliasId: nuvem_shop_credential.alias_id,
          data: {
            types: [...nuvem_shop_credential.types, selectedTrigger],
          },
        });
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [selectedWorkflowTriggerType, storeAliasId, toast]);

  const updateShopifyCredentials = React.useCallback(async () => {
    try {
      const { data } = await shopifyService.getShopifyCredentialPromise({ storeAliasId });
      const { shopify_credential } = data;

      const selectedTrigger = (selectedWorkflowTriggerType as unknown) as EShopifyType;
      const isTypeUsed = shopify_credential.types.some((type: any) => type === selectedTrigger);

      console.log('credential: ', shopify_credential);
      console.log(selectedTrigger);

      if (!isTypeUsed) {
        shopifyService.updateCredential({
          storeAliasId,
          data: {
            types: [...shopify_credential.types, selectedTrigger],
            is_active: true,
          },
        });
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [selectedWorkflowTriggerType, storeAliasId, toast]);

  const updateCartPandaCredentials = React.useCallback(async () => {
    try {
      const { data } = await cartPandaAuthService.getCartPandaCredentialPromise({ storeAliasId });
      const { cart_panda_credential } = data;

      const selectedTrigger = (selectedWorkflowTriggerType as unknown) as ECartPandaType;
      const isTypeUsed = cart_panda_credential.types.some((type: any) => type === selectedTrigger);

      if (!isTypeUsed) {
        cartPandaAuthService.updateCartPandaCredential({
          storeAliasId,
          cartPandaCredentialAliasId: cart_panda_credential.alias_id,
          data: {
            types: [...cart_panda_credential.types, selectedTrigger],
          },
        });
      }
      return;
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [selectedWorkflowTriggerType, storeAliasId, toast]);

  const checkWhatsappNodes = React.useCallback(
    async (mappedNodes: IWorkflowNode[]) => {
      const whatsappNodes = mappedNodes.filter(node => node.type === ENodeType.WHATSAPP);

      const newWhatsappNodes = [];

      for await (const whatsappNode of whatsappNodes) {
        const foundTemplateMessage = MESSAGES_TEMPLATES.some(messageTemplate => {
          return messageTemplate.id === whatsappNode.metadata.data.message.id;
        });

        if (!foundTemplateMessage) {
          newWhatsappNodes.push(whatsappNode);
          continue;
        }

        const messageTitle = whatsappNode.metadata.data.message.title;
        const messageContents = whatsappNode.metadata.data.message.message_contents;
        const messageCategory =
          whatsappNode.metadata.data.message.category || whatsappNode.metadata.data.category;
        const messageType =
          whatsappNode.metadata.data.message.type || whatsappNode.metadata.data.type;

        const message = await createMessage(
          messageTitle,
          messageContents,
          messageCategory,
          messageType,
          true,
        );

        if (!message) continue;

        const { data } = await messagesService.retrieveMessage({
          storeAliasId,
          message_alias_id: message.alias_id,
        });

        const newWhatsappNode = {
          ...whatsappNode,
          metadata: {
            ...whatsappNode.metadata,
            data: {
              ...whatsappNode.metadata.data,
              message: data?.message,
            },
          },
        };

        newWhatsappNodes.push(newWhatsappNode);
      }

      return newWhatsappNodes;
    },
    [createMessage, storeAliasId],
  );

  const saveTestAutomation = React.useCallback(
    async values => {
      setIsSavingTestAutomation(true);
      if (selectedIntegration) {
        const payload: ISelfTestWorkflow = {
          workflow_test_data: {
            currency: 'BRL',
            provider: selectedIntegration?.id,
            type: workflow.type,
            customer: {
              email: values.email,
              name: `${values.first_name} ${values.last_name}`,
              phone: values.phone.replace(/\D+/g, ''),
            },
            product: {
              name: values.product,
              quantity: values.quantity,
              total_amount: currencyToNumber(values.order_value),
            },
            workflow: {
              id: workflow.id,
              is_rejected_payment: workflow.type === ETriggers.REJECTED_PAYMENT,
              type: workflow.type,
            },
            address: {
              city: values.city,
              address: `${values.street}, ${values.street_number}`,
              neighborhood: values.neighborhood,
              country: 'Brazil',
              country_code: 'BR',
              state: values.state,
              zip_code: values.zip_code,
            },
          },
        };

        try {
          await automationsService.selfTestWorkflow({
            storeAliasId,
            data: payload,
          });

          toast.success(`Teste realizado com sucesso!`);
          setIsSavingTestAutomation(false);
          handleTestSideModalOpen();
          handleTestAutomationStepReset();
        } catch (error: any) {
          setIsSavingTestAutomation(false);
          toast.error(error?.response?.data?.message);
        }
      }
    },
    [
      selectedIntegration,
      workflow,
      storeAliasId,
      toast,
      handleTestSideModalOpen,
      handleTestAutomationStepReset,
    ],
  );

  const saveAutomation = React.useCallback(
    async (workflow_name: string, whatsappCredentialsAliasIds: string[]) => {
      const mappedNodes = nodes.map((node: any) => nodeMapper.map(node));
      const mappedEdges = edges.map((edge: any) => edgeMapper.map(edge));

      const isNodesConfigured = checkForMissingConfiguredNodes({
        nodes: mappedNodes,
        edges: mappedEdges,
        toast,
      });

      if (!isNodesConfigured) return;

      const isConnectionsConfigured = checkForMissingConnections({
        nodes: mappedNodes,
        edges: mappedEdges,
        toast,
      });

      if (!isConnectionsConfigured) return;

      setIsSavingAutomation(true);

      const newWhatsappNodes = await checkWhatsappNodes(mappedNodes);

      const nodesWithoutWhatsappNodes = mappedNodes.filter(
        mappedNode => mappedNode.type !== ENodeType.WHATSAPP,
      );

      nodesWithoutWhatsappNodes.push(...newWhatsappNodes);

      const { builded_edges, builded_nodes } = buildWorkflowToSave({
        edges: mappedEdges,
        nodes: nodesWithoutWhatsappNodes,
      });

      try {
        await automationsService.upsertWorkflow({
          storeAliasId,
          data: {
            edges: builded_edges,
            nodes: builded_nodes,
            workflow: {
              name: workflow_name,
              type: selectedWorkflowTriggerType as ETriggers,
              integration_name: selectedIntegration?.id as string,
              workflow_alias_id: workflowAliasId as string,
              whatsapp_credentials_alias_id: whatsappCredentialsAliasIds,
            },
          },
        });

        await mutateWhatsappCredentialsWorkflow();

        toast.success(`Automação ${workflowAliasId ? 'atualizada' : 'criada'} com sucesso!`);

        setIsSavingAutomation(false);

        if (selectedIntegration?.id === 'YAMPI') {
          updateYampiCredentials();
        }

        if (selectedIntegration?.id === 'CART_PANDA') {
          updateCartPandaCredentials();
        }

        if (selectedIntegration?.id === 'ZOUTI') {
          updateZoutiCredentials();
        }

        if (selectedIntegration?.id === 'NUVEM_SHOP') {
          updateNuvemShopCredentials();
        }

        if (selectedIntegration?.id === 'SHOPIFY') {
          updateShopifyCredentials();
        }

        if (selectedIntegration?.id === 'HOTMART') {
          updateHotmartCredentials();
        }

        if (selectedIntegration?.id === 'LOJA_INTEGRADA') {
          updateLojaIntegradaCredentials();
        }

        if (selectedIntegration?.id === 'KIWIFY') {
          updateKiwifyCredentials();
        }

        history.push(`/${storeAliasId}/dashboard/marketing/automations`);

        analytics?.track(
          'Automation Tool Flow Created',
          {},
          { context: { groupId: storeAliasId } },
        );
      } catch (error: any) {
        setIsSavingAutomation(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [
      nodes,
      edges,
      toast,
      storeAliasId,
      selectedWorkflowTriggerType,
      selectedIntegration,
      workflowAliasId,
      history,
      mutateWhatsappCredentialsWorkflow,
      updateYampiCredentials,
      updateCartPandaCredentials,
      analytics,
      checkWhatsappNodes,
      updateZoutiCredentials,
      updateNuvemShopCredentials,
      updateHotmartCredentials,
      updateLojaIntegradaCredentials,
      updateKiwifyCredentials,
      updateShopifyCredentials,
    ],
  );

  const handleDeleteEdges = React.useCallback(
    id => {
      setEdges(els => {
        const mappedEdges = els.map((edge: any) => {
          if (edge.id.includes(id)) {
            return false;
          }
          return edge;
        });

        const filteredEdges = mappedEdges.filter(edge => edge);

        return filteredEdges;
      });
    },
    [setEdges],
  );

  React.useEffect(() => {
    if (workflow && !isLoadingWorkflow) {
      const {
        nodes: nodesFromHideInterOrderStatus,
        edges: edgesFromHideInternalOrderStatus,
      } = hideInternalOrderStatus(workflow);

      const {
        nodes: nodesFromHideInteralFinishNode,
        edges: edgesFromHideInternalFinishNode,
      } = hideInternalFinishNode({
        ...workflow,
        workflow_nodes: nodesFromHideInterOrderStatus,
        workflow_edges: edgesFromHideInternalOrderStatus,
      });

      const {
        nodes: nodesFromHideInternalSellRecoveredStatusNode,
        edges: edgesFromHideInternalSellRecoveredStatusNode,
      } = hideInternalSellRecoveredStatusNode({
        ...workflow,
        workflow_nodes: nodesFromHideInteralFinishNode,
        workflow_edges: edgesFromHideInternalFinishNode,
      });

      const mappedNodes = nodeBackendToFrontMapper.map({
        ...workflow,
        workflow_nodes: nodesFromHideInternalSellRecoveredStatusNode,
        workflow_edges: edgesFromHideInternalSellRecoveredStatusNode,
      });
      const mappedEdges = edgeBackendToFrontMapper.map({
        ...workflow,
        workflow_nodes: nodesFromHideInternalSellRecoveredStatusNode,
        workflow_edges: edgesFromHideInternalSellRecoveredStatusNode,
      });

      setNodes(mappedNodes);
      setEdges(mappedEdges);
      setInitialNodes(mappedNodes);
      setInitialEdges(mappedEdges);

      handleSelectWorkflowTriggerType(workflow.type);

      setShowSideModal(false);

      const foundIntegration = INTEGRATIONS.find(
        integration => integration.id === workflow.integration_name,
      );

      if (foundIntegration) {
        setSelectedIntegration(foundIntegration);
      }
    }
  }, [
    workflow,
    setNodes,
    setEdges,
    isLoadingWorkflow,
    handleSelectWorkflowTriggerType,
    setShowSideModal,
  ]);

  const toggleShowSaveAutomationModal = React.useCallback(() => {
    setShowSaveAutomationModal(state => !state);
  }, []);

  const toggleShowAddWhatsappInstanceModal = React.useCallback(() => {
    setShowAddWhatsappInstanceModal(state => !state);
  }, []);

  const handleShowRenameWhatsappInstanceModal = React.useCallback(() => {
    setShowRenameWhatsappInstanceModal(true);
    setShowAddWhatsappInstanceModal(false);
    setShowSaveAutomationModal(false);
  }, []);

  const handleShowSaveAutomationModal = React.useCallback(() => {
    setShowRenameWhatsappInstanceModal(false);
    setShowAddWhatsappInstanceModal(false);
    setShowSaveAutomationModal(true);
  }, []);

  const handleSetWhatsappInstanceAliasId = React.useCallback((aliasId: string) => {
    setWhatsappInstanceAliasId(aliasId);
  }, []);

  const toggleShowRequestIntegrationByTrigger = React.useCallback(() => {
    setShowRequestIntegrationByTrigger(state => !state);
  }, []);

  const handleWhatsAppCredentialSearch = React.useCallback(
    whatsappName => setWhatsAppCredentialName(whatsappName),
    [],
  );

  const filteredWorkflows = whatsappCredentials
    ? whatsappCredentials.filter(whatsappCredential =>
        filterString(whatsappCredential.whatsapp_credential.name, whatsAppCredentialName),
      )
    : [];

  const handleConnectionInProgress = React.useCallback((value: boolean) => {
    setConnectionInProgress(value);
  }, []);

  const handleWhatsappNodeSelectMessage = React.useCallback(
    message => {
      if (!message) return;

      setNodes((currentNodes: any) =>
        currentNodes.map((node: any) => {
          if (node.id === selectedNodeId) {
            node.data = {
              ...node.data,
              message,
            };
          }

          return node;
        }),
      );

      updateNodeInternals(selectedNodeId as string);
    },
    [selectedNodeId, setNodes, updateNodeInternals],
  );

  const trackNode = React.useCallback(
    nodeType => {
      return analytics?.track(
        'Automation Tool Node Selected',
        { click_source: nodeType },
        { context: { groupId: storeAliasId } },
      );
    },
    [analytics, storeAliasId],
  );

  return (
    <AutomationContext.Provider
      value={{
        selectedIntegration,
        pickIntegration,
        nodes,
        onNodesChange,
        setNodes,
        edges,
        onEdgesChange,
        setEdges,
        onConnect,
        showSideModal,
        setShowSideModal,
        showSuggestionIntegrationModal,
        toggleSuggestionIntegrationModal,
        suggestions,
        voteUp,
        voteDown,
        selectedWorkflowTriggerType,
        handleSelectWorkflowTriggerType,
        selectedNodeId,
        handleSelectedNodeId,
        handleDeleteEdges,
        showSaveAutomationModal,
        toggleShowSaveAutomationModal,
        saveAutomation,
        showRequestIntegrationByTrigger,
        toggleShowRequestIntegrationByTrigger,
        workflow,
        isLoadingWorkflow: isLoadingWorkflow || isValidatingWorkflow,
        whatsappCredentials: filteredWorkflows,
        whatsAppCredentialName,
        handleWhatsAppCredentialSearch,
        whatsappCredentialsWorkflow,
        messages,
        messagesTotalPages,
        isLoadingMessages,
        isValidatingMessages,
        isMessagesError,
        mutateMessages,
        connectionInProgress,
        handleConnectionInProgress,
        showAddWhatsappInstanceModal,
        toggleShowAddWhatsappInstanceModal,
        isWhatsappCredentialsError,
        isLoadingWhatsappCredentials,
        isValidatingWhatsappCredentials,
        mutateWhatsappCredentials,
        showRenameWhatsappInstanceModal,
        handleShowRenameWhatsappInstanceModal,
        whatsappInstanceAliasId,
        handleSetWhatsappInstanceAliasId,
        handleShowSaveAutomationModal,
        isSavingAutomation,
        handleWhatsappNodeSelectMessage,
        mutateSuggestions,
        trackNode,
        selectedTriggerForMissingCredential,
        handleSelectTriggerForMissingCredential,
        initialEdges,
        initialNodes,
        handleWorkflowLimitationModalOpen,
        isWorkflowLimitationModalOpen,
        handleMessagePerWorkflowLimitationModalOpen,
        isMessagePerWorkflowLimitationModalOpen,
        handleWhatsappInstancesLimitationModalOpen,
        isWhatsappInstancesLimitationModalOpen,
        workflowAliasId,
        handleTestSideModalOpen,
        isTestSideModalOpen,
        handleSetFormValues,
        formDataTestAutomation,
        isSavingTestAutomation,
        saveTestAutomation,
        testAutomationStep,
        handleTestAutomationStep,
        showConnectedInstanceModal,
        handleShowConnectedInstanceModal,
      }}
    >
      {children}
    </AutomationContext.Provider>
  );
};

export const useAutomation = (): IAutomationProvider => {
  const context = React.useContext(AutomationContext);

  if (!context) {
    throw new Error('useAutomation must be used within AutomationContext');
  }

  return context;
};
