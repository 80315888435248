import React from 'react';

import { useProfile } from '@helpers/hooks/pages/dashboard/profile/useProfile';

import Tab from '@components/common/core/Navigation/Tab';
import PersonalInfo from '@components/Dashboard/Profile/PersonalInfo';
import Password from '@components/Dashboard/Profile/Password';
import Notification from '@components/Dashboard/Profile/Notification';
import MyStore from '@components/Dashboard/Profile/MyStore';
import PageError from '@components/common/core/Utils/PageError';
import SkeletonLoading from '../SkeletonLoading';

import * as S from './styles';

const Profile: React.FC = () => {
  const { isLoading, isValidating, isError, mutate } = useProfile();

  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const handleSelectedTab = React.useCallback(index => {
    setSelectedTab(index);
  }, []);

  if (isLoading || isValidating) {
    return (
      <S.Wrapper>
        <SkeletonLoading />
      </S.Wrapper>
    );
  }

  if (isError && !isValidating) {
    return <PageError mutate={mutate} />;
  }

  return (
    <S.Wrapper>
      <Tab defaultTab={0} onChangeTab={handleSelectedTab}>
        <Tab.Option>Perfil</Tab.Option>
        <Tab.Option>Senha</Tab.Option>
        <Tab.Option>Notificações</Tab.Option>
        <Tab.Option>Minha Loja</Tab.Option>
      </Tab>

      {selectedTab === 0 && <PersonalInfo />}

      {selectedTab === 1 && <Password />}

      {selectedTab === 2 && <Notification />}

      {selectedTab === 3 && <MyStore />}
    </S.Wrapper>
  );
};

export default Profile;
