import React from 'react';
import { useParams } from 'react-router-dom';

import { EGateway } from '@domain/enums/common/EGateway';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { IParams } from '@domain/interfaces/IParams';
import {
  IOtherGatewayIntegrationContext,
  IUpsertOtherGatewayProps,
} from '@domain/interfaces/dashboard/IntegrationCenter/Gateway/IOtherGateway';

import { useToast } from '@helpers/hooks/useToast';
import { useDate } from '@helpers/hooks/useDate';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import { getStoreProvider } from '@helpers/utils/common/integration';

import { GATEWAYS } from '@constants/common/integrations';

import otherGatewayIntegrationService from '@services/pages/dashboard/integrationCenter/gateway/otherGateway';

const OtherGatewayIntegrationContext = React.createContext<IOtherGatewayIntegrationContext | null>(
  null,
);

export const OtherGatewayIntegrationProvider: React.FC = ({ children }) => {
  const { mutateStore } = useStoreConfig();

  const foundIntegration = GATEWAYS.find(
    integration => integration.identifier === EGateway.OTHER_GATEWAY,
  ) as typeof GATEWAYS[number];

  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { utcToZonedTime, subDays, format } = useDate();
  const { addSynchronization } = useSynchronization();
  const { storeIntegrations } = useStoreConfig();

  const [isUpsertingGateway, setIsUpsertingGateway] = React.useState<boolean>(false);

  const {
    otherGateways,
    error: otherGatewaysError,
    mutate: mutateOtherGateways,
    isLoading: isLoadingOtherGateways,
    isValidating: isValidatingOtherGateways,
  } = otherGatewayIntegrationService.listOtherGateways({ storeAliasId });

  const getToastMessage = (): string => {
    if (otherGateways && Boolean(otherGateways.length)) {
      return 'Gateway atualizado com sucesso.';
    }

    return 'Gateway criado com sucesso.';
  };

  const storeProvider = getStoreProvider(storeIntegrations);

  const hasGatewaySynchronization = storeProvider?.gateways.includes(EGateway.OTHER_GATEWAY);

  const lastThirtyDays = utcToZonedTime(subDays(new Date(), 29));

  const params = `?start_date=${format(lastThirtyDays, 'yyyy-MM-dd')}&end_date=${format(
    utcToZonedTime(new Date()),
    'yyyy-MM-dd',
  )}`;

  const toastMessage = getToastMessage();

  const upsertOtherGateway = React.useCallback(
    async ({ data, syncOnCreate }: IUpsertOtherGatewayProps) => {
      setIsUpsertingGateway(true);

      try {
        await otherGatewayIntegrationService.upsertOtherGateway({ storeAliasId, data });

        if (
          (storeProvider && hasGatewaySynchronization) ||
          (syncOnCreate && storeProvider && hasGatewaySynchronization)
        ) {
          addSynchronization({
            name: storeProvider.name,
            type: ESynchronizationType.STORE_GATEWAY,
            label: foundIntegration.name,
            dependencies: [ESynchronizationType.STORE_PRODUCT, ESynchronizationType.STORE_ORDER],
            storeAliasId,
            config: {
              isOnboardingSynchronization: false,
              showNotification: true,
            },
            request: storeProvider.generateGatewaySyncRequest(
              foundIntegration.synchronizationName,
              storeAliasId,
              params,
            ),
          });
        }

        await Promise.all([mutateStore(), mutateOtherGateways()]);

        toast.success(toastMessage);

        setIsUpsertingGateway(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsUpsertingGateway(false);
      }
    },
    [
      toast,
      mutateOtherGateways,
      storeAliasId,
      toastMessage,
      addSynchronization,
      foundIntegration,
      hasGatewaySynchronization,
      storeProvider,
      mutateStore,
      params,
    ],
  );

  return (
    <OtherGatewayIntegrationContext.Provider
      value={{
        isUpsertingGateway,
        upsertOtherGateway,
        isLoadingOtherGateways,
        isValidatingOtherGateways,
        mutateOtherGateways,
        otherGateways,
        otherGatewaysError,
      }}
    >
      {children}
    </OtherGatewayIntegrationContext.Provider>
  );
};

export const useOtherGatewayIntegration = (): IOtherGatewayIntegrationContext => {
  const context = React.useContext(OtherGatewayIntegrationContext);

  if (!context) {
    throw new Error(
      'useOtherGatewayIntegration must be used within OtherGatewayIntegrationProvider',
    );
  }

  return context;
};
