import styled, { css } from 'styled-components/macro';

import CardBase from '@components/common/core/Surfaces/Card';
import TextBase from '@components/common/core/DataDisplay/Text';
import ImageBase from '@components/common/core/DataDisplay/Image';

export const InitialLetter = styled.span`
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.green.default};
  font-weight: 500;
  font-size: 1.5rem;
`;

export const InitialLetterWrapper = styled.div`
  width: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.background[3]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled(ImageBase)`
  width: 12px;
`;

export const ImageWrapper = styled.div``;

export const Text = styled(TextBase)`
  width: 100%;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 264px;

  @media only screen and (max-width: 456px) {
    max-width: 232px;
  }

  @media only screen and (max-width: 400px) {
    max-width: 184px;
  }

  @media only screen and (max-width: 352px) {
    max-width: 148px;
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const Card = styled(CardBase)`
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  position: relative;
  gap: 12px;
  border-radius: 8px;

  & > svg {
    min-width: 28px;
  }
`;

export const Button = styled.button`
  border: none;
  padding: 0;
  background-color: transparent;
  width: 100%;
  margin-bottom: 16px;
  z-index: ${({ theme }) => theme.zIndex.firstLayer};
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.2);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const StoreLeftContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  position: relative;
  gap: 12px;
  border-radius: 8px;

  & > svg {
    min-width: 28px;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: 'center';
  /* background-color: blue; */
  padding: 10px;
  box-sizing: border-box;

  color: ${({ theme }) => theme.colors.background[15]};

  &:hover {
    color: #d03333;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  border: none;
  padding: 0;
  background-color: transparent;
  width: 100%;
  margin-bottom: 16px;
  z-index: ${({ theme }) => theme.zIndex.firstLayer};
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.2);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const Left = styled.div<{ shouldRadius: boolean }>`
  display: flex;
  align-items: center;
  flex: 4;

  padding: 20px;
  justify-content: space-between;
  position: relative;
  gap: 12px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #2e323e;
  box-sizing: border-box;

  & > svg {
    min-width: 28px;
  }

  ${({ shouldRadius }) =>
    shouldRadius &&
    css`
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    `}
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  background-color: #2e323e;
  padding: 10px;
  justify-content: center;
  position: relative;
  gap: 12px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 40px;

  color: ${({ theme }) => theme.colors.background[15]};

  &:hover {
    color: #d03333;
  }

  & > svg {
    min-width: 28px;
  }
`;
