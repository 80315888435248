import { Warning } from 'phosphor-react';
import React from 'react';
import * as S from './styles';

interface IDeleteCredentialModalProps {
  isOpen: boolean;
  toggle: () => void;
}
const DeleteModalError: React.FC<IDeleteCredentialModalProps> = ({ isOpen, toggle }) => {
  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.QuestionWrapper>
        <Warning size={24} color="#CC962C" />

        <S.Question>Não é possível excluir este produto.</S.Question>
      </S.QuestionWrapper>

      <S.Text>
        Este produto já teve uma venda realizada ou foi cadastrado em um kit, portanto, não é
        possível excluí-lo do sistema.
      </S.Text>

      <S.ButtonsWrapper>
        <S.CancelButton onClick={toggle}>Entendi</S.CancelButton>
      </S.ButtonsWrapper>
    </S.Modal>
  );
};

export default DeleteModalError;
