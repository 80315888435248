export const numberFormatter = (number: number, digits: number, noDecimal?: boolean): string => {
  if (!number) {
    return '0';
  }

  if (number % 1 === 0 && noDecimal) {
    return number.toLocaleString('pt-br', { maximumFractionDigits: 0 });
  }

  return number.toLocaleString('pt-br', {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  });
};

export const chartNumberFormatter = (
  number: number,
  digits: number,
  noDecimal?: boolean,
): string => {
  if (number % 1 === 0 && noDecimal) {
    return number.toLocaleString('pt-br', { maximumFractionDigits: 0 });
  }

  return number.toLocaleString('pt-br', {
    maximumFractionDigits: digits,
    minimumFractionDigits: 0,
  });
};

export const friendlyNumberFormatter = (number: number, digits = 0, noDecimal = false): string => {
  if (number >= 1000000) {
    return `${numberFormatter(number / 1000000, digits)}mi`;
  }

  if (number >= 1000) {
    return `${numberFormatter(number / 1000, digits)}K`;
  }

  return numberFormatter(number, digits, noDecimal);
};

export const friendlyChartNumberFormatter = (number: number, digits = 0): string => {
  if (number >= 1000000) {
    return `${chartNumberFormatter(number / 1000000, digits)}mi`;
  }

  if (number >= 1000) {
    return `${chartNumberFormatter(number / 1000, digits)}K`;
  }

  return chartNumberFormatter(number, digits);
};

export const commaNotationNumberToDotNotationNumber = (number: string): number => {
  const parsedNumber = number.replaceAll('.', '').replaceAll(',', '.');

  return Number(parsedNumber);
};
