import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const SpendAmount = styled(TextBase)`
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray[1]};
  white-space: nowrap;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow-x: hidden;
  height: 58px;
  padding: 10px 12px;
  box-sizing: border-box;
`;
