import styled from 'styled-components/macro';

import EmptyDataBase from '@components/common/core/DataDisplay/EmptyData';
import PaginateBase from '@components/common/core/Utils/Paginate';

export const Container = styled.div`
  display: flex;
  padding-top: 95px;

  @media only screen and (max-width: 1086px) {
    flex-direction: column;
    gap: 36px;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1036px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const MessagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
`;

export const Paginate = styled(PaginateBase)`
  margin-left: auto;
`;

export const EmptyData = styled(EmptyDataBase)`
  margin-top: 40px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;
