import React from 'react';

import { Warning } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { IDeleteProfileModalProps } from '@domain/interfaces/onboarding/integrations/IDeleteProfileModal';

import * as S from './styles';

const DeleteProfileModal: React.FC<IDeleteProfileModalProps> = ({
  isOpen,
  toggle,
  onConfirm,
  removeButton: RemoveButton,
}) => {
  const theme = useTheme();

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Modal.Header>
        <Warning outline size={24} color={theme.colors.warning.default} />

        <S.Title size={ETextSize.B3} weight={ETextWeight.MEDIUM}>
          Deseja remover o perfil?
        </S.Title>
      </S.Modal.Header>

      <S.Modal.Body>
        <S.Description>
          Ao clicar em remover iremos{' '}
          <strong>remover todos os dados relacionados ao perfil.</strong> Isso pode impactar
          diretamente com os dados dos pedidos e produtos.
        </S.Description>

        <S.ButtonsWrapper>
          <S.CancelButton onClick={toggle}>Cancelar</S.CancelButton>
          {RemoveButton || <S.ConfirmButton onClick={onConfirm}>Remover</S.ConfirmButton>}
        </S.ButtonsWrapper>
      </S.Modal.Body>
    </S.Modal>
  );
};

export default DeleteProfileModal;
