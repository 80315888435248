import React from 'react';
import { Store } from '@profitfy/pakkun-icons';
import { useParams } from 'react-router-dom';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { EAdSenseProvider } from '@domain/enums/common/EAdSense';
import { ISidemodalProps } from '@domain/interfaces/onboarding/integrations/ISidemodal';
import { IParams } from '@domain/interfaces/IParams';

import { useMediaQueryHeight } from '@helpers/hooks/useMediaQuery';
import { useDropshipping } from '@helpers/hooks/pages/onboarding/useDropshipping';
import { useConfig } from '@helpers/hooks/useConfig';

import { ADS_PROVIDERS } from '@constants/common/integrations';

import SuggestionModal from './SuggestionModal';
import Save from './Save';

import * as S from './styles';

const Sidemodal: React.FC<ISidemodalProps> = ({ isOpen, toggle }) => {
  const { handleSelectedMarketings, selectedMarketings: userMarketings } = useDropshipping();
  const { analytics, user } = useConfig();
  const { storeAliasId } = useParams<IParams>();

  const mobileHeight = useMediaQueryHeight('768px');

  const [selectedMarketing, setSelectedMarketing] = React.useState<Array<EAdSenseProvider>>([]);
  const [isSuggestionModalOpen, setIsSuggestionModalOpen] = React.useState<boolean>(false);

  const handleSuggestionModalOpen = React.useCallback(
    () => setIsSuggestionModalOpen(!isSuggestionModalOpen),
    [isSuggestionModalOpen],
  );

  const openSuggestionModal = React.useCallback(() => {
    handleSuggestionModalOpen();
  }, [handleSuggestionModalOpen]);

  const onSave = React.useCallback(() => {
    handleSelectedMarketings(selectedMarketing);

    analytics?.track(
      'Onboarding Selected ADS Provider',
      {
        selected_ads_providers: selectedMarketing.map(
          (selectedMarketingPlatforms: EAdSenseProvider) => {
            const foundMarketing = ADS_PROVIDERS.find(
              integration => integration.identifier === selectedMarketingPlatforms,
            ) as typeof ADS_PROVIDERS[number];

            return foundMarketing?.name;
          },
        ),
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );

    toggle();
  }, [toggle, selectedMarketing, handleSelectedMarketings, analytics, storeAliasId, user]);

  const isDefaultChecked = React.useCallback(
    (integration: typeof ADS_PROVIDERS[number]) => {
      const foundIntegration = userMarketings.find(
        userSelectedMarketing => userSelectedMarketing === integration.identifier,
      );

      return Boolean(foundIntegration);
    },
    [userMarketings],
  );

  const onChange = React.useCallback(
    event => {
      if (event && !event.target) {
        if (event.checked) {
          const foundMarketing = ADS_PROVIDERS.find(
            integration => integration.identifier === event.value,
          );

          if (foundMarketing)
            setSelectedMarketing(previousState => [...previousState, foundMarketing.identifier]);
        } else {
          const filteredMarketing = selectedMarketing.filter(
            integration => integration !== event.value,
          );

          setSelectedMarketing(filteredMarketing);
        }
      }
    },
    [selectedMarketing],
  );

  React.useEffect(() => {
    if (userMarketings.length) {
      setSelectedMarketing(userMarketings);
    }
  }, [userMarketings]);

  return (
    <>
      <S.Sidemodal isOpen={isOpen} toggle={toggle} blockOverflowYScroll={mobileHeight}>
        <S.Header>
          <Store size={24} />
          <S.Title fontWeight={EHeadingWeight.REGULAR} type={EHeadingSize.H4}>
            Selecionar plataformas de marketing
          </S.Title>
        </S.Header>

        <S.Body>
          <S.NoIntegrationText>
            Não encontrou a plataforma que você usa?{' '}
            <S.SuggestionButton onClick={openSuggestionModal}>
              Nos informe clicando aqui.
            </S.SuggestionButton>
          </S.NoIntegrationText>

          <S.IntegrationsWrapper>
            {ADS_PROVIDERS.map(integration => {
              const Icon = integration.icon;

              return (
                <S.InputGroup key={integration.identifier}>
                  <S.CheckboxWrapper htmlFor={integration.identifier}>
                    <S.CheckboxTextWrapper>
                      <Icon size={24} />
                      <S.CheckboxText>{integration.name}</S.CheckboxText>
                    </S.CheckboxTextWrapper>
                  </S.CheckboxWrapper>

                  <S.Checkbox
                    value={integration.identifier}
                    defaultChecked={isDefaultChecked(integration)}
                    id={integration.identifier}
                    onChange={onChange}
                  />
                </S.InputGroup>
              );
            })}
          </S.IntegrationsWrapper>
        </S.Body>

        <Save onCancel={toggle} onSave={onSave} />
      </S.Sidemodal>

      <SuggestionModal isOpen={isSuggestionModalOpen} toggle={handleSuggestionModalOpen} />
    </>
  );
};

export default Sidemodal;
