import styled from 'styled-components/macro';

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.font};
  margin-bottom: 10px;
  font-weight: 400;
`;
