const sortDescValue = (value: number): number => {
  return value === 0 ? Infinity : value;
};

const sortAscValue = (value: number): number => {
  return value === 0 ? -Infinity : value;
};

export const sortStringData = (
  currentData: string,
  nextData: string,
  type: 'ASC' | 'DESC' | undefined,
): number => {
  const isDescType = type === 'DESC';

  if (isDescType && currentData > nextData) return 1;

  if (isDescType && currentData < nextData) return -1;

  if (isDescType && currentData === nextData) return 0;

  if (nextData > currentData) return 1;

  if (nextData < currentData) return -1;

  return 0;
};

export const sortNumberData = (
  currentData: number,
  nextData: number,
  type: 'ASC' | 'DESC' | undefined,
): number => {
  if (type === 'ASC') {
    return sortDescValue(currentData) - sortDescValue(nextData);
  }

  return sortAscValue(nextData) - sortAscValue(currentData);
};
