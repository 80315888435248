import React from 'react';
import { SimplePlay, SimplePause } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useDate } from '@helpers/hooks/useDate';
import { useCustomValuesOut } from '@helpers/hooks/pages/dashboard/customValues/useCustomValuesOut';

import { currencyFormatter, currencyToNumber, numberFormatter } from '@helpers/masks';

import { updateCustomValueSchema } from '@helpers/validators/dashboard/customValues/customValues';

import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';
import SideModalSave from '@components/common/core/Utils/SideModalSave';

import * as S from './styles';

const Body: React.FC = () => {
  const theme: any = useTheme();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(updateCustomValueSchema) });
  const { utcToZonedTime, format } = useDate();
  const {
    customValueToEdit,
    updateCustomValue,
    handleEditCustomValueSidemodalOpen,
    isUpdatingCustomValue,
  } = useCustomValuesOut();

  const amountInput = register('amount');

  const [isCustomValuePaused, setIsCustomValuePaused] = React.useState<boolean>(false);
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>();
  const [sendNotificationsChecked, setSendNotificationsChecked] = React.useState<boolean>(false);

  const handleCustomValuePaused = React.useCallback(
    () => setIsCustomValuePaused(!isCustomValuePaused),
    [isCustomValuePaused],
  );

  const onSubmit = React.useCallback(
    async data => {
      await updateCustomValue({
        amount: currencyToNumber(data.amount),
        currency: data.currency,
        description: data.description,
        is_active: !isCustomValuePaused,
        period: data.period,
        end_date: selectedDate && format(selectedDate, 'yyyy-MM-dd'),
        send_notification: sendNotificationsChecked,
      });
    },
    [isCustomValuePaused, selectedDate, updateCustomValue, format, sendNotificationsChecked],
  );

  const handleAmountEdit = React.useCallback(event => {
    event.target.value = currencyFormatter(event.target.value);
  }, []);

  const handleSendNotificationsCheckbox = React.useCallback(event => {
    if (event.target) return;

    setSendNotificationsChecked(event.checked);
  }, []);

  React.useEffect(() => {
    if (customValueToEdit) {
      setIsCustomValuePaused(!customValueToEdit.is_active);
    }

    if (customValueToEdit?.end_date) {
      setSelectedDate(utcToZonedTime(customValueToEdit.end_date));
    }

    if (customValueToEdit?.send_notification) {
      setSendNotificationsChecked(customValueToEdit.send_notification);
    }
  }, [customValueToEdit, utcToZonedTime]);

  return (
    <S.BodyWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.PauseButton type="button" onClick={handleCustomValuePaused}>
          <S.IconWrapper>
            {isCustomValuePaused ? (
              <SimplePlay color={theme.colors.background[15]} size={12} />
            ) : (
              <SimplePause color={theme.colors.background[15]} size={12} />
            )}
          </S.IconWrapper>

          <S.PauseButtonText>
            {isCustomValuePaused
              ? 'Continuar lançamento de cobranças'
              : 'Pausar lançamento de cobranças'}
          </S.PauseButtonText>
        </S.PauseButton>

        <S.InputGroup>
          <S.Label>Moeda</S.Label>

          <S.Select {...register('currency')} defaultValue={customValueToEdit?.currency}>
            <S.Option value="BRL">Real</S.Option>
            <S.Option value="USD">Dólar</S.Option>
          </S.Select>
          {errors.currency && <Text isErrorFeedback>{errors.currency.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Valor</S.Label>

          <S.Input
            {...amountInput}
            type="text"
            placeholder="Insira o valor de custo"
            onChange={handleAmountEdit}
            defaultValue={numberFormatter(customValueToEdit?.amount || 0, 2)}
          />
          {errors.amount && <Text isErrorFeedback>{errors.amount.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Descrição</S.Label>

          <S.Input
            {...register('description')}
            type="text"
            placeholder="Insira uma descrição para o custo"
            defaultValue={customValueToEdit?.description}
          />
          {errors.description && <Text isErrorFeedback>{errors.description.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Frequência</S.Label>

          <S.Select {...register('period')} defaultValue={customValueToEdit?.period}>
            <S.Option value="ONCE">Única</S.Option>
            <S.Option value="DAILY">Diária</S.Option>
            <S.Option value="WEEKLY">Semanal</S.Option>
            <S.Option value="MONTHLY">Mensal</S.Option>
            <S.Option value="QUARTERLY">Trimestral</S.Option>
            <S.Option value="SEMI_ANNUALY">Semestral</S.Option>
            <S.Option value="ANNUALLY">Anual</S.Option>
          </S.Select>
          {errors.period && <Text isErrorFeedback>{errors.period.message}</Text>}
        </S.InputGroup>

        <S.CheckboxInputGroup>
          <S.Checkbox
            checked={customValueToEdit?.send_notification}
            onChange={handleSendNotificationsCheckbox}
            id="send-custom-values-notifications"
          />
          <S.Label htmlFor="send-custom-values-notifications">
            Ativar notificação recorrente de despesas
          </S.Label>
        </S.CheckboxInputGroup>

        {selectedDate && (
          <S.InputGroup>
            <S.Label>Terminar em:</S.Label>

            <S.DayPicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          </S.InputGroup>
        )}

        <SideModalSave
          type="submit"
          successButtonText="Salvar"
          cancelButtonText="Cancelar"
          onCancel={handleEditCustomValueSidemodalOpen}
          isLoading={isUpdatingCustomValue}
          isDisabled={isUpdatingCustomValue}
        />
      </Form>
    </S.BodyWrapper>
  );
};

export default Body;
