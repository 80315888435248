import React from 'react';

import { EditVariantProvider } from '@helpers/hooks/pages/dashboard/kit/useEditVariant';
import { useEditKit } from '@helpers/hooks/pages/dashboard/kit/useEditKit';
import Variant from './Variant';
import NewVariant from './NewVariant';

import * as S from './styles';

const Variants: React.FC = () => {
  const { editKitVariants } = useEditKit();

  return (
    <S.Wrapper>
      {editKitVariants.map((variant, index) => (
        <EditVariantProvider
          key={`${variant?.variant?.alias_id || 'new_variant'}${index * 1}`}
          defaultVariant={variant.variant}
        >
          <Variant variant={variant} index={index} />
        </EditVariantProvider>
      ))}

      <NewVariant />
    </S.Wrapper>
  );
};

export default Variants;
