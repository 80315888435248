import styled, { css } from 'styled-components/macro';

import { IWrapperProps } from '@domain/interfaces/dashboard/FinancialDetails/IFinancialDetails';
import { ETableType } from '@domain/enums/dashboard/financialDetails/EFinancialDetails';
import TextBase from '@components/common/core/DataDisplay/Text';

export const PlanName = styled(TextBase)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.green.default};
`;

export const Text = styled(TextBase)`
  display: inline-block;
  margin-bottom: 42px;
  text-align: center;
`;

export const Wrapper = styled.div<IWrapperProps>`
  max-width: 1280px;
  width: 100%;
  transition: all 0.7s ease-in-out;

  ${({ tableType }) =>
    tableType === ETableType.DAILY &&
    css`
      max-width: 2500px;
    `}
`;
