import React from 'react';

import { Monetizze as Icon, Copy } from '@profitfy/pakkun-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';
import { useForm } from 'react-hook-form';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { ETextSize, ETextWeight } from '@domain/enums/components/EText';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useInfoProduct } from '@helpers/hooks/pages/onboarding/useInfoProduct';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { monetizzeSchema } from '@helpers/validators/dashboard/integrationCenter/infoProduct/monetizze';

import monetizzeService from '@services/pages/dashboard/integrationCenter/infoProduct/platforms/monetizze';

import NextStep from '@components/Onboarding/NextStep';
import Text from '@components/common/core/DataDisplay/Text';

import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Monetizze: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();

  const { selectedMarketings } = useInfoProduct();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { isLoadingTrial, handleStoreBonus } = useOnboarding();

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    watch,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(monetizzeSchema),
  });

  const [webhookURL, setWebhookURL] = React.useState<string>('any_webhook_url');
  const [isURLCopied, setIsURLCopied] = React.useState<boolean>(false);
  const [isGettingCredential] = React.useState<boolean>(false);
  const [isCreatingCredential, setIsCreatingCredential] = React.useState<boolean>(false);
  const [isGettingWebhookURL, setIsGettingWebhookURL] = React.useState<boolean>(false);

  const [webhookTokenValue] = getValues(['webhook_token']);
  const [consumerKeyValue] = getValues(['consumer_key']);

  watch();

  const webhookTokenInputRegister = register('webhook_token');
  const consumerKeyInputRegister = register('consumer_key');

  const handleCopyURL = React.useCallback(() => {
    navigator.clipboard.writeText(webhookURL);

    setIsURLCopied(true);

    toast.success('URL copiada com sucesso.');
  }, [toast, webhookURL]);

  const onSubmit = React.useCallback(
    async formData => {
      setIsCreatingCredential(true);

      try {
        await monetizzeService.createCredential({ storeAliasId, data: { ...formData } });

        toast.success('Credencial criada com sucesso.');

        setIsCreatingCredential(false);

        if (selectedMarketings.length) {
          history.push(`/onboarding/${storeAliasId}/info-product/integrations/marketing`);
        } else {
          await handleStoreBonus();
        }
      } catch (error: any) {
        toast.error(getTranslatedIntegrationsError(error));
      } finally {
        setIsCreatingCredential(false);
      }
    },
    [toast, storeAliasId, handleStoreBonus, history, selectedMarketings],
  );

  const generateWebhookURL = React.useCallback(async () => {
    setIsGettingWebhookURL(true);

    try {
      const { data } = await monetizzeService.generateWebhookURL({ storeAliasId });

      setWebhookURL(data?.webhook_url);
      setIsGettingWebhookURL(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [storeAliasId, toast]);

  React.useEffect(() => {
    generateWebhookURL();
  }, [generateWebhookURL]);

  const isWebhookTokenError = Boolean(errors.webhook_token);
  const isConsumerKeyError = Boolean(errors.consumer_key);

  const isNextStepButtonDisabled =
    isLoadingTrial || isCreatingCredential || !webhookTokenValue || !consumerKeyValue;

  if (isGettingCredential || isGettingWebhookURL) {
    return <SkeletonLoading />;
  }

  return (
    <S.Wrapper>
      <S.FormWrapper>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Header>
            <S.Title>Integre sua plataforma de infoproduto</S.Title>
          </S.Header>

          <S.IntegrationTitle>
            <Icon size={36} />

            <S.IntegrationTitleText fontWeight={EHeadingWeight.REGULAR}>
              Integração com Monetizze
            </S.IntegrationTitleText>
          </S.IntegrationTitle>

          <S.Body>
            <S.WebhookURLWrapper onClick={handleCopyURL} type="button">
              <S.WebhookURL weight={ETextWeight.MEDIUM}>{webhookURL}</S.WebhookURL>
              <S.IconWrapper>
                <Copy size={18} color={theme.colors.gray[1]} outline={!isURLCopied} />
              </S.IconWrapper>
            </S.WebhookURLWrapper>

            <S.HorizontalRule />

            <S.InputGroup>
              <S.Label>Chave única</S.Label>

              <S.Input
                {...webhookTokenInputRegister}
                type="text"
                value={webhookTokenValue}
                placeholder="Chave única da Monetizze"
                isError={isWebhookTokenError}
              />
              {isWebhookTokenError && <Text isErrorFeedback>{errors.webhook_token.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Chave do Cliente</S.Label>

              <S.Input
                {...consumerKeyInputRegister}
                type="text"
                value={consumerKeyValue}
                placeholder="Chave do Cliente Monetizze"
                isError={isConsumerKeyError}
              />
              {isConsumerKeyError && <Text isErrorFeedback>{errors.consumer_key.message}</Text>}
            </S.InputGroup>
          </S.Body>

          <NextStep
            type="submit"
            isLoading={isLoadingTrial || isCreatingCredential}
            disabled={isNextStepButtonDisabled}
          >
            Próximo passo
          </NextStep>
        </S.Form>
      </S.FormWrapper>

      <S.TutorialWrapper>
        <S.TutorialImage
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/onboarding/images/content/onboarding/onboarding-integration-tutorial.svg"
          alt="Profitfy.me Integration Tutorial"
        />
        <S.TutotialTitle weight={ETextWeight.BOLD}>Tutorial de integração</S.TutotialTitle>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          1 - Copie a URL no campo ao lado.
        </S.TutotialStep>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          2 -{' '}
          <S.Link href="https://app.monetizze.com.br/ferramentas/postback" target="_blank">
            Clique aqui e vá para a página de postback da Monetizze.
          </S.Link>
        </S.TutotialStep>

        <S.StepsWrapper>
          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              No campo &quot;Produto&quot;, selecione &quot;Todos os produtos&quot;.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              No campo &quot;Tipo&quot;, selecione &quot;Postback (server to server)&quot;.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              No campo &quot;URL&quot;, cole a URL copiada no campo ao lado.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              No campo &quot;Eventos&quot;, selecione todas as opções.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              Após preencher os campos, clique no botão &quot;Salvar&quot;.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              Para finalizar, no canto inferior esquerdo, copie a &quot;Chave única&quot;.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>Cole no campo ao lado.</S.StepText>
          </S.StepWrapper>
        </S.StepsWrapper>

        <S.TutotialStep weight={ETextWeight.BOLD} size={ETextSize.B5}>
          3 -{' '}
          <S.Link href="https://app.monetizze.com.br/ferramentas/api" target="_blank">
            Clique aqui e vá para a página de API da Monetizze.
          </S.Link>
        </S.TutotialStep>

        <S.StepsWrapper>
          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              No campo &quot;Descrição&quot;, digite o nome &quot;Profitfy.me&quot;.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>Clique no botão &quot;Salvar&quot;.</S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>
              Copie o valor no campo &quot;X_CONSUMER_KEY&quot;.
            </S.StepText>
          </S.StepWrapper>

          <S.StepWrapper>
            <S.StepBullet />
            <S.StepText size={ETextSize.B5}>Cole no campo ao lado.</S.StepText>
          </S.StepWrapper>
        </S.StepsWrapper>
      </S.TutorialWrapper>
    </S.Wrapper>
  );
};

export default Monetizze;
