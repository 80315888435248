import { ApiService } from '@services/api';
import { IForgotPasswordProps } from '@domain/interfaces/auth/IForgotPassword';
import { AUTH_API_DOMAIN } from '@constants/api';

export class ForgotPasswordService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public forgotPassword(data: IForgotPasswordProps): Promise<any> {
    return this.apiService.post('/auth/forgot-password', { ...data });
  }
}

export const forgotPasswordService = new ForgotPasswordService(AUTH_API_DOMAIN);

export default forgotPasswordService;
