import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  height: 42px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 5px;
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[5]};
  }
`;
