import React from 'react';

import { IImageProps } from '@domain/interfaces/components/IImage';

import * as S from './styles';

const Image: React.FC<IImageProps> = ({
  src,
  alt,
  onError,
  onClick,
  onLoad,
  isHiddenContent,
  ...rest
}) => {
  if (isHiddenContent) {
    return <S.HiddenContent {...rest} />;
  }

  return (
    <S.Image src={src} alt={alt} onError={onError} onLoad={onLoad} onClick={onClick} {...rest} />
  );
};

export default React.memo(Image);
