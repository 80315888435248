import React from 'react';

import Select from '@components/common/core/Utils/Filter/Filters/Select';
import Text from '@components/common/core/Utils/Filter/Filters/Text';
import DateRangePicker from '@components/common/core/Utils/Filter/Filters/DateRangePicker';
import AdsManagerAdSenseCredential from '@components/common/core/Utils/Filter/Filters/AdsManagerAdSenseCredential';
import AdsManagerAdSenseAccount from '@components/common/core/Utils/Filter/Filters/AdsManagerAdSenseAccount';
import Products from '@components/common/core/Utils/Filter/Filters/Products';
import {
  IFilterProps,
  IFilterQueryStringData,
  ISelectedFilter,
} from '@domain/interfaces/components/IFilter';

export const parseDataFilter = (data: Array<ISelectedFilter>): IFilterQueryStringData => {
  const result = data.reduce((queryObject, currentValue) => {
    if (currentValue.field === 'date') {
      return {
        ...queryObject,
        start_date: currentValue.value.startDate,
        end_date: currentValue.value.endDate,
      };
    }

    return {
      ...queryObject,
      [currentValue.field]: currentValue.value,
    };
  }, {});

  return result;
};

export const getFilterComponent = (filterType: string): React.FC<IFilterProps> =>
  ({
    SELECT: Select,
    TEXT: Text,
    DATE_RANGE_PICKER: DateRangePicker,
    ADS_MANAGER_AD_SENSE_CREDENTIAL: AdsManagerAdSenseCredential,
    ADS_MANAGER_AD_SENSE_ACCOUNT: AdsManagerAdSenseAccount,
    PRODUCTS: Products,
  }[filterType] || Select);
