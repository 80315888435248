import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ILimitationPageProps } from '@domain/interfaces/components/ILimitationPage';
import { IParams } from '@domain/interfaces/IParams';

import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const LimitationPage: React.FC<ILimitationPageProps> = ({
  imageSrc,
  title,
  description,
  trackingPropertyName,
}) => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { analytics, user } = useConfig();

  const onSeePlans = React.useCallback(() => {
    analytics?.track(
      'Limited Feature Page Button Clicked',
      {
        type: trackingPropertyName,
        source: 'SEE_PLANS',
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );

    history.push(`/${storeAliasId}/dashboard/subscription?tab=plans`);
  }, [history, storeAliasId, analytics, user, trackingPropertyName]);

  React.useEffect(() => {
    analytics?.track(
      'Limited Feature Page Viewed',
      {
        type: trackingPropertyName,
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );
  }, [analytics, user, storeAliasId, trackingPropertyName]);

  return (
    <S.Wrapper>
      <S.Image src={imageSrc} alt="Profitfy Limited Feature" />

      <S.ContentWrapper>
        <S.TitleAndDescription>
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>
        </S.TitleAndDescription>

        <S.ButtonsWrapper>
          <S.SeePlansButton onClick={onSeePlans}>Consultar planos</S.SeePlansButton>
        </S.ButtonsWrapper>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default LimitationPage;
