import styled from 'styled-components/macro';

import TableBase from '@components/common/core/Utils/Table';
import ButtonWithIcon from '@components/common/core/Inputs/ButtonWithIcon';
import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const Container = styled.div`
  max-width: 1262px;
  width: 100%;
  padding-top: 50px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const Title = styled(HeadingBase)``;

export const NewKitButton = styled(ButtonWithIcon)``;

export const KitTable = styled(TableBase)``;
