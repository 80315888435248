import { ApiService } from '@services/api';
import { useFetch } from '@providers/Fetch/useFetch';
import { API_DASHBOARD_DOMAIN } from '@constants/api';

import {
  IGetProductMarketingProps,
  IGetProductMarketingResponse,
} from '@domain/interfaces/dashboard/ProductAnalytics/IProductMarketing';

export class ProductMarketingService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getProductMarketing({
    storeAliasId,
    productAliasId,
    startDate,
    endDate,
  }: IGetProductMarketingProps): IGetProductMarketingResponse {
    const { data, isValidating, mutate, error } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/products/${productAliasId}/dashboards/marketing?start_date=${startDate}&end_date=${endDate}`,
      this.apiService,
    );

    const isLoading = !data && !error;

    return {
      marketingReport: data,
      isLoading,
      isValidating,
      error,
      mutate,
    };
  }
}

const productMarketingService = new ProductMarketingService(API_DASHBOARD_DOMAIN);

export default productMarketingService;
