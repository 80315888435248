import React from 'react';
import { useTheme } from 'styled-components';
import { X as CloseIcon } from 'phosphor-react';
import { Dsers } from '@profitfy/pakkun-icons';

import { useLocalStorage } from '@helpers/hooks/useLocalStorage';
import { useMediaQuery } from '@helpers/hooks/useMediaQuery';
import { useDate } from '@helpers/hooks/useDate';

import { getEnvironment } from '@helpers/utils/environment';

import * as S from './styles';

const DsersIssuesAnnouncement: React.FC = () => {
  const theme = useTheme();
  const isMobileWidth = useMediaQuery('628px');
  const { isAfter } = useDate();

  const [showDsersAnnouncement, setShowDsersAnnouncement] = useLocalStorage(
    `@profitfy:${getEnvironment()}/dsers-issues-announcement`,
    true,
  );

  const onCloseDsersAnnouncement = React.useCallback(() => {
    setShowDsersAnnouncement(false);
  }, [setShowDsersAnnouncement]);

  React.useEffect(() => {
    if (showDsersAnnouncement) {
      const isAfterDate = isAfter(new Date(), new Date('2023-09-25T00:00:00.000Z'));

      if (isAfterDate) {
        setShowDsersAnnouncement(false);
      }
    }
  }, [isAfter, setShowDsersAnnouncement, showDsersAnnouncement]);

  return (
    <>
      {showDsersAnnouncement && !isMobileWidth && (
        <S.Wrapper>
          <S.VideoWrapper>
            <Dsers size={40} />
          </S.VideoWrapper>

          <S.SubTitle>Comunicado</S.SubTitle>
          <S.Title>Dsers</S.Title>
          <S.Description>
            Identificamos que houve uma atualização no DSERS e que alguns lojistas estão enfrentando
            dificuldades na realização das sincronizações.
          </S.Description>
          <S.Description>
            Estamos empenhados em efetuar as atualizações necessárias para que tudo retorne ao
            funcionamento normal.
          </S.Description>

          <S.CloseButton onClick={onCloseDsersAnnouncement}>
            <CloseIcon size={18} color={theme.colors.gray[2]} weight="bold" />
          </S.CloseButton>
        </S.Wrapper>
      )}
    </>
  );
};

export default DsersIssuesAnnouncement;
