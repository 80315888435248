import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

interface IFreePlanOrdersExceeded {
  isOpen: boolean;
  toggle: () => void;
}

const FreePlanOrdersExceeded: React.FC<IFreePlanOrdersExceeded> = ({ isOpen, toggle }) => {
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { analytics } = useConfig();

  const onSeePlansClick = React.useCallback(() => {
    analytics?.track(
      'Free Plan Orders Modal Button Clicked',
      {},
      { context: { groupId: storeAliasId } },
    );

    history.push(`/${storeAliasId}/dashboard/subscription?defaultTab=plans`);

    toggle();
  }, [history, storeAliasId, toggle, analytics]);

  React.useEffect(() => {
    if (isOpen) {
      analytics?.track(
        'Free Plan Orders Reached Modal Viewed',
        {},
        { context: { groupId: storeAliasId } },
      );
    }
  }, [analytics, storeAliasId, isOpen]);

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Content>
        <S.Image
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/subscription/exceeded-free-plan-orders.svg"
          alt="Profitfy Free Plan Exceeded Orders"
        />

        <S.DescriptionWrapper>
          <S.Title>Você alcançou 50 pedidos no plano gratuito!</S.Title>

          <S.Description>
            <S.DescriptionText>
              Parabéns por alcançar esse número! Agora você pode dar um passo a frente escolhendo um
              plano que melhor se adequa.
            </S.DescriptionText>
          </S.Description>

          <S.SeePlansButton onClick={onSeePlansClick}>Ver todos os planos</S.SeePlansButton>
        </S.DescriptionWrapper>
      </S.Content>
    </S.Modal>
  );
};

export default FreePlanOrdersExceeded;
