import React from 'react';

import { useMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useMessageSettings';

import { ECreateMessageStep, EMessageType } from '@domain/enums/dashboard/automations/EMessage';

import * as S from './styles';

const MessageTemplate: React.FC<any> = ({ template }) => {
  const {
    handleCreateNewMessageStep,
    handleContents,
    contents,
    handleSelectedMessageTrigger,
  } = useMessageSettings();

  const onTemplateClick = React.useCallback(() => {
    if (template.id === 'no-content') {
      handleCreateNewMessageStep(ECreateMessageStep.CONFIG_MESSAGE);
      handleSelectedMessageTrigger(EMessageType.ABANDONED_CART);
      return;
    }

    const { message_contents } = template;

    handleContents([...contents, ...message_contents]);
    handleSelectedMessageTrigger(template.type);
    handleCreateNewMessageStep(ECreateMessageStep.CONFIG_MESSAGE);
  }, [
    handleCreateNewMessageStep,
    template,
    contents,
    handleContents,
    handleSelectedMessageTrigger,
  ]);

  const Icon = template.icon;

  return (
    <S.Wrapper onClick={onTemplateClick}>
      <S.IconWrapper>
        <Icon size={48} />
      </S.IconWrapper>

      <S.TemplateName>{template.title}</S.TemplateName>

      <S.Description />
    </S.Wrapper>
  );
};

export default MessageTemplate;
