import React from 'react';
import { ShoppingCart, Truck, AirplaneTilt } from 'phosphor-react';
import { Barcode, Pix, Card } from '@profitfy/pakkun-icons';

import { ETriggers } from '@domain/enums/dashboard/automations/ETriggers';
import { IGetWorkflowTriggerIconResponse } from '@domain/interfaces/utils/automations/automationsManager/IAutomationsManager';

const emptyWorkflowTriggerObject = {
  icon: <></>,
  backgroundColor: '',
  name: '',
};

export const getWorkflowTriggerIcon = (type: string): IGetWorkflowTriggerIconResponse =>
  ({
    [ETriggers.ABANDONED_CART]: {
      icon: <ShoppingCart size={20} color="#0F1116" />,
      backgroundColor: '#59B578',
      name: 'Carrinho Abandonado',
    },
    [ETriggers.PIX]: {
      icon: <Pix size={20} color="#0F1116" outline />,
      backgroundColor: '#59B578',
      name: 'Pix',
    },
    [ETriggers.REJECTED_PAYMENT]: {
      icon: <Card size={20} color="#0F1116" outline />,
      backgroundColor: '#59B578',
      name: 'Cartão Recusado',
    },
    [ETriggers.BOLETO]: {
      icon: <Barcode size={20} color="#0F1116" outline />,
      backgroundColor: '#59B578',
      name: 'Boleto',
    },
    [ETriggers.SIMPLE_SHIPMENT]: {
      icon: <Truck size={20} color="#0F1116" />,
      backgroundColor: '#59B578',
      name: 'Rastreio criado',
    },
    [ETriggers.UPDATED_FULFILLMENT]: {
      icon: <AirplaneTilt size={20} color="#0F1116" />,
      backgroundColor: '#59B578',
      name: 'Rastreio por status',
    },
  }[type] || emptyWorkflowTriggerObject);
