import React from 'react';

import { ITermsOfAdhesionModal } from '@domain/interfaces/components/ITermsOfAdhesionModal';

import { useCheckout } from '@helpers/hooks/pages/subscription/useCheckout';

import logo from '@assets/img/brand/profitfy-icon.svg';

import TermsOfAdhesionContent from './TermsOfAdhesionContent';

import * as S from './styles';

const TermsOfAdhesionModal: React.FC<ITermsOfAdhesionModal> = ({
  isOpen,
  toggle,
  handleAccept,
}) => {
  const { isSubscribing } = useCheckout();

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.Header>
        <S.Logo src={logo} alt="Profitfy.me" />

        <S.Title>Termo de Adesão ao Plano</S.Title>
        <S.LastUpdatedText>Atualizado em 07 de dezembro de 2022</S.LastUpdatedText>
      </S.Header>

      <S.Body>
        <TermsOfAdhesionContent />
      </S.Body>

      <S.ConfirmButton isLoading={isSubscribing} onClick={handleAccept}>
        Eu aceito
      </S.ConfirmButton>
    </S.Modal>
  );
};

export default TermsOfAdhesionModal;
