import React from 'react';
import { Copy } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import { ECouponType } from '@domain/enums/common/coupon/ECouponType';
import { ICouponProps } from '@domain/interfaces/dashboard/AdminPanel/ICoupons';

import { useDate } from '@helpers/hooks/useDate';
import { useToast } from '@helpers/hooks/useToast';

import { numberFormatter } from '@helpers/masks';

import * as S from './styles';

export const Coupon: React.FC<ICouponProps> = ({ coupon }) => {
  const theme = useTheme();
  const { utcToZonedTime, format } = useDate();
  const { toast } = useToast();

  const copyIdentifier = React.useCallback(async () => {
    try {
      await navigator.clipboard.writeText(coupon.identifier.toUpperCase());

      toast.success('Cupom copiado com sucesso!');
    } catch {
      toast.error('Não foi possível copiar.');
    }
  }, [toast, coupon]);

  const copyId = React.useCallback(async () => {
    try {
      await navigator.clipboard.writeText(coupon.id);

      toast.success('ID do cupom copiado com sucesso!');
    } catch {
      toast.error('Não foi possível copiar.');
    }
  }, [toast, coupon]);

  const getFormattedType = React.useCallback(() => {
    if (coupon.type === ECouponType.FIXED) return 'Fixo';

    return 'Porcentagem';
  }, [coupon]);

  const getFormattedAmount = React.useCallback(() => {
    if (coupon.type === ECouponType.FIXED) return `R$${numberFormatter(coupon.amount, 2)}`;

    return `${numberFormatter(coupon.amount, 0)}%`;
  }, [coupon]);

  const getCouponStatus = React.useCallback(() => {
    if (coupon.is_active) return 'Ativo';

    return 'Expirado';
  }, [coupon]);

  const formattedExpirationDate = format(utcToZonedTime(new Date(coupon.expires_at)), 'dd/MM/yyyy');

  return (
    <S.Wrapper>
      <S.Name>{coupon.name}</S.Name>

      <S.IdentifierWrapper onClick={copyIdentifier}>
        <Copy size={16} color={theme.colors.font} />
        <S.Identifier>{coupon.identifier.toUpperCase()}</S.Identifier>
      </S.IdentifierWrapper>

      <S.Type>{getFormattedType()}</S.Type>

      <S.Amount>{getFormattedAmount()}</S.Amount>

      <S.Status>{getCouponStatus()}</S.Status>

      <S.ExpirationDate>{formattedExpirationDate}</S.ExpirationDate>

      <S.OptionsWrapper>
        <S.Option onClick={copyId}>
          <Copy size={16} color={theme.colors.font} />
        </S.Option>
      </S.OptionsWrapper>
    </S.Wrapper>
  );
};

export default Coupon;
