import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';

import { useCoupon } from '@helpers/hooks/common/coupons/useCoupon';

import EmptyData from '@components/common/core/DataDisplay/EmptyData';
import Coupon from './Coupon';

import * as S from './styles';

const Coupons: React.FC = () => {
  const { coupons } = useCoupon();

  return (
    <S.Wrapper>
      <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.REGULAR}>
        Cupons existentes
      </S.Title>

      {Boolean(!coupons.length) && <EmptyData content="Não encontramos nenhum cupom" />}

      {Boolean(coupons.length) && (
        <S.CouponsWrapper>
          {coupons.map(coupon => (
            <Coupon coupon={coupon} key={coupon.alias_id} />
          ))}
        </S.CouponsWrapper>
      )}
    </S.Wrapper>
  );
};

export default Coupons;
