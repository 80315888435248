import React from 'react';

import { HandManual } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { useDate } from '@helpers/hooks/useDate';
import { getProviderIcon } from '@helpers/dashboard/orders/ordersUtils';
import { IOrderTableDataProps } from '@domain/interfaces/dashboard/Orders/IOrderTableData';

import Table from '@components/common/core/Utils/Table';

import * as S from './styles';

const Order: React.FC<IOrderTableDataProps> = ({ order }) => {
  const theme = useTheme();

  const { utcToZonedTime, format } = useDate();

  const providerUrl = order?.provider_detail_url;
  const orderName = order?.name;

  const date = utcToZonedTime(new Date(order.provider_created_at));
  const dateFormat = 'dd/MM/yyyy HH:mm:ss';

  const formattedProviderCreatedAt = format(date, dateFormat);

  const ProviderIcon = getProviderIcon(order?.provider);

  const isPopoverShow = Boolean(order) && order?.is_updated_manually;

  return (
    <Table.Data>
      <S.OrderDetails>
        <S.IconWrapper>
          {isPopoverShow && (
            <S.Popover>
              <S.ManualIconTrigger>
                <HandManual size={13} color={theme.colors.gray[1]} />
              </S.ManualIconTrigger>

              <S.Popover.Content side="top" sideOffset={5}>
                <S.ManuallyIconPopoverContent>
                  Pedido foi editado manualmente
                </S.ManuallyIconPopoverContent>
                <S.Popover.Arrow />
              </S.Popover.Content>
            </S.Popover>
          )}

          <ProviderIcon size={20} />
        </S.IconWrapper>

        <S.OrderTextWrapper>
          {Boolean(providerUrl) && (
            <S.OrderNumber href={providerUrl} target="_blank">
              #{orderName || ''}
            </S.OrderNumber>
          )}

          {Boolean(!providerUrl) && <S.OrderNumber>#{orderName || ''}</S.OrderNumber>}

          <S.OrderDate>
            {formattedProviderCreatedAt}
            {undefined}
          </S.OrderDate>
        </S.OrderTextWrapper>
      </S.OrderDetails>
    </Table.Data>
  );
};

export default Order;
