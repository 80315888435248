import { X as CloseIcon, Option, Plus } from 'phosphor-react';
import React from 'react';
import { useUpdateNodeInternals } from 'react-flow-renderer';
import { useTheme } from 'styled-components';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { ETriggers } from '@domain/enums/dashboard/automations/ETriggers';
import { ELogicalOperator } from '@domain/enums/dashboard/automations/node/conditionalNode/ELogicalOperator';
import { IConditionalNodeContent } from '@domain/interfaces/dashboard/Automations/Canvas/Node/ConditionalNode/IConditionalNodeContent';
import { IConditionalNodeModalProps } from '@domain/interfaces/dashboard/Automations/Canvas/Node/ConditionalNode/IConditionalNodeModal';

import { selectDefaultConditionalNodeContent } from '@helpers/utils/dashboard/automations/Nodes/selectDefaultConditionalNodeContent';

import Tab from './Tab';

import AbandonCartModal from './AbandonCartModal';
import BoletoModal from './BoletoModal';
import DeniedCardModal from './DeniedCardModal';
import PixModal from './PixModal';
import UpdateFulfillmentModal from './UpdateFulfillmentModal';

import ApprovedOrderModal from './ApprovedOrderModal';
import CanceledOrderModal from './CanceledOrderModal';
import CouponModal from './CouponModal';
import SimpleShipmentModal from './SimpleShipmentModal';
import * as S from './styles';

const ConditionalModal: React.FC<IConditionalNodeModalProps> = ({
  isOpen,
  toggle,
  rules: initialRules,
}) => {
  const { colors } = useTheme();
  const { selectedWorkflowTriggerType, setNodes, selectedNodeId } = useAutomation();
  const updateNodeInternals = useUpdateNodeInternals();

  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const [logicalOperator, setLogicalOperator] = React.useState<ELogicalOperator>(() => {
    return selectedTab === 0 ? ELogicalOperator.AND : ELogicalOperator.OR;
  });
  const [rules, setRules] = React.useState<IConditionalNodeContent[]>(initialRules);

  React.useEffect(() => {
    setRules([selectDefaultConditionalNodeContent(selectedWorkflowTriggerType as ETriggers)]);
  }, [selectedWorkflowTriggerType]);

  const onChangeTab = React.useCallback(index => setSelectedTab(index), []);

  const getSplittedItems = (): {
    firstRule: IConditionalNodeContent;
    restRules: IConditionalNodeContent[];
  } => {
    const restRules = [...rules];

    const firstRule = restRules.shift() as IConditionalNodeContent;

    return {
      firstRule,
      restRules,
    };
  };

  const { firstRule, restRules } = getSplittedItems();

  React.useEffect(() => {
    const seletedOperator = selectedTab === 0 ? ELogicalOperator.AND : ELogicalOperator.OR;
    setLogicalOperator(seletedOperator);
  }, [selectedTab]);

  const handleChangeRule = React.useCallback(
    (values: any, i: number) => {
      const arrayCopy = [...rules];
      arrayCopy[i] = values;
      setRules(arrayCopy);
    },
    [rules],
  );

  const handleAddRule = React.useCallback(() => {
    const nextConditionalValues = selectDefaultConditionalNodeContent(
      selectedWorkflowTriggerType as ETriggers,
    );
    setRules([...rules, nextConditionalValues]);
  }, [rules, selectedWorkflowTriggerType]);

  const handleDeleteRule = React.useCallback(
    (i: number) => {
      const arrayCopy = rules.map((condition, index) => {
        if (i === index) {
          return null;
        }
        return condition;
      });

      const newConditionalValues = arrayCopy.filter(
        condition => condition,
      ) as IConditionalNodeContent[];

      setRules(newConditionalValues);
    },
    [rules],
  );

  const handleCloseModal = React.useCallback(() => {
    setRules(initialRules);
    toggle();
  }, [initialRules, toggle]);

  const handleSaveRules = React.useCallback(() => {
    setNodes((nds: any) =>
      nds.map((node: any) => {
        if (node.id === selectedNodeId) {
          node.data = {
            ...node.data,
            rules,
          };
        }

        return node;
      }),
    );

    updateNodeInternals(selectedNodeId as string);
    toggle();
  }, [selectedNodeId, setNodes, rules, updateNodeInternals, toggle]);

  React.useEffect(() => {
    setRules(initialRules);
  }, [initialRules]);

  if (!rules.length) {
    return <></>;
  }

  return (
    <S.Wrapper isOpen={isOpen} toggle={handleCloseModal}>
      <S.Row>
        <S.GroupTitle>
          <Option size={28} color={colors.gray[1]} />
          <S.Title>Configurar condição</S.Title>
        </S.GroupTitle>
        <S.CloseButton onClick={handleCloseModal}>
          <CloseIcon size={20} color={colors.gray[3]} />
        </S.CloseButton>
      </S.Row>

      {restRules.length > 0 && (
        <Tab defaultTab={selectedTab} onChangeTab={onChangeTab}>
          <div>E</div>
          <div>Ou</div>
        </Tab>
      )}

      {selectedWorkflowTriggerType === ETriggers.ABANDONED_CART && (
        <AbandonCartModal
          firstRule={firstRule}
          rules={restRules}
          handleChangeRule={handleChangeRule}
          handleDeleteRule={handleDeleteRule}
          logicalOperator={logicalOperator}
        />
      )}

      {selectedWorkflowTriggerType === ETriggers.PIX && (
        <PixModal
          firstRule={firstRule}
          rules={restRules}
          handleChangeRule={handleChangeRule}
          handleDeleteRule={handleDeleteRule}
          logicalOperator={logicalOperator}
        />
      )}

      {selectedWorkflowTriggerType === ETriggers.BOLETO && (
        <BoletoModal
          firstRule={firstRule}
          rules={restRules}
          handleChangeRule={handleChangeRule}
          handleDeleteRule={handleDeleteRule}
          logicalOperator={logicalOperator}
        />
      )}

      {selectedWorkflowTriggerType === ETriggers.REJECTED_PAYMENT && (
        <DeniedCardModal
          firstRule={firstRule}
          rules={restRules}
          handleChangeRule={handleChangeRule}
          handleDeleteRule={handleDeleteRule}
          logicalOperator={logicalOperator}
        />
      )}

      {selectedWorkflowTriggerType === ETriggers.UPDATED_FULFILLMENT && (
        <UpdateFulfillmentModal
          firstRule={firstRule}
          rules={restRules}
          handleChangeRule={handleChangeRule}
          handleDeleteRule={handleDeleteRule}
          logicalOperator={logicalOperator}
        />
      )}

      {selectedWorkflowTriggerType === ETriggers.SIMPLE_SHIPMENT && (
        <SimpleShipmentModal
          firstRule={firstRule}
          rules={restRules}
          handleChangeRule={handleChangeRule}
          handleDeleteRule={handleDeleteRule}
          logicalOperator={logicalOperator}
        />
      )}

      {selectedWorkflowTriggerType === ETriggers.APPROVED_ORDER && (
        <ApprovedOrderModal
          firstRule={firstRule}
          rules={restRules}
          handleChangeRule={handleChangeRule}
          handleDeleteRule={handleDeleteRule}
          logicalOperator={logicalOperator}
        />
      )}

      {selectedWorkflowTriggerType === ETriggers.CANCELED_ORDER && (
        <CanceledOrderModal
          firstRule={firstRule}
          rules={restRules}
          handleChangeRule={handleChangeRule}
          handleDeleteRule={handleDeleteRule}
          logicalOperator={logicalOperator}
        />
      )}

      {selectedWorkflowTriggerType === ETriggers.COUPON && (
        <CouponModal
          firstRule={firstRule}
          rules={restRules}
          handleChangeRule={handleChangeRule}
          handleDeleteRule={handleDeleteRule}
          logicalOperator={logicalOperator}
        />
      )}

      <S.Row>
        <S.AddConditionButtonWrapper>
          <S.AddConditionButton
            onClick={handleAddRule}
            variant={EButtonVariant.SECONDARY_BUTTON}
            iconPrepend={Plus}
          >
            Nova condição
          </S.AddConditionButton>
        </S.AddConditionButtonWrapper>
      </S.Row>

      <S.SaveButtonWrapper onClick={handleSaveRules}>
        <S.SaveButton>Salvar configuração</S.SaveButton>
      </S.SaveButtonWrapper>
    </S.Wrapper>
  );
};

export default ConditionalModal;
