import styled from 'styled-components/macro';

export const InsightWrapper = styled.div`
  width: 24px;
  min-width: 24px;
  height: 26px;
  min-height: 26px;
  background-color: #24363f;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  overflow-x: scroll;
  scrollbar-width: none;
  height: 58px;
  padding: 10px 12px;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;
