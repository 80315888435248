import { v4 } from 'uuid';

import { ETriggers } from '@domain/enums/dashboard/automations/ETriggers';
import { IConditionalNodeContent } from '@domain/interfaces/dashboard/Automations/Canvas/Node/ConditionalNode/IConditionalNodeContent';
import {
  EAbandonCartType,
  EAbandonCartProductOptions,
} from '@domain/enums/dashboard/automations/node/switchNode/EAbandonCart';
import {
  EPixType,
  EPixProductOptions,
} from '@domain/enums/dashboard/automations/node/switchNode/EPix';
import {
  EBoletoType,
  EBoletoProductOptions,
} from '@domain/enums/dashboard/automations/node/switchNode/EBoleto';
import {
  EDeniedCardType,
  EDeniedCardProductOptions,
} from '@domain/enums/dashboard/automations/node/switchNode/EDeniedCard';
import {
  EUpdateFulfillmentType,
  EUpdateFulfillmentProductOptions,
} from '@domain/enums/dashboard/automations/node/switchNode/EUpdateFulfillment';
import {
  ESimpleShipmentProductOptions,
  ESimpleShipmentType,
} from '@domain/enums/dashboard/automations/node/switchNode/ESimpleShipment';

export const selectDefaultSwitchNodeContent = (
  triggerType: ETriggers,
  index = 0,
): IConditionalNodeContent => {
  const triggers = {
    ABANDONED_CART: {
      id: v4(),
      type: EAbandonCartType.PRODUCT_NAME,
      options: EAbandonCartProductOptions.CONTAINS,
      input: '',
      output: index,
    },
    PIX: {
      id: v4(),
      type: EPixType.PRODUCT_NAME,
      options: EPixProductOptions.CONTAINS,
      input: '',
      output: index,
    },
    BOLETO: {
      id: v4(),
      type: EBoletoType.PRODUCT_NAME,
      options: EBoletoProductOptions.CONTAINS,
      input: '',
      output: index,
    },
    REJECTED_PAYMENT: {
      id: v4(),
      type: EDeniedCardType.PRODUCT_NAME,
      options: EDeniedCardProductOptions.CONTAINS,
      input: '',
      output: index,
    },
    UPDATED_FULFILLMENT: {
      id: v4(),
      type: EUpdateFulfillmentType.PRODUCT_NAME,
      options: EUpdateFulfillmentProductOptions.CONTAINS,
    },
    SIMPLE_SHIPMENT: {
      id: v4(),
      type: ESimpleShipmentType.PRODUCT_NAME,
      options: ESimpleShipmentProductOptions.CONTAINS,
      input: '',
      output: index,
    },
  };

  const defaultContent = {
    id: '',
    input: '',
    options: 'contains',
    type: 'product',
  };

  return triggers[triggerType] || defaultContent;
};
