import { WHATSAPP_CONFIG_API_DOMAIN } from '@constants/api';
import {
  ICreateWorkflowProps,
  IDeleteWorkflowProps,
  IListWorkflowToCanvasProps,
  IListWorkflowToCanvasResponse,
  IListWorkflowsPromiseProps,
  IListWorkflowsPromiseResponse,
  IListWorkflowsProps,
  IListWorkflowsResponse,
  ISelfTestWorkflowProps,
  IUpdateWorkflowProps,
} from '@domain/interfaces/common/automations/IWorkflows';
import { useFetch } from '@providers/Fetch/useFetch';
import { ApiService } from '@services/api';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';

export class AutomationsService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public listWorkflows({
    endDate,
    startDate,
    storeAliasId,
  }: IListWorkflowsProps): IListWorkflowsResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/workflows?start_date=${format(
        startDate,
        'yyyy-MM-dd',
      )}&end_date=${format(endDate, 'yyyy-MM-dd')}`,
      this.apiService,
    );

    const isLoading = !error && !data;

    return {
      workflow_metrics: {
        recovered_amount: data?.workflows_with_metrics?.recovered_amount,
        workflows_quantity: data?.workflows_with_metrics?.workflows_quantity,
        delivery_workflow_event_quantity:
          data?.workflows_with_metrics?.delivery_workflow_event_quantity,
      },
      workflows: data?.workflows_with_metrics?.workflows,
      mutate,
      error,
      isLoading,
      isValidating,
    };
  }

  public listWorkflowsPromise({
    storeAliasId,
    startDate,
    endDate,
  }: IListWorkflowsPromiseProps): Promise<AxiosResponse<IListWorkflowsPromiseResponse>> {
    return this.apiService.get<IListWorkflowsPromiseResponse>(
      `/api/v1/users/stores/${storeAliasId}/workflows?start_date=${format(
        startDate,
        'yyyy-MM-dd',
      )}&end_date=${format(endDate, 'yyyy-MM-dd')}`,
    );
  }

  public deleteWorkflow({ workflowAliasId, storeAliasId }: IDeleteWorkflowProps): Promise<any> {
    return this.apiService.delete(
      `/api/v1/users/stores/${storeAliasId}/workflows/${workflowAliasId}`,
    );
  }

  public updateWorkflow({
    data,
    workflowAliasId,
    storeAliasId,
  }: IUpdateWorkflowProps): Promise<any> {
    return this.apiService.put(
      `/api/v1/users/stores/${storeAliasId}/workflows/${workflowAliasId}`,
      { ...data },
    );
  }

  public upsertWorkflow({ data, storeAliasId }: ICreateWorkflowProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/workflows/upsert`, {
      ...data,
    });
  }

  public selfTestWorkflow({ data, storeAliasId }: ISelfTestWorkflowProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/workflows/self-test`, {
      ...data,
    });
  }

  public listWorkflowToCanvas({
    workflowAliasId,
    storeAliasId,
    startDate,
    endDate,
  }: IListWorkflowToCanvasProps): IListWorkflowToCanvasResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/workflows/${workflowAliasId}/canvas?start_date=${startDate}&end_date=${endDate}`,
      this.apiService,
    );

    const isLoading = !error && !data;

    return {
      workflow: data?.workflow,
      mutate,
      error,
      isLoading,
      isValidating,
    };
  }
}

const automationsService = new AutomationsService(WHATSAPP_CONFIG_API_DOMAIN);

export default automationsService;
