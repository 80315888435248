import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const NewNotificationIcon = styled.span`
  display: block;
  width: 8px;
  min-width: 8px;
  height: 8px;
  min-height: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.green.default};
`;

export const ReceivedAt = styled(TextBase)`
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 100%;
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray[3]};
  text-align: left;
`;

export const Description = styled(TextBase)`
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 100%;
  display: inline-block;
  text-align: left;
`;

export const Title = styled(TextBase)`
  font-size: 1rem;
  font-weight: 700;
  line-height: 100%;
  display: inline-block;
  text-align: left;
`;

export const NotificationContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const NotificationIcon = styled.div`
  max-width: 36px;
  max-height: 36px;
  width: 100%;
`;

export const Wrapper = styled.button`
  padding: 16px 24px;
  border: unset;
  background-color: unset;
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background[2]};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[4]};
  }
`;
