import styled from 'styled-components/macro';

export const Container = styled.div`
  display: block;
  position: relative;
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  margin: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndex.firstLayer};
`;

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border: 1px solid ${({ theme }) => theme.colors.gray[3]};
  background-color: ${({ theme }) => theme.colors.background[2]};
  border-radius: 50%;
  transition: all 0.3s;

  ${Container}:hover & {
    border-color: ${({ theme }) => theme.colors.green.dark};
  }

  ${Input}:checked ~ & {
    border-color: ${({ theme }) => theme.colors.green.dark};
  }

  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.green.dark};
  }
  ${Input}:checked ~ &:after {
    display: block;
  }
`;
