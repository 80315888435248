import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresProfitMarginValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresProfitMarginValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresProfitMarginValuesResponse => {
  const currentSelectedStoreProfitMarginAmountValue =
    currentSelectedStoreFinancialDetailsReportData?.profit_margin?.amount;

  const storesProfitMarginAmountValues = selectedStoresData?.map(
    value => value.financial_details_report?.profit_margin?.amount,
  );

  const profitMarginAmountValues = [
    ...storesProfitMarginAmountValues,
    currentSelectedStoreProfitMarginAmountValue,
  ];

  const reducedProfitMarginAmountValues = profitMarginAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  return {
    profit_margin_amount: reducedProfitMarginAmountValues,
  };
};
