import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import LinkBase from '@components/common/core/Navigation/Link';

export const ManageSubscriptionLink = styled(LinkBase)`
  color: ${({ theme }) => theme.colors.gray[1]};
  font-size: 0.875rem;
  text-decoration: underline;
  text-underline-offset: 4px;
  align-self: flex-start;
`;

export const CurrentProgressWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.green.default};
`;

export const ProgressWrapper = styled.div`
  position: relative;
  height: 4px;
  width: 100%;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.gray[4]};
`;

export const QuantityText = styled(TextBase)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[2]};

  & > strong {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.green.default};
  }
`;

export const OrdersText = styled(TextBase)`
  font-size: 0.875rem;
`;

export const QuantityTextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const QuotaWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Wrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray[3]};
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 236px;
  box-sizing: border-box;

  @media only screen and (max-height: 700px) {
    padding: 16px 24px;
  }
`;
