import React from 'react';
import { useParams } from 'react-router-dom';

import {
  IRenamePopoverContentProps,
  IUpdateAdSenseCampaignData,
} from '@domain/interfaces/dashboard/AdsManager/AdSenseCampaigns/IAdSenseCampaignsActions';
import { EButtonSize, EButtonVariant } from '@domain/enums/components/EButton';
import { IParams } from '@domain/interfaces/IParams';

import { useCampaigns } from '@helpers/hooks/pages/dashboard/adsManager/useCampaigns';
import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const RenamePopoverContent: React.FC<IRenamePopoverContentProps> = ({ setIsRenamePopoverOpen }) => {
  const {
    handleRenameModalOpen,
    isRenameModalOpen,
    mappedAdSenseCampaigns,
    onUpdateAdSenseCampaign,
  } = useCampaigns();
  const { analytics } = useConfig();
  const { storeAliasId } = useParams<IParams>();

  const foundAdSenseCampaign = mappedAdSenseCampaigns.find(
    campaign => campaign.alias_id === isRenameModalOpen,
  );

  const [name, setName] = React.useState<string>(foundAdSenseCampaign?.data[2].data || '');
  const [isRenamingCampaign, setIsRenamingCampaign] = React.useState<boolean>(false);

  const onCancel = React.useCallback(() => {
    handleRenameModalOpen(undefined);
    setIsRenamePopoverOpen(false);
  }, [setIsRenamePopoverOpen, handleRenameModalOpen]);

  const onNameChange = React.useCallback(event => {
    setName(event.target.value);
  }, []);

  const onUpdateClick = React.useCallback(async () => {
    if (foundAdSenseCampaign) {
      setIsRenamingCampaign(true);

      const data: IUpdateAdSenseCampaignData = {
        name,
      };

      await onUpdateAdSenseCampaign(isRenameModalOpen, data);

      analytics?.track(
        'Ads Manager Table Campaign Name Edited',
        {},
        { context: { groupId: storeAliasId } },
      );

      setIsRenamingCampaign(false);
      setIsRenamePopoverOpen(false);
    }
  }, [
    foundAdSenseCampaign,
    name,
    isRenameModalOpen,
    onUpdateAdSenseCampaign,
    setIsRenamePopoverOpen,
    analytics,
    storeAliasId,
  ]);

  return (
    <S.CustomPopoverContent sideOffset={5}>
      <S.Wrapper>
        <S.Input
          type="text"
          defaultValue={foundAdSenseCampaign?.data[2].data}
          onChange={onNameChange}
        />

        <S.ButtonsWrapper>
          <S.CancelButton
            type="button"
            onClick={onCancel}
            variant={EButtonVariant.SECONDARY}
            buttonSize={EButtonSize.MEDIUM}
            disabled={isRenamingCampaign}
          >
            Cancelar
          </S.CancelButton>
          <S.RenameButton
            type="button"
            onClick={onUpdateClick}
            buttonSize={EButtonSize.MEDIUM}
            disabled={isRenamingCampaign}
            isLoading={isRenamingCampaign}
          >
            Publicar
          </S.RenameButton>
        </S.ButtonsWrapper>
      </S.Wrapper>
    </S.CustomPopoverContent>
  );
};

export default RenamePopoverContent;
