import {
  IFinancialDetailsReport,
  IGetFinancialDashboardPromiseResponse,
} from '@domain/interfaces/dashboard/FinancialDashboard/IFinancialDashboard';

import { IGetStoresPixPendingValuesResponse } from '@domain/interfaces/utils/multiStoreSelect/financialDashboard';

export const getStoresPixPendingValues = (
  currentSelectedStoreFinancialDetailsReportData: IFinancialDetailsReport,
  selectedStoresData: IGetFinancialDashboardPromiseResponse[],
): IGetStoresPixPendingValuesResponse => {
  const currentSelectedStorePixPendingAmountValue =
    currentSelectedStoreFinancialDetailsReportData?.pix?.pending?.amount;

  const currentSelectedStorePixPendingQuantityValue =
    currentSelectedStoreFinancialDetailsReportData?.pix?.pending?.quantity;

  const storesPixPendingAmountValues = selectedStoresData?.map(
    value => value.financial_details_report?.pix?.pending?.amount,
  );

  const storesPixPendingQuantityValues = selectedStoresData?.map(
    value => value.financial_details_report?.pix?.pending?.quantity,
  );

  const pixPendingAmountValues = [
    ...storesPixPendingAmountValues,
    currentSelectedStorePixPendingAmountValue,
  ];

  const pixPendingQuantityValues = [
    ...storesPixPendingQuantityValues,
    currentSelectedStorePixPendingQuantityValue,
  ];

  const reducedPixPendingAmountValues = pixPendingAmountValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  const reducedPixPendingQuantityValues = pixPendingQuantityValues.reduce(
    (totalValue, currentValue) => {
      return totalValue + currentValue;
    },
  );

  return {
    pix_pending_amount: reducedPixPendingAmountValues,
    pix_pending_quantity: reducedPixPendingQuantityValues,
  };
};
