import React from 'react';
import { Tiny as TinyIcon, Copy } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { EEcommerceProvider } from '@domain/enums/common/integrations/EEcommerceProvider'; // TODO: change to ecommerce
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useEcommerce } from '@helpers/hooks/pages/onboarding/useEcommerce';
import { useDate } from '@helpers/hooks/useDate';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { tinySchema } from '@helpers/validators/dashboard/integrationCenter/ecommerce/tiny';

import { ECOMMERCE_PROVIDERS } from '@constants/common/integrations';

import integrationCenterService from '@services/pages/dashboard/integrationCenter/integrationCenter';
import tinyAuthService from '@services/pages/dashboard/integrationCenter/ecommerce/tiny/tinyAuth';

import NextStep from '@components/Onboarding/NextStep';
import Text from '@components/common/core/DataDisplay/Text';

import { ETextWeight } from '@domain/enums/components/EText';
import * as S from './styles';

const Tiny: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(tinySchema) });
  const history = useHistory();
  const theme = useTheme();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { selectedMarketings, selectedGateways } = useEcommerce();
  const { addSynchronization } = useSynchronization();
  const { format, utcToZonedTime } = useDate();
  const { sendFinancialData, handleStoreBonus } = useOnboarding();

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [authTokenValue, setAuthTokenValue] = React.useState<string>('');
  const [webhookURL, setWebhookURL] = React.useState<string>('');
  const [isURLCopied, setIsURLCopied] = React.useState<boolean>(false);

  const authTokenRegister = register('auth_token');

  const onAuthTokenChange = React.useCallback(
    event => {
      authTokenRegister.onChange(event);

      setAuthTokenValue(event.target.value);
    },
    [authTokenRegister],
  );

  const handleSuccessSubmit = React.useCallback(async () => {
    const { data } = await integrationCenterService.getStoreIntegrations({ storeAliasId });

    const hasTinyCredential = data?.store_integrations?.store_front?.has_tiny_credential;

    if (hasTinyCredential) {
      const foundTinyIntegration = ECOMMERCE_PROVIDERS.find(
        integration => integration.identifier === EEcommerceProvider.TINY,
      ) as typeof ECOMMERCE_PROVIDERS[number];

      const { generateSyncRequest } = foundTinyIntegration;

      const params = `?start_date=${format(new Date(2000, 1, 1), 'yyyy-MM-dd')}&end_date=${format(
        utcToZonedTime(new Date()),
        'yyyy-MM-dd',
      )}`;

      addSynchronization({
        name: 'Tiny',
        type: ESynchronizationType.STORE_PRODUCT,
        label: 'Produtos',
        dependencies: [],
        storeAliasId,
        config: {
          isOnboardingSynchronization: true,
          showNotification: false,
        },
        request: generateSyncRequest(ESynchronizationType.STORE_PRODUCT, storeAliasId, params),
      });

      sendFinancialData(storeAliasId);

      setIsSubmittingForm(false);

      if (!selectedGateways.length && !selectedMarketings.length) {
        handleStoreBonus();
        return;
      }

      if (selectedGateways.length) {
        history.push(`/onboarding/${storeAliasId}/ecommerce/integrations/gateways`);
      }

      if (!selectedGateways.length && selectedMarketings.length) {
        history.push(`/onboarding/${storeAliasId}/ecommerce/integrations/marketing`);
      }
    } else {
      setIsSubmittingForm(false);
    }
  }, [
    addSynchronization,
    format,
    history,
    selectedMarketings,
    storeAliasId,
    utcToZonedTime,
    sendFinancialData,
    handleStoreBonus,
    selectedGateways,
  ]);

  const onSubmit = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      const data = {
        auth_token: formData.auth_token,
      };

      try {
        await tinyAuthService.createTinyCredential({ storeAliasId, data });

        handleSuccessSubmit();

        setIsSubmittingForm(false);
      } catch (error: any) {
        toast.error(getTranslatedIntegrationsError(error));
      }
    },
    [toast, handleSuccessSubmit, storeAliasId],
  );

  const handleCopyURL = React.useCallback(() => {
    navigator.clipboard.writeText(webhookURL);
    setIsURLCopied(true);
    toast.success('URL copiada com sucesso.');
  }, [toast, webhookURL]);

  const generateWebhookURL = React.useCallback(async () => {
    try {
      const { data } = await tinyAuthService.generateWebhookURL({ storeAliasId });

      setWebhookURL(data.webhook_url);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [toast, storeAliasId]);

  React.useEffect(() => {
    generateWebhookURL();
  }, [generateWebhookURL]);

  const hasAllInputsValue = authTokenValue;

  return (
    <S.Wrapper>
      <S.FormWrapper>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Header>
            <S.Title>Integre com sua plataforma de E-commerce</S.Title>
          </S.Header>

          <S.IntegrationTitle>
            <TinyIcon size={36} />

            <S.IntegrationTitleText fontWeight={EHeadingWeight.REGULAR}>
              Integração com Tiny
            </S.IntegrationTitleText>
          </S.IntegrationTitle>

          <S.Body>
            <S.InputGroup>
              <S.TutorialWebhookText>
                Copie o link e configure o webhook na Tiny.{' '}
                <S.TutorialLink
                  href="https://ajuda.profitfy.me/pt-BR/articles/8319258-como-integrar-com-a-tiny"
                  target="_blank"
                >
                  Veja nosso artigo para saber como configurar.
                </S.TutorialLink>
              </S.TutorialWebhookText>
              <S.Label>Webhook URL</S.Label>

              <S.WebhookURLWrapper onClick={handleCopyURL} type="button">
                <S.WebhookURL weight={ETextWeight.MEDIUM}>{webhookURL}</S.WebhookURL>
                <S.IconWrapper>
                  <Copy size={18} color={theme.colors.gray[1]} outline={!isURLCopied} />
                </S.IconWrapper>
              </S.WebhookURLWrapper>
            </S.InputGroup>

            <S.InputGroup>
              <S.Label htmlFor="auth-token">Token</S.Label>

              <S.TextField
                {...authTokenRegister}
                type="text"
                id="auth-token"
                onChange={onAuthTokenChange}
                value={authTokenValue}
                isError={Boolean(errors.auth_token)}
              />
              {errors.auth_token && <Text isErrorFeedback>{errors.auth_token.message}</Text>}
            </S.InputGroup>
          </S.Body>

          <NextStep
            type="submit"
            isLoading={isSubmittingForm}
            disabled={isSubmittingForm || !hasAllInputsValue}
          >
            Próximo passo
          </NextStep>
        </S.Form>
      </S.FormWrapper>

      <S.TutorialWrapper>
        <S.TutorialImage
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/onboarding/images/content/onboarding/onboarding-integration-tutorial.svg"
          alt="Profitfy.me Integration Tutorial"
        />
        <S.TutorialTitle>Tutorial de integração</S.TutorialTitle>
        <S.TutorialContent>
          Para realizar a integração você precisará do <strong>Token</strong> que são encontrados
          neste link:
        </S.TutorialContent>
        <S.TutorialLink
          href="https://ajuda.profitfy.me/pt-BR/articles/8319258-como-integrar-com-a-tiny"
          target="_blank"
        >
          Encontre suas credenciais clicando aqui!
        </S.TutorialLink>
      </S.TutorialWrapper>
    </S.Wrapper>
  );
};

export default Tiny;
