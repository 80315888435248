import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { EHeadAlign } from '@domain/enums/components/ETable';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <>
      <S.ChevronButton />
      <S.TableWrapper>
        <S.Table>
          <S.TableHeader>
            <S.TableRow>
              <S.TableHead>Data</S.TableHead>
              <S.TableHead textAlign={EHeadAlign.RIGHT}>Valor</S.TableHead>
              <S.TableHead textAlign={EHeadAlign.CENTER}>Categoria</S.TableHead>
              <S.TableHead />
            </S.TableRow>
          </S.TableHeader>

          <S.TableBody>
            <S.TableRow>
              <S.TableData>
                <S.DateWrapper>
                  <Skeleton width="100%" height={32} />
                </S.DateWrapper>
              </S.TableData>
              <S.TableData textAlign={EHeadAlign.RIGHT}>
                <S.ValueWrapper>
                  <Skeleton width="100%" height={32} />
                </S.ValueWrapper>
              </S.TableData>
              <S.TableData textAlign={EHeadAlign.CENTER}>
                <S.CategoryWrapper>
                  <Skeleton width="100%" height={32} style={{ borderRadius: 15 }} />
                </S.CategoryWrapper>
              </S.TableData>
              <S.TableData />
            </S.TableRow>
          </S.TableBody>
        </S.Table>
      </S.TableWrapper>
    </>
  );
};

export default SkeletonLoading;
