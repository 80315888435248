import React from 'react';
import { useParams } from 'react-router-dom';

import kitService from '@services/pages/dashboard/kit/kit';
import { IParams } from '@domain/interfaces/IParams';
import { IKitProvider } from '@domain/interfaces/dashboard/Kit/variantKit';
import { useToast } from '@helpers/hooks/useToast';

const KitContext = React.createContext<IKitProvider | null>(null);

export const KitProvider: React.FC = ({ children }) => {
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();

  const [page, setPage] = React.useState<number>(0);
  const [pageCount, setPageCount] = React.useState<number>(1);
  const [isDeletingKit, setIsDeletingKit] = React.useState<boolean>(false);

  const {
    variantKits,
    totalPages: totalPagesKits,
    isLoading: isLoadingKits,
    isValidating: isValidatingKits,
    error: kitsError,
    mutate: mutateKits,
  } = kitService.listKits({ storeAliasId, page });

  const mutateKitProvider = React.useCallback(() => {
    const promises = [];

    if (kitsError) promises.push(mutateKits());
  }, [kitsError, mutateKits]);

  const onPageChange = React.useCallback(({ selected }) => setPage(selected), []);

  const deleteKit = React.useCallback(
    async variantKitAliasId => {
      setIsDeletingKit(true);

      try {
        await kitService.deleteKit({ storeAliasId, variantKitAliasId });

        mutateKits();

        setIsDeletingKit(false);
      } catch (error: any) {
        setIsDeletingKit(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [mutateKits, toast, storeAliasId],
  );

  React.useEffect(() => {
    if (totalPagesKits) {
      setPageCount(totalPagesKits);
    }
  }, [totalPagesKits]);

  const isLoadingKitProvider = isLoadingKits;
  const isValidatingKitProvider = isValidatingKits;
  const isKitProviderError = Boolean(kitsError);

  return (
    <KitContext.Provider
      value={{
        page,
        pageCount,
        onPageChange,
        isKitProviderError,
        isLoadingKitProvider,
        isValidatingKitProvider,
        mutateKitProvider,
        variantKits,
        deleteKit,
        isDeletingKit,
      }}
    >
      {children}
    </KitContext.Provider>
  );
};

export const useKit = (): IKitProvider => {
  const context = React.useContext(KitContext);

  if (!context) {
    throw new Error('useKit must be used within KitContext');
  }

  return context;
};
