import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';

export const TemplateName = styled(TextBase)`
  margin: 32px 0;
  line-height: 130%;
  text-align: left;
`;

export const Description = styled(TextBase)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[3]};
  line-height: 120%;
  text-align: left;
`;

export const Wrapper = styled.button`
  width: 198px;
  min-width: 198px;
  height: 280px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  border: unset;
  background-color: ${({ theme }) => theme.colors.background[2]};
  border-radius: 20px;
  box-sizing: border-box;
  transition: all 0.3s;
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.colors.gray[1]};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.green.default};

    svg,
    ${TemplateName} {
      color: ${({ theme }) => theme.colors.gray[8]};
    }

    ${Description} {
      color: ${({ theme }) => theme.colors.gray[4]};
    }
  }
`;

export const IconWrapper = styled.div`
  min-width: 48px;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
