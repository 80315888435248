import { AxiosResponse } from 'axios';

import { ApiService } from '@services/api';

import { USER_API_DOMAIN } from '@constants/api';

export class TrackingService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public track(
    storeAliasId: string | undefined,
    data: Record<string, any>,
  ): Promise<AxiosResponse<void>> {
    return this.apiService.post<void>(
      `/api/v1/users/stores/${storeAliasId}/segments/tracking/group`,
      {
        ...data,
        store_alias_id: storeAliasId,
      },
    );
  }

  public identify(data: Record<string, any>): Promise<AxiosResponse<void>> {
    return this.apiService.post<void>(`/api/v1/users/stores/segments/tracking/identify`, {
      ...data,
    });
  }
}

const trackingService = new TrackingService(USER_API_DOMAIN);

export default trackingService;
