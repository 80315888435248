import React from 'react';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { Bling as BlingIcon } from '@profitfy/pakkun-icons';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { newWindow } from '@helpers/hooks/usePopup';

import blingAuthService from '@services/pages/dashboard/integrationCenter/ecommerce/bling/blingAuth';
import ActiveIntegrationFooter from '@components/Dashboard/IntegrationCenter/Sidemodal/ActiveIntegrationFooter';

import * as S from './styles';

const Bling: React.FC = () => {
  const theme: any = useTheme();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { mutateStore } = useStoreConfig();

  const [isIntegrating, setIsIntegrating] = React.useState<boolean>(false);
  const [userWindow, setUserWindow] = React.useState<Window | undefined>(undefined);

  const onIntegrateClick = React.useCallback(async () => {
    setIsIntegrating(true);

    try {
      const { data: blingData } = await blingAuthService.generateBlingUrl({ storeAliasId });

      const window = newWindow(blingData.auth_url, 'Bling', 1280, 720);

      setUserWindow(window);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsIntegrating(false);
    }
  }, [storeAliasId, toast]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (userWindow?.closed) {
        clearInterval(interval);
        mutateStore();
      }
    }, 1000);
  }, [userWindow, mutateStore]);

  return (
    <S.ContentWrapper>
      <S.Header>
        <BlingIcon size={52} color={theme.colors.gray[2]} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com Bling
        </S.Heading>
      </S.Header>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>
        <S.FunctionalityDescriptionText>
          A integração com sua plataforma de e-commerce serve para puxar seus dados de loja.
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <S.TutorialWrapper>
        <S.TutorialHeading type={EHeadingSize.H5}>Tutorial de integração</S.TutorialHeading>
        <S.TutorialText>Basta clicar no botão abaixo e integrar com a plataforma.</S.TutorialText>
      </S.TutorialWrapper>

      <ActiveIntegrationFooter onIntegrateClick={onIntegrateClick} isIntegrating={isIntegrating} />
    </S.ContentWrapper>
  );
};

export default Bling;
