import React from 'react';
import { Pen, Cancel } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';

import { EBadgeColors } from '@domain/enums/components/EBadge';
import { EHeadAlign } from '@domain/enums/components/ETable';
import { IRowProps } from '@domain/interfaces/dashboard/Tax/IRow';

import { useDate } from '@helpers/hooks/useDate';
import { useTax } from '@helpers/hooks/pages/dashboard/tax/useTax';

import { numberFormatter } from '@helpers/masks';

import DeleteModal from '@components/common/core/Utils/DeleteModal';

import * as S from './styles';

const Row: React.FC<IRowProps> = ({ tax }) => {
  const { format, utcToZonedTime } = useDate();

  const {
    handleUserPeriodTax,
    handleEditingTax,
    deleteTaxPeriod,
    isDeletingHistoricTax,
    handleEditTaxSideModalOpen,
  } = useTax();
  const theme: any = useTheme();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);
  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);

  const handlePopoverOpen = React.useCallback(() => setIsPopoverOpen(!isPopoverOpen), [
    isPopoverOpen,
  ]);

  const handleIsHovering = React.useCallback(status => setIsHovering(status), []);

  const handleDeleteModalOpen = React.useCallback(() => setIsDeleteModalOpen(!isDeleteModalOpen), [
    isDeleteModalOpen,
  ]);

  const onDelete = React.useCallback(async () => {
    await deleteTaxPeriod(tax);

    handleDeleteModalOpen();
  }, [deleteTaxPeriod, tax, handleDeleteModalOpen]);

  const parseCalculateMode = React.useCallback(
    () =>
      ({
        'WITHOUT-COGS': 'Receita Líquida',
        'WITH-COGS': 'Receita Líquida - Custo dos Produtos',
        'WITH-ADS': 'Receita Líquida - Custo dos Produtos - Custo de Marketing',
      }[tax.calculate_mode]),
    [tax],
  );

  const parseRegime = React.useCallback(
    () =>
      ({
        SIMPLES_NACIONAL: 'Simples Nacional',
        LUCRO_PRESUMIDO: 'Lucro Presumido',
        LUCRO_REAL: 'Lucro Real',
      }[tax.regime]),
    [tax],
  );

  const onEditClick = React.useCallback(() => {
    setIsPopoverOpen(false);

    handleUserPeriodTax(tax);
    handleEditTaxSideModalOpen();

    handleEditingTax();
  }, [tax, handleUserPeriodTax, handleEditingTax, handleEditTaxSideModalOpen]);

  const onDeleteClick = React.useCallback(() => {
    setIsPopoverOpen(false);
    handleDeleteModalOpen();
  }, [handleDeleteModalOpen]);

  return (
    <S.Table.Row
      onMouseEnter={() => handleIsHovering(true)}
      onMouseLeave={() => handleIsHovering(false)}
      borderBottom
    >
      <S.Table.Data>
        {tax.start_date ? format(utcToZonedTime(tax.start_date), 'dd/MM/yyyy') : '-'}
      </S.Table.Data>

      <S.Table.Data>
        {tax.end_date ? format(utcToZonedTime(tax.end_date), 'dd/MM/yyyy') : '-'}
      </S.Table.Data>

      <S.Table.Data>{parseCalculateMode()}</S.Table.Data>

      <S.Table.Data align={EHeadAlign.CENTER}>{parseRegime()}</S.Table.Data>

      <S.Table.Data align={EHeadAlign.RIGHT}>
        {`${numberFormatter(tax.percentage_amount, 2)}%`}
      </S.Table.Data>

      <S.Table.Data align={EHeadAlign.CENTER}>
        {tax.is_default && (
          <S.DefaultTaxBadge color={EBadgeColors.PRIMARY}>Padrão</S.DefaultTaxBadge>
        )}
      </S.Table.Data>

      <S.Table.Data>
        <S.Popover open={isPopoverOpen} onOpenChange={handlePopoverOpen}>
          <S.Popover.Trigger>
            <S.MoreActionsButton isHovering={isHovering}>
              <S.Bullet />
              <S.Bullet />
              <S.Bullet />
            </S.MoreActionsButton>
          </S.Popover.Trigger>

          <S.Popover.Content>
            <S.MoreOptionWrapper>
              <S.ActionButton onClick={onEditClick}>
                <Pen outline size={16} color={theme.colors.font} />
                Editar
              </S.ActionButton>
              <S.ActionButton onClick={onDeleteClick} isDeleteButton>
                <Cancel outline size={16} color={theme.colors.danger.default} />
                Excluir
              </S.ActionButton>
            </S.MoreOptionWrapper>

            <S.Popover.Arrow />
          </S.Popover.Content>
        </S.Popover>

        <DeleteModal
          isOpen={isDeleteModalOpen}
          toggle={handleDeleteModalOpen}
          title="Deseja realmente excluir este imposto?"
          content="Essa ação é irreversível, deseja realmente excluir este imposto?"
          isLoading={isDeletingHistoricTax}
          onDelete={onDelete}
        />
      </S.Table.Data>
    </S.Table.Row>
  );
};

export default Row;
