import React from 'react';

import { useFinancialDetails } from '@helpers/hooks/pages/dashboard/financialDetails/useFinancialDetails';
import {
  IColumnProps,
  IReducedColumns,
} from '@domain/interfaces/dashboard/FinancialDetails/IFinancialDetails';
import SkeletonLoading from './SkeletonLoading';
import TableBody from './TableBody';

import * as S from './styles';

const DailyTable: React.FC = () => {
  const {
    columnsConfig,
    isLoadingFinancialDetails,
    isValidatingFinancialDetails,
  } = useFinancialDetails();
  const tableRef = React.useRef<HTMLDivElement>(null);

  const [hasScrolled, setHasScrolled] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (tableRef.current) {
      tableRef.current.addEventListener('scroll', () => {
        if (tableRef.current) {
          if (tableRef.current.scrollLeft > 0) {
            setHasScrolled(true);
          } else {
            setHasScrolled(false);
          }
        }
      });
    }
  }, []);

  if (isLoadingFinancialDetails || isValidatingFinancialDetails) {
    return <SkeletonLoading />;
  }

  const reducedColumnsConfig: IReducedColumns = columnsConfig.reduce(
    (obj, item: IColumnProps) => ({
      ...obj,
      [item.value]: { label: item.label, is_active: item.is_active },
    }),
    {},
  );

  return (
    <S.Table hasScrolled={hasScrolled} containerRef={tableRef}>
      <S.Table.Header>
        <S.Table.Row>
          <S.Table.Head />

          {reducedColumnsConfig?.gross_revenue.is_active && (
            <S.Table.Head>
              <S.Tooltip>
                <S.TooltipTrigger>R. Bruta</S.TooltipTrigger>
                <S.TooltipContent side="top" sideOffset={5}>
                  <S.ColumnName>Receita Bruta</S.ColumnName>
                  <S.TooltipArrow />
                </S.TooltipContent>
              </S.Tooltip>
            </S.Table.Head>
          )}

          {reducedColumnsConfig?.pending_orders.is_active && (
            <S.Table.Head>
              <S.Tooltip>
                <S.TooltipTrigger>P. Pendentes</S.TooltipTrigger>
                <S.TooltipContent side="top" sideOffset={5}>
                  <S.ColumnName>Pedidos Pendentes</S.ColumnName>
                  <S.TooltipArrow />
                </S.TooltipContent>
              </S.Tooltip>
            </S.Table.Head>
          )}

          {reducedColumnsConfig?.in_analysis_orders.is_active && (
            <S.Table.Head>
              <S.Tooltip>
                <S.TooltipTrigger>P. Análise</S.TooltipTrigger>
                <S.TooltipContent side="top" sideOffset={5}>
                  <S.ColumnName>Pedidos em Análise</S.ColumnName>
                  <S.TooltipArrow />
                </S.TooltipContent>
              </S.Tooltip>
            </S.Table.Head>
          )}

          {reducedColumnsConfig?.no_information_orders.is_active && (
            <S.Table.Head>
              <S.Tooltip>
                <S.TooltipTrigger>P. Não Conciliados</S.TooltipTrigger>
                <S.TooltipContent side="top" sideOffset={5}>
                  <S.ColumnName>Pedidos Não Conciliados</S.ColumnName>
                  <S.TooltipArrow />
                </S.TooltipContent>
              </S.Tooltip>
            </S.Table.Head>
          )}

          {reducedColumnsConfig?.discount_revenue.is_active && (
            <S.Table.Head>
              <S.Tooltip>
                <S.TooltipTrigger>Descontos</S.TooltipTrigger>
                <S.TooltipContent side="top" sideOffset={5}>
                  <S.ColumnName>Descontos</S.ColumnName>
                  <S.TooltipArrow />
                </S.TooltipContent>
              </S.Tooltip>
            </S.Table.Head>
          )}

          {reducedColumnsConfig?.charged_back_revenue.is_active && (
            <S.Table.Head>
              <S.Tooltip>
                <S.TooltipTrigger>Chargebacks</S.TooltipTrigger>
                <S.TooltipContent side="top" sideOffset={5}>
                  <S.ColumnName>Chargebacks</S.ColumnName>
                  <S.TooltipArrow />
                </S.TooltipContent>
              </S.Tooltip>
            </S.Table.Head>
          )}

          {reducedColumnsConfig?.refund_revenue.is_active && (
            <S.Table.Head>
              <S.Tooltip>
                <S.TooltipTrigger>Devoluções</S.TooltipTrigger>
                <S.TooltipContent side="top" sideOffset={5}>
                  <S.ColumnName>Devoluções</S.ColumnName>
                  <S.TooltipArrow />
                </S.TooltipContent>
              </S.Tooltip>
            </S.Table.Head>
          )}

          {reducedColumnsConfig?.net_revenue.is_active && (
            <S.Table.Head>
              <S.Tooltip>
                <S.TooltipTrigger>R. Líquida</S.TooltipTrigger>
                <S.TooltipContent side="top" sideOffset={5}>
                  <S.ColumnName>Receita Líquida</S.ColumnName>
                  <S.TooltipArrow />
                </S.TooltipContent>
              </S.Tooltip>
            </S.Table.Head>
          )}

          {reducedColumnsConfig?.cogs.is_active && (
            <S.Table.Head>
              <S.Tooltip>
                <S.TooltipTrigger>C. Produtos</S.TooltipTrigger>
                <S.TooltipContent side="top" sideOffset={5}>
                  <S.ColumnName>Custo dos Produtos</S.ColumnName>
                  <S.TooltipArrow />
                </S.TooltipContent>
              </S.Tooltip>
            </S.Table.Head>
          )}

          {reducedColumnsConfig?.marketing.is_active && (
            <S.Table.Head>
              <S.Tooltip>
                <S.TooltipTrigger>Marketing</S.TooltipTrigger>
                <S.TooltipContent side="top" sideOffset={5}>
                  <S.ColumnName>Marketing</S.ColumnName>
                  <S.TooltipArrow />
                </S.TooltipContent>
              </S.Tooltip>
            </S.Table.Head>
          )}

          {reducedColumnsConfig?.sales_fee.is_active && (
            <S.Table.Head>
              <S.Tooltip>
                <S.TooltipTrigger>Taxas Checkout</S.TooltipTrigger>
                <S.TooltipContent side="top" sideOffset={5}>
                  <S.ColumnName>Taxas de Checkout</S.ColumnName>
                  <S.TooltipArrow />
                </S.TooltipContent>
              </S.Tooltip>
            </S.Table.Head>
          )}

          {reducedColumnsConfig?.gateway_fee.is_active && (
            <S.Table.Head>
              <S.Tooltip>
                <S.TooltipTrigger>Taxas Gateway</S.TooltipTrigger>
                <S.TooltipContent side="top" sideOffset={5}>
                  <S.ColumnName>Taxas de Gateway</S.ColumnName>
                  <S.TooltipArrow />
                </S.TooltipContent>
              </S.Tooltip>
            </S.Table.Head>
          )}

          {reducedColumnsConfig?.shipping_cost_owner.is_active && (
            <S.Table.Head>
              <S.Tooltip>
                <S.TooltipTrigger>Despesas Envio</S.TooltipTrigger>
                <S.TooltipContent side="top" sideOffset={5}>
                  <S.ColumnName>Despesas com Envio</S.ColumnName>
                  <S.TooltipArrow />
                </S.TooltipContent>
              </S.Tooltip>
            </S.Table.Head>
          )}

          {reducedColumnsConfig?.tax.is_active && (
            <S.Table.Head>
              <S.Tooltip>
                <S.TooltipTrigger>Impostos</S.TooltipTrigger>
                <S.TooltipContent side="top" sideOffset={5}>
                  <S.ColumnName>Impostos</S.ColumnName>
                  <S.TooltipArrow />
                </S.TooltipContent>
              </S.Tooltip>
            </S.Table.Head>
          )}

          {reducedColumnsConfig?.other_spends.is_active && (
            <S.Table.Head>
              <S.Tooltip>
                <S.TooltipTrigger>Outras Despesas</S.TooltipTrigger>
                <S.TooltipContent side="top" sideOffset={5}>
                  <S.ColumnName>Outras Despesas</S.ColumnName>
                  <S.TooltipArrow />
                </S.TooltipContent>
              </S.Tooltip>
            </S.Table.Head>
          )}

          {reducedColumnsConfig?.financial_income.is_active && (
            <S.Table.Head>
              <S.Tooltip>
                <S.TooltipTrigger>R. Financeiras</S.TooltipTrigger>
                <S.TooltipContent side="top" sideOffset={5}>
                  <S.ColumnName>Receitas Financeiras</S.ColumnName>
                  <S.TooltipArrow />
                </S.TooltipContent>
              </S.Tooltip>
            </S.Table.Head>
          )}

          {reducedColumnsConfig?.net_profit.is_active && (
            <S.Table.Head>
              <S.Tooltip>
                <S.TooltipTrigger>Lucro Líquido</S.TooltipTrigger>
                <S.TooltipContent side="top" sideOffset={5}>
                  <S.ColumnName>Lucro Líquido</S.ColumnName>
                  <S.TooltipArrow />
                </S.TooltipContent>
              </S.Tooltip>
            </S.Table.Head>
          )}
          <S.Table.Head />
        </S.Table.Row>
      </S.Table.Header>

      <TableBody />
    </S.Table>
  );
};

export default DailyTable;
