import React from 'react';

import { EProgressBarStep } from '@domain/enums/downgrade/EDowngradeStep';

import SideBar from '@components/Downgrade/SideBar';
import NavBar from '@components/Downgrade/NavBar';
import AdProvidersContent from '@components/Downgrade/AdProviders';

import * as S from './styles';

const AdProviders: React.FC = () => {
  return (
    <S.Container>
      <SideBar onboardingStep={EProgressBarStep.AD_PROVIDERS} previousRouteLink="/" />

      <S.Content>
        <NavBar />
        <AdProvidersContent />
      </S.Content>
    </S.Container>
  );
};

export default AdProviders;
