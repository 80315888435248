import React from 'react';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  const customValueBillingElements = Array.from(Array(3).keys());

  const commonProp = { lineHeight: 'unset' };

  return (
    <>
      {customValueBillingElements.map(element => {
        return (
          <S.Wrapper key={element}>
            <S.NameWrapper>
              <Skeleton width={10} height={20} style={{ ...commonProp }} />

              <S.TitleAndDescriptionWrapper>
                <Skeleton width={120} height={27} style={{ ...commonProp }} />
                <Skeleton width={35} height={24} style={{ ...commonProp }} />
              </S.TitleAndDescriptionWrapper>
            </S.NameWrapper>

            <S.PaymentDataWrapper>
              <Skeleton width={75} height={27} style={{ ...commonProp }} />
              <Skeleton width={115} height={24} style={{ ...commonProp }} />
            </S.PaymentDataWrapper>

            <S.ValueWrapper>
              <Skeleton width={40} height={27} style={{ ...commonProp }} />
              <Skeleton width={30} height={24} style={{ ...commonProp }} />
            </S.ValueWrapper>

            <S.StatusWrapper>
              <Skeleton width={74} height={20} style={{ borderRadius: 15, ...commonProp }} />
              <Skeleton width={37} height={24} />
            </S.StatusWrapper>
          </S.Wrapper>
        );
      })}
    </>
  );
};

export default SkeletonLoading;
