import styled from 'styled-components/macro';

import CustomHoverCard from '@components/common/core/Utils/CustomHoverCard';

import TextBase from '@components/common/core/DataDisplay/Text';
import ImageBase from '@components/common/core/DataDisplay/Image';
import BadgeBase from '@components/common/core/DataDisplay/Badge';

export const HoverCard = styled(CustomHoverCard)``;

export const HoverCardContent = styled(CustomHoverCard.Content)`
  padding: 16px;
  min-width: 286px;
  width: 286px;
`;

export const GrossValueWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Badge = styled(BadgeBase)``;

export const GrossRevenue = styled(TextBase)`
  margin-bottom: 20px;
  display: block;
`;

export const GatewaysWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  max-height: 120px;
  height: 100%;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.gray[4]};
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray[1]};
    border-radius: 5px;
    transition: all 0.3s;

    &:hover {
      background: ${({ theme }) => theme.colors.gray[2]};
    }
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[5]};
  padding: 8px 19px 8px 0px;
`;

export const IconAndNameWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const GatewayName = styled(TextBase)`
  font-size: 12px;
`;

export const GatewayValue = styled(TextBase)``;

export const ValueAndDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ApprovedRevenueByOrderDateValue = styled(TextBase)`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 120%;
`;

export const Description = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
  font-size: 0.75rem;
  line-height: 120%;
`;

export const GatewaysIcon = styled(ImageBase)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;
