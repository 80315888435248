import React from 'react';
import { useTheme } from 'styled-components/macro';
import { Task, Sync, ChevronDown } from '@profitfy/pakkun-icons';

import { useConfig } from '@helpers/hooks/useConfig';
import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { useStorePermission } from '@helpers/hooks/common/useStorePermission';

import SideModal from '@components/common/core/DataDisplay/SideModal';
import ExportSpreadSheetSideModalContent from '@components/Dashboard/FinancialDashboard/SpreadSheetSideModal';
import LimitationModal from '@components/common/core/Utils/LimitationModal';

import * as S from './styles';

const ActionsButton: React.FC = () => {
  const theme = useTheme();
  const { analytics, selectedStore, user } = useConfig();
  const { reloadPage } = useFinancialDashboard();
  const { hasExportSpreadsheetFeature } = useStorePermission();

  const [sideModalIsOpen, setSideModalIsOpen] = React.useState<boolean>(false);
  const [isLimitationModalOpen, setIsLimitationModalOpen] = React.useState<boolean>(false);
  const [
    isHoveringExportSpreadSheetButton,
    setIsHoveringExportSpreadSheetButton,
  ] = React.useState<boolean>(false);
  const [isHoveringReloadDataButton, setIsHoveringReloadDataButton] = React.useState<boolean>(
    false,
  );
  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);

  const handlePopoverOpen = React.useCallback(state => {
    setIsPopoverOpen(state);
  }, []);

  const onSpreadSheetButtonMouseEnter = React.useCallback(() => {
    setIsHoveringExportSpreadSheetButton(true);
  }, []);

  const onSpreadSheetButtonMouseLeave = React.useCallback(() => {
    setIsHoveringExportSpreadSheetButton(false);
  }, []);

  const onReloadDataButtonMouseEnter = React.useCallback(() => {
    setIsHoveringReloadDataButton(true);
  }, []);

  const onReloadDataButtonMouseLeave = React.useCallback(() => {
    setIsHoveringReloadDataButton(false);
  }, []);

  const handleReloadData = React.useCallback(() => {
    reloadPage();
  }, [reloadPage]);

  const handleLimitationModalOpen = React.useCallback(() => {
    setIsLimitationModalOpen(state => !state);
  }, []);

  const handleOpenModal = React.useCallback(() => {
    handlePopoverOpen(false);

    if (hasExportSpreadsheetFeature) {
      setSideModalIsOpen(true);

      analytics?.track(
        'SpreadSheet Button Clicked',
        {
          email: user?.email,
        },
        { context: { groupId: selectedStore?.alias_id } },
      );
    } else {
      handleLimitationModalOpen();
    }
  }, [
    analytics,
    selectedStore,
    hasExportSpreadsheetFeature,
    handleLimitationModalOpen,
    handlePopoverOpen,
    user,
  ]);

  const toggleModal = React.useCallback(() => {
    setSideModalIsOpen(false);
  }, []);

  return (
    <>
      <S.Popover open={isPopoverOpen} onOpenChange={handlePopoverOpen}>
        <S.ButtonTrigger>
          Ações
          <ChevronDown size={16} color={theme.colors.font} />
        </S.ButtonTrigger>

        <S.PopoverContent side="bottom" sideOffset={5}>
          <S.ActionButton
            onMouseEnter={onSpreadSheetButtonMouseEnter}
            onMouseLeave={onSpreadSheetButtonMouseLeave}
            onClick={handleOpenModal}
          >
            <Task
              outline
              size={16}
              color={
                isHoveringExportSpreadSheetButton ? theme.colors.green.default : theme.colors.font
              }
            />
            Exportar para planilha
          </S.ActionButton>

          <S.ActionButton
            onMouseEnter={onReloadDataButtonMouseEnter}
            onMouseLeave={onReloadDataButtonMouseLeave}
            onClick={handleReloadData}
          >
            <Sync
              size={16}
              color={isHoveringReloadDataButton ? theme.colors.green.default : theme.colors.font}
            />
            Recarregar dados
          </S.ActionButton>

          <S.PopoverArrow />
        </S.PopoverContent>
      </S.Popover>
      <SideModal isOpen={sideModalIsOpen} toggle={toggleModal}>
        <ExportSpreadSheetSideModalContent toggle={toggleModal} />
      </SideModal>

      <LimitationModal
        isOpen={isLimitationModalOpen}
        toggle={handleLimitationModalOpen}
        imageSrc="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/temp/limitation-temp.svg"
        title="Exportação de planilhas não disponível"
        description="Escolha um plano que te permita exportar planilhas para que consiga usufruir o melhor da ferramenta."
        trackingPropertyName="NO_EXPORT_SPREADSHEET_FEATURE"
      />
    </>
  );
};

export default ActionsButton;
