import React from 'react';

import { Refresh } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';
import { useToast } from '@helpers/hooks/useToast';
import { useDate } from '@helpers/hooks/useDate';

import marketingService from '@services/pages/dashboard/marketing/marketing';

import * as S from './styles';

const RefreshMarketingData: React.FC = () => {
  const theme = useTheme();
  const { storeAliasId } = useParams<IParams>();
  const { setMarketingSynchronizationId, period } = useFinancialDashboard();
  const { toast } = useToast();
  const { format, utcToZonedTime } = useDate();

  const [hasClickedOnRefreshClick, setHasClickedOnRefreshClick] = React.useState<boolean>(false);

  const onRefreshClick = React.useCallback(
    async event => {
      event.stopPropagation();

      setHasClickedOnRefreshClick(true);

      try {
        const { data } = await marketingService.syncMarketing({
          storeAliasId,
          startDate: format(utcToZonedTime(period.startDate), 'yyyyMMdd'),
          endDate: format(utcToZonedTime(period.endDate), 'yyyyMMdd'),
        });

        setHasClickedOnRefreshClick(false);
        setMarketingSynchronizationId(data.synchronization.id);
      } catch (error: any) {
        setHasClickedOnRefreshClick(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [setMarketingSynchronizationId, storeAliasId, toast, format, period, utcToZonedTime],
  );

  return (
    <S.RefreshButton onClick={onRefreshClick} disabled={hasClickedOnRefreshClick}>
      <Refresh size={20} color={theme.colors.gray[1]} />
    </S.RefreshButton>
  );
};

export default RefreshMarketingData;
