import React from 'react';

import { Layout, X as CloseIcon } from 'phosphor-react';
import { useTheme } from 'styled-components';

import { ECreateMessageStep } from '@domain/enums/dashboard/automations/EMessage';
import { EButtonSize, EButtonVariant } from '@domain/enums/components/EButton';
import { IWhatsAppNodeModalProps } from '@domain/interfaces/dashboard/Automations/Canvas/Node/WhatsAppNode/IWhatsAppNodeModalProps';

import { useCanvasMessage } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessage';
import { useCanvasMessageSettings } from '@helpers/hooks/pages/dashboard/automations/useCanvasMessageSettings';
import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';

import { MESSAGES_TEMPLATES } from '@constants/pages/dashboard/automations/messages/templates';

import { messageTemplateToMessage } from '@helpers/utils/dashboard/automations/Canvas/messages/messageMapper';

import UpdateDefaultMessageContent from './DefaultMessages/DefaultMessage/UpdateMessageContent';
import UpdateMessageContent from './UpdateMessageContent';
import NewMessageContent from './NewMessageContent';
import SelectMessage from './SelectMessage';
import DefaultMessages from './DefaultMessages';
import Tab from './Tab';

import * as S from './styles';

const MAX_MESSAGES = 10;

const WhatsAppModal: React.FC<IWhatsAppNodeModalProps> = ({ isOpen, toggle }) => {
  const theme = useTheme();

  const { selectedNodeId, nodes } = useAutomation();
  const { messages } = useCanvasMessage();
  const {
    handleCreateNewMessageStep,
    handleContents,
    showUpdateMessageContentOnWhatsappNode,
    showUpdateDefaultMessageContentOnWhatsappNode,
    handleShowUpdateMessageContentOnWhatsappNode,
    handleShowUpdateDefaultMessageContentOnWhatsappNode,
    handleShowNewMessageContentOnWhatsappNode,
    showNewMessageContentOnWhatsappNode,
    resetOnMessageModalClose,
    shouldCloseModal,
    handleShouldCloseModal,
    handleSelectedMessageToUpdate,
  } = useCanvasMessageSettings();

  const foundSelectedNode = nodes.find(node => node.id === selectedNodeId);
  const hasSelectedMessage = Boolean(foundSelectedNode?.data.message);

  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const onChangeTab = React.useCallback(
    index => {
      if (index === 0) {
        handleCreateNewMessageStep(ECreateMessageStep.SELECT_MESSAGE_TEMPLATE);
        handleContents([]);
      }
      setSelectedTab(index);
    },
    [handleContents, handleCreateNewMessageStep],
  );

  const handleClose = React.useCallback(() => {
    handleCreateNewMessageStep(ECreateMessageStep.SELECT_MESSAGE_TEMPLATE);
    resetOnMessageModalClose();
    handleContents([]);
    toggle();
  }, [handleContents, handleCreateNewMessageStep, toggle, resetOnMessageModalClose]);

  const onEditCurrentMessage = React.useCallback(() => {
    if (!foundSelectedNode) return;

    if (!foundSelectedNode?.data?.message) return;

    if (foundSelectedNode.data.message.alias_id) {
      const foundMessage = messages.find(
        message => message.alias_id === foundSelectedNode.data.message.alias_id,
      );

      handleShowUpdateMessageContentOnWhatsappNode();
      handleSelectedMessageToUpdate(foundMessage);
      return;
    }

    const foundMessage = MESSAGES_TEMPLATES.find(
      message => message.id === foundSelectedNode.data.message.id,
    );

    const mappedMessage = messageTemplateToMessage(foundMessage);

    handleShowUpdateMessageContentOnWhatsappNode();
    handleSelectedMessageToUpdate(mappedMessage);
  }, [
    foundSelectedNode,
    handleSelectedMessageToUpdate,
    handleShowUpdateMessageContentOnWhatsappNode,
    messages,
  ]);

  React.useEffect(() => {
    if (shouldCloseModal) {
      handleShouldCloseModal(false);
      resetOnMessageModalClose();
      toggle();
    }
  }, [shouldCloseModal, handleShouldCloseModal, toggle, resetOnMessageModalClose]);

  const isWithinMaxMessagesLimit = messages?.length < MAX_MESSAGES;

  return (
    <S.Wrapper isOpen={isOpen} toggle={handleClose}>
      {!showNewMessageContentOnWhatsappNode &&
        !showUpdateMessageContentOnWhatsappNode &&
        !showUpdateDefaultMessageContentOnWhatsappNode && (
          <>
            <S.Row>
              <S.GroupTitle>
                <Layout size={28} color={theme.colors.gray[1]} />
                <S.Title>Escolha uma mensagem</S.Title>
              </S.GroupTitle>

              <S.ButtonsWrapper>
                {hasSelectedMessage && (
                  <S.EditMessageButton
                    onClick={onEditCurrentMessage}
                    buttonSize={EButtonSize.MEDIUM}
                    variant={EButtonVariant.SECONDARY_BUTTON}
                  >
                    Editar mensagem
                  </S.EditMessageButton>
                )}

                <S.NewMessageButton
                  onClick={handleShowNewMessageContentOnWhatsappNode}
                  buttonSize={EButtonSize.MEDIUM}
                >
                  + Nova Mensagem
                </S.NewMessageButton>
              </S.ButtonsWrapper>
            </S.Row>

            {isWithinMaxMessagesLimit && (
              <Tab defaultTab={selectedTab} onChangeTab={onChangeTab}>
                <div>Minhas mensagens</div>
                <div>Mensagens padrões</div>
              </Tab>
            )}

            {!isWithinMaxMessagesLimit && (
              <Tab defaultTab={selectedTab} onChangeTab={onChangeTab}>
                <div>Selecionar mensagem</div>
              </Tab>
            )}

            {selectedTab === 0 && <SelectMessage toggleModal={handleClose} />}

            {isWithinMaxMessagesLimit && selectedTab === 1 && (
              <DefaultMessages toggleModal={handleClose} />
            )}
          </>
        )}

      {showNewMessageContentOnWhatsappNode && (
        <>
          <S.CloseButton onClick={handleShowNewMessageContentOnWhatsappNode}>
            <CloseIcon size={20} color={theme.colors.gray[3]} />
          </S.CloseButton>

          <NewMessageContent closeModal={toggle} />
        </>
      )}

      {showUpdateMessageContentOnWhatsappNode && (
        <>
          <S.CloseButton onClick={handleShowUpdateMessageContentOnWhatsappNode}>
            <CloseIcon size={20} color={theme.colors.gray[3]} />
          </S.CloseButton>

          <UpdateMessageContent />
        </>
      )}

      {showUpdateDefaultMessageContentOnWhatsappNode && (
        <>
          <S.CloseButton onClick={handleShowUpdateDefaultMessageContentOnWhatsappNode}>
            <CloseIcon size={20} color={theme.colors.gray[3]} />
          </S.CloseButton>

          <UpdateDefaultMessageContent handleClose={handleClose} />
        </>
      )}
    </S.Wrapper>
  );
};

export default WhatsAppModal;
