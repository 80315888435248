import styled from 'styled-components/macro';

import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';
import ImageBase from '@components/common/core/DataDisplay/Image';
import NoImageBase from '@components/common/core/DataDisplay/NoImage';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 548px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  padding: 32px;
  box-sizing: border-box;
`;

export const Heading = styled(TextBase)`
  display: inherit;
  margin-bottom: 24px;
  color: #ffffff;
  text-align: center;
  width: 100%;
  max-width: 471px;
`;

export const ProductWrapper = styled.div`
  min-height: 54px;
  display: flex;
  gap: 10px;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProductImage = styled(ImageBase)`
  width: 54px;
  height: 54px;
  border-radius: 50%;
`;

export const NoImage = styled(NoImageBase)`
  background-color: ${({ theme }) => theme.colors.background[3]};
`;

export const ProductName = styled(TextBase)`
  color: #ffffff;
`;

export const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray[5]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[5]};
  padding: 32px 0 24px 0;
`;

export const SummaryValues = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
`;

export const SummaryDetail = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SummaryDetailText = styled(TextBase)`
  color: #ffffff;
  font-size: 1.125rem;
`;

export const SummaryDetailValue = styled(TextBase)`
  color: #ffffff;
  font-size: 1.125rem;
`;

export const StoppedProfitingText = styled(TextBase)`
  color: #ffffff;
  font-size: 1.125rem;
`;

export const SummaryDetailTotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SummaryDetailTotal = styled(TextBase)`
  font-weight: 800;
  color: ${({ theme }) => theme.colors.success.light};
  line-height: 130%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: 1032px) {
    gap: 26px;
  }

  @media only screen and (max-width: 535px) {
    flex-direction: column;
    gap: 12px;
  }
`;

export const KnowZendropButton = styled(ButtonBase)``;

export const OportunityButton = styled(ButtonBase)`
  width: 100%;
`;
