import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';

export const SyncButton = styled(ButtonBase)``;

export const CancelButton = styled.button`
  background-color: unset;
  border: unset;
  color: ${({ theme }) => theme.colors.font};
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.fonts.body};
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: flex-end;
`;

export const Description = styled(TextBase)`
  margin-bottom: 24px;
  display: block;
`;

export const Modal = styled(ModalBase)`
  & > div:first-child {
    & > span {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 1.125rem;
      font-weight: 500;
    }
  }
`;
