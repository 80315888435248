import React from 'react';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const TableSkeletonLoading: React.FC = () => {
  const tableSkeletons = Array.from(Array(5).keys());

  return (
    <>
      {tableSkeletons.map(tableSkeleton => (
        <S.TablesSkeletonWrapper key={tableSkeleton}>
          <Skeleton width="100%" height={71} style={{ borderRadius: 15, lineHeight: 'unset' }} />
        </S.TablesSkeletonWrapper>
      ))}
    </>
  );
};

const PageSkeletonLoading: React.FC = () => {
  return (
    <S.PageSkeletonWrapper>
      <S.Header>
        <Skeleton width="100%" height={144} style={{ borderRadius: 15, lineHeight: 'unset' }} />
      </S.Header>

      <TableSkeletonLoading />
    </S.PageSkeletonWrapper>
  );
};

export { PageSkeletonLoading, TableSkeletonLoading };
