import React from 'react';

import { IMetricCardProps } from '@domain/interfaces/dashboard/ProductAnalytics/IProductDetails';
import { numberFormatter } from '@helpers/masks';
import { useFinancialDashboard } from '@helpers/hooks/pages/dashboard/financialDashboard/useFinancialDashboard';

import * as S from './styles';

const MetricCard: React.FC<IMetricCardProps> = ({ type, title, value, icon: Icon }) => {
  const { financialDetailsReport } = useFinancialDashboard();

  return (
    <S.Card>
      <S.Content>
        <S.IconWrapper>
          <Icon outline size={24} />
        </S.IconWrapper>

        <S.TextWrapper>
          {type === 'CURRENCY' && <S.Value>{`R$ ${numberFormatter(value, 2)}`}</S.Value>}
          {type === 'PERCENTAGE' && <S.Value>{`${numberFormatter(value, 1)}%`}</S.Value>}
          {type === 'QUANTITY' && (
            <S.Value>
              {`R$ ${numberFormatter(value, 2)} `}
              {title === 'Pedidos aprovados' && `(${financialDetailsReport.paid_orders.quantity})`}
              {title === 'Pedidos pendentes' &&
                `(${financialDetailsReport.pending_orders.quantity})`}
            </S.Value>
          )}

          <S.Title>{title}</S.Title>
        </S.TextWrapper>
      </S.Content>
    </S.Card>
  );
};

export default MetricCard;
